export const UNUSED_STREAK_FIELDS = new Set([
    "Client #1 First",
    "Client #1 Last",
    "Client #1 Email",
    "Client #1 Phone",
    "Client #1 DOB",
    "Client #2 First",
    "Client #2 Last",
    "Client #2 Email",
    "Client #2 Phone",
    "Client #2 DOB",
    "Producer Name",
    "Producer Email",
    "Producer Phone",
    "Producer License #",
    "Street #",
    "Street Name",
    "City",
    "State",
    "Zip",
    "Listing Company",
    "Listing Agent Address",
    "Listing Agent First Name",
    "Listing Agent Last Name",
    "Listing Agent Phone",
    "Listing Agent Email",
    "Select ZHL LO",
    "OP Team",
]);
export const getAddress = (leadOrContract) => {
    if ([leadOrContract.fields["Street #"], leadOrContract.fields["Street Name"], leadOrContract.fields["City"]].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).length === 3) {
        return [
            [
                [leadOrContract.fields["Street #"], leadOrContract.fields["Street Name"]].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" "),
                leadOrContract.fields["City"],
                leadOrContract.fields["State"],
            ]
                .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
                .join(", "),
            leadOrContract.fields["Zip"],
        ]
            .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
            .join(" ");
    }
    return null;
};
export const indexBy = (items, key) => {
    const itemsDict = {};
    for (let i = 0; i < items.length; ++i) {
        itemsDict[items[i][key]] = i;
    }
    return itemsDict;
};
export const groupBy = (items, key) => {
    const itemsDict = {};
    for (const item of items) {
        itemsDict[item[key]] = item;
    }
    return itemsDict;
};
