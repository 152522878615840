/** Fields that are only shown if `State` is set to `South Carolina` */
export const SC_ONLY_FIELDS = [
    "Termination Fee",
    "How do you intend to pay the Termination Fee?",
    "How do you intend to pay the Termination Fee? (Other)",
    "How do you intend to pay the Earnest Money Deposit?",
    "How do you intend to pay the Earnest Money Deposit? (Other)",
    "Lender Name",
];
export const NC_ONLY_FIELDS = [
    "Due Diligence Fee",
    "How do you intend to pay the Due Diligence Fee?",
    "How do you intend to pay the Due Diligence Fee? (Other)",
];
export const FORM_FIELDS = [
    [
        {
            name: "State",
            required: true,
        },
        {
            name: "Property Address",
            required: true,
        },
        {
            name: "Full Legal Names of ALL Borrowers",
            required: true,
        },
        // {
        //     name: "Have we submitted an offer on a property prior to this?",
        //     required: true,
        // },
    ],
    [
        {
            name: "Buyer #1 Email Address",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "Buyer #1 Date of Birth",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "Buyer #2 Name",
            xs: 12,
            md: 4,
        },
        {
            name: "Buyer #2 Email Address",
            xs: 12,
            md: 4,
        },
        {
            name: "Buyer #2 Date of Birth",
            xs: 12,
            md: 4,
        },
        {
            name: "Marital Status",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "Your Current Mailing Address",
            required: true,
            xs: 12,
            md: 6,
        },
    ],
    [
        {
            name: "Offer Amount",
            required: true,
            xs: 12,
            md: 4,
        },
        {
            name: "Due Diligence Fee",
            required: true,
            xs: 12,
            md: 4,
        },
        {
            name: "Termination Fee",
            required: true,
            xs: 12,
            md: 4,
        },
        {
            name: "Earnest Money Deposit",
            required: true,
            xs: 12,
            md: 4,
        },
        {
            name: "How do you intend to pay the Due Diligence Fee?",
            required: true,
        },
        {
            name: "How do you intend to pay the Due Diligence Fee? (Other)",
            required: false,
        },
        {
            name: "How do you intend to pay the Termination Fee?",
            required: true,
        },
        {
            name: "How do you intend to pay the Termination Fee? (Other)",
            required: false,
        },
        {
            name: "How do you intend to pay the Earnest Money Deposit?",
            required: true,
        },
        {
            name: "How do you intend to pay the Earnest Money Deposit? (Other)",
            required: false,
        },
        {
            name: "Closing Attorney Preference",
            label: "Have you chosen a closing attorney? If not, we recommend Midtown Law, a firm we frequently work with and trust. Please indicate 'Yes' if you have a preference, or 'No' if you'd like us to proceed with Midtown Law.",
            required: true,
        },
        {
            name: "What is the name of the Closing Attorney that you would prefer to use?",
            required: false,
        },
        {
            name: "Due Diligence Time Period",
            required: true,
            xs: 12,
            md: 6,
            // helperText: "Most contracts are between 14-21 days.",
        },
        {
            name: "Closing Date",
            required: true,
            xs: 12,
            md: 6,
            // helperText: "Cash can close in 14 days, VA takes 45...but typical is 30 days",
        },
        {
            name: "Personal Property",
            required: true,
        },
        {
            name: "Personal Property (Other)",
            required: false,
            // helperText: "Remember, things like the stove, dishwasher and anything screwed in automatically stay",
        },
        {
            name: "Full Legal Names of ALL to be on the deed",
            required: true,
        },
        {
            name: "Seller Paid Closing Costs",
            required: true,
            xs: 12,
            md: 6,
            // helperText: "Please enter a # amount or n/a",
        },
        {
            name: "Home Warranty",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "Do you want a Survey?",
            required: false,
            xs: 12,
            md: 6,
        },
        {
            name: "Are you attending the closing in person?",
            required: false,
            xs: 12,
            md: 6,
        },
    ],
    [
        {
            name: "Do you intend to obtain a loan?",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "Loan Type",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "How much are you putting as a down payment?",
            required: true,
            xs: 12,
            md: 6,
        },
        {
            name: "Do you have to sell or lease a property in order to qualify?",
            required: false,
            xs: 12,
            md: 6,
        },
        {
            name: "Have to Sell - Property Address",
            required: false,
            xs: 12,
        },
        {
            name: "Have to Sell - Under Contract",
            required: false,
            xs: 12,
        },
        {
            name: "Have to Sell - Currently Listed",
            required: false,
            xs: 12,
        },
        {
            name: "Lender Name",
            required: false,
            xs: 12,
        },
    ],
];
