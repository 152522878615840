import * as React from "react";
import { Close, Search } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
const ChronologySearch = (props) => {
    const { onClose, onSearchChanged } = props;
    const [search, setSearch] = React.useState("");
    const containerRef = React.useRef(null);
    const onClick = React.useCallback((e) => {
        const offset = containerRef.current.getBoundingClientRect();
        if (e.clientX < offset.x ||
            e.clientX > offset.x + offset.width ||
            e.clientY < offset.y ||
            e.clientY > offset.y + offset.height) {
            if (search.trim() === "") {
                onClose();
            }
        }
    }, [onClose, search]);
    const updateSearch = React.useCallback((e) => {
        setSearch(e.target.value);
        onSearchChanged(e.target.value);
    }, [onSearchChanged]);
    const handleClose = React.useCallback(() => {
        setSearch("");
        onClose();
    }, [onClose]);
    React.useEffect(() => {
        document.addEventListener("click", onClick, false);
        return () => {
            document.removeEventListener("click", onClick, false);
        };
    });
    return (React.createElement(TextField, { ref: containerRef, variant: "outlined", fullWidth: true, value: search, onChange: updateSearch, slotProps: {
            input: {
                startAdornment: React.createElement(Search, { fontSize: "small" }),
                endAdornment: (React.createElement(IconButton, { size: "small", onClick: handleClose, tabIndex: -1 },
                    React.createElement(Close, { fontSize: "small" }))),
            },
        }, size: "small" }));
};
export default ChronologySearch;
