import * as React from "react";
import { Grid2, MenuItem, TextField } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { FilterContext } from "../FilterContext";
const SavedViewChoiceFilter = (props) => {
    var _a, _b, _c, _d;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.filterGroups
        .reduce((acc, fg) => {
        return [...acc, ...fg.filters];
    }, [])
        .find((f) => f.uid === uid);
    const field = React.useMemo(() => {
        var _a, _b;
        return (_b = ((_a = context.fields) !== null && _a !== void 0 ? _a : []).find((x) => x.uid === filter.choice_filter.lhs_field)) !== null && _b !== void 0 ? _b : null;
    }, [context.fields, filter.choice_filter]);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.choice_filter.comparator = comparator;
        if (comparator !== "is any of" && comparator !== "is not any of") {
            newFilter.choice_filter.rhs_values = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValues = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.choice_filter.rhs_values = val;
        console.log({ choices: val, newFilter });
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.choiceFieldComparators;
    const options = React.useMemo(() => {
        var _a, _b, _c, _d;
        const res = (_b = (_a = field === null || field === void 0 ? void 0 : field.choice_set) === null || _a === void 0 ? void 0 : _a.choices) !== null && _b !== void 0 ? _b : [];
        for (const val in (_d = (_c = filter.choice_filter) === null || _c === void 0 ? void 0 : _c.rhs_values) !== null && _d !== void 0 ? _d : []) {
            if (!res.some((x) => x.name === val)) {
                res.push({
                    name: val,
                    isArchived: true,
                });
            }
        }
        return res;
    }, [(_a = field === null || field === void 0 ? void 0 : field.choice_set) === null || _a === void 0 ? void 0 : _a.choices, (_b = filter.choice_filter) === null || _b === void 0 ? void 0 : _b.rhs_values]);
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_c = filter.choice_filter.comparator) !== null && _c !== void 0 ? _c : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["is any of", "is not any of"].includes(filter.choice_filter.comparator) && (React.createElement(Grid2, { style: { minWidth: "200px" } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_d = filter.choice_filter.rhs_values) !== null && _d !== void 0 ? _d : [], onChoicesChange: setRHSValues, options: options.reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.name]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, options.map((x) => (React.createElement(MenuItem, { key: x.name, value: x.name, style: { fontStyle: x.isArchived ? "italic" : "normal" } }, x.name))))))));
};
export default SavedViewChoiceFilter;
