import * as React from "react";
import { FormControl } from "@mui/base";
import { Button, DialogActions, DialogContent, DialogTitle, RadioGroup, FormControlLabel, Radio, Grid, FormLabel, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import BuyerLeadAutocomplete from "./BuyerLeadAutocomplete";
import SellerLeadAutocomplete from "./SellerLeadAutocomplete";
const ContactLeadDialog = (props) => {
    const { DialogProps, onClose, onExistingBuyerLeadSelected, onExistingSellerLeadSelected, onNewBuyerLeadSelected, onNewSellerLeadSelected, } = props;
    const [buyerOrSeller, setBuyerOrSeller] = React.useState("buyer");
    const [mode, setMode] = React.useState("create-new");
    const [buyerLead, setBuyerLead] = React.useState(null);
    const [sellerLead, setSellerLead] = React.useState(null);
    const isValid = React.useMemo(() => {
        return (mode === "create-new" || (buyerOrSeller === "buyer" && buyerLead) || (buyerOrSeller === "seller" && sellerLead));
    }, [buyerLead, buyerOrSeller, mode, sellerLead]);
    const onConfirm = React.useMemo(() => {
        return () => {
            if (mode === "select-existing") {
                if (buyerOrSeller === "buyer") {
                    onExistingBuyerLeadSelected(buyerLead.uid);
                }
                else if (buyerOrSeller === "seller") {
                    onExistingSellerLeadSelected(sellerLead.uid);
                }
            }
            else if (mode === "create-new") {
                if (buyerOrSeller === "buyer") {
                    onNewBuyerLeadSelected();
                }
                else if (buyerOrSeller === "seller") {
                    onNewSellerLeadSelected();
                }
            }
            else {
                throw new Error(`Invalid mode ${mode}`);
            }
        };
    }, [
        mode,
        buyerOrSeller,
        onExistingBuyerLeadSelected,
        buyerLead,
        onExistingSellerLeadSelected,
        sellerLead,
        onNewBuyerLeadSelected,
        onNewSellerLeadSelected,
    ]);
    const handleBuyerLeadSelected = React.useCallback((_, val) => {
        setBuyerLead(val);
    }, [setBuyerLead]);
    const handleSellerLeadSelected = React.useCallback((_, val) => {
        setSellerLead(val);
    }, [setSellerLead]);
    const handleModeChanged = React.useCallback((e) => {
        const val = e.target.value;
        setMode(val);
        setBuyerLead(val === "select-existing" ? buyerLead : null);
        setSellerLead(val === "select-existing" ? sellerLead : null);
    }, [buyerLead, sellerLead]);
    const handleLeadTypeChanged = React.useCallback((e) => {
        const val = e.target.value;
        setBuyerOrSeller(val);
        setBuyerLead(val === "buyer" ? buyerLead : null);
        setSellerLead(val === "seller" ? sellerLead : null);
    }, [buyerLead, sellerLead]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, "Link contact to a lead"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 4, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(RadioGroup, { defaultValue: "create-new", name: "mode", value: mode, onChange: handleModeChanged },
                        React.createElement(FormControlLabel, { value: "create-new", control: React.createElement(Radio, null), label: "Create a new lead" }),
                        React.createElement(FormControlLabel, { value: "select-existing", control: React.createElement(Radio, null), label: "Select an existing lead" }))),
                React.createElement(Grid, { item: true },
                    React.createElement(FormControl, null,
                        React.createElement(FormLabel, null, "What kind of lead?"),
                        React.createElement(RadioGroup, { defaultValue: "buyer", name: "buyerOrSeller", value: buyerOrSeller, onChange: handleLeadTypeChanged },
                            React.createElement(FormControlLabel, { value: "buyer", control: React.createElement(Radio, null), label: "Buyer Lead" }),
                            React.createElement(FormControlLabel, { value: "seller", control: React.createElement(Radio, null), label: "Seller Lead" })))),
                mode === "select-existing" && (React.createElement(Grid, { item: true }, buyerOrSeller === "buyer" ? (React.createElement(BuyerLeadAutocomplete, { onChange: handleBuyerLeadSelected, value: buyerLead, TextFieldProps: { label: "Select a Buyer Lead" } })) : (React.createElement(SellerLeadAutocomplete, { onChange: handleSellerLeadSelected, value: sellerLead, TextFieldProps: { label: "Select a Seller Lead" } })))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onConfirm, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default ContactLeadDialog;
