/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get a file
 */
export const getFile = (uid, options) => {
    return axios.get(`/api/files/${uid}`, options);
};
export const getGetFileQueryKey = (uid) => {
    return [`/api/files/${uid}`];
};
export const getGetFileQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetFileQueryKey(uid);
    const queryFn = ({ signal }) => getFile(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a file
 */
export function useGetFile(uid, options) {
    const queryOptions = getGetFileQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Delete a file
 */
export const deleteFile = (uid, options) => {
    return axios.delete(`/api/files/${uid}`, options);
};
export const getDeleteFileMutationOptions = (options) => {
    const mutationKey = ['deleteFile'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteFile(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a file
*/
export const useDeleteFile = (options) => {
    const mutationOptions = getDeleteFileMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Search files
*/
export const searchFiles = (paginatedSearchFilesBodyRequest, options) => {
    return axios.post(`/api/files/search`, paginatedSearchFilesBodyRequest, options);
};
export const getSearchFilesMutationOptions = (options) => {
    const mutationKey = ['searchFiles'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchFiles(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search files
*/
export const useSearchFiles = (options) => {
    const mutationOptions = getSearchFilesMutationOptions(options);
    return useMutation(mutationOptions);
};
