/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Replaces all wildcards and parses Jinja syntax and other formatters for the given template and box

_Authorization: none required_
 * @summary Parse template for box
 */
export const parseMailschedule3Template = (templateParseBodyRequest, options) => {
    return axios.post(`/api/mailschedule/parse_template_for_box`, templateParseBodyRequest, options);
};
export const getParseMailschedule3TemplateMutationOptions = (options) => {
    const mutationKey = ['parseMailschedule3Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return parseMailschedule3Template(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Parse template for box
*/
export const useParseMailschedule3Template = (options) => {
    const mutationOptions = getParseMailschedule3TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Replaces all wildcards and parses Jinja syntax and other formatters for the given template and box

_Authorization: none required_
* @summary Parse template for box
*/
export const parseMultipleMailschedule4Templates = (mailSchedule4TemplateParseMultipleTemplateBodyRequest, options) => {
    return axios.post(`/api/mailschedule4/parse_multiple_templates`, mailSchedule4TemplateParseMultipleTemplateBodyRequest, options);
};
export const getParseMultipleMailschedule4TemplatesMutationOptions = (options) => {
    const mutationKey = ['parseMultipleMailschedule4Templates'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return parseMultipleMailschedule4Templates(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Parse template for box
*/
export const useParseMultipleMailschedule4Templates = (options) => {
    const mutationOptions = getParseMultipleMailschedule4TemplatesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Replaces all wildcards and parses Jinja syntax and other formatters for the given template and box

_Authorization: none required_
* @summary Parse multiple templates
*/
export const parseMailschedule4Template = (mailSchedule4TemplateParseBodyRequest, options) => {
    return axios.post(`/api/mailschedule4/parse_template_for_box`, mailSchedule4TemplateParseBodyRequest, options);
};
export const getParseMailschedule4TemplateMutationOptions = (options) => {
    const mutationKey = ['parseMailschedule4Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return parseMailschedule4Template(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Parse multiple templates
*/
export const useParseMailschedule4Template = (options) => {
    const mutationOptions = getParseMailschedule4TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
