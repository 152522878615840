/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all choices in a set
 */
export const listChoiceSets = (params, options) => {
    return axios.get(`/api/choice_sets`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListChoiceSetsQueryKey = (params) => {
    return [`/api/choice_sets`, ...(params ? [params] : [])];
};
export const getListChoiceSetsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListChoiceSetsQueryKey(params);
    const queryFn = ({ signal }) => listChoiceSets(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all choices in a set
 */
export function useListChoiceSets(params, options) {
    const queryOptions = getListChoiceSetsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Create a choice set
 */
export const addChoiceSet = (upsertChoiceSetRequest, options) => {
    return axios.post(`/api/choice_sets`, upsertChoiceSetRequest, options);
};
export const getAddChoiceSetMutationOptions = (options) => {
    const mutationKey = ['addChoiceSet'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addChoiceSet(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a choice set
*/
export const useAddChoiceSet = (options) => {
    const mutationOptions = getAddChoiceSetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a choice set
*/
export const getChoiceSet = (uid, options) => {
    return axios.get(`/api/choice_sets/${uid}`, options);
};
export const getGetChoiceSetQueryKey = (uid) => {
    return [`/api/choice_sets/${uid}`];
};
export const getGetChoiceSetQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetChoiceSetQueryKey(uid);
    const queryFn = ({ signal }) => getChoiceSet(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a choice set
 */
export function useGetChoiceSet(uid, options) {
    const queryOptions = getGetChoiceSetQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Update a choice set
 */
export const updateChoiceSet = (uid, upsertChoiceSetRequest, options) => {
    return axios.post(`/api/choice_sets/${uid}`, upsertChoiceSetRequest, options);
};
export const getUpdateChoiceSetMutationOptions = (options) => {
    const mutationKey = ['updateChoiceSet'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateChoiceSet(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a choice set
*/
export const useUpdateChoiceSet = (options) => {
    const mutationOptions = getUpdateChoiceSetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
* @summary Delete a choice set
*/
export const deleteChoiceSet = (uid, options) => {
    return axios.delete(`/api/choice_sets/${uid}`, options);
};
export const getDeleteChoiceSetMutationOptions = (options) => {
    const mutationKey = ['deleteChoiceSet'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteChoiceSet(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a choice set
*/
export const useDeleteChoiceSet = (options) => {
    const mutationOptions = getDeleteChoiceSetMutationOptions(options);
    return useMutation(mutationOptions);
};
