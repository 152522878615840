import * as React from "react";
import { Code } from "@mui/icons-material";
import { Chip, Grid, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, } from "@mui/material";
import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import { formatDate_Dayjs, formatNumber } from "@app/util/Utils";
import StreetViewImage from "../../buyer-leads/components/StreetViewImage";
const StyledLightbox = styled(Lightbox) `
  & .yarl_root {
    background: transparent;
  }
  & .yarl__container {
    background: rgba(0, 0, 0, 0.85);
  }
`;
const ListingListItem = (props) => {
    const { address, closeDate, json, listPrice, onMarketDate, photoUrls, salePrice, types } = props;
    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
    const openJson = React.useCallback(() => {
        const tab = window.open("about:blank", "_blank");
        tab.document.write(`<pre>${JSON.stringify(json, null, 2)}</pre>`);
        tab.document.close();
    }, [json]);
    const openLightbox = React.useCallback(() => {
        setIsLightboxOpen(true);
    }, [setIsLightboxOpen]);
    const closeLightbox = React.useCallback(() => {
        setIsLightboxOpen(false);
    }, [setIsLightboxOpen]);
    return (React.createElement(ListItem, null,
        React.createElement(ListItemIcon, null, photoUrls && photoUrls.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement("img", { src: photoUrls[0], alt: address, onClick: openLightbox, style: { cursor: "pointer", height: "40px", width: "40px", borderRadius: "2px" } }),
            React.createElement(StyledLightbox, { open: isLightboxOpen, close: closeLightbox, slides: photoUrls.map((url) => ({ src: url })) }))) : (React.createElement(StreetViewImage, { address: address, ImageProps: { style: { width: "32px", height: "32px", borderRadius: "2px" } } }))),
        React.createElement(ListItemText, { primary: address, secondary: React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
                types && types.length > 0 && (React.createElement(Grid, { item: true, container: true, spacing: 1 }, types.map((type) => (React.createElement(Grid, { key: type, item: true },
                    React.createElement(Chip, { size: "small", label: type })))))),
                onMarketDate && (React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" },
                        "On Market: ",
                        formatDate_Dayjs(onMarketDate, "ddd, MMM D, YYYY")))),
                closeDate && (React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" },
                        "Closed: ",
                        formatDate_Dayjs(closeDate, "ddd, MMM D, YYYY")))),
                listPrice && (React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" },
                        "List Price: ",
                        formatNumber(listPrice, "$0,0")))),
                salePrice && (React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" },
                        "Sale Price: ",
                        formatNumber(salePrice, "$0,0"))))) }),
        json && (React.createElement(ListItemSecondaryAction, null,
            React.createElement(IconButton, { onClick: openJson },
                React.createElement(Code, null))))));
};
export default ListingListItem;
