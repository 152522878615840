import * as React from "react";
import { Delete, PersonAdd } from "@mui/icons-material";
import { Avatar, AvatarGroup, Checkbox, Grid, IconButton, Typography } from "@mui/material";
import omit from "lodash/omit";
import { format } from "timeago.js";
import CoreTextField from "@app/common/CoreTextField";
import UserSelectIconButton from "@app/common/UserSelectIconButton";
import useSession from "@app/hooks/useSession";
import { getInitials } from "@app/util/Utils";
const Task = (props) => {
    var _a;
    const { onChange, onDelete, task } = props;
    const session = useSession();
    const canDeleteTask = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]);
    const taskWrite = React.useMemo(() => {
        return Object.assign(Object.assign({}, omit(task, "uid", "created_at", "archived_at", "assignees", "buyer", "seller", "contact", "recruit", "users")), { assignee_uids: task.assignees.map((x) => x.uid) });
    }, [task]);
    const toggleComplete = React.useCallback((_, checked) => {
        onChange(Object.assign(Object.assign({}, taskWrite), { is_complete: checked }));
    }, [onChange, taskWrite]);
    const setDescription = React.useCallback((val) => {
        onChange(Object.assign(Object.assign({}, taskWrite), { description: val }));
    }, [onChange, taskWrite]);
    const setAssignees = React.useCallback((assigneeUids) => {
        onChange(Object.assign(Object.assign({}, taskWrite), { assignee_uids: assigneeUids.map((x) => x.uid) }));
    }, [onChange, taskWrite]);
    const deleteTask = React.useCallback(() => {
        onDelete();
    }, [onDelete]);
    return (React.createElement(Grid, { container: true, direction: "row", spacing: 1, alignItems: "center" },
        React.createElement(Grid, { item: true },
            React.createElement(Checkbox, { size: "small", color: "primary", checked: task.is_complete, onChange: toggleComplete })),
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(CoreTextField, { size: "small", variant: "standard", fullWidth: true, value: task.description, onTextChange: setDescription, InputProps: {
                    disableUnderline: true,
                } })),
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, task.due_date ? format(task.due_date) : "No due date")),
        React.createElement(Grid, { item: true },
            React.createElement(UserSelectIconButton, { onSelect: setAssignees, selectedUserUids: task.assignees.map((x) => x.uid), IconButtonProps: { tabIndex: -1, style: { padding: 0 } } }, task.assignees.length > 0 ? (React.createElement(AvatarGroup, { max: 3, sx: { "& .MuiAvatar-root": { height: "22px", width: "22px", fontSize: "0.7rem" } } }, task.assignees.map((assignee) => {
                var _a, _b, _c;
                return (React.createElement(Avatar, { key: assignee.uid, src: (_a = assignee.icon_url) !== null && _a !== void 0 ? _a : undefined }, getInitials((_b = assignee.first_name) !== null && _b !== void 0 ? _b : null, (_c = assignee.last_name) !== null && _c !== void 0 ? _c : null) || "?"));
            }))) : (React.createElement(Avatar, { src: undefined, sx: { height: "22px", width: "22px", fontSize: "0.7rem" } },
                React.createElement(PersonAdd, { fontSize: "small" }))))),
        canDeleteTask && (React.createElement(Grid, { item: true },
            React.createElement(IconButton, { size: "small", tabIndex: -1, onClick: deleteTask },
                React.createElement(Delete, { fontSize: "small" }))))));
};
export default Task;
