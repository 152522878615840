/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Delete a template
 */
export const deleteMailschedule4Template = (uid, options) => {
    return axios.delete(`/api/mailschedule4/templates/${uid}`, options);
};
export const getDeleteMailschedule4TemplateMutationOptions = (options) => {
    const mutationKey = ['deleteMailschedule4Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteMailschedule4Template(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a template
*/
export const useDeleteMailschedule4Template = (options) => {
    const mutationOptions = getDeleteMailschedule4TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Duplicate a template
*/
export const duplicateMailschedule4Template = (uid, options) => {
    return axios.post(`/api/mailschedule4/templates/${uid}/duplicate`, undefined, options);
};
export const getDuplicateMailschedule4TemplateMutationOptions = (options) => {
    const mutationKey = ['duplicateMailschedule4Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateMailschedule4Template(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a template
*/
export const useDuplicateMailschedule4Template = (options) => {
    const mutationOptions = getDuplicateMailschedule4TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download Templates as CSV
*/
export const downloadMailschedule4Templates = (params, options) => {
    return axios.get(`/api/mailschedule4/templates/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadMailschedule4TemplatesQueryKey = (params) => {
    return [`/api/mailschedule4/templates/download`, ...(params ? [params] : [])];
};
export const getDownloadMailschedule4TemplatesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadMailschedule4TemplatesQueryKey(params);
    const queryFn = ({ signal }) => downloadMailschedule4Templates(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download Templates as CSV
 */
export function useDownloadMailschedule4Templates(params, options) {
    const queryOptions = getDownloadMailschedule4TemplatesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Re-order templates
 */
export const reorderMailschedule4Templates = (mailSchedule4TemplateReorderRequest, options) => {
    return axios.post(`/api/mailschedule4/templates/reorder`, mailSchedule4TemplateReorderRequest, options);
};
export const getReorderMailschedule4TemplatesMutationOptions = (options) => {
    const mutationKey = ['reorderMailschedule4Templates'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderMailschedule4Templates(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Re-order templates
*/
export const useReorderMailschedule4Templates = (options) => {
    const mutationOptions = getReorderMailschedule4TemplatesMutationOptions(options);
    return useMutation(mutationOptions);
};
