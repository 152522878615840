import { convertAsterisksToNull, focus } from "@app/util/Utils";
import config from "../../../../../../../../config.json";
export const TAB_NAMES = [
    "PSP",
    "Comps",
    "Comps-Finder",
    "Files",
    "Net-Sheet",
    "Offers",
    "Investor",
    "CMA-Comps",
    "Aged-Property-Report",
    "Calculations",
];
/** names of the MAtrix sidebars */
export const MATRIX_SIDEBARS = ["ArcGIS", "PSP Comps", "Comments"];
export const DEFAULT_LABEL_STYLE = { paddingRight: "8px", textAlign: "right" };
// export const PSP_COMP_QUALITY_MAPPING = { 0.5: "Poor", 1: "Good", 1.5: "Best" };
export const PSP_COMP_QUALITY_MAPPING = config.psp_comp_quality_mapping;
/** uids of MAtrices used for testing */
export const TEST_MATRIX_UIDS = [
    "46b6e182fc1949218269068818e92c6b",
    "dbdfcfc7cf154a559c851832eb59d3f2",
    "9bdadb0574f04916ac76b5a033fb9111",
    "0a277dab921140518cb2c4777fdb5e0c",
    "6125584c6349423995302e3312a3acce",
    "d402f6f209b64994a9f3eb1eb5fcf80b",
    "d82056ad9ebc417aa5c1aa876348a828",
];
/** List of field names for fields required to run a psp report */
export const PSP_REPORT_REQUIRED_FIELDS = config.psp_report_required_fields;
/** List of field names for fields required to run a buyer psp report */
export const BUYER_PSP_REPORT_REQUIRED_FIELDS = config.buyer_psp_report_required_fields;
/** List of field names for fields required to run a listing psp report */
export const LISTING_PSP_REPORT_REQUIRED_FIELDS = config.listing_psp_report_required_fields;
/** List of field names for fields required to run a pre-listing presentation */
export const PRE_LISTING_PRESENTATION_REQUIRED_FIELDS = config.pre_listing_presentation_required_fields;
export const onKeyDown = (e) => {
    if (e.key.toLowerCase().trim() === "enter") {
        const currentNode = e.target;
        focus(currentNode, "next");
        e.preventDefault();
    }
    if (e.key.toLowerCase().trim() === "arrowdown") {
        const currentNode = e.target;
        focus(currentNode, "next");
        e.preventDefault();
    }
    if (e.key.toLowerCase().trim() === "arrowup") {
        const currentNode = e.target;
        focus(currentNode, "previous");
        e.preventDefault();
    }
};
export const onFileButtonClick = (inputName) => () => {
    document.getElementById(inputName).click();
};
/** sanitizes field values to make them compatible with our backend */
export const sanitizeFields = (fields) => {
    const sanitizedFields = {};
    Object.keys(fields).forEach((key) => {
        let fieldVal = fields[key];
        if (typeof fieldVal === "number") {
            fieldVal = Math.round(fieldVal);
        }
        sanitizedFields[key] = fieldVal;
    });
    return convertAsterisksToNull(sanitizedFields);
};
/** converts a list of Field Definitions to an Object with Field Names as keys for easy access */
export const getFieldDefinitionsByName = (fieldDefinitions) => {
    if (!fieldDefinitions) {
        return null;
    }
    const fieldDefinitionsByName = {};
    fieldDefinitions.forEach((field) => {
        fieldDefinitionsByName[field.name] = field;
    });
    return fieldDefinitionsByName;
};
