import { doReplacements } from "../../utils/validation";
// eslint-disable-next-line import/prefer-default-export
export const saturateFieldWithValidation = (options) => {
    var _a, _b, _c, _d, _e, _f;
    const { ctx, field, stages, user } = options;
    const context = Object.assign(Object.assign({}, ctx), { USER: (_a = user === null || user === void 0 ? void 0 : user.asDict) !== null && _a !== void 0 ? _a : {}, VALUE: (_b = ctx === null || ctx === void 0 ? void 0 : ctx.fields) === null || _b === void 0 ? void 0 : _b[field.name] });
    let visibleValidationFn = null;
    let visibleValidationFnError = null;
    let visibleValidationResult = true;
    if (((_c = field.visible_formula) === null || _c === void 0 ? void 0 : _c.trim()) && context) {
        try {
            // eslint-disable-next-line no-new-func
            visibleValidationFn = new Function(doReplacements(field.visible_formula, context, stages));
            visibleValidationResult = visibleValidationFn();
        }
        catch (err) {
            visibleValidationFnError = err;
        }
    }
    // console.log({ context, fieldName: field.name, fn: visibleValidationFn?.toString(), visibleValidationResult });
    let editableValidationFn = null;
    let editableValidationFnError = null;
    let editableValidationFnResult = true;
    if (((_d = field.editable_formula) === null || _d === void 0 ? void 0 : _d.trim()) && context) {
        try {
            // eslint-disable-next-line no-new-func
            editableValidationFn = new Function(doReplacements(field.editable_formula, context, stages));
            editableValidationFnResult = editableValidationFn();
        }
        catch (err) {
            editableValidationFnError = err;
        }
    }
    let warnValidationFn = null;
    let warnValidationFnError = null;
    let warnValidationFnResult = null;
    if (((_e = field.warn_validation_formula) === null || _e === void 0 ? void 0 : _e.trim()) && context) {
        try {
            // eslint-disable-next-line no-new-func
            warnValidationFn = new Function(doReplacements(field.warn_validation_formula, context, stages));
            warnValidationFnResult = warnValidationFn();
        }
        catch (err) {
            warnValidationFnError = err;
        }
    }
    let rejectValidationFn = null;
    let rejectValidationFnError = null;
    let rejectValidationFnResult = null;
    if (((_f = field.reject_validation_formula) === null || _f === void 0 ? void 0 : _f.trim()) && context) {
        try {
            // eslint-disable-next-line no-new-func
            rejectValidationFn = new Function(doReplacements(field.reject_validation_formula, context, stages));
            rejectValidationFnResult = rejectValidationFn();
        }
        catch (err) {
            rejectValidationFnError = err;
        }
    }
    return Object.assign(Object.assign({}, field), { visibleValidation: {
            fn: visibleValidationFn,
            error: visibleValidationFnError,
            result: visibleValidationResult,
        }, editableValidation: {
            fn: editableValidationFn,
            error: editableValidationFnError,
            result: editableValidationFnResult,
        }, warnValidation: {
            fn: warnValidationFn,
            error: warnValidationFnError,
            result: warnValidationFnResult,
        }, rejectValidation: {
            fn: rejectValidationFn,
            error: rejectValidationFnError,
            result: rejectValidationFnResult,
        } });
};
