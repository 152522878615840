/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Retrieves data from the PSPs spreadsheet.
 */
export const getPspSpreadsheetData = (options) => {
    return axios.get(`/api/google_sheets/psp_spreadsheet_data`, options);
};
export const getGetPspSpreadsheetDataQueryKey = () => {
    return [`/api/google_sheets/psp_spreadsheet_data`];
};
export const getGetPspSpreadsheetDataQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspSpreadsheetDataQueryKey();
    const queryFn = ({ signal }) => getPspSpreadsheetData(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Retrieves data from the PSPs spreadsheet.
 */
export function useGetPspSpreadsheetData(options) {
    const queryOptions = getGetPspSpreadsheetDataQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
