import * as React from "react";
import { Chip, Grid, Typography } from "@mui/material";
import gmailLogo from "@app/assets/img/gmail.png";
import googleCalendarLogo from "@app/assets/img/google-calendar.png";
import dashLogo from "@app/assets/img/logo-notext.png";
import slackLogo from "@app/assets/img/slack.png";
import streakLogo from "@app/assets/img/streak.png";
import twilioLogo from "@app/assets/img/twilio.png";
import dayjs_ from "@app/common/dayjs";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
const getTemplateLogo = (templateType) => {
    let logo;
    switch (templateType) {
        case "Calendar":
            logo = googleCalendarLogo;
            break;
        case "Email":
        case "Email Draft":
            logo = gmailLogo;
            break;
        case "SMS":
        case "SMS Draft":
            logo = twilioLogo;
            break;
        case "Slack":
            logo = slackLogo;
            break;
        case "Streak Comment":
            logo = streakLogo;
            break;
        default:
            logo = dashLogo;
    }
    return logo;
};
const MailScheduleChronologyItem = (props) => {
    var _a;
    const { template, value } = props;
    const session = useSession();
    const canOpenTemplate = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]);
    const logo = getTemplateLogo(template.template_type);
    return (React.createElement(Grid, { container: true, spacing: 8 },
        React.createElement(Grid, { item: true, style: { paddingTop: "unset", paddingBottom: "unset", marginTop: "auto", marginBottom: "auto" } },
            React.createElement("img", { src: logo, alt: "logo", width: "32px", height: "32px" })),
        React.createElement(Grid, { item: true, style: {
                flex: 1,
                borderRadius: "0.25rem",
                border: "1px solid #ccc",
                textDecoration: "unset",
                color: "unset",
                padding: "0.5rem",
                marginLeft: "0.75rem",
                marginRight: "-0.25rem",
            }, 
            // @ts-expect-error: no overload matches this call
            component: canOpenTemplate ? RouterLink : undefined, to: canOpenTemplate ? `/ms4/templates/${template.uid}` : undefined },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, container: true, xs: 12 },
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, template.name)),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "caption", style: { color: "#ccc" } }, dayjs_(new Date(value.timestamp)).format("M/D/YYYY h:mm A")))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Chip, { size: "small", label: value.status }))))));
};
export default MailScheduleChronologyItem;
