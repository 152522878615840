import * as React from "react";
import { Grid2, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import zhlIcon from "@app/assets/img/zillow-home-loans.png";
import ActionDialog from "@app/common/ActionDialog";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useCreateZhlEngagedTransferSubmission, useListZhlEngagedTransferStates } from "@app/orval/api/zhl-etr";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
/**
 * ZHL Loan Officer Dialog
 * https://dashcarolina.atlassian.net/jira/core/projects/DC/board?selectedIssue=DC-371
 *
 * @returns
 */
const SendZHLEngagedTransferDialog = (props) => {
    var _a, _b, _c;
    const { DialogProps, buyerLead, onClose, onSubmitComplete } = props;
    const getStatesApi = useListZhlEngagedTransferStates(INFINITE_CACHE_PARAMS);
    const states = (_b = (_a = getStatesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : [];
    const [formData, setFormData] = React.useState({
        buyer_lead_uid: buyerLead.uid,
        notes: "",
        state: null,
    });
    React.useEffect(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { buyer_lead_uid: buyerLead.uid })));
    }, [buyerLead]);
    const isValid = React.useMemo(() => {
        return formData.state != null && formData.notes.trim() !== "";
    }, [formData]);
    const submitApi = useCreateZhlEngagedTransferSubmission();
    const submit = React.useCallback(() => {
        const loadingSnackbar = enqueueSnackbar("Sending ZHL ETR...", { variant: "info", persist: true });
        submitApi
            .mutateAsync({
            data: {
                buyer_lead_uid: formData.buyer_lead_uid,
                notes: formData.notes,
                state: formData.state,
            },
        })
            .then((result) => {
            enqueueSnackbar(`ZHL ETR sent successfully for ${buyerLead.name}`, { variant: "success" });
            onSubmitComplete(result.data);
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(loadingSnackbar);
        });
    }, [buyerLead.name, formData.buyer_lead_uid, formData.notes, formData.state, onSubmitComplete, submitApi]);
    const setNotes = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { notes: e.target.value })));
    }, [setFormData]);
    const setState = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { state: e.target.value ? e.target.value : null })));
    }, [setFormData]);
    return (React.createElement(ActionDialog, { DialogProps: DialogProps, isConfirmButtonDisabled: !isValid || submitApi.isPending, isCancelButtonDisabled: submitApi.isPending, confirmButtonText: "Submit", confirmButtonProps: {
            startIcon: React.createElement("img", { src: zhlIcon, alt: "ZHL", style: { height: 24 } }),
        }, onConfirm: submit, onCancel: onClose, title: "Send ZHL Engaged Transfer" },
        React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
            React.createElement(Grid2, null,
                React.createElement(Grid2, { container: true, spacing: 1 },
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "ZHL LO Name:")),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body1" }, buyerLead.fields["Select ZHL LO"])))),
            React.createElement(Grid2, null,
                React.createElement(TextField, { size: "small", fullWidth: true, multiline: true, minRows: 4, maxRows: 8, slotProps: {
                        input: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Notes"),
                        },
                    }, value: formData.notes, onChange: setNotes }, formData.notes)),
            React.createElement(Grid2, null,
                React.createElement(TextField, { size: "small", fullWidth: true, select: true, slotProps: {
                        input: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "State"),
                        },
                        select: {
                            displayEmpty: true,
                        },
                    }, value: (_c = formData.state) !== null && _c !== void 0 ? _c : "", onChange: setState },
                    React.createElement(MenuItem, { value: "" }),
                    states.map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))))));
};
export default SendZHLEngagedTransferDialog;
