import * as React from "react";
import { Launch } from "@mui/icons-material";
import { Grid2, Tooltip, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import styled from "styled-components";
import { format } from "timeago.js";
import { shadeBlendConvert } from "@app/entrypoints/brokerage/features/crm/components/spreadsheet-view/utils";
import { formatDateMemoized_Dayjs } from "@app/util/Utils";
import CancelMessageIconButton from "./CancelMessageIconButton";
import MediaItem from "./MediaItem";
import MessageAvatar from "./MessageAvatar";
import OpenMessageIconButton from "./OpenMessageIconButton";
const GridHoverWrapper = styled(Grid2) `
  & a.MuiIconButton-root {
    display: none;
  }
  &:hover a.MuiIconButton-root {
    display: unset;
  }
`;
const MY_PHONE_NUMBER_COLOR = "#aef5a6";
const WHITE_COLOR = "#ffffff";
const ConversationMessage = (props) => {
    const { message, onMessageCanceled, timestampFormatting = "short" } = props;
    const theme = useTheme();
    const { mode } = theme.palette;
    const handleMessageCancelled = React.useCallback(() => {
        onMessageCanceled(props.message.sid);
    }, [onMessageCanceled, props.message]);
    const isInbound = typeof message !== "string" && message.direction === "inbound";
    const body = typeof message === "string" ? message : message.body;
    const dateCreated = typeof message === "string" ? dayjs(new Date().toISOString()).toDate() : dayjs(message.date_created).toDate();
    const dateSent = typeof message === "string"
        ? dayjs(new Date().toISOString()).toDate()
        : message.date_sent
            ? dayjs(message.date_sent).toDate()
            : null;
    const sentBy = typeof message !== "string" && message.sent_by;
    const isTemporary = typeof message === "string";
    const isAutoReply = typeof message !== "string" && message.direction === "outbound-reply";
    const isScheduled = typeof message !== "string" && message.status === "scheduled" && message.send_at;
    const isError = typeof message !== "string" && message.error_code;
    let color = "#ccc";
    if (isError) {
        color = "#f8d7da";
    }
    else if (isScheduled) {
        color = "#f7f7d0";
    }
    else if (isAutoReply) {
        color = shadeBlendConvert(0.6, MY_PHONE_NUMBER_COLOR, WHITE_COLOR);
    }
    else if (!isInbound) {
        color = shadeBlendConvert(0.4, MY_PHONE_NUMBER_COLOR, WHITE_COLOR);
    }
    const openMessageButton = typeof message !== "string" && (React.createElement(OpenMessageIconButton, { message: message, IconButtonProps: { disableRipple: true } },
        React.createElement(Launch, { fontSize: "small", style: { transform: "translateY(3px)" } })));
    const scheduledMessageButton = isScheduled && (React.createElement(CancelMessageIconButton, { message: message, onMessageCancelled: handleMessageCancelled }));
    return (React.createElement(Grid2, { container: true, style: {
            maxWidth: "90%",
            float: isInbound ? "left" : "right",
            borderLeft: isError && isInbound ? "4px solid red" : undefined,
            borderRight: isError && !isInbound ? "4px solid red" : undefined,
            paddingLeft: isError && isInbound ? "4px" : undefined,
            paddingRight: isError && !isInbound ? "4px" : undefined,
        }, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(GridHoverWrapper, { container: true, spacing: 1, wrap: "nowrap" },
                !isInbound && React.createElement(Grid2, { style: { flex: 1 } }),
                !isInbound && typeof message !== "string" && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto", minWidth: "22px" } }, openMessageButton)),
                !isInbound && scheduledMessageButton && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto", minWidth: "22px" } }, scheduledMessageButton)),
                !isInbound && sentBy && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto", minWidth: "22px" } },
                    React.createElement(MessageAvatar, { user: sentBy }))),
                React.createElement(Grid2, null,
                    React.createElement(Tooltip, { title: isError ? (React.createElement(Typography, null, isInbound
                            ? "An error occurred processing this message"
                            : "This message may have not been delivered successfully")) : undefined },
                        React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 },
                            body.trim() && (React.createElement(Grid2, null,
                                React.createElement("div", { style: {
                                        opacity: isTemporary ? 0.5 : undefined,
                                        background: color,
                                        borderRadius: "0.5rem",
                                        display: "inline-block",
                                        float: isInbound ? "left" : "right",
                                        fontStyle: isAutoReply ? "italic" : undefined,
                                        maxWidth: "100%",
                                    } },
                                    React.createElement(Typography, { variant: "body2", style: { padding: "0.5rem", color: "rgba(0,0,0,0.87)" } },
                                        React.createElement("pre", { style: {
                                                fontFamily: "inherit",
                                                margin: 0,
                                                whiteSpace: "pre-wrap",
                                                overflow: "hidden",
                                            } }, body))))),
                            typeof message !== "string" &&
                                message.media.map((mediaItem) => (React.createElement(Grid2, { key: mediaItem.sid },
                                    React.createElement(MediaItem, { mediaItem: mediaItem }))))))),
                isInbound && sentBy && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto", minWidth: "22px" } },
                    React.createElement(MessageAvatar, { user: sentBy }))),
                isInbound && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto", minWidth: "22px" } }, openMessageButton)),
                isInbound && React.createElement(Grid2, { style: { flex: 1 } }))),
        !isScheduled && (React.createElement(Grid2, null,
            React.createElement(Tooltip, { title: timestampFormatting === "full" ? undefined : (React.createElement(Typography, null, formatDateMemoized_Dayjs((dateSent !== null && dateSent !== void 0 ? dateSent : dateCreated).toISOString(), "ddd MMMM D YYYY h:mm A"))) },
                React.createElement(Typography, { variant: "caption", style: { color: mode === "light" ? "#888" : "#ccc", float: isInbound ? "left" : "right" } }, timestampFormatting === "full"
                    ? formatDateMemoized_Dayjs((dateSent !== null && dateSent !== void 0 ? dateSent : dateCreated).toISOString(), "M/D/YYYY h:mm A")
                    : format(dayjs.utc(dateCreated).toDate()))))),
        typeof message !== "string" && message.sent_by && (React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "caption", style: {
                    color: "#aaa",
                    float: isInbound ? "left" : "right",
                    transform: "translateY(-5px)",
                } },
                "Sent by ",
                [message.sent_by.first_name, message.sent_by.last_name].filter((o) => o.trim()).join(" "))))));
};
export default ConversationMessage;
