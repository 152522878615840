import * as React from "react";
import { Person } from "@mui/icons-material";
import { Grid2, InputAdornment, MenuItem, TextField } from "@mui/material";
import logo from "@app/assets/img/logo-notext.png";
import TwilioSMSRecipientSelect from "@app/entrypoints/brokerage/context/twilio-sms/TwilioSMSRecipientSelect";
const ThreadSearch = (props) => {
    var _a, _b;
    const { onSearch, selectedExternalPhoneNumber, selectedTwilioPhoneNumber, twilioPhoneNumbers } = props;
    const [twilioPhoneNumber, setTwilioPhoneNumber] = React.useState(selectedTwilioPhoneNumber);
    const [externalPhoneNumber, setExternalPhoneNumber] = React.useState(selectedExternalPhoneNumber);
    React.useEffect(() => {
        if (selectedTwilioPhoneNumber !== twilioPhoneNumber) {
            setTwilioPhoneNumber(selectedTwilioPhoneNumber);
        }
        if (selectedExternalPhoneNumber !== externalPhoneNumber) {
            setExternalPhoneNumber(selectedExternalPhoneNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTwilioPhoneNumber, selectedExternalPhoneNumber]);
    const search = React.useCallback(() => {
        if (externalPhoneNumber !== selectedExternalPhoneNumber || twilioPhoneNumber !== selectedTwilioPhoneNumber) {
            onSearch(twilioPhoneNumber, externalPhoneNumber);
        }
    }, [externalPhoneNumber, selectedExternalPhoneNumber, twilioPhoneNumber, selectedTwilioPhoneNumber, onSearch]);
    const handleTwilioPhoneNumberChanged = React.useCallback((e) => {
        setTwilioPhoneNumber(e.target.value);
    }, []);
    const handleExternalPhoneNumberChanged = React.useCallback((value) => {
        setExternalPhoneNumber(value);
    }, []);
    React.useEffect(() => {
        search();
    }, [twilioPhoneNumber, search]);
    return (React.createElement(Grid2, { container: true, spacing: 1 },
        React.createElement(Grid2, { size: { xs: 12, md: 6 } },
            React.createElement(TextField, { size: "small", variant: "outlined", value: (_a = twilioPhoneNumber !== null && twilioPhoneNumber !== void 0 ? twilioPhoneNumber : props.selectedTwilioPhoneNumber) !== null && _a !== void 0 ? _a : "", onChange: handleTwilioPhoneNumberChanged, select: true, fullWidth: true, slotProps: {
                    input: {
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement("img", { src: logo, alt: "DASH Realty", style: { height: "20px" } }))),
                    },
                } },
                React.createElement(MenuItem, { value: "" }, "Any Twilio phone"),
                (twilioPhoneNumbers !== null && twilioPhoneNumbers !== void 0 ? twilioPhoneNumbers : []).map((phoneNumber) => (React.createElement(MenuItem, { key: phoneNumber.phone_number, value: phoneNumber.phone_number }, phoneNumber.friendly_name || phoneNumber.phone_number))))),
        React.createElement(Grid2, { size: { xs: 12, md: 6 } },
            React.createElement(TwilioSMSRecipientSelect, { phoneNumber: (_b = externalPhoneNumber !== null && externalPhoneNumber !== void 0 ? externalPhoneNumber : selectedExternalPhoneNumber) !== null && _b !== void 0 ? _b : null, onPhoneNumberChanged: handleExternalPhoneNumberChanged, TextFieldProps: {
                    slotProps: {
                        input: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement(Person, null))),
                        },
                    },
                } }))));
};
export default ThreadSearch;
