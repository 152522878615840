/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 *
This is a wrapper around the Bridge API for the triangle dataset;
our access token is just baked into the query. You can construct queries just
like you would if hiting the Bridge API directly, ex:

_/api/bridge/triangle?MlsStatus=ACTIVE&DaysOnMarket=0&YearBuilt.gt=1979&YearBuilt.lt=2021&LivingArea.gt=1199&LivingArea.lt=2751&PropertyType=Residential&BedroomsTotal.gt=1&BedroomsTotal.lt=6&((PropertySubType=Attached) or (PropertySubType=Detached))&((PoolFeatures=null) or (PoolFeatures/all(a: a ne 'In Ground') and PoolFeatures/all(a: a ne 'Above Ground'))) and ((City eq 'Raleigh') or (City eq 'Durham') or (City eq 'Cary') or (City eq 'Morrisville') or (City eq 'Wake Forest') or (City eq 'Knightdale') or (City eq 'Wendell') or (City eq 'Zebulon') or (City eq 'Clayton') or (City eq 'Holly Springs') or (City eq 'Garner') or (City eq 'Fuquay Varina') or (City eq 'Chapel Hill') or (City eq 'Carrboro') or (City eq 'Hillsborough') or (City eq 'Youngsville'))_

Be sure to include the dataset ID in the query path, which can be one of the following:
 - `triangle` Triangle MLS
 - `triadmls` Triad MLS
 - `zillow` Zillow dataset

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a list of properties from Bridge Web API
 */
export const getBridgePropertyData = (dataset, options) => {
    return axios.get(`/api/bridge/${dataset}`, options);
};
export const getGetBridgePropertyDataQueryKey = (dataset) => {
    return [`/api/bridge/${dataset}`];
};
export const getGetBridgePropertyDataQueryOptions = (dataset, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBridgePropertyDataQueryKey(dataset);
    const queryFn = ({ signal }) => getBridgePropertyData(dataset, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(dataset) }, queryOptions);
};
/**
 * @summary Get a list of properties from Bridge Web API
 */
export function useGetBridgePropertyData(dataset, options) {
    const queryOptions = getGetBridgePropertyDataQueryOptions(dataset, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Creates a query then returns data for the given Bridge dataset. Uses the Bridge RESO Web API.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List properties from the given dataset
 */
export const listBridgeProperties = (dataset, params, options) => {
    return axios.get(`/api/bridge/${dataset}/list`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListBridgePropertiesQueryKey = (dataset, params) => {
    return [`/api/bridge/${dataset}/list`, ...(params ? [params] : [])];
};
export const getListBridgePropertiesQueryOptions = (dataset, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListBridgePropertiesQueryKey(dataset, params);
    const queryFn = ({ signal }) => listBridgeProperties(dataset, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(dataset) }, queryOptions);
};
/**
 * @summary List properties from the given dataset
 */
export function useListBridgeProperties(dataset, params, options) {
    const queryOptions = getListBridgePropertiesQueryOptions(dataset, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get the listings for an agent by the Agent's MLS Id.
 */
export const getBridgePropertyDataForAgent = (dataset, agentMlsId, options) => {
    return axios.get(`/api/bridge/${dataset}/list_for_agent/${agentMlsId}`, options);
};
export const getGetBridgePropertyDataForAgentQueryKey = (dataset, agentMlsId) => {
    return [`/api/bridge/${dataset}/list_for_agent/${agentMlsId}`];
};
export const getGetBridgePropertyDataForAgentQueryOptions = (dataset, agentMlsId, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBridgePropertyDataForAgentQueryKey(dataset, agentMlsId);
    const queryFn = ({ signal }) => getBridgePropertyDataForAgent(dataset, agentMlsId, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(dataset && agentMlsId) }, queryOptions);
};
/**
 * @summary Get the listings for an agent by the Agent's MLS Id.
 */
export function useGetBridgePropertyDataForAgent(dataset, agentMlsId, options) {
    const queryOptions = getGetBridgePropertyDataForAgentQueryOptions(dataset, agentMlsId, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 *
This is a wrapper around the Bridge API for the triangle dataset;
our access token is just baked into the query. You can construct queries just
like you would if hiting the Bridge API directly, ex:

_/api/bridge_reso/triangle?$filter=MlsStatus eq 'ACTIVE' and DaysOnMarket eq 0 and YearBuilt gt 1979 and YearBuilt lt 2021 and LivingArea gt 1199 and LivingArea lt 2751 and PropertyType eq 'Residential' and BedroomsTotal gt 1 and BedroomsTotal lt 6 and ((PropertySubType eq 'Attached') or (PropertySubType eq 'Detached')) and ((PoolFeatures eq null) or (PoolFeatures/all(a: a ne 'In Ground') and PoolFeatures/all(a: a ne 'Above Ground'))) and ((City eq 'Raleigh') or (City eq 'Durham') or (City eq 'Cary') or (City eq 'Morrisville') or (City eq 'Wake Forest') or (City eq 'Knightdale') or (City eq 'Wendell') or (City eq 'Zebulon') or (City eq 'Clayton') or (City eq 'Holly Springs') or (City eq 'Garner') or (City eq 'Fuquay Varina') or (City eq 'Chapel Hill') or (City eq 'Carrboro') or (City eq 'Hillsborough') or (City eq 'Youngsville'))_

Be sure to include the dataset ID in the query path, which can be one of the following:
 - `triangle` Triangle MLS
 - `triadmls` Triad MLS
 - `zillow` Zillow dataset

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a list of properties from Bridge RESO Web API
 */
export const searchBridgeListingsReso = (dataset, params, options) => {
    return axios.get(`/api/bridge_reso/${dataset}`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getSearchBridgeListingsResoQueryKey = (dataset, params) => {
    return [`/api/bridge_reso/${dataset}`, ...(params ? [params] : [])];
};
export const getSearchBridgeListingsResoQueryOptions = (dataset, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchBridgeListingsResoQueryKey(dataset, params);
    const queryFn = ({ signal }) => searchBridgeListingsReso(dataset, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(dataset) }, queryOptions);
};
/**
 * @summary Get a list of properties from Bridge RESO Web API
 */
export function useSearchBridgeListingsReso(dataset, params, options) {
    const queryOptions = getSearchBridgeListingsResoQueryOptions(dataset, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get the Bridge API response for a property address
 */
export const searchBridgeApiByAddress = (dataset, params, options) => {
    return axios.get(`/api/utilities/bridge/${dataset}`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getSearchBridgeApiByAddressQueryKey = (dataset, params) => {
    return [`/api/utilities/bridge/${dataset}`, ...(params ? [params] : [])];
};
export const getSearchBridgeApiByAddressQueryOptions = (dataset, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchBridgeApiByAddressQueryKey(dataset, params);
    const queryFn = ({ signal }) => searchBridgeApiByAddress(dataset, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(dataset) }, queryOptions);
};
/**
 * @summary Get the Bridge API response for a property address
 */
export function useSearchBridgeApiByAddress(dataset, params, options) {
    const queryOptions = getSearchBridgeApiByAddressQueryOptions(dataset, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get the zestimate for a property address.
 */
export const getBridgeZestimate = (params, options) => {
    return axios.get(`/api/utilities/zestimate`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetBridgeZestimateQueryKey = (params) => {
    return [`/api/utilities/zestimate`, ...(params ? [params] : [])];
};
export const getGetBridgeZestimateQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBridgeZestimateQueryKey(params);
    const queryFn = ({ signal }) => getBridgeZestimate(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the zestimate for a property address.
 */
export function useGetBridgeZestimate(params, options) {
    const queryOptions = getGetBridgeZestimateQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
