import * as React from "react";
import { AddOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useSession from "@app/hooks/useSession";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { countContacts, countContactsByVendorType, downloadContacts, searchContacts, useBulkDeleteContacts, useCreateContact, } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { StandardSmallCell } from "../components/spreadsheet-view/components";
import Spreadsheet from "../components/spreadsheet-view/Spreadsheet";
import { SpreadsheetContext } from "../components/spreadsheet-view/SpreadsheetContext";
import { renderRelationshipsCell } from "../components/spreadsheet-view/table-cells";
import { groupBy, indexBy } from "../utils/utils";
const Contacts = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const status = props.status || undefined;
    const vendorType = props.vendorType || undefined;
    const limit = (_a = props.limit) !== null && _a !== void 0 ? _a : 50;
    const offset = (_b = props.offset) !== null && _b !== void 0 ? _b : 0;
    const showNewsfeed = (_c = props.showNewsfeed) !== null && _c !== void 0 ? _c : false;
    const { search, view } = props;
    const session = useSession();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const updateSearch = useUpdateSearch();
    const context = React.useContext(SpreadsheetContext);
    const bulkDeleteApi = useBulkDeleteContacts();
    const requestData = React.useMemo(() => {
        var _a;
        return pickBy({
            search,
            view,
            statuses: status ? [status] : undefined,
            vendor_type: vendorType,
            filter: (_a = context === null || context === void 0 ? void 0 : context.filter) !== null && _a !== void 0 ? _a : undefined,
        }, (val) => val !== undefined);
    }, [context === null || context === void 0 ? void 0 : context.filter, search, status, vendorType, view]);
    const paginatedRequestData = React.useMemo(() => {
        return Object.assign(Object.assign({}, requestData), { limit, offset });
    }, [requestData, limit, offset]);
    const searchContactsApi = usePostQuery(searchContacts, paginatedRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const items = (_e = (_d = searchContactsApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const countItemsApi = usePostQuery(countContacts, requestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const count = (_g = (_f = countItemsApi.data) === null || _f === void 0 ? void 0 : _f.data.count) !== null && _g !== void 0 ? _g : null;
    const countBuyersApi = usePostQuery(countContacts, omit(Object.assign(Object.assign({}, requestData), { statuses: ["buyer"] }), "vendor_type"), FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const numBuyers = (_j = (_h = countBuyersApi.data) === null || _h === void 0 ? void 0 : _h.data.count) !== null && _j !== void 0 ? _j : null;
    const countSellersApi = usePostQuery(countContacts, omit(Object.assign(Object.assign({}, requestData), { statuses: ["seller"] }), "vendor_type"), FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const numSellers = (_l = (_k = countSellersApi.data) === null || _k === void 0 ? void 0 : _k.data.count) !== null && _l !== void 0 ? _l : null;
    const totalItemsApi = usePostQuery(countContacts, {}, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const total = (_o = (_m = totalItemsApi.data) === null || _m === void 0 ? void 0 : _m.data.count) !== null && _o !== void 0 ? _o : null;
    const countByVendorTypeApi = usePostQuery(countContactsByVendorType, omit(requestData, "statuses", "vendor_type"), FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const countByVendorType = (_q = (_p = countByVendorTypeApi.data) === null || _p === void 0 ? void 0 : _p.data) !== null && _q !== void 0 ? _q : null;
    const countByGroup = React.useMemo(() => {
        const buyerGroup = numBuyers != null
            ? {
                group: {
                    uid: "buyer",
                    name: "Buyers",
                    onClick: () => {
                        updateSearch("status", status === "buyer" ? null : "buyer", "vendor_type", null, "offset", null);
                    },
                    isSelected: status === "buyer",
                },
                count: numBuyers,
            }
            : null;
        const sellerGroup = numSellers != null
            ? {
                group: {
                    uid: "seller",
                    name: "Sellers",
                    onClick: () => {
                        updateSearch("status", status === "seller" ? null : "seller", "vendor_type", null, "offset", null);
                    },
                    isSelected: status === "seller",
                },
                count: numSellers,
            }
            : null;
        const vendorGroups = countByVendorType
            ? countByVendorType
                .filter((x) => x.group)
                .map((x) => ({
                group: {
                    uid: x.group,
                    name: x.group,
                    onClick: () => {
                        updateSearch("status", null, "vendor_type", x.group === vendorType ? null : x.group, "offset", null);
                    },
                    isSelected: vendorType === x.group,
                },
                count: x.count,
            }))
            : null;
        const res = [...(buyerGroup ? [buyerGroup] : []), ...(sellerGroup ? [sellerGroup] : []), ...(vendorGroups !== null && vendorGroups !== void 0 ? vendorGroups : [])];
        return res;
    }, [countByVendorType, numBuyers, numSellers, status, updateSearch, vendorType]);
    const listFieldsApi = useListFields({ entity_type: "Contact" }, REFETCH_CACHE_PARAMS);
    const fields = (_s = (_r = listFieldsApi.data) === null || _r === void 0 ? void 0 : _r.data) !== null && _s !== void 0 ? _s : null;
    const itemsByUid = React.useMemo(() => {
        return items ? groupBy(items, "uid") : null;
    }, [items]);
    const itemsIndexedByUid = React.useMemo(() => {
        return items ? indexBy(items, "uid") : null;
    }, [items]);
    const refresh = React.useCallback(() => {
        searchContactsApi.refetch();
    }, [searchContactsApi]);
    const getPOSTApiEndpoint = React.useCallback((row) => {
        return `/api/contacts/${row.uid}`;
    }, []);
    const getMailMergeEmailAddressesForItem = React.useCallback((row) => {
        return [row.fields["Email"]].filter((x) => x).map((x) => x);
    }, []);
    const getDetailURL = React.useCallback((row) => {
        const item = itemsByUid ? itemsByUid[row] : null;
        if (item) {
            return `/contacts/${item.uid}`;
        }
        return "";
    }, [itemsByUid]);
    const nonStandardColumns = [
        {
            field: "relationships",
            headerName: "Related Contacts",
            renderCell: renderRelationshipsCell,
            editable: false,
        },
    ];
    const onFieldChanged = React.useCallback((params) => {
        const { field, row, value } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchContactsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [
                    ...prev.data.slice(0, fullIndx),
                    Object.assign(Object.assign({}, prev.data[fullIndx]), { fields: Object.assign(Object.assign({}, prev.data[fullIndx].fields), { [field]: value }) }),
                    ...prev.data.slice(fullIndx + 1, prev.data.length),
                ] });
        });
    }, [itemsIndexedByUid, queryClient, searchContactsApi.queryKey]);
    const onFieldWillSave = onFieldChanged;
    const onFieldSaved = React.useCallback((params) => {
        const { response, row } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchContactsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [...prev.data.slice(0, fullIndx), response.data, ...prev.data.slice(fullIndx + 1, prev.data.length)] });
        });
    }, [itemsIndexedByUid, queryClient, searchContactsApi.queryKey]);
    const page = React.useMemo(() => {
        return Math.floor(offset / limit) + 1;
    }, [offset, limit]);
    const renderListItem = React.useCallback((item) => {
        var _a;
        return (React.createElement(StandardSmallCell, { name: item.name, stage: undefined, 
            // @ts-expect-error: readonly
            collaborators: item.collaborators, link: `/contacts/${item.uid}`, allLinks: (_a = items === null || items === void 0 ? void 0 : items.map((x) => `/contacts/${x.uid}`)) !== null && _a !== void 0 ? _a : [] }));
    }, [items]);
    const createItemApi = useCreateContact();
    const createItem = React.useCallback(() => {
        createItemApi
            .mutateAsync({
            data: {
                fields: {},
            },
        })
            .then((result) => {
            navigate(`/contacts/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [createItemApi, navigate]);
    const bulkDelete = React.useCallback((selectedUids) => {
        queryClient.setQueryData(searchContactsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev === null || prev === void 0 ? void 0 : prev.data.filter((item) => !selectedUids.includes(item.uid)) });
        });
        queryClient.setQueryData(countItemsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        queryClient.setQueryData(totalItemsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        bulkDeleteApi
            .mutateAsync({ data: { uids: selectedUids } })
            .then(() => {
            enqueueSnackbar(`${selectedUids.length} buyer leads deleted`, { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [bulkDeleteApi, countItemsApi.queryKey, queryClient, searchContactsApi.queryKey, totalItemsApi.queryKey]);
    return (React.createElement(Spreadsheet, { title: "Contacts", fields: fields, view: view, countByGroup: countByGroup, items: items, search: search, nonStandardColumns: nonStandardColumns, getPOSTApiEndpoint: getPOSTApiEndpoint, getMailMergeEmailAddressesForItem: getMailMergeEmailAddressesForItem, getDetailURL: getDetailURL, entityType: "Contact", rowsPerPage: limit, count: count !== null && count !== void 0 ? count : null, total: total !== null && total !== void 0 ? total : null, page: page, onRefresh: refresh, onFieldChanged: onFieldChanged, onFieldWillSave: onFieldWillSave, onFieldSaved: onFieldSaved, renderListItem: renderListItem, canEditStages: false, includeOpenColumn: (_t = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _t === void 0 ? void 0 : _t.hasRole(["Superadmin", "Admin"]), includeStageColumn: false, downloadFunc: downloadContacts, downloadFilename: "contacts.csv", loadingPercentComplete: undefined, isLoading: searchContactsApi.isFetching, showNewsfeed: showNewsfeed, slots: {
            addButton: ((_u = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _u === void 0 ? void 0 : _u.hasRole(["Superadmin", "Admin"])) ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add a new contact") },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: createItem, tabIndex: -1, disabled: createItemApi.isPending, color: "primary" },
                        React.createElement(AddOutlined, null))))) : undefined,
        }, error: searchContactsApi.error ||
            countItemsApi.error ||
            countBuyersApi.error ||
            countSellersApi.error ||
            countByVendorTypeApi.error, onBulkDelete: ((_v = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _v === void 0 ? void 0 : _v.hasRole(["Superadmin", "Admin"])) ? bulkDelete : undefined }));
};
export default Contacts;
