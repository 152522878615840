import * as React from "react";
import { Send } from "@mui/icons-material";
import { Alert, Button, Grid } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import QRCode from "react-qr-code";
import CoreLoading from "@app/common/CoreLoading";
import { useGetVcardForUser } from "@app/orval/api/users";
import SendVCardDialog from "./SendVCardDialog";
const VCardQRCode = (props) => {
    var _a, _b;
    const { includeSendButton = true, userUid } = props;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const getVCardForUserApi = useGetVcardForUser(userUid, { include_headshot: false });
    const vCard = (_b = (_a = getVCardForUserApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const openDialog = React.useCallback(() => {
        setIsDialogOpen(true);
    }, [setIsDialogOpen]);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);
    if (getVCardForUserApi.error) {
        return React.createElement(Alert, { severity: "error" }, format(JSON.stringify(getVCardForUserApi.error)));
    }
    if (getVCardForUserApi.isFetching) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
            React.createElement(Grid, { item: true },
                React.createElement(Alert, { severity: "info" }, "Due to size restrictions of QR codes, the agent's headshot will not be included in the vCard if you scan the QR code. If you want the headshot, try sending the vCard to a phone number instead.")),
            React.createElement(Grid, { item: true, style: { textAlign: "center" } },
                React.createElement(QRCode, { value: vCard.content })),
            React.createElement(Grid, { item: true },
                React.createElement(Button, { size: "small", startIcon: React.createElement(Send, { fontSize: "small" }), variant: "outlined", onClick: openDialog }, "Send vCard..."))),
        isDialogOpen && includeSendButton && (React.createElement(SendVCardDialog, { DialogProps: { open: true }, onClose: closeDialog, userUid: userUid }))));
};
export default VCardQRCode;
