import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid2, InputAdornment, Typography, RadioGroup, FormControlLabel, Radio, Box, } from "@mui/material";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import { getContact } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AgentMenuItem from "../../buyer-leads/components/AgentMenuItem";
import { NOT_DUPLICATE_CONTACT_FILTER } from "../../buyer-leads/components/utils";
import ContactAutocomplete from "../../components/box-view/actions/ContactAutocomplete";
const DEFAULT_SHOWING = {
    showing_type: "Private Showing",
    datetime: null,
    notes: null,
    agent_uids: [],
};
const showingTypes = ["Private Showing", "Open House"]; // TODO: pull from API
const ShowingsDialog = (props) => {
    var _a, _b, _c, _d, _e;
    const { DialogProps, onClose, onUpdate, showing } = props;
    const [initialData, setInitialData] = React.useState(DEFAULT_SHOWING);
    const [formData, setFormData] = React.useState(DEFAULT_SHOWING);
    const [selectedContact, setSelectedContact] = React.useState(null);
    React.useEffect(() => {
        var _a;
        if ((_a = showing === null || showing === void 0 ? void 0 : showing.contact) === null || _a === void 0 ? void 0 : _a.uid) {
            getContact(showing.contact.uid).then((contact) => {
                setSelectedContact(contact.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const defaultThirdPartyFields = React.useMemo(() => {
        if (!(showing === null || showing === void 0 ? void 0 : showing.contact)) {
            return null;
        }
        return {
            "First Name": showing.contact.first_name,
            "Last Name": showing.contact.last_name,
            Email: showing.contact.email,
            Phone: showing.contact.phone,
            Company: showing.contact.company,
        };
    }, [showing === null || showing === void 0 ? void 0 : showing.contact]);
    const defaultThirdPartyParams = React.useMemo(() => {
        var _a, _b;
        return {
            contact_uid: (_b = (_a = showing === null || showing === void 0 ? void 0 : showing.contact) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : null,
            contact_data: defaultThirdPartyFields ? { fields: defaultThirdPartyFields } : null,
            type: "Buyer Agent",
            notes: null,
            // associated_with_contracts: showing?.contact?.associated_with_contracts ?? [],
        };
    }, [showing, defaultThirdPartyFields]);
    const [thirdPartyParams, setThirdPartyParams] = React.useState(defaultThirdPartyParams);
    const [thirdPartyUpdateMode, setThirdPartyUpdateMode] = React.useState("select-existing");
    const listUsersApi = useListUsers({}, REFETCH_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listContactFieldsApi = useListFields({ entity_type: "Contact" }, REFETCH_CACHE_PARAMS);
    const contactFields = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listContactFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_c = listContactFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data]);
    React.useEffect(() => {
        const data = showing
            ? {
                showing_type: showing.showing_type,
                datetime: showing.datetime,
                notes: showing.notes,
                agent_uids: showing.agents.map((x) => x.uid),
            }
            : DEFAULT_SHOWING;
        setFormData(data);
        setInitialData(data);
    }, [showing]);
    const setShowingType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { showing_type: e.target.value })));
    }, [setFormData]);
    const setDateTime = React.useCallback((dt) => {
        console.log("TEST: set showing date:", dt);
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { datetime: dt })));
    }, [setFormData]);
    const setAgents = React.useCallback((agentUids) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { agent_uids: agentUids })));
    }, [setFormData]);
    const setNotes = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { notes: e.target.value })));
    }, [setFormData]);
    const handleSaveClicked = React.useCallback(() => {
        var _a, _b;
        const contactUid = (_a = thirdPartyParams.contact_uid) !== null && _a !== void 0 ? _a : undefined;
        onUpdate({
            showing_type: formData.showing_type,
            datetime: formData.datetime,
            agent_uids: formData.agent_uids,
            notes: formData.notes,
            contact_uid: thirdPartyUpdateMode === "select-existing" ? contactUid : undefined,
            contact_data: (_b = thirdPartyParams.contact_data) !== null && _b !== void 0 ? _b : undefined,
        });
    }, [onUpdate, formData, thirdPartyParams, thirdPartyUpdateMode]);
    const handleModeChanged = React.useCallback((e) => {
        const val = e.target.value;
        setThirdPartyUpdateMode(val);
        setSelectedContact(val === "select-existing" ? selectedContact : null);
        setThirdPartyParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { contact_data: val === "create-new"
                    ? Object.assign(Object.assign({}, prevState.contact_data), { fields: Object.assign(Object.assign({}, (_a = prevState.contact_data) === null || _a === void 0 ? void 0 : _a.fields), { "Vendor Type": "Agent" }) }) : null, contact_uid: val === "select-existing" ? prevState.contact_uid : null }));
        });
    }, [selectedContact]);
    const handleContactChanged = React.useCallback((_, val) => {
        setSelectedContact(val);
        setThirdPartyParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { contact_uid: (_a = val === null || val === void 0 ? void 0 : val.uid) !== null && _a !== void 0 ? _a : null, contact_data: null }));
        });
    }, [setSelectedContact]);
    const handleContactFieldChanged = React.useCallback((fieldName) => (value) => {
        setThirdPartyParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { contact_data: Object.assign(Object.assign({}, prevState.contact_data), { fields: Object.assign(Object.assign({}, (_a = prevState.contact_data) === null || _a === void 0 ? void 0 : _a.fields), { [fieldName]: value }) }) }));
        });
    }, []);
    const isValid = React.useMemo(() => {
        var _a;
        return ((formData.datetime && JSON.stringify(formData) !== JSON.stringify(initialData)) ||
            (selectedContact != null && selectedContact.uid !== ((_a = showing === null || showing === void 0 ? void 0 : showing.contact) === null || _a === void 0 ? void 0 : _a.uid)) ||
            formData.contact_data !== defaultThirdPartyParams.contact_data);
    }, [formData, initialData, selectedContact, (_d = showing === null || showing === void 0 ? void 0 : showing.contact) === null || _d === void 0 ? void 0 : _d.uid, defaultThirdPartyParams.contact_data]);
    console.log({ selectedContact });
    console.log("TEST: dialog datetime", formData.datetime);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, showing ? "Edit showing" : "Add a showing"),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                React.createElement(Grid2, null,
                    React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Showing Type"),
                            },
                        }, value: formData.showing_type, onChange: setShowingType }, (showingTypes !== null && showingTypes !== void 0 ? showingTypes : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(CoreDateField, { mode: "datetime", format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", fullWidth: true, date: formData.datetime, onDateChange: setDateTime, hasPicker: true })),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body1", style: { color: "#999" } }, "Eastern time")))),
                React.createElement(Grid2, { container: true, style: { border: "2px solid #cccccc", padding: "8px" } },
                    React.createElement(Grid2, { container: true },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1" }, "Third Party Agent"))),
                    React.createElement(Grid2, { container: true },
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(RadioGroup, { defaultValue: "select-existing", name: "mode", value: thirdPartyUpdateMode, onChange: handleModeChanged },
                                React.createElement(FormControlLabel, { value: "select-existing", control: React.createElement(Radio, null), label: "Select an existing contact" }),
                                React.createElement(FormControlLabel, { value: "create-new", control: React.createElement(Radio, null), label: "Create a new contact" }))),
                        thirdPartyUpdateMode === "select-existing" && (React.createElement(Grid2, { size: 12 },
                            React.createElement(ContactAutocomplete, { onChange: handleContactChanged, value: selectedContact, query: {
                                    vendor_type: "Agent",
                                    filter: NOT_DUPLICATE_CONTACT_FILTER,
                                }, TextFieldProps: {
                                    label: "Contact",
                                    helperText: "You can search by the contact's name, email address, phone number, or company",
                                }, searchWhenBlank: true }))),
                        thirdPartyUpdateMode === "create-new" && (React.createElement(Grid2, { size: 12 },
                            React.createElement(Box, { pt: 1, pb: 8 },
                                React.createElement(Grid2, { container: true, spacing: 2 }, contactFields === null || contactFields === void 0 ? void 0 : contactFields.filter((field) => ["First Name", "Last Name", "Email", "Phone", "Company", "Location"]
                                    .filter((x) => x)
                                    .includes(field.name)).map((field) => {
                                    var _a, _b, _c;
                                    return (React.createElement(Grid2, { key: field.uid, size: { xs: 12, sm: 6 } },
                                        React.createElement(DashAutoSaveField, { shouldSave: false, field: field, value: (_c = (_b = (_a = thirdPartyParams.contact_data) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b[field.name]) !== null && _c !== void 0 ? _c : "", onSaveBegin: handleContactFieldChanged(field.name), size: "small", variant: "standard", fullWidth: true, label: field.name, apiEndpoint: "" })));
                                }))))))),
                React.createElement(Grid2, null,
                    React.createElement(CoreChoiceField, { fullWidth: true, variant: "standard", multiple: true, SelectProps: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Agents"),
                            endAdornment: !users ? React.createElement(InputAdornment, { position: "end" }, "Loading...") : undefined,
                        }, sx: {
                            "& .MuiSelect-icon": { display: !users ? "none" : undefined },
                        }, disabled: !users, value: formData.agent_uids, onChoicesChange: setAgents, options: (users !== null && users !== void 0 ? users : []).reduce((dict, agent) => (Object.assign(Object.assign({}, dict), { [agent.uid]: [agent.first_name, agent.last_name].filter((o) => o).join(" ") })), {}) }, (users !== null && users !== void 0 ? users : []).map((agent) => (React.createElement(MenuItem, { key: agent.uid, value: agent.uid },
                        React.createElement(AgentMenuItem, { agent: agent })))))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { fullWidth: true, multiline: true, variant: "standard", minRows: 1, maxRows: 8, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Notes"),
                            },
                        }, value: (_e = formData.notes) !== null && _e !== void 0 ? _e : "", onChange: setNotes })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleSaveClicked, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default ShowingsDialog;
