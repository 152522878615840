var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Event } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { convertDateFormatToDayjs } from "@app/util/Utils";
import CoreDateFieldPicker from "./CoreDateFieldPicker";
import CorePopover from "./CorePopover";
import { parseDate } from "./date-utils";
import dayjs_ from "./dayjs";
dayjs.extend(utc);
dayjs.extend(timezone);
const INTERNAL_DATE_FORMAT = "YYYY-MM-DD";
const INTERNAL_TIME_FORAMT = "HH:mm:ss";
const CoreDateField = (props) => {
    var _a, _b;
    const { children, format, formatType, hasPicker, onBlur, onChange, onDateChange, transform } = props, other = __rest(props, ["children", "format", "formatType", "hasPicker", "onBlur", "onChange", "onDateChange", "transform"]);
    const [value, setValue] = React.useState(null);
    const [date, setDate] = React.useState(null);
    const [pickerAnchorEl, setPickerAnchorEl] = React.useState(null);
    const mode = (_a = props.mode) !== null && _a !== void 0 ? _a : "date";
    React.useEffect(() => {
        setDate(props.date);
        setValue(null);
    }, [props.date]);
    const openDatePicker = React.useCallback((e) => {
        setPickerAnchorEl(e.currentTarget);
    }, [setPickerAnchorEl]);
    const closeDatePicker = React.useCallback(() => {
        setPickerAnchorEl(null);
    }, [setPickerAnchorEl]);
    const handleNewValueSet = React.useCallback((dt) => {
        let newDate = "";
        if (dt && dayjs(dt).isValid()) {
            if (mode === "date") {
                newDate = dayjs_(dt).format(INTERNAL_DATE_FORMAT);
            }
            else if (mode === "time") {
                newDate = dayjs(dt).format(INTERNAL_TIME_FORAMT);
            }
            else if (mode === "datetime") {
                newDate = dayjs(dt).toISOString();
            }
        }
        if (transform) {
            newDate = transform(newDate);
        }
        if (onDateChange) {
            onDateChange(newDate || null);
        }
        if (newDate !== date) {
            setDate(newDate || null);
            setValue(null);
        }
    }, [date, mode, onDateChange, transform]);
    const handleValuePicked = React.useCallback((val) => {
        var _a;
        handleNewValueSet((_a = val === null || val === void 0 ? void 0 : val.toDate()) !== null && _a !== void 0 ? _a : null);
        closeDatePicker();
    }, [handleNewValueSet, closeDatePicker]);
    const handleBlur = React.useCallback((e) => {
        if (onBlur) {
            onBlur(e);
        }
        const newValue = e.target.value;
        const dateParsed = newValue ? parseDate(mode === "time" ? `1970-01-01 ${newValue}` : newValue) : null;
        handleNewValueSet(dateParsed);
    }, [onBlur, handleNewValueSet, mode]);
    const handleChange = React.useCallback((e) => {
        if (onChange) {
            onChange(e);
        }
        setDate(null);
        setValue(e.target.value);
    }, [onChange, setDate, setValue]);
    const val = React.useMemo(() => {
        let res;
        const dayjsFormat = formatType === "dayjs" ? format : convertDateFormatToDayjs(format);
        if (date) {
            if (mode === "date") {
                res = dayjs(date).isValid() ? dayjs.utc(date).format(dayjsFormat) : date;
            }
            else if (mode === "time") {
                res = dayjs(`1970-01-01 ${date}`).isValid()
                    ? dayjs.tz(`1970-01-01 ${date}`, "America/New_York").format(dayjsFormat)
                    : `1970-01-01 ${date}`;
            }
            else {
                res = dayjs(date).isValid() ? dayjs.utc(date).tz("America/New_York").format(dayjsFormat) : date;
            }
        }
        else if (value !== null) {
            res = value;
        }
        else {
            res = "";
        }
        return res;
    }, [date, value, format, mode, formatType]);
    const endAdornment = React.useMemo(() => {
        var _a;
        let res = (_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.endAdornment;
        if (hasPicker) {
            res = (React.createElement(IconButton, { onClick: openDatePicker, size: "small", tabIndex: -1 },
                React.createElement(Event, { fontSize: "small" })));
        }
        return res;
    }, [hasPicker, openDatePicker, (_b = props.InputProps) === null || _b === void 0 ? void 0 : _b.endAdornment]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, Object.assign({ size: "small", fullWidth: true, variant: "standard" }, other, { value: val, onBlur: handleBlur, onChange: handleChange, InputProps: Object.assign(Object.assign({}, other.InputProps), { endAdornment }) }), children),
        pickerAnchorEl && (React.createElement(CorePopover, { open: true, anchorEl: pickerAnchorEl, onClose: closeDatePicker },
            React.createElement(CoreDateFieldPicker, { value: date ? dayjs.utc(date) : null, onChange: handleValuePicked, onClose: closeDatePicker, mode: mode })))));
};
export default CoreDateField;
