import * as React from "react";
import { Alert, Grid2, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSearchMlsesByAddress } from "@app/orval/api/mls";
import MLSPropertyDetails from "./MLSPropertyDetails";
import MLSPropertyPhotosCarousel from "./MLSPropertyPhotosCarousel";
import StreetViewImage from "../../buyer-leads/components/StreetViewImage";
const MLSProperty = (props) => {
    var _a, _b, _c, _d, _e;
    const { StreetViewImageProps, activeDate, address, layout, showAddress } = props;
    const searchByAddressApi = useSearchMlsesByAddress({ address });
    const propertyDetails = (_b = (_a = searchByAddressApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const activeDateDayjs = React.useMemo(() => (activeDate ? dayjs(activeDate) : null), [activeDate]);
    const mostRecentPropertyDetails = propertyDetails === null || propertyDetails === void 0 ? void 0 : propertyDetails.sort((a, b) => {
        var _a, _b;
        const aOnMarketDate = (_a = a.on_market_date) !== null && _a !== void 0 ? _a : "";
        const bOnMarketDate = (_b = b.on_market_date) !== null && _b !== void 0 ? _b : "";
        if (activeDate) {
            const aOnMarketDateDayjs = aOnMarketDate ? dayjs(aOnMarketDate) : null;
            const bOnMarketDateDayjs = bOnMarketDate ? dayjs(bOnMarketDate) : null;
            if (aOnMarketDate && !bOnMarketDate)
                return -1;
            if (!aOnMarketDate && bOnMarketDate)
                return 1;
            if (!aOnMarketDate && !bOnMarketDate)
                return 0;
            const aDiff = Math.abs(aOnMarketDateDayjs.diff(activeDateDayjs));
            const bDiff = Math.abs(bOnMarketDateDayjs.diff(activeDateDayjs));
            if (aDiff < bDiff)
                return -1;
            if (aDiff > bDiff)
                return 1;
            return 0;
        }
        if (aOnMarketDate > bOnMarketDate)
            return -1;
        if (aOnMarketDate < bOnMarketDate)
            return 1;
        return 0;
    })[0];
    const containerRef = React.useRef(null);
    const width = (_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.offsetWidth) !== null && _d !== void 0 ? _d : 0;
    const activeDateWarning = React.useMemo(() => {
        if (activeDateDayjs && mostRecentPropertyDetails) {
            if (!mostRecentPropertyDetails.on_market_date) {
                return (React.createElement(Alert, { severity: "warning", variant: "outlined" }, "The MLS didn't report an active date for this property. This info may not be accurate!"));
            }
            if (mostRecentPropertyDetails.on_market_date) {
                const onMarketDateObj = dayjs(mostRecentPropertyDetails.on_market_date);
                const onMarketDateFormatted = onMarketDateObj.format("MMMM D, YYYY");
                const dateDifference = activeDateDayjs.diff(onMarketDateObj, "day");
                if (Math.abs(dateDifference) > 2) {
                    return (React.createElement(Alert, { severity: "warning", variant: "outlined" },
                        "The MLS info for this property has an active date of ",
                        onMarketDateFormatted,
                        ", which is",
                        " ",
                        dateDifference > 0 ? "before" : "after",
                        " the one indicated here date by ",
                        Math.abs(dateDifference),
                        " days. The MLS data may not be accurate for this lead!"));
                }
            }
        }
        return null;
    }, [activeDateDayjs, mostRecentPropertyDetails]);
    if (width > 200 && layout === "horizontal") {
        return (React.createElement(Grid2, { ref: containerRef, container: true, spacing: 2, direction: "column" },
            React.createElement(Grid2, { container: true, spacing: 1 },
                React.createElement(Grid2, { size: 3 },
                    React.createElement(StreetViewImage, Object.assign({ address: address }, StreetViewImageProps, { ImageProps: Object.assign(Object.assign({}, StreetViewImageProps === null || StreetViewImageProps === void 0 ? void 0 : StreetViewImageProps.ImageProps), { style: Object.assign({ width: "100%", borderRadius: "10px", maxHeight: "100%" }, (_e = StreetViewImageProps === null || StreetViewImageProps === void 0 ? void 0 : StreetViewImageProps.ImageProps) === null || _e === void 0 ? void 0 : _e.style) }) }))),
                propertyDetails == null && (React.createElement(Grid2, { style: { flex: 1 } },
                    React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1" }, address)),
                        React.createElement(Grid2, null, searchByAddressApi.error ? (React.createElement(Alert, { severity: "error", variant: "outlined" }, "Unable to get details for this property via MLS")) : (React.createElement(Typography, { variant: "body2" }, "Loading...")))))),
                propertyDetails != null && !mostRecentPropertyDetails && (React.createElement(Grid2, { style: { flex: 1 } },
                    React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1" }, address)),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2" }, "No data found for this address in MLS"))))),
                propertyDetails != null && mostRecentPropertyDetails && (React.createElement(Grid2, { style: { flex: 1 } },
                    React.createElement(MLSPropertyDetails, { details: mostRecentPropertyDetails, showAddress: showAddress })))),
            (mostRecentPropertyDetails === null || mostRecentPropertyDetails === void 0 ? void 0 : mostRecentPropertyDetails.photos) && mostRecentPropertyDetails.photos.length > 0 && (React.createElement(Grid2, null,
                React.createElement(MLSPropertyPhotosCarousel, { photos: mostRecentPropertyDetails.photos.filter((photo) => photo.url), imageWidthPx: 80 }))),
            activeDateWarning && React.createElement(Grid2, null, activeDateWarning)));
    }
    return (React.createElement(Grid2, { ref: containerRef, container: true, spacing: 2, direction: "column" },
        React.createElement(Grid2, null,
            React.createElement(StreetViewImage, { address: address, ImageProps: { style: { width: "100%", borderRadius: "10px" } } })),
        (mostRecentPropertyDetails === null || mostRecentPropertyDetails === void 0 ? void 0 : mostRecentPropertyDetails.photos) && mostRecentPropertyDetails.photos.length > 0 && (React.createElement(Grid2, null,
            React.createElement(MLSPropertyPhotosCarousel, { photos: mostRecentPropertyDetails.photos.filter((photo) => photo.url), imageWidthPx: 80 }))),
        propertyDetails == null && (React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body1" }, address)),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, "Loading..."))))),
        propertyDetails != null && !mostRecentPropertyDetails && (React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body1" }, address)),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, "No data found for this address in MLS"))))),
        mostRecentPropertyDetails && (React.createElement(Grid2, null,
            React.createElement(MLSPropertyDetails, { details: mostRecentPropertyDetails, showAddress: showAddress }))),
        activeDateWarning && React.createElement(Grid2, null, activeDateWarning)));
};
export default MLSProperty;
