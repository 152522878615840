import { useMemo } from "react";
import { searchBuyerContracts } from "@app/orval/api/buyer-contracts";
import { searchBuyerLeads } from "@app/orval/api/buyer-leads";
import { searchRecruits } from "@app/orval/api/recruits";
import { searchSellerContracts } from "@app/orval/api/seller-contracts";
import { searchSellerLeads } from "@app/orval/api/seller-leads";
import { searchUsers } from "@app/orval/api/users";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const useListEntitiesApi = (entities) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const buyerLeadUids = useMemo(() => entities.filter((x) => x.entity_type === "Buyer Lead" || !x.entity_type).map((x) => x.uid), [entities]);
    const sellerLeadUids = useMemo(() => entities.filter((x) => x.entity_type === "Seller Lead" || !x.entity_type).map((x) => x.uid), [entities]);
    const buyerContractUids = useMemo(() => entities.filter((x) => x.entity_type === "Buyer Contract" || !x.entity_type).map((x) => x.uid), [entities]);
    const sellerContractUids = useMemo(() => entities.filter((x) => x.entity_type === "Listing" || !x.entity_type).map((x) => x.uid), [entities]);
    const userUids = useMemo(() => entities.filter((x) => x.entity_type === "User" || !x.entity_type).map((x) => x.uid), [entities]);
    const recruitUids = useMemo(() => entities.filter((x) => x.entity_type === "Recruit" || !x.entity_type).map((x) => x.uid), [entities]);
    const listBuyerLeadsApi = usePostQuery(searchBuyerLeads, { uids: buyerLeadUids, limit: buyerLeadUids.length }, { enabled: !!buyerLeadUids.length });
    const listSellerLeadsApi = usePostQuery(searchSellerLeads, { uids: sellerLeadUids, limit: sellerLeadUids.length }, { enabled: !!sellerLeadUids.length });
    const listBuyerContractsApi = usePostQuery(searchBuyerContracts, { uids: buyerContractUids, limit: buyerContractUids.length }, { enabled: !!buyerContractUids.length });
    const listSellerContractsApi = usePostQuery(searchSellerContracts, { uids: sellerContractUids, limit: sellerContractUids.length }, { enabled: !!sellerContractUids.length });
    const listUsersApi = usePostQuery(searchUsers, { uids: userUids, limit: userUids.length, disable_sort: true }, { enabled: !!userUids.length });
    const listRecruitsApi = usePostQuery(searchRecruits, { uids: recruitUids, limit: 1000 }, { enabled: !!recruitUids.length });
    const buyerLeads = (_b = (_a = listBuyerLeadsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const sellerLeads = (_d = (_c = listSellerLeadsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const buyerContracts = (_f = (_e = listBuyerContractsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const sellerContracts = (_h = (_g = listSellerContractsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const users = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listUsersApi.data]);
    const recruits = (_k = (_j = listRecruitsApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    return { buyerLeads, sellerLeads, buyerContracts, sellerContracts, users, recruits };
};
export default useListEntitiesApi;
