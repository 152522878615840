/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get an execution log
 */
export const getMailschedule4Execution = (uid, options) => {
    return axios.get(`/api/mailschedule4/executions/${uid}`, options);
};
export const getGetMailschedule4ExecutionQueryKey = (uid) => {
    return [`/api/mailschedule4/executions/${uid}`];
};
export const getGetMailschedule4ExecutionQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule4ExecutionQueryKey(uid);
    const queryFn = ({ signal }) => getMailschedule4Execution(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an execution log
 */
export function useGetMailschedule4Execution(uid, options) {
    const queryOptions = getGetMailschedule4ExecutionQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get an execution log
 */
export const stopMailschedule4Execution = (uid, options) => {
    return axios.post(`/api/mailschedule4/executions/${uid}/stop`, undefined, options);
};
export const getStopMailschedule4ExecutionMutationOptions = (options) => {
    const mutationKey = ['stopMailschedule4Execution'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return stopMailschedule4Execution(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get an execution log
*/
export const useStopMailschedule4Execution = (options) => {
    const mutationOptions = getStopMailschedule4ExecutionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count execution logs
*/
export const countMailschedule4Executions = (params, options) => {
    return axios.get(`/api/mailschedule4/executions/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountMailschedule4ExecutionsQueryKey = (params) => {
    return [`/api/mailschedule4/executions/count`, ...(params ? [params] : [])];
};
export const getCountMailschedule4ExecutionsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountMailschedule4ExecutionsQueryKey(params);
    const queryFn = ({ signal }) => countMailschedule4Executions(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count execution logs
 */
export function useCountMailschedule4Executions(params, options) {
    const queryOptions = getCountMailschedule4ExecutionsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Returns all template logs from a single script execution

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List template logs
 */
export const searchMailschedule4Logs = (paginatedMailSchedule4LogQueryRequest, options) => {
    return axios.post(`/api/mailschedule4/executions/logs_detailed`, paginatedMailSchedule4LogQueryRequest, options);
};
export const getSearchMailschedule4LogsMutationOptions = (options) => {
    const mutationKey = ['searchMailschedule4Logs'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchMailschedule4Logs(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary List template logs
*/
export const useSearchMailschedule4Logs = (options) => {
    const mutationOptions = getSearchMailschedule4LogsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a custom template
*/
export const getMailschedule4CustomTemplate = (uid, options) => {
    return axios.get(`/api/mailschedule4/executions/logs_detailed/${uid}/custom_template`, options);
};
export const getGetMailschedule4CustomTemplateQueryKey = (uid) => {
    return [`/api/mailschedule4/executions/logs_detailed/${uid}/custom_template`];
};
export const getGetMailschedule4CustomTemplateQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule4CustomTemplateQueryKey(uid);
    const queryFn = ({ signal }) => getMailschedule4CustomTemplate(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a custom template
 */
export function useGetMailschedule4CustomTemplate(uid, options) {
    const queryOptions = getGetMailschedule4CustomTemplateQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Count template logs
 */
export const countMailschedule4Logs = (mailSchedule4LogQueryRequest, options) => {
    return axios.post(`/api/mailschedule4/executions/logs_detailed/count`, mailSchedule4LogQueryRequest, options);
};
export const getCountMailschedule4LogsMutationOptions = (options) => {
    const mutationKey = ['countMailschedule4Logs'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countMailschedule4Logs(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count template logs
*/
export const useCountMailschedule4Logs = (options) => {
    const mutationOptions = getCountMailschedule4LogsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary List execution logs
*/
export const searchMailschedule4Executions = (paginatedMailSchedule4ExecutionQueryRequest, options) => {
    return axios.post(`/api/mailschedule4/executions/search`, paginatedMailSchedule4ExecutionQueryRequest, options);
};
export const getSearchMailschedule4ExecutionsMutationOptions = (options) => {
    const mutationKey = ['searchMailschedule4Executions'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchMailschedule4Executions(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary List execution logs
*/
export const useSearchMailschedule4Executions = (options) => {
    const mutationOptions = getSearchMailschedule4ExecutionsMutationOptions(options);
    return useMutation(mutationOptions);
};
