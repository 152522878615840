import * as React from "react";
import { Avatar, ListItemAvatar, ListItemText } from "@mui/material";
import PipelineAndStageText from "./PipelineAndStageText";
const UserSearchResult = (props) => {
    var _a;
    const { user } = props;
    const initials = [user.first_name, user.last_name]
        .filter((obj) => obj)
        .map((obj) => obj === null || obj === void 0 ? void 0 : obj.substring(0, 1))
        .join("");
    const name = [user.first_name, user.last_name].filter((obj) => obj).join(" ");
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: {
                    height: "30px",
                    width: "30px",
                    fontSize: "0.9rem",
                } }, initials && initials !== "" ? initials : "?")),
        React.createElement(ListItemText, { primary: name, secondary: React.createElement(PipelineAndStageText, { pipeline: "DASH User", stageName: user.stage.name, stageUid: user.stage.uid }) })));
};
export default UserSearchResult;
