import * as React from "react";
import { Info } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Paper, Tooltip, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import AppConfig from "@app/util/AppConfig";
const SmallMetricBox = (props) => {
    const { description, error, isLoading, title, value } = props;
    return (React.createElement(Box, { p: 2 },
        React.createElement(Paper, { elevation: 4 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                    React.createElement(Grid, { item: true, container: true, spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, title)),
                        description && (React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, description) },
                                React.createElement(Info, null))))),
                    React.createElement(Grid, { item: true },
                        error && React.createElement(CoreError, { error: error }),
                        isLoading && value == null && React.createElement(CircularProgress, { size: 20 }),
                        value != null && (React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "h6", style: { color: AppConfig.themeColors.primary, fontWeight: "bold" } }, value))))))))));
};
export default SmallMetricBox;
