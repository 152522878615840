import * as React from "react";
import { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useGetNotificationUrl } from "@app/orval/api/redirects";
const NotificationRedirectPage = () => {
    var _a, _b;
    const params = useParams();
    const navigate = useNavigate();
    const { uid } = params;
    const getNotificationUrlApi = useGetNotificationUrl(uid);
    const notificationUrl = (_b = (_a = getNotificationUrlApi.data) === null || _a === void 0 ? void 0 : _a.data.url) !== null && _b !== void 0 ? _b : null;
    useEffect(() => {
        if (notificationUrl) {
            navigate(notificationUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationUrl]);
    return (React.createElement(Container, { maxWidth: "md" },
        React.createElement(Box, { p: 4 },
            React.createElement(Typography, { variant: "body1", gutterBottom: true, align: "center" }, "Loading notification info..."))));
};
export default NotificationRedirectPage;
