import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import GraduationRequirementTypeForm from "../../features/admin/onboarding/GraduationRequirementTypeForm";
const GraduationRequirementTypeFormPage = () => {
    useDocumentTitle("Onboarding | Graduation Requirement Types");
    const params = useParams();
    return React.createElement(GraduationRequirementTypeForm, { uid: params.uid });
};
export default GraduationRequirementTypeFormPage;
