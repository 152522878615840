import * as React from "react";
import { Grid2, Typography } from "@mui/material";
import pickBy from "lodash/pickBy";
import { sanitizeFields } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import CompsPullerWrapper from "@app/entrypoints/brokerage/features/tools/comps-puller/CompsPullerWrapper";
import { getDaysOnMarket, getAddress, getLivingArea, getCompType, } from "@app/entrypoints/brokerage/features/tools/comps-puller/utils";
/**
 * Wraps the Comps Finder widget within the PSP
 */
const PSPCompsFinderTab = (props) => {
    const { bulkAddPSPComps, bulkUpdateFields, isAddingSubjectPropertyData, isBulkAddingPSPComps, mlsId, propertyAddress, uid, } = props;
    const addComps = React.useCallback((properties) => {
        console.log("adding properties", properties);
        const newComps = [];
        properties.forEach((property) => {
            const compFields = {
                Address: getAddress(property.data),
                "Total Living Area SqFt": getLivingArea(property.data, property.source),
                Beds: property.data["BedroomsTotal"],
                "Full Baths": property.data["BathroomsFull"],
                "Close Date": property.data["CloseDate"],
                "Scheduled Close Date": property.data["CloseDate"],
                "Sold Price": property.data["ClosePrice"],
                "List Price": property.data["ListPrice"],
                Garages: property.data["GarageSpaces"],
                DOM: getDaysOnMarket(property.data, property.source),
                "Financial Concessions": property.data["ConcessionsAmount"],
            };
            const sanitizedFields = sanitizeFields(compFields);
            const standardStatus = property.data["StandardStatus"];
            const mlsStatus = property.data["MlsStatus"];
            const compType = getCompType(standardStatus, mlsStatus);
            if (!compType) {
                throw Error(`Unexpected property status. StandardStatus='${standardStatus}', MlsStatus='${mlsStatus}'`);
            }
            newComps.push({
                matrix_uid: uid,
                comp_type: compType,
                fields: pickBy(sanitizedFields, (val) => val != null),
            });
        });
        bulkAddPSPComps(newComps, true);
    }, [bulkAddPSPComps, uid]);
    /** Updates PSP fields with data from the Subject Property */
    const addSubjectPropertyDataToPSP = React.useCallback((subjectProperty) => {
        // key: Dash field name, value: MLS value
        const fields = {
            "MLS ID #": subjectProperty.mlsnumber,
            "Original List Price": subjectProperty.data["OriginalListPrice"],
            "Current List Price": subjectProperty.data["ListPrice"],
            DOM: getDaysOnMarket(subjectProperty.data, subjectProperty.source),
            "Finished SqFt": getLivingArea(subjectProperty.data, subjectProperty.source),
            "Basement Finished SqFt": subjectProperty.data["BelowGradeFinishedArea"],
            "HOA Dues/M": subjectProperty.data["AssociationFee"],
            "Subdivision HOA Time Period": subjectProperty.data["AssociationFeeFrequency"],
            "Year Built": subjectProperty.data["YearBuilt"],
            "Closing Date": subjectProperty.data["CloseDate"],
        };
        const sanitizedFields = sanitizeFields(fields);
        console.log("Adding fields", fields, "from", subjectProperty);
        bulkUpdateFields(sanitizedFields);
        // unset the loading state
    }, [bulkUpdateFields]);
    if (!propertyAddress && !mlsId) {
        return (React.createElement(Grid2, { container: true, spacing: 2 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "h6" }, "Unable to display the Comps Puller")),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, null, "Make sure you've entered either the Property Address or MLS ID #"))));
    }
    return (React.createElement(CompsPullerWrapper, { pspUid: uid, pspAddress: propertyAddress !== null && propertyAddress !== void 0 ? propertyAddress : undefined, pspMlsId: mlsId !== null && mlsId !== void 0 ? mlsId : undefined, onSelectionConfirmed: addComps, addSubjectPropertyData: addSubjectPropertyDataToPSP, isAddingComps: isBulkAddingPSPComps, isAddingSubjectProperty: isAddingSubjectPropertyData }));
};
export default PSPCompsFinderTab;
