/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all boilerplate
 */
export const listMailschedule4Boilerplate = (options) => {
    return axios.get(`/api/mailschedule4/boilerplate`, options);
};
export const getListMailschedule4BoilerplateQueryKey = () => {
    return [`/api/mailschedule4/boilerplate`];
};
export const getListMailschedule4BoilerplateQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailschedule4BoilerplateQueryKey();
    const queryFn = ({ signal }) => listMailschedule4Boilerplate(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all boilerplate
 */
export function useListMailschedule4Boilerplate(options) {
    const queryOptions = getListMailschedule4BoilerplateQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a boilerplate
 */
export const createMailschedule4Boilerplate = (mailSchedule4BoilerplateRequest, options) => {
    return axios.post(`/api/mailschedule4/boilerplate`, mailSchedule4BoilerplateRequest, options);
};
export const getCreateMailschedule4BoilerplateMutationOptions = (options) => {
    const mutationKey = ['createMailschedule4Boilerplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createMailschedule4Boilerplate(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a boilerplate
*/
export const useCreateMailschedule4Boilerplate = (options) => {
    const mutationOptions = getCreateMailschedule4BoilerplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a boilerplate
*/
export const getMailschedule4Boilerplate = (uid, options) => {
    return axios.get(`/api/mailschedule4/boilerplate/${uid}`, options);
};
export const getGetMailschedule4BoilerplateQueryKey = (uid) => {
    return [`/api/mailschedule4/boilerplate/${uid}`];
};
export const getGetMailschedule4BoilerplateQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule4BoilerplateQueryKey(uid);
    const queryFn = ({ signal }) => getMailschedule4Boilerplate(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a boilerplate
 */
export function useGetMailschedule4Boilerplate(uid, options) {
    const queryOptions = getGetMailschedule4BoilerplateQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a boilerplate
 */
export const updateMailschedule4Boilerplate = (uid, mailSchedule4BoilerplateRequest, options) => {
    return axios.post(`/api/mailschedule4/boilerplate/${uid}`, mailSchedule4BoilerplateRequest, options);
};
export const getUpdateMailschedule4BoilerplateMutationOptions = (options) => {
    const mutationKey = ['updateMailschedule4Boilerplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateMailschedule4Boilerplate(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a boilerplate
*/
export const useUpdateMailschedule4Boilerplate = (options) => {
    const mutationOptions = getUpdateMailschedule4BoilerplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete a boilerplate
*/
export const deleteMailschedule4Boilerplate = (uid, options) => {
    return axios.delete(`/api/mailschedule4/boilerplate/${uid}`, options);
};
export const getDeleteMailschedule4BoilerplateMutationOptions = (options) => {
    const mutationKey = ['deleteMailschedule4Boilerplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteMailschedule4Boilerplate(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a boilerplate
*/
export const useDeleteMailschedule4Boilerplate = (options) => {
    const mutationOptions = getDeleteMailschedule4BoilerplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Duplicate a boilerplate
*/
export const duplicateMailschedule4Boilerplate = (uid, options) => {
    return axios.post(`/api/mailschedule4/boilerplate/${uid}/duplicate`, undefined, options);
};
export const getDuplicateMailschedule4BoilerplateMutationOptions = (options) => {
    const mutationKey = ['duplicateMailschedule4Boilerplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateMailschedule4Boilerplate(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a boilerplate
*/
export const useDuplicateMailschedule4Boilerplate = (options) => {
    const mutationOptions = getDuplicateMailschedule4BoilerplateMutationOptions(options);
    return useMutation(mutationOptions);
};
