import { enqueueSnackbar } from "notistack";
const useChromeExtensionListener = () => {
    const chromeExtensionListener = (onPaste) => {
        enqueueSnackbar("Paste (ctrl+v or cmd+v) here to fill in the information.", { variant: "info" });
        document.removeEventListener("paste", onPaste);
        document.addEventListener("paste", onPaste);
    };
    return chromeExtensionListener;
};
export default useChromeExtensionListener;
