/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all word doc templates
 */
export const listWordDocTemplates = (options) => {
    return axios.get(`/api/word_doc_templates`, options);
};
export const getListWordDocTemplatesQueryKey = () => {
    return [`/api/word_doc_templates`];
};
export const getListWordDocTemplatesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListWordDocTemplatesQueryKey();
    const queryFn = ({ signal }) => listWordDocTemplates(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all word doc templates
 */
export function useListWordDocTemplates(options) {
    const queryOptions = getListWordDocTemplatesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Returns a Presigned URL which can be used to download the template

_Authorization: none required_
 * @summary Download a Word Doc Template
 */
export const downloadWordDocTemplate = (templateType, options) => {
    return axios.get(`/api/word_doc_templates/${templateType}`, options);
};
export const getDownloadWordDocTemplateQueryKey = (templateType) => {
    return [`/api/word_doc_templates/${templateType}`];
};
export const getDownloadWordDocTemplateQueryOptions = (templateType, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadWordDocTemplateQueryKey(templateType);
    const queryFn = ({ signal }) => downloadWordDocTemplate(templateType, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(templateType) }, queryOptions);
};
/**
 * @summary Download a Word Doc Template
 */
export function useDownloadWordDocTemplate(templateType, options) {
    const queryOptions = getDownloadWordDocTemplateQueryOptions(templateType, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Upload a word doc template to Google Cloud Storage
 */
export const uploadWordDocTemplate = (templateType, wordDocTemplateUploadRequest, options) => {
    return axios.post(`/api/word_doc_templates/${templateType}`, wordDocTemplateUploadRequest, options);
};
export const getUploadWordDocTemplateMutationOptions = (options) => {
    const mutationKey = ['uploadWordDocTemplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { templateType, data } = props !== null && props !== void 0 ? props : {};
        return uploadWordDocTemplate(templateType, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Upload a word doc template to Google Cloud Storage
*/
export const useUploadWordDocTemplate = (options) => {
    const mutationOptions = getUploadWordDocTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List all word doc template types
*/
export const listWordDocTemplateTypes = (options) => {
    return axios.get(`/api/word_doc_templates/types`, options);
};
export const getListWordDocTemplateTypesQueryKey = () => {
    return [`/api/word_doc_templates/types`];
};
export const getListWordDocTemplateTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListWordDocTemplateTypesQueryKey();
    const queryFn = ({ signal }) => listWordDocTemplateTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all word doc template types
 */
export function useListWordDocTemplateTypes(options) {
    const queryOptions = getListWordDocTemplateTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
