import { useEffect, useMemo } from "react";
/**
 * Set the document title (i.e. the title of the browser tab)
 *
 * @param title the title to set
 * @param options
 */
const useDocumentTitle = (title, options) => {
    var _a;
    const siteName = (_a = options === null || options === void 0 ? void 0 : options.siteName) !== null && _a !== void 0 ? _a : "DASH Portal";
    const fullTitle = useMemo(() => {
        return [title, siteName].filter((x) => x.trim()).join(" | ");
    }, [title, siteName]);
    useEffect(() => {
        document.title = fullTitle;
    }, [fullTitle]);
};
export default useDocumentTitle;
