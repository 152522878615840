import * as React from "react";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import PSPCertificationFeedbackSidebar from "./PSPCertificationFeedbackSidebar";
import PSPCertificationTable from "./PSPCertificationTable";
const PSPCertificationWrapper = () => {
    const [feedbackSidebarPSP, setFeedbackSidebarPSP] = React.useState(null);
    const openFeedbackSidebar = React.useCallback((matrix) => {
        setFeedbackSidebarPSP(matrix);
    }, []);
    const closeFeedbackSidebar = React.useCallback(() => {
        setFeedbackSidebarPSP(null);
    }, []);
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(PSPCertificationTable, { onSidebarToggle: openFeedbackSidebar }), sidebar: feedbackSidebarPSP ? (React.createElement(PSPCertificationFeedbackSidebar, { matrix: feedbackSidebarPSP, onSidebarToggle: closeFeedbackSidebar })) : undefined, sidebarWidth: 250, opened: !!feedbackSidebarPSP }));
};
export default PSPCertificationWrapper;
