import * as React from "react";
import { Box, Grid2, List, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { listTransactions } from "@app/orval/api/agent-dashboard";
import usePostQuery from "../../../dashboards/agent-dashboard/usePostQuery";
const ListSkeleton = (props) => {
    const { length = 3 } = props;
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 }, Array.from({ length }).map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    React.createElement(Grid2, { key: i },
        React.createElement(Skeleton, null))))));
};
const BoxesModal = (props) => {
    var _a;
    const { boxUids } = props;
    const listTransactionsApi = usePostQuery(listTransactions, {
        uids: boxUids,
        include_under_contract_leads: true,
    }, {}, boxUids.length > 0);
    const transactions = (_a = listTransactionsApi.data) === null || _a === void 0 ? void 0 : _a.data;
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
        React.createElement(Grid2, null,
            transactions == null && (React.createElement(Box, { p: 2 },
                React.createElement(ListSkeleton, { length: boxUids.length }))),
            transactions != null && (React.createElement(List, { dense: true }, transactions.map((x) => (React.createElement(ListItemButton, { key: x.uid, component: RouterLink, to: `/boxes/${x.uid}` },
                React.createElement(ListItemText, { primary: x.name, secondary: x.type })))))))));
};
export default BoxesModal;
