import * as React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { Grid2, Typography, DialogContent, DialogTitle, DialogActions, IconButton, Button, Tooltip, } from "@mui/material";
import numeral from "numeral";
import styled from "styled-components";
import CoreDialog from "@app/common/CoreDialog";
import CoreNumberField from "@app/common/CoreNumberField";
import CoreTextField from "@app/common/CoreTextField";
import { onKeyDown } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
const LineItemWrapper = styled("div") `
    width: 100%;
    & div button {
        visibility: hidden;
    }
    & div:hover div button {
        visibility: unset;
    }
`;
const MAtrixLineItemField = (props) => {
    const { format, onChange, type, value } = props;
    if (type === "number") {
        return (React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, number: value, format: format !== null && format !== void 0 ? format : "0", size: "small", inputProps: {
                style: {
                    fontSize: "11px",
                },
            }, onNumberChange: onChange, onKeyDown: onKeyDown }));
    }
    if (type === "formula") {
        return React.createElement(Typography, null, value === null ? "-" : numeral(value).format(format !== null && format !== void 0 ? format : "0"));
    }
    return (React.createElement(CoreTextField, { variant: "standard", fullWidth: true, value: value, onTextChange: onChange, size: "small", inputProps: {
            style: {
                fontSize: "11px",
            },
        }, onKeyDown: onKeyDown }));
};
class MAtrixLineItem extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                lineItem: this.props.lineItem,
                showDialog: false,
                isDeletingLineItem: false,
                isUpdatingLineItem: false,
            }
        });
        Object.defineProperty(this, "saveLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { onSaveRenovationBudget, onSaveRentRoll, type } = this.props;
                const { lineItem } = this.state;
                this.setState({
                    isUpdatingLineItem: true,
                    showDialog: false,
                });
                if (type === "Rent Roll" && onSaveRentRoll) {
                    onSaveRentRoll(lineItem);
                }
                else if (type === "Renovation Budget" && onSaveRenovationBudget) {
                    onSaveRenovationBudget(lineItem);
                }
            }
        });
        Object.defineProperty(this, "deleteLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { lineItem } = this.state;
                this.setState({
                    isDeletingLineItem: true,
                    showDialog: false,
                });
                this.props.onDelete(lineItem.uid);
            }
        });
        Object.defineProperty(this, "updateLineItemField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (fieldName) => (value) => {
                this.setState((prevState) => {
                    const newLineItem = prevState.lineItem;
                    newLineItem[fieldName] = value === "" ? null : value;
                    return {
                        lineItem: newLineItem,
                    };
                });
            }
        });
        Object.defineProperty(this, "openDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    showDialog: true,
                });
            }
        });
        Object.defineProperty(this, "closeDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    lineItem: this.props.lineItem,
                    showDialog: false,
                });
            }
        });
    }
    componentDidMount() {
        this.setState({
            lineItem: Object.assign({}, this.props.lineItem),
        });
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.lineItem) !== JSON.stringify(this.props.lineItem)) {
            this.setState({
                lineItem: this.props.lineItem,
                isUpdatingLineItem: false,
                isDeletingLineItem: false,
            });
        }
    }
    render() {
        const { formulaFieldValues, lineItemFieldData, type } = this.props;
        const { isDeletingLineItem, isUpdatingLineItem, lineItem, showDialog } = this.state;
        // console.log(lineItem);
        return (React.createElement(React.Fragment, null,
            React.createElement(LineItemWrapper, null,
                React.createElement(Grid2, { container: true, size: 12 },
                    lineItemFieldData.map((field) => {
                        var _a;
                        let val = lineItem[field.name];
                        const formulaField = (formulaFieldValues !== null && formulaFieldValues !== void 0 ? formulaFieldValues : []).find((item) => item.name === field.name);
                        if (formulaField) {
                            val = formulaField.value;
                        }
                        if (field.type === "number" || field.type === "formula") {
                            val = val === null ? null : numeral(val).format((_a = field.format) !== null && _a !== void 0 ? _a : "0");
                        }
                        return (React.createElement(Grid2, { key: `line_item_${lineItem.uid}_${field.name}_${field.type}`, size: field.gridSize, style: { paddingRight: "4px", paddingLeft: "4px" } },
                            React.createElement(Typography, null, val === null ? "-" : val)));
                    }),
                    React.createElement(Grid2, { size: 2, style: { textAlign: "center" } },
                        React.createElement(Tooltip, { title: "Edit" },
                            React.createElement(IconButton, { size: "small", disabled: isUpdatingLineItem || isDeletingLineItem, onClick: this.openDialog },
                                React.createElement(Edit, null))),
                        React.createElement(Tooltip, { title: "Delete" },
                            React.createElement(IconButton, { size: "small", disabled: isUpdatingLineItem || isDeletingLineItem, onClick: this.deleteLineItem },
                                React.createElement(Delete, null)))))),
            React.createElement(CoreDialog, { open: !!showDialog },
                React.createElement(DialogTitle, null, `${type} Fields`),
                React.createElement(DialogContent, null,
                    React.createElement(Grid2, { container: true, size: 12 }, lineItemFieldData.map((field) => {
                        const val = lineItem[field.name];
                        return (field.type !== "formula" && (React.createElement(React.Fragment, null,
                            React.createElement(Grid2, { key: `line_item_${lineItem.uid}_${field.name}_label`, size: 5 },
                                React.createElement(Typography, { style: {
                                        textAlign: "right",
                                        paddingRight: "8px",
                                    } }, `${field.label}:`)),
                            React.createElement(Grid2, { key: `line_item_${lineItem.uid}_${field.name}_field`, size: 7, style: { paddingRight: "4px" } },
                                React.createElement(MAtrixLineItemField, { value: val, type: field.type, format: field.format, onChange: this.updateLineItemField(field.name) })))));
                    }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { color: "primary", variant: "contained", disabled: isUpdatingLineItem || isDeletingLineItem, onClick: this.saveLineItem }, "Save"),
                    React.createElement(Button, { onClick: this.closeDialog }, "Close")))));
    }
}
export default MAtrixLineItem;
