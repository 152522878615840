var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Add, Edit, Delete } from "@mui/icons-material";
import { Box, Divider, IconButton, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Typography, Grid2, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useDeleteTwilioDoNotCallItem, useListTwilioDoNotCallItems } from "@app/orval/api/twilio-do-not-call";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import DoNotCallDialog from "./DoNotCallDialog";
const DoNotCall = () => {
    var _a, _b;
    const queryClient = useQueryClient();
    const listItemsApi = useListTwilioDoNotCallItems(REFETCH_CACHE_PARAMS);
    const items = (_b = (_a = listItemsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const refresh = React.useCallback(() => {
        listItemsApi.refetch();
    }, [listItemsApi]);
    const deleteItemApi = useDeleteTwilioDoNotCallItem();
    const confirm = useConfirm();
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const deleteDoNotContactItem = React.useCallback((uid) => () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS);
        if (result.confirmed) {
            queryClient.setQueryData(listItemsApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((item) => item.uid !== uid) });
            });
            deleteItemApi
                .mutateAsync({ uid })
                .then(() => {
                enqueueSnackbar("Deleted phone number from Do Not Call list", { variant: "success" });
            })
                .catch(enqueueErrorSnackbar);
        }
    }), [confirm, deleteItemApi, listItemsApi.queryKey, queryClient]);
    const openDoNotContactDialog = React.useCallback((uid) => () => {
        setSelectedItem(uid ? items.find((item) => item.uid === uid) : null);
        setIsDialogOpen(true);
    }, [items]);
    const onSaveComplete = React.useCallback(() => {
        refresh();
        setIsDialogOpen(false);
    }, [refresh]);
    const onCancel = React.useCallback(() => {
        setIsDialogOpen(false);
    }, []);
    let content;
    if (listItemsApi.error) {
        content = React.createElement(CoreError, { error: listItemsApi.error });
    }
    else if (!items) {
        content = null;
    }
    else {
        content = (React.createElement(Grid2, null,
            React.createElement(Table, { size: "small", stickyHeader: true },
                React.createElement("colgroup", null,
                    React.createElement("col", { style: { width: "100px" } }),
                    React.createElement("col", null),
                    React.createElement("col", null)),
                React.createElement(TableHead, { style: { fontWeight: "bold" } },
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: { textAlign: "center" } },
                            React.createElement(Tooltip, { title: "Add number" },
                                React.createElement(IconButton, { size: "small", onClick: openDoNotContactDialog() },
                                    React.createElement(Add, { fontSize: "small" })))),
                        React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Name"),
                        React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Number"))),
                React.createElement(TableBody, null, items.map((item) => (React.createElement(TableRow, { key: item.uid },
                    React.createElement(TableCell, { style: { textAlign: "center" } },
                        React.createElement(Tooltip, { title: "Remove number" },
                            React.createElement(IconButton, { size: "small", onClick: deleteDoNotContactItem(item.uid) },
                                React.createElement(Delete, { fontSize: "small" }))),
                        React.createElement(Tooltip, { title: "Edit number" },
                            React.createElement(IconButton, { size: "small", onClick: openDoNotContactDialog(item.uid) },
                                React.createElement(Edit, { fontSize: "small" })))),
                    React.createElement(TableCell, null, item.name),
                    React.createElement(TableCell, null, item.number))))))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { component: Grid2, container: true, direction: "column", p: 2, spacing: 2, wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "caption", display: "block" }, "Use this feature to prevent scripts from reaching out to certain numbers (i.e. the Feedback Request Cadence)."),
                React.createElement(Typography, { variant: "caption", display: "block" }, "You can still contact these numbers through the Messages module or email."),
                React.createElement(Typography, { variant: "caption", display: "block" }, "Any format is OK for the phone number (the automation will ignore everything that's not a digit)."),
                React.createElement(Typography, { variant: "caption", display: "block", dangerouslySetInnerHTML: {
                        __html: `Users can also prevent themselves from being contacted altogether
                                by replying <strong>STOP</strong> or <strong>UNSUBSCRIBE</strong> to a text.`,
                    } })),
            React.createElement(Grid2, null,
                React.createElement(Divider, null)),
            content),
        React.createElement(DoNotCallDialog, { DialogProps: { open: isDialogOpen }, uid: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.uid, initialData: selectedItem, onSaveComplete: onSaveComplete, onCancel: onCancel })));
};
export default DoNotCall;
