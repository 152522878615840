import * as React from "react";
import { Close } from "@mui/icons-material";
import { Grid, Typography, IconButton, Link } from "@mui/material";
import numeral from "numeral";
import dayjs_ from "@app/common/dayjs";
const isBuyerContract = (contract) => {
    return "buyer_lead_uid" in Object(contract);
};
const isSellerContract = (contract) => {
    return "seller_lead_uid" in Object(contract);
};
const ReportingPortalToolsTooltip = (props) => {
    const { closeTooltip, data } = props;
    const val = data.value && numeral(data.value).value();
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true, xs: 12, style: { display: "flex" } },
            React.createElement("div", null,
                React.createElement("strong", null, data.name),
                " - ",
                val != null && Math.round(val * 100) / 100,
                data.showContracts &&
                    ` ${numeral(data.contracts.reduce((total, contract) => { var _a; return numeral(Math.round(((_a = contract.fields["Contract Price"]) !== null && _a !== void 0 ? _a : 0) * 100) / 100).value() + total; }, 0)).format("$0,0")}`),
            React.createElement(IconButton, { size: "small", onClick: closeTooltip, style: { marginLeft: "auto", marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Close, null))),
        data.showContracts && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, null, `Contracts: ${data.contracts.length}`)),
            React.createElement(Grid, { container: true, item: true, spacing: 0, style: { paddingLeft: "8px" } }, data.contracts
                .sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
                .map((contract) => {
                let path = null;
                if (isBuyerContract(contract) && contract.buyer_lead_uid) {
                    path = `buyers/${contract.buyer_lead_uid}`;
                }
                else if (isSellerContract(contract) && contract.seller_lead_uid) {
                    path = `sellers/${contract.seller_lead_uid}`;
                }
                if (!path) {
                    console.warn("No path for contract", contract);
                    return (React.createElement(Typography, { key: `contract_${contract.uid}_${contract.created_at}`, variant: "caption" },
                        contract.name,
                        " - ",
                        numeral(contract.fields["Contract Price"]).format("$0,0.00")));
                }
                return (React.createElement(Grid, { key: `contract_${contract.uid}_${contract.created_at}`, item: true, xs: 12 },
                    React.createElement(Link, { href: `${window.location.origin}/${path}`, target: "_blank" },
                        React.createElement(Typography, { variant: "caption" },
                            contract.name,
                            " - ",
                            numeral(contract.fields["Contract Price"]).format("$0,0.00")))));
            })))),
        data.showPSPs && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, null, `PSPs: ${data.psps.length}`)),
            React.createElement(Grid, { container: true, item: true, spacing: 0, style: { paddingLeft: "8px" } }, data.psps
                .sort((a, b) => {
                if (a.created_at < b.created_at)
                    return -1;
                if (a.created_at > b.created_at)
                    return 1;
                return 0;
            })
                .map((psp) => {
                var _a;
                return (React.createElement(Grid, { key: psp.matrix_uid, item: true, xs: 12 },
                    React.createElement(Link, { href: `${window.location.origin}/matrix/${psp.matrix_uid}`, target: "_blank" },
                        React.createElement(Typography, { variant: "caption" }, `${(_a = psp.property_address) !== null && _a !== void 0 ? _a : "No Address Provided"} - ${dayjs_(psp.created_at).format("MM/DD/YYYY")}`))));
            })))),
        data.showQFOs && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, null, `QFOs: ${data.qfos.length}`)),
            React.createElement(Grid, { container: true, item: true, spacing: 0, style: { paddingLeft: "8px" } }, data.qfos
                .sort((a, b) => {
                if (a.created < b.created)
                    return -1;
                if (a.created > b.created)
                    return 1;
                return 0;
            })
                .map((qfo) => {
                var _a;
                return (React.createElement(Grid, { key: qfo.uid, item: true, xs: 12 },
                    React.createElement(Link, { href: `${window.location.origin}/forms/qfo/${qfo.uid}`, target: "_blank" },
                        React.createElement(Typography, { variant: "caption" }, `${(_a = qfo.fields["Property Address"]) !== null && _a !== void 0 ? _a : ""} - ${qfo.submitted_at ? dayjs_(qfo.submitted_at).format("MM/DD/YYYY") : qfo.submitted_at}`))));
            }))))));
};
export default ReportingPortalToolsTooltip;
