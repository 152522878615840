import * as React from "react";
import { Menu } from "@mui/material";
import { isChromeExtension, getRootElement } from "@app/util/Utils";
/**
 * A wrapper around MUI's `Menu` component whose modal
 * is rendered within the root element (rather than at the bottom of
 * the document) when we're using the Gmail version of the app
 *
 * @param props
 * @returns
 */
const CoreMenu = (props) => {
    return (React.createElement(Menu, Object.assign({ container: isChromeExtension ? getRootElement() : undefined }, props), props.children));
};
export default CoreMenu;
