import * as React from "react";
import { Add, Close } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useCreateDataValidationRule, useDeleteDataValidationRule, useListDataValidationRules, useUpdateDataValidationRule, } from "@app/orval/api/data-validation-rules";
import { useListStages } from "@app/orval/api/stages";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import DataValidationRuleComponent from "./DataValidationRule";
const DataValidation = (props) => {
    var _a, _b, _c, _d;
    const { entityType, onClose } = props;
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const listValidationRulesApi = useListDataValidationRules({ entity_type: entityType }, REFETCH_CACHE_PARAMS);
    const validationRules = (_b = (_a = listValidationRulesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listStagesApi = useListStages({ type: entityType }, INFINITE_CACHE_PARAMS);
    const stages = (_d = (_c = listStagesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const createDataValidationRuleApi = useCreateDataValidationRule();
    const updateDataValidationRuleApi = useUpdateDataValidationRule();
    const deleteDataValidationRuleApi = useDeleteDataValidationRule();
    const addRule = React.useCallback(() => {
        createDataValidationRuleApi
            .mutateAsync({
            data: {
                entity_type: entityType,
                is_enabled: true,
                stage_validation_rule: {
                    stage_uids: [stages[0].uid],
                    action: "Disable",
                    formula: "",
                },
            },
        })
            .then((result) => {
            queryClient.setQueryData(listValidationRulesApi.queryKey, (oldData) => {
                if (oldData == null) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: [...oldData.data, result.data] });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [createDataValidationRuleApi, entityType, listValidationRulesApi.queryKey, queryClient, stages]);
    const saveRule = React.useCallback((uid) => (rule) => {
        updateDataValidationRuleApi
            .mutateAsync({
            uid,
            data: rule,
        })
            .then((result) => {
            queryClient.setQueryData(listValidationRulesApi.queryKey, (oldData) => {
                if (oldData == null) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: oldData.data.map((r) => (r.uid === result.data.uid ? result.data : r)) });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [listValidationRulesApi.queryKey, queryClient, updateDataValidationRuleApi]);
    const deleteRule = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listValidationRulesApi.queryKey, (oldData) => {
                    if (oldData == null) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((rule) => rule.uid !== uid) });
                });
                deleteDataValidationRuleApi
                    .mutateAsync({ uid })
                    .then(() => enqueueSnackbar("Deleted validation rule", { variant: "success" }))
                    .catch(enqueueErrorSnackbar);
            }
        });
    }, [confirm, deleteDataValidationRuleApi, listValidationRulesApi.queryKey, queryClient]);
    return (React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap", style: { overflow: "hidden", background: "#fff", height: "100%" } },
        React.createElement(Grid, { item: true, style: { background: "#333", color: "white" } },
            React.createElement(Box, { p: 1 },
                React.createElement(Grid, { container: true, spacing: 1, direction: "row", wrap: "nowrap", style: { overflow: "hidden" }, alignItems: "center" },
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Typography, null, "Data Validation")),
                    React.createElement(Grid, { item: true },
                        React.createElement(IconButton, { size: "small", onClick: onClose },
                            React.createElement(Close, { fontSize: "small", style: { color: "white" } })))))),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { size: "small", onClick: addRule, startIcon: React.createElement(Add, { fontSize: "small" }), variant: "text", disabled: !stages ||
                    !validationRules ||
                    createDataValidationRuleApi.isPending ||
                    updateDataValidationRuleApi.isPending ||
                    deleteDataValidationRuleApi.isPending }, "Add rule")),
        React.createElement(Grid, { item: true }, listValidationRulesApi.isLoading && validationRules ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, style: { flex: 1, overflowY: "scroll" } },
            listValidationRulesApi.isLoading && !validationRules && React.createElement(CoreLoading, null), validationRules === null || validationRules === void 0 ? void 0 :
            validationRules.map((rule, i) => (React.createElement(React.Fragment, null,
                i > 0 && React.createElement(Divider, null),
                React.createElement(DataValidationRuleComponent, { key: rule.uid, rule: rule, onSave: saveRule(rule.uid), onDelete: deleteRule(rule.uid) })))))));
};
export default DataValidation;
