import * as React from "react";
import { Add } from "@mui/icons-material";
import { Divider, Grid2, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import ChromeExtensionCompatibleIframe from "@app/common/ChromeExtensionCompatibleIframe";
import CoreLink from "@app/common/CoreLink";
import CoreLoading from "@app/common/CoreLoading";
import MAtrixDraggable from "@app/entrypoints/brokerage/features/apps/matrix/components/MAtrixDraggable";
import { onUploadButtonClick } from "@app/util/Utils";
const REPORT_TYPES = [
    "MLS Sheet",
    "CMA Summary",
    "Flood Zone",
    "Airport Noise",
    "540 Extension Map (if needed)",
    "CA",
    "RPR",
    "CRS",
];
const getDraggableItems = (items) => items.map((item) => ({
    id: item.uid,
}));
const FilesTab = (props) => {
    const { addFile, addPhoto, deleteFile, deletePhoto, files, isUploadingFile, isUploadingPhoto, photos, reorderFiles, reorderPhotos, } = props;
    const onFileReorder = React.useCallback((e) => {
        var _a;
        const activeId = e.active.id;
        const overId = (_a = e.over) === null || _a === void 0 ? void 0 : _a.id;
        if (activeId !== overId && files) {
            const oldIndex = files.findIndex((file) => file.uid === activeId);
            const newIndex = files.findIndex((file) => file.uid === overId);
            if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
                const fileToMove = files[oldIndex];
                files.splice(oldIndex, 1);
                files.splice(newIndex, 0, fileToMove);
                reorderFiles(files.map((file) => file.uid));
            }
            else {
                console.warn("Could not reorder files: oldIndex:", oldIndex, "newIndex:", newIndex);
            }
        }
    }, [files, reorderFiles]);
    const onPhotoReorder = React.useCallback((e) => {
        var _a;
        const activeId = e.active.id;
        const overId = (_a = e.over) === null || _a === void 0 ? void 0 : _a.id;
        if (activeId !== overId && photos) {
            const oldIndex = photos.findIndex((file) => file.uid === activeId);
            const newIndex = photos.findIndex((file) => file.uid === overId);
            if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
                const photoToMove = photos[oldIndex];
                photos.splice(oldIndex, 1);
                photos.splice(newIndex, 0, photoToMove);
                reorderPhotos(photos.map((file) => file.uid));
            }
            else {
                console.warn("Could not reorder photos: oldIndex:", oldIndex, "newIndex:", newIndex);
            }
        }
    }, [photos, reorderPhotos]);
    const onFileUpload = React.useCallback((e) => {
        var _a;
        const fileUploads = (_a = e.target.files) !== null && _a !== void 0 ? _a : null;
        if (fileUploads && fileUploads.length > 0) {
            addFile(fileUploads);
        }
    }, [addFile]);
    const onPhotoUpload = React.useCallback((e) => {
        var _a;
        const fileUploads = (_a = e.target.files) !== null && _a !== void 0 ? _a : null;
        if (fileUploads && fileUploads.length > 0) {
            addPhoto(fileUploads);
        }
    }, [addPhoto]);
    return (React.createElement(Grid2, { container: true, spacing: 2, style: { width: "100%", margin: 0 } },
        React.createElement(Grid2, { size: 12 },
            React.createElement(Grid2, { container: true },
                React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "h6" }, "Files")),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add a File to the MAtrix") },
                        React.createElement(IconButton, { onClick: onUploadButtonClick("matrix-file-upload-input"), disabled: isUploadingFile || !files },
                            React.createElement(Add, null))),
                    React.createElement("input", { type: "file", id: "matrix-file-upload-input", accept: "application/pdf", multiple: true, style: { display: "none" }, onChange: onFileUpload })))),
        (isUploadingFile || !files) && (React.createElement(Grid2, { size: 12, style: { marginLeft: "auto", marginRight: "auto" } },
            React.createElement(CoreLoading, null))),
        !isUploadingFile && files && (React.createElement(Grid2, { size: 12 },
            React.createElement(Grid2, { container: true, spacing: 2 },
                React.createElement(Grid2, { container: true, style: {
                        whiteSpace: "nowrap",
                        width: "100%",
                        display: "block",
                        overflowX: "auto",
                        overflowY: "hidden",
                    } },
                    React.createElement(DndContext, { onDragEnd: onFileReorder, collisionDetection: closestCenter, modifiers: [restrictToHorizontalAxis] },
                        React.createElement(SortableContext, { items: getDraggableItems(files), strategy: horizontalListSortingStrategy }, files.map((file) => (React.createElement(MAtrixDraggable, { key: `${file.uid}_file_draggable`, uid: file.uid, name: React.createElement(CoreLink, { href: `/matrix-files/${file.uid}`, target: "_blank" }, file.filename), style: {
                                width: "300px",
                                maxWidth: "300px",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                                margin: "8px",
                                display: "inline-block",
                                // height: "unset",
                            }, onDelete: deleteFile },
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(ChromeExtensionCompatibleIframe, { src: `/matrix-files/${file.uid}`, title: file.filename, height: "400px", width: "100%" }))))))))))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Grid2, { container: true },
                React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "h6" }, "Property Photos")),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add a Property Photo to the MAtrix") },
                        React.createElement(IconButton, { onClick: onUploadButtonClick("matrix-photo-upload-input") },
                            React.createElement(Add, null))),
                    React.createElement("input", { type: "file", id: "matrix-photo-upload-input", accept: "image/jpg,image/jpeg,image/png", multiple: true, style: { display: "none" }, onChange: onPhotoUpload }))),
            React.createElement(Typography, null, "Accepts JPEGs or PNGs"),
            React.createElement(Typography, null, "Only the first photo will be included in reports")),
        (isUploadingPhoto || !photos) && React.createElement(CoreLoading, null),
        !isUploadingPhoto && photos && (React.createElement(Grid2, { size: 12 },
            React.createElement(Grid2, { container: true, spacing: 2 },
                React.createElement(Grid2, { container: true, style: {
                        whiteSpace: "nowrap",
                        width: "100%",
                        display: "block",
                        overflowX: "auto",
                        overflowY: "hidden",
                    } },
                    React.createElement(DndContext, { onDragEnd: onPhotoReorder, collisionDetection: closestCenter, modifiers: [restrictToHorizontalAxis] },
                        React.createElement(SortableContext, { items: getDraggableItems(photos), strategy: horizontalListSortingStrategy }, photos.map((photo) => (React.createElement(MAtrixDraggable, { key: `${photo.uid}_draggable_key`, uid: photo.uid, name: React.createElement(CoreLink, { href: `/matrix-photos/${photo.uid}`, target: "_blank" },
                                " ",
                                photo.filename,
                                " "), style: {
                                width: "300px",
                                maxWidth: "300px",
                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                                margin: "8px",
                                display: "inline-block",
                                // height: "unset",
                            }, onDelete: deletePhoto },
                            React.createElement(Grid2, { size: 12 },
                                React.createElement("img", { alt: "", src: `/matrix-photos/${photo.uid}`, width: 300 }))))))))))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { variant: "h6" }, "Reports")),
        React.createElement(Grid2, { size: 12 },
            React.createElement(List, { dense: true }, REPORT_TYPES.map((report, indx) => (React.createElement(ListItem, { key: report },
                React.createElement(ListItemText, { primary: `${indx + 1}. ${report}` }))))))));
};
export default FilesTab;
