import * as React from "react";
import { InputAdornment } from "@mui/material";
import CoreDateField from "@app/common/CoreDateField";
const DateTimeFilter = (props) => {
    var _a, _b, _c;
    const { CoreDateFieldProps, onChange, value } = props;
    const handleChange = React.useCallback((date) => {
        onChange(date);
    }, [onChange]);
    return (React.createElement(CoreDateField, Object.assign({ variant: "standard", fullWidth: true, hasPicker: true, format: "dddd, MMMM D, YYYY h:mm a", formatType: "dayjs" }, CoreDateFieldProps, { mode: "datetime", date: value !== null && value !== void 0 ? value : "", onDateChange: handleChange, slotProps: Object.assign(Object.assign({}, CoreDateFieldProps === null || CoreDateFieldProps === void 0 ? void 0 : CoreDateFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Timestamp") }, (_a = CoreDateFieldProps === null || CoreDateFieldProps === void 0 ? void 0 : CoreDateFieldProps.slotProps) === null || _a === void 0 ? void 0 : _a.input), inputLabel: Object.assign({ shrink: true }, (_b = CoreDateFieldProps === null || CoreDateFieldProps === void 0 ? void 0 : CoreDateFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.inputLabel), select: Object.assign({ displayEmpty: true }, (_c = CoreDateFieldProps === null || CoreDateFieldProps === void 0 ? void 0 : CoreDateFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.select) }) })));
};
export default DateTimeFilter;
