import * as React from "react";
import { Add, Clear, FileCopy } from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { copyToClipboard } from "@app/util/Utils";
const keywords = ["if", "else if", "else"];
const comparators = [
    "equal to",
    "not equal to",
    "greater than",
    "less than",
    "greater than or equal to",
    "less than or equal to",
    "is blank",
    "is not blank",
    "is checked",
    "is not checked",
];
const getMention = (fieldKey, fieldName) => `
        <span class="mention" data-id="${fieldKey}" data-value="${fieldName}">
            &#xFEFF;
            <span contenteditable="false">
                <span class="ql-mention-denotation-char">undefined</span>
                ${fieldName}
            </span>
            &#xFEFF;
        </span>
    `
    .split(/\n/g)
    .map((line) => line.replace(/\s+$/, "").replace(/^\s+/, ""))
    .join("");
class IfStatementBuilder extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                statements: [],
            }
        });
        Object.defineProperty(this, "addStatement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    statements: [
                        ...prevState.statements,
                        {
                            keyword: prevState.statements.length === 0 ? "if" : "else if",
                            fieldKey: this.props.fields[0].key,
                            comparator: "equal to",
                            value: "",
                            key: uuidv4(),
                        },
                    ],
                }));
            }
        });
        Object.defineProperty(this, "removeStatement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key) => () => {
                this.setState((prevState) => ({
                    statements: prevState.statements.filter((s) => s.key !== key),
                }));
            }
        });
        Object.defineProperty(this, "setStatementKeyword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key) => (e) => {
                const val = e.target.value;
                this.setState((prevState) => {
                    const statementIndx = prevState.statements.findIndex((s) => s.key === key);
                    return {
                        statements: [
                            ...prevState.statements.slice(0, statementIndx),
                            Object.assign(Object.assign({}, prevState.statements[statementIndx]), { keyword: val }),
                            ...(val === "else" ? [] : prevState.statements.slice(statementIndx + 1, prevState.statements.length)),
                        ],
                    };
                });
            }
        });
        Object.defineProperty(this, "setStatementField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key) => (e) => {
                const { fields } = this.props;
                const val = e.target.value;
                const field = fields.find((f) => f.key === val);
                this.setState((prevState) => {
                    const statementIndx = prevState.statements.findIndex((s) => s.key === key);
                    return {
                        statements: [
                            ...prevState.statements.slice(0, statementIndx),
                            Object.assign(Object.assign({}, prevState.statements[statementIndx]), { fieldKey: val, comparator: (() => {
                                    const statement = prevState.statements[statementIndx];
                                    if (field.type === "CHECKBOX" &&
                                        statement.comparator !== "is checked" &&
                                        statement.comparator !== "is not checked") {
                                        return "is checked";
                                    }
                                    if (field.type !== "CHECKBOX" &&
                                        (statement.comparator === "is checked" || statement.comparator === "is not checked")) {
                                        return "equal to";
                                    }
                                    return statement.comparator;
                                })() }),
                            ...prevState.statements.slice(statementIndx + 1, prevState.statements.length),
                        ],
                    };
                });
            }
        });
        Object.defineProperty(this, "setStatementComparator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key) => (e) => {
                const val = e.target.value;
                this.setState((prevState) => {
                    const statementIndx = prevState.statements.findIndex((s) => s.key === key);
                    return {
                        statements: [
                            ...prevState.statements.slice(0, statementIndx),
                            Object.assign(Object.assign({}, prevState.statements[statementIndx]), { comparator: val }),
                            ...prevState.statements.slice(statementIndx + 1, prevState.statements.length),
                        ],
                    };
                });
            }
        });
        Object.defineProperty(this, "setStatementValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (key) => (e) => {
                const val = e.target.value;
                this.setState((prevState) => {
                    const statementIndx = prevState.statements.findIndex((s) => s.key === key);
                    return {
                        statements: [
                            ...prevState.statements.slice(0, statementIndx),
                            Object.assign(Object.assign({}, prevState.statements[statementIndx]), { value: val }),
                            ...prevState.statements.slice(statementIndx + 1, prevState.statements.length),
                        ],
                    };
                });
            }
        });
        Object.defineProperty(this, "copyStatementToClipboard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                copyToClipboard(this.statementsAsString);
                enqueueSnackbar("Copied statement to clipboard.", { variant: "success" });
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
            this.setState({
                statements: [],
            });
        }
    }
    get statementsAsString() {
        var _a;
        const { statements } = this.state;
        const { fields, variant } = this.props;
        let str = "";
        for (const statement of statements) {
            const field = fields.find((f) => f.key === statement.fieldKey);
            str += "{% ";
            str += {
                if: "if",
                else: "else",
                "else if": "elif",
            }[statement.keyword];
            if (statement.keyword !== "else") {
                str += " ";
                const quot = field.type === "CHECKBOX" ? "" : "'";
                if (variant === "html") {
                    const mention = getMention(statement.fieldKey, field.name);
                    str += `${quot}${mention}${quot}`; // TODO: FIX
                }
                else {
                    str += `${quot}$[${statement.fieldKey}](${field.name})${quot}`;
                }
                str += " ";
                str += {
                    "equal to": "==",
                    "not equal to": "!=",
                    "greater than": ">",
                    "less than": "<",
                    "greater than or equal to": ">=",
                    "less than or equal to": "<=",
                    "is blank": "==",
                    "is not blank": "!=",
                    "is checked": "==",
                    "is not checked": "!=",
                }[statement.comparator];
                str += " ";
                if (field.type === "CHECKBOX") {
                    str += "true";
                }
                else {
                    str += `'${(_a = statement.value) !== null && _a !== void 0 ? _a : ""}'`;
                }
            }
            str += " %}";
        }
        str += "{% endif %}";
        return str;
    }
    get canAddStatement() {
        const { statements } = this.state;
        return !statements.find((s) => s.keyword === "else");
    }
    render() {
        const { statements } = this.state;
        const { fields } = this.props;
        return (React.createElement(Grid, { container: true, style: { margin: "-4px" } },
            React.createElement(Grid, { item: true, style: { padding: "4px" } },
                React.createElement(Typography, { variant: "h6" }, "If-Statement Builder")),
            React.createElement(Grid, { item: true, xs: 12, style: { padding: "4px" } },
                React.createElement(Grid, { container: true, spacing: 1 }, statements.map((s, i) => {
                    var _a;
                    const field = fields.find((f) => f.key === s.fieldKey);
                    return (React.createElement(Grid, { key: s.key, item: true, xs: 12 },
                        React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true, style: {
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    visibility: i === 0 ? "hidden" : undefined,
                                } },
                                React.createElement(Tooltip, { title: "Remove condition" },
                                    React.createElement("span", null,
                                        React.createElement(IconButton, { onClick: this.removeStatement(s.key), size: "small" },
                                            React.createElement(Clear, { fontSize: "small" }))))),
                            React.createElement(Grid, { item: true, style: { width: "75px" } },
                                React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, value: s.keyword, onChange: this.setStatementKeyword(s.key), disabled: i === 0 }, keywords
                                    .filter((keyword) => {
                                    if (i === 0) {
                                        return keyword === "if";
                                    }
                                    return keyword !== "if";
                                })
                                    .map((k) => (React.createElement(MenuItem, { key: k, value: k }, k))))),
                            s.keyword !== "else" && (React.createElement(React.Fragment, null,
                                React.createElement(Grid, { item: true, style: { width: "200px" } },
                                    React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, value: s.fieldKey, onChange: this.setStatementField(s.key) }, this.props.fields.map((f) => (React.createElement(MenuItem, { key: f.key, value: f.key }, f.name))))),
                                React.createElement(Grid, { item: true, style: { width: "200px" } },
                                    React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, value: s.comparator, onChange: this.setStatementComparator(s.key) }, comparators
                                        .filter((c) => (c === "is checked" || c === "is not checked") === ((field === null || field === void 0 ? void 0 : field.type) === "CHECKBOX"))
                                        .map((c) => (React.createElement(MenuItem, { key: c, value: c }, c))))),
                                s.comparator !== "is blank" &&
                                    s.comparator !== "is not blank" &&
                                    s.comparator !== "is checked" &&
                                    s.comparator !== "is not checked" && (React.createElement(Grid, { item: true, style: { flex: 1 } },
                                    React.createElement(TextField, { variant: "standard", fullWidth: true, value: (_a = s.value) !== null && _a !== void 0 ? _a : "", onChange: this.setStatementValue(s.key) }))))))));
                }))),
            React.createElement(Grid, { item: true, xs: 12, style: { padding: "4px" } },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { color: "inherit", onClick: this.addStatement, disabled: !this.canAddStatement, startIcon: React.createElement(Add, null) }, "Add a condition")),
                    statements.length > 0 && (React.createElement(Grid, { item: true },
                        React.createElement(Button, { variant: "outlined", color: "primary", startIcon: React.createElement(FileCopy, null), onClick: this.copyStatementToClipboard }, "Copy to Clipboard")))))));
    }
}
export default IfStatementBuilder;
