import * as React from "react";
import { CallEnd, Close, Phone } from "@mui/icons-material";
import { InputAdornment, TextField, Button, MenuItem, CircularProgress, Grid2, Typography } from "@mui/material";
import { Call } from "@twilio/voice-sdk";
import { useMeasure } from "react-use";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { formatPhoneNumber, isValidPhoneNumber } from "@app/util/Utils";
import { TwilioCallContext } from "./TwilioCallContext";
import useTwilioPhonePreference from "./useTwilioPhonePreference";
import TwilioSMSRecipientSelect from "../twilio-sms/TwilioSMSRecipientSelect";
const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
};
const NoIconComponent = () => null;
const minInputElementWidth = 500;
const TwilioDialWidget = (props) => {
    var _a, _b, _c;
    const { contacts, containerProps, defaultExternalPhone, defaultTwilioPhone, onCallPressed, onClose } = props;
    const context = React.useContext(TwilioCallContext);
    React.useEffect(() => {
        if (defaultExternalPhone) {
            context.setTo(defaultExternalPhone);
        }
        if (defaultTwilioPhone && isValidPhoneNumber(defaultTwilioPhone)) {
            context.setFrom(defaultTwilioPhone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const twilioPreferences = useTwilioPhonePreference();
    console.log({ defaultExternalPhone, defaultTwilioPhone, to: context.to });
    React.useEffect(() => {
        const lastSelectedPhone = twilioPreferences.getLastSelectedTwilioPhone();
        if (lastSelectedPhone) {
            context.setFrom(lastSelectedPhone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [ref, measure] = useMeasure();
    const xs = React.useMemo(() => {
        if (measure.width <= minInputElementWidth || !contacts || contacts.length === 0) {
            return 12;
        }
        return 6;
    }, [contacts, measure.width]);
    const listTwilioPhonesApi = useListCachedTwilioPhones({ access_only: true, include_agent_phones: true, include_proxy_phones: false }, INFINITE_CACHE_PARAMS);
    const twilioPhones = (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const firstContactWithPhoneNumber = React.useMemo(() => {
        return contacts === null || contacts === void 0 ? void 0 : contacts.find((x) => x.phone_number);
    }, [contacts]);
    const [selectedContact, setSelectedContact] = React.useState(firstContactWithPhoneNumber
        ? ((_c = formatPhoneNumber(firstContactWithPhoneNumber.phone_number, { format: "international" })) !== null && _c !== void 0 ? _c : null)
        : null);
    React.useEffect(() => {
        var _a, _b, _c;
        context.setTo((_b = (_a = firstContactWithPhoneNumber === null || firstContactWithPhoneNumber === void 0 ? void 0 : firstContactWithPhoneNumber.phone_number) !== null && _a !== void 0 ? _a : defaultExternalPhone) !== null && _b !== void 0 ? _b : "");
        setSelectedContact((firstContactWithPhoneNumber === null || firstContactWithPhoneNumber === void 0 ? void 0 : firstContactWithPhoneNumber.phone_number)
            ? ((_c = formatPhoneNumber(firstContactWithPhoneNumber.phone_number, { format: "international" })) !== null && _c !== void 0 ? _c : null)
            : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstContactWithPhoneNumber]);
    const containerRef = React.useRef(null);
    const handleFromChanged = React.useCallback((e) => {
        context.setFrom(e.target.value);
        twilioPreferences.setLastSelectedTwilioPhone(e.target.value);
    }, [context, twilioPreferences]);
    const selectContact = React.useCallback((e) => {
        context.setTo(e.target.value);
        setSelectedContact(e.target.value);
    }, [context]);
    const handleToChanged = React.useCallback((value) => {
        context.setTo(value !== null && value !== void 0 ? value : "");
        setSelectedContact(null);
    }, [context]);
    const handleCancel = React.useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);
    React.useEffect(() => {
        setTimeout(() => {
            document.addEventListener("keydown", onKeyDown, false);
            document.addEventListener("click", onClick, false);
        }, 1);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
            document.removeEventListener("click", onClick, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onKeyDown = React.useCallback((e) => {
        if (e.keyCode === 27 && onClose) {
            onClose();
        }
    }, [onClose]);
    const onClick = React.useCallback((e) => {
        var _a;
        if (onClose) {
            const offset = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (offset != null) {
                if (e.clientX < offset.x ||
                    e.clientX > offset.x + offset.width ||
                    e.clientY < offset.y ||
                    e.clientY > offset.y + offset.height) {
                    onClose();
                }
            }
        }
    }, [onClose]);
    const onCall = React.useCallback(() => {
        if (onCallPressed) {
            onCallPressed();
        }
        context.call();
    }, [context, onCallPressed]);
    const hangup = React.useCallback(() => {
        context.calls.forEach((call) => {
            call.call.disconnect();
        });
    }, [context]);
    console.log({ contacts, selectedContact });
    return (React.createElement(Grid2, Object.assign({ ref: ref, container: true, spacing: 2 }, containerProps),
        React.createElement(Grid2, { size: xs },
            React.createElement(TextField, { select: true, size: "small", fullWidth: true, slotProps: {
                    input: {
                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "From"),
                        endAdornment: listTwilioPhonesApi.isFetching && !twilioPhones ? (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(CircularProgress, { size: 20 }))) : undefined,
                    },
                    select: {
                        IconComponent: !twilioPhones ? NoIconComponent : undefined,
                    },
                }, disabled: !twilioPhones, value: context.from, onChange: handleFromChanged }, (twilioPhones !== null && twilioPhones !== void 0 ? twilioPhones : []).map((phone) => (React.createElement(MenuItem, { key: phone.phone_number, value: phone.phone_number, onClick: preventDefault }, phone.friendly_name || phone.phone_number))))),
        contacts && contacts.length > 0 && (React.createElement(Grid2, { size: selectedContact ? xs : xs / 2 },
            React.createElement(TextField, { select: true, size: "small", fullWidth: true, slotProps: {
                    input: {
                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "To"),
                    },
                    select: {
                        displayEmpty: true,
                    },
                }, value: selectedContact ? formatPhoneNumber(selectedContact, { format: "international" }) : "", onChange: selectContact },
                React.createElement(MenuItem, { value: "" }, "Select a contact"),
                contacts.map((c) => (React.createElement(MenuItem, { key: formatPhoneNumber(c.phone_number, { format: "international" }), value: formatPhoneNumber(c.phone_number, { format: "international" }), onClick: preventDefault }, c.name || c.phone_number)))))),
        !selectedContact && (React.createElement(Grid2, { size: contacts && contacts.length > 0 ? xs / 2 : xs },
            React.createElement(TwilioSMSRecipientSelect, { phoneNumber: context.to, onPhoneNumberChanged: handleToChanged, TextFieldProps: {
                    slotProps: {
                        input: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement(Phone, null))),
                        },
                    },
                    helperText: context.to && isValidPhoneNumber(context.to) ? `Phone number: ${context.to}` : undefined,
                } }))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Grid2, { container: true, spacing: 1 },
                !context.isActive && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "caption", style: { color: "#888" } }, "Loading Twilio..."))),
                React.createElement(Grid2, { style: { flex: 1 } }),
                onClose && (React.createElement(Grid2, null,
                    React.createElement(Button, { onClick: handleCancel, startIcon: React.createElement(Close, null), tabIndex: -1 }, "Cancel"))),
                context.calls.filter((x) => x.call.status() !== Call.State.Closed).length === 1 && (React.createElement(Grid2, null,
                    React.createElement(Button, { color: "secondary", onClick: hangup, startIcon: React.createElement(CallEnd, null), tabIndex: -1 }, "Hang up"))),
                React.createElement(Grid2, null,
                    React.createElement(Button, { color: "primary", disabled: !context.canMakeCall || !twilioPhones, onClick: onCall, startIcon: React.createElement(Phone, null), tabIndex: -1 }, "Call"))))));
};
export default TwilioDialWidget;
