import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Home, Label, PhotoCamera } from "@mui/icons-material";
import { Box, Grid2, InputAdornment, Tabs, Tab, Alert, Typography, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CoreAnchor from "@app/common/CoreAnchor";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import AgedPropertyReportTab from "@app/entrypoints/brokerage/features/apps/matrix/components/AgedPropertyReportTab";
import ArcGISSidebar from "@app/entrypoints/brokerage/features/apps/matrix/components/ArcGISSidebar";
import CalculationsTab from "@app/entrypoints/brokerage/features/apps/matrix/components/CalculationsTab";
import CMACompsTab from "@app/entrypoints/brokerage/features/apps/matrix/components/CMACompsTab";
import FilesTab from "@app/entrypoints/brokerage/features/apps/matrix/components/FilesTab";
import NetSheetTab from "@app/entrypoints/brokerage/features/apps/matrix/components/NetSheetTab";
import OfferTab from "@app/entrypoints/brokerage/features/apps/matrix/components/OfferTab";
import PSPCommentsSidebar from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPCommentsSidebar";
import PSPCompsFinderTab from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPCompsFinderTab";
import PSPCompsSidebar from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPCompsSidebar";
import PSPCompsTab from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPCompsTab";
import PSPTab from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPTab";
import Toolbar from "@app/entrypoints/brokerage/features/apps/matrix/components/Toolbar";
import useMAtrixApis from "@app/entrypoints/brokerage/features/apps/matrix/useMAtrixApis";
import { getFieldDefinitionsByName, TAB_NAMES } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import useSession from "@app/hooks/useSession";
import { geocodeWithGoogleMaps, getGoogleMapsStreetViewPhoto } from "@app/orval/api/geocode";
import { updatePsp } from "@app/orval/api/psps";
import MAtrixInvestor from "./matrix-form/MAtrixInvestor";
const getTheme = (parentTheme) => {
    var _a, _b, _c;
    return createTheme(Object.assign(Object.assign({}, parentTheme), { components: Object.assign(Object.assign({}, parentTheme.components), { MuiTab: Object.assign(Object.assign({}, (_a = parentTheme.components) === null || _a === void 0 ? void 0 : _a.MuiTab), { styleOverrides: Object.assign(Object.assign({}, (_c = (_b = parentTheme.components) === null || _b === void 0 ? void 0 : _b.MuiTab) === null || _c === void 0 ? void 0 : _c.styleOverrides), { root: {
                        [parentTheme.breakpoints.up("xs")]: {
                            minWidth: "72px",
                        },
                        [parentTheme.breakpoints.up("sm")]: {
                            minWidth: "160px",
                        },
                    } }) }) }) }));
};
const MAtrixForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [streetViewPhotoUrl, setStreetViewPhotoUrl] = React.useState(null);
    const [currentSidebar, setCurrentSidebar] = React.useState(null);
    const { currentTab, uid } = props;
    const session = useSession();
    const { addCMAComp, addFile, addOffer, addPSPComp, addPhoto, bulkCreatePSPComps, bulkUpdateAndCreateCMAComps, bulkUpdateAndCreatePSPComps, bulkUpdateFields, cmaCompFieldDefinitions, cmaCompsExtended, deleteCMAComp, deleteFile, deleteOffer, deletePSPComp, deletePhoto, isAddingCMAComp, isAddingOffer, isAddingPSPComp, isBulkAddingPSPComps, isBulkUpdatingFields, isGettingCMACompsExtended, isUploadingFile, isUploadingPropertyPhoto, matrix, matrixFieldDefinitions, offersFieldDefinitions, onCMACompFieldSaveComplete, onFieldSaveBegin, onFieldSaveComplete, onOfferFieldSaveComplete, onPSPCompFieldSaveComplete, onPSPCompQualityUpdate, propertyAddress, pspCompFieldDefinitions, pspCompTypes, pspCompsCurrentlyUpdatingQuality, pspError, pspFiles, pspPhotos, pspReportTemplates, refreshPSP, reorderCMAComps, reorderOffers, reorderPSPComps, reorderPSPFiles, reorderPSPPhotos, submitTrainingMAtrix, users, } = useMAtrixApis(uid);
    const matrixSchema = React.useMemo(() => {
        return getFieldDefinitionsByName(matrixFieldDefinitions);
    }, [matrixFieldDefinitions]);
    const offerSchema = React.useMemo(() => {
        return getFieldDefinitionsByName(offersFieldDefinitions);
    }, [offersFieldDefinitions]);
    const cmaCompSchema = React.useMemo(() => {
        return getFieldDefinitionsByName(cmaCompFieldDefinitions);
    }, [cmaCompFieldDefinitions]);
    const visibleTabs = React.useMemo(() => {
        return TAB_NAMES.filter((tab) => {
            var _a, _b;
            const label = tab.toLowerCase().trim().replace(/\-/g, " ");
            const isInvestorPSP = matrix !== null ? matrix.fields["Investor PSP?"] : false;
            const hidden = (label === "calculations" && !((_a = session === null || session === void 0 ? void 0 : session.activeUser) === null || _a === void 0 ? void 0 : _a.isDeveloper)) ||
                (label === "comparables" && !((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin", "Market Analyst"]))) ||
                (label === "investor" && !isInvestorPSP);
            return !hidden;
        });
    }, [matrix, (_a = session === null || session === void 0 ? void 0 : session.activeUser) === null || _a === void 0 ? void 0 : _a.isDeveloper, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const visibleTabsLowercase = React.useMemo(() => {
        return visibleTabs.map((tab) => tab.toLowerCase().trim());
    }, [visibleTabs]);
    const invalidFiles = React.useMemo(() => {
        return (pspFiles !== null && pspFiles !== void 0 ? pspFiles : []).filter((file) => file.mime_type !== "application/pdf");
    }, [pspFiles]);
    const onStreetViewPhotoChange = React.useCallback((address) => {
        console.log("get street view photo for address:", address);
        if (address.trim() !== "") {
            getGoogleMapsStreetViewPhoto({ address })
                .then((result) => {
                setStreetViewPhotoUrl(result.data.photo_url);
            })
                .catch((error) => {
                if (error.name !== "AbortError") {
                    console.error(`error getting street view photo${error}`);
                }
            });
        }
        else {
            setStreetViewPhotoUrl(null);
        }
    }, [setStreetViewPhotoUrl]);
    const getCountyFromAddress = React.useCallback((address) => {
        geocodeWithGoogleMaps({
            address,
        })
            .then((geocodeResult) => {
            const rawGeocode = geocodeResult.data.raw
                ? JSON.parse(geocodeResult.data.raw)
                : { address_components: [] };
            let county = null;
            rawGeocode["address_components"].forEach((item) => {
                if (item.types.includes("administrative_area_level_2")) {
                    county = item.long_name.replace(" County", "");
                }
            });
            onFieldSaveBegin("County")(county);
            updatePsp(uid, { fields: { County: county } })
                .then((_) => {
                console.log("Updated county field");
            })
                .catch((error) => {
                console.error(`There was an error updating the County field: '${county}'. Error: ${error}`);
            });
        })
            .catch((error) => {
            enqueueErrorSnackbar(`There was an error geocoding the address: '${address}'. Check that the address is spelled and formatted correctly. Error: ${error}`);
        });
    }, [onFieldSaveBegin, uid]);
    React.useEffect(() => {
        onStreetViewPhotoChange((propertyAddress !== null && propertyAddress !== void 0 ? propertyAddress : "").trim());
        if (propertyAddress) {
            getCountyFromAddress(propertyAddress.trim());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyAddress]);
    const onSidebarChange = React.useCallback((sidebar) => {
        setCurrentSidebar((prevState) => {
            return prevState === sidebar ? null : sidebar;
        });
    }, [setCurrentSidebar]);
    const sidebarEl = React.useMemo(() => {
        var _a;
        let el = null;
        const fields = matrix ? matrix.fields : null;
        if (currentSidebar === "ArcGIS") {
            el = React.createElement(ArcGISSidebar, { address: (_a = matrix.fields["Property Address"]) !== null && _a !== void 0 ? _a : null, closeSidebar: onSidebarChange });
        }
        else if (currentSidebar === "PSP Comps") {
            const pspType = fields ? fields["PSP Type"] : null;
            const pspSqft = fields ? fields["Finished SqFt"] : null;
            const pspValueMin = fields ? fields["PSP Value (Min)"].value : null;
            const pspValueHigh = fields ? fields["PSP Value (High)"].value : null;
            const pspListPrice = fields ? fields["Current List Price"] : null;
            el = (React.createElement(PSPCompsSidebar, { matrixUid: props.uid, matrixFields: fields, matrixSchema: matrixSchema, pspComps: matrix ? matrix.psp_comps : null, pspType: pspType !== null && pspType !== void 0 ? pspType : null, pspSqft: pspSqft !== null && pspSqft !== void 0 ? pspSqft : null, pspValueMin: pspValueMin !== null && pspValueMin !== void 0 ? pspValueMin : null, pspValueHigh: pspValueHigh !== null && pspValueHigh !== void 0 ? pspValueHigh : null, pspListPrice: pspListPrice !== null && pspListPrice !== void 0 ? pspListPrice : null, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, closeSidebar: onSidebarChange }));
        }
        else if (currentSidebar === "Comments") {
            const comments = fields ? fields["Comments"] : null;
            const agentUid = fields ? fields["Agent"] : null;
            const pspType = fields ? fields["PSP Type"] : null;
            el = (React.createElement(PSPCommentsSidebar, { matrixUid: props.uid, users: users, comments: comments !== null && comments !== void 0 ? comments : null, pspType: pspType !== null && pspType !== void 0 ? pspType : null, agentUid: agentUid !== null && agentUid !== void 0 ? agentUid : null, matrixFields: fields, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, closeSidebar: onSidebarChange }));
        }
        return el;
    }, [currentSidebar, onSidebarChange, matrix, matrixSchema, onFieldSaveBegin, props.uid, users, onFieldSaveComplete]);
    if (pspError) {
        return React.createElement(CoreError, { error: pspError });
    }
    if (!matrix || !matrixSchema) {
        return React.createElement(CoreLoading, null);
    }
    console.log("matrix:", matrix);
    const boxKey = (_b = matrix.fields["Box Key"]) !== null && _b !== void 0 ? _b : null;
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(ThemeProvider, { theme: getTheme },
            React.createElement(Box, { style: { height: "calc(100% - 16px)" } },
                invalidFiles.length > 0 && (React.createElement(Alert, { severity: "warning", style: { marginBottom: "8px" } },
                    React.createElement(Typography, null,
                        React.createElement("strong", null, "Warning:"),
                        " Uploading files that are not PDFs can cause errors or unexpected behavior in some MAtrix features.:"),
                    invalidFiles.map((file) => (React.createElement(Typography, { key: file.uid }, file.filename))))),
                React.createElement(Grid2, { container: true, style: { background: "#f0f8ff" } },
                    React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Grid2, { container: true, spacing: 1, style: { paddingLeft: "4px" } },
                            React.createElement(Grid2, { size: boxKey ? 3 : 4 },
                                React.createElement(DashAutoSaveField, { placeholder: "Property Address", fullWidth: true, field: matrixSchema["Property Address"], value: matrix.fields["Property Address"], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onFieldSaveBegin("Property Address"), onSuccess: onFieldSaveComplete, size: "small", 
                                    // @ts-expect-error: outlined not assignable to type standard
                                    variant: "outlined", slotProps: {
                                        input: {
                                            startAdornment: (React.createElement(InputAdornment, { position: "start" }, streetViewPhotoUrl ? (React.createElement(CoreAnchor, { target: "_blank", href: streetViewPhotoUrl, rel: "noreferrer" },
                                                React.createElement("img", { src: streetViewPhotoUrl, alt: "Street View", height: "24", style: {
                                                        borderRadius: "4px",
                                                    } }))) : (React.createElement(Home, null)))),
                                        },
                                    } })),
                            React.createElement(Grid2, { size: boxKey ? 3 : 4 },
                                React.createElement(DashAutoSaveField, { fullWidth: true, placeholder: "MLS ID", field: matrixSchema["MLS ID #"], value: matrix.fields["MLS ID #"], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onFieldSaveBegin("MLS ID #"), onSuccess: onFieldSaveComplete, size: "small", 
                                    // @ts-expect-error: outlined not assignable to type standard
                                    variant: "outlined", slotProps: {
                                        input: {
                                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                                React.createElement(Label, null))),
                                        },
                                    } })),
                            React.createElement(Grid2, { size: boxKey ? 3 : 4 },
                                React.createElement(DashAutoSaveField, { fullWidth: true, placeholder: "Photo URL", field: matrixSchema["Photo URL"], value: matrix.fields["Photo URL"], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onFieldSaveBegin("Photo URL"), onSuccess: onFieldSaveComplete, size: "small", 
                                    // @ts-expect-error: outlined not assignable to type standard
                                    variant: "outlined", slotProps: {
                                        input: {
                                            startAdornment: (React.createElement(InputAdornment, { position: "start" }, matrix.fields["Photo URL"] ? (React.createElement(CoreAnchor, { target: "_blank", href: matrix.fields["Photo URL"], rel: "noreferrer" },
                                                React.createElement("img", { src: matrix.fields["Photo URL"], alt: "Property", height: "24", style: {
                                                        borderRadius: "4px",
                                                    } }))) : (React.createElement(PhotoCamera, null)))),
                                        },
                                    } })),
                            boxKey && (React.createElement(Grid2, { size: 3, style: { display: "flex", alignItems: "center" } },
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, boxKey) },
                                    React.createElement(Typography, { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }, boxKey)))))),
                    React.createElement(Grid2, null,
                        React.createElement(Toolbar, { uid: uid, matrix: matrix, propertyAddress: (_c = matrix.fields["Property Address"]) !== null && _c !== void 0 ? _c : null, matrixSchema: matrixSchema, pspComps: matrix.psp_comps, cmaComps: matrix.cma_comps, trainingReferenceUid: matrix.training_reference_uid, trainingSubmissions: matrix.training_submissions, pspReportTemplates: pspReportTemplates, bulkUpdateFields: bulkUpdateFields, bulkUpdatePSPComps: bulkUpdateAndCreatePSPComps, bulkUpdateCMAComps: bulkUpdateAndCreateCMAComps, submitTrainingMAtrix: submitTrainingMAtrix, setSidebar: onSidebarChange, refreshPSP: refreshPSP }))),
                React.createElement(Tabs, { value: visibleTabsLowercase.includes(currentTab.toLowerCase().trim())
                        ? visibleTabsLowercase.indexOf(currentTab.toLowerCase().trim())
                        : 0, variant: "scrollable", scrollButtons: "auto", indicatorColor: "primary", textColor: "primary", style: { background: "#fafcff" } }, visibleTabs.map((tab) => {
                    const label = tab.toLowerCase().trim().replace(/\-/g, " ");
                    return (React.createElement(Tab, { key: tab, component: RouterLink, to: `/matrix/${uid}/${tab.toLowerCase().trim()}`, label: label }));
                })),
                React.createElement(Box, { pt: 2, style: {
                        height: "calc(100% - 97px)",
                        overflowY: "auto",
                    } },
                    currentTab.toLowerCase().trim() === "cma-comps" && (React.createElement(CMACompsTab, { uid: uid, matrixFields: matrix.fields, matrixSchema: matrixSchema, cmaComps: cmaCompsExtended, cmaCompSchema: cmaCompSchema, isAddingCMAComp: !!isAddingCMAComp, isGettingCMAComps: !!isGettingCMACompsExtended, addCMAComp: addCMAComp, updateCMAComp: onCMACompFieldSaveComplete, deleteCMAComp: deleteCMAComp, reorderCMAComps: reorderCMAComps, onMAtrixFieldSaveBegin: onFieldSaveBegin, onMAtrixFieldSaveComplete: onFieldSaveComplete })),
                    currentTab.toLowerCase().trim() === "files" && (React.createElement(FilesTab, { files: pspFiles, photos: pspPhotos, isUploadingFile: isUploadingFile, isUploadingPhoto: isUploadingPropertyPhoto, addFile: addFile, addPhoto: addPhoto, deleteFile: deleteFile, deletePhoto: deletePhoto, reorderFiles: reorderPSPFiles, reorderPhotos: reorderPSPPhotos })),
                    currentTab.toLowerCase().trim() === "aged-property-report" && (React.createElement(AgedPropertyReportTab, { uid: uid, schema: matrixSchema, matrixFields: matrix.fields, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete })),
                    currentTab.toLowerCase().trim() === "psp" && (React.createElement(PSPTab, { uid: uid, matrixSchema: matrixSchema, matrixFields: matrix.fields, users: users, pspType: (_d = matrix.fields["PSP Type"]) !== null && _d !== void 0 ? _d : null, noRPR: (_e = matrix.fields["No RPR"]) !== null && _e !== void 0 ? _e : false, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete })),
                    currentTab.toLowerCase().trim() === "net-sheet" && (React.createElement(NetSheetTab, { uid: uid, matrixSchema: matrixSchema, matrixFields: matrix.fields, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete })),
                    currentTab.toLowerCase().trim() === "comps" && (React.createElement(PSPCompsTab, { pspComps: matrix.psp_comps, pspCompSchema: pspCompFieldDefinitions, pspCompTypes: pspCompTypes, pspCompsCurrentlyUpdatingQuality: pspCompsCurrentlyUpdatingQuality, isAddingPSPComp: !!isAddingPSPComp, addPSPComp: addPSPComp, updatePSPComp: onPSPCompFieldSaveComplete, deletePSPComp: deletePSPComp, reorderPSPComps: reorderPSPComps, updatePSPCompQuality: onPSPCompQualityUpdate })),
                    React.createElement(Box, { style: { height: "calc(100% - 16px)" }, display: currentTab.toLowerCase().trim() === "comps-finder" ? "block" : "none" },
                        React.createElement(PSPCompsFinderTab, { uid: uid, propertyAddress: (_f = matrix.fields["Property Address"]) !== null && _f !== void 0 ? _f : null, mlsId: (_g = matrix.fields["MLS ID #"]) !== null && _g !== void 0 ? _g : null, isBulkAddingPSPComps: isBulkAddingPSPComps, isAddingSubjectPropertyData: isBulkUpdatingFields, bulkAddPSPComps: bulkCreatePSPComps, bulkUpdateFields: bulkUpdateFields })),
                    currentTab.toLowerCase().trim() === "offers" && (React.createElement(OfferTab, { uid: uid, offers: matrix.offers, matrixFields: matrix.fields, matrixSchema: matrixSchema, offerSchema: offerSchema, isAddingOffer: isAddingOffer, addOffer: addOffer, updateOffer: onOfferFieldSaveComplete, deleteOffer: deleteOffer, reorderOffers: reorderOffers, onMAtrixFieldSaveBegin: onFieldSaveBegin, onMAtrixFieldSaveComplete: onFieldSaveComplete })),
                    currentTab.toLowerCase().trim() === "calculations" && ((_h = session === null || session === void 0 ? void 0 : session.activeUser) === null || _h === void 0 ? void 0 : _h.isDeveloper) && (React.createElement(CalculationsTab, { uid: uid, matrixSchema: matrixSchema, matrixFields: matrix.fields, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete })),
                    currentTab.toLowerCase().trim() === "investor" && (React.createElement(MAtrixInvestor, { matrix: matrix, matrixSchema: matrixSchema }))))), sidebar: sidebarEl, sidebarWidth: 300, opened: !!currentSidebar, isResizable: true }));
};
export default MAtrixForm;
