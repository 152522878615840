/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all offices
 */
export const listOffices = (options) => {
    return axios.get(`/api/offices`, options);
};
export const getListOfficesQueryKey = () => {
    return [`/api/offices`];
};
export const getListOfficesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListOfficesQueryKey();
    const queryFn = ({ signal }) => listOffices(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all offices
 */
export function useListOffices(options) {
    const queryOptions = getListOfficesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create an office
 */
export const addOffice = (officeWriteRequest, options) => {
    return axios.post(`/api/offices`, officeWriteRequest, options);
};
export const getAddOfficeMutationOptions = (options) => {
    const mutationKey = ['addOffice'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addOffice(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create an office
*/
export const useAddOffice = (options) => {
    const mutationOptions = getAddOfficeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Get an office
*/
export const getOffice = (uid, options) => {
    return axios.get(`/api/offices/${uid}`, options);
};
export const getGetOfficeQueryKey = (uid) => {
    return [`/api/offices/${uid}`];
};
export const getGetOfficeQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetOfficeQueryKey(uid);
    const queryFn = ({ signal }) => getOffice(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an office
 */
export function useGetOffice(uid, options) {
    const queryOptions = getGetOfficeQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update an office
 */
export const updateOffice = (uid, officeWriteRequest, options) => {
    return axios.post(`/api/offices/${uid}`, officeWriteRequest, options);
};
export const getUpdateOfficeMutationOptions = (options) => {
    const mutationKey = ['updateOffice'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateOffice(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an office
*/
export const useUpdateOffice = (options) => {
    const mutationOptions = getUpdateOfficeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete an office
*/
export const deleteOffice = (uid, options) => {
    return axios.delete(`/api/offices/${uid}`, options);
};
export const getDeleteOfficeMutationOptions = (options) => {
    const mutationKey = ['deleteOffice'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteOffice(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete an office
*/
export const useDeleteOffice = (options) => {
    const mutationOptions = getDeleteOfficeMutationOptions(options);
    return useMutation(mutationOptions);
};
