import { createTheme } from "@mui/material/styles";
import dayjs_ from "@app/common/dayjs";
import { getBaseThemeOptions } from "@app/common/grid/theme";
import { activeStatuses, allMlsStatuses, closedStatuses, pendingStatuses } from "@app/util/Utils";
export const COMPS_PULLER_VISUALS = ["google map", "arcgis map", "scatterplot"];
export const mapStyles = {
    height: "100%",
    width: "100%",
    maxHeight: "100%",
    maxWidth: "100%",
    margin: "auto",
};
export const activeStatusesLowercase = activeStatuses.map((status) => status.toLowerCase().trim());
export const pendingStatusesLowercase = pendingStatuses.map((status) => status.toLowerCase().trim());
export const closedStatusesLowercase = closedStatuses.map((status) => status.toLowerCase().trim());
export const allMlsStatusesLowercase = allMlsStatuses.map((status) => status.toLowerCase().trim());
/**
 * Outputs the `PropertyType` of a given property, which may be pulled from a different field depending on MLS.
 */
export const getPropertyType = (
/**
 * Key-value pairs of MLS data
 */
property) => {
    var _a, _b;
    if (Object.keys(property).includes("PropertyTypeLabel")) {
        return (_a = property["PropertyTypeLabel"]) !== null && _a !== void 0 ? _a : null;
    }
    return (_b = property["PropertyType"]) !== null && _b !== void 0 ? _b : null;
};
/**
 * Outputs a formatted addressed based on key-value pairs of MLS data.
 * This endpoint is designed to work with RESO-compliant data,
 * so this should work with both Bridge API and MLS Grid.
 */
export const getAddress = (
/**
 * Key-value pairs of MLS data
 */
property) => [
    [
        [
            property["StreetNumber"],
            property["StreetName"],
            property["StreetSuffix"],
            property["UnitNumber"] ? `#${property["UnitNumber"]}` : property["UnitNumber"],
        ]
            .filter((item) => item)
            .join(" "),
        property["City"],
        property["State"],
    ]
        .filter((item) => item)
        .join(", "),
    property["PostalCode"],
]
    .filter((item) => item)
    .join(" ");
/**
 * Outputs the total living area for the property, which may be pulled from a different field
 * depending on MLS.
 * @param property - key value pairs of MLS data
 * @returns the living area
 */
export const getLivingArea = (
/**
 * Key-value pairs of MLS data
 */
property, 
/**
 * The MLS used to get the property data
 */
mls) => {
    var _a, _b, _c, _d, _e;
    if (mls === "triangle" || mls === "triadmls") {
        return (_b = (_a = property["LivingArea"]) !== null && _a !== void 0 ? _a : property["BuildingAreaTotal"]) !== null && _b !== void 0 ? _b : null;
    }
    if (mls === "canopy") {
        return (_d = (_c = property["LivingArea"]) !== null && _c !== void 0 ? _c : property["CAR_SqFtTotalPropertyHLA"]) !== null && _d !== void 0 ? _d : null;
    }
    if (mls === "flexmls") {
        return (_e = property["BuildingAreaTotal"]) !== null && _e !== void 0 ? _e : null;
    }
    return null;
};
/**
 * Outputs the total acreage for the property, which may be pulled from a different field
 * depending on MLS.
 * @param property - key value pairs of MLS data
 * @returns the acreage or null
 */
export const getAcreage = (
/**
 * Key-value pairs of MLS data
 */
property) => {
    let acreage = null;
    if (Object.keys(property).includes("LotSizeAcres")) {
        acreage = property["LotSizeAcres"];
    }
    else if (Object.keys(property).includes("LotSizeArea")) {
        acreage = property["LotSizeArea"];
    }
    return acreage;
};
/**
 * Outputs the total bedrooms for the property, which may be pulled from a different field
 * depending on MLS.
 * @param property - key value pairs of MLS data
 * @returns total beds
 */
export const getBedroomsTotal = (
/**
 * Key-value pairs of MLS data
 */
property) => { var _a, _b; return (_b = (_a = property["BedroomsTotal"]) !== null && _a !== void 0 ? _a : property["BedsTotal"]) !== null && _b !== void 0 ? _b : null; };
/**
 * Outputs the number of full bathrooms for the property, which may be pulled from a different field
 * depending on MLS.
 * @param property - key value pairs of MLS data
 * @returns number of full baths
 */
export const getBathroomsFull = (
/**
 * Key-value pairs of MLS data
 */
property) => { var _a, _b; return (_b = (_a = property["BathroomsFull"]) !== null && _a !== void 0 ? _a : property["BathsFull"]) !== null && _b !== void 0 ? _b : null; };
/**
 * Outputs the SPLP for the property, which may be pulled from a different field
 * depending on MLS. Uses OriginalListPrice for the list price.
 * @param property - key value pairs of MLS data
 * @returns OriginalListPrice / ClosePrice
 */
export const getSPLP = (
/**
 * Key-value pairs of MLS data
 */
property) => {
    const closePrice = property["ClosePrice"];
    const listPrice = property["OriginalListPrice"];
    if (closePrice && listPrice) {
        return closePrice / listPrice;
    }
    return null;
};
/**
 * Outputs the dom for the property, which may be pulled from a different field
 * depending on MLS.
 * @param property - key value pairs of MLS data
 * @returns days on market
 */
export const getDaysOnMarket = (
/**
 * Key-value pairs of MLS data
 */
property, 
/**
 * The MLS used to get the property data
 */
mls) => {
    const listingContractDate = property["ListingContractDate"];
    const closeDate = property["CloseDate"];
    const purchaseContractDate = property["PurchaseContractDate"];
    const offMarketDate = property["OffMarketDate"];
    const onMarketDate = property["OnMarketDate"];
    const milliseconds = 86400000;
    if (mls === "triangle" || mls === "triadmls") {
        // bridge DOM is inaccurate for Active properties and the field may be removed
        if (closeDate && mls === "triadmls") {
            return property["DaysOnMarket"] != null ? property["DaysOnMarket"] : null;
        }
        if (listingContractDate && purchaseContractDate) {
            return Math.floor(dayjs_(purchaseContractDate).diff(dayjs_(listingContractDate)) / milliseconds);
        }
        if (listingContractDate && !closeDate) {
            return Math.floor(dayjs_(new Date()).diff(dayjs_(listingContractDate)) / milliseconds);
        }
        return null;
    }
    if (Object.keys(property).includes("DaysOnMarket")) {
        return property["DaysOnMarket"];
    }
    if (onMarketDate) {
        // Spark does not have a DaysOnMarket field
        const endDate = offMarketDate ? dayjs_(offMarketDate) : new Date();
        return Math.floor(dayjs_(endDate).diff(dayjs_(onMarketDate)) / milliseconds);
    }
    return null;
};
export const getPricePerSquareFoot = (property) => {
    const closePrice = property.data["ClosePrice"];
    const listPrice = property.data["ListPrice"];
    const squareFeet = getLivingArea(property.data, property.source);
    if ((closePrice != null || listPrice != null) && squareFeet) {
        return (closePrice !== null && closePrice !== void 0 ? closePrice : listPrice) / squareFeet;
    }
    return null;
};
export const getStructureType = (property) => {
    const structureType = property["StructureType"];
    if (structureType) {
        return structureType != null && typeof structureType === "object" && !Array.isArray(structureType)
            ? Object.keys(structureType)
            : structureType;
    }
    return property["CAR_ConstructionType"];
};
/**
 * Gets a URL for viewing the API response from the MLS source for the property
 *
 * @param mls ex: canopy, triangle, triadmls
 * @param listingId the MLS Listing ID for the property
 * @returns the url to view the API response
 */
export const getUrl = (
/**
 * The MLS used to get the property data
 */
mls, 
/**
 * The MLS Listing ID for the property
 */
listingId) => {
    if (mls === "triangle" || mls === "triadmls") {
        return `/api/bridge/${mls}?ListingId=${listingId}`;
    }
    if (mls === "canopy") {
        return `/api/utilities/mls_grid/search?listing_id=${listingId}`;
    }
    if (mls === "flexmls") {
        return `/api/utilities/spark?listing_id=${listingId}`;
    }
    return null;
};
/**
 * Based on a property's `StandardStatus` and `MlsStatus` reported from MLS,
 * determine whether or not the property is eligable as a comp.
 * @param standardStatus the property's `StandardStatus`
 * @param mlsStatus the property's `MlsStatus`
 * @returns the comp type
 */
export const getCompType = (standardStatus, mlsStatus) => {
    if (standardStatus) {
        if (activeStatusesLowercase.includes(standardStatus.toLowerCase().trim())) {
            return "Active";
        }
        if (pendingStatusesLowercase.includes(standardStatus.toLowerCase().trim())) {
            return "Pending";
        }
        if (closedStatusesLowercase.includes(standardStatus.toLowerCase().trim())) {
            return "Closed";
        }
    }
    if (mlsStatus) {
        if (activeStatusesLowercase.includes(mlsStatus.toLowerCase().trim())) {
            return "Active";
        }
        if (pendingStatusesLowercase.includes(mlsStatus.toLowerCase().trim())) {
            return "Pending";
        }
        if (closedStatusesLowercase.includes(mlsStatus.toLowerCase().trim())) {
            return "Closed";
        }
    }
    return null;
};
export const getMLSSource = (property) => {
    var _a;
    if (((_a = property["ListingId"]) !== null && _a !== void 0 ? _a : "").includes("CAR")) {
        return "canopy";
    }
    const originatingSystemName = property["OriginatingSystemName"];
    // Longleaf is being integrated into tmls
    // https://www.trianglemls.com/productsservices/data-share/
    // Some `Triangle` properties now listed as Triangle MLS, Inc -- KL 06/02/2022
    const triangleNames = ["Triangle MLS", "Triangle MLS, Inc", "Longleaf Pine Realtors"];
    if (triangleNames.includes(originatingSystemName)) {
        return "triangle";
    }
    if (originatingSystemName === "Triad MLS") {
        return "triadmls";
    }
    if (originatingSystemName === "North Carolina Regional MLS") {
        return "flexmls";
    }
    console.warn("Invalid MLS for property", property);
    return null;
};
/**
 * Convenience method to add subject property to list of returned comps
 */
export const addSubjectPropertyToComps = (comps, subjectProperty) => {
    if (comps && comps.length > 0 && subjectProperty) {
        return comps.concat(!comps.find((comp) => comp.data["ListingId"] === subjectProperty.data["ListingId"]) ? [subjectProperty] : []);
    }
    return comps;
};
/**
 * Returns a spark query string to filter for statuses given
 * @param statuses List of MLS Statuses (ex:, [Active, Coming soon])
 */
export const getSparkStatusQuery = (statuses) => {
    const statusQueryParams = [];
    statuses.forEach((status) => {
        statusQueryParams.push(`StandardStatus Eq '${status.trim()}'`);
    });
    return statusQueryParams.join(" OR ");
};
/**
 * returns a list of comps that are selected or refined.
 * Useful to save certain comps between searches without having to re-find/select them
 * @param comps
 * @param refinedComps
 * @returns
 */
export const getSelectedComps = (comps, refinedComps) => {
    return comps.filter((comp) => comp.selected || [...(refinedComps !== null && refinedComps !== void 0 ? refinedComps : [])].includes(comp.mlsnumber));
};
const getCompsPullerGridThemeOptions = (parentTheme) => {
    var _a, _b, _c, _d, _e, _f;
    const base = getBaseThemeOptions(parentTheme);
    return Object.assign(Object.assign({}, base), { components: Object.assign(Object.assign({}, base.components), { MuiDataGrid: Object.assign(Object.assign({}, (_a = base.components) === null || _a === void 0 ? void 0 : _a.MuiDataGrid), { styleOverrides: Object.assign(Object.assign({}, (_c = (_b = base.components) === null || _b === void 0 ? void 0 : _b.MuiDataGrid) === null || _c === void 0 ? void 0 : _c.styleOverrides), { row: Object.assign(Object.assign({}, (_f = (_e = (_d = base.components) === null || _d === void 0 ? void 0 : _d.MuiDataGrid) === null || _e === void 0 ? void 0 : _e.styleOverrides) === null || _f === void 0 ? void 0 : _f.row), { "&.comps-puller-active": {
                            backgroundColor: "#e8e8e8",
                        }, "&.comps-puller-pending": {
                            backgroundColor: "#e0ffee",
                        }, "&.comps-puller-selected": {
                            backgroundColor: "#e3f1ff",
                        }, "&.comps-puller-highlighted": {
                            backgroundColor: "#90ee90",
                        } }) }) }) }) });
};
export const compsPullerGridTheme = (parentTheme) => createTheme(getCompsPullerGridThemeOptions(parentTheme));
export const getRowClassName = (params) => {
    if (params.row.selected) {
        return "comps-puller-selected";
    }
    if (params.row.highlighted) {
        return "comps-puller-highlighted";
    }
    if (activeStatusesLowercase.includes(params.row.status.toLowerCase().trim())) {
        return "comps-puller-active";
    }
    if (pendingStatusesLowercase.includes(params.row.status.toLowerCase().trim())) {
        return "comps-puller-pending";
    }
    return "";
};
