import * as React from "react";
import { Add, Delete, Info } from "@mui/icons-material";
import { Box, Grid, Button, Tooltip, Typography, IconButton } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import { useListMailschedule3Templates } from "@app/orval/api/mail-schedule-templates";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import MaiLSchedule3TemplateAutocomplete from "./MailSchedule3TemplatesAutocomplete";
const MailSchedule3Templates = (props) => {
    var _a, _b;
    const { onUpdate, selectedTemplates } = props;
    const listMailSchedule3TemplatesApi = useListMailschedule3Templates({}, REFETCH_CACHE_PARAMS);
    const templates = (_b = (_a = listMailSchedule3TemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const addTemplate = React.useCallback(() => {
        onUpdate([...selectedTemplates, null]);
    }, [onUpdate, selectedTemplates]);
    const removeTemplate = React.useCallback((index) => () => {
        onUpdate(selectedTemplates.filter((_, i) => i !== index));
    }, [onUpdate, selectedTemplates]);
    const updateTemplate = React.useCallback((index) => (templateUid) => {
        const newSelectedTemplates = [...selectedTemplates];
        newSelectedTemplates[index] = templateUid;
        onUpdate(newSelectedTemplates);
    }, [onUpdate, selectedTemplates]);
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 2 },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Related Streak Mail Schedule Templates")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Use this feature to select which Streak templates this one was migrated from. If a Streak template was already processed for a box, this template will not be processed, depending on the exact criteria and resend configuration for the template.") },
                            React.createElement(Info, null)))))),
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, direction: "column", spacing: 0, wrap: "nowrap" }, selectedTemplates.map((templateUid, i) => (React.createElement(Grid, { key: i, item: true }, templates ? (React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(IconButton, { size: "small", onClick: removeTemplate(i) },
                        React.createElement(Delete, { fontSize: "small" }))),
                React.createElement(Grid, { item: true, style: { flex: 1 } },
                    React.createElement(MaiLSchedule3TemplateAutocomplete, { templates: templates, onChange: updateTemplate(i), value: templateUid })))) : (React.createElement(CoreLoading, null))))))),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { variant: "text", size: "small", startIcon: React.createElement(Add, null), onClick: addTemplate }, "Add template"))));
};
export default MailSchedule3Templates;
