import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid, InputAdornment, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import { useListOffices } from "@app/orval/api/offices";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const TeamSelectDialog = (props) => {
    var _a, _b, _c;
    const { DialogProps, initialValue, onClose, onConfirm } = props;
    const [selectedOfficeUid, setSelectedOfficeUid] = React.useState((_a = initialValue === null || initialValue === void 0 ? void 0 : initialValue.uid) !== null && _a !== void 0 ? _a : null);
    const listOfficesApi = useListOffices(REFETCH_CACHE_PARAMS);
    const offices = (_c = (_b = listOfficesApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const officesSorted = React.useMemo(() => {
        return offices
            ? offices.sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
            : null;
    }, [offices]);
    const handleOfficeChanged = React.useCallback((e) => {
        const officeUid = e.target.value;
        setSelectedOfficeUid(officeUid || null);
    }, [setSelectedOfficeUid]);
    const handleConfirm = React.useCallback(() => {
        onConfirm(selectedOfficeUid ? { uid: selectedOfficeUid } : null);
    }, [onConfirm, selectedOfficeUid]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, "Add user to office"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Office"),
                            },
                            select: {
                                displayEmpty: true,
                            },
                        }, value: selectedOfficeUid !== null && selectedOfficeUid !== void 0 ? selectedOfficeUid : "", onChange: handleOfficeChanged },
                        React.createElement(MenuItem, { value: "" }, "No office"),
                        (officesSorted !== null && officesSorted !== void 0 ? officesSorted : []).map((office) => (React.createElement(MenuItem, { key: office.uid, value: office.uid }, office.name))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleConfirm, disabled: !offices, tabIndex: -1 }, "OK"))));
};
export default TeamSelectDialog;
