import * as React from "react";
import { Add } from "@mui/icons-material";
import { Alert, Box, Divider, Grid, Hidden, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useStateCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { listTransactions } from "@app/orval/api/agent-dashboard";
import { useAddBuyerLead } from "@app/orval/api/buyer-leads";
import { useAddComment, useDeleteComment, useUpdateComment, listComments } from "@app/orval/api/comments";
import { useSearchContacts, useGetContact, useCreateContact, useDeleteContact, useAddRelatedContact, useRemoveRelatedContact, useListEmailsForContact, useListMessagesForContact, useListCallsForContact, } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListFieldSections } from "@app/orval/api/field-sections";
import { listNewsfeedFieldValueUpdates, listNewsfeedContactNoteChanges } from "@app/orval/api/newsfeed";
import { useAddSellerLead } from "@app/orval/api/seller-leads";
import { REFETCH_CACHE_PARAMS, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import ContactAliases from "./ContactAliases";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import OtherLeadsAndContracts from "../buyer-leads/components/OtherLeadsAndContracts";
import ContactLeadDialog from "../components/box-view/actions/ContactLeadDialog";
import Chronology from "../components/box-view/Chronology/Chronology";
import ContactAvatar from "../components/box-view/Contact/ContactAvatar";
import ContactRelationships from "../components/box-view/Contact/ContactRelationships";
import DataEntry from "../components/box-view/DataEntry";
import DataEntrySkeleton from "../components/box-view/DataEntrySkeleton";
import Header from "../components/box-view/Header";
import useSaturateFields from "../components/box-view/useSaturateFields";
import ValidationErrors from "../components/box-view/ValidationErrors";
const ALLOWED_VISIBILITY_OPTIONS = ["Calls", "Comments", "Emails", "History", "SMS"];
const ALLOWED_VISIBILITY_OPTIONS__WRITEABLE = [...ALLOWED_VISIBILITY_OPTIONS];
const ContactsForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    const { uid } = props;
    const tab = (_a = props.tab) !== null && _a !== void 0 ? _a : "data";
    // STATE
    const [search, setSearch] = useStateCallback("");
    // HOOKS
    const updateSearch = useUpdateSearch();
    const confirm = useConfirm();
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const session = useSession();
    const onTabChanged = React.useCallback((_, value) => {
        updateSearch("tab", value);
    }, [updateSearch]);
    const listFieldsApi = useListFields({ entity_type: "Contact" }, REFETCH_CACHE_PARAMS);
    const fields = (_c = (_b = listFieldsApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const listSectionsApi = useListFieldSections({ entity_type: "Contact" }, REFETCH_CACHE_PARAMS);
    const sections = (_e = (_d = listSectionsApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : [];
    const [isContactLeadDialogOpen, setIsContactLeadDialogOpen] = React.useState(false);
    const openContactLeadDialog = React.useCallback(() => {
        setIsContactLeadDialogOpen(true);
    }, []);
    const closeContactLeadDialog = React.useCallback(() => {
        setIsContactLeadDialogOpen(false);
    }, []);
    const getContactApi = useGetContact(uid, REFETCH_CACHE_PARAMS);
    const contact = (_g = (_f = getContactApi.data) === null || _f === void 0 ? void 0 : _f.data) !== null && _g !== void 0 ? _g : null;
    const createContactApi = useCreateContact();
    const deleteContactApi = useDeleteContact();
    const createBuyerLeadApi = useAddBuyerLead();
    const createSellerLeadApi = useAddSellerLead();
    const addRelatedContactApi = useAddRelatedContact();
    const removeRelatedContactApi = useRemoveRelatedContact();
    const listCommentsApi = usePostQuery(listComments, { contact_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const comments = (_j = (_h = listCommentsApi.data) === null || _h === void 0 ? void 0 : _h.data) !== null && _j !== void 0 ? _j : null;
    const addCommentApi = useAddComment();
    const updateCommentApi = useUpdateComment();
    const deleteCommentApi = useDeleteComment();
    const listEmailsApi = useListEmailsForContact(uid, REFETCH_CACHE_PARAMS);
    const emails = (_l = (_k = listEmailsApi.data) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : null;
    const listMessagesApi = useListMessagesForContact(uid, REFETCH_CACHE_PARAMS);
    const messages = (_o = (_m = listMessagesApi.data) === null || _m === void 0 ? void 0 : _m.data) !== null && _o !== void 0 ? _o : null;
    const listCallsApi = useListCallsForContact(uid, REFETCH_CACHE_PARAMS);
    const calls = (_q = (_p = listCallsApi.data) === null || _p === void 0 ? void 0 : _p.data) !== null && _q !== void 0 ? _q : null;
    const fieldValueChangesApi = usePostQuery(listNewsfeedFieldValueUpdates, { contact_uids: [uid] }, REFETCH_CACHE_PARAMS);
    const fieldValueChanges = (_s = (_r = fieldValueChangesApi.data) === null || _r === void 0 ? void 0 : _r.data) !== null && _s !== void 0 ? _s : null;
    const noteChangesApi = usePostQuery(listNewsfeedContactNoteChanges, { contact_uids: [uid], search }, { query: { gcTime: Infinity } });
    const noteChanges = (_u = (_t = noteChangesApi.data) === null || _t === void 0 ? void 0 : _t.data) !== null && _u !== void 0 ? _u : null;
    const isLoading = createContactApi.isPending ||
        deleteContactApi.isPending ||
        createBuyerLeadApi.isPending ||
        createSellerLeadApi.isPending;
    const deleteContact = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteContactApi
                    .mutateAsync({ uid })
                    .then(() => {
                    navigate("/contacts");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteContactApi, uid, errorDialog, navigate]);
    const createBuyerLead = React.useCallback(() => {
        createBuyerLeadApi
            .mutateAsync({ data: { client_uids: [uid] } })
            .then((result) => {
            navigate(`/buyers/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [createBuyerLeadApi, errorDialog, navigate, uid]);
    const connectBuyerLead = React.useCallback(() => {
        // TODO: implement
    }, []);
    const createSellerLead = React.useCallback(() => {
        createSellerLeadApi
            .mutateAsync({ data: { client_uids: [uid] } })
            .then((result) => {
            navigate(`/sellers/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [createSellerLeadApi, errorDialog, navigate, uid]);
    const connectSellerLead = React.useCallback(() => {
        // TODO: implement
    }, []);
    const createExistingContactRelationship = React.useCallback((contactUid, relationship) => {
        console.log("add existing related contact", contactUid, relationship);
        addRelatedContactApi
            .mutateAsync({
            data: {
                contact_uid: uid,
                related_contact_uid: contactUid,
                // @ts-expect-error: not assignable to type
                relationship,
            },
        })
            .then((result) => {
            queryClient.setQueryData(getContactApi.queryKey, () => result);
        })
            .catch(errorDialog);
    }, [addRelatedContactApi, errorDialog, getContactApi.queryKey, queryClient, uid]);
    const createNewContactRelationship = React.useCallback((contactDetails, relationship) => {
        console.log("add new related contact", contactDetails, relationship);
        addRelatedContactApi
            .mutateAsync({
            data: {
                contact_uid: uid,
                related_contact_data: {
                    fields: contactDetails,
                },
                // @ts-expect-error: not assignable to type
                relationship,
            },
        })
            .then((result) => {
            queryClient.setQueryData(getContactApi.queryKey, () => result);
        })
            .catch(errorDialog);
    }, [addRelatedContactApi, errorDialog, getContactApi.queryKey, queryClient, uid]);
    const removeContactRelationship = React.useCallback((relatedContactUid) => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This won't delete the related contact; it will only remove the relationship." })).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(getContactApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { relationships: old.data.relationships.filter((r) => r.related_contact_uid !== relatedContactUid) }) });
                });
                removeRelatedContactApi
                    .mutateAsync({
                    data: {
                        contact_uid: uid,
                        related_contact_uid: relatedContactUid,
                    },
                })
                    .then(() => {
                    console.log("removed related contact");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, errorDialog, getContactApi.queryKey, queryClient, removeRelatedContactApi, uid]);
    const onFieldSaveBegin = React.useCallback((fieldName, fieldValue) => {
        queryClient.setQueryData(getContactApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), { [fieldName]: fieldValue }) }) });
        });
    }, [getContactApi.queryKey, queryClient]);
    const onFieldSaveComplete = React.useCallback((response) => {
        queryClient.setQueryData(getContactApi.queryKey, () => response);
    }, [getContactApi.queryKey, queryClient]);
    const transactionsForClientsApi = usePostQuery(listTransactions, {
        client_uids: [uid],
    }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const transactionsForClient = (_w = (_v = transactionsForClientsApi.data) === null || _v === void 0 ? void 0 : _v.data.filter((x) => x.uid !== uid)) !== null && _w !== void 0 ? _w : [];
    const aliases = contact
        ? transactionsForClient.filter((t) => {
            var _a;
            return (_a = t.clients) === null || _a === void 0 ? void 0 : _a.some((c) => {
                var _a, _b, _c, _d, _e, _f;
                return c.client.uid === uid &&
                    (c.client.first_name !== ((_a = contact.fields["First Name"]) !== null && _a !== void 0 ? _a : null) ||
                        c.client.last_name !== ((_b = contact.fields["Last Name"]) !== null && _b !== void 0 ? _b : null) ||
                        c.client.email !== ((_c = contact.fields["Email"]) !== null && _c !== void 0 ? _c : null) ||
                        c.client.phone !== ((_d = contact.fields["Phone"]) !== null && _d !== void 0 ? _d : null) ||
                        c.client.date_of_birth !== ((_e = contact.fields["Date of Birth"]) !== null && _e !== void 0 ? _e : null) ||
                        c.client.company !== ((_f = contact.fields["Company"]) !== null && _f !== void 0 ? _f : null));
            });
        })
        : null;
    const canViewAliases = (_x = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _x === void 0 ? void 0 : _x.hasRole(["Superadmin", "Admin"]);
    const searchContactsApi = useSearchContacts();
    const [matchingContactsByEmail, setMatchingContactsByEmail] = React.useState(null);
    const [matchingContactsByPhone, setMatchingContactsByPhone] = React.useState(null);
    const possibleDuplicateContacts = React.useMemo(() => {
        var _a, _b;
        return [
            ...((_a = matchingContactsByEmail === null || matchingContactsByEmail === void 0 ? void 0 : matchingContactsByEmail.filter((x) => x.uid !== (contact === null || contact === void 0 ? void 0 : contact.uid))) !== null && _a !== void 0 ? _a : []),
            ...((_b = matchingContactsByPhone === null || matchingContactsByPhone === void 0 ? void 0 : matchingContactsByPhone.filter((x) => x.uid !== (contact === null || contact === void 0 ? void 0 : contact.uid))) !== null && _b !== void 0 ? _b : []),
        ].filter((x, i, arr) => {
            if (arr.find((y, j) => y.uid === x.uid && j < i)) {
                return false;
            }
            return true;
        });
    }, [contact === null || contact === void 0 ? void 0 : contact.uid, matchingContactsByEmail, matchingContactsByPhone]);
    React.useEffect(() => {
        var _a;
        setMatchingContactsByEmail(null);
        if ((_a = contact === null || contact === void 0 ? void 0 : contact.fields["Email"]) === null || _a === void 0 ? void 0 : _a.trim()) {
            searchContactsApi
                .mutateAsync({ data: { email_addresses: [contact.fields["Email"]], limit: 10 } })
                .then((result) => {
                var _a;
                setMatchingContactsByEmail((_a = result.data) !== null && _a !== void 0 ? _a : null);
            })
                .catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact === null || contact === void 0 ? void 0 : contact.fields["Email"]]);
    React.useEffect(() => {
        var _a;
        setMatchingContactsByPhone(null);
        if (((_a = contact === null || contact === void 0 ? void 0 : contact.fields["Phone"]) === null || _a === void 0 ? void 0 : _a.trim()) && contact.fields["Phone"].replace(/\D/g, "").length === 10) {
            searchContactsApi
                .mutateAsync({ data: { phone_numbers: [contact.fields["Phone"]], limit: 10 } })
                .then((result) => {
                var _a;
                setMatchingContactsByPhone((_a = result.data) !== null && _a !== void 0 ? _a : null);
            })
                .catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact === null || contact === void 0 ? void 0 : contact.fields["Phone"]]);
    const saveComment = React.useCallback((params) => {
        if (params.uid) {
            updateCommentApi
                .mutateAsync({ uid: params.uid, data: { content: params.content } })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [...old.data.filter((x) => x.uid !== params.uid), result.data] });
                });
            })
                .catch(errorDialog);
        }
        else {
            addCommentApi
                .mutateAsync({ data: { content: params.content } })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
                });
            })
                .catch(errorDialog);
        }
    }, [updateCommentApi, addCommentApi, errorDialog, queryClient, listCommentsApi.queryKey]);
    const deleteComment = React.useCallback((commentUid) => {
        queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== commentUid) });
        });
        deleteCommentApi
            .mutateAsync({ uid: commentUid })
            .then(() => {
            console.log("Delete comment");
        })
            .catch(errorDialog);
    }, [deleteCommentApi, queryClient, listCommentsApi.queryKey, errorDialog]);
    const doSearch = React.useCallback((searchString) => {
        setSearch(searchString);
    }, [setSearch]);
    const { fieldsSaturated: contactFieldsSaturated, validationErrors } = useSaturateFields(fields, contact);
    if (!contact || isLoading) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid, { container: true, direction: "column", style: { background: "#eee", overflowY: "hidden", height: "100%" }, wrap: "nowrap" },
        validationErrors.length > 0 && (React.createElement(Grid, { item: true },
            React.createElement(ValidationErrors, { errors: validationErrors }))),
        React.createElement(Grid, { item: true, style: { width: "100%", overflow: "hidden" } },
            React.createElement(Box, { pl: 2, pt: 0, pb: 0 },
                React.createElement(Header, { moduleName: "Contacts", pipelineUrl: "/contacts", name: contact.name, showCopyLinkButton: true, onDelete: deleteContact }))),
        possibleDuplicateContacts.length > 0 && (React.createElement(Grid, { item: true, pb: 1 },
            React.createElement(Alert, { severity: "warning" },
                React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, null, "This contact may be a duplicate")),
                    React.createElement(Grid, { item: true, container: true, spacing: 2 }, possibleDuplicateContacts.map((x) => (React.createElement(Grid, { key: x.uid, item: true },
                        React.createElement(RouterLink, { to: `/contacts/${x.uid}` }, x.name))))))))),
        React.createElement(Grid, { item: true, style: { overflow: "hidden", flex: 1 } },
            React.createElement(Grid, { container: true, spacing: 1, style: { height: "100%" } },
                React.createElement(Hidden, { lgDown: tab !== "data" },
                    React.createElement(Grid, { item: true, xs: 12, lg: 3, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                React.createElement(Grid, { container: true, style: { height: "100%", overflowY: "scroll" }, direction: "column", wrap: "nowrap" },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(ContactAvatar, { contact: contact }))),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid, { item: true }, contactFieldsSaturated ? (React.createElement(DataEntry, { apiUri: `/api/contacts/${contact.uid}`, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, fields: contactFieldsSaturated, sections: sections !== null && sections !== void 0 ? sections : [], values: contact.fields })) : (React.createElement(DataEntrySkeleton, null))),
                                    canViewAliases && aliases && aliases.length > 0 && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid, { item: true },
                                            React.createElement(ContactAliases, { aliases: aliases, contact: contact }))))))))),
                React.createElement(Hidden, { lgDown: tab !== "chronology" },
                    React.createElement(Grid, { item: true, xs: 12, lg: 6, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                React.createElement(Box, { p: 2, style: { height: "100%" } },
                                    React.createElement(Chronology, { allowedVisibilityOptions: ALLOWED_VISIBILITY_OPTIONS__WRITEABLE, visibilityType: "Contact", updates: fieldValueChanges, noteChanges: noteChanges, comments: comments, messages: messages, calls: calls, emails: emails, onSearch: doSearch, onCommentSubmitted: saveComment, onCommentDeleted: deleteComment, showToolbar: true, contacts: [
                                            {
                                                name: [contact.fields["First Name"], contact.fields["Last Name"]]
                                                    .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
                                                    .join(" ") || "Contact",
                                                phone_number: (_z = (_y = contact.fields["Phone"]) === null || _y === void 0 ? void 0 : _y.trim()) !== null && _z !== void 0 ? _z : "",
                                            },
                                        ].filter((x) => isValidPhoneNumber(x.phone_number, "US")) })))))),
                React.createElement(Hidden, { lgDown: tab !== "links" },
                    React.createElement(Grid, { item: true, xs: 12, lg: 3, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                React.createElement(Grid, { container: true, style: { height: "100%", overflowY: "scroll" }, direction: "column", wrap: "nowrap" },
                                    transactionsForClient.length > 0 && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(OtherLeadsAndContracts, { CollapsibleSectionProps: {
                                                        title: "Leads & Deals",
                                                        toolbar: (React.createElement(IconButton, { size: "small", onClick: openContactLeadDialog },
                                                            React.createElement(Add, { fontSize: "small" }))),
                                                    }, buyerLeads: transactionsForClient.filter((t) => t.buyer_lead).map((t) => t.buyer_lead), sellerLeads: transactionsForClient
                                                        .filter((t) => t.seller_lead)
                                                        .map((t) => t.seller_lead), buyerContracts: transactionsForClient
                                                        .filter((t) => t.buyer_contract)
                                                        .map((t) => t.buyer_contract), sellerContracts: transactionsForClient
                                                        .filter((t) => t.seller_contract)
                                                        .map((t) => t.seller_contract) })),
                                            React.createElement(ContactLeadDialog, { DialogProps: { open: isContactLeadDialogOpen }, onExistingBuyerLeadSelected: connectBuyerLead, onExistingSellerLeadSelected: connectSellerLead, onNewBuyerLeadSelected: createBuyerLead, onNewSellerLeadSelected: createSellerLead, onClose: closeContactLeadDialog })))),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(ContactRelationships, { contact: contact, onCreateExistingContactRelationship: createExistingContactRelationship, onCreateNewContactRelationship: createNewContactRelationship, onRemoveRelationship: removeContactRelationship })))))))))),
        React.createElement(Hidden, { lgUp: true },
            React.createElement(Grid, { item: true, style: { background: "white" } },
                React.createElement(Tabs, { value: tab, onChange: onTabChanged, variant: "fullWidth" },
                    React.createElement(Tab, { value: "data", label: "Data" }),
                    React.createElement(Tab, { value: "chronology", label: "Chronology" }),
                    React.createElement(Tab, { value: "links", label: "Links" }))))));
};
export default ContactsForm;
