import * as React from "react";
import { CloudUpload } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { FileDrop } from "react-file-drop";
import styled from "styled-components";
const FileDropWrapper = styled("div") `
    & .file-drop {
        height: 100%;
        width: 100%;
    }

    & .file-drop > .file-drop-target {
        height: 100%;
        display: none;
    }

    & .file-drop > .file-drop-dragging-over-frame {
        display: block;
        z-index: 1000000;
    }

    & .file-drop > .file-drop-target.file-drop-dragging-over-frame {
        /* overlay a black mask when dragging over the frame */
        border: none;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: none;
        z-index: 50;
        opacity: 1;

        /* typography */
        color: white;
    }

    & .file-drop > .file-drop-target.file-drop-dragging-over-target {
        /* turn stuff orange when we are dragging over the target */
        color: #888;
        background-color: rgba(0, 0, 0, 0.65);
        box-shadow: 0 0 13px 3px #888;
    }
`;
const FileDropper = (props) => {
    const { onFilesDropped } = props;
    const [isDraggingFiles, setIsDraggingFiles] = React.useState(false);
    const handleFileDragBegan = React.useCallback(() => {
        setIsDraggingFiles(true);
    }, []);
    const handleFileDragEnded = React.useCallback(() => {
        setIsDraggingFiles(false);
    }, []);
    const handleFilesDroppedForUpload = React.useCallback((files) => {
        console.log(files);
        onFilesDropped(files);
    }, [onFilesDropped]);
    return (React.createElement(FileDropWrapper, { style: {
            width: "100%",
            height: "100%",
            position: "absolute",
            transform: "translate(-0.5rem, -0.25rem)",
            zIndex: isDraggingFiles ? 100 : -1,
        } },
        React.createElement(FileDrop, { onFrameDragEnter: handleFileDragBegan, onFrameDragLeave: handleFileDragEnded, onFrameDrop: handleFileDragEnded, onDrop: handleFilesDroppedForUpload },
            React.createElement("div", { style: {
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    top: "50%",
                    transform: "translateY(-25%)",
                    position: "absolute",
                } },
                React.createElement(CloudUpload, { fontSize: "large" }),
                React.createElement(Typography, { variant: "h4" }, "Drop files here")))));
};
export default FileDropper;
