var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { LabelImportant } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, Grid2, InputAdornment, MenuItem, TextField, Typography, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useBulkUpdateBuyerContracts } from "@app/orval/api/buyer-contracts";
import { useBulkUpdateBuyerLeads } from "@app/orval/api/buyer-leads";
import { useBulkUpdateRecruits } from "@app/orval/api/recruits";
import { useBulkUpdateSellerContracts } from "@app/orval/api/seller-contracts";
import { useBulkUpdateSellerLeads } from "@app/orval/api/seller-leads";
import { useListStages } from "@app/orval/api/stages";
import { useBulkUpdateUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const BulkStageUpdateDialog = (props) => {
    var _a, _b, _c, _d;
    const { onClose, onUpdate, pipeline, uids } = props;
    const [selectedStage, setSelectedStage] = React.useState(null);
    const handleStageChange = React.useCallback((e) => {
        setSelectedStage(e.target.value || null);
    }, []);
    React.useEffect(() => {
        setSelectedStage(null);
    }, [pipeline]);
    const stageType = React.useMemo(() => {
        switch (pipeline) {
            case "Buyer Leads":
                return "Buyer Lead";
            case "Seller Leads":
                return "Seller Lead";
            case "Buyer Contracts":
                return "Buyer Contract";
            case "Seller Contracts":
                return "Listing";
            case "Recruits":
                return "Recruit";
            case "Users":
                return "User";
            default:
                throw new Error(`Invalid pipeline ${pipeline}`);
        }
    }, [pipeline]);
    const listStagesApi = useListStages({
        type: stageType,
    }, INFINITE_CACHE_PARAMS);
    const stages = (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    const bulkUpdateBuyerLeadsApi = useBulkUpdateBuyerLeads();
    const bulkUpdateSellerLeadsApi = useBulkUpdateSellerLeads();
    const bulkUpdateBuyerContractsApi = useBulkUpdateBuyerContracts();
    const bulkUpdateSellerContractsApi = useBulkUpdateSellerContracts();
    const bulkUpdateRecruitsApi = useBulkUpdateRecruits();
    const bulkUpdateUsersApi = useBulkUpdateUsers();
    const isUpdating = bulkUpdateBuyerLeadsApi.isPending ||
        bulkUpdateSellerLeadsApi.isPending ||
        bulkUpdateBuyerContractsApi.isPending ||
        bulkUpdateSellerContractsApi.isPending ||
        bulkUpdateRecruitsApi.isPending ||
        bulkUpdateUsersApi.isPending;
    const onConfirm = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const payload = uids.map((uid) => ({
            uid,
            stage_uid: selectedStage,
        }));
        switch (pipeline) {
            case "Buyer Leads":
                return bulkUpdateBuyerLeadsApi
                    .mutateAsync({ data: { items: payload } })
                    .then((response) => {
                    onUpdate(response.data);
                    onClose();
                })
                    .catch(enqueueErrorSnackbar);
            case "Seller Leads":
                return bulkUpdateSellerLeadsApi
                    .mutateAsync({ data: { items: payload } })
                    .then((response) => {
                    onUpdate(response.data);
                    onClose();
                })
                    .catch(enqueueErrorSnackbar);
            case "Buyer Contracts":
                return bulkUpdateBuyerContractsApi
                    .mutateAsync({ data: { items: payload } })
                    .then((response) => {
                    onUpdate(response.data);
                    onClose();
                })
                    .catch(enqueueErrorSnackbar);
            case "Seller Contracts":
                return bulkUpdateSellerContractsApi
                    .mutateAsync({ data: { items: payload } })
                    .then((response) => {
                    onUpdate(response.data);
                    onClose();
                })
                    .catch(enqueueErrorSnackbar);
            case "Recruits":
                return bulkUpdateRecruitsApi
                    .mutateAsync({ data: { items: payload } })
                    .then((response) => {
                    onUpdate(response.data);
                    onClose();
                })
                    .catch(enqueueErrorSnackbar);
            case "Users":
                return bulkUpdateUsersApi
                    .mutateAsync({ data: { items: payload } })
                    .then((response) => {
                    onUpdate(response.data);
                    onClose();
                })
                    .catch(enqueueErrorSnackbar);
            default:
                throw new Error(`Invalid pipeline ${pipeline}`);
        }
    }), [
        bulkUpdateBuyerContractsApi,
        bulkUpdateBuyerLeadsApi,
        bulkUpdateRecruitsApi,
        bulkUpdateSellerContractsApi,
        bulkUpdateSellerLeadsApi,
        bulkUpdateUsersApi,
        onClose,
        onUpdate,
        pipeline,
        selectedStage,
        uids,
    ]);
    const isValid = selectedStage !== null;
    return (React.createElement(CoreDialog, Object.assign({ open: (_d = (_c = props.DialogProps) === null || _c === void 0 ? void 0 : _c.open) !== null && _d !== void 0 ? _d : true }, props.DialogProps, { maxWidth: "sm", fullWidth: true }),
        React.createElement(DialogTitle, null, "Bulk Update Stage"),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, direction: "column", spacing: 2 },
                React.createElement(Grid2, null,
                    React.createElement(TextField, { size: "small", select: true, fullWidth: true, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Select a stage"),
                            },
                        }, value: selectedStage !== null && selectedStage !== void 0 ? selectedStage : "", onChange: handleStageChange, tabIndex: -1 },
                        React.createElement(MenuItem, { value: "" }),
                        stages.map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid },
                            React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                                React.createElement(Grid2, null,
                                    React.createElement(LabelImportant, { style: { color: x.color } })),
                                React.createElement(Grid2, { style: { flex: 1 } },
                                    React.createElement(Typography, null, x.name)))))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, color: "inherit", disabled: isUpdating, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onConfirm, color: "primary", variant: "contained", disabled: isUpdating || !isValid, tabIndex: -1 }, "Update"))));
};
export default BulkStageUpdateDialog;
