import * as React from "react";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { SpreadsheetProvider } from "../../features/crm/components/spreadsheet-view/SpreadsheetContext";
import usePaginatedTable from "../../features/crm/components/spreadsheet-view/usePaginatedTable";
import Users from "../../features/crm/users/Users";
const UsersPage = () => {
    useDocumentTitle("Users");
    const { limit, offset, search, showNewsfeed, stage, view } = usePaginatedTable();
    return (React.createElement(SpreadsheetProvider, null,
        React.createElement(Users, { limit: limit !== null && limit !== void 0 ? limit : undefined, offset: offset !== null && offset !== void 0 ? offset : undefined, stage: stage !== null && stage !== void 0 ? stage : undefined, search: search !== null && search !== void 0 ? search : undefined, view: view !== null && view !== void 0 ? view : undefined, showNewsfeed: showNewsfeed })));
};
export default UsersPage;
