import * as React from "react";
import { FilterList, Layers, SortByAlpha, Star } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import useStarredViewsForCurrentUser from "@app/hooks/api/useStarredViewsForCurrentUser";
import useSession from "@app/hooks/useSession";
import { useListStarredViews } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewSummary = () => {
    var _a, _b, _c, _d, _e;
    const context = useSavedViewEditorContext();
    const session = useSession();
    const starredViewsApi = useListStarredViews({ user_uid: (_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : undefined }, Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS.query), { enabled: !!((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid) }) }));
    const toggleEdit = React.useCallback(() => {
        context.setIsEditing((prev) => !prev);
    }, [context]);
    const { starView, starredViewUids } = useStarredViewsForCurrentUser();
    const starView_ = React.useCallback(() => {
        var _a, _b, _c;
        console.log({ uid: context.uid, name: (_a = context.view) === null || _a === void 0 ? void 0 : _a.name });
        starView(context.uid, (_c = (_b = context.view) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "");
    }, [starView, context.uid, (_d = context.view) === null || _d === void 0 ? void 0 : _d.name]);
    const isViewStarred = starredViewUids === null || starredViewUids === void 0 ? void 0 : starredViewUids.has((_e = context.uid) !== null && _e !== void 0 ? _e : "");
    if (!context.initialData) {
        return null;
    }
    const name = context.initialData.name.trim() || "(saved view)";
    const numFilters = context.initialData.filter_groups.map((fg) => fg.filters).flat().length || 0;
    const numSorting = context.initialData.sorting.length || 0;
    const numGrouping = context.initialData.grouping ? 1 : 0;
    return (React.createElement(Box, { p: 2, pt: 1, pb: 1, style: { backgroundColor: "rgb(221,252,217)", border: "1px solid #cccccc" } },
        React.createElement(Grid, { container: true, spacing: 4, wrap: "nowrap" },
            starredViewsApi.isFetched && (React.createElement(Grid, { item: true },
                React.createElement(Star, { style: { color: isViewStarred ? "#ffd76d" : "white", cursor: "pointer" }, sx: { "& path": { stroke: "#ccc" } }, onClick: starView_ }))),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, name)),
            React.createElement(Grid, { item: true },
                React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(FilterList, { fontSize: "small" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                            numFilters,
                            " filter",
                            numFilters === 1 ? "" : "s")))),
            React.createElement(Grid, { item: true },
                React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(SortByAlpha, { fontSize: "small" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                            numSorting,
                            " sort",
                            numSorting === 1 ? "" : "s")))),
            React.createElement(Grid, { item: true },
                React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Layers, { fontSize: "small" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                            numGrouping,
                            " grouping",
                            numGrouping === 1 ? "" : "s")))),
            React.createElement(Grid, { item: true, style: { flex: 1 } }),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: {
                        color: AppConfig.themeColors.primary,
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }, onClick: context.close }, "Back to Full Pipeline")),
            context.canEdit && (React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: {
                        color: AppConfig.themeColors.primary,
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }, onClick: toggleEdit }, "Edit View"))),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: {
                        color: AppConfig.themeColors.primary,
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }, onClick: context.openSaveAsDialog }, "Save As")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: {
                        color: AppConfig.themeColors.primary,
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }, onClick: context.createNewView }, "Create New View")))));
};
export default SavedViewSummary;
