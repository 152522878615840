import * as React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
const ModeFilter = (props) => {
    var _a, _b, _c;
    const { TextFieldProps, onChange, value } = props;
    const handleChange = React.useCallback((event) => {
        onChange(event.target.value || null);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true }, TextFieldProps, { select: true, value: value !== null && value !== void 0 ? value : "", onChange: handleChange, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Mode") }, (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _a === void 0 ? void 0 : _a.input), inputLabel: Object.assign({ shrink: true }, (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.inputLabel), select: Object.assign({ displayEmpty: true }, (_c = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.select) }) }),
        React.createElement(MenuItem, { value: "" }, "Any"),
        ["Manual", "Scheduled", "Triggered"].map((x) => (React.createElement(MenuItem, { key: x, value: x.toLowerCase() }, x)))));
};
export default ModeFilter;
