import * as React from "react";
import { Grid2, MenuItem, TextField } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { FilterContext } from "../FilterContext";
const SavedViewViewFilter = (props) => {
    var _a, _b;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const { views } = context;
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.view_filter.comparator = comparator;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValues = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.view_filter.view_uids = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.viewComparators;
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.view_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator, helperText: filter.view_filter.comparator === "is not in any of" ? "This option isn't working yet!" : undefined }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        React.createElement(Grid2, { style: { minWidth: "200px" } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_b = filter.view_filter.view_uids) !== null && _b !== void 0 ? _b : [], onChoicesChange: setRHSValues, options: (views !== null && views !== void 0 ? views : []).reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.uid]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, (views !== null && views !== void 0 ? views : []).map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name)))))));
};
export default SavedViewViewFilter;
