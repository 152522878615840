import * as React from "react";
import { Link } from "react-router-dom";
import numeral from "numeral";
import dayjs_ from "@app/common/dayjs";
export const renderAddressCell = (params) => {
    var _a;
    const uid = params.row.id;
    const address = (_a = params.row[params.field]) !== null && _a !== void 0 ? _a : "";
    const url = `/boxes/${uid}`;
    // return address;
    return (React.createElement(Link, { to: url, target: "_blank" }, address));
};
export const dateFieldFormatter = (value) => {
    return value ? dayjs_(value).format("M/D/YYYY") : "";
};
export const closingYearFormatter = (value) => {
    return value ? dayjs_(value).year() : null;
};
export const moneyFieldFormatter = (value) => {
    return value ? numeral(value).format("$0,0") : "";
};
