import { keepPreviousData } from "@tanstack/react-query";
/**
 * The default cache time for cache-able queries.
 */
export const DEFAULT_CACHE_TIME = 1000 * 60 * 5;
export const FIVE_MINUTES = 1000 * 60 * 5;
export const THIRTY_MINUTES = 1000 * 60 * 30;
/**
 * Default parameters for cachable queries
 * - sets `query.staleTime` to `DEFAULT_CACHE_TIME`
 * - sets `query.gcTime` to `DEFAULT_CACHE_TIME`
 */
export const DEFAULT_CACHE_PARAMS = {
    query: {
        staleTime: DEFAULT_CACHE_TIME,
        gcTime: DEFAULT_CACHE_TIME,
    },
};
/**
 * Default parameters for cachable queries
 * - sets `query.staleTime` to `DEFAULT_CACHE_TIME`
 * - sets `query.gcTime` to `DEFAULT_CACHE_TIME`
 */
export const FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA = Object.assign(Object.assign({}, DEFAULT_CACHE_PARAMS), { query: Object.assign(Object.assign({}, DEFAULT_CACHE_PARAMS), { placeholderData: keepPreviousData }) });
/**
 * Default parameters for cachable queries
 * - sets `query.staleTime` to `Infinity`
 * - sets `query.gcTime` to `Infinity`
 */
export const INFINITE_CACHE_PARAMS = {
    query: {
        staleTime: Infinity,
        gcTime: Infinity,
    },
};
/**
 * Default parameters for cachable queries, where
 * we want to display previously-fetched data immediately,
 * but also refetch via API
 * - sets `query.staleTime` to `0`
 * - sets `query.gcTime` to `Infinity`
 */
export const REFETCH_CACHE_PARAMS = {
    query: {
        staleTime: 0,
        gcTime: Infinity,
    },
};
/**
 * Default parameters for cachable queries, where
 * we want to display previously-fetched data immediately,
 * but also refetch via API
 * - sets `query.staleTime` to `0`
 * - sets `query.gcTime` to `Infinity`
 */
export const REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS = {
    query: {
        staleTime: 0,
        gcTime: 5 * 60 * 1000,
    },
};
/**
 * Default parameters for cachable queries, where
 * we want to display previously-fetched data immediately,
 * but also refetch via API. Also uses the previously-fetched
 * data as placeholder data while new data loads.
 * - sets `query.staleTime` to `0`
 * - sets `query.gcTime` to `Infinity`
 * - sets `placeholderData` to `keepPreviousData`
 */
export const REFETCH_CACHE_PARAMS_WITH_PACEHOLDER_DATA = Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { placeholderData: keepPreviousData }) });
