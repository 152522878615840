import * as React from "react";
import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, MenuItem } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { withFilter } from "@app/hoc/withFilter";
import API from "@app/util/api";
class MailScheduleTemplatesSidebar extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                pipelines: null,
                templateTypes: null,
                selectedPipeline: null,
                selectedTemplateType: null,
                selectedTemplateStatus: null,
                error: null,
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setPipeline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                this.props.updateFilter("pipeline", choice || null);
            }
        });
        Object.defineProperty(this, "setTemplateType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                this.props.updateFilter("template_type", choice || null);
            }
        });
        Object.defineProperty(this, "setTemplateStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                this.props.updateFilter("status", choice || null);
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                            selectedPipeline: result[0].name,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.mailScheduleTemplateTypes.list({
                    onSuccess: (result) => {
                        this.setState({
                            templateTypes: result,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a, _b, _c;
        const queryParams = new URLSearchParams(nextProps.location.search);
        return {
            selectedPipeline: (_a = queryParams.get("pipeline")) !== null && _a !== void 0 ? _a : null,
            selectedTemplateType: (_b = queryParams.get("template_type")) !== null && _b !== void 0 ? _b : null,
            selectedTemplateStatus: (_c = queryParams.get("status")) !== null && _c !== void 0 ? _c : null,
        };
    }
    componentDidMount() {
        this.refresh();
    }
    componentWillUnmount() {
        var _a;
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    render() {
        const { onSidebarToggle } = this.props;
        const { error, pipelines, selectedPipeline, selectedTemplateStatus, selectedTemplateType, templateTypes } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!pipelines || !templateTypes) {
            return React.createElement(CoreLoading, null);
        }
        return (React.createElement(DashCard, { heading: "Filters", toolbar: React.createElement(IconButton, { onClick: onSidebarToggle },
                React.createElement(Close, null)), padding: 0, elevation: 0, height: "100%", PaperProps: {
                style: {
                    overflowX: "hidden",
                },
            } },
            React.createElement(Box, { component: Grid, container: true, spacing: 2, p: 2 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(CoreChoiceField, { variant: "standard", label: "Pipeline", fullWidth: true, select: true, value: selectedPipeline !== null && selectedPipeline !== void 0 ? selectedPipeline : "any", onChoiceChange: this.setPipeline, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "any" }, "Any"),
                        pipelines.map((pipeline) => (React.createElement(MenuItem, { key: pipeline.key, value: pipeline.key }, pipeline.name))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(CoreChoiceField, { variant: "standard", label: "Template Type", fullWidth: true, select: true, value: selectedTemplateType !== null && selectedTemplateType !== void 0 ? selectedTemplateType : "", onChoiceChange: this.setTemplateType, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        templateTypes.map((templateType) => (React.createElement(MenuItem, { key: templateType.name, value: templateType.name }, templateType.name))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(CoreChoiceField, { variant: "standard", label: "Status", fullWidth: true, select: true, value: selectedTemplateStatus !== null && selectedTemplateStatus !== void 0 ? selectedTemplateStatus : "", onChoiceChange: this.setTemplateStatus, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        React.createElement(MenuItem, { value: "ok" }, "OK"),
                        React.createElement(MenuItem, { value: "broken" }, "Broken"))))));
    }
}
export default withFilter(MailScheduleTemplatesSidebar);
