import * as React from "react";
import { Info } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import AppConfig from "@app/util/AppConfig";
const BigMetricBox = (props) => {
    const { description, error = null, icon, isLoading = false, title, value } = props;
    return (React.createElement(Box, { p: 2, style: { borderRadius: "4px", background: AppConfig.themeColors.primary, color: "white" } },
        React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
            React.createElement(Grid, { item: true, container: true, spacing: 1 },
                React.createElement(Grid, { item: true, style: { flex: 1 } }, icon),
                description && (React.createElement(Grid, { item: true },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, description), arrow: true },
                        React.createElement(Info, null))))),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, title)),
            React.createElement(Grid, { item: true },
                error && React.createElement(CoreError, { error: error }),
                isLoading && value == null && React.createElement(CircularProgress, { style: { color: "white" } }),
                value != null && (React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "h5", style: { fontWeight: "bold" } }, value)),
                    isLoading && (React.createElement(Grid, { item: true },
                        React.createElement(CircularProgress, { style: { color: "white" }, size: 20 })))))))));
};
export default BigMetricBox;
