import * as React from "react";
import { Box, Divider, Grid, LinearProgress, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import Task from "@app/entrypoints/brokerage/features/crm/components/box-view/Chronology/Task";
import useSession from "@app/hooks/useSession";
import { countTasks, listTasks, useDeleteTask, useUpdateTask } from "@app/orval/api/tasks";
import { searchUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../usePostQuery";
const MyTasks = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { ListWrapperProps } = props;
    const session = useSession();
    const myUid = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid;
    const queryClient = useQueryClient();
    const searchUsersApi = usePostQuery(searchUsers, { limit: 1000 }, INFINITE_CACHE_PARAMS);
    const users = (_c = (_b = searchUsersApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const usersByUid = React.useMemo(() => {
        if (!users) {
            return {};
        }
        return users.reduce((acc, user) => {
            acc[user.uid] = user;
            return acc;
        }, {});
    }, [users]);
    const listTasksApi = usePostQuery(listTasks, { assignee_uids: myUid ? [myUid] : undefined, is_complete: false }, REFETCH_CACHE_PARAMS, !!myUid);
    const tasks = (_e = (_d = listTasksApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const updateTaskApi = useUpdateTask();
    const deleteTaskApi = useDeleteTask();
    const countTasksApi = usePostQuery(countTasks, { assignee_uids: myUid ? [myUid] : undefined, is_complete: false }, REFETCH_CACHE_PARAMS, !!myUid);
    const count = (_g = (_f = countTasksApi.data) === null || _f === void 0 ? void 0 : _f.data.count) !== null && _g !== void 0 ? _g : null;
    const onChange = React.useCallback((taskUid) => (task) => {
        if (task.is_complete) {
            queryClient.setQueryData(countTasksApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: { count: oldData.data.count - 1 } });
            });
        }
        queryClient.setQueryData(listTasksApi.queryKey, (oldData) => {
            if (!oldData) {
                return oldData;
            }
            return Object.assign(Object.assign({}, oldData), { data: oldData.data.map((t) => {
                    var _a, _b;
                    return t.uid === taskUid
                        ? Object.assign(Object.assign(Object.assign({}, t), task), { assignees: (_b = (_a = task.assignee_uids) === null || _a === void 0 ? void 0 : _a.map((x) => usersByUid[x]).filter((x) => x)) !== null && _b !== void 0 ? _b : t.assignees }) : t;
                }) });
        });
        updateTaskApi
            .mutateAsync({ uid: taskUid, data: task })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: oldData.data.map((t) => (t.uid === taskUid ? Object.assign(Object.assign({}, t), result.data) : t)) });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [countTasksApi.queryKey, listTasksApi.queryKey, queryClient, updateTaskApi, usersByUid]);
    const onDelete = React.useCallback((taskUid) => () => {
        queryClient.setQueryData(listTasksApi.queryKey, (oldData) => {
            if (!oldData) {
                return oldData;
            }
            return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((task) => task.uid !== taskUid) });
        });
        queryClient.setQueryData(countTasksApi.queryKey, (oldData) => {
            if (!oldData) {
                return oldData;
            }
            return Object.assign(Object.assign({}, oldData), { data: { count: oldData.data.count - 1 } });
        });
        deleteTaskApi
            .mutateAsync({ uid: taskUid })
            .then(() => {
            console.log(`deleted task ${taskUid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [countTasksApi.queryKey, deleteTaskApi, listTasksApi.queryKey, queryClient]);
    const isLoading = listTasksApi.isFetching;
    return (React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } },
                    "My Tasks",
                    count != null ? ` (${count})` : ""))),
        React.createElement(Grid, { item: true }, isLoading ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid, Object.assign({ item: true }, ListWrapperProps),
            React.createElement(List, { dense: true, sx: { "& .MuiListSubheader-root": { background: "white", height: "32px" } } }, (tasks !== null && tasks !== void 0 ? tasks : [])
                .filter((task) => !task.is_complete)
                .sort((a, b) => {
                if (!a.due_date && !b.due_date) {
                    return 0;
                }
                if (a.due_date && !b.due_date) {
                    return -1;
                }
                if (!a.due_date && b.due_date) {
                    return 1;
                }
                if (a.due_date < b.due_date) {
                    return -1;
                }
                if (a.due_date > b.due_date) {
                    return 1;
                }
                return -1;
            })
                .map((task) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                return (React.createElement(ListItem, { key: task.uid, style: { paddingTop: 0, paddingBottom: 0 } },
                    React.createElement(ListItemText, { primary: React.createElement(Task, { task: task, onChange: onChange(task.uid), onDelete: onDelete(task.uid) }), secondary: React.createElement(RouterLink, { to: `/boxes/${(_h = (_f = (_d = (_b = (_a = task.buyer) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : (_c = task.seller) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : (_e = task.user) === null || _e === void 0 ? void 0 : _e.uid) !== null && _f !== void 0 ? _f : (_g = task.recruit) === null || _g === void 0 ? void 0 : _g.uid) !== null && _h !== void 0 ? _h : (_j = task.contact) === null || _j === void 0 ? void 0 : _j.uid}` },
                            React.createElement(Typography, { variant: "caption" }, ((_k = task.buyer) === null || _k === void 0 ? void 0 : _k.name) ||
                                ((_l = task.seller) === null || _l === void 0 ? void 0 : _l.name) ||
                                ((_m = task.user) === null || _m === void 0 ? void 0 : _m.name) ||
                                ((_o = task.recruit) === null || _o === void 0 ? void 0 : _o.name) ||
                                ((_p = task.contact) === null || _p === void 0 ? void 0 : _p.name) ||
                                "Open Box")) })));
            })))));
};
export default MyTasks;
