import * as React from "react";
import { List, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import PSPListItem from "./PSPListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const PSPs = (props) => {
    var _a;
    const { isCollapsible, onRemovePSP, psps } = props;
    const removePSP = React.useCallback((uid) => {
        onRemovePSP(uid);
    }, [onRemovePSP]);
    const isLoading = psps == null;
    const noPSPs = psps && psps.length === 0;
    return (React.createElement(CollapsibleSection, { title: "PSPs", count: (_a = psps === null || psps === void 0 ? void 0 : psps.length) !== null && _a !== void 0 ? _a : undefined, isCollapsible: isCollapsible },
        isLoading && React.createElement(CoreLoading, null),
        noPSPs && React.createElement(Typography, { variant: "body2" }, "No PSPs"),
        psps && psps.length > 0 && (React.createElement(List, { dense: true }, psps
            .sort((a, b) => {
            if (a.created_at > b.created_at)
                return -1;
            if (a.created_at < b.created_at)
                return 1;
            return 0;
        })
            .map((psp) => (React.createElement(PSPListItem, { key: psp.uid, psp: psp, onRemovePSP: removePSP })))))));
};
export default PSPs;
