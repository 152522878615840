import * as React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import { searchUsersBasic } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { NOT_SPECIFIED } from "./utils";
const RunForAgentFilter = (props) => {
    var _a, _b, _c, _d, _e;
    const { TextFieldProps, isNull: agentIsNull, onChange, value: agent } = props;
    const listUsersApi = usePostQuery(searchUsersBasic, {}, INFINITE_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const usersSorted = React.useMemo(() => {
        var _a;
        return ((_a = users === null || users === void 0 ? void 0 : users.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _a !== void 0 ? _a : null);
    }, [users]);
    const handleChange = React.useCallback((event) => {
        onChange(event.target.value || null);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true }, TextFieldProps, { select: true, value: agentIsNull ? NOT_SPECIFIED : (agent !== null && agent !== void 0 ? agent : ""), onChange: handleChange, disabled: !users, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Run For Agent") }, (_c = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.input), inputLabel: Object.assign({ shrink: true }, (_d = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _d === void 0 ? void 0 : _d.inputLabel), select: Object.assign({ displayEmpty: true }, (_e = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _e === void 0 ? void 0 : _e.select) }) }),
        React.createElement(MenuItem, { value: "" }, "Any"),
        React.createElement(MenuItem, { value: NOT_SPECIFIED }, "Not Specified"),
        (usersSorted !== null && usersSorted !== void 0 ? usersSorted : []).map((user) => (React.createElement(MenuItem, { key: user.uid, value: user.uid }, user.name)))));
};
export default RunForAgentFilter;
