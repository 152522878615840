// @ts-nocheck
/* eslint-disable */
/** This file was generated automatically by drf-tsdk.
* It should be in .gitignore, and you should not edit it;
* its contents will be overwritten automatically every time
* the Django server is run. See https://github.com/ryan.laney/drf-tsdk
* for more info. */
const HEADERS = { 'x-dashcrm-request': '1' };
const cache = {};
const API = {
    slack: {
        /** List Channels
         *
         * Lists all Slack channels. DASH's Slack integration, "Dashbot", must have access to the channel in order to be included.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        listChannels: (params) => {
            const requestPath = "/api/slack/channels" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** List Users
         *
         * Lists all Slack users.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        listUsers: (params) => {
            const requestPath = "/api/slack/users" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    bridge: {
        /** Get the Bridge API response for a property address
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        search: (dataset, params) => {
            const requestPath = `/api/utilities/bridge/${dataset}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mlsGrid: {
        /** List properties
         *
         * Returns data for MLS Grid properties as stored in our database, so keep in mind, this may be slightly out of date depending on when the script that syncs the data last ran.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        listProperties: (params) => {
            const requestPath = "/api/utilities/mls_grid/search" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Get a property
         *
         * Get the MLS Grid API response for a property address
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        search: (params) => {
            const requestPath = "/api/utilities/mls_grid" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    downloads: {
        /** Get a downloaded file
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (uid, params) => {
            const requestPath = `/api/downloads/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    streakPipelines: {
        /** Get Streak pipelines
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/streak/pipelines" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    streakBoxes: {
        /** Get Streak boxes
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (pipeline_key, params) => {
            const requestPath = `/api/streak/pipelines/${pipeline_key}/boxes` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Get a Streak box
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (box_key, params) => {
            const requestPath = `/api/streak/boxes/${box_key}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Get Streak boxes
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        listPaginated: (pipeline_key, params) => {
            const requestPath = `/api/streak/pipelines/${pipeline_key}/paginated_boxes` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    streakFiles: {
        /** Add a file to a Streak Box
         *
         * _Authorization: none required_ */
        upload: (box_key, params) => {
            const requestPath = `/api/streak/upload_file_to_box/${box_key}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    streakUsers: {
        /** Get Streak users
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/streak/users" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    streakViews: {
        /** Get all Streak views
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/streak/views" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    slackAccess: {
        /** Update Slack access
         *
         * Update which users are allowed to access each of our Slack channels
         *
         * _Authorization: none required_ */
        update: (params) => {
            const requestPath = "/api/update_slack_access" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    teams: {
        /** List all teams
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/teams" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a team
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        create: (params) => {
            const requestPath = "/api/teams" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Update a team
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        update: (uid, params) => {
            const requestPath = `/api/teams/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a team
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        delete: (uid, params) => {
            const requestPath = `/api/teams/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    apidoc: {
        /** Get a team
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        get: (uid, params) => {
            const requestPath = `/api/teams/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    teamRoles: {
        /** List roles
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/teams/roles" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    twilioAccess: {
        /** Get twilio access
         *
         * Lists which users are able to send texts or place calls via Twilio phones
         *
         * _Authorization: none required_ */
        get: (params) => {
            const requestPath = "/api/get_twilio_access" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update twilio access
         *
         * Update which users are able to send texts or place calls via Twilio phones
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        update: (params) => {
            const requestPath = "/api/update_twilio_access" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    pspReports: {
        /** Generate Cash to Close
         *
         * _Authorization: none required_ */
        generateCashToCLose: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_cash_to_close` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Generate Multiple Offers report
         *
         * _Authorization: none required_ */
        generateMultipleOffers: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_multiple_offers` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Generate Pre-Listing Presentation PDF
         *
         * _Authorization: none required_ */
        generatePreListingPresentationPDF: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_pre_listing_presentation_pdf` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Generate Listing Presentation PDF
         *
         * _Authorization: none required_ */
        generateListingPresentationPDF: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_listing_presentation_pdf` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Generate Listing Presentation PDF
         *
         * _Authorization: none required_ */
        generateInvestorReport: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_investor_report` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Generate Aged Property Report
         *
         * _Authorization: none required_ */
        generateAgedPropertyReport: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_aged_property_report` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Generate Net Sheet
         *
         * _Authorization: none required_ */
        generateNetSheet: (matrix_uid, params) => {
            const requestPath = `/api/psps/${matrix_uid}/generate_net_sheet` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    compsFinderStates: {
        /** List all states
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/compsfinder/states" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    compsFinderPropertyTypes: {
        /** List all property types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/compsfinder/property_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    compsFinderPropertyStatuses: {
        /** List all property statuses
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/compsfinder/property_statuses" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    buyerLeads: {
        /** Add a collaborator
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        addCollaborator: (buyer_lead_uid, params) => {
            const requestPath = `/api/buyer_leads/${buyer_lead_uid}/add_collaborator` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    contactRelationshipTypes: {
        /** List relationship types
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/contact_relationship_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleSettings: {
        /** Get the current settings
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (params) => {
            const requestPath = "/api/mailschedule/settings" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update the current settings
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        save: (params) => {
            const requestPath = "/api/mailschedule/settings" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    mailScheduleTemplates: {
        /** List all templates
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/mailschedule/templates" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        create: (params) => {
            const requestPath = "/api/mailschedule/templates" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (uid, params) => {
            const requestPath = `/api/mailschedule/templates/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        update: (uid, params) => {
            const requestPath = `/api/mailschedule/templates/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (uid, params) => {
            const requestPath = `/api/mailschedule/templates/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Re-order templates
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        reorder: (params) => {
            const requestPath = "/api/mailschedule/templates/reorder" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Duplicate a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        duplicate: (uid, params) => {
            const requestPath = `/api/mailschedule/templates/${uid}/duplicate` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Download Templates as CSV
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        download: (params) => {
            const requestPath = "/api/mailschedule/templates/download" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailSchedule: {
        /** Get the status of each template for a given box, pipeline, or agent
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        getStatusOfTemplate: (params) => {
            const requestPath = "/api/mailschedule/status" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Set the status of a template for a given box
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        setStatusOfTemplate: (params) => {
            const requestPath = "/api/mailschedule/status" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Run the Mail Schedule
         *
         * This endpoint triggers a script which runs the Mail Schedule. This script may take a while to run; if you set `run_in_background` to `true`, this endpoint will immediately return a `uid` for the script execution almost immediately, the script itself will run in the bacground, and you can use this `uid` with the *Check execution status* endpoint to check the status of the script and get a summary of processed templates.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        run: (params) => {
            const requestPath = "/api/mailschedule/run" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Check execution status
         *
         * The *Run the Mail Schedule* endpoint is designed to return the execution `uid` immediately, but the script itself takes a while to run.  Use this script to check the script's status.  If the script has finished, it will return a summary of the processed templates.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        checkExecutionStatus: (uid, params) => {
            const requestPath = `/api/mailschedule/run/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Parse template for box
         *
         * Replaces all wildcards and parses Jinja syntax and other formatters for the given template and box
         *
         * _Authorization: none required_ */
        parseTemplateForBox: (params) => {
            const requestPath = "/api/mailschedule/parse_template_for_box" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    mailScheduleErrors: {
        /** List all errors
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/mailschedule/errors" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    sellerLeads: {
        /** Add a showing
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        addShowing: (seller_lead_uid, params) => {
            const requestPath = `/api/seller_leads/${seller_lead_uid}/add_showing` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    vendorTypes: {
        /** List vendor types
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/vendor_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    choiceSets: {
        /** List all choices in a set
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/choice_sets" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a choice set
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        create: (params) => {
            const requestPath = "/api/choice_sets" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get a choice set
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (uid, params) => {
            const requestPath = `/api/choice_sets/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a choice set
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        update: (uid, params) => {
            const requestPath = `/api/choice_sets/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a choice set
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        delete: (uid, params) => {
            const requestPath = `/api/choice_sets/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    fields: {
        /** List all field definitions
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/fields" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** List all Pre-Offer field definitions
         *
         * Does not require authentication. This allows clients who are completing the QFO form to get a list of only the relevant field definitions for the QFO without exposing *all* of our field definitions.
         *
         * _Authorization: none required_ */
        listPreOffer: (params) => {
            const requestPath = "/api/fields/pre-offer" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a field
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        create: (params) => {
            const requestPath = "/api/fields" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get a field
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (uid, params) => {
            const requestPath = `/api/fields/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a field
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        update: (uid, params) => {
            const requestPath = `/api/fields/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a field
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        delete: (uid, params) => {
            const requestPath = `/api/fields/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Set field section
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        setSection: (uid, params) => {
            const requestPath = `/api/fields/${uid}/set_section` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Reorder fields
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._ */
        reorder: (params) => {
            const requestPath = "/api/fields/reorder" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Download the list of fields as a CSV
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        download: (params) => {
            const requestPath = "/api/fields/download" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    feedbackRequestCadence: {
        /** List all templates
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/feedback_request_cadence" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Get a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (uid, params) => {
            const requestPath = `/api/feedback_request_cadence/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a template
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        update: (uid, params) => {
            const requestPath = `/api/feedback_request_cadence/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    questionsForTheOfferSubmissions: {
        /** List all Questions for the Offer submissions
         *
         * Includes submitted as well as un-submitted QFO's
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/forms/questions_for_the_offer" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a new Questions for the Offer submission
         *
         * Includes submitted as well as un-submitted QFO's
         *
         * _Authorization: none required_ */
        create: (params) => {
            const requestPath = "/api/forms/questions_for_the_offer" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get a Questions for the Offer submission
         *
         * Includes submitted as well as un-submitted QFO's
         *
         * _Authorization: none required_ */
        get: (uid, params) => {
            const requestPath = `/api/forms/questions_for_the_offer/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a Questions for the Offer submission
         *
         * _Authorization: none required_ */
        update: (uid, params) => {
            const requestPath = `/api/forms/questions_for_the_offer/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a Questions for the Offer submission
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (uid, params) => {
            const requestPath = `/api/forms/questions_for_the_offer/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Submit a Questions for the Offer form
         *
         * Marks the form as *submitted*. May only be called once per form.
         *
         * _Authorization: none required_ */
        submit: (uid, params) => {
            const requestPath = `/api/forms/questions_for_the_offer/${uid}/submit` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Submit a Questions for the Insurance form
         *
         * Marks the form as *submitted*. May only be called once per form. TODO: This documentation seems inaccurate and out of date.
         *
         * _Authorization: none required_ */
        submitQFI: (uid, params) => {
            const requestPath = `/api/forms/questions_for_the_insurance/${uid}/submit_qfi` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Pull data from Bridge API
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        populateBridgeData: (uid, params) => {
            const requestPath = `/api/forms/questions_for_the_offer/${uid}/populate_bridge_data` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Count Questions for the Offer submissions
         *
         * Includes submitted as well as un-submitted QFO's
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        count: (params) => {
            const requestPath = "/api/forms/questions_for_the_offer/count" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    forms: {
        questionsForTheOffer: {
            /** Get a quote for the submission
             *
             * Returns the ascii-encoded representation of a PDF quote for a submitted QFO
             *
             * _Authorization: none required_ */
            getPDF: (uid, params) => {
                const requestPath = `/api/forms/questions_for_the_offer/${uid}/pdf` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
                if (params.shouldUseCache && cache[requestPath]) {
                    params.onSuccess && params.onSuccess(cache[requestPath]);
                }
                else {
                    return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                        .then((response) => {
                        if (response.ok) {
                            return response.json()
                                .then((result) => {
                                if (params.shouldUpdateCache) {
                                    cache[requestPath] = result;
                                }
                                ;
                                params.onSuccess && params.onSuccess(result);
                            })
                                .catch((error) => params.onError && params.onError(error));
                        }
                        return response.text()
                            .then((result) => params.onError && params.onError({
                            response: response,
                            status: response.status,
                            statusText: response.statusText,
                            message: result
                        }))
                            .catch((error) => params.onError && params.onError(error));
                    });
                }
            },
        },
    },
    userRoles: {
        /** List all user roles
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/lists/roles" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    entityTypes: {
        /** List all entity types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/entity_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    fieldTypes: {
        /** List all field types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/field_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleDateOffsetUnits: {
        /** List all date offset units
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/date_offset_units" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleTemplateTypes: {
        /** List all template types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/template_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleStageCriteriaComparisonTypes: {
        /** List all stage criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/stage_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleDateCriteriaComparisonTypes: {
        /** List all date field criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/date_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleTagCriteriaComparisonTypes: {
        /** List all tag field criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/tag_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleTextCriteriaComparisonTypes: {
        /** List all text field criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/text_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleDropdownCriteriaComparisonTypes: {
        /** List all dropdown field criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/dropdown_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleCheckboxCriteriaComparisonTypes: {
        /** List all checkbox criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/checkbox_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleTemplateCriteriaComparisonTypes: {
        /** List all template criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/template_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleTemplateCriteriaDelayModes: {
        /** List all template criteria delay modes
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/template_criteria_delay_modes" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleTemplateCriteriaDelayUnits: {
        /** List all template criteria delay units
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/template_criteria_delay_units" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleViewCriteriaComparisonTypes: {
        /** List all view criteria comparison types
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/lists/mailschedule/view_criteria_comparison_types" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailScheduleBoilerplate: {
        /** List all boilerplate
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/mailschedule/boilerplate" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a boilerplate
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        create: (params) => {
            const requestPath = "/api/mailschedule/boilerplate" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get a boilerplate
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (uid, params) => {
            const requestPath = `/api/mailschedule/boilerplate/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a boilerplate
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        update: (uid, params) => {
            const requestPath = `/api/mailschedule/boilerplate/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a boilerplate
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (uid, params) => {
            const requestPath = `/api/mailschedule/boilerplate/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Duplicate a boilerplate
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        duplicate: (uid, params) => {
            const requestPath = `/api/mailschedule/boilerplate/${uid}/duplicate` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    mailScheduleScriptExecutions: {
        /** List execution logs
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        listExecutions: (params) => {
            const requestPath = "/api/mailschedule/executions" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Get an execution log
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        getExecution: (uid, params) => {
            const requestPath = `/api/mailschedule/executions/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Get an execution log
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        stopExecution: (uid, params) => {
            const requestPath = `/api/mailschedule/executions/${uid}/stop` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** List template logs
         *
         * Returns all template logs from a single script execution
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        getLogs: (execution_uid, params) => {
            const requestPath = `/api/mailschedule/executions/${execution_uid}/logs` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    mailschedule: {
        /** Adds email to unsubscribed emails
         *
         * Add given email to unsubcribed emails.
         *
         * _Authorization: none required_ */
        unsubscribe: (params) => {
            const requestPath = "/api/unsubscribe" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    psps: {
        /** List PSPs
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/psps" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Create a PSP
         *
         * _Authorization: none required_ */
        create: (params) => {
            const requestPath = "/api/psps" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Update a PSP
         *
         * _Authorization: none required_ */
        update: (uid, params) => {
            const requestPath = `/api/psps/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete a PSP
         *
         * _Authorization: none required_ */
        delete: (uid, params) => {
            const requestPath = `/api/psps/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Download PSPs
         *
         * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._ */
        download: (params) => {
            const requestPath = "/api/psps/download" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Count PSPs
         *
         * _Authorization: none required_ */
        count: (params) => {
            const requestPath = "/api/psps/count" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Duplicate a PSP
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        duplicate: (uid, params) => {
            const requestPath = `/api/psps/${uid}/duplicate` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Share a PSP
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        share: (uid, params) => {
            const requestPath = `/api/psps/${uid}/share` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Archive a PSP
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        archive: (uid, params) => {
            const requestPath = `/api/psps/${uid}/archive` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Unarchive a PSP
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        unarchive: (uid, params) => {
            const requestPath = `/api/psps/${uid}/unarchive` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Archive multiple PSPs
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        bulkArchive: (params) => {
            const requestPath = "/api/psps/bulk_archive" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Unarchive multiple PSPs
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        bulkUnarchive: (params) => {
            const requestPath = "/api/psps/bulk_unarchive" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Delete multiple PSPs
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        bulkDelete: (params) => {
            const requestPath = "/api/psps/bulk_delete" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Submit a training PSP
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        submit: (uid, params) => {
            const requestPath = `/api/psps/${uid}/submit` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    scripts: {
        /** Get a list of background scripts setup to run via CRON
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/scripts" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Manually run a background script
         *
         * The `success` response returned by this endpoint simply indicates
         *         that the script execution has begun.  Use the `/api/scripts/status` endpoint
         *         to check the execution status of a script.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        run: (script_name, params) => {
            const requestPath = `/api/scripts/${script_name}/run` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Check the status of a script execution
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        status: (uid, params) => {
            const requestPath = `/api/scripts/executions/${uid}/status` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    sellerReportFeedback: {
        /** Delete feedback
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (twilio_message_sid, params) => {
            const requestPath = `/api/feedback/${twilio_message_sid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    twilioAutoReplySettings: {
        /** Add a new auto reply setting
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        create: (params) => {
            const requestPath = "/api/twilio/autoreply" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Update an auto reply setting
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        update: (uid, params) => {
            const requestPath = `/api/twilio/autoreply/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Remove an auto reply setting
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (uid, params) => {
            const requestPath = `/api/twilio/autoreply/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    twilioBlockedNumbers: {
        /** List blocked phone numbers
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/twilio/blocked_numbers" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Add a new blocked phone number
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        create: (params) => {
            const requestPath = "/api/twilio/blocked_numbers" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get a blocked phone number
         *
         * _Authorization: none required_ */
        get: (uid, params) => {
            const requestPath = `/api/twilio/blocked_numbers/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Update a blocked phone number
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        update: (uid, params) => {
            const requestPath = `/api/twilio/blocked_numbers/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Unblock a blocked phone number
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (uid, params) => {
            const requestPath = `/api/twilio/blocked_numbers/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    twilioCalls: {
        /** List Twilio calls
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/twilio/calls" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    twilioDoNotCallList: {
        /** List all numbers on the Do Not Call list
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/twilio/do_not_call" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Add a number to the Do Not Call list
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        create: (params) => {
            const requestPath = "/api/twilio/do_not_call" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Update a number on the Do Not Call list
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        update: (uid, params) => {
            const requestPath = `/api/twilio/do_not_call/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Remove a number from the Do Not Call list
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        delete: (uid, params) => {
            const requestPath = `/api/twilio/do_not_call/${uid}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "DELETE", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    twilio: {
        /** List all current on-hold music recordings
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        listOnHoldMusic: (params) => {
            const requestPath = "/api/twilio/on_hold_music" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Set the current on-hold music recording for a Twilio phone
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        setOnHoldMusic: (params) => {
            const requestPath = "/api/twilio/on_hold_music" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
        /** Get the current on-hold music recording for a Twilio phone
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        getOnHoldMusic: (phone_number, params) => {
            const requestPath = `/api/twilio/on_hold_music/${phone_number}` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    twilioPhoneNumbers: {
        /** List Phone Numbers
         *
         * Lists all registered Twilio phone numbers
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        list: (params) => {
            const requestPath = "/api/twilio/phones" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    users: {
        /** List users
         *
         * _Authorization: none required_ */
        list: (params) => {
            const requestPath = "/api/users" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Search users
         *
         * _Authorization: none required_ */
        search: (params) => {
            const requestPath = "/api/users/search" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    dotloop: {
        /** Update loop details and participants
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        updateLoop: (profile_email, loop_id, params) => {
            const requestPath = `/api/dotloop/profiles/${profile_email}/loops/${loop_id}/detail` + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            return fetch(requestPath, Object.assign({ method: "POST", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS), body: JSON.stringify(params.data) }, params.options))
                .then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((result) => {
                        if (params.shouldUpdateCache) {
                            cache[requestPath] = result;
                        }
                        ;
                        params.onSuccess && params.onSuccess(result);
                    })
                        .catch((error) => params.onError && params.onError(error));
                }
                return response.text()
                    .then((result) => params.onError && params.onError({
                    response: response,
                    status: response.status,
                    statusText: response.statusText,
                    message: result
                }))
                    .catch((error) => params.onError && params.onError(error));
            });
        },
    },
    googleCloud: {
        /** List filenames from a GCP folder
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        listBlobs: (params) => {
            const requestPath = "/api/utilities/google_cloud/list_blobs" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
        /** Downloads a file from a GCP folder and returns the ASCII content.
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        download: (params) => {
            const requestPath = "/api/utilities/google_cloud/download" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
    currentVersion: {
        /** Get the current application version
         *
         * _Authorization: only logged in, registered users can use this endpoint._ */
        get: (params) => {
            const requestPath = "/api/version" + (params.query ? ("?" + new URLSearchParams(params.query).toString()) : "");
            if (params.shouldUseCache && cache[requestPath]) {
                params.onSuccess && params.onSuccess(cache[requestPath]);
            }
            else {
                return fetch(requestPath, Object.assign({ method: "GET", headers: Object.assign({ "Content-Type": "application/json" }, HEADERS) }, params.options))
                    .then((response) => {
                    if (response.ok) {
                        return response.json()
                            .then((result) => {
                            if (params.shouldUpdateCache) {
                                cache[requestPath] = result;
                            }
                            ;
                            params.onSuccess && params.onSuccess(result);
                        })
                            .catch((error) => params.onError && params.onError(error));
                    }
                    return response.text()
                        .then((result) => params.onError && params.onError({
                        response: response,
                        status: response.status,
                        statusText: response.statusText,
                        message: result
                    }))
                        .catch((error) => params.onError && params.onError(error));
                });
            }
        },
    },
};
export default API;
