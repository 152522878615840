/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List PSP Training Feeedback
 */
export const listPspTrainingFeedback = (params, options) => {
    return axios.get(`/api/psp_training_feedback`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspTrainingFeedbackQueryKey = (params) => {
    return [`/api/psp_training_feedback`, ...(params ? [params] : [])];
};
export const getListPspTrainingFeedbackQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspTrainingFeedbackQueryKey(params);
    const queryFn = ({ signal }) => listPspTrainingFeedback(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSP Training Feeedback
 */
export function useListPspTrainingFeedback(params, options) {
    const queryOptions = getListPspTrainingFeedbackQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Submit PSP Training Feedback
 */
export const createPspTrainingFeedback = (trainingFeedbackCreateRequest, options) => {
    return axios.post(`/api/psp_training_feedback`, trainingFeedbackCreateRequest, options);
};
export const getCreatePspTrainingFeedbackMutationOptions = (options) => {
    const mutationKey = ['createPspTrainingFeedback'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPspTrainingFeedback(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit PSP Training Feedback
*/
export const useCreatePspTrainingFeedback = (options) => {
    const mutationOptions = getCreatePspTrainingFeedbackMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a PSP Training Feeedback submission
*/
export const getPspTrainingFeedback = (uid, options) => {
    return axios.get(`/api/psp_training_feedback/${uid}`, options);
};
export const getGetPspTrainingFeedbackQueryKey = (uid) => {
    return [`/api/psp_training_feedback/${uid}`];
};
export const getGetPspTrainingFeedbackQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspTrainingFeedbackQueryKey(uid);
    const queryFn = ({ signal }) => getPspTrainingFeedback(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP Training Feeedback submission
 */
export function useGetPspTrainingFeedback(uid, options) {
    const queryOptions = getGetPspTrainingFeedbackQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a PSP Training Feedback submission
 */
export const updatePspTrainingFeedback = (uid, trainingFeedbackRequest, options) => {
    return axios.post(`/api/psp_training_feedback/${uid}`, trainingFeedbackRequest, options);
};
export const getUpdatePspTrainingFeedbackMutationOptions = (options) => {
    const mutationKey = ['updatePspTrainingFeedback'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updatePspTrainingFeedback(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a PSP Training Feedback submission
*/
export const useUpdatePspTrainingFeedback = (options) => {
    const mutationOptions = getUpdatePspTrainingFeedbackMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a PSP Training Feedback submission
*/
export const deletePspTrainingFeedback = (uid, options) => {
    return axios.delete(`/api/psp_training_feedback/${uid}`, options);
};
export const getDeletePspTrainingFeedbackMutationOptions = (options) => {
    const mutationKey = ['deletePspTrainingFeedback'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspTrainingFeedback(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a PSP Training Feedback submission
*/
export const useDeletePspTrainingFeedback = (options) => {
    const mutationOptions = getDeletePspTrainingFeedbackMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count PSP Training Feedback submissions
*/
export const countPspTrainingFeedback = (params, options) => {
    return axios.get(`/api/psp_training_feedback/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountPspTrainingFeedbackQueryKey = (params) => {
    return [`/api/psp_training_feedback/count`, ...(params ? [params] : [])];
};
export const getCountPspTrainingFeedbackQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountPspTrainingFeedbackQueryKey(params);
    const queryFn = ({ signal }) => countPspTrainingFeedback(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count PSP Training Feedback submissions
 */
export function useCountPspTrainingFeedback(params, options) {
    const queryOptions = getCountPspTrainingFeedbackQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download PSP Training Feedback submissions
 */
export const downloadPspTrainingFeedback = (params, options) => {
    return axios.get(`/api/psp_training_feedback/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadPspTrainingFeedbackQueryKey = (params) => {
    return [`/api/psp_training_feedback/download`, ...(params ? [params] : [])];
};
export const getDownloadPspTrainingFeedbackQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadPspTrainingFeedbackQueryKey(params);
    const queryFn = ({ signal }) => downloadPspTrainingFeedback(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download PSP Training Feedback submissions
 */
export function useDownloadPspTrainingFeedback(params, options) {
    const queryOptions = getDownloadPspTrainingFeedbackQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
