import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import TeamForm from "../../features/admin/teams/TeamForm";
const TeamFormPage = () => {
    useDocumentTitle("Teams");
    const params = useParams();
    return React.createElement(TeamForm, { uid: params.uid });
};
export default TeamFormPage;
