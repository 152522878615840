import * as React from "react";
import { useLocation } from "react-router";
/**
 * A component that listens for location changes and sends a message to the parent window.
 *
 * @param props
 * @returns
 */
const RouteChangeListener = (props) => {
    const location = useLocation();
    React.useEffect(() => {
        const currentPath = location.pathname + location.search + location.hash;
        window.parent.postMessage({
            type: "ROUTE_CHANGE",
            path: currentPath,
        }, "*"); // Consider specifying the parent origin instead of '*'
    }, [location]);
    return props.children;
};
export default RouteChangeListener;
