import * as React from "react";
import { FileCopy } from "@mui/icons-material";
import mime from "mime";
import { FileIcon as ReactFileIcon, defaultStyles } from "react-file-icon";
import styled from "styled-components";
const Wrapper = styled.div `
  & svg {
    max-height: ${(props) => props.maxHeight}px;
  }
`;
const FileIcon = (props) => {
    const { imgProps, maxHeight, mimeType, url } = props;
    if (["image/jpeg", "image/jpg", "image/png"].includes(mimeType.toLowerCase().trim()) && url) {
        return React.createElement("img", Object.assign({}, imgProps, { src: url, alt: "File", style: Object.assign(Object.assign({}, imgProps === null || imgProps === void 0 ? void 0 : imgProps.style), { borderRadius: "0.25rem" }) }));
    }
    const extension = mime.getExtension(mimeType);
    if (extension) {
        return (React.createElement(Wrapper, { maxHeight: maxHeight },
            React.createElement(ReactFileIcon, Object.assign({}, defaultStyles[extension]))));
    }
    return React.createElement(FileCopy, null);
};
export default FileIcon;
