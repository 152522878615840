import * as React from "react";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Alert, Box, Collapse, Divider, Fab, Grid2, Hidden, Paper, Stack, Tab, Tabs, Tooltip } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import pickBy from "lodash/pickBy";
import { useLocation } from "react-router";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import useSize from "@app/hooks/useSize";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { countTransactionsByThirdParty, listTransactions } from "@app/orval/api/agent-dashboard";
import { useListMailScheduleErrorsForBoxes, useListMailScheduleHistoryForBoxes } from "@app/orval/api/mail-schedule";
import { REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS } from "@app/orval/config";
import Agents from "./components/Agents";
import BuyerLeadActionsMenu from "./components/BuyerLeadActionsMenu";
import Clients from "./components/Clients";
import Forms from "./components/Forms";
import LoadingContracts from "./components/LoadingContracts";
import LockedContractNotification from "./components/LockedContractNotification";
import OtherLeadsAndContracts from "./components/OtherLeadsAndContracts";
import Properties from "./components/properties/Properties";
import SectionHeader from "./components/SectionHeader";
import ThirdParties from "./components/ThirdParties";
import ViewAllToggle from "./components/ViewAllToggle";
import useBuyerLeadApis from "./useBuyerLeadApis";
import useCanAssignSelf from "./useCanAssignSelf";
import useValidation from "./useValidation";
import { getBuyerLeadAlerts } from "./utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import BuyerContractActionsMenu from "../buyer-contracts/components/BuyerContractActionsMenu";
import Collaborators from "../components/box-view/actions/Collaborators";
import Chronology from "../components/box-view/Chronology/Chronology";
import DataEntry from "../components/box-view/DataEntry";
import DataEntrySkeleton from "../components/box-view/DataEntrySkeleton";
import Header from "../components/box-view/Header";
import MailScheduleErrors from "../components/box-view/MailScheduleErrors";
import ValidationErrors from "../components/box-view/ValidationErrors";
import ViewMembership from "../components/box-view/ViewMembership";
import Notes from "../seller-leads/components/Notes";
import { getAddress } from "../utils/utils";
const ALLOWED_VISIBILITY_OPTIONS = [
    "Calls",
    "Comments",
    "Files",
    "Emails",
    "History",
    "SMS",
    "PSPs",
    "Forms",
    "Mail Schedule",
];
const ALLOWED_VISIBILITY_OPTIONS__WRITEABLE = [...ALLOWED_VISIBILITY_OPTIONS];
const BuyerLeadsForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { uid } = props;
    const tab = (_a = props.tab) !== null && _a !== void 0 ? _a : "data";
    const [isChronologyOpen, setIsChronologyOpen] = React.useState(!!window.localStorage.getItem("chronologyOpen"));
    const [isBuyerLeadCollapsed, setIsBuyerLeadCollapsed] = React.useState(false);
    const [collapsedBuyerContracts, setCollapsedBuyerContracts] = React.useState(new Set());
    const [unlockedBuyerContracts, setUnlockedBuyerContracts] = React.useState(new Set());
    const [currentlyEditingPropertyUid, setCurrentlyEditingPropertyUid] = React.useState(null);
    const [search, setSearch] = React.useState("");
    const [searchStringDebounced, setSearchStringDebounced] = React.useState(search);
    const [isViewAllEnabled, setIsViewAllEnabled] = React.useState(false);
    const size = useSize();
    const isSmallDevice = size.isDown("sm");
    const location = useLocation();
    const toggleViewAllEnabled = React.useCallback(() => {
        setIsViewAllEnabled((prev) => !prev);
    }, []);
    const onPropertyAdded = React.useCallback((propertyUid) => {
        setCurrentlyEditingPropertyUid(propertyUid);
    }, [setCurrentlyEditingPropertyUid]);
    const selectPropertyToEdit = React.useCallback((propertyUid) => {
        setCurrentlyEditingPropertyUid(propertyUid);
    }, [setCurrentlyEditingPropertyUid]);
    const closePropertyDialog = React.useCallback(() => {
        setCurrentlyEditingPropertyUid(null);
    }, [setCurrentlyEditingPropertyUid]);
    const { addAgent, addCollaborator, addExistingClient, addNewClient, addProperty, addShowing, addThirdParty, agentChanges, buyerContractFieldSections, buyerContractFields, buyerContractStageChanges, buyerContractStages, buyerContracts, buyerLead, buyerLeadFieldSections, buyerLeadFields, buyerLeadStages, calls, canEdit, comments, createTask, currentlySavingComment, currentlyUploadingFiles, deleteBuyerContract, deleteBuyerLead, deleteComment, deleteTask, emails, fieldValueChanges, files, getBuyerLeadApi, isAddingPSP, isAddingQFO, isLoading, linkPSP, linkQFO, messages, noteChanges, notifications, onBuyerContractFieldSaveBegin, onBuyerContractFieldSaveComplete, onBuyerContractFieldSaveError, onFieldSaveBegin, onFieldSaveComplete, onFieldSaveError, onFileDeleted, onFilesSelectedForUpload, onMarketAnalysisClicked, onSmsSent, removeAgent, removeClient, removeCollaborator, removeProperty, removeShowing, removeThirdParty, reorderClients, saveComment, setBuyerContractStage, setBuyerContracts, setNotes, setStage, stageChanges, startNewBuyerContract, startNewPSP, startNewQFO, streakComments, streakFiles, tasks, unlinkPSP, unlinkQFO, updateAgent, updateCollaborator, updateProperty, updateShowing, updateTask, updateThirdParty, } = useBuyerLeadApis(uid, { search: searchStringDebounced, onPropertyAdded });
    const canAssignSelf = useCanAssignSelf(buyerLead !== null && buyerLead !== void 0 ? buyerLead : undefined);
    const allStageChanges = React.useMemo(() => {
        const changes = [...(buyerContractStageChanges !== null && buyerContractStageChanges !== void 0 ? buyerContractStageChanges : []), ...(stageChanges !== null && stageChanges !== void 0 ? stageChanges : [])];
        return changes;
    }, [buyerContractStageChanges, stageChanges]);
    const buyerContractUids = React.useMemo(() => {
        return buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.map((x) => x.uid);
    }, [buyerContracts]);
    const getMailScheduleHistoryApi = useListMailScheduleHistoryForBoxes(pickBy({
        buyer_lead_uids: uid,
        buyer_contract_uids: buyerContractUids === null || buyerContractUids === void 0 ? void 0 : buyerContractUids.join(","),
    }, (val) => !!val));
    const mailScheduleHistory = (_c = (_b = getMailScheduleHistoryApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const getMailScheduleErrorsApi = useListMailScheduleErrorsForBoxes(pickBy({
        buyer_lead_uids: uid,
        buyer_contract_uids: buyerContractUids === null || buyerContractUids === void 0 ? void 0 : buyerContractUids.join(","),
    }, (val) => !!val));
    const mailScheduleErrors = (_e = (_d = getMailScheduleErrorsApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const updateSearch = useUpdateSearch({ allowBlankQueryParams: true, navigateOptions: { state: location.state } });
    // callbacks
    const onTabChanged = React.useCallback((_, value) => {
        updateSearch("tab", value);
        if (value === "chronology") {
            setIsChronologyOpen(true);
        }
    }, [updateSearch, setIsChronologyOpen]);
    const searchTimeout = React.useRef(null);
    const doSearch = React.useCallback((searchString) => {
        if (searchTimeout.current) {
            window.clearTimeout(searchTimeout.current);
        }
        setSearch(searchString);
        searchTimeout.current = window.setTimeout(() => {
            setSearchStringDebounced(searchString);
        }, 500);
    }, [setSearch, setSearchStringDebounced]);
    const openChronology = React.useCallback(() => {
        window.localStorage.setItem("chronologyOpen", "1");
        setIsChronologyOpen(true);
    }, []);
    const closeChronology = React.useCallback(() => {
        window.localStorage.removeItem("chronologyOpen");
        setIsChronologyOpen(false);
    }, []);
    const collapseBuyerLead = React.useCallback(() => {
        setIsBuyerLeadCollapsed((prev) => !prev);
    }, []);
    const collapseBuyerContract = React.useCallback((buyerContractUid) => () => {
        setCollapsedBuyerContracts((val) => {
            if (val.has(buyerContractUid)) {
                val.delete(buyerContractUid);
            }
            else {
                val.add(buyerContractUid);
            }
            return new Set([...val]);
        });
    }, [setCollapsedBuyerContracts]);
    const unlockBuyerContract = React.useCallback((buyerContractUid) => () => {
        setUnlockedBuyerContracts((val) => {
            if (val.has(buyerContractUid)) {
                val.delete(buyerContractUid);
            }
            else {
                val.add(buyerContractUid);
            }
            return new Set([...val]);
        });
    }, [setUnlockedBuyerContracts]);
    const onImportFromMLSComplete = React.useCallback((buyerContract) => {
        setBuyerContracts((prev) => {
            if (prev) {
                return prev.map((x) => (x.uid === buyerContract.uid ? buyerContract : x));
            }
            return prev;
        });
    }, [setBuyerContracts]);
    React.useEffect(() => {
        for (const buyerContractUid of buyerContractUids !== null && buyerContractUids !== void 0 ? buyerContractUids : []) {
            setCollapsedBuyerContracts((val) => {
                if (buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.find((x) => { var _a; return x.uid === buyerContractUid && ((_a = x.stage) === null || _a === void 0 ? void 0 : _a.name) === "Terminated"; })) {
                    val.add(buyerContractUid);
                }
                return new Set([...val]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyerContractUids === null || buyerContractUids === void 0 ? void 0 : buyerContractUids.join(",")]);
    const clientUids = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.clients.map((x) => x.client.uid).sort();
    const transactionsForClientsApi = usePostQuery(listTransactions, {
        client_uids: clientUids !== null && clientUids !== void 0 ? clientUids : [],
    }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS, clientUids != null && clientUids.length > 0);
    const transactionsForClients = (_g = (_f = transactionsForClientsApi.data) === null || _f === void 0 ? void 0 : _f.data.filter((x) => x.uid !== uid && x.lead_uid !== uid)) !== null && _g !== void 0 ? _g : [];
    const countTransactionsByThirdPartyApi = usePostQuery(countTransactionsByThirdParty, {
        third_party_uids: (_h = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.vendors.map((x) => x.contact.uid).sort()) !== null && _h !== void 0 ? _h : [],
    }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS, (buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.vendors) != null && buyerLead.vendors.length > 0);
    const transactionsByThirdParty = (_k = (_j = countTransactionsByThirdPartyApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const numTransactionsByThirdParty = React.useMemo(() => {
        return transactionsByThirdParty === null || transactionsByThirdParty === void 0 ? void 0 : transactionsByThirdParty.reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.third_party_contact_uid]: x.count })), {});
    }, [transactionsByThirdParty]);
    const { buyerContractFieldSectionsSaturated, buyerContractFieldsSaturated, buyerLeadFieldSectionsSaturated, buyerLeadFieldsSaturated, validationErrors, } = useValidation({
        buyerLead,
        buyerContracts,
        buyerLeadFields,
        buyerContractFields,
        buyerLeadFieldSections,
        buyerContractFieldSections,
    });
    if (!buyerLead && getBuyerLeadApi.error) {
        return React.createElement(CoreError, { error: getBuyerLeadApi.error });
    }
    if (!buyerLead || isLoading) {
        return React.createElement(CoreLoading, null);
    }
    const buyerLeadAddress = buyerLead.properties.length > 0 ? buyerLead.properties[0].property_address : undefined;
    const alerts = getBuyerLeadAlerts({
        buyerLead,
        buyerContracts,
        isViewAllEnabled,
    });
    return (React.createElement(Grid2, { container: true, direction: "column", style: { background: "#eee", overflowY: "hidden", height: "100%" }, wrap: "nowrap" },
        validationErrors.length > 0 && (React.createElement(Grid2, null,
            React.createElement(ValidationErrors, { errors: validationErrors }))),
        React.createElement(Grid2, null,
            React.createElement(MailScheduleErrors, { entityUids: [buyerLead.uid, ...(buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : []).map((x) => x.uid)] })),
        alerts
            .filter((x) => x.test)
            .map((x) => (React.createElement(Grid2, { key: x.message },
            React.createElement(Alert, { severity: x.severity }, x.message)))),
        React.createElement(Grid2, { style: { width: "100%" } },
            React.createElement(Box, { p: 2, pt: 0, pb: 0 },
                React.createElement(Header, { moduleName: "Buyers", pipelineUrl: "/buyer-leads", name: buyerLead.name, showCopyLinkButton: true, onDelete: deleteBuyerLead, deleteVerbiage: "Delete lead", referredFrom: "/overview", leadSource: buyerLead.fields["Source"], leadType: buyerLead.fields["Lead Type"], createdAt: buyerLead.created_at },
                    React.createElement(ViewAllToggle, { viewAll: isViewAllEnabled, onClick: toggleViewAllEnabled }),
                    React.createElement(ViewMembership, { entityType: "Buyer Lead", entityUid: buyerLead.uid }),
                    React.createElement(BuyerLeadActionsMenu, { onCreateBuyerContractSelected: startNewBuyerContract, buyerLead: buyerLead })))),
        React.createElement(Grid2, { style: { overflow: "hidden", flex: 1 } },
            !isChronologyOpen && (React.createElement(Hidden, { lgDown: true },
                React.createElement(Tooltip, { title: "Open history", placement: "left" },
                    React.createElement(Fab, { size: "large", onClick: openChronology, style: {
                            position: "absolute",
                            top: "50%",
                            right: "-20px",
                            opacity: 0.8,
                            backgroundColor: "#00a9df",
                            color: "white",
                        } },
                        React.createElement(KeyboardArrowLeft, { style: { transform: "translateX(-8px)" } }))))),
            React.createElement(Grid2, { container: true, spacing: 1, style: { height: "100%" } },
                React.createElement(Hidden, { lgDown: tab !== "overview" },
                    React.createElement(Grid2, { style: {
                            height: "100%",
                            overflowY: "hidden",
                            minWidth: size.isDown("lg") ? "100%" : "325px",
                            maxWidth: size.isDown("lg") ? "100%" : "350px",
                        } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { maxHeight: "100%", overflowY: "scroll" } },
                                React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll" }, direction: "column" },
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2, pb: 0 },
                                            React.createElement(Collaborators, { collaborators: buyerLead.collaborators, onCreateCollaborator: canEdit || canAssignSelf ? addCollaborator : undefined, onUpdateCollaborator: canEdit ? updateCollaborator : undefined, onRemoveCollaborator: canEdit ? removeCollaborator : undefined, canAssignSelfOnly: !canEdit && canAssignSelf }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2, pb: 0 },
                                            React.createElement(Clients, { clients: buyerLead.clients, onRemoveClient: canEdit ? removeClient : undefined, onAddNewClient: canEdit ? addNewClient : undefined, onAddExistingClient: canEdit ? addExistingClient : undefined, allowEditing: canEdit, onReorderClients: canEdit ? reorderClients : undefined, getBuyerLeadApi: getBuyerLeadApi }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2, pb: 0 },
                                            React.createElement(Agents, { agents: buyerLead.agents, agentTypes: ["Producer", "Co-Agent", "Showing Agent"], variant: "buyer", onCreateAgent: canEdit ? addAgent : undefined, onUpdateAgent: canEdit ? updateAgent : undefined, onRemoveAgent: canEdit ? removeAgent : undefined }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2, pb: 0 },
                                            React.createElement(ThirdParties, { parties: buyerLead.vendors, onAddParty: addThirdParty, onUpdateParty: updateThirdParty, onRemoveParty: removeThirdParty, buyerLead: buyerLead, buyerContracts: buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : undefined, transactionsPerThirdParty: numTransactionsByThirdParty, getBuyerLeadApi: getBuyerLeadApi, canEdit: canEdit }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2, pb: 0 },
                                            React.createElement(Forms, { buyerLeadUid: buyerLead.uid, onRemoveQFO: unlinkQFO }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2, pb: 0 },
                                            React.createElement(Properties, { buyerLead: buyerLead, onAddProperty: addProperty, onUpdateProperty: updateProperty, onRemoveProperty: removeProperty, onSelectPropertyToEdit: selectPropertyToEdit, currentlyEditingPropertyUid: currentlyEditingPropertyUid !== null && currentlyEditingPropertyUid !== void 0 ? currentlyEditingPropertyUid : undefined, onDialogClose: closePropertyDialog, onAddShowing: addShowing, onUpdateShowing: updateShowing, onRemoveShowing: removeShowing, onStartNewPSP: startNewPSP, onMarketAnalysisClicked: onMarketAnalysisClicked, onUnlinkPSP: unlinkPSP, onLinkPSP: linkPSP, isAddingPSP: isAddingPSP, onStartNewQFO: startNewQFO, onUnlinkQFO: unlinkQFO, onLinkQFO: linkQFO, isAddingQFO: isAddingQFO, canEdit: canEdit }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(Notes, { notes: (_l = buyerLead.notes) !== null && _l !== void 0 ? _l : "", onSetNotes: setNotes, canEdit: canEdit }))),
                                    transactionsForClients.length > 0 && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(OtherLeadsAndContracts, { clients: buyerLead.clients, buyerLeads: transactionsForClients.filter((t) => t.buyer_lead).map((t) => t.buyer_lead), sellerLeads: transactionsForClients
                                                        .filter((t) => t.seller_lead)
                                                        .map((t) => t.seller_lead), buyerContracts: transactionsForClients
                                                        .filter((t) => t.buyer_contract)
                                                        .map((t) => t.buyer_contract), sellerContracts: transactionsForClients
                                                        .filter((t) => t.seller_contract)
                                                        .map((t) => t.seller_contract) })))))))))),
                React.createElement(Hidden, { lgDown: tab !== "data" },
                    React.createElement(Grid2, { style: { height: "100%", overflowY: "hidden", flex: 1 } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "scroll", width: "100%" } },
                            React.createElement(Grid2, { container: true, spacing: 2, direction: "column", style: { width: "100%" } },
                                React.createElement(Grid2, { style: { width: "100%" } },
                                    React.createElement(Paper, { style: { maxHeight: "100%", overflowY: "scroll", width: "100%" } },
                                        React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll", width: "100%" }, direction: "column" },
                                            React.createElement(Grid2, { style: { width: "100%" } },
                                                React.createElement(Box, { p: 2 },
                                                    React.createElement(SectionHeader, { uid: buyerLead.uid, name: "Lead", address: buyerLeadAddress !== null && buyerLeadAddress !== void 0 ? buyerLeadAddress : undefined, isCollapsed: isBuyerLeadCollapsed, onCollapse: buyerContracts && buyerContracts.length > 0 ? collapseBuyerLead : undefined, stages: buyerLeadStages !== null && buyerLeadStages !== void 0 ? buyerLeadStages : undefined, stage: (_m = buyerLead.stage) === null || _m === void 0 ? void 0 : _m.uid, onStageChange: setStage, mailScheduleLinkUrl: `/buyer-leads/${buyerLead.uid}/mail-schedule`, numMailScheduleErrors: mailScheduleErrors === null || mailScheduleErrors === void 0 ? void 0 : mailScheduleErrors.filter((x) => x.box_key === buyerLead.streak_box_key).length, canEdit: canEdit }))),
                                            React.createElement(Collapse, { in: !isBuyerLeadCollapsed },
                                                React.createElement(Grid2, null, buyerLeadFieldsSaturated ? (React.createElement(DataEntry, { apiUri: `/api/buyer_leads/${buyerLead.uid}`, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, onFieldSaveError: onFieldSaveError, fields: buyerLeadFieldsSaturated, sections: buyerLeadFieldSectionsSaturated !== null && buyerLeadFieldSectionsSaturated !== void 0 ? buyerLeadFieldSectionsSaturated : [], values: buyerLead.fields, viewAllEnabled: isViewAllEnabled, collapseCompletedSectionsByDefault: true, subordinateUserUidsToCheck: buyerLead.agents.map((x) => x.agent.uid), isEditable: canEdit })) : (React.createElement(Box, { p: 2 },
                                                    React.createElement(DataEntrySkeleton, null)))))))),
                                buyerLead.contracts.length > 0 && !buyerContracts && (React.createElement(Grid2, null,
                                    React.createElement(LoadingContracts, { numContracts: buyerLead.contracts.length }))),
                                (buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : []).map((buyerContract) => {
                                    var _a, _b, _c, _d, _e, _f;
                                    const shouldBeLocked = ((_a = buyerContract.stage) === null || _a === void 0 ? void 0 : _a.name) === "Closed" || ((_b = buyerContract.stage) === null || _b === void 0 ? void 0 : _b.name) === "Terminated";
                                    const isUnlocked = unlockedBuyerContracts.has(buyerContract.uid);
                                    const isLocked = !isUnlocked && shouldBeLocked;
                                    return (React.createElement(Grid2, { key: buyerContract.uid, style: { width: "100%" } },
                                        React.createElement(Paper, { style: { maxHeight: "calc(100% - 1rem)", width: "100%", overflowY: "scroll" } },
                                            React.createElement(Grid2, { container: true, style: { height: "100%", width: "100%", overflowY: "scroll" }, direction: "column" },
                                                React.createElement(Grid2, { style: { width: "100%" } },
                                                    React.createElement(Box, { p: 2 },
                                                        React.createElement(SectionHeader, { uid: buyerContract.uid, name: "Contract", address: (_c = getAddress(buyerContract)) !== null && _c !== void 0 ? _c : undefined, stages: buyerContractStages.filter((x) => buyerContract.fields["Transaction Type"] !== "Inventory | New Construction" &&
                                                                buyerContract.fields["Transaction Type"] !== "Pre-Sale | New Construction"
                                                                ? ![
                                                                    "Builder Signatures",
                                                                    "Permits",
                                                                    "Foundation",
                                                                    "Framing",
                                                                    "Drywall",
                                                                    "Trim Out",
                                                                    "Punch List",
                                                                ].includes(x.name)
                                                                : true), stage: (_d = buyerContract.stage) === null || _d === void 0 ? void 0 : _d.uid, disableStageSelect: isLocked, onStageChange: setBuyerContractStage(buyerContract.uid), onCollapse: collapseBuyerContract(buyerContract.uid), isCollapsed: collapsedBuyerContracts.has(buyerContract.uid), onDelete: !isLocked ? deleteBuyerContract(buyerContract.uid) : undefined, isLocked: !isUnlocked, onToggleLock: shouldBeLocked ? unlockBuyerContract(buyerContract.uid) : undefined, deleteVerbiage: "Delete buyer contract", mailScheduleLinkUrl: `/buyer-contracts/${buyerContract.uid}/mail-schedule`, numMailScheduleErrors: mailScheduleErrors === null || mailScheduleErrors === void 0 ? void 0 : mailScheduleErrors.filter((x) => x.box_key === buyerContract.streak_buyer_contracts_box_key).length, toolbar: React.createElement(Stack, { direction: "row" },
                                                                React.createElement(ViewMembership, { entityType: "Buyer Contract", entityUid: buyerContract.uid }),
                                                                React.createElement(BuyerContractActionsMenu, { buyerContract: buyerContract, onImportFromMLSComplete: onImportFromMLSComplete })), canEdit: canEdit }))),
                                                React.createElement(Collapse, { in: !collapsedBuyerContracts.has(buyerContract.uid) },
                                                    isLocked && (React.createElement(Grid2, null,
                                                        React.createElement(Box, { p: 2 },
                                                            React.createElement(LockedContractNotification, null)))),
                                                    React.createElement(Grid2, null, (buyerContractFieldsSaturated === null || buyerContractFieldsSaturated === void 0 ? void 0 : buyerContractFieldsSaturated[buyerContract.uid]) ? (React.createElement(DataEntry, { apiUri: `/api/buyer_contracts/${buyerContract.uid}`, onFieldSaveBegin: onBuyerContractFieldSaveBegin(buyerContract.uid), onFieldSaveComplete: onBuyerContractFieldSaveComplete(buyerContract.uid), onFieldSaveError: onBuyerContractFieldSaveError(buyerContract.uid), fields: (_e = buyerContractFieldsSaturated[buyerContract.uid]) !== null && _e !== void 0 ? _e : [], sections: (_f = buyerContractFieldSectionsSaturated === null || buyerContractFieldSectionsSaturated === void 0 ? void 0 : buyerContractFieldSectionsSaturated[buyerContract.uid]) !== null && _f !== void 0 ? _f : [], values: buyerContract.fields, viewAllEnabled: isViewAllEnabled, isEditable: !isLocked, collapseCompletedSectionsByDefault: true })) : (React.createElement(Box, { p: 2 },
                                                        React.createElement(DataEntrySkeleton, null)))))))));
                                }))))),
                (size.isUp("lg") ? isChronologyOpen : tab === "chronology") && (React.createElement(Grid2, { size: size.isUp("lg") ? undefined : 12, style: {
                        height: "100%",
                        overflowY: "hidden",
                        minWidth: size.isUp("lg") ? "450px" : undefined,
                        maxWidth: size.isUp("lg") ? "600px" : undefined,
                    } },
                    React.createElement(Box, { p: 2, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Paper, { style: { maxHeight: "calc(100% - 1rem)", height: "100%", overflowY: "hidden" } },
                            React.createElement(Box, { p: 2, style: { height: "100%" } },
                                React.createElement(Chronology, { allowedVisibilityOptions: ALLOWED_VISIBILITY_OPTIONS__WRITEABLE, visibilityType: "Buyer", updates: fieldValueChanges, stageChanges: allStageChanges, comments: comments, tasks: tasks, messages: messages, calls: calls, emails: emails, files: files, currentlyUploadingFiles: currentlyUploadingFiles, noteChanges: noteChanges, agentChanges: agentChanges, notifications: notifications, streakComments: streakComments, streakFiles: streakFiles, psps: [], qfos: [], mailScheduleTemplateHistory: mailScheduleHistory !== null && mailScheduleHistory !== void 0 ? mailScheduleHistory : undefined, contacts: buyerLead.clients
                                        .filter((c) => c.client.phone && isValidPhoneNumber(c.client.phone.trim(), "US"))
                                        .map((c) => ({
                                        name: [c.client.first_name, c.client.last_name].filter((o) => o).join(" ") || "Client",
                                        phone_number: c.client.phone.trim(),
                                    })), isSubmittingComment: !!currentlySavingComment, onSmsSent: onSmsSent, onSearch: doSearch, onCommentSubmitted: saveComment, onCommentDeleted: deleteComment, onTaskCreated: createTask, onTaskUpdated: updateTask, onTaskDeleted: deleteTask, onFileDeleted: onFileDeleted, onFilesSelectedForUpload: onFilesSelectedForUpload, showToolbar: true, onClose: isSmallDevice ? undefined : closeChronology, entityUid: buyerLead.uid })))))))),
        React.createElement(Hidden, { lgUp: true },
            React.createElement(Grid2, { style: { background: "white" } },
                React.createElement(Tabs, { value: tab, onChange: onTabChanged, variant: "fullWidth" },
                    React.createElement(Tab, { value: "overview", label: "Overview" }),
                    React.createElement(Tab, { value: "data", label: "Data" }),
                    React.createElement(Tab, { value: "chronology", label: "Chronology" }))))));
};
export default BuyerLeadsForm;
