import * as React from "react";
import { useNavigate } from "react-router";
/**
 * A component that listens for messages from the parent window and navigates to the specified route.
 *
 * @param props
 * @returns
 */
const NavigationListener = (props) => {
    const navigate = useNavigate();
    React.useEffect(() => {
        console.log("adding NAVIGATE listener");
        window.postMessage({ type: "NAVIGATE_LISTENER_ADDED" });
        window.addEventListener("message", (event) => {
            if (event.data.type === "NAVIGATE") {
                console.log(`navigating to ${event.data.to}`);
                navigate(event.data.to);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return props.children;
};
export default NavigationListener;
