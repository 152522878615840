/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List all SOPs
 */
export const listSops = (params, options) => {
    return axios.get(`/api/sops`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListSopsQueryKey = (params) => {
    return [`/api/sops`, ...(params ? [params] : [])];
};
export const getListSopsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSopsQueryKey(params);
    const queryFn = ({ signal }) => listSops(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all SOPs
 */
export function useListSops(params, options) {
    const queryOptions = getListSopsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create an SOP
 */
export const createSop = (sOPWithContentRequest, options) => {
    return axios.post(`/api/sops`, sOPWithContentRequest, options);
};
export const getCreateSopMutationOptions = (options) => {
    const mutationKey = ['createSop'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createSop(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create an SOP
*/
export const useCreateSop = (options) => {
    const mutationOptions = getCreateSopMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get an SOP
*/
export const getSop = (uid, params, options) => {
    return axios.get(`/api/sops/${uid}`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetSopQueryKey = (uid, params) => {
    return [`/api/sops/${uid}`, ...(params ? [params] : [])];
};
export const getGetSopQueryOptions = (uid, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSopQueryKey(uid, params);
    const queryFn = ({ signal }) => getSop(uid, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an SOP
 */
export function useGetSop(uid, params, options) {
    const queryOptions = getGetSopQueryOptions(uid, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update an SOP
 */
export const updateSop = (uid, sOPWithContentRequest, options) => {
    return axios.post(`/api/sops/${uid}`, sOPWithContentRequest, options);
};
export const getUpdateSopMutationOptions = (options) => {
    const mutationKey = ['updateSop'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSop(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an SOP
*/
export const useUpdateSop = (options) => {
    const mutationOptions = getUpdateSopMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete an SOP
*/
export const deleteSop = (uid, options) => {
    return axios.delete(`/api/sops/${uid}`, options);
};
export const getDeleteSopMutationOptions = (options) => {
    const mutationKey = ['deleteSop'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteSop(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete an SOP
*/
export const useDeleteSop = (options) => {
    const mutationOptions = getDeleteSopMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Update sharing settings for an SOP
*/
export const shareSop = (uid, sOPShareWriteRequest, options) => {
    return axios.post(`/api/sops/${uid}/share`, sOPShareWriteRequest, options);
};
export const getShareSopMutationOptions = (options) => {
    const mutationKey = ['shareSop'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return shareSop(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update sharing settings for an SOP
*/
export const useShareSop = (options) => {
    const mutationOptions = getShareSopMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List permission types for SOPs
*/
export const listPermissionTypes = (options) => {
    return axios.get(`/api/sops/list_permission_types`, options);
};
export const getListPermissionTypesQueryKey = () => {
    return [`/api/sops/list_permission_types`];
};
export const getListPermissionTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPermissionTypesQueryKey();
    const queryFn = ({ signal }) => listPermissionTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List permission types for SOPs
 */
export function useListPermissionTypes(options) {
    const queryOptions = getListPermissionTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
