import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, TextField, RadioGroup, FormControlLabel, Radio, Grid2, Box, } from "@mui/material";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import { NOT_DUPLICATE_CONTACT_FILTER } from "./utils";
import ContactAutocomplete from "../../components/box-view/actions/ContactAutocomplete";
const AddClientDialog = (props) => {
    var _a, _b, _c, _d, _e;
    const { DialogProps, onAddExistingClientSelected, onAddNewClientSelected, onClose } = props;
    const [mode, setMode] = React.useState("select-existing");
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [contactDetails, setContactDetails] = React.useState(null);
    const isValid = React.useMemo(() => {
        return !!(selectedContact ||
            ((contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["First Name"]) &&
                contactDetails["Last Name"] &&
                (contactDetails["Email"] || contactDetails["Phone"])));
    }, [contactDetails, selectedContact]);
    const onContactSelected = React.useCallback(() => {
        if (mode === "select-existing") {
            onAddExistingClientSelected(selectedContact.uid);
        }
        else if (mode === "create-new") {
            onAddNewClientSelected(contactDetails);
        }
        else {
            throw new Error(`Invalid mode ${mode}`);
        }
    }, [contactDetails, mode, onAddExistingClientSelected, onAddNewClientSelected, selectedContact]);
    const onSelectedContactChange = React.useCallback((_, val) => {
        setSelectedContact(val);
    }, []);
    const onModeChange = React.useCallback((e) => {
        const val = e.target.value;
        setMode(val);
        setSelectedContact((prev) => {
            return val === "select-existing" ? prev : null;
        });
        setContactDetails((prev) => {
            return val === "create-new" ? prev : null;
        });
    }, []);
    const setFirstName = React.useCallback((e) => {
        const val = e.target.value;
        setContactDetails((prev) => {
            return Object.assign(Object.assign({}, prev), { "First Name": val });
        });
    }, []);
    const setLastName = React.useCallback((e) => {
        const val = e.target.value;
        setContactDetails((prev) => {
            return Object.assign(Object.assign({}, prev), { "Last Name": val });
        });
    }, []);
    const setEmail = React.useCallback((e) => {
        const val = e.target.value;
        setContactDetails((prev) => {
            return Object.assign(Object.assign({}, prev), { Email: val });
        });
    }, []);
    const setPhone = React.useCallback((e) => {
        const val = e.target.value;
        setContactDetails((prev) => {
            return Object.assign(Object.assign({}, prev), { Phone: val });
        });
    }, []);
    const setDateOfBirth = React.useCallback((date) => {
        setContactDetails((prev) => {
            return Object.assign(Object.assign({}, prev), { "Date of Birth": date !== null && date !== void 0 ? date : undefined });
        });
    }, []);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, "Select a contact"),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                React.createElement(Grid2, null,
                    React.createElement(RadioGroup, { defaultValue: "select-existing", name: "mode", value: mode, onChange: onModeChange },
                        React.createElement(FormControlLabel, { value: "select-existing", control: React.createElement(Radio, null), label: "Select an existing contact" }),
                        React.createElement(FormControlLabel, { value: "create-new", control: React.createElement(Radio, null), label: "Create a new contact" }))),
                mode === "select-existing" && (React.createElement(Grid2, null,
                    React.createElement(ContactAutocomplete, { onChange: onSelectedContactChange, value: selectedContact, query: {
                            filter: NOT_DUPLICATE_CONTACT_FILTER,
                        } }))),
                mode === "create-new" && (React.createElement(Grid2, null,
                    React.createElement(Box, { pt: 1, pb: 8 },
                        React.createElement(Grid2, { container: true, spacing: 2 },
                            React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                                React.createElement(TextField, { label: "First Name", variant: "standard", fullWidth: true, size: "small", value: (_a = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["First Name"]) !== null && _a !== void 0 ? _a : "", onChange: setFirstName, inputProps: {
                                        maxLength: 255,
                                    } })),
                            React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                                React.createElement(TextField, { label: "Last Name", variant: "standard", fullWidth: true, size: "small", value: (_b = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Last Name"]) !== null && _b !== void 0 ? _b : "", onChange: setLastName, inputProps: {
                                        maxLength: 255,
                                    } })),
                            React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                                React.createElement(TextField, { label: "Email", variant: "standard", fullWidth: true, size: "small", value: (_c = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Email"]) !== null && _c !== void 0 ? _c : "", onChange: setEmail, inputProps: {
                                        maxLength: 255,
                                    } })),
                            React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                                React.createElement(TextField, { label: "Phone", variant: "standard", fullWidth: true, size: "small", value: (_d = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Phone"]) !== null && _d !== void 0 ? _d : "", onChange: setPhone, inputProps: {
                                        maxLength: 255,
                                    } })),
                            React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                                React.createElement(CoreDateField, { label: "Date of Birth", variant: "standard", fullWidth: true, size: "small", date: (_e = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Date of Birth"]) !== null && _e !== void 0 ? _e : null, onDateChange: setDateOfBirth, format: "MM/DD/YYYY", formatType: "dayjs", hasPicker: true })))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onContactSelected, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default AddClientDialog;
