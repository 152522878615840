import * as React from "react";
import { CopyAll } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import styled from "styled-components";
import { copyToClipboard } from "@app/util/Utils";
import EmailMenu from "./EmailMenu";
const CopyEmailStyledGrid = styled(Grid) `
  &:not(:hover) button {
    display: none;
  }
`;
const CopyEmailWrapper = (props) => {
    const copyEmailAddress = React.useCallback(() => {
        copyToClipboard(props.emailAddresses.join(", "));
        enqueueSnackbar("Email address copied to clipboard", { variant: "success" });
    }, [props.emailAddresses]);
    return (React.createElement(CopyEmailStyledGrid, { container: true, spacing: 1, wrap: "nowrap" },
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, props.children),
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { size: "small", tabIndex: -1, onClick: copyEmailAddress, style: { height: 0 }, disableRipple: true },
                React.createElement(CopyAll, { fontSize: "small" })))));
};
const EmailLink = (props) => {
    const { TypographyProps, emailAddresses, entityUids, text } = props;
    const [emailMenuAnchorEl, setEmailMenuAnchorEl] = React.useState(null);
    const showEmailMenu = React.useCallback((e) => {
        setEmailMenuAnchorEl(e.currentTarget);
    }, []);
    const closeEmailMenu = React.useCallback(() => {
        setEmailMenuAnchorEl(null);
    }, []);
    return (React.createElement(CopyEmailWrapper, { emailAddresses: emailAddresses },
        React.createElement(Typography, Object.assign({ variant: "body2" }, TypographyProps, { style: { textDecoration: "underline", color: "rgb(6, 77, 121)", cursor: "pointer" }, onClick: showEmailMenu }), text !== null && text !== void 0 ? text : emailAddresses.join(", ")),
        emailMenuAnchorEl && (React.createElement(EmailMenu, { MenuProps: {
                anchorEl: emailMenuAnchorEl,
                open: true,
                onClose: closeEmailMenu,
                onClick: closeEmailMenu,
            }, contacts: [{ name: "", email: emailAddresses.join(", ") }], entityUids: entityUids }))));
};
export default EmailLink;
