import * as React from "react";
import { Close } from "@mui/icons-material";
import { Avatar, Box, Checkbox, Divider, FormControlLabel, Grid, IconButton, MenuItem } from "@mui/material";
import User from "@app/classes/user";
import DashCard from "@app/common/CoreCard";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import { withFilter } from "@app/hoc/withFilter";
import withSession from "@app/hoc/withSession";
import API from "@app/util/api";
import REPORTING_DATE_RANGE_OPTIONS from "@app/util/date_ranges";
const pspTypes = ["Buyer", "Listing", "Pre-Listing", "Land", "New Construction"];
class MAtrixTableSidebar extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                from: null,
                to: null,
                selectedAgent: null,
                pspType: null,
                users: null,
                archived: false,
                dateRange: null,
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                API.users.list({
                    onSuccess: (result) => {
                        this.setState({
                            users: result,
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "setDateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                var _a, _b, _c, _d;
                this.setState({
                    dateRange: choice,
                });
                this.props.updateFilter("from", (_b = (_a = REPORTING_DATE_RANGE_OPTIONS.find((option) => option.name === choice)) === null || _a === void 0 ? void 0 : _a.startDate) !== null && _b !== void 0 ? _b : null, "to", (_d = (_c = REPORTING_DATE_RANGE_OPTIONS.find((option) => option.name === choice)) === null || _c === void 0 ? void 0 : _c.endDate) !== null && _d !== void 0 ? _d : null);
            }
        });
        Object.defineProperty(this, "setFromDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.props.updateFilter("from", date);
            }
        });
        Object.defineProperty(this, "setToDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.props.updateFilter("to", date);
            }
        });
        Object.defineProperty(this, "setAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                this.props.updateFilter("created_by_uid", choice || null);
            }
        });
        Object.defineProperty(this, "setArchived", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.props.updateFilter("archived", checked || null);
            }
        });
        Object.defineProperty(this, "setPSPType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                this.props.updateFilter("psp_type", choice || null);
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        const queryParams = new URLSearchParams(nextProps.location.search);
        return {
            from: queryParams.get("from"),
            to: queryParams.get("to"),
            selectedAgent: queryParams.get("created_by_uid"),
            pspType: queryParams.get("psp_type"),
            archived: queryParams.get("archived") === "true",
        };
    }
    componentDidMount() {
        this.refresh();
    }
    render() {
        var _a, _b, _c;
        const { archived, dateRange, from, pspType, selectedAgent, to, users } = this.state;
        return (React.createElement(DashCard, { heading: "Filters", toolbar: React.createElement(IconButton, { onClick: this.props.onSidebarToggle },
                React.createElement(Close, null)), padding: 0, elevation: 0, PaperProps: {
                style: {
                    overflowX: "hidden",
                },
            } },
            React.createElement(Box, { component: Grid, container: true, spacing: 2, p: 2 },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Date Range", fullWidth: true, value: dateRange !== null && dateRange !== void 0 ? dateRange : "", onChoiceChange: this.setDateRange }, REPORTING_DATE_RANGE_OPTIONS.map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(CoreDateField, { variant: "standard", label: "From", fullWidth: true, date: from !== null && from !== void 0 ? from : null, format: "MM/DD/YYYY", onDateChange: this.setFromDate })),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(CoreDateField, { variant: "standard", label: "To", fullWidth: true, date: to !== null && to !== void 0 ? to : null, format: "MM/DD/YYYY", onDateChange: this.setToDate })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "PSP Type", fullWidth: true, value: pspType !== null && pspType !== void 0 ? pspType : "", onChoiceChange: this.setPSPType },
                            React.createElement(MenuItem, { key: -1, value: "" }, "Any"),
                            pspTypes.map((p) => (React.createElement(MenuItem, { key: p, value: p }, p))))),
                    (((_a = this.props.session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin")) ||
                        ((_b = this.props.session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole("Admin")) ||
                        ((_c = this.props.session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole("Market Analyst"))) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(CoreChoiceField, { variant: "standard", label: "User", fullWidth: true, value: selectedAgent !== null && selectedAgent !== void 0 ? selectedAgent : "", onChoiceChange: this.setAgent },
                                React.createElement(MenuItem, { key: -1, value: "" }, "Any"),
                                (users !== null && users !== void 0 ? users : []).map((obj) => {
                                    var _a;
                                    const user = new User(obj);
                                    return (React.createElement(MenuItem, { key: user.uid, value: user.uid },
                                        React.createElement(Grid, { container: true, spacing: 1 },
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { width: "24px", height: "24px", fontSize: "0.7rem" } }, user.initials)),
                                            React.createElement(Grid, { item: true, style: { flexGrow: 1, marginTop: "auto", marginBottom: "auto" } }, user.name))));
                                }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(FormControlLabel, { label: "Archived", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: archived, onChange: this.setArchived }) }))))))));
    }
}
export default withSession(withFilter(MAtrixTableSidebar));
