import * as React from "react";
import { withRouter } from "@app/hoc/withRouter";
export const withFilter = (Component) => {
    class WithFilter extends React.Component {
        constructor() {
            super(...arguments);
            Object.defineProperty(this, "updateFilter", {
                enumerable: true,
                configurable: true,
                writable: true,
                value: (...args) => {
                    const params = new URLSearchParams(this.props.location.search);
                    for (let i = 0; i < args.length; i += 2) {
                        const param = args[i];
                        const value = args[i + 1];
                        if (value === null || value === undefined || value === "") {
                            params.delete(param);
                        }
                        else {
                            params.set(param, value);
                        }
                    }
                    this.props.navigate(`${this.props.location.pathname}?${params.toString()}`);
                }
            });
        }
        render() {
            return React.createElement(Component, Object.assign({}, this.props, { updateFilter: this.updateFilter }));
        }
    }
    return withRouter(WithFilter);
};
