/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List stages
 */
export const listStages = (params, options) => {
    return axios.get(`/api/stages`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListStagesQueryKey = (params) => {
    return [`/api/stages`, ...(params ? [params] : [])];
};
export const getListStagesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStagesQueryKey(params);
    const queryFn = ({ signal }) => listStages(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List stages
 */
export function useListStages(params, options) {
    const queryOptions = getListStagesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create a stage
 */
export const addStage = (stageCreateRequest, options) => {
    return axios.post(`/api/stages`, stageCreateRequest, options);
};
export const getAddStageMutationOptions = (options) => {
    const mutationKey = ['addStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a stage
*/
export const useAddStage = (options) => {
    const mutationOptions = getAddStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a stage
*/
export const getStage = (uid, options) => {
    return axios.get(`/api/stages/${uid}`, options);
};
export const getGetStageQueryKey = (uid) => {
    return [`/api/stages/${uid}`];
};
export const getGetStageQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetStageQueryKey(uid);
    const queryFn = ({ signal }) => getStage(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a stage
 */
export function useGetStage(uid, options) {
    const queryOptions = getGetStageQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update a stage
 */
export const updateStage = (uid, stageRequest, options) => {
    return axios.post(`/api/stages/${uid}`, stageRequest, options);
};
export const getUpdateStageMutationOptions = (options) => {
    const mutationKey = ['updateStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateStage(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a stage
*/
export const useUpdateStage = (options) => {
    const mutationOptions = getUpdateStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a stages
*/
export const deleteStage = (uid, options) => {
    return axios.delete(`/api/stages/${uid}`, options);
};
export const getDeleteStageMutationOptions = (options) => {
    const mutationKey = ['deleteStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteStage(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a stages
*/
export const useDeleteStage = (options) => {
    const mutationOptions = getDeleteStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Download stages
*/
export const downloadStages = (options) => {
    return axios.get(`/api/stages/download`, options);
};
export const getDownloadStagesQueryKey = () => {
    return [`/api/stages/download`];
};
export const getDownloadStagesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadStagesQueryKey();
    const queryFn = ({ signal }) => downloadStages(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download stages
 */
export function useDownloadStages(options) {
    const queryOptions = getDownloadStagesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Set all stages
 */
export const setAllStages = (stageSetAllRequest, options) => {
    return axios.post(`/api/stages/set_all`, stageSetAllRequest, options);
};
export const getSetAllStagesMutationOptions = (options) => {
    const mutationKey = ['setAllStages'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return setAllStages(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Set all stages
*/
export const useSetAllStages = (options) => {
    const mutationOptions = getSetAllStagesMutationOptions(options);
    return useMutation(mutationOptions);
};
