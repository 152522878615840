import * as React from "react";
import { ArrowRight, ArrowLeft } from "@mui/icons-material";
import { Box, Grid2, Divider, Typography, Tooltip, IconButton, MenuItem } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import dayjs_ from "@app/common/dayjs";
import PSPAccuracyChart from "./PSPAccuracyChart";
import PSPAccuracySidebar from "./PSPAccuracySidebar";
import PSPAccuracyTable from "./PSPAccuracyTable";
import { OUTLIER_TOLERANCE, DEFAULT_EXPECTED_MEAN_PSP_VALUE_ACCURACY, DEFAULT_COMPLETED_BY_EMAILS, VIEWS, MEASURE_ACCURACY_FROM_OPTIONS, getPercentDifference, filterDataByCloseDate, getTableData, DEFAULT_PRICE_RANGE, PRICE_RANGES, getPValue, getPSPValueAccuracies, getPSPValueRangeAccuracies, calculateAgentGrade, getLetterGrade, getTValue, } from "./utils";
const DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_TO_DATE = dayjs_(new Date()).format(DATE_FORMAT);
const DEFAULT_FROM_DATE = dayjs_(new Date(new Date().setDate(new Date().getDate() - 90))).format(DATE_FORMAT);
const PSPAccuracyContent = (props) => {
    const { defaultMarketsFilter, defaultZipCodesFilter, pspAccuracyData } = props;
    /** filter for listings within specified range of close prices */
    const [priceRangeFilter, setPriceRangeFilter] = React.useState(DEFAULT_PRICE_RANGE);
    /** filter for listings closed after this date */
    const [fromDate, setFromDate] = React.useState(DEFAULT_FROM_DATE);
    /** filter for listings closed before this date */
    const [toDate, setToDate] = React.useState(DEFAULT_TO_DATE);
    /** list of zip codes to filter for psps in certain zip codes */
    const [zipCodesFilter, setZipCodesFilter] = React.useState(defaultZipCodesFilter);
    /** list of markets to filter for psps in certain markets */
    const [marketsFilter, setMarketsFilter] = React.useState(defaultMarketsFilter);
    /** list of emails to filter for psps completed by certain individuals */
    const [completedByFilter, setCompletedByFilter] = React.useState(DEFAULT_COMPLETED_BY_EMAILS);
    /** filter psps by address */
    const [addressFilter, setAddressFilter] = React.useState(null);
    const [excludeOutliers, setExcludeOutliers] = React.useState(false);
    const [measureAccuracyFrom, setMeasureAccuracyFrom] = React.useState(MEASURE_ACCURACY_FROM_OPTIONS[0]);
    /** graph to display */
    const [currentView, setCurrentView] = React.useState(VIEWS[0]);
    /** The expected mean percent difference between Close Price and PSP Value Avg */
    const [expectedMeanPSPValueAccuracy, setExpectedMeanPSPValueAccuracy] = React.useState(DEFAULT_EXPECTED_MEAN_PSP_VALUE_ACCURACY);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    /**
     * Determines the start/stop dates for the previous period.
     * Used to compare metrics in table.
     * Ex: If current period is the past 90 days, previous period will be the 90 days before that
     */
    const previousPeriod = React.useMemo(() => {
        if (fromDate) {
            const fromDateAsDate = dayjs_(fromDate);
            const toDateAsDate = dayjs_(toDate);
            const periodLengthDays = Math.floor(toDateAsDate.diff(fromDateAsDate) / (1000 * 60 * 60 * 24));
            const prevPeriodEnd = dayjs_(fromDateAsDate).add(-1, "day");
            const prevPeriodStart = dayjs_(prevPeriodEnd).add(-1 * periodLengthDays, "day");
            return {
                fromDate: dayjs_(prevPeriodStart).format(DATE_FORMAT),
                toDate: dayjs_(prevPeriodEnd).format(DATE_FORMAT),
            };
        }
        return {
            fromDate: null,
            toDate,
        };
    }, [fromDate, toDate]);
    const dataFiltered = React.useMemo(() => {
        const { maxPrice, minPrice } = priceRangeFilter;
        const filteredData = [...pspAccuracyData].filter((psp) => {
            const { address, close_price, created_by_email, market, psp_value_avg, zip_code } = psp;
            let isOutlier = false;
            if (excludeOutliers) {
                const closePriceAccuracy = getPercentDifference(close_price, psp_value_avg);
                isOutlier = closePriceAccuracy == null || Math.abs(closePriceAccuracy) >= OUTLIER_TOLERANCE;
            }
            const withinPriceRange = !!(close_price &&
                (!minPrice || close_price > minPrice) &&
                (!maxPrice || close_price < maxPrice));
            const withinMarkets = market && (marketsFilter.length === 0 || marketsFilter.includes(market));
            const withinZipCodes = zip_code && (zipCodesFilter.length === 0 || zipCodesFilter.includes(zip_code));
            const withinCompletedBy = created_by_email && (completedByFilter.length === 0 || completedByFilter.includes(created_by_email));
            const validAddress = addressFilter == null || (address === null || address === void 0 ? void 0 : address.toLowerCase().trim().includes(addressFilter.toLowerCase().trim()));
            return withinPriceRange && withinZipCodes && withinMarkets && withinCompletedBy && !isOutlier && validAddress;
        });
        return filteredData;
    }, [
        addressFilter,
        completedByFilter,
        excludeOutliers,
        marketsFilter,
        priceRangeFilter,
        zipCodesFilter,
        pspAccuracyData,
    ]);
    const dataFiltered_ = React.useMemo(() => {
        return filterDataByCloseDate(dataFiltered, fromDate, toDate);
    }, [dataFiltered, fromDate, toDate]);
    const dataPrevPeriod = React.useMemo(() => {
        return filterDataByCloseDate(pspAccuracyData, previousPeriod.fromDate, previousPeriod.toDate);
    }, [previousPeriod, pspAccuracyData]);
    const dataByEmail = React.useMemo(() => {
        return getTableData(dataFiltered_);
    }, [dataFiltered_]);
    const dataByEmailPrevPeriod = React.useMemo(() => {
        return getTableData(dataPrevPeriod);
    }, [dataPrevPeriod]);
    const allPSPsAccuracies = React.useMemo(() => {
        return measureAccuracyFrom === "PSP Value Range"
            ? getPSPValueRangeAccuracies(dataFiltered_, true)
            : getPSPValueAccuracies(dataFiltered_, true);
    }, [dataFiltered_, measureAccuracyFrom]);
    const mean = React.useMemo(() => {
        return allPSPsAccuracies.length > 0 ? allPSPsAccuracies.reduce((a, b) => a + b) / allPSPsAccuracies.length : null;
    }, [allPSPsAccuracies]);
    const toggleSidebar = React.useCallback(() => {
        setIsSidebarOpen((prevState) => {
            return !prevState;
        });
    }, [setIsSidebarOpen]);
    const setView = React.useCallback((view) => {
        setCurrentView(view);
    }, [setCurrentView]);
    const onPriceRangeFilterChange = React.useCallback((name) => {
        var _a;
        setPriceRangeFilter((_a = PRICE_RANGES.find((item) => item.name === name)) !== null && _a !== void 0 ? _a : DEFAULT_PRICE_RANGE);
    }, [setPriceRangeFilter]);
    const onFromDateChange = React.useCallback((date) => {
        setFromDate(date === "" ? null : date);
    }, [setFromDate]);
    const onToDateChange = React.useCallback((date) => {
        setToDate(date !== null && date !== void 0 ? date : DEFAULT_TO_DATE);
    }, [setToDate]);
    const onCompletedByFilterChange = React.useCallback((emails) => {
        setCompletedByFilter(emails);
    }, [setCompletedByFilter]);
    const onMarketsFilterChange = React.useCallback((markets) => {
        setMarketsFilter(markets);
    }, [setMarketsFilter]);
    const onAddressFilterChange = React.useCallback((address) => {
        setAddressFilter(address === "" ? null : address);
    }, [setAddressFilter]);
    const onZipCodesFilterChange = React.useCallback((zipCodes) => {
        setZipCodesFilter(zipCodes);
    }, [setZipCodesFilter]);
    const onMeasureAccuracyFromChange = React.useCallback((value) => {
        setMeasureAccuracyFrom(value);
    }, [setMeasureAccuracyFrom]);
    const toggleExcludeOutliers = React.useCallback((isChecked) => {
        setExcludeOutliers(isChecked);
    }, [setExcludeOutliers]);
    const onExpectedMeanPSPValueAccuracyChange = React.useCallback((expectedMean) => {
        setExpectedMeanPSPValueAccuracy(expectedMean !== null && expectedMean !== void 0 ? expectedMean : DEFAULT_EXPECTED_MEAN_PSP_VALUE_ACCURACY);
    }, [setExpectedMeanPSPValueAccuracy]);
    console.log(`Got filtered data: ${dataFiltered_.length} out of ${pspAccuracyData.length}`);
    Object.keys(dataByEmail).forEach((email) => {
        if (Object.keys(dataByEmailPrevPeriod).includes(email)) {
            Object.keys(dataByEmail[email]).forEach((key) => {
                if (["accuracy", "avgRange", "belowRangeRate", "aboveRangeRate"].includes(key)) {
                    dataByEmail[email][`${key}Delta`] = getPercentDifference(dataByEmail[email][key], dataByEmailPrevPeriod[email][key]);
                }
                else if (key === "psps") {
                    dataByEmail[email]["numPSPsDelta"] = getPercentDifference(dataByEmail[email].psps.length, dataByEmailPrevPeriod[email].psps.length);
                }
            });
        }
        // perform one-sample t-test
        const agentPSPs = dataByEmail[email].psps;
        const agentPSPValueAccuracies = getPSPValueAccuracies(agentPSPs, true);
        const agentAvgPSPValueAccuracy = agentPSPValueAccuracies.length > 0
            ? agentPSPValueAccuracies.reduce((a, b) => a + b) / agentPSPValueAccuracies.length
            : null;
        dataByEmail[email].avgPSPValueAccuracy = agentAvgPSPValueAccuracy;
        const pspValueAccuracyTTestVal = getTValue(agentPSPValueAccuracies, expectedMeanPSPValueAccuracy);
        // use t-test to get p-value
        const pspValueAccuracyPValue = agentPSPValueAccuracies.length < 3 || pspValueAccuracyTTestVal == null
            ? null
            : getPValue(pspValueAccuracyTTestVal, agentPSPValueAccuracies.length - 1, 2);
        dataByEmail[email].pspValueAccuracyPValue = pspValueAccuracyPValue;
        const agentPSPValueRangeAccuracies = getPSPValueRangeAccuracies(agentPSPs, true);
        const agentAvgPSPValueRangeAccuracies = agentPSPValueRangeAccuracies.length > 0
            ? agentPSPValueRangeAccuracies.reduce((a, b) => a + b) / agentPSPValueRangeAccuracies.length
            : null;
        dataByEmail[email].avgPSPValueRangeAccuracy = agentAvgPSPValueRangeAccuracies;
        const pspValueRangeAccuracyTTestVal = getTValue(agentPSPValueRangeAccuracies, expectedMeanPSPValueAccuracy);
        // use t-test to get p-value
        const pspValueRangeAccuracyPValue = agentPSPValueRangeAccuracies.length < 3 || pspValueRangeAccuracyTTestVal == null
            ? null
            : getPValue(pspValueRangeAccuracyTTestVal, agentPSPValueRangeAccuracies.length - 1, 2);
        dataByEmail[email].pspValueRangeAccuracyPValue = pspValueRangeAccuracyPValue;
        const agentTValue = measureAccuracyFrom === "PSP Value Average" ? pspValueAccuracyTTestVal : pspValueRangeAccuracyTTestVal;
        dataByEmail[email].tValue = agentTValue;
        const agentPValue = measureAccuracyFrom === "PSP Value Average" ? pspValueAccuracyPValue : pspValueRangeAccuracyPValue;
        dataByEmail[email].pValue = agentPValue;
        // get agent grade
        const agentGrade = agentPValue ? calculateAgentGrade(agentPValue) : 85;
        const letterGrade = getLetterGrade(agentGrade);
        dataByEmail[email].grade = agentGrade;
        dataByEmail[email].letterGrade = letterGrade;
    });
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(Grid2, { container: true },
            React.createElement(Grid2, { style: { flexGrow: 1, paddingLeft: "8px" } },
                React.createElement(Typography, { variant: "h6" }, "PSP Accuracy")),
            React.createElement(Grid2, null,
                React.createElement(Box, { display: "flex", ml: "auto", maxWidth: "800px" },
                    React.createElement(Typography, { style: { paddingRight: "8px", lineHeight: "30px" } }, "Graph:"),
                    React.createElement(CoreChoiceField, { variant: "standard", fullWidth: true, value: currentView, onChoiceChange: setView }, VIEWS.map((view) => (React.createElement(MenuItem, { key: view, value: view }, view)))),
                    React.createElement(Tooltip, { title: `${isSidebarOpen ? "Hide" : "Show"} Sidebar` },
                        React.createElement(IconButton, { size: "small", onClick: toggleSidebar }, isSidebarOpen ? React.createElement(ArrowRight, null) : React.createElement(ArrowLeft, null))))),
            React.createElement(Grid2, { container: true, size: 12 },
                React.createElement(Grid2, { size: 12, style: { padding: "4px" } },
                    React.createElement(Divider, null))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(PSPAccuracyChart, { psps: dataFiltered_, dataByEmail: dataByEmail, view: currentView, measureAccuracyFrom: measureAccuracyFrom })),
            React.createElement(Grid2, { size: 12 },
                React.createElement(PSPAccuracyTable, { data: dataByEmail }))), sidebar: React.createElement(PSPAccuracySidebar, { currentMean: mean, pspAccuracyData: pspAccuracyData, priceRangeFilter: priceRangeFilter, completedByFilter: completedByFilter, marketsFilter: marketsFilter, addressFilter: addressFilter, defaultMarketsFilter: defaultMarketsFilter, fromDate: fromDate, toDate: toDate, zipCodesFilter: zipCodesFilter, defaultZipCodesFilter: defaultZipCodesFilter, measureAccuracyFrom: measureAccuracyFrom, excludeOutliers: excludeOutliers, expectedMeanPSPValueAccuracy: expectedMeanPSPValueAccuracy, setPriceRange: onPriceRangeFilterChange, setFromDate: onFromDateChange, setToDate: onToDateChange, setCompletedByFilter: onCompletedByFilterChange, setMarketsFilter: onMarketsFilterChange, setZipCodesFilter: onZipCodesFilterChange, setAddressFilter: onAddressFilterChange, setMeasureAccuracyFrom: onMeasureAccuracyFromChange, setExcludeOutliers: toggleExcludeOutliers, setExpectedMeanPSPValueAccuracy: onExpectedMeanPSPValueAccuracyChange, onSidebarToggle: toggleSidebar }), sidebarWidth: 250, opened: !!isSidebarOpen }));
};
export default PSPAccuracyContent;
