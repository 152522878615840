/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all rules
 */
export const listCompsfinderRulesets = (options) => {
    return axios.get(`/api/compsfinder/rule_sets`, options);
};
export const getListCompsfinderRulesetsQueryKey = () => {
    return [`/api/compsfinder/rule_sets`];
};
export const getListCompsfinderRulesetsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCompsfinderRulesetsQueryKey();
    const queryFn = ({ signal }) => listCompsfinderRulesets(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all rules
 */
export function useListCompsfinderRulesets(options) {
    const queryOptions = getListCompsfinderRulesetsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a rule
 */
export const createCompsfinderRuleset = (ruleSetRequest, options) => {
    return axios.post(`/api/compsfinder/rule_sets`, ruleSetRequest, options);
};
export const getCreateCompsfinderRulesetMutationOptions = (options) => {
    const mutationKey = ['createCompsfinderRuleset'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createCompsfinderRuleset(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a rule
*/
export const useCreateCompsfinderRuleset = (options) => {
    const mutationOptions = getCreateCompsfinderRulesetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a rule
*/
export const getCompsfinderRuleset = (uid, options) => {
    return axios.get(`/api/compsfinder/rule_sets/${uid}`, options);
};
export const getGetCompsfinderRulesetQueryKey = (uid) => {
    return [`/api/compsfinder/rule_sets/${uid}`];
};
export const getGetCompsfinderRulesetQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetCompsfinderRulesetQueryKey(uid);
    const queryFn = ({ signal }) => getCompsfinderRuleset(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a rule
 */
export function useGetCompsfinderRuleset(uid, options) {
    const queryOptions = getGetCompsfinderRulesetQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a rule
 */
export const updateCompsfinderRuleset = (uid, ruleSetRequest, options) => {
    return axios.post(`/api/compsfinder/rule_sets/${uid}`, ruleSetRequest, options);
};
export const getUpdateCompsfinderRulesetMutationOptions = (options) => {
    const mutationKey = ['updateCompsfinderRuleset'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateCompsfinderRuleset(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a rule
*/
export const useUpdateCompsfinderRuleset = (options) => {
    const mutationOptions = getUpdateCompsfinderRulesetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete a rule set
*/
export const deleteCompsfinderRuleset = (uid, options) => {
    return axios.delete(`/api/compsfinder/rule_sets/${uid}`, options);
};
export const getDeleteCompsfinderRulesetMutationOptions = (options) => {
    const mutationKey = ['deleteCompsfinderRuleset'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteCompsfinderRuleset(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a rule set
*/
export const useDeleteCompsfinderRuleset = (options) => {
    const mutationOptions = getDeleteCompsfinderRulesetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Re-order templates
*/
export const reorderCompsfinderRulesets = (ruleSetReorderRequest, options) => {
    return axios.post(`/api/compsfinder/rule_sets/reorder`, ruleSetReorderRequest, options);
};
export const getReorderCompsfinderRulesetsMutationOptions = (options) => {
    const mutationKey = ['reorderCompsfinderRulesets'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderCompsfinderRulesets(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Re-order templates
*/
export const useReorderCompsfinderRulesets = (options) => {
    const mutationOptions = getReorderCompsfinderRulesetsMutationOptions(options);
    return useMutation(mutationOptions);
};
