import * as React from "react";
import { Edit } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import TeamSelectDialog from "./TeamSelectDialog";
const TeamSelect = (props) => {
    const { canEdit, onChange, team } = props;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const openDialog = React.useCallback(() => {
        setIsDialogOpen(true);
    }, [setIsDialogOpen]);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);
    const onConfirm = React.useCallback((params) => {
        closeDialog();
        onChange(params);
    }, [onChange, closeDialog]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap", style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, "Team")),
            React.createElement(Grid, { item: true, container: true, direction: "column", style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, team ? (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body1" }, team.name)),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, team.role)))) : (React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1" }, "This user isn't assigned to a team.")))),
            canEdit && (React.createElement(Grid, { item: true },
                React.createElement(IconButton, { onClick: openDialog },
                    React.createElement(Edit, null))))),
        isDialogOpen && (React.createElement(TeamSelectDialog, { onClose: closeDialog, DialogProps: { open: true }, onConfirm: onConfirm, initialValue: team }))));
};
export default TeamSelect;
