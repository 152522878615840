// eslint-disable-next-line import/prefer-default-export
export const getModalSidebarDialogProps = (width = 420) => ({
    slotProps: {
        paper: {
            style: {
                position: "fixed",
                top: 0,
                right: 0,
                margin: 0,
                height: "100%",
                maxHeight: "unset",
                width,
                borderRadius: 0,
            },
        },
    },
});
