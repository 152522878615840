import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Close, Reorder, Save } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography, } from "@mui/material";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import { enqueueSnackbar } from "notistack";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreError from "@app/common/CoreError";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { listMailschedule3Templates, reorderMailschedule3Templates } from "@app/orval/api/mail-schedule-templates";
import { useListStreakPipelines } from "@app/orval/api/streak-pipelines";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
const MailScheduleTemplateReorder = (props) => {
    var _a, _b;
    const [isSaving, setIsSaving] = useStateCallback(false);
    const [templates, setTemplates] = useStateCallback(null);
    const streakPipelinesListApi = useListStreakPipelines(DEFAULT_CACHE_PARAMS);
    // const mailscheduleTemplatesListApi = useMailscheduletemplatesList({ pipeline: props.pipeline });
    const pipelines = (_b = (_a = streakPipelinesListApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const showErrorDialog = useErrorDialog();
    const updateFilter = useUpdateSearch();
    React.useEffect(() => {
        if (props.pipeline) {
            listMailschedule3Templates({ pipeline: props.pipeline })
                .then((result) => {
                setTemplates(result.data);
            })
                .catch((error) => {
                showErrorDialog(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    const save = React.useCallback(() => {
        setIsSaving(true, () => {
            const templateOrder = templates.map((obj) => obj.uid);
            reorderMailschedule3Templates({ templates: templateOrder })
                .then(() => {
                enqueueSnackbar("Templates have been reordered", { variant: "success" });
                setIsSaving(false);
            })
                .catch((error) => {
                showErrorDialog(error);
                setIsSaving(false);
            });
        });
    }, [templates, showErrorDialog, setIsSaving]);
    const onPipelineChange = React.useCallback((choice) => {
        setTemplates(null, () => {
            updateFilter("pipeline", choice);
        });
    }, [updateFilter, setTemplates]);
    const onDrop = React.useCallback((result) => {
        if (result.destination && result.source.index !== result.destination.index) {
            setTemplates((prevState) => {
                const newTemplates = prevState;
                const template = newTemplates[result.source.index];
                newTemplates.splice(result.source.index, 1);
                newTemplates.splice(result.destination.index, 0, template);
                return newTemplates;
            });
        }
    }, [setTemplates]);
    const { pipeline } = props;
    const loading = [
        {
            label: "Loading Streak pipelines...",
            status: streakPipelinesListApi.status,
        },
    ];
    const { error } = streakPipelinesListApi;
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (loading.find((x) => x.status === "pending") || !pipelines || !templates) {
        return React.createElement(Loading, { items: loading });
    }
    return (React.createElement(Grid, { container: true, spacing: 1, style: { height: "100%", width: "100%" } },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Pipeline", value: pipeline !== null && pipeline !== void 0 ? pipeline : "", onChoiceChange: onPipelineChange, style: { width: "200px" } },
                            React.createElement(MenuItem, { key: -1, value: "" }),
                            pipelines.map((obj) => (React.createElement(MenuItem, { key: obj.key, value: obj.key }, obj.name))))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true, style: {
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(Tooltip, { title: "Save" },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: save, disabled: isSaving },
                                    React.createElement(Save, null)))),
                        React.createElement(Tooltip, { title: "Close" },
                            React.createElement(IconButton, { component: RouterLink, to: `/mailschedule/templates${pipeline ? `?pipeline=${pipeline}` : ""}` },
                                React.createElement(Close, null))))))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, xs: 12, style: { height: "calc(100% - 70px)", overflowY: "hidden", position: "relative" } },
            React.createElement(Box, { p: 2, style: { height: "100%" } },
                React.createElement(Typography, { variant: "body2" },
                    "Drag & drop the templates below to update the order in which they should appear. Press the",
                    " ",
                    React.createElement(Save, { fontSize: "small" }),
                    " button above when done. Re-ordering the templates has no effect on when they are sent; it is only a visual aid. Templates are only processed based on their triggers and/or criteria."),
                React.createElement(DragDropContext, { onDragEnd: onDrop },
                    React.createElement(Droppable, { droppableId: "reorder-list" }, (droppableProvided) => (React.createElement(React.Fragment, null,
                        React.createElement(List, Object.assign({}, droppableProvided.droppableProps, { ref: droppableProvided.innerRef, style: {
                                maxHeight: "calc(100% - 30px)",
                                overflowY: "auto",
                            }, dense: true }), templates.map((obj, indx) => (React.createElement(Draggable, { key: obj.uid, draggableId: String(obj.uid), index: indx }, (draggableProvided) => (React.createElement(ListItem, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps, { key: obj.uid }),
                            React.createElement(ListItemIcon, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps, { style: {
                                    cursor: "grab",
                                } }),
                                React.createElement(Reorder, { fontSize: "small" })),
                            React.createElement(ListItemText, { primary: indx + 1, secondary: obj.name }))))))),
                        droppableProvided.placeholder))))))));
};
export default MailScheduleTemplateReorder;
