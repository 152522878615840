import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Add, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, Divider, Grid2, Hidden, IconButton, Typography } from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import groupBy from "lodash/groupBy";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router";
import CoreError from "@app/common/CoreError";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import useSize from "@app/hooks/useSize";
import { useListEntityTypes } from "@app/orval/api/entity-types";
import { useListFields, useReorderFields, useSetFieldSection } from "@app/orval/api/field-definitions";
import { useListFieldSections, useReorderFieldSections } from "@app/orval/api/field-sections";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import FieldManagementAutocomplete from "./FieldManagementAutocomplete";
import Section from "./Section";
import SectionField from "./SectionField";
import SectionsForm from "./SectionsForm";
import FieldForm from "../fields/FieldForm";
const Sections = () => {
    var _a, _b, _c, _d, _e;
    const [expandedEntityTypes, setExpandedEntityTypes] = React.useState(new Set());
    const [expandedSections, setExpandedSections] = React.useState(new Set());
    const [dragOverId, setDragOverId] = React.useState(null);
    const searchParams = useSearchParams();
    const navigate = useNavigate();
    const size = useSize();
    const queryClient = useQueryClient();
    const selectedSectionUid = searchParams[0].get("section");
    const selectedFieldUid = searchParams[0].get("field");
    const errorDialog = useErrorDialog();
    const listEntityTypesApi = useListEntityTypes(INFINITE_CACHE_PARAMS);
    const entityTypes = React.useMemo(() => {
        var _a;
        return ((_a = listEntityTypesApi.data) === null || _a === void 0 ? void 0 : _a.data)
            ? [...listEntityTypesApi.data.data].sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
            : null;
    }, [(_a = listEntityTypesApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const listSectionsApi = useListFieldSections({}, REFETCH_CACHE_PARAMS);
    const sections = (_c = (_b = listSectionsApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const sectionsByEntity = React.useMemo(() => {
        return sections ? groupBy(sections, "entity_type") : null;
    }, [sections]);
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = (_d = listFieldsApi.data) === null || _d === void 0 ? void 0 : _d.data;
    // Fields are sorted by entity + alphabetically for the Autocomplete
    // component. The spread operator is used because we don't want to mess
    // up sorting in the drag+drop component.
    const fieldsSortedByEntityTypeAlphabetically = React.useMemo(() => {
        return fields
            ? [...fields].sort((a, b) => {
                if (a.entity_type < b.entity_type)
                    return -1;
                if (a.entity_type > b.entity_type)
                    return 1;
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
            : null;
    }, [fields]);
    // organize fields by entity and section
    // so we can access them more easily without iterating
    // through the entire array for fields... probably
    // helps improve responsiveness
    const fieldsByEntityAndSection = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        if (fields) {
            const res = {};
            for (const field of fields) {
                if (!res[field.entity_type]) {
                    res[field.entity_type] = {};
                }
                if (!res[field.entity_type][(_b = (_a = field.section) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : ""]) {
                    res[field.entity_type][(_d = (_c = field.section) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : ""] = [];
                }
                res[field.entity_type][(_f = (_e = field.section) === null || _e === void 0 ? void 0 : _e.uid) !== null && _f !== void 0 ? _f : ""].push(field);
            }
            console.log({ fields: res["Recruit"] });
            return res;
        }
        return null;
    }, [fields]);
    const fieldsByUid = React.useMemo(() => {
        if (fields) {
            const res = {};
            for (const field of fields) {
                res[field.uid] = field;
            }
            return res;
        }
        return null;
    }, [fields]);
    const reorderSectionsApi = useReorderFieldSections();
    const reorderFieldsApi = useReorderFields();
    const setSectionApi = useSetFieldSection();
    const loading = useLoading({
        items: [
            {
                label: "Loading entity types...",
                queryResult: listEntityTypesApi,
            },
            {
                label: "Loading sections...",
                queryResult: listSectionsApi,
            },
            {
                label: "Loading fields...",
                queryResult: listFieldsApi,
            },
        ],
    });
    /**
     * Collapse or uncollapse an Entity
     */
    const collapseEntityType = React.useCallback((entityType) => () => {
        setExpandedEntityTypes((prev) => {
            const updated = new Set(prev);
            if (updated.has(entityType)) {
                updated.delete(entityType);
            }
            else {
                updated.add(entityType);
            }
            return updated;
        });
    }, [setExpandedEntityTypes]);
    /**
     * Collapse or uncollapse a section.
     */
    const collapseSection = React.useCallback((sectionUid) => {
        setExpandedSections((prev) => {
            const updated = new Set(prev);
            if (updated.has(sectionUid)) {
                updated.delete(sectionUid);
            }
            else {
                updated.add(sectionUid);
            }
            return updated;
        });
    }, [setExpandedSections]);
    /**
     * Select a section. Also expands the section
     * if it isn't currently expanded.
     */
    const selectSection = React.useCallback((sectionUid) => {
        if (sectionUid) {
            navigate(`/field-management?section=${sectionUid}`);
        }
        else {
            navigate("/field-management");
        }
        setExpandedSections((prev) => {
            const updated = new Set(prev);
            updated.add(sectionUid);
            return updated;
        });
    }, [navigate]);
    const onDragStart = React.useCallback((event) => {
        console.log("DRAG START", event);
        const sectionUid = event.active.id;
        if (sectionUid) {
            selectSection(sectionUid);
        }
    }, [selectSection]);
    /**
     * Reorder sections. Note that sections can only be reordered
     * within a single entity (sections can't be moved to another entity)
     */
    const reorderSections = React.useCallback((entityType) => (result) => {
        var _a;
        const activeId = result.active.id;
        const overId = (_a = result.over) === null || _a === void 0 ? void 0 : _a.id;
        setDragOverId(null);
        if (activeId && overId && activeId !== overId) {
            const sectionsForEntity = sectionsByEntity[entityType];
            const oldIndex = sectionsForEntity.findIndex((x) => x.uid === activeId);
            const newIndex = sectionsForEntity.findIndex((x) => x.uid === overId);
            const section = sectionsForEntity[oldIndex];
            sectionsForEntity.splice(oldIndex, 1);
            sectionsForEntity.splice(newIndex, 0, section);
            reorderSectionsApi
                .mutateAsync({
                data: {
                    entity_type: entityType,
                    section_uids: sectionsForEntity.map((x) => x.uid),
                },
            })
                .then(() => {
                console.log("reordered sections");
            })
                .catch(errorDialog);
        }
    }, [errorDialog, reorderSectionsApi, sectionsByEntity]);
    const onDragOver = React.useCallback((event) => {
        var _a;
        const overId = (_a = event.over) === null || _a === void 0 ? void 0 : _a.id;
        console.log("DRAGGING OVER:", event.over);
        setDragOverId(overId ? String(overId) : null);
    }, []);
    /**
     * Reorder fields. Note that fields can be moved between
     * sections, but can't be moved to a separate Entity.
     */
    const reorderFields = React.useCallback((reorderParams) => {
        const { fieldUid, position, targetFieldUid, targetSectionUid } = reorderParams;
        console.log("REORDER FIELDS", reorderParams);
        const field = Object.assign(Object.assign({}, fieldsByUid[fieldUid]), { section: targetSectionUid
                ? {
                    uid: targetSectionUid,
                    name: sections.find((s) => s.uid === targetSectionUid).name,
                }
                : null });
        const fieldsForEntity = [...fields].filter((f) => f.entity_type === field.entity_type);
        const targetIndx = fieldsForEntity.findIndex((f) => f.uid === targetFieldUid);
        const dragIndx = fieldsForEntity.findIndex((f) => f.uid === fieldUid);
        console.log("TEST:", targetIndx, dragIndx);
        if (targetIndx >= 0 && dragIndx >= 0) {
            if (position === "below") {
                fieldsForEntity.splice(targetIndx + 1, 0, field);
            }
            else {
                fieldsForEntity.splice(targetIndx, 0, field);
            }
            fieldsForEntity.splice(dragIndx < targetIndx ? dragIndx : dragIndx + 1, 1);
        }
        else {
            fieldsForEntity.splice(dragIndx, 1);
            if (targetIndx >= 0) {
                fieldsForEntity.splice(targetIndx, 0, field);
            }
            else {
                fieldsForEntity.push(field);
            }
        }
        setSectionApi
            .mutateAsync({ uid: fieldUid, data: { section_uid: targetSectionUid || null } })
            .then(() => {
            if (targetFieldUid) {
                reorderFieldsApi
                    .mutateAsync({
                    data: {
                        field_order: fieldsForEntity.map((f) => f.uid),
                    },
                })
                    .then(() => {
                    console.log("reordered fields");
                })
                    .catch(errorDialog);
            }
            console.log("updated section");
        })
            .catch(errorDialog);
        queryClient.setQueryData(listFieldsApi.queryKey, (old) => {
            if (!old)
                return old;
            const newData = [...old.data];
            const entityStartIndx = newData.findIndex((x) => x.entity_type === field.entity_type);
            newData.splice(entityStartIndx, fieldsForEntity.length, ...fieldsForEntity);
            return Object.assign(Object.assign({}, old), { data: [...newData] });
        });
    }, [fieldsByUid, sections, fields, setSectionApi, errorDialog, queryClient, listFieldsApi.queryKey, reorderFieldsApi]);
    /**
     * Move a field to a nother section.
     */
    const moveFieldToSection = React.useCallback((moveFieldParams) => {
        console.log("MOVE FIELD TO SECTION", moveFieldParams);
        const { fieldUid, sectionUid } = moveFieldParams;
        reorderFields({ fieldUid, targetSectionUid: sectionUid });
    }, [reorderFields]);
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    if (!sectionsByEntity || !entityTypes || !fields) {
        return React.createElement(CoreError, { error: "An error occurred loading the component" });
    }
    const useLargeSize = size.isUp("md");
    const isFormOpen = !!selectedSectionUid || !!selectedFieldUid;
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 2, wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h5" }, "Field Management")),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(FieldManagementAutocomplete, { fields: fieldsSortedByEntityTypeAlphabetically !== null && fieldsSortedByEntityTypeAlphabetically !== void 0 ? fieldsSortedByEntityTypeAlphabetically : [], value: selectedFieldUid ? ((_e = fields.find((x) => x.uid === selectedFieldUid)) !== null && _e !== void 0 ? _e : null) : null, TextFieldProps: {
                                style: {
                                    minWidth: "300px",
                                },
                                size: "small",
                                autoFocus: false,
                            }, AutocompleteProps: {
                                clearIcon: null,
                                autoFocus: false,
                            } })),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { component: RouterLink, to: "/field-management?section=new", size: "small", variant: "outlined", startIcon: React.createElement(Add, null), color: "primary", disabled: selectedSectionUid === "new" }, "Add section")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { component: RouterLink, to: "/field-management?field=new", size: "small", variant: "outlined", startIcon: React.createElement(Add, null), color: "primary", disabled: selectedFieldUid === "new" }, "Add field"))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, { style: { overflow: "hidden" } },
            React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap", style: { height: "100%" } },
                (useLargeSize || !isFormOpen) && (React.createElement(Grid2, { style: { width: isFormOpen ? "300px" : "100%", overflowY: "scroll" } }, entityTypes.map((entityType) => {
                    var _a, _b;
                    return (React.createElement(DndProvider, { key: entityType.name, backend: HTML5Backend },
                        React.createElement(Grid2, { key: entityType.name, container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                            React.createElement(Grid2, null,
                                React.createElement(IconButton, { size: "small", onClick: collapseEntityType(entityType.name) }, expandedEntityTypes.has(entityType.name) ? (React.createElement(KeyboardArrowDown, { fontSize: "small" })) : (React.createElement(KeyboardArrowRight, { fontSize: "small" })))),
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { style: { cursor: (fieldsByEntityAndSection === null || fieldsByEntityAndSection === void 0 ? void 0 : fieldsByEntityAndSection[entityType.name]) ? "pointer" : undefined }, onClick: collapseEntityType(entityType.name) }, entityType.name))),
                        expandedEntityTypes.has(entityType.name) && (React.createElement(DndContext, { onDragStart: onDragStart, onDragEnd: reorderSections(entityType.name), onDragOver: onDragOver, collisionDetection: closestCenter },
                            React.createElement(SortableContext, { items: [
                                    ...((_a = sectionsByEntity[entityType.name]) !== null && _a !== void 0 ? _a : []),
                                    { uid: "", name: "no section", entity_type: entityType.name },
                                ].map((section) => {
                                    return { id: section.uid };
                                }), strategy: verticalListSortingStrategy },
                                React.createElement(Grid2, { container: true }, [
                                    ...((_b = sectionsByEntity[entityType.name]) !== null && _b !== void 0 ? _b : []),
                                    { uid: "", name: "no section", entity_type: entityType.name },
                                ].map((section) => {
                                    var _a, _b, _c, _d;
                                    return (React.createElement(React.Fragment, null,
                                        React.createElement(Grid2, { key: section.uid, container: true, direction: "column", wrap: "nowrap", style: { width: "100%" } },
                                            React.createElement(Section, { section: section, dragOverId: dragOverId, onCollapse: collapseSection, 
                                                // onSelect={selectSection}
                                                onFieldMovedToSection: moveFieldToSection, isCollapsible: !!((_a = fieldsByEntityAndSection === null || fieldsByEntityAndSection === void 0 ? void 0 : fieldsByEntityAndSection[section.entity_type]) === null || _a === void 0 ? void 0 : _a[section.uid]), isCollapsed: expandedSections.has(section.uid), isClickable: !!(section.uid || ((_b = fieldsByEntityAndSection === null || fieldsByEntityAndSection === void 0 ? void 0 : fieldsByEntityAndSection[section.entity_type]) === null || _b === void 0 ? void 0 : _b[section.uid])) })),
                                        expandedSections.has(section.uid) && (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" }, ((_d = (_c = fieldsByEntityAndSection === null || fieldsByEntityAndSection === void 0 ? void 0 : fieldsByEntityAndSection[section.entity_type]) === null || _c === void 0 ? void 0 : _c[section.uid]) !== null && _d !== void 0 ? _d : []).map((field) => (React.createElement(Grid2, { key: field.uid },
                                            React.createElement(SectionField, { field: field, entityType: section.entity_type, sectionUid: section.uid, onReorder: reorderFields }))))))));
                                })))))));
                }))),
                isFormOpen && (React.createElement(React.Fragment, null,
                    useLargeSize && (React.createElement(Grid2, { style: { height: "100%" } },
                        React.createElement(Divider, { orientation: "vertical" }))),
                    React.createElement(Grid2, { style: {
                            flex: useLargeSize ? 1 : undefined,
                            width: useLargeSize ? undefined : "100%",
                            height: "100%",
                            overflowY: "scroll",
                        } },
                        selectedSectionUid && !selectedFieldUid && (React.createElement(SectionsForm, { uid: selectedSectionUid === "new" ? null : selectedSectionUid })),
                        selectedFieldUid && (React.createElement(FieldForm, { uid: selectedFieldUid === "new" ? undefined : selectedFieldUid, backLink: "/field-management" })))))))));
};
export default Sections;
