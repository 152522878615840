import * as React from "react";
import { useParams } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetBuyerContract } from "@app/orval/api/buyer-contracts";
import MailScheduleBoxesTable from "../../features/mailschedule4/boxes/MailScheduleBoxesTable";
const BuyerContractsMailSchedulePage = () => {
    var _a, _b;
    const params = useParams();
    const { uid } = params;
    useDocumentTitle("Buyer Contracts | Mail Schedule");
    const getBuyerContractApi = useGetBuyerContract(uid);
    const buyerContract = (_b = (_a = getBuyerContractApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    if (!buyerContract) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(MailScheduleBoxesTable, { entity: {
            uid: uid,
            entityType: "Buyer Contract",
        }, backLink: `/buyers/${buyerContract.buyer_lead_uid}` }));
};
export default BuyerContractsMailSchedulePage;
