import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import FilterContextProvider from "./FilterContext";
import FilterGroup from "./FilterGroup";
const FilterGroups = (props) => {
    const { entityType, filterGroups, onUpdate } = props;
    const mainGroupUid = props.filterGroups.find((fg) => !fg.parent_uid).uid;
    console.log({ filterGroups });
    return (React.createElement(FilterContextProvider, { filterGroups: filterGroups.map((fg) => {
            return Object.assign(Object.assign({}, fg), { filters: fg.filters.map((f) => {
                    var _a;
                    return Object.assign(Object.assign({}, f), { uid: (_a = f.uid) !== null && _a !== void 0 ? _a : uuidv4().replace(/-/g, "") });
                }) });
        }), entityType: entityType, onUpdate: onUpdate },
        React.createElement(FilterGroup, { uid: mainGroupUid })));
};
export default FilterGroups;
