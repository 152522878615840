import * as React from "react";
import { House } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
import PipelineAndStageText from "./PipelineAndStageText";
const BuyerContractSearchResult = (props) => {
    var _a, _b;
    const { buyerContract } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemIcon, null,
            React.createElement(House, { fontSize: "large" })),
        React.createElement(ListItemText, { primary: buyerContract.name, secondary: React.createElement(PipelineAndStageText, { pipeline: "Buyer Contract", stageName: (_a = buyerContract.stage) === null || _a === void 0 ? void 0 : _a.name, stageUid: (_b = buyerContract.stage) === null || _b === void 0 ? void 0 : _b.uid }) })));
};
export default BuyerContractSearchResult;
