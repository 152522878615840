import * as React from "react";
import { Edit, PushPin, Visibility } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
const SavedViewShareSelector = (props) => {
    const { TooltipProps, disableEditable, disablePinned, disableShared, onChange, value } = props;
    const arrayValue = React.useMemo(() => {
        return Object.keys(value).filter((key) => value[key] ||
            (key === "is_shared" && disableShared) ||
            (key === "is_editable" && disableEditable) ||
            (key === "is_pinned" && disablePinned));
    }, [disableEditable, disablePinned, disableShared, value]);
    const handleChange = React.useCallback((_, newValue) => {
        const newSettings = {
            is_shared: newValue.includes("is_shared") || newValue.includes("is_pinned") || !!disableShared,
            is_editable: newValue.includes("is_editable") || !!disableEditable,
            is_pinned: newValue.includes("is_pinned") || !!disablePinned,
        };
        onChange(newSettings);
    }, [disableEditable, disablePinned, disableShared, onChange]);
    return (React.createElement(ToggleButtonGroup, { size: "small", value: arrayValue, onChange: handleChange, exclusive: false },
        React.createElement(Tooltip, Object.assign({ title: React.createElement(Typography, { dangerouslySetInnerHTML: {
                    __html: "<b>Visible</b> - users will be able to see boxes that they have access to within this view",
                } }) }, TooltipProps),
            React.createElement("span", null,
                React.createElement(ToggleButton, { value: "is_shared", disabled: !!disableShared, style: { background: disableShared ? "#eee" : undefined } },
                    React.createElement(Visibility, { fontSize: "small" })))),
        React.createElement(Tooltip, Object.assign({ title: React.createElement(Typography, { dangerouslySetInnerHTML: {
                    __html: "<b>Editable</b> - users will be able to manage the view's filters, column arrangement, and sharing settings",
                } }) }, TooltipProps),
            React.createElement("span", null,
                React.createElement(ToggleButton, { value: "is_editable", disabled: !!disableEditable, style: { background: disableEditable ? "#eee" : undefined } },
                    React.createElement(Edit, { fontSize: "small" })))),
        React.createElement(Tooltip, Object.assign({ title: React.createElement(Typography, { dangerouslySetInnerHTML: {
                    __html: "<b>Pinned</b> - this view will appear in the user's inbox as well as on the Agent Dashboard page",
                } }) }, TooltipProps),
            React.createElement("span", null,
                React.createElement(ToggleButton, { value: "is_pinned", disabled: !!disablePinned, style: { background: disablePinned ? "#eee" : undefined } },
                    React.createElement(PushPin, { fontSize: "small" }))))));
};
export default SavedViewShareSelector;
