import * as React from "react";
import { FilterAlt } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import ResponsiveFilterChip from "./ResponsiveFilterChip";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const SourceFilter = () => {
    const { selectedSources, setSelectedSources, sources } = useAgentDashboardFilters();
    const [panelAnchorEl, setPanelAnchorEl] = React.useState(null);
    const handleSourceFilterClicked = React.useCallback((e) => {
        setPanelAnchorEl(e.currentTarget);
    }, [setPanelAnchorEl]);
    const closePanel = React.useCallback(() => {
        setPanelAnchorEl(null);
    }, [setPanelAnchorEl]);
    const selectAll = React.useCallback(() => {
        setSelectedSources(new Set(sources !== null && sources !== void 0 ? sources : []));
    }, [setSelectedSources, sources]);
    const toggleSelectedSources = React.useCallback((source) => () => {
        setSelectedSources((prev) => {
            const next = new Set(prev);
            if (next.has(source)) {
                next.delete(source);
            }
            else {
                next.add(source);
            }
            return next;
        });
    }, [setSelectedSources]);
    const selectSource = React.useCallback((source) => () => {
        setSelectedSources(new Set([source]));
    }, [setSelectedSources]);
    if (!sources || sources.length <= 1) {
        return null;
    }
    const numSelected = selectedSources.size > 0 ? selectedSources.size : sources.length;
    const label = `${numSelected} of ${sources.length} sources`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveFilterChip, { size: "small", label: label, icon: React.createElement(FilterAlt, { fontSize: "small" }), onClick: handleSourceFilterClicked, isActive: numSelected !== sources.length }),
        panelAnchorEl && (React.createElement(CorePopover, { anchorEl: panelAnchorEl, onClose: closePanel, open: true, sx: { "& .MuiPaper-root ": { minWidth: "300px" } } },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", spacing: 1, style: { overflow: "hidden" } },
                    React.createElement(Grid, { item: true, style: { height: "32px" } },
                        React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center", style: { width: "100%" } },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, { style: { fontWeight: "bold" } },
                                    "Sources (",
                                    numSelected,
                                    " selected)")),
                            React.createElement(Grid, { item: true },
                                React.createElement(Button, { size: "small", onClick: selectAll }, "Select All")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 0, style: { maxHeight: "300px", overflow: "scroll", flex: 1 } }, sources
                        .sort((a, b) => {
                        const aName = a.toLowerCase().trim();
                        const bName = b.toLowerCase().trim();
                        if (aName < bName) {
                            return -1;
                        }
                        if (aName > bName) {
                            return 1;
                        }
                        return 0;
                    })
                        .map((source) => (React.createElement(Grid, { key: source, item: true },
                        React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(FormControlLabel, { label: source, control: React.createElement(Checkbox, { size: "small", checked: selectedSources.has(source) || selectedSources.size === 0, color: "primary", onChange: toggleSelectedSources(source), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Button, { style: { padding: 0 }, onClick: selectSource(source) }, "only")))))))))))));
};
export default SourceFilter;
