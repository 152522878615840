import * as React from "react";
import { Add, Cancel, Delete, Save } from "@mui/icons-material";
import { Box, Divider, Grid, Button, TableHead, TableBody, TextField, MenuItem, Typography, Table, TableCell, IconButton, Tooltip, TableRow, Skeleton, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useAddTeam, useDeleteTeam, useGetTeam, useListTeamRoles, useListTeams, useUpdateTeam, } from "@app/orval/api/teams";
import { useListUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { replaceItemInArray } from "@app/util/Utils";
import UserAutocomplete from "../../crm/components/box-view/actions/UserAutocomplete";
const DEFAULT_FORM_DATA = {
    name: "",
    members: [],
};
const TeamForm = (props) => {
    var _a, _b, _c, _d;
    const { uid } = props;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [formData, setFormData] = React.useState(DEFAULT_FORM_DATA);
    const getTeamApi = useGetTeam(uid !== null && uid !== void 0 ? uid : "", {
        query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: false }),
    });
    const addTeamApi = useAddTeam();
    const updateTeamApi = useUpdateTeam();
    const deleteTeamApi = useDeleteTeam();
    const listTeamsApi = useListTeams({ query: { enabled: false } });
    const listUsersApi = useListUsers({}, REFETCH_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listTeamRolesApi = useListTeamRoles(REFETCH_CACHE_PARAMS);
    const teamRoles = (_d = (_c = listTeamRolesApi.data) === null || _c === void 0 ? void 0 : _c.data.items) !== null && _d !== void 0 ? _d : null;
    React.useEffect(() => {
        if (props.uid) {
            if (getTeamApi.data) {
                setFormData({
                    name: getTeamApi.data.data.name,
                    members: getTeamApi.data.data.members.map((x) => ({
                        user_uid: x.user.uid,
                        role: x.role,
                        randomId: uuidv4(),
                    })),
                });
            }
            else {
                getTeamApi
                    .refetch()
                    .then((result) => {
                    var _a, _b, _c, _d;
                    setFormData({
                        name: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data.name) !== null && _b !== void 0 ? _b : "",
                        members: (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.data.members.map((x) => ({
                            user_uid: x.user.uid,
                            role: x.role,
                            randomId: uuidv4(),
                        }))) !== null && _d !== void 0 ? _d : [],
                    });
                })
                    .catch(errorDialog);
            }
        }
        else {
            setFormData(DEFAULT_FORM_DATA);
        }
    }, [uid]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const addMember = React.useCallback(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { members: [
                ...prev.members,
                {
                    user_uid: null,
                    role: "Member",
                    randomId: uuidv4(),
                },
            ] })));
    }, [setFormData]);
    const removeMember = React.useCallback((randomId) => () => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { members: prev.members.filter((x) => x.randomId !== randomId) })));
    }, [setFormData]);
    const setMemberUser = React.useCallback((randomId) => (_, value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { members: replaceItemInArray({
                arr: prev.members,
                getValue: (previousMember) => {
                    var _a;
                    return (Object.assign(Object.assign({}, previousMember), { user_uid: (_a = value === null || value === void 0 ? void 0 : value.uid) !== null && _a !== void 0 ? _a : null }));
                },
                where: (item) => item.randomId === randomId,
            }) })));
    }, [setFormData]);
    const setMemberRole = React.useCallback((randomId) => (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { members: replaceItemInArray({
                arr: prev.members,
                getValue: (previousMember) => (Object.assign(Object.assign({}, previousMember), { role: e.target.value })),
                where: (item) => item.randomId === randomId,
            }) })));
    }, [setFormData]);
    const cancel = React.useCallback(() => {
        navigate("/teams");
    }, [navigate]);
    const deleteTeam = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listTeamsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((item) => item.uid !== uid) });
                });
                deleteTeamApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    console.log("deleted team");
                    navigate("/teams");
                })
                    .catch(errorDialog);
            }
        });
    }, [uid, queryClient, listTeamsApi.queryKey, deleteTeamApi, confirm, errorDialog, navigate]);
    const saveTeam = React.useCallback(() => {
        const data = Object.assign(Object.assign({}, formData), { members: formData.members.map((x) => (Object.assign(Object.assign({}, x), { user_uid: x.user_uid, role: x.role }))) });
        if (uid) {
            updateTeamApi
                .mutateAsync({ uid, data })
                .then((result) => {
                queryClient.setQueryData(listTeamsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                });
                queryClient.setQueryData(getTeamApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: result.data });
                });
                navigate("/teams");
            })
                .catch(errorDialog);
        }
        else {
            addTeamApi
                .mutateAsync({ data })
                .then((result) => {
                queryClient.setQueryData(listTeamsApi.queryKey, (old) => {
                    if (old) {
                        return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                    }
                    return undefined;
                });
                navigate("/teams");
            })
                .catch(errorDialog);
        }
    }, [
        uid,
        queryClient,
        listTeamsApi.queryKey,
        getTeamApi.queryKey,
        addTeamApi,
        updateTeamApi,
        formData,
        navigate,
        errorDialog,
    ]);
    const isValid = !!formData.name.trim() && !formData.members.find((x) => !x.role || !x.user_uid) && users && teamRoles;
    const isMutating = addTeamApi.status === "pending" || updateTeamApi.status === "pending" || deleteTeamApi.status === "pending";
    const loading = [
        {
            label: "Loading team",
            queryResult: getTeamApi,
        },
    ].filter((_) => !!uid);
    const showInfiniteSpinner = uid ? getTeamApi.status === "pending" : false;
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    return (React.createElement(Grid, { container: true, direction: "column", spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 4 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Name", value: formData.name, onChange: setName, slotProps: {
                                htmlInput: { maxLength: 255 },
                            } })),
                    (!users || !teamRoles) && (React.createElement(Grid, { item: true, xs: 12 }, Array.from(Array(10)).map((i) => (React.createElement(Skeleton, { key: i, style: { height: "40px" } }))))),
                    users && teamRoles && (React.createElement(Grid, { item: true, container: true, xs: 12, direction: "column", spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "h6" }, "Members")),
                        formData.members.length > 0 && (React.createElement(Grid, { item: true },
                            React.createElement(Table, { size: "small" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableCell, { style: { width: "30px" } }),
                                    React.createElement(TableCell, null, "User"),
                                    React.createElement(TableCell, null, "Role")),
                                React.createElement(TableBody, null, formData.members.map((member) => {
                                    var _a;
                                    return (React.createElement(TableRow, { key: member.randomId },
                                        React.createElement(TableCell, null,
                                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove member") },
                                                React.createElement(IconButton, { size: "small", onClick: removeMember(member.randomId), tabIndex: -1 },
                                                    React.createElement("span", null,
                                                        React.createElement(Delete, { fontSize: "small" }))))),
                                        React.createElement(TableCell, null,
                                            React.createElement(UserAutocomplete, { users: users, value: (_a = users.find((x) => x.uid === member.user_uid)) !== null && _a !== void 0 ? _a : null, onChange: setMemberUser(member.randomId), TextFieldProps: {
                                                    size: "small",
                                                    InputProps: { disableUnderline: true },
                                                    autoFocus: false,
                                                }, AutocompleteProps: {
                                                    autoFocus: false,
                                                } })),
                                        React.createElement(TableCell, null,
                                            React.createElement(TextField, { variant: "standard", size: "small", select: true, fullWidth: true, value: member.role, onChange: setMemberRole(member.randomId), slotProps: {
                                                    input: {
                                                        disableUnderline: true,
                                                    },
                                                } }, teamRoles.map((role) => (React.createElement(MenuItem, { key: role, value: role }, role)))))));
                                }))))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", variant: "outlined", onClick: addMember, startIcon: React.createElement(Add, null), tabIndex: -1 }, "Add member"))))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                    uid && (React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", color: "error", tabIndex: -1, disabled: isMutating, startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: deleteTeam }, "Delete"))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { startIcon: React.createElement(Cancel, null), onClick: cancel, disabled: isMutating, tabIndex: -1 }, "Cancel")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { startIcon: React.createElement(Save, null), onClick: saveTeam, disabled: isMutating || !isValid, color: "primary", variant: "outlined", tabIndex: -1 }, "Save")))))));
};
export default TeamForm;
