import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import ViewPurposeForm from "../../features/crm/view-purposes/ViewPurposeForm";
const ViewPurposesFormPage = () => {
    useDocumentTitle("View Purposes");
    const params = useParams();
    return React.createElement(ViewPurposeForm, { uid: params.uid });
};
export default ViewPurposesFormPage;
