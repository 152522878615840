import * as React from "react";
import { Add, AutoAwesome, Link, QuestionAnswer, ViewModule } from "@mui/icons-material";
import { Button, Grid2, List, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import useSession from "@app/hooks/useSession";
import LinkPSPDialog from "./LinkPSPDialog";
import PSPListItem from "./PSPListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
import PSPRequestForm from "../../components/box-view/psp-request-form/PSPRequestForm";
const SellerPSPs = (props) => {
    var _a, _b;
    const { isAddingPSP, isCollapsible, onLinkPSP, onMarketAnalysisClicked, onRemovePSP, onStartNewPSP, psps, sellerLead, } = props;
    const session = useSession();
    const [isLinkPSPDialogOpen, setIsLinkPSPDialogOpen] = React.useState(false);
    const [isRequestPSPDialogOpen, setIsRequestPSPDialogOpen] = React.useState(false);
    const sellerLeadUid = sellerLead.uid;
    const removePSP = React.useCallback((uid) => {
        onRemovePSP(uid);
    }, [onRemovePSP]);
    const openLinkPSPDialog = React.useCallback(() => {
        setIsLinkPSPDialogOpen(true);
    }, [setIsLinkPSPDialogOpen]);
    const closeLinkPSPDialog = React.useCallback(() => {
        setIsLinkPSPDialogOpen(false);
    }, [setIsLinkPSPDialogOpen]);
    const openRequestPSPDialog = React.useCallback(() => {
        setIsRequestPSPDialogOpen(true);
    }, [setIsRequestPSPDialogOpen]);
    const closeRequestPSPDialog = React.useCallback(() => {
        setIsRequestPSPDialogOpen(false);
    }, [setIsRequestPSPDialogOpen]);
    const isLoading = psps == null;
    const noPSPs = psps && psps.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { title: "PSPs", count: (_a = psps === null || psps === void 0 ? void 0 : psps.length) !== null && _a !== void 0 ? _a : undefined, isCollapsible: isCollapsible, icon: React.createElement(ViewModule, null) },
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                React.createElement(Grid2, null,
                    isLoading && React.createElement(CoreLoading, null),
                    noPSPs && React.createElement(Typography, { variant: "body2" }, "No PSPs"),
                    psps && psps.length > 0 && (React.createElement(List, { dense: true }, psps
                        .sort((a, b) => {
                        if (a.created_at > b.created_at)
                            return -1;
                        if (a.created_at < b.created_at)
                            return 1;
                        return 0;
                    })
                        .map((psp) => (
                    // @ts-expect-error
                    React.createElement(PSPListItem, { key: psp.uid, psp: psp, onRemovePSP: onRemovePSP ? removePSP : undefined })))))),
                !isLoading && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 2 },
                        onStartNewPSP && (React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Add, { fontSize: "small" }), disabled: isAddingPSP, onClick: onStartNewPSP }, "Start new PSP"))),
                        onLinkPSP && (React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Link, { fontSize: "small" }), disabled: isAddingPSP, onClick: openLinkPSPDialog }, "Link an existing PSP"))),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(QuestionAnswer, { fontSize: "small" }), onClick: openRequestPSPDialog }, "Request a PSP")),
                        onMarketAnalysisClicked && ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.isDeveloper) && (React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", variant: "contained", tabIndex: -1, startIcon: React.createElement(AutoAwesome, { fontSize: "small" }), disabled: isAddingPSP, onClick: onMarketAnalysisClicked }, "Market Analysis")))))))),
        isLinkPSPDialogOpen && (React.createElement(LinkPSPDialog, { onLinkPSP: onLinkPSP, onClose: closeLinkPSPDialog, DialogProps: { open: true }, autocompleteFilter: { exclude_uids: (psps !== null && psps !== void 0 ? psps : []).map((p) => p.uid).join(",") } })),
        isRequestPSPDialogOpen && (React.createElement(PSPRequestForm, { onClose: closeRequestPSPDialog, DialogProps: { open: true }, sellerLeadUid: sellerLeadUid }))));
};
export default SellerPSPs;
