import * as React from "react";
import { Bathtub, Bed, Straighten } from "@mui/icons-material";
import { Chip, Grid2, Typography } from "@mui/material";
import numeral from "numeral";
import dayjs_ from "@app/common/dayjs";
const MLSPropertyMeasurement = (props) => {
    const { icon, label } = props;
    return (React.createElement(Grid2, { container: true, spacing: 1 },
        icon && React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, icon),
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body2", style: { marginTop: "auto", marginBottom: "auto" } }, label))));
};
const MLSPropertyDetails = (props) => {
    const { details, showAddress } = props;
    return (React.createElement(Grid2, { container: true, spacing: 1, direction: "column" },
        (details.num_beds != null || details.num_baths != null || details.living_area != null) && (React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, spacing: 0, sx: { rowGap: 1, columnGap: 3 } },
                details.num_beds != null && (React.createElement(Grid2, null,
                    React.createElement(MLSPropertyMeasurement, { icon: React.createElement(Bed, { fontSize: "small", style: { color: "#999" } }), label: `${details.num_beds} beds` }))),
                details.num_baths != null && (React.createElement(Grid2, null,
                    React.createElement(MLSPropertyMeasurement, { icon: React.createElement(Bathtub, { fontSize: "small", style: { color: "#999" } }), label: `${details.num_baths} baths` }))),
                details.living_area != null && (React.createElement(Grid2, null,
                    React.createElement(MLSPropertyMeasurement, { icon: React.createElement(Straighten, { fontSize: "small", style: { color: "#999" } }), label: `${details.living_area} sqft` })))))),
        details.formatted_address != null && showAddress !== false && (React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body1" }, details.formatted_address))),
        details.list_price != null && (React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, "List Price")),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, numeral(details.list_price).format("$0,0"))))),
        details.on_market_date != null && dayjs_(details.on_market_date) && (React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, "On Market")),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, dayjs_(details.on_market_date).format("dddd, MMMM D, YYYY"))))),
        details.source && (React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, "Source")),
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Chip, { size: "small", label: details.source }))))));
};
export default MLSPropertyDetails;
