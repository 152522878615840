import { useMemo } from "react";
import useSession from "@app/hooks/useSession";
import { canEditRecruit } from "./utils";
const useCanEditRecruit = (recruit) => {
    const session = useSession();
    const canEdit = useMemo(() => {
        if (!recruit) {
            return false;
        }
        return canEditRecruit({
            recruit,
            sessionUser: session === null || session === void 0 ? void 0 : session.viewingAsUser,
        });
    }, [recruit, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    return canEdit;
};
export default useCanEditRecruit;
