import * as React from "react";
import { Avatar, InputAdornment, Tooltip } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { getInitials } from "@app/util/Utils";
import useSelectedContactForPhoneNumber from "./useSelectedContactForPhoneNumber";
import Search from "../../container/Header/Search/Search";
const TwilioSMSRecipientSelect = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { TextFieldProps, onPhoneNumberChanged, phoneNumber } = props;
    const [toContact, setToContact] = React.useState(null);
    const { contact, recruit, user } = useSelectedContactForPhoneNumber(phoneNumber !== null && phoneNumber !== void 0 ? phoneNumber : "");
    const toSearchEndAdornment = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        if (contact) {
            return (React.createElement(InputAdornment, { position: "end" },
                React.createElement(Tooltip, { title: contact.name },
                    React.createElement(Avatar, { src: undefined, style: { fontSize: "0.675rem", height: "24px", width: "24px" }, component: RouterLink, to: `/contacts/${contact.uid}` }, getInitials((_a = contact.fields["First Name"]) !== null && _a !== void 0 ? _a : null, (_b = contact.fields["Last Name"]) !== null && _b !== void 0 ? _b : null)))));
        }
        if (recruit) {
            return (React.createElement(InputAdornment, { position: "end" },
                React.createElement(Tooltip, { title: recruit.name },
                    React.createElement(Avatar, { src: undefined, style: { fontSize: "0.675rem", height: "24px", width: "24px" }, component: RouterLink, to: `/recruits/${recruit.uid}` }, getInitials((_c = recruit.fields["First Name"]) !== null && _c !== void 0 ? _c : null, (_d = recruit.fields["Last Name"]) !== null && _d !== void 0 ? _d : null)))));
        }
        if (user) {
            return (React.createElement(InputAdornment, { position: "end" },
                React.createElement(Tooltip, { title: user.name },
                    React.createElement(Avatar, { src: undefined, style: { fontSize: "0.675rem", height: "24px", width: "24px" }, component: RouterLink, to: `/users/${user.uid}` }, getInitials((_e = user.fields["First Name"]) !== null && _e !== void 0 ? _e : null, (_f = user.fields["Last Name"]) !== null && _f !== void 0 ? _f : null)))));
        }
        return undefined;
    }, [contact, recruit, user]);
    const handleToSelected = React.useCallback((value) => {
        var _a, _b, _c;
        setToContact(value);
        if (value.contact) {
            onPhoneNumberChanged((_a = value.contact.fields["Phone"]) !== null && _a !== void 0 ? _a : null);
        }
        else if (value.user) {
            onPhoneNumberChanged((_b = value.user.fields["Phone"]) !== null && _b !== void 0 ? _b : null);
        }
        else if (value.recruit) {
            onPhoneNumberChanged((_c = value.recruit.fields["Phone"]) !== null && _c !== void 0 ? _c : null);
        }
    }, [onPhoneNumberChanged]);
    const handleToPhoneNumberChanged = React.useCallback((e) => {
        onPhoneNumberChanged(e.target.value);
        setToContact(null);
    }, [onPhoneNumberChanged]);
    console.log({ phoneNumber });
    return (React.createElement(Search, { searchTypes: ["contacts", "users", "recruits"], TextFieldProps: Object.assign(Object.assign({ size: "small", fullWidth: true }, TextFieldProps), { slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign(Object.assign({}, (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _a === void 0 ? void 0 : _a.input), { 
                    // @ts-expect-error: seems like mui's typings aren't great
                    startAdornment: (_d = (_c = (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.input) === null || _c === void 0 ? void 0 : _c.startAdornment) !== null && _d !== void 0 ? _d : (React.createElement(InputAdornment, { position: "start" }, "To:")), 
                    // @ts-expect-error: seems like mui's typings aren't great
                    endAdornment: (_g = (_f = (_e = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _e === void 0 ? void 0 : _e.input) === null || _f === void 0 ? void 0 : _f.endAdornment) !== null && _g !== void 0 ? _g : toSearchEndAdornment }) }), onChange: handleToPhoneNumberChanged }), variant: "standard", showNoResults: false, onItemSelected: handleToSelected, defaultSearchString: (_p = (_o = (_l = (_j = (_h = toContact === null || toContact === void 0 ? void 0 : toContact.recruit) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : (_k = toContact === null || toContact === void 0 ? void 0 : toContact.user) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : (_m = toContact === null || toContact === void 0 ? void 0 : toContact.contact) === null || _m === void 0 ? void 0 : _m.name) !== null && _o !== void 0 ? _o : phoneNumber) !== null && _p !== void 0 ? _p : "", clearWhenEscapePressed: false }));
};
export default TwilioSMSRecipientSelect;
