/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List PSP Requests
 */
export const listPspRequests = (params, options) => {
    return axios.get(`/api/psp_requests`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspRequestsQueryKey = (params) => {
    return [`/api/psp_requests`, ...(params ? [params] : [])];
};
export const getListPspRequestsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestsQueryKey(params);
    const queryFn = ({ signal }) => listPspRequests(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSP Requests
 */
export function useListPspRequests(params, options) {
    const queryOptions = getListPspRequestsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get a PSP Request
 */
export const getPspRequest = (uid, options) => {
    return axios.get(`/api/psp_requests/${uid}`, options);
};
export const getGetPspRequestQueryKey = (uid) => {
    return [`/api/psp_requests/${uid}`];
};
export const getGetPspRequestQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspRequestQueryKey(uid);
    const queryFn = ({ signal }) => getPspRequest(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP Request
 */
export function useGetPspRequest(uid, options) {
    const queryOptions = getGetPspRequestQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Delete a PSP Request
 */
export const deletePspRequest = (uid, options) => {
    return axios.delete(`/api/psp_requests/${uid}`, options);
};
export const getDeletePspRequestMutationOptions = (options) => {
    const mutationKey = ['deletePspRequest'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspRequest(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a PSP Request
*/
export const useDeletePspRequest = (options) => {
    const mutationOptions = getDeletePspRequestMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List investor types
*/
export const listPspRequestInvestorTypes = (options) => {
    return axios.get(`/api/psp_requests/investor_types`, options);
};
export const getListPspRequestInvestorTypesQueryKey = () => {
    return [`/api/psp_requests/investor_types`];
};
export const getListPspRequestInvestorTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestInvestorTypesQueryKey();
    const queryFn = ({ signal }) => listPspRequestInvestorTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List investor types
 */
export function useListPspRequestInvestorTypes(options) {
    const queryOptions = getListPspRequestInvestorTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List language options
 */
export const listPspRequestLanguageOptions = (options) => {
    return axios.get(`/api/psp_requests/languages`, options);
};
export const getListPspRequestLanguageOptionsQueryKey = () => {
    return [`/api/psp_requests/languages`];
};
export const getListPspRequestLanguageOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestLanguageOptionsQueryKey();
    const queryFn = ({ signal }) => listPspRequestLanguageOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List language options
 */
export function useListPspRequestLanguageOptions(options) {
    const queryOptions = getListPspRequestLanguageOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List markets
 */
export const listPspRequestMarkets = (options) => {
    return axios.get(`/api/psp_requests/markets`, options);
};
export const getListPspRequestMarketsQueryKey = () => {
    return [`/api/psp_requests/markets`];
};
export const getListPspRequestMarketsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestMarketsQueryKey();
    const queryFn = ({ signal }) => listPspRequestMarkets(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List markets
 */
export function useListPspRequestMarkets(options) {
    const queryOptions = getListPspRequestMarketsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List request types
 */
export const listPspRequestTypes = (options) => {
    return axios.get(`/api/psp_requests/request_types`, options);
};
export const getListPspRequestTypesQueryKey = () => {
    return [`/api/psp_requests/request_types`];
};
export const getListPspRequestTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestTypesQueryKey();
    const queryFn = ({ signal }) => listPspRequestTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List request types
 */
export function useListPspRequestTypes(options) {
    const queryOptions = getListPspRequestTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Submit a PSP Request
 */
export const submitPspRequest = (pSPRequestRequest, options) => {
    return axios.post(`/api/psp_requests/submit`, pSPRequestRequest, options);
};
export const getSubmitPspRequestMutationOptions = (options) => {
    const mutationKey = ['submitPspRequest'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return submitPspRequest(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit a PSP Request
*/
export const useSubmitPspRequest = (options) => {
    const mutationOptions = getSubmitPspRequestMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List request timing options
*/
export const listPspRequestTimingOptions = (options) => {
    return axios.get(`/api/psp_requests/timing`, options);
};
export const getListPspRequestTimingOptionsQueryKey = () => {
    return [`/api/psp_requests/timing`];
};
export const getListPspRequestTimingOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestTimingOptionsQueryKey();
    const queryFn = ({ signal }) => listPspRequestTimingOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List request timing options
 */
export function useListPspRequestTimingOptions(options) {
    const queryOptions = getListPspRequestTimingOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List urgency options
 */
export const listPspRequestUrgencyOptions = (options) => {
    return axios.get(`/api/psp_requests/urgency`, options);
};
export const getListPspRequestUrgencyOptionsQueryKey = () => {
    return [`/api/psp_requests/urgency`];
};
export const getListPspRequestUrgencyOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspRequestUrgencyOptionsQueryKey();
    const queryFn = ({ signal }) => listPspRequestUrgencyOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List urgency options
 */
export function useListPspRequestUrgencyOptions(options) {
    const queryOptions = getListPspRequestUrgencyOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
