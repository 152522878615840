import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import ChoiceSetForm from "./ChoiceSetForm";
const ChoiceSetsFormPage = () => {
    useDocumentTitle("Choice Sets");
    const params = useParams();
    return React.createElement(ChoiceSetForm, { uid: params.uid });
};
export default ChoiceSetsFormPage;
