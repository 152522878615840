import * as React from "react";
import { useParams } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetBuyerLead } from "@app/orval/api/buyer-leads";
import MailScheduleBoxesTable from "../../features/mailschedule4/boxes/MailScheduleBoxesTable";
const BuyerLeadsMailSchedulePage = () => {
    var _a, _b;
    const params = useParams();
    const { uid } = params;
    useDocumentTitle("Buyer Leads | Mail Schedule");
    const getBuyerLeadApi = useGetBuyerLead(uid);
    const buyerLead = (_b = (_a = getBuyerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    if (!buyerLead) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(MailScheduleBoxesTable, { entity: {
            uid: uid,
            entityType: "Buyer Lead",
        }, 
        // onPreviewSelected={onPreviewSelected}
        backLink: `/buyers/${buyerLead.uid}` }));
};
export default BuyerLeadsMailSchedulePage;
