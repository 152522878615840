import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Divider, Grid2 } from "@mui/material";
import CRMChangesReportContent from "./CRMChangesReportContent";
import CRMChangesReportFilters from "./CRMChangesReportFilters";
const CRMChangesReport = () => {
    var _a, _b, _c;
    const [searchParams] = useSearchParams();
    const startDate = (_a = searchParams.get("start_date")) !== null && _a !== void 0 ? _a : null;
    const endDate = (_b = searchParams.get("end_date")) !== null && _b !== void 0 ? _b : null;
    const office = (_c = searchParams.get("office")) !== null && _c !== void 0 ? _c : null;
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(CRMChangesReportFilters, null))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        startDate && endDate && (React.createElement(Grid2, { style: { overflow: "auto" } },
            React.createElement(Box, { p: 2 },
                React.createElement(CRMChangesReportContent, { startDate: startDate, endDate: endDate, office: office }))))));
};
export default CRMChangesReport;
