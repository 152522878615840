import { useCallback, useMemo } from "react";
import { listComments } from "@app/orval/api/comments";
import { listNewsfeedFieldValueUpdates, listNewsfeedContactNoteChanges } from "@app/orval/api/newsfeed";
import { REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { getCommentStory, getNoteChangeStory } from "../components/newsfeed/utils";
const useNewsfeed = (params) => {
    var _a, _b, _c, _d, _e, _f;
    const from = params === null || params === void 0 ? void 0 : params.from;
    const to = params === null || params === void 0 ? void 0 : params.to;
    const fetchFieldValuesApi = usePostQuery(listNewsfeedFieldValueUpdates, { earliest: from, latest: to, limit: 100, entity_types: ["Contact"] }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const fieldValues = (_b = (_a = fetchFieldValuesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const isLoadingFieldValues = fetchFieldValuesApi.isLoading;
    const fetchNoteChangesApi = usePostQuery(listNewsfeedContactNoteChanges, { earliest: from, latest: to, limit: 100 }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const notes = (_d = (_c = fetchNoteChangesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const isLoadingNotes = fetchNoteChangesApi.isLoading;
    const fetchCommentsApi = usePostQuery(listComments, { added_from: from !== null && from !== void 0 ? from : undefined, added_to: to !== null && to !== void 0 ? to : undefined, limit: 100, entity_type: "Contact" }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const comments = (_f = (_e = fetchCommentsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const isLoadingComments = fetchCommentsApi.isLoading;
    const refresh = useCallback(() => {
        fetchCommentsApi.refetch();
        fetchNoteChangesApi.refetch();
        fetchFieldValuesApi.refetch();
    }, [fetchCommentsApi, fetchFieldValuesApi, fetchNoteChangesApi]);
    const newsfeed = useMemo(() => {
        const items = [
            ...(fieldValues !== null && fieldValues !== void 0 ? fieldValues : []).map((x) => (Object.assign(Object.assign({}, x), { url: `/contacts/${x.contact.uid}`, name: x.contact.name, type: "field" }))),
            ...(notes !== null && notes !== void 0 ? notes : []).map((x) => (Object.assign(Object.assign({}, x), { story: getNoteChangeStory(x), url: `/contacts/${x.contact.uid}`, name: x.contact.name, type: "note" }))),
            ...(comments !== null && comments !== void 0 ? comments : []).map((x) => (Object.assign(Object.assign({}, x), { timestamp: x.edits[0].timestamp, userstamp: x.edits[0].author, story: getCommentStory(x), url: `/contacts/${x.contact.uid}`, name: x.contact.name, type: "comment" }))),
        ];
        return items;
    }, [comments, fieldValues, notes]);
    return {
        isLoadingNotes,
        isLoadingFieldValues,
        isLoadingComments,
        refresh,
        newsfeed,
    };
};
export default useNewsfeed;
