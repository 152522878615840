import * as React from "react";
import { AutoAwesome, Code, Comment, Mail } from "@mui/icons-material";
import { Divider, Link, ListItemIcon, MenuItem } from "@mui/material";
import streakIcon from "@app/assets/img/streak.png";
import CorePopover from "@app/common/CorePopover";
import useSession from "@app/hooks/useSession";
import { getFullName, getStreakLinkForBox } from "@app/util/Utils";
import CommentPopover from "../../../dashboards/agent-dashboard/components/pinned-boxes/CommentPopover";
import BuyerLeadActionsMenuFormsSection from "../../buyer-leads/components/BuyerLeadActionsMenuFormsSection";
import ImportFromMLSDialog from "../../buyer-leads/components/ImportFromMLSDialog";
import ActionsMenu from "../../components/box-view/ActionsMenu";
import EmailMenuSection from "../../components/box-view/ActionsMenu/EmailMenuSection";
import FollowUpBossMenuSection from "../../components/box-view/ActionsMenu/FollowUpBossMenuSection";
import PhoneMenuSection from "../../components/box-view/ActionsMenu/PhoneMenuSection";
import PremierAgentMenuSection from "../../components/box-view/ActionsMenu/PremierAgentMenuSection";
import OpenLoopInDotloopMenuItem from "../../components/box-view/OpenLoopInDotloopMenuItem";
const BuyerContractActionsMenu = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { buyerContract, onImportFromMLSComplete } = props;
    const ref = React.useRef(null);
    const [isCommentPopoverOpen, setIsCommentPopoverOpen] = React.useState(false);
    const openCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(true);
    }, []);
    const closeCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(false);
    }, []);
    const premierAgentId = (_a = buyerContract.buyer_lead) === null || _a === void 0 ? void 0 : _a.fields["Premier Agent Contact ID"];
    const session = useSession();
    const canViewApiResponse = (_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openImportFromMLSDialog = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [setAnchorEl]);
    const closeImportFromMLSDialog = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsMenu, { ref: ref },
            React.createElement(MenuItem, { onClick: openCommentPopover },
                React.createElement(ListItemIcon, null,
                    React.createElement(Comment, null)),
                "Add a comment"),
            React.createElement(Divider, null),
            React.createElement(EmailMenuSection, { entityType: "Buyer Contract", contacts: (_e = (_d = (_c = buyerContract.buyer_lead) === null || _c === void 0 ? void 0 : _c.clients) === null || _d === void 0 ? void 0 : _d.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        email: (_a = client.client.email) !== null && _a !== void 0 ? _a : "",
                    });
                })) !== null && _e !== void 0 ? _e : [], entityUids: [buyerContract.uid] }),
            React.createElement(Divider, null),
            React.createElement(PhoneMenuSection, { contacts: (_h = (_g = (_f = buyerContract.buyer_lead) === null || _f === void 0 ? void 0 : _f.clients) === null || _g === void 0 ? void 0 : _g.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        phone: (_a = client.client.phone) !== null && _a !== void 0 ? _a : "",
                    });
                })) !== null && _h !== void 0 ? _h : [] }),
            React.createElement(Divider, null),
            React.createElement(PremierAgentMenuSection, { premierAgentId: premierAgentId }),
            React.createElement(FollowUpBossMenuSection, { personId: (_k = (_j = buyerContract.buyer_lead) === null || _j === void 0 ? void 0 : _j.fields["FUB ID"]) !== null && _k !== void 0 ? _k : undefined }),
            React.createElement(BuyerLeadActionsMenuFormsSection, { buyerLead: (_l = buyerContract.buyer_lead) !== null && _l !== void 0 ? _l : { uid: buyerContract.lead_uid } }),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { disabled: !buyerContract.fields["MLS#"] && !buyerContract.fields["Property Address"], onClick: openImportFromMLSDialog },
                React.createElement(ListItemIcon, null,
                    React.createElement(AutoAwesome, null)),
                "Import data from MLS"),
            React.createElement(MenuItem, { component: Link, href: `/buyer-contracts/${buyerContract.uid}/mail-schedule`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Mail, null)),
                "Open Mail Schedule"),
            ((_m = buyerContract.streak_buyer_contracts_box_key) !== null && _m !== void 0 ? _m : buyerContract.streak_buyer_new_construction_box_key) && (React.createElement(MenuItem, { component: Link, href: getStreakLinkForBox((_p = (_o = buyerContract.streak_buyer_new_construction_box_key) !== null && _o !== void 0 ? _o : buyerContract.streak_buyer_new_construction_box_key) !== null && _p !== void 0 ? _p : ""), target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { height: 20, alt: "Streak", src: streakIcon })),
                "Open Streak box")),
            React.createElement(OpenLoopInDotloopMenuItem, { loopId: buyerContract.fields["Dotloop ID"] }),
            canViewApiResponse && React.createElement(Divider, null),
            canViewApiResponse && (React.createElement(MenuItem, { component: Link, href: `/api/buyer_contracts/${buyerContract.uid}`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Code, null)),
                "View API Response"))),
        anchorEl && (React.createElement(ImportFromMLSDialog, { buyerContract: buyerContract, onClose: closeImportFromMLSDialog, DialogProps: { open: true }, onImportComplete: onImportFromMLSComplete })),
        isCommentPopoverOpen && (React.createElement(CorePopover, { open: true, anchorEl: ref.current },
            React.createElement(CommentPopover, { onCancel: closeCommentPopover, onSubmit: closeCommentPopover, 
                // @ts-expect-error: need this in order for this to work from the Overview page
                entityUid: (_r = (_q = buyerContract.buyer_lead) === null || _q === void 0 ? void 0 : _q.uid) !== null && _r !== void 0 ? _r : buyerContract.lead_uid })))));
};
export default BuyerContractActionsMenu;
