/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List PSP Comps
 */
export const listPspComps = (params, options) => {
    return axios.get(`/api/psp_comps`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspCompsQueryKey = (params) => {
    return [`/api/psp_comps`, ...(params ? [params] : [])];
};
export const getListPspCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspCompsQueryKey(params);
    const queryFn = ({ signal }) => listPspComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSP Comps
 */
export function useListPspComps(params, options) {
    const queryOptions = getListPspCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a PSP Comp
 */
export const createPspComp = (pSPCompCreateRequest, options) => {
    return axios.post(`/api/psp_comps`, pSPCompCreateRequest, options);
};
export const getCreatePspCompMutationOptions = (options) => {
    const mutationKey = ['createPspComp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPspComp(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a PSP Comp
*/
export const useCreatePspComp = (options) => {
    const mutationOptions = getCreatePspCompMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a PSP Comp
*/
export const getPspComp = (uid, options) => {
    return axios.get(`/api/psp_comps/${uid}`, options);
};
export const getGetPspCompQueryKey = (uid) => {
    return [`/api/psp_comps/${uid}`];
};
export const getGetPspCompQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspCompQueryKey(uid);
    const queryFn = ({ signal }) => getPspComp(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP Comp
 */
export function useGetPspComp(uid, options) {
    const queryOptions = getGetPspCompQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a PSP Comp
 */
export const updatePspComp = (uid, pSPCompUpdateRequest, options) => {
    return axios.post(`/api/psp_comps/${uid}`, pSPCompUpdateRequest, options);
};
export const getUpdatePspCompMutationOptions = (options) => {
    const mutationKey = ['updatePspComp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updatePspComp(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a PSP Comp
*/
export const useUpdatePspComp = (options) => {
    const mutationOptions = getUpdatePspCompMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a PSP Comp
*/
export const deletePspComp = (uid, options) => {
    return axios.delete(`/api/psp_comps/${uid}`, options);
};
export const getDeletePspCompMutationOptions = (options) => {
    const mutationKey = ['deletePspComp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspComp(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a PSP Comp
*/
export const useDeletePspComp = (options) => {
    const mutationOptions = getDeletePspCompMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count PSP Comps
*/
export const countPspComps = (params, options) => {
    return axios.get(`/api/psp_comps/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountPspCompsQueryKey = (params) => {
    return [`/api/psp_comps/count`, ...(params ? [params] : [])];
};
export const getCountPspCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountPspCompsQueryKey(params);
    const queryFn = ({ signal }) => countPspComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count PSP Comps
 */
export function useCountPspComps(params, options) {
    const queryOptions = getCountPspCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download PSP Comps
 */
export const downloadPspComps = (params, options) => {
    return axios.get(`/api/psp_comps/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadPspCompsQueryKey = (params) => {
    return [`/api/psp_comps/download`, ...(params ? [params] : [])];
};
export const getDownloadPspCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadPspCompsQueryKey(params);
    const queryFn = ({ signal }) => downloadPspComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download PSP Comps
 */
export function useDownloadPspComps(params, options) {
    const queryOptions = getDownloadPspCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Reorder PSP Comps
 */
export const reorderPspComps = (multipleUIDRequest, options) => {
    return axios.post(`/api/psp_comps/reorder`, multipleUIDRequest, options);
};
export const getReorderPspCompsMutationOptions = (options) => {
    const mutationKey = ['reorderPspComps'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderPspComps(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder PSP Comps
*/
export const useReorderPspComps = (options) => {
    const mutationOptions = getReorderPspCompsMutationOptions(options);
    return useMutation(mutationOptions);
};
