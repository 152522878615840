import * as React from "react";
import { CodeOff, Edit } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import { useListStages } from "@app/orval/api/stages";
const StyledGrid = styled(Grid) `
  .edit-rule-button {
    visibility: hidden
  }
  &:hover .edit-rule-button {
    visibility: visible
  }
`;
const DataValidationRuleView = (props) => {
    var _a, _b, _c, _d;
    const { onEditClicked, rule } = props;
    // @ts-expect-error
    const listStagesApi = useListStages({ type: rule.entity_type }, { enabled: false });
    const stagesByUid = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data.reduce((acc, stage) => {
        acc[stage.uid] = stage;
        return acc;
    }, {});
    const attrs = {
        Stage: (_b = rule.stage_validation_rule) === null || _b === void 0 ? void 0 : _b.stage_uids.map((x) => { var _a; return (_a = stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[x]) === null || _a === void 0 ? void 0 : _a.name; }).join(", "),
        Action: (_c = rule.stage_validation_rule) === null || _c === void 0 ? void 0 : _c.action,
        Formula: (_d = rule.stage_validation_rule) === null || _d === void 0 ? void 0 : _d.formula,
    };
    return (React.createElement(StyledGrid, { container: true, spacing: 1, wrap: "nowrap", style: !rule.is_enabled ? { color: "#666" } : undefined },
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(Grid, { container: true, spacing: 0, direction: "column", wrap: "nowrap" },
                Object.keys(attrs).map((key) => (React.createElement(Grid, { key: key, item: true, container: true, spacing: 1 },
                    React.createElement(Grid, { item: true, pt: 0, pb: 0 },
                        React.createElement(Typography, { variant: "caption" }, key)),
                    React.createElement(Grid, { item: true, pt: 0, pb: 0 },
                        React.createElement(Typography, { variant: "caption", style: { fontWeight: "bold" } }, attrs[key] || "(blank)"))))),
                !rule.is_enabled && (React.createElement(Grid, { item: true, pt: 0, pb: 0 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(CodeOff, { fontSize: "small", style: { color: "#888" } })),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", flex: 1, transform: "translateY(-3px)" } },
                            React.createElement(Typography, { variant: "caption", style: { color: "#888" } }, "Disabled"))))))),
        React.createElement(Grid, { item: true, className: "edit-rule-button", style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { size: "small", onClick: onEditClicked },
                React.createElement(Edit, { fontSize: "small" })))));
};
export default DataValidationRuleView;
