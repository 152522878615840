import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import FlexRegion from "./FlexRegion";
const FlexRegionPage = () => {
    const { region } = useParams();
    useDocumentTitle(["FLEX Regions", region === null || region === void 0 ? void 0 : region.toUpperCase().trim()].filter((o) => o).join(" "));
    return React.createElement(FlexRegion, { region: region });
};
export default FlexRegionPage;
