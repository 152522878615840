import * as React from "react";
import { MenuItem } from "@mui/material";
import { getTemplateName } from "@app/entrypoints/brokerage/features/crm/components/box-view/ActionsMenu/EmailMenuSection";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { FIVE_MINUTES } from "@app/orval/config";
import SearchableMenu from "../SearchableMenu";
/**
 * Searchable menu of all Mail Schedule templates
 * @param props
 * @returns
 */
const MailScheduleTemplateMenu = (props) => {
    var _a, _b;
    const { MenuProps: MenuProps_, entityType, onTemplateSelected } = props;
    const [search, setSearch] = React.useState("");
    const listMailScheduleTemplatesApi = useListMailschedule4Templates({ entity_type: entityType }, { query: { staleTime: FIVE_MINUTES, gcTime: FIVE_MINUTES } });
    const mailScheduleTemplates = (_b = (_a = listMailScheduleTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const handleTemplateSelected = React.useCallback((templateUid) => () => {
        onTemplateSelected(templateUid);
    }, [onTemplateSelected]);
    return (React.createElement(SearchableMenu, { MenuProps: Object.assign({ anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "left",
            }, sx: {
                "& .MuiPaper-root": {
                    maxHeight: "400px",
                    overflowY: "scroll",
                },
            } }, MenuProps_), search: search, onSearchChange: setSearch }, mailScheduleTemplates === null || mailScheduleTemplates === void 0 ? void 0 : mailScheduleTemplates.filter((x) => (x.template_type === "Email" || x.template_type === "Email Draft") &&
        (!search.trim() || x.name.toLowerCase().includes(search.toLowerCase().trim()))).filter((x) => {
        var _a, _b;
        return !((_a = x.email_template) === null || _a === void 0 ? void 0 : _a.body.includes("$'QFO Form Link'")) &&
            !((_b = x.email_draft_template) === null || _b === void 0 ? void 0 : _b.body.includes("$'QFO Form Link'"));
    }).map((template) => (React.createElement(MenuItem, { key: template.uid, onClick: handleTemplateSelected(template.uid), style: { color: template.is_active ? undefined : "#888" } }, getTemplateName(template))))));
};
export default MailScheduleTemplateMenu;
