import * as React from "react";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
const CollapsibleSection = (props) => {
    const { children, count, displayPlusAfterCount, icon, isCollapsedInitially, isCollapsible, title, toolbar } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(isCollapsedInitially !== null && isCollapsedInitially !== void 0 ? isCollapsedInitially : false);
    const toggleIsCollapsed = React.useCallback((_) => {
        setIsCollapsed(!isCollapsed);
    }, [isCollapsed]);
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Grid, { container: true, spacing: 1 },
                icon && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", color: "#888" } }, icon)),
                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, typeof title === "string" ? React.createElement(Typography, { variant: "body1" }, title) : title),
                count != null && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "body1", color: "#888" },
                        "(",
                        count,
                        displayPlusAfterCount && "+",
                        ")"))),
                React.createElement(Grid, { item: true, style: { flex: 1 } }),
                toolbar && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, toolbar)),
                isCollapsible !== false && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(IconButton, { tabIndex: -1, size: "small", onClick: toggleIsCollapsed }, isCollapsed ? React.createElement(KeyboardArrowRight, { fontSize: "small" }) : React.createElement(KeyboardArrowDown, { fontSize: "small" })))))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Collapse, { in: !isCollapsed }, children))));
};
export default CollapsibleSection;
