import * as React from "react";
import { v4 as uuidv4 } from "uuid";
/**
 * A component that displays `value` by default, and `children` when clicked
 * @param props
 * @returns
 */
const LatentComponent = (props) => {
    const { children, style, value } = props;
    const [isActive, setIsActive] = React.useState(false);
    const wrapperRef = React.useRef(null);
    const wrapperId = React.useRef(uuidv4().replace(/\-/g, ""));
    const onClick = React.useCallback((e) => {
        try {
            // @ts-expect-error: id doe snot exist on type
            if (e.target.id === wrapperRef.current.id || e.target.closest(`#${wrapperRef.current.id}`)) {
                setIsActive(true);
            }
            else {
                setIsActive(false);
            }
        }
        catch (err) {
            setIsActive(false);
        }
    }, [setIsActive]);
    const onKeyDown = React.useCallback((e) => {
        if (e.key === "Escape" || e.key === "Tab") {
            setIsActive(false);
        }
    }, [setIsActive]);
    React.useEffect(() => {
        document.addEventListener("click", onClick);
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("click", onClick);
            document.removeEventListener("keydown", onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { ref: wrapperRef, style: style, id: wrapperId.current },
        !isActive ? value : null,
        isActive ? children : null));
};
export default LatentComponent;
