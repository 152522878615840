import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Grid2, ThemeProvider } from "@mui/material";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import numeral from "numeral";
import dayjs_ from "@app/common/dayjs";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import useLoading from "@app/hooks/useLoading";
import useSession from "@app/hooks/useSession";
import { useGetBuySideTowneMortgageData } from "@app/orval/api/towne-mortgage";
import AppConfig from "@app/util/AppConfig";
import { dateFieldFormatter, moneyFieldFormatter, renderAddressCell } from "../utils";
const GRAPH_COLORS = [
    AppConfig.themeColors.blue,
    AppConfig.themeColors.red,
    AppConfig.themeColors.yellow,
    "purple",
    "green",
];
const TowneMortgageBuySideContent = (props) => {
    var _a, _b, _c;
    const { view } = props;
    const [searchParams] = useSearchParams();
    const session = useSession();
    const lenderFirm = (_a = searchParams.get("lender_firm")) !== null && _a !== void 0 ? _a : null;
    const fromDate = (_b = searchParams.get("from_date")) !== null && _b !== void 0 ? _b : null;
    const toDate = (_c = searchParams.get("to_date")) !== null && _c !== void 0 ? _c : null;
    const buySideDataQuery = React.useMemo(() => {
        if (view === "buy-side-pending") {
            return { view: "pending" };
        }
        return { view };
    }, [view]);
    const listTowneMortgageBuysSideApi = useGetBuySideTowneMortgageData(buySideDataQuery);
    const contracts = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTowneMortgageBuysSideApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listTowneMortgageBuysSideApi.data]);
    const contractsByUid = React.useMemo(() => {
        if (!contracts) {
            return {};
        }
        return contracts.reduce((acc, contract) => {
            acc[contract.uid] = contract;
            return acc;
        }, {});
    }, [contracts]);
    const loading = useLoading({
        items: [
            {
                label: "Loading Towne Mortgage buy side data...",
                queryResult: listTowneMortgageBuysSideApi,
            },
        ],
    });
    const contractsFiltered = React.useMemo(() => {
        if (!contracts) {
            return null;
        }
        const fromDateAsDate = fromDate ? dayjs_(fromDate) : null;
        const toDateAsDate = toDate ? dayjs_(toDate) : null;
        return contracts.filter((contract) => {
            var _a;
            const comparisonDate = view === "closed" ? contract.closing_date : contract.effective_date;
            const comparisonDateAsDate = comparisonDate ? dayjs_(comparisonDate) : null;
            if ((fromDateAsDate || toDateAsDate) && !comparisonDateAsDate) {
                return false;
            }
            if (fromDateAsDate && (comparisonDateAsDate === null || comparisonDateAsDate === void 0 ? void 0 : comparisonDateAsDate.isBefore(fromDateAsDate))) {
                return false;
            }
            if (toDateAsDate && (comparisonDateAsDate === null || comparisonDateAsDate === void 0 ? void 0 : comparisonDateAsDate.isAfter(toDateAsDate))) {
                return false;
            }
            if (lenderFirm && !((_a = contract.lender_firm) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim().includes(lenderFirm.toLowerCase().trim()))) {
                return false;
            }
            return true;
        });
    }, [contracts, fromDate, lenderFirm, toDate, view]);
    const contractsByLender = React.useMemo(() => {
        if (!contractsFiltered || contractsFiltered.length === 0) {
            return {};
        }
        const contractsByLender_ = {};
        contractsFiltered.forEach((contract) => {
            var _a, _b, _c;
            const lender = (_b = (_a = contract.lender_firm) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) !== null && _b !== void 0 ? _b : "N/A";
            contractsByLender_[lender] = ((_c = contractsByLender_[lender]) !== null && _c !== void 0 ? _c : 0) + 1;
        });
        return contractsByLender_;
    }, [contractsFiltered]);
    const pieChartData = React.useMemo(() => {
        const totalTowneMortgage = Object.keys(contractsByLender)
            .filter((key) => {
            return key.includes("towne mortgage");
        })
            .reduce((sum, val) => sum + contractsByLender[val], 0);
        const totalOther = Object.keys(contractsByLender)
            .filter((key) => {
            return !key.includes("towne mortgage");
        })
            .reduce((sum, val) => sum + contractsByLender[val], 0);
        return [
            {
                id: "Other",
                label: "Other",
                value: totalOther,
            },
            {
                id: "Towne Mortgage",
                label: "Towne Mortgage",
                value: totalTowneMortgage,
            },
        ];
    }, [contractsByLender]);
    const formatPieLabels = React.useCallback((pieSlice) => {
        const pieVal = pieSlice.value;
        const total = pieChartData
            .map((slice) => {
            return slice.value;
        })
            .reduce((sum, val) => sum + val, 0);
        if (total) {
            return numeral((pieVal / total) * 100)
                .format("0%")
                .toString();
        }
        return pieVal.toString();
    }, [pieChartData]);
    const getStageSortComparator = React.useCallback((sortDirection) => {
        const modifier = sortDirection === "asc" ? 1 : -1;
        const handleStageSort = (_, __, c1, c2) => {
            var _a, _b;
            const row1 = contractsByUid[c1.id];
            const row2 = contractsByUid[c2.id];
            const stageOrder1 = (_a = row1 === null || row1 === void 0 ? void 0 : row1.status_order) !== null && _a !== void 0 ? _a : 0;
            const stageOrder2 = (_b = row2 === null || row2 === void 0 ? void 0 : row2.status_order) !== null && _b !== void 0 ? _b : 0;
            if (stageOrder1 < stageOrder2)
                return -1 * modifier;
            if (stageOrder1 > stageOrder2)
                return 1 * modifier;
            return 0;
        };
        return handleStageSort;
    }, [contractsByUid]);
    const rows = React.useMemo(() => {
        return (contractsFiltered !== null && contractsFiltered !== void 0 ? contractsFiltered : []).map((contract) => {
            return Object.assign({ id: contract.uid, closing_year: contract.closing_date && view === "closed" ? dayjs_(contract.closing_date).year() : null }, contract);
        });
    }, [contractsFiltered, view]);
    const columns = React.useMemo(() => {
        const columns_ = [
            {
                field: "type",
                headerName: "Type",
            },
            {
                field: "status",
                headerName: "Status",
                getSortComparator: getStageSortComparator,
            },
            {
                field: "assigned_agent_name",
                headerName: "Assigned Agent",
                width: 175,
            },
            {
                field: "op_team",
                headerName: "OP Team",
            },
            {
                field: "address",
                headerName: "Address",
                width: 300,
                renderCell: (session === null || session === void 0 ? void 0 : session.viewingAsUser.isInternal) ? renderAddressCell : undefined,
            },
            view !== "closed" && {
                field: "effective_date",
                headerName: "Effective Date",
                valueFormatter: dateFieldFormatter,
            },
            {
                field: "closing_date",
                headerName: "Closing Date",
                valueFormatter: dateFieldFormatter,
            },
            view === "closed" && {
                field: "closing_year",
                headerName: "Closing Year",
            },
            {
                field: "buyer_name",
                headerName: "Buyer Name",
            },
            {
                field: "contract_price",
                headerName: "Contract Price",
                valueFormatter: moneyFieldFormatter,
            },
            {
                field: "lender_firm",
                headerName: "Lender Firm",
            },
            {
                field: "lender_first_name",
                headerName: "Lender First",
            },
            {
                field: "lender_last_name",
                headerName: "Lender Last",
            },
            {
                field: "lender_phone",
                headerName: "Lender Phone",
            },
            {
                field: "lender_email",
                headerName: "Lender Email",
            },
            view === "closed" && {
                field: "longleaf",
                headerName: "Lender Email Domain",
            },
        ].filter((col) => col);
        return columns_;
    }, [getStageSortComparator, session === null || session === void 0 ? void 0 : session.viewingAsUser, view]);
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", style: { height: "100%" }, wrap: "nowrap" },
        React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 30, pagination: true, paginationMode: "client", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], disableRowSelectionOnClick: true, disableColumnMenu: true, disableColumnReorder: true, density: "compact", filterMode: "client" }))),
        view !== "closed" && (React.createElement(Grid2, null,
            React.createElement(PieChart, { colors: GRAPH_COLORS, series: [
                    {
                        data: pieChartData,
                        arcLabel: formatPieLabels,
                    },
                ], disableAxisListener: true, slotProps: {
                    legend: {
                        position: {
                            horizontal: "middle",
                            vertical: "bottom",
                        },
                        direction: "row",
                    },
                }, height: 275, margin: {
                    bottom: 75,
                }, sx: {
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                    },
                } })))));
};
export default TowneMortgageBuySideContent;
