import * as React from "react";
import { FileCopy, Delete, Save } from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import MailScheduleFormattersHelper from "@app/entrypoints/brokerage/features/mailschedule/utils/MailScheduleFormattersHelper";
import MailScheduleQuill from "@app/entrypoints/brokerage/features/mailschedule/utils/MailScheduleQuill";
import { bodyHelperText } from "@app/entrypoints/brokerage/features/mailschedule/utils/utils";
import { withDialog } from "@app/hoc/withDialog";
import { withRouter } from "@app/hoc/withRouter";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
class MailScheduleBoilerplateForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                boilerplate: null,
                pipelines: null,
                error: null,
                isSaving: false,
                isDeleting: false,
                isDuplicating: false,
            }
        });
        Object.defineProperty(this, "fields", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setContentToggle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        // @ts-expect-error
        Object.defineProperty(this, "checkWildcards", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                var _a;
                const matches = (value !== null && value !== void 0 ? value : "").match(/\$\'(.*?)\'/g);
                if (!matches) {
                    return null;
                }
                console.log("matches", matches);
                const errorFields = [];
                for (const match of matches) {
                    const fieldNameMatch = match.split("$'")[1].split("'")[0];
                    if (!((_a = this.fields) === null || _a === void 0 ? void 0 : _a.find((field) => fieldNameMatch === field.name))) {
                        errorFields.push(fieldNameMatch);
                    }
                }
                if (errorFields.length === 0) {
                    return null;
                }
                return `Field${errorFields.length > 1 ? "s" : ""} ${errorFields.join(", ")} not found in any pipeline. Make sure the field exists with the same capitalization and spacing as you've written here.`;
            }
        });
        Object.defineProperty(this, "save", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (onComplete) => () => {
                const { uid } = this.props;
                const { boilerplate } = this.state;
                this.setState({
                    isSaving: true,
                });
                const loadingSnackbar = enqueueSnackbar("Saving boilerplate...", { variant: "info", persist: true });
                const params = {
                    data: {
                        name: boilerplate.name,
                        pipeline: boilerplate.pipeline,
                        content: boilerplate.content,
                    },
                    onSuccess: (result) => {
                        this.setState({
                            isSaving: false,
                        });
                        if (onComplete) {
                            onComplete(result);
                        }
                        else {
                            this.props.navigate("/mailschedule/boilerplate");
                        }
                        enqueueSnackbar("Boilerplate has been saved.", { variant: "success" });
                        closeSnackbar(loadingSnackbar);
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                        this.setState({
                            isSaving: false,
                        });
                        closeSnackbar(loadingSnackbar);
                    },
                };
                if (uid) {
                    API.mailScheduleBoilerplate.update(uid, params);
                }
                else {
                    API.mailScheduleBoilerplate.create(params);
                }
            }
        });
        Object.defineProperty(this, "duplicate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.save((result) => {
                    this.setState({
                        isDuplicating: true,
                    });
                    API.mailScheduleBoilerplate.duplicate(result.uid, {
                        onSuccess: (result2) => {
                            this.setState({
                                isDuplicating: false,
                            });
                            this.props.navigate(`/mailschedule/boilerplate/${result2.uid}`);
                        },
                        onError: (error) => {
                            this.props.showErrorDialog(error);
                            this.setState({
                                isDuplicating: false,
                            });
                        },
                    });
                })();
            }
        });
        Object.defineProperty(this, "delete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { uid } = this.props;
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState({
                            isDeleting: true,
                        });
                        const loadingSnackbar = enqueueSnackbar("Deleting boilerplate...", {
                            variant: "info",
                            persist: true,
                        });
                        API.mailScheduleBoilerplate.delete(uid, {
                            onSuccess: () => {
                                this.props.navigate("/mailschedule/boilerplate");
                                enqueueSnackbar("Boilerplate has been deleted.", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                this.setState({
                                    isDeleting: false,
                                });
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { uid } = this.props;
                this.setState({
                    boilerplate: uid ? null : { name: "", content: "", pipeline: "" },
                    pipelines: null,
                    error: null,
                });
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                if (uid) {
                    API.mailScheduleBoilerplate.get(uid, {
                        onSuccess: (result) => {
                            this.setState({
                                boilerplate: result,
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                }
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "setName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.persist();
                this.setState((prevState) => ({
                    boilerplate: Object.assign(Object.assign({}, prevState.boilerplate), { name: e.target.value }),
                }));
            }
        });
        Object.defineProperty(this, "setPipeline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const pipeline = e.target.value;
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "Changing the pipeline may lead to unexpected behavior for certain merge fields, because the Streak keys may be different for fields in the new pipeline. For best results, delete and re-add all merge fields to ensure they're in sync with the new pipeline.",
                    confirmButtonText: "Change Pipeline",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            boilerplate: Object.assign(Object.assign({}, prevState.boilerplate), { pipeline }),
                        }));
                    },
                });
            }
        });
        Object.defineProperty(this, "setContent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (content) => {
                if (this.setContentToggle) {
                    console.log("setting content", content);
                    this.setState((prevState) => ({
                        boilerplate: Object.assign(Object.assign({}, prevState.boilerplate), { content }),
                    }));
                }
            }
        });
    }
    componentDidMount() {
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uid !== this.props.uid) {
            this.refresh();
        }
        if (this.state.pipelines && prevState.pipelines !== this.state.pipelines) {
            const fields = [];
            for (const pipeline of this.state.pipelines) {
                for (const field of pipeline.fields) {
                    if (fields.filter((f) => f.name === field.name).length === 0) {
                        fields.push(field);
                    }
                }
            }
            this.fields = fields.sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
        }
    }
    componentWillUnmount() {
        var _a;
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    get isValid() {
        var _a;
        const { boilerplate } = this.state;
        return !!((boilerplate === null || boilerplate === void 0 ? void 0 : boilerplate.name.trim()) && ((_a = boilerplate.pipeline) !== null && _a !== void 0 ? _a : "").trim() && boilerplate.content.trim());
    }
    get selectedPipeline() {
        var _a;
        const { boilerplate, pipelines } = this.state;
        if (pipelines && boilerplate) {
            return (_a = pipelines.find((p) => p.key === boilerplate.pipeline)) !== null && _a !== void 0 ? _a : null;
        }
        return null;
    }
    render() {
        var _a, _b, _c;
        const { uid } = this.props;
        const { boilerplate, error, isDeleting, isDuplicating, isSaving, pipelines } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!boilerplate || !pipelines) {
            return React.createElement(CoreLoading, null);
        }
        return (React.createElement(Box, { p: 4 },
            React.createElement(Paper, { elevation: 4, style: { borderTop: `5px solid ${AppConfig.themeColors.lightblue}` } },
                React.createElement(Box, { component: Grid, container: true, p: 2, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Name", value: boilerplate.name, onChange: this.setName })),
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, required: true, label: "Pipeline", value: (_a = boilerplate.pipeline) !== null && _a !== void 0 ? _a : "", onChange: this.setPipeline }, pipelines.map((pipeline) => (React.createElement(MenuItem, { key: pipeline.key, value: pipeline.key }, pipeline.name))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(MailScheduleQuill, { value: (_b = boilerplate.content) !== null && _b !== void 0 ? _b : "", onChange: this.setContent, fields: (_c = this.selectedPipeline) === null || _c === void 0 ? void 0 : _c.fields })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: bodyHelperText } })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(MailScheduleFormattersHelper, null)),
                    React.createElement(Grid, { item: true, container: true, xs: 12 },
                        React.createElement(Grid, { item: true, xs: uid ? 4 : 6 },
                            React.createElement(Button, { size: "small", color: "primary", variant: "contained", startIcon: React.createElement(Save, { fontSize: "small" }), onClick: this.save(), disabled: isDuplicating || isSaving || isDeleting || !this.isValid }, "Save")),
                        React.createElement(Grid, { item: true, xs: uid ? 4 : 6, style: { textAlign: uid ? "center" : "right" } },
                            React.createElement(Button, { size: "small", color: "inherit", variant: "contained", startIcon: React.createElement(FileCopy, { fontSize: "small" }), onClick: this.duplicate, disabled: isDuplicating || isSaving || isDeleting || !this.isValid }, "Save and Duplicate")),
                        uid && (React.createElement(Grid, { item: true, xs: 4, style: { textAlign: "right" } },
                            React.createElement(Button, { size: "small", color: "secondary", variant: "contained", startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: this.delete, disabled: isDuplicating || isSaving || isDeleting || !this.isValid }, "Delete"))))))));
    }
}
export default withDialog(withRouter(MailScheduleBoilerplateForm));
