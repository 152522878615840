import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BarChart, ChartsGrid } from "@mui/x-charts";
import AppConfig from "@app/util/AppConfig";
import ReportingPortalToolsTooltip from "./ReportingPortalToolsTooltip";
const ReportingPortalToolsContent = (props) => {
    const [tooltipData, setTooltipData] = React.useState(null);
    const { agentStats, contracts, psps, qfos, teams } = props;
    const openTooltip = (dataKeys, data) => (_, barItemIdentifier) => {
        const { dataIndex } = barItemIdentifier;
        if (dataIndex < data.length) {
            const barData = data[dataIndex];
            const tooltipData_ = {
                name: barData.name,
                value: barData.stat,
                contracts: barData.contracts,
                psps: barData.psps,
                qfos: barData.qfos,
                showContracts: dataKeys.includes("contracts"),
                showPSPs: dataKeys.includes("psps"),
                showQFOs: dataKeys.includes("qfos"),
            };
            setTooltipData(tooltipData_);
        }
    };
    const closeTooltip = () => {
        setTooltipData(null);
    };
    /**
     *
     * @param title Title for Graph
     * @param data Graph data
     * @param dataKeys Which keys were used for data (ex: ['psps', 'qfos'])
     * @returns
     */
    const createBarChart = (title, data, dataKeys) => {
        const graphData = data
            .sort((a, b) => {
            if (a.stat > b.stat)
                return -1;
            if (a.stat < b.stat)
                return 1;
            return 0;
        })
            .map((bar) => {
            return { name: bar.name, stat: bar.stat };
        });
        return (React.createElement(Grid, { item: true, md: 6, xs: 12, style: { padding: "8px 8px 24px 8px" } },
            React.createElement(Box, { fontFamily: AppConfig.fonts.primary, textAlign: "center" },
                React.createElement(Typography, { variant: "body1", display: "inline", style: { fontSize: "14px" } }, title)),
            React.createElement(BarChart, { dataset: graphData, layout: "horizontal", xAxis: [
                    {
                        label: " ",
                        labelStyle: { fontFamily: AppConfig.fonts.primary },
                        tickLabelStyle: { fontFamily: AppConfig.fonts.primary, fontSize: 10 },
                        hideTooltip: true,
                        id: "quantity",
                    },
                ], yAxis: [
                    {
                        scaleType: "band",
                        dataKey: "name",
                        label: " ",
                        tickLabelStyle: { fontFamily: AppConfig.fonts.primary, fontSize: 10 },
                        hideTooltip: true,
                    },
                ], topAxis: "quantity", series: [
                    {
                        dataKey: "stat",
                    },
                ], height: Math.max(30 * graphData.length, 220), disableAxisListener: true, grid: { vertical: true }, onItemClick: openTooltip(dataKeys, data), tooltip: { trigger: "none" }, slotProps: {
                    bar: {
                        fill: AppConfig.themeColors.blue,
                        style: {
                            fill: AppConfig.themeColors.blue,
                        },
                    },
                }, margin: { left: 100 } },
                React.createElement(ChartsGrid, { vertical: true, style: { stroke: "#ccc" } }))));
    };
    // format/calculate data
    const qfosPerAgentData = agentStats
        .filter((entry) => entry.qfos.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.qfos.length })));
    const pspsPerAgentData = agentStats
        .filter((entry) => entry.psps.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.psps.length })));
    const qfosPerContractData = agentStats
        .filter((entry) => entry.qfos.length > 0 && entry.contracts.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.qfos.length / entry.contracts.length })));
    const pspsPerContractData = agentStats
        .filter((entry) => entry.psps.length > 0 && entry.contracts.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.psps.length / entry.contracts.length })));
    // per location data
    const locationData = ["RDU", "AVL"].map((location) => {
        const locationPSPs = [];
        const locationContracts = [];
        const locationQFOs = [];
        agentStats.forEach((agent) => {
            if (agent.office && agent.office.toLowerCase().trim() === location.toLowerCase().trim()) {
                locationPSPs.push(...agent.psps);
                locationContracts.push(...agent.contracts);
                locationQFOs.push(...agent.qfos);
            }
        });
        return {
            uid: location,
            name: location,
            contracts: locationContracts,
            psps: locationPSPs,
            qfos: locationQFOs,
        };
    });
    const pspsPerContractPerLocationData = locationData
        .filter((entry) => entry.psps.length > 0 && entry.contracts.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.psps.length / entry.contracts.length })));
    const qfosPerContractPerLocationData = locationData
        .filter((entry) => entry.qfos.length > 0 && entry.contracts.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.qfos.length / entry.contracts.length })));
    // per team data
    const teamData = teams.map((team) => {
        const teamPSPs = [];
        const teamContracts = [];
        const teamQFOs = [];
        agentStats.forEach((agent) => {
            if (agent.team && agent.team.toLowerCase().trim() === team.toLowerCase().trim()) {
                teamPSPs.push(...agent.psps);
                teamContracts.push(...agent.contracts);
                teamQFOs.push(...agent.qfos);
            }
        });
        return {
            uid: team,
            name: team,
            contracts: teamContracts,
            psps: teamPSPs,
            qfos: teamQFOs,
        };
    });
    const pspsPerContractPerTeamData = teamData
        .filter((entry) => entry.psps.length > 0 && entry.contracts.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.psps.length / entry.contracts.length })));
    const qfosPerContractPerTeamData = teamData
        .filter((entry) => entry.qfos.length > 0 && entry.contracts.length > 0)
        .map((entry) => (Object.assign(Object.assign({}, entry), { stat: entry.qfos.length / entry.contracts.length })));
    // totals
    const totalsData = [
        {
            uid: "1",
            name: "Contracts",
            contracts,
            qfos,
            psps,
            stat: contracts.length,
        },
        {
            uid: "2",
            name: "PSPs",
            contracts,
            qfos,
            psps,
            stat: psps.length,
        },
        {
            uid: "3",
            name: "QFOs",
            contracts,
            qfos,
            psps,
            stat: qfos.length,
        },
    ];
    const totalPerContractData = [
        {
            uid: "1",
            name: "PSPs Per Contract",
            contracts,
            qfos,
            psps,
            stat: contracts.length > 0 ? psps.length / contracts.length : 0,
        },
        {
            uid: "2",
            name: "QFOs per Contract",
            contracts,
            qfos,
            psps,
            stat: contracts.length > 0 ? qfos.length / contracts.length : 0,
        },
    ];
    const barCharts = [
        { title: "Totals", data: totalsData, dataKeys: ["contracts, qfos, psps"] },
        { title: "Totals Per Contract", data: totalPerContractData, dataKeys: ["contracts, qfos", "psps"] },
        { title: "QFOs Per Agent", data: qfosPerAgentData, dataKeys: ["qfos"] },
        { title: "PSPs Per Agent", data: pspsPerAgentData, dataKeys: ["psps"] },
        { title: "QFOs Per Contract", data: qfosPerContractData, dataKeys: ["qfos", "contracts"] },
        { title: "PSPs Per Contract", data: pspsPerContractData, dataKeys: ["psps", "contracts"] },
        {
            title: "QFOs Per Contract Per Location",
            data: qfosPerContractPerLocationData,
            dataKeys: ["qfos", "contracts"],
        },
        {
            title: "PSPs Per Contract Per Location",
            data: pspsPerContractPerLocationData,
            dataKeys: ["psps", "contracts"],
        },
        { title: "QFOs Per Contract Per Team", data: qfosPerContractPerTeamData, dataKeys: ["qfos", "contracts"] },
        { title: "PSPs Per Contract Per Team", data: pspsPerContractPerTeamData, dataKeys: ["psps", "contracts"] },
    ];
    return (React.createElement(Grid, { container: true, style: { paddingTop: "16px" } },
        React.createElement(Grid, { item: true, xs: tooltipData ? 9 : 12, style: { height: "calc(100vh - 210px)", overflowY: "auto" } },
            React.createElement(Grid, { container: true, item: true, xs: 12 }, barCharts.map((chart) => createBarChart(chart.title, chart.data, chart.dataKeys)))),
        tooltipData && (React.createElement(Grid, { container: true, item: true, xs: 3, style: {
                borderLeft: "1px solid rgb(204, 204, 204)",
                paddingLeft: "10px",
                overflowY: "auto",
                height: "calc(100vh - 210px)",
            } },
            React.createElement(ReportingPortalToolsTooltip, { data: tooltipData, closeTooltip: closeTooltip })))));
};
export default ReportingPortalToolsContent;
