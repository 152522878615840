import * as React from "react";
import { Grid, Typography } from "@mui/material";
import dayjs_ from "@app/common/dayjs";
import { getFullNameMemoized } from "@app/util/Utils";
const NoteChangeItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { item } = props;
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 9 }, item.is_initial_value ? (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, getFullNameMemoized((_b = (_a = item.userstamp) === null || _a === void 0 ? void 0 : _a.first_name) !== null && _b !== void 0 ? _b : null, (_d = (_c = item.userstamp) === null || _c === void 0 ? void 0 : _c.last_name) !== null && _d !== void 0 ? _d : null) ||
                    "Anonymous user or automation")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, "set the notes to")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", fontStyle: "italic" }, (_e = item.new_value) !== null && _e !== void 0 ? _e : "(blank)")))) : (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, getFullNameMemoized((_g = (_f = item.userstamp) === null || _f === void 0 ? void 0 : _f.first_name) !== null && _g !== void 0 ? _g : null, (_j = (_h = item.userstamp) === null || _h === void 0 ? void 0 : _h.last_name) !== null && _j !== void 0 ? _j : null) ||
                    "Anonymous user or automation")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, "changed the notes from")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", fontStyle: "italic" }, (_k = item.previous_value) !== null && _k !== void 0 ? _k : "(blank)")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, "to")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", fontStyle: "italic" }, (_l = item.new_value) !== null && _l !== void 0 ? _l : "(blank)"))))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Typography, { style: {
                    float: "right",
                    color: "#ccc",
                }, variant: "caption" }, dayjs_(item.timestamp).isValid()
                ? dayjs_(new Date(item.timestamp)).format("M/D/YYYY h:mm A")
                : item.timestamp))));
};
export default NoteChangeItem;
