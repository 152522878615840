import * as React from "react";
import { PhoneOutlined } from "@mui/icons-material";
import { Divider, Button, Typography, Paper, Box, Collapse, Grid2 } from "@mui/material";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import RouterLink from "@app/common/RouterLink";
import { useGetCallInfo } from "@app/orval/api/twilio";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import useSelectedContactForPhoneNumber from "../twilio-sms/useSelectedContactForPhoneNumber";
dayjs.extend(durationPlugin);
const TwilioCallWidget = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const { call } = props;
    const [showMore, setShowMore] = React.useState(false);
    const toggleShowMore = React.useCallback(() => {
        setShowMore((prev) => !prev);
    }, []);
    const start = React.useRef(new Date());
    const [duration, setDuration] = React.useState(null);
    const updateDurationTimeout = React.useRef(null);
    const updateDuration = React.useCallback(() => {
        if (updateDurationTimeout.current) {
            window.clearTimeout(updateDurationTimeout.current);
        }
        setDuration(new Date().getTime() - start.current.getTime());
        updateDurationTimeout.current = window.setTimeout(updateDuration, 1000);
    }, []);
    React.useEffect(() => {
        updateDuration();
        return () => {
            if (updateDurationTimeout.current) {
                window.clearInterval(updateDurationTimeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const listTwilioPhonesApi = useListCachedTwilioPhones({}, INFINITE_CACHE_PARAMS);
    const twilioPhones = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listTwilioPhonesApi.data]);
    const twilioPhonesByE164 = React.useMemo(() => {
        return twilioPhones === null || twilioPhones === void 0 ? void 0 : twilioPhones.reduce((acc, phone) => {
            acc[phone.phone_number] = phone;
            return acc;
        }, {});
    }, [twilioPhones]);
    const callSid = call.parameters["CallSid"];
    const getCallInfoApi = useGetCallInfo(callSid, { query: { enabled: false } });
    const callInfo = (_b = (_a = getCallInfoApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    // delay the API call... it doesn't seem like Twilio
    // immediately has a record of the Call SID
    React.useEffect(() => {
        let timeout = null;
        if (callSid) {
            timeout = window.setTimeout(() => {
                getCallInfoApi.refetch();
            }, 1000);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [callSid, getCallInfoApi]);
    const fromE164 = callInfo === null || callInfo === void 0 ? void 0 : callInfo.from;
    const fromFriendlyName = (_d = (_c = twilioPhonesByE164 === null || twilioPhonesByE164 === void 0 ? void 0 : twilioPhonesByE164[fromE164 !== null && fromE164 !== void 0 ? fromE164 : ""]) === null || _c === void 0 ? void 0 : _c.friendly_name) !== null && _d !== void 0 ? _d : fromE164;
    const callerName = call.customParameters.get("Caller Name");
    const toE164 = callInfo === null || callInfo === void 0 ? void 0 : callInfo.to;
    const toContactInfo = useSelectedContactForPhoneNumber(toE164 !== null && toE164 !== void 0 ? toE164 : "");
    const toFriendlyName = (_h = (_f = (_e = toContactInfo.user) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : (_g = toContactInfo.recruit) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : (_j = toContactInfo.contact) === null || _j === void 0 ? void 0 : _j.name;
    const toDistribution = call.customParameters.get("Distribution");
    const toComponent = (React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } },
        toDistribution || toFriendlyName || call.parameters["To"] || toE164,
        toFriendlyName && ` (${call.parameters["To"] || toE164})`));
    const hangup = React.useCallback(() => {
        call.disconnect();
    }, [call]);
    console.log({ callInfo });
    return (React.createElement(Paper, { style: { minWidth: "350px", maxWidth: "360px", pointerEvents: "auto" }, elevation: 4 },
        React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 2 },
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { direction: "row", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(PhoneOutlined, { fontSize: "large" })),
                        React.createElement(Grid2, null,
                            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                                React.createElement(Grid2, { container: true, spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body1" }, "From")),
                                    React.createElement(Grid2, { style: { flex: 1 } },
                                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, callerName || fromFriendlyName || call.parameters["From"]))),
                                React.createElement(Grid2, { container: true, spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body1" }, "To")),
                                    React.createElement(Grid2, { style: { flex: 1 } }, ((_l = (_k = toContactInfo.recruit) !== null && _k !== void 0 ? _k : toContactInfo.contact) !== null && _l !== void 0 ? _l : toContactInfo.user) ? (React.createElement(RouterLink, { to: `/boxes/${(_s = (_q = (_o = (_m = toContactInfo.recruit) === null || _m === void 0 ? void 0 : _m.uid) !== null && _o !== void 0 ? _o : (_p = toContactInfo.contact) === null || _p === void 0 ? void 0 : _p.uid) !== null && _q !== void 0 ? _q : (_r = toContactInfo.user) === null || _r === void 0 ? void 0 : _r.uid) !== null && _s !== void 0 ? _s : ""}` }, toComponent)) : (toComponent))),
                                duration && (React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "body2", style: { color: "#888" } }, dayjs.duration(duration).format("m:ss")))),
                                React.createElement(Grid2, null,
                                    React.createElement(Grid2, { direction: "column", spacing: 1, wrap: "nowrap" },
                                        React.createElement(Grid2, null,
                                            React.createElement(Button, { variant: "text", onClick: toggleShowMore }, showMore ? "Show less" : "Show more")),
                                        React.createElement(Grid2, null,
                                            React.createElement(Collapse, { in: showMore },
                                                Object.keys(call.parameters).length > 0 && (React.createElement("pre", { style: { color: "#888" } }, JSON.stringify(call.parameters, null, 2))),
                                                Object.keys(call.customParameters).length > 0 && (React.createElement("pre", { style: { color: "#888" } }, JSON.stringify(call.customParameters, null, 2))))))))))),
                React.createElement(Grid2, null,
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { style: { textAlign: "center" } },
                    React.createElement(Button, { variant: "text", onClick: hangup, color: "error" }, "Hang up"))))));
};
export default TwilioCallWidget;
