import * as React from "react";
import { Add } from "@mui/icons-material";
import { Grid2, IconButton, Tooltip, Typography } from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreLoading from "@app/common/CoreLoading";
import MAtrixDraggable from "@app/entrypoints/brokerage/features/apps/matrix/components/MAtrixDraggable";
import OfferFieldLabel from "./OfferFieldLabel";
const MATRIX_FIELDS = [
    { name: "Seller Name" },
    { name: "Listing Percent", label: "Listing %", multiplyBy100: true },
    { name: "Selling Percent", label: "Selling %", multiplyBy100: true },
    { name: "Annual Taxes" },
    { name: "First Mortgage" },
    { name: "Second Mortgage" },
];
const OFFER_FIELDS = [
    { name: "Purchase Price" },
    { name: "Due Diligence Fee" },
    { name: "Earnest Money Deposit" },
    { name: "Due Diligence Period" },
    { name: "Closing Date" },
    { name: "Personal Property" },
    { name: "Financing" },
    { name: "Financing Type" },
    { name: "Addendums" },
    { name: "Seller Paid Closing Costs", label: "Seller-paid closing costs:" },
    { name: "Home Warranty" },
    { name: "Needs to Sell", label: "Needs to sell/lease another" },
    { name: "Buyer Name" },
    { name: "Agent Name" },
];
const MORTGAGE_FIELDS = [
    { name: "First Mortgage", label: "1st Mortgage Payoff (estimated)" },
    { name: "Second Mortgage", label: "2nd Mortgage Payoff (estimated)" },
];
const CLOSING_COST_FIELDS = [
    { name: "Attorney Fees", label: "Attorney Fees for document preparation, etc" },
    { name: "Brokerage Fee" },
    { name: "Recording Fees", label: "Recording Fees (estimate)" },
    { name: "Revenue Stamps", label: "Revenue Stamps ($2.00 per $1,000)" },
    { name: "Listing Firm Commission", label: "Listing Firm & Listing Agent Brokerage Commission" },
    { name: "Selling Firm Commission", label: "Selling Firm & Selling Agent Brokerage Commission" },
    {
        name: "Repairs",
        label: "***Repairs from Home Inspection***",
        labelStyle: { fontWeight: "bold", fontStyle: "italic" },
    },
    {
        name: "Closing Expenses",
        label: "***Closing Expenses Paid for Buyer***",
        labelStyle: { fontWeight: "bold", fontStyle: "italic" },
    },
    { name: "Home Warranty Cost", label: "Home Warranty", labelStyle: { fontStyle: "italic" } },
    { name: "VA Required Fees" },
    {
        name: "Total Estimated Seller Costs",
        label: "TOTAL ESTIMATED SELLER COSTS",
        labelStyle: { fontWeight: "bold", textAlign: "center" },
    },
    {
        name: "Estimated Net Proceeds",
        label: "ESTIMATED NET PROCEEDS TO/FROM SELLER",
        labelStyle: { fontWeight: "bold", textAlign: "center" },
    },
];
const OfferTab = (props) => {
    const { addOffer, deleteOffer, isAddingOffer, matrixFields, matrixSchema, offerSchema, offers, onMAtrixFieldSaveBegin, onMAtrixFieldSaveComplete, reorderOffers, uid, updateOffer, } = props;
    /** list of offer uids used for draggable container */
    const offerUids = React.useMemo(() => {
        return offers.map((offer) => ({
            id: offer.uid,
        }));
    }, [offers]);
    const onOffersReorder = React.useCallback((e) => {
        var _a;
        const activeId = e.active.id;
        const overId = (_a = e.over) === null || _a === void 0 ? void 0 : _a.id;
        if (activeId !== overId) {
            const oldIndex = offers.findIndex((offer) => offer.uid === activeId);
            const newIndex = offers.findIndex((offer) => offer.uid === overId);
            if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
                const offerToMove = offers[oldIndex];
                offers.splice(oldIndex, 1);
                offers.splice(newIndex, 0, offerToMove);
                reorderOffers(offers.map((offer) => offer.uid));
            }
            else {
                console.warn("Could not reorder offers: oldIndex:", oldIndex, "newIndex:", newIndex);
            }
        }
    }, [offers, reorderOffers]);
    if (!offerSchema) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, spacing: 2, style: { height: "100%", width: "100%", margin: 0 } },
        React.createElement(Grid2, { container: true, size: 12, spacing: 2 }, MATRIX_FIELDS.map((field) => {
            var _a, _b;
            return (React.createElement(Grid2, { key: field.name, size: { xs: 12, sm: 4, md: 3, lg: 2 } },
                React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, label: (_a = field.label) !== null && _a !== void 0 ? _a : field.name, field: matrixSchema[field.name], value: matrixFields[field.name], apiEndpoint: `/api/psps/${uid}`, multiplyBy100: (_b = field.multiplyBy100) !== null && _b !== void 0 ? _b : undefined, onSaveBegin: onMAtrixFieldSaveBegin(field.name), onSuccess: onMAtrixFieldSaveComplete })));
        })),
        React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
            React.createElement(Grid2, { container: true, size: { xs: 6, lg: 4, xl: 3 }, spacing: 2 },
                React.createElement(Grid2, { container: true, size: 12 },
                    React.createElement(Grid2, { container: true, size: 12, style: { textAlign: "center" } },
                        React.createElement(Grid2, { style: { flexGrow: 1 } },
                            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Offer")),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: "Add offer" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { size: "small", onClick: addOffer, disabled: isAddingOffer },
                                        React.createElement(Add, { fontSize: "small" })))))),
                    OFFER_FIELDS.map((field) => {
                        var _a;
                        return (React.createElement(OfferFieldLabel, { key: field.name, label: (_a = field.label) !== null && _a !== void 0 ? _a : field.name, labelStyle: field.labelStyle }));
                    })),
                React.createElement(Grid2, { container: true, size: 12 },
                    React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "MORTGAGES/LIENS")),
                    MORTGAGE_FIELDS.map((field) => {
                        var _a;
                        return (React.createElement(OfferFieldLabel, { key: field.name, label: (_a = field.label) !== null && _a !== void 0 ? _a : field.name, labelStyle: field.labelStyle }));
                    })),
                React.createElement(Grid2, { container: true, size: 12 },
                    React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "CLOSING COSTS")),
                    CLOSING_COST_FIELDS.map((field) => {
                        var _a;
                        return (React.createElement(OfferFieldLabel, { key: field.name, label: (_a = field.label) !== null && _a !== void 0 ? _a : field.name, labelStyle: field.labelStyle }));
                    }))),
            React.createElement(Grid2, { container: true, size: { xs: 6, lg: 8, xl: 9 }, spacing: 1 },
                React.createElement(Grid2, { container: true, style: {
                        whiteSpace: "nowrap",
                        width: "100%",
                        display: "block",
                        overflowX: "auto",
                    } },
                    React.createElement(DndContext, { onDragEnd: onOffersReorder, collisionDetection: closestCenter, modifiers: [restrictToHorizontalAxis] },
                        React.createElement(SortableContext, { items: offerUids, strategy: horizontalListSortingStrategy }, offers.map((offer, indx) => (React.createElement(MAtrixDraggable, { key: `${offer.uid}_draggable_key`, uid: offer.uid, name: `Offer ${indx + 1}`, onDelete: deleteOffer },
                            React.createElement(Grid2, { container: true, size: 12 }, OFFER_FIELDS.map((field) => {
                                const isFormulaField = offerSchema[field.name]["field_type"] === "formula";
                                return (React.createElement(Grid2, { key: field.name, size: 12, style: { height: "30px" } },
                                    React.createElement(DashAutoSaveField, { variant: "standard", className: "offer-field", fullWidth: true, field: offerSchema[field.name], value: isFormulaField ? offer.fields[field.name].value : offer.fields[field.name], disabled: isFormulaField, apiEndpoint: `/api/offers/${offer.uid}`, onSuccess: updateOffer })));
                            })),
                            React.createElement(Grid2, { container: true, size: 12 },
                                React.createElement(Grid2, { container: true, size: 12, style: { height: "20px" } }),
                                MORTGAGE_FIELDS.map((field) => {
                                    const isFormulaField = offerSchema[field.name]["field_type"] === "formula";
                                    return (React.createElement(Grid2, { key: field.name, size: 12, style: { height: "30px" } },
                                        React.createElement(DashAutoSaveField, { variant: "standard", className: "offer-field", fullWidth: true, field: offerSchema[field.name], value: isFormulaField ? offer.fields[field.name].value : offer.fields[field.name], disabled: isFormulaField, apiEndpoint: `/api/offers/${offer.uid}`, onSuccess: updateOffer })));
                                })),
                            React.createElement(Grid2, { container: true, size: 12 },
                                React.createElement(Grid2, { container: true, size: 12, style: { height: "20px" } }),
                                CLOSING_COST_FIELDS.map((field) => {
                                    const isFormulaField = offerSchema[field.name]["field_type"] === "formula";
                                    return (React.createElement(Grid2, { key: field.name, size: 12, style: { height: "30px" } },
                                        React.createElement(DashAutoSaveField, { variant: "standard", className: "offer-field", fullWidth: true, field: offerSchema[field.name], value: isFormulaField ? offer.fields[field.name].value : offer.fields[field.name], disabled: isFormulaField, apiEndpoint: `/api/offers/${offer.uid}`, onSuccess: updateOffer })));
                                }))))))))))));
};
export default OfferTab;
