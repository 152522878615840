import * as React from "react";
import { Edit } from "@mui/icons-material";
import { Button, Grid2 } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import ActionDialog from "@app/common/ActionDialog";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import { useGetContact } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
const overridableFieldNames = ["First Name", "Last Name", "Email", "Phone", "Location", "Company"];
const mapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Phone: "phone",
    Location: "address",
    Company: "company",
};
const VendorInfoDialog = (props) => {
    var _a, _b, _c, _d, _e;
    const { DialogProps, getBuyerLeadApi, getSellerLeadApi, onCancel, onConfirm, vendor } = props;
    const queryClient = useQueryClient();
    const session = useSession();
    const contact = useGetContact(vendor.contact.uid, REFETCH_CACHE_PARAMS);
    const contactData = (_b = (_a = contact.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listContactFieldsApi = useListFields({ entity_type: "Contact" }, INFINITE_CACHE_PARAMS);
    const contactFields = (_d = (_c = listContactFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const contactFieldsByName = React.useMemo(() => {
        if (!contactFields) {
            return {};
        }
        return contactFields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [contactFields]);
    const apiEndpoint = React.useMemo(() => {
        return `/api/contacts/${vendor.contact.uid}`;
    }, [vendor.contact.uid]);
    const setVendorInfo = React.useCallback((fieldName) => (value) => {
        if (getBuyerLeadApi) {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
                return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { vendors: old.data.vendors.map((c) => {
                            if (c.contact.uid === vendor.contact.uid) {
                                return Object.assign(Object.assign({}, c), { contact: Object.assign(Object.assign({}, c.contact), { [mapping[fieldName]]: (value === null || value === void 0 ? void 0 : value.trim()) || (contactData === null || contactData === void 0 ? void 0 : contactData.fields[fieldName]) }) });
                            }
                            return c;
                        }) }) });
            });
        }
        if (getSellerLeadApi) {
            queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
                return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { vendors: old.data.vendors.map((c) => {
                            if (c.contact.uid === vendor.contact.uid) {
                                return Object.assign(Object.assign({}, c), { contact: Object.assign(Object.assign({}, c.contact), { [mapping[fieldName]]: (value === null || value === void 0 ? void 0 : value.trim()) || (contactData === null || contactData === void 0 ? void 0 : contactData.fields[fieldName]) }) });
                            }
                            return c;
                        }) }) });
            });
        }
    }, [vendor.contact.uid, contactData === null || contactData === void 0 ? void 0 : contactData.fields, getBuyerLeadApi, getSellerLeadApi, queryClient]);
    const canAccessContactsPage = (_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole(["Superadmin", "Admin"]);
    return (React.createElement(ActionDialog, { title: `Third Party Information - ${vendor.contact.first_name} ${vendor.contact.last_name}`, DialogProps: DialogProps, confirmButtonText: "OK", onCancel: onCancel, onConfirm: onConfirm },
        React.createElement(Grid2, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
            contactFields ? (React.createElement(Grid2, { container: true, spacing: 2 }, overridableFieldNames.map((f) => {
                var _a, _b;
                return (React.createElement(Grid2, { key: f, size: { xs: 12, md: 6 } },
                    React.createElement(DashAutoSaveField, { size: "small", variant: "standard", label: f, fullWidth: true, field: contactFieldsByName[f], apiEndpoint: apiEndpoint, value: (_b = (_a = vendor.contact[mapping[f]]) !== null && _a !== void 0 ? _a : contactData === null || contactData === void 0 ? void 0 : contactData.fields[f]) !== null && _b !== void 0 ? _b : "", placeholder: contactData === null || contactData === void 0 ? void 0 : contactData.fields[f], InputLabelProps: {
                            shrink: (contactData === null || contactData === void 0 ? void 0 : contactData.fields[f]) ? true : undefined,
                        }, onSaveBegin: setVendorInfo(f), hasPicker: contactFieldsByName[f].field_type === "date" ? true : undefined })));
            }))) : (React.createElement(Grid2, null,
                React.createElement(CoreLoading, null))),
            canAccessContactsPage && (React.createElement(Grid2, null,
                React.createElement(Button, { component: RouterLink, to: `/contacts/${vendor.contact.uid}`, variant: "outlined", startIcon: React.createElement(Edit, null), color: "primary" }, "Edit via Contacts page"))))));
};
export default VendorInfoDialog;
