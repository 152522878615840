import * as React from "react";
import { Box, Button, CircularProgress, Grid2, Paper, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { checkScriptStatus, useListScripts, useRunScript } from "@app/orval/api/utilities-scripts";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const Scripts = () => {
    var _a, _b;
    const listScriptsApi = useListScripts(REFETCH_CACHE_PARAMS);
    const scripts = (_b = (_a = listScriptsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const runScriptApi = useRunScript();
    const [scriptStatuses, setScriptStatues] = React.useState({});
    const timeouts = React.useRef({});
    const getScriptExecutionStatus = React.useCallback((uid) => () => {
        checkScriptStatus(uid)
            .then((result) => {
            if (result.data.status !== "running") {
                window.clearTimeout(timeouts.current[uid]);
            }
            else {
                timeouts.current[uid] = window.setTimeout(getScriptExecutionStatus(uid), 3000);
            }
            setScriptStatues((prev) => {
                var _a, _b;
                const functionName = Object.keys(prev).find((fn) => prev[fn].uid === uid);
                return Object.assign(Object.assign({}, prev), { [functionName]: {
                        uid,
                        status: result.data.status,
                        execution_started: result.data.execution_started,
                        execution_ended: (_a = result.data.execution_ended) !== null && _a !== void 0 ? _a : undefined,
                        response_content: (_b = result.data.response_content) !== null && _b !== void 0 ? _b : undefined,
                    } });
            });
        })
            .catch((err) => {
            console.error(err);
            timeouts.current[uid] = window.setTimeout(getScriptExecutionStatus(uid), 3000);
        });
    }, []);
    const runScript = React.useCallback((scriptName) => () => {
        setScriptStatues((prev) => (Object.assign(Object.assign({}, prev), { [scriptName]: {
                status: "running",
            } })));
        runScriptApi
            .mutateAsync({ scriptName })
            .then((result) => {
            setScriptStatues((prev) => (Object.assign(Object.assign({}, prev), { [scriptName]: {
                    uid: result.data.uid,
                    status: "running",
                } })));
            timeouts.current[result.data.uid] = window.setTimeout(getScriptExecutionStatus(result.data.uid), 3000);
        })
            .catch(enqueueErrorSnackbar);
    }, [getScriptExecutionStatus, runScriptApi]);
    if (listScriptsApi.error) {
        return React.createElement(CoreError, { error: listScriptsApi.error });
    }
    if (!scripts) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Box, { component: Grid2, container: true, p: 2, spacing: 4, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body2" }, "Use this page to manually run background scripts."),
            React.createElement(Typography, { variant: "body2" }, "Keep in mind that certain scripts may take a while to run (up to 30 mins)."),
            React.createElement(Typography, { variant: "body2" }, "When run manually, scripts are not subject to all of the same quotas that Google enforces when scripts are triggered automatically in the background.")),
        React.createElement(Grid2, { container: true, spacing: 4, direction: "column" }, scripts.map((script) => {
            var _a;
            return (React.createElement(Grid2, { key: script.function_name },
                React.createElement(Paper, { elevation: 4 },
                    React.createElement(Box, { component: Grid2, container: true, p: 2, spacing: 2 },
                        React.createElement(Grid2, { size: {
                                xs: 12,
                                md: script.docstring ? 6 : 12,
                                lg: script.docstring ? 4 : 12,
                                xl: script.docstring ? 3 : 12,
                            } },
                            React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                                React.createElement(Grid2, null,
                                    React.createElement("pre", { style: { fontSize: "0.875rem" } }, script.function_name.replace("_automatic", ""))),
                                script.frequency && (React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "body2" },
                                        "Runs automatically: ",
                                        script.frequency))),
                                React.createElement(Grid2, { style: { textAlign: "center" } },
                                    React.createElement(Button, { color: "primary", variant: "contained", disabled: scriptStatuses[script.function_name] &&
                                            scriptStatuses[script.function_name].status === "running", onClick: runScript(script.function_name), endIcon: scriptStatuses[script.function_name] &&
                                            scriptStatuses[script.function_name].status === "running" ? (React.createElement(CircularProgress, { size: 20 })) : undefined }, "Run")),
                                scriptStatuses[script.function_name] &&
                                    scriptStatuses[script.function_name].status !== "running" && (React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "caption", display: "block" },
                                        "Status: ",
                                        scriptStatuses[script.function_name].status),
                                    React.createElement(Typography, { variant: "caption", display: "block" },
                                        "Runtime:",
                                        " ",
                                        (new Date(scriptStatuses[script.function_name].execution_ended).getTime() -
                                            new Date(scriptStatuses[script.function_name].execution_started).getTime()) /
                                            1000,
                                        " ",
                                        "seconds"),
                                    React.createElement(Typography, { variant: "caption", display: "block" },
                                        "Response:",
                                        " ", (_a = scriptStatuses[script.function_name].response_content) !== null && _a !== void 0 ? _a : "The script finished successfully but returned no content."))))),
                        script.docstring && (React.createElement(Grid2, { size: { xs: 12, md: 6, lg: 8, xl: 9 } },
                            React.createElement(ReactMarkdown, null, script.docstring)))))));
        }))));
};
export default Scripts;
