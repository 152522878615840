import * as React from "react";
import { Info } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import dayjs from "dayjs";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import { countContractsByMonth } from "@app/orval/api/agent-dashboard";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const ContractsHistory = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { selectedUserUids } = agentDashboardFilters;
    const queryParams = pickBy({ agent_uids: [...selectedUserUids] }, (val) => val != null);
    const countContractsByMonthApi = usePostQuery(countContractsByMonth, queryParams);
    const contractsByMonth = (_a = countContractsByMonthApi.data) === null || _a === void 0 ? void 0 : _a.data;
    const years = [...new Set((contractsByMonth !== null && contractsByMonth !== void 0 ? contractsByMonth : []).map((item) => item.year_month.split("-")[0]))]
        .filter((item) => parseFloat(item) <= new Date().getFullYear())
        .sort()
        .reverse()
        .slice(0, 3);
    const lineChartData = years.map((year) => {
        return {
            year,
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
                .filter((monthNum) => {
                return `${year}-${numeral(monthNum + 1).format("00")}` <= dayjs().format("YYYY-MM");
            })
                .map((month) => {
                var _a;
                const monthData = (contractsByMonth !== null && contractsByMonth !== void 0 ? contractsByMonth : []).find((item) => item.year_month === `${year}-${numeral(month + 1).format("00")}`);
                return (_a = monthData === null || monthData === void 0 ? void 0 : monthData.value) !== null && _a !== void 0 ? _a : 0;
            }),
        };
    });
    return (React.createElement(Box, { p: 2 },
        React.createElement(Paper, { elevation: 4 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid, { item: true, container: true, spacing: 1, alignItems: "center" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, "Contracts by Month")),
                        React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Number of closed contracts (by Effective Date) each month. Closings that occur after the current date are not included. The Period filter has no effect on this graph.") },
                                React.createElement(Info, null)))),
                    React.createElement(Grid, { item: true },
                        countContractsByMonthApi.isFetching && React.createElement(CircularProgress, { size: 20 }),
                        countContractsByMonthApi.error && React.createElement(CoreError, { error: countContractsByMonthApi.error }),
                        countContractsByMonthApi.isFetched && (React.createElement(LineChart, { xAxis: [
                                {
                                    id: "month",
                                    data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                    label: "Month",
                                    scaleType: "point",
                                },
                            ], yAxis: [
                                {
                                    id: "num_contracts",
                                    label: "# Contracts",
                                    min: 0,
                                },
                            ], series: lineChartData.map((item) => ({ data: item.data, label: item.year })), height: 200 }))))))));
};
export default ContractsHistory;
