import * as React from "react";
import { Add } from "@mui/icons-material";
import { Alert, IconButton, List, Tooltip, Typography } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import slackIcon from "@app/assets/img/slack.png";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useUpdateSlackAccess } from "@app/orval/api/slack-access";
import { useListSlackConversationsForUser } from "@app/orval/api/users";
import SlackChannelDialog from "./SlackChannelDialog";
import SlackChannelListItem from "./SlackChannelListItem";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const SlackChannels = (props) => {
    var _a, _b, _c;
    const { userUid } = props;
    const queryClient = useQueryClient();
    const updateSlackAccessApi = useUpdateSlackAccess();
    const getSlackChannelsForUserApi = useListSlackConversationsForUser(userUid, { types: "private_channel" });
    const slackChannels = (_b = (_a = getSlackChannelsForUserApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const openDialog = React.useCallback(() => {
        setIsDialogOpen(true);
    }, [setIsDialogOpen]);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);
    const removeUserFromChannel = React.useCallback((channelId) => {
        updateSlackAccessApi
            .mutateAsync({
            data: {
                channel_id: channelId,
                user_uid: userUid,
                has_access: false,
            },
        })
            .then(() => {
            queryClient.setQueryData(getSlackChannelsForUserApi.queryKey, (prev) => {
                if (!prev)
                    return prev;
                return Object.assign(Object.assign({}, prev), { data: prev.data.filter((channel) => channel.id !== channelId) });
            });
            enqueueSnackbar("Removed user from channel", { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [getSlackChannelsForUserApi.queryKey, queryClient, updateSlackAccessApi, userUid]);
    const addUserToChannel = React.useCallback((channel) => {
        closeDialog();
        updateSlackAccessApi
            .mutateAsync({
            data: {
                channel_id: channel.id,
                user_uid: userUid,
                has_access: true,
            },
        })
            .then(() => {
            queryClient.setQueryData(getSlackChannelsForUserApi.queryKey, (prev) => {
                if (!prev)
                    return prev;
                return Object.assign(Object.assign({}, prev), { data: [...prev.data, channel] });
            });
            enqueueSnackbar(`Added user to channel ${channel.name}`, { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [closeDialog, getSlackChannelsForUserApi.queryKey, queryClient, updateSlackAccessApi, userUid]);
    const slackChannelsSorted = React.useMemo(() => {
        var _a;
        return ((_a = slackChannels === null || slackChannels === void 0 ? void 0 : slackChannels.sort((a, b) => {
            var _a, _b;
            const aName = (_a = a.name) !== null && _a !== void 0 ? _a : "";
            const bName = (_b = b.name) !== null && _b !== void 0 ? _b : "";
            if (aName < bName)
                return -1;
            if (aName > bName)
                return 1;
            return 0;
        })) !== null && _a !== void 0 ? _a : null);
    }, [slackChannels]);
    const isLoading = slackChannels == null;
    const noChannels = slackChannels && slackChannels.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement("img", { src: slackIcon, height: 20, alt: "Slack" }), title: "Slack Channels", count: (_c = slackChannels === null || slackChannels === void 0 ? void 0 : slackChannels.length) !== null && _c !== void 0 ? _c : undefined, toolbar: React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add user to channel") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: openDialog, disabled: slackChannels == null },
                        React.createElement(Add, { fontSize: "small" })))), isCollapsedInitially: true },
            getSlackChannelsForUserApi.error && (React.createElement(Alert, { severity: "error" }, JSON.stringify(format(getSlackChannelsForUserApi.error)))),
            isLoading && !getSlackChannelsForUserApi.error && React.createElement(CoreLoading, null),
            noChannels && React.createElement(Typography, { variant: "body2" }, "No interviews"),
            slackChannels && slackChannels.length > 0 && (React.createElement(List, { dense: true }, (slackChannelsSorted !== null && slackChannelsSorted !== void 0 ? slackChannelsSorted : []).map((channel) => (React.createElement(SlackChannelListItem, { key: channel.id, channel: channel, onRemoveChannel: removeUserFromChannel })))))),
        isDialogOpen && (React.createElement(SlackChannelDialog, { onClose: closeDialog, onConfirm: addUserToChannel, DialogProps: { open: true } }))));
};
export default SlackChannels;
