import * as React from "react";
import { Avatar, Box, Divider, Grid2, Hidden, Paper, Tab, Tabs } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isValidPhoneNumber } from "libphonenumber-js";
import pickBy from "lodash/pickBy";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useFileUploads from "@app/hooks/useFileUploads";
import useStateCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListMessagesForRecruit } from "@app/orval/api/clients";
import { useAddComment, useDeleteComment, useUpdateComment, listComments } from "@app/orval/api/comments";
import { useListCallsForRecruit } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListFieldSections } from "@app/orval/api/field-sections";
import { searchFiles, useDeleteFile } from "@app/orval/api/files";
import { listNewsfeedFieldValueUpdates, listNewsfeedRecruitNoteChanges, listNewsfeedRecruitStageChanges, } from "@app/orval/api/newsfeed";
import { searchNotifications } from "@app/orval/api/notifications";
import { addInterviewForRecruit, removeInterviewForRecruit, updateInterviewForRecruit, useDeleteRecruit, useGetRecruit, useListEmailsForRecruit, useListStreakFilesForRecruit, useUpdateRecruit, useListStreakCommentsForRecruit, } from "@app/orval/api/recruits";
import { useListStages } from "@app/orval/api/stages";
import { listTasks, useAddTask, useDeleteTask, useUpdateTask } from "@app/orval/api/tasks";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { getFullName, getInitialsMemoized } from "@app/util/Utils";
import Forms from "./components/Forms";
import Interviews from "./components/Interviews";
import Listings from "./components/Listings";
import RecruitingActionsMenu from "./RecruitingActionsMenu";
import useCanEditRecruit from "./useCanEditRecruit";
import useValidation from "./useValidation";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import SectionHeader from "../buyer-leads/components/SectionHeader";
import Collaborators from "../components/box-view/actions/Collaborators";
import Chronology from "../components/box-view/Chronology/Chronology";
import DataEntry from "../components/box-view/DataEntry";
import DataEntrySkeleton from "../components/box-view/DataEntrySkeleton";
import Header from "../components/box-view/Header";
import ValidationErrors from "../components/box-view/ValidationErrors";
import ViewMembership from "../components/box-view/ViewMembership";
import useCollaborators from "../hooks/useCollaborators";
import useStages from "../hooks/useStages";
import Notes from "../seller-leads/components/Notes";
import { groupBy } from "../utils/utils";
const ALLOWED_VISIBILITY_OPTIONS = ["Calls", "Comments", "Emails", "History", "SMS", "Files"];
const ALLOWED_VISIBILITY_OPTIONS__WRITEABLE = [...ALLOWED_VISIBILITY_OPTIONS];
const RecruitingForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16;
    const { uid } = props;
    const tab = (_a = props.tab) !== null && _a !== void 0 ? _a : "data";
    // STATE
    const [search, setSearch] = useStateCallback("");
    const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = useStateCallback(new Set());
    // HOOKS
    const errorDialog = useErrorDialog();
    const location = useLocation();
    const updateSearch = useUpdateSearch({ navigateOptions: { state: location.state } });
    const confirm = useConfirm();
    const navigate = useNavigate();
    const listFieldsApi = useListFields({ entity_type: "Recruit" }, REFETCH_CACHE_PARAMS);
    const fields = (_c = (_b = listFieldsApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const fieldsByName = React.useMemo(() => {
        return fields === null || fields === void 0 ? void 0 : fields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [fields]);
    const listSectionsApi = useListFieldSections({ entity_type: "Recruit" }, REFETCH_CACHE_PARAMS);
    const sections = (_e = (_d = listSectionsApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const listStagesApi = useListStages({ type: "Recruit" }, REFETCH_CACHE_PARAMS);
    const stages = (_g = (_f = listStagesApi.data) === null || _f === void 0 ? void 0 : _f.data) !== null && _g !== void 0 ? _g : null;
    const stagesByUid = React.useMemo(() => {
        if (!stages) {
            return null;
        }
        return groupBy(stages, "uid");
    }, [stages]);
    const getRecruitApi = useGetRecruit(uid, REFETCH_CACHE_PARAMS);
    const updateRecruitApi = useUpdateRecruit();
    const recruit = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = getRecruitApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
    }, [getRecruitApi.data]);
    const canEdit = useCanEditRecruit(recruit);
    const deleteRecruitApi = useDeleteRecruit();
    const listCommentsApi = usePostQuery(listComments, { recruit_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const comments = (_j = (_h = listCommentsApi.data) === null || _h === void 0 ? void 0 : _h.data) !== null && _j !== void 0 ? _j : null;
    const searchNotificationsApi = usePostQuery(searchNotifications, { entity_uids: [uid] }, {});
    const notifications = (_l = (_k = searchNotificationsApi.data) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : null;
    const addCommentApi = useAddComment();
    const updateCommentApi = useUpdateComment();
    const deleteCommentApi = useDeleteComment();
    const listTasksApi = usePostQuery(listTasks, { recruit_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const tasks = (_o = (_m = listTasksApi.data) === null || _m === void 0 ? void 0 : _m.data) !== null && _o !== void 0 ? _o : null;
    const addTaskApi = useAddTask();
    const updateTaskApi = useUpdateTask();
    const deleteTaskApi = useDeleteTask();
    const listEmailsApi = useListEmailsForRecruit(uid, REFETCH_CACHE_PARAMS);
    const emails = (_q = (_p = listEmailsApi.data) === null || _p === void 0 ? void 0 : _p.data) !== null && _q !== void 0 ? _q : null;
    const listMessagesApi = useListMessagesForRecruit(uid, REFETCH_CACHE_PARAMS);
    const messages = (_s = (_r = listMessagesApi.data) === null || _r === void 0 ? void 0 : _r.data) !== null && _s !== void 0 ? _s : null;
    const listCallsApi = useListCallsForRecruit(uid, REFETCH_CACHE_PARAMS);
    const calls = (_u = (_t = listCallsApi.data) === null || _t === void 0 ? void 0 : _t.data) !== null && _u !== void 0 ? _u : null;
    const listTwilioPhonesApi = useListCachedTwilioPhones({ include_agent_phones: false }, REFETCH_CACHE_PARAMS);
    const twilioPhones = (_w = (_v = listTwilioPhonesApi.data) === null || _v === void 0 ? void 0 : _v.data) !== null && _w !== void 0 ? _w : null;
    const fieldValueChangesApi = usePostQuery(listNewsfeedFieldValueUpdates, { recruit_uids: [uid] }, REFETCH_CACHE_PARAMS);
    const fieldValueChanges = (_y = (_x = fieldValueChangesApi.data) === null || _x === void 0 ? void 0 : _x.data) !== null && _y !== void 0 ? _y : null;
    const { uploadFile } = useFileUploads();
    const { setRecruitingStage } = useStages();
    const { addCollaboratorForRecruit, removeCollaboratorForRecruit, updateCollaboratorForRecruit } = useCollaborators();
    const stageChangesApi = usePostQuery(listNewsfeedRecruitStageChanges, { recruit_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const stageChanges = (_0 = (_z = stageChangesApi.data) === null || _z === void 0 ? void 0 : _z.data) !== null && _0 !== void 0 ? _0 : null;
    const noteChangesApi = usePostQuery(listNewsfeedRecruitNoteChanges, { recruit_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const noteChanges = (_2 = (_1 = noteChangesApi.data) === null || _1 === void 0 ? void 0 : _1.data) !== null && _2 !== void 0 ? _2 : null;
    const listFilesApi = usePostQuery(searchFiles, { recruit_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const files = (_4 = (_3 = listFilesApi.data) === null || _3 === void 0 ? void 0 : _3.data) !== null && _4 !== void 0 ? _4 : null;
    const deleteFileApi = useDeleteFile();
    const listStreakCommentsApi = useListStreakCommentsForRecruit(uid, REFETCH_CACHE_PARAMS);
    const streakComments = (_6 = (_5 = listStreakCommentsApi.data) === null || _5 === void 0 ? void 0 : _5.data) !== null && _6 !== void 0 ? _6 : null;
    const listStreakFilesApi = useListStreakFilesForRecruit(uid, REFETCH_CACHE_PARAMS);
    const streakFiles = (_8 = (_7 = listStreakFilesApi.data) === null || _7 === void 0 ? void 0 : _7.data) !== null && _8 !== void 0 ? _8 : null;
    const queryClient = useQueryClient();
    // CALLBACKS
    const onTabChanged = React.useCallback((_, value) => {
        updateSearch("tab", value);
    }, [updateSearch]);
    const onFilesSelectedForUpload = React.useCallback((fileList) => {
        const filesToUpload = Array.from(fileList);
        setCurrentlyUploadingFiles((prev) => new Set([...prev, ...filesToUpload.map((x) => x.name)]));
        for (const file of filesToUpload) {
            console.log({ file });
            uploadFile({
                recruitUid: uid,
                file,
            })
                .then((file_) => {
                setCurrentlyUploadingFiles((prev) => new Set([...prev].filter((fname) => fname !== file_.filename)));
                queryClient.setQueryData(listFilesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [...old.data, file_] });
                });
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [uploadFile, uid, queryClient, listFilesApi.queryKey, setCurrentlyUploadingFiles]);
    const onFileDeleted = React.useCallback((fileUid) => {
        queryClient.setQueryData(listFilesApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== fileUid) });
        });
        deleteFileApi.mutateAsync({ uid: fileUid }).then(console.log).catch(enqueueErrorSnackbar);
    }, [queryClient, listFilesApi.queryKey, deleteFileApi]);
    const doSearch = React.useCallback((searchString) => {
        setSearch(searchString);
    }, [setSearch]);
    const deleteRecruit = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteRecruitApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Deleted recruit", { variant: "success" });
                    navigate("/recruits");
                })
                    .catch(enqueueErrorSnackbar);
            }
        });
    }, [confirm, deleteRecruitApi, navigate, uid]);
    const onFieldSaveBegin = React.useCallback((fieldName, fieldValue) => {
        queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), { [fieldName]: fieldValue }) }) });
        });
    }, [queryClient, getRecruitApi.queryKey]);
    const onFieldSaveComplete = React.useCallback((response) => {
        queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), Object.fromEntries(Object.entries(response.data.fields).filter(([name, _]) => {
                        var _a;
                        return (_a = fieldsByName === null || fieldsByName === void 0 ? void 0 : fieldsByName[name]) === null || _a === void 0 ? void 0 : _a.formula;
                    }))) }) });
        });
    }, [queryClient, getRecruitApi.queryKey, fieldsByName]);
    const onFieldSaveError = React.useCallback((error) => {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            confirm({
                title: "Unable to save field",
                description: String(error.response.data),
                hideCancelButton: true,
                confirmationText: "Ok",
            });
        }
        getRecruitApi.refetch();
    }, [confirm, getRecruitApi]);
    const onUpdate = React.useCallback((result) => {
        queryClient.setQueryData(getRecruitApi.queryKey, result);
    }, [getRecruitApi.queryKey, queryClient]);
    const onBeforeStageUpdate = React.useCallback((_, stageUid) => {
        queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { stage: stagesByUid[stageUid] }) });
        });
    }, [getRecruitApi.queryKey, queryClient, stagesByUid]);
    const onStageUpdateError = React.useCallback((dataBeforeUpdate) => () => {
        queryClient.setQueryData(getRecruitApi.queryKey, dataBeforeUpdate);
    }, [getRecruitApi.queryKey, queryClient]);
    const onStageChange = React.useCallback((stageUid) => {
        const dataBeforeUpdate = getRecruitApi.data;
        if (dataBeforeUpdate) {
            setRecruitingStage(uid, stageUid, onBeforeStageUpdate, onStageUpdateError(dataBeforeUpdate), onUpdate);
        }
    }, [getRecruitApi.data, setRecruitingStage, uid, onBeforeStageUpdate, onStageUpdateError, onUpdate]);
    const setNotes = React.useCallback((notes) => {
        updateRecruitApi
            .mutateAsync({ uid, data: { notes } })
            .then((result) => {
            queryClient.setQueryData(getRecruitApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getRecruitApi.queryKey, queryClient, uid, updateRecruitApi]);
    const saveComment = React.useCallback((params) => {
        const { content, reply_to } = params;
        if (params.uid) {
            updateCommentApi
                .mutateAsync({ uid: params.uid, data: { content } })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.map((c) => (c.uid === params.uid ? result.data : c)) });
                });
            })
                .catch(errorDialog);
        }
        else {
            const data = Object.assign({ content }, pickBy({
                reply_to,
                entity_uid: recruit.uid,
            }, (val) => val !== undefined));
            addCommentApi
                .mutateAsync({ data })
                .then((result) => {
                console.log({ result });
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [result.data, ...old.data] });
                });
            })
                .catch(errorDialog);
        }
    }, [updateCommentApi, errorDialog, queryClient, listCommentsApi.queryKey, addCommentApi, recruit]);
    const deleteComment = React.useCallback((commentUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== commentUid) });
                });
                deleteCommentApi
                    .mutateAsync({ uid: commentUid })
                    .then(() => {
                    console.log("Delete comment");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, queryClient, listCommentsApi.queryKey, deleteCommentApi, errorDialog]);
    const createTask = React.useCallback((task) => {
        addTaskApi
            .mutateAsync({
            data: Object.assign(Object.assign({}, task), { entity_uid: uid }),
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [addTaskApi, listTasksApi, queryClient, uid]);
    const updateTask = React.useCallback((taskUid, task) => {
        updateTaskApi
            .mutateAsync({
            uid: taskUid,
            data: task,
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: old.data.map((x) => (x.uid === taskUid ? result.data : x)) });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [listTasksApi, queryClient, updateTaskApi]);
    const deleteTask = React.useCallback((taskUid) => {
        queryClient.setQueryData(listTasksApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== taskUid) });
        });
        deleteTaskApi
            .mutateAsync({ uid: taskUid })
            .then(() => {
            console.log(`deleted task ${taskUid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [deleteTaskApi, listTasksApi, queryClient]);
    const onAddInterview = React.useCallback((params) => {
        addInterviewForRecruit(uid, params)
            .then((result) => {
            queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { interviews: result.data.interviews }) });
            });
        })
            .catch(errorDialog);
    }, [queryClient, getRecruitApi.queryKey, uid, errorDialog]);
    const onUpdateInterview = React.useCallback((interviewUid, params) => {
        updateInterviewForRecruit(uid, interviewUid, params)
            .then((result) => {
            queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { interviews: result.data.interviews }) });
            });
        })
            .catch(errorDialog);
    }, [queryClient, getRecruitApi.queryKey, uid, errorDialog]);
    const onRemoveInterview = React.useCallback((interviewUid) => {
        queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { interviews: old.data.interviews.filter((x) => x.uid !== interviewUid) }) });
        });
        removeInterviewForRecruit(uid, interviewUid)
            .then(() => {
            console.log("removed interview");
        })
            .catch(errorDialog);
    }, [queryClient, getRecruitApi.queryKey, errorDialog, uid]);
    const addCollaborator = React.useCallback((params) => {
        addCollaboratorForRecruit(uid, params, {
            onSaveComplete: onUpdate,
        });
    }, [addCollaboratorForRecruit, onUpdate, uid]);
    const updateCollaborator = React.useCallback((collaboratorUid, params) => {
        updateCollaboratorForRecruit(uid, collaboratorUid, params, {
            onUpdate,
        });
    }, [onUpdate, uid, updateCollaboratorForRecruit]);
    const removeCollaborator = React.useCallback((collaboratorUid) => {
        queryClient.setQueryData(getRecruitApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { collaborators: old.data.collaborators.filter((a) => a.uid !== collaboratorUid) }) });
        });
        removeCollaboratorForRecruit(uid, collaboratorUid, {
            onRemove: onUpdate,
        });
    }, [getRecruitApi.queryKey, onUpdate, queryClient, removeCollaboratorForRecruit, uid]);
    // MEMOIZED VALUES
    const callsSaturatedWithFriendlyNames = React.useMemo(() => {
        if (!calls) {
            return null;
        }
        if (!twilioPhones) {
            return calls;
        }
        const twilioPhonesByNumber = groupBy(twilioPhones, "phone_number");
        return calls.map((call) => {
            var _a, _b, _c, _d;
            return (Object.assign(Object.assign({}, call), { 
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                fromName: (_b = (_a = twilioPhonesByNumber[call.from_]) === null || _a === void 0 ? void 0 : _a.friendly_name) !== null && _b !== void 0 ? _b : null, 
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                toName: (_d = (_c = twilioPhonesByNumber[call.to]) === null || _c === void 0 ? void 0 : _c.friendly_name) !== null && _d !== void 0 ? _d : null }));
        });
    }, [calls, twilioPhones]);
    const { fieldSectionsSaturated, fieldsSaturated, validationErrors } = useValidation({
        recruit,
        fields: fields !== null && fields !== void 0 ? fields : null,
        sections,
    });
    if (!recruit || deleteRecruitApi.status === "pending") {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", style: { background: "#eee", overflowY: "hidden", height: "100%" }, wrap: "nowrap" },
        validationErrors.length > 0 && (React.createElement(Grid2, null,
            React.createElement(ValidationErrors, { errors: validationErrors }))),
        React.createElement(Grid2, { style: { width: "100%", overflow: "hidden" } },
            React.createElement(Box, { pl: 2, pt: 0, pb: 0 },
                React.createElement(Header, { moduleName: "Recruiting", pipelineUrl: "/recruits", name: recruit.name, showCopyLinkButton: true, onDelete: deleteRecruit },
                    React.createElement(ViewMembership, { entityType: "Recruit", entityUid: recruit.uid }),
                    React.createElement(RecruitingActionsMenu, { recruit: recruit })))),
        React.createElement(Grid2, { style: { overflow: "hidden", flex: 1 } },
            React.createElement(Grid2, { container: true, spacing: 1, style: { height: "100%" } },
                React.createElement(Hidden, { lgDown: tab !== "data" },
                    React.createElement(Grid2, { size: { xs: 12, lg: 3 }, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll", width: "100%" }, direction: "column", wrap: "nowrap" },
                                    React.createElement(Grid2, { style: { width: "100%" } },
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(SectionHeader, { uid: recruit.uid, name: React.createElement(Avatar, { src: undefined }, getInitialsMemoized((_9 = recruit.fields["First Name"]) !== null && _9 !== void 0 ? _9 : null, (_10 = recruit.fields["Last Name"]) !== null && _10 !== void 0 ? _10 : null) || "?"), stages: stages !== null && stages !== void 0 ? stages : undefined, stage: (_11 = recruit.stage) === null || _11 === void 0 ? void 0 : _11.uid, onStageChange: onStageChange, mailScheduleLinkUrl: `/recruits/${recruit.uid}/mail-schedule`, 
                                                // numMailScheduleErrors={
                                                //   mailScheduleErrors?.filter((x) => x.box_key === recruit.streak_box_key).length
                                                // }
                                                canEdit: canEdit }))),
                                    React.createElement(Grid2, null, fieldsSaturated ? (React.createElement(DataEntry, { apiUri: `/api/recruits/${recruit.uid}`, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, onFieldSaveError: onFieldSaveError, fields: fieldsSaturated, sections: fieldSectionsSaturated !== null && fieldSectionsSaturated !== void 0 ? fieldSectionsSaturated : [], values: recruit.fields, isEditable: canEdit })) : (React.createElement(Box, { p: 2 },
                                        React.createElement(DataEntrySkeleton, null)))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(Notes, { notes: (_12 = recruit.notes) !== null && _12 !== void 0 ? _12 : "", onSetNotes: setNotes, canEdit: canEdit })))))))),
                React.createElement(Hidden, { lgDown: tab !== "chronology" },
                    React.createElement(Grid2, { size: { xs: 12, lg: 6 }, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                React.createElement(Box, { p: 2, style: { height: "100%" } },
                                    React.createElement(Chronology, { allowedVisibilityOptions: ALLOWED_VISIBILITY_OPTIONS__WRITEABLE, visibilityType: "Recruit", updates: fieldValueChanges, stageChanges: stageChanges, noteChanges: noteChanges, comments: comments, notifications: notifications, tasks: tasks, onTaskCreated: createTask, onTaskUpdated: updateTask, onTaskDeleted: deleteTask, files: files, currentlyUploadingFiles: currentlyUploadingFiles, messages: messages, calls: callsSaturatedWithFriendlyNames !== null && callsSaturatedWithFriendlyNames !== void 0 ? callsSaturatedWithFriendlyNames : [], emails: emails, streakComments: streakComments, streakFiles: streakFiles, onSearch: doSearch, onCommentSubmitted: saveComment, onCommentDeleted: deleteComment, onFilesSelectedForUpload: onFilesSelectedForUpload, onFileDeleted: onFileDeleted, showToolbar: true, contacts: [
                                            {
                                                name: getFullName((_13 = recruit.fields["First Name"]) !== null && _13 !== void 0 ? _13 : null, (_14 = recruit.fields["Last Name"]) !== null && _14 !== void 0 ? _14 : null) ||
                                                    "Recruit",
                                                phone_number: (_16 = (_15 = recruit.fields["Phone"]) === null || _15 === void 0 ? void 0 : _15.trim()) !== null && _16 !== void 0 ? _16 : "",
                                            },
                                        ].filter((x) => isValidPhoneNumber(x.phone_number, "US")), entityUid: recruit.uid })))))),
                React.createElement(Hidden, { lgDown: tab !== "listings" },
                    React.createElement(Grid2, { size: { xs: 12, lg: 3 }, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll" }, direction: "column", wrap: "nowrap" },
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(Collaborators, { collaborators: recruit.collaborators, onCreateCollaborator: canEdit ? addCollaborator : undefined, onUpdateCollaborator: canEdit ? updateCollaborator : undefined, onRemoveCollaborator: canEdit ? removeCollaborator : undefined }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(Forms, { recruitUid: recruit.uid }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Divider, null)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(Interviews, { interviews: recruit.interviews, onAddInterview: canEdit ? onAddInterview : undefined, onRemoveInterview: canEdit ? onRemoveInterview : undefined, onUpdateInterview: canEdit ? onUpdateInterview : undefined }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(Listings, { mlsNumber: recruit.fields["MLS ID#"] })))))))))),
        React.createElement(Hidden, { lgUp: true },
            React.createElement(Grid2, { style: { background: "white" } },
                React.createElement(Tabs, { value: tab, onChange: onTabChanged, variant: "fullWidth" },
                    React.createElement(Tab, { value: "data", label: "Data" }),
                    React.createElement(Tab, { value: "chronology", label: "Chronology" }),
                    React.createElement(Tab, { value: "listings", label: "Listings" }))))));
};
export default RecruitingForm;
