import * as React from "react";
import { Alert, Avatar, Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography, Autocomplete, } from "@mui/material";
import pickBy from "lodash/pickBy";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { withDialog } from "@app/hoc/withDialog";
import API from "@app/util/api";
import Session from "@app/util/Session";
import { getInitials } from "@app/util/Utils";
import MailScheduleResults from "./MailScheduleResults";
class MailScheduleRun extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                boxes: null,
                pipelines: null,
                settings: null,
                agents: null,
                templates: null,
                boxSearch: null,
                boxKey: null,
                pipelineKey: null,
                agent: null,
                template: null,
                isTest: true,
                shouldIgnoreDisabledSettings: false,
                error: null,
                isProcessing: false,
                isGettingBoxes: false,
                processedTemplates: null,
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "boxesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Fetch various options from API */
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.mailScheduleSettings.get({
                    onSuccess: (result) => {
                        this.setState({
                            settings: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    shouldUpdateCache: true,
                });
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.mailScheduleTemplates.list({
                    onSuccess: (result) => {
                        this.setState({
                            templates: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.users.list({
                    onSuccess: (result) => {
                        this.setState({
                            agents: result.filter((user) => user.roles.filter((role) => role.toLowerCase().includes("agent"))),
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "getBoxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (page) => {
                const { isGettingBoxes, pipelineKey } = this.state;
                if (pipelineKey) {
                    if (isGettingBoxes) {
                        console.log("Getting paginated boxes: page", page);
                        this.boxesAbortController = new AbortController();
                        API.streakBoxes.listPaginated(pipelineKey, {
                            query: {
                                start_page: page,
                                end_page: page + 1,
                            },
                            onSuccess: (result) => {
                                this.setState((prevState) => {
                                    return {
                                        boxes: prevState.boxes == null ? result.boxes : [...prevState.boxes, ...result.boxes],
                                        isGettingBoxes: result.boxes.length > 0,
                                    };
                                }, () => {
                                    this.getBoxes(page + 2);
                                });
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                            },
                            options: {
                                signal: this.boxesAbortController.signal,
                            },
                            shouldUseCache: true,
                            shouldUpdateCache: true,
                        });
                    }
                }
                else {
                    this.setState({
                        boxes: null,
                        isGettingBoxes: false,
                    });
                }
            }
        });
        Object.defineProperty(this, "setAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({
                    agent: e.target.value || null,
                    boxKey: null,
                    boxSearch: null,
                });
            }
        });
        Object.defineProperty(this, "setPipeline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({
                    pipelineKey: e.target.value || null,
                    boxes: null,
                    boxKey: null,
                    boxSearch: null,
                    isGettingBoxes: true,
                }, () => {
                    this.getBoxes(0);
                });
            }
        });
        Object.defineProperty(this, "setTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState({
                    template: e.target.value || null,
                });
            }
        });
        Object.defineProperty(this, "setIsTest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState({
                    isTest: checked,
                    shouldIgnoreDisabledSettings: false,
                });
            }
        });
        Object.defineProperty(this, "setShouldIgnoreDisabledSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState({
                    shouldIgnoreDisabledSettings: checked,
                });
            }
        });
        Object.defineProperty(this, "filterOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (options) => options
                .filter((box) => !this.state.boxSearch ||
                box.name.toLowerCase().trim().indexOf(this.state.boxSearch.toLowerCase().trim()) !== -1)
                .slice(0, 100)
        });
        Object.defineProperty(this, "groupBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (box) => {
                const { pipelineKey, pipelines } = this.state;
                const pipelinesDict = {};
                for (const pipeline of pipelines) {
                    pipelinesDict[pipeline.key] = pipeline;
                }
                if (pipelineKey) {
                    return pipelinesDict[box.pipelineKey].stages[box.stageKey].name;
                }
                return `${pipelinesDict[box.pipelineKey].name} - ${pipelinesDict[box.pipelineKey].stages[box.stageKey].name}`;
            }
        });
        Object.defineProperty(this, "onSearchInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event, value, reason) => {
                console.log("onSearchInputChange", event, value, reason);
                if (reason !== "reset") {
                    this.setState({
                        boxSearch: value,
                        boxKey: null,
                    });
                }
            }
        });
        Object.defineProperty(this, "getOptionLabel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (box) => [
                [box.readable_fields["Street #"], box.readable_fields["Street Name"]].filter((obj) => obj).join(" "),
                [
                    [box.readable_fields["Client #1 First"], box.readable_fields["Client #1 Last"]]
                        .filter((obj) => obj)
                        .join(" "),
                    [box.readable_fields["Client #2 First"], box.readable_fields["Client #2 Last"]]
                        .filter((obj) => obj)
                        .join(" "),
                ]
                    .filter((obj) => obj)
                    .join(" and "),
            ]
                .filter((obj) => obj)
                .join(" - ") || box.name
        });
        Object.defineProperty(this, "renderBoxSearchInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (params) => (React.createElement(TextField, Object.assign({}, params, { label: this.state.boxes || !this.state.pipelineKey ? "Box" : "Loading boxes...", placeholder: "All boxes", variant: "standard", size: "small", InputLabelProps: {
                    shrink: true,
                } })))
        });
        Object.defineProperty(this, "onBoxSearchChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, value, reason) => {
                console.log("onBoxSearchChange", reason);
                if (reason === "selectOption") {
                    this.setState({
                        boxKey: value.key,
                        boxSearch: value.name,
                    });
                }
                else if (reason === "removeOption" || reason === "clear") {
                    this.setState({
                        boxKey: null,
                        boxSearch: null,
                    });
                }
            }
        });
        Object.defineProperty(this, "runMailSchedule", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { agent, boxKey, isTest, pipelineKey, template } = this.state;
                this.setState({
                    isProcessing: true,
                    processedTemplates: null,
                });
                const data = pickBy({
                    pipeline_key: pipelineKey,
                    box_key: boxKey,
                    template_uid: template,
                    user_email: agent,
                    run_in_background: false,
                    is_test: isTest,
                }, (val) => val != null);
                console.log("Running mail schedule...", data);
                API.mailSchedule.run({
                    data,
                    onSuccess: (result) => {
                        this.checkExecutionStatus(result.uid);
                    },
                    onError: (error) => {
                        console.error(error);
                        this.setState({
                            isProcessing: false,
                            processedTemplates: null,
                        });
                        this.props.showErrorDialog(error);
                    },
                });
            }
        });
        Object.defineProperty(this, "checkExecutionStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => {
                API.mailSchedule.checkExecutionStatus(uid, {
                    onSuccess: (result) => {
                        console.log(result);
                        if (result.end_timestamp && !result.error_message) {
                            this.setState({
                                isProcessing: false,
                                processedTemplates: result.logs,
                            });
                            const successfulTemplates = result.logs.filter((item) => !item.error_message);
                            const failedTemplates = result.logs.filter((item) => item.error_message);
                            this.props.showDialog({
                                title: failedTemplates.length === 0 ? "Success!" : "Finished processing, but encountered some errors.",
                                text: `Successfully processed ${successfulTemplates.length} templates. Encountered ${failedTemplates.length} errors.`,
                                cancelButtonText: "OK",
                            });
                        }
                        else if (result.error_message) {
                            this.setState({
                                isProcessing: false,
                                processedTemplates: null,
                            });
                            this.props.showDialog({
                                title: "An error occurred",
                                text: result.error_message,
                                cancelButtonText: "OK",
                            });
                        }
                        else {
                            window.setTimeout(() => {
                                this.checkExecutionStatus(uid);
                            }, 5000);
                        }
                    },
                    onError: (error) => {
                        console.error(error);
                        window.setTimeout(() => {
                            this.checkExecutionStatus(uid);
                        }, 5000);
                    },
                });
            }
        });
    }
    componentDidMount() {
        this.refresh();
    }
    componentWillUnmount() {
        var _a;
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const { agent, agents, boxKey, boxes, error, isGettingBoxes, isProcessing, isTest, pipelineKey, pipelines, processedTemplates, settings, shouldIgnoreDisabledSettings, template, templates, } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!pipelines || !settings || !agents || !templates) {
            return React.createElement(CoreLoading, null);
        }
        const pipelinesDict = {};
        for (const pipeline of pipelines) {
            pipelinesDict[pipeline.key] = pipeline;
        }
        const filteredBoxes = (boxes !== null && boxes !== void 0 ? boxes : [])
            .filter((box) => { var _a; return agent ? ((_a = box.assignedToSharingEntries[0]) === null || _a === void 0 ? void 0 : _a.email.toLowerCase().trim()) === agent.toLowerCase().trim() : true; })
            .filter((box) => (pipelineKey ? box.pipelineKey === pipelineKey : true))
            .sort((a, b) => {
            if (pipelinesDict[a.pipelineKey].name < pipelinesDict[b.pipelineKey].name)
                return -1;
            if (pipelinesDict[a.pipelineKey].name > pipelinesDict[b.pipelineKey].name)
                return 1;
            if (pipelinesDict[a.pipelineKey].stageOrder.indexOf(a.stageKey) <
                pipelinesDict[b.pipelineKey].stageOrder.indexOf(b.stageKey))
                return -1;
            if (pipelinesDict[a.pipelineKey].stageOrder.indexOf(a.stageKey) >
                pipelinesDict[b.pipelineKey].stageOrder.indexOf(b.stageKey))
                return 1;
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
        return (React.createElement(Box, { p: 2 },
            React.createElement(Grid, { container: true, spacing: 4 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Alert, { severity: "info" },
                        React.createElement(Typography, null, "The Mail Schedule runs automatically in the background, but you can use this widget to manually run the Mail Schedule if necessary."))),
                isProcessing ? (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, null, "Running mail schedule...")),
                    !pipelineKey && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, null, "Since you didn't select a pipeline, this may take a few minutes, because the portal has to fetch all boxes in all pipelines from Streak."))),
                    pipelineKey && !boxKey && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, null, "Since you didn't select a box, this may take a few minutes, because the portal has to fetch all boxes in the pipeline from Streak."))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreLoading, null)))) : (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                        (((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin")) || ((_b = Session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole("Admin"))) && (React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Agent", select: true, value: agent !== null && agent !== void 0 ? agent : "", onChange: this.setAgent, SelectProps: {
                                    displayEmpty: true,
                                }, InputLabelProps: {
                                    shrink: true,
                                } },
                                React.createElement(MenuItem, { key: -1, value: "" }, "All Agents"),
                                agents
                                    .filter((agent) => agent.email)
                                    .map((agent) => {
                                    var _a, _b, _c;
                                    return (React.createElement(MenuItem, { key: (_a = agent.email) !== null && _a !== void 0 ? _a : "", value: (_b = agent.email) !== null && _b !== void 0 ? _b : "" },
                                        React.createElement(Grid, { container: true, spacing: 1 },
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Avatar, { src: (_c = agent.icon_url) !== null && _c !== void 0 ? _c : undefined, style: { height: "24px", width: "24px", fontSize: "0.75rem" } }, getInitials(agent.first_name, agent.last_name))),
                                            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, [agent.first_name, agent.last_name].filter((obj) => obj).join(" ")))));
                                })))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Pipeline", select: true, value: pipelineKey !== null && pipelineKey !== void 0 ? pipelineKey : "", onChange: this.setPipeline, SelectProps: {
                                    displayEmpty: true,
                                }, InputLabelProps: {
                                    shrink: true,
                                } },
                                React.createElement(MenuItem, { key: -1, value: "" }, "All Pipelines"),
                                pipelines
                                    .filter((pipeline) => { var _a, _b; return (_b = ((_a = settings.pipeline_settings) !== null && _a !== void 0 ? _a : []).find((p) => p.pipeline_key === pipeline.key)) === null || _b === void 0 ? void 0 : _b.is_enabled; })
                                    .map((pipeline) => (React.createElement(MenuItem, { key: pipeline.key, value: pipeline.key }, pipeline.name))))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Autocomplete, { forcePopupIcon: false, options: filteredBoxes, noOptionsText: "No boxes found", filterOptions: this.filterOptions, groupBy: this.groupBy, inputValue: this.state.boxSearch === null
                                    ? (_f = (_e = (_d = ((_c = this.state.boxes) !== null && _c !== void 0 ? _c : []).find((box) => box.key === boxKey)) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : boxKey) !== null && _f !== void 0 ? _f : ""
                                    : this.state.boxSearch, onInputChange: this.onSearchInputChange, getOptionLabel: this.getOptionLabel, style: { width: "100%" }, 
                                // debug
                                renderInput: this.renderBoxSearchInput, 
                                // renderOption={}
                                onChange: this.onBoxSearchChange, disabled: !this.state.boxes || isGettingBoxes })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Template", select: true, value: template !== null && template !== void 0 ? template : "", onChange: this.setTemplate, SelectProps: {
                                    displayEmpty: true,
                                }, InputLabelProps: {
                                    shrink: true,
                                } },
                                React.createElement(MenuItem, { key: -1, value: "" }, "All Templates"),
                                pipelineKey &&
                                    templates
                                        .filter((template_) => template_.is_active && template_.pipeline === pipelineKey)
                                        .map((template_) => (React.createElement(MenuItem, { key: template_.uid, value: template_.uid }, template_.name))))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(FormControlLabel, { label: isTest
                                    ? "Test mode is ON; running the Mail Schedule only shows you what would send, but doesn't process any templates"
                                    : "Test mode is OFF; running the Mail Schedule will actually process templates", control: React.createElement(Checkbox, { size: "small", onChange: this.setIsTest, color: "primary", checked: isTest }) })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(FormControlLabel, { label: shouldIgnoreDisabledSettings
                                    ? 'Mail Schedule will be run for "disabled" pipelines and templates. This can only be activated when Test mode is ON. Useful for testing Mail Schedule templates before they\'re actually "live".'
                                    : 'Mail Schedule will not be run for "disabled" pipelines or templates.', control: React.createElement(Checkbox, { size: "small", onChange: this.setShouldIgnoreDisabledSettings, color: "primary", checked: isTest && shouldIgnoreDisabledSettings, disabled: !isTest }) }))),
                    ((_g = Session.activeUser) === null || _g === void 0 ? void 0 : _g.isDeveloper) && (React.createElement(Grid, { item: true, xs: 12 }, (() => {
                        const data = {
                            boxKey,
                            pipelineKey,
                            agent,
                            template,
                        };
                        return Object.keys(data).map((key) => (React.createElement(Typography, { key: key },
                            key,
                            ": ",
                            data[key])));
                    })())),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Button, { onClick: this.runMailSchedule, disabled: isProcessing || (((_h = this.state.boxSearch) !== null && _h !== void 0 ? _h : "") !== "" && !boxKey), color: "primary", variant: "contained" }, "Run Mail Schedule")))),
                processedTemplates && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(MailScheduleResults, { processedTemplates: processedTemplates, boxes: boxes !== null && boxes !== void 0 ? boxes : undefined, pipelines: pipelines !== null && pipelines !== void 0 ? pipelines : undefined, templates: templates !== null && templates !== void 0 ? templates : undefined }))))));
    }
}
export default withDialog(MailScheduleRun);
