/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List all auto reply settings
 */
export const listTwilioAutoReplySettings = (options) => {
    return axios.get(`/api/twilio/autoreply`, options);
};
export const getListTwilioAutoReplySettingsQueryKey = () => {
    return [`/api/twilio/autoreply`];
};
export const getListTwilioAutoReplySettingsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTwilioAutoReplySettingsQueryKey();
    const queryFn = ({ signal }) => listTwilioAutoReplySettings(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all auto reply settings
 */
export function useListTwilioAutoReplySettings(options) {
    const queryOptions = getListTwilioAutoReplySettingsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Add a new auto reply setting
 */
export const createTwilioAutoReplySetting = (autoReplyRequest, options) => {
    return axios.post(`/api/twilio/autoreply`, autoReplyRequest, options);
};
export const getCreateTwilioAutoReplySettingMutationOptions = (options) => {
    const mutationKey = ['createTwilioAutoReplySetting'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createTwilioAutoReplySetting(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a new auto reply setting
*/
export const useCreateTwilioAutoReplySetting = (options) => {
    const mutationOptions = getCreateTwilioAutoReplySettingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get an auto reply setting
*/
export const getTwilioAutoReplySetting = (uid, options) => {
    return axios.get(`/api/twilio/autoreply/${uid}`, options);
};
export const getGetTwilioAutoReplySettingQueryKey = (uid) => {
    return [`/api/twilio/autoreply/${uid}`];
};
export const getGetTwilioAutoReplySettingQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTwilioAutoReplySettingQueryKey(uid);
    const queryFn = ({ signal }) => getTwilioAutoReplySetting(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an auto reply setting
 */
export function useGetTwilioAutoReplySetting(uid, options) {
    const queryOptions = getGetTwilioAutoReplySettingQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update an auto reply setting
 */
export const updateTwilioAutoReplySetting = (uid, autoReplyRequest, options) => {
    return axios.post(`/api/twilio/autoreply/${uid}`, autoReplyRequest, options);
};
export const getUpdateTwilioAutoReplySettingMutationOptions = (options) => {
    const mutationKey = ['updateTwilioAutoReplySetting'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateTwilioAutoReplySetting(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an auto reply setting
*/
export const useUpdateTwilioAutoReplySetting = (options) => {
    const mutationOptions = getUpdateTwilioAutoReplySettingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove an auto reply setting
*/
export const deleteTwilioAutoReplySetting = (uid, options) => {
    return axios.delete(`/api/twilio/autoreply/${uid}`, options);
};
export const getDeleteTwilioAutoReplySettingMutationOptions = (options) => {
    const mutationKey = ['deleteTwilioAutoReplySetting'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteTwilioAutoReplySetting(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove an auto reply setting
*/
export const useDeleteTwilioAutoReplySetting = (options) => {
    const mutationOptions = getDeleteTwilioAutoReplySettingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Reorder auto reply settings
*/
export const reorderTwilioAutoReplySettings = (autoReplyReorderRequest, options) => {
    return axios.post(`/api/twilio/autoreply/reorder`, autoReplyReorderRequest, options);
};
export const getReorderTwilioAutoReplySettingsMutationOptions = (options) => {
    const mutationKey = ['reorderTwilioAutoReplySettings'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderTwilioAutoReplySettings(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder auto reply settings
*/
export const useReorderTwilioAutoReplySettings = (options) => {
    const mutationOptions = getReorderTwilioAutoReplySettingsMutationOptions(options);
    return useMutation(mutationOptions);
};
