import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, Typography } from "@mui/material";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import BoxFilter from "./components/filters/BoxFilter";
import ExecutionStatusFilter from "./components/filters/ExecutionStatusFilter";
import ModeFilter from "./components/filters/ModeFilter";
import PipelineFilter from "./components/filters/PipelineFilter";
import RunByAgentFilter from "./components/filters/RunByAgentFilter";
import RunForAgentFilter from "./components/filters/RunForAgentFilter";
import TemplateFilter from "./components/filters/TemplateFilter";
import TestModeFilter from "./components/filters/TestModeFilter";
import { NOT_SPECIFIED } from "./components/filters/utils";
const MailScheduleExecutionsTableSidebar = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { onSidebarToggle } = props;
    const [searchParams] = useSearchParams();
    const agent = (_a = searchParams.get("agent")) !== null && _a !== void 0 ? _a : null;
    const agentIsNull = searchParams.get("agent_isnull") === "true" || null;
    const templateUid = (_b = searchParams.get("template")) !== null && _b !== void 0 ? _b : null;
    const templateUidIsNull = searchParams.get("template_isnull") === "true" || null;
    const pipeline = (_c = searchParams.get("pipeline")) !== null && _c !== void 0 ? _c : null;
    const pipelineIsNull = searchParams.get("pipeline_isnull") === "true" || null;
    const status = (_d = searchParams.get("status")) !== null && _d !== void 0 ? _d : null;
    const mode = (_e = searchParams.get("mode")) !== null && _e !== void 0 ? _e : null;
    const isTest = (_g = {
        "1": true,
        "0": false,
    }[(_f = searchParams.get("is_test")) !== null && _f !== void 0 ? _f : ""]) !== null && _g !== void 0 ? _g : null;
    const box = (_h = searchParams.get("box")) !== null && _h !== void 0 ? _h : null;
    const runByAgent = (_j = searchParams.get("run_by_agent")) !== null && _j !== void 0 ? _j : null;
    const updateSearch = useUpdateSearch();
    const handleModeChanged = React.useCallback((val) => {
        updateSearch("mode", val || null, "offset", 0);
    }, [updateSearch]);
    const handleTestModeChanged = React.useCallback((val) => {
        const valString = val === null ? null : val ? "1" : "0";
        updateSearch("is_test", valString, "offset", 0);
    }, [updateSearch]);
    const handlePipelineChanged = React.useCallback((val) => {
        const pipeline_ = val === NOT_SPECIFIED ? null : val;
        const pipelineIsNull_ = val === NOT_SPECIFIED ? true : null;
        updateSearch("pipeline", pipeline_, "pipeline_isnull", pipelineIsNull_, "template", null, "template_isnull", null, "offset", 0);
    }, [updateSearch]);
    const handleTemplateChanged = React.useCallback((val) => {
        const templateUid_ = val === NOT_SPECIFIED ? null : val;
        const templateUidIsNull_ = val === NOT_SPECIFIED ? true : null;
        updateSearch("template", templateUid_, "template_isnull", templateUidIsNull_, "offset", 0);
    }, [updateSearch]);
    const handleAgentChanged = React.useCallback((val) => {
        const agent_ = val === NOT_SPECIFIED ? null : val;
        const agentIsNull_ = val === NOT_SPECIFIED ? true : null;
        updateSearch("agent", agent_, "agent_isnull", agentIsNull_, "offset", 0);
    }, [updateSearch]);
    const handleRunByAgentChanged = React.useCallback((val) => {
        updateSearch("run_by_agent", val, "offset", 0);
    }, [updateSearch]);
    const handleStatusChanged = React.useCallback((val) => {
        updateSearch("status", val, "offset", 0);
    }, [updateSearch]);
    const handleBoxChanged = React.useCallback((val) => {
        updateSearch("box", val, "offset", 0, "box_isnull", val ? null : true);
    }, [updateSearch]);
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 0, wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, alignItems: "center" },
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Typography, { variant: "h6" }, "Filters")),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { onClick: onSidebarToggle },
                            React.createElement(Close, null)))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { component: Grid2, container: true, spacing: 2, p: 2, direction: "column" },
                React.createElement(Grid2, null,
                    React.createElement(ModeFilter, { onChange: handleModeChanged, value: mode })),
                React.createElement(Grid2, null,
                    React.createElement(PipelineFilter, { isNull: pipelineIsNull, onChange: handlePipelineChanged, value: pipeline })),
                React.createElement(Grid2, null,
                    React.createElement(TemplateFilter, { pipeline: pipeline, isNull: templateUidIsNull, onChange: handleTemplateChanged, value: templateUid })),
                React.createElement(Grid2, null,
                    React.createElement(RunForAgentFilter, { onChange: handleAgentChanged, isNull: !!agentIsNull, value: agent })),
                React.createElement(Grid2, null,
                    React.createElement(RunByAgentFilter, { onChange: handleRunByAgentChanged, value: runByAgent })),
                React.createElement(Grid2, null,
                    React.createElement(ExecutionStatusFilter, { onChange: handleStatusChanged, value: status })),
                React.createElement(Grid2, null,
                    React.createElement(TestModeFilter, { onChange: handleTestModeChanged, value: isTest })),
                React.createElement(Grid2, null,
                    React.createElement(BoxFilter, { onChange: handleBoxChanged, value: box }))))));
};
export default MailScheduleExecutionsTableSidebar;
