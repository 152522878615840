import * as React from "react";
import { Diversity3 } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import useSession from "@app/hooks/useSession";
import ResponsiveFilterChip from "./ResponsiveFilterChip";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const TeamFilter = () => {
    var _a, _b;
    const { selectedTeamUids, setSelectedTeamUids, teams } = useAgentDashboardFilters();
    const session = useSession();
    const [panelAnchorEl, setPanelAnchorEl] = React.useState(null);
    const handleTeamsFilterClicked = React.useCallback((e) => {
        setPanelAnchorEl(e.currentTarget);
    }, [setPanelAnchorEl]);
    const closePanel = React.useCallback(() => {
        setPanelAnchorEl(null);
    }, [setPanelAnchorEl]);
    const selectAll = React.useCallback(() => {
        var _a;
        setSelectedTeamUids(new Set((_a = teams === null || teams === void 0 ? void 0 : teams.map((team) => team.uid)) !== null && _a !== void 0 ? _a : []));
    }, [setSelectedTeamUids, teams]);
    const toggleSelectedTeam = React.useCallback((teamUid) => () => {
        setSelectedTeamUids((prev) => {
            const next = new Set(prev);
            if (next.has(teamUid)) {
                next.delete(teamUid);
            }
            else {
                next.add(teamUid);
            }
            return next;
        });
    }, [setSelectedTeamUids]);
    const selectTeam = React.useCallback((teamUid) => () => {
        setSelectedTeamUids(new Set([teamUid]));
    }, [setSelectedTeamUids]);
    if (!teams || teams.length <= 1) {
        return null;
    }
    const numSelected = selectedTeamUids.size > 0 ? selectedTeamUids.size : teams.length;
    const label = `${numSelected} of ${teams.length} teams`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveFilterChip, { size: "small", label: label, icon: React.createElement(Diversity3, { fontSize: "small" }), onClick: handleTeamsFilterClicked, isActive: numSelected !== teams.length }),
        panelAnchorEl && (React.createElement(CorePopover, { anchorEl: panelAnchorEl, onClose: closePanel, open: true, sx: { "& .MuiPaper-root ": { minWidth: "300px" } } },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", spacing: 1, style: { overflow: "hidden" } },
                    React.createElement(Grid, { item: true, style: { height: "32px" } },
                        React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center", style: { width: "100%" } },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, { style: { fontWeight: "bold" } },
                                    "Teams (",
                                    numSelected,
                                    " selected)")),
                            React.createElement(Grid, { item: true },
                                React.createElement(Button, { size: "small", onClick: selectAll }, "Select All")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 0, style: { maxHeight: "300px", overflow: "scroll", flex: 1 } },
                        ((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.team) && (React.createElement(Grid, { item: true },
                            React.createElement(Box, { pb: 3 },
                                React.createElement(Grid, { container: true, spacing: 1 },
                                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                                        React.createElement(FormControlLabel, { label: "My Team", control: React.createElement(Checkbox, { size: "small", checked: selectedTeamUids.has(session.viewingAsUser.profile.team.uid) ||
                                                    selectedTeamUids.size === 0, color: "primary", onChange: toggleSelectedTeam(session.viewingAsUser.profile.team.uid), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Button, { style: { padding: 0 }, onClick: selectTeam(session.viewingAsUser.profile.team.uid) }, "only")))))),
                        teams
                            .sort((a, b) => {
                            const aName = a.name.toLowerCase().trim();
                            const bName = b.name.toLowerCase().trim();
                            if (aName < bName) {
                                return -1;
                            }
                            if (aName > bName) {
                                return 1;
                            }
                            return 0;
                        })
                            .map((team) => (React.createElement(Grid, { key: team.uid, item: true },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, style: { flex: 1 } },
                                    React.createElement(FormControlLabel, { label: team.name, control: React.createElement(Checkbox, { size: "small", checked: selectedTeamUids.has(team.uid) || selectedTeamUids.size === 0, color: "primary", onChange: toggleSelectedTeam(team.uid), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Button, { style: { padding: 0 }, onClick: selectTeam(team.uid) }, "only")))))))))))));
};
export default TeamFilter;
