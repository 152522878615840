import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Add, Alarm, Check, Clear, Delete, Done, Error, ErrorOutline, FileCopy, FilterList, Launch, PlaylistAddCheck, Refresh, SortByAlpha, Warning, FileDownload, } from "@mui/icons-material";
import { Badge, Box, Divider, Grid, MenuItem, IconButton, Link, TableRow, TableCell, Tooltip, Typography, Avatar, ListItemIcon, } from "@mui/material";
import { addDays } from "date-fns";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import styled from "styled-components";
import gmailLogo from "@app/assets/img/gmail.png";
import googleCalendarLogo from "@app/assets/img/google-calendar.png";
import dashLogo from "@app/assets/img/logo-notext.png";
import slackLogo from "@app/assets/img/slack.png";
import streakLogo from "@app/assets/img/streak.png";
import twilioLogo from "@app/assets/img/twilio.png";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import DashPopover from "@app/common/CoreMoreMenu";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { sanitize } from "@app/entrypoints/brokerage/features/mailschedule/utils/utils";
import { withDialog } from "@app/hoc/withDialog";
import { withRouter } from "@app/hoc/withRouter";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import { DEFAULT_DATE_FORMAT, downloadBlob, getInitials, getStreakLinkForPipeline } from "@app/util/Utils";
const MailScheduleBadge = styled(Badge) `
    & span {
        max-width: 10px;
        min-width: 10px;
        width: 12px;
        height: 12px;
        color: white;
        font-weight: bold;
        opacity: 0.85;
        line-height: 0;
    }
`;
const DraftBadge = styled(MailScheduleBadge) `
    & span {
        background: #666;
        bottom: 6px;
        right: 1px;
    }
`;
const TriggerBadge = styled(MailScheduleBadge) `
    & span {
        top: 6px;
        right: 1px;
    }
`;
const CriteriaBadge = styled(MailScheduleBadge) `
& span {
    bottom: 6px;
    right: 1px;
}
`;
/**
 * Converts a time string (e.g. 05:00:00) to a friendlier
 * string for display (e.g. 5:00:00 am)
 *
 * @param value the time
 * @returns the time, as a friendly string
 */
const getLocalTimeStr = (value) => {
    if (value) {
        const valueAsDate = dayjs_(`1970-01-01 ${value}`);
        if (valueAsDate.isValid()) {
            return valueAsDate.format("h:mm A");
        }
    }
    return value;
};
const getTemplateReviewFrequencyError = (template, now) => {
    if (!template.review_frequency) {
        return null;
    }
    const lastUpdatedRequirement = {
        Annual: addDays(now, -365),
        Quarterly: addDays(now, -90),
    }[template.review_frequency];
    if (template.last_updated < dayjs_(lastUpdatedRequirement).format(DEFAULT_DATE_FORMAT)) {
        return `According to this template's review requirement, this template should have been updated by ${dayjs_(lastUpdatedRequirement).format("dddd, MMMM D, YYYY")}`;
    }
    return null;
};
const getMarkupForMergeFieldAttribute = (value) => (value !== null && value !== void 0 ? value : "").replace(/(\$\'.*?\')/g, `<font color='${AppConfig.themeColors.primary}'>$1</font>`);
class MailScheduleTemplatesTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                templates: null,
                users: null,
                pipelines: null,
                pipelineFilter: null,
                templateTypeFilter: null,
                statusFilter: null,
                isDownloading: false,
                error: null,
            }
        });
        Object.defineProperty(this, "child", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "deleteTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error Property 'handleClose' does not exist on type '{}'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            templates: prevState.templates.filter((template) => template.uid !== uid),
                        }));
                        const loadingSnackbar = enqueueSnackbar("Deleting template...", { variant: "info", persist: true });
                        API.mailScheduleTemplates.delete(uid, {
                            onSuccess: () => {
                                enqueueSnackbar("Template has been deleted", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                console.error(error.response);
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "duplicateTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                const loadingSnackbar = enqueueSnackbar("Duplicating template...", { variant: "info", persist: true });
                API.mailScheduleTemplates.duplicate(uid, {
                    onSuccess: (result) => {
                        console.log(result);
                        this.props.navigate(`/mailschedule/templates/${result.uid}`);
                        enqueueSnackbar("Template has been duplicated.", { variant: "success" });
                        closeSnackbar(loadingSnackbar);
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                        closeSnackbar(loadingSnackbar);
                    },
                });
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                this.setState({
                    templates: null,
                    pipelines: null,
                    error: null,
                });
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.mailScheduleTemplates.list({
                    onSuccess: (result) => {
                        this.setState({
                            templates: result.map((obj) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                                return (Object.assign(Object.assign({}, obj), { recipients: (_l = {
                                        Calendar: (_a = obj.calendar_template) === null || _a === void 0 ? void 0 : _a.guests,
                                        Email: [(_b = obj.email_template) === null || _b === void 0 ? void 0 : _b.to, (_c = obj.email_template) === null || _c === void 0 ? void 0 : _c.cc, (_d = obj.email_template) === null || _d === void 0 ? void 0 : _d.bcc]
                                            .filter((x) => x)
                                            .join(", "),
                                        "Email Draft": [
                                            (_e = obj.email_draft_template) === null || _e === void 0 ? void 0 : _e.to,
                                            (_f = obj.email_draft_template) === null || _f === void 0 ? void 0 : _f.cc,
                                            (_g = obj.email_draft_template) === null || _g === void 0 ? void 0 : _g.bcc,
                                        ]
                                            .filter((x) => x)
                                            .join(", "),
                                        SMS: (_h = obj.sms_template) === null || _h === void 0 ? void 0 : _h.to_phone,
                                        "SMS Draft": (_j = obj.sms_draft_template) === null || _j === void 0 ? void 0 : _j.to_phone,
                                        Slack: (_k = obj.slack_template) === null || _k === void 0 ? void 0 : _k.channel_id,
                                        "Streak Comment": "",
                                    }[obj.template_type]) !== null && _l !== void 0 ? _l : "", subject: (_q = {
                                        Calendar: (_m = obj.calendar_template) === null || _m === void 0 ? void 0 : _m.title,
                                        Email: (_o = obj.email_template) === null || _o === void 0 ? void 0 : _o.subject,
                                        "Email Draft": (_p = obj.email_draft_template) === null || _p === void 0 ? void 0 : _p.subject,
                                        SMS: "",
                                        "SMS Draft": "",
                                        Slack: "",
                                        "Streak Comment": "",
                                    }[obj.template_type]) !== null && _q !== void 0 ? _q : "" }));
                            }),
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUpdateCache: true,
                });
                // get users for assigned agents column
                API.users.list({
                    onSuccess: (result) => {
                        this.setState({
                            users: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            console.log(`Error getting users ${error}`);
                            this.props.showErrorDialog(error);
                        }
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState(error);
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "exportToCSV", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isDownloading: true,
                }, () => {
                    var _a;
                    (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.abortController = new AbortController();
                    API.mailScheduleTemplates.download({
                        onSuccess: (result) => {
                            this.setState({
                                isDownloading: false,
                            });
                            downloadBlob(result.content, "mailschedule_templates", "text/csv");
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                    isDownloading: false,
                                });
                            }
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                });
            }
        });
        Object.defineProperty(this, "stageSortCompare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (order) => (obj1, obj2) => {
                const { pipelineFilter, pipelines } = this.state;
                const pipeline = (pipelines !== null && pipelines !== void 0 ? pipelines : []).find((p) => p.key === pipelineFilter);
                if (!pipeline) {
                    return 0;
                }
                const stageKey1 = Object.keys(pipeline.stages).find((stageKey) => pipeline.stages[stageKey].name === obj1.data);
                const stageKey2 = Object.keys(pipeline.stages).find((stageKey) => pipeline.stages[stageKey].name === obj2.data);
                const stageIndex1 = pipeline.stageOrder.indexOf(stageKey1);
                const stageIndex2 = pipeline.stageOrder.indexOf(stageKey2);
                return (stageIndex1 - stageIndex2) * (order === "asc" ? 1 : -1);
            }
        });
        Object.defineProperty(this, "pipelineSortCompare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (order) => (obj1, obj2) => {
                var _a, _b, _c, _d;
                const { pipelines } = this.state;
                const pipelineName1 = (_b = (_a = (pipelines !== null && pipelines !== void 0 ? pipelines : []).find((p) => p.key === obj1.data)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
                const pipelineName2 = (_d = (_c = (pipelines !== null && pipelines !== void 0 ? pipelines : []).find((p) => p.key === obj2.data)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "";
                let val = 0;
                if (pipelineName1 < pipelineName2)
                    val = -1;
                if (pipelineName1 > pipelineName2)
                    val = 1;
                return val * (order === "asc" ? 1 : -1);
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        const params = new URLSearchParams(nextProps.location.search);
        return {
            pipelineFilter: params.get("pipeline") && params.get("pipeline") !== "any" ? params.get("pipeline") : null,
            templateTypeFilter: params.get("template_type"),
            statusFilter: params.get("status"),
        };
    }
    componentDidMount() {
        this.refresh();
    }
    render() {
        const { onSidebarToggle } = this.props;
        const { error, isDownloading, pipelineFilter, pipelines, statusFilter, templateTypeFilter, templates, users } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!templates || !users || !pipelines) {
            return React.createElement(CoreLoading, null);
        }
        const now = new Date();
        const rows = templates
            .filter((template) => {
            if (pipelineFilter && template.pipeline !== pipelineFilter) {
                return false;
            }
            if (templateTypeFilter && template.template_type !== templateTypeFilter) {
                return false;
            }
            if (statusFilter) {
                const isBroken = template.errors.length > 0 || !!template.criteria.find((c) => c.error);
                return statusFilter === "broken" ? isBroken : !isBroken;
            }
            return true;
        })
            .map((template) => {
            const pipeline = pipelines.find((p) => p.key === template.pipeline);
            const stageCriteria = template.criteria
                .filter((criterion) => { var _a, _b; return ["equal to", "after", "after or equal to"].includes((_b = (_a = criterion.stage_criteria) === null || _a === void 0 ? void 0 : _a.comparison) !== null && _b !== void 0 ? _b : ""); })
                .sort((a, b) => {
                const aIndx = pipeline.stageOrder.indexOf(a.stage_criteria.stage_key);
                const bIndx = pipeline.stageOrder.indexOf(b.stage_criteria.stage_key);
                if (aIndx < bIndx)
                    return -1;
                if (aIndx > bIndx)
                    return 1;
                return 0;
            });
            const stageTriggers = template.triggers
                .filter((trigger) => { var _a; return (_a = trigger.stage_trigger) === null || _a === void 0 ? void 0 : _a.to_stage_key; })
                .sort((a, b) => {
                const aIndx = pipeline.stageOrder.indexOf(a.stage_trigger.to_stage_key);
                const bIndx = pipeline.stageOrder.indexOf(b.stage_trigger.to_stage_key);
                if (aIndx < bIndx)
                    return -1;
                if (aIndx > bIndx)
                    return 1;
                return 0;
            });
            const earliestStageKey = (stageCriteria.length > 0 && stageCriteria[0].stage_criteria.stage_key) ||
                (stageTriggers.length > 0 && stageTriggers[0].stage_trigger.to_stage_key);
            const firstStageInPipeline = pipeline.stages[pipeline.stageOrder[0]];
            return Object.assign(Object.assign({}, template), { stage: earliestStageKey && pipeline.stages[earliestStageKey]
                    ? pipeline.stages[earliestStageKey].name
                    : firstStageInPipeline.name });
        })
            .map((template) => {
            const pipeline = pipelines.find((p) => p.key === template.pipeline);
            let t = template;
            if (pipeline) {
                t = sanitize(t, pipeline);
            }
            return t;
        })
            .map((template, index) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, template), template.email_template), template.email_draft_template), template.sms_template), template.sms_draft_template), template.calendar_template), template.slack_template), template.streak_comment_template), { number: index + 1 })));
        const columns = [
            {
                name: "actions",
                label: " ",
                options: {
                    searchable: false,
                    sort: false,
                    download: false,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customBodyRender: (_, tableMeta) => {
                        const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        const templateErrors = [
                            ...template.errors,
                            ...template.criteria
                                .filter((c) => c.error)
                                .reduce((errors, c) => [...errors, `Criteria Error: ${c.error}`], [])
                                .flat(),
                        ];
                        return (React.createElement(Grid, { container: true, style: { width: "calc(100% + 4px)" } },
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(DashPopover, null,
                                    React.createElement(MenuItem, { component: RouterLink, to: `/mailschedule/templates/${template.uid}` },
                                        React.createElement(ListItemIcon, null,
                                            React.createElement(Launch, { fontSize: "small" })),
                                        "Open"),
                                    React.createElement(MenuItem, { onClick: this.duplicateTemplate(template.uid) },
                                        React.createElement(ListItemIcon, null,
                                            React.createElement(FileCopy, { fontSize: "small" })),
                                        "Duplicate"),
                                    React.createElement(MenuItem, { onClick: this.deleteTemplate(template.uid) },
                                        React.createElement(ListItemIcon, null,
                                            React.createElement(Delete, { fontSize: "small" })),
                                        "Delete"))),
                            templateErrors.length > 0 && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Tooltip, { title: React.createElement(Grid, { container: true, spacing: 2 }, templateErrors.map((templateError) => (React.createElement(Grid, { key: templateError, item: true, xs: 12 },
                                        React.createElement(Typography, null, templateError))))) },
                                    React.createElement(Warning, { style: { color: "orange" } }))))));
                    },
                    setCellProps: () => ({
                        style: {
                            width: "46px",
                            minWidth: "46px",
                            maxWidth: "46px",
                        },
                    }),
                },
            },
            ...(!pipelineFilter
                ? [
                    {
                        name: "pipeline",
                        label: "Pipeline",
                        options: {
                            customBodyRender: (value) => {
                                var _a;
                                const pipeline = pipelines.find((p) => p.key === value);
                                if (value) {
                                    const url = getStreakLinkForPipeline(value);
                                    return (React.createElement(Link, { href: url, target: "_blank" }, (_a = pipeline === null || pipeline === void 0 ? void 0 : pipeline.name) !== null && _a !== void 0 ? _a : value));
                                }
                                return value;
                            },
                            sortCompare: this.pipelineSortCompare,
                        },
                    },
                ]
                : []),
            ...(pipelineFilter
                ? [
                    {
                        name: "number",
                        label: "#",
                    },
                ]
                : []),
            {
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        let logo;
                        switch (template.template_type) {
                            case "Calendar":
                                logo = googleCalendarLogo;
                                break;
                            case "Email":
                            case "Email Draft":
                                logo = gmailLogo;
                                break;
                            case "SMS":
                            case "SMS Draft":
                                logo = twilioLogo;
                                break;
                            case "Slack":
                                logo = slackLogo;
                                break;
                            case "Streak Comment":
                                logo = streakLogo;
                                break;
                            default:
                                logo = dashLogo;
                        }
                        const logoComponent = React.createElement("img", { src: logo, alt: "logo", width: "22", height: "22" });
                        return (React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true },
                                React.createElement(Tooltip, { title: template.template_type }, template.template_type.toLowerCase().includes("draft") ? (React.createElement(DraftBadge, { badgeContent: "D", color: "default", anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right",
                                    } }, logoComponent)) : (logoComponent))),
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", width: "calc(100% - 26px)" } }, String(value))));
                    },
                },
            },
            {
                name: "assigned_agent",
                label: "Assigned Agent",
                options: {
                    customBodyRender: (value) => {
                        var _a;
                        const user = users.find((item) => {
                            return item.uid === value;
                        });
                        return user ? (React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true, style: { width: "30px" } },
                                React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "20px", width: "20px", fontSize: "0.7rem" } }, getInitials(user.first_name, user.last_name))),
                            React.createElement(Grid, { item: true, style: { width: "calc(100% - 30px)", marginTop: "auto", marginBottom: "auto" } }, [user.first_name, user.last_name].filter((o) => o).join(" ")))) : (user);
                    },
                    setCellProps: () => ({
                        style: {
                            width: "150px",
                            minWidth: "150px",
                        },
                    }),
                },
            },
            {
                name: "triggers_and_criteria",
                label: "Criteria",
                options: {
                    sort: false,
                    customBodyRender: (_, tableMeta) => {
                        const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        let icon;
                        if (template.triggers.length > 0) {
                            icon = React.createElement(Alarm, { fontSize: "small" });
                        }
                        else {
                            icon = React.createElement(PlaylistAddCheck, { fontSize: "small" });
                        }
                        const tooltipItems = [];
                        if (template.triggers.length > 0) {
                            tooltipItems.push(`${template.triggers.length} trigger${template.triggers.length > 1 ? "s" : ""}`);
                        }
                        if (template.criteria.length > 0) {
                            tooltipItems.push(`${template.criteria.length} criteria`);
                        }
                        if (template.triggers.length > 0 && template.criteria.length > 0) {
                            icon = (React.createElement(TriggerBadge, { color: "secondary", badgeContent: template.triggers.length, anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                } }, icon));
                        }
                        else if (template.triggers.length > 0) {
                            icon = (React.createElement(TriggerBadge, { color: "secondary", badgeContent: template.triggers.length, anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                } }, icon));
                        }
                        else if (template.criteria.length > 0) {
                            icon = (React.createElement(CriteriaBadge, { color: "primary", badgeContent: template.criteria.length, anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                } }, icon));
                        }
                        return (React.createElement(Tooltip, { title: React.createElement(Box, { p: 1 },
                                React.createElement(Grid, { container: true, spacing: 4 },
                                    template.triggers.length > 0 && (React.createElement(Grid, { item: true, container: true, spacing: 1, xs: 12 },
                                        React.createElement(Grid, { item: true, xs: 12 },
                                            React.createElement(Typography, null, "Triggers")),
                                        React.createElement(Grid, { item: true, xs: 12 },
                                            React.createElement(Divider, { style: { background: "white" } })),
                                        template.triggers.map((trigger, indx) => (React.createElement(Grid, { key: `trigger${indx}__${trigger.description}`, container: true, item: true, xs: 12, spacing: 1 },
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Alarm, { style: { color: AppConfig.themeColors.lightestblue }, fontSize: "small" })),
                                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                                React.createElement(Typography, { variant: "body2" }, trigger.description))))))),
                                    template.criteria.length > 0 && (React.createElement(Grid, { item: true, container: true, spacing: 1, xs: 12 },
                                        React.createElement(Grid, { item: true, xs: 12 },
                                            React.createElement(Typography, null, "Criteria")),
                                        React.createElement(Grid, { item: true, xs: 12 },
                                            React.createElement(Divider, { style: { background: "white" } })),
                                        template.criteria.map((criterion, indx) => (React.createElement(Grid, { key: `criteria${indx}__${criterion.description}`, container: true, item: true, xs: 12, spacing: 1 },
                                            React.createElement(Grid, { item: true }, criterion.error ? (React.createElement(ErrorOutline, { style: { color: "#ff8e8a" }, fontSize: "small" })) : (React.createElement(Done, { style: { color: "#61ff71" }, fontSize: "small" }))),
                                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                                React.createElement(Typography, { variant: "body2" }, criterion.description))))))))) },
                            React.createElement("span", null,
                                icon,
                                !!template.criteria.find((criterion) => criterion.error) && (React.createElement(ErrorOutline, { style: { color: "#ff8e8a" }, fontSize: "small" })))));
                    },
                },
            },
            {
                name: "stage",
                label: "Stage",
                options: {
                    sort: !!pipelineFilter,
                    sortCompare: this.stageSortCompare,
                },
            },
            {
                name: "subject",
                label: "Subject or Title",
                options: {
                    customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: { __html: getMarkupForMergeFieldAttribute(value) } })),
                },
            },
            {
                name: "recipients",
                label: "Recipients or Guests",
                options: {
                    customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: { __html: getMarkupForMergeFieldAttribute(value) } })),
                },
            },
            {
                name: "earliest_process_time",
                label: "Earliest Process Time",
                options: {
                    customBodyRender: (value) => getLocalTimeStr(value),
                },
            },
            {
                name: "latest_process_time",
                label: "Latest Process Time",
                options: {
                    customBodyRender: (value) => getLocalTimeStr(value),
                },
            },
            {
                name: "is_active",
                label: "Enabled",
                options: {
                    customBodyRender: (value) => value ? (React.createElement(Check, { fontSize: "small", style: { color: "green" } })) : (React.createElement(Clear, { fontSize: "small", style: { color: "red" } })),
                },
            },
            {
                name: "last_updated",
                label: "Last Updated",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        const err = getTemplateReviewFrequencyError(template, now);
                        const ts = value && dayjs_(value).isValid() ? dayjs_(value).format("dddd, MMMM D, YYYY h:mm A") : value;
                        if (err) {
                            return (React.createElement(Tooltip, { title: React.createElement(Typography, null, err) },
                                React.createElement(Grid, { container: true, spacing: 1 },
                                    React.createElement(Grid, { item: true, style: { width: "20px", marginTop: "auto", marginBottom: "auto" } },
                                        React.createElement(Error, { color: "error", fontSize: "small" })),
                                    React.createElement(Grid, { item: true, style: { width: "calc(100% - 20px)", marginTop: "auto", marginBottom: "auto" } },
                                        React.createElement(Typography, { variant: "body2" }, ts)))));
                        }
                        return ts;
                    },
                    setCellProps: () => ({
                        style: {
                            width: "200px",
                            minWidth: "200px",
                        },
                    }),
                },
            },
            {
                name: "last_updated_by",
                label: "Last Updated By",
                options: {
                    customBodyRender: (value) => value ? (React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { width: "30px" } },
                            React.createElement(Avatar, { src: value.icon_url, style: { height: "20px", width: "20px", fontSize: "0.7rem" } }, [value.first_name, value.last_name]
                                .filter((o) => o)
                                .map((o) => o.substring(0, 1))
                                .join(""))),
                        React.createElement(Grid, { item: true, style: { width: "calc(100% - 30px)", marginTop: "auto", marginBottom: "auto" } }, [value.first_name, value.last_name].filter((o) => o).join(" ")))) : (value),
                    setCellProps: () => ({
                        style: {
                            width: "150px",
                            minWidth: "150px",
                        },
                    }),
                },
            },
        ].filter((obj) => !!obj);
        const options = {
            responsive: "standard",
            selectableRows: "none",
            download: false,
            print: false,
            filter: false,
            search: false,
            searchOpen: true,
            pagination: true,
            rowsPerPage: 100,
            rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
            viewColumns: true,
            expandableRows: true,
            setRowProps: (_, dataIndex) => {
                const template = rows[dataIndex];
                if (!template.is_active) {
                    return {
                        style: { opacity: 0.3 },
                    };
                }
                return {};
            },
            customToolbar: () => (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: "Refresh" },
                    React.createElement(IconButton, { onClick: this.refresh },
                        React.createElement(Refresh, null))),
                React.createElement(Tooltip, { title: "Create new template" },
                    React.createElement(IconButton, { component: RouterLink, to: "/mailschedule/templates/new" },
                        React.createElement(Add, null))),
                React.createElement(Tooltip, { title: "Export Templates to CSV" },
                    React.createElement(IconButton, { disabled: isDownloading, onClick: this.exportToCSV },
                        React.createElement(FileDownload, null))),
                pipelineFilter && (React.createElement(Tooltip, { title: "Reorder" },
                    React.createElement(IconButton, { component: RouterLink, to: `/mailschedule/templates/reorder?pipeline=${pipelineFilter}` },
                        React.createElement(SortByAlpha, null)))),
                React.createElement(Tooltip, { title: "Show filters" },
                    React.createElement(IconButton, { onClick: onSidebarToggle },
                        React.createElement(FilterList, null))))),
            renderExpandableRow: (_, rowMeta) => {
                var _a, _b;
                return (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: columns.length + 1, dangerouslySetInnerHTML: {
                            __html: (_b = (_a = rows[rowMeta.dataIndex].body) !== null && _a !== void 0 ? _a : rows[rowMeta.dataIndex].description) !== null && _b !== void 0 ? _b : "",
                        } })));
            },
        };
        return React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 0 });
    }
}
export default withRouter(withDialog(MailScheduleTemplatesTable));
