import * as React from "react";
import { Edit, RemoveCircleOutline } from "@mui/icons-material";
import { Avatar, Chip, IconButton, ListItemAvatar, ListItem, ListItemText, Tooltip, Typography, Grid2, } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import numeral from "numeral";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import AppConfig, { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const AgentListItem = (props) => {
    var _a, _b;
    const { agent, onEditAgent, onRemoveAgent } = props;
    const confirm = useConfirm();
    const session = useSession();
    const removeAgent = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This will remove the agent from the lead; it will not delete the agent from the portal.", confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveAgent(agent.uid);
            }
        });
    }, [agent.uid, confirm, onRemoveAgent]);
    const editAgent = React.useCallback(() => {
        onEditAgent(agent.uid);
    }, [agent.uid, onEditAgent]);
    const initials = [agent.agent.first_name, agent.agent.last_name]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o === null || o === void 0 ? void 0 : o.substring(0, 1))
        .join("");
    const name = [agent.agent.first_name, agent.agent.last_name].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" ");
    const badgeColor = {
        Producer: AppConfig.themeColors.primary,
        "Co-Agent": AppConfig.themeColors.lightblue,
        "Showing Agent": undefined,
        "Listing Coordinator": AppConfig.themeColors.green,
    }[agent.type];
    const textColor = {
        Producer: "white",
        "Co-Agent": "white",
        "Showing Agent": "black",
        "Listing Coordinator": "white",
    }[agent.type];
    return (React.createElement(ListItem, { secondaryAction: React.createElement(React.Fragment, null,
            onEditAgent && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit agent") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: editAgent },
                        React.createElement(Edit, { fontSize: "small" }))))),
            onRemoveAgent && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove agent") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removeAgent },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" })))))) },
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, { src: (_a = agent.agent.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "28px", width: "28px", fontSize: "0.75rem" } }, initials)),
        React.createElement(ListItemText, { style: { marginTop: 0, marginBottom: 0 }, primary: ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"])) ? (React.createElement(RouterLink, { to: `/users/${agent.agent.uid}`, style: { textDecoration: "none" } }, name)) : (name), secondary: React.createElement(Grid2, { container: true, spacing: 1 },
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Chip, { label: agent.type, size: "small", style: { background: badgeColor, color: textColor, height: "12px" } })),
                agent.split != null && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "caption" },
                        "Split: ",
                        numeral(agent.split * 100).format("0,0.00%"))))) })));
};
export default AgentListItem;
