import { htmlToText } from "html-to-text";
import AppConfig from "@app/util/AppConfig";
export const bodyHelperText = `
  You can use <b>$\'Field Name\'</b> syntax to add a Streak field to the template.
  Mail Schedule 3.0 also supports <a href="https://palletsprojects.com/p/jinja/" target="_blank">Jinja2-style template markup.</a>
  `
    .trim()
    .replace(/\s+/g, " ");
/** Available fonts for ReactQuill text editor */
export const quillFontWhitelist = [
    "Arial",
    "Verdana",
    "Helvetica",
    "Tahoma",
    "Trebuchet",
    "Times",
    "Georgia",
    "Garamond",
    "Courier",
    "Nunita",
];
/** Replaces markup with $'Field Name' syntax to view more easily in the table, export to csv, etc. */
export const sanitize = (template, pipeline, box) => {
    var _a;
    const t = template;
    for (const key of Object.keys(t)) {
        if (typeof t[key] === "string" && t[key].includes("$[")) {
            for (const field of [...pipeline.fields, { key: "assignedToSharingEntries", name: "Assigned Agents" }]) {
                try {
                    const regexp = new RegExp(`\\$\\[(.*?)\\]\\(${field.key}\\)`, "g");
                    const replacement = (box === null || box === void 0 ? void 0 : box.fields[field.key]) && String(box.fields[field.key]).trim() !== ""
                        ? `<b title="${field.name}"><font color="${AppConfig.themeColors.primary}">${htmlToText(String((_a = box.readable_fields[field.name]) !== null && _a !== void 0 ? _a : ""))}</font></b>`
                        : `$$'${field.name}'`;
                    t[key] = t[key].replace(regexp, replacement);
                }
                catch (err) {
                    console.warn(`Unable to do sanitization for field ${field.name} for the \`${key}\` parameter in template ${t["name"]} of pipeline ${pipeline.name}`, err);
                    break;
                }
            }
        }
        else if (t[key] && t[key].constructor === Object) {
            t[key] = sanitize(template[key], pipeline, box);
        }
    }
    return t;
};
export const Formatters = {
    Currency: {
        name: "::curr",
        description: "U.S. dollars, with no cents. (ex: _$450,000_)",
    },
    Datetime: {
        name: "::datetime",
        description: "Date in `dddd, MMMM D` (at) `h:mm am/pm` format. (ex: _Sunday, January 1 at 1:00 pm_)",
    },
    Fulldate: {
        name: "::fulldate",
        description: "Date in `dddd, MMMM D, YYYY` format. (ex: _Sunday, January 1, 2021_)",
    },
    MMDDYY: {
        name: "::mmddyy",
        description: "Date in `MM/DD/YY` format. (ex: _01/01/21_)",
    },
    Birthday: {
        name: "::birthday",
        description: "Date in `dddd, MMMM D` format. (ex: _Sunday, January 1_)",
    },
    DaysUntil: {
        name: "::daysuntil",
        description: "The number of days until a given date. Negative if the current date is past the given date.",
    },
    DaysSince: {
        name: "::dayssince",
        description: "The number of days since a given date. Negative if the current date is before the given date.",
    },
    Date: {
        name: "::date",
        description: "Date in `MMMM D, YYYY` format. (ex: _January 1, 2021_)",
    },
    TinyURL: {
        name: "::tinyurl",
        description: "Convertes a long URL to a branded tiny URL",
    },
};
