import * as React from "react";
import { BarChart, Email, HourglassBottom, Phone, Sms, Assignment, Close, FileCopy, Chat } from "@mui/icons-material";
import { Button, CircularProgress, Tooltip, ToggleButton, ToggleButtonGroup, Typography, Grid2 } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { convertUtcDateString } from "@app/common/date-utils";
import dayjs_ from "@app/common/dayjs";
import { useListMailschedule3Templates } from "@app/orval/api/mail-schedule-templates";
import { useListStages } from "@app/orval/api/stages";
import { useListUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
import ChronologyActions from "./ChronologyActions";
import AgentChangeItem from "./ChronologyItems/AgentChangeItem";
import CallChronologyItem from "./ChronologyItems/CallChronologyItem";
import CommentChronologyItem from "./ChronologyItems/CommentChronologyItem";
import EmailChronologyItem from "./ChronologyItems/EmailChronologyItem";
import FileUploadChronologyItem from "./ChronologyItems/FileUploadChronologyItem";
import MailScheduleChronologyItem from "./ChronologyItems/MailScheduleChronologyItem";
import NoteChangeItem from "./ChronologyItems/NoteChangeItem";
import NotificationChronologyItem from "./ChronologyItems/NotificationChronologyItem";
import PSPChronologyItem from "./ChronologyItems/PSPChronologyItem";
import QFOChronologyItem from "./ChronologyItems/QFOChronologyItem";
import SMSChronologyItem from "./ChronologyItems/SMSChronologyItem";
import StageChangeItem from "./ChronologyItems/StageChangeItem";
import StreakCommentItem from "./ChronologyItems/StreakCommentItem";
import StreakFileItem from "./ChronologyItems/StreakFileItem";
import FileDropper from "./FileDropper";
import Tasks from "./Tasks";
import useChronologyVisibilitySettings from "./useChronologyVisibilitySettings";
const visibilityOptions = [
    {
        label: "History",
        icon: React.createElement(HourglassBottom, null),
        tooltip: "Show/hide updates",
    },
    {
        label: "Comments",
        icon: React.createElement(Chat, null),
        tooltip: "Show/hide comments",
    },
    {
        label: "Emails",
        icon: React.createElement(Email, null),
        tooltip: "Show/hide emails",
    },
    {
        label: "Files",
        icon: React.createElement(FileCopy, null),
        tooltip: "Show/hide files",
    },
    {
        label: "SMS",
        icon: React.createElement(Sms, null),
        tooltip: "Show/hide SMS",
    },
    {
        label: "Calls",
        icon: React.createElement(Phone, null),
        tooltip: "Show/hide calls",
    },
    {
        label: "PSPs",
        icon: React.createElement(BarChart, null),
        tooltip: "Show/hide PSPs",
    },
    {
        label: "Forms",
        icon: React.createElement(Assignment, null),
        tooltip: "Show/hide form submissions",
    },
    {
        label: "Mail Schedule",
        icon: React.createElement(Typography, null, "MS"),
        tooltip: "Show/hide Mail Schedule",
    },
];
const visibilityOptionStrings = visibilityOptions.map((item) => item.label);
const Chronology = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { agentChanges, allowedVisibilityOptions, calls, comments, contacts, currentlyUploadingFiles, emails, entityUid, files, isSubmittingComment, mailScheduleTemplateHistory, messages, noteChanges, notifications, onClose, onCommentDeleted, onCommentSubmitted, onFileDeleted, onFilesSelectedForUpload, onSearch, onSmsSent, onTaskCreated, onTaskDeleted, onTaskUpdated, psps, qfos, showToolbar, stageChanges, streakComments, streakFiles, tasks, updates, visibilityType, } = props;
    const listUsersApi = useListUsers({}, REFETCH_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listMailScheduleTemplatesApi = useListMailschedule3Templates(undefined, REFETCH_CACHE_PARAMS);
    const mailScheduleTemplates = (_d = (_c = listMailScheduleTemplatesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_f = (_e = listStagesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const allToggleButtonRef = React.useRef(null);
    const mailScheduleTemplatesByUid = React.useMemo(() => {
        if (mailScheduleTemplates) {
            const res = {};
            for (const template of mailScheduleTemplates) {
                res[template.uid] = template;
            }
            return res;
        }
        return {};
    }, [mailScheduleTemplates]);
    const structuredComments = React.useMemo(() => {
        var _a;
        console.log({ comments });
        let res = (_a = comments === null || comments === void 0 ? void 0 : comments.map((comment) => (Object.assign(Object.assign(Object.assign({}, comment), comment.edits.slice(-1)[0]), { replies: [] })))) !== null && _a !== void 0 ? _a : [];
        if (res.length === 0) {
            return [];
        }
        for (let i = res.length - 1; i >= 0; --i) {
            if (res[i].reply_to) {
                for (const comment of res) {
                    if (comment.uid === res[i].reply_to) {
                        comment.replies.push(res[i]);
                    }
                }
                // for (let j = 0; j < res.length; ++j) {
                //     if (res[j].uid === res[i].reply_to) {
                //         res[j].replies.push(res[i]);
                //         break;
                //     }
                // }
            }
        }
        res = res.filter((comment) => !comment.reply_to);
        for (const comment of res) {
            comment.replies = comment.replies.sort((a, b) => {
                if (a.timestamp < b.timestamp)
                    return -1;
                if (a.timestamp > b.timestamp)
                    return 1;
                return 0;
            });
        }
        return res;
    }, [comments]);
    const history = React.useMemo(() => {
        const historyItems = (updates !== null && updates !== void 0 ? updates : []).map((item) => ({
            historyItem: item,
            timestamp: item.timestamp,
        }));
        const stageChangeItems = (stageChanges !== null && stageChanges !== void 0 ? stageChanges : []).map((item) => ({
            stageChangeItem: item,
            timestamp: item.timestamp,
        }));
        const noteChangeItems = (noteChanges !== null && noteChanges !== void 0 ? noteChanges : []).map((item) => ({
            noteChangeItem: item,
            timestamp: item.timestamp,
        }));
        const agentChangeItems = (agentChanges !== null && agentChanges !== void 0 ? agentChanges : []).map((item) => ({
            agentChangeItem: item,
            timestamp: item.timestamp,
        }));
        const emailItems = (emails !== null && emails !== void 0 ? emails : []).map((item) => ({
            emailItem: item,
            timestamp: new Date(item.internal_date).toISOString(),
        }));
        const commentItems = structuredComments.map((item) => ({
            commentItem: item,
            timestamp: item.edits[0].timestamp,
        }));
        const notificationItems = (notifications !== null && notifications !== void 0 ? notifications : []).map((item) => ({
            notificationItem: item,
            timestamp: item.created_at,
        }));
        const fileUploadItems = (files !== null && files !== void 0 ? files : []).map((item) => ({
            fileUploadItem: item,
            timestamp: item.created_at,
        }));
        const smsHistoryItems = (messages !== null && messages !== void 0 ? messages : []).map((item) => {
            var _a;
            return ({
                smsItem: item,
                timestamp: (_a = item.date_sent) !== null && _a !== void 0 ? _a : item.date_created,
            });
        });
        const callHistoryItem = (calls !== null && calls !== void 0 ? calls : []).map((item) => ({
            callItem: item,
            timestamp: item.start_time || item.date_created,
        }));
        const pspItems = (psps !== null && psps !== void 0 ? psps : []).map((item) => ({
            pspItem: item,
            timestamp: item.created_at,
        }));
        const qfoItems = (qfos !== null && qfos !== void 0 ? qfos : []).map((item) => ({
            qfoItem: item,
            timestamp: item.submitted_at || item.created,
        }));
        const mailScheduleItems = (mailScheduleTemplateHistory !== null && mailScheduleTemplateHistory !== void 0 ? mailScheduleTemplateHistory : []).map((item) => ({
            mailScheduleItem: item,
            timestamp: item.timestamp,
        }));
        const streakCommentItems = (streakComments !== null && streakComments !== void 0 ? streakComments : []).map((item) => ({
            streakCommentItem: item,
            timestamp: item.timestamp,
        }));
        const streakFileItems = (streakFiles !== null && streakFiles !== void 0 ? streakFiles : []).map((item) => ({
            streakFileItem: item,
            timestamp: item.created,
        }));
        return historyItems
            .concat(stageChangeItems)
            .concat(noteChangeItems)
            .concat(agentChangeItems)
            .concat(emailItems)
            .concat(commentItems)
            .concat(notificationItems)
            .concat(fileUploadItems)
            .concat(smsHistoryItems)
            .concat(callHistoryItem)
            .concat(pspItems)
            .concat(qfoItems)
            .concat(mailScheduleItems)
            .concat(streakCommentItems)
            .concat(streakFileItems)
            .filter((item) => {
            if (item.timestamp) {
                return true;
            }
            if (item.streakCommentItem && !item.timestamp) {
                console.warn("Streak comment item has no timestamp", item.streakCommentItem);
                return false;
            }
            return true;
        })
            .sort((a, b) => {
            const aTimestamp = new Date(a.timestamp.replace("Z", ""));
            const bTimestamp = new Date(b.timestamp.replace("Z", ""));
            if (aTimestamp > bTimestamp)
                return -1;
            if (aTimestamp < bTimestamp)
                return 1;
            return 0;
        });
    }, [
        updates,
        stageChanges,
        noteChanges,
        agentChanges,
        emails,
        structuredComments,
        notifications,
        files,
        messages,
        calls,
        psps,
        qfos,
        mailScheduleTemplateHistory,
        streakComments,
        streakFiles,
    ]);
    const { onVisibilityChanged, visibility } = useChronologyVisibilitySettings({
        visibilityType,
        allowedVisibilityOptions: allowedVisibilityOptions !== null && allowedVisibilityOptions !== void 0 ? allowedVisibilityOptions : [],
    });
    const allVisibilityOptionsSelected = React.useMemo(() => {
        return visibility.length === 0 || !(allowedVisibilityOptions === null || allowedVisibilityOptions === void 0 ? void 0 : allowedVisibilityOptions.find((x) => !visibility.includes(x)));
    }, [allowedVisibilityOptions, visibility]);
    const onButtonGroupToggle = React.useCallback((e, value) => {
        const selectAll = e.currentTarget === allToggleButtonRef.current && !allVisibilityOptionsSelected;
        const unselectAll = e.currentTarget === allToggleButtonRef.current && allVisibilityOptionsSelected;
        const newValue = (() => {
            if (selectAll) {
                return allowedVisibilityOptions;
            }
            if (unselectAll) {
                return [];
            }
            return value.filter((x) => x !== "all");
        })();
        console.log({ selectAll, unselectAll, newValue });
        onVisibilityChanged(newValue);
    }, [onVisibilityChanged, allowedVisibilityOptions, allVisibilityOptionsSelected]);
    const handleFilesSelectedForUpload = React.useCallback((fileList) => {
        if (fileList) {
            onFilesSelectedForUpload(fileList);
        }
    }, [onFilesSelectedForUpload]);
    return (React.createElement(Grid2, { container: true, spacing: 4, style: { height: "100%", width: "100%", overflowY: "hidden", overflowX: "hidden" }, direction: "column" },
        onFilesSelectedForUpload && React.createElement(FileDropper, { onFilesDropped: handleFilesSelectedForUpload }),
        React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
            React.createElement(Grid2, { container: true, spacing: 1 },
                React.createElement(Grid2, { style: { flex: 1 } }),
                showToolbar && (React.createElement(Grid2, { style: {
                        marginTop: "auto",
                        marginBottom: "auto",
                    } },
                    React.createElement(ToggleButtonGroup, { onChange: onButtonGroupToggle, size: "small", value: visibility, disabled: !onVisibilityChanged },
                        visibilityOptions
                            .filter((item) => !allowedVisibilityOptions || allowedVisibilityOptions.includes(item.label))
                            .map((item) => (React.createElement(ToggleButton, { key: item.label, value: item.label },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, item.tooltip) }, item.icon)))),
                        React.createElement(ToggleButton, { ref: allToggleButtonRef, value: "all", selected: allVisibilityOptionsSelected },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "All") },
                                React.createElement(Typography, null, "ALL")))))),
                onClose && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Button, { size: "small", startIcon: React.createElement(Close, { fontSize: "small" }), tabIndex: -1, onClick: onClose }, "Close")))),
            React.createElement(Grid2, { style: { marginTop: "0.25rem" } },
                React.createElement(ChronologyActions, { contacts: contacts, onSmsSent: onSmsSent, onSearchUpdate: onSearch, onCommentSubmitted: onCommentSubmitted, onTaskCreated: onTaskCreated, handleFilesSelectedForUpload: onFilesSelectedForUpload ? handleFilesSelectedForUpload : undefined, isSubmittingComment: isSubmittingComment, disabled: !comments || isSubmittingComment }))),
        React.createElement(Grid2, { style: { flex: 1, width: "100%", overflowY: "scroll", overflowX: "hidden" } },
            React.createElement(Grid2, { container: true, direction: "column", spacing: 1, style: { width: "calc(100% - 1rem)" } },
                currentlyUploadingFiles && currentlyUploadingFiles.size > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { style: { textAlign: "center" } },
                        React.createElement(CircularProgress, null)),
                    React.createElement(Grid2, { style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "body2" },
                            "Uploading ",
                            currentlyUploadingFiles.size,
                            " file",
                            currentlyUploadingFiles.size === 1 ? "" : "s",
                            ". You can navigate to a different page, but don't close this app in order to allow the files to continue uploading.")))),
                tasks && tasks.length > 0 && onTaskUpdated && onTaskDeleted && (React.createElement(Grid2, null,
                    React.createElement(Tasks, { tasks: tasks, onTaskUpdated: onTaskUpdated, onTaskDeleted: onTaskDeleted }))),
                history.map((item) => {
                    var _a;
                    const { agentChangeItem, historyItem, noteChangeItem, stageChangeItem } = item;
                    if (historyItem && visibility.includes("History")) {
                        return (React.createElement(Grid2, { key: `${historyItem.field_name} ${historyItem.timestamp}`, sx: { width: "100%" } },
                            React.createElement(Grid2, { container: true, spacing: 1 },
                                React.createElement(Grid2, { style: { flex: 1 } },
                                    React.createElement(Markdown, { options: {
                                            disableParsingRawHTML: true,
                                            wrapper: "article",
                                        }, style: {
                                            fontFamily: `'${AppConfig.fonts.primary}', Helvetica, Arial, sans-serif`,
                                            fontWeight: 400,
                                            fontSize: 11,
                                        } }, historyItem.story)),
                                React.createElement(Grid2, { style: { textWrap: "nowrap" } },
                                    React.createElement(Typography, { style: {
                                            float: "right",
                                            color: "#ccc",
                                        }, variant: "caption" }, dayjs_(historyItem.timestamp).isValid()
                                        ? convertUtcDateString(historyItem.timestamp, { format: "M/D/YYYY h:mm A" })
                                        : historyItem.timestamp)))));
                    }
                    if (stageChangeItem && visibility.includes("History")) {
                        return (React.createElement(Grid2, { key: (_a = stageChangeItem.new_value) === null || _a === void 0 ? void 0 : _a.version_uid, sx: { width: "100%" } },
                            React.createElement(StageChangeItem, { item: stageChangeItem, stages: stages !== null && stages !== void 0 ? stages : [] })));
                    }
                    if (noteChangeItem && visibility.includes("History")) {
                        return (React.createElement(Grid2, { key: noteChangeItem.timestamp, sx: { width: "100%" } },
                            React.createElement(NoteChangeItem, { item: noteChangeItem })));
                    }
                    if (agentChangeItem && visibility.includes("History")) {
                        return (React.createElement(Grid2, { key: agentChangeItem.timestamp, sx: { width: "100%" } },
                            React.createElement(AgentChangeItem, { item: agentChangeItem, users: users !== null && users !== void 0 ? users : undefined })));
                    }
                    if (item.commentItem && visibility.includes("Comments")) {
                        const { commentItem } = item;
                        return (React.createElement(Grid2, { key: item.commentItem.uid, style: { marginTop: "0.5rem", width: "100%" } },
                            React.createElement(CommentChronologyItem, { key: commentItem.uid, users: users, comment: commentItem, onCommentSubmitted: onCommentSubmitted, onCommentDeleted: onCommentDeleted, disabled: isSubmittingComment })));
                    }
                    if (item.notificationItem && visibility.includes("Comments")) {
                        const { notificationItem } = item;
                        return (React.createElement(Grid2, { key: notificationItem.uid },
                            React.createElement(NotificationChronologyItem, { key: notificationItem.uid, notification: notificationItem })));
                    }
                    if (item.streakCommentItem && visibility.includes("Comments")) {
                        const { streakCommentItem } = item;
                        return (React.createElement(Grid2, { key: streakCommentItem.uid },
                            React.createElement(StreakCommentItem, { comment: streakCommentItem })));
                    }
                    if (item.streakFileItem && visibility.includes("Files")) {
                        const { streakFileItem } = item;
                        return (React.createElement(Grid2, { key: streakFileItem.uid },
                            React.createElement(StreakFileItem, { file: streakFileItem })));
                    }
                    if (item.smsItem && visibility.includes("SMS")) {
                        const { smsItem } = item;
                        return (React.createElement(Grid2, { key: smsItem.sid, style: { marginTop: "0.5rem", width: "100%" } },
                            React.createElement(SMSChronologyItem, { message: smsItem })));
                    }
                    if (item.callItem && visibility.includes("Calls")) {
                        const { callItem } = item;
                        return (React.createElement(Grid2, { key: callItem.sid, style: { marginTop: "0.5rem", width: "100%" } },
                            React.createElement(CallChronologyItem, { call: callItem })));
                    }
                    if (item.emailItem && visibility.includes("Emails")) {
                        const { emailItem } = item;
                        return (React.createElement(Grid2, { key: emailItem.uid, style: { marginTop: "2rem", width: "100%" } },
                            React.createElement(EmailChronologyItem, { email: emailItem, entityUid: entityUid })));
                    }
                    if (item.pspItem && visibility.includes("PSPs")) {
                        const { pspItem } = item;
                        return (React.createElement(Grid2, { key: pspItem.uid },
                            React.createElement(PSPChronologyItem, { psp: pspItem })));
                    }
                    if (item.qfoItem && visibility.includes("Forms")) {
                        const { qfoItem } = item;
                        return (React.createElement(Grid2, { key: qfoItem.uid },
                            React.createElement(QFOChronologyItem, { qfo: qfoItem })));
                    }
                    if (item.mailScheduleItem &&
                        visibility.includes("Mail Schedule") &&
                        mailScheduleTemplatesByUid[item.mailScheduleItem.template_uid]) {
                        const { mailScheduleItem } = item;
                        return (React.createElement(Grid2, { key: mailScheduleItem.timestamp, style: { marginTop: "3rem" } },
                            React.createElement(MailScheduleChronologyItem, { template: mailScheduleTemplatesByUid[mailScheduleItem.template_uid], value: mailScheduleItem })));
                    }
                    if (item.fileUploadItem && visibility.includes("Files")) {
                        const { fileUploadItem } = item;
                        return (React.createElement(Grid2, { key: item.fileUploadItem.uid, style: { marginTop: "0.5rem", width: "100%" } },
                            React.createElement(FileUploadChronologyItem, { file: fileUploadItem, handleDelete: onFileDeleted })));
                    }
                    return null;
                })))));
};
export default Chronology;
