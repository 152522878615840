import * as React from "react";
import { House } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
import PipelineAndStageText from "./PipelineAndStageText";
const BuyerLeadSearchResult = (props) => {
    var _a, _b;
    const { buyerLead } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemIcon, null,
            React.createElement(House, { fontSize: "large" })),
        React.createElement(ListItemText, { primary: buyerLead.name, secondary: React.createElement(PipelineAndStageText, { pipeline: "Buyer Lead", stageName: (_a = buyerLead.stage) === null || _a === void 0 ? void 0 : _a.name, stageUid: (_b = buyerLead.stage) === null || _b === void 0 ? void 0 : _b.uid }) })));
};
export default BuyerLeadSearchResult;
