import * as React from "react";
import { Close } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Tooltip, Typography, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import User from "@app/classes/user";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useCreatePspTrainingFeedback, useListPspTrainingFeedback } from "@app/orval/api/psp-training-feeedback";
const PSPCertificationFeedbackSidebar = (props) => {
    var _a, _b, _c, _d, _e, _f;
    // const [feedback, setFeedback] = React.useState<MAtrixFeedback[] | null>(null);
    const [content, setContent] = React.useState("");
    const [isSubmittingFeedback, setIsSubmittingFeedback] = useStateWithCallback(false);
    const { matrix, onSidebarToggle } = props;
    const queryClient = useQueryClient();
    const session = useSession();
    const errorDialog = useErrorDialog();
    const createPSPTrainingFeedbackApi = useCreatePspTrainingFeedback();
    const pspTrainingFeedbackListApi = useListPspTrainingFeedback({ matrix_uids: matrix.uid });
    const feedback = (_b = (_a = pspTrainingFeedbackListApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const onContentChange = React.useCallback((e) => {
        setContent(e.target.value);
    }, []);
    const submitFeedback = React.useCallback(() => {
        setIsSubmittingFeedback(true, () => {
            createPSPTrainingFeedbackApi
                .mutateAsync({
                data: {
                    matrix_uid: matrix.uid,
                    feedback: content,
                },
            })
                .then((result) => {
                setContent("");
                queryClient.setQueryData(pspTrainingFeedbackListApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: [result.data, ...old.data] });
                });
            })
                .catch((error) => {
                errorDialog(error);
            })
                .then(() => {
                setIsSubmittingFeedback(false);
            });
        });
    }, [
        content,
        createPSPTrainingFeedbackApi,
        errorDialog,
        matrix.uid,
        pspTrainingFeedbackListApi.queryKey,
        queryClient,
        setIsSubmittingFeedback,
    ]);
    const loading = [
        {
            label: "Loading feedback...",
            status: pspTrainingFeedbackListApi.status,
        },
    ];
    if (loading.find((x) => x.status === "pending") || !feedback) {
        return React.createElement(Loading, { items: loading });
    }
    const userIsMAtrixAdmin = !!((_c = session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole("Superadmin")) ||
        !!((_d = session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.hasRole("Admin")) ||
        !!((_e = session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole("Market Analyst"));
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2, style: { margin: "unset" } },
        React.createElement(Box, { component: Grid, container: true, item: true, xs: 12, spacing: 1, p: 2 },
            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "h6" }, (_f = matrix.fields["Property Address"]) !== null && _f !== void 0 ? _f : "Feedback")),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(IconButton, { onClick: onSidebarToggle },
                    React.createElement(Close, null)))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        userIsMAtrixAdmin && (React.createElement(React.Fragment, null,
            React.createElement(Box, { component: Grid, container: true, item: true, xs: 12, spacing: 1, p: 2 },
                React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(TextField, { variant: "standard", label: "Leave Feedback", fullWidth: true, multiline: true, maxRows: 8, rows: 4, value: content, onChange: onContentChange })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Button, { variant: "contained", onClick: submitFeedback, disabled: !!isSubmittingFeedback || content.trim() === "" }, "Submit"))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, null)))),
        React.createElement(Box, { component: Grid, item: true, p: 2 },
            React.createElement(List, { dense: true }, feedback
                .sort((a, b) => {
                if (a.timestamp > b.timestamp)
                    return -1;
                if (a.timestamp < b.timestamp)
                    return 1;
                return 0;
            })
                .map((comment) => {
                var _a;
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                const reviewer = comment.reviewer ? new User(comment.reviewer) : null;
                return (React.createElement(ListItem, { key: comment.uid, divider: true },
                    reviewer && (React.createElement(ListItemAvatar, null,
                        React.createElement(Tooltip, { title: reviewer.name },
                            React.createElement(Avatar, { src: (_a = reviewer.icon_url) !== null && _a !== void 0 ? _a : undefined }, reviewer.initials || "")))),
                    React.createElement(ListItemText, { primary: React.createElement(Typography, { variant: "body2", style: { whiteSpace: "pre-line" } }, comment.feedback.trim()) })));
            })))));
};
export default PSPCertificationFeedbackSidebar;
