import * as React from "react";
import { Close, Restore } from "@mui/icons-material";
import { Grid2, TextField, IconButton, MenuItem, Chip, Typography, Autocomplete, Tooltip, FormControlLabel, Switch, } from "@mui/material";
import numeral from "numeral";
import DashCard from "@app/common/CoreCard";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import CoreNumberField from "@app/common/CoreNumberField";
import CoreTextField from "@app/common/CoreTextField";
import { MEASURE_ACCURACY_FROM_OPTIONS, getMarkets, getZipCodes, getCompletedBy, PRICE_RANGES, OUTLIER_TOLERANCE, DEFAULT_COMPLETED_BY_EMAILS, } from "./utils";
const renderMarketValues = (selected) => (React.createElement("div", { style: {
        display: "flex",
    } }, selected
    .sort((a, b) => {
    if (a > b)
        return 1;
    if (a < b)
        return -1;
    return 0;
})
    .map((option) => (React.createElement(Chip, { key: `${option}_chip}`, label: option, style: { marginRight: 2, height: "unset" } })))));
const renderZipCodesTags = (value) => {
    const valuesSorted = value.sort((a, b) => {
        if (a > b)
            return 1;
        if (a < b)
            return -1;
        return 0;
    });
    return (React.createElement("div", { style: {
            display: "flex",
        } }, valuesSorted.length > 1 ? (React.createElement(React.Fragment, null,
        React.createElement(Chip, { label: valuesSorted[0], style: { marginRight: 2, height: "unset" } }),
        React.createElement(Typography, null, `+ ${valuesSorted.length - 1}`))) : (React.createElement(Chip, { label: valuesSorted[0], style: { marginRight: 2, height: "unset" } }))));
};
const renderCompletedBySearchInput = (params) => (React.createElement(TextField, Object.assign({}, params, { variant: "standard", size: "small", label: "Completed By", style: {
        // height: "40px",
        // display: "flex",
        // overflow: "hidden",
        marginTop: "8px",
    } })));
const renderZipCodesSearchInput = (params) => (React.createElement(TextField, Object.assign({}, params, { variant: "standard", size: "small", label: "Zip Codes", style: {
        // height: "40px",
        // display: "flex",
        // overflow: "hidden",
        marginTop: "8px",
    } })));
const PSPAccuracySidebar = (props) => {
    const { addressFilter, completedByFilter, currentMean, defaultMarketsFilter, defaultZipCodesFilter, excludeOutliers, expectedMeanPSPValueAccuracy, fromDate, marketsFilter, measureAccuracyFrom, onSidebarToggle, priceRangeFilter, pspAccuracyData, setAddressFilter, setCompletedByFilter, setExcludeOutliers, setExpectedMeanPSPValueAccuracy, setFromDate, setMarketsFilter, setMeasureAccuracyFrom, setPriceRange, setToDate, setZipCodesFilter, toDate, zipCodesFilter, } = props;
    const completedByData = React.useMemo(() => {
        return getCompletedBy(pspAccuracyData);
    }, [pspAccuracyData]);
    const getCompletedByNameFromEmail = React.useCallback((email) => {
        return Object.keys(completedByData).includes(email) ? completedByData[email] : "";
    }, [completedByData]);
    const renderCompletedByTags = React.useCallback((value) => {
        const valuesSorted = value
            .map((item) => getCompletedByNameFromEmail(item))
            .sort((a, b) => {
            if (a > b)
                return 1;
            if (a < b)
                return -1;
            return 0;
        });
        return (React.createElement("div", { style: {
                display: "flex",
            } },
            valuesSorted.length > 1 && (React.createElement(React.Fragment, null,
                React.createElement(Chip, { label: valuesSorted[0], style: { paddingRight: 2, maxWidth: "70px", height: "unset" } }),
                React.createElement(Typography, null, `+ ${valuesSorted.length - 1}`))),
            valuesSorted.length === 1 && React.createElement(Chip, { label: valuesSorted[0], style: { maxWidth: "95px", height: "unset" } })));
    }, [getCompletedByNameFromEmail]);
    const onCompletedByFilterChange = React.useCallback((_, emails) => {
        setCompletedByFilter(emails);
    }, [setCompletedByFilter]);
    const resetCompletedByFilter = React.useCallback(() => {
        if (completedByFilter !== DEFAULT_COMPLETED_BY_EMAILS) {
            setCompletedByFilter(DEFAULT_COMPLETED_BY_EMAILS);
        }
    }, [completedByFilter, setCompletedByFilter]);
    const onZipCodesFilterChange = React.useCallback((_, zipCodes) => {
        setZipCodesFilter(zipCodes);
    }, [setZipCodesFilter]);
    const resetZipCodesFilter = React.useCallback(() => {
        if (zipCodesFilter !== defaultZipCodesFilter) {
            setZipCodesFilter(defaultZipCodesFilter);
        }
    }, [defaultZipCodesFilter, setZipCodesFilter, zipCodesFilter]);
    const onExcludeOutleirsToggle = React.useCallback((_, isChecked) => {
        setExcludeOutliers(isChecked);
    }, [setExcludeOutliers]);
    const resetMarketsFilter = React.useCallback(() => {
        if (marketsFilter !== defaultMarketsFilter) {
            setMarketsFilter(defaultMarketsFilter);
        }
    }, [defaultMarketsFilter, marketsFilter, setMarketsFilter]);
    const onAddressFilterChange = React.useCallback((address) => {
        setAddressFilter(address);
    }, [setAddressFilter]);
    return (React.createElement(DashCard, { toolbar: React.createElement(IconButton, { onClick: onSidebarToggle },
            React.createElement(Close, null)), padding: 0, elevation: 0, height: "100%" },
        React.createElement(Grid2, { container: true, style: { padding: "8px" } },
            React.createElement(Grid2, { size: 12, style: { padding: "4px" } },
                React.createElement(CoreChoiceField, { variant: "standard", label: "Price Range", fullWidth: true, value: priceRangeFilter.name, onChoiceChange: setPriceRange }, PRICE_RANGES.map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
            React.createElement(Grid2, { container: true, size: 12, style: { padding: "4px" } },
                React.createElement(Grid2, { size: 11 },
                    React.createElement(CoreChoiceField, { variant: "standard", label: "Market", fullWidth: true, multiple: true, renderValue: renderMarketValues, value: marketsFilter, onChoicesChange: setMarketsFilter }, getMarkets(pspAccuracyData)
                        .sort((a, b) => {
                        if (a > b)
                            return 1;
                        if (a < b)
                            return -1;
                        return 0;
                    })
                        .map((market) => (React.createElement(MenuItem, { key: market, value: market }, market))))),
                React.createElement(Grid2, { size: 1, style: { display: "flex" } },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Reset to default") },
                        React.createElement(IconButton, { size: "small", onClick: resetMarketsFilter, style: { marginTop: "auto" } },
                            React.createElement(Restore, null))))),
            React.createElement(Grid2, { size: 12, style: { padding: "4px" } },
                React.createElement(CoreDateField, { variant: "standard", label: "From", fullWidth: true, size: "small", date: fromDate !== null && fromDate !== void 0 ? fromDate : null, format: "MM/DD/YYYY", onDateChange: setFromDate })),
            React.createElement(Grid2, { size: 12, style: { padding: "4px" } },
                React.createElement(CoreDateField, { variant: "standard", label: "To", fullWidth: true, size: "small", date: toDate !== null && toDate !== void 0 ? toDate : null, format: "MM/DD/YYYY", onDateChange: setToDate })),
            React.createElement(Grid2, { container: true, size: 12, style: { padding: "4px" } },
                React.createElement(Grid2, { size: 11 },
                    React.createElement(Autocomplete, { disableCloseOnSelect: true, openOnFocus: true, autoComplete: true, multiple: true, fullWidth: true, size: "small", limitTags: 1, 
                        // getLimitTagsText={renderLimitTagsText}
                        value: completedByFilter, forcePopupIcon: false, options: Object.keys(completedByData).sort((a, b) => {
                            if (getCompletedByNameFromEmail(a) > getCompletedByNameFromEmail(b))
                                return 1;
                            if (getCompletedByNameFromEmail(a) < getCompletedByNameFromEmail(b))
                                return -1;
                            return 0;
                        }), noOptionsText: "No agents found", getOptionLabel: getCompletedByNameFromEmail, style: { fontSize: "11px" }, 
                        // debug
                        renderInput: renderCompletedBySearchInput, renderTags: renderCompletedByTags, onChange: onCompletedByFilterChange })),
                React.createElement(Grid2, { size: 1, style: { display: "flex" } },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Reset to default") },
                        React.createElement(IconButton, { size: "small", onClick: resetCompletedByFilter, style: { marginTop: "auto" } },
                            React.createElement(Restore, null))))),
            React.createElement(Grid2, { container: true, size: 12, style: { padding: "4px" } },
                React.createElement(Grid2, { size: 11 },
                    React.createElement(Autocomplete
                    // disableClearable
                    , { 
                        // disableClearable
                        disableCloseOnSelect: true, openOnFocus: true, autoComplete: true, multiple: true, fullWidth: true, size: "small", limitTags: 1, 
                        // getLimitTagsText={renderLimitTagsText}
                        value: zipCodesFilter, forcePopupIcon: false, options: getZipCodes(pspAccuracyData).sort((a, b) => {
                            if (a > b)
                                return 1;
                            if (a < b)
                                return -1;
                            return 0;
                        }), noOptionsText: "No zip codes found", style: { fontSize: "11px" }, renderInput: renderZipCodesSearchInput, renderTags: renderZipCodesTags, onChange: onZipCodesFilterChange })),
                React.createElement(Grid2, { size: 1, style: { display: "flex" } },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Reset to default") },
                        React.createElement(IconButton, { size: "small", onClick: resetZipCodesFilter, style: { marginTop: "auto" } },
                            React.createElement(Restore, null))))),
            React.createElement(Grid2, { container: true, size: 12, style: { padding: "4px" } },
                React.createElement(CoreTextField, { label: "Address", variant: "standard", fullWidth: true, size: "small", value: addressFilter, style: { fontSize: "11px" }, onTextChange: onAddressFilterChange })),
            React.createElement(Grid2, { size: 12, style: { padding: "4px" } },
                React.createElement(CoreChoiceField, { variant: "standard", label: "Measure Accuracy From", fullWidth: true, value: measureAccuracyFrom, onChoiceChange: setMeasureAccuracyFrom }, MEASURE_ACCURACY_FROM_OPTIONS.map((obj) => (React.createElement(MenuItem, { key: obj, value: obj }, obj))))),
            React.createElement(Grid2, { size: 12, style: { paddingLeft: "8px" } },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, `When enabled, filters outliers out of data. An outlier is defined as a psp with a close price that is over ${numeral(OUTLIER_TOLERANCE * 100).format("0,0%")} above or below the PSP Value Avg`) },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: excludeOutliers, onChange: onExcludeOutleirsToggle, color: "primary" }), label: "Exclude Outliers" }))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(CoreNumberField, { variant: "standard", label: "Expected Mean", fullWidth: true, number: expectedMeanPSPValueAccuracy, format: "0.00%", size: "small", onNumberChange: setExpectedMeanPSPValueAccuracy })),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { style: { fontSize: "14px" } }, `Current Mean: ${currentMean == null ? "-" : numeral(currentMean * 100).format("0,0.00%")}`)))));
};
export default PSPAccuracySidebar;
