import { useMemo } from "react";
import useSession from "@app/hooks/useSession";
import { useListStages } from "@app/orval/api/stages";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { saturateFieldWithValidation } from "../components/box-view/field_validation";
import { saturateSectionWithValidation } from "../components/box-view/section_validation";
import useTeamAdminPermissions from "../users/useTeamAdminPermissions";
const useValidation = (params) => {
    var _a, _b;
    const { buyerContractFieldSections, buyerContractFields, buyerContracts, buyerLead, buyerLeadFieldSections, buyerLeadFields, } = params;
    const session = useSession();
    const { effectiveUser } = useTeamAdminPermissions((_a = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.agents.map((x) => x.agent.uid)) !== null && _a !== void 0 ? _a : []);
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    }, [(_b = listStagesApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const buyerLeadFieldSectionsSaturated = useMemo(() => {
        return buyerLeadFieldSections === null || buyerLeadFieldSections === void 0 ? void 0 : buyerLeadFieldSections.map((section) => saturateSectionWithValidation({ section, ctx: buyerLead, user: effectiveUser !== null && effectiveUser !== void 0 ? effectiveUser : undefined, stages }));
    }, [buyerLead, buyerLeadFieldSections, effectiveUser, stages]);
    const buyerLeadFieldsSaturated = useMemo(() => {
        return buyerLeadFields === null || buyerLeadFields === void 0 ? void 0 : buyerLeadFields.map((field) => saturateFieldWithValidation({ field, ctx: buyerLead, user: effectiveUser !== null && effectiveUser !== void 0 ? effectiveUser : undefined, stages }));
    }, [buyerLead, buyerLeadFields, effectiveUser, stages]);
    const buyerContractFieldSectionsSaturated = useMemo(() => {
        return buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.reduce((acc, item) => {
            return Object.assign(Object.assign({}, acc), { [item.uid]: buyerContractFieldSections === null || buyerContractFieldSections === void 0 ? void 0 : buyerContractFieldSections.map((section) => {
                    var _a;
                    return saturateSectionWithValidation({
                        section,
                        ctx: Object.assign(Object.assign(Object.assign({}, buyerLead), item), { fields: Object.assign(Object.assign({}, buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.fields), item.fields) }),
                        user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined,
                    });
                }) });
        }, {});
    }, [buyerContractFieldSections, buyerContracts, buyerLead, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const buyerContractFieldsSaturated = useMemo(() => {
        return buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.reduce((acc, item) => {
            return Object.assign(Object.assign({}, acc), { [item.uid]: buyerContractFields === null || buyerContractFields === void 0 ? void 0 : buyerContractFields.map((field) => {
                    var _a;
                    return saturateFieldWithValidation({
                        field,
                        ctx: Object.assign(Object.assign(Object.assign({}, buyerLead), item), { fields: Object.assign(Object.assign({}, buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.fields), item.fields) }),
                        user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined,
                    });
                }) });
        }, {});
    }, [buyerContractFields, buyerContracts, buyerLead, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const validationErrors = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return [
            ...((_a = buyerLeadFieldsSaturated === null || buyerLeadFieldsSaturated === void 0 ? void 0 : buyerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Visible Validation`,
                error: f.visibleValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _a !== void 0 ? _a : []),
            ...((_b = buyerLeadFieldsSaturated === null || buyerLeadFieldsSaturated === void 0 ? void 0 : buyerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Editable Validation`,
                error: f.editableValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _b !== void 0 ? _b : []),
            ...((_c = buyerLeadFieldsSaturated === null || buyerLeadFieldsSaturated === void 0 ? void 0 : buyerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Warn Validation`,
                error: f.warnValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _c !== void 0 ? _c : []),
            ...((_d = buyerLeadFieldsSaturated === null || buyerLeadFieldsSaturated === void 0 ? void 0 : buyerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Reject Validation`,
                error: f.rejectValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _d !== void 0 ? _d : []),
            ...((_e = buyerLeadFieldSectionsSaturated === null || buyerLeadFieldSectionsSaturated === void 0 ? void 0 : buyerLeadFieldSectionsSaturated.map((f) => ({
                name: `${f.name} - Visible Validation`,
                error: f.visibleValidation.error,
                url: `/field-management?section=${f.uid}`,
            }))) !== null && _e !== void 0 ? _e : []),
            ...Object.keys(buyerContractFieldsSaturated !== null && buyerContractFieldsSaturated !== void 0 ? buyerContractFieldsSaturated : {})
                .map((uid) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return [
                    ...((_b = (_a = buyerContractFieldsSaturated[uid]) === null || _a === void 0 ? void 0 : _a.map((f) => ({
                        name: `${f.name} - Visible Validation`,
                        error: f.visibleValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _b !== void 0 ? _b : []),
                    ...((_d = (_c = buyerContractFieldsSaturated[uid]) === null || _c === void 0 ? void 0 : _c.map((f) => ({
                        name: `${f.name} - Editable Validation`,
                        error: f.editableValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _d !== void 0 ? _d : []),
                    ...((_f = (_e = buyerContractFieldsSaturated[uid]) === null || _e === void 0 ? void 0 : _e.map((f) => ({
                        name: `${f.name} - Warn Validation`,
                        error: f.warnValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _f !== void 0 ? _f : []),
                    ...((_h = (_g = buyerContractFieldsSaturated[uid]) === null || _g === void 0 ? void 0 : _g.map((f) => ({
                        name: `${f.name} - Reject Validation`,
                        error: f.rejectValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _h !== void 0 ? _h : []),
                ];
            })
                .flat(),
            ...Object.keys(buyerContractFieldSectionsSaturated !== null && buyerContractFieldSectionsSaturated !== void 0 ? buyerContractFieldSectionsSaturated : {})
                .map((uid) => {
                var _a, _b;
                return [
                    ...((_b = (_a = buyerContractFieldSectionsSaturated[uid]) === null || _a === void 0 ? void 0 : _a.map((f) => ({
                        name: `${f.name} - Visible Validation`,
                        error: f.visibleValidation.error,
                        url: `/field-management?section=${f.uid}`,
                    }))) !== null && _b !== void 0 ? _b : []),
                ];
            })
                .flat(),
        ].filter((x) => x.error);
    }, [
        buyerContractFieldSectionsSaturated,
        buyerContractFieldsSaturated,
        buyerLeadFieldSectionsSaturated,
        buyerLeadFieldsSaturated,
    ]);
    return {
        buyerLeadFieldSectionsSaturated,
        buyerContractFieldSectionsSaturated,
        buyerLeadFieldsSaturated,
        buyerContractFieldsSaturated,
        validationErrors,
    };
};
export default useValidation;
