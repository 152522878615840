import * as React from "react";
import { Phone } from "@mui/icons-material";
import VCardQRCode from "./VCardQRCode";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const VCard = (props) => {
    const { userUid } = props;
    return (React.createElement(CollapsibleSection, { icon: React.createElement(Phone, null), title: "vCard" },
        React.createElement(VCardQRCode, { userUid: userUid })));
};
export default VCard;
