import * as React from "react";
import { AutoAwesome, CopyAll, Email } from "@mui/icons-material";
import { Link, Menu, ListItemIcon, MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { createDraft } from "@app/entrypoints/brokerage/window-messages";
import { copyToClipboard, isChromeExtension } from "@app/util/Utils";
import MailScheduleTemplateMenu from "./MailScheduleTemplateMenu";
import useEmailDraftCreator from "./useEmailDraftCreator";
const EmailMenu = (props) => {
    const { MenuProps, contacts, entityType, entityUids } = props;
    const contactsWithEmails = contacts.filter((x) => x.email.trim());
    const [mailScheduleMenuAnchorEl, setMailScheduleMenuAnchorEl] = React.useState(null);
    const draftCreator = useEmailDraftCreator();
    const onCreateDraft = React.useCallback(() => {
        createDraft({
            draft: {
                to: contactsWithEmails.map((x) => x.email.trim()).join(","),
                entity_uids: entityUids,
            },
        });
    }, [contactsWithEmails, entityUids]);
    const openMailScheduleMenu = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setMailScheduleMenuAnchorEl(e.currentTarget);
    }, []);
    const closeMailScheduleMenu = React.useCallback(() => {
        setMailScheduleMenuAnchorEl(null);
    }, []);
    const handleTemplateSelected = React.useCallback((templateUid) => {
        console.log({ templateUid });
        draftCreator.createEmailDrafts({
            items: [
                {
                    templateUid,
                    entityType: entityType,
                    entityUid: entityUids[0],
                },
            ],
        });
    }, [draftCreator, entityType, entityUids]);
    const copyEmailAddress = React.useCallback(() => {
        copyToClipboard(contactsWithEmails.map((x) => x.email.trim()).join(", "));
        enqueueSnackbar(`Copied ${contactsWithEmails.length} email address${contactsWithEmails.length === 1 ? "" : "es"} to clipboard`, { variant: "success" });
    }, [contactsWithEmails]);
    return (React.createElement(Menu, Object.assign({}, MenuProps),
        React.createElement(MenuItem, { onClick: copyEmailAddress, disabled: contactsWithEmails.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(CopyAll, null)),
            "Copy email address",
            contactsWithEmails.length === 1 ? "" : "es",
            " to clipboard"),
        isChromeExtension ? (React.createElement(MenuItem, { key: "email", onClick: onCreateDraft, disabled: contactsWithEmails.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(Email, null)),
            "Email ",
            contactsWithEmails.map((c) => c.name).join(", "))) : (React.createElement(MenuItem, { key: "email", component: Link, href: `mailto:${contactsWithEmails.map((c) => c.email.trim()).join(",")}`, disabled: contactsWithEmails.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(Email, null)),
            "Email ",
            contactsWithEmails.map((c) => c.name || c.email).join(", "),
            "...")),
        entityType && entityUids && entityUids.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { key: "draft-a-template", onClick: openMailScheduleMenu },
                React.createElement(ListItemIcon, null,
                    React.createElement(AutoAwesome, null)),
                "Create a draft from a template..."),
            React.createElement(MailScheduleTemplateMenu, { key: "mail-schedule-list", MenuProps: {
                    anchorEl: mailScheduleMenuAnchorEl,
                    open: !!mailScheduleMenuAnchorEl,
                    onClick: closeMailScheduleMenu,
                    onClose: closeMailScheduleMenu,
                }, onTemplateSelected: handleTemplateSelected, entityType: entityType })))));
};
export default EmailMenu;
