import * as React from "react";
import { Grid2 } from "@mui/material";
import FormRow from "@app/entrypoints/brokerage/features/apps/matrix/components/FormRow";
import PSPTabSection from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPTabSection";
const NET_SHEET_ROWS = [
    {
        name: "Client Name",
        fields: [{ name: "Client Name" }],
    },
    {
        name: "First Mortgage Payoff",
        fields: [{ name: "Net Sheet - First Mortgage Payoff" }],
    },
    {
        name: "Closing Date",
        fields: [{ name: "Closing Date" }],
    },
    {
        name: "Listing Fee",
        fields: [{ name: "Listing Fee", multiplyBy100: true }],
    },
    {
        name: "Traditional Listing Firm Commission Fee",
        fields: [{ name: "Traditional Listing Firm Commission Fee", multiplyBy100: true }],
    },
    {
        name: "Buyers Agent Commission Fee",
        fields: [{ name: "Buyers Agent Commission Fee", multiplyBy100: true }],
    },
    {
        name: "Other Liens",
        fields: [{ name: "Net Sheet - Other Liens" }],
    },
    {
        name: "Attorney Fees",
        fields: [{ name: "Net Sheet - Attorney Fees" }],
    },
    {
        name: "Brokerage Fee",
        fields: [{ name: "Net Sheet - Brokerage Fee" }],
    },
    {
        name: "Pro-Rated Taxes",
        fields: [{ name: "Net Sheet - Pro-Rated Taxes" }],
    },
    {
        name: "Recording Fees",
        fields: [{ name: "Net Sheet - Recording Fees" }],
    },
    {
        name: "Home Warranty",
        fields: [{ name: "Net Sheet - Home Warranty" }],
    },
    {
        name: "VA Required Fees",
        fields: [{ name: "Net Sheet - VA Required Fees" }],
    },
    {
        name: "Repairs from Home Inspection - Low",
        fields: [{ name: "Net Sheet - Repairs Low" }],
    },
    {
        name: "Repairs from Home Inspection - Avg",
        fields: [{ name: "Net Sheet - Repairs Avg" }],
    },
    {
        name: "Repairs from Home Inspection - High",
        fields: [{ name: "Net Sheet - Repairs High" }],
    },
    {
        name: "Closing Expenses Paid for Buyer - Low",
        fields: [{ name: "Net Sheet - Closing Expenses Low" }],
    },
    {
        name: "Closing Expenses Paid for Buyer - Avg",
        fields: [{ name: "Net Sheet - Closing Expenses Avg" }],
    },
    {
        name: "Closing Expenses Paid for Buyer - High",
        fields: [{ name: "Net Sheet - Closing Expenses High" }],
    },
    {
        name: "Sales Price Range - Low",
        fields: [{ name: "Sales Price Range - Low" }],
    },
    {
        name: "Sales Price Range - Mid",
        fields: [{ name: "Sales Price Range - Mid" }],
    },
    {
        name: "Sales Price Range - High",
        fields: [{ name: "Sales Price Range - High" }],
    },
];
const NetSheetTab = (props) => {
    const { matrixFields, matrixSchema, onFieldSaveBegin, onFieldSaveComplete, uid } = props;
    const rows = React.useMemo(() => {
        return NET_SHEET_ROWS.map((row) => (React.createElement(FormRow, { key: row.name, uid: uid, 
            // matrixFields={matrixFields}
            schema: matrixSchema, rowData: Object.assign(Object.assign({}, row), { fields: row.fields.map((field) => (Object.assign(Object.assign({}, field), { value: matrixFields[field.name] }))) }), labelSize: 3, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete })));
    }, [matrixFields, matrixSchema, onFieldSaveBegin, onFieldSaveComplete, uid]);
    return (React.createElement(Grid2, { container: true, spacing: 4, style: { height: "100%", width: "100%", margin: 0 } },
        React.createElement(PSPTabSection, { name: "Net Sheet Data" }, rows)));
};
export default NetSheetTab;
