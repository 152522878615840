import * as React from "react";
import { Clear, Delete, PlayArrow, Save } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { SpreadsheetContext } from "../../SpreadsheetContext";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewActions = () => {
    const context = useSavedViewEditorContext();
    const spreadsheetContext = React.useContext(SpreadsheetContext);
    return (React.createElement(Grid, { container: true, spacing: 4 },
        context.uid && (React.createElement(Grid, { item: true },
            React.createElement(Button, { variant: "contained", color: "error", startIcon: React.createElement(Delete, null), size: "small", disabled: context.isSaving || context.isDeleting, onClick: context.deleteView }, "Delete"))),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { variant: "outlined", startIcon: React.createElement(Clear, null), onClick: context.clearFilters, size: "small" }, "Clear Filters")),
        React.createElement(Grid, { item: true, style: { flex: 1 } }),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { variant: "text", onClick: context.uid ? context.cancel : context.close, size: "small" }, "Cancel")),
        spreadsheetContext && (React.createElement(Grid, { item: true },
            React.createElement(Button, { variant: "outlined", color: "primary", onClick: context.testView, startIcon: React.createElement(PlayArrow, null), size: "small", disabled: context.isSaving || context.isDeleting || !context.canTest }, "Test"))),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { variant: "contained", color: "primary", onClick: context.saveView, startIcon: React.createElement(Save, null), size: "small", disabled: context.isSaving || context.isDeleting || !context.canSave }, "Save"))));
};
export default SavedViewActions;
