import * as React from "react";
import { Email, LabelImportant, Phone } from "@mui/icons-material";
import { Avatar, Grid, ListItemButton, ListItemText, Typography } from "@mui/material";
import CoreLink from "@app/common/CoreLink";
import RouterLink from "@app/common/RouterLink";
import { getFullNameMemoized, getInitialsMemoized } from "@app/util/Utils";
export const StandardSmallCell = (props) => {
    var _a;
    const { allLinks, collaborators, link, name, stage } = props;
    return (React.createElement(ListItemButton, { component: RouterLink, to: link, state: { allBoxPaths: allLinks }, divider: true },
        React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, style: { flex: 1 } },
                    React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "body1" }, name)),
                        stage && (React.createElement(Grid, { item: true },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(LabelImportant, { fontSize: "small", style: { color: stage.color } })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "body2" }, stage.name))))))),
                collaborators && collaborators.length > 0 && (React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(Avatar, { src: (_a = collaborators[0].user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "24px", width: "24px", fontSize: "0.7rem" } }, getInitialsMemoized(collaborators[0].user.first_name, collaborators[0].user.last_name))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "caption" },
                                "Assigned to",
                                " ",
                                getFullNameMemoized(collaborators[0].user.first_name, collaborators[0].user.last_name),
                                collaborators.length > 1 ? `${` and ${collaborators.length - 1} more`}` : "")))))) })));
};
export const Client = (props) => {
    var _a, _b;
    const { client } = props;
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true },
            React.createElement(Avatar, { src: undefined }, getInitialsMemoized(client.client.first_name, client.client.last_name) || "?")),
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body1", component: RouterLink, to: `/contacts/${client.uid}` }, getFullNameMemoized(client.client.first_name, client.client.last_name))),
                ((_a = client.client.email) === null || _a === void 0 ? void 0 : _a.trim()) && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(Email, { fontSize: "small" })),
                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                            React.createElement(Typography, { variant: "body1", component: CoreLink, href: `mailto:${client.client.email}` }, client.client.email))))),
                ((_b = client.client.phone) === null || _b === void 0 ? void 0 : _b.trim()) && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(Phone, { fontSize: "small" })),
                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                            React.createElement(Typography, { variant: "body2", component: RouterLink, to: `/sms?twilio_phone_number=%2B19842052279&external_phone_number=${client.client.phone}` }, client.client.phone)))))))));
};
export const Clients = (props) => {
    const { clients } = props;
    return (React.createElement(Grid, { container: true }, clients.map((client) => (React.createElement(Grid, { key: client.uid, item: true, xs: 12, lg: 6 },
        React.createElement(Client, { client: client }))))));
};
