import * as React from "react";
import { Add, CheckCircle, CloudUpload, Phone, Search, Sms } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TwilioDialWidget from "@app/entrypoints/brokerage/context/twilio-calls/TwilioDialWidget";
import useStateCallback from "@app/hooks/useStateCallback";
import AppConfig from "@app/util/AppConfig";
import CommentField from "./ChronologyItems/CommentField";
import SmsField from "./ChronologyItems/SmsField";
import TaskField from "./ChronologyItems/TaskField";
import ChronologySearch from "./ChronologySearch";
const ChronologyActions = (props) => {
    const { contacts, disabled, handleFilesSelectedForUpload, isSubmittingComment, onCommentSubmitted, onSearchUpdate, onSmsSent, onTaskCreated, } = props;
    const [activeInput, setActiveInput] = useStateCallback(null);
    const fileInputId = React.useRef(uuidv4());
    const closeInput = React.useCallback(() => {
        setActiveInput(null);
    }, [setActiveInput]);
    const handleCommentFieldClicked = React.useCallback(() => {
        setActiveInput("comment");
    }, [setActiveInput]);
    const handleTaskButtonClicked = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveInput("task");
    }, [setActiveInput]);
    const handleSmsButtonClicked = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveInput("sms");
    }, [setActiveInput]);
    const handleCallButtonClicked = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveInput("call");
    }, [setActiveInput]);
    const handleSearchButtonClicked = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveInput("search");
    }, [setActiveInput]);
    const handleCommentSubmitted = React.useCallback((comment) => {
        if (onCommentSubmitted) {
            onCommentSubmitted(comment);
        }
    }, [onCommentSubmitted]);
    const handleTaskSaved = React.useCallback((task) => {
        if (onTaskCreated) {
            onTaskCreated(task);
        }
    }, [onTaskCreated]);
    const handleSmsSent = React.useCallback((message) => {
        setActiveInput(null);
        if (onSmsSent) {
            onSmsSent(message);
        }
    }, [onSmsSent, setActiveInput]);
    const handleSearchClose = React.useCallback(() => {
        setActiveInput(null);
    }, [setActiveInput]);
    const handleUploadButtonClicked = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById(fileInputId.current).click();
    }, []);
    const handleFilesSelectedForUpload_ = React.useCallback((e) => {
        if (e.target.files && handleFilesSelectedForUpload) {
            handleFilesSelectedForUpload(e.target.files);
        }
        e.target.value = "";
    }, [handleFilesSelectedForUpload]);
    if (activeInput === "comment") {
        return (React.createElement(Box, { p: 1 },
            React.createElement(CommentField, { onClose: closeInput, onCommentSubmitted: handleCommentSubmitted, replyTo: null, isSubmittingComment: isSubmittingComment })));
    }
    if (activeInput === "task") {
        return (React.createElement(Box, { p: 1 },
            React.createElement(TaskField, { onClose: closeInput, onSave: handleTaskSaved })));
    }
    if (activeInput === "sms") {
        return (React.createElement(Box, { p: 1 },
            React.createElement(SmsField, { contacts: contacts !== null && contacts !== void 0 ? contacts : [], onMessageSent: handleSmsSent, onClose: closeInput })));
    }
    if (activeInput === "call") {
        return (React.createElement(Box, { p: 1 },
            React.createElement(TwilioDialWidget, { contacts: contacts, onClose: closeInput, onCallPressed: closeInput })));
    }
    if (activeInput === "search" && onSearchUpdate) {
        return React.createElement(ChronologySearch, { onSearchChanged: onSearchUpdate, onClose: handleSearchClose });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, onClick: !disabled ? handleCommentFieldClicked : undefined, spacing: 1, style: {
                background: "#ccc",
                borderRadius: "1rem",
                cursor: !disabled ? "pointer" : undefined,
            } },
            !disabled && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Add, { fontSize: "small", style: { color: AppConfig.themeColors.primary } }))),
            React.createElement(Grid, { item: true, style: {
                    marginTop: "auto",
                    marginBottom: "auto",
                    flexGrow: 1,
                    opacity: disabled ? 0.8 : undefined,
                } },
                React.createElement(Typography, { variant: "body2" }, disabled ? "Loading comments..." : "Add comment")),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Tooltip, { title: "Search" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: handleSearchButtonClicked, size: "small", style: { transform: "translate(-2px, -2px)" }, tabIndex: -1 },
                            React.createElement(Search, { fontSize: "small" })))),
                onTaskCreated && (React.createElement(Tooltip, { title: "Add task" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: handleTaskButtonClicked, size: "small", style: { transform: "translate(-2px, -2px)" }, tabIndex: -1 },
                            React.createElement(CheckCircle, { fontSize: "small" }))))),
                React.createElement(Tooltip, { title: "Send SMS" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: handleSmsButtonClicked, size: "small", style: { transform: "translate(-2px, -2px)" }, tabIndex: -1 },
                            React.createElement(Sms, { fontSize: "small" })))),
                React.createElement(Tooltip, { title: "Make a call" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: handleCallButtonClicked, size: "small", style: { transform: "translate(-2px, -2px)" }, tabIndex: -1 },
                            React.createElement(Phone, { fontSize: "small" })))),
                handleFilesSelectedForUpload && (React.createElement(Tooltip, { title: "Upload files" },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: handleUploadButtonClicked, size: "small", style: { transform: "translate(-2px, -2px)" }, tabIndex: -1 },
                            React.createElement(CloudUpload, { fontSize: "small" }))))))),
        React.createElement("input", { type: "file", id: fileInputId.current, multiple: true, style: { display: "none" }, onChange: handleFilesSelectedForUpload_ })));
};
export default ChronologyActions;
