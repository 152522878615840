import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import TwilioDialWidget from "../../context/twilio-calls/TwilioDialWidget";
const CallsPage = () => {
    useDocumentTitle("Calls");
    const [searchParams] = useSearchParams();
    const twilioPhoneNumber = searchParams.get("twilio_phone");
    const externalPhoneNumber = searchParams.get("external_phone");
    return (React.createElement(Container, { maxWidth: "xs" },
        React.createElement(Box, { p: 2 },
            React.createElement(TwilioDialWidget, { defaultTwilioPhone: twilioPhoneNumber !== null && twilioPhoneNumber !== void 0 ? twilioPhoneNumber : undefined, defaultExternalPhone: externalPhoneNumber !== null && externalPhoneNumber !== void 0 ? externalPhoneNumber : undefined }))));
};
export default CallsPage;
