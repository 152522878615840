import * as React from "react";
import { Alert } from "@mui/material";
import useSession from "@app/hooks/useSession";
const LockedContractNotification = () => {
    var _a;
    const session = useSession();
    return (React.createElement(Alert, { severity: "info", variant: "outlined" },
        "This contract is locked and can't be edited.",
        " ",
        ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]))
            ? "Click the lock icon above to unlock the contract."
            : "Contact an admin if you need to make any changes."));
};
export default LockedContractNotification;
