/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Review current and historical tax and assessment values, aiding in property valuation and tax planning. **IMPORTANT: This endpoint costs 40 cents per use if using production HouseCanary API credentials!**

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Get Tax History
 */
export const getHousecanaryTaxHistory = (params, options) => {
    return axios.get(`/api/housecanary/get_tax_history`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetHousecanaryTaxHistoryQueryKey = (params) => {
    return [`/api/housecanary/get_tax_history`, ...(params ? [params] : [])];
};
export const getGetHousecanaryTaxHistoryQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetHousecanaryTaxHistoryQueryKey(params);
    const queryFn = ({ signal }) => getHousecanaryTaxHistory(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get Tax History
 */
export function useGetHousecanaryTaxHistory(params, options) {
    const queryOptions = getGetHousecanaryTaxHistoryQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
