var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Add, Delete, Save, Close, DragHandle } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, Button, TextField, Tooltip, Typography, List, Skeleton, ListItemButton, ListItemText, ListItem, ListItemIcon, ListItemSecondaryAction, } from "@mui/material";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import groupBy from "lodash/groupBy";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import CoreError from "@app/common/CoreError";
import LatentComponent from "@app/common/LatentComponent";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useAddChoiceSet, useDeleteChoiceSet, useGetChoiceSet, useListChoiceSets, useUpdateChoiceSet, } from "@app/orval/api/choice-sets";
import { useListFields } from "@app/orval/api/field-definitions";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import Session from "@app/util/Session";
import { replaceItemInArray } from "@app/util/Utils";
const DEFAULT_SUBMISSION = {
    name: "",
    choices: [],
};
const ChoiceSetForm = (props) => {
    var _a, _b, _c, _d, _e;
    const { uid } = props;
    const droppableId = React.useRef(uuidv4());
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState(uid ? null : DEFAULT_SUBMISSION);
    const getChoiceSetApi = useGetChoiceSet(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: false } });
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = (_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const fieldsByChoiceSet = React.useMemo(() => {
        return fields ? groupBy(fields, (val) => { var _a; return (_a = val.choice_set) === null || _a === void 0 ? void 0 : _a.uid; }) : null;
    }, [fields]);
    const listChoiceSetsApi = useListChoiceSets();
    const createChoiceSetApi = useAddChoiceSet();
    const updateChoiceSetApi = useUpdateChoiceSet();
    const deleteChoiceSetApi = useDeleteChoiceSet();
    const loading = useLoading({
        items: [
            {
                label: "Loading choice set...",
                queryResult: getChoiceSetApi,
                isRelevant: !formData,
            },
            {
                label: "Loading fields...",
                queryResult: listFieldsApi,
            },
            {
                label: "Creating new choice set...",
                mutationResult: createChoiceSetApi,
            },
            {
                label: "Updating choice set...",
                mutationResult: updateChoiceSetApi,
            },
            {
                label: "Deleting choice set...",
                mutationResult: deleteChoiceSetApi,
            },
        ],
    });
    React.useEffect(() => {
        if (uid) {
            getChoiceSetApi
                .refetch()
                .then((result) => {
                var _a;
                const data = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data;
                if (data) {
                    setFormData({
                        name: data.name,
                        choices: data.choices.map((c) => (Object.assign(Object.assign({}, c), { randomId: uuidv4() }))),
                    });
                }
                else {
                    setFormData(null);
                }
            })
                .catch(errorDialog);
        }
        else {
            setFormData(DEFAULT_SUBMISSION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const addChoice = React.useCallback(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { choices: [
                ...prev.choices,
                {
                    name: "New Choice",
                    randomId: uuidv4(),
                },
            ] })));
    }, [setFormData]);
    const deleteChoice = React.useCallback((indx) => () => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { choices: prev.choices.filter((_, i) => i !== indx) })));
    }, [setFormData]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const reorderChoices = React.useCallback((result) => {
        if (result.destination) {
            setFormData((prev) => {
                const updatedChoices = prev.choices;
                const choice = updatedChoices[result.source.index];
                updatedChoices.splice(result.source.index, 1);
                updatedChoices.splice(result.destination.index, 0, choice);
                return Object.assign(Object.assign({}, prev), { choices: updatedChoices });
            });
        }
    }, [setFormData]);
    const setChoiceName = React.useCallback((indx) => (e) => {
        e.persist();
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { choices: [
                ...prev.choices.slice(0, indx),
                Object.assign(Object.assign({}, prev.choices[indx]), { name: e.target.value }),
                ...prev.choices.slice(indx + 1, prev.choices.length),
            ] })));
    }, [setFormData]);
    const saveChoiceSet = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield confirm({
            title: "Are you sure",
            description: "Certain features in the application are dependent on item names. Changing or removing items may have undesirable effects, and changing the name of the choice set will almost certainly break certain fields; best to consult a developer before making adjustments here.",
            confirmationText: "Save",
            confirmationButtonProps: { color: "primary" },
        });
        if (result.confirmed) {
            const data = {
                name: formData.name,
                choices: formData.choices.map((choice) => ({
                    uid: choice.uid,
                    name: choice.name,
                })),
            };
            console.log({ data });
            if (uid) {
                updateChoiceSetApi
                    .mutateAsync({ uid, data })
                    .then((result) => {
                    if (listChoiceSetsApi.data) {
                        queryClient.setQueryData(listChoiceSetsApi.queryKey, (old) => {
                            if (!old)
                                return old;
                            return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                        });
                    }
                    navigate("/choice-sets");
                })
                    .catch(errorDialog);
            }
            else {
                createChoiceSetApi
                    .mutateAsync({ data })
                    .then((result) => {
                    if (listChoiceSetsApi.data) {
                        queryClient.setQueryData(listChoiceSetsApi.queryKey, (old) => {
                            if (!old)
                                return old;
                            return Object.assign(Object.assign({}, old), { data: [...listChoiceSetsApi.data.data, result.data] });
                        });
                    }
                    navigate("/choice-sets");
                })
                    .catch(errorDialog);
            }
        }
    }), [
        confirm,
        createChoiceSetApi,
        errorDialog,
        formData,
        listChoiceSetsApi.data,
        listChoiceSetsApi.queryKey,
        navigate,
        queryClient,
        uid,
        updateChoiceSetApi,
    ]);
    const deleteChoiceSet = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS), { description: "Removing a choice set won't break any existing features, but you won't be able to use this choice set with other fields." })).then(() => {
            deleteChoiceSetApi
                .mutateAsync({ uid: uid })
                .then((result) => {
                if (listChoiceSetsApi.data) {
                    queryClient.setQueryData(listChoiceSetsApi.queryKey, (old) => {
                        if (!old)
                            return old;
                        return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== result.data.uid) });
                    });
                }
                navigate("/choice-sets");
            })
                .catch(errorDialog);
        });
    }, [
        confirm,
        deleteChoiceSetApi,
        errorDialog,
        listChoiceSetsApi.data,
        listChoiceSetsApi.queryKey,
        navigate,
        queryClient,
        uid,
    ]);
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    if (!formData) {
        return React.createElement(CoreError, { error: "An error occurred loading the data." });
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 2 },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Typography, { variant: "h6" }, formData.name.trim() || (uid ? "Edit Choice Set" : "New Choice Set")))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 3, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Name", value: formData.name || "", onChange: setName, error: (formData.name || "").trim() === "", helperText: (formData.name || "").trim() === "" ? "This field is required" : null })),
                    React.createElement(Grid2, null,
                        React.createElement(Divider, null)),
                    React.createElement(Grid2, null,
                        React.createElement(DragDropContext, { onDragEnd: reorderChoices },
                            React.createElement(Droppable, { droppableId: droppableId.current }, (droppableProvided) => (React.createElement(List, Object.assign({ dense: true }, droppableProvided.droppableProps, { ref: droppableProvided.innerRef }),
                                formData.choices.map((choice, indx) => (React.createElement(Draggable, { key: choice.randomId, draggableId: choice.randomId, index: indx }, (draggableProvided) => (React.createElement(ListItem, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps),
                                    formData.choices.length > 0 && (React.createElement(ListItemIcon, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps, { style: {
                                            cursor: "grab",
                                        } }),
                                        React.createElement(DragHandle, null))),
                                    React.createElement(ListItemText, { primary: React.createElement(LatentComponent, { value: choice.name, style: { cursor: "pointer" } },
                                            React.createElement(TextField, { size: "small", variant: "standard", value: choice.name, onChange: setChoiceName(indx), fullWidth: true, autoFocus: true })) }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Delete choice") },
                                            React.createElement(IconButton, { onClick: deleteChoice(indx) },
                                                React.createElement(Delete, null))))))))),
                                droppableProvided.placeholder))))),
                    ((_c = Session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin"])) && (React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", onClick: addChoice, tabIndex: -1, startIcon: React.createElement(Add, { fontSize: "small" }), variant: "outlined" }, "Add")))))),
        uid &&
            (fieldsByChoiceSet ? (React.createElement(Grid2, { container: true, spacing: 1, direction: "column" },
                React.createElement(Grid2, null,
                    React.createElement(Box, { p: 2 },
                        React.createElement(Typography, { variant: "h6" }, fieldsByChoiceSet[uid] && fieldsByChoiceSet[uid].length > 0
                            ? "This choice set is used by the following fields:"
                            : "This choice set isn't currently used by any fields."))),
                fieldsByChoiceSet[uid] && fieldsByChoiceSet[uid].length > 0 && (React.createElement(Grid2, null,
                    React.createElement(List, { dense: true }, fieldsByChoiceSet[uid].map((x) => (React.createElement(ListItemButton, { key: x.uid, component: RouterLink, to: `/field-management?field=${x.uid}` },
                        React.createElement(ListItemText, { primary: x.name }))))))))) : (React.createElement(Skeleton, null))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pt: 0 },
                React.createElement(Grid2, { container: true, spacing: 1 },
                    uid && ((_d = Session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.hasRole(["Superadmin"])) && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Button, { size: "small", onClick: deleteChoiceSet, startIcon: React.createElement(Delete, null), tabIndex: -1, color: "error", variant: "contained" }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Button, { size: "small", component: RouterLink, to: "/choice-sets", tabIndex: -1, startIcon: React.createElement(Close, null) }, "Close")),
                    ((_e = Session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole(["Superadmin"])) && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Button, { size: "small", onClick: saveChoiceSet, startIcon: React.createElement(Save, null), tabIndex: -1, color: "primary", variant: "outlined" }, "Save"))))))));
};
export default ChoiceSetForm;
