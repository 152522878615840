var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import BulkDeleteConfirmDialog from "./BulkDeleteConfirmDialog";
export const BulkDeleteConfirmContext = React.createContext({
    confirmBulkDelete: () => __awaiter(void 0, void 0, void 0, function* () { return false; }),
});
export const BulkDeleteConfirmProvider = (props) => {
    const [confirmDialogNumBoxes, setConfirmDialogNumBoxes] = React.useState(null);
    const [resolvePromise, setResolvePromise] = React.useState(null);
    const confirmBulkDelete = React.useCallback((numBoxes) => __awaiter(void 0, void 0, void 0, function* () {
        setConfirmDialogNumBoxes(numBoxes);
        return new Promise((resolve) => {
            setResolvePromise(() => resolve);
        });
    }), []);
    const onCancel = React.useCallback(() => {
        setConfirmDialogNumBoxes(null);
        if (resolvePromise) {
            resolvePromise(false);
            setResolvePromise(null);
        }
    }, [resolvePromise]);
    const onConfirm = React.useCallback(() => {
        setConfirmDialogNumBoxes(null);
        if (resolvePromise) {
            resolvePromise(true);
            setResolvePromise(null);
        }
    }, [resolvePromise]);
    const value = React.useMemo(() => {
        return {
            confirmBulkDelete,
        };
    }, [confirmBulkDelete]);
    return (React.createElement(BulkDeleteConfirmContext.Provider, { value: value },
        props.children,
        confirmDialogNumBoxes != null && (React.createElement(BulkDeleteConfirmDialog, { onCancel: onCancel, onConfirm: onConfirm, numBoxes: confirmDialogNumBoxes }))));
};
