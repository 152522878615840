import * as React from "react";
import { CheckCircle } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import TaskComponent from "./Task";
const Tasks = (props) => {
    const { onTaskDeleted, onTaskUpdated, tasks } = props;
    const now = new Date().toISOString();
    const incompleteTasks = tasks.filter((task) => !task.is_complete);
    const numOverdueTasks = incompleteTasks.filter((task) => task.due_date && dayjs(task.due_date).toISOString() < now).length;
    const numUpcomingTasks = incompleteTasks.filter((task) => !task.due_date || dayjs(task.due_date).toISOString() >= now).length;
    const numCompletedTasks = tasks.filter((task) => task.is_complete).length;
    const handleUpdate = React.useCallback((uid) => (task) => {
        onTaskUpdated(uid, task);
    }, [onTaskUpdated]);
    const handleDelete = React.useCallback((uid) => () => {
        onTaskDeleted(uid);
    }, [onTaskDeleted]);
    if (incompleteTasks.length === 0) {
        return null;
    }
    return (React.createElement(Grid, { container: true, direction: "row", spacing: 1, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(CheckCircle, null)),
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(Grid, { container: true, direction: "column", spacing: 1, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, direction: "row", spacing: 1, wrap: "nowrap", alignItems: "center" },
                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Upcoming Tasks")),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "caption" },
                                numOverdueTasks,
                                " overdue, ",
                                numUpcomingTasks,
                                " upcoming, and ",
                                numCompletedTasks,
                                " completed")))),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" }, incompleteTasks
                        .sort((a, b) => {
                        if (a.due_date && !b.due_date) {
                            return -1;
                        }
                        if (!a.due_date && b.due_date) {
                            return 1;
                        }
                        if (!a.due_date && !b.due_date) {
                            return 0;
                        }
                        if (a.due_date < b.due_date) {
                            return -1;
                        }
                        if (a.due_date > b.due_date) {
                            return 1;
                        }
                        return 0;
                    })
                        .map((task) => (React.createElement(Grid, { key: task.uid, item: true },
                        React.createElement(TaskComponent, { task: task, onChange: handleUpdate(task.uid), onDelete: handleDelete(task.uid) }))))))))));
};
export default Tasks;
