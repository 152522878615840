/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List interview types
 */
export const listInterviewTypes = (options) => {
    return axios.get(`/api/lists/interview_types`, options);
};
export const getListInterviewTypesQueryKey = () => {
    return [`/api/lists/interview_types`];
};
export const getListInterviewTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListInterviewTypesQueryKey();
    const queryFn = ({ signal }) => listInterviewTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List interview types
 */
export function useListInterviewTypes(options) {
    const queryOptions = getListInterviewTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
