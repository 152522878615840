import * as React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
const ConversationUnscehduleMessageDialog = (props) => {
    const { DialogProps, onCancel, onConfirm } = props;
    return (React.createElement(Dialog, Object.assign({}, DialogProps),
        React.createElement(DialogTitle, null, "Cancel this message?"),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onCancel, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onConfirm, color: "primary", tabIndex: -1 }, "OK"))));
};
export default ConversationUnscehduleMessageDialog;
