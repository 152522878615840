var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { ArrowDropDown, Edit, FilterList, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, SortByAlpha, VisibilityOff, } from "@mui/icons-material";
import { Grid, Typography, MenuItem, Divider } from "@mui/material";
import styled from "styled-components";
import CoreMenu from "@app/common/CoreMenu";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
export const renderHeader = (props) => 
// eslint-disable-next-line react/display-name
(params) => {
    return React.createElement(Header, Object.assign({ gridColumnHeaderParams: params }, props));
};
const MenuButton = styled("div") `
  border: 1px solid #999;
  border-radius: 2px;
  height: 10px;
  width: 10px;
  &:hover {
    background: black;
    color: white;
  }
`;
const Header = (props) => {
    var _a, _b;
    const { disableViews, fieldUid, gridApi, gridColumnHeaderParams, onFilter, onFreeze, onGroup, onHide, onMove, onSort, onUnhide, } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const session = useSession();
    const canManageColumn = true; // anybody can mess with the column arrangement, but only admins can actually save the column arrangement
    const canEditField = React.useMemo(() => {
        var _a;
        return (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin"]);
    }, [session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const toggleMenu = React.useCallback((e) => {
        setAnchorEl((prev) => (prev ? null : e.currentTarget));
    }, [setAnchorEl]);
    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const handleFilterSelected = React.useCallback(() => {
        onFilter(gridColumnHeaderParams.field);
        closeMenu();
    }, [closeMenu, gridColumnHeaderParams.field, onFilter]);
    const pinnedColumns = (gridApi === null || gridApi === void 0 ? void 0 : gridApi.getPinnedColumns) ? new Set((_a = gridApi.getPinnedColumns().left) !== null && _a !== void 0 ? _a : []) : null;
    const isFrozen = (_b = pinnedColumns === null || pinnedColumns === void 0 ? void 0 : pinnedColumns.has(gridColumnHeaderParams.field)) !== null && _b !== void 0 ? _b : false;
    const currentIndx = (gridApi === null || gridApi === void 0 ? void 0 : gridApi.getAllColumns)
        ? gridApi.getAllColumns().findIndex((c) => {
            return c.field === gridColumnHeaderParams.field;
        })
        : -1;
    let nextVisibleColumnIndex = null;
    if (currentIndx !== -1) {
        const visibleColumns = new Set(gridApi.getVisibleColumns().map((x) => x.field));
        const allColumns = gridApi.getAllColumns();
        for (let i = currentIndx + 1; i < allColumns.length; i++) {
            if (visibleColumns.has(allColumns[i].field)) {
                nextVisibleColumnIndex = i;
                break;
            }
        }
    }
    const handleMoveLeftmostClicked = React.useCallback(() => {
        if (currentIndx !== -1) {
            console.log({ currentIndx });
            onMove(gridColumnHeaderParams.field, 0);
        }
        closeMenu();
    }, [closeMenu, currentIndx, gridColumnHeaderParams.field, onMove]);
    const handleMoveLeftClicked = React.useCallback(() => {
        if (currentIndx !== -1) {
            console.log({ currentIndx });
            onMove(gridColumnHeaderParams.field, currentIndx - 1);
        }
        closeMenu();
    }, [closeMenu, currentIndx, gridColumnHeaderParams.field, onMove]);
    const handleMoveRightClicked = React.useCallback(() => {
        if (currentIndx !== -1) {
            onMove(gridColumnHeaderParams.field, currentIndx + 1);
        }
        closeMenu();
    }, [closeMenu, currentIndx, gridColumnHeaderParams.field, onMove]);
    const handleMoveRightmostClicked = React.useCallback(() => {
        if (currentIndx !== -1) {
            onMove(gridColumnHeaderParams.field, gridApi.getAllColumns().length);
        }
        closeMenu();
    }, [closeMenu, currentIndx, gridApi, gridColumnHeaderParams.field, onMove]);
    const handleOnHideClicked = React.useCallback(() => {
        onHide(gridColumnHeaderParams.field);
        closeMenu();
    }, [onHide, gridColumnHeaderParams.field, closeMenu]);
    const handleOnFreezeClicked = React.useCallback(() => {
        onFreeze(gridColumnHeaderParams.field, !isFrozen);
        closeMenu();
    }, [onFreeze, gridColumnHeaderParams.field, isFrozen, closeMenu]);
    const handleUnhideColumns = React.useCallback(() => {
        const firstColumnToUnhide = currentIndx + 1;
        const lastColumnToUnhide = nextVisibleColumnIndex;
        if (lastColumnToUnhide != null) {
            const columnsToUnhide = gridApi.getAllColumns().slice(firstColumnToUnhide, lastColumnToUnhide + 1);
            const columnNames = columnsToUnhide.map((c) => c.field);
            onUnhide(columnNames);
        }
    }, [currentIndx, gridApi, nextVisibleColumnIndex, onUnhide]);
    const sortAscending = React.useCallback(() => {
        onSort(gridColumnHeaderParams.field, "ascending");
    }, [gridColumnHeaderParams.field, onSort]);
    const sortDescending = React.useCallback(() => {
        onSort(gridColumnHeaderParams.field, "descending");
    }, [gridColumnHeaderParams.field, onSort]);
    const columnLabel = gridColumnHeaderParams.colDef.headerName;
    const fieldName = gridColumnHeaderParams.field;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, wrap: "nowrap", style: { width: "100%" } },
            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto", overflow: "hidden" } },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis" } }, columnLabel)),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", paddingRight: "5px" } },
                React.createElement(MenuButton, { onClick: toggleMenu },
                    React.createElement(ArrowDropDown, { fontSize: "large", style: { width: "100%", height: "100%", transform: "translateY(-1px)" } })),
                React.createElement(CoreMenu, { anchorEl: anchorEl, open: Boolean(anchorEl), keepMounted: true, onClose: closeMenu, style: {
                        color: "#999999",
                    } },
                    !disableViews && (React.createElement(HeaderMenuItem, { icon: React.createElement(FilterList, null), disabled: !onFilter, onClick: handleFilterSelected },
                        "Filter by ",
                        columnLabel)),
                    (!disableViews || !!onSort) && gridColumnHeaderParams.colDef.sortable !== false && (React.createElement(HeaderMenuItem, { icon: React.createElement(SortByAlpha, null), disabled: !onSort, onClick: sortAscending },
                        "Sort by ",
                        columnLabel,
                        " ascending")),
                    (!disableViews || !!onSort) && gridColumnHeaderParams.colDef.sortable !== false && (React.createElement(HeaderMenuItem, { icon: React.createElement(SortByAlpha, null), disabled: !onSort, onClick: sortDescending },
                        "Sort by ",
                        columnLabel,
                        " descending")),
                    !disableViews && React.createElement(HeaderMenuItem, { disabled: !onGroup },
                        "Group by ",
                        columnLabel),
                    canManageColumn && !disableViews && React.createElement(Divider, null),
                    canManageColumn && (React.createElement(HeaderMenuItem, { icon: React.createElement(KeyboardDoubleArrowLeft, null), disabled: !onMove, onClick: handleMoveLeftmostClicked }, "Move leftmost")),
                    canManageColumn && (React.createElement(HeaderMenuItem, { icon: React.createElement(KeyboardArrowLeft, null), disabled: !onMove, onClick: handleMoveLeftClicked }, "Move left")),
                    canManageColumn && (React.createElement(HeaderMenuItem, { icon: React.createElement(KeyboardArrowRight, null), disabled: !onMove, onClick: handleMoveRightClicked }, "Move right")),
                    canManageColumn && (React.createElement(HeaderMenuItem, { icon: React.createElement(KeyboardDoubleArrowRight, null), disabled: !onMove, onClick: handleMoveRightmostClicked }, "Move rightmost")),
                    canManageColumn && (React.createElement(HeaderMenuItem, { icon: React.createElement(VisibilityOff, null), disabled: !onHide, onClick: handleOnHideClicked }, "Hide")),
                    canManageColumn && (React.createElement(HeaderMenuItem, { disabled: !onFreeze, onClick: handleOnFreezeClicked }, (pinnedColumns === null || pinnedColumns === void 0 ? void 0 : pinnedColumns.has(fieldName)) ? "Unfreeze" : "Freeze")),
                    canEditField && fieldUid && React.createElement(Divider, null),
                    canEditField && fieldUid && (
                    // @ts-expect-error Property 'to' does not exist on type
                    React.createElement(HeaderMenuItem, { icon: React.createElement(Edit, null), component: RouterLink, to: `/field-management?field=${fieldUid}` }, "Edit this column..."))))),
        nextVisibleColumnIndex !== currentIndx + 1 && nextVisibleColumnIndex != null && (React.createElement(UnhideWidget, { onUnhide: handleUnhideColumns }))));
};
const HeaderMenuItem = (props) => {
    const { children, icon } = props, other = __rest(props, ["children", "icon"]);
    return (React.createElement(MenuItem, Object.assign({}, other),
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, style: { width: "32px", marginTop: "auto", marginBottom: "auto" } }, icon),
            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, children))));
};
const UnhideWidget = (props) => {
    const { onUnhide } = props;
    return (React.createElement(Grid, { container: true, wrap: "nowrap", style: {
            zIndex: 100,
            background: "#eee",
            border: "1px solid #ccc",
            borderRadius: "0.0625rem",
            position: "absolute",
            right: 0,
            bottom: 0,
            height: "12px",
            width: "32px",
            cursor: "pointer",
            transform: "translate(16px, 5px)",
        }, onClick: onUnhide },
        React.createElement(Grid, { item: true },
            React.createElement(KeyboardArrowLeft, { fontSize: "small", style: { transform: "translateY(-2px)", width: "14px" } })),
        React.createElement(Grid, { item: true },
            React.createElement(KeyboardArrowRight, { fontSize: "small", style: { transform: "translateY(-2px)", width: "14px" } }))));
};
