import * as React from "react";
import { Box, Divider, Grid } from "@mui/material";
import SavedViewActions from "./SavedViewActions";
import SavedViewGrouping from "./SavedViewGrouping";
import SavedViewHeader from "./SavedViewHeader";
import SavedViewLoading from "./SavedViewLoading";
import SavedViewSection from "./SavedViewSection";
import SavedViewSharingDescription from "./SavedViewSharingDescription";
import SavedViewSorting from "./SavedViewSorting";
import SavedViewSummary from "./SavedViewSummary";
import FilterGroups from "../../filters/FilterGroups";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewEditorInner = () => {
    var _a, _b;
    const context = useSavedViewEditorContext();
    const onUpdateFilters = React.useCallback((filterGroups) => {
        context.setView(Object.assign(Object.assign({}, context.view), { filter_groups: filterGroups }));
    }, [context]);
    if (context.isLoading) {
        return React.createElement(SavedViewLoading, null);
    }
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", style: { backgroundColor: "rgb(244,252,255)", maxHeight: "75%", overflow: "hidden" } },
        context.uid && context.initialData && (React.createElement(Grid, { item: true },
            React.createElement(SavedViewSummary, null))),
        context.isEditing && context.canEdit && (React.createElement(Grid, { item: true, style: { border: "1px solid #cccccc", overflow: "auto" } },
            React.createElement(Grid, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(SavedViewSharingDescription, null)),
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2, pb: 0 },
                        React.createElement(SavedViewHeader, null))),
                React.createElement(Grid, { item: true },
                    React.createElement(Divider, null)),
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2, pt: 0, pb: 0 },
                        React.createElement(SavedViewSection, { title: "Filters" },
                            React.createElement(FilterGroups, { onUpdate: onUpdateFilters, filterGroups: (_b = (_a = context.view) === null || _a === void 0 ? void 0 : _a.filter_groups) !== null && _b !== void 0 ? _b : [], entityType: context.entityType })))),
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2, pt: 0, pb: 0 },
                        React.createElement(SavedViewSection, { title: "Sorting" },
                            React.createElement(SavedViewSorting, null)))),
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2, pt: 0, pb: 0 },
                        React.createElement(SavedViewSection, { title: "Grouping" },
                            React.createElement(SavedViewGrouping, null)))),
                React.createElement(Grid, { item: true },
                    React.createElement(Divider, null)),
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2, pt: 0 },
                        React.createElement(SavedViewActions, null))))))));
};
export default SavedViewEditorInner;
