import { htmlToText } from "html-to-text";
import AppConfig from "@app/util/AppConfig";
export const bodyHelperText = `
  You can use <b>$\'Field Name\'</b> syntax to add a Streak field to the template.
  Mail Schedule 3.0 also supports <a href="https://palletsprojects.com/p/jinja/" target="_blank">Jinja2-style template markup.</a>
  `
    .trim()
    .replace(/\s+/g, " ");
/** Available fonts for ReactQuill text editor */
export const quillFontWhitelist = [
    "Arial",
    "Verdana",
    "Helvetica",
    "Tahoma",
    "Trebuchet",
    "Times",
    "Georgia",
    "Garamond",
    "Courier",
    "Nunita",
];
const HARD_CODED_WILDCARDS = [
    "Client #1 First",
    "Client #1 Last",
    "Client #1 Email",
    "Client #1 Phone",
    "Client #2 First",
    "Client #2 Last",
    "Client #2 Email",
    "Client #2 Phone",
    "Lender Name",
    "Lender Email",
    "Lender Phone",
    "Lender Company",
    "Listing Agent Name",
    "Listing Agent Email",
    "Listing Agent Phone",
    "Listing Agent Company",
    "Site Manager Name",
    "Site Manager Email",
    "Site Manager Phone",
    "Site Manager Company",
    "Producer Name",
    "Producer Email",
    "Producer Phone",
    "Co-Agent Name",
    "Co-Agent Email",
    "Co-Agent Phone",
    "Listing Coordinator Name",
    "Listing Coordinator Email",
    "Listing Coordinator Phone",
    "Listing Coordinator Name #2",
    "Listing Coordinator Email #2",
    "Listing Coordinator Phone #2",
    "Closing Attorney Name",
    "Closing Attorney Phone",
    "Closing Attorney Email",
    "Paralegal Name",
    "Paralegal Phone",
    "Paralegal Email",
];
export const MAIL_SCHEDULE_ENTITY_TYPES = [
    "Buyer Lead",
    "Buyer Contract",
    "Seller Lead",
    "Listing",
    "User",
    "Recruit",
];
export const getMarkupForMergeFieldAttribute = (value) => (value !== null && value !== void 0 ? value : "").replace(/(\$\'.*?\')/g, `<font color='${AppConfig.themeColors.primary}'>$1</font>`);
/** Replaces markup with $'Field Name' syntax to view more easily in the table, export to csv, etc. */
export const sanitize = (template, fields, entity) => {
    var _a;
    const t = template;
    for (const key of Object.keys(t)) {
        if (typeof t[key] === "string" && t[key].includes("$[")) {
            for (const field of [
                ...fields,
                ...HARD_CODED_WILDCARDS.map((x) => ({ uid: x, name: x })),
                { uid: "assignedToSharingEntries", name: "Assigned Agents" },
            ]) {
                try {
                    const regexp = new RegExp(`\\$\\[(.*?)\\]\\(${field.uid}\\)`, "g");
                    const replacement = (entity === null || entity === void 0 ? void 0 : entity.fields[field.name]) && String(entity.fields[field.name]).trim() !== ""
                        ? `<b title="${field.name}"><font color="${AppConfig.themeColors.primary}">${htmlToText(String((_a = entity.fields[field.name]) !== null && _a !== void 0 ? _a : ""))}</font></b>`
                        : `$$'${field.name}'`;
                    t[key] = t[key].replace(regexp, replacement);
                }
                catch (err) {
                    console.warn(`Unable to do sanitization for field ${field.name} for the \`${key}\` parameter in template ${t["name"]}`, err);
                    break;
                }
            }
            // Replace any remaining $[...] with $$'...'
            t[key] = t[key].replace(/\$\[(.*?)\]\((.*?)\)/g, "<font color='#666'>$$'$1'</font>");
        }
        else if (t[key] && t[key].constructor === Object) {
            t[key] = sanitize(template[key], fields, entity);
        }
    }
    return t;
};
export const getFlattenedCriteria = (filterGroups, parentUid) => {
    const matchingGroups = filterGroups.filter((group) => group.parent_uid == parentUid);
    const filters = matchingGroups.map((x) => x.filters).flat();
    console.log({ flattened: filters });
    return [...filters, ...matchingGroups.map((x) => getFlattenedCriteria(filterGroups, x.uid)).flat()];
};
export const Formatters = {
    Currency: {
        name: "::curr",
        description: "U.S. dollars, with no cents. (ex: _$450,000_)",
    },
    Datetime: {
        name: "::datetime",
        description: "Date in `dddd, MMMM D` (at) `h:mm am/pm` format. (ex: _Sunday, January 1 at 1:00 pm_)",
    },
    Fulldate: {
        name: "::fulldate",
        description: "Date in `dddd, MMMM D, YYYY` format. (ex: _Sunday, January 1, 2021_)",
    },
    MMDDYY: {
        name: "::mmddyy",
        description: "Date in `MM/DD/YY` format. (ex: _01/01/21_)",
    },
    Birthday: {
        name: "::birthday",
        description: "Date in `dddd, MMMM D` format. (ex: _Sunday, January 1_)",
    },
    DaysUntil: {
        name: "::daysuntil",
        description: "The number of days until a given date. Negative if the current date is past the given date.",
    },
    DaysSince: {
        name: "::dayssince",
        description: "The number of days since a given date. Negative if the current date is before the given date.",
    },
    Date: {
        name: "::date",
        description: "Date in `MMMM D, YYYY` format. (ex: _January 1, 2021_)",
    },
    TinyURL: {
        name: "::tinyurl",
        description: "Convertes a long URL to a branded tiny URL",
    },
};
