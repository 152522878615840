import * as React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { FilterContext } from "../FilterContext";
const SavedViewUserFilter = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.user_filter.comparator = comparator;
        if (comparator === "is any of" || comparator === "is not any of") {
            newFilter.user_filter.rhs_values = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValues = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.user_filter.rhs_values = val;
        newFilter.user_filter.rhs_offices = undefined;
        newFilter.user_filter.rhs_teams = undefined;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSOffices = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.user_filter.rhs_values = undefined;
        newFilter.user_filter.rhs_offices = val;
        newFilter.user_filter.rhs_teams = undefined;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSTeams = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.user_filter.rhs_values = undefined;
        newFilter.user_filter.rhs_offices = undefined;
        newFilter.user_filter.rhs_teams = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.userComparators;
    console.log({ users: context.users });
    return (React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true, style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.user_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["is any of", "is not any of"].includes(filter.user_filter.comparator) && (React.createElement(Grid, { item: true, style: { minWidth: "200px" } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_b = filter.user_filter.rhs_values) !== null && _b !== void 0 ? _b : [], onChoicesChange: setRHSValues, options: ((_c = context.users) !== null && _c !== void 0 ? _c : []).reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.uid]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, ((_d = context.users) !== null && _d !== void 0 ? _d : [])
                .sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
                .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name)))))),
        ["is in office"].includes(filter.user_filter.comparator) && (React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_e = filter.user_filter.rhs_offices) !== null && _e !== void 0 ? _e : [], onChoicesChange: setRHSOffices, options: ((_f = context.offices) !== null && _f !== void 0 ? _f : []).reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.uid]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, ((_g = context.offices) !== null && _g !== void 0 ? _g : []).map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name)))))),
        ["is on team"].includes(filter.user_filter.comparator) && (React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_h = filter.user_filter.rhs_teams) !== null && _h !== void 0 ? _h : [], onChoicesChange: setRHSTeams, options: ((_j = context.teams) !== null && _j !== void 0 ? _j : []).reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.uid]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, ((_k = context.teams) !== null && _k !== void 0 ? _k : []).map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))))));
};
export default SavedViewUserFilter;
