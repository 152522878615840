import * as React from "react";
import { Grid2, MenuItem, TextField } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { FilterContext } from "../FilterContext";
const SavedViewStageFilter = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.stage_filter.comparator = comparator;
        if (comparator === "is any of" || comparator === "is not any of") {
            newFilter.stage_filter.rhs_stage = undefined;
        }
        else {
            newFilter.stage_filter.rhs_stages = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSStage = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.stage_filter.rhs_stage = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSStages = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.stage_filter.rhs_stages = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.stageComparators;
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.stage_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["is any of", "is not any of"].includes(filter.stage_filter.comparator) ? (React.createElement(Grid2, { style: { minWidth: "200px" } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_b = filter.stage_filter.rhs_stages) !== null && _b !== void 0 ? _b : [], onChoicesChange: setRHSStages, options: ((_c = context.stages) !== null && _c !== void 0 ? _c : []).reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.uid]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, ((_d = context.stages) !== null && _d !== void 0 ? _d : [])
                .sort((a, b) => {
                if (a.sort_order < b.sort_order)
                    return -1;
                if (a.sort_order > b.sort_order)
                    return 1;
                return 0;
            })
                .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name)))))) : (React.createElement(Grid2, { style: { width: "200px" } },
            React.createElement(TextField, { size: "small", variant: "standard", select: true, fullWidth: true, value: (_e = filter.stage_filter.rhs_stage) !== null && _e !== void 0 ? _e : "", onChange: setRHSStage, slotProps: {
                    select: {
                        displayEmpty: true,
                    },
                } },
                React.createElement(MenuItem, { value: "" }, "Select stage"),
                ((_f = context.stages) !== null && _f !== void 0 ? _f : []).map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))))));
};
export default SavedViewStageFilter;
