import * as React from "react";
import QuillMention from "quill-mention";
import { ErrorBoundary } from "react-error-boundary";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListVendorTypes } from "@app/orval/api/vendor-types";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import MailScheduleQuillFallback from "./MailScheduleQuillFallback";
import { quillFontWhitelist, Formatters } from "./utils";
Quill.register("modules/mention", QuillMention, true);
const Font = Quill.import("formats/font");
Font.whitelist = quillFontWhitelist;
Quill.register(Font, true);
/** Defines the items in the `ReactQuill` toolbar */
const modules = {
    toolbar: [
        [{ font: Font.whitelist }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        [{ script: "sub" }, { script: "super" }],
        ["link", "image"],
        ["clean"],
    ],
    // clipboard: {
    //   matchVisual: false,
    //   matchers: [["img", () => ({})]],
    // },
};
/** Needed to whitelist the actions associated with `ReactQuill` */
const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "indent",
    // "sub", // for some reason this isn't working
    // "super", // for some reason this isn't working
    "link",
    "image",
    "mention",
];
const StyledReactQuill = styled(ReactQuill) `
  ${quillFontWhitelist
    .map((font) => `
  .ql-picker.ql-font .ql-picker-label[data-value="${font}"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="${font}"]::before
  {
      font-family: "${font}", cursive;;
      content: "${font}" !important;
  }
  .ql-font-${font} {
      font-family: "${font}";
  }
  `)
    .join("")}

  .mention {
      height: 24px;
      width: 65px;
      border-radius: 6px;
      background-color: #d3e1eb;
      padding: 3px 0;
      margin-right: 2px;
      user-select: all;
      > span {
          margin: 0 3px;
      }
  }
  .mention[data-id*="::"] {
      background-color: #daedab;
  }
  .ql-mention-list-container {
      z-index: 1;
      ul {
          box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
          max-height: 200px;
          overflow-y: scroll;
          background: white;
          margin: 0;
          padding: 0;
          li {
              padding: 0.25rem;
              list-style-type: none;
              &:hover {
                  background: #eee;
              }
          }
      }
  }
  .ql-mention-denotation-char {
      display: none;
  }
  .MuiTab-root {
    @media (min-width: 600px) {
      min-width: 160px;
    }
    @media (max-width: 600px) {
      min-width: 72px;
    }
  }
`;
const MailScheduleQuill = (props) => {
    var _a, _b, _c, _d;
    const { ReactQuillProps, boilerplate, entityType, fields } = props;
    const [isUnmounted, setIsUnmounted] = React.useState(false);
    const listVendorTypesApi = useListVendorTypes(INFINITE_CACHE_PARAMS);
    const vendorTypes = (_b = (_a = listVendorTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    const listUserFieldsApi = useListFields({ entity_type: "User" }, INFINITE_CACHE_PARAMS);
    const userFields = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listUserFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_c = listUserFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data]);
    // ReactQuill doesn't have referential stability with the mentions
    // module, so we need to force a remount when the fields or boilerplate
    // change in order for the autocomplete to update correctly. See
    // https://stackoverflow.com/questions/74775040/why-does-react-quill-mentions-not-showing
    React.useEffect(() => {
        setIsUnmounted(true);
        setTimeout(() => {
            setIsUnmounted(false);
        }, 1);
    }, [fields, boilerplate, vendorTypes, entityType, userFields]);
    const source = React.useCallback((searchTerm, renderList, mentionChar) => {
        if (mentionChar === "$" || mentionChar === "$'") {
            const values = [
                {
                    id: "name",
                    value: "Name",
                },
                ...(fields !== null && fields !== void 0 ? fields : []).sort((a, b) => {
                    if (a.value < b.value)
                        return -1;
                    if (a.value > b.value)
                        return 1;
                    return 0;
                }),
            ];
            if (searchTerm.length === 0) {
                renderList(values, searchTerm);
            }
            else {
                const matches = values.filter((v) => v.value.toLowerCase().includes(searchTerm.toLowerCase()));
                renderList(matches, searchTerm);
            }
        }
        else if (mentionChar === ":" || mentionChar === "::") {
            const formatters = [
                Formatters.Birthday.name,
                Formatters.Currency.name,
                Formatters.Date.name,
                Formatters.Datetime.name,
                Formatters.DaysUntil.name,
                Formatters.DaysSince.name,
                Formatters.Fulldate.name,
                Formatters.MMDDYY.name,
                Formatters.TinyURL.name,
            ];
            const values = formatters.map((formatter) => ({
                id: formatter,
                value: formatter,
            }));
            const matches = values.filter((v) => v.value.toLowerCase().includes(searchTerm.toLowerCase()));
            renderList(matches, searchTerm);
        }
    }, 
    // we have some unnecessary dependencies here due to the way ReactQuill works...
    // need to re-render on any of these changes
    // eslint-disable-next-line
    [fields, vendorTypes, boilerplate, entityType, userFields]);
    const mentionModule = {
        allowedChars: /^.*$/,
        mentionDenotationChars: ["$", "$'", "::"],
        source,
        showDenotationChar: false,
        // onSelect: this.onSelect,
    };
    if (isUnmounted) {
        return null;
    }
    return (React.createElement(ErrorBoundary, { fallback: React.createElement(MailScheduleQuillFallback, { value: ReactQuillProps.value }) },
        React.createElement(StyledReactQuill, Object.assign({ modules: Object.assign(Object.assign({}, modules), { mention: mentionModule }), formats: formats, defaultValue: (_d = ReactQuillProps.value) !== null && _d !== void 0 ? _d : "" }, ReactQuillProps))));
};
export default MailScheduleQuill;
