/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Update which users are allowed to access each of our Slack channels

_Authorization: none required_
 * @summary Update Slack access
 */
export const updateSlackAccess = (slackAccessWriteRequest, options) => {
    return axios.post(`/api/update_slack_access`, slackAccessWriteRequest, options);
};
export const getUpdateSlackAccessMutationOptions = (options) => {
    const mutationKey = ['updateSlackAccess'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return updateSlackAccess(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update Slack access
*/
export const useUpdateSlackAccess = (options) => {
    const mutationOptions = getUpdateSlackAccessMutationOptions(options);
    return useMutation(mutationOptions);
};
