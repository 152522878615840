import * as React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { FilterContext } from "../FilterContext";
const SavedViewTextFilter = (props) => {
    var _a, _b, _c, _d, _e;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const textFields = (_b = (_a = context.fields) === null || _a === void 0 ? void 0 : _a.filter((f) => f.field_type === "text")) !== null && _b !== void 0 ? _b : [];
    const filter = context.filterGroups
        .reduce((acc, fg) => {
        return [...acc, ...fg.filters];
    }, [])
        .find((f) => f.uid === uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.text_filter.comparator = comparator;
        if (comparator === "is not set" || comparator === "is set") {
            newFilter.text_filter.rhs_field = undefined;
            newFilter.text_filter.rhs_value = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSField = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.text_filter.rhs_field = val;
        newFilter.text_filter.rhs_value = val ? undefined : "";
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValue = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.text_filter.rhs_value = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.textFieldComparators;
    return (React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true, style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_c = filter.text_filter.comparator) !== null && _c !== void 0 ? _c : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["contains", "does not contain", "equals", "does not equal"].includes(filter.text_filter.comparator) && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, style: { width: "200px" } },
                React.createElement(TextField, { size: "small", variant: "standard", select: true, fullWidth: true, value: (_d = filter.text_filter.rhs_field) !== null && _d !== void 0 ? _d : "", onChange: setRHSField, SelectProps: {
                        displayEmpty: true,
                    } },
                    React.createElement(MenuItem, { value: "" }, "a constant value"),
                    textFields
                        .filter((x) => x.uid !== filter.text_filter.lhs_field)
                        .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))),
            !filter.text_filter.rhs_field && (React.createElement(Grid, { item: true, style: { width: "200px" } },
                React.createElement(TextField, { size: "small", variant: "standard", fullWidth: true, value: (_e = filter.text_filter.rhs_value) !== null && _e !== void 0 ? _e : "", onChange: setRHSValue })))))));
};
export default SavedViewTextFilter;
