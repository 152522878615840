import * as React from "react";
export const TwilioSMSContext = React.createContext({
    openConversation: () => { },
    closeConversation: () => { },
    close: () => { },
    conversations: [],
    ignoredMessages: new Set(),
    ignoreMessage: () => { },
});
const TwilioSMSProvider = (props) => {
    const [ignoredMessages, setIgnoredMessages] = React.useState(new Set());
    const [conversations, setConversations] = React.useState([]);
    const openConversation = React.useCallback((params) => {
        setConversations((prev) => [...prev, Object.assign(Object.assign({}, params), { uid: Math.random().toString() })]);
    }, []);
    const close = React.useCallback(() => {
        setConversations([]);
    }, []);
    const closeConversation = React.useCallback((uid) => {
        setConversations((prev) => prev.filter((conversation) => conversation.uid !== uid));
    }, []);
    const ignoreMessage = React.useCallback((messageSid) => {
        setIgnoredMessages((prev) => {
            const next = new Set(prev);
            next.add(messageSid);
            return next;
        });
    }, []);
    const value = React.useMemo(() => {
        return {
            openConversation,
            closeConversation,
            close,
            ignoredMessages,
            ignoreMessage,
            conversations,
        };
    }, [openConversation, closeConversation, close, ignoredMessages, ignoreMessage, conversations]);
    return React.createElement(TwilioSMSContext.Provider, { value: value }, props.children);
};
export default TwilioSMSProvider;
