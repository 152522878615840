import * as React from "react";
import { Notes as NotesIcon } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Notes = (props) => {
    const { canEdit = true, notes, onSetNotes } = props;
    const [value, setValue] = React.useState(notes);
    const onChange = React.useCallback((e) => {
        setValue(e.target.value);
    }, []);
    const setNotes = React.useCallback(() => {
        if (value.trim() !== notes.trim()) {
            onSetNotes(value);
        }
    }, [notes, onSetNotes, value]);
    return (React.createElement(CollapsibleSection, { icon: React.createElement(NotesIcon, null), title: "Notes" }, canEdit ? (React.createElement(TextField, { fullWidth: true, size: "small", multiline: true, minRows: 1, maxRows: 8, value: value, onChange: onChange, onBlur: setNotes })) : (React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic" } }, value))));
};
export default Notes;
