import * as React from "react";
import { Add, Delete, Launch, Refresh, SortByAlpha } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
// import styled from "styled-components";
import CoreLoading from "@app/common/CoreLoading";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING } from "@app/common/grid/utils";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useDeleteCompsfinderRuleset, useListCompsfinderRulesets } from "@app/orval/api/comps-finder-rule-sets";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
// const CoreDataTableWrapper = styled("div")`
//     & tbody tr td:first-child button {
//         visibility: hidden;
//     }
//     & tbody tr td:first-child a {
//         visibility: hidden;
//     }
//     & tbody tr:hover td:first-child button {
//         visibility: unset;
//     }
//     & tbody tr:hover td:first-child a {
//         visibility: unset;
//     }
// `;
const CompsPullerRuleSets = () => {
    var _a, _b;
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const listRuleSetsApi = useListCompsfinderRulesets();
    const ruleSets = (_b = (_a = listRuleSetsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const deleteRuleApi = useDeleteCompsfinderRuleset();
    const refresh = React.useCallback(() => {
        listRuleSetsApi.refetch();
    }, [listRuleSetsApi]);
    const deleteRule = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listRuleSetsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((rule) => rule.uid !== uid) });
                });
                const loadingSnackbar = enqueueSnackbar("Deleting rule...", { variant: "info", persist: true });
                deleteRuleApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Rule Set has been deleted", { variant: "success" });
                })
                    .catch(enqueueErrorSnackbar)
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [confirm, deleteRuleApi, listRuleSetsApi.queryKey, queryClient]);
    const rows = React.useMemo(() => {
        return ruleSets === null || ruleSets === void 0 ? void 0 : ruleSets.map((rule, i) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            return (Object.assign(Object.assign({}, rule), { id: rule.uid, priority: i + 1, states: ((_a = rule.listing_criteria.states) === null || _a === void 0 ? void 0 : _a.join(", ")) || "any", cities: ((_b = rule.listing_criteria.cities) === null || _b === void 0 ? void 0 : _b.join(", ")) || "any", subdivisions: ((_c = rule.listing_criteria.subdivisions) === null || _c === void 0 ? void 0 : _c.join(", ")) || "any", zipcodes: ((_d = rule.listing_criteria.zipcodes) === null || _d === void 0 ? void 0 : _d.join(", ")) || "any", square_footage: `${(_e = rule.listing_criteria.sqft_gte) !== null && _e !== void 0 ? _e : "0"} - ${(_f = rule.listing_criteria.sqft_lte) !== null && _f !== void 0 ? _f : "∞"}`, full_baths: `${(_g = rule.listing_criteria.full_baths_gte) !== null && _g !== void 0 ? _g : "0"} - ${(_h = rule.listing_criteria.full_baths_lte) !== null && _h !== void 0 ? _h : "∞"}`, beds: `${(_j = rule.listing_criteria.beds_gte) !== null && _j !== void 0 ? _j : "0"} - ${(_k = rule.listing_criteria.beds_lte) !== null && _k !== void 0 ? _k : "∞"}`, garage_spaces: `${(_l = rule.listing_criteria.garage_spaces_gte) !== null && _l !== void 0 ? _l : "0"} - ${(_m = rule.listing_criteria.garage_spaces_lte) !== null && _m !== void 0 ? _m : "∞"}`, year_built: `${(_o = rule.listing_criteria.year_built_gte) !== null && _o !== void 0 ? _o : "0"} - ${(_p = rule.listing_criteria.year_built_lte) !== null && _p !== void 0 ? _p : new Date().getFullYear()}` }));
        });
    }, [ruleSets]);
    const columns = React.useMemo(() => {
        const res = [
            {
                field: "uid",
                headerName: " ",
                sortable: false,
                renderCell: (params) => {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Tooltip, { title: "Edit rule" },
                            React.createElement(IconButton, { size: "small", component: RouterLink, to: `/tools/comps-puller/rules/${params.value}` },
                                React.createElement(Launch, { fontSize: "small" }))),
                        React.createElement(Tooltip, { title: "Delete rule..." },
                            React.createElement(IconButton, { size: "small", onClick: deleteRule(params.value) },
                                React.createElement(Delete, { fontSize: "small" })))));
                },
            },
            {
                field: "priority",
                headerName: " ",
                sortable: false,
                cellClassName: "cell-bold",
            },
            {
                field: "name",
                headerName: "Name",
                cellClassName: "cell-bold",
            },
            {
                field: "states",
                headerName: "States",
            },
            {
                field: "cities",
                headerName: "Cities",
            },
            {
                field: "zipcodes",
                headerName: "Zip Codes",
            },
            {
                field: "subdivisions",
                headerName: "Subdivisions",
            },
            {
                field: "square_footage",
                headerName: "Square Footage",
            },
            {
                field: "full_baths",
                headerName: "Full Baths",
            },
            {
                field: "beds",
                headerName: "Beds",
            },
            {
                field: "garage_spaces",
                headerName: "Garage Spaces",
            },
            {
                field: "year_built",
                headerName: "Year Built",
            },
        ];
        return res;
    }, [deleteRule]);
    if (!rows) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 1 },
                React.createElement(Grid2, { container: true, direction: "row" },
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: "Reorder" },
                            React.createElement(IconButton, { component: RouterLink, to: "/tools/comps-puller/rules/reorder" },
                                React.createElement(SortByAlpha, null))),
                        React.createElement(Tooltip, { title: "Refresh" },
                            React.createElement(IconButton, { onClick: refresh },
                                React.createElement(Refresh, null))),
                        React.createElement(Tooltip, { title: "Create new rule" },
                            React.createElement(IconButton, { component: RouterLink, to: "/tools/comps-puller/rules/new" },
                                React.createElement(Add, null))))))),
        React.createElement(Grid2, null, listRuleSetsApi.isFetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnSorting: true, disableColumnResize: true, disableColumnMenu: true, hideFooterSelectedRowCount: true, density: "compact", 
                // pagination={count != null && rows.length < count}
                // rowCount={count ?? undefined}
                paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], 
                // paginationModel={{ page, pageSize: limit }}
                // onPaginationModelChange={onPageChange}
                getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                    toolbarColumns: "",
                }, sx: {
                    border: "none",
                    "& .MuiDataGrid-main": {
                        border: "none",
                    },
                    "& .cell-bold": {
                        fontWeight: "bold",
                    },
                } }))));
};
export default CompsPullerRuleSets;
