/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get buy side pending data
 */
export const getBuySideTowneMortgageData = (params, options) => {
    return axios.get(`/api/reports/towne_mortgage/buy_side`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetBuySideTowneMortgageDataQueryKey = (params) => {
    return [`/api/reports/towne_mortgage/buy_side`, ...(params ? [params] : [])];
};
export const getGetBuySideTowneMortgageDataQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBuySideTowneMortgageDataQueryKey(params);
    const queryFn = ({ signal }) => getBuySideTowneMortgageData(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get buy side pending data
 */
export function useGetBuySideTowneMortgageData(params, options) {
    const queryOptions = getGetBuySideTowneMortgageDataQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get listings data
 */
export const getListingsTowneMortgageData = (options) => {
    return axios.get(`/api/reports/towne_mortgage/listings`, options);
};
export const getGetListingsTowneMortgageDataQueryKey = () => {
    return [`/api/reports/towne_mortgage/listings`];
};
export const getGetListingsTowneMortgageDataQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetListingsTowneMortgageDataQueryKey();
    const queryFn = ({ signal }) => getListingsTowneMortgageData(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get listings data
 */
export function useGetListingsTowneMortgageData(options) {
    const queryOptions = getGetListingsTowneMortgageDataQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
