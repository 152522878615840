import * as React from "react";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";
const QFOSearchResult = (props) => {
    const { qfo } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemIcon, null,
            React.createElement(Typography, { variant: "h6" }, "QFO")),
        React.createElement(ListItemText, null, [qfo.fields["Property Address"]].filter((obj) => obj).join(" • "))));
};
export default QFOSearchResult;
