import * as React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import useSession from "@app/hooks/useSession";
import { formatDateMemoized_Dayjs, getFullName, getInitials } from "@app/util/Utils";
import { convertUtcDateString } from "../date-utils";
import RouterLink from "../RouterLink";
export const UserCell = (props) => {
    var _a, _b;
    const { isClickable, user } = props;
    const session = useSession();
    const isClickable_ = isClickable && ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]));
    return (React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", 
        // @ts-expect-error: no overload matches this call
        component: isClickable_ ? RouterLink : undefined, to: isClickable_ ? `/users/${user.uid}` : undefined, style: isClickable_ ? { textDecoration: "unset" } : undefined },
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Avatar, { style: { width: "18px", height: "18px", fontSize: "0.65rem" }, src: (_b = user.icon_url) !== null && _b !== void 0 ? _b : undefined }, getInitials(user.first_name, user.last_name))),
        React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, { variant: "body2" }, [user.first_name, user.last_name].filter((o) => o).join(" ")))));
};
export const renderUserCell = (params) => {
    const val = params.value;
    if (val) {
        return React.createElement(UserCell, { user: val, isClickable: true });
    }
    return val;
};
export const formatUserCell = (value) => {
    const val = value;
    if (val) {
        return [val.first_name, val.last_name].filter((o) => o).join(" ");
    }
    return val;
};
export const userSortComparator = (v1, v2) => {
    const name1 = getFullName(v1.first_name, v1.last_name).trim();
    const name2 = getFullName(v2.first_name, v2.last_name).trim();
    if (name1 < name2)
        return -1;
    if (name1 > name2)
        return 1;
    return 0;
};
export const renderDateTimeCell_Local = (params) => {
    const val = params.value;
    if (val) {
        return convertUtcDateString(val, { format: "dddd, MMMM D, YYYY h:mm A" });
    }
    return val;
};
export const renderDateCell = (params) => {
    return formatDateMemoized_Dayjs(params.value, "MMMM D, YYYY");
};
export const disableQuickFilterFn = () => null;
export const CLASS_NAME_VERTICAL_HEADER = "vertical-header";
export const CLASS_NAME_NO_FOCUS_RING = "no-focus-ring";
export const GET_CELL_CLASS_NAME_NO_FOCUS_RING = () => {
    return CLASS_NAME_NO_FOCUS_RING;
};
