import * as React from "react";
import { Launch } from "@mui/icons-material";
import { InputAdornment, Autocomplete, IconButton, TextField } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { useListStreakPipelines } from "@app/orval/api/streak-pipelines";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const MaiLSchedule3TemplateAutocomplete = (props) => {
    var _a, _b, _c, _d, _e;
    const { onChange, templates, value } = props;
    const [search, setSearch] = React.useState((_b = (_a = templates.find((x) => x.uid === value)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null);
    const listStreakPipelinesApi = useListStreakPipelines(INFINITE_CACHE_PARAMS);
    const pipelines = (_d = (_c = listStreakPipelinesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const filterOptions = React.useCallback((options) => options
        .filter((template) => !search || template.name.toLowerCase().trim().includes(search.toLowerCase().trim()))
        .slice(0, 100), [search]);
    const groupBy = React.useCallback((option) => {
        var _a, _b;
        if (!pipelines) {
            return "";
        }
        return (_b = (_a = pipelines.find((p) => p.key === option.pipeline)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
    }, [pipelines]);
    const onSearchInputChange = React.useCallback((_, value_, reason) => {
        if (reason !== "reset") {
            setSearch(value_);
            onChange(null);
        }
    }, [onChange]);
    const getOptionLabel = React.useCallback((option) => option.name, []);
    const renderTemplateSearchInput = React.useCallback((params) => (React.createElement(TextField, Object.assign({}, params, { variant: "standard", placeholder: "Select a template...", size: "small", InputProps: {
            endAdornment: value ? (React.createElement(InputAdornment, { position: "end" },
                React.createElement(IconButton, { size: "small", component: RouterLink, to: `/mailschedule/templates/${value}` },
                    React.createElement(Launch, { fontSize: "small" })))) : undefined,
        } }))), [value]);
    const onSearchChange = React.useCallback((_, value_, reason) => {
        if (reason === "selectOption") {
            setSearch(null);
            onChange(value_.uid);
        }
        else if (reason === "removeOption") {
            setSearch(null);
            onChange(null);
        }
    }, [onChange]);
    return (React.createElement(Autocomplete, { disableClearable: true, forcePopupIcon: false, options: templates, noOptionsText: "No templates found", filterOptions: filterOptions, groupBy: groupBy, inputValue: search == null ? (_e = templates.find((item) => item.uid === value)) === null || _e === void 0 ? void 0 : _e.name : search, onInputChange: onSearchInputChange, getOptionLabel: getOptionLabel, fullWidth: true, renderInput: renderTemplateSearchInput, onChange: onSearchChange }));
};
export default MaiLSchedule3TemplateAutocomplete;
