import React, { createContext, useContext, useEffect, useState } from "react";
import { NavigationType, useLocation, useNavigationType } from "react-router";
const BackStackContext = createContext({ backStack: [] });
export const BackStackContextProvider = (props) => {
    const location = useLocation();
    const navigationType = useNavigationType();
    const [backStack, setBackStack] = useState([]);
    useEffect(() => {
        setBackStack((prev) => {
            console.log({ location, navigationType });
            switch (navigationType) {
                case NavigationType.Pop:
                    return prev.slice(0, prev.length - 1);
                case NavigationType.Push:
                    return [...prev, location];
                case NavigationType.Replace:
                    return [...prev.slice(0, prev.length - 1), location];
                default:
                    return prev;
            }
        });
    }, [location, navigationType]);
    return (React.createElement(BackStackContext.Provider, { value: {
            backStack,
        } }, props.children));
};
const useBrowserBackStack = () => {
    const backStackContext = useContext(BackStackContext);
    return backStackContext.backStack;
};
export default useBrowserBackStack;
