/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Get the account ID for the given email address

_Authorization: none required_
 * @summary Get user account ID
 */
export const getUserAccountId = (getUserAccountIdRequestRequest, options) => {
    return axios.post(`/api/get_user_account_id`, getUserAccountIdRequestRequest, options);
};
export const getGetUserAccountIdMutationOptions = (options) => {
    const mutationKey = ['getUserAccountId'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getUserAccountId(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get user account ID
*/
export const useGetUserAccountId = (options) => {
    const mutationOptions = getGetUserAccountIdMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Get info about the user for the current request session

_Authorization: none required_
* @summary Get session info
*/
export const getSessionInfo = (options) => {
    return axios.get(`/api/session`, options);
};
export const getGetSessionInfoQueryKey = () => {
    return [`/api/session`];
};
export const getGetSessionInfoQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSessionInfoQueryKey();
    const queryFn = ({ signal }) => getSessionInfo(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get session info
 */
export function useGetSessionInfo(options) {
    const queryOptions = getGetSessionInfoQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
