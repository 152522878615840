import * as React from "react";
import { useCallback } from "react";
import { Button } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { getBackgroundTaskStatus } from "@app/orval/api/background-tasks";
import { useGenerateAgedPropertyReport, useGenerateCashToClose, useGenerateInvestorReport, useGenerateMultipleOffers, useGenerateTemplatedPspReport, useGenerateNetSheet, useSendPspComments, useGenerateBuyerPspReport, useGenerateListingPspReport, useGenerateListingPresentationPdf, useGeneratePreListingPresentationPdf, } from "@app/orval/api/psp-reports";
import { getReadableErrorMessage, isChromeExtension } from "@app/util/Utils";
const useReportApis = (matrixUid) => {
    const generateBuyerPspReportApi = useGenerateBuyerPspReport();
    const generateListingPspReportApi = useGenerateListingPspReport();
    const generateTemplatedPspReportApi = useGenerateTemplatedPspReport();
    const generateAgedPropertyReportApi = useGenerateAgedPropertyReport();
    const generateCashToCloseApi = useGenerateCashToClose();
    const generateInvestorReportApi = useGenerateInvestorReport();
    const generateMultipleOffersApi = useGenerateMultipleOffers();
    const generateListingPresentationApi = useGenerateListingPresentationPdf();
    const generatePreListingPresentationApi = useGeneratePreListingPresentationPdf();
    const generateNetSheetApi = useGenerateNetSheet();
    const sendCommentsApi = useSendPspComments();
    const acknowledge = useCallback((message) => {
        const action = (key) => (React.createElement(Button
        // eslint-disable-next-line react/jsx-no-bind
        , { 
            // eslint-disable-next-line react/jsx-no-bind
            onClick: () => {
                closeSnackbar(key);
            }, style: { color: "white" } }, "Dismiss"));
        const snackbarKey = enqueueSnackbar(message !== null && message !== void 0 ? message : "Report is being generated. It will open automatically as soon as it's ready.", {
            variant: "info",
            action,
            autoHideDuration: null,
        });
        return snackbarKey;
    }, []);
    const checkStatus = useCallback((uid, options) => {
        getBackgroundTaskStatus(uid)
            .then((result) => {
            var _a;
            if (result.data.status === "running") {
                setTimeout(() => {
                    checkStatus(uid, options);
                }, 1000);
            }
            else if (result.data.gcs_artifact) {
                const path = `/psp-reports/${uid}`;
                const action = (key) => (React.createElement(React.Fragment, null,
                    React.createElement(Button
                    // eslint-disable-next-line react/jsx-no-bind
                    , { 
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick: () => {
                            closeSnackbar(key);
                        }, style: { color: "white" } }, "Dismiss"),
                    React.createElement(Button, { component: RouterLink, to: path, target: "_blank", style: { color: "white" }, 
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick: () => {
                            closeSnackbar(key);
                        } }, "Open")));
                enqueueSnackbar((_a = options === null || options === void 0 ? void 0 : options.successMessage) !== null && _a !== void 0 ? _a : "Your report is ready. Click here to view it.", {
                    variant: "success",
                    action,
                    autoHideDuration: null,
                });
                console.log({ options });
                if (options === null || options === void 0 ? void 0 : options.snackbarKey) {
                    console.log("SNACKBAR KEY", options.snackbarKey);
                    closeSnackbar(options.snackbarKey);
                }
                if (!isChromeExtension) {
                    window.open(`${window.location.origin}${path}`, "_blank");
                }
            }
            else if (result.data.error) {
                enqueueErrorSnackbar(result.data.error);
                if (options === null || options === void 0 ? void 0 : options.snackbarKey) {
                    closeSnackbar(options.snackbarKey);
                }
            }
        })
            .catch((error) => {
            enqueueSnackbar("An error occurred", { variant: "error" });
            console.error(`Error checking status of background task ${uid}: ${getReadableErrorMessage(error)}`);
        });
    }, []);
    const sendPSPComments = useCallback((agentName, address, useV2Template) => {
        sendCommentsApi
            .mutateAsync({ matrixUid, data: { use_v2_template: useV2Template } })
            .then((response) => {
            const snackbarKey = acknowledge(`Sending email with comments and PSP Report to ${agentName}...`);
            checkStatus(response.data.uid, { snackbarKey, successMessage: `Email for ${address} sent successfully` });
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
            console.error(`Error sending PSP Comments to ${agentName}: ${getReadableErrorMessage(error)}`);
        });
    }, [acknowledge, checkStatus, matrixUid, sendCommentsApi]);
    const generateBuyerPspReport = useCallback((format, useV2Template) => {
        const initialSnackbar = enqueueSnackbar("Generating buyer psp report...", { variant: "info" });
        generateBuyerPspReportApi
            .mutateAsync({ matrixUid, data: { format, use_v2_template: useV2Template } })
            .then((response) => {
            const snackbarKey = acknowledge();
            console.log({ snackbarKey });
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(initialSnackbar);
        });
    }, [acknowledge, checkStatus, generateBuyerPspReportApi, matrixUid]);
    const generateListingPspReport = useCallback((format, zavvieFormat) => {
        const initialSnackbar = enqueueSnackbar("Generating listing psp report...", { variant: "info" });
        generateListingPspReportApi
            .mutateAsync({ matrixUid, data: { format, zavvie_format: zavvieFormat } })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(initialSnackbar);
        });
    }, [acknowledge, checkStatus, generateListingPspReportApi, matrixUid]);
    const generateTemplatedPspReport = useCallback((reportUid, includeListingPresentation) => {
        const initialSnackbar = enqueueSnackbar("Generating templated psp report...", { variant: "info" });
        generateTemplatedPspReportApi
            .mutateAsync({
            matrixUid,
            data: { report_uid: reportUid, include_listing_presentation: includeListingPresentation },
        })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(initialSnackbar);
        });
    }, [acknowledge, checkStatus, generateTemplatedPspReportApi, matrixUid]);
    const generateCashToClose = useCallback(() => {
        generateCashToCloseApi
            .mutateAsync({ matrixUid })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar);
    }, [acknowledge, checkStatus, generateCashToCloseApi, matrixUid]);
    const generateInvestorReport = useCallback(() => {
        generateInvestorReportApi
            .mutateAsync({ matrixUid })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar);
    }, [acknowledge, checkStatus, generateInvestorReportApi, matrixUid]);
    const generateAgedPropertyReport = useCallback(() => {
        generateAgedPropertyReportApi
            .mutateAsync({ matrixUid })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar);
    }, [acknowledge, checkStatus, generateAgedPropertyReportApi, matrixUid]);
    const generateMultipleOffers = useCallback(() => {
        generateMultipleOffersApi
            .mutateAsync({ matrixUid })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar);
    }, [acknowledge, checkStatus, generateMultipleOffersApi, matrixUid]);
    const generateListingPresentation = useCallback(() => {
        generateListingPresentationApi
            .mutateAsync({ matrixUid })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar);
    }, [acknowledge, checkStatus, generateListingPresentationApi, matrixUid]);
    const generatePreListingPresentation = useCallback(() => {
        const initialSnackbar = enqueueSnackbar("Generating pre-listing presentation...", { variant: "info" });
        generatePreListingPresentationApi
            .mutateAsync({ matrixUid })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(initialSnackbar);
        });
    }, [acknowledge, checkStatus, generatePreListingPresentationApi, matrixUid]);
    const generateNetSheet = useCallback((type, format) => {
        generateNetSheetApi
            .mutateAsync({ matrixUid, data: { type, format } })
            .then((response) => {
            const snackbarKey = acknowledge();
            checkStatus(response.data.uid, { snackbarKey });
        })
            .catch(enqueueErrorSnackbar);
    }, [acknowledge, checkStatus, generateNetSheetApi, matrixUid]);
    return {
        generateBuyerPspReport,
        generateListingPspReport,
        generateTemplatedPspReport,
        generateCashToClose,
        generateInvestorReport,
        generateAgedPropertyReport,
        generateMultipleOffers,
        generateListingPresentation,
        generatePreListingPresentation,
        generateNetSheet,
        sendPSPComments,
        sendCommentsApi,
    };
};
export default useReportApis;
