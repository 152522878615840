import * as React from "react";
import { MenuItem, TextField } from "@mui/material";
const YesNoSelect = (props) => {
    const { TextFieldProps, onChange, value } = props;
    const handleChange = React.useCallback((e) => {
        var _a;
        if (onChange) {
            onChange((_a = {
                Yes: true,
                No: false,
                "": null,
            }[e.target.value]) !== null && _a !== void 0 ? _a : null);
        }
    }, [onChange]);
    const valAsText = React.useMemo(() => {
        if (value === true) {
            return "Yes";
        }
        if (value === false) {
            return "No";
        }
        return "";
    }, [value]);
    return (React.createElement(TextField, Object.assign({ fullWidth: true }, TextFieldProps, { select: true, value: valAsText, onChange: handleChange }),
        React.createElement(MenuItem, { value: "" }),
        React.createElement(MenuItem, { value: "Yes" }, "Yes"),
        React.createElement(MenuItem, { value: "No" }, "No")));
};
export default YesNoSelect;
