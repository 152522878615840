import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { useGetQfoPdf } from "@app/orval/api/forms-questions-for-the-offer";
import { base64ToArrayBuffer } from "@app/util/Utils";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const QuestionsForTheOfferCashToClose = (props) => {
    var _a, _b;
    const { uid } = props;
    const getPdfApi = useGetQfoPdf(uid);
    const pdf = (_b = (_a = getPdfApi.data) === null || _a === void 0 ? void 0 : _a.data.content) !== null && _b !== void 0 ? _b : null;
    const pdfUrl = React.useMemo(() => {
        if (pdf) {
            const arrayBuffer = base64ToArrayBuffer(pdf);
            const blob = new Blob([arrayBuffer], { type: "application/pdf" });
            return URL.createObjectURL(blob);
        }
        return null;
    }, [pdf]);
    if (getPdfApi.error) {
        return React.createElement(CoreError, { error: getPdfApi.error });
    }
    if (getPdfApi.isFetching) {
        return React.createElement(CoreLoading, null);
    }
    if (!pdfUrl) {
        return React.createElement(CoreError, { error: "No PDF found" });
    }
    return (React.createElement("a", { href: pdfUrl, target: "__blank" },
        React.createElement(Document, { file: pdfUrl },
            React.createElement(Page, { pageNumber: 1, renderTextLayer: false, renderAnnotationLayer: false }))));
};
export default QuestionsForTheOfferCashToClose;
