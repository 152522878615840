import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Add, Delete, Launch, Refresh, SortByAlpha } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING } from "@app/common/grid/utils";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useDeleteGoogleDataStudioReport, useListGoogleDataStudioReports, } from "@app/orval/api/google-data-studio-reports";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const GoogleDataStudioReportsTable = () => {
    var _a, _b;
    useDocumentTitle("Google Data Studio");
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const listReportsApi = useListGoogleDataStudioReports();
    const reports = (_b = (_a = listReportsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const { error } = listReportsApi;
    const deleteReportApi = useDeleteGoogleDataStudioReport();
    const refresh = React.useCallback(() => {
        listReportsApi.refetch();
    }, [listReportsApi]);
    const deleteReport = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listReportsApi.queryKey, (oldData) => {
                    if (oldData == null) {
                        return undefined;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((report) => report.uid !== uid) });
                });
                const loadingSnackbar = enqueueSnackbar("Removing report from DASHboard...", {
                    variant: "info",
                    persist: true,
                });
                deleteReportApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Report has been deleted", { variant: "success" });
                })
                    .catch(enqueueErrorSnackbar)
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [confirm, deleteReportApi, listReportsApi.queryKey, queryClient]);
    const rows = React.useMemo(() => {
        return (reports !== null && reports !== void 0 ? reports : []).map((report) => (Object.assign(Object.assign({}, report), { id: report.uid })));
    }, [reports]);
    const columns = React.useMemo(() => [
        {
            field: "uid",
            headerName: " ",
            sortable: false,
            renderCell: (params) => {
                const report = params.row;
                return (React.createElement(Grid2, { container: true, spacing: 1 },
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", component: RouterLink, to: `/datastudios/config/${report.uid}` },
                            React.createElement(Launch, { fontSize: "small" }))),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: deleteReport(report.uid) },
                            React.createElement(Delete, { fontSize: "small" })))));
            },
        },
        {
            field: "name",
            headerName: "Name",
        },
        {
            field: "url",
            headerName: "URL",
            flex: 2,
        },
    ], [deleteReport]);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!reports) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 1 },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: "Reorder" },
                            React.createElement(IconButton, { component: RouterLink, to: "/datastudios/config/reorder" },
                                React.createElement(SortByAlpha, null))),
                        React.createElement(Tooltip, { title: "Refresh" },
                            React.createElement(IconButton, { onClick: refresh },
                                React.createElement(Refresh, null))),
                        React.createElement(Tooltip, { title: "Create new report" },
                            React.createElement(IconButton, { component: RouterLink, to: "/datastudios/config/new" },
                                React.createElement(Add, null))))))),
        React.createElement(Grid2, null, listReportsApi.isFetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnSorting: true, disableColumnResize: true, disableColumnMenu: true, hideFooterSelectedRowCount: true, density: "compact", 
                // pagination={count != null && rows.length < count}
                // rowCount={count ?? undefined}
                paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], 
                // paginationModel={{ page, pageSize: limit }}
                // onPaginationModelChange={onPageChange}
                getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                    toolbarColumns: "",
                }, sx: {
                    border: "none",
                    "& .MuiDataGrid-main": {
                        border: "none",
                    },
                    "& .cell-bold": {
                        fontWeight: "bold",
                    },
                } }))));
};
export default GoogleDataStudioReportsTable;
