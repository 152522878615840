import * as React from "react";
import { House } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
import PipelineAndStageText from "./PipelineAndStageText";
const SellerLeadSearchResult = (props) => {
    var _a, _b;
    const { sellerLead } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemIcon, null,
            React.createElement(House, { fontSize: "large" })),
        React.createElement(ListItemText, { primary: sellerLead.name, secondary: React.createElement(PipelineAndStageText, { pipeline: "Seller Lead", stageName: (_a = sellerLead.stage) === null || _a === void 0 ? void 0 : _a.name, stageUid: (_b = sellerLead.stage) === null || _b === void 0 ? void 0 : _b.uid }) })));
};
export default SellerLeadSearchResult;
