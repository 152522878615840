import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import QuestionsForTheAffiliationFormNEWCRM from "./QuestionsForTheAffiliationFormNEWCRM";
const QuestionsForTheAffiliationFormPageNEWCRM = () => {
    useDocumentTitle("Questions for Affiliation");
    const params = useParams();
    const recruitUid = params.uid;
    return React.createElement(QuestionsForTheAffiliationFormNEWCRM, { recruitUid: recruitUid });
};
export default QuestionsForTheAffiliationFormPageNEWCRM;
