import * as React from "react";
import { AutoAwesome, Email } from "@mui/icons-material";
import { Link, ListItemIcon, MenuItem } from "@mui/material";
import MailScheduleTemplateMenu from "@app/common/links/MailScheduleTemplateMenu";
import useEmailDraftCreator from "@app/common/links/useEmailDraftCreator";
import { createDraft } from "@app/entrypoints/brokerage/window-messages";
import { isChromeExtension } from "@app/util/Utils";
export const getTemplateName = (template) => {
    const { name } = template;
    const isSC = template.criteria.some((x) => x.filters.some((f) => f.description.toLowerCase().includes("state contains sc")));
    const isNewConstruction = template.criteria.some((x) => x.filters.some((f) => f.description.startsWith("Transaction Type is any of") && f.description.includes("New Construction")));
    return [isSC && "(SC)", isNewConstruction && "(New Construction)", name].filter((x) => x).join(" ");
};
const EmailMenuSection = (props) => {
    const { contacts, entityType, entityUids } = props;
    const contactsWithEmails = contacts.filter((x) => x.email.trim());
    const [mailScheduleMenuAnchorEl, setMailScheduleMenuAnchorEl] = React.useState(null);
    const draftCreator = useEmailDraftCreator();
    const onClick = React.useCallback(() => {
        createDraft({
            draft: {
                to: contactsWithEmails.map((x) => x.email.trim()).join(","),
                entity_uids: entityUids,
            },
        });
    }, [contactsWithEmails, entityUids]);
    const openMailScheduleMenu = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setMailScheduleMenuAnchorEl(e.currentTarget);
    }, []);
    const closeMailScheduleMenu = React.useCallback(() => {
        setMailScheduleMenuAnchorEl(null);
    }, []);
    const handleTemplateSelected = React.useCallback((templateUid) => {
        draftCreator.createEmailDrafts({
            items: (entityUids !== null && entityUids !== void 0 ? entityUids : []).map((item) => ({
                templateUid,
                entityType: entityType,
                entityUid: item,
            })),
        });
    }, [draftCreator, entityType, entityUids]);
    return [
        isChromeExtension ? (React.createElement(MenuItem, { key: "email", onClick: onClick, disabled: contactsWithEmails.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(Email, null)),
            "Email ",
            contactsWithEmails.map((c) => c.name).join(", "))) : (React.createElement(MenuItem, { key: "email", component: Link, href: `mailto:${contactsWithEmails.map((c) => c.email.trim()).join(",")}`, disabled: contactsWithEmails.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(Email, null)),
            "Email ",
            contactsWithEmails.map((c) => c.name).join(", "),
            "...")),
        entityType && entityUids && entityUids.length > 0 && (React.createElement(MenuItem, { key: "draft-a-template", onClick: openMailScheduleMenu },
            React.createElement(ListItemIcon, null,
                React.createElement(AutoAwesome, null)),
            "Create a draft from a template...")),
        entityType && entityUids && entityUids.length > 0 && (React.createElement(MailScheduleTemplateMenu, { key: "mail-schedule-list", MenuProps: {
                anchorEl: mailScheduleMenuAnchorEl,
                open: !!mailScheduleMenuAnchorEl,
                onClick: closeMailScheduleMenu,
                onClose: closeMailScheduleMenu,
            }, onTemplateSelected: handleTemplateSelected, entityType: entityType })),
    ].filter((o) => o);
};
export default EmailMenuSection;
