import * as React from "react";
import { Add, Delete, DragHandle, Refresh } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { v4 as uuidv4 } from "uuid";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useDeleteShadowingType, useListShadowingTypes, useReorderShadowingTypes, } from "@app/orval/api/onboarding-shadowing-types";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const ShadowingTypes = () => {
    var _a, _b;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const droppableId = React.useRef(uuidv4());
    const listShadowingTypesApi = useListShadowingTypes(REFETCH_CACHE_PARAMS);
    const shadowingTypes = (_b = (_a = listShadowingTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const deleteShadowingTypeApi = useDeleteShadowingType();
    const reorderShadowingTypesApi = useReorderShadowingTypes();
    const refresh = React.useCallback(() => {
        listShadowingTypesApi.refetch();
    }, [listShadowingTypesApi]);
    const deleteShadowingType = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listShadowingTypesApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((t) => t.uid !== uid) });
                });
                deleteShadowingTypeApi
                    .mutateAsync({ uid })
                    .then((result) => {
                    console.log(result);
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteShadowingTypeApi, errorDialog, listShadowingTypesApi.queryKey, queryClient]);
    const reorderShadowingTypes = React.useCallback((result) => {
        if (result.destination) {
            const reorderedShadowingTypes = [...shadowingTypes];
            const shadowingType = reorderedShadowingTypes[result.source.index];
            reorderedShadowingTypes.splice(result.source.index, 1);
            reorderedShadowingTypes.splice(result.destination.index, 0, shadowingType);
            queryClient.setQueryData(listShadowingTypesApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: reorderedShadowingTypes });
            });
            const uids = reorderedShadowingTypes.map((x) => x.uid);
            reorderShadowingTypesApi
                .mutateAsync({ data: { uids } })
                .then((reorderResult) => {
                console.log(reorderResult);
            })
                .catch(errorDialog);
        }
    }, [errorDialog, listShadowingTypesApi.queryKey, queryClient, reorderShadowingTypesApi, shadowingTypes]);
    const loading = useLoading({
        items: [
            {
                label: "Loading shadowing types...",
                queryResult: listShadowingTypesApi,
            },
        ],
    });
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    return (React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "h6" }, "Shadowing Types")),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true },
                        React.createElement(IconButton, { component: RouterLink, to: "/onboarding/shadowing-types/new", tabIndex: -1 },
                            React.createElement(Add, null))),
                    React.createElement(Grid, { item: true },
                        React.createElement(IconButton, { onClick: refresh, disabled: listShadowingTypesApi.isFetching, tabIndex: -1 },
                            React.createElement(Refresh, null)))))),
        React.createElement(Grid, { item: true }, listShadowingTypesApi.isRefetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        shadowingTypes && shadowingTypes.length > 0 && (React.createElement(Grid, { item: true },
            React.createElement(DragDropContext, { onDragEnd: reorderShadowingTypes },
                React.createElement(Droppable, { droppableId: droppableId.current }, (droppableProvided) => (React.createElement(List, Object.assign({ dense: true }, droppableProvided.droppableProps, { ref: droppableProvided.innerRef }),
                    shadowingTypes.map((t, indx) => (React.createElement(Draggable, { key: t.uid, draggableId: t.uid, index: indx }, (draggableProvided) => (React.createElement(ListItem, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps),
                        React.createElement(ListItemAvatar, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps),
                            React.createElement(DragHandle, null)),
                        React.createElement(ListItemText, { primary: t.name }),
                        React.createElement(ListItemSecondaryAction, null,
                            React.createElement(IconButton, { onClick: deleteShadowingType(t.uid), tabIndex: -1 },
                                React.createElement(Delete, null)))))))),
                    droppableProvided.placeholder)))))),
        shadowingTypes && shadowingTypes.length === 0 && (React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2, style: { textAlign: "center" } },
                React.createElement(Typography, { variant: "body1" }, "No shadowing types found."))))));
};
export default ShadowingTypes;
