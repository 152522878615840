import * as React from "react";
import { Person } from "@mui/icons-material";
import { Avatar, Box, Grid2, Tab, Tabs, Typography } from "@mui/material";
import { useLocation } from "react-router";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import { getInitials } from "@app/util/Utils";
const tabsHeight = "32px";
const Header = () => {
    var _a, _b, _c, _d;
    const session = useSession();
    const user = session === null || session === void 0 ? void 0 : session.viewingAsUser;
    const location = useLocation();
    return (React.createElement(Box, { p: 2, pb: 0, pt: 0 },
        React.createElement(Grid2, { container: true, spacing: 2, alignItems: "center" },
            React.createElement(Grid2, { style: { paddingTop: "8px" } },
                React.createElement(Avatar, { src: (_a = user === null || user === void 0 ? void 0 : user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "30px", width: "30px", fontSize: "0.75rem" } }, getInitials((_b = user === null || user === void 0 ? void 0 : user.first_name) !== null && _b !== void 0 ? _b : null, (_c = user === null || user === void 0 ? void 0 : user.last_name) !== null && _c !== void 0 ? _c : null) || React.createElement(Person, null))),
            React.createElement(Grid2, { style: { flex: 1, paddingTop: "8px" } },
                React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } },
                    "Hello ", (_d = user === null || user === void 0 ? void 0 : user.first_name) !== null && _d !== void 0 ? _d : "DASH Producer",
                    "!")),
            React.createElement(Grid2, { style: { flex: 1 } }),
            React.createElement(Grid2, { style: { paddingTop: "8px" } },
                React.createElement(Tabs, { value: location.pathname, indicatorColor: "primary", textColor: "primary", sx: {
                        height: tabsHeight,
                        minHeight: tabsHeight,
                        "& .MuiTab-root": { height: tabsHeight, minHeight: tabsHeight },
                    } },
                    React.createElement(Tab, { label: "Dashboard", component: RouterLink, to: "/", value: "/" }),
                    React.createElement(Tab, { label: "Overview", component: RouterLink, to: "/overview", value: "/overview" }))))));
    return React.createElement("div", null);
};
export default Header;
