var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Add, Delete, Edit, Train } from "@mui/icons-material";
import { Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip, Grid, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useGetUser, useRemoveUserGraduationRequirement } from "@app/orval/api/users";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { formatDate_Dayjs } from "@app/util/Utils";
import GraduationRequirementsDialog from "./GraduationRequirementDialog";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const GraduationRequirements = (props) => {
    const { user } = props;
    const [isGraduationRequirementDialogOpen, setIsGraduationRequirementDialogOpen] = React.useState(false);
    const [selectedGraduationRequirement, setSelectedGraduationRequirement] = React.useState(undefined);
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const getUserApi = useGetUser(user.uid, { query: { enabled: false } });
    const removeGraduationRequirementApi = useRemoveUserGraduationRequirement();
    const { graduation_requirements } = user;
    const removeGraduationRequirement = React.useCallback((graduationRequirementUid) => () => __awaiter(void 0, void 0, void 0, function* () {
        yield confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS);
        queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
            if (!oldData) {
                return oldData;
            }
            return Object.assign(Object.assign({}, oldData), { data: Object.assign(Object.assign({}, oldData.data), { graduation_requirements: oldData.data.graduation_requirements.filter((graduationRequirement) => graduationRequirement.uid !== graduationRequirementUid) }) });
        });
        removeGraduationRequirementApi
            .mutateAsync({ userUid: user.uid, graduationRequirementUid })
            .then((response) => {
            queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: response.data });
            });
        })
            .catch(errorDialog);
    }), [confirm, errorDialog, getUserApi.queryKey, queryClient, removeGraduationRequirementApi, user.uid]);
    const openGraduationRequirementDialog = React.useCallback(() => {
        setSelectedGraduationRequirement(undefined);
        setIsGraduationRequirementDialogOpen(true);
    }, [setSelectedGraduationRequirement, setIsGraduationRequirementDialogOpen]);
    const closeGraduationRequirementDialog = React.useCallback(() => {
        setIsGraduationRequirementDialogOpen(false);
        setSelectedGraduationRequirement(undefined);
    }, [setIsGraduationRequirementDialogOpen, setSelectedGraduationRequirement]);
    const setSelectedGraduationRequirementAndOpenDialog = React.useCallback((graduationRequirement) => () => {
        setSelectedGraduationRequirement(graduationRequirement);
        setIsGraduationRequirementDialogOpen(true);
    }, [setSelectedGraduationRequirement, setIsGraduationRequirementDialogOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(Train, null), title: "Graduation Requirements", count: graduation_requirements.length, isCollapsedInitially: true, toolbar: React.createElement(Tooltip, { title: "Add graduation requirement", placement: "top", arrow: true, disableInteractive: true },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: openGraduationRequirementDialog, size: "small" },
                        React.createElement(Add, { fontSize: "small" })))) },
            graduation_requirements.length === 0 && (React.createElement(Typography, { variant: "body2" }, "No graduation requirements completed")),
            graduation_requirements.length > 0 && (React.createElement(List, { dense: true }, graduation_requirements.map((graduationRequirement) => (React.createElement(ListItem, { key: graduationRequirement.uid },
                React.createElement(ListItemText, { primary: graduationRequirement.graduation_requirement_type.name, secondary: formatDate_Dayjs(graduationRequirement.graduation_requirement_date, "dddd, MMMM D, YYYY") }),
                React.createElement(ListItemSecondaryAction, null,
                    React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: "Edit graduation requirement", placement: "top", arrow: true, disableInteractive: true },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { edge: "end", "aria-label": "edit", onClick: setSelectedGraduationRequirementAndOpenDialog(graduationRequirement) },
                                        React.createElement(Edit, null))))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: "Delete graduation requirement", placement: "top", arrow: true, disableInteractive: true },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { edge: "end", "aria-label": "delete", onClick: removeGraduationRequirement(graduationRequirement.uid) },
                                        React.createElement(Delete, null))))))))))))),
        isGraduationRequirementDialogOpen && (React.createElement(GraduationRequirementsDialog, { DialogProps: { open: true, maxWidth: "sm", PaperProps: { sx: { minWidth: "500px" } } }, userUid: user.uid, graduationRequirement: selectedGraduationRequirement, onClose: closeGraduationRequirementDialog }))));
};
export default GraduationRequirements;
