import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Add, Delete, GetApp, Launch, Refresh } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING } from "@app/common/grid/utils";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useDeleteSuggestedPriceReductionScheduleItem, useDownloadSuggestedPriceReductionSchedule, useListSuggestedPriceReductionScheduleItems, } from "@app/orval/api/price-reduction-schedule";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { downloadBlob } from "@app/util/Utils";
const PriceReductionSchedule = () => {
    var _a, _b;
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const listPriceReductionScheduleApi = useListSuggestedPriceReductionScheduleItems(REFETCH_CACHE_PARAMS);
    const items = (_b = (_a = listPriceReductionScheduleApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const rows = React.useMemo(() => {
        return items === null || items === void 0 ? void 0 : items.map((item) => (Object.assign({ id: item.uid }, item)));
    }, [items]);
    const refresh = React.useCallback(() => {
        listPriceReductionScheduleApi.refetch();
    }, [listPriceReductionScheduleApi]);
    const downloadSuggestedPriceReductionsApi = useDownloadSuggestedPriceReductionSchedule({ query: { enabled: false } });
    const deletePriceReductionItemApi = useDeleteSuggestedPriceReductionScheduleItem();
    const deleteItem = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listPriceReductionScheduleApi.queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((item) => item.uid !== uid) });
                });
                deletePriceReductionItemApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Item deleted", { variant: "success" });
                })
                    .catch(enqueueErrorSnackbar);
            }
        });
    }, [confirm, deletePriceReductionItemApi, queryClient, listPriceReductionScheduleApi.queryKey]);
    const download = React.useCallback(() => {
        downloadSuggestedPriceReductionsApi
            .refetch()
            .then((result) => {
            var _a, _b, _c;
            console.log({ res: (_a = result.data) === null || _a === void 0 ? void 0 : _a.data });
            enqueueSnackbar("Download complete", { variant: "success" });
            downloadBlob((_c = (_b = result.data) === null || _b === void 0 ? void 0 : _b.data.content) !== null && _c !== void 0 ? _c : "", "suggested_price_reduction_schedule", "text/csv");
        })
            .catch(enqueueErrorSnackbar);
    }, [downloadSuggestedPriceReductionsApi]);
    const isDownloading = downloadSuggestedPriceReductionsApi.isFetching;
    const columns = [
        {
            field: "uid",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Tooltip, { title: "Edit item", disableInteractive: true },
                        React.createElement(IconButton, { size: "small", component: RouterLink, to: `/price-reduction-schedule/${params.value}` },
                            React.createElement(Launch, { fontSize: "small" }))),
                    React.createElement(Tooltip, { title: "Delete item...", disableInteractive: true },
                        React.createElement(IconButton, { size: "small", onClick: deleteItem(params.value) },
                            React.createElement(Delete, { fontSize: "small" })))));
            },
        },
        {
            field: "days_on_market",
            headerName: "DOM",
            renderCell: (params) => {
                return params.value != null ? numeral(params.value).format("0,0") : "";
            },
        },
        {
            field: "reduction",
            headerName: "% of Original List Price",
            width: 200,
            renderCell: (params) => {
                return params.value != null ? numeral(params.value * 100).format("0.00%") : "";
            },
        },
    ];
    if (listPriceReductionScheduleApi.error) {
        return React.createElement(CoreError, { error: listPriceReductionScheduleApi.error });
    }
    if (!rows) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 1 },
                React.createElement(Grid2, { container: true, direction: "row" },
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: "Refresh" },
                            React.createElement(IconButton, { onClick: refresh, disabled: listPriceReductionScheduleApi.isFetching },
                                React.createElement(Refresh, null))),
                        React.createElement(Tooltip, { title: "Create new rule" },
                            React.createElement(IconButton, { component: RouterLink, to: "/price-reduction-schedule/new" },
                                React.createElement(Add, null))),
                        React.createElement(Tooltip, { title: "Download" },
                            React.createElement(IconButton, { onClick: download, disabled: isDownloading },
                                React.createElement(GetApp, null))))))),
        React.createElement(Grid2, null, listPriceReductionScheduleApi.isFetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnSorting: true, disableColumnResize: true, disableColumnMenu: true, hideFooterSelectedRowCount: true, density: "compact", 
                // pagination={count != null && rows.length < count}
                // rowCount={count ?? undefined}
                paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], 
                // paginationModel={{ page, pageSize: limit }}
                // onPaginationModelChange={onPageChange}
                getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                    toolbarColumns: "",
                }, sx: {
                    border: "none",
                    "& .MuiDataGrid-main": {
                        border: "none",
                    },
                    "& .cell-bold": {
                        fontWeight: "bold",
                    },
                } }))));
};
export default PriceReductionSchedule;
