import * as React from "react";
import { Grid, Typography } from "@mui/material";
const SelectPhoneNumbersAlert = () => {
    return (React.createElement(Grid, { container: true, direction: "column", style: { height: "100%" } },
        React.createElement(Grid, { item: true, style: { flex: 1 } }),
        React.createElement(Grid, { item: true, style: { textAlign: "center" } },
            React.createElement(Typography, { variant: "h5", style: { color: "#aaa" } }, "Select phone numbers to open a conversation")),
        React.createElement(Grid, { item: true, style: { flex: 1 } })));
};
export default SelectPhoneNumbersAlert;
