import { useCallback, useMemo } from "react";
import { listComments } from "@app/orval/api/comments";
import { listNewsfeedFieldValueUpdates, listNewsfeedSellerContractStageChanges, listNewsfeedSellerLeadNoteChanges, } from "@app/orval/api/newsfeed";
import { REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { getCommentStory, getNoteChangeStory, getStageChangeStory } from "../components/newsfeed/utils";
const useNewsfeed = (params) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const from = params === null || params === void 0 ? void 0 : params.from;
    const to = params === null || params === void 0 ? void 0 : params.to;
    const fetchFieldValuesApi = usePostQuery(listNewsfeedFieldValueUpdates, { earliest: from, latest: to, limit: 100, entity_types: ["Listing"] }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const fieldValues = (_b = (_a = fetchFieldValuesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const isLoadingFieldValues = fetchFieldValuesApi.isLoading;
    const fetchStageUpdatesApi = usePostQuery(listNewsfeedSellerContractStageChanges, { earliest: from, latest: to, limit: 100 }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const stageChanges = (_d = (_c = fetchStageUpdatesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const isLoadingStages = fetchStageUpdatesApi.isLoading;
    const fetchNoteChangesApi = usePostQuery(listNewsfeedSellerLeadNoteChanges, { earliest: from, latest: to, limit: 100 }, Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS.query), { enabled: !(params === null || params === void 0 ? void 0 : params.ignoreSellerLeads) }) }));
    const notes = (_f = (_e = fetchNoteChangesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const isLoadingNotes = fetchNoteChangesApi.isLoading;
    const fetchCommentsApi = usePostQuery(listComments, { added_from: from !== null && from !== void 0 ? from : undefined, added_to: to !== null && to !== void 0 ? to : undefined, limit: 100, entity_type: "Seller Lead" }, Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS.query), { enabled: !(params === null || params === void 0 ? void 0 : params.ignoreSellerLeads) }) }));
    const comments = (_h = (_g = fetchCommentsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const isLoadingComments = fetchCommentsApi.isLoading;
    const refresh = useCallback(() => {
        fetchCommentsApi.refetch();
        fetchNoteChangesApi.refetch();
        fetchStageUpdatesApi.refetch();
        fetchFieldValuesApi.refetch();
    }, [fetchCommentsApi, fetchFieldValuesApi, fetchNoteChangesApi, fetchStageUpdatesApi]);
    const newsfeed = useMemo(() => {
        const items = [
            ...(fieldValues !== null && fieldValues !== void 0 ? fieldValues : []).map((x) => (Object.assign(Object.assign({}, x), { url: `/sellers/${x.seller_contract.uid}`, name: x.seller_contract.name, type: "field" }))),
            ...(stageChanges !== null && stageChanges !== void 0 ? stageChanges : []).map((x) => (Object.assign(Object.assign({}, x), { story: getStageChangeStory(x), url: `/sellers/${x.seller_contract.uid}`, name: x.seller_contract.name, type: "stage" }))),
            ...(notes !== null && notes !== void 0 ? notes : []).map((x) => (Object.assign(Object.assign({}, x), { story: getNoteChangeStory(x), url: `/sellers/${x.seller_lead.uid}`, name: x.seller_lead.name, type: "note" }))),
            ...(comments !== null && comments !== void 0 ? comments : []).map((x) => (Object.assign(Object.assign({}, x), { timestamp: x.edits[0].timestamp, userstamp: x.edits[0].author, story: getCommentStory(x), url: `/sellers/${x.seller.uid}`, name: x.seller.name, type: "comment" }))),
        ];
        return items;
    }, [comments, fieldValues, notes, stageChanges]);
    return {
        isLoadingNotes,
        isLoadingStages,
        isLoadingFieldValues,
        isLoadingComments,
        refresh,
        newsfeed,
    };
};
export default useNewsfeed;
