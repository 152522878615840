/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a Streak box
 */
export const getStreakBox = (boxKey, options) => {
    return axios.get(`/api/streak/boxes/${boxKey}`, options);
};
export const getGetStreakBoxQueryKey = (boxKey) => {
    return [`/api/streak/boxes/${boxKey}`];
};
export const getGetStreakBoxQueryOptions = (boxKey, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetStreakBoxQueryKey(boxKey);
    const queryFn = ({ signal }) => getStreakBox(boxKey, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(boxKey) }, queryOptions);
};
/**
 * @summary Get a Streak box
 */
export function useGetStreakBox(boxKey, options) {
    const queryOptions = getGetStreakBoxQueryOptions(boxKey, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get Streak boxes
 */
export const listStreakBoxes = (pipelineKey, options) => {
    return axios.get(`/api/streak/pipelines/${pipelineKey}/boxes`, options);
};
export const getListStreakBoxesQueryKey = (pipelineKey) => {
    return [`/api/streak/pipelines/${pipelineKey}/boxes`];
};
export const getListStreakBoxesQueryOptions = (pipelineKey, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakBoxesQueryKey(pipelineKey);
    const queryFn = ({ signal }) => listStreakBoxes(pipelineKey, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(pipelineKey) }, queryOptions);
};
/**
 * @summary Get Streak boxes
 */
export function useListStreakBoxes(pipelineKey, options) {
    const queryOptions = getListStreakBoxesQueryOptions(pipelineKey, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get Streak boxes
 */
export const listStreakBoxesPaginated = (pipelineKey, params, options) => {
    return axios.get(`/api/streak/pipelines/${pipelineKey}/paginated_boxes`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListStreakBoxesPaginatedQueryKey = (pipelineKey, params) => {
    return [`/api/streak/pipelines/${pipelineKey}/paginated_boxes`, ...(params ? [params] : [])];
};
export const getListStreakBoxesPaginatedQueryOptions = (pipelineKey, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakBoxesPaginatedQueryKey(pipelineKey, params);
    const queryFn = ({ signal }) => listStreakBoxesPaginated(pipelineKey, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(pipelineKey) }, queryOptions);
};
/**
 * @summary Get Streak boxes
 */
export function useListStreakBoxesPaginated(pipelineKey, params, options) {
    const queryOptions = getListStreakBoxesPaginatedQueryOptions(pipelineKey, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
