import * as React from "react";
import { Edit } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import OfficeSelectDialog from "./OfficeSelectDialog";
const OfficeSelect = (props) => {
    const { canEdit, office, onChange } = props;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const openDialog = React.useCallback(() => {
        setIsDialogOpen(true);
    }, [setIsDialogOpen]);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);
    const onConfirm = React.useCallback((params) => {
        closeDialog();
        onChange(params);
    }, [onChange, closeDialog]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap", style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, "Office")),
            React.createElement(Grid, { item: true, container: true, direction: "column", style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, office ? (React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1" }, office.name))) : (React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1" }, "This user isn't assigned to an office.")))),
            canEdit && (React.createElement(Grid, { item: true },
                React.createElement(IconButton, { onClick: openDialog },
                    React.createElement(Edit, null))))),
        isDialogOpen && (React.createElement(OfficeSelectDialog, { onClose: closeDialog, DialogProps: { open: true }, onConfirm: onConfirm, initialValue: office }))));
};
export default OfficeSelect;
