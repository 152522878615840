import * as React from "react";
import { Link, ListItemIcon, MenuItem } from "@mui/material";
import followUpBossIcon from "@app/assets/img/followupboss.png";
import { navigateTo } from "@app/entrypoints/brokerage/window-messages";
import { variant } from "@app/util/Utils";
const followUpBossBaseUrl = "https://dashnc.followupboss.com";
const getFollowUpBossPath = (personId) => {
    return `/2/people/view/${personId}`;
};
const getFollowUpBossUrl = (personId) => {
    return `${followUpBossBaseUrl}${getFollowUpBossPath(personId)}`;
};
const FollowUpBossMenuSection = (props) => {
    const { personId } = props;
    const followUpBossPath = getFollowUpBossPath(personId !== null && personId !== void 0 ? personId : "");
    const followUpBossUrl = personId ? getFollowUpBossUrl(personId) : null;
    const openInFollowUpBoss = React.useCallback(() => {
        navigateTo(followUpBossPath);
    }, [followUpBossPath]);
    if (variant === "followupboss") {
        return [
            React.createElement(MenuItem, { key: "fub", disabled: !personId, onClick: openInFollowUpBoss },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { src: followUpBossIcon, alt: "Follow Up Boss", height: 20 })),
                "Open in Follow Up Boss"),
        ];
    }
    return [
        /* @ts-expect-error */
        React.createElement(MenuItem, { key: "fub", disabled: !followUpBossUrl, component: followUpBossUrl ? Link : undefined, href: followUpBossUrl !== null && followUpBossUrl !== void 0 ? followUpBossUrl : undefined, target: followUpBossUrl ? "_blank" : undefined },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: followUpBossIcon, alt: "Follow Up Boss", height: 20 })),
            "Open in Follow Up Boss"),
    ];
};
export default FollowUpBossMenuSection;
