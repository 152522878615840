import * as React from "react";
import { Close } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import numeral from "numeral";
import { Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, ReferenceLine, LabelList, Text } from "recharts";
import DashCard from "@app/common/CoreCard";
import Loading from "@app/common/Loading";
import AppConfig from "@app/util/AppConfig";
import FormRow from "./FormRow";
const PSP_COMPS_SIDEBAR_ROWS = [
    { name: "Weighted Avg", fields: [{ name: "MLS Data Weighted Avg", multiplyBy100: true }] },
    { name: "Active Avg per SqFt", fields: [{ name: "Active Avg List Price per SqFt" }] },
    { name: "Active Min per SqFt", fields: [{ name: "Active Min List Price per SqFt" }] },
    { name: "Active Max per SqFt", fields: [{ name: "Active Max List Price per SqFt" }], divider: true },
    { name: "MLS Active Weight", fields: [{ name: "MLS Active Weight" }], hidden: true },
    { name: "Closed Avg per SqFt", fields: [{ name: "Closed Avg Sold Price per SqFt" }] },
    { name: "Closed Min per SqFt", fields: [{ name: "Closed Min Sold Price per SqFt" }] },
    { name: "Closed Max per SqFt", fields: [{ name: "Closed Max Sold Price per SqFt" }], divider: true },
    { name: "MLS Closed Weight", fields: [{ name: "MLS Closed Weight" }], hidden: true },
    { name: "Closed Avg SPLP", fields: [{ name: "Closed Comp Avg SPLP" }] },
    { name: "Closed Min SPLP", fields: [{ name: "Closed Comp Min SPLP" }] },
    { name: "Closed Max SPLP", fields: [{ name: "Closed Comp Max SPLP" }], divider: true },
    { name: "Pending Avg per SqFt", fields: [{ name: "Pending Avg List Price per SqFt" }] },
    { name: "Pending Min per SqFt", fields: [{ name: "Pending Min List Price per SqFt" }] },
    { name: "Pending Max per SqFt", fields: [{ name: "Pending Max List Price per SqFt" }], divider: true },
    { name: "MLS Pending Weight", fields: [{ name: "MLS Pending Weight" }], hidden: true },
    { name: "Financial Concessions Low", fields: [{ name: "PSP Comps Financial Concessions Low" }] },
    { name: "Financial Concessions High", fields: [{ name: "PSP Comps Financial Concessions High" }] },
    { name: "Avg DOM", fields: [{ name: "PSP Comps Avg DOM" }] },
];
/** formats tick numbers for recharts graph axis */
const formatTick = (tickItem) => {
    return numeral(tickItem).format("0,0a");
};
const ListPriceLabel = (props) => {
    const viewBox = props.viewBox;
    const { height, x, y } = viewBox;
    return (React.createElement("g", null,
        React.createElement(Text, { x: x, y: y + height / 4, textAnchor: "middle", fill: "#000000", style: {
                fontFamily: AppConfig.fonts.primary,
                fontSize: 9,
                fontWeight: "bold",
            } }, props.text)));
};
const PSPCompsSidebar = (props) => {
    const onClose = React.useCallback(() => {
        props.closeSidebar(null);
    }, [props]);
    const { matrixFields, matrixSchema, matrixUid, onFieldSaveBegin, onFieldSaveComplete, pspComps, pspListPrice, pspSqft, pspType, pspValueHigh, pspValueMin, } = props;
    const rows = React.useMemo(() => {
        if (!matrixFields || !matrixSchema) {
            return [];
        }
        return PSP_COMPS_SIDEBAR_ROWS.map((row) => {
            return (React.createElement(FormRow, { key: `${row.name}_psp_comp_sidebar`, uid: matrixUid, rowData: Object.assign(Object.assign({}, row), { fields: row.fields.map((field) => {
                        var _a, _b;
                        return Object.assign(Object.assign({}, field), { value: (_b = (_a = matrixFields[field.name]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null });
                    }) }), 
                // matrixFields={matrixFields}
                schema: matrixSchema, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete }));
        });
    }, [matrixFields, matrixSchema, matrixUid, onFieldSaveBegin, onFieldSaveComplete]);
    // only display graph if it would be displayed in PSP Report
    const shouldDisplayGraph = React.useMemo(() => {
        return pspValueMin != null && pspValueHigh != null && ["Buyer", "Listing"].includes(pspType !== null && pspType !== void 0 ? pspType : "");
    }, [pspType, pspValueHigh, pspValueMin]);
    const graphData = React.useMemo(() => {
        return [
            {
                price: [pspValueMin, pspValueHigh],
                dataGroup: "Full County (from MLS)",
                minLabel: numeral(pspValueMin).format("$0,0"),
                highLabel: numeral(pspValueHigh).format("$0,0"),
            },
        ];
    }, [pspValueMin, pspValueHigh]);
    const markers = React.useMemo(() => {
        // get values for reference lines
        // use Current List Price for Buyer PSP
        // use PSP Comps for Listing PSP
        if (!shouldDisplayGraph) {
            return undefined;
        }
        return pspType === "Buyer"
            ? [
                {
                    value: pspListPrice,
                    label: numeral(pspListPrice).format("$0,0"),
                },
            ]
            : (pspComps !== null && pspComps !== void 0 ? pspComps : [])
                .filter((comp) => {
                var _a;
                const priceField = comp.comp_type === "Closed" ? "Sold Price/SqFt" : "List Price/SqFt";
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                return ((_a = comp.fields[priceField]) === null || _a === void 0 ? void 0 : _a.value) != null;
            })
                .map((comp) => {
                var _a;
                const priceField = comp.comp_type === "Closed" ? "Sold Price/SqFt" : "List Price/SqFt";
                // get number of comp (ex: `Comp 1`, `Comp 2`)
                const compNumber = (pspComps !== null && pspComps !== void 0 ? pspComps : [])
                    .filter((comp_) => {
                    return comp_.comp_type === comp.comp_type;
                })
                    .map((comp_) => {
                    return comp_.uid;
                })
                    .indexOf(comp.uid);
                return {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unnecessary-type-assertion
                    value: ((_a = comp.fields[priceField]) === null || _a === void 0 ? void 0 : _a.value) != null ? comp.fields[priceField].value * (pspSqft !== null && pspSqft !== void 0 ? pspSqft : 1) : null, // scale $/sqft by sqft of property
                    label: comp.comp_type === "Closed" ? String(compNumber + 1) : `${comp.comp_type} ${compNumber + 1}`,
                };
            });
    }, [pspComps, pspListPrice, pspSqft, pspType, shouldDisplayGraph]);
    if (!matrixFields || !matrixSchema) {
        return React.createElement(Loading, { items: [{ label: "Waiting for MAtrix data...", status: "pending" }] });
    }
    // adjust domain so all markers and labels are visible
    const range = shouldDisplayGraph && pspValueHigh != null && pspValueMin != null ? pspValueHigh - pspValueMin : null;
    let domainMin;
    let domainMax;
    if (shouldDisplayGraph) {
        const values = [
            pspValueMin,
            pspValueHigh,
            ...(markers !== null && markers !== void 0 ? markers : []).map((marker) => {
                return marker.value;
            }),
        ];
        domainMin =
            pspListPrice == null
                ? (pspValueMin !== null && pspValueMin !== void 0 ? pspValueMin : 0) - 0.15 * (range !== null && range !== void 0 ? range : 0)
                : Math.min(...values.filter((x) => x != null).map((x) => x)) - 0.15 * (range !== null && range !== void 0 ? range : 0);
        domainMax =
            pspListPrice == null
                ? (pspValueHigh !== null && pspValueHigh !== void 0 ? pspValueHigh : 0) - 0.15 * (range !== null && range !== void 0 ? range : 0)
                : Math.max(...values.filter((x) => x != null).map((x) => x)) + 0.35 * (range !== null && range !== void 0 ? range : 0);
    }
    return (React.createElement(DashCard, { heading: "MLS Data", toolbar: React.createElement(IconButton, { onClick: onClose },
            React.createElement(Close, null)), padding: 0, elevation: 0, PaperProps: {
            style: {
                overflowX: "hidden",
                overflowY: "auto",
            },
        } },
        React.createElement(Grid, { container: true, spacing: 2, style: { marginTop: "4px" } },
            rows,
            shouldDisplayGraph && (React.createElement(Grid, { item: true, xs: 12, style: { paddingRight: "8px" } },
                React.createElement(Grid, null,
                    React.createElement(Typography, { align: "center", style: { fontWeight: "bold" } }, "Dash Range")),
                React.createElement(ResponsiveContainer, { width: "100%", height: 150 },
                    React.createElement(BarChart, { data: graphData, layout: "vertical", onMouseEnter: undefined },
                        React.createElement(XAxis, { type: "number", dataKey: "price", orientation: "bottom", tick: { fontFamily: AppConfig.fonts.primary, fontSize: 9 }, tickFormatter: formatTick, domain: [domainMin, domainMax] }),
                        React.createElement(Bar, { dataKey: "price", fill: "#0059A9", barSize: 30 },
                            graphData.map((item) => (React.createElement(Cell, { key: `bar_${item.dataGroup}`, fill: AppConfig.themeColors.primary }))),
                            React.createElement(LabelList, { dataKey: "minLabel", position: "left", fill: "#000000", style: {
                                    fontFamily: AppConfig.fonts.primary,
                                    fontSize: 9,
                                    fontWeight: "bold",
                                } }),
                            React.createElement(LabelList, { dataKey: "highLabel", position: "right", fill: "#000000", style: {
                                    fontFamily: AppConfig.fonts.primary,
                                    fontSize: 9,
                                    fontWeight: "bold",
                                } })),
                        (markers !== null && markers !== void 0 ? markers : []).map((marker) => {
                            var _a;
                            return (React.createElement(ReferenceLine, { key: `${marker.label}_graph_marker`, x: (_a = marker.value) !== null && _a !== void 0 ? _a : undefined, isFront: true, label: React.createElement(ListPriceLabel, { text: marker.label }), stroke: "#0059A9", 
                                // segment={[
                                //   { x: marker.value, y: 0 },
                                //   { x: marker.value, y: 0.5 },
                                // ]}
                                style: {
                                    fontFamily: AppConfig.fonts.primary,
                                    fontSize: 9,
                                } }));
                        }),
                        React.createElement(YAxis, { label: " ", axisLine: false, dataKey: "dataGroup", type: "category", tick: false }))))))));
};
export default PSPCompsSidebar;
