import * as React from "react";
import { Avatar, Grid, Tooltip, Typography } from "@mui/material";
import { convertUtcDateString } from "@app/common/date-utils";
import { getFullName, getInitials } from "@app/util/Utils";
const UserAndTimestampCell = (props) => {
    var _a;
    const { timestamp, user } = props;
    return (React.createElement(Grid, { container: true, style: { overflowY: "hidden", margin: "0px", marginBottom: "auto", marginTop: "auto" }, wrap: "nowrap" },
        user && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, getFullName(user.first_name, user.last_name)) },
                React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "24px", width: "24px", fontSize: "0.7rem" } }, getInitials(user.first_name, user.last_name) || "?")))),
        timestamp && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", flexGrow: 1 } }, convertUtcDateString(timestamp, { format: "dddd, MMMM D, YYYY h:mm A" })))));
};
export default UserAndTimestampCell;
