import * as React from "react";
import { ArrowDropDown, Check, Close } from "@mui/icons-material";
import { Avatar, Chip, Grid2, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import fontColorContrast from "font-color-contrast";
import { isValidPhoneNumber } from "libphonenumber-js";
import CoreLink from "@app/common/CoreLink";
import EmailMenu from "@app/common/links/EmailMenu";
import PhoneMenu from "@app/common/links/PhoneMenu";
import useSession from "@app/hooks/useSession";
import { formatDateMemoized_Dayjs, formatNumberMemoized, getFullName, getInitials, isValidUrl, validateEmail, } from "@app/util/Utils";
export const collaboratorCellValueFormatter = (value) => {
    var _a;
    const users = value;
    return (_a = users === null || users === void 0 ? void 0 : users.map((x) => getFullName(x.user.first_name, x.user.last_name)).join(", ")) !== null && _a !== void 0 ? _a : "";
};
export const agentCellValueGetter = (value) => {
    var _a;
    const users = value;
    return (_a = users === null || users === void 0 ? void 0 : users.map((x) => getFullName(x.agent.first_name, x.agent.last_name)).join(", ")) !== null && _a !== void 0 ? _a : "";
};
export const renderCollaboratorsCell = (params) => {
    var _a;
    const collaborators = ((_a = params.row[params.field]) !== null && _a !== void 0 ? _a : []);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, collaborators.map((collaborator) => {
        var _a, _b;
        return (React.createElement(React.Fragment, { key: collaborator.uid },
            React.createElement(Grid2, { style: { minWidth: "20px" } },
                React.createElement(Avatar, { src: (_a = collaborator.user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "20px", width: "20px", fontSize: "0.675rem" } }, getInitials(collaborator.user.first_name, collaborator.user.last_name) || "?")),
            React.createElement(Grid2, { style: { overflowX: "hidden" } },
                React.createElement(Typography, { variant: "body2" }, (_b = (getFullName(collaborator.user.first_name, collaborator.user.last_name) || collaborator.user.email)) === null || _b === void 0 ? void 0 : _b.replace(/\s+/g, " ")))));
    })));
};
export const clientCellValueFormatter = (value) => {
    const clients = (value !== null && value !== void 0 ? value : []);
    return clients.map((client) => getFullName(client.client.first_name, client.client.last_name)).join(",");
};
const ClientTooltip = (props) => {
    var _a, _b;
    const { client } = props;
    const name = getFullName(client.client.first_name, client.client.last_name);
    return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", direction: "column" },
        name && (React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Name:")),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, name))))),
        ((_a = client.client.email) === null || _a === void 0 ? void 0 : _a.trim()) && (React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Email:")),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, client.client.email))))),
        ((_b = client.client.phone) === null || _b === void 0 ? void 0 : _b.trim()) && (React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Phone:")),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, client.client.phone)))))));
};
export const renderClientsCell = (params) => {
    var _a;
    const clients = ((_a = params.row[params.field]) !== null && _a !== void 0 ? _a : []);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, clients.map((client, i) => {
        var _a;
        return (React.createElement(Grid2, { key: client.uid || i },
            React.createElement(Tooltip, { title: React.createElement(ClientTooltip, { client: client }), disableInteractive: true },
                React.createElement(Grid2, { container: true, wrap: "nowrap", spacing: 1 },
                    React.createElement(Grid2, { style: { minWidth: "20px" } },
                        React.createElement(Avatar, { src: undefined, style: { height: "20px", width: "20px", fontSize: "0.675rem" } }, getInitials(client.client.first_name, client.client.last_name) || "?")),
                    React.createElement(Grid2, { style: { overflow: "hidden" } },
                        React.createElement(Typography, { variant: "body2" }, (_a = (getFullName(client.client.first_name, client.client.last_name) ||
                            client.client.email ||
                            client.client.phone)) === null || _a === void 0 ? void 0 : _a.replace(/\s+/g, " ")))))));
    })));
};
export const renderAgentsCell = (params) => {
    var _a;
    const agents = ((_a = params.row[params.field]) !== null && _a !== void 0 ? _a : []);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, agents.map((agent, i) => {
        var _a;
        return (React.createElement(React.Fragment, { key: agent.uid || i },
            React.createElement(Grid2, null,
                React.createElement(Avatar, { src: undefined, style: { height: "20px", width: "20px", fontSize: "0.675rem" } }, getInitials(agent.agent.first_name, agent.agent.last_name) || "?")),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2" }, (_a = (getFullName(agent.agent.first_name, agent.agent.last_name) || agent.agent.email)) === null || _a === void 0 ? void 0 : _a.replace(/\s+/g, " ")))));
    })));
};
export const renderProducerLastUpdatedTimestampCell = (params) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const lead = (_f = (_d = (_b = (_a = params.row.buyer_lead) !== null && _a !== void 0 ? _a : params.row.seller_lead) !== null && _b !== void 0 ? _b : (_c = params.row.buyer_contract) === null || _c === void 0 ? void 0 : _c.buyer_lead) !== null && _d !== void 0 ? _d : (_e = params.row.seller_contract) === null || _e === void 0 ? void 0 : _e.seller_lead) !== null && _f !== void 0 ? _f : params.row;
    const { agents } = lead;
    const val = (_g = (agents !== null && agents !== void 0 ? agents : [])
        .filter((x) => x.type === "Producer")
        .sort((a, b) => {
        if (a.updated_at > b.updated_at)
            return -1;
        if (a.updated_at < b.updated_at)
            return 1;
        return 0;
    })[0]) === null || _g === void 0 ? void 0 : _g.updated_at;
    if (val) {
        return dayjs(val).tz("America/New_York").format("dddd, MMMM D, YYYY h:mm A");
    }
    return null;
};
export const vendorCellValueFormatter = (value) => {
    var _a;
    const vendors = value;
    return (_a = vendors === null || vendors === void 0 ? void 0 : vendors.map((x) => getFullName(x.contact.first_name, x.contact.last_name)).join(", ")) !== null && _a !== void 0 ? _a : "";
};
const VendorTooltip = (props) => {
    const { vendor } = props;
    const name = getFullName(vendor.contact.first_name, vendor.contact.last_name);
    const { email } = vendor.contact;
    const { phone } = vendor.contact;
    return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", direction: "column" },
        name && (React.createElement(Grid2, null,
            React.createElement(Typography, null, name))),
        email && (React.createElement(Grid2, null,
            React.createElement(Typography, null, email))),
        phone && (React.createElement(Grid2, null,
            React.createElement(Typography, null, phone)))));
};
export const renderVendorsCell = (params) => {
    var _a;
    const vendors = ((_a = params.row[params.field]) !== null && _a !== void 0 ? _a : []);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, vendors.map((vendor, i) => {
        var _a;
        return (React.createElement(Grid2, { key: vendor.uid || i },
            React.createElement(Tooltip, { title: React.createElement(VendorTooltip, { vendor: vendor }), placement: "bottom-start" },
                React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(Avatar, { src: undefined, style: { height: "20px", width: "20px", fontSize: "0.675rem" } }, getInitials(vendor.contact.first_name, vendor.contact.last_name) || "?")),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2" }, (_a = (getFullName(vendor.contact.first_name, vendor.contact.last_name) || vendor.contact.email)) === null || _a === void 0 ? void 0 : _a.replace(/\s+/g, " ")))))));
    })));
};
export const renderDaysInStageCell = (params) => {
    var _a;
    const { stage } = params.row;
    const lastStageChangeTimestamp = (_a = stage === null || stage === void 0 ? void 0 : stage.updated_at) !== null && _a !== void 0 ? _a : params.row.last_stage_change_timestamp;
    const daysInStage = lastStageChangeTimestamp ? dayjs().diff(dayjs(lastStageChangeTimestamp), "day") : null;
    return daysInStage;
};
export const stageCellValueFormatter = (value) => {
    var _a;
    const stage = value;
    return (_a = stage === null || stage === void 0 ? void 0 : stage.name) !== null && _a !== void 0 ? _a : "";
};
// eslint-disable-next-line react/display-name
export const renderStageCell = (stages) => (params) => {
    const stage = params.row[params.field];
    if (!stage) {
        return null;
    }
    const stageObj = stages === null || stages === void 0 ? void 0 : stages.find((s) => s.uid === stage.uid);
    if (!stageObj) {
        return stage.name;
    }
    return (React.createElement(Chip, { size: "small", style: { backgroundColor: stageObj.color, color: fontColorContrast(stageObj.color, 0.7) }, label: stage.name }));
};
export const renderStageChangeTimestampCell = (params) => {
    const item = params.row;
    const { stage } = item;
    if (!stage) {
        return null;
    }
    const val = stage.updated_at;
    if (val) {
        const formatted = dayjs(val).tz("America/New_York").format("dddd, MMMM D, YYYY h:mm A");
        return formatted;
    }
    return null;
};
const InterviewTooltip = (props) => {
    const { interview } = props;
    const name = interview.interviewer
        ? getFullName(interview.interviewer.first_name, interview.interviewer.last_name)
        : null;
    const dateAndTime = interview.date_and_time
        ? `${dayjs(interview.date_and_time).tz("America/New_York").format("M/D/YYYY h:mm A")} Eastern time`
        : null;
    return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", direction: "column" },
        name && (React.createElement(Grid2, null,
            React.createElement(Typography, null, name))),
        dateAndTime && (React.createElement(Grid2, null,
            React.createElement(Typography, null, dateAndTime))),
        interview.notes && (React.createElement(Grid2, null,
            React.createElement(Typography, { style: { fontStyle: "italic" } },
                " ",
                interview.notes)))));
};
export const renderInterviewsCell = (params) => {
    var _a;
    const interviews = ((_a = params.row[params.field]) !== null && _a !== void 0 ? _a : []);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, interviews.map((interview) => {
        var _a;
        return (React.createElement(Grid2, { key: interview.uid },
            React.createElement(Tooltip, { title: React.createElement(InterviewTooltip, { interview: interview }), placement: "bottom-start" },
                React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                    interview.interviewer && (React.createElement(Grid2, null,
                        React.createElement(Avatar, { src: (_a = interview.interviewer.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "20px", width: "20px", fontSize: "0.675rem" } }, getInitials(interview.interviewer.first_name, interview.interviewer.last_name) || "?"))),
                    interview.date_and_time && (React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2" }, dayjs(interview.date_and_time).tz("America/New_York").format("M/D/YYYY h:mm A"))))))));
    })));
};
export const renderRelationshipsCell = (params) => {
    var _a;
    const relationships = ((_a = params.row[params.field]) !== null && _a !== void 0 ? _a : []);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, relationships.map((relationship) => {
        var _a, _b, _c, _d;
        return (React.createElement(Grid2, { key: relationship.related_contact_uid },
            React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                React.createElement(Grid2, null,
                    React.createElement(Avatar, { src: undefined, style: { height: "20px", width: "20px", fontSize: "0.675rem" } }, getInitials((_a = relationship.related_contact_fields["First Name"]) !== null && _a !== void 0 ? _a : null, (_b = relationship.related_contact_fields["Last Name"]) !== null && _b !== void 0 ? _b : null) || "?")),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, getFullName((_c = relationship.related_contact_fields["First Name"]) !== null && _c !== void 0 ? _c : null, (_d = relationship.related_contact_fields["Last Name"]) !== null && _d !== void 0 ? _d : null) || "?")),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic", color: "#999" } }, relationship.relationship)))));
    })));
};
const TextCell = (props) => {
    var _a, _b;
    const { entityType, entityUid } = props;
    const value = String((_a = props.value) !== null && _a !== void 0 ? _a : "");
    const [emailMenuAnchorEl, setEmailMenuAnchorEl] = React.useState(null);
    const [phoneMenuAnchorEl, setPhoneMenuAnchorEl] = React.useState(null);
    const session = useSession();
    const showEmailMenu = React.useCallback((e) => {
        setEmailMenuAnchorEl(e.currentTarget);
    }, []);
    const showPhoneMenu = React.useCallback((e) => {
        setPhoneMenuAnchorEl(e.currentTarget);
    }, []);
    const closeEmailMenu = React.useCallback(() => {
        setEmailMenuAnchorEl(null);
    }, []);
    const closePhoneMenu = React.useCallback(() => {
        setPhoneMenuAnchorEl(null);
    }, []);
    const kindOfText = React.useMemo(() => {
        var _a;
        // only logged in users can click email/phone/website links
        if (!((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal)) {
            return null;
        }
        if (validateEmail(value)) {
            return "email";
        }
        if (isValidPhoneNumber(value, "US")) {
            return "phone";
        }
        if (isValidUrl(value)) {
            return "website";
        }
        return null;
    }, [(_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.isInternal, value]);
    if (kindOfText === "email") {
        return (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, value), placement: "bottom-start" },
                React.createElement("span", { style: { textDecoration: "underline", color: "rgb(6, 77, 121)", cursor: "pointer" }, onClick: showEmailMenu }, value)),
            emailMenuAnchorEl && (React.createElement(EmailMenu, { MenuProps: {
                    anchorEl: emailMenuAnchorEl,
                    open: true,
                    onClose: closeEmailMenu,
                    onClick: closeEmailMenu,
                }, contacts: [{ name: "", email: value }], entityType: entityType !== null && entityType !== void 0 ? entityType : undefined, entityUids: entityUid ? [entityUid] : undefined }))));
    }
    if (kindOfText === "phone") {
        return (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, value), placement: "bottom-start" },
                React.createElement("span", { style: { textDecoration: "underline", color: "rgb(6, 77, 121)", cursor: "pointer" }, onClick: showPhoneMenu }, value)),
            phoneMenuAnchorEl && (React.createElement(PhoneMenu, { MenuProps: {
                    anchorEl: phoneMenuAnchorEl,
                    open: true,
                    onClose: closePhoneMenu,
                    onClick: closePhoneMenu,
                }, contacts: [{ name: "", phone: value }] }))));
    }
    if (kindOfText === "website") {
        return (React.createElement(Tooltip, { title: React.createElement(Typography, null, value), placement: "bottom-start" },
            React.createElement("span", null,
                React.createElement(CoreLink, { style: { textDecoration: "underline", color: "rgb(6, 77, 121)", cursor: "pointer" }, href: value.trim(), target: "_blank" }, value))));
    }
    return (React.createElement(Tooltip, { title: React.createElement(Typography, null, value), placement: "bottom-start" },
        React.createElement("span", null, value)));
};
export const renderTextCell = (params) => {
    return React.createElement(TextCell, { value: params.value, entityType: params.row["entity_type"], entityUid: params.row.id });
};
export const renderChoiceCell = (params) => {
    return (React.createElement(Grid2, { container: true, style: { width: "100%" }, wrap: "nowrap" },
        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, { variant: "body2" }, Array.isArray(params.value) ? params.value.join(", ") : params.value)),
        React.createElement(Grid2, null,
            React.createElement(ArrowDropDown, { fontSize: "small", style: { color: "#aaa", transform: "translateY(2px)" } }))));
};
export const renderCheckboxCell = (params) => {
    if (params.value) {
        return React.createElement(Check, { fontSize: "small", color: "primary" });
    }
    return null;
};
export const renderFormulaCell = (params) => {
    var _a, _b;
    if (params.value === "false") {
        return React.createElement(Close, { fontSize: "small", color: "disabled" });
    }
    if (params.value === "true") {
        return React.createElement(Check, { fontSize: "small", color: "primary", style: { fontWeight: "bold" } });
    }
    if ((_a = params.row.fields[params.colDef.headerName]) === null || _a === void 0 ? void 0 : _a.error) {
        return (React.createElement(Tooltip, { title: React.createElement(Typography, null, String((_b = params.row.fields[params.colDef.headerName]) === null || _b === void 0 ? void 0 : _b.error)), arrow: true },
            React.createElement(Typography, { variant: "body2" }, "Error")));
    }
    return params.value;
};
export const renderFieldCell = (field) => (params) => {
    var _a, _b, _c, _d, _e;
    const item = params.row;
    let value;
    if (field.field_type === "formula") {
        value = (_c = (_b = (_a = item.fields) === null || _a === void 0 ? void 0 : _a[field.name]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : "";
    }
    else {
        value = (_e = (_d = item.fields) === null || _d === void 0 ? void 0 : _d[field.name]) !== null && _e !== void 0 ? _e : "";
    }
    let formattedValue;
    if (field.format && ["date", "datetime"].includes(field.field_type)) {
        // replaceAll is hacky
        // need to update fields to be specified in dayjs format
        formattedValue = formatDateMemoized_Dayjs(value, field.format.replaceAll("d", "D").replaceAll("y", "Y").replace("tt", "a"));
    }
    else if (field.format && field.field_type === "time") {
        // replaceAll is hacky
        // need to update fields to be specified in dayjs format
        formattedValue = formatDateMemoized_Dayjs(value, field.format.replaceAll("d", "D").replaceAll("y", "Y").replace("tt", "a"));
    }
    else if (field.format && field.field_type === "number") {
        formattedValue = formatNumberMemoized(value, field.format, { multiplyPercentagesBy100: true });
    }
    else if (field.format && field.field_type === "formula" && value != null && value !== "") {
        if (field.format.includes("0")) {
            formattedValue = formatNumberMemoized(value, field.format, { multiplyPercentagesBy100: true });
        }
        else {
            // replaceAll is hacky
            // need to update fields to be specified in dayjs format
            formattedValue = formatDateMemoized_Dayjs(value, field.format.replaceAll("d", "D").replaceAll("y", "Y").replace("tt", "a"));
        }
    }
    else if (field.field_type === "text") {
        formattedValue = value; // (value && value.length > 50) ? `${value.substring(0, 47)}...` : value;
    }
    else {
        formattedValue = String(value);
    }
    return formattedValue;
};
export const renderOfficeCell = (params) => {
    var _a;
    const office = params.value;
    return (_a = office === null || office === void 0 ? void 0 : office.name) !== null && _a !== void 0 ? _a : null;
};
export const renderRolesCell = (params) => {
    var _a;
    const roles = params.value;
    return (_a = roles === null || roles === void 0 ? void 0 : roles.join(", ")) !== null && _a !== void 0 ? _a : null;
};
