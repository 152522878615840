/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all templates
 */
export const listMailschedule3Templates = (params, options) => {
    return axios.get(`/api/mailschedule/templates`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListMailschedule3TemplatesQueryKey = (params) => {
    return [`/api/mailschedule/templates`, ...(params ? [params] : [])];
};
export const getListMailschedule3TemplatesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailschedule3TemplatesQueryKey(params);
    const queryFn = ({ signal }) => listMailschedule3Templates(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all templates
 */
export function useListMailschedule3Templates(params, options) {
    const queryOptions = getListMailschedule3TemplatesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a template
 */
export const createMailschedule3Template = (templateRequest, options) => {
    return axios.post(`/api/mailschedule/templates`, templateRequest, options);
};
export const getCreateMailschedule3TemplateMutationOptions = (options) => {
    const mutationKey = ['createMailschedule3Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createMailschedule3Template(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a template
*/
export const useCreateMailschedule3Template = (options) => {
    const mutationOptions = getCreateMailschedule3TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a template
*/
export const getMailschedule3Template = (uid, options) => {
    return axios.get(`/api/mailschedule/templates/${uid}`, options);
};
export const getGetMailschedule3TemplateQueryKey = (uid) => {
    return [`/api/mailschedule/templates/${uid}`];
};
export const getGetMailschedule3TemplateQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule3TemplateQueryKey(uid);
    const queryFn = ({ signal }) => getMailschedule3Template(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a template
 */
export function useGetMailschedule3Template(uid, options) {
    const queryOptions = getGetMailschedule3TemplateQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a template
 */
export const updateMailschedule3Template = (uid, templateRequest, options) => {
    return axios.post(`/api/mailschedule/templates/${uid}`, templateRequest, options);
};
export const getUpdateMailschedule3TemplateMutationOptions = (options) => {
    const mutationKey = ['updateMailschedule3Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateMailschedule3Template(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a template
*/
export const useUpdateMailschedule3Template = (options) => {
    const mutationOptions = getUpdateMailschedule3TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete a template
*/
export const deleteMailschedule3Template = (uid, options) => {
    return axios.delete(`/api/mailschedule/templates/${uid}`, options);
};
export const getDeleteMailschedule3TemplateMutationOptions = (options) => {
    const mutationKey = ['deleteMailschedule3Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteMailschedule3Template(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a template
*/
export const useDeleteMailschedule3Template = (options) => {
    const mutationOptions = getDeleteMailschedule3TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Duplicate a template
*/
export const duplicateMailschedule3Template = (uid, options) => {
    return axios.post(`/api/mailschedule/templates/${uid}/duplicate`, undefined, options);
};
export const getDuplicateMailschedule3TemplateMutationOptions = (options) => {
    const mutationKey = ['duplicateMailschedule3Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateMailschedule3Template(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a template
*/
export const useDuplicateMailschedule3Template = (options) => {
    const mutationOptions = getDuplicateMailschedule3TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download Templates as CSV
*/
export const downloadMailschedule3Templates = (options) => {
    return axios.get(`/api/mailschedule/templates/download`, options);
};
export const getDownloadMailschedule3TemplatesQueryKey = () => {
    return [`/api/mailschedule/templates/download`];
};
export const getDownloadMailschedule3TemplatesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadMailschedule3TemplatesQueryKey();
    const queryFn = ({ signal }) => downloadMailschedule3Templates(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download Templates as CSV
 */
export function useDownloadMailschedule3Templates(options) {
    const queryOptions = getDownloadMailschedule3TemplatesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Re-order templates
 */
export const reorderMailschedule3Templates = (templateReorderRequest, options) => {
    return axios.post(`/api/mailschedule/templates/reorder`, templateReorderRequest, options);
};
export const getReorderMailschedule3TemplatesMutationOptions = (options) => {
    const mutationKey = ['reorderMailschedule3Templates'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderMailschedule3Templates(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Re-order templates
*/
export const useReorderMailschedule3Templates = (options) => {
    const mutationOptions = getReorderMailschedule3TemplatesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary List all templates
*/
export const listMailschedule4Templates = (params, options) => {
    return axios.get(`/api/mailschedule4/templates`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListMailschedule4TemplatesQueryKey = (params) => {
    return [`/api/mailschedule4/templates`, ...(params ? [params] : [])];
};
export const getListMailschedule4TemplatesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailschedule4TemplatesQueryKey(params);
    const queryFn = ({ signal }) => listMailschedule4Templates(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all templates
 */
export function useListMailschedule4Templates(params, options) {
    const queryOptions = getListMailschedule4TemplatesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a template
 */
export const createMailschedule4Template = (mailSchedule4TemplateWriteRequest, options) => {
    return axios.post(`/api/mailschedule4/templates`, mailSchedule4TemplateWriteRequest, options);
};
export const getCreateMailschedule4TemplateMutationOptions = (options) => {
    const mutationKey = ['createMailschedule4Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createMailschedule4Template(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a template
*/
export const useCreateMailschedule4Template = (options) => {
    const mutationOptions = getCreateMailschedule4TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a template
*/
export const getMailschedule4Template = (uid, options) => {
    return axios.get(`/api/mailschedule4/templates/${uid}`, options);
};
export const getGetMailschedule4TemplateQueryKey = (uid) => {
    return [`/api/mailschedule4/templates/${uid}`];
};
export const getGetMailschedule4TemplateQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule4TemplateQueryKey(uid);
    const queryFn = ({ signal }) => getMailschedule4Template(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a template
 */
export function useGetMailschedule4Template(uid, options) {
    const queryOptions = getGetMailschedule4TemplateQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a template
 */
export const updateMailschedule4Template = (uid, mailSchedule4TemplateWriteRequest, options) => {
    return axios.post(`/api/mailschedule4/templates/${uid}`, mailSchedule4TemplateWriteRequest, options);
};
export const getUpdateMailschedule4TemplateMutationOptions = (options) => {
    const mutationKey = ['updateMailschedule4Template'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateMailschedule4Template(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a template
*/
export const useUpdateMailschedule4Template = (options) => {
    const mutationOptions = getUpdateMailschedule4TemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
