// eslint-disable-next-line import/prefer-default-export
export const getViewUrl = (view) => {
    if (view.entity_type === "Buyer Lead") {
        return `/buyer-leads?view=${view.uid}`;
    }
    if (view.entity_type === "Seller Lead") {
        return `/seller-leads?view=${view.uid}`;
    }
    if (view.entity_type === "Buyer Contract") {
        return `/buyer-contracts?view=${view.uid}`;
    }
    if (view.entity_type === "Listing") {
        return `/seller-contracts?view=${view.uid}`;
    }
    if (view.entity_type === "Contact") {
        return `/contacts?view=${view.uid}`;
    }
    if (view.entity_type === "Recruit") {
        return `/recruits?view=${view.uid}`;
    }
    if (view.entity_type === "User") {
        return `/users?view=${view.uid}`;
    }
    throw new Error("Invalid entity type");
};
