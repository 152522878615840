/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Create a task
 */
export const addTask = (taskCreateRequest, options) => {
    return axios.post(`/api/tasks`, taskCreateRequest, options);
};
export const getAddTaskMutationOptions = (options) => {
    const mutationKey = ['addTask'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addTask(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a task
*/
export const useAddTask = (options) => {
    const mutationOptions = getAddTaskMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a task
*/
export const getTask = (uid, options) => {
    return axios.get(`/api/tasks/${uid}`, options);
};
export const getGetTaskQueryKey = (uid) => {
    return [`/api/tasks/${uid}`];
};
export const getGetTaskQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTaskQueryKey(uid);
    const queryFn = ({ signal }) => getTask(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a task
 */
export function useGetTask(uid, options) {
    const queryOptions = getGetTaskQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a task
 */
export const updateTask = (uid, taskUpdateRequest, options) => {
    return axios.post(`/api/tasks/${uid}`, taskUpdateRequest, options);
};
export const getUpdateTaskMutationOptions = (options) => {
    const mutationKey = ['updateTask'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateTask(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a task
*/
export const useUpdateTask = (options) => {
    const mutationOptions = getUpdateTaskMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a task
*/
export const deleteTask = (uid, options) => {
    return axios.delete(`/api/tasks/${uid}`, options);
};
export const getDeleteTaskMutationOptions = (options) => {
    const mutationKey = ['deleteTask'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteTask(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a task
*/
export const useDeleteTask = (options) => {
    const mutationOptions = getDeleteTaskMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count tasks
*/
export const countTasks = (taskQueryRequest, options) => {
    return axios.post(`/api/tasks/count`, taskQueryRequest, options);
};
export const getCountTasksMutationOptions = (options) => {
    const mutationKey = ['countTasks'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countTasks(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count tasks
*/
export const useCountTasks = (options) => {
    const mutationOptions = getCountTasksMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List all tasks
*/
export const listTasks = (paginatedTaskQueryRequest, options) => {
    return axios.post(`/api/tasks/search`, paginatedTaskQueryRequest, options);
};
export const getListTasksMutationOptions = (options) => {
    const mutationKey = ['listTasks'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listTasks(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary List all tasks
*/
export const useListTasks = (options) => {
    const mutationOptions = getListTasksMutationOptions(options);
    return useMutation(mutationOptions);
};
