import * as React from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetSellerLead } from "@app/orval/api/seller-leads";
import MailScheduleTemplatePreview from "../../features/mailschedule/preview/MailScheduleTemplatePreview";
const SellerLeadsMailSchedulePreviewPage = () => {
    var _a, _b;
    const params = useParams();
    const { template_uid, uid } = params;
    useDocumentTitle("Seller Leads | Mail Schedule");
    const navigate = useNavigate();
    const getSellerLeadApi = useGetSellerLead(uid);
    const sellerLead = (_b = (_a = getSellerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const redirectUrl = `/seller-leads/${uid}/mail-schedule`;
    const onTemplateProcessed = React.useCallback(() => {
        navigate(redirectUrl);
    }, [navigate, redirectUrl]);
    if (!sellerLead) {
        return React.createElement(CoreLoading, null);
    }
    if (!sellerLead.streak_seller_leads_box_key) {
        return React.createElement(Typography, null, "Unable to load Mail Schedule");
    }
    return (React.createElement(MailScheduleTemplatePreview, { boxKey: sellerLead.streak_seller_leads_box_key, templateUid: template_uid, onTemplateProcessed: onTemplateProcessed, onCloseRedirectUrl: redirectUrl }));
};
export default SellerLeadsMailSchedulePreviewPage;
