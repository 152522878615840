import * as React from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import { withRouter } from "@app/hoc/withRouter";
import MailScheduleTemplateForm from "./MailScheduleTemplateForm";
import MailScheduleTemplateMetrics from "./MailScheduleTemplateMetrics";
import MailScheduleTemplateReorder from "./MailScheduleTemplateReorder";
import MailScheduleTemplatesSidebar from "./MailScheduleTemplatesSidebar";
import MailScheduleTemplatesTable from "./MailScheduleTemplatesTable";
const MailScheduleTemplatesWrapper = () => {
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = React.useState(true);
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const pipeline = searchParams.get("pipeline");
    const toggleFilterSidebar = React.useCallback(() => {
        setIsFilterSidebarOpen((prevState) => {
            return !prevState;
        });
    }, [setIsFilterSidebarOpen]);
    const { uid } = params;
    React.useEffect(() => {
        if (!uid && !searchParams.get("pipeline")) {
            searchParams.set("pipeline", "Buyer Lead");
            navigate(`/ms4/templates?${searchParams.toString()}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (uid || location.pathname.includes("/new")) {
        return React.createElement(MailScheduleTemplateForm, { uid: uid });
    }
    if (location.pathname.includes("/reorder")) {
        return React.createElement(MailScheduleTemplateReorder, { pipeline: pipeline });
    }
    if (location.pathname.includes("/metrics")) {
        return React.createElement(MailScheduleTemplateMetrics, null);
    }
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(MailScheduleTemplatesTable, { onSidebarToggle: toggleFilterSidebar }), sidebar: React.createElement(MailScheduleTemplatesSidebar, { onSidebarToggle: toggleFilterSidebar }), sidebarWidth: 250, opened: isFilterSidebarOpen }));
};
export default withRouter(MailScheduleTemplatesWrapper);
