import * as React from "react";
import { Grid2, Typography } from "@mui/material";
const OfferFieldLabel = (props) => {
    const { label, labelStyle } = props;
    return (React.createElement(Grid2, { size: 12, style: {
            height: "30px",
            textAlign: "right",
            // paddingTop: "10px",
            whiteSpace: "nowrap",
            overflowX: "hidden",
        } },
        React.createElement(Typography, { variant: "caption", style: labelStyle }, label)));
};
export default OfferFieldLabel;
