import * as React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import AppConfig from "@app/util/AppConfig";
dayjs.extend(utc);
dayjs.extend(timezone);
const ConversationScheduleMessageDialog = (props) => {
    const { DialogProps, onCancel, onDateTimeSelected } = props;
    const [selectedDateTime, setSelectedDateTime] = React.useState(dayjs.utc(new Date()).add(1, "day").tz(AppConfig.timezone));
    const onConfirm = React.useCallback(() => {
        onDateTimeSelected(selectedDateTime);
    }, [onDateTimeSelected, selectedDateTime]);
    return (React.createElement(Dialog, Object.assign({}, DialogProps),
        React.createElement(DialogTitle, null, "Schedule message"),
        React.createElement(StaticDateTimePicker, { value: dayjs.utc(selectedDateTime).tz(AppConfig.timezone), onChange: setSelectedDateTime, minDateTime: dayjs.utc(new Date().toISOString()).add(15, "minute").tz(AppConfig.timezone), slotProps: {
                actionBar: {
                    style: {
                        display: "none",
                    },
                },
            } }),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onCancel, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onConfirm, color: "primary", disabled: !selectedDateTime, tabIndex: -1 }, "OK"))));
};
export default ConversationScheduleMessageDialog;
