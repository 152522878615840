import * as React from "react";
import { Edit, Mail, Notifications, NotificationsOff, RemoveCircleOutline, Visibility } from "@mui/icons-material";
import { Avatar, IconButton, ListItemAvatar, ListItem, ListItemText, Tooltip, Typography, Grid2 } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import RouterLink from "@app/common/RouterLink";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const CollaboratorIcon = (props) => {
    const { icon, label } = props;
    return React.createElement(Tooltip, { title: React.createElement(Typography, null, label) }, icon);
};
const CollaboratorListItem = (props) => {
    var _a;
    const { collaborator, onEditCollaborator, onRemoveCollaborator } = props;
    const confirm = useConfirm();
    const removeCollaborator = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This will remove the collaborator from the lead.", confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveCollaborator(collaborator.uid);
            }
        });
    }, [collaborator.uid, confirm, onRemoveCollaborator]);
    const editCollaborator = React.useCallback(() => {
        onEditCollaborator(collaborator.uid);
    }, [collaborator.uid, onEditCollaborator]);
    const initials = [collaborator.user.first_name, collaborator.user.last_name]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o === null || o === void 0 ? void 0 : o.substring(0, 1))
        .join("");
    const name = [collaborator.user.first_name, collaborator.user.last_name].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" ");
    return (React.createElement(ListItem, { secondaryAction: React.createElement(React.Fragment, null,
            onEditCollaborator && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit collaborator") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: editCollaborator },
                        React.createElement(Edit, { fontSize: "small" }))))),
            onRemoveCollaborator && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove collaborator") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removeCollaborator },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" })))))) },
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, { src: (_a = collaborator.user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "28px", width: "28px", fontSize: "0.75rem" } }, initials)),
        React.createElement(ListItemText, { style: { marginTop: 0, marginBottom: 0 }, primary: React.createElement(RouterLink, { to: `/users/${collaborator.user.uid}`, style: { textDecoration: "none" } }, name), secondary: React.createElement(Grid2, { container: true, spacing: 1 },
                React.createElement(Grid2, null, collaborator.can_edit ? (React.createElement(CollaboratorIcon, { label: "Allowed to edit", icon: React.createElement(Edit, { fontSize: "small" }) })) : (React.createElement(CollaboratorIcon, { label: "View-only", icon: React.createElement(Visibility, { fontSize: "small" }) }))),
                React.createElement(Grid2, null, collaborator.should_receive_notifications ? (React.createElement(CollaboratorIcon, { label: "Notifications enabled", icon: React.createElement(Notifications, { fontSize: "small" }) })) : (React.createElement(CollaboratorIcon, { label: "Notifications disabled", icon: React.createElement(NotificationsOff, { fontSize: "small" }) }))),
                React.createElement(Grid2, null,
                    collaborator.is_mail_schedule_owner && (React.createElement(CollaboratorIcon, { label: "Mail Schedule owner", icon: React.createElement(Mail, { fontSize: "small", color: "primary" }) })),
                    collaborator.can_run_mail_schedule && !collaborator.is_mail_schedule_owner && (React.createElement(CollaboratorIcon, { label: "Mail Schedule Access Enabled", icon: React.createElement(Mail, { fontSize: "small" }) })))) })));
};
export default CollaboratorListItem;
