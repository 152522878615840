import * as React from "react";
import { Message, Phone, PhoneIphone, Remove } from "@mui/icons-material";
import { Badge, Box, Button, Divider, Grid2, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Popover, TextField, Tooltip, Typography, } from "@mui/material";
import { format } from "timeago.js";
import useSession from "@app/hooks/useSession";
import { useListCachedTwilioMessagingLogs } from "@app/orval/api/twilio";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { TwilioCallContext } from "../../context/twilio-calls/TwilioCallContext";
import { TwilioSMSContext } from "../../context/twilio-sms/TwilioSMSContext";
const Twilio = () => {
    var _a, _b, _c, _d, _e;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, []);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);
    const callContext = React.useContext(TwilioCallContext);
    const smsContext = React.useContext(TwilioSMSContext);
    const session = useSession();
    const openDialer = React.useCallback(() => {
        callContext.openDialer([]);
        handleClose();
    }, [callContext, handleClose]);
    const openSMS = React.useCallback(() => {
        smsContext.openConversation({
            fromPhoneNumber: null,
            toPhoneNumber: null,
        });
        handleClose();
    }, [smsContext, handleClose]);
    const listMessagesApi = useListCachedTwilioMessagingLogs({
        most_recent_only: true,
        is_read: false,
    }, {
        query: {
            refetchInterval: 10000,
            enabled: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]),
        },
    });
    const unreadMessages = (_c = (_b = listMessagesApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const listTwilioPhonesApi = useListCachedTwilioPhones({}, INFINITE_CACHE_PARAMS);
    const twilioPhones = (_e = (_d = listTwilioPhonesApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const { ignoreMessage: ignoreMessage_, ignoredMessages, openConversation } = React.useContext(TwilioSMSContext);
    const filteredUnreadMessages = React.useMemo(() => {
        var _a;
        return ((_a = unreadMessages === null || unreadMessages === void 0 ? void 0 : unreadMessages.filter((message) => {
            return !ignoredMessages.has(message.sid);
        })) !== null && _a !== void 0 ? _a : null);
    }, [unreadMessages, ignoredMessages]);
    const openMessage = React.useCallback((messageSid) => () => {
        ignoreMessage_(messageSid);
        handleClose();
        const message = unreadMessages.find((x) => x.sid === messageSid);
        openConversation({ fromPhoneNumber: message.from_, toPhoneNumber: message.to });
    }, [handleClose, ignoreMessage_, openConversation, unreadMessages]);
    const ignoreMessage = React.useCallback((messageSid) => () => {
        ignoreMessage_(messageSid);
    }, [ignoreMessage_]);
    const setBitRate = React.useCallback((e) => {
        callContext.setBitRate(parseFloat(e.target.value));
    }, [callContext]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Badge, { badgeContent: filteredUnreadMessages === null || filteredUnreadMessages === void 0 ? void 0 : filteredUnreadMessages.length, color: "error", anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            } },
            React.createElement(IconButton, { onClick: openPopover },
                React.createElement(PhoneIphone, { style: { color: "white" } }))),
        React.createElement(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, slotProps: {
                paper: {
                    style: {
                        maxWidth: "300px",
                        minWidth: "200px",
                    },
                },
            } },
            React.createElement(Grid2, { container: true, direction: "column", spacing: 1, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Box, { p: 2, style: { textAlign: "center" } },
                        React.createElement(Grid2, { container: true, alignItems: "center" },
                            React.createElement(Grid2, { size: 6 },
                                React.createElement(Button, { startIcon: React.createElement(Phone, null), color: "primary", onClick: openDialer, disabled: !callContext.isActive }, "New Call...")),
                            React.createElement(Grid2, { size: 6 },
                                React.createElement(Button, { startIcon: React.createElement(Message, null), color: "primary", onClick: openSMS }, "New Text..."))))),
                callContext.isActive && callContext.bitRate && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, null,
                        React.createElement(Divider, null)),
                    React.createElement(Grid2, null,
                        React.createElement(Box, { p: 2 },
                            React.createElement(TextField, { select: true, fullWidth: true, size: "small", slotProps: {
                                    input: {
                                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Audio Bit Rate"),
                                    },
                                }, value: callContext.bitRate, onChange: setBitRate },
                                React.createElement(MenuItem, { value: 8000 }, "8kbps"),
                                React.createElement(MenuItem, { value: 16000 }, "16kbps"),
                                React.createElement(MenuItem, { value: 32000 }, "32kbps")))))),
                filteredUnreadMessages && filteredUnreadMessages.length > 0 && (React.createElement(Grid2, null,
                    React.createElement(Divider, null))),
                filteredUnreadMessages && filteredUnreadMessages.length > 0 && (React.createElement(Grid2, null,
                    React.createElement(List, { dense: true }, filteredUnreadMessages.map((message) => {
                        var _a, _b, _c, _d, _e;
                        const fromPhoneLabel = (_b = (_a = twilioPhones === null || twilioPhones === void 0 ? void 0 : twilioPhones.find((x) => x.phone_number === message.from_)) === null || _a === void 0 ? void 0 : _a.friendly_name) !== null && _b !== void 0 ? _b : message.from_;
                        const toPhoneLabel = (_d = (_c = twilioPhones === null || twilioPhones === void 0 ? void 0 : twilioPhones.find((x) => x.phone_number === message.to)) === null || _c === void 0 ? void 0 : _c.friendly_name) !== null && _d !== void 0 ? _d : message.to;
                        return (React.createElement(ListItem, { key: message.sid, divider: true, secondaryAction: React.createElement(Tooltip, { title: React.createElement(Typography, null, "Ignore") },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: ignoreMessage(message.sid) },
                                        React.createElement(Remove, null)))) },
                            React.createElement(ListItemText, { primary: [fromPhoneLabel, toPhoneLabel].join(", "), secondary: React.createElement(Grid2, { container: true, direction: "column", spacing: 0 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body2" }, message.body.length > 127 ? `${message.body.slice(0, 127)}...` : message.body)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic" } }, format((_e = message.date_sent) !== null && _e !== void 0 ? _e : message.date_created)))), onClick: openMessage(message.sid), style: { cursor: "pointer" } })));
                    }))))))));
};
export default Twilio;
