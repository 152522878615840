/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Lists all registered Twilio phone numbers

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Phone Numbers (cached)
 */
export const listCachedTwilioPhones = (params, options) => {
    return axios.get(`/api/twilio/cached_phones`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListCachedTwilioPhonesQueryKey = (params) => {
    return [`/api/twilio/cached_phones`, ...(params ? [params] : [])];
};
export const getListCachedTwilioPhonesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCachedTwilioPhonesQueryKey(params);
    const queryFn = ({ signal }) => listCachedTwilioPhones(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Phone Numbers (cached)
 */
export function useListCachedTwilioPhones(params, options) {
    const queryOptions = getListCachedTwilioPhonesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
