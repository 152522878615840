import * as React from "react";
import { AttachFile, CalendarMonth, Send } from "@mui/icons-material";
import { Box, Chip, Divider, Grid2, IconButton, InputAdornment, LinearProgress, TextField, Tooltip, Typography, } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { TwilioSMSContext } from "@app/entrypoints/brokerage/context/twilio-sms/TwilioSMSContext";
import { markMessageAsRead, useTwilioBulkCancelMessages, useTwilioBulkSendMessages } from "@app/orval/api/twilio";
import { isValidPhoneNumber } from "@app/util/Utils";
import AttachFilesButton from "./components/AttachFilesButton";
import ConversationMessage from "./components/ConversationMessage";
import ScheduleMessageButton from "./components/ScheduleMessageButton";
import SnippetButton from "./components/SnippetButton";
import TwilioSendingMessage from "./components/TwilioSendingMessage";
import TwilioSMSLoading from "./components/TwilioSMSLoading";
import { uploadFile } from "./media";
import useListMessagesIncrementally from "./useListMessagesIncrementally";
const TwilioSMSConversation = (props) => {
    const { fromPhoneNumber, onMessageCancelled, onMessageSent, toPhoneNumber } = props;
    const [message, setMessage] = React.useState("");
    const [files, setFiles] = React.useState([]);
    const [currentlySendingMessages, setCurrentlySendingMessages] = React.useState([]);
    const { ignoreMessage } = React.useContext(TwilioSMSContext);
    const handleMessageChanged = React.useCallback((e) => {
        setMessage(e.target.value);
    }, []);
    const { addMessage, messages, removeMessage } = useListMessagesIncrementally({
        fromPhoneNumber,
        toPhoneNumber,
        refreshIntervalMilliseconds: 5000,
    });
    const isToPhoneNumberValid = React.useMemo(() => {
        return toPhoneNumber != null && isValidPhoneNumber(toPhoneNumber);
    }, [toPhoneNumber]);
    // log when the user has seen the most recent message
    const mostRecentMessageSid = React.useMemo(() => {
        var _a;
        return (_a = messages === null || messages === void 0 ? void 0 : messages[0]) === null || _a === void 0 ? void 0 : _a.sid;
    }, [messages]);
    React.useEffect(() => {
        if (mostRecentMessageSid) {
            ignoreMessage(mostRecentMessageSid);
            markMessageAsRead(mostRecentMessageSid)
                .then(() => {
                console.log(`Marked message ${mostRecentMessageSid} as read`);
            })
                .catch(console.error);
        }
    }, [ignoreMessage, mostRecentMessageSid]);
    const sendMessageApi = useTwilioBulkSendMessages();
    const bulkCancelApi = useTwilioBulkCancelMessages();
    const messagesRef = React.useRef(null);
    React.useEffect(() => {
        if (messagesRef.current) {
            console.log("scrolling to bottom");
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);
    const sendMessage = React.useCallback((sendAt) => {
        const id = Math.random().toString();
        setCurrentlySendingMessages([...currentlySendingMessages, { message, id, timestamp: new Date() }]);
        sendMessageApi
            .mutateAsync({
            data: {
                messages: [
                    {
                        from_: fromPhoneNumber,
                        to: toPhoneNumber,
                        body: message,
                        send_at: sendAt,
                        media_uids: files.map((x) => x.uid),
                    },
                ],
            },
        })
            .then((result) => {
            console.log({ result });
            addMessage(result.data[0]);
            if (onMessageSent) {
                onMessageSent(Object.assign(Object.assign({}, result.data[0]), { send_at: sendAt, sent_by: null }));
            }
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            setCurrentlySendingMessages((prev) => prev.filter((x) => x.id !== id));
        });
        setMessage("");
        setFiles([]);
    }, [
        currentlySendingMessages,
        message,
        sendMessageApi,
        fromPhoneNumber,
        toPhoneNumber,
        files,
        addMessage,
        onMessageSent,
    ]);
    const sendMessageNow = React.useCallback(() => {
        sendMessage(null);
    }, [sendMessage]);
    const scheduleMessage = React.useCallback((at) => {
        sendMessage(at.toISOString());
    }, [sendMessage]);
    const cancelMessage = React.useCallback((messageSid) => {
        removeMessage(messageSid);
        bulkCancelApi
            .mutateAsync({ data: { sids: [messageSid] } })
            .then(() => {
            if (onMessageCancelled) {
                onMessageCancelled(messageSid);
            }
        })
            .catch(enqueueErrorSnackbar);
    }, [bulkCancelApi, onMessageCancelled, removeMessage]);
    const onFilesSelected = React.useCallback((value) => {
        setFiles(value.map((f) => ({
            id: uuidv4(),
            file: f,
        })));
        for (const file of value) {
            uploadFile(file).then((uid) => {
                setFiles((prev) => prev.map((x) => {
                    if (x.file === file) {
                        return Object.assign(Object.assign({}, x), { uid });
                    }
                    return x;
                }));
            });
        }
    }, []);
    const removeFile = React.useCallback((fileId) => () => {
        setFiles((prev) => prev.filter((x) => x.id !== fileId));
    }, []);
    const isLoading = fromPhoneNumber != null && toPhoneNumber != null && isToPhoneNumberValid && messages == null;
    const canSendMessage = 
    // message must be non-empty or files must be attached
    (!!message.trim() || files.length > 0) &&
        // from phone number must be selected
        !!fromPhoneNumber &&
        // to phone number must be selected
        !!toPhoneNumber &&
        // can't currently be loading
        !isLoading &&
        // must have loaded messages
        messages != null &&
        // all files must be uploaded to Cloud Storage
        !files.some((x) => !x.uid);
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1, style: { height: "100%" } },
        React.createElement(Grid2, { style: { flex: 1, overflowY: "hidden" } },
            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1, style: { height: "100%" } },
                (!fromPhoneNumber || !toPhoneNumber || !isToPhoneNumberValid) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h6", style: { color: "#666", textAlign: "center" } }, "Select a phone number")),
                    React.createElement(Grid2, { style: { flex: 1 } }))),
                isLoading && (React.createElement(Grid2, { style: { flex: 1 } },
                    React.createElement(TwilioSMSLoading, null))),
                messages && messages.length === 0 && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h6", style: { color: "#666", textAlign: "center" } }, "Type a message to get started")),
                    React.createElement(Grid2, { style: { flex: 1 } }))),
                messages != null && (React.createElement(Grid2, { style: { overflowY: "hidden" } },
                    React.createElement(Box, { p: 2, style: { height: "calc(100% - 1rem)" } },
                        React.createElement(Grid2, { ref: messagesRef, container: true, direction: "column", wrap: "nowrap", spacing: 2, flexDirection: "column-reverse", style: { height: "100%", overflowY: "scroll" } },
                            currentlySendingMessages
                                .sort((a, b) => {
                                if (a.timestamp > b.timestamp)
                                    return -1;
                                if (a.timestamp < b.timestamp)
                                    return 1;
                                return 0;
                            })
                                .map((x) => (React.createElement(Grid2, { key: x.id },
                                React.createElement(TwilioSendingMessage, { message: x.message, timestamp: x.timestamp })))),
                            messages.map((x) => (React.createElement(Grid2, { key: x.sid },
                                React.createElement(ConversationMessage, { message: x, onMessageCanceled: cancelMessage })))))))))),
        React.createElement(Grid2, null, sendMessageApi.isPending ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(TextField, { placeholder: "Type a message", size: "small", multiline: true, minRows: 1, maxRows: 8, fullWidth: true, value: message, onChange: handleMessageChanged, slotProps: {
                        input: {
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(Grid2, { container: true, spacing: 0, direction: "row", wrap: "nowrap", sx: { "& .MuiIconButton-root": { padding: 0 } } },
                                    React.createElement(Grid2, null,
                                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add snippet...") },
                                            React.createElement("span", null,
                                                React.createElement(SnippetButton, null)))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Attach files...") },
                                            React.createElement("span", null,
                                                React.createElement(AttachFilesButton, { onFilesSelected: onFilesSelected },
                                                    React.createElement(AttachFile, null))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Schedule message...") },
                                            React.createElement("span", null,
                                                React.createElement(ScheduleMessageButton, { IconButtonProps: { disabled: !canSendMessage }, onDateTimeSelected: scheduleMessage },
                                                    React.createElement(CalendarMonth, null))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(IconButton, { tabIndex: -1, disabled: !canSendMessage, onClick: sendMessageNow },
                                            React.createElement(Send, null)))))),
                        },
                    } }))),
        files.length > 0 && (React.createElement(Grid2, null,
            React.createElement(Box, { pl: 2, pr: 2 },
                React.createElement(Grid2, { container: true, spacing: 1 }, files.map((file) => (React.createElement(Grid2, { key: file.id },
                    React.createElement(Chip, { size: "small", label: file.file.name, onDelete: removeFile(file.id) }))))))))));
};
export default TwilioSMSConversation;
