import * as React from "react";
import { Skeleton, Stack } from "@mui/material";
const StagesSkeleton = () => {
    return (React.createElement(Stack, { spacing: 2 },
        React.createElement(Skeleton, { variant: "rectangular" }),
        React.createElement(Skeleton, { variant: "rectangular" }),
        React.createElement(Skeleton, { variant: "rectangular" }),
        React.createElement(Skeleton, { variant: "rectangular" }),
        React.createElement(Skeleton, { variant: "rectangular" })));
};
export default StagesSkeleton;
