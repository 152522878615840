import * as React from "react";
import { Public, SelfImprovement } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import dashLogo from "@app/assets/img/logo-notext.png";
import useSession from "@app/hooks/useSession";
import UserAutocomplete from "../../../components/box-view/actions/UserAutocomplete";
const SendVCardIcon = (props) => {
    const { mode } = props;
    if (mode === "to myself") {
        return React.createElement(SelfImprovement, null);
    }
    if (mode === "to a dash user") {
        return React.createElement("img", { src: dashLogo, alt: "Dash", height: 24 });
    }
    return React.createElement(Public, null);
};
const SendVCardUserSelect = (props) => {
    var _a;
    const { onChange, users, value } = props;
    const session = useSession();
    const usersWithPhoneNumber = React.useMemo(() => {
        var _a;
        return ((_a = users === null || users === void 0 ? void 0 : users.filter((x) => { var _a; return (_a = x.fields["Phone"]) === null || _a === void 0 ? void 0 : _a.trim(); }).sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _a !== void 0 ? _a : null);
    }, [users]);
    const usersByPhoneNumber = React.useMemo(() => {
        if (!usersWithPhoneNumber) {
            return null;
        }
        const result = {};
        for (const user of usersWithPhoneNumber) {
            result[user.fields["Phone"].trim()] = user;
        }
        return result;
    }, [usersWithPhoneNumber]);
    const [mode, setMode] = React.useState("to a dash user");
    const toggleMode = React.useCallback(() => {
        setMode((prev) => {
            if (prev === "to myself") {
                return "to a dash user";
            }
            if (prev === "to a dash user") {
                return "to someone else";
            }
            return "to myself";
        });
    }, [setMode]);
    React.useEffect(() => {
        var _a, _b, _c;
        if (mode === "to myself") {
            onChange((_c = (_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.trim()) !== null && _c !== void 0 ? _c : "");
        }
        else {
            onChange("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);
    const handleValueChanged = React.useCallback((e) => {
        onChange(e.target.value);
    }, [onChange]);
    const handleUserChanged = React.useCallback((_, val) => {
        var _a, _b;
        onChange((_b = (_a = val === null || val === void 0 ? void 0 : val.phone) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : "");
    }, [onChange]);
    const icon = React.createElement(SendVCardIcon, { mode: mode });
    const endAdornment = (React.createElement(Button, { size: "small", startIcon: icon, onClick: toggleMode, variant: "text", style: { whiteSpace: "nowrap", width: mode === "to someone else" ? "150px" : undefined } }, mode));
    if (mode === "to myself") {
        return React.createElement(TextField, { size: "small", fullWidth: true, value: value !== null && value !== void 0 ? value : "", slotProps: { input: { endAdornment } }, disabled: true });
    }
    if (mode === "to someone else") {
        return (React.createElement(TextField, { size: "small", fullWidth: true, value: value !== null && value !== void 0 ? value : "", slotProps: {
                htmlInput: {
                    maxWidth: 255,
                },
                input: {
                    endAdornment,
                },
            }, onChange: handleValueChanged }));
    }
    return (React.createElement(UserAutocomplete, { users: usersWithPhoneNumber !== null && usersWithPhoneNumber !== void 0 ? usersWithPhoneNumber : [], value: value ? ((_a = usersByPhoneNumber === null || usersByPhoneNumber === void 0 ? void 0 : usersByPhoneNumber[value]) !== null && _a !== void 0 ? _a : null) : null, onChange: handleUserChanged, AutocompleteProps: { disabled: !users }, TextFieldProps: {
            InputProps: { endAdornment, style: { paddingRight: 0 } },
        } }));
};
export default SendVCardUserSelect;
