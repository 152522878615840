import * as React from "react";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, Grid2, Typography } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDrop } from "react-dnd";
import { DragAndDropItemTypes } from "./utils";
const Section = (props) => {
    const { dragOverId, isClickable, isCollapsed, isCollapsible, onCollapse, onFieldMovedToSection, section } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id: section.uid });
    const collapse = React.useCallback(() => {
        onCollapse(section.uid);
    }, [onCollapse, section.uid]);
    /**
     * NOTE: This is used to handle dropping fields into sections.
     * This should only fire if the dropped field has the same entity type as the section
     * AND is not already in the section. This allows fields to be dropped in sections
     * that have no fields.
     */
    const [_, drop] = useDrop(() => ({
        accept: DragAndDropItemTypes.FIELD,
        drop: (item) => {
            // @ts-expect-error: unknown
            console.log(`dropped item ${item.uid} onto section ${section.uid}`);
            // @ts-expect-error: unknown
            onFieldMovedToSection({ fieldUid: item.uid, sectionUid: section.uid });
        },
        canDrop: (item) => {
            // @ts-expect-error: item is of type unknown
            return item.entityType === section.entity_type && item.sectionUid !== section.uid;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [onFieldMovedToSection, section.entity_type, section.uid]);
    // const select = React.useCallback(() => {
    //   console.log("SELECT", section.uid);
    //   onSelect(section.uid);
    // }, [onSelect, section.uid]);
    const isActive = dragOverId === section.uid;
    return (React.createElement(Grid2, { ref: setNodeRef, className: "dash-section", "data-dash-entity": section.entity_type, "data-dash-section-uid": section.uid, container: true, spacing: 1, pl: 4, alignItems: "center", wrap: "nowrap", style: {
            background: isActive ? "#aaeedd" : undefined,
            transform: CSS.Transform.toString(transform),
        } },
        React.createElement(Grid2, null,
            React.createElement(IconButton, { size: "small", onClick: collapse, style: {
                    visibility: isCollapsible ? undefined : "hidden",
                } }, isCollapsed ? React.createElement(KeyboardArrowDown, { fontSize: "small" }) : React.createElement(KeyboardArrowRight, { fontSize: "small" }))),
        React.createElement(Grid2, null,
            React.createElement(Typography, Object.assign({ ref: drop, style: {
                    fontStyle: !section.uid ? "italic" : undefined,
                    cursor: isClickable ? "pointer" : undefined,
                } }, attributes, listeners), section.name))));
};
export default Section;
