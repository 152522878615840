var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Chip, FormControl, InputLabel, TextField, Select } from "@mui/material";
import omit from "lodash/omit";
const CoreChoiceField = (props) => {
    var _a, _b;
    const { children, multiple, onChoiceChange, onChoiceFieldBlurred, onChoicesChange, options, renderValue, value } = props, other = __rest(props, ["children", "multiple", "onChoiceChange", "onChoiceFieldBlurred", "onChoicesChange", "options", "renderValue", "value"]);
    const [stateValue, setStateValue] = React.useState(value);
    const initialValue = React.useRef(value);
    const defaultMultiSelectRenderValue = React.useCallback((selected) => {
        const selectedValues = selected;
        // if children are all MenuItems
        if (children && children.filter((child) => { var _a, _b; return ((_b = (_a = child.type) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.name) !== "MuiMenuItem"; }).length === 0) {
            return (React.createElement("div", { style: {
                    display: "flex",
                    flexWrap: "wrap",
                } }, children
                .filter((child) => selectedValues.includes(child.props.value))
                .map((child) => (React.createElement(Chip, { key: `${String(child.props.value)}__${String(child.props.children)}`, label: child.props.children, style: { marginRight: 2, height: "unset" } })))));
        }
        return (React.createElement("div", { style: {
                display: "flex",
                flexWrap: "wrap",
            } }, selectedValues.map((value) => (React.createElement(Chip, { key: value, label: options ? options[value] : value, style: { marginRight: 2, height: "unset" } })))));
    }, [children, options]);
    const onBlur = React.useCallback((e) => {
        if (props.onBlur) {
            // @ts-expect-error
            props.onBlur(e);
        }
        if (onChoiceFieldBlurred) {
            onChoiceFieldBlurred(e, 
            // @ts-expect-error
            stateValue);
        }
    }, [onChoiceFieldBlurred, props, stateValue]);
    const setMultipleChoice = React.useCallback((e) => {
        const val = e.target.value;
        if (JSON.stringify(val) !== JSON.stringify(initialValue.current)) {
            initialValue.current = val;
            setStateValue(val);
            if (onChoicesChange) {
                onChoicesChange(val);
            }
        }
    }, [onChoicesChange]);
    const setChoice = React.useCallback((e) => {
        if (e.target.value !== initialValue.current) {
            initialValue.current = e.target.value;
            if (onChoiceChange) {
                onChoiceChange(e.target.value);
            }
        }
        setStateValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }, [onChoiceChange, props]);
    if (multiple) {
        return (
        // @ts-expect-error
        React.createElement(FormControl, Object.assign({}, omit(other, "SelectProps", "InputProps")),
            React.createElement(InputLabel, null, props.label),
            React.createElement(Select, Object.assign({}, (_a = props.slotProps) === null || _a === void 0 ? void 0 : _a.select, props.SelectProps, { onBlur: onBlur, multiple: true, value: stateValue ? [stateValue].flat().filter((obj) => obj) : [], onChange: setMultipleChoice, renderValue: (_b = props.renderValue) !== null && _b !== void 0 ? _b : defaultMultiSelectRenderValue }), children)));
    }
    return (React.createElement(TextField, Object.assign({}, other, { select: true, value: stateValue !== null && stateValue !== void 0 ? stateValue : "", onChange: setChoice }), children));
};
export default CoreChoiceField;
