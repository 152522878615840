/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get the Spark API response for a property address or mls id
 */
export const searchSpark = (params, options) => {
    return axios.get(`/api/utilities/spark`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getSearchSparkQueryKey = (params) => {
    return [`/api/utilities/spark`, ...(params ? [params] : [])];
};
export const getSearchSparkQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchSparkQueryKey(params);
    const queryFn = ({ signal }) => searchSpark(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the Spark API response for a property address or mls id
 */
export function useSearchSpark(params, options) {
    const queryOptions = getSearchSparkQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 *
This is a wrapper around the Spark API for the flexmls dataset;
our access token is just baked into the query. You can construct queries just
like you would if hiting the Spark API directly, ex:
 

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a list of properties from Spark API
 */
export const listSparkProperties = (params, options) => {
    return axios.get(`/api/utilities/spark/search`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListSparkPropertiesQueryKey = (params) => {
    return [`/api/utilities/spark/search`, ...(params ? [params] : [])];
};
export const getListSparkPropertiesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSparkPropertiesQueryKey(params);
    const queryFn = ({ signal }) => listSparkProperties(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get a list of properties from Spark API
 */
export function useListSparkProperties(params, options) {
    const queryOptions = getListSparkPropertiesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
