import * as React from "react";
import { ListItemIcon, MenuItem } from "@mui/material";
import dotloopLogo from "@app/assets/img/dotloop.png";
import CoreLink from "@app/common/CoreLink";
const OpenLoopInDotloopMenuItem = (props) => {
    var _a;
    return (React.createElement(MenuItem, { component: CoreLink, href: `https://www.dotloop.com/my/loop/${(_a = props.loopId) !== null && _a !== void 0 ? _a : ""}`, target: "_blank", disabled: !props.loopId },
        React.createElement(ListItemIcon, null,
            React.createElement("img", { height: 20, alt: "Dotloop", src: dotloopLogo })),
        "Open in Dotloop"));
};
export default OpenLoopInDotloopMenuItem;
