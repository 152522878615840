// eslint-disable-next-line import/prefer-default-export
export const STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS = {
    title: "Unable to change the stage",
    cancellationText: "Cancel",
    confirmationText: "Yes, override the stage",
    cancellationButtonProps: {
        color: "inherit",
    },
    confirmationButtonProps: {
        variant: "contained",
        color: "error",
    },
    titleProps: {
        style: {
            background: "red",
            color: "white",
        },
    },
    contentProps: {
        style: {
            paddingTop: "1rem",
        },
    },
};
