import * as React from "react";
import { Edit, RemoveCircleOutline } from "@mui/icons-material";
import { Avatar, Grid2, IconButton, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useConfirm } from "material-ui-confirm";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { getFullNameMemoized, getInitialsMemoized } from "@app/util/Utils";
dayjs.extend(utc);
const InterviewListItem = (props) => {
    var _a;
    const { interview, onEditInterview, onRemoveInterview } = props;
    const confirm = useConfirm();
    const handleRemoveInterview = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveInterview(interview.uid);
            }
        });
    }, [confirm, onRemoveInterview, interview.uid]);
    const handleEditInterview = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        onEditInterview(interview.uid);
    }, [onEditInterview, interview.uid]);
    return (React.createElement(ListItem, { divider: true, secondaryAction: React.createElement(React.Fragment, null,
            onEditInterview && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit intervew") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: handleEditInterview },
                        React.createElement(Edit, { fontSize: "small" }))))),
            onRemoveInterview && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove interview") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: handleRemoveInterview },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" })))))) },
        React.createElement(ListItemText, { primary: React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                interview.date_and_time && (React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, dayjs(interview.date_and_time).tz("America/New_York").format("dddd, MMMM D, YYYY h:mm A")))),
                interview.type && (React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2", style: { color: "#888" } }, interview.type))),
                React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                    interview.interviewer && (React.createElement(Grid2, { container: true, style: { marginTop: "auto", marginBottom: "auto" }, spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Avatar, { style: { height: "22px", width: "22px", fontSize: "0.7rem" }, src: (_a = interview.interviewer.icon_url) !== null && _a !== void 0 ? _a : undefined }, getInitialsMemoized(interview.interviewer.first_name, interview.interviewer.last_name) || "?")),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body2", style: { color: "#bbb" } }, "Conducted by")),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body2", style: { color: "#bbb", fontWeight: "bold" } }, getFullNameMemoized(interview.interviewer.first_name, interview.interviewer.last_name))))),
                    interview.notes && (React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption", style: { fontStyle: "italic", color: "#aaa" } }, interview.notes))))) })));
};
export default InterviewListItem;
