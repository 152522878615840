/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Gets the URL of a box with a given `uid`

_Authorization: none required_
 * @summary Get box url
 */
export const getBoxUrl = (uid, options) => {
    return axios.get(`/api/redirects/get_box_url/${uid}`, options);
};
export const getGetBoxUrlQueryKey = (uid) => {
    return [`/api/redirects/get_box_url/${uid}`];
};
export const getGetBoxUrlQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBoxUrlQueryKey(uid);
    const queryFn = ({ signal }) => getBoxUrl(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get box url
 */
export function useGetBoxUrl(uid, options) {
    const queryOptions = getGetBoxUrlQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets the box URL of a comment with a given `uid`

_Authorization: none required_
 * @summary Get comment url
 */
export const getCommentUrl = (uid, options) => {
    return axios.get(`/api/redirects/get_comment_url/${uid}`, options);
};
export const getGetCommentUrlQueryKey = (uid) => {
    return [`/api/redirects/get_comment_url/${uid}`];
};
export const getGetCommentUrlQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetCommentUrlQueryKey(uid);
    const queryFn = ({ signal }) => getCommentUrl(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get comment url
 */
export function useGetCommentUrl(uid, options) {
    const queryOptions = getGetCommentUrlQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets the box URL of a CRM notification with a given `uid`

_Authorization: none required_
 * @summary Get notification url
 */
export const getNotificationUrl = (uid, options) => {
    return axios.get(`/api/redirects/get_notification_url/${uid}`, options);
};
export const getGetNotificationUrlQueryKey = (uid) => {
    return [`/api/redirects/get_notification_url/${uid}`];
};
export const getGetNotificationUrlQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetNotificationUrlQueryKey(uid);
    const queryFn = ({ signal }) => getNotificationUrl(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get notification url
 */
export function useGetNotificationUrl(uid, options) {
    const queryOptions = getGetNotificationUrlQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
