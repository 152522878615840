import { createTheme } from "@mui/material/styles";
import AppConfig from "@app/util/AppConfig";
export const getBaseThemeOptions = (parentTheme) => {
    var _a, _b, _c, _d, _e;
    return (Object.assign(Object.assign({}, parentTheme), { palette: Object.assign(Object.assign({}, parentTheme.palette), { primary: Object.assign(Object.assign({}, parentTheme.palette.primary), { main: {
                    light: AppConfig.themeColors.primary,
                    dark: AppConfig.themeColors.primary,
                }[parentTheme.palette.mode] }) }), components: Object.assign(Object.assign({}, parentTheme.components), { MuiCheckbox: Object.assign(Object.assign({}, (_b = (_a = parentTheme.components) === null || _a === void 0 ? void 0 : _a.MuiCheckbox) === null || _b === void 0 ? void 0 : _b.styleOverrides), { styleOverrides: {
                    root: {
                        "& svg": {
                            width: 16,
                            height: 16,
                        },
                    },
                } }), MuiDataGrid: Object.assign(Object.assign({}, (_c = parentTheme.components) === null || _c === void 0 ? void 0 : _c.MuiDataGrid), { styleOverrides: Object.assign(Object.assign({}, (_e = (_d = parentTheme.components) === null || _d === void 0 ? void 0 : _d.MuiDataGrid) === null || _e === void 0 ? void 0 : _e.styleOverrides), { main: {
                        border: "1px solid #ddd",
                    }, columnHeaders: {
                        overflow: "visible",
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            overflow: "visible",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            overflow: "visible",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainerContent div": {
                            overflow: "hidden !important",
                        },
                    }, pinnedRows: {
                        "& .MuiDataGrid-pinnedColumns": {
                            height: "42px",
                            width: "100%",
                            zIndex: 1,
                        },
                        "& .MuiDataGrid-pinnedRowsRenderZone": {
                            marginLeft: "-20px", // to compensate for smaller checkbox column
                        },
                    }, pinnedColumns: {
                        boxShadow: "none",
                        borderRight: "2px solid #eee",
                    }, columnHeaderCheckbox: {
                        width: "30px !important",
                        minWidth: "30px !important",
                    }, cellCheckbox: {
                        width: "30px !important",
                        minWidth: "30px !important",
                    }, columnHeaderTitleContainer: {
                        "& .MuiDataGrid-checkboxInput:not(.Mui-checked):not(.MuiCheckbox-indeterminate)": {
                            display: "none",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                            width: "100%",
                        },
                        width: "100%",
                    }, iconButtonContainer: {
                        marginLeft: "auto",
                    }, columnHeaderTitle: {
                        fontWeight: "bold",
                    }, columnHeader: {
                        paddingLeft: "4px",
                        paddingRight: 0,
                        borderTop: "hidden",
                        "&:focus": {
                            outline: "none",
                        },
                        "&:focus-within": {
                            outline: "none",
                        },
                        "&.vertical-header": {
                            "& .MuiDataGrid-columnHeaderTitle": {
                                overflow: "visible",
                            },
                            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                                transform: "rotate(-90deg) translate(-100px, 15px)",
                                transformOrigin: "left",
                                overflow: "visible",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                display: "none",
                            },
                            "& .MuiSvgIcon-root": {
                                display: "none",
                            },
                        },
                    }, row: {
                        "&.Mui-hovered:not(.editing)": {
                            backgroundColor: parentTheme.palette.mode === "light" ? "rgb(239, 255, 255)" : undefined,
                        },
                        // "&:hover": {
                        //   backgroundColor: parentTheme.palette.mode === "light" ? "rgb(239, 255, 255)" : undefined,
                        //   "& .hover-actions": {
                        //     display: "unset",
                        //   },
                        // },
                        "&.dashcrm-group": {
                            backgroundColor: "#eee",
                            "& .MuiDataGrid-cell": {
                                border: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                            "& .MuiDataGrid-cellCheckbox > .MuiCheckbox-root": {
                                display: "none",
                            },
                            "& :focus-within": {
                                outline: "none",
                            },
                        },
                        "&.dashcrm-group-selected": {
                            position: "absolute",
                        },
                    }, cell: {
                        fontFamily: `'${AppConfig.fonts.primary}', Helvetica, Arial, sans-serif;`,
                        "&.MuiDataGrid-cell--editing": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none",
                            },
                            "& .MuiInputBase-input": {
                                padding: 0,
                                paddingLeft: "9px",
                                paddingTop: "1px",
                            },
                        },
                        "&.no-focus-ring": {
                            outline: "none !important",
                        },
                        "&:focus": {
                            outlineWidth: "2px",
                        },
                        "& input": {
                            fontSize: "11px",
                        },
                        '&[data-field="name"]': {
                            fontWeight: "bold",
                        },
                        "&.cell-button": {
                            padding: 0,
                        },
                        "&.cell-field-number": {
                            textAlign: "right",
                        },
                        "&.cell-field-datetime": {
                            textAlign: "right",
                        },
                        "&.cell-field-date": {
                            textAlign: "right",
                        },
                        "&.cell-field-time": {
                            textAlign: "right",
                        },
                        "&.cell-field-formula": {
                            textAlign: "right",
                            fontStyle: "italic",
                            opacity: 0.85,
                        },
                        "&.cell-field-error": {
                            textAlign: "left",
                            position: "relative",
                            "-webkit-justify-content": "flex-start",
                        },
                        "&.cell-field-error::after": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderWidth: "0 6px 6px 0",
                            borderColor: "transparent #f00 transparent transparent",
                        },
                        "&.cell-field-frozen": {
                            textAlign: "left",
                            position: "relative",
                            "-webkit-justify-content": "flex-start",
                        },
                        "&.cell-field-frozen::before": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderWidth: "0 6px 6px 0",
                            borderColor: "transparent #00f transparent transparent",
                        },
                        "&.cell-field-checkbox": {
                            "& label": {
                                margin: "auto",
                                marginTop: "5px",
                                color: {
                                    light: "blue",
                                    dark: "lightblue",
                                }[parentTheme.palette.mode],
                            },
                        },
                        "& > .MuiChip-root": {
                            height: "16px",
                        },
                        "& .dash-actions-button": {
                            height: "20px",
                            padding: 0,
                        },
                    } }) }) }), typography: Object.assign(Object.assign({}, parentTheme.typography), { fontSize: 11 }) }));
};
const getCrmGridThemeOptions = (parentTheme) => {
    var _a, _b, _c, _d, _e, _f;
    const base = getBaseThemeOptions(parentTheme);
    return Object.assign(Object.assign({}, base), { components: Object.assign(Object.assign({}, base.components), { MuiDataGrid: Object.assign(Object.assign({}, (_a = base.components) === null || _a === void 0 ? void 0 : _a.MuiDataGrid), { styleOverrides: Object.assign(Object.assign({}, (_c = (_b = base.components) === null || _b === void 0 ? void 0 : _b.MuiDataGrid) === null || _c === void 0 ? void 0 : _c.styleOverrides), { main: Object.assign(Object.assign({}, (_f = (_e = (_d = base.components) === null || _d === void 0 ? void 0 : _d.MuiDataGrid) === null || _e === void 0 ? void 0 : _e.styleOverrides) === null || _f === void 0 ? void 0 : _f.main), { "& .MuiDataGrid-cell--pinnedLeft:nth-child(n+2)": {
                            transform: "translateX(-20px)",
                            "& .MuiIconButton-sizeSmall": {
                                transform: "translate(1px, -2px)",
                            },
                        }, "& .MuiDataGrid-columnHeader--pinnedLeft:nth-child(n+2)": {
                            transform: "translateX(-20px)",
                        } }) }) }) }) });
};
export const gridTheme = (parentTheme) => createTheme(getCrmGridThemeOptions(parentTheme));
export const gridThemeNoCheckboxes = (parentTheme) => createTheme(getBaseThemeOptions(parentTheme));
export default gridTheme;
