import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import UsersForm from "../../features/crm/users/UsersForm";
const UserFormPage = () => {
    var _a;
    useDocumentTitle("Users");
    const params = useParams();
    const searchParams = useSearchParams();
    const tab = (_a = searchParams[0].get("tab")) !== null && _a !== void 0 ? _a : undefined;
    return React.createElement(UsersForm, { tab: tab, uid: params.uid });
};
export default UserFormPage;
