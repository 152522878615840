import * as React from "react";
import { useMemo } from "react";
import { Public, Share, Lock } from "@mui/icons-material";
import { Alert, Typography, Grid } from "@mui/material";
import useSession from "@app/hooks/useSession";
import SavedViewShareButton from "./SavedViewShareButton";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewSharingDescription = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const context = useSavedViewEditorContext();
    const session = useSession();
    const isShared = ((_b = (_a = context.view) === null || _a === void 0 ? void 0 : _a.sharing.roles) !== null && _b !== void 0 ? _b : []).find((x) => x.is_shared || x.is_editable || x.is_pinned) ||
        ((_d = (_c = context.view) === null || _c === void 0 ? void 0 : _c.sharing.offices) !== null && _d !== void 0 ? _d : []).find((x) => x.is_shared || x.is_editable || x.is_pinned) ||
        ((_f = (_e = context.view) === null || _e === void 0 ? void 0 : _e.sharing.users) !== null && _f !== void 0 ? _f : []).find((x) => { var _a; return (x.is_shared || x.is_editable || x.is_pinned) && x.user !== ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid); });
    const allUsersPermissions = (_g = context.view) === null || _g === void 0 ? void 0 : _g.sharing.all_users;
    const isAccessibleByAllUsers = (allUsersPermissions === null || allUsersPermissions === void 0 ? void 0 : allUsersPermissions.is_shared) || (allUsersPermissions === null || allUsersPermissions === void 0 ? void 0 : allUsersPermissions.is_editable) || (allUsersPermissions === null || allUsersPermissions === void 0 ? void 0 : allUsersPermissions.is_pinned);
    const icon = useMemo(() => {
        if (isAccessibleByAllUsers) {
            return React.createElement(Public, { color: "primary" });
        }
        if (isShared) {
            return React.createElement(Share, { color: "primary" });
        }
        return React.createElement(Lock, { color: "primary" });
    }, [isAccessibleByAllUsers, isShared]);
    const description = useMemo(() => {
        if (isAccessibleByAllUsers) {
            return `This view ${context.uid ? "is" : "will be"} accessible to all users`;
        }
        if (isShared) {
            return `This view ${context.uid ? "is" : "will be"} shared with other users`;
        }
        return `This view ${context.uid ? "is" : "will be"} private`;
    }, [context.uid, isAccessibleByAllUsers, isShared]);
    return (React.createElement(Alert, { severity: "info", icon: icon, sx: { height: "32px", "& .MuiAlert-message": { width: "100%" } } },
        React.createElement(Grid, { container: true, style: { width: "100%" } },
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, null, description)),
            React.createElement(Grid, { item: true, style: { transform: "translateY(-5px)" } },
                React.createElement(SavedViewShareButton, null)))));
};
export default SavedViewSharingDescription;
