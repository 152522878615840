import * as React from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetBuyerContract } from "@app/orval/api/buyer-contracts";
import MailScheduleTemplatePreview from "../../features/mailschedule/preview/MailScheduleTemplatePreview";
const BuyerContractsMailSchedulePreviewPage = () => {
    var _a, _b;
    const params = useParams();
    const { template_uid, uid } = params;
    useDocumentTitle("Buyer Contracts | Mail Schedule");
    const navigate = useNavigate();
    const getBuyerContractApi = useGetBuyerContract(uid);
    const buyerContract = (_b = (_a = getBuyerContractApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const redirectUrl = `/buyer-contracts/${uid}/mail-schedule`;
    const onTemplateProcessed = React.useCallback(() => {
        navigate(redirectUrl);
    }, [navigate, redirectUrl]);
    if (!buyerContract) {
        return React.createElement(CoreLoading, null);
    }
    if (!buyerContract.streak_buyer_contracts_box_key) {
        return React.createElement(Typography, null, "Unable to load Mail Schedule");
    }
    return (React.createElement(MailScheduleTemplatePreview, { boxKey: buyerContract.streak_buyer_contracts_box_key, templateUid: template_uid, onTemplateProcessed: onTemplateProcessed, onCloseRedirectUrl: redirectUrl }));
};
export default BuyerContractsMailSchedulePreviewPage;
