import * as React from "react";
import { Cake, Edit, Email, KeyboardArrowDown, KeyboardArrowRight, Phone, RemoveCircleOutline, } from "@mui/icons-material";
import { Avatar, Grid2, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useConfirm } from "material-ui-confirm";
import CallLink from "@app/common/links/CallLink";
import EmailLink from "@app/common/links/EmailLink";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import ClientAliasDialog from "./ClientAliasDialog";
const ClientListItem = (props) => {
    var _a;
    const { allowEditing, client, disableDialog, dragHandle, getBuyerLeadApi, getSellerLeadApi, onRemoveClient } = props;
    const session = useSession();
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [currentlyEditingClientUid, setCurrentlyEditingClientUid] = React.useState(null);
    const toggleCollapse = React.useCallback(() => {
        setIsCollapsed((prev) => !prev);
    }, []);
    const confirm = useConfirm();
    const removeClient = React.useCallback(() => {
        confirm({
            title: "Are you sure?",
            description: "This will just remove the client; it will not delete the contact record.",
            confirmationText: "Remove",
        }).then((result) => {
            if (result.confirmed) {
                onRemoveClient(client.client.uid);
            }
        });
    }, [client.client.uid, confirm, onRemoveClient]);
    const editClient = React.useCallback(() => {
        setCurrentlyEditingClientUid(client.client.uid);
    }, [client.client.uid]);
    const stopEditingClient = React.useCallback(() => {
        setCurrentlyEditingClientUid(null);
    }, []);
    const initials = [client.client.first_name, client.client.last_name]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o === null || o === void 0 ? void 0 : o.substring(0, 1))
        .join("");
    const name = [client.client.first_name, client.client.last_name].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" ");
    const { email } = client.client;
    const { phone } = client.client;
    const dateOfBirth = client.client.date_of_birth;
    return (React.createElement(ListItem, { secondaryAction: React.createElement(React.Fragment, null,
            allowEditing && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit client") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: editClient },
                        React.createElement(Edit, { fontSize: "small" }))))),
            onRemoveClient && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove client") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removeClient },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" }))))),
            React.createElement(IconButton, { size: "small", onClick: toggleCollapse }, isCollapsed ? React.createElement(KeyboardArrowRight, { fontSize: "small" }) : React.createElement(KeyboardArrowDown, { fontSize: "small" }))) },
        dragHandle,
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, { src: undefined, style: { height: "28px", width: "28px", fontSize: "0.75rem" } }, initials)),
        React.createElement(ListItemText, { style: { marginTop: 0, marginBottom: 0 }, primary: React.createElement(Grid2, { container: true, direction: "column" },
                React.createElement(Grid2, null, ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) ? (React.createElement(RouterLink, { to: `/contacts/${client.client.uid}`, style: { textDecoration: "none" } },
                    React.createElement(Typography, { variant: "body2" }, name || "(no name)"))) : (React.createElement(Typography, { variant: "body2" }, name || "(no name)"))),
                email && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Email, { fontSize: "small", style: { color: "#888", height: "12px" } })),
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(EmailLink, { emailAddresses: [email], TypographyProps: { variant: "caption" } }))))),
                phone && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Phone, { fontSize: "small", style: { color: "#888", height: "12px" } })),
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(CallLink, { phoneNumber: phone, name: name, TypographyProps: { variant: "caption" } }))))),
                dateOfBirth && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Cake, { fontSize: "small", style: { color: "#888", height: "12px" } })),
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "caption" }, dayjs(dateOfBirth).format("MMMM D, YYYY"))))))), secondary: !isCollapsed
                ? client.added_at
                    ? `Added ${dayjs(client.added_at).format("M/D/YYYY h:mm A")}`
                    : undefined
                : undefined }),
        currentlyEditingClientUid && !disableDialog && (React.createElement(ClientAliasDialog, { client: client, DialogProps: { open: true, onClose: stopEditingClient }, onConfirm: stopEditingClient, onCancel: stopEditingClient, getBuyerLeadApi: getBuyerLeadApi, getSellerLeadApi: getSellerLeadApi }))));
};
export default ClientListItem;
