import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AccountBox, Book, Description, Error, Notes, PlayArrow } from "@mui/icons-material";
import { Tabs, Tab, Alert, Grid2 } from "@mui/material";
import styled from "styled-components";
import dashIcon from "@app/assets/img/logo.svg";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import useSession from "@app/hooks/useSession";
import MailScheduleBoilerplateWrapper from "./boilerplate/MailScheduleBoilerplateWrapper";
import MailScheduleBoxesTableWrapper from "./boxes/MailScheduleBoxesTableWrapper";
import MailScheduleErrorsTable from "./errors/MailScheduleErrorsTable";
import MailScheduleDetailedLogsPage from "./logs/MailScheduleDetailedLogsPage";
import MailScheduleExecutionsPage from "./logs/MailScheduleExecutionsPage";
import MailScheduleRun from "./run/MailScheduleRun";
import MailScheduleSettings from "./settings/MailScheduleSettings";
import MailScheduleTemplatesWrapper from "./templates/MailScheduleTemplatesWrapper";
const StyledMailScheduleWrapper = styled("div") `
    height: 100%;
`;
const MailScheduleWrapper = (props) => {
    var _a, _b, _c, _d, _e, _f;
    useDocumentTitle("Mail Schedule");
    const session = useSession();
    const tabs = [
        {
            name: "settings",
            isVisible: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]),
        },
        {
            name: "templates",
            isVisible: (_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"]),
        },
        {
            name: "boilerplate",
            isVisible: (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin", "Admin"]),
        },
        {
            name: "boxes",
            isVisible: true,
        },
        {
            name: "run",
            isVisible: true,
        },
        {
            name: "errors",
            isVisible: true,
        },
        {
            name: "logs",
            isVisible: true,
        },
        {
            name: "logs-detailed",
            isVisible: false,
        },
    ];
    const { page } = props;
    return (React.createElement(StyledMailScheduleWrapper, null,
        React.createElement(Grid2, { container: true, direction: "column", spacing: 0, wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
            React.createElement(Grid2, null,
                React.createElement(Alert, { severity: "info", icon: React.createElement("img", { src: dashIcon, alt: "DASH", height: 24 }) },
                    "This is the the New CRM Mail Schedule. ",
                    React.createElement(RouterLink, { to: "/mailschedule" }, "Click here"),
                    " if you're looking for the Streak Mail Schedule.")),
            React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
                React.createElement(Tabs, { value: tabs
                        .filter((tab) => tab.isVisible)
                        .map((tab) => tab.name)
                        .indexOf(page), indicatorColor: "primary", textColor: "primary" },
                    ((_d = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/ms4/settings", label: React.createElement("div", null,
                            React.createElement(Description, { fontSize: "inherit" }),
                            " Settings") })),
                    ((_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/ms4/templates", label: React.createElement("div", null,
                            React.createElement(Description, { fontSize: "inherit" }),
                            " Templates") })),
                    ((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/ms4/boilerplate", label: React.createElement("div", null,
                            React.createElement(Notes, { fontSize: "inherit" }),
                            " Boilerplate") })),
                    React.createElement(Tab, { component: RouterLink, to: "/ms4/boxes", label: React.createElement("div", null,
                            React.createElement(AccountBox, { fontSize: "inherit" }),
                            " Boxes") }),
                    React.createElement(Tab, { component: RouterLink, to: "/ms4/run", label: React.createElement("div", null,
                            React.createElement(PlayArrow, { fontSize: "inherit" }),
                            " Run") }),
                    React.createElement(Tab, { component: RouterLink, to: "/ms4/errors", label: React.createElement("div", null,
                            React.createElement(Error, { fontSize: "inherit" }),
                            " Errors") }),
                    React.createElement(Tab, { component: RouterLink, to: "/ms4/logs?template_isnull=true&agent_isnull=true", label: React.createElement("div", null,
                            React.createElement(Book, { fontSize: "inherit" }),
                            " Execution Logs") })),
                React.createElement("div", { style: { height: "calc(100% - 48px)", overflowY: "auto" } },
                    page === "settings" && React.createElement(MailScheduleSettings, null),
                    page === "templates" && React.createElement(MailScheduleTemplatesWrapper, null),
                    page === "boilerplate" && React.createElement(MailScheduleBoilerplateWrapper, null),
                    page === "boxes" && React.createElement(MailScheduleBoxesTableWrapper, null),
                    page === "run" && React.createElement(MailScheduleRun, null),
                    page === "errors" && React.createElement(MailScheduleErrorsTable, null),
                    page === "logs" && React.createElement(MailScheduleExecutionsPage, null),
                    page === "logs-detailed" && React.createElement(MailScheduleDetailedLogsPage, null))))));
};
export default MailScheduleWrapper;
