import * as React from "react";
import { Check, Error, KeyboardArrowLeft, Pause } from "@mui/icons-material";
import { Box, CircularProgress, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import useBrowserBackStack from "@app/hooks/useBrowserBackStack";
import useSession from "@app/hooks/useSession";
import { BackButton } from "./BackButton";
import CoreLoading from "./CoreLoading";
const Loading = (props) => {
    const { allowBackNavigation, showIdle, showInfiniteSpinner, showLoadingIndicatorWhenRefetching, showPaused } = props;
    const backStack = useBrowserBackStack();
    const items = props.items
        .filter((x) => x.isRelevant !== false)
        .filter((x) => { var _a; return !((_a = x.mutationResult) === null || _a === void 0 ? void 0 : _a.isIdle) || showIdle; })
        .filter((x) => { var _a; return !((_a = x.mutationResult) === null || _a === void 0 ? void 0 : _a.isPaused) || showPaused; })
        .map((x) => {
        var _a, _b, _c, _d;
        let { status } = x;
        if (x.queryResult) {
            status = x.queryResult.isFetching && !!showLoadingIndicatorWhenRefetching ? "pending" : x.queryResult.status;
        }
        if (x.mutationResult) {
            status = x.mutationResult.status;
        }
        return Object.assign(Object.assign({}, x), { status: status, error: (_c = (_a = x.error) !== null && _a !== void 0 ? _a : (_b = x.queryResult) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : (_d = x.mutationResult) === null || _d === void 0 ? void 0 : _d.error });
    });
    const stillLoading = items.filter((x) => x.status !== "success");
    const backNavigation = (allowBackNavigation !== null && allowBackNavigation !== void 0 ? allowBackNavigation : true) && backStack.length > 0 && (React.createElement(BackButton, { url: "", startIcon: React.createElement(KeyboardArrowLeft, null) }, "Go Back"));
    if (stillLoading.length > 0) {
        const pctComplete = ((items.length - stillLoading.reduce((tot, x) => { var _a; return tot + 1 - ((_a = x.percentComplete) !== null && _a !== void 0 ? _a : 0); }, 0)) * 100) / items.length;
        return (React.createElement(Container, { maxWidth: "sm" },
            React.createElement(Box, { p: 4 },
                React.createElement(Grid, { container: true, spacing: 2, direction: "column", alignContent: "center", alignItems: "center" },
                    React.createElement(Grid, { item: true, style: { position: "relative" } },
                        React.createElement(CircularProgress, { value: 100, variant: "determinate", sx: { color: "#eee", position: "absolute" } }),
                        React.createElement(CircularProgress, { value: pctComplete, variant: items.length > 1 ? "determinate" : "indeterminate", color: "primary" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Grid, { container: true, direction: "column" }, items.map((x) => (React.createElement(Grid, { key: x.label, item: true },
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, style: { flex: 1 } },
                                    React.createElement(Typography, { variant: "body2" }, x.label)),
                                (items.length > 1 || x.status !== "pending") && (React.createElement(Grid, { item: true },
                                    React.createElement(LoadingItemIcon, { status: x.status, error: x.error }))))))))),
                    backNavigation && React.createElement(Grid, { item: true }, backNavigation)))));
    }
    if (showInfiniteSpinner) {
        return (React.createElement(Grid, { container: true, direction: "column", spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(CoreLoading, { color: "secondary" })),
            backNavigation && React.createElement(Grid, { item: true }, backNavigation)));
    }
    return null;
};
const LoadingItemIcon = (props) => {
    var _a;
    const { error, status } = props;
    const session = useSession();
    const icon = {
        success: React.createElement(Check, { fontSize: "small", color: "primary" }),
        error: React.createElement(Error, { fontSize: "small", color: "error" }),
        pending: React.createElement(CircularProgress, { size: 12 }),
        idle: React.createElement(Pause, null),
    }[status];
    const openInNewTab = React.useCallback(() => {
        const tab = window.open("about:blank", "_blank");
        tab.document.write(`<pre>${JSON.stringify(format(error), null, 2)}</pre>`);
        tab.document.close();
    }, [error]);
    if (error && ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal)) {
        return (React.createElement(Tooltip, { title: React.createElement("pre", { style: { whiteSpace: "pre-wrap", font: "inherit", fontSize: 12 } }, JSON.stringify(format(error), null, 2)) },
            React.createElement(IconButton, { onClick: openInNewTab, size: "small", style: { height: 0, width: 0 } }, icon)));
    }
    return icon;
};
export default Loading;
