import * as React from "react";
import { Close, CopyAll, Delete } from "@mui/icons-material";
import { Divider, Grid2, IconButton, Tooltip, Typography } from "@mui/material";
import BulkEditIconButton from "./BulkEditIconButton";
import MailMergeIconButton from "./MailMergeButton";
const ActionsToolbar = ({ entityType, onBulkEditComplete, onClose, onCopyEmailAddressesButtonClicked, onDeleteButtonClicked, selectedEntityTypes, selectedUids, }) => {
    var _a;
    const bulkEditPipeline = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (entityType) {
            case "Buyer Contract":
                return "Buyer Contracts";
            case "Buyer Lead":
                return "Buyer Leads";
            case "Recruit":
                return "Recruits";
            case "Listing":
                return "Seller Contracts";
            case "Seller Lead":
                return "Seller Leads";
            case "User":
                return "Users";
            default:
                if (onBulkEditComplete) {
                    throw new Error(`Unexpected entity type ${entityType}`);
                }
                return null;
        }
    }, [entityType, onBulkEditComplete]);
    return (React.createElement(Grid2, { container: true, spacing: 2, alignItems: "center", style: { background: "rgb(236,239,250)" } },
        React.createElement(Grid2, null,
            React.createElement(IconButton, { onClick: onClose },
                React.createElement(Close, null))),
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } },
                selectedUids.size,
                " selected")),
        React.createElement(Grid2, { style: { height: "24px" } },
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Copy email addresses to clipboard") },
                React.createElement(IconButton, { onClick: onCopyEmailAddressesButtonClicked },
                    React.createElement(CopyAll, null)))),
        React.createElement(Grid2, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Start a mail merge with selected items") },
                React.createElement(MailMergeIconButton, { entityType: entityType !== null && entityType !== void 0 ? entityType : (selectedEntityTypes && selectedEntityTypes.size === 1
                        ? (_a = selectedEntityTypes
                            .values()
                            .next()
                            .value) === null || _a === void 0 ? void 0 : _a.replace("Seller Contract", "Listing")
                        : undefined), entityUids: selectedUids }))),
        onBulkEditComplete && bulkEditPipeline && (React.createElement(Grid2, null,
            React.createElement(BulkEditIconButton, { pipeline: bulkEditPipeline, uids: selectedUids, onUpdate: onBulkEditComplete }))),
        onDeleteButtonClicked && (React.createElement(Grid2, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Delete items (ctrl + d)") },
                React.createElement(IconButton, { onClick: onDeleteButtonClicked },
                    React.createElement(Delete, null)))))));
};
export default ActionsToolbar;
