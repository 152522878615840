import * as React from "react";
import { Button, MenuItem, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, RadioGroup, FormControlLabel, Radio, Grid, Box, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import API from "@app/util/api";
import ContactAutocomplete from "./ContactAutocomplete";
class RelatedContactDialog extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                selectedContact: null,
                contactDetails: null,
                relationship: null,
                relationshipTypes: null,
                mode: "select-existing",
            }
        });
        Object.defineProperty(this, "getRelationshipTypesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "getRelationshipTypes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.getRelationshipTypesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.getRelationshipTypesAbortController = new AbortController();
                API.contactRelationshipTypes.list({
                    onSuccess: (result) => {
                        this.setState({
                            relationshipTypes: result.items,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            console.error(error);
                        }
                    },
                    options: {
                        signal: this.getRelationshipTypesAbortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "onContactSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.state.mode === "select-existing") {
                    this.props.onExistingContactSelected(this.state.selectedContact.uid, this.state.relationship);
                }
                else if (this.state.mode === "create-new") {
                    this.props.onNewContactSelected(this.state.contactDetails, this.state.relationship);
                }
                else {
                    throw new Error(`Invalid mode ${this.state.mode}`);
                }
            }
        });
        Object.defineProperty(this, "setSelectedContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, val) => {
                this.setState({
                    selectedContact: val,
                });
            }
        });
        Object.defineProperty(this, "setRelationship", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const val = e.target.value;
                this.setState({
                    relationship: val,
                });
            }
        });
        Object.defineProperty(this, "setMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const val = e.target.value;
                this.setState((prevState) => ({
                    mode: val,
                    selectedContact: val === "select-existing" ? prevState.selectedContact : null,
                    contactDetails: val === "create-new" ? prevState.contactDetails : null,
                }));
            }
        });
        Object.defineProperty(this, "setFirstName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const val = e.target.value;
                this.setState((prevState) => ({
                    contactDetails: Object.assign(Object.assign({}, prevState.contactDetails), { "First Name": val }),
                }));
            }
        });
        Object.defineProperty(this, "setLastName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const val = e.target.value;
                this.setState((prevState) => ({
                    contactDetails: Object.assign(Object.assign({}, prevState.contactDetails), { "Last Name": val }),
                }));
            }
        });
        Object.defineProperty(this, "setEmail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const val = e.target.value;
                this.setState((prevState) => ({
                    contactDetails: Object.assign(Object.assign({}, prevState.contactDetails), { Email: val }),
                }));
            }
        });
        Object.defineProperty(this, "setPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const val = e.target.value;
                this.setState((prevState) => ({
                    contactDetails: Object.assign(Object.assign({}, prevState.contactDetails), { Phone: val }),
                }));
            }
        });
    }
    componentDidMount() {
        this.getRelationshipTypes();
    }
    componentWillUnmount() {
        var _a;
        (_a = this.getRelationshipTypesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    get isValid() {
        const { contactDetails, relationship, selectedContact } = this.state;
        return ((selectedContact ||
            ((contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["First Name"]) &&
                contactDetails["Last Name"] &&
                (contactDetails["Email"] || contactDetails["Phone"]))) &&
            relationship);
    }
    render() {
        var _a, _b, _c, _d, _e;
        const { DialogProps, onClose } = this.props;
        const { contactDetails, mode, relationshipTypes, selectedContact } = this.state;
        return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
            React.createElement(DialogTitle, null, "Select a contact"),
            React.createElement(DialogContent, null,
                React.createElement(Grid, { container: true, spacing: 2, direction: "column" },
                    React.createElement(Grid, { item: true },
                        React.createElement(RadioGroup, { defaultValue: "select-existing", name: "mode", value: mode, onChange: this.setMode },
                            React.createElement(FormControlLabel, { value: "select-existing", control: React.createElement(Radio, null), label: "Select an existing contact" }),
                            React.createElement(FormControlLabel, { value: "create-new", control: React.createElement(Radio, null), label: "Create a new contact" }))),
                    mode === "select-existing" && (React.createElement(Grid, { item: true },
                        React.createElement(ContactAutocomplete, { onChange: this.setSelectedContact, value: selectedContact, TextFieldProps: {
                                label: "Contact",
                            } }))),
                    mode === "create-new" && (React.createElement(Grid, { item: true },
                        React.createElement(Box, { pt: 1, pb: 8 },
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                                    React.createElement(TextField, { label: "First Name", variant: "standard", fullWidth: true, size: "small", value: (_a = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["First Name"]) !== null && _a !== void 0 ? _a : "", onChange: this.setFirstName, slotProps: {
                                            htmlInput: {
                                                maxLength: 255,
                                            },
                                        } })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                                    React.createElement(TextField, { label: "Last Name", variant: "standard", fullWidth: true, size: "small", value: (_b = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Last Name"]) !== null && _b !== void 0 ? _b : "", onChange: this.setLastName, slotProps: {
                                            htmlInput: {
                                                maxLength: 255,
                                            },
                                        } })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                                    React.createElement(TextField, { label: "Email", variant: "standard", fullWidth: true, size: "small", value: (_c = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Email"]) !== null && _c !== void 0 ? _c : "", onChange: this.setEmail, slotProps: {
                                            htmlInput: {
                                                maxLength: 255,
                                            },
                                        } })),
                                React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                                    React.createElement(TextField, { label: "Phone", variant: "standard", fullWidth: true, size: "small", value: (_d = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["Phone"]) !== null && _d !== void 0 ? _d : "", onChange: this.setPhone, slotProps: {
                                            htmlInput: {
                                                maxLength: 255,
                                            },
                                        } })))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { fullWidth: true, select: true, variant: "outlined", size: "small", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Relationship"),
                                },
                            }, value: (_e = this.state.relationship) !== null && _e !== void 0 ? _e : "", onChange: this.setRelationship },
                            React.createElement(MenuItem, { value: "" }),
                            (relationshipTypes !== null && relationshipTypes !== void 0 ? relationshipTypes : []).map((item) => (React.createElement(MenuItem, { key: item, value: item }, item))))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
                React.createElement(Button, { onClick: this.onContactSelected, disabled: !this.isValid, tabIndex: -1 }, "OK"))));
    }
}
export default RelatedContactDialog;
