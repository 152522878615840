/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all field definitions
 */
export const listFields = (params, options) => {
    return axios.get(`/api/fields`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListFieldsQueryKey = (params) => {
    return [`/api/fields`, ...(params ? [params] : [])];
};
export const getListFieldsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListFieldsQueryKey(params);
    const queryFn = ({ signal }) => listFields(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all field definitions
 */
export function useListFields(params, options) {
    const queryOptions = getListFieldsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Create a field
 */
export const addField = (fieldCreateRequest, options) => {
    return axios.post(`/api/fields`, fieldCreateRequest, options);
};
export const getAddFieldMutationOptions = (options) => {
    const mutationKey = ['addField'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addField(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a field
*/
export const useAddField = (options) => {
    const mutationOptions = getAddFieldMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a field
*/
export const getField = (uid, options) => {
    return axios.get(`/api/fields/${uid}`, options);
};
export const getGetFieldQueryKey = (uid) => {
    return [`/api/fields/${uid}`];
};
export const getGetFieldQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetFieldQueryKey(uid);
    const queryFn = ({ signal }) => getField(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a field
 */
export function useGetField(uid, options) {
    const queryOptions = getGetFieldQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Update a field
 */
export const updateField = (uid, fieldUpdateRequest, options) => {
    return axios.post(`/api/fields/${uid}`, fieldUpdateRequest, options);
};
export const getUpdateFieldMutationOptions = (options) => {
    const mutationKey = ['updateField'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateField(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a field
*/
export const useUpdateField = (options) => {
    const mutationOptions = getUpdateFieldMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
* @summary Delete a field
*/
export const deleteField = (uid, options) => {
    return axios.delete(`/api/fields/${uid}`, options);
};
export const getDeleteFieldMutationOptions = (options) => {
    const mutationKey = ['deleteField'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteField(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a field
*/
export const useDeleteField = (options) => {
    const mutationOptions = getDeleteFieldMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
* @summary Set field section
*/
export const setFieldSection = (uid, fieldSetSectionRequest, options) => {
    return axios.post(`/api/fields/${uid}/set_section`, fieldSetSectionRequest, options);
};
export const getSetFieldSectionMutationOptions = (options) => {
    const mutationKey = ['setFieldSection'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return setFieldSection(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Set field section
*/
export const useSetFieldSection = (options) => {
    const mutationOptions = getSetFieldSectionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Download the list of fields as a CSV
*/
export const downloadFields = (params, options) => {
    return axios.get(`/api/fields/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadFieldsQueryKey = (params) => {
    return [`/api/fields/download`, ...(params ? [params] : [])];
};
export const getDownloadFieldsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadFieldsQueryKey(params);
    const queryFn = ({ signal }) => downloadFields(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download the list of fields as a CSV
 */
export function useDownloadFields(params, options) {
    const queryOptions = getDownloadFieldsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Does not require authentication. This allows clients who are completing the QFO form to get a list of only the relevant field definitions for the QFO without exposing *all* of our field definitions.

_Authorization: none required_
 * @summary List all Pre-Offer field definitions
 */
export const listPreOfferFields = (options) => {
    return axios.get(`/api/fields/pre-offer`, options);
};
export const getListPreOfferFieldsQueryKey = () => {
    return [`/api/fields/pre-offer`];
};
export const getListPreOfferFieldsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPreOfferFieldsQueryKey();
    const queryFn = ({ signal }) => listPreOfferFields(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all Pre-Offer field definitions
 */
export function useListPreOfferFields(options) {
    const queryOptions = getListPreOfferFieldsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Reorder fields
 */
export const reorderFields = (fieldReorderRequest, options) => {
    return axios.post(`/api/fields/reorder`, fieldReorderRequest, options);
};
export const getReorderFieldsMutationOptions = (options) => {
    const mutationKey = ['reorderFields'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderFields(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder fields
*/
export const useReorderFields = (options) => {
    const mutationOptions = getReorderFieldsMutationOptions(options);
    return useMutation(mutationOptions);
};
