import * as React from "react";
import { Grid, TextField, MenuItem, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import ActionDialog from "@app/common/ActionDialog";
import CoreDateField from "@app/common/CoreDateField";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useListAgentStatusTypes } from "@app/orval/api/lists-agent-statuses";
import { useAddAgentStatus, useGetUser } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const DEFAULT_FORM_SUBMISSION = {
    effective_date: dayjs(new Date()).format("YYYY-MM-DD"),
    status: undefined,
};
const AgentStatusDialog = (props) => {
    var _a, _b, _c;
    const { DialogProps, onClose, userUid } = props;
    const listStatusTypesApi = useListAgentStatusTypes(REFETCH_CACHE_PARAMS);
    const statusTypes = (_b = (_a = listStatusTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [formData, setFormData] = React.useState(DEFAULT_FORM_SUBMISSION);
    const [initialData, setInitialData] = React.useState(formData);
    React.useEffect(() => {
        setFormData(DEFAULT_FORM_SUBMISSION);
        setInitialData(DEFAULT_FORM_SUBMISSION);
    }, [DialogProps.open]);
    const isValid = React.useMemo(() => {
        return (formData.status != null &&
            formData.effective_date != null &&
            JSON.stringify(formData) !== JSON.stringify(initialData));
    }, [formData, initialData]);
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const getUserApi = useGetUser(props.userUid, { query: { enabled: false } });
    const createAgentStatusApi = useAddAgentStatus();
    const save = React.useCallback(() => {
        const data = {
            effective_date: formData.effective_date,
            status: formData.status,
        };
        createAgentStatusApi
            .mutateAsync({ userUid, data })
            .then((response) => {
            queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: response.data });
            });
        })
            .catch(errorDialog)
            .then(onClose);
    }, [
        formData.effective_date,
        formData.status,
        createAgentStatusApi,
        userUid,
        errorDialog,
        onClose,
        queryClient,
        getUserApi.queryKey,
    ]);
    const setAgentStatusType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { status: e.target.value ? e.target.value : undefined })));
    }, [setFormData]);
    const setAgentStatusDate = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { effective_date: date !== null && date !== void 0 ? date : undefined })));
    }, [setFormData]);
    const isSaving = createAgentStatusApi.isPending;
    return (React.createElement(ActionDialog, { title: "Add Agent Status", DialogProps: DialogProps, onConfirm: save, onCancel: onClose, confirmButtonText: "Save", isConfirmButtonDisabled: !isValid || isSaving },
        React.createElement(Grid, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
            React.createElement(Grid, { item: true }, statusTypes ? (React.createElement(TextField, { fullWidth: true, label: "Status", variant: "standard", select: true, value: formData.status || "", onChange: setAgentStatusType },
                React.createElement(MenuItem, { value: "" }, "Select agent status"),
                statusTypes.map((type) => (React.createElement(MenuItem, { key: type.name, value: type.name }, type.name))))) : (React.createElement(Typography, null, "Loading status options..."))),
            React.createElement(Grid, { item: true },
                React.createElement(CoreDateField, { fullWidth: true, label: "Effective Date", format: "M/D/YYYY", formatType: "dayjs", variant: "standard", date: (_c = formData.effective_date) !== null && _c !== void 0 ? _c : null, onDateChange: setAgentStatusDate, hasPicker: true })))));
};
export default AgentStatusDialog;
