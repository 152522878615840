/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get status of task
 */
export const getBackgroundTaskStatus = (uid, options) => {
    return axios.get(`/api/background_tasks/get_status/${uid}`, options);
};
export const getGetBackgroundTaskStatusQueryKey = (uid) => {
    return [`/api/background_tasks/get_status/${uid}`];
};
export const getGetBackgroundTaskStatusQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBackgroundTaskStatusQueryKey(uid);
    const queryFn = ({ signal }) => getBackgroundTaskStatus(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get status of task
 */
export function useGetBackgroundTaskStatus(uid, options) {
    const queryOptions = getGetBackgroundTaskStatusQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
