/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get DASHscore settings
 */
export const loadDashscoreSettings = (options) => {
    return axios.get(`/api/dashscore/settings`, options);
};
export const getLoadDashscoreSettingsQueryKey = () => {
    return [`/api/dashscore/settings`];
};
export const getLoadDashscoreSettingsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getLoadDashscoreSettingsQueryKey();
    const queryFn = ({ signal }) => loadDashscoreSettings(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get DASHscore settings
 */
export function useLoadDashscoreSettings(options) {
    const queryOptions = getLoadDashscoreSettingsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Save DASHscore settings
 */
export const saveDashscoreSettings = (dashScoreSettingsRequest, options) => {
    return axios.post(`/api/dashscore/settings`, dashScoreSettingsRequest, options);
};
export const getSaveDashscoreSettingsMutationOptions = (options) => {
    const mutationKey = ['saveDashscoreSettings'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return saveDashscoreSettings(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Save DASHscore settings
*/
export const useSaveDashscoreSettings = (options) => {
    const mutationOptions = getSaveDashscoreSettingsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List DASHscore component values
*/
export const listDashscoreComponentValues = (options) => {
    return axios.get(`/api/dashscore/settings/score_component_values`, options);
};
export const getListDashscoreComponentValuesQueryKey = () => {
    return [`/api/dashscore/settings/score_component_values`];
};
export const getListDashscoreComponentValuesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDashscoreComponentValuesQueryKey();
    const queryFn = ({ signal }) => listDashscoreComponentValues(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List DASHscore component values
 */
export function useListDashscoreComponentValues(options) {
    const queryOptions = getListDashscoreComponentValuesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Bulk update DASHscore component values
 */
export const bulkUpdateDashscoreComponentValues = (dashScoreComponentValueBulkUpdateRequest, options) => {
    return axios.post(`/api/dashscore/settings/score_component_values`, dashScoreComponentValueBulkUpdateRequest, options);
};
export const getBulkUpdateDashscoreComponentValuesMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateDashscoreComponentValues'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateDashscoreComponentValues(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Bulk update DASHscore component values
*/
export const useBulkUpdateDashscoreComponentValues = (options) => {
    const mutationOptions = getBulkUpdateDashscoreComponentValuesMutationOptions(options);
    return useMutation(mutationOptions);
};
