import * as React from "react";
import { Delete, Email, KeyboardArrowDown, KeyboardArrowRight, LabelImportant, Lock, LockOpen, } from "@mui/icons-material";
import { Badge, Grid2, IconButton, Tooltip, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import StageSelect from "./StageSelect";
import StreetViewImage from "./StreetViewImage";
const MailScheduleIcon = (props) => {
    const { linkUrl, numErrors } = props;
    return (React.createElement(Tooltip, { title: React.createElement(Typography, null,
            "Mail Schedule",
            (numErrors !== null && numErrors !== void 0 ? numErrors : 0) > 0 ? ` (${numErrors} errors)` : "") },
        React.createElement("span", null,
            React.createElement(IconButton, { component: RouterLink, to: linkUrl },
                React.createElement(Badge, { color: "error", badgeContent: numErrors, showZero: false },
                    React.createElement(Email, null))))));
};
const SectionHeader = (props) => {
    var _a, _b, _c;
    const { address, canEdit = true, deleteVerbiage, disableStageSelect = false, isCollapsed, isLocked, mailScheduleLinkUrl, name, numMailScheduleErrors, onCollapse, onDelete, onStageChange, onToggleLock, stage, stages, toolbar, } = props;
    const session = useSession();
    return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
        React.createElement(Grid2, { container: true, style: {
                width: "calc(100% - 256px)",
                marginTop: "auto",
                marginBottom: "auto",
                overflow: "hidden",
            } },
            React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
                address && address.length > 4 && (React.createElement(Grid2, null,
                    React.createElement(StreetViewImage, { address: address, ImageProps: { style: { maxHeight: "40px", borderRadius: "4px" } } }))),
                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid2, null, typeof name === "string" ? React.createElement(Typography, { variant: "h6" }, name) : name),
                    address && (React.createElement(Grid2, { style: { transform: "translateY(-12px)" } },
                        React.createElement(Typography, { variant: "caption", style: { whiteSpace: "nowrap" } }, address)))))),
        toolbar && React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, toolbar),
        mailScheduleLinkUrl && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(MailScheduleIcon, { numErrors: numMailScheduleErrors, linkUrl: mailScheduleLinkUrl }))),
        stages && (React.createElement(Grid2, { style: { flex: 2, width: "256px", marginTop: "auto", marginBottom: "auto", minWidth: "135px" } }, canEdit ? (React.createElement(StageSelect, { stages: stages, value: stage !== null && stage !== void 0 ? stage : "", onChange: onStageChange, TextFieldProps: { disabled: disableStageSelect } })) : (React.createElement(Grid2, { container: true, wrap: "nowrap", spacing: 1 },
            React.createElement(Grid2, null,
                React.createElement(LabelImportant, { style: { color: (_a = stages.find((s) => s.uid === stage)) === null || _a === void 0 ? void 0 : _a.color } })),
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2" }, (_b = stages.find((s) => s.uid === stage)) === null || _b === void 0 ? void 0 : _b.name)))))),
        onDelete && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, deleteVerbiage !== null && deleteVerbiage !== void 0 ? deleteVerbiage : "Delete") },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: onDelete, size: "small" },
                        React.createElement(Delete, { fontSize: "small" })))))),
        onToggleLock && ((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin", "Admin"])) && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { size: "small", onClick: onToggleLock }, isLocked ? React.createElement(Lock, { fontSize: "small" }) : React.createElement(LockOpen, { fontSize: "small" })))),
        onCollapse && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { onClick: onCollapse, size: "small" }, isCollapsed ? React.createElement(KeyboardArrowRight, { fontSize: "small" }) : React.createElement(KeyboardArrowDown, { fontSize: "small" }))))));
};
export default SectionHeader;
