import * as React from "react";
import { Grid2, MenuItem, Typography } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { useListRoles } from "@app/orval/api/user-roles";
const RoleSelect = (props) => {
    var _a, _b;
    const { canEdit, onChange, roles } = props;
    const listRolesApi = useListRoles();
    const roleOptions = (_b = (_a = listRolesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const handleChoiceFieldBlur = React.useCallback((_, value) => {
        onChange(value);
    }, [onChange]);
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap", style: { marginTop: "auto", marginBottom: "auto" } },
        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, "Role")),
        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, roleOptions && canEdit ? (React.createElement(CoreChoiceField, { size: "small", fullWidth: true, variant: "outlined", multiple: true, value: roles, onChoiceFieldBlurred: handleChoiceFieldBlur, slotProps: {
                input: {
                    placeholder: "Select one or more roles",
                }
            } }, roleOptions.map((role) => (React.createElement(MenuItem, { key: role.name, value: role.name }, role.name))))) : (React.createElement(Typography, { variant: "body2" }, roles.join(", "))))));
};
export default RoleSelect;
