import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Check, Clear } from "@mui/icons-material";
import { Link, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import Session from "@app/util/Session";
const getTheme = (parentTheme) => createTheme(Object.assign(Object.assign({}, parentTheme), { components: {
        // @ts-expect-error 'MUIDataTable' does not exist in type 'Components<Omit<Theme, "components" | "palette"> & CssVarsTheme>'
        MUIDataTable: {
            styleOverrides: {
                root: {
                    overflowX: "auto",
                },
                responsiveBase: {
                    overflowY: "hidden",
                },
                liveAnnounce: {
                    display: "none",
                },
            },
        },
    } }));
const MailScheduleResults = (props) => {
    const { boxes, pipelines, processedTemplates, templates } = props;
    const rows = processedTemplates.map((processedTemplate) => {
        const box = boxes === null || boxes === void 0 ? void 0 : boxes.find((box) => box.key === processedTemplate.box_key);
        return Object.assign(Object.assign({}, processedTemplate), { pipeline_key: box === null || box === void 0 ? void 0 : box.pipelineKey, assigned_agent: box && box.assignedToSharingEntries.length > 0 ? box.assignedToSharingEntries[0].fullName : null, success: !processedTemplate.error_message });
    });
    const columns = [
        {
            name: "template_uid",
            label: "Template",
            options: {
                customBodyRender: (value) => {
                    var _a;
                    if (value) {
                        const template = templates === null || templates === void 0 ? void 0 : templates.find((template) => template.uid === value);
                        if (template && ((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]))) {
                            return React.createElement(RouterLink, { to: `/mailschedule/templates/${value}` }, template.name);
                        }
                    }
                    return value;
                },
                sortCompare: (order) => (a, b) => {
                    const aTemplate = templates === null || templates === void 0 ? void 0 : templates.find((template) => template.uid === a.data);
                    const bTemplate = templates === null || templates === void 0 ? void 0 : templates.find((template) => template.uid === b.data);
                    if (aTemplate && !bTemplate)
                        return -1 * (order === "desc" ? -1 : 1);
                    if (!aTemplate && bTemplate)
                        return 1 * (order === "desc" ? -1 : 1);
                    if (!aTemplate && !bTemplate)
                        return 0;
                    if (aTemplate.name < bTemplate.name)
                        return -1 * (order === "desc" ? -1 : 1);
                    if (aTemplate.name > bTemplate.name)
                        return 1 * (order === "desc" ? -1 : 1);
                    return 0;
                },
            },
        },
        {
            name: "assigned_agent",
            label: "Assigned Agent",
        },
        {
            name: "pipeline_key",
            label: "Pipeline",
            options: {
                customBodyRender: (value) => {
                    if (value && pipelines) {
                        const pipeline = pipelines.find((pipeline_) => pipeline_.key === value);
                        if (pipeline) {
                            return (React.createElement(Link, { href: `https://streak.com/a/pipelines/${pipeline.key}`, target: "_blank" }, pipeline.name));
                        }
                    }
                    return value;
                },
                sortCompare: (order) => (a, b) => {
                    const aPipelineKey = a.data;
                    const bPipelineKey = b.data;
                    if (pipelines) {
                        const aPipeline = pipelines.find((pipeline) => pipeline.key === aPipelineKey);
                        const bPipeline = pipelines.find((pipeline) => pipeline.key === bPipelineKey);
                        if (aPipeline && !bPipeline)
                            return -1 * (order === "desc" ? -1 : 1);
                        if (!aPipeline && bPipeline)
                            return 1 * (order === "desc" ? -1 : 1);
                        if (!aPipeline && !bPipeline)
                            return 0;
                        if (aPipeline.name < bPipeline.name)
                            return -1 * (order === "desc" ? -1 : 1);
                        if (aPipeline.name > bPipeline.name)
                            return 1 * (order === "desc" ? -1 : 1);
                        return 0;
                    }
                    if ((aPipelineKey !== null && aPipelineKey !== void 0 ? aPipelineKey : "") < (bPipelineKey !== null && bPipelineKey !== void 0 ? bPipelineKey : ""))
                        return -1 * (order === "desc" ? -1 : 1);
                    if ((aPipelineKey !== null && aPipelineKey !== void 0 ? aPipelineKey : "") > (bPipelineKey !== null && bPipelineKey !== void 0 ? bPipelineKey : ""))
                        return 1 * (order === "desc" ? -1 : 1);
                    return 0;
                },
            },
        },
        {
            name: "box_key",
            label: "Box",
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        const box = boxes === null || boxes === void 0 ? void 0 : boxes.find((box) => box.key === value);
                        if (box) {
                            return (React.createElement(Link, { href: `https://streak.com/a/boxes/${box.key}`, target: "_blank" }, box.name));
                        }
                    }
                    return value;
                },
                sortCompare: (order) => (a, b) => {
                    const aBox = boxes === null || boxes === void 0 ? void 0 : boxes.find((box) => box.key === a.data);
                    const bBox = boxes === null || boxes === void 0 ? void 0 : boxes.find((box) => box.key === b.data);
                    if (aBox && !bBox)
                        return -1 * (order === "desc" ? -1 : 1);
                    if (!aBox && bBox)
                        return 1 * (order === "desc" ? -1 : 1);
                    if (!aBox && !bBox)
                        return 0;
                    if (aBox.name < bBox.name)
                        return -1 * (order === "desc" ? -1 : 1);
                    if (aBox.name > bBox.name)
                        return 1 * (order === "desc" ? -1 : 1);
                    return 0;
                },
            },
        },
        {
            name: "success",
            label: "Success",
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        return (React.createElement(Tooltip, { title: "Processed" },
                            React.createElement(Check, { color: "primary" })));
                    }
                    return (React.createElement(Tooltip, { title: "Error" },
                        React.createElement(Clear, { color: "secondary" })));
                },
            },
        },
        {
            name: "error_message",
            label: "Error",
        },
    ];
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        search: false,
        searchOpen: false,
        pagination: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        viewColumns: false,
        expandableRows: false,
    };
    return (React.createElement(ThemeProvider, { theme: getTheme },
        React.createElement(CoreDataTable, { title: "Results", columns: columns, options: options, data: rows, cellPadding: 0 })));
};
export default MailScheduleResults;
