import * as React from "react";
import { Group, Groups, Person } from "@mui/icons-material";
import { Grid2, IconButton, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import CoreDataGrid from "@app/common/grid/DataGrid";
import DashScoreLeaderboardFilter from "./DashScoreLeaderboardFilter";
import DashScoreLevels from "./DashScoreLevels";
import { dashscoreLeaderboardThemeProvider, decimalColumnRenderer, getLeaderboardCellClassName, percentColumnRenderer, } from "./utils";
const DashScoreLeaderboard = (props) => {
    const { agents, currentLevel, data, isSmallWindow, offices, onLevelChange, settings, teams, users } = props;
    const [officesFilter, setOfficesFilter] = React.useState(offices);
    const [teamsFilter, setTeamsFilter] = React.useState(teams);
    const [agentsFilter, setAgentsFilter] = React.useState(agents);
    const [officesFilterAnchorEl, setOfficesFilterAnchorEl] = React.useState(null);
    const [teamsFilterAnchorEl, setTeamsFilterAnchorEl] = React.useState(null);
    const [agentsFilterAnchorEl, setAgentsFilterAnchorEl] = React.useState(null);
    const userInfoByName = React.useMemo(() => {
        return users.reduce((acc, user) => {
            acc[user.name.toLowerCase().trim()] = user;
            return acc;
        }, {});
    }, [users]);
    const teamInfoByName = React.useMemo(() => {
        const officeAggregates = [...data.subject_aggregates].filter((item) => {
            return item.level === "Office";
        });
        return teams.reduce((acc, team) => {
            const officeData = officeAggregates.find((item) => {
                return (item.member_names ? item.member_names.toLowerCase().trim().split(",") : []).includes(team.toLowerCase().trim());
            });
            acc[team.toLowerCase().trim()] = {
                office: officeData ? officeData.name : null,
            };
            return acc;
        }, {});
    }, [data.subject_aggregates, teams]);
    const agentMetricsForLevel = React.useMemo(() => {
        return [...data.agent_metrics].filter((item) => {
            return item.level.toLowerCase().trim() === currentLevel.toLowerCase().trim();
        });
    }, [currentLevel, data.agent_metrics]);
    const connectionMetricsForLevel = React.useMemo(() => {
        return [...data.connection_metrics].filter((item) => {
            return item.level.toLowerCase().trim() === currentLevel.toLowerCase().trim();
        });
    }, [currentLevel, data.connection_metrics]);
    const scoresForLevel = React.useMemo(() => {
        return [...data.subject_aggregates].filter((item) => {
            if (currentLevel === "Office") {
                return offices.includes(item.name);
            }
            if (currentLevel === "Team") {
                return teams.includes(item.name);
            }
            return agents.includes(item.name);
        });
    }, [agents, currentLevel, data.subject_aggregates, offices, teams]);
    const showAllOffices = React.useMemo(() => {
        return officesFilter === offices;
    }, [officesFilter, offices]);
    const showAllTeams = React.useMemo(() => {
        return teamsFilter === teams;
    }, [teamsFilter, teams]);
    const columns = React.useMemo(() => {
        const columns_ = [
            {
                field: "name",
                headerName: "Name",
                width: 175,
            },
            {
                field: "score",
                headerName: "Score",
                valueFormatter: decimalColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "percentToTransactionTarget",
                headerName: "% to Transaction Target",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "cvr",
                headerName: "CVR",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "pickUpPercent",
                headerName: "Pick Up %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "almPercent",
                headerName: "ALM %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "apptPercent",
                headerName: "Appt %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "metRatePercent",
                headerName: "Met Rate %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "showingPercent",
                headerName: "Showing %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "offerPercent",
                headerName: "Offer %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "csat",
                headerName: "CSAT",
                valueFormatter: decimalColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "percentToAttachTarget",
                headerName: "% to Attach Target",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "optInETRPercent",
                headerName: "Opt-In ETR %",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "pca",
                headerName: "PCA",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "sla",
                headerName: "SLA",
                valueFormatter: decimalColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "pig_attach_rate",
                headerName: "PIG Attach Rate",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
            {
                field: "pig_etr",
                headerName: "PIG ETR",
                valueFormatter: percentColumnRenderer,
                cellClassName: getLeaderboardCellClassName,
            },
        ];
        return columns_;
    }, []);
    const rows = React.useMemo(() => {
        let namesForLevel = null;
        // get a list of names to show in the table
        const officesFilterLowercase = officesFilter.map((item) => {
            return item.toLowerCase().trim();
        });
        const teamsFilterLowercase = teamsFilter.map((item) => {
            return item.toLowerCase().trim();
        });
        const agentsFilterLowercase = agentsFilter.map((item) => {
            return item.toLowerCase().trim();
        });
        if (currentLevel === "Office") {
            namesForLevel = offices;
        }
        else if (currentLevel === "Team") {
            namesForLevel = teams;
        }
        else if (currentLevel === "Agent") {
            namesForLevel = agents;
        }
        if (namesForLevel == null) {
            // sanity check
            console.warn("Invalid level for DASHscore viewing: ", currentLevel);
            return [];
        }
        return namesForLevel
            .filter((name) => {
            if (currentLevel === "Office") {
                return officesFilterLowercase.includes(name.toLowerCase().trim());
            }
            if (currentLevel === "Team") {
                const teamInfo = teamInfoByName[name.toLowerCase().trim()];
                const teamOffice = teamInfo.office ? teamInfo.office.toLowerCase().trim() : null;
                return (teamsFilterLowercase.includes(name.toLowerCase().trim()) &&
                    (teamOffice == null || officesFilterLowercase.includes(teamOffice)));
            }
            if (currentLevel === "Agent") {
                const agentInfo = userInfoByName[name.toLowerCase().trim()];
                const agentTeam = agentInfo ? agentInfo.team : null;
                const agentOffice = agentInfo ? agentInfo.office : null;
                const isAgentTeamInFilter = agentTeam
                    ? teamsFilterLowercase.includes(agentTeam.name.toLowerCase().trim())
                    : false;
                const isAgentInOfficeFilter = agentOffice
                    ? officesFilterLowercase.includes(agentOffice.name.toLowerCase().trim())
                    : false;
                return (agentsFilterLowercase.includes(name.toLowerCase().trim()) &&
                    (showAllOffices || isAgentInOfficeFilter) &&
                    (showAllTeams || isAgentTeamInFilter));
            }
            return true;
        })
            .map((name) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
            const scoreData = scoresForLevel.find((item) => item.name === name);
            const score = (_a = scoreData === null || scoreData === void 0 ? void 0 : scoreData.dashscore) !== null && _a !== void 0 ? _a : null;
            const agentMetrics = agentMetricsForLevel.filter((item) => {
                return item.name === name;
            });
            const agentMetrics30Day = agentMetrics.find((item) => item.range === "30 Day");
            const agentMetrics60Day = agentMetrics.find((item) => item.range === "60 Day");
            const agentMetrics90Day = agentMetrics.find((item) => item.range === "90 Day");
            const agentMetrics180Day = agentMetrics.find((item) => item.range === "180 Day");
            const connectionMetrics = connectionMetricsForLevel.filter((item) => {
                return item.connected_name === name;
            });
            const connectionMetrics90Day = connectionMetrics.find((item) => item.range === "90 Day");
            return {
                id: name,
                name,
                score,
                // flex metrics
                percentToTransactionTarget: (_b = scoreData === null || scoreData === void 0 ? void 0 : scoreData.percent_to_transaction_target) !== null && _b !== void 0 ? _b : null,
                cvr: (_c = agentMetrics180Day === null || agentMetrics180Day === void 0 ? void 0 : agentMetrics180Day.cvr) !== null && _c !== void 0 ? _c : null,
                pickUpPercent: (_d = agentMetrics30Day === null || agentMetrics30Day === void 0 ? void 0 : agentMetrics30Day.pickup_rate) !== null && _d !== void 0 ? _d : null,
                almPercent: (_e = agentMetrics30Day === null || agentMetrics30Day === void 0 ? void 0 : agentMetrics30Day.alm_appointment_rate) !== null && _e !== void 0 ? _e : null,
                apptPercent: (_f = agentMetrics30Day === null || agentMetrics30Day === void 0 ? void 0 : agentMetrics30Day.appointment_rate) !== null && _f !== void 0 ? _f : null,
                metRatePercent: (_g = agentMetrics30Day === null || agentMetrics30Day === void 0 ? void 0 : agentMetrics30Day.met_rate) !== null && _g !== void 0 ? _g : null,
                showingPercent: (_h = agentMetrics60Day === null || agentMetrics60Day === void 0 ? void 0 : agentMetrics60Day.show_rate) !== null && _h !== void 0 ? _h : null,
                offerPercent: (_j = agentMetrics90Day === null || agentMetrics90Day === void 0 ? void 0 : agentMetrics90Day.offer_rate) !== null && _j !== void 0 ? _j : null,
                csat: (_k = agentMetrics180Day === null || agentMetrics180Day === void 0 ? void 0 : agentMetrics180Day.avg_current_csat) !== null && _k !== void 0 ? _k : null,
                // zhl metrics
                percentToAttachTarget: (_l = connectionMetrics90Day === null || connectionMetrics90Day === void 0 ? void 0 : connectionMetrics90Day.zhl_percent_to_target) !== null && _l !== void 0 ? _l : null,
                optInETRPercent: (_m = connectionMetrics90Day === null || connectionMetrics90Day === void 0 ? void 0 : connectionMetrics90Day.opt_in_engaged_transfer_rate) !== null && _m !== void 0 ? _m : null,
                // ancillary metrics
                pca: (_o = agentMetrics180Day === null || agentMetrics180Day === void 0 ? void 0 : agentMetrics180Day.pca_percent_attach_to_target) !== null && _o !== void 0 ? _o : null,
                sla: (_p = agentMetrics30Day === null || agentMetrics30Day === void 0 ? void 0 : agentMetrics30Day.num_days_with_sla_violations) !== null && _p !== void 0 ? _p : null,
                pig_attach_rate: (_q = agentMetrics180Day === null || agentMetrics180Day === void 0 ? void 0 : agentMetrics180Day.pig_percent_attach_to_target) !== null && _q !== void 0 ? _q : null,
                pig_etr: (_r = agentMetrics60Day === null || agentMetrics60Day === void 0 ? void 0 : agentMetrics60Day.pig_etr) !== null && _r !== void 0 ? _r : null,
                // css
                score__RedValue: settings.dashscore_red_value,
                score__GreenValue: settings.dashscore_green_value,
                percentToTransactionTarget__RedValue: settings.flex_percent_to_trxn_target_score_red_value,
                percentToTransactionTarget__GreenValue: settings.flex_percent_to_trxn_target_score_green_value,
                cvr__RedValue: settings.cvr_score_red_value,
                cvr__GreenValue: settings.cvr_score_green_value,
                pickUpPercent__RedValue: settings.pick_up_score_red_value,
                pickUpPercent__GreenValue: settings.pick_up_score_green_value,
                almPercent__RedValue: settings.alm_appointment_rate_red_value,
                almPercent__GreenValue: settings.alm_appointment_rate_green_value,
                apptPercent__RedValue: settings.appointment_score_red_value,
                apptPercent__GreenValue: settings.appointment_score_green_value,
                metRatePercent__RedValue: settings.met_rate_score_red_value,
                metRatePercent__GreenValue: settings.met_rate_score_green_value,
                showingPercent__RedValue: settings.showing_homes_score_red_value,
                showingPercent__GreenValue: settings.showing_homes_score_green_value,
                offerPercent__RedValue: settings.offer_percent_score_red_value,
                offerPercent__GreenValue: settings.offer_percent_score_green_value,
                csat__RedValue: settings.csat_score_red_value,
                csat__GreenValue: settings.csat_score_green_value,
                percentToAttachTarget__RedValue: settings.zhl_percent_to_attach_target_red_value,
                percentToAttachTarget__GreenValue: settings.zhl_percent_to_attach_target_green_value,
                optInETRPercent__RedValue: settings.zhl_engaged_transfer_score_red_value,
                optInETRPercent__GreenValue: settings.zhl_engaged_transfer_score_green_value,
                pca__RedValue: settings.pca_percent_to_attach_target_red_value,
                pca__GreenValue: settings.pca_percent_to_attach_target_green_value,
                sla__RedValue: settings.num_sla_violations_red_value,
                sla__GreenValue: settings.num_sla_violations_green_value,
                pig_attach_rate__RedValue: settings.pig_percent_to_attach_target_red_value,
                pig_attach_rate__GreenValue: settings.pig_percent_to_attach_target_green_value,
            };
        });
    }, [
        officesFilter,
        teamsFilter,
        agentsFilter,
        currentLevel,
        offices,
        teams,
        agents,
        teamInfoByName,
        userInfoByName,
        showAllOffices,
        showAllTeams,
        scoresForLevel,
        agentMetricsForLevel,
        connectionMetricsForLevel,
        settings,
    ]);
    const openOfficeFilterMenu = React.useCallback((event) => {
        event.stopPropagation();
        setOfficesFilterAnchorEl(event.currentTarget);
    }, []);
    const closeOfficeFilterMenu = React.useCallback(() => {
        setOfficesFilterAnchorEl(null);
    }, []);
    const openTeamsFilterMenu = React.useCallback((event) => {
        event.stopPropagation();
        setTeamsFilterAnchorEl(event.currentTarget);
    }, []);
    const closeTeamsFilterMenu = React.useCallback(() => {
        setTeamsFilterAnchorEl(null);
    }, []);
    const openAgentsFilterMenu = React.useCallback((event) => {
        event.stopPropagation();
        setAgentsFilterAnchorEl(event.currentTarget);
    }, []);
    const closeAgentsFilterMenu = React.useCallback(() => {
        setAgentsFilterAnchorEl(null);
    }, []);
    const onOfficeFilterChange = React.useCallback((office) => {
        setOfficesFilter((prev) => {
            if (prev.includes(office)) {
                return prev.filter((item) => item !== office);
            }
            return [...prev, office];
        });
    }, []);
    const onTeamFilterChange = React.useCallback((team) => {
        setTeamsFilter((prev) => {
            if (prev.includes(team)) {
                return prev.filter((item) => item !== team);
            }
            return [...prev, team];
        });
    }, []);
    const onAgentFilterChange = React.useCallback((agent) => {
        setAgentsFilter((prev) => {
            if (prev.includes(agent)) {
                return prev.filter((item) => item !== agent);
            }
            return [...prev, agent];
        });
    }, []);
    const customToolbar = React.useCallback((toolbarProps) => {
        return (React.createElement(GridToolbarContainer, Object.assign({}, toolbarProps),
            React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                React.createElement(Grid2, { size: { xs: isSmallWindow ? 12 : undefined }, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "h6", fontWeight: "bold", align: isSmallWindow ? "center" : undefined }, "DASHscore Leaderboard")),
                React.createElement(Grid2, { size: { xs: isSmallWindow ? 12 : undefined }, style: { marginLeft: isSmallWindow ? undefined : "auto" } },
                    React.createElement(DashScoreLevels, { currentLevel: currentLevel, onLevelChange: onLevelChange, style: isSmallWindow ? { width: "100%" } : undefined })),
                React.createElement(Grid2, { style: {
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: isSmallWindow ? "auto" : undefined,
                        marginRight: isSmallWindow ? "auto" : undefined,
                    } },
                    React.createElement(Tooltip, { title: "Filter by Office" },
                        React.createElement(IconButton, { onClick: openOfficeFilterMenu },
                            React.createElement(Groups, null))),
                    React.createElement(Tooltip, { title: "Filter by Team" },
                        React.createElement(IconButton, { onClick: openTeamsFilterMenu },
                            React.createElement(Group, null))),
                    React.createElement(Tooltip, { title: "Filter by Agent" },
                        React.createElement(IconButton, { onClick: openAgentsFilterMenu },
                            React.createElement(Person, null)))))));
    }, [currentLevel, isSmallWindow, onLevelChange, openAgentsFilterMenu, openOfficeFilterMenu, openTeamsFilterMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, spacing: 2, style: { padding: "8px" } },
            React.createElement(Grid2, { size: 12 },
                React.createElement(ThemeProvider, { theme: dashscoreLeaderboardThemeProvider },
                    React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, density: "compact", rowCount: rows.length, 
                        // getCellClassName={getLeaderboardCellClassName}
                        // getRowClassName={getLeaderboardRowClassName}
                        // onSortModelChange={}
                        pagination: true, paginationMode: "client", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], localeText: {
                            toolbarColumns: "",
                        }, slots: {
                            toolbar: customToolbar,
                        } })))),
        React.createElement(DashScoreLeaderboardFilter, { anchorEl: officesFilterAnchorEl, items: offices, selected: officesFilter, title: "Filter by Office", onChange: onOfficeFilterChange, onClose: closeOfficeFilterMenu }),
        React.createElement(DashScoreLeaderboardFilter, { anchorEl: teamsFilterAnchorEl, items: teams, selected: teamsFilter, title: "Filter by Team", onChange: onTeamFilterChange, onClose: closeTeamsFilterMenu }),
        React.createElement(DashScoreLeaderboardFilter, { anchorEl: agentsFilterAnchorEl, items: agents, selected: agentsFilter, title: "Filter by Agent", onChange: onAgentFilterChange, onClose: closeAgentsFilterMenu })));
};
export default DashScoreLeaderboard;
