import * as React from "react";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
const LoadingContracts = (props) => {
    const { numContracts } = props;
    return (React.createElement(Box, { p: 2, style: {
            maxWidth: "200px",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            border: "1px solid #aaa",
            borderRadius: "0.25rem",
        } },
        React.createElement(Grid, { container: true, justifyContent: "center", direction: "column", wrap: "nowrap" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, null,
                    "Loading ",
                    numContracts != null ? `${numContracts} ` : "",
                    "contract",
                    numContracts != 1 ? "s" : "",
                    "...")),
            React.createElement(Grid, { item: true },
                React.createElement(LinearProgress, { variant: "indeterminate" })))));
};
export default LoadingContracts;
