import * as React from "react";
import { Grid, Typography, Paper } from "@mui/material";
const PSPTabSection = (props) => {
    const { info, name } = props;
    return (React.createElement(Paper, { elevation: 1, style: { marginBottom: "16px", width: "100%" } },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12, style: {
                    marginLeft: "4px !important",
                    marginRight: "4px !important",
                    backgroundColor: "rgb(250, 250, 255)",
                    borderBottom: "1px solid #ebedf2",
                    padding: "8px",
                } },
                React.createElement(Typography, { variant: "h6" }, name),
                info),
            props.children)));
};
export default PSPTabSection;
