import * as React from "react";
import { Box, Grid2, TextField } from "@mui/material";
import ActionDialog from "@app/common/ActionDialog";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useCreateTwilioDoNotCallItem, useUpdateTwilioDoNotCallItem } from "@app/orval/api/twilio-do-not-call";
import { isValidPhoneNumber } from "@app/util/Utils";
const defaultData = {
    number: "",
    name: "",
};
const DoNotCallDialog = (props) => {
    var _a;
    const { DialogProps, initialData, onCancel, onSaveComplete, uid } = props;
    const open = (_a = DialogProps === null || DialogProps === void 0 ? void 0 : DialogProps.open) !== null && _a !== void 0 ? _a : true;
    const [formData, setFormData] = React.useState(initialData !== null && initialData !== void 0 ? initialData : defaultData);
    React.useEffect(() => {
        setFormData(initialData !== null && initialData !== void 0 ? initialData : defaultData);
    }, [initialData]);
    const createItemApi = useCreateTwilioDoNotCallItem();
    const updateItemApi = useUpdateTwilioDoNotCallItem();
    const isSaving = createItemApi.isPending || updateItemApi.isPending;
    const isValid = React.useMemo(() => {
        return !!formData.name.trim() && !!formData.number.trim() && isValidPhoneNumber(formData.number);
    }, [formData]);
    const save = React.useCallback(() => {
        if (!isValid) {
            return;
        }
        if (uid) {
            updateItemApi
                .mutateAsync({ uid, data: formData })
                .then((response) => {
                onSaveComplete(response.data);
            })
                .catch(enqueueErrorSnackbar);
        }
        else {
            createItemApi
                .mutateAsync({ data: formData })
                .then((response) => {
                onSaveComplete(response.data);
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [createItemApi, formData, isValid, onSaveComplete, uid, updateItemApi]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, []);
    const setNumber = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { number: e.target.value })));
    }, []);
    return (React.createElement(ActionDialog, { title: `${uid != null ? "Edit" : "Add"} Number`, onCancel: onCancel, onConfirm: save, confirmButtonText: "Save", confirmButtonProps: {
            disabled: !isValid || isSaving,
        }, DialogProps: Object.assign(Object.assign({}, DialogProps), { open }) },
        React.createElement(Box, { p: 2, component: Grid2, container: true, spacing: 2, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Name", value: formData.name, onChange: setName })),
            React.createElement(Grid2, null,
                React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Number", value: formData.number, onChange: setNumber })))));
};
export default DoNotCallDialog;
