import { format } from "@redtea/format-axios-error";
import { isAxiosError } from "axios";
import { useConfirm } from "material-ui-confirm";
import { CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const useErrorDialog = () => {
    const confirm = useConfirm();
    const showErrorDialog = (error, options) => {
        var _a, _b;
        const defaultOptions = CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS;
        const title = isAxiosError(error)
            ? [(_a = error.response) === null || _a === void 0 ? void 0 : _a.status, (_b = error.response) === null || _b === void 0 ? void 0 : _b.statusText].filter((o) => o).join(" ")
            : "An error occurred";
        const description = isAxiosError(error) ? JSON.stringify(format(error), null, 2) : String(error);
        confirm(Object.assign(Object.assign(Object.assign({}, defaultOptions), { title,
            description }), options));
    };
    return showErrorDialog;
};
export default useErrorDialog;
