/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all view purposes
 */
export const listViewPurposes = (options) => {
    return axios.get(`/api/view_purposes`, options);
};
export const getListViewPurposesQueryKey = () => {
    return [`/api/view_purposes`];
};
export const getListViewPurposesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListViewPurposesQueryKey();
    const queryFn = ({ signal }) => listViewPurposes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all view purposes
 */
export function useListViewPurposes(options) {
    const queryOptions = getListViewPurposesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create a view purpose
 */
export const addViewPurpose = (viewPurposeRequest, options) => {
    return axios.post(`/api/view_purposes`, viewPurposeRequest, options);
};
export const getAddViewPurposeMutationOptions = (options) => {
    const mutationKey = ['addViewPurpose'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addViewPurpose(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a view purpose
*/
export const useAddViewPurpose = (options) => {
    const mutationOptions = getAddViewPurposeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Get a view purpose
*/
export const getViewPurpose = (uid, options) => {
    return axios.get(`/api/view_purposes/${uid}`, options);
};
export const getGetViewPurposeQueryKey = (uid) => {
    return [`/api/view_purposes/${uid}`];
};
export const getGetViewPurposeQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetViewPurposeQueryKey(uid);
    const queryFn = ({ signal }) => getViewPurpose(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a view purpose
 */
export function useGetViewPurpose(uid, options) {
    const queryOptions = getGetViewPurposeQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update a view purpose
 */
export const updateViewPurpose = (uid, viewPurposeRequest, options) => {
    return axios.post(`/api/view_purposes/${uid}`, viewPurposeRequest, options);
};
export const getUpdateViewPurposeMutationOptions = (options) => {
    const mutationKey = ['updateViewPurpose'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateViewPurpose(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a view purpose
*/
export const useUpdateViewPurpose = (options) => {
    const mutationOptions = getUpdateViewPurposeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a view purpose
*/
export const deleteViewPurpose = (uid, options) => {
    return axios.delete(`/api/view_purposes/${uid}`, options);
};
export const getDeleteViewPurposeMutationOptions = (options) => {
    const mutationKey = ['deleteViewPurpose'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteViewPurpose(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a view purpose
*/
export const useDeleteViewPurpose = (options) => {
    const mutationOptions = getDeleteViewPurposeMutationOptions(options);
    return useMutation(mutationOptions);
};
