/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List submissions
 */
export const listQflSubmissionsNewcrm = (params, options) => {
    return axios.get(`/api/forms/questions_for_the_listing`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListQflSubmissionsNewcrmQueryKey = (params) => {
    return [`/api/forms/questions_for_the_listing`, ...(params ? [params] : [])];
};
export const getListQflSubmissionsNewcrmQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQflSubmissionsNewcrmQueryKey(params);
    const queryFn = ({ signal }) => listQflSubmissionsNewcrm(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List submissions
 */
export function useListQflSubmissionsNewcrm(params, options) {
    const queryOptions = getListQflSubmissionsNewcrmQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get a submission
 */
export const getQflSubmissionNewcrm = (uid, options) => {
    return axios.get(`/api/forms/questions_for_the_listing/${uid}`, options);
};
export const getGetQflSubmissionNewcrmQueryKey = (uid) => {
    return [`/api/forms/questions_for_the_listing/${uid}`];
};
export const getGetQflSubmissionNewcrmQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetQflSubmissionNewcrmQueryKey(uid);
    const queryFn = ({ signal }) => getQflSubmissionNewcrm(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a submission
 */
export function useGetQflSubmissionNewcrm(uid, options) {
    const queryOptions = getGetQflSubmissionNewcrmQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List marital status options for the QFL

_Authorization: none required_
 * @summary List marital statuses
 */
export const listQuestionsForTheListingMaritalStatusOptions = (options) => {
    return axios.get(`/api/forms/questions_for_the_listing/marital_status_options`, options);
};
export const getListQuestionsForTheListingMaritalStatusOptionsQueryKey = () => {
    return [`/api/forms/questions_for_the_listing/marital_status_options`];
};
export const getListQuestionsForTheListingMaritalStatusOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheListingMaritalStatusOptionsQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheListingMaritalStatusOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List marital statuses
 */
export function useListQuestionsForTheListingMaritalStatusOptions(options) {
    const queryOptions = getListQuestionsForTheListingMaritalStatusOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List road maintenance options for the QFL

_Authorization: none required_
 * @summary List road maintenance options
 */
export const listQuestionsForTheListingRoadMaintenanceOptions = (options) => {
    return axios.get(`/api/forms/questions_for_the_listing/road_maintenance_options`, options);
};
export const getListQuestionsForTheListingRoadMaintenanceOptionsQueryKey = () => {
    return [`/api/forms/questions_for_the_listing/road_maintenance_options`];
};
export const getListQuestionsForTheListingRoadMaintenanceOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheListingRoadMaintenanceOptionsQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheListingRoadMaintenanceOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List road maintenance options
 */
export function useListQuestionsForTheListingRoadMaintenanceOptions(options) {
    const queryOptions = getListQuestionsForTheListingRoadMaintenanceOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List sewer system options for the QFL

_Authorization: none required_
 * @summary List sewer system options
 */
export const listQuestionsForTheListingSewerSystemOptions = (options) => {
    return axios.get(`/api/forms/questions_for_the_listing/sewer_system_options`, options);
};
export const getListQuestionsForTheListingSewerSystemOptionsQueryKey = () => {
    return [`/api/forms/questions_for_the_listing/sewer_system_options`];
};
export const getListQuestionsForTheListingSewerSystemOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheListingSewerSystemOptionsQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheListingSewerSystemOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List sewer system options
 */
export function useListQuestionsForTheListingSewerSystemOptions(options) {
    const queryOptions = getListQuestionsForTheListingSewerSystemOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List water system options for the QFL

_Authorization: none required_
 * @summary List water system options
 */
export const listQuestionsForTheListingWaterSystemOptions = (options) => {
    return axios.get(`/api/forms/questions_for_the_listing/water_system_options`, options);
};
export const getListQuestionsForTheListingWaterSystemOptionsQueryKey = () => {
    return [`/api/forms/questions_for_the_listing/water_system_options`];
};
export const getListQuestionsForTheListingWaterSystemOptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheListingWaterSystemOptionsQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheListingWaterSystemOptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List water system options
 */
export function useListQuestionsForTheListingWaterSystemOptions(options) {
    const queryOptions = getListQuestionsForTheListingWaterSystemOptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
