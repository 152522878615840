import * as React from "react";
import { Add, Delete } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, Grid2, MenuItem, Typography, IconButton, Tooltip, } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDialog from "@app/common/CoreDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { shareSop, useListPermissionTypes } from "@app/orval/api/sops";
import { useListRoles } from "@app/orval/api/user-roles";
/**
 * Widget that allows users to share an SOP.
 */
const SOPShareDialog = (props) => {
    var _a, _b, _c, _d;
    const { dialogProps, onCancel, onSaveComplete, sop } = props;
    const [isSaving, setIsSaving] = useStateWithCallback(false);
    const [settings, setSettings] = React.useState([...sop.sharing]);
    const errorDialog = useErrorDialog();
    const listUserRolesApi = useListRoles();
    const roles = (_b = (_a = listUserRolesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listPermissionTypesApi = useListPermissionTypes();
    const permissionTypes = (_d = (_c = listPermissionTypesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const isValid = React.useMemo(() => {
        const validSettings = settings.filter((setting) => {
            return setting.role && setting.permission;
        });
        return settings.length === validSettings.length;
    }, [settings]);
    const onSave = React.useCallback(() => {
        setIsSaving(true, () => {
            const loadingSnackbar = enqueueSnackbar("Updating sharing settings...", {
                variant: "info",
                persist: true,
            });
            const { uid } = sop;
            if (uid) {
                // update sharing settings
                shareSop(uid, {
                    settings,
                })
                    .then((result) => {
                    onSaveComplete(result.data);
                    enqueueSnackbar("Sharing settings updated.", { variant: "success" });
                })
                    .catch((error) => {
                    errorDialog(error);
                })
                    .finally(() => {
                    setIsSaving(false);
                    closeSnackbar(loadingSnackbar);
                });
            }
            else {
                // update state for new SOP. settings will only be saved if SOP is saved
                onSaveComplete(Object.assign(Object.assign({}, sop), { sharing: settings }));
                closeSnackbar(loadingSnackbar);
            }
        });
    }, [errorDialog, onSaveComplete, setIsSaving, settings, sop]);
    const addSetting = React.useCallback(() => {
        setSettings((prev) => {
            return [...prev, { role: null, permission: null }];
        });
    }, []);
    const removeSetting = React.useCallback((index) => () => {
        setSettings((prev) => {
            return prev.filter((_, indx) => {
                return indx !== index;
            });
        });
    }, []);
    const onRoleUpdate = React.useCallback((index) => (role) => {
        setSettings((prev) => {
            return prev.map((setting, indx) => {
                if (indx === index) {
                    return Object.assign(Object.assign({}, setting), { role: role });
                }
                return setting;
            });
        });
    }, []);
    const onPermissionUpdate = React.useCallback((index) => (permission) => {
        setSettings((prev) => {
            return prev.map((setting, indx) => {
                if (indx === index) {
                    return Object.assign(Object.assign({}, setting), { permission: permission });
                }
                return setting;
            });
        });
    }, []);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, dialogProps),
        React.createElement(DialogTitle, null,
            React.createElement(Grid2, { container: true, spacing: 4 },
                React.createElement(Grid2, { style: { flex: 1 } },
                    React.createElement(Typography, { variant: "h6" }, "Sharing")),
                React.createElement(Grid2, null,
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add a New Setting") },
                        React.createElement(IconButton, { size: "small", onClick: addSetting, style: { margin: "auto", display: "flex" } },
                            React.createElement(Add, null)))))),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 4, style: { padding: "8px" } }, settings.map((setting, index) => {
                var _a, _b;
                return (
                // eslint-disable-next-line react/no-array-index-key
                React.createElement(Grid2, { key: `sop_sharing_setting_${index}`, container: true, size: 12, spacing: 2 },
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Role", required: true, fullWidth: true, value: (_a = setting.role) !== null && _a !== void 0 ? _a : "", onChoiceChange: onRoleUpdate(index) }, (roles !== null && roles !== void 0 ? roles : []).map((role) => (React.createElement(MenuItem, { key: role.name, value: role.name }, role.name))))),
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Permission", required: true, fullWidth: true, value: (_b = setting.permission) !== null && _b !== void 0 ? _b : "", onChoiceChange: onPermissionUpdate(index) }, (permissionTypes !== null && permissionTypes !== void 0 ? permissionTypes : []).map((permission) => (React.createElement(MenuItem, { key: permission.name, value: permission.name }, permission.name))))),
                    React.createElement(Grid2, { style: { marginTop: "auto" } },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove Setting") },
                            React.createElement(IconButton, { size: "small", onClick: removeSetting(index), style: { margin: "auto", display: "flex" } },
                                React.createElement(Delete, null))))));
            }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "inherit", onClick: onCancel }, "Cancel"),
            React.createElement(Button, { onClick: onSave, color: "primary", disabled: !isValid || !!isSaving }, "Save"))));
};
export default SOPShareDialog;
