import * as React from "react";
import { createContext, useState } from "react";
export const SpreadsheetContext = createContext(null);
export const SpreadsheetProvider = (props) => {
    const [filter, setFilter] = useState(null);
    return (React.createElement(SpreadsheetContext.Provider, { value: {
            filter,
            setFilter,
        } }, props.children));
};
