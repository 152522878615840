var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Session from "@app/util/Session";
/**
 * Determines if the user has permission to edit sops.
 * If sharing settings are provided, determines permission for the specific sop.
 * Superadmins and Admins can edit all SOPs
 */
export const canEditSops = (sharing) => {
    var _a;
    if ((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) {
        return true;
    }
    if (!sharing) {
        return false;
    }
    const canEdit = sharing.some((setting) => {
        var _a, _b;
        return (((_a = setting.permission) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) === "edit" &&
            setting.role &&
            ((_b = Session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(setting.role)));
    });
    return canEdit;
};
export const getParents = (folder, allFolders) => {
    for (const f of allFolders) {
        if (folder.parent_uid === f.uid) {
            return [f, ...getParents(f, allFolders)];
        }
    }
    return [];
};
/**
 * formats SOP content for viewing.
 * NOTE: there should be a better way to do this, but I could not get the remarkdirectives to work
 * */
export const formatVideos = (content) => {
    let formattedContent = content;
    // find embedded videos and replace with iframes
    let match = formattedContent.match("::embedvideo{(.*?)}");
    while (match) {
        formattedContent = formattedContent.replaceAll(match[0], `<p><iframe ${match[1]} allowFullScreen>Video</iframe></p>`);
        match = formattedContent.match("::embedvideo{(.*?)}");
    }
    return formattedContent;
};
/**
 * formats SOP content for viewing.
 * solves some issues of SOPs crashing or images not rendering
 * NOTE: there should be a better way to do this, but I could not get the remarkdirectives to work
 * */
export const formatImages = (content) => {
    let formattedContent = content;
    // find uploaded images and replace with img tags
    let imgMatch = formattedContent.match("::uploadimage{(.*?)}");
    while (imgMatch) {
        formattedContent = formattedContent.replaceAll(imgMatch[0], `<img ${imgMatch[1]} />`);
        imgMatch = formattedContent.match("::uploadimage{(.*?)}");
    }
    // eslint-disable-next-line
    const imgMatchBString = "::uploadimage\[\!\[\]\((.*?)\)\]{(.*?)}";
    let imgMatchB = formattedContent.match(imgMatchBString);
    while (imgMatchB) {
        formattedContent = formattedContent.replaceAll(imgMatchB[0], `<img ${imgMatchB[3]} />`);
        imgMatchB = formattedContent.match(imgMatchBString);
    }
    // eslint-disable-next-line
    const imgMatchCString = "::uploadimage\[(.*?)\]{(.*?)}";
    let imgMatchC = formattedContent.match(imgMatchCString);
    while (imgMatchC) {
        formattedContent = formattedContent.replaceAll(imgMatchC[0], `<img ${imgMatchC[3]} />`);
        imgMatchC = formattedContent.match(imgMatchCString);
    }
    return formattedContent;
};
export const formatInvalidEmailLinks = (content) => {
    let formattedContent = content;
    const regex = /<[^>]*@[^>]*[^>]*>/g;
    const matches = content.match(regex);
    matches === null || matches === void 0 ? void 0 : matches.forEach((match) => {
        if (match.length > 1) {
            const email = match.substring(1, match.length - 1);
            formattedContent = formattedContent.replaceAll(match, `[${email}](mailto\\:${email})`);
        }
    });
    return formattedContent;
};
export const imageUploader = (file) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            // @ts-expect-error object is possibly null
            resolve(reader.result.toString());
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
});
