import { useMemo } from "react";
import useSession from "@app/hooks/useSession";
import { useListStages } from "@app/orval/api/stages";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { saturateFieldWithValidation } from "../components/box-view/field_validation";
import { saturateSectionWithValidation } from "../components/box-view/section_validation";
import useTeamAdminPermissions from "../users/useTeamAdminPermissions";
const useValidation = (params) => {
    var _a, _b;
    const { sellerContractFieldSections, sellerContractFields, sellerContracts, sellerLead, sellerLeadFieldSections, sellerLeadFields, } = params;
    const session = useSession();
    const { effectiveUser } = useTeamAdminPermissions((_a = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.agents.map((x) => x.agent.uid)) !== null && _a !== void 0 ? _a : []);
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    }, [(_b = listStagesApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const sellerLeadFieldSectionsSaturated = useMemo(() => {
        return sellerLeadFieldSections === null || sellerLeadFieldSections === void 0 ? void 0 : sellerLeadFieldSections.map((section) => saturateSectionWithValidation({ section, ctx: sellerLead, user: effectiveUser !== null && effectiveUser !== void 0 ? effectiveUser : undefined, stages }));
    }, [effectiveUser, sellerLead, sellerLeadFieldSections, stages]);
    const sellerLeadFieldsSaturated = useMemo(() => {
        return sellerLeadFields === null || sellerLeadFields === void 0 ? void 0 : sellerLeadFields.map((field) => saturateFieldWithValidation({ field, ctx: sellerLead, user: effectiveUser !== null && effectiveUser !== void 0 ? effectiveUser : undefined, stages }));
    }, [effectiveUser, sellerLead, sellerLeadFields, stages]);
    const sellerContractFieldSectionsSaturated = useMemo(() => {
        return sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.reduce((acc, item) => {
            return Object.assign(Object.assign({}, acc), { [item.uid]: sellerContractFieldSections === null || sellerContractFieldSections === void 0 ? void 0 : sellerContractFieldSections.map((section) => {
                    var _a;
                    return saturateSectionWithValidation({
                        section,
                        ctx: Object.assign(Object.assign(Object.assign({}, sellerLead), item), { fields: Object.assign(Object.assign({}, sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.fields), item.fields) }),
                        user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined,
                    });
                }) });
        }, {});
    }, [sellerContractFieldSections, sellerContracts, sellerLead, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const sellerContractFieldsSaturated = useMemo(() => {
        return sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.reduce((acc, item) => {
            return Object.assign(Object.assign({}, acc), { [item.uid]: sellerContractFields === null || sellerContractFields === void 0 ? void 0 : sellerContractFields.map((field) => {
                    var _a;
                    return saturateFieldWithValidation({
                        field,
                        ctx: Object.assign(Object.assign(Object.assign({}, sellerLead), item), { fields: Object.assign(Object.assign({}, sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.fields), item.fields) }),
                        user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined,
                    });
                }) });
        }, {});
    }, [sellerContractFields, sellerContracts, sellerLead, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const validationErrors = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return [
            ...((_a = sellerLeadFieldsSaturated === null || sellerLeadFieldsSaturated === void 0 ? void 0 : sellerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Visible Validation`,
                error: f.visibleValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _a !== void 0 ? _a : []),
            ...((_b = sellerLeadFieldsSaturated === null || sellerLeadFieldsSaturated === void 0 ? void 0 : sellerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Editable Validation`,
                error: f.editableValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _b !== void 0 ? _b : []),
            ...((_c = sellerLeadFieldsSaturated === null || sellerLeadFieldsSaturated === void 0 ? void 0 : sellerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Warn Validation`,
                error: f.warnValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _c !== void 0 ? _c : []),
            ...((_d = sellerLeadFieldsSaturated === null || sellerLeadFieldsSaturated === void 0 ? void 0 : sellerLeadFieldsSaturated.map((f) => ({
                name: `${f.name} - Reject Validation`,
                error: f.rejectValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _d !== void 0 ? _d : []),
            ...((_e = sellerLeadFieldSectionsSaturated === null || sellerLeadFieldSectionsSaturated === void 0 ? void 0 : sellerLeadFieldSectionsSaturated.map((f) => ({
                name: `${f.name} - Visible Validation`,
                error: f.visibleValidation.error,
                url: `/field-management?section=${f.uid}`,
            }))) !== null && _e !== void 0 ? _e : []),
            ...Object.keys(sellerContractFieldsSaturated !== null && sellerContractFieldsSaturated !== void 0 ? sellerContractFieldsSaturated : {})
                .map((uid) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return [
                    ...((_b = (_a = sellerContractFieldsSaturated[uid]) === null || _a === void 0 ? void 0 : _a.map((f) => ({
                        name: `${f.name} - Visible Validation`,
                        error: f.visibleValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _b !== void 0 ? _b : []),
                    ...((_d = (_c = sellerContractFieldsSaturated[uid]) === null || _c === void 0 ? void 0 : _c.map((f) => ({
                        name: `${f.name} - Editable Validation`,
                        error: f.editableValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _d !== void 0 ? _d : []),
                    ...((_f = (_e = sellerContractFieldsSaturated[uid]) === null || _e === void 0 ? void 0 : _e.map((f) => ({
                        name: `${f.name} - Warn Validation`,
                        error: f.warnValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _f !== void 0 ? _f : []),
                    ...((_h = (_g = sellerContractFieldsSaturated[uid]) === null || _g === void 0 ? void 0 : _g.map((f) => ({
                        name: `${f.name} - Reject Validation`,
                        error: f.rejectValidation.error,
                        url: `/field-management?field=${f.uid}`,
                    }))) !== null && _h !== void 0 ? _h : []),
                ];
            })
                .flat(),
            ...Object.keys(sellerContractFieldSectionsSaturated !== null && sellerContractFieldSectionsSaturated !== void 0 ? sellerContractFieldSectionsSaturated : {})
                .map((uid) => {
                var _a, _b;
                return [
                    ...((_b = (_a = sellerContractFieldSectionsSaturated[uid]) === null || _a === void 0 ? void 0 : _a.map((f) => ({
                        name: `${f.name} - Visible Validation`,
                        error: f.visibleValidation.error,
                        url: `/field-management?section=${f.uid}`,
                    }))) !== null && _b !== void 0 ? _b : []),
                ];
            })
                .flat(),
        ].filter((x) => x.error);
    }, [
        sellerContractFieldSectionsSaturated,
        sellerContractFieldsSaturated,
        sellerLeadFieldSectionsSaturated,
        sellerLeadFieldsSaturated,
    ]);
    return {
        sellerLeadFieldSectionsSaturated,
        sellerContractFieldSectionsSaturated,
        sellerLeadFieldsSaturated,
        sellerContractFieldsSaturated,
        validationErrors,
    };
};
export default useValidation;
