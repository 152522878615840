import * as React from "react";
import { House } from "@mui/icons-material";
import { List, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import { useListBridgeProperties } from "@app/orval/api/bridge";
import { useListMlsGridListings } from "@app/orval/api/mls-grid";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import ListingListItem from "./ListingListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Listings = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { mlsNumber } = props;
    const getListingsMLSGridApi = useListMlsGridListings({ agent_mls_id: mlsNumber !== null && mlsNumber !== void 0 ? mlsNumber : undefined }, { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { enabled: false }) });
    const mlsGridListings = (_b = (_a = getListingsMLSGridApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const getListingsBridgeTMLS = useListBridgeProperties("triangle", { agent_mls_id: mlsNumber !== null && mlsNumber !== void 0 ? mlsNumber : undefined, limit: 100 }, { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { enabled: false }) });
    const tmlsListings = (_d = (_c = getListingsBridgeTMLS.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const getListingsBridgeTriad = useListBridgeProperties("triadmls", { agent_mls_id: mlsNumber !== null && mlsNumber !== void 0 ? mlsNumber : undefined, limit: 100 }, { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { enabled: false }) });
    const triadListings = (_f = (_e = getListingsBridgeTriad.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const isLoading = mlsNumber &&
        (getListingsMLSGridApi.isFetching || getListingsBridgeTMLS.isFetching || getListingsBridgeTriad.isFetching);
    const isMissingMlsNumber = mlsNumber == null;
    const allListings = React.useMemo(() => {
        return [
            ...(mlsGridListings !== null && mlsGridListings !== void 0 ? mlsGridListings : []).map((listing) => {
                var _a;
                const propertyData = listing.property_data;
                return {
                    address: propertyData["PropertyAddress"],
                    listPrice: (_a = propertyData["OriginalListPrice"]) !== null && _a !== void 0 ? _a : propertyData["ListPrice"],
                    salePrice: propertyData["ClosePrice"],
                    onMarketDate: propertyData["ListingContractDate"],
                    closeDate: propertyData["CloseDate"],
                    photoUrls: propertyData["Media"].map((x) => x["MediaURL"]),
                    json: listing,
                };
            }),
            ...(tmlsListings !== null && tmlsListings !== void 0 ? tmlsListings : []).map((listing) => {
                var _a;
                const propertyData = listing;
                return {
                    address: propertyData["UnparsedAddress"],
                    listPrice: propertyData["ListPrice"],
                    salePrice: propertyData["ClosePrice"],
                    onMarketDate: propertyData["ListingContractDate"],
                    closeDate: propertyData["CloseDate"],
                    photoUrls: ((_a = propertyData["Media"]) !== null && _a !== void 0 ? _a : []).map((x) => x["MediaURL"]),
                    json: listing,
                };
            }),
            ...(triadListings !== null && triadListings !== void 0 ? triadListings : []).map((listing) => {
                var _a;
                const propertyData = listing;
                return {
                    address: propertyData["UnparsedAddress"],
                    listPrice: propertyData["ListPrice"],
                    salePrice: propertyData["ClosePrice"],
                    onMarketDate: propertyData["ListingContractDate"],
                    closeDate: propertyData["CloseDate"],
                    photoUrls: ((_a = propertyData["Media"]) !== null && _a !== void 0 ? _a : []).map((x) => x["MediaURL"]),
                    json: listing,
                };
            }),
        ]
            .map((x) => (Object.assign(Object.assign({}, x), { types: (() => {
                var _a, _b, _c, _d;
                const res = [];
                if (((_a = x.json["BuyerAgentMlsId"]) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) === (mlsNumber === null || mlsNumber === void 0 ? void 0 : mlsNumber.toLowerCase().trim())) {
                    res.push("Buyer Agent");
                }
                if (((_b = x.json["ListAgentMlsId"]) === null || _b === void 0 ? void 0 : _b.toLowerCase().trim()) === (mlsNumber === null || mlsNumber === void 0 ? void 0 : mlsNumber.toLowerCase().trim())) {
                    res.push("List Agent");
                }
                if (((_c = x.json["CoBuyerAgentMlsId"]) === null || _c === void 0 ? void 0 : _c.toLowerCase().trim()) === (mlsNumber === null || mlsNumber === void 0 ? void 0 : mlsNumber.toLowerCase().trim())) {
                    res.push("Co-Buyer Agent");
                }
                if (((_d = x.json["CoListAgentMlsId"]) === null || _d === void 0 ? void 0 : _d.toLowerCase().trim()) === (mlsNumber === null || mlsNumber === void 0 ? void 0 : mlsNumber.toLowerCase().trim())) {
                    res.push("Co-List Agent");
                }
                return res;
            })() })))
            .sort((a, b) => {
            var _a, _b;
            const aListingDate = (_a = a.onMarketDate) !== null && _a !== void 0 ? _a : "";
            const bListingDate = (_b = b.onMarketDate) !== null && _b !== void 0 ? _b : "";
            if (aListingDate > bListingDate)
                return -1;
            if (aListingDate < bListingDate)
                return 1;
            return 0;
        });
    }, [mlsGridListings, tmlsListings, triadListings, mlsNumber]);
    React.useEffect(() => {
        if (mlsNumber) {
            getListingsMLSGridApi.refetch();
            getListingsBridgeTMLS.refetch();
            getListingsBridgeTriad.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mlsNumber]);
    return (React.createElement(CollapsibleSection, { icon: React.createElement(House, null), title: "Listings", count: isLoading ? undefined : allListings.length, displayPlusAfterCount: allListings.length >= 100 },
        isLoading && React.createElement(CoreLoading, null),
        !isLoading && allListings.length === 0 && React.createElement(Typography, { variant: "body2" }, "No listings found"),
        isMissingMlsNumber && (React.createElement(Typography, { variant: "body2" }, "Add an MLS Number to see this agent's listings")),
        allListings.length > 0 && (React.createElement(List, { dense: true }, allListings.map((listing) => {
            return (React.createElement(ListingListItem, { key: listing.address, address: listing.address, listPrice: listing.listPrice, salePrice: listing.salePrice, onMarketDate: listing.onMarketDate, closeDate: listing.closeDate, photoUrls: listing.photoUrls, types: listing.types, json: listing }));
        }))),
        !isMissingMlsNumber && !isLoading && (React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic" } }, "Listings in FLEX MLS are not included in these results."))));
};
export default Listings;
