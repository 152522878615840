import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Grid2, InputAdornment, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import CoreDateField from "@app/common/CoreDateField";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
const offices = ["RDU", "AVL", "CLT", "FAY"];
const CRMChangesReportFilters = () => {
    var _a, _b, _c;
    const [searchParams] = useSearchParams();
    const updateSearch = useUpdateSearch();
    const startDate = (_a = searchParams.get("start_date")) !== null && _a !== void 0 ? _a : null;
    const endDate = (_b = searchParams.get("end_date")) !== null && _b !== void 0 ? _b : null;
    const office = (_c = searchParams.get("office")) !== null && _c !== void 0 ? _c : null;
    const handleStartDateChanged = React.useCallback((datetime) => {
        updateSearch("start_date", datetime !== null && datetime !== void 0 ? datetime : dayjs(new Date()).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss"));
    }, [updateSearch]);
    const handleEndDateChanged = React.useCallback((datetime) => {
        updateSearch("end_date", datetime !== null && datetime !== void 0 ? datetime : dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss"));
    }, [updateSearch]);
    const handleOfficeChanged = React.useCallback((e) => {
        updateSearch("office", e.target.value || null);
    }, [updateSearch]);
    return (React.createElement(Grid2, { container: true, spacing: 2 },
        React.createElement(Grid2, { style: { width: "300px" } },
            React.createElement(CoreDateField, { fullWidth: true, variant: "standard", size: "small", mode: "datetime", format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", slotProps: {
                    input: {
                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Start Date"),
                    },
                }, date: startDate, onDateChange: handleStartDateChanged })),
        React.createElement(Grid2, { style: { width: "300px" } },
            React.createElement(CoreDateField, { fullWidth: true, variant: "standard", size: "small", mode: "datetime", format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", InputProps: {
                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "End Date"),
                }, date: endDate, onDateChange: handleEndDateChanged })),
        React.createElement(Grid2, { style: { width: "300px" } },
            React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", size: "small", slotProps: {
                    input: {
                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Office"),
                    },
                }, value: office, onChange: handleOfficeChanged },
                React.createElement(MenuItem, { value: "" }, "All"),
                offices.map((x) => (React.createElement(MenuItem, { key: x, value: x }, x)))))));
};
export default CRMChangesReportFilters;
