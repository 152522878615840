import * as React from "react";
import { Phone, Sms } from "@mui/icons-material";
import { Link, ListItemIcon, MenuItem } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import twilioIcon from "@app/assets/img/twilio.png";
import { TwilioCallContext } from "@app/entrypoints/brokerage/context/twilio-calls/TwilioCallContext";
import { TwilioSMSContext } from "@app/entrypoints/brokerage/context/twilio-sms/TwilioSMSContext";
import { getCallUrl, getSmsUrl } from "@app/util/Utils";
const PhoneMenuSection = (props) => {
    const { contacts } = props;
    const voiceContext = React.useContext(TwilioCallContext);
    const smsContext = React.useContext(TwilioSMSContext);
    const contact1 = contacts.find((x) => x.phone && isValidPhoneNumber(x.phone, "US"));
    const contactsWithPhones = contacts.filter((x) => x.phone && isValidPhoneNumber(x.phone, "US"));
    const callViaTwilio = React.useCallback(() => {
        voiceContext.openDialer(contactsWithPhones.map((c) => { var _a; return ({ name: (_a = c.name) !== null && _a !== void 0 ? _a : "", phone_number: c.phone }); }));
    }, [contactsWithPhones, voiceContext]);
    const textViaTwilio = React.useCallback(() => {
        smsContext.openConversation({ toPhoneNumber: contacts[0].phone });
    }, [contacts, smsContext]);
    return [
        React.createElement(MenuItem, { key: "call", component: Link, href: contact1 ? getCallUrl(contact1.phone) : undefined, disabled: !contact1 },
            React.createElement(ListItemIcon, null,
                React.createElement(Phone, null)),
            "Call ", contact1 === null || contact1 === void 0 ? void 0 :
            contact1.name,
            "..."),
        React.createElement(MenuItem, { key: "text", component: Link, href: contactsWithPhones.length > 0 ? getSmsUrl(contactsWithPhones.map((c) => c.phone)) : undefined, disabled: !contact1 },
            React.createElement(ListItemIcon, null,
                React.createElement(Sms, null)),
            "Text ",
            contactsWithPhones.map((c) => c.name).join(", "),
            "..."),
        React.createElement(MenuItem, { key: "call via twilio", disabled: !contact1, onClick: callViaTwilio },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: twilioIcon, height: 24, alt: "Twilio" })),
            "Call ", contact1 === null || contact1 === void 0 ? void 0 :
            contact1.name,
            " via Twilio..."),
        React.createElement(MenuItem, { key: "text via twilio", disabled: !contact1, onClick: textViaTwilio },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: twilioIcon, height: 24, alt: "Twilio" })),
            "Text ", contact1 === null || contact1 === void 0 ? void 0 :
            contact1.name,
            " via Twilio..."),
    ];
};
export default PhoneMenuSection;
