import * as React from "react";
import { Grid } from "@mui/material";
import SavedViewEditNameTextField from "./SavedViewEditNameTextField";
import SavedViewEditPurposeSelect from "./SavedViewEditPurposeSelect";
const SavedViewHeader = () => {
    return (React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(SavedViewEditNameTextField, null)),
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(SavedViewEditPurposeSelect, null))));
};
export default SavedViewHeader;
