/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List shadowing types
 */
export const listShadowingTypes = (options) => {
    return axios.get(`/api/onboarding/shadowing_types`, options);
};
export const getListShadowingTypesQueryKey = () => {
    return [`/api/onboarding/shadowing_types`];
};
export const getListShadowingTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListShadowingTypesQueryKey();
    const queryFn = ({ signal }) => listShadowingTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List shadowing types
 */
export function useListShadowingTypes(options) {
    const queryOptions = getListShadowingTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Add shadowing type
 */
export const addShadowingType = (shadowingTypeRequest, options) => {
    return axios.post(`/api/onboarding/shadowing_types`, shadowingTypeRequest, options);
};
export const getAddShadowingTypeMutationOptions = (options) => {
    const mutationKey = ['addShadowingType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addShadowingType(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add shadowing type
*/
export const useAddShadowingType = (options) => {
    const mutationOptions = getAddShadowingTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get shadowing type
*/
export const getShadowingType = (uid, options) => {
    return axios.get(`/api/onboarding/shadowing_types/${uid}`, options);
};
export const getGetShadowingTypeQueryKey = (uid) => {
    return [`/api/onboarding/shadowing_types/${uid}`];
};
export const getGetShadowingTypeQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetShadowingTypeQueryKey(uid);
    const queryFn = ({ signal }) => getShadowingType(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get shadowing type
 */
export function useGetShadowingType(uid, options) {
    const queryOptions = getGetShadowingTypeQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update shadowing type
 */
export const updateShadowingType = (uid, shadowingTypeRequest, options) => {
    return axios.post(`/api/onboarding/shadowing_types/${uid}`, shadowingTypeRequest, options);
};
export const getUpdateShadowingTypeMutationOptions = (options) => {
    const mutationKey = ['updateShadowingType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateShadowingType(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update shadowing type
*/
export const useUpdateShadowingType = (options) => {
    const mutationOptions = getUpdateShadowingTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete shadowing type
*/
export const deleteShadowingType = (uid, options) => {
    return axios.delete(`/api/onboarding/shadowing_types/${uid}`, options);
};
export const getDeleteShadowingTypeMutationOptions = (options) => {
    const mutationKey = ['deleteShadowingType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteShadowingType(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete shadowing type
*/
export const useDeleteShadowingType = (options) => {
    const mutationOptions = getDeleteShadowingTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Reorder shadowing types
*/
export const reorderShadowingTypes = (shadowingTypeReorderRequest, options) => {
    return axios.post(`/api/onboarding/shadowing_types/reorder`, shadowingTypeReorderRequest, options);
};
export const getReorderShadowingTypesMutationOptions = (options) => {
    const mutationKey = ['reorderShadowingTypes'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderShadowingTypes(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder shadowing types
*/
export const useReorderShadowingTypes = (options) => {
    const mutationOptions = getReorderShadowingTypesMutationOptions(options);
    return useMutation(mutationOptions);
};
