export const isRelevant = (options) => (transaction) => {
    var _a, _b;
    const { office } = options;
    const fields = Object.assign(Object.assign(Object.assign({}, transaction.fields), (_a = transaction.buyer_lead) === null || _a === void 0 ? void 0 : _a.fields), (_b = transaction.seller_lead) === null || _b === void 0 ? void 0 : _b.fields);
    const flexOffice = fields["FLEX OFFICE"];
    const zillowOfficeId = fields["Zillow Office ID"];
    const leadType = fields["Lead Type"];
    const source = fields["Source"];
    if (office === "RDU") {
        if ((flexOffice === null || flexOffice === void 0 ? void 0 : flexOffice.toLowerCase().trim()) === "avl" || zillowOfficeId === "X1-ZUs9lf5adjduyx_3xvfe") {
            return false;
        }
        if (!(source === "FLEX" || source === "ZG")) {
            return false;
        }
    }
    if (office === "AVL") {
        if ((flexOffice === null || flexOffice === void 0 ? void 0 : flexOffice.toLowerCase().trim()) !== "avl") {
            return false;
        }
        if (source !== "DASH") {
            return false;
        }
        if (!["Profile", "Live Connection", "Tour request", "Nurture"].includes(leadType !== null && leadType !== void 0 ? leadType : "")) {
            return false;
        }
    }
    if (office === "FAY") {
        if ((flexOffice === null || flexOffice === void 0 ? void 0 : flexOffice.toLowerCase().trim()) !== "fay") {
            return false;
        }
        if (source !== "FLEX") {
            return false;
        }
    }
    if (office === "CLT") {
        if ((flexOffice === null || flexOffice === void 0 ? void 0 : flexOffice.toLowerCase().trim()) !== "clt") {
            return false;
        }
        if (source !== "FLEX") {
            return false;
        }
    }
    return true;
};
