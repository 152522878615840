var _a, _b;
import * as React from "react";
import MAtrixPage from "@app/entrypoints/brokerage/features/apps/matrix/MAtrixPage";
import BuyerLeadsFormPage from "@app/entrypoints/brokerage/features/crm/buyer-leads/BuyerLeadsFormPage";
import BuyerLeadsPage from "@app/entrypoints/brokerage/features/crm/buyer-leads/BuyerLeadsPage";
import ContactsFormPage from "@app/entrypoints/brokerage/features/crm/contacts/ContactsFormPage";
import ContactsPage from "@app/entrypoints/brokerage/features/crm/contacts/ContactsPage";
import SellerLeadsFormPage from "@app/entrypoints/brokerage/features/crm/seller-leads/SellerLeadsFormPage";
import SellerLeadsPage from "@app/entrypoints/brokerage/features/crm/seller-leads/SellerLeadsPage";
import AgentDashboardPage from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/AgentDashboardPage";
import Report from "@app/entrypoints/brokerage/features/datastudios/Report";
import ReportConfigWrapper from "@app/entrypoints/brokerage/features/datastudios/ReportConfigWrapper";
import GDSReportReorder from "@app/entrypoints/brokerage/features/datastudios/ReportReorder";
import ChoiceSetsPage from "@app/entrypoints/brokerage/features/developer/choice-sets/ChoiceSetsPage";
import EmailPage from "@app/entrypoints/brokerage/features/emails/EmailPage";
import FlexRegionPage from "@app/entrypoints/brokerage/features/flex-regions/FlexRegionPage";
import QuestionsForTheInsuranceForm from "@app/entrypoints/brokerage/features/forms/questions-for-the-insurance/QuestionsForTheInsuranceForm";
import QuestionsForTheOfferFormPage from "@app/entrypoints/brokerage/features/forms/questions-for-the-offer/QuestionsForTheOfferFormPage";
import QuestionsForTheOfferSubmission from "@app/entrypoints/brokerage/features/forms/questions-for-the-offer/QuestionsForTheOfferSubmission";
import QuestionsForTheOfferSubmissions from "@app/entrypoints/brokerage/features/forms/questions-for-the-offer/QuestionsForTheOfferSubmissionsWrapper";
import MLSGrid from "@app/entrypoints/brokerage/features/integrations/mls-grid/MLSGrid";
import SlackAuthorizationsPage from "@app/entrypoints/brokerage/features/integrations/slack/SlackAuthorizationsPage";
import Spark from "@app/entrypoints/brokerage/features/integrations/spark/Spark";
import TwilioWrapper from "@app/entrypoints/brokerage/features/integrations/twilio/TwilioWrapper";
import Login from "@app/entrypoints/brokerage/features/Login";
import MailScheduleWrapper from "@app/entrypoints/brokerage/features/mailschedule/MailScheduleWrapper";
import MailSchedule4Wrapper from "@app/entrypoints/brokerage/features/mailschedule4/MailScheduleWrapper";
import Unsubscribe from "@app/entrypoints/brokerage/features/misc/Unsubscribe";
import PriceReductionSchedule from "@app/entrypoints/brokerage/features/price-reduction-schedule/PriceReductionSchedule";
import DashScoreSettings from "@app/entrypoints/brokerage/features/reporting/dashscore/DashScoreSettings";
import CompsPullerRuleSetPage from "@app/entrypoints/brokerage/features/tools/comps-puller/rules/CompsPullerRuleSetPage";
import CompsPullerRuleSets from "@app/entrypoints/brokerage/features/tools/comps-puller/rules/CompsPullerRuleSets";
import CompsPullerRuleSetsReorder from "@app/entrypoints/brokerage/features/tools/comps-puller/rules/CompsPullerRuleSetsReorder";
import WordDocTemplatesPage from "@app/entrypoints/brokerage/pages/admin/WordDocTemplatesPage";
import BuyerContractsMailSchedulePage from "@app/entrypoints/brokerage/pages/crm/BuyerContractsMailSchedulePage";
import BuyerContractsMailSchedulePreviewPage from "@app/entrypoints/brokerage/pages/crm/BuyerContractsMailSchedulePreviewPage";
import BuyerLeadsMailSchedulePage from "@app/entrypoints/brokerage/pages/crm/BuyerLeadsMailSchedulePage";
import BuyerLeadsMailSchedulePreviewPage from "@app/entrypoints/brokerage/pages/crm/BuyerLeadsMailSchedulePreviewPage";
import SellerContractsMailSchedulePage from "@app/entrypoints/brokerage/pages/crm/SellerContractsMailSchedulePage";
import SellerContractsMailSchedulePreviewPage from "@app/entrypoints/brokerage/pages/crm/SellerContractsMailSchedulePreviewPage";
import SellerLeadsMailSchedulePage from "@app/entrypoints/brokerage/pages/crm/SellerLeadsMailSchedulePage";
import SellerLeadsMailSchedulePreviewPage from "@app/entrypoints/brokerage/pages/crm/SellerLeadsMailSchedulePreviewPage";
import FieldManagementPage from "@app/entrypoints/brokerage/pages/developer/FieldManagementPage";
import BridgePage from "@app/entrypoints/brokerage/pages/integrations/BridgePage";
import MAtrixFormPage from "@app/entrypoints/brokerage/pages/matrix/MAtrixFormPage";
import PSPCertificationPage from "@app/entrypoints/brokerage/pages/matrix/PSPCertificationPage";
import DashDailyOnDemandPage from "@app/entrypoints/brokerage/pages/reporting/DashDailyOnDemandPage";
import DashScorePage from "@app/entrypoints/brokerage/pages/reporting/DashScorePage";
import PSPAccuracyPage from "@app/entrypoints/brokerage/pages/reporting/PSPAccuracyPage";
import ReportingPortalToolsPage from "@app/entrypoints/brokerage/pages/reporting/ReportingPortalToolsPage";
import SLAReportPage from "@app/entrypoints/brokerage/pages/reporting/SLAReportPage";
import CompsPullerPage from "@app/entrypoints/brokerage/pages/tools/CompsPullerPage";
import TowneMortgageBuySidePage from "@app/entrypoints/brokerage/pages/towne-mortgage/TowneMortgageBuySidePage";
import TowneMortgageListingsPage from "@app/entrypoints/brokerage/pages/towne-mortgage/TowneMortgageListingsPage";
import Session from "@app/util/Session";
import SMSPage from "./features/apps/sms/SMSPage";
import AddThreadsToBoxPage from "./features/crm/add-threads-to-box/AddThreadsToBoxPage";
import ViewPage from "./features/crm/views/ViewPage";
import ViewsPage from "./features/crm/views/ViewsPage";
import OverviewPage from "./features/dashboards/agent-dashboard/components/overview/OverviewPage";
import ChoiceSetsFormPage from "./features/developer/choice-sets/ChoiceSetsFormPage";
import RequestDebuggingPage from "./features/developer/request-debugging/RequestDebuggingPage";
import FeedbackRequestCadencePage from "./features/feedback-request-cadence/FeedbackRequestCadencePage";
import QuestionsForTheAffiliationFormPageNEWCRM from "./features/forms/questions-for-the-affiliation/QuestionsForTheAffiliationFormPageNEWCRM";
import QuestionsForTheAffiliationFormSubmissionPageNEWCRM from "./features/forms/questions-for-the-affiliation/QuestionsForTheAffiliationFormSubmissionPageNEWCRM";
import QuestionsForTheListingFormPageNEWCRM from "./features/forms/questions-for-the-listing/QuestionsForTheListingFormPageNEWCRM";
import QuestionsForTheListingFormSubmissionPageNEWCRM from "./features/forms/questions-for-the-listing/QuestionsForTheListingFormSubmissionPageNEWCRM";
import QuestionsForTheSearchFormPage from "./features/forms/questions-for-the-search/QuestionsForTheSearchFormPage";
import QuestionsForTheSearchFormSubmissionPageNEWCRM from "./features/forms/questions-for-the-search/QuestionsForTheSearchFormSubmissionPageNEWCRM";
import ScriptsPage from "./features/integrations/scripts/ScriptsPage";
import MailSchedule4TemplatePreviewPage from "./features/mailschedule4/preview/MailScheduleTemplatePreviewPage";
import PriceReductionScheduleFormPage from "./features/price-reduction-schedule/PriceReductionScheduleFormPage";
import GoogleGroupsAccessPage from "./pages/admin/GoogleGroupsAccessPage";
import GraduationRequirementTypeFormPage from "./pages/admin/GraduationRequirementTypeFormPage";
import GraduationRequirementTypesPage from "./pages/admin/GraduationRequirementTypesPage";
import OfficeFormPage from "./pages/admin/OfficeFormPage";
import OfficesPage from "./pages/admin/OfficesPage";
import ShadowingTypeFormPage from "./pages/admin/ShadowingTypesFormPage";
import ShadowingTypesPage from "./pages/admin/ShadowingTypesPage";
import SlackAccessPage from "./pages/admin/SlackAccessPage";
import TeamFormPage from "./pages/admin/TeamFormPage";
import TeamsPage from "./pages/admin/TeamsPage";
import TwilioAccessPage from "./pages/admin/TwilioAccessPage";
import NMSRequestPage from "./pages/analyst/NMSRequestPage";
import BuyerContractsPage from "./pages/crm/BuyerContractsPage";
import CallsPage from "./pages/crm/CallsPage";
import MyTasksPage from "./pages/crm/MyTasksPage";
import OfficeExclusivePage from "./pages/crm/OfficeExclusivePage";
import RecruitingFormPage from "./pages/crm/RecruitingFormPage";
import RecruitingPage from "./pages/crm/RecruitingPage";
import BoxRedirectPage from "./pages/crm/redirects/BoxRedirectPage";
import CommentRedirectPage from "./pages/crm/redirects/CommentRedirectPage";
import NotificationRedirectPage from "./pages/crm/redirects/NotificationRedirectPage";
import SellerContractsPage from "./pages/crm/SellerContractsPage";
import UserFormPage from "./pages/crm/UserFormPage";
import UsersPage from "./pages/crm/UsersPage";
import ViewPurposesFormPage from "./pages/crm/ViewPurposesFormPage";
import ViewPurposesPage from "./pages/crm/ViewPurposesPage";
import CRMChangesReportPage from "./pages/reporting/CRMChangesReportPage";
import PSPReportPage from "./pages/reporting/PSPReportPage";
import ZHLEngagedTransferPage from "./pages/reporting/ZHLEngagedTransferPage";
import EditSOPPage from "./pages/sops/EditSOPPage";
import SOPPage from "./pages/sops/SOPPage";
import SOPsPage from "./pages/sops/SOPsPage";
const isDeveloper = (session) => { var _a; return !!((_a = session.activeUser) === null || _a === void 0 ? void 0 : _a.isDeveloper); };
const routesConfig = [
    // Login
    {
        path: "/login",
        element: React.createElement(Login, null),
    },
    // Dashboards
    {
        path: "/",
        element: React.createElement(AgentDashboardPage, null),
        internalOnly: true,
    },
    {
        path: "/overview",
        element: React.createElement(OverviewPage, null),
        internalOnly: true,
    },
    // Unsubscribe
    {
        path: "/unsubscribe",
        element: React.createElement(Unsubscribe, null),
        pageName: "Unsubscribe",
    },
    // Developer
    // Admin - Fields
    {
        path: "/field-management",
        element: React.createElement(FieldManagementPage, null),
        roles: ["Superadmin"],
    },
    // Admin - Choice Sets
    {
        path: "/choice-sets/new",
        element: React.createElement(ChoiceSetsFormPage, null),
        roles: ["Superadmin"],
    },
    {
        path: "/choice-sets/:uid",
        element: React.createElement(ChoiceSetsFormPage, null),
        roles: ["Superadmin"],
    },
    {
        path: "/choice-sets",
        element: React.createElement(ChoiceSetsPage, null),
        pageName: "Choice Sets",
        roles: ["Superadmin"],
        tags: ["Admin", "Choice Sets", "Choices", "Dropdown"],
    },
    // Users
    {
        path: "/users/:uid",
        element: React.createElement(UserFormPage, null),
        internalOnly: true,
    },
    {
        path: "/users",
        element: React.createElement(UsersPage, null),
        pageName: "Users",
        internalOnly: true,
    },
    // Offices
    {
        path: "/offices/new",
        element: React.createElement(OfficeFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/offices/:uid",
        element: React.createElement(OfficeFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/offices",
        element: React.createElement(OfficesPage, null),
        pageName: "Offices",
        roles: ["Superadmin", "Admin"],
    },
    // Teams
    {
        path: "/teams/new",
        element: React.createElement(TeamFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/teams/:uid",
        element: React.createElement(TeamFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/teams",
        element: React.createElement(TeamsPage, null),
        pageName: "Offices",
        roles: ["Superadmin", "Admin"],
    },
    // Access
    {
        path: "/twilio-access",
        element: React.createElement(TwilioAccessPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/slack-access",
        element: React.createElement(SlackAccessPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/google-groups-access",
        element: React.createElement(GoogleGroupsAccessPage, null),
        roles: ["Superadmin", "Admin"],
    },
    // Onboarding
    {
        path: "/onboarding/graduation-requirement-types/new",
        element: React.createElement(GraduationRequirementTypeFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/onboarding/graduation-requirement-types/:uid",
        element: React.createElement(GraduationRequirementTypeFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/onboarding/graduation-requirement-types",
        element: React.createElement(GraduationRequirementTypesPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/onboarding/shadowing-types/new",
        element: React.createElement(ShadowingTypeFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/onboarding/shadowing-types/:uid",
        element: React.createElement(ShadowingTypeFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/onboarding/shadowing-types",
        element: React.createElement(ShadowingTypesPage, null),
        roles: ["Superadmin", "Admin"],
    },
    // Templates
    {
        path: "/templates",
        element: React.createElement(WordDocTemplatesPage, null),
        pageName: "Templates",
        roles: ["Superadmin", "Admin"],
        tags: ["Admin", "Templates", "Word Doc Templates"],
    },
    // Mail Schedule
    {
        path: "/mailschedule/settings",
        element: React.createElement(MailScheduleWrapper, { page: "settings" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule/templates/reorder",
        element: React.createElement(MailScheduleWrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule/templates/new",
        element: React.createElement(MailScheduleWrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule/templates/:uid?",
        element: React.createElement(MailScheduleWrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule/boilerplate/new",
        element: React.createElement(MailScheduleWrapper, { page: "boilerplate" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule/boilerplate/:uid?",
        element: React.createElement(MailScheduleWrapper, { page: "boilerplate" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule/boxes",
        element: React.createElement(MailScheduleWrapper, { page: "boxes" }),
        internalOnly: true,
    },
    {
        path: "/mailschedule/run",
        element: React.createElement(MailScheduleWrapper, { page: "run" }),
        internalOnly: true,
    },
    {
        path: "/mailschedule/errors",
        element: React.createElement(MailScheduleWrapper, { page: "errors" }),
        internalOnly: true,
    },
    {
        path: "/mailschedule/logs/:uid?",
        element: React.createElement(MailScheduleWrapper, { page: "logs" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/mailschedule",
        redirect: ((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]))
            ? "/mailschedule/templates"
            : "/mailschedule/boxes",
        pageName: "Mail Schedule",
    },
    // Mail Schedule 4
    {
        path: "/ms4/settings",
        element: React.createElement(MailSchedule4Wrapper, { page: "settings" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/templates/reorder",
        element: React.createElement(MailSchedule4Wrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/templates/metrics",
        element: React.createElement(MailSchedule4Wrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/templates/new",
        element: React.createElement(MailSchedule4Wrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/templates/:uid?",
        element: React.createElement(MailSchedule4Wrapper, { page: "templates" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/boilerplate/new",
        element: React.createElement(MailSchedule4Wrapper, { page: "boilerplate" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/boilerplate/:uid?",
        element: React.createElement(MailSchedule4Wrapper, { page: "boilerplate" }),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/ms4/boxes",
        element: React.createElement(MailSchedule4Wrapper, { page: "boxes" }),
        internalOnly: true,
    },
    {
        path: "/ms4/run",
        element: React.createElement(MailSchedule4Wrapper, { page: "run" }),
        internalOnly: true,
    },
    {
        path: "/ms4/errors",
        element: React.createElement(MailSchedule4Wrapper, { page: "errors" }),
        internalOnly: true,
    },
    {
        path: "/ms4/logs",
        element: React.createElement(MailSchedule4Wrapper, { page: "logs" }),
        internalOnly: true,
    },
    {
        path: "/ms4/logs/detailed",
        element: React.createElement(MailSchedule4Wrapper, { page: "logs-detailed" }),
        internalOnly: true,
    },
    {
        path: "/ms4/preview",
        element: React.createElement(MailSchedule4TemplatePreviewPage, null),
        internalOnly: true,
    },
    {
        path: "/ms4",
        redirect: ((_b = Session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"])) ? "/ms4/templates" : "/ms4/boxes",
        pageName: "Mail Schedule",
    },
    {
        path: "/feedback-request-cadence",
        element: React.createElement(FeedbackRequestCadencePage, null),
        pageName: "Feedback Request Cadence",
    },
    // Apps
    // MAtrix
    {
        // (files|psp|net-sheet|cma-comps|comps|comps-finder|offers|calculations|investor)
        path: "/matrix/:uid/:tab?",
        element: React.createElement(MAtrixFormPage, null),
        pageName: "MAtrix",
        tags: ["Matrix", "PSP"],
        internalOnly: true,
    },
    {
        path: "/matrix",
        element: React.createElement(MAtrixPage, null),
        pageName: "MAtrix",
        tags: ["Matrix", "PSP"],
        internalOnly: true,
    },
    {
        path: "/psp-certification",
        element: React.createElement(PSPCertificationPage, null),
        tags: ["Matix", "PSP", "Certification"],
        pageName: "PSP Certification",
        internalOnly: true,
    },
    {
        path: "/psp-reports/:uid",
        element: React.createElement(PSPReportPage, null),
        internalOnly: true,
    },
    // NMS Requests
    {
        path: "/nms-requests",
        element: React.createElement(NMSRequestPage, null),
        pageName: "NMS Requests",
        tags: ["NMS", "Showing", "Requests"],
        internalOnly: true,
    },
    // Price Reduction Schedule
    {
        path: "/price-reduction-schedule/new",
        element: React.createElement(PriceReductionScheduleFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/price-reduction-schedule/:uid",
        element: React.createElement(PriceReductionScheduleFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/price-reduction-schedule",
        element: React.createElement(PriceReductionSchedule, null),
        pageName: "Price Reduction Schedule",
        roles: ["Superadmin", "Admin"],
        tags: ["Price", "Reduction", "Invest", "Suggested"],
    },
    // SMS
    {
        path: "/sms",
        element: React.createElement(SMSPage, null),
        pageName: "SMS",
        tags: ["SMS", "Text", "Texts", "Twilio"],
        internalOnly: true,
    },
    // CALLS
    {
        path: "/calls",
        element: React.createElement(CallsPage, null),
        internalOnly: true,
    },
    // SOPs
    {
        path: "/sops",
        element: React.createElement(SOPsPage, null),
        pageName: "SOPs",
        tags: ["SOP", "SOPs"],
        internalOnly: true,
    },
    {
        path: "/sops/:uid/view",
        element: React.createElement(SOPPage, null),
        internalOnly: true,
    },
    {
        path: "/sops/new",
        element: React.createElement(EditSOPPage, null),
        internalOnly: true,
    },
    {
        path: "/sops/:uid",
        element: React.createElement(EditSOPPage, null),
        internalOnly: true,
    },
    // Forms
    {
        path: "/forms/qfo/:uid",
        element: React.createElement(QuestionsForTheOfferSubmission, null),
        internalOnly: true,
    },
    {
        // (history|cash-to-close|qfi)
        path: "/forms/qfo/:uid/:tab",
        element: React.createElement(QuestionsForTheOfferSubmission, null),
        internalOnly: true,
    },
    {
        path: "/forms/qfo/form/:uid?",
        element: React.createElement(QuestionsForTheOfferFormPage, null),
    },
    {
        path: "/forms/qfo",
        element: React.createElement(QuestionsForTheOfferSubmissions, null),
        pageName: "Questions for the Offer",
        tags: ["QFO"],
        internalOnly: true,
    },
    {
        path: "/forms/qfi/form/:uid?",
        element: React.createElement(QuestionsForTheInsuranceForm, null),
    },
    {
        path: "/forms/questions-for-the-listing/:uid",
        element: React.createElement(QuestionsForTheListingFormPageNEWCRM, null),
    },
    {
        path: "/forms/questions-for-the-listing/submissions/:uid",
        element: React.createElement(QuestionsForTheListingFormSubmissionPageNEWCRM, null),
        internalOnly: true,
    },
    {
        path: "/forms/questions-for-the-search/:uid",
        element: React.createElement(QuestionsForTheSearchFormPage, null),
    },
    {
        path: "/forms/questions-for-the-search/submissions/:uid",
        element: React.createElement(QuestionsForTheSearchFormSubmissionPageNEWCRM, null),
        internalOnly: true,
    },
    {
        path: "/forms/questions-for-affiliation/:uid",
        element: React.createElement(QuestionsForTheAffiliationFormPageNEWCRM, null),
    },
    {
        path: "/forms/questions-for-affiliation/submissions/:uid",
        element: React.createElement(QuestionsForTheAffiliationFormSubmissionPageNEWCRM, null),
        internalOnly: true,
    },
    // Tools
    {
        path: "/tools/comps-puller/rules/reorder",
        element: React.createElement(CompsPullerRuleSetsReorder, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/tools/comps-puller/rules/new",
        element: React.createElement(CompsPullerRuleSetPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/tools/comps-puller/rules/:uid",
        element: React.createElement(CompsPullerRuleSetPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/tools/comps-puller/rules",
        element: React.createElement(CompsPullerRuleSets, null),
        pageName: "Comps Puller Rules",
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/tools/comps-puller",
        element: React.createElement(CompsPullerPage, null),
        pageName: "Comps Puller",
        internalOnly: true,
    },
    // Google Data Studio
    {
        path: "/datastudios/config/reorder",
        element: React.createElement(GDSReportReorder, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/datastudios/config/new",
        element: React.createElement(ReportConfigWrapper, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/datastudios/config/:uid",
        element: React.createElement(ReportConfigWrapper, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/datastudios/config",
        element: React.createElement(ReportConfigWrapper, null),
        pageName: "Google Data Studio Reports",
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/datastudios/reports/:uid",
        element: React.createElement(Report, null),
        internalOnly: true,
    },
    // Reporting
    {
        path: "/reporting/dashscore",
        element: React.createElement(DashScorePage, null),
        pageName: "Reporting | DashScore",
        tags: ["Reporting", "Dash", "Score", "Zillow", "DashScore"],
    },
    {
        path: "/reporting/dashscore/settings",
        element: React.createElement(DashScoreSettings, null),
        pageName: "Reporting | DashScore",
        tags: ["Reporting", "Dash", "Score", "Zillow", "DashScore"],
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/reporting/crm-changes-report",
        element: React.createElement(CRMChangesReportPage, null),
        tags: ["Reporting", "Streak Changes Reports", "Streak", "Changes", "Reports"],
        pageName: "Reporting | Streak Changes Reports (New CRM)",
    },
    {
        path: "/reporting/portal-tools",
        element: React.createElement(ReportingPortalToolsPage, null),
        pageName: "Reporting | Portal Tools",
        roles: ["Superadmin", "Admin"],
        tags: ["Reporting", "Portal Tools"],
    },
    {
        path: "/reporting/sla",
        element: React.createElement(SLAReportPage, null),
        pageName: "Reporting | SLA Violations",
        roles: ["Superadmin", "Admin", "Operating Partner"],
        tags: ["Reporting", "SLA", "Violation", "Violations"],
    },
    {
        path: "/reporting/psp-accuracy",
        element: React.createElement(PSPAccuracyPage, null),
        pageName: "Reporting | PSP Accuracy",
        roles: ["Superadmin", "Admin"],
        tags: ["Reporting", "PSP Accuracy"],
    },
    {
        path: "/reporting/dash-daily-on-demand",
        element: React.createElement(DashDailyOnDemandPage, null),
        pageName: "Reporting | Dash Daily on Demand",
        roles: ["Superadmin", "Admin"],
        tags: ["Reporting", "Dash Daily on Demand"],
    },
    {
        path: "/reporting/zhl-engaged-transfer",
        element: React.createElement(ZHLEngagedTransferPage, null),
        pageName: "Reporting | ZHL Engaged Transsfer",
        roles: ["Superadmin", "Admin"],
        tags: ["Reporting", "ZHL Engaged Transfer"],
    },
    // Flex Regions
    {
        path: "/flex-regions/:region",
        element: React.createElement(FlexRegionPage, null),
        internalOnly: true,
    },
    // Towne Mortgage
    {
        path: "/towne-mortgage/listings",
        element: React.createElement(TowneMortgageListingsPage, null),
        pageName: "Towne Mortgage | Listings",
        tags: ["Towne Mortage", "Listings"],
    },
    {
        path: "/towne-mortgage/buy-side-pending",
        element: React.createElement(TowneMortgageBuySidePage, { view: "buy-side-pending" }),
        pageName: "Towne Mortgage | Buy Side Pending",
        tags: ["Towne Mortgage", "Buy Side", "Buyer Contract", "Buyer New Construction", "Pending"],
    },
    {
        path: "/towne-mortgage/buy-side-closed",
        element: React.createElement(TowneMortgageBuySidePage, { view: "closed" }),
        pageName: "Towne Mortgage | Buy Side Closed",
        tags: ["Towne Mortgage", "Buy Side", "Buyer Contract", "Buyer New Construction", "Buy Side Closed"],
    },
    // Integrations
    {
        path: "/integrations/slack",
        element: React.createElement(SlackAuthorizationsPage, null),
        pageName: "Integrations | Slack",
        internalOnly: true,
    },
    {
        path: "/integrations/bridge",
        element: React.createElement(BridgePage, null),
        pageName: "Integrations | Bridge",
        internalOnly: true,
    },
    {
        path: "/integrations/mls-grid",
        element: React.createElement(MLSGrid, null),
        pageName: "Integrations | MLS Grid",
        internalOnly: true,
    },
    {
        path: "/integrations/spark",
        element: React.createElement(Spark, null),
        pageName: "Integrations | Spark",
        internalOnly: true,
    },
    {
        // (calls|routing|blocked-numbers|do-not-call|autoreply|voicemail|on-hold)
        path: "/integrations/twilio/:page",
        element: React.createElement(TwilioWrapper, null),
        internalOnly: true,
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/integrations/twilio",
        redirect: "/integrations/twilio/calls",
        pageName: "Integrations | Twilio",
    },
    {
        path: "/integrations/scripts",
        element: React.createElement(ScriptsPage, null),
        pageName: "Integrations | Scripts",
        roles: ["Superadmin", "Admin"],
        tags: ["Scripts", "Background", "Google"],
    },
    {
        path: "/integrations",
        redirect: "/integrations/slack",
    },
    // CRM
    {
        path: "/emails/:uid",
        element: React.createElement(EmailPage, null),
        internalOnly: true,
    },
    {
        path: "/contacts",
        element: React.createElement(ContactsPage, null),
        pageName: "Contacts",
        internalOnly: true,
    },
    {
        path: "/contacts/:uid",
        element: React.createElement(ContactsFormPage, null),
        internalOnly: true,
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/buyer-leads",
        element: React.createElement(BuyerLeadsPage, null),
        pageName: "Buyer Leads",
        internalOnly: true,
    },
    {
        path: "/buyers/:uid",
        element: React.createElement(BuyerLeadsFormPage, null),
        internalOnly: true,
    },
    {
        path: "/buyer-leads/:uid/mail-schedule",
        element: React.createElement(BuyerLeadsMailSchedulePage, null),
        internalOnly: true,
    },
    {
        path: "/buyer-leads/:uid/mail-schedule/:template_uid/preview",
        element: React.createElement(BuyerLeadsMailSchedulePreviewPage, null),
        internalOnly: true,
    },
    {
        path: "/seller-leads",
        element: React.createElement(SellerLeadsPage, null),
        pageName: "Seller Leads",
        internalOnly: true,
    },
    {
        path: "/sellers/:uid",
        element: React.createElement(SellerLeadsFormPage, null),
        internalOnly: true,
    },
    {
        path: "/seller-leads/:uid/mail-schedule",
        element: React.createElement(SellerLeadsMailSchedulePage, null),
        internalOnly: true,
    },
    {
        path: "/seller-leads/:uid/mail-schedule/:template_uid/preview",
        element: React.createElement(SellerLeadsMailSchedulePreviewPage, null),
        internalOnly: true,
    },
    {
        path: "/buyer-contracts",
        element: React.createElement(BuyerContractsPage, null),
        internalOnly: true,
    },
    {
        path: "/seller-contracts",
        element: React.createElement(SellerContractsPage, null),
        internalOnly: true,
    },
    {
        path: "/buyer-contracts/:uid/mail-schedule",
        element: React.createElement(BuyerContractsMailSchedulePage, null),
        internalOnly: true,
    },
    {
        path: "/buyer-contracts/:uid/mail-schedule/:template_uid/preview",
        element: React.createElement(BuyerContractsMailSchedulePreviewPage, null),
        internalOnly: true,
    },
    {
        path: "/seller-contracts/:uid/mail-schedule",
        element: React.createElement(SellerContractsMailSchedulePage, null),
        internalOnly: true,
    },
    {
        path: "/seller-contracts/:uid/mail-schedule/:template_uid/preview",
        element: React.createElement(SellerContractsMailSchedulePreviewPage, null),
        internalOnly: true,
    },
    {
        path: "/recruits",
        element: React.createElement(RecruitingPage, null),
        internalOnly: true,
    },
    {
        path: "/recruits/:uid",
        element: React.createElement(RecruitingFormPage, null),
        internalOnly: true,
    },
    {
        path: "/views",
        element: React.createElement(ViewsPage, null),
        internalOnly: true,
    },
    {
        path: "/views/:uid",
        element: React.createElement(ViewPage, null),
        internalOnly: true,
    },
    {
        path: "/view-purposes/new",
        element: React.createElement(ViewPurposesFormPage, null),
        internalOnly: true,
    },
    {
        path: "/view-purposes/new",
        element: React.createElement(ViewPurposesFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/view-purposes/:uid",
        element: React.createElement(ViewPurposesFormPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/view-purposes",
        element: React.createElement(ViewPurposesPage, null),
        roles: ["Superadmin", "Admin"],
    },
    {
        path: "/add-threads-to-box",
        element: React.createElement(AddThreadsToBoxPage, null),
        internalOnly: true,
    },
    {
        path: "/my-tasks",
        element: React.createElement(MyTasksPage, null),
        internalOnly: true,
    },
    {
        path: "/office-exclusive",
        element: React.createElement(OfficeExclusivePage, null),
        internalOnly: true,
    },
    // redirects
    {
        path: "/boxes/:uid",
        element: React.createElement(BoxRedirectPage, null),
        internalOnly: true,
    },
    {
        path: "/comments/:uid",
        element: React.createElement(CommentRedirectPage, null),
        internalOnly: true,
    },
    {
        path: "/notifications/:uid",
        element: React.createElement(NotificationRedirectPage, null),
        internalOnly: true,
    },
    // debugging
    {
        path: "request-debugging",
        element: React.createElement(RequestDebuggingPage, null),
        visibility: isDeveloper,
    },
];
export default routesConfig;
