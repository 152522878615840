/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Cancel multiple text messages
 */
export const twilioBulkCancelMessages = (twilioBulkSMSCancelBodyRequest, options) => {
    return axios.post(`/api/twilio/bulk_cancel_messages`, twilioBulkSMSCancelBodyRequest, options);
};
export const getTwilioBulkCancelMessagesMutationOptions = (options) => {
    const mutationKey = ['twilioBulkCancelMessages'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return twilioBulkCancelMessages(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Cancel multiple text messages
*/
export const useTwilioBulkCancelMessages = (options) => {
    const mutationOptions = getTwilioBulkCancelMessagesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Send multiple text messages
*/
export const twilioBulkSendMessages = (twilioBulkSMSBodyRequest, options) => {
    return axios.post(`/api/twilio/bulk_send_messages`, twilioBulkSMSBodyRequest, options);
};
export const getTwilioBulkSendMessagesMutationOptions = (options) => {
    const mutationKey = ['twilioBulkSendMessages'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return twilioBulkSendMessages(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Send multiple text messages
*/
export const useTwilioBulkSendMessages = (options) => {
    const mutationOptions = getTwilioBulkSendMessagesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Get info about the specified call

_Authorization: none required_
* @summary Get call info
*/
export const getCallInfo = (callSid, options) => {
    return axios.get(`/api/twilio/calls/${callSid}`, options);
};
export const getGetCallInfoQueryKey = (callSid) => {
    return [`/api/twilio/calls/${callSid}`];
};
export const getGetCallInfoQueryOptions = (callSid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetCallInfoQueryKey(callSid);
    const queryFn = ({ signal }) => getCallInfo(callSid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(callSid) }, queryOptions);
};
/**
 * @summary Get call info
 */
export function useGetCallInfo(callSid, options) {
    const queryOptions = getGetCallInfoQueryOptions(callSid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Generates a signed URL for uploading media to Twilio

_Authorization: none required_
 * @summary Generate temporary media upload URL
 */
export const generateTemporaryTwilioMediaUploadUrl = (twilioMMSMediaUploadSignedUrlBodyRequest, options) => {
    return axios.post(`/api/twilio/generate_temporary_media_upload_url`, twilioMMSMediaUploadSignedUrlBodyRequest, options);
};
export const getGenerateTemporaryTwilioMediaUploadUrlMutationOptions = (options) => {
    const mutationKey = ['generateTemporaryTwilioMediaUploadUrl'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return generateTemporaryTwilioMediaUploadUrl(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate temporary media upload URL
*/
export const useGenerateTemporaryTwilioMediaUploadUrl = (options) => {
    const mutationOptions = getGenerateTemporaryTwilioMediaUploadUrlMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets the appropriate JWT tokens to place and receive calls from the browser for the current user

_Authorization: none required_
* @summary Get JWT tokens for the current user
*/
export const getMyTwilioVoiceAppToken = (options) => {
    return axios.get(`/api/twilio/get_my_auth_token`, options);
};
export const getGetMyTwilioVoiceAppTokenQueryKey = () => {
    return [`/api/twilio/get_my_auth_token`];
};
export const getGetMyTwilioVoiceAppTokenQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMyTwilioVoiceAppTokenQueryKey();
    const queryFn = ({ signal }) => getMyTwilioVoiceAppToken(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get JWT tokens for the current user
 */
export function useGetMyTwilioVoiceAppToken(options) {
    const queryOptions = getGetMyTwilioVoiceAppTokenQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets a signed URL for sending media via Twilio

_Authorization: none required_
 * @summary Get temporary media download URL
 */
export const getTemporaryTwilioMediaDownloadUrl = (uid, options) => {
    return axios.get(`/api/twilio/get_temporary_media_download_url/${uid}`, options);
};
export const getGetTemporaryTwilioMediaDownloadUrlQueryKey = (uid) => {
    return [`/api/twilio/get_temporary_media_download_url/${uid}`];
};
export const getGetTemporaryTwilioMediaDownloadUrlQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTemporaryTwilioMediaDownloadUrlQueryKey(uid);
    const queryFn = ({ signal }) => getTemporaryTwilioMediaDownloadUrl(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get temporary media download URL
 */
export function useGetTemporaryTwilioMediaDownloadUrl(uid, options) {
    const queryOptions = getGetTemporaryTwilioMediaDownloadUrlQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List media for MMS
 */
export const twilioListMediaForMessage = (messageSid, options) => {
    return axios.get(`/api/twilio/list_media_for_message/${messageSid}`, options);
};
export const getTwilioListMediaForMessageQueryKey = (messageSid) => {
    return [`/api/twilio/list_media_for_message/${messageSid}`];
};
export const getTwilioListMediaForMessageQueryOptions = (messageSid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getTwilioListMediaForMessageQueryKey(messageSid);
    const queryFn = ({ signal }) => twilioListMediaForMessage(messageSid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(messageSid) }, queryOptions);
};
/**
 * @summary List media for MMS
 */
export function useTwilioListMediaForMessage(messageSid, options) {
    const queryOptions = getTwilioListMediaForMessageQueryOptions(messageSid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Retrieves the cached Twilio messaging logs in our database. This is faster and has more robust search features than `GET /api/twilio/messaging_logs`, but results may be slightly out of date.

_Authorization: none required_
 * @summary List messaging logs (cached)
 */
export const listCachedTwilioMessagingLogs = (params, options) => {
    return axios.get(`/api/twilio/logs/messaging_cached`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListCachedTwilioMessagingLogsQueryKey = (params) => {
    return [`/api/twilio/logs/messaging_cached`, ...(params ? [params] : [])];
};
export const getListCachedTwilioMessagingLogsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCachedTwilioMessagingLogsQueryKey(params);
    const queryFn = ({ signal }) => listCachedTwilioMessagingLogs(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List messaging logs (cached)
 */
export function useListCachedTwilioMessagingLogs(params, options) {
    const queryOptions = getListCachedTwilioMessagingLogsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Mark message as read
 */
export const markMessageAsRead = (messageSid, options) => {
    return axios.post(`/api/twilio/messages/${messageSid}/mark_as_read`, undefined, options);
};
export const getMarkMessageAsReadMutationOptions = (options) => {
    const mutationKey = ['markMessageAsRead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { messageSid } = props !== null && props !== void 0 ? props : {};
        return markMessageAsRead(messageSid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Mark message as read
*/
export const useMarkMessageAsRead = (options) => {
    const mutationOptions = getMarkMessageAsReadMutationOptions(options);
    return useMutation(mutationOptions);
};
