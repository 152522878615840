import * as React from "react";
import { KeyboardArrowRight, Phone } from "@mui/icons-material";
import { Grid, IconButton, Link, Typography } from "@mui/material";
import styled from "styled-components";
import twilioIcon from "@app/assets/img/twilio.png";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { formatDateMemoized_Dayjs } from "@app/util/Utils";
const StyledGrid = styled(Grid) `
  .twilio-link {
    visibility: hidden;
  }
  &:hover .twilio-link {
    visibility: visible;
  }
`;
const CallChronologyItem = (props) => {
    var _a;
    const { call } = props;
    const listTwilioPhonesApi = useListCachedTwilioPhones({}, INFINITE_CACHE_PARAMS);
    const twilioPhones = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listTwilioPhonesApi.data]);
    const fromTwilioPhone = React.useMemo(() => {
        if (twilioPhones) {
            return twilioPhones.find((phone) => phone.phone_number === call.from_);
        }
        return null;
    }, [twilioPhones, call.from_]);
    const toTwilioPhone = React.useMemo(() => {
        if (twilioPhones) {
            return twilioPhones.find((phone) => phone.phone_number === call.to);
        }
        return null;
    }, [twilioPhones, call.to]);
    return (React.createElement(StyledGrid, { container: true, style: { width: "100%", border: "1px solid #aaa", borderRadius: "0.25rem", marginLeft: "0.25rem" }, spacing: 2 },
        React.createElement(Grid, { item: true, style: { width: "40px" } },
            React.createElement(Phone, { fontSize: "large" })),
        React.createElement(Grid, { item: true, container: true, spacing: 2, style: { flex: 1 } },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, String((fromTwilioPhone === null || fromTwilioPhone === void 0 ? void 0 : fromTwilioPhone.friendly_name) || call.fromName || call.from_formatted))),
            React.createElement(Grid, { item: true },
                React.createElement(KeyboardArrowRight, null)),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, String((toTwilioPhone === null || toTwilioPhone === void 0 ? void 0 : toTwilioPhone.friendly_name) || call.toName || call.to_formatted))),
            React.createElement(Grid, { item: true, style: { flex: 1 } }),
            React.createElement(Grid, { item: true },
                React.createElement(IconButton, { size: "small", className: "twilio-link", component: Link, href: `https://console.twilio.com/us1/monitor/logs/calls?frameUrl=%2Fconsole%2Fvoice%2Fcalls%2Flogs%2F${call.sid}`, target: "_blank", style: { transform: "translateY(-4px)" } },
                    React.createElement("img", { src: twilioIcon, height: 28, alt: "Twilio" })))),
        React.createElement(Grid, { item: true, style: { width: "120px", textAlign: "right", paddingRight: "0.5rem" } },
            React.createElement(Typography, { variant: "caption", style: { color: "rgb(204, 204, 204)" } }, formatDateMemoized_Dayjs((_a = call.start_time) !== null && _a !== void 0 ? _a : call.date_created, "M/D/YYYY h:mm A")))));
};
export default CallChronologyItem;
