import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import omit from "lodash/omit";
import { useLocation } from "react-router";
import useBrowserBackStack from "@app/hooks/useBrowserBackStack";
import RouterLink from "./RouterLink";
export const BackButton = (props) => {
    const { url } = props;
    const navigate = useNavigate();
    const backStack = useBrowserBackStack();
    const buttonProps = omit(props, "url");
    const goBack = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    if (backStack.length > 0) {
        return (React.createElement(Button, Object.assign({}, buttonProps, { onClick: goBack }), props.children));
    }
    return (
    // @ts-expect-error: component does not exist
    React.createElement(Button, Object.assign({}, buttonProps, { component: RouterLink, to: url }), props.children));
};
export const BackIconButton = (props) => {
    const { url } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const buttonProps = omit(props, "url");
    const goBack = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    if (location.key) {
        return (React.createElement(IconButton, Object.assign({}, buttonProps, { onClick: goBack }), props.children));
    }
    return (
    // @ts-expect-error: component does not exist
    React.createElement(IconButton, Object.assign({}, buttonProps, { component: RouterLink, to: url }), props.children));
};
