// eslint-disable-next-line import/prefer-default-export
export const canEditRecruit = (params) => {
    const { recruit, sessionUser } = params;
    if (!sessionUser) {
        return false;
    }
    if (sessionUser.hasRole(["Superadmin", "Admin"])) {
        return true;
    }
    // collaborators can edit boxes if they've been given edit access
    if (recruit.collaborators.some((x) => {
        return x.user.uid === sessionUser.uid && x.can_edit;
    })) {
        return true;
    }
    return false;
};
