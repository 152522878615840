import * as React from "react";
import { Add, Edit, Delete } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Typography, } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import { withDialog } from "@app/hoc/withDialog";
import API from "@app/util/api";
import AppManager from "@app/util/AppManager";
import BlockedNumbersDialog from "./BlockedNumbersDialog";
class BlockedNumbers extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                items: null,
                error: null,
                isDialogOpen: false,
                selectedItem: null,
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                API.twilioBlockedNumbers.list({
                    onSuccess: (result) => {
                        this.setState({
                            items: result,
                            error: null,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            items: null,
                            error,
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "openBlockedNumbersDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                this.setState((prevState) => ({
                    selectedItem: uid ? prevState.items.find((item) => item.uid === uid) : null,
                    isDialogOpen: true,
                }));
            }
        });
        Object.defineProperty(this, "deleteBlockedNumbersItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "This action cannot be undone.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            items: prevState.items.filter((item) => item.uid !== uid),
                        }));
                        const loadingSnackbar = enqueueSnackbar("Deleting...", { variant: "info", persist: true });
                        API.twilioBlockedNumbers.delete(uid, {
                            onSuccess: () => {
                                enqueueSnackbar("Deleted phone number", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "onSaveComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.refresh();
                this.setState({
                    isDialogOpen: false,
                });
            }
        });
        Object.defineProperty(this, "onCancel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isDialogOpen: false,
                });
            }
        });
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "Twilio", subtitle: "Blocked Numbers" };
        this.refresh();
    }
    componentWillUnmount() {
        AppManager.shared.pageTitle = {};
    }
    render() {
        const { error, isDialogOpen, items, selectedItem } = this.state;
        let content;
        if (error) {
            content = React.createElement(CoreError, { error: error });
        }
        else if (!items) {
            content = null;
        }
        else {
            content = (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Table, { size: "small", stickyHeader: true },
                    React.createElement("colgroup", null,
                        React.createElement("col", { style: { width: "100px" } }),
                        React.createElement("col", null),
                        React.createElement("col", null)),
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { style: { textAlign: "center" } },
                                React.createElement(Tooltip, { title: "Add number" },
                                    React.createElement(IconButton, { size: "small", onClick: this.openBlockedNumbersDialog() },
                                        React.createElement(Add, { fontSize: "small" })))),
                            React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Number"),
                            React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Notes"),
                            React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Forward To"))),
                    React.createElement(TableBody, null, items.map((item) => {
                        var _a, _b;
                        return (React.createElement(TableRow, { key: item.uid },
                            React.createElement(TableCell, { style: { textAlign: "center" } },
                                React.createElement(Tooltip, { title: "Remove number" },
                                    React.createElement(IconButton, { size: "small", onClick: this.deleteBlockedNumbersItem(item.uid) },
                                        React.createElement(Delete, { fontSize: "small" }))),
                                React.createElement(Tooltip, { title: "Edit number" },
                                    React.createElement(IconButton, { size: "small", onClick: this.openBlockedNumbersDialog(item.uid) },
                                        React.createElement(Edit, { fontSize: "small" })))),
                            React.createElement(TableCell, null, item.number),
                            React.createElement(TableCell, null, (_a = item.notes) !== null && _a !== void 0 ? _a : ""),
                            React.createElement(TableCell, null, (_b = item.forward_to) !== null && _b !== void 0 ? _b : "")));
                    })))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Box, { component: Grid, container: true, p: 2, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "caption", display: "block" }, "Use this feature to block spam callers from being routed by the automation."),
                    React.createElement(Typography, { variant: "caption", display: "block" }, "If you provide a \"forward to\" number, the call will be forwarded to that specific number rather than be processed by the automation.")),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Divider, null)),
                content),
            React.createElement(BlockedNumbersDialog, { open: !!isDialogOpen, item: selectedItem, onSaveComplete: this.onSaveComplete, onCancel: this.onCancel })));
    }
}
export default withDialog(BlockedNumbers);
