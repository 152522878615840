import * as React from "react";
import { List, ListItemButton, Paper } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import BuyerContractSearchResult from "./components/BuyerContractSearchResult";
import BuyerLeadSearchResult from "./components/BuyerLeadSearchResult";
import ContactSearchResult from "./components/ContactSearchResult";
import PageSearchResult from "./components/PageSearchResult";
import PSPSearchResult from "./components/PSPSearchResult";
import QFOSearchResult from "./components/QFOSearchResult";
import RecruitSearchResult from "./components/RecruitSearchResult";
import SellerContractSearchResult from "./components/SellerContractSearchResult";
import SellerLeadSearchResult from "./components/SellerLeadSearchResult";
import UserSearchResult from "./components/UserSearchResult";
const SearchResultListItem = (props) => {
    if (props.onClick) {
        return React.createElement(ListItemButton, { onClick: props.onClick }, props.children);
    }
    return React.createElement(ListItemButton, Object.assign({}, props));
};
const SearchResultsList = (props) => {
    const { onClick, onClose, onItemSelected, results } = props;
    const handleItemSelected = React.useCallback((item) => () => {
        onItemSelected(item);
    }, [onItemSelected]);
    return (React.createElement(Paper, { style: {
            position: "absolute",
            width: "100%",
            maxHeight: "250px",
            overflowY: "scroll",
            background: "white",
            zIndex: 100000,
        }, onClick: onClose },
        React.createElement(List, { dense: true, onClick: onClick }, results.map((item) => {
            if (item.page) {
                return (React.createElement(SearchResultListItem, { key: item.page.path, component: RouterLink, to: item.page.path, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(PageSearchResult, { page: item.page })));
            }
            if (item.user) {
                return (React.createElement(SearchResultListItem, { key: item.user.uid, component: RouterLink, to: `/users/${item.user.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(UserSearchResult, { user: item.user })));
            }
            if (item.psp) {
                return (React.createElement(SearchResultListItem, { key: item.psp.uid, component: RouterLink, to: `/matrix/${item.psp.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(PSPSearchResult, { psp: item.psp })));
            }
            if (item.qfo) {
                return (React.createElement(SearchResultListItem, { key: item.qfo.uid, component: RouterLink, to: `/forms/qfo/form/${item.qfo.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(QFOSearchResult, { qfo: item.qfo })));
            }
            if (item.contact) {
                return (React.createElement(SearchResultListItem, { key: item.contact.uid, component: RouterLink, to: `/contacts/${item.contact.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(ContactSearchResult, { contact: item.contact })));
            }
            if (item.buyerLead) {
                return (React.createElement(SearchResultListItem, { key: item.buyerLead.uid, component: RouterLink, to: `/buyers/${item.buyerLead.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(BuyerLeadSearchResult, { buyerLead: item.buyerLead })));
            }
            if (item.sellerLead) {
                return (React.createElement(SearchResultListItem, { key: item.sellerLead.uid, component: RouterLink, to: `/sellers/${item.sellerLead.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(SellerLeadSearchResult, { sellerLead: item.sellerLead })));
            }
            if (item.buyerContract) {
                return (React.createElement(SearchResultListItem, { key: item.buyerContract.uid, component: RouterLink, to: `/buyers/${item.buyerContract.buyer_lead_uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(BuyerContractSearchResult, { buyerContract: item.buyerContract })));
            }
            if (item.sellerContract) {
                return (React.createElement(SearchResultListItem, { key: item.sellerContract.uid, component: RouterLink, to: `/sellers/${item.sellerContract.seller_lead_uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(SellerContractSearchResult, { sellerContract: item.sellerContract })));
            }
            if (item.recruit) {
                return (React.createElement(SearchResultListItem, { key: item.recruit.uid, component: RouterLink, to: `/recruits/${item.recruit.uid}`, onClick: onItemSelected ? handleItemSelected(item) : undefined },
                    React.createElement(RecruitSearchResult, { recruit: item.recruit })));
            }
            return null;
        }))));
};
export default SearchResultsList;
