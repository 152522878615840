import * as React from "react";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { DoneAll, Launch, Search } from "@mui/icons-material";
import { Avatar, Button, Chip, Grid, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import User from "@app/classes/user";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreTextField from "@app/common/CoreTextField";
import { convertUtcDateString } from "@app/common/date-utils";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import DashFixedWidthTableCell from "@app/common/grid/CoreFixedWidthTableCell";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useStateWithCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useCountPsps, useCreatePsp, useListPsps } from "@app/orval/api/psps";
import { useListUsers } from "@app/orval/api/users";
import PSPCertificationDialog from "./PSPCertificationDialog";
const PSPCertificationTable = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const [isTrainingPSPDialogOpen, setIsTrainingPSPDialogOpen] = React.useState(false);
    const [isCreatingMAtrix, setIsCreatingMAtrix] = useStateWithCallback(false);
    const { onSidebarToggle } = props;
    const session = useSession();
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const updateSearch = useUpdateSearch();
    const [searchParams, _] = useSearchParams();
    const search = searchParams.get("search");
    const agent = searchParams.get("agent");
    const limit = searchParams.get("limit") ? numeral(searchParams.get("limit")).value() : 100;
    const offset = searchParams.get("offset") ? numeral(searchParams.get("offset")).value() : 0;
    const ordering = (_a = searchParams.get("ordering")) !== null && _a !== void 0 ? _a : "-created_at";
    const createPspsApi = useCreatePsp();
    const usersListApi = useListUsers();
    const agents = (_c = (_b = usersListApi.data) === null || _b === void 0 ? void 0 : _b.data.filter((user) => {
        return (user.roles.filter((role) => ["Market Analyst", "Producer-Full Time", "Producer-Part Time"].includes(role))
            .length > 0);
    })) !== null && _c !== void 0 ? _c : null;
    const pspQuery = {
        is_training_psp: true,
        created_by_uid: (_e = agent !== null && agent !== void 0 ? agent : (_d = session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.uid) !== null && _e !== void 0 ? _e : undefined,
        search: search !== null && search !== void 0 ? search : undefined,
    };
    const listPspsApi = useListPsps(pickBy(Object.assign(Object.assign({}, pspQuery), { limit,
        offset,
        ordering }), (val) => val !== undefined));
    const psps = (_g = (_f = listPspsApi.data) === null || _f === void 0 ? void 0 : _f.data) !== null && _g !== void 0 ? _g : null;
    const countPspsApi = useCountPsps(pickBy(pspQuery, (val) => val !== undefined));
    const count = (_j = (_h = countPspsApi.data) === null || _h === void 0 ? void 0 : _h.data.count) !== null && _j !== void 0 ? _j : null;
    const loading = [
        {
            label: "Loading agents...",
            status: usersListApi.status,
        },
        {
            label: "Loading PSPs...",
            status: listPspsApi.status,
        },
        {
            label: "Loading PSP count...",
            status: countPspsApi.status,
        },
    ];
    const refresh = React.useCallback(() => {
        listPspsApi.refetch();
        countPspsApi.refetch();
    }, [countPspsApi, listPspsApi]);
    const startTrainingPSP = React.useCallback((uid) => {
        setIsCreatingMAtrix(true, () => {
            createPspsApi
                .mutateAsync({
                data: {
                    training_reference_uid: uid,
                },
            })
                .then((result) => {
                navigate(`/matrix/${result.data.uid}`);
            })
                .catch((error) => {
                errorDialog(error);
            })
                .then(() => {
                setIsCreatingMAtrix(false);
                // close dialog?
            });
        });
    }, [createPspsApi, errorDialog, navigate, setIsCreatingMAtrix]);
    const openTrainingPSPDialog = React.useCallback(() => {
        setIsTrainingPSPDialogOpen(true);
    }, []);
    const onTrainingPSPDialogClose = React.useCallback(() => {
        setIsTrainingPSPDialogOpen(false);
    }, []);
    const onSearchKeyDown = React.useCallback((e) => {
        if (e.key.toLowerCase().trim() === "enter") {
            updateSearch("search", e.target.value.trim(), "offset", null);
            e.preventDefault();
        }
    }, [updateSearch]);
    const onSearchUpdate = React.useCallback((text) => {
        updateSearch("search", text.trim(), "offset", null);
    }, [updateSearch]);
    const setAgent = React.useCallback((agentUid) => {
        updateSearch("agent", agentUid ? agentUid.trim() : null);
    }, [updateSearch]);
    const openFeedbackDialog = React.useCallback((uid) => () => {
        onSidebarToggle((psps !== null && psps !== void 0 ? psps : []).find((psp) => psp.uid === uid));
    }, [onSidebarToggle, psps]);
    if (loading.find((x) => ["pending", "fetching"].includes(x.status)) || !agents || !psps) {
        return React.createElement(Loading, { items: loading });
    }
    const rowsPerPage = limit;
    const page = Math.floor(offset / limit);
    const data = psps.map((psp) => (Object.assign(Object.assign(Object.assign({}, psp), psp.fields), { training_feedback: psp.feedback.length > 0 ? psp.feedback.slice(-1)[0].feedback : null, training_submitted_at: psp.training_submissions.length > 0 ? psp.training_submissions.slice(-1)[0].timestamp : null, training_success: psp.training_submissions.length > 0 ? psp.training_submissions.slice(-1)[0].success : null })));
    const userIsMAtrixAdmin = !!((_k = session.viewingAsUser) === null || _k === void 0 ? void 0 : _k.hasRole("Superadmin")) ||
        !!((_l = session.viewingAsUser) === null || _l === void 0 ? void 0 : _l.hasRole("Admin")) ||
        !!((_m = session.viewingAsUser) === null || _m === void 0 ? void 0 : _m.hasRole("Market Analyst"));
    const columns = [
        {
            name: "uid",
            label: " ",
            options: {
                sort: false,
                download: false,
                viewColumns: false,
                display: true,
                customHeadRender: ({ index }) => React.createElement(DashFixedWidthTableCell, { key: index, width: "35px" }),
                customBodyRender: (value, tableMeta) => {
                    const matrix = psps[tableMeta.rowIndex];
                    return (React.createElement(Tooltip, { title: "Open" },
                        React.createElement("span", null,
                            React.createElement(IconButton, { size: "small", component: RouterLink, to: `/matrix/${value}`, disabled: !!matrix.archived },
                                React.createElement(Launch, { fontSize: "small" })))));
                },
            },
        },
        {
            name: "Property Address",
            label: "Address",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const psp = psps[tableMeta.rowIndex];
                    return (React.createElement(Grid, { container: true, spacing: 1, style: { overflowY: "hidden", margin: "0px" }, wrap: "nowrap" },
                        React.createElement(Grid, { item: true }, psp.archived && React.createElement(Chip, { label: "Archived", size: "small" })),
                        React.createElement(Grid, { item: true, style: { flexGrow: 1, marginTop: "auto", marginBottom: "auto" } }, value)));
                },
            },
        },
        {
            name: "created_at",
            label: "Created",
            options: {
                customBodyRender: (value, tableMeta) => {
                    var _a;
                    const createdBy = psps[tableMeta.rowIndex].created_by;
                    const user = createdBy ? new User(createdBy) : null;
                    return (React.createElement(Grid, { container: true, spacing: 1, style: { overflowY: "hidden", margin: "0px" }, wrap: "nowrap" },
                        user && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: user.name },
                                React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "32px", width: "32px", fontSize: "0.875rem" } }, user.initials !== "" ? user.initials : "?")))),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", flexGrow: 1 } }, convertUtcDateString(value, { format: "dddd, MMMM D, YYYY h:mm A" }))));
                },
            },
        },
        {
            name: "training_submitted_at",
            label: "Submitted",
            options: {
                sort: false,
                customBodyRender: (value) => value ? convertUtcDateString(value, { format: "dddd, MMMM D, YYYY h:mm A" }) : value,
            },
        },
        {
            name: "training_success",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    if (value === true) {
                        return "Success";
                    }
                    if (value === false) {
                        return "Fail";
                    }
                    return "In Progress";
                },
            },
        },
        {
            name: "training_feedback",
            label: "Feedback",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const psp = psps[tableMeta.rowIndex];
                    if (psp.training_submissions.length === 0) {
                        return null;
                    }
                    if (value) {
                        const cutValue = value.length > 50 ? `${value.substring(0, 50)}...` : value;
                        return (React.createElement("a", { href: "javascript:void(0)", onClick: openFeedbackDialog(psp.uid) }, cutValue));
                    }
                    if (userIsMAtrixAdmin) {
                        return (React.createElement(Button, { size: "small", variant: "contained", color: "primary", onClick: openFeedbackDialog(psp.uid) }, "Leave Feedback..."));
                    }
                    return value;
                },
            },
        },
    ];
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        pagination: true,
        viewColumns: false,
        search: false,
        serverSide: true,
        count: count,
        page,
        rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        onChangePage: (currentPage) => {
            updateSearch("offset", rowsPerPage * currentPage);
        },
        onColumnSortChange: (changedColumn) => {
            updateSearch("ordering", ordering === changedColumn ? `-${changedColumn}` : changedColumn);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            updateSearch("limit", numberOfRows);
        },
        customToolbar: () => {
            var _a;
            return (React.createElement(Grid, { container: true, spacing: 2, justifyContent: "flex-end" },
                ((_a = session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin", "Market Analyst"])) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, style: { width: "200px", paddingLeft: "1rem", textAlign: "left" } },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Agent", size: "small", fullWidth: true, onChoiceChange: setAgent, value: agent, style: {
                                transform: "translateY(-6px)",
                            }, SelectProps: {
                                displayEmpty: true,
                            }, InputLabelProps: {
                                shrink: true,
                            } },
                            React.createElement(MenuItem, { value: "" }, "Me"),
                            agents
                                .filter((_agent) => { var _a; return _agent.uid !== ((_a = session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid); })
                                .map((_agent) => (React.createElement(MenuItem, { key: _agent.uid, value: _agent.uid }, [_agent.first_name, _agent.last_name].filter((obj) => obj).join(" ")))))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }))),
                data.filter((submission) => submission.training_success).length >= 5 && (React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(DoneAll, { color: "primary" })),
                        React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { color: "primary" }, "PSP Certification Complete"))))),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { color: "inherit", onClick: openTrainingPSPDialog, size: "small", disabled: isCreatingMAtrix, variant: "contained" }, "Start new training PSP")),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { color: "inherit", onClick: refresh, size: "small", variant: "contained" }, "Refresh"))));
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CoreDataTable, { title: React.createElement(Grid, { container: true, spacing: 1, alignItems: "flex-end" },
                React.createElement(Grid, { item: true },
                    React.createElement(Search, null)),
                React.createElement(Grid, { item: true, style: { flexGrow: 1 } },
                    React.createElement(CoreTextField, { variant: "standard", fullWidth: true, autoFocus: true, style: { maxWidth: "500px" }, onTextChange: onSearchUpdate, onKeyPress: onSearchKeyDown, value: search !== null && search !== void 0 ? search : "" }))), data: data, columns: columns, options: options, cellPadding: 0 }),
        isTrainingPSPDialogOpen && (React.createElement(PSPCertificationDialog, { onConfirm: startTrainingPSP, onCancel: onTrainingPSPDialogClose }))));
};
export default PSPCertificationTable;
