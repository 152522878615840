/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all items
 */
export const listSuggestedPriceReductionScheduleItems = (options) => {
    return axios.get(`/api/price_reduction_schedule`, options);
};
export const getListSuggestedPriceReductionScheduleItemsQueryKey = () => {
    return [`/api/price_reduction_schedule`];
};
export const getListSuggestedPriceReductionScheduleItemsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSuggestedPriceReductionScheduleItemsQueryKey();
    const queryFn = ({ signal }) => listSuggestedPriceReductionScheduleItems(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all items
 */
export function useListSuggestedPriceReductionScheduleItems(options) {
    const queryOptions = getListSuggestedPriceReductionScheduleItemsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create an item
 */
export const createSuggestedPriceReductionScheduleItem = (priceReductionRequest, options) => {
    return axios.post(`/api/price_reduction_schedule`, priceReductionRequest, options);
};
export const getCreateSuggestedPriceReductionScheduleItemMutationOptions = (options) => {
    const mutationKey = ['createSuggestedPriceReductionScheduleItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createSuggestedPriceReductionScheduleItem(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create an item
*/
export const useCreateSuggestedPriceReductionScheduleItem = (options) => {
    const mutationOptions = getCreateSuggestedPriceReductionScheduleItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get an item
*/
export const getSuggestedPriceReductionScheduleItem = (uid, options) => {
    return axios.get(`/api/price_reduction_schedule/${uid}`, options);
};
export const getGetSuggestedPriceReductionScheduleItemQueryKey = (uid) => {
    return [`/api/price_reduction_schedule/${uid}`];
};
export const getGetSuggestedPriceReductionScheduleItemQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSuggestedPriceReductionScheduleItemQueryKey(uid);
    const queryFn = ({ signal }) => getSuggestedPriceReductionScheduleItem(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an item
 */
export function useGetSuggestedPriceReductionScheduleItem(uid, options) {
    const queryOptions = getGetSuggestedPriceReductionScheduleItemQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update an item
 */
export const updateSuggestedPriceReductionScheduleItem = (uid, priceReductionRequest, options) => {
    return axios.post(`/api/price_reduction_schedule/${uid}`, priceReductionRequest, options);
};
export const getUpdateSuggestedPriceReductionScheduleItemMutationOptions = (options) => {
    const mutationKey = ['updateSuggestedPriceReductionScheduleItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSuggestedPriceReductionScheduleItem(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an item
*/
export const useUpdateSuggestedPriceReductionScheduleItem = (options) => {
    const mutationOptions = getUpdateSuggestedPriceReductionScheduleItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete an item
*/
export const deleteSuggestedPriceReductionScheduleItem = (uid, options) => {
    return axios.delete(`/api/price_reduction_schedule/${uid}`, options);
};
export const getDeleteSuggestedPriceReductionScheduleItemMutationOptions = (options) => {
    const mutationKey = ['deleteSuggestedPriceReductionScheduleItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteSuggestedPriceReductionScheduleItem(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete an item
*/
export const useDeleteSuggestedPriceReductionScheduleItem = (options) => {
    const mutationOptions = getDeleteSuggestedPriceReductionScheduleItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download a list of suggested price reductions as a CSV
*/
export const downloadSuggestedPriceReductionSchedule = (options) => {
    return axios.get(`/api/price_reduction_schedule/download`, options);
};
export const getDownloadSuggestedPriceReductionScheduleQueryKey = () => {
    return [`/api/price_reduction_schedule/download`];
};
export const getDownloadSuggestedPriceReductionScheduleQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadSuggestedPriceReductionScheduleQueryKey();
    const queryFn = ({ signal }) => downloadSuggestedPriceReductionSchedule(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download a list of suggested price reductions as a CSV
 */
export function useDownloadSuggestedPriceReductionSchedule(options) {
    const queryOptions = getDownloadSuggestedPriceReductionScheduleQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
