import * as React from "react";
import { Phone } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import PhoneMenu from "@app/common/links/PhoneMenu";
import { formatPhoneNumber, isValidPhoneNumber } from "@app/util/Utils";
const PhoneContactButton = (props) => {
    const { name, phoneNumber } = props;
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const openMenu = React.useCallback((e) => {
        setMenuAnchorEl(e.currentTarget);
    }, []);
    const closeMenu = React.useCallback(() => {
        setMenuAnchorEl(null);
    }, []);
    const phoneNumberFormatted = phoneNumber && isValidPhoneNumber(phoneNumber)
        ? formatPhoneNumber(phoneNumber, { country: "US", format: "international" })
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: openMenu, size: "small", disabled: !phoneNumberFormatted },
            React.createElement(Phone, { fontSize: "small" })),
        menuAnchorEl && (React.createElement(PhoneMenu, { MenuProps: {
                anchorEl: menuAnchorEl,
                open: true,
                onClose: closeMenu,
                onClick: closeMenu,
            }, contacts: [{ name, phone: phoneNumberFormatted }] }))));
};
export default PhoneContactButton;
