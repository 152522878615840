import * as React from "react";
import { IconButton } from "@mui/material";
import ConversationScheduleMessageDialog from "./ConversationScheduleMessageDialog";
const ScheduleMessageButton = (props) => {
    const { IconButtonProps, children, onDateTimeSelected } = props;
    const [isScheduleMessagePopupOpen, setIsScheduleMessagePopupOpen] = React.useState(false);
    const openScheduleMessagePopup = React.useCallback(() => {
        setIsScheduleMessagePopupOpen(true);
    }, []);
    const closeScheduleMessagePopup = React.useCallback(() => {
        setIsScheduleMessagePopupOpen(false);
    }, []);
    const handleDateTimeSelected = React.useCallback((datetime) => {
        onDateTimeSelected(datetime);
        closeScheduleMessagePopup();
    }, [onDateTimeSelected, closeScheduleMessagePopup]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, Object.assign({ tabIndex: -1, onClick: openScheduleMessagePopup }, IconButtonProps), children),
        React.createElement(ConversationScheduleMessageDialog, { DialogProps: { open: isScheduleMessagePopupOpen }, onCancel: closeScheduleMessagePopup, onDateTimeSelected: handleDateTimeSelected })));
};
export default ScheduleMessageButton;
