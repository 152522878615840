import * as React from "react";
import { Close, Send } from "@mui/icons-material";
import { Grid, Button, MenuItem, DialogActions, DialogContent, DialogTitle, TextField, Skeleton, Stack, Box, Typography, FormControlLabel, Checkbox, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useGetBuyerLead } from "@app/orval/api/buyer-leads";
import { useListNmsMlsAreas, useListNmsRequests, useListNmsRequestTypes, useSubmitNmsRequest, } from "@app/orval/api/nms-requests";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { validateEmail } from "@app/util/Utils";
const NMSRequestForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { DialogProps, buyerLeadUid, buyerPropertyUid, onClose } = props;
    const errorDialog = useErrorDialog();
    const [formData, setFormData] = React.useState(null);
    const listRequestTypesApi = useListNmsRequestTypes(INFINITE_CACHE_PARAMS);
    const requestTypes = (_b = (_a = listRequestTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listMlsAreasApi = useListNmsMlsAreas(INFINITE_CACHE_PARAMS);
    const mlsAreas = (_d = (_c = listMlsAreasApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const getBuyerLeadApi = useGetBuyerLead(buyerLeadUid);
    const submitRequestApi = useSubmitNmsRequest();
    const propertyAddress = React.useMemo(() => {
        var _a, _b, _c;
        return (_c = (_b = (_a = getBuyerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data.properties.find((x) => x.uid === buyerPropertyUid)) === null || _b === void 0 ? void 0 : _b.property_address) !== null && _c !== void 0 ? _c : null;
    }, [buyerPropertyUid, getBuyerLeadApi.data]);
    const queryClient = useQueryClient();
    const listNMSRequestsApi = useListNmsRequests({ buyer_property_uid: buyerPropertyUid, is_claimed: false }, { query: { enabled: false } });
    const loading = useLoading({
        items: [
            {
                label: "Loading request types...",
                queryResult: listRequestTypesApi,
            },
            {
                label: "Loading MLS areas...",
                queryResult: listMlsAreasApi,
            },
        ],
    });
    React.useEffect(() => {
        setFormData(null);
        getBuyerLeadApi.refetch().then((result) => {
            var _a, _b, _c;
            setFormData({
                request_type: "Showing Only",
                mls_area: undefined,
                preferred_showing_date_and_time: undefined,
                listing_agent_email: (_c = (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data.vendors.find((x) => x.type === "Listing Agent")) === null || _b === void 0 ? void 0 : _b.contact.email) !== null && _c !== void 0 ? _c : undefined,
                listing_agent_approved: false,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyerLeadUid]);
    const submit = React.useCallback(() => {
        submitRequestApi
            .mutateAsync({
            data: {
                buyer_property_uid: buyerPropertyUid,
                request_type: formData.request_type,
                mls_area: formData.mls_area,
                preferred_showing_date_and_time: formData.preferred_showing_date_and_time,
                listing_agent_email: formData.listing_agent_email.trim(),
                listing_agent_approved: formData.listing_agent_approved,
            },
        })
            .then((result) => {
            onClose();
            enqueueSnackbar("NMS request submitted successfully.", { variant: "success" });
            if (listNMSRequestsApi.data) {
                queryClient.setQueryData(listNMSRequestsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
                });
            }
        })
            .catch(errorDialog);
    }, [
        buyerPropertyUid,
        errorDialog,
        formData,
        listNMSRequestsApi.data,
        listNMSRequestsApi.queryKey,
        onClose,
        queryClient,
        submitRequestApi,
    ]);
    const isSubmitting = submitRequestApi.isPending;
    const isListingAgentEmailValid = React.useMemo(() => {
        var _a;
        return formData ? validateEmail((_a = formData.listing_agent_email) !== null && _a !== void 0 ? _a : "") : false;
    }, [formData]);
    const isValid = React.useMemo(() => {
        var _a;
        return ((formData === null || formData === void 0 ? void 0 : formData.request_type) &&
            formData.mls_area &&
            formData.preferred_showing_date_and_time &&
            !!((_a = formData.listing_agent_email) === null || _a === void 0 ? void 0 : _a.trim()) &&
            !!validateEmail(formData.listing_agent_email));
    }, [formData]);
    const setRequestType = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { request_type: val ? val : undefined })));
    }, [setFormData]);
    const setMlsArea = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { mls_area: val ? val : undefined })));
    }, [setFormData]);
    const setPreferredShowingDateAndTime = React.useCallback((dt) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { preferred_showing_date_and_time: dt || undefined })));
    }, [setFormData]);
    const setListingAgentEmail = React.useCallback((e) => {
        const val = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_agent_email: val })));
    }, [setFormData]);
    const setListingAgentApproved = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_agent_approved: checked })));
    }, [setFormData]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "md" }, DialogProps, { open: DialogProps.open, PaperProps: {
            style: Object.assign({ minWidth: "500px" }, (_e = DialogProps.PaperProps) === null || _e === void 0 ? void 0 : _e.style),
        } }),
        React.createElement(DialogTitle, null, ["NMS Request Form", propertyAddress].filter((o) => o).join(": ")),
        React.createElement(DialogContent, null,
            loading.isLoading && loading.loadingComponent && loading.loadingComponent,
            formData ? (React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", spacing: 4 },
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Request Type", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_f = formData.request_type) !== null && _f !== void 0 ? _f : "", onChange: setRequestType },
                            React.createElement(MenuItem, { value: "" }),
                            (requestTypes !== null && requestTypes !== void 0 ? requestTypes : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "MLS Area", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_g = formData.mls_area) !== null && _g !== void 0 ? _g : "", onChange: setMlsArea },
                            React.createElement(MenuItem, { value: "" }),
                            (mlsAreas !== null && mlsAreas !== void 0 ? mlsAreas : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(CoreDateField, { label: "Preferred Showing Date & Time", format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", mode: "datetime", date: (_h = formData.preferred_showing_date_and_time) !== null && _h !== void 0 ? _h : null, onDateChange: setPreferredShowingDateAndTime, size: "small", fullWidth: true, 
                                    // @ts-expect-error: not assignable to type 'standard'
                                    variant: "outlined", hasPicker: true })),
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Typography, { style: { color: "#999" } }, "Eastern time")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Listing Agent Email", slotProps: {
                                htmlInput: {
                                    maxLength: 255,
                                },
                            }, fullWidth: true, variant: "outlined", size: "small", value: (_j = formData.listing_agent_email) !== null && _j !== void 0 ? _j : "", onChange: setListingAgentEmail, helperText: isListingAgentEmailValid || !formData.listing_agent_email
                                ? undefined
                                : "Must enter a valid email address" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(FormControlLabel, { label: "Listing Agent Approved", control: React.createElement(Checkbox, { checked: formData.listing_agent_approved, onChange: setListingAgentApproved, color: "primary" }) }))))) : (React.createElement(Stack, null,
                React.createElement(Skeleton, null),
                React.createElement(Skeleton, null),
                React.createElement(Skeleton, null)))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { startIcon: React.createElement(Close, null), variant: "text", onClick: onClose, disabled: isSubmitting }, "Cancel"),
            React.createElement(Button, { startIcon: React.createElement(Send, null), variant: "contained", onClick: submit, disabled: isSubmitting || !isValid }, "Submit"))));
};
export default NMSRequestForm;
