import * as React from "react";
import { Phone, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Grid2, Paper, Typography } from "@mui/material";
const IncomingCall = (props) => {
    const { call } = props;
    const [isDeclined, setIsDeclined] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);
    const toggleShowMore = React.useCallback(() => {
        setShowMore((prev) => !prev);
    }, []);
    const accept = React.useCallback(() => {
        call.accept();
    }, [call]);
    const decline = React.useCallback(() => {
        call.ignore();
        setIsDeclined(true);
    }, [call]);
    if (isDeclined) {
        return null;
    }
    return (React.createElement(Paper, { elevation: 4, style: { pointerEvents: "auto" } },
        React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1, direction: "row" },
                        React.createElement(Grid2, null,
                            React.createElement(Phone, null)),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, "Incoming Call")))),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "row", spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "From:")),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1" }, call.parameters["From"])))),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "row", spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "To:")),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1" }, call.parameters["To"])))),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "column", spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid2, null,
                            React.createElement(Button, { variant: "text", onClick: toggleShowMore }, showMore ? "Show less" : "Show more")),
                        React.createElement(Grid2, null,
                            React.createElement(Collapse, { in: showMore },
                                React.createElement("pre", { style: { color: "#888" } }, JSON.stringify(call.parameters, null, 2)),
                                React.createElement("pre", { style: { color: "#888" } }, JSON.stringify(call.customParameters, null, 2)))))),
                React.createElement(Grid2, null,
                    React.createElement(Divider, null)),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "row", spacing: 1 },
                        React.createElement(Grid2, { style: { flex: 1 } }),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", color: "primary", variant: "contained", startIcon: React.createElement(ThumbUp, { fontSize: "small" }), onClick: accept }, "Accept")),
                        React.createElement(Grid2, { style: { flex: 1 } }),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", color: "error", variant: "contained", startIcon: React.createElement(ThumbDown, { fontSize: "small" }), onClick: decline }, "Decline")),
                        React.createElement(Grid2, { style: { flex: 1 } })))))));
};
export default IncomingCall;
