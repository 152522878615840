import * as React from "react";
import { Box, Divider, Grid2, Typography, MenuItem, TextField, Autocomplete } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import MAtrixFormFieldLabel from "@app/entrypoints/brokerage/features/apps/matrix/components/FormFieldLabel";
import FormRow from "@app/entrypoints/brokerage/features/apps/matrix/components/FormRow";
import PSPTabSection from "@app/entrypoints/brokerage/features/apps/matrix/components/PSPTabSection";
import { DEFAULT_LABEL_STYLE } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import useSession from "@app/hooks/useSession";
import { updatePsp } from "@app/orval/api/psps";
import { getUserName, defaultUserIcon, getBrokerageFeeForMarket } from "@app/util/Utils";
import HouseCanary from "./HouseCanary";
const PSP_FIELDS_DEFAULT_GRID_SIZE = 5;
/**
 * Alerts user if psp type is 'land' or 'new construction'
 */
const verifyPSPType = (type) => {
    if (type && ["land", "new construction"].includes(type.toLowerCase().trim())) {
        enqueueSnackbar("PSP does not work for land or new construction. Run a quick CMA and use either the Land CMA or New Construction CMA snippet.", { variant: "info" });
    }
};
const renderAgentDropdownOption = (props, option) => {
    var _a;
    const rowStyle = {
        overflow: "hidden !important",
        height: "10px",
        fontSize: "10px",
    };
    return (React.createElement(MenuItem, Object.assign({}, props, { value: option ? `agent_dropdown_option_${option.uid}` : "agent_dropdown_option_blank" }),
        React.createElement(Grid2, { container: true, spacing: 2 }, option != null && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, { size: 3 },
                React.createElement(Box, null,
                    React.createElement("img", { alt: "", src: (_a = option.icon_url) !== null && _a !== void 0 ? _a : defaultUserIcon, width: "30px", height: "30px", style: {
                            margin: 0,
                            borderRadius: "50%",
                        } }))),
            React.createElement(Grid2, { size: 9 },
                React.createElement(Grid2, { size: 12, style: rowStyle }, `${option.first_name} ${option.last_name}`.trim()),
                React.createElement(Grid2, { size: 12, style: rowStyle }, option.email),
                React.createElement(Grid2, { size: 12, style: rowStyle }, option.phone)))))));
};
const renderAgentSearchInput = (params) => (React.createElement(TextField, Object.assign({}, params, { variant: "standard", label: "", size: "small" })));
const PSPTab = (props) => {
    var _a;
    const { matrixFields, matrixSchema, noRPR, onFieldSaveBegin, onFieldSaveComplete, pspType, uid, users } = props;
    const session = useSession();
    React.useEffect(() => {
        verifyPSPType(pspType);
    }, [pspType]);
    const agents = React.useMemo(() => {
        const agentUid = matrixFields["Agent ID"];
        return (users !== null && users !== void 0 ? users : [])
            .filter((user) => {
            var _a;
            const allowedRole = user.roles.includes("Agent") ||
                user.roles.includes("Producer-Full Time") ||
                user.roles.includes("Producer-Part Time");
            const allowedEmail = ["justin@dashnc.com"].includes(((_a = user.email) !== null && _a !== void 0 ? _a : "").toLowerCase().trim());
            return allowedRole || user.uid === agentUid || allowedEmail;
        })
            .sort((a, b) => {
            const aName = [a.first_name, a.last_name].filter((o) => o).join(" ");
            const bName = [b.first_name, b.last_name].filter((o) => o).join(" ");
            if (aName < bName)
                return -1;
            if (aName > bName)
                return 1;
            return 0;
        });
    }, [matrixFields, users]);
    const rprRows = React.useMemo(() => {
        return [
            {
                name: "Weighted Avg",
                fields: [{ name: "RPR Weighted Avg", multiplyBy100: true }],
            },
            {
                name: "No RPR",
                fields: [{ name: "No RPR" }],
                labelStyle: Object.assign(Object.assign({}, DEFAULT_LABEL_STYLE), { lineHeight: "35px" }),
            },
            {
                name: "RPR Value",
                fields: [{ name: "RPR Value" }],
                hidden: noRPR,
            },
            {
                name: "RPR Low",
                fields: [{ name: "RPR Low" }],
                hidden: noRPR,
            },
            {
                name: "RPR High",
                fields: [{ name: "RPR High" }],
                hidden: noRPR,
            },
        ];
    }, [noRPR]);
    const getSectionRows = React.useCallback((rows) => {
        return rows.map((row) => {
            var _a;
            return (React.createElement(FormRow, { key: `psp_row_${row.name}`, uid: uid, rowData: Object.assign(Object.assign({}, row), { fields: row.fields.map((field) => (Object.assign(Object.assign({}, field), { value: matrixFields[field.name] }))) }), 
                // matrixFields={matrixFields}
                schema: matrixSchema, labelSize: (_a = row.labelSize) !== null && _a !== void 0 ? _a : PSP_FIELDS_DEFAULT_GRID_SIZE, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete }));
        });
    }, [uid, matrixFields, matrixSchema, onFieldSaveBegin, onFieldSaveComplete]);
    const onAgentFieldChange = React.useCallback((_, agent, reason) => {
        // use formstore update
        const newValue = agent == null ? null : agent.uid;
        updatePsp(uid, {
            fields: { "Agent ID": reason === "selectOption" ? newValue : null },
        })
            .then(() => {
            onFieldSaveBegin("Agent ID")(newValue);
        })
            .catch((error) => {
            enqueueErrorSnackbar(error, { label: "There was an error updating the Agent field" });
        });
    }, [onFieldSaveBegin, uid]);
    const onMarketChange = React.useCallback((market) => {
        onFieldSaveBegin("Market")(market);
        const brokerageFee = getBrokerageFeeForMarket(market);
        const fields = {
            "Net Sheet - Brokerage Fee": brokerageFee,
        };
        updatePsp(uid, {
            fields,
        })
            .then(() => {
            onFieldSaveBegin("Net Sheet - Brokerage Fee")(brokerageFee);
        })
            .catch((error) => {
            enqueueErrorSnackbar("There was an error updating the `Net Sheet - Brokerage Fee` field: ", error);
        });
    }, [onFieldSaveBegin, uid]);
    const noRedfin = React.useMemo(() => {
        return matrixFields["No Redfin"];
    }, [matrixFields]);
    const market = React.useMemo(() => {
        return matrixFields["Market"];
    }, [matrixFields]);
    const noCA = React.useMemo(() => {
        return matrixFields["No CA"];
    }, [matrixFields]);
    const agentUid = React.useMemo(() => {
        return matrixFields["Agent ID"];
    }, [matrixFields]);
    const agentInformationRows = React.useMemo(() => {
        return [
            {
                name: "agent_dropdown",
                fields: [],
                customLayout: (React.createElement(React.Fragment, null,
                    React.createElement(MAtrixFormFieldLabel, { name: "Agent", gridSize: 5, labelStyle: DEFAULT_LABEL_STYLE }),
                    React.createElement(Grid2, { key: "AgentID_field", size: 7 },
                        React.createElement(Autocomplete, { disableClearable: true, value: agents.find((agent) => agent.uid === agentUid), forcePopupIcon: false, options: [null, ...agents], noOptionsText: "No agents found", getOptionLabel: getUserName, style: { fontSize: "11px", paddingRight: "8px" }, 
                            // debug
                            renderInput: renderAgentSearchInput, renderOption: renderAgentDropdownOption, onChange: onAgentFieldChange })))),
            },
            {
                name: "Share With Agent",
                fields: [{ name: "Share With Agent" }],
                labelStyle: Object.assign(Object.assign({}, DEFAULT_LABEL_STYLE), { lineHeight: "35px" }),
            },
        ];
    }, [agentUid, agents, onAgentFieldChange]);
    const mlsDataRows = React.useMemo(() => {
        return [
            {
                name: "Market",
                fields: [{ name: "Market", onSaveBegin: onMarketChange }],
            },
            {
                name: "County",
                fields: [{ name: "County" }],
            },
            {
                name: "PSP Type",
                fields: [{ name: "PSP Type", onSaveBegin: verifyPSPType }],
            },
            {
                name: "Initial List Price",
                fields: [{ name: "Original List Price" }],
                hidden: pspType === "Listing",
            },
            {
                name: "Current List Price",
                fields: [{ name: "Current List Price" }],
                hidden: pspType === "Listing",
            },
            {
                name: "Days on Market",
                fields: [{ name: "DOM" }],
                hidden: pspType === "Listing",
            },
            {
                name: "Square Footage",
                fields: [{ name: "Finished SqFt" }],
            },
            {
                name: "Finished Basement SqFt",
                fields: [{ name: "Basement Finished SqFt" }],
            },
            {
                name: "Unfinished Basement SqFt",
                fields: [{ name: "Basement Unfinished SqFt" }],
            },
            {
                name: "HOA Dues/M",
                fields: [
                    { name: "HOA Dues/M", size: 2 },
                    { name: "Subdivision HOA Time Period", size: 5 },
                ],
            },
            {
                name: "Flood Plain",
                fields: [{ name: "Flood Zone" }],
            },
            {
                name: "Flight Path",
                fields: [{ name: "Flight Path" }],
            },
            {
                name: "I-540 Impact",
                fields: [{ name: "I-540 Impact" }],
                hidden: market && market.toLowerCase().trim() !== "rdu",
            },
            {
                name: "I-26 or St 129 Rt Impact",
                fields: [{ name: "I-26 or St Rt 129 Impact" }],
                hidden: market && market.toLowerCase().trim() !== "avl",
            },
        ];
    }, [market, onMarketChange, pspType]);
    const mlsSQFTSearchRangesRows = React.useMemo(() => {
        return [
            {
                name: "mls_sqft_search_ranges",
                fields: [],
                customLayout: (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { size: 12, style: { marginTop: "16px !important", marginBottom: "8px !important" } },
                        React.createElement(Divider, null)),
                    ["", "Low", "High"].map((item) => (React.createElement(MAtrixFormFieldLabel, { key: `mls_sqft_search_ranges_table_header_${item}`, name: item, gridSize: 4, labelStyle: {
                            fontWeight: "bold",
                        } }))))),
            },
            ...["10", "15", "20"].map((pcnt) => ({
                name: `Total SqFt (${pcnt}%)`,
                labelSize: 4,
                divider: true,
                dividerPlacement: "above",
                fields: [
                    { name: `Total SqFt ${pcnt} Percent MLS Search Range Low` },
                    { name: `Total SqFt ${pcnt} Percent MLS Search Range High` },
                ],
            })),
        ];
    }, []);
    const crsRealistTaxDataRows = React.useMemo(() => {
        return [
            {
                name: "Weighed Avg",
                fields: [{ name: "Realist/Tax Data Weighted Avg", multiplyBy100: true }],
            },
            {
                name: "Total Tax Appraisal",
                fields: [{ name: "Tax Value" }],
            },
            {
                name: "Total Taxes",
                fields: [{ name: "Yearly Tax Bill" }],
            },
            {
                name: "Square Feet",
                fields: [{ name: "Above Grade" }],
            },
            {
                name: "Sold Price",
                fields: [{ name: "Tax Record Purchase Price" }],
            },
            {
                name: "Closing Year",
                fields: [{ name: "Tax Record Year Sold" }],
            },
        ];
    }, []);
    const collateralAnalyticsRows = React.useMemo(() => {
        return [
            {
                name: "Weighted Avg",
                fields: [{ name: "Collateral Analytics Weighted Avg", multiplyBy100: true }],
            },
            {
                name: "No CA",
                fields: [{ name: "No CA" }],
                labelStyle: Object.assign(Object.assign({}, DEFAULT_LABEL_STYLE), { lineHeight: "35px" }),
            },
            {
                name: "Collateral Analytics Value",
                fields: [{ name: "Collateral Analytics Value" }],
                hidden: noCA,
            },
            {
                name: "Collateral Analytics Low",
                fields: [{ name: "Collateral Analytics Low" }],
                hidden: noCA,
            },
            {
                name: "Collateral Analytics High",
                fields: [{ name: "Collateral Analytics High" }],
                hidden: noCA,
            },
        ];
    }, [noCA]);
    const thirdPartyRows = React.useMemo(() => {
        return [
            {
                name: "Weighted Avg",
                fields: [{ name: "3rd Party Weighted Avg", multiplyBy100: true }],
            },
            {
                name: "Zestimate",
                fields: [{ name: "Zestimate" }],
            },
            {
                name: "Rental Zestimate",
                fields: [{ name: "Rental Zestimate" }],
            },
            {
                name: "redfin_row",
                fields: [],
                customLayout: (React.createElement(React.Fragment, null,
                    React.createElement(MAtrixFormFieldLabel, { key: "no_redfin_label", name: "No Redfin:", gridSize: 2, labelStyle: {
                            lineHeight: "35px",
                            fontSize: "8px",
                            paddingLeft: "4px",
                        } }),
                    React.createElement(Grid2, { key: "no_redfin_checkbox", size: 1 },
                        React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, field: matrixSchema["No Redfin"], value: matrixFields["No Redfin"], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onFieldSaveBegin("No Redfin"), onSuccess: onFieldSaveComplete, size: "small" })),
                    React.createElement(MAtrixFormFieldLabel, { key: "redfin_label", name: "Redfin:", gridSize: 2, labelStyle: {
                            paddingRight: "8px",
                            lineHeight: "35px",
                            fontSize: "9px",
                        } }),
                    React.createElement(Grid2, { key: "redfin_field", size: 7 }, noRedfin ? (React.createElement(Typography, { style: { lineHeight: "35px" } }, "n/a")) : (React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, field: matrixSchema["Redfin"], value: matrixFields["Redfin"], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onFieldSaveBegin("Redfin"), onSuccess: onFieldSaveComplete, size: "small" }))))),
            },
            {
                name: "Average",
                fields: [{ name: "3rd Party Avg" }],
            },
        ];
    }, [matrixFields, matrixSchema, noRedfin, onFieldSaveBegin, onFieldSaveComplete, uid]);
    const miscRows = React.useMemo(() => {
        return [
            {
                name: "PSP Report Language",
                fields: [{ name: "PSP Report Language" }],
            },
            {
                name: "Post Flip/Reno",
                fields: [{ name: "Post Flip/Reno" }],
                labelStyle: Object.assign(Object.assign({}, DEFAULT_LABEL_STYLE), { lineHeight: "35px" }),
            },
            {
                name: "Investor PSP?",
                fields: [{ name: "Investor PSP?" }],
                labelStyle: Object.assign(Object.assign({}, DEFAULT_LABEL_STYLE), { lineHeight: "35px" }),
            },
        ];
    }, []);
    if (!users) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, style: { height: "100%", width: "100%", margin: 0 } },
        React.createElement(Grid2, { container: true, size: { sm: 12, md: 4 }, style: { padding: "8px", marginBottom: "auto" } },
            React.createElement(PSPTabSection, { name: "Agent Information" }, getSectionRows(agentInformationRows)),
            React.createElement(PSPTabSection, { name: "MLS Data", info: React.createElement(Typography, { style: { fontSize: "10px" } },
                    "Some of these fields are located on the ",
                    React.createElement("i", null, "Aged Property Report"),
                    " tabs.") }, getSectionRows(mlsDataRows)),
            React.createElement(PSPTabSection, { name: "MLS SQFT Search Ranges" }, getSectionRows(mlsSQFTSearchRangesRows))),
        React.createElement(Grid2, { container: true, size: { sm: 12, md: 4 }, style: { padding: "8px", marginBottom: "auto" } },
            React.createElement(PSPTabSection, { name: "CRS Realist Tax Data" }, getSectionRows(crsRealistTaxDataRows)),
            React.createElement(PSPTabSection, { name: "Collateral Analytics" }, getSectionRows(collateralAnalyticsRows)),
            React.createElement(PSPTabSection, { name: "No RPR" }, getSectionRows(rprRows)),
            React.createElement(PSPTabSection, { name: "3rd Party" }, getSectionRows(thirdPartyRows)),
            ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isDeveloper) && (React.createElement(HouseCanary, { matrix: { uid, fields: matrixFields }, schema: matrixSchema }))),
        React.createElement(Grid2, { container: true, size: { sm: 12, md: 4 }, style: { padding: "8px", marginBottom: "auto" } },
            React.createElement(PSPTabSection, { name: "Misc" }, getSectionRows(miscRows)))));
};
export default PSPTab;
