import * as React from "react";
import { BrowserRouter, HashRouter, MemoryRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorBoundary } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import numeral from "numeral";
import Fallback from "@app/common/fallback";
import theme from "@app/common/theme";
import { ActivityProvider } from "@app/hooks/useActivity";
import { BackStackContextProvider } from "@app/hooks/useBrowserBackStack";
import { FileUploadsProvider } from "@app/hooks/useFileUploads";
import BrokerageRouter from "@brokerage/features/index";
import App from "./container/App";
// import Content from "./container/Content";
import NavigationListener from "./NavigationListener";
import RouteChangeListener from "./RouteChangeListener";
import { SessionProvider } from "../../hooks/useSession";
numeral.options.scalePercentBy100 = false;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            gcTime: 0,
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});
const authToken = window._DASH_AUTH_TOKEN;
if (authToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
}
const Router = (props) => {
    const { basename, children, routerType } = props;
    console.log({ routerType });
    if (routerType === "hash") {
        return (React.createElement(HashRouter, { basename: basename },
            React.createElement(RouteChangeListener, null, children)));
    }
    if (routerType === "memory") {
        return React.createElement(MemoryRouter, { basename: basename }, children);
    }
    return React.createElement(BrowserRouter, null, children);
};
const MainApp = (props) => {
    const { initialRoute, routerBasename, routerType, showHeader, showSidebar, wrapperProps } = props;
    if (routerType === "browser") {
        const logoutChannel = new BroadcastChannel("logout");
        logoutChannel.onmessage = () => {
            window.location.href = "/";
            logoutChannel.close();
        };
    }
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(ErrorBoundary, { fallback: React.createElement(Fallback, null) },
            React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                React.createElement(QueryClientProvider, { client: queryClient },
                    React.createElement(SessionProvider, null,
                        React.createElement(FileUploadsProvider, null,
                            React.createElement(Router, { basename: routerBasename, routerType: routerType },
                                React.createElement(NavigationListener, null,
                                    React.createElement(BackStackContextProvider, null,
                                        React.createElement(ActivityProvider, { fetchIntervalMs: 30000, updateIntervalMs: 30000 },
                                            React.createElement(App, { showHeader: showHeader, showSidebar: showSidebar, wrapperProps: wrapperProps, content: React.createElement(BrokerageRouter, { initialRoute: initialRoute }), routerType: routerType }))))))))))));
};
export default MainApp;
