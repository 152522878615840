import * as React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { Base64 } from "js-base64";
import { convertUtcDateString } from "@app/common/date-utils";
const getDataForPart = (part) => {
    var _a;
    if (part["parts"]) {
        return part["parts"].map((p) => getDataForPart(p));
    }
    return [(_a = part["body"]["data"]) !== null && _a !== void 0 ? _a : ""];
};
const getBodyParts = (email) => {
    var _a;
    const details = email.details;
    if (details["payload"]["parts"]) {
        return details["payload"]["parts"].map((part) => getDataForPart(part));
    }
    return [(_a = details["payload"]["body"]["data"]) !== null && _a !== void 0 ? _a : ""];
};
const getBodyHtml = (email) => {
    const parts = getBodyParts(email).flat(1000);
    console.log({ parts });
    return parts.map((part) => Base64.decode(part.replace(/-/g, "+").replace(/_/g, "/"))).join("\n\n");
};
const Email = ({ email }) => {
    console.log(email);
    const details = email.details;
    const headers = details["payload"]["headers"].reduce((dict, header) => (Object.assign(Object.assign({}, dict), { [header.name.toLowerCase().trim()]: header.value })), {});
    const { bcc, cc, from, subject, to } = headers;
    const replyTo = headers["reply-to"];
    const body = getBodyHtml(email);
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { item: true, container: true, style: { flex: 1 }, spacing: 2 },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { color: "#777" } }, "From")),
                React.createElement(Grid, { item: true, style: { flex: 1 } },
                    React.createElement(Typography, { variant: "body2" }, from))),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#777" } }, convertUtcDateString(new Date(email.internal_date).toISOString(), { format: "dddd, MMMM D, YYYY h:mm A" })))),
        to && (React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#777" } }, "To")),
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2" }, to)))),
        cc && (React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#777" } }, "Cc")),
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2" }, cc)))),
        bcc && (React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#777" } }, "Bcc")),
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2" }, bcc)))),
        replyTo && (React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#777" } }, "Reply To")),
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2" }, replyTo)))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, subject)),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement("p", { dangerouslySetInnerHTML: { __html: body !== null && body !== void 0 ? body : "" } }))));
};
export default Email;
