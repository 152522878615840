import * as React from "react";
import { Avatar, Tooltip, Typography } from "@mui/material";
const MessageAvatar = (props) => {
    var _a, _b;
    const { user } = props;
    return (React.createElement(Tooltip, { title: React.createElement(Typography, null, [user.first_name, user.last_name].filter((o) => o).join(" ")) },
        React.createElement(Avatar, { style: { height: "22px", width: "22px", fontSize: "0.675rem" }, src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined }, (_b = [user.first_name, user.last_name]
            .filter((o) => o)
            .map((o) => o === null || o === void 0 ? void 0 : o.substring(0, 1))
            .join("")) !== null && _b !== void 0 ? _b : "?")));
};
export default MessageAvatar;
