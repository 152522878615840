import { useMemo } from "react";
import useSession from "@app/hooks/useSession";
import { searchUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { canEditBuyerLead } from "./utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const useCanEditBuyerLead = (buyerLead) => {
    var _a, _b;
    const session = useSession();
    const searchAllUsersApi = usePostQuery(searchUsers, {}, INFINITE_CACHE_PARAMS);
    const allUsers = (_b = (_a = searchAllUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const allUsersByUid = useMemo(() => {
        if (!allUsers) {
            return {};
        }
        return allUsers.reduce((acc, user) => {
            acc[user.uid] = user;
            return acc;
        }, {});
    }, [allUsers]);
    const canEdit = useMemo(() => {
        if (!buyerLead) {
            return false;
        }
        return canEditBuyerLead({
            buyerLead,
            sessionUser: session === null || session === void 0 ? void 0 : session.viewingAsUser,
            allUsers: allUsersByUid,
        });
    }, [allUsersByUid, buyerLead, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    return canEdit;
};
export default useCanEditBuyerLead;
