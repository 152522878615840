/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Admin, Superadmin._
 * @summary Retrieves QFOs with any agents associated with an attached Buyer Lead.
 */
export const portalToolsListQfos = (params, options) => {
    return axios.get(`/api/reports/portal_tools/qfos`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getPortalToolsListQfosQueryKey = (params) => {
    return [`/api/reports/portal_tools/qfos`, ...(params ? [params] : [])];
};
export const getPortalToolsListQfosQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getPortalToolsListQfosQueryKey(params);
    const queryFn = ({ signal }) => portalToolsListQfos(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Retrieves QFOs with any agents associated with an attached Buyer Lead.
 */
export function usePortalToolsListQfos(params, options) {
    const queryOptions = getPortalToolsListQfosQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
