import dayjs_ from "@app/common/dayjs";
import { DEFAULT_DATE_FORMAT } from "./Utils";
const TODAY = dayjs_(new Date());
const TODAY_FORMATTED = dayjs_(TODAY).format(DEFAULT_DATE_FORMAT);
export const TOWNE_MORTGAGE_DATE_RANGE_OPTIONS = [
    {
        name: "7 day",
        value: TODAY.add(-7, "day"),
    },
    {
        name: "30 day",
        value: TODAY.add(-30, "day"),
    },
    {
        name: "60 day",
        value: TODAY.add(-60, "day"),
    },
    {
        name: "90 day",
        value: TODAY.add(-90, "day"),
    },
    {
        name: "180 day",
        value: TODAY.add(-180, "day"),
    },
    {
        name: "365 day",
        value: TODAY.add(-365, "day"),
    },
    {
        name: "YTD",
        value: dayjs_(new Date(new Date().getFullYear(), 0, 1)),
    },
].map((timePeriod) => (Object.assign(Object.assign({}, timePeriod), { value: timePeriod.value.format("YYYY-MM-DD") })));
const REPORTING_DATE_RANGE_OPTIONS = [
    {
        name: "30 Day",
        startDate: TODAY.add(-30, "day").format(DEFAULT_DATE_FORMAT),
        endDate: TODAY_FORMATTED,
    },
    {
        name: "60 Day",
        startDate: TODAY.add(-60, "day").format(DEFAULT_DATE_FORMAT),
        endDate: TODAY_FORMATTED,
    },
    {
        name: "90 Day",
        startDate: TODAY.add(-90, "day").format(DEFAULT_DATE_FORMAT),
        endDate: TODAY_FORMATTED,
    },
    {
        name: "180 Day",
        startDate: TODAY.add(-180, "day").format(DEFAULT_DATE_FORMAT),
        endDate: TODAY_FORMATTED,
    },
    {
        name: "365 Day",
        startDate: TODAY.add(-365, "day").format(DEFAULT_DATE_FORMAT),
        endDate: TODAY_FORMATTED,
    },
    {
        name: "All",
        startDate: null,
        endDate: TODAY_FORMATTED,
    },
];
export default REPORTING_DATE_RANGE_OPTIONS;
