import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Add, Archive, Delete, FileCopy, FilterList, Launch, Refresh, Search, Share, Unarchive, ViewColumn, } from "@mui/icons-material";
import { Box, Checkbox, Chip, Grid2, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridToolbarContainer } from "@mui/x-data-grid-premium";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import DashPopover from "@app/common/CoreMoreMenu";
import CorePopover from "@app/common/CorePopover";
import CoreTextField from "@app/common/CoreTextField";
import dayjs_ from "@app/common/dayjs";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING } from "@app/common/grid/utils";
import { withDialog } from "@app/hoc/withDialog";
import { withFilter } from "@app/hoc/withFilter";
import withSession from "@app/hoc/withSession";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import MAtrixShareDialog from "./MAtrixShareDialog";
import UserAndTimestampCell from "./UserAndTimestampCell";
const adminCols = ["training_enabled"];
const unhideableCols = ["uid", GRID_CHECKBOX_SELECTION_COL_DEF.field];
class MAtrixTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                search: null,
                from: null,
                to: null,
                created_by_uid: null,
                pspType: null,
                archived: false,
                limit: 100,
                offset: 0,
                ordering: "-created_at",
                count: null,
                page: null,
                // rowsPerPage: 100,
                matrices: null,
                fields: null,
                error: null,
                selectedMatrices: new Set(),
                matricesWithUpdatingTrainingStatus: new Set(),
                isCreatingMAtrix: false,
                isDialogOpen: false,
                sharingMatrix: null,
                sharingMatrixShouldDuplicate: false,
                visibleColumns: ["uid", "Market", "Property Address", "created_at", "requested", "claimed", "training_enabled"],
                anchorEl: null,
                users: null,
            }
        });
        Object.defineProperty(this, "child", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "listUsersAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "listUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.listUsersAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.listUsersAbortController = new AbortController();
                API.users.search({
                    data: {
                        limit: 1000,
                        offset: 0,
                    },
                    onSuccess: (result) => {
                        this.setState({
                            users: result,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.listUsersAbortController.signal,
                    },
                });
            }
        });
        Object.defineProperty(this, "createMAtrix", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isCreatingMAtrix: true,
                });
                API.psps.create({
                    onSuccess: (result) => {
                        this.props.navigate(`/matrix/${result.uid}`);
                        this.setState({
                            isCreatingMAtrix: false,
                        });
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                        this.setState({
                            isCreatingMAtrix: false,
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "onDuplicateClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                this.setState((prevState) => ({
                    isDialogOpen: true,
                    sharingMatrix: prevState.matrices.find((matrix) => matrix.uid === uid),
                    sharingMatrixShouldDuplicate: true,
                }));
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
            }
        });
        Object.defineProperty(this, "unarchive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "The MAtrix can be re-archived later if necessary.",
                    confirmButtonText: "Unarchive",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            selectedMatrices: new Set([...prevState.selectedMatrices].filter((u) => u !== uid)),
                            matrices: prevState.matrices.filter((matrix) => matrix.uid !== uid),
                            count: prevState.count - 1,
                        }));
                        const loadingSnackbar = enqueueSnackbar("Unarchiving matrices...", {
                            variant: "info",
                            persist: true,
                        });
                        API.psps.unarchive(uid, {
                            onSuccess: () => {
                                enqueueSnackbar("Unarchived matrix", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "unarchiveBulk", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const uids = this.state.selectedMatrices;
                this.props.showDialog({
                    title: "Are you sure?",
                    text: `This will unarchive ${uids.size} selected MAtrices. They can be re-archived later if necessary.`,
                    confirmButtonText: "Unarchive",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            selectedMatrices: new Set(),
                            matrices: prevState.matrices.filter((matrix) => !uids.has(matrix.uid)),
                            count: prevState.count - prevState.matrices.filter((matrix) => uids.has(matrix.uid)).length,
                        }));
                        const loadingSnackbar = enqueueSnackbar(`Unarchiving ${uids.size} matrices...`, {
                            variant: "info",
                            persist: true,
                        });
                        API.psps.bulkUnarchive({
                            data: {
                                uids: Array.from(uids),
                            },
                            onSuccess: () => {
                                enqueueSnackbar(`Unarchived ${uids.size} matrices`, { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "archive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "The MAtrix can be unarchived later if necessary.",
                    confirmButtonText: "Archive",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            selectedMatrices: new Set([...prevState.selectedMatrices].filter((u) => u !== uid)),
                            matrices: prevState.matrices.filter((matrix) => matrix.uid !== uid),
                            count: prevState.count - 1,
                        }));
                        const loadingSnackbar = enqueueSnackbar("Archiving matrices...", { variant: "info", persist: true });
                        API.psps.archive(uid, {
                            onSuccess: () => {
                                enqueueSnackbar("Archived matrix", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "archiveBulk", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const uids = this.state.selectedMatrices;
                this.props.showDialog({
                    title: "Are you sure?",
                    text: `This will archive ${uids.size} MAtrices. They can be unarchived later if necessary.`,
                    confirmButtonText: "Archive",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            selectedMatrices: new Set(),
                            matrices: prevState.matrices.filter((matrix) => !uids.has(matrix.uid)),
                            count: prevState.count - prevState.matrices.filter((matrix) => uids.has(matrix.uid)).length,
                        }));
                        const loadingSnackbar = enqueueSnackbar(`Archiving ${uids.size} matrices...`, {
                            variant: "info",
                            persist: true,
                        });
                        API.psps.bulkArchive({
                            data: {
                                uids: Array.from(uids),
                            },
                            onSuccess: () => {
                                enqueueSnackbar(`Archived ${uids.size} matrices`, { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "delete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            selectedMatrices: new Set([...prevState.selectedMatrices].filter((u) => u !== uid)),
                            matrices: prevState.matrices.filter((matrix) => matrix.uid !== uid),
                            count: prevState.count - 1,
                        }));
                        const loadingSnackbar = enqueueSnackbar("Deleting matrices...", { variant: "info", persist: true });
                        API.psps.delete(uid, {
                            onSuccess: () => {
                                enqueueSnackbar("Deleted matrix", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "deleteBulk", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const uids = this.state.selectedMatrices;
                this.props.showDialog({
                    title: "Are you sure?",
                    text: `This will delete ${uids.size} MAtrices. You won't be able to undo this action.`,
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            selectedMatrices: new Set(),
                            matrices: prevState.matrices.filter((matrix) => !uids.has(matrix.uid)),
                            count: prevState.count - prevState.matrices.filter((matrix) => uids.has(matrix.uid)).length,
                        }));
                        const loadingSnackbar = enqueueSnackbar(`Deleting ${uids.size} matrices...`, {
                            variant: "info",
                            persist: true,
                        });
                        API.psps.bulkDelete({
                            data: {
                                uids: Array.from(uids),
                            },
                            onSuccess: () => {
                                enqueueSnackbar(`Deleted ${uids.size} matrices`, { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "onShareClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                this.setState((prevState) => ({
                    isDialogOpen: true,
                    sharingMatrix: prevState.matrices.find((matrix) => matrix.uid === uid),
                    sharingMatrixShouldDuplicate: false,
                }));
            }
        });
        Object.defineProperty(this, "onShareComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (result) => {
                this.setState((prevState) => ({
                    isDialogOpen: false,
                    matrices: [
                        ...prevState.matrices.slice(0, prevState.matrices.findIndex((matrix) => matrix.uid === result.uid)),
                        result,
                        ...prevState.matrices.slice(prevState.matrices.findIndex((matrix) => matrix.uid === result.uid) + 1, prevState.matrices.length),
                    ],
                }));
            }
        });
        Object.defineProperty(this, "onShareCancel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isDialogOpen: false,
                });
            }
        });
        Object.defineProperty(this, "setTrainingEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => (_, checked) => {
                this.setState((prevState) => {
                    const { matricesWithUpdatingTrainingStatus } = prevState;
                    matricesWithUpdatingTrainingStatus.add(uid);
                    return {
                        matricesWithUpdatingTrainingStatus,
                    };
                });
                API.psps.update(uid, {
                    data: {
                        training_enabled: checked,
                    },
                    onSuccess: () => {
                        this.setState((prevState) => {
                            const indx = prevState.matrices.findIndex((matrix) => matrix.uid === uid);
                            const { matricesWithUpdatingTrainingStatus } = prevState;
                            matricesWithUpdatingTrainingStatus.delete(uid);
                            return {
                                matricesWithUpdatingTrainingStatus,
                                matrices: [
                                    ...prevState.matrices.slice(0, indx),
                                    Object.assign(Object.assign({}, prevState.matrices[indx]), { training_enabled: checked }),
                                    ...prevState.matrices.slice(indx + 1, prevState.matrices.length),
                                ],
                            };
                        });
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                        this.setState((prevState) => {
                            const { matricesWithUpdatingTrainingStatus } = prevState;
                            matricesWithUpdatingTrainingStatus.delete(uid);
                            return {
                                matricesWithUpdatingTrainingStatus,
                            };
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                this.setState({
                    matrices: null,
                    error: null,
                }, () => {
                    var _a, _b, _c, _d, _e;
                    const query = pickBy({
                        search: (_a = this.state.search) !== null && _a !== void 0 ? _a : undefined,
                        created_from: (_b = this.state.from) !== null && _b !== void 0 ? _b : undefined,
                        created_to: (_c = this.state.to) !== null && _c !== void 0 ? _c : undefined,
                        created_by_uid: (_d = this.state.created_by_uid) !== null && _d !== void 0 ? _d : undefined,
                        psp_type: (_e = this.state.pspType) !== null && _e !== void 0 ? _e : undefined,
                        is_training_psp: false,
                        is_archived: this.state.archived,
                        limit: this.state.limit,
                        offset: this.state.offset,
                        ordering: this.state.ordering,
                    }, (val) => val !== undefined);
                    console.log("QUERY", query);
                    API.psps.list({
                        query,
                        onSuccess: (result) => {
                            this.setState((prevState) => ({
                                matrices: result,
                                // rowsPerPage: prevState.limit,
                                page: Math.floor(prevState.offset / prevState.limit),
                                error: null,
                            }));
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                    API.psps.count({
                        query: omit(query, "limit", "offset", "ordering"),
                        onSuccess: (result) => {
                            this.setState({
                                count: result.count,
                            });
                        },
                        onError: (error) => {
                            console.error(error);
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                    API.fields.list({
                        onSuccess: (result) => {
                            this.setState({
                                fields: result.filter((field) => field.entity_type === "MAtrix"),
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "searchKeyDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (e.key.toLowerCase().trim() === "enter") {
                    this.updateSearch(e.target.value);
                    e.preventDefault();
                }
            }
        });
        Object.defineProperty(this, "updateSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.props.updateFilter("search", text.trim(), "offset", null);
            }
        });
        Object.defineProperty(this, "onPageChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (model) => {
                const rowsPerPage = model.pageSize;
                const currentPage = model.page;
                this.props.updateFilter("offset", rowsPerPage * currentPage, "limit", rowsPerPage);
            }
        });
        Object.defineProperty(this, "onSortModelChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (model) => {
                const { ordering } = this.state;
                if (model.length > 0) {
                    const changedColumn = model[0].field;
                    this.props.updateFilter("ordering", ordering === changedColumn ? `-${changedColumn}` : changedColumn);
                }
            }
        });
        Object.defineProperty(this, "onRowSelectionModelChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (model) => {
                const { matrices, selectedMatrices } = this.state;
                const selectedMatrixUids = new Set(model);
                const matrixUids = (matrices !== null && matrices !== void 0 ? matrices : []).map((matrix) => matrix.uid);
                const selectedMatricesThisPage = new Set(matrixUids.filter((uid) => selectedMatrixUids.has(uid)));
                const selectedMatricesOtherPages = [...selectedMatrices].filter((uid) => !selectedMatricesThisPage.has(uid) && !matrixUids.includes(uid));
                const newSelectedMatrices = [...selectedMatricesThisPage, ...selectedMatricesOtherPages];
                if (newSelectedMatrices.sort().join("") !== [...selectedMatrices].sort().join("")) {
                    this.setState({
                        selectedMatrices: new Set(newSelectedMatrices),
                    });
                }
            }
        });
        Object.defineProperty(this, "toggleColumnVisibility", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (colName) => (_, checked) => {
                this.setState((prevState) => {
                    return {
                        visibleColumns: checked
                            ? [...prevState.visibleColumns, colName]
                            : prevState.visibleColumns.filter((col) => {
                                return col !== colName;
                            }),
                    };
                });
            }
        });
        Object.defineProperty(this, "openViewColumnMenu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.stopPropagation();
                this.setState({
                    anchorEl: e.target,
                });
            }
        });
        Object.defineProperty(this, "closeViewColumnMenu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    anchorEl: null,
                });
            }
        });
        Object.defineProperty(this, "customToolbar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (props) => {
                const { archived, isCreatingMAtrix, search, selectedMatrices } = this.state;
                return (React.createElement(GridToolbarContainer, Object.assign({}, props),
                    React.createElement(Grid2, { container: true, spacing: 1, alignItems: "flex-end", style: { padding: "12px", width: "100%" } },
                        React.createElement(Grid2, null,
                            React.createElement(Search, null)),
                        React.createElement(Grid2, { style: { flexGrow: 1 } },
                            React.createElement(CoreTextField, { variant: "standard", fullWidth: true, autoFocus: true, style: { maxWidth: "500px" }, onTextChange: this.updateSearch, onKeyPress: this.searchKeyDown, value: search !== null && search !== void 0 ? search : "" })),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "View Columns") },
                                React.createElement(IconButton, { size: "small", onClick: this.openViewColumnMenu },
                                    React.createElement(ViewColumn, { fontSize: "small" }))),
                            selectedMatrices.size > 0 && (React.createElement(Typography, { variant: "caption", style: { fontWeight: "bold", color: AppConfig.themeColors.blue, paddingRight: "1rem" } },
                                selectedMatrices.size,
                                " selected")),
                            React.createElement(Tooltip, { title: "Create new MAtrix" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: this.createMAtrix, size: "small", disabled: isCreatingMAtrix },
                                        React.createElement(Add, { fontSize: "small" })))),
                            React.createElement(Tooltip, { title: archived ? "Unarchive selected MAtrices" : "Archive selected MAtrices" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: archived ? this.unarchiveBulk : this.archiveBulk, size: "small", disabled: selectedMatrices.size === 0 }, archived ? React.createElement(Unarchive, { fontSize: "small" }) : React.createElement(Archive, { fontSize: "small" })))),
                            React.createElement(Tooltip, { title: "Permanently delete selected MAtrices" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: this.deleteBulk, size: "small", disabled: selectedMatrices.size === 0 },
                                        React.createElement(Delete, { fontSize: "small" })))),
                            React.createElement(Tooltip, { title: "Refresh" },
                                React.createElement(IconButton, { onClick: this.refresh, size: "small" },
                                    React.createElement(Refresh, { fontSize: "small" }))),
                            this.props.onSidebarToggle && (React.createElement(Tooltip, { title: "Show/hide filters" },
                                React.createElement(IconButton, { onClick: this.props.onSidebarToggle, size: "small" },
                                    React.createElement(FilterList, { fontSize: "small" }))))))));
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a;
        const queryParams = new URLSearchParams(nextProps.location.search);
        return {
            search: queryParams.get("search"),
            from: queryParams.get("from"),
            to: queryParams.get("to"),
            created_by_uid: queryParams.get("created_by_uid"),
            pspType: queryParams.get("psp_type"),
            archived: queryParams.get("archived") === "true",
            limit: queryParams.get("limit") ? numeral(queryParams.get("limit")).value() : 100,
            offset: queryParams.get("offset") ? numeral(queryParams.get("offset")).value() : 0,
            ordering: (_a = queryParams.get("ordering")) !== null && _a !== void 0 ? _a : "-created_at",
        };
    }
    componentDidMount() {
        this.refresh();
        this.listUsers();
        this.setState({
            selectedMatrices: new Set(),
        });
    }
    componentDidUpdate(_, prevState) {
        if (prevState.search !== this.state.search ||
            prevState.from !== this.state.from ||
            prevState.to !== this.state.to ||
            prevState.created_by_uid !== this.state.created_by_uid ||
            prevState.pspType !== this.state.pspType ||
            prevState.archived !== this.state.archived ||
            prevState.limit !== this.state.limit ||
            prevState.offset !== this.state.offset ||
            prevState.ordering !== this.state.ordering) {
            this.refresh();
        }
        if (prevState.archived !== this.state.archived) {
            this.setState({
                selectedMatrices: new Set(),
            });
        }
    }
    componentWillUnmount() {
        var _a, _b;
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
        (_b = this.listUsersAbortController) === null || _b === void 0 ? void 0 : _b.abort();
    }
    render() {
        var _a, _b, _c, _d;
        const { anchorEl, count, error, fields, isDialogOpen, limit, matrices, matricesWithUpdatingTrainingStatus, page, sharingMatrix, sharingMatrixShouldDuplicate, visibleColumns, } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!matrices || !fields) {
            return React.createElement(CoreLoading, null);
        }
        const rows = matrices.map((matrix) => (Object.assign(Object.assign(Object.assign({}, matrix), matrix.fields), { id: matrix.uid })));
        const userIsMAtrixAdmin = !!((_a = this.props.session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin")) ||
            !!((_b = this.props.session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole("Admin")) ||
            !!((_c = this.props.session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole("Market Analyst"));
        const columns = [
            {
                field: "uid",
                headerName: " ",
                sortable: false,
                width: userIsMAtrixAdmin ? 35 : 60,
                disableExport: true,
                hideable: false,
                type: "string",
                renderCell: (params) => {
                    var _a;
                    const { row } = params;
                    return (React.createElement(Box, { m: "auto" },
                        React.createElement(DashPopover, null,
                            React.createElement(MenuItem, { component: RouterLink, to: `/matrix/${params.value}`, disabled: !!row.archived && !userIsMAtrixAdmin },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Launch, { fontSize: "small" })),
                                "Open"),
                            row.created_by && row.created_by.uid === ((_a = this.props.session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) && (React.createElement(MenuItem, { onClick: this.onShareClick(params.value) },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Share, { fontSize: "small" })),
                                "Share")),
                            React.createElement(MenuItem, { onClick: this.onDuplicateClick(params.value) },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(FileCopy, { fontSize: "small" })),
                                "Duplicate"),
                            userIsMAtrixAdmin && (React.createElement(React.Fragment, null,
                                React.createElement(MenuItem, { onClick: row.archived ? this.unarchive(params.value) : this.archive(params.value) },
                                    React.createElement(ListItemIcon, null, row.archived ? React.createElement(Unarchive, { fontSize: "small" }) : React.createElement(Archive, { fontSize: "small" })),
                                    row.archived ? "Unarchive" : "Archive"),
                                React.createElement(MenuItem, { onClick: this.delete(params.value) },
                                    React.createElement(ListItemIcon, null,
                                        React.createElement(Delete, { fontSize: "small" })),
                                    "Delete"))))));
                },
            },
            GRID_CHECKBOX_SELECTION_COL_DEF,
            {
                field: "Market",
                headerName: "Market",
                maxWidth: 100,
            },
            {
                field: "Property Address",
                headerName: "Address",
                type: "string",
                renderCell: (params) => {
                    // const matrix = matrices[tableMeta.rowIndex];
                    const matrix = params.row;
                    return (React.createElement(Grid2, { container: true, style: { overflowY: "hidden", margin: "0px" }, wrap: "nowrap" },
                        React.createElement(Grid2, null, matrix.archived &&
                            (!userIsMAtrixAdmin ? (React.createElement(Tooltip, { title: "Contact a MA to access this MAtrix" },
                                React.createElement(Chip, { label: "Archived", size: "small" }))) : (React.createElement(Chip, { label: "Archived", size: "small" })))),
                        React.createElement(Grid2, { style: { flexGrow: 1, marginTop: "auto", marginBottom: "auto" } }, params.value)));
                },
            },
            {
                field: "created_at",
                headerName: "Created",
                renderCell: (params) => {
                    var _a;
                    const matrix = params.row;
                    return React.createElement(UserAndTimestampCell, { user: (_a = matrix.created_by) !== null && _a !== void 0 ? _a : null, timestamp: matrix.created_at });
                },
            },
            {
                field: "requested",
                headerName: "Requested",
                renderCell: (params) => {
                    var _a, _b, _c, _d;
                    const matrix = params.row;
                    return (React.createElement(UserAndTimestampCell, { user: (_b = (_a = matrix.psp_request) === null || _a === void 0 ? void 0 : _a.requested_by) !== null && _b !== void 0 ? _b : null, timestamp: (_d = (_c = matrix.psp_request) === null || _c === void 0 ? void 0 : _c.requested_at) !== null && _d !== void 0 ? _d : null }));
                },
            },
            {
                field: "claimed",
                headerName: "Claimed",
                renderCell: (params) => {
                    var _a, _b, _c, _d;
                    const matrix = params.row;
                    return (React.createElement(UserAndTimestampCell, { user: (_b = (_a = matrix.psp_request) === null || _a === void 0 ? void 0 : _a.claimed_by) !== null && _b !== void 0 ? _b : null, timestamp: (_d = (_c = matrix.psp_request) === null || _c === void 0 ? void 0 : _c.claimed_at) !== null && _d !== void 0 ? _d : null }));
                },
            },
            ...(((_d = this.props.session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.isDeveloper)
                ? fields
                    .filter((field) => !["Market", "Property Address"].includes(field.name))
                    .map((field) => {
                    return {
                        field: field.name,
                        headerName: field.name,
                        renderCell: (params) => {
                            var _a, _b, _c;
                            const matrix = params.row;
                            let value;
                            if (field.field_type === "formula") {
                                value = (_b = (_a = matrix.fields[field.name]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "";
                            }
                            else {
                                value = (_c = matrix.fields[field.name]) !== null && _c !== void 0 ? _c : "";
                            }
                            let formattedValue;
                            if (field.format && ["date", "datetime"].includes(field.field_type)) {
                                formattedValue =
                                    value !== null && value !== undefined && value !== "" && dayjs_(value)
                                        ? dayjs_(value).format(field.format)
                                        : (value !== null && value !== void 0 ? value : "");
                            }
                            else if (field.format && field.field_type === "number") {
                                formattedValue =
                                    value === null || value === undefined || value === "" ? "" : numeral(value).format(field.format);
                            }
                            else if (field.format && field.field_type === "formula" && value != null && value !== "") {
                                formattedValue = field.format.includes("0")
                                    ? numeral(value).format(field.format)
                                    : dayjs_(value).format(field.format);
                            }
                            else if (field.field_type === "text") {
                                formattedValue = value && value.length > 50 ? `${value.substring(0, 47)}...` : value;
                            }
                            else {
                                formattedValue = String(value);
                            }
                            return formattedValue;
                        },
                    };
                })
                : []),
            {
                field: "training_enabled",
                headerName: "Training Enabled",
                maxWidth: 150,
                type: "string",
                renderCell: (params) => {
                    var _a, _b;
                    const matrix = params.row;
                    const createdByUser = (_a = this.state.users) === null || _a === void 0 ? void 0 : _a.find((x) => { var _a; return x.uid === ((_a = matrix.created_by) === null || _a === void 0 ? void 0 : _a.uid); });
                    const claimedByUser = (_b = this.state.users) === null || _b === void 0 ? void 0 : _b.find((x) => { var _a, _b; return x.uid === ((_b = (_a = matrix.psp_request) === null || _a === void 0 ? void 0 : _a.claimed_by) === null || _b === void 0 ? void 0 : _b.uid); });
                    const createdByMA = !!(createdByUser === null || createdByUser === void 0 ? void 0 : createdByUser.roles.includes("Market Analyst")) || !!(claimedByUser === null || claimedByUser === void 0 ? void 0 : claimedByUser.roles.includes("Market Analyst"));
                    if (createdByMA) {
                        return (React.createElement(Checkbox, { size: "small", color: "primary", checked: !!params.value, disabled: matricesWithUpdatingTrainingStatus.has(matrix.uid), onChange: this.setTrainingEnabled(matrix.uid) }));
                    }
                    return null;
                },
            },
            // @ts-expect-error
        ].map((col) => {
            return Object.assign(Object.assign({}, col), { editable: false, filterable: false, disableColumnMenu: true, 
                // width: col.width ?? 150,
                resizable: col.resizable !== false, flex: col.width ? undefined : 1 });
        });
        const columnVisibilityModel = {};
        columns.forEach((column) => {
            if (adminCols.includes(column.field)) {
                columnVisibilityModel[column.field] = userIsMAtrixAdmin;
            }
            else if (column.field === GRID_CHECKBOX_SELECTION_COL_DEF.field) {
                columnVisibilityModel[column.field] = true;
            }
            else {
                columnVisibilityModel[column.field] = visibleColumns.includes(column.field);
            }
        });
        return (React.createElement(React.Fragment, null,
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, hideFooterSelectedRowCount: true, checkboxSelection: userIsMAtrixAdmin, density: "compact", 
                    // filterMode="server"
                    pagination: count != null && rows.length < count, rowCount: count !== null && count !== void 0 ? count : undefined, paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], paginationModel: { page: page !== null && page !== void 0 ? page : 0, pageSize: limit }, onPaginationModelChange: this.onPageChange, columnVisibilityModel: columnVisibilityModel, onSortModelChange: this.onSortModelChange, onRowSelectionModelChange: this.onRowSelectionModelChange, getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                        toolbarColumns: "",
                    }, slots: {
                        toolbar: this.customToolbar,
                    } })),
            sharingMatrix && (React.createElement(MAtrixShareDialog, { matrix: sharingMatrix, dialogProps: { open: !!isDialogOpen }, onSaveComplete: this.onShareComplete, onCancel: this.onShareCancel, shouldDuplicate: sharingMatrixShouldDuplicate })),
            React.createElement(CorePopover, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: this.closeViewColumnMenu, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                } },
                React.createElement(Box, { padding: "16px 24px" },
                    React.createElement(Typography, { variant: "caption", style: {
                            marginLeft: "-7px",
                            marginRight: "24px",
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 500,
                        } }, "Show Columns"),
                    React.createElement(List, { style: { marginLeft: "-11px" } }, columns
                        .filter((col) => {
                        return !unhideableCols.includes(col.field) && (!adminCols.includes(col.field) || userIsMAtrixAdmin);
                    })
                        .map((col) => {
                        var _a;
                        return (React.createElement(Box, { key: `${col.field}_view_column_item` },
                            React.createElement(ListItem, { style: { paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px" } },
                                React.createElement(Checkbox, { checked: !!columnVisibilityModel[col.field], onChange: this.toggleColumnVisibility(col.field) }),
                                React.createElement(ListItemText, { primary: React.createElement(Typography, { style: { fontSize: "15px", marginLeft: "8px", color: "rgba(0, 0, 0, 0.87)" } }, (_a = col.headerName) !== null && _a !== void 0 ? _a : col.field) }))));
                    }))))));
    }
}
export default withSession(withDialog(withFilter(MAtrixTable)));
