import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
const usePaginatedQuery = (fn, queryParams, options) => {
    var _a, _b;
    const enabled = (_a = options === null || options === void 0 ? void 0 : options.enabled) !== null && _a !== void 0 ? _a : true;
    const pageSize = (_b = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _b !== void 0 ? _b : 1000;
    const [result, setResult] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const cancelTokenSource = useRef(null);
    const fetchData = useCallback((offset = 0) => {
        // @ts-expect-error: T can be instantiated with arbitrary type
        fn(Object.assign(Object.assign({}, queryParams), { limit: pageSize, offset }), { cancelToken: cancelTokenSource.current.token })
            .then((response) => {
            setResult((prevResult) => {
                if (prevResult === null) {
                    return response.data;
                }
                return [...prevResult, ...response.data];
            });
            if (response.data.length === pageSize) {
                fetchData(offset + pageSize);
            }
            else {
                setIsFetching(false);
            }
        })
            .catch((err) => {
            setIsFetching(false);
            setResult(null);
            if (!axios.isCancel(err)) {
                console.error(err);
                setError(err);
            }
        });
    }, [fn, pageSize, queryParams]);
    useEffect(() => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel();
        }
        if (enabled) {
            setError(null);
            setIsFetching(true);
            setResult(null);
            cancelTokenSource.current = axios.CancelToken.source();
            fetchData(0);
        }
        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, /* fetchData, */ JSON.stringify(queryParams)]);
    return {
        data: isFetching ? null : result,
        isFetching,
        error,
    };
};
export default usePaginatedQuery;
