/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Search notifications
 */
export const searchNotifications = (paginatedNotificationQueryRequest, options) => {
    return axios.post(`/api/notifications`, paginatedNotificationQueryRequest, options);
};
export const getSearchNotificationsMutationOptions = (options) => {
    const mutationKey = ['searchNotifications'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchNotifications(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search notifications
*/
export const useSearchNotifications = (options) => {
    const mutationOptions = getSearchNotificationsMutationOptions(options);
    return useMutation(mutationOptions);
};
