import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import PSPReport from "../../features/reporting/psp-reports/PSPReport";
const PSPReportPage = () => {
    useDocumentTitle("PSP Report");
    const { uid } = useParams();
    return React.createElement(PSPReport, { uid: uid });
};
export default PSPReportPage;
