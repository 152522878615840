import * as React from "react";
import { Feedback, Launch, ViewModule, WorkspacePremium } from "@mui/icons-material";
import { Alert, Chip, Grid2, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography, } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import { useListPsps } from "@app/orval/api/psps";
import { formatDate_Dayjs } from "@app/util/Utils";
import PSPCertificationFeedbackBlurb from "./PSPCertificationFeedbackBlurb";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const NUM_PASSES_REQUIRED_FOR_CERTIFICATION = 5;
const PSPCertification = (props) => {
    var _a, _b;
    const { userUid } = props;
    const listPSPsApi = useListPsps({
        is_training_psp: true,
        created_by_uid: userUid,
        limit: 100,
        offset: 0,
        ordering: "created_at",
    });
    const psps = (_b = (_a = listPSPsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const pspsSorted = React.useMemo(() => {
        return psps
            ? psps
                .filter((psp) => psp.training_submissions.length > 0)
                .sort((a, b) => {
                var _a, _b, _c, _d;
                const aTimestamp = (_b = (_a = a.training_submissions.slice(-1)[0]) === null || _a === void 0 ? void 0 : _a.timestamp) !== null && _b !== void 0 ? _b : "";
                const bTimestamp = (_d = (_c = b.training_submissions.slice(-1)[0]) === null || _c === void 0 ? void 0 : _c.timestamp) !== null && _d !== void 0 ? _d : "";
                if (aTimestamp > bTimestamp)
                    return -1;
                if (aTimestamp < bTimestamp)
                    return 1;
                return 0;
            })
            : null;
    }, [psps]);
    const numPasses = psps === null || psps === void 0 ? void 0 : psps.filter((psp) => !!psp.training_submissions.find((t) => t.success)).length;
    return (React.createElement(CollapsibleSection, { icon: React.createElement(ViewModule, null), title: React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Typography, { style: { whiteSpace: "nowrap" } }, "PSP Certification")),
            psps && (numPasses !== null && numPasses !== void 0 ? numPasses : 0) >= NUM_PASSES_REQUIRED_FOR_CERTIFICATION && (React.createElement(Grid2, { container: true, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(WorkspacePremium, { fontSize: "small" })),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, "Certified")))),
            psps && (numPasses !== null && numPasses !== void 0 ? numPasses : 0) < NUM_PASSES_REQUIRED_FOR_CERTIFICATION && (React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2", style: { color: "#888" } }, numPasses !== null && numPasses !== void 0 ? numPasses : 0,
                    " of ",
                    NUM_PASSES_REQUIRED_FOR_CERTIFICATION,
                    " completed")))), isCollapsedInitially: true },
        listPSPsApi.error && React.createElement(Alert, { severity: "error" }, JSON.stringify(format(listPSPsApi.error))),
        listPSPsApi.isFetching && React.createElement(CoreLoading, null),
        psps && psps.length === 0 && React.createElement(Typography, { variant: "body2" }, "No training PSPs found"),
        psps && psps.length > 0 && (React.createElement(List, { dense: true }, (pspsSorted !== null && pspsSorted !== void 0 ? pspsSorted : []).map((psp) => (React.createElement(ListItem, { key: psp.uid, secondaryAction: React.createElement(Grid2, { container: true, alignItems: "center" },
                psp.feedback && psp.feedback.length > 0 && (React.createElement(Grid2, null,
                    React.createElement(Tooltip, { title: React.createElement(PSPCertificationFeedbackBlurb, { feedback: psp.feedback }), slotProps: {
                            popper: {
                                style: {
                                    maxWidth: "600px",
                                },
                            },
                            tooltip: {
                                style: {
                                    maxWidth: "600px",
                                },
                            },
                        } },
                        React.createElement(Feedback, { style: { color: "#888" } })))),
                React.createElement(Grid2, null,
                    React.createElement(IconButton, { component: RouterLink, to: `/matrix/${psp.uid}` },
                        React.createElement(Launch, null)))) },
            React.createElement(ListItemAvatar, null, psp.training_submissions.slice(-1)[0].success ? (React.createElement(Chip, { size: "small", color: "primary", label: "PASS" })) : (React.createElement(Chip, { size: "small", color: "error", label: "FAIL" }))),
            React.createElement(ListItemText, { primary: psp.fields["Property Address"], secondary: `Submitted ${formatDate_Dayjs(psp.training_submissions.slice(-1)[0].timestamp, "ddd, MMM D, YYYY")}` }))))))));
};
export default PSPCertification;
