import * as React from "react";
import { useCallback } from "react";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import { useAddCollaboratorForBuyerLead, useRemoveCollaboratorForBuyerLead, useUpdateCollaboratorForBuyerLead, } from "@app/orval/api/buyer-leads";
import { useAddCollaboratorForRecruit, useRemoveCollaboratorForRecruit, useUpdateCollaboratorForRecruit, } from "@app/orval/api/recruits";
import { useAddCollaboratorForSellerLead, useRemoveCollaboratorForSellerLead, useUpdateCollaboratorForSellerLead, } from "@app/orval/api/seller-leads";
import { useAddCollaboratorForUser, useRemoveCollaboratorForUser, useUpdateCollaboratorForUser, } from "@app/orval/api/users";
export const useCollaborators = () => {
    var _a, _b;
    const [isCollaboratorsDialogOpen, setIsCollaboratorsDialogOpen] = React.useState(false);
    const [collaboratorsDialogParams, setCollaboratorsDialogParams] = React.useState(null);
    const session = useSession();
    const errorDialog = useErrorDialog();
    const canEditCollaboratorsCells = ["scripts@dashnc.com", "developer@dashnc.com"].includes((_b = (_a = session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : "");
    const addCollaboratorForBuyerLeadApi = useAddCollaboratorForBuyerLead();
    const updateCollaboratorForBuyerLeadApi = useUpdateCollaboratorForBuyerLead();
    const removeCollaboratorForBuyerLeadApi = useRemoveCollaboratorForBuyerLead();
    const addCollaboratorForSellerLeadApi = useAddCollaboratorForSellerLead();
    const updateCollaboratorForSellerLeadApi = useUpdateCollaboratorForSellerLead();
    const removeCollaboratorForSellerLeadApi = useRemoveCollaboratorForSellerLead();
    const addCollaboratorForRecruitApi = useAddCollaboratorForRecruit();
    const updateCollaboratorForRecruitApi = useUpdateCollaboratorForRecruit();
    const removeCollaboratorForRecruitApi = useRemoveCollaboratorForRecruit();
    const addCollaboratorForUserApi = useAddCollaboratorForUser();
    const updateCollaboratorForUserApi = useUpdateCollaboratorForUser();
    const removeCollaboratorForUserApi = useRemoveCollaboratorForUser();
    const openCollaboratorsDialog = useCallback(() => {
        setIsCollaboratorsDialogOpen(true);
    }, []);
    const closeCollaboratorsDialog = useCallback(() => {
        setIsCollaboratorsDialogOpen(false);
    }, []);
    const addCollaboratorForBuyerLead = useCallback((buyerLeadUid, params, options) => {
        addCollaboratorForBuyerLeadApi
            .mutateAsync({ buyerLeadUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onSaveComplete) {
                options.onSaveComplete(result);
            }
        })
            .catch(errorDialog);
    }, [addCollaboratorForBuyerLeadApi, errorDialog]);
    const updateCollaboratorForBuyerLead = useCallback((buyerLeadUid, 
    /** uid of collaborator, not the user */
    collaboratorUid, params, options) => {
        updateCollaboratorForBuyerLeadApi
            .mutateAsync({ buyerLeadUid, uid: collaboratorUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, updateCollaboratorForBuyerLeadApi]);
    const removeCollaboratorForBuyerLead = useCallback((buyerLeadUid, collaboratorUid, options) => {
        if (options === null || options === void 0 ? void 0 : options.onBeforeRemove) {
            options.onBeforeRemove(collaboratorUid);
        }
        removeCollaboratorForBuyerLeadApi
            .mutateAsync({
            buyerLeadUid,
            uid: collaboratorUid,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeCollaboratorForBuyerLeadApi]);
    const addCollaboratorForSellerLead = useCallback((sellerLeadUid, params, options) => {
        addCollaboratorForSellerLeadApi
            .mutateAsync({ sellerLeadUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onSaveComplete) {
                options.onSaveComplete(result);
            }
        })
            .catch(errorDialog);
    }, [addCollaboratorForSellerLeadApi, errorDialog]);
    const updateCollaboratorForSellerLead = useCallback((sellerLeadUid, collaboratorUid, params, options) => {
        updateCollaboratorForSellerLeadApi
            .mutateAsync({ sellerLeadUid, uid: collaboratorUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, updateCollaboratorForSellerLeadApi]);
    const removeCollaboratorForSellerLead = useCallback((sellerLeadUid, collaboratorUid, options) => {
        if (options === null || options === void 0 ? void 0 : options.onBeforeRemove) {
            options.onBeforeRemove(collaboratorUid);
        }
        removeCollaboratorForSellerLeadApi
            .mutateAsync({
            sellerLeadUid,
            uid: collaboratorUid,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeCollaboratorForSellerLeadApi]);
    const addCollaboratorForRecruit = useCallback((recruitUid, params, options) => {
        addCollaboratorForRecruitApi
            .mutateAsync({ recruitUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onSaveComplete) {
                options.onSaveComplete(result);
            }
        })
            .catch(errorDialog);
    }, [addCollaboratorForRecruitApi, errorDialog]);
    const updateCollaboratorForRecruit = useCallback((recruitUid, collaboratorUid, params, options) => {
        updateCollaboratorForRecruitApi
            .mutateAsync({ recruitUid, uid: collaboratorUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, updateCollaboratorForRecruitApi]);
    const removeCollaboratorForRecruit = useCallback((recruitUid, collaboratorUid, options) => {
        if (options === null || options === void 0 ? void 0 : options.onBeforeRemove) {
            options.onBeforeRemove(collaboratorUid);
        }
        removeCollaboratorForRecruitApi
            .mutateAsync({
            recruitUid,
            uid: collaboratorUid,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeCollaboratorForRecruitApi]);
    const addCollaboratorForUser = useCallback((userUid, params, options) => {
        addCollaboratorForUserApi
            .mutateAsync({
            profileUid: userUid,
            data: params,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onSaveComplete) {
                options.onSaveComplete(result);
            }
        })
            .catch(errorDialog);
    }, [addCollaboratorForUserApi, errorDialog]);
    const updateCollaboratorForUser = useCallback((userUid, collaboratorUid, params, options) => {
        updateCollaboratorForUserApi
            .mutateAsync({ profileUid: userUid, uid: collaboratorUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, updateCollaboratorForUserApi]);
    const removeCollaboratorForUser = useCallback((userUid, collaboratorUid, options) => {
        if (options === null || options === void 0 ? void 0 : options.onBeforeRemove) {
            options.onBeforeRemove(collaboratorUid);
        }
        removeCollaboratorForUserApi
            .mutateAsync({
            profileUid: userUid,
            uid: collaboratorUid,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeCollaboratorForUserApi]);
    return {
        isCollaboratorsDialogOpen,
        collaboratorsDialogParams,
        canEditCollaboratorsCells,
        setCollaboratorsDialogParams,
        openCollaboratorsDialog,
        closeCollaboratorsDialog,
        addCollaboratorForBuyerLead,
        updateCollaboratorForBuyerLead,
        removeCollaboratorForBuyerLead,
        addCollaboratorForSellerLead,
        updateCollaboratorForSellerLead,
        removeCollaboratorForSellerLead,
        addCollaboratorForRecruit,
        updateCollaboratorForRecruit,
        removeCollaboratorForRecruit,
        addCollaboratorForUser,
        updateCollaboratorForUser,
        removeCollaboratorForUser,
    };
};
export default useCollaborators;
