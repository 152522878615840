import * as React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
const STATUSES = [
    {
        value: "Success",
        description: "Indicates that the Mail Schedule ran successfully, although there may have been errors with individual templates.",
    },
    {
        value: "Failed",
        description: "Indicates that a bug or another error prevented the Mail Schedule from running successfully.",
    },
    {
        value: "Running",
        description: "Indicates that the Mail Schedule is still running.",
    },
    {
        value: "Stopped",
        description: "Indicates that a user stopped the Mail Schedule before it finished running.",
    },
];
const ExecutionStatusFilter = (props) => {
    var _a, _b, _c, _d;
    const { TextFieldProps, onChange, value: status } = props;
    const handleChange = React.useCallback((event) => {
        onChange(event.target.value || null);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true, helperText: (_a = STATUSES.find((s) => s.value === status)) === null || _a === void 0 ? void 0 : _a.description }, TextFieldProps, { select: true, value: status !== null && status !== void 0 ? status : "", onChange: handleChange, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Status") }, (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.input), inputLabel: Object.assign({ shrink: true }, (_c = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.inputLabel), select: Object.assign({ displayEmpty: true }, (_d = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _d === void 0 ? void 0 : _d.select) }) }),
        React.createElement(MenuItem, { value: "" }, "Any"),
        STATUSES.map((x) => (React.createElement(MenuItem, { key: x.value, value: x.value }, x.value)))));
};
export default ExecutionStatusFilter;
