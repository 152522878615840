import * as React from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetBuyerLead } from "@app/orval/api/buyer-leads";
import MailScheduleTemplatePreview from "../../features/mailschedule/preview/MailScheduleTemplatePreview";
const BuyerLeadsMailSchedulePreviewPage = () => {
    var _a, _b;
    const params = useParams();
    const { template_uid, uid } = params;
    useDocumentTitle("Buyer Leads | Mail Schedule");
    const navigate = useNavigate();
    const getBuyerLeadApi = useGetBuyerLead(uid);
    const buyerLead = (_b = (_a = getBuyerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const redirectUrl = `/buyer-leads/${uid}/mail-schedule`;
    const onTemplateProcessed = React.useCallback(() => {
        navigate(redirectUrl);
    }, [navigate, redirectUrl]);
    if (!buyerLead) {
        return React.createElement(CoreLoading, null);
    }
    if (!buyerLead.streak_box_key) {
        return React.createElement(Typography, null, "Unable to load Mail Schedule");
    }
    return (React.createElement(MailScheduleTemplatePreview, { boxKey: buyerLead.streak_box_key, templateUid: template_uid, onTemplateProcessed: onTemplateProcessed, onCloseRedirectUrl: redirectUrl }));
};
export default BuyerLeadsMailSchedulePreviewPage;
