/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List Google Groups
 */
export const listGoogleGroups = (options) => {
    return axios.get(`/api/google_groups`, options);
};
export const getListGoogleGroupsQueryKey = () => {
    return [`/api/google_groups`];
};
export const getListGoogleGroupsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListGoogleGroupsQueryKey();
    const queryFn = ({ signal }) => listGoogleGroups(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Google Groups
 */
export function useListGoogleGroups(options) {
    const queryOptions = getListGoogleGroupsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary List members in a Google Group
 */
export const listMembersInGoogleGroup = (groupId, options) => {
    return axios.get(`/api/google_groups/${groupId}/members`, options);
};
export const getListMembersInGoogleGroupQueryKey = (groupId) => {
    return [`/api/google_groups/${groupId}/members`];
};
export const getListMembersInGoogleGroupQueryOptions = (groupId, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMembersInGoogleGroupQueryKey(groupId);
    const queryFn = ({ signal }) => listMembersInGoogleGroup(groupId, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(groupId) }, queryOptions);
};
/**
 * @summary List members in a Google Group
 */
export function useListMembersInGoogleGroup(groupId, options) {
    const queryOptions = getListMembersInGoogleGroupQueryOptions(groupId, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Update which users are allowed to access each of our Google Groups

_Authorization: none required_
 * @summary Update Google Group access
 */
export const updateGoogleGroupAccess = (googleGroupAccessWriteRequest, options) => {
    return axios.post(`/api/update_google_groups_access`, googleGroupAccessWriteRequest, options);
};
export const getUpdateGoogleGroupAccessMutationOptions = (options) => {
    const mutationKey = ['updateGoogleGroupAccess'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return updateGoogleGroupAccess(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update Google Group access
*/
export const useUpdateGoogleGroupAccess = (options) => {
    const mutationOptions = getUpdateGoogleGroupAccessMutationOptions(options);
    return useMutation(mutationOptions);
};
