/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Count violations per agent
 */
export const countSlaViolationsPerAgent = (params, options) => {
    return axios.get(`/api/reports/sla/count_per_agent`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountSlaViolationsPerAgentQueryKey = (params) => {
    return [`/api/reports/sla/count_per_agent`, ...(params ? [params] : [])];
};
export const getCountSlaViolationsPerAgentQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountSlaViolationsPerAgentQueryKey(params);
    const queryFn = ({ signal }) => countSlaViolationsPerAgent(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count violations per agent
 */
export function useCountSlaViolationsPerAgent(params, options) {
    const queryOptions = getCountSlaViolationsPerAgentQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Get SLA violations
 */
export const getSlaData = (params, options) => {
    return axios.get(`/api/reports/sla/list_violations`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetSlaDataQueryKey = (params) => {
    return [`/api/reports/sla/list_violations`, ...(params ? [params] : [])];
};
export const getGetSlaDataQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSlaDataQueryKey(params);
    const queryFn = ({ signal }) => getSlaData(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get SLA violations
 */
export function useGetSlaData(params, options) {
    const queryOptions = getGetSlaDataQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
