import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Refresh, Search } from "@mui/icons-material";
import { Box, Button, Grid, Hidden, IconButton, InputAdornment, LinearProgress, TextField, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import CoreLink from "@app/common/CoreLink";
import dayjs_ from "@app/common/dayjs";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { CLASS_NAME_NO_FOCUS_RING, disableQuickFilterFn } from "@app/common/grid/utils";
import useLoading from "@app/hooks/useLoading";
import useSession from "@app/hooks/useSession";
import { useListMailschedule4Errors } from "@app/orval/api/mail-schedule-4-errors";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
const getCellClassName = () => {
    return CLASS_NAME_NO_FOCUS_RING;
};
const MailScheduleErrorsTable = (props) => {
    var _a, _b, _c, _d;
    const { entityUid, templateUid } = props;
    const session = useSession();
    const gridApi = useGridApiRef();
    const [search, setSearch] = React.useState("");
    const handleSearchChanged = React.useCallback((e) => {
        const searchStr = e.target.value;
        setSearch(searchStr);
        gridApi.current.setQuickFilterValues([searchStr]);
    }, [setSearch, gridApi]);
    const listMailScheduleTemplatesApi = useListMailschedule4Templates(undefined, DEFAULT_CACHE_PARAMS);
    const templates = (_b = (_a = listMailScheduleTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listMailScheduleErrorsApi = useListMailschedule4Errors({ entity_uid: entityUid, template_uid: templateUid }, { query: { gcTime: 0 } });
    const errors = (_d = (_c = listMailScheduleErrorsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const refresh = React.useCallback(() => {
        listMailScheduleErrorsApi.refetch();
    }, [listMailScheduleErrorsApi]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading errors",
                queryResult: listMailScheduleErrorsApi,
            },
        ],
    });
    if (isLoading) {
        return loadingComponent;
    }
    if (!errors) {
        return null; // should never happen
    }
    const rows = errors.map((e) => {
        var _a, _b;
        return (Object.assign(Object.assign({}, e), { template: (_b = (_a = templates === null || templates === void 0 ? void 0 : templates.find((t) => t.uid === e.template_uid)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : e.template_uid, error_type: (() => {
                if (e.is_data_error) {
                    return "Bad data in CRM";
                }
                if (e.is_data_error === false) {
                    return "Issue with template";
                }
                return null;
            })(), id: [e.box.uid, e.template_uid, e.timestamp].join("__") }));
    });
    const columns = [
        {
            field: "id",
            headerName: "",
            editable: false,
            sortable: false,
            width: 60,
            resizable: false,
            getApplyQuickFilterFn: disableQuickFilterFn,
            cellClassName: "cell-button",
            align: "center",
            hide: true,
            renderCell: (params) => {
                const row = params.row;
                return (React.createElement(Button, { size: "small", variant: "text", component: RouterLink, to: `/ms4/preview?template=${row.template_uid}&entity_type=${row.box.entity_type}&uid=${row.box.uid}` }, "Preview"));
            },
        },
        {
            field: "timestamp",
            headerName: "Timestamp",
            width: 200,
            renderCell: (params) => {
                const { value } = params;
                if (value && dayjs_(value).isValid()) {
                    return dayjs_(value).format("dddd, MMMM D, YYYY h:mm A");
                }
                return value;
            },
        },
        {
            field: "entity_type",
            headerName: "Pipeline",
            renderCell: (params) => {
                const processedTemplate = params.row;
                const { box } = processedTemplate;
                const value = box.entity_type;
                const link = {
                    "Buyer Lead": "/buyer-leads",
                    "Seller Lead": "/seller-leads",
                    "Buyer Contract": "/buyer-contracts",
                    "Seller Contract": "/seller-contracts",
                    Listing: "/seller-contracts",
                    User: "/users",
                    Recruit: "/recruits",
                }[(value !== null && value !== void 0 ? value : "")];
                if (link) {
                    return (React.createElement(CoreLink, { href: link, target: "_blank" }, value));
                }
                return value;
            },
            sortComparator: (_, __, param1, param2) => {
                var _a, _b;
                const id1 = param1.id;
                const id2 = param2.id;
                const row1 = rows.find((r) => r.id === id1);
                const row2 = rows.find((r) => r.id === id2);
                const v1 = (_a = row1 === null || row1 === void 0 ? void 0 : row1.box.entity_type) !== null && _a !== void 0 ? _a : "";
                const v2 = (_b = row2 === null || row2 === void 0 ? void 0 : row2.box.entity_type) !== null && _b !== void 0 ? _b : "";
                if (v1 < v2)
                    return -1;
                if (v1 > v2)
                    return 1;
                return 0;
            },
            getApplyQuickFilterFn: (searchString) => {
                return (_, row) => {
                    return row.box.entity_type.toLowerCase().includes(searchString.toLowerCase().trim());
                };
            },
        },
        {
            field: "entity_uid",
            headerName: "Box",
            width: 200,
            renderCell: (params) => {
                const processedTemplate = params.row;
                const { box } = processedTemplate;
                const url = `/boxes/${box.uid}`;
                return (React.createElement(CoreLink, { href: url, target: "_blank" }, processedTemplate.box.name));
            },
            sortComparator: (_, __, param1, param2) => {
                var _a, _b;
                const id1 = param1.id;
                const id2 = param2.id;
                const row1 = rows.find((r) => r.id === id1);
                const row2 = rows.find((r) => r.id === id2);
                const v1 = (_a = row1 === null || row1 === void 0 ? void 0 : row1.box.name) !== null && _a !== void 0 ? _a : "";
                const v2 = (_b = row2 === null || row2 === void 0 ? void 0 : row2.box.name) !== null && _b !== void 0 ? _b : "";
                if (v1 < v2)
                    return -1;
                if (v1 > v2)
                    return 1;
                return 0;
            },
            getApplyQuickFilterFn: (searchString) => {
                return (_, row) => {
                    return row.box.name.toLowerCase().includes(searchString.toLowerCase().trim());
                };
            },
        },
        {
            field: "template_uid",
            headerName: "Template",
            width: 200,
            renderCell: (params) => {
                var _a, _b;
                const { value } = params;
                const template = templates === null || templates === void 0 ? void 0 : templates.find((t) => t.uid === value);
                if ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) {
                    if (template) {
                        return React.createElement(RouterLink, { to: `/ms4/templates/${value}` }, template.name);
                    }
                    if (value) {
                        return React.createElement(RouterLink, { to: `/ms4/templates/${value}` }, value);
                    }
                }
                return (_b = template === null || template === void 0 ? void 0 : template.name) !== null && _b !== void 0 ? _b : value;
            },
            sortComparator: (v1, v2) => {
                var _a, _b, _c, _d;
                const name1 = (_b = (_a = templates === null || templates === void 0 ? void 0 : templates.find((t) => t.uid === v1)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
                const name2 = (_d = (_c = templates === null || templates === void 0 ? void 0 : templates.find((t) => t.uid === v2)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "";
                if (name1 < name2)
                    return -1;
                if (name1 > name2)
                    return 1;
                return 0;
            },
            getApplyQuickFilterFn: (searchString) => {
                return (cellValue) => {
                    var _a;
                    const template = templates === null || templates === void 0 ? void 0 : templates.find((t) => t.uid === cellValue);
                    return (_a = template === null || template === void 0 ? void 0 : template.name.toLowerCase().includes(searchString.toLowerCase().trim())) !== null && _a !== void 0 ? _a : false;
                };
            },
        },
        {
            field: "error_type",
            headerName: "Error Type",
        },
        {
            field: "error_message",
            headerName: "Error",
            flex: 1,
        },
    ].map((column) => (Object.assign(Object.assign({}, column), { filterable: false, disableColumnMenu: true, width: column.width || 150, resizable: column.resizable !== false, editable: false, 
        // @ts-expect-error: missing
        type: (column.type || "string") })));
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 1 },
                React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { size: "small", style: { width: "300px" }, value: search, onChange: handleSearchChanged, InputProps: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Search, null))),
                                } }))),
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true, style: { flex: 1 } })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Refresh errors") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: refresh },
                                    React.createElement(Refresh, null)))))))),
        listMailScheduleErrorsApi.isFetching && (React.createElement(Grid, { item: true },
            React.createElement(LinearProgress, { variant: "indeterminate" }))),
        React.createElement(Grid, { item: true, style: { overflow: "hidden" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, density: "compact", rowHeight: 30, columnHeaderHeight: 30, disableVirtualization: true, disableRowSelectionOnClick: true, getCellClassName: getCellClassName, rowCount: rows.length, pagination: true, paginationMode: "client", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], apiRef: gridApi, initialState: {
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: [search],
                            },
                        },
                    } })))));
};
export default MailScheduleErrorsTable;
