import * as React from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useLocation } from "react-router";
import RouterLink from "@app/common/RouterLink";
const ForwardBackNavigation = (props) => {
    const { paths } = props;
    const location = useLocation();
    const currentPath = location.pathname;
    const currentIndex = paths.indexOf(currentPath.split("?")[0]);
    console.log({ currentPath, currentIndex, paths });
    if (currentIndex === -1) {
        return null;
    }
    return (React.createElement(Grid, { container: true, direction: "row", spacing: 1 },
        React.createElement(Grid, { item: true },
            React.createElement(IconButton, { size: "small", tabIndex: -1, component: RouterLink, disabled: currentIndex === 0, to: paths[currentIndex - 1], state: location.state },
                React.createElement(KeyboardArrowLeft, { fontSize: "small" }))),
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, { variant: "body2", style: { color: "#888" } },
                currentIndex + 1,
                " of ",
                paths.length)),
        React.createElement(Grid, { item: true },
            React.createElement(IconButton, { size: "small", tabIndex: -1, component: RouterLink, disabled: currentIndex === paths.length - 1, to: paths[currentIndex + 1], state: location.state },
                React.createElement(KeyboardArrowRight, { fontSize: "small" })))));
};
export default ForwardBackNavigation;
