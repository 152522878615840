/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Retrieves data from the Listings spreadsheet.
 */
export const getListingsSpreadsheetData = (options) => {
    return axios.get(`/api/google_sheets/listings_spreadsheet_data`, options);
};
export const getGetListingsSpreadsheetDataQueryKey = () => {
    return [`/api/google_sheets/listings_spreadsheet_data`];
};
export const getGetListingsSpreadsheetDataQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetListingsSpreadsheetDataQueryKey();
    const queryFn = ({ signal }) => getListingsSpreadsheetData(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Retrieves data from the Listings spreadsheet.
 */
export function useGetListingsSpreadsheetData(options) {
    const queryOptions = getGetListingsSpreadsheetDataQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
