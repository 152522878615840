import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import QuestionsForTheOfferForm from "./QuestionsForTheOfferForm";
const QuestionsForTheOfferFormPage = () => {
    var _a;
    useDocumentTitle("QFO");
    const params = useParams();
    return React.createElement(QuestionsForTheOfferForm, { uid: (_a = params.uid) !== null && _a !== void 0 ? _a : null });
};
export default QuestionsForTheOfferFormPage;
