import dayjs from "dayjs";
/**
 * Determines whether a user can request Market Analyst services for a lead
 * In order for a user to be able to request Market Analyst services:
 * - If the lead is a Buyer Lead, it must have a valid Agency Effective Date and Agency Expiration Date
 * - The user must be in the Active stage (i.e. onboarding agents can't request MA services)
 *
 * @param lead
 * @param user
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getReasonsWhyUserCantRequestMAServices = (params) => {
    const { buyerLead, user } = params;
    const reasonsUserCantRequestMAServices = [];
    const now = new Date();
    const nowYYYYMMDD = dayjs(now).format("YYYY-MM-DD");
    if (buyerLead) {
        if (!buyerLead.fields["Agency Effective Date"]) {
            reasonsUserCantRequestMAServices.push("Agency Effective Date is not set");
        }
        if (!buyerLead.fields["Agency Expiration Date"]) {
            reasonsUserCantRequestMAServices.push("Agency Expiration Date is not set");
        }
        if (buyerLead.fields["Agency Effective Date"] && buyerLead.fields["Agency Effective Date"] > nowYYYYMMDD) {
            reasonsUserCantRequestMAServices.push("Agency Effective Date is in the future");
        }
        if (buyerLead.fields["Agency Expiration Date"] && buyerLead.fields["Agency Expiration Date"] < nowYYYYMMDD) {
            reasonsUserCantRequestMAServices.push("Agency has expired");
        }
    }
    if (!user || user.stage.name !== "Active") {
        reasonsUserCantRequestMAServices.push("You are not an active agent");
    }
    return reasonsUserCantRequestMAServices;
};
