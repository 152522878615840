/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List emails
 */
export const listEmails = (params, options) => {
    return axios.get(`/api/emails`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListEmailsQueryKey = (params) => {
    return [`/api/emails`, ...(params ? [params] : [])];
};
export const getListEmailsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListEmailsQueryKey(params);
    const queryFn = ({ signal }) => listEmails(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List emails
 */
export function useListEmails(params, options) {
    const queryOptions = getListEmailsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get an email
 */
export const getEmail = (uid, options) => {
    return axios.get(`/api/emails/${uid}`, options);
};
export const getGetEmailQueryKey = (uid) => {
    return [`/api/emails/${uid}`];
};
export const getGetEmailQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetEmailQueryKey(uid);
    const queryFn = ({ signal }) => getEmail(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an email
 */
export function useGetEmail(uid, options) {
    const queryOptions = getGetEmailQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Add draft to box
 */
export const addDraftToBox = (addDraftToBoxRequest, options) => {
    return axios.post(`/api/emails/add_draft_to_box`, addDraftToBoxRequest, options);
};
export const getAddDraftToBoxMutationOptions = (options) => {
    const mutationKey = ['addDraftToBox'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addDraftToBox(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add draft to box
*/
export const useAddDraftToBox = (options) => {
    const mutationOptions = getAddDraftToBoxMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add draft to box
*/
export const addThreadsFromDraft = (addThreadsFromDraftRequest, options) => {
    return axios.post(`/api/emails/add_threads_from_draft`, addThreadsFromDraftRequest, options);
};
export const getAddThreadsFromDraftMutationOptions = (options) => {
    const mutationKey = ['addThreadsFromDraft'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addThreadsFromDraft(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add draft to box
*/
export const useAddThreadsFromDraft = (options) => {
    const mutationOptions = getAddThreadsFromDraftMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add threads to box
*/
export const addThreadsToBox = (addThreadsToBoxRequest, options) => {
    return axios.post(`/api/emails/add_threads_to_box`, addThreadsToBoxRequest, options);
};
export const getAddThreadsToBoxMutationOptions = (options) => {
    const mutationKey = ['addThreadsToBox'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addThreadsToBox(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add threads to box
*/
export const useAddThreadsToBox = (options) => {
    const mutationOptions = getAddThreadsToBoxMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count emails
*/
export const countEmails = (params, options) => {
    return axios.get(`/api/emails/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountEmailsQueryKey = (params) => {
    return [`/api/emails/count`, ...(params ? [params] : [])];
};
export const getCountEmailsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountEmailsQueryKey(params);
    const queryFn = ({ signal }) => countEmails(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count emails
 */
export function useCountEmails(params, options) {
    const queryOptions = getCountEmailsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Determines if the active user has access to a message

_Authorization: none required_
 * @summary Get access to message
 */
export const getAccessToMessage = (messageId, options) => {
    return axios.get(`/api/emails/get_access_to_message/${messageId}`, options);
};
export const getGetAccessToMessageQueryKey = (messageId) => {
    return [`/api/emails/get_access_to_message/${messageId}`];
};
export const getGetAccessToMessageQueryOptions = (messageId, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAccessToMessageQueryKey(messageId);
    const queryFn = ({ signal }) => getAccessToMessage(messageId, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(messageId) }, queryOptions);
};
/**
 * @summary Get access to message
 */
export function useGetAccessToMessage(messageId, options) {
    const queryOptions = getGetAccessToMessageQueryOptions(messageId, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get messages in threads
 */
export const getMessagesInThreads = (getMessagesInThreadsBodyRequest, options) => {
    return axios.post(`/api/emails/get_messages_in_threads`, getMessagesInThreadsBodyRequest, options);
};
export const getGetMessagesInThreadsMutationOptions = (options) => {
    const mutationKey = ['getMessagesInThreads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getMessagesInThreads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get messages in threads
*/
export const useGetMessagesInThreads = (options) => {
    const mutationOptions = getGetMessagesInThreadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Uses the Gmail API to get the current user's signature

_Authorization: none required_
* @summary Get my signature
*/
export const getMySignature = (options) => {
    return axios.get(`/api/emails/get_my_signature`, options);
};
export const getGetMySignatureQueryKey = () => {
    return [`/api/emails/get_my_signature`];
};
export const getGetMySignatureQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMySignatureQueryKey();
    const queryFn = ({ signal }) => getMySignature(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get my signature
 */
export function useGetMySignature(options) {
    const queryOptions = getGetMySignatureQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get transactions for threads
 */
export const getTransactionsForThreads = (paginatedGetTransactionsForThreadsBodyRequest, options) => {
    return axios.post(`/api/emails/get_transactions_for_threads`, paginatedGetTransactionsForThreadsBodyRequest, options);
};
export const getGetTransactionsForThreadsMutationOptions = (options) => {
    const mutationKey = ['getTransactionsForThreads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getTransactionsForThreads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get transactions for threads
*/
export const useGetTransactionsForThreads = (options) => {
    const mutationOptions = getGetTransactionsForThreadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Remove threads from box
*/
export const removeThreadsFromBox = (removeThreadsFromBoxRequest, options) => {
    return axios.post(`/api/emails/remove_threads_from_box`, removeThreadsFromBoxRequest, options);
};
export const getRemoveThreadsFromBoxMutationOptions = (options) => {
    const mutationKey = ['removeThreadsFromBox'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return removeThreadsFromBox(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove threads from box
*/
export const useRemoveThreadsFromBox = (options) => {
    const mutationOptions = getRemoveThreadsFromBoxMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Sync emails
*/
export const syncEmails = (syncEmailsBodyRequest, options) => {
    return axios.post(`/api/emails/sync`, syncEmailsBodyRequest, options);
};
export const getSyncEmailsMutationOptions = (options) => {
    const mutationKey = ['syncEmails'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return syncEmails(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Sync emails
*/
export const useSyncEmails = (options) => {
    const mutationOptions = getSyncEmailsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get comments for threads
*/
export const getCommentsForThreads = (getCommentsForThreadsRequestRequest, options) => {
    return axios.post(`/api/get_comments_for_threads`, getCommentsForThreadsRequestRequest, options);
};
export const getGetCommentsForThreadsMutationOptions = (options) => {
    const mutationKey = ['getCommentsForThreads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getCommentsForThreads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get comments for threads
*/
export const useGetCommentsForThreads = (options) => {
    const mutationOptions = getGetCommentsForThreadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get notifications for threads
*/
export const getNotificationsForThreads = (getNotificationsForThreadsRequestRequest, options) => {
    return axios.post(`/api/get_notifications_for_threads`, getNotificationsForThreadsRequestRequest, options);
};
export const getGetNotificationsForThreadsMutationOptions = (options) => {
    const mutationKey = ['getNotificationsForThreads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return getNotificationsForThreads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get notifications for threads
*/
export const useGetNotificationsForThreads = (options) => {
    const mutationOptions = getGetNotificationsForThreadsMutationOptions(options);
    return useMutation(mutationOptions);
};
