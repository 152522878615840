import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import Contacts from "./Contacts";
import { SpreadsheetProvider } from "../components/spreadsheet-view/SpreadsheetContext";
import usePaginatedTable from "../components/spreadsheet-view/usePaginatedTable";
const ContactsPage = () => {
    var _a, _b;
    useDocumentTitle("Contacts");
    const { limit, offset, search, showNewsfeed, view } = usePaginatedTable();
    const [searchParams, _] = useSearchParams();
    const status = (_a = searchParams.get("status")) !== null && _a !== void 0 ? _a : undefined;
    const vendorType = (_b = searchParams.get("vendor_type")) !== null && _b !== void 0 ? _b : undefined;
    return (React.createElement(SpreadsheetProvider, null,
        React.createElement(Contacts, { limit: limit !== null && limit !== void 0 ? limit : undefined, offset: offset !== null && offset !== void 0 ? offset : undefined, search: search !== null && search !== void 0 ? search : undefined, status: status, vendorType: vendorType, view: view !== null && view !== void 0 ? view : undefined, showNewsfeed: showNewsfeed })));
};
export default ContactsPage;
