import * as React from "react";
import { Comment } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import CommentPopover from "./CommentPopover";
const CommentButton = (props) => {
    const { entityUid } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openCommentPopover = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, []);
    const closeCommentPopover = React.useCallback(() => {
        setAnchorEl(null);
    }, []);
    const onCancel = React.useCallback(() => {
        closeCommentPopover();
    }, [closeCommentPopover]);
    const onSubmit = React.useCallback(() => {
        closeCommentPopover();
    }, [closeCommentPopover]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { size: "small", onClick: openCommentPopover },
            React.createElement(Comment, { fontSize: "small" })),
        anchorEl && (React.createElement(CorePopover, { anchorEl: anchorEl, open: true, onClose: closeCommentPopover },
            React.createElement(CommentPopover, { entityUid: entityUid, onCancel: onCancel, onSubmit: onSubmit })))));
};
export default CommentButton;
