import * as React from "react";
import { Add, Cancel, Delete, Save } from "@mui/icons-material";
import { Box, Divider, Button, TextField, Grid2, Typography, List, ListItem, IconButton, ListItemText, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import Loading from "@app/common/Loading";
import usePaginatedQuery from "@app/hooks/api/usePaginatedQuery";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useDeleteOffice, useAddOffice, useGetOffice, useUpdateOffice, useListOffices } from "@app/orval/api/offices";
import { searchUsersBasic } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { replaceItemInArray } from "@app/util/Utils";
import AddMemberDialog from "./AddMemberDialog";
const DEFAULT_FORM_DATA = {
    name: "",
    address: "",
};
const OfficeForm = (props) => {
    var _a;
    const { uid } = props;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [formData, setFormData] = React.useState(DEFAULT_FORM_DATA);
    const [members, setMembers] = React.useState([]);
    const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = React.useState(null);
    const getOfficeApi = useGetOffice(uid !== null && uid !== void 0 ? uid : "", {
        query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: false }),
    });
    const addOfficeApi = useAddOffice();
    const updateOfficeApi = useUpdateOffice();
    const deleteOfficeApi = useDeleteOffice();
    const listOfficesApi = useListOffices({ query: { enabled: false } });
    const listUsersApi = usePaginatedQuery(searchUsersBasic, {});
    const users = React.useMemo(() => {
        var _a;
        return (_a = listUsersApi.data) !== null && _a !== void 0 ? _a : null;
    }, [listUsersApi.data]);
    const usersByUid = React.useMemo(() => {
        return users === null || users === void 0 ? void 0 : users.reduce((acc, x) => {
            acc[x.uid] = x;
            return acc;
        }, {});
    }, [users]);
    React.useEffect(() => {
        if (props.uid) {
            if (getOfficeApi.data) {
                setFormData(getOfficeApi.data.data);
            }
            else {
                getOfficeApi
                    .refetch()
                    .then((result) => {
                    var _a, _b, _c, _d;
                    setFormData({
                        name: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data.name) !== null && _b !== void 0 ? _b : "",
                        address: (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.data.address) !== null && _d !== void 0 ? _d : null,
                    });
                })
                    .catch(errorDialog);
            }
        }
        else {
            setFormData(DEFAULT_FORM_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    React.useEffect(() => {
        setMembers(uid
            ? (users !== null && users !== void 0 ? users : [])
                .filter((x) => { var _a; return ((_a = x.office) === null || _a === void 0 ? void 0 : _a.uid) === uid; })
                .map((x) => ({
                user_uid: x.uid,
                is_managing_partner: x.roles.includes("Managing Partner"),
            }))
            : []);
    }, [uid, users]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const setAddress = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { address: e.target.value })));
    }, [setFormData]);
    const cancel = React.useCallback(() => {
        navigate("/offices");
    }, [navigate]);
    const deleteOffice = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listOfficesApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((item) => item.uid !== uid) });
                });
                deleteOfficeApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    console.log("deleted office");
                    navigate("/offices");
                })
                    .catch(errorDialog);
            }
        });
    }, [uid, queryClient, listOfficesApi.queryKey, deleteOfficeApi, confirm, errorDialog, navigate]);
    const saveOffice = React.useCallback(() => {
        if (uid) {
            updateOfficeApi
                .mutateAsync({ uid, data: Object.assign(Object.assign({}, formData), { members }) })
                .then((result) => {
                queryClient.setQueryData(listOfficesApi.queryKey, (old) => {
                    if (old) {
                        return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                    }
                    return undefined;
                });
                queryClient.setQueryData(getOfficeApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: result.data });
                });
                navigate("/offices");
            })
                .catch(errorDialog);
        }
        else {
            addOfficeApi
                .mutateAsync({ data: formData })
                .then((result) => {
                queryClient.setQueryData(listOfficesApi.queryKey, (old) => {
                    if (old) {
                        return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                    }
                    return undefined;
                });
                navigate("/offices");
            })
                .catch(errorDialog);
        }
    }, [
        uid,
        updateOfficeApi,
        formData,
        members,
        errorDialog,
        queryClient,
        listOfficesApi.queryKey,
        getOfficeApi.queryKey,
        navigate,
        addOfficeApi,
    ]);
    const openAddMemberDialog = React.useCallback(() => {
        setIsAddMemberDialogOpen(true);
    }, []);
    const closeAddMemberDialog = React.useCallback(() => {
        setIsAddMemberDialogOpen(false);
    }, []);
    const addMember = React.useCallback((params) => {
        setMembers((prev) => [
            ...(prev !== null && prev !== void 0 ? prev : []),
            {
                user_uid: params.userUid,
                is_managing_partner: params.isManagingPartner,
            },
        ]);
        closeAddMemberDialog();
    }, [closeAddMemberDialog]);
    const removeMember = React.useCallback((memberUid) => () => {
        setMembers((prev) => (prev !== null && prev !== void 0 ? prev : []).filter((x) => x.user_uid !== memberUid));
    }, []);
    const isValid = !!formData.name.trim();
    const isMutating = addOfficeApi.status === "pending" || updateOfficeApi.status === "pending" || deleteOfficeApi.status === "pending";
    const loading = [
        {
            label: "Loading office",
            queryResult: getOfficeApi,
        },
        {
            label: "Loading users...",
            status: (listUsersApi.error ? "error" : listUsersApi.isFetching ? "pending" : "success"),
        },
    ].filter((_) => !!uid);
    const showInfiniteSpinner = uid ? getOfficeApi.status === "pending" : false;
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 2, wrap: "nowrap", style: { maxHeight: "100%", msOverflowY: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Name", value: formData.name, onChange: setName, slotProps: {
                                htmlInput: {
                                    maxLength: 255,
                                },
                            } })),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Address", value: (_a = formData.address) !== null && _a !== void 0 ? _a : "", onChange: setAddress, minRows: 1, maxRows: 5, multiline: true }))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, { style: { overflowY: "auto" } },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h6" }, "Members")),
                    members && members.length > 0 && (React.createElement(Grid2, null,
                        React.createElement(List, { dense: true }, members
                            .sort((a, b) => {
                            if (a.is_managing_partner && !b.is_managing_partner)
                                return -1;
                            if (!a.is_managing_partner && b.is_managing_partner)
                                return 1;
                            const userA = usersByUid === null || usersByUid === void 0 ? void 0 : usersByUid[a.user_uid];
                            const userB = usersByUid === null || usersByUid === void 0 ? void 0 : usersByUid[b.user_uid];
                            const aName = userA ? userA.name : "";
                            const bName = userB ? userB.name : "";
                            if (aName < bName)
                                return -1;
                            if (aName > bName)
                                return 1;
                            return 0;
                        })
                            .map((member, i) => (React.createElement(ListItem, { key: member.user_uid, secondaryAction: React.createElement(IconButton, { size: "small", onClick: removeMember(member.user_uid) },
                                React.createElement(Delete, { fontSize: "small" })), divider: i < members.length - 1 },
                            React.createElement(ListItemText, { primary: (usersByUid === null || usersByUid === void 0 ? void 0 : usersByUid[member.user_uid]) ? usersByUid[member.user_uid].name : null, secondary: member.is_managing_partner ? "Managing Partner" : "" })))))))),
                isAddMemberDialogOpen && (React.createElement(AddMemberDialog, { DialogProps: { open: true }, onClose: closeAddMemberDialog, onAdd: addMember, currentMembers: (members !== null && members !== void 0 ? members : []).map((x) => x.user_uid) })))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                    uid && (React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", color: "error", tabIndex: -1, disabled: isMutating, startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: deleteOffice }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", startIcon: React.createElement(Add, null), onClick: openAddMemberDialog, disabled: isMutating || !users, tabIndex: -1 }, "Add member")),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { startIcon: React.createElement(Cancel, null), onClick: cancel, disabled: isMutating, tabIndex: -1 }, "Cancel")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { startIcon: React.createElement(Save, null), onClick: saveOffice, disabled: isMutating || !isValid, color: "primary", variant: "outlined", tabIndex: -1 }, "Save")))))));
};
export default OfficeForm;
