var _a, _b, _c, _d;
import { variant } from "@app/util/Utils";
import createRoot from "./setup";
console.log({ variant });
const routerType = (_a = {
    gmail: "hash",
    sidebar: "memory",
    followupboss: "hash",
    "followupboss embedded app": "memory",
}[variant]) !== null && _a !== void 0 ? _a : "browser";
const showSidebar = (_b = {
    standard: true,
    followupboss: true,
}[variant]) !== null && _b !== void 0 ? _b : false;
const showHeader = (_c = {
    standard: true,
}[variant]) !== null && _c !== void 0 ? _c : false;
const forceSmallDevice = (_d = {
    sidebar: true,
    "followupboss embedded app": true,
}[variant]) !== null && _d !== void 0 ? _d : false;
createRoot("#root", {
    showSidebar,
    showHeader,
    routerType: routerType,
    // routerBasename: "dashcrm",
    routerBasename: "/",
    apiHeaders: Object.assign(Object.assign({}, window._DASH_API_HEADERS), (window._DASH_AUTH_TOKEN
        ? {
            // @ts-expect-error
            Authorization: `Bearer ${window._DASH_AUTH_TOKEN}`,
        }
        : {})),
    // @ts-expect-error
    initialRoute: window._INITIAL_ROUTE,
    forceSmallDevice,
    wrapperProps: {},
});
