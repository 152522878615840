import * as React from "react";
import { Grid2, Link, Typography } from "@mui/material";
import numeral from "numeral";
import { getBathroomsFull, getStructureType } from "./utils";
const Remarks = (props) => {
    var _a;
    const { property } = props;
    const publicRemarks = property["PublicRemarks"];
    const privateRemarks = property["PrivateRemarks"];
    const source = property["Source"];
    const hoaInfo = [
        {
            name: "HOA 1 Management",
            value: property["AssociationName"],
        },
        {
            name: "HOA 1 Phone",
            value: property["AssociationPhone"],
        },
        {
            name: "HOA 1 Fees",
            value: property["AssociationFee"],
        },
        {
            name: "HOA 2 Management",
            value: property["AssociationName2"],
        },
        {
            name: "HOA 2 Phone",
            value: property["AssociationPhone2"],
        },
        {
            name: "HOA 2 Fees",
            value: property["AssociationFee2"],
        },
    ];
    const hoaItems = hoaInfo
        .filter((item) => item.value != null)
        .map((item) => (React.createElement(Grid2, { key: item.name, size: 12 },
        React.createElement(Typography, { variant: "body2" }, `${item.name}: ${item.value}`))));
    const constructionType = getStructureType(property);
    let levels = (_a = property["Levels"]) !== null && _a !== void 0 ? _a : [];
    if (typeof levels === "string") {
        levels = levels.split(",");
    }
    const finishedBelowGrade = source === "Bridge"
        ? numeral(property["BelowGradeFinishedArea"]).value()
        : numeral(property["BelowGradeFinishedArea"]).value();
    const unfinishedBelowGrade = source === "Bridge"
        ? numeral(property["TMLS_LM_Int2_8"]).value()
        : numeral(property["CAR_SqFtUnheatedBasement"]).value();
    const belowGrade = finishedBelowGrade + unfinishedBelowGrade;
    let basement = source === "Bridge" ? property["Basement"] : property["FoundationDetails"];
    if (typeof basement === "string") {
        basement = basement.split(",");
    }
    const fullBaths = getBathroomsFull(property);
    const halfBaths = property["BathroomsHalf"];
    return (React.createElement(Grid2, { container: true, spacing: 4 },
        React.createElement(Grid2, { container: true, size: 12, spacing: 1 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, null, "Public Remarks")),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, publicRemarks !== null && publicRemarks !== void 0 ? publicRemarks : "none"))),
        React.createElement(Grid2, { container: true, size: 12, spacing: 1 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, null, "Private Remarks")),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, privateRemarks !== null && privateRemarks !== void 0 ? privateRemarks : "none"))),
        React.createElement(Grid2, { container: true, size: 12, spacing: 1 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, null, "Property Info")),
            constructionType && (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, `Construction Type: ${Array.isArray(constructionType) ? constructionType.join(", ") : constructionType}`))),
            levels.length > 0 ? (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, `Design: ${levels[0]}${["split level"].includes(levels[0].toLowerCase().trim()) ||
                    levels[0].toLowerCase().trim().includes("story")
                    ? ""
                    : " Story"}`))) : null,
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, `Basement: ${(basement !== null && basement !== void 0 ? basement : []).length === 0 ? "No" : ""}`),
                (basement !== null && basement !== void 0 ? basement : []).length > 0
                    ? basement.map((item) => (React.createElement(Typography, { key: `basement_${item}`, variant: "body2" },
                        React.createElement("li", null, item))))
                    : null),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, `Area Below Grade: ${belowGrade}`),
                belowGrade > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("li", null, `Finished: ${finishedBelowGrade}`)),
                    React.createElement(Typography, { variant: "body2" },
                        React.createElement("li", null, `Unfinished: ${unfinishedBelowGrade}`))))),
            fullBaths != null && (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, `Full Baths: ${fullBaths}`))),
            halfBaths != null && (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, `Half Baths: ${halfBaths}`)))),
        hoaItems.length > 0 && (React.createElement(Grid2, { container: true, size: 12 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, null, "HOA")),
            hoaItems)),
        React.createElement(Grid2, { container: true, size: 12 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, null, "Listing Agent")),
            property["ListAgentFullName"] && (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, property["ListAgentFullName"]))),
            property["ListOfficeName"] && (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, property["ListOfficeName"]))),
            property["ListAgentEmail"] && (React.createElement(Grid2, { size: 12 },
                React.createElement(Link, { href: `mailto:${property["ListAgentEmail"]}` },
                    React.createElement(Typography, { variant: "body2" }, property["ListAgentEmail"])))),
            property["ListAgentDirectPhone"] && (React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "body2" }, property["ListAgentDirectPhone"]))))));
};
export default Remarks;
