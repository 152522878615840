import * as React from "react";
import { Add, Clear, Delete, KeyboardArrowUp, KeyboardArrowDown, Save } from "@mui/icons-material";
import { Box, Button, Checkbox, Container, Divider, FormControlLabel, IconButton, MenuItem, Table, TableBody, TableRow, TableCell, TextField, Tooltip, Typography, Grid2, } from "@mui/material";
import omit from "lodash/omit";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useListCompsfinderPropertyStatuses } from "@app/orval/api/comps-finder-property-statuses";
import { useListCompsfinderPropertyTypes } from "@app/orval/api/comps-finder-property-types";
import { useCreateCompsfinderRuleset, useDeleteCompsfinderRuleset, useGetCompsfinderRuleset, useUpdateCompsfinderRuleset, } from "@app/orval/api/comps-finder-rule-sets";
import { useListCompsfinderStates } from "@app/orval/api/comps-finder-states";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const defaultPass = {
    same_property_type: true,
    same_property_subtype: true,
    same_subdivision: false,
    same_city: false,
    same_state: true,
    same_zip: false,
    max_distance: 5,
    min_full_baths: -1,
    max_full_baths: 1,
    min_beds: -1,
    max_beds: 1,
    min_garage_spaces: -1,
    max_garage_spaces: 1,
    min_year_built: -10,
    max_year_built: 10,
    min_living_area_sqft_pct_difference: 0.8,
    max_living_area_sqft_pct_difference: 1.2,
    min_close_date: 180,
    property_status: null,
    quality: 5,
};
const current_year = new Date().getFullYear();
const CompsPullerRuleSetForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { uid } = props;
    const navigate = useNavigate();
    const confirm = useConfirm();
    const getRuleSetApi = useGetCompsfinderRuleset(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: !!uid } });
    const ruleSet = (_b = (_a = getRuleSetApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listCompsfinderStatesApi = useListCompsfinderStates();
    const states = (_d = (_c = listCompsfinderStatesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listCompsfinderPropertyStatusesApi = useListCompsfinderPropertyStatuses();
    const propertyStatuses = (_f = (_e = listCompsfinderPropertyStatusesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listCompsfinderPropertyTypesApi = useListCompsfinderPropertyTypes();
    const propertyTypes = (_h = (_g = listCompsfinderPropertyTypesApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const createRuleSetApi = useCreateCompsfinderRuleset();
    const updateRuleSetApi = useUpdateCompsfinderRuleset();
    const deleteRuleSetApi = useDeleteCompsfinderRuleset();
    const [formData, setFormData] = React.useState(null);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        if (ruleSet) {
            setFormData(Object.assign(Object.assign({}, ruleSet), { listing_criteria: Object.assign(Object.assign({}, ruleSet.listing_criteria), { cities: (_b = (_a = ruleSet.listing_criteria.cities) === null || _a === void 0 ? void 0 : _a.join(", ")) !== null && _b !== void 0 ? _b : "", subdivisions: (_d = (_c = ruleSet.listing_criteria.subdivisions) === null || _c === void 0 ? void 0 : _c.join(", ")) !== null && _d !== void 0 ? _d : "", zipcodes: (_f = (_e = ruleSet.listing_criteria.zipcodes) === null || _e === void 0 ? void 0 : _e.join(", ")) !== null && _f !== void 0 ? _f : "" }), passes: ruleSet.passes.map((pass) => (Object.assign(Object.assign({}, pass), { randomId: uuidv4() }))) }));
        }
        else {
            setFormData({
                name: "",
                listing_criteria: {
                    sqft_gte: null,
                    sqft_lte: null,
                    full_baths_gte: null,
                    full_baths_lte: null,
                    beds_gte: null,
                    beds_lte: null,
                    garage_spaces_gte: null,
                    garage_spaces_lte: null,
                    year_built_gte: null,
                    year_built_lte: null,
                    list_price_gte: null,
                    list_price_lte: null,
                    property_types: null,
                    states: null,
                    cities: null,
                    zipcodes: null,
                    subdivisions: null,
                },
                passes: [
                    Object.assign(Object.assign({}, defaultPass), { randomId: uuidv4() }),
                ],
            });
        }
    }, [ruleSet]);
    const save = React.useCallback(() => {
        var _a, _b, _c;
        const loadingSnackbar = enqueueSnackbar("Saving rule set...", { variant: "info", persist: true });
        const data = Object.assign(Object.assign({}, formData), { listing_criteria: Object.assign(Object.assign({}, formData.listing_criteria), { cities: ((_a = formData.listing_criteria.cities) === null || _a === void 0 ? void 0 : _a.split(",").map((s) => s.trim()).filter((x) => !!x)) || null, subdivisions: ((_b = formData.listing_criteria.subdivisions) === null || _b === void 0 ? void 0 : _b.split(",").map((s) => s.trim()).filter((x) => !!x)) || null, zipcodes: ((_c = formData.listing_criteria.zipcodes) === null || _c === void 0 ? void 0 : _c.split(",").map((s) => s.trim()).filter((x) => !!x)) || null }), passes: formData.passes.map((pass) => {
                return omit(pass, "randomId");
            }) });
        console.log({ data });
        if (uid) {
            updateRuleSetApi
                .mutateAsync({ uid, data })
                .then(() => {
                navigate("/tools/comps-puller/rules");
                enqueueSnackbar("Rule has been saved", { variant: "success" });
            })
                .catch(enqueueErrorSnackbar)
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
        else {
            createRuleSetApi
                .mutateAsync({ data })
                .then(() => {
                navigate("/tools/comps-puller/rules");
                enqueueSnackbar("Rule has been saved", { variant: "success" });
            })
                .catch(enqueueErrorSnackbar)
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
    }, [createRuleSetApi, formData, navigate, uid, updateRuleSetApi]);
    const isSaving = React.useMemo(() => {
        return createRuleSetApi.isPending || updateRuleSetApi.isPending;
    }, [createRuleSetApi.isPending, updateRuleSetApi.isPending]);
    const onDelete = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                const loadingSnackbar = enqueueSnackbar("Deleting ruleSet...", { variant: "info", persist: true });
                deleteRuleSetApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    navigate("/tools/comps-puller/rules");
                    enqueueSnackbar("Rule has been deleted", { variant: "success" });
                })
                    .catch(enqueueErrorSnackbar)
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [confirm, deleteRuleSetApi, navigate, uid]);
    const onCancel = React.useCallback(() => {
        navigate("/tools/comps-puller/rules");
    }, [navigate]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, []);
    const setListingCriteriaSqftGTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { sqft_gte: value }) })));
    }, []);
    const setListingCriteriaSqftLTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { sqft_lte: value }) })));
    }, []);
    const setListingCriteriaFullBathsGTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { full_baths_gte: value }) })));
    }, []);
    const setListingCriteriaFullBathsLTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { full_baths_lte: value }) })));
    }, []);
    const setListingCriteriaBedsGTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { beds_gte: value }) })));
    }, []);
    const setListingCriteriaBedsLTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { beds_lte: value }) })));
    }, []);
    const setListingCriteriaGarageSpacesGTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { garage_spaces_gte: value }) })));
    }, []);
    const setListingCriteriaGarageSpacesLTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { garage_spaces_lte: value }) })));
    }, []);
    const setListingCriteriaYearBuiltGTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { year_built_gte: value }) })));
    }, []);
    const setListingCriteriaYearBuiltLTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { year_built_lte: value }) })));
    }, []);
    const setListingCriteriaListPriceGTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { list_price_gte: value }) })));
    }, []);
    const setListingCriteriaListPriceLTE = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { list_price_lte: value }) })));
    }, []);
    const setListingCriteriaPropertyTypes = React.useCallback((property_types) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { property_types: property_types.length > 0 ? property_types : null }) })));
    }, []);
    const setListingCriteriaStates = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { states: val.length > 0 ? val : null }) })));
    }, []);
    const setListingCriteriaCities = React.useCallback((e) => {
        const cities = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { cities }) })));
    }, []);
    const setListingCriteriaSubdivisions = React.useCallback((e) => {
        const subdivisions = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { subdivisions }) })));
    }, []);
    const setListingCriteriaZipCodes = React.useCallback((e) => {
        const zipcodes = e.target.value;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { listing_criteria: Object.assign(Object.assign({}, prev.listing_criteria), { zipcodes }) })));
    }, []);
    const addPass = React.useCallback(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: [
                ...prev.passes,
                Object.assign(Object.assign({}, defaultPass), { randomId: uuidv4() }),
            ] })));
    }, []);
    const deletePass = React.useCallback((randomId) => () => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.filter((pass) => pass.randomId !== randomId) })));
    }, []);
    const movePass = React.useCallback((randomId, direction) => () => {
        setFormData((prev) => {
            const { passes } = prev;
            const selectedPassIndex = passes.findIndex((pass) => pass.randomId === randomId);
            const selectedPass = passes[selectedPassIndex];
            passes.splice(selectedPassIndex, 1);
            passes.splice(selectedPassIndex + (direction === "up" ? -1 : 1), 0, selectedPass);
            return Object.assign(Object.assign({}, prev), { passes });
        });
    }, []);
    const setPassSamePropertyType = React.useCallback((passIndex) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { same_property_type: checked });
                }
                return pass;
            }) })));
    }, []);
    const setPassSamePropertySubtype = React.useCallback((passIndex) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { same_property_subtype: checked });
                }
                return pass;
            }) })));
    }, []);
    const setPassSameState = React.useCallback((passIndex) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { same_state: checked });
                }
                return pass;
            }) })));
    }, []);
    const setPassSameCity = React.useCallback((passIndex) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { same_city: checked });
                }
                return pass;
            }) })));
    }, []);
    const setPassSameSubdivision = React.useCallback((passIndex) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { same_subdivision: checked });
                }
                return pass;
            }) })));
    }, []);
    const setPassSameZipcode = React.useCallback((passIndex) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { same_zip: checked });
                }
                return pass;
            }) })));
    }, []);
    const setPassLivingAreaSqftMin = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { min_living_area_sqft_pct_difference: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassLivingAreaSqftMax = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { max_living_area_sqft_pct_difference: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassFullBathsMin = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { min_full_baths: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassFullBathsMax = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { max_full_baths: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassBedsMin = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { min_beds: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassBedsMax = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { max_beds: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassGarageSpacesMin = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { min_garage_spaces: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassGarageSpacesMax = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { max_garage_spaces: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassYearBuiltMin = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { min_year_built: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassYearBuiltMax = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { max_year_built: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassPropertyStatus = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { property_status: value.length > 0 ? value : null });
                }
                return pass;
            }) })));
    }, []);
    const setPassMaxDistance = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { max_distance: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassMinCloseDate = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { min_close_date: value });
                }
                return pass;
            }) })));
    }, []);
    const setPassquality = React.useCallback((passIndex) => (value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { passes: prev.passes.map((pass, index) => {
                if (index === passIndex) {
                    return Object.assign(Object.assign({}, pass), { quality: value !== null && value !== void 0 ? value : 0 });
                }
                return pass;
            }) })));
    }, []);
    if (formData == null) {
        return React.createElement(CoreLoading, null);
    }
    if (uid != null && !ruleSet) {
        return React.createElement(CoreLoading, null);
    }
    if (getRuleSetApi.error) {
        return React.createElement(CoreError, { error: getRuleSetApi.error });
    }
    return (React.createElement(Grid2, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 4 },
                React.createElement(TextField, { variant: "outlined", size: "small", label: "Name", fullWidth: true, value: formData.name, onChange: setName }))),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Container, { maxWidth: "md" },
                    React.createElement(Grid2, { container: true, spacing: 1, direction: "column" },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "h6" }, "Listing Criteria")),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2" }, "A subject property must match all of the below criteria in order for this rule set to apply.")),
                        React.createElement(Grid2, null,
                            React.createElement(Table, { size: "small" },
                                React.createElement(TableBody, null,
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Square Footage")),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Greater than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.sqft_gte, onNumberChange: setListingCriteriaSqftGTE })),
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Sq Ft")))),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Less than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.sqft_lte, onNumberChange: setListingCriteriaSqftLTE })),
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Sq Ft"))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Full Baths")),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Greater than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.full_baths_gte, onNumberChange: setListingCriteriaFullBathsGTE })))),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Less than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.full_baths_lte, onNumberChange: setListingCriteriaFullBathsLTE }))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Bedrooms")),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Greater than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.beds_gte, onNumberChange: setListingCriteriaBedsGTE })))),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Less than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.beds_lte, onNumberChange: setListingCriteriaBedsLTE }))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Garage Spaces")),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Greater than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.garage_spaces_gte, onNumberChange: setListingCriteriaGarageSpacesGTE })))),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Less than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: formData.listing_criteria.garage_spaces_lte, onNumberChange: setListingCriteriaGarageSpacesLTE }))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Year Built")),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Greater than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0", placeholder: "any", min: 1600, max: current_year, number: formData.listing_criteria.year_built_gte, onNumberChange: setListingCriteriaYearBuiltGTE })))),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Less than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0", placeholder: "any", min: 1600, max: current_year, number: formData.listing_criteria.year_built_lte, onNumberChange: setListingCriteriaYearBuiltLTE }))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "List Price")),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Greater than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0", placeholder: "any", min: 0, number: formData.listing_criteria.list_price_gte, onNumberChange: setListingCriteriaListPriceGTE })))),
                                        React.createElement(TableCell, null,
                                            React.createElement(Grid2, { container: true, spacing: 2 },
                                                React.createElement(Grid2, null,
                                                    React.createElement(Typography, null, "Less than or equal to")),
                                                React.createElement(Grid2, null,
                                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0", placeholder: "any", min: 0, number: formData.listing_criteria.list_price_lte, onNumberChange: setListingCriteriaListPriceLTE }))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Property Types")),
                                        React.createElement(TableCell, { colSpan: 2 },
                                            React.createElement(CoreChoiceField, { variant: "standard", multiple: true, fullWidth: true, placeholder: "any", value: (_j = formData.listing_criteria.property_types) !== null && _j !== void 0 ? _j : [], onChoicesChange: setListingCriteriaPropertyTypes, options: (propertyTypes !== null && propertyTypes !== void 0 ? propertyTypes : [])
                                                    .map((obj) => ({
                                                    value: obj.name,
                                                    label: obj.name,
                                                }))
                                                    .reduce((dict, obj) => (Object.assign(Object.assign({}, dict), { [obj.value]: obj.label })), {}) }, (propertyTypes !== null && propertyTypes !== void 0 ? propertyTypes : []).map((propertyType) => (React.createElement(MenuItem, { key: propertyType.name, value: propertyType.name }, propertyType.name)))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "State")),
                                        React.createElement(TableCell, { colSpan: 2 },
                                            React.createElement(CoreChoiceField, { variant: "standard", multiple: true, fullWidth: true, placeholder: "any", value: (_k = formData.listing_criteria.states) !== null && _k !== void 0 ? _k : [], onChoicesChange: setListingCriteriaStates, options: (states !== null && states !== void 0 ? states : [])
                                                    .map((obj) => ({
                                                    value: obj.name,
                                                    label: obj.abbreviation,
                                                }))
                                                    .reduce((dict, obj) => (Object.assign(Object.assign({}, dict), { [obj.value]: obj.label })), {}) }, (states !== null && states !== void 0 ? states : []).map((state) => (React.createElement(MenuItem, { key: state.name, value: state.name }, state.abbreviation)))))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Cities")),
                                        React.createElement(TableCell, { colSpan: 2 },
                                            React.createElement(TextField, { variant: "standard", fullWidth: true, placeholder: "Comma-separated list", value: (_l = formData.listing_criteria.cities) !== null && _l !== void 0 ? _l : "", onChange: setListingCriteriaCities }))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Subdivisions")),
                                        React.createElement(TableCell, { colSpan: 2 },
                                            React.createElement(TextField, { variant: "standard", fullWidth: true, placeholder: "Comma-separated list", value: (_m = formData.listing_criteria.subdivisions) !== null && _m !== void 0 ? _m : "", onChange: setListingCriteriaSubdivisions }))),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement(Typography, null, "Zip Codes")),
                                        React.createElement(TableCell, { colSpan: 2 },
                                            React.createElement(TextField, { variant: "standard", fullWidth: true, placeholder: "Comma-separated list", value: (_o = formData.listing_criteria.zipcodes) !== null && _o !== void 0 ? _o : "", onChange: setListingCriteriaZipCodes })))))))))),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Container, { maxWidth: "md" },
                    React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "h6" }, "Passes")),
                        React.createElement(Grid2, null,
                            React.createElement(Grid2, { container: true, spacing: 4 }, formData.passes.map((pass, passIndex) => {
                                var _a;
                                return (React.createElement(React.Fragment, null,
                                    passIndex > 0 && (React.createElement(Grid2, null,
                                        React.createElement(Divider, null))),
                                    React.createElement(Grid2, { key: pass.randomId },
                                        React.createElement(Grid2, { container: true, spacing: 2 },
                                            React.createElement(Grid2, null,
                                                React.createElement(Grid2, { container: true, direction: "column" },
                                                    React.createElement(Grid2, null,
                                                        React.createElement(Typography, { variant: "h1" }, passIndex + 1)),
                                                    React.createElement(Grid2, null,
                                                        React.createElement(Tooltip, { title: "Move up" },
                                                            React.createElement("span", null,
                                                                React.createElement(IconButton, { onClick: movePass(pass.randomId, "up"), disabled: passIndex === 0 },
                                                                    React.createElement(KeyboardArrowUp, null))))),
                                                    React.createElement(Grid2, null,
                                                        React.createElement(Tooltip, { title: "Move down" },
                                                            React.createElement("span", null,
                                                                React.createElement(IconButton, { onClick: movePass(pass.randomId, "down"), disabled: passIndex === formData.passes.length - 1 },
                                                                    React.createElement(KeyboardArrowDown, null))))),
                                                    React.createElement(Grid2, null,
                                                        React.createElement(Divider, null)),
                                                    React.createElement(Grid2, null,
                                                        React.createElement(Tooltip, { title: "Delete pass" },
                                                            React.createElement("span", null,
                                                                React.createElement(IconButton, { onClick: deletePass(pass.randomId), disabled: formData.passes.length <= 1 },
                                                                    React.createElement(Delete, null))))))),
                                            React.createElement(Grid2, { style: { flex: 1 } },
                                                React.createElement(Grid2, { container: true, spacing: 1 },
                                                    React.createElement(Grid2, { size: { xs: 12, md: 4 } },
                                                        React.createElement(Grid2, { container: true, spacing: 0 },
                                                            React.createElement(Grid2, null,
                                                                React.createElement(FormControlLabel, { label: "Same property type", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: pass.same_property_type, onChange: setPassSamePropertyType(passIndex) }) })),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(FormControlLabel, { label: "Same property subtype", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: pass.same_property_subtype, onChange: setPassSamePropertySubtype(passIndex) }) })),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(FormControlLabel, { label: "Same state", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: pass.same_state, onChange: setPassSameState(passIndex) }) })),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(FormControlLabel, { label: "Same city", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: pass.same_city, onChange: setPassSameCity(passIndex) }) })),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(FormControlLabel, { label: "Same subdivision", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: pass.same_subdivision, onChange: setPassSameSubdivision(passIndex) }) })),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(FormControlLabel, { label: "Same zip code", control: React.createElement(Checkbox, { size: "small", color: "primary", checked: pass.same_zip, onChange: setPassSameZipcode(passIndex) }) })),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(Grid2, { container: true, spacing: 1 },
                                                                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                                                        React.createElement(Typography, { variant: "body2" }, "Within")),
                                                                    React.createElement(Grid2, { style: { width: "50px" } },
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0.0", placeholder: "any", min: 0, number: pass.max_distance, onNumberChange: setPassMaxDistance(passIndex) })),
                                                                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                                                        React.createElement(Typography, { variant: "body2" }, "miles of S.P.")))),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(Grid2, { container: true },
                                                                    React.createElement(Grid2, null,
                                                                        React.createElement(Grid2, { container: true, spacing: 1 },
                                                                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                                                                React.createElement(Typography, { variant: "body2" }, "Closed in last")),
                                                                            React.createElement(Grid2, { style: { width: "50px" } },
                                                                                React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: 0, number: pass.min_close_date, onNumberChange: setPassMinCloseDate(passIndex) })),
                                                                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                                                                React.createElement(Typography, { variant: "body2" }, "days*")))),
                                                                    React.createElement(Grid2, null,
                                                                        React.createElement(Typography, { variant: "caption", style: { marginLeft: "0.5rem" } }, "*Applies to Closed comps only")))),
                                                            React.createElement(Grid2, null,
                                                                React.createElement(Grid2, { container: true, spacing: 1 },
                                                                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                                                        React.createElement(Typography, { variant: "body2" }, "Quality")),
                                                                    React.createElement(Grid2, { style: { width: "50px" } },
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0.0", placeholder: "any", min: 0, number: pass.quality, onNumberChange: setPassquality(passIndex) })))))),
                                                    React.createElement(Grid2, { size: { md: 8 } },
                                                        React.createElement(Table, { size: "small" },
                                                            React.createElement(TableBody, null,
                                                                React.createElement(TableRow, null,
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, null, "Square Footage")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0.00%", placeholder: "any", min: 0, number: pass.min_living_area_sqft_pct_difference, onNumberChange: setPassLivingAreaSqftMin(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "to")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0.00%", placeholder: "any", min: 0, number: pass.max_living_area_sqft_pct_difference, onNumberChange: setPassLivingAreaSqftMax(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "relative to SP"))),
                                                                React.createElement(TableRow, null,
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, null, "Full Baths")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -100, max: 100, number: pass.min_full_baths, onNumberChange: setPassFullBathsMin(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "to")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -100, max: 100, number: pass.max_full_baths, onNumberChange: setPassFullBathsMax(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "relative to SP"))),
                                                                React.createElement(TableRow, null,
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, null, "Bedrooms")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -100, max: 100, number: pass.min_beds, onNumberChange: setPassBedsMin(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "to")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -100, max: 100, number: pass.max_beds, onNumberChange: setPassBedsMax(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "relative to SP"))),
                                                                React.createElement(TableRow, null,
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, null, "Garage Spaces")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -100, max: 100, number: pass.min_garage_spaces, onNumberChange: setPassGarageSpacesMin(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "to")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -100, max: 100, number: pass.max_garage_spaces, onNumberChange: setPassGarageSpacesMax(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "relative to SP"))),
                                                                React.createElement(TableRow, null,
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, null, "Year Built")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -1000, max: 1000, number: pass.min_year_built, onNumberChange: setPassYearBuiltMin(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "to")),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, format: "0,0", placeholder: "any", min: -1000, max: 1000, number: pass.max_year_built, onNumberChange: setPassYearBuiltMax(passIndex) })),
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, { variant: "body2" }, "relative to SP"))),
                                                                React.createElement(TableRow, null,
                                                                    React.createElement(TableCell, null,
                                                                        React.createElement(Typography, null, "MLS Status")),
                                                                    React.createElement(TableCell, { colSpan: 4 },
                                                                        React.createElement(CoreChoiceField, { variant: "standard", multiple: true, fullWidth: true, placeholder: "Any", value: (_a = pass.property_status) !== null && _a !== void 0 ? _a : [], onChoicesChange: setPassPropertyStatus(passIndex), options: (propertyStatuses !== null && propertyStatuses !== void 0 ? propertyStatuses : [])
                                                                                .map((obj) => ({
                                                                                value: obj.name,
                                                                                label: obj.name,
                                                                            }))
                                                                                .reduce((dict, obj) => (Object.assign(Object.assign({}, dict), { [obj.value]: obj.label })), {}) }, (propertyStatuses !== null && propertyStatuses !== void 0 ? propertyStatuses : []).map((status) => (React.createElement(MenuItem, { key: status.name, value: status.name }, status.name)))))))))))))));
                            }))))))),
        React.createElement(Grid2, { style: { textAlign: "center" } },
            React.createElement(Button, { color: "primary", startIcon: React.createElement(Add, null), onClick: addPass, variant: "outlined" }, "Add pass")),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    uid && (React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", startIcon: React.createElement(Delete, null), variant: "contained", color: "secondary", onClick: onDelete, disabled: isSaving || deleteRuleSetApi.isPending }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", startIcon: React.createElement(Clear, null), color: "inherit", onClick: onCancel, disabled: isSaving || deleteRuleSetApi.isPending }, "Cancel")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", startIcon: React.createElement(Save, null), variant: "contained", color: "primary", onClick: save, disabled: isSaving || deleteRuleSetApi.isPending }, "Save")))))));
};
export default CompsPullerRuleSetForm;
