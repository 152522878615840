import * as React from "react";
import { Alert, Typography } from "@mui/material";
import slackIcon from "@app/assets/img/slack.png";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const SlackChannelsMissingSlackID = () => {
    return (React.createElement(CollapsibleSection, { icon: React.createElement("img", { src: slackIcon, height: 20, alt: "Slack" }), title: "Slack Channels", isCollapsible: false },
        React.createElement(Alert, { severity: "warning" },
            React.createElement(Typography, null, "This user is missing a Slack ID"))));
};
export default SlackChannelsMissingSlackID;
