import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetView } from "@app/orval/api/views";
const ViewPage = () => {
    var _a, _b;
    useDocumentTitle("Views");
    const navigate = useNavigate();
    const { uid } = useParams();
    const getViewApi = useGetView(uid);
    const view = (_b = (_a = getViewApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    React.useEffect(() => {
        if (view === null || view === void 0 ? void 0 : view.uid) {
            let url;
            if (view.entity_type === "Buyer Lead") {
                url = "/buyer-leads";
            }
            else if (view.entity_type === "Seller Lead") {
                url = "/seller-leads";
            }
            else if (view.entity_type === "Buyer Contract") {
                url = "/buyer-contracts";
            }
            else if (view.entity_type === "Listing") {
                url = "/seller-contracts";
            }
            else if (view.entity_type === "Recruit") {
                url = "/recruits";
            }
            else if (view.entity_type === "User") {
                url = "/users";
            }
            else if (view.entity_type === "Contact") {
                url = "/contacts";
            }
            else {
                throw new Error(`Unexpected entity type ${view.entity_type}`);
            }
            navigate(`${url}?view=${view.uid}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view === null || view === void 0 ? void 0 : view.uid]);
    return React.createElement(CoreLoading, null);
};
export default ViewPage;
