import * as React from "react";
import { AutoAwesome, Delete, Mail } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
import dayjs_ from "@app/common/dayjs";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useRemoveThreadsFromBox } from "@app/orval/api/emails";
const StyledGrid = styled(Grid) `
  & .unpin-thread-button {
    display: none;
  }
  &:hover .unpin-thread-button {
    display: unset;
  }
`;
const EmailChronologyItem = ({ email, entityUid }) => {
    const unpinThreadApi = useRemoveThreadsFromBox();
    const unpinThread = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        unpinThreadApi
            .mutateAsync({
            data: {
                thread_ids: [email.thread_id],
                entity_uid: entityUid,
            },
        })
            .then(() => {
            console.log(`removed email ${email.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [email.thread_id, email.uid, entityUid, unpinThreadApi]);
    if (!unpinThreadApi.isIdle) {
        return null;
    }
    return (React.createElement(StyledGrid, { container: true, spacing: 8 },
        React.createElement(Grid, { item: true, style: { position: "relative" } },
            React.createElement(Mail, null),
            !email.is_pinned && (React.createElement(AutoAwesome, { fontSize: "small", style: { position: "absolute", color: "#999", right: "-10px", top: "45px" } }))),
        React.createElement(Grid, { item: true, style: {
                flex: 1,
                borderRadius: "0.25rem",
                border: "1px solid #ccc",
                textDecoration: "unset",
                color: "unset",
                padding: "0.5rem",
                marginLeft: "0.75rem",
                marginRight: "-0.25rem",
            }, component: RouterLink, to: `/emails/${email.uid}` },
            React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true, container: true },
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, email.subject)),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "caption", style: { color: "#ccc" } }, dayjs_(new Date(email.internal_date)).format("M/D/YYYY h:mm A")))),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                            React.createElement(Typography, { variant: "body2", dangerouslySetInnerHTML: { __html: email.snippet } })),
                        email.is_pinned && entityUid && (React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove thread") },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { size: "small", onClick: unpinThread, className: "unpin-thread-button" },
                                        React.createElement(Delete, { fontSize: "small" })))))))),
                React.createElement(Grid, { item: true, sx: { lineHeight: 0 } },
                    React.createElement(Grid, { container: true, spacing: 3, direction: "column" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Grid, { container: true, spacing: 0, direction: "column" },
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "caption", style: { color: "#333", fontWeight: "bold" } }, "Sender:")),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "caption", style: { color: "#555" } }, email.sender)))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Grid, { container: true, spacing: 0, direction: "column" },
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "caption", style: { color: "#333", fontWeight: "bold" } }, "Recipients:")),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "caption", style: { color: "#555" } }, [...email.to, ...email.cc, ...email.bcc].filter((o) => o).join(", ")))))))))));
};
export default EmailChronologyItem;
