import * as React from "react";
import { Grid2 } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
const PinnedBoxSubheader = (props) => {
    const { view } = props;
    return (React.createElement(Grid2, { container: true, wrap: "nowrap" },
        React.createElement(Grid2, { style: { flex: 1 } },
            view.entity_type.replace("Listing", "Seller Contract"),
            "s | ",
            React.createElement("b", null, view.name)),
        React.createElement(Grid2, null,
            React.createElement(RouterLink, { to: `/views/${view.uid}`, style: { textDecoration: "unset" } }, "Open"))));
};
export default PinnedBoxSubheader;
