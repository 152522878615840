import * as React from "react";
import { Clear, Send } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Link, Table, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import styled from "styled-components";
import { BackButton } from "@app/common/BackButton";
import CoreLoading from "@app/common/CoreLoading";
import Loading from "@app/common/Loading";
import useStateCallback from "@app/hooks/useStateCallback";
import { useParseMailschedule3Template } from "@app/orval/api/mail-schedule-parsing";
import { useRunMailschedule3 } from "@app/orval/api/mail-schedule-run";
import { useListSlackChannels } from "@app/orval/api/slack";
import { useGetStreakBox } from "@app/orval/api/streak-boxes";
import { useListUsers } from "@app/orval/api/users";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const EmailPreview = styled("pre") `
    all: initial;
`;
const MailScheduleTemplatePreview = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { boxKey, onClose, onCloseRedirectUrl, onTemplateProcessed, template, templateUid } = props;
    const confirm = useConfirm();
    const boxGetApi = useGetStreakBox(boxKey);
    const box = (_b = (_a = boxGetApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listSlackChannelsApi = useListSlackChannels({}, DEFAULT_CACHE_PARAMS);
    const slackChannels = (_d = (_c = listSlackChannelsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listUsersApi = useListUsers(undefined, DEFAULT_CACHE_PARAMS);
    const users = (_f = (_e = listUsersApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const parseTemplateApi = useParseMailschedule3Template();
    const [parsedTemplate, setParsedTemplate] = React.useState(null);
    const mailscheduleRunApi = useRunMailschedule3();
    const [isProcessing, setIsProcessing] = useStateCallback(false);
    React.useEffect(() => {
        parseTemplateApi
            .mutateAsync({
            data: {
                box_key: boxKey,
                template_uid: templateUid,
                template,
            },
        })
            .then((result) => {
            setParsedTemplate(result.data);
        });
    }, [boxKey, templateUid, template]);
    const processTemplate = React.useCallback(() => {
        setIsProcessing(true, () => {
            mailscheduleRunApi
                .mutateAsync({
                data: {
                    template_uid: templateUid !== null && templateUid !== void 0 ? templateUid : template === null || template === void 0 ? void 0 : template.uid,
                    box_key: boxKey,
                    ignore_criteria: true,
                    is_test: false,
                },
            })
                .then(() => {
                setIsProcessing(false);
                if (onTemplateProcessed) {
                    onTemplateProcessed();
                }
            })
                .catch((err) => {
                setIsProcessing(false);
                confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS), { description: String(err) }));
            });
        });
    }, [setIsProcessing, mailscheduleRunApi, templateUid, template === null || template === void 0 ? void 0 : template.uid, boxKey, onTemplateProcessed, confirm]);
    const loading = [
        {
            label: "Loading users...",
            queryResult: listUsersApi,
        },
        {
            label: "Loading box data...",
            queryResult: boxGetApi,
        },
        {
            label: "Parsing template...",
            // @ts-expect-error
            mutationResult: parseTemplateApi,
        },
    ];
    if (parsedTemplate === null || parsedTemplate === void 0 ? void 0 : parsedTemplate.slack_template) {
        loading.push({
            label: "Loading Slack channels...",
            queryResult: listSlackChannelsApi,
        });
    }
    const showInfiniteSpinner = !users || !parsedTemplate || !box || (parsedTemplate.slack_template && !slackChannels);
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    if (isProcessing) {
        return React.createElement(CoreLoading, null);
    }
    const assignedAgent = String((_g = box.readable_fields["Mail Schedule User"]) !== null && _g !== void 0 ? _g : (box.assignedToSharingEntries[0] ? box.assignedToSharingEntries[0].email : null));
    const dashboardUser = assignedAgent
        ? users.find((user) => user.email && user.email.toLowerCase().trim() === assignedAgent.toLowerCase().trim())
        : null;
    const keysAndValues = [];
    if (parsedTemplate.email_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.email_template.sender_type === "assigned agent"
                ? assignedAgent
                : parsedTemplate.email_template.sender,
        });
        keysAndValues.push({ key: "To", value: (_h = parsedTemplate.email_template.to) !== null && _h !== void 0 ? _h : "" });
        keysAndValues.push({ key: "Cc", value: (_j = parsedTemplate.email_template.cc) !== null && _j !== void 0 ? _j : "" });
        keysAndValues.push({ key: "Bcc", value: (_k = parsedTemplate.email_template.bcc) !== null && _k !== void 0 ? _k : "" });
        keysAndValues.push({ key: "Subject", value: parsedTemplate.email_template.subject });
        keysAndValues.push({ key: "Body", value: parsedTemplate.email_template.body });
    }
    else if (parsedTemplate.email_draft_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.email_draft_template.sender_type === "assigned agent"
                ? assignedAgent
                : parsedTemplate.email_draft_template.sender,
        });
        keysAndValues.push({ key: "To", value: (_l = parsedTemplate.email_draft_template.to) !== null && _l !== void 0 ? _l : "" });
        keysAndValues.push({ key: "Cc", value: (_m = parsedTemplate.email_draft_template.cc) !== null && _m !== void 0 ? _m : "" });
        keysAndValues.push({ key: "Bcc", value: (_o = parsedTemplate.email_draft_template.bcc) !== null && _o !== void 0 ? _o : "" });
        keysAndValues.push({ key: "Subject", value: parsedTemplate.email_draft_template.subject });
        keysAndValues.push({ key: "Body", value: parsedTemplate.email_draft_template.body });
    }
    else if (parsedTemplate.sms_template) {
        keysAndValues.push({
            key: "To",
            value: `sms${parsedTemplate.sms_template.use_agent_phone_number
                ? ((_p = dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.fields["Twilio #"]) !== null && _p !== void 0 ? _p : "")
                : parsedTemplate.sms_template.from_phone}${parsedTemplate.sms_template.to_phone}`,
        });
        keysAndValues.push({ key: "Message", value: parsedTemplate.sms_template.message });
    }
    else if (parsedTemplate.sms_draft_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.sms_draft_template.drafted_by_type === "assigned agent"
                ? assignedAgent
                : parsedTemplate.sms_draft_template.drafted_by,
        });
        keysAndValues.push({
            key: "To",
            value: `sms${parsedTemplate.sms_draft_template.use_agent_phone_number
                ? ((_q = dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.fields["Twilio #"]) !== null && _q !== void 0 ? _q : "")
                : parsedTemplate.sms_draft_template.from_phone}${parsedTemplate.sms_draft_template.to_phone}@dashagentportal.com`,
        });
        keysAndValues.push({ key: "Message", value: parsedTemplate.sms_draft_template.message });
    }
    else if (parsedTemplate.calendar_template) {
        keysAndValues.push({
            key: "Host",
            value: parsedTemplate.calendar_template.assigned_agent_is_host
                ? assignedAgent
                : parsedTemplate.calendar_template.host,
        });
        keysAndValues.push({ key: "Guests", value: parsedTemplate.calendar_template.guests });
        keysAndValues.push({ key: "Title", value: parsedTemplate.calendar_template.title });
        keysAndValues.push({ key: "Location", value: parsedTemplate.calendar_template.location });
        if (parsedTemplate.calendar_template.is_all_day_event) {
            keysAndValues.push({
                key: "Date",
                value: box.fields[parsedTemplate.calendar_template.start_date_field],
            });
        }
        keysAndValues.push({ key: "Description", value: parsedTemplate.calendar_template.description });
    }
    else if (parsedTemplate.streak_comment_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.streak_comment_template.use_agent_account
                ? assignedAgent
                : parsedTemplate.streak_comment_template.from_agent,
        });
        keysAndValues.push({ key: "Message", value: parsedTemplate.streak_comment_template.message });
    }
    else if (parsedTemplate.slack_template) {
        const channelID = parsedTemplate.slack_template.channel_id;
        const { recipients } = parsedTemplate.slack_template;
        if (channelID) {
            const slackChannelName = (_r = slackChannels === null || slackChannels === void 0 ? void 0 : slackChannels.items.find((channel) => channel["id"] === channelID)) === null || _r === void 0 ? void 0 : _r["name"];
            keysAndValues.push({ key: "Channel", value: slackChannelName !== null && slackChannelName !== void 0 ? slackChannelName : "(unknown)" });
        }
        if (recipients) {
            keysAndValues.push({ key: "Recpients", value: recipients });
        }
        keysAndValues.push({ key: "From", value: parsedTemplate.slack_template.bot_name });
        keysAndValues.push({ key: "Message", value: parsedTemplate.slack_template.message });
    }
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "h6" }, parsedTemplate.name || "Preview")),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Link, { href: `https://streak.com/a/boxes/${box.key}`, target: "_blank" },
                            React.createElement(Typography, { variant: "body1" }, box.name)))))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Table, { size: "small" },
                React.createElement(TableBody, null, keysAndValues.map((obj) => {
                    var _a;
                    return (React.createElement(TableRow, { key: obj.key },
                        React.createElement(TableCell, { style: { textAlign: "right", fontWeight: "bold" } }, obj.key),
                        React.createElement(TableCell, null, obj.key === "Body" ? (React.createElement(EmailPreview, { dangerouslySetInnerHTML: { __html: (_a = obj.value) !== null && _a !== void 0 ? _a : "" } })) : (React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: obj.value == null
                                    ? ""
                                    : obj.value.replaceAll("\n", "<br>").replaceAll("\t", "\u00A0\u00A0\u00A0\u00A0"),
                            } })))));
                })))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    (onClose || onCloseRedirectUrl) && (React.createElement(Grid, { item: true },
                        onClose && (React.createElement(Button, { variant: "text", startIcon: React.createElement(Clear, null), onClick: onClose }, "Close")),
                        onCloseRedirectUrl && (React.createElement(BackButton, { variant: "text", startIcon: React.createElement(Clear, null), url: onCloseRedirectUrl }, "Close")))),
                    (templateUid !== null && templateUid !== void 0 ? templateUid : template === null || template === void 0 ? void 0 : template.uid) && (React.createElement(Grid, { item: true },
                        React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(Send, null), onClick: processTemplate }, "Process"))))))));
};
export default MailScheduleTemplatePreview;
