/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all reports
 */
export const listGoogleDataStudioReports = (options) => {
    return axios.get(`/api/reports/googledatastudio`, options);
};
export const getListGoogleDataStudioReportsQueryKey = () => {
    return [`/api/reports/googledatastudio`];
};
export const getListGoogleDataStudioReportsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListGoogleDataStudioReportsQueryKey();
    const queryFn = ({ signal }) => listGoogleDataStudioReports(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all reports
 */
export function useListGoogleDataStudioReports(options) {
    const queryOptions = getListGoogleDataStudioReportsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a report
 */
export const createGoogleDataStudioReport = (googleDataStudioReportRequest, options) => {
    return axios.post(`/api/reports/googledatastudio`, googleDataStudioReportRequest, options);
};
export const getCreateGoogleDataStudioReportMutationOptions = (options) => {
    const mutationKey = ['createGoogleDataStudioReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createGoogleDataStudioReport(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a report
*/
export const useCreateGoogleDataStudioReport = (options) => {
    const mutationOptions = getCreateGoogleDataStudioReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a report
*/
export const getGoogleDataStudioReport = (uid, options) => {
    return axios.get(`/api/reports/googledatastudio/${uid}`, options);
};
export const getGetGoogleDataStudioReportQueryKey = (uid) => {
    return [`/api/reports/googledatastudio/${uid}`];
};
export const getGetGoogleDataStudioReportQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetGoogleDataStudioReportQueryKey(uid);
    const queryFn = ({ signal }) => getGoogleDataStudioReport(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a report
 */
export function useGetGoogleDataStudioReport(uid, options) {
    const queryOptions = getGetGoogleDataStudioReportQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a report
 */
export const updateGoogleDataStudioReport = (uid, googleDataStudioReportRequest, options) => {
    return axios.post(`/api/reports/googledatastudio/${uid}`, googleDataStudioReportRequest, options);
};
export const getUpdateGoogleDataStudioReportMutationOptions = (options) => {
    const mutationKey = ['updateGoogleDataStudioReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateGoogleDataStudioReport(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a report
*/
export const useUpdateGoogleDataStudioReport = (options) => {
    const mutationOptions = getUpdateGoogleDataStudioReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete a report
*/
export const deleteGoogleDataStudioReport = (uid, options) => {
    return axios.delete(`/api/reports/googledatastudio/${uid}`, options);
};
export const getDeleteGoogleDataStudioReportMutationOptions = (options) => {
    const mutationKey = ['deleteGoogleDataStudioReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteGoogleDataStudioReport(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a report
*/
export const useDeleteGoogleDataStudioReport = (options) => {
    const mutationOptions = getDeleteGoogleDataStudioReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Re-order reports
*/
export const reorderGoogleDataStudioReports = (googleDataStudioReportReorderRequest, options) => {
    return axios.post(`/api/reports/googledatastudio/reorder`, googleDataStudioReportReorderRequest, options);
};
export const getReorderGoogleDataStudioReportsMutationOptions = (options) => {
    const mutationKey = ['reorderGoogleDataStudioReports'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderGoogleDataStudioReports(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Re-order reports
*/
export const useReorderGoogleDataStudioReports = (options) => {
    const mutationOptions = getReorderGoogleDataStudioReportsMutationOptions(options);
    return useMutation(mutationOptions);
};
