import * as React from "react";
import { BarChart } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { formatDateMemoized_Dayjs } from "@app/util/Utils";
const QFOChronologyItem = (props) => {
    var _a, _b;
    const { qfo } = props;
    return (React.createElement(RouterLink, { to: `/qfo/submissions/${qfo.uid}`, style: { all: "unset", cursor: "pointer" } },
        React.createElement(Grid, { container: true, style: { width: "100%", border: "1px solid #aaa", borderRadius: "0.25rem", marginLeft: "0.25rem" }, spacing: 2 },
            React.createElement(Grid, { item: true, style: { width: "40px" } },
                React.createElement(BarChart, { fontSize: "large" })),
            React.createElement(Grid, { item: true, container: true, spacing: 2, style: { flex: 1 } },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, "Questions for the Offer")),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "caption" }, (_a = qfo.fields["Property Address"]) !== null && _a !== void 0 ? _a : ""))),
            React.createElement(Grid, { item: true, style: { width: "120px", textAlign: "right", paddingRight: "0.5rem" } },
                React.createElement(Typography, { variant: "caption", style: { color: "rgb(204, 204, 204)" } }, formatDateMemoized_Dayjs((_b = qfo.submitted_at) !== null && _b !== void 0 ? _b : qfo.created, "M/D/YYYY h:mm A"))))));
};
export default QFOChronologyItem;
