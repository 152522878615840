import { getFullNameMemoized } from "@app/util/Utils";
export const getStageChangeStory = (stageChange) => {
    var _a, _b, _c, _d, _e;
    const userName = stageChange.userstamp
        ? getFullNameMemoized(stageChange.userstamp.first_name, stageChange.userstamp.last_name)
        : null;
    if ((_a = stageChange.previous_value) === null || _a === void 0 ? void 0 : _a.stage) {
        return `**${userName}** changed the stage from _${stageChange.previous_value.stage.name}_ to _${(_c = (_b = stageChange.new_value) === null || _b === void 0 ? void 0 : _b.stage) === null || _c === void 0 ? void 0 : _c.name}_`;
    }
    return `**${userName}** set the stage to _${(_e = (_d = stageChange.new_value) === null || _d === void 0 ? void 0 : _d.stage) === null || _e === void 0 ? void 0 : _e.name}_`;
};
export const getNoteChangeStory = (stageChange) => {
    var _a, _b;
    const userName = stageChange.userstamp
        ? getFullNameMemoized(stageChange.userstamp.first_name, stageChange.userstamp.last_name)
        : null;
    if (stageChange.previous_value) {
        return `**${userName}** changed the notes from _${stageChange.previous_value}_ to _${(_a = stageChange.new_value) !== null && _a !== void 0 ? _a : "(blank)"}_`;
    }
    return `**${userName}** set the notes to _${(_b = stageChange.new_value) !== null && _b !== void 0 ? _b : "(blank)"}_`;
};
export const getCommentStory = (comment) => {
    if (comment.edits[0].author) {
        return `**${getFullNameMemoized(comment.edits[0].author.first_name, comment.edits[0].author.last_name)}** added a comment`;
    }
    return "Someone added a comment";
};
export const getFileStory = (file) => {
    let res = "";
    if (file.uploaded_by) {
        res += `**${getFullNameMemoized(file.uploaded_by.first_name, file.uploaded_by.last_name)}**`;
    }
    else {
        res += "Someone";
    }
    if (file.filename) {
        res += ` uploaded file _${file.filename}_`;
    }
    else {
        res += " uploaded a file";
    }
    return res;
};
