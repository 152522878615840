import React, { useContext } from "react";
import { Button, Divider, Grid2, Typography } from "@mui/material";
import CoreLink from "@app/common/CoreLink";
import { RequestDebuggingContext } from "../RequestDebuggingContext";
import Waterfall from "./Waterfall";
const Details = () => {
    const context = useContext(RequestDebuggingContext);
    if (!context.selectedRequest) {
        return null;
    }
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 2 },
        React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, direction: "row", spacing: 1, alignItems: "center" },
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "h6" },
                        "Request ID: ",
                        context.selectedRequest.request_id)),
                context.selectedRequest.user_email && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, null,
                        React.createElement(Divider, { orientation: "vertical", style: { height: "15px" } })),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h6" },
                            "User: ",
                            context.selectedRequest.user_email)))),
                context.selectedRequest.response_status && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, null,
                        React.createElement(Divider, { orientation: "vertical", style: { height: "15px" } })),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h6" },
                            "Response Status: ",
                            context.selectedRequest.response_status)))),
                React.createElement(Grid2, null,
                    React.createElement(Divider, { orientation: "vertical", style: { height: "15px" } })),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "h6" },
                        "Latency: ",
                        context.selectedRequest.latency,
                        " seconds")))),
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } },
                context.selectedRequest.method,
                " ",
                context.selectedRequest.path)),
        React.createElement(Grid2, null,
            React.createElement(Waterfall, null)),
        React.createElement(Grid2, null,
            React.createElement(Button, { component: CoreLink, variant: "text", href: `https://console.cloud.google.com/logs/query;query=SEARCH%2528"${context.selectedRequest.request_id}"%2529`, target: "_blank" }, "Open Logs"))));
};
export default Details;
