import * as React from "react";
import { Send } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import MailMergeDialog from "./MailMergeDialog";
const MailMergeIconButton = (props) => {
    const { entityType, entityUids } = props;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const onMailMergeButtonClicked = React.useCallback(() => {
        setIsDialogOpen(true);
    }, []);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Start a mail merge with selected items") },
            React.createElement("span", null,
                React.createElement(IconButton, { onClick: onMailMergeButtonClicked },
                    React.createElement(Send, null)))),
        isDialogOpen && (React.createElement(MailMergeDialog, { entityType: entityType, entityUids: Array.from(entityUids), onClose: closeDialog }))));
};
export default MailMergeIconButton;
