import * as React from "react";
import { TableFooter, TableRow, TableCell, Typography } from "@mui/material";
import numeral from "numeral";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { getAvgerageRange, getPercentClosePriceAboveRange, getPercentClosePriceBelowRange, getPSPAccuracy, } from "./utils";
const getDeltaHeaderLabel = (_) => (React.createElement("div", { style: { padding: "0.1rem" } },
    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
        "% ",
        "\u0394")));
const PSPAccuracyTable = (props) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const onRowsPerPageChange = React.useCallback((numRows) => {
        setRowsPerPage(numRows);
    }, [setRowsPerPage]);
    const { data } = props;
    const rows = Object.keys(data)
        .map((email) => (Object.assign(Object.assign({}, data[email]), { numPSPs: data[email].psps.length, email })))
        .sort((a, b) => {
        var _a, _b;
        const aAccuracy = (_a = a.accuracy) !== null && _a !== void 0 ? _a : 0;
        const bAccuracy = (_b = b.accuracy) !== null && _b !== void 0 ? _b : 0;
        // sort by accuracy first
        if (aAccuracy > bAccuracy)
            return -1;
        if (aAccuracy < bAccuracy)
            return 1;
        // total PSPs second
        if (a.numPSPs > b.numPSPs)
            return -1;
        if (a.numPSPs < b.numPSPs)
            return 1;
        return 0;
    });
    const columns = [
        {
            name: "email",
            label: "Email",
            options: {
                display: false,
                sort: false,
            },
        },
        {
            name: "psps",
            label: "PSPs",
            options: {
                display: false,
                sort: false,
            },
        },
        {
            name: "name",
            label: "Completed By",
        },
        {
            name: "accuracy",
            label: "Accuracy",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value * 100).format("%0.00") : "-"),
            },
        },
        {
            name: "accuracyDelta",
            label: "Accuracy Delta",
            options: {
                customHeadLabelRender: getDeltaHeaderLabel,
                customBodyRender: (value) => {
                    if (value == null) {
                        return "-";
                    }
                    return (React.createElement("div", { style: { display: "flex" } },
                        numeral(value * 100).format("%0.0"),
                        value < 0 ? (React.createElement("div", { style: { marginLeft: "auto", color: "red" } }, "\u21E3")) : (React.createElement("div", { style: { marginLeft: "auto", color: "green" } }, "\u21E1"))));
                },
                sort: false,
            },
        },
        {
            name: "avgRange",
            label: "AVG Range",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value).format("$0,0.00") : "-"),
            },
        },
        {
            name: "avgRangeDelta",
            label: "AVG Range Delta",
            options: {
                customHeadLabelRender: getDeltaHeaderLabel,
                customBodyRender: (value) => {
                    if (value == null) {
                        return "-";
                    }
                    return (React.createElement("div", { style: { display: "flex" } },
                        numeral(value * 100).format("%0.0"),
                        value < 0 ? (React.createElement("div", { style: { marginLeft: "auto", color: "red" } }, "\u21E3")) : (React.createElement("div", { style: { marginLeft: "auto", color: "green" } }, "\u21E1"))));
                },
                sort: false,
            },
        },
        {
            name: "numPSPs",
            label: "# of PSPs",
        },
        {
            name: "numPSPsDelta",
            label: "# of PSPs Delta",
            options: {
                customHeadLabelRender: getDeltaHeaderLabel,
                customBodyRender: (value) => {
                    if (value == null) {
                        return "-";
                    }
                    return (React.createElement("div", { style: { display: "flex" } },
                        numeral(value * 100).format("%0.0"),
                        value < 0 ? (React.createElement("div", { style: { marginLeft: "auto", color: "red" } }, "\u21E3")) : (React.createElement("div", { style: { marginLeft: "auto", color: "green" } }, "\u21E1"))));
                },
                sort: false,
            },
        },
        {
            name: "aboveRangeRate",
            label: "Coming in LOW",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value * 100).format("%0.00") : "-"),
            },
        },
        {
            name: "aboveRangeRateDelta",
            label: "Coming in LOW Delta",
            options: {
                customHeadLabelRender: getDeltaHeaderLabel,
                customBodyRender: (value) => {
                    if (value == null) {
                        return "-";
                    }
                    return (React.createElement("div", { style: { display: "flex" } },
                        numeral(value * 100).format("%0.0"),
                        value < 0 ? (React.createElement("div", { style: { marginLeft: "auto", color: "red" } }, "\u21E3")) : (React.createElement("div", { style: { marginLeft: "auto", color: "green" } }, "\u21E1"))));
                },
                sort: false,
            },
        },
        {
            name: "belowRangeRate",
            label: "Coming in High",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value * 100).format("%0.00") : "-"),
            },
        },
        {
            name: "belowRangeRateDelta",
            label: "Coming in HIGH Delta",
            options: {
                customHeadLabelRender: getDeltaHeaderLabel,
                customBodyRender: (value) => {
                    if (value == null) {
                        return "-";
                    }
                    return (React.createElement("div", { style: { display: "flex" } },
                        numeral(value * 100).format("%0.0"),
                        value < 0 ? (React.createElement("div", { style: { marginLeft: "auto", color: "red" } }, "\u21E3")) : (React.createElement("div", { style: { marginLeft: "auto", color: "green" } }, "\u21E1"))));
                },
                sort: false,
            },
        },
        {
            name: "avgPSPValueAccuracy",
            label: "Close Price / PSP Value % Difference",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value * 100).format("0,0.0%") : "-"),
            },
        },
        {
            name: "pspValueAccuracyPValue",
            label: "P Value (PSP Value Avg)",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value).format("0,0.0000") : "-"),
            },
        },
        {
            name: "avgPSPValueRangeAccuracy",
            label: "Close Price / PSP Value Range % Difference",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value * 100).format("0,0.0%") : "-"),
            },
        },
        {
            name: "pspValueRangeAccuracyPValue",
            label: "P Value (PSP Value Range)",
            options: {
                customBodyRender: (value) => (value != null ? numeral(value).format("0,0.0000") : "-"),
            },
        },
        {
            name: "grade",
            label: "Grade",
            options: {
                customBodyRender: (_, tableMeta) => {
                    const agent = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    const { letterGrade } = agent;
                    return letterGrade !== null && letterGrade !== void 0 ? letterGrade : "-";
                },
            },
        },
    ];
    const tableOptions = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        pagination: true,
        rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500],
        viewColumns: false,
        search: false,
        searchOpen: false,
        sort: true,
        onChangeRowsPerPage: onRowsPerPageChange,
        customTableBodyFooterRender: (options) => {
            const pspsColIndex = options.columns.findIndex((col) => col.name === "psps");
            // get all psps used in table data for calculating totals
            let allPSPs = [];
            options.data.forEach((item) => {
                allPSPs = [...allPSPs, ...item.data[pspsColIndex]];
            });
            return (React.createElement(TableFooter, null,
                React.createElement(TableRow, null, options.columns
                    .filter((col) => col.display === "true") // display is stored as a string for some reason
                    .map((col) => {
                    // calculate total for each column
                    let inner = "";
                    if (col.name === "name") {
                        inner = "Grand Total";
                    }
                    else if (col.name === "numPSPs") {
                        inner = String(allPSPs.length);
                    }
                    else if (col.name === "accuracy") {
                        const totalAccuracy = getPSPAccuracy(allPSPs);
                        inner = totalAccuracy != null ? numeral(totalAccuracy * 100).format("0.00%") : "-";
                    }
                    else if (col.name === "avgRange") {
                        const totalRange = getAvgerageRange(allPSPs);
                        inner = totalRange != null ? numeral(totalRange).format("$0,0.00") : "-";
                    }
                    else if (col.name === "aboveRangeRate") {
                        const aboveRate = getPercentClosePriceAboveRange(allPSPs);
                        inner = aboveRate != null ? numeral(aboveRate * 100).format("0.00%") : "-";
                    }
                    else if (col.name === "belowRangeRate") {
                        const belowRate = getPercentClosePriceBelowRange(allPSPs);
                        inner = belowRate != null ? numeral(belowRate * 100).format("0.00%") : "-";
                    }
                    return (React.createElement(TableCell, { key: `${col.name}_total_cell`, style: {
                            fontWeight: "bold",
                            color: "black",
                        } }, inner));
                }))));
        },
    };
    return (React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: tableOptions, cellPadding: 0, height: "max-content" }));
};
export default PSPAccuracyTable;
