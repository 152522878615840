import * as React from "react";
import { Search } from "@mui/icons-material";
import { Box, Divider, Grid, InputAdornment, LinearProgress, List, ListItemButton, ListItemText, TextField, } from "@mui/material";
import pickBy from "lodash/pickBy";
import { useListViewsSimple } from "@app/orval/api/views";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const SavedViewPanel = (props) => {
    var _a;
    const { entityType, onViewSelected } = props;
    const [searchStr, setSearchStr] = React.useState("");
    const [search, setSearch] = React.useState("");
    const listViewsApi = useListViewsSimple(pickBy({ entity_type: entityType, search: search.trim() || undefined }, (val) => val !== undefined), !search.trim() ? REFETCH_CACHE_PARAMS : undefined);
    const views = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listViewsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_a = listViewsApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const searchTimeout = React.useRef(null);
    const handleSearchStrChanged = React.useCallback((e) => {
        setSearchStr(e.target.value);
        if (searchTimeout.current) {
            window.clearTimeout(searchTimeout.current);
        }
        searchTimeout.current = window.setTimeout(() => {
            setSearch(e.target.value);
        }, 500);
    }, [setSearchStr]);
    const handleViewSelected = React.useCallback((uid) => () => {
        onViewSelected(uid);
    }, [onViewSelected]);
    return (React.createElement(Grid, { container: true, direction: "column", spacing: 0, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(TextField, { size: "small", variant: "outlined", slotProps: {
                        input: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement(Search, null))),
                        },
                    }, value: searchStr, onChange: handleSearchStrChanged, fullWidth: true }))),
        React.createElement(Grid, { item: true }, listViewsApi.isFetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, pt: 0 },
            React.createElement(List, { dense: true },
                React.createElement(ListItemButton, { onClick: handleViewSelected(null) },
                    React.createElement(ListItemText, { primary: "Create New View" })),
                views && views.length > 0 && React.createElement(Divider, null), views === null || views === void 0 ? void 0 :
                views.map((view) => (React.createElement(ListItemButton, { key: view.uid, onClick: handleViewSelected(view.uid) },
                    React.createElement(ListItemText, { primary: view.name }))))))));
};
export default SavedViewPanel;
