import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import QuestionsForTheSearchForm from "./QuestionsForTheSearchForm";
const QuestionsForTheSearchFormPage = () => {
    useDocumentTitle("Questions for the Search");
    const params = useParams();
    const { uid } = params;
    return React.createElement(QuestionsForTheSearchForm, { buyerLeadUid: uid });
};
export default QuestionsForTheSearchFormPage;
