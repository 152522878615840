import * as React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
const getRowClassName = (params) => {
    if (params.indexRelativeToCurrentPage % 2 === 0) {
        return "odd";
    }
    return "even";
};
const CoreDataGrid = (props) => {
    const [columnOrder, setColumnOrder] = React.useState(props.columns.map((col) => col.field));
    const [columnWidths, setColumnWidths] = React.useState({});
    const onColumnOrderChange = React.useCallback((params) => {
        setColumnOrder((prevColumnOrder) => {
            const newColumnOrder = [...prevColumnOrder];
            newColumnOrder.splice(newColumnOrder.indexOf(params.column.field), 1);
            newColumnOrder.splice(params.targetIndex - 1, 0, params.column.field);
            return newColumnOrder;
        });
    }, []);
    const onColumnWidthChange = React.useCallback((params) => {
        setColumnWidths((prevColumnWidths) => (Object.assign(Object.assign({}, prevColumnWidths), { [params.colDef.field]: params.width })));
    }, []);
    // const saveColumnArrangement = (): void => {
    //   const table = props.extendedProps?.table;
    //   if (table) {
    //     const data = {
    //       table,
    //       fields: columnOrder.map((field) => ({
    //         field,
    //         width: columnWidths[field] ?? null,
    //       })),
    //     };
    //     console.log("saving column arrangement", data);
    //     API.gridSettings.setColumns({
    //       data,
    //       onSuccess: () => {
    //         console.log("saved columns", data);
    //       },
    //       onError: (error) => {
    //         if (error.name !== "AbortError" && error.status !== 403) {
    //           console.warn(error);
    //         }
    //       },
    //     });
    //   }
    // };
    let { columns } = props;
    if (!props.disableColumnResize) {
        columns = columns.map((col) => {
            var _a;
            return (Object.assign(Object.assign({}, col), { width: (_a = columnWidths[col.field]) !== null && _a !== void 0 ? _a : col.width }));
        });
    }
    if (!props.disableColumnReorder) {
        columns = [...columns].sort((a, b) => {
            if (columnOrder.indexOf(a.field) < columnOrder.indexOf(b.field))
                return -1;
            if (columnOrder.indexOf(a.field) > columnOrder.indexOf(b.field))
                return 1;
            return 0;
        });
    }
    return (React.createElement(DataGridPremium, Object.assign({ onColumnOrderChange: onColumnOrderChange, onColumnWidthChange: onColumnWidthChange }, props, { getRowClassName: props.getRowClassName || getRowClassName, columns: columns })));
};
export default CoreDataGrid;
