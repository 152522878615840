import { useMemo } from "react";
import { searchContacts } from "@app/orval/api/contacts";
import { searchRecruits } from "@app/orval/api/recruits";
import { searchUsersBasic } from "@app/orval/api/users";
import { isValidPhoneNumber } from "@app/util/Utils";
import usePostQuery from "../../features/dashboards/agent-dashboard/usePostQuery";
const useSelectedContactForPhoneNumber = (phoneNumber) => {
    var _a, _b, _c;
    const isValidPhoneNumber_ = useMemo(() => {
        return isValidPhoneNumber(phoneNumber);
    }, [phoneNumber]);
    const listContactsApi = usePostQuery(searchContacts, { phone_numbers: [phoneNumber] }, {
        enabled: isValidPhoneNumber_,
    });
    const contacts = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listContactsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_a = listContactsApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const contact = useMemo(() => {
        return contacts && contacts.length > 0 ? contacts[0] : null;
    }, [contacts]);
    const listUsersApi = usePostQuery(searchUsersBasic, { phone_numbers: [phoneNumber] }, {
        enabled: isValidPhoneNumber_,
    });
    const users = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_b = listUsersApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const user = useMemo(() => {
        return users && users.length > 0 ? users[0] : null;
    }, [users]);
    const listRecruitsApi = usePostQuery(searchRecruits, { phone_numbers: [phoneNumber] }, {
        enabled: isValidPhoneNumber_,
    });
    const recruits = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listRecruitsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_c = listRecruitsApi.data) === null || _c === void 0 ? void 0 : _c.data]);
    const recruit = useMemo(() => {
        return recruits && recruits.length > 0 ? recruits[0] : null;
    }, [recruits]);
    return {
        contact: isValidPhoneNumber_ && !listRecruitsApi.isFetching ? contact : null,
        user: isValidPhoneNumber_ && !listUsersApi.isFetching ? user : null,
        recruit: isValidPhoneNumber_ && !listRecruitsApi.isFetching ? recruit : null,
    };
};
export default useSelectedContactForPhoneNumber;
