import * as React from "react";
import { Typography, List, ListItem, ListItemButton, ListItemText, Grid2, Divider, Box, TextField, } from "@mui/material";
const isHeader = (element) => {
    return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(element.tagName.toLowerCase().trim());
};
/**
 * reads the DOM to get the items for a Table of Contents.
 * searches through the children of any `.markdown-body` elements
 * and uses the <h> tags to create the Table of Contents
 */
const getTOCItems = () => {
    const markdown = document.getElementsByClassName("markdown-body");
    const items = [];
    if (markdown.length === 0) {
        return items;
    }
    let counter = 0;
    Array.from(markdown[0].children).forEach((el, index) => {
        var _a, _b;
        // if child is header, take text content
        // recursive: check children of nearest ul or ol for h tags, take text content (indented)
        if (isHeader(el)) {
            el.setAttribute("id", `header_${counter}`);
            const { tagName } = el;
            const hNumber = parseFloat((_a = tagName.split("")[1]) !== null && _a !== void 0 ? _a : 1);
            console.log(tagName, hNumber);
            const indent = index === 0 ? 0 : hNumber - 1;
            items.push({
                id: `header_${counter}`,
                indent,
                content: (_b = el.textContent) !== null && _b !== void 0 ? _b : "",
                style: { fontWeight: "bold" },
            });
            counter += 1;
        }
    });
    return items;
};
const SOPTableOfContents = (props) => {
    const [headingsFilter, setHeadingsFilter] = React.useState(null);
    const { onClose } = props;
    const onHeadersFilterChange = React.useCallback((e) => {
        const text = e.target.value;
        setHeadingsFilter(text.trim() === "" ? null : text);
    }, []);
    const tableItems = getTOCItems();
    const tableItemsFiltered = tableItems.filter((item) => {
        return headingsFilter == null || item.content.toLowerCase().includes(headingsFilter.toLowerCase());
    });
    return (React.createElement(Grid2, { container: true, spacing: 1 },
        React.createElement(Grid2, { size: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(TextField, { size: "small", variant: "outlined", placeholder: "Filter headings", fullWidth: true, value: headingsFilter !== null && headingsFilter !== void 0 ? headingsFilter : "", onChange: onHeadersFilterChange }))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(List, { dense: true }, tableItemsFiltered.map((item) => {
                    var _a;
                    return (React.createElement(ListItem, { key: `toc_item_${item.id}`, style: { paddingTop: "0px", paddingBottom: "0px", paddingLeft: 0 } },
                        React.createElement(ListItemButton, { href: `#${item.id}`, onClick: onClose, style: Object.assign(Object.assign({ paddingLeft: `${8 * item.indent}px` }, item.style), { paddingTop: 0, paddingBottom: 0 }) },
                            React.createElement(ListItemText, { primary: React.createElement(Typography, { style: (_a = item.style) !== null && _a !== void 0 ? _a : {} }, item.content) }))));
                }))))));
};
export default SOPTableOfContents;
