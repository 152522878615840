import * as React from "react";
import { LabelImportantOutlined } from "@mui/icons-material";
import { Box, Checkbox, Divider, FormControlLabel, Grid, Typography, Button } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import ResponsiveFilterChip from "./ResponsiveFilterChip";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const relevantStageTypes = ["Buyer Lead", "Seller Lead", "Buyer Contract", "Listing"];
const StageFilter = () => {
    var _a;
    const { selectedStageUids, setSelectedStageUids, stages } = useAgentDashboardFilters();
    React.useEffect(() => {
        var _a;
        if (selectedStageUids.size === 0) {
            setSelectedStageUids(new Set((_a = stages === null || stages === void 0 ? void 0 : stages.filter((stage) => !["Rejected", "Nurture", "Terminated"].includes(stage.name)).map((stage) => stage.uid)) !== null && _a !== void 0 ? _a : []));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stages]);
    const [panelAnchorEl, setPanelAnchorEl] = React.useState(null);
    const handleFilterClicked = React.useCallback((e) => {
        setPanelAnchorEl(e.currentTarget);
    }, [setPanelAnchorEl]);
    const closePanel = React.useCallback(() => {
        setPanelAnchorEl(null);
    }, [setPanelAnchorEl]);
    const selectAll = React.useCallback(() => {
        var _a;
        setSelectedStageUids(new Set((_a = stages === null || stages === void 0 ? void 0 : stages.map((stage) => stage.uid)) !== null && _a !== void 0 ? _a : []));
    }, [setSelectedStageUids, stages]);
    const togglePipeline = React.useCallback((pipeline) => (_, checked) => {
        setSelectedStageUids((prev) => {
            const next = new Set(prev);
            stages === null || stages === void 0 ? void 0 : stages.filter((x) => x.type === pipeline).forEach((stage) => {
                if (checked) {
                    next.add(stage.uid);
                }
                else {
                    next.delete(stage.uid);
                }
            });
            return next;
        });
    }, [stages, setSelectedStageUids]);
    const toggleStage = React.useCallback((stageUid) => (_, checked) => {
        setSelectedStageUids((prev) => {
            const next = new Set(prev);
            if (checked) {
                next.add(stageUid);
            }
            else {
                next.delete(stageUid);
            }
            return next;
        });
    }, [setSelectedStageUids]);
    const selectStage = React.useCallback((stageUid) => () => {
        setSelectedStageUids(new Set([stageUid]));
    }, [setSelectedStageUids]);
    const selectPipeline = React.useCallback((pipeline) => () => {
        var _a;
        setSelectedStageUids(new Set((_a = stages === null || stages === void 0 ? void 0 : stages.filter((x) => x.type === pipeline).map((x) => x.uid)) !== null && _a !== void 0 ? _a : []));
    }, [setSelectedStageUids, stages]);
    if (!stages || stages.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveFilterChip, { size: "small", label: `${selectedStageUids.size} of ${(_a = stages.length) !== null && _a !== void 0 ? _a : 0} stages`, icon: React.createElement(LabelImportantOutlined, { fontSize: "small" }), onClick: handleFilterClicked, isActive: selectedStageUids.size < stages.length }),
        panelAnchorEl && (React.createElement(CorePopover, { anchorEl: panelAnchorEl, onClose: closePanel, open: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", spacing: 1, style: { overflow: "hidden" } },
                    React.createElement(Grid, { item: true, style: { height: "32px" } },
                        React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center", style: { width: "100%" } },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, { style: { fontWeight: "bold" } }, "Stages")),
                            React.createElement(Grid, { item: true },
                                React.createElement(Button, { onClick: selectAll, size: "small" }, "Select All")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 0, style: { maxHeight: "300px", overflow: "scroll", flex: 1 } }, relevantStageTypes.map((type) => {
                        const stagesOfType = stages.filter((stage) => stage.type === type);
                        return (React.createElement(Grid, { key: type, item: true },
                            React.createElement(Grid, { container: true, direction: "column", spacing: 0, wrap: "nowrap" },
                                React.createElement(Grid, { item: true },
                                    React.createElement(Grid, { container: true, spacing: 1 },
                                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                                            React.createElement(FormControlLabel, { label: `${type.replace("Listing", "Seller Contract")}s`, control: React.createElement(Checkbox, { size: "small", color: "primary", style: { paddingTop: 0, paddingBottom: 0 }, checked: !stagesOfType.find((x) => !selectedStageUids.has(x.uid)), indeterminate: !!(stagesOfType.find((x) => selectedStageUids.has(x.uid)) &&
                                                        stagesOfType.find((x) => !selectedStageUids.has(x.uid))), onChange: togglePipeline(type) }) })),
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Button, { style: { padding: 0 }, onClick: selectPipeline(type), size: "small", variant: "text" }, "only")))),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Grid, { container: true, direction: "column", spacing: 0, wrap: "nowrap", pl: 4 }, stagesOfType.map((stage) => (React.createElement(Grid, { key: stage.uid, item: true },
                                        React.createElement(Grid, { container: true, spacing: 1 },
                                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                                React.createElement(FormControlLabel, { key: stage.uid, label: stage.name, control: React.createElement(Checkbox, { size: "small", color: "primary", style: { paddingTop: 0, paddingBottom: 0 }, checked: selectedStageUids.has(stage.uid), onChange: toggleStage(stage.uid) }) })),
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Button, { style: { padding: 0 }, onClick: selectStage(stage.uid), size: "small", variant: "text" }, "only")))))))))));
                    }))))))));
};
export default StageFilter;
