import * as React from "react";
import { Savings } from "@mui/icons-material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import { getAgentNet } from "@app/orval/api/agent-dashboard";
import BigMetricBox from "./widgets/BigMetricBox";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const AgentNet = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { fromDate, selectedUserUids, toDate } = agentDashboardFilters;
    const queryParams = pickBy({ from_date: fromDate, to_date: toDate, agent_uids: [...selectedUserUids] }, (val) => val != null);
    const getAgentNetApi = usePostQuery(getAgentNet, queryParams);
    const agentNet = (_a = getAgentNetApi.data) === null || _a === void 0 ? void 0 : _a.data.value;
    const value = agentNet != null ? numeral(agentNet).format("$0,0") : undefined;
    return (React.createElement(BigMetricBox, { title: "Agent Net", value: value, icon: React.createElement(Savings, { fontSize: "large" }), isLoading: getAgentNetApi.isFetching, error: getAgentNetApi.error, description: `
        The sum of Agent Net and Co-Agent Referral $ in Buyer and Seller Contracts where the
        Closing Date | COE falls within the specified date range.
        Does not include Terminated transactions.` }));
};
export default AgentNet;
