import * as React from "react";
import { MoreVert } from "@mui/icons-material";
import { Grid2, IconButton, Typography, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import CoreDateField from "@app/common/CoreDateField";
import CoreMenu from "@app/common/CoreMenu";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const YYYYMMDD = "YYYY-MM-DD";
const PeriodFilter = () => {
    const { fromDate, setFromDate, setToDate, toDate } = useAgentDashboardFilters();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleFromDateChanged = React.useCallback((date) => {
        setFromDate(date || null);
    }, [setFromDate]);
    const handleToDateChanged = React.useCallback((date) => {
        setToDate(date || null);
    }, [setToDate]);
    const openMenu = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [setAnchorEl]);
    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const setYTD = React.useCallback(() => {
        setFromDate(`${dayjs().year()}-01-01`);
        setToDate(dayjs().format(YYYYMMDD));
    }, [setFromDate, setToDate]);
    const set30days = React.useCallback(() => {
        setFromDate(dayjs().subtract(30, "days").format(YYYYMMDD));
        setToDate(dayjs().format(YYYYMMDD));
    }, [setFromDate, setToDate]);
    const set60days = React.useCallback(() => {
        setFromDate(dayjs().subtract(60, "days").format(YYYYMMDD));
        setToDate(dayjs().format(YYYYMMDD));
    }, [setFromDate, setToDate]);
    const set90days = React.useCallback(() => {
        setFromDate(dayjs().subtract(90, "days").format(YYYYMMDD));
        setToDate(dayjs().format(YYYYMMDD));
    }, [setFromDate, setToDate]);
    const set180days = React.useCallback(() => {
        setFromDate(dayjs().subtract(180, "days").format(YYYYMMDD));
        setToDate(dayjs().format(YYYYMMDD));
    }, [setFromDate, setToDate]);
    const set12months = React.useCallback(() => {
        setFromDate(dayjs().subtract(12, "months").format(YYYYMMDD));
        setToDate(dayjs().format(YYYYMMDD));
    }, [setFromDate, setToDate]);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Select period:")),
        React.createElement(Grid2, null,
            React.createElement(CoreDateField, { size: "small", 
                /** @ts-expect-error: standard */
                variant: "outlined", format: "MMM D, YYYY", formatType: "dayjs", inputProps: { style: { height: "10px" } }, date: fromDate, onDateChange: handleFromDateChanged, hasPicker: true, style: { width: "130px" } })),
        React.createElement(Grid2, null,
            React.createElement(CoreDateField, { size: "small", 
                /** @ts-expect-error: standard */
                variant: "outlined", format: "MMM D, YYYY", formatType: "dayjs", inputProps: { style: { height: "10px" } }, date: toDate, onDateChange: handleToDateChanged, hasPicker: true, style: { width: "130px" } })),
        React.createElement(Grid2, null,
            React.createElement(IconButton, { size: "small", onClick: openMenu },
                React.createElement(MoreVert, { fontSize: "small" })),
            React.createElement(CoreMenu, { anchorEl: anchorEl, onClose: closeMenu, open: Boolean(anchorEl), onClick: closeMenu },
                React.createElement(MenuItem, { onClick: setYTD }, "YTD"),
                React.createElement(MenuItem, { onClick: set30days }, "30 days"),
                React.createElement(MenuItem, { onClick: set60days }, "60 days"),
                React.createElement(MenuItem, { onClick: set90days }, "90 days"),
                React.createElement(MenuItem, { onClick: set180days }, "180 days"),
                React.createElement(MenuItem, { onClick: set12months }, "12 months")))));
};
export default PeriodFilter;
