import * as React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { NOT_SPECIFIED } from "./utils";
const TemplateFilter = (props) => {
    var _a, _b, _c, _d;
    const { TextFieldProps, includeNotSpecifiedOption = true, isNull, onChange, pipeline, value } = props;
    const listTemplatesApi = useListMailschedule4Templates({}, REFETCH_CACHE_PARAMS);
    const templates = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const handleChange = React.useCallback((event) => {
        onChange(event.target.value || null);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true }, TextFieldProps, { select: true, value: isNull ? NOT_SPECIFIED : (value !== null && value !== void 0 ? value : ""), onChange: handleChange, disabled: !templates, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Template") }, (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.input), inputLabel: Object.assign({ shrink: true }, (_c = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.inputLabel), select: Object.assign({ displayEmpty: true }, (_d = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _d === void 0 ? void 0 : _d.select) }) }),
        React.createElement(MenuItem, { value: "" }, "Any"),
        includeNotSpecifiedOption && React.createElement(MenuItem, { value: NOT_SPECIFIED }, "Not Specified"),
        (templates !== null && templates !== void 0 ? templates : [])
            .filter((t) => !pipeline || t.entity_type === pipeline)
            .map((template) => (React.createElement(MenuItem, { key: template.uid, value: template.uid }, template.name)))));
};
export default TemplateFilter;
