import * as React from "react";
import { Add, Close, DragIndicator, Save } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Typography } from "@mui/material";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";
import CoreDialog from "@app/common/CoreDialog";
import { withDialog } from "@app/hoc/withDialog";
import useStateCallback from "@app/hooks/useStateCallback";
import { useListStages, useSetAllStages } from "@app/orval/api/stages";
import SaveChangesDialog from "./SaveChangesDialog";
import Stage from "./Stage";
import StagesSkeleton from "./StagesSkeleton";
const StagesDialog = (props) => {
    const { DialogProps, onClose, onSaveComplete, showErrorDialog, type } = props;
    const [initialStages, setInitialStages] = React.useState(null);
    const [stages, setStages] = React.useState(null);
    const [isSaveChangesDialogOpen, setIsSaveChangesDialogOpen] = React.useState(false);
    const [isSaving, setIsSaving] = useStateCallback(false);
    const listStagesApi = useListStages(
    // @ts-expect-error
    { type: type.replace("Seller Contract", "Listing") }, { query: { enabled: false } });
    const setStagesApi = useSetAllStages();
    const hasChanges = JSON.stringify(initialStages !== null && initialStages !== void 0 ? initialStages : {}) !== JSON.stringify(stages !== null && stages !== void 0 ? stages : {});
    const canSave = hasChanges && stages != null;
    React.useEffect(() => {
        setStages(null);
        listStagesApi
            .refetch()
            .then((result) => {
            const params = result.data.data.map((item) => (Object.assign(Object.assign({}, omit(item, "type", "created_at", "last_updated_at", "archived_at")), { randomId: uuidv4() })));
            setStages(params);
            setInitialStages(params);
        })
            .catch((err) => {
            showErrorDialog(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    const save = React.useCallback(() => {
        setIsSaving(true, () => {
            setStagesApi
                .mutateAsync({
                data: {
                    items: stages.map((x) => {
                        var _a, _b;
                        return (Object.assign(Object.assign({}, x), { followupboss_stage_name: (_b = (_a = x.followupboss_stage_name) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : null }));
                    }),
                },
            })
                .then((result) => {
                setIsSaving(false);
                onSaveComplete(result.data);
            })
                .catch((err) => {
                showErrorDialog(err);
                setIsSaving(false);
            });
        });
    }, [setIsSaving, setStagesApi, stages, onSaveComplete, showErrorDialog]);
    const close = React.useCallback(() => {
        onClose();
    }, [onClose]);
    const askToClose = React.useCallback((_, reason) => {
        if ((reason === "backdropClick" || reason === "escapeKeyDown") && isSaving) {
            return;
        }
        if (!hasChanges) {
            onClose();
        }
        else {
            setIsSaveChangesDialogOpen(true);
        }
    }, [hasChanges, isSaving, onClose]);
    const closeSaveChangesDialog = React.useCallback(() => {
        setIsSaveChangesDialogOpen(false);
    }, []);
    const updateStage = (randomId) => (params) => {
        const { color, followupboss_stage_name, name } = params;
        setStages((prev) => {
            const prevIndx = prev.findIndex((stage) => stage.randomId === randomId);
            return [
                ...prev.slice(0, prevIndx),
                Object.assign(Object.assign({}, prev[prevIndx]), { name,
                    color,
                    followupboss_stage_name }),
                ...prev.slice(prevIndx + 1, prev.length),
            ];
        });
    };
    const deleteStage = (randomId) => () => {
        setStages((prev) => {
            return prev.filter((stage) => stage.randomId !== randomId);
        });
    };
    const addStage = React.useCallback(() => {
        setStages((prev) => [
            ...prev,
            {
                randomId: uuidv4(),
                name: `New Stage ${prev.length + 1}`,
                color: "#999999",
                followupboss_stage_name: null,
                type,
            },
        ]);
    }, [type]);
    const reorderStages = React.useCallback((result) => {
        if (result.destination && result.source.index !== result.destination.index) {
            setStages((prev) => {
                const newItems = [...prev];
                const item = newItems[result.source.index];
                newItems.splice(result.source.index, 1);
                newItems.splice(result.destination.index, 0, item);
                return newItems;
            });
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(CoreDialog, Object.assign({ slotProps: {
                paper: {
                    style: {
                        position: "fixed",
                        top: 0,
                        right: 0,
                        margin: 0,
                        height: "100%",
                        maxHeight: "unset",
                        width: "420px",
                    },
                },
            } }, DialogProps, { onClose: askToClose, disableEscapeKeyDown: isSaving }),
            React.createElement(DialogTitle, { style: { background: "#eee" } },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { onClick: askToClose, disabled: isSaving, tabIndex: -1 },
                            React.createElement(Close, null))),
                    React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, { variant: "h5" },
                            type,
                            " Stages")))),
            React.createElement(DialogContent, null,
                React.createElement(Box, { p: 2 },
                    stages == null && React.createElement(StagesSkeleton, null),
                    stages != null && (React.createElement(DragDropContext, { onDragEnd: reorderStages },
                        React.createElement(Droppable, { droppableId: "reorder-list" }, (droppableProvided) => (React.createElement(Grid2, Object.assign({}, droppableProvided.droppableProps, { ref: droppableProvided.innerRef, container: true, spacing: 4, direction: "column", style: stages.length > 0 ? { paddingTop: "1rem" } : undefined }),
                            stages.map((stage, indx) => (React.createElement(Draggable, { key: stage.randomId, draggableId: String(stage.randomId), index: indx }, (draggableProvided) => (React.createElement(Grid2, Object.assign({ key: stage.randomId, ref: draggableProvided.innerRef }, draggableProvided.draggableProps),
                                React.createElement(Stage, { value: stage, onUpdate: updateStage(stage.randomId), onDelete: deleteStage(stage.randomId), dragHandle: stages.length > 1 && (React.createElement("div", Object.assign({}, draggableProvided.dragHandleProps, { tabIndex: -1 }),
                                        React.createElement(DragIndicator, { style: {
                                                cursor: "grab",
                                            } }))), hasDetails: type === "Buyer Lead" || type === "Seller Lead" })))))),
                            droppableProvided.placeholder,
                            React.createElement(Grid2, null,
                                React.createElement(Button, { size: "large", style: { width: "100%" }, variant: "outlined", startIcon: React.createElement(Add, null), onClick: addStage, tabIndex: -1 }, "Add Stage"))))))))),
            React.createElement(DialogActions, { style: { background: "#eee" } },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, null,
                        React.createElement(Button, { color: "primary", variant: "contained", startIcon: React.createElement(Save, null), onClick: save, disabled: !canSave || isSaving, tabIndex: -1 }, "Save")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { color: "primary", variant: "text", onClick: askToClose, disabled: isSaving, tabIndex: -1 }, "Cancel"))))),
        React.createElement(SaveChangesDialog, { DialogProps: { open: isSaveChangesDialogOpen }, verbiage: `You have unsaved changes to the stage settings for ${type}`, onBackToEditing: closeSaveChangesDialog, onDiscardChanges: close, onSave: save })));
};
export default withDialog(StagesDialog);
