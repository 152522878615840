import * as React from "react";
import { TextField, MenuItem, Grid, Typography, Avatar } from "@mui/material";
const AgentSelect = (props) => {
    const { agents, onChange, value } = props;
    const handleChange = React.useCallback((e) => {
        onChange(e.target.value || null);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ fullWidth: true, size: "small", variant: "standard", select: true }, props.TextFieldProps, { value: value !== null && value !== void 0 ? value : "", onChange: handleChange }),
        React.createElement(MenuItem, { value: "" }),
        agents.map((agent) => {
            var _a;
            const initials = [agent.first_name, agent.last_name]
                .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
                .map((o) => o.substring(0, 1))
                .join("");
            const name = [agent.first_name, agent.last_name].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" ");
            return (React.createElement(MenuItem, { key: agent.uid, value: agent.uid },
                React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Avatar, { src: (_a = agent.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "22px", width: "22px", fontSize: "0.7rem" } }, initials)),
                    React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, { variant: "body1" }, name)))));
        })));
};
export default AgentSelect;
