import { enqueueSnackbar } from "notistack";
import { copyToClipboard } from "@app/util/Utils";
export const copyQFLLink = (sellerLead) => {
    const qflLink = `${window.location.protocol}//${window.location.host}/forms/questions-for-the-listing/${sellerLead.uid}`;
    copyToClipboard(qflLink);
    enqueueSnackbar("QFL link copied to clipboard", { variant: "success" });
};
export const copyQFBLink = (sellerLead) => {
    const qflLink = `${window.location.protocol}//${window.location.host}/forms/questions-for-the-brage/${sellerLead.uid}`;
    copyToClipboard(qflLink);
    enqueueSnackbar("QFB link copied to clipboard", { variant: "success" });
};
export const canEditSellerLead = (params) => {
    var _a, _b;
    const { allUsers, sellerLead, sessionUser } = params;
    if (!sessionUser) {
        return false;
    }
    if (sessionUser.hasRole(["Superadmin", "Admin"])) {
        return true;
    }
    // producers can edit their own boxes, as well as listing coordinators
    if (sellerLead.agents.some((x) => {
        return (x.type === "Producer" || x.type === "Listing Coordinator") && x.agent.uid === sessionUser.uid;
    })) {
        return true;
    }
    // collaborators can edit boxes if they've been given edit access
    if (sellerLead.collaborators.some((x) => {
        return x.user.uid === sessionUser.uid && x.can_edit;
    })) {
        return true;
    }
    // Managing Partners can edit boxes if they are the managing partner
    // of the producer'soffice
    const user = allUsers === null || allUsers === void 0 ? void 0 : allUsers[sessionUser.uid];
    if (!user) {
        return false;
    }
    if (sellerLead.agents.some((x) => { var _a, _b; return x.type === "Producer" && allUsers[x.agent.uid] && ((_a = allUsers[x.agent.uid].office) === null || _a === void 0 ? void 0 : _a.name) === ((_b = user.office) === null || _b === void 0 ? void 0 : _b.name); })) {
        return true;
    }
    // Operating Partners and Success Partners can edit boxes if they are
    // on the producer's team
    if ((((_a = user.team) === null || _a === void 0 ? void 0 : _a.role) === "Operating Partner" || ((_b = user.team) === null || _b === void 0 ? void 0 : _b.role) === "Success Partner") &&
        sellerLead.agents.some((x) => { var _a, _b; return x.type === "Producer" && allUsers[x.agent.uid] && ((_a = allUsers[x.agent.uid].team) === null || _a === void 0 ? void 0 : _a.uid) === ((_b = user.team) === null || _b === void 0 ? void 0 : _b.uid); })) {
        return true;
    }
    return false;
};
