import * as React from "react";
import { Download } from "@mui/icons-material";
import { Box, IconButton, Grid2, Link, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
const StreakFileItem = (props) => {
    const { file } = props;
    const linkClassName = React.useRef(uuidv4().replace(/-/g, ""));
    const selector = `&:not(:hover) .${linkClassName.current}`;
    return (React.createElement(Box, { p: 1, style: { border: "1px solid #888", borderRadius: "4px" }, sx: {
            [selector]: {
                display: "none",
            },
        } },
        React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, file.drive_icon_url && React.createElement("img", { src: file.drive_icon_url, alt: "logo", width: "24px", height: "24px" })),
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Box, { p: 1 },
                    React.createElement(Grid2, { container: true, wrap: "nowrap", direction: "column" },
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(Typography, { variant: "body2" }, file.file_name)),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "caption" },
                                "Uploaded to Streak ",
                                dayjs(file.created).format("M/D/YYYY h:mm A")))))),
            file.drive_url && (React.createElement(Grid2, { className: linkClassName.current },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Download"), disableInteractive: true },
                    React.createElement(IconButton, { component: Link, href: file.drive_url, target: "_blank" },
                        React.createElement(Download, null))))))));
};
export default StreakFileItem;
