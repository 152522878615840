import * as React from "react";
import { Business } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import useSession from "@app/hooks/useSession";
import ResponsiveFilterChip from "./ResponsiveFilterChip";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const OfficeFilter = () => {
    var _a;
    const { offices, selectedOfficeUids, setSelectedOfficeUids } = useAgentDashboardFilters();
    const session = useSession();
    const [panelAnchorEl, setPanelAnchorEl] = React.useState(null);
    const handleOfficesFilterClicked = React.useCallback((e) => {
        setPanelAnchorEl(e.currentTarget);
    }, [setPanelAnchorEl]);
    const closePanel = React.useCallback(() => {
        setPanelAnchorEl(null);
    }, [setPanelAnchorEl]);
    const selectAll = React.useCallback(() => {
        var _a;
        setSelectedOfficeUids(new Set((_a = offices === null || offices === void 0 ? void 0 : offices.map((office) => office.uid)) !== null && _a !== void 0 ? _a : []));
    }, [setSelectedOfficeUids, offices]);
    const toggleSelectedOffice = React.useCallback((officeUid) => () => {
        setSelectedOfficeUids((prev) => {
            const next = new Set(prev);
            if (next.has(officeUid)) {
                next.delete(officeUid);
            }
            else {
                next.add(officeUid);
            }
            return next;
        });
    }, [setSelectedOfficeUids]);
    const selectOffice = React.useCallback((officeUid) => () => {
        setSelectedOfficeUids(new Set([officeUid]));
    }, [setSelectedOfficeUids]);
    if (!offices || offices.length <= 1) {
        return null;
    }
    const numSelected = selectedOfficeUids.size > 0 ? selectedOfficeUids.size : offices.length;
    const label = `${numSelected} of ${offices.length} offices`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveFilterChip, { size: "small", label: label, icon: React.createElement(Business, { fontSize: "small" }), onClick: handleOfficesFilterClicked, isActive: numSelected !== offices.length }),
        panelAnchorEl && (React.createElement(CorePopover, { anchorEl: panelAnchorEl, onClose: closePanel, open: true, sx: { "& .MuiPaper-root ": { minWidth: "300px" } } },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", spacing: 1, style: { overflow: "hidden" } },
                    React.createElement(Grid, { item: true, style: { height: "32px" } },
                        React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center", style: { width: "100%" } },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, { style: { fontWeight: "bold" } },
                                    "Offices (",
                                    numSelected,
                                    " selected)")),
                            React.createElement(Grid, { item: true },
                                React.createElement(Button, { size: "small", onClick: selectAll }, "Select All")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 0, style: { maxHeight: "300px", overflow: "scroll", flex: 1 } },
                        ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.office) && (React.createElement(Grid, { item: true },
                            React.createElement(Box, { pb: 3 },
                                React.createElement(Grid, { container: true, spacing: 1 },
                                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                                        React.createElement(FormControlLabel, { label: "My Office", control: React.createElement(Checkbox, { size: "small", checked: selectedOfficeUids.has(session.viewingAsUser.office.uid) ||
                                                    selectedOfficeUids.size === 0, color: "primary", onChange: toggleSelectedOffice(session.viewingAsUser.office.uid), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Button, { style: { padding: 0 }, onClick: selectOffice(session.viewingAsUser.office.uid) }, "only")))))),
                        offices
                            .sort((a, b) => {
                            const aName = a.name.toLowerCase().trim();
                            const bName = b.name.toLowerCase().trim();
                            if (aName < bName) {
                                return -1;
                            }
                            if (aName > bName) {
                                return 1;
                            }
                            return 0;
                        })
                            .map((office) => (React.createElement(Grid, { key: office.uid, item: true },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, style: { flex: 1 } },
                                    React.createElement(FormControlLabel, { label: office.name, control: React.createElement(Checkbox, { size: "small", checked: selectedOfficeUids.has(office.uid) || selectedOfficeUids.size === 0, color: "primary", onChange: toggleSelectedOffice(office.uid), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Button, { style: { padding: 0 }, onClick: selectOffice(office.uid) }, "only")))))))))))));
};
export default OfficeFilter;
