import * as React from "react";
import { Grid2, MenuItem, TextField } from "@mui/material";
import CoreNumberField from "@app/common/CoreNumberField";
import { FilterContext } from "../FilterContext";
const SavedViewNumberFilter = (props) => {
    var _a, _b, _c, _d;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const numberFields = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = context.fields) === null || _a === void 0 ? void 0 : _a.filter((f) => f.field_type === "number")) !== null && _b !== void 0 ? _b : [];
    }, [context.fields]);
    const filter = context.getFilter(uid);
    const selectedField = React.useMemo(() => {
        var _a;
        return (_a = context.fields) === null || _a === void 0 ? void 0 : _a.find((x) => {
            var _a;
            return x.uid ===
                (typeof filter.number_filter.lhs_field === "string"
                    ? filter.number_filter.lhs_field
                    : (_a = filter.number_filter.lhs_field) === null || _a === void 0 ? void 0 : _a.uid);
        });
    }, [context.fields, filter.number_filter]);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.number_filter.comparator = comparator;
        if (comparator === "is not set" || comparator === "is set") {
            newFilter.number_filter.rhs_field = undefined;
            newFilter.number_filter.rhs_value = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSField = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.number_filter.rhs_field = val;
        newFilter.number_filter.rhs_value = val ? undefined : null;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValue = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.number_filter.rhs_value = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.numberFieldComparators;
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.number_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        filter.number_filter.comparator !== "is set" && filter.number_filter.comparator !== "is not set" && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, { style: { width: "200px" } },
                React.createElement(TextField, { size: "small", variant: "standard", select: true, fullWidth: true, value: (_b = filter.number_filter.rhs_field) !== null && _b !== void 0 ? _b : "", onChange: setRHSField, SelectProps: {
                        displayEmpty: true,
                    } },
                    React.createElement(MenuItem, { value: "" }, "a constant value"),
                    numberFields
                        .filter((x) => x.uid !== filter.number_filter.lhs_field)
                        .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))),
            !filter.number_filter.rhs_field && (React.createElement(Grid2, { style: { width: "100px" } },
                React.createElement(CoreNumberField, { size: "small", variant: "standard", fullWidth: true, number: (_c = filter.number_filter.rhs_value) !== null && _c !== void 0 ? _c : null, format: (_d = selectedField === null || selectedField === void 0 ? void 0 : selectedField.format) !== null && _d !== void 0 ? _d : "0", min: selectedField === null || selectedField === void 0 ? void 0 : selectedField.min, max: selectedField === null || selectedField === void 0 ? void 0 : selectedField.max, onNumberChange: setRHSValue })))))));
};
export default SavedViewNumberFilter;
