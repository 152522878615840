import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Close, Reorder, Save } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateCallback from "@app/hooks/useStateCallback";
import { reorderCompsfinderRulesets, useListCompsfinderRulesets } from "@app/orval/api/comps-finder-rule-sets";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
const CompsPullerRuleSetsReorder = () => {
    var _a, _b;
    const [isSaving, setIsSaving] = useStateCallback(false);
    const queryClient = useQueryClient();
    const compsFinderRuleSetsListApi = useListCompsfinderRulesets(DEFAULT_CACHE_PARAMS);
    const ruleSets = (_b = (_a = compsFinderRuleSetsListApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const showErrorDialog = useErrorDialog();
    // private readonly abortController: AbortController | null = null;
    const save = React.useCallback(() => {
        setIsSaving(true, () => {
            const ordering = ruleSets.map((obj) => obj.uid);
            console.log({ ordering });
            reorderCompsfinderRulesets({ rule_sets: ordering })
                .then((_) => {
                enqueueSnackbar("Rule Sets have been reordered", { variant: "success" });
                setIsSaving(false);
            })
                .catch((error) => {
                showErrorDialog(error);
                setIsSaving(false);
            });
        });
    }, [setIsSaving, showErrorDialog, ruleSets]);
    const onDrop = React.useCallback((result) => {
        if (result.destination && result.source.index !== result.destination.index) {
            queryClient.setQueryData(compsFinderRuleSetsListApi.queryKey, (old) => {
                if (!old)
                    return old;
                const newRuleSets = old.data;
                const ruleSet = newRuleSets[result.source.index];
                newRuleSets.splice(result.source.index, 1);
                newRuleSets.splice(result.destination.index, 0, ruleSet);
                console.log({ newRuleSets });
                return Object.assign(Object.assign({}, old), newRuleSets);
            });
        }
    }, [queryClient, compsFinderRuleSetsListApi]);
    const loading = [
        {
            label: "Loading rule sets...",
            status: compsFinderRuleSetsListApi.status,
        },
    ];
    const { error } = compsFinderRuleSetsListApi;
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (loading.find((x) => x.status === "pending") || !ruleSets) {
        return React.createElement(Loading, { items: loading });
    }
    return (React.createElement(Grid2, { container: true, spacing: 1, style: { height: "100%", width: "100%" } },
        React.createElement(Grid2, { size: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, { variant: "body2" },
                            "Drag & drop the rule sets below to update the order in which they should appear. Press the",
                            " ",
                            React.createElement(Save, { fontSize: "small" }),
                            " button when done.")),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, { style: {
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(Tooltip, { title: "Save" },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: save, disabled: isSaving },
                                    React.createElement(Save, null)))),
                        React.createElement(Tooltip, { title: "Close" },
                            React.createElement(IconButton, { component: RouterLink, to: "/tools/comps-puller/rules" },
                                React.createElement(Close, null))))))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12, style: { height: "calc(100% - 70px)", overflowY: "hidden", position: "relative" } },
            React.createElement(Box, { p: 2, style: { height: "100%" } },
                React.createElement(DragDropContext, { onDragEnd: onDrop },
                    React.createElement(Droppable, { droppableId: "reorder-list" }, (droppableProvided) => (React.createElement(React.Fragment, null,
                        React.createElement(List, Object.assign({}, droppableProvided.droppableProps, { ref: droppableProvided.innerRef, style: {
                                maxHeight: "calc(100% - 30px)",
                                overflowY: "auto",
                            }, dense: true }), ruleSets.map((obj, indx) => (React.createElement(Draggable, { key: obj.uid, draggableId: String(obj.uid), index: indx }, (draggableProvided) => (React.createElement(ListItem, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps, { key: obj.uid }),
                            React.createElement(ListItemIcon, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps, { style: {
                                    cursor: "grab",
                                } }),
                                React.createElement(Reorder, { fontSize: "small" })),
                            React.createElement(ListItemText, { primary: indx + 1, secondary: obj.name }))))))),
                        droppableProvided.placeholder))))))));
};
export default CompsPullerRuleSetsReorder;
