/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List all SOP folders
 */
export const listSopFolders = (params, options) => {
    return axios.get(`/api/sop_folders`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListSopFoldersQueryKey = (params) => {
    return [`/api/sop_folders`, ...(params ? [params] : [])];
};
export const getListSopFoldersQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSopFoldersQueryKey(params);
    const queryFn = ({ signal }) => listSopFolders(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all SOP folders
 */
export function useListSopFolders(params, options) {
    const queryOptions = getListSopFoldersQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create an SOP folder
 */
export const createSopFolder = (sOPFolderRequest, options) => {
    return axios.post(`/api/sop_folders`, sOPFolderRequest, options);
};
export const getCreateSopFolderMutationOptions = (options) => {
    const mutationKey = ['createSopFolder'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createSopFolder(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create an SOP folder
*/
export const useCreateSopFolder = (options) => {
    const mutationOptions = getCreateSopFolderMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get an SOP folder
*/
export const getSopFolder = (uid, options) => {
    return axios.get(`/api/sop_folders/${uid}`, options);
};
export const getGetSopFolderQueryKey = (uid) => {
    return [`/api/sop_folders/${uid}`];
};
export const getGetSopFolderQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSopFolderQueryKey(uid);
    const queryFn = ({ signal }) => getSopFolder(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an SOP folder
 */
export function useGetSopFolder(uid, options) {
    const queryOptions = getGetSopFolderQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update an SOP folder
 */
export const updateSopFolder = (uid, sOPFolderRequest, options) => {
    return axios.post(`/api/sop_folders/${uid}`, sOPFolderRequest, options);
};
export const getUpdateSopFolderMutationOptions = (options) => {
    const mutationKey = ['updateSopFolder'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateSopFolder(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an SOP folder
*/
export const useUpdateSopFolder = (options) => {
    const mutationOptions = getUpdateSopFolderMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete an SOP folder
*/
export const deleteSopFolder = (uid, options) => {
    return axios.delete(`/api/sop_folders/${uid}`, options);
};
export const getDeleteSopFolderMutationOptions = (options) => {
    const mutationKey = ['deleteSopFolder'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteSopFolder(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete an SOP folder
*/
export const useDeleteSopFolder = (options) => {
    const mutationOptions = getDeleteSopFolderMutationOptions(options);
    return useMutation(mutationOptions);
};
