import * as React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import { navigateTo } from "@app/entrypoints/brokerage/window-messages";
import { listTransactions } from "@app/orval/api/agent-dashboard";
const FollowUpBossRedirect = (props) => {
    const { boxUid } = props;
    const listBoxesApi = usePostQuery(listTransactions, { uids: [boxUid] }, {});
    const boxes = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listBoxesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listBoxesApi.data]);
    // @ts-expect-error
    const fubId = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (boxes != null && boxes.length > 0) {
            return ((_j = (_f = (_d = (_b = (_a = boxes[0].buyer_lead) === null || _a === void 0 ? void 0 : _a.fields["FUB ID"]) !== null && _b !== void 0 ? _b : (_c = boxes[0].seller_lead) === null || _c === void 0 ? void 0 : _c.fields["FUB ID"]) !== null && _d !== void 0 ? _d : (_e = boxes[0].fields) === null || _e === void 0 ? void 0 : _e["FUB ID"]) !== null && _f !== void 0 ? _f : (_h = (_g = boxes[0].buyer_contract) === null || _g === void 0 ? void 0 : _g.buyer_lead) === null || _h === void 0 ? void 0 : _h.fields["FUB ID"]) !== null && _j !== void 0 ? _j : (_l = (_k = boxes[0].seller_contract) === null || _k === void 0 ? void 0 : _k.seller_lead) === null || _l === void 0 ? void 0 : _l.fields["FUB ID"]);
        }
        return null;
    }, [boxes]);
    console.log({ boxes, fubId });
    React.useEffect(() => {
        if (fubId) {
            navigateTo(`/2/people/view/${fubId}`);
        }
    }, [fubId]);
    if (listBoxesApi.error) {
        return React.createElement(CoreError, { error: listBoxesApi.error });
    }
    if (!listBoxesApi.isFetching && !fubId) {
        return (React.createElement(Container, { maxWidth: "md" },
            React.createElement(Box, { p: 4 },
                React.createElement(Stack, { spacing: 2, alignItems: "center" },
                    React.createElement(Typography, { variant: "h5", style: { fontWeight: "bold" } }, "Unable to open the box"),
                    React.createElement(Typography, { variant: "body1", gutterBottom: true }, "The FUB ID field hasn't been set for this box.")))));
    }
    return (React.createElement(Container, { maxWidth: "md" },
        React.createElement(Box, { p: 4 },
            React.createElement(Typography, { variant: "body1", gutterBottom: true, align: "center" }, "Loading box info..."))));
};
export default FollowUpBossRedirect;
