import * as React from "react";
import { Autocomplete, Avatar, Box, Chip, Grid, List, ListItemButton, ListItemText, TextField, Typography, } from "@mui/material";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import { searchUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
import { getInitials } from "@app/util/Utils";
import CoreLoading from "./CoreLoading";
const UserSelect = (props) => {
    var _a;
    const { onSelect, selectedUserUids } = props;
    const [search, setSearch] = React.useState("");
    const listUsersApi = usePostQuery(searchUsers, { limit: 1000, disable_sort: true }, INFINITE_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const selectedUsers = React.useMemo(() => {
        var _a;
        return (_a = users === null || users === void 0 ? void 0 : users.filter((x) => selectedUserUids.includes(x.uid))) !== null && _a !== void 0 ? _a : [];
    }, [selectedUserUids, users]);
    const selectedUserUidsSet = React.useMemo(() => {
        return new Set(selectedUserUids);
    }, [selectedUserUids]);
    const onSearchChanged = React.useCallback((e) => {
        setSearch(e.target.value);
    }, [setSearch]);
    const toggleUser = React.useCallback((user) => () => {
        if (selectedUsers.some((x) => x.uid === user.uid)) {
            onSelect(selectedUsers.filter((x) => x.uid !== user.uid));
        }
        else {
            onSelect([...new Set([...selectedUsers, user])]);
        }
    }, [onSelect, selectedUsers]);
    const onDeleteChip = React.useCallback((chipValue) => () => {
        console.log({ chipValue, new: selectedUsers.filter((user) => user.uid !== chipValue) });
        onSelect(selectedUsers.filter((user) => user.uid !== chipValue));
    }, [onSelect, selectedUsers]);
    const renderTags = React.useCallback((values, tagProps) => {
        return values.map((value, index) => {
            var _a;
            console.log({ value });
            const user = users.find((x) => x.uid === value);
            return (React.createElement(Chip, Object.assign({}, tagProps({ index }), { key: value, size: "small", sx: { ".MuiChip-label": { paddingLeft: 0 } }, label: React.createElement(Grid, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Avatar, { sx: { height: "22px", width: "22px", fontSize: "0.7rem" }, src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined }, getInitials(user.first_name, user.last_name) || "?")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, user.name))), onDelete: onDeleteChip(value) })));
        });
    }, [onDeleteChip, users]);
    const renderInput = React.useCallback((params) => {
        return React.createElement(TextField, Object.assign({}, params, { variant: "outlined", size: "small", onChange: onSearchChanged }));
    }, [onSearchChanged]);
    console.log({ selectedUserUids });
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", sx: { minWidth: "300px" } },
        users && (React.createElement(Grid, { item: true },
            React.createElement(Autocomplete, { options: [], renderInput: renderInput, multiple: true, renderTags: renderTags, value: selectedUserUids, disableClearable: true, disableCloseOnSelect: true, open: false, sx: {
                    "& .MuiAutocomplete-popupIndicator": {
                        display: "none",
                    },
                } }))),
        users && (React.createElement(Grid, { item: true },
            React.createElement(List, { dense: true }, users
                .filter((user) => {
                if (!search.trim()) {
                    return true;
                }
                return user.name.toLowerCase().includes(search.toLowerCase());
            })
                .map((user) => {
                var _a;
                return (React.createElement(ListItemButton, { key: user.uid, onClick: toggleUser(user), sx: {
                        paddingTop: 0,
                        paddingBottom: 0,
                        background: selectedUserUidsSet.has(user.uid) ? AppConfig.themeColors.lightestblue : undefined,
                    } },
                    React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, sx: { height: "22px", width: "22px", fontSize: "0.7rem" } }, getInitials(user.first_name, user.last_name) || "?")),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body1" }, user.name)),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body1", sx: { color: "#999" } }, "\u2022")),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body1", sx: { color: "#999" } }, user.email))) })));
            })))),
        !users && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true },
                React.createElement(Box, { p: 2 },
                    React.createElement(Typography, { variant: "body1" }, "Loading users..."))),
            React.createElement(Grid, { item: true },
                React.createElement(Box, { p: 2, sx: { minHeight: "300px" } },
                    React.createElement(CoreLoading, null)))))));
};
export default UserSelect;
