import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AccountBox, Book, Description, Error, Notes, PlayArrow } from "@mui/icons-material";
import { Tabs, Tab, Grid, Alert } from "@mui/material";
import styled from "styled-components";
import streakIcon from "@app/assets/img/streak.png";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import useSession from "@app/hooks/useSession";
import MailScheduleBoilerplateWrapper from "./boilerplate/MailScheduleBoilerplateWrapper";
import MailScheduleBoxesTableWrapper from "./boxes/MailScheduleBoxesTableWrapper";
import MailScheduleErrorsTable from "./errors/MailScheduleErrorsTable";
import MailScheduleExecutionsWrapper from "./logs/MailScheduleExecutionsWrapper";
import MailScheduleRun from "./run/MailScheduleRun";
import MailScheduleSettings from "./settings/MailScheduleSettings";
import MailScheduleTemplatesWrapper from "./templates/MailScheduleTemplatesWrapper";
import { quillFontWhitelist } from "./utils/utils";
const StyledMailScheduleWrapper = styled("div") `
    height: 100%;

    ${quillFontWhitelist
    .map((font) => `
    .ql-picker.ql-font .ql-picker-label[data-value="${font}"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="${font}"]::before
    {
        font-family: "${font}", cursive;;
        content: "${font}" !important;
    }
    .ql-font-${font} {
        font-family: "${font}";
    }
    `)
    .join("")}

    .mention {
        height: 24px;
        width: 65px;
        border-radius: 6px;
        background-color: #d3e1eb;
        padding: 3px 0;
        margin-right: 2px;
        user-select: all;
        > span {
            margin: 0 3px;
        }
    }
    .mention[data-id*="::"] {
        background-color: #daedab;
    }
    .ql-mention-list-container {
        z-index: 1;
        ul {
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
            max-height: 200px;
            overflow-y: scroll;
            background: white;
            margin: 0;
            padding: 0;
            li {
                padding: 0.25rem;
                list-style-type: none;
                &:hover {
                    background: #eee;
                }
            }
        }
    }
    .ql-mention-denotation-char {
        display: none;
    }
    .MuiTab-root {
      @media (min-width: 600px) {
        min-width: 160px;
      }
      @media (max-width: 600px) {
        min-width: 72px;
      }
    }
`;
const MailScheduleWrapper = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    useDocumentTitle("Mail Schedule");
    const session = useSession();
    const tabs = [
        {
            name: "settings",
            isVisible: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]),
        },
        {
            name: "templates",
            isVisible: (_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"]),
        },
        {
            name: "boilerplate",
            isVisible: (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin", "Admin"]),
        },
        {
            name: "boxes",
            isVisible: true,
        },
        {
            name: "run",
            isVisible: true,
        },
        {
            name: "errors",
            isVisible: true,
        },
        {
            name: "logs",
            isVisible: (_d = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.hasRole(["Superadmin", "Admin"]),
        },
    ];
    const { page } = props;
    return (React.createElement(StyledMailScheduleWrapper, null,
        React.createElement(Grid, { container: true, direction: "column", spacing: 0, wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
            React.createElement(Grid, { item: true },
                React.createElement(Alert, { severity: "info", icon: React.createElement("img", { src: streakIcon, alt: "Streak", height: 24 }) },
                    "This is the the Streak Mail Schedule. ",
                    React.createElement(RouterLink, { to: "/ms4" }, "Click here"),
                    " if you're looking for the New CRM Mail Schedule.")),
            React.createElement(Grid, { item: true, style: { flex: 1, overflow: "hidden" } },
                React.createElement(Tabs, { value: tabs
                        .filter((tab) => tab.isVisible)
                        .map((tab) => tab.name)
                        .indexOf(page), indicatorColor: "primary", textColor: "primary" },
                    ((_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/mailschedule/settings", label: React.createElement("div", null,
                            React.createElement(Description, { fontSize: "inherit" }),
                            " Settings") })),
                    ((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/mailschedule/templates", label: React.createElement("div", null,
                            React.createElement(Description, { fontSize: "inherit" }),
                            " Templates") })),
                    ((_g = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/mailschedule/boilerplate", label: React.createElement("div", null,
                            React.createElement(Notes, { fontSize: "inherit" }),
                            " Boilerplate") })),
                    React.createElement(Tab, { component: RouterLink, to: "/mailschedule/boxes", label: React.createElement("div", null,
                            React.createElement(AccountBox, { fontSize: "inherit" }),
                            " Boxes") }),
                    React.createElement(Tab, { component: RouterLink, to: "/mailschedule/run", label: React.createElement("div", null,
                            React.createElement(PlayArrow, { fontSize: "inherit" }),
                            " Run") }),
                    React.createElement(Tab, { component: RouterLink, to: "/mailschedule/errors", label: React.createElement("div", null,
                            React.createElement(Error, { fontSize: "inherit" }),
                            " Errors") }),
                    ((_h = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _h === void 0 ? void 0 : _h.hasRole(["Superadmin", "Admin"])) && (React.createElement(Tab, { component: RouterLink, to: "/mailschedule/logs?template_uid_isnull=true&pipeline_key_isnull=true&box_key_isnull=true&agent_email_isnull=true", label: React.createElement("div", null,
                            React.createElement(Book, { fontSize: "inherit" }),
                            " Execution Logs") }))),
                React.createElement("div", { style: { height: "calc(100% - 48px)", overflowY: "auto" } },
                    page === "settings" && React.createElement(MailScheduleSettings, null),
                    page === "templates" && React.createElement(MailScheduleTemplatesWrapper, null),
                    page === "boilerplate" && React.createElement(MailScheduleBoilerplateWrapper, null),
                    page === "boxes" && React.createElement(MailScheduleBoxesTableWrapper, null),
                    page === "run" && React.createElement(MailScheduleRun, null),
                    page === "errors" && React.createElement(MailScheduleErrorsTable, null),
                    page === "logs" && React.createElement(MailScheduleExecutionsWrapper, null))))));
};
export default MailScheduleWrapper;
