var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import * as React from "react";
import { TextField } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import omit from "lodash/omit";
import AutocompleteTextField from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import styled from "styled-components";
import Session from "@app/util/Session";
import { isChromeExtension, isValidUrl, validateEmail } from "@app/util/Utils";
import EmailMenu from "./links/EmailMenu";
import PhoneMenu from "./links/PhoneMenu";
const StyledAutocompleteTextField = styled(AutocompleteTextField) `
    width: 100%;
`;
const AutocompleteTextFieldWrapper = styled("div") `
    position: relative;
    width: 100%;
    & span {
        width: 100%;
        position: absolute;
        top: 16px;
        left: 0;
    }
    & ul.react-autocomplete-input {
        max-height: 200px;
        overflow-y: scroll;
    }
`;
const userIsInternal = (_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal;
const CoreTextField = (props) => {
    var _a, _b, _c, _d;
    const _e = omit(props, "onTextChange", "value", "error", "helperText"), { autocomplete, errorAfterBlurOnly, onAutocompleteSelect, onBlurError, onBlurHelperText, required = false } = _e, other = __rest(_e, ["autocomplete", "errorAfterBlurOnly", "onAutocompleteSelect", "onBlurError", "onBlurHelperText", "required"]);
    const [hasBeenBlurred, setHasBeenBlurred] = React.useState(false);
    const [error, setError] = React.useState(props.error);
    const [helperText, setHelperText] = React.useState(props.helperText);
    const [value, setValue] = React.useState(props.value);
    const [phoneMenuAnchorEl, setPhoneMenuAnchorEl] = React.useState(null);
    const [emailMenuAnchorEl, setEmailMenuAnchorEl] = React.useState(null);
    const initialValue = React.useRef(props.value);
    const inputRef = React.useRef(null);
    const autocompleteRef = React.useRef(null);
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    React.useEffect(() => {
        setError(props.error);
    }, [props.error]);
    React.useEffect(() => {
        setHelperText(props.helperText);
    }, [props.helperText]);
    const kindOfText = React.useMemo(() => {
        // return null;
        // only clickable links for logged in users
        if (!userIsInternal) {
            return null;
        }
        // emails are only clickable in the chorme extension
        if (isChromeExtension && validateEmail(String(value))) {
            return "email";
        }
        if (isValidPhoneNumber(String(value), "US")) {
            return "phone";
        }
        if (isValidUrl(String(value))) {
            return "website";
        }
        return null;
    }, [value]);
    const textDecoration = React.useMemo(() => {
        if (kindOfText != null) {
            return "underline";
        }
        return undefined;
    }, [kindOfText]);
    const color = React.useMemo(() => {
        if (kindOfText != null) {
            return "rgb(6, 77, 121)";
        }
        return undefined;
    }, [kindOfText]);
    const handleValidation = React.useCallback((e) => {
        var _a;
        const error_ = typeof props.onBlurError === "function" ? props.onBlurError(e) : props.onBlurError;
        // console.log(this.props.required, e.target.value.trim())
        if (props.required && !((_a = e.target.value) !== null && _a !== void 0 ? _a : "").trim() /* && !e.target.value === "0" && !e.target.value === 0 */) {
            setError(true);
            setHelperText("This field is required");
        }
        else if (error_) {
            setError(true);
            setHelperText(typeof props.onBlurHelperText === "function" ? props.onBlurHelperText(e) : props.onBlurHelperText);
        }
        else {
            setError(false);
            setHelperText(null);
        }
    }, [props]);
    const onBlur = React.useCallback((e) => {
        const { onTextChange } = props;
        setHasBeenBlurred(true);
        if (props.onBlur) {
            props.onBlur(e);
        }
        if (e.target.value !== initialValue.current) {
            if (onTextChange) {
                onTextChange(e.target.value);
            }
            initialValue.current = e.target.value;
        }
        handleValidation(e);
    }, [handleValidation, props]);
    const onChange = React.useCallback((e) => {
        const { regexp } = props;
        if (regexp && !e.target.value.match(new RegExp(regexp, "gi"))) {
            return;
        }
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }, [props]);
    const onAutocompleteChange = React.useCallback((val) => {
        const elementPrototype = window.HTMLInputElement.prototype;
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(elementPrototype, "value").set;
        nativeInputValueSetter.call(inputRef.current, val);
        const ev2 = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(ev2);
    }, []);
    const onKeyDown = React.useCallback((e) => {
        var _a, _b;
        console.log(autocompleteRef.current);
        autocompleteRef.current.refInput.current.dispatchEvent(new KeyboardEvent("keydown", { key: e.key }));
        autocompleteRef.current.refInput.current.dispatchEvent(new Event("input"));
        (_a = document.querySelector("input")) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new KeyboardEvent("keydown", { key: e.key }));
        (_b = document.querySelector("input")) === null || _b === void 0 ? void 0 : _b.dispatchEvent(new Event("input"));
        autocompleteRef.current.props.onKeyDown(e);
    }, []);
    const showPhoneMenu = React.useCallback(() => {
        setPhoneMenuAnchorEl(inputRef.current);
        setEmailMenuAnchorEl(null);
    }, []);
    const closePhoneMenu = React.useCallback(() => {
        setPhoneMenuAnchorEl(null);
    }, []);
    const showEmailMenu = React.useCallback(() => {
        setEmailMenuAnchorEl(inputRef.current);
        setPhoneMenuAnchorEl(null);
    }, []);
    const closeEmailMenu = React.useCallback(() => {
        setEmailMenuAnchorEl(null);
    }, []);
    const onTextClicked = React.useCallback((e) => {
        const target = e.target;
        const cursorPosition = target.selectionStart;
        if (cursorPosition &&
            cursorPosition > 0 &&
            cursorPosition < target.value.length &&
            target.selectionStart === target.selectionEnd) {
            if (kindOfText === "email") {
                showEmailMenu();
            }
            else if (kindOfText === "phone") {
                showPhoneMenu();
            }
            else if (kindOfText === "website") {
                let url = target.value;
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                    url = `http://${url}`; // Prepend the default scheme if not present
                }
                window.open(url, "_blank");
            }
        }
    }, [kindOfText, showEmailMenu, showPhoneMenu]);
    const textfield = (React.createElement(React.Fragment, null,
        React.createElement(TextField, Object.assign({}, other, { inputRef: inputRef, error: !errorAfterBlurOnly || hasBeenBlurred ? error || error : undefined, helperText: !errorAfterBlurOnly || hasBeenBlurred ? helperText || helperText : undefined, value: value !== null && value !== void 0 ? value : "", onBlur: onBlur, onChange: onChange, sx: Object.assign(Object.assign({}, props.sx), { "& input": Object.assign(Object.assign(Object.assign({}, (_a = props.sx) === null || _a === void 0 ? void 0 : _a["& input"]), (_b = props.inputProps) === null || _b === void 0 ? void 0 : _b.style), { textDecoration: textDecoration !== null && textDecoration !== void 0 ? textDecoration : undefined, color: color !== null && color !== void 0 ? color : undefined }) }), slotProps: Object.assign(Object.assign({}, props.slotProps), { htmlInput: Object.assign(Object.assign(Object.assign({}, props.inputProps), (_c = props.slotProps) === null || _c === void 0 ? void 0 : _c.htmlInput), { onKeyDown: autocomplete ? onKeyDown : (_d = props.inputProps) === null || _d === void 0 ? void 0 : _d.onKeyDown, onClick: onTextClicked }) }) }), props.children),
        phoneMenuAnchorEl && (React.createElement(PhoneMenu, { MenuProps: {
                anchorEl: phoneMenuAnchorEl,
                open: true,
                onClose: closePhoneMenu,
                onClick: closePhoneMenu,
            }, contacts: [{ name: "", phone: String(value) }] })),
        emailMenuAnchorEl && (React.createElement(EmailMenu, { MenuProps: {
                anchorEl: emailMenuAnchorEl,
                open: true,
                onClose: closeEmailMenu,
                onClick: closeEmailMenu,
            }, contacts: [{ name: "", email: String(value) }] }))));
    if (autocomplete) {
        return (React.createElement(AutocompleteTextFieldWrapper, { className: "MuiInputBase-root" },
            textfield,
            React.createElement(StyledAutocompleteTextField, { ref: autocompleteRef, trigger: autocomplete.map((a) => a.trigger), options: autocomplete.reduce((opts, item) => (Object.assign(Object.assign({}, opts), { [item.trigger]: item.options })), {}), maxOptions: 0, changeOnSelect: onAutocompleteSelect, Component: "input", className: "MuiInputBase-input MuiInput-input MuiInputBase-root", value: value !== null && value !== void 0 ? value : "", 
                // @ts-expect-error: Type 'ChangeEvent<HTMLTextAreaElement>' is not assignable to type 'string'
                onChange: onAutocompleteChange, style: {
                    width: "100%",
                    outline: "none",
                    border: "none",
                    background: "none",
                } })));
    }
    return textfield;
};
export default CoreTextField;
