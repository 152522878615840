import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { withRouter } from "@app/hoc/withRouter";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import ContactsForm from "./ContactsForm";
const ContactsFormPage = () => {
    var _a;
    useDocumentTitle("Contacts");
    const params = useParams();
    const searchParams = useSearchParams();
    const tab = (_a = searchParams[0].get("tab")) !== null && _a !== void 0 ? _a : undefined;
    return React.createElement(ContactsForm, { tab: tab, uid: params.uid });
};
export default withRouter(ContactsFormPage);
