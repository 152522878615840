import * as React from "react";
import { Button } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import { isAxiosError } from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";
const ActionButton = (props) => {
    const { error, snackbarKey } = props;
    const isAxiosError_ = isAxiosError(error);
    const dismiss = React.useCallback(() => {
        closeSnackbar(snackbarKey);
    }, [snackbarKey]);
    const onClick = React.useCallback(() => {
        const newWindow = window.open("", "_blank");
        const errorStr = isAxiosError_ ? JSON.stringify(format(error), null, 2) : String(error);
        newWindow === null || newWindow === void 0 ? void 0 : newWindow.document.write(`<pre>${errorStr}</pre>`);
        dismiss();
    }, [isAxiosError_, error, dismiss]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: dismiss, style: { color: "white" } }, "Dismiss"),
        isAxiosError_ && (React.createElement(Button, { onClick: onClick, style: { color: "white" } }, "See more"))));
};
// eslint-disable-next-line import/prefer-default-export
export const enqueueErrorSnackbar = (error, params) => {
    const action = (key) => {
        return React.createElement(ActionButton, { snackbarKey: key, error: error });
    };
    const label = (() => {
        var _a, _b, _c, _d, _e, _f;
        if (typeof error === "string") {
            return error;
        }
        if (params === null || params === void 0 ? void 0 : params.label) {
            return params.label;
        }
        if (Array.isArray((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) && error.response.data.every((x) => typeof x === "string")) {
            return (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.join("; ");
        }
        if (((_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.detail) && typeof error.response.data.detail === "string") {
            return error.response.data.detail;
        }
        if (((_f = (_e = error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.error) && typeof error.response.data.error === "string") {
            return error.response.data.error;
        }
        return "An error occurred";
    })();
    enqueueSnackbar(label.length > 255 ? `${label.slice(0, 255)}...` : label, Object.assign({ variant: "error", persist: true, action }, params === null || params === void 0 ? void 0 : params.options));
};
