import * as React from "react";
import { Table, TableCell, TableRow, TableBody, Grid, Button } from "@mui/material";
import dayjs from "dayjs";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { useGetRecruitingQfaSubmissionNewcrm } from "@app/orval/api/recruiting-qfa";
const QuestionsForTheAffiliationFormSubmissionNEWCRM = (props) => {
    var _a, _b, _c, _d;
    const { uid } = props;
    const getQFAAPI = useGetRecruitingQfaSubmissionNewcrm(uid);
    const qfa = (_b = (_a = getQFAAPI.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [showMore, setShowMore] = React.useState(false);
    const toggleShowMore = React.useCallback(() => {
        setShowMore((prev) => !prev);
    }, []);
    if (getQFAAPI.error) {
        return React.createElement(CoreError, { error: getQFAAPI.error });
    }
    if (getQFAAPI.isFetching || !qfa) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Table, { size: "small", sx: { "& tr td:first-child ": { fontWeight: "bold" } } },
        React.createElement(TableBody, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "First Name"),
                React.createElement(TableCell, null, qfa.first_name)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Last Name"),
                React.createElement(TableCell, null, qfa.last_name)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Personal Email"),
                React.createElement(TableCell, null, qfa.personal_email)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Phone Number"),
                React.createElement(TableCell, null, qfa.phone_number)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "License #"),
                React.createElement(TableCell, null, qfa.license_no)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Start Date"),
                React.createElement(TableCell, null, dayjs(qfa.start_date).format("dddd, MMMM D, YYYY"))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Access to MLS"),
                React.createElement(TableCell, null, (_c = qfa.access_to_mls) !== null && _c !== void 0 ? _c : "No")),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Regional Realtor Association"),
                React.createElement(TableCell, null, (_d = qfa.regional_realtor_association) !== null && _d !== void 0 ? _d : "No")),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Full Submission"),
                React.createElement(TableCell, null,
                    React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { size: "small", variant: "text", onClick: toggleShowMore }, showMore ? "Show Less" : "Show More")),
                        showMore && (React.createElement(Grid, { item: true },
                            React.createElement("pre", null, JSON.stringify(qfa.submission_json, null, 2))))))))));
};
export default QuestionsForTheAffiliationFormSubmissionNEWCRM;
