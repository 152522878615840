import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { formatDate_Dayjs, getInitials } from "@app/util/Utils";
const PSPCertificationFeedbackBlurb = (props) => {
    const { feedback } = props;
    console.log({ feedback });
    return (React.createElement(List, { dense: true }, feedback.map((item) => {
        var _a;
        return (React.createElement(ListItem, { key: item.uid },
            React.createElement(ListItemAvatar, null,
                React.createElement(Avatar, { src: (_a = item.reviewer.icon_url) !== null && _a !== void 0 ? _a : undefined }, getInitials(item.reviewer.first_name, item.reviewer.last_name))),
            React.createElement(ListItemText, { primary: item.feedback, secondary: formatDate_Dayjs(item.timestamp, "ddd, MMM D, YYYY h:mm A"), style: { whiteSpace: "pre-wrap" }, sx: {
                    "& .MuiListItemText-secondary": {
                        color: "white",
                        fontStyle: "italic",
                    },
                } })));
    })));
};
export default PSPCertificationFeedbackBlurb;
