import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
const useUpdateSearch = (options) => {
    var _a;
    const allowBlankQueryParams = (_a = options === null || options === void 0 ? void 0 : options.allowBlankQueryParams) !== null && _a !== void 0 ? _a : false;
    const navigateOptions = options === null || options === void 0 ? void 0 : options.navigateOptions;
    const search = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const updateSearch = (...args) => {
        const params = search[0];
        for (let i = 0; i < args.length; i += 2) {
            const param = args[i];
            const value = args[i + 1];
            if (value === null || value === undefined || (!allowBlankQueryParams && value === "")) {
                params.delete(param);
            }
            else {
                params.set(param, value);
            }
        }
        navigate(`${location.pathname}?${params.toString()}`, navigateOptions);
    };
    return updateSearch;
};
export default useUpdateSearch;
