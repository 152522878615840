import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Add, Delete, FileCopy, Launch, Refresh } from "@mui/icons-material";
import { IconButton, TableRow, TableCell, Tooltip, MenuItem, ListItemIcon, CircularProgress, Grid, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { htmlToText } from "html-to-text";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import DashPopover from "@app/common/CoreMoreMenu";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useLoading from "@app/hooks/useLoading";
import { useDeleteMailschedule4Boilerplate, useDuplicateMailschedule4Boilerplate, useListMailschedule4Boilerplate, } from "@app/orval/api/mail-schedule-4-boilerplate";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const MailScheduleBoilerplateTable = () => {
    var _a, _b;
    const listBoilerplateApi = useListMailschedule4Boilerplate(REFETCH_CACHE_PARAMS);
    const boilerplate = (_b = (_a = listBoilerplateApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const navigate = useNavigate();
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const duplicateBoilerplateApi = useDuplicateMailschedule4Boilerplate();
    const deleteBoilerplateApi = useDeleteMailschedule4Boilerplate();
    /** Duplicates the boilerplate and redirects to the new boilerplate form on success */
    const duplicateBoilerplate = React.useCallback((uid) => () => {
        const loadingSnackbar = enqueueSnackbar("Duplicating boilerplate...", {
            variant: "info",
            persist: true,
        });
        duplicateBoilerplateApi
            .mutateAsync({ uid })
            .then((result) => {
            enqueueSnackbar("Boilerplate has been duplicated.", { variant: "success" });
            navigate(`/ms4/boilerplate/${result.data.uid}`);
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
        })
            .finally(() => [closeSnackbar(loadingSnackbar)]);
    }, [duplicateBoilerplateApi, navigate]);
    const deleteBoilerplate = React.useCallback((uid) => () => {
        confirm(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS)).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listBoilerplateApi.queryKey, (oldData) => {
                    if (!oldData)
                        return oldData;
                    return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((x) => x.uid !== uid) });
                });
                const loadingSnackbar = enqueueSnackbar("Deleting boilerplate...", {
                    variant: "info",
                    persist: true,
                });
                deleteBoilerplateApi
                    .mutateAsync({ uid })
                    .then((result) => {
                    console.log({ result });
                    enqueueSnackbar("Boilerplate has been deleted", { variant: "success" });
                })
                    .catch((error) => {
                    enqueueErrorSnackbar(error);
                })
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [confirm, deleteBoilerplateApi, listBoilerplateApi.queryKey, queryClient]);
    const refresh = React.useCallback(() => {
        listBoilerplateApi.refetch();
    }, [listBoilerplateApi]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading boilerplate",
                queryResult: listBoilerplateApi,
            },
        ],
    });
    if (isLoading) {
        return loadingComponent;
    }
    if (!boilerplate) {
        return null; // should never happen
    }
    const rows = boilerplate.map((item) => (Object.assign({ id: item.uid }, item)));
    const columns = [
        {
            name: "actions",
            label: " ",
            options: {
                searchable: false,
                sort: false,
                download: false,
                filter: false,
                print: false,
                customBodyRender: (_, tableMeta) => {
                    const item = rows[tableMeta.rowIndex];
                    return (React.createElement(DashPopover, null,
                        React.createElement(MenuItem, { component: RouterLink, to: `/ms4/boilerplate/${item.uid}` },
                            React.createElement(ListItemIcon, null,
                                React.createElement(Launch, { fontSize: "small" })),
                            "Open"),
                        React.createElement(MenuItem, { onClick: duplicateBoilerplate(item.uid) },
                            React.createElement(ListItemIcon, null,
                                React.createElement(FileCopy, { fontSize: "small" })),
                            "Duplicate"),
                        React.createElement(MenuItem, { onClick: deleteBoilerplate(item.uid) },
                            React.createElement(ListItemIcon, null,
                                React.createElement(Delete, { fontSize: "small" })),
                            "Delete")));
                },
            },
        },
        {
            name: "name",
            label: "Name",
        },
        {
            name: "entity_type",
            label: "Pipeline",
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        return `${value}s`;
                    }
                    return value;
                },
            },
        },
        {
            name: "content",
            label: "Content",
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        const text = htmlToText(value);
                        if (text.length > 50) {
                            return `${text.substring(0, 50)}...`;
                        }
                        return text;
                    }
                    return value;
                },
            },
        },
    ];
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        search: false,
        searchOpen: true,
        pagination: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        viewColumns: false,
        expandableRows: true,
        customToolbar: () => (React.createElement(Grid, { container: true, direction: "row", spacing: 1, alignItems: "center", wrap: "nowrap", justifyContent: "right" },
            listBoilerplateApi.isFetching && (React.createElement(Grid, { item: true },
                React.createElement(CircularProgress, { size: 20 }))),
            React.createElement(Grid, { item: true },
                React.createElement(Tooltip, { title: "Refresh" },
                    React.createElement(IconButton, { onClick: refresh, disabled: listBoilerplateApi.isFetching },
                        React.createElement(Refresh, null)))),
            React.createElement(Grid, { item: true },
                React.createElement(Tooltip, { title: "Create new boilerplate" },
                    React.createElement(IconButton, { component: RouterLink, to: "/ms4/boilerplate/new" },
                        React.createElement(Add, null)))))),
        renderExpandableRow: (_, rowMeta) => (React.createElement(TableRow, null,
            React.createElement(TableCell, { colSpan: columns.length + 1, dangerouslySetInnerHTML: { __html: boilerplate[rowMeta.dataIndex].content } }))),
    };
    return React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 0 });
};
export default MailScheduleBoilerplateTable;
