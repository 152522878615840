import * as React from "react";
import { Email } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import EmailMenu from "@app/common/links/EmailMenu";
const EmailContactButton = (props) => {
    const { emailAddresses, entityType, entityUid } = props;
    const [emailMenuAnchorEl, setEmailMenuAnchorEl] = React.useState(null);
    const openEmailMenu = React.useCallback((e) => {
        setEmailMenuAnchorEl(e.currentTarget);
    }, []);
    const closeEmailMenu = React.useCallback(() => {
        setEmailMenuAnchorEl(null);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { size: "small", onClick: openEmailMenu, disabled: emailAddresses.length === 0 },
            React.createElement(Email, { fontSize: "small" })),
        React.createElement(EmailMenu, { contacts: emailAddresses.map((email) => ({ email })), MenuProps: {
                anchorEl: emailMenuAnchorEl,
                open: Boolean(emailMenuAnchorEl),
                onClose: closeEmailMenu,
                onClick: closeEmailMenu,
            }, entityType: entityType, entityUids: [entityUid] })));
};
export default EmailContactButton;
