import * as React from "react";
import { Phone, Sms } from "@mui/icons-material";
import { MenuItem, ListItemIcon, Menu, Link, Divider } from "@mui/material";
import twilioIcon from "@app/assets/img/twilio.png";
import { TwilioCallContext } from "@app/entrypoints/brokerage/context/twilio-calls/TwilioCallContext";
import { TwilioSMSContext } from "@app/entrypoints/brokerage/context/twilio-sms/TwilioSMSContext";
import { getCallUrl, getSmsUrl } from "@app/util/Utils";
const PhoneMenu = (props) => {
    const { MenuProps, contacts } = props;
    const voiceContext = React.useContext(TwilioCallContext);
    const smsContext = React.useContext(TwilioSMSContext);
    const callViaTwilio = React.useCallback(() => {
        voiceContext.openDialer(contacts.map((c) => { var _a; return ({ name: (_a = c.name) !== null && _a !== void 0 ? _a : "", phone_number: c.phone }); }));
    }, [contacts, voiceContext]);
    const textViaTwilio = React.useCallback(() => {
        smsContext.openConversation({ toPhoneNumber: contacts[0].phone });
    }, [contacts, smsContext]);
    return (React.createElement(Menu, Object.assign({}, MenuProps),
        React.createElement(MenuItem, { component: Link, href: getCallUrl(contacts[0].phone), disabled: contacts.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(Phone, null)),
            " ",
            "Call..."),
        React.createElement(MenuItem, { component: Link, href: getSmsUrl(contacts.map((c) => c.phone)), disabled: contacts.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement(Sms, null)),
            " ",
            "Text..."),
        React.createElement(Divider, null),
        React.createElement(MenuItem, { onClick: callViaTwilio, disabled: contacts.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: twilioIcon, alt: "Twilio", height: 20 })),
            " ",
            "Call via Twilio..."),
        React.createElement(MenuItem, { onClick: textViaTwilio, disabled: contacts.length === 0 },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: twilioIcon, alt: "Twilio", height: 20 })),
            " ",
            "Text via Twilio...")));
};
export default PhoneMenu;
