import * as React from "react";
import { Avatar, ListItemAvatar, ListItemText } from "@mui/material";
import PipelineAndStageText from "./PipelineAndStageText";
const RecruitSearchResult = (props) => {
    var _a, _b;
    const { recruit } = props;
    const initials = [recruit.fields["First Name"], recruit.fields["Last Name"]]
        .filter((obj) => obj)
        .map((obj) => obj === null || obj === void 0 ? void 0 : obj.substring(0, 1))
        .join("");
    const name = [recruit.fields["First Name"], recruit.fields["Last Name"]].filter((obj) => obj).join(" ");
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, { src: undefined, style: {
                    height: "30px",
                    width: "30px",
                    fontSize: "0.9rem",
                } }, initials && initials !== "" ? initials : "?")),
        React.createElement(ListItemText, { primary: name, secondary: React.createElement(PipelineAndStageText, { pipeline: "Recruit", stageName: (_a = recruit.stage) === null || _a === void 0 ? void 0 : _a.name, stageUid: (_b = recruit.stage) === null || _b === void 0 ? void 0 : _b.uid }) })));
};
export default RecruitSearchResult;
