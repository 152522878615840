import * as React from "react";
import { ArrowRight, ArrowLeft } from "@mui/icons-material";
import { Box, Grid2, Divider, List, InputAdornment, TextField, MenuItem, Typography, ListItemText, ListItem, Button, IconButton, Tooltip, FormControlLabel, Switch, Tabs, Tab, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import CoreLoading from "@app/common/CoreLoading";
import CoreTextField from "@app/common/CoreTextField";
import dayjs_ from "@app/common/dayjs";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { getDashscoreData, useGetDashscoreHistory, 
// getDashscoreBuyerLeadsData,
setDashscoreSpreadsheetId, useGetDashscoreSpreadsheetId, } from "@app/orval/api/dash-score";
import { useLoadDashscoreSettings } from "@app/orval/api/dashscore-settings";
import { useListDashscoreTabDescriptions, useListDashscoreTabs } from "@app/orval/api/dashscore-tab-sections";
import { useListUsers } from "@app/orval/api/users";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
import AgentScorecard from "./AgentScorecard";
import DashScoreLeaderboard from "./DashScoreLeaderboard";
import DashScoreLevels from "./DashScoreLevels";
import DASHscorePayout from "./DASHscorePayout";
import DashScoreSidebar, { SIDEBAR_WIDTH } from "./DashScoreSidebar";
import { DASHSCORE_LEVELS, DASHSCORE_TABS } from "./utils";
const HISTORY_START_DATE = dayjs_(new Date()).add(-30, "days").format("YYYY-MM-DD");
const DashScore = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const [spreadsheetId, setSpreadsheetId] = useStateWithCallback(null);
    const [currentLevel, setCurrentLevel] = React.useState(DASHSCORE_LEVELS[DASHSCORE_LEVELS.length - 1]);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [selectedSubject, setSelectedSubject] = React.useState("");
    const [dashScoreData, setDashScoreData] = useStateWithCallback(null);
    const [currentTab, setCurrentTab] = React.useState(DASHSCORE_TABS[0]);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const session = useSession();
    const isAdmin = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]);
    // const [dashScoreBuyerLeadsData, setDashScoreBuyerLeadsData] = React.useState<SubjectBuyerLeadsData[] | null>(null);
    // const [isLoadingBuyerLeadsData, setIsLoadingBuyerLeadsData] = useStateWithCallback<boolean>(false);
    const size = useSize();
    const queryClient = useQueryClient();
    const dashScoreSettingsLoadApi = useLoadDashscoreSettings(DEFAULT_CACHE_PARAMS);
    const usersListApi = useListUsers(undefined, DEFAULT_CACHE_PARAMS);
    const dashScoreListTabsApi = useListDashscoreTabs(DEFAULT_CACHE_PARAMS);
    const dashScoreGetSpreadsheetIdApi = useGetDashscoreSpreadsheetId(DEFAULT_CACHE_PARAMS);
    const isMountedRef = React.useRef(true);
    const isSmallWindow = React.useMemo(() => {
        return size.isDown("sm");
    }, [size]);
    React.useEffect(() => {
        // cleanup
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    // const getBuyerLeadsData = React.useCallback(async () => {
    //   let buyerLeadsData_: SubjectBuyerLeadsData[] = [];
    //   let startPage = 0;
    //   setIsLoadingBuyerLeadsData(true);
    //   while (true) {
    //     if (!isMountedRef.current) {
    //       // component has dismounted
    //       break;
    //     }
    //     const leadsThisPage = (await getDashscoreBuyerLeadsData({ limit: 1000, offset: startPage * 1000 })).data.items;
    //     console.log("leads this page:", leadsThisPage, startPage);
    //     buyerLeadsData_ = buyerLeadsData_.concat(leadsThisPage);
    //     // // NOTE: inefficient... one extra API call is required
    //     // // If we know page size, we can break one loop earlier
    //     if (leadsThisPage.length === 0) {
    //       break;
    //     }
    //     startPage += 1;
    //   }
    //   // aggregate data to sum up leads for each agent, range, and level
    //   const combinedData: { [key: string]: SubjectBuyerLeadsData } = buyerLeadsData_.reduce((acc, cur) => {
    //     const key = `${cur.name}-${cur.level}-${cur.range}`;
    //     // Step 3: Check if the key exists
    //     if (acc[key]) {
    //       // Sum up the other attributes
    //       acc[key].all_leads += cur.all_leads;
    //       acc[key].nurture_leads += cur.nurture_leads;
    //       acc[key].rejected_leads += cur.rejected_leads;
    //     } else {
    //       // If not exists, add the current element to the accumulator
    //       acc[key] = { ...cur };
    //     }
    //     return acc;
    //   }, {});
    //   const combinedDataArray: SubjectBuyerLeadsData[] = Object.values(combinedData).map((item) => {
    //     return {
    //       ...item,
    //       nurture_ratio: item.all_leads ? item.nurture_leads / item.all_leads : 0,
    //       rejected_ratio: item.all_leads ? item.rejected_leads / item.all_leads : 0,
    //     };
    //   });
    //   setDashScoreBuyerLeadsData(combinedDataArray);
    //   setIsLoadingBuyerLeadsData(false);
    // }, [setIsLoadingBuyerLeadsData]);
    // React.useEffect(() => {
    //   getBuyerLeadsData();
    //   // empty array prevents this from being called numerous times
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const settings = (_c = (_b = dashScoreSettingsLoadApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const users = (_e = (_d = usersListApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const scorecardTabs = (_g = (_f = dashScoreListTabsApi.data) === null || _f === void 0 ? void 0 : _f.data) !== null && _g !== void 0 ? _g : null;
    const initalSpreadsheetId = (_j = (_h = dashScoreGetSpreadsheetIdApi.data) === null || _h === void 0 ? void 0 : _h.data.spreadsheet_id) !== null && _j !== void 0 ? _j : null;
    const listTabDescriptionsApi = useListDashscoreTabDescriptions();
    const scorecardTabDescriptions = (_l = (_k = listTabDescriptionsApi.data) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : null;
    const dashScoreHistoryApi = useGetDashscoreHistory({ start_date: HISTORY_START_DATE });
    const dashScoreHistory = (_o = (_m = dashScoreHistoryApi.data) === null || _m === void 0 ? void 0 : _m.data) !== null && _o !== void 0 ? _o : null;
    const showErrorDialog = useErrorDialog();
    const scorecardTabNames = React.useMemo(() => {
        return scorecardTabs === null || scorecardTabs === void 0 ? void 0 : scorecardTabs.map((item) => {
            return item.name;
        });
    }, [scorecardTabs]);
    const sidebarToggleText = React.useMemo(() => {
        return isSidebarOpen ? "Hide" : "Show";
    }, [isSidebarOpen]);
    const agents = React.useMemo(() => {
        if (!dashScoreData || !users) {
            return null;
        }
        const validUsers = users.map((user) => {
            return `${user.first_name} ${user.last_name}`.toLowerCase().trim();
        });
        const agents_ = dashScoreData.agent_metrics
            .filter((x) => {
            return x.level === "Agent";
        })
            .map((x) => x.name)
            .filter((name) => {
            return validUsers.includes(name.toLowerCase().trim());
        });
        return [...new Set(agents_)].filter((o) => o).sort();
    }, [dashScoreData, users]);
    const teams = React.useMemo(() => {
        if (!dashScoreData) {
            return null;
        }
        const teamNames = dashScoreData.agent_metrics
            .filter((item) => {
            return item.level === "Team";
        })
            .map((item) => {
            return item.name.trim();
        });
        return [...new Set(teamNames)].filter((o) => !!o).sort();
    }, [dashScoreData]);
    const offices = React.useMemo(() => {
        if (!dashScoreData) {
            return null;
        }
        const officeNames = dashScoreData.agent_metrics
            .filter((item) => {
            return item.level === "Office";
        })
            .map((item) => {
            return item.name.trim();
        });
        return [...new Set(officeNames)].filter((o) => o).sort();
    }, [dashScoreData]);
    const dropdownOptions = React.useMemo(() => {
        const dropdownOptionsByLevel = {
            Agent: agents,
            Team: teams,
            Office: offices,
        };
        return dropdownOptionsByLevel[currentLevel];
    }, [currentLevel, agents, offices, teams]);
    const selectedAgentInfo = React.useMemo(() => {
        if (users == null || selectedSubject.trim() === "") {
            return null;
        }
        const info = users.find((user) => {
            return `${user.first_name} ${user.last_name}`.toLowerCase().trim() === selectedSubject.toLowerCase().trim();
        });
        return info !== null && info !== void 0 ? info : null;
    }, [selectedSubject, users]);
    const selectedAgentOffice = React.useMemo(() => {
        var _a;
        return selectedAgentInfo ? (_a = selectedAgentInfo.office) === null || _a === void 0 ? void 0 : _a.name : "";
    }, [selectedAgentInfo]);
    const handleResize = React.useCallback(() => {
        setWidth(window.innerWidth);
    }, [setWidth]);
    window.addEventListener("resize", handleResize);
    const dashScoreDataAbortController = React.useRef(null);
    const getDashScoreData = React.useCallback(() => {
        var _a;
        (_a = dashScoreDataAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        dashScoreDataAbortController.current = new AbortController();
        getDashscoreData()
            .then((result) => {
            setDashScoreData(result.data);
        })
            .catch((error) => {
            if (error.name !== "AbortError") {
                showErrorDialog(error);
            }
        });
    }, [setDashScoreData, showErrorDialog]);
    React.useEffect(() => {
        if (initalSpreadsheetId) {
            setSpreadsheetId(initalSpreadsheetId);
            getDashScoreData();
        }
        return () => {
            var _a;
            (_a = dashScoreDataAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initalSpreadsheetId]);
    React.useEffect(() => {
        var _a, _b;
        if (agents && !selectedSubject) {
            const firstAgent = agents[0];
            setSelectedSubject(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (_b = (_a = agents.find((name) => {
                var _a;
                return name.toLowerCase().trim() === ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.name.toLowerCase().trim());
            })) !== null && _a !== void 0 ? _a : firstAgent) !== null && _b !== void 0 ? _b : "");
        }
    }, [agents, selectedSubject, (_p = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _p === void 0 ? void 0 : _p.name]);
    const selectedSubjectAggregateData = React.useMemo(() => {
        if (!dashScoreData || !selectedSubject) {
            return null;
        }
        return dashScoreData.subject_aggregates.find((item) => {
            return item.name === selectedSubject;
        });
    }, [dashScoreData, selectedSubject]);
    const subjectDashScoreHistory = React.useMemo(() => {
        if (!dashScoreHistory || !selectedSubject) {
            return null;
        }
        return dashScoreHistory.filter((item) => {
            return item.name.toLowerCase().trim() === selectedSubject.toLowerCase().trim();
        });
    }, [dashScoreHistory, selectedSubject]);
    const subjectRolling30DashScore = React.useMemo(() => {
        if (!dashScoreHistory || !selectedSubject) {
            return null;
        }
        if (!subjectDashScoreHistory || subjectDashScoreHistory.length === 0) {
            return null;
        }
        const agentTotalDashScore = subjectDashScoreHistory
            .map((item) => {
            return item.score;
        })
            .reduce((acc, curr) => {
            return acc + curr;
        });
        return subjectDashScoreHistory.length > 0 ? agentTotalDashScore / subjectDashScoreHistory.length : null;
    }, [dashScoreHistory, selectedSubject, subjectDashScoreHistory]);
    const onSpreadsheetIdChange = React.useCallback((val) => {
        const id = val || null;
        if (id !== spreadsheetId) {
            setSpreadsheetId(id, () => {
                if (id === null || id === void 0 ? void 0 : id.trim()) {
                    setDashscoreSpreadsheetId({
                        spreadsheet_id: id.trim(),
                    })
                        .then((_) => {
                        getDashScoreData();
                    })
                        .catch((error) => {
                        if (error.name !== "AbortError") {
                            showErrorDialog(error);
                        }
                    });
                }
            });
        }
    }, [setSpreadsheetId, spreadsheetId, getDashScoreData, showErrorDialog]);
    /** used when an Agent, Team, or Office is selected through the dropdown */
    const onSubjectDropdownChange = React.useCallback((e) => {
        const agent = e.target.value;
        setSelectedSubject(agent);
    }, [setSelectedSubject]);
    /** used when an Agent, Team, or Office is selected through the sidebar */
    const onSelectedSubjectChange = React.useCallback((name) => {
        setSelectedSubject(name);
    }, [setSelectedSubject]);
    const toggleSidebar = React.useCallback(() => {
        setIsSidebarOpen((prevState) => {
            return !prevState;
        });
    }, [setIsSidebarOpen]);
    const changeLevel = React.useCallback((level) => {
        var _a, _b;
        const viewingAsUserName = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.name;
        const viewingAsAgent = (_b = (agents !== null && agents !== void 0 ? agents : []).find((name) => {
            return name.toLowerCase().trim() === (viewingAsUserName === null || viewingAsUserName === void 0 ? void 0 : viewingAsUserName.toLowerCase().trim());
        })) !== null && _b !== void 0 ? _b : null;
        let newSelectedSubject = null;
        if (level === "Agent" && agents) {
            newSelectedSubject = viewingAsAgent !== null && viewingAsAgent !== void 0 ? viewingAsAgent : agents[0];
        }
        else if (level === "Team" && teams) {
            newSelectedSubject = teams[0];
        }
        else if (level === "Office" && offices) {
            newSelectedSubject = offices[0];
        }
        setCurrentLevel(level);
        setSelectedSubject(newSelectedSubject !== null && newSelectedSubject !== void 0 ? newSelectedSubject : "");
    }, [(_q = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _q === void 0 ? void 0 : _q.name, agents, teams, offices]);
    const updateScorecardTabDescriptions = React.useCallback((tabDescription) => {
        queryClient.setQueryData(listTabDescriptionsApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.findIndex((x) => x.tab_name === tabDescription.tab_name);
            return Object.assign(Object.assign({}, old), { data: [...old.data.slice(0, indx), tabDescription, ...old.data.slice(indx + 1, old.data.length)] });
        });
    }, [queryClient, listTabDescriptionsApi.queryKey]);
    const changeTab = React.useCallback((_, tabName) => {
        setCurrentTab(tabName);
    }, []);
    console.log("data:", dashScoreData);
    // console.log("buyer leads:", dashScoreBuyerLeadsData);
    console.log("history:", dashScoreHistory);
    return (React.createElement(Grid2, { container: true, style: { height: "100%" } },
        React.createElement(Grid2, { size: 12 },
            React.createElement(Box, { display: "flex", p: 2 },
                React.createElement(Box, { display: "flex", flex: "1 1 auto" },
                    isAdmin && (React.createElement(Button, { href: "/reporting/dashscore/settings", style: { marginRight: "8px" } }, "Settings")),
                    React.createElement(Tabs, { value: currentTab, indicatorColor: "primary", onChange: changeTab, style: { paddingRight: "8px" } }, DASHSCORE_TABS.map((tab) => {
                        return React.createElement(Tab, { key: tab, label: tab, value: tab });
                    }))),
                isAdmin && (React.createElement(CoreTextField, { size: "small", fullWidth: true, value: spreadsheetId !== null && spreadsheetId !== void 0 ? spreadsheetId : "", onTextChange: onSpreadsheetIdChange, 
                    // @ts-expect-error: no overload matches this call
                    variant: "outlined", slotProps: {
                        input: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Spreadsheet ID"),
                        },
                    }, style: { marginTop: "auto", marginBottom: "auto" } })))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        (dashScoreData === null || dashScoreData === void 0 ? void 0 : dashScoreData.errors) && dashScoreData.errors.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, { size: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(Typography, null, "The following errors occurred:")),
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(List, { dense: true }, dashScoreData.errors.map((error) => (React.createElement(ListItem, { key: error },
                                React.createElement(ListItemText, { primary: error }))))))))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Divider, null)))),
        spreadsheetId && !dashScoreData && (React.createElement(Box, { display: "flex", mb: "auto", ml: "auto", mr: "auto", pt: "8px" },
            React.createElement(CoreLoading, null))),
        React.createElement(Grid2, { size: 12, style: { display: "flex", height: "calc(100% - 60px)" } },
            currentTab === "Leaderboard" && teams && offices && dashScoreData && settings && agents && users && (React.createElement(DashScoreLeaderboard, { data: dashScoreData, currentLevel: currentLevel, agents: agents, offices: offices, teams: teams, users: users, settings: settings, isSmallWindow: isSmallWindow, onLevelChange: changeLevel })),
            currentTab === "Scorecard" && (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", p: 2, flex: "1 1 auto", width: isSmallWindow && isSidebarOpen ? `calc(100% - ${SIDEBAR_WIDTH + 40}px)` : undefined }, agents && scorecardTabNames && scorecardTabDescriptions && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { container: true, style: { maxWidth: isSmallWindow ? "380px" : "600px", overflow: "hidden auto", marginLeft: "auto" } },
                        isAdmin && isSmallWindow && (React.createElement(React.Fragment, null,
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: isSidebarOpen, color: "primary", onChange: toggleSidebar }), label: `${sidebarToggleText} ${currentLevel}s` })),
                            React.createElement(Box, { overflow: "hidden auto" }, dashScoreData && teams && offices && settings && (React.createElement(DashScoreSidebar, { dashScores: dashScoreData.subject_aggregates, currentLevel: currentLevel, agents: agents, teams: teams, offices: offices, settings: settings, isOpen: isSidebarOpen, isSmallWindow: isSmallWindow, selectAgent: onSelectedSubjectChange }))))),
                        React.createElement(Grid2, { container: true, spacing: 2, size: 12 },
                            React.createElement(Grid2, { container: true, size: 12, style: { maxWidth: isSmallWindow ? "375px" : undefined } },
                                React.createElement(DashScoreLevels, { currentLevel: currentLevel, onLevelChange: changeLevel, style: { width: "100%" } })),
                            React.createElement(Grid2, { size: 12, style: { maxWidth: isSmallWindow ? "375px" : undefined } },
                                React.createElement(Box, { display: "flex", width: "100%", maxWidth: "520px" },
                                    React.createElement(TextField, { size: "small", select: true, fullWidth: true, slotProps: {
                                            input: {
                                                startAdornment: (React.createElement(InputAdornment, { position: "start" }, `Select ${currentLevel
                                                    .toLowerCase()
                                                    .trim()}`)),
                                            },
                                        }, value: selectedSubject !== null && selectedSubject !== void 0 ? selectedSubject : "", onChange: onSubjectDropdownChange }, (dropdownOptions !== null && dropdownOptions !== void 0 ? dropdownOptions : []).map((item) => (React.createElement(MenuItem, { key: item, value: item }, item)))))),
                            selectedSubject && dashScoreData && settings && (React.createElement(React.Fragment, null,
                                currentLevel === "Agent" && (React.createElement(Grid2, { container: true, size: 12, style: { maxWidth: isSmallWindow ? "375px" : undefined } },
                                    React.createElement(Box, { display: "flex", width: "100%", maxWidth: "520px" },
                                        React.createElement(Typography, { style: {
                                                fontWeight: "bold",
                                                fontSize: "13pt",
                                                fontFamily: "Proxima Nova, sans-serif",
                                                paddingRight: "8px",
                                            } }, "Team"),
                                        React.createElement(Typography, { style: {
                                                fontWeight: "bold",
                                                fontSize: "13pt",
                                                fontFamily: "Proxima Nova, sans-serif",
                                                background: "#eee",
                                                borderRadius: "10px",
                                                paddingRight: "4px",
                                                paddingLeft: "4px",
                                                flex: "1 1 auto",
                                            } }, selectedAgentInfo && selectedAgentInfo.team
                                            ? `${selectedAgentInfo.team.name} Team`
                                            : ""),
                                        React.createElement(Typography, { style: {
                                                fontWeight: "bold",
                                                fontSize: "13pt",
                                                fontFamily: "Proxima Nova, sans-serif",
                                                paddingLeft: "4px",
                                                paddingRight: "4px",
                                            } }, "Office"),
                                        React.createElement(Typography, { style: {
                                                fontWeight: "bold",
                                                fontSize: "13pt",
                                                fontFamily: "Proxima Nova, sans-serif",
                                                background: "#eee",
                                                borderRadius: "10px",
                                                width: width <= 400 ? "80px" : "100px",
                                                paddingRight: "4px",
                                                paddingLeft: "4px",
                                            } }, selectedAgentOffice)))),
                                isSmallWindow && selectedSubjectAggregateData && (React.createElement(Grid2, { container: true, style: {
                                        maxWidth: "240px",
                                        height: "160px",
                                        paddingTop: "8px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        paddingBottom: "8px",
                                    } },
                                    React.createElement(DASHscorePayout, { units: (_r = selectedSubjectAggregateData.flex_rolling_12_contracts) !== null && _r !== void 0 ? _r : null, payoutRate: (_s = selectedSubjectAggregateData.payout) !== null && _s !== void 0 ? _s : null, rolling30Score: subjectRolling30DashScore, scoreRedVal: settings.dashscore_red_value, scoreGreenVal: settings.dashscore_green_value, scoreHistory: subjectDashScoreHistory }))),
                                React.createElement(Grid2, { size: 12 },
                                    React.createElement(AgentScorecard, { agent: selectedSubject, agentOffice: selectedAgentOffice !== null && selectedAgentOffice !== void 0 ? selectedAgentOffice : null, dashScoreData: dashScoreData, tabs: scorecardTabNames, tabDescriptions: scorecardTabDescriptions, settings: settings, isSmallWindow: isSmallWindow, updateTabDescriptions: updateScorecardTabDescriptions })))))),
                    !isSmallWindow && selectedSubjectAggregateData && (React.createElement(Grid2, { container: true, style: {
                            maxWidth: "240px",
                            height: "200px",
                            marginRight: "auto",
                            marginBottom: "auto",
                            marginTop: "auto",
                            marginLeft: "12px",
                        } },
                        React.createElement(DASHscorePayout, { units: (_t = selectedSubjectAggregateData.flex_rolling_12_contracts) !== null && _t !== void 0 ? _t : null, payoutRate: (_u = selectedSubjectAggregateData.payout) !== null && _u !== void 0 ? _u : null, rolling30Score: subjectRolling30DashScore, scoreRedVal: (_v = settings === null || settings === void 0 ? void 0 : settings.dashscore_red_value) !== null && _v !== void 0 ? _v : null, scoreGreenVal: (_w = settings === null || settings === void 0 ? void 0 : settings.dashscore_green_value) !== null && _w !== void 0 ? _w : null, scoreHistory: subjectDashScoreHistory })))))),
                isAdmin && !isSmallWindow && currentTab === "Scorecard" && (React.createElement(Box, { display: "flex", p: 2 },
                    React.createElement(Box, { display: "flex", height: "30px" },
                        React.createElement(Tooltip, { title: `${sidebarToggleText} Sidebar` },
                            React.createElement(IconButton, { size: "small", onClick: toggleSidebar }, isSidebarOpen ? React.createElement(ArrowRight, null) : React.createElement(ArrowLeft, null)))),
                    React.createElement(Box, { overflow: "hidden auto" }, dashScoreData && agents && teams && offices && settings && (React.createElement(DashScoreSidebar, { dashScores: dashScoreData.subject_aggregates, currentLevel: currentLevel, agents: agents, teams: teams, offices: offices, settings: settings, isOpen: isSidebarOpen, isSmallWindow: isSmallWindow, selectAgent: onSelectedSubjectChange }))))))))));
};
export default DashScore;
