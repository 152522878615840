import * as React from "react";
import { Grid, Chip, Typography } from "@mui/material";
import fontColorContrast from "font-color-contrast";
import { useListStages } from "@app/orval/api/stages";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const PipelineAndStageText = (props) => {
    var _a, _b, _c, _d, _e;
    const { pipeline, stageName, stageUid } = props;
    const listStagesApi = useListStages({}, INFINITE_CACHE_PARAMS);
    const stages = (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const stage = stages === null || stages === void 0 ? void 0 : stages.find((x) => x.uid === stageUid);
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, pipeline)),
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Chip, { size: "small", label: (_d = (_c = stage === null || stage === void 0 ? void 0 : stage.name) !== null && _c !== void 0 ? _c : stageName) !== null && _d !== void 0 ? _d : "unknown stage", sx: {
                    height: "12px",
                    background: (_e = stage === null || stage === void 0 ? void 0 : stage.color) !== null && _e !== void 0 ? _e : undefined,
                    color: (stage === null || stage === void 0 ? void 0 : stage.color) ? fontColorContrast(stage.color, 0.7) : undefined,
                } }))));
};
export default PipelineAndStageText;
