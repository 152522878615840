import * as React from "react";
import { Save } from "@mui/icons-material";
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useListMailschedule4Settings, useSaveMailschedule4Settings } from "@app/orval/api/mail-schedule-4-settings";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const MailScheduleSettings = () => {
    var _a, _b;
    const [formData, setFormData] = React.useState(null);
    const errorDialog = useErrorDialog();
    const listSettingsApi = useListMailschedule4Settings(REFETCH_CACHE_PARAMS);
    const settings = (_b = (_a = listSettingsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const saveSettingsApi = useSaveMailschedule4Settings();
    React.useEffect(() => {
        if (settings) {
            setFormData(settings);
        }
    }, [settings]);
    const setAutomaticEnabled = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_automatic_enabled: checked })));
    }, [setFormData]);
    const setManualEnabled = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_manual_enabled: checked })));
    }, [setFormData]);
    const setNotificationsEnabled = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_notifications_enabled: checked })));
    }, [setFormData]);
    const setEntityTypeEnabled = React.useCallback((entityType) => (_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { entity_type_settings: prev.entity_type_settings.map((item) => {
                if (item.entity_type === entityType) {
                    return Object.assign(Object.assign({}, item), { is_enabled: checked });
                }
                return item;
            }) })));
    }, [setFormData]);
    const save = React.useCallback(() => {
        saveSettingsApi
            .mutateAsync({ data: formData })
            .then((result) => {
            console.log({ result });
            enqueueSnackbar("Settings saved", { variant: "success" });
        })
            .catch(errorDialog);
    }, [saveSettingsApi, formData, errorDialog]);
    const isSaving = saveSettingsApi.isPending;
    const loading = [
        {
            label: "Loading settings",
            queryResult: listSettingsApi,
        },
    ];
    const showInfiniteSpinner = settings == null || formData == null;
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: formData.is_automatic_enabled, color: "primary", onChange: setAutomaticEnabled }), label: "Automatic Mail Schedule Enabled" })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: formData.is_manual_enabled, color: "primary", onChange: setManualEnabled }), label: "Manual Mail Schedule Enabled" })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: formData.is_notifications_enabled, color: "primary", onChange: setNotificationsEnabled }), label: "Triggered Templates Enabled" }))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, null)),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, null, "Pipelines")),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { pl: 4 },
                    React.createElement(Grid, { container: true, spacing: 1 }, formData.entity_type_settings
                        .sort((a, b) => {
                        const aName = a.entity_type.replace("Listing", "Seller Contract");
                        const bName = b.entity_type.replace("Listing", "Seller Contract");
                        if (aName < bName)
                            return -1;
                        if (aName > bName)
                            return 1;
                        return 0;
                    })
                        .map((item) => {
                        return (React.createElement(Grid, { key: item.entity_type, item: true, xs: 12 },
                            React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: item.is_enabled, color: "primary", onChange: setEntityTypeEnabled(item.entity_type) }), label: item.entity_type.replace("Listing", "Seller Contract") })));
                    })))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, null)),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Button, { color: "primary", variant: "contained", startIcon: React.createElement(Save, null), onClick: save, disabled: isSaving }, "Save")))));
};
export default MailScheduleSettings;
