/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List Offers
 */
export const listPspOffers2 = (params, options) => {
    return axios.get(`/api/offers`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspOffers2QueryKey = (params) => {
    return [`/api/offers`, ...(params ? [params] : [])];
};
export const getListPspOffers2QueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspOffers2QueryKey(params);
    const queryFn = ({ signal }) => listPspOffers2(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Offers
 */
export function useListPspOffers2(params, options) {
    const queryOptions = getListPspOffers2QueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create an Offer
 */
export const createPspOffer = (offerCreateRequest, options) => {
    return axios.post(`/api/offers`, offerCreateRequest, options);
};
export const getCreatePspOfferMutationOptions = (options) => {
    const mutationKey = ['createPspOffer'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPspOffer(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create an Offer
*/
export const useCreatePspOffer = (options) => {
    const mutationOptions = getCreatePspOfferMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get an Offer
*/
export const getPspOffer = (uid, options) => {
    return axios.get(`/api/offers/${uid}`, options);
};
export const getGetPspOfferQueryKey = (uid) => {
    return [`/api/offers/${uid}`];
};
export const getGetPspOfferQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspOfferQueryKey(uid);
    const queryFn = ({ signal }) => getPspOffer(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get an Offer
 */
export function useGetPspOffer(uid, options) {
    const queryOptions = getGetPspOfferQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update an Offer
 */
export const updatePspOffer = (uid, offerUpdateRequest, options) => {
    return axios.post(`/api/offers/${uid}`, offerUpdateRequest, options);
};
export const getUpdatePspOfferMutationOptions = (options) => {
    const mutationKey = ['updatePspOffer'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updatePspOffer(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an Offer
*/
export const useUpdatePspOffer = (options) => {
    const mutationOptions = getUpdatePspOfferMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete an Offer
*/
export const deletePspOffer = (uid, options) => {
    return axios.delete(`/api/offers/${uid}`, options);
};
export const getDeletePspOfferMutationOptions = (options) => {
    const mutationKey = ['deletePspOffer'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspOffer(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete an Offer
*/
export const useDeletePspOffer = (options) => {
    const mutationOptions = getDeletePspOfferMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count Offers
*/
export const countPspOffers = (params, options) => {
    return axios.get(`/api/offers/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountPspOffersQueryKey = (params) => {
    return [`/api/offers/count`, ...(params ? [params] : [])];
};
export const getCountPspOffersQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountPspOffersQueryKey(params);
    const queryFn = ({ signal }) => countPspOffers(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count Offers
 */
export function useCountPspOffers(params, options) {
    const queryOptions = getCountPspOffersQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download Offers
 */
export const downloadPspOffers = (params, options) => {
    return axios.get(`/api/offers/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadPspOffersQueryKey = (params) => {
    return [`/api/offers/download`, ...(params ? [params] : [])];
};
export const getDownloadPspOffersQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadPspOffersQueryKey(params);
    const queryFn = ({ signal }) => downloadPspOffers(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download Offers
 */
export function useDownloadPspOffers(params, options) {
    const queryOptions = getDownloadPspOffersQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Reorder Offers
 */
export const reorderPspOffers = (multipleUIDRequest, options) => {
    return axios.post(`/api/offers/reorder`, multipleUIDRequest, options);
};
export const getReorderPspOffersMutationOptions = (options) => {
    const mutationKey = ['reorderPspOffers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderPspOffers(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder Offers
*/
export const useReorderPspOffers = (options) => {
    const mutationOptions = getReorderPspOffersMutationOptions(options);
    return useMutation(mutationOptions);
};
