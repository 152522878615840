import * as React from "react";
import { Warning } from "@mui/icons-material";
import { Avatar, Box, Divider, Grid2, Hidden, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useLocation, useNavigate } from "react-router";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useFileUploads from "@app/hooks/useFileUploads";
import useStateCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useAddComment, useDeleteComment, useUpdateComment, listComments } from "@app/orval/api/comments";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListFieldSections } from "@app/orval/api/field-sections";
import { searchFiles, useDeleteFile } from "@app/orval/api/files";
import { listNewsfeedFieldValueUpdates, listNewsfeedUserNoteChanges, listNewsfeedUserStageChanges, } from "@app/orval/api/newsfeed";
import { searchNotifications } from "@app/orval/api/notifications";
import { listTasks, useAddTask, useDeleteTask, useUpdateTask } from "@app/orval/api/tasks";
import { useAddCollaboratorForUser, useDeleteUser, useGetUser, useListUsers, useRemoveCollaboratorForUser, useUpdateCollaboratorForUser, useUpdateUser, } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { getInitialsMemoized } from "@app/util/Utils";
import DuplicateFieldAlert from "../DuplicateFieldAlert";
import useStages from "../hooks/useStages";
import { groupBy } from "../utils/utils";
import AgentStatuses from "./components/agent-statuses/AgentStatuses";
import GoogleGroupAccess from "./components/google-groups/GoogleGroupAccess";
import GoogleGroupsMissingEmail from "./components/google-groups/GoogleGroupsMissingEmail";
import Trainings from "./components/graduation-requirements/GraduationRequirements";
import OfficeSelect from "./components/office-select/OfficeSelect";
import PSPCertification from "./components/psp-certification/PSPCertification";
import RecentActivity from "./components/recent-activity/RecentActivity";
import RoleSelect from "./components/role-select/RoleSelect";
import Shadowings from "./components/shadowings/Shadowings";
import SlackChannels from "./components/slack/SlackChannels";
import SlackChannelsMissingSlackID from "./components/slack/SlackChannelsMissingSlackID";
import TeamSelect from "./components/team-select/TeamSelect";
import TerminateUserDialog from "./components/TerminateUserDialog";
import TwilioAccess from "./components/twilio/TwilioAccess";
import VCard from "./components/vcard/VCard";
import UsersActionsCell from "./UserActionsMenu";
import useTeamAdminPermissions from "./useTeamAdminPermissions";
import useValidation from "./useValidation";
import { FIELDS_THAT_SHOULD_BE_UNIQUE_FOR_EACH_USER } from "./utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import SectionHeader from "../buyer-leads/components/SectionHeader";
import Collaborators from "../components/box-view/actions/Collaborators";
import Chronology from "../components/box-view/Chronology/Chronology";
import DataEntry from "../components/box-view/DataEntry";
import DataEntrySkeleton from "../components/box-view/DataEntrySkeleton";
import Header from "../components/box-view/Header";
import ValidationErrors from "../components/box-view/ValidationErrors";
import ViewMembership from "../components/box-view/ViewMembership";
import Listings from "../recruiting/components/Listings";
const ALLOWED_VISIBILITY_OPTIONS = ["Comments", "History", "Files"];
const ALLOWED_VISIBILITY_OPTIONS__WRITEABLE = [...ALLOWED_VISIBILITY_OPTIONS];
const UsersForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    const { uid } = props;
    const tab = (_a = props.tab) !== null && _a !== void 0 ? _a : "data";
    // STATE
    const [search, setSearch] = useStateCallback("");
    const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = useStateCallback(new Set());
    const [isTerminatedDialogOpen, setIsTerminatedDialogOpen] = useStateCallback(false);
    const [shouldShowWarnings, setShowWarnings] = useStateCallback(true);
    // HOOKS
    const confirm = useConfirm();
    const errorDialog = useErrorDialog();
    const location = useLocation();
    const updateSearch = useUpdateSearch({ navigateOptions: { state: location.state } });
    const navigate = useNavigate();
    const { setUserStage, userStages: stages } = useStages();
    const listFieldsApi = useListFields({ entity_type: "User" }, REFETCH_CACHE_PARAMS);
    const fields = (_b = listFieldsApi.data) === null || _b === void 0 ? void 0 : _b.data;
    const fieldsByName = React.useMemo(() => {
        return fields === null || fields === void 0 ? void 0 : fields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [fields]);
    const stagesByUid = React.useMemo(() => {
        return stages ? groupBy(stages, "uid") : null;
    }, [stages]);
    const listSectionsApi = useListFieldSections({ entity_type: "User" }, REFETCH_CACHE_PARAMS);
    const sections = (_d = (_c = listSectionsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : [];
    const getUserApi = useGetUser(uid, { query: { gcTime: Infinity } });
    const updateUserApi = useUpdateUser();
    const user = ((_f = (_e = getUserApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null);
    const { canManage, effectiveUser } = useTeamAdminPermissions([uid]);
    const canEditOffice = React.useMemo(() => {
        return effectiveUser === null || effectiveUser === void 0 ? void 0 : effectiveUser.hasRole(["Superadmin", "Admin"]);
    }, [effectiveUser]);
    const canEditRole = canEditOffice;
    const canEditTeam = canEditOffice;
    const canManageAgentStatuses = canEditOffice;
    const canManageTwilioDistributions = canEditOffice;
    const canManageSlackDistributions = canEditOffice;
    const canManageGoogleGroupDistributions = canEditOffice;
    const listUsersApi = useListUsers({}, REFETCH_CACHE_PARAMS);
    const users = (_h = (_g = listUsersApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const usersWithMatchingFields = React.useMemo(() => {
        var _a;
        if (!user) {
            return {};
        }
        const res = {};
        for (const item of FIELDS_THAT_SHOULD_BE_UNIQUE_FOR_EACH_USER) {
            const { fieldName } = item;
            const postprocessor = (_a = item.postprocessor) !== null && _a !== void 0 ? _a : ((val) => {
                return val != null ? String(val).toLowerCase().trim() : null;
            });
            for (const user_ of users !== null && users !== void 0 ? users : []) {
                if (user.uid !== user_.uid &&
                    user.fields[fieldName] &&
                    String(user.fields[fieldName]).trim() &&
                    postprocessor(user.fields[fieldName]) === postprocessor(user_.fields[fieldName])) {
                    if (!res[user_.uid]) {
                        res[user_.uid] = [];
                    }
                    res[user_.uid].push(String(fieldName));
                }
            }
        }
        return res;
    }, [user, users]);
    const deleteUserApi = useDeleteUser();
    const listCommentsApi = usePostQuery(listComments, { user_uids: [uid], search }, { query: { gcTime: Infinity } });
    const comments = (_k = (_j = listCommentsApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const searchNotificationsApi = usePostQuery(searchNotifications, { entity_uids: [uid] }, {});
    const notifications = (_m = (_l = searchNotificationsApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const addCommentApi = useAddComment();
    const updateCommentApi = useUpdateComment();
    const deleteCommentApi = useDeleteComment();
    const listTasksApi = usePostQuery(listTasks, { user_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const tasks = (_p = (_o = listTasksApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const addTaskApi = useAddTask();
    const updateTaskApi = useUpdateTask();
    const deleteTaskApi = useDeleteTask();
    const fieldValueChangesApi = usePostQuery(listNewsfeedFieldValueUpdates, { user_uids: [uid] }, { query: { gcTime: Infinity } });
    const fieldValueChanges = (_r = (_q = fieldValueChangesApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const { uploadFile } = useFileUploads();
    const stageChangesApi = usePostQuery(listNewsfeedUserStageChanges, { profile_uids: [uid], search }, { query: { gcTime: Infinity } });
    const stageChanges = (_t = (_s = stageChangesApi.data) === null || _s === void 0 ? void 0 : _s.data) !== null && _t !== void 0 ? _t : null;
    const noteChangesApi = usePostQuery(listNewsfeedUserNoteChanges, { profile_uids: [uid], search }, { query: { gcTime: Infinity } });
    const noteChanges = (_v = (_u = noteChangesApi.data) === null || _u === void 0 ? void 0 : _u.data) !== null && _v !== void 0 ? _v : null;
    const listFilesApi = usePostQuery(searchFiles, { user_uids: [uid], search }, { query: { gcTime: Infinity } });
    const files = (_x = (_w = listFilesApi.data) === null || _w === void 0 ? void 0 : _w.data) !== null && _x !== void 0 ? _x : null;
    const deleteFileApi = useDeleteFile();
    const queryClient = useQueryClient();
    // CALLBACKS
    const toggleWarnings = React.useCallback(() => {
        setShowWarnings((prev) => !prev);
    }, [setShowWarnings]);
    const onTabChanged = React.useCallback((_, value) => {
        updateSearch("tab", value);
    }, [updateSearch]);
    const onFilesSelectedForUpload = React.useCallback((fileList) => {
        const filesToUpload = Array.from(fileList);
        setCurrentlyUploadingFiles((prev) => new Set([...prev, ...filesToUpload.map((x) => x.name)]));
        for (const file of filesToUpload) {
            console.log({ file });
            uploadFile({
                userUid: uid,
                file,
            })
                .then((file_) => {
                setCurrentlyUploadingFiles((prev) => new Set([...prev].filter((fname) => fname !== file_.filename)));
                queryClient.setQueryData(listFilesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [...old.data, file_] });
                });
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [uploadFile, uid, queryClient, listFilesApi.queryKey, setCurrentlyUploadingFiles]);
    const onFileDeleted = React.useCallback((fileUid) => {
        queryClient.setQueryData(listFilesApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== fileUid) });
        });
        deleteFileApi.mutateAsync({ uid: fileUid }).then(console.log).catch(enqueueErrorSnackbar);
    }, [queryClient, listFilesApi.queryKey, deleteFileApi]);
    const doSearch = React.useCallback((searchString) => {
        setSearch(searchString);
    }, [setSearch]);
    const deleteUser = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "Deleting a user is only really appropriate if the user was created in error. In most circumstances, you should move the user to Terminated to remove their portal access. If you go through with deleting the user, you won't be able to undo this action, and if a new account is created for the user in the future, they won't have access to their previous data." })).then((result) => {
            if (result.confirmed) {
                confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { title: "Really?", description: "You really want to delete this user?\n\nThis is the last time I'll check." })).then((result) => {
                    if (result.confirmed) {
                        deleteUserApi
                            .mutateAsync({ uid })
                            .then(() => {
                            console.log("Deleted user");
                            navigate("/users");
                        })
                            .catch(errorDialog);
                    }
                });
            }
        });
    }, [confirm, deleteUserApi, errorDialog, navigate, uid]);
    const onFieldSaveBegin = React.useCallback((fieldName, fieldValue) => {
        queryClient.setQueryData(getUserApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), { [fieldName]: fieldValue }) }) });
        });
    }, [queryClient, getUserApi.queryKey]);
    const onFieldSaveComplete = React.useCallback((response) => {
        queryClient.setQueryData(getUserApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), Object.fromEntries(Object.entries(response.data.fields).filter(([name, _]) => {
                        var _a;
                        return (_a = fieldsByName === null || fieldsByName === void 0 ? void 0 : fieldsByName[name]) === null || _a === void 0 ? void 0 : _a.formula;
                    }))) }) });
        });
    }, [queryClient, getUserApi.queryKey, fieldsByName]);
    const onFieldSaveError = React.useCallback((error) => {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            confirm({
                title: "Unable to save field",
                description: String(error.response.data),
                hideCancelButton: true,
                confirmationText: "Ok",
            });
        }
        getUserApi.refetch();
    }, [confirm, getUserApi]);
    const onTerminateUser = React.useCallback(() => {
        setIsTerminatedDialogOpen(true);
    }, [setIsTerminatedDialogOpen]);
    const onBeforeStageUpdate = React.useCallback((_, stageUid) => {
        queryClient.setQueryData(getUserApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { stage: stagesByUid[stageUid] }) });
        });
    }, [queryClient, getUserApi.queryKey, stagesByUid]);
    const onStageUpdateError = React.useCallback((dataBeforeUpdate) => () => {
        queryClient.setQueryData(getUserApi.queryKey, dataBeforeUpdate);
    }, [queryClient, getUserApi.queryKey]);
    const onStageUpdateSuccess = React.useCallback((result) => {
        queryClient.setQueryData(getUserApi.queryKey, result);
    }, [queryClient, getUserApi.queryKey]);
    const onStageChange = React.useCallback((stageUid) => {
        const dataBeforeUpdate = getUserApi.data;
        if (dataBeforeUpdate) {
            setUserStage(uid, stageUid, onTerminateUser, onBeforeStageUpdate, onStageUpdateError(dataBeforeUpdate), onStageUpdateSuccess);
        }
        else {
            enqueueErrorSnackbar("User data not loaded. Please try again.");
        }
    }, [
        getUserApi.data,
        setUserStage,
        uid,
        onTerminateUser,
        onBeforeStageUpdate,
        onStageUpdateError,
        onStageUpdateSuccess,
    ]);
    const closeTerminatedDialog = React.useCallback(() => {
        setIsTerminatedDialogOpen(false);
    }, [setIsTerminatedDialogOpen]);
    const saveComment = React.useCallback((params) => {
        if (params.uid) {
            updateCommentApi
                .mutateAsync({ uid: params.uid, data: { content: params.content } })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.map((c) => (c.uid === params.uid ? result.data : c)) });
                });
            })
                .catch(errorDialog);
        }
        else {
            addCommentApi
                .mutateAsync({ data: { content: params.content, entity_uid: user.uid } })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: [result.data, ...old.data] });
                });
            })
                .catch(errorDialog);
        }
    }, [updateCommentApi, errorDialog, queryClient, listCommentsApi.queryKey, addCommentApi, user]);
    const deleteComment = React.useCallback((commentUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== commentUid) });
                });
                deleteCommentApi
                    .mutateAsync({ uid: commentUid })
                    .then(() => {
                    console.log("Delete comment");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, queryClient, listCommentsApi.queryKey, deleteCommentApi, errorDialog]);
    const createTask = React.useCallback((task) => {
        addTaskApi
            .mutateAsync({
            data: Object.assign(Object.assign({}, task), { entity_uid: uid }),
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [addTaskApi, listTasksApi, queryClient, uid]);
    const updateTask = React.useCallback((taskUid, task) => {
        updateTaskApi
            .mutateAsync({
            uid: taskUid,
            data: task,
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((x) => (x.uid === taskUid ? result.data : x)) });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [listTasksApi, queryClient, updateTaskApi]);
    const deleteTask = React.useCallback((taskUid) => {
        queryClient.setQueryData(listTasksApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== taskUid) });
        });
        deleteTaskApi
            .mutateAsync({ uid: taskUid })
            .then(() => {
            console.log(`deleted task ${taskUid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [deleteTaskApi, listTasksApi, queryClient]);
    const setRoles = React.useCallback((roles) => {
        updateUserApi
            .mutateAsync({
            uid,
            data: {
                roles: roles,
            },
        })
            .then((result) => {
            queryClient.setQueryData(getUserApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: result.data });
            });
        })
            .catch(errorDialog);
    }, [updateUserApi, queryClient, errorDialog, getUserApi.queryKey, uid]);
    const setOffice = React.useCallback((office) => {
        var _a;
        updateUserApi
            .mutateAsync({
            uid,
            data: {
                office: (_a = office === null || office === void 0 ? void 0 : office.uid) !== null && _a !== void 0 ? _a : null,
            },
        })
            .then((result) => {
            queryClient.setQueryData(getUserApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: result.data });
            });
        })
            .catch(errorDialog);
    }, [updateUserApi, queryClient, errorDialog, getUserApi.queryKey, uid]);
    const setTeam = React.useCallback((team) => {
        updateUserApi
            .mutateAsync({
            uid,
            data: {
                // @ts-expect-error: string not assignable to type Role
                team,
            },
        })
            .then((result) => {
            queryClient.setQueryData(getUserApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: result.data });
            });
        })
            .catch(errorDialog);
    }, [updateUserApi, queryClient, errorDialog, getUserApi.queryKey, uid]);
    const addCollaboratorApi = useAddCollaboratorForUser();
    const updateCollaboratorApi = useUpdateCollaboratorForUser();
    const removeCollaboratorApi = useRemoveCollaboratorForUser();
    const addCollaborator = React.useCallback((params) => {
        addCollaboratorApi
            .mutateAsync({ profileUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getUserApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addCollaboratorApi, errorDialog, getUserApi.queryKey, queryClient, uid]);
    const updateCollaborator = React.useCallback((collaboratorUid, params) => {
        updateCollaboratorApi
            .mutateAsync({ profileUid: uid, uid: collaboratorUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getUserApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getUserApi.queryKey, queryClient, uid, updateCollaboratorApi]);
    const removeCollaborator = React.useCallback((collaboratorUid) => {
        queryClient.setQueryData(getUserApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { collaborators: old.data.collaborators.filter((a) => a.uid !== collaboratorUid) }) });
        });
        removeCollaboratorApi
            .mutateAsync({ uid: collaboratorUid, profileUid: uid })
            .then((result) => {
            queryClient.setQueryData(getUserApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getUserApi.queryKey, queryClient, removeCollaboratorApi, uid]);
    const error = (_y = (getUserApi.data ? null : getUserApi.error)) !== null && _y !== void 0 ? _y : listSectionsApi.error;
    const { fieldSectionsSaturated, fieldsSaturated, validationErrors } = useValidation({
        user,
        fields: fields !== null && fields !== void 0 ? fields : null,
        sections,
        teamMemberUids: [uid],
    });
    const updateUserState = React.useCallback((result) => {
        queryClient.setQueryData(getUserApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: result });
        });
    }, [getUserApi.queryKey, queryClient]);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!user || deleteUserApi.status === "pending" || !effectiveUser || canManage === null) {
        return React.createElement(CoreLoading, null);
    }
    if (user.uid !== effectiveUser.uid && !canManage) {
        return React.createElement(CoreError, { error: { message: "You do not have permission to manage this user profile." } });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, direction: "column", style: { background: "#eee", overflowY: "hidden", height: "100%" }, wrap: "nowrap" },
            validationErrors.length > 0 && (React.createElement(Grid2, null,
                React.createElement(ValidationErrors, { errors: validationErrors }))),
            React.createElement(Grid2, { style: { width: "100%", overflow: "hidden" } },
                React.createElement(Box, { pl: 2, pt: 0, pb: 0 },
                    React.createElement(Header, { moduleName: "Users", pipelineUrl: "/users", name: user.name, showCopyLinkButton: true, onDelete: deleteUser },
                        React.createElement(ViewMembership, { entityType: "User", entityUid: user.uid }),
                        Object.keys(usersWithMatchingFields).length ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Show/hide warnings") },
                            React.createElement(IconButton, { size: "small", onClick: toggleWarnings },
                                React.createElement(Warning, { fontSize: "small", color: shouldShowWarnings ? "warning" : undefined })))) : null,
                        React.createElement(UsersActionsCell, { user: user, onTwilioPhoneNumberPurchased: updateUserState, onTwilioPhoneNumberReleased: updateUserState })))),
            shouldShowWarnings && Object.keys(usersWithMatchingFields).length > 0 && (React.createElement(DuplicateFieldAlert, { duplicates: usersWithMatchingFields })),
            React.createElement(Grid2, { style: { overflow: "hidden", flex: 1 } },
                React.createElement(Grid2, { container: true, spacing: 1, style: { height: "100%" } },
                    React.createElement(Hidden, { lgDown: tab !== "data" },
                        React.createElement(Grid2, { size: { xs: 12, lg: 3 }, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                                React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                    React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll", width: "100%" }, direction: "column", wrap: "nowrap" },
                                        React.createElement(Grid2, { style: { width: "100%" } },
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(SectionHeader, { uid: user.uid, name: React.createElement(Avatar, { src: (_z = user.fields["Headshot"]) !== null && _z !== void 0 ? _z : undefined }, getInitialsMemoized((_0 = user.fields["First Name"]) !== null && _0 !== void 0 ? _0 : null, (_1 = user.fields["Last Name"]) !== null && _1 !== void 0 ? _1 : null) || "?"), stages: stages !== null && stages !== void 0 ? stages : undefined, stage: user.stage ? user.stage.uid : undefined, onStageChange: onStageChange, mailScheduleLinkUrl: canManage ? `/users/${user.uid}/mail-schedule` : undefined, disableStageSelect: !canManage }))),
                                        React.createElement(Grid2, null, fieldsSaturated ? (React.createElement(DataEntry, { apiUri: `/api/users/${user.uid}`, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, onFieldSaveError: onFieldSaveError, fields: fieldsSaturated, sections: fieldSectionsSaturated !== null && fieldSectionsSaturated !== void 0 ? fieldSectionsSaturated : [], values: user.fields, subordinateUserUidsToCheck: [user.uid] })) : (React.createElement(Box, { p: 2 },
                                            React.createElement(DataEntrySkeleton, null))))))))),
                    React.createElement(Hidden, { lgDown: tab !== "chronology" },
                        React.createElement(Grid2, { size: { xs: 12, lg: 6 }, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                                React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                    React.createElement(Box, { p: 2, style: { height: "100%" } },
                                        React.createElement(Chronology, { allowedVisibilityOptions: ALLOWED_VISIBILITY_OPTIONS__WRITEABLE, visibilityType: "User", updates: fieldValueChanges, stageChanges: stageChanges, noteChanges: noteChanges, comments: comments, notifications: notifications, tasks: tasks, files: files, currentlyUploadingFiles: currentlyUploadingFiles, onSearch: doSearch, onCommentSubmitted: saveComment, onCommentDeleted: deleteComment, onTaskCreated: createTask, onTaskUpdated: updateTask, onTaskDeleted: deleteTask, onFilesSelectedForUpload: onFilesSelectedForUpload, onFileDeleted: onFileDeleted, showToolbar: true, entityUid: user.uid })))))),
                    React.createElement(Hidden, { lgDown: tab !== "listings" },
                        React.createElement(Grid2, { size: { xs: 12, lg: 3 }, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                                React.createElement(Paper, { style: { height: "100%", overflowY: "scroll" } },
                                    React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll" }, direction: "column", wrap: "nowrap" },
                                        canManage && (React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(Collaborators, { collaborators: user.collaborators, onCreateCollaborator: addCollaborator, onUpdateCollaborator: updateCollaborator, onRemoveCollaborator: removeCollaborator })))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                                                    React.createElement(Grid2, null,
                                                        React.createElement(RoleSelect, { roles: user.roles, onChange: setRoles, canEdit: canEditRole })),
                                                    React.createElement(Grid2, null,
                                                        React.createElement(OfficeSelect, { office: (_2 = user.office) !== null && _2 !== void 0 ? _2 : null, onChange: setOffice, canEdit: canEditRole })),
                                                    React.createElement(Grid2, null,
                                                        React.createElement(TeamSelect, { team: (_3 = user.team) !== null && _3 !== void 0 ? _3 : null, onChange: setTeam, canEdit: canEditTeam }))))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(AgentStatuses, { user: user, canEdit: canManageAgentStatuses }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(Trainings, { user: user }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(Shadowings, { user: user }))),
                                        canManageSlackDistributions && (React.createElement(React.Fragment, null,
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2 }, user.fields["Slack ID"] ? (React.createElement(SlackChannels, { userUid: user.uid })) : (React.createElement(SlackChannelsMissingSlackID, null)))))),
                                        canManageTwilioDistributions && (React.createElement(React.Fragment, null,
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2 },
                                                    React.createElement(TwilioAccess, { userUid: user.uid, userTwilioPhoneNumber: user.fields["Twilio #"] }))))),
                                        canManageGoogleGroupDistributions && (React.createElement(React.Fragment, null,
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2 }, user.email ? React.createElement(GoogleGroupAccess, { userUid: user.uid }) : React.createElement(GoogleGroupsMissingEmail, null))))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(PSPCertification, { userUid: user.uid }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(VCard, { userUid: user.uid }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(RecentActivity, { userUid: user.uid }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(Divider, null)),
                                        React.createElement(Grid2, null,
                                            React.createElement(Box, { p: 2 },
                                                React.createElement(Listings, { mlsNumber: user.fields["Triangle MLS ID#"] })))))))))),
            React.createElement(Hidden, { lgUp: true },
                React.createElement(Grid2, { style: { background: "white" } },
                    React.createElement(Tabs, { value: tab, onChange: onTabChanged, variant: "fullWidth" },
                        React.createElement(Tab, { value: "data", label: "Data" }),
                        React.createElement(Tab, { value: "chronology", label: "Chronology" }),
                        React.createElement(Tab, { value: "listings", label: "Listings" }))))),
        isTerminatedDialogOpen && (React.createElement(TerminateUserDialog, { user: user, onCancel: closeTerminatedDialog, onComplete: closeTerminatedDialog }))));
};
export default UsersForm;
