var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Add, AutoAwesome, CopyAll, Link, QuestionAnswer, Send } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, TextField, Grid2, InputAdornment, Hidden, Typography, Avatar, Menu, MenuItem, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import CoreDialog from "@app/common/CoreDialog";
import useEmailDraftCreator from "@app/common/links/useEmailDraftCreator";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useSession from "@app/hooks/useSession";
import { downloadFileFromCloudStorage } from "@app/orval/api/google-cloud";
import { parseMailschedule4Template } from "@app/orval/api/mail-schedule-parsing";
import { useListNmsRequests } from "@app/orval/api/nms-requests";
import { useListPspRequests } from "@app/orval/api/psp-requests";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { base64ToFile, copyToClipboard, formatDate_Dayjs, getInitials, invalidateQueriesStartingWith, } from "@app/util/Utils";
import NMSRequestForm from "../../../components/box-view/nms-request-form/NMSRequestForm";
import PSPRequestForm from "../../../components/box-view/psp-request-form/PSPRequestForm";
import MLSProperty from "../../../seller-leads/components/MLSProperty";
import Showings from "../../../seller-leads/components/Showings";
import LinkPSPDialog from "../LinkPSPDialog";
import LinkQFODialog from "../LinkQFODialog";
import PSPs from "../PSPs";
import QFOs from "../QFOs";
const PropertyDialog = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { DialogProps, buyerLead, isAddingPSP, isAddingQFO, onAddShowing, onClose, onLinkPSP, onLinkQFO, onMarketAnalysisClicked, onRemoveShowing, onStartNewPSP, onStartNewQFO, onUnlinkPSP, onUnlinkQFO, onUpdate, onUpdateShowing, property, } = props;
    const session = useSession();
    const [formData, setFormData] = React.useState({ property_address: null });
    const [sendQFOAnchorEl, setSendQFOAnchorEl] = React.useState(null);
    const [isSendingQFO, setIsSendingQFO] = React.useState(false);
    const [blurredPropertyAddress, setBlurredPropertyAddress] = React.useState(property.property_address);
    const [isLinkPSPDialogOpen, setIsLinkPSPDialogOpen] = React.useState(false);
    const [isRequestPSPDialogOpen, setIsRequestPSPDialogOpen] = React.useState(false);
    const [isLinkQFODialogOpen, setIsLinkQFODialogOpen] = React.useState(false);
    const [isNMSRequestDialogOpen, setIsNMSRequestDialogOpen] = React.useState(false);
    const openSendQFOMenu = React.useCallback((e) => {
        setSendQFOAnchorEl(e.currentTarget);
    }, []);
    const closeSendQFOMenu = React.useCallback(() => {
        setSendQFOAnchorEl(null);
    }, []);
    const queryClient = useQueryClient();
    const draftCreator = useEmailDraftCreator();
    const listPSPRequestsApi = useListPspRequests({ buyer_property_uid: property.uid, is_claimed: false }, REFETCH_CACHE_PARAMS);
    const pspRequests = (_b = (_a = listPSPRequestsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listNMSRequestsApi = useListNmsRequests({ buyer_property_uid: property.uid }, REFETCH_CACHE_PARAMS);
    const nmsRequests = (_d = (_c = listNMSRequestsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    React.useEffect(() => {
        invalidateQueriesStartingWith(queryClient, listPSPRequestsApi.queryKey[0], [listPSPRequestsApi.queryKey]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listPSPRequestsApi.queryKey]);
    React.useEffect(() => {
        setFormData({
            property_address: property.property_address,
        });
    }, [property]);
    const onPropertyAddressChanged = React.useCallback((e) => {
        setFormData({
            property_address: e.target.value,
        });
    }, [setFormData]);
    const onPropertyAddressBlurred = React.useCallback((e) => {
        const val = e.target.value;
        setBlurredPropertyAddress(val);
        onUpdate({
            property_address: val,
        });
    }, [setBlurredPropertyAddress, onUpdate]);
    const openLinkPSPDialog = React.useCallback(() => {
        setIsLinkPSPDialogOpen(true);
    }, [setIsLinkPSPDialogOpen]);
    const openRequestPSPDialog = React.useCallback(() => {
        setIsRequestPSPDialogOpen(true);
    }, [setIsRequestPSPDialogOpen]);
    const openNMSRequestDialog = React.useCallback(() => {
        setIsNMSRequestDialogOpen(true);
    }, [setIsNMSRequestDialogOpen]);
    const openLinkQFODialog = React.useCallback(() => {
        setIsLinkQFODialogOpen(true);
    }, [setIsLinkQFODialogOpen]);
    const sendQFO = React.useCallback((templateUid) => () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        closeSendQFOMenu();
        setIsSendingQFO(true);
        const parsedTemplateResponse = yield parseMailschedule4Template({
            entity: {
                entity_type: "Buyer Lead",
                uid: buyerLead.uid,
            },
            template_uid: templateUid,
        });
        if (parsedTemplateResponse.status !== 200) {
            enqueueErrorSnackbar(parsedTemplateResponse.data);
            setIsSendingQFO(false);
            return;
        }
        const attachmentPromises = parsedTemplateResponse.data.gcp_attachments.map((attachment) => __awaiter(void 0, void 0, void 0, function* () {
            return downloadFileFromCloudStorage({
                blob_name: attachment.blob_name,
            });
        }));
        const downloadedAttachments = yield Promise.all(attachmentPromises);
        const attachmentFiles = yield Promise.all(downloadedAttachments.map((x) => base64ToFile(x.data.file, x.data.filename, x.data.mime_type)));
        try {
            yield draftCreator.createEmailDrafts({
                items: [
                    {
                        entityUid: buyerLead.uid,
                        entityType: "Buyer Lead",
                        templateUid, // QFO mail schedule template
                        template: {
                            email_draft_template: Object.assign(Object.assign({}, ((_a = parsedTemplateResponse.data.email_template) !== null && _a !== void 0 ? _a : parsedTemplateResponse.data.email_draft_template)), { body: ((_b = parsedTemplateResponse.data.email_template) !== null && _b !== void 0 ? _b : parsedTemplateResponse.data.email_draft_template).body.replaceAll("$'QFO Form Link'", `${window.location.protocol}//${window.location.host}/forms/qfo/form?property=${property.uid}`) }),
                        },
                        attachments: attachmentFiles,
                    },
                ],
            });
        }
        catch (e) {
            enqueueErrorSnackbar(e);
        }
        setIsSendingQFO(false);
    }), [buyerLead.uid, closeSendQFOMenu, draftCreator, property.uid]);
    const copyQFOLink = React.useCallback(() => {
        copyToClipboard(`${window.location.protocol}//${window.location.host}/forms/qfo/form?property=${property.uid}`);
        enqueueSnackbar("QFO link copied to clipboard", { variant: "success" });
    }, [property.uid]);
    const closeDialog = React.useCallback(() => {
        setIsNMSRequestDialogOpen(false);
        setIsLinkPSPDialogOpen(false);
        setIsRequestPSPDialogOpen(false);
        setIsLinkQFODialogOpen(false);
    }, [setIsLinkPSPDialogOpen, setIsRequestPSPDialogOpen, setIsLinkQFODialogOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CoreDialog, Object.assign({ maxWidth: "md", fullWidth: true }, DialogProps),
            React.createElement(DialogTitle, null, "Edit property"),
            React.createElement(DialogContent, null,
                React.createElement(Grid2, { container: true, spacing: 2 },
                    blurredPropertyAddress && blurredPropertyAddress.length > 4 && (React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid2, { size: 4, style: { textAlign: "center" } },
                            React.createElement(MLSProperty, { address: blurredPropertyAddress, showAddress: false })))),
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                            React.createElement(Grid2, null,
                                React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                        input: {
                                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Property Address"),
                                        },
                                    }, value: (_e = formData.property_address) !== null && _e !== void 0 ? _e : "", onChange: onPropertyAddressChanged, onBlur: onPropertyAddressBlurred })),
                            React.createElement(Grid2, { style: { marginTop: "2rem" } },
                                React.createElement(Showings, { showings: property.showings, onAddShowing: onAddShowing, onRemoveShowing: onRemoveShowing, onUpdateShowing: onUpdateShowing, CollapsibleSectionProps: {
                                        icon: null,
                                        isCollapsible: false,
                                    } })),
                            nmsRequests && nmsRequests.length > 0 && (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" }, nmsRequests
                                .sort((a, b) => {
                                if (a.requested_at > b.requested_at)
                                    return -1;
                                if (a.requested_at < b.requested_at)
                                    return 1;
                                return 0;
                            })
                                .map((x) => {
                                var _a;
                                return (React.createElement(Grid2, { key: x.uid, container: true, spacing: 1, direction: "column" },
                                    React.createElement(Grid2, { container: true, spacing: 1 },
                                        React.createElement(Grid2, null,
                                            React.createElement(QuestionAnswer, { fontSize: "small", style: { color: "#999" } })),
                                        React.createElement(Grid2, null,
                                            React.createElement(Typography, { variant: "body2" },
                                                "NMS Request submitted at ",
                                                formatDate_Dayjs(x.requested_at, "dddd, MMMM D, YYYY h:mm A")))),
                                    x.claimed_by && (React.createElement(Grid2, { container: true, spacing: 1 },
                                        React.createElement(Grid2, null,
                                            React.createElement(Avatar, { src: (_a = x.claimed_by.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { fontSize: "0.7rem" } }, getInitials(x.claimed_by.first_name, x.claimed_by.last_name))),
                                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Typography, { variant: "caption" },
                                                "Claimed by ",
                                                x.claimed_by.first_name,
                                                " ",
                                                x.claimed_by.last_name))))));
                            }))),
                            React.createElement(Grid2, null,
                                React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(QuestionAnswer, { fontSize: "small" }), onClick: openNMSRequestDialog }, "NMS Request Form")),
                            React.createElement(Grid2, { style: { marginTop: "2rem" } },
                                React.createElement(PSPs, { psps: property.psps, onRemovePSP: onUnlinkPSP, isCollapsible: false })),
                            pspRequests && pspRequests.length > 0 && (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" }, pspRequests
                                .sort((a, b) => {
                                if (a.requested_at > b.requested_at)
                                    return -1;
                                if (a.requested_at < b.requested_at)
                                    return 1;
                                return 0;
                            })
                                .map((x) => (React.createElement(Grid2, { key: x.uid, container: true, spacing: 1 },
                                React.createElement(Grid2, null,
                                    React.createElement(QuestionAnswer, { fontSize: "small", style: { color: "#999" } })),
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "body2" },
                                        "Requested a PSP/Offer at ",
                                        formatDate_Dayjs(x.requested_at, "dddd, MMMM D, YYYY h:mm A")))))))),
                            React.createElement(Grid2, null,
                                React.createElement(Grid2, { container: true, spacing: 2 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Add, { fontSize: "small" }), disabled: isAddingPSP || isAddingQFO, onClick: onStartNewPSP }, "Start new PSP")),
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Link, { fontSize: "small" }), disabled: isAddingPSP || isAddingQFO, onClick: openLinkPSPDialog }, "Link an existing PSP")),
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(QuestionAnswer, { fontSize: "small" }), onClick: openRequestPSPDialog }, "Request PSP/Offer")),
                                    ((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.isDeveloper) && (React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "contained", tabIndex: -1, startIcon: React.createElement(AutoAwesome, { fontSize: "small" }), disabled: isAddingPSP || isAddingQFO, onClick: onMarketAnalysisClicked }, "Market Analysis"))))),
                            React.createElement(Grid2, { style: { marginTop: "2rem" } },
                                React.createElement(QFOs, { qfos: property.qfos, onRemoveQFO: onUnlinkQFO, isCollapsible: false })),
                            React.createElement(Grid2, null,
                                React.createElement(Grid2, { container: true, spacing: 2 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Add, { fontSize: "small" }), disabled: isAddingPSP || isAddingQFO, onClick: onStartNewQFO }, "Create a QFO")),
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Link, { fontSize: "small" }), disabled: isAddingPSP || isAddingQFO, onClick: openLinkQFODialog }, "Link an existing QFO")),
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(Send, { fontSize: "small" }), disabled: isAddingPSP || isAddingQFO || isSendingQFO, onClick: openSendQFOMenu }, "Send QFO to lead..."),
                                        React.createElement(Menu, { anchorEl: sendQFOAnchorEl, open: Boolean(sendQFOAnchorEl), onClose: closeSendQFOMenu }, [
                                            {
                                                name: "QFO | RDU",
                                                uid: "38dd4eddd9844bec9a5b9a62ffeb9128",
                                            },
                                            {
                                                name: "QFO | AVL",
                                                uid: "97821e4e3bf746e398628f825c0fb94a",
                                            },
                                            {
                                                name: "QFO | FAY",
                                                uid: "874027a10f454165817f430705e4772a",
                                            },
                                            {
                                                name: "QFO | CLT",
                                                uid: "7ce79ee637c647f3b9e667554d367f74",
                                            },
                                        ].map((template) => (React.createElement(MenuItem, { key: template.uid, onClick: sendQFO(template.uid) }, template.name))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { size: "small", variant: "outlined", tabIndex: -1, startIcon: React.createElement(CopyAll, { fontSize: "small" }), onClick: copyQFOLink }, "Copy QFO link")))))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Done"))),
        isLinkPSPDialogOpen && (React.createElement(LinkPSPDialog, { onLinkPSP: onLinkPSP, onClose: closeDialog, DialogProps: { open: true }, autocompleteFilter: { exclude_uids: property.psps.map((p) => p.uid).join(",") } })),
        isRequestPSPDialogOpen && (React.createElement(PSPRequestForm, { onClose: closeDialog, DialogProps: { open: true }, buyerLeadUid: buyerLead.uid, buyerPropertyUid: property.uid })),
        isNMSRequestDialogOpen && (React.createElement(NMSRequestForm, { onClose: closeDialog, DialogProps: { open: true }, buyerLeadUid: buyerLead.uid, buyerPropertyUid: property.uid })),
        isLinkQFODialogOpen && (React.createElement(LinkQFODialog, { onLinkQFO: onLinkQFO, onClose: closeDialog, DialogProps: { open: true }, autocompleteFilter: { exclude_uids: property.qfos.map((q) => q.uid).join(",") } }))));
};
export default PropertyDialog;
