import * as React from "react";
import { Add, DragIndicator } from "@mui/icons-material";
import { Box, Grid2, Typography, IconButton, Tooltip, Chip, Paper } from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import numeral from "numeral";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import dayjs_ from "@app/common/dayjs";
import MAtrixDraggable from "@app/entrypoints/brokerage/features/apps/matrix/components/MAtrixDraggable";
import { onKeyDown } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
const PHOTO_HEIGHT = 100;
const ROW_HEIGHT = 30;
const FIELDS = [
    { compName: "Address", xs: 12 },
    { compName: "ZPID", xs: 12 },
    { compName: "Sales Price", xs: 12 },
    { compName: "Convert Acres To SQFT", hasAdjustment: true },
    { compName: "Closing Date", hasAdjustment: true },
    { matrixName: "DOM", compName: "DOM", hasAdjustment: true },
    { matrixName: "Finished SqFt", compName: "Living Area Above Grade SqFt", hasAdjustment: true },
    { matrixName: "Basement Finished SqFt", compName: "Living Area Below Grade SqFt", hasAdjustment: true },
    { matrixName: "Basement Unfinished SqFt", compName: "Other Area Below Grade SqFt", hasAdjustment: true },
    { matrixName: "Full Baths", compName: "Full Baths", hasAdjustment: true },
    { matrixName: "Half Baths", compName: "Half Baths", hasAdjustment: true },
    { matrixName: "Stories", compName: "Stories", hasAdjustment: true },
    { matrixName: "Year Built", compName: "Year Built", hasAdjustment: true },
    {
        matrixName: "Garage Type",
        compName: "Garage Type",
        label: "Garage",
        hasAdjustment: true,
    },
    {
        matrixName: "Garage Spots",
        compName: "Garage Spots",
        label: "# of Garage Spaces",
        hasAdjustment: true,
    },
    {
        matrixName: "Carport Spots",
        compName: "Carport Spots",
        label: "# of Carport Spaces",
        hasAdjustment: true,
    },
    {
        matrixName: "Driveway Type",
        compName: "Driveway Type",
        label: "Driveway",
        hasAdjustment: true,
    },
    {
        matrixName: "Flooring Type",
        compName: "Flooring Type",
        label: "Flooring",
        hasAdjustment: true,
    },
    {
        matrixName: "Pool Type",
        compName: "Pool Type",
        label: "Pool on Property",
        hasAdjustment: true,
    },
    { matrixName: "Landscape", compName: "Landscape", hasAdjustment: true },
    { matrixName: "Construction Side Upgrades", compName: "Construction Side Upgrades", hasAdjustment: true },
    {
        matrixName: "Fencing Type",
        compName: "Fencing Type",
        label: "Fencing",
        hasAdjustment: true,
    },
    { matrixName: "Bathroom Quality", compName: "Bathroom Quality", hasAdjustment: true },
    { matrixName: "Kitchen Appliances", compName: "Kitchen Appliances", hasAdjustment: true },
    { matrixName: "Kitchen Quality", compName: "Kitchen Quality", hasAdjustment: true },
    { matrixName: "Power Line", compName: "Power Line", hasAdjustment: true },
    { matrixName: "Railroad", compName: "Railroad", hasAdjustment: true },
    { matrixName: "Road Type", compName: "Road Type", hasAdjustment: true },
    {
        matrixName: "Porch/Patio Type",
        compName: "Porch/Patio Type",
        label: "Porch/Patio",
        hasAdjustment: true,
    },
    { compName: "Concession", hasAdjustment: true },
    {
        compName: "Additional Subjective Adjustment Amount",
        label: "Additional Subjective Lot Adjustment $",
        hasAdjustment: true,
    },
    {
        compName: "Additional Subjective Adjustment Amount 2",
        label: "Additional Subjective Lot Adjustment $ #2",
        hasAdjustment: true,
    },
    { compName: "In Susan Subdivision", hasAdjustment: true },
    { matrixName: "Negative Externalities", compName: "Negative Externalities", hasAdjustment: true },
    { matrixName: "Positive Externalities", compName: "Positive Externalities", hasAdjustment: true },
    { matrixName: "Zestimate", compName: "Zestimate", xs: 6 },
    { compName: "Adjusted Value", label: "Adj Value", isTotal: true, xs: 12 },
    { compName: "Total Adjustment", label: "Total Adj", isTotal: true, xs: 12 },
    { compName: "Weight", xs: 12 },
];
const ANALYST_FIELDS = [
    { label: "Analyst's Confidence Score", matrixName: "Analyst Confidence Score", multiplyBy100: true },
    { label: "Analyst's Suggested Price", matrixName: "Analyst Suggested Price" },
    { label: "Liquidation Price" },
];
const MATRIX_FIELDS = [
    {
        name: "Garage Spots",
    },
    {
        name: "Carport Spots",
    },
    {
        name: "Kitchen Quality",
    },
    {
        name: "Kitchen Appliances",
    },
    {
        name: "Bathroom Quality",
    },
    {
        label: "# Baths",
        name: "Full Baths",
    },
];
const renderTags = (selected) => {
    return (React.createElement(Box, { display: "flex", overflow: "hidden" }, selected.map((value) => (React.createElement(Chip, { key: value, label: value, size: "small", style: { height: "15px" } })))));
};
const CMACompsTab = (props) => {
    const { addCMAComp, cmaCompSchema, cmaComps, deleteCMAComp, isAddingCMAComp, isGettingCMAComps, matrixFields, matrixSchema, onMAtrixFieldSaveBegin, onMAtrixFieldSaveComplete, reorderCMAComps, uid, updateCMAComp, } = props;
    /** gets a list of cma comp uids for the draggable container */
    const cmaCompUids = React.useMemo(() => {
        return cmaComps.map((comp) => ({
            id: comp.uid,
        }));
    }, [cmaComps]);
    /** total adjustments for each comp */
    const adjustmentTotals = React.useMemo(() => {
        const totals = [];
        cmaComps.forEach((comp) => {
            var _a;
            const adjValue = comp.fields["Adjusted Value"] ? comp.fields["Adjusted Value"].value : null;
            const totalAdj = comp.fields["Total Adjustment"] ? comp.fields["Total Adjustment"].value : null;
            totals.push({
                uid: comp.uid,
                adjValue: adjValue !== null && adjValue !== void 0 ? adjValue : 0,
                totalAdj: totalAdj !== null && totalAdj !== void 0 ? totalAdj : 0,
                weight: (_a = comp.fields["Weight"]) !== null && _a !== void 0 ? _a : 0,
            });
        });
        return totals;
    }, [cmaComps]);
    const filteredAdjustmentTotals = React.useMemo(() => {
        return adjustmentTotals.filter((item) => item.adjValue);
    }, [adjustmentTotals]);
    const weightedAvg = React.useMemo(() => {
        let weightedAvgSum = 0;
        let totalWeight = 0;
        filteredAdjustmentTotals.forEach((item) => {
            const compWeight = item.weight || 0;
            totalWeight += compWeight;
            weightedAvgSum += item.adjValue * compWeight;
        });
        return totalWeight !== 0 ? Math.round(weightedAvgSum / totalWeight / 100) * 100 : 0;
    }, [filteredAdjustmentTotals]);
    const suggestedConfidenceScore = React.useMemo(() => {
        const grandTotalAdj = adjustmentTotals
            .map((item) => item.totalAdj)
            .reduce((total, num) => total + Math.abs(num), 0);
        const grandTotalAdjValue = adjustmentTotals
            .map((item) => item.adjValue)
            .reduce((total, num) => total + Math.abs(num), 0);
        return numeral(Math.ceil((grandTotalAdj / filteredAdjustmentTotals.length / (grandTotalAdjValue / filteredAdjustmentTotals.length)) *
            100)).format("0%");
    }, [filteredAdjustmentTotals, adjustmentTotals]);
    const onCMACompsReorder = React.useCallback((e) => {
        var _a;
        const activeId = e.active.id;
        const overId = (_a = e.over) === null || _a === void 0 ? void 0 : _a.id;
        if (activeId !== overId) {
            const oldIndex = cmaComps.findIndex((comp) => comp.uid === activeId);
            const newIndex = cmaComps.findIndex((comp) => comp.uid === overId);
            if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
                const compToMove = cmaComps[oldIndex];
                cmaComps.splice(oldIndex, 1);
                cmaComps.splice(newIndex, 0, compToMove);
                reorderCMAComps(cmaComps.map((item) => item.uid));
            }
        }
    }, [cmaComps, reorderCMAComps]);
    console.log({ cmaComps });
    console.log(isGettingCMAComps);
    if (isGettingCMAComps || !cmaCompSchema) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, style: { padding: "0px" } },
        React.createElement(Paper, { elevation: 2, style: { width: "100%" } },
            React.createElement(Grid2, { container: true, size: 12, style: { padding: "4px" } },
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, { variant: "h6" }, "Subject Property")),
                MATRIX_FIELDS.map((field) => {
                    var _a;
                    return (React.createElement(Grid2, { key: field.name, size: { xs: 12, sm: 4, md: 3, lg: 2 }, style: { padding: "4px" } },
                        React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, label: (_a = field.label) !== null && _a !== void 0 ? _a : field.name, field: matrixSchema[field.name], value: matrixFields[field.name], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onMAtrixFieldSaveBegin, onSuccess: onMAtrixFieldSaveComplete })));
                }))),
        React.createElement(Grid2, { container: true, size: 12 },
            React.createElement(Grid2, { style: { width: "325px" } },
                React.createElement(Grid2, null,
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add a Comp to the MAtrix") },
                        React.createElement(IconButton, { onClick: addCMAComp, disabled: !!isAddingCMAComp },
                            React.createElement(Add, null)))),
                React.createElement(Grid2, { style: { height: PHOTO_HEIGHT + 20, textAlign: "right" } },
                    React.createElement(Typography, { variant: "caption", align: "right", style: {
                            textAlign: "right",
                            paddingRight: "8px",
                        } }, "Photo")),
                React.createElement(Grid2, { style: { height: PHOTO_HEIGHT - 2, textAlign: "right", marginTop: "10px" } },
                    React.createElement(Typography, { variant: "caption", align: "right", style: {
                            textAlign: "right",
                            paddingRight: "8px",
                        } }, "Street View")),
                FIELDS.map((field) => {
                    var _a, _b;
                    let fieldVal = null;
                    if (field.matrixName) {
                        fieldVal =
                            matrixSchema[field.matrixName].field_type === "choice" && matrixSchema[field.matrixName].allows_multiple
                                ? ((_a = matrixFields[field.matrixName]) !== null && _a !== void 0 ? _a : []).join(", ")
                                : matrixFields[field.matrixName];
                    }
                    return (React.createElement(Grid2, { key: `${field.compName}LeftLabel`, container: true, size: 12, style: { height: ROW_HEIGHT, overflow: "hidden" } },
                        React.createElement(Grid2, { size: { xs: field.xs === 12 ? 12 : 8 }, style: { textAlign: "right", height: ROW_HEIGHT, overflow: "hidden" } },
                            React.createElement(Typography, { variant: "caption", align: "right", style: {
                                    textAlign: "right",
                                    paddingRight: "8px",
                                    lineHeight: `${ROW_HEIGHT}px`,
                                } }, (_b = field.label) !== null && _b !== void 0 ? _b : field.compName)),
                        field.matrixName && (React.createElement(Grid2, { size: 4 }, field.hasAdjustment && (React.createElement(Tooltip, { title: fieldVal },
                            React.createElement(Typography, { variant: "body2", align: "center", style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    lineHeight: `${ROW_HEIGHT}px`,
                                } }, fieldVal)))))));
                })),
            React.createElement(Grid2, { style: { maxWidth: "calc(100% - 325px)" } },
                React.createElement(Grid2, { container: true, style: {
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                        padding: "4px",
                        overflowX: "auto",
                        overflowY: "hidden",
                    } },
                    React.createElement(DndContext, { onDragEnd: onCMACompsReorder, collisionDetection: closestCenter, modifiers: [restrictToHorizontalAxis] },
                        React.createElement(SortableContext, { items: cmaCompUids, strategy: horizontalListSortingStrategy }, cmaComps.map((comp, index) => {
                            const compStreetviewPhotoUrl = comp.streetviewPhotoUrl;
                            return (React.createElement(MAtrixDraggable, { key: `${comp.uid}draggable_inner`, uid: comp.uid, name: React.createElement(React.Fragment, null,
                                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, `Comp ${index + 1}`),
                                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold", marginLeft: "auto" } }, "Adj.")), dragHandle: React.createElement(DragIndicator, { fontSize: "small", className: "drag-handle", style: {
                                        cursor: "grab",
                                        color: "#999",
                                        transform: "rotate(90deg)",
                                        display: "flex",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    } }), onDelete: deleteCMAComp },
                                React.createElement(Grid2, { size: 12, style: { height: PHOTO_HEIGHT, textAlign: "center" } }, comp.fields["Photo URL"] && (React.createElement("img", { alt: "Property", src: comp.fields["Photo URL"], height: PHOTO_HEIGHT }))),
                                React.createElement(Grid2, { size: 12 },
                                    React.createElement(DashAutoSaveField, { variant: "standard", placeholder: "URL", fullWidth: true, field: cmaCompSchema["Photo URL"], value: comp.fields["Photo URL"], apiEndpoint: `/api/cma_comps/${comp.uid}`, onSuccess: updateCMAComp, size: "small" })),
                                React.createElement(Grid2, { size: 12, style: { height: PHOTO_HEIGHT, textAlign: "center", marginBottom: "10px" } }, compStreetviewPhotoUrl && (React.createElement("img", { alt: "Streetview", src: compStreetviewPhotoUrl, width: PHOTO_HEIGHT }))),
                                FIELDS.map((field) => {
                                    var _a, _b;
                                    const compField = (_a = cmaCompSchema[field.compName]) !== null && _a !== void 0 ? _a : {};
                                    let compValue = comp.fields[field.compName];
                                    const isFormulaField = !!compField.formula;
                                    if (isFormulaField) {
                                        const { format } = compField;
                                        // eslint-disable-next-line
                                        compValue = compValue ? compValue.value : null;
                                        if (format && compValue != null) {
                                            if (format.includes("0")) {
                                                compValue = numeral(compValue).format(format);
                                            }
                                            else if (format.toLowerCase().includes("m")) {
                                                compValue = dayjs_(compValue).format(format);
                                            }
                                        }
                                    }
                                    const adjustedName = `${field.compName} Adjustment`;
                                    let adjustedVal = field.hasAdjustment ? comp.fields[adjustedName] : null;
                                    const bgColor = index % 2 === 0 ? "rgb(230, 230, 230)" : "rgb(250, 250, 250)";
                                    let adjColor = bgColor;
                                    const fieldSize = field.hasAdjustment ? 6 : ((_b = field.xs) !== null && _b !== void 0 ? _b : 12);
                                    if (adjustedVal) {
                                        // eslint-disable-next-line
                                        adjustedVal = adjustedVal ? adjustedVal.value : null;
                                    }
                                    if (field.hasAdjustment &&
                                        adjustedName in cmaCompSchema &&
                                        !["", null, undefined].includes(adjustedVal)) {
                                        const { format } = cmaCompSchema[adjustedName];
                                        if (adjustedVal && (adjustedVal > 0 || adjustedVal < 0)) {
                                            adjColor = "#ffff99";
                                        }
                                        adjustedVal = numeral(adjustedVal).format(format !== null && format !== void 0 ? format : "$0,0");
                                    }
                                    return (React.createElement(Grid2, { key: `${field.compName + comp.uid}_comp_field`, container: true, size: 12, spacing: 0, style: { height: `${ROW_HEIGHT}px` } },
                                        React.createElement(Grid2, { key: `${comp.uid}_${field.compName}`, size: fieldSize, style: {
                                                paddingRight: fieldSize === 12 ? "0px" : "8px",
                                            } }, isFormulaField ? (React.createElement(Typography, { align: "center", style: {
                                                backgroundColor: bgColor,
                                                borderColor: "#ebedf2",
                                                borderRadius: "0.25rem",
                                            } }, compValue == null ? "" : compValue)) : (React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, field: compField, value: compValue, apiEndpoint: `/api/cma_comps/${comp.uid}`, onSuccess: updateCMAComp, size: "small", inputProps: {
                                                style: {
                                                    fontSize: "11px",
                                                    borderColor: "#ebedf2",
                                                    borderRadius: "0.25rem",
                                                },
                                            }, style: {
                                                backgroundColor: bgColor,
                                                borderColor: "#ebedf2",
                                                borderRadius: "0.25rem",
                                                paddingBottom: "0px",
                                                marginTop: "0px",
                                            }, 
                                            // TODO: look into this
                                            // @ts-expect-error: not sure what this is about
                                            renderValue: compField.allows_multiple ? renderTags : undefined, onKeyDown: onKeyDown }))),
                                        field.hasAdjustment && (React.createElement(Grid2, { size: { xs: fieldSize === 12 ? 12 : 12 - fieldSize }, style: {
                                                backgroundColor: adjColor,
                                                textAlign: field.isTotal ? "center" : "left",
                                                borderColor: "#ebedf2",
                                                borderRadius: "0.25rem",
                                                height: `${ROW_HEIGHT - 6}px`,
                                            } },
                                            React.createElement(Typography, { style: {
                                                    paddingLeft: "8px",
                                                } }, adjustedVal || null)))));
                                })));
                        })))))),
        React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
            React.createElement(Grid2, null,
                React.createElement(Grid2, { container: true, size: 12, style: { height: `${ROW_HEIGHT}px` } },
                    React.createElement(Grid2, { size: 8, style: { textAlign: "right" } },
                        React.createElement(Typography, { variant: "caption", align: "right", style: {
                                textAlign: "right",
                                paddingRight: "8px",
                                fontWeight: "bold",
                            } }, "Suggested Confidence Score")),
                    React.createElement(Grid2, { size: 4 },
                        React.createElement(Typography, { variant: "caption", align: "right", style: {
                                textAlign: "right",
                                paddingRight: "8px",
                                fontWeight: "bold",
                            } }, suggestedConfidenceScore))),
                ANALYST_FIELDS.map((field) => {
                    var _a, _b, _c, _d;
                    let fieldVal = matrixFields[(_a = field.matrixName) !== null && _a !== void 0 ? _a : field.label];
                    if (fieldVal) {
                        fieldVal = numeral(fieldVal).format("$0,0");
                    }
                    return (React.createElement(Grid2, { key: `${field.label}AnalystFieldLabel`, container: true, size: 12, style: { height: `${ROW_HEIGHT}px` } },
                        React.createElement(Grid2, { size: 8, style: { textAlign: "right" } },
                            React.createElement(Typography, { variant: "caption", align: "right", style: {
                                    textAlign: "right",
                                    paddingRight: "8px",
                                    fontWeight: "bold",
                                } }, field.label)),
                        React.createElement(Grid2, { size: 4 },
                            React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, field: matrixSchema[(_b = field.matrixName) !== null && _b !== void 0 ? _b : field.label], value: matrixFields[(_c = field.matrixName) !== null && _c !== void 0 ? _c : field.label], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onMAtrixFieldSaveBegin, onSuccess: onMAtrixFieldSaveComplete, size: "small", multiplyBy100: (_d = field.multiplyBy100) !== null && _d !== void 0 ? _d : undefined }))));
                })),
            React.createElement(Grid2, { style: { flexGrow: 1 } },
                React.createElement(Grid2, { size: 12 },
                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, number: weightedAvg, format: "$0,0", size: "small", disabled: true })),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, placeholder: "Notes", field: matrixSchema["Notes"], value: matrixFields["Notes"], apiEndpoint: `/api/psps/${uid}`, onSaveBegin: onMAtrixFieldSaveBegin, onSuccess: onMAtrixFieldSaveComplete, size: "small", slotProps: {
                            input: {
                                multiline: true,
                                minRows: 5,
                                maxRows: 5,
                            },
                        } }))))));
};
export default CMACompsTab;
