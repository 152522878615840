// eslint-disable-next-line import/prefer-default-export
export const NOT_DUPLICATE_CONTACT_FILTER = [
    {
        uid: "",
        is_match_all: true,
        filters: [
            {
                uid: "",
                boolean_filter: {
                    lhs_field: "35dc914032ac43cd8776b4b390d8722a",
                    comparator: "is not checked",
                },
            },
        ],
        parent_uid: null,
    },
];
