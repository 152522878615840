import { useMemo } from "react";
import User from "@app/classes/user";
import useSession from "@app/hooks/useSession";
import { searchUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
/**
 * Determines if the current user is a team admin for any of the given team member uids,
 * and enriches the current user's roles with the "Admin" role if they are.
 *
 * @param memberUids
 * @returns
 */
const useTeamAdminPermissions = (memberUids) => {
    var _a, _b;
    const session = useSession();
    const listUsersInMyPurviewApi = usePostQuery(searchUsers, { is_in_my_purview: true, limit: 1000 }, INFINITE_CACHE_PARAMS);
    const usersInMyPurview = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersInMyPurviewApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_a = listUsersInMyPurviewApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const canManage = (_b = usersInMyPurview === null || usersInMyPurview === void 0 ? void 0 : usersInMyPurview.some((x) => (memberUids === null || memberUids === void 0 ? void 0 : memberUids.includes(x.uid)) && usersInMyPurview.length > 1)) !== null && _b !== void 0 ? _b : null;
    const effectiveUser = useMemo(() => {
        var _a;
        if ((session === null || session === void 0 ? void 0 : session.viewingAsUser) == null) {
            return null;
        }
        return new User(Object.assign(Object.assign({}, session.viewingAsUser), { roles: [...new Set([...((_a = session.viewingAsUser.roles) !== null && _a !== void 0 ? _a : []), ...(canManage ? ["Admin"] : [])])] }));
    }, [canManage, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    return {
        canManage,
        effectiveUser,
        usersInMyPurview,
    };
};
export default useTeamAdminPermissions;
