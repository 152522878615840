import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { addStarredView, removeStarredView } from "@app/entrypoints/brokerage/window-messages";
import { useListStarredViews, useStarView } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { isChromeExtension } from "@app/util/Utils";
import useSession from "../useSession";
const useStarredViewsForCurrentUser = () => {
    var _a, _b, _c;
    const session = useSession();
    const queryClient = useQueryClient();
    const starViewApi = useStarView();
    const listStarredViewsApi = useListStarredViews({ user_uid: (_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : undefined }, Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS.query), { enabled: !!((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid) }) }));
    const starredViews = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStarredViewsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listStarredViewsApi]);
    const starredViewUids = useMemo(() => {
        return starredViews ? new Set(starredViews.items.map((view) => view.uid)) : null;
    }, [starredViews]);
    const starView = useCallback((viewUid, viewName) => {
        const shouldStar = !(starredViewUids === null || starredViewUids === void 0 ? void 0 : starredViewUids.has(viewUid));
        queryClient.setQueryData(listStarredViewsApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { items: [
                        ...old.data.items.filter((x) => x.uid !== viewUid),
                        ...(shouldStar
                            ? [
                                {
                                    uid: viewUid,
                                    name: viewName,
                                    user_uid: session.viewingAsUser.uid,
                                    starred_at: new Date().toISOString(),
                                },
                            ]
                            : []),
                    ] }) });
        });
        if (isChromeExtension) {
            if (shouldStar) {
                addStarredView({ uid: viewUid, name: viewName });
            }
            else {
                removeStarredView(viewUid);
            }
        }
        starViewApi
            .mutateAsync({
            data: {
                view_uid: viewUid,
                user_uid: session.viewingAsUser.uid,
                is_starred: shouldStar,
            },
        })
            .catch(enqueueErrorSnackbar);
    }, [listStarredViewsApi.queryKey, queryClient, session, starredViewUids, starViewApi]);
    return {
        starredViews,
        starredViewUids,
        starView,
    };
};
export default useStarredViewsForCurrentUser;
