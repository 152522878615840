import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const useErrorHandler = () => {
    const confirm = useConfirm();
    const handleError = (error, handler) => {
        if (handler === "do-nothing") {
            console.warn(error);
        }
        else if (handler === "show-snackbar") {
            enqueueSnackbar(error, { variant: "error", persist: true });
        }
        else if (handler === "show-dialog") {
            // confirm({ title: "An error occurred", description: String(error), hideCancelButton: true });
            confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS), { description: String(error) }));
        }
        else {
            handler(error);
        }
    };
    return {
        handleError,
    };
};
export default useErrorHandler;
