var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Delete, Save } from "@mui/icons-material";
import { Box, Button, Divider, Grid2, TextField } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useCreateGoogleDataStudioReport, useDeleteGoogleDataStudioReport, useGetGoogleDataStudioReport, useUpdateGoogleDataStudioReport, } from "@app/orval/api/google-data-studio-reports";
const defaultReport = {
    name: "",
    url: "",
};
const ReportConfig = (props) => {
    var _a, _b;
    const { uid = null } = props;
    const navigate = useNavigate();
    const confirm = useConfirm();
    const getReportApi = useGetGoogleDataStudioReport(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: !!uid } });
    const report = (_b = (_a = getReportApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [formData, setFormData] = React.useState(defaultReport);
    const [initialData, setInitialData] = React.useState(defaultReport);
    React.useEffect(() => {
        if (report) {
            setFormData(report);
            setInitialData(report);
        }
        else {
            setFormData(defaultReport);
            setInitialData(defaultReport);
        }
    }, [report]);
    const isValid = React.useMemo(() => {
        return (formData.name.trim() !== "" &&
            formData.url.trim() !== "" &&
            JSON.stringify(formData) !== JSON.stringify(initialData));
    }, [formData, initialData]);
    const createReportApi = useCreateGoogleDataStudioReport();
    const updateReportApi = useUpdateGoogleDataStudioReport();
    const deleteReportApi = useDeleteGoogleDataStudioReport();
    const isSaving = createReportApi.isPending || updateReportApi.isPending;
    const isDeleting = deleteReportApi.isPending;
    const goBack = React.useCallback(() => {
        navigate("/datastudios/config");
    }, [navigate]);
    const save = React.useCallback(() => {
        if (uid) {
            updateReportApi.mutateAsync({ uid, data: formData }).then(goBack).catch(enqueueErrorSnackbar);
        }
        else {
            createReportApi.mutateAsync({ data: formData }).then(goBack).catch(enqueueErrorSnackbar);
        }
    }, [uid, formData, createReportApi, updateReportApi, goBack]);
    const remove = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield confirm({ title: "Are you sure you want to delete this report?" });
        if (result.confirmed) {
            deleteReportApi.mutateAsync({ uid: uid }).then(goBack).catch(enqueueErrorSnackbar);
        }
    }), [uid, deleteReportApi, confirm, goBack]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const setUrl = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { url: e.target.value })));
    }, [setFormData]);
    if (getReportApi.error) {
        return React.createElement(CoreError, { error: getReportApi.error });
    }
    if ((uid != null && report == null) || isSaving || isDeleting) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 4 },
                React.createElement(Grid2, { container: true, spacing: 4 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", label: "Name", required: true, fullWidth: true, value: formData.name, onChange: setName })),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", label: "URL", fullWidth: true, value: formData.url, onChange: setUrl }))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true },
                    uid && (React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", color: "error", startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: remove }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", variant: "contained", color: "primary", disabled: !isValid, startIcon: React.createElement(Save, { fontSize: "small" }), onClick: save }, "Save")))))));
};
export default ReportConfig;
