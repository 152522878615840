import * as React from "react";
import { Add, CloudUpload, CloudDownload, Refresh } from "@mui/icons-material";
import { IconButton, ListItemIcon, MenuItem } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import DashPopover from "@app/common/CoreMoreMenu";
import { convertUtcDateString } from "@app/common/date-utils";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import DashFixedWidthTableCell from "@app/common/grid/CoreFixedWidthTableCell";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { downloadWordDocTemplate, useListWordDocTemplates, useListWordDocTemplateTypes, } from "@app/orval/api/templates-word-doc-templates";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import TemplatesDialog from "./TemplatesDialog";
const TemplatesTable = () => {
    var _a, _b, _c, _d, _e;
    const [isTemplatesDialogOpen, setIsTemplatesDialogOpen] = React.useState(false);
    const [currentTemplateType, setCurrentTemplateType] = useStateWithCallback(null);
    const errorDialog = useErrorDialog();
    const session = useSession();
    const listTemplatesApi = useListWordDocTemplates();
    const listTemplateTypesApi = useListWordDocTemplateTypes(INFINITE_CACHE_PARAMS);
    const templates = (_b = (_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const templateTypes = (_d = (_c = listTemplateTypesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const child = React.createRef();
    const refresh = React.useCallback(() => {
        listTemplatesApi.refetch();
        listTemplateTypesApi.refetch();
        setCurrentTemplateType(null);
        setIsTemplatesDialogOpen(false);
    }, [listTemplatesApi, listTemplateTypesApi, setCurrentTemplateType]);
    const closePopover = React.useCallback(() => {
        var _a;
        // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
        (_a = child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
    }, [child]);
    const downloadTemplate = React.useCallback((templateType) => () => {
        closePopover();
        downloadWordDocTemplate(templateType)
            .then((result) => {
            const { url } = result.data;
            window.open(url, "_blank");
        })
            .catch((error) => {
            errorDialog(error);
        });
    }, [closePopover, errorDialog]);
    const uploadTemplate = React.useCallback((templateType) => () => {
        closePopover();
        setCurrentTemplateType(templateType, () => {
            setIsTemplatesDialogOpen(true);
        });
    }, [closePopover, setCurrentTemplateType]);
    const openTemplatesDialog = React.useCallback(() => {
        setIsTemplatesDialogOpen(true);
    }, []);
    const onCancel = React.useCallback(() => {
        setIsTemplatesDialogOpen(false);
        setCurrentTemplateType(null);
    }, [setCurrentTemplateType]);
    const loading = [
        {
            label: "Loading templates...",
            status: listTemplatesApi.status,
            error: listTemplatesApi.error,
        },
        {
            label: "Loading template types...",
            status: listTemplateTypesApi.status,
            error: listTemplateTypesApi.error,
        },
    ];
    if (loading.find((x) => x.status === "pending") || !templates || !templateTypes) {
        return React.createElement(Loading, { items: loading });
    }
    console.log({ templateTypes });
    const columns = [
        ((_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole("Superadmin")) && {
            name: "template_type",
            label: " ",
            options: {
                searchable: false,
                sort: false,
                download: false,
                filter: false,
                print: false,
                customHeadRender: ({ index }) => React.createElement(DashFixedWidthTableCell, { key: index, width: "35px" }),
                customBodyRender: (value) => {
                    return (React.createElement(DashPopover, null,
                        React.createElement(MenuItem, { onClick: uploadTemplate(value) },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CloudUpload, { fontSize: "small" })),
                            "Upload new template"),
                        React.createElement(MenuItem, { onClick: downloadTemplate(value) },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CloudDownload, { fontSize: "small" })),
                            "Download template")));
                },
            },
        },
        {
            name: "template_type",
            label: "Template Type",
        },
        {
            name: "filename",
            label: "Filename",
        },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                customBodyRender: (value) => value ? convertUtcDateString(value, { format: "M/D/YYYY h:mm A" }) : "",
            },
        },
    ].filter((obj) => obj);
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        search: false,
        searchOpen: false,
        pagination: false,
        viewColumns: false,
        expandableRows: false,
        customToolbar: () => (React.createElement(React.Fragment, null,
            React.createElement(IconButton, { onClick: refresh },
                React.createElement(Refresh, null)),
            React.createElement(IconButton, { onClick: openTemplatesDialog },
                React.createElement(Add, null)))),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DashCard, { padding: 2, overflowY: "hidden" },
            React.createElement(CoreDataTable, { isLoading: !templates, title: "", data: templates, columns: columns, options: options, cellPadding: 0 })),
        React.createElement(TemplatesDialog, { open: !!isTemplatesDialogOpen, templateType: currentTemplateType, templateTypes: templateTypes, onUploadComplete: refresh, onCancel: onCancel })));
};
export default TemplatesTable;
