/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List NMS Requests
 */
export const listNmsRequests = (params, options) => {
    return axios.get(`/api/nms_requests`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListNmsRequestsQueryKey = (params) => {
    return [`/api/nms_requests`, ...(params ? [params] : [])];
};
export const getListNmsRequestsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListNmsRequestsQueryKey(params);
    const queryFn = ({ signal }) => listNmsRequests(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List NMS Requests
 */
export function useListNmsRequests(params, options) {
    const queryOptions = getListNmsRequestsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get a NMS Requests
 */
export const getNmsRequest = (uid, options) => {
    return axios.get(`/api/nms_requests/${uid}`, options);
};
export const getGetNmsRequestQueryKey = (uid) => {
    return [`/api/nms_requests/${uid}`];
};
export const getGetNmsRequestQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetNmsRequestQueryKey(uid);
    const queryFn = ({ signal }) => getNmsRequest(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a NMS Requests
 */
export function useGetNmsRequest(uid, options) {
    const queryOptions = getGetNmsRequestQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Delete a NMS Request
 */
export const deleteNmsRequest = (uid, options) => {
    return axios.delete(`/api/nms_requests/${uid}`, options);
};
export const getDeleteNmsRequestMutationOptions = (options) => {
    const mutationKey = ['deleteNmsRequest'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteNmsRequest(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a NMS Request
*/
export const useDeleteNmsRequest = (options) => {
    const mutationOptions = getDeleteNmsRequestMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary COunt NMS Requests
*/
export const countNmsRequests = (params, options) => {
    return axios.get(`/api/nms_requests/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountNmsRequestsQueryKey = (params) => {
    return [`/api/nms_requests/count`, ...(params ? [params] : [])];
};
export const getCountNmsRequestsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountNmsRequestsQueryKey(params);
    const queryFn = ({ signal }) => countNmsRequests(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary COunt NMS Requests
 */
export function useCountNmsRequests(params, options) {
    const queryOptions = getCountNmsRequestsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List MLS areas
 */
export const listNmsMlsAreas = (options) => {
    return axios.get(`/api/nms_requests/mls_areas`, options);
};
export const getListNmsMlsAreasQueryKey = () => {
    return [`/api/nms_requests/mls_areas`];
};
export const getListNmsMlsAreasQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListNmsMlsAreasQueryKey();
    const queryFn = ({ signal }) => listNmsMlsAreas(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List MLS areas
 */
export function useListNmsMlsAreas(options) {
    const queryOptions = getListNmsMlsAreasQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List request types
 */
export const listNmsRequestTypes = (options) => {
    return axios.get(`/api/nms_requests/request_types`, options);
};
export const getListNmsRequestTypesQueryKey = () => {
    return [`/api/nms_requests/request_types`];
};
export const getListNmsRequestTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListNmsRequestTypesQueryKey();
    const queryFn = ({ signal }) => listNmsRequestTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List request types
 */
export function useListNmsRequestTypes(options) {
    const queryOptions = getListNmsRequestTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Submit a NMS Request
 */
export const submitNmsRequest = (nMSRequestWriteRequest, options) => {
    return axios.post(`/api/nms_requests/submit`, nMSRequestWriteRequest, options);
};
export const getSubmitNmsRequestMutationOptions = (options) => {
    const mutationKey = ['submitNmsRequest'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return submitNmsRequest(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit a NMS Request
*/
export const useSubmitNmsRequest = (options) => {
    const mutationOptions = getSubmitNmsRequestMutationOptions(options);
    return useMutation(mutationOptions);
};
