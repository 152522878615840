var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { ArrowBack, Check, Close, Error, FilterList, OpenInBrowser, Refresh } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Divider, Grid2, IconButton, InputAdornment, LinearProgress, MenuItem, TextField, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import dayjs from "dayjs";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import { useMountedState } from "react-use";
import styled from "styled-components";
import { DialogContext } from "@app/common/context/DialogContext";
import { getModalSidebarDialogProps } from "@app/common/context/utils";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreTextField from "@app/common/CoreTextField";
import { convertUtcDateString } from "@app/common/date-utils";
import dayjs_ from "@app/common/dayjs";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING } from "@app/common/grid/utils";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { countMailschedule4Logs, searchMailschedule4Executions, searchMailschedule4Logs, } from "@app/orval/api/mail-schedule-4-logs";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { searchUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import CustomTemplateModal from "./components/CustomTemplateModal";
import BoxFilter from "./components/filters/BoxFilter";
import DateTimeFilter from "./components/filters/DateTimeFilter";
import ModeFilter from "./components/filters/ModeFilter";
import PipelineFilter from "./components/filters/PipelineFilter";
import RunByAgentFilter from "./components/filters/RunByAgentFilter";
import TemplateFilter from "./components/filters/TemplateFilter";
import TestModeFilter from "./components/filters/TestModeFilter";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const StyledWrapper = styled("div") `
    & .MuiFab-root {
        display: none;
    }
    &:hover .MuiFab-root {
        display: inline-flex;
    }
`;
const colorsPerExecutionUid = {};
const getColorForExecutionUid = (executionUid) => {
    if (!colorsPerExecutionUid[executionUid]) {
        colorsPerExecutionUid[executionUid] = `hsl(${Math.floor(Math.random() * 360)}, 100%, 75%)`;
    }
    return colorsPerExecutionUid[executionUid];
};
const MailScheduleLogsTable = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    const [searchParams] = useSearchParams();
    const status = (_a = searchParams.get("status")) !== null && _a !== void 0 ? _a : undefined;
    const pipelineFilter = (_b = searchParams.get("pipeline")) !== null && _b !== void 0 ? _b : undefined;
    const boxFilter = (_c = searchParams.get("box")) !== null && _c !== void 0 ? _c : undefined;
    const templateFilter = (_d = searchParams.get("template_uid")) !== null && _d !== void 0 ? _d : undefined;
    const modeFilter = (_e = searchParams.get("mode")) !== null && _e !== void 0 ? _e : undefined;
    const runByAgentFilter = (_f = searchParams.get("run_by_agent")) !== null && _f !== void 0 ? _f : undefined;
    const testModeFilter = (_h = {
        "1": true,
        "0": false,
    }[(_g = searchParams.get("is_test")) !== null && _g !== void 0 ? _g : ""]) !== null && _h !== void 0 ? _h : null;
    const search = (_j = searchParams.get("search")) !== null && _j !== void 0 ? _j : undefined;
    const fromTimestampFilter = (_k = searchParams.get("from_timestamp")) !== null && _k !== void 0 ? _k : undefined;
    const toTimestampFilter = (_l = searchParams.get("to_timestamp")) !== null && _l !== void 0 ? _l : undefined;
    const limit = searchParams.get("limit") ? numeral(searchParams.get("limit")).value() : 100;
    const offset = searchParams.get("offset") ? numeral(searchParams.get("offset")).value() : 0;
    const executionUid = (_m = searchParams.get("uid")) !== null && _m !== void 0 ? _m : undefined;
    const dialogContext = React.useContext(DialogContext);
    const isMounted = useMountedState();
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = React.useState(false);
    const toggleFilterSidebar = React.useCallback(() => {
        setIsFilterSidebarOpen((prev) => !prev);
    }, []);
    const updateSearch = useUpdateSearch();
    const query = React.useMemo(() => {
        const mutationBody = {
            execution_uid: executionUid,
            box_uid: boxFilter,
            pipeline: pipelineFilter,
            template_uid: templateFilter,
            status: status,
            search,
            mode: modeFilter,
            run_by_uid: runByAgentFilter,
            is_test: testModeFilter,
            from_timestamp: fromTimestampFilter,
            to_timestamp: toTimestampFilter,
            limit,
            offset,
        };
        return pickBy(mutationBody, (val) => val != null);
    }, [
        executionUid,
        boxFilter,
        pipelineFilter,
        templateFilter,
        status,
        search,
        modeFilter,
        runByAgentFilter,
        testModeFilter,
        fromTimestampFilter,
        toTimestampFilter,
        limit,
        offset,
    ]);
    const countQuery = React.useMemo(() => {
        return omit(query, ["limit", "offset"]);
    }, [query]);
    const listTemplatesApi = useListMailschedule4Templates({}, INFINITE_CACHE_PARAMS);
    const templates = (_p = (_o = listTemplatesApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const templatesByUid = React.useMemo(() => {
        const res = {};
        for (const template of templates !== null && templates !== void 0 ? templates : []) {
            res[template.uid] = template;
        }
        return res;
    }, [templates]);
    const searchUsersApi = usePostQuery(searchUsers, {}, INFINITE_CACHE_PARAMS);
    const users = (_r = (_q = searchUsersApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const usersByUid = React.useMemo(() => {
        const res = {};
        for (const user of users !== null && users !== void 0 ? users : []) {
            res[user.uid] = user;
        }
        return res;
    }, [users]);
    const searchLogsApi = usePostQuery(searchMailschedule4Logs, query, {});
    const logs = (_t = (_s = searchLogsApi.data) === null || _s === void 0 ? void 0 : _s.data) !== null && _t !== void 0 ? _t : null;
    const countLogsApi = usePostQuery(countMailschedule4Logs, countQuery, {});
    const count = (_v = (_u = countLogsApi.data) === null || _u === void 0 ? void 0 : _u.data.count) !== null && _v !== void 0 ? _v : null;
    const executionUids = React.useMemo(() => {
        const res = new Set();
        for (const log of logs !== null && logs !== void 0 ? logs : []) {
            res.add(log.execution_uid);
        }
        return Array.from(res).sort();
    }, [logs]);
    const searchExecutionsApi = usePostQuery(searchMailschedule4Executions, {
        uids: executionUids,
    }, {}, executionUids.length > 0);
    const executions = (_x = (_w = searchExecutionsApi.data) === null || _w === void 0 ? void 0 : _w.data) !== null && _x !== void 0 ? _x : null;
    const executionsByUid = React.useMemo(() => {
        const res = {};
        for (const execution of executions !== null && executions !== void 0 ? executions : []) {
            res[execution.uid] = execution;
        }
        return res;
    }, [executions]);
    const execution = executions && executions.length === 1 ? executions[0] : null;
    const canAutoRefresh = React.useMemo(() => {
        return (logs != null &&
            count != null &&
            executionUid != null &&
            !(execution === null || execution === void 0 ? void 0 : execution.end_timestamp) &&
            !(execution === null || execution === void 0 ? void 0 : execution.stopped_timestamp) &&
            !(execution === null || execution === void 0 ? void 0 : execution.error_message));
    }, [logs, count, executionUid, execution === null || execution === void 0 ? void 0 : execution.end_timestamp, execution === null || execution === void 0 ? void 0 : execution.stopped_timestamp, execution === null || execution === void 0 ? void 0 : execution.error_message]);
    const page = React.useMemo(() => {
        if (limit === 0) {
            return 0;
        }
        return Math.floor(offset / limit);
    }, [limit, offset]);
    const isRunning = React.useMemo(() => {
        return (execution &&
            execution.end_timestamp == null &&
            execution.stopped_timestamp == null &&
            execution.error_message == null);
    }, [execution]);
    const openCustomTemplateModal = React.useCallback((logUid) => () => {
        dialogContext.openDialog(React.createElement(CustomTemplateModal, { logUid: logUid }), {
            dialogProps: getModalSidebarDialogProps(),
        });
    }, [dialogContext]);
    const clearExecutionFilter = React.useCallback(() => {
        updateSearch("uid", null, "offset", 0);
    }, [updateSearch]);
    const handleModeFilterChanged = React.useCallback((val) => {
        updateSearch("mode", val, "offset", 0);
    }, [updateSearch]);
    const clearModeFilter = React.useCallback(() => {
        handleModeFilterChanged(null);
    }, [handleModeFilterChanged]);
    const handleTestModeFilterChanged = React.useCallback((val) => {
        const valString = val == null ? null : val ? "1" : "0";
        updateSearch("is_test", valString, "offset", 0);
    }, [updateSearch]);
    const clearTestModeFilter = React.useCallback(() => {
        handleTestModeFilterChanged(null);
    }, [handleTestModeFilterChanged]);
    const handleRunByAgentFilterChanged = React.useCallback((val) => {
        updateSearch("run_by_agent", val, "offset", 0);
    }, [updateSearch]);
    const clearRunByAgentFilter = React.useCallback(() => {
        handleRunByAgentFilterChanged(null);
    }, [handleRunByAgentFilterChanged]);
    const handleTemplateFilterChanged = React.useCallback((val) => {
        updateSearch("template_uid", val, "offset", 0);
    }, [updateSearch]);
    const filterTemplate = React.useCallback((val) => () => {
        handleTemplateFilterChanged(val);
    }, [handleTemplateFilterChanged]);
    const clearTemplateFilter = React.useCallback(() => {
        handleTemplateFilterChanged(null);
    }, [handleTemplateFilterChanged]);
    const handlePipelineFilterChanged = React.useCallback((val) => {
        updateSearch("pipeline", val, "template_uid", null, "offset", 0);
    }, [updateSearch]);
    const filterPipeline = React.useCallback((val) => () => {
        handlePipelineFilterChanged(val);
    }, [handlePipelineFilterChanged]);
    const clearPipelineFilter = React.useCallback(() => {
        handlePipelineFilterChanged(null);
    }, [handlePipelineFilterChanged]);
    const handleBoxFilterChanged = React.useCallback((val) => {
        updateSearch("box", val, "offset", 0);
    }, [updateSearch]);
    const filterBox = React.useCallback((boxUid) => () => {
        handleBoxFilterChanged(boxUid);
    }, [handleBoxFilterChanged]);
    const clearBoxFilter = React.useCallback(() => {
        handleBoxFilterChanged(null);
    }, [handleBoxFilterChanged]);
    const refresh = React.useCallback((...args_1) => __awaiter(void 0, [...args_1], void 0, function* (auto = false) {
        var _a;
        if (!isMounted()) {
            return;
        }
        const res = yield Promise.all([countLogsApi.refetch(), searchLogsApi.refetch(), searchExecutionsApi.refetch()]);
        if (auto) {
            const execution_ = (_a = res[2].data) === null || _a === void 0 ? void 0 : _a.data[0];
            if (executionUid != null &&
                (execution_ == null ||
                    (!execution_.end_timestamp && !execution_.stopped_timestamp && !execution_.error_message))) {
                setTimeout(() => {
                    refresh(true);
                }, 1000);
            }
        }
    }), [countLogsApi, searchLogsApi, searchExecutionsApi, executionUid, isMounted]);
    const onRefreshClicked = React.useCallback(() => {
        refresh();
    }, [refresh]);
    React.useEffect(() => {
        if (canAutoRefresh) {
            refresh(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executionUid, canAutoRefresh]);
    const handleStatusFilterChanged = React.useCallback((val) => {
        updateSearch("status", val, "offset", 0);
    }, [updateSearch]);
    const setStatus = React.useCallback((e) => {
        handleStatusFilterChanged(e.target.value || null);
    }, [handleStatusFilterChanged]);
    const clearStatusFilter = React.useCallback(() => {
        handleStatusFilterChanged(null);
    }, [handleStatusFilterChanged]);
    const setSearch = React.useCallback((value) => {
        updateSearch("search", value.trim() || null, "offset", 0);
    }, [updateSearch]);
    const handleFromTimestampFilterChanged = React.useCallback((val) => {
        updateSearch("from_timestamp", val, "offset", 0);
    }, [updateSearch]);
    const clearFromTimestampFilter = React.useCallback(() => {
        handleFromTimestampFilterChanged(null);
    }, [handleFromTimestampFilterChanged]);
    const handleToTimestampFilterChanged = React.useCallback((val) => {
        updateSearch("to_timestamp", val, "offset", 0);
    }, [updateSearch]);
    const clearToTimestampFilter = React.useCallback(() => {
        handleToTimestampFilterChanged(null);
    }, [handleToTimestampFilterChanged]);
    const onPageChange = React.useCallback((model) => {
        const rowsPerPage = model.pageSize;
        const currentPage = model.page;
        updateSearch("offset", rowsPerPage * currentPage, "limit", rowsPerPage);
    }, [updateSearch]);
    const rows = React.useMemo(() => (logs !== null && logs !== void 0 ? logs : []).map((e) => (Object.assign(Object.assign({}, e), { id: e.uid, template: e.template_uid && (templates === null || templates === void 0 ? void 0 : templates[e.template_uid]) ? templates[e.template_uid].name : e.template_uid }))), [logs, templates]);
    const columns = React.useMemo(() => [
        {
            field: "execution_uid",
            headerName: "Execution",
            width: 250,
            renderCell: (params) => {
                const log = params.row;
                const thisExecution = executionsByUid[log.execution_uid];
                return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { component: RouterLink, to: `/ms4/logs/detailed?uid=${log.execution_uid}`, tabIndex: -1, size: "small" },
                            React.createElement(FilterList, { fontSize: "small" }))),
                    React.createElement(Grid2, null,
                        React.createElement("div", { style: {
                                background: thisExecution ? getColorForExecutionUid(thisExecution.uid) : "#888",
                                borderRadius: "50%",
                                height: "8px",
                                width: "8px",
                            } })),
                    thisExecution && (React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2" }, dayjs(thisExecution.start_timestamp).format("M/D/YYYY h:mm a")))),
                    (thisExecution === null || thisExecution === void 0 ? void 0 : thisExecution.mode) && (React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2", style: { color: "#888" } }, "\u2022"))),
                    (thisExecution === null || thisExecution === void 0 ? void 0 : thisExecution.mode) && (React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2" }, thisExecution.mode)))));
            },
        },
        {
            field: "template_uid",
            headerName: "Template",
            width: 250,
            renderCell: (params) => {
                var _a;
                const template = templates === null || templates === void 0 ? void 0 : templates.find((x) => x.uid === params.value);
                const customTemplateUid = params.row.custom_template_uid;
                return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                    params.value && (React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: filterTemplate(params.value) },
                            React.createElement(FilterList, { fontSize: "small" })))),
                    customTemplateUid && params.value && (React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: openCustomTemplateModal(params.row.id), tabIndex: -1 },
                            React.createElement(OpenInBrowser, { fontSize: "small" })))),
                    params.value && (React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(RouterLink, { to: `/ms4/templates/${params.value}` }, (_a = template === null || template === void 0 ? void 0 : template.name) !== null && _a !== void 0 ? _a : params.value))),
                    customTemplateUid && !params.value && (React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Button, { variant: "text", size: "small", onClick: openCustomTemplateModal(params.row.id), tabIndex: -1 }, "Custom Template")))));
            },
        },
        {
            field: "entity_type",
            headerName: "Pipeline",
            width: 150,
            renderCell: (params) => {
                const log = params.row;
                const entityType = log.entity.pipeline;
                return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                    entityType && (React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: filterPipeline(entityType) },
                            React.createElement(FilterList, { fontSize: "small" })))),
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Typography, { variant: "body2" }, entityType))));
            },
        },
        {
            field: "box",
            headerName: "Box",
            width: 300,
            renderCell: (params) => {
                var _a;
                const log = params.row;
                return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center", wrap: "nowrap" },
                    log.entity.uid && (React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: filterBox(log.entity.uid) },
                            React.createElement(FilterList, { fontSize: "small" })))),
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(RouterLink, { to: `/boxes/${log.entity.uid}`, target: "_blank" }, (_a = log.entity.name) !== null && _a !== void 0 ? _a : log.entity.uid))));
            },
        },
        {
            field: "start_timestamp",
            headerName: "Start",
            width: 200,
            renderCell: (params) => {
                const { value } = params;
                if (value && dayjs_(value).isValid()) {
                    return dayjs_(value).format("ddd, MMM D, YYYY h:mm:ss A");
                }
                return value;
            },
        },
        {
            field: "end_timestamp",
            headerName: "End",
            width: 200,
            renderCell: (params) => {
                const { value } = params;
                if (value && dayjs_(value).isValid()) {
                    return dayjs_(value).format("ddd, MMM D, YYYY h:mm:ss A");
                }
                return value;
            },
        },
        {
            field: "error_message",
            headerName: "Status",
            renderCell: (params) => {
                const { value } = params;
                let status;
                let icon;
                if (value) {
                    status = "Errored Out";
                    icon = React.createElement(Error, { style: { color: "red" } });
                }
                else {
                    const log = params.row;
                    if (!log.end_timestamp &&
                        dayjs_(new Date(`${log.start_timestamp}+00:00`)).isAfter(dayjs_().add(-1, "hour"))) {
                        status = "Running";
                        icon = React.createElement(CoreLoading, { size: 16 });
                    }
                    else {
                        status = "Finished";
                        icon = React.createElement(Check, { style: { color: "green" } });
                    }
                }
                return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                    React.createElement(Grid2, null, value ? (React.createElement(Tooltip, { title: React.createElement(Typography, { variant: "body2" }, value), disableInteractive: true }, icon)) : (icon)),
                    React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, status)));
            },
        },
    ], [executionsByUid, filterBox, filterPipeline, filterTemplate, openCustomTemplateModal, templates]);
    const transactionsByUid = React.useMemo(() => {
        const res = {};
        for (const log of logs !== null && logs !== void 0 ? logs : []) {
            res[log.entity.uid] = log.entity;
        }
        return res;
    }, [logs]);
    if (searchLogsApi.error) {
        return React.createElement(CoreError, { error: searchLogsApi.error });
    }
    if (logs == null) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(StyledWrapper, { style: { height: "100%" } },
        React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", style: { width: "100%", height: "100%", overflow: "hidden" } },
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
                    React.createElement(Grid2, null,
                        React.createElement(Box, { p: 1 },
                            React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                                React.createElement(Grid2, null,
                                    React.createElement(IconButton, { size: "small", component: RouterLink, to: "/ms4/logs" },
                                        React.createElement(ArrowBack, { fontSize: "small" }))),
                                executionUid && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: "Single Execution", size: "small", onDelete: clearExecutionFilter }))),
                                status && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: status, size: "small", onDelete: clearStatusFilter }))),
                                pipelineFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: pipelineFilter, size: "small", onDelete: clearPipelineFilter }))),
                                templateFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    , { 
                                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                        label: (_z = (_y = templatesByUid[templateFilter]) === null || _y === void 0 ? void 0 : _y.name) !== null && _z !== void 0 ? _z : templateFilter, size: "small", onDelete: clearTemplateFilter }))),
                                runByAgentFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    , { 
                                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                        label: `Run by ${(_1 = (_0 = usersByUid[runByAgentFilter]) === null || _0 === void 0 ? void 0 : _0.name) !== null && _1 !== void 0 ? _1 : runByAgentFilter}`, size: "small", onDelete: clearRunByAgentFilter }))),
                                boxFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    , { 
                                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                        label: (_3 = (_2 = transactionsByUid[boxFilter]) === null || _2 === void 0 ? void 0 : _2.name) !== null && _3 !== void 0 ? _3 : boxFilter, size: "small", onDelete: clearBoxFilter }))),
                                modeFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: modeFilter, size: "small", onDelete: clearModeFilter }))),
                                testModeFilter != null && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: testModeFilter ? "Test Only" : "Live Only", size: "small", onDelete: clearTestModeFilter }))),
                                fromTimestampFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: `From ${convertUtcDateString(fromTimestampFilter, { format: "M/D/YYYY h:mm A" })}`, size: "small", onDelete: clearFromTimestampFilter }))),
                                toTimestampFilter && (React.createElement(Grid2, null,
                                    React.createElement(Chip, { label: `To ${convertUtcDateString(toTimestampFilter, { format: "M/D/YYYY h:mm A" })}`, size: "small", onDelete: clearToTimestampFilter }))),
                                React.createElement(Grid2, { style: { flex: 1 } }),
                                isRunning && (React.createElement(Grid2, null,
                                    React.createElement(CircularProgress, { variant: "indeterminate", color: "primary", size: 20 }))),
                                !canAutoRefresh && (React.createElement(Grid2, null,
                                    React.createElement(IconButton, { size: "small", onClick: onRefreshClicked, tabIndex: -1, disabled: searchLogsApi.isFetching || countLogsApi.isFetching },
                                        React.createElement(Refresh, { fontSize: "small" })))),
                                React.createElement(Grid2, null,
                                    React.createElement(CoreTextField
                                    /* @ts-expect-error */
                                    , { 
                                        /* @ts-expect-error */
                                        variant: "outlined", size: "small", style: { width: "200px" }, InputProps: {
                                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Search"),
                                        }, value: (_4 = query.search) !== null && _4 !== void 0 ? _4 : "", onTextChange: setSearch })),
                                React.createElement(Grid2, null,
                                    React.createElement(IconButton, { size: "small", onClick: toggleFilterSidebar, tabIndex: -1 },
                                        React.createElement(FilterList, { fontSize: "small" })))))),
                    React.createElement(Grid2, null, searchLogsApi.isFetching || countLogsApi.isFetching || canAutoRefresh ? (React.createElement(LinearProgress, { variant: "indeterminate" })) : (React.createElement(Divider, null))),
                    React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
                        logs.length === 0 && React.createElement(Typography, { align: "center" }, "No templates have been processed."),
                        logs.length > 0 && (React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                            React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnSorting: true, disableColumnResize: true, disableColumnMenu: true, hideFooterSelectedRowCount: true, density: "compact", pagination: count != null && rows.length < count, rowCount: count !== null && count !== void 0 ? count : undefined, paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], paginationModel: { page, pageSize: limit }, onPaginationModelChange: onPageChange, getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                                    toolbarColumns: "",
                                }, sx: {
                                    border: "none",
                                    ".MuiDataGrid-main": {
                                        border: "none",
                                    },
                                } })))))),
            isFilterSidebarOpen && (React.createElement(React.Fragment, null,
                React.createElement(Grid2, null,
                    React.createElement(Divider, { orientation: "vertical" })),
                React.createElement(Grid2, { style: { width: "300px" } },
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
                        React.createElement(Grid2, null,
                            React.createElement(Box, { p: 1 },
                                React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                                    React.createElement(Grid2, { style: { flex: 1 } },
                                        React.createElement(Typography, { variant: "h6" }, "Filters")),
                                    React.createElement(Grid2, null,
                                        React.createElement(IconButton, { size: "small", onClick: toggleFilterSidebar },
                                            React.createElement(Close, { fontSize: "small" })))))),
                        React.createElement(Grid2, null,
                            React.createElement(Divider, null)),
                        React.createElement(Grid2, null,
                            React.createElement(Box, { p: 1 },
                                React.createElement(Grid2, null,
                                    React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, select: true, slotProps: {
                                            select: {
                                                displayEmpty: true,
                                            },
                                            input: {
                                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Status"),
                                            },
                                        }, value: (_5 = query.status) !== null && _5 !== void 0 ? _5 : "", onChange: setStatus },
                                        React.createElement(MenuItem, { value: "" }, "Any"),
                                        React.createElement(MenuItem, { value: "Success" }, "Success"),
                                        React.createElement(MenuItem, { value: "Error" }, "Error"))),
                                React.createElement(Grid2, null,
                                    React.createElement(ModeFilter, { value: modeFilter, onChange: handleModeFilterChanged })),
                                React.createElement(Grid2, null,
                                    React.createElement(PipelineFilter, { value: pipelineFilter, onChange: handlePipelineFilterChanged, includeNotSpecifiedOption: false })),
                                React.createElement(Grid2, null,
                                    React.createElement(TemplateFilter, { value: templateFilter, onChange: handleTemplateFilterChanged, includeNotSpecifiedOption: false })),
                                React.createElement(Grid2, null,
                                    React.createElement(RunByAgentFilter, { value: runByAgentFilter, onChange: handleRunByAgentFilterChanged })),
                                React.createElement(Grid2, null,
                                    React.createElement(TestModeFilter, { value: testModeFilter, onChange: handleTestModeFilterChanged })),
                                React.createElement(Grid2, null,
                                    React.createElement(DateTimeFilter, { value: fromTimestampFilter, onChange: handleFromTimestampFilterChanged, CoreDateFieldProps: {
                                            slotProps: {
                                                input: { startAdornment: React.createElement(InputAdornment, { position: "start" }, "From") },
                                            },
                                        } })),
                                React.createElement(Grid2, null,
                                    React.createElement(DateTimeFilter, { value: toTimestampFilter, onChange: handleToTimestampFilterChanged, CoreDateFieldProps: {
                                            slotProps: {
                                                input: { startAdornment: React.createElement(InputAdornment, { position: "start" }, "To") },
                                            },
                                        } })),
                                React.createElement(Grid2, null,
                                    React.createElement(BoxFilter, { value: boxFilter, onChange: handleBoxFilterChanged })))))))))));
};
export default MailScheduleLogsTable;
