import * as React from "react";
import { Close, Event, PersonAdd, Save } from "@mui/icons-material";
import { Avatar, AvatarGroup, Button, Checkbox, Grid, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { format } from "timeago.js";
import DatePickerIconButton from "@app/common/DatePickerIconButton";
import UserSelectIconButton from "@app/common/UserSelectIconButton";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import useSession from "@app/hooks/useSession";
import { searchUsersBasic } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { getInitials } from "@app/util/Utils";
const defaultTask = {};
const TaskField = (props) => {
    var _a, _b, _c, _d, _e;
    const { onClose, onSave } = props;
    const session = useSession();
    const listUsersApi = usePostQuery(searchUsersBasic, { limit: 1000, disable_sort: true }, INFINITE_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const usersByUid = React.useMemo(() => {
        const ret = {};
        users === null || users === void 0 ? void 0 : users.forEach((user) => {
            ret[user.uid] = user;
        });
        return ret;
    }, [users]);
    const viewingAsUserUid = (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid;
    const containerRef = React.createRef();
    const inputRef = React.createRef();
    const [task, setTask] = React.useState(Object.assign(Object.assign({}, defaultTask), { assignee_uids: viewingAsUserUid ? [viewingAsUserUid] : undefined }));
    const handleFocus = React.useCallback(() => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [inputRef]);
    React.useEffect(() => {
        handleFocus();
        setTimeout(() => {
            document.addEventListener("keydown", onKeyDown, false);
        }, 1);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setDescription = React.useCallback((e) => {
        const val = e.target.value;
        setTask((prev) => (Object.assign(Object.assign({}, prev), { description: val === "" ? undefined : val })));
    }, [setTask]);
    const setIsCompleted = React.useCallback((_, checked) => {
        setTask((prev) => (Object.assign(Object.assign({}, prev), { is_completed: checked || undefined })));
    }, [setTask]);
    const setDueDate = React.useCallback((date) => {
        setTask((prev) => {
            var _a;
            return (Object.assign(Object.assign({}, prev), { due_date: (_a = date === null || date === void 0 ? void 0 : date.toISOString()) !== null && _a !== void 0 ? _a : undefined }));
        });
    }, [setTask]);
    const onAssigneeSelect = React.useCallback((val) => {
        setTask((prev) => (Object.assign(Object.assign({}, prev), { assignee_uids: val.map((user) => user.uid) })));
    }, []);
    const canSave = React.useMemo(() => {
        var _a;
        return !!((_a = task.description) === null || _a === void 0 ? void 0 : _a.trim());
    }, [task.description]);
    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);
    const save = React.useCallback(() => {
        onClose();
        onSave(task);
    }, [onClose, onSave, task]);
    const onKeyDown = React.useCallback((e) => {
        var _a, _b;
        if (!((_a = task.description) === null || _a === void 0 ? void 0 : _a.trim()) && e.key === "Escape") {
            onClose();
        }
        if (((_b = task.description) === null || _b === void 0 ? void 0 : _b.trim()) && e.key === "Enter") {
            save();
        }
    }, [task, onClose, save]);
    return (React.createElement(Grid, { ref: containerRef, container: true, spacing: 1, direction: "column" },
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, direction: "row", spacing: 1, alignItems: "center" },
                React.createElement(Grid, { item: true },
                    React.createElement(Checkbox, { color: "primary", checked: task.is_complete, onChange: setIsCompleted })),
                React.createElement(Grid, { item: true, style: { flex: 1 } },
                    React.createElement(TextField, { ref: inputRef, size: "small", fullWidth: true, value: (_d = task.description) !== null && _d !== void 0 ? _d : "", onChange: setDescription })),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, spacing: 0, alignItems: "center" },
                        task.due_date && (React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, format(task.due_date)))),
                        React.createElement(Grid, { item: true },
                            React.createElement(DatePickerIconButton, { IconButtonProps: {
                                    tabIndex: -1,
                                }, DateFieldPickerProps: {
                                    value: task.due_date ? dayjs.utc(task.due_date) : null,
                                    onChange: setDueDate,
                                    onClose: () => { },
                                    mode: "datetime",
                                } },
                                React.createElement(Event, null))))),
                React.createElement(Grid, { item: true },
                    React.createElement(UserSelectIconButton, { onSelect: onAssigneeSelect, selectedUserUids: (_e = task.assignee_uids) !== null && _e !== void 0 ? _e : [], IconButtonProps: { tabIndex: -1 } }, task.assignee_uids && task.assignee_uids.length > 0 ? (React.createElement(AvatarGroup, { max: 3, sx: { "& .MuiAvatar-root": { height: "22px", width: "22px", fontSize: "0.7rem" } } }, task.assignee_uids.map((assigneeUid) => {
                        var _a, _b, _c;
                        const user = usersByUid[assigneeUid];
                        return (React.createElement(Avatar, { key: assigneeUid, src: (_a = user === null || user === void 0 ? void 0 : user.icon_url) !== null && _a !== void 0 ? _a : undefined, alt: user === null || user === void 0 ? void 0 : user.name }, getInitials((_b = user === null || user === void 0 ? void 0 : user.first_name) !== null && _b !== void 0 ? _b : null, (_c = user === null || user === void 0 ? void 0 : user.last_name) !== null && _c !== void 0 ? _c : null) || "?"));
                    }))) : (React.createElement(Avatar, { src: undefined, sx: { height: "22px", width: "22px", fontSize: "0.7rem" } },
                        React.createElement(PersonAdd, { fontSize: "small" }))))))),
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, style: { flex: 1 } }),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { onClick: handleCancel, size: "small", style: {
                            marginRight: "1rem",
                        }, startIcon: React.createElement(Close, null), tabIndex: -1 }, "Cancel")),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { size: "small", color: "primary", onClick: save, disabled: !canSave, startIcon: React.createElement(Save, null), tabIndex: -1 }, "Save"))))));
};
export default TaskField;
