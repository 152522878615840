export const FIELDS_THAT_SHOULD_BE_UNIQUE_FOR_EACH_USER = [
    { fieldName: "Email" },
    { fieldName: "LinkedIN Link" },
    { fieldName: "Instagram" },
    { fieldName: "Facebook" },
    { fieldName: "Personal Email" },
    { fieldName: "NC RE License #" },
    { fieldName: "Streak API Key" },
    { fieldName: "Slack ID" },
    { fieldName: "Headshot" },
    { fieldName: "Canopy MLS ID#" },
    { fieldName: "Triad MLS ID#" },
    { fieldName: "NCRMLS ID#" },
    { fieldName: "Triangle MLS ID#" },
    { fieldName: "Zillow Agent ID" },
    { fieldName: "Zillow User ID" },
    { fieldName: "Zillow Partner ID" },
    { fieldName: "Phone", postprocessor: (value) => value === null || value === void 0 ? void 0 : value.replace(/\D/g, "") },
    { fieldName: "Twilio #", postprocessor: (value) => value === null || value === void 0 ? void 0 : value.replace(/\D/g, "") },
];
export const canOpenUserPage = (me, user, params) => {
    var _a, _b;
    const teams = params === null || params === void 0 ? void 0 : params.teams;
    if (me.uid === user.uid) {
        return true;
    }
    if (me.roles.includes("Superadmin")) {
        return true;
    }
    if (me.roles.includes("Admin")) {
        return true;
    }
    if (me.roles.includes("Managing Partner") && ((_a = me.office) === null || _a === void 0 ? void 0 : _a.name) === ((_b = user.office) === null || _b === void 0 ? void 0 : _b.name)) {
        return true;
    }
    console.log({ teams });
    if (teams === null || teams === void 0 ? void 0 : teams.some((t) => t.members.some((m) => m.user.uid === me.uid && ["Operating Partner", "Success Partner"].includes(m.role)) &&
        t.members.some((m) => m.user.uid === user.uid))) {
        return true;
    }
    return false;
};
