/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all templates
 */
export const listFeedbackRequestCadenceTemplates = (options) => {
    return axios.get(`/api/feedback_request_cadence`, options);
};
export const getListFeedbackRequestCadenceTemplatesQueryKey = () => {
    return [`/api/feedback_request_cadence`];
};
export const getListFeedbackRequestCadenceTemplatesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListFeedbackRequestCadenceTemplatesQueryKey();
    const queryFn = ({ signal }) => listFeedbackRequestCadenceTemplates(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all templates
 */
export function useListFeedbackRequestCadenceTemplates(options) {
    const queryOptions = getListFeedbackRequestCadenceTemplatesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a template
 */
export const getFeedbackRequestCadenceTemplate = (uid, options) => {
    return axios.get(`/api/feedback_request_cadence/${uid}`, options);
};
export const getGetFeedbackRequestCadenceTemplateQueryKey = (uid) => {
    return [`/api/feedback_request_cadence/${uid}`];
};
export const getGetFeedbackRequestCadenceTemplateQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetFeedbackRequestCadenceTemplateQueryKey(uid);
    const queryFn = ({ signal }) => getFeedbackRequestCadenceTemplate(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a template
 */
export function useGetFeedbackRequestCadenceTemplate(uid, options) {
    const queryOptions = getGetFeedbackRequestCadenceTemplateQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a template
 */
export const updateFeedbackRequestCadenceTemplate = (uid, feedbackRequestCadenceRequest, options) => {
    return axios.post(`/api/feedback_request_cadence/${uid}`, feedbackRequestCadenceRequest, options);
};
export const getUpdateFeedbackRequestCadenceTemplateMutationOptions = (options) => {
    const mutationKey = ['updateFeedbackRequestCadenceTemplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateFeedbackRequestCadenceTemplate(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a template
*/
export const useUpdateFeedbackRequestCadenceTemplate = (options) => {
    const mutationOptions = getUpdateFeedbackRequestCadenceTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
