/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List PSP Photos
 */
export const listPspPropertyPhotos = (params, options) => {
    return axios.get(`/api/psp_photos`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspPropertyPhotosQueryKey = (params) => {
    return [`/api/psp_photos`, ...(params ? [params] : [])];
};
export const getListPspPropertyPhotosQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspPropertyPhotosQueryKey(params);
    const queryFn = ({ signal }) => listPspPropertyPhotos(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSP Photos
 */
export function useListPspPropertyPhotos(params, options) {
    const queryOptions = getListPspPropertyPhotosQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a Photo
 */
export const createPspPropertyPhoto = (propertyPhotoUploadRequest, options) => {
    return axios.post(`/api/psp_photos`, propertyPhotoUploadRequest, options);
};
export const getCreatePspPropertyPhotoMutationOptions = (options) => {
    const mutationKey = ['createPspPropertyPhoto'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPspPropertyPhoto(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a Photo
*/
export const useCreatePspPropertyPhoto = (options) => {
    const mutationOptions = getCreatePspPropertyPhotoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a PSP Photo
*/
export const getPspPropertyPhoto = (uid, options) => {
    return axios.get(`/api/psp_photos/${uid}`, options);
};
export const getGetPspPropertyPhotoQueryKey = (uid) => {
    return [`/api/psp_photos/${uid}`];
};
export const getGetPspPropertyPhotoQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspPropertyPhotoQueryKey(uid);
    const queryFn = ({ signal }) => getPspPropertyPhoto(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP Photo
 */
export function useGetPspPropertyPhoto(uid, options) {
    const queryOptions = getGetPspPropertyPhotoQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Delete a Photo
 */
export const deletePspPropertyPhoto = (uid, options) => {
    return axios.delete(`/api/psp_photos/${uid}`, options);
};
export const getDeletePspPropertyPhotoMutationOptions = (options) => {
    const mutationKey = ['deletePspPropertyPhoto'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspPropertyPhoto(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a Photo
*/
export const useDeletePspPropertyPhoto = (options) => {
    const mutationOptions = getDeletePspPropertyPhotoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count PSP Photos
*/
export const countPspPropertyPhotos = (params, options) => {
    return axios.get(`/api/psp_photos/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountPspPropertyPhotosQueryKey = (params) => {
    return [`/api/psp_photos/count`, ...(params ? [params] : [])];
};
export const getCountPspPropertyPhotosQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountPspPropertyPhotosQueryKey(params);
    const queryFn = ({ signal }) => countPspPropertyPhotos(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count PSP Photos
 */
export function useCountPspPropertyPhotos(params, options) {
    const queryOptions = getCountPspPropertyPhotosQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download PSP Photos
 */
export const downloadPspPropertyPhotos = (params, options) => {
    return axios.get(`/api/psp_photos/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadPspPropertyPhotosQueryKey = (params) => {
    return [`/api/psp_photos/download`, ...(params ? [params] : [])];
};
export const getDownloadPspPropertyPhotosQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadPspPropertyPhotosQueryKey(params);
    const queryFn = ({ signal }) => downloadPspPropertyPhotos(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download PSP Photos
 */
export function useDownloadPspPropertyPhotos(params, options) {
    const queryOptions = getDownloadPspPropertyPhotosQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Reorder PSP Photos
 */
export const reorderPspPropertyPhotos = (multipleUIDRequest, options) => {
    return axios.post(`/api/psp_photos/reorder`, multipleUIDRequest, options);
};
export const getReorderPspPropertyPhotosMutationOptions = (options) => {
    const mutationKey = ['reorderPspPropertyPhotos'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderPspPropertyPhotos(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder PSP Photos
*/
export const useReorderPspPropertyPhotos = (options) => {
    const mutationOptions = getReorderPspPropertyPhotosMutationOptions(options);
    return useMutation(mutationOptions);
};
