import * as React from "react";
import dayjs from "dayjs";
import useSession from "@app/hooks/useSession";
import { useListAllSources } from "@app/orval/api/agent-dashboard";
import { useListOffices } from "@app/orval/api/offices";
import { useListStages } from "@app/orval/api/stages";
import { useListTeams } from "@app/orval/api/teams";
import { searchUsersBasic } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import usePersistedState from "./usePersistedState";
import usePostQuery from "./usePostQuery";
const defaultFromDate = dayjs().subtract(12, "months").format("YYYY-MM-DD");
const defaultToDate = dayjs().format("YYYY-MM-DD");
const AgentDashboardFiltersContext = React.createContext({
    leadCreationDateGTE: null,
    setLeadCreationDateGTE: () => { },
    leadCreationDateLTE: null,
    setLeadCreationDateLTE: () => { },
    effectiveDateGTE: null,
    setEffectiveDateGTE: () => { },
    effectiveDateLTE: null,
    setEffectiveDateLTE: () => { },
    closingDateGTE: null,
    setClosingDateGTE: () => { },
    closingDateLTE: null,
    setClosingDateLTE: () => { },
    fromDate: null,
    setFromDate: () => { },
    toDate: null,
    setToDate: () => { },
    selectedUserUids: new Set(),
    setSelectedUserUids: () => { },
    selectedOfficeUids: new Set(),
    setSelectedOfficeUids: () => { },
    selectedTeamUids: new Set(),
    setSelectedTeamUids: () => { },
    selectedStageUids: new Set(),
    setSelectedStageUids: () => { },
    selectedSources: new Set(),
    setSelectedSources: () => { },
    users: null,
    offices: null,
    teams: null,
    stages: null,
    sources: null,
    canAccessTeamsFilter: false,
    canAccessOfficeFilter: false,
    canAccessUsersFilter: false,
});
export const AgentDashboardFiltersContextProvider = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const session = useSession();
    const [leadCreationDateGTE, setLeadCreationDateGTE] = React.useState(null);
    const [leadCreationDateLTE, setLeadCreationDateLTE] = React.useState(null);
    const [effectiveDateGTE, setEffectiveDateGTE] = React.useState(null);
    const [effectiveDateLTE, setEffectiveDateLTE] = React.useState(null);
    const [closingDateGTE, setClosingDateGTE] = React.useState(null);
    const [closingDateLTE, setClosingDateLTE] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(defaultFromDate);
    const [toDate, setToDate] = React.useState(defaultToDate);
    const [selectedUserUids, setSelectedUserUids] = usePersistedState(new Set(), "agent_dashboard_filters.selected_user_uids", (value) => new Set(JSON.parse(value)));
    const [selectedOfficeUids, setSelectedOfficeUids] = usePersistedState(new Set(), "agent_dashboard_filters.selected_office_uids", (value) => new Set(JSON.parse(value)));
    const [selectedTeamUids, setSelectedTeamUids] = usePersistedState(new Set(), "agent_dashboard_filters.selected_team_uids", (value) => new Set(JSON.parse(value)));
    const [selectedStageUids, setSelectedStageUids] = usePersistedState(new Set(), "agent_dashboard_filters.selected_stage_uids", (value) => new Set(JSON.parse(value)));
    const [selectedSources, setSelectedSources] = usePersistedState(new Set(), "agent_dashboard_filters.selected_sources", (value) => new Set(JSON.parse(value)));
    const listUsersApi = usePostQuery(searchUsersBasic, { limit: 10000, is_in_my_purview: true, disable_sort: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listUsersApi.data]);
    const listOfficesApi = useListOffices(INFINITE_CACHE_PARAMS);
    const offices = (_b = (_a = listOfficesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listTeamsApi = useListTeams(INFINITE_CACHE_PARAMS);
    const teams = (_d = (_c = listTeamsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listSourcesApi = useListAllSources(INFINITE_CACHE_PARAMS);
    const sources = (_f = (_e = listSourcesApi.data) === null || _e === void 0 ? void 0 : _e.data.items) !== null && _f !== void 0 ? _f : null;
    const listStagesApi = useListStages({}, INFINITE_CACHE_PARAMS);
    const stages = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.type < b.type)
                return -1;
            if (a.type > b.type)
                return 1;
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        }).filter((stage) => stage.type === "Buyer Lead" ||
            stage.type === "Buyer Contract" ||
            stage.type === "Seller Lead" ||
            stage.type === "Listing").sort((a, b) => {
            if (a.type < b.type)
                return -1;
            if (a.type > b.type)
                return 1;
            if (a.sort_order < b.sort_order)
                return -1;
            if (a.sort_order > b.sort_order)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_g = listStagesApi.data) === null || _g === void 0 ? void 0 : _g.data]);
    const canAccessTeamsFilter = (_j = (_h = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _h === void 0 ? void 0 : _h.hasRole(["Superadmin", "Admin"])) !== null && _j !== void 0 ? _j : false;
    const canAccessOfficeFilter = (_l = (_k = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _k === void 0 ? void 0 : _k.hasRole(["Superadmin", "Admin"])) !== null && _l !== void 0 ? _l : false;
    const canAccessUsersFilter = !!(users && users.length > 1);
    const value = React.useMemo(() => {
        return {
            leadCreationDateGTE,
            setLeadCreationDateGTE,
            leadCreationDateLTE,
            setLeadCreationDateLTE,
            effectiveDateGTE,
            setEffectiveDateGTE,
            effectiveDateLTE,
            setEffectiveDateLTE,
            closingDateGTE,
            setClosingDateGTE,
            closingDateLTE,
            setClosingDateLTE,
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            selectedUserUids,
            setSelectedUserUids,
            selectedOfficeUids,
            setSelectedOfficeUids,
            selectedTeamUids,
            setSelectedTeamUids,
            selectedStageUids,
            setSelectedStageUids,
            selectedSources,
            setSelectedSources,
            users,
            teams,
            offices,
            stages,
            sources,
            canAccessTeamsFilter,
            canAccessOfficeFilter,
            canAccessUsersFilter,
        };
    }, [
        leadCreationDateGTE,
        setLeadCreationDateGTE,
        leadCreationDateLTE,
        setLeadCreationDateLTE,
        effectiveDateGTE,
        setEffectiveDateGTE,
        effectiveDateLTE,
        setEffectiveDateLTE,
        closingDateGTE,
        setClosingDateGTE,
        closingDateLTE,
        setClosingDateLTE,
        fromDate,
        toDate,
        selectedUserUids,
        setSelectedUserUids,
        selectedOfficeUids,
        setSelectedOfficeUids,
        selectedTeamUids,
        setSelectedTeamUids,
        selectedStageUids,
        setSelectedStageUids,
        selectedSources,
        setSelectedSources,
        users,
        teams,
        offices,
        stages,
        sources,
        canAccessTeamsFilter,
        canAccessOfficeFilter,
        canAccessUsersFilter,
    ]);
    return React.createElement(AgentDashboardFiltersContext.Provider, { value: value }, props.children);
};
const useAgentDashboardFilters = () => {
    const { canAccessOfficeFilter, canAccessTeamsFilter, canAccessUsersFilter, closingDateGTE, closingDateLTE, effectiveDateGTE, effectiveDateLTE, fromDate, leadCreationDateGTE, leadCreationDateLTE, offices, selectedOfficeUids, selectedSources, selectedStageUids, selectedTeamUids, selectedUserUids, setClosingDateGTE, setClosingDateLTE, setEffectiveDateGTE, setEffectiveDateLTE, setFromDate, setLeadCreationDateGTE, setLeadCreationDateLTE, setSelectedOfficeUids, setSelectedSources, setSelectedStageUids, setSelectedTeamUids, setSelectedUserUids, setToDate, sources, stages, teams, toDate, users, } = React.useContext(AgentDashboardFiltersContext);
    return {
        leadCreationDateGTE,
        leadCreationDateLTE,
        effectiveDateGTE,
        effectiveDateLTE,
        closingDateGTE,
        closingDateLTE,
        setLeadCreationDateGTE,
        setLeadCreationDateLTE,
        setEffectiveDateGTE,
        setEffectiveDateLTE,
        setClosingDateGTE,
        setClosingDateLTE,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        selectedUserUids,
        setSelectedUserUids,
        selectedOfficeUids,
        setSelectedOfficeUids,
        selectedTeamUids,
        setSelectedTeamUids,
        selectedStageUids,
        setSelectedStageUids,
        selectedSources,
        setSelectedSources,
        users,
        offices,
        teams,
        stages,
        sources,
        canAccessOfficeFilter,
        canAccessTeamsFilter,
        canAccessUsersFilter,
    };
};
export default useAgentDashboardFilters;
