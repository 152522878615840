import * as React from "react";
import { isChromeExtension } from "@app/util/Utils";
/**
 * An anchor component that works in an isolated web app
 * as well as a Chrome extension, where we can't open things in a new page
 *
 * @param props
 * @returns
 */
const CoreAnchor = (props) => {
    return (React.createElement("a", Object.assign({}, props, { target: isChromeExtension ? "_blank" : props.target }), props.children));
};
export default CoreAnchor;
