import * as React from "react";
import { Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { Formatters } from "./utils";
const StyledTable = styled(Table) `
    & td {
        padding: 0.125rem !important;
        font-size: 0.65rem;
        line-height: 0 !important;
    }
`;
const MailScheduleFormattersHelper = () => (React.createElement(React.Fragment, null,
    React.createElement(Typography, { variant: "caption", display: "block" }, "The following formatters are also valid:"),
    React.createElement(StyledTable, { size: "small" },
        React.createElement(TableBody, null, Object.keys(Formatters).map((key) => (React.createElement(TableRow, { key: key },
            React.createElement(TableCell, { style: { fontWeight: "bold" } }, Formatters[key].name),
            React.createElement(TableCell, null,
                React.createElement(ReactMarkdown, null, Formatters[key].description)))))))));
export default MailScheduleFormattersHelper;
