import * as React from "react";
import { Avatar, Box, Grid2, Typography } from "@mui/material";
import logo from "@app/assets/img/streak.png";
import dayjs_ from "@app/common/dayjs";
import { useListStreakUsers } from "@app/orval/api/streak-users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const StreakCommentItem = (props) => {
    var _a, _b, _c;
    const { comment } = props;
    const listStreakUsersApi = useListStreakUsers(INFINITE_CACHE_PARAMS);
    const streakUsers = (_b = (_a = listStreakUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const streakUser = streakUsers === null || streakUsers === void 0 ? void 0 : streakUsers.find((x) => x.userKey === comment.creator_key);
    const message = comment.message.replace(/\@\[(.*?)\]\((.*?)\)/g, "@$1");
    return (React.createElement(Grid2, { container: true, spacing: 8, wrap: "nowrap" },
        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement("img", { src: logo, alt: "logo", width: "24px", height: "24px" })),
        React.createElement(Grid2, { style: { flex: 1 } },
            React.createElement(Box, { p: 1, style: { border: "1px solid #888", borderRadius: "4px", background: "#ffefc2" } },
                React.createElement(Grid2, { container: true, wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(Avatar, { src: streakUser === null || streakUser === void 0 ? void 0 : streakUser.image, style: { height: "28px", width: "28px", fontSize: "0.7rem" } }, (_c = streakUser === null || streakUser === void 0 ? void 0 : streakUser.displayName) !== null && _c !== void 0 ? _c : "?")),
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Typography, { variant: "body2" }, message)),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption" }, dayjs_(comment.timestamp).format("M/D/YYYY h:mm A"))))))));
};
export default StreakCommentItem;
