import * as React from "react";
import { StaticDatePicker, StaticDateTimePicker, StaticTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { variant } from "@app/util/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);
const CoreDateFieldPicker = (props) => {
    const { mode, onChange, onClose, value } = props;
    if (mode === "date") {
        return (React.createElement(StaticDatePicker, { value: value === null || value === void 0 ? void 0 : value.tz("America/New_York"), onAccept: onChange, onClose: onClose, slotProps: {
                actionBar: {
                    style: variant === "sidebar"
                        ? {
                            justifyContent: "flex-start",
                        }
                        : undefined,
                },
            } }));
    }
    if (mode === "time") {
        return (React.createElement(StaticTimePicker, { value: value, onAccept: onChange, onClose: onClose, slotProps: {
                actionBar: {
                    style: variant === "sidebar"
                        ? {
                            justifyContent: "flex-start",
                        }
                        : undefined,
                },
            } }));
    }
    if (mode === "datetime") {
        return (React.createElement(StaticDateTimePicker, { value: value === null || value === void 0 ? void 0 : value.tz("America/New_York"), onAccept: onChange, onClose: onClose, slotProps: {
                actionBar: {
                    style: variant === "sidebar"
                        ? {
                            justifyContent: "flex-start",
                        }
                        : undefined,
                },
            } }));
    }
    throw new Error("Invalid type");
};
export default CoreDateFieldPicker;
