import * as React from "react";
import { Delete, KeyboardArrowLeft, KeyboardArrowRight, Link } from "@mui/icons-material";
import { Chip, Grid, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router";
import RouterLink from "@app/common/RouterLink";
import { copyToClipboard } from "@app/util/Utils";
import ForwardBackNavigation from "./ForwardBackNavigation";
const Header = (props) => {
    const { children, createdAt, deleteVerbiage, leadSource, leadType, moduleName, name, onDelete, pipelineUrl, referredFrom, showCopyLinkButton, } = props;
    const location = useLocation();
    const copyLinkToClipboard = React.useCallback(() => {
        copyToClipboard(window.location.href);
        enqueueSnackbar("Link copied to clipboard", { variant: "success" });
    }, []);
    const theme = useTheme();
    const isSmallDevice = !useMediaQuery(theme.breakpoints.up("lg"));
    const isExtraSmallDevice = !useMediaQuery(theme.breakpoints.up("sm"));
    const allBoxPaths = (location.state ? location.state["allBoxPaths"] : null);
    return (React.createElement(Grid, { container: true, spacing: 1, alignItems: "center", flexWrap: "nowrap" },
        referredFrom && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { component: RouterLink, to: referredFrom, tabIndex: -1 },
                React.createElement(KeyboardArrowLeft, null)))),
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, pipelineUrl ? (React.createElement(RouterLink, { to: pipelineUrl },
            React.createElement(Typography, { variant: isSmallDevice ? "body1" : "h6" }, moduleName))) : (React.createElement(Typography, { variant: isSmallDevice ? "body1" : "h6" }, moduleName))),
        !isExtraSmallDevice && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(KeyboardArrowRight, { fontSize: isSmallDevice ? "small" : "large" })),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: isSmallDevice ? "body1" : "h6" }, name)))),
        !isExtraSmallDevice && leadSource && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", paddingLeft: "1rem" } },
            React.createElement(Chip, { label: leadSource, color: "primary", size: "small" }))),
        !isExtraSmallDevice && leadType && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Chip, { label: leadType, size: "small" }))),
        !isExtraSmallDevice && createdAt && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Chip, { label: `Added ${dayjs(createdAt).format("dddd, MMMM D, YYYY")}`, size: "small" }))),
        React.createElement(Grid, { item: true, style: { flex: 1 } }),
        children && React.createElement(Grid, { item: true }, children),
        showCopyLinkButton !== false && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { onClick: copyLinkToClipboard },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Copy link URL") },
                    React.createElement(Link, null))))),
        onDelete && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { onClick: onDelete },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, deleteVerbiage || "Delete") },
                    React.createElement(Delete, null))))),
        allBoxPaths && allBoxPaths.length > 1 && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(ForwardBackNavigation, { paths: allBoxPaths })))));
};
export default Header;
