import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import dayjs_ from "@app/common/dayjs";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import useLoading from "@app/hooks/useLoading";
import useSession from "@app/hooks/useSession";
import { useGetListingsTowneMortgageData } from "@app/orval/api/towne-mortgage";
import { dateFieldFormatter, renderAddressCell } from "../utils";
const TowneMortgageListingsContent = () => {
    const [searchParams] = useSearchParams();
    const session = useSession();
    const fromDate = React.useMemo(() => {
        var _a;
        return (_a = searchParams.get("from_date")) !== null && _a !== void 0 ? _a : null;
    }, [searchParams]);
    const toDate = React.useMemo(() => {
        var _a;
        return (_a = searchParams.get("to_date")) !== null && _a !== void 0 ? _a : null;
    }, [searchParams]);
    const getTowneMortgageListingsApi = useGetListingsTowneMortgageData();
    const listings = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getTowneMortgageListingsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [getTowneMortgageListingsApi.data]);
    const listingsByUid = React.useMemo(() => {
        if (!listings) {
            return {};
        }
        return listings.reduce((acc, listing) => {
            acc[listing.uid] = listing;
            return acc;
        }, {});
    }, [listings]);
    const loading = useLoading({
        items: [
            {
                label: "Loading Towne Mortgage listings data...",
                queryResult: getTowneMortgageListingsApi,
            },
        ],
    });
    const listingsFiltered = React.useMemo(() => {
        if (!listings) {
            return null;
        }
        const fromDateAsDate = fromDate ? dayjs_(fromDate) : null;
        const toDateAsDate = toDate ? dayjs_(toDate) : null;
        return listings.filter((listing) => {
            const activeDate = listing.active_date ? dayjs_(listing.active_date) : null;
            if ((fromDateAsDate || toDateAsDate) && !activeDate) {
                return false;
            }
            if (fromDateAsDate && (activeDate === null || activeDate === void 0 ? void 0 : activeDate.isBefore(fromDateAsDate))) {
                return false;
            }
            if (toDateAsDate && (activeDate === null || activeDate === void 0 ? void 0 : activeDate.isAfter(toDateAsDate))) {
                return false;
            }
            return true;
        });
    }, [fromDate, listings, toDate]);
    const getStageSortComparator = React.useCallback((sortDirection) => {
        const modifier = sortDirection === "asc" ? 1 : -1;
        const handleStageSort = (_, __, c1, c2) => {
            var _a, _b;
            const row1 = listingsByUid[c1.id];
            const row2 = listingsByUid[c2.id];
            const stageOrder1 = (_a = row1 === null || row1 === void 0 ? void 0 : row1.status_order) !== null && _a !== void 0 ? _a : 0;
            const stageOrder2 = (_b = row2 === null || row2 === void 0 ? void 0 : row2.status_order) !== null && _b !== void 0 ? _b : 0;
            if (stageOrder1 < stageOrder2)
                return -1 * modifier;
            if (stageOrder1 > stageOrder2)
                return 1 * modifier;
            return 0;
        };
        return handleStageSort;
    }, [listingsByUid]);
    const rows = React.useMemo(() => {
        return (listingsFiltered !== null && listingsFiltered !== void 0 ? listingsFiltered : []).map((listing) => {
            return Object.assign({ id: listing.uid }, listing);
        });
    }, [listingsFiltered]);
    const columns = React.useMemo(() => {
        const columns_ = [
            {
                field: "status",
                headerName: "Status",
                getSortComparator: getStageSortComparator,
            },
            {
                field: "assigned_agent_name",
                headerName: "Assigned Agent",
                width: 175,
            },
            {
                field: "op_team",
                headerName: "OP Team",
            },
            {
                field: "address",
                headerName: "Address",
                width: 300,
                renderCell: (session === null || session === void 0 ? void 0 : session.viewingAsUser.isInternal) ? renderAddressCell : undefined,
            },
            {
                field: "active_date",
                headerName: "Active Date",
                valueFormatter: dateFieldFormatter,
            },
            {
                field: "closing_date",
                headerName: "Closing Date",
                valueFormatter: dateFieldFormatter,
            },
            {
                field: "mls_number",
                headerName: "MLS Number",
            },
            {
                field: "seller_name",
                headerName: "Seller Name",
            },
        ];
        return columns_;
    }, [getStageSortComparator, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    return (React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
        React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 30, pagination: true, paginationMode: "client", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], sortingMode: "client", disableRowSelectionOnClick: true, disableColumnMenu: true, disableColumnReorder: true, density: "compact", filterMode: "client" })));
};
export default TowneMortgageListingsContent;
