import * as React from "react";
import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import { useAddBuyerContract, useUpdateBuyerContract } from "@app/orval/api/buyer-contracts";
import { useUpdateBuyerLead } from "@app/orval/api/buyer-leads";
import { useUpdateRecruit } from "@app/orval/api/recruits";
import { useAddSellerContract, useUpdateSellerContract } from "@app/orval/api/seller-contracts";
import { useUpdateSellerLead } from "@app/orval/api/seller-leads";
import { useListStages } from "@app/orval/api/stages";
import { useUpdateUser } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import StageValidationError from "../components/box-view/StageValidationError";
import { STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS } from "../components/box-view/utils";
export const useStages = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const session = useSession();
    const updateSellerLeadApi = useUpdateSellerLead();
    const listSellerLeadStagesApi = useListStages({ type: "Seller Lead" }, REFETCH_CACHE_PARAMS);
    const sellerLeadStages = (_b = (_a = listSellerLeadStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const updateSellerContractApi = useUpdateSellerContract();
    const createSellerContractApi = useAddSellerContract();
    const listSellerContractStagesApi = useListStages({ type: "Listing" }, REFETCH_CACHE_PARAMS);
    const sellerContractStages = (_d = (_c = listSellerContractStagesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const updateBuyerLeadApi = useUpdateBuyerLead();
    const listBuyerLeadStagesApi = useListStages({ type: "Buyer Lead" }, REFETCH_CACHE_PARAMS);
    const buyerLeadStages = (_f = (_e = listBuyerLeadStagesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const updateBuyerContractApi = useUpdateBuyerContract();
    const createBuyerContractApi = useAddBuyerContract();
    const listBuyerContractStagesApi = useListStages({ type: "Buyer Contract" }, REFETCH_CACHE_PARAMS);
    const buyerContractStages = (_h = (_g = listBuyerContractStagesApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const updateRecruitApi = useUpdateRecruit();
    const listRecruitStagesApi = useListStages({ type: "Recruit" }, REFETCH_CACHE_PARAMS);
    const recruitStages = (_k = (_j = listRecruitStagesApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const updateUserApi = useUpdateUser();
    const listUserStagesApi = useListStages({ type: "User" }, REFETCH_CACHE_PARAMS);
    const userStages = (_l = listUserStagesApi.data) === null || _l === void 0 ? void 0 : _l.data;
    const terminatedStage = useMemo(() => {
        return userStages === null || userStages === void 0 ? void 0 : userStages.find((stage) => stage.name === "Terminated");
    }, [userStages]);
    const canOverrideStageValidation = (_m = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _m === void 0 ? void 0 : _m.hasRole(["Superadmin"]);
    // BUYER
    const createBuyerContract = React.useCallback((buyerLeadUid, options) => {
        createBuyerContractApi
            .mutateAsync({ data: { buyer_lead_uid: buyerLeadUid } })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onBuyerContractCreated) {
                options.onBuyerContractCreated(result);
            }
        })
            .catch(errorDialog);
    }, [createBuyerContractApi, errorDialog]);
    const setBuyerLeadStage = React.useCallback((buyerLeadUid, stageUid, options) => {
        var _a;
        const overrideValidator = (_a = options === null || options === void 0 ? void 0 : options.overrideValidator) !== null && _a !== void 0 ? _a : false;
        if (options === null || options === void 0 ? void 0 : options.onBeforeUpdate) {
            options.onBeforeUpdate(buyerLeadUid, stageUid);
        }
        updateBuyerLeadApi
            .mutateAsync({ uid: buyerLeadUid, data: { stage_uid: stageUid, override_validator: overrideValidator } })
            .then((result) => {
            var _a;
            if (options === null || options === void 0 ? void 0 : options.onBuyerLeadUpdated) {
                options.onBuyerLeadUpdated(result);
            }
            if (stageUid === ((_a = buyerLeadStages === null || buyerLeadStages === void 0 ? void 0 : buyerLeadStages.find((x) => x.name === "Under Contract")) === null || _a === void 0 ? void 0 : _a.uid)) {
                confirm({
                    title: "Congrats on getting this lead under contract!",
                    description: "Do you want to create a new contract for this lead? If one doesn't already exist, the answer is probably \"Yes\".",
                    confirmationText: "Yes, start a new contract",
                    cancellationText: "No",
                }).then((result) => {
                    if (result.confirmed) {
                        createBuyerContract(buyerLeadUid, { onBuyerContractCreated: options === null || options === void 0 ? void 0 : options.onBuyerContractCreated });
                    }
                });
            }
        })
            .catch((error) => {
            if (error.response && error.response.status === 400 && canOverrideStageValidation) {
                confirm(Object.assign(Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS), { description: React.createElement(StageValidationError, { error: error }), confirmationButtonProps: Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS.confirmationButtonProps) })).then((result) => {
                    if (result.confirmed) {
                        setBuyerLeadStage(buyerLeadUid, stageUid, Object.assign(Object.assign({}, options), { overrideValidator: true }));
                    }
                });
            }
            else {
                enqueueErrorSnackbar(error);
            }
            if (options === null || options === void 0 ? void 0 : options.onError) {
                options.onError(error);
            }
        });
    }, [buyerLeadStages, canOverrideStageValidation, confirm, createBuyerContract, updateBuyerLeadApi]);
    const setBuyerContractStage = useCallback((buyerContractUid, stageUid, options) => {
        var _a;
        const overrideValidator = (_a = options === null || options === void 0 ? void 0 : options.overrideValidator) !== null && _a !== void 0 ? _a : false;
        if (options === null || options === void 0 ? void 0 : options.onBeforeUpdate) {
            options.onBeforeUpdate(buyerContractUid, stageUid);
        }
        updateBuyerContractApi
            .mutateAsync({ uid: buyerContractUid, data: { stage_uid: stageUid, override_validator: overrideValidator } })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch((error) => {
            if (error.response && error.response.status === 400 && canOverrideStageValidation) {
                confirm(Object.assign(Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS), { description: React.createElement(StageValidationError, { error: error }), confirmationButtonProps: Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS.confirmationButtonProps) })).then((result) => {
                    if (result.confirmed) {
                        setBuyerContractStage(buyerContractUid, stageUid, Object.assign(Object.assign({}, options), { overrideValidator: true }));
                    }
                });
            }
            else {
                enqueueErrorSnackbar(error);
            }
            if (options === null || options === void 0 ? void 0 : options.onError) {
                options.onError(error);
            }
        });
    }, [canOverrideStageValidation, confirm, updateBuyerContractApi]);
    // SELLER
    const onSellerLeadStagesSaved = useCallback((allStages) => {
        queryClient.setQueryData(listSellerLeadStagesApi.queryKey, (prev) => {
            if (prev == null)
                return prev;
            return Object.assign(Object.assign({}, prev), { data: allStages });
        });
    }, [listSellerLeadStagesApi.queryKey, queryClient]);
    const createSellerContract = React.useCallback((sellerLeadUid, options) => {
        createSellerContractApi
            .mutateAsync({ data: { seller_lead_uid: sellerLeadUid } })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onSellerContractCreated) {
                options.onSellerContractCreated(result);
            }
        })
            .catch(errorDialog);
    }, [createSellerContractApi, errorDialog]);
    const setSellerLeadStage = useCallback((uid, stageUid, options) => {
        var _a;
        const overrideValidator = (_a = options === null || options === void 0 ? void 0 : options.overrideValidator) !== null && _a !== void 0 ? _a : false;
        if (options === null || options === void 0 ? void 0 : options.onUpdateBegin) {
            console.log("ON UPDATE BEGIN");
            options.onUpdateBegin(uid, stageUid);
        }
        updateSellerLeadApi
            .mutateAsync({ uid, data: { stage_uid: stageUid, override_validator: overrideValidator } })
            .then((updateResult) => {
            var _a;
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(updateResult);
            }
            if (stageUid === ((_a = sellerLeadStages === null || sellerLeadStages === void 0 ? void 0 : sellerLeadStages.find((stage) => stage.name === "Contract")) === null || _a === void 0 ? void 0 : _a.uid)) {
                confirm({
                    title: "Start a new contract?",
                    description: "Would you like to start a new contract for this seller lead? If one doesn't already exist, the answer is probably \"Yes\".",
                    confirmationText: "Yes, start new contract",
                    cancellationText: "No",
                }).then((result) => {
                    if (result.confirmed) {
                        createSellerContract(uid, { onSellerContractCreated: options === null || options === void 0 ? void 0 : options.onSellerContractCreated });
                    }
                });
            }
        })
            .catch((err) => {
            var _a, _b;
            if (err.response && err.response.status === 400 && ((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes("Superadmin"))) {
                confirm(Object.assign(Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS), { description: React.createElement(StageValidationError, { error: err }), confirmationButtonProps: {} })).then((result) => {
                    if (result.confirmed) {
                        setSellerLeadStage(uid, stageUid, Object.assign(Object.assign({}, options), { overrideValidator: true }));
                    }
                });
            }
            else {
                enqueueErrorSnackbar(err);
            }
            if (options === null || options === void 0 ? void 0 : options.onError) {
                options.onError(err);
            }
        });
    }, [confirm, createSellerContract, sellerLeadStages, (_o = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _o === void 0 ? void 0 : _o.roles, updateSellerLeadApi]);
    const setSellerContractStage = useCallback((uid, stageUid, options) => {
        var _a;
        const overrideValidator = (_a = options === null || options === void 0 ? void 0 : options.overrideValidator) !== null && _a !== void 0 ? _a : false;
        if (options === null || options === void 0 ? void 0 : options.onUpdateBegin) {
            options.onUpdateBegin(uid, stageUid);
        }
        updateSellerContractApi
            .mutateAsync({ uid, data: { stage_uid: stageUid, override_validator: overrideValidator } })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch((error) => {
            if (error.response && error.response.status === 400 && canOverrideStageValidation) {
                confirm(Object.assign(Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS), { description: React.createElement(StageValidationError, { error: error }), confirmationButtonProps: Object.assign({}, STAGE_CHANGE_ERROR_CONFIRM_DIALOG_PROPS.confirmationButtonProps) })).then((result) => {
                    if (result.confirmed) {
                        setSellerContractStage(uid, stageUid, Object.assign(Object.assign({}, options), { overrideValidator: true }));
                    }
                });
            }
            else {
                enqueueErrorSnackbar(error);
            }
            if (options === null || options === void 0 ? void 0 : options.onError) {
                options.onError(error);
            }
        });
    }, [canOverrideStageValidation, confirm, updateSellerContractApi]);
    // RECRUITING
    const setRecruitingStage = React.useCallback((uid, stageUid, onBeforeUpdate, onError, onUpdated) => {
        if (onBeforeUpdate) {
            onBeforeUpdate(uid, stageUid);
        }
        updateRecruitApi
            .mutateAsync({ uid, data: { stage_uid: stageUid } })
            .then((result) => {
            if (onUpdated) {
                onUpdated(result);
            }
        })
            .catch((error) => {
            if (error.response && error.response.status === 400) {
                confirm({
                    title: "Unable to change the stage",
                    description: String(error.response.data),
                    hideCancelButton: true,
                    confirmationText: "Ok",
                });
            }
            else {
                enqueueErrorSnackbar(error);
            }
            if (onError) {
                onError(error);
            }
        });
    }, [updateRecruitApi, confirm]);
    // USERS
    const setUserStage = React.useCallback((uid, stageUid, onTerminateUser, onBeforeUpdate, onError, onUpdated) => {
        if (stageUid === terminatedStage.uid) {
            if (onTerminateUser) {
                onTerminateUser();
            }
        }
        else {
            if (onBeforeUpdate) {
                onBeforeUpdate(uid, stageUid);
            }
            updateUserApi
                .mutateAsync({ uid, data: { stage_uid: stageUid } })
                .then((result) => {
                if (onUpdated) {
                    onUpdated(result);
                }
            })
                .catch((error) => {
                if (error.response && error.response.status === 400) {
                    confirm({
                        title: "Unable to change the stage",
                        description: String(error.response.data),
                        hideCancelButton: true,
                        confirmationText: "Ok",
                    });
                }
                else {
                    enqueueErrorSnackbar(error);
                }
                if (onError) {
                    onError(error);
                }
            });
        }
    }, [terminatedStage, updateUserApi, confirm]);
    return {
        setSellerLeadStage,
        sellerLeadStages,
        onSellerLeadStagesSaved,
        setSellerContractStage,
        createBuyerContract,
        setBuyerLeadStage,
        buyerLeadStages,
        setBuyerContractStage,
        setRecruitingStage,
        setUserStage,
        userStages,
        listBuyerLeadStagesApi,
        buyerContractStages,
        listBuyerContractStagesApi,
        sellerContractStages,
        listSellerContractStagesApi,
        listUserStagesApi,
        recruitStages,
        listRecruitStagesApi,
    };
};
export default useStages;
