import * as React from "react";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import QuestionsForTheOfferSubmissionsSidebar from "./QuestionsForTheOfferSubmissionsSidebar";
import QuestionsForTheOfferSubmissionsTable from "./QuestionsForTheOfferSubmissionsTable";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
const QuestionsForTheOfferSubmissionsWrapper = (props) => {
    const [showFilterSidebar, setShowFilterSidebar] = React.useState(!!props.showFilterSidebar);
    useDocumentTitle("QFO");
    const toggleFilterSidebar = React.useCallback(() => {
        setShowFilterSidebar(!showFilterSidebar);
    }, [showFilterSidebar]);
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(QuestionsForTheOfferSubmissionsTable, { onToggleSidebar: toggleFilterSidebar }), sidebar: React.createElement(QuestionsForTheOfferSubmissionsSidebar, { onToggleSidebar: toggleFilterSidebar }), sidebarWidth: 250, opened: showFilterSidebar, style: {
            overflowY: "hidden",
        } }));
};
export default QuestionsForTheOfferSubmissionsWrapper;
