var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { keepPreviousData } from "@tanstack/react-query";
import pickBy from "lodash/pickBy";
import { useListRequests } from "@app/orval/api/debugging";
export const requestMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"];
const DEFAULT_LIMIT = 100;
const DEFAULT_OFFSET = 0;
const sleep = (milliseconds) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
});
export const RequestDebuggingContext = React.createContext({
    fromDatetime: null,
    setFromDatetime: () => { },
    toDatetime: null,
    setToDatetime: () => { },
    userEmail: null,
    setUserEmail: () => { },
    search: null,
    setSearch: () => { },
    requestMethod: null,
    setRequestMethod: () => { },
    responseStatus: null,
    setResponseStatus: () => { },
    minRequestTimeSeconds: null,
    setMinRequestTimeSeconds: () => { },
    limit: DEFAULT_LIMIT,
    setLimit: () => { },
    offset: DEFAULT_OFFSET,
    setOffset: () => { },
    isFiltersOpen: true,
    setIsFiltersOpen: () => { },
    selectedRequest: null,
    setSelectedRequest: () => { },
    refresh: () => { },
    next: () => { },
    previous: () => { },
    searchRequestsApi: null,
    clearFilters: () => { },
});
const RequestDebuggingContextProvider = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { initialValues } = props;
    const [fromDatetime, setFromDatetime] = React.useState((_a = initialValues === null || initialValues === void 0 ? void 0 : initialValues.fromDatetime) !== null && _a !== void 0 ? _a : null);
    const [toDatetime, setToDatetime] = React.useState((_b = initialValues === null || initialValues === void 0 ? void 0 : initialValues.toDatetime) !== null && _b !== void 0 ? _b : null);
    const [userEmail, setUserEmail] = React.useState((_c = initialValues === null || initialValues === void 0 ? void 0 : initialValues.userEmail) !== null && _c !== void 0 ? _c : null);
    const [search, setSearch] = React.useState((_d = initialValues === null || initialValues === void 0 ? void 0 : initialValues.search) !== null && _d !== void 0 ? _d : null);
    const [requestMethod, setRequestMethod] = React.useState(null);
    const [responseStatus, setResponseStatus] = React.useState(null);
    const [minRequestTimeSeconds, setMinRequestTimeSeconds] = React.useState(null);
    const [limit, setLimit] = React.useState((_e = initialValues === null || initialValues === void 0 ? void 0 : initialValues.limit) !== null && _e !== void 0 ? _e : DEFAULT_LIMIT);
    const [offset, setOffset] = React.useState((_f = initialValues === null || initialValues === void 0 ? void 0 : initialValues.offset) !== null && _f !== void 0 ? _f : DEFAULT_OFFSET);
    const [isFiltersOpen, setIsFiltersOpen] = React.useState(true);
    const [selectedRequest, setSelectedRequest] = React.useState(null);
    const query = React.useMemo(() => {
        return pickBy({
            from_datetime: fromDatetime,
            to_datetime: toDatetime,
            user_email: userEmail,
            search,
            request_method: requestMethod,
            response_status: responseStatus,
            min_request_time_seconds: minRequestTimeSeconds,
            limit,
            offset,
        }, (val) => val != null);
    }, [
        fromDatetime,
        toDatetime,
        userEmail,
        search,
        requestMethod,
        responseStatus,
        minRequestTimeSeconds,
        limit,
        offset,
    ]);
    const searchApi = useListRequests(query, { query: { enabled: false, placeholderData: keepPreviousData } });
    const refresh = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setOffset(0);
        yield sleep(10);
        yield searchApi.refetch();
    }), [searchApi]);
    const next = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setOffset((prev) => prev + limit);
        yield sleep(10);
        yield searchApi.refetch();
    }), [limit, searchApi]);
    const previous = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setOffset((prev) => Math.max(prev - limit, 0));
        yield sleep(10);
        yield searchApi.refetch();
    }), [limit, searchApi]);
    React.useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const clearFilters = React.useCallback(() => {
        setFromDatetime(null);
        setToDatetime(null);
        setUserEmail(null);
        setSearch(null);
        setRequestMethod(null);
        setResponseStatus(null);
        setLimit(DEFAULT_LIMIT);
        setOffset(DEFAULT_OFFSET);
    }, []);
    const value = React.useMemo(() => {
        return {
            fromDatetime,
            setFromDatetime,
            toDatetime,
            setToDatetime,
            userEmail,
            setUserEmail,
            search,
            setSearch,
            requestMethod,
            setRequestMethod,
            responseStatus,
            setResponseStatus,
            minRequestTimeSeconds,
            setMinRequestTimeSeconds,
            limit,
            setLimit,
            offset,
            setOffset,
            isFiltersOpen,
            setIsFiltersOpen,
            selectedRequest,
            setSelectedRequest,
            refresh,
            next,
            previous,
            searchRequestsApi: searchApi,
            clearFilters,
        };
    }, [
        fromDatetime,
        setFromDatetime,
        toDatetime,
        setToDatetime,
        userEmail,
        setUserEmail,
        search,
        setSearch,
        requestMethod,
        setRequestMethod,
        responseStatus,
        setResponseStatus,
        minRequestTimeSeconds,
        setMinRequestTimeSeconds,
        limit,
        setLimit,
        offset,
        setOffset,
        isFiltersOpen,
        setIsFiltersOpen,
        selectedRequest,
        setSelectedRequest,
        refresh,
        next,
        previous,
        searchApi,
        clearFilters,
    ]);
    return React.createElement(RequestDebuggingContext.Provider, { value: value }, props.children);
};
export default RequestDebuggingContextProvider;
