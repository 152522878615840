import * as React from "react";
import { Check, Code, PriorityHigh, KeyboardArrowDown, KeyboardArrowRight, Phone } from "@mui/icons-material";
import { Avatar, CircularProgress, Grid, IconButton, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Typography, } from "@mui/material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import InfiniteScroll from "react-infinite-scroller";
import twilioIcon from "@app/assets/img/twilio.png";
import User from "@app/classes/user";
import CoreError from "@app/common/CoreError";
import CoreLink from "@app/common/CoreLink";
import dayjs_ from "@app/common/dayjs";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import AppManager from "@app/util/AppManager";
import { formatPhoneNumber } from "@app/util/Utils";
class Calls extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                calls: null,
                phones: null,
                users: null,
                error: null,
                isShowingDetails: new Set(),
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (startTime) => {
                var _a;
                const { phones, users } = this.state;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                if (!phones) {
                    API.twilioPhoneNumbers.list({
                        onSuccess: (result) => {
                            this.setState({
                                phones: result.reduce((obj, item) => (Object.assign(Object.assign({}, obj), { [item.phone_number]: item.friendly_name })), {}),
                            });
                        },
                        onError: (error) => {
                            this.setState({
                                error,
                            });
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                }
                if (!users) {
                    API.users.list({
                        onSuccess: (result) => {
                            this.setState({
                                users: result
                                    .filter((user) => user.phone)
                                    .reduce((obj, user) => {
                                    var _a;
                                    const formattedNumber = (_a = (user.phone && formatPhoneNumber(user.phone, { format: "national" }))) !== null && _a !== void 0 ? _a : "";
                                    return Object.assign(Object.assign({}, obj), { [formattedNumber]: user });
                                }, {}),
                            });
                        },
                    });
                }
                API.twilioCalls.list({
                    query: pickBy({
                        start_time_before: startTime ? dayjs_(startTime).format("YYYY-MM-DDThh:mm:ss+00:00") : undefined,
                        inbound_only: true,
                        limit: 25,
                        include_call_events: true,
                    }, (val) => val !== undefined),
                    onSuccess: (result) => {
                        this.setState((prevState) => {
                            var _a;
                            return ({
                                calls: [...((_a = prevState.calls) !== null && _a !== void 0 ? _a : []), ...result],
                            });
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                });
            }
        });
        Object.defineProperty(this, "getNextBatch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { calls } = this.state;
                this.refresh((calls !== null && calls !== void 0 ? calls : []).slice(-1)[0].date_created);
            }
        });
        Object.defineProperty(this, "viewJSON", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => () => {
                const call = this.state.calls[indx];
                const tab = window.open("about:blank", "_blank");
                tab.document.write(`<pre>${JSON.stringify(call, null, 2)}</pre>`);
                tab.document.close();
            }
        });
        Object.defineProperty(this, "toggleDetails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (callSid) => () => {
                this.setState((prevState) => {
                    const { isShowingDetails } = prevState;
                    if (isShowingDetails.has(callSid)) {
                        isShowingDetails.delete(callSid);
                    }
                    else {
                        isShowingDetails.add(callSid);
                    }
                    return {
                        isShowingDetails,
                    };
                });
            }
        });
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "Twilio", subtitle: "Calls" };
        this.refresh();
    }
    componentWillUnmount() {
        var _a;
        AppManager.shared.pageTitle = {};
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    render() {
        const { calls, error, phones, users } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!calls || !phones || !users) {
            return (React.createElement("div", { style: { textAlign: "center", padding: "1rem" } },
                React.createElement(CircularProgress, null)));
        }
        return (React.createElement(InfiniteScroll, { hasMore: true, loader: React.createElement("div", { style: { textAlign: "center" } },
                React.createElement(CircularProgress, null)), loadMore: this.getNextBatch, pageStart: 0, useWindow: false },
            React.createElement(Table, { size: "small", stickyHeader: true },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null),
                        React.createElement(TableCell, null, "Timestamp"),
                        React.createElement(TableCell, null, "From"),
                        React.createElement(TableCell, null, "To"),
                        React.createElement(TableCell, null, "Forwarded To"),
                        React.createElement(TableCell, null, "Connected With"),
                        React.createElement(TableCell, null, "Duration"),
                        React.createElement(TableCell, null, "Cost"))),
                React.createElement(TableBody, null, calls.map((call, indx) => {
                    var _a;
                    const connection = call.prompted
                        .map((item) => (Object.assign(Object.assign({}, item), { action: "prompted" })))
                        .concat(call.dialed.map((item) => (Object.assign(Object.assign({}, item), { action: "dialed" }))))
                        .filter((item) => {
                        var _a, _b;
                        const connected = !!((_a = item.events) === null || _a === void 0 ? void 0 : _a.find((e) => e.request.url.includes("/join_conference/"))) &&
                            ((_b = item.conferences) !== null && _b !== void 0 ? _b : []).length > 0;
                        const dialed = item.action === "dialed";
                        return connected || dialed;
                    })
                        .sort((a, b) => {
                        var _a, _b, _c, _d;
                        if (((_a = a.duration) !== null && _a !== void 0 ? _a : 0) > ((_b = b.duration) !== null && _b !== void 0 ? _b : 0))
                            return -1;
                        if (((_c = a.duration) !== null && _c !== void 0 ? _c : 0) < ((_d = b.duration) !== null && _d !== void 0 ? _d : 0))
                            return 1;
                        return 0;
                    })[0];
                    let connectedWith;
                    let missed = false;
                    if (connection) {
                        const formattedNumber = formatPhoneNumber(connection.to_formatted, { format: "national" });
                        const connectedUser = formattedNumber && users[formattedNumber] ? new User(users[formattedNumber]) : null;
                        if (connectedUser) {
                            connectedWith = connectedUser.name;
                        }
                        else {
                            connectedWith = connection.to_formatted;
                        }
                    }
                    else {
                        connectedWith = ((_a = call.events) === null || _a === void 0 ? void 0 : _a.find((e) => e.response.response_code >= 400))
                            ? "API errored out"
                            : "Did not connect";
                        missed = !calls.find((_call, _indx) => _indx < indx && _call.from === call.from && _call.to === call.to);
                    }
                    return (React.createElement(React.Fragment, { key: call.sid },
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { style: { whiteSpace: "nowrap" } },
                                React.createElement(Tooltip, { title: "Call Details" },
                                    React.createElement(IconButton, { size: "small", onClick: this.toggleDetails(call.sid) }, this.state.isShowingDetails.has(call.sid) ? (React.createElement(KeyboardArrowDown, { fontSize: "small" })) : (React.createElement(KeyboardArrowRight, { fontSize: "small" })))),
                                React.createElement(Tooltip, { title: "Open Twilio Log" },
                                    React.createElement(IconButton, { size: "small", component: CoreLink, href: `https://www.twilio.com/console/voice/calls/logs/${call.sid}`, target: "_blank" },
                                        React.createElement("img", { src: twilioIcon, alt: "Twilio", height: "22" }))),
                                React.createElement(Tooltip, { title: "View JSON" },
                                    React.createElement(IconButton, { size: "small", onClick: this.viewJSON(indx) },
                                        React.createElement(Code, { fontSize: "small" })))),
                            React.createElement(TableCell, null, call.start_time && dayjs_(new Date(call.start_time)).format("dddd, MMM D, YYYY h:mm A")),
                            React.createElement(TableCell, null, [call.caller_name, call.from_formatted].filter((obj) => obj).join(" ")),
                            React.createElement(TableCell, null, [phones[call.to], call.to_formatted].filter((obj) => obj).join(" ")),
                            React.createElement(TableCell, null, (call.prompted.length > 0 || call.dialed.length > 0) &&
                                `${call.prompted.length + call.dialed.length} users`),
                            React.createElement(TableCell, null,
                                React.createElement(Grid, { container: true, spacing: 1 },
                                    missed && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                        React.createElement(PriorityHigh, { fontSize: "small", style: { color: "red" } }))),
                                    React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", flex: 1 } }, connectedWith))),
                            React.createElement(TableCell, null, call.duration
                                ? `${Math.floor(numeral(call.duration).value() / 60)} min ${numeral(call.duration).value() % 60} sec`
                                : ""),
                            React.createElement(TableCell, null, numeral(Math.abs(numeral(call.price).value() +
                                call.prompted.reduce((total, item) => total + numeral(item.price).value(), 0) +
                                call.dialed.reduce((total, item) => total + numeral(item.price).value(), 0))).format("$0,0.00"))),
                        this.state.isShowingDetails.has(call.sid) && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 8 },
                                React.createElement(Grid, { container: true, spacing: 1 }, call.prompted
                                    .map((item) => (Object.assign(Object.assign({}, item), { action: "prompted" })))
                                    .concat(call.dialed.map((item) => (Object.assign(Object.assign({}, item), { action: "dialed" }))))
                                    .sort((a, b) => {
                                    const aFormatted = a.to_formatted;
                                    const bFormatted = b.to_formatted;
                                    const aUser = aFormatted && users[aFormatted] ? new User(users[aFormatted]) : null;
                                    const bUser = bFormatted && users[bFormatted] ? new User(users[bFormatted]) : null;
                                    if (!!(aUser === null || aUser === void 0 ? void 0 : aUser.name) && !(bUser === null || bUser === void 0 ? void 0 : bUser.name))
                                        return -1;
                                    if (!(aUser === null || aUser === void 0 ? void 0 : aUser.name) && !!(bUser === null || bUser === void 0 ? void 0 : bUser.name))
                                        return 1;
                                    if (!(aUser === null || aUser === void 0 ? void 0 : aUser.name) && !(bUser === null || bUser === void 0 ? void 0 : bUser.name))
                                        return 0;
                                    if ((aUser === null || aUser === void 0 ? void 0 : aUser.name) < (bUser === null || bUser === void 0 ? void 0 : bUser.name))
                                        return -1;
                                    if ((aUser === null || aUser === void 0 ? void 0 : aUser.name) > (bUser === null || bUser === void 0 ? void 0 : bUser.name))
                                        return 1;
                                    return 0;
                                })
                                    .map((item) => {
                                    var _a, _b, _c, _d;
                                    const formattedNumber = formatPhoneNumber(item.to_formatted, { format: "national" });
                                    const user = formattedNumber && users[formattedNumber] ? new User(users[formattedNumber]) : null;
                                    const pressedY = !!((_a = item.events) === null || _a === void 0 ? void 0 : _a.find((e) => e.request.url.includes("/press_one_to_accept_response/")));
                                    const connected = !!((_b = item.events) === null || _b === void 0 ? void 0 : _b.find((e) => e.request.url.includes("/join_conference/")));
                                    const dialed = item.action === "dialed";
                                    return (React.createElement(Grid, { key: item.to, container: true, item: true, xs: 12, spacing: 1 },
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Avatar, { src: (_c = user === null || user === void 0 ? void 0 : user.icon_url) !== null && _c !== void 0 ? _c : undefined, style: { height: "22px", width: "22px", fontSize: "0.7rem" } }, (user === null || user === void 0 ? void 0 : user.initials) || "?")),
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, "\u2022"),
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Typography, { variant: "body2" }, (_d = user === null || user === void 0 ? void 0 : user.name) !== null && _d !== void 0 ? _d : "Unknown User")),
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, "\u2022"),
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Typography, { variant: "body2" }, item.to_formatted)),
                                        pressedY && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Typography, { style: { fontWeight: "bold", color: AppConfig.themeColors.primary } }, "Y"))),
                                        connected && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Check, { style: { color: AppConfig.themeColors.primary } }))),
                                        dialed && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Phone, { style: { color: AppConfig.themeColors.primary } }))),
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, "\u2022"),
                                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(Typography, { variant: "caption", style: { color: "#888" } }, numeral(Math.abs(numeral(item.price).value())).format("$0,0.0000")))));
                                })))))));
                })))));
    }
}
export default Calls;
