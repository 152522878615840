import useSession from "@app/hooks/useSession";
import { useGetUser } from "@app/orval/api/users";
// https://dashcarolina.atlassian.net/browse/DC-343?atlOrigin=eyJpIjoiYTdlZDdiNWNiYjI2NGE4YmEwOGNiNzQ5MTUyM2I3ZjUiLCJwIjoiaiJ9
/**
 * Returns `true` if the current user is in Onboarding | Phase 2
 * OR the current user has the #FishingPond tag`
 * and the lead in question is a Fishing Pond lead.
 * @param buyerLead
 * @returns
 */
const useCanAssignSelf = (buyerLead) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const session = useSession();
    const getMeApi = useGetUser((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : "", { query: { enabled: !!((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid) } });
    const me = (_e = (_d = getMeApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    if (!me) {
        return false;
    }
    if (!buyerLead) {
        return false;
    }
    return (((_f = buyerLead.stage) === null || _f === void 0 ? void 0 : _f.name) === "Fishing Pond" &&
        (me.stage.name === "Onboarding | Phase 2" || ((_g = me.fields["Tags"]) === null || _g === void 0 ? void 0 : _g.includes("#FishingPond"))));
};
export default useCanAssignSelf;
