import * as React from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useGetBoxUrl } from "@app/orval/api/redirects";
const BoxRedirectPage = () => {
    var _a, _b;
    const params = useParams();
    const [searchParams, _] = useSearchParams();
    const navigate = useNavigate();
    const { uid } = params;
    const getBoxUrlApi = useGetBoxUrl(uid);
    const boxUrl = (_b = (_a = getBoxUrlApi.data) === null || _a === void 0 ? void 0 : _a.data.url) !== null && _b !== void 0 ? _b : null;
    useEffect(() => {
        if (boxUrl) {
            const url = boxUrl + (searchParams.toString() ? `?${searchParams.toString()}` : "");
            navigate(url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxUrl, searchParams]);
    return (React.createElement(Container, { maxWidth: "md" },
        React.createElement(Box, { p: 4 },
            React.createElement(Typography, { variant: "body1", gutterBottom: true, align: "center" }, "Loading box info..."))));
};
export default BoxRedirectPage;
