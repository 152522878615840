import * as React from "react";
import { Add, Person } from "@mui/icons-material";
import { IconButton, List, Tooltip, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import CollaboratorDialog from "./CollaboratorDialog";
import CollaboratorListItem from "./CollaboratorListItem";
import CollapsibleSection from "../Contact/CollapsibleSection";
const Collaborators = (props) => {
    var _a;
    const { canAssignSelfOnly, collaborators, onCreateCollaborator, onRemoveCollaborator, onUpdateCollaborator } = props;
    const [isCollaboratorDialogOpen, setIsCollaboratorDialogOpen] = React.useState(false);
    const [editingCollaboratorUid, setEditingCollaboratorUid] = React.useState(null);
    const openCollaboratorDialog = React.useCallback(() => {
        setIsCollaboratorDialogOpen(true);
    }, [setIsCollaboratorDialogOpen]);
    const closeCollaboratorDialog = React.useCallback(() => {
        setIsCollaboratorDialogOpen(false);
    }, [setIsCollaboratorDialogOpen]);
    const addCollaborator = React.useCallback(() => {
        setEditingCollaboratorUid(null);
        openCollaboratorDialog();
    }, [openCollaboratorDialog]);
    const editCollaborator = React.useCallback((collaboratorUid) => {
        setEditingCollaboratorUid(collaboratorUid);
        openCollaboratorDialog();
    }, [openCollaboratorDialog, setEditingCollaboratorUid]);
    const createOrUpdateCollaborator = React.useCallback((params) => {
        closeCollaboratorDialog();
        console.log({ editingCollaboratorUid });
        console.log("TEST: PARAMS", params);
        if (editingCollaboratorUid) {
            onUpdateCollaborator(editingCollaboratorUid, params);
        }
        else {
            onCreateCollaborator(params);
        }
    }, [closeCollaboratorDialog, editingCollaboratorUid, onUpdateCollaborator, onCreateCollaborator]);
    const isLoading = collaborators == null;
    const noAgents = collaborators && collaborators.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(Person, null), title: "Collaborators", count: (_a = collaborators.length) !== null && _a !== void 0 ? _a : undefined, toolbar: onCreateCollaborator ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add collaborator") },
                React.createElement(IconButton, { size: "small", onClick: addCollaborator },
                    React.createElement(Add, { fontSize: "small" })))) : null },
            isLoading && React.createElement(CoreLoading, null),
            noAgents && React.createElement(Typography, { variant: "body2" }, "No collaborators"),
            collaborators && collaborators.length > 0 && (React.createElement(List, { dense: true }, collaborators.map((collaborator) => (React.createElement(CollaboratorListItem, { key: collaborator.uid, collaborator: collaborator, onEditCollaborator: onUpdateCollaborator ? editCollaborator : undefined, onRemoveCollaborator: onRemoveCollaborator })))))),
        isCollaboratorDialogOpen && (React.createElement(CollaboratorDialog, { DialogProps: { open: isCollaboratorDialogOpen }, onClose: closeCollaboratorDialog, onUpdate: createOrUpdateCollaborator, collaborator: collaborators.find((a) => a.uid === editingCollaboratorUid), canAssignSelfOnly: canAssignSelfOnly }))));
};
export default Collaborators;
