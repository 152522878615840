import * as React from "react";
import { BarChart } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { formatDateMemoized_Dayjs } from "@app/util/Utils";
const PSPChronologyItem = (props) => {
    const { psp } = props;
    return (React.createElement(RouterLink, { to: `/matrix/${psp.uid}`, style: { all: "unset", cursor: "pointer" } },
        React.createElement(Grid, { container: true, style: { width: "100%", border: "1px solid #aaa", borderRadius: "0.25rem", marginLeft: "0.25rem" }, spacing: 2 },
            React.createElement(Grid, { item: true, style: { width: "40px" } },
                React.createElement(BarChart, { fontSize: "large" })),
            React.createElement(Grid, { item: true, container: true, spacing: 2, style: { flex: 1 } },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "PSP Created"))),
            React.createElement(Grid, { item: true, style: { width: "120px", textAlign: "right", paddingRight: "0.5rem" } },
                React.createElement(Typography, { variant: "caption", style: { color: "rgb(204, 204, 204)" } }, formatDateMemoized_Dayjs(psp.created_at, "M/D/YYYY h:mm A"))))));
};
export default PSPChronologyItem;
