import * as React from "react";
import { Close } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, MenuItem } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import { withFilter } from "@app/hoc/withFilter";
import REPORTING_DATE_RANGE_OPTIONS from "@app/util/date_ranges";
class QuestionsForTheOfferSubmissionsSidebar extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                created_from: null,
                created_to: null,
                submitted_from: null,
                submitted_to: null,
                createdDateRange: null,
                submittedDateRange: null,
                status: null,
            }
        });
        Object.defineProperty(this, "setCreatedDateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                var _a, _b, _c, _d;
                this.setState({
                    createdDateRange: choice,
                });
                this.props.updateFilter("created_from", (_b = (_a = REPORTING_DATE_RANGE_OPTIONS.find((option) => option.name === choice)) === null || _a === void 0 ? void 0 : _a.startDate) !== null && _b !== void 0 ? _b : null, "created_to", (_d = (_c = REPORTING_DATE_RANGE_OPTIONS.find((option) => option.name === choice)) === null || _c === void 0 ? void 0 : _c.endDate) !== null && _d !== void 0 ? _d : null);
            }
        });
        Object.defineProperty(this, "setCreatedFrom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.props.updateFilter("created_from", date);
            }
        });
        Object.defineProperty(this, "setCreatedTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.props.updateFilter("created_to", date);
            }
        });
        Object.defineProperty(this, "setSubmittedDateRange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                var _a, _b, _c, _d;
                this.setState({
                    submittedDateRange: choice,
                });
                this.props.updateFilter("submitted_from", (_b = (_a = REPORTING_DATE_RANGE_OPTIONS.find((option) => option.name === choice)) === null || _a === void 0 ? void 0 : _a.startDate) !== null && _b !== void 0 ? _b : null, "submitted_to", (_d = (_c = REPORTING_DATE_RANGE_OPTIONS.find((option) => option.name === choice)) === null || _c === void 0 ? void 0 : _c.endDate) !== null && _d !== void 0 ? _d : null);
            }
        });
        Object.defineProperty(this, "setSubmittedFrom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.props.updateFilter("submitted_from", date);
            }
        });
        Object.defineProperty(this, "setSubmittedTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.props.updateFilter("submitted_to", date);
            }
        });
        Object.defineProperty(this, "setStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (choice) => {
                this.props.updateFilter("status", choice);
            }
        });
    }
    render() {
        const { onToggleSidebar } = this.props;
        const { created_from, created_to, createdDateRange, status, submitted_from, submitted_to, submittedDateRange } = this.state;
        return (React.createElement(DashCard, { heading: "Filters", toolbar: React.createElement(IconButton, { onClick: onToggleSidebar },
                React.createElement(Close, null)), padding: 0, elevation: 0, PaperProps: {
                style: {
                    overflowX: "hidden",
                },
            } },
            React.createElement(Box, { component: Grid, container: true, spacing: 2, p: 2 },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Created", fullWidth: true, value: createdDateRange !== null && createdDateRange !== void 0 ? createdDateRange : "", onChoiceChange: this.setCreatedDateRange }, REPORTING_DATE_RANGE_OPTIONS.map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(CoreDateField, { variant: "standard", label: "From", fullWidth: true, date: created_from !== null && created_from !== void 0 ? created_from : null, format: "MM/DD/YYYY", onDateChange: this.setCreatedFrom })),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(CoreDateField, { variant: "standard", label: "To", fullWidth: true, date: created_to !== null && created_to !== void 0 ? created_to : null, format: "MM/DD/YYYY", onDateChange: this.setCreatedTo })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Submitted", fullWidth: true, value: submittedDateRange !== null && submittedDateRange !== void 0 ? submittedDateRange : "", onChoiceChange: this.setSubmittedDateRange }, REPORTING_DATE_RANGE_OPTIONS.map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(CoreDateField, { variant: "standard", label: "From", fullWidth: true, date: submitted_from !== null && submitted_from !== void 0 ? submitted_from : null, format: "MM/DD/YYYY", onDateChange: this.setSubmittedFrom })),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(CoreDateField, { variant: "standard", label: "To", fullWidth: true, date: submitted_to !== null && submitted_to !== void 0 ? submitted_to : null, format: "MM/DD/YYYY", onDateChange: this.setSubmittedTo })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Status", fullWidth: true, value: status !== null && status !== void 0 ? status : "Any", onChoiceChange: this.setStatus },
                            React.createElement(MenuItem, { value: "Any" }, "Any"),
                            React.createElement(MenuItem, { value: "In Progress" }, "In Progress"),
                            React.createElement(MenuItem, { value: "Submitted" }, "Submitted")))))));
    }
}
/* eslint-disable react/no-arrow-function-lifecycle */
Object.defineProperty(QuestionsForTheOfferSubmissionsSidebar, "getDerivedStateFromProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (nextProps) => {
        var _a, _b, _c, _d, _e;
        const queryParams = new URLSearchParams(nextProps.location.search);
        return {
            created_from: (_a = queryParams.get("created_from")) !== null && _a !== void 0 ? _a : null,
            created_to: (_b = queryParams.get("created_to")) !== null && _b !== void 0 ? _b : null,
            submitted_from: (_c = queryParams.get("submitted_from")) !== null && _c !== void 0 ? _c : null,
            submitted_to: (_d = queryParams.get("submitted_to")) !== null && _d !== void 0 ? _d : null,
            status: ((_e = queryParams.get("status")) !== null && _e !== void 0 ? _e : null),
        };
    }
});
export default withFilter(QuestionsForTheOfferSubmissionsSidebar);
