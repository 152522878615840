import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Newsfeed from "./Newsfeed";
import useNewsfeedBuyerContracts from "../../buyer-contracts/useNewsfeed";
import useNewsfeedBuyerLeads from "../../buyer-leads/useNewsfeed";
import useNewsfeedContacts from "../../contacts/useNewsfeed";
import useNewsfeedRecruits from "../../recruiting/useNewsfeed";
import useNewsfeedSellerContracts from "../../seller-contracts/useNewsfeed";
import useNewsfeedSellerLeads from "../../seller-leads/useNewsfeed";
import useNewsfeedUsers from "../../users/useNewsfeed";
export const OverviewNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const buyerLeadNewsfeed = useNewsfeedBuyerLeads();
    const sellerLeadNewsfeed = useNewsfeedSellerLeads();
    const buyerContractNewsfeed = useNewsfeedBuyerContracts({ ignoreBuyerLeads: true });
    const sellerContractNewsfeed = useNewsfeedSellerContracts({ ignoreSellerLeads: true });
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = React.useMemo(() => {
        return [
            ...buyerLeadNewsfeed.newsfeed,
            ...sellerLeadNewsfeed.newsfeed,
            ...buyerContractNewsfeed.newsfeed,
            ...sellerContractNewsfeed.newsfeed,
        ];
    }, [
        buyerLeadNewsfeed.newsfeed,
        sellerLeadNewsfeed.newsfeed,
        buyerContractNewsfeed.newsfeed,
        sellerContractNewsfeed.newsfeed,
    ]);
    const refreshNewsfeed = React.useCallback(() => {
        buyerLeadNewsfeed.refresh();
        sellerLeadNewsfeed.refresh();
        buyerContractNewsfeed.refresh();
        sellerContractNewsfeed.refresh();
    }, [buyerLeadNewsfeed, sellerLeadNewsfeed, buyerContractNewsfeed, sellerContractNewsfeed]);
    const isLoadingNewsfeed = React.useMemo(() => {
        return (buyerLeadNewsfeed.isLoadingComments ||
            buyerLeadNewsfeed.isLoadingFieldValues ||
            buyerLeadNewsfeed.isLoadingNotes ||
            buyerLeadNewsfeed.isLoadingStages ||
            buyerLeadNewsfeed.isLoadingFiles ||
            sellerLeadNewsfeed.isLoadingComments ||
            sellerLeadNewsfeed.isLoadingFieldValues ||
            sellerLeadNewsfeed.isLoadingStages ||
            sellerLeadNewsfeed.isLoadingNotes ||
            sellerLeadNewsfeed.isLoadingFiles ||
            buyerContractNewsfeed.isLoadingComments ||
            buyerContractNewsfeed.isLoadingFieldValues ||
            buyerContractNewsfeed.isLoadingNotes ||
            buyerContractNewsfeed.isLoadingStages ||
            sellerContractNewsfeed.isLoadingComments ||
            sellerContractNewsfeed.isLoadingFieldValues ||
            sellerContractNewsfeed.isLoadingStages ||
            sellerContractNewsfeed.isLoadingNotes);
    }, [
        buyerContractNewsfeed.isLoadingComments,
        buyerContractNewsfeed.isLoadingFieldValues,
        buyerContractNewsfeed.isLoadingNotes,
        buyerContractNewsfeed.isLoadingStages,
        buyerLeadNewsfeed.isLoadingComments,
        buyerLeadNewsfeed.isLoadingFieldValues,
        buyerLeadNewsfeed.isLoadingFiles,
        buyerLeadNewsfeed.isLoadingNotes,
        buyerLeadNewsfeed.isLoadingStages,
        sellerContractNewsfeed.isLoadingComments,
        sellerContractNewsfeed.isLoadingFieldValues,
        sellerContractNewsfeed.isLoadingNotes,
        sellerContractNewsfeed.isLoadingStages,
        sellerLeadNewsfeed.isLoadingComments,
        sellerLeadNewsfeed.isLoadingFieldValues,
        sellerLeadNewsfeed.isLoadingFiles,
        sellerLeadNewsfeed.isLoadingNotes,
        sellerLeadNewsfeed.isLoadingStages,
    ]);
    return (React.createElement(Newsfeed, { newsfeed: newsfeed, onClose: onClose, onRefresh: refreshNewsfeed, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const BuyerLeadsNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedBuyerLeads();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes || newsfeed.isLoadingStages;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const SellerLeadsNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedSellerLeads();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes || newsfeed.isLoadingStages;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const BuyerContractsNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedBuyerContracts();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes || newsfeed.isLoadingStages;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const SellerContractsNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedSellerContracts();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes || newsfeed.isLoadingStages;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const RecruitsNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedRecruits();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes || newsfeed.isLoadingStages;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const UsersNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedUsers();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes || newsfeed.isLoadingStages;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Stage changes", "Note updates", "Comments", "Files"], currentOptions: newsfeedVisibility }));
};
export const ContactsNewsfeed = (props) => {
    var _a, _b;
    const { onClose } = props;
    const [searchParams, _] = useSearchParams();
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    const newsfeed = useNewsfeedContacts();
    const isLoadingNewsfeed = newsfeed.isLoadingComments || newsfeed.isLoadingFieldValues || newsfeed.isLoadingNotes;
    return (React.createElement(Newsfeed, { newsfeed: newsfeed.newsfeed, onClose: onClose, onRefresh: newsfeed.refresh, isLoading: isLoadingNewsfeed, allowedOptions: ["Field value updates", "Note updates", "Comments"], currentOptions: newsfeedVisibility }));
};
