/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List submissions
 */
export const listRecruitingQfaSubmissonsNewcrm = (params, options) => {
    return axios.get(`/api/forms/questions_for_affiliation`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListRecruitingQfaSubmissonsNewcrmQueryKey = (params) => {
    return [`/api/forms/questions_for_affiliation`, ...(params ? [params] : [])];
};
export const getListRecruitingQfaSubmissonsNewcrmQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListRecruitingQfaSubmissonsNewcrmQueryKey(params);
    const queryFn = ({ signal }) => listRecruitingQfaSubmissonsNewcrm(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List submissions
 */
export function useListRecruitingQfaSubmissonsNewcrm(params, options) {
    const queryOptions = getListRecruitingQfaSubmissonsNewcrmQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get a Recruiting QFA
 */
export const getRecruitingQfaSubmissionNewcrm = (uid, options) => {
    return axios.get(`/api/forms/questions_for_affiliation/${uid}`, options);
};
export const getGetRecruitingQfaSubmissionNewcrmQueryKey = (uid) => {
    return [`/api/forms/questions_for_affiliation/${uid}`];
};
export const getGetRecruitingQfaSubmissionNewcrmQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRecruitingQfaSubmissionNewcrmQueryKey(uid);
    const queryFn = ({ signal }) => getRecruitingQfaSubmissionNewcrm(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a Recruiting QFA
 */
export function useGetRecruitingQfaSubmissionNewcrm(uid, options) {
    const queryOptions = getGetRecruitingQfaSubmissionNewcrmQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get data for recruit
 */
export const getDataForRecruitingQfa = (recruitUid, options) => {
    return axios.get(`/api/forms/questions_for_affiliation/get_recruit_data/${recruitUid}`, options);
};
export const getGetDataForRecruitingQfaQueryKey = (recruitUid) => {
    return [`/api/forms/questions_for_affiliation/get_recruit_data/${recruitUid}`];
};
export const getGetDataForRecruitingQfaQueryOptions = (recruitUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDataForRecruitingQfaQueryKey(recruitUid);
    const queryFn = ({ signal }) => getDataForRecruitingQfa(recruitUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(recruitUid) }, queryOptions);
};
/**
 * @summary Get data for recruit
 */
export function useGetDataForRecruitingQfa(recruitUid, options) {
    const queryOptions = getGetDataForRecruitingQfaQueryOptions(recruitUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Submit form
 */
export const submitRecruitingQfaNewcrm = (recruitUid, recruitingQFASubmissionNEWCRMRequest, options) => {
    return axios.post(`/api/forms/questions_for_affiliation/submit/${recruitUid}/submit`, recruitingQFASubmissionNEWCRMRequest, options);
};
export const getSubmitRecruitingQfaNewcrmMutationOptions = (options) => {
    const mutationKey = ['submitRecruitingQfaNewcrm'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, data } = props !== null && props !== void 0 ? props : {};
        return submitRecruitingQfaNewcrm(recruitUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit form
*/
export const useSubmitRecruitingQfaNewcrm = (options) => {
    const mutationOptions = getSubmitRecruitingQfaNewcrmMutationOptions(options);
    return useMutation(mutationOptions);
};
