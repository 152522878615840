import * as React from "react";
import { Box, Grid2 } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { useLocation } from "react-router";
import DialogProvider from "@app/common/context/DialogContext";
import { DialogServiceProvider } from "@app/hoc/withDialog";
import useAllowOverlay from "@app/hooks/useAllowOverlay";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import { variant } from "@app/util/Utils";
import Header from "./Header";
import OpenInCRMButton from "./OpenInCRMButton";
import Overlay from "./Overlay/Overlay";
import Sidebar from "./Sidebar";
import WindowsScrollbarWrapper from "../../../common/layout/WindowsScrollbarWrapper";
import MailMergeProvider from "../context/mail-merge/MailMergeContext";
import TwilioCallProvider from "../context/twilio-calls/TwilioCallContext";
import TwilioSMSProvider from "../context/twilio-sms/TwilioSMSContext";
import { BulkDeleteConfirmProvider } from "../features/crm/components/box-view/bulk-delete-confirm/BulkDeleteConfirmContext";
import { AgentDashboardFiltersContextProvider } from "../features/dashboards/agent-dashboard/useAgentDashboardFilters";
const App = (props) => {
    var _a, _b;
    const { content, showHeader, showSidebar, wrapperProps } = props;
    const session = useSession();
    const allowOverlay = useAllowOverlay();
    const size = useSize();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(size.isUp("sm"));
    const location = useLocation();
    const toggleSidebar = React.useCallback(() => {
        setIsSidebarOpen((prev) => !prev);
    }, [setIsSidebarOpen]);
    const isSmall = size.isDown("sm");
    // on small devices, where the sidebar takes up 100% of the screen,
    // close the sidebar whenever we navigate to a new page
    const prevLocation = React.useRef(location);
    React.useEffect(() => {
        if (isSmall && isSidebarOpen && location !== prevLocation.current) {
            setIsSidebarOpen(false);
        }
        prevLocation.current = location;
    }, [isSmall, location, isSidebarOpen]);
    console.log({ session });
    const nowrapper = window.location.href.includes("nowrapper") ||
        !((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal) ||
        ((_b = session.viewingAsUser.profile) === null || _b === void 0 ? void 0 : _b.stage.name) === "Terminated";
    return (React.createElement(WindowsScrollbarWrapper, Object.assign({ id: "windows-sidebar-wrapper" }, wrapperProps),
        React.createElement(SnackbarProvider, { maxSnack: 3 },
            React.createElement(DialogProvider, null,
                React.createElement(ConfirmProvider, null,
                    React.createElement(DialogServiceProvider, null,
                        React.createElement(BulkDeleteConfirmProvider, null,
                            React.createElement(MailMergeProvider, null,
                                React.createElement(TwilioCallProvider, null,
                                    React.createElement(TwilioSMSProvider, null,
                                        React.createElement(AgentDashboardFiltersContextProvider, null,
                                            React.createElement(React.Fragment, null,
                                                React.createElement(Grid2, { container: true, direction: "column", style: { height: "100%", width: "100%", overflow: "hidden" }, wrap: "nowrap" },
                                                    !nowrapper && showHeader !== false && (React.createElement(Grid2, null,
                                                        React.createElement(Header, { onSidebarToggle: toggleSidebar }))),
                                                    variant === "sidebar" && (React.createElement(Grid2, { style: { textAlign: "center" } },
                                                        React.createElement(OpenInCRMButton, null))),
                                                    React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
                                                        React.createElement(Grid2, { container: true, style: { height: "100%", overflow: "hidden" }, wrap: "nowrap" },
                                                            !nowrapper && showSidebar !== false && (React.createElement(Grid2, null,
                                                                React.createElement(Sidebar, { open: isSidebarOpen }))),
                                                            React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
                                                                React.createElement(Box, { id: "main-content", style: {
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        overflowY: "auto",
                                                                    } }, content))))),
                                                allowOverlay && React.createElement(Overlay, null)))))))))))));
};
export default App;
