import * as React from "react";
import { Upload } from "@mui/icons-material";
import { Button, Grid2, Typography } from "@mui/material";
const FileField = (props) => {
    const { onChange, value } = props;
    const inputRef = React.useRef(null);
    const clickInputElement = React.useCallback(() => {
        inputRef.current.click();
    }, []);
    const onFileChange = React.useCallback((e) => {
        var _a, _b;
        const file = (_b = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : null;
        onChange(file);
        inputRef.current.value = "";
    }, [onChange]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Button, { size: "small", onClick: clickInputElement, endIcon: React.createElement(Upload, { fontSize: "small" }), color: "primary" }, "Upload")),
            value && (React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "body2" }, `${value.name} - ${Math.round((value.size / 1024 / 1024) * 10) / 10}MB`)))),
        React.createElement("input", { ref: inputRef, type: "file", accept: "application/pdf", style: { display: "none" }, onChange: onFileChange })));
};
export default FileField;
