import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import PSPAutocomplete from "../../components/box-view/actions/PSPAutocomplete";
const LinkPSPDialog = (props) => {
    var _a;
    const { DialogProps, autocompleteFilter, onClose, onLinkPSP } = props;
    const [selectedPSP, setSelectedPSP] = React.useState(null);
    const setSelectedPSP_ = React.useCallback((_, psp) => {
        setSelectedPSP(psp);
    }, [setSelectedPSP]);
    const linkPSP = React.useCallback(() => {
        onLinkPSP(selectedPSP.uid);
        onClose();
    }, [onClose, onLinkPSP, selectedPSP]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "xs", fullWidth: true }, DialogProps, { open: (_a = DialogProps === null || DialogProps === void 0 ? void 0 : DialogProps.open) !== null && _a !== void 0 ? _a : false }),
        React.createElement(DialogTitle, null, "Select a PSP"),
        React.createElement(DialogContent, null,
            React.createElement(PSPAutocomplete, { value: selectedPSP, onChange: setSelectedPSP_, query: autocompleteFilter })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: linkPSP, tabIndex: -1, disabled: !selectedPSP }, "OK"))));
};
export default LinkPSPDialog;
