import * as React from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
const getOptionLabel = (option) => (typeof option === "string" ? option : option.name);
const groupBy = (option) => { var _a; return (_a = option.stage_name) !== null && _a !== void 0 ? _a : option.stageKey; };
const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 750,
});
const isOptionEqualToValue = (option, value) => (option === null || option === void 0 ? void 0 : option.boxKey) == (value === null || value === void 0 ? void 0 : value.boxKey);
const renderOption = (props, option) => {
    return (React.createElement("li", Object.assign({}, props, { key: option.boxKey }), option.name));
};
const BoxAutocomplete = (props) => {
    var _a, _b;
    const { AutocompleteProps, InputProps, TextFieldProps, boxes, onBoxSelected, selectedBoxKey } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [isTyping, setIsTyping] = React.useState(false);
    React.useEffect(() => {
        var _a, _b;
        if (selectedBoxKey) {
            setInputValue((_b = (_a = boxes.find((b) => b.boxKey === selectedBoxKey)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "");
        }
    }, [selectedBoxKey, boxes]);
    const onAutocompleteChange = React.useCallback((_, value) => {
        var _a;
        onBoxSelected((_a = value === null || value === void 0 ? void 0 : value.boxKey) !== null && _a !== void 0 ? _a : null);
    }, [onBoxSelected]);
    const onInputFocus = React.useCallback(() => {
        if (!isTyping) {
            setIsTyping(true);
        }
    }, [isTyping]);
    const onInputBlur = React.useCallback(() => {
        if (isTyping) {
            setIsTyping(false);
        }
    }, [isTyping]);
    const onInputChange = React.useCallback((e) => {
        const val = e.target.value;
        setInputValue(val);
    }, [setInputValue]);
    const renderInput = React.useCallback((params) => {
        return (React.createElement(TextField, Object.assign({ variant: "outlined" }, TextFieldProps, params, { inputProps: Object.assign(Object.assign({}, params.inputProps), { onFocus: onInputFocus, onBlur: onInputBlur }), onChange: onInputChange, InputProps: Object.assign(Object.assign({}, params.InputProps), InputProps) })));
    }, [InputProps, TextFieldProps, onInputFocus, onInputBlur, onInputChange]);
    const selectedBox = React.useMemo(() => {
        var _a;
        if (selectedBoxKey) {
            return (_a = boxes.find((box) => box.boxKey === selectedBoxKey)) !== null && _a !== void 0 ? _a : null;
        }
        return null;
    }, [selectedBoxKey, boxes]);
    return (React.createElement(Autocomplete, Object.assign({ clearOnBlur: true, clearOnEscape: true }, AutocompleteProps, { value: selectedBox !== null && selectedBox !== void 0 ? selectedBox : undefined, getOptionLabel: getOptionLabel, renderOption: renderOption, isOptionEqualToValue: isOptionEqualToValue, inputValue: inputValue || (selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.name) || "", groupBy: (_a = AutocompleteProps === null || AutocompleteProps === void 0 ? void 0 : AutocompleteProps.groupBy) !== null && _a !== void 0 ? _a : groupBy, noOptionsText: (_b = AutocompleteProps === null || AutocompleteProps === void 0 ? void 0 : AutocompleteProps.noOptionsText) !== null && _b !== void 0 ? _b : "No boxes found", onChange: onAutocompleteChange, options: boxes, filterOptions: filterOptions, renderInput: renderInput })));
};
export default BoxAutocomplete;
