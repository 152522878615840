import * as React from "react";
import { Grid2, MenuItem, TextField } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { FilterContext } from "../FilterContext";
const SavedViewOfficeFilter = (props) => {
    var _a, _b, _c, _d;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.office_filter.comparator = comparator;
        if (comparator !== "is any of" && comparator !== "is not any of") {
            newFilter.office_filter.rhs_values = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValues = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.office_filter.rhs_values = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.officeComparators;
    console.log({ comparators });
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.office_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["is any of", "is not any of"].includes(filter.office_filter.comparator) && (React.createElement(Grid2, { style: { minWidth: "200px" } },
            React.createElement(CoreChoiceField, { size: "small", variant: "standard", fullWidth: true, multiple: true, value: (_b = filter.office_filter.rhs_values) !== null && _b !== void 0 ? _b : [], onChoicesChange: setRHSValues, options: ((_c = context.offices) !== null && _c !== void 0 ? _c : []).reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.uid]: x.name })), {}), sx: {
                    "& .MuiInputBase-root": {
                        marginTop: 0,
                    },
                } }, ((_d = context.offices) !== null && _d !== void 0 ? _d : []).map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))))));
};
export default SavedViewOfficeFilter;
