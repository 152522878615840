import * as React from "react";
import { Close, Send } from "@mui/icons-material";
import { Grid, Button, MenuItem, DialogActions, DialogContent, DialogTitle, TextField, Skeleton, Stack, Box, Typography, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar } from "notistack";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useGetBuyerLead } from "@app/orval/api/buyer-leads";
import { useListPspRequestInvestorTypes, useListPspRequestLanguageOptions, useListPspRequestMarkets, useListPspRequestTimingOptions, useListPspRequestTypes, useListPspRequestUrgencyOptions, useListPspRequests, useSubmitPspRequest, } from "@app/orval/api/psp-requests";
import { useGetSellerLead } from "@app/orval/api/seller-leads";
import { useGetCurrentUser } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { getReasonsWhyUserCantRequestMAServices } from "../../../buyer-leads/components/market-analyst-services";
import MarketAnalystServicesAlert from "../../../buyer-leads/components/MarketAnalystServicesAlert";
const buyerPspRequestTypes = ["Buyer PSP", "Offer", "Offer & Agency", "2nd Opinion", "Agency ONLY"];
const listingPspRequestTypes = ["Listing PSP", "Pre-Listing", "CMA"];
const PSPRequestForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const { DialogProps, buyerLeadUid, buyerPropertyUid, onClose, sellerLeadUid } = props;
    const errorDialog = useErrorDialog();
    const [formData, setFormData] = React.useState(null);
    const listRequestTypesApi = useListPspRequestTypes(INFINITE_CACHE_PARAMS);
    const requestTypes = (_b = (_a = listRequestTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listRequestUrgenciesApi = useListPspRequestUrgencyOptions(INFINITE_CACHE_PARAMS);
    const urgencies = (_d = (_c = listRequestUrgenciesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listRequestTimingApi = useListPspRequestTimingOptions(INFINITE_CACHE_PARAMS);
    const timings = (_f = (_e = listRequestTimingApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listRequestMarketsApi = useListPspRequestMarkets(INFINITE_CACHE_PARAMS);
    const markets = (_h = (_g = listRequestMarketsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const listRequestInvestorTypesApi = useListPspRequestInvestorTypes(INFINITE_CACHE_PARAMS);
    const investorTypes = (_k = (_j = listRequestInvestorTypesApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const listRequestLangaugeOptionsApi = useListPspRequestLanguageOptions(INFINITE_CACHE_PARAMS);
    const languages = (_m = (_l = listRequestLangaugeOptionsApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const getBuyerLeadApi = useGetBuyerLead(buyerLeadUid !== null && buyerLeadUid !== void 0 ? buyerLeadUid : "", { query: { enabled: false } });
    const getSellerLeadApi = useGetSellerLead(sellerLeadUid !== null && sellerLeadUid !== void 0 ? sellerLeadUid : "", { query: { enabled: false } });
    const submitRequestApi = useSubmitPspRequest();
    const getCurrentUserApi = useGetCurrentUser(INFINITE_CACHE_PARAMS);
    const buyerLead = React.useMemo(() => {
        var _a;
        return (_a = getBuyerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data;
    }, [getBuyerLeadApi.data]);
    const currentUser = React.useMemo(() => {
        var _a;
        return (_a = getCurrentUserApi.data) === null || _a === void 0 ? void 0 : _a.data;
    }, [getCurrentUserApi.data]);
    const reasonsUserCantRequestMarketAnalystServices = currentUser
        ? getReasonsWhyUserCantRequestMAServices({ buyerLead, user: currentUser })
        : [];
    const canRequestMAServices = reasonsUserCantRequestMarketAnalystServices.length === 0 || (formData === null || formData === void 0 ? void 0 : formData.request_type) === "Agency ONLY";
    const propertyAddress = React.useMemo(() => {
        var _a, _b;
        return buyerLead
            ? (_a = buyerLead.properties.find((x) => x.uid === buyerPropertyUid)) === null || _a === void 0 ? void 0 : _a.property_address
            : (_b = getSellerLeadApi.data) === null || _b === void 0 ? void 0 : _b.data.name;
    }, [buyerLead, buyerPropertyUid, getSellerLeadApi]);
    const queryClient = useQueryClient();
    const listPSPRequestsApi = useListPspRequests(pickBy({ buyer_property_uid: buyerPropertyUid, seller_lead_uid: sellerLeadUid, is_claimed: false }, (val) => val != null), { query: { enabled: false } });
    const loading = useLoading({
        items: [
            {
                label: "Loading request types...",
                queryResult: listRequestTypesApi,
            },
            {
                label: "Loading urgency options...",
                queryResult: listRequestUrgenciesApi,
            },
            {
                label: "Loading timing options...",
                queryResult: listRequestTimingApi,
            },
            {
                label: "Loading markets...",
                queryResult: listRequestMarketsApi,
            },
            {
                label: "Loading investor types...",
                queryResult: listRequestInvestorTypesApi,
            },
            {
                label: "Loading language options",
                queryResult: listRequestLangaugeOptionsApi,
            },
        ],
    });
    React.useEffect(() => {
        setFormData(null);
        if (buyerLeadUid) {
            getBuyerLeadApi.refetch().then((result) => {
                setFormData({
                    request_type: "Buyer PSP",
                    urgency: undefined,
                    deadline: undefined,
                    additional_information: null,
                    timing: undefined,
                    investor_type: "No",
                    market: result.data.data.fields["Office"],
                    language: "English",
                });
            });
        }
        else {
            getSellerLeadApi.refetch().then((result) => {
                setFormData({
                    request_type: "Listing PSP",
                    urgency: undefined,
                    deadline: undefined,
                    additional_information: null,
                    timing: undefined,
                    market: result.data.data.fields["Office"],
                    language: "English",
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyerLeadUid, sellerLeadUid]);
    const submit = React.useCallback(() => {
        var _a, _b, _c;
        submitRequestApi
            .mutateAsync({
            data: {
                buyer_property_uid: buyerPropertyUid,
                seller_lead_uid: sellerLeadUid,
                request_type: formData.request_type,
                urgency: formData.urgency,
                deadline: (_a = formData.deadline) !== null && _a !== void 0 ? _a : null,
                additional_information: (_b = formData.additional_information) !== null && _b !== void 0 ? _b : null,
                timing: formData.timing,
                investor_type: buyerPspRequestTypes.includes((_c = formData.request_type) !== null && _c !== void 0 ? _c : "")
                    ? formData.investor_type
                    : undefined,
                market: formData.market,
                language: formData.language,
            },
        })
            .then((result) => {
            onClose();
            enqueueSnackbar("PSP request submitted successfully.", { variant: "success" });
            if (listPSPRequestsApi.data) {
                queryClient.setQueryData(listPSPRequestsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
                });
            }
        })
            .catch(errorDialog);
    }, [
        buyerPropertyUid,
        errorDialog,
        formData,
        listPSPRequestsApi.data,
        listPSPRequestsApi.queryKey,
        onClose,
        queryClient,
        sellerLeadUid,
        submitRequestApi,
    ]);
    const isSubmitting = submitRequestApi.isPending;
    const isInvestorFieldRelevant = React.useMemo(() => { var _a; return buyerPspRequestTypes.includes((_a = formData === null || formData === void 0 ? void 0 : formData.request_type) !== null && _a !== void 0 ? _a : ""); }, [formData]);
    const isDeadlineFieldApplicable = React.useMemo(() => {
        var _a;
        return buyerPspRequestTypes.includes((_a = formData === null || formData === void 0 ? void 0 : formData.request_type) !== null && _a !== void 0 ? _a : "");
    }, [formData === null || formData === void 0 ? void 0 : formData.request_type]);
    const isValid = React.useMemo(() => {
        return ((formData === null || formData === void 0 ? void 0 : formData.request_type) &&
            formData.urgency &&
            (formData.urgency === "Level 1 - Urgent! Deadline Today"
                ? !isDeadlineFieldApplicable || !!formData.deadline
                : true) &&
            formData.timing &&
            (formData.investor_type || !isInvestorFieldRelevant) &&
            formData.market &&
            formData.language);
    }, [
        formData === null || formData === void 0 ? void 0 : formData.deadline,
        formData === null || formData === void 0 ? void 0 : formData.investor_type,
        formData === null || formData === void 0 ? void 0 : formData.language,
        formData === null || formData === void 0 ? void 0 : formData.market,
        formData === null || formData === void 0 ? void 0 : formData.request_type,
        formData === null || formData === void 0 ? void 0 : formData.timing,
        formData === null || formData === void 0 ? void 0 : formData.urgency,
        isDeadlineFieldApplicable,
        isInvestorFieldRelevant,
    ]);
    const setRequestType = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { request_type: val ? val : undefined })));
    }, [setFormData]);
    const setRequestUrgency = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { urgency: val ? val : undefined })));
    }, [setFormData]);
    const setDeadline = React.useCallback((dt) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { deadline: dt || undefined })));
    }, [setFormData]);
    const setAdditionalInformation = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { additional_information: val })));
    }, [setFormData]);
    const setTiming = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { timing: val ? val : undefined })));
    }, [setFormData]);
    const setInvestorType = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { investor_type: val ? val : undefined })));
    }, [setFormData]);
    const setMarket = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { market: val ? val : undefined })));
    }, [setFormData]);
    const setLanguage = React.useCallback((e) => {
        const val = e.target.value || null;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { language: val ? val : undefined })));
    }, [setFormData]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "md" }, DialogProps, { open: DialogProps.open, PaperProps: {
            style: Object.assign({ minWidth: "500px" }, (_o = DialogProps.PaperProps) === null || _o === void 0 ? void 0 : _o.style),
        } }),
        React.createElement(DialogTitle, null, ["PSP Request Form", propertyAddress].filter((o) => o).join(": ")),
        React.createElement(DialogContent, null,
            loading.isLoading && loading.loadingComponent && loading.loadingComponent,
            formData ? (React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", spacing: 4 },
                    reasonsUserCantRequestMarketAnalystServices.length > 0 && currentUser && !canRequestMAServices && (React.createElement(Grid, { item: true },
                        React.createElement(MarketAnalystServicesAlert, { reasons: reasonsUserCantRequestMarketAnalystServices }))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Request Type", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_p = formData.request_type) !== null && _p !== void 0 ? _p : "", onChange: setRequestType },
                            React.createElement(MenuItem, { value: "" }),
                            (requestTypes !== null && requestTypes !== void 0 ? requestTypes : [])
                                .filter((item) => {
                                if (buyerLeadUid) {
                                    return buyerPspRequestTypes.includes(item.name);
                                }
                                return listingPspRequestTypes.includes(item.name);
                            })
                                .map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Urgency", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_q = formData.urgency) !== null && _q !== void 0 ? _q : "", onChange: setRequestUrgency },
                            React.createElement(MenuItem, { value: "" }),
                            (urgencies !== null && urgencies !== void 0 ? urgencies : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name))))),
                    isDeadlineFieldApplicable && (React.createElement(Grid, { item: true },
                        React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(CoreDateField, { label: "Offer Deadline Day & Time", format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", mode: "datetime", date: (_r = formData.deadline) !== null && _r !== void 0 ? _r : null, onDateChange: setDeadline, size: "small", fullWidth: true, 
                                    // @ts-expect-error: not assignable to type 'standard'
                                    variant: "outlined", hasPicker: true, helperText: formData.urgency === "Level 1 - Urgent! Deadline Today"
                                        ? "Required for urgent requests"
                                        : undefined })),
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Typography, { style: { color: "#999" } }, "Eastern time"))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Additional Information", minRows: 4, maxRows: 8, multiline: true, fullWidth: true, variant: "outlined", size: "small", value: (_s = formData.additional_information) !== null && _s !== void 0 ? _s : "", onChange: setAdditionalInformation })),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Request Timing", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_t = formData.timing) !== null && _t !== void 0 ? _t : "", onChange: setTiming },
                            React.createElement(MenuItem, { value: "" }),
                            (timings !== null && timings !== void 0 ? timings : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name))))),
                    isInvestorFieldRelevant && (React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Investor?", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_u = formData.investor_type) !== null && _u !== void 0 ? _u : "", onChange: setInvestorType },
                            React.createElement(MenuItem, { value: "" }),
                            (investorTypes !== null && investorTypes !== void 0 ? investorTypes : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name)))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Market", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_v = formData.market) !== null && _v !== void 0 ? _v : "", onChange: setMarket },
                            React.createElement(MenuItem, { value: "" }),
                            (markets !== null && markets !== void 0 ? markets : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Language", select: true, fullWidth: true, variant: "outlined", size: "small", value: (_w = formData.language) !== null && _w !== void 0 ? _w : "", onChange: setLanguage }, (languages !== null && languages !== void 0 ? languages : []).map((item) => (React.createElement(MenuItem, { key: item.name, value: item.name }, item.name)))))))) : (React.createElement(Stack, null,
                React.createElement(Skeleton, null),
                React.createElement(Skeleton, null),
                React.createElement(Skeleton, null)))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { startIcon: React.createElement(Close, null), variant: "text", onClick: onClose, disabled: isSubmitting }, "Cancel"),
            React.createElement(Button, { startIcon: React.createElement(Send, null), variant: "contained", onClick: submit, disabled: isSubmitting || !isValid || !canRequestMAServices || !currentUser }, "Submit"))));
};
export default PSPRequestForm;
