import { useSearchParams } from "react-router-dom";
const usePaginatedTable = () => {
    var _a, _b;
    const [searchParams, _] = useSearchParams();
    const limitStr = searchParams.get("limit");
    const limit = limitStr ? parseFloat(limitStr) : null;
    const offsetStr = searchParams.get("offset");
    const offset = offsetStr ? parseFloat(offsetStr) : null;
    const search = searchParams.get("search");
    const stage = searchParams.get("stage");
    const view = searchParams.get("view");
    const showNewsfeed = searchParams.get("show_newsfeed") != null;
    const newsfeedVisibility = (_b = (_a = searchParams
        .get("newsfeed_visibility")) === null || _a === void 0 ? void 0 : _a.split(",").filter((x) => x.trim())) !== null && _b !== void 0 ? _b : null;
    return {
        limit,
        offset,
        search,
        showNewsfeed,
        newsfeedVisibility,
        stage,
        view,
    };
};
export default usePaginatedTable;
