import * as React from "react";
import { Code, Delete, Edit, Reply } from "@mui/icons-material";
import { Avatar, Box, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import JsxParser from "@zeit/react-jsx-parser";
import styled from "styled-components";
import User from "@app/classes/user";
import CoreLink from "@app/common/CoreLink";
import { convertUtcDateString } from "@app/common/date-utils";
import useSession from "@app/hooks/useSession";
import CommentField from "./CommentField";
const CommentGrid = styled(Grid) `
  & .api-comment-button,
  .delete-comment-button,
  .edit-comment-button,
  .reply-comment-button,
  .share-comment-button {
    display: none;
  }
  &:hover {
    & .api-comment-button,
    .delete-comment-button,
    .edit-comment-button,
    .reply-comment-button,
    .share-comment-button {
      display: inline;
    }
  }
`;
const CommentChronologyItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { disabled = false, onCommentDeleted, onCommentSubmitted, users } = props;
    const session = useSession();
    const [comment, setComment] = React.useState(props.comment);
    React.useEffect(() => {
        setComment(props.comment);
    }, [props.comment]);
    const deleteComment = React.useCallback(() => {
        onCommentDeleted(comment.uid);
    }, [comment.uid, onCommentDeleted]);
    const handleDidBeginEditing = React.useCallback(() => {
        setComment((prev) => (Object.assign(Object.assign({}, prev), { isEditing: true })));
    }, [setComment]);
    const handleDidBeginReplying = React.useCallback(() => {
        setComment((prev) => (Object.assign(Object.assign({}, prev), { isReplying: true })));
    }, [setComment]);
    const closeEdit = React.useCallback(() => {
        setComment((prev) => (Object.assign(Object.assign({}, prev), { isEditing: false })));
    }, [setComment]);
    const closeReply = React.useCallback(() => {
        setComment((prev) => (Object.assign(Object.assign({}, prev), { isReplying: false })));
    }, [setComment]);
    /// TODO: need to determine why `edits[0]` is sometimes null
    if (!comment.edits[0]) {
        console.warn("Invalid comment", comment);
        return null;
    }
    const author = comment.edits[0].author ? new User(comment.edits[0].author) : null;
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: (author === null || author === void 0 ? void 0 : author.name) || "unknown author" },
                React.createElement(Avatar, { src: (_a = author === null || author === void 0 ? void 0 : author.icon_url) !== null && _a !== void 0 ? _a : undefined, style: {
                        height: "25px",
                        width: "25px",
                        fontSize: "0.75rem",
                    } }, (author === null || author === void 0 ? void 0 : author.initials) || "?"))),
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(CommentGrid, { className: "comment-grid", container: true, spacing: 1, style: !comment.reply_to
                    ? {
                        border: "1px solid #ccc",
                        borderRadius: "0.25rem",
                        background: "rgba(0, 0, 0, 0.04)",
                        padding: 0,
                        paddingBottom: "0.25rem",
                        paddingRight: "0.25rem",
                        marginLeft: "0.25rem",
                    }
                    : undefined },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1, wrap: "nowrap" },
                    React.createElement(Grid, { item: true, style: { flex: 1, fontSize: "0.75rem" } }, comment.isEditing ? (React.createElement(Box, { p: 1, pb: 0, m: 1 },
                        React.createElement(CommentField, { users: users !== null && users !== void 0 ? users : [], content: comment.edits[0].content, onClose: closeEdit, onCommentSubmitted: onCommentSubmitted, uid: comment.uid }))) : (React.createElement("span", { style: {
                            display: "block",
                            whiteSpace: "pre-wrap",
                        } },
                        React.createElement(JsxParser, { components: { Tooltip, Link }, 
                            // TODO: fix markup
                            jsx: `<p style={{ fontSize: "0.7rem", fontFamily: "Helvetica", wordBreak: "break-word", margin: 0 }}>${comment.edits[0].content.replace(/@\[(.*?)\]\((.*?)\)/g, users ? "<Link href='/users/$2'>$1</Link>" : "$1")}</p>` })))),
                    !comment.isEditing && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { className: "comment-actions-div", item: true, style: { position: "relative" } },
                            ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.isDeveloper) && (React.createElement(Tooltip, { title: "View JSON" },
                                React.createElement(IconButton, { className: "api-comment-button", component: CoreLink, target: "_blank", href: `/api/comments/${comment.uid}`, size: "small", tabIndex: -1 },
                                    React.createElement(Code, { fontSize: "small" })))),
                            !comment.reply_to && (React.createElement(Tooltip, { title: "Reply" },
                                React.createElement(IconButton, { className: "reply-comment-button", onClick: handleDidBeginReplying, size: "small", tabIndex: -1, disabled: disabled },
                                    React.createElement(Reply, { fontSize: "small" })))),
                            ((_c = comment.edits[0].author) === null || _c === void 0 ? void 0 : _c.email) === ((_d = session === null || session === void 0 ? void 0 : session.activeUser) === null || _d === void 0 ? void 0 : _d.email) && (React.createElement(Tooltip, { title: "Edit" },
                                React.createElement(IconButton, { className: "edit-comment-button", onClick: handleDidBeginEditing, size: "small", tabIndex: -1, disabled: disabled },
                                    React.createElement(Edit, { fontSize: "small" })))),
                            (((_e = comment.edits[0].author) === null || _e === void 0 ? void 0 : _e.email) === ((_f = session === null || session === void 0 ? void 0 : session.activeUser) === null || _f === void 0 ? void 0 : _f.email) ||
                                ((_g = session === null || session === void 0 ? void 0 : session.activeUser) === null || _g === void 0 ? void 0 : _g.hasRole(["Superadmin", "Admin"]))) && (React.createElement(Tooltip, { title: "Delete" },
                                React.createElement(IconButton, { className: "delete-comment-button", onClick: deleteComment, size: "small", tabIndex: -1, disabled: disabled },
                                    React.createElement(Delete, { fontSize: "small" }))))),
                        React.createElement(Grid, { item: true, style: {
                                textAlign: "right",
                                width: "108px",
                            } },
                            React.createElement(Grid, { container: true },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { style: { color: "#aaa" }, variant: "caption" }, convertUtcDateString(comment.edits[0].timestamp, { format: "M/D/YYYY h:mm A" })))))))), (_h = comment.replies) === null || _h === void 0 ? void 0 :
                _h.map((reply) => (React.createElement(Grid, { key: reply.uid, item: true, xs: 12 },
                    React.createElement(Box, { ml: 2 },
                        React.createElement(CommentChronologyItem, Object.assign({}, props, { comment: reply })))))),
                comment.isReplying && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { p: 1, pl: 2, pb: 0, pt: 2 },
                        React.createElement(CommentField, { users: users !== null && users !== void 0 ? users : [], onCommentSubmitted: onCommentSubmitted, onClose: closeReply, replyTo: comment.uid }))))))));
};
export default CommentChronologyItem;
