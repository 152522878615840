import * as React from "react";
import { Add, Delete } from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
const EmailAttachments = (props) => {
    const { attachments, onChange } = props;
    const fileInputRef = React.useRef(null);
    const clickAddAttachmentButton = React.useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []);
    const onFileInputChange = React.useCallback((e) => {
        const files = Array.from(e.target.files || []);
        const newAttachments = files.map((file) => ({
            uid: uuidv4(),
            file,
        }));
        onChange([...attachments, ...newAttachments]);
        e.target.value = "";
    }, [attachments, onChange]);
    const removeAttachment = React.useCallback((uid) => () => {
        onChange(attachments.filter((x) => x.uid !== uid));
    }, [attachments, onChange]);
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" }, attachments.map((x) => (React.createElement(Grid, { key: x.uid, item: true },
                React.createElement(Grid, { container: true, direction: "row", spacing: 1, alignItems: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(IconButton, { size: "small", onClick: removeAttachment(x.uid) },
                            React.createElement(Delete, { fontSize: "small" }))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, x.file.name)))))))),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { size: "small", color: "inherit", variant: "outlined", tabIndex: -1, onClick: clickAddAttachmentButton, startIcon: React.createElement(Add, { fontSize: "small" }) }, "Add attachment"),
            React.createElement("input", { ref: fileInputRef, type: "file", multiple: true, style: { display: "none" }, onChange: onFileInputChange }))));
};
export default EmailAttachments;
