import * as React from "react";
import { Clear, Send } from "@mui/icons-material";
import { Box, Button, Divider, Link, Table, TableBody, TableRow, TableCell, Typography, Grid2 } from "@mui/material";
import pickBy from "lodash/pickBy";
import styled from "styled-components";
import { BackButton } from "@app/common/BackButton";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { MailMergeContext } from "@app/entrypoints/brokerage/context/mail-merge/MailMergeContext";
import useLoading from "@app/hooks/useLoading";
import { parseMailschedule4Template } from "@app/orval/api/mail-schedule-parsing";
import { useListSlackChannels } from "@app/orval/api/slack";
import { useListUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { formatPhoneNumber } from "@app/util/Utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import useListEntitiesApi from "../run/useListEntitiesApi";
const EmailPreview = styled("pre") `
    all: initial;
`;
const MailScheduleTemplatePreview = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { entity, onClose, onCloseRedirectUrl, onTemplateProcessed, template, templateUid } = props;
    const context = React.useContext(MailMergeContext);
    const listEntitiesApi = useListEntitiesApi([{ entity_type: entity.entityType, uid: entity.uid }]);
    const entityData = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        switch (entity.entityType) {
            case "Buyer Lead":
                return (_a = listEntitiesApi.buyerLeads) === null || _a === void 0 ? void 0 : _a[0];
            case "Seller Lead":
                return (_b = listEntitiesApi.sellerLeads) === null || _b === void 0 ? void 0 : _b[0];
            case "Buyer Contract":
                return (_c = listEntitiesApi.buyerContracts) === null || _c === void 0 ? void 0 : _c[0];
            case "Listing":
                return (_d = listEntitiesApi.sellerContracts) === null || _d === void 0 ? void 0 : _d[0];
            case "User":
                return (_e = listEntitiesApi.users) === null || _e === void 0 ? void 0 : _e[0];
            case "Recruit":
                return (_f = listEntitiesApi.recruits) === null || _f === void 0 ? void 0 : _f[0];
            default:
                return null;
        }
    }, [entity.entityType, listEntitiesApi]);
    const params = Object.assign({ entity: {
            uid: entity.uid,
            entity_type: entity.entityType,
        } }, pickBy({
        template_uid: templateUid,
        template,
    }, (val) => val != null));
    const parseTemplateApi = usePostQuery(parseMailschedule4Template, params);
    const parsedTemplate = (_b = (_a = parseTemplateApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [isProcessing, setIsProcessing] = React.useState(false);
    const listSlackChannelsApi = useListSlackChannels({}, INFINITE_CACHE_PARAMS);
    const slackChannels = (_d = (_c = listSlackChannelsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listUsersApi = useListUsers(undefined, REFETCH_CACHE_PARAMS);
    const users = (_f = (_e = listUsersApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const mailScheduleOwner = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
        switch (entity.entityType) {
            case "Buyer Lead":
                return (_d = (_c = (_b = (_a = listEntitiesApi.buyerLeads) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.collaborators) === null || _c === void 0 ? void 0 : _c.find((x) => x.is_mail_schedule_owner)) === null || _d === void 0 ? void 0 : _d.user.uid;
            case "Seller Lead":
                return (_h = (_g = (_f = (_e = listEntitiesApi.sellerLeads) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.collaborators) === null || _g === void 0 ? void 0 : _g.find((x) => x.is_mail_schedule_owner)) === null || _h === void 0 ? void 0 : _h.user.uid;
            case "Buyer Contract":
                return (_p = (_o = (_m = (_l = (_k = (_j = listEntitiesApi.buyerContracts) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.buyer_lead) === null || _l === void 0 ? void 0 : _l.collaborators) === null || _m === void 0 ? void 0 : _m.find((x) => x.is_mail_schedule_owner)) === null || _o === void 0 ? void 0 : _o.user) === null || _p === void 0 ? void 0 : _p.uid;
            case "Listing":
                return (_u = (_t = (_s = (_r = (_q = listEntitiesApi.sellerContracts) === null || _q === void 0 ? void 0 : _q[0]) === null || _r === void 0 ? void 0 : _r.seller_lead) === null || _s === void 0 ? void 0 : _s.collaborators) === null || _t === void 0 ? void 0 : _t.find((x) => x.is_mail_schedule_owner)) === null || _u === void 0 ? void 0 : _u.user.uid;
            case "User":
                return (_y = (_x = (_w = (_v = listEntitiesApi.users) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.collaborators) === null || _x === void 0 ? void 0 : _x.find((x) => x.is_mail_schedule_owner)) === null || _y === void 0 ? void 0 : _y.user.uid;
            case "Recruit":
                return (_2 = (_1 = (_0 = (_z = listEntitiesApi.recruits) === null || _z === void 0 ? void 0 : _z[0]) === null || _0 === void 0 ? void 0 : _0.collaborators) === null || _1 === void 0 ? void 0 : _1.find((x) => x.is_mail_schedule_owner)) === null || _2 === void 0 ? void 0 : _2.user.uid;
            default:
                return null;
        }
    }, [
        entity.entityType,
        listEntitiesApi.buyerContracts,
        listEntitiesApi.buyerLeads,
        listEntitiesApi.recruits,
        listEntitiesApi.sellerContracts,
        listEntitiesApi.sellerLeads,
        listEntitiesApi.users,
    ]);
    const dashboardUser = React.useMemo(() => {
        const userUid = mailScheduleOwner;
        return users === null || users === void 0 ? void 0 : users.find((user) => user.uid === userUid);
    }, [users, mailScheduleOwner]);
    const processTemplate = React.useCallback(() => {
        setIsProcessing(true);
        context
            .runMailMerge({
            data: {
                template_uids: templateUid ? [templateUid] : [template.uid],
                entity_type: entity.entityType,
                entity_uids: [entity.uid],
                should_ignore_criteria: true,
                should_ignore_disabled_settings: true,
                is_test: false,
            },
            onExecutionStarted: () => {
                if (onTemplateProcessed) {
                    onTemplateProcessed();
                }
            },
        })
            .then(() => {
            setIsProcessing(false);
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
            setIsProcessing(false);
        });
    }, [context, templateUid, template, entity.entityType, entity.uid, onTemplateProcessed]);
    const loadingItems = [
        {
            label: "Loading users...",
            queryResult: listUsersApi,
        },
        {
            label: "Loading box data...",
            status: entityData ? "success" : "pending",
        },
        {
            label: "Parsing template...",
            queryResult: parseTemplateApi,
        },
    ];
    if (parsedTemplate === null || parsedTemplate === void 0 ? void 0 : parsedTemplate.slack_template) {
        loadingItems.push({
            label: "Loading Slack channels...",
            queryResult: listSlackChannelsApi,
        });
    }
    const { isLoading, loadingComponent } = useLoading({
        items: loadingItems,
    });
    if (isLoading) {
        return loadingComponent;
    }
    if (!parsedTemplate) {
        return null; // should never happen
    }
    const keysAndValues = [];
    if (parsedTemplate.email_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.email_template.sender_type === "assigned agent"
                ? dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.email
                : parsedTemplate.email_template.sender,
        });
        keysAndValues.push({ key: "To", value: (_g = parsedTemplate.email_template.to) !== null && _g !== void 0 ? _g : "" });
        keysAndValues.push({ key: "Cc", value: (_h = parsedTemplate.email_template.cc) !== null && _h !== void 0 ? _h : "" });
        keysAndValues.push({ key: "Bcc", value: (_j = parsedTemplate.email_template.bcc) !== null && _j !== void 0 ? _j : "" });
        keysAndValues.push({ key: "Subject", value: parsedTemplate.email_template.subject });
        keysAndValues.push({ key: "Body", value: parsedTemplate.email_template.body });
    }
    else if (parsedTemplate.email_draft_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.email_draft_template.sender_type === "assigned agent"
                ? dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.email
                : parsedTemplate.email_draft_template.sender,
        });
        keysAndValues.push({ key: "To", value: (_k = parsedTemplate.email_draft_template.to) !== null && _k !== void 0 ? _k : "" });
        keysAndValues.push({ key: "Cc", value: (_l = parsedTemplate.email_draft_template.cc) !== null && _l !== void 0 ? _l : "" });
        keysAndValues.push({ key: "Bcc", value: (_m = parsedTemplate.email_draft_template.bcc) !== null && _m !== void 0 ? _m : "" });
        keysAndValues.push({ key: "Subject", value: parsedTemplate.email_draft_template.subject });
        keysAndValues.push({ key: "Body", value: parsedTemplate.email_draft_template.body });
    }
    else if (parsedTemplate.sms_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.sms_template.use_agent_phone_number
                ? ((_o = dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.fields["Twilio #"]) !== null && _o !== void 0 ? _o : "")
                : parsedTemplate.sms_template.from_phone,
        });
        keysAndValues.push({
            key: "To",
            value: parsedTemplate.sms_template.to_phone
                .split(",")
                .map((x) => formatPhoneNumber(x.trim()))
                .join(", "),
        });
        keysAndValues.push({ key: "Message", value: parsedTemplate.sms_template.message });
    }
    else if (parsedTemplate.sms_draft_template) {
        keysAndValues.push({
            key: "From",
            value: parsedTemplate.sms_draft_template.drafted_by_type === "assigned agent"
                ? dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.email
                : parsedTemplate.sms_draft_template.drafted_by,
        });
        keysAndValues.push({
            key: "To",
            value: `sms${formatPhoneNumber(parsedTemplate.sms_draft_template.use_agent_phone_number
                ? ((_p = dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.fields["Twilio #"]) !== null && _p !== void 0 ? _p : "")
                : ((_q = parsedTemplate.sms_draft_template.from_phone) !== null && _q !== void 0 ? _q : ""), { country: "US", format: "international" })}${parsedTemplate.sms_draft_template.to_phone
                .split(",")
                .map((x) => formatPhoneNumber(x.trim(), { country: "US", format: "international" }))
                .join("")}`,
        });
        keysAndValues.push({ key: "Message", value: parsedTemplate.sms_draft_template.message });
    }
    else if (parsedTemplate.calendar_template) {
        keysAndValues.push({
            key: "Host",
            value: parsedTemplate.calendar_template.assigned_agent_is_host
                ? dashboardUser === null || dashboardUser === void 0 ? void 0 : dashboardUser.email
                : parsedTemplate.calendar_template.host,
        });
        keysAndValues.push({ key: "Guests", value: parsedTemplate.calendar_template.guests });
        keysAndValues.push({ key: "Title", value: parsedTemplate.calendar_template.title });
        keysAndValues.push({ key: "Location", value: parsedTemplate.calendar_template.location });
        if (parsedTemplate.calendar_template.is_all_day_event) {
            keysAndValues.push({
                key: "Date",
                value: entityData === null || entityData === void 0 ? void 0 : entityData.fields[parsedTemplate.calendar_template.start_date_field_uid],
            });
        }
        keysAndValues.push({ key: "Description", value: parsedTemplate.calendar_template.description });
    }
    else if (parsedTemplate.crm_notification_template) {
        keysAndValues.push({ key: "Title", value: parsedTemplate.crm_notification_template.title });
        keysAndValues.push({ key: "Message", value: parsedTemplate.crm_notification_template.message });
    }
    else if (parsedTemplate.slack_template) {
        const channelID = parsedTemplate.slack_template.channel_id;
        const { recipients } = parsedTemplate.slack_template;
        if (channelID) {
            const slackChannelName = (_r = slackChannels === null || slackChannels === void 0 ? void 0 : slackChannels.items.find((channel) => channel["id"] === channelID)) === null || _r === void 0 ? void 0 : _r["name"];
            keysAndValues.push({ key: "Channel", value: slackChannelName !== null && slackChannelName !== void 0 ? slackChannelName : "(unknown)" });
        }
        if (recipients) {
            keysAndValues.push({ key: "Recpients", value: recipients });
        }
        keysAndValues.push({ key: "From", value: parsedTemplate.slack_template.bot_name });
        keysAndValues.push({ key: "Message", value: parsedTemplate.slack_template.message });
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 1 },
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                            React.createElement(Grid2, null,
                                React.createElement(RouterLink, { to: `/ms4/templates/${parsedTemplate.uid}` },
                                    React.createElement(Typography, { variant: "h6" }, parsedTemplate.name || "Preview"))),
                            React.createElement(Grid2, null,
                                React.createElement(Link, { href: `/boxes/${entity.uid}`, target: "_blank" },
                                    React.createElement(Typography, { variant: "body1" }, entityData === null || entityData === void 0 ? void 0 : entityData.name))))),
                    parseTemplateApi.isFetching && (React.createElement(Grid2, null,
                        React.createElement(CoreLoading, { size: 16 })))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Table, { size: "small" },
                        React.createElement(TableBody, null, keysAndValues.map((obj) => {
                            var _a;
                            return (React.createElement(TableRow, { key: obj.key },
                                React.createElement(TableCell, { style: { textAlign: "right", fontWeight: "bold" } }, obj.key),
                                React.createElement(TableCell, null, obj.key === "Body" ? (React.createElement(EmailPreview, { dangerouslySetInnerHTML: { __html: (_a = obj.value) !== null && _a !== void 0 ? _a : "" } })) : (React.createElement("div", { dangerouslySetInnerHTML: {
                                        __html: obj.value == null
                                            ? ""
                                            : obj.value.replaceAll("\n", "<br>").replaceAll("\t", "\u00A0\u00A0\u00A0\u00A0"),
                                    } })))));
                        })))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    (onClose || onCloseRedirectUrl) && (React.createElement(Grid2, null,
                        onClose && (React.createElement(Button, { variant: "text", startIcon: React.createElement(Clear, null), onClick: onClose }, "Close")),
                        onCloseRedirectUrl && (React.createElement(BackButton, { variant: "text", startIcon: React.createElement(Clear, null), url: onCloseRedirectUrl }, "Close")))),
                    (templateUid !== null && templateUid !== void 0 ? templateUid : template === null || template === void 0 ? void 0 : template.uid) && (React.createElement(Grid2, null,
                        React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(Send, null), onClick: processTemplate, disabled: isProcessing }, "Process"))))))));
};
export default MailScheduleTemplatePreview;
