import AppConfig from "@app/util/AppConfig";
import { renderDaysInStageCell, renderProducerLastUpdatedTimestampCell } from "./table-cells";
export const GROUP_ROW_HEIGHT = 42;
export const STANDARD_ROW_HEIGHT = 21;
export const getRows = (items, groups, getGroup, selectedGroup, isPaginated, entityType) => {
    var _a;
    const res = [];
    console.log({ items });
    const groupsByValue = {};
    for (const group of groups !== null && groups !== void 0 ? groups : []) {
        groupsByValue[group.value] = group;
    }
    let thisGroup = null;
    const groupsNotPushed = new Set((_a = groups === null || groups === void 0 ? void 0 : groups.map((g) => g.value)) !== null && _a !== void 0 ? _a : []);
    for (let i = 0; i < items.length; ++i) {
        const row = Object.assign(Object.assign({}, items[i]), { id: items[i].uid, __rowtype: "standard" });
        if (!isPaginated) {
            if (groups && (i === 0 || getGroup(items[i]) !== getGroup(items[i - 1]))) {
                const lastGroupValue = i === 0 ? null : getGroup(items[i - 1]);
                const thisGroupValue = getGroup(items[i]);
                thisGroup = groupsByValue[thisGroupValue];
                const groupsToPush = groups.filter((_, groupIndex) => groupIndex > groups.findIndex((g) => g.value === lastGroupValue) &&
                    groupIndex <= groups.findIndex((g) => g.value === thisGroupValue));
                for (const group of groupsToPush) {
                    // @ts-expect-error: not assignable to type "T"
                    res.push({
                        __group: group,
                        id: `group_${String(group.value)}`,
                        __rowtype: "group",
                        __selected: String(group.value) === selectedGroup,
                    });
                    groupsNotPushed.delete(group.value);
                }
            }
        }
        if (thisGroup == null || thisGroup.isVisible) {
            res.push(row);
        }
    }
    if (!isPaginated) {
        for (const groupValue of groupsNotPushed) {
            // @ts-expect-error: not assignable to type "T"
            res.push({
                __group: groupsByValue[groupValue],
                id: `group_${String(groupValue)}`,
                __rowtype: "group",
                __selected: String(groupValue) === selectedGroup,
            });
        }
    }
    res.forEach((row) => {
        var _a;
        // eslint-disable-next-line no-param-reassign
        row["entity_type"] = (_a = row["entity_type"]) !== null && _a !== void 0 ? _a : entityType;
    });
    return res;
};
export const getRowClassName = (params) => {
    if (params.row.__selected) {
        return "dashcrm-group dashcrm-group-selected";
    }
    if (params.row.__rowtype === "group") {
        return "dashcrm-group";
    }
    return "";
};
export const getRowHeight = (params) => {
    if (String(params.id).startsWith("group_")) {
        return GROUP_ROW_HEIGHT;
    }
    return STANDARD_ROW_HEIGHT;
};
export const isRowSelectable = (params) => {
    if (String(params.id).startsWith("group_")) {
        return false;
    }
    return true;
};
export const getGroupColor = (params) => {
    const { index, totalGroups } = params;
    const startColor = AppConfig.themeColors.lightestblue;
    const endColor = AppConfig.themeColors.blue;
    const percentage = index / (totalGroups - 1);
    const res = shadeBlendConvert(percentage, startColor, endColor);
    return res;
};
/**
 * Blend two colors
 * Courtesy of GitHub Copilot
 *
 * p = percentage
 * from = hex color
 * to = hex color
 */
export const shadeBlendConvert = (p, from, to) => {
    const f = parseInt(from.slice(1), 16);
    const t = parseInt(to.slice(1), 16);
    const R1 = f >> 16;
    const G1 = (f >> 8) & 0x00ff;
    const B1 = f & 0x0000ff;
    const R2 = t >> 16;
    const G2 = (t >> 8) & 0x00ff;
    const B2 = t & 0x0000ff;
    return `#${(0x1000000 +
        (Math.round((R2 - R1) * p) + R1) * 0x10000 +
        (Math.round((G2 - G1) * p) + G1) * 0x100 +
        (Math.round((B2 - B1) * p) + B1))
        .toString(16)
        .slice(1)}`;
};
export const DAYS_IN_STAGE_COLUMN = {
    field: "days_in_stage",
    headerName: "Days in Stage",
    renderCell: renderDaysInStageCell,
    align: "right",
    editable: false,
    sortable: false,
};
export const PRODUCER_LAST_UPDATED_TIMESTAMP_COLUMN = {
    field: "producer_last_updated_timestamp",
    headerName: "Producer Last Updated Timestamp",
    editable: false,
    renderCell: renderProducerLastUpdatedTimestampCell,
};
