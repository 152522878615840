import * as React from "react";
import { Add } from "@mui/icons-material";
import { Grid2, IconButton, Tooltip, Typography, Paper } from "@mui/material";
import numeral from "numeral";
import FormRow from "@app/entrypoints/brokerage/features/apps/matrix/components/FormRow";
import { withDialog } from "@app/hoc/withDialog";
import { createRenovationBudgetItem, deleteRenovationBudgetItem, updateRenovationBudgetItem, } from "@app/orval/api/renovation-budget";
import { createRentRollItem, deleteRentRollItem, updateRentRollItem } from "@app/orval/api/rent-roll";
import AppConfig from "@app/util/AppConfig";
import MAtrixLineItem from "./MAtrixLineItem";
const createTableHeader = (colHeaders) => (React.createElement(Grid2, { container: true, size: 12, style: { backgroundColor: AppConfig.themeColors.greyLighten } }, colHeaders.map((col) => {
    let inner = null;
    if (col.action) {
        const button = (React.createElement(IconButton, { size: "small", disabled: col.action.disabled, onClick: col.action.onClick, style: { display: "flex", margin: "auto" } }, col.action.icon));
        inner = col.action.helpText ? React.createElement(Tooltip, { title: col.action.helpText }, button) : button;
    }
    else {
        inner = React.createElement(Typography, null, col.name);
    }
    return (React.createElement(Grid2, { key: col.name, size: col.gridSize, style: { marginTop: "auto", marginBottom: "auto", paddingLeft: "4px" } }, inner));
})));
class MAtrixInvestor extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                matrix: this.props.matrix,
                isAddingRentRollLineItem: false,
                isAddingRenovationBudgetLineItem: false,
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "returnSummaryHeaderData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                { name: "", gridSize: 6 },
                { name: "IRR", gridSize: 2 },
                { name: "Profit", gridSize: 2 },
                { name: "Multiple", gridSize: 2 },
            ]
        });
        Object.defineProperty(this, "operatingExpensesHeaderData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                { name: "", gridSize: 5 },
                { name: "Fixed/ Variable", gridSize: 3 },
                { name: "$/Year", gridSize: 2 },
                { name: "$/Month", gridSize: 2 },
            ]
        });
        Object.defineProperty(this, "rentRollFieldData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                {
                    name: "unit",
                    label: "Unit",
                    type: "text",
                    gridSize: 1,
                },
                {
                    name: "unit_type",
                    label: "Unit Type",
                    type: "text",
                    gridSize: 1,
                },
                {
                    name: "size",
                    label: "Size",
                    type: "number",
                    format: "0,0",
                    gridSize: 1,
                },
                {
                    name: "current_price",
                    label: "Current",
                    type: "number",
                    format: "$0,0",
                    gridSize: 1,
                },
                {
                    name: "current_price_per_sqft",
                    label: "Current $/SF",
                    type: "formula",
                    format: "$0,0.00",
                    gridSize: 2,
                },
                {
                    name: "market_price",
                    label: "Market",
                    type: "formula",
                    format: "$0,0",
                    gridSize: 1,
                },
                {
                    name: "market_price_per_sqft",
                    label: "Mkt $/SF",
                    type: "number",
                    format: "$0,0.00",
                    gridSize: 1,
                },
                {
                    name: "roll_to_market",
                    label: "Roll to Mkt",
                    type: "text",
                    gridSize: 1,
                },
                {
                    name: "downtime",
                    label: "Downtime",
                    type: "text",
                    gridSize: 1,
                },
            ]
        });
        Object.defineProperty(this, "renovationBudgetFieldData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                {
                    name: "name",
                    label: "Renovation Budget",
                    type: "text",
                    gridSize: 6,
                },
                {
                    name: "cost",
                    label: "$",
                    type: "number",
                    format: "$0,0",
                    gridSize: 2,
                },
                {
                    name: "cost_per_sqft",
                    label: "$/SqFt",
                    type: "formula",
                    format: "$0,0.00",
                    gridSize: 2,
                },
            ]
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
            }
        });
        Object.defineProperty(this, "addRentRollLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { matrix } = this.state;
                this.setState({
                    isAddingRentRollLineItem: true,
                }, () => {
                    createRentRollItem({
                        matrix_uid: matrix.uid,
                        unit: null,
                        unit_type: null,
                        size: null,
                        current_price: null,
                        current_price_per_sqft: null,
                        market_price: null,
                        market_price_per_sqft: null,
                        roll_to_market: null,
                        downtime: null,
                    })
                        .then((result) => {
                        this.setState((prevState) => {
                            return {
                                matrix: Object.assign(Object.assign({}, prevState.matrix), { rent_roll_line_items: [...prevState.matrix.rent_roll_line_items, result.data] }),
                            };
                        });
                    })
                        .catch((error) => {
                        this.props.showErrorDialog(error);
                    })
                        .then(() => {
                        this.setState({
                            isAddingRentRollLineItem: false,
                        });
                    });
                });
            }
        });
        Object.defineProperty(this, "updateRentRollLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (lineItem) => {
                updateRentRollItem(lineItem.uid, lineItem).then((result) => {
                    this.setState((prevState) => {
                        return {
                            matrix: Object.assign(Object.assign({}, prevState.matrix), { rent_roll_line_items: prevState.matrix.rent_roll_line_items.map((item) => item.uid === lineItem.uid ? result.data : item) }),
                        };
                    });
                });
            }
        });
        Object.defineProperty(this, "deleteRentRollLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => {
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        deleteRentRollItem(uid).then(() => {
                            this.setState((prevState) => {
                                return {
                                    matrix: Object.assign(Object.assign({}, prevState.matrix), { rent_roll_line_items: prevState.matrix.rent_roll_line_items.filter((item) => item.uid !== uid) }),
                                };
                            });
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "addRenovationBudgetLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { matrix } = this.state;
                this.setState({
                    isAddingRenovationBudgetLineItem: true,
                }, () => {
                    createRenovationBudgetItem({
                        matrix_uid: matrix.uid,
                        name: null,
                        cost: null,
                    })
                        .then((result) => {
                        this.setState((prevState) => {
                            return {
                                matrix: Object.assign(Object.assign({}, prevState.matrix), { renovation_budget_line_items: [...prevState.matrix.renovation_budget_line_items, result.data] }),
                            };
                        });
                    })
                        .catch((error) => {
                        this.props.showErrorDialog(error);
                    })
                        .then(() => {
                        this.setState({
                            isAddingRenovationBudgetLineItem: false,
                        });
                    });
                });
            }
        });
        Object.defineProperty(this, "updateRenovationBudgetLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (lineItem) => {
                updateRenovationBudgetItem(lineItem.uid, lineItem).then((result) => {
                    this.setState((prevState) => {
                        return {
                            matrix: Object.assign(Object.assign({}, prevState.matrix), { renovation_budget_line_items: prevState.matrix.renovation_budget_line_items.map((item) => item.uid === lineItem.uid ? result.data : item) }),
                        };
                    });
                });
            }
        });
        Object.defineProperty(this, "deleteRenovationBudgetLineItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => {
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        deleteRenovationBudgetItem(uid).then(() => {
                            this.setState((prevState) => {
                                return {
                                    matrix: Object.assign(Object.assign({}, prevState.matrix), { renovation_budget_line_items: prevState.matrix.renovation_budget_line_items.filter((item) => item.uid !== uid) }),
                                };
                            });
                        });
                    },
                });
            }
        });
    }
    componentDidMount() {
        this.refresh();
    }
    render() {
        var _a, _b;
        const { matrixSchema, onFieldSaveBegin, onFieldSaveComplete } = this.props;
        const { isAddingRenovationBudgetLineItem, isAddingRentRollLineItem, matrix } = this.state;
        const buildingSize = matrix.fields["Building Size (SqFt)"].value;
        const appraisedValuationForRefinancing = matrix.fields["Refinance Value Calculations - Appraised Valuation for Refinancing (Manual)"];
        const refinanceCapRate = matrix.fields["Refinance Value Calculations - Cap Rate for Valuation"];
        const shouldCalculateSqFt = !(matrix.fields["Lot Size (Acres)"] === null || matrix.fields["Lot Size (Acres)"] === undefined);
        const shouldCalculateAcres = !(matrix.fields["Lot Size (SqFt)"] === null || matrix.fields["Lot Size (SqFt)"] === undefined);
        const shouldCalculateAppraisedValueForRefinance = !(refinanceCapRate === null || refinanceCapRate === undefined);
        const shouldCalculateCapRateForRefinance = !(appraisedValuationForRefinancing === null || appraisedValuationForRefinancing === undefined);
        const rentRollRentDifferenceFieldVal = matrix.fields["Rent Roll - Difference from Market"].value;
        const rentRollRentDifference = rentRollRentDifferenceFieldVal == null
            ? "n/a"
            : numeral(rentRollRentDifferenceFieldVal * 100).format((_a = matrixSchema["Rent Roll - Difference from Market"].format) !== null && _a !== void 0 ? _a : undefined);
        const rentRollHeaders = [
            ...this.rentRollFieldData.map((field) => ({
                name: field.label,
                gridSize: field.gridSize,
            })),
            {
                name: "rent_roll_action_header",
                gridSize: 2,
                action: {
                    helpText: "Add a Line Item",
                    icon: React.createElement(Add, null),
                    disabled: isAddingRentRollLineItem,
                    onClick: this.addRentRollLineItem,
                },
            },
        ];
        const renovationBudgetHeaders = [
            ...this.renovationBudgetFieldData.map((field) => ({
                name: field.label,
                gridSize: field.gridSize,
            })),
            {
                name: "renovation_budget_action_column",
                gridSize: 2,
                action: {
                    helpText: "Add a Line Item",
                    icon: React.createElement(Add, null),
                    disabled: isAddingRenovationBudgetLineItem,
                    onClick: this.addRenovationBudgetLineItem,
                },
            },
        ];
        const columns = [
            {
                key: "column1",
                size: 4,
                sections: [
                    {
                        name: "Property Information",
                        columns: [
                            {
                                key: "property_info_fields",
                                subsections: [
                                    {
                                        name: "",
                                        rows: [
                                            {
                                                name: "APN",
                                                fields: [
                                                    {
                                                        name: "APN",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Property Type",
                                                fields: [
                                                    {
                                                        name: "Home Type",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Zoning",
                                                fields: [
                                                    {
                                                        name: "Property Information Zoning",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Lot Size (SqFt)",
                                                fields: [
                                                    {
                                                        // allow agent to enter either acreage or sqft (show formula field for other)
                                                        name: shouldCalculateSqFt ? "Property Information - Lot Size (SqFt)" : "Lot Size (SqFt)",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Lot Size (Acres)",
                                                fields: [
                                                    {
                                                        // allow agent to enter either acreage or sqft (show formula field for other)
                                                        name: shouldCalculateAcres ? "Property Information - Lot Size (Acres)" : "Lot Size (Acres)",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "# of Units",
                                                fields: [
                                                    {
                                                        name: "Number of Units",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Avg Unit Size",
                                                fields: [
                                                    {
                                                        name: "Rent Roll - Avg Size",
                                                        endText: "sf",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Building Size",
                                                fields: [
                                                    {
                                                        name: "Building Size (SqFt)",
                                                        endText: "sf",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Added Square Footage",
                                                fields: [
                                                    {
                                                        name: "Property Information Added SqFt",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "New Total Building Size",
                                                fields: [
                                                    {
                                                        name: "New Total Building Size (SqFt)",
                                                        endText: "sf",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Year Built",
                                                fields: [
                                                    {
                                                        name: "Year Built",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Parking Spaces",
                                                fields: [
                                                    {
                                                        name: "Parking Spaces",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "First Mortgage Assumptions",
                        columns: [
                            {
                                key: "first_mortgage_assumption_fields",
                                subsections: [
                                    {
                                        name: "Loan Assumptions",
                                        rows: [
                                            {
                                                name: "Loan-to-Value",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Loan-to-Value",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Proceeds",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Loan Proceeds",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Interest Rate",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Interest Rate",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Type",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Loan Type",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Term (Years)",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Loan Term (Years)",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Term (Months)",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Loan Term (Months)",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Renovation Costs Included in Loan?",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Renovation Costs Included in Loan?",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Renovation Costs Included",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Renovation Costs Included",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Total Loan Proceeds",
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Total Loan Proceeds",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Implied Total Value",
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Implied Total Value",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Total Down Payment",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Total Down Payment (%)",
                                                        // size: 3,
                                                        multiplyBy100: true,
                                                    },
                                                    {
                                                        name: "Total Down Payment",
                                                        // size: 4,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Monthly Payment (Loan Only)",
                                                fields: [
                                                    {
                                                        name: "Loan Assumptions - Monthly Payment (Loan Only)",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        name: "Mortgage Insurance (PMI/MIP)",
                                        rows: [
                                            {
                                                name: "Required (if LTV exceeds)",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - LTV Limit",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - Required (If LTV Exceeds Limit)",
                                                        size: 3,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Upfront Rate",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - Upfront Rate",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - Upfront Rate (If LTV Exceeds Limit)",
                                                        size: 3,
                                                        multiplyBy100: true,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "PMI Upfront Cost",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "PMI Upfront Cost",
                                                        size: 6,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Annual Rate",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - Annual Rate",
                                                        size: 6,
                                                        multiplyBy100: true,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Annual Cost",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - Annual Cost",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "PMI Term (Max Duration)",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "PMI Term Max Duration",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "PMI Term Max Duration (If LTV Exceeds Limit)",
                                                        size: 3,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Pay Down Loan Balance w/ Positive CF?",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - Pay Down Loan Balance w/ Positive CF",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "End Loan Pay Down In",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - End Loan Pay Down In",
                                                        size: 3,
                                                        startText: "Month",
                                                    },
                                                    {
                                                        name: "Mortgage Insurance (PMI/MIP) - End Loan Pay Down In (If Refinance and Monthly Payment)",
                                                        size: 3,
                                                        startText: "Month",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Renovation Assumptions & Summary",
                        columns: [
                            {
                                key: "renovation_assumptions_and_summary_column_1_fields",
                                subsections: [
                                    {
                                        name: "",
                                        rows: [
                                            {
                                                name: "Renovation Occurs?",
                                                fields: [
                                                    {
                                                        name: "Renovation Assumptions - Renovation Occurs?",
                                                    },
                                                ],
                                                rowStyle: {
                                                    marginBottom: "4px",
                                                },
                                            },
                                            {
                                                name: "renovation_assumptions_header",
                                                fields: [],
                                                customLayout: createTableHeader(renovationBudgetHeaders),
                                            },
                                            ...matrix.renovation_budget_line_items.map((lineItem) => {
                                                const renovationBudgetFormulaFieldValues = [
                                                    {
                                                        name: "cost_per_sqft",
                                                        value: lineItem.cost !== null && buildingSize ? lineItem.cost / buildingSize : null,
                                                    },
                                                ];
                                                return {
                                                    name: `line_item_${lineItem.uid}`,
                                                    fields: [],
                                                    customLayout: (React.createElement(Grid2, { container: true, size: 12 },
                                                        React.createElement(MAtrixLineItem, { lineItem: lineItem, type: "Renovation Budget", lineItemFieldData: this.renovationBudgetFieldData, formulaFieldValues: renovationBudgetFormulaFieldValues, onSaveRenovationBudget: this.updateRenovationBudgetLineItem, onDelete: this.deleteRenovationBudgetLineItem }))),
                                                };
                                            }),
                                            {
                                                name: "Contingency",
                                                labelSize: 4,
                                                fields: [
                                                    {
                                                        name: "Renovation Assumptions - Contingency",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Renovation Assumptions - Contingency ($)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Renovation Assumptions - Contingency ($/SqFt)",
                                                        size: 2,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Total",
                                                labelSize: 6,
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Renovation Assumptions - Total",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Renovation Assumptions - Total ($/SqFt)",
                                                        size: 2,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Cost Per Unit",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Renovation Assumptions - Cost Per Unit",
                                                        size: 6,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Cost Per Added Square Foot",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Renovation Assumptions - Cost Per SqFt",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Construction & Reassessment Assumptions",
                        columns: [
                            {
                                key: "construction_and_reassessment_column_1_fields",
                                subsections: [
                                    {
                                        name: "Construction Schedule",
                                        rows: [
                                            {
                                                name: "Construction Start",
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Construction Start",
                                                        startText: "Month",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Duration",
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Duration",
                                                        endText: "Months",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Construction Complete In",
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Construction Complete In",
                                                        startText: "Month",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Property Reassessed After Renovation?",
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Property Reassessed After Renovation?",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Tax Reassessment Month",
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Tax Reassessment Month",
                                                        startText: "Month",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Adjusted Tax Basis Post Renovation",
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Adjusted Tax Basis Post Renovation",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Adjusted Tax Basis ($/SqFt)",
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Adjusted Tax Basis ($/SqFt)",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Adjusted Tax Basis ($/Unit)",
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Construction & Reassessment Assumptions - Adjusted Tax Basis ($/Unit)",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                key: "column2",
                size: 8,
                sections: [
                    {
                        name: "Purchase Assumptions & Summary",
                        columns: [
                            {
                                key: "purchase_assumptions_and_summary_column_1_fields",
                                size: 6,
                                subsections: [
                                    {
                                        name: "Acquisition Costs",
                                        rows: [
                                            {
                                                name: "Listing Price",
                                                fields: [
                                                    {
                                                        name: "Current List Price",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Negotiated Discount",
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Negotiated Discount",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Negotiated Purchase Price",
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Negotiated Purchase Price",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Purchase Price/Unit",
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Purchase Price/Unit",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Purchase Price/SqFt",
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Purchase Price/SqFt",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Closing Costs",
                                                fields: [
                                                    {
                                                        name: "Total Closing Costs ($)",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "All-in Purchase Price",
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - All-in Purchase Price",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Going-in Cap Rate (Year 1 NOI)",
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Going-in Cap Rate (Year 1 NOI)",
                                                        multiplyBy100: true,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "PMI Upfront Cost",
                                                fields: [
                                                    {
                                                        name: "PMI Upfront Cost",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Purchase Price + Renovation Costs",
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Purchase Price + Renovation Costs",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Total Down Payment",
                                                fields: [
                                                    {
                                                        name: "Total Down Payment",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Cash Due at Closing",
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Cash Due at Closing",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Total Cash Invested Through Exit",
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Total Cash Invested Through Exit",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                key: "purchase_assumptions_and_summary_column_2_fields",
                                size: 6,
                                subsections: [
                                    {
                                        name: "Closing Costs",
                                        rows: [
                                            {
                                                name: "Agent",
                                                fields: [
                                                    {
                                                        name: "Closing Costs - Agent (%)",
                                                    },
                                                    {
                                                        name: "Closing Costs - Agent ($)",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Lender Fees",
                                                fields: [
                                                    {
                                                        name: "Closing Costs - Lender Fees",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Discount Fee",
                                                fields: [
                                                    {
                                                        name: "Closing Costs - Loan Discount Fee",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Other Fees",
                                                fields: [
                                                    {
                                                        name: "Closing Costs - Other Fees (%)",
                                                    },
                                                    {
                                                        name: "Closing Costs - Other Fees ($)",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Total",
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Total Closing Costs (%)",
                                                    },
                                                    {
                                                        name: "Total Closing Costs ($)",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        name: "Hold & Exit Assumptions",
                                        rows: [
                                            {
                                                name: "Analysis Start Date",
                                                fields: [
                                                    {
                                                        name: "Hold & Exit Assumptions - Analysis Start Date",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Hold Period (Months)",
                                                fields: [
                                                    {
                                                        name: "Hold & Exit Assumptions - Hold Period",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Exit Date",
                                                fields: [
                                                    {
                                                        name: "Hold & Exit Assumptions - Exit Date",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Exit Cap Rate",
                                                fields: [
                                                    {
                                                        name: "Hold & Exit Assumptions - Exit Cap Rate",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Sale Costs",
                                                fields: [
                                                    {
                                                        name: "Hold & Exit Assumptions - Sale Costs",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Refinance/2nd Loan Assumptions & Summary",
                        columns: [
                            {
                                key: "refinance_2nd_loan_column_1_fields",
                                size: 6,
                                subsections: [
                                    {
                                        name: "Loan Assumptions",
                                        rows: [
                                            {
                                                name: "Refinance?",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Refinance?",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Refinance Month",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Refinance Month",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Interest Rate",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Interest Rate",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Type",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Loan Type",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Term (Years)",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Loan Term (Years)",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Loan Term (Months)",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Loan Term (Months)",
                                                        size: 6,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Monthly Payment",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Monthly Payment",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Pay Down Loan Balance w/ Positive CF?",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - Pay Down Loan Balance w/ Positive CF?",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "End Loan Pay Down In",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Second Loan Assumptions - End Loan Pay Down In",
                                                        size: 3,
                                                        startText: "Month",
                                                    },
                                                    {
                                                        name: "Second Loan Assumptions - End Loan Pay Down In (If Refinance and Positive CF)",
                                                        size: 3,
                                                        startText: "Month",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                key: "refinance_2nd_loan_column_2_fields",
                                size: 6,
                                subsections: [
                                    {
                                        name: "Refinance Value Calculations",
                                        rows: [
                                            {
                                                name: "Purchase + Renovations ($)",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Purchase + Renovations ($)",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Purchase + Renovations ($/SqFt)",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Purchase + Renovations ($/SqFt)",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "12 Months Forward NOI from Refinance Month",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Forward 12 Months NOI from Refinance Month",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Cap Rate for Valuation",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: shouldCalculateCapRateForRefinance
                                                            ? "Refinance Value Calculations - Cap Rate for Valuation (Formula)"
                                                            : "Refinance Value Calculations - Cap Rate for Valuation",
                                                        size: 5,
                                                        multiplyBy100: shouldCalculateCapRateForRefinance,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Appraised Valuation for Refinancing",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: shouldCalculateAppraisedValueForRefinance
                                                            ? "Refinance Value Calculations - Appraised Valuation for Refinancing"
                                                            : "Refinance Value Calculations - Appraised Valuation for Refinancing (Manual)",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Appraised Value ($/SqFt)",
                                                labelSize: 7,
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Appraised Value ($/SqFt)",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Post Renovation/Market-to-Market Premium",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Post Renovation/Market-to-Market Premium",
                                                        size: 5,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Total Equity in Property at Refinancing",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Total Equity in Property at Refinancing",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Equity as Share of Property Value",
                                                labelSize: 7,
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Equity as Share of Property Value",
                                                        size: 5,
                                                        multiplyBy100: true,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Loan-to-Value",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Loan-to-Value",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Refinance Loan Proceeds",
                                                labelSize: 7,
                                                fields: [
                                                    {
                                                        name: "Refinance Value Calculations - Refinance Loan Proceeds",
                                                        size: 5,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        name: "Cash Out Summary",
                                        rows: [
                                            {
                                                name: "First Loan Payoff",
                                                fields: [
                                                    {
                                                        name: "Cash Out Summary - First Loan Payoff",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Cash Out",
                                                fields: [
                                                    {
                                                        name: "Cash Out Summary - Cash Out",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Cash Invested to Refinance Month",
                                                fields: [
                                                    {
                                                        name: "Cash Out Summary - Cash Invested to Refinance Month",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Cash Out > Investment",
                                                fields: [
                                                    {
                                                        name: "Cash Out Summary - Cash Out > Investment",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Additional Cash Out",
                                                fields: [
                                                    {
                                                        name: "Cash Out Summary - Additional Cash Out",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Rent & Operating Assumptions",
                        columns: [
                            {
                                key: "rent_and_operating_assumptions_column_1_fields",
                                size: 6,
                                subsections: [
                                    {
                                        name: "Income and Loss Assumptions",
                                        rows: [
                                            {
                                                name: "Property Tax Rate",
                                                fields: [
                                                    {
                                                        name: "Income and Loss Assumptions - Property Tax Rate",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Vacancy Rate",
                                                fields: [
                                                    {
                                                        name: "Income and Loss Assumptions - Vacancy Rate",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Credit Loss",
                                                fields: [
                                                    {
                                                        name: "Income and Loss Assumptions - Credit Loss",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Other Income",
                                                fields: [
                                                    {
                                                        name: "Income and Loss Assumptions - Other Income",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        name: "Growth Assumptions",
                                        rows: [
                                            {
                                                name: "Rent Growth",
                                                fields: [
                                                    {
                                                        name: "Growth Assumptions - Rent Growth",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "OpEx Growth",
                                                fields: [
                                                    {
                                                        name: "Growth Assumptions - OpEx Growth",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Property Tax Growth",
                                                fields: [
                                                    {
                                                        name: "Growth Assumptions - Property Tax Growth",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "CapEx Growth",
                                                fields: [
                                                    {
                                                        name: "Growth Assumptions - CapEx Growth",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                key: "rent_and_operating_assumptions_column_2_fields",
                                size: 6,
                                subsections: [
                                    {
                                        name: "Operating Expenses",
                                        rows: [
                                            {
                                                name: "operating_expenses_table_header",
                                                fields: [],
                                                customLayout: createTableHeader(this.operatingExpensesHeaderData),
                                            },
                                            {
                                                name: "Utilities",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "Operating Expenses - Utilities (Fixed/Variable)",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Utilities ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Utilities ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Landscaping",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "Operating Expenses - Landscaping (Fixed/Variable)",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Landscaping ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Landscaping ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Repairs & Maintenance",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "Operating Expenses - Repairs & Maintenance (Fixed/Variable)",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Repairs & Maintenance ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Repairs & Maintenance ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Trash",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "Operating Expenses - Trash (Fixed/Variable)",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Trash ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Trash ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Insurance",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "Operating Expenses - Insurance (Fixed/Variable)",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Insurance ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Insurance ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Property Management Fee",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Property Management Fee",
                                                        size: 4,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "General & Administrative",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "Operating Expenses - General & Administrative (Fixed/Variable)",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - General & Administrative ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - General & Administrative ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Capital Expenses",
                                                labelSize: 5,
                                                fields: [
                                                    {
                                                        name: "",
                                                        size: 3,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Capital Expenses ($/Year) (Formula)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Operating Expenses - Capital Expenses ($/Month) (Manual)",
                                                        size: 2,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                key: "rent_and_operating_assumptions_column_3_fields",
                                size: 12,
                                subsections: [
                                    {
                                        name: "Rent Roll",
                                        rows: [
                                            {
                                                name: "rent_roll_table_header",
                                                fields: [],
                                                customLayout: createTableHeader(rentRollHeaders),
                                            },
                                            ...matrix.rent_roll_line_items.map((lineItem) => ({
                                                name: `line_item_${lineItem.uid}`,
                                                fields: [],
                                                customLayout: (React.createElement(Grid2, { container: true, size: 12 },
                                                    React.createElement(MAtrixLineItem, { lineItem: lineItem, type: "Rent Roll", lineItemFieldData: this.rentRollFieldData, onSaveRentRoll: this.updateRentRollLineItem, onDelete: this.deleteRentRollLineItem }))),
                                            })),
                                            {
                                                name: "Total/Avg.",
                                                labelSize: 2,
                                                labelStyle: {
                                                    fontWeight: "bold",
                                                    textAlign: "left",
                                                    paddingRight: "8px",
                                                    paddingLeft: "4px",
                                                },
                                                fields: [
                                                    {
                                                        name: "Rent Roll - Avg Size",
                                                        size: 1,
                                                    },
                                                    {
                                                        name: "Rent Roll - Avg Current Price",
                                                        size: 1,
                                                    },
                                                    {
                                                        name: "Rent Roll - Avg Current Price per SqFt",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Rent Roll - Avg Market Price",
                                                        size: 1,
                                                    },
                                                    {
                                                        name: "Rent Roll - Avg Market Price per SqFt",
                                                        size: 1,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Difference from Market",
                                                fields: [],
                                                customLayout: (React.createElement(Grid2, { size: 12 },
                                                    React.createElement(Typography, { style: { textAlign: "center" } },
                                                        "Current rents are ",
                                                        rentRollRentDifference,
                                                        " below market rents"))),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: "Return Summary",
                        columns: [
                            {
                                key: "return_summary_column_1_fields",
                                subsections: [
                                    {
                                        name: "",
                                        rows: [
                                            {
                                                name: "return_summary_table_header",
                                                fields: [],
                                                customLayout: createTableHeader(this.returnSummaryHeaderData),
                                            },
                                            {
                                                name: "Unlevered",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Return Summary - Unlevered (IRR)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Return Summary - Unlevered (Profit)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Return Summary - Unlevered (Multiple)",
                                                        size: 2,
                                                        endText: "x",
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Levered",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Return Summary - Levered (IRR)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Return Summary - Levered (Profit)",
                                                        size: 2,
                                                    },
                                                    {
                                                        name: "Return Summary - Levered (Multiple)",
                                                        size: 2,
                                                        endText: "x",
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Stabilized Cash Yield",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Return Summary - Stabilized Cash Yield (Year)",
                                                        size: 4,
                                                        unit: "Year",
                                                    },
                                                    {
                                                        name: "Return Summary - Stabilized Cash Yield (Percent)",
                                                        size: 2,
                                                        multiplyBy100: true,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Total Equity Invested",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Acquisition Costs - Total Cash Invested Through Exit",
                                                        size: 6,
                                                    },
                                                ],
                                                divider: true,
                                            },
                                            {
                                                name: "Exit Price",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Return Summary - Exit Price",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Exit Price/Unit",
                                                labelSize: 6,
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Return Summary - Exit Price/Unit",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Exit Price/SqFt",
                                                labelSize: 6,
                                                labelStyle: {
                                                    fontStyle: "italic",
                                                    paddingRight: "8px",
                                                    textAlign: "right",
                                                },
                                                fields: [
                                                    {
                                                        name: "Return Summary - Exit Price/SqFt",
                                                        size: 6,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Total Appreciation Through Exit",
                                                labelSize: 6,
                                                fields: [
                                                    {
                                                        name: "Return Summary - Total Appreciation Through Exit",
                                                        size: 6,
                                                        multiplyBy100: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ];
        return (React.createElement(Grid2, { container: true, spacing: 4, style: { height: "100%", width: "100%", margin: 0 } },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "h6" }, `Investment Property Pro Forma - ${(_b = matrix.fields["Property Address"]) !== null && _b !== void 0 ? _b : ""}`)),
            columns.map((col) => (React.createElement(Grid2, { key: col.key, size: { xs: 12, md: col.size } }, col.sections.map((section) => (React.createElement(Paper, { key: `${col.key}_${section.name}`, elevation: 1, style: { marginBottom: "16px" } },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { size: 12, style: {
                            marginLeft: "4px !important",
                            marginRight: "4px !important",
                            backgroundColor: AppConfig.themeColors.primary,
                            paddingLeft: "4px",
                        } },
                        React.createElement(Typography, { variant: "h6" }, section.name)),
                    section.columns.map((sectionCol) => {
                        var _a;
                        return (React.createElement(Grid2, { key: `${section.name}_${sectionCol.key}`, container: true, size: { xs: 12, md: (_a = sectionCol.size) !== null && _a !== void 0 ? _a : 12 }, style: {
                                height: "100%",
                                paddingBottom: "12px",
                            } }, sectionCol.subsections.map((subSection) => (React.createElement(React.Fragment, null,
                            subSection.name && (React.createElement(Grid2, { size: 12, style: {
                                    backgroundColor: AppConfig.themeColors.greyLighten,
                                    borderBottom: "2px solid black",
                                    height: "25px",
                                    paddingLeft: "4px",
                                } },
                                React.createElement(Typography, { style: { fontWeight: "bold", lineHeight: "25px" } }, subSection.name))),
                            subSection.rows.map((row) => {
                                var _a;
                                return (React.createElement(FormRow, { key: `${subSection.name}_${row.name}`, uid: matrix.uid, rowData: Object.assign(Object.assign({}, row), { fields: row.fields.map((field) => (Object.assign(Object.assign({}, field), { value: matrix.fields[field.name] }))) }), 
                                    // matrixFields={matrix.fields}
                                    schema: matrixSchema, rowStyle: Object.assign({ height: "25px" }, ((_a = row.rowStyle) !== null && _a !== void 0 ? _a : {})), onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete }));
                            }))))));
                    }))))))))));
    }
}
export default withDialog(MAtrixInvestor);
