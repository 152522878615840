import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
const Fallback = () => {
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid, { container: true, direction: "column", spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "h4" }, "Something went wrong")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, null, "An internal error occurred. Refresh the page to re-open the app.")))));
};
export default Fallback;
