import * as React from "react";
import { useSearchParams } from "react-router-dom";
import CoreError from "@app/common/CoreError";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import Loading from "@app/common/Loading";
import { withRouter } from "@app/hoc/withRouter";
import { useListStreakPipelines } from "@app/orval/api/streak-pipelines";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
import MailScheduleTemplateForm from "./MailScheduleTemplateForm";
import MailScheduleTemplateReorder from "./MailScheduleTemplateReorder";
import MailScheduleTemplatesSidebar from "./MailScheduleTemplatesSidebar";
import MailScheduleTemplatesTable from "./MailScheduleTemplatesTable";
const MailScheduleTemplatesWrapper = (props) => {
    var _a, _b;
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = React.useState(true);
    const streakPipelinesListApi = useListStreakPipelines(DEFAULT_CACHE_PARAMS);
    const pipelines = (_b = (_a = streakPipelinesListApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [searchParams, _] = useSearchParams();
    const pipeline = searchParams.get("pipeline");
    // private abortController: AbortController | null = null;
    const toggleFilterSidebar = React.useCallback(() => {
        setIsFilterSidebarOpen((prevState) => {
            return !prevState;
        });
    }, [setIsFilterSidebarOpen]);
    const { uid } = props.params;
    const loading = [
        {
            label: "Loading Streak pipelines...",
            status: streakPipelinesListApi.status,
        },
    ];
    const { error } = streakPipelinesListApi;
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (uid || props.location.pathname.includes("/new")) {
        return React.createElement(MailScheduleTemplateForm, { uid: uid });
    }
    if (props.location.pathname.includes("/reorder")) {
        return React.createElement(MailScheduleTemplateReorder, { pipeline: pipeline });
    }
    if (loading.find((x) => x.status === "pending") || !pipelines) {
        return React.createElement(Loading, { items: loading });
    }
    if (!uid) {
        const params = new URLSearchParams(props.location.search);
        if (!params.get("pipeline")) {
            params.set("pipeline", pipelines[0].key);
            props.navigate(`/mailschedule/templates?${params.toString()}`);
            // @ts-expect-error Type 'null' is not assignable to type 'Element'
            return null;
        }
    }
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(MailScheduleTemplatesTable, { onSidebarToggle: toggleFilterSidebar }), sidebar: React.createElement(MailScheduleTemplatesSidebar, { onSidebarToggle: toggleFilterSidebar }), sidebarWidth: 250, opened: isFilterSidebarOpen }));
};
export default withRouter(MailScheduleTemplatesWrapper);
