import * as React from "react";
import { Grid2 } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import styled from "styled-components";
const StyledLightbox = styled(Lightbox) `
  & .yarl_root {
    background: transparent;
  }
  & .yarl__container {
    background: rgba(0, 0, 0, 0.85);
  }
`;
const MLSPropertyPhotosCarousel = (props) => {
    var _a;
    const { imageWidthPx, photos } = props;
    const [lightboxStartPhotoIndex, setLightboxStartPhotoIndex] = React.useState(null);
    const openLightbox = (startIndex) => () => {
        setLightboxStartPhotoIndex(startIndex);
    };
    const closeLightbox = React.useCallback(() => {
        setLightboxStartPhotoIndex(null);
    }, []);
    const carouselWrapperRef = React.useRef(null);
    const imageWidth = imageWidthPx !== null && imageWidthPx !== void 0 ? imageWidthPx : 100;
    const width = (_a = carouselWrapperRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
    const groupedPhotos = width == null
        ? photos.map((photo) => [photo])
        : photos.map((_, i) => photos.slice(i, i + Math.floor(width / imageWidth)));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: carouselWrapperRef, style: { width: "100%", textAlign: "center" } },
            React.createElement(Carousel, { autoPlay: false, cycleNavigation: false, indicators: false, sx: { width: "100%" } }, groupedPhotos.map((photosSlice, sliceIndex) => (React.createElement(Grid2, { key: photosSlice[0].url, container: true, spacing: 2, wrap: "nowrap" },
                React.createElement(Grid2, { style: { flex: 1 } }),
                photosSlice.map((photo, photoIndex) => (React.createElement(Grid2, { key: photo.url },
                    React.createElement("img", { key: photo.url, style: {
                            width: `${imageWidth}px`,
                            height: `${imageWidth}px`,
                            borderRadius: `${Math.round(imageWidth / 12)}px`,
                            cursor: "pointer",
                        }, src: photo.url, alt: photo.description || photo.url, onClick: openLightbox(sliceIndex + photoIndex) })))),
                React.createElement(Grid2, { style: { flex: 1 } })))))),
        React.createElement(StyledLightbox, { animation: { fade: 250, swipe: 0, easing: { fade: "ease", swipe: "ease-out", navigation: "ease-in-out" } }, open: lightboxStartPhotoIndex != null, close: closeLightbox, slides: photos.map((photo) => ({ src: photo.url })), index: lightboxStartPhotoIndex !== null && lightboxStartPhotoIndex !== void 0 ? lightboxStartPhotoIndex : 0 })));
};
export default MLSPropertyPhotosCarousel;
