import * as React from "react";
import { Alert, Button, Grid, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
const ValidationError = (props) => {
    var _a;
    const { error } = props;
    const session = useSession();
    const canManageFields = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin");
    return (React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
        React.createElement(Grid, { item: true }, canManageFields && error.url ? (React.createElement(RouterLink, { to: error.url },
            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, error.name))) : (React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, error.name))),
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: "body2" }, String(error.error)))));
};
const ValidationErrors = (props) => {
    var _a, _b;
    const { errors } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const toggleCollapse = React.useCallback(() => {
        setIsCollapsed((prev) => !prev);
    }, [setIsCollapsed]);
    const session = useSession();
    const canManageFields = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin");
    return (React.createElement(Alert, { severity: "error", sx: { "& .MuiAlert-message": { width: "100%" } } },
        React.createElement(Grid, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } },
                            errors.length,
                            " validation error",
                            errors.length === 1 ? "" : "s",
                            " found")),
                    errors.slice(0, 3).map((item) => (React.createElement(Grid, { key: String(item.error), item: true },
                        React.createElement(ValidationError, { error: item })))),
                    errors.length > 3 && (React.createElement(React.Fragment, null,
                        !isCollapsed && (React.createElement(React.Fragment, null, errors.slice(3).map((item) => (React.createElement(Grid, { key: String(item.error), item: true },
                            React.createElement(ValidationError, { error: item })))))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { size: "small", onClick: toggleCollapse }, isCollapsed ? "Show more" : "Show less")))))),
            canManageFields && (React.createElement(Grid, { item: true },
                React.createElement(Button, { component: RouterLink, to: (_b = errors[0].url) !== null && _b !== void 0 ? _b : "/field-management", size: "small" }, "Resolve"))))));
};
export default ValidationErrors;
