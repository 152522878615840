import * as React from "react";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import MAtrixTable from "./MAtrixTable";
import MAtrixTableSidebar from "./MAtrixTableSidebar";
const MAtrixPage = () => {
    useDocumentTitle("MAtrix");
    const [showFilterSidebar, setShowFilterSidebar] = React.useState(true);
    const toggleFilterSidebar = React.useCallback(() => {
        setShowFilterSidebar((prevState) => !prevState);
    }, []);
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(MAtrixTable, { onSidebarToggle: toggleFilterSidebar }), sidebar: React.createElement(MAtrixTableSidebar, { onSidebarToggle: toggleFilterSidebar }), sidebarWidth: 250, opened: showFilterSidebar }));
};
export default MAtrixPage;
