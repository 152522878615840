import * as React from "react";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Box, Divider, Grid2, ListItem, ListItemText, ListSubheader, Tooltip, Typography, List, IconButton, } from "@mui/material";
import pickBy from "lodash/pickBy";
import dayjs_ from "@app/common/dayjs";
import { listTransactions } from "@app/orval/api/agent-dashboard";
import { useGetSlaData } from "@app/orval/api/reports-sla";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const SLAViolationsDetailed = (props) => {
    const { agentName, agentUid, fromDate, onClose, toDate } = props;
    const query = React.useMemo(() => {
        return pickBy({
            agent_uid: agentUid,
            from_date: fromDate,
            to_date: toDate,
        }, (val) => val != null);
    }, [agentUid, fromDate, toDate]);
    const listViolationsApi = useGetSlaData(query);
    const violations = React.useMemo(() => {
        if (!listViolationsApi.data) {
            return null;
        }
        return listViolationsApi.data.data
            .map((x) => (Object.assign(Object.assign({}, x), { id: `${x.box.uid}_${x.notification_date}_${x.producer_uid}_${x.title}` })))
            .sort((a, b) => {
            if (a.notification_date > b.notification_date)
                return -1;
            if (a.notification_date < b.notification_date)
                return 1;
            return 0;
        });
    }, [listViolationsApi.data]);
    const transactionUids = React.useMemo(() => {
        if (!violations) {
            return [];
        }
        return violations.map((x) => x.box.uid);
    }, [violations]);
    const searchTransactionsApi = usePostQuery(listTransactions, { uids: transactionUids }, {}, transactionUids.length > 0);
    const transactions = React.useMemo(() => {
        if (!searchTransactionsApi.data) {
            return null;
        }
        return searchTransactionsApi.data.data;
    }, [searchTransactionsApi.data]);
    const transactionsByUid = React.useMemo(() => {
        if (!transactions) {
            return {};
        }
        return transactions.reduce((acc, x) => {
            acc[x.uid] = x;
            return acc;
        }, {});
    }, [transactions]);
    const hideDetails = React.useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, style: { transform: "translateY(8px)" } },
                    React.createElement(Grid2, { style: { flex: 1 } }, agentName && (React.createElement(Typography, null,
                        agentName,
                        violations && `(${violations.length})`))),
                    onClose && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Tooltip, { title: "Close" },
                            React.createElement(IconButton, { size: "small", onClick: hideDetails },
                                React.createElement(Close, { fontSize: "small" })))))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        violations && (React.createElement(Grid2, null,
            React.createElement(List, { dense: true }, violations.map((violation, i) => {
                var _a;
                const previousViolation = i > 0 ? violations[i - 1] : null;
                return (React.createElement(React.Fragment, null,
                    violation.notification_date !== (previousViolation === null || previousViolation === void 0 ? void 0 : previousViolation.notification_date) && (React.createElement(ListSubheader, { key: `${violation.id}_subheader`, style: { background: "white" } }, dayjs_(violation.notification_date).format("dddd, MMMM D, YYYY"))),
                    React.createElement(ListItem, { key: violation.id },
                        React.createElement(ListItemText, { primary: React.createElement(Link, { to: `/boxes/${violation.box.uid}`, target: "_blank" }, violation.title), 
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            secondary: (_a = transactionsByUid[violation.box.uid]) === null || _a === void 0 ? void 0 : _a.name }))));
            }))))));
};
export default SLAViolationsDetailed;
