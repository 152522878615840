var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { CanceledError } from "axios";
import { searchSellerLeads } from "@app/orval/api/seller-leads";
import ServerSideAutocomplete from "./ServerSideAutocomplete";
const getOptionLabel = (option) => option.name || "";
const renderOption = (props, option) => {
    return (React.createElement("li", Object.assign({}, props, { key: option.uid }), option.name));
};
const isOptionEqualToValue = (option, value) => (option === null || option === void 0 ? void 0 : option.uid) == (value === null || value === void 0 ? void 0 : value.uid);
const SellerLeadAutocomplete = (props) => {
    const other = __rest(props, []);
    const [isLoading, setIsLoading] = React.useState(false);
    const abortController = React.useRef(null);
    const doSearch = React.useCallback((searchString) => __awaiter(void 0, void 0, void 0, function* () {
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();
        setIsLoading(true);
        let items = [];
        try {
            const res = yield searchSellerLeads({ search: searchString, limit: 10 }, { signal: abortController.current.signal });
            items = res.data;
            setIsLoading(false);
        }
        catch (e) {
            if (!(e instanceof CanceledError)) {
                console.error(e);
                setIsLoading(false);
            }
        }
        return items;
    }), [setIsLoading]);
    return (React.createElement(ServerSideAutocomplete, Object.assign({}, other, { getOptionLabel: getOptionLabel, renderOption: renderOption, isOptionEqualToValue: isOptionEqualToValue, noOptionsText: "No seller leads found", doSearch: doSearch, isLoading: isLoading })));
};
export default SellerLeadAutocomplete;
