import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import QFOAutocomplete from "../../components/box-view/actions/QFOAutocomplete";
const LinkQFODialog = (props) => {
    var _a;
    const { DialogProps, autocompleteFilter, onClose, onLinkQFO } = props;
    const [selectedQFO, setSelectedQFO] = React.useState(null);
    const setSelectedQFO_ = React.useCallback((_, qfo) => {
        setSelectedQFO(qfo);
    }, [setSelectedQFO]);
    const linkQFO = React.useCallback(() => {
        onLinkQFO(selectedQFO.uid);
        onClose();
    }, [onClose, onLinkQFO, selectedQFO]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "xs", fullWidth: true }, DialogProps, { open: (_a = DialogProps === null || DialogProps === void 0 ? void 0 : DialogProps.open) !== null && _a !== void 0 ? _a : false }),
        React.createElement(DialogTitle, null, "Select a QFO"),
        React.createElement(DialogContent, null,
            React.createElement(QFOAutocomplete, { value: selectedQFO, onChange: setSelectedQFO_, query: autocompleteFilter, TextFieldProps: {
                    label: "Select a QFO",
                } })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: linkQFO, tabIndex: -1, disabled: !selectedQFO }, "OK"))));
};
export default LinkQFODialog;
