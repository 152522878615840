import * as React from "react";
import { AlarmOn, Checklist, ContentCut } from "@mui/icons-material";
import { Grid, Tooltip, Badge } from "@mui/material";
import styled from "styled-components";
import gmailLogo from "@app/assets/img/gmail.png";
import googleCalendarLogo from "@app/assets/img/google-calendar.png";
import dashLogo from "@app/assets/img/logo-notext.png";
import slackLogo from "@app/assets/img/slack.png";
import twilioLogo from "@app/assets/img/twilio.png";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import TypeTooltip from "./TypeTooltip";
export const MailScheduleBadge = styled(Badge) `
    & span {
        max-width: 10px;
        min-width: 10px;
        width: 12px;
        height: 12px;
        color: white;
        font-weight: bold;
        opacity: 0.85;
        line-height: 0;
    }
`;
export const TriggerBadge = styled(MailScheduleBadge) `
    & span {
        top: 6px;
        right: 1px;
    }
`;
export const CriteriaBadge = styled(MailScheduleBadge) `
& span {
    bottom: 6px;
    right: 1px;
}
`;
export const DraftBadge = styled(MailScheduleBadge) `
    & span {
        background: #666;
        bottom: 6px;
        right: 1px;
    }
`;
const NameCell = (props) => {
    var _a;
    const { template } = props;
    const session = useSession();
    let logo;
    switch (template.template_type) {
        case "Calendar":
            logo = googleCalendarLogo;
            break;
        case "Email":
        case "Email Draft":
            logo = gmailLogo;
            break;
        case "SMS":
        case "SMS Draft":
            logo = twilioLogo;
            break;
        case "Slack":
            logo = slackLogo;
            break;
        case "CRM Notification":
            logo = dashLogo;
            break;
        default:
            logo = dashLogo;
    }
    const logoComponent = React.createElement("img", { src: logo, alt: "logo", width: "22", height: "22" });
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: template.template_type }, template.template_type.toLowerCase().includes("draft") ? (React.createElement(DraftBadge, { badgeContent: "D", color: "default", anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                } }, logoComponent)) : (logoComponent))),
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", width: "calc(100% - 26px)" } }, ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) ? (React.createElement(RouterLink, { to: `/ms4/templates/${template.uid}` }, String(template.name))) : (String(template.name)))));
};
// eslint-disable-next-line react/display-name
export const renderNameCell = (rows) => (_, tableMeta) => {
    const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
    return React.createElement(NameCell, { template: template });
};
// eslint-disable-next-line react/display-name
export const renderTypeCell = (rows) => (_, tableMeta) => {
    const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
    console.log({ template });
    if (template.triggers && template.triggers.length > 0) {
        return (React.createElement(Tooltip, { title: React.createElement(TypeTooltip, { title: "Triggered template", description: "This template is processed when one of the specified fields or stages is changed, as long as as the listed criteria are satisfied" }) },
            React.createElement(AlarmOn, { fontSize: "small" })));
    }
    if (template.criteria && template.criteria.length > 0) {
        return (React.createElement(Tooltip, { title: React.createElement(TypeTooltip, { title: "Criteria-based template", description: "This template is processed when the specified criteria are satisfied" }) },
            React.createElement(Checklist, { fontSize: "small" })));
    }
    return (React.createElement(Tooltip, { title: React.createElement(TypeTooltip, { title: "Snippet", description: "This template doesn't run automatically, but you can process it manually any time" }) },
        React.createElement(ContentCut, { fontSize: "small" })));
};
