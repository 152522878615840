import * as React from "react";
import { Checkbox, Grid2, List, ListItem, ListItemText, Typography } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
const DashScoreLeaderboardFilter = (props) => {
    const { anchorEl, items, onChange, onClose, selected, title } = props;
    const isMenuOpen = React.useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);
    const onItemToggle = React.useCallback((itemName) => (event) => {
        event.stopPropagation();
        onChange(itemName);
    }, [onChange]);
    return (React.createElement(CorePopover, { open: isMenuOpen, anchorEl: anchorEl, onClose: onClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "center",
        } },
        React.createElement(Grid2, { container: true, spacing: 2 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { variant: "h6", fontSize: "16px" }, title)),
            React.createElement(Grid2, { container: true, size: 12 },
                React.createElement(List, null, items.map((item) => (React.createElement(Grid2, { key: `${item}_${title}_filter_menu_option`, size: 12 },
                    React.createElement(ListItem, { style: { paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px" } },
                        React.createElement(Checkbox, { checked: selected.includes(item), onChange: onItemToggle(item) }),
                        React.createElement(ListItemText, { primary: React.createElement(Typography, { fontSize: "14px", style: { marginLeft: "8px" } }, item) }))))))))));
};
export default DashScoreLeaderboardFilter;
