import * as React from "react";
import { Divider, Grid2 } from "@mui/material";
import OverviewSpreadsheet from "./OverviewSpreadsheet";
import Header from "../Header";
const Overview = (props) => {
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap", style: { height: "100%" }, mt: 0 },
        React.createElement(Grid2, null,
            React.createElement(Header, null)),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, { style: { flex: 1, height: 0, paddingTop: 0 } },
            React.createElement(OverviewSpreadsheet, Object.assign({}, props)))));
};
export default Overview;
