import * as React from "react";
import { FormControlLabel, Grid2, Skeleton, Stack, Switch } from "@mui/material";
import ActionDialog from "@app/common/ActionDialog";
import usePaginatedQuery from "@app/hooks/api/usePaginatedQuery";
import { searchUsers } from "@app/orval/api/users";
import UserAutocomplete from "../../crm/components/box-view/actions/UserAutocomplete";
const AddMemberDialog = (props) => {
    var _a;
    const { DialogProps, currentMembers, onAdd, onClose } = props;
    const listUsersApi = usePaginatedQuery(searchUsers, {});
    const users = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [listUsersApi.data]);
    const usersByUid = React.useMemo(() => {
        return users === null || users === void 0 ? void 0 : users.reduce((acc, user) => {
            acc[user.uid] = user;
            return acc;
        }, {});
    }, [users]);
    const [selectedUserUid, setSelectedUserUid] = React.useState(null);
    const [isManagingPartner, setIsManagingPartner] = React.useState(false);
    const isValid = React.useMemo(() => {
        return selectedUserUid != null;
    }, [selectedUserUid]);
    const handleSelectedUserChanged = React.useCallback((_, value) => {
        var _a;
        setSelectedUserUid((_a = value === null || value === void 0 ? void 0 : value.uid) !== null && _a !== void 0 ? _a : null);
    }, []);
    const handleManagingPartnerChanged = React.useCallback((_, checked) => {
        setIsManagingPartner(checked);
    }, []);
    const addUser = React.useCallback(() => {
        onAdd({ userUid: selectedUserUid, isManagingPartner });
    }, [onAdd, selectedUserUid, isManagingPartner]);
    return (React.createElement(ActionDialog, { title: "Add member", DialogProps: DialogProps, onCancel: onClose, onConfirm: addUser, isConfirmButtonDisabled: !isValid }, users ? (React.createElement(Grid2, { container: true, direction: "column", spacing: 4 },
        React.createElement(Grid2, null,
            React.createElement(UserAutocomplete, { users: users.filter((x) => !currentMembers.includes(x.uid)), value: selectedUserUid ? ((_a = usersByUid === null || usersByUid === void 0 ? void 0 : usersByUid[selectedUserUid]) !== null && _a !== void 0 ? _a : null) : null, onChange: handleSelectedUserChanged })),
        React.createElement(Grid2, null,
            React.createElement(FormControlLabel, { label: "Managing Partner", control: React.createElement(Switch, { checked: isManagingPartner, onChange: handleManagingPartnerChanged, color: "primary" }) })))) : (React.createElement(Stack, { spacing: 1 },
        React.createElement(Skeleton, { variant: "rectangular" }),
        React.createElement(Skeleton, { variant: "rectangular", style: { width: "50%" } })))));
};
export default AddMemberDialog;
