import * as React from "react";
import { TextField, MenuItem, Typography, Grid2, InputAdornment } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import ActionDialog from "@app/common/ActionDialog";
import CoreDateField from "@app/common/CoreDateField";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useListShadowingTypes } from "@app/orval/api/onboarding-shadowing-types";
import { useAddUserShadowing, useGetUser, useListUsers, useUpdateUserShadowing } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import UserAutocomplete from "../../../components/box-view/actions/UserAutocomplete";
const DEFAULT_FORM_SUBMISSION = {
    shadowed_user: undefined,
    shadowing_type: undefined,
};
const ShadowingDialog = (props) => {
    var _a, _b, _c, _d;
    const { DialogProps, onClose, shadowing, userUid } = props;
    const [formData, setFormData] = React.useState(shadowing
        ? {
            shadowing_type: shadowing.shadowing_type.uid,
            shadowed_user: shadowing.shadowed_user.uid,
        }
        : DEFAULT_FORM_SUBMISSION);
    const [initialData, setInitialData] = React.useState(formData);
    const isValid = React.useMemo(() => {
        return (formData.shadowing_type != null &&
            formData.shadowed_user != null &&
            formData.shadowing_date_and_time != null &&
            JSON.stringify(formData) !== JSON.stringify(initialData));
    }, [formData, initialData]);
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const getUserApi = useGetUser(props.userUid, { query: { enabled: false } });
    const createShadowingApi = useAddUserShadowing();
    const updateShadowingApi = useUpdateUserShadowing();
    const listShadowingTypesApi = useListShadowingTypes(REFETCH_CACHE_PARAMS);
    const shadowingTypes = (_b = (_a = listShadowingTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listUsersApi = useListUsers({}, INFINITE_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listUsersApi.data]);
    /**
     * only show users that are certified shadowing agents
     * https://github.com/ryanlaney/dash-portal/issues/2024
     */
    const selectableUsers = React.useMemo(() => {
        return users === null || users === void 0 ? void 0 : users.filter((x) => x.uid === formData.shadowed_user || x.fields["Certified Shadowing Agent"]);
    }, [users, formData.shadowed_user]);
    console.log({ users, selectableUsers });
    React.useEffect(() => {
        if (shadowing) {
            const data = {
                shadowed_user: shadowing.shadowed_user.uid,
                shadowing_type: shadowing.shadowing_type.uid,
            };
            setFormData(data);
            setInitialData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shadowing === null || shadowing === void 0 ? void 0 : shadowing.uid]);
    const save = React.useCallback(() => {
        const data = {
            shadowing_type: formData.shadowing_type,
            shadowed_user: formData.shadowed_user,
            shadowing_date_and_time: formData.shadowing_date_and_time,
        };
        if (shadowing === null || shadowing === void 0 ? void 0 : shadowing.uid) {
            updateShadowingApi
                .mutateAsync({ userUid, shadowingUid: shadowing.uid, data })
                .then((response) => {
                queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: response.data });
                });
            })
                .catch(errorDialog)
                .then(onClose);
        }
        else {
            createShadowingApi
                .mutateAsync({ userUid, data })
                .then((response) => {
                queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: response.data });
                });
            })
                .catch(errorDialog)
                .then(onClose);
        }
    }, [
        formData.shadowing_type,
        formData.shadowed_user,
        formData.shadowing_date_and_time,
        shadowing === null || shadowing === void 0 ? void 0 : shadowing.uid,
        updateShadowingApi,
        userUid,
        errorDialog,
        onClose,
        queryClient,
        getUserApi.queryKey,
        createShadowingApi,
    ]);
    const setShadowingType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { shadowing_type: e.target.value })));
    }, [setFormData]);
    const setShadowedUser = React.useCallback((_, value) => {
        setFormData((prev) => { var _a; return (Object.assign(Object.assign({}, prev), { shadowed_user: (_a = value === null || value === void 0 ? void 0 : value.uid) !== null && _a !== void 0 ? _a : undefined })); });
    }, [setFormData]);
    const setShadowingDateAndTime = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { shadowing_date_and_time: value !== null && value !== void 0 ? value : undefined })));
    }, [setFormData]);
    const isSaving = createShadowingApi.isPending || updateShadowingApi.isPending;
    return (React.createElement(ActionDialog, { title: shadowing ? "Edit Shadowing" : "Add Shadowing", DialogProps: DialogProps, onConfirm: save, onCancel: onClose, confirmButtonText: "Save", isConfirmButtonDisabled: !isValid || isSaving },
        React.createElement(Grid2, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null, shadowingTypes ? (React.createElement(TextField, { fullWidth: true, label: "Shadowing Type", variant: "standard", select: true, value: formData.shadowing_type || "", onChange: setShadowingType },
                React.createElement(MenuItem, { value: "" }, "Select Shadowing Type"),
                shadowingTypes.map((type) => (React.createElement(MenuItem, { key: type.uid, value: type.uid }, type.name))))) : (React.createElement(Typography, null, "Loading shadowing types..."))),
            React.createElement(Grid2, null, users ? (React.createElement(UserAutocomplete, { users: selectableUsers, value: (_c = users.find((x) => x.uid === formData.shadowed_user)) !== null && _c !== void 0 ? _c : null, onChange: setShadowedUser, TextFieldProps: {
                    label: "Shadowed Agent",
                } })) : (React.createElement(Typography, null, "Loading users..."))),
            React.createElement(Grid2, null,
                React.createElement(CoreDateField, { format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", mode: "datetime", date: (_d = formData.shadowing_date_and_time) !== null && _d !== void 0 ? _d : null, onDateChange: setShadowingDateAndTime, size: "small", fullWidth: true, 
                    // @ts-expect-error: not assignable to type 'standard'
                    variant: "outlined", hasPicker: true, slotProps: {
                        input: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Date & time"),
                            endAdornment: React.createElement(InputAdornment, { position: "end" }, "Eastern time"),
                        },
                    } })))));
};
export default ShadowingDialog;
