import * as React from "react";
import { Grid2, Paper } from "@mui/material";
import FormRow from "./FormRow";
const SQFT_ROWS = [
    {
        name: "Square Footage",
        fields: [{ name: "Square Footage" }],
    },
    {
        name: "Other SqFt",
        fields: [{ name: "Other SqFt" }],
    },
    {
        name: "List Price/SqFt",
        fields: [{ name: "List Price/SqFt" }],
    },
];
const CMA_VALUES_ROWS = [
    {
        name: "CMA Value Active (Avg)",
        fields: [{ name: "CMA Value Active (Avg)" }],
    },
    {
        name: "CMA Value Active (Min)",
        fields: [{ name: "CMA Value Active (Min)" }],
    },
    {
        name: "CMA Value Active (High)",
        fields: [{ name: "CMA Value Active (High)" }],
    },
    {
        name: "CMA Value Closed (Avg)",
        fields: [{ name: "CMA Value Closed (Avg)" }],
    },
    {
        name: "CMA Value Closed (Min)",
        fields: [{ name: "CMA Value Closed (Min)" }],
    },
    {
        name: "CMA Value Closed (High)",
        fields: [{ name: "CMA Value Closed (High)" }],
    },
    {
        name: "CMA Value Pending (Avg)",
        fields: [{ name: "CMA Value Pending (Avg)" }],
    },
    {
        name: "CMA Value Pending (Min)",
        fields: [{ name: "CMA Value Pending (Min)" }],
    },
    {
        name: "CMA Value Pending (High)",
        fields: [{ name: "CMA Value Pending (High)" }],
    },
];
const CMA_OVERALLS_ROWS = [
    {
        name: "CMA Value (Avg)",
        fields: [{ name: "CMA Value (Avg)" }],
    },
    {
        name: "CMA Value (Min)",
        fields: [{ name: "CMA Value (Min)" }],
    },
    {
        name: "CMA Value (High)",
        fields: [{ name: "CMA Value (High)" }],
    },
];
const PSP_VALUES_ROWS = [
    {
        name: "PSP Value (Avg)",
        fields: [{ name: "PSP Value (Avg)" }],
    },
    {
        name: "PSP Value (Min)",
        fields: [{ name: "PSP Value (Min)" }],
    },
    {
        name: "PSP Value (High)",
        fields: [{ name: "PSP Value (High)" }],
    },
];
const SPLP_VALUES_ROWS = [
    {
        name: "Closed Avg SPLP",
        fields: [{ name: "Closed Avg SPLP Percent" }],
    },
    {
        name: "Closed Min SPLP",
        fields: [{ name: "Closed Min SPLP Percent" }],
    },
    {
        name: "Closed Max SPLP",
        fields: [{ name: "Closed Max SPLP Percent" }],
    },
];
const CMA_TAX_VALUES_ROWS = [
    {
        name: "CMA Value Active (Avg Tax)",
        fields: [{ name: "CMA Value Active (Avg Tax)" }],
    },
    {
        name: "CMA Value Active (Min Tax)",
        fields: [{ name: "CMA Value Active (Min Tax)" }],
    },
    {
        name: "CMA Value Active (High Tax)",
        fields: [{ name: "CMA Value Active (High Tax)" }],
    },
    {
        name: "CMA Value Closed (Avg Tax)",
        fields: [{ name: "CMA Value Closed (Avg Tax)" }],
    },
    {
        name: "CMA Value Closed (Min Tax)",
        fields: [{ name: "CMA Value Closed (Min Tax)" }],
    },
    {
        name: "CMA Value Closed (High Tax)",
        fields: [{ name: "CMA Value Closed (High Tax)" }],
    },
    {
        name: "CMA Value Pending (Avg Tax)",
        fields: [{ name: "CMA Value Pending (Avg Tax)" }],
    },
    {
        name: "CMA Value Pending (Min Tax)",
        fields: [{ name: "CMA Value Pending (Min Tax)" }],
    },
    {
        name: "CMA Value Pending (High Tax)",
        fields: [{ name: "CMA Value Pending (High Tax)" }],
    },
];
const CMA_TAX_OVERALLS_ROWS = [
    {
        name: "CMA Value (Avg Tax)",
        fields: [{ name: "CMA Value (Avg Tax)" }],
    },
    {
        name: "CMA Value (Min Tax)",
        fields: [{ name: "CMA Value (Min Tax)" }],
    },
    {
        name: "CMA Value (High Tax)",
        fields: [{ name: "CMA Value (High Tax)" }],
    },
];
const PSP_TAX_VALUES_ROWS = [
    {
        name: "PSP Value (Avg Tax)",
        fields: [{ name: "PSP Value (Avg Tax)" }],
    },
    {
        name: "PSP Value (Min Tax)",
        fields: [{ name: "PSP Value (Min Tax)" }],
    },
    {
        name: "PSP Value (High Tax)",
        fields: [{ name: "PSP Value (High Tax)" }],
    },
];
const MISC_ROWS = [
    {
        name: "List Price/SqFt",
        fields: [{ name: "List Price/SqFt" }],
    },
    {
        name: "Age",
        fields: [{ name: "Age" }],
    },
    {
        name: "Current Year",
        fields: [{ name: "Current Year" }],
    },
];
const CalculationsTab = (props) => {
    const { matrixFields, matrixSchema, onFieldSaveBegin, onFieldSaveComplete, uid } = props;
    const getRowSection = React.useCallback((rows) => {
        return (React.createElement(Paper, { elevation: 1, style: { marginBottom: "16px", width: "100%" } },
            React.createElement(Grid2, { container: true, spacing: 2 }, rows.map((row) => {
                return (React.createElement(FormRow, { key: row.name, uid: uid, rowData: Object.assign(Object.assign({}, row), { fields: row.fields.map((field) => (Object.assign(Object.assign({}, field), { value: matrixFields[field.name] }))) }), 
                    // matrixFields={matrixFields}
                    schema: matrixSchema, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete }));
            }))));
    }, [matrixFields, matrixSchema, onFieldSaveBegin, onFieldSaveComplete, uid]);
    return (React.createElement(Grid2, { container: true, spacing: 4, style: { width: "100%", margin: 0 } },
        React.createElement(Grid2, { container: true, size: { sm: 12, md: 4 } }, getRowSection(SQFT_ROWS)),
        React.createElement(Grid2, { container: true, size: { sm: 12, md: 4 } },
            getRowSection(CMA_VALUES_ROWS),
            getRowSection(CMA_OVERALLS_ROWS),
            getRowSection(PSP_VALUES_ROWS),
            getRowSection(SPLP_VALUES_ROWS)),
        React.createElement(Grid2, { container: true, size: { sm: 12, md: 4 } },
            getRowSection(CMA_TAX_VALUES_ROWS),
            getRowSection(CMA_TAX_OVERALLS_ROWS),
            getRowSection(PSP_TAX_VALUES_ROWS),
            getRowSection(MISC_ROWS))));
};
export default CalculationsTab;
