import * as React from "react";
import { Grid2, Typography, Divider, Tooltip } from "@mui/material";
import numeral from "numeral";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import dayjs_ from "@app/common/dayjs";
import FormFieldLabel from "@app/entrypoints/brokerage/features/apps/matrix/components/FormFieldLabel";
import FormFieldTextAdornment from "@app/entrypoints/brokerage/features/apps/matrix/components/FormFieldTextAdornment";
import { DEFAULT_LABEL_STYLE, onKeyDown } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import { convertDateFormatToDayjs } from "@app/util/Utils";
const FormRow = (props) => {
    var _a, _b, _c, _d, _e;
    const { labelSize, onFieldSaveBegin, onFieldSaveComplete, rowData, rowStyle, schema, uid } = props;
    if (rowData.hidden) {
        return React.createElement("div", null);
    }
    if (rowData.customLayout) {
        return rowData.customLayout;
    }
    const labelSize_ = (_b = (_a = rowData.labelSize) !== null && _a !== void 0 ? _a : labelSize) !== null && _b !== void 0 ? _b : 6;
    return (React.createElement(Grid2, { container: true, size: 12, style: Object.assign({ padding: "4px" }, rowStyle) },
        rowData.divider && ((_c = rowData.dividerPlacement) !== null && _c !== void 0 ? _c : "").split(",").includes("above") && (React.createElement(Grid2, { size: 12, style: { marginTop: "16px !important", marginBottom: "8px !important" } },
            React.createElement(Divider, null))),
        React.createElement(FormFieldLabel, { name: `${rowData.name}:`, gridSize: labelSize_, labelStyle: (_d = rowData.labelStyle) !== null && _d !== void 0 ? _d : DEFAULT_LABEL_STYLE }),
        rowData.fields.map((field) => {
            var _a, _b, _c, _d;
            const fieldSize = (_a = field.size) !== null && _a !== void 0 ? _a : Math.round((12 - labelSize_) / rowData.fields.length);
            const fieldName = field.name;
            const fieldDefinition = (_b = schema[fieldName]) !== null && _b !== void 0 ? _b : {};
            const helpText = fieldDefinition.help_text;
            if (!schema[fieldName]) {
                return React.createElement(Grid2, { key: `${rowData.name}_${field.name}`, size: fieldSize });
            }
            // handle formula fields
            let isFormulaField = false;
            let formattedFieldVal = null;
            if (fieldDefinition.formula) {
                isFormulaField = true;
                formattedFieldVal = field.value;
                const { format } = schema[fieldName];
                if (formattedFieldVal === null || formattedFieldVal === undefined) {
                    formattedFieldVal = "-";
                }
                else {
                    if (typeof formattedFieldVal === "object" && "value" in formattedFieldVal) {
                        formattedFieldVal = (_c = formattedFieldVal.value) !== null && _c !== void 0 ? _c : "-";
                    }
                    if (format) {
                        if (format.includes("0")) {
                            formattedFieldVal = numeral(formattedFieldVal * (field.multiplyBy100 ? 100 : 1)).format(format);
                        }
                        else if (format.toLowerCase().includes("m")) {
                            formattedFieldVal = dayjs_(formattedFieldVal).format(convertDateFormatToDayjs(format));
                        }
                    }
                    if (field.startText) {
                        formattedFieldVal = `${field.startText} ${formattedFieldVal}`;
                    }
                    if (field.endText) {
                        formattedFieldVal = `${formattedFieldVal} ${field.endText}`;
                    }
                }
            }
            return (React.createElement(Grid2, { key: `${rowData.name}_${field.name}`, size: fieldSize, style: {
                    paddingRight: "8px",
                    marginTop: "auto",
                } },
                React.createElement(Tooltip, { title: helpText ? React.createElement(Typography, { variant: "body2" }, helpText) : "", placement: "top" }, isFormulaField ? (React.createElement(Typography, { variant: "caption", style: { fontSize: "11px" } }, formattedFieldVal)) : (React.createElement("span", null,
                    React.createElement(DashAutoSaveField, { fullWidth: true, variant: "standard", field: schema[fieldName], value: field.value, apiEndpoint: `/api/psps/${uid}`, onSuccess: onFieldSaveComplete, onSaveBegin: (_d = field.onSaveBegin) !== null && _d !== void 0 ? _d : onFieldSaveBegin, size: "small", multiline: field.multiline, rows: field.multilineRows, multiplyBy100: field.multiplyBy100, inputProps: {
                            style: {
                                fontSize: "11px",
                            },
                        }, InputProps: {
                            startAdornment: field.startText ? (React.createElement(FormFieldTextAdornment, { text: field.startText, placement: "start" })) : null,
                            endAdornment: field.endText ? (React.createElement(FormFieldTextAdornment, { text: field.endText, placement: "end" })) : null,
                        }, onKeyDown: onKeyDown }))))));
        }),
        rowData.divider &&
            (((_e = rowData.dividerPlacement) !== null && _e !== void 0 ? _e : "").split(",").includes("below") || !rowData.dividerPlacement) && (React.createElement(Grid2, { size: 12, style: { marginTop: "16px !important", marginBottom: "8px !important" } },
            React.createElement(Divider, null)))));
};
export default FormRow;
