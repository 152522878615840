import * as React from "react";
import { Person } from "@mui/icons-material";
import { List } from "@mui/material";
import ContactAliasListItem from "./ContactAliasListItem";
import CollapsibleSection from "../components/box-view/Contact/CollapsibleSection";
const ContactAliases = (props) => {
    const { aliases, contact } = props;
    return (React.createElement(CollapsibleSection, { icon: React.createElement(Person, null), title: "Aliases", count: aliases.length }, aliases.length > 0 && (React.createElement(List, { dense: true }, aliases
        .sort((a, b) => {
        if (a.type < b.type)
            return -1;
        if (a.type > b.type)
            return 1;
        return 0;
    })
        .map((alias) => (React.createElement(ContactAliasListItem, { key: alias.uid, transaction: alias, contact: contact })))))));
};
export default ContactAliases;
