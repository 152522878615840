import * as React from "react";
import { useCallback } from "react";
import { Search } from "@mui/icons-material";
import { InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import omit from "lodash/omit";
const SearchableMenu = (props) => {
    const { MenuProps, children, onSearchChange, search } = props;
    const handleSearchChange = useCallback((e) => {
        console.log(e.target.value);
        onSearchChange(e.target.value);
        e.preventDefault();
        e.stopPropagation();
    }, [onSearchChange]);
    const disableMousePropagation = useCallback((e) => {
        console.log("clicked");
        e.stopPropagation();
    }, []);
    const disableKeyPropagation = useCallback((e) => {
        if (e.key !== "Escape") {
            e.stopPropagation();
        }
    }, []);
    return (React.createElement(Menu, Object.assign({}, omit(MenuProps, "children"), { key: "mail-schedule-list" }),
        React.createElement(MenuItem, { onClickCapture: disableMousePropagation, disableRipple: true, divider: !!children, onKeyDown: disableKeyPropagation },
            React.createElement(TextField, { variant: "outlined", size: "small", fullWidth: true, value: search, onChange: handleSearchChange, onClick: disableMousePropagation, onDoubleClick: disableMousePropagation, autoFocus: true, slotProps: {
                    input: {
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement(Search, { fontSize: "small" }))),
                    },
                } })),
        children));
};
export default SearchableMenu;
