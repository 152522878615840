import * as React from "react";
import { Edit, Save } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Grid2, Paper, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useListFeedbackRequestCadenceTemplates, useUpdateFeedbackRequestCadenceTemplate, } from "@app/orval/api/feedback-request-cadence";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const FeedbackRequestCadence = () => {
    var _a, _b;
    const queryClient = useQueryClient();
    const [currentlyEditingItem, setCurrentlyEditingItem] = React.useState(null);
    const [currentlySavingItemUid, setCurrentlySavingItemUid] = React.useState(null);
    const listFeedbackRequestCadenceApi = useListFeedbackRequestCadenceTemplates(REFETCH_CACHE_PARAMS);
    const items = (_b = (_a = listFeedbackRequestCadenceApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const { error } = listFeedbackRequestCadenceApi;
    const updateFeedbackRequestTemplateApi = useUpdateFeedbackRequestCadenceTemplate();
    const toggleEditMode = React.useCallback((uid) => () => {
        setCurrentlyEditingItem({
            uid,
            message: items.find((item) => item.uid === uid).message,
        });
    }, []);
    const cancel = React.useCallback(() => {
        setCurrentlyEditingItem(null);
    }, []);
    const updateMessage = React.useCallback((e) => {
        const message = e.target.value;
        setCurrentlyEditingItem((prevState) => (Object.assign(Object.assign({}, prevState), { message })));
    }, []);
    const saveItem = React.useCallback((uid) => () => {
        setCurrentlySavingItemUid(uid);
        const message = currentlyEditingItem.message.trim();
        updateFeedbackRequestTemplateApi
            .mutateAsync({
            uid,
            data: {
                message,
            },
        })
            .then(() => {
            queryClient.setQueryData(listFeedbackRequestCadenceApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: oldData.data.map((item) => {
                        if (item.uid === uid) {
                            return Object.assign(Object.assign({}, item), { message });
                        }
                        return item;
                    }) });
            });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            setCurrentlySavingItemUid(null);
        });
    }, [currentlyEditingItem, listFeedbackRequestCadenceApi.queryKey, queryClient, updateFeedbackRequestTemplateApi]);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!items) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, spacing: 4, direction: "column" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h4" }, "Feedback request cadence")),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, null, "Use {{ agent }}, {{ assigned_agent }}, or {{ property }} to populate the SMS with the appropriate data."))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        items.map((item) => (React.createElement(Grid2, { key: item.uid },
            React.createElement(Box, { p: 2 },
                React.createElement(Paper, { elevation: 4 },
                    React.createElement(Box, { p: 2 },
                        React.createElement(Grid2, { container: true, spacing: 4, direction: "column" },
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { variant: "h6" }, item.name)),
                            (currentlyEditingItem === null || currentlyEditingItem === void 0 ? void 0 : currentlyEditingItem.uid) === item.uid ? (React.createElement(React.Fragment, null,
                                React.createElement(Grid2, null,
                                    React.createElement(TextField, { variant: "standard", fullWidth: true, value: currentlyEditingItem.message, onChange: updateMessage, disabled: currentlySavingItemUid === currentlyEditingItem.uid })),
                                React.createElement(Grid2, null,
                                    React.createElement(Grid2, { container: true, spacing: 1 },
                                        React.createElement(Grid2, null,
                                            React.createElement(Button, { size: "small", variant: "outlined", color: "inherit", onClick: cancel, disabled: currentlySavingItemUid === currentlyEditingItem.uid }, "Cancel")),
                                        React.createElement(Grid2, { style: { flex: 1 } }),
                                        React.createElement(Grid2, null,
                                            React.createElement(Button, { size: "small", variant: "contained", color: "primary", startIcon: currentlySavingItemUid === currentlyEditingItem.uid ? (React.createElement(CircularProgress, { size: 15 })) : (React.createElement(Save, null)), onClick: saveItem(currentlyEditingItem.uid), disabled: currentlySavingItemUid === currentlyEditingItem.uid }, "Save")))))) : (React.createElement(React.Fragment, null,
                                React.createElement(Grid2, null, item.message.trim() === "" ? (React.createElement(Typography, { variant: "body1", style: { fontStyle: "italic" } }, "no message")) : (React.createElement(Typography, { variant: "body1" }, item.message))),
                                React.createElement(Grid2, null,
                                    React.createElement(Button, { size: "small", variant: "outlined", color: "primary", onClick: toggleEditMode(item.uid), startIcon: React.createElement(Edit, null) }, "Edit")))))))))))));
};
export default FeedbackRequestCadence;
