import * as React from "react";
import CoreLoading from "@app/common/CoreLoading";
import CoreTextField from "@app/common/CoreTextField";
//* * Tab Section contents for DASHscore scorecard */
const ScorecardTabSection = (props) => {
    const onSectionUpdate = React.useCallback((text) => {
        const { tabName, updateDescriptionText } = props;
        updateDescriptionText(tabName, text);
    }, [props]);
    const { hasEditPermission, isUpdating, text } = props;
    return (React.createElement(React.Fragment, null,
        props.children,
        React.createElement(CoreTextField, { fullWidth: true, size: "small", multiline: true, rows: 4, value: text, onTextChange: onSectionUpdate, disabled: !hasEditPermission, slotProps: {
                input: {
                    endAdornment: isUpdating ? React.createElement(CoreLoading, null) : undefined,
                },
            } })));
};
export default ScorecardTabSection;
