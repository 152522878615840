import * as React from "react";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import MailScheduleExecutionsTable from "./MailScheduleExecutionsTable";
import MailScheduleExecutionsTableSidebar from "./MailScheduleExecutionsTableSidebar";
const MailScheduleExecutionsWrapper = () => {
    const [showFilterSidebar, setShowFilterSidebar] = React.useState(false);
    const toggleFilterSidebar = React.useCallback(() => {
        setShowFilterSidebar((prev) => !prev);
    }, []);
    return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(MailScheduleExecutionsTable, { onSidebarToggle: toggleFilterSidebar }), sidebar: React.createElement(MailScheduleExecutionsTableSidebar, { onSidebarToggle: toggleFilterSidebar }), sidebarWidth: 250, opened: showFilterSidebar }));
};
export default MailScheduleExecutionsWrapper;
