import { useCallback, useMemo } from "react";
import useSession from "@app/hooks/useSession";
const useTwilioPhonePreference = () => {
    var _a;
    const session = useSession();
    const settingName = useMemo(() => {
        var _a;
        return `lastSelectedTwilioPhone__${(_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid}`;
    }, [(_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid]);
    const setLastSelectedTwilioPhone = useCallback((phoneNumberE164) => {
        window.localStorage.setItem(settingName, phoneNumberE164);
    }, [settingName]);
    const getLastSelectedTwilioPhone = useCallback(() => {
        return window.localStorage.getItem(settingName);
    }, [settingName]);
    return {
        setLastSelectedTwilioPhone,
        getLastSelectedTwilioPhone,
    };
};
export default useTwilioPhonePreference;
