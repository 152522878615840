var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { downloadFileFromCloudStorage } from "@app/orval/api/google-cloud";
const useDownloadMultipleCloudStorageFiles = (files) => {
    const [downloadedFiles, setDownloadedFiles] = useState([]);
    const downloadingFiles = useMemo(() => {
        return files.filter((f) => {
            return !downloadedFiles.some((d) => d.bucket_name === f.bucket_name && d.blob_name === f.blob_name);
        });
    }, [downloadedFiles, files]);
    const cancelToken = useRef(null);
    const downloadFiles = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        cancelToken.current = axios.CancelToken.source();
        const promises = files.map((file) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield downloadFileFromCloudStorage(file, { cancelToken: cancelToken.current.token });
            setDownloadedFiles((prev) => [...prev, Object.assign(Object.assign({}, file), response.data)]);
        }));
        console.log({ promises });
        yield Promise.all(promises);
    }), [files]);
    useEffect(() => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        console.log({ files });
        setDownloadedFiles([]);
        downloadFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(files)]);
    return {
        downloadedFiles,
        downloadingFiles,
    };
};
export default useDownloadMultipleCloudStorageFiles;
