import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { TextField } from "@mui/material";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
/**
 * A TextField that updates the specified search param on blur.
 *
 * @param props
 * @returns
 */
const SearchParamTextField = (props) => {
    const { TextFieldProps, searchParam } = props;
    const updateSearch = useUpdateSearch();
    const [searchParams, _] = useSearchParams();
    const searchParamVal = searchParams.get(searchParam);
    const [value, setValue] = React.useState(searchParamVal !== null && searchParamVal !== void 0 ? searchParamVal : "");
    const doUpdate = React.useCallback(() => {
        updateSearch(searchParam, value || null, "offset", null);
    }, [searchParam, value, updateSearch]);
    React.useEffect(() => {
        const listener = (e) => {
            if (e.key === "Enter" || e.key === "Escape") {
                doUpdate();
            }
        };
        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [doUpdate]);
    React.useEffect(() => {
        setValue(searchParamVal !== null && searchParamVal !== void 0 ? searchParamVal : "");
    }, [searchParamVal]);
    const onChange = React.useCallback((e) => {
        setValue(e.target.value);
    }, []);
    const onBlur = React.useCallback(doUpdate, [doUpdate]);
    return React.createElement(TextField, Object.assign({ value: value, onChange: onChange, onBlur: onBlur }, TextFieldProps));
};
export default SearchParamTextField;
