import * as React from "react";
import { Cancel, Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useReleaseTwilioPhoneForUser } from "@app/orval/api/users";
const ReleaseTwilioPhoneDialog = (props) => {
    var _a;
    const { DialogProps, onCancel, onReleaseComplete, user } = props;
    const releasePhoneNumberApi = useReleaseTwilioPhoneForUser();
    const cancel = React.useCallback(() => {
        onCancel();
    }, [onCancel]);
    const releasePhoneNumber = React.useCallback(() => {
        releasePhoneNumberApi
            .mutateAsync({
            profileUid: user.uid,
        })
            .then((result) => {
            onReleaseComplete(result.data);
        })
            .catch(enqueueErrorSnackbar);
    }, [onReleaseComplete, releasePhoneNumberApi, user.uid]);
    return (React.createElement(Dialog, Object.assign({}, DialogProps, { open: (_a = DialogProps.open) !== null && _a !== void 0 ? _a : true }),
        React.createElement(DialogTitle, null,
            "Release Twilio Phone for ",
            user.name),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, null,
                        "Are you sure you want to release Twilio phone ",
                        React.createElement("b", null, user.fields["Twilio #"]),
                        " for ",
                        React.createElement("b", null, user.name),
                        "? You won't be able to recover the phone number once it is released.")))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "inherit", startIcon: React.createElement(Cancel, null), onClick: cancel }, "Cancel"),
            React.createElement(Button, { color: "error", onClick: releasePhoneNumber, startIcon: React.createElement(Delete, null), disabled: releasePhoneNumberApi.isPending }, "Release"))));
};
export default ReleaseTwilioPhoneDialog;
