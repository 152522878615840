import * as React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid2, InputAdornment, RadioGroup, FormControlLabel, Radio, Box, Switch, List, IconButton, Collapse, Tooltip, } from "@mui/material";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreDialog from "@app/common/CoreDialog";
import useSession from "@app/hooks/useSession";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListVendorTypes } from "@app/orval/api/vendor-types";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import ThirdPartyListItem from "./ThirdPartyListItem";
import { NOT_DUPLICATE_CONTACT_FILTER } from "./utils";
import ContactAutocomplete from "../../components/box-view/actions/ContactAutocomplete";
const getVendorType = (type) => {
    if (type === "Buyer Closing Attorney") {
        return "Attorney";
    }
    if (type === "Seller Closing Attorney") {
        return "Attorney";
    }
    if (type === "Listing Agent") {
        return "Agent";
    }
    if (type === "Inspector") {
        return "Inspector";
    }
    if (type === "Lender") {
        return "Lender";
    }
    if (type === "Zillow Loan Officer") {
        return "Zillow Loan Officer";
    }
    if (type === "Paralegal") {
        return "Paralegal";
    }
    if (type === "Measurement Company") {
        return "Measurement Company";
    }
    if (type === "Photo Company") {
        return "Photo Company";
    }
    return undefined;
};
const ThirdPartyDialog = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { DialogProps, allowedTypes, buyerContracts, buyerLead, existingVendors, onClose, onRemoveExistingVendor, onUpdate, party, sellerContracts, sellerLead, vendorType, } = props;
    const [existingVendors_, setExistingVendors_] = React.useState(existingVendors !== null && existingVendors !== void 0 ? existingVendors : null);
    const [selectedVendor, setSelectedVendor] = React.useState(null);
    const session = useSession();
    const variant = React.useMemo(() => {
        if (buyerLead && sellerLead) {
            throw new Error("Cannot pass in both `buyerLead` and `sellerLead`");
        }
        if (buyerLead)
            return "buyer";
        if (sellerLead)
            return "seller";
        throw new Error("Need to pass in either `buyerLead` or `sellerLead`");
    }, [buyerLead, sellerLead]);
    const existingVendorsFiltered = React.useMemo(() => {
        if (!existingVendors_) {
            return null;
        }
        if (vendorType) {
            return existingVendors_.filter((x) => x.type === vendorType);
        }
        return existingVendors_;
    }, [existingVendors_, vendorType]);
    const [displayExistingVendors, setDisplayExistingVendors] = React.useState(existingVendorsFiltered && existingVendorsFiltered.length > 0);
    const dialogTitle = React.useMemo(() => {
        if (party) {
            return "Edit third party";
        }
        if ((existingVendorsFiltered !== null && existingVendorsFiltered !== void 0 ? existingVendorsFiltered : []).length > 0) {
            return "Edit third parties";
        }
        return "Add a third party";
    }, [existingVendorsFiltered, party]);
    const contractUids = React.useMemo(() => {
        var _a, _b, _c, _d;
        return ((_d = (_c = (_b = (_a = buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.map((x) => x.uid)) !== null && _a !== void 0 ? _a : sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.map((x) => x.uid)) !== null && _b !== void 0 ? _b : buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts.map((x) => x.uid)) !== null && _c !== void 0 ? _c : sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts.map((x) => x.uid)) !== null && _d !== void 0 ? _d : []);
    }, [buyerContracts, buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts, sellerContracts, sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts]);
    const contractsByUid = React.useMemo(() => {
        const contracts = [...(buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : []), ...(sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : [])];
        const res = contracts.reduce((acc, contract) => {
            acc[contract.uid] = contract;
            return acc;
        }, {});
        return res;
    }, [buyerContracts, sellerContracts]);
    console.log({ party });
    console.log({ selectedVendor });
    const defaultParty = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        return {
            contact_uid: (_a = party === null || party === void 0 ? void 0 : party.contact.uid) !== null && _a !== void 0 ? _a : null,
            contact_data: null,
            type: (_c = (_b = party === null || party === void 0 ? void 0 : party.type) !== null && _b !== void 0 ? _b : vendorType) !== null && _c !== void 0 ? _c : null,
            notes: (_d = party === null || party === void 0 ? void 0 : party.notes) !== null && _d !== void 0 ? _d : null,
            associated_with_contracts: (_g = (_f = (_e = party === null || party === void 0 ? void 0 : party.associated_with_contracts) !== null && _e !== void 0 ? _e : (buyerContracts && buyerContracts.length === 1 ? [buyerContracts[0].uid] : null)) !== null && _f !== void 0 ? _f : (sellerContracts && sellerContracts.length === 1 ? [sellerContracts[0].uid] : null)) !== null && _g !== void 0 ? _g : [],
        };
    }, [
        party === null || party === void 0 ? void 0 : party.contact.uid,
        party === null || party === void 0 ? void 0 : party.type,
        party === null || party === void 0 ? void 0 : party.notes,
        party === null || party === void 0 ? void 0 : party.associated_with_contracts,
        buyerContracts,
        sellerContracts,
        vendorType,
    ]);
    console.log({ defaultParty });
    const [params, setParams] = React.useState(defaultParty);
    console.log({ params });
    const [selectedContact, setSelectedContact] = React.useState(null);
    const listContactFieldsApi = useListFields({ entity_type: "Contact" }, REFETCH_CACHE_PARAMS);
    const contactFields = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listContactFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_a = listContactFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const listVendorTypesApi = useListVendorTypes(INFINITE_CACHE_PARAMS);
    const vendorTypes = React.useMemo(() => {
        var _a, _b, _c, _d;
        const res = (_b = (_a = listVendorTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
        if (variant === "seller") {
            return (_c = res === null || res === void 0 ? void 0 : res.filter((x) => !["Listing Agent", "Zillow Loan Officer"].includes(x))) !== null && _c !== void 0 ? _c : null;
        }
        if (variant === "buyer") {
            return (_d = res === null || res === void 0 ? void 0 : res.filter((x) => !["Measurement Company", "Photo Company", "Zillow Loan Officer"].includes(x))) !== null && _d !== void 0 ? _d : null;
        }
        return res;
    }, [(_b = listVendorTypesApi.data) === null || _b === void 0 ? void 0 : _b.data, variant]);
    const [mode, setMode] = React.useState("select-existing");
    React.useEffect(() => {
        setParams(defaultParty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [party === null || party === void 0 ? void 0 : party.uid]);
    const isValid = React.useMemo(() => {
        var _a, _b, _c, _d;
        console.log({ params });
        return ((params.contact_uid || ((_b = (_a = params.contact_data) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b["Email"]) || ((_d = (_c = params.contact_data) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d["Phone"])) &&
            params.type &&
            params.associated_with_contracts &&
            params.associated_with_contracts.length > 0 &&
            JSON.stringify(params) !== JSON.stringify(defaultParty));
    }, [defaultParty, params]);
    const handleUpdate = React.useCallback(() => {
        var _a, _b, _c, _d, _e, _f;
        onUpdate({
            contact_uid: (_a = params.contact_uid) !== null && _a !== void 0 ? _a : undefined,
            contact_data: (_b = params.contact_data) !== null && _b !== void 0 ? _b : undefined,
            type: params.type,
            notes: (_d = (_c = params.notes) === null || _c === void 0 ? void 0 : _c.trim()) !== null && _d !== void 0 ? _d : null,
            associated_with_contracts: (_e = params.associated_with_contracts) !== null && _e !== void 0 ? _e : undefined,
        }, (_f = selectedVendor === null || selectedVendor === void 0 ? void 0 : selectedVendor.uid) !== null && _f !== void 0 ? _f : undefined);
    }, [params, onUpdate, selectedVendor]);
    const handleModeChanged = React.useCallback((e) => {
        const val = e.target.value;
        setMode(val);
        setSelectedContact(val === "select-existing" ? selectedContact : null);
        setParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { contact_data: val === "create-new"
                    ? Object.assign(Object.assign({}, prevState.contact_data), { fields: Object.assign(Object.assign({}, (_a = prevState.contact_data) === null || _a === void 0 ? void 0 : _a.fields), { "Vendor Type": getVendorType(prevState.type) }) }) : null, contact_uid: val === "select-existing" ? prevState.contact_uid : null }));
        });
    }, [selectedContact, setMode]);
    const handleContactChanged = React.useCallback((_, val) => {
        setSelectedContact(val);
        setParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { contact_uid: (_a = val === null || val === void 0 ? void 0 : val.uid) !== null && _a !== void 0 ? _a : null, contact_data: null }));
        });
    }, [setSelectedContact, setParams]);
    const handleTypeChanged = React.useCallback((e) => {
        const val = e.target.value;
        setParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { type: val || null, contact_uid: null, contact_data: Object.assign(Object.assign({}, prevState.contact_data), { fields: Object.assign(Object.assign({}, (_a = prevState.contact_data) === null || _a === void 0 ? void 0 : _a.fields), { "Vendor Type": getVendorType(prevState.type) }) }) }));
        });
        setSelectedContact(null);
    }, [setParams]);
    const handleContactFieldChanged = React.useCallback((fieldName) => (value) => {
        setParams((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { contact_data: Object.assign(Object.assign({}, prevState.contact_data), { fields: Object.assign(Object.assign({}, (_a = prevState.contact_data) === null || _a === void 0 ? void 0 : _a.fields), { [fieldName]: value }) }) }));
        });
    }, []);
    const handleNotesChanged = React.useCallback((e) => {
        const val = e.target.value;
        setParams((prevState) => (Object.assign(Object.assign({}, prevState), { notes: val })));
    }, [setParams]);
    const toggleAssociatedWithContract = React.useCallback((contractUid) => (_, checked) => {
        setParams((prevState) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, prevState), { associated_with_contracts: checked
                    ? [...((_a = prevState.associated_with_contracts) !== null && _a !== void 0 ? _a : []), contractUid]
                    : ((_b = prevState.associated_with_contracts) !== null && _b !== void 0 ? _b : []).filter((x) => x !== contractUid) }));
        });
    }, [setParams]);
    const toggleDisplayExistingVendors = React.useCallback(() => {
        setDisplayExistingVendors((prev) => !prev);
    }, [setDisplayExistingVendors]);
    const handleExistingVendorSelect = React.useCallback((vendorUid) => () => {
        console.log("TEST:", vendorUid, existingVendorsFiltered);
        const existingVendor = existingVendorsFiltered === null || existingVendorsFiltered === void 0 ? void 0 : existingVendorsFiltered.find((x) => x.uid === vendorUid);
        setSelectedVendor(existingVendor !== null && existingVendor !== void 0 ? existingVendor : null);
        setParams((prevState) => {
            return existingVendor
                ? Object.assign(Object.assign({}, prevState), { contact_uid: existingVendor.contact.uid, contact_data: null, type: existingVendor.type, notes: existingVendor.notes, associated_with_contracts: existingVendor.associated_with_contracts }) : defaultParty;
        });
        toggleDisplayExistingVendors();
    }, [defaultParty, existingVendorsFiltered, toggleDisplayExistingVendors]);
    const handleRemoveExistingVendor = React.useCallback((vendorUid) => () => {
        setSelectedVendor(null);
        if (onRemoveExistingVendor) {
            onRemoveExistingVendor(vendorUid);
            setExistingVendors_((prev) => {
                var _a;
                return (_a = prev === null || prev === void 0 ? void 0 : prev.filter((x) => x.uid !== vendorUid)) !== null && _a !== void 0 ? _a : null;
            });
        }
    }, [onRemoveExistingVendor]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, dialogTitle),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 4, direction: "column" },
                React.createElement(Grid2, null,
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Type"),
                            },
                        }, value: (_c = params.type) !== null && _c !== void 0 ? _c : "", onChange: handleTypeChanged, disabled: !!vendorType }, (vendorTypes !== null && vendorTypes !== void 0 ? vendorTypes : [])
                        .filter((v) => !allowedTypes || allowedTypes.includes(v))
                        .map((type) => (React.createElement(MenuItem, { key: type, value: type }, type))))),
                existingVendorsFiltered && existingVendorsFiltered.length > 0 && (React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: displayExistingVendors ? "Hide existing vendors" : "Show existing vendors" },
                            React.createElement(IconButton, { onClick: toggleDisplayExistingVendors }, displayExistingVendors ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null)))),
                    React.createElement(Collapse, { in: !!displayExistingVendors, style: { width: "100%" } },
                        React.createElement(Grid2, null,
                            React.createElement(List, { dense: true }, existingVendorsFiltered
                                .sort((a, b) => {
                                if (a.type < b.type)
                                    return -1;
                                if (a.type > b.type)
                                    return 1;
                                const aName = [a.contact.first_name, a.contact.last_name].filter((o) => o).join(" ");
                                const bName = [b.contact.first_name, b.contact.last_name].filter((o) => o).join(" ");
                                if (aName < bName)
                                    return -1;
                                if (aName > bName)
                                    return 1;
                                return 0;
                            })
                                .map((vendor, i) => (React.createElement(ThirdPartyListItem, { key: vendor.uid, party: vendor, onEditParty: handleExistingVendorSelect(vendor.uid), onRemoveParty: handleRemoveExistingVendor(vendor.uid), buyerLead: buyerLead, sellerLead: sellerLead, buyerContracts: buyerContracts, sellerContracts: sellerContracts, ListItemProps: {
                                    divider: i < existingVendorsFiltered.length - 1,
                                } })))))))),
                params.type && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, null,
                        React.createElement(RadioGroup, { defaultValue: "select-existing", name: "mode", value: mode, onChange: handleModeChanged },
                            React.createElement(FormControlLabel, { value: "select-existing", control: React.createElement(Radio, null), label: "Select an existing contact" }),
                            React.createElement(FormControlLabel, { value: "create-new", control: React.createElement(Radio, null), label: "Create a new contact" }))),
                    mode === "select-existing" && (React.createElement(Grid2, null,
                        React.createElement(ContactAutocomplete, { onChange: handleContactChanged, value: selectedContact, query: {
                                vendor_type: (_d = {
                                    "Buyer Closing Attorney": "Attorney",
                                    "Seller Closing Attorney": "Attorney",
                                    "Listing Agent": "Agent",
                                }[params.type]) !== null && _d !== void 0 ? _d : params.type,
                                filter: NOT_DUPLICATE_CONTACT_FILTER,
                            }, TextFieldProps: {
                                label: "Contact",
                                helperText: "You can search by the contact's name, email address, phone number, or company",
                            }, searchWhenBlank: true }))),
                    mode === "create-new" && (React.createElement(Grid2, null,
                        React.createElement(Box, { pt: 1, pb: 8 },
                            React.createElement(Grid2, { container: true, spacing: 2 }, contactFields === null || contactFields === void 0 ? void 0 : contactFields.filter((field) => ["First Name", "Last Name", "Email", "Phone", "Company", "Location"]
                                .filter((x) => x)
                                .includes(field.name)).map((field) => {
                                var _a, _b, _c;
                                return (React.createElement(Grid2, { key: field.uid, size: { xs: 12, sm: 6 } },
                                    React.createElement(DashAutoSaveField, { shouldSave: false, field: field, value: (_c = (_b = (_a = params.contact_data) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b[field.name]) !== null && _c !== void 0 ? _c : "", onSaveBegin: handleContactFieldChanged(field.name), size: "small", variant: "standard", fullWidth: true, label: field.name, apiEndpoint: "" })));
                            }))))))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { fullWidth: true, multiline: true, variant: "standard", minRows: 1, maxRows: 8, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Notes"),
                            },
                        }, value: (_e = params.notes) !== null && _e !== void 0 ? _e : "", onChange: handleNotesChanged })),
                contractUids.length > 1 && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 }, (_f = (buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts)) === null || _f === void 0 ? void 0 :
                        _f.map((contract) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            return (React.createElement(Grid2, { key: contract.uid },
                                React.createElement(FormControlLabel
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                , { 
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    label: `Associated with Contract: ${[(_a = contractsByUid[contract.uid]) === null || _a === void 0 ? void 0 : _a.address, (_c = (_b = contract.stage) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ""].filter((x) => x).join(" • ")}`, control: React.createElement(Switch, { checked: (_e = (_d = params.associated_with_contracts) === null || _d === void 0 ? void 0 : _d.includes(contract.uid)) !== null && _e !== void 0 ? _e : false, onChange: toggleAssociatedWithContract(contract.uid), disabled: !((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.hasRole(["Superadmin", "Admin"])) &&
                                            ["Closed", "Terminated"].includes((_h = (_g = contract.stage) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : "") }) })));
                        }), (_g = (sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts)) === null || _g === void 0 ? void 0 :
                        _g.map((contract) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            return (React.createElement(Grid2, { key: contract.uid },
                                React.createElement(FormControlLabel
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                , { 
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    label: `Associated with Contract: ${[(_a = contractsByUid[contract.uid]) === null || _a === void 0 ? void 0 : _a.address, (_c = (_b = contract.stage) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ""].filter((x) => x).join(" • ")}`, control: React.createElement(Switch, { checked: (_e = (_d = params.associated_with_contracts) === null || _d === void 0 ? void 0 : _d.includes(contract.uid)) !== null && _e !== void 0 ? _e : false, onChange: toggleAssociatedWithContract(contract.uid), disabled: !((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.hasRole(["Superadmin", "Admin"])) &&
                                            ["Closed", "Terminated"].includes((_h = (_g = contract.stage) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : "") }) })));
                        })))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleUpdate, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default ThirdPartyDialog;
