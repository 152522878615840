import * as React from "react";
import { TablePagination } from "@mui/material";
const SpreadsheetPagination = (props) => {
    const { count, handlePageChange, handleRowsPerPageChange, page, rowsPerPage, total } = props;
    const labelDisplayedRows = React.useCallback(({ from, to }) => {
        return `${from}-${to} of ${count}${total && total !== count ? ` (filtered from ${total})` : ""}`;
    }, [total, count]);
    return (React.createElement(TablePagination, { count: count, rowsPerPage: rowsPerPage, page: page - 1, size: "small", showFirstButton: true, showLastButton: true, onPageChange: handlePageChange, onRowsPerPageChange: handleRowsPerPageChange, rowsPerPageOptions: [50, 100, 200, 500, 1000], disabled: false, labelDisplayedRows: labelDisplayedRows, sx: {
            display: "block",
            overflow: "hidden",
            border: "none",
            height: "40px",
            "& .MuiTablePagination-toolbar": {
                minHeight: "40px",
            },
        } }));
};
export default SpreadsheetPagination;
