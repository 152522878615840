import * as React from "react";
import { Avatar, Grid2, List, ListItemAvatar, ListItemButton, ListItemText, Tooltip, Typography, useTheme, } from "@mui/material";
import { format as formatTimeAgo } from "timeago.js";
import RouterLink from "@app/common/RouterLink";
import { formatPhoneNumberMEMOIZED, getInitialsMemoized } from "@app/util/Utils";
import { selectedColor } from "../utils";
const itemHeight = 70;
const ThreadList = (props) => {
    const { selectedThread, showTwilioPhone, threads, twilioPhoneNumbers } = props;
    const { mode } = useTheme().palette;
    return (React.createElement(List, { dense: true }, threads.map((thread) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const contact = thread.direction === "inbound" ? thread.fromContact : thread.toContact;
        const twilioPhoneNumber = thread.direction === "inbound" ? thread.to : thread.from_;
        const externalPhone = thread.direction === "inbound" ? thread.from_ : thread.to;
        const twilioPhone = twilioPhoneNumbers === null || twilioPhoneNumbers === void 0 ? void 0 : twilioPhoneNumbers.find((o) => o.phone_number === twilioPhoneNumber);
        return (React.createElement(ListItemButton, { key: thread.sid, divider: true, component: RouterLink, to: `/sms?twilio_phone=${encodeURIComponent(twilioPhoneNumber)}&external_phone=${encodeURIComponent(externalPhone)}`, style: {
                background: (selectedThread === null || selectedThread === void 0 ? void 0 : selectedThread.includes(thread.to)) && selectedThread.includes(thread.from_)
                    ? selectedColor(mode)
                    : undefined,
                height: `${itemHeight}px`,
                overflowY: "hidden",
                alignItems: "top",
                WebkitAlignItems: "top",
            } },
            contact && (React.createElement(ListItemAvatar, null,
                React.createElement(Avatar, { src: undefined }, getInitialsMemoized((_a = contact.first_name) !== null && _a !== void 0 ? _a : null, (_b = contact.last_name) !== null && _b !== void 0 ? _b : null) || "?"))),
            React.createElement(ListItemText, { style: { maxHeight: `${itemHeight - 18}px` }, primary: React.createElement(Grid2, { container: true, direction: "column" },
                    React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, spacing: 1 },
                            React.createElement(Grid2, { style: { flex: 1 } },
                                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, [
                                    (showTwilioPhone || thread.from_ !== twilioPhoneNumber) &&
                                        (thread.from_ === twilioPhoneNumber
                                            ? ((_c = twilioPhone === null || twilioPhone === void 0 ? void 0 : twilioPhone.friendly_name) !== null && _c !== void 0 ? _c : `DASH Twilio ${formatPhoneNumberMEMOIZED(twilioPhoneNumber, {
                                                format: "national",
                                                failSilently: true,
                                            })}`)
                                            : [
                                                [(_d = thread.fromContact) === null || _d === void 0 ? void 0 : _d.first_name, (_e = thread.fromContact) === null || _e === void 0 ? void 0 : _e.last_name]
                                                    .filter((o) => o)
                                                    .join(" "),
                                                formatPhoneNumberMEMOIZED(thread.from_, { format: "national", failSilently: true }),
                                            ]
                                                .filter((x) => x)
                                                .join(" ")),
                                    (showTwilioPhone || thread.to !== twilioPhoneNumber) &&
                                        (thread.to === twilioPhoneNumber
                                            ? ((_f = twilioPhone === null || twilioPhone === void 0 ? void 0 : twilioPhone.friendly_name) !== null && _f !== void 0 ? _f : `DASH Twilio ${formatPhoneNumberMEMOIZED(twilioPhoneNumber, {
                                                format: "national",
                                                failSilently: true,
                                            })}`)
                                            : [
                                                [(_g = thread.toContact) === null || _g === void 0 ? void 0 : _g.first_name, (_h = thread.toContact) === null || _h === void 0 ? void 0 : _h.last_name]
                                                    .filter((o) => o)
                                                    .join(" "),
                                                formatPhoneNumberMEMOIZED(thread.to, { format: "national", failSilently: true }),
                                            ]
                                                .filter((x) => x)
                                                .join(" ")),
                                ]
                                    .filter((str) => str)
                                    .map((str) => str)
                                    .join(", "))),
                            thread.date_sent && (React.createElement(Grid2, null,
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, thread.date_sent) },
                                    React.createElement(Typography, { variant: "caption", style: { color: "#aaa" } }, formatTimeAgo(thread.date_sent))))))),
                    React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, thread.body) },
                            React.createElement(Typography, { variant: "body2", style: { color: mode === "light" ? "#888" : undefined } }, thread.body)))) })));
    })));
};
export default ThreadList;
