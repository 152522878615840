var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Call } from "@twilio/voice-sdk";
import { isValidPhoneNumber } from "@app/util/Utils";
import useTwilioDevices, { DEFAULT_BIT_RATE } from "./useTwilioDevices";
export const TwilioCallContext = React.createContext({
    from: "",
    to: "",
    setFrom: () => { },
    setTo: () => { },
    openDialer: () => { },
    canMakeCall: false,
    call: () => { },
    isDialWidgetOpen: false,
    contactSuggestions: [],
    closeDialWidget: () => { },
    calls: [],
    bitRate: DEFAULT_BIT_RATE,
    setBitRate: () => { },
    isActive: false,
});
const TwilioCallProvider = (props) => {
    const [from, setFrom] = React.useState("");
    const [to, setTo] = React.useState("");
    const [isDialWidgetOpen, setIsDialWidgetOpen] = React.useState(false);
    const [contactSuggestions, setContactSuggestions] = React.useState([]);
    const { bitRate, calls, placeCall, setBitRate, twilioDevices } = useTwilioDevices();
    const twilioDevice = React.useMemo(() => {
        // return twilioDevices?.[from.replace("+", "")];
        return twilioDevices ? Object.values(twilioDevices)[0] : null;
    }, [twilioDevices]);
    const canMakeCall = React.useMemo(() => {
        return (!!(from && to.trim() && isValidPhoneNumber(to.trim(), { country: "US" }) && twilioDevice != null) &&
            calls.filter((x) => x.call.status() !== Call.State.Closed).length === 0);
    }, [from, to, twilioDevice, calls]);
    const isActive = React.useMemo(() => {
        return twilioDevices != null;
    }, [twilioDevices]);
    console.log({ twilioDevice, from, to, calls });
    const call = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsDialWidgetOpen(false);
        placeCall(from, to);
    }), [to, from, placeCall]);
    const openDialer = (contactSuggestions_) => {
        setIsDialWidgetOpen(true);
        setContactSuggestions(contactSuggestions_ !== null && contactSuggestions_ !== void 0 ? contactSuggestions_ : []);
    };
    const closeDialWidget = React.useCallback(() => {
        setIsDialWidgetOpen(false);
    }, []);
    const value = React.useMemo(() => {
        return {
            call,
            from,
            to,
            setFrom,
            setTo,
            canMakeCall,
            openDialer,
            isDialWidgetOpen,
            contactSuggestions,
            closeDialWidget,
            calls,
            bitRate,
            setBitRate,
            isActive,
        };
    }, [
        call,
        calls,
        canMakeCall,
        closeDialWidget,
        contactSuggestions,
        from,
        isDialWidgetOpen,
        to,
        bitRate,
        setBitRate,
        isActive,
    ]);
    return React.createElement(TwilioCallContext.Provider, { value: value }, props.children);
};
export default TwilioCallProvider;
