import * as React from "react";
import { Chat, FileCopy, HourglassBottom, LabelImportant, NoteAdd } from "@mui/icons-material";
export const visibilityOptions = [
    {
        label: "Field value updates",
        icon: React.createElement(HourglassBottom, { fontSize: "small" }),
        tooltip: "Show/hide field value updates",
    },
    {
        label: "Stage changes",
        icon: React.createElement(LabelImportant, { fontSize: "small" }),
        tooltip: "Show/hide stage changes",
    },
    {
        label: "Note updates",
        icon: React.createElement(NoteAdd, { fontSize: "small" }),
        tooltip: "Show/hide note updates",
    },
    {
        label: "Comments",
        icon: React.createElement(Chat, { fontSize: "small" }),
        tooltip: "Show/hide comments",
    },
    {
        label: "Files",
        icon: React.createElement(FileCopy, { fontSize: "small" }),
        tooltip: "Show/hide files",
    },
];
export const visibilityOptionStrings = visibilityOptions.map((item) => item.label);
