import * as React from "react";
import { Add, Business } from "@mui/icons-material";
import { IconButton, List, Tooltip, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import ThirdPartyDialog from "./ThirdPartyDialog";
import ThirdPartyListItem from "./ThirdPartyListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const ThirdParties = (props) => {
    var _a;
    const { buyerContracts, buyerLead, canEdit = true, getBuyerLeadApi, getSellerLeadApi, onAddParty, onRemoveParty, onUpdateParty, parties, sellerContracts, sellerLead, transactionsPerThirdParty, } = props;
    const [isThirdPartyDialogOpen, setIsThirdPartyDialogOpen] = React.useState(false);
    const [editingPartyUid, setEditingPartyUid] = React.useState(null);
    const openThirdPartyDialog = React.useCallback(() => {
        setIsThirdPartyDialogOpen(true);
    }, [setIsThirdPartyDialogOpen]);
    const closeThirdPartyDialog = React.useCallback(() => {
        setIsThirdPartyDialogOpen(false);
    }, [setIsThirdPartyDialogOpen]);
    const addParty = React.useCallback(() => {
        setEditingPartyUid(null);
        openThirdPartyDialog();
    }, [openThirdPartyDialog]);
    const editParty = React.useCallback((agentUid) => {
        setEditingPartyUid(agentUid);
        openThirdPartyDialog();
    }, [openThirdPartyDialog]);
    const addOrUpdateParty = React.useCallback((params) => {
        closeThirdPartyDialog();
        console.log({ editingPartyUid });
        if (editingPartyUid) {
            onUpdateParty(editingPartyUid, params);
        }
        else {
            onAddParty(params);
        }
    }, [closeThirdPartyDialog, editingPartyUid, onAddParty, onUpdateParty]);
    const isLoading = parties == null;
    const noAgents = parties && parties.length === 0;
    const canAddThirdParty = React.useMemo(() => {
        var _a, _b;
        return ((_a = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts) !== null && _a !== void 0 ? _a : []).some((x) => { var _a, _b; return !["Terminated", "Closed"].includes((_b = (_a = x.stage) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""); }) ||
            ((_b = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts) !== null && _b !== void 0 ? _b : []).some((x) => { var _a, _b; return !["Terminated", "Closed"].includes((_b = (_a = x.stage) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""); });
    }, [buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts, sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(Business, null), title: "Third Parties", count: (_a = parties === null || parties === void 0 ? void 0 : parties.length) !== null && _a !== void 0 ? _a : undefined, toolbar: canEdit ? (React.createElement(Tooltip, { title: React.createElement(Typography, null,
                    "Add third party",
                    !canAddThirdParty ? " (must have an active contract)" : "") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: addParty, disabled: !canAddThirdParty },
                        React.createElement(Add, { fontSize: "small" }))))) : null },
            isLoading && React.createElement(CoreLoading, null),
            noAgents && React.createElement(Typography, { variant: "body2" }, "No third parties"),
            parties && parties.length > 0 && (React.createElement(List, { dense: true }, parties
                .sort((a, b) => {
                if (a.type < b.type)
                    return -1;
                if (a.type > b.type)
                    return 1;
                const aName = [a.contact.first_name, a.contact.last_name].filter((o) => o).join(" ");
                const bName = [b.contact.first_name, b.contact.last_name].filter((o) => o).join(" ");
                if (aName < bName)
                    return -1;
                if (aName > bName)
                    return 1;
                return 0;
            })
                .map((party, i) => (React.createElement(ThirdPartyListItem, { key: party.uid, party: party, onEditParty: editParty, onRemoveParty: onRemoveParty, buyerLead: buyerLead, sellerLead: sellerLead, buyerContracts: buyerContracts, sellerContracts: sellerContracts, ListItemProps: {
                    divider: i < parties.length - 1,
                }, numTransactions: transactionsPerThirdParty === null || transactionsPerThirdParty === void 0 ? void 0 : transactionsPerThirdParty[party.contact.uid], getBuyerLeadApi: getBuyerLeadApi, getSellerLeadApi: getSellerLeadApi, canEdit: canEdit })))))),
        isThirdPartyDialogOpen && (React.createElement(ThirdPartyDialog, { DialogProps: { open: isThirdPartyDialogOpen }, onClose: closeThirdPartyDialog, onUpdate: addOrUpdateParty, party: (parties !== null && parties !== void 0 ? parties : []).find((a) => a.uid === editingPartyUid), buyerLead: buyerLead, sellerLead: sellerLead, buyerContracts: buyerContracts, sellerContracts: sellerContracts }))));
};
export default ThirdParties;
