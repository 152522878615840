import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Grid2, MenuItem, TextField } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import dayjs_ from "@app/common/dayjs";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { TOWNE_MORTGAGE_DATE_RANGE_OPTIONS } from "@app/util/date_ranges";
const TowneMortgageListingsSidebar = () => {
    const [searchParams] = useSearchParams();
    const updateSearch = useUpdateSearch();
    const from_date = React.useMemo(() => {
        var _a;
        return (_a = searchParams.get("from_date")) !== null && _a !== void 0 ? _a : null;
    }, [searchParams]);
    const onTimePeriodChange = React.useCallback((e) => {
        updateSearch("from_date", e.target.value || null, "to_date", e.target.value ? dayjs_(new Date()).format("YYYY-MM-DD") : null);
    }, [updateSearch]);
    return (React.createElement(DashCard, { heading: "Filters", padding: 0, elevation: 0, PaperProps: {
            style: {
                overflowX: "hidden",
            },
        } },
        React.createElement(Grid2, { container: true, spacing: 2, style: { padding: "8px" } },
            React.createElement(Grid2, { size: 12 },
                React.createElement(TextField, { label: "Time Period", variant: "standard", fullWidth: true, select: true, value: from_date !== null && from_date !== void 0 ? from_date : "", onChange: onTimePeriodChange, SelectProps: {
                        displayEmpty: true,
                    }, InputLabelProps: {
                        shrink: true,
                    } },
                    React.createElement(MenuItem, { value: "" }, "All"),
                    TOWNE_MORTGAGE_DATE_RANGE_OPTIONS.map((timePeriod) => (React.createElement(MenuItem, { key: timePeriod.value, value: timePeriod.value }, timePeriod.name))))))));
};
export default TowneMortgageListingsSidebar;
