var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Star } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText, Tooltip, Typography, } from "@mui/material";
import { CanceledError } from "axios";
import { searchContacts } from "@app/orval/api/contacts";
import { formatPhoneNumber } from "@app/util/Utils";
import ServerSideAutocomplete from "./ServerSideAutocomplete";
const getOptionLabel = (option) => option.name || "";
const renderOption = (props, option) => {
    return (React.createElement(ListItem, Object.assign({}, props, { key: option.uid }),
        option.fields["Vendor Status"] === "Preferred" && (React.createElement(ListItemIcon, { style: { minWidth: 0, paddingRight: "0.5rem" } },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Preferred vendor") },
                React.createElement(Star, { color: "primary" })))),
        React.createElement(ListItemText, { primary: option.name, secondary: [
                option.fields["Email"],
                option.fields["Phone"]
                    ? formatPhoneNumber(option.fields["Phone"], { format: "national" })
                    : option.fields["Phone"],
                option.fields["Company"],
            ]
                .filter((o) => o)
                .join(" • ") })));
};
// eslint-disable-next-line eqeqeq
const isOptionEqualToValue = (option, value) => (option === null || option === void 0 ? void 0 : option.uid) == (value === null || value === void 0 ? void 0 : value.uid);
const ContactAutocomplete = (props) => {
    var _a, _b;
    const { query, searchWhenBlank } = props, other = __rest(props, ["query", "searchWhenBlank"]);
    const [isLoading, setIsLoading] = React.useState(false);
    const abortController = React.useRef(null);
    const doSearch = React.useCallback((searchString) => __awaiter(void 0, void 0, void 0, function* () {
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();
        setIsLoading(true);
        let items = [];
        try {
            const res = yield searchContacts(Object.assign(Object.assign({}, query), { search: searchString, limit: 10, offset: 0, ordering: [{ field: "Vendor Status", direction: "desc" }] }), { signal: abortController.current.signal });
            items = res.data;
            setIsLoading(false);
        }
        catch (e) {
            if (!(e instanceof CanceledError)) {
                console.error(e);
                setIsLoading(false);
            }
        }
        return items;
    }), [query, setIsLoading]);
    return (React.createElement(ServerSideAutocomplete, Object.assign({}, other, { getOptionLabel: (_a = props.getOptionLabel) !== null && _a !== void 0 ? _a : getOptionLabel, renderOption: (_b = props.renderOption) !== null && _b !== void 0 ? _b : renderOption, isOptionEqualToValue: isOptionEqualToValue, noOptionsText: "No contacts found", doSearch: doSearch, isLoading: isLoading, searchWhenBlank: searchWhenBlank })));
};
export default ContactAutocomplete;
