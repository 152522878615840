import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import pickBy from "lodash/pickBy";
import { listCachedTwilioMessagingLogs } from "@app/orval/api/twilio";
import { isValidPhoneNumber } from "@app/util/Utils";
const useListMessagesIncrementally = (params) => {
    const { fromPhoneNumber, refreshIntervalMilliseconds = 5000, requireParticipants = true, toPhoneNumber } = params;
    const dateSentAfter = useRef(null);
    const [messages, setMessages] = useState(null);
    const cancelToken = useRef(null);
    const refreshTimeout = useRef(null);
    const getMessages = useCallback(() => {
        var _a;
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        cancelToken.current = axios.CancelToken.source();
        const date = new Date();
        const queryParams = pickBy({
            thread_id: [fromPhoneNumber, toPhoneNumber].sort().join(""),
            date_sent_after: (_a = dateSentAfter.current) === null || _a === void 0 ? void 0 : _a.toISOString(),
        }, (val) => val !== undefined);
        listCachedTwilioMessagingLogs(queryParams, { cancelToken: cancelToken.current.token })
            .then((result) => {
            setMessages((prev) => {
                const existingMessageSids = new Set((prev !== null && prev !== void 0 ? prev : []).map((x) => x.sid));
                const newMessages = result.data.filter((x) => !existingMessageSids.has(x.sid));
                if (newMessages.length > 0 || prev == null) {
                    return [...newMessages, ...(prev !== null && prev !== void 0 ? prev : [])];
                }
                return prev;
            });
            dateSentAfter.current = dayjs(date).subtract(5, "minute").toDate();
            refreshTimeout.current = window.setTimeout(getMessages, refreshIntervalMilliseconds);
        })
            .catch((error) => {
            if (!axios.isCancel(error)) {
                console.error(error);
                refreshTimeout.current = window.setTimeout(getMessages, refreshIntervalMilliseconds);
            }
        });
    }, [fromPhoneNumber, refreshIntervalMilliseconds, toPhoneNumber]);
    const addMessage = useCallback((message) => {
        setMessages((prev) => (prev ? [message, ...prev] : [message]));
    }, []);
    const removeMessage = useCallback((messageSid) => {
        setMessages((prev) => { var _a; return (_a = prev === null || prev === void 0 ? void 0 : prev.filter((x) => x.sid !== messageSid)) !== null && _a !== void 0 ? _a : null; });
    }, []);
    useEffect(() => {
        setMessages(null);
        dateSentAfter.current = null;
        if ((fromPhoneNumber && toPhoneNumber && isValidPhoneNumber(toPhoneNumber, { country: "US" })) ||
            !requireParticipants) {
            getMessages();
        }
        return () => {
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
            if (refreshTimeout.current) {
                window.clearTimeout(refreshTimeout.current);
            }
        };
    }, [fromPhoneNumber, getMessages, requireParticipants, toPhoneNumber]);
    return {
        messages,
        addMessage,
        removeMessage,
    };
};
export default useListMessagesIncrementally;
