import * as React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
const SavedViewSection = (props) => {
    const { children, title } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const toggleCollapsed = React.useCallback(() => {
        setIsCollapsed((prev) => !prev);
    }, [setIsCollapsed]);
    return (React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { container: true, item: true, spacing: 1, alignItems: "center" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, title)),
            React.createElement(Grid, { item: true },
                React.createElement(IconButton, { onClick: toggleCollapsed }, isCollapsed ? React.createElement(ExpandMore, null) : React.createElement(ExpandLess, null)))),
        !isCollapsed && React.createElement(Grid, { item: true }, children)));
};
export default SavedViewSection;
