var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Refresh, Search } from "@mui/icons-material";
import { Alert, Box, Checkbox, Grid, Hidden, IconButton, InputAdornment, TextField, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import styled from "styled-components";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { CLASS_NAME_NO_FOCUS_RING, formatUserCell, renderUserCell, userSortComparator, CLASS_NAME_VERTICAL_HEADER, } from "@app/common/grid/utils";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { listSlackChannelMembers, useListSlackChannels } from "@app/orval/api/slack";
import { useUpdateSlackAccess } from "@app/orval/api/slack-access";
import { useListUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { replaceItemInArray } from "@app/util/Utils";
import { groupBy } from "../../crm/utils/utils";
const StyledCoreDataGrid = styled(CoreDataGrid) `
  & .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 200px !important;
  }
`;
const getCellClassName = () => {
    return CLASS_NAME_NO_FOCUS_RING;
};
const SlackAccessTable = () => {
    var _a, _b, _c, _d;
    const [search, setSearch] = React.useState("");
    const [currentlyUpdatingItems, setCurrentlyUpdatingItems] = React.useState([]);
    const currentlyUpdatingItemsByUser = React.useMemo(() => {
        const res = {};
        for (const item of currentlyUpdatingItems) {
            if (!res[item.userUid]) {
                res[item.userUid] = new Set();
            }
            res[item.userUid].add(item.channelId);
        }
        return res;
    }, [currentlyUpdatingItems]);
    const [channelMembersPercentComplete, setChannelMembersPercentComplete] = React.useState(null);
    const [channelMembers, setChannelMembers] = React.useState(null);
    const channelMembersByChannel = React.useMemo(() => {
        if (!channelMembers) {
            return null;
        }
        const res = {};
        for (const channel of channelMembers) {
            res[channel.channelId] = new Set(channel.members);
        }
        return res;
    }, [channelMembers]);
    const gridApi = useGridApiRef();
    const errorDialog = useErrorDialog();
    const listUsersApi = useListUsers({}, REFETCH_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const usersByUid = React.useMemo(() => {
        return users ? groupBy(users, "uid") : null;
    }, [users]);
    const listSlackChannelsApi = useListSlackChannels({ types: "private_channel", exclude_archived: true }, REFETCH_CACHE_PARAMS);
    const slackChannels = (_d = (_c = listSlackChannelsApi.data) === null || _c === void 0 ? void 0 : _c.data.items) !== null && _d !== void 0 ? _d : null;
    const updateSlackAccessApi = useUpdateSlackAccess();
    React.useEffect(() => {
        console.log("getting members");
        if (slackChannels) {
            setChannelMembers(null);
            setChannelMembersPercentComplete(0);
            const promises = slackChannels.map((channel) => __awaiter(void 0, void 0, void 0, function* () {
                const response = yield listSlackChannelMembers(channel.id);
                setChannelMembersPercentComplete((prev) => (prev !== null && prev !== void 0 ? prev : 0) + 1 / slackChannels.length);
                return {
                    channelId: channel.id,
                    members: response.data.items,
                };
            }));
            Promise.all(promises)
                .then((value) => {
                setChannelMembers(value);
            })
                .catch(errorDialog);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listSlackChannelsApi.data]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading users",
                queryResult: listUsersApi,
            },
            {
                label: "Loading Slack channels...",
                queryResult: listSlackChannelsApi,
            },
            {
                label: "Getting members in channels...",
                status: channelMembers ? "success" : "pending",
                percentComplete: channelMembersPercentComplete !== null && channelMembersPercentComplete !== void 0 ? channelMembersPercentComplete : undefined,
            },
        ],
    });
    const toggleChannel = React.useCallback((userUid, channelId) => (_, checked) => {
        setCurrentlyUpdatingItems((prev) => [
            ...prev,
            {
                userUid,
                channelId,
            },
        ]);
        updateSlackAccessApi
            .mutateAsync({
            data: {
                user_uid: userUid,
                channel_id: channelId,
                has_access: checked,
            },
        })
            .then(() => {
            setChannelMembers((prev) => replaceItemInArray({
                arr: prev,
                getValue: (item) => (Object.assign(Object.assign({}, item), { members: checked
                        ? [...item.members, usersByUid[userUid].fields["Slack ID"]]
                        : item.members.filter((x) => x !== usersByUid[userUid].fields["Slack ID"]) })),
                where: (item) => item.channelId === channelId,
            }));
        })
            .catch(errorDialog)
            .then(() => {
            setCurrentlyUpdatingItems((prev) => prev.filter((x) => x.channelId !== channelId || x.userUid !== userUid));
        });
    }, [errorDialog, updateSlackAccessApi, usersByUid]);
    const slackChannelsSorted = React.useMemo(() => {
        console.log("sorting slack channels");
        if (slackChannels) {
            return slackChannels.sort((a, b) => {
                var _a, _b;
                const aName = (_a = a.name) !== null && _a !== void 0 ? _a : "";
                const bName = (_b = b.name) !== null && _b !== void 0 ? _b : "";
                if (aName < bName)
                    return -1;
                if (aName > bName)
                    return 1;
                return 0;
            });
        }
        return null;
    }, [slackChannels]);
    const columns = React.useMemo(() => {
        console.log("getting columns...");
        return [
            {
                field: "user",
                headerName: "",
                editable: false,
                sortable: true,
                width: 125,
                resizable: true,
                sortComparator: userSortComparator,
                cellClassName: "cell-button",
                align: "center",
                renderCell: renderUserCell,
                valueFormatter: formatUserCell,
            },
            ...(slackChannelsSorted !== null && slackChannelsSorted !== void 0 ? slackChannelsSorted : []).map((channel) => ({
                field: channel.id,
                headerName: channel.name,
                headerClassName: [CLASS_NAME_VERTICAL_HEADER],
                width: 40,
                minWidth: 40,
                resizable: false,
                align: "center",
                colSpan: (_, row) => {
                    const userUid = row.id;
                    const user = usersByUid[userUid];
                    if (!user.fields["Slack ID"]) {
                        return (slackChannelsSorted !== null && slackChannelsSorted !== void 0 ? slackChannelsSorted : []).length;
                    }
                    return 1;
                },
                renderCell: (params) => {
                    var _a;
                    const userUid = params.id;
                    const user = usersByUid[userUid];
                    if (!user.fields["Slack ID"]) {
                        return "Slack ID is not set for this user";
                    }
                    const channelMembers_ = channelMembersByChannel[channel.id];
                    return (React.createElement(Checkbox, { size: "small", color: "primary", checked: user.fields["Slack ID"] ? channelMembers_.has(user.fields["Slack ID"]) : false, onChange: toggleChannel(params.row.id, channel.id), 
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        disabled: ((_a = currentlyUpdatingItemsByUser[userUid]) === null || _a === void 0 ? void 0 : _a.has(channel.id)) || !user.fields["Slack ID"], style: { padding: 0 } }));
                },
            })),
        ].map((column) => (Object.assign(Object.assign({}, column), { filterable: false, disableColumnMenu: true, width: column.width || 150, resizable: column.resizable !== false, editable: false, type: column.type || "string" })));
    }, [slackChannelsSorted, usersByUid, channelMembersByChannel, toggleChannel, currentlyUpdatingItemsByUser]);
    const rows = React.useMemo(() => {
        console.log("getting rows");
        return (users !== null && users !== void 0 ? users : []).map((user) => {
            const row = { id: user.uid, user };
            for (const channel of slackChannelsSorted !== null && slackChannelsSorted !== void 0 ? slackChannelsSorted : []) {
                row[channel.id] = user.fields["Slack ID"]
                    ? channelMembersByChannel === null || channelMembersByChannel === void 0 ? void 0 : channelMembersByChannel[channel.id].has(user.fields["Slack ID"])
                    : false;
            }
            return row;
        });
    }, [users, slackChannelsSorted, channelMembersByChannel]);
    const handleSearchChanged = React.useCallback((e) => {
        const searchStr = e.target.value;
        setSearch(searchStr);
        gridApi.current.setQuickFilterValues([searchStr]);
    }, [setSearch, gridApi]);
    const refresh = React.useCallback(() => {
        setChannelMembers(null);
        listUsersApi.refetch();
        listSlackChannelsApi.refetch();
    }, [listUsersApi, listSlackChannelsApi]);
    if (isLoading) {
        return loadingComponent;
    }
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid, { item: true },
            React.createElement(Alert, { severity: "info" },
                React.createElement(Typography, { variant: "body1", display: "inline" }, "Use this widget to give users access to private Slack channels."))),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 1 },
                React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { size: "small", style: { width: "300px" }, value: search, onChange: handleSearchChanged, slotProps: {
                                    input: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(Search, null))),
                                    },
                                } }))),
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true, style: { flex: 1 } })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Refresh") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: refresh },
                                    React.createElement(Refresh, null)))))))),
        React.createElement(Grid, { item: true, style: { flex: 1, overflow: "hidden" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(StyledCoreDataGrid, { rows: rows, columns: columns, density: "compact", rowHeight: 30, columnHeaderHeight: 300, disableRowSelectionOnClick: true, hideFooter: true, pagination: false, rowCount: rows.length, apiRef: gridApi, getCellClassName: getCellClassName, initialState: {
                        pinnedColumns: {
                            left: ["id", "user"],
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: [search],
                            },
                        },
                    } })))));
};
export default SlackAccessTable;
