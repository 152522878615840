import * as React from "react";
import { Delete, GetApp } from "@mui/icons-material";
import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import prettyBytes from "pretty-bytes";
import styled from "styled-components";
import CoreLink from "@app/common/CoreLink";
import FileIcon from "@app/common/FileIcon";
import { getFullNameMemoized, getInitialsMemoized } from "@app/util/Utils";
const FileUploadGrid = styled(Grid) `
  & .delete-file-button,
  .share-file-button,
  .download-file-button {
    display: none;
  }
  &:hover {
    & .delete-file-button,
    .share-file-button,
    .download-file-button {
      display: inline;
    }
  }
`;
const FileIconGridItem = styled(Grid) `
  & svg {
    max-width: 36px !important;
  }
  & img {
    max-width: 36px !important;
  }
`;
const FileUploadChronologyItem = (props) => {
    var _a, _b;
    const { file, handleDelete } = props;
    const onDelete = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (handleDelete) {
            handleDelete(file.uid);
        }
    }, [handleDelete, file.uid]);
    const fileUrl = `/files/${file.uid}`;
    return (React.createElement(FileUploadGrid, { className: "file-upload-grid", container: true, item: true, spacing: 1, xs: 12, 
        /* @ts-expect-error */
        component: CoreLink, href: fileUrl, target: "_blank", style: {
            color: "unset",
            textDecoration: "unset",
            border: "1px solid #ccc",
            borderRadius: "0.25rem",
            background: "rgba(0, 0, 0, 0.01)",
        } },
        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2, style: { padding: "0.5rem" } },
            React.createElement(FileIconGridItem, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(FileIcon, { mimeType: (_a = file.mime_type) !== null && _a !== void 0 ? _a : "application/octet-stream", url: fileUrl, maxHeight: 32 })),
            React.createElement(Grid, { item: true, container: true, style: { flex: 1, fontSize: "0.75rem" }, alignContent: "flex-start", spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, file.filename)),
                file.uploaded_by && (React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 1 },
                    React.createElement(Grid, { item: true, style: {
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(Avatar, { src: (_b = file.uploaded_by.icon_url) !== null && _b !== void 0 ? _b : undefined, style: {
                                height: "18px",
                                width: "18px",
                                fontSize: "0.65rem",
                            } }, getInitialsMemoized(file.uploaded_by.first_name, file.uploaded_by.last_name) || "?")),
                    React.createElement(Grid, { item: true, style: {
                            flex: 1,
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(Typography, { variant: "caption", style: { color: "#888" } },
                            "Uploaded by ",
                            getFullNameMemoized(file.uploaded_by.first_name, file.uploaded_by.last_name)))))),
            React.createElement(Grid, { className: "file-actions-div", item: true },
                React.createElement(Tooltip, { title: "Download file" },
                    React.createElement(IconButton, { className: "download-file-button", size: "small", component: CoreLink, href: fileUrl },
                        React.createElement(GetApp, { fontSize: "small" }))),
                React.createElement(Tooltip, { title: "Delete" },
                    React.createElement(IconButton, { className: "delete-file-button", onClick: onDelete, size: "small" },
                        React.createElement(Delete, { fontSize: "small" })))),
            React.createElement(Grid, { item: true, style: { textAlign: "right", width: "108px" } },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { style: { color: "#aaa" }, variant: "caption" }, dayjs(`${file.created_at.replace("T", " ").split(".")[0]}+00:00`).format("M/D/YYYY h:mm A"))),
                    file.size && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "overline", style: { lineHeight: 1.25, color: "#aaa" } }, prettyBytes(file.size)))))))));
};
export default FileUploadChronologyItem;
