import * as React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppConfig from "@app/util/AppConfig";
const DashCard = (props) => {
    const { PaperProps, children, elevation, heading, height, overflowY, padding, themeColor, toolbar } = props;
    const getMuiTheme = React.useCallback((parentTheme) => {
        return createTheme(Object.assign(Object.assign({}, parentTheme), { palette: Object.assign(Object.assign({}, parentTheme.palette), { primary: Object.assign(Object.assign({}, parentTheme.palette.primary), { 500: themeColor !== null && themeColor !== void 0 ? themeColor : AppConfig.themeColors.primary }) }), components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            overflowY: overflowY !== null && overflowY !== void 0 ? overflowY : "auto",
                            height: height !== null && height !== void 0 ? height : "inherit",
                        },
                    },
                },
            } }));
    }, [themeColor, overflowY, height]);
    return (React.createElement(ThemeProvider, { theme: getMuiTheme },
        React.createElement(Paper, Object.assign({}, PaperProps, { elevation: elevation }),
            (heading || toolbar) && (React.createElement(React.Fragment, null,
                React.createElement(Box, { component: Grid, container: true, justifyContent: "space-between", p: 1 },
                    React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, typeof heading === "string" ? React.createElement(Typography, { variant: "h6" }, heading) : heading),
                    toolbar && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, toolbar))),
                React.createElement(Divider, null))),
            React.createElement(Box, { p: padding }, children))));
};
export default DashCard;
