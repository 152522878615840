import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid, InputAdornment, } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useListInterviewTypes } from "@app/orval/api/interview-types";
import { useListUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import UserAutocomplete from "../../components/box-view/actions/UserAutocomplete";
dayjs.extend(utc);
dayjs.extend(timezone);
const getInitialState = (interview) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        uid: (_a = interview === null || interview === void 0 ? void 0 : interview.uid) !== null && _a !== void 0 ? _a : undefined,
        date_and_time: (_b = interview === null || interview === void 0 ? void 0 : interview.date_and_time) !== null && _b !== void 0 ? _b : null,
        type: (_c = interview === null || interview === void 0 ? void 0 : interview.type) !== null && _c !== void 0 ? _c : null,
        interviewer: (_e = (_d = interview === null || interview === void 0 ? void 0 : interview.interviewer) === null || _d === void 0 ? void 0 : _d.uid) !== null && _e !== void 0 ? _e : null,
        notes: (_f = interview === null || interview === void 0 ? void 0 : interview.notes) !== null && _f !== void 0 ? _f : null,
    };
};
const InterviewDialog = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { DialogProps, interview, onClose, onUpdate } = props;
    const [formData, setFormData] = useStateWithCallback(getInitialState(interview));
    const listInterviewTypesApi = useListInterviewTypes(INFINITE_CACHE_PARAMS);
    const interviewTypes = (_b = (_a = listInterviewTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listUsersApi = useListUsers({}, INFINITE_CACHE_PARAMS);
    const users = (_d = (_c = listUsersApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const isValid = React.useMemo(() => {
        return formData.date_and_time || formData.interviewer || formData.notes || formData.type;
    }, [formData]);
    const setType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { type: e.target.value })));
    }, [setFormData]);
    const setDateTime = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { date_and_time: date })));
    }, [setFormData]);
    const setNotes = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { notes: e.target.value })));
    }, [setFormData]);
    const setUser = React.useCallback((_, value) => {
        setFormData((prev) => {
            var _a;
            return (Object.assign(Object.assign({}, prev), { interviewer: (_a = value === null || value === void 0 ? void 0 : value.uid) !== null && _a !== void 0 ? _a : null }));
        });
    }, [setFormData]);
    const handleUpdate = React.useCallback(() => {
        onUpdate(formData);
    }, [onUpdate, formData]);
    console.log("TEST:", formData.date_and_time);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, interview ? "Edit interview" : "Add interview"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Type"),
                            },
                        }, value: (_e = formData.type) !== null && _e !== void 0 ? _e : "", onChange: setType }, (interviewTypes !== null && interviewTypes !== void 0 ? interviewTypes : []).map((interviewType) => (React.createElement(MenuItem, { key: interviewType.name, value: interviewType.name }, interviewType.name))))),
                React.createElement(Grid, { item: true },
                    React.createElement(CoreDateField, { format: "dddd, MMMM D, YYYY h:mm A", formatType: "dayjs", mode: "datetime", date: (_f = formData.date_and_time) !== null && _f !== void 0 ? _f : null, onDateChange: setDateTime, size: "small", fullWidth: true, 
                        // @ts-expect-error: not assignable to type 'standard'
                        variant: "outlined", hasPicker: true, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Date & time"),
                                endAdornment: React.createElement(InputAdornment, { position: "end" }, "Eastern time"),
                            },
                        } })),
                users && (React.createElement(Grid, { item: true },
                    React.createElement(UserAutocomplete, { users: users, value: (_g = users.find((x) => x.uid === formData.interviewer)) !== null && _g !== void 0 ? _g : null, onChange: setUser, TextFieldProps: {
                            InputProps: { startAdornment: React.createElement(InputAdornment, { position: "start" }, "Interviewer") },
                        } }))),
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { fullWidth: true, multiline: true, variant: "standard", minRows: 1, maxRows: 8, slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Notes"),
                            },
                        }, value: (_h = formData.notes) !== null && _h !== void 0 ? _h : "", onChange: setNotes })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleUpdate, disabled: !isValid || !users, tabIndex: -1 }, "OK"))));
};
export default InterviewDialog;
