import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import pickBy from "lodash/pickBy";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useFileUploads from "@app/hooks/useFileUploads";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useAddBuyerContract, useDeleteBuyerContract, getBuyerContract, searchBuyerContracts, } from "@app/orval/api/buyer-contracts";
import { useAddAgentForBuyerLead, useAddBuyerLead, useAddClientForBuyerLead, useAddPropertyForBuyerLead, useAddShowingForBuyerLead, useAddVendorForBuyerLead, useCreatePspForProperty, useDeleteBuyerLead, useGetBuyerLead, useLinkPspToBuyerLeadProperty, useLinkQfoToBuyerLeadProperty, useListEmailsForBuyerLead, useListStreakCommentsForBuyerLead, useListStreakFilesForBuyerLead, useRemoveAgentForBuyerLead, useRemoveClientForBuyerLead, useRemovePropertyForBuyerLead, useRemoveShowingForBuyerLead, useRemoveVendorForBuyerLead, useReorderClientsForBuyerLead, useUnlinkPspFromBuyerLeadProperty, useUnlinkQfoFromBuyerLeadProperty, useUpdateAgentForBuyerLead, useUpdateBuyerLead, useUpdatePropertyForBuyerLead, useUpdateShowingForBuyerLead, useUpdateVendorForBuyerLead, } from "@app/orval/api/buyer-leads";
import { useListMessagesForBuyerLead } from "@app/orval/api/clients";
import { useAddComment, useDeleteComment, useUpdateComment, listComments } from "@app/orval/api/comments";
import { useListCallsForBuyerLead } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListFieldSections } from "@app/orval/api/field-sections";
import { searchFiles, useDeleteFile } from "@app/orval/api/files";
import { useAddQfo } from "@app/orval/api/forms-questions-for-the-offer";
import { listNewsfeedBuyerContractStageChanges, listNewsfeedBuyerLeadNoteChanges, listNewsfeedBuyerLeadStageChanges, listNewsfeedFieldValueUpdates, listNewsfeedBuyerLeadAgentChanges, } from "@app/orval/api/newsfeed";
import { searchNotifications } from "@app/orval/api/notifications";
import { useCreatePsp } from "@app/orval/api/psps";
import { useListStages } from "@app/orval/api/stages";
import { listTasks, useAddTask, useDeleteTask, useUpdateTask } from "@app/orval/api/tasks";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import useCanEditBuyerLead from "./useCanEditBuyerLead";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import useCollaborators from "../hooks/useCollaborators";
import useStages from "../hooks/useStages";
const useBuyerLeadApis = (uid, options) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
    const { onPropertyAdded, search } = options;
    // state
    const [currentlySavingComment, setCurrentlySavingComment] = useStateWithCallback(null);
    const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = useStateWithCallback(new Set());
    // hooks
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { uploadFile } = useFileUploads();
    // api
    const getBuyerLeadApi = useGetBuyerLead(uid, REFETCH_CACHE_PARAMS);
    const buyerLead = ((_b = (_a = getBuyerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
    const searchBuyerContractsApi = usePostQuery(searchBuyerContracts, {
        buyer_lead_uid: uid,
        should_recalculate_formulas: true,
    }, {});
    const buyerContracts = useMemo(() => {
        var _a, _b;
        return ((_b = (_a = searchBuyerContractsApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            var _a, _b, _c, _d;
            if (((_a = a.stage) === null || _a === void 0 ? void 0 : _a.name) !== "Terminated" && ((_b = b.stage) === null || _b === void 0 ? void 0 : _b.name) === "Terminated")
                return -1;
            if (((_c = a.stage) === null || _c === void 0 ? void 0 : _c.name) === "Terminated" && ((_d = b.stage) === null || _d === void 0 ? void 0 : _d.name) !== "Terminated")
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [searchBuyerContractsApi.data]);
    const canEdit = useCanEditBuyerLead(buyerLead);
    /**
     * Update the lead's Buyer Contracts in the cache
     */
    const setBuyerContracts = useCallback((value) => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            // if `value` is a function, call it
            const newValue = typeof value === "function" ? value(old.data) : value;
            return Object.assign(Object.assign({}, old), { data: newValue });
        });
    }, [queryClient, searchBuyerContractsApi.queryKey]);
    const listBuyerLeadFieldsApi = useListFields({ entity_type: "Buyer Lead" }, REFETCH_CACHE_PARAMS);
    const buyerLeadFields = (_d = (_c = listBuyerLeadFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const buyerLeadFieldsByName = useMemo(() => {
        return buyerLeadFields === null || buyerLeadFields === void 0 ? void 0 : buyerLeadFields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [buyerLeadFields]);
    const listBuyerContractFieldsApi = useListFields({ entity_type: "Buyer Contract" }, REFETCH_CACHE_PARAMS);
    const buyerContractFields = (_f = (_e = listBuyerContractFieldsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const buyerContractFieldsByName = useMemo(() => {
        return buyerContractFields === null || buyerContractFields === void 0 ? void 0 : buyerContractFields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [buyerContractFields]);
    const listBuyerLeadFieldSectionsApi = useListFieldSections({ entity_type: "Buyer Lead" }, REFETCH_CACHE_PARAMS);
    const buyerLeadFieldSections = (_h = (_g = listBuyerLeadFieldSectionsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const listBuyerContractFieldSectionsApi = useListFieldSections({ entity_type: "Buyer Contract" }, REFETCH_CACHE_PARAMS);
    const buyerContractFieldSections = (_k = (_j = listBuyerContractFieldSectionsApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const listBuyerLeadStagesApi = useListStages({ type: "Buyer Lead" }, REFETCH_CACHE_PARAMS);
    const buyerLeadStages = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listBuyerLeadStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    }, [listBuyerLeadStagesApi.data]);
    const listBuyerContractStagesApi = useListStages({ type: "Buyer Contract" }, REFETCH_CACHE_PARAMS);
    const buyerContractStages = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listBuyerContractStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    }, [listBuyerContractStagesApi.data]);
    const createBuyerLeadApi = useAddBuyerLead();
    const updateBuyerLeadApi = useUpdateBuyerLead();
    const deleteBuyerLeadApi = useDeleteBuyerLead();
    const createBuyerContractApi = useAddBuyerContract();
    const deleteBuyerContractApi = useDeleteBuyerContract();
    const listCommentsApi = usePostQuery(listComments, { buyer_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const comments = (_m = (_l = listCommentsApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const searchNotificationsApi = usePostQuery(searchNotifications, { entity_uids: [(_o = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.uid) !== null && _o !== void 0 ? _o : "", ...((_p = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts) !== null && _p !== void 0 ? _p : []).map((x) => x.uid)] }, {}, !!buyerLead);
    const notifications = (_r = (_q = searchNotificationsApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const addCommentApi = useAddComment();
    const updateCommentApi = useUpdateComment();
    const deleteCommentApi = useDeleteComment();
    const listTasksApi = usePostQuery(listTasks, { buyer_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const tasks = (_t = (_s = listTasksApi.data) === null || _s === void 0 ? void 0 : _s.data) !== null && _t !== void 0 ? _t : null;
    const addTaskApi = useAddTask();
    const updateTaskApi = useUpdateTask();
    const deleteTaskApi = useDeleteTask();
    const listEmailsApi = useListEmailsForBuyerLead(uid, REFETCH_CACHE_PARAMS);
    const emails = (_v = (_u = listEmailsApi.data) === null || _u === void 0 ? void 0 : _u.data) !== null && _v !== void 0 ? _v : null;
    const listMessagesApi = useListMessagesForBuyerLead(uid, REFETCH_CACHE_PARAMS);
    const messages = (_x = (_w = listMessagesApi.data) === null || _w === void 0 ? void 0 : _w.data) !== null && _x !== void 0 ? _x : null;
    const listCallsApi = useListCallsForBuyerLead(uid, REFETCH_CACHE_PARAMS);
    const calls = (_z = (_y = listCallsApi.data) === null || _y === void 0 ? void 0 : _y.data) !== null && _z !== void 0 ? _z : null;
    const fieldValueChangesApi = usePostQuery(listNewsfeedFieldValueUpdates, { buyer_lead_uids: [uid], buyer_contract_uids: (_0 = buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.map((x) => x.uid)) !== null && _0 !== void 0 ? _0 : undefined, search }, REFETCH_CACHE_PARAMS);
    const fieldValueChanges = (_2 = (_1 = fieldValueChangesApi.data) === null || _1 === void 0 ? void 0 : _1.data) !== null && _2 !== void 0 ? _2 : null;
    const noteChangesApi = usePostQuery(listNewsfeedBuyerLeadNoteChanges, { buyer_lead_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const noteChanges = (_4 = (_3 = noteChangesApi.data) === null || _3 === void 0 ? void 0 : _3.data) !== null && _4 !== void 0 ? _4 : null;
    const agentChangesApi = usePostQuery(listNewsfeedBuyerLeadAgentChanges, { buyer_lead_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const agentChanges = (_6 = (_5 = agentChangesApi.data) === null || _5 === void 0 ? void 0 : _5.data) !== null && _6 !== void 0 ? _6 : null;
    const stageChangesApi = usePostQuery(listNewsfeedBuyerLeadStageChanges, { buyer_lead_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const stageChanges = (_8 = (_7 = stageChangesApi.data) === null || _7 === void 0 ? void 0 : _7.data) !== null && _8 !== void 0 ? _8 : null;
    const buyerContractStageChangesApi = usePostQuery(listNewsfeedBuyerContractStageChanges, { buyer_contract_uids: (_9 = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts.map((x) => x.uid)) !== null && _9 !== void 0 ? _9 : [] }, REFETCH_CACHE_PARAMS, !!(buyerLead && buyerLead.contracts.length > 0));
    const buyerContractStageChanges = buyerLead && buyerLead.contracts.length > 0 ? ((_11 = (_10 = buyerContractStageChangesApi.data) === null || _10 === void 0 ? void 0 : _10.data) !== null && _11 !== void 0 ? _11 : null) : null;
    const streakCommentsApi = useListStreakCommentsForBuyerLead(uid, REFETCH_CACHE_PARAMS);
    const streakComments = (_13 = (_12 = streakCommentsApi.data) === null || _12 === void 0 ? void 0 : _12.data) !== null && _13 !== void 0 ? _13 : null;
    const streakFilesApi = useListStreakFilesForBuyerLead(uid, REFETCH_CACHE_PARAMS);
    const streakFiles = (_15 = (_14 = streakFilesApi.data) === null || _14 === void 0 ? void 0 : _14.data) !== null && _15 !== void 0 ? _15 : null;
    const listFilesApi = usePostQuery(searchFiles, { buyer_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const files = (_17 = (_16 = listFilesApi.data) === null || _16 === void 0 ? void 0 : _16.data) !== null && _17 !== void 0 ? _17 : null;
    const deleteFileApi = useDeleteFile();
    const addAgentApi = useAddAgentForBuyerLead();
    const updateAgentApi = useUpdateAgentForBuyerLead();
    const removeAgentApi = useRemoveAgentForBuyerLead();
    const addClientApi = useAddClientForBuyerLead();
    const removeClientApi = useRemoveClientForBuyerLead();
    const reorderClientsApi = useReorderClientsForBuyerLead();
    const addThirdPartyApi = useAddVendorForBuyerLead();
    const updateThirdPartyApi = useUpdateVendorForBuyerLead();
    const removeThirdPartyApi = useRemoveVendorForBuyerLead();
    const addPropertyApi = useAddPropertyForBuyerLead();
    const updatePropertyApi = useUpdatePropertyForBuyerLead();
    const deletePropertyApi = useRemovePropertyForBuyerLead();
    const addPSPApi = useCreatePsp();
    const linkPSPApi = useLinkPspToBuyerLeadProperty();
    const unlinkPSPApi = useUnlinkPspFromBuyerLeadProperty();
    const createPspForBuyerLeadPropertyApi = useCreatePspForProperty();
    const addQFOApi = useAddQfo();
    const linkQFOApi = useLinkQfoToBuyerLeadProperty();
    const unlinkQFOApi = useUnlinkQfoFromBuyerLeadProperty();
    const addShowingApi = useAddShowingForBuyerLead();
    const updateShowingApi = useUpdateShowingForBuyerLead();
    const removeShowingApi = useRemoveShowingForBuyerLead();
    const onFieldSaveBegin = useCallback((fieldName, fieldValue) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), { [fieldName]: fieldValue }) }) });
        });
    }, [getBuyerLeadApi.queryKey, queryClient]);
    const onFieldSaveComplete = useCallback((response) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), Object.fromEntries(Object.entries(response.data.fields).filter(([name, _]) => {
                        var _a;
                        return (_a = buyerLeadFieldsByName === null || buyerLeadFieldsByName === void 0 ? void 0 : buyerLeadFieldsByName[name]) === null || _a === void 0 ? void 0 : _a.formula;
                    }))) }) });
        });
    }, [buyerLeadFieldsByName, getBuyerLeadApi.queryKey, queryClient]);
    const onFieldSaveError = useCallback((error) => {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            confirm({
                title: "Unable to save field",
                description: String(error.response.data),
                hideCancelButton: true,
                confirmationText: "Ok",
            });
        }
        getBuyerLeadApi.refetch();
    }, [confirm, getBuyerLeadApi]);
    const onBuyerContractFieldSaveBegin = useCallback((buyerContractUid) => (fieldName, fieldValue) => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            const indx = old.data.findIndex((x) => x.uid === buyerContractUid);
            const data = [
                ...old.data.slice(0, indx),
                Object.assign(Object.assign({}, old.data[indx]), { fields: Object.assign(Object.assign({}, old.data[indx].fields), { [fieldName]: fieldValue }) }),
                ...old.data.slice(indx + 1, old.data.length),
            ];
            return Object.assign(Object.assign({}, old), { data });
        });
    }, [queryClient, searchBuyerContractsApi.queryKey]);
    const onBuyerContractFieldSaveComplete = useCallback((buyerContractUid) => (response) => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            const indx = old.data.findIndex((x) => x.uid === buyerContractUid);
            const data = [
                ...old.data.slice(0, indx),
                Object.assign(Object.assign({}, old.data[indx]), { fields: Object.assign(Object.assign({}, old.data[indx].fields), Object.fromEntries(Object.entries(response.data.fields).filter(([name, _]) => {
                        var _a;
                        return (_a = buyerContractFieldsByName === null || buyerContractFieldsByName === void 0 ? void 0 : buyerContractFieldsByName[name]) === null || _a === void 0 ? void 0 : _a.formula;
                    }))) }),
                ...old.data.slice(indx + 1, old.data.length),
            ];
            return Object.assign(Object.assign({}, old), { data });
        });
    }, [buyerContractFieldsByName, queryClient, searchBuyerContractsApi.queryKey]);
    const onBuyerContractFieldSaveError = useCallback((buyerContractUid) => (error) => {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            confirm({
                title: "Unable to save field",
                description: String(error.response.data),
                hideCancelButton: true,
                confirmationText: "Ok",
            });
        }
        getBuyerContract(buyerContractUid)
            .then((result) => {
            queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
                if (!old)
                    return old;
                const indx = old.data.findIndex((x) => x.uid === buyerContractUid);
                return Object.assign(Object.assign({}, old), { data: [...old.data.slice(0, indx), result.data, ...old.data.slice(indx + 1, old.data.length)] });
            });
        })
            .catch(console.error);
    }, [confirm, queryClient, searchBuyerContractsApi.queryKey]);
    const createBuyerContract = useCallback(() => {
        createBuyerContractApi
            .mutateAsync({ data: { buyer_lead_uid: uid } })
            .then((result) => {
            queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
                console.log("_ADDING BUYER CONTRACT");
                if (!old)
                    return old;
                console.log("ADDING BUYER CONTRACT");
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
            getBuyerLeadApi.refetch();
        })
            .catch(errorDialog);
    }, [createBuyerContractApi, errorDialog, getBuyerLeadApi, queryClient, searchBuyerContractsApi.queryKey, uid]);
    const { setBuyerContractStage: setBuyerContractStage_, setBuyerLeadStage } = useStages();
    const { addCollaboratorForBuyerLead, removeCollaboratorForBuyerLead, updateCollaboratorForBuyerLead } = useCollaborators();
    const onStageUpdateBegin = useCallback((_, stageUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { stage: Object.assign(Object.assign({}, buyerLeadStages.find((x) => x.uid === stageUid)), { updated_at: new Date().toISOString() }) }) });
        });
    }, [buyerLeadStages, getBuyerLeadApi.queryKey, queryClient]);
    const onStageUpdateError = useCallback(() => {
        getBuyerLeadApi.refetch();
    }, [getBuyerLeadApi]);
    const onBuyerLeadUpdate = useCallback((result) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
    }, [getBuyerLeadApi.queryKey, queryClient]);
    const onBuyerContractCreated = useCallback((result) => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
        });
        getBuyerLeadApi.refetch();
    }, [getBuyerLeadApi, queryClient, searchBuyerContractsApi.queryKey]);
    const setStage = useCallback((stageUid, params) => {
        var _a;
        setBuyerLeadStage(uid, stageUid, {
            onBeforeUpdate: onStageUpdateBegin,
            onError: onStageUpdateError,
            onBuyerLeadUpdated: onBuyerLeadUpdate,
            onBuyerContractCreated,
            overrideValidator: (_a = params === null || params === void 0 ? void 0 : params.overrideValidator) !== null && _a !== void 0 ? _a : false,
        });
    }, [onBuyerContractCreated, onBuyerLeadUpdate, onStageUpdateBegin, onStageUpdateError, setBuyerLeadStage, uid]);
    const onBeforeBuyerContractStageUpdate = useCallback((buyerContractUid, stageUid) => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            const indx = old.data.findIndex((x) => x.uid === buyerContractUid);
            const data = [
                ...old.data.slice(0, indx),
                Object.assign(Object.assign({}, old.data[indx]), { stage: Object.assign(Object.assign({}, buyerContractStages.find((x) => x.uid === stageUid)), { updated_at: new Date().toISOString() }) }),
                ...old.data.slice(indx + 1, old.data.length),
            ];
            return Object.assign(Object.assign({}, old), { data });
        });
    }, [buyerContractStages, queryClient, searchBuyerContractsApi.queryKey]);
    const onBuyerContractStageUpdate = useCallback((result) => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            const indx = old.data.findIndex((x) => x.uid === result.data.uid);
            return Object.assign(Object.assign({}, old), { data: [...old.data.slice(0, indx), result.data, ...old.data.slice(indx + 1, old.data.length)] });
        });
    }, [queryClient, searchBuyerContractsApi.queryKey]);
    const onBuyerContractStageUpdateError = useCallback((dataBeforeUpdate) => () => {
        queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            const indx = old.data.findIndex((x) => x.uid === dataBeforeUpdate.uid);
            return Object.assign(Object.assign({}, old), { data: [...old.data.slice(0, indx), dataBeforeUpdate, ...old.data.slice(indx + 1, old.data.length)] });
        });
    }, [queryClient, searchBuyerContractsApi.queryKey]);
    const setBuyerContractStage = useCallback((buyerContractUid) => (stageUid, params) => {
        var _a;
        const overrideValidator = (_a = params === null || params === void 0 ? void 0 : params.overrideValidator) !== null && _a !== void 0 ? _a : false;
        const dataBeforeUpdate = buyerContracts.find((x) => x.uid === buyerContractUid);
        setBuyerContractStage_(buyerContractUid, stageUid, {
            onBeforeUpdate: onBeforeBuyerContractStageUpdate,
            onError: onBuyerContractStageUpdateError(dataBeforeUpdate),
            onUpdate: onBuyerContractStageUpdate,
            overrideValidator,
        });
    }, [
        buyerContracts,
        setBuyerContractStage_,
        onBeforeBuyerContractStageUpdate,
        onBuyerContractStageUpdateError,
        onBuyerContractStageUpdate,
    ]);
    const setNotes = useCallback((notes) => {
        updateBuyerLeadApi
            .mutateAsync({ uid, data: { notes } })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, uid, updateBuyerLeadApi]);
    const addCollaborator = useCallback((params) => {
        addCollaboratorForBuyerLead(uid, params, {
            onSaveComplete: onBuyerLeadUpdate,
        });
    }, [addCollaboratorForBuyerLead, onBuyerLeadUpdate, uid]);
    const updateCollaborator = useCallback((collaboratorUid, params) => {
        updateCollaboratorForBuyerLead(uid, collaboratorUid, params, {
            onUpdate: onBuyerLeadUpdate,
        });
    }, [uid, onBuyerLeadUpdate, updateCollaboratorForBuyerLead]);
    const removeCollaborator = useCallback((collaboratorUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { collaborators: old.data.collaborators.filter((a) => a.uid !== collaboratorUid) }) });
        });
        removeCollaboratorForBuyerLead(uid, collaboratorUid, {
            onRemove: onBuyerLeadUpdate,
        });
    }, [getBuyerLeadApi.queryKey, onBuyerLeadUpdate, queryClient, removeCollaboratorForBuyerLead, uid]);
    const addAgent = useCallback((params) => {
        addAgentApi
            .mutateAsync({ buyerLeadUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addAgentApi, errorDialog, getBuyerLeadApi.queryKey, queryClient, uid]);
    const updateAgent = useCallback((agentUid, params) => {
        updateAgentApi
            .mutateAsync({ uid: agentUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, updateAgentApi]);
    const removeAgent = useCallback((agentUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { agents: old.data.agents.filter((a) => a.uid !== agentUid) }) });
        });
        removeAgentApi
            .mutateAsync({ uid: agentUid })
            .then((result) => queryClient.setQueryData(getBuyerLeadApi.queryKey, result))
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, removeAgentApi]);
    const addNewClient = useCallback((clientData) => {
        addClientApi
            .mutateAsync({
            buyerLeadUid: uid,
            data: {
                contact_data: {
                    fields: clientData,
                },
            },
        })
            .then((result) => {
            listEmailsApi.refetch();
            listMessagesApi.refetch();
            listCallsApi.refetch();
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [
        addClientApi,
        errorDialog,
        getBuyerLeadApi.queryKey,
        listCallsApi,
        listEmailsApi,
        listMessagesApi,
        queryClient,
        uid,
    ]);
    const addExistingClient = useCallback((clientUid) => {
        addClientApi
            .mutateAsync({
            buyerLeadUid: uid,
            data: {
                contact_uid: clientUid,
            },
        })
            .then((result) => {
            listEmailsApi.refetch();
            listMessagesApi.refetch();
            listCallsApi.refetch();
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [
        addClientApi,
        errorDialog,
        getBuyerLeadApi.queryKey,
        listCallsApi,
        listEmailsApi,
        listMessagesApi,
        queryClient,
        uid,
    ]);
    const removeClient = useCallback((clientUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { clients: old.data.clients.filter((c) => c.uid !== clientUid) }) });
        });
        removeClientApi
            .mutateAsync({
            buyerLeadUid: uid,
            data: {
                contact_uid: clientUid,
            },
        })
            .then((result) => {
            listEmailsApi.refetch();
            listMessagesApi.refetch();
            listCallsApi.refetch();
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [
        errorDialog,
        getBuyerLeadApi.queryKey,
        listCallsApi,
        listEmailsApi,
        listMessagesApi,
        queryClient,
        removeClientApi,
        uid,
    ]);
    const reorderClients = useCallback((clientUids) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { clients: [...old.data.clients].sort((a, b) => {
                        const aIndx = clientUids.indexOf(a.client.uid);
                        const bIndx = clientUids.indexOf(b.client.uid);
                        console.log([aIndx, bIndx]);
                        if (aIndx < bIndx)
                            return -1;
                        if (aIndx > bIndx)
                            return 1;
                        return 0;
                    }) }) });
        });
        reorderClientsApi
            .mutateAsync({
            buyerLeadUid: uid,
            data: {
                client_uids: clientUids,
            },
        })
            .then(console.log)
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, reorderClientsApi, uid]);
    const addThirdParty = useCallback((params) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        addThirdPartyApi
            .mutateAsync({ buyerLeadUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addThirdPartyApi, errorDialog, getBuyerLeadApi.queryKey, queryClient, uid]);
    const updateThirdParty = useCallback((vendorUid, params) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        updateThirdPartyApi
            .mutateAsync({ uid: vendorUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, updateThirdPartyApi]);
    const removeThirdParty = useCallback((vendorUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { vendors: old.data.vendors.filter((v) => v.uid !== vendorUid) }) });
        });
        removeThirdPartyApi
            .mutateAsync({ uid: vendorUid })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, removeThirdPartyApi]);
    const startNewBuyerContract = useCallback(() => {
        var _a;
        if (((_a = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.stage) === null || _a === void 0 ? void 0 : _a.name) === "Under Contract") {
            createBuyerContract();
        }
        else {
            confirm({
                title: "It looks like this lead isn't under contract",
                description: "You should only start a new contract if the lead's stage is \"Under Contract\". Do you want to start a new contract anyway?",
            }).then((result) => {
                if (result.confirmed) {
                    createBuyerContract();
                }
            });
        }
    }, [(_18 = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.stage) === null || _18 === void 0 ? void 0 : _18.name, confirm, createBuyerContract]);
    const deleteBuyerContract = useCallback((buyerContractUid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(searchBuyerContractsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== buyerContractUid) });
                });
                deleteBuyerContractApi
                    .mutateAsync({ uid: buyerContractUid })
                    .then(() => {
                    getBuyerLeadApi.refetch();
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteBuyerContractApi, errorDialog, getBuyerLeadApi, queryClient, searchBuyerContractsApi.queryKey]);
    const deleteBuyerLead = useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteBuyerLeadApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Deleted buyer lead", { variant: "success" });
                    navigate("/buyer-leads");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteBuyerLeadApi, errorDialog, navigate, uid]);
    const addProperty = useCallback((params, onComplete) => {
        var _a;
        const properties = (_a = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.properties) !== null && _a !== void 0 ? _a : [];
        addPropertyApi
            .mutateAsync({ buyerLeadUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
            const addedProperty = result.data.properties.find((p) => !properties.map((x) => x.uid).includes(p.uid));
            onPropertyAdded(addedProperty.uid);
            if (onComplete) {
                onComplete(addedProperty);
            }
        })
            .catch(errorDialog);
    }, [addPropertyApi, buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.properties, errorDialog, getBuyerLeadApi.queryKey, onPropertyAdded, queryClient, uid]);
    const updateProperty = useCallback((propertyUid, params) => {
        updatePropertyApi
            .mutateAsync({ uid: propertyUid, data: params })
            .then((result) => queryClient.setQueryData(getBuyerLeadApi.queryKey, result))
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, updatePropertyApi]);
    const removeProperty = useCallback((propertyUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { properties: old.data.properties.filter((p) => p.uid !== propertyUid) }) });
        });
        deletePropertyApi
            .mutateAsync({ uid: propertyUid })
            .then((result) => queryClient.setQueryData(getBuyerLeadApi.queryKey, result))
            .catch(errorDialog);
    }, [deletePropertyApi, errorDialog, getBuyerLeadApi.queryKey, queryClient]);
    const startNewPSP = useCallback((propertyUid) => {
        var _a, _b;
        addPSPApi
            .mutateAsync({
            data: {
                fields: {
                    "Property Address": (_b = (_a = buyerLead.properties.find((x) => x.uid === propertyUid)) === null || _a === void 0 ? void 0 : _a.property_address) !== null && _b !== void 0 ? _b : undefined,
                },
                buyer_lead_property_uid: propertyUid,
            },
        })
            .then((result) => {
            getBuyerLeadApi.refetch();
            navigate(`/matrix/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [addPSPApi, buyerLead, getBuyerLeadApi, navigate]);
    const onMarketAnalysisClicked = useCallback((propertyUid) => {
        createPspForBuyerLeadPropertyApi
            .mutateAsync({
            propertyUid,
        })
            .then((result) => {
            navigate(`/matrix/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [createPspForBuyerLeadPropertyApi, navigate]);
    const linkPSP = useCallback((propertyUid, pspUid) => {
        linkPSPApi
            .mutateAsync({
            propertyUid,
            pspUid,
        })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(enqueueErrorSnackbar);
    }, [getBuyerLeadApi.queryKey, linkPSPApi, queryClient]);
    const unlinkPSP = useCallback((propertyUid, pspUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            const propertyIndx = old.data.properties.findIndex((property) => property.uid === propertyUid);
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { properties: [
                        ...old.data.properties.slice(0, propertyIndx),
                        Object.assign(Object.assign({}, old.data.properties[propertyIndx]), { psps: old.data.properties[propertyIndx].psps.filter((psp) => psp.uid !== pspUid) }),
                        ...old.data.properties.slice(propertyIndx + 1, old.data.properties.length),
                    ] }) });
        });
        unlinkPSPApi
            .mutateAsync({
            propertyUid,
            pspUid,
        })
            .then((result) => queryClient.setQueryData(getBuyerLeadApi.queryKey, result))
            .catch(enqueueErrorSnackbar);
    }, [getBuyerLeadApi.queryKey, queryClient, unlinkPSPApi]);
    const startNewQFO = useCallback((propertyUid) => {
        addQFOApi
            .mutateAsync({ data: { buyer_lead_property_uid: propertyUid } })
            .then((result) => {
            getBuyerLeadApi.refetch();
            navigate(`/forms/qfo/form/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [addQFOApi, errorDialog, getBuyerLeadApi, navigate]);
    const linkQFO = useCallback((propertyUid, qfoUid) => {
        linkQFOApi
            .mutateAsync({ propertyUid, qfoUid })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, linkQFOApi, queryClient]);
    const unlinkQFO = useCallback((propertyUid, qfoUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            const propertyIndx = old.data.properties.findIndex((property) => property.uid === propertyUid);
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { properties: [
                        ...old.data.properties.slice(0, propertyIndx),
                        Object.assign(Object.assign({}, old.data.properties[propertyIndx]), { qfos: old.data.properties[propertyIndx].psps.filter((psp) => psp.uid !== qfoUid) }),
                        ...old.data.properties.slice(propertyIndx + 1, old.data.properties.length),
                    ] }) });
        });
        unlinkQFOApi
            .mutateAsync({ propertyUid, qfoUid })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, unlinkQFOApi]);
    const addShowing = useCallback((propertyUid, params) => {
        addShowingApi
            .mutateAsync({ propertyUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addShowingApi, errorDialog, getBuyerLeadApi.queryKey, queryClient]);
    const updateShowing = useCallback((uid_, params) => {
        updateShowingApi
            .mutateAsync({ uid: uid_, data: params })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, updateShowingApi]);
    const removeShowing = useCallback((showingUid) => {
        queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            const propertyIndx = old.data.properties.findIndex((property) => !!property.showings.find((s) => s.uid === showingUid));
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { properties: [
                        ...old.data.properties.slice(0, propertyIndx),
                        Object.assign(Object.assign({}, old.data.properties[propertyIndx]), { showings: old.data.properties[propertyIndx].showings.filter((showing) => showing.uid !== showingUid) }),
                        ...old.data.properties.slice(propertyIndx + 1, old.data.properties.length),
                    ] }) });
        });
        removeShowingApi
            .mutateAsync({ uid: showingUid })
            .then((result) => {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getBuyerLeadApi.queryKey, queryClient, removeShowingApi]);
    /**
     * Saves a comment via API
     * @param comment
     */
    const saveComment = useCallback((comment) => {
        setCurrentlySavingComment(comment);
        const { content, reply_to } = comment;
        const commentUid = comment.uid;
        if (commentUid) {
            updateCommentApi
                .mutateAsync({
                uid: commentUid,
                data: {
                    content,
                },
            })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.map((c) => (c.uid === commentUid ? result.data : c)) });
                });
                setCurrentlySavingComment(null);
            })
                .catch((error) => {
                setCurrentlySavingComment(null);
                errorDialog(error);
            });
        }
        else {
            const data = Object.assign({ content }, pickBy({
                reply_to,
                entity_uid: uid,
            }, (val) => val !== undefined));
            addCommentApi
                .mutateAsync({
                data,
            })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [result.data, ...old.data] });
                });
                setCurrentlySavingComment(null);
            })
                .catch((error) => {
                setCurrentlySavingComment(null);
                errorDialog(error);
            });
        }
    }, [
        addCommentApi,
        errorDialog,
        listCommentsApi.queryKey,
        queryClient,
        setCurrentlySavingComment,
        uid,
        updateCommentApi,
    ]);
    /**
     * Deletes the specified comment via API
     * @param commentUid
     */
    const deleteComment = useCallback((commentUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((comment) => comment.uid !== commentUid) });
                });
                deleteCommentApi.mutateAsync({ uid: commentUid }).then(console.log).catch(console.error);
            }
        });
    }, [confirm, deleteCommentApi, listCommentsApi.queryKey, queryClient]);
    const createTask = useCallback((task) => {
        addTaskApi
            .mutateAsync({
            data: Object.assign(Object.assign({}, task), { entity_uid: uid }),
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [addTaskApi, listTasksApi, queryClient, uid]);
    const updateTask = useCallback((taskUid, task) => {
        updateTaskApi
            .mutateAsync({
            uid: taskUid,
            data: task,
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: old.data.map((x) => (x.uid === taskUid ? result.data : x)) });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [listTasksApi, queryClient, updateTaskApi]);
    const deleteTask = useCallback((taskUid) => {
        queryClient.setQueryData(listTasksApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== taskUid) });
        });
        deleteTaskApi
            .mutateAsync({ uid: taskUid })
            .then(() => {
            console.log(`deleted task ${taskUid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [deleteTaskApi, listTasksApi, queryClient]);
    const onSmsSent = useCallback(() => {
        listMessagesApi.refetch();
    }, [listMessagesApi]);
    const onFilesSelectedForUpload = useCallback((fileList) => {
        const filesToUpload = Array.from(fileList);
        setCurrentlyUploadingFiles((prev) => new Set([...prev, ...filesToUpload.map((x) => x.name)]));
        for (const file of filesToUpload) {
            console.log({ file });
            uploadFile({
                buyerLeadUid: uid,
                file,
            })
                .then((file_) => {
                console.log("DONE UPLOADING FILE", file_);
                setCurrentlyUploadingFiles((prev) => new Set([...prev].filter((fname) => fname !== file_.filename)));
                queryClient.setQueryData(listFilesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [...old.data, file_] });
                });
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [uploadFile, uid, queryClient, listFilesApi.queryKey, setCurrentlyUploadingFiles]);
    const onFileDeleted = useCallback((fileUid) => {
        queryClient.setQueryData(listFilesApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== fileUid) });
        });
        deleteFileApi.mutateAsync({ uid: fileUid }).then(console.log).catch(enqueueErrorSnackbar);
    }, [queryClient, listFilesApi.queryKey, deleteFileApi]);
    const isAddingPSP = addPSPApi.isPending || createPspForBuyerLeadPropertyApi.isPending;
    const isAddingQFO = addQFOApi.isPending;
    const isLoading = createBuyerLeadApi.isPending || deleteBuyerLeadApi.isPending;
    return {
        addAgent,
        addExistingClient,
        addNewClient,
        addProperty,
        addShowing,
        isLoading,
        addThirdParty,
        addCollaborator,
        buyerContractStageChanges,
        updateCollaborator,
        removeCollaborator,
        buyerContractFieldSections,
        buyerContractFields,
        buyerContractStages,
        onMarketAnalysisClicked,
        buyerContracts,
        buyerLead,
        buyerLeadFieldSections,
        buyerLeadFields,
        buyerLeadStages,
        calls,
        comments,
        deleteBuyerContract,
        deleteBuyerLead,
        emails,
        saveComment,
        deleteComment,
        fieldValueChanges,
        isAddingPSP,
        isAddingQFO,
        linkPSP,
        linkQFO,
        messages,
        files,
        noteChanges,
        agentChanges,
        onSmsSent,
        removeAgent,
        removeClient,
        unlinkPSP,
        removeProperty,
        unlinkQFO,
        removeShowing,
        removeThirdParty,
        reorderClients,
        setNotes,
        stageChanges,
        startNewBuyerContract,
        startNewPSP,
        currentlySavingComment,
        startNewQFO,
        updateAgent,
        updateProperty,
        updateShowing,
        updateThirdParty,
        onFieldSaveBegin,
        onFieldSaveComplete,
        onFieldSaveError,
        onFilesSelectedForUpload,
        onFileDeleted,
        currentlyUploadingFiles,
        onBuyerContractFieldSaveBegin,
        onBuyerContractFieldSaveComplete,
        onBuyerContractFieldSaveError,
        setStage,
        setBuyerContractStage,
        getBuyerLeadApi,
        streakComments,
        streakFiles,
        notifications,
        tasks,
        createTask,
        updateTask,
        deleteTask,
        setBuyerContracts,
        canEdit,
    };
};
export default useBuyerLeadApis;
