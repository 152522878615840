import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, TextField, Box, Grid2, Typography, InputAdornment, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
const NewPropertyDialog = (props) => {
    const { DialogProps, isAddingProperty, onAddProperty, onClose } = props;
    const [propertyAddress, setPropertyAddress] = React.useState("");
    const handlePropertyAddressCanged = React.useCallback((e) => {
        setPropertyAddress(e.target.value);
    }, []);
    const addProperty = React.useCallback(() => {
        onAddProperty({ address: propertyAddress });
    }, [onAddProperty, propertyAddress]);
    const isValid = React.useMemo(() => {
        return propertyAddress.trim().length > 0;
    }, [propertyAddress]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, "Add Property"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { p: 4 },
                React.createElement(Grid2, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { fullWidth: true, size: "small", value: propertyAddress, onChange: handlePropertyAddressCanged, slotProps: {
                                input: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Typography, null, "Address"))),
                                },
                            } }))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1, variant: "text", color: "inherit", disabled: !!isAddingProperty }, "Cancel"),
            React.createElement(Button, { onClick: addProperty, tabIndex: -1, variant: "contained", color: "primary", disabled: !isValid || !!isAddingProperty }, "Add Property"))));
};
export default NewPropertyDialog;
