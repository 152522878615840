/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get the current settings
 */
export const listMailschedule4Settings = (options) => {
    return axios.get(`/api/mailschedule4/settings`, options);
};
export const getListMailschedule4SettingsQueryKey = () => {
    return [`/api/mailschedule4/settings`];
};
export const getListMailschedule4SettingsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailschedule4SettingsQueryKey();
    const queryFn = ({ signal }) => listMailschedule4Settings(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the current settings
 */
export function useListMailschedule4Settings(options) {
    const queryOptions = getListMailschedule4SettingsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update the current settings
 */
export const saveMailschedule4Settings = (mailSchedule4SettingsRequest, options) => {
    return axios.post(`/api/mailschedule4/settings`, mailSchedule4SettingsRequest, options);
};
export const getSaveMailschedule4SettingsMutationOptions = (options) => {
    const mutationKey = ['saveMailschedule4Settings'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return saveMailschedule4Settings(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update the current settings
*/
export const useSaveMailschedule4Settings = (options) => {
    const mutationOptions = getSaveMailschedule4SettingsMutationOptions(options);
    return useMutation(mutationOptions);
};
