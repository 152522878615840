import * as React from "react";
import { Box, Button, Grid, Link, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Typography, } from "@mui/material";
import axios from "axios";
import { parsePhoneNumber } from "libphonenumber-js";
import twilioIcon from "@app/assets/img/twilio.png";
import { withDialog } from "@app/hoc/withDialog";
import API from "@app/util/api";
import AppManager from "@app/util/AppManager";
class OnHoldMusic extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                twilioPhones: null,
                recordings: null,
                users: null,
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.twilioPhoneNumbers.list({
                    onSuccess: (result) => {
                        this.setState({
                            twilioPhones: result,
                        });
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.twilio.listOnHoldMusic({
                    onSuccess: (result) => {
                        this.setState({
                            recordings: result,
                        });
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.users.list({
                    onSuccess: (result) => {
                        this.setState({
                            users: result,
                        });
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "handleSetRecordingClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (twilioPhone) => () => {
                document.getElementById(`twilio-on-hold-music-recording-input-${twilioPhone}`).click();
            }
        });
        Object.defineProperty(this, "handleClearRecordingClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (twilioPhone) => () => {
                this.setState((prevState) => ({
                    recordings: [
                        ...prevState.recordings.filter((recording) => recording.phone_number !== twilioPhone),
                        {
                            phone_number: twilioPhone,
                            filename: null,
                            url: null,
                        },
                    ],
                }));
                API.twilio.setOnHoldMusic({
                    data: {
                        phone_number: twilioPhone,
                        recording: null,
                    },
                    onSuccess: (result) => {
                        console.log(result);
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                    },
                });
            }
        });
        Object.defineProperty(this, "handleRecordingChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (twilioPhone) => (e) => {
                const file = e.target.files ? e.target.files[0] : null;
                if (file) {
                    const formData = new FormData();
                    formData.append("recording", file);
                    formData.append("phone_number", twilioPhone);
                    console.log(formData);
                    axios
                        .post("/api/twilio/on_hold_music", formData)
                        .then((result) => {
                        this.setState((prevState) => ({
                            recordings: [
                                ...prevState.recordings.filter((recording) => recording.phone_number !== twilioPhone),
                                result.data,
                            ],
                        }));
                    })
                        .catch((error) => {
                        this.props.showErrorDialog(error);
                    });
                    // clear file input so that re-selecting the same file will still work
                    e.target.value = "";
                }
            }
        });
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "Twilio", subtitle: "On Hold Music" };
        this.refresh();
    }
    componentWillUnmount() {
        var _a;
        AppManager.shared.pageTitle = {};
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    render() {
        const { recordings, twilioPhones, users } = this.state;
        const loading = [];
        if (!twilioPhones) {
            loading.push("Getting Twilio phones...");
        }
        if (!recordings) {
            loading.push("Getting current on-hold music...");
        }
        if (!users) {
            loading.push("Getting users...");
        }
        if (loading.length > 0) {
            return (React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2 }, loading.map((item) => (React.createElement(Grid, { key: item, item: true, xs: 12 },
                    React.createElement(Typography, null, item)))))));
        }
        return (React.createElement(List, { dense: true }, twilioPhones
            .filter((twilioPhone) => {
            const agent = users.find((user) => user.fields["Twilio #"] &&
                parsePhoneNumber(user.fields["Twilio #"], { defaultCountry: "US" }).number ===
                    parsePhoneNumber(twilioPhone.phone_number, { defaultCountry: "US" }).number);
            return !agent;
        })
            .map((twilioPhone) => {
            const recording = (recordings !== null && recordings !== void 0 ? recordings : []).find((r) => r.phone_number === twilioPhone.phone_number);
            return (React.createElement(ListItem, { key: twilioPhone.phone_number },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { src: twilioIcon, alt: "Twilio", width: 40, height: 40, style: {
                            borderRadius: "50%",
                        } })),
                React.createElement(ListItemText, { primary: twilioPhone.friendly_name, secondary: twilioPhone.phone_number }),
                React.createElement(ListItemSecondaryAction, null,
                    React.createElement(Grid, { container: true, spacing: 1 },
                        (recording === null || recording === void 0 ? void 0 : recording.url) && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Link, { href: recording.url, target: "_blank" }, recording.filename))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: this.handleSetRecordingClicked(twilioPhone.phone_number) }, "Set Recording")),
                        React.createElement(Grid, { item: true }, (recording === null || recording === void 0 ? void 0 : recording.url) && (React.createElement(Button, { color: "secondary", size: "small", onClick: this.handleClearRecordingClicked(twilioPhone.phone_number) }, "Clear Recording")))),
                    React.createElement("input", { accept: "audio/mpeg", id: `twilio-on-hold-music-recording-input-${twilioPhone.phone_number}`, onChange: this.handleRecordingChanged(twilioPhone.phone_number), style: { display: "none" }, type: "file" }))));
        })));
    }
}
export default withDialog(OnHoldMusic);
