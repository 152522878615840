import * as React from "react";
import RequestDebuggingContextProvider from "./RequestDebuggingContext";
import RequestDebuggingInner from "./RequestDebuggingInner";
const RequestDebugging = (props) => {
    return (React.createElement(RequestDebuggingContextProvider, { initialValues: {
            fromDatetime: props.fromDatetime,
            toDatetime: props.toDatetime,
            userEmail: props.userEmail,
            search: props.search,
            limit: props.limit,
            offset: props.offset,
        } },
        React.createElement(RequestDebuggingInner, null)));
};
export default RequestDebugging;
