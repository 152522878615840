/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Get the MLS Grid API response for a property address

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a property
 */
export const searchMlsGrid = (params, options) => {
    return axios.get(`/api/utilities/mls_grid`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getSearchMlsGridQueryKey = (params) => {
    return [`/api/utilities/mls_grid`, ...(params ? [params] : [])];
};
export const getSearchMlsGridQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchMlsGridQueryKey(params);
    const queryFn = ({ signal }) => searchMlsGrid(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get a property
 */
export function useSearchMlsGrid(params, options) {
    const queryOptions = getSearchMlsGridQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * MLS Grid doesn't like us using their S3 files; they want us to download the files and use our own copy.This endpoint downloads the requested file if it doesn't already exist.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Download media
 */
export const getMlsGridMedia = (params, options) => {
    return axios.get(`/api/utilities/mls_grid/download_media`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetMlsGridMediaQueryKey = (params) => {
    return [`/api/utilities/mls_grid/download_media`, ...(params ? [params] : [])];
};
export const getGetMlsGridMediaQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMlsGridMediaQueryKey(params);
    const queryFn = ({ signal }) => getMlsGridMedia(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download media
 */
export function useGetMlsGridMedia(params, options) {
    const queryOptions = getGetMlsGridMediaQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List MLS Grid Listings for an Agent by Agent MLS Id

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List MLS Grid Listings for an Agent by Agent MLS Id
 */
export const listMlsGridListingsForAgent = (agentMlsId, options) => {
    return axios.get(`/api/utilities/mls_grid/list_for_agent/${agentMlsId}`, options);
};
export const getListMlsGridListingsForAgentQueryKey = (agentMlsId) => {
    return [`/api/utilities/mls_grid/list_for_agent/${agentMlsId}`];
};
export const getListMlsGridListingsForAgentQueryOptions = (agentMlsId, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMlsGridListingsForAgentQueryKey(agentMlsId);
    const queryFn = ({ signal }) => listMlsGridListingsForAgent(agentMlsId, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(agentMlsId) }, queryOptions);
};
/**
 * @summary List MLS Grid Listings for an Agent by Agent MLS Id
 */
export function useListMlsGridListingsForAgent(agentMlsId, options) {
    const queryOptions = getListMlsGridListingsForAgentQueryOptions(agentMlsId, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Returns data for MLS Grid properties as stored in our database, so keep in mind, this may be slightly out of date depending on when the script that syncs the data last ran.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List properties
 */
export const listMlsGridListings = (params, options) => {
    return axios.get(`/api/utilities/mls_grid/search`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListMlsGridListingsQueryKey = (params) => {
    return [`/api/utilities/mls_grid/search`, ...(params ? [params] : [])];
};
export const getListMlsGridListingsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMlsGridListingsQueryKey(params);
    const queryFn = ({ signal }) => listMlsGridListings(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List properties
 */
export function useListMlsGridListings(params, options) {
    const queryOptions = getListMlsGridListingsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
