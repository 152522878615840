import * as React from "react";
import { Error } from "@mui/icons-material";
import { Badge, Grid2, IconButton, List, ListItemButton, ListItemText, ListSubheader, Typography } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import RouterLink from "@app/common/RouterLink";
import useErrors from "./useErrors";
const StyledSubheader = (props) => {
    const { description, title } = props;
    return (React.createElement(ListSubheader, { sx: { backgroundColor: "white", lineHeight: "1rem" } },
        React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, title)),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "caption" }, description)))));
};
const Errors = () => {
    var _a;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, []);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);
    const { mailScheduleTemplateErrors, usersWithNonexistantTwilioPhone, viewsWithErrors } = useErrors();
    const numErrors = mailScheduleTemplateErrors.length + ((_a = usersWithNonexistantTwilioPhone === null || usersWithNonexistantTwilioPhone === void 0 ? void 0 : usersWithNonexistantTwilioPhone.length) !== null && _a !== void 0 ? _a : 0) + viewsWithErrors.length;
    if (numErrors === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Badge, { badgeContent: numErrors, color: "error", anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            } },
            React.createElement(IconButton, { onClick: openPopover },
                React.createElement(Error, { style: { color: "white" } }))),
        React.createElement(CorePopover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, slotProps: {
                paper: {
                    style: {
                        maxWidth: "300px",
                        minWidth: "200px",
                    },
                },
            } },
            React.createElement(List, { dense: true },
                mailScheduleTemplateErrors.length > 0 && (React.createElement(StyledSubheader, { title: `${mailScheduleTemplateErrors.length} issues with Mail Schedule templates`, description: "Issues have been found with the following Mail Schedule templates. They may be broken or not working as\n              expected. You might want to fix or delete these templates." })),
                mailScheduleTemplateErrors.map((template) => (React.createElement(ListItemButton, { key: template.uid, component: RouterLink, to: `/ms4/templates/${template.uid}` },
                    React.createElement(ListItemText, { primary: template.name, secondary: template.error })))),
                viewsWithErrors.length > 0 && (React.createElement(StyledSubheader, { title: `${viewsWithErrors.length} issues with saved views`, description: "Issues have been found with the following saved views. They may be broken or not working as expected. You" })),
                viewsWithErrors.map((view) => {
                    var _a, _b;
                    return (React.createElement(ListItemButton, { key: view.uid, component: RouterLink, to: `/views/${view.uid}` },
                        React.createElement(ListItemText, { primary: view.name, secondary: (_b = (_a = view.filter_groups.find((fg) => fg.filters.some((f) => f.error))) === null || _a === void 0 ? void 0 : _a.filters.find((f) => f.error)) === null || _b === void 0 ? void 0 : _b.error })));
                }),
                usersWithNonexistantTwilioPhone && usersWithNonexistantTwilioPhone.length > 0 && (React.createElement(StyledSubheader, { title: `${usersWithNonexistantTwilioPhone.length} users with nonexistant Twilio phone numbers`, description: "The following users may have a Twilio phone number that no longer exists. Check the Twilio console to verify." })), usersWithNonexistantTwilioPhone === null || usersWithNonexistantTwilioPhone === void 0 ? void 0 :
                usersWithNonexistantTwilioPhone.map((user) => (React.createElement(ListItemButton, { key: user.uid, component: RouterLink, to: `/users/${user.uid}` },
                    React.createElement(ListItemText, { primary: user.name, secondary: `Twilio phone number ${user.fields["Twilio #"]} may not exist` }))))))));
};
export default Errors;
