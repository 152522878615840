import * as React from "react";
import PropTypes from "prop-types";
import BathtubIcon from "@mui/icons-material/Bathtub";
import BedIcon from "@mui/icons-material/Bed";
import EmailIcon from "@mui/icons-material/Email";
import GarageIcon from "@mui/icons-material/Garage";
import PhoneIcon from "@mui/icons-material/Phone";
import SellIcon from "@mui/icons-material/Sell";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { Box, Card, CardContent, Chip, Divider, Grid, Typography, Paper, Avatar, IconButton, Tooltip, } from "@mui/material";
import numeral from "numeral";
import RouterLink from "@app/common/RouterLink";
import useLoading from "@app/hooks/useLoading";
import { useListOfficeExclusiveSellerLeads } from "@app/orval/api/seller-leads";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import StreetViewImage from "../buyer-leads/components/StreetViewImage";
const PropertyStat = ({ icon, label, value }) => (React.createElement(Box, { display: "flex", alignItems: "center", gap: 1, width: "auto", mr: 2, mb: 1 },
    icon,
    React.createElement(Typography, { variant: "body2", fontWeight: "medium", color: "text.secondary" },
        label,
        ":"),
    React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, value)));
PropertyStat.propTypes = {
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
const OfficeExclusive = () => {
    var _a, _b, _c;
    const searchSellerLeadsApi = useListOfficeExclusiveSellerLeads(REFETCH_CACHE_PARAMS);
    const sellerLeads = (_b = (_a = searchSellerLeadsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading seller leads",
                queryResult: searchSellerLeadsApi,
            },
        ],
    });
    // Handler function for stopping propagation
    const handleButtonClick = React.useCallback((e) => {
        e.stopPropagation();
    }, []);
    // Handler for image error
    const handleImageError = React.useCallback((e) => {
        // Handle error by applying a placeholder style to the parent box
        const errorBox = e.currentTarget.parentElement;
        if (errorBox) {
            errorBox.innerHTML = `<div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
        <span style="color: #666;">Property image not available</span>
      </div>`;
        }
    }, []);
    if (isLoading) {
        return loadingComponent;
    }
    return (React.createElement(Box, { sx: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f5f7fa",
            overflow: "hidden",
        } },
        React.createElement(Paper, { elevation: 0, sx: { p: 3, mb: 2, borderRadius: 0 } },
            React.createElement(Typography, { variant: "h5", fontWeight: "bold", color: "primary" }, "Office Exclusive Listings"),
            React.createElement(Typography, { variant: "body2", color: "text.secondary" }, (_c = sellerLeads === null || sellerLeads === void 0 ? void 0 : sellerLeads.length) !== null && _c !== void 0 ? _c : 0,
                " exclusive properties available")),
        sellerLeads && sellerLeads.length > 0 ? (React.createElement(Box, { sx: { overflow: "auto", px: 2, pb: 2, flexGrow: 1, maxHeight: "calc(100vh - 120px)" } },
            React.createElement(Grid, { container: true, spacing: 2 }, sellerLeads.map((sellerLead) => {
                var _a, _b, _c;
                const address = [
                    sellerLead.fields["Street #"],
                    sellerLead.fields["Street Name"],
                    sellerLead.fields["City"],
                    sellerLead.fields["State"],
                    sellerLead.fields["Zip"],
                ]
                    .filter((o) => o)
                    .join(" ");
                // Get producer information
                const producerAgent = (_a = sellerLead.agents.find((agent) => agent.type === "Producer")) === null || _a === void 0 ? void 0 : _a.agent;
                const hasProducer = producerAgent !== undefined;
                const producerName = hasProducer
                    ? `${(_b = producerAgent.first_name) !== null && _b !== void 0 ? _b : ""} ${(_c = producerAgent.last_name) !== null && _c !== void 0 ? _c : ""}`
                    : "";
                const producerEmail = hasProducer ? producerAgent.email : "";
                const producerPhone = hasProducer ? producerAgent.phone : "";
                return (React.createElement(Grid, { key: sellerLead.uid, item: true, xs: 12, md: 6, lg: 4 },
                    React.createElement(Card, { component: RouterLink, to: `/sellers/${sellerLead.uid}`, sx: {
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            transition: "transform 0.2s, box-shadow 0.2s",
                            "&:hover": {
                                transform: "translateY(-4px)",
                                boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
                            },
                            textDecoration: "none",
                        } },
                        React.createElement(Box, { sx: { position: "relative", width: "100%" } },
                            React.createElement(Box, { sx: {
                                    height: 200,
                                    width: "100%",
                                    backgroundColor: "#e0e0e0",
                                    position: "relative",
                                    overflow: "hidden",
                                } },
                                React.createElement(StreetViewImage, { address: address, ImageProps: {
                                        style: {
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "cover",
                                        },
                                        onError: handleImageError,
                                    } })),
                            sellerLead.fields["Property Type"] && (React.createElement(Chip, { size: "small", label: sellerLead.fields["Property Type"], sx: {
                                    position: "absolute",
                                    top: 12,
                                    left: 12,
                                    backgroundColor: "rgba(255,255,255,0.9)",
                                    fontWeight: "bold",
                                } })),
                            sellerLead.fields["List Price"] && (React.createElement(Chip, { size: "medium", label: numeral(sellerLead.fields["List Price"]).format("$0,0"), sx: {
                                    position: "absolute",
                                    top: 12,
                                    right: 12,
                                    backgroundColor: "primary.main",
                                    color: "white",
                                    fontWeight: "bold",
                                } }))),
                        React.createElement(CardContent, { sx: { flexGrow: 1, pt: 2 } },
                            React.createElement(Typography, { variant: "h6", gutterBottom: true, fontWeight: "bold", sx: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }, address),
                            React.createElement(Box, { sx: { display: "flex", flexWrap: "wrap", mb: 2 } },
                                sellerLead.fields["Beds"] != null && (React.createElement(PropertyStat, { icon: React.createElement(BedIcon, { fontSize: "small", color: "primary" }), label: "Beds", value: numeral(sellerLead.fields["Beds"]).format("0,0") })),
                                sellerLead.fields["Baths"] != null && (React.createElement(PropertyStat, { icon: React.createElement(BathtubIcon, { fontSize: "small", color: "primary" }), label: "Baths", value: numeral(sellerLead.fields["Baths"]).format("0,0.0") })),
                                sellerLead.fields["SqFt"] != null && (React.createElement(PropertyStat, { icon: React.createElement(SquareFootIcon, { fontSize: "small", color: "primary" }), label: "SqFt", value: numeral(sellerLead.fields["SqFt"]).format("0,0") })),
                                sellerLead.fields["Garage"] != null && (React.createElement(PropertyStat, { icon: React.createElement(GarageIcon, { fontSize: "small", color: "primary" }), label: "Garage", value: sellerLead.fields["Garage"] })),
                                sellerLead.fields["Buy Side Commission %"] != null && (React.createElement(PropertyStat, { icon: React.createElement(SellIcon, { fontSize: "small", color: "primary" }), label: "Commission", value: `${sellerLead.fields["Buy Side Commission %"] * 100}%` }))),
                            hasProducer && (React.createElement(Box, null,
                                React.createElement(Divider, { sx: { mb: 2 } }),
                                React.createElement(Box, { sx: { display: "flex", alignItems: "center" } },
                                    React.createElement(Avatar, { sx: { mr: 2, bgcolor: "secondary.main" } }, producerName
                                        .split(" ")
                                        .map((n) => n[0])
                                        .join("")),
                                    React.createElement(Box, { sx: { flexGrow: 1 } },
                                        React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, producerName),
                                        React.createElement(Box, { sx: { display: "flex", mt: 0.5 } },
                                            React.createElement(Tooltip, { title: `Email: ${producerEmail}` },
                                                React.createElement(IconButton, { size: "small", href: `mailto:${producerEmail}`, onClick: handleButtonClick, sx: { mr: 1 } },
                                                    React.createElement(EmailIcon, { fontSize: "small" }))),
                                            React.createElement(Tooltip, { title: `Call: ${producerPhone}` },
                                                React.createElement(IconButton, { size: "small", href: `tel:${producerPhone}`, onClick: handleButtonClick },
                                                    React.createElement(PhoneIcon, { fontSize: "small" }))))))))))));
            })))) : (React.createElement(Box, { sx: { display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 } },
            React.createElement(Typography, { variant: "body1", color: "text.secondary" }, "No office exclusive listings available at this time")))));
};
export default OfficeExclusive;
