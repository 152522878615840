/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get state options
 */
export const listZhlEngagedTransferStates = (options) => {
    return axios.get(`/api/zhl_engaged_transfer_states`, options);
};
export const getListZhlEngagedTransferStatesQueryKey = () => {
    return [`/api/zhl_engaged_transfer_states`];
};
export const getListZhlEngagedTransferStatesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListZhlEngagedTransferStatesQueryKey();
    const queryFn = ({ signal }) => listZhlEngagedTransferStates(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get state options
 */
export function useListZhlEngagedTransferStates(options) {
    const queryOptions = getListZhlEngagedTransferStatesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Updates the Lender in Follow Up Boss, which notifies the ZHL. We log the submission in our database for reporting/tracking purposes.

_Authorization: none required_
 * @summary Send ZHL Engaged Transfer
 */
export const createZhlEngagedTransferSubmission = (sendZHLEngagedTransferRequest, options) => {
    return axios.post(`/api/zhl_engaged_transfer_submissions`, sendZHLEngagedTransferRequest, options);
};
export const getCreateZhlEngagedTransferSubmissionMutationOptions = (options) => {
    const mutationKey = ['createZhlEngagedTransferSubmission'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createZhlEngagedTransferSubmission(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Send ZHL Engaged Transfer
*/
export const useCreateZhlEngagedTransferSubmission = (options) => {
    const mutationOptions = getCreateZhlEngagedTransferSubmissionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a ZHL Engaged Transfer submissions
*/
export const getZhlEngagedTransferSubmission = (uid, options) => {
    return axios.get(`/api/zhl_engaged_transfer_submissions/${uid}`, options);
};
export const getGetZhlEngagedTransferSubmissionQueryKey = (uid) => {
    return [`/api/zhl_engaged_transfer_submissions/${uid}`];
};
export const getGetZhlEngagedTransferSubmissionQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetZhlEngagedTransferSubmissionQueryKey(uid);
    const queryFn = ({ signal }) => getZhlEngagedTransferSubmission(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a ZHL Engaged Transfer submissions
 */
export function useGetZhlEngagedTransferSubmission(uid, options) {
    const queryOptions = getGetZhlEngagedTransferSubmissionQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Count ZHL Engaged Transfer submissions
 */
export const countZhlEngagedTransferSubmissions = (options) => {
    return axios.post(`/api/zhl_engaged_transfer_submissions/count`, undefined, options);
};
export const getCountZhlEngagedTransferSubmissionsMutationOptions = (options) => {
    const mutationKey = ['countZhlEngagedTransferSubmissions'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return countZhlEngagedTransferSubmissions(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count ZHL Engaged Transfer submissions
*/
export const useCountZhlEngagedTransferSubmissions = (options) => {
    const mutationOptions = getCountZhlEngagedTransferSubmissionsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Search ZHL Engaged Transfer submissions
*/
export const searchZhlEngagedTransferSubmissions = (paginatedStrictRequest, options) => {
    return axios.post(`/api/zhl_engaged_transferr_submissions/search`, paginatedStrictRequest, options);
};
export const getSearchZhlEngagedTransferSubmissionsMutationOptions = (options) => {
    const mutationKey = ['searchZhlEngagedTransferSubmissions'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchZhlEngagedTransferSubmissions(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search ZHL Engaged Transfer submissions
*/
export const useSearchZhlEngagedTransferSubmissions = (options) => {
    const mutationOptions = getSearchZhlEngagedTransferSubmissionsMutationOptions(options);
    return useMutation(mutationOptions);
};
