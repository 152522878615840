import * as React from "react";
import { Code, SpeakerNotes } from "@mui/icons-material";
import { Checkbox, Button, Grid2, IconButton, Typography, Tooltip, Rating, ThemeProvider } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import { Icon as TacoIcon } from "@iconify/react";
import numeral from "numeral";
import CoreLink from "@app/common/CoreLink";
import CoreDataGrid from "@app/common/grid/DataGrid";
import AppConfig from "@app/util/AppConfig";
import { formatDateMemoized_Dayjs } from "@app/util/Utils";
import MLSPhoto from "./MLSPhoto";
import { getAddress, getLivingArea, getAcreage, getUrl, getCompType, getDaysOnMarket, getPricePerSquareFoot, getRowClassName, compsPullerGridTheme, } from "./utils";
const CompsPullerTable = (props) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(null);
    const { isAddingComps, onSelectionConfirmed, openPhotos, properties, refinedComps, showDetails, toggleRefinedComps, toggleSelected, } = props;
    const onCompSelect = React.useCallback((mlsnumber) => (_) => {
        toggleSelected(mlsnumber);
    }, [toggleSelected]);
    const onSelectionConfirmed_ = React.useCallback(() => {
        const selectedProperties = properties.filter((property) => property.selected);
        if (onSelectionConfirmed) {
            onSelectionConfirmed(selectedProperties);
        }
    }, [onSelectionConfirmed, properties]);
    const onRefinedCompsToggle = React.useCallback(() => {
        toggleRefinedComps();
    }, [toggleRefinedComps]);
    const onDetailsOpen = React.useCallback((property) => (_) => {
        showDetails(property);
    }, [showDetails]);
    const onPhotosOpen = React.useCallback((address, photos) => (_) => {
        openPhotos(address, photos.map((photo) => ({ src: photo.url })));
    }, [openPhotos]);
    const rows = React.useMemo(() => {
        return properties
            .map((property) => {
            var _a, _b;
            return (Object.assign(Object.assign(Object.assign({ id: property.mlsnumber }, property), property.data), { PricePerSqFt: getPricePerSquareFoot(property), status: (_a = property.data["MlsStatus"]) !== null && _a !== void 0 ? _a : property.data["StandardStatus"], County: (_b = property.data["County"]) !== null && _b !== void 0 ? _b : property.data["CountyOrParish"], address: getAddress(property.data), sqft: getLivingArea(property.data, property.source), acreage: getAcreage(property.data), dom: getDaysOnMarket(property.data, property.source) }));
        })
            .filter((property) => !refinedComps || refinedComps.includes(property.mlsnumber));
    }, [properties, refinedComps]);
    const tableTitle = React.useMemo(() => {
        return `Found ${rows.length} properties`;
    }, [rows.length]);
    const columns = React.useMemo(() => {
        const columns_ = [
            {
                field: "selected",
                headerName: " ",
                sortable: false,
                width: 55,
                renderCell: (params) => {
                    const { row, value } = params;
                    const mlsnumber = row["ListingId"];
                    if (getCompType(row["StandardStatus"], row["MlsStatus"])) {
                        return React.createElement(Checkbox, { checked: value, color: "primary", onChange: onCompSelect(mlsnumber) });
                    }
                    return null;
                },
            },
            {
                field: "ListingId",
                headerName: " ",
                sortable: false,
                width: 85,
                renderCell: (params) => {
                    const { row, value } = params;
                    if (value) {
                        const { source } = row;
                        const url = getUrl(source, value);
                        return (React.createElement(React.Fragment, null,
                            url && (React.createElement(Tooltip, { title: "View JSON" },
                                React.createElement(IconButton, { size: "small", component: CoreLink, href: url, target: "_blank" },
                                    React.createElement(Code, { fontSize: "small" })))),
                            React.createElement(Tooltip, { title: "Show details" },
                                React.createElement(IconButton, { onClick: onDetailsOpen(row) },
                                    React.createElement(SpeakerNotes, null)))));
                    }
                    return value;
                },
            },
            rows.find((row) => row["Media"])
                ? {
                    field: "Media",
                    headerName: " ",
                    sortable: false,
                    width: 145,
                    renderCell: (params) => {
                        var _a, _b;
                        const { row, value } = params;
                        const photos = (value !== null && value !== void 0 ? value : []).map((photo) => ({
                            id: photo["MediaObjectID"],
                            url: photo["MediaURL"],
                            key: photo["MediaKey"],
                            description: photo["ShortDescription"],
                        }));
                        return (photos.length > 0 && (React.createElement(Grid2, { container: true, spacing: 1, style: { height: "100%" } },
                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto", height: "100%" } },
                                React.createElement(MLSPhoto, { alt: (_a = photos[0].description) !== null && _a !== void 0 ? _a : "", src: photos[0].url, mediaKey: (_b = photos[0].key) !== null && _b !== void 0 ? _b : undefined, width: "30px", height: "30px", style: {
                                        padding: "4px",
                                        borderRadius: "1em",
                                        cursor: "pointer",
                                    }, onClick: onPhotosOpen(row["UnparsedAddress"], photos) })),
                            React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Button, { variant: "contained", size: "small", color: "primary", onClick: onPhotosOpen(row["UnparsedAddress"], photos), style: { padding: "4px" } }, "View Photos")))));
                    },
                }
                : null,
            rows.find((row) => row["quality"] != null)
                ? {
                    field: "quality",
                    headerName: "Pass Rate",
                    width: 115,
                    renderHeader: (_) => {
                        // TODO: This should be pulled from CompsPullerRuleSets
                        const passRules = [
                            {
                                name: " 1st Pass - min 6 closed comps",
                                criteria: [
                                    "1/2 Mile from Subject",
                                    "Closed date 90 days",
                                    "Sqft Range 10%",
                                    "Full Baths 1+/-",
                                    "Garages 1+/-",
                                    "Year build 5+/-",
                                ],
                            },
                            {
                                name: "2nd Pass - min 7 closed comps",
                                criteria: ["Closed date 6 months", "Sqft 15%", "Year built 10+/-"],
                            },
                            {
                                name: "3rd Pass - min 8 comps",
                                criteria: ["1-mile radius"],
                            },
                            {
                                name: "4th Pass - min 10 comps",
                                criteria: ["Sqft 20%"],
                            },
                            {
                                name: "5th Pass - min 15 comps",
                                criteria: ["12 months back"],
                            },
                            {
                                name: "6th Pass - min 20 comps",
                                criteria: ["Full Baths 2+/-", "Garages 2+/-"],
                            },
                        ];
                        const title = (React.createElement(Grid2, { container: true, spacing: 2 }, passRules.map((pass) => (React.createElement(Grid2, { key: pass.name, container: true, size: 12 },
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { style: { fontWeight: "bold" } }, pass.name)),
                            pass.criteria.map((item) => (React.createElement(Grid2, { key: `${pass.name}_${item}`, size: 12 },
                                React.createElement(Typography, { variant: "body2" },
                                    React.createElement("li", null, item))))))))));
                        return (React.createElement(Tooltip, { title: title },
                            React.createElement(Typography, { style: {
                                    fontWeight: "bold",
                                    fontSize: "0.6875rem",
                                    color: "rgba(0, 0, 0, 0.87",
                                    lineHeight: "unset",
                                } }, "Pass Rate")));
                    },
                    renderCell: (params) => {
                        const { value } = params;
                        if (value != null) {
                            return (React.createElement(Rating, { value: value, precision: 0.5, readOnly: true, max: 5, icon: React.createElement(TacoIcon, { icon: "mdi:taco", color: AppConfig.themeColors.warning }), emptyIcon: React.createElement(TacoIcon, { icon: "mdi:taco" }) }));
                        }
                        return null;
                    },
                }
                : null,
            {
                field: "CloseDate",
                headerName: "Sold Date",
                valueFormatter: (value) => {
                    return value ? formatDateMemoized_Dayjs(`${value}`, "M/D/YYYY") : "-";
                },
            },
            {
                field: "address",
                headerName: "Address",
                width: 270,
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "SubdivisionName",
                headerName: "Subdivision",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "County",
                headerName: "County",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "status",
                headerName: "Status",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "PropertySubType",
                headerName: "Property Type",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "BedroomsTotal",
                headerName: "Beds",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "BathroomsFull",
                headerName: "Baths",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "sqft",
                headerName: "SqFt",
                valueFormatter: (value) => {
                    return value != null ? numeral(value).format("0,0") : "-";
                },
            },
            {
                field: "acreage",
                headerName: "Acreage",
                valueFormatter: (value) => {
                    return value != null ? numeral(value).format("0.00") : "-";
                },
            },
            {
                field: "ClosePrice",
                headerName: "Sold Price",
                valueFormatter: (value) => {
                    return value != null ? numeral(value).format("$0,0") : "-";
                },
            },
            {
                field: "ListPrice",
                headerName: "Orig. List Price",
                valueFormatter: (value) => {
                    return value != null ? numeral(value).format("$0,0") : "-";
                },
            },
            {
                field: "PricePerSqFt",
                headerName: "$ per sqft",
                valueFormatter: (value) => {
                    return value != null ? numeral(value).format("$0,0.00") : "-";
                },
            },
            {
                field: "GarageSpaces",
                headerName: "Garage Spaces",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "ConcessionsAmount",
                headerName: "Concessions",
                valueFormatter: (value) => {
                    return value != null ? numeral(value).format("$0,0") : "-";
                },
            },
            {
                field: "YearBuilt",
                headerName: "Year Built",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
            {
                field: "dom",
                headerName: "DOM",
                valueFormatter: (value) => {
                    return value !== null && value !== void 0 ? value : "-";
                },
            },
        ];
        return columns_.filter((obj) => obj != null);
    }, [onCompSelect, onDetailsOpen, onPhotosOpen, rows]);
    const onPaginationModelChange = React.useCallback((model) => {
        setPage(model.page);
        setRowsPerPage(model.pageSize);
    }, []);
    const customToolbar = React.useCallback((toolbarProps) => {
        return (React.createElement(GridToolbarContainer, Object.assign({}, toolbarProps),
            React.createElement(Grid2, { container: true, spacing: 2, style: { marginTop: "16px", marginBottom: "16px", width: "100%" } },
                React.createElement(Grid2, { style: { flexGrow: 1 } },
                    React.createElement(Typography, { variant: "h6", fontSize: 16 }, tableTitle)),
                onSelectionConfirmed && rows.filter((property) => property.selected).length > 0 && (React.createElement(Grid2, { style: { marginRight: "8px" } },
                    React.createElement(Button, { color: "primary", disabled: isAddingComps, onClick: onRefinedCompsToggle }, refinedComps ? "Show All" : "Refine"),
                    React.createElement(Button, { color: "primary", variant: "outlined", disabled: isAddingComps, onClick: onSelectionConfirmed_, style: {
                            marginLeft: "5px",
                        } }, "Add to comps"))))));
    }, [isAddingComps, onRefinedCompsToggle, onSelectionConfirmed, onSelectionConfirmed_, refinedComps, rows, tableTitle]);
    /** if clicking on a cell that's in the 4th column or after, select the row */
    const onCellClick = React.useCallback((params) => {
        console.log("TEST:", params);
        const cellColIndex = columns.findIndex((col) => {
            return col.field === params.field;
        });
        if (cellColIndex !== -1 && cellColIndex >= 4) {
            const mlsnumber = params.row["ListingId"];
            if (getCompType(params.row["StandardStatus"], params.row["MlsStatus"])) {
                toggleSelected(mlsnumber);
            }
        }
    }, [columns, toggleSelected]);
    return (React.createElement(ThemeProvider, { theme: compsPullerGridTheme },
        React.createElement(CoreDataGrid, { rows: rows, columns: columns, disableRowSelectionOnClick: true, disableColumnReorder: true, density: "compact", filterMode: "client", rowCount: rows.length, getRowClassName: getRowClassName, onCellClick: onCellClick, pagination: true, paginationMode: "client", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], paginationModel: { page: page !== null && page !== void 0 ? page : 0, pageSize: rowsPerPage }, onPaginationModelChange: onPaginationModelChange, 
            // sort="client"
            // onSortModelChange={this.onSortModelChange}
            // onRowSelectionModelChange={this.onRowSelectionModelChange}
            localeText: {
                toolbarColumns: "",
            }, slots: {
                toolbar: customToolbar,
            } })));
};
export default CompsPullerTable;
