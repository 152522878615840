import * as React from "react";
import { AcUnit, Article, Code, Comment, CopyAll, Mail, Send } from "@mui/icons-material";
import { Divider, Link, ListItemIcon, MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import streakIcon from "@app/assets/img/streak.png";
import CorePopover from "@app/common/CorePopover";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useSession from "@app/hooks/useSession";
import { useDuplicateBuyerLead, useDuplicateBuyerLeadAsSellerLead } from "@app/orval/api/buyer-leads";
import { copyToClipboard, getFullName, getStreakLinkForBox } from "@app/util/Utils";
import BuyerLeadActionsMenuFormsSection from "./BuyerLeadActionsMenuFormsSection";
import SendZHLETRDialog from "./SendZHLEngagedTransferDialog";
import CommentPopover from "../../../dashboards/agent-dashboard/components/pinned-boxes/CommentPopover";
import ActionsMenu from "../../components/box-view/ActionsMenu";
import EmailMenuSection from "../../components/box-view/ActionsMenu/EmailMenuSection";
import FollowUpBossMenuSection from "../../components/box-view/ActionsMenu/FollowUpBossMenuSection";
import PhoneMenuSection from "../../components/box-view/ActionsMenu/PhoneMenuSection";
import PremierAgentMenuSection from "../../components/box-view/ActionsMenu/PremierAgentMenuSection";
const BuyerLeadActionsMenu = (props) => {
    var _a;
    const { buyerLead, onCreateBuyerContractSelected } = props;
    const ref = React.useRef(null);
    const [isCommentPopoverOpen, setIsCommentPopoverOpen] = React.useState(false);
    const openCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(true);
    }, []);
    const closeCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(false);
    }, []);
    const premierAgentId = buyerLead.fields["Premier Agent Contact ID"];
    const session = useSession();
    const navigate = useNavigate();
    const canViewApiResponse = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]);
    const freezeAllFormulas = React.useCallback(() => {
        enqueueSnackbar("Not implemented yet");
    }, []);
    const copyQFSLink = React.useCallback(() => {
        copyToClipboard(`${window.location.protocol}//${window.location.host}/forms/questions-for-the-search/${buyerLead.uid}`);
        enqueueSnackbar("Copied QFS link to clipboard", { variant: "success" });
    }, [buyerLead.uid]);
    const duplicateApi = useDuplicateBuyerLead();
    const duplicate = React.useCallback(() => {
        duplicateApi
            .mutateAsync({ uid: buyerLead.uid })
            .then((result) => {
            navigate(`/buyers/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [buyerLead.uid, duplicateApi, navigate]);
    const duplicateAsSellerApi = useDuplicateBuyerLeadAsSellerLead();
    const createSellerLead = React.useCallback(() => {
        duplicateAsSellerApi
            .mutateAsync({ uid: buyerLead.uid })
            .then((result) => {
            navigate(`/sellers/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [buyerLead.uid, duplicateAsSellerApi, navigate]);
    const isCreatingLead = duplicateApi.isPending || duplicateAsSellerApi.isPending;
    const copyLinkToLead = React.useCallback(() => {
        const url = new URL(window.location.href);
        url.pathname = `/a/buyers/${buyerLead.uid}`;
        url.search = "";
        url.hash = "";
        copyToClipboard(url.toString());
        enqueueSnackbar("Copied link to clipboard", { variant: "success" });
    }, [buyerLead.uid]);
    const [isZHLETRDialogOpen, setIsZHLETRDialogOpen] = React.useState(false);
    const openZHLETRDialog = React.useCallback(() => {
        setIsZHLETRDialogOpen(true);
    }, []);
    const closeZHLETRDialog = React.useCallback(() => {
        setIsZHLETRDialogOpen(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsMenu, { ref: ref },
            React.createElement(MenuItem, { onClick: openCommentPopover },
                React.createElement(ListItemIcon, null,
                    React.createElement(Comment, null)),
                "Add a comment"),
            React.createElement(Divider, null),
            React.createElement(EmailMenuSection, { entityType: "Buyer Lead", contacts: buyerLead.clients.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        email: (_a = client.client.email) !== null && _a !== void 0 ? _a : "",
                    });
                }), entityUids: [buyerLead.uid] }),
            React.createElement(Divider, null),
            React.createElement(PhoneMenuSection, { contacts: buyerLead.clients.map((client) => {
                    var _a;
                    return ({
                        name: getFullName(client.client.first_name, client.client.last_name),
                        phone: (_a = client.client.phone) !== null && _a !== void 0 ? _a : "",
                    });
                }) }),
            React.createElement(Divider, null),
            React.createElement(PremierAgentMenuSection, { premierAgentId: premierAgentId }),
            React.createElement(FollowUpBossMenuSection, { personId: buyerLead.fields["FUB ID"] }),
            React.createElement(BuyerLeadActionsMenuFormsSection, { buyerLead: buyerLead }),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { onClick: copyQFSLink },
                React.createElement(ListItemIcon, null,
                    React.createElement(Article, null)),
                "Copy Questions for the Search link"),
            React.createElement(MenuItem, { component: Link, href: `/buyer-leads/${buyerLead.uid}/mail-schedule`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Mail, null)),
                "Open Mail Schedule"),
            buyerLead.streak_box_key && (React.createElement(MenuItem, { component: Link, href: getStreakLinkForBox(buyerLead.streak_box_key), target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { height: 20, alt: "Streak", src: streakIcon })),
                "Open Streak box")),
            React.createElement(MenuItem, { onClick: freezeAllFormulas },
                React.createElement(ListItemIcon, null,
                    React.createElement(AcUnit, null)),
                "Freeze all formulas"),
            React.createElement(MenuItem, { onClick: openZHLETRDialog, disabled: !buyerLead.fields["FUB ID"] || !buyerLead.fields["FLEX OFFICE"] },
                React.createElement(ListItemIcon, null,
                    React.createElement(Send, null)),
                "Send ZHL Engaged Transfer"),
            React.createElement(Divider, null),
            onCreateBuyerContractSelected && (React.createElement(MenuItem, { onClick: onCreateBuyerContractSelected }, "Start new contract")),
            React.createElement(MenuItem, { onClick: duplicate, disabled: isCreatingLead }, "Duplicate buyer lead"),
            React.createElement(MenuItem, { onClick: createSellerLead, disabled: isCreatingLead }, "Create seller lead"),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { onClick: copyLinkToLead },
                React.createElement(ListItemIcon, null,
                    React.createElement(CopyAll, { fontSize: "small" })),
                "Copy link to this lead"),
            canViewApiResponse && (React.createElement(MenuItem, { component: Link, href: `/api/buyer_leads/${buyerLead.uid}`, target: "_blank" },
                React.createElement(ListItemIcon, null,
                    React.createElement(Code, null)),
                "View API response"))),
        isCommentPopoverOpen && (React.createElement(CorePopover, { open: true, anchorEl: ref.current },
            React.createElement(CommentPopover, { onCancel: closeCommentPopover, onSubmit: closeCommentPopover, entityUid: buyerLead.uid }))),
        isZHLETRDialogOpen && (React.createElement(SendZHLETRDialog, { buyerLead: buyerLead, onClose: closeZHLETRDialog, DialogProps: { open: true }, onSubmitComplete: closeZHLETRDialog }))));
};
export default BuyerLeadActionsMenu;
