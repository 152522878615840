import * as React from "react";
import { Check, Error } from "@mui/icons-material";
import { CircularProgress, FormControlLabel, Grid2, Switch, Typography } from "@mui/material";
import ActionDialog from "@app/common/ActionDialog";
import useLoading from "@app/hooks/useLoading";
import { useUpdateGoogleGroupAccess } from "@app/orval/api/google-groups";
import { useUpdateSlackAccess } from "@app/orval/api/slack-access";
import { useListStages } from "@app/orval/api/stages";
import { useGetUser, useListGoogleGroupsForUser, useListSlackConversationsForUser, useReleaseTwilioPhoneForUser, useUpdateUser, } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const TerminateUserDialog = (props) => {
    var _a, _b, _c, _d, _e;
    const { onCancel, onComplete, user } = props;
    const [shouldRemoveUserFromSlackChannels, setShouldRemoveUserFromSlackChannels] = React.useState(true);
    const [shouldRemoveUserFromGoogleGroups, setShouldRemoveUserFromGoogleGroups] = React.useState(true);
    const [shouldReleaseTwilioNumber, setShouldReleaseTwilioNumber] = React.useState(!!user.fields["Twilio #"]);
    const [status, setStatus] = React.useState([]);
    const getUserApi = useGetUser(user.uid, { query: { enabled: false } });
    const updateUserApi = useUpdateUser();
    const listStagesApi = useListStages({ type: "User" }, REFETCH_CACHE_PARAMS);
    const stages = (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const terminatedStage = React.useMemo(() => {
        return stages === null || stages === void 0 ? void 0 : stages.find((x) => x.name === "Terminated");
    }, [stages]);
    const listSlackChannelsForUserApi = useListSlackConversationsForUser(user.uid, {}, { query: { enabled: !!user.fields["Slack ID"] } });
    const slackChannelsForUser = React.useMemo(() => {
        var _a, _b;
        if (!user.fields["Slack ID"]) {
            return [];
        }
        return (_b = (_a = listSlackChannelsForUserApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_c = listSlackChannelsForUserApi.data) === null || _c === void 0 ? void 0 : _c.data, user.fields]);
    const listGoogleGroupsForUserApi = useListGoogleGroupsForUser(user.uid, { query: { enabled: !!user.email } });
    const googleGroupsForUser = React.useMemo(() => {
        var _a, _b;
        if (!user.email) {
            return { groups: [] };
        }
        return (_b = (_a = listGoogleGroupsForUserApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_d = listGoogleGroupsForUserApi.data) === null || _d === void 0 ? void 0 : _d.data, user.email]);
    const updateSlackAccessApi = useUpdateSlackAccess();
    const updateGoogleGroupsAccessApi = useUpdateGoogleGroupAccess();
    const releaseTwilioNumberApi = useReleaseTwilioPhoneForUser();
    const handleToggleRemoveUserFromSlackChannels = React.useCallback((_, checked) => {
        setShouldRemoveUserFromSlackChannels(checked);
    }, [setShouldRemoveUserFromSlackChannels]);
    const handleToggleRemoveUserFromGoogleGroups = React.useCallback((_, checked) => {
        setShouldRemoveUserFromGoogleGroups(checked);
    }, [setShouldRemoveUserFromGoogleGroups]);
    const handleToggleReleaseTwilioNumber = React.useCallback((_, checked) => {
        setShouldReleaseTwilioNumber(checked);
    }, [setShouldReleaseTwilioNumber]);
    const terminateUser = React.useCallback(() => {
        var _a, _b;
        setStatus([
            {
                label: "Terminating user...",
                status: "loading",
            },
            ...(shouldRemoveUserFromSlackChannels
                ? slackChannelsForUser.map((x) => ({
                    label: `Removing user from ${x.name}...`,
                    status: "loading",
                }))
                : []),
            ...(shouldRemoveUserFromGoogleGroups
                ? ((_a = googleGroupsForUser === null || googleGroupsForUser === void 0 ? void 0 : googleGroupsForUser.groups) !== null && _a !== void 0 ? _a : []).map((x) => ({
                    label: `Removing user from ${x.name}...`,
                    status: "loading",
                }))
                : []),
            ...(shouldReleaseTwilioNumber
                ? [
                    {
                        label: "Releasing Twilio number...",
                        status: "loading",
                    },
                ]
                : []),
        ]);
        const promises = [];
        promises.push(updateUserApi
            .mutateAsync({ uid: user.uid, data: { stage_uid: terminatedStage.uid } })
            .then(() => {
            setStatus((prev) => prev.map((x) => (x.label === "Terminating user..." ? Object.assign(Object.assign({}, x), { status: "success" }) : x)));
        })
            .catch(() => {
            setStatus((prev) => prev.map((x) => (x.label === "Terminating user..." ? Object.assign(Object.assign({}, x), { status: "error" }) : x)));
        }));
        if (shouldRemoveUserFromSlackChannels) {
            for (const channel of slackChannelsForUser) {
                promises.push(updateSlackAccessApi
                    .mutateAsync({ data: { channel_id: channel.id, user_uid: user.uid, has_access: false } })
                    .then(() => {
                    setStatus((prev) => prev.map((x) => x.label === `Removing user from ${channel.name}...` ? Object.assign(Object.assign({}, x), { status: "success" }) : x));
                })
                    .catch(() => {
                    setStatus((prev) => prev.map((x) => (x.label === `Removing user from ${channel.name}...` ? Object.assign(Object.assign({}, x), { status: "error" }) : x)));
                }));
            }
        }
        if (shouldRemoveUserFromGoogleGroups) {
            for (const group of (_b = googleGroupsForUser === null || googleGroupsForUser === void 0 ? void 0 : googleGroupsForUser.groups) !== null && _b !== void 0 ? _b : []) {
                promises.push(updateGoogleGroupsAccessApi
                    .mutateAsync({
                    data: { group_id: group.id, user_uid: user.uid, has_access: false },
                })
                    .then(() => {
                    setStatus((prev) => prev.map((x) => (x.label === `Removing user from ${group.name}...` ? Object.assign(Object.assign({}, x), { status: "success" }) : x)));
                })
                    .catch(() => {
                    setStatus((prev) => prev.map((x) => (x.label === `Removing user from ${group.name}...` ? Object.assign(Object.assign({}, x), { status: "error" }) : x)));
                }));
            }
        }
        if (shouldReleaseTwilioNumber) {
            promises.push(releaseTwilioNumberApi
                .mutateAsync({ profileUid: user.uid })
                .then(() => {
                setStatus((prev) => [...prev, { label: "Releasing Twilio number...", status: "success" }]);
            })
                .catch(() => {
                setStatus((prev) => [...prev, { label: "Releasing Twilio number...", status: "error" }]);
            }));
        }
        Promise.all(promises).then(() => {
            getUserApi.refetch();
            onComplete();
        });
    }, [
        getUserApi,
        googleGroupsForUser === null || googleGroupsForUser === void 0 ? void 0 : googleGroupsForUser.groups,
        onComplete,
        releaseTwilioNumberApi,
        shouldReleaseTwilioNumber,
        shouldRemoveUserFromGoogleGroups,
        shouldRemoveUserFromSlackChannels,
        slackChannelsForUser,
        terminatedStage,
        updateGoogleGroupsAccessApi,
        updateSlackAccessApi,
        updateUserApi,
        user.uid,
    ]);
    const loading = useLoading({
        items: [
            {
                label: "Loading stage definitions...",
                queryResult: listStagesApi,
            },
            {
                label: "Loading Slack channels for user...",
                queryResult: listSlackChannelsForUserApi,
                isRelevant: !!user.fields["Slack ID"],
            },
            {
                label: "Loading Google Groups for user...",
                queryResult: listGoogleGroupsForUserApi,
                isRelevant: !!user.email,
            },
        ],
    });
    const isUpdating = updateUserApi.isPending;
    const isWorking = loading.isLoading || isUpdating;
    return (React.createElement(ActionDialog, { title: `Terminate ${user.name}`, onCancel: onCancel, onConfirm: terminateUser, isConfirmButtonDisabled: isWorking, isCancelButtonDisabled: isUpdating, DialogProps: {
            open: true,
        } }, loading.isLoading && loading.loadingComponent ? (loading.loadingComponent) : (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Typography, null,
                "You're about to terminate ",
                user.name || "this user",
                ".",
                " ",
                (slackChannelsForUser !== null && slackChannelsForUser !== void 0 ? slackChannelsForUser : []).length + ((_e = googleGroupsForUser === null || googleGroupsForUser === void 0 ? void 0 : googleGroupsForUser.groups) !== null && _e !== void 0 ? _e : []).length > 0 &&
                    "Do you want also want to:")),
        slackChannelsForUser.length > 0 && (React.createElement(Grid2, { container: true, direction: "column", spacing: 0 },
            React.createElement(Grid2, null,
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: shouldRemoveUserFromSlackChannels, onChange: handleToggleRemoveUserFromSlackChannels }), label: `Remove user from ${slackChannelsForUser.length} Slack channels` })),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "caption", style: { fontStyle: "italic" } }, slackChannelsForUser
                    .sort((a, b) => {
                    var _a, _b;
                    const aName = (_a = a.name) !== null && _a !== void 0 ? _a : "";
                    const bName = (_b = b.name) !== null && _b !== void 0 ? _b : "";
                    if (aName < bName)
                        return -1;
                    if (aName > bName)
                        return 1;
                    return 0;
                })
                    .map((c) => c.name)
                    .join(", "))))),
        (googleGroupsForUser === null || googleGroupsForUser === void 0 ? void 0 : googleGroupsForUser.groups) && googleGroupsForUser.groups.length > 0 && (React.createElement(Grid2, { container: true, direction: "column", spacing: 0 },
            React.createElement(Grid2, null,
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: shouldRemoveUserFromGoogleGroups, onChange: handleToggleRemoveUserFromGoogleGroups }), label: `Remove user from ${googleGroupsForUser.groups.length} Google Groups` })),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "caption", style: { fontStyle: "italic" } }, googleGroupsForUser.groups
                    .sort((a, b) => {
                    if (a.name < b.name)
                        return -1;
                    if (a.name > b.name)
                        return 1;
                    return 0;
                })
                    .map((g) => g.name)
                    .join(", "))))),
        !!user.fields["Twilio #"] && (React.createElement(Grid2, null,
            React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: shouldReleaseTwilioNumber, onChange: handleToggleReleaseTwilioNumber }), label: `Release Twilio number ${user.fields["Twilio #"]}` }))),
        status.length > 0 && (React.createElement(Grid2, null, status.map((s) => (React.createElement(Grid2, { key: s.label, container: true, spacing: 1, direction: "row", wrap: "nowrap" },
            React.createElement(Grid2, null,
                s.status === "loading" && React.createElement(CircularProgress, { size: 16 }),
                s.status === "success" && React.createElement(Check, { color: "primary", fontSize: "small" }),
                s.status === "error" && React.createElement(Error, { color: "error", fontSize: "small" })),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "body2" }, s.label)))))))))));
};
export default TerminateUserDialog;
