import * as React from "react";
import { Box } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { useGetEmail } from "@app/orval/api/emails";
import Email from "./Email";
import useGmailThreadRedirect from "./useGmailThreadRedirect";
const EmailWrapper = (props) => {
    var _a, _b;
    const { uid } = props;
    const getEmailApi = useGetEmail(uid);
    const email = (_b = (_a = getEmailApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    useGmailThreadRedirect(email === null || email === void 0 ? void 0 : email.id);
    if (getEmailApi.error) {
        return React.createElement(CoreError, { error: getEmailApi.error });
    }
    if (!email) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Box, { p: 2 },
        React.createElement(Email, { email: email })));
};
export default EmailWrapper;
