import * as React from "react";
import { Delete, DragIndicator } from "@mui/icons-material";
import { Grid2, IconButton, Tooltip, Typography } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
export const DEFAULT_TITLE_STYLE = { fontWeight: "bold", width: "100%", textAlign: "center" };
export const DEFAULT_CONTAINER_STYLE = {
    width: "200px",
    maxWidth: "200px",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderLeft: "1px solid #ccc",
    display: "inline-block",
    height: "100%",
};
/** This component still uses `@dnd-kit` because `@hello-pangea` does not allow nested scrollbars */
const MAtrixDraggable = (props) => {
    const { dragHandle, name, onDelete, style, titleStyle, uid } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id: uid });
    const deleteItem = (itemUid) => () => {
        onDelete(itemUid);
    };
    return (React.createElement(Grid2, { ref: setNodeRef, container: true, spacing: 2, style: Object.assign({ transform: CSS.Transform.toString(transform) }, (style !== null && style !== void 0 ? style : DEFAULT_CONTAINER_STYLE)) },
        React.createElement(Grid2, { container: true, size: 12 },
            React.createElement(Grid2, { container: true, size: 12, style: { textAlign: "center" }, alignItems: "center" },
                React.createElement(Grid2, Object.assign({}, attributes, listeners, { style: { marginTop: "auto" } }), dragHandle !== null && dragHandle !== void 0 ? dragHandle : (React.createElement(DragIndicator, { fontSize: "small", style: {
                        cursor: "grab",
                        color: "#999",
                    } }))),
                React.createElement(Grid2, { style: {
                        display: "flex",
                        width: "calc(100% - 58px)",
                        overflow: "hidden",
                        marginTop: "auto",
                        marginBottom: "auto",
                    } }, typeof name === "string" ? (React.createElement(Typography, { variant: "body2", style: titleStyle !== null && titleStyle !== void 0 ? titleStyle : DEFAULT_TITLE_STYLE }, name)) : (name)),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Tooltip, { title: "Delete" },
                        React.createElement("span", null,
                            React.createElement(IconButton, { size: "small", onClick: deleteItem(uid) },
                                React.createElement(Delete, { fontSize: "small" }))))))),
        props.children));
};
export default MAtrixDraggable;
