import * as React from "react";
import { Add, House } from "@mui/icons-material";
import { IconButton, List, Tooltip, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import NewPropertyDialog from "./NewPropertyDialog";
import PropertyDialog from "./PropertyDialog";
import PropertyListItem from "./PropertyListItem";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const Properties = (props) => {
    var _a;
    const { buyerLead, canEdit = true, currentlyEditingPropertyUid, isAddingPSP, isAddingQFO, onAddProperty, onAddShowing, onDialogClose, onLinkPSP, onLinkQFO, onMarketAnalysisClicked, onRemoveProperty, onRemoveShowing, onSelectPropertyToEdit, onStartNewPSP, onStartNewQFO, onUnlinkPSP, onUnlinkQFO, onUpdateProperty, onUpdateShowing, } = props;
    const properties = buyerLead.properties;
    const [isNewPropertyDialogOpen, setIsNewPropertyDialogOpen] = React.useState(false);
    const [isAddingProperty, setIsAddingProperty] = React.useState(false);
    const editProperty = React.useCallback((propertyUid) => {
        onSelectPropertyToEdit(propertyUid);
    }, [onSelectPropertyToEdit]);
    const addShowing = React.useCallback((showing) => {
        onAddShowing(currentlyEditingPropertyUid, showing);
    }, [currentlyEditingPropertyUid, onAddShowing]);
    const startNewPSP = React.useCallback(() => {
        onStartNewPSP(currentlyEditingPropertyUid);
    }, [currentlyEditingPropertyUid, onStartNewPSP]);
    const unlinkPSP = React.useCallback((pspUid) => {
        onUnlinkPSP(currentlyEditingPropertyUid, pspUid);
    }, [currentlyEditingPropertyUid, onUnlinkPSP]);
    const linkPSP = React.useCallback((pspUid) => {
        onLinkPSP(currentlyEditingPropertyUid, pspUid);
    }, [currentlyEditingPropertyUid, onLinkPSP]);
    const startNewQFO = React.useCallback(() => {
        onStartNewQFO(currentlyEditingPropertyUid);
    }, [currentlyEditingPropertyUid, onStartNewQFO]);
    const marketAnalysis = React.useCallback(() => {
        onMarketAnalysisClicked(currentlyEditingPropertyUid);
    }, [currentlyEditingPropertyUid, onMarketAnalysisClicked]);
    const unlinkQFO = React.useCallback((qfoUid) => {
        onUnlinkQFO(currentlyEditingPropertyUid, qfoUid);
    }, [currentlyEditingPropertyUid, onUnlinkQFO]);
    const linkQFO = React.useCallback((qfoUid) => {
        onLinkQFO(currentlyEditingPropertyUid, qfoUid);
    }, [currentlyEditingPropertyUid, onLinkQFO]);
    const addOrUpdateProperty = React.useCallback((params) => {
        console.log({ currentlyEditingPropertyUid });
        onUpdateProperty(currentlyEditingPropertyUid, params);
    }, [currentlyEditingPropertyUid, onUpdateProperty]);
    const openNewPropertyDialog = React.useCallback(() => {
        setIsNewPropertyDialogOpen(true);
    }, []);
    const closeNewPropertyDialog = React.useCallback(() => {
        setIsNewPropertyDialogOpen(false);
    }, []);
    const addProperty = React.useCallback((params) => {
        setIsAddingProperty(true);
        onAddProperty({ property_address: params.address }, () => {
            setIsAddingProperty(false);
            closeNewPropertyDialog();
        });
    }, [closeNewPropertyDialog, onAddProperty]);
    const isLoading = properties == null;
    const noProperties = properties && properties.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(House, null), title: "Properties", count: (_a = properties.length) !== null && _a !== void 0 ? _a : undefined, toolbar: canEdit ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add property") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: openNewPropertyDialog, disabled: !!isAddingProperty },
                        React.createElement(Add, { fontSize: "small" }))))) : null },
            isLoading && React.createElement(CoreLoading, null),
            noProperties && React.createElement(Typography, { variant: "body2" }, "No properties"),
            properties && properties.length > 0 && (React.createElement(List, { dense: true }, properties
                .sort((a, b) => {
                if (a.created_at > b.created_at)
                    return -1;
                if (a.created_at < b.created_at)
                    return 1;
                return 0;
            })
                .map((property) => (React.createElement(PropertyListItem, { key: property.uid, property: property, onEditProperty: editProperty, onRemoveProperty: onRemoveProperty, canEdit: canEdit })))))),
        !!currentlyEditingPropertyUid && (React.createElement(PropertyDialog, { buyerLead: buyerLead, DialogProps: { open: !!currentlyEditingPropertyUid }, property: (properties !== null && properties !== void 0 ? properties : []).find((a) => a.uid === currentlyEditingPropertyUid), onClose: onDialogClose, onUpdate: addOrUpdateProperty, onStartNewPSP: startNewPSP, onUnlinkPSP: unlinkPSP, isAddingPSP: isAddingPSP, onStartNewQFO: startNewQFO, onMarketAnalysisClicked: marketAnalysis, onUnlinkQFO: unlinkQFO, isAddingQFO: isAddingQFO, onLinkPSP: linkPSP, onLinkQFO: linkQFO, onAddShowing: addShowing, onUpdateShowing: onUpdateShowing, onRemoveShowing: onRemoveShowing })),
        isNewPropertyDialogOpen && (React.createElement(NewPropertyDialog, { onAddProperty: addProperty, onClose: closeNewPropertyDialog, DialogProps: { open: true }, isAddingProperty: isAddingProperty }))));
};
export default Properties;
