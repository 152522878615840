import { useCallback, useEffect, useState } from "react";
const useChronologyVisibilitySettings = (params) => {
    const { allowedVisibilityOptions, visibilityType } = params;
    const [visibility, setVisibility] = useState(allowedVisibilityOptions);
    useEffect(() => {
        if (visibilityType) {
            const storedVisibility = localStorage.getItem(`chronology visibility: ${visibilityType}`);
            console.log({ storedVisibility });
            if (storedVisibility) {
                try {
                    setVisibility(JSON.parse(storedVisibility));
                }
                catch (e) {
                    console.warn(e);
                }
            }
        }
    }, [visibilityType]);
    const onVisibilityChanged = useCallback((value) => {
        const valueSanitized = value.filter((x) => allowedVisibilityOptions.includes(x));
        setVisibility(valueSanitized);
        if (visibilityType) {
            console.log({ visibilityType, valueSanitized });
            localStorage.setItem(`chronology visibility: ${visibilityType}`, JSON.stringify(value));
        }
    }, [visibilityType, allowedVisibilityOptions]);
    return {
        visibility: [...visibility, "alsdkhjfasldkjfh"],
        onVisibilityChanged,
    };
};
export default useChronologyVisibilitySettings;
