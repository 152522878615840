import * as React from "react";
import { CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import MUIDataTable from "mui-datatables";
import { numericSortCompare } from "@app/util/Utils";
class CoreDataTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "getMuiTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (parentTheme) => {
                var _a, _b, _c;
                let heightSubtract = 0;
                if (!(((_a = this.props.options) === null || _a === void 0 ? void 0 : _a.showToolbar) === false ||
                    (this.props.title === "" &&
                        this.props.options &&
                        !this.props.options.customToolbar &&
                        !this.props.options.filter &&
                        !this.props.options.download &&
                        !this.props.options.print &&
                        !this.props.options.viewColumns &&
                        !this.props.options.search &&
                        !this.props.options.searchOpen))) {
                    heightSubtract += 64;
                }
                if (((_b = this.props.options) === null || _b === void 0 ? void 0 : _b.pagination) !== false) {
                    heightSubtract += 53;
                }
                if (heightSubtract) {
                    heightSubtract += 1;
                }
                const theme = Object.assign(Object.assign({}, parentTheme), { components: {
                        MuiTableCell: {
                            styleOverrides: {
                                head: {
                                    whiteSpace: "nowrap",
                                },
                                body: {
                                    whiteSpace: "nowrap",
                                    overflowX: "hidden",
                                    paddingTop: this.props.cellPadding === undefined ? "5px" : `${this.props.cellPadding}px`,
                                    paddingBottom: this.props.cellPadding === undefined ? "5px" : `${this.props.cellPadding}px`,
                                },
                            },
                        },
                        // @ts-expect-error 'MUIDataTable' does not exist in type 'Components<Omit<Theme, "components" | "palette"> & CssVarsTheme>'
                        MUIDataTable: {
                            styleOverrides: {
                                root: {
                                    overflowX: "auto",
                                },
                                responsiveBase: {
                                    height: `calc(100% - ${heightSubtract}px) !important`,
                                },
                                paper: {
                                    maxHeight: "none !important",
                                    height: this.props.height ? this.props.height : "100%",
                                    overflowY: "auto",
                                },
                            },
                        },
                        MUIDataTableSelectCell: {
                            styleOverrides: {
                                root: {
                                    "& button": {
                                        padding: "0px",
                                    },
                                },
                            },
                        },
                        MUIDataTableSearch: {
                            styleOverrides: {
                                clearIcon: {
                                    display: "none !important",
                                },
                            },
                        },
                        MUIDataTablePagination: {
                            styleOverrides: {
                                tableCellContainer: {
                                    padding: "0 !important",
                                },
                            },
                        },
                        MUIDataTableHeadCell: {
                            styleOverrides: {
                                root: {
                                    fontWeight: "bold !important",
                                },
                                sortAction: {
                                    fontWeight: "bold !important",
                                },
                                toolButton: {
                                    all: "unset !important",
                                },
                            },
                        },
                        MUIDataTableBodyCell: {
                            styleOverrides: {
                                root: {
                                    overflow: "hidden !important",
                                },
                            },
                        },
                    } });
                if (((_c = this.props.options) === null || _c === void 0 ? void 0 : _c.showToolbar) === false) {
                    theme.components.MuiToolbar = {
                        styleOverrides: {
                            root: {
                                display: "none",
                            },
                        },
                    };
                }
                return createTheme(theme);
            }
        });
    }
    render() {
        var _a, _b, _c;
        const loadingComponent = (React.createElement("div", { style: {
                position: "absolute",
                zIndex: 110,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(255,255,255,0.8)",
            } },
            React.createElement(CircularProgress, { size: 100 })));
        const { columns } = this.props;
        for (let col of columns) {
            col = col;
            if (col.type === "number" && ((_a = col.options) === null || _a === void 0 ? void 0 : _a.sort) !== false) {
                if (!col.options) {
                    col.options = {};
                }
                col.options.sortCompare = numericSortCompare;
            }
            else if (col.type === "date" && ((_b = col.options) === null || _b === void 0 ? void 0 : _b.sort) !== false) {
                if (!col.options) {
                    col.options = {};
                }
                col.options.sortCompare = (order) => (obj1, obj2) => {
                    const val1 = dayjs(obj1.data);
                    const val2 = dayjs(obj2.data);
                    return (val1.unix() - val2.unix()) * (order === "asc" ? 1 : -1);
                };
            }
        }
        return (React.createElement(ThemeProvider, { theme: this.getMuiTheme },
            this.props.isLoading && loadingComponent,
            React.createElement(MUIDataTable, { title: (_c = this.props.title) !== null && _c !== void 0 ? _c : "", data: this.props.data, columns: columns, options: this.props.options })));
    }
}
Object.defineProperty(CoreDataTable, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        height: null,
        cellPadding: null,
        isLoading: false,
    }
});
export default CoreDataTable;
