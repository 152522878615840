import * as React from "react";
import ChromeExtensionCompatibleIframe from "@app/common/ChromeExtensionCompatibleIframe";
import CoreLoading from "@app/common/CoreLoading";
import { useGetPspReportUrl } from "@app/orval/api/psp-reports";
const PSPReport = (props) => {
    var _a, _b;
    const { uid } = props;
    const getPspReportUrlApi = useGetPspReportUrl(uid);
    const pspReportUrl = (_b = (_a = getPspReportUrlApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    if (getPspReportUrlApi.isFetching) {
        return React.createElement(CoreLoading, null);
    }
    if (!pspReportUrl) {
        return React.createElement("div", null, "Report not found");
    }
    return React.createElement(ChromeExtensionCompatibleIframe, { width: "100%", height: "100%", src: pspReportUrl.url, title: "PSP Report" });
};
export default PSPReport;
