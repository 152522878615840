import * as React from "react";
import { InputAdornment, TextField } from "@mui/material";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewEditNameTextField = () => {
    var _a;
    const context = useSavedViewEditorContext();
    const setName = React.useCallback((e) => {
        context.setView((prev) => {
            return Object.assign(Object.assign({}, prev), { name: e.target.value });
        });
    }, [context]);
    return (React.createElement(TextField, { size: "small", variant: "outlined", fullWidth: true, slotProps: {
            input: {
                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Name"),
            },
        }, value: (_a = context.view.name) !== null && _a !== void 0 ? _a : "", onChange: setName }));
};
export default SavedViewEditNameTextField;
