import * as React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { format } from "timeago.js";
dayjs.extend(utc);
const TwilioSendingMessage = (props) => {
    const { message, timestamp } = props;
    const theme = useTheme();
    const { mode } = theme.palette;
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 0, style: {
            textAlign: "right",
            alignItems: "flex-end",
        } },
        React.createElement(Grid, { item: true, style: {
                maxWidth: "90%",
                background: "#aef5a6",
                color: "rgba(0, 0, 0, 0.87)",
                opacity: 0.5,
                borderRadius: "0.25rem",
            } },
            React.createElement(Box, { p: 1 },
                React.createElement(Typography, { variant: "body2" }, message))),
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: "caption", style: { color: mode === "light" ? "#888" : "#ccc" } },
                React.createElement(Typography, { variant: "caption" }, format(dayjs.utc(timestamp).toDate()))))));
};
export default TwilioSendingMessage;
