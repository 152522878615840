import { useWindowSize } from "react-use";
import theme_ from "@app/common/theme";
const useSize = (theme) => {
    const { height, width } = useWindowSize();
    /**
     * `true` if the window width is equal to or above the specified size
     * @param size the size to check, as a number of pixels OR a breakpoint (xs, sm, md, lg, xl)
     * @returns
     */
    const isUp = (size) => {
        if (typeof size === "number") {
            return width >= size;
        }
        return width >= (theme !== null && theme !== void 0 ? theme : theme_).breakpoints.values[size];
    };
    /**
     * `true` if the ewindow width is below the specified size
     * @param size the size to check, as a number of pixels OR a breakpoint (xs, sm, md, lg, xl)
     * @returns
     */
    const isDown = (size) => {
        if (typeof size === "number") {
            return width < size;
        }
        return width < (theme !== null && theme !== void 0 ? theme : theme_).breakpoints.values[size];
    };
    return {
        isUp,
        isDown,
        width,
        height,
    };
};
export default useSize;
