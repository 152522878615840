import * as React from "react";
import { Menu } from "@mui/icons-material";
import { AppBar, Grid2, IconButton, Toolbar } from "@mui/material";
import dashLogo from "@app/assets/img/logo-white.png";
import useAllowOverlay from "@app/hooks/useAllowOverlay";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import Activity from "./Header/Activity";
import Errors from "./Header/Errors/Errors";
import NewVersionNotification from "./Header/NewVersionNotification";
import Search from "./Header/Search/Search";
import Twilio from "./Header/Twilio";
import User from "./Header/User";
const Header = (props) => {
    var _a, _b, _c, _d;
    const { onSidebarToggle } = props;
    const session = useSession();
    const allowOverlay = useAllowOverlay();
    const toggleSidebarOpen = React.useCallback(() => {
        onSidebarToggle();
    }, [onSidebarToggle]);
    const size = useSize();
    return (React.createElement(AppBar, { position: "relative", style: { backgroundColor: "#005686", color: "#FFFFFF" } },
        React.createElement(NewVersionNotification, null),
        React.createElement(Toolbar, { variant: "dense" },
            React.createElement(IconButton, { edge: "start", color: "inherit", onClick: toggleSidebarOpen },
                React.createElement(Menu, null)),
            React.createElement("img", { src: dashLogo, alt: "DASH Carolina Logo", style: { height: "30px", marginLeft: "10px" } }),
            React.createElement("div", { style: { flexGrow: 1 } }),
            React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal) && size.isUp("md") && (React.createElement(Grid2, null,
                    React.createElement(Search, { variant: "navbar" }))),
                allowOverlay && (React.createElement(Grid2, null,
                    React.createElement(Twilio, null))),
                ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"])) && size.isUp("md") && (React.createElement(Grid2, null,
                    React.createElement(Errors, null))),
                ((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.isInternal) && size.isUp("md") && (React.createElement(Grid2, null,
                    React.createElement(Activity, null))),
                ((_d = session === null || session === void 0 ? void 0 : session.activeUser) === null || _d === void 0 ? void 0 : _d.isInternal) && React.createElement(User, null)))));
};
export default Header;
