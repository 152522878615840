import * as React from "react";
import { Email, Phone } from "@mui/icons-material";
import { Avatar, Grid, Typography } from "@mui/material";
import CallLink from "@app/common/links/CallLink";
import EmailLink from "@app/common/links/EmailLink";
const ContactAvatar = (props) => {
    var _a, _b, _c;
    const { contact } = props;
    const initials = [contact.fields["First Name"], contact.fields["Last Name"]]
        .filter((o) => o)
        .map((o) => (o !== null && o !== void 0 ? o : "").trim().substring(0, 1))
        .join("") || "?";
    const fullName = [contact.fields["First Name"], contact.fields["Last Name"]]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => (o !== null && o !== void 0 ? o : "").trim())
        .join(" ") ||
        ((_a = contact.fields["Email"]) === null || _a === void 0 ? void 0 : _a.trim()) ||
        ((_b = contact.fields["Phone"]) === null || _b === void 0 ? void 0 : _b.trim());
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true },
            React.createElement(Avatar, { src: undefined, style: { height: "48px", width: "48px" } }, initials)),
        React.createElement(Grid, { item: true, style: { flex: 1 } },
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "h6" }, fullName)),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Phone, { style: { color: "#888" } })),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(CallLink, { phoneNumber: (_c = contact.fields["Phone"]) !== null && _c !== void 0 ? _c : "", name: fullName })))),
                React.createElement(Grid, { item: true },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Email, { style: { color: "#888" } })),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(EmailLink, { emailAddresses: contact.fields["Email"] ? [contact.fields["Email"]] : [] }))))))));
};
export default ContactAvatar;
