var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { DragIndicator } from "@mui/icons-material";
import { Grid2 } from "@mui/material";
const RESIZE_HANDLE_WIDTH = 20;
/**
 * Adds a right sidebar to a component
 * @param props
 * @returns
 */
const DashSidebarContainer = (props) => {
    const { isResizable = false, mainContent, opened = false, sidebar = null, sidebarWidth: defaultSidebarWidth = 250, style } = props, other = __rest(props, ["isResizable", "mainContent", "opened", "sidebar", "sidebarWidth", "style"]);
    const [isOpened, setIsOpened] = React.useState(opened);
    const [isDragging, setIsDragging] = React.useState(false);
    const [sidebarWidth, setSidebarWidth] = React.useState(defaultSidebarWidth);
    const initialPosition = React.useRef(null);
    const initialSidebarWidth = React.useRef(null);
    React.useEffect(() => {
        if (opened !== isOpened) {
            setIsOpened(opened);
        }
    }, [isOpened, opened]);
    const onDrag = React.useCallback((initialX, newX) => {
        setSidebarWidth(() => {
            const newWidth = initialSidebarWidth.current - (newX - initialX);
            return newWidth;
        });
    }, []);
    const handleMouseDown = React.useCallback((e) => {
        setIsDragging(true);
        initialPosition.current = {
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
        };
        initialSidebarWidth.current = sidebarWidth;
        e.stopPropagation();
        e.preventDefault();
    }, [sidebarWidth]);
    const handleMouseMove = React.useCallback((e) => {
        const newPosition = {
            x: e.pageX,
            y: e.pageY,
        };
        onDrag(initialPosition.current.x, newPosition.x);
        e.stopPropagation();
        e.preventDefault();
    }, [onDrag]);
    const handleMouseUp = React.useCallback((e) => {
        initialPosition.current = null;
        setIsDragging(false);
        e.stopPropagation();
        e.preventDefault();
    }, []);
    React.useEffect(() => {
        if (isDragging) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        }
        else {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        }
    }, [handleMouseMove, handleMouseUp, isDragging]);
    return (React.createElement("div", Object.assign({}, other, { style: Object.assign({ height: "100%", width: "100%" }, style) }),
        React.createElement(Grid2, { container: true, wrap: "nowrap", style: { height: "100%" } },
            React.createElement(Grid2, { style: {
                    width: opened && sidebar ? `calc(100% - ${sidebarWidth}px)` : "100%",
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                } }, mainContent),
            sidebar && opened && (React.createElement(React.Fragment, null,
                isResizable && (React.createElement(Grid2, { style: {
                        width: RESIZE_HANDLE_WIDTH,
                        cursor: "grab",
                        marginTop: "auto",
                        marginBottom: "auto",
                        borderLeft: "1px solid #ccc",
                        overflow: "hidden",
                    }, onMouseDown: handleMouseDown },
                    React.createElement(DragIndicator, null))),
                React.createElement(Grid2, { style: {
                        width: sidebarWidth - (isResizable ? RESIZE_HANDLE_WIDTH : 0),
                        borderLeft: "1px solid #ccc",
                        height: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                    } }, sidebar))))));
};
export default DashSidebarContainer;
