import * as React from "react";
import { TextField, MenuItem, Typography, Grid2 } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import ActionDialog from "@app/common/ActionDialog";
import CoreDateField from "@app/common/CoreDateField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useListGraduationRequirementTypes } from "@app/orval/api/graduation-requirement-types";
import { useAddUserGraduationRequirement, useGetUser, useUpdateUserGraduationRequirement } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const DEFAULT_FORM_SUBMISSION = {
    graduation_requirement_date: dayjs(new Date()).format("YYYY-MM-DD"),
    graduation_requirement_type: undefined,
};
const GraduationRequirementDialog = (props) => {
    var _a, _b, _c;
    const { DialogProps, graduationRequirement, onClose, userUid } = props;
    const [formData, setFormData] = React.useState(graduationRequirement
        ? {
            graduation_requirement_date: graduationRequirement.graduation_requirement_date,
            graduation_requirement_type: graduationRequirement.graduation_requirement_type.uid,
        }
        : DEFAULT_FORM_SUBMISSION);
    const [initialData, setInitialData] = React.useState(formData);
    const isValid = React.useMemo(() => {
        return (formData.graduation_requirement_type != null &&
            formData.graduation_requirement_date != null &&
            JSON.stringify(formData) !== JSON.stringify(initialData));
    }, [formData, initialData]);
    const queryClient = useQueryClient();
    const getUserApi = useGetUser(props.userUid, { query: { enabled: false } });
    const createGraduationRequirementApi = useAddUserGraduationRequirement();
    const updateGraduationRequirementApi = useUpdateUserGraduationRequirement();
    const listGraduationRequirementTypesAPi = useListGraduationRequirementTypes(REFETCH_CACHE_PARAMS);
    const graduationRequirementTypes = (_b = (_a = listGraduationRequirementTypesAPi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    React.useEffect(() => {
        if (graduationRequirement) {
            const data = {
                graduation_requirement_date: graduationRequirement.graduation_requirement_date,
                graduation_requirement_type: graduationRequirement.graduation_requirement_type.uid,
            };
            setFormData(data);
            setInitialData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graduationRequirement === null || graduationRequirement === void 0 ? void 0 : graduationRequirement.uid]);
    const save = React.useCallback(() => {
        const data = {
            graduation_requirement_date: formData.graduation_requirement_date,
            graduation_requirement_type: formData.graduation_requirement_type,
        };
        if (graduationRequirement === null || graduationRequirement === void 0 ? void 0 : graduationRequirement.uid) {
            updateGraduationRequirementApi
                .mutateAsync({ userUid, graduationRequirementUid: graduationRequirement.uid, data })
                .then((response) => {
                queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: response.data });
                });
            })
                .catch(enqueueErrorSnackbar)
                .then(onClose);
        }
        else {
            createGraduationRequirementApi
                .mutateAsync({ userUid, data })
                .then((response) => {
                queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: response.data });
                });
            })
                .catch(enqueueErrorSnackbar)
                .then(onClose);
        }
    }, [
        formData.graduation_requirement_date,
        formData.graduation_requirement_type,
        graduationRequirement === null || graduationRequirement === void 0 ? void 0 : graduationRequirement.uid,
        updateGraduationRequirementApi,
        userUid,
        onClose,
        queryClient,
        getUserApi.queryKey,
        createGraduationRequirementApi,
    ]);
    const setGraduationRequirementType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { graduation_requirement_type: e.target.value })));
    }, [setFormData]);
    const setGraduationRequirementDate = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { graduation_requirement_date: date !== null && date !== void 0 ? date : undefined })));
    }, [setFormData]);
    const isSaving = createGraduationRequirementApi.isPending || updateGraduationRequirementApi.isPending;
    return (React.createElement(ActionDialog, { title: graduationRequirement ? "Edit Graduation Requirement" : "Add Graduation Requirement", DialogProps: DialogProps, onConfirm: save, onCancel: onClose, confirmButtonText: "Save", isConfirmButtonDisabled: !isValid || isSaving },
        React.createElement(Grid2, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null, graduationRequirementTypes ? (React.createElement(TextField, { fullWidth: true, label: "Type", variant: "standard", select: true, value: formData.graduation_requirement_type || "", onChange: setGraduationRequirementType },
                React.createElement(MenuItem, { value: "" }, "Select Graduation Requirement"),
                graduationRequirementTypes.map((type) => (React.createElement(MenuItem, { key: type.uid, value: type.uid }, type.name))))) : (React.createElement(Typography, null, "Loading graduation requirements..."))),
            React.createElement(Grid2, null,
                React.createElement(CoreDateField, { fullWidth: true, label: "Date", format: "M/D/YYYY", formatType: "dayjs", variant: "standard", date: (_c = formData.graduation_requirement_date) !== null && _c !== void 0 ? _c : null, onDateChange: setGraduationRequirementDate, hasPicker: true })))));
};
export default GraduationRequirementDialog;
