import * as React from "react";
import { useLocation, useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import MailScheduleBoilerplateForm from "./MailScheduleBoilerplateForm";
import MailScheduleBoilerplateTable from "./MailScheduleBoilerplateTable";
const MailScheduleBoilerplateWrapper = () => {
    const params = useParams();
    const location = useLocation();
    const { uid } = params;
    useDocumentTitle("Mail Schedule | Boilerplate");
    return uid || location.pathname.includes("/new") ? (React.createElement(MailScheduleBoilerplateForm, { uid: uid })) : (React.createElement(MailScheduleBoilerplateTable, null));
};
export default MailScheduleBoilerplateWrapper;
