import * as React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useGetGoogleDataStudioReport } from "@app/orval/api/google-data-studio-reports";
const Report = (props) => {
    var _a, _b, _c;
    const { iframeProps } = props;
    const params = useParams();
    useDocumentTitle("Google Data Studio");
    const getReportApi = useGetGoogleDataStudioReport((_a = params.uid) !== null && _a !== void 0 ? _a : "", { query: { enabled: !!params.uid } });
    const report = (_c = (_b = getReportApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const { error } = getReportApi;
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!report) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Box, { p: 2, style: { height: "calc(100% - 1rem)" } },
        React.createElement("iframe", Object.assign({ title: report.name, width: "100%", height: "100%", src: report.url, frameBorder: "0", style: Object.assign({ border: 0 }, iframeProps === null || iframeProps === void 0 ? void 0 : iframeProps.style), allowFullScreen: true }, iframeProps))));
};
export default Report;
