import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Close, Edit, KeyboardArrowLeft, Print, Toc } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, Tooltip, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkDirective from "remark-directive";
import remarkGfm from "remark-gfm";
import { v4 as uuidv4 } from "uuid";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import "github-markdown-css";
import "./markdown_style";
import CorePopover from "@app/common/CorePopover";
import dayjs_ from "@app/common/dayjs";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { getSop } from "@app/orval/api/sops";
import { reactMarkdownRemarkDirective, embedVideoRemarkDirective } from "./custom_directives_viewonly";
import SOPTableOfContents from "./SOPTableOfContents";
import { canEditSops, formatImages, formatVideos } from "./utils";
const MARKDOWN_WRAPPER_ID = uuidv4();
const SOP = (props) => {
    const { onClose, sop, uid } = props;
    const [sop_, setSop_] = useStateWithCallback(sop !== null && sop !== void 0 ? sop : null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError] = React.useState(null);
    const retrieveSop = React.useCallback(() => {
        if (uid) {
            getSop(uid)
                .then((result) => {
                console.log("GOT SOP:", result.data);
                setSop_(result.data);
            })
                .catch((err) => {
                console.error(err);
                setSop_(null);
                setError(err);
            });
        }
    }, [setSop_, uid]);
    React.useEffect(() => {
        if (!sop) {
            retrieveSop();
        }
    }, [retrieveSop, sop]);
    const print = React.useCallback(() => {
        const content = document.getElementById(MARKDOWN_WRAPPER_ID);
        console.log("PRINT CONTENT:", content);
        const myframe = document.createElement("IFRAME");
        myframe.style.position = "absolute";
        myframe.style.top = "0px";
        myframe.style.left = "0px";
        myframe.style.width = "0px";
        myframe.style.height = "0px";
        myframe.style.display = "none";
        document.body.appendChild(myframe);
        myframe.contentWindow.open();
        myframe.contentWindow.document.write(`<h1>${sop_ === null || sop_ === void 0 ? void 0 : sop_.name}</h1><hr>${content.innerHTML}`);
        for (const child of Array.from(document.head.children)) {
            if (child.tagName === "LINK" || child.tagName === "STYLE") {
                myframe.contentWindow.document.head.appendChild(child.cloneNode(true));
            }
        }
        myframe.contentWindow.document.close();
        myframe.contentWindow.focus();
        myframe.contentWindow.print();
        // for (const child of Array.from(myframe.contentWindow!.document.head.children)) {
        //   myframe.contentWindow!.document.head.removeChild(child)
        // }
        myframe.remove();
    }, [sop_ === null || sop_ === void 0 ? void 0 : sop_.name]);
    const openTableOfContents = React.useCallback((e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }, []);
    const closeTableOfContents = React.useCallback((e) => {
        e.stopPropagation();
        setAnchorEl(null);
    }, []);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!sop_) {
        return React.createElement(CoreLoading, null);
    }
    // format any custom directive tags to display content
    const content = formatImages(formatVideos(sop_.content));
    const { last_updated, last_updated_by } = sop_;
    console.log("SOP CONTENT:", content);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, spacing: 2, style: { height: "100%" } },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true },
                        !onClose && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Back to SOPs") },
                                React.createElement(IconButton, { component: RouterLink, to: "/sops", size: "large" },
                                    React.createElement(KeyboardArrowLeft, { fontSize: "large" }))))),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Table of Contents") },
                                React.createElement(IconButton, { size: "large", onClick: openTableOfContents },
                                    React.createElement(Toc, { fontSize: "large" })))),
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "h2" }, sop_.name)),
                        canEditSops([...sop_.sharing]) && uid && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit SOP") },
                                React.createElement(IconButton, { component: RouterLink, to: `/sops/${uid}`, size: "large" },
                                    React.createElement(Edit, { fontSize: "large" }))))),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Print") },
                                React.createElement(IconButton, { onClick: print },
                                    React.createElement(Print, { fontSize: "large" })))),
                        onClose && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: "Close" },
                                React.createElement(IconButton, { onClick: onClose },
                                    React.createElement(Close, { fontSize: "large" })))))))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Typography, { style: { fontSize: "10px", paddingLeft: "8px" } },
                    `Last updated: ${last_updated && dayjs_(last_updated)
                        ? dayjs_(last_updated).format("dddd, MMMM D, YYYY h:mm A")
                        : last_updated}`,
                    last_updated_by ? ` by ${last_updated_by.first_name} ${last_updated_by.last_name}` : "")),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Divider, null)),
            React.createElement(Grid2, { size: 12, style: { height: "calc(100% - 100px)", overflowY: "auto" } },
                React.createElement(Box, { p: 2 },
                    React.createElement("div", { id: MARKDOWN_WRAPPER_ID, "data-color-mode": "light" },
                        React.createElement(ReactMarkdown, { remarkPlugins: [remarkGfm, remarkDirective, reactMarkdownRemarkDirective, embedVideoRemarkDirective], 
                            // @ts-expect-error Type '(options?: Options | null | undefined) => (tree: Root, file: VFile) => Root' is not assignable to type 'Pluggable<any[]>'
                            rehypePlugins: [rehypeRaw], className: "markdown-body" }, content))))),
        React.createElement(CorePopover, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: closeTableOfContents, anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "center",
            } },
            React.createElement(SOPTableOfContents, { onClose: closeTableOfContents }))));
};
export default SOP;
