import * as React from "react";
import Loading from "@app/common/Loading";
import { useGetListingsSpreadsheetData } from "@app/orval/api/google-sheets-listings-spreadsheet-data";
import { useGetPspSpreadsheetData } from "@app/orval/api/google-sheets-psp-spreadsheet-data";
import PSPAccuracyContent from "./PSPAccuracyContent";
import { mergePSPData, getZipCodes, getMarkets } from "./utils";
const PSPAccuracyWrapper = () => {
    var _a, _b;
    const googlesheetsGetPSPSpreadsheetDataApi = useGetPspSpreadsheetData();
    const googlesheetsGetListingsSpreadsheetDataApi = useGetListingsSpreadsheetData();
    const psps = (_a = googlesheetsGetPSPSpreadsheetDataApi.data) === null || _a === void 0 ? void 0 : _a.data;
    const listings = (_b = googlesheetsGetListingsSpreadsheetDataApi.data) === null || _b === void 0 ? void 0 : _b.data;
    const loading = [
        {
            label: "Loading PSP Spreadsheet data...",
            status: googlesheetsGetPSPSpreadsheetDataApi.status,
            error: googlesheetsGetPSPSpreadsheetDataApi.error,
        },
        {
            label: "Loading Listings Spreadsheet data...",
            status: googlesheetsGetListingsSpreadsheetDataApi.status,
            error: googlesheetsGetListingsSpreadsheetDataApi.error,
        },
    ];
    if (loading.find((x) => x.status !== "success") || !psps || !listings) {
        return React.createElement(Loading, { items: loading });
    }
    console.log({ psps });
    console.log({ listings });
    const pspAccuracyData = mergePSPData(psps, listings);
    const defaultZipCodesFilter = getZipCodes(pspAccuracyData);
    const defaultMarketsFilter = getMarkets(pspAccuracyData);
    return (React.createElement(PSPAccuracyContent, { pspAccuracyData: pspAccuracyData, defaultZipCodesFilter: defaultZipCodesFilter, defaultMarketsFilter: defaultMarketsFilter }));
};
export default PSPAccuracyWrapper;
