import * as React from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router";
import { closeSidebar, navigateTo } from "../window-messages";
const OpenInCRMButton = () => {
    const location = useLocation();
    const openInCRM = React.useCallback(() => {
        console.log(`opening ${location.pathname}${location.search} in Gmail CRM`);
        navigateTo(location.pathname + location.search);
        closeSidebar();
    }, [location]);
    // don't need this button certain widgets
    if (location.pathname.includes("add-threads-to-box") || location.pathname.endsWith("/my-tasks")) {
        return null;
    }
    // check if this button is disabled
    // @ts-expect-error: does not exist on type 'Window'
    if (window._DASH_HIDE_OPEN_IN_CRM_BUTTON) {
        return null;
    }
    return (React.createElement(Button, { onClick: openInCRM, variant: "text", style: { width: "100%" } }, "Open in CRM"));
};
export default OpenInCRMButton;
