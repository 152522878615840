import { useMemo, useRef } from "react";
import { useListMailschedule4Errors } from "@app/orval/api/mail-schedule-4-errors";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
const useMailScheduleTemplateErrors = () => {
    var _a, _b, _c, _d;
    const now = useRef(new Date());
    const twoHoursAgo = useRef(new Date(now.current.getTime() - 2 * 60 * 60 * 1000));
    const listTemplatesApi = useListMailschedule4Templates();
    const templates = (_b = (_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const templatesByUid = useMemo(() => {
        const res = {};
        templates === null || templates === void 0 ? void 0 : templates.forEach((t) => {
            res[t.uid] = t;
        });
        return res;
    }, [templates]);
    const listErrorsApi = useListMailschedule4Errors({
        since: twoHoursAgo.current.toISOString(),
        is_data_error: false,
    });
    const errors = (_d = (_c = listErrorsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const templatesWithErrors = useMemo(() => {
        var _a, _b, _c;
        return [
            ...((_a = templates === null || templates === void 0 ? void 0 : templates.filter((x) => x.criteria.some((fg) => fg.filters.some((f) => f.error)))) !== null && _a !== void 0 ? _a : []),
            ...((_b = templates === null || templates === void 0 ? void 0 : templates.filter((x) => x.criteria.some((fg) => fg.parent_uid != null && fg.filters.length === 0 && !x.criteria.some((fg2) => fg2.parent_uid === fg.uid))).map((x) => (Object.assign(Object.assign({}, x), { error: "At least one filter group is empty" })))) !== null && _b !== void 0 ? _b : []),
            ...((_c = errors === null || errors === void 0 ? void 0 : errors.filter((e) => !!templatesByUid[e.template_uid]).map((e) => {
                return Object.assign(Object.assign({}, templatesByUid[e.template_uid]), { error: e.error_message });
            })) !== null && _c !== void 0 ? _c : []).filter((x, i, all) => {
                return !all.some((y, j) => j > i && y.uid === x.uid && y.error === x.error);
            }),
        ];
    }, [templates, errors, templatesByUid]);
    return templatesWithErrors;
};
export default useMailScheduleTemplateErrors;
