import * as React from "react";
import { Grid2, Typography } from "@mui/material";
import useSession from "@app/hooks/useSession";
const StageValidationError = (props) => {
    var _a, _b;
    const { error } = props;
    const session = useSession();
    const canOverrideStageValidationError = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin"]);
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 3 },
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body1" }, String((_b = error.response) === null || _b === void 0 ? void 0 : _b.data))),
        canOverrideStageValidationError && (React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold", fontStyle: "italic" } }, "Do you want to override this rule and change the stage anyway?")))));
};
export default StageValidationError;
