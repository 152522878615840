import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, AvatarGroup, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography, } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";
import User from "@app/classes/user";
import CorePopover from "@app/common/CorePopover";
import DashStatusBadge from "@app/common/CoreStatusBadge";
import useActivity from "@app/hooks/useActivity";
import useSession from "@app/hooks/useSession";
import AppConfig from "@app/util/AppConfig";
import { formatDate_Dayjs } from "@app/util/Utils";
dayjs.extend(utc);
const StyledRouterLink = styled(RouterLink) `
    color: ${AppConfig.themeColors.primary};
    :not(:hover) {
        text-decoration: unset;
    }
`;
const Activity = (props) => {
    var _a;
    const { style } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const session = useSession();
    const handleClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const activity = useActivity();
    const activityPerUser = React.useMemo(() => {
        var _a;
        const res = {};
        ((_a = activity.currentActivity) !== null && _a !== void 0 ? _a : []).forEach((item) => {
            var _a, _b, _c, _d;
            if (((_a = item.user) === null || _a === void 0 ? void 0 : _a.email) === ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.email)) {
                return;
            }
            const key = (_d = (_c = item.user) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : item.ip_address;
            if (!key) {
                return;
            }
            if (!res[key]) {
                res[key] = [];
            }
            res[key].push(item);
        });
        return Object.values(res).map((item) => ({
            user: item[0].user,
            activity: item,
        }));
    }, [activity.currentActivity, (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.email]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: "Activity", placement: "bottom" },
            React.createElement(IconButton, { onClick: handleClick, tabIndex: -1 },
                React.createElement(AvatarGroup, { max: 6, style: style }, activityPerUser
                    .sort((a, b) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
                    if (a.user && !b.user)
                        return -1;
                    if (!a.user && b.user)
                        return 1;
                    if (((_b = (_a = a.user) === null || _a === void 0 ? void 0 : _a.first_name) !== null && _b !== void 0 ? _b : "") < ((_d = (_c = b.user) === null || _c === void 0 ? void 0 : _c.first_name) !== null && _d !== void 0 ? _d : ""))
                        return -1;
                    if (((_f = (_e = a.user) === null || _e === void 0 ? void 0 : _e.first_name) !== null && _f !== void 0 ? _f : "") > ((_h = (_g = b.user) === null || _g === void 0 ? void 0 : _g.first_name) !== null && _h !== void 0 ? _h : ""))
                        return 1;
                    if (((_k = (_j = a.user) === null || _j === void 0 ? void 0 : _j.last_name) !== null && _k !== void 0 ? _k : "") < ((_m = (_l = b.user) === null || _l === void 0 ? void 0 : _l.last_name) !== null && _m !== void 0 ? _m : ""))
                        return -1;
                    if (((_p = (_o = a.user) === null || _o === void 0 ? void 0 : _o.last_name) !== null && _p !== void 0 ? _p : "") > ((_r = (_q = b.user) === null || _q === void 0 ? void 0 : _q.last_name) !== null && _r !== void 0 ? _r : ""))
                        return 1;
                    return 0;
                })
                    .filter((item) => {
                    var _a, _b, _c;
                    if (!((_a = session === null || session === void 0 ? void 0 : session.activeUser) === null || _a === void 0 ? void 0 : _a.isDeveloper) && !((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.isDeveloper)) {
                        return (!item.user ||
                            (!new User(item.user).isDeveloper && item.user.email !== ((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.email)));
                    }
                    return true;
                })
                    .map((item) => {
                    var _a, _b, _c;
                    const userObj = item.user ? new User(item.user) : null;
                    return (React.createElement(Avatar, { key: (_a = userObj === null || userObj === void 0 ? void 0 : userObj.email) !== null && _a !== void 0 ? _a : item.activity[0].ip_address, src: (_b = userObj === null || userObj === void 0 ? void 0 : userObj.icon_url) !== null && _b !== void 0 ? _b : undefined }, (_c = userObj === null || userObj === void 0 ? void 0 : userObj.initials) !== null && _c !== void 0 ? _c : "?"));
                })))),
        React.createElement(CorePopover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, slotProps: {
                paper: {
                    style: {
                        maxWidth: "800px",
                    },
                },
            } },
            React.createElement(List, { dense: true }, activityPerUser
                .filter((item) => {
                var _a, _b, _c;
                if (item.activity.length === 0) {
                    return false;
                }
                if (!((_a = session === null || session === void 0 ? void 0 : session.activeUser) === null || _a === void 0 ? void 0 : _a.isDeveloper) && !((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.isDeveloper)) {
                    return (!item.user || (!new User(item.user).isDeveloper && item.user.email !== ((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.email)));
                }
                return true;
            })
                .map((item) => {
                var _a, _b, _c, _d, _e;
                const userObj = item.user ? new User(item.user) : null;
                return (React.createElement(ListItem, { key: (_b = (_a = item.user) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : item.activity[0].ip_address },
                    React.createElement(ListItemAvatar, null,
                        React.createElement(DashStatusBadge, { status: dayjs.utc(`${item.activity[0].last_active}+00:00`) > dayjs.utc(new Date()).add(-2, "minute")
                                ? "active"
                                : "away", overlap: "circular", anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            }, variant: "dot" },
                            React.createElement(Avatar, { src: (_c = userObj === null || userObj === void 0 ? void 0 : userObj.icon_url) !== null && _c !== void 0 ? _c : undefined }, (_d = userObj === null || userObj === void 0 ? void 0 : userObj.initials) !== null && _d !== void 0 ? _d : "?"))),
                    React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Grid, { container: true, spacing: 1 },
                                    React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Typography, { style: { fontWeight: "bold" } }, (_e = userObj === null || userObj === void 0 ? void 0 : userObj.name) !== null && _e !== void 0 ? _e : item.activity[0].ip_address)),
                                        userObj && (React.createElement(React.Fragment, null,
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Typography, { style: { fontWeight: "bold" } }, "\u2022")),
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Link, { href: `mailto:${userObj.email}` },
                                                    React.createElement(Typography, null, userObj.email)))))),
                                    React.createElement(Grid, { item: true, container: true, xs: 12 }, item.activity
                                        .filter((elem, i) => {
                                        if (item.activity.find((elem_, j) => elem.url === elem_.url &&
                                            elem.opened === elem_.opened &&
                                            elem.last_active === elem_.last_active &&
                                            j > i)) {
                                            return false;
                                        }
                                        return true;
                                    })
                                        .map((obj) => (React.createElement(Grid, { key: `${obj.url}_${obj.opened}_${obj.last_active}`, item: true, xs: 12 },
                                        React.createElement(StyledRouterLink, { to: new URL(obj.url).pathname },
                                            React.createElement(Typography, { variant: "body2", color: "primary" }, obj.url)))))))),
                            React.createElement(Grid, { item: true, style: { textAlign: "right" } },
                                React.createElement(Typography, null,
                                    "Last Active",
                                    " ",
                                    formatDate_Dayjs(`${[...item.activity].sort((a, b) => {
                                        if (a.last_active < b.last_active)
                                            return -1;
                                        if (a.last_active > b.last_active)
                                            return 1;
                                        return 0;
                                    })[0].last_active}`, "M/D/YYYY h:mm A")))) })));
            })))));
};
export default Activity;
