/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List boolean field comparators
 */
export const listBooleanFieldComparators = (options) => {
    return axios.get(`/api/filters/list_boolean_comparators`, options);
};
export const getListBooleanFieldComparatorsQueryKey = () => {
    return [`/api/filters/list_boolean_comparators`];
};
export const getListBooleanFieldComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListBooleanFieldComparatorsQueryKey();
    const queryFn = ({ signal }) => listBooleanFieldComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List boolean field comparators
 */
export function useListBooleanFieldComparators(options) {
    const queryOptions = getListBooleanFieldComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List choice field comparators
 */
export const listChoiceFieldComparators = (options) => {
    return axios.get(`/api/filters/list_choice_comparators`, options);
};
export const getListChoiceFieldComparatorsQueryKey = () => {
    return [`/api/filters/list_choice_comparators`];
};
export const getListChoiceFieldComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListChoiceFieldComparatorsQueryKey();
    const queryFn = ({ signal }) => listChoiceFieldComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List choice field comparators
 */
export function useListChoiceFieldComparators(options) {
    const queryOptions = getListChoiceFieldComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List date field comparators
 */
export const listDateFieldComparators = (options) => {
    return axios.get(`/api/filters/list_date_comparators`, options);
};
export const getListDateFieldComparatorsQueryKey = () => {
    return [`/api/filters/list_date_comparators`];
};
export const getListDateFieldComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDateFieldComparatorsQueryKey();
    const queryFn = ({ signal }) => listDateFieldComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List date field comparators
 */
export function useListDateFieldComparators(options) {
    const queryOptions = getListDateFieldComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List date filter units
 */
export const listDateFilterUnits = (options) => {
    return axios.get(`/api/filters/list_date_filter_units`, options);
};
export const getListDateFilterUnitsQueryKey = () => {
    return [`/api/filters/list_date_filter_units`];
};
export const getListDateFilterUnitsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDateFilterUnitsQueryKey();
    const queryFn = ({ signal }) => listDateFilterUnits(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List date filter units
 */
export function useListDateFilterUnits(options) {
    const queryOptions = getListDateFilterUnitsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List date ranges
 */
export const listDateRanges = (options) => {
    return axios.get(`/api/filters/list_date_ranges`, options);
};
export const getListDateRangesQueryKey = () => {
    return [`/api/filters/list_date_ranges`];
};
export const getListDateRangesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDateRangesQueryKey();
    const queryFn = ({ signal }) => listDateRanges(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List date ranges
 */
export function useListDateRanges(options) {
    const queryOptions = getListDateRangesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List Mail Schedule template comparators
 */
export const listMailScheduleTemplateComparators = (options) => {
    return axios.get(`/api/filters/list_mail_schedule_template_comparators`, options);
};
export const getListMailScheduleTemplateComparatorsQueryKey = () => {
    return [`/api/filters/list_mail_schedule_template_comparators`];
};
export const getListMailScheduleTemplateComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailScheduleTemplateComparatorsQueryKey();
    const queryFn = ({ signal }) => listMailScheduleTemplateComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Mail Schedule template comparators
 */
export function useListMailScheduleTemplateComparators(options) {
    const queryOptions = getListMailScheduleTemplateComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List Mail Schedule Template Filter time comparators
 */
export const listMailScheduleTemplateFilterTimeComparators = (options) => {
    return axios.get(`/api/filters/list_mail_schedule_template_filter_time_comparators`, options);
};
export const getListMailScheduleTemplateFilterTimeComparatorsQueryKey = () => {
    return [`/api/filters/list_mail_schedule_template_filter_time_comparators`];
};
export const getListMailScheduleTemplateFilterTimeComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailScheduleTemplateFilterTimeComparatorsQueryKey();
    const queryFn = ({ signal }) => listMailScheduleTemplateFilterTimeComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Mail Schedule Template Filter time comparators
 */
export function useListMailScheduleTemplateFilterTimeComparators(options) {
    const queryOptions = getListMailScheduleTemplateFilterTimeComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List Mail Schedule Template Filter time units
 */
export const listMailScheduleTemplateFilterTimeUnits = (options) => {
    return axios.get(`/api/filters/list_mail_schedule_template_filter_time_units`, options);
};
export const getListMailScheduleTemplateFilterTimeUnitsQueryKey = () => {
    return [`/api/filters/list_mail_schedule_template_filter_time_units`];
};
export const getListMailScheduleTemplateFilterTimeUnitsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailScheduleTemplateFilterTimeUnitsQueryKey();
    const queryFn = ({ signal }) => listMailScheduleTemplateFilterTimeUnits(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Mail Schedule Template Filter time units
 */
export function useListMailScheduleTemplateFilterTimeUnits(options) {
    const queryOptions = getListMailScheduleTemplateFilterTimeUnitsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List multiple choice field comparators
 */
export const listMultipleChoiceFieldComparators = (options) => {
    return axios.get(`/api/filters/list_multiple_choice_comparators`, options);
};
export const getListMultipleChoiceFieldComparatorsQueryKey = () => {
    return [`/api/filters/list_multiple_choice_comparators`];
};
export const getListMultipleChoiceFieldComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMultipleChoiceFieldComparatorsQueryKey();
    const queryFn = ({ signal }) => listMultipleChoiceFieldComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List multiple choice field comparators
 */
export function useListMultipleChoiceFieldComparators(options) {
    const queryOptions = getListMultipleChoiceFieldComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List notes comparators
 */
export const listNotesComparators = (options) => {
    return axios.get(`/api/filters/list_notes_comparators`, options);
};
export const getListNotesComparatorsQueryKey = () => {
    return [`/api/filters/list_notes_comparators`];
};
export const getListNotesComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListNotesComparatorsQueryKey();
    const queryFn = ({ signal }) => listNotesComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List notes comparators
 */
export function useListNotesComparators(options) {
    const queryOptions = getListNotesComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List number field comparators
 */
export const listNumberFieldComparators = (options) => {
    return axios.get(`/api/filters/list_number_comparators`, options);
};
export const getListNumberFieldComparatorsQueryKey = () => {
    return [`/api/filters/list_number_comparators`];
};
export const getListNumberFieldComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListNumberFieldComparatorsQueryKey();
    const queryFn = ({ signal }) => listNumberFieldComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List number field comparators
 */
export function useListNumberFieldComparators(options) {
    const queryOptions = getListNumberFieldComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List office comparators
 */
export const listOfficeComparators = (options) => {
    return axios.get(`/api/filters/list_office_comparators`, options);
};
export const getListOfficeComparatorsQueryKey = () => {
    return [`/api/filters/list_office_comparators`];
};
export const getListOfficeComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListOfficeComparatorsQueryKey();
    const queryFn = ({ signal }) => listOfficeComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List office comparators
 */
export function useListOfficeComparators(options) {
    const queryOptions = getListOfficeComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List role comparators
 */
export const listRoleComparators = (options) => {
    return axios.get(`/api/filters/list_role_comparators`, options);
};
export const getListRoleComparatorsQueryKey = () => {
    return [`/api/filters/list_role_comparators`];
};
export const getListRoleComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListRoleComparatorsQueryKey();
    const queryFn = ({ signal }) => listRoleComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List role comparators
 */
export function useListRoleComparators(options) {
    const queryOptions = getListRoleComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List stage comparators
 */
export const listStageComparators = (options) => {
    return axios.get(`/api/filters/list_stage_comparators`, options);
};
export const getListStageComparatorsQueryKey = () => {
    return [`/api/filters/list_stage_comparators`];
};
export const getListStageComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStageComparatorsQueryKey();
    const queryFn = ({ signal }) => listStageComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List stage comparators
 */
export function useListStageComparators(options) {
    const queryOptions = getListStageComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List text field comparators
 */
export const listTextFieldComparators = (options) => {
    return axios.get(`/api/filters/list_text_comparators`, options);
};
export const getListTextFieldComparatorsQueryKey = () => {
    return [`/api/filters/list_text_comparators`];
};
export const getListTextFieldComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTextFieldComparatorsQueryKey();
    const queryFn = ({ signal }) => listTextFieldComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List text field comparators
 */
export function useListTextFieldComparators(options) {
    const queryOptions = getListTextFieldComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List third-party comparators
 */
export const listThirdPartyComparators = (options) => {
    return axios.get(`/api/filters/list_third_party_comparators`, options);
};
export const getListThirdPartyComparatorsQueryKey = () => {
    return [`/api/filters/list_third_party_comparators`];
};
export const getListThirdPartyComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListThirdPartyComparatorsQueryKey();
    const queryFn = ({ signal }) => listThirdPartyComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List third-party comparators
 */
export function useListThirdPartyComparators(options) {
    const queryOptions = getListThirdPartyComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List timestamp comparators
 */
export const listTimestampComparators = (options) => {
    return axios.get(`/api/filters/list_timestamp_comparators`, options);
};
export const getListTimestampComparatorsQueryKey = () => {
    return [`/api/filters/list_timestamp_comparators`];
};
export const getListTimestampComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTimestampComparatorsQueryKey();
    const queryFn = ({ signal }) => listTimestampComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List timestamp comparators
 */
export function useListTimestampComparators(options) {
    const queryOptions = getListTimestampComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List timestamp filter units
 */
export const listTimestampFilterUnits = (options) => {
    return axios.get(`/api/filters/list_timestamp_filter_units`, options);
};
export const getListTimestampFilterUnitsQueryKey = () => {
    return [`/api/filters/list_timestamp_filter_units`];
};
export const getListTimestampFilterUnitsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTimestampFilterUnitsQueryKey();
    const queryFn = ({ signal }) => listTimestampFilterUnits(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List timestamp filter units
 */
export function useListTimestampFilterUnits(options) {
    const queryOptions = getListTimestampFilterUnitsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List timestamp types
 */
export const listTimestampTypes = (options) => {
    return axios.get(`/api/filters/list_timestamp_types`, options);
};
export const getListTimestampTypesQueryKey = () => {
    return [`/api/filters/list_timestamp_types`];
};
export const getListTimestampTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTimestampTypesQueryKey();
    const queryFn = ({ signal }) => listTimestampTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List timestamp types
 */
export function useListTimestampTypes(options) {
    const queryOptions = getListTimestampTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List user comparators
 */
export const listUserComparators = (options) => {
    return axios.get(`/api/filters/list_user_comparators`, options);
};
export const getListUserComparatorsQueryKey = () => {
    return [`/api/filters/list_user_comparators`];
};
export const getListUserComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListUserComparatorsQueryKey();
    const queryFn = ({ signal }) => listUserComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List user comparators
 */
export function useListUserComparators(options) {
    const queryOptions = getListUserComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List user filter types
 */
export const listUserFilterTypes = (options) => {
    return axios.get(`/api/filters/list_user_filter_types`, options);
};
export const getListUserFilterTypesQueryKey = () => {
    return [`/api/filters/list_user_filter_types`];
};
export const getListUserFilterTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListUserFilterTypesQueryKey();
    const queryFn = ({ signal }) => listUserFilterTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List user filter types
 */
export function useListUserFilterTypes(options) {
    const queryOptions = getListUserFilterTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List view comparators
 */
export const listViewComparators = (options) => {
    return axios.get(`/api/filters/list_view_comparators`, options);
};
export const getListViewComparatorsQueryKey = () => {
    return [`/api/filters/list_view_comparators`];
};
export const getListViewComparatorsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListViewComparatorsQueryKey();
    const queryFn = ({ signal }) => listViewComparators(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List view comparators
 */
export function useListViewComparators(options) {
    const queryOptions = getListViewComparatorsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List views
 */
export const listViews = (params, options) => {
    return axios.get(`/api/views`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListViewsQueryKey = (params) => {
    return [`/api/views`, ...(params ? [params] : [])];
};
export const getListViewsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListViewsQueryKey(params);
    const queryFn = ({ signal }) => listViews(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List views
 */
export function useListViews(params, options) {
    const queryOptions = getListViewsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a view
 */
export const addView = (viewCreateRequest, options) => {
    return axios.post(`/api/views`, viewCreateRequest, options);
};
export const getAddViewMutationOptions = (options) => {
    const mutationKey = ['addView'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addView(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a view
*/
export const useAddView = (options) => {
    const mutationOptions = getAddViewMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a view
*/
export const getView = (uid, options) => {
    return axios.get(`/api/views/${uid}`, options);
};
export const getGetViewQueryKey = (uid) => {
    return [`/api/views/${uid}`];
};
export const getGetViewQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetViewQueryKey(uid);
    const queryFn = ({ signal }) => getView(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a view
 */
export function useGetView(uid, options) {
    const queryOptions = getGetViewQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a view
 */
export const updateView = (uid, viewWriteRequest, options) => {
    return axios.post(`/api/views/${uid}`, viewWriteRequest, options);
};
export const getUpdateViewMutationOptions = (options) => {
    const mutationKey = ['updateView'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateView(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a view
*/
export const useUpdateView = (options) => {
    const mutationOptions = getUpdateViewMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete a view
*/
export const deleteView = (uid, options) => {
    return axios.delete(`/api/views/${uid}`, options);
};
export const getDeleteViewMutationOptions = (options) => {
    const mutationKey = ['deleteView'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteView(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a view
*/
export const useDeleteView = (options) => {
    const mutationOptions = getDeleteViewMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count views
*/
export const countViews = (params, options) => {
    return axios.get(`/api/views/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountViewsQueryKey = (params) => {
    return [`/api/views/count`, ...(params ? [params] : [])];
};
export const getCountViewsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountViewsQueryKey(params);
    const queryFn = ({ signal }) => countViews(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count views
 */
export function useCountViews(params, options) {
    const queryOptions = getCountViewsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * A faster version of `/api/views` that returns only a subset of fields

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List views (simplified)
 */
export const listViewsSimple = (params, options) => {
    return axios.get(`/api/views/simple`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListViewsSimpleQueryKey = (params) => {
    return [`/api/views/simple`, ...(params ? [params] : [])];
};
export const getListViewsSimpleQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListViewsSimpleQueryKey(params);
    const queryFn = ({ signal }) => listViewsSimple(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List views (simplified)
 */
export function useListViewsSimple(params, options) {
    const queryOptions = getListViewsSimpleQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Star a view
 */
export const starView = (starViewBodyRequest, options) => {
    return axios.post(`/api/views/star`, starViewBodyRequest, options);
};
export const getStarViewMutationOptions = (options) => {
    const mutationKey = ['starView'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return starView(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Star a view
*/
export const useStarView = (options) => {
    const mutationOptions = getStarViewMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary List starred views
*/
export const listStarredViews = (params, options) => {
    return axios.get(`/api/views/starred`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListStarredViewsQueryKey = (params) => {
    return [`/api/views/starred`, ...(params ? [params] : [])];
};
export const getListStarredViewsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStarredViewsQueryKey(params);
    const queryFn = ({ signal }) => listStarredViews(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List starred views
 */
export function useListStarredViews(params, options) {
    const queryOptions = getListStarredViewsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
