import * as React from "react";
import { ChevronRight } from "@mui/icons-material";
import { Box, Chip, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
const DataEntrySection = (props) => {
    var _a;
    const { hideHeader, isCompleted, name } = props;
    const [isVisible, setIsVisible] = React.useState((_a = props.isVisible) !== null && _a !== void 0 ? _a : true);
    const toggleVisibility = React.useCallback(() => {
        setIsVisible((prev) => !prev);
    }, [setIsVisible]);
    return (React.createElement(Stack, { style: { width: "100%" }, spacing: 1 },
        props.name && !hideHeader && (React.createElement(Box, { p: 2, pb: 0 },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, typeof name === "string" ? (React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, props.name)) : (name)),
                isCompleted && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto", paddingTop: 0, paddingBottom: 0 } },
                    React.createElement(Chip, { label: "Completed", variant: "outlined", size: "small", color: "primary", style: { borderRadius: "0.25rem", height: "16px" }, sx: {
                            "& span": {
                                padding: "0.25rem",
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        } }))),
                React.createElement(Grid, { item: true, style: { flex: 1 } }),
                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(IconButton, { onClick: toggleVisibility, size: "small" },
                        React.createElement(ChevronRight, { fontSize: "small", style: { transform: `rotate(${isVisible ? "90" : "0"}deg)` } })))))),
        isVisible && (React.createElement(React.Fragment, null,
            !hideHeader && React.createElement(Divider, null),
            React.createElement(Box, { p: 2, pt: hideHeader ? 2 : 0 }, props.children)))));
};
export default DataEntrySection;
