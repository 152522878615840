import * as React from "react";
import { DragHandle, Search } from "@mui/icons-material";
import { Box, Checkbox, Divider, FormControlLabel, Grid2, InputAdornment, TextField } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
const ColumnPanel = (props) => {
    const { columnArrangement, columns, onMoveColumn, onSearchUpdated, onSetColumnVisibility, searchStr } = props;
    console.log({ columnArrangement, columns });
    const droppableId = React.useRef(uuidv4());
    const lastSelectedColumn = React.useRef(null);
    const isShiftKeyPressed = React.useRef(false);
    const onKeyDown = React.useCallback((e) => {
        if (e.key === "Shift") {
            isShiftKeyPressed.current = true;
        }
    }, []);
    const onKeyUp = React.useCallback((e) => {
        if (e.key === "Shift") {
            isShiftKeyPressed.current = false;
        }
    }, []);
    React.useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("keyup", onKeyUp);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("keyup", onKeyUp);
        };
    }, [onKeyDown, onKeyUp]);
    const handleSearchStrChanged = React.useCallback((e) => {
        onSearchUpdated(e.target.value);
    }, [onSearchUpdated]);
    const updateColumnVisibility = React.useCallback((columnName) => (_, checked) => {
        console.log({
            isShiftKeyPressed: isShiftKeyPressed.current,
            lastSelectedColumn: lastSelectedColumn.current,
            columnName,
            checked,
        });
        if (isShiftKeyPressed.current && lastSelectedColumn.current) {
            const colIndx1 = columns.findIndex((c) => c.field === lastSelectedColumn.current);
            const colIndx2 = columns.findIndex((c) => c.field === columnName);
            const start = Math.min(colIndx1, colIndx2);
            const end = Math.max(colIndx1, colIndx2);
            const cols = columns.slice(start, end + 1);
            const params = cols.map((col) => ({ columnName: col.field, isVisible: checked }));
            console.log({ start, end, params });
            onSetColumnVisibility(params);
        }
        else {
            onSetColumnVisibility([{ columnName, isVisible: checked }]);
        }
        lastSelectedColumn.current = columnName;
    }, [columns, onSetColumnVisibility]);
    const adjustableColumns = React.useMemo(() => {
        return columns.filter((c) => c.hideable !== false);
    }, [columns]);
    const moveColumn = React.useCallback((result) => {
        if (result.destination) {
            const targetCol = adjustableColumns[result.destination.index];
            const col = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.find((c) => c.field_uid_or_column_name === targetCol.field);
            if (col) {
                onMoveColumn(adjustableColumns[result.source.index].field, col.sort_order - 1);
            }
        }
    }, [adjustableColumns, columnArrangement, onMoveColumn]);
    const canReorder = searchStr === "";
    const visibleColumns = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.filter((c) => c.is_visible).map((c) => c.field_uid_or_column_name);
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 0, wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(TextField, { size: "small", variant: "outlined", slotProps: {
                        input: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement(Search, null))),
                        },
                    }, value: searchStr, onChange: handleSearchStrChanged, fullWidth: true }))),
        visibleColumns != null && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, null,
                React.createElement(Divider, null)),
            React.createElement(Grid2, { style: { overflowY: "auto", flex: 1, maxHeight: "70vh" } },
                React.createElement(Box, { pl: 2, pr: 2 },
                    React.createElement(DragDropContext, { onDragEnd: moveColumn },
                        React.createElement(Droppable, { droppableId: droppableId.current }, (droppableProvided) => (React.createElement(Grid2, Object.assign({ container: true, spacing: 0, direction: "column" }, droppableProvided.droppableProps, { ref: droppableProvided.innerRef }), adjustableColumns.map((c, indx) => (React.createElement(Draggable, { key: c.field, draggableId: c.field, index: indx, isDragDisabled: !canReorder }, (draggableProvided) => (React.createElement(Grid2, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps),
                            React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                                React.createElement(Grid2, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps),
                                    React.createElement(DragHandle, { style: { transform: "translateY(2px)", color: canReorder ? "#666" : "#bbb" } })),
                                React.createElement(Grid2, null,
                                    React.createElement(FormControlLabel, { label: c.headerName || c.field, control: React.createElement(Checkbox, { color: "primary", size: "small", checked: visibleColumns.includes(c.field), sx: { padding: 0 }, onChange: updateColumnVisibility(c.field) }), sx: { marginLeft: "unset" } }))))))))))))))))));
};
export default ColumnPanel;
