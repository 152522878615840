/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Gets an available phone number

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Get Available Phone Number
 */
export const getAvailablePhoneNumber = (options) => {
    return axios.get(`/api/twilio/get_available_phone_number`, options);
};
export const getGetAvailablePhoneNumberQueryKey = () => {
    return [`/api/twilio/get_available_phone_number`];
};
export const getGetAvailablePhoneNumberQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAvailablePhoneNumberQueryKey();
    const queryFn = ({ signal }) => getAvailablePhoneNumber(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get Available Phone Number
 */
export function useGetAvailablePhoneNumber(options) {
    const queryOptions = getGetAvailablePhoneNumberQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Lists all registered Twilio phone numbers

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Phone Numbers
 */
export const listTwilioPhones = (params, options) => {
    return axios.get(`/api/twilio/phones`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListTwilioPhonesQueryKey = (params) => {
    return [`/api/twilio/phones`, ...(params ? [params] : [])];
};
export const getListTwilioPhonesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTwilioPhonesQueryKey(params);
    const queryFn = ({ signal }) => listTwilioPhones(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Phone Numbers
 */
export function useListTwilioPhones(params, options) {
    const queryOptions = getListTwilioPhonesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
