import * as React from "react";
import { Add, Refresh, Search } from "@mui/icons-material";
import { Alert, Box, Divider, Grid2, IconButton, InputAdornment, LinearProgress, List, ListItemButton, ListItemText, TextField, Tooltip, } from "@mui/material";
import groupBy from "lodash/groupBy";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import { useListChoiceSets } from "@app/orval/api/choice-sets";
import { useListFields } from "@app/orval/api/field-definitions";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const ChoiceSetsTable = () => {
    var _a, _b, _c, _d;
    const [search, setSearch] = React.useState("");
    const listFieldsApi = useListFields({ field_type: "choice" }, REFETCH_CACHE_PARAMS);
    const fields = (_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const fieldsByChoiceSet = React.useMemo(() => {
        return fields ? groupBy(fields, (val) => { var _a; return (_a = val.choice_set) === null || _a === void 0 ? void 0 : _a.uid; }) : null;
    }, [fields]);
    const listChoiceSetsApi = useListChoiceSets({}, REFETCH_CACHE_PARAMS);
    const choiceSets = (_d = (_c = listChoiceSetsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const choiceSetsFiltered = React.useMemo(() => {
        return choiceSets
            ? choiceSets
                .filter((x) => !search.trim() || x.name.toLowerCase().trim().includes(search.toLowerCase().trim()))
                .sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
            : null;
    }, [choiceSets, search]);
    const refresh = React.useCallback(() => {
        listChoiceSetsApi.refetch();
    }, [listChoiceSetsApi]);
    const handleSearchChanged = React.useCallback((e) => {
        setSearch(e.target.value);
    }, [setSearch]);
    if (listChoiceSetsApi.error) {
        return React.createElement(CoreError, { error: listChoiceSetsApi.error });
    }
    if (!choiceSets) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Alert, { severity: "warning" }, "Updating or removing choice sets will affect the behavior of the portal, which can introduce bugs if anything is incorrect. Be careful when making changes!")),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { style: {
                            flex: 1,
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(TextField, { size: "small", variant: "outlined", value: search, onChange: handleSearchChanged, slotProps: {
                                input: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Search, null))),
                                },
                            } })),
                    React.createElement(Grid2, { style: {
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(Tooltip, { title: "Refresh" },
                            React.createElement(IconButton, { onClick: refresh, tabIndex: -1, disabled: listChoiceSetsApi.isFetching },
                                React.createElement(Refresh, null))),
                        React.createElement(Tooltip, { title: "Add choice set" },
                            React.createElement(IconButton, { component: RouterLink, to: "/choice-sets/new", tabIndex: -1 },
                                React.createElement(Add, null))))))),
        React.createElement(Grid2, null, listChoiceSetsApi.isRefetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(List, { dense: true, component: "nav" }, (choiceSetsFiltered !== null && choiceSetsFiltered !== void 0 ? choiceSetsFiltered : []).map((obj) => (React.createElement(ListItemButton, { key: obj.uid, component: RouterLink, to: `/choice-sets/${obj.uid}` },
                React.createElement(ListItemText, { primary: obj.name, secondary: (fieldsByChoiceSet === null || fieldsByChoiceSet === void 0 ? void 0 : fieldsByChoiceSet[obj.uid]) ? fieldsByChoiceSet[obj.uid].map((f) => f.name).join(", ") : undefined }))))))));
};
export default ChoiceSetsTable;
