import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
const BulkDeleteConfirmDialog = (props) => {
    const { DialogProps, numBoxes, onCancel, onConfirm } = props;
    const validInput = `${numBoxes} box${numBoxes !== 1 ? "es" : ""}`;
    const [currentInput, setCurrentInput] = React.useState("");
    const handleInputChanged = React.useCallback((e) => {
        setCurrentInput(e.target.value);
    }, []);
    return (React.createElement(Dialog, Object.assign({ open: true, fullWidth: true, maxWidth: "xs" }, DialogProps),
        React.createElement(DialogTitle, null, "Are you sure?"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, null,
                        "Are you sure you want to delete ",
                        numBoxes,
                        " box",
                        numBoxes === 1 ? "" : "es",
                        "? You won't be able to undo this action.")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { dangerouslySetInnerHTML: {
                            __html: `Type <b>${validInput}</b> if you want to delete the ${numBoxes === 1 ? "box" : "boxes"}.`,
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { placeholder: validInput, fullWidth: true, variant: "standard", size: "small", value: currentInput, onChange: handleInputChanged })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onCancel, color: "inherit", variant: "text", tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onConfirm, color: "error", variant: "contained", disabled: currentInput !== validInput, tabIndex: -1 },
                "Delete ",
                validInput))));
};
export default BulkDeleteConfirmDialog;
