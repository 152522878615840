import * as React from "react";
import CoreDataGrid from "@app/common/grid/DataGrid";
const ScrollTrackingDatagrid = (props) => {
    const { columns } = props;
    const gridApi = props.apiRef;
    const [scrollPosition, setScrollPosition] = React.useState(null);
    const handleScroll = React.useCallback((params) => {
        setScrollPosition(params);
    }, [setScrollPosition]);
    React.useEffect(() => {
        if (gridApi.current) {
            gridApi.current.subscribeEvent("scrollPositionChange", handleScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridApi.current]);
    const gridKey = React.useMemo(() => {
        return columns.map((c) => c.field).join(",");
    }, [columns]);
    React.useEffect(() => {
        console.log({ scrollingPosition: scrollPosition });
        if (scrollPosition) {
            window.setTimeout(() => {
                gridApi.current.scroll(scrollPosition);
            }, 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridKey]);
    return React.createElement(CoreDataGrid, Object.assign({ key: gridKey }, props, { apiRef: gridApi }));
};
export default ScrollTrackingDatagrid;
