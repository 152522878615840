import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import Search from "@app/entrypoints/brokerage/container/Header/Search/Search";
import useSession from "@app/hooks/useSession";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { searchUsersBasic } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import MailScheduleBoxesTable from "./MailScheduleBoxesTable";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { MAIL_SCHEDULE_ENTITY_TYPES } from "../utils/utils";
const MailScheduleBoxesTableWrapper = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const [searchParams, _] = useSearchParams();
    const updateSearch = useUpdateSearch();
    const listUsersApi = usePostQuery(searchUsersBasic, { limit: 1000, disable_sort: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const agentUid = (_b = searchParams.get("agent")) !== null && _b !== void 0 ? _b : null;
    const pipeline = ((_c = searchParams.get("pipeline")) !== null && _c !== void 0 ? _c : null);
    const boxUid = (_d = searchParams.get("box")) !== null && _d !== void 0 ? _d : null;
    const [selectedBox, setSelectedBox] = React.useState(null);
    const session = useSession();
    const handleSelectedAgentChanged = React.useCallback((e) => {
        updateSearch("agent", e.target.value || null, "box", null);
        setSelectedBox(null);
    }, [updateSearch]);
    const handleSelectedPipelineChanged = React.useCallback((e) => {
        updateSearch("pipeline", e.target.value || null, "box", null);
        setSelectedBox(null);
    }, [updateSearch]);
    const handleSelectedBoxChanged = React.useCallback((box) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        setSelectedBox(box);
        updateSearch("box", (_m = (_k = (_h = (_f = (_d = (_b = (_a = box === null || box === void 0 ? void 0 : box.buyerContract) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : (_c = box === null || box === void 0 ? void 0 : box.buyerLead) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : (_e = box === null || box === void 0 ? void 0 : box.sellerLead) === null || _e === void 0 ? void 0 : _e.uid) !== null && _f !== void 0 ? _f : (_g = box === null || box === void 0 ? void 0 : box.sellerContract) === null || _g === void 0 ? void 0 : _g.uid) !== null && _h !== void 0 ? _h : (_j = box === null || box === void 0 ? void 0 : box.recruit) === null || _j === void 0 ? void 0 : _j.uid) !== null && _k !== void 0 ? _k : (_l = box === null || box === void 0 ? void 0 : box.user) === null || _l === void 0 ? void 0 : _l.uid) !== null && _m !== void 0 ? _m : null);
    }, [updateSearch]);
    return (React.createElement(Grid, { container: true, spacing: 2, style: {
            width: "100%",
            height: "100%",
            overflowX: "hidden",
            margin: 0,
        }, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    ((_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.hasRole(["Superadmin", "Admin"])) && (React.createElement(Grid, { item: true, xs: 12, md: 4, style: { marginTop: "auto" } },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Agent", select: true, value: agentUid !== null && agentUid !== void 0 ? agentUid : "", onChange: handleSelectedAgentChanged },
                            React.createElement(MenuItem, { key: -1, value: "" }, "Any"),
                            (users !== null && users !== void 0 ? users : [])
                                .filter((user) => user.email)
                                .map((user) => (React.createElement(MenuItem, { key: user.uid, value: user.uid },
                                user.first_name,
                                " ",
                                user.last_name)))))),
                    React.createElement(Grid, { item: true, xs: 12, md: 4, style: { marginTop: "auto" } },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Pipeline", select: true, value: pipeline !== null && pipeline !== void 0 ? pipeline : "", onChange: handleSelectedPipelineChanged },
                            React.createElement(MenuItem, { key: -1, value: "" }, "Any"),
                            MAIL_SCHEDULE_ENTITY_TYPES.map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
                    React.createElement(Grid, { item: true, xs: 12, md: 4, style: { marginTop: "auto" } },
                        React.createElement(Search
                        // @ts-expect-error: search types
                        , { 
                            // @ts-expect-error: search types
                            searchTypes: [
                                pipeline === "Buyer Contract" || !pipeline ? "buyerContracts" : null,
                                pipeline === "Buyer Lead" || !pipeline ? "buyerLeads" : null,
                                pipeline === "Listing" || !pipeline ? "sellerContracts" : null,
                                pipeline === "Seller Lead" || !pipeline ? "sellerLeads" : null,
                                pipeline === "User" || !pipeline ? "users" : null,
                                pipeline === "Recruit" || !pipeline ? "recruits" : null,
                            ].filter((o) => o), onItemSelected: handleSelectedBoxChanged, TextFieldProps: {
                                size: "small",
                                placeholder: "Search for a box",
                            }, variant: "standard", defaultSearchString: (_s = (_q = (_o = (_l = (_j = (_g = (_f = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.buyerContract) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : (_h = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.buyerLead) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : (_k = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.contact) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : (_m = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.recruit) === null || _m === void 0 ? void 0 : _m.name) !== null && _o !== void 0 ? _o : (_p = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.sellerContract) === null || _p === void 0 ? void 0 : _p.name) !== null && _q !== void 0 ? _q : (_r = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.sellerLead) === null || _r === void 0 ? void 0 : _r.name) !== null && _s !== void 0 ? _s : (_t = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.user) === null || _t === void 0 ? void 0 : _t.name, showNoResults: !selectedBox }))))),
        boxUid && (React.createElement(Grid, { item: true, style: { flex: 1, overflow: "hidden" } },
            React.createElement(MailScheduleBoxesTable, { entity: { uid: boxUid, entityType: pipeline } })))));
};
export default MailScheduleBoxesTableWrapper;
