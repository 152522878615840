import { doReplacements } from "../../utils/validation";
// eslint-disable-next-line import/prefer-default-export
export const saturateSectionWithValidation = (options) => {
    var _a, _b;
    const { ctx, section, stages, user } = options;
    const context = Object.assign(Object.assign({}, ctx), { USER: (_a = user === null || user === void 0 ? void 0 : user.asDict) !== null && _a !== void 0 ? _a : {} });
    let visibleValidationFn = null;
    let visibleValidationFnError = null;
    let visibleValidationResult = true;
    if (((_b = section.validation) === null || _b === void 0 ? void 0 : _b.trim()) && context) {
        const functionStr = doReplacements(section.validation, context, stages);
        try {
            // eslint-disable-next-line no-new-func
            visibleValidationFn = new Function(functionStr);
            visibleValidationResult = visibleValidationFn();
        }
        catch (err) {
            visibleValidationFnError = err;
        }
    }
    return Object.assign(Object.assign({}, section), { visibleValidation: {
            fn: visibleValidationFn,
            error: visibleValidationFnError,
            result: visibleValidationResult,
        } });
};
