import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Grid2, MenuItem, Typography } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDialog from "@app/common/CoreDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { uploadWordDocTemplate } from "@app/orval/api/templates-word-doc-templates";
import { fileToBase64, onUploadButtonClick } from "@app/util/Utils";
const TemplatesDialog = (props) => {
    const { onCancel, onUploadComplete, open, templateType, templateTypes } = props;
    const [currentTemplateType, setCurrentTemplateType] = React.useState(templateType);
    const [file, setFile] = React.useState(null);
    const [isSaving, setIsSaving] = useStateWithCallback(false);
    const errorDialog = useErrorDialog();
    const isValid = React.useMemo(() => {
        // if template type props is null, show dropdown and require selection
        return !!(currentTemplateType && file);
    }, [currentTemplateType, file]);
    const dialogTitle = React.useMemo(() => {
        return `Upload a New File Template${currentTemplateType ? ` for ${currentTemplateType}` : ""}`;
    }, [currentTemplateType]);
    const onUpload = React.useCallback(() => {
        setIsSaving(true, () => {
            if (file && currentTemplateType) {
                fileToBase64(file).then((result) => {
                    const data = {
                        content: result,
                        filename: file.name,
                    };
                    uploadWordDocTemplate(currentTemplateType, data)
                        .then((postResult) => {
                        setIsSaving(false);
                        setCurrentTemplateType(null);
                        onUploadComplete(postResult.data);
                    })
                        .catch((error) => {
                        errorDialog(error);
                    })
                        .then(() => {
                        setIsSaving(false);
                    });
                });
            }
        });
    }, [currentTemplateType, errorDialog, file, onUploadComplete, setIsSaving]);
    const onTemplateTypeChange = React.useCallback((type) => {
        setCurrentTemplateType(type);
    }, []);
    const onFileChange = React.useCallback((e) => {
        const fileUpload = e.target.files ? e.target.files[0] : null;
        setFile(fileUpload);
    }, []);
    console.log({ isValid });
    return (React.createElement(CoreDialog, { open: open, maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, dialogTitle),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2, style: { padding: "8px" } },
                !templateType && (React.createElement(Grid2, { size: 12 },
                    React.createElement(CoreChoiceField, { variant: "standard", required: true, fullWidth: true, value: currentTemplateType !== null && currentTemplateType !== void 0 ? currentTemplateType : "", onChoiceChange: onTemplateTypeChange }, templateTypes.map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name)))))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement("input", { type: "file", id: "word-doc-template-upload-input", accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword", style: { display: "none" }, onChange: onFileChange }),
                    React.createElement(Button, { color: "primary", variant: "contained", onClick: onUploadButtonClick("word-doc-template-upload-input"), disabled: !!isSaving }, "Browse Files")),
                file && (React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, file.name),
                    React.createElement(Typography, null, `${file.size / 1000}KB`))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "inherit", onClick: onCancel }, "Cancel"),
            React.createElement(Button, { onClick: onUpload, color: "primary", disabled: !isValid || isSaving }, "Upload"))));
};
export default TemplatesDialog;
