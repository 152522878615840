import * as React from "react";
import { Alert, Grid2, Typography } from "@mui/material";
import dayjs from "dayjs";
const NotificationChronologyItem = (props) => {
    const { notification } = props;
    const description = notification.description.replace(/\@\[(.*?)\]\((.*?)\)/g, "@$1");
    return (React.createElement(Alert, { severity: "info", variant: "outlined", sx: { ".MuiAlert-message": { width: "100%" } } },
        React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, notification.title)),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "body2", style: { whiteSpace: "pre-wrap" } }, description)))),
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "caption" }, dayjs(notification.created_at).format("M/D/YYYY h:mm A"))))));
};
export default NotificationChronologyItem;
