import * as React from "react";
import { Save, ArrowBack } from "@mui/icons-material";
import { Grid2, Button, Divider, Typography, IconButton, Tooltip, Alert } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreNumberField from "@app/common/CoreNumberField";
import Loading from "@app/common/Loading";
import useErrorHandler from "@app/hooks/useErrorHandler";
import useStateCallback from "@app/hooks/useStateCallback";
import { useBulkUpdateDashscoreComponentValues, useListDashscoreComponentValues, useLoadDashscoreSettings, useSaveDashscoreSettings, } from "@app/orval/api/dashscore-settings";
const DashScoreSettingsForm = () => {
    var _a, _b;
    const [settings, setSettings] = React.useState(null);
    const [componentValueUpdates, setComponentValueUpdates] = React.useState(null);
    const [isSaving, setIsSaving] = useStateCallback(false);
    const { handleError } = useErrorHandler();
    const dashScoreSettingsLoadApi = useLoadDashscoreSettings();
    const dashScoreSettingsSaveApi = useSaveDashscoreSettings();
    const dashScoreComponentValueBulkUpdateApi = useBulkUpdateDashscoreComponentValues();
    const dashScoreComponentValuesListApi = useListDashscoreComponentValues();
    const dashScoreComponentValues = (_b = (_a = dashScoreComponentValuesListApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    React.useEffect(() => {
        var _a, _b;
        if (!settings) {
            setSettings((_b = (_a = dashScoreSettingsLoadApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
        }
    }, [settings, setSettings, dashScoreSettingsLoadApi]);
    const isValid = React.useMemo(() => {
        if (!settings) {
            return false;
        }
        return !Object.keys(settings).some((key) => settings[key] == null);
    }, [settings]);
    const dashScoreComponentValuesSum = React.useMemo(() => {
        if (!dashScoreComponentValues) {
            return null;
        }
        const values = dashScoreComponentValues.map((val) => {
            const updatedValue = componentValueUpdates === null || componentValueUpdates === void 0 ? void 0 : componentValueUpdates.find((x) => x.name === val.name);
            return updatedValue !== null && updatedValue !== void 0 ? updatedValue : val;
        });
        return values.reduce((acc, val) => acc + val.maximum_value, 0);
    }, [componentValueUpdates, dashScoreComponentValues]);
    const saveComponentValues = React.useCallback(() => {
        const loadingSnackbar = enqueueSnackbar("Saving score component values...", { variant: "info", persist: true });
        if (componentValueUpdates) {
            dashScoreComponentValueBulkUpdateApi
                .mutateAsync({
                data: {
                    items: componentValueUpdates,
                },
            })
                .then(() => {
                enqueueSnackbar("Component values have been saved.", { variant: "success" });
            })
                .catch((error) => {
                handleError(error, "show-dialog");
            })
                .finally(() => {
                setIsSaving(false);
                closeSnackbar(loadingSnackbar);
            });
        }
        else {
            enqueueSnackbar("Score component values not found.", { variant: "info", persist: true });
            closeSnackbar(loadingSnackbar);
        }
    }, [componentValueUpdates, dashScoreComponentValueBulkUpdateApi, handleError, setIsSaving]);
    const save = React.useCallback(() => {
        const loadingSnackbar = enqueueSnackbar("Saving...", { variant: "info", persist: true });
        if (settings) {
            setIsSaving(true, () => {
                if (componentValueUpdates) {
                    saveComponentValues();
                }
                dashScoreSettingsSaveApi
                    .mutateAsync({
                    data: settings,
                })
                    .then(() => {
                    enqueueSnackbar("Settings have been saved.", { variant: "success" });
                })
                    .catch((error) => {
                    handleError(error, "show-dialog");
                })
                    .finally(() => {
                    setIsSaving(false);
                    closeSnackbar(loadingSnackbar);
                });
            });
        }
        else {
            enqueueSnackbar("No settings found.", { variant: "info", persist: true });
        }
    }, [settings, setIsSaving, componentValueUpdates, dashScoreSettingsSaveApi, saveComponentValues, handleError]);
    const onComponentValueUpdate = React.useCallback((name, valueType) => (value) => {
        const savedValue = dashScoreComponentValues === null || dashScoreComponentValues === void 0 ? void 0 : dashScoreComponentValues.find((x) => x.name === name);
        if (savedValue) {
            setComponentValueUpdates((prev) => {
                const previousUpdate = prev === null || prev === void 0 ? void 0 : prev.find((x) => x.name === name);
                if (previousUpdate) {
                    return (prev !== null && prev !== void 0 ? prev : []).map((x) => {
                        if (x.name === name) {
                            return Object.assign(Object.assign({}, x), { [`${valueType}_value`]: value });
                        }
                        return x;
                    });
                }
                return [
                    ...(prev !== null && prev !== void 0 ? prev : []),
                    Object.assign(Object.assign({}, savedValue), { [`${valueType}_value`]: value }),
                ];
            });
        }
    }, [dashScoreComponentValues]);
    const setExpectedConversionRate = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { expected_conversion_rate: val });
        });
    }, [setSettings]);
    const setPCATarget = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pca_target: val });
        });
    }, [setSettings]);
    const setPIGTarget = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pig_target: val });
        });
    }, [setSettings]);
    const setZHLAttachTarget = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_attach_target: val });
        });
    }, [setSettings]);
    const setCSATScoreGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { csat_score_green_value: val });
        });
    }, [setSettings]);
    const setCSATScoreRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { csat_score_red_value: val });
        });
    }, [setSettings]);
    const setFlexPercentToTrxnTargetGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { flex_percent_to_trxn_target_score_green_value: val });
        });
    }, [setSettings]);
    const setFlexPercentToTrxnTargetRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { flex_percent_to_trxn_target_score_red_value: val });
        });
    }, [setSettings]);
    const setNumSLAViolationsGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { num_sla_violations_green_value: val });
        });
    }, [setSettings]);
    const setNumSLAViolationsRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { num_sla_violations_red_value: val });
        });
    }, [setSettings]);
    const setDaysWithSLAViolationsGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { days_with_sla_violations_green_value: val });
        });
    }, [setSettings]);
    const setDaysWithSLAViolationsRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { days_with_sla_violations_red_value: val });
        });
    }, [setSettings]);
    const setPickUpScoreGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pick_up_score_green_value: val });
        });
    }, [setSettings]);
    const setPickUpScoreRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pick_up_score_red_value: val });
        });
    }, [setSettings]);
    const setAppointmentScoreGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { appointment_score_green_value: val });
        });
    }, [setSettings]);
    const setAppointmentScoreRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { appointment_score_red_value: val });
        });
    }, [setSettings]);
    const setMetRateGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { met_rate_score_green_value: val });
        });
    }, [setSettings]);
    const setMetRateRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { met_rate_score_red_value: val });
        });
    }, [setSettings]);
    const setShowingHomesGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { showing_homes_score_green_value: val });
        });
    }, [setSettings]);
    const setShowingHomesRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { showing_homes_score_red_value: val });
        });
    }, [setSettings]);
    const setOfferPercentGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { offer_percent_score_green_value: val });
        });
    }, [setSettings]);
    const setOfferPercentRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { offer_percent_score_red_value: val });
        });
    }, [setSettings]);
    const setCVRScoreGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { cvr_score_green_value: val });
        });
    }, [setSettings]);
    const setCVRScoreRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { cvr_score_red_value: val });
        });
    }, [setSettings]);
    const setZHLEngagedTransferGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_engaged_transfer_score_green_value: val });
        });
    }, [setSettings]);
    const setZHLEngagedTransferRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_engaged_transfer_score_red_value: val });
        });
    }, [setSettings]);
    const setZHLTransferGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_transfer_score_green_value: val });
        });
    }, [setSettings]);
    const setZHLTransferRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_transfer_score_red_value: val });
        });
    }, [setSettings]);
    const setLockPercentGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_lock_percent_score_green_value: val });
        });
    }, [setSettings]);
    const setLockPercentRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_lock_percent_score_red_value: val });
        });
    }, [setSettings]);
    const setFundedPercentGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_funded_percent_score_green_value: val });
        });
    }, [setSettings]);
    const setFundedPercentRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_funded_percent_score_red_value: val });
        });
    }, [setSettings]);
    const setALMAppointmentRateGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { alm_appointment_rate_green_value: val });
        });
    }, [setSettings]);
    const setALMAppointmentRateRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { alm_appointment_rate_red_value: val });
        });
    }, [setSettings]);
    const setDashScoreGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { dashscore_green_value: val });
        });
    }, [setSettings]);
    const setDashScoreRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { dashscore_green_value: val });
        });
    }, [setSettings]);
    const setZHLPercentToAttachTargetGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_percent_to_attach_target_green_value: val });
        });
    }, [setSettings]);
    const setZHLPercentToAttachTargetRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { zhl_percent_to_attach_target_red_value: val });
        });
    }, [setSettings]);
    const setPCAPercentToAttachTargetGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pca_percent_to_attach_target_green_value: val });
        });
    }, [setSettings]);
    const setPCAPercentToAttachTargetRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pca_percent_to_attach_target_red_value: val });
        });
    }, [setSettings]);
    const setPIGPercentToAttachTargetGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pig_percent_to_attach_target_green_value: val });
        });
    }, [setSettings]);
    const setPIGPercentToAttachTargetRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pig_percent_to_attach_target_red_value: val });
        });
    }, [setSettings]);
    const setPIGETRGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pig_etr_green_value: val });
        });
    }, [setSettings]);
    const setPIGETRRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { pig_etr_red_value: val });
        });
    }, [setSettings]);
    const setFlexPercentToUpstreamMetricTargetGreenValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { flex_percent_to_upstream_metric_target_green_value: val });
        });
    }, [setSettings]);
    const setFlexPercentToUpstreamMetricTargetRedValue = React.useCallback((val) => {
        setSettings((prevState) => {
            return Object.assign(Object.assign({}, prevState), { flex_percent_to_upstream_metric_target_red_value: val });
        });
    }, [setSettings]);
    const loading = [
        {
            label: "Loading DASHscore settings...",
            status: dashScoreSettingsLoadApi.status,
            error: dashScoreSettingsLoadApi.error,
        },
    ];
    if (loading.find((x) => x.status !== "success") || !settings) {
        return React.createElement(Loading, { items: loading });
    }
    const sectionHeaderStyle = { fontWeight: "bold" };
    console.log({ settings });
    console.log({ dashScoreComponentValues, componentValueUpdates });
    return (React.createElement(Grid2, { container: true, spacing: 2, style: { padding: "8px" } },
        React.createElement(Grid2, { container: true, size: 12 },
            React.createElement(Typography, { variant: "h6" }, "DASHscore Settings"),
            React.createElement(Tooltip, { title: "Back to DASHscore" },
                React.createElement(IconButton, { href: "/reporting/dashscore", style: { marginLeft: "auto" } },
                    React.createElement(ArrowBack, { fontSize: "small" })))),
        dashScoreComponentValuesSum != null && dashScoreComponentValuesSum !== 10 && (React.createElement(Grid2, { size: 12 },
            React.createElement(Alert, { severity: "warning" },
                React.createElement(Typography, null,
                    React.createElement("strong", null, "Warning:"),
                    " DASHscore Component Values do not total 10.")))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "DASHscore Component Values"),
            React.createElement(Alert, { severity: "info" },
                React.createElement(Typography, null, "Use the below section to adjust how many points each component contributes to the DASHscore. The maximum values (for a green score) MUST be set. The minimum values (for a red score) default to 0. The medium values (for a yellow score) default to half the maximum."))),
        (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : []).map((val) => {
            var _a, _b;
            return (React.createElement(Grid2, { key: `${val.name}_component_value_settings`, container: true, size: 12 },
                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, "Minimum points given if the value is in the red. Defaults to 0.") },
                        React.createElement(CoreNumberField, { size: "small", variant: "standard", label: `${val.name} Minimum`, fullWidth: true, format: "0,0.0", number: (_a = val.minimum_value) !== null && _a !== void 0 ? _a : null, onNumberChange: onComponentValueUpdate(val.name, "minimum"), min: 0 }))),
                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                    React.createElement(CoreNumberField, { size: "small", variant: "standard", label: `${val.name} Medium`, fullWidth: true, format: "0,0.0", number: (_b = val.medium_value) !== null && _b !== void 0 ? _b : null, onNumberChange: onComponentValueUpdate(val.name, "medium"), min: 0 })),
                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                    React.createElement(CoreNumberField, { size: "small", variant: "standard", label: `${val.name} Maximum`, fullWidth: true, format: "0,0.0", number: val.maximum_value, onNumberChange: onComponentValueUpdate(val.name, "maximum"), min: 0 }))));
        }),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "Targets")),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Expected Conversion Rate", fullWidth: true, format: "0,0.00%", number: settings.expected_conversion_rate, onNumberChange: setExpectedConversionRate, min: 0, max: 1 })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "PCA Target", fullWidth: true, format: "0,0.00%", number: settings.pca_target, onNumberChange: setPCATarget, min: 0 })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "PIG Target", fullWidth: true, format: "0,0.00%", number: settings.pig_target, onNumberChange: setPIGTarget, min: 0 })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "ZHL Attach Target", fullWidth: true, format: "0,0.00%", number: settings.zhl_attach_target, onNumberChange: setZHLAttachTarget, min: 0 })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "DASHscore Maximum Red Value", fullWidth: true, format: "0,0", number: settings.dashscore_red_value, onNumberChange: setDashScoreRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "DASHscore Minimum Green Value", fullWidth: true, format: "0,0", number: settings.dashscore_green_value, onNumberChange: setDashScoreGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "FLEX")),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Percent to Trxn Target Score Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.flex_percent_to_trxn_target_score_red_value, onNumberChange: setFlexPercentToTrxnTargetRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Percent to Trxn Target Score Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.flex_percent_to_trxn_target_score_green_value, onNumberChange: setFlexPercentToTrxnTargetGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Percent to Upstream Metric Target Score Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.flex_percent_to_upstream_metric_target_red_value, onNumberChange: setFlexPercentToUpstreamMetricTargetRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Percent to Upstream Metric Target Score Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.flex_percent_to_upstream_metric_target_green_value, onNumberChange: setFlexPercentToUpstreamMetricTargetGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Pick Up Rate Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.pick_up_score_red_value, onNumberChange: setPickUpScoreRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Pick Up Rate Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.pick_up_score_green_value, onNumberChange: setPickUpScoreGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "ALM Appointment Rate Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.alm_appointment_rate_red_value, onNumberChange: setALMAppointmentRateRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "ALM Appointment Rate Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.alm_appointment_rate_green_value, onNumberChange: setALMAppointmentRateGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Appointment Rate Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.appointment_score_red_value, onNumberChange: setAppointmentScoreRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Appointment Rate Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.appointment_score_green_value, onNumberChange: setAppointmentScoreGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Met Rate Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.met_rate_score_red_value, onNumberChange: setMetRateRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Met Rate Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.met_rate_score_green_value, onNumberChange: setMetRateGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Showing Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.showing_homes_score_red_value, onNumberChange: setShowingHomesRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Showing Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.showing_homes_score_green_value, onNumberChange: setShowingHomesGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Offer Percent Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.offer_percent_score_green_value, onNumberChange: setOfferPercentRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Offer Percent Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.offer_percent_score_green_value, onNumberChange: setOfferPercentGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "CVR Score Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.cvr_score_red_value, onNumberChange: setCVRScoreRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "CVR Score Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.cvr_score_red_value, onNumberChange: setCVRScoreGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "ZHL")),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Engaged Transfer Score Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_engaged_transfer_score_red_value, onNumberChange: setZHLEngagedTransferRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Engaged Transfer Score Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_engaged_transfer_score_green_value, onNumberChange: setZHLEngagedTransferGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Transfer Score Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_transfer_score_red_value, onNumberChange: setZHLTransferRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Transfer Score Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_transfer_score_green_value, onNumberChange: setZHLTransferGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Lock Rate Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_lock_percent_score_red_value, onNumberChange: setLockPercentRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Lock Rate Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_lock_percent_score_green_value, onNumberChange: setLockPercentGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Funded Rate Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_funded_percent_score_red_value, onNumberChange: setFundedPercentRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Funded Rate Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_funded_percent_score_green_value, onNumberChange: setFundedPercentGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "% to Attach Target Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_percent_to_attach_target_red_value, onNumberChange: setZHLPercentToAttachTargetRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "% to Attach Target Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.zhl_percent_to_attach_target_green_value, onNumberChange: setZHLPercentToAttachTargetGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "CSAT")),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "CSAT Score Maximum Red Value", fullWidth: true, format: "0,0", number: settings.csat_score_red_value, onNumberChange: setCSATScoreRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "CSAT Score Minimum Green Value", fullWidth: true, format: "0,0", number: settings.csat_score_green_value, onNumberChange: setCSATScoreGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "PCA")),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "% to Attach Target Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.pca_percent_to_attach_target_red_value, onNumberChange: setPCAPercentToAttachTargetRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "% to Attach Target Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.pca_percent_to_attach_target_green_value, onNumberChange: setPCAPercentToAttachTargetGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "SLA")),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Number of SLA Violations Minimum Red Value", fullWidth: true, format: "0,0", number: settings.num_sla_violations_red_value, onNumberChange: setNumSLAViolationsRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Number of SLA Violations Maximum Green Value", fullWidth: true, format: "0,0", number: settings.num_sla_violations_green_value, onNumberChange: setNumSLAViolationsGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Number of Days with SLA Violations Minimum Red Value", fullWidth: true, format: "0,0", number: settings.days_with_sla_violations_red_value, onNumberChange: setDaysWithSLAViolationsRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "Number of Days with SLA Violations Maximum Green Value", fullWidth: true, format: "0,0", number: settings.days_with_sla_violations_green_value, onNumberChange: setDaysWithSLAViolationsGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Typography, { style: sectionHeaderStyle }, "PIG")),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "% to Attach Target Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.pig_percent_to_attach_target_red_value, onNumberChange: setPIGPercentToAttachTargetRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "% to Attach Target Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.pig_percent_to_attach_target_green_value, onNumberChange: setPIGPercentToAttachTargetGreenValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "ETR Maximum Red Value", fullWidth: true, format: "0,0.00%", number: settings.pig_etr_red_value, onNumberChange: setPIGETRRedValue })),
        React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", label: "ETR Minimum Green Value", fullWidth: true, format: "0,0.00%", number: settings.pig_etr_green_value, onNumberChange: setPIGETRGreenValue })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Button, { size: "small", variant: "contained", color: "primary", onClick: save, startIcon: React.createElement(Save, { fontSize: "small" }), disabled: isSaving || !isValid }, "Save"))));
};
export default DashScoreSettingsForm;
