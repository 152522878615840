import * as React from "react";
import { Alert, Grid2 } from "@mui/material";
import DOMPurify from "dompurify";
const MailScheduleQuillFallback = (props) => {
    const { value } = props;
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
        React.createElement(Grid2, null,
            React.createElement(Alert, { severity: "error" }, "An error occurred renderering the text editor.")),
        value && (React.createElement(Grid2, null,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(value) } })))));
};
export default MailScheduleQuillFallback;
