import * as React from "react";
import { Box, Divider, Grid2, LinearProgress, List, ListItem, ListItemText, ListSubheader, Paper, Typography, } from "@mui/material";
import styled from "styled-components";
import RouterLink from "@app/common/RouterLink";
import { countBuyerContracts, searchBuyerContracts } from "@app/orval/api/buyer-contracts";
import { countBuyerLeads, searchBuyerLeads } from "@app/orval/api/buyer-leads";
import { countRecruits, searchRecruits } from "@app/orval/api/recruits";
import { countSellerContracts, searchSellerContracts } from "@app/orval/api/seller-contracts";
import { countSellerLeads, searchSellerLeads } from "@app/orval/api/seller-leads";
import { countUsers, searchUsers } from "@app/orval/api/users";
import { useListViewsSimple } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import CommentButton from "./pinned-boxes/CommentContactButton";
import EmailContactButton from "./pinned-boxes/EmailContactButton";
import PhoneContactButton from "./pinned-boxes/PhoneContactButton";
import PinnedBoxSubheader from "./PinnedBoxSubheader";
import usePostQuery from "../usePostQuery";
const StyledList = styled(List) `
  & .MuiListSubheader-root {
    background: white;
    height: 32px;
  };
  & .MuiListItemText-root {
    max-width: calc(100% - 45px);
  };
  & .MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  };
`;
const PinnedBoxes = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const listPinnedViewsApi = useListViewsSimple({ is_pinned: true }, INFINITE_CACHE_PARAMS);
    const pinnedViews = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listPinnedViewsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    }, [(_a = listPinnedViewsApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const getPinnedBuyerLeadsApi = usePostQuery(searchBuyerLeads, { is_pinned: true, disable_sort: true, include_view_membership: true, limit: 50 }, REFETCH_CACHE_PARAMS);
    const pinnedBuyerLeads = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getPinnedBuyerLeadsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_b = getPinnedBuyerLeadsApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const getPinnedSellerLeadsApi = usePostQuery(searchSellerLeads, { is_pinned: true, disable_sort: true, include_view_membership: true, limit: 50 }, REFETCH_CACHE_PARAMS);
    const pinnedSellerLeads = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getPinnedSellerLeadsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_c = getPinnedSellerLeadsApi.data) === null || _c === void 0 ? void 0 : _c.data]);
    const getPinnedBuyerContractsApi = usePostQuery(searchBuyerContracts, { is_pinned: true, disable_sort: true, include_view_membership: true, limit: 50 }, REFETCH_CACHE_PARAMS);
    const pinnedBuyerContracts = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getPinnedBuyerContractsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_d = getPinnedBuyerContractsApi.data) === null || _d === void 0 ? void 0 : _d.data]);
    const getPinnedSellerContractsApi = usePostQuery(searchSellerContracts, { is_pinned: true, disable_sort: true, include_view_membership: true, limit: 50 }, REFETCH_CACHE_PARAMS);
    const pinnedSellerContracts = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getPinnedSellerContractsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_e = getPinnedSellerContractsApi.data) === null || _e === void 0 ? void 0 : _e.data]);
    const getPinnedUsersApi = usePostQuery(searchUsers, { is_pinned: true, disable_sort: true, include_view_membership: true, limit: 50 }, REFETCH_CACHE_PARAMS);
    const pinnedUsers = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getPinnedUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_f = getPinnedUsersApi.data) === null || _f === void 0 ? void 0 : _f.data]);
    const getPinnedRecruitsApi = usePostQuery(searchRecruits, { is_pinned: true, disable_sort: true, include_view_membership: true, limit: 50 }, REFETCH_CACHE_PARAMS);
    const pinnedRecruits = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = getPinnedRecruitsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_g = getPinnedRecruitsApi.data) === null || _g === void 0 ? void 0 : _g.data]);
    const countPinnedBuyerLeadsApi = usePostQuery(countBuyerLeads, { is_pinned: true }, REFETCH_CACHE_PARAMS);
    const numPinnedBuyerLeads = (_j = (_h = countPinnedBuyerLeadsApi.data) === null || _h === void 0 ? void 0 : _h.data.count) !== null && _j !== void 0 ? _j : null;
    const countPinnedSellerLeadsApi = usePostQuery(countSellerLeads, { is_pinned: true }, REFETCH_CACHE_PARAMS);
    const numPinnedSellerLeads = (_l = (_k = countPinnedSellerLeadsApi.data) === null || _k === void 0 ? void 0 : _k.data.count) !== null && _l !== void 0 ? _l : null;
    const countPinnedBuyerContracts = usePostQuery(countBuyerContracts, { is_pinned: true }, REFETCH_CACHE_PARAMS);
    const numPinnedBuyerContracts = (_o = (_m = countPinnedBuyerContracts.data) === null || _m === void 0 ? void 0 : _m.data.count) !== null && _o !== void 0 ? _o : null;
    const countPinnedSellerContracts = usePostQuery(countSellerContracts, { is_pinned: true }, REFETCH_CACHE_PARAMS);
    const numPinnedSellerContracts = (_q = (_p = countPinnedSellerContracts.data) === null || _p === void 0 ? void 0 : _p.data.count) !== null && _q !== void 0 ? _q : null;
    const countPinnedUsers = usePostQuery(countUsers, { is_pinned: true }, REFETCH_CACHE_PARAMS);
    const numPinnedUsers = (_s = (_r = countPinnedUsers.data) === null || _r === void 0 ? void 0 : _r.data.count) !== null && _s !== void 0 ? _s : null;
    const countPinnedRecruits = usePostQuery(countRecruits, { is_pinned: true }, REFETCH_CACHE_PARAMS);
    const numPinnedRecruits = (_u = (_t = countPinnedRecruits.data) === null || _t === void 0 ? void 0 : _t.data.count) !== null && _u !== void 0 ? _u : null;
    const isLoading = getPinnedBuyerLeadsApi.isFetching ||
        getPinnedSellerLeadsApi.isFetching ||
        getPinnedBuyerContractsApi.isFetching ||
        getPinnedSellerContractsApi.isFetching ||
        getPinnedUsersApi.isFetching ||
        getPinnedRecruitsApi.isFetching;
    const count = (numPinnedBuyerLeads !== null && numPinnedBuyerLeads !== void 0 ? numPinnedBuyerLeads : 0) +
        (numPinnedSellerLeads !== null && numPinnedSellerLeads !== void 0 ? numPinnedSellerLeads : 0) +
        (numPinnedBuyerContracts !== null && numPinnedBuyerContracts !== void 0 ? numPinnedBuyerContracts : 0) +
        (numPinnedSellerContracts !== null && numPinnedSellerContracts !== void 0 ? numPinnedSellerContracts : 0) +
        (numPinnedUsers !== null && numPinnedUsers !== void 0 ? numPinnedUsers : 0) +
        (numPinnedRecruits !== null && numPinnedRecruits !== void 0 ? numPinnedRecruits : 0);
    const allPinnedViewUids = React.useMemo(() => {
        return new Set(pinnedViews.map((x) => x.uid));
    }, [pinnedViews]);
    const allViewUids = React.useMemo(() => {
        const res = new Set([
            ...(pinnedBuyerLeads !== null && pinnedBuyerLeads !== void 0 ? pinnedBuyerLeads : [])
                .map((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.filter((v) => v.is_in_view && allPinnedViewUids.has(v.view.uid)).map((v) => v.view.uid); })
                .flat(100),
            ...(pinnedSellerLeads !== null && pinnedSellerLeads !== void 0 ? pinnedSellerLeads : [])
                .map((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.filter((v) => v.is_in_view && allPinnedViewUids.has(v.view.uid)).map((v) => v.view.uid); })
                .flat(100),
            ...(pinnedBuyerContracts !== null && pinnedBuyerContracts !== void 0 ? pinnedBuyerContracts : [])
                .map((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.filter((v) => v.is_in_view && allPinnedViewUids.has(v.view.uid)).map((v) => v.view.uid); })
                .flat(100),
            ...(pinnedSellerContracts !== null && pinnedSellerContracts !== void 0 ? pinnedSellerContracts : [])
                .map((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.filter((v) => v.is_in_view && allPinnedViewUids.has(v.view.uid)).map((v) => v.view.uid); })
                .flat(100),
            ...(pinnedUsers !== null && pinnedUsers !== void 0 ? pinnedUsers : [])
                .map((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.filter((v) => v.is_in_view && allPinnedViewUids.has(v.view.uid)).map((v) => v.view.uid); })
                .flat(100),
            ...(pinnedRecruits !== null && pinnedRecruits !== void 0 ? pinnedRecruits : [])
                .map((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.filter((v) => v.is_in_view && allPinnedViewUids.has(v.view.uid)).map((v) => v.view.uid); })
                .flat(100),
        ]);
        return res;
    }, [
        allPinnedViewUids,
        pinnedBuyerContracts,
        pinnedBuyerLeads,
        pinnedSellerContracts,
        pinnedSellerLeads,
        pinnedUsers,
        pinnedRecruits,
    ]);
    const allPinnedViewsWithBoxes = React.useMemo(() => {
        return pinnedViews
            .filter((x) => allViewUids.has(x.uid))
            .sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
    }, [allViewUids, pinnedViews]);
    const allPinnedBoxPaths = React.useMemo(() => {
        return [
            ...new Set([
                ...(pinnedBuyerLeads !== null && pinnedBuyerLeads !== void 0 ? pinnedBuyerLeads : []).map((x) => `/buyers/${x.uid}`),
                ...(pinnedSellerLeads !== null && pinnedSellerLeads !== void 0 ? pinnedSellerLeads : []).map((x) => `/sellers/${x.uid}`),
                ...(pinnedBuyerContracts !== null && pinnedBuyerContracts !== void 0 ? pinnedBuyerContracts : []).map((x) => `/buyers/${x.buyer_lead_uid}`),
                ...(pinnedSellerContracts !== null && pinnedSellerContracts !== void 0 ? pinnedSellerContracts : []).map((x) => `/sellers/${x.seller_lead_uid}`),
                ...(pinnedUsers !== null && pinnedUsers !== void 0 ? pinnedUsers : []).map((x) => `/users/${x.uid}`),
                ...(pinnedRecruits !== null && pinnedRecruits !== void 0 ? pinnedRecruits : []).map((x) => `/recruits/${x.uid}`),
            ].filter((x) => x)),
        ];
    }, [pinnedBuyerContracts, pinnedBuyerLeads, pinnedRecruits, pinnedSellerContracts, pinnedSellerLeads, pinnedUsers]);
    return (React.createElement(Paper, { elevation: 4 },
        React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(Box, { p: 2, pb: 0 },
                    React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } },
                        "To Dos",
                        count ? ` (${count})` : ""))),
            React.createElement(Grid2, null, isLoading ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
            React.createElement(Grid2, { style: { maxHeight: "300px", overflowY: "scroll", paddingTop: 0 } },
                React.createElement(StyledList, { dense: true },
                    allPinnedViewsWithBoxes
                        .filter((v) => v.entity_type === "Buyer Lead")
                        .map((pinnedView) => (React.createElement(React.Fragment, null,
                        React.createElement(ListSubheader, { key: pinnedView.uid },
                            React.createElement(PinnedBoxSubheader, { view: pinnedView })), pinnedBuyerLeads === null || pinnedBuyerLeads === void 0 ? void 0 :
                        pinnedBuyerLeads.filter((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.find((v) => v.view.uid === pinnedView.uid && v.is_in_view); }).map((item) => {
                            var _a, _b;
                            return (React.createElement(ListItem, { key: item.uid, secondaryAction: React.createElement(React.Fragment, null,
                                    React.createElement(EmailContactButton, { emailAddresses: item.clients.filter((c) => c.client.email).map((c) => c.client.email), entityUid: item.uid, entityType: "Buyer Lead" }),
                                    React.createElement(PhoneContactButton, { phoneNumber: (_b = (_a = item.clients.find((c) => c.client.phone)) === null || _a === void 0 ? void 0 : _a.client.phone) !== null && _b !== void 0 ? _b : undefined }),
                                    React.createElement(CommentButton, { entityUid: item.uid })) },
                                React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: `/buyers/${item.uid}`, state: { allBoxPaths: allPinnedBoxPaths } }, item.name || "Buyer Lead") })));
                        })))),
                    allPinnedViewsWithBoxes
                        .filter((v) => v.entity_type === "Seller Lead")
                        .map((pinnedView) => (React.createElement(React.Fragment, null,
                        React.createElement(ListSubheader, { key: pinnedView.uid },
                            React.createElement(PinnedBoxSubheader, { view: pinnedView })), pinnedSellerLeads === null || pinnedSellerLeads === void 0 ? void 0 :
                        pinnedSellerLeads.filter((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.find((v) => v.view.uid === pinnedView.uid && v.is_in_view); }).map((item) => {
                            var _a, _b;
                            return (React.createElement(ListItem, { key: item.uid, secondaryAction: React.createElement(React.Fragment, null,
                                    React.createElement(EmailContactButton, { emailAddresses: item.clients.filter((c) => c.client.email).map((c) => c.client.email), entityUid: item.uid, entityType: "Seller Lead" }),
                                    React.createElement(PhoneContactButton, { phoneNumber: (_b = (_a = item.clients.find((c) => c.client.phone)) === null || _a === void 0 ? void 0 : _a.client.phone) !== null && _b !== void 0 ? _b : undefined }),
                                    React.createElement(CommentButton, { entityUid: item.uid })) },
                                React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: `/sellers/${item.uid}`, state: { allBoxPaths: allPinnedBoxPaths } }, item.name || "Seller Lead") })));
                        })))),
                    allPinnedViewsWithBoxes
                        .filter((v) => v.entity_type === "Buyer Contract")
                        .map((pinnedView) => (React.createElement(React.Fragment, null,
                        React.createElement(ListSubheader, { key: pinnedView.uid },
                            React.createElement(PinnedBoxSubheader, { view: pinnedView })), pinnedBuyerContracts === null || pinnedBuyerContracts === void 0 ? void 0 :
                        pinnedBuyerContracts.filter((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.find((v) => v.view.uid === pinnedView.uid && v.is_in_view); }).map((item) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            return (React.createElement(ListItem, { key: item.uid, secondaryAction: React.createElement(React.Fragment, null,
                                    React.createElement(EmailContactButton, { emailAddresses: (_c = (_b = (_a = item.buyer_lead) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.filter((c) => c.client.email).map((c) => c.client.email)) !== null && _c !== void 0 ? _c : [], entityUid: item.uid, entityType: "Buyer Contract" }),
                                    React.createElement(PhoneContactButton, { phoneNumber: (_g = (_f = (_e = (_d = item.buyer_lead) === null || _d === void 0 ? void 0 : _d.clients) === null || _e === void 0 ? void 0 : _e.find((c) => c.client.phone)) === null || _f === void 0 ? void 0 : _f.client.phone) !== null && _g !== void 0 ? _g : undefined }),
                                    ((_h = item.buyer_lead) === null || _h === void 0 ? void 0 : _h.uid) && React.createElement(CommentButton, { entityUid: item.buyer_lead.uid })) },
                                React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: `/buyers/${item.buyer_lead_uid}`, state: { allBoxPaths: allPinnedBoxPaths } }, item.name || "Buyer Contract") })));
                        })))),
                    allPinnedViewsWithBoxes
                        .filter((v) => v.entity_type === "Listing")
                        .map((pinnedView) => (React.createElement(React.Fragment, null,
                        React.createElement(ListSubheader, { key: pinnedView.uid },
                            React.createElement(PinnedBoxSubheader, { view: pinnedView })), pinnedSellerContracts === null || pinnedSellerContracts === void 0 ? void 0 :
                        pinnedSellerContracts.filter((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.find((v) => v.view.uid === pinnedView.uid && v.is_in_view); }).map((item) => {
                            var _a, _b, _c, _d, _e, _f;
                            return (React.createElement(ListItem, { key: item.uid, secondaryAction: React.createElement(React.Fragment, null,
                                    React.createElement(EmailContactButton, { emailAddresses: (_b = (_a = item.seller_lead) === null || _a === void 0 ? void 0 : _a.clients.filter((c) => c.client.email).map((c) => c.client.email)) !== null && _b !== void 0 ? _b : [], entityUid: item.uid, entityType: "Listing" }),
                                    React.createElement(PhoneContactButton, { phoneNumber: (_e = (_d = (_c = item.seller_lead) === null || _c === void 0 ? void 0 : _c.clients.find((c) => c.client.phone)) === null || _d === void 0 ? void 0 : _d.client.phone) !== null && _e !== void 0 ? _e : undefined }),
                                    ((_f = item.seller_lead) === null || _f === void 0 ? void 0 : _f.uid) && React.createElement(CommentButton, { entityUid: item.seller_lead.uid })) },
                                React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: `/sellers/${item.seller_lead_uid}`, state: { allBoxPaths: allPinnedBoxPaths } }, item.name || "Seller Contract") })));
                        })))),
                    allPinnedViewsWithBoxes
                        .filter((v) => v.entity_type === "Recruit")
                        .map((pinnedView) => (React.createElement(React.Fragment, null,
                        React.createElement(ListSubheader, { key: pinnedView.uid },
                            React.createElement(PinnedBoxSubheader, { view: pinnedView })), pinnedRecruits === null || pinnedRecruits === void 0 ? void 0 :
                        pinnedRecruits.filter((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.find((v) => v.view.uid === pinnedView.uid && v.is_in_view); }).map((item) => (React.createElement(ListItem, { key: item.uid, secondaryAction: React.createElement(React.Fragment, null,
                                React.createElement(EmailContactButton, { emailAddresses: [item.fields["Personal Email"]].filter((x) => x === null || x === void 0 ? void 0 : x.trim()).map((x) => x), entityUid: item.uid, entityType: "Recruit" }),
                                React.createElement(PhoneContactButton, { phoneNumber: item.fields["Phone"] }),
                                React.createElement(CommentButton, { entityUid: item.uid })) },
                            React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: `/recruits/${item.uid}`, state: { allBoxPaths: allPinnedBoxPaths } }, item.name || "Recruit") }))))))),
                    allPinnedViewsWithBoxes
                        .filter((v) => v.entity_type === "User")
                        .map((pinnedView) => (React.createElement(React.Fragment, null,
                        React.createElement(ListSubheader, { key: pinnedView.uid },
                            React.createElement(PinnedBoxSubheader, { view: pinnedView })), pinnedUsers === null || pinnedUsers === void 0 ? void 0 :
                        pinnedUsers.filter((x) => { var _a; return (_a = x.view_membership) === null || _a === void 0 ? void 0 : _a.find((v) => v.view.uid === pinnedView.uid && v.is_in_view); }).map((item) => (React.createElement(ListItem, { key: item.uid, secondaryAction: React.createElement(React.Fragment, null,
                                React.createElement(EmailContactButton, { emailAddresses: [item.fields["Email"]].filter((x) => x === null || x === void 0 ? void 0 : x.trim()).map((x) => x), entityUid: item.uid, entityType: "User" }),
                                React.createElement(PhoneContactButton, { phoneNumber: item.fields["Phone"] }),
                                React.createElement(CommentButton, { entityUid: item.uid })) },
                            React.createElement(ListItemText, { primary: React.createElement(RouterLink, { to: `/users/${item.uid}`, state: { allBoxPaths: allPinnedBoxPaths } }, item.name || "User") }))))))))))));
};
export default PinnedBoxes;
