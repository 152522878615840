import * as React from "react";
import { Grid } from "@mui/material";
import ConversationMessage from "@app/entrypoints/brokerage/features/apps/sms/conversation/components/ConversationMessage";
const SMSChronologyItem = (props) => {
    const { message } = props;
    const direction = React.useMemo(() => {
        if (message.direction === "inbound") {
            return "inbound";
        }
        return "outbound";
    }, [message]);
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 23, style: {
                textAlign: direction === "inbound" ? "left" : "right",
            } },
            React.createElement(ConversationMessage, { message: message, timestampFormatting: "full" }))));
};
export default SMSChronologyItem;
