import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Delete, Edit, FilterList, Launch, Refresh, Search } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreTextField from "@app/common/CoreTextField";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import DashFixedWidthTableCell from "@app/common/grid/CoreFixedWidthTableCell";
import { withDialog } from "@app/hoc/withDialog";
import { withFilter } from "@app/hoc/withFilter";
import API from "@app/util/api";
class QuestionsForTheOfferSubmissionsTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                search: null,
                box_key: null,
                created_from: null,
                created_to: null,
                submitted_from: null,
                submitted_to: null,
                status: null,
                limit: 100,
                offset: 0,
                ordering: "-created",
                count: null,
                page: null,
                rowsPerPage: null,
                submissions: null,
                isDeletingSubmission: false,
                schema: null,
                error: null,
            }
        });
        Object.defineProperty(this, "visibleColumns", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Set([
                "uid",
                "created",
                "submitted_at",
                "Property Address",
                "Submission Quoted",
            ])
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b, _c;
                this.setState({
                    submissions: null,
                    error: null,
                });
                const query = pickBy({
                    search: (_a = this.state.search) !== null && _a !== void 0 ? _a : undefined,
                    box_key: (_b = this.state.box_key) !== null && _b !== void 0 ? _b : undefined,
                    created_from: this.state.created_from ? dayjs_(this.state.created_from).toISOString() : undefined,
                    created_to: this.state.created_to
                        ? dayjs_(this.state.created_to).add(1, "day").add(-1, "second").toISOString()
                        : undefined,
                    submitted_from: this.state.submitted_from ? dayjs_(this.state.submitted_from).toISOString() : undefined,
                    submitted_to: this.state.submitted_to
                        ? dayjs_(this.state.submitted_to).add(1, "day").add(-1, "second").toISOString()
                        : undefined,
                    status: (_c = this.state.status) !== null && _c !== void 0 ? _c : undefined,
                    limit: this.state.limit,
                    offset: this.state.offset,
                    ordering: this.state.ordering,
                }, (val) => val !== undefined);
                API.questionsForTheOfferSubmissions.list({
                    query,
                    onSuccess: (result) => {
                        this.setState((prevState) => ({
                            submissions: result,
                            rowsPerPage: prevState.limit,
                            page: Math.floor(prevState.offset / prevState.limit),
                        }));
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                });
                API.questionsForTheOfferSubmissions.count({
                    query: omit(query, "limit", "offset", "ordering"),
                    onSuccess: (result) => {
                        this.setState({
                            count: result.count,
                        });
                    },
                    onError: (error) => {
                        console.error(error);
                    },
                });
            }
        });
        Object.defineProperty(this, "deleteSubmission", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "This can't be undone.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState({
                            isDeletingSubmission: true,
                        });
                        API.questionsForTheOfferSubmissions.delete(uid, {
                            onSuccess: () => {
                                console.log(`Deleted submission ${uid}`);
                                this.setState((prevState) => ({
                                    isDeletingSubmission: false,
                                    submissions: prevState.submissions.filter((obj) => obj.uid !== uid),
                                    count: prevState.count - 1,
                                }));
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                this.setState({
                                    isDeletingSubmission: false,
                                });
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "searchKeyDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                if (e.key.toLowerCase().trim() === "enter") {
                    this.doSearch(e.target.value);
                    e.preventDefault();
                }
            }
        });
        Object.defineProperty(this, "doSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.props.updateFilter("search", text.trim(), "offset", null);
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a, _b;
        const queryParams = new URLSearchParams(nextProps.location.search);
        return {
            search: queryParams.get("search"),
            box_key: queryParams.get("box_key"),
            created_from: queryParams.get("created_from"),
            created_to: queryParams.get("created_to"),
            submitted_from: queryParams.get("submitted_from"),
            submitted_to: queryParams.get("submitted_to"),
            status: ((_a = queryParams.get("status")) !== null && _a !== void 0 ? _a : null),
            limit: queryParams.get("limit") ? numeral(queryParams.get("limit")).value() : 100,
            offset: queryParams.get("offset") ? numeral(queryParams.get("offset")).value() : 0,
            ordering: (_b = queryParams.get("ordering")) !== null && _b !== void 0 ? _b : "-created",
        };
    }
    componentDidMount() {
        this.refresh();
        API.fields.list({
            query: {
                entity_type: "Pre-Offer",
            },
            onSuccess: (result) => {
                this.setState({
                    schema: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
        });
    }
    componentDidUpdate(_, prevState) {
        if (prevState.search !== this.state.search ||
            prevState.box_key !== this.state.box_key ||
            prevState.created_from !== this.state.created_from ||
            prevState.created_to !== this.state.created_to ||
            prevState.submitted_from !== this.state.submitted_from ||
            prevState.submitted_to !== this.state.submitted_to ||
            prevState.status !== this.state.status ||
            prevState.limit !== this.state.limit ||
            prevState.offset !== this.state.offset ||
            prevState.ordering !== this.state.ordering) {
            this.refresh();
        }
    }
    render() {
        const { onToggleSidebar } = this.props;
        const { count, error, isDeletingSubmission, ordering, page, rowsPerPage, schema, search, submissions } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!submissions || !schema) {
            return React.createElement(CoreLoading, null);
        }
        const schemaColumns = schema.map((field) => ({
            name: field.name,
            label: field.name,
            options: {
                customBodyRender: (_, tableMeta) => {
                    var _a, _b, _c, _d;
                    const submission = submissions[tableMeta.rowIndex];
                    const val = submission.fields[field.name];
                    if (val == null) {
                        return "";
                    }
                    if (field.field_type === "number" || (field.field_type === "formula" && ((_a = field.format) === null || _a === void 0 ? void 0 : _a.includes("0")))) {
                        return numeral(val).format((_b = field.format) !== null && _b !== void 0 ? _b : "0.000000");
                    }
                    if (field.field_type === "date" ||
                        field.field_type === "datetime" ||
                        (field.field_type === "formula" && !((_c = field.format) === null || _c === void 0 ? void 0 : _c.includes("0")))) {
                        return dayjs_(val).format((_d = field.format) !== null && _d !== void 0 ? _d : "M/D/YYYY");
                    }
                    return String(val);
                },
            },
        }));
        const columns = [
            {
                name: "uid",
                label: " ",
                options: {
                    sort: false,
                    download: false,
                    viewColumns: false,
                    display: true,
                    customHeadRender: ({ index }) => React.createElement(DashFixedWidthTableCell, { key: index, width: "35px" }),
                    customBodyRender: (value, tableMeta) => {
                        const submission = submissions[tableMeta.rowIndex];
                        return (React.createElement(React.Fragment, null,
                            submission.submitted_at ? (React.createElement(Tooltip, { title: "Open submission" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { size: "small", component: RouterLink, to: `/forms/qfo/${value}`, disabled: isDeletingSubmission },
                                        React.createElement(Launch, { fontSize: "small" }))))) : (React.createElement(Tooltip, { title: "Edit submission" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { size: "small", component: RouterLink, to: `/forms/qfo/form/${value}`, disabled: isDeletingSubmission },
                                        React.createElement(Edit, { fontSize: "small" }))))),
                            React.createElement(Tooltip, { title: "Delete submission" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { size: "small", onClick: this.deleteSubmission(value), disabled: isDeletingSubmission },
                                        React.createElement(Delete, { fontSize: "small" }))))));
                    },
                },
            },
            {
                name: "created",
                label: "Created",
                options: {
                    customBodyRender: (value) => dayjs_(value).format("M/D/YYYY h:mm A"),
                },
            },
            {
                name: "submitted_at",
                label: "Submitted",
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            return dayjs_(value).format("M/D/YYYY h:mm A");
                        }
                        return null;
                    },
                },
            },
        ].concat(schemaColumns);
        for (const column of columns) {
            column.options = Object.assign(Object.assign({}, column.options), { display: this.visibleColumns.has(column.name) });
        }
        const options = {
            responsive: "standard",
            selectableRows: "none",
            download: false,
            print: false,
            filter: false,
            pagination: true,
            viewColumns: true,
            search: false,
            serverSide: true,
            count: count,
            page: page,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
            textLabels: {
                body: {
                    noMatch: !submissions ? "" : "Sorry, there is no matching data to display",
                },
            },
            onChangePage: (currentPage) => {
                this.props.updateFilter("offset", rowsPerPage * currentPage);
            },
            onColumnSortChange: (changedColumn) => {
                this.props.updateFilter("ordering", ordering === changedColumn ? `-${changedColumn}` : changedColumn);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                this.props.updateFilter("limit", numberOfRows);
            },
            onViewColumnsChange: (changedColumn, action) => {
                if (action === "add") {
                    this.visibleColumns.add(changedColumn);
                }
                else if (action === "remove") {
                    this.visibleColumns.delete(changedColumn);
                }
            },
            customToolbar: () => (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: "Refresh" },
                    React.createElement(IconButton, { onClick: this.refresh, size: "small" },
                        React.createElement(Refresh, { fontSize: "small" }))),
                onToggleSidebar && (React.createElement(Tooltip, { title: "Show/hide filters" },
                    React.createElement(IconButton, { onClick: onToggleSidebar, size: "small" },
                        React.createElement(FilterList, { fontSize: "small" })))))),
        };
        return (React.createElement(CoreDataTable, { title: React.createElement(Grid, { container: true, spacing: 1, alignItems: "flex-end" },
                React.createElement(Grid, { item: true },
                    React.createElement(Search, null)),
                React.createElement(Grid, { item: true, style: { flexGrow: 1 } },
                    React.createElement(CoreTextField, { variant: "standard", fullWidth: true, autoFocus: true, style: { maxWidth: "500px" }, onTextChange: this.doSearch, onKeyPress: this.searchKeyDown, value: search === null ? "" : search }))), data: submissions, columns: columns, options: options, cellPadding: 0 }));
    }
}
export default withDialog(withFilter(QuestionsForTheOfferSubmissionsTable));
