import * as React from "react";
import { useCallback } from "react";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import { useAddVendorForBuyerLead, useRemoveVendorForBuyerLead, useUpdateVendorForBuyerLead, } from "@app/orval/api/buyer-leads";
import { useAddSellerLeadVendor, useRemoveSellerLeadVendor, useUpdateSellerLeadVendor, } from "@app/orval/api/seller-leads";
export const useThirdParties = () => {
    var _a, _b;
    const [isThirdPartyDialogOpen, setIsThirdPartyDialogOpen] = React.useState(false);
    const [thirdPartyDialogParams, setThirdPartyDialogParams] = React.useState(null);
    const errorDialog = useErrorDialog();
    const session = useSession();
    const addThirdPartyForBuyerLeadApi = useAddVendorForBuyerLead();
    const updateThirdPartyForBuyerLeadApi = useUpdateVendorForBuyerLead();
    const removeThirdPartyForBuyerLeadApi = useRemoveVendorForBuyerLead();
    const addThirdPartyForSellerLeadApi = useAddSellerLeadVendor();
    const updateThirdPartyForSellerLeadApi = useUpdateSellerLeadVendor();
    const removeThirdPartyForSellerLeadApi = useRemoveSellerLeadVendor();
    const canEditThirdPartyCells = ["scripts@dashnc.com", "developer@dashnc.com"].includes((_b = (_a = session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : "");
    const openThirdPartyDialog = useCallback(() => {
        setIsThirdPartyDialogOpen(true);
    }, [setIsThirdPartyDialogOpen]);
    const closeThirdPartyDialog = useCallback(() => {
        setIsThirdPartyDialogOpen(false);
    }, [setIsThirdPartyDialogOpen]);
    const addThirdPartyForBuyerLead = useCallback((buyerLeadUid, params, options) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        addThirdPartyForBuyerLeadApi
            .mutateAsync({ buyerLeadUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [addThirdPartyForBuyerLeadApi, errorDialog]);
    const updateThirdPartyForBuyerLead = useCallback((vendorUid, params, options) => {
        console.log("updateThirdPartyForBuyerLead", vendorUid, params);
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        updateThirdPartyForBuyerLeadApi
            .mutateAsync({ uid: vendorUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, updateThirdPartyForBuyerLeadApi]);
    const removeThirdPartyForBuyerLead = useCallback((vendorUid, options) => {
        if (options === null || options === void 0 ? void 0 : options.onBeforeRemove) {
            options.onBeforeRemove();
        }
        removeThirdPartyForBuyerLeadApi
            .mutateAsync({ uid: vendorUid })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeThirdPartyForBuyerLeadApi]);
    const addThirdPartyForSellerLead = useCallback((sellerLeadUid, params, options) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        addThirdPartyForSellerLeadApi
            .mutateAsync({ sellerLeadUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [addThirdPartyForSellerLeadApi, errorDialog]);
    const updateThirdPartyForSellerLead = useCallback((vendorUid, params, options) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        updateThirdPartyForSellerLeadApi
            .mutateAsync({ uid: vendorUid, data: params })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, updateThirdPartyForSellerLeadApi]);
    const removeThirdPartyForSellerLead = useCallback((vendorUid, options) => {
        if (options === null || options === void 0 ? void 0 : options.onBeforeRemove) {
            options.onBeforeRemove();
        }
        removeThirdPartyForSellerLeadApi
            .mutateAsync({ uid: vendorUid })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeThirdPartyForSellerLeadApi]);
    return {
        openThirdPartyDialog,
        closeThirdPartyDialog,
        addThirdPartyForBuyerLead,
        updateThirdPartyForBuyerLead,
        removeThirdPartyForBuyerLead,
        isThirdPartyDialogOpen,
        canEditThirdPartyCells,
        thirdPartyDialogParams,
        setThirdPartyDialogParams,
        addThirdPartyForSellerLead,
        updateThirdPartyForSellerLead,
        removeThirdPartyForSellerLead,
    };
};
export default useThirdParties;
