import * as React from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Grid2, Box, Tooltip, Button, Link, Alert, Typography, FormControlLabel, Checkbox, } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import CoreLoading from "@app/common/CoreLoading";
import CoreRichTextField from "@app/common/CoreRichTextField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { PSP_REPORT_REQUIRED_FIELDS, BUYER_PSP_REPORT_REQUIRED_FIELDS, LISTING_PSP_REPORT_REQUIRED_FIELDS, PRE_LISTING_PRESENTATION_REQUIRED_FIELDS, } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import useStateCallback from "@app/hooks/useStateCallback";
import { useUpdatePsp } from "@app/orval/api/psps";
import { getUserName } from "@app/util/Utils";
import useReportApis from "./useReportApis";
const COMMENT_FIELD_NAME = "Comments";
const PSPCommentsSidebar = (props) => {
    const { agentUid, closeSidebar, comments: comments_, matrixFields, matrixUid, onFieldSaveBegin, onFieldSaveComplete, pspType, users, } = props;
    const [isUpdatingComments, setIsUpdatingComments] = useStateCallback(false);
    const [useV2PSPReport, setUseV2PSPReport] = React.useState(false);
    const [comments, setComments] = React.useState(comments_);
    const updatePSPApi = useUpdatePsp();
    React.useEffect(() => {
        // ensure comment updates are preserved when sidebar is re-opened
        setComments(comments_);
    }, [comments_]);
    const { generateBuyerPspReport, generateListingPspReport, generatePreListingPresentation, sendCommentsApi, sendPSPComments, } = useReportApis(matrixUid);
    const missingRequiredFields = React.useMemo(() => {
        let requiredFields = PSP_REPORT_REQUIRED_FIELDS;
        if (pspType !== null && pspType.toLowerCase().trim() === "buyer") {
            requiredFields = [...requiredFields, ...BUYER_PSP_REPORT_REQUIRED_FIELDS];
        }
        else if (pspType !== null && pspType.toLowerCase().trim() === "listing") {
            requiredFields = [...requiredFields, ...LISTING_PSP_REPORT_REQUIRED_FIELDS];
        }
        else if (pspType !== null && pspType.toLowerCase().trim() === "pre-listing") {
            requiredFields = [...requiredFields, ...PRE_LISTING_PRESENTATION_REQUIRED_FIELDS];
        }
        return requiredFields.filter((fieldName) => {
            return matrixFields && !matrixFields[fieldName];
        });
    }, [matrixFields, pspType]);
    const isValidPSP = React.useMemo(() => {
        const isValidPSPType = pspType != null && ["listing", "buyer", "pre-listing"].includes(pspType.toLowerCase().trim());
        return isValidPSPType && missingRequiredFields.length === 0;
    }, [missingRequiredFields.length, pspType]);
    const onClose = React.useCallback(() => {
        closeSidebar(null);
    }, [closeSidebar]);
    /** update value of Comments field */
    const onCommentsChange = React.useCallback((text) => {
        if (comments !== text) {
            setIsUpdatingComments(true, () => {
                onFieldSaveBegin(COMMENT_FIELD_NAME)(text);
                updatePSPApi
                    .mutateAsync({
                    uid: matrixUid,
                    data: {
                        fields: {
                            [COMMENT_FIELD_NAME]: text,
                        },
                    },
                })
                    .then((response) => {
                    onFieldSaveComplete(response);
                    setComments(text);
                })
                    .catch(enqueueErrorSnackbar)
                    .then(() => {
                    setIsUpdatingComments(false);
                });
            });
        }
    }, [comments, matrixUid, onFieldSaveBegin, onFieldSaveComplete, setIsUpdatingComments, updatePSPApi]);
    /**
     * Sends an email to the assigned agent with the comments and the psp report attached
     */
    const sendComments = React.useCallback(() => {
        if (!users) {
            console.error("Attempted to send comments before users loaded. Users:", users);
            enqueueErrorSnackbar("Users could not be found");
            return;
        }
        if (matrixFields["Property Address"]) {
            const agent = agentUid ? users.find((user) => user.uid === agentUid) : null;
            const agentName = agent ? getUserName(agent) : "";
            sendPSPComments(agentName, matrixFields["Property Address"], useV2PSPReport);
        }
        else {
            console.error("Attempting to send comments without a property address");
            enqueueErrorSnackbar("Property Address is required to send PSP Comments");
        }
    }, [users, matrixFields, agentUid, sendPSPComments, useV2PSPReport]);
    const downloadPSPReport = React.useCallback(() => {
        if (pspType) {
            if (pspType.toLowerCase().trim() === "pre-listing") {
                generatePreListingPresentation();
            }
            else if (pspType.toLowerCase().trim() === "listing") {
                generateListingPspReport("pdf", "pdf");
            }
            else if (pspType.toLowerCase().trim() === "buyer") {
                generateBuyerPspReport("pdf", useV2PSPReport);
            }
        }
    }, [generateBuyerPspReport, generateListingPspReport, generatePreListingPresentation, pspType, useV2PSPReport]);
    const onUseV2ReportToggle = React.useCallback(() => {
        setUseV2PSPReport((prev) => !prev);
    }, [setUseV2PSPReport]);
    if (!users) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(DashCard, { heading: "Comments", toolbar: React.createElement(IconButton, { onClick: onClose },
            React.createElement(Close, null)), padding: 0, elevation: 0 },
        React.createElement(Grid2, { container: true, spacing: 2 },
            !isValidPSP && (React.createElement(Alert, { severity: "warning", style: { marginBottom: "8px" } },
                React.createElement(Typography, null,
                    React.createElement("strong", null, "Warning:"),
                    " Cannot generate PSP Report. Check that all required fields are filled out."),
                missingRequiredFields.map((fieldName) => {
                    return (React.createElement(Typography, { key: `${fieldName}_missing_required_field` }, `'${fieldName}' is missing or blank`));
                }))),
            (pspType === null || pspType === void 0 ? void 0 : pspType.toLowerCase().trim()) === "buyer" && (React.createElement(Grid2, { style: { paddingLeft: "40px", marginTop: "8px" } },
                React.createElement(FormControlLabel, { label: "Use New PSP Report", control: React.createElement(Checkbox, { size: "small", checked: useV2PSPReport, color: "primary", onChange: onUseV2ReportToggle, style: { paddingTop: 0, paddingBottom: 0 } }) }))),
            React.createElement(Grid2, { size: { xs: 12, md: 6 }, style: { padding: "4px", marginTop: "8px", display: "flex" } },
                React.createElement(Tooltip, { title: isValidPSP ? "Send to Agent" : "Cannot generate PSP Report. Missing Required fields." },
                    React.createElement(Button, { variant: "contained", color: "primary", style: {
                            display: "flex",
                            margin: "auto",
                        }, onClick: sendComments, 
                        // NOTE: this is only disabled while the sendCommentsApi is pending, not while the background task is pending
                        disabled: sendCommentsApi.isPending || isUpdatingComments || !isValidPSP }, "Send")),
                sendCommentsApi.isPending && (React.createElement(Box, { height: "80%" },
                    React.createElement(CoreLoading, null)))),
            React.createElement(Grid2, { size: { xs: 12, md: 6 }, style: { padding: "4px", marginTop: "8px" } },
                React.createElement(Box, { display: "flex", m: "auto" },
                    React.createElement(Tooltip, { title: `Download ${pspType} PSP Report` },
                        React.createElement(Button, { component: Link, variant: "contained", color: "primary", disabled: !isValidPSP, onClick: downloadPSPReport, target: "_blank", style: {
                                margin: "auto",
                            } }, "Download")))),
            React.createElement(Grid2, { size: 12, style: {
                    paddingTop: "4px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "4px",
                    display: "flex",
                    minHeight: "150px",
                } },
                React.createElement(Box, { width: "100%" },
                    React.createElement(CoreRichTextField, { rawValue: comments !== null && comments !== void 0 ? comments : "", onBlur: onCommentsChange })),
                isUpdatingComments && React.createElement(CoreLoading, null)))));
};
export default PSPCommentsSidebar;
