import * as React from "react";
import { 
// Avatar,
Box, Button, Grid, Typography, } from "@mui/material";
import { withDialog } from "@app/hoc/withDialog";
import CommentTextArea from "./CommentTextArea";
class CommentField extends React.Component {
    constructor() {
        var _a;
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                content: (_a = this.props.content) !== null && _a !== void 0 ? _a : null,
            }
        });
        Object.defineProperty(this, "inputRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "containerRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "onKeyDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { content } = this.state;
                if ((content == null || content === "") && e.keyCode === 27) {
                    this.props.onClose();
                }
            }
        });
        Object.defineProperty(this, "onClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                var _a;
                const offset = (_a = this.containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                if (offset != null) {
                    if (e.clientX < offset.x ||
                        e.clientX > offset.x + offset.width ||
                        e.clientY < offset.y ||
                        e.clientY > offset.y + offset.height) {
                        if (this.state.content === null || this.state.content === "") {
                            this.props.onClose();
                        }
                    }
                }
            }
        });
        Object.defineProperty(this, "submitComment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { replyTo, uid } = this.props;
                const { content } = this.state;
                const data = {
                    uid,
                    reply_to: replyTo !== null && replyTo !== void 0 ? replyTo : undefined,
                    content: content,
                };
                this.props.onCommentSubmitted(data);
                this.props.onClose();
            }
        });
        Object.defineProperty(this, "setContent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState({
                    content: newValue,
                });
            }
        });
        Object.defineProperty(this, "handleFocus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
        });
        Object.defineProperty(this, "handleCancel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onClose();
            }
        });
    }
    componentDidMount() {
        setTimeout(() => {
            document.addEventListener("keydown", this.onKeyDown, false);
            document.addEventListener("click", this.onClick, false);
        }, 1);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
        document.removeEventListener("click", this.onClick, false);
    }
    get canSave() {
        var _a;
        const { content } = this.state;
        return (content === null || content === void 0 ? void 0 : content.replace(/[^A-Za-z0-9]/g, "").trim()) && content.trim() !== ((_a = this.props.content) === null || _a === void 0 ? void 0 : _a.trim());
    }
    render() {
        const { content } = this.state;
        return (React.createElement(Box, { ref: this.containerRef, component: Grid, container: true, p: 1, spacing: 2, style: {
                border: "1px solid #ddd",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
                background: "white",
            } },
            React.createElement(Grid, { item: true, style: { position: "relative" }, xs: 12 },
                React.createElement(CommentTextArea, { value: content, onChange: this.setContent }),
                (content === null || content === "") && (React.createElement(Typography, { onClick: this.handleFocus, style: {
                        position: "absolute",
                        top: "0.5rem",
                        color: "#888",
                    }, variant: "body2" }, this.props.uid ? "Edit Comment" : "Add comment"))),
            React.createElement(Grid, { item: true, style: { textAlign: "right" }, xs: 12 },
                (this.props.uid || this.props.replyTo) && (React.createElement(Button, { color: "primary", onClick: this.handleCancel, size: "small", style: {
                        marginRight: "1rem",
                    }, tabIndex: -1 }, "Cancel")),
                React.createElement(Button, { color: "primary", disabled: !!this.props.isSubmittingComment || !this.canSave, onClick: this.submitComment, size: "small", variant: "contained", tabIndex: -1 }, this.props.uid ? "Save" : "Submit"))));
    }
}
Object.defineProperty(CommentField, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        replyTo: null,
        content: null,
    }
});
export default withDialog(CommentField);
