import * as React from "react";
import { Box, Grid2, MenuItem, Button, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreTextField from "@app/common/CoreTextField";
import Loading from "@app/common/Loading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useGenerateDashDailyCountyReport } from "@app/orval/api/dash-daily-county-report";
import { fileContentToURL, dashDailyMarkets } from "@app/util/Utils";
const DashDailyOnDemand = () => {
    const [county, setCounty] = React.useState(null);
    const [market, setMarket] = React.useState(null);
    const [fileUrl, setFileUrl] = React.useState(null);
    const [isGenerating, setIsGenerating] = React.useState(false);
    const generateCountyReportApi = useGenerateDashDailyCountyReport({
        counties: county !== null && county !== void 0 ? county : "",
        market: market !== null && market !== void 0 ? market : "RDU",
    }, {
        query: {
            enabled: false,
        },
    });
    const onCountyChanged = React.useCallback((val) => {
        setCounty(val);
    }, [setCounty]);
    const onMarketChanged = React.useCallback((val) => {
        setMarket(val);
    }, [setMarket]);
    const generateReport = React.useCallback(() => {
        if (county && market) {
            console.log("Generating Dash Daily report for ", county, " county in market ", market);
            setIsGenerating(true);
            generateCountyReportApi
                .refetch()
                .then((result) => {
                const url = fileContentToURL(result.data.data.file, "application/pdf");
                setFileUrl(url);
                setIsGenerating(false);
            })
                .catch((err) => {
                enqueueErrorSnackbar(err);
                setIsGenerating(false);
            });
        }
        else {
            // Sanity check: shouldn't be able to reach this point
            enqueueSnackbar("Cannot generate report without both County and Market", { variant: "error", persist: true });
        }
    }, [county, market, setIsGenerating, generateCountyReportApi]);
    return (React.createElement(Box, { p: 2, style: { height: "calc(100% - 1rem)" } },
        React.createElement(Grid2, { container: true, spacing: 2, direction: "column", style: { height: "100%" } },
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "h6" }, "Dash Daily on Demand")),
            !isGenerating && (React.createElement(Grid2, { container: true, spacing: 2 },
                React.createElement(Grid2, { size: { xs: 12, sm: 5 } },
                    React.createElement(CoreTextField, { variant: "standard", fullWidth: true, required: true, value: county, label: "County", onTextChange: onCountyChanged })),
                React.createElement(Grid2, { size: { xs: 12, sm: 5 } },
                    React.createElement(CoreChoiceField, { variant: "standard", fullWidth: true, required: true, label: "Market", value: market, onChoiceChange: onMarketChanged }, [null, ...dashDailyMarkets].map((item) => {
                        return (React.createElement(MenuItem, { key: item !== null && item !== void 0 ? item : "markets_dropdown_null_val", value: item !== null && item !== void 0 ? item : "", style: { height: "35px" } }, item !== null && item !== void 0 ? item : ""));
                    }))),
                React.createElement(Grid2, { size: { xs: 12, sm: 2 } },
                    React.createElement(Button, { variant: "contained", disabled: !(county && market) || isGenerating, onClick: generateReport, style: { margin: "auto" } }, "Generate Report")))),
            isGenerating && (React.createElement(Grid2, null,
                React.createElement(Loading, { items: [
                        {
                            label: `Generating report for ${county} in market ${market}...`,
                            status: "pending",
                        },
                    ] }))),
            fileUrl && !isGenerating && (React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement("iframe", { title: `${market}_${county}_report`, width: "100%", height: "100%", src: fileUrl }))))));
};
export default DashDailyOnDemand;
