import * as React from "react";
import { Close, Phone, Send } from "@mui/icons-material";
import { Grid, InputAdornment, TextField, Button, MenuItem } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useStateCallback from "@app/hooks/useStateCallback";
import { useTwilioBulkSendMessages } from "@app/orval/api/twilio";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
};
const SMSField = (props) => {
    var _a, _b;
    const { contacts, onClose, onMessageSent } = props;
    const listTwilioPhonesApi = useListCachedTwilioPhones({ include_agent_phones: true, include_proxy_phones: false, access_only: true }, INFINITE_CACHE_PARAMS);
    const twilioPhones = (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const sendMessageApi = useTwilioBulkSendMessages();
    const [selectedTwilioPhone, setSelectedTwilioPhone] = useStateCallback(null);
    const [selectedContact, setSelectedContact] = useStateCallback(null);
    const [recipient, setRecipient] = useStateCallback("");
    const [message, setMessage] = useStateCallback("");
    const inputRef = React.createRef();
    const containerRef = React.createRef();
    const onKeyDown = React.useCallback((e) => {
        if (message === "" && e.keyCode === 27) {
            onClose();
        }
    }, [message, onClose]);
    const onClick = React.useCallback((e) => {
        var _a;
        const offset = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (offset != null) {
            if (e.clientX < offset.x ||
                e.clientX > offset.x + offset.width ||
                e.clientY < offset.y ||
                e.clientY > offset.y + offset.height) {
                if (message === "") {
                    onClose();
                }
            }
        }
    }, [containerRef, message, onClose]);
    const handleFocus = React.useCallback(() => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [inputRef]);
    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);
    React.useEffect(() => {
        handleFocus();
        setTimeout(() => {
            document.addEventListener("keydown", onKeyDown, false);
            document.addEventListener("click", onClick, false);
        }, 1);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
            document.removeEventListener("click", onClick, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const canSendSms = React.useMemo(() => {
        return !!(selectedTwilioPhone &&
            recipient &&
            message &&
            !sendMessageApi.isPending &&
            isValidPhoneNumber(recipient, "US"));
    }, [selectedTwilioPhone, recipient, message, sendMessageApi.isPending]);
    const onTwilioPhoneChanged = React.useCallback((e) => {
        const val = e.target.value;
        setSelectedTwilioPhone(val || null);
    }, [setSelectedTwilioPhone]);
    const onContactChanged = React.useCallback((e) => {
        const val = e.target.value;
        setSelectedContact(val);
        setRecipient(val);
    }, [setSelectedContact, setRecipient]);
    const onRecipientChanged = React.useCallback((e) => {
        const val = e.target.value;
        setSelectedContact(null);
        setRecipient(val);
    }, [setSelectedContact, setRecipient]);
    const onMessageChanged = React.useCallback((e) => {
        const val = e.target.value;
        setMessage(val);
    }, [setMessage]);
    const sendSms = React.useCallback(() => {
        sendMessageApi
            .mutateAsync({
            data: {
                messages: [
                    {
                        from_: selectedTwilioPhone,
                        to: recipient,
                        body: message,
                        send_at: null,
                        media_uids: null,
                    },
                ],
            },
        })
            .then((result) => {
            if (onMessageSent) {
                onMessageSent(result.data[0]);
            }
        })
            .catch(enqueueErrorSnackbar);
    }, [sendMessageApi, selectedTwilioPhone, recipient, message, onMessageSent]);
    return (React.createElement(Grid, { ref: containerRef, container: true, spacing: 1, direction: "column" },
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 6, style: { marginTop: "auto" } },
                    React.createElement(TextField, { fullWidth: true, select: true, size: "small", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "From"),
                            },
                        }, value: selectedTwilioPhone !== null && selectedTwilioPhone !== void 0 ? selectedTwilioPhone : "", onChange: onTwilioPhoneChanged, SelectProps: {
                            displayEmpty: true,
                        }, disabled: sendMessageApi.isPending },
                        React.createElement(MenuItem, { value: "" }, "Select a phone"),
                        (twilioPhones !== null && twilioPhones !== void 0 ? twilioPhones : []).map((p) => (React.createElement(MenuItem, { key: p.phone_number, value: p.phone_number, onClick: preventDefault }, p.friendly_name))))),
                React.createElement(Grid, { item: true, xs: selectedContact ? 6 : 3, style: { marginTop: "auto" } },
                    React.createElement(TextField, { fullWidth: true, select: true, size: "small", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "To"),
                            },
                            select: {
                                displayEmpty: true,
                            },
                        }, value: selectedContact !== null && selectedContact !== void 0 ? selectedContact : "", onChange: onContactChanged, disabled: sendMessageApi.isPending },
                        React.createElement(MenuItem, { value: "" }, "Number specified"),
                        contacts.map((c) => (React.createElement(MenuItem, { key: c.phone_number, value: c.phone_number, onClick: preventDefault }, c.name))))),
                !selectedContact && (React.createElement(Grid, { item: true, xs: 3, style: { marginTop: "auto" } },
                    React.createElement(TextField, { fullWidth: true, variant: "standard", size: "small", slotProps: {
                            input: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(Phone, null))),
                            },
                        }, value: recipient, onChange: onRecipientChanged, disabled: sendMessageApi.isPending }))))),
        React.createElement(Grid, { item: true },
            React.createElement(TextField, { ref: inputRef, placeholder: "Type message here", multiline: true, minRows: 3, maxRows: 8, fullWidth: true, value: message, onChange: onMessageChanged, disabled: sendMessageApi.isPending })),
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, style: { flex: 1 } }),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { onClick: handleCancel, size: "small", style: {
                            marginRight: "1rem",
                        }, startIcon: React.createElement(Close, null), tabIndex: -1 }, "Cancel")),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { size: "small", color: "primary", onClick: sendSms, disabled: !canSendSms, startIcon: React.createElement(Send, null), tabIndex: -1 }, "Send"))))));
};
export default SMSField;
