import * as React from "react";
import { LinkOff } from "@mui/icons-material";
import { Avatar, Chip, IconButton, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Tooltip, Typography, } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { withDialog } from "@app/hoc/withDialog";
const ContactRelationshipListItem = (props) => {
    var _a, _b, _c;
    const { onUnlink, relationship } = props;
    const unlink = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        props.showDialog({
            title: "Are you sure?",
            text: "This will just remove the relationship; it will not delete the related contact.",
            confirmButtonText: "Remove",
            confirmButtonColor: "secondary",
            onConfirm: () => {
                props.hideDialog();
                onUnlink(relationship.related_contact_uid);
            },
        });
    }, []);
    const initials = [relationship.related_contact_fields["First Name"], relationship.related_contact_fields["Last Name"]]
        .filter((o) => o)
        .map((o) => o === null || o === void 0 ? void 0 : o.substring(0, 1))
        .join("") ||
        ((_a = relationship.related_contact_fields["Email"]) === null || _a === void 0 ? void 0 : _a.trim().substring(0, 1)) ||
        "?";
    const name = [relationship.related_contact_fields["First Name"], relationship.related_contact_fields["Last Name"]]
        .filter((o) => o)
        .join(" ") ||
        ((_b = relationship.related_contact_fields["Email"]) === null || _b === void 0 ? void 0 : _b.trim()) ||
        ((_c = relationship.related_contact_fields["Phone"]) === null || _c === void 0 ? void 0 : _c.trim());
    return (React.createElement(ListItemButton, { component: RouterLink, to: `/contacts/${relationship.related_contact_uid}` },
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, { src: undefined }, initials)),
        React.createElement(ListItemText, { primary: name, secondary: React.createElement(Chip, { size: "small", label: relationship.relationship }) }),
        React.createElement(ListItemSecondaryAction, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove relationship") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: unlink },
                        React.createElement(LinkOff, { fontSize: "small" })))))));
};
export default withDialog(ContactRelationshipListItem);
