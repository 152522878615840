import * as React from "react";
import { Grid, Typography } from "@mui/material";
import dayjs_ from "@app/common/dayjs";
import { getFullName, getFullNameMemoized } from "@app/util/Utils";
const AgentChangeItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const { item, users } = props;
    const previousAgent = users === null || users === void 0 ? void 0 : users.find((x) => { var _a; return x.uid === ((_a = item.previous_value) === null || _a === void 0 ? void 0 : _a.agent_uid); });
    const previousAgentName = previousAgent
        ? getFullName((_a = previousAgent.first_name) !== null && _a !== void 0 ? _a : null, (_b = previousAgent.last_name) !== null && _b !== void 0 ? _b : null)
        : null;
    const newAgent = users === null || users === void 0 ? void 0 : users.find((x) => { var _a; return x.uid === ((_a = item.new_value) === null || _a === void 0 ? void 0 : _a.agent_uid); });
    const newAgentName = newAgent ? getFullName((_c = newAgent.first_name) !== null && _c !== void 0 ? _c : null, (_d = newAgent.last_name) !== null && _d !== void 0 ? _d : null) : null;
    console.log({ item });
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 9 },
            (item.previous_value == null || (item.new_value && item.previous_value.type !== item.new_value.type)) && (React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, getFullNameMemoized((_f = (_e = item.userstamp) === null || _e === void 0 ? void 0 : _e.first_name) !== null && _f !== void 0 ? _f : null, (_h = (_g = item.userstamp) === null || _g === void 0 ? void 0 : _g.last_name) !== null && _h !== void 0 ? _h : null) ||
                        "Anonymous user or automation")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "set the")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_j = item.new_value) === null || _j === void 0 ? void 0 : _j.type)),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "to")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", fontStyle: "italic" }, ((_k = item.new_value) === null || _k === void 0 ? void 0 : _k.agent_uid) ? (newAgentName !== null && newAgentName !== void 0 ? newAgentName : item.new_value.agent_uid) : "(blank)")))),
            item.new_value == null && (React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, getFullNameMemoized((_m = (_l = item.userstamp) === null || _l === void 0 ? void 0 : _l.first_name) !== null && _m !== void 0 ? _m : null, (_p = (_o = item.userstamp) === null || _o === void 0 ? void 0 : _o.last_name) !== null && _p !== void 0 ? _p : null) ||
                        "Anonymous user or automation")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "removed the")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_q = item.previous_value) === null || _q === void 0 ? void 0 : _q.type)))),
            item.previous_value && item.new_value && item.previous_value.type === item.new_value.type && (React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, getFullNameMemoized((_s = (_r = item.userstamp) === null || _r === void 0 ? void 0 : _r.first_name) !== null && _s !== void 0 ? _s : null, (_u = (_t = item.userstamp) === null || _t === void 0 ? void 0 : _t.last_name) !== null && _u !== void 0 ? _u : null) ||
                        "Anonymous user or automation")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "changed the")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, item.new_value.type)),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "from")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", fontStyle: "italic" }, item.previous_value.agent_uid ? (previousAgentName !== null && previousAgentName !== void 0 ? previousAgentName : item.previous_value.agent_uid) : "(blank)")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, "to")),
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", fontStyle: "italic" }, item.new_value.agent_uid ? (newAgentName !== null && newAgentName !== void 0 ? newAgentName : item.new_value.agent_uid) : "(blank)"))))),
        React.createElement(Grid, { item: true, xs: 3 },
            React.createElement(Typography, { style: {
                    float: "right",
                    color: "#ccc",
                }, variant: "caption" }, dayjs_(item.timestamp).isValid()
                ? dayjs_(new Date(item.timestamp)).format("M/D/YYYY h:mm A")
                : item.timestamp))));
};
export default AgentChangeItem;
