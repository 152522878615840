import { useEffect } from "react";
const useMarkRowAsEditing = () => {
    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList") {
                    const editingCells = document.querySelectorAll(".MuiDataGrid-cell--editing");
                    editingCells.forEach((cell) => {
                        const row = cell.closest(".MuiDataGrid-row");
                        // mark all rows with the matching data-id as being edited...
                        // this includes both pinned and unpinned rows
                        const qs = `.MuiDataGrid-row[data-id="${row === null || row === void 0 ? void 0 : row.getAttribute("data-id")}"]`;
                        document.querySelectorAll(qs).forEach((r) => {
                            r.classList.add("editing");
                        });
                    });
                }
            });
        });
        const config = { childList: true, subtree: true };
        observer.observe(document.body, config);
        return () => {
            observer.disconnect();
        };
    }, []);
};
export default useMarkRowAsEditing;
