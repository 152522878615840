import { useMemo } from "react";
import useSession from "@app/hooks/useSession";
import { saturateFieldWithValidation } from "./field_validation";
const useSaturateFields = (fields, context) => {
    const session = useSession();
    const fieldsSaturated = useMemo(() => {
        const res = fields && context
            ? fields.map((field) => { var _a; return saturateFieldWithValidation({ field, ctx: context, user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined }); })
            : null;
        console.log({ res });
        return res;
    }, [context, fields, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const validationErrors = useMemo(() => {
        return (fieldsSaturated !== null && fieldsSaturated !== void 0 ? fieldsSaturated : [])
            .reduce((acc, item) => {
            const url = `/field-management?field=${item.uid}`;
            return [
                ...acc,
                {
                    name: item.name,
                    error: item.visibleValidation.error,
                    url,
                },
                {
                    name: item.name,
                    error: item.editableValidation.error,
                    url,
                },
                {
                    name: item.name,
                    error: item.warnValidation.error,
                    url,
                },
                {
                    name: item.name,
                    error: item.rejectValidation.error,
                    url,
                },
            ];
        }, [])
            .filter((o) => o.error);
    }, [fieldsSaturated]);
    return {
        fieldsSaturated,
        validationErrors,
    };
};
export default useSaturateFields;
