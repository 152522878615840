import * as React from "react";
import { AddOutlined, Launch } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import dayjs_ from "@app/common/dayjs";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useStateWithCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListTeams } from "@app/orval/api/teams";
import { countUsers, countUsersByStage, downloadUsers, searchUsers, useAddUser, useBulkDeleteUsers,
// useBulkDeleteUsers,
 } from "@app/orval/api/users";
import { FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA, INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS, } from "@app/orval/config";
import UsersActionsCell from "./UserActionsMenu";
import { canOpenUserPage } from "./utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { StandardSmallCell } from "../components/spreadsheet-view/components";
import Spreadsheet from "../components/spreadsheet-view/Spreadsheet";
import { SpreadsheetContext } from "../components/spreadsheet-view/SpreadsheetContext";
import { renderCollaboratorsCell, renderOfficeCell, renderRolesCell } from "../components/spreadsheet-view/table-cells";
import { DAYS_IN_STAGE_COLUMN } from "../components/spreadsheet-view/utils";
import useStages from "../hooks/useStages";
import { groupBy, indexBy } from "../utils/utils";
import TerminateUserDialog from "./components/TerminateUserDialog";
const Users = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const stage = (_a = props.stage) !== null && _a !== void 0 ? _a : undefined;
    const limit = (_b = props.limit) !== null && _b !== void 0 ? _b : 1000;
    const offset = (_c = props.offset) !== null && _c !== void 0 ? _c : 0;
    const showNewsfeed = (_d = props.showNewsfeed) !== null && _d !== void 0 ? _d : false;
    const { search, view } = props;
    const [isTerminatedDialogOpen, setIsTerminatedDialogOpen] = React.useState(false);
    const [userToBeTerminated, setUserToBeTerminated] = useStateWithCallback(null);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const errorDialog = useErrorDialog();
    const session = useSession();
    const context = React.useContext(SpreadsheetContext);
    const bulkDeleteApi = useBulkDeleteUsers();
    const { listUserStagesApi: listStagesApi, setUserStage, userStages: stages } = useStages();
    const accessToUsersApi = usePostQuery(searchUsers, { is_in_my_purview: true, limit: 1000 }, INFINITE_CACHE_PARAMS);
    const accessToUserUids = React.useMemo(() => {
        var _a, _b;
        return new Set((_b = (_a = accessToUsersApi.data) === null || _a === void 0 ? void 0 : _a.data.map((user) => user.uid)) !== null && _b !== void 0 ? _b : []);
    }, [(_e = accessToUsersApi.data) === null || _e === void 0 ? void 0 : _e.data]);
    const requestData = React.useMemo(() => {
        var _a;
        return pickBy({ search, view, stage_uids: stage ? [stage] : undefined, filter: (_a = context === null || context === void 0 ? void 0 : context.filter) !== null && _a !== void 0 ? _a : undefined }, (val) => val !== undefined);
    }, [context === null || context === void 0 ? void 0 : context.filter, search, stage, view]);
    const paginatedRequestData = React.useMemo(() => {
        return Object.assign(Object.assign({}, requestData), { limit, offset });
    }, [requestData, limit, offset]);
    const searchUsersApi = usePostQuery(searchUsers, paginatedRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = searchUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [searchUsersApi.data]);
    const countItemsApi = usePostQuery(countUsers, requestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const count = (_g = (_f = countItemsApi.data) === null || _f === void 0 ? void 0 : _f.data.count) !== null && _g !== void 0 ? _g : null;
    const totalItemsApi = usePostQuery(countUsers, {}, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const total = (_j = (_h = totalItemsApi.data) === null || _h === void 0 ? void 0 : _h.data.count) !== null && _j !== void 0 ? _j : null;
    const listTeamsApi = useListTeams(REFETCH_CACHE_PARAMS);
    const teams = (_l = (_k = listTeamsApi.data) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : null;
    const countByStageApi = usePostQuery(countUsersByStage, omit(requestData, "stage_uids"), FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const countByStage = (_o = (_m = countByStageApi.data) === null || _m === void 0 ? void 0 : _m.data) !== null && _o !== void 0 ? _o : null;
    const isUserEditable = React.useCallback((userUid) => {
        return accessToUserUids.size > 1 && accessToUserUids.has(userUid);
    }, [accessToUserUids]);
    const items = React.useMemo(() => {
        return users
            ? users
                .map((item) => (Object.assign({}, item)))
                .sort((a, b) => {
                var _a, _b;
                if (count != null && count <= limit) {
                    const aIsMe = a.uid === ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid);
                    const bIsMe = b.uid === ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.uid);
                    if (aIsMe && !bIsMe) {
                        return -1;
                    }
                    if (!aIsMe && bIsMe) {
                        return 1;
                    }
                    const aIsEditable = isUserEditable(a.uid);
                    const bIsEditable = isUserEditable(b.uid);
                    if (aIsEditable && !bIsEditable) {
                        return -1;
                    }
                    if (!aIsEditable && bIsEditable) {
                        return 1;
                    }
                }
                return 0;
            })
            : null;
    }, [count, isUserEditable, limit, (_p = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _p === void 0 ? void 0 : _p.uid, users]);
    const countByStage_allStages = React.useMemo(() => {
        return stages && countByStage
            ? stages.map((s) => { var _a, _b; return ({ stage: s, count: (_b = (_a = countByStage.find((c) => { var _a; return ((_a = c.stage) === null || _a === void 0 ? void 0 : _a.uid) === s.uid; })) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0 }); })
            : null;
    }, [stages, countByStage]);
    const updateSearch = useUpdateSearch();
    const countByGroup = React.useMemo(() => {
        return countByStage_allStages
            ? countByStage_allStages.map((item) => {
                var _a, _b, _c, _d, _e, _f;
                return ({
                    group: {
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        uid: (_b = (_a = item.stage) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : "",
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        name: (_d = (_c = item.stage) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "Not Set",
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        color: (_e = stages === null || stages === void 0 ? void 0 : stages.find((s) => { var _a; return s.uid === ((_a = item.stage) === null || _a === void 0 ? void 0 : _a.uid); })) === null || _e === void 0 ? void 0 : _e.color,
                        onClick: () => {
                            var _a, _b, _c;
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            updateSearch("stage", stage === ((_a = item.stage) === null || _a === void 0 ? void 0 : _a.uid) ? null : ((_c = (_b = item.stage) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : null), "offset", null);
                        },
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        isSelected: stage === ((_f = item.stage) === null || _f === void 0 ? void 0 : _f.uid),
                    },
                    count: item.count,
                });
            })
            : null;
    }, [countByStage_allStages, stage, stages, updateSearch]);
    const listFieldsApi = useListFields({ entity_type: "User" }, REFETCH_CACHE_PARAMS);
    const fields = (_r = (_q = listFieldsApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const itemsByUid = React.useMemo(() => {
        return items ? groupBy(items, "uid") : null;
    }, [items]);
    const itemsIndexedByUid = React.useMemo(() => {
        return items ? indexBy(items, "uid") : null;
    }, [items]);
    const stagesByUid = React.useMemo(() => {
        return stages ? groupBy(stages, "uid") : null;
    }, [stages]);
    const createItemApi = useAddUser();
    const createItem = React.useCallback(() => {
        createItemApi
            .mutateAsync({ data: { fields: {} } })
            .then((result) => {
            navigate(`/users/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [createItemApi, errorDialog, navigate]);
    const onBulkUpdate = React.useCallback((items_) => {
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev === null || prev === void 0 ? void 0 : prev.data.map((item) => {
                    const updatedItem = items_.find((x) => x.uid === item.uid);
                    return updatedItem !== null && updatedItem !== void 0 ? updatedItem : item;
                }) });
        });
    }, [queryClient, searchUsersApi.queryKey]);
    const bulkDelete = React.useCallback((selectedUids) => {
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev === null || prev === void 0 ? void 0 : prev.data.filter((item) => !selectedUids.includes(item.uid)) });
        });
        queryClient.setQueryData(countItemsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        queryClient.setQueryData(countByStageApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev === null || prev === void 0 ? void 0 : prev.data.map((item) => (Object.assign(Object.assign({}, item), { count: item.count -
                        selectedUids.filter((uid) => { var _a; return !item.stage || !((_a = itemsByUid === null || itemsByUid === void 0 ? void 0 : itemsByUid[uid]) === null || _a === void 0 ? void 0 : _a.stage) || itemsByUid[uid].stage.uid === item.stage.uid; }).length }))) });
        });
        queryClient.setQueryData(totalItemsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        bulkDeleteApi
            .mutateAsync({ data: { uids: selectedUids } })
            .then(() => {
            enqueueSnackbar(`${selectedUids.length} buyer leads deleted`, { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [
        bulkDeleteApi,
        countItemsApi.queryKey,
        countByStageApi.queryKey,
        itemsByUid,
        queryClient,
        searchUsersApi.queryKey,
        totalItemsApi.queryKey,
    ]);
    const refresh = React.useCallback(() => {
        searchUsersApi.refetch();
        countItemsApi.refetch();
        countByStageApi.refetch();
    }, [countByStageApi, countItemsApi, searchUsersApi]);
    const getPOSTApiEndpoint = React.useCallback((row) => {
        return `/api/users/${row.uid}`;
    }, []);
    const getMailMergeAddressesForItem = React.useCallback((row) => {
        return [row.email].filter((x) => x).map((x) => x);
    }, []);
    const renderLaunchColumn = React.useCallback((params) => {
        var _a;
        const canOpenUserPage_ = ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.profile)
            ? canOpenUserPage(session.viewingAsUser.profile, params.row, { teams: teams !== null && teams !== void 0 ? teams : undefined })
            : false;
        const url = `/users/${params.row.uid}`;
        return (canOpenUserPage_ && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Open item") },
            React.createElement("span", null,
                React.createElement(IconButton, { size: "small", component: RouterLink, to: url, state: {
                        from: [window.location.pathname, window.location.search].filter((o) => o).join(""),
                    }, tabIndex: -1 },
                    React.createElement(Launch, { fontSize: "small" }))))));
    }, [(_s = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _s === void 0 ? void 0 : _s.profile, teams]);
    const updateUserState = React.useCallback((user) => {
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === user.uid) {
                        return user;
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchUsersApi.queryKey]);
    const renderActionsColumn = React.useCallback((params) => {
        return (React.createElement(UsersActionsCell, { user: params.row, onTwilioPhoneNumberPurchased: updateUserState, onTwilioPhoneNumberReleased: updateUserState }));
    }, [updateUserState]);
    const onStagesSaved = React.useCallback((allStages) => {
        queryClient.setQueryData(listStagesApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: allStages });
        });
    }, [listStagesApi.queryKey, queryClient]);
    const nonStandardColumns = [
        {
            field: "collaborators",
            headerName: "Collaborators",
            renderCell: renderCollaboratorsCell,
            editable: false,
        },
        {
            field: "roles",
            headerName: "Roles",
            renderCell: renderRolesCell,
            editable: false,
        },
        {
            field: "office",
            headerName: "Office",
            renderCell: renderOfficeCell,
            editable: false,
        },
        DAYS_IN_STAGE_COLUMN,
    ];
    const onFieldChanged = React.useCallback((params) => {
        const { field, row, value } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [
                    ...prev.data.slice(0, fullIndx),
                    Object.assign(Object.assign({}, prev.data[fullIndx]), { fields: Object.assign(Object.assign({}, prev.data[fullIndx].fields), { [field]: value }) }),
                    ...prev.data.slice(fullIndx + 1, prev.data.length),
                ] });
        });
    }, [itemsIndexedByUid, queryClient, searchUsersApi.queryKey]);
    const onFieldWillSave = onFieldChanged;
    const onFieldSaved = React.useCallback((params) => {
        const { response, row } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [...prev.data.slice(0, fullIndx), response.data, ...prev.data.slice(fullIndx + 1, prev.data.length)] });
        });
    }, [itemsIndexedByUid, queryClient, searchUsersApi.queryKey]);
    const page = React.useMemo(() => {
        return Math.floor(offset / limit) + 1;
    }, [offset, limit]);
    const renderListItem = React.useCallback((item) => {
        return (React.createElement(StandardSmallCell, { name: item.name, stage: stagesByUid && item.stage ? stagesByUid[item.stage.uid] : undefined, 
            // @ts-expect-error: readonly
            collaborators: item.collaborators, link: `/users/${item.uid}`, allLinks: items === null || items === void 0 ? void 0 : items.map((i) => `/users/${i.uid}`) }));
    }, [items, stagesByUid]);
    const isCellEditable = React.useCallback((params) => {
        const userUid = params.row.id;
        return isUserEditable(userUid);
    }, [isUserEditable]);
    const onTerminateUser = React.useCallback((user) => () => {
        setUserToBeTerminated(user, () => {
            setIsTerminatedDialogOpen(true);
        });
    }, [setUserToBeTerminated]);
    const closeTerminatedDialog = React.useCallback(() => {
        setUserToBeTerminated(null, () => {
            setIsTerminatedDialogOpen(false);
        });
    }, [setUserToBeTerminated]);
    const onBeforeRowStageUpdate = React.useCallback((userUid, stageUid) => {
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === userUid) {
                        return Object.assign(Object.assign({}, item), { stage: Object.assign(Object.assign({}, (stages !== null && stages !== void 0 ? stages : []).find((stage_) => {
                                return stage_.uid === stageUid;
                            })), { updated_at: dayjs_().format("YYYY-MM-DDThh:mm:ssZ") }) });
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchUsersApi.queryKey, stages]);
    const onRowStageUpdateError = React.useCallback((user) => () => {
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === user.uid) {
                        return user;
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchUsersApi.queryKey]);
    const onRowStageUpdateSuccess = React.useCallback((result) => {
        queryClient.setQueryData(searchUsersApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === result.data.uid) {
                        return result.data;
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchUsersApi.queryKey]);
    const onRowStageChange = React.useCallback((params) => {
        const { row, stageUid } = params;
        const user = users === null || users === void 0 ? void 0 : users.find((u) => u.uid === row);
        if (user) {
            if (user.stage.uid === stageUid) {
                console.log("No API call needed. User is already in the selected stage.");
                return;
            }
            setUserStage(row, stageUid, onTerminateUser(user), onBeforeRowStageUpdate, onRowStageUpdateError(user), onRowStageUpdateSuccess);
        }
        else {
            enqueueErrorSnackbar(`Could not find user with uid: ${row}`);
        }
    }, [onBeforeRowStageUpdate, onRowStageUpdateError, onRowStageUpdateSuccess, onTerminateUser, setUserStage, users]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Spreadsheet, { title: "Users", fields: fields, stages: stages, view: view, countByGroup: countByGroup !== null && countByGroup !== void 0 ? countByGroup : [], items: items, search: search, nonStandardColumns: nonStandardColumns, getPOSTApiEndpoint: getPOSTApiEndpoint, getMailMergeEmailAddressesForItem: getMailMergeAddressesForItem, renderLaunchColumn: renderLaunchColumn, renderActionsColumn: renderActionsColumn, entityType: "User", rowsPerPage: limit, count: count !== null && count !== void 0 ? count : null, total: total !== null && total !== void 0 ? total : null, page: page, onRefresh: refresh, onStagesSaved: onStagesSaved, onFieldChanged: onFieldChanged, onFieldWillSave: onFieldWillSave, onFieldSaved: onFieldSaved, onRowStageChange: onRowStageChange, renderListItem: renderListItem, slots: {
                addButton: (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add user") },
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: createItem, tabIndex: -1, disabled: createItemApi.isPending, color: "primary" },
                            React.createElement(AddOutlined, null))))),
            }, canEditStages: true, downloadFunc: downloadUsers, downloadFilename: "users.csv", loadingPercentComplete: undefined, isLoading: searchUsersApi.isFetching, showNewsfeed: showNewsfeed, error: searchUsersApi.error || countItemsApi.error || countByStageApi.error, onBulkUpdateStages: onBulkUpdate, onBulkDelete: ((_t = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _t === void 0 ? void 0 : _t.hasRole(["Superadmin", "Admin"])) ? bulkDelete : undefined, isCellEditable: isCellEditable }),
        isTerminatedDialogOpen && userToBeTerminated && (React.createElement(TerminateUserDialog, { user: userToBeTerminated, onCancel: closeTerminatedDialog, onComplete: closeTerminatedDialog }))));
};
export default Users;
