var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { PSP_COMP_QUALITY_MAPPING } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { listCmaComps, useCreateCmaComp, useDeleteCmaComp, useReorderCmaComps, useUpdateCmaComp, } from "@app/orval/api/cma-comps";
import { useListFields } from "@app/orval/api/field-definitions";
import { getGoogleMapsStreetViewPhoto } from "@app/orval/api/geocode";
import { useCreatePspOffer, useDeletePspOffer, useReorderPspOffers } from "@app/orval/api/offers";
import { useListPspCompTypes } from "@app/orval/api/psp-comp-types";
import { useCreatePspComp, useDeletePspComp, useReorderPspComps, useUpdatePspComp } from "@app/orval/api/psp-comps";
import { useCreatePspFile, useDeletePspFile, useListPspFiles, useReorderPspFiles } from "@app/orval/api/psp-files";
import { useCreatePspPropertyPhoto, useDeletePspPropertyPhoto, useListPspPropertyPhotos, useReorderPspPropertyPhotos, } from "@app/orval/api/psp-photos";
import { useListPspReportTemplates } from "@app/orval/api/psp-report-templates";
import { useGetPsp, useSubmitPsp, useUpdatePsp } from "@app/orval/api/psps";
import { useListUsers } from "@app/orval/api/users";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { fileToBase64 } from "@app/util/Utils";
const useMAtrixApis = (uid) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    // state
    /** Photo URL for the Streetview of the MAtrix Property Address */
    const [cmaCompsExtended, setCmaCompsExtended] = useStateWithCallback([]);
    const [isGettingCMACompsExtended, setIsGettingCMACompsExtended] = useStateWithCallback(false);
    const [isAddingPSPComp, setIsAddingPSPComp] = useStateWithCallback(false);
    const [isBulkAddingPSPComps, setIsBulkAddingPSPComps] = useStateWithCallback(false);
    const [isBulkUpdatingPSPComps, setIsBulkUpdatingPSPComps] = useStateWithCallback(false);
    const [pspCompsCurrentlyUpdatingQuality, setPSPCompCurrentlyUpdatingQuality] = useStateWithCallback([]);
    const [isDeletingPSPComp, setIsDeletingPSPComp] = useStateWithCallback(false);
    const [isAddingCMAComp, setIsAddingCMAComp] = useStateWithCallback(false);
    const [isBulkAddingCMAComps, setIsBulkAddingCMAComps] = useStateWithCallback(false);
    const [isBulkUpdatingCMAComps, setIsBulkUpdatingCMAComps] = useStateWithCallback(false);
    const [isDeletingCMAComp, setIsDeletingCMAComp] = useStateWithCallback(false);
    const [isAddingOffer, setIsAddingOffer] = useStateWithCallback(false);
    const [isUploadingFile, setIsUploadingFile] = useStateWithCallback(false);
    const [isUploadingPropertyPhoto, setIsUploadingPropertyPhoto] = useStateWithCallback(false);
    const [isBulkUpdatingFields, setIsBulkUpdatingFields] = useStateWithCallback(false);
    // hooks
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const confirm = useConfirm();
    // api
    const getPSPApi = useGetPsp(uid);
    const matrix = (_b = (_a = getPSPApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const updatePSPApi = useUpdatePsp();
    const listPSPFieldsApi = useListFields({ entity_type: "MAtrix" }, DEFAULT_CACHE_PARAMS);
    const matrixFieldDefinitions = (_d = (_c = listPSPFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listUsersApi = useListUsers();
    const users = (_f = (_e = listUsersApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    // toolbar
    const listPSPReportTemplateApi = useListPspReportTemplates();
    const pspReportTemplatesUnfiltered = (_h = (_g = listPSPReportTemplateApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    // TODO: will need a better solution to handle test templates for legacy reports
    // probably will need to do a larger update on legacy reports
    const pspReportTemplates = useMemo(() => {
        var _a;
        return ((_a = pspReportTemplatesUnfiltered === null || pspReportTemplatesUnfiltered === void 0 ? void 0 : pspReportTemplatesUnfiltered.filter((template) => {
            return !template.name.toLowerCase().includes("test");
        })) !== null && _a !== void 0 ? _a : null);
    }, [pspReportTemplatesUnfiltered]);
    const submitTrainingMAtrixApi = useSubmitPsp();
    // psp comps
    const listPSPCompTypesApi = useListPspCompTypes();
    const pspCompTypes = (_k = (_j = listPSPCompTypesApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const listPSPCompFieldsApi = useListFields({ entity_type: "PSP Comp" }, DEFAULT_CACHE_PARAMS);
    const pspCompFieldDefinitions = (_m = (_l = listPSPCompFieldsApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const updatePSPCompApi = useUpdatePspComp();
    const addPSPCompApi = useCreatePspComp();
    const deletePSPCompApi = useDeletePspComp();
    const reorderPSPCompsApi = useReorderPspComps();
    // cma comps
    const listCMACompFieldsApi = useListFields({ entity_type: "Comp" }, DEFAULT_CACHE_PARAMS);
    const cmaCompFieldDefinitions = (_p = (_o = listCMACompFieldsApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const addCMACompApi = useCreateCmaComp();
    const updateCMACompApi = useUpdateCmaComp();
    const deleteCMACompApi = useDeleteCmaComp();
    const reorderCMACompsApi = useReorderCmaComps();
    // files tab
    const listPSPFilesApi = useListPspFiles({ matrix_uids: uid });
    const pspFiles = (_r = (_q = listPSPFilesApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const addPSPFileApi = useCreatePspFile();
    const deletePSPFileApi = useDeletePspFile();
    const reorderPSPFilesApi = useReorderPspFiles();
    const listPSPPhotosApi = useListPspPropertyPhotos({ matrix_uids: uid });
    const pspPhotos = (_t = (_s = listPSPPhotosApi.data) === null || _s === void 0 ? void 0 : _s.data) !== null && _t !== void 0 ? _t : null;
    const reorderPSPPhotosApi = useReorderPspPropertyPhotos();
    const addPSPPhotoApi = useCreatePspPropertyPhoto();
    const deletePSPPhotoApi = useDeletePspPropertyPhoto();
    // offers
    const listOffersFieldsApi = useListFields({ entity_type: "Offer" }, DEFAULT_CACHE_PARAMS);
    const offersFieldDefinitions = (_v = (_u = listOffersFieldsApi.data) === null || _u === void 0 ? void 0 : _u.data) !== null && _v !== void 0 ? _v : null;
    const addOfferApi = useCreatePspOffer();
    const deleteOfferApi = useDeletePspOffer();
    const reorderOffersApi = useReorderPspOffers();
    useEffect(() => {
        // get streetview photo for property address
        // list cma comps
        setIsGettingCMACompsExtended(true, () => {
            listCmaComps({ matrix_uids: uid })
                .then((result) => {
                const cmaComps = result.data;
                setCmaCompsExtended(cmaComps, () => {
                    for (const comp of cmaComps) {
                        if (comp.fields["Address"]) {
                            getGoogleMapsStreetViewPhoto({ address: comp.fields["Address"] })
                                .then((streetViewResult) => {
                                setCmaCompsExtended((prev) => {
                                    return prev.map((a) => {
                                        if (a.uid === comp.uid) {
                                            return Object.assign(Object.assign({}, a), { streetviewPhotoUrl: streetViewResult.data.photo_url });
                                        }
                                        return a;
                                    });
                                });
                            })
                                .catch((error) => {
                                console.error(error);
                            });
                        }
                    }
                });
            })
                .catch((error) => {
                errorDialog(error);
            })
                .then(() => {
                setIsGettingCMACompsExtended(false);
            });
        });
    }, [uid]);
    /** currently using for MAtrixShareDialog. Should be able to delete when that component is updated */
    const refreshPSP = useCallback((newMAtrix) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: newMAtrix });
        });
    }, [getPSPApi.queryKey, queryClient]);
    const onFieldSaveBegin = useCallback((fieldName) => (fieldValue) => {
        console.log("begin field save", fieldName, fieldValue);
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), { [fieldName]: fieldValue }) }) });
        });
    }, [getPSPApi.queryKey, queryClient]);
    const onFieldSaveComplete = useCallback((response) => {
        console.log("field save complete", response.data);
        // queryClient.setQueryData(getPSPApi.queryKey, responsse);
        getPSPApi.refetch();
    }, [getPSPApi]);
    const bulkUpdateFields = useCallback((fields) => {
        console.log("bulk update fields", fields);
        if (Object.keys(fields).length > 0) {
            setIsBulkUpdatingFields(true, () => {
                enqueueSnackbar(`Updating ${Object.keys(fields).length} fields...`, { variant: "info" });
                queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), fields) }) });
                });
                updatePSPApi
                    .mutateAsync({
                    uid,
                    data: {
                        fields,
                    },
                })
                    .then(() => {
                    // queryClient.setQueryData(getPSPApi.queryKey, result);
                    confirm({
                        title: `Updated ${Object.keys(fields).length} fields.`,
                        description: JSON.stringify(fields, null, 2),
                    });
                    // enqueueSnackbar(`Updated ${Object.keys(fields).length} fields`, { variant: "success" });
                })
                    .catch(errorDialog)
                    .then(() => {
                    setIsBulkUpdatingFields(false);
                    getPSPApi.refetch();
                });
            });
        }
        else {
            enqueueSnackbar("No fields to update", { variant: "warning" });
        }
    }, [confirm, errorDialog, getPSPApi, queryClient, setIsBulkUpdatingFields, uid, updatePSPApi]);
    const submitTrainingMAtrix = useCallback(() => {
        const loadingSnackbar = enqueueSnackbar("Submitting training matrix...", {
            variant: "info",
            persist: true,
        });
        submitTrainingMAtrixApi
            .mutateAsync({ uid })
            .then(() => {
            enqueueSnackbar("Submitted training matrix.", { variant: "success" });
            // navigate("/psps");
        })
            .catch((error) => {
            console.error(error);
            errorDialog(error);
        })
            .finally(() => {
            closeSnackbar(loadingSnackbar);
        });
    }, [errorDialog, submitTrainingMAtrixApi, uid]);
    const addPSPComp = useCallback((compType, params) => {
        console.log("create psp comp with type: ", compType);
        setIsAddingPSPComp(true, () => {
            addPSPCompApi
                .mutateAsync({
                data: {
                    matrix_uid: uid,
                    comp_type: compType,
                    fields: {},
                },
            })
                .then((result) => {
                queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: [...old.data.psp_comps, result.data] }) });
                });
                if (params === null || params === void 0 ? void 0 : params.onCompCreated) {
                    params.onCompCreated(result.data);
                }
            })
                .catch(errorDialog)
                .then(() => {
                setIsAddingPSPComp(false);
            });
        });
    }, [addPSPCompApi, errorDialog, getPSPApi.queryKey, queryClient, setIsAddingPSPComp, uid]);
    const onPSPCompFieldSaveBegin = useCallback((pspCompUid, fieldName, fieldValue) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.psp_comps.findIndex((x) => x.uid === pspCompUid);
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: [
                        ...old.data.psp_comps.slice(0, indx),
                        Object.assign(Object.assign({}, old.data.psp_comps[indx]), { fields: Object.assign(Object.assign({}, old.data.psp_comps[indx].fields), { [fieldName]: fieldValue }) }),
                        ...old.data.psp_comps.slice(indx + 1, old.data.psp_comps.length),
                    ] }) });
        });
    }, [getPSPApi.queryKey, queryClient]);
    const onPSPCompFieldSaveComplete = useCallback((response) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.psp_comps.findIndex((x) => x.uid === response.data.uid);
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: [
                        ...old.data.psp_comps.slice(0, indx),
                        response.data,
                        ...old.data.psp_comps.slice(indx + 1, old.data.psp_comps.length),
                    ] }) });
        });
        getPSPApi.refetch();
    }, [getPSPApi, queryClient]);
    /**
     * Updates the `Quality` field of a PSP Comp and updates the `Comments` field
     * of the PSP to reflect the change.
     */
    const onPSPCompQualityUpdate = useCallback((pspCompUid, quality) => {
        setPSPCompCurrentlyUpdatingQuality((prev) => {
            return [...prev, pspCompUid];
        }, () => {
            updatePSPCompApi
                .mutateAsync({ uid: pspCompUid, data: { fields: { "Comp Quality": quality } } })
                .then((result) => {
                var _a;
                onPSPCompFieldSaveComplete(result);
                let matrixComments = matrix.fields["Comments"];
                const compQualityComments = `${(_a = result.data.fields["Address"]) !== null && _a !== void 0 ? _a : result.data.uid} was given a quality rating of 'Best' as it is most similar to the subject. This property has been given a slightly higher value in the DASH range.`;
                if (PSP_COMP_QUALITY_MAPPING[quality].toLowerCase().trim() === "best") {
                    // add comments if switching to best
                    matrixComments = matrixComments ? `${matrixComments}\n\n${compQualityComments}` : compQualityComments;
                }
                else if (matrixComments === null || matrixComments === void 0 ? void 0 : matrixComments.includes(compQualityComments)) {
                    // remove comments if switching from best
                    matrixComments = matrixComments.replace(compQualityComments, "");
                }
                if (matrixComments !== matrix.fields["Comments"]) {
                    // update PSP Comments if there was a change
                    onFieldSaveBegin("Comments")(matrixComments);
                    updatePSPApi
                        .mutateAsync({
                        uid,
                        data: {
                            fields: { Comments: matrixComments },
                        },
                    })
                        .then(console.log)
                        .catch(errorDialog)
                        .then(() => {
                        setPSPCompCurrentlyUpdatingQuality((prev) => {
                            return prev.filter((a) => a !== pspCompUid);
                        });
                    });
                }
            })
                .catch(errorDialog)
                .then(() => {
                setPSPCompCurrentlyUpdatingQuality((prev) => {
                    return prev.filter((a) => a !== pspCompUid);
                });
            });
        });
    }, [
        errorDialog,
        matrix,
        onFieldSaveBegin,
        onPSPCompFieldSaveComplete,
        setPSPCompCurrentlyUpdatingQuality,
        uid,
        updatePSPApi,
        updatePSPCompApi,
    ]);
    /**
     * Bulk creates PSP Comps. Currently, this can be done through Comps Finder or the Toolbar.
     * Redirects to the PSP Comps page after completion.
     */
    const bulkCreatePSPComps = useCallback((compsToCreate, shouldRedirect) => {
        setIsBulkAddingPSPComps(true, () => {
            Promise.all(compsToCreate.map((comp) => __awaiter(void 0, void 0, void 0, function* () {
                return addPSPCompApi.mutateAsync({ data: comp }).then((result) => {
                    queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: [...old.data.psp_comps, result.data] }) });
                    });
                });
            })))
                .catch(errorDialog)
                .then(() => {
                setIsBulkAddingPSPComps(false);
                if (shouldRedirect) {
                    // redirect to psp comps page
                    getPSPApi.refetch();
                    navigate(`/matrix/${uid}/comps`);
                }
            });
        });
    }, [addPSPCompApi, errorDialog, getPSPApi, navigate, queryClient, setIsBulkAddingPSPComps, uid]);
    /** Bulk creates new PSP Comps and updates existing ones. When finished, redirects to the PSP Comps tab */
    const bulkUpdateAndCreatePSPComps = useCallback((compsToUpdate, compsToCreate) => {
        setIsBulkAddingPSPComps(true, () => {
            Promise.all(compsToCreate.map((comp) => __awaiter(void 0, void 0, void 0, function* () {
                return addPSPCompApi.mutateAsync({ data: comp }).then((result) => {
                    queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: [...old.data.psp_comps, result.data] }) });
                    });
                });
            })))
                .catch(errorDialog)
                .then(() => {
                setIsBulkAddingPSPComps(false, () => {
                    if (!isBulkUpdatingPSPComps) {
                        // redirect to psp comps page
                        getPSPApi.refetch();
                        navigate(`/matrix/${uid}/comps`);
                    }
                });
            });
        });
        setIsBulkUpdatingPSPComps(true, () => {
            Promise.all(compsToUpdate.map((comp) => __awaiter(void 0, void 0, void 0, function* () {
                return updatePSPCompApi.mutateAsync({ uid: comp.compUid, data: comp.params }).then((result) => {
                    queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: old.data.psp_comps.map((a) => (a.uid === result.data.uid ? result.data : a)) }) });
                    });
                });
            })))
                .catch(errorDialog)
                .then(() => {
                setIsBulkUpdatingPSPComps(false, () => {
                    if (!isBulkAddingPSPComps) {
                        // redirect to psp comps page
                        getPSPApi.refetch();
                        navigate(`/matrix/${uid}/comps`);
                    }
                });
            });
        });
    }, [
        addPSPCompApi,
        errorDialog,
        getPSPApi,
        isBulkAddingPSPComps,
        isBulkUpdatingPSPComps,
        navigate,
        queryClient,
        setIsBulkAddingPSPComps,
        setIsBulkUpdatingPSPComps,
        uid,
        updatePSPCompApi,
    ]);
    const deletePSPComp = useCallback((pspCompUid, params) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                setIsDeletingPSPComp(true, () => {
                    deletePSPCompApi
                        .mutateAsync({ uid: pspCompUid })
                        .then((result) => {
                        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                            if (!old) {
                                return old;
                            }
                            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: old.data.psp_comps.filter((a) => a.uid !== result.data.uid) }) });
                        });
                        if (params === null || params === void 0 ? void 0 : params.onDeleted) {
                            params.onDeleted(result.data.uid);
                        }
                    })
                        .catch(errorDialog)
                        .then(() => {
                        setIsDeletingPSPComp(false);
                        getPSPApi.refetch();
                    });
                });
            }
        });
    }, [confirm, deletePSPCompApi, errorDialog, getPSPApi, queryClient, setIsDeletingPSPComp]);
    const reorderPSPComps = useCallback((pspCompUids, params) => {
        if (params === null || params === void 0 ? void 0 : params.onReorderStart) {
            params.onReorderStart(pspCompUids);
        }
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psp_comps: [...old.data.psp_comps].sort((a, b) => {
                        const aIndx = pspCompUids.indexOf(a.uid);
                        const bIndx = pspCompUids.indexOf(b.uid);
                        if (aIndx < bIndx)
                            return -1;
                        if (aIndx > bIndx)
                            return 1;
                        return 0;
                    }) }) });
        });
        reorderPSPCompsApi
            .mutateAsync({ data: { uids: pspCompUids } })
            .then(console.log)
            .catch(errorDialog);
    }, [errorDialog, getPSPApi.queryKey, queryClient, reorderPSPCompsApi]);
    const addCMAComp = useCallback(() => {
        setIsAddingCMAComp(true, () => {
            addCMACompApi
                .mutateAsync({ data: { matrix_uid: uid, fields: {} } })
                .then((result) => {
                queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { cma_comps: [...old.data.cma_comps, result.data] }) });
                });
                setCmaCompsExtended((prev) => {
                    return [
                        ...prev,
                        Object.assign(Object.assign({}, result.data), { fields: {} }),
                    ];
                });
            })
                .catch(errorDialog)
                .then(() => {
                setIsAddingCMAComp(false);
            });
        });
    }, [addCMACompApi, errorDialog, getPSPApi.queryKey, queryClient, setCmaCompsExtended, setIsAddingCMAComp, uid]);
    const onCMACompFieldSaveComplete = useCallback((result) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { cma_comps: old.data.cma_comps.map((a) => (a.uid === result.data.uid ? result.data : a)) }) });
        });
        setCmaCompsExtended((prev) => {
            return prev.map((a) => a.uid === result.data.uid
                ? Object.assign(Object.assign({}, a), result.data) : a);
        });
    }, [getPSPApi.queryKey, queryClient, setCmaCompsExtended]);
    const bulkUpdateAndCreateCMAComps = useCallback((compsToUpdate, compsToCreate) => {
        setIsBulkAddingCMAComps(true, () => {
            Promise.all(compsToCreate.map((comp) => __awaiter(void 0, void 0, void 0, function* () {
                return addCMACompApi.mutateAsync({ data: comp }).then((result) => {
                    queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { cma_comps: [...old.data.cma_comps, result.data] }) });
                    });
                    setCmaCompsExtended((prev) => {
                        return prev.map((a) => a.uid === result.data.uid
                            ? Object.assign(Object.assign({}, a), result.data) : a);
                    });
                });
            })))
                .catch(errorDialog)
                .then(() => {
                setIsBulkAddingCMAComps(false, () => {
                    if (!isBulkUpdatingCMAComps) {
                        // redirect to psp comps page
                        navigate(`/matrix/${uid}/cma-comps`);
                    }
                });
            });
        });
        setIsBulkUpdatingCMAComps(true, () => {
            Promise.all(compsToUpdate.map((comp) => __awaiter(void 0, void 0, void 0, function* () {
                return updateCMACompApi.mutateAsync({ uid: comp.compUid, data: comp.params }).then((result) => {
                    queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { cma_comps: old.data.cma_comps.map((a) => (a.uid === result.data.uid ? result.data : a)) }) });
                    });
                });
            })))
                .catch(errorDialog)
                .then(() => {
                setIsBulkUpdatingCMAComps(false, () => {
                    if (!isBulkAddingCMAComps) {
                        // redirect to psp comps page
                        navigate(`/matrix/${uid}/cma-comps`);
                    }
                });
            });
        });
    }, [
        addCMACompApi,
        errorDialog,
        getPSPApi.queryKey,
        isBulkAddingCMAComps,
        isBulkUpdatingCMAComps,
        navigate,
        queryClient,
        setCmaCompsExtended,
        setIsBulkAddingCMAComps,
        setIsBulkUpdatingCMAComps,
        uid,
        updateCMACompApi,
    ]);
    const reorderCMAComps = useCallback((cmaCompUids) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { cma_comps: [...old.data.cma_comps].sort((a, b) => {
                        const aIndx = cmaCompUids.indexOf(a.uid);
                        const bIndx = cmaCompUids.indexOf(b.uid);
                        if (aIndx < bIndx)
                            return -1;
                        if (aIndx > bIndx)
                            return 1;
                        return 0;
                    }) }) });
        });
        reorderCMACompsApi
            .mutateAsync({ data: { uids: cmaCompUids } })
            .then(() => {
            setCmaCompsExtended((prev) => {
                return prev.sort((a, b) => {
                    const aIndx = cmaCompUids.indexOf(a.uid);
                    const bIndx = cmaCompUids.indexOf(b.uid);
                    if (aIndx < bIndx)
                        return -1;
                    if (aIndx > bIndx)
                        return 1;
                    return 0;
                });
            });
        })
            .catch(errorDialog);
    }, [errorDialog, getPSPApi.queryKey, queryClient, reorderCMACompsApi, setCmaCompsExtended]);
    const deleteCMAComp = useCallback((cmaCompUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                setIsDeletingCMAComp(true, () => {
                    deleteCMACompApi
                        .mutateAsync({ uid: cmaCompUid })
                        .then((result) => {
                        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                            if (!old) {
                                return old;
                            }
                            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { cma_comps: old.data.cma_comps.filter((a) => a.uid !== result.data.uid) }) });
                        });
                        setCmaCompsExtended((prev) => {
                            return prev.filter((a) => a.uid !== result.data.uid);
                        });
                    })
                        .catch(errorDialog)
                        .then(() => {
                        setIsDeletingCMAComp(false);
                    });
                });
            }
        });
    }, [
        confirm,
        deleteCMACompApi,
        errorDialog,
        getPSPApi.queryKey,
        queryClient,
        setCmaCompsExtended,
        setIsDeletingCMAComp,
    ]);
    /**
     * Adds a new file to the PSP via API
     */
    const addFile = useCallback((files) => {
        if (files.length > 0) {
            setIsUploadingFile(true, () => {
                for (const file of Array.from(files)) {
                    fileToBase64(file).then((result) => {
                        const data = {
                            matrix_uid: uid,
                            content: result,
                            filename: file.name,
                        };
                        addPSPFileApi
                            .mutateAsync({ data })
                            .then((uploadResult) => {
                            queryClient.setQueryData(listPSPFilesApi.queryKey, (old) => {
                                if (!old) {
                                    return old;
                                }
                                return Object.assign(Object.assign({}, old), { data: [...old.data, uploadResult.data] });
                            });
                        })
                            .catch(errorDialog)
                            .then(() => {
                            setIsUploadingFile(false);
                        });
                    });
                }
            });
        }
    }, [addPSPFileApi, errorDialog, listPSPFilesApi.queryKey, queryClient, setIsUploadingFile, uid]);
    /**
     * Deletes the specified PSP File via API
     * @param commentUid
     */
    const deleteFile = useCallback((fileUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listPSPFilesApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((file) => file.uid !== fileUid) });
                });
            }
        });
        deletePSPFileApi.mutateAsync({ uid: fileUid }).then(console.log).catch(console.error);
    }, [confirm, deletePSPFileApi, listPSPFilesApi.queryKey, queryClient]);
    const reorderPSPFiles = useCallback((fileUids) => {
        queryClient.setQueryData(listPSPFilesApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: old.data.sort((a, b) => {
                    const aIndx = fileUids.indexOf(a.uid);
                    const bIndx = fileUids.indexOf(b.uid);
                    if (aIndx < bIndx)
                        return -1;
                    if (aIndx > bIndx)
                        return 1;
                    return 0;
                }) });
        });
        reorderPSPFilesApi
            .mutateAsync({ data: { uids: fileUids } })
            .then(console.log)
            .catch(errorDialog);
    }, [errorDialog, listPSPFilesApi.queryKey, queryClient, reorderPSPFilesApi]);
    /**
     * Adds a new photo to the PSP via API
     */
    const addPhoto = useCallback((files) => {
        if (files.length > 0) {
            setIsUploadingPropertyPhoto(true, () => {
                for (const file of Array.from(files)) {
                    fileToBase64(file).then((result) => {
                        const data = {
                            matrix_uid: uid,
                            content: result,
                            filename: file.name,
                        };
                        addPSPPhotoApi
                            .mutateAsync({ data })
                            .then((uploadResult) => {
                            queryClient.setQueryData(listPSPPhotosApi.queryKey, (old) => {
                                if (!old) {
                                    return old;
                                }
                                return Object.assign(Object.assign({}, old), { data: [...old.data, uploadResult.data] });
                            });
                        })
                            .catch(errorDialog)
                            .then(() => {
                            setIsUploadingPropertyPhoto(false);
                        });
                    });
                }
            });
        }
    }, [addPSPPhotoApi, errorDialog, listPSPPhotosApi.queryKey, queryClient, setIsUploadingPropertyPhoto, uid]);
    /**
     * Deletes the specified PSP Photo via API
     * @param commentUid
     */
    const deletePhoto = useCallback((photoUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listPSPPhotosApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((photo) => photo.uid !== photoUid) });
                });
            }
        });
        deletePSPPhotoApi.mutateAsync({ uid: photoUid }).then(console.log).catch(console.error);
    }, [confirm, deletePSPPhotoApi, listPSPPhotosApi.queryKey, queryClient]);
    const reorderPSPPhotos = useCallback((photoUids) => {
        queryClient.setQueryData(listPSPPhotosApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: old.data.sort((a, b) => {
                    const aIndx = photoUids.indexOf(a.uid);
                    const bIndx = photoUids.indexOf(b.uid);
                    if (aIndx < bIndx)
                        return -1;
                    if (aIndx > bIndx)
                        return 1;
                    return 0;
                }) });
        });
        reorderPSPPhotosApi
            .mutateAsync({ data: { uids: photoUids } })
            .then(console.log)
            .catch(errorDialog);
    }, [errorDialog, listPSPPhotosApi.queryKey, queryClient, reorderPSPPhotosApi]);
    const addOffer = useCallback(() => {
        setIsAddingOffer(true, () => {
            addOfferApi
                .mutateAsync({ data: { matrix_uid: uid, fields: {} } })
                .then((result) => {
                queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { offers: [...old.data.offers, result.data] }) });
                });
            })
                .catch(errorDialog)
                .then(() => {
                setIsAddingOffer(false);
            });
        });
    }, [addOfferApi, errorDialog, getPSPApi.queryKey, queryClient, setIsAddingOffer, uid]);
    const onOfferFieldSaveComplete = useCallback((result) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { offers: old.data.offers.map((a) => (a.uid === result.data.uid ? result.data : a)) }) });
        });
    }, [getPSPApi.queryKey, queryClient]);
    const deleteOffer = useCallback((offerUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteOfferApi
                    .mutateAsync({ uid: offerUid })
                    .then((result) => {
                    queryClient.setQueryData(getPSPApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { offers: old.data.offers.filter((a) => a.uid !== result.data.uid) }) });
                    });
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteOfferApi, errorDialog, getPSPApi.queryKey, queryClient]);
    const reorderOffers = useCallback((offerUids) => {
        queryClient.setQueryData(getPSPApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { offers: [...old.data.offers].sort((a, b) => {
                        const aIndx = offerUids.indexOf(a.uid);
                        const bIndx = offerUids.indexOf(b.uid);
                        if (aIndx < bIndx)
                            return -1;
                        if (aIndx > bIndx)
                            return 1;
                        return 0;
                    }) }) });
        });
        reorderOffersApi
            .mutateAsync({ data: { uids: offerUids } })
            .then(console.log)
            .catch(errorDialog);
    }, [errorDialog, getPSPApi.queryKey, queryClient, reorderOffersApi]);
    const pspError = (_w = getPSPApi.error) !== null && _w !== void 0 ? _w : listPSPFieldsApi.error;
    const propertyAddress = matrix ? matrix.fields["Property Address"] : null;
    return {
        matrix,
        matrixFieldDefinitions,
        pspFiles,
        pspPhotos,
        users,
        pspReportTemplates,
        pspCompTypes,
        pspCompFieldDefinitions,
        cmaCompsExtended,
        cmaCompFieldDefinitions,
        offersFieldDefinitions,
        refreshPSP,
        onFieldSaveBegin,
        onFieldSaveComplete,
        bulkUpdateFields,
        addFile,
        deleteFile,
        reorderPSPFiles,
        addPhoto,
        deletePhoto,
        reorderPSPPhotos,
        submitTrainingMAtrix,
        addPSPComp,
        bulkCreatePSPComps,
        bulkUpdateAndCreatePSPComps,
        onPSPCompFieldSaveBegin,
        onPSPCompFieldSaveComplete,
        onPSPCompQualityUpdate,
        deletePSPComp,
        reorderPSPComps,
        addCMAComp,
        onCMACompFieldSaveComplete,
        bulkUpdateAndCreateCMAComps,
        reorderCMAComps,
        deleteCMAComp,
        addOffer,
        onOfferFieldSaveComplete,
        deleteOffer,
        reorderOffers,
        isGettingCMACompsExtended,
        isAddingPSPComp,
        pspCompsCurrentlyUpdatingQuality,
        isDeletingPSPComp,
        isAddingCMAComp,
        isDeletingCMAComp,
        isAddingOffer,
        isUploadingFile,
        isUploadingPropertyPhoto,
        isBulkAddingPSPComps,
        isBulkUpdatingFields,
        propertyAddress,
        pspError,
    };
};
export default useMAtrixApis;
