import * as React from "react";
import { Delete } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, MenuItem, TextField } from "@mui/material";
import SavedViewBooleanFilter from "./components/SavedViewBooleanFilter";
import SavedViewChoiceFilter from "./components/SavedViewChoiceFilter";
import SavedViewDateFilter from "./components/SavedViewDateFilter";
import SavedViewMailScheduleTemplateFilter from "./components/SavedViewMailScheduleTemplateFilter";
import SavedViewMultipleChoiceFilter from "./components/SavedViewMultipleChoiceFilter";
import SavedViewNotesFilter from "./components/SavedViewNotesFilter";
import SavedViewNumberFilter from "./components/SavedViewNumberFilter";
import SavedViewOfficeFilter from "./components/SavedViewOfficeFilter";
import SavedViewRoleFilter from "./components/SavedViewRoleFilter";
import SavedViewStageFilter from "./components/SavedViewStageFilter";
import SavedViewTextFilter from "./components/SavedViewTextFilter";
import SavedViewThirdPartyFilter from "./components/SavedViewThirdPartyFilter";
import SavedViewTimestampFilter from "./components/SavedViewTimestampFilter";
import SavedViewUserFilter from "./components/SavedViewUserFilter";
import SavedViewViewFilter from "./components/SavedViewViewFilter";
import { FilterContext } from "./FilterContext";
const Filter = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.getFilter(uid);
    const field = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const fieldUid = (_k = (_h = (_f = (_d = (_b = (_a = filter.text_filter) === null || _a === void 0 ? void 0 : _a.lhs_field) !== null && _b !== void 0 ? _b : (_c = filter.date_filter) === null || _c === void 0 ? void 0 : _c.lhs_field) !== null && _d !== void 0 ? _d : (_e = filter.number_filter) === null || _e === void 0 ? void 0 : _e.lhs_field) !== null && _f !== void 0 ? _f : (_g = filter.choice_filter) === null || _g === void 0 ? void 0 : _g.lhs_field) !== null && _h !== void 0 ? _h : (_j = filter.multiple_choice_filter) === null || _j === void 0 ? void 0 : _j.lhs_field) !== null && _k !== void 0 ? _k : (_l = filter.boolean_filter) === null || _l === void 0 ? void 0 : _l.lhs_field;
        if (fieldUid) {
            return (_m = context.fields) === null || _m === void 0 ? void 0 : _m.find((x) => x.uid === fieldUid);
        }
        return null;
    }, [filter, context.fields]);
    const lhs = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        if (((_a = context.timestampTypes) !== null && _a !== void 0 ? _a : []).includes((_c = (_b = filter.timestamp_filter) === null || _b === void 0 ? void 0 : _b.timestamp_type) !== null && _c !== void 0 ? _c : "")) {
            return (_e = (_d = filter.timestamp_filter) === null || _d === void 0 ? void 0 : _d.timestamp_type) !== null && _e !== void 0 ? _e : "";
        }
        if (filter.stage_filter) {
            return "stage";
        }
        if (filter.notes_filter) {
            return "notes";
        }
        if (filter.view_filter) {
            return "view";
        }
        if (filter.mail_schedule_template_filter) {
            return "mail-schedule-template";
        }
        if ((_f = context.userFilterTypes) === null || _f === void 0 ? void 0 : _f.includes((_h = (_g = filter.user_filter) === null || _g === void 0 ? void 0 : _g.user_type) !== null && _h !== void 0 ? _h : "")) {
            return (_k = (_j = filter.user_filter) === null || _j === void 0 ? void 0 : _j.user_type) !== null && _k !== void 0 ? _k : "";
        }
        if ((_l = context.thirdPartyTypes) === null || _l === void 0 ? void 0 : _l.includes((_o = (_m = filter.third_party_filter) === null || _m === void 0 ? void 0 : _m.third_party_type) !== null && _o !== void 0 ? _o : "")) {
            return (_q = (_p = filter.third_party_filter) === null || _p === void 0 ? void 0 : _p.third_party_type) !== null && _q !== void 0 ? _q : "";
        }
        if (filter.role_filter) {
            return "role";
        }
        if (filter.office_filter) {
            return "office";
        }
        return (_r = field === null || field === void 0 ? void 0 : field.uid) !== null && _r !== void 0 ? _r : "";
    }, [
        context.thirdPartyTypes,
        context.timestampTypes,
        context.userFilterTypes,
        field === null || field === void 0 ? void 0 : field.uid,
        filter.mail_schedule_template_filter,
        filter.notes_filter,
        filter.office_filter,
        filter.role_filter,
        filter.stage_filter,
        (_a = filter.third_party_filter) === null || _a === void 0 ? void 0 : _a.third_party_type,
        (_b = filter.timestamp_filter) === null || _b === void 0 ? void 0 : _b.timestamp_type,
        (_c = filter.user_filter) === null || _c === void 0 ? void 0 : _c.user_type,
        filter.view_filter,
    ]);
    const isIncomplete = React.useMemo(() => {
        var _a, _b;
        if (filter.text_filter) {
            if (filter.text_filter.comparator !== "is not set" && filter.text_filter.comparator !== "is set") {
                return filter.text_filter.rhs_field == null && ((_a = filter.text_filter.rhs_value) !== null && _a !== void 0 ? _a : "").trim() === "";
            }
            return false;
        }
        if (filter.number_filter) {
            if (filter.number_filter.comparator !== "is not set" && filter.number_filter.comparator !== "is set") {
                return filter.number_filter.rhs_field == null && filter.number_filter.rhs_value == null;
            }
            return false;
        }
        if (filter.choice_filter) {
            if (filter.choice_filter.comparator !== "is not set" && filter.choice_filter.comparator !== "is set") {
                return filter.choice_filter.rhs_values == null || filter.choice_filter.rhs_values.length === 0;
            }
            return false;
        }
        if (filter.multiple_choice_filter) {
            if (filter.multiple_choice_filter.comparator !== "is blank" &&
                filter.multiple_choice_filter.comparator !== "is not blank") {
                return (filter.multiple_choice_filter.rhs_values == null || filter.multiple_choice_filter.rhs_values.length === 0);
            }
            return false;
        }
        if (filter.date_filter) {
            if (filter.date_filter.comparator === "is in a range") {
                return filter.date_filter.rhs_range == null;
            }
            if (filter.date_filter.comparator !== "is not set" && filter.date_filter.comparator !== "is set") {
                return filter.date_filter.interval == null || filter.date_filter.units == null;
            }
            return false;
        }
        if (filter.boolean_filter) {
            if (filter.boolean_filter.comparator !== "is checked" && filter.boolean_filter.comparator !== "is not checked") {
                return filter.boolean_filter.rhs_field == null;
            }
            return false;
        }
        if (filter.notes_filter) {
            if (filter.notes_filter.comparator !== "is not set" && filter.notes_filter.comparator !== "is set") {
                return ((_b = filter.notes_filter.rhs_value) !== null && _b !== void 0 ? _b : "").trim() === "";
            }
            return false;
        }
        if (filter.stage_filter) {
            return (filter.stage_filter.rhs_stage == null &&
                (filter.stage_filter.rhs_stages == null || filter.stage_filter.rhs_stages.length === 0));
        }
        if (filter.role_filter) {
            if (filter.role_filter.comparator !== "is not set" && filter.role_filter.comparator !== "is set") {
                return filter.role_filter.rhs_values == null || filter.role_filter.rhs_values.length === 0;
            }
            return false;
        }
        if (filter.office_filter) {
            if (filter.office_filter.comparator !== "is not set" && filter.office_filter.comparator !== "is set") {
                return filter.office_filter.rhs_values == null || filter.office_filter.rhs_values.length === 0;
            }
            return false;
        }
        if (filter.third_party_filter) {
            if (filter.third_party_filter.comparator !== "is not set" && filter.third_party_filter.comparator !== "is set") {
                return filter.third_party_filter.rhs_contact_uid == null;
            }
            return false;
        }
        if (filter.timestamp_filter) {
            if (filter.timestamp_filter.comparator === "is in a range") {
                return filter.timestamp_filter.rhs_range == null;
            }
            if (filter.timestamp_filter.comparator !== "is not set" && filter.timestamp_filter.comparator !== "is set") {
                return filter.timestamp_filter.interval == null || filter.timestamp_filter.units == null;
            }
            return false;
        }
        if (filter.user_filter) {
            if (filter.user_filter.comparator === "is on team") {
                return filter.user_filter.rhs_teams == null || filter.user_filter.rhs_teams.length === 0;
            }
            if (filter.user_filter.comparator === "is in office") {
                return filter.user_filter.rhs_offices == null || filter.user_filter.rhs_offices.length === 0;
            }
            if (filter.user_filter.comparator === "is any of" || filter.user_filter.comparator === "is not any of") {
                return filter.user_filter.rhs_values == null || filter.user_filter.rhs_values.length === 0;
            }
            return false;
        }
        if (filter.view_filter) {
            return filter.view_filter.view_uids.length === 0;
        }
        if (filter.mail_schedule_template_filter) {
            return false;
        }
        return true;
    }, [filter]);
    const removeFilter = React.useCallback(() => {
        context.removeFilter(uid);
    }, [context, uid]);
    const setLHS = React.useCallback((e) => {
        var _a, _b, _c, _d, _e, _f, _g;
        const val = e.target.value;
        const newFilter = { uid: filter.uid };
        const lhsField = (_a = context.fields) === null || _a === void 0 ? void 0 : _a.find((x) => x.uid === val);
        console.log({ val });
        if (lhsField) {
            let lhsFieldType = lhsField.field_type;
            if (lhsFieldType === "formula") {
                if (!lhsField.format) {
                    lhsFieldType = "text";
                }
                else if (lhsField.format.includes("0")) {
                    lhsFieldType = "number";
                }
                else {
                    lhsFieldType = "date";
                }
            }
            if (lhsFieldType === "text") {
                newFilter.text_filter = {
                    lhs_field: lhsField.uid,
                    comparator: "is set",
                    rhs_field: undefined,
                    rhs_value: undefined,
                };
            }
            else if (lhsFieldType === "number") {
                newFilter.number_filter = {
                    lhs_field: lhsField.uid,
                    comparator: "is set",
                    rhs_field: undefined,
                    rhs_value: undefined,
                };
            }
            else if (lhsFieldType === "date") {
                newFilter.date_filter = {
                    lhs_field: lhsField.uid,
                    comparator: "is set",
                    interval: 0,
                    units: "day",
                    rhs_field: undefined,
                    rhs_value: undefined,
                };
            }
            else if (lhsFieldType === "checkbox") {
                newFilter.boolean_filter = {
                    lhs_field: lhsField.uid,
                    comparator: "is checked",
                };
            }
            else if (lhsFieldType === "choice") {
                if (lhsField.allows_multiple) {
                    newFilter.multiple_choice_filter = {
                        lhs_field: lhsField.uid,
                        comparator: "includes any of",
                        rhs_values: [],
                    };
                }
                else {
                    newFilter.choice_filter = {
                        lhs_field: lhsField.uid,
                        comparator: "is any of",
                        rhs_values: [],
                    };
                }
            }
        }
        else if (((_b = context.timestampTypes) !== null && _b !== void 0 ? _b : []).includes(val)) {
            newFilter.timestamp_filter = {
                timestamp_type: val,
                comparator: "is before",
                interval: 0,
                units: "day",
                rhs_field: undefined,
                rhs_value: undefined,
            };
        }
        else if (((_c = context.userFilterTypes) !== null && _c !== void 0 ? _c : []).includes(val)) {
            newFilter.user_filter = {
                user_type: val,
                comparator: "is any of",
                rhs_values: undefined,
            };
        }
        else if (((_d = context.thirdPartyTypes) !== null && _d !== void 0 ? _d : []).includes(val)) {
            newFilter.third_party_filter = {
                third_party_type: val,
                comparator: "is set",
                rhs_contact_uid: undefined,
            };
        }
        else if (val === "stage") {
            newFilter.stage_filter = {
                comparator: "is any of",
                rhs_stage: undefined,
                rhs_stages: [],
            };
        }
        else if (val === "notes") {
            newFilter.notes_filter = {
                comparator: "is set",
                rhs_value: undefined,
            };
        }
        else if (val === "view") {
            newFilter.view_filter = {
                comparator: "is in any of",
                view_uids: [],
            };
        }
        else if (val === "mail-schedule-template") {
            newFilter.mail_schedule_template_filter = {
                comparator: "has been sent",
                time_comparator: "at least",
                time_interval: 0,
                time_unit: "days",
                template_uid: (_g = (_f = (_e = context.mailScheduleTemplates) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.uid) !== null && _g !== void 0 ? _g : "",
            };
        }
        else if (val === "role") {
            newFilter.role_filter = {
                comparator: "is set",
                rhs_values: undefined,
            };
        }
        else if (val === "office") {
            newFilter.office_filter = {
                comparator: "is set",
                rhs_values: undefined,
            };
        }
        else {
            throw new Error(`Unknown lhs ${val}`);
        }
        console.log({ newFilter });
        context.updateFilter(uid, newFilter);
    }, [context, filter.uid, uid]);
    const fieldSelect = (React.createElement(Grid2, { style: { width: "200px" } },
        React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: lhs, onChange: setLHS },
            ((_d = context.fields) !== null && _d !== void 0 ? _d : [])
                .sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
                .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { value: "stage" }, "Stage"),
            React.createElement(MenuItem, { value: "notes" }, "Notes"),
            ["User"].includes((_e = context.entityType) !== null && _e !== void 0 ? _e : "") && React.createElement(MenuItem, { value: "role" }, "Role"),
            ["User"].includes((_f = context.entityType) !== null && _f !== void 0 ? _f : "") && React.createElement(MenuItem, { value: "office" }, "Office"),
            React.createElement(Divider, { key: "divider1" }),
            ["Buyer Lead", "Buyer Contract", "Seller Lead", "Listing"].includes((_g = context.entityType) !== null && _g !== void 0 ? _g : "") &&
                [
                    React.createElement(MenuItem, { key: "Producer", value: "Producer" }, "Producer"),
                    React.createElement(MenuItem, { key: "Co-Agent", value: "Co-Agent" }, "Co-Agent"),
                    (context.entityType === "Seller Lead" || context.entityType === "Listing") && (React.createElement(MenuItem, { key: "Listing Coordinator", value: "Listing Coordinator" }, "Listing Coordinator")),
                ].filter((x) => x),
            React.createElement(MenuItem, { key: "Collaborator", value: "Collaborator" }, "Collaborator"),
            ["Buyer Contract", "Listing"].includes((_h = context.entityType) !== null && _h !== void 0 ? _h : "") && [
                React.createElement(Divider, { key: "third-parties-divier" }),
                (_j = context.thirdPartyTypes) === null || _j === void 0 ? void 0 : _j.map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))),
            ],
            context.fields && React.createElement(Divider, null),
            React.createElement(MenuItem, { value: "view" }, "View Membership"),
            React.createElement(MenuItem, { value: "mail-schedule-template" }, "Mail Schedule Template"),
            context.timestampTypes && [
                React.createElement(Divider, { key: "timestamp-divider" }),
                context.timestampTypes
                    .filter((x) => {
                    var _a;
                    // Producer Last Updated Timestamp is only relevant for
                    // leads and contracts
                    if (!["Buyer Lead", "Seller Lead", "Buyer Contract", "Seller Contract"].includes((_a = context.entityType) !== null && _a !== void 0 ? _a : "") &&
                        x === "Producer Last Updated Timestamp") {
                        return false;
                    }
                    return true;
                })
                    .map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))),
            ],
            React.createElement(Divider, null),
            React.createElement(MenuItem, { value: "Created By" }, "Created By"))));
    const invertedPlacement = ((_k = filter.timestamp_filter) === null || _k === void 0 ? void 0 : _k.comparator) === "is the anniversary of" ||
        ((_l = filter.date_filter) === null || _l === void 0 ? void 0 : _l.comparator) === "is the anniversary of";
    return (React.createElement(Box, { p: 1, style: { background: isIncomplete ? "#ffaaaa" : "#ccc", borderRadius: "0.125rem" } },
        React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(IconButton, { size: "small", onClick: removeFilter },
                    React.createElement(Delete, { fontSize: "small" }))),
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                    !invertedPlacement && fieldSelect,
                    React.createElement(Grid2, { style: { flex: !invertedPlacement ? 1 : undefined } },
                        filter.text_filter && React.createElement(SavedViewTextFilter, { uid: uid }),
                        filter.number_filter && React.createElement(SavedViewNumberFilter, { uid: uid }),
                        filter.boolean_filter && React.createElement(SavedViewBooleanFilter, { uid: uid }),
                        filter.date_filter && React.createElement(SavedViewDateFilter, { uid: uid }),
                        filter.choice_filter && React.createElement(SavedViewChoiceFilter, { uid: uid }),
                        filter.multiple_choice_filter && React.createElement(SavedViewMultipleChoiceFilter, { uid: uid }),
                        filter.stage_filter && React.createElement(SavedViewStageFilter, { uid: uid }),
                        filter.notes_filter && React.createElement(SavedViewNotesFilter, { uid: uid }),
                        filter.user_filter && React.createElement(SavedViewUserFilter, { uid: uid }),
                        filter.timestamp_filter && React.createElement(SavedViewTimestampFilter, { uid: uid }),
                        filter.view_filter && React.createElement(SavedViewViewFilter, { uid: uid }),
                        filter.mail_schedule_template_filter && React.createElement(SavedViewMailScheduleTemplateFilter, { uid: uid }),
                        filter.third_party_filter && React.createElement(SavedViewThirdPartyFilter, { uid: uid }),
                        filter.role_filter && React.createElement(SavedViewRoleFilter, { uid: uid }),
                        filter.office_filter && React.createElement(SavedViewOfficeFilter, { uid: uid })),
                    invertedPlacement && fieldSelect)))));
};
export default Filter;
