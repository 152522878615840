import * as React from "react";
import { Check, Refresh, Search } from "@mui/icons-material";
import { Alert, Box, Checkbox, Grid, Hidden, IconButton, InputAdornment, TextField, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { CLASS_NAME_NO_FOCUS_RING, formatUserCell, renderUserCell, userSortComparator, CLASS_NAME_VERTICAL_HEADER, } from "@app/common/grid/utils";
// import useErrorDialog from "@app/hooks/useErrorDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useGetTwilioAccess, useUpdateTwilioAccess } from "@app/orval/api/twilio-access";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { useListUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { replaceItemInArray } from "@app/util/Utils";
import { groupBy } from "../../crm/utils/utils";
const StyledCoreDataGrid = styled(CoreDataGrid) `
  & .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 200px !important;
  }
`;
const getCellClassName = () => {
    return CLASS_NAME_NO_FOCUS_RING;
};
const TwilioAccessTable = () => {
    var _a, _b, _c, _d, _e, _f;
    const [search, setSearch] = React.useState("");
    const [currentlyUpdatingItems, setCurrentlyUpdatingItems] = React.useState([]);
    const currentlyUpdatingItemsByUser = React.useMemo(() => {
        const res = {};
        for (const item of currentlyUpdatingItems !== null && currentlyUpdatingItems !== void 0 ? currentlyUpdatingItems : []) {
            if (!res[item.userUid]) {
                res[item.userUid] = new Set();
            }
            res[item.userUid].add(item.phoneSid);
        }
        return res;
    }, [currentlyUpdatingItems]);
    const gridApi = useGridApiRef();
    const errorDialog = useErrorDialog();
    const queryClient = useQueryClient();
    const listUsersApi = useListUsers({}, REFETCH_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const usersByUid = React.useMemo(() => {
        return users ? groupBy(users, "uid") : null;
    }, [users]);
    const listTwilioPhonesApi = useListCachedTwilioPhones({}, REFETCH_CACHE_PARAMS);
    const twilioPhones = (_d = (_c = listTwilioPhonesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listTwilioAccessApi = useGetTwilioAccess({}, REFETCH_CACHE_PARAMS);
    const twilioAccess = (_f = (_e = listTwilioAccessApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const twilioAccessByUserAndPhone = React.useMemo(() => {
        const res = {};
        for (const item of twilioAccess !== null && twilioAccess !== void 0 ? twilioAccess : []) {
            if (!res[item.user.uid]) {
                res[item.user.uid] = {};
            }
            res[item.user.uid][item.phone_sid] = item;
        }
        return res;
    }, [twilioAccess]);
    const updateTwilioAccessApi = useUpdateTwilioAccess();
    console.log({ twilioAccess });
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading users",
                queryResult: listUsersApi,
            },
            {
                label: "Loading Twilio phones...",
                queryResult: listTwilioPhonesApi,
            },
            {
                label: "Loading access settings...",
                queryResult: listTwilioAccessApi,
            },
        ],
    });
    const togglePhone = React.useCallback((userUid, phoneSid) => (_, checked) => {
        setCurrentlyUpdatingItems((prev) => [
            ...prev,
            {
                userUid,
                phoneSid,
            },
        ]);
        updateTwilioAccessApi
            .mutateAsync({
            data: {
                items: [
                    {
                        user_uid: userUid,
                        phone_sid: phoneSid,
                        has_access: checked,
                    },
                ],
            },
        })
            .then(() => {
            queryClient.setQueryData(listTwilioAccessApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: replaceItemInArray({
                        arr: old.data,
                        val: {
                            // @ts-expect-error
                            user: usersByUid[userUid],
                            phone_sid: phoneSid,
                        },
                        where: (item) => item.user.uid === userUid && item.phone_sid === phoneSid,
                    }) });
            });
        })
            .catch(errorDialog)
            .then(() => {
            setCurrentlyUpdatingItems((prev) => prev.filter((x) => x.phoneSid !== phoneSid || x.userUid !== userUid));
        });
    }, [queryClient, listTwilioAccessApi.queryKey, usersByUid, errorDialog, updateTwilioAccessApi]);
    const twilioPhonesSorted = React.useMemo(() => {
        console.log("getting twilio phones");
        if (twilioPhones) {
            return twilioPhones
                .filter((a) => {
                var _a, _b;
                return !((_a = a.friendly_name) === null || _a === void 0 ? void 0 : _a.includes("Agent Phone")) && !((_b = a.friendly_name) === null || _b === void 0 ? void 0 : _b.includes("Proxy"));
            })
                .sort((a, b) => {
                var _a, _b;
                const aFriendlyName = (_a = a.friendly_name) !== null && _a !== void 0 ? _a : "";
                const bFriendlyName = (_b = b.friendly_name) !== null && _b !== void 0 ? _b : "";
                if (aFriendlyName < bFriendlyName)
                    return -1;
                if (aFriendlyName > bFriendlyName)
                    return 1;
                return 0;
            });
        }
        return null;
    }, [twilioPhones]);
    const columns = React.useMemo(() => {
        console.log("getting columns...");
        return [
            {
                field: "user",
                headerName: "",
                editable: false,
                sortable: true,
                width: 125,
                resizable: true,
                sortComparator: userSortComparator,
                cellClassName: "cell-button",
                align: "center",
                renderCell: renderUserCell,
                valueFormatter: formatUserCell,
            },
            ...(twilioPhonesSorted !== null && twilioPhonesSorted !== void 0 ? twilioPhonesSorted : []).map((phone) => ({
                field: phone.sid,
                headerName: phone.friendly_name || phone.phone_number,
                headerClassName: [CLASS_NAME_VERTICAL_HEADER],
                width: 40,
                minWidth: 40,
                resizable: false,
                align: "center",
                renderCell: (params) => {
                    var _a;
                    const userUid = params.id;
                    const user = usersByUid[userUid];
                    if (user.roles.includes("Superadmin") || user.roles.includes("Admin")) {
                        return React.createElement(Check, { color: "primary", fontSize: "small", style: { height: "100%" } });
                    }
                    return (React.createElement(Checkbox, { size: "small", color: "primary", checked: params.value, onChange: togglePhone(params.row.id, phone.sid), 
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        disabled: (_a = currentlyUpdatingItemsByUser[userUid]) === null || _a === void 0 ? void 0 : _a.has(phone.sid), style: { padding: 0 } }));
                },
            })),
        ].map((column) => (Object.assign(Object.assign({}, column), { filterable: false, disableColumnMenu: true, width: column.width || 150, resizable: column.resizable !== false, editable: false, type: column.type || "string" })));
    }, [twilioPhonesSorted, togglePhone, currentlyUpdatingItemsByUser, usersByUid]);
    const rows = React.useMemo(() => {
        console.log("getting rows");
        return (users !== null && users !== void 0 ? users : []).map((user) => {
            var _a;
            const row = { id: user.uid, user };
            for (const phone of twilioPhonesSorted !== null && twilioPhonesSorted !== void 0 ? twilioPhonesSorted : []) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                row[phone.sid] = !!((_a = twilioAccessByUserAndPhone[user.uid]) === null || _a === void 0 ? void 0 : _a[phone.sid]);
            }
            return row;
        });
    }, [users, twilioPhonesSorted, twilioAccessByUserAndPhone]);
    const handleSearchChanged = React.useCallback((e) => {
        const searchStr = e.target.value;
        setSearch(searchStr);
        gridApi.current.setQuickFilterValues([searchStr]);
    }, [setSearch, gridApi]);
    const refresh = React.useCallback(() => {
        listUsersApi.refetch();
        listTwilioPhonesApi.refetch();
    }, [listUsersApi, listTwilioPhonesApi]);
    if (isLoading) {
        return loadingComponent;
    }
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid, { item: true },
            React.createElement(Alert, { severity: "info" },
                React.createElement(Typography, { variant: "body1", display: "inline" }, "Use this widget to give users access to send texts or place calls via the following Twilio phones."),
                React.createElement(Typography, { variant: "body1", display: "inline", style: { fontWeight: "bold" }, ml: 0.5 }, "Note that the ability to receive texts or calls from these phones is governed by separate routing rules."),
                React.createElement(Typography, { variant: "body1", display: "inline", ml: 0.5 }, "If an agent is assigned a personal Twilio phone, they will automatically have access to text and call from that phone."))),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 1 },
                React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { size: "small", style: { width: "300px" }, value: search, onChange: handleSearchChanged, slotProps: {
                                    input: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(Search, null))),
                                    },
                                } }))),
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true, style: { flex: 1 } })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Refresh") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: refresh },
                                    React.createElement(Refresh, null)))))))),
        React.createElement(Grid, { item: true, style: { flex: 1, overflow: "hidden" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(StyledCoreDataGrid, { rows: rows, columns: columns, density: "compact", rowHeight: 30, columnHeaderHeight: 300, disableRowSelectionOnClick: true, hideFooter: true, pagination: false, rowCount: rows.length, apiRef: gridApi, getCellClassName: getCellClassName, initialState: {
                        pinnedColumns: {
                            left: ["id", "user"],
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: [search],
                            },
                        },
                    } })))));
};
export default TwilioAccessTable;
