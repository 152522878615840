import * as React from "react";
import { Info } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import pickBy from "lodash/pickBy";
import CoreError from "@app/common/CoreError";
import { countContractsBySource } from "@app/orval/api/agent-dashboard";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const ContractsBySource = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { fromDate, selectedUserUids, toDate } = agentDashboardFilters;
    const queryParams = pickBy({ from_date: fromDate, to_date: toDate, agent_uids: [...selectedUserUids] }, (val) => val != null);
    const countContractsBySourceApi = usePostQuery(countContractsBySource, queryParams);
    const countBySource = (_a = countContractsBySourceApi.data) === null || _a === void 0 ? void 0 : _a.data;
    const pieChartData = countBySource === null || countBySource === void 0 ? void 0 : countBySource.map((item) => ({
        id: item.source,
        value: item.value,
        label: item.source,
    }));
    return (React.createElement(Box, { p: 2 },
        React.createElement(Paper, { elevation: 4 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid, { container: true, item: true, spacing: 1, alignItems: "center" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { variant: "h6", style: { fontWeight: "bold" } }, "Contracts by Source")),
                        React.createElement(Grid, { item: true },
                            React.createElement(Grid, { item: true },
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Number of closed contracts by Source.") },
                                    React.createElement(Info, null))))),
                    React.createElement(Grid, { item: true },
                        countContractsBySourceApi.isFetching && React.createElement(CircularProgress, { size: 20 }),
                        countContractsBySourceApi.error && React.createElement(CoreError, { error: countContractsBySourceApi.error }),
                        pieChartData && (React.createElement(PieChart, { series: [
                                {
                                    data: pieChartData,
                                    innerRadius: 30,
                                    // outerRadius: pieChartWidth != null ? pieChartWidth / 2 - 10 : 50,
                                },
                            ], height: 200 }))))))));
};
export default ContractsBySource;
