import * as React from "react";
import { Search } from "@mui/icons-material";
import { Box, Grid2, InputAdornment, LinearProgress, TablePagination, TextField, ThemeProvider, Typography, } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { CLASS_NAME_NO_FOCUS_RING, renderDateTimeCell_Local } from "@app/common/grid/utils";
import RouterLink from "@app/common/RouterLink";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListNmsRequests, useCountNmsRequests } from "@app/orval/api/nms-requests";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
import UserAndTimestampCell from "../apps/matrix/UserAndTimestampCell";
import { renderCheckboxCell } from "../crm/components/spreadsheet-view/table-cells";
const getCellClassName = () => {
    return CLASS_NAME_NO_FOCUS_RING;
};
const NMSRequests = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const limit = (_a = props.limit) !== null && _a !== void 0 ? _a : 1000;
    const offset = (_b = props.offset) !== null && _b !== void 0 ? _b : 0;
    const search = (_c = props.search) !== null && _c !== void 0 ? _c : "";
    const updateSearch = useUpdateSearch();
    const [searchText, setSearchText] = React.useState(search);
    const listNMSRequestsApi = useListNmsRequests({ limit, offset, search }, DEFAULT_CACHE_PARAMS);
    const nmsRequests = (_e = (_d = listNMSRequestsApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const countNMSRequestsApi = useCountNmsRequests({ search }, DEFAULT_CACHE_PARAMS);
    const count = (_g = (_f = countNMSRequestsApi.data) === null || _f === void 0 ? void 0 : _f.data.count) !== null && _g !== void 0 ? _g : null;
    const handleSearchTextChanged = React.useCallback((e) => {
        setSearchText(e.target.value);
    }, [setSearchText]);
    const handleSearchTextBlurred = React.useCallback(() => {
        updateSearch("search", searchText, "offset", null);
    }, [updateSearch, searchText]);
    const handlePageChange = React.useCallback((_, newPage) => {
        updateSearch("offset", newPage * limit || null);
    }, [updateSearch, limit]);
    const handleRowsPerPageChange = React.useCallback((e) => {
        updateSearch("limit", e.target.value, "offset", null);
    }, [updateSearch]);
    const page = Math.floor(offset / limit);
    const rows = React.useMemo(() => {
        return nmsRequests === null || nmsRequests === void 0 ? void 0 : nmsRequests.map((x) => (Object.assign({ id: x.uid }, x)));
    }, [nmsRequests]);
    const columns = React.useMemo(() => {
        return [
            {
                field: "property",
                headerName: "Property",
                renderCell: (params) => {
                    const row = params.row;
                    return (React.createElement(RouterLink, { to: `/buyers/${row.buyer_property.buyer_lead_uid}` }, row.buyer_property.property_address));
                },
            },
            {
                field: "request_type",
                headerName: "Request Type",
                width: 150,
            },
            {
                field: "mls_area",
                headerName: "MLS Area",
                width: 300,
            },
            {
                field: "preferred_showing_date_and_time",
                headerName: "Preferred Showing Date and Time",
                width: 200,
                renderCell: renderDateTimeCell_Local,
            },
            {
                field: "listing_agent_email",
                headerName: "Listing Agent Email",
                width: 150,
            },
            {
                field: "listing_agent_approved",
                headerName: "Listing Agent Approved",
                width: 150,
                renderCell: renderCheckboxCell,
            },
            {
                field: "requested",
                headerName: "Requested",
                width: 200,
                renderCell: (params) => {
                    const row = params.row;
                    return React.createElement(UserAndTimestampCell, { user: row.requested_by, timestamp: row.requested_at });
                },
            },
            {
                field: "claimed",
                headerName: "Claimed",
                width: 200,
                renderCell: (params) => {
                    const row = params.row;
                    return React.createElement(UserAndTimestampCell, { user: row.claimed_by, timestamp: row.claimed_at });
                },
            },
        ].map((x) => (Object.assign(Object.assign({}, x), { sortable: false, editable: false })));
    }, []);
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid2, { container: true, spacing: 1 },
                    React.createElement(Grid2, { style: { maxWidth: "300px" } },
                        React.createElement(TextField, { size: "small", variant: "outlined", value: searchText, onChange: handleSearchTextChanged, onBlur: handleSearchTextBlurred, slotProps: {
                                input: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Search, null))),
                                },
                            } })),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    count ? (React.createElement(Grid2, null,
                        React.createElement(TablePagination, { count: count, rowsPerPage: limit, page: page, size: "small", showFirstButton: true, showLastButton: true, onPageChange: handlePageChange, onRowsPerPageChange: handleRowsPerPageChange, rowsPerPageOptions: [50, 100, 200, 500, 1000], disabled: listNMSRequestsApi.isFetching, sx: {
                                display: "block",
                                overflow: "hidden",
                                border: "none",
                                height: "50px",
                            } }))) : null))),
        listNMSRequestsApi.isFetching && rows && (React.createElement(Grid2, { style: { position: "relative" } },
            React.createElement(LinearProgress, { variant: "indeterminate", style: { position: "absolute", height: "3px", width: "100%", zIndex: 100 } }))),
        listNMSRequestsApi.isFetching && !rows && (React.createElement(Grid2, null,
            React.createElement(CoreLoading, null))),
        (listNMSRequestsApi.error || countNMSRequestsApi.error) && (React.createElement(Grid2, null,
            React.createElement(CoreError, { error: listNMSRequestsApi.error || countNMSRequestsApi.error }))),
        rows && count ? (React.createElement(Grid2, { style: { overflow: "scroll" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, rowCount: count, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnMenu: true, hideFooter: true, density: "compact", paginationMode: "server", getCellClassName: getCellClassName, localeText: {
                        toolbarColumns: "",
                    } })))) : null,
        rows && count === 0 && (React.createElement(Grid2, { style: { flex: 1 } },
            React.createElement(Grid2, { container: true, direction: "column", style: { height: "100%" } },
                React.createElement(Grid2, { style: { flex: 1 } }),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "h6", style: { textAlign: "center", color: "#aaa" } }, "No NMS Requests found")),
                React.createElement(Grid2, { style: { flex: 1 } }))))));
};
export default NMSRequests;
