/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List PSP Report Templates
 */
export const listPspReportTemplates = (options) => {
    return axios.get(`/api/psp_report_templates`, options);
};
export const getListPspReportTemplatesQueryKey = () => {
    return [`/api/psp_report_templates`];
};
export const getListPspReportTemplatesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspReportTemplatesQueryKey();
    const queryFn = ({ signal }) => listPspReportTemplates(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSP Report Templates
 */
export function useListPspReportTemplates(options) {
    const queryOptions = getListPspReportTemplatesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a PSP Report Template
 */
export const createPspReportTemplate = (reportTemplateRequest, options) => {
    return axios.post(`/api/psp_report_templates`, reportTemplateRequest, options);
};
export const getCreatePspReportTemplateMutationOptions = (options) => {
    const mutationKey = ['createPspReportTemplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPspReportTemplate(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a PSP Report Template
*/
export const useCreatePspReportTemplate = (options) => {
    const mutationOptions = getCreatePspReportTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a PSP Report Template
*/
export const getPspReportTemplates = (uid, options) => {
    return axios.get(`/api/psp_report_templates/${uid}`, options);
};
export const getGetPspReportTemplatesQueryKey = (uid) => {
    return [`/api/psp_report_templates/${uid}`];
};
export const getGetPspReportTemplatesQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspReportTemplatesQueryKey(uid);
    const queryFn = ({ signal }) => getPspReportTemplates(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP Report Template
 */
export function useGetPspReportTemplates(uid, options) {
    const queryOptions = getGetPspReportTemplatesQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a PSP Template
 */
export const updatePspReportTemplate = (uid, reportTemplateRequest, options) => {
    return axios.post(`/api/psp_report_templates/${uid}`, reportTemplateRequest, options);
};
export const getUpdatePspReportTemplateMutationOptions = (options) => {
    const mutationKey = ['updatePspReportTemplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updatePspReportTemplate(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a PSP Template
*/
export const useUpdatePspReportTemplate = (options) => {
    const mutationOptions = getUpdatePspReportTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a PSP Report Template
*/
export const deletePspReportTemplate = (uid, options) => {
    return axios.delete(`/api/psp_report_templates/${uid}`, options);
};
export const getDeletePspReportTemplateMutationOptions = (options) => {
    const mutationKey = ['deletePspReportTemplate'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspReportTemplate(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a PSP Report Template
*/
export const useDeletePspReportTemplate = (options) => {
    const mutationOptions = getDeletePspReportTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
