var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PowerSettingsNew, Web, ExpandLess, ChevronRight } from "@mui/icons-material";
import { Drawer, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse, Box, Divider, Avatar, Typography, useTheme, alpha, } from "@mui/material";
import CoreLink from "@app/common/CoreLink";
import MaterialIcon from "@app/common/MaterialIcon";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import { useGetSidebar } from "@app/orval/api/sidebar";
import { useListStarredViews } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { isChromeExtension, isLocal } from "@app/util/Utils";
// ==============================
// Helper Components
// ==============================
const NavItem = (props) => {
    const { active = false, children, component, depth = 0, hasChildren = false, href, icon, onClick, onToggle, open, primary, to } = props, other = __rest(props, ["active", "children", "component", "depth", "hasChildren", "href", "icon", "onClick", "onToggle", "open", "primary", "to"]);
    const theme = useTheme();
    const paddingLeft = 16 + depth * 16;
    // Create the appropriate props for linking
    let linkProps = {};
    if (to) {
        linkProps = { component: RouterLink, to };
    }
    else if (href) {
        linkProps = { component: CoreLink, href };
    }
    else if (component) {
        linkProps = { component };
    }
    // Create click handler that respects both navigation and toggle
    const handleClick = () => {
        // Always call onClick if it exists
        if (onClick) {
            // If it has children, we're toggling the dropdown
            // but we still want the item to be clickable for navigation
            onClick();
        }
        // Let the default navigation occur regardless - this makes
        // collapsed dropdown headers still clickable/navigable
    };
    return (React.createElement(ListItemButton, Object.assign({}, linkProps, other, { onClick: handleClick, disableRipple: true, sx: {
            py: 1,
            px: 2,
            pl: `${paddingLeft}px`,
            color: active ? theme.palette.primary.main : "text.primary",
            backgroundColor: active ? alpha(theme.palette.primary.main, 0.08) : "transparent",
            borderRadius: 1,
            mx: 1,
            my: 0.5,
            "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                color: "primary.main",
            },
        } }),
        icon && (React.createElement(ListItemIcon, { sx: {
                minWidth: 36,
                color: active ? "primary.main" : "text.secondary",
            } }, icon)),
        React.createElement(ListItemText, { primary: primary, primaryTypographyProps: {
                fontSize: 14,
                fontWeight: active ? "medium" : "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            } }),
        hasChildren && (React.createElement(Box, { component: "span", sx: { ml: "auto" } }, open ? React.createElement(ExpandLess, { fontSize: "small" }) : React.createElement(ChevronRight, { fontSize: "small" })))));
};
const SidebarSection = (props) => {
    const { depth = 0, items } = props;
    const [openItems, setOpenItems] = React.useState({});
    const session = useSession();
    const handleToggle = (itemPath) => {
        setOpenItems((prevState) => (Object.assign(Object.assign({}, prevState), { [itemPath]: !prevState[itemPath] })));
    };
    const handleToggleItem = (itemPath) => {
        return () => {
            handleToggle(itemPath);
        };
    };
    if (!(session === null || session === void 0 ? void 0 : session.viewingAsUser)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(React.Fragment, null, items.map((item) => {
        var _a;
        const isOpen = openItems[item.path] || false;
        const hasChildren = !!((_a = item.children) === null || _a === void 0 ? void 0 : _a.length);
        const icon = item.iconName ? React.createElement(MaterialIcon, { name: item.iconName }) : null;
        return (React.createElement(React.Fragment, { key: item.path },
            React.createElement(NavItem, { primary: item.name, icon: icon, depth: depth, hasChildren: hasChildren, open: isOpen, onClick: handleToggleItem(item.path), to: !item.openInNewTab ? item.path : undefined, href: item.openInNewTab ? item.path : undefined }),
            hasChildren && (React.createElement(Collapse, { in: isOpen, timeout: "auto", unmountOnExit: true },
                React.createElement(SidebarSection, { items: item.children, depth: depth + 1 })))));
    })));
};
// ==============================
// Main Component
// ==============================
const Sidebar = (props) => {
    var _a, _b, _c, _d, _e;
    const { open } = props;
    const theme = useTheme();
    const isSmall = useSize().isDown("sm");
    const session = useSession();
    // eslint-disable-next-line no-nested-ternary
    const sidebarWidth = open ? (!isSmall ? 240 : "100%") : 0;
    // Fetch starred views
    const listStarredViewsApi = useListStarredViews({ user_uid: (_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : undefined }, Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS.query), { enabled: !!((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid) }) }));
    const starredViews = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listStarredViewsApi.data) === null || _a === void 0 ? void 0 : _a.data.items.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : []);
    }, [(_d = listStarredViewsApi.data) === null || _d === void 0 ? void 0 : _d.data]);
    // Fetch sidebar configuration
    const getSidebarApi = useGetSidebar(INFINITE_CACHE_PARAMS);
    const sidebarConfig = React.useMemo(() => {
        var _a, _b;
        const baseConfig = (_b = (_a = getSidebarApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
        return baseConfig
            ? Object.assign(Object.assign({}, baseConfig), { sections: baseConfig.sections.map((section) => {
                    if (section.name === "CRM") {
                        return Object.assign(Object.assign({}, section), { items: [
                                ...section.items,
                                ...starredViews.map((view) => ({
                                    iconName: "star",
                                    name: view.name,
                                    path: `/views/${view.uid}`,
                                })),
                            ] });
                    }
                    return section;
                }) }) : null;
    }, [(_e = getSidebarApi.data) === null || _e === void 0 ? void 0 : _e.data, starredViews]);
    // Determine seller portal link
    const sellerPortalLink = React.useMemo(() => {
        if (isChromeExtension) {
            return "https://sellers.dash.premierbrokeragesystem.com";
        }
        if (isLocal) {
            return `${new URL(window.location.href).protocol}//${new URL(window.location.href).host}/seller-portal/`;
        }
        return `${new URL(window.location.href).protocol}//sellers.${new URL(window.location.href).host}`;
    }, []);
    if (!(session === null || session === void 0 ? void 0 : session.viewingAsUser)) {
        return null;
    }
    return (React.createElement(Drawer, { variant: "permanent", PaperProps: {
            elevation: 4,
            sx: {
                borderRight: "1px solid",
                borderColor: "divider",
            },
        }, sx: {
            width: sidebarWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
            "& .MuiDrawer-paper": {
                width: sidebarWidth,
                position: "relative",
                background: theme.palette.mode === "light"
                    ? "linear-gradient(180deg, #f7f9fc 0%, #edf2f7 100%)"
                    : "linear-gradient(180deg, #1a202c 0%, #171923 100%)",
                color: theme.palette.text.primary,
                overflowY: "auto",
                transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
                }),
                boxShadow: theme.palette.mode === "light" ? "0px 2px 8px rgba(0, 0, 0, 0.05)" : "0px 2px 8px rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
            },
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            height: "100%",
        } },
        session.viewingAsUser && (React.createElement(Box, { sx: {
                p: 2,
                textAlign: "center",
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: theme.palette.mode === "light"
                    ? "linear-gradient(180deg, #f7f9fc 0%, #edf2f7 100%)"
                    : "linear-gradient(180deg, #1a202c 0%, #171923 100%)",
                borderBottom: "1px solid",
                borderColor: "divider",
            } },
            React.createElement(Avatar, { sx: {
                    width: 64,
                    height: 64,
                    mx: "auto",
                    mb: 1,
                    background: "linear-gradient(45deg, #6b46c1, #3182ce)",
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.1)",
                } }, session.viewingAsUser.name.charAt(0) || "U"),
            React.createElement(Typography, { variant: "subtitle1", fontWeight: "medium" }, session.viewingAsUser.name || "User"),
            session.viewingAsUser.email && (React.createElement(Typography, { variant: "body2", color: "text.secondary", noWrap: true }, session.viewingAsUser.email)))),
        React.createElement(Divider, { sx: { my: 1.5 } }),
        React.createElement(Box, { sx: {
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
            } }, sidebarConfig === null || sidebarConfig === void 0 ? void 0 : sidebarConfig.sections.map((section) => (React.createElement(List, { key: section.name, subheader: React.createElement(ListSubheader, { sx: {
                    background: alpha(theme.palette.primary.main, 0.08),
                    color: theme.palette.text.primary,
                    fontSize: "0.75rem",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    letterSpacing: "0.08em",
                    p: "8px 16px",
                    borderRadius: 1,
                    m: "8px 8px 4px 8px",
                    lineHeight: "1.5rem",
                } }, section.name), sx: { py: 0 } },
            React.createElement(SidebarSection, { items: section.items }))))),
        React.createElement(Box, { sx: {
                position: "sticky",
                bottom: 0,
                zIndex: 1,
                background: theme.palette.mode === "light"
                    ? "linear-gradient(180deg, #edf2f7 0%, #f7f9fc 100%)"
                    : "linear-gradient(180deg, #171923 0%, #1a202c 100%)",
                borderTop: "1px solid",
                borderColor: "divider",
                pt: 1.5,
            } },
            React.createElement(Divider, { sx: { mb: 1.5 } }),
            session.viewingAsUser.isInternal && (React.createElement(List, { sx: { py: 0 } },
                React.createElement(NavItem, { primary: "Seller Portal", icon: React.createElement(Web, null), href: sellerPortalLink }))),
            !isChromeExtension && (React.createElement(List, { sx: { py: 0 } },
                React.createElement(NavItem, { primary: session.viewingAsUser.uid ? "Logout" : "Login", icon: React.createElement(PowerSettingsNew, null), href: "/logout/", sx: {
                        color: theme.palette.error.main,
                        "&:hover": {
                            backgroundColor: alpha(theme.palette.error.main, 0.1),
                            color: theme.palette.error.dark,
                        },
                    } }))))));
};
export default Sidebar;
