/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all sections
 */
export const listFieldSections = (params, options) => {
    return axios.get(`/api/field_sections`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListFieldSectionsQueryKey = (params) => {
    return [`/api/field_sections`, ...(params ? [params] : [])];
};
export const getListFieldSectionsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListFieldSectionsQueryKey(params);
    const queryFn = ({ signal }) => listFieldSections(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all sections
 */
export function useListFieldSections(params, options) {
    const queryOptions = getListFieldSectionsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Create a section
 */
export const addFieldSection = (fieldSectionCreateRequest, options) => {
    return axios.post(`/api/field_sections`, fieldSectionCreateRequest, options);
};
export const getAddFieldSectionMutationOptions = (options) => {
    const mutationKey = ['addFieldSection'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addFieldSection(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a section
*/
export const useAddFieldSection = (options) => {
    const mutationOptions = getAddFieldSectionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a section
*/
export const getFieldSection = (uid, options) => {
    return axios.get(`/api/field_sections/${uid}`, options);
};
export const getGetFieldSectionQueryKey = (uid) => {
    return [`/api/field_sections/${uid}`];
};
export const getGetFieldSectionQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetFieldSectionQueryKey(uid);
    const queryFn = ({ signal }) => getFieldSection(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a section
 */
export function useGetFieldSection(uid, options) {
    const queryOptions = getGetFieldSectionQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
 * @summary Update a section
 */
export const updateFieldSection = (uid, fieldSectionUpdateRequest, options) => {
    return axios.post(`/api/field_sections/${uid}`, fieldSectionUpdateRequest, options);
};
export const getUpdateFieldSectionMutationOptions = (options) => {
    const mutationKey = ['updateFieldSection'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateFieldSection(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a section
*/
export const useUpdateFieldSection = (options) => {
    const mutationOptions = getUpdateFieldSectionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
* @summary Delete a section
*/
export const deleteFieldSection = (uid, options) => {
    return axios.delete(`/api/field_sections/${uid}`, options);
};
export const getDeleteFieldSectionMutationOptions = (options) => {
    const mutationKey = ['deleteFieldSection'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteFieldSection(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a section
*/
export const useDeleteFieldSection = (options) => {
    const mutationOptions = getDeleteFieldSectionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin._
* @summary Reorder sections
*/
export const reorderFieldSections = (fieldSectionReorderRequest, options) => {
    return axios.post(`/api/field_sections/reorder`, fieldSectionReorderRequest, options);
};
export const getReorderFieldSectionsMutationOptions = (options) => {
    const mutationKey = ['reorderFieldSections'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderFieldSections(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder sections
*/
export const useReorderFieldSections = (options) => {
    const mutationOptions = getReorderFieldSectionsMutationOptions(options);
    return useMutation(mutationOptions);
};
