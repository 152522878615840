import * as React from "react";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";
const PSPSearchResult = (props) => {
    const { psp } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemIcon, null,
            React.createElement(Typography, { variant: "h6" }, "PSP")),
        React.createElement(ListItemText, { primary: [psp.fields["Property Address"]].filter((obj) => obj).join(" • ") })));
};
export default PSPSearchResult;
