import * as React from "react";
import { FilterList } from "@mui/icons-material";
import { IconButton, Badge, Grid2, Box, Typography, Divider, List, ListItemButton, ListItemText } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import RouterLink from "@app/common/RouterLink";
import { searchBuyerContracts } from "@app/orval/api/buyer-contracts";
import { searchBuyerLeads } from "@app/orval/api/buyer-leads";
import { searchContacts } from "@app/orval/api/contacts";
import { searchRecruits } from "@app/orval/api/recruits";
import { searchSellerContracts } from "@app/orval/api/seller-contracts";
import { searchSellerLeads } from "@app/orval/api/seller-leads";
import { searchUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../../dashboards/agent-dashboard/usePostQuery";
const getViewUrl = (entityType, viewUid) => {
    if (entityType === "Buyer Lead") {
        return `/buyer-leads?view=${viewUid}`;
    }
    if (entityType === "Seller Lead") {
        return `/seller-leads?view=${viewUid}`;
    }
    if (entityType === "Buyer Contract") {
        return `/buyer-contracts?view=${viewUid}`;
    }
    if (entityType === "Listing") {
        return `/seller-contracts?view=${viewUid}`;
    }
    if (entityType === "Recruit") {
        return `/recruits?view=${viewUid}`;
    }
    if (entityType === "User") {
        return `/users?view=${viewUid}`;
    }
    if (entityType === "Contact") {
        return `/contacts?view=${viewUid}`;
    }
    throw new Error("Unexpected");
};
const ViewMembership = (props) => {
    var _a, _b, _c, _d;
    const { entityType, entityUid } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const api = React.useMemo(() => {
        if (entityType === "Buyer Lead") {
            return searchBuyerLeads;
        }
        if (entityType === "Seller Lead") {
            return searchSellerLeads;
        }
        if (entityType === "Buyer Contract") {
            return searchBuyerContracts;
        }
        if (entityType === "Listing") {
            return searchSellerContracts;
        }
        if (entityType === "Recruit") {
            return searchRecruits;
        }
        if (entityType === "User") {
            return searchUsers;
        }
        if (entityType === "Contact") {
            return searchContacts;
        }
        return null;
    }, [entityType]);
    const openViewMembership = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [setAnchorEl]);
    const closePopover = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const postApi = usePostQuery(
    // @ts-expect-error
    api !== null && api !== void 0 ? api : searchBuyerLeads, { uids: [entityUid], include_view_membership: true, disable_sort: true }, Object.assign({ enabled: !!api }, REFETCH_CACHE_PARAMS));
    const data = (_a = postApi.data) === null || _a === void 0 ? void 0 : _a.data;
    const viewMembership = (_c = (_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b.view_membership) !== null && _c !== void 0 ? _c : null;
    if (!viewMembership) {
        return null;
    }
    const foundInViews = viewMembership.filter((x) => x.is_in_view);
    return (React.createElement(React.Fragment, null,
        React.createElement(Badge, { color: "primary", badgeContent: foundInViews.length, showZero: true, anchorOrigin: { vertical: "bottom", horizontal: "right" }, sx: {
                "& .MuiBadge-badge": {
                    height: "16px", // Smaller height
                    minWidth: "16px", // Smaller width
                    fontSize: "0.625rem", // Smaller font size
                    padding: "0 4px", // Adjust padding as needed
                },
            } },
            React.createElement(IconButton, { size: "small", onClick: openViewMembership },
                React.createElement(FilterList, { fontSize: "small" }))),
        React.createElement(CorePopover, { open: !!anchorEl, anchorEl: anchorEl, onClose: closePopover, style: { minWidth: "250px" } },
            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Box, { p: 2 },
                        React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                            React.createElement(Grid2, null,
                                React.createElement(FilterList, { style: { color: "#888" } })),
                            React.createElement(Grid2, { style: { flex: 1 } },
                                React.createElement(Typography, null,
                                    "Found in ", (_d = foundInViews.length) !== null && _d !== void 0 ? _d : 0,
                                    " view",
                                    foundInViews.length === 1 ? "" : "s"))))),
                foundInViews.length ? (React.createElement(Grid2, null,
                    React.createElement(Divider, null))) : null,
                foundInViews.length ? (React.createElement(Grid2, null,
                    React.createElement(List, { dense: true }, foundInViews.map((item) => (React.createElement(ListItemButton, { key: item.view.uid, to: getViewUrl(entityType, item.view.uid), component: RouterLink },
                        React.createElement(ListItemText, { primary: item.view.name }))))))) : null))));
};
export default ViewMembership;
