var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { CircularProgress, InputAdornment, TextField, Autocomplete } from "@mui/material";
import debounce from "lodash/debounce";
const ServerSideAutocomplete = (props) => {
    var _a, _b, _c, _d;
    const { TextFieldProps, debounceMilliseconds, doSearch, isLoading, presetString, searchWhenBlank } = props, other = __rest(props, ["TextFieldProps", "debounceMilliseconds", "doSearch", "isLoading", "presetString", "searchWhenBlank"]);
    const [selectedItem, setSelectedItem] = React.useState((_a = props.value) !== null && _a !== void 0 ? _a : null);
    const [inputValue, setInputValue] = React.useState(presetString !== null && presetString !== void 0 ? presetString : null);
    const [search, setSearch] = React.useState(presetString !== null && presetString !== void 0 ? presetString : null);
    const [isTyping, setIsTyping] = React.useState(false);
    const [items, setItems] = React.useState(null);
    const debouncedGetContactsFunc = React.useRef(null);
    React.useEffect(() => {
        if (search || searchWhenBlank) {
            doSearch(search !== null && search !== void 0 ? search : "")
                .then((result) => {
                setItems(result);
            })
                .catch(console.error);
        }
    }, [search, searchWhenBlank, doSearch]);
    React.useEffect(() => {
        var _a;
        setSelectedItem((_a = props.value) !== null && _a !== void 0 ? _a : null);
    }, [props.value]);
    const onInputFocus = React.useCallback(() => {
        if (!isTyping) {
            setIsTyping(true);
        }
    }, [isTyping]);
    const onInputBlur = React.useCallback(() => {
        if (isTyping) {
            setIsTyping(false);
        }
    }, [isTyping]);
    const handleInputValueChanged = React.useCallback((e) => {
        var _a;
        const val = e.target.value;
        setInputValue(val);
        (_a = debouncedGetContactsFunc.current) === null || _a === void 0 ? void 0 : _a.cancel();
        debouncedGetContactsFunc.current = debounce(() => {
            setSearch(val);
        }, 500);
        debouncedGetContactsFunc.current();
    }, [setInputValue]);
    const filterOptions = React.useCallback((options) => {
        return options;
    }, []);
    const renderInput = React.useCallback((params) => {
        var _a, _b, _c, _d;
        return (React.createElement(TextField, Object.assign({ variant: "standard" }, params, TextFieldProps, { inputProps: Object.assign(Object.assign({}, params.inputProps), { onFocus: onInputFocus, onBlur: onInputBlur }), onChange: handleInputValueChanged, slotProps: {
                input: Object.assign(Object.assign(Object.assign({}, params.InputProps), ((_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.InputProps) !== null && _a !== void 0 ? _a : (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.input)), { endAdornment: isLoading ? (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(CircularProgress, { size: 20 }))) : (((_c = params.InputProps.endAdornment) !== null && _c !== void 0 ? _c : (_d = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.InputProps) === null || _d === void 0 ? void 0 : _d.endAdornment)) }),
            } })));
    }, [TextFieldProps, onInputFocus, onInputBlur, handleInputValueChanged, isLoading]);
    const onChange = React.useCallback((_, value, reason) => {
        setIsTyping(false);
        setInputValue(null);
        setSearch(null);
        if (reason === "selectOption") {
            setSelectedItem(value);
            if (props.onChange) {
                props.onChange(_, value, reason);
            }
        }
        else if (reason === "clear") {
            setSelectedItem(null);
            if (props.onChange) {
                props.onChange(_, value, reason);
            }
        }
    }, [props]);
    const shouldShowNoOptionsText = !!(inputValue === null || inputValue === void 0 ? void 0 : inputValue.trim()) && !isLoading;
    console.log({ items });
    return (React.createElement(Autocomplete, Object.assign({ clearOnBlur: true, clearOnEscape: true }, other, { getOptionLabel: props.getOptionLabel, renderOption: props.renderOption, isOptionEqualToValue: props.isOptionEqualToValue, value: selectedItem !== null && selectedItem !== void 0 ? selectedItem : undefined, inputValue: (_c = inputValue !== null && inputValue !== void 0 ? inputValue : (selectedItem && ((_b = props.getOptionLabel) === null || _b === void 0 ? void 0 : _b.call(props, selectedItem)))) !== null && _c !== void 0 ? _c : "", groupBy: (_d = other.groupBy) !== null && _d !== void 0 ? _d : undefined, noOptionsText: shouldShowNoOptionsText ? props.noOptionsText : null, onChange: onChange, options: items !== null && items !== void 0 ? items : [], filterOptions: filterOptions, renderInput: renderInput, slotProps: {
            paper: {
                style: (isLoading && (!items || items.length === 0)) || (!searchWhenBlank && (!inputValue || !search))
                    ? {
                        display: "none",
                    }
                    : undefined,
            },
        } })));
};
export default ServerSideAutocomplete;
