import * as React from "react";
import { Table, TableCell, TableRow, TableBody, Grid, Button } from "@mui/material";
import dayjs from "dayjs";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import CoreLink from "@app/common/CoreLink";
import CoreLoading from "@app/common/CoreLoading";
import { useGetQflSubmissionNewcrm } from "@app/orval/api/questions-for-the-listing";
const boolAsYesNo = (value) => {
    if (value === true) {
        return "Yes";
    }
    if (value === false) {
        return "No";
    }
    return null;
};
const ValueRow = (props) => {
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null, props.name),
        React.createElement(TableCell, null, props.value)));
};
const FileLink = (props) => {
    const { file } = props;
    if (!file) {
        return null;
    }
    return (React.createElement(CoreLink, { href: `/qfl-uploads/${file.uid}`, target: "_blank" }, file.filename));
};
const QuestionsForTheListingFormSubmissionNEWCRM = (props) => {
    var _a, _b;
    const { uid } = props;
    const getQFLAPI = useGetQflSubmissionNewcrm(uid);
    const qfl = (_b = (_a = getQFLAPI.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [showMore, setShowMore] = React.useState(false);
    const toggleShowMore = React.useCallback(() => {
        setShowMore((prev) => !prev);
    }, []);
    if (getQFLAPI.error) {
        return React.createElement(CoreError, { error: getQFLAPI.error });
    }
    if (getQFLAPI.isFetching || !qfl) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Table, { size: "small", sx: { "& tr td:first-child ": { fontWeight: "bold" } } },
        React.createElement(TableBody, null,
            React.createElement(ValueRow, { name: "Full Legal Name 1", value: qfl.full_legal_names_1 }),
            React.createElement(ValueRow, { name: "Full Legal Name 2", value: qfl.full_legal_names_2 }),
            React.createElement(ValueRow, { name: "Date of Birth 1", value: qfl.date_of_birth_1 ? dayjs(qfl.date_of_birth_1).format("MMMM D, YYYY") : null }),
            React.createElement(ValueRow, { name: "Date of Birth 2", value: qfl.date_of_birth_2 ? dayjs(qfl.date_of_birth_2).format("MMMM D, YYYY") : null }),
            React.createElement(ValueRow, { name: "Marital Status", value: qfl.marital_status }),
            React.createElement(ValueRow, { name: "Is this your primary residence?", value: boolAsYesNo(qfl.primary_residence) }),
            React.createElement(ValueRow, { name: "Are you or have you ever been a licensed broker in the state of North Carolina?", value: boolAsYesNo(qfl.licensed_broker_in_nc) }),
            React.createElement(ValueRow, { name: "Spouse's email address?", value: qfl.spouse_email_address }),
            React.createElement(ValueRow, { name: "List any/all personal items that DO convey:", value: qfl.personal_items_that_convey }),
            React.createElement(ValueRow, { name: "List any/all personal items that DO NOT convey:", value: qfl.items_leased_or_not_owned }),
            React.createElement(ValueRow, { name: "List any fixtures that DO NOT convey:", value: qfl.fixtures_that_do_not_convey }),
            React.createElement(ValueRow, { name: "Current lien(s) on property?", value: qfl.current_liens_on_property }),
            React.createElement(ValueRow, { name: "1st Mortgage Lender", value: qfl.first_mortgage_lender }),
            React.createElement(ValueRow, { name: "1st Mortgage Approx Balance", value: qfl.first_mortgage_approx_balance != null
                    ? numeral(qfl.first_mortgage_approx_balance).format("$0,0.00")
                    : null }),
            React.createElement(ValueRow, { name: "2nd Mortgage Lender", value: qfl.second_mortgage_lender }),
            React.createElement(ValueRow, { name: "2nd Mortgage Approx Balance", value: qfl.second_mortgage_approx_balance != null
                    ? numeral(qfl.second_mortgage_approx_balance).format("$0,0.00")
                    : null }),
            React.createElement(ValueRow, { name: "HELOC Lender", value: qfl.heloc_lender }),
            React.createElement(ValueRow, { name: "HELOC Approx Balance", value: qfl.heloc_approx_balance != null ? numeral(qfl.heloc_approx_balance).format("$0,0.00") : null }),
            React.createElement(ValueRow, { name: "HELOC Lender Phone", value: qfl.heloc_lender_phone }),
            React.createElement(ValueRow, { name: "HOA?", value: boolAsYesNo(qfl.is_hoa) }),
            React.createElement(ValueRow, { name: "Homeowners Association Name", value: qfl.homeowners_association_name }),
            React.createElement(ValueRow, { name: "HOA Contact Email", value: qfl.hoa_contact_email }),
            React.createElement(ValueRow, { name: "HOA Website", value: qfl.hoa_website }),
            React.createElement(ValueRow, { name: "HOA Amenities", value: qfl.hoa_amenities }),
            React.createElement(ValueRow, { name: "HOA Documents", value: React.createElement(FileLink, { file: qfl.attachments.find((x) => x.file_type === "HOA Documents") }) }),
            React.createElement(ValueRow, { name: "List Price", value: qfl.list_price != null ? numeral(qfl.list_price).format("$0,0.00") : null }),
            React.createElement(ValueRow, { name: "Buyer Agent Commission %", value: qfl.buyer_agent_commission_percent ? numeral(qfl.buyer_agent_commission_percent).format("0,0.00%") : null }),
            React.createElement(ValueRow, { name: "Active Date", value: qfl.active_date ? dayjs(qfl.active_date).format("MMMM D, YYYY") : null }),
            React.createElement(ValueRow, { name: "Coming Soon Marketing", value: boolAsYesNo(qfl.coming_soon_marketing) }),
            React.createElement(ValueRow, { name: "Coming Soon Date", value: qfl.coming_soon_date ? dayjs(qfl.coming_soon_date).format("MMMM D, YYYY") : null }),
            React.createElement(ValueRow, { name: "Photo Ready Date", value: qfl.photo_ready_date ? dayjs(qfl.photo_ready_date).format("MMMM D, YYYY") : null }),
            React.createElement(ValueRow, { name: "Is the house currently occupied?", value: boolAsYesNo(qfl.is_house_currently_occupied) }),
            React.createElement(ValueRow, { name: "In this case, can we do a go and show?", value: qfl.can_do_go_and_show }),
            !qfl.is_house_currently_occupied && (React.createElement(ValueRow, { name: "In this case, how many of a notice will you need for showings?", value: qfl.notice_needed_for_showings })),
            qfl.is_house_currently_occupied && (React.createElement(React.Fragment, null,
                React.createElement(ValueRow, { name: "Who should we send the emails to for showing approvals?", value: qfl.recipient_of_emails_for_showing_approvals }),
                React.createElement(ValueRow, { name: "Who should we send texts to for showing approvals", value: qfl.recipient_of_texts_for_showing_approvals }),
                React.createElement(ValueRow, { name: "Are there any pets that we should be concerned about?", value: boolAsYesNo(qfl.pets_to_be_concerned_about) }))),
            React.createElement(ValueRow, { name: "What type of water system does your house have?", value: qfl.water_system }),
            React.createElement(ValueRow, { name: "What type of sewer system does your house have?", value: qfl.sewer_system }),
            React.createElement(ValueRow, { name: "Does your house have a basement?", value: boolAsYesNo(qfl.has_basement) }),
            React.createElement(ValueRow, { name: "Does your house have a garage?", value: boolAsYesNo(qfl.has_garage) }),
            React.createElement(ValueRow, { name: "Who is responsible for road maintenance?", value: qfl.road_maintenance }),
            React.createElement(ValueRow, { name: "Are there any known encroachments, easements, or encumberances on or adjacent to the property?", value: qfl.known_encroachments_easments_or_encumberances }),
            React.createElement(ValueRow, { name: "Do you have a copy of the previous title insurance policy?", value: boolAsYesNo(qfl.have_copy_of_previous_title_insurance_policy) }),
            React.createElement(ValueRow, { name: "Previous Title Insurance", value: React.createElement(FileLink, { file: qfl.attachments.find((x) => x.file_type === "Title Insurance") }) }),
            React.createElement(ValueRow, { name: "Previous Closing Attorney Contact Info", value: qfl.previous_closing_attorney_contact_info }),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Full Submission"),
                React.createElement(TableCell, null,
                    React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { size: "small", variant: "text", onClick: toggleShowMore }, showMore ? "Show Less" : "Show More")),
                        showMore && (React.createElement(Grid, { item: true },
                            React.createElement("pre", null, JSON.stringify(qfl.submission_json, null, 2))))))))));
};
export default QuestionsForTheListingFormSubmissionNEWCRM;
