import * as React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { FilterContext } from "../FilterContext";
const SavedViewBooleanFilter = (props) => {
    var _a, _b;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const booleanFields = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = context.fields) === null || _a === void 0 ? void 0 : _a.filter((f) => f.field_type === "checkbox")) !== null && _b !== void 0 ? _b : [];
    }, [context.fields]);
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.boolean_filter.comparator = comparator;
        if (comparator === "is not checked" || comparator === "is checked") {
            newFilter.boolean_filter.rhs_field = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSField = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.boolean_filter.rhs_field = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.booleanFieldComparators;
    return (React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true, style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.boolean_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        filter.boolean_filter.comparator !== "is checked" && filter.boolean_filter.comparator !== "is not checked" && (React.createElement(Grid, { item: true, style: { width: "200px" } },
            React.createElement(TextField, { size: "small", variant: "standard", select: true, fullWidth: true, value: (_b = filter.boolean_filter.rhs_field) !== null && _b !== void 0 ? _b : "", onChange: setRHSField, SelectProps: {
                    displayEmpty: true,
                } },
                React.createElement(MenuItem, { value: "" }, "Select field"),
                booleanFields
                    .filter((x) => x.uid !== filter.boolean_filter.lhs_field)
                    .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))))));
};
export default SavedViewBooleanFilter;
