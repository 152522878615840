import * as React from "react";
import { Send } from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Container, Divider, Typography, TextField, Checkbox, FormControlLabel, } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useSubmitQuestionsForTheSearch } from "@app/orval/api/forms-questions-for-the-search";
import { useListQuestionsForTheSearchAge, useListQuestionsForTheSearchGarages, useListQuestionsForTheSearchLocations, useListQuestionsForTheSearchLotSize, useGetMarketForQuestionsForTheSearch, useListQuestionsForTheSearchPropertyType, } from "@app/orval/api/questions-for-the-search";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { validateEmail } from "@app/util/Utils";
const QuestionsForTheSearchForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    const { buyerLeadUid } = props;
    const [formData, setFormData] = React.useState({});
    const [isComplete, setIsComplete] = React.useState(false);
    const submitQFSAPI = useSubmitQuestionsForTheSearch();
    const isValid = React.useMemo(() => {
        var _a, _b, _c;
        return (((_a = formData.name) === null || _a === void 0 ? void 0 : _a.trim()) &&
            ((_b = formData.email) === null || _b === void 0 ? void 0 : _b.trim()) &&
            validateEmail(formData.email.trim()) &&
            (!formData.additional_email || validateEmail(formData.additional_email.trim())) &&
            ((_c = formData.phone) === null || _c === void 0 ? void 0 : _c.trim()) &&
            formData.minimum_price != null &&
            formData.maximum_price != null);
    }, [formData]);
    const submission = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        return {
            buyer_lead_uid: buyerLeadUid,
            name: (_b = (_a = formData.name) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : "",
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            additional_name: ((_c = formData.additional_name) === null || _c === void 0 ? void 0 : _c.trim()) || null,
            email: (_e = (_d = formData.email) === null || _d === void 0 ? void 0 : _d.trim()) !== null && _e !== void 0 ? _e : "",
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            additional_email: ((_f = formData.additional_email) === null || _f === void 0 ? void 0 : _f.trim()) || null,
            phone: (_h = (_g = formData.phone) === null || _g === void 0 ? void 0 : _g.trim()) !== null && _h !== void 0 ? _h : "",
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            additional_phone: ((_j = formData.additional_phone) === null || _j === void 0 ? void 0 : _j.trim()) || null,
            minimum_price: formData.minimum_price,
            maximum_price: formData.maximum_price,
            age: (_k = formData.age) !== null && _k !== void 0 ? _k : null,
            minimum_num_bedrooms: (_l = formData.minimum_num_bedrooms) !== null && _l !== void 0 ? _l : null,
            minimum_num_full_baths: (_m = formData.minimum_num_full_baths) !== null && _m !== void 0 ? _m : null,
            minimum_square_footage: (_o = formData.minimum_square_footage) !== null && _o !== void 0 ? _o : null,
            maximum_square_footage: (_p = formData.maximum_square_footage) !== null && _p !== void 0 ? _p : null,
            garages: (_q = formData.garages) !== null && _q !== void 0 ? _q : null,
            minimum_lot_size: (_r = formData.minimum_lot_size) !== null && _r !== void 0 ? _r : null,
            general_locations: (_s = formData.general_locations) !== null && _s !== void 0 ? _s : [],
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            general_locations_other: ((_t = formData.general_locations_other) === null || _t === void 0 ? void 0 : _t.trim()) || null,
            property_types: (_u = formData.property_types) !== null && _u !== void 0 ? _u : [],
        };
    }, [formData, buyerLeadUid]);
    const getMarketApi = useGetMarketForQuestionsForTheSearch(buyerLeadUid);
    const market = (_b = (_a = getMarketApi.data) === null || _a === void 0 ? void 0 : _a.data.market) !== null && _b !== void 0 ? _b : null;
    const listAgeOptionsApi = useListQuestionsForTheSearchAge(INFINITE_CACHE_PARAMS);
    const ageOptions = (_d = (_c = listAgeOptionsApi.data) === null || _c === void 0 ? void 0 : _c.data.items) !== null && _d !== void 0 ? _d : null;
    const listGarageOptionsApi = useListQuestionsForTheSearchGarages(INFINITE_CACHE_PARAMS);
    const garageOptions = (_f = (_e = listGarageOptionsApi.data) === null || _e === void 0 ? void 0 : _e.data.items) !== null && _f !== void 0 ? _f : null;
    const listLotSizeOptionsApi = useListQuestionsForTheSearchLotSize(INFINITE_CACHE_PARAMS);
    const lotSizeOptions = (_h = (_g = listLotSizeOptionsApi.data) === null || _g === void 0 ? void 0 : _g.data.items) !== null && _h !== void 0 ? _h : null;
    const listPropertyTypeOptionsApi = useListQuestionsForTheSearchPropertyType(INFINITE_CACHE_PARAMS);
    const propertyTypes = (_k = (_j = listPropertyTypeOptionsApi.data) === null || _j === void 0 ? void 0 : _j.data.items) !== null && _k !== void 0 ? _k : null;
    const listLocationOptionsApi = useListQuestionsForTheSearchLocations({ market: market !== null && market !== void 0 ? market : "" }, Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS.query), { enabled: !!market }) }));
    const locationOptions = (_m = (_l = listLocationOptionsApi.data) === null || _l === void 0 ? void 0 : _l.data.items) !== null && _m !== void 0 ? _m : null;
    console.log({ market, locationOptions });
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, []);
    const setAdditionalName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { additional_name: e.target.value })));
    }, []);
    const setEmail = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email: e.target.value })));
    }, []);
    const setAdditionalEmail = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { additional_email: e.target.value })));
    }, []);
    const setPhone = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { phone: e.target.value })));
    }, []);
    const setAdditionalPhone = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { additional_phone: e.target.value })));
    }, []);
    const setMinimumPrice = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { minimum_price: val })));
    }, []);
    const setMaximumPrice = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { maximum_price: val })));
    }, []);
    const setAge = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { age: e.target.value })));
    }, []);
    const setMinimumNumBedrooms = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { minimum_num_bedrooms: val })));
    }, []);
    const setMinimumNumFullBaths = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { minimum_num_full_baths: val })));
    }, []);
    const setMinimumSquareFootage = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { minimum_square_footage: val })));
    }, []);
    const setMaximumSquareFootage = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { maximum_square_footage: val })));
    }, []);
    const setGarages = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { garages: e.target.value })));
    }, []);
    const setMinimumLotSize = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { minimum_lot_size: e.target.value })));
    }, []);
    const toggleLocation = React.useCallback((val) => (_, checked) => {
        setFormData((prev) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, prev), { general_locations: checked
                    ? [...((_a = prev.general_locations) !== null && _a !== void 0 ? _a : []), val]
                    : ((_b = prev.general_locations) !== null && _b !== void 0 ? _b : []).filter((item) => item !== val) }));
        });
    }, []);
    const setGeneralLocationOther = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { general_location_other: e.target.value })));
    }, []);
    const togglePropertyType = React.useCallback((val) => (_, checked) => {
        setFormData((prev) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, prev), { property_types: checked
                    ? [...((_a = prev.property_types) !== null && _a !== void 0 ? _a : []), val]
                    : ((_b = prev.property_types) !== null && _b !== void 0 ? _b : []).filter((item) => item !== val) }));
        });
    }, []);
    const submit = React.useCallback(() => {
        submitQFSAPI
            .mutateAsync({ data: submission })
            .then(() => {
            setIsComplete(true);
        })
            .catch(enqueueErrorSnackbar);
    }, [submission, submitQFSAPI]);
    if (isComplete) {
        return (React.createElement(Container, { maxWidth: "md" },
            React.createElement(Box, { p: 2 },
                React.createElement(Typography, { variant: "body1", style: { textAlign: "center" } }, "Thank you for your submission!"))));
    }
    if (getMarketApi.error) {
        return React.createElement(CoreError, { error: getMarketApi.error });
    }
    if (getMarketApi.isFetching || !market) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid, { container: true, direction: "column", spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Typography, { variant: "h4" }, "Questions for the Search"))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Name", value: (_o = formData.name) !== null && _o !== void 0 ? _o : "", onChange: setName, inputProps: {
                                maxWidth: 127,
                            } })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Additional Name", value: (_p = formData.additional_name) !== null && _p !== void 0 ? _p : "", onChange: setAdditionalName, inputProps: {
                                maxWidth: 127,
                            } })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Email", value: (_q = formData.email) !== null && _q !== void 0 ? _q : "", onChange: setEmail, inputProps: {
                                maxWidth: 127,
                            }, helperText: formData.email && !validateEmail(formData.email) ? "Must be a valid email" : null })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Additional Email", value: (_r = formData.additional_email) !== null && _r !== void 0 ? _r : "", onChange: setAdditionalEmail, inputProps: {
                                maxWidth: 127,
                            }, helperText: formData.additional_email && !validateEmail(formData.additional_email)
                                ? "Must be a valid email"
                                : null })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Phone", value: (_s = formData.phone) !== null && _s !== void 0 ? _s : "", onChange: setPhone, inputProps: {
                                maxWidth: 127,
                            } })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Additional Phone", value: (_t = formData.additional_phone) !== null && _t !== void 0 ? _t : "", onChange: setAdditionalPhone, inputProps: {
                                maxWidth: 127,
                            } })),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6, lg: 3 },
                        React.createElement(CoreNumberField, { variant: "standard", format: "$0,0.00", fullWidth: true, required: true, label: "Minimum Price", number: (_u = formData.minimum_price) !== null && _u !== void 0 ? _u : null, onNumberChange: setMinimumPrice, min: 0, max: 999999999 })),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6, lg: 3 },
                        React.createElement(CoreNumberField, { variant: "standard", format: "$0,0.00", fullWidth: true, required: true, label: "Maximum Price", number: (_v = formData.maximum_price) !== null && _v !== void 0 ? _v : null, onNumberChange: setMaximumPrice, min: 0, max: 999999999 })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Age", value: (_w = formData.age) !== null && _w !== void 0 ? _w : "", onChange: setAge },
                            React.createElement(MenuItem, { value: "" }), ageOptions === null || ageOptions === void 0 ? void 0 :
                            ageOptions.map((age) => (React.createElement(MenuItem, { key: age, value: age }, age))))),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(CoreNumberField, { variant: "standard", format: "0,0", fullWidth: true, label: "Minimum # of Bedrooms", number: (_x = formData.minimum_num_bedrooms) !== null && _x !== void 0 ? _x : null, onNumberChange: setMinimumNumBedrooms, min: 0, max: 99 })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(CoreNumberField, { variant: "standard", format: "0,0", fullWidth: true, label: "Minimum # of full baths", number: (_y = formData.minimum_num_full_baths) !== null && _y !== void 0 ? _y : null, onNumberChange: setMinimumNumFullBaths, min: 0, max: 99 })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(CoreNumberField, { variant: "standard", format: "0,0", fullWidth: true, label: "Minimum Square Footage?", number: (_z = formData.minimum_square_footage) !== null && _z !== void 0 ? _z : null, onNumberChange: setMinimumSquareFootage, min: 0, max: 99000 })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(CoreNumberField, { variant: "standard", format: "0,0", fullWidth: true, label: "Maximum Square Footage?", number: (_0 = formData.maximum_square_footage) !== null && _0 !== void 0 ? _0 : null, onNumberChange: setMaximumSquareFootage, min: 0, max: 99000 })),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Garages?", value: (_1 = formData.garages) !== null && _1 !== void 0 ? _1 : "", onChange: setGarages },
                            React.createElement(MenuItem, { value: "" }), garageOptions === null || garageOptions === void 0 ? void 0 :
                            garageOptions.map((age) => (React.createElement(MenuItem, { key: age, value: age }, age))))),
                    React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Minimum Lot Size", value: (_2 = formData.minimum_lot_size) !== null && _2 !== void 0 ? _2 : "", onChange: setMinimumLotSize },
                            React.createElement(MenuItem, { value: "" }), lotSizeOptions === null || lotSizeOptions === void 0 ? void 0 :
                            lotSizeOptions.map((age) => (React.createElement(MenuItem, { key: age, value: age }, age))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { p: 4 },
                            React.createElement(Grid, { container: true },
                                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                    React.createElement(Grid, { container: true, direction: "column", spacing: 1 },
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Typography, { variant: "h5" }, "General Locations")),
                                        React.createElement(Grid, { item: true, container: true, direction: "column", spacing: 0 }, locationOptions === null || locationOptions === void 0 ? void 0 : locationOptions.map((item) => {
                                            var _a, _b, _c;
                                            const includeTextField = item.toLowerCase().trim() === "other" && ((_a = formData.general_locations) !== null && _a !== void 0 ? _a : []).includes(item);
                                            return (React.createElement(Grid, { key: `Location${item}`, container: true, item: true },
                                                React.createElement(Grid, { item: true, xs: includeTextField ? 3 : 12 },
                                                    React.createElement(FormControlLabel, { label: item, control: React.createElement(Checkbox, { size: "small", color: "primary", checked: ((_b = formData.general_locations) !== null && _b !== void 0 ? _b : []).includes(item), onChange: toggleLocation(item), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                                includeTextField && (React.createElement(Grid, { item: true, xs: 9 },
                                                    React.createElement(TextField, { variant: "standard", label: "", fullWidth: true, value: (_c = formData.general_locations_other) !== null && _c !== void 0 ? _c : "", onChange: setGeneralLocationOther, inputProps: {
                                                            maxWidth: 127,
                                                        } })))));
                                        })))),
                                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                    React.createElement(Grid, { container: true, direction: "column", spacing: 1 },
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Typography, { variant: "h5" }, "Property Types")),
                                        React.createElement(Grid, { item: true, container: true, direction: "column", spacing: 0 }, propertyTypes === null || propertyTypes === void 0 ? void 0 : propertyTypes.map((item) => {
                                            var _a;
                                            return (React.createElement(Grid, { key: `PropertyType${item}`, item: true },
                                                React.createElement(FormControlLabel, { label: item, control: React.createElement(Checkbox, { size: "small", color: "primary", checked: ((_a = formData.property_types) !== null && _a !== void 0 ? _a : []).includes(item), onChange: togglePropertyType(item), style: { paddingTop: 0, paddingBottom: 0 } }) })));
                                        })))))))))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", variant: "contained", color: "primary", disabled: !isValid || submitQFSAPI.isPending, startIcon: React.createElement(Send, { fontSize: "small" }), onClick: submit }, "Submit")))))));
};
export default QuestionsForTheSearchForm;
