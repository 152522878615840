/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Create a buyer contracts
 */
export const addBuyerContract = (buyerContractCreateRequest, options) => {
    return axios.post(`/api/buyer_contracts`, buyerContractCreateRequest, options);
};
export const getAddBuyerContractMutationOptions = (options) => {
    const mutationKey = ['addBuyerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addBuyerContract(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a buyer contracts
*/
export const useAddBuyerContract = (options) => {
    const mutationOptions = getAddBuyerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a buyer contract
*/
export const getBuyerContract = (uid, options) => {
    return axios.get(`/api/buyer_contracts/${uid}`, options);
};
export const getGetBuyerContractQueryKey = (uid) => {
    return [`/api/buyer_contracts/${uid}`];
};
export const getGetBuyerContractQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBuyerContractQueryKey(uid);
    const queryFn = ({ signal }) => getBuyerContract(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a buyer contract
 */
export function useGetBuyerContract(uid, options) {
    const queryOptions = getGetBuyerContractQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a buyer contract
 */
export const updateBuyerContract = (uid, buyerContractUpdateRequest, options) => {
    return axios.post(`/api/buyer_contracts/${uid}`, buyerContractUpdateRequest, options);
};
export const getUpdateBuyerContractMutationOptions = (options) => {
    const mutationKey = ['updateBuyerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateBuyerContract(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a buyer contract
*/
export const useUpdateBuyerContract = (options) => {
    const mutationOptions = getUpdateBuyerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a buyer contract
*/
export const deleteBuyerContract = (uid, options) => {
    return axios.delete(`/api/buyer_contracts/${uid}`, options);
};
export const getDeleteBuyerContractMutationOptions = (options) => {
    const mutationKey = ['deleteBuyerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteBuyerContract(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a buyer contract
*/
export const useDeleteBuyerContract = (options) => {
    const mutationOptions = getDeleteBuyerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Import MLS Data to Buyer Contract
*/
export const importMlsDataForBuyerContract = (uid, buyerContractImportMLSDataRequest, options) => {
    return axios.post(`/api/buyer_contracts/${uid}/import_mls_data`, buyerContractImportMLSDataRequest, options);
};
export const getImportMlsDataForBuyerContractMutationOptions = (options) => {
    const mutationKey = ['importMlsDataForBuyerContract'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return importMlsDataForBuyerContract(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Import MLS Data to Buyer Contract
*/
export const useImportMlsDataForBuyerContract = (options) => {
    const mutationOptions = getImportMlsDataForBuyerContractMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete multiple buyer contracts
*/
export const bulkDeleteBuyerContracts = (buyerContractBulkDeleteRequest, options) => {
    return axios.post(`/api/buyer_contracts/bulk_delete`, buyerContractBulkDeleteRequest, options);
};
export const getBulkDeleteBuyerContractsMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteBuyerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteBuyerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple buyer contracts
*/
export const useBulkDeleteBuyerContracts = (options) => {
    const mutationOptions = getBulkDeleteBuyerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Update multiple buyer contracts
*/
export const bulkUpdateBuyerContracts = (buyerContractBulkUpdateRequest, options) => {
    return axios.post(`/api/buyer_contracts/bulk_update`, buyerContractBulkUpdateRequest, options);
};
export const getBulkUpdateBuyerContractsMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateBuyerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateBuyerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple buyer contracts
*/
export const useBulkUpdateBuyerContracts = (options) => {
    const mutationOptions = getBulkUpdateBuyerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count buyer contracts
*/
export const countBuyerContracts = (buyerContractQuerySerializerWithCertainFieldsRemoved7c9638e75eb242729b12e934cbfc41d9Request, options) => {
    return axios.post(`/api/buyer_contracts/count`, buyerContractQuerySerializerWithCertainFieldsRemoved7c9638e75eb242729b12e934cbfc41d9Request, options);
};
export const getCountBuyerContractsMutationOptions = (options) => {
    const mutationKey = ['countBuyerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countBuyerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count buyer contracts
*/
export const useCountBuyerContracts = (options) => {
    const mutationOptions = getCountBuyerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count by stage
*/
export const countBuyerContractsByStage = (buyerContractQuerySerializerWithCertainFieldsRemoved7c9638e75eb242729b12e934cbfc41d9Request, options) => {
    return axios.post(`/api/buyer_contracts/count_by_stage`, buyerContractQuerySerializerWithCertainFieldsRemoved7c9638e75eb242729b12e934cbfc41d9Request, options);
};
export const getCountBuyerContractsByStageMutationOptions = (options) => {
    const mutationKey = ['countBuyerContractsByStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countBuyerContractsByStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count by stage
*/
export const useCountBuyerContractsByStage = (options) => {
    const mutationOptions = getCountBuyerContractsByStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download buyer contracts
*/
export const downloadBuyerContracts = (options) => {
    return axios.post(`/api/buyer_contracts/download`, undefined, options);
};
export const getDownloadBuyerContractsMutationOptions = (options) => {
    const mutationKey = ['downloadBuyerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return downloadBuyerContracts(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Download buyer contracts
*/
export const useDownloadBuyerContracts = (options) => {
    const mutationOptions = getDownloadBuyerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Search buyer contracts
*/
export const searchBuyerContracts = (paginatedBuyerContractQueryRequest, options) => {
    return axios.post(`/api/buyer_contracts/search`, paginatedBuyerContractQueryRequest, options);
};
export const getSearchBuyerContractsMutationOptions = (options) => {
    const mutationKey = ['searchBuyerContracts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchBuyerContracts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search buyer contracts
*/
export const useSearchBuyerContracts = (options) => {
    const mutationOptions = getSearchBuyerContractsMutationOptions(options);
    return useMutation(mutationOptions);
};
