/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Lists which users are able to send texts or place calls via Twilio phones

_Authorization: none required_
 * @summary Get twilio access
 */
export const getTwilioAccess = (params, options) => {
    return axios.get(`/api/get_twilio_access`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetTwilioAccessQueryKey = (params) => {
    return [`/api/get_twilio_access`, ...(params ? [params] : [])];
};
export const getGetTwilioAccessQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTwilioAccessQueryKey(params);
    const queryFn = ({ signal }) => getTwilioAccess(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get twilio access
 */
export function useGetTwilioAccess(params, options) {
    const queryOptions = getGetTwilioAccessQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Update which users are able to send texts or place calls via Twilio phones

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update twilio access
 */
export const updateTwilioAccess = (twilioAccessWriteRequest, options) => {
    return axios.post(`/api/update_twilio_access`, twilioAccessWriteRequest, options);
};
export const getUpdateTwilioAccessMutationOptions = (options) => {
    const mutationKey = ['updateTwilioAccess'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return updateTwilioAccess(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update twilio access
*/
export const useUpdateTwilioAccess = (options) => {
    const mutationOptions = getUpdateTwilioAccessMutationOptions(options);
    return useMutation(mutationOptions);
};
