import * as React from "react";
import { Launch } from "@mui/icons-material";
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import RouterLink from "@app/common/RouterLink";
const QFSListItem = (props) => {
    const { qfs } = props;
    return (React.createElement(ListItem, null,
        React.createElement(ListItemText, { primary: "QFS", secondary: `Submitted ${dayjs(qfs.created_at).format("dddd, MMMM D, YYYY h:mm A")}` }),
        React.createElement(ListItemSecondaryAction, null,
            React.createElement(IconButton, { component: RouterLink, to: `/forms/questions-for-the-search/submissions/${qfs.uid}`, size: "small" },
                React.createElement(Launch, { fontSize: "small" })))));
};
export default QFSListItem;
