import * as React from "react";
import { Close, Delete, Save } from "@mui/icons-material";
import { Box, Button, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useAddShadowingType, useDeleteShadowingType, useGetShadowingType, useListShadowingTypes, useUpdateShadowingType, } from "@app/orval/api/onboarding-shadowing-types";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const DEFAULT_FORM_DATA = {
    name: "",
};
const ShadowingTypeForm = (props) => {
    const { uid } = props;
    const [formData, setFormData] = React.useState(DEFAULT_FORM_DATA);
    const [initialData, setInitialData] = React.useState(DEFAULT_FORM_DATA);
    const isValid = React.useMemo(() => {
        return formData.name.trim() && formData.name.trim() !== initialData.name.trim();
    }, [formData, initialData]);
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const listShadowingTypesApi = useListShadowingTypes({ query: { enabled: false } });
    const getShadowingTypeApi = useGetShadowingType(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: false } });
    const createShadowingTypeApi = useAddShadowingType();
    const updateShadowingTypeApi = useUpdateShadowingType();
    const deleteShadowingTypeApi = useDeleteShadowingType();
    React.useEffect(() => {
        if (uid) {
            getShadowingTypeApi
                .refetch()
                .then((result) => {
                setFormData(result.data.data);
                setInitialData(result.data.data);
            })
                .catch(errorDialog);
        }
        else {
            setFormData(DEFAULT_FORM_DATA);
            setInitialData(DEFAULT_FORM_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const createShadowingType = React.useCallback((params) => {
        createShadowingTypeApi
            .mutateAsync({ data: { name: params.name } })
            .then((result) => {
            queryClient.setQueryData(listShadowingTypesApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
            navigate("/onboarding/shadowing-types");
        })
            .catch(errorDialog);
    }, [createShadowingTypeApi, errorDialog, listShadowingTypesApi.queryKey, navigate, queryClient]);
    const updateShadowingType = React.useCallback((shadowingTypeUid, params) => {
        updateShadowingTypeApi
            .mutateAsync({ uid: shadowingTypeUid, data: { name: params.name } })
            .then((result) => {
            queryClient.setQueryData(listShadowingTypesApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((t) => (t.uid === result.data.uid ? result.data : t)) });
            });
            navigate("/onboarding/shadowing-types");
        })
            .catch(errorDialog);
    }, [errorDialog, listShadowingTypesApi.queryKey, navigate, queryClient, updateShadowingTypeApi]);
    const saveShadowingType = React.useCallback(() => {
        if (uid) {
            updateShadowingType(uid, formData);
        }
        else {
            createShadowingType(formData);
        }
    }, [createShadowingType, formData, uid, updateShadowingType]);
    const deleteShadowingType = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteShadowingTypeApi
                    .mutateAsync({ uid: uid })
                    .then((result) => {
                    queryClient.setQueryData(listShadowingTypesApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: old.data.filter((t) => t.uid !== result.data.uid) });
                    });
                    navigate("/onboarding/shadowing-types");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteShadowingTypeApi, errorDialog, listShadowingTypesApi.queryKey, navigate, queryClient, uid]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const isSaving = createShadowingTypeApi.isPending || updateShadowingTypeApi.isPending || deleteShadowingTypeApi.isPending;
    return (React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Typography, { variant: "h6" },
                    uid ? "Edit" : "Add",
                    " Shadowing Type"))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { fullWidth: true, size: "small", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Name"),
                                },
                                htmlInput: {
                                    maxLength: 255,
                                },
                            }, value: formData.name, onChange: setName }))))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 1 },
                    uid && (React.createElement(Grid, { item: true },
                        React.createElement(Button, { onClick: deleteShadowingType, variant: "contained", color: "error", startIcon: React.createElement(Delete, null), disabled: isSaving, tabIndex: -1 }, "Delete"))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { component: RouterLink, to: "/onboarding/shadowing-types", variant: "text", startIcon: React.createElement(Close, null), disabled: isSaving, tabIndex: -1 }, "Cancel")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { onClick: saveShadowingType, variant: "outlined", color: "primary", startIcon: React.createElement(Save, null), disabled: isSaving || !isValid, tabIndex: -1 }, "Save")))))));
};
export default ShadowingTypeForm;
