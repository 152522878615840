import * as React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import styled from "styled-components";
import useStateCallback from "@app/hooks/useStateCallback";
import AppConfig from "@app/util/AppConfig";
// define your extension array
const extensions = [StarterKit];
// mostly copied from https://tiptap.dev/docs/editor/installation/react
const StyledEditorContent = styled(EditorContent) `

border: 1px solid #ccc;
border-radius: 0.25rem;
padding: 0.5rem;

.tiptap {
  font-family: ${AppConfig.fonts.primary} !important;

  outline: none;

  > * + * {
    margin-top: 0.75em;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
`;
const CoreRichTextField = (props) => {
    const { onBlur, onTextChange, rawValue } = props;
    const [value, setValue] = useStateCallback(rawValue);
    React.useEffect(() => {
        console.log("updating value");
        setValue(rawValue);
    }, [rawValue]);
    const handleChange = React.useCallback((params) => {
        console.log({ event: "update", params });
        if (onTextChange) {
            const html = params.editor.getHTML();
            onTextChange(html);
        }
    }, [onTextChange]);
    const handleBlur = React.useCallback((params) => {
        console.log({ event: "blur", params });
        if (onBlur) {
            const html = params.editor.getHTML();
            onBlur(html);
        }
    }, [onBlur]);
    const editor = useEditor({
        extensions,
        content: value,
        onUpdate: handleChange,
        onBlur: handleBlur,
    });
    // weird typescript issue with EditorContentProps...
    return React.createElement(StyledEditorContent, Object.assign({}, props.EditorContentProps, { editor: editor }));
};
export default CoreRichTextField;
