import * as React from "react";
import { Info } from "@mui/icons-material";
import { Box, CircularProgress, Grid2, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import { useGetMyDashscore, useGetMyFlexCvr } from "@app/orval/api/dash-score";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
const DASHscoreInfo = () => {
    return (React.createElement(Tooltip, { title: React.createElement(Typography, null, "This value reflects the current DASHscore regardless of any filters.") },
        React.createElement(Info, null)));
};
const CVRInfo = () => {
    return (React.createElement(Tooltip, { title: React.createElement(Typography, null, "This value reflects the current CVR % regardless of any filters.") },
        React.createElement(Info, null)));
};
const DASHscore = () => {
    var _a, _b, _c, _d;
    const useDashscoreApi = useGetMyDashscore(Object.assign({}, REFETCH_CACHE_PARAMS));
    const dashscoreData = (_b = (_a = useDashscoreApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const useFlexCvrAPI = useGetMyFlexCvr(INFINITE_CACHE_PARAMS);
    const flexCVRData = (_d = (_c = useFlexCvrAPI.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid2, { container: true, spacing: 4 },
            React.createElement(Grid2, null,
                React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                    React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { style: { fontWeight: "bold" } }, "DASHscore")),
                        React.createElement(Grid2, null,
                            React.createElement(DASHscoreInfo, null))),
                    React.createElement(Grid2, null,
                        useDashscoreApi.isFetching && React.createElement(CircularProgress, null),
                        useDashscoreApi.error && React.createElement(CoreError, { error: useDashscoreApi.error }),
                        dashscoreData != null && (React.createElement(Typography, { variant: "h3", fontWeight: "bold" }, dashscoreData.value ? dashscoreData.value.score : "n/a"))))),
            React.createElement(Grid2, null,
                React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                    React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { style: { fontWeight: "bold" } }, "FLEX CVR %")),
                        React.createElement(Grid2, null,
                            React.createElement(CVRInfo, null))),
                    React.createElement(Grid2, null,
                        useFlexCvrAPI.isFetching && !flexCVRData && React.createElement(CircularProgress, null),
                        useFlexCvrAPI.error && React.createElement(CoreError, { error: useFlexCvrAPI.error }),
                        flexCVRData && (React.createElement(Typography, { variant: "h3", fontWeight: "bold" }, flexCVRData.value ? numeral(flexCVRData.value * 100).format("0,0%") : "n/a"))))))));
};
export default DASHscore;
