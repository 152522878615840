/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get random listing photos
 */
export const listPspOffers = (options) => {
    return axios.get(`/api/get_random_listing_photos`, options);
};
export const getListPspOffersQueryKey = () => {
    return [`/api/get_random_listing_photos`];
};
export const getListPspOffersQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspOffersQueryKey();
    const queryFn = ({ signal }) => listPspOffers(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get random listing photos
 */
export function useListPspOffers(options) {
    const queryOptions = getListPspOffersQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List PSPs
 */
export const listPsps = (params, options) => {
    return axios.get(`/api/psps`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspsQueryKey = (params) => {
    return [`/api/psps`, ...(params ? [params] : [])];
};
export const getListPspsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspsQueryKey(params);
    const queryFn = ({ signal }) => listPsps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSPs
 */
export function useListPsps(params, options) {
    const queryOptions = getListPspsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a PSP
 */
export const createPsp = (mAtrixCreateRequest, options) => {
    return axios.post(`/api/psps`, mAtrixCreateRequest, options);
};
export const getCreatePspMutationOptions = (options) => {
    const mutationKey = ['createPsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPsp(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a PSP
*/
export const useCreatePsp = (options) => {
    const mutationOptions = getCreatePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a PSP
*/
export const getPsp = (uid, options) => {
    return axios.get(`/api/psps/${uid}`, options);
};
export const getGetPspQueryKey = (uid) => {
    return [`/api/psps/${uid}`];
};
export const getGetPspQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspQueryKey(uid);
    const queryFn = ({ signal }) => getPsp(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP
 */
export function useGetPsp(uid, options) {
    const queryOptions = getGetPspQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a PSP
 */
export const updatePsp = (uid, mAtrixWriteRequest, options) => {
    return axios.post(`/api/psps/${uid}`, mAtrixWriteRequest, options);
};
export const getUpdatePspMutationOptions = (options) => {
    const mutationKey = ['updatePsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updatePsp(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a PSP
*/
export const useUpdatePsp = (options) => {
    const mutationOptions = getUpdatePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a PSP
*/
export const deletePsp = (uid, options) => {
    return axios.delete(`/api/psps/${uid}`, options);
};
export const getDeletePspMutationOptions = (options) => {
    const mutationKey = ['deletePsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePsp(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a PSP
*/
export const useDeletePsp = (options) => {
    const mutationOptions = getDeletePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Archive a PSP
*/
export const archivePsp = (uid, options) => {
    return axios.post(`/api/psps/${uid}/archive`, undefined, options);
};
export const getArchivePspMutationOptions = (options) => {
    const mutationKey = ['archivePsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return archivePsp(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Archive a PSP
*/
export const useArchivePsp = (options) => {
    const mutationOptions = getArchivePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Duplicate a PSP
*/
export const duplicatePsp = (uid, mAtrixDuplicateRequest, options) => {
    return axios.post(`/api/psps/${uid}/duplicate`, mAtrixDuplicateRequest, options);
};
export const getDuplicatePspMutationOptions = (options) => {
    const mutationKey = ['duplicatePsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return duplicatePsp(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a PSP
*/
export const useDuplicatePsp = (options) => {
    const mutationOptions = getDuplicatePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Share a PSP
*/
export const sharePsp = (uid, mAtrixShareWriteRequest, options) => {
    return axios.post(`/api/psps/${uid}/share`, mAtrixShareWriteRequest, options);
};
export const getSharePspMutationOptions = (options) => {
    const mutationKey = ['sharePsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return sharePsp(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Share a PSP
*/
export const useSharePsp = (options) => {
    const mutationOptions = getSharePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Submit a training PSP
*/
export const submitPsp = (uid, options) => {
    return axios.post(`/api/psps/${uid}/submit`, undefined, options);
};
export const getSubmitPspMutationOptions = (options) => {
    const mutationKey = ['submitPsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return submitPsp(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit a training PSP
*/
export const useSubmitPsp = (options) => {
    const mutationOptions = getSubmitPspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Unarchive a PSP
*/
export const unarchivePsp = (uid, options) => {
    return axios.post(`/api/psps/${uid}/unarchive`, undefined, options);
};
export const getUnarchivePspMutationOptions = (options) => {
    const mutationKey = ['unarchivePsp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return unarchivePsp(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Unarchive a PSP
*/
export const useUnarchivePsp = (options) => {
    const mutationOptions = getUnarchivePspMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Archive multiple PSPs
*/
export const bulkArchivePsps = (multipleUIDRequest, options) => {
    return axios.post(`/api/psps/bulk_archive`, multipleUIDRequest, options);
};
export const getBulkArchivePspsMutationOptions = (options) => {
    const mutationKey = ['bulkArchivePsps'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkArchivePsps(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Archive multiple PSPs
*/
export const useBulkArchivePsps = (options) => {
    const mutationOptions = getBulkArchivePspsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete multiple PSPs
*/
export const bulkDeletePsps = (multipleUIDRequest, options) => {
    return axios.post(`/api/psps/bulk_delete`, multipleUIDRequest, options);
};
export const getBulkDeletePspsMutationOptions = (options) => {
    const mutationKey = ['bulkDeletePsps'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeletePsps(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple PSPs
*/
export const useBulkDeletePsps = (options) => {
    const mutationOptions = getBulkDeletePspsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Unarchive multiple PSPs
*/
export const bulkUnarchivePsps = (multipleUIDRequest, options) => {
    return axios.post(`/api/psps/bulk_unarchive`, multipleUIDRequest, options);
};
export const getBulkUnarchivePspsMutationOptions = (options) => {
    const mutationKey = ['bulkUnarchivePsps'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUnarchivePsps(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Unarchive multiple PSPs
*/
export const useBulkUnarchivePsps = (options) => {
    const mutationOptions = getBulkUnarchivePspsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count PSPs
*/
export const countPsps = (params, options) => {
    return axios.get(`/api/psps/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountPspsQueryKey = (params) => {
    return [`/api/psps/count`, ...(params ? [params] : [])];
};
export const getCountPspsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountPspsQueryKey(params);
    const queryFn = ({ signal }) => countPsps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count PSPs
 */
export function useCountPsps(params, options) {
    const queryOptions = getCountPspsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
