import * as React from "react";
import { useSearchParams } from "react-router-dom";
import AppManager from "@app/util/AppManager";
import Overview from "./Overview";
import usePaginatedTable from "../../../../crm/components/spreadsheet-view/usePaginatedTable";
const OverviewPage = () => {
    React.useEffect(() => {
        AppManager.shared.pageTitle = { title: "Overview" };
        return () => {
            AppManager.shared.pageTitle = {};
        };
    }, []);
    const { limit, offset, search, showNewsfeed } = usePaginatedTable();
    const [searchParams, _] = useSearchParams();
    const status = searchParams.get("status");
    return (React.createElement(Overview, { limit: limit !== null && limit !== void 0 ? limit : undefined, offset: offset !== null && offset !== void 0 ? offset : undefined, search: search !== null && search !== void 0 ? search : undefined, status: status !== null && status !== void 0 ? status : undefined, showNewsfeed: showNewsfeed }));
};
export default OverviewPage;
