/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get PSP Report URL
 */
export const getPspReportUrl = (uid, options) => {
    return axios.get(`/api/get_psp_report_url/${uid}`, options);
};
export const getGetPspReportUrlQueryKey = (uid) => {
    return [`/api/get_psp_report_url/${uid}`];
};
export const getGetPspReportUrlQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspReportUrlQueryKey(uid);
    const queryFn = ({ signal }) => getPspReportUrl(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get PSP Report URL
 */
export function useGetPspReportUrl(uid, options) {
    const queryOptions = getGetPspReportUrlQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Generate Aged Property Report
 */
export const generateAgedPropertyReport = (matrixUid, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_aged_property_report`, undefined, options);
};
export const getGenerateAgedPropertyReportMutationOptions = (options) => {
    const mutationKey = ['generateAgedPropertyReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid } = props !== null && props !== void 0 ? props : {};
        return generateAgedPropertyReport(matrixUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Aged Property Report
*/
export const useGenerateAgedPropertyReport = (options) => {
    const mutationOptions = getGenerateAgedPropertyReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Buyer PSP Report
*/
export const generateBuyerPspReport = (matrixUid, pSPReportBodyRequest, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_buyer_psp_report`, pSPReportBodyRequest, options);
};
export const getGenerateBuyerPspReportMutationOptions = (options) => {
    const mutationKey = ['generateBuyerPspReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid, data } = props !== null && props !== void 0 ? props : {};
        return generateBuyerPspReport(matrixUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Buyer PSP Report
*/
export const useGenerateBuyerPspReport = (options) => {
    const mutationOptions = getGenerateBuyerPspReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Cash to Close
*/
export const generateCashToClose = (matrixUid, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_cash_to_close`, undefined, options);
};
export const getGenerateCashToCloseMutationOptions = (options) => {
    const mutationKey = ['generateCashToClose'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid } = props !== null && props !== void 0 ? props : {};
        return generateCashToClose(matrixUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Cash to Close
*/
export const useGenerateCashToClose = (options) => {
    const mutationOptions = getGenerateCashToCloseMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Listing Presentation PDF
*/
export const generateInvestorReport = (matrixUid, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_investor_report`, undefined, options);
};
export const getGenerateInvestorReportMutationOptions = (options) => {
    const mutationKey = ['generateInvestorReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid } = props !== null && props !== void 0 ? props : {};
        return generateInvestorReport(matrixUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Listing Presentation PDF
*/
export const useGenerateInvestorReport = (options) => {
    const mutationOptions = getGenerateInvestorReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Listing Presentation PDF
*/
export const generateListingPresentationPdf = (matrixUid, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_listing_presentation_pdf`, undefined, options);
};
export const getGenerateListingPresentationPdfMutationOptions = (options) => {
    const mutationKey = ['generateListingPresentationPdf'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid } = props !== null && props !== void 0 ? props : {};
        return generateListingPresentationPdf(matrixUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Listing Presentation PDF
*/
export const useGenerateListingPresentationPdf = (options) => {
    const mutationOptions = getGenerateListingPresentationPdfMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Listing PSP Report
*/
export const generateListingPspReport = (matrixUid, listingPSPReportBodyRequest, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_listing_psp_report`, listingPSPReportBodyRequest, options);
};
export const getGenerateListingPspReportMutationOptions = (options) => {
    const mutationKey = ['generateListingPspReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid, data } = props !== null && props !== void 0 ? props : {};
        return generateListingPspReport(matrixUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Listing PSP Report
*/
export const useGenerateListingPspReport = (options) => {
    const mutationOptions = getGenerateListingPspReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Multiple Offers report
*/
export const generateMultipleOffers = (matrixUid, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_multiple_offers`, undefined, options);
};
export const getGenerateMultipleOffersMutationOptions = (options) => {
    const mutationKey = ['generateMultipleOffers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid } = props !== null && props !== void 0 ? props : {};
        return generateMultipleOffers(matrixUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Multiple Offers report
*/
export const useGenerateMultipleOffers = (options) => {
    const mutationOptions = getGenerateMultipleOffersMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Net Sheet
*/
export const generateNetSheet = (matrixUid, netSheetBodyRequest, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_net_sheet`, netSheetBodyRequest, options);
};
export const getGenerateNetSheetMutationOptions = (options) => {
    const mutationKey = ['generateNetSheet'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid, data } = props !== null && props !== void 0 ? props : {};
        return generateNetSheet(matrixUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Net Sheet
*/
export const useGenerateNetSheet = (options) => {
    const mutationOptions = getGenerateNetSheetMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Pre-Listing Presentation PDF
*/
export const generatePreListingPresentationPdf = (matrixUid, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_pre_listing_presentation_pdf`, undefined, options);
};
export const getGeneratePreListingPresentationPdfMutationOptions = (options) => {
    const mutationKey = ['generatePreListingPresentationPdf'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid } = props !== null && props !== void 0 ? props : {};
        return generatePreListingPresentationPdf(matrixUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Pre-Listing Presentation PDF
*/
export const useGeneratePreListingPresentationPdf = (options) => {
    const mutationOptions = getGeneratePreListingPresentationPdfMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Generate Templated PSP Report
*/
export const generateTemplatedPspReport = (matrixUid, templatedPSPReportBodyRequest, options) => {
    return axios.post(`/api/psps/${matrixUid}/generate_templated_psp_report`, templatedPSPReportBodyRequest, options);
};
export const getGenerateTemplatedPspReportMutationOptions = (options) => {
    const mutationKey = ['generateTemplatedPspReport'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid, data } = props !== null && props !== void 0 ? props : {};
        return generateTemplatedPspReport(matrixUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Generate Templated PSP Report
*/
export const useGenerateTemplatedPspReport = (options) => {
    const mutationOptions = getGenerateTemplatedPspReportMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Send PSP Comments with attached report to the Assigned Agent
*/
export const sendPspComments = (matrixUid, sendCommentsBodyRequest, options) => {
    return axios.post(`/api/psps/${matrixUid}/send_psp_comments`, sendCommentsBodyRequest, options);
};
export const getSendPspCommentsMutationOptions = (options) => {
    const mutationKey = ['sendPspComments'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { matrixUid, data } = props !== null && props !== void 0 ? props : {};
        return sendPspComments(matrixUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Send PSP Comments with attached report to the Assigned Agent
*/
export const useSendPspComments = (options) => {
    const mutationOptions = getSendPspCommentsMutationOptions(options);
    return useMutation(mutationOptions);
};
