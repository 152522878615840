import * as React from "react";
import { AutoAwesome, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid2, Step, StepLabel, Stepper, Switch, TextField, Typography, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar } from "notistack";
import CoreDialog from "@app/common/CoreDialog";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useImportMlsDataForBuyerContract } from "@app/orval/api/buyer-contracts";
import { useSearchMlsesByAddress } from "@app/orval/api/mls";
const MLSES = ["Triangle MLS", "Triad MLS", "Canopy", "FlexMLS"];
const mlsToBuyerContractMapping = {
    has_pool: "Pool",
    has_hoa: "HOA?",
    has_septic: "Septic",
    has_well: "Well",
    county: "County",
    street_number: "Street #",
    street_name: "Street Name",
    city: "City",
    state: "State",
    zip_code: "Zip",
    property_sub_type: "Property Type",
    listing_id: "MLS#",
    subdivision_name: "Subdivision",
    year_built: "Year Built",
    living_area: "SqFt",
    formatted_address: "Property Address",
};
const getDataForImport = (mlsData) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        Pool: mlsData.has_pool ? "Yes" : "No",
        "HOA?": mlsData.has_hoa ? "Yes" : "No",
        Septic: mlsData.has_septic ? "Yes" : "No",
        Well: mlsData.has_well ? "Yes" : "No",
        County: (_a = mlsData.county) !== null && _a !== void 0 ? _a : null,
        "Street #": (_b = mlsData.street_number) !== null && _b !== void 0 ? _b : null,
        "Street Name": (_c = mlsData.street_name) !== null && _c !== void 0 ? _c : null,
        Subdivision: (_d = mlsData.subdivision_name) !== null && _d !== void 0 ? _d : null,
        "Year Built": mlsData.year_built ? String(mlsData.year_built) : null,
        SqFt: mlsData.living_area ? Math.round(mlsData.living_area) : null,
        "Property Address": mlsData.formatted_address,
        "MLS#": mlsData.listing_id,
        City: (_e = mlsData.city) !== null && _e !== void 0 ? _e : null,
        State: (_f = mlsData.state) !== null && _f !== void 0 ? _f : null,
        Zip: (_g = mlsData.zip_code) !== null && _g !== void 0 ? _g : null,
    };
};
const getListingAgentData = (mlsData) => {
    var _a, _b, _c, _d, _e;
    return {
        "First Name": (_a = mlsData.list_agent_first_name) !== null && _a !== void 0 ? _a : null,
        "Last Name": (_b = mlsData.list_agent_last_name) !== null && _b !== void 0 ? _b : null,
        Email: (_c = mlsData.list_agent_email) !== null && _c !== void 0 ? _c : null,
        Phone: (_d = mlsData.list_agent_phone) !== null && _d !== void 0 ? _d : null,
        Company: (_e = mlsData.listing_company) !== null && _e !== void 0 ? _e : null,
        "Vendor Type": "Agent",
    };
};
const ImportFromMLSDialog = (props) => {
    var _a, _b, _c;
    const { DialogProps, buyerContract, onClose, onImportComplete } = props;
    const queryClient = useQueryClient();
    const [mlsId, setMlsId] = React.useState(null);
    const [propertyAddress, setPropertyAddress] = React.useState(null);
    const [mlses, setMlses] = React.useState(MLSES);
    const [fieldsToImport, setFieldsToImport] = React.useState(null);
    const [listAgentFieldsToImport, setListAgentFieldsToImport] = React.useState(null);
    const [shouldImportListingAgent, setShouldImportListingAgent] = React.useState(true);
    const queryParams = React.useMemo(() => {
        return pickBy({
            address: !mlsId ? (propertyAddress !== null && propertyAddress !== void 0 ? propertyAddress : "") : null,
            listing_id: mlsId,
        }, (val) => val != null);
    }, [mlsId, propertyAddress]);
    const searchMlsApi = useSearchMlsesByAddress(queryParams, { query: { enabled: false } });
    const mlsData = (_b = (_a = searchMlsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const mostRecentMlsData = (_c = mlsData === null || mlsData === void 0 ? void 0 : mlsData.sort((a, b) => {
        var _a, _b;
        const aOnMarketDate = (_a = a.on_market_date) !== null && _a !== void 0 ? _a : "";
        const bOnMarketDate = (_b = b.on_market_date) !== null && _b !== void 0 ? _b : "";
        if (aOnMarketDate > bOnMarketDate)
            return -1;
        if (aOnMarketDate < bOnMarketDate)
            return 1;
        return 0;
    })[0]) !== null && _c !== void 0 ? _c : null;
    const dataForImport = mostRecentMlsData ? getDataForImport(mostRecentMlsData) : null;
    const importMLSDataApi = useImportMlsDataForBuyerContract();
    React.useEffect(() => {
        var _a;
        setMlsId((_a = buyerContract.fields["MLS#"]) !== null && _a !== void 0 ? _a : null);
        setPropertyAddress([
            buyerContract.fields["Street #"],
            buyerContract.fields["Street Name"],
            buyerContract.fields["City"],
            buyerContract.fields["State"],
        ]
            .filter((x) => x === null || x === void 0 ? void 0 : x.trim())
            .map((x) => x.trim())
            .join(" "));
    }, [buyerContract]);
    React.useEffect(() => {
        if (mostRecentMlsData) {
            const fieldsToImport_ = Object.keys(mostRecentMlsData).reduce((acc, key) => {
                if (!mlsToBuyerContractMapping[key]) {
                    return acc;
                }
                return Object.assign(Object.assign({}, acc), { [mlsToBuyerContractMapping[key]]: true });
            }, {});
            setFieldsToImport(fieldsToImport_);
        }
    }, [mostRecentMlsData]);
    const listAgentData = React.useMemo(() => {
        return mostRecentMlsData ? getListingAgentData(mostRecentMlsData) : null;
    }, [mostRecentMlsData]);
    const [activeStep, setActiveStep] = React.useState(0);
    React.useEffect(() => {
        if (mostRecentMlsData && listAgentData) {
            const listAgentFieldsToImport_ = Object.keys(listAgentData).filter((key) => {
                return listAgentData[key] !== null && key !== "Vendor Type";
            });
            setListAgentFieldsToImport(listAgentFieldsToImport_);
        }
        else {
            setListAgentFieldsToImport(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mostRecentMlsData]);
    const handlePropertyAddressChanged = React.useCallback((e) => {
        setPropertyAddress(e.target.value);
    }, [setPropertyAddress]);
    const handleMlsIdChanged = React.useCallback((e) => {
        setMlsId(e.target.value);
    }, [setMlsId]);
    const toggleMls = React.useCallback((mls) => () => {
        setMlses((prev) => {
            if (prev.includes(mls)) {
                return prev.filter((x) => x !== mls);
            }
            return [...prev, mls];
        });
    }, [setMlses]);
    const toggleField = React.useCallback((fieldName) => () => {
        setFieldsToImport((prev) => {
            return Object.assign(Object.assign({}, prev), { [fieldName]: !(prev === null || prev === void 0 ? void 0 : prev[fieldName]) });
        });
    }, [setFieldsToImport]);
    const toggleListAgentField = React.useCallback((fieldName) => () => {
        setListAgentFieldsToImport((prev) => {
            if (prev === null || prev === void 0 ? void 0 : prev.includes(fieldName)) {
                return prev.filter((x) => x !== fieldName);
            }
            return [...(prev !== null && prev !== void 0 ? prev : []), fieldName];
        });
    }, [setListAgentFieldsToImport]);
    const toggleShouldImportListingAgent = React.useCallback(() => {
        setShouldImportListingAgent((prev) => !prev);
    }, [setShouldImportListingAgent]);
    const previous = React.useCallback(() => {
        setActiveStep((prev) => prev - 1);
    }, [setActiveStep]);
    const next = React.useCallback(() => {
        setActiveStep((prev) => prev + 1);
    }, [setActiveStep]);
    // search MLS when we get to step 3
    React.useEffect(() => {
        if (activeStep === 2) {
            queryClient.setQueryData(searchMlsApi.queryKey, undefined);
            searchMlsApi.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);
    const isStepValid = React.useCallback((stepNumber) => {
        if (stepNumber === 0) {
            return !!(mlsId === null || mlsId === void 0 ? void 0 : mlsId.trim()) || !!(propertyAddress === null || propertyAddress === void 0 ? void 0 : propertyAddress.trim());
        }
        if (stepNumber === 1) {
            return mlses.length > 0;
        }
        if (stepNumber === 2) {
            return (dataForImport &&
                ((fieldsToImport == null && Object.keys(dataForImport).length > 0) ||
                    (fieldsToImport &&
                        Object.keys(fieldsToImport).filter((key) => fieldsToImport[key] && dataForImport[key]).length > 0)));
        }
        throw new Error(`Invalid step number ${stepNumber}`);
    }, [mlsId, propertyAddress, mlses.length, fieldsToImport, dataForImport]);
    const importData = React.useCallback(() => {
        const newAgentFields = {};
        if (listAgentData) {
            Object.keys(listAgentData)
                .filter((key) => listAgentFieldsToImport === null || listAgentFieldsToImport === void 0 ? void 0 : listAgentFieldsToImport.includes(key))
                .forEach((key) => {
                newAgentFields[key] = listAgentData[key];
            });
        }
        importMLSDataApi
            .mutateAsync({
            uid: buyerContract.uid,
            data: {
                buyer_contract_fields: pick(mostRecentMlsData ? getDataForImport(mostRecentMlsData) : {}, Object.keys(fieldsToImport !== null && fieldsToImport !== void 0 ? fieldsToImport : {}).filter((key) => fieldsToImport == null || fieldsToImport[key])),
                list_agent_fields: shouldImportListingAgent ? newAgentFields : null,
            },
        })
            .then((result) => {
            enqueueSnackbar("Data imported successfully", { variant: "success" });
            if (onImportComplete) {
                onImportComplete(result.data);
            }
        })
            .catch(enqueueErrorSnackbar)
            .then(onClose);
    }, [
        listAgentData,
        importMLSDataApi,
        buyerContract.uid,
        mostRecentMlsData,
        fieldsToImport,
        shouldImportListingAgent,
        onClose,
        listAgentFieldsToImport,
        onImportComplete,
    ]);
    const isWorking = React.useMemo(() => {
        return importMLSDataApi.isPending || searchMlsApi.isFetching;
    }, [importMLSDataApi.isPending, searchMlsApi.isFetching]);
    console.log({ listAgentData });
    return (React.createElement(CoreDialog, Object.assign({}, DialogProps, { open: true, maxWidth: "md", PaperProps: { style: { width: "500px" } } }),
        React.createElement(DialogTitle, null, "Import data from MLS"),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap", direction: "column" },
                React.createElement(Grid2, null,
                    React.createElement(Stepper, { activeStep: activeStep, alternativeLabel: true },
                        React.createElement(Step, null,
                            React.createElement(StepLabel, null, "Enter MLS ID")),
                        React.createElement(Step, null,
                            React.createElement(StepLabel, null, "Select MLS")),
                        React.createElement(Step, null,
                            React.createElement(StepLabel, null, "Review Data")))),
                activeStep === 0 && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 4, wrap: "nowrap", direction: "column" },
                        React.createElement(Grid2, null,
                            React.createElement(TextField, { label: "MLS ID", value: mlsId, onChange: handleMlsIdChanged, fullWidth: true, size: "small", variant: "standard" })),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body1", textAlign: "center" }, "or")),
                        React.createElement(Grid2, null,
                            React.createElement(TextField, { label: "Property Address", value: propertyAddress, onChange: handlePropertyAddressChanged, fullWidth: true, size: "small", variant: "standard" }))))),
                activeStep === 1 && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap", direction: "column" }, MLSES.map((mls) => (React.createElement(Grid2, { key: mls },
                        React.createElement(FormControlLabel, { label: mls, control: React.createElement(Switch, { checked: mlses.includes(mls), onChange: toggleMls(mls) }) }))))))),
                activeStep === 2 && isWorking && (React.createElement(Grid2, null,
                    React.createElement(CoreLoading, null))),
                activeStep === 2 && mostRecentMlsData && dataForImport && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "column", spacing: 0, wrap: "nowrap" }, Object.keys(mlsToBuyerContractMapping)
                        .filter((key) => dataForImport[mlsToBuyerContractMapping[key]] !== undefined)
                        .sort()
                        .map((key) => (React.createElement(Grid2, { key: key },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: fieldsToImport == null || fieldsToImport[mlsToBuyerContractMapping[key]], onChange: toggleField(mlsToBuyerContractMapping[key]), style: { paddingTop: 0, paddingBottom: 0 } }), label: `${mlsToBuyerContractMapping[key]}: ${dataForImport[mlsToBuyerContractMapping[key]]}` }))))),
                    listAgentData && listAgentFieldsToImport != null && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, { style: { paddingTop: "8px" } },
                            React.createElement(Typography, { variant: "body1", fontWeight: "bold" }, "Listing Agent")),
                        React.createElement(Grid2, null,
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: shouldImportListingAgent, onChange: toggleShouldImportListingAgent, style: { paddingTop: 0, paddingBottom: 0 } }), label: "Import Listing Agent?" })),
                        React.createElement(Grid2, { style: { marginLeft: "24px" } }, Object.keys(listAgentData)
                            .filter((key) => {
                            return !["Vendor Type"].includes(key) && listAgentData[key] !== null;
                        })
                            .map((key) => (React.createElement(Grid2, { key: key },
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: listAgentFieldsToImport.includes(key), disabled: !shouldImportListingAgent, onChange: toggleListAgentField(key), style: { paddingTop: 0, paddingBottom: 0 } }), label: `${key}: ${listAgentData[key]}` }))))))))),
                activeStep === 2 && !mostRecentMlsData && !searchMlsApi.isFetching && (React.createElement(Grid2, null,
                    React.createElement(Box, { p: 2 },
                        React.createElement(Typography, { textAlign: "center" }, "Unable to find any data for this listing")))))),
        React.createElement(DialogActions, null,
            React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap", alignItems: "center" },
                React.createElement(Grid2, null,
                    React.createElement(Button, { onClick: onClose, tabIndex: -1, disabled: isWorking }, "Cancel")),
                React.createElement(Grid2, { style: { flex: 1 } }),
                activeStep > 0 && (React.createElement(Button, { onClick: previous, tabIndex: -1, variant: "text", startIcon: React.createElement(KeyboardArrowLeft, null), disabled: isWorking }, "Previous")),
                activeStep < 2 && (React.createElement(Button, { onClick: next, tabIndex: -1, variant: "text", startIcon: React.createElement(KeyboardArrowRight, null), disabled: isWorking || !isStepValid(activeStep) }, "Next")),
                activeStep === 2 && (React.createElement(Button, { onClick: importData, tabIndex: -1, disabled: !isStepValid(activeStep) || isWorking, variant: "contained", startIcon: React.createElement(AutoAwesome, null) }, "Import"))))));
};
export default ImportFromMLSDialog;
