var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import * as React from "react";
import { Alert, Box, Button, Container, Divider, Grid2, MenuItem, TextField, Typography } from "@mui/material";
import CoreDateField from "@app/common/CoreDateField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useGetQflSellerLeadData, useSubmitQuestionsForTheListing, } from "@app/orval/api/forms-questions-for-the-listing";
import { useListQuestionsForTheListingMaritalStatusOptions, useListQuestionsForTheListingRoadMaintenanceOptions, useListQuestionsForTheListingSewerSystemOptions, useListQuestionsForTheListingWaterSystemOptions, } from "@app/orval/api/questions-for-the-listing";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { fileToBase64, validateEmail } from "@app/util/Utils";
import FileField from "./FileField";
import FormField from "./FormField";
import FormSection from "./FormSection";
import YesNoSelect from "./YesNoSelect";
const yesNoAsBool = (val) => {
    if (val === "Yes") {
        return true;
    }
    if (val === "No") {
        return false;
    }
    if (val == null) {
        return null;
    }
    console.error(`Unexpected value ${val}`);
    return null;
};
const saveFormDataToSessionStorage = (formData) => {
    sessionStorage.setItem("qflFormData", JSON.stringify(formData));
};
const getFormDataFromSessionStorage = () => {
    const sessionData = sessionStorage.getItem("qflFormData");
    return sessionData ? JSON.parse(sessionData) : null;
};
const QuestionsForTheListingFormNEWCRM = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24;
    const { sellerLeadUid } = props;
    const getSellerLeadDataApi = useGetQflSellerLeadData(sellerLeadUid);
    const sellerLeadData = (_b = (_a = getSellerLeadDataApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const submitQFLAPI = useSubmitQuestionsForTheListing();
    const [isComplete, setIsComplete] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        const sessionData = getFormDataFromSessionStorage();
        const sellerLeadFormData = sellerLeadData
            ? {
                full_legal_names_1: [
                    (_a = sellerLeadData.clients[0]) === null || _a === void 0 ? void 0 : _a.client.first_name,
                    (_b = sellerLeadData.clients[0]) === null || _b === void 0 ? void 0 : _b.client.last_name,
                ]
                    .filter((o) => o)
                    .join(" "),
                full_legal_names_2: [
                    (_c = sellerLeadData.clients[1]) === null || _c === void 0 ? void 0 : _c.client.first_name,
                    (_d = sellerLeadData.clients[1]) === null || _d === void 0 ? void 0 : _d.client.last_name,
                ]
                    .filter((o) => o)
                    .join(" "),
                date_of_birth_1: (_e = sellerLeadData.clients[0]) === null || _e === void 0 ? void 0 : _e.client.date_of_birth,
                date_of_birth_2: (_f = sellerLeadData.clients[1]) === null || _f === void 0 ? void 0 : _f.client.date_of_birth,
                marital_status: sellerLeadData.fields["Marital Status"],
                primary_residence: yesNoAsBool(sellerLeadData.fields["Occupied?"]),
                licensed_broker_in_nc: yesNoAsBool(sellerLeadData.fields["Licensed?"]),
                spouse_email_address: (_g = sellerLeadData.clients[1]) === null || _g === void 0 ? void 0 : _g.client.email,
                personal_items_that_convey: sellerLeadData.fields["Personal Property*"],
                items_leased_or_not_owned: sellerLeadData.fields["Items Not Owned"],
                fixtures_that_do_not_convey: sellerLeadData.fields["Items Do not Convey"],
                current_liens_on_property: yesNoAsBool(sellerLeadData.fields["Current Lein(s)?"]),
                first_mortgage_lender: sellerLeadData.fields["1st Mortgage Lender"],
                first_mortgage_approx_balance: sellerLeadData.fields["1st Mortgage Balance"],
                second_mortgage_lender: sellerLeadData.fields["2nd Mortgage Lender"],
                second_mortgage_approx_balance: sellerLeadData.fields["2nd Mortgage Balance"],
                heloc_lender: sellerLeadData.fields["HELOC Lender"],
                heloc_approx_balance: sellerLeadData.fields["HELOC Balance"],
                heloc_lender_phone: sellerLeadData.fields["HELOC #"],
                is_hoa: yesNoAsBool(sellerLeadData.fields["HOA?"]),
                homeowners_association_name: sellerLeadData.fields["HOA Name"],
                hoa_contact_email: sellerLeadData.fields["HOA Contact Email"],
                hoa_website: sellerLeadData.fields["HOA Website"],
                hoa_amenities: sellerLeadData.fields["HOA Amenities"],
                list_price: sellerLeadData.fields["List Price"],
                buyer_agent_commission_percent: sellerLeadData.fields["Buy Side Commission %"],
                active_date: sellerLeadData.fields["Active Date"],
                coming_soon_marketing: yesNoAsBool(sellerLeadData.fields["Coming Soon"]),
                coming_soon_date: sellerLeadData.fields["Coming Soon Date"],
                photo_ready_date: sellerLeadData.fields["Photo Ready Date"],
                is_house_currently_occupied: yesNoAsBool(sellerLeadData.fields["Occupied?"]),
                notice_needed_for_showings: sellerLeadData.fields["Notice Required?"],
                recipient_of_emails_for_showing_approvals: sellerLeadData.fields["Showing Approval Email"],
                recipient_of_texts_for_showing_approvals: sellerLeadData.fields["Showing Approval Phone"],
                pets_to_be_concerned_about: yesNoAsBool(sellerLeadData.fields["Pets?"]),
                water_system: sellerLeadData.fields["Water"],
                sewer_system: sellerLeadData.fields["Sewer"],
                has_basement: yesNoAsBool(sellerLeadData.fields["Basement"]),
                has_garage: yesNoAsBool(sellerLeadData.fields["Garage"]),
                road_maintenance: sellerLeadData.fields["Road Responsibility"],
                known_encroachments_easments_or_encumberances: sellerLeadData.fields["Encroachments"],
                have_copy_of_previous_title_insurance_policy: yesNoAsBool(sellerLeadData.fields["Previous Title Insurance"]),
                previous_closing_attorney_contact_info: sellerLeadData.fields["Previous Closing Attorney Contact Info"],
                attachments: [],
            }
            : {};
        setFormData(Object.assign(Object.assign({}, sellerLeadFormData), (sessionData !== null && sessionData !== void 0 ? sessionData : {})));
    }, [sellerLeadData]);
    React.useEffect(() => {
        const formDataForStorage = Object.assign({}, formData);
        delete formDataForStorage.attachments;
        saveFormDataToSessionStorage(formDataForStorage);
    }, [formData]);
    const sanitizedData = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
        return {
            // ...formData,
            seller_lead_uid: sellerLeadUid,
            full_legal_names_1: ((_a = formData.full_legal_names_1) === null || _a === void 0 ? void 0 : _a.trim()) || null,
            full_legal_names_2: ((_b = formData.full_legal_names_2) === null || _b === void 0 ? void 0 : _b.trim()) || null,
            date_of_birth_1: ((_c = formData.date_of_birth_1) === null || _c === void 0 ? void 0 : _c.trim()) || null,
            date_of_birth_2: ((_d = formData.date_of_birth_2) === null || _d === void 0 ? void 0 : _d.trim()) || null,
            marital_status: ((_e = formData.marital_status) === null || _e === void 0 ? void 0 : _e.trim()) || null,
            primary_residence: (_f = formData.primary_residence) !== null && _f !== void 0 ? _f : null,
            licensed_broker_in_nc: (_g = formData.licensed_broker_in_nc) !== null && _g !== void 0 ? _g : null,
            spouse_email_address: ((_h = formData.spouse_email_address) === null || _h === void 0 ? void 0 : _h.trim()) || null,
            personal_items_that_convey: ((_j = formData.personal_items_that_convey) === null || _j === void 0 ? void 0 : _j.trim()) || null,
            items_leased_or_not_owned: ((_k = formData.items_leased_or_not_owned) === null || _k === void 0 ? void 0 : _k.trim()) || null,
            fixtures_that_do_not_convey: ((_l = formData.fixtures_that_do_not_convey) === null || _l === void 0 ? void 0 : _l.trim()) || null,
            current_liens_on_property: (_m = formData.current_liens_on_property) !== null && _m !== void 0 ? _m : null,
            first_mortgage_lender: ((_o = formData.first_mortgage_lender) === null || _o === void 0 ? void 0 : _o.trim()) || null,
            first_mortgage_approx_balance: (_p = formData.first_mortgage_approx_balance) !== null && _p !== void 0 ? _p : null,
            second_mortgage_lender: ((_q = formData.second_mortgage_lender) === null || _q === void 0 ? void 0 : _q.trim()) || null,
            second_mortgage_approx_balance: (_r = formData.second_mortgage_approx_balance) !== null && _r !== void 0 ? _r : null,
            heloc_lender: ((_s = formData.heloc_lender) === null || _s === void 0 ? void 0 : _s.trim()) || null,
            heloc_approx_balance: (_t = formData.heloc_approx_balance) !== null && _t !== void 0 ? _t : null,
            heloc_lender_phone: ((_u = formData.heloc_lender_phone) === null || _u === void 0 ? void 0 : _u.trim()) || null,
            is_hoa: (_v = formData.is_hoa) !== null && _v !== void 0 ? _v : null,
            homeowners_association_name: ((_w = formData.homeowners_association_name) === null || _w === void 0 ? void 0 : _w.trim()) || null,
            hoa_contact_email: ((_x = formData.hoa_contact_email) === null || _x === void 0 ? void 0 : _x.trim()) || null,
            hoa_website: ((_y = formData.hoa_website) === null || _y === void 0 ? void 0 : _y.trim()) || null,
            hoa_amenities: ((_z = formData.hoa_amenities) === null || _z === void 0 ? void 0 : _z.trim()) || null,
            list_price: (_0 = formData.list_price) !== null && _0 !== void 0 ? _0 : null,
            buyer_agent_commission_percent: (_1 = formData.buyer_agent_commission_percent) !== null && _1 !== void 0 ? _1 : null,
            active_date: ((_2 = formData.active_date) === null || _2 === void 0 ? void 0 : _2.trim()) || null,
            coming_soon_marketing: (_3 = formData.coming_soon_marketing) !== null && _3 !== void 0 ? _3 : null,
            coming_soon_date: ((_4 = formData.coming_soon_date) === null || _4 === void 0 ? void 0 : _4.trim()) || null,
            photo_ready_date: ((_5 = formData.photo_ready_date) === null || _5 === void 0 ? void 0 : _5.trim()) || null,
            is_house_currently_occupied: (_6 = formData.is_house_currently_occupied) !== null && _6 !== void 0 ? _6 : null,
            can_do_go_and_show: (_7 = formData.can_do_go_and_show) !== null && _7 !== void 0 ? _7 : null,
            notice_needed_for_showings: ((_8 = formData.notice_needed_for_showings) === null || _8 === void 0 ? void 0 : _8.trim()) || null,
            recipient_of_emails_for_showing_approvals: ((_9 = formData.recipient_of_emails_for_showing_approvals) === null || _9 === void 0 ? void 0 : _9.trim()) || null,
            recipient_of_texts_for_showing_approvals: ((_10 = formData.recipient_of_texts_for_showing_approvals) === null || _10 === void 0 ? void 0 : _10.trim()) || null,
            pets_to_be_concerned_about: (_11 = formData.pets_to_be_concerned_about) !== null && _11 !== void 0 ? _11 : null,
            water_system: ((_12 = formData.water_system) === null || _12 === void 0 ? void 0 : _12.trim()) || null,
            sewer_system: ((_13 = formData.sewer_system) === null || _13 === void 0 ? void 0 : _13.trim()) || null,
            has_basement: (_14 = formData.has_basement) !== null && _14 !== void 0 ? _14 : null,
            has_garage: (_15 = formData.has_garage) !== null && _15 !== void 0 ? _15 : null,
            road_maintenance: ((_16 = formData.road_maintenance) === null || _16 === void 0 ? void 0 : _16.trim()) || null,
            known_encroachments_easments_or_encumberances: ((_17 = formData.known_encroachments_easments_or_encumberances) === null || _17 === void 0 ? void 0 : _17.trim()) || null,
            have_copy_of_previous_title_insurance_policy: (_18 = formData.have_copy_of_previous_title_insurance_policy) !== null && _18 !== void 0 ? _18 : null,
            previous_closing_attorney_contact_info: ((_19 = formData.previous_closing_attorney_contact_info) === null || _19 === void 0 ? void 0 : _19.trim()) || null,
            attachments: (_20 = formData.attachments) !== null && _20 !== void 0 ? _20 : [],
        };
    }, [formData, sellerLeadUid]);
    const isValid = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return (((_a = formData.full_legal_names_1) === null || _a === void 0 ? void 0 : _a.trim()) &&
            ((_b = formData.date_of_birth_1) === null || _b === void 0 ? void 0 : _b.trim()) &&
            ((_c = formData.marital_status) === null || _c === void 0 ? void 0 : _c.trim()) &&
            formData.primary_residence != null &&
            formData.licensed_broker_in_nc != null &&
            (!formData.full_legal_names_2 ||
                (((_d = formData.spouse_email_address) === null || _d === void 0 ? void 0 : _d.trim()) && validateEmail(formData.spouse_email_address))) &&
            ((_e = formData.personal_items_that_convey) === null || _e === void 0 ? void 0 : _e.trim()) &&
            ((_f = formData.items_leased_or_not_owned) === null || _f === void 0 ? void 0 : _f.trim()) &&
            ((_g = formData.fixtures_that_do_not_convey) === null || _g === void 0 ? void 0 : _g.trim()) &&
            formData.current_liens_on_property != null &&
            ((_h = formData.first_mortgage_lender) === null || _h === void 0 ? void 0 : _h.trim()) &&
            formData.first_mortgage_approx_balance != null &&
            formData.is_hoa != null &&
            formData.list_price != null &&
            formData.buyer_agent_commission_percent != null &&
            formData.active_date != null &&
            (!formData.coming_soon_marketing || !!formData.coming_soon_date) &&
            formData.photo_ready_date &&
            formData.is_house_currently_occupied != null &&
            formData.pets_to_be_concerned_about != null &&
            formData.has_basement != null &&
            formData.has_garage != null &&
            ((_j = formData.water_system) === null || _j === void 0 ? void 0 : _j.trim()) &&
            ((_k = formData.sewer_system) === null || _k === void 0 ? void 0 : _k.trim()) &&
            ((_l = formData.road_maintenance) === null || _l === void 0 ? void 0 : _l.trim()) &&
            ((_m = formData.known_encroachments_easments_or_encumberances) === null || _m === void 0 ? void 0 : _m.trim()) &&
            formData.have_copy_of_previous_title_insurance_policy != null);
    }, [formData]);
    const submit = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const promises = ((_a = formData.attachments) !== null && _a !== void 0 ? _a : []).map((a) => __awaiter(void 0, void 0, void 0, function* () { return fileToBase64(a.file); }));
        const attachmentBase64 = yield Promise.all(promises);
        submitQFLAPI
            .mutateAsync({
            data: Object.assign(Object.assign({}, sanitizedData), { attachments: ((_b = formData.attachments) !== null && _b !== void 0 ? _b : []).map((a, i) => ({
                    file_type: a.file_type,
                    filename: a.file.name,
                    content: attachmentBase64[i],
                })) }),
        })
            .then(() => {
            setIsComplete(true);
        })
            .catch(enqueueErrorSnackbar);
    }), [submitQFLAPI, sanitizedData, formData.attachments]);
    const listMaritalStatusOptionsApi = useListQuestionsForTheListingMaritalStatusOptions(INFINITE_CACHE_PARAMS);
    const maritalStatusOptions = (_d = (_c = listMaritalStatusOptionsApi.data) === null || _c === void 0 ? void 0 : _c.data.items) !== null && _d !== void 0 ? _d : null;
    const listWaterSystemOptionsApi = useListQuestionsForTheListingWaterSystemOptions(INFINITE_CACHE_PARAMS);
    const waterSystemOptions = (_f = (_e = listWaterSystemOptionsApi.data) === null || _e === void 0 ? void 0 : _e.data.items) !== null && _f !== void 0 ? _f : null;
    const listSewerSystemOptionsApi = useListQuestionsForTheListingSewerSystemOptions(INFINITE_CACHE_PARAMS);
    const sewerSystemOptions = (_h = (_g = listSewerSystemOptionsApi.data) === null || _g === void 0 ? void 0 : _g.data.items) !== null && _h !== void 0 ? _h : null;
    const listRoadMaintenanceOptionsApi = useListQuestionsForTheListingRoadMaintenanceOptions(INFINITE_CACHE_PARAMS);
    const roadMaintenanceOptions = (_k = (_j = listRoadMaintenanceOptionsApi.data) === null || _j === void 0 ? void 0 : _j.data.items) !== null && _k !== void 0 ? _k : null;
    const totalUploadedFileSize = React.useMemo(() => {
        var _a;
        return ((_a = formData.attachments) !== null && _a !== void 0 ? _a : []).reduce((acc, a) => acc + a.file.size, 0);
    }, [formData.attachments]);
    const maxFileSizeExceeded = React.useMemo(() => {
        return totalUploadedFileSize > 25 * 1024 * 1024;
    }, [totalUploadedFileSize]);
    const setFullLegalNames1 = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { full_legal_names_1: e.target.value })));
    }, []);
    const setFullLegalNames2 = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { full_legal_names_2: e.target.value })));
    }, []);
    const setDateOfBirth1 = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { date_of_birth_1: date })));
    }, []);
    const setDateOfBirth2 = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { date_of_birth_2: date })));
    }, []);
    const setMaritalStatus = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { marital_status: e.target.value })));
    }, []);
    const setPrimaryResidence = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { primary_residence: val })));
    }, []);
    const setLicensedBrokerInNC = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { licensed_broker_in_nc: val })));
    }, []);
    const setSpouseEmailAddress = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { spouse_email_address: e.target.value })));
    }, []);
    const setPersonalItemsThatConvey = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { personal_items_that_convey: e.target.value })));
    }, []);
    const setItemsLeasedOrNotOwned = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { items_leased_or_not_owned: e.target.value })));
    }, []);
    const setFixturesThatDoNotConvey = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { fixtures_that_do_not_convey: e.target.value })));
    }, []);
    const setCurrentLiensOnProperty = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { current_liens_on_property: val })));
    }, []);
    const setFirstMortgageLender = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { first_mortgage_lender: e.target.value })));
    }, []);
    const setFirstMortgageApproxBalance = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { first_mortgage_approx_balance: val })));
    }, []);
    const setSecondMortgageLender = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { second_mortgage_lender: e.target.value })));
    }, []);
    const setSecondMortgageApproxBalance = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { second_mortgage_approx_balance: val })));
    }, []);
    const setHelocLender = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { heloc_lender: e.target.value })));
    }, []);
    const setHelocApproxBalance = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { heloc_approx_balance: val })));
    }, []);
    const setHelocLenderPhone = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { heloc_lender_phone: e.target.value })));
    }, []);
    const setIsHOA = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_hoa: val })));
    }, []);
    const setHomeownersAssociationName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { homeowners_association_name: e.target.value })));
    }, []);
    const setHOAContactEmail = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { hoa_contact_email: e.target.value })));
    }, []);
    const setHOAWebsite = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { hoa_website: e.target.value })));
    }, []);
    const setHOAAmenities = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { hoa_amenities: e.target.value })));
    }, []);
    const setListPrice = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { list_price: val })));
    }, []);
    const setBuyerAgentCommissionPercent = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { buyer_agent_commission_percent: val })));
    }, []);
    const setActiveDate = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { active_date: date })));
    }, []);
    const setComingSoonMarketing = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { coming_soon_marketing: val })));
    }, []);
    const setComingSoonDate = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { coming_soon_date: date })));
    }, []);
    const setPhotoReadyDate = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { photo_ready_date: date })));
    }, []);
    const setIsHouseCurrentlyOccupied = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_house_currently_occupied: val })));
    }, []);
    const setCanDoGoAndShow = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { can_do_go_and_show: val })));
    }, []);
    const setNoticeNeededForShowings = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { notice_needed_for_showings: e.target.value })));
    }, []);
    const setRecipientOfEmailsForShowingApprovals = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { recipient_of_emails_for_showing_approvals: e.target.value })));
    }, []);
    const setRecipientOfTextsForShowingApprovals = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { recipient_of_texts_for_showing_approvals: e.target.value })));
    }, []);
    const setPetsToBeConcernedAbout = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { pets_to_be_concerned_about: val })));
    }, []);
    const setWaterSystem = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { water_system: e.target.value })));
    }, []);
    const setSewerSystem = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { sewer_system: e.target.value })));
    }, []);
    const setHasBasement = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { has_basement: val })));
    }, []);
    const setHasGarage = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { has_garage: val })));
    }, []);
    const setRoadMaintenance = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { road_maintenance: e.target.value })));
    }, []);
    const setKnownEncroachmentsEasmentsOrEncumberances = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { known_encroachments_easments_or_encumberances: e.target.value })));
    }, []);
    const setHaveCopyOfPreviousTitleInsurancePolicy = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { have_copy_of_previous_title_insurance_policy: val })));
    }, []);
    const setPreviousClosingAttorneyContactInfo = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { previous_closing_attorney_contact_info: e.target.value })));
    }, []);
    const setAttachment = React.useCallback((attachmentType) => (file) => {
        setFormData((prev) => {
            var _a;
            const attachments = ((_a = prev.attachments) !== null && _a !== void 0 ? _a : []).filter((x) => x.file_type !== attachmentType);
            if (file) {
                attachments.push({
                    file_type: attachmentType,
                    file,
                });
            }
            return Object.assign(Object.assign({}, prev), { attachments });
        });
    }, []);
    if (isComplete) {
        return (React.createElement(Container, { maxWidth: "md" },
            React.createElement(Box, { p: 2 },
                React.createElement(Typography, { variant: "body1", style: { textAlign: "center" } }, "Thank you for your submission!"))));
    }
    if (getSellerLeadDataApi.isFetching) {
        return React.createElement(CoreLoading, null);
    }
    if (getSellerLeadDataApi.error) {
        return React.createElement(CoreError, { error: getSellerLeadDataApi.error });
    }
    return (React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Container, { maxWidth: "lg" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Typography, { variant: "h4" }, "Questions for the Listing")))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Container, { maxWidth: "lg" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, spacing: 4, direction: "column" },
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Personal Information" },
                                React.createElement(Grid2, { container: true, spacing: 1, direction: "column" },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Full Legal Names", required: true },
                                            React.createElement(Grid2, { container: true, spacing: 1 },
                                                React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                                                    React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                            htmlInput: {
                                                                maxLength: 127,
                                                            },
                                                        }, value: (_l = formData.full_legal_names_1) !== null && _l !== void 0 ? _l : "", onChange: setFullLegalNames1, label: "First Middle Last *" })),
                                                React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                                                    React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                            htmlInput: {
                                                                maxLength: 127,
                                                            },
                                                        }, value: (_m = formData.full_legal_names_2) !== null && _m !== void 0 ? _m : "", onChange: setFullLegalNames2, label: "First Middle Last" }))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Date of Birth", required: true },
                                            React.createElement(Grid2, { container: true, spacing: 1 },
                                                React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                                                    React.createElement(CoreDateField, { fullWidth: true, variant: "standard", date: (_o = formData.date_of_birth_1) !== null && _o !== void 0 ? _o : null, onDateChange: setDateOfBirth1, format: "MMMM D, YYYY", formatType: "dayjs", hasPicker: true })),
                                                React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                                                    React.createElement(CoreDateField, { fullWidth: true, variant: "standard", date: (_p = formData.date_of_birth_2) !== null && _p !== void 0 ? _p : null, onDateChange: setDateOfBirth2, format: "MMMM D, YYYY", formatType: "dayjs", hasPicker: true }))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Marital Status", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, value: (_q = formData.marital_status) !== null && _q !== void 0 ? _q : "", onChange: setMaritalStatus },
                                                React.createElement(MenuItem, { value: "" }), maritalStatusOptions === null || maritalStatusOptions === void 0 ? void 0 :
                                                maritalStatusOptions.map((option) => (React.createElement(MenuItem, { key: option, value: option }, option)))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Is this your primary residence?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.primary_residence, onChange: setPrimaryResidence }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Are you or have you ever been a licensed broker in the state of North Carolina?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.licensed_broker_in_nc, onChange: setLicensedBrokerInNC }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Client #2 email address?", required: !!formData.full_legal_names_2 },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_r = formData.spouse_email_address) !== null && _r !== void 0 ? _r : "", onChange: setSpouseEmailAddress, helperText: formData.spouse_email_address && !validateEmail(formData.spouse_email_address)
                                                    ? "Must be a valid email address"
                                                    : "" })))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Personal Property" },
                                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "List any/all personals that DO convey:", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 1023,
                                                    },
                                                }, value: (_s = formData.personal_items_that_convey) !== null && _s !== void 0 ? _s : "", onChange: setPersonalItemsThatConvey, label: "i.e. fridge, washer, dryer, curtains, mower, etc or 'none'*" }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "List any items leased or NOT owned by you:", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 1023,
                                                    },
                                                }, value: (_t = formData.items_leased_or_not_owned) !== null && _t !== void 0 ? _t : "", onChange: setItemsLeasedOrNotOwned, label: "i.e. fuel tanks, antennas, satellite dishes, appliances, alarm & security systems, et or 'none'*" }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "List any fixtures that DO NOT convey:", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 1023,
                                                    },
                                                }, value: (_u = formData.fixtures_that_do_not_convey) !== null && _u !== void 0 ? _u : "", onChange: setFixturesThatDoNotConvey, helperText: "As a reminder: Permanent irrigation systems / rain barrels / address markers, Mailboxes, Mirrors attached to the walls / ceilings / cabinets / doors, ALL bathroom mirrors, Storage Shed / Utility building, Swimming pool / spa / hot tub, Solar electric and solar water heating systems, Sump - pumps / radon fans / crawl space ventilators / dehumidifiers, Surface-mounting brackets for television and speakers, Recess-mounted speakers, Mounted intercom system, Water supply equipment / including filters and water softeners, Window/Door blinds and shades, Curtain and drapery rods and brackets, Door and Window screens. Remember unless the contract states otherwise the fridge, washer, and dryer are considered personal property and do NOT convey to the new buyers." })))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Existing Mortgage Information" },
                                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Current liens(s) on property?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.current_liens_on_property, onChange: setCurrentLiensOnProperty }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "1st Mortgage Lender", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_v = formData.first_mortgage_lender) !== null && _v !== void 0 ? _v : "", onChange: setFirstMortgageLender }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "1st Mortgage Approx Balance", required: true },
                                            React.createElement(CoreNumberField, { fullWidth: true, variant: "standard", format: "$0,0.00", min: 0, max: 999999999, number: (_w = formData.first_mortgage_approx_balance) !== null && _w !== void 0 ? _w : null, onNumberChange: setFirstMortgageApproxBalance }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "2nd Mortgage Lender" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_x = formData.second_mortgage_lender) !== null && _x !== void 0 ? _x : "", onChange: setSecondMortgageLender }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "2nd Mortgage Approx Balance" },
                                            React.createElement(CoreNumberField, { fullWidth: true, variant: "standard", format: "$0,0.00", min: 0, max: 999999999, number: (_y = formData.second_mortgage_approx_balance) !== null && _y !== void 0 ? _y : null, onNumberChange: setSecondMortgageApproxBalance }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "HELOC Lender" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_z = formData.heloc_lender) !== null && _z !== void 0 ? _z : "", onChange: setHelocLender }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "HELOC Approx Balance" },
                                            React.createElement(CoreNumberField, { fullWidth: true, variant: "standard", format: "$0,0.00", min: 0, max: 999999999, number: (_0 = formData.heloc_approx_balance) !== null && _0 !== void 0 ? _0 : null, onNumberChange: setHelocApproxBalance }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "HELOC Lender Phone" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: { htmlInput: { maxLength: 15 } }, value: (_1 = formData.heloc_lender_phone) !== null && _1 !== void 0 ? _1 : "", onChange: setHelocLenderPhone })))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "HOA Information" },
                                React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "HOA?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.is_hoa, onChange: setIsHOA }))),
                                    formData.is_hoa && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid2, null,
                                            React.createElement(FormField, { title: "Homeowners Association Name" },
                                                React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                        htmlInput: {
                                                            maxLength: 127,
                                                        },
                                                    }, value: (_2 = formData.homeowners_association_name) !== null && _2 !== void 0 ? _2 : "", onChange: setHomeownersAssociationName }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(FormField, { title: "HOA Contact Email" },
                                                React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                        htmlInput: {
                                                            maxLength: 127,
                                                        },
                                                    }, value: (_3 = formData.hoa_contact_email) !== null && _3 !== void 0 ? _3 : "", onChange: setHOAContactEmail, helperText: formData.hoa_contact_email && !validateEmail(formData.hoa_contact_email)
                                                        ? "Must be a valid email address"
                                                        : "" }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(FormField, { title: "HOA Website" },
                                                React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                        htmlInput: {
                                                            maxLength: 1023,
                                                        },
                                                    }, value: (_4 = formData.hoa_website) !== null && _4 !== void 0 ? _4 : "", onChange: setHOAWebsite }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(FormField, { title: "HOA Amenities" },
                                                React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                        htmlInput: {
                                                            maxLength: 1023,
                                                        },
                                                    }, value: (_5 = formData.hoa_amenities) !== null && _5 !== void 0 ? _5 : "", onChange: setHOAAmenities }))),
                                        React.createElement(Grid2, null,
                                            React.createElement(FormField, { title: "Please upload a copy of your HOA Documents." },
                                                React.createElement(FileField, { value: (_8 = (_7 = (_6 = formData.attachments) === null || _6 === void 0 ? void 0 : _6.find((x) => x.file_type === "HOA Documents")) === null || _7 === void 0 ? void 0 : _7.file) !== null && _8 !== void 0 ? _8 : null, onChange: setAttachment("HOA Documents") })))))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Marketing" },
                                React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "List Price", required: true },
                                            React.createElement(CoreNumberField, { fullWidth: true, variant: "standard", format: "$0,0.00", min: 0, max: 999999999, number: (_9 = formData.list_price) !== null && _9 !== void 0 ? _9 : null, onNumberChange: setListPrice, helperText: "TBD until discussed with agent...this will be established and/or adjusted before going active." }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Buyer Agent Commission %", required: true },
                                            React.createElement(CoreNumberField, { fullWidth: true, variant: "standard", format: "0.00%", min: 0, max: 100, number: (_10 = formData.buyer_agent_commission_percent) !== null && _10 !== void 0 ? _10 : null, onNumberChange: setBuyerAgentCommissionPercent, helperText: "Enter the commission percentage you are willing to offer to the buyer's agent; this is negotiable and not fixed by law but we typically see around 2-3%." }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Active Date", required: true },
                                            React.createElement(CoreDateField, { fullWidth: true, variant: "standard", date: (_11 = formData.active_date) !== null && _11 !== void 0 ? _11 : null, onDateChange: setActiveDate, format: "MMMM D, YYYY", formatType: "dayjs", hasPicker: true, helperText: "Please allow at least 7 days from this form to active date to schedule photos and measurements for your property." }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Coming Soon Marketing", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.coming_soon_marketing, onChange: setComingSoonMarketing }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Coming Soon Date", required: !!formData.coming_soon_marketing },
                                            React.createElement(CoreDateField, { fullWidth: true, variant: "standard", date: (_12 = formData.coming_soon_date) !== null && _12 !== void 0 ? _12 : null, onDateChange: setComingSoonDate, format: "MMMM D, YYYY", formatType: "dayjs", hasPicker: true, helperText: "Please allow at least 3 days from the completion of this form." }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Photo Ready Date", required: true },
                                            React.createElement(CoreDateField, { fullWidth: true, variant: "standard", date: (_13 = formData.photo_ready_date) !== null && _13 !== void 0 ? _13 : null, onDateChange: setPhotoReadyDate, format: "MMMM D, YYYY", formatType: "dayjs", hasPicker: true, helperText: "Based on this date we will schedule the next available opportunity for photos and measurements." })))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Showing Appointments" },
                                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Is the house currently occupied?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.is_house_currently_occupied, onChange: setIsHouseCurrentlyOccupied }))),
                                    !formData.is_house_currently_occupied && (React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "In that case, can we do a go and show?" },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.can_do_go_and_show, onChange: setCanDoGoAndShow })))),
                                    formData.is_house_currently_occupied && (React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "In that case, how much of a notice will you need for new showings?" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_14 = formData.notice_needed_for_showings) !== null && _14 !== void 0 ? _14 : "", onChange: setNoticeNeededForShowings })))),
                                    formData.is_house_currently_occupied && (React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Who should we send the emails to for showing approvals" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_15 = formData.recipient_of_emails_for_showing_approvals) !== null && _15 !== void 0 ? _15 : "", onChange: setRecipientOfEmailsForShowingApprovals })))),
                                    formData.is_house_currently_occupied && (React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Who should we send texts to for showing approvals" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 127,
                                                    },
                                                }, value: (_16 = formData.recipient_of_texts_for_showing_approvals) !== null && _16 !== void 0 ? _16 : "", onChange: setRecipientOfTextsForShowingApprovals })))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Are there any pets that we should be concerned about?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.pets_to_be_concerned_about, onChange: setPetsToBeConcernedAbout })))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Utilities" },
                                React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "What type of water system does your house have?", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, value: (_17 = formData.water_system) !== null && _17 !== void 0 ? _17 : "", onChange: setWaterSystem },
                                                React.createElement(MenuItem, { value: "" }), waterSystemOptions === null || waterSystemOptions === void 0 ? void 0 :
                                                waterSystemOptions.map((option) => (React.createElement(MenuItem, { key: option, value: option }, option)))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "What type of sewer system does your house have?", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, value: (_18 = formData.sewer_system) !== null && _18 !== void 0 ? _18 : "", onChange: setSewerSystem },
                                                React.createElement(MenuItem, { value: "" }), sewerSystemOptions === null || sewerSystemOptions === void 0 ? void 0 :
                                                sewerSystemOptions.map((option) => (React.createElement(MenuItem, { key: option, value: option }, option))))))))),
                        React.createElement(Grid2, null,
                            React.createElement(FormSection, { title: "Other" },
                                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Does your house have a basement?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.has_basement, onChange: setHasBasement }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Does your house have a garage?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.has_garage, onChange: setHasGarage }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Who is responsible for road maintenance?", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, value: (_19 = formData.road_maintenance) !== null && _19 !== void 0 ? _19 : "", onChange: setRoadMaintenance },
                                                React.createElement(MenuItem, { value: "" }), roadMaintenanceOptions === null || roadMaintenanceOptions === void 0 ? void 0 :
                                                roadMaintenanceOptions.map((option) => (React.createElement(MenuItem, { key: option, value: option }, option)))))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Are there any known encroachments, easements, or encumberances on or adjacent to the property?", required: true },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 1023,
                                                    },
                                                }, value: (_20 = formData.known_encroachments_easments_or_encumberances) !== null && _20 !== void 0 ? _20 : "", onChange: setKnownEncroachmentsEasmentsOrEncumberances, helperText: "i.e. our fence or our neighbor\u2019s fence, shed, any utility or drainage easements on or adjacent to the property" }))),
                                    React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Do you have a copy of the previous title insurance policy?", required: true },
                                            React.createElement(YesNoSelect, { TextFieldProps: {
                                                    fullWidth: true,
                                                    variant: "standard",
                                                    size: "small",
                                                }, value: formData.have_copy_of_previous_title_insurance_policy, onChange: setHaveCopyOfPreviousTitleInsurancePolicy }))),
                                    formData.have_copy_of_previous_title_insurance_policy && (React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Please upload a copy of your previous title insurance." },
                                            React.createElement(FileField, { value: (_23 = (_22 = (_21 = formData.attachments) === null || _21 === void 0 ? void 0 : _21.find((x) => x.file_type === "Title Insurance")) === null || _22 === void 0 ? void 0 : _22.file) !== null && _23 !== void 0 ? _23 : null, onChange: setAttachment("Title Insurance") })))),
                                    !formData.have_copy_of_previous_title_insurance_policy && (React.createElement(Grid2, null,
                                        React.createElement(FormField, { title: "Previous Closing Attorney Contact Info" },
                                            React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                                    htmlInput: {
                                                        maxLength: 1023,
                                                    },
                                                }, value: (_24 = formData.previous_closing_attorney_contact_info) !== null && _24 !== void 0 ? _24 : "", onChange: setPreviousClosingAttorneyContactInfo }))))))))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        maxFileSizeExceeded && (React.createElement(Grid2, null,
            React.createElement(Alert, { severity: "warning" },
                React.createElement(Typography, null,
                    React.createElement("strong", null, "Warning:")),
                React.createElement(Typography, null, `Total file size (${Math.round((totalUploadedFileSize / 1024 / 1024) * 10) / 10}MB) exceeds 25MB. Questions for the Listing cannot be submitted.`)))),
        React.createElement(Grid2, null,
            React.createElement(Container, { maxWidth: "lg" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true },
                        React.createElement(Grid2, { style: { flex: 1 } }),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", variant: "contained", onClick: submit, disabled: !isValid || submitQFLAPI.isPending || maxFileSizeExceeded }, "Submit"))))))));
};
export default QuestionsForTheListingFormNEWCRM;
