var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Delete, Save } from "@mui/icons-material";
import { Box, Button, Divider, Grid2 } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useCreateSuggestedPriceReductionScheduleItem, useDeleteSuggestedPriceReductionScheduleItem, useGetSuggestedPriceReductionScheduleItem, useUpdateSuggestedPriceReductionScheduleItem, } from "@app/orval/api/price-reduction-schedule";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const defaultSubmission = {
    days_on_market: 0,
    reduction: 1,
};
const PriceReductionScheduleForm = (props) => {
    var _a, _b;
    const { uid } = props;
    const confirm = useConfirm();
    const navigate = useNavigate();
    const getItemApi = useGetSuggestedPriceReductionScheduleItem(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: !!uid } });
    const item = (_b = (_a = getItemApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [initialData, setInitialData] = React.useState(defaultSubmission);
    const [formData, setFormData] = React.useState(defaultSubmission);
    React.useEffect(() => {
        if (item) {
            const data = Object.assign({}, item);
            setInitialData(data);
            setFormData(data);
        }
        else {
            setInitialData(defaultSubmission);
            setFormData(defaultSubmission);
        }
    }, [item]);
    const isValid = React.useMemo(() => {
        return JSON.stringify(formData) !== JSON.stringify(initialData);
    }, [formData, initialData]);
    const createItemApi = useCreateSuggestedPriceReductionScheduleItem();
    const updateItemApi = useUpdateSuggestedPriceReductionScheduleItem();
    const deleteItemApi = useDeleteSuggestedPriceReductionScheduleItem();
    const isSaving = createItemApi.isPending || updateItemApi.isPending;
    const isDeleting = deleteItemApi.isPending;
    const goBack = React.useCallback(() => {
        navigate("/price-reduction-schedule");
    }, [navigate]);
    const save = React.useCallback(() => {
        const data = Object.assign({}, formData);
        if (uid) {
            updateItemApi.mutateAsync({ uid, data }).then(goBack).catch(enqueueErrorSnackbar);
        }
        else {
            createItemApi.mutateAsync({ data }).then(goBack).catch(enqueueErrorSnackbar);
        }
    }, [createItemApi, formData, goBack, uid, updateItemApi]);
    const deleteItem = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!uid) {
            return;
        }
        const result = yield confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS);
        if (result.confirmed) {
            deleteItemApi.mutateAsync({ uid }).then(goBack).catch(enqueueErrorSnackbar);
        }
    }), [confirm, deleteItemApi, goBack, uid]);
    const setDaysOnMarket = React.useCallback((dom) => {
        setFormData((prevData) => (Object.assign(Object.assign({}, prevData), { days_on_market: dom !== null && dom !== void 0 ? dom : 0 })));
    }, []);
    const setReduction = React.useCallback((reduction) => {
        setFormData((prevData) => (Object.assign(Object.assign({}, prevData), { reduction: reduction !== null && reduction !== void 0 ? reduction : 0 })));
    }, []);
    if (getItemApi.error) {
        return React.createElement(CoreError, { error: getItemApi.error });
    }
    if ((uid && !item) || isSaving || isDeleting) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, spacing: 2 },
        React.createElement(Grid2, { size: 12 },
            React.createElement(Box, { p: 4 },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, label: "Days On Market", format: "0,0", onNumberChange: setDaysOnMarket, number: formData.days_on_market })),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, label: "% of Original List Price", format: "0,0.000%", onNumberChange: setReduction, number: formData.reduction }))))),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Box, { p: 4 },
                React.createElement(Grid2, { container: true },
                    uid && (React.createElement(Grid2, { style: { textAlign: "right" } },
                        React.createElement(Button, { size: "small", color: "secondary", startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: deleteItem, tabIndex: -1 }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", variant: "contained", color: "primary", disabled: !isValid, startIcon: React.createElement(Save, { fontSize: "small" }), onClick: save, tabIndex: -1 }, "Save")))))));
};
export default PriceReductionScheduleForm;
