import * as React from "react";
import { Save } from "@mui/icons-material";
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { withDialog } from "@app/hoc/withDialog";
import API from "@app/util/api";
import AppManager from "@app/util/AppManager";
class MailScheduleSettings extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                settings: null,
                pipelines: null,
                error: null,
                isSaving: false,
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.mailScheduleSettings.get({
                    onSuccess: (result) => {
                        this.setState({
                            settings: result,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                });
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "setAutomaticEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.persist();
                this.setState((prevState) => ({
                    settings: Object.assign(Object.assign({}, prevState.settings), { is_automatic_enabled: e.target.checked }),
                }));
            }
        });
        Object.defineProperty(this, "setManualEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.persist();
                this.setState((prevState) => ({
                    settings: Object.assign(Object.assign({}, prevState.settings), { is_manual_enabled: e.target.checked }),
                }));
            }
        });
        Object.defineProperty(this, "setNotificationsEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                e.persist();
                this.setState((prevState) => ({
                    settings: Object.assign(Object.assign({}, prevState.settings), { is_notifications_enabled: e.target.checked }),
                }));
            }
        });
        Object.defineProperty(this, "setPipelineEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pipelineKey) => (e) => {
                e.persist();
                this.setState((prevState) => {
                    const settings = prevState.settings;
                    const pipelineIndex = settings.pipeline_settings.findIndex((p) => p.pipeline_key === pipelineKey);
                    return {
                        settings: Object.assign(Object.assign({}, settings), { pipeline_settings: [
                                ...settings.pipeline_settings.slice(0, pipelineIndex),
                                Object.assign(Object.assign({}, settings.pipeline_settings[pipelineIndex]), { is_enabled: e.target.checked }),
                                ...settings.pipeline_settings.slice(pipelineIndex + 1, settings.pipeline_settings.length),
                            ] }),
                    };
                });
            }
        });
        Object.defineProperty(this, "save", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isSaving: true,
                }, () => {
                    const data = this.state.settings;
                    console.log("saving", data);
                    API.mailScheduleSettings.save({
                        data,
                        onSuccess: () => {
                            enqueueSnackbar("Saved settings", { variant: "success" });
                            this.setState({
                                isSaving: false,
                            });
                        },
                        onError: (error) => {
                            this.props.showErrorDialog(error);
                            this.setState({
                                isSaving: false,
                            });
                        },
                    });
                });
            }
        });
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "Mail Schedule", subtitle: "Settings" };
        this.refresh();
    }
    componentWillUnmount() {
        AppManager.shared.pageTitle = {};
    }
    render() {
        const { error, isSaving, pipelines, settings } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!settings || !pipelines) {
            return React.createElement(CoreLoading, null);
        }
        return (React.createElement(Box, { p: 2 },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, container: true, spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: settings.is_automatic_enabled, color: "primary", onChange: this.setAutomaticEnabled }), label: "Automatic Mail Schedule Enabled" })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: settings.is_manual_enabled, color: "primary", onChange: this.setManualEnabled }), label: "Manual Mail Schedule Enabled" })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: settings.is_notifications_enabled, color: "primary", onChange: this.setNotificationsEnabled }), label: "Triggered Templates Enabled" }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, null, "Pipelines")),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { pl: 4 },
                        React.createElement(Grid, { container: true, spacing: 1 }, pipelines.map((pipeline) => {
                            var _a;
                            const setting = ((_a = settings.pipeline_settings) !== null && _a !== void 0 ? _a : []).find((p) => p.pipeline_key === pipeline.key);
                            return (React.createElement(Grid, { key: pipeline.key, item: true, xs: 12 },
                                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: setting ? setting.is_enabled : false, color: "primary", onChange: this.setPipelineEnabled(pipeline.key) }), label: pipeline.name })));
                        })))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Button, { color: "primary", variant: "contained", startIcon: React.createElement(Save, null), onClick: this.save, disabled: isSaving }, "Save")))));
    }
}
export default withDialog(MailScheduleSettings);
