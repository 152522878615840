var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { createContext, useContext, useState } from "react";
import axios from "axios";
import pickBy from "lodash/pickBy";
import { v4 as uuidv4 } from "uuid";
import { useFinishBuyerLeadFileUpload, useGetBuyerLeadFileUploadUrl, useGetSellerLeadFileUploadUrl, } from "@app/orval/api/buyer-leads";
import { useFinishContactFileUpload, useGetContactFileUploadUrl } from "@app/orval/api/contacts";
import { getFile } from "@app/orval/api/files";
import { useFinishRecruitFileUpload, useGetRecruitFileUploadUrl } from "@app/orval/api/recruits";
import { useFinishSellerLeadFileUpload } from "@app/orval/api/seller-leads";
import { useFinishUserFileUpload, useGetUserFileUploadUrl } from "@app/orval/api/users";
const FileUploadsContext = createContext({ uploadingFiles: {}, setUploadingFiles: () => { } });
export const FileUploadsProvider = (props) => {
    const [uploadingFiles, setUploadingFiles] = useState({});
    const value = React.useMemo(() => {
        return {
            uploadingFiles,
            setUploadingFiles,
        };
    }, [uploadingFiles, setUploadingFiles]);
    return React.createElement(FileUploadsContext.Provider, { value: value }, props.children);
};
const useFileUploads = () => {
    const { setUploadingFiles, uploadingFiles } = useContext(FileUploadsContext);
    const contactFileUploadApi = useGetContactFileUploadUrl();
    const contactFileCompletionApi = useFinishContactFileUpload();
    const recruitFileUploadApi = useGetRecruitFileUploadUrl();
    const recruitFileCompletionApi = useFinishRecruitFileUpload();
    const buyerLeadFileUploadApi = useGetBuyerLeadFileUploadUrl();
    const buyerLeadFileCompletionApi = useFinishBuyerLeadFileUpload();
    const sellerLeadFileUploadApi = useGetSellerLeadFileUploadUrl();
    const sellerLeadFileCompletionApi = useFinishSellerLeadFileUpload();
    const userFileUploadApi = useGetUserFileUploadUrl();
    const userFileCompletionApi = useFinishUserFileUpload();
    const getFileUploadApi = React.useCallback((params) => {
        if (params.buyerLeadUid) {
            return buyerLeadFileUploadApi;
        }
        if (params.sellerLeadUid) {
            return sellerLeadFileUploadApi;
        }
        if (params.recruitUid) {
            return recruitFileUploadApi;
        }
        if (params.contactUid) {
            return contactFileUploadApi;
        }
        if (params.userUid) {
            return userFileUploadApi;
        }
        throw new Error("Invalid params");
    }, [buyerLeadFileUploadApi, contactFileUploadApi, recruitFileUploadApi, sellerLeadFileUploadApi, userFileUploadApi]);
    const getFileCompletionApi = React.useCallback((params) => {
        if (params.buyerLeadUid) {
            return buyerLeadFileCompletionApi;
        }
        if (params.sellerLeadUid) {
            return sellerLeadFileCompletionApi;
        }
        if (params.recruitUid) {
            return recruitFileCompletionApi;
        }
        if (params.contactUid) {
            return contactFileCompletionApi;
        }
        if (params.userUid) {
            return userFileCompletionApi;
        }
        throw new Error("Invalid params");
    }, [
        buyerLeadFileCompletionApi,
        contactFileCompletionApi,
        recruitFileCompletionApi,
        sellerLeadFileCompletionApi,
        userFileCompletionApi,
    ]);
    const uploadFile = React.useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        const { buyerLeadUid, contactUid, file, recruitUid, sellerLeadUid, userUid } = params;
        const fileKey = uuidv4();
        setUploadingFiles((prev) => (Object.assign(Object.assign({}, prev), { [fileKey]: {
                uid: null,
                file,
                status: "pending",
                error: null,
            } })));
        const uploadApi = getFileUploadApi(params);
        const completionApi = getFileCompletionApi(params);
        const mimeType = file.type || "application/octet-stream";
        return new Promise((resolve, reject) => {
            uploadApi
                .mutateAsync(
            // @ts-expect-error
            pickBy({
                contactUid,
                recruitUid,
                buyerLeadUid,
                sellerLeadUid,
                profileUid: userUid,
                data: {
                    filename: file.name,
                    mime_type: mimeType,
                },
            }, (val) => val !== undefined))
                .then((result) => {
                setUploadingFiles((prev) => (Object.assign(Object.assign({}, prev), { [fileKey]: {
                        uid: result.data.uid,
                        file,
                        status: "uploading",
                        error: null,
                    } })));
                axios
                    .put(result.data.url, file, {
                    headers: { "Content-Type": mimeType },
                })
                    .then((_) => {
                    completionApi
                        .mutateAsync({ fileUid: result.data.uid })
                        .then((completionResult) => {
                        console.log(completionResult);
                        setUploadingFiles((prev) => (Object.assign(Object.assign({}, prev), { [fileKey]: {
                                uid: result.data.uid,
                                file,
                                status: "finished",
                                error: null,
                            } })));
                        getFile(result.data.uid)
                            .then((getFileResult) => {
                            resolve(getFileResult.data);
                        })
                            .catch((getFileError) => {
                            console.error(getFileError);
                            reject(getFileError);
                        });
                    })
                        .catch((completionError) => {
                        console.error(completionError);
                        setUploadingFiles((prev) => (Object.assign(Object.assign({}, prev), { [fileKey]: {
                                uid: result.data.uid,
                                file,
                                status: "error",
                                error: completionError,
                            } })));
                        reject(completionError);
                    });
                })
                    .catch((putError) => {
                    console.error(putError);
                    setUploadingFiles((prev) => (Object.assign(Object.assign({}, prev), { [fileKey]: {
                            uid: result.data.uid,
                            file,
                            status: "error",
                            error: putError,
                        } })));
                    reject(putError);
                });
            })
                .catch((uploadError) => {
                console.error(uploadError);
                setUploadingFiles((prev) => (Object.assign(Object.assign({}, prev), { [fileKey]: {
                        uid: null,
                        file,
                        status: "error",
                        error: uploadError,
                    } })));
                reject(uploadError);
            });
        });
    }), [getFileCompletionApi, getFileUploadApi, setUploadingFiles]);
    return {
        uploadingFiles,
        uploadFile,
    };
};
export default useFileUploads;
