/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List PSP Files
 */
export const listPspFiles = (params, options) => {
    return axios.get(`/api/psp_files`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListPspFilesQueryKey = (params) => {
    return [`/api/psp_files`, ...(params ? [params] : [])];
};
export const getListPspFilesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListPspFilesQueryKey(params);
    const queryFn = ({ signal }) => listPspFiles(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List PSP Files
 */
export function useListPspFiles(params, options) {
    const queryOptions = getListPspFilesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a File
 */
export const createPspFile = (fileUploadRequest, options) => {
    return axios.post(`/api/psp_files`, fileUploadRequest, options);
};
export const getCreatePspFileMutationOptions = (options) => {
    const mutationKey = ['createPspFile'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createPspFile(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a File
*/
export const useCreatePspFile = (options) => {
    const mutationOptions = getCreatePspFileMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a PSP File
*/
export const getPspFile = (uid, options) => {
    return axios.get(`/api/psp_files/${uid}`, options);
};
export const getGetPspFileQueryKey = (uid) => {
    return [`/api/psp_files/${uid}`];
};
export const getGetPspFileQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPspFileQueryKey(uid);
    const queryFn = ({ signal }) => getPspFile(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a PSP File
 */
export function useGetPspFile(uid, options) {
    const queryOptions = getGetPspFileQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Delete a File
 */
export const deletePspFile = (uid, options) => {
    return axios.delete(`/api/psp_files/${uid}`, options);
};
export const getDeletePspFileMutationOptions = (options) => {
    const mutationKey = ['deletePspFile'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deletePspFile(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a File
*/
export const useDeletePspFile = (options) => {
    const mutationOptions = getDeletePspFileMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count PSP Files
*/
export const countPspFiles = (params, options) => {
    return axios.get(`/api/psp_files/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountPspFilesQueryKey = (params) => {
    return [`/api/psp_files/count`, ...(params ? [params] : [])];
};
export const getCountPspFilesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountPspFilesQueryKey(params);
    const queryFn = ({ signal }) => countPspFiles(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count PSP Files
 */
export function useCountPspFiles(params, options) {
    const queryOptions = getCountPspFilesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download PSP Files
 */
export const downloadPspFiles = (params, options) => {
    return axios.get(`/api/psp_files/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadPspFilesQueryKey = (params) => {
    return [`/api/psp_files/download`, ...(params ? [params] : [])];
};
export const getDownloadPspFilesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadPspFilesQueryKey(params);
    const queryFn = ({ signal }) => downloadPspFiles(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download PSP Files
 */
export function useDownloadPspFiles(params, options) {
    const queryOptions = getDownloadPspFilesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Reorder PSP Files
 */
export const reorderPspFiles = (multipleUIDRequest, options) => {
    return axios.post(`/api/psp_files/reorder`, multipleUIDRequest, options);
};
export const getReorderPspFilesMutationOptions = (options) => {
    const mutationKey = ['reorderPspFiles'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderPspFiles(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder PSP Files
*/
export const useReorderPspFiles = (options) => {
    const mutationOptions = getReorderPspFilesMutationOptions(options);
    return useMutation(mutationOptions);
};
