import * as React from "react";
import { Info } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import DateCriteriaHelper from "./DateCriteriaHelper";
class DateCriteriaInfoButton extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                anchorEl: null,
            }
        });
        Object.defineProperty(this, "openDateCriteriaHelper", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                console.log(e, e.currentTarget);
                this.setState({
                    anchorEl: e.currentTarget,
                });
            }
        });
        Object.defineProperty(this, "closeDateCriteriaHelper", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                console.log("close");
                this.setState({
                    anchorEl: null,
                });
            }
        });
    }
    render() {
        const { criteria, fields } = this.props;
        const { anchorEl } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement(IconButton, { onClick: this.openDateCriteriaHelper, style: { padding: 0 } },
                React.createElement(Info, null)),
            React.createElement(CorePopover, { anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                }, open: !!anchorEl, anchorEl: anchorEl, onClose: this.closeDateCriteriaHelper },
                React.createElement(DateCriteriaHelper, { criteria: criteria, fields: fields }))));
    }
}
export default DateCriteriaInfoButton;
