import * as React from "react";
import { Send } from "@mui/icons-material";
import { Box, Container, Divider, Grid, MenuItem, TextField, Typography, Button } from "@mui/material";
import dayjs from "dayjs";
import CoreDateField from "@app/common/CoreDateField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useGetDataForRecruitingQfa, useSubmitRecruitingQfaNewcrm } from "@app/orval/api/recruiting-qfa";
import { validateEmail } from "@app/util/Utils";
const DEFAULT_SUBMISSION = {
    first_name: "",
    last_name: "",
    personal_email: "",
    phone_number: "",
    start_date: new Date().toISOString().split("T")[0],
    license_no: "",
    access_to_mls: null,
    regional_realtor_association: null,
};
const QuestionsForTheAffiliationFormNEWCRM = (props) => {
    var _a, _b;
    const { recruitUid } = props;
    const getRecruitDataApi = useGetDataForRecruitingQfa(recruitUid);
    const recruitData = (_b = (_a = getRecruitDataApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const submitQFAAPI = useSubmitRecruitingQfaNewcrm();
    const [formData, setFormData] = React.useState(DEFAULT_SUBMISSION);
    const [isComplete, setIsComplete] = React.useState(false);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (recruitData) {
            setFormData({
                first_name: (_a = recruitData.first_name) !== null && _a !== void 0 ? _a : "",
                last_name: (_b = recruitData.last_name) !== null && _b !== void 0 ? _b : "",
                personal_email: (_c = recruitData.personal_email) !== null && _c !== void 0 ? _c : "",
                phone_number: (_d = recruitData.phone_number) !== null && _d !== void 0 ? _d : "",
                start_date: (_e = recruitData.start_date) !== null && _e !== void 0 ? _e : new Date().toISOString().split("T")[0],
                license_no: (_f = recruitData.license_no) !== null && _f !== void 0 ? _f : "",
                access_to_mls: (_g = recruitData.access_to_mls) !== null && _g !== void 0 ? _g : null,
                regional_realtor_association: (_h = recruitData.regional_realtor_association) !== null && _h !== void 0 ? _h : null,
            });
        }
    }, [recruitData]);
    const isValid = React.useMemo(() => {
        return (formData.first_name.trim() &&
            formData.last_name.trim() &&
            formData.phone_number.trim() &&
            formData.personal_email.trim() &&
            !!validateEmail(formData.personal_email) &&
            (formData.access_to_mls === null || formData.access_to_mls.trim()) &&
            (formData.regional_realtor_association === null || formData.regional_realtor_association.trim()));
    }, [formData]);
    const setFirstName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { first_name: e.target.value })));
    }, []);
    const setLastName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { last_name: e.target.value })));
    }, []);
    const setPhoneNumber = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { phone_number: e.target.value })));
    }, []);
    const setPersonalEmail = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { personal_email: e.target.value })));
    }, []);
    const setLicenseNo = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { license_no: e.target.value })));
    }, []);
    const setStartDate = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { start_date: date !== null && date !== void 0 ? date : dayjs(new Date()).format("YYYY-MM-DD") })));
    }, []);
    const setCurrentlyHasAccessToMls = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { access_to_mls: e.target.value === "1" ? "" : null })));
    }, []);
    const setAccessToMls = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { access_to_mls: e.target.value })));
    }, []);
    const setCurrentlyAssociatedWithRegionalRealtorAssociation = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { regional_realtor_association: e.target.value === "1" ? "" : null })));
    }, []);
    const setRegionalRealtorAssociation = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { regional_realtor_association: e.target.value })));
    }, []);
    const submit = React.useCallback(() => {
        submitQFAAPI
            .mutateAsync({ recruitUid, data: formData })
            .then(() => {
            setIsComplete(true);
        })
            .catch(enqueueErrorSnackbar);
    }, [formData, recruitUid, submitQFAAPI]);
    if (isComplete) {
        return (React.createElement(Container, { maxWidth: "md" },
            React.createElement(Box, { p: 2 },
                React.createElement(Typography, { variant: "body1", style: { textAlign: "center" } }, "Thank you for your submission!"))));
    }
    if (getRecruitDataApi.error) {
        return React.createElement(CoreError, { error: getRecruitDataApi.error });
    }
    if (!recruitData) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Container, { maxWidth: "md" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Typography, { variant: "h4" }, "Questions for Affiliation")))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Container, { maxWidth: "md" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "First Name", fullWidth: true, value: formData.first_name, onChange: setFirstName, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                } })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "Last Name", fullWidth: true, value: formData.last_name, onChange: setLastName, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                } })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "Personal Email", fullWidth: true, value: formData.personal_email, onChange: setPersonalEmail, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                }, helperText: validateEmail(formData.personal_email) ? "" : "Must be a valid email address" })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "Phone Number", fullWidth: true, value: formData.phone_number, onChange: setPhoneNumber, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                } })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "License #", fullWidth: true, value: formData.license_no, onChange: setLicenseNo, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                } })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(CoreDateField, { size: "small", variant: "standard", label: "Start Date", fullWidth: true, format: "dddd, MMMM D, YYYY", formatType: "dayjs", date: formData.start_date, hasPicker: true, onDateChange: setStartDate })),
                        React.createElement(Grid, { item: true, xs: 12, sm: formData.access_to_mls != null ? 6 : 12 },
                            React.createElement(TextField, { select: true, size: "small", variant: "standard", label: "Do you currently have access to the MLS?", fullWidth: true, value: formData.access_to_mls != null ? "1" : "0", onChange: setCurrentlyHasAccessToMls },
                                React.createElement(MenuItem, { value: "1" }, "Yes"),
                                React.createElement(MenuItem, { value: "0" }, "No"))),
                        formData.access_to_mls != null && (React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "Which MLS?", fullWidth: true, value: formData.access_to_mls, onChange: setAccessToMls, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                } }))),
                        React.createElement(Grid, { item: true, xs: 12, sm: formData.regional_realtor_association != null ? 6 : 12 },
                            React.createElement(TextField, { select: true, size: "small", variant: "standard", label: "Are you currently associated with a Regional Realtor Association?", fullWidth: true, value: formData.regional_realtor_association != null ? "1" : "0", onChange: setCurrentlyAssociatedWithRegionalRealtorAssociation },
                                React.createElement(MenuItem, { value: "1" }, "Yes"),
                                React.createElement(MenuItem, { value: "0" }, "No"))),
                        formData.regional_realtor_association != null && (React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                            React.createElement(TextField, { size: "small", variant: "standard", label: "Which association?", fullWidth: true, value: formData.regional_realtor_association, onChange: setRegionalRealtorAssociation, slotProps: {
                                    htmlInput: {
                                        maxLength: 127,
                                    },
                                } }))))))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Container, { maxWidth: "md" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, style: { flex: 1 } }),
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { onClick: submit, startIcon: React.createElement(Send, null), disabled: !isValid || submitQFAAPI.isPending }, "Submit"))))))));
};
export default QuestionsForTheAffiliationFormNEWCRM;
