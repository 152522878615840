/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Marks the form as *submitted*. May only be called once per form. TODO: This documentation seems inaccurate and out of date.

_Authorization: none required_
 * @summary Submit a Questions for the Insurance form
 */
export const submitQfi = (uid, options) => {
    return axios.post(`/api/forms/questions_for_the_insurance/${uid}/submit_qfi`, undefined, options);
};
export const getSubmitQfiMutationOptions = (options) => {
    const mutationKey = ['submitQfi'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return submitQfi(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit a Questions for the Insurance form
*/
export const useSubmitQfi = (options) => {
    const mutationOptions = getSubmitQfiMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Includes submitted as well as un-submitted QFO's

_Authorization: none required_
* @summary List all Questions for the Offer submissions
*/
export const listQfos = (params, options) => {
    return axios.get(`/api/forms/questions_for_the_offer`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListQfosQueryKey = (params) => {
    return [`/api/forms/questions_for_the_offer`, ...(params ? [params] : [])];
};
export const getListQfosQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQfosQueryKey(params);
    const queryFn = ({ signal }) => listQfos(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all Questions for the Offer submissions
 */
export function useListQfos(params, options) {
    const queryOptions = getListQfosQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Includes submitted as well as un-submitted QFO's

_Authorization: none required_
 * @summary Create a new Questions for the Offer submission
 */
export const addQfo = (questionsForTheOfferCreateRequest, options) => {
    return axios.post(`/api/forms/questions_for_the_offer`, questionsForTheOfferCreateRequest, options);
};
export const getAddQfoMutationOptions = (options) => {
    const mutationKey = ['addQfo'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addQfo(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a new Questions for the Offer submission
*/
export const useAddQfo = (options) => {
    const mutationOptions = getAddQfoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Includes submitted as well as un-submitted QFO's

_Authorization: none required_
* @summary Get a Questions for the Offer submission
*/
export const getQfo = (uid, options) => {
    return axios.get(`/api/forms/questions_for_the_offer/${uid}`, options);
};
export const getGetQfoQueryKey = (uid) => {
    return [`/api/forms/questions_for_the_offer/${uid}`];
};
export const getGetQfoQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetQfoQueryKey(uid);
    const queryFn = ({ signal }) => getQfo(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a Questions for the Offer submission
 */
export function useGetQfo(uid, options) {
    const queryOptions = getGetQfoQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a Questions for the Offer submission
 */
export const updateQfo = (uid, questionsForTheOfferUpdateRequest, options) => {
    return axios.post(`/api/forms/questions_for_the_offer/${uid}`, questionsForTheOfferUpdateRequest, options);
};
export const getUpdateQfoMutationOptions = (options) => {
    const mutationKey = ['updateQfo'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateQfo(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a Questions for the Offer submission
*/
export const useUpdateQfo = (options) => {
    const mutationOptions = getUpdateQfoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Delete a Questions for the Offer submission
*/
export const destroyQfo = (uid, options) => {
    return axios.delete(`/api/forms/questions_for_the_offer/${uid}`, options);
};
export const getDestroyQfoMutationOptions = (options) => {
    const mutationKey = ['destroyQfo'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return destroyQfo(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a Questions for the Offer submission
*/
export const useDestroyQfo = (options) => {
    const mutationOptions = getDestroyQfoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Returns the ascii-encoded representation of a PDF quote for a submitted QFO

_Authorization: none required_
* @summary Get a quote for the submission
*/
export const getQfoPdf = (uid, options) => {
    return axios.get(`/api/forms/questions_for_the_offer/${uid}/pdf`, options);
};
export const getGetQfoPdfQueryKey = (uid) => {
    return [`/api/forms/questions_for_the_offer/${uid}/pdf`];
};
export const getGetQfoPdfQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetQfoPdfQueryKey(uid);
    const queryFn = ({ signal }) => getQfoPdf(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a quote for the submission
 */
export function useGetQfoPdf(uid, options) {
    const queryOptions = getGetQfoPdfQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Pull data from Bridge API
 */
export const populateBridgeDataForQfo = (uid, options) => {
    return axios.post(`/api/forms/questions_for_the_offer/${uid}/populate_bridge_data`, undefined, options);
};
export const getPopulateBridgeDataForQfoMutationOptions = (options) => {
    const mutationKey = ['populateBridgeDataForQfo'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return populateBridgeDataForQfo(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Pull data from Bridge API
*/
export const usePopulateBridgeDataForQfo = (options) => {
    const mutationOptions = getPopulateBridgeDataForQfoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Marks the form as *submitted*. May only be called once per form.

_Authorization: none required_
* @summary Submit a Questions for the Offer form
*/
export const submitQfo = (uid, options) => {
    return axios.post(`/api/forms/questions_for_the_offer/${uid}/submit`, undefined, options);
};
export const getSubmitQfoMutationOptions = (options) => {
    const mutationKey = ['submitQfo'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return submitQfo(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit a Questions for the Offer form
*/
export const useSubmitQfo = (options) => {
    const mutationOptions = getSubmitQfoMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Includes submitted as well as un-submitted QFO's

_Authorization: only logged in, registered users can use this endpoint._
* @summary Count Questions for the Offer submissions
*/
export const countQfos = (params, options) => {
    return axios.get(`/api/forms/questions_for_the_offer/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountQfosQueryKey = (params) => {
    return [`/api/forms/questions_for_the_offer/count`, ...(params ? [params] : [])];
};
export const getCountQfosQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountQfosQueryKey(params);
    const queryFn = ({ signal }) => countQfos(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count Questions for the Offer submissions
 */
export function useCountQfos(params, options) {
    const queryOptions = getCountQfosQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
