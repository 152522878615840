import * as React from "react";
import { Grid, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { useListViewPurposes } from "@app/orval/api/view-purposes";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewEditPurposeSelect = () => {
    var _a, _b, _c, _d;
    const context = useSavedViewEditorContext();
    const listPurposesApi = useListViewPurposes(REFETCH_CACHE_PARAMS);
    const purposes = (_b = (_a = listPurposesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const setPurpose = React.useCallback((e) => {
        context.setView((prev) => {
            return Object.assign(Object.assign({}, prev), { purpose: e.target.value || null });
        });
    }, [context]);
    return purposes ? (React.createElement(TextField, { select: true, size: "small", variant: "outlined", fullWidth: true, slotProps: {
            input: {
                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Purpose"),
            },
        }, value: (_c = context.view.purpose) !== null && _c !== void 0 ? _c : "", onChange: setPurpose },
        React.createElement(MenuItem, { value: "" }),
        purposes.map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))) : (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true },
            React.createElement(Typography, null, "Purpose")),
        React.createElement(Grid, { item: true },
            React.createElement(Typography, null, (_d = context.view.purpose) !== null && _d !== void 0 ? _d : "n/a"))));
};
export default SavedViewEditPurposeSelect;
