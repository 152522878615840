import * as React from "react";
import { Grid2, Typography } from "@mui/material";
import { fontColorContrastMemoized } from "@app/util/Utils";
const arrowSize = 20;
const paddingLeft = arrowSize + 10;
const GroupPanelItem = (props) => {
    const { group, index, numGroups, onClick, size } = props;
    const textColor = fontColorContrastMemoized(group.color);
    let clipPath;
    if (index === 0) {
        clipPath = `polygon(calc(100% - ${arrowSize}px) 0, 100% 50%, calc(100% - ${arrowSize}px) 100%, 0 100%, 0 0)`;
    }
    else if (index === numGroups - 1) {
        clipPath = `polygon(100% 0, 100% 100%, 0 100%, ${arrowSize}px 50%, 0 0)`;
    }
    else {
        clipPath = `polygon(calc(100% - ${arrowSize}px) 0, 100% 50%, calc(100% - ${arrowSize}px) 100%, 0 100%, ${arrowSize}px 50%, 0 0)`;
    }
    const style = {
        background: group.color,
        position: "absolute",
        left: `calc(${(index * 100) / numGroups}% - ${index === 0 ? 0 : paddingLeft}px)`,
        width: `calc(${100 / numGroups}% + ${index === 0 ? 0 : paddingLeft}px)`,
        height: "100%",
        paddingLeft: index === 0 ? undefined : `${paddingLeft}px`,
        zIndex: numGroups - index,
        clipPath,
        cursor: "pointer",
        boxShadow: group.isSelected ? "inset 0 0 50px white" : undefined,
        marginTop: 0,
        marginLeft: 0,
    };
    const handleClick = React.useCallback(() => {
        onClick(group.value);
    }, [onClick, group.value]);
    if (size === "small") {
        return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", style: style, className: group.isSelected ? "dashcrm-selected-group" : undefined, onClick: handleClick },
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold", color: textColor } }, group.count)),
            React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto", overflow: "hidden" } },
                React.createElement(Typography, { variant: "body2", style: {
                        color: fontColorContrastMemoized(group.color),
                        opacity: 0.5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    } }, group.name))));
    }
    return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", style: style, onClick: handleClick, className: group.isSelected ? "dashcrm-selected-group" : undefined },
        React.createElement(Grid2, null),
        React.createElement(Grid2, { container: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" }, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(Typography, { variant: "h6", style: { color: textColor, fontWeight: "Bold" } }, group.count)),
            React.createElement(Grid2, { style: { maxWidth: `calc(100% - ${arrowSize}px)`, overflow: "hidden" } },
                React.createElement(Typography, { variant: "body2", style: { color: textColor, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }, group.name)))));
};
const GroupPanel = (props) => {
    const { groups, height, onClick } = props;
    const handleClick = React.useCallback((groupValue) => {
        onClick(groupValue);
    }, [onClick]);
    return (React.createElement("div", { style: { height, position: "relative" } }, groups.map((group, i) => {
        var _a;
        return (React.createElement(GroupPanelItem, { key: group.value, group: group, size: height >= 56 ? "large" : "small", index: i, numGroups: groups.length, onClick: (_a = group.onClick) !== null && _a !== void 0 ? _a : handleClick }));
    })));
};
export default GroupPanel;
