import * as React from "react";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { Add, Alarm, Check, Clear, Delete, Done, Error, ErrorOutline, FileCopy, FilterList, Launch, PlaylistAddCheck, Refresh, SortByAlpha, Warning, FileDownload, ContentCut, BarChart, Info, } from "@mui/icons-material";
import { Box, Divider, Grid, MenuItem, IconButton, TableRow, TableCell, Tooltip, Typography, Avatar, ListItemIcon, Grid2, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { addDays } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import numeral from "numeral";
import streakIcon from "@app/assets/img/streak.png";
import DashPopover from "@app/common/CoreMoreMenu";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { getFlattenedCriteria, getMarkupForMergeFieldAttribute, sanitize, } from "@app/entrypoints/brokerage/features/mailschedule4/utils/utils";
import useLoading from "@app/hooks/useLoading";
import { useListFields } from "@app/orval/api/field-definitions";
import { getMailschedule4TemplateOpenRates } from "@app/orval/api/mail-schedule-4-reporting";
import { useDeleteMailschedule4Template, useDownloadMailschedule4Templates, useDuplicateMailschedule4Template, } from "@app/orval/api/mail-schedule-4-templates";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { useListStages } from "@app/orval/api/stages";
import { searchUsersBasic } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AppConfig, { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { DEFAULT_DATE_FORMAT, downloadBlob, getInitials } from "@app/util/Utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { CriteriaBadge, TriggerBadge, renderNameCell } from "../utils/table-cells";
import TypeTooltip from "../utils/TypeTooltip";
const thirtyOneDaysAgo = dayjs_().subtract(31, "days").toISOString();
const oneDayAgo = dayjs_().subtract(1, "days").toISOString();
console.log({ thirtyOneDaysAgo, oneDayAgo });
/**
 * Converts a time string (e.g. 05:00:00) to a friendlier
 * string for display (e.g. 5:00:00 am)
 *
 * @param value the time
 * @returns the time, as a friendly string
 */
const getLocalTimeStr = (value) => {
    if (value) {
        const valueAsDate = dayjs_(`1970-01-01 ${value}`);
        if (valueAsDate.isValid()) {
            return valueAsDate.format("h:mm A");
        }
    }
    return value;
};
const getTemplateReviewFrequencyError = (template, now) => {
    if (!template.review_frequency) {
        return null;
    }
    const lastUpdatedRequirement = {
        Annual: addDays(now, -365),
        Quarterly: addDays(now, -90),
    }[template.review_frequency];
    if (template.last_updated < dayjs_(lastUpdatedRequirement).format(DEFAULT_DATE_FORMAT)) {
        return `According to this template's review requirement, this template should have been updated by ${dayjs_(lastUpdatedRequirement).format("dddd, MMMM D, YYYY")}`;
    }
    return null;
};
const MailScheduleTemplatesTable = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { onSidebarToggle } = props;
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const listTemplatesApi = useListMailschedule4Templates({}, REFETCH_CACHE_PARAMS);
    const templates = (_b = (_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const getOpenRateApi = usePostQuery(getMailschedule4TemplateOpenRates, {
        sent_timestamp_from: thirtyOneDaysAgo,
        sent_timestamp_to: oneDayAgo,
    }, REFETCH_CACHE_PARAMS);
    const openRates = (_d = (_c = getOpenRateApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const openRatesByTemplateUid = React.useMemo(() => {
        const res = {};
        openRates === null || openRates === void 0 ? void 0 : openRates.forEach((openRate) => {
            res[openRate.template_uid] = openRate;
        });
        return res;
    }, [openRates]);
    console.log({ openRatesByTemplateUid });
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_f = (_e = listStagesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listUsersApi = usePostQuery(searchUsersBasic, { limit: 1000, disable_sort: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listUsersApi.data]);
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = (_h = (_g = listFieldsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const deleteTemplateApi = useDeleteMailschedule4Template();
    const duplicateTemplateApi = useDuplicateMailschedule4Template();
    const downloadTemplatesApi = useDownloadMailschedule4Templates({}, { query: { enabled: false } });
    const [searchParams] = useSearchParams();
    const pipelineFilter = searchParams.get("pipeline") && searchParams.get("pipeline") !== "any" ? searchParams.get("pipeline") : null;
    const templateTypeFilter = searchParams.get("template_type");
    // const statusFilter = searchParams.get("status");
    const categoryFilter = searchParams.get("category");
    const now = React.useRef(new Date()).current;
    const refresh = React.useCallback(() => {
        listTemplatesApi.refetch();
        listStagesApi.refetch();
        listUsersApi.refetch();
        listFieldsApi.refetch();
    }, [listFieldsApi, listStagesApi, listTemplatesApi, listUsersApi]);
    const deleteTemplate = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listTemplatesApi.queryKey, (oldData) => {
                    if (!oldData)
                        return oldData;
                    return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((template) => template.uid !== uid) });
                });
                deleteTemplateApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Template has been deleted", { variant: "success" });
                })
                    .catch(enqueueErrorSnackbar);
            }
        });
    }, [confirm, deleteTemplateApi, listTemplatesApi.queryKey, queryClient]);
    const duplicateTemplate = React.useCallback((uid) => () => {
        const loadingSnackbar = enqueueSnackbar("Duplicating template...", { variant: "info", persist: true });
        duplicateTemplateApi
            .mutateAsync({ uid })
            .then((result) => {
            navigate(`/ms4/templates/${result.data.uid}`);
            enqueueSnackbar("Template has been duplicated.", { variant: "success" });
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
        })
            .finally(() => {
            closeSnackbar(loadingSnackbar);
        });
    }, [duplicateTemplateApi, navigate]);
    const exportToCSV = React.useCallback(() => {
        downloadTemplatesApi
            .refetch()
            .then((result) => {
            downloadBlob(result.data.data.content, "mailschedule_templates", "text/csv");
        })
            .catch(enqueueErrorSnackbar);
    }, [downloadTemplatesApi]);
    const stageSortCompare = React.useCallback((order) => (obj1, obj2) => {
        const stageIndex1 = (stages !== null && stages !== void 0 ? stages : []).indexOf(obj1.data);
        const stageIndex2 = (stages !== null && stages !== void 0 ? stages : []).indexOf(obj2.data);
        return (stageIndex1 - stageIndex2) * (order === "asc" ? 1 : -1);
    }, [stages]);
    const rows = React.useMemo(() => (templates !== null && templates !== void 0 ? templates : [])
        .filter((template) => {
        if (pipelineFilter && template.entity_type !== pipelineFilter) {
            return false;
        }
        if (templateTypeFilter && template.template_type !== templateTypeFilter) {
            return false;
        }
        if (categoryFilter) {
            if (categoryFilter === "snippet" &&
                (template.triggers.length > 0 || template.criteria.map((x) => x.filters).flat().length > 0)) {
                return false;
            }
            if (categoryFilter === "mailschedule" &&
                template.triggers.length === 0 &&
                template.criteria.map((x) => x.filters).flat().length === 0) {
                return false;
            }
        }
        // if (statusFilter) {
        //   const isBroken = template.errors.length > 0 || !!template.criteria.find((c) => c.error);
        //   return statusFilter === "broken" ? isBroken : !isBroken;
        // }
        return true;
    })
        .map((template) => {
        var _a, _b;
        const pipelineStages = (stages !== null && stages !== void 0 ? stages : [])
            .filter((x) => x.type === template.entity_type)
            .sort((a, b) => {
            if (a.sort_order < b.sort_order)
                return -1;
            if (a.sort_order > b.sort_order)
                return 1;
            return 0;
        });
        const firstStageInPipeline = pipelineStages[0];
        const earliestAllowedStage = (_a = template.criteria
            .find((x) => x.parent_uid == null && x.is_match_all)) === null || _a === void 0 ? void 0 : _a.filters.filter((x) => {
            return x.stage_filter;
        }).map((x) => {
            const rhsStage = pipelineStages.find((stage) => { var _a, _b; return stage.uid === ((_b = (_a = x.stage_filter) === null || _a === void 0 ? void 0 : _a.rhs_stage) === null || _b === void 0 ? void 0 : _b.uid); });
            const rhsStages = pipelineStages.filter((stage) => { var _a; return ((_a = x.stage_filter.rhs_stages) !== null && _a !== void 0 ? _a : []).map((s) => s.uid).includes(stage.uid); });
            if (x.stage_filter.comparator === "is after" && rhsStage) {
                return pipelineStages.find((y) => y.sort_order > rhsStage.sort_order);
            }
            if (x.stage_filter.comparator === "is before" && rhsStage) {
                return pipelineStages[0];
            }
            if (x.stage_filter.comparator === "is after or equal to") {
                return rhsStage;
            }
            if (x.stage_filter.comparator === "is before or equal to") {
                return pipelineStages[0];
            }
            if (x.stage_filter.comparator === "is any of") {
                return rhsStages[0];
            }
            if (x.stage_filter.comparator === "is not any of") {
                return pipelineStages.find((y) => !rhsStages.includes(y));
            }
            return null;
        }).filter((x) => x).sort((a, b) => {
            if (a.sort_order > b.sort_order)
                return -1;
            if (a.sort_order < b.sort_order)
                return 1;
            return 0;
        })[0];
        return Object.assign(Object.assign({}, template), { 
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            stage: (_b = earliestAllowedStage === null || earliestAllowedStage === void 0 ? void 0 : earliestAllowedStage.name) !== null && _b !== void 0 ? _b : firstStageInPipeline === null || firstStageInPipeline === void 0 ? void 0 : firstStageInPipeline.name });
    })
        .map((template) => {
        const fieldsForEntity = (fields !== null && fields !== void 0 ? fields : []).filter((x) => {
            if (template.entity_type === "Buyer Contract") {
                return x.entity_type === "Buyer Contract" || x.entity_type === "Buyer Lead";
            }
            if (template.entity_type === "Listing") {
                return x.entity_type === "Listing" || x.entity_type === "Seller Lead";
            }
            return template.entity_type === x.entity_type;
        });
        let t = template;
        t = sanitize(t, fieldsForEntity);
        return t;
    })
        .map((template, index) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, template), template.email_template), template.email_draft_template), template.sms_template), template.sms_draft_template), template.calendar_template), template.slack_template), template.crm_notification_template), { criteria: getFlattenedCriteria(template.criteria), recipients: (_l = {
                Calendar: (_a = template.calendar_template) === null || _a === void 0 ? void 0 : _a.guests,
                Email: [(_b = template.email_template) === null || _b === void 0 ? void 0 : _b.to, (_c = template.email_template) === null || _c === void 0 ? void 0 : _c.cc, (_d = template.email_template) === null || _d === void 0 ? void 0 : _d.bcc]
                    .filter((x) => x)
                    .join(", "),
                "Email Draft": [
                    (_e = template.email_draft_template) === null || _e === void 0 ? void 0 : _e.to,
                    (_f = template.email_draft_template) === null || _f === void 0 ? void 0 : _f.cc,
                    (_g = template.email_draft_template) === null || _g === void 0 ? void 0 : _g.bcc,
                ]
                    .filter((x) => x)
                    .join(", "),
                SMS: (_h = template.sms_template) === null || _h === void 0 ? void 0 : _h.to_phone,
                "SMS Draft": (_j = template.sms_draft_template) === null || _j === void 0 ? void 0 : _j.to_phone,
                Slack: (_k = template.slack_template) === null || _k === void 0 ? void 0 : _k.channel_id,
                "Streak Comment": "",
            }[template.template_type]) !== null && _l !== void 0 ? _l : "", subject: (_q = {
                Calendar: (_m = template.calendar_template) === null || _m === void 0 ? void 0 : _m.title,
                Email: (_o = template.email_template) === null || _o === void 0 ? void 0 : _o.subject,
                "Email Draft": (_p = template.email_draft_template) === null || _p === void 0 ? void 0 : _p.subject,
                SMS: "",
                "SMS Draft": "",
                Slack: "",
                "Streak Comment": "",
            }[template.template_type]) !== null && _q !== void 0 ? _q : "", number: index + 1 }));
    }), [categoryFilter, fields, pipelineFilter, stages, templateTypeFilter, templates]);
    const columns = React.useMemo(() => [
        {
            name: "actions",
            label: " ",
            options: {
                searchable: false,
                sort: false,
                download: false,
                filter: false,
                print: false,
                viewColumns: false,
                customBodyRender: (_, tableMeta) => {
                    const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    const templateErrors = [
                    // ...template.errors,
                    // ...template.criteria
                    //   .filter((c) => c.error)
                    //   .reduce((errors: string[], c) => [...errors, `Criteria Error: ${c.error}`], [])
                    //   .flat(),
                    ];
                    return (React.createElement(Grid, { container: true, style: { width: "calc(100% + 4px)" } },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(DashPopover, null,
                                React.createElement(MenuItem, { component: RouterLink, to: `/ms4/templates/${template.uid}` },
                                    React.createElement(ListItemIcon, null,
                                        React.createElement(Launch, { fontSize: "small" })),
                                    "Open"),
                                React.createElement(MenuItem, { onClick: duplicateTemplate(template.uid) },
                                    React.createElement(ListItemIcon, null,
                                        React.createElement(FileCopy, { fontSize: "small" })),
                                    "Duplicate"),
                                React.createElement(MenuItem, { onClick: deleteTemplate(template.uid) },
                                    React.createElement(ListItemIcon, null,
                                        React.createElement(Delete, { fontSize: "small" })),
                                    "Delete"),
                                template.mailschedule3_templates.length > 0 && (React.createElement(React.Fragment, null,
                                    React.createElement(Divider, null),
                                    React.createElement(MenuItem, { component: RouterLink, to: `/mailschedule/templates/${template.mailschedule3_templates[0]}` },
                                        React.createElement(ListItemIcon, null,
                                            React.createElement("img", { src: streakIcon, alt: "Streak", height: 20 })),
                                        "Open Streak Mail Schedule Template"))))),
                        templateErrors.length > 0 && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: React.createElement(Grid, { container: true, spacing: 2 }, templateErrors.map((templateError) => (React.createElement(Grid, { key: templateError, item: true, xs: 12 },
                                    React.createElement(Typography, null, templateError))))) },
                                React.createElement(Warning, { style: { color: "orange" } }))))));
                },
                setCellProps: () => ({
                    style: {
                        width: "46px",
                        minWidth: "46px",
                        maxWidth: "46px",
                    },
                }),
            },
        },
        ...(!pipelineFilter
            ? [
                {
                    name: "entity_type",
                    label: "Pipeline",
                    customBodyRender: (value) => {
                        let url = null;
                        if (value === "Buyer Lead") {
                            url = "/buyer-leads";
                        }
                        else if (value === "Buyer Contract") {
                            url = "/buyer-contracts";
                        }
                        else if (value === "Seller Lead") {
                            url = "/seller-leads";
                        }
                        else if (value === "Listing") {
                            url = "/seller-contracts";
                        }
                        else if (value === "User") {
                            url = "/users";
                        }
                        else if (value === "Recruit") {
                            url = "/recruits";
                        }
                        if (url) {
                            return React.createElement(RouterLink, { to: url }, value);
                        }
                        return value;
                    },
                },
            ]
            : []),
        ...(pipelineFilter
            ? [
                {
                    name: "number",
                    label: "#",
                },
            ]
            : []),
        {
            name: "name",
            label: "Name",
            options: {
                customBodyRender: renderNameCell(rows),
            },
        },
        {
            name: "open_rate",
            label: "Rolling 30 Day Open Rate",
            options: {
                sort: false,
                customHeadLabelRender: () => {
                    return (React.createElement(Grid2, { container: true, direction: "row", spacing: 1, wrap: "nowrap", alignItems: "center" },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { style: { fontSize: "0.6875rem", fontWeight: "bold" } }, "Rolling 30 Day Open Rate")),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: React.createElement(Typography, null,
                                    "Open Rate is calculated by dividing the number of unique opens by the number of emails sent (unique opens/emails sent) between ",
                                    dayjs_(thirtyOneDaysAgo).format("M/D/YYYY h:mm A"),
                                    " and",
                                    " ",
                                    dayjs_(oneDayAgo).format("M/D/YYYY h:mm A"),
                                    ". Open rate is only tracked for Email and Email Draft templates that were processed by Mail Schedule. Snippets and other templates that were created outside of Mail Schedule (ex: Gmail, Mail Merge) aren't tracked here.") },
                                React.createElement(Info, { fontSize: "small" })))));
                },
                customBodyRender: (_, tableMeta) => {
                    const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    const openRate = openRatesByTemplateUid[template.uid];
                    if (openRate != null) {
                        if (openRate.num_sent > 0) {
                            return (React.createElement(Tooltip, { title: React.createElement(Grid2, { container: true, direction: "column", spacing: 1, wrap: "nowrap" },
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body2" },
                                            "Sent: ",
                                            openRate.num_sent)),
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body2" },
                                            "Opened: ",
                                            openRate.num_opened))), disableInteractive: true },
                                React.createElement(Typography, { variant: "body2" }, numeral((openRate.num_opened * 100) / openRate.num_sent).format("0.00%"))));
                        }
                        return (React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic", color: "#888" } }, "none sent"));
                    }
                    if (template.template_type === "Email" || template.template_type === "Email Draft") {
                        return (React.createElement(Tooltip, { title: React.createElement(Typography, null, "This template hasn't been tracked in the past 30 days"), disableInteractive: true },
                            React.createElement(Typography, { style: { color: "#888" } }, "n/a")));
                    }
                    return null;
                },
            },
        },
        {
            name: "assigned_agent",
            label: "Assigned Agent",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    var _a;
                    const user = (users !== null && users !== void 0 ? users : []).find((item) => {
                        return item.uid === value;
                    });
                    return user ? (React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { width: "30px" } },
                            React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "20px", width: "20px", fontSize: "0.7rem" } }, getInitials(user.first_name, user.last_name))),
                        React.createElement(Grid, { item: true, style: { width: "calc(100% - 30px)", marginTop: "auto", marginBottom: "auto" } }, [user.first_name, user.last_name].filter((o) => o).join(" ")))) : (value);
                },
                setCellProps: () => ({
                    style: {
                        width: "150px",
                        minWidth: "150px",
                    },
                }),
            },
        },
        {
            name: "triggers_and_criteria",
            label: "Criteria",
            options: {
                sort: false,
                customBodyRender: (_, tableMeta) => {
                    const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    if (template.triggers.length === 0 && template.criteria.length === 0) {
                        return (React.createElement(Tooltip, { title: React.createElement(TypeTooltip, { title: "This is a snippet", description: "This template doesn't run automatically, but you can process it manually any time" }) },
                            React.createElement(ContentCut, { fontSize: "small" })));
                    }
                    let icon;
                    if (template.triggers.length > 0) {
                        icon = React.createElement(Alarm, { fontSize: "small" });
                    }
                    else {
                        icon = React.createElement(PlaylistAddCheck, { fontSize: "small" });
                    }
                    const tooltipItems = [];
                    if (template.triggers.length > 0) {
                        tooltipItems.push(`${template.triggers.length} trigger${template.triggers.length > 1 ? "s" : ""}`);
                    }
                    if (template.criteria.length > 0) {
                        tooltipItems.push(`${template.criteria.length} criteria`);
                    }
                    if (template.triggers.length > 0 && template.criteria.length > 0) {
                        icon = (React.createElement(TriggerBadge, { color: "secondary", badgeContent: template.triggers.length, anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            } }, icon));
                    }
                    else if (template.triggers.length > 0) {
                        icon = (React.createElement(TriggerBadge, { color: "secondary", badgeContent: template.triggers.length, anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            } }, icon));
                    }
                    else if (template.criteria.length > 0) {
                        icon = (React.createElement(CriteriaBadge, { color: "primary", badgeContent: template.criteria.length, anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            } }, icon));
                    }
                    return (React.createElement(Tooltip, { title: React.createElement(Box, { p: 1 },
                            React.createElement(Grid, { container: true, spacing: 4 },
                                template.triggers.length > 0 && (React.createElement(Grid, { item: true, container: true, spacing: 1, xs: 12 },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(Typography, null, "Triggers")),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(Divider, { style: { background: "white" } })),
                                    template.triggers.map((trigger, indx) => (React.createElement(Grid, { key: `trigger${indx}__${trigger.description}`, container: true, item: true, xs: 12, spacing: 1 },
                                        React.createElement(Grid, { item: true },
                                            React.createElement(Alarm, { style: { color: AppConfig.themeColors.lightestblue }, fontSize: "small" })),
                                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                                            React.createElement(Typography, { variant: "body2" }, trigger.description))))))),
                                template.criteria.length > 0 && (React.createElement(Grid, { item: true, container: true, spacing: 1, xs: 12 },
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(Typography, null, "Criteria")),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(Divider, { style: { background: "white" } })),
                                    template.criteria.map((criterion, indx) => (React.createElement(Grid, { key: `criteria${indx}__${criterion.description}`, container: true, item: true, xs: 12, spacing: 1 },
                                        React.createElement(Grid, { item: true }, criterion.error ? (React.createElement(ErrorOutline, { style: { color: "#ff8e8a" }, fontSize: "small" })) : (React.createElement(Done, { style: { color: "#61ff71" }, fontSize: "small" }))),
                                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                                            React.createElement(Typography, { variant: "body2" }, criterion.description))))))))) },
                        React.createElement("span", null,
                            icon,
                            !!template.criteria.find((criterion) => criterion.error) && (React.createElement(ErrorOutline, { style: { color: "#ff8e8a" }, fontSize: "small" })))));
                },
            },
        },
        {
            name: "stage",
            label: "Stage",
            options: {
                sort: !!pipelineFilter,
                sortCompare: stageSortCompare,
            },
        },
        {
            name: "subject",
            label: "Subject or Title",
            options: {
                customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: { __html: getMarkupForMergeFieldAttribute(value) } })),
            },
        },
        {
            name: "recipients",
            label: "Recipients or Guests",
            options: {
                customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: { __html: getMarkupForMergeFieldAttribute(value) } })),
            },
        },
        {
            name: "earliest_process_time",
            label: "Earliest Process Time",
            options: {
                customBodyRender: (value) => getLocalTimeStr(value),
            },
        },
        {
            name: "latest_process_time",
            label: "Latest Process Time",
            options: {
                customBodyRender: (value) => getLocalTimeStr(value),
            },
        },
        {
            name: "is_active",
            label: "Enabled",
            options: {
                customBodyRender: (value) => value ? (React.createElement(Check, { fontSize: "small", style: { color: "green" } })) : (React.createElement(Clear, { fontSize: "small", style: { color: "red" } })),
            },
        },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const template = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    const err = getTemplateReviewFrequencyError(template, now);
                    const ts = value && dayjs_(value).isValid() ? dayjs_(value).format("dddd, MMMM D, YYYY h:mm A") : value;
                    if (err) {
                        return (React.createElement(Tooltip, { title: React.createElement(Typography, null, err) },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, style: { width: "20px", marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Error, { color: "error", fontSize: "small" })),
                                React.createElement(Grid, { item: true, style: { width: "calc(100% - 20px)", marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Typography, { variant: "body2" }, ts)))));
                    }
                    return ts;
                },
                setCellProps: () => ({
                    style: {
                        width: "200px",
                        minWidth: "200px",
                    },
                }),
            },
        },
        {
            name: "last_updated_by",
            label: "Last Updated By",
            options: {
                sort: false,
                customBodyRender: (value) => value ? (React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true, style: { width: "30px" } },
                        React.createElement(Avatar, { src: value.icon_url, style: { height: "20px", width: "20px", fontSize: "0.7rem" } }, [value.first_name, value.last_name]
                            .filter((o) => o)
                            .map((o) => o.substring(0, 1))
                            .join(""))),
                    React.createElement(Grid, { item: true, style: { width: "calc(100% - 30px)", marginTop: "auto", marginBottom: "auto" } }, [value.first_name, value.last_name].filter((o) => o).join(" ")))) : (value),
                setCellProps: () => ({
                    style: {
                        width: "150px",
                        minWidth: "150px",
                    },
                }),
            },
        },
    ].filter((obj) => !!obj), [deleteTemplate, duplicateTemplate, now, pipelineFilter, rows, stageSortCompare, users, openRatesByTemplateUid]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading templates",
                queryResult: listTemplatesApi,
            },
        ],
    });
    if (isLoading) {
        return loadingComponent;
    }
    if (!templates) {
        return null; // should never happen
    }
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        search: false,
        searchOpen: true,
        pagination: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        viewColumns: true,
        expandableRows: true,
        setRowProps: (_, dataIndex) => {
            const template = rows[dataIndex];
            if (!template.is_active) {
                return {
                    style: { opacity: 0.3 },
                };
            }
            return {};
        },
        customToolbar: () => (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: "Refresh" },
                React.createElement(IconButton, { onClick: refresh, disabled: listTemplatesApi.isFetching ||
                        listFieldsApi.isFetching ||
                        listStagesApi.isFetching ||
                        listUsersApi.isFetching },
                    React.createElement(Refresh, null))),
            React.createElement(Tooltip, { title: "Create new template" },
                React.createElement(IconButton, { component: RouterLink, to: "/ms4/templates/new" },
                    React.createElement(Add, null))),
            React.createElement(Tooltip, { title: "Export Templates to CSV" },
                React.createElement(IconButton, { disabled: downloadTemplatesApi.isFetching, onClick: exportToCSV },
                    React.createElement(FileDownload, null))),
            pipelineFilter && (React.createElement(Tooltip, { title: "Reorder" },
                React.createElement(IconButton, { component: RouterLink, to: `/ms4/templates/reorder?pipeline=${pipelineFilter}` },
                    React.createElement(SortByAlpha, null)))),
            React.createElement(Tooltip, { title: "Show filters" },
                React.createElement(IconButton, { onClick: onSidebarToggle },
                    React.createElement(FilterList, null))),
            React.createElement(Tooltip, { title: "Metrics" },
                React.createElement("span", null,
                    React.createElement(IconButton, { component: RouterLink, to: "/ms4/templates/metrics" },
                        React.createElement(BarChart, null)))))),
        renderExpandableRow: (_, rowMeta) => {
            var _a, _b;
            return (React.createElement(TableRow, null,
                React.createElement(TableCell, { colSpan: columns.length + 1, dangerouslySetInnerHTML: {
                        __html: (_b = (_a = rows[rowMeta.dataIndex].body) !== null && _a !== void 0 ? _a : rows[rowMeta.dataIndex].description) !== null && _b !== void 0 ? _b : "",
                    } })));
        },
    };
    return React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 0 });
};
export default MailScheduleTemplatesTable;
