import * as React from "react";
import { Place, Share, Description, CloudDownload, MenuOpen, Comment } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, MenuItem, Divider } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import numeral from "numeral";
import CoreLink from "@app/common/CoreLink";
import CoreLoading from "@app/common/CoreLoading";
import CoreMenu from "@app/common/CoreMenu";
import dayjs_ from "@app/common/dayjs";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import MAtrixShareDialog from "@app/entrypoints/brokerage/features/apps/matrix/MAtrixShareDialog";
import { TEST_MATRIX_UIDS } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import { getBridgeZestimate } from "@app/orval/api/bridge";
import { convertAsterisksToNull, DEFAULT_DATE_FORMAT, formatDateMemoized_Dayjs } from "@app/util/Utils";
import useReportApis from "./useReportApis";
import useChromeExtensionListener from "../useChromeExtensionListener";
const MAPPING = {
    pspComp: {
        Bedrooms: "Beds",
        "Closing Date": "Close Date",
        "Days On Market": "DOM",
        "Living Area Above Grade": "Total Living Area SqFt",
    },
    comp: {
        Price: "Sales Price",
        "Approximate Acres": "Convert Acres to SQFT",
        "Financial Concessions": "Concession",
        "Living Area Above Grade": "Living Area Above Grade SqFt",
        "Living Area Below Grade": "Living Area Below Grade SqFt",
        "Total Other Area SqFt": "Other Area Below Grade SqFt",
        Garage: "Garage Spaces",
        Porch: "Porch/Patio Type",
        "Course View": "Golf View", // This field is not used
        "Pool-In Ground Pool": "Pool Type",
        "Sold Price": "Sales Price",
        "Days On Market": "DOM",
        "Above Grade Finished Area": "Living Area Above Grade SqFt",
        "Below Grade Finished Area": "Living Area Below Grade SqFt",
        "Close Date": "Closing Date",
    },
    "CRS Property Report": {
        "Square Feet": ["Above Grade"],
        Taxes: ["Yearly Tax Bill"],
        "Year Built": ["Tax Record Year Built"],
        "Total Tax Appraisal": ["Tax Value"],
        "Last Sold Date": ["Year Purchased", "Tax Record Year Sold"],
        "Last Sold Price": ["Sale Price", "Tax Record Purchase Price"],
    },
    Property: {
        "Public Zestimate": "Zestimate",
        "HOA Dues": "HOA Dues/M",
        "Home type": "Home Type",
        "Year purchased": "Year Purchased",
        "Age restricted": "Age Restricted", // This field is not used
        "Garage Parking Spots": "Garage Spots",
        "Story Count": "Stories",
        "Finished square feet": "Finished SqFt",
        "Basement square feet": "Basement Finished SqFt",
        "Additional features": "Additional Features", // This field is not used
        "Hot tub or spa": "Hot Tub/Spa", // This field is not used
        "In-ground pool": "Pool Type",
        "Septic tank": "Sewer",
        "Well water": "Water",
        "Building Sq Ft": "Tax Record SqFt",
        "Year Built": "Tax Record Year Built",
        "Settle Date": "Tax Record Year Sold",
        "Sale Price": "Tax Record Purchase Price",
        Status: "Currently Active in MLS", // This field is not used
        "MLS #": "Previous or Active MLS Number", // This field is not used
        "List Price": [
            "Previous or Active Asking Price", // This field is not used
            "Current List Price",
        ],
        "Closing Date": "Last MLS Status Date", // This field is not used
        "Living Area Above Grade": [
            "Subject Property MLS Above Grade SqFt", // This field is not used
            "Finished SqFt",
        ],
        "Living Area Below Grade": [
            "Subject Property MLS Below Grade SqFt", // This field is not used
            "Basement Finished SqFt",
        ],
        Fireplace: "Number of Fireplaces",
        "Total HOA Dues": "HOA Dues/M",
        "Fireplaces Total": "Number of Fireplaces",
        "Above Grade Finished Area": [
            "Subject Property MLS Above Grade SqFt", // This field is not used
            "Finished SqFt",
        ],
        "Below Grade Finished Area": [
            "Subject Property MLS Below Grade SqFt", // This field is not used
            "Basement Finished SqFt",
        ],
        "Close Date": "Closing Date",
    },
    "Property Dropdown": {
        Y: "Yes",
        N: "No",
        "Single family": "Single Family",
        "Town house": "Town House",
        "City Water": "City/County",
        "County Water": "City/County",
        "City Sewer": "City/County",
        "County Sewer": "City/County",
        "Septic Tank": "Septic",
    },
    "Realist Report": {
        "Building Sq Ft": ["Tax Record SqFt", "Above Grade"],
        "Year Built": ["Tax Record Year Built"],
        "MLS Close Date": ["Tax Record Year Sold", "Year Purchased"],
        "Sale Price": ["Tax Record Purchase Price", "Sale Price"],
        "RealAVM™": "Realist Real AVM", // This field is not used
        "Assessed Value - Total": "Tax Value",
        "Yearly Tax Bill": "Yearly Tax Bill",
    },
    "Canopy MLS": {
        "Year Built": "Tax Record Year Built",
        "Close Date": ["Tax Record Close Date", "Year Purchased"],
        "Closed Price": "Close Price",
        "Above Grade SF": "Finished SqFt",
        Bsmt: "Basement Finished SqFt",
        "Closed Dt": "Closing Date",
        "List Price": "Current List Price",
    },
};
const MENU_ITEM_ICON = React.createElement(Description, null);
const COMPS_FIELDS = [
    "Address",
    "Sale Price",
    "Closing Date",
    "Living Area Above Grade SqFt",
    "Living Area Below Grade SqFt",
    "Full Baths",
    "Half Baths",
    "Garage Spots",
    "Number of Fireplaces",
];
const getPasteDataWithMapping = (clipboardData, fieldMapping, dropdownMapping) => {
    const data = {};
    for (const [key, value] of Object.entries(clipboardData)) {
        const fieldName = (fieldMapping === null || fieldMapping === void 0 ? void 0 : fieldMapping[key]) ? fieldMapping[key] : key;
        const fieldVal = (dropdownMapping === null || dropdownMapping === void 0 ? void 0 : dropdownMapping[value]) ? dropdownMapping[value] : value;
        // console.log("name:", fieldName, "val:", fieldVal);
        if (Array.isArray(fieldName)) {
            for (const dashField of fieldName) {
                if (["Tax Record Year Sold", "Year Purchased"].includes(dashField)) {
                    // only want year
                    data[dashField] = dayjs_(fieldVal).isValid() ? dayjs_(fieldVal).format("YYYY") : null;
                }
                else {
                    data[dashField] = fieldVal;
                }
            }
        }
        else if (["Tax Record Year Sold", "Year Purchased"].includes(fieldName)) {
            // only want year
            data[fieldName] = dayjs_(fieldVal).isValid() ? dayjs_(fieldVal).format("YYYY") : null;
        }
        else if (fieldName === "Currently Active in MLS" && fieldVal === "Closed") {
            data[fieldName] = "Sold";
        }
        else {
            data[fieldName] = fieldVal;
        }
    }
    return data;
};
/**
 * Shows snackbar with instructions for user
 */
const showChromExtensionInstructions = () => {
    enqueueSnackbar("Paste (ctrl+v or cmd+v) here to fill in the information.", { variant: "info" });
};
const showChromeExtensionError = (error) => {
    enqueueSnackbar(`${error}.\nInvalid clipboard data. Be sure to use the Chrome Extension first.`, {
        variant: "error",
    });
};
const Toolbar = (props) => {
    const [menuItems, setMenuItems] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);
    const chromeExtensionListener = useChromeExtensionListener();
    const child = React.createRef();
    const { bulkUpdateCMAComps, bulkUpdateFields, bulkUpdatePSPComps, cmaComps, matrix, matrixSchema, propertyAddress, pspComps, pspReportTemplates, refreshPSP, setSidebar, submitTrainingMAtrix, trainingReferenceUid, trainingSubmissions, uid, } = props;
    const { generateAgedPropertyReport, generateBuyerPspReport, generateCashToClose, generateInvestorReport, generateListingPresentation, generateListingPspReport, generateMultipleOffers, generateNetSheet, generatePreListingPresentation, generateTemplatedPspReport, } = useReportApis(uid);
    const onMenuOpen = React.useCallback((items) => (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setMenuItems(items);
    }, [setMenuItems, setAnchorEl]);
    const onMenuClose = React.useCallback((e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setMenuItems(null);
    }, [setAnchorEl, setMenuItems]);
    const onShareDialogOpen = React.useCallback(() => {
        var _a;
        // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
        (_a = child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
        setIsShareDialogOpen(true);
    }, [setIsShareDialogOpen, child]);
    const onShareComplete = React.useCallback((result) => {
        refreshPSP(result);
        setIsShareDialogOpen(false);
    }, [refreshPSP]);
    const onShareCancel = React.useCallback(() => {
        var _a;
        // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
        (_a = child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
        setIsShareDialogOpen(false);
    }, [setIsShareDialogOpen, child]);
    const autopopulateFromZillow = React.useCallback(() => {
        const address = propertyAddress !== null && propertyAddress !== void 0 ? propertyAddress : "";
        if (address.trim() !== "") {
            const loadingSnackbar = enqueueSnackbar("Getting data from bridge api...", {
                variant: "info",
                persist: true,
            });
            getBridgeZestimate({
                address,
            })
                .then((result) => {
                bulkUpdateFields(result.data.fields);
            })
                .catch((error) => {
                console.error(error);
                enqueueSnackbar("Error getting data from bridge api", { variant: "error" });
            })
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
        else {
            enqueueSnackbar("`Property Address` is missing or blank.", { variant: "warning" });
        }
    }, [propertyAddress, bulkUpdateFields]);
    const refreshMAtrixFields = React.useCallback((data) => {
        if (Object.keys(data).length > 0) {
            const fields = {};
            // console.log("test:", data);
            for (const fieldName of Object.keys(data)) {
                // format values to match MAtrix expectations
                if (Object.keys(matrixSchema).includes(fieldName)) {
                    if (matrixSchema[fieldName].field_type === "number") {
                        // reformats strings ('$300,000') to numbers (300000)
                        fields[fieldName] = Math.round(numeral(data[fieldName]).value()); // backend seems to expect integers
                    }
                    else if (matrixSchema[fieldName].field_type === "date" ||
                        matrixSchema[fieldName].field_type === "datetime") {
                        // reformats date
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        fields[fieldName] = dayjs_(data[fieldName])
                            ? formatDateMemoized_Dayjs(data[fieldName], DEFAULT_DATE_FORMAT)
                            : null;
                    }
                    else {
                        fields[fieldName] = data[fieldName];
                    }
                }
            }
            const updatedFields = convertAsterisksToNull(fields);
            // bulkUpdateFields(updatedFields);
            console.log("update fields:", updatedFields);
            if (Object.keys(updatedFields).length > 0) {
                bulkUpdateFields(updatedFields);
            }
            else {
                enqueueSnackbar("Field names did not match MAtrix.", { variant: "warning" });
            }
        }
        else {
            enqueueSnackbar("Found no fields to update.", { variant: "warning" });
        }
    }, [bulkUpdateFields, matrixSchema]);
    const pasteCRSData = React.useCallback((e) => {
        document.removeEventListener("paste", pasteCRSData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            showChromeExtensionError(error);
        }
        const data = getPasteDataWithMapping(clipText, MAPPING["CRS Property Report"]);
        refreshMAtrixFields(data);
    }, [refreshMAtrixFields]);
    const populateFromCRS = React.useCallback(() => {
        chromeExtensionListener(pasteCRSData);
    }, [chromeExtensionListener, pasteCRSData]);
    const pasteRealistReportData = React.useCallback((e) => {
        document.removeEventListener("paste", pasteRealistReportData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            showChromeExtensionError(error);
        }
        if (clipText["Building Sq Ft"] && clipText["Building Sq Ft"].split(" ").length === 4) {
            clipText["Building Sq Ft"] = clipText["Building Sq Ft"].split(" ")[1];
        }
        const data = getPasteDataWithMapping(clipText, MAPPING["Realist Report"]);
        refreshMAtrixFields(data);
    }, [refreshMAtrixFields]);
    const populateFromRealistReport = React.useCallback(() => {
        showChromExtensionInstructions();
        document.removeEventListener("paste", pasteRealistReportData);
        document.addEventListener("paste", pasteRealistReportData);
    }, [pasteRealistReportData]);
    const pasteMLSData = React.useCallback((e) => {
        document.removeEventListener("paste", pasteMLSData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            showChromeExtensionError(error);
        }
        const data = getPasteDataWithMapping(clipText, MAPPING["Property"], MAPPING["Property Dropdown"]);
        refreshMAtrixFields(data);
    }, [refreshMAtrixFields]);
    const populateFromMLS = React.useCallback(() => {
        chromeExtensionListener(pasteMLSData);
    }, [chromeExtensionListener, pasteMLSData]);
    const pasteCanopyMLSData = React.useCallback((e) => {
        document.removeEventListener("paste", pasteCanopyMLSData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            showChromeExtensionError(error);
        }
        const data = getPasteDataWithMapping(clipText, MAPPING["Canopy MLS"]);
        console.log("got data:", data);
        if (Object.keys(data).includes("Assoc Fee")) {
            // split Association fee into value and time period
            // ex: '$350/Annually' -> '$350' and 'Annually'
            const hoaData = data["Assoc Fee"].split("/");
            data["HOA Dues/M"] = hoaData[0];
            if (hoaData.length > 1) {
                data["Subdivision HOA Time Period"] = hoaData[1];
            }
        }
        refreshMAtrixFields(data);
    }, [refreshMAtrixFields]);
    const populateFromCanopyMLS = React.useCallback(() => {
        chromeExtensionListener(pasteCanopyMLSData);
    }, [chromeExtensionListener, pasteCanopyMLSData]);
    const pasteGenericReportData = React.useCallback((e) => {
        document.removeEventListener("paste", pasteGenericReportData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            showChromeExtensionError(error);
        }
        const data = getPasteDataWithMapping(clipText);
        refreshMAtrixFields(data);
    }, [refreshMAtrixFields]);
    const populateFromGeneric = React.useCallback(() => {
        chromeExtensionListener(pasteGenericReportData);
    }, [chromeExtensionListener, pasteGenericReportData]);
    const pastePSPCompData = React.useCallback((e) => {
        document.removeEventListener("paste", pastePSPCompData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        // let clipText = e.clipboardData.getData("Text");
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            enqueueSnackbar(`${error}.\nInvalid clipboard data. Be sure to use the Chrome Extension first.`, {
                variant: "error",
            });
        }
        console.log("pasting....", clipText);
        const addressArray = clipText["Address"];
        if (addressArray && Array.isArray(addressArray)) {
            const loadingSnackbar = enqueueSnackbar("Searching through addresses...", {
                variant: "info",
                persist: true,
            });
            let pspCompsFieldsUpdated = 0;
            const pspMapping = MAPPING.pspComp;
            const pspCompFields = [
                "Address",
                "Total Living Area SqFt",
                "Beds",
                "Full Baths",
                "DOM",
                "Sold Price",
                "List Price",
                "Close Date",
                "Scheduled Close Date",
                "comp_type",
            ];
            const pspAddresses = pspComps
                .filter((comp) => !!comp.fields["Address"])
                .map((comp) => comp.fields["Address"].toLowerCase().trim());
            const compsToUpdate = [];
            const compsToCreate = [];
            // console.log("begin searching through addresses...", addressArray);
            addressArray.forEach((address, indx) => {
                var _a;
                console.log("Searching through addresses...", address.toLowerCase().trim());
                const compIndex = pspAddresses.indexOf(address.toLowerCase().trim());
                const isMatch = pspAddresses.find((addr) => addr === address.toLowerCase().trim());
                const data = {
                    fields: {},
                };
                for (const [key, _] of Object.entries(clipText)) {
                    let field = key;
                    let value = (_a = clipText[key][indx]) !== null && _a !== void 0 ? _a : null;
                    if (value === "") {
                        value = null;
                    }
                    // use mapped version if it exists
                    if (key in pspMapping) {
                        field = pspMapping[key];
                    }
                    if (key === "Status" && value && !isMatch) {
                        // if the field is not a match, get comp_type from Status
                        if (value.toLowerCase().trim() === "contingent") {
                            data["comp_type"] = "Pending";
                        }
                        else {
                            const compType = value.toUpperCase()[0] + value.toLowerCase().slice(1);
                            data["comp_type"] = compType;
                        }
                    }
                    if (field === "Total Living Area SqFt") {
                        value = String(numeral(clipText["Living Area Above Grade"][indx]).value() +
                            numeral(clipText["Living Area Below Grade"][indx]).value());
                    }
                    if (value !== null) {
                        if (["sold price", "list price"].includes(field.toLowerCase())) {
                            value = numeral(value).value();
                        }
                        if (["close date", "scheduled close date"].includes(field.toLowerCase())) {
                            value = formatDateMemoized_Dayjs(value, DEFAULT_DATE_FORMAT);
                        }
                    }
                    if (pspCompFields.includes(field)) {
                        // add in data for each field
                        if (value !== data.fields[field]) {
                            data.fields[field] = value;
                            pspCompsFieldsUpdated += 1;
                        }
                    }
                }
                data.fields = convertAsterisksToNull(data.fields);
                if (isMatch) {
                    compsToUpdate.push({
                        compUid: pspComps[compIndex].uid,
                        params: { fields: data.fields },
                    });
                }
                else {
                    compsToCreate.push({
                        matrix_uid: uid,
                        fields: data.fields,
                        comp_type: data["comp_type"],
                    });
                }
                console.log("Address matched: ", address, data, pspCompsFieldsUpdated);
            });
            closeSnackbar(loadingSnackbar);
            console.log("Needs Update:", compsToUpdate, "Needs Create:", compsToCreate);
            bulkUpdatePSPComps(compsToUpdate, compsToCreate);
        }
        else {
            console.error("Could not find address fields with clipdata: ", clipText);
            enqueueSnackbar("Could not find Address fields.", { variant: "error" });
        }
    }, [bulkUpdatePSPComps, pspComps, uid]);
    const populatePSPCompData = React.useCallback(() => {
        chromeExtensionListener(pastePSPCompData);
    }, [chromeExtensionListener, pastePSPCompData]);
    const pasteCompData = React.useCallback((e) => {
        document.removeEventListener("paste", pasteCompData);
        const { clipboardData } = e;
        if (!clipboardData) {
            return;
        }
        let clipText = {};
        try {
            clipText = JSON.parse(clipboardData.getData("Text"));
        }
        catch (error) {
            enqueueSnackbar(`${error}.\nInvalid clipboard data. Be sure to use the Chrome Extension first.`, {
                variant: "error",
            });
        }
        console.log("pasting....", clipText);
        const addressArray = clipText["Address"];
        if (addressArray && Array.isArray(addressArray)) {
            const loadingSnackbar = enqueueSnackbar("Searching through addresses...", {
                variant: "info",
                persist: true,
            });
            let compsFieldsUpdated = 0;
            const compsMapping = MAPPING.comp;
            const compAddresses = cmaComps
                .filter((comp) => !!comp.fields["Address"])
                .map((comp) => comp.fields["Address"].toLowerCase().trim());
            const compsToUpdate = [];
            const compsToCreate = [];
            addressArray.forEach((address, indx) => {
                var _a;
                console.log("Searching through addresses...", address.toLowerCase().trim());
                const compIndex = compAddresses.indexOf(address.toLowerCase().trim());
                const isMatch = compAddresses.find((addr) => addr === address.toLowerCase().trim());
                const data = {
                    fields: {},
                };
                for (const [key, _] of Object.entries(clipText)) {
                    let field = key;
                    let value = (_a = clipText[key][indx]) !== null && _a !== void 0 ? _a : null;
                    if (value === "") {
                        value = null;
                    }
                    // use mapped version if it exists
                    if (key in compsMapping) {
                        field = compsMapping[key];
                    }
                    if (field === "Total Living Area SqFt") {
                        value = String(numeral(clipText["Living Area Above Grade"][indx]).value() +
                            numeral(clipText["Living Area Below Grade"][indx]).value());
                    }
                    if (value !== null) {
                        if ([
                            "sale price",
                            "living area above grade sqft",
                            "living area below grade sqft",
                            "full baths",
                            "half baths",
                            "garage spots",
                            "number of fireplaces",
                        ].includes(field.toLowerCase())) {
                            value = numeral(value).value();
                        }
                        if (["closing date"].includes(field.toLowerCase())) {
                            value = formatDateMemoized_Dayjs(value, DEFAULT_DATE_FORMAT);
                        }
                    }
                    // add in data for each field
                    if (COMPS_FIELDS.includes(field)) {
                        if (value !== data.fields[field]) {
                            data.fields[field] = value;
                            compsFieldsUpdated += 1;
                        }
                    }
                }
                data.fields = convertAsterisksToNull(data.fields);
                if (isMatch) {
                    compsToUpdate.push({
                        compUid: cmaComps[compIndex].uid,
                        params: { fields: data.fields },
                    });
                }
                else {
                    compsToCreate.push({
                        matrix_uid: uid,
                        fields: data.fields,
                    });
                }
                console.log("Address matched: ", address, data, compsFieldsUpdated);
            });
            closeSnackbar(loadingSnackbar);
            console.log("Needs Update:", compsToUpdate, "Needs Create:", compsToCreate);
            bulkUpdateCMAComps(compsToUpdate, compsToCreate);
        }
        else {
            console.error("Could not find address fields with clipdata: ", clipText);
            enqueueErrorSnackbar("Could not find Address fields.");
        }
    }, [bulkUpdateCMAComps, cmaComps, uid]);
    const populateCompData = React.useCallback(() => {
        chromeExtensionListener(pasteCompData);
    }, [chromeExtensionListener, pasteCompData]);
    const onSidebarChange = (sidebar) => () => {
        setSidebar(sidebar);
    };
    const templateMenuItems = React.useMemo(() => {
        const items = [];
        (pspReportTemplates !== null && pspReportTemplates !== void 0 ? pspReportTemplates : []).forEach((template) => {
            items.push({
                label: `${template.name} Report`,
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateTemplatedPspReport(template.uid, true);
                },
            });
            if (template.name === "LISTING") {
                items.push({
                    label: "LISTING Report (without Listing Presentation)",
                    icon: MENU_ITEM_ICON,
                    onClick: () => {
                        generateTemplatedPspReport(template.uid, false);
                    },
                });
            }
        });
        return items;
    }, [generateTemplatedPspReport, pspReportTemplates]);
    const onBuyerPSPReportClick = React.useCallback((format, useV2Template) => () => {
        generateBuyerPspReport(format, useV2Template);
    }, [generateBuyerPspReport]);
    const onListingPSPReportClick = React.useCallback((format, zavvieOutput) => () => {
        generateListingPspReport(format, zavvieOutput);
    }, [generateListingPspReport]);
    const onListingPresentationClick = React.useCallback(() => () => {
        generateListingPresentation();
    }, [generateListingPresentation]);
    const onPreListingPresentationClick = React.useCallback(() => () => {
        generatePreListingPresentation();
    }, [generatePreListingPresentation]);
    const filesMenuItems = React.useMemo(() => {
        return [
            {
                label: "Buyer PSP Report",
                icon: MENU_ITEM_ICON,
                onClick: onBuyerPSPReportClick("pdf"),
            },
            {
                label: "Buyer PSP Report (docx)",
                icon: MENU_ITEM_ICON,
                onClick: onBuyerPSPReportClick("docx"),
            },
            {
                label: "Download Buyer PSP Report Template",
                icon: MENU_ITEM_ICON,
                href: "/matrix/templates/buyer-psp-report",
                target: "_blank",
            },
            {
                label: "Buyer PSP Report (V2)",
                icon: MENU_ITEM_ICON,
                onClick: onBuyerPSPReportClick("pdf", true),
            },
            {
                label: "Listing PSP Report",
                icon: MENU_ITEM_ICON,
                onClick: onListingPSPReportClick("pdf", null),
            },
            {
                label: "Listing PSP Report w/ Zavvie (JSON)",
                icon: MENU_ITEM_ICON,
                onClick: onListingPSPReportClick("pdf", "json"),
            },
            {
                label: "Listing PSP Report w/ Zavvie (PDF)",
                icon: MENU_ITEM_ICON,
                onClick: onListingPSPReportClick("pdf", "pdf"),
            },
            {
                label: "Listing PSP Report (docx)",
                icon: MENU_ITEM_ICON,
                onClick: onListingPSPReportClick("docx", null),
            },
            {
                label: "Download Listing PSP Report Template",
                icon: MENU_ITEM_ICON,
                href: "/matrix/templates/listing-psp-report",
                target: "_blank",
            },
            {
                label: "psp-b1-divider",
                type: "divider",
            },
            ...templateMenuItems,
            {
                label: "cma-net-sheet-divider",
                type: "divider",
            },
            {
                label: "Net Sheet - Traditional",
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateNetSheet("Traditional", "pdf");
                },
            },
            {
                label: "Net Sheet - Traditional (xlsx)",
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateNetSheet("Traditional", "xlsx");
                },
            },
            {
                label: "Net Sheet - General",
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateNetSheet("General", "pdf");
                },
            },
            {
                label: "Net Sheet - General (xlsx)",
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateNetSheet("General", "xlsx");
                },
            },
            {
                label: "Net Sheet - iBuyer",
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateNetSheet("iBuyer", "pdf");
                },
            },
            {
                label: "Net Sheet - iBuyer (xlsx)",
                icon: MENU_ITEM_ICON,
                onClick: () => {
                    generateNetSheet("iBuyer", "xlsx");
                },
            },
            {
                label: "Cash to Close",
                icon: MENU_ITEM_ICON,
                onClick: generateCashToClose,
            },
            {
                label: "Multiple Offers",
                icon: MENU_ITEM_ICON,
                onClick: generateMultipleOffers,
            },
            {
                label: "View pre-listing presentation PDF",
                icon: MENU_ITEM_ICON,
                onClick: onPreListingPresentationClick(),
            },
            {
                label: "View listing presentation PDF",
                icon: MENU_ITEM_ICON,
                onClick: onListingPresentationClick(),
            },
            {
                label: "Investor Report",
                icon: MENU_ITEM_ICON,
                onClick: generateInvestorReport,
            },
            {
                label: "Aged Property Report",
                icon: MENU_ITEM_ICON,
                onClick: generateAgedPropertyReport,
            },
        ];
    }, [
        generateAgedPropertyReport,
        generateCashToClose,
        generateInvestorReport,
        generateMultipleOffers,
        generateNetSheet,
        onBuyerPSPReportClick,
        onListingPSPReportClick,
        onListingPresentationClick,
        onPreListingPresentationClick,
        templateMenuItems,
    ]);
    const pspMenuItems = React.useMemo(() => {
        const items = [
            {
                label: "Autopopulate from Zillow",
                icon: MENU_ITEM_ICON,
                onClick: autopopulateFromZillow,
            },
            {
                label: "Autopopulate CRS Property Report",
                icon: MENU_ITEM_ICON,
                onClick: populateFromCRS,
            },
            {
                label: "Autopopulate Realist Report",
                icon: MENU_ITEM_ICON,
                onClick: populateFromRealistReport,
            },
            {
                label: "Autopopulate RPR Data",
                icon: MENU_ITEM_ICON,
                onClick: populateFromGeneric,
            },
            {
                label: "Autopopulate CA",
                icon: MENU_ITEM_ICON,
                onClick: populateFromGeneric,
            },
            {
                label: "Autopopulate MLS",
                icon: MENU_ITEM_ICON,
                onClick: populateFromMLS,
            },
            {
                label: "Autopopulate Canopy MLS",
                icon: MENU_ITEM_ICON,
                onClick: populateFromCanopyMLS,
            },
            {
                label: "Autopopulate PSP Comps",
                icon: MENU_ITEM_ICON,
                onClick: populatePSPCompData,
            },
            {
                label: "Autopopulate Comps",
                icon: MENU_ITEM_ICON,
                onClick: populateCompData,
            },
            {
                label: "Autopopulate from CMA Summary",
                icon: MENU_ITEM_ICON,
                onClick: populateFromGeneric,
            },
        ];
        if (trainingReferenceUid || TEST_MATRIX_UIDS.includes(uid)) {
            items.push({
                label: trainingSubmissions.length === 0 ? "Submit" : "Resubmit",
                icon: MENU_ITEM_ICON,
                onClick: submitTrainingMAtrix,
            });
        }
        return items;
    }, [
        autopopulateFromZillow,
        populateCompData,
        populateFromCRS,
        populateFromCanopyMLS,
        populateFromGeneric,
        populateFromMLS,
        populateFromRealistReport,
        populatePSPCompData,
        submitTrainingMAtrix,
        trainingReferenceUid,
        trainingSubmissions.length,
        uid,
    ]);
    if (!pspReportTemplates) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", justifyContent: "right" },
            React.createElement(Box, { p: 2 },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Share MAtrix") },
                    React.createElement(IconButton, { size: "small", onClick: onShareDialogOpen },
                        React.createElement(Share, { color: "primary" })))),
            React.createElement(Box, { p: 2 },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "PSP") },
                    React.createElement(IconButton, { size: "small", onClick: onMenuOpen(pspMenuItems.filter((item) => {
                            return !item.hidden;
                        })) },
                        React.createElement(CloudDownload, { color: "primary" })))),
            React.createElement(Box, { p: 2 },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Files") },
                    React.createElement(IconButton, { size: "small", onClick: onMenuOpen(filesMenuItems.filter((item) => {
                            return !item.hidden;
                        })) },
                        React.createElement(Description, { color: "primary" })))),
            React.createElement(Box, { p: 2 },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Comments") },
                    React.createElement(IconButton, { size: "small", onClick: onSidebarChange("Comments") },
                        React.createElement(Comment, { color: "primary" })))),
            React.createElement(Box, { p: 2 },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "MLS Data") },
                    React.createElement(IconButton, { size: "small", onClick: onSidebarChange("PSP Comps") },
                        React.createElement(MenuOpen, { color: "primary" })))),
            React.createElement(Box, { p: 2 },
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "ArcGIS") },
                    React.createElement(IconButton, { size: "small", onClick: onSidebarChange("ArcGIS") },
                        React.createElement(Place, { color: "primary" }))))),
        React.createElement(CoreMenu, { anchorEl: anchorEl, open: Boolean(anchorEl), keepMounted: true, onClose: onMenuClose, style: {
                color: "#999999",
            } }, (menuItems !== null && menuItems !== void 0 ? menuItems : []).map((item) => {
            var _a, _b, _c;
            if (item.type === "divider") {
                return React.createElement(Divider, { key: item.label });
            }
            if (item.href) {
                return (React.createElement(MenuItem, { key: item.label, onClick: onMenuClose, disabled: (_a = item.disabled) !== null && _a !== void 0 ? _a : false, href: item.href, component: CoreLink, target: item.target },
                    item.icon,
                    " ",
                    item.label));
            }
            return (React.createElement(MenuItem, { key: item.label, onClick: (_b = item.onClick) !== null && _b !== void 0 ? _b : onMenuClose, disabled: (_c = item.disabled) !== null && _c !== void 0 ? _c : false },
                item.icon,
                " ",
                item.label));
        })),
        React.createElement(MAtrixShareDialog, { matrix: matrix, dialogProps: { open: !!isShareDialogOpen }, onSaveComplete: onShareComplete, onCancel: onShareCancel, shouldDuplicate: false })));
};
export default Toolbar;
