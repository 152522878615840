import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import MailSchedule4TemplatePreview from "./MailScheduleTemplatePreview";
const MailSchedule4TemplatePreviewPage = () => {
    var _a, _b, _c;
    useDocumentTitle("Mail Schedule | Preview");
    const [searchParams, _] = useSearchParams();
    const navigate = useNavigate();
    const entityType = ((_a = searchParams.get("entity_type")) !== null && _a !== void 0 ? _a : null);
    const entityUid = (_b = searchParams.get("uid")) !== null && _b !== void 0 ? _b : null;
    const templateUid = (_c = searchParams.get("template")) !== null && _c !== void 0 ? _c : null;
    const goBack = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    if (entityType == null || entityUid == null || templateUid == null) {
        return React.createElement("div", null, "Invalid parameters");
    }
    return (React.createElement(MailSchedule4TemplatePreview, { entity: { entityType, uid: entityUid }, templateUid: templateUid, onTemplateProcessed: goBack, onClose: goBack }));
};
export default MailSchedule4TemplatePreviewPage;
