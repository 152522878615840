import * as React from "react";
import { House } from "@mui/icons-material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import { getTotalUnits } from "@app/orval/api/agent-dashboard";
import BigMetricBox from "./widgets/BigMetricBox";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const TotalUnits = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { fromDate, selectedUserUids, toDate } = agentDashboardFilters;
    const queryParams = pickBy({ from_date: fromDate, to_date: toDate, agent_uids: [...selectedUserUids] }, (val) => val != null);
    const getUnitsApi = usePostQuery(getTotalUnits, queryParams);
    const units = (_a = getUnitsApi.data) === null || _a === void 0 ? void 0 : _a.data.value;
    const value = units != null ? numeral(units).format("0,0") : undefined;
    return (React.createElement(BigMetricBox, { title: "Units", value: value, icon: React.createElement(House, { fontSize: "large" }), isLoading: getUnitsApi.isFetching, error: getUnitsApi.error, description: `
        The number of transactions in Buyer and Seller Contracts where the
        Closing Date | COE falls within the specified date range.
        Does not include Terminated transactions.` }));
};
export default TotalUnits;
