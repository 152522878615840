import * as React from "react";
import { Grid2, MenuItem, TextField } from "@mui/material";
import { useGetContact } from "@app/orval/api/contacts";
import ContactAutocomplete from "../../../box-view/actions/ContactAutocomplete";
import { FilterContext } from "../FilterContext";
const SavedViewThirdPartyFilter = (props) => {
    var _a, _b;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.getFilter(uid);
    const getContactApi = useGetContact(filter.third_party_filter.rhs_contact_uid, {
        query: { enabled: !!((_a = filter.third_party_filter) === null || _a === void 0 ? void 0 : _a.rhs_contact_uid) },
    });
    const selectedContact = React.useMemo(() => {
        var _a, _b;
        if (getContactApi.isFetching) {
            return null;
        }
        return (_b = (_a = getContactApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [getContactApi]);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.third_party_filter.comparator = comparator;
        if (comparator !== "is equal to" && comparator !== "is not equal to") {
            newFilter.third_party_filter.rhs_contact_uid = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValue = React.useCallback((_, val) => {
        var _a;
        const newFilter = Object.assign({}, filter);
        newFilter.third_party_filter.rhs_contact_uid = (_a = val === null || val === void 0 ? void 0 : val.uid) !== null && _a !== void 0 ? _a : undefined;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.thirdPartyComparators;
    let query = {};
    if (filter.third_party_filter.third_party_type === "Buyer Agent" ||
        filter.third_party_filter.third_party_type === "Listing Agent") {
        query = { vendor_type: "Agent" };
    }
    else if (filter.third_party_filter.third_party_type === "Inspector") {
        query = { vendor_type: "Inspector" };
    }
    else if (filter.third_party_filter.third_party_type === "Lender") {
        query = { vendor_type: "Lender" };
    }
    else if (filter.third_party_filter.third_party_type === "Paralegal") {
        query = { vendor_type: "Paralegal" };
    }
    else if (filter.third_party_filter.third_party_type === "Site Manager") {
        query = { vendor_type: "Site Manager" };
    }
    else if (filter.third_party_filter.third_party_type === "Transaction Coordinator") {
        query = { vendor_type: "Transaction Coordinator" };
    }
    else if (filter.third_party_filter.third_party_type === "Zillow Loan Officer") {
        query = { vendor_type: "Zillow Loan Officer" };
    }
    else if (filter.third_party_filter.third_party_type === "Buyer Closing Attorney" ||
        filter.third_party_filter.third_party_type === "Seller Closing Attorney") {
        query = { vendor_type: "Attorney" };
    }
    else if (filter.third_party_filter.third_party_type === "Measurement Company") {
        query = { vendor_type: "Measurement Company" };
    }
    else if (filter.third_party_filter.third_party_type === "Photo Company") {
        query = { vendor_type: "Photo Company" };
    }
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_b = filter.third_party_filter.comparator) !== null && _b !== void 0 ? _b : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["is equal to", "is not equal to"].includes(filter.third_party_filter.comparator) && (React.createElement(Grid2, { style: { flex: 1 } },
            React.createElement(ContactAutocomplete, { value: selectedContact, onChange: setRHSValue, query: query })))));
};
export default SavedViewThirdPartyFilter;
