import { useCallback, useMemo } from "react";
import { listComments } from "@app/orval/api/comments";
import { searchFiles } from "@app/orval/api/files";
import { listNewsfeedFieldValueUpdates, listNewsfeedUserNoteChanges, listNewsfeedUserStageChanges, } from "@app/orval/api/newsfeed";
import { REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { getCommentStory, getFileStory, getNoteChangeStory, getStageChangeStory } from "../components/newsfeed/utils";
const useNewsfeed = (params) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const from = params === null || params === void 0 ? void 0 : params.from;
    const to = params === null || params === void 0 ? void 0 : params.to;
    const fetchFieldValuesApi = usePostQuery(listNewsfeedFieldValueUpdates, { earliest: from, latest: to, limit: 100, entity_types: ["User"] }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    // const fieldValues = fetchFieldValuesApi.data?.data ?? null;
    const isLoadingFieldValues = fetchFieldValuesApi.isLoading;
    const fetchStageUpdatesApi = usePostQuery(listNewsfeedUserStageChanges, { earliest: from, latest: to, limit: 100 }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const stageChanges = (_b = (_a = fetchStageUpdatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const isLoadingStages = fetchStageUpdatesApi.isLoading;
    const fetchNoteChangesApi = usePostQuery(listNewsfeedUserNoteChanges, { earliest: from, latest: to, limit: 100 }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const notes = (_d = (_c = fetchNoteChangesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const isLoadingNotes = fetchNoteChangesApi.isLoading;
    const fetchCommentsApi = usePostQuery(listComments, { added_from: from !== null && from !== void 0 ? from : undefined, added_to: to !== null && to !== void 0 ? to : undefined, limit: 100, entity_type: "User" }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const comments = (_f = (_e = fetchCommentsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const isLoadingComments = fetchCommentsApi.isLoading;
    const fetchFilesApi = usePostQuery(searchFiles, {
        created_after: from !== null && from !== void 0 ? from : undefined,
        created_before: to !== null && to !== void 0 ? to : undefined,
        limit: 100,
        entity_type: "User",
    }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS);
    const files = (_h = (_g = fetchFilesApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const isLoadingFiles = fetchFilesApi.isLoading;
    const refresh = useCallback(() => {
        fetchCommentsApi.refetch();
        fetchNoteChangesApi.refetch();
        fetchStageUpdatesApi.refetch();
        fetchFieldValuesApi.refetch();
        fetchFilesApi.refetch();
    }, [fetchCommentsApi, fetchFieldValuesApi, fetchFilesApi, fetchNoteChangesApi, fetchStageUpdatesApi]);
    const newsfeed = useMemo(() => {
        const items = [
            // ...(fieldValues ?? []).map((x) => ({
            //   ...x,
            //   url: `/users/${x.user!.uid}`,
            //   name: x.user!.name,
            //   type: "field" as "field",
            // })),
            ...(stageChanges !== null && stageChanges !== void 0 ? stageChanges : []).map((x) => (Object.assign(Object.assign({}, x), { story: getStageChangeStory(x), url: `/users/${x.user.uid}`, name: x.user.name, type: "stage" }))),
            ...(notes !== null && notes !== void 0 ? notes : []).map((x) => (Object.assign(Object.assign({}, x), { story: getNoteChangeStory(x), url: `/users/${x.user.uid}`, name: x.user.name, type: "note" }))),
            ...(comments !== null && comments !== void 0 ? comments : []).map((x) => (Object.assign(Object.assign({}, x), { timestamp: x.edits[0].timestamp, userstamp: x.edits[0].author, story: getCommentStory(x), url: `/users/${x.user.uid}`, name: x.user.name, type: "comment" }))),
            ...(files !== null && files !== void 0 ? files : []).map((x) => (Object.assign(Object.assign({}, x), { timestamp: x.created_at, userstamp: x.uploaded_by, story: getFileStory(x), url: `/users/${x.user.uid}`, name: x.user.name, type: "file" }))),
        ];
        return items;
    }, [comments, files, notes, stageChanges]);
    return {
        isLoadingNotes,
        isLoadingStages,
        isLoadingFieldValues,
        isLoadingComments,
        isLoadingFiles,
        refresh,
        newsfeed,
    };
};
export default useNewsfeed;
