var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Box, Button, List, ListItem, ListItemText, ListItemIcon, Typography, Grid2, Alert } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { useConfirm } from "material-ui-confirm";
import twilioIcon from "@app/assets/img/twilio.png";
import CoreLink from "@app/common/CoreLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { useCreateTwilioVoicemailGreeting, useListTwilioVoicemailGreetings, } from "@app/orval/api/twilio-voicemail-recordings";
import { searchUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { fileToBase64 } from "@app/util/Utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const Voicemail = () => {
    var _a, _b, _c, _d, _e, _f;
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const listTwilioPhonesApi = useListCachedTwilioPhones({}, REFETCH_CACHE_PARAMS);
    const twilioPhones = (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listRecordingsApi = useListTwilioVoicemailGreetings(REFETCH_CACHE_PARAMS);
    const recordings = (_d = (_c = listRecordingsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const setVoicemailGreetingApi = useCreateTwilioVoicemailGreeting();
    const refresh = React.useCallback(() => {
        listRecordingsApi.refetch();
    }, [listRecordingsApi]);
    const searchUsersApi = usePostQuery(searchUsers, {}, REFETCH_CACHE_PARAMS);
    const users = (_f = (_e = searchUsersApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const handleSetRecordingClicked = React.useCallback((twilioPhone) => () => {
        document.getElementById(`twilio-voicemail-recording-input-${twilioPhone}`).click();
    }, []);
    const handleClearRecordingClicked = React.useCallback((twilioPhone) => () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS);
        if (result.confirmed) {
            queryClient.setQueryData(listRecordingsApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((recording) => recording.phone_number !== twilioPhone) });
            });
            setVoicemailGreetingApi
                .mutateAsync({
                data: {
                    phone_number: twilioPhone,
                    recording: null,
                    filename: null,
                },
            })
                .catch(enqueueErrorSnackbar);
        }
    }), [confirm, listRecordingsApi.queryKey, queryClient, setVoicemailGreetingApi]);
    const handleRecordingChanged = React.useCallback((twilioPhone) => (event) => __awaiter(void 0, void 0, void 0, function* () {
        const file = event.target.files ? event.target.files[0] : null;
        event.target.value = "";
        if (!file) {
            return;
        }
        const base64content = yield fileToBase64(file);
        setVoicemailGreetingApi
            .mutateAsync({
            data: {
                phone_number: twilioPhone,
                recording: base64content,
                filename: file.name,
            },
        })
            .then(() => {
            refresh();
        })
            .catch(enqueueErrorSnackbar);
    }), [refresh, setVoicemailGreetingApi]);
    const loading = [];
    if (!twilioPhones) {
        loading.push("Getting Twilio phones...");
    }
    if (!recordings) {
        loading.push("Getting current voicemail recordings...");
    }
    if (!users) {
        loading.push("Getting users...");
    }
    if (loading.length > 0) {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" }, loading.map((item) => (React.createElement(Grid2, { key: item },
                React.createElement(Typography, null, item)))))));
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { maxHeight: "100%", overflowY: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Alert, { severity: "info", variant: "outlined" },
                React.createElement(Typography, null, "You can edit the voicemail greeting for any DASH phone that isn't assigned to a DASH agent."))),
        React.createElement(Grid2, { style: { overflowY: "auto" } },
            React.createElement(List, { dense: true }, (twilioPhones !== null && twilioPhones !== void 0 ? twilioPhones : [])
                .filter((twilioPhone) => {
                const agent = users.find((user) => user.fields["Twilio #"] &&
                    parsePhoneNumberWithError(user.fields["Twilio #"], { defaultCountry: "US" }).number ===
                        parsePhoneNumberWithError(twilioPhone.phone_number, { defaultCountry: "US" }).number);
                return !agent;
            })
                .map((twilioPhone) => {
                const recording = (recordings !== null && recordings !== void 0 ? recordings : []).find((r) => r.phone_number === twilioPhone.phone_number);
                return (React.createElement(ListItem, { key: twilioPhone.phone_number, secondaryAction: React.createElement(React.Fragment, null,
                        React.createElement(Grid2, { container: true, spacing: 1 },
                            (recording === null || recording === void 0 ? void 0 : recording.url) && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(CoreLink, { href: recording.url, target: "_blank" }, recording.filename))),
                            React.createElement(Grid2, null,
                                React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: handleSetRecordingClicked(twilioPhone.phone_number) }, "Set Recording")),
                            React.createElement(Grid2, null, (recording === null || recording === void 0 ? void 0 : recording.url) && (React.createElement(Button, { color: "secondary", size: "small", onClick: handleClearRecordingClicked(twilioPhone.phone_number) }, "Clear Recording")))),
                        React.createElement("input", { accept: "audio/mpeg", id: `twilio-voicemail-recording-input-${twilioPhone.phone_number}`, onChange: handleRecordingChanged(twilioPhone.phone_number), style: { display: "none" }, type: "file" })) },
                    React.createElement(ListItemIcon, null,
                        React.createElement("img", { src: twilioIcon, alt: "Twilio", width: 40, height: 40, style: {
                                borderRadius: "50%",
                            } })),
                    React.createElement(ListItemText, { primary: twilioPhone.friendly_name, secondary: twilioPhone.phone_number })));
            })))));
};
export default Voicemail;
