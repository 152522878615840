import * as React from "react";
import DashSidebarContainer from "@app/common/CoreSidebarContainer";
import { withRouter } from "@app/hoc/withRouter";
import AppManager from "@app/util/AppManager";
import MailScheduleExecutionsTable from "./MailScheduleExecutionsTable";
import MailScheduleExecutionsTableSidebar from "./MailScheduleExecutionsTableSidebar";
import MailScheduleLogsTable from "./MailScheduleLogsTable";
class MailScheduleExecutionsWrapper extends React.Component {
    constructor() {
        var _a;
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                showFilterSidebar: (_a = this.props.showFilterSidebar) !== null && _a !== void 0 ? _a : false,
            }
        });
        Object.defineProperty(this, "toggleFilterSidebar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    showFilterSidebar: !prevState.showFilterSidebar,
                }));
            }
        });
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "Mail Schedule", subtitle: "Execution Logs" };
    }
    componentWillUnmount() {
        AppManager.shared.pageTitle = {};
    }
    render() {
        const { uid } = this.props.params;
        const { showFilterSidebar } = this.state;
        if (uid) {
            return React.createElement(MailScheduleLogsTable, { executionUid: uid });
        }
        return (React.createElement(DashSidebarContainer, { mainContent: React.createElement(MailScheduleExecutionsTable, { onSidebarToggle: this.toggleFilterSidebar }), sidebar: React.createElement(MailScheduleExecutionsTableSidebar, { onSidebarToggle: this.toggleFilterSidebar }), sidebarWidth: 250, opened: showFilterSidebar }));
    }
}
Object.defineProperty(MailScheduleExecutionsWrapper, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        showFilterSidebar: true,
    }
});
export default withRouter(MailScheduleExecutionsWrapper);
