import * as React from "react";
import { Link } from "react-router-dom";
import { isChromeExtension } from "@app/util/Utils";
/**
 * A wrapper around react-router-dom's ``Link`` component
 * which automatically adds the "router-link" class name, which
 * can be used in theming.
 *
 * Also plays nice with the Gmail version, because it won't
 * open links in a new page when we're within Gmail.
 *
 * @param props LinkProps
 * @returns the component
 */
const RouterLink = (props) => {
    const className = "router-link";
    // eslint-disable-next-line react/prop-types
    const { target } = props;
    const canOpenInNewPage = (isChromeExtension && !String(props.to).startsWith("/")) || !isChromeExtension;
    return (React.createElement(Link, Object.assign({}, props, { target: canOpenInNewPage ? target : undefined, className: [props.className, className].filter((o) => o).join(" ") })));
};
export default RouterLink;
