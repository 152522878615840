var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { runMailschedule4 } from "@app/orval/api/mail-schedule-run";
import { isChromeExtension } from "@app/util/Utils";
/**
 * Create email draft
 *
 * If the app is running as a Chrome extension, it will send a message to the parent window to create draft within the Gmail UI.
 *
 * If the app is running as a web app, it create the draft via the Gmail API
 *
 * @param options
 */
export const createDraft = (params) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g;
    const { draft } = params;
    if (isChromeExtension) {
        window.parent.postMessage({
            type: "CREATE_DRAFT",
            options: {
                draft: Object.assign(Object.assign({}, draft), { entityUids: draft.entity_uids, templateUid: draft.template_uid, attachments: draft.attachments }),
            },
        }, "*");
    }
    else {
        const loadingSnackbar = enqueueSnackbar("Creating draft...", { variant: "info", persist: true });
        const mailMergeResponse = yield runMailschedule4({
            entity_uids: (_a = draft.entity_uids) !== null && _a !== void 0 ? _a : [],
            custom_templates: [
                {
                    base_template_uid: (_b = draft.template_uid) !== null && _b !== void 0 ? _b : null,
                    email_draft_template: {
                        to: (_c = draft.to) !== null && _c !== void 0 ? _c : "",
                        cc: (_d = draft.cc) !== null && _d !== void 0 ? _d : "",
                        bcc: (_e = draft.bcc) !== null && _e !== void 0 ? _e : "",
                        subject: (_f = draft.subject) !== null && _f !== void 0 ? _f : "",
                        body: (_g = draft.body) !== null && _g !== void 0 ? _g : "",
                        attachments: [], // TODO: fix this
                    },
                },
            ],
            is_test: false,
        });
        console.log({ mailMergeResponse });
        closeSnackbar(loadingSnackbar);
    }
});
export const navigateTo = (path, params) => {
    var _a;
    const isRootLevel = (_a = params === null || params === void 0 ? void 0 : params.isRootLevel) !== null && _a !== void 0 ? _a : false;
    window.parent.postMessage({
        type: "NAVIGATE_TO",
        path,
        isRootLevel,
    }, "*");
};
export const closeSidebar = () => {
    window.parent.postMessage({
        type: "CLOSE_SIDEBAR",
    }, "*");
};
export const addStarredView = (params) => {
    window.parent.postMessage({
        type: "ADD_STARRED_VIEW",
        uid: params.uid,
        name: params.name,
    }, "*");
};
export const removeStarredView = (uid) => {
    window.parent.postMessage({
        type: "REMOVE_STARRED_VIEW",
        uid,
    }, "*");
};
