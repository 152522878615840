import * as React from "react";
import { Money } from "@mui/icons-material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import { getTotalVolume } from "@app/orval/api/agent-dashboard";
import BigMetricBox from "./widgets/BigMetricBox";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const TotalVolume = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { fromDate, selectedUserUids, toDate } = agentDashboardFilters;
    const queryParams = pickBy({ from_date: fromDate, to_date: toDate, agent_uids: [...selectedUserUids] }, (val) => val != null);
    const getVolumeApi = usePostQuery(getTotalVolume, queryParams);
    const volume = (_a = getVolumeApi.data) === null || _a === void 0 ? void 0 : _a.data.value;
    const value = volume != null ? numeral(volume).format("$0,0") : undefined;
    return (React.createElement(BigMetricBox, { title: "Volume", value: value, icon: React.createElement(Money, { fontSize: "large" }), isLoading: getVolumeApi.isFetching, error: getVolumeApi.error, description: `
        The sum of the Volume field for all transactions in Buyer and Seller Contracts where the
        Closing Date | COE falls within the specified date range.
        Does not include Terminated transactions.` }));
};
export default TotalVolume;
