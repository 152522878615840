/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List all numbers on the Do Not Call list
 */
export const listTwilioDoNotCallItems = (options) => {
    return axios.get(`/api/twilio/do_not_call`, options);
};
export const getListTwilioDoNotCallItemsQueryKey = () => {
    return [`/api/twilio/do_not_call`];
};
export const getListTwilioDoNotCallItemsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTwilioDoNotCallItemsQueryKey();
    const queryFn = ({ signal }) => listTwilioDoNotCallItems(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all numbers on the Do Not Call list
 */
export function useListTwilioDoNotCallItems(options) {
    const queryOptions = getListTwilioDoNotCallItemsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Add a number to the Do Not Call list
 */
export const createTwilioDoNotCallItem = (doNotCallRequest, options) => {
    return axios.post(`/api/twilio/do_not_call`, doNotCallRequest, options);
};
export const getCreateTwilioDoNotCallItemMutationOptions = (options) => {
    const mutationKey = ['createTwilioDoNotCallItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createTwilioDoNotCallItem(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a number to the Do Not Call list
*/
export const useCreateTwilioDoNotCallItem = (options) => {
    const mutationOptions = getCreateTwilioDoNotCallItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a number on the Do Not Call list
*/
export const getTwilioDoNotCallItem = (uid, options) => {
    return axios.get(`/api/twilio/do_not_call/${uid}`, options);
};
export const getGetTwilioDoNotCallItemQueryKey = (uid) => {
    return [`/api/twilio/do_not_call/${uid}`];
};
export const getGetTwilioDoNotCallItemQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTwilioDoNotCallItemQueryKey(uid);
    const queryFn = ({ signal }) => getTwilioDoNotCallItem(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a number on the Do Not Call list
 */
export function useGetTwilioDoNotCallItem(uid, options) {
    const queryOptions = getGetTwilioDoNotCallItemQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a number on the Do Not Call list
 */
export const updateTwilioDoNotCallItem = (uid, doNotCallRequest, options) => {
    return axios.post(`/api/twilio/do_not_call/${uid}`, doNotCallRequest, options);
};
export const getUpdateTwilioDoNotCallItemMutationOptions = (options) => {
    const mutationKey = ['updateTwilioDoNotCallItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateTwilioDoNotCallItem(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a number on the Do Not Call list
*/
export const useUpdateTwilioDoNotCallItem = (options) => {
    const mutationOptions = getUpdateTwilioDoNotCallItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a number from the Do Not Call list
*/
export const deleteTwilioDoNotCallItem = (uid, options) => {
    return axios.delete(`/api/twilio/do_not_call/${uid}`, options);
};
export const getDeleteTwilioDoNotCallItemMutationOptions = (options) => {
    const mutationKey = ['deleteTwilioDoNotCallItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteTwilioDoNotCallItem(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a number from the Do Not Call list
*/
export const useDeleteTwilioDoNotCallItem = (options) => {
    const mutationOptions = getDeleteTwilioDoNotCallItemMutationOptions(options);
    return useMutation(mutationOptions);
};
