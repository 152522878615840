/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get a list of background scripts setup to run via CRON
 */
export const listScripts = (options) => {
    return axios.get(`/api/scripts`, options);
};
export const getListScriptsQueryKey = () => {
    return [`/api/scripts`];
};
export const getListScriptsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListScriptsQueryKey();
    const queryFn = ({ signal }) => listScripts(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get a list of background scripts setup to run via CRON
 */
export function useListScripts(options) {
    const queryOptions = getListScriptsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * The `success` response returned by this endpoint simply indicates
        that the script execution has begun.  Use the `/api/scripts/status` endpoint
        to check the execution status of a script.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Manually run a background script
 */
export const runScript = (scriptName, options) => {
    return axios.post(`/api/scripts/${scriptName}/run`, undefined, options);
};
export const getRunScriptMutationOptions = (options) => {
    const mutationKey = ['runScript'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { scriptName } = props !== null && props !== void 0 ? props : {};
        return runScript(scriptName, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Manually run a background script
*/
export const useRunScript = (options) => {
    const mutationOptions = getRunScriptMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Check the status of a script execution
*/
export const checkScriptStatus = (uid, options) => {
    return axios.get(`/api/scripts/executions/${uid}/status`, options);
};
export const getCheckScriptStatusQueryKey = (uid) => {
    return [`/api/scripts/executions/${uid}/status`];
};
export const getCheckScriptStatusQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCheckScriptStatusQueryKey(uid);
    const queryFn = ({ signal }) => checkScriptStatus(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Check the status of a script execution
 */
export function useCheckScriptStatus(uid, options) {
    const queryOptions = getCheckScriptStatusQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
