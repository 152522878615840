import * as React from "react";
import { RemoveCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip, Typography, } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { convertUtcDateString } from "@app/common/date-utils";
import RouterLink from "@app/common/RouterLink";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import StreetViewImage from "./StreetViewImage";
const QFOListItem = (props) => {
    var _a;
    const { onRemoveQFO, qfo } = props;
    const confirm = useConfirm();
    const removeQFO = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This will remove the QFO from the lead.", confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveQFO(qfo.uid);
            }
        });
    }, [confirm, onRemoveQFO, qfo.uid]);
    return (React.createElement(ListItemButton, { component: RouterLink, to: `/forms/qfo/${qfo.uid}`, tabIndex: -1 },
        React.createElement(ListItemIcon, null,
            React.createElement(StreetViewImage, { address: (_a = qfo.property_address) !== null && _a !== void 0 ? _a : "", ImageProps: { style: { width: "32px", height: "32px", borderRadius: "2px" } } })),
        React.createElement(ListItemText, { primary: qfo.property_address, secondary: React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "caption" }, qfo.created_at
                        ? `Added ${convertUtcDateString(qfo.created_at, { format: "dddd, MMMM D, YYYY h:mm A" })}`
                        : ""))) }),
        React.createElement(ListItemSecondaryAction, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove QFO") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removeQFO, tabIndex: -1 },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" })))))));
};
export default QFOListItem;
