import * as React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Check, Error, FilterList, HighlightOff, Launch, ManageSearch, OpenInBrowser, Refresh, Stop, } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Grid2, IconButton, LinearProgress, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import styled from "styled-components";
import { DialogContext } from "@app/common/context/DialogContext";
import { getModalSidebarDialogProps } from "@app/common/context/utils";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import dayjs_ from "@app/common/dayjs";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING } from "@app/common/grid/utils";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { listTransactions } from "@app/orval/api/agent-dashboard";
import { searchMailschedule4Executions, useCountMailschedule4Executions, useStopMailschedule4Execution, } from "@app/orval/api/mail-schedule-4-logs";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { getInitials } from "@app/util/Utils";
import BoxesModal from "./components/BoxesModal";
import CustomTemplateModal from "./components/CustomTemplateModal";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const StyledWrapper = styled("div") `
    & .actions {
        display: none;
    }
    &:hover .actions {
        display: inline-flex;
    }
`;
const MailScheduleExecutionsTable = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const { onSidebarToggle } = props;
    const [searchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const updateSearch = useUpdateSearch();
    const dialogContext = React.useContext(DialogContext);
    const limit = searchParams.get("limit") ? numeral(searchParams.get("limit")).value() : 100;
    const offset = searchParams.get("offset") ? numeral(searchParams.get("offset")).value() : 0;
    const runByUid = (_a = searchParams.get("run_by_agent")) !== null && _a !== void 0 ? _a : undefined;
    const agentUid = (_b = searchParams.get("agent")) !== null && _b !== void 0 ? _b : undefined;
    const agentUidIsnull = searchParams.get("agent_isnull") === "true" || undefined;
    const templateUid = (_c = searchParams.get("template")) !== null && _c !== void 0 ? _c : undefined;
    const templateUidIsNull = searchParams.get("template_isnull") === "true" || undefined;
    const pipeline = (_d = searchParams.get("pipeline")) !== null && _d !== void 0 ? _d : undefined;
    const pipelineIsNull = searchParams.get("pipeline_isnull") === "true" || undefined;
    const status = ((_e = searchParams.get("status")) !== null && _e !== void 0 ? _e : undefined);
    const mode = (_f = searchParams.get("mode")) !== null && _f !== void 0 ? _f : undefined;
    const boxUid = (_g = searchParams.get("box")) !== null && _g !== void 0 ? _g : undefined;
    const isTest = (_j = {
        "0": false,
        "1": true,
    }[(_h = searchParams.get("is_test")) !== null && _h !== void 0 ? _h : ""]) !== null && _j !== void 0 ? _j : undefined;
    const page = React.useMemo(() => {
        if (limit === 0) {
            return 0;
        }
        return Math.floor(offset / limit);
    }, [limit, offset]);
    const [stoppingExecutions, setStoppingExecutions] = React.useState(new Set());
    const listTemplatesApi = useListMailschedule4Templates({}, INFINITE_CACHE_PARAMS);
    const templates = (_l = (_k = listTemplatesApi.data) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : null;
    const query = React.useMemo(() => {
        return pickBy({
            limit,
            offset,
            run_by_uid: runByUid,
            run_for_uid: agentUid,
            run_for_uid_isnull: agentUidIsnull,
            template_uid: templateUid,
            template_uid_isnull: templateUidIsNull,
            entity_type: pipeline,
            entity_type_isnull: pipelineIsNull,
            status,
            entity_uid: boxUid,
            mode,
            is_test: isTest,
        }, (val) => val != null);
    }, [
        agentUid,
        agentUidIsnull,
        boxUid,
        isTest,
        limit,
        mode,
        offset,
        pipeline,
        pipelineIsNull,
        runByUid,
        status,
        templateUid,
        templateUidIsNull,
    ]);
    const countQuery = React.useMemo(() => omit(query, "limit", "offset"), [query]);
    const searchExecutionsApi = usePostQuery(searchMailschedule4Executions, query, {
        query: {
            staleTime: 5 * 60 * 1000,
            gcTime: 5 * 60 * 1000,
            placeholderData: keepPreviousData,
        },
    });
    const executionLogs = (_o = (_m = searchExecutionsApi.data) === null || _m === void 0 ? void 0 : _m.data) !== null && _o !== void 0 ? _o : null;
    const executionLogsByUid = React.useMemo(() => {
        const res = {};
        for (const log of executionLogs !== null && executionLogs !== void 0 ? executionLogs : []) {
            res[log.uid] = log;
        }
        return res;
    }, [executionLogs]);
    const transactionUidsToFetch = React.useMemo(() => {
        var _a;
        const uids = new Set();
        for (const log of executionLogs !== null && executionLogs !== void 0 ? executionLogs : []) {
            if (((_a = log.run_for_entity_uids) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                for (const entityUid of log.run_for_entity_uids) {
                    uids.add(entityUid);
                }
            }
        }
        return Array.from(uids);
    }, [executionLogs]);
    const listTransactionsApi = usePostQuery(listTransactions, {
        uids: transactionUidsToFetch,
        include_under_contract_leads: true,
    }, {}, transactionUidsToFetch.length > 0);
    const transactions = (_q = (_p = listTransactionsApi.data) === null || _p === void 0 ? void 0 : _p.data) !== null && _q !== void 0 ? _q : null;
    const transactionsByUid = React.useMemo(() => {
        const res = {};
        for (const transaction of transactions !== null && transactions !== void 0 ? transactions : []) {
            res[transaction.uid] = transaction;
        }
        return res;
    }, [transactions]);
    const refresh = React.useCallback(() => {
        searchExecutionsApi.refetch();
    }, [searchExecutionsApi]);
    const countExecutionLogsApi = useCountMailschedule4Executions(countQuery);
    const count = (_s = (_r = countExecutionLogsApi.data) === null || _r === void 0 ? void 0 : _r.data.count) !== null && _s !== void 0 ? _s : null;
    const stopExecutionApi = useStopMailschedule4Execution();
    const openBoxesModal = React.useCallback((uid) => () => {
        var _a;
        dialogContext.openDialog(React.createElement(BoxesModal, { boxUids: (_a = executionLogsByUid[uid].run_for_entity_uids) !== null && _a !== void 0 ? _a : [] }), {
            dialogProps: getModalSidebarDialogProps(),
        });
    }, [dialogContext, executionLogsByUid]);
    const openCustomTemplateModal = React.useCallback((uid) => () => {
        const customTemplates = executionLogsByUid[uid].run_for_custom_templates;
        if (!customTemplates) {
            return;
        }
        dialogContext.openDialog(React.createElement(CustomTemplateModal, { customTemplate: customTemplates[0] }), {
            dialogProps: getModalSidebarDialogProps(),
        });
    }, [dialogContext, executionLogsByUid]);
    const stopExecution = React.useCallback((uid) => () => {
        stopExecutionApi
            .mutateAsync({
            uid,
        })
            .then((result) => {
            setStoppingExecutions((prev) => {
                const next = new Set(prev);
                next.delete(uid);
                return next;
            });
            queryClient.setQueryData(searchExecutionsApi.queryKey, (prev) => {
                if (!prev)
                    return prev;
                const executionIndx = prev.data.findIndex((e) => e.uid === uid);
                return Object.assign(Object.assign({}, prev), { data: [
                        ...prev.data.slice(0, executionIndx),
                        result.data,
                        ...prev.data.slice(executionIndx + 1, prev.data.length),
                    ] });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [searchExecutionsApi.queryKey, queryClient, stopExecutionApi]);
    const onPageChange = React.useCallback((model) => {
        const rowsPerPage = model.pageSize;
        const currentPage = model.page;
        updateSearch("offset", rowsPerPage * currentPage, "limit", rowsPerPage);
    }, [updateSearch]);
    const rows = React.useMemo(() => {
        return (executionLogs !== null && executionLogs !== void 0 ? executionLogs : []).map((e) => {
            var _a;
            return Object.assign(Object.assign({}, e), { id: e.uid, template: templates === null || templates === void 0 ? void 0 : templates.filter((x) => { var _a; return ((_a = e.run_for_template_uids) !== null && _a !== void 0 ? _a : []).includes(x.uid); }), pipeline: (_a = e.run_for_entity_types) !== null && _a !== void 0 ? _a : [], agent: e.run_for_users, box: e.run_for_entity_uids, mode: e.mode === "Manual" ? "Run Manually" : e.mode });
        });
    }, [executionLogs, templates]);
    const columns = React.useMemo(() => [
        {
            field: "uid",
            headerName: "",
            width: 75,
            renderCell: (params) => {
                const log = executionLogsByUid[params.value];
                return (React.createElement(Grid2, { container: true, spacing: 1, style: { width: "calc(100% + 10px)" } },
                    React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Open logs"), disableInteractive: true },
                            React.createElement(IconButton, { size: "small", component: RouterLink, to: `/ms4/logs/detailed?uid=${log.uid}`, tabIndex: -1 },
                                React.createElement(Launch, { fontSize: "small" })))),
                    !log.end_timestamp &&
                        !log.error_message &&
                        dayjs_(log.start_timestamp).isAfter(dayjs_(new Date()).add(-1, "hour")) && (React.createElement(Grid2, null,
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Stop script execution"), disableInteractive: true },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", onClick: stopExecution(log.uid), disabled: stoppingExecutions.has(log.uid), tabIndex: -1 },
                                    React.createElement(HighlightOff, { fontSize: "small" }))))))));
            },
        },
        {
            field: "is_test",
            headerName: "Test",
            width: 40,
            minWidth: 40,
            renderCell: (params) => {
                const log = executionLogsByUid[params.row.uid];
                return log.is_test ? React.createElement(Check, { fontSize: "small" }) : null;
            },
        },
        {
            field: "mode",
            headerName: "Mode",
            width: 175,
            renderCell: (params) => {
                var _a;
                const log = executionLogsByUid[params.row.uid];
                if (log.mode === "Scheduled" || log.mode === "Triggered") {
                    return log.mode;
                }
                const runBy = log.run_by;
                if (runBy) {
                    return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center", style: { height: "100%" } },
                        React.createElement(Grid2, null,
                            React.createElement(Avatar, { style: { width: "18px", height: "18px", fontSize: "0.65rem" }, src: (_a = runBy.icon_url) !== null && _a !== void 0 ? _a : undefined }, getInitials(runBy.first_name, runBy.last_name))),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2", color: "textSecondary" },
                                "Run by ",
                                runBy.first_name,
                                " ",
                                runBy.last_name))));
                }
                if (log.mode === "Manual") {
                    return "Run Manually";
                }
                return null;
            },
        },
        {
            field: "start_timestamp",
            headerName: "Start",
            width: 175,
            renderCell: (params) => {
                const { value } = params;
                if (value && dayjs_(value).isValid()) {
                    return dayjs_(value).format("ddd, MMM D, YYYY h:mm:ss A");
                }
                return value;
            },
        },
        {
            field: "end_timestamp",
            headerName: "End",
            width: 175,
            renderCell: (params) => {
                const { value } = params;
                if (value && dayjs_(value).isValid()) {
                    return dayjs_(value).format("ddd, MMM D, YYYY h:mm:ss A");
                }
                return value;
            },
        },
        {
            field: "error_message",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const log = executionLogsByUid[params.row.uid];
                let status_;
                let icon;
                if (log.error_message) {
                    status_ = "Errored Out";
                    icon = React.createElement(Error, { style: { color: "red" } });
                }
                else if (log.stopped_timestamp) {
                    status_ = "Stopped";
                    icon = React.createElement(Stop, { style: { color: "#888" } });
                }
                else if (!log.end_timestamp && dayjs_(log.start_timestamp).isAfter(dayjs_().add(-1, "hour"))) {
                    status_ = "Running";
                    icon = React.createElement(CoreLoading, { size: 16 });
                }
                else {
                    status_ = "Finished";
                    icon = React.createElement(Check, { style: { color: "green" } });
                }
                return (React.createElement(Grid2, { container: true, spacing: 1 },
                    React.createElement(Grid2, null, log.error_message ? (React.createElement(Tooltip, { title: React.createElement(Typography, { variant: "body2" }, log.error_message), disableInteractive: true }, icon)) : (icon)),
                    React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, status_)));
            },
        },
        {
            field: "num_templates_sent",
            headerName: "# Processed",
        },
        {
            field: "num_templates_errored_out",
            headerName: "# Errored Out",
        },
        {
            field: "pipeline",
            headerName: "Pipeline",
            width: 150,
            renderCell: (params) => {
                const log = executionLogsByUid[params.row.uid];
                const value = log.run_for_entity_types;
                if (!value || value.length === 0) {
                    return null;
                }
                if (value.length === 1) {
                    let url = "";
                    if (value[0] === "Buyer Lead") {
                        url = "/buyer-leads";
                    }
                    else if (value[0] === "Seller Lead") {
                        url = "/seller-leads";
                    }
                    else if (value[0] === "Buyer Contract") {
                        url = "/buyer-contracts";
                    }
                    else if (value[0] === "Listing") {
                        url = "/seller-contracts";
                    }
                    else if (value[0] === "Recruit") {
                        url = "/recruits";
                    }
                    else if (value[0] === "User") {
                        url = "/users";
                    }
                    return React.createElement(RouterLink, { to: url }, value[0]);
                }
                return (React.createElement(Typography, { variant: "body2", color: "textSecondary" }, `${value.length} pipelines`));
            },
        },
        {
            field: "agent",
            headerName: "Run For Agent",
            width: 150,
            renderCell: (params) => {
                const { value } = params;
                if (!value || value.length === 0) {
                    return null;
                }
                if (value.length === 1) {
                    return React.createElement(RouterLink, { to: `/users/${value[0].uid}` }, value[0].name);
                }
                return (React.createElement(Typography, { variant: "body2", color: "textSecondary" }, `${value.length} agents`));
            },
        },
        {
            field: "box",
            headerName: "Box",
            width: 150,
            renderCell: (params) => {
                var _a;
                const log = executionLogsByUid[params.row.uid];
                const value = log.run_for_entity_uids;
                if (!value || value.length === 0) {
                    return null;
                }
                if (value.length === 1) {
                    const transaction = transactionsByUid[value[0]];
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    return React.createElement(RouterLink, { to: `/boxes/${value[0]}` }, (_a = transaction === null || transaction === void 0 ? void 0 : transaction.name) !== null && _a !== void 0 ? _a : value[0]);
                }
                return (React.createElement(Button, { variant: "text", size: "small", onClick: openBoxesModal(log.uid), tabIndex: -1 }, `${value.length} boxes`));
            },
        },
        {
            field: "template",
            headerName: "Template",
            width: 150,
            renderCell: (params) => {
                const log = executionLogsByUid[params.row.uid];
                if (log.run_for_template_uids && log.run_for_template_uids.length > 0) {
                    if (log.run_for_template_uids.length === 1) {
                        const template = templates === null || templates === void 0 ? void 0 : templates.find((x) => { var _a; return x.uid === ((_a = log.run_for_template_uids) === null || _a === void 0 ? void 0 : _a[0]); });
                        return React.createElement(RouterLink, { to: `/ms4/templates/${template === null || template === void 0 ? void 0 : template.uid}` }, template === null || template === void 0 ? void 0 : template.name);
                    }
                    return (React.createElement(Typography, { variant: "body2", color: "textSecondary" }, `${log.run_for_template_uids.length} templates`));
                }
                if (log.run_for_custom_templates && log.run_for_custom_templates.length > 0) {
                    if (log.run_for_custom_templates.length === 1) {
                        if (log.run_for_custom_templates[0].base_template_uid) {
                            const template = templates === null || templates === void 0 ? void 0 : templates.find((x) => { var _a; return x.uid === ((_a = log.run_for_custom_templates) === null || _a === void 0 ? void 0 : _a[0].base_template_uid); });
                            return (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                                React.createElement(Grid2, { style: { width: "32px" } },
                                    React.createElement(IconButton, { size: "small", onClick: openCustomTemplateModal(log.uid), tabIndex: -1 },
                                        React.createElement(OpenInBrowser, { fontSize: "small" }))),
                                React.createElement(Grid2, { style: { flex: 1 } },
                                    React.createElement(RouterLink, { to: `/ms4/templates/${template === null || template === void 0 ? void 0 : template.uid}` }, template === null || template === void 0 ? void 0 : template.name))));
                        }
                        return (React.createElement(Button, { variant: "text", size: "small", onClick: openCustomTemplateModal(log.uid), tabIndex: -1 }, "Custom Template"));
                    }
                    return (React.createElement(Typography, { variant: "body2", color: "textSecondary" }, `${log.run_for_custom_templates.length} templates`));
                }
                return null;
            },
        },
    ], [
        executionLogsByUid,
        openBoxesModal,
        openCustomTemplateModal,
        stopExecution,
        stoppingExecutions,
        templates,
        transactionsByUid,
    ]);
    if (searchExecutionsApi.error) {
        return React.createElement(CoreError, { error: searchExecutionsApi.error });
    }
    return (React.createElement(StyledWrapper, { style: { height: "100%", position: "relative" } }, executionLogs ? (React.createElement(Grid2, { container: true, direction: "column", spacing: 0, wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 1 },
                React.createElement(Grid2, { container: true, direction: "row", spacing: 1, alignItems: "center" },
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", variant: "contained", component: RouterLink, to: "/ms4/logs/detailed", startIcon: React.createElement(ManageSearch, null), tabIndex: -1, color: "inherit" }, "Show detailed logs")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", variant: "contained", onClick: refresh, disabled: searchExecutionsApi.isFetching, startIcon: React.createElement(Refresh, null), tabIndex: -1, color: "inherit" }, "Refresh")),
                    onSidebarToggle && (React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", variant: "contained", onClick: onSidebarToggle, startIcon: React.createElement(FilterList, null), tabIndex: -1, color: "inherit" }, "Filters")))))),
        React.createElement(Grid2, null, searchExecutionsApi.isFetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 40, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnSorting: true, disableColumnResize: true, disableColumnMenu: true, hideFooterSelectedRowCount: true, density: "compact", pagination: count != null && rows.length < count, rowCount: count !== null && count !== void 0 ? count : undefined, paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], paginationModel: { page, pageSize: limit }, onPaginationModelChange: onPageChange, getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                        toolbarColumns: "",
                    }, sx: {
                        border: "none",
                        ".MuiDataGrid-main": {
                            border: "none",
                        },
                    } }))))) : (React.createElement(CoreLoading, null))));
};
export default MailScheduleExecutionsTable;
