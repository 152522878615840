import * as React from "react";
import { Table, TableCell, TableRow, TableBody, Grid, Button } from "@mui/material";
import numeral from "numeral";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { useGetQfsSubmissionNewcrm } from "@app/orval/api/questions-for-the-search";
const QuestionsForTheSearchFormSubmissionNEWCRM = (props) => {
    var _a, _b;
    const { uid } = props;
    const getQFSAPI = useGetQfsSubmissionNewcrm(uid);
    const qfs = (_b = (_a = getQFSAPI.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [showMore, setShowMore] = React.useState(false);
    const toggleShowMore = React.useCallback(() => {
        setShowMore((prev) => !prev);
    }, []);
    if (getQFSAPI.error) {
        return React.createElement(CoreError, { error: getQFSAPI.error });
    }
    if (getQFSAPI.isFetching || !qfs) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Table, { size: "small", sx: { "& tr td:first-child ": { fontWeight: "bold" } } },
        React.createElement(TableBody, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Name"),
                React.createElement(TableCell, null, qfs.name)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Additional Name"),
                React.createElement(TableCell, null, qfs.additional_name)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Email"),
                React.createElement(TableCell, null, qfs.email)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Additional Email"),
                React.createElement(TableCell, null, qfs.additional_email)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Phone"),
                React.createElement(TableCell, null, qfs.phone)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Additional Phone"),
                React.createElement(TableCell, null, qfs.additional_phone)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Minimum Price"),
                React.createElement(TableCell, null, qfs.minimum_price != null ? numeral(qfs.minimum_price).format("$0,0.00") : qfs.minimum_price)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Maximum Price"),
                React.createElement(TableCell, null, qfs.maximum_price != null ? numeral(qfs.maximum_price).format("$0,0.00") : qfs.maximum_price)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Age"),
                React.createElement(TableCell, null, qfs.age)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Minimum # of Bedrooms"),
                React.createElement(TableCell, null, qfs.minimum_num_bedrooms)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Maximum # of full baths"),
                React.createElement(TableCell, null, qfs.minimum_num_full_baths)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Minimum Square Footage"),
                React.createElement(TableCell, null, qfs.minimum_square_footage)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Maximum Square Footage"),
                React.createElement(TableCell, null, qfs.maximum_square_footage)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Garages"),
                React.createElement(TableCell, null, qfs.garages)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Minimum Lot Size"),
                React.createElement(TableCell, null, qfs.minimum_lot_size)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "General Locations"),
                React.createElement(TableCell, null, [...qfs.general_locations, qfs.general_locations_other ? `Other: ${qfs.general_locations_other}` : null]
                    .filter((x) => x)
                    .join(" "))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Property Types"),
                React.createElement(TableCell, null, qfs.property_types.join(" "))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Full Submission"),
                React.createElement(TableCell, null,
                    React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { size: "small", variant: "text", onClick: toggleShowMore }, showMore ? "Show Less" : "Show More")),
                        showMore && (React.createElement(Grid, { item: true },
                            React.createElement("pre", null, JSON.stringify(qfs.submission_json, null, 2))))))))));
};
export default QuestionsForTheSearchFormSubmissionNEWCRM;
