import * as React from "react";
import { KeyboardArrowLeft, KeyboardArrowRight, Timeline, AddLocation } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid2, MenuItem, InputAdornment, Typography, DialogContent, DialogTitle, DialogActions, Tooltip, Collapse, IconButton, Fab, } from "@mui/material";
import pickBy from "lodash/pickBy";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import CoreTextField from "@app/common/CoreTextField";
import dayjs_ from "@app/common/dayjs";
import CompsPullerSelectPropertyTable from "@app/entrypoints/brokerage/features/tools/comps-puller/CompsPullerSelectPropertyTable";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { listBridgeProperties } from "@app/orval/api/bridge";
import { geocodeWithGoogleMaps } from "@app/orval/api/geocode";
import { searchMlsesByAddress } from "@app/orval/api/mls";
import { listMlsGridListings } from "@app/orval/api/mls-grid";
import { pullComps } from "@app/orval/api/psp-comp-puller";
import { getPsp } from "@app/orval/api/psps";
import { listSparkProperties } from "@app/orval/api/spark";
import { toTitleCase, activeStatuses, pendingStatuses, closedStatuses, bridgePropertyDatasets, convertAsterisksToNull, getLatLng, } from "@app/util/Utils";
import CompsPullerAgedPropertyRegression from "./CompsPullerAgedPropertyRegression";
import CompsPullerPhotoCarousel from "./CompsPullerPhotoCarousel";
import CompsPullerTable from "./CompsPullerTable";
import CompsPullerArcGISMap from "./map/CompsPullerArcGISMap";
import CompsPullerGoogleMap from "./map/CompsPullerGoogleMap";
import PropertyInfoBar from "./PropertyInfoBar";
import Remarks from "./Remarks";
import StackedFields from "./StackedFields";
import { getPropertyType, getBathroomsFull, getBedroomsTotal, getAddress, getLivingArea, getMLSSource, COMPS_PULLER_VISUALS, getSparkStatusQuery, addSubjectPropertyToComps, getSelectedComps, } from "./utils";
const bridgeDatasets = bridgePropertyDatasets.map((dataset) => {
    return dataset.toLowerCase().trim();
});
const mlses = new Set([...bridgeDatasets, "canopy", "flexmls"]);
const DEFAULT_SQFT_VARIANCE = 0.1;
// date constants
const TODAY = new Date();
const SIX_MONTHS_AGO = dayjs_(new Date(TODAY)).add(-6, "months").format("YYYY-MM-DD");
const TWELVE_MONTHS_AGO = dayjs_(new Date(TODAY)).add(-12, "months").format("YYYY-MM-DD");
const EIGHTEEN_MONTHS_AGO = dayjs_(new Date(TODAY)).add(-18, "months").format("YYYY-MM-DD");
const TWENTY_FOUR_MONTHS_AGO = dayjs_(new Date(TODAY)).add(-24, "months").format("YYYY-MM-DD");
/** options for close date dropdown */
const CLOSE_DATE_OPTIONS = [
    {
        name: "6 Months",
        value: SIX_MONTHS_AGO,
    },
    {
        name: "12 Months",
        value: TWELVE_MONTHS_AGO,
    },
    {
        name: "18 Months",
        value: EIGHTEEN_MONTHS_AGO,
    },
    {
        name: "24 Months",
        value: TWENTY_FOUR_MONTHS_AGO,
    },
];
const CompsPullerWrapper = (props) => {
    var _a, _b, _c;
    const { addSubjectPropertyData, isAddingComps, isAddingSubjectProperty, onSelectionConfirmed, pspAddress, pspMlsId, pspUid, } = props;
    const [searchStatusMessage, setSearchStatusMessage] = React.useState(null);
    const [address, setAddress] = useStateWithCallback(null);
    const [mlsId, setMlsId] = useStateWithCallback(null);
    const [useSmartSearch, setUseSmartSearch] = React.useState(!!pspUid);
    const [sqftVariance, setSqftVariance] = React.useState(DEFAULT_SQFT_VARIANCE);
    const [distance, setDistance] = React.useState(0.5);
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [subjectProperty, setSubjectProperty] = React.useState(null);
    const [comps, setComps] = React.useState(null);
    const [refinedComps, setRefinedComps] = React.useState(null);
    const [params, setParams] = React.useState({
        status: null,
        property_sub_type: null,
        living_area__gte: null,
        living_area__lte: null,
        bathrooms_full__gte: null,
        bathrooms_full__lte: null,
        bedrooms_total__gte: null,
        bedrooms_total__lte: null,
        garage_spaces__gte: null,
        garage_spaces__lte: null,
        year_built__gte: null,
        year_built__lte: null,
        close_date__gte: null,
    });
    const [unableToFindSP, setUnableToFindSP] = React.useState(false);
    const [hideSearchParams, setHideSearchParams] = React.useState(false);
    const [expandTable, setExpandTable] = React.useState(false);
    const [currentVisual, setCurrentVisual] = React.useState("google map");
    const [photosCarouselData, setPhotosCarouselData] = React.useState(null);
    const [selectPropertiesDialogData, setSelectPropertiesDialogData] = React.useState(null);
    const [currentlyShowingDetailsForProperty, setCurrentlyShowingDetailsForProperty] = React.useState(null);
    const [searchingMLSesForComps, setSearchingMLSesForComps] = useStateWithCallback(null);
    const errorDialog = useErrorDialog();
    const selectedAndRefinedComps = React.useMemo(() => {
        if (!comps) {
            return [];
        }
        return getSelectedComps(comps, refinedComps !== null && refinedComps !== void 0 ? refinedComps : undefined);
    }, [comps, refinedComps]);
    const getDefaultFilters = React.useCallback((propertyData) => {
        const currentYear = parseInt(dayjs_().format("YYYY"), 10);
        const source = getMLSSource(propertyData);
        const livingArea = getLivingArea(propertyData, source);
        const garageSpaces = propertyData["GarageSpaces"];
        const bedrooms = propertyData["BedroomsTotal"];
        const bathrooms = propertyData["BathroomsFull"];
        const yearBuilt = propertyData["YearBuilt"];
        const defaults = {
            property_type: "Residential",
            living_area__gte: livingArea != null ? livingArea * (1 - (sqftVariance !== null && sqftVariance !== void 0 ? sqftVariance : DEFAULT_SQFT_VARIANCE)) : null,
            living_area__lte: livingArea != null ? livingArea * (1 + (sqftVariance !== null && sqftVariance !== void 0 ? sqftVariance : DEFAULT_SQFT_VARIANCE)) : null,
            bathrooms_full__gte: bathrooms != null ? Math.max(bathrooms - 1, 0) : null,
            bathrooms_full__lte: bathrooms != null ? bathrooms + 1 : null,
            garage_spaces__gte: garageSpaces != null ? Math.max(garageSpaces - 1, 0) : null,
            garage_spaces__lte: garageSpaces != null ? garageSpaces + 1 : null,
            bedrooms_total__gte: bedrooms != null ? Math.max(bedrooms - 1, 0) : null,
            bedrooms_total__lte: bedrooms != null ? bedrooms + 1 : null,
            year_built__gte: yearBuilt != null ? yearBuilt - 5 : null,
            year_built__lte: yearBuilt != null ? yearBuilt + 5 : null,
            close_date__gte: null,
        };
        if (defaults.year_built__lte !== null && defaults.year_built__lte > currentYear) {
            defaults.year_built__lte = currentYear;
        }
        return defaults;
    }, [sqftVariance]);
    const onSubjectPropertyChange = React.useCallback((spData) => {
        // `property_data` is returned by MLS Grid endpoint
        // const data_ = convertAsterisksToNull(data.property_data ?? data);
        const data_ = convertAsterisksToNull(spData.data);
        console.log("Set subject Property: ", data_);
        const spAddress = getAddress(data_);
        const spMlsId = data_["ListingId"];
        const defaults = getDefaultFilters(data_);
        const sp = {
            address: spAddress,
            mlsnumber: spMlsId,
            selected: false,
            highlighted: false,
            price: data_["ListPrice"],
            data: data_,
            source: getMLSSource(data_),
        };
        if (address !== spAddress) {
            setAddress(spAddress);
        }
        if (mlsId !== spMlsId) {
            setMlsId(spMlsId);
        }
        setUnableToFindSP(false);
        setSearchStatusMessage(null);
        setLatitude(data_["Latitude"]);
        setLongitude(data_["Longitude"]);
        setSubjectProperty(sp);
        setParams((prev) => {
            return Object.assign(Object.assign({}, prev), defaults);
        });
    }, [address, getDefaultFilters, mlsId, setAddress, setMlsId]);
    const getSubjectProperty = React.useCallback((spAddress, mlsnumber) => {
        console.log(`getting subject property for address ${spAddress} / mls id ${mlsnumber}`);
        setUnableToFindSP(false);
        if (!!spAddress || !!mlsnumber) {
            searchMlsesByAddress(pickBy({
                listing_id: mlsnumber,
                address: spAddress,
            }, (val) => val != null))
                .then((result) => {
                console.log("Got subject property from MLS API", result.data);
                const potentialSubjectProperties = result.data.map((listing) => {
                    return {
                        address: listing.formatted_address,
                        mlsnumber: listing.listing_id,
                        selected: false,
                        highlighted: false,
                        price: listing.list_price,
                        data: listing.property_data,
                        source: listing.source,
                    };
                });
                console.log("Got subject property data:", potentialSubjectProperties);
                if (potentialSubjectProperties.length === 0) {
                    // if no subject property is found, alert the user
                    setSearchStatusMessage("Unable to find subject property via Bridge API, Spark API, or MLS Grid");
                    setUnableToFindSP(true);
                    setUseSmartSearch(false);
                }
                else if (potentialSubjectProperties.length > 1) {
                    // if multiple subject properties are found, ask the user to select one
                    setSelectPropertiesDialogData(potentialSubjectProperties);
                    setUnableToFindSP(false);
                }
                else {
                    onSubjectPropertyChange(potentialSubjectProperties[0]);
                }
            })
                .catch((error) => {
                var _a, _b, _c;
                console.error("error getting subject property from mls", error);
                const errorMessage = (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : undefined;
                setSearchStatusMessage(`There was an error searching the MLS for the subject property: ${errorMessage !== null && errorMessage !== void 0 ? errorMessage : ""}`);
            });
        }
        else {
            console.warn("No address or mls number provided to search for subject property.");
        }
    }, [onSubjectPropertyChange]);
    React.useEffect(() => {
        /**
         * Get the subject property using, in order of precedence:
         * - the `mlsId`
         * - the `address`
         * - extract the address and/or MLS ID from the PSP using the `pspUid`
         */
        if (pspMlsId) {
            setMlsId(pspMlsId, () => {
                getSubjectProperty(undefined, pspMlsId);
            });
        }
        else if (pspAddress) {
            setAddress(pspAddress, () => {
                getSubjectProperty(pspAddress, undefined);
            });
        }
        else if (pspUid) {
            getPsp(pspUid)
                .then((result) => {
                const pspPropertyAddress = result.data.fields["Property Address"];
                const mlsId_ = result.data.fields["MLS ID #"];
                setAddress(pspPropertyAddress !== null && pspPropertyAddress !== void 0 ? pspPropertyAddress : null, () => {
                    getSubjectProperty(mlsId_ ? undefined : pspPropertyAddress, mlsId_);
                });
            })
                .catch((error) => {
                setSearchStatusMessage(`Encoauntered an error when getting the property address from the MAtrix: ${error}`);
                setSubjectProperty(null);
            });
        }
        else {
            setSearchStatusMessage("Please enter an address or mls number and parameters to search for.");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pspUid, pspAddress, pspMlsId]);
    /** Create Spark query string from current parameters */
    const sparkQuery = React.useMemo(() => {
        const queryArr = [];
        const quotedParams = ["PropertyType", "PropertySubType"];
        const sparkValueMapping = {
            Residential: "A",
        };
        Object.keys(params)
            .filter((key) => key !== "status")
            .forEach((key) => {
            let sparkValue = params[key];
            if (sparkValue) {
                let sparkName = key.split("__")[0];
                let operator = "Eq";
                if (key.endsWith("__lte")) {
                    operator = "Le";
                }
                else if (key.endsWith("__gte")) {
                    operator = "Ge";
                }
                else if (key.endsWith("__lt")) {
                    operator = "Lt";
                }
                else if (key.endsWith("__gt")) {
                    operator = "Gt";
                }
                if (sparkName.includes("bathrooms_full")) {
                    sparkName = sparkName.replace("bathrooms_full", "BathsFull");
                }
                else if (sparkName.includes("bedrooms_total")) {
                    sparkName = sparkName.replace("bedrooms_total", "BedsTotal");
                }
                else if (sparkName === "property_sub_type") {
                    sparkName = "PropertySubType";
                }
                else {
                    const firstName = sparkName.split("_")[0].charAt(0).toUpperCase() + sparkName.split("_")[0].slice(1);
                    const secondName = sparkName.includes("_")
                        ? sparkName.split("_")[1].charAt(0).toUpperCase() + sparkName.split("_")[1].slice(1)
                        : "";
                    sparkName = firstName + secondName;
                }
                if (sparkName === "PropertyType") {
                    sparkValue = sparkValueMapping[sparkValue];
                }
                if (quotedParams.includes(sparkName)) {
                    sparkValue = `'${sparkValue}'`;
                }
                if (sparkName === "PropertySubType" && params[key].toLowerCase().trim() === "manufactured") {
                    queryArr.push(`tolower(${sparkName}) ${operator} tolower(${sparkValue.trim()})`);
                }
                else {
                    queryArr.push(`${sparkName} ${operator} ${sparkValue}`);
                }
            }
        });
        if (params.status === "Active") {
            queryArr.push(`(${getSparkStatusQuery(activeStatuses)})`);
        }
        else if (params.status === "Pending") {
            queryArr.push(`(${getSparkStatusQuery(pendingStatuses)})`);
        }
        else if (params.status === "Closed") {
            queryArr.push(`(${getSparkStatusQuery(closedStatuses)})`);
        }
        if (distance) {
            const lat = subjectProperty ? subjectProperty.data["Latitude"] : latitude;
            const lng = subjectProperty ? subjectProperty.data["Longitude"] : longitude;
            const latLngDiff = getLatLng(lat, distance);
            queryArr.push(`Latitude Ge ${lat - latLngDiff.latDiff}`);
            queryArr.push(`Latitude Le ${lat + latLngDiff.latDiff}`);
            queryArr.push(`Longitude Ge ${lng - latLngDiff.lngDiff}`);
            queryArr.push(`Longitude Le ${lng + latLngDiff.lngDiff}`);
        }
        return queryArr.join(" AND ");
    }, [distance, latitude, longitude, params, subjectProperty]);
    const smartSearch = React.useCallback(() => {
        if (subjectProperty) {
            // use smart search
            setSearchStatusMessage("Using smart search...");
            setComps(selectedAndRefinedComps.length > 0 ? selectedAndRefinedComps : null);
            setRefinedComps(null);
            setSearchingMLSesForComps(new Set(mlses), () => {
                const query = pickBy({
                    standard_status: subjectProperty.data["StandardStatus"],
                    property_type: getPropertyType(subjectProperty.data),
                    property_sub_type: subjectProperty.data["PropertySubType"],
                    subdivision: subjectProperty.data["SubdivisionName"],
                    city: subjectProperty.data["City"],
                    state: subjectProperty.data["StateOrProvince"],
                    zip_code: subjectProperty.data["PostalCode"],
                    list_price: subjectProperty.data["ListPrice"],
                    latitude: subjectProperty.data["Latitude"],
                    longitude: subjectProperty.data["Longitude"],
                    living_area: getLivingArea(subjectProperty.data, subjectProperty.source),
                    bathrooms_full: getBathroomsFull(subjectProperty.data),
                    bedrooms_total: getBedroomsTotal(subjectProperty.data),
                    garage_spaces: subjectProperty.data["GarageSpaces"],
                    year_built: subjectProperty.data["YearBuilt"],
                }, (val) => val != null);
                pullComps(query)
                    .then((result) => {
                    const smartComps = result.data.map((property) => {
                        var _a;
                        const smartSearchPropertyData = Object.assign(Object.assign({}, property.property_data), { quality: property.quality });
                        return {
                            address: getAddress(smartSearchPropertyData),
                            mlsnumber: smartSearchPropertyData["ListingId"],
                            selected: false,
                            highlighted: false,
                            price: (_a = smartSearchPropertyData["ListPrice"]) !== null && _a !== void 0 ? _a : null,
                            data: smartSearchPropertyData,
                            source: getMLSSource(smartSearchPropertyData),
                        };
                    });
                    setComps((prev) => {
                        return addSubjectPropertyToComps([
                            ...(prev !== null && prev !== void 0 ? prev : []),
                            ...smartComps.filter((smartComp) => {
                                return !(prev === null || prev === void 0 ? void 0 : prev.some((comp) => comp.mlsnumber === smartComp.mlsnumber));
                            }),
                        ], subjectProperty);
                    });
                })
                    .catch((error) => {
                    errorDialog(error);
                })
                    .then(() => {
                    setSearchingMLSesForComps(null);
                    setSearchStatusMessage(null);
                });
            });
        }
        else {
            setSearchStatusMessage("No subject property to search with.");
            setComps(null);
        }
    }, [errorDialog, selectedAndRefinedComps, setSearchingMLSesForComps, subjectProperty]);
    const search = React.useCallback(() => {
        setSearchStatusMessage("Getting comps...");
        setComps(selectedAndRefinedComps.length > 0 ? selectedAndRefinedComps : null);
        setRefinedComps(null);
        setSearchingMLSesForComps(mlses, () => {
            let latitudeMin = null;
            let latitudeMax = null;
            let longitudeMin = null;
            let longitudeMax = null;
            let statuses = null;
            if (params.status === "Closed") {
                statuses = closedStatuses;
            }
            else if (params.status === "Pending") {
                statuses = pendingStatuses;
            }
            else if (params.status === "Active") {
                statuses = activeStatuses;
            }
            if (latitude !== null && longitude !== null) {
                const latLngDiff = getLatLng(latitude, distance);
                latitudeMin = latitude - latLngDiff.latDiff;
                latitudeMax = latitude + latLngDiff.latDiff;
                longitudeMin = longitude - latLngDiff.lngDiff;
                longitudeMax = longitude + latLngDiff.lngDiff;
            }
            // search Bridge
            bridgeDatasets.forEach((dataset) => {
                listBridgeProperties(dataset, pickBy({
                    statuses: statuses == null ? statuses : statuses.join(","),
                    property_type: "Residential",
                    property_sub_type: params.property_sub_type,
                    living_area_min: params.living_area__gte,
                    living_area_max: params.living_area__lte,
                    bathrooms_full_min: params.bathrooms_full__gte,
                    bathrooms_full_max: params.bathrooms_full__lte,
                    bedrooms_total_min: params.bedrooms_total__gte,
                    bedrooms_total_max: params.bedrooms_total__lte,
                    garage_spaces_min: params.garage_spaces__gte,
                    garage_spaces_max: params.garage_spaces__lte,
                    year_built_min: params.year_built__gte,
                    year_built_max: params.year_built__lte,
                    close_date_min: dataset.toLowerCase().trim() !== "triangle" ? params.close_date__gte : null,
                    closed_after: dataset.toLowerCase().trim() === "triangle" ? params.close_date__gte : null, // See [here](https://dashcarolina.atlassian.net/jira/core/projects/DC/board?filter=assignee%20%3D%20%2261f0067f58ce4f006881f6b2%22&selectedIssue=DC-163). Triangle filter needs to match Paragon
                    latitude_min: latitudeMin,
                    latitude_max: latitudeMax,
                    longitude_min: longitudeMin,
                    longitude_max: longitudeMax,
                    limit: 200,
                }, (val) => val != null))
                    .then((result) => {
                    const bridgeComps = result.data.map((property) => {
                        var _a;
                        return ({
                            address: getAddress(property),
                            mlsnumber: property["ListingId"],
                            selected: false,
                            highlighted: false,
                            price: (_a = property["ListPrice"]) !== null && _a !== void 0 ? _a : null,
                            data: property,
                            source: getMLSSource(property),
                        });
                    });
                    console.log("total bridge comps using ", dataset, "dataset:", bridgeComps);
                    setComps((prev) => {
                        return addSubjectPropertyToComps([
                            ...(prev !== null && prev !== void 0 ? prev : []),
                            ...bridgeComps.filter((bridgeComp) => {
                                return !(prev === null || prev === void 0 ? void 0 : prev.some((comp) => comp.mlsnumber === bridgeComp.mlsnumber));
                            }),
                        ], subjectProperty);
                    });
                })
                    .catch((error) => {
                    var _a, _b;
                    const bridgeErrorStatus = ((_a = error.result) === null || _a === void 0 ? void 0 : _a.status) || ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) || error.status;
                    console.error(`Error ${error.status} searching dataset '${dataset}': ${error.message}. Full error: ${error}`);
                    if (bridgeErrorStatus !== 401) {
                        errorDialog(error);
                    }
                })
                    .then(() => {
                    setSearchingMLSesForComps((prev) => {
                        const remainingMLSes = prev ? new Set(prev) : null;
                        if (!remainingMLSes) {
                            return null;
                        }
                        remainingMLSes.delete(dataset);
                        return remainingMLSes;
                    });
                });
            });
            // search Spark
            console.log("Search Spark with params: ", sparkQuery);
            listSparkProperties({
                filter_params: sparkQuery,
                limit: 100,
            })
                .then((result) => {
                const sparkComps = result.data.map((property) => {
                    var _a;
                    const sparkPropertyData = property["StandardFields"];
                    return {
                        address: getAddress(sparkPropertyData),
                        mlsnumber: sparkPropertyData["ListingId"],
                        selected: false,
                        highlighted: false,
                        price: (_a = sparkPropertyData["ListPrice"]) !== null && _a !== void 0 ? _a : null,
                        data: sparkPropertyData,
                        source: getMLSSource(sparkPropertyData),
                    };
                });
                console.log("total spark comps:", sparkComps);
                setComps((prev) => {
                    return addSubjectPropertyToComps([
                        ...(prev !== null && prev !== void 0 ? prev : []),
                        ...sparkComps.filter((sparkComp) => {
                            return !(prev === null || prev === void 0 ? void 0 : prev.some((comp) => comp.mlsnumber === sparkComp.mlsnumber));
                        }),
                    ], subjectProperty);
                });
            })
                .catch((error) => {
                var _a, _b;
                console.error("error getting spark comps:", error);
                const sparkErrorStatus = ((_a = error.result) === null || _a === void 0 ? void 0 : _a.status) || ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) || error.status;
                if (sparkErrorStatus !== 401) {
                    errorDialog(error);
                }
            })
                .then(() => {
                setSearchingMLSesForComps((prev) => {
                    const remainingMLSes = prev ? new Set(prev) : null;
                    if (!remainingMLSes) {
                        return null;
                    }
                    remainingMLSes.delete("flexmls");
                    return remainingMLSes;
                });
            });
            // search MLS Grid
            listMlsGridListings(pickBy({
                statuses: statuses == null ? statuses : statuses.join(","),
                property_type: "Residential",
                property_sub_type: params.property_sub_type,
                living_area__gte: params.living_area__gte,
                living_area__lte: params.living_area__lte,
                bathrooms_full__gte: params.bathrooms_full__gte,
                bathrooms_full__lte: params.bathrooms_full__lte,
                bedrooms_total__gte: params.bedrooms_total__gte,
                bedrooms_total__lte: params.bedrooms_total__lte,
                garage_spaces__gte: params.garage_spaces__gte,
                garage_spaces__lte: params.garage_spaces__lte,
                year_built__gte: params.year_built__gte,
                year_built__lte: params.year_built__lte,
                close_date__gte: params.close_date__gte,
                latitude: subjectProperty ? subjectProperty.data["Latitude"] : latitude,
                longitude: subjectProperty ? subjectProperty.data["Longitude"] : longitude,
                // TODO: this is a ROUGH conversion of radius to degrees. Need to implement a function based on lat and lng.
                radius: distance != null ? distance / 68.9393939 : distance,
            }, (val) => val != null))
                .then((result) => {
                const mlsGridComps = result.data.map((property) => {
                    var _a;
                    const mlsGridPropertyData = property.property_data;
                    return {
                        address: getAddress(mlsGridPropertyData),
                        mlsnumber: mlsGridPropertyData["ListingId"],
                        selected: false,
                        highlighted: false,
                        price: (_a = mlsGridPropertyData["ListPrice"]) !== null && _a !== void 0 ? _a : null,
                        data: mlsGridPropertyData,
                        source: getMLSSource(mlsGridPropertyData),
                    };
                });
                console.log("total MLS Grid comps:", mlsGridComps);
                setComps((prev) => {
                    return addSubjectPropertyToComps([
                        ...(prev !== null && prev !== void 0 ? prev : []),
                        ...mlsGridComps.filter((mlsGridComp) => {
                            return !(prev === null || prev === void 0 ? void 0 : prev.some((comp) => comp.mlsnumber === mlsGridComp.mlsnumber));
                        }),
                    ], subjectProperty);
                });
            })
                .catch((error) => {
                errorDialog(error);
            })
                .then(() => {
                setSearchingMLSesForComps((prev) => {
                    const remainingMLSes = prev ? new Set(prev) : null;
                    if (!remainingMLSes) {
                        return null;
                    }
                    remainingMLSes.delete("canopy");
                    return remainingMLSes;
                });
            });
        });
    }, [
        distance,
        errorDialog,
        latitude,
        longitude,
        params.bathrooms_full__gte,
        params.bathrooms_full__lte,
        params.bedrooms_total__gte,
        params.bedrooms_total__lte,
        params.close_date__gte,
        params.garage_spaces__gte,
        params.garage_spaces__lte,
        params.living_area__gte,
        params.living_area__lte,
        params.property_sub_type,
        params.status,
        params.year_built__gte,
        params.year_built__lte,
        selectedAndRefinedComps,
        setSearchingMLSesForComps,
        sparkQuery,
        subjectProperty,
    ]);
    const onAddressChange = React.useCallback((newAddress) => {
        if (newAddress !== address) {
            setMlsId(null);
            setLatitude(null);
            setLongitude(null);
            setSubjectProperty(null);
            setComps(null);
            setSearchStatusMessage(null);
            setUnableToFindSP(false);
            setAddress(newAddress, () => {
                if (newAddress) {
                    getSubjectProperty(newAddress, undefined);
                }
            });
        }
    }, [address, getSubjectProperty, setAddress, setMlsId]);
    const onMlsIdChange = React.useCallback((newMlsId) => {
        if (newMlsId !== mlsId) {
            setAddress(null);
            setLatitude(null);
            setLongitude(null);
            setSubjectProperty(null);
            setComps(null);
            setSearchStatusMessage(null);
            setUnableToFindSP(false);
            setMlsId(newMlsId, () => {
                if (newMlsId) {
                    getSubjectProperty(undefined, newMlsId);
                }
            });
        }
    }, [getSubjectProperty, mlsId, setAddress, setMlsId]);
    const toggleSmartSearch = React.useCallback(() => {
        setUseSmartSearch((prevState) => !prevState);
    }, []);
    const onSqftVarianceChange = React.useCallback((variance) => {
        setSqftVariance(variance);
        setParams((prev) => {
            const sqft = subjectProperty && variance && getLivingArea(subjectProperty.data, subjectProperty.source)
                ? getLivingArea(subjectProperty.data, subjectProperty.source)
                : null;
            return Object.assign(Object.assign({}, prev), { living_area__gte: sqft && variance ? sqft * (1 - variance) : null, living_area__lte: sqft && variance ? sqft * (1 + variance) : null });
        });
    }, [subjectProperty]);
    const setCloseDateMin = React.useCallback((value) => {
        setParams((prevState) => (Object.assign(Object.assign({}, prevState), { close_date__gte: value != null && value.trim() !== "" ? value : null, status: value ? "Closed" : prevState.status })));
    }, []);
    /**
     * change distance, if subject property is defined, update lat/lng params
     * @param distance
     */
    const onDistanceChange = React.useCallback((newDistance) => {
        setDistance(newDistance);
    }, []);
    /**
     * if subject property could not be found, user can geocode property address to get latitude/longitude
     * @param latitude
     * @param longitude
     */
    const geocodeAddress = React.useCallback(() => {
        if (address) {
            geocodeWithGoogleMaps({ address })
                .then((result) => {
                setLatitude(result.data.latitude);
                setLongitude(result.data.longitude);
            })
                .catch((error) => {
                errorDialog(error);
            });
        }
        else {
            setLatitude(null);
            setLongitude(null);
            setSearchStatusMessage("An address is needed to find latitude and longitude.");
        }
    }, [address, errorDialog]);
    /**
     * if subject property could not be found, user can enter a latidude to search by
     * @param lat
     */
    const onLatitudeChange = React.useCallback((lat) => {
        setLatitude(lat);
    }, []);
    /**
     * if subject property could not be found, user can enter a longitude to search by
     * @param lng
     */
    const onLongitudeChange = React.useCallback((lng) => {
        setLongitude(lng);
    }, []);
    /**
     * matches property by listing id then toggles selected state
     * @param mlsnumber
     */
    const toggleSelected = React.useCallback((mlsnumber) => {
        setComps((prev) => {
            return (prev !== null && prev !== void 0 ? prev : []).map((comp) => {
                if (comp.data["ListingId"] === mlsnumber) {
                    return Object.assign(Object.assign({}, comp), { selected: !comp.selected });
                }
                return comp;
            });
        });
    }, []);
    /**
     * show which properties are within the map's search polygon
     * @param mlsnumbers list of mlsnumbers for properties within search polygon
     */
    const highlightProperties = React.useCallback((mlsnumbers) => {
        setComps((prev) => {
            return (prev !== null && prev !== void 0 ? prev : []).map((comp) => {
                return Object.assign(Object.assign({}, comp), { highlighted: mlsnumbers.includes(comp.data["ListingId"]) });
            });
        });
    }, []);
    const setFieldValue = React.useCallback((name) => (value) => {
        setParams((prev) => {
            return Object.assign(Object.assign({}, prev), { [name]: value === "" ? null : value });
        });
    }, []);
    /**
     * set all params to null, clear comps
     */
    const clearFields = React.useCallback(() => {
        setParams((prev) => {
            const newParams = prev;
            Object.keys(newParams).forEach((key) => {
                newParams[key] = null;
            });
            return newParams;
        });
    }, []);
    const toggleRefinedComps = React.useCallback(() => {
        setRefinedComps((prev) => {
            return prev ? null : (comps !== null && comps !== void 0 ? comps : []).filter((comp) => comp.selected).map((comp) => comp.mlsnumber);
        });
    }, [comps]);
    const openPhotos = React.useCallback((photoAddress, photos) => {
        setPhotosCarouselData({
            photos,
            address: photoAddress,
        });
    }, []);
    const closePhotos = React.useCallback(() => {
        setPhotosCarouselData(null);
    }, []);
    const showDetails = React.useCallback((property) => {
        setCurrentlyShowingDetailsForProperty(property);
    }, []);
    const hideDetails = React.useCallback(() => {
        setCurrentlyShowingDetailsForProperty(null);
    }, []);
    const collapseParams = React.useCallback(() => {
        setHideSearchParams((prev) => !prev);
    }, []);
    const toggleCollapseMap = React.useCallback(() => {
        setExpandTable((prev) => !prev);
    }, []);
    const nextVisual = React.useMemo(() => {
        const currentIndex = COMPS_PULLER_VISUALS.findIndex((option) => option === currentVisual);
        return currentIndex === -1
            ? COMPS_PULLER_VISUALS[0]
            : COMPS_PULLER_VISUALS[(currentIndex + 1) % COMPS_PULLER_VISUALS.length];
    }, [currentVisual]);
    const changeCurrentVisual = React.useCallback(() => {
        setCurrentVisual(nextVisual);
    }, [nextVisual]);
    const onAddressKeyDown = React.useCallback((e) => {
        if (e.key.toLowerCase().trim() === "enter") {
            onAddressChange(e.target.value);
            e.preventDefault();
        }
    }, [onAddressChange]);
    const onMLSKeyDown = React.useCallback((e) => {
        if (e.key.toLowerCase().trim() === "enter") {
            onMlsIdChange(e.target.value);
            e.preventDefault();
        }
    }, [onMlsIdChange]);
    const closeSelectPropertiesTable = React.useCallback(() => {
        setSelectPropertiesDialogData(null);
    }, []);
    const paramFields = React.useMemo(() => {
        var _a, _b;
        const stackedFields = [
            {
                name: "bathrooms_full",
                label: "Full Baths",
            },
            {
                name: "garage_spaces",
                label: "Garages",
            },
            {
                name: "bedrooms_total",
                label: "Bed Count",
            },
        ].map((field) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, field), { element: (React.createElement(StackedFields, { firstLabel: `${field.label} Min:`, secondLabel: `${field.label} Max:`, firstField: React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: (_a = params[`${field.name}__gte`]) !== null && _a !== void 0 ? _a : null, onNumberChange: setFieldValue(`${field.name}__gte`), format: "0,0", min: 0, max: 99, disabled: useSmartSearch }), secondField: React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: (_b = params[`${field.name}__lte`]) !== null && _b !== void 0 ? _b : null, onNumberChange: setFieldValue(`${field.name}__lte`), format: "0,0", min: 0, max: 99, disabled: useSmartSearch }) })) }));
        });
        return [
            {
                name: "lat/lng",
                label: "Latitude/Longitude",
                element: (React.createElement(StackedFields, { firstLabel: "Lat:", secondLabel: "Lng:", firstField: React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: latitude, onNumberChange: onLatitudeChange, format: "0,0.000000" }), secondField: React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: longitude, onNumberChange: onLongitudeChange, format: "0,0.000000" }) })),
            },
            {
                name: "status",
                label: "MLS Status",
                options: ["Active", "Closed", "Pending"],
            },
            {
                name: "property_sub_type",
                label: "Property Type",
                options: ["Attached", "Condo", "Detached", "Manufactured"],
            },
            {
                name: "distance",
                label: "Select Distance",
                element: (React.createElement(CoreNumberField, { variant: "standard", label: "Distance", fullWidth: true, size: "small", number: distance, onNumberChange: onDistanceChange, format: "0,0.00", disabled: useSmartSearch, InputProps: {
                        endAdornment: React.createElement(InputAdornment, { position: "end" }, "mi"),
                    } })),
            },
            {
                name: "sqft_variance",
                label: "SqFt Variance",
                element: (React.createElement(Box, { display: "flex" },
                    React.createElement(Box, { mt: "auto", mb: "auto" },
                        React.createElement(CoreNumberField, { variant: "standard", label: "SqFt Variance", fullWidth: true, size: "small", number: sqftVariance !== null && sqftVariance !== void 0 ? sqftVariance : null, format: "0,0%", onNumberChange: onSqftVarianceChange, min: 0, max: 999999, disabled: useSmartSearch || unableToFindSP })),
                    React.createElement(Box, null,
                        React.createElement(Box, { display: "flex" },
                            React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto" } }, "Low:"),
                            React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: params.living_area__gte, format: "0,0", onNumberChange: setFieldValue("living_area__gte"), min: 0, max: 999999, disabled: useSmartSearch })),
                        React.createElement(Box, { display: "flex" },
                            React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto" } }, "High:"),
                            React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: params.living_area__lte, format: "0,0", onNumberChange: setFieldValue("living_area__lte"), min: 0, max: 999999, disabled: useSmartSearch }))))),
            },
            ...stackedFields,
            {
                name: "year_built",
                label: "Year Built",
                element: (React.createElement(React.Fragment, null,
                    React.createElement(Box, { display: "flex" },
                        React.createElement(Box, { whiteSpace: "nowrap" },
                            React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto", fontSize: "10px" } }, "Year Built Min:")),
                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: params.year_built__gte, format: "00", onNumberChange: setFieldValue("year_built__gte"), disabled: useSmartSearch })),
                    React.createElement(Box, { display: "flex" },
                        React.createElement(Box, { whiteSpace: "nowrap" },
                            React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto", fontSize: "10px" } }, "Year Built Max:")),
                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: params.year_built__lte, format: "00", onNumberChange: setFieldValue("year_built__lte"), disabled: useSmartSearch })))),
            },
            {
                name: "close_date",
                label: "Close Date",
                element: (React.createElement(Box, { display: "flex" },
                    React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(CoreChoiceField, { variant: "standard", label: "Closed Since", fullWidth: true, size: "small", value: params.close_date__gte
                                    ? ((_b = (_a = CLOSE_DATE_OPTIONS.find((option) => option.value === params.close_date__gte)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "")
                                    : "", onChoiceChange: setCloseDateMin, disabled: useSmartSearch, SelectProps: {
                                    displayEmpty: true,
                                } },
                                React.createElement(MenuItem, { value: "" }),
                                CLOSE_DATE_OPTIONS.map((option) => (React.createElement(MenuItem, { key: option.name, value: option.value }, option.name))))),
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(CoreDateField, { variant: "standard", fullWidth: true, size: "small", date: params.close_date__gte, onDateChange: setCloseDateMin, format: "M/D/YYYY", disabled: useSmartSearch }))))),
            },
        ];
    }, [
        distance,
        latitude,
        longitude,
        onDistanceChange,
        onLatitudeChange,
        onLongitudeChange,
        onSqftVarianceChange,
        params,
        setCloseDateMin,
        setFieldValue,
        sqftVariance,
        unableToFindSP,
        useSmartSearch,
    ]);
    /** properties to show on map */
    const manualSubjectProperty = React.useMemo(() => {
        return {
            address: address !== null && address !== void 0 ? address : "",
            mlsnumber: "n/a",
            data: {
                Latitude: latitude,
                Longitude: longitude,
            },
            source: "manual",
        };
    }, [address, latitude, longitude]);
    /** Zoom level for map visuals. Will vary based on search radius */
    const mapZoom = React.useMemo(() => {
        const defaultZoom = 14.5;
        return defaultZoom - (distance !== null && distance !== void 0 ? distance : 0) + 0.5;
    }, [distance]);
    const tableProperties = React.useMemo(() => {
        return comps !== null && comps !== void 0 ? comps : [];
    }, [comps]);
    /** Listings formatted for the visuals */
    const visualsListings = React.useMemo(() => {
        let visualsListings_ = (subjectProperty !== null && subjectProperty !== void 0 ? subjectProperty : manualSubjectProperty).source === "manual"
            ? [...(comps !== null && comps !== void 0 ? comps : []), (subjectProperty !== null && subjectProperty !== void 0 ? subjectProperty : manualSubjectProperty)]
            : (comps !== null && comps !== void 0 ? comps : []);
        visualsListings_ = visualsListings_.filter((property) => !refinedComps ||
            refinedComps.includes(property.mlsnumber) ||
            property.mlsnumber === (subjectProperty !== null && subjectProperty !== void 0 ? subjectProperty : manualSubjectProperty).mlsnumber);
        return visualsListings_;
    }, [comps, manualSubjectProperty, refinedComps, subjectProperty]);
    console.log({ comps });
    console.log({ refinedComps });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { p: 2, style: { height: "100%" } },
            React.createElement(Grid2, { container: true, alignContent: "flex-start", style: {
                    height: "100%",
                    position: "relative",
                } },
                selectPropertiesDialogData && (React.createElement(Grid2, { container: true, size: 12, style: { marginBottom: "16px" } },
                    React.createElement(CompsPullerSelectPropertyTable, { properties: selectPropertiesDialogData, hasSelectedSubjectProperty: !!subjectProperty, onSelectProperty: onSubjectPropertyChange, onClose: closeSelectPropertiesTable }),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Divider, null)))),
                React.createElement(Grid2, { container: true, size: 12 },
                    !pspUid && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, { size: { xs: 12, md: 6 }, style: { display: "flex" } },
                            React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto" } }, "Address:"),
                            React.createElement(CoreTextField, { variant: "standard", fullWidth: true, style: {
                                    maxWidth: "300px",
                                }, value: address !== null && address !== void 0 ? address : "", onTextChange: onAddressChange, onKeyDown: onAddressKeyDown })),
                        React.createElement(Grid2, { size: { xs: 12, md: 6 }, style: { display: "flex" } },
                            React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto" } }, "MLS #:"),
                            React.createElement(CoreTextField, { variant: "standard", fullWidth: true, style: {
                                    maxWidth: "300px",
                                }, value: mlsId !== null && mlsId !== void 0 ? mlsId : "", onTextChange: onMlsIdChange, onKeyDown: onMLSKeyDown })))),
                    React.createElement(Grid2, { container: true, size: 12 },
                        React.createElement(Grid2, { style: {
                                width: "25px",
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginRight: "10px",
                            } },
                            React.createElement(Tooltip, { title: hideSearchParams ? "Show Search Fields" : "Hide Search Fields" },
                                React.createElement(IconButton, { size: "small", onClick: collapseParams }, hideSearchParams ? React.createElement(KeyboardArrowRight, null) : React.createElement(KeyboardArrowLeft, null)))),
                        React.createElement(Grid2, { style: { width: "calc(100% - 35px)" } },
                            React.createElement(Collapse, { in: !hideSearchParams, style: { width: "100%" } },
                                React.createElement(Grid2, { container: true },
                                    React.createElement(Grid2, { style: {
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                            width: "150px",
                                        } },
                                        React.createElement(Grid2, { container: true, spacing: 1 },
                                            React.createElement(FormControlLabel, { label: "Use Smart Search", control: React.createElement(Checkbox, { checked: useSmartSearch, color: "primary", onChange: toggleSmartSearch, disabled: unableToFindSP }) }),
                                            React.createElement(Grid2, { size: 12 },
                                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Get latitude/longitude from property address") },
                                                    React.createElement("span", null,
                                                        React.createElement(Button, { variant: "contained", color: "primary", disabled: !address || ((_a = searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.size) !== null && _a !== void 0 ? _a : 0) > 0, onClick: geocodeAddress }, "Geocode")))))),
                                    React.createElement(Grid2, { style: { flex: 1, overflowY: "hidden" } },
                                        React.createElement(Grid2, { container: true, spacing: 1, style: { flexWrap: "inherit", display: "-webkit-box" } }, paramFields.map((field) => {
                                            var _a, _b;
                                            return (React.createElement(Grid2, { key: field.name, style: {
                                                    width: "150px",
                                                } },
                                                React.createElement(Box, { p: 2, pb: 5, border: "1px solid rgba(150, 150, 150, 0.5)", borderRadius: "0.5em" },
                                                    field.element,
                                                    !field.element && field.options && (React.createElement(CoreChoiceField, { variant: "standard", label: field.label, fullWidth: true, size: "small", value: (_a = params[field.name]) !== null && _a !== void 0 ? _a : null, onChoiceChange: setFieldValue(field.name), disabled: useSmartSearch, SelectProps: {
                                                            displayEmpty: true,
                                                        }, InputLabelProps: {
                                                            shrink: true,
                                                        } },
                                                        React.createElement(MenuItem, { value: "" }, "Any"),
                                                        field.options.map((item) => (React.createElement(MenuItem, { key: item, value: item }, item))))),
                                                    !field.element && ((_b = field.format) === null || _b === void 0 ? void 0 : _b.includes("0")) && (React.createElement(CoreNumberField, { variant: "standard", label: field.label, fullWidth: true, size: "small", number: params[field.name], format: field.format, onNumberChange: setFieldValue(field.name), min: 0, max: 999999, disabled: useSmartSearch })))));
                                        }))),
                                    React.createElement(Grid2, { style: {
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                            width: "70px",
                                        } },
                                        React.createElement(Grid2, { container: true, spacing: 1 },
                                            React.createElement(Grid2, { size: 12 },
                                                React.createElement(Button, { variant: "contained", color: "primary", disabled: (!subjectProperty && !latitude && !longitude) || ((_b = searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.size) !== null && _b !== void 0 ? _b : 0) > 0, onClick: useSmartSearch ? smartSearch : search }, "Apply")),
                                            React.createElement(Grid2, { size: 12 },
                                                React.createElement(Button, { color: "inherit", onClick: clearFields, disabled: useSmartSearch }, "Clear"))))))))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                subjectProperty && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(PropertyInfoBar, { subjectProperty: subjectProperty, pspUid: pspUid, openPhotos: openPhotos, showDetails: showDetails, addSubjectPropertyData: addSubjectPropertyData, isAddingSubjectProperty: isAddingSubjectProperty })),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Divider, null)))),
                ((searchingMLSesForComps && searchingMLSesForComps.size > 0) || (searchStatusMessage && !comps)) && (React.createElement(Grid2, { container: true, spacing: 2, size: 12 },
                    ((searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.has("triangle")) || (searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.has("triadmls"))) && (React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "caption" }, "Searching Bridge..."))),
                    (searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.has("canopy")) && (React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "caption" }, "Searching MLS Grid..."))),
                    (searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.has("flexmls")) && (React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "caption" }, "Searching Spark..."))),
                    ((_c = searchingMLSesForComps === null || searchingMLSesForComps === void 0 ? void 0 : searchingMLSesForComps.size) !== null && _c !== void 0 ? _c : 0) > 0 && (React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(CoreLoading, null))),
                    searchStatusMessage && (React.createElement(Grid2, { size: 12, style: { textAlign: "center" } },
                        React.createElement(Typography, { variant: "caption" }, searchStatusMessage))))),
                React.createElement(React.Fragment, null,
                    comps && comps.length > 0 && (React.createElement(Grid2, { container: true, style: {
                            height: hideSearchParams ? "calc(100% - 50px)" : "calc(100% - 120px)",
                            paddingBottom: "0px",
                        } },
                        React.createElement(Grid2, { style: { width: expandTable ? "50px" : "450px", height: "100%", overflow: "hidden" } },
                            React.createElement(Box, { position: "fixed", zIndex: 1 },
                                React.createElement(Tooltip, { title: expandTable ? "Show Map" : "Hide Map" },
                                    React.createElement(Fab, { size: "small", onClick: toggleCollapseMap, style: {
                                            display: "block",
                                            margin: "4px",
                                        } }, expandTable ? React.createElement(KeyboardArrowRight, { fontSize: "small" }) : React.createElement(KeyboardArrowLeft, { fontSize: "small" }))),
                                React.createElement(Tooltip, { title: `Show ${toTitleCase(nextVisual)}` },
                                    React.createElement(Fab, { size: "small", onClick: changeCurrentVisual, style: {
                                            display: "block",
                                            margin: "4px",
                                        } },
                                        currentVisual === "google map" && React.createElement(AddLocation, { fontSize: "small" }),
                                        currentVisual === "arcgis map" && React.createElement(Timeline, { fontSize: "small" }),
                                        currentVisual === "scatterplot" && React.createElement(AddLocation, { fontSize: "small" })))),
                            !expandTable && (React.createElement(Box, { style: { height: "100%" } },
                                currentVisual === "scatterplot" && (React.createElement(CompsPullerAgedPropertyRegression, { properties: visualsListings })),
                                currentVisual === "google map" && (React.createElement(CompsPullerGoogleMap, { properties: visualsListings, subjectProperty: subjectProperty !== null && subjectProperty !== void 0 ? subjectProperty : manualSubjectProperty, zoom: mapZoom, toggleSelected: toggleSelected })),
                                currentVisual === "arcgis map" && (React.createElement(CompsPullerArcGISMap, { properties: visualsListings, subjectProperty: subjectProperty !== null && subjectProperty !== void 0 ? subjectProperty : manualSubjectProperty, zoom: mapZoom, toggleSelected: toggleSelected, highlightProperties: highlightProperties }))))),
                        React.createElement(Grid2, { style: { width: expandTable ? "calc(100% - 50px)" : "calc(100% - 450px)", height: "100%" } },
                            React.createElement(CompsPullerTable, { properties: tableProperties, refinedComps: refinedComps, toggleSelected: toggleSelected, openPhotos: openPhotos, showDetails: showDetails, onSelectionConfirmed: onSelectionConfirmed, toggleRefinedComps: toggleRefinedComps, isAddingComps: isAddingComps })))),
                    comps && comps.length === 0 && (React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, null, "No comps found")))))),
        React.createElement(CompsPullerPhotoCarousel, { open: !!photosCarouselData, photos: photosCarouselData ? photosCarouselData.photos : [], address: photosCarouselData ? photosCarouselData.address : "", onCancel: closePhotos }),
        React.createElement(CoreDialog, { open: !!currentlyShowingDetailsForProperty },
            React.createElement(DialogTitle, null, currentlyShowingDetailsForProperty && getAddress(currentlyShowingDetailsForProperty)),
            React.createElement(DialogContent, null, currentlyShowingDetailsForProperty && React.createElement(Remarks, { property: currentlyShowingDetailsForProperty })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: hideDetails }, "Close")))));
};
export default CompsPullerWrapper;
