/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List graduation requirement types
 */
export const listGraduationRequirementTypes = (options) => {
    return axios.get(`/api/onboarding/graduation_requirement_types`, options);
};
export const getListGraduationRequirementTypesQueryKey = () => {
    return [`/api/onboarding/graduation_requirement_types`];
};
export const getListGraduationRequirementTypesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListGraduationRequirementTypesQueryKey();
    const queryFn = ({ signal }) => listGraduationRequirementTypes(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List graduation requirement types
 */
export function useListGraduationRequirementTypes(options) {
    const queryOptions = getListGraduationRequirementTypesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Add graduation requirement type
 */
export const addGraduationRequirementType = (graduationRequirementTypeRequest, options) => {
    return axios.post(`/api/onboarding/graduation_requirement_types`, graduationRequirementTypeRequest, options);
};
export const getAddGraduationRequirementTypeMutationOptions = (options) => {
    const mutationKey = ['addGraduationRequirementType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addGraduationRequirementType(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add graduation requirement type
*/
export const useAddGraduationRequirementType = (options) => {
    const mutationOptions = getAddGraduationRequirementTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get graduation requirement type
*/
export const getGraduationRequirementType = (uid, options) => {
    return axios.get(`/api/onboarding/graduation_requirement_types/${uid}`, options);
};
export const getGetGraduationRequirementTypeQueryKey = (uid) => {
    return [`/api/onboarding/graduation_requirement_types/${uid}`];
};
export const getGetGraduationRequirementTypeQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetGraduationRequirementTypeQueryKey(uid);
    const queryFn = ({ signal }) => getGraduationRequirementType(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get graduation requirement type
 */
export function useGetGraduationRequirementType(uid, options) {
    const queryOptions = getGetGraduationRequirementTypeQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update graduation requirement type
 */
export const updateGraduationRequirementType = (uid, graduationRequirementTypeRequest, options) => {
    return axios.post(`/api/onboarding/graduation_requirement_types/${uid}`, graduationRequirementTypeRequest, options);
};
export const getUpdateGraduationRequirementTypeMutationOptions = (options) => {
    const mutationKey = ['updateGraduationRequirementType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateGraduationRequirementType(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update graduation requirement type
*/
export const useUpdateGraduationRequirementType = (options) => {
    const mutationOptions = getUpdateGraduationRequirementTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete graduation requirement type
*/
export const deleteGraduationRequirementType = (uid, options) => {
    return axios.delete(`/api/onboarding/graduation_requirement_types/${uid}`, options);
};
export const getDeleteGraduationRequirementTypeMutationOptions = (options) => {
    const mutationKey = ['deleteGraduationRequirementType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteGraduationRequirementType(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete graduation requirement type
*/
export const useDeleteGraduationRequirementType = (options) => {
    const mutationOptions = getDeleteGraduationRequirementTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Reorder graduation requirement types
*/
export const reorderGraduationRequirementTypes = (graduationRequirementTypeReorderRequest, options) => {
    return axios.post(`/api/onboarding/graduation_requirement_types/reorder`, graduationRequirementTypeReorderRequest, options);
};
export const getReorderGraduationRequirementTypesMutationOptions = (options) => {
    const mutationKey = ['reorderGraduationRequirementTypes'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderGraduationRequirementTypes(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder graduation requirement types
*/
export const useReorderGraduationRequirementTypes = (options) => {
    const mutationOptions = getReorderGraduationRequirementTypesMutationOptions(options);
    return useMutation(mutationOptions);
};
