import * as React from "react";
import { Grid, Typography } from "@mui/material";
import useSize from "@app/hooks/useSize";
const FormField = (props) => {
    const { children, required, title } = props;
    const size = useSize();
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, container: true, spacing: 1, style: { width: size.isUp("md") ? "300px" : "100px" } },
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2", style: { textAlign: "right", transform: "translateY(5px)" } }, title)),
            required && (React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "red" } }, "*")))),
        React.createElement(Grid, { item: true, style: { flex: 1 } }, children)));
};
export default FormField;
