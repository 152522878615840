import { useMemo } from "react";
import useSession from "@app/hooks/useSession";
import { useListStages } from "@app/orval/api/stages";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { saturateFieldWithValidation } from "../components/box-view/field_validation";
import { saturateSectionWithValidation } from "../components/box-view/section_validation";
const useValidation = (params) => {
    var _a;
    const { fields, recruit, sections } = params;
    const session = useSession();
    const listStagesApi = useListStages({ type: "Recruit" }, REFETCH_CACHE_PARAMS);
    const stages = useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [];
    }, [(_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const fieldSectionsSaturated = useMemo(() => {
        return sections === null || sections === void 0 ? void 0 : sections.map((section) => { var _a; return saturateSectionWithValidation({ section, ctx: recruit, user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined, stages }); });
    }, [recruit, sections, session === null || session === void 0 ? void 0 : session.viewingAsUser, stages]);
    const fieldsSaturated = useMemo(() => {
        return fields === null || fields === void 0 ? void 0 : fields.map((field) => { var _a; return saturateFieldWithValidation({ field, ctx: recruit, user: (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) !== null && _a !== void 0 ? _a : undefined, stages }); });
    }, [fields, recruit, session === null || session === void 0 ? void 0 : session.viewingAsUser, stages]);
    const validationErrors = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return [
            ...((_a = fieldsSaturated === null || fieldsSaturated === void 0 ? void 0 : fieldsSaturated.map((f) => ({
                name: `${f.name} - Visible Validation`,
                error: f.visibleValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _a !== void 0 ? _a : []),
            ...((_b = fieldsSaturated === null || fieldsSaturated === void 0 ? void 0 : fieldsSaturated.map((f) => ({
                name: `${f.name} - Editable Validation`,
                error: f.editableValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _b !== void 0 ? _b : []),
            ...((_c = fieldsSaturated === null || fieldsSaturated === void 0 ? void 0 : fieldsSaturated.map((f) => ({
                name: `${f.name} - Warn Validation`,
                error: f.warnValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _c !== void 0 ? _c : []),
            ...((_d = fieldsSaturated === null || fieldsSaturated === void 0 ? void 0 : fieldsSaturated.map((f) => ({
                name: `${f.name} - Reject Validation`,
                error: f.rejectValidation.error,
                url: `/field-management?field=${f.uid}`,
            }))) !== null && _d !== void 0 ? _d : []),
            ...((_e = fieldSectionsSaturated === null || fieldSectionsSaturated === void 0 ? void 0 : fieldSectionsSaturated.map((f) => ({
                name: `${f.name} - Visible Validation`,
                error: f.visibleValidation.error,
                url: `/field-management?section=${f.uid}`,
            }))) !== null && _e !== void 0 ? _e : []),
        ].filter((x) => x.error);
    }, [fieldSectionsSaturated, fieldsSaturated]);
    return {
        fieldsSaturated,
        fieldSectionsSaturated,
        validationErrors,
    };
};
export default useValidation;
