/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List Rent Roll
 */
export const listRentRollItems = (params, options) => {
    return axios.get(`/api/rent_roll`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListRentRollItemsQueryKey = (params) => {
    return [`/api/rent_roll`, ...(params ? [params] : [])];
};
export const getListRentRollItemsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListRentRollItemsQueryKey(params);
    const queryFn = ({ signal }) => listRentRollItems(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Rent Roll
 */
export function useListRentRollItems(params, options) {
    const queryOptions = getListRentRollItemsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a Rent Roll Line Item
 */
export const createRentRollItem = (rentRollLineItemCreateRequest, options) => {
    return axios.post(`/api/rent_roll`, rentRollLineItemCreateRequest, options);
};
export const getCreateRentRollItemMutationOptions = (options) => {
    const mutationKey = ['createRentRollItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createRentRollItem(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a Rent Roll Line Item
*/
export const useCreateRentRollItem = (options) => {
    const mutationOptions = getCreateRentRollItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a Rent Roll Line Item
*/
export const getRentRollItem = (uid, options) => {
    return axios.get(`/api/rent_roll/${uid}`, options);
};
export const getGetRentRollItemQueryKey = (uid) => {
    return [`/api/rent_roll/${uid}`];
};
export const getGetRentRollItemQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRentRollItemQueryKey(uid);
    const queryFn = ({ signal }) => getRentRollItem(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a Rent Roll Line Item
 */
export function useGetRentRollItem(uid, options) {
    const queryOptions = getGetRentRollItemQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a Rent Roll Line Item
 */
export const updateRentRollItem = (uid, rentRollLineItemRequest, options) => {
    return axios.post(`/api/rent_roll/${uid}`, rentRollLineItemRequest, options);
};
export const getUpdateRentRollItemMutationOptions = (options) => {
    const mutationKey = ['updateRentRollItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateRentRollItem(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a Rent Roll Line Item
*/
export const useUpdateRentRollItem = (options) => {
    const mutationOptions = getUpdateRentRollItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a Rent Roll Line Item
*/
export const deleteRentRollItem = (uid, options) => {
    return axios.delete(`/api/rent_roll/${uid}`, options);
};
export const getDeleteRentRollItemMutationOptions = (options) => {
    const mutationKey = ['deleteRentRollItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteRentRollItem(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a Rent Roll Line Item
*/
export const useDeleteRentRollItem = (options) => {
    const mutationOptions = getDeleteRentRollItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count Rent Roll
*/
export const countRentRollItems = (params, options) => {
    return axios.get(`/api/rent_roll/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountRentRollItemsQueryKey = (params) => {
    return [`/api/rent_roll/count`, ...(params ? [params] : [])];
};
export const getCountRentRollItemsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountRentRollItemsQueryKey(params);
    const queryFn = ({ signal }) => countRentRollItems(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count Rent Roll
 */
export function useCountRentRollItems(params, options) {
    const queryOptions = getCountRentRollItemsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download Rent Roll
 */
export const downloadRentRoll = (params, options) => {
    return axios.get(`/api/rent_roll/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadRentRollQueryKey = (params) => {
    return [`/api/rent_roll/download`, ...(params ? [params] : [])];
};
export const getDownloadRentRollQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadRentRollQueryKey(params);
    const queryFn = ({ signal }) => downloadRentRoll(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download Rent Roll
 */
export function useDownloadRentRoll(params, options) {
    const queryOptions = getDownloadRentRollQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
