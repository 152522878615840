var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Typography } from "@mui/material";
import omit from "lodash/omit";
import { MentionsInput as MentionsInput_, Mention } from "react-mentions";
import styled from "styled-components";
import AppConfig from "@app/util/AppConfig";
import { Formatters } from "./utils";
const StyledMentionsInput = styled(MentionsInput_) `
    padding-bottom: 0.25rem;
    border: none;
    textarea, input {
        border: none;
        outline: none;
    }
    ul {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        max-height: 200px;
        overflow-y: scroll;
    }
    li {
        padding: 0.25rem;
    }
    span {
        margin-right: -0.2%;
    }

    font-family: Nunito, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.785714rem;
    line-height: 1.1876em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    padding-bottom: 0.25rem;
    border: None;
    ::before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      left: 0px;
      bottom: 0px;
      content: " ";
      position: absolute;
      right: 0px;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }
    ::after {
      border-bottom: 2px solid rgb(13, 141, 186);
      left: 0px;
      bottom: 0px;
      content: "";
      position: absolute;
      right: 0px;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: none;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    }
`;
const MentionsInput = (props) => {
    const { additionalAutocompleteOptions, error, fields, helperText, slackUsers, users } = props, attributes = __rest(props, ["additionalAutocompleteOptions", "error", "fields", "helperText", "slackUsers", "users"]);
    let helperComponent = null;
    if (helperText) {
        if (typeof helperText === "string") {
            helperComponent = (React.createElement(Typography, { variant: "caption", color: error ? "secondary" : "inherit" }, helperText));
        }
        else {
            helperComponent = helperText;
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledMentionsInput, Object.assign({}, omit(attributes, "data"), { allowSpaceInQuery: true, className: "MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-lwfdia" }), (fields
            ? ["$", "$'"].map((trigger) => (React.createElement(Mention, { key: trigger, trigger: trigger, data: [
                    { id: "name", display: "Name" },
                    { id: "assignedToSharingEntries", display: "Assigned Agents" },
                ]
                    .concat(fields
                    .sort((a, b) => {
                    if (a.name < b.name)
                        return -1;
                    if (a.name > b.name)
                        return 1;
                    return 0;
                })
                    .map((field) => ({
                    id: field.uid,
                    display: field.name,
                })))
                    .concat((additionalAutocompleteOptions !== null && additionalAutocompleteOptions !== void 0 ? additionalAutocompleteOptions : []).sort((a, b) => {
                    if (a.display < b.display)
                        return -1;
                    if (a.display > b.display)
                        return 1;
                    return 0;
                })), 
                // displayTransform={this.messageDisplayTransform}
                markup: "$[__display__](__id__)", appendSpaceOnAdd: true, 
                // renderSuggestion={(
                //     suggestion,
                //     // @ts-expect-error
                //     search,
                //     // @ts-expect-error
                //     highlightedDisplay,
                //     // @ts-expect-error
                //     index,
                //     // @ts-expect-error
                //     focused,
                // ) => (
                //     <div style={{ background: "red" }}>
                //         {suggestion.id}
                //     </div>
                // )}
                style: {
                    border: `1px solid ${AppConfig.themeColors.primary}`,
                    borderRadius: "0.25rem",
                    background: AppConfig.themeColors.lightestblue,
                    lineHeight: 1.5,
                    display: "inline-block",
                } })))
            : [])
            .concat(users
            ? ["@"].map((trigger) => (React.createElement(Mention, { key: `${trigger}__streak`, trigger: trigger, data: [
                    {
                        id: "assignedToSharingEntries",
                        display: "@AssignedTo",
                    },
                ].concat(users
                    .sort((a, b) => {
                    const aName = [a.first_name, a.last_name].filter((o) => o).join(" ");
                    const bName = [b.first_name, b.last_name].filter((o) => o).join(" ");
                    if (aName < bName)
                        return -1;
                    if (aName > bName)
                        return 1;
                    return 0;
                })
                    .map((user) => ({
                    id: user.uid,
                    display: `@${[user.first_name, user.last_name].filter((o) => o).join(" ")} (${user.email})`,
                }))), markup: "@[__display__](__id__)", appendSpaceOnAdd: true, style: {
                    border: "1px solid #87c787",
                    borderRadius: "0.25rem",
                    background: "#d1e8d1",
                    lineHeight: 1.5,
                    display: "inline-block",
                } })))
            : [])
            .concat(slackUsers
            ? ["@"].map((trigger) => (React.createElement(Mention, { key: `${trigger}__slack`, trigger: trigger, data: [
                    { id: "!here", display: "@here" },
                    { id: "!channel", display: "@channel" },
                    { id: "!everyone", display: "@everyone" },
                ].concat(slackUsers
                    .sort((a, b) => {
                    if (a.name < b.name)
                        return -1;
                    if (a.name > b.name)
                        return 1;
                    return 0;
                })
                    .map((slackUser) => ({
                    id: slackUser.id,
                    display: `@${slackUser.real_name || slackUser.name}`,
                }))), markup: "@[__display__](__id__)", appendSpaceOnAdd: true, style: {
                    border: "1px solid #a36fbd",
                    borderRadius: "0.25rem",
                    background: "#d7b5e8",
                    lineHeight: 1.5,
                    display: "inline-block",
                } })))
            : [])
            .concat([":", "::"].map((trigger) => (React.createElement(Mention, { key: `${trigger}__formatters`, trigger: trigger, data: Object.keys(Formatters).map((key) => ({
                id: Formatters[key].name,
                display: Formatters[key].name,
            })), markup: ":[__display__](__id__)", appendSpaceOnAdd: true, style: {
                border: "1px solid #859460",
                borderRadius: "0.25rem",
                background: "#ecfaca",
                lineHeight: 1.5,
                display: "inline-block",
            } }))))),
        helperComponent));
};
export default MentionsInput;
