import * as React from "react";
import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import { withFilter } from "@app/hoc/withFilter";
import API from "@app/util/api";
const NOT_SPECIFIED = "Not Specified";
const STATUSES = [
    {
        value: "Success",
        description: "Indicates that the Mail Schedule ran successfully, although there may have been errors with individual templates.",
    },
    {
        value: "Failed",
        description: "Indicates that a bug or another error prevented the Mail Schedule from running successfully.",
    },
    {
        value: "Running",
        description: "Indicates that the Mail Schedule is still running.",
    },
    {
        value: "Stopped",
        description: "Indicates that a user stopped the Mail Schedule before it finished running.",
    },
];
class MailScheduleExecutionsTableSidebar extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                pipelines: null,
                templates: null,
                users: null,
                query: {},
            }
        });
        Object.defineProperty(this, "pipelinesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "templatesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "usersAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setPipeline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.props.updateFilter("pipeline_key", (e.target.value !== NOT_SPECIFIED && e.target.value) || null, "pipeline_key_isnull", e.target.value === NOT_SPECIFIED ? true : null, "template_uid", null, "offset", 0);
            }
        });
        Object.defineProperty(this, "setTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.props.updateFilter("template_uid", (e.target.value !== NOT_SPECIFIED && e.target.value) || null, "template_uid_isnull", e.target.value === NOT_SPECIFIED ? true : null, "offset", 0);
            }
        });
        Object.defineProperty(this, "setAgentEmail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.props.updateFilter("agent_email", (e.target.value !== NOT_SPECIFIED && e.target.value) || null, "agent_email_isnull", e.target.value === NOT_SPECIFIED ? true : null, "offset", 0);
            }
        });
        Object.defineProperty(this, "setStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.props.updateFilter("status", e.target.value || null, "offset", 0);
            }
        });
        Object.defineProperty(this, "setBoxKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.props.updateFilter("box_key", e.target.value || null, "offset", 0, "box_key_isnull", e.target.value ? null : true);
            }
        });
        Object.defineProperty(this, "getTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    templates: null,
                }, () => {
                    var _a;
                    (_a = this.templatesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.templatesAbortController = new AbortController();
                    API.mailScheduleTemplates.list({
                        onSuccess: (result) => {
                            this.setState({
                                templates: result,
                            });
                        },
                        onError: (error) => {
                            console.error(error);
                        },
                        options: {
                            signal: this.templatesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "getPipelines", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    pipelines: null,
                }, () => {
                    var _a;
                    (_a = this.pipelinesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.pipelinesAbortController = new AbortController();
                    API.streakPipelines.list({
                        onSuccess: (result) => {
                            this.setState({
                                pipelines: result,
                            });
                        },
                        onError: (error) => {
                            console.error(error);
                        },
                        options: {
                            signal: this.pipelinesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "getUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    users: null,
                }, () => {
                    var _a;
                    (_a = this.usersAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.usersAbortController = new AbortController();
                    API.users.list({
                        onSuccess: (result) => {
                            this.setState({
                                users: result,
                            });
                        },
                        onError: (error) => {
                            console.error(error);
                        },
                        options: {
                            signal: this.usersAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a, _b, _c, _d, _e;
        const queryParams = new URLSearchParams(nextProps.location.search);
        return {
            query: {
                agent_email: (_a = queryParams.get("agent_email")) !== null && _a !== void 0 ? _a : null,
                agent_email_isnull: queryParams.get("agent_email_isnull") === "true" || null,
                template_uid: (_b = queryParams.get("template_uid")) !== null && _b !== void 0 ? _b : null,
                template_uid_isnull: queryParams.get("template_uid_isnull") === "true" || null,
                pipeline_key: (_c = queryParams.get("pipeline_key")) !== null && _c !== void 0 ? _c : null,
                pipeline_key_isnull: queryParams.get("pipeline_key_isnull") === "true" || null,
                status: (_d = queryParams.get("status")) !== null && _d !== void 0 ? _d : null,
                box_key: (_e = queryParams.get("box_key")) !== null && _e !== void 0 ? _e : null,
            },
        };
    }
    componentDidMount() {
        this.getPipelines();
        this.getTemplates();
        this.getUsers();
    }
    componentWillUnmount() {
        var _a, _b, _c;
        (_a = this.pipelinesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
        (_b = this.templatesAbortController) === null || _b === void 0 ? void 0 : _b.abort();
        (_c = this.usersAbortController) === null || _c === void 0 ? void 0 : _c.abort();
    }
    render() {
        var _a, _b, _c, _d, _e, _f;
        const { onSidebarToggle } = this.props;
        const { pipelines, query, templates, users } = this.state;
        return (React.createElement(DashCard, { heading: "Filters", toolbar: React.createElement(IconButton, { onClick: onSidebarToggle },
                React.createElement(Close, null)), padding: 0, elevation: 0, height: "100%", PaperProps: {
                style: {
                    overflowX: "hidden",
                },
            } },
            React.createElement(Box, { component: Grid, container: true, spacing: 2, p: 2 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { variant: "standard", label: "Pipeline", fullWidth: true, select: true, value: query.pipeline_key_isnull ? NOT_SPECIFIED : (_a = query.pipeline_key) !== null && _a !== void 0 ? _a : "", onChange: this.setPipeline, disabled: !pipelines, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        React.createElement(MenuItem, { value: NOT_SPECIFIED }, "Not Specified"),
                        (pipelines !== null && pipelines !== void 0 ? pipelines : []).map((pipeline) => (React.createElement(MenuItem, { key: pipeline.key, value: pipeline.key }, pipeline.name))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { variant: "standard", label: "Template", fullWidth: true, select: true, value: query.template_uid_isnull ? NOT_SPECIFIED : (_b = query.template_uid) !== null && _b !== void 0 ? _b : "", onChange: this.setTemplate, disabled: !templates || !pipelines, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        React.createElement(MenuItem, { value: NOT_SPECIFIED }, "Not Specified"),
                        (templates !== null && templates !== void 0 ? templates : [])
                            .filter((t) => t.pipeline === query.pipeline_key)
                            .map((template) => (React.createElement(MenuItem, { key: template.uid, value: template.uid }, template.name))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { variant: "standard", label: "Agent", fullWidth: true, select: true, value: query.agent_email_isnull ? NOT_SPECIFIED : (_c = query.agent_email) !== null && _c !== void 0 ? _c : "", onChange: this.setAgentEmail, disabled: !users, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        React.createElement(MenuItem, { value: NOT_SPECIFIED }, "Not Specified"),
                        (users !== null && users !== void 0 ? users : []).map((user) => {
                            var _a, _b;
                            return (React.createElement(MenuItem, { key: (_a = user.email) !== null && _a !== void 0 ? _a : "", value: (_b = user.email) !== null && _b !== void 0 ? _b : "" }, user.email));
                        }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { variant: "standard", label: "Status", fullWidth: true, select: true, value: (_d = query.status) !== null && _d !== void 0 ? _d : "", onChange: this.setStatus, helperText: (_e = STATUSES.find((s) => s.value === query.status)) === null || _e === void 0 ? void 0 : _e.description, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        STATUSES.map((status) => (React.createElement(MenuItem, { key: status.value, value: status.value }, status.value))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(TextField, { variant: "standard", label: "Box Key", fullWidth: true, value: (_f = query.box_key) !== null && _f !== void 0 ? _f : "", onChange: this.setBoxKey, InputLabelProps: {
                            shrink: true,
                        } })))));
    }
}
export default withFilter(MailScheduleExecutionsTableSidebar);
