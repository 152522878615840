import * as React from "react";
import { Close, Delete, Save } from "@mui/icons-material";
import { Box, Button, Divider, Grid2, InputAdornment, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useAddGraduationRequirementType, useDeleteGraduationRequirementType, useGetGraduationRequirementType, useListGraduationRequirementTypes, useUpdateGraduationRequirementType, } from "@app/orval/api/graduation-requirement-types";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const DEFAULT_FORM_DATA = {
    name: "",
};
const GraduationRequirementTypeForm = (props) => {
    const { uid } = props;
    const [formData, setFormData] = React.useState(DEFAULT_FORM_DATA);
    const [initialData, setInitialData] = React.useState(DEFAULT_FORM_DATA);
    const isValid = React.useMemo(() => {
        return formData.name.trim() && formData.name.trim() !== initialData.name.trim();
    }, [formData, initialData]);
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const listGraduationRequirementTypesApi = useListGraduationRequirementTypes({ query: { enabled: false } });
    const getTrainingTypeApi = useGetGraduationRequirementType(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: false } });
    const createGraduationRequirementTypeApi = useAddGraduationRequirementType();
    const updateGraduationRequirementTypeApi = useUpdateGraduationRequirementType();
    const deleteGraduationRequirementTypeApi = useDeleteGraduationRequirementType();
    React.useEffect(() => {
        if (uid) {
            getTrainingTypeApi
                .refetch()
                .then((result) => {
                setFormData(result.data.data);
                setInitialData(result.data.data);
            })
                .catch(errorDialog);
        }
        else {
            setFormData(DEFAULT_FORM_DATA);
            setInitialData(DEFAULT_FORM_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const createGraduationRequirementType = React.useCallback((params) => {
        createGraduationRequirementTypeApi
            .mutateAsync({ data: { name: params.name } })
            .then((result) => {
            queryClient.setQueryData(listGraduationRequirementTypesApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
            navigate("/onboarding/graduation-requirement-types");
        })
            .catch(errorDialog);
    }, [
        createGraduationRequirementTypeApi,
        errorDialog,
        listGraduationRequirementTypesApi.queryKey,
        navigate,
        queryClient,
    ]);
    const updateGraduationRequirementType = React.useCallback((trainingTypeUid, params) => {
        updateGraduationRequirementTypeApi
            .mutateAsync({ uid: trainingTypeUid, data: { name: params.name } })
            .then((result) => {
            queryClient.setQueryData(listGraduationRequirementTypesApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((t) => (t.uid === result.data.uid ? result.data : t)) });
            });
            navigate("/onboarding/graduation-requirement-types");
        })
            .catch(errorDialog);
    }, [
        errorDialog,
        listGraduationRequirementTypesApi.queryKey,
        navigate,
        queryClient,
        updateGraduationRequirementTypeApi,
    ]);
    const saveGraduationRequirementType = React.useCallback(() => {
        if (uid) {
            updateGraduationRequirementType(uid, formData);
        }
        else {
            createGraduationRequirementType(formData);
        }
    }, [createGraduationRequirementType, formData, uid, updateGraduationRequirementType]);
    const deleteGraduationRequirementType = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteGraduationRequirementTypeApi
                    .mutateAsync({ uid: uid })
                    .then((result) => {
                    queryClient.setQueryData(listGraduationRequirementTypesApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: old.data.filter((t) => t.uid !== result.data.uid) });
                    });
                    navigate("/onboarding/graduation-requirement-types");
                })
                    .catch(errorDialog);
            }
        });
    }, [
        confirm,
        deleteGraduationRequirementTypeApi,
        errorDialog,
        listGraduationRequirementTypesApi.queryKey,
        navigate,
        queryClient,
        uid,
    ]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const isSaving = createGraduationRequirementTypeApi.isPending ||
        updateGraduationRequirementTypeApi.isPending ||
        deleteGraduationRequirementTypeApi.isPending;
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Typography, { variant: "h6" },
                    uid ? "Edit" : "Add",
                    " Graduation Requirement"))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { size: { xs: 12 } },
                        React.createElement(TextField, { fullWidth: true, size: "small", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Name"),
                                },
                                htmlInput: { maxLength: 255 },
                            }, value: formData.name, onChange: setName }))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 1 },
                    uid && (React.createElement(Grid2, null,
                        React.createElement(Button, { onClick: deleteGraduationRequirementType, variant: "contained", color: "error", startIcon: React.createElement(Delete, null), disabled: isSaving, tabIndex: -1 }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { component: RouterLink, to: "/onboarding/graduation-requirement-types", variant: "text", startIcon: React.createElement(Close, null), disabled: isSaving, tabIndex: -1 }, "Cancel")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { onClick: saveGraduationRequirementType, variant: "outlined", color: "primary", startIcon: React.createElement(Save, null), disabled: isSaving || !isValid, tabIndex: -1 }, "Save")))))));
};
export default GraduationRequirementTypeForm;
