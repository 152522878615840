import * as React from "react";
import { Typography } from "@mui/material";
import AppConfig from "@app/util/AppConfig";
export const MailScheduleTemplateHelperText = (props) => {
    const { extraVerbiage, includeStreakFieldVerbiage } = props;
    return (React.createElement(Typography, { variant: "caption", dangerouslySetInnerHTML: {
            __html: (includeStreakFieldVerbiage &&
                `Use <b><font color='${AppConfig.themeColors.blue}'>$'Field Name'</font></b> syntax to auto-populate with CRM data. `) +
                (extraVerbiage !== null && extraVerbiage !== void 0 ? extraVerbiage : ""),
        } }));
};
MailScheduleTemplateHelperText.defaultProps = {
    includeStreakFieldVerbiage: true,
    extraVerbiage: "",
};
