import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import ZHLEngagedTransferSubmissions from "../../features/reporting/zhl-engaged-transfer/ZHLEngagedTransferSubmissions";
const ZHLEngagedTransferPage = () => {
    useDocumentTitle("Reporting | ZHL Engaged Transfer");
    const [searchParams] = useSearchParams();
    const limit = searchParams.get("limit") ? parseInt(searchParams.get("limit"), 10) : undefined;
    const offset = searchParams.get("offset") ? parseInt(searchParams.get("offset"), 10) : undefined;
    return React.createElement(ZHLEngagedTransferSubmissions, { limit: limit, offset: offset });
};
export default ZHLEngagedTransferPage;
