import * as React from "react";
import { Add, AttachMoney, RemoveOutlined } from "@mui/icons-material";
import { Alert, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useGetUser, useRemoveAgentStatus } from "@app/orval/api/users";
import { formatDate_Dayjs } from "@app/util/Utils";
import AgentStatusDialog from "./AgentStatusDialog";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const AgentStatuses = (props) => {
    const { canEdit = false, user } = props;
    const [isStatusDialogOpen, setIsStatusDialogOpen] = React.useState(false);
    const queryClient = useQueryClient();
    const { queryKey } = useGetUser(user.uid, { query: { enabled: false } });
    const errorDialog = useErrorDialog();
    const agentStatuses = React.useMemo(() => {
        return [...user.agent_statuses].sort((a, b) => {
            if (a.effective_date > b.effective_date)
                return -1;
            if (a.effective_date < b.effective_date)
                return 1;
            return 0;
        });
    }, [user]);
    const removeStatusApi = useRemoveAgentStatus();
    const removeStatus = React.useCallback((statusUid) => () => {
        queryClient.setQueryData(queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { agent_statuses: old.data.agent_statuses.filter((x) => x.uid !== statusUid) }) });
        });
        removeStatusApi
            .mutateAsync({ userUid: user.uid, agentStatusUid: statusUid })
            .then(() => {
            console.log("removed status");
        })
            .catch(errorDialog);
    }, [queryClient, queryKey, removeStatusApi, user.uid, errorDialog]);
    const openNewStatusDialog = React.useCallback(() => {
        setIsStatusDialogOpen(true);
    }, [setIsStatusDialogOpen]);
    const closeStatusDialog = React.useCallback(() => {
        setIsStatusDialogOpen(false);
    }, [setIsStatusDialogOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(AttachMoney, null), title: "Agent Statuses", count: agentStatuses.length, isCollapsedInitially: true, toolbar: canEdit && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add new status"), arrow: true },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: openNewStatusDialog, size: "small" },
                        React.createElement(Add, { fontSize: "small" }))))) },
            React.createElement(Alert, { severity: "info" },
                React.createElement(Typography, { variant: "body2" }, "Changes to an agent's status, combined with DASHscore, are used to determine their payout on transactions.")),
            React.createElement(List, { dense: true }, agentStatuses.map((agentStatus) => (React.createElement(ListItem, { key: agentStatus.uid },
                React.createElement(ListItemText, { primary: agentStatus.status, secondary: `Effective ${formatDate_Dayjs(agentStatus.effective_date, "ddd, MMM D, YYYY")}` }),
                canEdit && (React.createElement(ListItemSecondaryAction, null,
                    React.createElement(Grid, { container: true, wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove status"), arrow: true },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: removeStatus(agentStatus.uid) },
                                        React.createElement(RemoveOutlined, null))))))))))))),
        isStatusDialogOpen && (React.createElement(AgentStatusDialog, { userUid: user.uid, onClose: closeStatusDialog, DialogProps: { open: true, maxWidth: "sm", PaperProps: { style: { minWidth: "300px" } } } }))));
};
export default AgentStatuses;
