import * as React from "react";
import { Add, FileCopy, Delete, Save } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Divider, Grid2, IconButton, InputAdornment, List, ListItem, ListItemText, ListItemSecondaryAction, MenuItem, Paper, Slider, Switch, TextField, Tooltip, Typography, Autocomplete, Alert, AlertTitle, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import numeral from "numeral";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import CoreDateField from "@app/common/CoreDateField";
import CoreLink from "@app/common/CoreLink";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import dayjs_ from "@app/common/dayjs";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import Search from "@app/entrypoints/brokerage/container/Header/Search/Search";
import { bodyHelperText } from "@app/entrypoints/brokerage/features/mailschedule/utils/utils";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListMailschedule4Boilerplate } from "@app/orval/api/mail-schedule-4-boilerplate";
import { useListMailschedule4ResendUnits } from "@app/orval/api/mail-schedule-4-resend-units";
import { useListMailschedule4TemplateTypes } from "@app/orval/api/mail-schedule-4-template-types";
import { useDeleteMailschedule4Template, useDuplicateMailschedule4Template, } from "@app/orval/api/mail-schedule-4-templates";
import { useCreateMailschedule4Template, useGetMailschedule4Template, useUpdateMailschedule4Template, } from "@app/orval/api/mail-schedule-templates";
import { useListSlackChannels, useListSlackUsers } from "@app/orval/api/slack";
import { useListStages } from "@app/orval/api/stages";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { searchUsersBasic } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AppConfig, { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { defaultUserIcon, getLinkForEntity } from "@app/util/Utils";
import CreateViewForFiltersButton from "./CreateViewForFiltersButton";
import GCPAttachments from "./GCPAttachments";
import IfStatementBuilder from "./IfStatementBuilder";
import MailSchedule3Templates from "./MailSchedule3Templates";
import { MailScheduleTemplateHelperText } from "./MailScheduleTemplateHelperText";
import FilterGroups from "../../crm/components/spreadsheet-view/filters/FilterGroups";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import MailScheduleTemplatePreview from "../preview/MailScheduleTemplatePreview";
import { getAgentMentionsFields, getAgentName, getClientMentionsFields, getDateFieldsInPipeline, getTemplateType, getTextFieldsInPipeline, getTimeFieldsInPipeline, getVendorMentionsFields, templateTypeHelperText, } from "../utils/form";
import MailScheduleFormattersHelper from "../utils/MailScheduleFormattersHelper";
import MailScheduleQuill from "../utils/MailScheduleQuill";
import MentionsInput from "../utils/MentionsInput";
import { MAIL_SCHEDULE_ENTITY_TYPES } from "../utils/utils";
const renderAgentDropdownOption = (props, option) => {
    var _a;
    const rowStyle = {
        overflow: "hidden !important",
        height: "10px",
        fontSize: "10px",
    };
    return (React.createElement(MenuItem, Object.assign({}, props, { value: option ? `agent_dropdown_option_${option.uid}` : "agent_dropdown_option_blank" }),
        React.createElement(Grid2, { container: true, spacing: 2 }, option != null && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, { size: 3 },
                React.createElement(Box, null,
                    React.createElement("img", { alt: "", src: (_a = option.icon_url) !== null && _a !== void 0 ? _a : defaultUserIcon, width: "30px", height: "30px", style: {
                            margin: 0,
                            borderRadius: "50%",
                        } }))),
            React.createElement(Grid2, { size: 9 },
                React.createElement(Grid2, { size: 12, style: rowStyle }, `${option.first_name} ${option.last_name}`.trim()),
                React.createElement(Grid2, { size: 12, style: rowStyle }, option.email),
                React.createElement(Grid2, { size: 12, style: rowStyle }, option.phone)))))));
};
const renderAgentSearchInput = (params) => (React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: "standard", label: "Assigned Agent" })));
const entityTypes = MAIL_SCHEDULE_ENTITY_TYPES;
const MailScheduleTemplateForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55;
    const { uid } = props;
    const getTemplateApi = useGetMailschedule4Template(uid !== null && uid !== void 0 ? uid : "", Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: !!uid }) }));
    const template = (_b = (_a = getTemplateApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const createTemplateApi = useCreateMailschedule4Template();
    const updateTemplateApi = useUpdateMailschedule4Template();
    const deleteTemplateApi = useDeleteMailschedule4Template();
    const duplicateTemplateApi = useDuplicateMailschedule4Template();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const isSaving = createTemplateApi.isPending || updateTemplateApi.isPending;
    const isDeleting = deleteTemplateApi.isPending;
    const isDuplicating = duplicateTemplateApi.isPending;
    const [initialData, setInitialData] = React.useState(null);
    const [formData, setFormData] = React.useState(null);
    const [itemForPreview, setItemForPreview] = React.useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
    React.useEffect(() => {
        if (template) {
            const data = Object.assign(Object.assign({}, template), { criteria: template.criteria.length === 0
                    ? [
                        {
                            uid: uuidv4().replace(/-/g, ""),
                            filters: [],
                            parent_uid: null,
                            is_match_all: true,
                        },
                    ]
                    : template.criteria.map((filterGroup) => (Object.assign(Object.assign({}, filterGroup), { filters: filterGroup.filters.map((filter) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                            return (Object.assign(Object.assign({}, filter), { uid: uuidv4().replace(/-/g, ""), text_filter: filter.text_filter
                                    ? Object.assign(Object.assign({}, filter.text_filter), { lhs_field: filter.text_filter.lhs_field.uid, rhs_field: (_a = filter.text_filter.rhs_field) === null || _a === void 0 ? void 0 : _a.uid }) : filter.text_filter, date_filter: filter.date_filter
                                    ? Object.assign(Object.assign({}, filter.date_filter), { lhs_field: filter.date_filter.lhs_field.uid, rhs_field: (_b = filter.date_filter.rhs_field) === null || _b === void 0 ? void 0 : _b.uid }) : filter.date_filter, number_filter: filter.number_filter
                                    ? Object.assign(Object.assign({}, filter.number_filter), { lhs_field: filter.number_filter.lhs_field.uid, rhs_field: (_c = filter.number_filter.rhs_field) === null || _c === void 0 ? void 0 : _c.uid }) : filter.number_filter, boolean_filter: filter.boolean_filter
                                    ? Object.assign(Object.assign({}, filter.boolean_filter), { lhs_field: filter.boolean_filter.lhs_field.uid, rhs_field: (_d = filter.boolean_filter.rhs_field) === null || _d === void 0 ? void 0 : _d.uid }) : filter.boolean_filter, choice_filter: filter.choice_filter
                                    ? Object.assign(Object.assign({}, filter.choice_filter), { lhs_field: filter.choice_filter.lhs_field.uid }) : filter.choice_filter, multiple_choice_filter: filter.multiple_choice_filter
                                    ? Object.assign(Object.assign({}, filter.multiple_choice_filter), { lhs_field: filter.multiple_choice_filter.lhs_field.uid }) : filter.multiple_choice_filter, stage_filter: filter.stage_filter
                                    ? Object.assign(Object.assign({}, filter.stage_filter), { rhs_stage: (_e = filter.stage_filter.rhs_stage) === null || _e === void 0 ? void 0 : _e.uid, rhs_stages: (_f = filter.stage_filter.rhs_stages) === null || _f === void 0 ? void 0 : _f.map((s) => s.uid) }) : filter.stage_filter, timestamp_filter: filter.timestamp_filter
                                    ? Object.assign(Object.assign({}, filter.timestamp_filter), { rhs_field: (_g = filter.timestamp_filter.rhs_field) === null || _g === void 0 ? void 0 : _g.uid }) : filter.timestamp_filter, office_filter: filter.office_filter
                                    ? Object.assign(Object.assign({}, filter.office_filter), { rhs_values: (_j = (_h = filter.office_filter.rhs_values) === null || _h === void 0 ? void 0 : _h.map((x) => x.uid)) !== null && _j !== void 0 ? _j : [] }) : filter.office_filter }));
                        }) }))) });
            setFormData(data);
            setInitialData(data);
        }
        else if (!uid) {
            const defaultTemplate = {
                name: `New Template (created ${dayjs_(new Date()).format("M/D/YYYY h:mm A")})`,
                assigned_agent: null,
                entity_type: "Buyer Lead",
                email_draft_template: {
                    sender_type: "assigned agent",
                    subject: "",
                    to: "",
                    cc: "",
                    bcc: "",
                    body: "",
                    allow_unsubscribe: false,
                },
                triggers: [],
                criteria: [
                    {
                        uid: uuidv4().replace(/-/g, ""),
                        filters: [],
                        parent_uid: null,
                        is_match_all: true,
                    },
                ],
                drive_attachments: [],
                gcp_attachments: [],
                is_active: true,
                earliest_process_time: null,
                latest_process_time: null,
                resend_maximum: 1,
                review_frequency: "Annual",
                mailschedule3_templates: [],
            };
            setFormData(defaultTemplate);
            setInitialData(defaultTemplate);
        }
        else {
            setFormData(null);
            setInitialData(null);
        }
    }, [template, uid]);
    const listTemplateTypesApi = useListMailschedule4TemplateTypes(INFINITE_CACHE_PARAMS);
    const templateTypes = (_d = (_c = listTemplateTypesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listDateOffsetUnitsApi = useListMailschedule4ResendUnits(INFINITE_CACHE_PARAMS);
    const dateOffsetUnits = (_f = (_e = listDateOffsetUnitsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listBoilerplateApi = useListMailschedule4Boilerplate(REFETCH_CACHE_PARAMS);
    const boilerplate = (_h = (_g = listBoilerplateApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const boilerplateForEntityType = React.useMemo(() => {
        if (!boilerplate || !(formData === null || formData === void 0 ? void 0 : formData.entity_type)) {
            return [];
        }
        return boilerplate.filter((b) => b.entity_type === formData.entity_type);
    }, [boilerplate, formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const listUsersAPi = usePostQuery(searchUsersBasic, { limit: 1000, disable_sort: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersAPi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_j = listUsersAPi.data) === null || _j === void 0 ? void 0 : _j.data]);
    const listSlackChannelsApi = useListSlackChannels({ exclude_archived: true }, INFINITE_CACHE_PARAMS);
    const slackChannels = (_l = (_k = listSlackChannelsApi.data) === null || _k === void 0 ? void 0 : _k.data.items) !== null && _l !== void 0 ? _l : null;
    const selectedChannel = React.useMemo(() => {
        var _a, _b;
        if (!slackChannels || !((_a = formData === null || formData === void 0 ? void 0 : formData.slack_template) === null || _a === void 0 ? void 0 : _a.channel_id)) {
            return null;
        }
        return (_b = slackChannels.find((c) => { var _a; return c.id === ((_a = formData.slack_template) === null || _a === void 0 ? void 0 : _a.channel_id); })) !== null && _b !== void 0 ? _b : null;
    }, [slackChannels, (_m = formData === null || formData === void 0 ? void 0 : formData.slack_template) === null || _m === void 0 ? void 0 : _m.channel_id]);
    const listSlackUsersApi = useListSlackUsers(INFINITE_CACHE_PARAMS);
    const slackUsers = (_p = (_o = listSlackUsersApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const listTwilioPhonesApi = useListCachedTwilioPhones({ include_agent_phones: false }, INFINITE_CACHE_PARAMS);
    const twilioPhones = (_r = (_q = listTwilioPhonesApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listFieldsApi.data]);
    const fieldsForEntityType = React.useMemo(() => {
        if (!fields || !(formData === null || formData === void 0 ? void 0 : formData.entity_type)) {
            return [];
        }
        return fields.filter((x) => {
            if (!formData.entity_type) {
                return false;
            }
            if (x.entity_type === formData.entity_type) {
                return true;
            }
            if (x.entity_type === "Buyer Lead" && formData.entity_type === "Buyer Contract") {
                return true;
            }
            if (x.entity_type === "Seller Lead" && formData.entity_type === "Listing") {
                return true;
            }
            return false;
        });
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type, fields]);
    const userFields = React.useMemo(() => {
        return fields === null || fields === void 0 ? void 0 : fields.filter((f) => f.entity_type === "User");
    }, [fields]);
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_t = (_s = listStagesApi.data) === null || _s === void 0 ? void 0 : _s.data) !== null && _t !== void 0 ? _t : null;
    const stagesForEntityType = React.useMemo(() => {
        if (!stages || !(formData === null || formData === void 0 ? void 0 : formData.entity_type)) {
            return [];
        }
        return stages.filter((f) => f.type === formData.entity_type);
    }, [stages, formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const timeFields = React.useMemo(() => {
        return getTimeFieldsInPipeline(fieldsForEntityType);
    }, [fieldsForEntityType]);
    const dateFields = React.useMemo(() => {
        return getDateFieldsInPipeline(fieldsForEntityType);
    }, [fieldsForEntityType]);
    const textFields = React.useMemo(() => {
        return getTextFieldsInPipeline(fieldsForEntityType);
    }, [fieldsForEntityType]);
    const agentMentionsFields = React.useMemo(() => {
        return (formData === null || formData === void 0 ? void 0 : formData.entity_type) ? getAgentMentionsFields(formData.entity_type, { fields: userFields }) : [];
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type, userFields]);
    const agentMentionsTextFields = React.useMemo(() => {
        return (formData === null || formData === void 0 ? void 0 : formData.entity_type)
            ? getAgentMentionsFields(formData.entity_type, { fields: userFields === null || userFields === void 0 ? void 0 : userFields.filter((f) => f.field_type === "text") })
            : [];
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type, userFields]);
    const vendorMentionsFields = React.useMemo(() => {
        return (formData === null || formData === void 0 ? void 0 : formData.entity_type) ? getVendorMentionsFields(formData.entity_type) : [];
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const clientMentionsFields = React.useMemo(() => {
        return (formData === null || formData === void 0 ? void 0 : formData.entity_type) ? getClientMentionsFields(formData.entity_type) : [];
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const formDataSanitized = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!formData) {
            return null;
        }
        return Object.assign(Object.assign({}, formData), { email_template: formData.email_template
                ? Object.assign(Object.assign({}, formData.email_template), { sender: formData.email_template.sender_type === "another email address"
                        ? formData.email_template.sender
                        : undefined }) : undefined, email_draft_template: formData.email_draft_template
                ? Object.assign(Object.assign({}, formData.email_draft_template), { sender: formData.email_draft_template.sender_type === "another email address"
                        ? formData.email_draft_template.sender
                        : undefined }) : undefined, sms_template: formData.sms_template
                ? Object.assign(Object.assign({}, formData.sms_template), { from_phone: (_a = formData.sms_template.from_phone) !== null && _a !== void 0 ? _a : undefined }) : undefined, sms_draft_template: formData.sms_draft_template
                ? Object.assign(Object.assign({}, formData.sms_draft_template), { drafted_by: (_b = formData.sms_draft_template.drafted_by) !== null && _b !== void 0 ? _b : undefined }) : undefined, calendar_template: formData.calendar_template
                ? Object.assign(Object.assign({}, formData.calendar_template), { host: (_c = formData.calendar_template.host) !== null && _c !== void 0 ? _c : undefined, start_time_field_uid: (_d = formData.calendar_template.start_time_field_uid) !== null && _d !== void 0 ? _d : undefined, start_time_offset: (_e = formData.calendar_template.start_time_offset) !== null && _e !== void 0 ? _e : undefined, start_time_absolute: (_f = formData.calendar_template.start_time_absolute) !== null && _f !== void 0 ? _f : undefined, duration: (_g = formData.calendar_template.duration) !== null && _g !== void 0 ? _g : undefined }) : undefined, slack_template: (_h = formData.slack_template) !== null && _h !== void 0 ? _h : undefined, crm_notification_template: (_j = formData.crm_notification_template) !== null && _j !== void 0 ? _j : undefined, resend_unit: (_k = formData.resend_unit) !== null && _k !== void 0 ? _k : undefined, resend_frequency: (_l = formData.resend_frequency) !== null && _l !== void 0 ? _l : undefined, mailschedule3_templates: formData.mailschedule3_templates.filter((x) => x) });
    }, [formData]);
    const hasChanges = !initialData || !formData || JSON.stringify(initialData) !== JSON.stringify(formData) || true; // for some reason this doesn't trigger when filters change
    const save = React.useCallback((onComplete) => () => {
        const loadingSnackbar = enqueueSnackbar("Saving template...", { variant: "info", persist: true });
        if (uid) {
            updateTemplateApi
                .mutateAsync({ uid, data: formDataSanitized })
                .then((result) => {
                if (onComplete) {
                    onComplete(result.data);
                }
                else if (formData === null || formData === void 0 ? void 0 : formData.entity_type) {
                    navigate(`/ms4/templates?pipeline=${formData.entity_type}`);
                }
                else {
                    navigate("/ms4/templates");
                }
                setInitialData(formData);
                queryClient.setQueryData(getTemplateApi.queryKey, result);
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            })
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
        else {
            createTemplateApi
                .mutateAsync({ data: formDataSanitized })
                .then((result) => {
                if (onComplete) {
                    onComplete(result.data);
                }
                else if (formData === null || formData === void 0 ? void 0 : formData.entity_type) {
                    navigate(`/ms4/templates?pipeline=${formData.entity_type}`);
                }
                else {
                    navigate("/ms4/templates");
                }
                setInitialData(formData);
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            })
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
    }, [
        createTemplateApi,
        formData,
        formDataSanitized,
        getTemplateApi.queryKey,
        navigate,
        queryClient,
        uid,
        updateTemplateApi,
    ]);
    const duplicate = React.useCallback(() => {
        console.log("saving");
        save((result) => {
            console.log("duplicating");
            duplicateTemplateApi
                .mutateAsync({ uid: result.uid })
                .then((duplicateResult) => {
                navigate(`/ms4/templates/${duplicateResult.data.uid}`);
            })
                .catch(enqueueErrorSnackbar);
        })();
    }, [duplicateTemplateApi, navigate, save]);
    const deleteTemplate = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                const loadingSnackbar = enqueueSnackbar("Deleting template...", { variant: "info", persist: true });
                deleteTemplateApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    if (formData === null || formData === void 0 ? void 0 : formData.entity_type) {
                        navigate(`/mailschedule/templates?pipeline=${formData.entity_type}`);
                    }
                    else {
                        navigate("/mailschedule/templates");
                    }
                    enqueueSnackbar("Template has been deleted.", { variant: "success" });
                })
                    .catch((error) => {
                    enqueueErrorSnackbar(error);
                })
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [confirm, deleteTemplateApi, formData === null || formData === void 0 ? void 0 : formData.entity_type, navigate, uid]);
    const setIsActive = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_active: checked })));
    }, []);
    const setPipeline = React.useCallback((e) => {
        confirm({
            title: "Are you sure?",
            description: "Changing the pipeline may lead to unexpected behavior for certain merge fields, and all triggers and criteria will be removed, because the Streak keys may be different for fields in the new pipeline. For best results, be sure to re-add all necessary triggers and criteria, and delete and re-add all merge fields to ensure they're in sync with the new pipeline.",
            confirmationText: "Change pipeline",
        }).then((result) => {
            if (result.confirmed) {
                setFormData((prev) => (Object.assign(Object.assign({}, prev), { entity_type: e.target.value, criteria: [
                        {
                            uid: uuidv4().replace(/-/g, ""),
                            filters: [],
                            parent_uid: null,
                            is_match_all: true,
                        },
                    ], triggers: [] })));
            }
        });
    }, [confirm]);
    const setTemplateType = React.useCallback((e) => {
        const templateType = e.target.value;
        setFormData((prev) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81;
            return (Object.assign(Object.assign({}, prev), { email_template: templateType === "Email"
                    ? {
                        sender_type: "assigned agent",
                        sender: undefined,
                        to: (_b = (_a = prev.email_draft_template) === null || _a === void 0 ? void 0 : _a.to) !== null && _b !== void 0 ? _b : "",
                        cc: (_d = (_c = prev.email_draft_template) === null || _c === void 0 ? void 0 : _c.cc) !== null && _d !== void 0 ? _d : "",
                        bcc: (_f = (_e = prev.email_draft_template) === null || _e === void 0 ? void 0 : _e.bcc) !== null && _f !== void 0 ? _f : "",
                        subject: (_k = (_h = (_g = prev.email_draft_template) === null || _g === void 0 ? void 0 : _g.subject) !== null && _h !== void 0 ? _h : (_j = prev.calendar_template) === null || _j === void 0 ? void 0 : _j.title) !== null && _k !== void 0 ? _k : "",
                        body: (_p = (_m = (_l = prev.email_draft_template) === null || _l === void 0 ? void 0 : _l.body) !== null && _m !== void 0 ? _m : (_o = prev.calendar_template) === null || _o === void 0 ? void 0 : _o.description) !== null && _p !== void 0 ? _p : "",
                        allow_unsubscribe: (_t = (_r = (_q = prev.email_draft_template) === null || _q === void 0 ? void 0 : _q.allow_unsubscribe) !== null && _r !== void 0 ? _r : (_s = prev.calendar_template) === null || _s === void 0 ? void 0 : _s.allow_unsubscribe) !== null && _t !== void 0 ? _t : false,
                    }
                    : undefined, email_draft_template: templateType === "Email Draft"
                    ? {
                        sender_type: "assigned agent",
                        sender: undefined,
                        to: (_v = (_u = prev.email_template) === null || _u === void 0 ? void 0 : _u.to) !== null && _v !== void 0 ? _v : "",
                        cc: (_x = (_w = prev.email_template) === null || _w === void 0 ? void 0 : _w.cc) !== null && _x !== void 0 ? _x : "",
                        bcc: (_z = (_y = prev.email_template) === null || _y === void 0 ? void 0 : _y.bcc) !== null && _z !== void 0 ? _z : "",
                        subject: (_3 = (_1 = (_0 = prev.email_template) === null || _0 === void 0 ? void 0 : _0.subject) !== null && _1 !== void 0 ? _1 : (_2 = prev.calendar_template) === null || _2 === void 0 ? void 0 : _2.title) !== null && _3 !== void 0 ? _3 : "",
                        body: (_7 = (_5 = (_4 = prev.email_template) === null || _4 === void 0 ? void 0 : _4.body) !== null && _5 !== void 0 ? _5 : (_6 = prev.calendar_template) === null || _6 === void 0 ? void 0 : _6.description) !== null && _7 !== void 0 ? _7 : "",
                        allow_unsubscribe: (_11 = (_9 = (_8 = prev.email_template) === null || _8 === void 0 ? void 0 : _8.allow_unsubscribe) !== null && _9 !== void 0 ? _9 : (_10 = prev.calendar_template) === null || _10 === void 0 ? void 0 : _10.allow_unsubscribe) !== null && _11 !== void 0 ? _11 : false,
                    }
                    : undefined, sms_template: templateType === "SMS"
                    ? {
                        use_agent_phone_number: (_15 = (_13 = (_12 = prev.sms_template) === null || _12 === void 0 ? void 0 : _12.use_agent_phone_number) !== null && _13 !== void 0 ? _13 : (_14 = prev.sms_draft_template) === null || _14 === void 0 ? void 0 : _14.use_agent_phone_number) !== null && _15 !== void 0 ? _15 : true,
                        from_phone: (_19 = (_17 = (_16 = prev.sms_template) === null || _16 === void 0 ? void 0 : _16.from_phone) !== null && _17 !== void 0 ? _17 : (_18 = prev.sms_draft_template) === null || _18 === void 0 ? void 0 : _18.from_phone) !== null && _19 !== void 0 ? _19 : null,
                        to_phone: (_23 = (_21 = (_20 = prev.sms_template) === null || _20 === void 0 ? void 0 : _20.to_phone) !== null && _21 !== void 0 ? _21 : (_22 = prev.sms_draft_template) === null || _22 === void 0 ? void 0 : _22.to_phone) !== null && _23 !== void 0 ? _23 : "",
                        message: (_31 = (_29 = (_27 = (_25 = (_24 = prev.sms_template) === null || _24 === void 0 ? void 0 : _24.message) !== null && _25 !== void 0 ? _25 : (_26 = prev.sms_draft_template) === null || _26 === void 0 ? void 0 : _26.message) !== null && _27 !== void 0 ? _27 : (_28 = prev.slack_template) === null || _28 === void 0 ? void 0 : _28.message) !== null && _29 !== void 0 ? _29 : (_30 = prev.crm_notification_template) === null || _30 === void 0 ? void 0 : _30.message) !== null && _31 !== void 0 ? _31 : "",
                        send_as_group_text: (_33 = (_32 = prev.sms_template) === null || _32 === void 0 ? void 0 : _32.send_as_group_text) !== null && _33 !== void 0 ? _33 : false,
                    }
                    : undefined, sms_draft_template: templateType === "SMS Draft"
                    ? {
                        use_agent_phone_number: (_37 = (_35 = (_34 = prev.sms_template) === null || _34 === void 0 ? void 0 : _34.use_agent_phone_number) !== null && _35 !== void 0 ? _35 : (_36 = prev.sms_draft_template) === null || _36 === void 0 ? void 0 : _36.use_agent_phone_number) !== null && _37 !== void 0 ? _37 : true,
                        from_phone: (_41 = (_39 = (_38 = prev.sms_template) === null || _38 === void 0 ? void 0 : _38.from_phone) !== null && _39 !== void 0 ? _39 : (_40 = prev.sms_draft_template) === null || _40 === void 0 ? void 0 : _40.from_phone) !== null && _41 !== void 0 ? _41 : null,
                        to_phone: (_45 = (_43 = (_42 = prev.sms_template) === null || _42 === void 0 ? void 0 : _42.to_phone) !== null && _43 !== void 0 ? _43 : (_44 = prev.sms_draft_template) === null || _44 === void 0 ? void 0 : _44.to_phone) !== null && _45 !== void 0 ? _45 : "",
                        message: (_53 = (_51 = (_49 = (_47 = (_46 = prev.sms_template) === null || _46 === void 0 ? void 0 : _46.message) !== null && _47 !== void 0 ? _47 : (_48 = prev.sms_draft_template) === null || _48 === void 0 ? void 0 : _48.message) !== null && _49 !== void 0 ? _49 : (_50 = prev.slack_template) === null || _50 === void 0 ? void 0 : _50.message) !== null && _51 !== void 0 ? _51 : (_52 = prev.crm_notification_template) === null || _52 === void 0 ? void 0 : _52.message) !== null && _53 !== void 0 ? _53 : "",
                        drafted_by_type: "assigned agent",
                        drafted_by: undefined,
                    }
                    : undefined, calendar_template: templateType === "Calendar"
                    ? {
                        assigned_agent_is_host: true,
                        host: null,
                        guests: (_57 = (_55 = (_54 = prev.email_template) === null || _54 === void 0 ? void 0 : _54.to) !== null && _55 !== void 0 ? _55 : (_56 = prev.email_draft_template) === null || _56 === void 0 ? void 0 : _56.to) !== null && _57 !== void 0 ? _57 : "",
                        title: (_61 = (_59 = (_58 = prev.email_template) === null || _58 === void 0 ? void 0 : _58.subject) !== null && _59 !== void 0 ? _59 : (_60 = prev.email_draft_template) === null || _60 === void 0 ? void 0 : _60.subject) !== null && _61 !== void 0 ? _61 : "",
                        location: "",
                        description: (_65 = (_63 = (_62 = prev.email_template) === null || _62 === void 0 ? void 0 : _62.body) !== null && _63 !== void 0 ? _63 : (_64 = prev.email_draft_template) === null || _64 === void 0 ? void 0 : _64.body) !== null && _65 !== void 0 ? _65 : "",
                        start_date_offset: 0,
                        start_date_field_uid: dateFields.length > 0 ? dateFields[0].uid : "",
                        is_all_day_event: true,
                        start_time_field_uid: undefined,
                        start_time_absolute: undefined,
                        start_time_offset: undefined,
                        duration: 60,
                        reminder_email_minutes_before: null,
                        reminder_popup_minutes_before: null,
                        google_meet: true,
                        color: "Pale Blue",
                        allow_unsubscribe: (_69 = (_67 = (_66 = prev.email_template) === null || _66 === void 0 ? void 0 : _66.allow_unsubscribe) !== null && _67 !== void 0 ? _67 : (_68 = prev.email_draft_template) === null || _68 === void 0 ? void 0 : _68.allow_unsubscribe) !== null && _69 !== void 0 ? _69 : false,
                    }
                    : undefined, slack_template: templateType === "Slack"
                    ? {
                        channel_id: slackChannels && slackChannels.length > 0 ? slackChannels[0].id : "",
                        recipients: null,
                        bot_name: "Dashbot",
                        message: (_75 = (_73 = (_71 = (_70 = prev.sms_template) === null || _70 === void 0 ? void 0 : _70.message) !== null && _71 !== void 0 ? _71 : (_72 = prev.sms_draft_template) === null || _72 === void 0 ? void 0 : _72.message) !== null && _73 !== void 0 ? _73 : (_74 = prev.crm_notification_template) === null || _74 === void 0 ? void 0 : _74.message) !== null && _75 !== void 0 ? _75 : "",
                    }
                    : undefined, crm_notification_template: templateType === "CRM Notification"
                    ? {
                        title: "Mail Schedule Alert",
                        message: (_81 = (_79 = (_77 = (_76 = prev.sms_template) === null || _76 === void 0 ? void 0 : _76.message) !== null && _77 !== void 0 ? _77 : (_78 = prev.sms_draft_template) === null || _78 === void 0 ? void 0 : _78.message) !== null && _79 !== void 0 ? _79 : (_80 = prev.slack_template) === null || _80 === void 0 ? void 0 : _80.message) !== null && _81 !== void 0 ? _81 : "",
                        should_send_email: true,
                    }
                    : undefined }));
        });
    }, [dateFields, slackChannels]);
    const setTemplateName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, []);
    const onAgentFieldChange = React.useCallback((_, agent, reason) => {
        console.log("SET AGENT TO ", agent, "REASON:", reason);
        const newValue = agent == null ? null : agent.uid;
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { assigned_agent: reason === "selectOption" ? newValue : null })));
    }, []);
    const setFromType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { sender_type: e.target.value, sender: undefined }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { sender_type: e.target.value, sender: undefined }) : undefined })));
    }, []);
    const setFrom = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { sender: newValue }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { sender: newValue }) : undefined })));
    }, []);
    const setHost = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { assigned_agent_is_host: newValue.trim() !== "", host: newValue.trim() === "" ? null : newValue }) })));
    }, []);
    const setTo = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { to: newValue }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { to: newValue }) : undefined })));
    }, []);
    const setCC = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { cc: newValue }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { cc: newValue }) : undefined })));
    }, []);
    const setBCC = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { bcc: newValue }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { bcc: newValue }) : undefined })));
    }, []);
    const setSubject = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { subject: newValue }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { subject: newValue }) : undefined })));
    }, []);
    const setAllowUnsubscribe = React.useCallback((_, isChecked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { allow_unsubscribe: isChecked }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { allow_unsubscribe: isChecked }) : undefined, calendar_template: prev.calendar_template
                ? Object.assign(Object.assign({}, prev.calendar_template), { allow_unsubscribe: isChecked }) : undefined })));
    }, []);
    const setGuests = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { guests: newValue }) })));
    }, []);
    const setTitle = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { title: newValue }) })));
    }, []);
    const setLocation = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { location: newValue }) })));
    }, []);
    const setStartDateOffset = React.useCallback((number) => {
        setFormData((prev) => {
            var _a;
            return (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { start_date_offset: number ? number * (((_a = prev.calendar_template.start_date_offset) !== null && _a !== void 0 ? _a : 0 < 0) ? -1 : 1) : 0 }) }));
        });
    }, []);
    const setStartDateOffsetComparison = React.useCallback((e) => {
        setFormData((prev) => {
            var _a;
            return (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { start_date_offset: Math.abs((_a = prev.calendar_template.start_date_offset) !== null && _a !== void 0 ? _a : 0) * (e.target.value === "before" ? -1 : 1) }) }));
        });
    }, []);
    const setStartDateField = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { start_date_field_uid: e.target.value }) })));
    }, []);
    const setIsAllDayEvent = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { is_all_day_event: checked, start_time_field: undefined, start_time_offset: undefined, start_time_absolute: undefined, duration: checked ? undefined : 60 }) })));
    }, []);
    const setUseStreakFieldForStartTime = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { start_time_field_uid: checked && timeFields.length > 0 ? timeFields[0].uid : undefined, start_time_offset: 0, start_time_absolute: undefined }) })));
    }, [timeFields]);
    const setStartTimeField = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { start_time_field_uid: e.target.value, start_time_absolute: undefined }) })));
    }, []);
    const setStartTimeAbsolute = React.useCallback((date) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { start_time_absolute: date !== null && date !== void 0 ? date : undefined }) })));
    }, []);
    const setDuration = React.useCallback((number) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { duration: number !== null && number !== void 0 ? number : 60 }) })));
    }, []);
    const setReminderEmailMinutesBefore = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { reminder_email_minutes_before: e.target.value ? numeral(e.target.value).value() : null }) })));
    }, []);
    const setReminderPopupMinutesBefore = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { reminder_popup_minutes_before: e.target.value ? numeral(e.target.value).value() : null }) })));
    }, []);
    const setEventColor = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { color: e.target.value }) })));
    }, []);
    const setGoogleMeet = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { google_meet: checked }) })));
    }, []);
    const setFromPhone = React.useCallback((e) => {
        const updates = {
            from_phone: e.target.value !== "" ? e.target.value : undefined,
            use_agent_phone_number: e.target.value === "",
        };
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { sms_template: prev.sms_template
                ? Object.assign(Object.assign({}, prev.sms_template), updates) : undefined, sms_draft_template: prev.sms_draft_template
                ? Object.assign(Object.assign({}, prev.sms_draft_template), updates) : undefined })));
    }, []);
    const setToPhone = React.useCallback((_, newValue) => {
        const updates = {
            to_phone: newValue,
        };
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { sms_template: prev.sms_template
                ? Object.assign(Object.assign({}, prev.sms_template), updates) : undefined, sms_draft_template: prev.sms_draft_template
                ? Object.assign(Object.assign({}, prev.sms_draft_template), updates) : undefined })));
    }, []);
    const setSendAsGroupMMS = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { sms_template: Object.assign(Object.assign({}, prev.sms_template), { send_as_group_text: checked }) })));
    }, []);
    const setDraftedByType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { sms_draft_template: Object.assign(Object.assign({}, prev.sms_draft_template), { drafted_by_type: e.target.value, drafted_by: e.target.value === "assigned agent" ? undefined : "" }) })));
    }, []);
    const setDraftedBy = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { sms_draft_template: Object.assign(Object.assign({}, prev.sms_draft_template), { drafted_by: newValue }) })));
    }, []);
    const setSlackChannel = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { slack_template: Object.assign(Object.assign({}, prev.slack_template), { channel_id: e.target.value === "" ? null : e.target.value, recipients: null }) })));
    }, []);
    const setSlackRecipients = React.useCallback((_, newValue) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { slack_template: Object.assign(Object.assign({}, prev.slack_template), { channel_id: null, recipients: newValue !== null && newValue !== void 0 ? newValue : null }) })));
    }, []);
    const setBotName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { slack_template: Object.assign(Object.assign({}, prev.slack_template), { bot_name: e.target.value }) })));
    }, []);
    const setEarliestProcessTime = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { earliest_process_time: e.target.value || null })));
    }, []);
    const setLatestProcessTime = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { latest_process_time: e.target.value || null })));
    }, []);
    const setResendMaximum = React.useCallback((number) => {
        setFormData((prev) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, prev), { resend_maximum: number, resend_frequency: number === 1 ? undefined : ((_a = prev.resend_frequency) !== null && _a !== void 0 ? _a : 1), resend_unit: number === 1 ? undefined : ((_b = prev.resend_unit) !== null && _b !== void 0 ? _b : "day") }));
        });
    }, []);
    const setResendFrequency = React.useCallback((number) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { resend_frequency: number !== null && number !== void 0 ? number : undefined })));
    }, []);
    const setResendUnit = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { resend_unit: e.target.value })));
    }, []);
    const setReviewFrequency = React.useCallback((_, value) => {
        let val = null;
        if (value === 1) {
            val = "Quarterly";
        }
        else if (value === 2) {
            val = "Annual";
        }
        else if (value !== 0) {
            throw new Error(`Unexpected value ${value}`);
        }
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { review_frequency: val })));
    }, []);
    const setMessage = React.useCallback((e, newValue, newPlainTextValue, mentions) => {
        console.log("Setting Mentions Input Message", e, newValue, "-----", newPlainTextValue, mentions);
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { body: newValue }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { body: newValue }) : undefined, sms_template: prev.sms_template
                ? Object.assign(Object.assign({}, prev.sms_template), { message: newValue }) : undefined, sms_draft_template: prev.sms_draft_template
                ? Object.assign(Object.assign({}, prev.sms_draft_template), { message: newValue }) : undefined, slack_template: prev.slack_template
                ? Object.assign(Object.assign({}, prev.slack_template), { message: newValue }) : undefined, crm_notification_template: prev.crm_notification_template
                ? Object.assign(Object.assign({}, prev.crm_notification_template), { message: newValue }) : undefined })));
        // setMessagePlainText(newPlainTextValue)
    }, []);
    const setBody = React.useCallback((body) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { email_template: prev.email_template
                ? Object.assign(Object.assign({}, prev.email_template), { body }) : undefined, email_draft_template: prev.email_draft_template
                ? Object.assign(Object.assign({}, prev.email_draft_template), { body }) : undefined })));
    }, []);
    const setShouldSendEmail = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { crm_notification_template: Object.assign(Object.assign({}, prev.crm_notification_template), { should_send_email: checked }) })));
    }, []);
    const setCalendarDescription = React.useCallback((description) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { calendar_template: Object.assign(Object.assign({}, prev.calendar_template), { description }) })));
    }, []);
    const addTrigger = React.useCallback(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { triggers: [
                ...prev.triggers,
                {
                    stage_trigger: {
                        from_stage_uid: null,
                        to_stage_uid: null,
                    },
                },
            ] })));
    }, []);
    const deleteTrigger = React.useCallback((indx) => () => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { triggers: prev.triggers.filter((_, i) => i !== indx) })));
    }, []);
    const setTriggerType = React.useCallback((indx) => (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { triggers: [
                ...prev.triggers.slice(0, indx),
                Object.assign(Object.assign({}, prev.triggers[indx]), { field_trigger: e.target.value === "field" ? { field_uid: null } : undefined, stage_trigger: e.target.value === "stage" ? { from_stage_uid: null, to_stage_uid: null } : undefined }),
                ...prev.triggers.slice(indx + 1, prev.triggers.length),
            ] })));
    }, []);
    const setTriggerFromStage = React.useCallback((indx) => (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { triggers: [
                ...prev.triggers.slice(0, indx),
                Object.assign(Object.assign({}, prev.triggers[indx]), { stage_trigger: Object.assign(Object.assign({}, prev.triggers[indx].stage_trigger), { from_stage_uid: e.target.value || null }) }),
                ...prev.triggers.slice(indx + 1, prev.triggers.length),
            ] })));
    }, []);
    const setTriggerToStage = React.useCallback((indx) => (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { triggers: [
                ...prev.triggers.slice(0, indx),
                Object.assign(Object.assign({}, prev.triggers[indx]), { stage_trigger: Object.assign(Object.assign({}, prev.triggers[indx].stage_trigger), { to_stage_uid: e.target.value || null }) }),
                ...prev.triggers.slice(indx + 1, prev.triggers.length),
            ] })));
    }, []);
    const setTriggerField = React.useCallback((indx) => (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { triggers: [
                ...prev.triggers.slice(0, indx),
                Object.assign(Object.assign({}, prev.triggers[indx]), { field_trigger: Object.assign(Object.assign({}, prev.triggers[indx].field_trigger), { field_uid: e.target.value || null }) }),
                ...prev.triggers.slice(indx + 1, prev.triggers.length),
            ] })));
    }, []);
    const setCriteria = React.useCallback((criteria) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { criteria })));
    }, []);
    const addAttachment = React.useCallback(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { drive_attachments: [...prev.drive_attachments, ""] })));
    }, []);
    const removeAttachment = React.useCallback((attachment) => () => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { drive_attachments: prev.drive_attachments.filter((a) => a !== attachment) })));
    }, []);
    const updateAttachment = React.useCallback((indx) => (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { drive_attachments: [
                ...prev.drive_attachments.slice(0, indx),
                e.target.value,
                ...prev.drive_attachments.slice(indx + 1, prev.drive_attachments.length),
            ] })));
    }, []);
    const addGCPAttachment = React.useCallback(() => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { gcp_attachments: [...prev.gcp_attachments, { blob_name: "" }] })));
    }, []);
    const removeGCPAttachment = React.useCallback((index) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { gcp_attachments: prev.gcp_attachments.filter((_, indx) => indx !== index) })));
    }, []);
    const updateGCPAttachmentBlobName = React.useCallback((attachmentIndex, prefix, choice) => {
        const isFile = choice.includes(".pdf");
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { gcp_attachments: [
                ...prev.gcp_attachments.slice(0, attachmentIndex),
                Object.assign(Object.assign({}, prev.gcp_attachments[attachmentIndex]), { blob_name: prefix + choice + (isFile ? "" : "/") }),
                ...prev.gcp_attachments.slice(attachmentIndex + 1, prev.gcp_attachments.length),
            ] })));
    }, []);
    const setMailSchedule3Templates = React.useCallback((templateUids) => {
        // @ts-expect-error: null is not assignable to type 'string'
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { mailschedule3_templates: templateUids })));
    }, []);
    const handleItemSelectedForPreview = React.useCallback((item) => {
        setItemForPreview(item);
    }, []);
    const openPreview = React.useCallback(() => {
        setIsPreviewOpen(true);
    }, []);
    const handlePreviewClosed = React.useCallback(() => {
        setIsPreviewOpen(false);
    }, []);
    const handleTemplateProcessed = React.useCallback(() => {
        enqueueSnackbar("Template processed successfully", { variant: "success" });
        handlePreviewClosed();
    }, [handlePreviewClosed]);
    const checkWildcards = React.useCallback((value, checkEmailAddresses = false) => {
        const matches = (value !== null && value !== void 0 ? value : "").match(/\$\'(.*?)\'/g);
        if (!matches) {
            return null;
        }
        console.log(checkEmailAddresses); // TODO: I feel like we need this for something, but not sure what
        console.log("matches", matches);
        const errorFields = [];
        for (const match of matches) {
            const fieldNameMatch = match.match(/\$\'(.*?)\'/)[1];
            if (!fieldsForEntityType.find((field) => fieldNameMatch === field.name)) {
                errorFields.push(fieldNameMatch);
            }
        }
        if (errorFields.length === 0) {
            return null;
        }
        return `Field${errorFields.length > 1 ? "s" : ""} ${errorFields.join(", ")} not found in pipeline ${formData === null || formData === void 0 ? void 0 : formData.entity_type}. Make sure the field exists with the same capitalization and spacing as you've written here.`;
    }, [fieldsForEntityType, formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const doFieldReplacements = React.useCallback((text) => {
        let _text = text;
        for (const field of fieldsForEntityType) {
            try {
                const regexp = new RegExp(`\\$'(${field.uid})`, "g");
                _text = _text.replace(regexp, `$$'${field.name}`);
            }
            catch (err) {
                console.warn(`Unable to do sanitization for field ${field.name} of pipeline ${formData === null || formData === void 0 ? void 0 : formData.entity_type}`, err);
                return _text;
            }
        }
        return _text;
    }, [fieldsForEntityType, formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const hasEmptyFilterGroup = React.useMemo(() => {
        return formData === null || formData === void 0 ? void 0 : formData.criteria.some((x) => x.parent_uid != null && x.filters.length === 0 && !formData.criteria.find((y) => y.parent_uid === x.uid));
    }, [formData === null || formData === void 0 ? void 0 : formData.criteria]);
    const mailScheduleQuillFields = React.useMemo(() => {
        return [
            ...fieldsForEntityType.map((field) => ({
                id: field.uid,
                value: field.name,
            })),
            ...clientMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
            ...agentMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
            ...vendorMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
        ];
    }, [agentMentionsFields, clientMentionsFields, fieldsForEntityType, vendorMentionsFields]);
    if (isPreviewOpen) {
        return (React.createElement(Box, { p: 4 },
            React.createElement(Paper, { elevation: 4, style: { borderTop: `5px solid ${AppConfig.themeColors.lightblue}` } },
                React.createElement(Box, { p: 2 },
                    React.createElement(MailScheduleTemplatePreview, { entity: {
                            entityType: formData.entity_type,
                            uid: ((_3 = (_1 = (_z = (_x = (_v = (_u = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.buyerLead) === null || _u === void 0 ? void 0 : _u.uid) !== null && _v !== void 0 ? _v : (_w = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.buyerContract) === null || _w === void 0 ? void 0 : _w.uid) !== null && _x !== void 0 ? _x : (_y = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.sellerContract) === null || _y === void 0 ? void 0 : _y.uid) !== null && _z !== void 0 ? _z : (_0 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.sellerLead) === null || _0 === void 0 ? void 0 : _0.uid) !== null && _1 !== void 0 ? _1 : (_2 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.user) === null || _2 === void 0 ? void 0 : _2.uid) !== null && _3 !== void 0 ? _3 : (_4 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.recruit) === null || _4 === void 0 ? void 0 : _4.uid),
                        }, template: formDataSanitized, onClose: handlePreviewClosed, onTemplateProcessed: handleTemplateProcessed })))));
    }
    if (!formData) {
        return React.createElement(CoreLoading, null);
    }
    for (const key of Object.keys(formData)) {
        if (typeof formData[key] === "string") {
            formData[key] = doFieldReplacements(formData[key]);
        }
    }
    return (React.createElement(Box, { p: 4 },
        React.createElement(Paper, { elevation: 4, style: { borderTop: `5px solid ${AppConfig.themeColors.lightblue}` } },
            React.createElement(Box, { component: Grid2, container: true, p: 2, spacing: 2 },
                React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: formData.is_active, onChange: setIsActive, color: "primary" }), label: "Enabled" })),
                    React.createElement(Grid2, { size: { xs: 12, md: 3 } },
                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "Pipeline", value: formData.entity_type, onChange: setPipeline, helperText: "The Pipeline criteria is required for every template. Template will only be processed for boxes that reside in the indicated pipeline." },
                            React.createElement(MenuItem, { key: -1, value: "" }),
                            entityTypes.map((x) => (React.createElement(MenuItem, { key: x, value: x }, `${x.replace("Listing", "Seller Contract")}s`))))),
                    React.createElement(Grid2, { size: { xs: 12, md: 3 } },
                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "Template Type", value: getTemplateType(formData), onChange: setTemplateType, helperText: templateTypeHelperText(getTemplateType(formData)) },
                            React.createElement(MenuItem, { key: -1, value: "" }),
                            (templateTypes !== null && templateTypes !== void 0 ? templateTypes : []).map((templateType) => (React.createElement(MenuItem, { key: templateType.name, value: templateType.name }, templateType.name))))),
                    React.createElement(Grid2, { size: { xs: 12, md: 3 } },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Template Name", value: formData.name, onChange: setTemplateName, helperText: "The Template Name is used for internal purposes only; it doesn't appear in the email subject, event title, or as any other artifact." })),
                    React.createElement(Grid2, { size: { xs: 12, md: 3 } },
                        React.createElement(Autocomplete, { disableClearable: true, value: (users !== null && users !== void 0 ? users : []).find((user) => user.uid === formData.assigned_agent), forcePopupIcon: false, options: [null, ...(users !== null && users !== void 0 ? users : [])], noOptionsText: "No agents found", getOptionLabel: getAgentName, renderInput: renderAgentSearchInput, renderOption: renderAgentDropdownOption, onChange: onAgentFieldChange }))),
                (formData.email_template || formData.email_draft_template) &&
                    (() => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        const t = ((_a = formData.email_template) !== null && _a !== void 0 ? _a : formData.email_draft_template);
                        return (React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                            React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                                React.createElement(Grid2, { size: { xs: 12, md: t.sender_type === "another email address" ? 6 : 12 } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "From", required: true, value: (_b = t.sender_type) !== null && _b !== void 0 ? _b : "assigned agent", onChange: setFromType, helperText: (() => {
                                            if (t.sender_type === "assigned agent") {
                                                return "The message will be sent or drafted from the Streak assignee's inbox.";
                                            }
                                            if (t.sender_type === "another email address") {
                                                return "The message will be sent or drafted from the specified email address.";
                                            }
                                            if (t.sender_type === "noreply") {
                                                return "The message will be sent from noreply@dashnc.com.";
                                            }
                                            return "";
                                        })() }, ["assigned agent", formData.email_template && "noreply", "another email address"]
                                        .filter((obj) => obj)
                                        .map((obj) => (React.createElement(MenuItem, { key: obj, value: obj }, obj))))),
                                t.sender_type === "another email address" && (React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                                    React.createElement(Typography, { variant: "caption" }, "From email"),
                                    React.createElement(MentionsInput, { value: (_c = t.sender) !== null && _c !== void 0 ? _c : "", onChange: setFrom, error: !!checkWildcards(t.sender), helperText: (_d = checkWildcards(t.sender)) !== null && _d !== void 0 ? _d : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "Must be a valid DASH email address." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: agentMentionsTextFields })))),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "To"),
                                React.createElement(MentionsInput, { value: t.to, onChange: setTo, error: !!checkWildcards(t.to, true), helperText: (_e = checkWildcards(t.to, true)) !== null && _e !== void 0 ? _e : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                        ...agentMentionsTextFields,
                                        ...clientMentionsFields,
                                        ...vendorMentionsFields,
                                    ] })),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Cc"),
                                React.createElement(MentionsInput, { value: t.cc, onChange: setCC, error: !!checkWildcards(t.cc, true), helperText: (_f = checkWildcards(t.cc, true)) !== null && _f !== void 0 ? _f : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                        ...agentMentionsTextFields,
                                        ...clientMentionsFields,
                                        ...vendorMentionsFields,
                                    ] })),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Bcc"),
                                React.createElement(MentionsInput, { value: t.bcc, onChange: setBCC, error: !!checkWildcards(t.bcc, true), helperText: (_g = checkWildcards(t.bcc, true)) !== null && _g !== void 0 ? _g : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                        ...agentMentionsTextFields,
                                        ...clientMentionsFields,
                                        ...vendorMentionsFields,
                                    ] })),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Subject"),
                                React.createElement(MentionsInput, { value: t.subject, onChange: setSubject, error: !!checkWildcards(t.subject), helperText: (_h = checkWildcards(t.subject)) !== null && _h !== void 0 ? _h : React.createElement(MailScheduleTemplateHelperText, null), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                        ...agentMentionsTextFields,
                                        ...clientMentionsFields,
                                        ...vendorMentionsFields,
                                    ] }))));
                    })(),
                formData.calendar_template &&
                    (() => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                        const t = formData.calendar_template;
                        return (React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Host"),
                                React.createElement(MentionsInput, { value: (_a = t.host) !== null && _a !== void 0 ? _a : "", onChange: setHost, placeholder: "Assigned Agent", error: !!checkWildcards((_b = t.host) !== null && _b !== void 0 ? _b : "", true), helperText: (_d = checkWildcards((_c = t.host) !== null && _c !== void 0 ? _c : "", true)) !== null && _d !== void 0 ? _d : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a valid email address." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Guests"),
                                React.createElement(MentionsInput, { value: t.guests, onChange: setGuests, error: !!checkWildcards(t.guests, true), helperText: (_e = checkWildcards(t.guests, true)) !== null && _e !== void 0 ? _e : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Title"),
                                React.createElement(MentionsInput, { value: t.title, onChange: setTitle, error: !!checkWildcards(t.title), helperText: (_f = checkWildcards(t.title)) !== null && _f !== void 0 ? _f : React.createElement(MailScheduleTemplateHelperText, null), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })),
                            React.createElement(Grid2, { size: 12 },
                                React.createElement(Typography, { variant: "caption" }, "Location"),
                                React.createElement(MentionsInput, { value: t.location, onChange: setLocation, error: !!checkWildcards(t.location), helperText: (_g = checkWildcards(t.location)) !== null && _g !== void 0 ? _g : React.createElement(MailScheduleTemplateHelperText, null), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })),
                            React.createElement(Grid2, { container: true, size: 12, spacing: 1 },
                                React.createElement(Grid2, { style: { marginTop: "auto" } },
                                    React.createElement(Typography, { variant: "body2" }, "Date")),
                                React.createElement(Grid2, { style: { flexGrow: 1 } },
                                    React.createElement(Grid2, { container: true, spacing: 1 },
                                        React.createElement(Grid2, { size: 1 },
                                            React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: t.start_date_offset === null || t.start_date_offset === undefined
                                                    ? 0
                                                    : Math.abs(t.start_date_offset), format: "0,0", min: 0, max: 60, onNumberChange: setStartDateOffset })),
                                        React.createElement(Grid2, { size: 2 },
                                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: !t.start_date_offset || t.start_date_offset < 0 ? "before" : "after", onChange: setStartDateOffsetComparison },
                                                React.createElement(MenuItem, { value: "before" }, "days before"),
                                                React.createElement(MenuItem, { value: "after" }, "days after"))),
                                        React.createElement(Grid2, { size: 9, style: { marginTop: "auto" } },
                                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, placeholder: "Streak field", value: t.start_date_field_uid, onChange: setStartDateField },
                                                React.createElement(MenuItem, { key: -1, value: "" }),
                                                dateFields.map((field) => (React.createElement(MenuItem, { key: field.uid, value: field.uid }, field.name)))))))),
                            React.createElement(Grid2, { size: 12, style: { marginTop: "auto" } },
                                React.createElement(FormControlLabel, { label: "All Day Event?", control: React.createElement(Checkbox, { color: "primary", checked: !!t.is_all_day_event, onChange: setIsAllDayEvent, size: "small" }) })),
                            !t.is_all_day_event && (React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                                React.createElement(Grid2, { size: { xs: 12, sm: 4 }, style: { marginTop: "auto" } },
                                    React.createElement(FormControlLabel, { label: "Use field for Start Time?", control: React.createElement(Checkbox, { color: "primary", checked: !!t.start_time_field_uid, onChange: setUseStreakFieldForStartTime, size: "small" }) })),
                                t.start_time_field_uid ? (React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Event Time Field", value: (_h = t.start_time_field_uid) !== null && _h !== void 0 ? _h : null, onChange: setStartTimeField }, timeFields.map((field) => (React.createElement(MenuItem, { key: field.uid, value: field.uid }, field.name)))))) : (React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                                    React.createElement(CoreDateField, { variant: "standard", fullWidth: true, label: "Event Time", format: "h:mm A", mode: "time", date: (_j = t.start_time_absolute) !== null && _j !== void 0 ? _j : null, onDateChange: setStartTimeAbsolute }))),
                                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                                    React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, required: true, label: "Duration (minutes)", min: 1, max: 10080, format: "0,0", number: (_k = t.duration) !== null && _k !== void 0 ? _k : null, onNumberChange: setDuration })))),
                            React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Reminder Email", value: (_l = t.reminder_email_minutes_before) !== null && _l !== void 0 ? _l : "", onChange: setReminderEmailMinutesBefore }, [
                                        { name: "None", value: "" },
                                        { name: "5 minutes before", value: 5 },
                                        { name: "10 minutes before", value: 10 },
                                        { name: "15 minutes before", value: 15 },
                                        { name: "30 minutes before", value: 30 },
                                        { name: "1 hour before", value: 60 },
                                        { name: "2 hours before", value: 120 },
                                        { name: "3 hours before", value: 180 },
                                        { name: "24 hours before", value: 1440 },
                                    ].map((option) => (React.createElement(MenuItem, { key: option.name, value: option.value }, option.name))))),
                                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Reminder Popup", value: (_m = t.reminder_popup_minutes_before) !== null && _m !== void 0 ? _m : "", onChange: setReminderPopupMinutesBefore }, [
                                        { name: "None", value: "" },
                                        { name: "5 minutes before", value: 5 },
                                        { name: "10 minutes before", value: 10 },
                                        { name: "15 minutes before", value: 15 },
                                        { name: "30 minutes before", value: 30 },
                                        { name: "1 hour before", value: 60 },
                                        { name: "2 hours before", value: 120 },
                                        { name: "3 hours before", value: 180 },
                                        { name: "24 hours before", value: 1440 },
                                    ].map((option) => (React.createElement(MenuItem, { key: option.name, value: option.value }, option.name))))),
                                React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Event Color", value: t.color, onChange: setEventColor }, [
                                        "Pale Blue",
                                        "Pale Green",
                                        "Mauve",
                                        "Pale Red",
                                        "Yellow",
                                        "Orange",
                                        "Cyan",
                                        "Gray",
                                        "Blue",
                                        "Green",
                                        "Red",
                                    ].map((color) => (React.createElement(MenuItem, { key: color, value: color }, color))))),
                                React.createElement(Grid2, { size: 12 },
                                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: t.google_meet, onChange: setGoogleMeet, color: "primary" }), label: "Include Link to Google Meet" })))));
                    })(),
                ((_5 = formData.sms_template) !== null && _5 !== void 0 ? _5 : formData.sms_draft_template) &&
                    (() => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        const t = ((_a = formData.sms_template) !== null && _a !== void 0 ? _a : formData.sms_draft_template);
                        return (React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                            React.createElement(Grid2, { size: { xs: 12, md: formData.sms_draft_template ? 3 : 6 } },
                                React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "From", value: (_b = t.from_phone) !== null && _b !== void 0 ? _b : "", onChange: setFromPhone, SelectProps: {
                                        displayEmpty: true,
                                    }, InputLabelProps: {
                                        shrink: true,
                                    } },
                                    React.createElement(MenuItem, { key: -1, value: "" }, "agent's phone"),
                                    (twilioPhones !== null && twilioPhones !== void 0 ? twilioPhones : [])
                                        .filter((phone) => { var _a; return (_a = phone.capabilities) === null || _a === void 0 ? void 0 : _a.sms; })
                                        .map((phone) => (React.createElement(MenuItem, { key: phone.phone_number, value: phone.phone_number }, phone.friendly_name))))),
                            React.createElement(Grid2, { size: { xs: 12, md: formData.sms_draft_template ? 3 : 6 } },
                                React.createElement(Typography, { variant: "caption" }, "To"),
                                React.createElement(MentionsInput, { value: (_c = t.to_phone) !== null && _c !== void 0 ? _c : "", required: true, onChange: setToPhone, error: !!checkWildcards(t.to_phone), helperText: (_d = checkWildcards(t.to_phone)) !== null && _d !== void 0 ? _d : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid phone numbers." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })),
                            formData.sms_template && (React.createElement(Grid2, { size: 12 },
                                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: (_e = t.send_as_group_text) !== null && _e !== void 0 ? _e : false, onChange: setSendAsGroupMMS, color: "primary" }), label: "Send as Group MMS" }))),
                            formData.sms_draft_template && (React.createElement(React.Fragment, null,
                                React.createElement(Grid2, { size: {
                                        xs: 12,
                                        md: formData.sms_draft_template.drafted_by_type === "another email address" ? 3 : 6,
                                    } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Drafted by", required: true, value: (_f = formData.sms_draft_template.drafted_by_type) !== null && _f !== void 0 ? _f : "", onChange: setDraftedByType },
                                        React.createElement(MenuItem, { key: -1, value: "" }),
                                        ["assigned agent", "another email address"].map((obj) => (React.createElement(MenuItem, { key: obj, value: obj }, obj))))),
                                formData.sms_draft_template.drafted_by_type === "another email address" && (React.createElement(Grid2, { size: { xs: 12, sm: 3 } },
                                    React.createElement(Typography, { variant: "caption" }, "Email Address"),
                                    React.createElement(MentionsInput, { value: (_g = formData.sms_draft_template.drafted_by) !== null && _g !== void 0 ? _g : "", onChange: setDraftedBy, error: !!checkWildcards(formData.sms_draft_template.drafted_by), helperText: (_h = checkWildcards(formData.sms_draft_template.drafted_by)) !== null && _h !== void 0 ? _h : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "Must be a valid DASH email address." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })))))));
                    })(),
                formData.slack_template && (React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                    React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Channel", value: (_6 = formData.slack_template.channel_id) !== null && _6 !== void 0 ? _6 : "", onChange: setSlackChannel, error: selectedChannel !== null && !selectedChannel.is_member, helperText: selectedChannel && !selectedChannel.is_member ? "Make sure Dashbot has access to the channel" : null },
                            React.createElement(MenuItem, { key: -1, value: "" }),
                            (slackChannels !== null && slackChannels !== void 0 ? slackChannels : [])
                                .filter((channel) => channel.name)
                                .map((channel) => (React.createElement(MenuItem, { key: channel.id, value: channel.id }, channel.name))))),
                    React.createElement(Grid2, { size: { xs: 12, sm: 4 }, style: { marginTop: "13px" } },
                        React.createElement(MentionsInput, { value: (_7 = formData.slack_template.recipients) !== null && _7 !== void 0 ? _7 : undefined, onChange: setSlackRecipients, error: !!checkWildcards((_8 = formData.slack_template.recipients) !== null && _8 !== void 0 ? _8 : undefined, true), helperText: (_10 = checkWildcards((_9 = formData.slack_template.recipients) !== null && _9 !== void 0 ? _9 : undefined, true)) !== null && _10 !== void 0 ? _10 : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, fields: textFields, additionalAutocompleteOptions: [...agentMentionsTextFields, ...vendorMentionsFields] })),
                    React.createElement(Grid2, { size: { xs: 12, sm: 4 } },
                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Bot Name", value: (_11 = formData.slack_template.bot_name) !== null && _11 !== void 0 ? _11 : "Dashbot", onChange: setBotName })))),
                formData.crm_notification_template && (React.createElement(Grid2, { size: 12 },
                    React.createElement(FormControlLabel, { label: "Send email?", control: React.createElement(Switch, { color: "primary", checked: formData.crm_notification_template.should_send_email, onChange: setShouldSendEmail, tabIndex: -1 }) }))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Box, { component: Grid2, container: true, size: 12, padding: 1, border: 1, borderRadius: "8px", borderColor: "grey.500", style: { margin: "1rem" } },
                    React.createElement(Grid2, { container: true, size: 12 },
                        React.createElement(Grid2, { style: { flexGrow: 1 } },
                            React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Triggers"),
                            React.createElement(Typography, { variant: "caption", style: { color: "#aaa" }, dangerouslySetInnerHTML: {
                                    __html: "If criteria are specified below, the template will be sent <em>only when the criteria are satisfied.</em> Otherwise, it will be sent whenever any of the triggers are triggered.",
                                } })),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: "Add trigger" },
                                React.createElement(IconButton, { size: "small", onClick: addTrigger },
                                    React.createElement(Add, { fontSize: "small" }))))),
                    React.createElement(Grid2, { container: true, size: 12 }, formData.triggers.length === 0 ? (React.createElement(Typography, { variant: "body2" },
                        "Press the ",
                        React.createElement(Add, { fontSize: "small" }),
                        " button to create a new trigger.")) : (formData.triggers.map((trigger, indx) => {
                        var _a, _b, _c;
                        return (React.createElement(Grid2, { key: indx, container: true, size: 12 },
                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Tooltip, { title: "Delete trigger" },
                                    React.createElement(IconButton, { size: "small", onClick: deleteTrigger(indx) },
                                        React.createElement(Delete, { fontSize: "small" })))),
                            React.createElement(Grid2, { container: true, style: { flexGrow: 1 } },
                                React.createElement(Grid2, { container: true, size: 12 },
                                    React.createElement(Grid2, { size: 4, style: { paddingRight: "4px" } },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "Type", value: trigger.field_trigger ? "field" : "stage", onChange: setTriggerType(indx) },
                                            React.createElement(MenuItem, { key: "stage", value: "stage" }, "Stage change"),
                                            React.createElement(MenuItem, { key: "field", value: "field" }, "Field change"))),
                                    trigger.stage_trigger && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid2, { size: 4, style: { paddingRight: "4px" } },
                                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "From Stage", value: (_a = trigger.stage_trigger.from_stage_uid) !== null && _a !== void 0 ? _a : "", onChange: setTriggerFromStage(indx) }, stagesForEntityType.map((stage) => (React.createElement(MenuItem, { key: stage.uid, value: stage.uid }, stage.name))))),
                                        React.createElement(Grid2, { size: 4 },
                                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "To Stage", value: (_b = trigger.stage_trigger.to_stage_uid) !== null && _b !== void 0 ? _b : "", onChange: setTriggerToStage(indx) }, stagesForEntityType.map((stage) => (React.createElement(MenuItem, { key: stage.uid, value: stage.uid }, stage.name))))))),
                                    trigger.field_trigger && (React.createElement(Grid2, { size: 8 },
                                        React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, select: true, label: "Field name", value: (_c = trigger.field_trigger.field_uid) !== null && _c !== void 0 ? _c : "", onChange: setTriggerField(indx) }, fieldsForEntityType.map((field) => (React.createElement(MenuItem, { key: field.uid, value: field.uid }, field.name))))))))));
                    })))),
                React.createElement(Box, { component: Grid2, container: true, size: 12, padding: 1, border: 1, borderRadius: "8px", borderColor: "grey.500", style: { margin: "1rem" } },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Grid2, { container: true, spacing: 1 },
                            React.createElement(Grid2, { style: { flex: 1 } },
                                React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Criteria"),
                                React.createElement(Typography, { variant: "caption", style: { color: "#aaa" }, dangerouslySetInnerHTML: {
                                        __html: "The template will <em>only</em> be sent if/when these criteria are satisfied.",
                                    } })),
                            formData.criteria.map((fg) => fg.filters).flat().length > 0 && (React.createElement(Grid2, null,
                                React.createElement(CreateViewForFiltersButton, { entityType: formData.entity_type, filters: formData.criteria, name: formData.name }))))),
                    hasEmptyFilterGroup && (React.createElement(Grid2, { size: 12 },
                        React.createElement(Alert, { severity: "warning" },
                            React.createElement(AlertTitle, null, "Empty Filter Group"),
                            "At least one filter group is empty. Please add filters to the empty filter group(s) or remove them."))),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(FilterGroups, { filterGroups: formData.criteria, onUpdate: setCriteria, entityType: formData.entity_type }))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                    React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, null, "Send no earlier than")),
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(TextField, { variant: "standard", id: "time", type: "time", value: (_12 = formData.earliest_process_time) !== null && _12 !== void 0 ? _12 : "", onChange: setEarliestProcessTime, slotProps: {
                                    htmlInput: {
                                        step: 300, // 5 min
                                    },
                                    inputLabel: {
                                        shrink: true,
                                    },
                                } }))),
                    React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, null, "Send no later than")),
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(TextField, { variant: "standard", id: "time", type: "time", value: (_13 = formData.latest_process_time) !== null && _13 !== void 0 ? _13 : "", onChange: setLatestProcessTime, slotProps: {
                                    htmlInput: {
                                        step: 300, // 5 min
                                    },
                                    inputLabel: {
                                        shrink: true,
                                    },
                                } })))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                    React.createElement(Grid2, { style: { marginTop: "auto", textAlign: "right" } },
                        React.createElement(Typography, { variant: "body2" }, "This template should be processed at most")),
                    React.createElement(Grid2, { style: { marginTop: "auto", width: "100px" } },
                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, placeholder: "infinite", number: (_14 = formData.resend_maximum) !== null && _14 !== void 0 ? _14 : null, min: 1, max: 999, format: "0,0", onNumberChange: setResendMaximum })),
                    formData.triggers.length > 0 ? (React.createElement(Grid2, { style: { marginTop: "auto", textAlign: "right" } },
                        React.createElement(Typography, { variant: "body2" }, formData.resend_maximum !== 1 ? "times" : " time"))) : (React.createElement(Grid2, { style: { marginTop: "auto", textAlign: "right" } },
                        React.createElement(Typography, { variant: "body2" }, formData.resend_maximum !== 1 ? "times; at frequently as once every" : " time"))),
                    formData.resend_maximum !== 1 && formData.triggers.length === 0 && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, { style: { marginTop: "auto", width: "50px" } },
                            React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, min: 1, max: 999, format: "0", number: (_15 = formData.resend_frequency) !== null && _15 !== void 0 ? _15 : 1, onNumberChange: setResendFrequency })),
                        React.createElement(Grid2, { style: { marginTop: "auto", width: "75px" } },
                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, value: (_16 = formData.resend_unit) !== null && _16 !== void 0 ? _16 : "day", onChange: setResendUnit }, (dateOffsetUnits !== null && dateOffsetUnits !== void 0 ? dateOffsetUnits : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, `${obj.name + (formData.resend_frequency !== 1 ? "s" : "")}`)))))))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { container: true, size: 12, spacing: 4 },
                    React.createElement(Grid2, { style: {
                            marginTop: "auto",
                            marginBottom: "auto",
                            textAlign: "right",
                        } },
                        React.createElement(Typography, { variant: "body2" }, "Review Frequency: ")),
                    React.createElement(Grid2, { style: { marginTop: "auto", width: "200px" } },
                        React.createElement(Slider, { value: formData.review_frequency
                                ? {
                                    Annual: 2,
                                    Quarterly: 1,
                                }[formData.review_frequency]
                                : 0, step: 1, marks: true, min: 0, max: 2, onChange: setReviewFrequency })),
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, { variant: "body2" }, (_17 = formData.review_frequency) !== null && _17 !== void 0 ? _17 : "n/a"))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                fieldsForEntityType.length > 0 && (React.createElement(Grid2, { size: 12 },
                    React.createElement(Box, { p: 4, style: { border: "1px solid #ccc", borderRadius: "0.5rem" } },
                        React.createElement(IfStatementBuilder, { fields: fieldsForEntityType, variant: ((_19 = (_18 = formData.email_template) !== null && _18 !== void 0 ? _18 : formData.email_draft_template) !== null && _19 !== void 0 ? _19 : formData.calendar_template)
                                ? "html"
                                : "plaintext" })))),
                ((_21 = (_20 = formData.email_template) !== null && _20 !== void 0 ? _20 : formData.email_draft_template) !== null && _21 !== void 0 ? _21 : formData.calendar_template) ? (React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, { variant: "caption" }, ((_22 = formData.email_template) !== null && _22 !== void 0 ? _22 : formData.email_draft_template) ? "Body" : "Description"),
                    React.createElement(MailScheduleQuill, { ReactQuillProps: {
                            value: (_28 = (_26 = (_24 = (_23 = formData.email_template) === null || _23 === void 0 ? void 0 : _23.body) !== null && _24 !== void 0 ? _24 : (_25 = formData.email_draft_template) === null || _25 === void 0 ? void 0 : _25.body) !== null && _26 !== void 0 ? _26 : (_27 = formData.calendar_template) === null || _27 === void 0 ? void 0 : _27.description) !== null && _28 !== void 0 ? _28 : "",
                            onChange: ((_29 = formData.email_template) !== null && _29 !== void 0 ? _29 : formData.email_draft_template) ? setBody : setCalendarDescription,
                        }, fields: mailScheduleQuillFields, boilerplate: boilerplateForEntityType, entityType: formData.entity_type }))) : ((() => {
                    var _a, _b, _c, _d, _e;
                    const t = ((_c = (_b = (_a = formData.crm_notification_template) !== null && _a !== void 0 ? _a : formData.slack_template) !== null && _b !== void 0 ? _b : formData.sms_template) !== null && _c !== void 0 ? _c : formData.sms_draft_template);
                    return (React.createElement(Grid2, { size: 12 },
                        React.createElement(MentionsInput, { value: (_d = t.message) !== null && _d !== void 0 ? _d : "", onChange: setMessage, fields: fieldsForEntityType !== null && fieldsForEntityType !== void 0 ? fieldsForEntityType : undefined, users: formData.crm_notification_template ? (users !== null && users !== void 0 ? users : undefined) : undefined, slackUsers: formData.slack_template ? ((_e = slackUsers === null || slackUsers === void 0 ? void 0 : slackUsers.filter((user) => !user.deleted)) !== null && _e !== void 0 ? _e : undefined) : undefined, additionalAutocompleteOptions: [...agentMentionsFields, ...vendorMentionsFields] })));
                })()),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: bodyHelperText } })),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(MailScheduleFormattersHelper, null)),
                (formData.email_template || formData.email_draft_template || formData.calendar_template) &&
                    (() => {
                        var _a, _b, _c;
                        const t = ((_b = (_a = formData.email_template) !== null && _a !== void 0 ? _a : formData.email_draft_template) !== null && _b !== void 0 ? _b : formData.calendar_template);
                        return (React.createElement(Grid2, { size: 12 },
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "When enabled, user will be able to unsubscribe") },
                                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: (_c = t.allow_unsubscribe) !== null && _c !== void 0 ? _c : false, onChange: setAllowUnsubscribe, color: "primary" }), label: "Allow Unsubscribe" }))));
                    })(),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Grid2, { container: true },
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Attachments")),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: "Add attachment" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: addAttachment, disabled: formData.drive_attachments.filter((a) => !a.trim()).length > 0 },
                                        React.createElement(Add, null))))))),
                formData.drive_attachments.length > 0 && (React.createElement(Grid2, { size: 12 },
                    React.createElement(List, { dense: true }, formData.drive_attachments.map((attachment, indx) => (
                    // (OK here because it's not reorderable)
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement(ListItem, { key: indx },
                        React.createElement(ListItemText, { primary: React.createElement(TextField, { value: attachment, onChange: updateAttachment(indx), fullWidth: true, variant: "outlined", size: "small", placeholder: "https://drive.google.com/file/d/<file_id>/view" }) }),
                        React.createElement(ListItemSecondaryAction, null,
                            React.createElement(Tooltip, { title: "Remove attachment" },
                                React.createElement(IconButton, { onClick: removeAttachment(attachment), tabIndex: -1 },
                                    React.createElement(Delete, null)))))))))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, { variant: "caption" }, "Press the + button above to add an attachment from Google Drive. At this time, Attachments only work with Email and Email Draft templates.")),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Grid2, { container: true },
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body1" }, "Google Cloud Platform Bucket")),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: "Add Google Cloud Platform file" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: addGCPAttachment },
                                        React.createElement(Add, null))))))),
                formData.gcp_attachments.length > 0 && (React.createElement(Grid2, { size: 12 },
                    React.createElement(GCPAttachments, { gcpAttachments: formData.gcp_attachments, onAttachmentRemoved: removeGCPAttachment, onAttachmentUpdated: updateGCPAttachmentBlobName }))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, { variant: "caption" }, "Press the + button above to add an attachment from a Google Cloud bucket. At this time, Attachments only work with Email and Email Draft templates.")),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(MailSchedule3Templates, { selectedTemplates: formData.mailschedule3_templates, onUpdate: setMailSchedule3Templates })),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Search
                    // @ts-expect-error: string not assignable to type
                    , { 
                        // @ts-expect-error: string not assignable to type
                        searchTypes: [
                            formData.entity_type === "Buyer Contract" ? "buyerContracts" : null,
                            formData.entity_type === "Buyer Lead" ? "buyerLeads" : null,
                            formData.entity_type === "Listing" ? "sellerContracts" : null,
                            formData.entity_type === "Seller Lead" ? "sellerLeads" : null,
                            formData.entity_type === "User" ? "users" : null,
                            formData.entity_type === "Recruit" ? "recruits" : null,
                        ].filter((o) => o), onItemSelected: handleItemSelectedForPreview, TextFieldProps: {
                            slotProps: {
                                input: {
                                    endAdornment: itemForPreview ? (React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(Button, { color: "inherit", disabled: !itemForPreview, onClick: openPreview }, "Preview"),
                                        React.createElement(Button, { color: "inherit", component: CoreLink, href: getLinkForEntity(formData.entity_type === "Buyer Contract"
                                                ? "Buyer Lead"
                                                : formData.entity_type === "Listing"
                                                    ? "Seller Lead"
                                                    : formData.entity_type, ((_41 = (_38 = (_36 = (_34 = (_32 = (_31 = (_30 = itemForPreview.buyerContract) === null || _30 === void 0 ? void 0 : _30.buyer_lead) === null || _31 === void 0 ? void 0 : _31.uid) !== null && _32 !== void 0 ? _32 : (_33 = itemForPreview.buyerLead) === null || _33 === void 0 ? void 0 : _33.uid) !== null && _34 !== void 0 ? _34 : (_35 = itemForPreview.recruit) === null || _35 === void 0 ? void 0 : _35.uid) !== null && _36 !== void 0 ? _36 : (_37 = itemForPreview.sellerLead) === null || _37 === void 0 ? void 0 : _37.uid) !== null && _38 !== void 0 ? _38 : (_40 = (_39 = itemForPreview.sellerContract) === null || _39 === void 0 ? void 0 : _39.seller_lead) === null || _40 === void 0 ? void 0 : _40.uid) !== null && _41 !== void 0 ? _41 : (_42 = itemForPreview.user) === null || _42 === void 0 ? void 0 : _42.uid)), target: "_blank" }, "Open Box"))) : undefined,
                                },
                            },
                            size: "small",
                            placeholder: "Search for a box to preview",
                        }, variant: "standard", defaultSearchString: (_54 = (_52 = (_50 = (_48 = (_46 = (_44 = (_43 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.buyerContract) === null || _43 === void 0 ? void 0 : _43.name) !== null && _44 !== void 0 ? _44 : (_45 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.buyerLead) === null || _45 === void 0 ? void 0 : _45.name) !== null && _46 !== void 0 ? _46 : (_47 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.contact) === null || _47 === void 0 ? void 0 : _47.name) !== null && _48 !== void 0 ? _48 : (_49 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.recruit) === null || _49 === void 0 ? void 0 : _49.name) !== null && _50 !== void 0 ? _50 : (_51 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.sellerContract) === null || _51 === void 0 ? void 0 : _51.name) !== null && _52 !== void 0 ? _52 : (_53 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.sellerLead) === null || _53 === void 0 ? void 0 : _53.name) !== null && _54 !== void 0 ? _54 : (_55 = itemForPreview === null || itemForPreview === void 0 ? void 0 : itemForPreview.user) === null || _55 === void 0 ? void 0 : _55.name, showNoResults: !itemForPreview })),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { container: true, size: 12, spacing: 1 },
                    uid && (React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", color: "secondary", variant: "contained", startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: deleteTemplate, disabled: isDuplicating || isSaving || isDeleting, tabIndex: -1 }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", color: "inherit", variant: "contained", startIcon: React.createElement(FileCopy, { fontSize: "small" }), onClick: duplicate, disabled: isDuplicating || isSaving || isDeleting, tabIndex: -1 }, "Save and Duplicate")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", color: "primary", variant: "contained", startIcon: React.createElement(Save, { fontSize: "small" }), onClick: save(), disabled: isDuplicating || isSaving || isDeleting || !hasChanges || hasEmptyFilterGroup, tabIndex: -1 }, "Save")))))));
};
export default MailScheduleTemplateForm;
