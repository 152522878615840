import * as React from "react";
import { Chip, Grid, Typography } from "@mui/material";
import dayjs_ from "@app/common/dayjs";
import { fontColorContrastMemoized, getFullNameMemoized } from "@app/util/Utils";
import { groupBy } from "../../../../utils/utils";
const StageChangeItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { item, stages } = props;
    const stagesByUid = React.useMemo(() => {
        return stages ? groupBy(stages, "uid") : null;
    }, [stages]);
    const previousStage = ((_a = item.previous_value) === null || _a === void 0 ? void 0 : _a.stage) ? ((_b = stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[item.previous_value.stage.uid]) !== null && _b !== void 0 ? _b : null) : null;
    const newStage = ((_c = item.new_value) === null || _c === void 0 ? void 0 : _c.stage) ? ((_d = stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[item.new_value.stage.uid]) !== null && _d !== void 0 ? _d : null) : null;
    return (React.createElement(Grid, { container: true, spacing: 1, style: { overflowX: "hidden", overflowY: "hidden", lineHeight: 1 } },
        React.createElement(Grid, { item: true, style: { flex: 1 } }, item.is_initial_value ? (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, getFullNameMemoized((_f = (_e = item.userstamp) === null || _e === void 0 ? void 0 : _e.first_name) !== null && _f !== void 0 ? _f : null, (_h = (_g = item.userstamp) === null || _g === void 0 ? void 0 : _g.last_name) !== null && _h !== void 0 ? _h : null) ||
                    "Anonymous user or Automation")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, "set the stage to")),
            React.createElement(Grid, { item: true }, newStage ? (React.createElement(Chip, { size: "small", style: {
                    background: newStage.color,
                    color: fontColorContrastMemoized(newStage.color),
                    height: "16px",
                    transform: "translateY(-3px)",
                }, label: newStage.name })) : null))) : (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, getFullNameMemoized((_k = (_j = item.userstamp) === null || _j === void 0 ? void 0 : _j.first_name) !== null && _k !== void 0 ? _k : null, (_m = (_l = item.userstamp) === null || _l === void 0 ? void 0 : _l.last_name) !== null && _m !== void 0 ? _m : null) ||
                    "Anonymous user or Automation")),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, "changed the stage from")),
            React.createElement(Grid, { item: true }, previousStage ? (React.createElement(Chip, { size: "small", style: {
                    background: previousStage.color,
                    color: fontColorContrastMemoized(previousStage.color),
                    height: "16px",
                    transform: "translateY(-3px)",
                }, label: previousStage.name })) : null),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2" }, "to")),
            React.createElement(Grid, { item: true }, newStage ? (React.createElement(Chip, { size: "small", style: {
                    background: newStage.color,
                    color: fontColorContrastMemoized(newStage.color),
                    height: "16px",
                    transform: "translateY(-3px)",
                }, label: newStage.name })) : null)))),
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { style: {
                    float: "right",
                    color: "#ccc",
                }, variant: "caption" }, dayjs_(item.timestamp).isValid()
                ? dayjs_(new Date(item.timestamp)).format("M/D/YYYY h:mm A")
                : item.timestamp))));
};
export default StageChangeItem;
