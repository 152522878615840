import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import SOP from "@brokerage/features/apps/sops/SOP";
const SOPPage = () => {
    useDocumentTitle("SOPs");
    const params = useParams();
    const uid = params.uid;
    return React.createElement(SOP, { uid: uid });
};
export default SOPPage;
