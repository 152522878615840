import * as React from "react";
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Collapse } from "@mui/material";
import styled from "styled-components";
import { ACCEPTABLE_COLOR, EXPECTED_COLOR, getMetricScore, UNACCEPTABLE_COLOR } from "./utils";
// styled components or themeprovider
const StyledTable = styled(Table) `
  white-space: break-word;
  width: 100% !important;
  table-layout: fixed;

  th {
    font-weight: bold;
  }

  td {
    height: 1rem;
  }

  th, td {
    text-align: center;
    padding: 0px 0px 0px 5px;
  }

  tbody td:first-child {
    font-weight: bold;
    width: 120px;
    text-align: left;
  }

  tbody td:not(:first-child) {
    text-align: center;
  }

  td.Expected {
    background: ${EXPECTED_COLOR};
  }

  td.Acceptable {
    background: ${ACCEPTABLE_COLOR};
  }

  td.Unacceptable {
    background: ${UNACCEPTABLE_COLOR};
  }

  tr &:hover {
    cursor: pointer !important;
  }

  .sub-row:first-child {
    width: 92px;
  }
`;
export const SIDEBAR_WIDTH = 250;
/**
 * Sidebar content for DashScore page.
 * Displays content based on the current level.
 *  */
const DashScoreSidebar = (props) => {
    const [expandedNames, setExpandedNames] = React.useState([]);
    const { agents, currentLevel, dashScores, isOpen, isSmallWindow, offices, selectAgent, settings, teams } = props;
    const dashScoresFiltered = React.useMemo(() => {
        return [...dashScores].filter((item) => {
            if (currentLevel === "Office") {
                return offices.includes(item.name);
            }
            if (currentLevel === "Team") {
                return teams.includes(item.name);
            }
            return agents.includes(item.name);
        });
    }, [agents, currentLevel, dashScores, offices, teams]);
    /** ordered by DASHscore descending */
    const orderedDashscores = React.useMemo(() => {
        return dashScoresFiltered.sort((a, b) => {
            var _a, _b;
            const aScore = (_a = a.dashscore) !== null && _a !== void 0 ? _a : 0;
            const bScore = (_b = b.dashscore) !== null && _b !== void 0 ? _b : 0;
            if (aScore > bScore)
                return -1;
            if (aScore < bScore)
                return 1;
            return 0;
        });
    }, [dashScoresFiltered]);
    const toggleExpandedNames = React.useCallback((name) => {
        setExpandedNames((prevState) => {
            return prevState.includes(name)
                ? prevState.filter((item) => {
                    return item !== name;
                })
                : [...prevState, name];
        });
    }, [setExpandedNames]);
    const onAgentSelect = React.useCallback((name) => () => {
        if (currentLevel !== "Agent") {
            toggleExpandedNames(name);
        }
        selectAgent(name);
    }, [currentLevel, selectAgent, toggleExpandedNames]);
    if (dashScoresFiltered.length === 0) {
        return React.createElement(Box, { p: 2 });
    }
    console.log({ isSmallWindow, isOpen });
    const shouldTransitionWidth = isOpen || isSmallWindow;
    return (React.createElement(Box, { pl: 4, width: SIDEBAR_WIDTH * (shouldTransitionWidth ? 1 : 0), height: isOpen || !isSmallWindow ? undefined : 0, style: {
            transition: `${shouldTransitionWidth ? "width" : "height"} 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
            flexShrink: 0,
            whiteSpace: "nowrap",
        } },
        React.createElement(StyledTable, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, currentLevel),
                    React.createElement(TableCell, null, "DASHScore"))),
            React.createElement(TableBody, null, orderedDashscores.map((scoreData) => {
                var _a;
                const score = scoreData.dashscore == null ? null : Math.ceil(scoreData.dashscore);
                const className = (_a = getMetricScore(score, settings.dashscore_red_value, settings.dashscore_green_value)) !== null && _a !== void 0 ? _a : "";
                const subjectName = scoreData.name;
                return (React.createElement(React.Fragment, null,
                    React.createElement(TableRow, { key: `${subjectName}_dashscore_list`, onClick: onAgentSelect(subjectName) },
                        React.createElement(TableCell, { className: className },
                            React.createElement(Typography, null, subjectName)),
                        React.createElement(TableCell, { className: className },
                            React.createElement(Typography, null, score == null ? "" : Math.ceil(score)))),
                    scoreData.member_names && expandedNames.includes(subjectName) && (React.createElement(TableRow, { key: `${subjectName}_dashscore_list_members` },
                        React.createElement(TableCell, { colSpan: 2, style: { paddingBottom: 0, paddingTop: 0 } },
                            React.createElement(Collapse, { in: expandedNames.includes(subjectName) },
                                React.createElement(Box, { pl: 4 },
                                    React.createElement(StyledTable, null,
                                        React.createElement(TableBody, null, scoreData.member_names
                                            .split(",")
                                            .sort((a, b) => {
                                            var _a, _b;
                                            const aMemberScoreData = dashScores.find((item) => {
                                                return item.name.toLowerCase().trim() === a.toLowerCase().trim();
                                            });
                                            const bMemberScoreData = dashScores.find((item) => {
                                                return item.name.toLowerCase().trim() === b.toLowerCase().trim();
                                            });
                                            const aScore = (_a = aMemberScoreData.dashscore) !== null && _a !== void 0 ? _a : 0;
                                            const bScore = (_b = bMemberScoreData.dashscore) !== null && _b !== void 0 ? _b : 0;
                                            if (aScore > bScore)
                                                return -1;
                                            if (aScore < bScore)
                                                return 1;
                                            return 0;
                                        })
                                            .map((memberName) => {
                                            var _a;
                                            const memberScoreData = dashScores.find((item) => {
                                                return item.name.toLowerCase().trim() === memberName.toLowerCase().trim();
                                            });
                                            const memberScore = memberScoreData.dashscore != null ? Math.ceil(memberScoreData.dashscore) : null;
                                            const memberClassName = (_a = getMetricScore(memberScore !== null && memberScore !== void 0 ? memberScore : 0, settings.dashscore_red_value, settings.dashscore_green_value)) !== null && _a !== void 0 ? _a : "";
                                            return (React.createElement(TableRow, { key: `${memberName}_dashscore_list_child` },
                                                React.createElement(TableCell, { className: memberClassName, style: { width: "92px" } }, memberName),
                                                React.createElement(TableCell, { className: memberClassName }, memberScore)));
                                        }))))))))));
            })))));
};
export default DashScoreSidebar;
