import * as React from "react";
import { Business, Edit, Email, LabelImportant, Phone, RemoveCircleOutline } from "@mui/icons-material";
import { Avatar, Chip, Grid2, IconButton, ListItemAvatar, ListItem, ListItemText, Tooltip, Typography, Button, } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import CallLink from "@app/common/links/CallLink";
import EmailLink from "@app/common/links/EmailLink";
import useSession from "@app/hooks/useSession";
import { useListStages } from "@app/orval/api/stages";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import VendorInfoDialog from "./VendorInfoDialog";
const ThirdPartyListItem = (props) => {
    var _a, _b, _c, _d, _e;
    const { buyerContracts, buyerLead, canEdit = true, getBuyerLeadApi, getSellerLeadApi, numTransactions, onEditParty, onRemoveParty, party, sellerContracts, sellerLead, } = props;
    const confirm = useConfirm();
    const session = useSession();
    const [isVendorInfoDialogOpen, setIsVendorInfoDialogOpen] = React.useState(false);
    const listStagesApi = useListStages({}, INFINITE_CACHE_PARAMS);
    const stages = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const stagesByUid = React.useMemo(() => {
        return stages === null || stages === void 0 ? void 0 : stages.reduce((acc, stage) => {
            acc[stage.uid] = stage;
            return acc;
        }, {});
    }, [stages]);
    const removeParty = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This will remove the third party from the lead; it will not delete the contact record.", confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveParty(party.uid);
            }
        });
    }, [confirm, onRemoveParty, party.uid]);
    const editParty = React.useCallback(() => {
        onEditParty(party.uid);
    }, [onEditParty, party.uid]);
    const initials = [party.contact.first_name, party.contact.last_name]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o === null || o === void 0 ? void 0 : o.substring(0, 1))
        .join("");
    const name = [party.contact.first_name, party.contact.last_name].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" ");
    const contractsByUid = React.useMemo(() => {
        const contracts = [...(buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : []), ...(sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : [])];
        const res = contracts.reduce((acc, contract) => {
            acc[contract.uid] = contract;
            return acc;
        }, {});
        return res;
    }, [buyerContracts, sellerContracts]);
    const openVendorInfoDialog = React.useCallback(() => {
        setIsVendorInfoDialogOpen(true);
    }, []);
    const closeVendorInfoDialog = React.useCallback(() => {
        setIsVendorInfoDialogOpen(false);
    }, []);
    const canEdit_ = canEdit && (!!((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"])) || numTransactions === 1);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, Object.assign({ style: { paddingTop: 0, paddingBottom: 0 }, secondaryAction: canEdit ? (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit third party") },
                    React.createElement("span", null,
                        React.createElement(IconButton, { size: "small", onClick: editParty },
                            React.createElement(Edit, { fontSize: "small" })))),
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove third party") },
                    React.createElement("span", null,
                        React.createElement(IconButton, { size: "small", onClick: removeParty, 
                            // can't delete the third party if it's associated with a closed or terminated contract
                            disabled: !((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin", "Admin"])) &&
                                ((_d = party.associated_with_contracts) === null || _d === void 0 ? void 0 : _d.some((x) => (buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts.some((c) => { var _a, _b; return c.uid === x && ["Closed", "Terminated"].includes((_b = (_a = c.stage) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""); })) ||
                                    (sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts.some((c) => { var _a, _b; return c.uid === x && ["Closed", "Terminated"].includes((_b = (_a = c.stage) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ""); })))) },
                            React.createElement(RemoveCircleOutline, { fontSize: "small" })))))) : null }, props.ListItemProps),
            React.createElement(ListItemAvatar, null,
                React.createElement(Avatar, { src: undefined, style: { height: "28px", width: "28px", fontSize: "0.75rem" } }, initials)),
            React.createElement(ListItemText, { style: { marginTop: 0, marginBottom: 0 }, primary: canEdit_ ? (React.createElement(Button, { onClick: openVendorInfoDialog, style: { textDecoration: "none", padding: 0, lineHeight: 0, textTransform: "unset" }, variant: "text", size: "small", disableRipple: true }, name.trim() || "(unknown name)")) : (name.trim() || "(unknown name)"), secondary: React.createElement(Grid2, { container: true, spacing: 0, direction: "column", wrap: "nowrap" },
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Chip, { label: party.type, size: "small", style: { height: "10px" } })),
                    party.notes && (React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption", style: { fontStyle: "italic", color: "#aaa" } }, party.notes))),
                    party.contact.email && (React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, wrap: "nowrap", spacing: 1 },
                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Email, { fontSize: "small", style: { color: "#888", height: "12px" } })),
                            React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(EmailLink, { emailAddresses: [party.contact.email], TypographyProps: { variant: "caption" } }))))),
                    party.contact.phone && (React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, wrap: "nowrap", spacing: 1 },
                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Phone, { fontSize: "small", style: { color: "#888", height: "12px" } })),
                            React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(CallLink, { phoneNumber: party.contact.phone, name: name, TypographyProps: { variant: "caption" } }))))),
                    party.contact.company && (React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, wrap: "nowrap", spacing: 1 },
                            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Business, { fontSize: "small", style: { color: "#888", height: "12px" } })),
                            React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Typography, { variant: "caption" }, party.contact.company))))),
                    ((_e = party.associated_with_contracts) !== null && _e !== void 0 ? _e : []).map((contractUid) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return (React.createElement(Grid2, { key: contractUid },
                            React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                                React.createElement(Grid2, null,
                                    React.createElement(LabelImportant, { fontSize: "small", style: {
                                            color: (_g = stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[(_f = (_c = (_b = (_a = buyerLead === null || buyerLead === void 0 ? void 0 : buyerLead.contracts.find((x) => x.uid === contractUid)) === null || _a === void 0 ? void 0 : _a.stage) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : (_e = (_d = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts.find((x) => x.uid === contractUid)) === null || _d === void 0 ? void 0 : _d.stage) === null || _e === void 0 ? void 0 : _e.uid) !== null && _f !== void 0 ? _f : ""]) === null || _g === void 0 ? void 0 : _g.color,
                                        } })),
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "caption", style: { fontStyle: "italic", color: "#aaa" } },
                                        "Associated with ", (_j = (_h = contractsByUid[contractUid]) === null || _h === void 0 ? void 0 : _h.address) !== null && _j !== void 0 ? _j : "contract")))));
                    })) })),
        isVendorInfoDialogOpen && (React.createElement(VendorInfoDialog, { vendor: party, onConfirm: closeVendorInfoDialog, onCancel: closeVendorInfoDialog, DialogProps: { open: true, onClose: closeVendorInfoDialog }, getBuyerLeadApi: getBuyerLeadApi, getSellerLeadApi: getSellerLeadApi }))));
};
export default ThirdPartyListItem;
