import * as React from "react";
import { Close, Person } from "@mui/icons-material";
import { Grid2, IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import logo from "@app/assets/img/logo-notext.png";
import TwilioSMSRecipientSelect from "@app/entrypoints/brokerage/context/twilio-sms/TwilioSMSRecipientSelect";
import useAllowOverlay from "@app/hooks/useAllowOverlay";
import { isValidPhoneNumber } from "@app/util/Utils";
import SMSPopOutButton from "./SMSPopOutButton";
const ConversationSelect = (props) => {
    const { onClose, onSelect, selectedExternalPhoneNumber, selectedTwilioPhoneNumber, twilioPhoneNumbers } = props;
    const allowOverlay = useAllowOverlay();
    const [externalPhoneNumber, setExternalPhoneNumber] = React.useState(selectedExternalPhoneNumber);
    console.log({ externalPhoneNumber, selectedExternalPhoneNumber });
    React.useEffect(() => {
        setExternalPhoneNumber(selectedExternalPhoneNumber);
    }, [selectedExternalPhoneNumber]);
    const handleTwilioPhoneNumberChanged = React.useCallback((e) => {
        onSelect(e.target.value, selectedExternalPhoneNumber);
    }, [selectedExternalPhoneNumber, onSelect]);
    const handleExternalPhoneNumberChanged = React.useCallback((value) => {
        setExternalPhoneNumber(value);
    }, []);
    const handleExternalPhoneNumberBlurred = React.useCallback(() => {
        onSelect(selectedTwilioPhoneNumber, externalPhoneNumber);
    }, [externalPhoneNumber, onSelect, selectedTwilioPhoneNumber]);
    const close = React.useCallback(() => {
        onClose();
    }, [onClose]);
    return (React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
        React.createElement(Grid2, { style: { flex: 1 } },
            React.createElement(Grid2, { container: true, spacing: 1 },
                React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                    React.createElement(TextField, { size: "small", variant: "outlined", value: selectedTwilioPhoneNumber !== null && selectedTwilioPhoneNumber !== void 0 ? selectedTwilioPhoneNumber : "", onChange: handleTwilioPhoneNumberChanged, select: true, fullWidth: true, slotProps: {
                            input: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement("img", { src: logo, alt: "DASH Realty", style: { height: "20px" } }))),
                            },
                        }, disabled: !twilioPhoneNumbers }, (twilioPhoneNumbers !== null && twilioPhoneNumbers !== void 0 ? twilioPhoneNumbers : []).map((phoneNumber) => (React.createElement(MenuItem, { key: phoneNumber.phone_number, value: phoneNumber.phone_number }, phoneNumber.friendly_name || phoneNumber.phone_number))))),
                React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                    React.createElement(TwilioSMSRecipientSelect, { phoneNumber: externalPhoneNumber !== null && externalPhoneNumber !== void 0 ? externalPhoneNumber : null, onPhoneNumberChanged: handleExternalPhoneNumberChanged, TextFieldProps: {
                            slotProps: {
                                input: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(Person, null))),
                                },
                            },
                            onBlur: handleExternalPhoneNumberBlurred,
                            helperText: externalPhoneNumber && isValidPhoneNumber(externalPhoneNumber)
                                ? `Phone number: ${externalPhoneNumber}`
                                : undefined,
                        } })))),
        React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                allowOverlay && (React.createElement(Grid2, null,
                    React.createElement(SMSPopOutButton, { fromPhoneNumber: selectedTwilioPhoneNumber, toPhoneNumber: externalPhoneNumber }))),
                React.createElement(Grid2, null,
                    React.createElement(IconButton, { size: "small", onClick: close, tabIndex: -1 },
                        React.createElement(Close, { fontSize: "small" })))))));
};
export default ConversationSelect;
