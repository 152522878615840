import * as React from "react";
import { Reorder } from "@mui/icons-material";
import { List, ListItemIcon } from "@mui/material";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import ClientListItem from "./ClientListItem";
const ClientsReorder = (props) => {
    const { allowEditing, clients, disableDialog, getBuyerLeadApi, getSellerLeadApi, onRemoveClient, onReorderClients } = props;
    const [clients_, setClients_] = React.useState(clients);
    const reorder = React.useCallback((result) => {
        if (result.destination && result.source.index !== result.destination.index) {
            const newItems = clients_.map((c) => c.client.uid);
            const item = newItems[result.source.index];
            newItems.splice(result.source.index, 1);
            newItems.splice(result.destination.index, 0, item);
            onReorderClients(newItems);
            // setting state here prevents a momentary reset to the previous order
            setClients_(clients_.sort((a, b) => newItems.indexOf(a.client.uid) - newItems.indexOf(b.client.uid)));
        }
    }, [clients_, onReorderClients]);
    const handleRemoveClient = React.useCallback((clientUid) => {
        onRemoveClient(clientUid);
        setClients_((prev) => {
            return prev.filter((c) => c.client.uid !== clientUid);
        });
    }, [onRemoveClient]);
    return (React.createElement(DragDropContext, { onDragEnd: reorder },
        React.createElement(Droppable, { droppableId: "client-reorder-list" }, (droppableProvided) => (React.createElement(List, Object.assign({}, droppableProvided.droppableProps, { ref: droppableProvided.innerRef, dense: true }),
            clients_.map((client, indx) => {
                return (React.createElement(Draggable, { key: client.client.uid, draggableId: String(client.client.uid), index: indx }, (draggableProvided) => (React.createElement("div", Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps),
                    React.createElement(ClientListItem, { client: client, onRemoveClient: onRemoveClient ? handleRemoveClient : undefined, allowEditing: allowEditing, dragHandle: onReorderClients ? (React.createElement(ListItemIcon, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps, { style: {
                                cursor: "grab",
                                minWidth: "24px",
                            } }),
                            React.createElement(Reorder, { fontSize: "small" }))) : undefined, getBuyerLeadApi: getBuyerLeadApi, getSellerLeadApi: getSellerLeadApi, disableDialog: disableDialog })))));
            }),
            droppableProvided.placeholder)))));
};
export default ClientsReorder;
