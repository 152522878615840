import * as React from "react";
import CoreDialog from "../CoreDialog";
export const DialogContext = React.createContext({
    openDialog: () => { },
    closeDialog: () => { },
});
const DialogProvider = (props) => {
    const [currentDialog, setCurrentDialog] = React.useState(null);
    const openDialog = React.useCallback((content, params) => {
        console.log("SETTING DIALOG");
        setCurrentDialog({ content, props: params === null || params === void 0 ? void 0 : params.dialogProps });
    }, []);
    const onClose = React.useCallback((event, reason) => {
        var _a;
        if ((_a = currentDialog === null || currentDialog === void 0 ? void 0 : currentDialog.props) === null || _a === void 0 ? void 0 : _a.onClose) {
            currentDialog.props.onClose(event, reason);
        }
        setCurrentDialog(null);
    }, [currentDialog === null || currentDialog === void 0 ? void 0 : currentDialog.props]);
    const closeDialog = React.useCallback(() => {
        setCurrentDialog(null);
    }, []);
    const value = React.useMemo(() => {
        return {
            openDialog,
            closeDialog,
        };
    }, [openDialog, closeDialog]);
    return (React.createElement(DialogContext.Provider, { value: value },
        props.children,
        currentDialog && (React.createElement(CoreDialog, Object.assign({}, currentDialog.props, { open: true, onClose: onClose }), currentDialog.content))));
};
export default DialogProvider;
