import * as React from "react";
import { Add, Clear, FileCopy } from "@mui/icons-material";
import { Button, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { copyToClipboard } from "@app/util/Utils";
const keywords = ["if", "else if", "else"];
const comparators = [
    "equal to",
    "not equal to",
    "greater than",
    "less than",
    "greater than or equal to",
    "less than or equal to",
    "is blank",
    "is not blank",
    "is checked",
    "is not checked",
];
const getMention = (fieldKey, fieldName) => `
        <span class="mention" data-id="${fieldKey}" data-value="${fieldName}">
            &#xFEFF;
            <span contenteditable="false">
                <span class="ql-mention-denotation-char">undefined</span>
                ${fieldName}
            </span>
            &#xFEFF;
        </span>
    `
    .split(/\n/g)
    .map((line) => line.replace(/\s+$/, "").replace(/^\s+/, ""))
    .join("");
const IfStatementBuilder = (props) => {
    const { fields, variant } = props;
    const [statements, setStatements] = React.useState([]);
    React.useEffect(() => {
        setStatements([]);
    }, [props.fields]);
    const statementsAsString = React.useMemo(() => {
        var _a;
        let str = "";
        for (const statement of statements) {
            const field = fields.find((f) => f.uid === statement.fieldKey);
            str += "{% ";
            str += {
                if: "if",
                else: "else",
                "else if": "elif",
            }[statement.keyword];
            if (statement.keyword !== "else") {
                str += " ";
                const quot = field.field_type === "checkbox" ? "" : "'";
                if (variant === "html") {
                    const mention = getMention(statement.fieldKey, field.name);
                    str += `${quot}${mention}${quot}`; // TODO: FIX
                }
                else {
                    str += `${quot}$[${statement.fieldKey}](${field.name})${quot}`;
                }
                str += " ";
                str += {
                    "equal to": "==",
                    "not equal to": "!=",
                    "greater than": ">",
                    "less than": "<",
                    "greater than or equal to": ">=",
                    "less than or equal to": "<=",
                    "is blank": "==",
                    "is not blank": "!=",
                    "is checked": "==",
                    "is not checked": "!=",
                }[statement.comparator];
                str += " ";
                if (field.field_type === "checkbox") {
                    str += "true";
                }
                else {
                    str += `'${(_a = statement.value) !== null && _a !== void 0 ? _a : ""}'`;
                }
            }
            str += " %}";
        }
        str += "{% endif %}";
        return str;
    }, [statements, fields, variant]);
    const canAddStatement = React.useMemo(() => {
        return !statements.find((s) => s.keyword === "else");
    }, [statements]);
    const addStatement = React.useCallback(() => {
        setStatements((prev) => [
            ...prev,
            {
                keyword: prev.length === 0 ? "if" : "else if",
                fieldKey: fields[0].uid,
                comparator: "equal to",
                value: "",
                key: uuidv4(),
            },
        ]);
    }, [fields]);
    const removeStatement = React.useCallback((key) => () => {
        setStatements((prev) => prev.filter((s) => s.key !== key));
    }, []);
    const setStatementKeyword = React.useCallback((key) => (e) => {
        const val = e.target.value;
        setStatements((prev) => {
            const statementIndx = prev.findIndex((s) => s.key === key);
            return [
                ...prev.slice(0, statementIndx),
                Object.assign(Object.assign({}, prev[statementIndx]), { keyword: val }),
                ...(val === "else" ? [] : prev.slice(statementIndx + 1, prev.length)),
            ];
        });
    }, []);
    const setStatementField = React.useCallback((key) => (e) => {
        const val = e.target.value;
        const field = fields.find((f) => f.uid === val);
        setStatements((prev) => {
            const statementIndx = prev.findIndex((s) => s.key === key);
            return [
                ...prev.slice(0, statementIndx),
                Object.assign(Object.assign({}, prev[statementIndx]), { fieldKey: val, comparator: (() => {
                        const statement = prev[statementIndx];
                        if (field.field_type === "checkbox" &&
                            statement.comparator !== "is checked" &&
                            statement.comparator !== "is not checked") {
                            return "is checked";
                        }
                        if (field.field_type !== "checkbox" &&
                            (statement.comparator === "is checked" || statement.comparator === "is not checked")) {
                            return "equal to";
                        }
                        return statement.comparator;
                    })() }),
                ...prev.slice(statementIndx + 1, prev.length),
            ];
        });
    }, [fields]);
    const setStatementComparator = React.useCallback((key) => (e) => {
        const val = e.target.value;
        setStatements((prev) => {
            const statementIndx = prev.findIndex((s) => s.key === key);
            return [
                ...prev.slice(0, statementIndx),
                Object.assign(Object.assign({}, prev[statementIndx]), { comparator: val }),
                ...prev.slice(statementIndx + 1, prev.length),
            ];
        });
    }, []);
    const setStatementValue = React.useCallback((key) => (e) => {
        const val = e.target.value;
        setStatements((prev) => {
            const statementIndx = prev.findIndex((s) => s.key === key);
            return [
                ...prev.slice(0, statementIndx),
                Object.assign(Object.assign({}, prev[statementIndx]), { value: val }),
                ...prev.slice(statementIndx + 1, prev.length),
            ];
        });
    }, []);
    const copyStatementToClipboard = React.useCallback(() => {
        copyToClipboard(statementsAsString);
        enqueueSnackbar("Copied statement to clipboard.", { variant: "success" });
    }, [statementsAsString]);
    return (React.createElement(Grid, { container: true, style: { margin: "-4px" } },
        React.createElement(Grid, { item: true, style: { padding: "4px" } },
            React.createElement(Typography, { variant: "h6" }, "If-Statement Builder")),
        React.createElement(Grid, { item: true, xs: 12, style: { padding: "4px" } },
            React.createElement(Grid, { container: true, spacing: 1 }, statements.map((s, i) => {
                var _a;
                const field = fields.find((f) => f.uid === s.fieldKey);
                return (React.createElement(Grid, { key: s.key, item: true, xs: 12 },
                    React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: {
                                marginTop: "auto",
                                marginBottom: "auto",
                                visibility: i === 0 ? "hidden" : undefined,
                            } },
                            React.createElement(Tooltip, { title: "Remove condition" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { onClick: removeStatement(s.key), size: "small" },
                                        React.createElement(Clear, { fontSize: "small" }))))),
                        React.createElement(Grid, { item: true, style: { width: "75px" } },
                            React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, value: s.keyword, onChange: setStatementKeyword(s.key), disabled: i === 0 }, keywords
                                .filter((keyword) => {
                                if (i === 0) {
                                    return keyword === "if";
                                }
                                return keyword !== "if";
                            })
                                .map((k) => (React.createElement(MenuItem, { key: k, value: k }, k))))),
                        s.keyword !== "else" && (React.createElement(React.Fragment, null,
                            React.createElement(Grid, { item: true, style: { width: "200px" } },
                                React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, value: s.fieldKey, onChange: setStatementField(s.key) }, fields
                                    .sort((a, b) => {
                                    if (a.name < b.name)
                                        return -1;
                                    if (a.name > b.name)
                                        return 1;
                                    return 0;
                                })
                                    .map((f) => (React.createElement(MenuItem, { key: f.uid, value: f.uid }, f.name))))),
                            React.createElement(Grid, { item: true, style: { width: "200px" } },
                                React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, value: s.comparator, onChange: setStatementComparator(s.key) }, comparators
                                    .filter((c) => (c === "is checked" || c === "is not checked") === ((field === null || field === void 0 ? void 0 : field.field_type) === "checkbox"))
                                    .map((c) => (React.createElement(MenuItem, { key: c, value: c }, c))))),
                            s.comparator !== "is blank" &&
                                s.comparator !== "is not blank" &&
                                s.comparator !== "is checked" &&
                                s.comparator !== "is not checked" && (React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(TextField, { variant: "standard", fullWidth: true, value: (_a = s.value) !== null && _a !== void 0 ? _a : "", onChange: setStatementValue(s.key) }))))))));
            }))),
        React.createElement(Grid, { item: true, xs: 12, style: { padding: "4px" } },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { color: "inherit", onClick: addStatement, disabled: !canAddStatement, startIcon: React.createElement(Add, null) }, "Add a condition")),
                statements.length > 0 && (React.createElement(Grid, { item: true },
                    React.createElement(Button, { variant: "outlined", color: "primary", startIcon: React.createElement(FileCopy, null), onClick: copyStatementToClipboard }, "Copy to Clipboard")))))));
};
export default IfStatementBuilder;
