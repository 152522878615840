/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List submissions
 */
export const listQfsSubmissionNewcrm = (params, options) => {
    return axios.get(`/api/forms/questions_for_the_search`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListQfsSubmissionNewcrmQueryKey = (params) => {
    return [`/api/forms/questions_for_the_search`, ...(params ? [params] : [])];
};
export const getListQfsSubmissionNewcrmQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQfsSubmissionNewcrmQueryKey(params);
    const queryFn = ({ signal }) => listQfsSubmissionNewcrm(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List submissions
 */
export function useListQfsSubmissionNewcrm(params, options) {
    const queryOptions = getListQfsSubmissionNewcrmQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Determines the market based on the email of the producer. RDU by default.

_Authorization: none required_
 * @summary Get the relevant market
 */
export const getMarketForQuestionsForTheSearch = (buyerLeadUid, options) => {
    return axios.get(`/api/forms/questions_for_the_search/${buyerLeadUid}/market`, options);
};
export const getGetMarketForQuestionsForTheSearchQueryKey = (buyerLeadUid) => {
    return [`/api/forms/questions_for_the_search/${buyerLeadUid}/market`];
};
export const getGetMarketForQuestionsForTheSearchQueryOptions = (buyerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMarketForQuestionsForTheSearchQueryKey(buyerLeadUid);
    const queryFn = ({ signal }) => getMarketForQuestionsForTheSearch(buyerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(buyerLeadUid) }, queryOptions);
};
/**
 * @summary Get the relevant market
 */
export function useGetMarketForQuestionsForTheSearch(buyerLeadUid, options) {
    const queryOptions = getGetMarketForQuestionsForTheSearchQueryOptions(buyerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get a submission
 */
export const getQfsSubmissionNewcrm = (uid, options) => {
    return axios.get(`/api/forms/questions_for_the_search/${uid}`, options);
};
export const getGetQfsSubmissionNewcrmQueryKey = (uid) => {
    return [`/api/forms/questions_for_the_search/${uid}`];
};
export const getGetQfsSubmissionNewcrmQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetQfsSubmissionNewcrmQueryKey(uid);
    const queryFn = ({ signal }) => getQfsSubmissionNewcrm(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a submission
 */
export function useGetQfsSubmissionNewcrm(uid, options) {
    const queryOptions = getGetQfsSubmissionNewcrmQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List the ages based on the market.

_Authorization: none required_
 * @summary List ages
 */
export const listQuestionsForTheSearchAge = (options) => {
    return axios.get(`/api/forms/questions_for_the_search/age_options`, options);
};
export const getListQuestionsForTheSearchAgeQueryKey = () => {
    return [`/api/forms/questions_for_the_search/age_options`];
};
export const getListQuestionsForTheSearchAgeQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheSearchAgeQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheSearchAge(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List ages
 */
export function useListQuestionsForTheSearchAge(options) {
    const queryOptions = getListQuestionsForTheSearchAgeQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List the garages based on the market.

_Authorization: none required_
 * @summary List garages
 */
export const listQuestionsForTheSearchGarages = (options) => {
    return axios.get(`/api/forms/questions_for_the_search/garage_options`, options);
};
export const getListQuestionsForTheSearchGaragesQueryKey = () => {
    return [`/api/forms/questions_for_the_search/garage_options`];
};
export const getListQuestionsForTheSearchGaragesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheSearchGaragesQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheSearchGarages(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List garages
 */
export function useListQuestionsForTheSearchGarages(options) {
    const queryOptions = getListQuestionsForTheSearchGaragesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List the locations based on the market.

_Authorization: none required_
 * @summary List locations
 */
export const listQuestionsForTheSearchLocations = (params, options) => {
    return axios.get(`/api/forms/questions_for_the_search/locations`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListQuestionsForTheSearchLocationsQueryKey = (params) => {
    return [`/api/forms/questions_for_the_search/locations`, ...(params ? [params] : [])];
};
export const getListQuestionsForTheSearchLocationsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheSearchLocationsQueryKey(params);
    const queryFn = ({ signal }) => listQuestionsForTheSearchLocations(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List locations
 */
export function useListQuestionsForTheSearchLocations(params, options) {
    const queryOptions = getListQuestionsForTheSearchLocationsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List the lot sizes based on the market.

_Authorization: none required_
 * @summary List lot sizes
 */
export const listQuestionsForTheSearchLotSize = (options) => {
    return axios.get(`/api/forms/questions_for_the_search/lot_size_options`, options);
};
export const getListQuestionsForTheSearchLotSizeQueryKey = () => {
    return [`/api/forms/questions_for_the_search/lot_size_options`];
};
export const getListQuestionsForTheSearchLotSizeQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheSearchLotSizeQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheSearchLotSize(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List lot sizes
 */
export function useListQuestionsForTheSearchLotSize(options) {
    const queryOptions = getListQuestionsForTheSearchLotSizeQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * List the property types based on the market.

_Authorization: none required_
 * @summary List property types
 */
export const listQuestionsForTheSearchPropertyType = (options) => {
    return axios.get(`/api/forms/questions_for_the_search/property_type_options`, options);
};
export const getListQuestionsForTheSearchPropertyTypeQueryKey = () => {
    return [`/api/forms/questions_for_the_search/property_type_options`];
};
export const getListQuestionsForTheSearchPropertyTypeQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListQuestionsForTheSearchPropertyTypeQueryKey();
    const queryFn = ({ signal }) => listQuestionsForTheSearchPropertyType(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List property types
 */
export function useListQuestionsForTheSearchPropertyType(options) {
    const queryOptions = getListQuestionsForTheSearchPropertyTypeQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
