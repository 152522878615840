import * as React from "react";
import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
const StyledLightbox = styled(Lightbox) `
  & .yarl_root {
    background: transparent;
    text-align: center;
  }
  & .yarl__container, & .yarl__thumbnails_container {
    background: rgba(0, 0, 0, 0.85);
  }
  & button.yarl__thumbnails_thumbnail {
    background: transparent;
    border: none;
  }
`;
const CompsPullerPhotoCarousel = (props) => {
    const { address, onCancel, open, photos } = props;
    const thumbnailsRef = React.useRef(null);
    const photos_ = React.useMemo(() => {
        return photos.map((photo) => {
            return Object.assign(Object.assign({}, photo), { title: address });
        });
    }, [address, photos]);
    return (React.createElement(StyledLightbox, { animation: { fade: 250, swipe: 0, easing: { fade: "ease", swipe: "ease-out", navigation: "ease-in-out" } }, open: open, close: onCancel, slides: photos_, index: 0, plugins: [Captions, Thumbnails], thumbnails: { ref: thumbnailsRef } }));
};
export default CompsPullerPhotoCarousel;
