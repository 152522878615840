import * as React from "react";
import { Alarm, Clear, Done } from "@mui/icons-material";
import { Box, Divider, Grid, Typography } from "@mui/material";
import AppConfig from "@app/util/AppConfig";
const StatusTooltip = (props) => {
    var _a, _b;
    return (React.createElement(Box, { p: 1 },
        React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, null, props.status)),
            React.createElement(Grid, { item: true },
                React.createElement(Divider, { style: { background: "white" } })),
            ((_a = props.triggers) !== null && _a !== void 0 ? _a : []).map((trigger, indx) => (React.createElement(Grid, { key: `trigger${indx}__${trigger.description}`, container: true, item: true, xs: 12, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(Alarm, { style: { color: AppConfig.themeColors.lightestblue }, fontSize: "small" })),
                React.createElement(Grid, { item: true, style: { flex: 1 } },
                    React.createElement(Typography, { variant: "body2" }, trigger.description))))),
            ((_b = props.criteria) !== null && _b !== void 0 ? _b : [])
                .map((c) => c.filters)
                .flat()
                .map((f, indx) => (React.createElement(Grid, { key: `criteria${indx}__${f.filter.description}`, container: true, item: true, spacing: 1 },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1 },
                    React.createElement(Grid, { item: true }, f.passes ? (React.createElement(Done, { style: { color: "#61ff71" }, fontSize: "small" })) : (React.createElement(Clear, { style: { color: "#ff6363" }, fontSize: "small" }))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Typography, { variant: "body2" }, f.filter.description))),
                f.error && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, f.error)))))))));
};
export default StatusTooltip;
