import * as React from "react";
import { Box, Table, TableHead, TableBody, TableCell, TableRow, Tooltip, Typography, Tabs, Tab, List, ListItem, ListItemText, } from "@mui/material";
import styled from "styled-components";
import dashLogo from "@app/assets/img/logo-notext-black-transparent.png";
import dayjs_ from "@app/common/dayjs";
import useErrorHandler from "@app/hooks/useErrorHandler";
import useStateCallback from "@app/hooks/useStateCallback";
import { useListDashscoreComponentValues } from "@app/orval/api/dashscore-settings";
import { useUpdateDashscoreTabDescription } from "@app/orval/api/dashscore-tab-sections";
import Session from "@app/util/Session";
import ScorecardTabSection from "./ScorecardTabSection";
import { getMetricScore, getSLAViolationsDescriptor, formatDecimal, formatDecimalMemoized, formatPercentageMemoized, EXPECTED_COLOR, ACCEPTABLE_COLOR, UNACCEPTABLE_COLOR, CURRENT_PCAS, getDashscoreBreakdownText, } from "./utils";
const StyledTable = styled(Table) `
  border: 3px solid #666666;
  white-space: nowrap;
  width: unset !important;
  margin-right: 12px;
  overflow: hidden;

  @media (max-width: 480px) {
    zoom: 70%;
  }

  th {
    font-weight: bold;
  }

  td {
    height: 1rem;
    min-width: 25px;
    padding-bottom: 2px;
    padding-top: 2px;
    overflow: "hidden",
    text-overflow: "",
    white-space: "nowrap",
  }

  th, td {
    border: ${(props) => (props.boldBorders ? "3px solid black" : "none")};
    text-align: center;
    padding: 5px;
    border-left: 1px solid #666666;
    font-family: Proxima Nova, sans-serif;
  }

  tr, td {
    height: auto;
  }

  tbody td:first-child {
    font-weight: bold;
  }

  tbody td:not(:first-child) {
    text-align: center;
    font-style: italic;
  }

  td.category-a {
    background: #eee;
  }

  th.time-period-header {
    font-weight: bold;
    font-style: italic;
    font-size: 9pt;
    text-decoration: underline;
    padding-bottom: 0px;
    vertical-align: bottom;
    max-width: 54px;
    min-width: 52px;
  }

  @media (min-width: 480px) {
    th.time-period-header {
      width: 52px;
    }
  }

  td.category-b {
    background: #ddd;
    text-align: right;
  }

  td.category-c {
    background: #ccc;
    text-align: right;
  }

  td.overall-category {
    background: #ddd;
    font-style: normal !important;
  }

  td.data-col {
    width: 51px;
    min-width: 51px;
    max-width: 51px;
    border-top: 1px solid black;
  }

  td.all-time {
    height: 22px;
  }

  td.Expected {
    background: ${EXPECTED_COLOR};
  }

  td.Acceptable {
    background: ${ACCEPTABLE_COLOR};
  }

  td.Unacceptable {
    background: ${UNACCEPTABLE_COLOR};
  }

  td.not-relevant {
    background: #434343
  }

  td.width-a {
    width: 70px;
    min-width: 70px;
  }

  td.width-b {
    width: 55px;
    min-width: 55px;
    min-width: 55px;
    padding-left: 0;
    padding-right: 0;
  }

  td.width-c {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
  }

  td.width-d {
    width: 197px;
    min-width: 197px;
    max-width: 197px;
  }

  td.width-e {
    width: 70px;
    min-width: 70px;
  }

  td.width-f {
    width: 60px;
    min-width: 60px;
  }

  td.height-a {
    height: 31px;
  }

  .tab-section-left-col {
    width: 91px;
    min-width: 91px;
    border-top: 1px solid black;
  }

  .zhl-data-cell {
    width: 54px;
    min-width: 54px;
    font-size: 10pt;
    min-height: 20px;
  }
`;
/**
 *
 * @param metricName Name to display
 * @param metricKey key name used for the metric in config.json
 * @param decimalPlaces Optional. If provided, rounds the result to given number of decimal places. Ex: To round 14.26 to 14.3, use 1. To round 12 to 10, use -1
 * @returns
 */
const getTooltipText = (metricName, redValue, greenValue, multiplyBy100, decimalPlaces) => {
    const multiplier = multiplyBy100 ? 100 : 1;
    const greenVal = greenValue != null ? greenValue * multiplier : null;
    const redVal = redValue != null ? redValue * multiplier : null;
    let greenValText = "-";
    if (greenVal != null) {
        greenValText = `${decimalPlaces != null ? Math.round(greenVal * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces) : greenVal}${multiplyBy100 ? "%" : ""}`;
    }
    let redValText = "-";
    if (redVal != null) {
        redValText = `${decimalPlaces != null ? Math.round(redVal * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces) : redVal}${multiplyBy100 ? "%" : ""}`;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { align: "center", style: { fontWeight: "bold", fontSize: "14px" } }, metricName),
        React.createElement(Typography, null, `Green above: ${greenValText}`),
        React.createElement(Typography, null, `Red below: ${redValText}`)));
};
const getSLAViolationsContext = (violations) => {
    return (React.createElement(Box, null,
        React.createElement(Typography, { align: "center", style: { fontSize: "16px" } }, "SLA Violations:"),
        violations
            .sort((a, b) => {
            if (a.notification_date > b.notification_date)
                return 1;
            if (a.notification_date < b.notification_date)
                return -1;
            return 0;
        })
            .map((violation) => {
            return (React.createElement(Typography, { key: `${violation.notification_date} ${violation.producer_uid} ${violation.box.uid} ${violation.title}` },
                React.createElement("a", { target: "_blank", href: `/boxes/${violation.box.uid}`, rel: "noreferrer" }, `${dayjs_(violation.notification_date).format("M/D/YYYY")}: ${violation.title}`)));
        })));
};
const AgentScorecard = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62;
    const { agent, agentOffice, 
    // dashScoreBuyerLeadsData,
    dashScoreData, 
    // isLoadingBuyerLeadsData,
    isSmallWindow, settings, tabDescriptions, tabs, } = props;
    const [currentTab, setCurrentTab] = React.useState((_a = tabs[0]) !== null && _a !== void 0 ? _a : "");
    const [currentlyUpdatingTabs, setCurrentlyUpdatingTabs] = useStateCallback([]);
    const dashScoreUpdateTabDescriptionApi = useUpdateDashscoreTabDescription();
    const dashScoreComponentValuesListApi = useListDashscoreComponentValues();
    const dashScoreComponentValues = (_c = (_b = dashScoreComponentValuesListApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const { handleError } = useErrorHandler();
    const changeTab = React.useCallback((_, value) => {
        setCurrentTab(tabs[value]);
    }, [tabs]);
    const updateTabDescription = React.useCallback((tabName, text) => {
        setCurrentlyUpdatingTabs((prevState) => {
            return [...prevState, tabName];
        }, () => {
            dashScoreUpdateTabDescriptionApi
                .mutateAsync({
                data: {
                    tab_name: tabName,
                    content: text,
                },
            })
                .then((result) => {
                props.updateTabDescriptions(result.data);
                setCurrentlyUpdatingTabs((prevState) => {
                    return prevState.filter((item) => {
                        return item !== result.data.tab_name;
                    });
                });
            })
                .catch((error) => {
                handleError(error, "show-dialog");
            });
        });
    }, [props, setCurrentlyUpdatingTabs, dashScoreUpdateTabDescriptionApi, handleError]);
    const isAdmin = (_d = Session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.hasRole(["Superadmin", "Admin"]);
    const subjectAggregateData = (_e = dashScoreData.subject_aggregates.find((x) => x.name === agent)) !== null && _e !== void 0 ? _e : null;
    console.log({ agent });
    console.log({ agentOffice });
    const agentMetrics = {
        "30 Day": (_f = dashScoreData.agent_metrics.find((x) => x.name === agent &&
            x.range === "30 Day" &&
            (!agentOffice || agentOffice.toLowerCase().trim() === x.agent_office))) !== null && _f !== void 0 ? _f : null,
        "60 Day": (_g = dashScoreData.agent_metrics.find((x) => x.name === agent &&
            x.range === "60 Day" &&
            (!agentOffice || agentOffice.toLowerCase().trim() === x.agent_office))) !== null && _g !== void 0 ? _g : null,
        "90 Day": (_h = dashScoreData.agent_metrics.find((x) => x.name === agent &&
            x.range === "90 Day" &&
            (!agentOffice || agentOffice.toLowerCase().trim() === x.agent_office))) !== null && _h !== void 0 ? _h : null,
        "180 Day": (_j = dashScoreData.agent_metrics.find((x) => x.name === agent &&
            x.range === "180 Day" &&
            (!agentOffice || agentOffice.toLowerCase().trim() === x.agent_office))) !== null && _j !== void 0 ? _j : null,
        "All Time": (_k = dashScoreData.agent_metrics.find((x) => x.name === agent && x.range === "All Time")) !== null && _k !== void 0 ? _k : null,
    };
    console.log({ agentMetrics });
    const connectionMetrics = {
        "30 Day": (_l = dashScoreData.connection_metrics.find((x) => {
            return x.connected_name === agent && x.range === "30 Day";
        })) !== null && _l !== void 0 ? _l : null,
        "60 Day": (_m = dashScoreData.connection_metrics.find((x) => {
            return x.connected_name === agent && x.range === "60 Day";
        })) !== null && _m !== void 0 ? _m : null,
        "90 Day": (_o = dashScoreData.connection_metrics.find((x) => {
            return x.connected_name === agent && x.range === "90 Day";
        })) !== null && _o !== void 0 ? _o : null,
        "180 Day": (_p = dashScoreData.connection_metrics.find((x) => {
            return x.connected_name === agent && x.range === "180 Day";
        })) !== null && _p !== void 0 ? _p : null,
    };
    console.log({ connectionMetrics });
    // const buyerLeadsData = {
    //   "30 Day":
    //     dashScoreBuyerLeadsData?.find((x) => {
    //       return x.name === agent && x.range === "30 Day";
    //     }) ?? null,
    //   "60 Day":
    //     dashScoreBuyerLeadsData?.find((x) => {
    //       return x.name === agent && x.range === "60 Day";
    //     }) ?? null,
    //   "90 Day":
    //     dashScoreBuyerLeadsData?.find((x) => {
    //       return x.name === agent && x.range === "90 Day";
    //     }) ?? null,
    //   "180 Day":
    //     dashScoreBuyerLeadsData?.find((x) => {
    //       return x.name === agent && x.range === "180 Day";
    //     }) ?? null,
    //   "All Time":
    //     dashScoreBuyerLeadsData?.find((x) => {
    //       return x.name === agent && x.range === "All Time";
    //     }) ?? null,
    // };
    // console.log("buyer leads data:", buyerLeadsData);
    const csatScore = (_q = agentMetrics["180 Day"]) === null || _q === void 0 ? void 0 : _q.avg_current_csat;
    const slaViolations = (_r = agentMetrics["30 Day"]) === null || _r === void 0 ? void 0 : _r.sla_violations;
    const numSLAViolations = slaViolations ? slaViolations.length : null;
    const numDaysWithSLAViolations = (_s = agentMetrics["30 Day"]) === null || _s === void 0 ? void 0 : _s.num_days_with_sla_violations;
    const dashScore = (subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.dashscore) == null ? null : Math.ceil(subjectAggregateData.dashscore);
    const units = subjectAggregateData === null ? null : subjectAggregateData.units;
    const expectedUnits = subjectAggregateData === null ? null : subjectAggregateData.expected_units;
    const percentToTransactionTarget = subjectAggregateData === null ? null : subjectAggregateData.percent_to_transaction_target;
    const timePeriods = ["30 Day", "60 Day", "90 Day", "180 Day"];
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Box, { display: "flex" },
            React.createElement(Box, { display: "flex", pb: 2, pt: 2 },
                React.createElement(StyledTable, { boldBorders: true, style: { height: "40px", marginTop: "auto", border: "unset" } },
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { style: {
                                    paddingBottom: "0px",
                                    paddingTop: "0px",
                                    width: isSmallWindow ? "293px" : "308px",
                                    display: "flex",
                                    borderLeft: "3px solid black",
                                }, className: "overall-category" },
                                React.createElement(Box, { display: "flex", marginTop: "auto", marginBottom: "auto", marginLeft: "auto", paddingRight: "8px" },
                                    React.createElement("img", { src: dashLogo, alt: "logo", width: "40", height: "40" })),
                                React.createElement(Typography, { fontWeight: "bold", fontSize: "25.5pt", style: { marginTop: "auto", marginBottom: "auto", marginRight: "auto" } }, "DASHscore")))))),
            React.createElement(Box, { display: "flex", pb: 2, pt: 2 },
                React.createElement(StyledTable, { boldBorders: true, style: { height: "56px", marginTop: "auto", border: "3px solid black" } },
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                                    getTooltipText("DASHscore", settings.dashscore_red_value, settings.dashscore_green_value),
                                    React.createElement(Typography, { align: "center", style: { fontWeight: "bold" } }, "Breakdown:"),
                                    React.createElement(Typography, null, `FLEX: ${getDashscoreBreakdownText(subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.total_flex_score, (_t = (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : []).find((item) => {
                                        return item.name.toLowerCase().includes("flex");
                                    })) === null || _t === void 0 ? void 0 : _t.maximum_value)}`),
                                    React.createElement(Typography, null, `ZHL: ${getDashscoreBreakdownText(subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.total_zhl_score, (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : [])
                                        .filter((item) => {
                                        return item.name.toLowerCase().includes("zhl");
                                    })
                                        .reduce((total, value) => {
                                        return total + value.maximum_value;
                                    }, 0))}`),
                                    React.createElement(Typography, null, `PCA: ${getDashscoreBreakdownText(subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.total_pca_score, (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : [])
                                        .filter((item) => {
                                        return item.name.toLowerCase().includes("pca");
                                    })
                                        .reduce((total, value) => {
                                        return total + value.maximum_value;
                                    }, 0))}`),
                                    React.createElement(Typography, null, `CSAT: ${getDashscoreBreakdownText(subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.total_csat_score, (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : [])
                                        .filter((item) => {
                                        return item.name.toLowerCase().includes("csat");
                                    })
                                        .reduce((total, value) => {
                                        return total + value.maximum_value;
                                    }, 0))}`),
                                    React.createElement(Typography, null, `SLA: ${getDashscoreBreakdownText(subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.total_sla_score, (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : [])
                                        .filter((item) => {
                                        return item.name.toLowerCase().includes("sla");
                                    })
                                        .reduce((total, value) => {
                                        return total + value.maximum_value;
                                    }, 0))}`),
                                    React.createElement(Typography, null, `PIG: ${getDashscoreBreakdownText(subjectAggregateData === null || subjectAggregateData === void 0 ? void 0 : subjectAggregateData.total_pig_score, (dashScoreComponentValues !== null && dashScoreComponentValues !== void 0 ? dashScoreComponentValues : [])
                                        .filter((item) => {
                                        return item.name.toLowerCase().includes("pig");
                                    })
                                        .reduce((total, value) => {
                                        return total + value.maximum_value;
                                    }, 0))}`)) },
                                React.createElement(TableCell, { style: {
                                        fontSize: "23pt",
                                        paddingBottom: "3px",
                                        paddingTop: "3px",
                                        width: isSmallWindow ? "84px" : undefined,
                                        minWidth: isSmallWindow ? "84px" : undefined,
                                    }, className: `${(_u = getMetricScore(dashScore, settings.dashscore_red_value, settings.dashscore_green_value)) !== null && _u !== void 0 ? _u : ""} width-a` }, formatDecimalMemoized(dashScore)))))))),
        React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
            React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black", marginRight: "22px" } },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontWeight: "bold",
                                fontSize: "17pt",
                                height: "38px",
                                verticalAlign: "top",
                                width: "124px",
                                minWidth: "124px",
                            }, colSpan: 2, className: "overall-category" }, "FLEX"),
                        React.createElement(TableCell, { style: {
                                fontSize: "8pt",
                                maxWidth: isSmallWindow ? "50px" : undefined,
                            }, rowSpan: 2, className: "overall-category width-f" },
                            "% TO",
                            React.createElement("br", null),
                            " ",
                            React.createElement("strong", null, "TRXN"),
                            React.createElement("br", null),
                            " TARGET")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                width: "61px",
                                fontSize: "5pt",
                                fontWeight: "normal",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                            }, className: "overall-category" }, "TARGET"),
                        React.createElement(TableCell, { style: {
                                fontSize: "7pt",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                                width: "61px",
                            }, className: "overall-category" }, "ACTUAL")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { className: "overall-category", style: {
                                fontSize: "13pt",
                                fontWeight: "normal",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "41px",
                                maxHeight: "41px",
                            } }, formatDecimalMemoized(expectedUnits, 2)),
                        React.createElement(TableCell, { className: "overall-category", style: {
                                fontSize: "13pt",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "41px",
                                maxHeight: "41px",
                            } }, formatDecimalMemoized(units)),
                        React.createElement(Tooltip, { title: getTooltipText("% to Transaction Target", settings.flex_percent_to_trxn_target_score_red_value, settings.flex_percent_to_trxn_target_score_green_value, true) },
                            React.createElement(TableCell, { className: (_v = getMetricScore(percentToTransactionTarget, settings.flex_percent_to_trxn_target_score_red_value, settings.flex_percent_to_trxn_target_score_green_value)) !== null && _v !== void 0 ? _v : "", style: {
                                    fontWeight: "bold",
                                    fontSize: "9pt",
                                    fontStyle: "normal",
                                    paddingBottom: "0px",
                                    paddingTop: "0px",
                                    height: "41px",
                                    maxHeight: "41px",
                                    maxWidth: isSmallWindow ? "50px" : "55px",
                                } }, formatPercentageMemoized(percentToTransactionTarget)))))),
            React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontWeight: "bold",
                                fontSize: "17pt",
                                height: "38px",
                                verticalAlign: "top",
                                width: "122px",
                                minWidth: "122px",
                            }, colSpan: 2, className: "overall-category" }, "ZHL"),
                        React.createElement(TableCell, { style: {
                                fontSize: "8pt",
                                maxWidth: isSmallWindow ? "50px" : undefined,
                            }, rowSpan: 2, className: "overall-category width-f" },
                            "% TO",
                            React.createElement("br", null),
                            " ",
                            React.createElement("strong", null, "ATTACH"),
                            React.createElement("br", null),
                            " TARGET")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                width: "61px",
                                fontSize: "5pt",
                                fontWeight: "normal",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                            }, className: "overall-category" }, "TARGET"),
                        React.createElement(TableCell, { style: {
                                fontSize: "7pt",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                                width: "61px",
                            }, className: "overall-category" }, "ACTUAL")),
                    React.createElement(TableRow, { className: "value-a" },
                        React.createElement(TableCell, { className: "overall-category", style: {
                                fontSize: "13pt",
                                fontWeight: "normal",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "41px",
                                maxHeight: "41px",
                            } }, formatDecimalMemoized((_w = connectionMetrics["180 Day"]) === null || _w === void 0 ? void 0 : _w.zhl_attach_target)),
                        React.createElement(TableCell, { className: "overall-category", style: {
                                fontSize: "13pt",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "41px",
                                maxHeight: "41px",
                            } }, formatDecimalMemoized((_x = connectionMetrics["180 Day"]) === null || _x === void 0 ? void 0 : _x.actual_zhl_funded_or_locked)),
                        React.createElement(Tooltip, { title: getTooltipText("ZHL % to Attach Target", settings.zhl_percent_to_attach_target_red_value, settings.zhl_percent_to_attach_target_green_value, true) },
                            React.createElement(TableCell, { className: (_z = getMetricScore((_y = connectionMetrics["180 Day"]) === null || _y === void 0 ? void 0 : _y.zhl_percent_to_target, settings.zhl_percent_to_attach_target_red_value, settings.zhl_percent_to_attach_target_green_value)) !== null && _z !== void 0 ? _z : "", style: {
                                    fontWeight: "bold",
                                    fontSize: "9pt",
                                    fontStyle: "normal",
                                    paddingBottom: "0px",
                                    paddingTop: "0px",
                                    height: "41px",
                                    maxHeight: "41px",
                                    maxWidth: isSmallWindow ? "50px" : "55px",
                                } }, formatPercentageMemoized((_0 = connectionMetrics["180 Day"]) === null || _0 === void 0 ? void 0 : _0.zhl_percent_to_target))))))),
        React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
            React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontWeight: "bold",
                                fontSize: "16pt",
                                borderBottom: "none",
                                verticalAlign: "top",
                                paddingBottom: "0px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "PCA")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontSize: "8pt",
                                fontWeight: "normal",
                                border: "1px solid black",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "15px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "180 Day")),
                    React.createElement(TableRow, { className: "value-a" },
                        React.createElement(Tooltip, { title: getTooltipText("PCA Score", settings.pca_percent_to_attach_target_red_value, settings.pca_percent_to_attach_target_green_value, true) },
                            React.createElement(TableCell, { className: `width-e ${getMetricScore((_1 = agentMetrics["180 Day"]) === null || _1 === void 0 ? void 0 : _1.pca_percent_attach_to_target, settings.pca_percent_to_attach_target_red_value, settings.pca_percent_to_attach_target_green_value)}`, style: {
                                    fontSize: "9pt",
                                    height: "18px",
                                    width: isSmallWindow ? "65px" : undefined,
                                    minWidth: isSmallWindow ? "65px" : undefined,
                                    borderTop: "none",
                                } }, formatPercentageMemoized((_2 = agentMetrics["180 Day"]) === null || _2 === void 0 ? void 0 : _2.pca_percent_attach_to_target)))))),
            React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontWeight: "bold",
                                fontSize: "16pt",
                                borderBottom: "none",
                                verticalAlign: "top",
                                paddingBottom: "0px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "CSAT")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontSize: "7pt",
                                fontWeight: "normal",
                                border: "1px solid black",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "15px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "PER PA APP")),
                    React.createElement(TableRow, { className: "value-a" },
                        React.createElement(Tooltip, { title: getTooltipText("CSAT Score", settings.csat_score_red_value, settings.csat_score_green_value) },
                            React.createElement(TableCell, { className: `width-e ${getMetricScore(csatScore, settings.csat_score_red_value, settings.csat_score_green_value)}`, style: {
                                    fontSize: "9pt",
                                    height: "18px",
                                    width: isSmallWindow ? "65px" : undefined,
                                    minWidth: isSmallWindow ? "65px" : undefined,
                                    borderTop: "none",
                                } }, formatDecimalMemoized(csatScore)))))),
            React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontWeight: "bold",
                                fontSize: "17pt",
                                borderBottom: "none",
                                verticalAlign: "top",
                                paddingBottom: "0px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "SLA")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontSize: "7pt",
                                fontWeight: "normal",
                                border: "1px solid black",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "15px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "OF THE LAST 30")),
                    React.createElement(TableRow, { className: "value-a" },
                        React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                                React.createElement(Typography, { align: "center", style: { fontWeight: "bold", fontSize: "14px" } }, "Days with SLA Violations:"),
                                React.createElement(Typography, null, `Green below: ${settings.days_with_sla_violations_green_value}`),
                                React.createElement(Typography, null, `Red above: ${settings.days_with_sla_violations_red_value}`)) },
                            React.createElement(TableCell, { className: `${(_3 = getSLAViolationsDescriptor(numDaysWithSLAViolations, settings.days_with_sla_violations_red_value, settings.days_with_sla_violations_green_value)) !== null && _3 !== void 0 ? _3 : ""} width-a`, style: {
                                    fontSize: "9pt",
                                    height: "18px",
                                    width: isSmallWindow ? "65px" : undefined,
                                    minWidth: isSmallWindow ? "65px" : undefined,
                                    borderTop: "none",
                                } }, formatDecimalMemoized(numDaysWithSLAViolations)))))),
            React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black", marginRight: "0px" } },
                React.createElement(TableBody, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontWeight: "bold",
                                fontSize: "16pt",
                                borderBottom: "none",
                                verticalAlign: "top",
                                paddingBottom: "0px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "PIG")),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: {
                                fontSize: "8pt",
                                fontWeight: "normal",
                                border: "1px solid black",
                                borderRight: "3px solid black",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                                height: "15px",
                                width: isSmallWindow ? "65px" : undefined,
                                minWidth: isSmallWindow ? "65px" : undefined,
                            }, className: "overall-category width-e" }, "180 Day")),
                    React.createElement(TableRow, { className: "value-a" },
                        React.createElement(Tooltip, { title: getTooltipText("PIG Lock Rate Score", settings.pig_percent_to_attach_target_red_value, settings.pig_percent_to_attach_target_green_value, true) },
                            React.createElement(TableCell, { className: `width-e ${getMetricScore((_4 = agentMetrics["180 Day"]) === null || _4 === void 0 ? void 0 : _4.pig_percent_attach_to_target, settings.pig_percent_to_attach_target_red_value, settings.pig_percent_to_attach_target_green_value)}`, style: {
                                    fontSize: "9pt",
                                    height: "18px",
                                    width: isSmallWindow ? "65px" : undefined,
                                    minWidth: isSmallWindow ? "65px" : undefined,
                                    borderTop: "none",
                                } }, formatPercentageMemoized((_5 = agentMetrics["180 Day"]) === null || _5 === void 0 ? void 0 : _5.pig_percent_attach_to_target))))))),
        React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
            React.createElement(Tabs, { value: tabs.indexOf(currentTab), indicatorColor: "primary", onChange: changeTab }, tabs.map((tab) => {
                return React.createElement(Tab, { key: tab, label: tab });
            }))),
        currentTab === "FLEX" && (React.createElement(ScorecardTabSection, { tabName: currentTab, text: (_7 = (_6 = tabDescriptions.find((item) => {
                return item.tab_name === currentTab;
            })) === null || _6 === void 0 ? void 0 : _6.content) !== null && _7 !== void 0 ? _7 : "", hasEditPermission: !!isAdmin, isUpdating: currentlyUpdatingTabs.includes(currentTab), updateDescriptionText: updateTabDescription },
            React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                    React.createElement(StyledTable, null,
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, ["FLEX", ...timePeriods].map((x) => (React.createElement(TableCell, { key: x, className: x === "FLEX" ? "tab-section-left-col" : "time-period-header", style: {
                                    borderLeft: x === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                    fontSize: x === "FLEX" ? "15pt" : "9pt",
                                    paddingTop: x === "FLEX" ? "2px" : undefined,
                                    paddingBottom: x === "FLEX" ? "2px" : undefined,
                                    paddingRight: x === "180 Day" && !isSmallWindow ? "6px" : undefined,
                                } }, x))))),
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, { style: { height: "35px" } },
                                React.createElement(TableCell, { className: "category-a tab-section-left-col", style: { fontSize: "7pt", paddingTop: "2px", paddingBottom: "2px" } }, "CONNECTIONS"),
                                timePeriods.map((t) => {
                                    var _a;
                                    return (React.createElement(TableCell, { key: t, className: "category-a data-col", style: {
                                            borderLeft: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                            fontSize: "12pt",
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                        } }, formatDecimalMemoized((_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.flex_cvr_leads)));
                                })))),
                    React.createElement(StyledTable, null,
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { style: {
                                        fontSize: "8pt",
                                        paddingBottom: "0px",
                                        verticalAlign: "bottom",
                                        height: "18px",
                                    } }, "All Time"))),
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, { style: { height: "35px" } },
                                React.createElement(TableCell, { className: "category-a data-col", style: {
                                        fontSize: "11pt",
                                        fontStyle: "italic",
                                        fontWeight: "normal",
                                    } }, formatDecimalMemoized((_8 = agentMetrics["All Time"]) === null || _8 === void 0 ? void 0 : _8.flex_cvr_leads)))))),
                React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                    React.createElement(StyledTable, null,
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-b tab-section-left-col", style: { fontSize: "11pt" } }, "Pick Up %"),
                                timePeriods.map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.pickup_rate;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("Pick Up Score", settings.pick_up_score_red_value, settings.pick_up_score_green_value, true) },
                                        React.createElement(TableCell, { style: {
                                                borderLeft: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "30 Day" ? "10pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                                paddingLeft: t === "30 Day" && !isSmallWindow ? "6px" : undefined,
                                                minWidth: t === "180 Day" ? "54px" : undefined,
                                            }, className: `${(_b = getMetricScore(score, settings.pick_up_score_red_value, settings.pick_up_score_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-b tab-section-left-col", style: { fontSize: "11pt" } }, "ALM%"),
                                timePeriods.map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.alm_appointment_rate;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("ALM Appointment Rate", settings.alm_appointment_rate_red_value, settings.alm_appointment_rate_green_value, true) },
                                        React.createElement(TableCell, { style: {
                                                borderLeft: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "30 Day" ? "10pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                                paddingLeft: t === "30 Day" && !isSmallWindow ? "6px" : undefined,
                                            }, className: `${(_b = getMetricScore(score, settings.alm_appointment_rate_red_value, settings.alm_appointment_rate_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-b tab-section-left-col", style: { fontSize: "11pt" } }, "Appt%"),
                                timePeriods.map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.appointment_rate;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("Appointment Score", settings.appointment_score_red_value, settings.appointment_score_green_value, true) },
                                        React.createElement(TableCell, { style: {
                                                borderLeft: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "30 Day" ? "10pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                                paddingLeft: t === "30 Day" && !isSmallWindow ? "6px" : undefined,
                                                borderTop: "1px solid black",
                                            }, className: `${(_b = getMetricScore(score, settings.appointment_score_red_value, settings.appointment_score_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-b tab-section-left-col", style: { fontSize: "11pt" } }, "Met Rate %"),
                                timePeriods.map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.met_rate;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("Met Rate Score", settings.met_rate_score_red_value, settings.met_rate_score_green_value, true) },
                                        React.createElement(TableCell, { style: {
                                                borderLeft: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                borderBottom: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "30 Day" ? "10pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                                paddingLeft: t === "30 Day" && !isSmallWindow ? "6px" : undefined,
                                            }, className: `${(_b = getMetricScore(score, settings.met_rate_score_red_value, settings.met_rate_score_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 2, className: "category-b", style: { fontSize: "12pt", borderTop: "1px solid black" } }, "Showing %"),
                                timePeriods
                                    .filter((t) => {
                                    return t !== "30 Day";
                                })
                                    .map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.show_rate;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("Showing Homes Score", settings.showing_homes_score_red_value, settings.showing_homes_score_green_value, true) },
                                        React.createElement(TableCell, { style: {
                                                borderLeft: t === "60 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                borderBottom: t === "60 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "60 Day" ? "14pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                            }, className: `${(_b = getMetricScore(score, settings.showing_homes_score_red_value, settings.showing_homes_score_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 3, className: "category-b", style: { fontSize: "13pt", borderTop: "1px solid black" } }, "Offer %"),
                                timePeriods
                                    .filter((t) => {
                                    return !["30 Day", "60 Day"].includes(t);
                                })
                                    .map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.offer_rate;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("Offer Percent Score", settings.offer_percent_score_red_value, settings.offer_percent_score_green_value, true, 0) },
                                        React.createElement(TableCell, { style: {
                                                borderLeft: t === "90 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                borderBottom: t === "90 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "90 Day" ? "15pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                            }, className: `${(_b = getMetricScore(score, settings.offer_percent_score_red_value, settings.offer_percent_score_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 4, className: "category-b", style: { fontSize: "16pt", borderTop: "1px solid black" } }, "CVR%"),
                                timePeriods
                                    .filter((t) => {
                                    return !["30 Day", "60 Day", "90 Day"].includes(t);
                                })
                                    .map((t) => {
                                    var _a, _b;
                                    const score = (_a = agentMetrics[t]) === null || _a === void 0 ? void 0 : _a.cvr;
                                    return (React.createElement(Tooltip, { key: t, title: getTooltipText("CVR Score", settings.cvr_score_red_value, settings.cvr_score_green_value, true) },
                                        React.createElement(TableCell, { style: {
                                                fontWeight: t === "180 Day" ? "bold" : undefined,
                                                borderLeft: t === "180 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontSize: t === "180 Day" ? "16pt" : "12pt",
                                                color: "rgb(153, 153, 153)",
                                            }, className: `${(_b = getMetricScore(score, settings.cvr_score_red_value, settings.cvr_score_green_value)) !== null && _b !== void 0 ? _b : ""} data-col` }, formatPercentageMemoized(score))));
                                })))),
                    React.createElement(StyledTable, null,
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("Pick Up Score", settings.pick_up_score_red_value, settings.pick_up_score_green_value, true) },
                                    React.createElement(TableCell, { className: `${(_10 = getMetricScore((_9 = agentMetrics["All Time"]) === null || _9 === void 0 ? void 0 : _9.pickup_rate, settings.pick_up_score_red_value, settings.pick_up_score_green_value)) !== null && _10 !== void 0 ? _10 : ""} data-col all-time`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                        } }, formatPercentageMemoized((_11 = agentMetrics["All Time"]) === null || _11 === void 0 ? void 0 : _11.pickup_rate)))),
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("ALM Appointment Rate", settings.alm_appointment_rate_red_value, settings.alm_appointment_rate_green_value, true) },
                                    React.createElement(TableCell, { className: `data-col all-time ${(_13 = getMetricScore((_12 = agentMetrics["All Time"]) === null || _12 === void 0 ? void 0 : _12.alm_appointment_rate, settings.alm_appointment_rate_red_value, settings.alm_appointment_rate_green_value)) !== null && _13 !== void 0 ? _13 : ""}`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                        } }, formatPercentageMemoized((_14 = agentMetrics["All Time"]) === null || _14 === void 0 ? void 0 : _14.alm_appointment_rate)))),
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("Appointment Score", settings.appointment_score_red_value, settings.appointment_score_green_value, true) },
                                    React.createElement(TableCell, { className: `data-col all-time ${(_16 = getMetricScore((_15 = agentMetrics["All Time"]) === null || _15 === void 0 ? void 0 : _15.appointment_rate, settings.appointment_score_red_value, settings.appointment_score_green_value)) !== null && _16 !== void 0 ? _16 : ""}`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                        } }, formatPercentageMemoized((_17 = agentMetrics["All Time"]) === null || _17 === void 0 ? void 0 : _17.appointment_rate)))),
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("Met Rate Score", settings.met_rate_score_red_value, settings.met_rate_score_green_value, true) },
                                    React.createElement(TableCell, { className: `data-col all-time ${(_19 = getMetricScore((_18 = agentMetrics["All Time"]) === null || _18 === void 0 ? void 0 : _18.met_rate, settings.met_rate_score_red_value, settings.met_rate_score_green_value)) !== null && _19 !== void 0 ? _19 : ""}`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                        } }, formatPercentageMemoized((_20 = agentMetrics["All Time"]) === null || _20 === void 0 ? void 0 : _20.met_rate)))),
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("Showing Homes Score", settings.showing_homes_score_red_value, settings.showing_homes_score_green_value, true) },
                                    React.createElement(TableCell, { className: `data-col all-time ${(_22 = getMetricScore((_21 = agentMetrics["All Time"]) === null || _21 === void 0 ? void 0 : _21.show_rate, settings.showing_homes_score_red_value, settings.showing_homes_score_green_value)) !== null && _22 !== void 0 ? _22 : ""}`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                            height: "28px",
                                        } }, formatPercentageMemoized((_23 = agentMetrics["All Time"]) === null || _23 === void 0 ? void 0 : _23.show_rate)))),
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("Offer Percent Score", settings.offer_percent_score_red_value, settings.offer_percent_score_green_value, true, 0) },
                                    React.createElement(TableCell, { className: `data-col all-time ${(_25 = getMetricScore((_24 = agentMetrics["All Time"]) === null || _24 === void 0 ? void 0 : _24.offer_rate, settings.offer_percent_score_red_value, settings.offer_percent_score_green_value)) !== null && _25 !== void 0 ? _25 : ""}`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                            height: "28px",
                                        } }, formatPercentageMemoized((_26 = agentMetrics["All Time"]) === null || _26 === void 0 ? void 0 : _26.offer_rate)))),
                            React.createElement(TableRow, null,
                                React.createElement(Tooltip, { title: getTooltipText("CVR Score", settings.cvr_score_red_value, settings.cvr_score_green_value, true) },
                                    React.createElement(TableCell, { className: `data-col all-time ${(_28 = getMetricScore((_27 = agentMetrics["All Time"]) === null || _27 === void 0 ? void 0 : _27.cvr, settings.cvr_score_red_value, settings.cvr_score_green_value)) !== null && _28 !== void 0 ? _28 : ""}`, style: {
                                            fontSize: "11pt",
                                            fontStyle: "italic",
                                            color: "rgb(153, 153, 153)",
                                            height: "30px",
                                        } }, formatPercentageMemoized((_29 = agentMetrics["All Time"]) === null || _29 === void 0 ? void 0 : _29.cvr)))))))))),
        currentTab === "ZHL" && (React.createElement(ScorecardTabSection, { tabName: currentTab, text: (_31 = (_30 = tabDescriptions.find((item) => {
                return item.tab_name === currentTab;
            })) === null || _30 === void 0 ? void 0 : _30.content) !== null && _31 !== void 0 ? _31 : "", hasEditPermission: !!isAdmin, isUpdating: currentlyUpdatingTabs.includes(currentTab), updateDescriptionText: updateTabDescription },
            React.createElement(Box, { display: "flex" },
                React.createElement(Box, { width: "300px", pr: 2 },
                    React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                        React.createElement(StyledTable, null,
                            React.createElement(TableHead, null, [
                                "ZHL",
                                ...timePeriods.filter((t) => {
                                    return t !== "180 Day";
                                }),
                            ].map((header) => {
                                return (React.createElement(TableCell, { key: header, className: header === "ZHL" ? undefined : "time-period-header", style: {
                                        fontSize: header === "ZHL" ? "13pt" : "9pt",
                                        borderLeft: header === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                        paddingTop: header === "ZHL" ? "2px" : undefined,
                                        paddingBottom: header === "ZHL" ? "2px" : undefined,
                                        paddingLeft: header === "ZHL" ? undefined : "3px",
                                        paddingRight: header === "ZHL" ? undefined : "3px",
                                    } }, header));
                            })),
                            React.createElement(TableBody, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-a tab-section-left-col", style: { fontSize: "10pt", paddingTop: "2px", paddingBottom: "2px" } }, "OPT-IN CXN"),
                                    timePeriods
                                        .filter((t) => {
                                        return t !== "180 Day";
                                    })
                                        .map((t) => {
                                        var _a;
                                        const score = (_a = connectionMetrics[t]) === null || _a === void 0 ? void 0 : _a.opt_in_cnx;
                                        return (React.createElement(TableCell, { key: t, className: "category-a zhl-data-cell", style: {
                                                borderLeft: t === "30 Day" ? "3px solid rgb(67, 67, 67)" : undefined,
                                                fontStyle: t === "30 Day" ? "normal" : undefined,
                                            } }, formatDecimalMemoized(score)));
                                    }))))),
                    React.createElement(Box, { width: "100%", display: "flex", pb: 2 },
                        React.createElement(StyledTable, { style: { marginRight: "0px" } },
                            React.createElement(TableBody, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c tab-section-left-col", style: { fontSize: "8pt" } }, "TRANSFER %"),
                                    timePeriods
                                        .filter((t) => {
                                        return t !== "180 Day";
                                    })
                                        .map((t) => {
                                        var _a, _b;
                                        const score = (_a = connectionMetrics[t]) === null || _a === void 0 ? void 0 : _a.opt_in_transfer_rate;
                                        return (React.createElement(Tooltip, { key: t, title: getTooltipText("Transfer Score", settings.zhl_transfer_score_red_value, settings.zhl_transfer_score_green_value, true) },
                                            React.createElement(TableCell, { className: `zhl-data-cell ${(_b = getMetricScore(score, settings.zhl_transfer_score_red_value, settings.zhl_transfer_score_green_value)) !== null && _b !== void 0 ? _b : ""}`, style: {
                                                    borderLeft: t === "30 Day" ? "3px solid #666666" : undefined,
                                                    fontStyle: "normal",
                                                    width: "52px",
                                                } }, formatPercentageMemoized(score))));
                                    })),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c tab-section-left-col", style: { fontSize: "9pt" } }, "ENGAGED %"),
                                    timePeriods
                                        .filter((t) => {
                                        return t !== "180 Day";
                                    })
                                        .map((t) => {
                                        var _a, _b;
                                        const score = (_a = connectionMetrics[t]) === null || _a === void 0 ? void 0 : _a.opt_in_engaged_transfer_rate;
                                        return (React.createElement(Tooltip, { key: t, title: getTooltipText("Opt-In Engaged Transfer Score", settings.zhl_engaged_transfer_score_red_value, settings.zhl_engaged_transfer_score_green_value, true) },
                                            React.createElement(TableCell, { className: `zhl-data-cell ${(_b = getMetricScore(score, settings.zhl_engaged_transfer_score_red_value, settings.zhl_engaged_transfer_score_green_value)) !== null && _b !== void 0 ? _b : ""}`, style: {
                                                    fontStyle: "normal",
                                                    borderLeft: t === "30 Day" || t === "90 Day" ? "3px solid #666666" : undefined,
                                                    borderBottom: t === "30 Day" || t === "90 Day" ? "3px solid #666666" : undefined,
                                                    fontWeight: t === "90 Day" ? "bold" : undefined,
                                                    borderTop: t === "90 Day" ? "3px solid #666666" : undefined,
                                                    width: "52px",
                                                } }, formatPercentageMemoized(score))));
                                    })),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { colSpan: 2, className: "category-c", style: { fontSize: "11pt", borderTop: "1px solid black" } }, "LOCK %"),
                                    timePeriods
                                        .filter((t) => {
                                        return !["30 Day", "180 Day"].includes(t);
                                    })
                                        .map((t) => {
                                        var _a, _b;
                                        const score = (_a = connectionMetrics[t]) === null || _a === void 0 ? void 0 : _a.at_lock_rate;
                                        return (React.createElement(Tooltip, { key: t, title: getTooltipText("Lock Rate Score", settings.zhl_lock_percent_score_red_value, settings.zhl_lock_percent_score_green_value, true) },
                                            React.createElement(TableCell, { key: t, className: `zhl-data-cell ${(_b = getMetricScore(score, settings.zhl_lock_percent_score_red_value, settings.zhl_lock_percent_score_green_value)) !== null && _b !== void 0 ? _b : ""}`, style: {
                                                    fontStyle: "normal",
                                                    borderLeft: t === "60 Day" ? "3px solid #666666" : undefined,
                                                    borderBottom: t === "60 Day" ? "3px solid #666666" : undefined,
                                                    width: "52px",
                                                } }, formatPercentageMemoized(score))));
                                    })))))),
                React.createElement(Box, null,
                    React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                        React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                            React.createElement(TableBody, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "width-d", style: {
                                            fontWeight: "bold",
                                            fontSize: "10pt",
                                            verticalAlign: "bottom",
                                        } }, "% ATTACH TO TARGET"))))),
                    React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                        React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                            React.createElement(TableBody, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c", style: {
                                            fontSize: "8pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "left",
                                        } }, "180 DAY ZILLOW Deals"),
                                    React.createElement(TableCell, { className: "width-b", style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatDecimalMemoized((_32 = connectionMetrics["180 Day"]) === null || _32 === void 0 ? void 0 : _32.num_zillow_deals))),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c", style: {
                                            fontSize: "8pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        } }, `${formatPercentageMemoized(settings.zhl_attach_target)} Attach Target`),
                                    React.createElement(TableCell, { className: "width-b", style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatDecimalMemoized((_33 = connectionMetrics["180 Day"]) === null || _33 === void 0 ? void 0 : _33.zhl_attach_target))),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c", style: {
                                            fontSize: "7pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        } }, "ACTUAL ZHL FUNDED / LOCKED"),
                                    React.createElement(TableCell, { className: "width-b", style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatDecimalMemoized((_34 = connectionMetrics["180 Day"]) === null || _34 === void 0 ? void 0 : _34.actual_zhl_funded_or_locked))),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c", style: {
                                            fontSize: "7pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                            paddingBottom: "8px",
                                            paddingTop: "8px",
                                        } }, "= % TO ATTACH TARGET"),
                                    React.createElement(Tooltip, { title: getTooltipText("ZHL % to Attach Target", settings.zhl_percent_to_attach_target_red_value, settings.zhl_percent_to_attach_target_green_value, true) },
                                        React.createElement(TableCell, { className: `width-b ${(_36 = getMetricScore((_35 = connectionMetrics["180 Day"]) === null || _35 === void 0 ? void 0 : _35.zhl_percent_to_target, settings.zhl_percent_to_attach_target_red_value, settings.zhl_percent_to_attach_target_green_value)) !== null && _36 !== void 0 ? _36 : ""}`, style: {
                                                fontSize: "9pt",
                                                fontWeight: "bold",
                                                fontStyle: "normal",
                                                paddingBottom: "8px",
                                                paddingTop: "8px",
                                            } }, formatPercentageMemoized((_37 = connectionMetrics["180 Day"]) === null || _37 === void 0 ? void 0 : _37.zhl_percent_to_target))))))))))),
        currentTab === "PCA" && (React.createElement(ScorecardTabSection, { tabName: currentTab, text: (_39 = (_38 = tabDescriptions.find((item) => {
                return item.tab_name === currentTab;
            })) === null || _38 === void 0 ? void 0 : _38.content) !== null && _39 !== void 0 ? _39 : "", hasEditPermission: !!isAdmin, isUpdating: currentlyUpdatingTabs.includes(currentTab), updateDescriptionText: updateTabDescription },
            React.createElement(Box, { display: "flex" },
                React.createElement(Box, { maxWidth: "250px" },
                    React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                        React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                            React.createElement(TableBody, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "width-d", style: {
                                            fontWeight: "bold",
                                            fontSize: "10pt",
                                            verticalAlign: "bottom",
                                        } }, "% PCA TO TARGET"))))),
                    React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                        React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                            React.createElement(TableBody, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                            fontSize: "7pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "left",
                                        } }, "180 DAY NON-SELF & NOT NEW CONSTRUCTION"),
                                    React.createElement(TableCell, { className: "width-b", style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatDecimalMemoized((_40 = agentMetrics["180 Day"]) === null || _40 === void 0 ? void 0 : _40.pca_not_self_or_new_construction))),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                            fontSize: "8pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        } }, `${formatPercentageMemoized(settings.pca_target)} PCA Target`),
                                    React.createElement(TableCell, { className: "width-b", style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatDecimal((_41 = agentMetrics["180 Day"]) === null || _41 === void 0 ? void 0 : _41.pca_target))),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                            fontSize: "7pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        } }, "ACTUAL PREFERRED ATTY"),
                                    React.createElement(TableCell, { className: "width-b", style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatDecimal((_42 = agentMetrics["180 Day"]) === null || _42 === void 0 ? void 0 : _42.pca_actual_preferred_attorney))),
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                            fontSize: "7pt",
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        } }, "= % TO ATTACH TARGET"),
                                    React.createElement(Tooltip, { title: getTooltipText("% to Attach Target", settings.pca_percent_to_attach_target_red_value, settings.pca_percent_to_attach_target_green_value, true) },
                                        React.createElement(TableCell, { className: `width-b ${getMetricScore((_43 = agentMetrics["180 Day"]) === null || _43 === void 0 ? void 0 : _43.pca_percent_attach_to_target, settings.pca_percent_to_attach_target_red_value, settings.pca_percent_to_attach_target_green_value)}`, style: {
                                                fontSize: "9pt",
                                                fontWeight: "bold",
                                                fontStyle: "normal",
                                            } }, formatPercentageMemoized((_44 = agentMetrics["180 Day"]) === null || _44 === void 0 ? void 0 : _44.pca_percent_attach_to_target)))))))),
                React.createElement(Box, { pt: "6px" },
                    React.createElement(Typography, { fontWeight: "bold", fontSize: "10pt", align: "center" }, "Current PCAs"),
                    React.createElement(List, { dense: true }, CURRENT_PCAS.map((pca) => {
                        return (React.createElement(ListItem, { key: pca, style: { display: "flex" } },
                            React.createElement(ListItemText, { slotProps: {
                                    primary: {
                                        style: {
                                            display: "flex",
                                        },
                                    },
                                }, primary: React.createElement(React.Fragment, null,
                                    "\u2022",
                                    React.createElement(Typography, { style: { paddingLeft: "8px" } }, pca)) })));
                    })))))),
        currentTab === "PIG" && (React.createElement(ScorecardTabSection, { tabName: currentTab, text: (_46 = (_45 = tabDescriptions.find((item) => {
                return item.tab_name === currentTab;
            })) === null || _45 === void 0 ? void 0 : _45.content) !== null && _46 !== void 0 ? _46 : "", hasEditPermission: !!isAdmin, isUpdating: currentlyUpdatingTabs.includes(currentTab), updateDescriptionText: updateTabDescription },
            React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                    React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "width-d", style: {
                                        fontWeight: "bold",
                                        fontSize: "10pt",
                                        verticalAlign: "bottom",
                                    } }, "% PIG TO TARGET"))))),
                React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                    React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                        fontSize: "7pt",
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontWeight: "normal",
                                        textAlign: "left",
                                    } }, "180 DAY BUYER CONTRACTS"),
                                React.createElement(TableCell, { className: "width-b", style: {
                                        fontSize: "14pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatDecimalMemoized((_47 = agentMetrics["180 Day"]) === null || _47 === void 0 ? void 0 : _47.pig_buyer_contracts))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                        fontSize: "8pt",
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    } }, `${formatPercentageMemoized(settings.pig_target)} PIG Target`),
                                React.createElement(TableCell, { className: "width-b", style: {
                                        fontSize: "14pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatDecimal((_48 = agentMetrics["180 Day"]) === null || _48 === void 0 ? void 0 : _48.pig_target))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                        fontSize: "7pt",
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    } }, "INSURANCE POLICIES"),
                                React.createElement(TableCell, { className: "width-b", style: {
                                        fontSize: "14pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatDecimal((_49 = agentMetrics["180 Day"]) === null || _49 === void 0 ? void 0 : _49.pig_insurance_policies))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                        fontSize: "7pt",
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    } }, "= % TO ATTACH TARGET"),
                                React.createElement(Tooltip, { title: getTooltipText("% to Attach Target", settings.pig_percent_to_attach_target_red_value, settings.pig_percent_to_attach_target_green_value, true) },
                                    React.createElement(TableCell, { className: `width-b ${getMetricScore((_50 = agentMetrics["180 Day"]) === null || _50 === void 0 ? void 0 : _50.pig_percent_attach_to_target, settings.pig_percent_to_attach_target_red_value, settings.pig_percent_to_attach_target_green_value)}`, style: {
                                            fontSize: "9pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        } }, formatPercentageMemoized((_51 = agentMetrics["180 Day"]) === null || _51 === void 0 ? void 0 : _51.pig_percent_attach_to_target)))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                        fontSize: "7pt",
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    } }, "Contracts with Call Logs"),
                                React.createElement(TableCell, { className: "width-b", style: {
                                        fontSize: "14pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatDecimal((_52 = agentMetrics["60 Day"]) === null || _52 === void 0 ? void 0 : _52.pig_num_contracts_with_logs))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "category-c width-c height-a", style: {
                                        fontSize: "7pt",
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    } }, "PIG ETR"),
                                React.createElement(TableCell, { className: "width-b", style: {
                                        fontSize: "9pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatPercentageMemoized((_53 = agentMetrics["60 Day"]) === null || _53 === void 0 ? void 0 : _53.pig_etr))))))))),
        currentTab === "CSAT" && (React.createElement(ScorecardTabSection, { tabName: currentTab, text: (_55 = (_54 = tabDescriptions.find((item) => {
                return item.tab_name === currentTab;
            })) === null || _54 === void 0 ? void 0 : _54.content) !== null && _55 !== void 0 ? _55 : "", hasEditPermission: !!isAdmin, isUpdating: currentlyUpdatingTabs.includes(currentTab), updateDescriptionText: updateTabDescription },
            React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                    React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { className: "width-d", style: {
                                        fontWeight: "bold",
                                        fontSize: "10pt",
                                        verticalAlign: "bottom",
                                    } }, "CSAT"))))),
                React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                    React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, { className: "value-a" },
                                React.createElement(TableCell, { className: "category-c width-c" }),
                                React.createElement(Tooltip, { title: getTooltipText("CSAT Score", settings.csat_score_red_value, settings.csat_score_green_value) },
                                    React.createElement(TableCell, { className: `width-b ${(_56 = getMetricScore(csatScore, settings.csat_score_red_value, settings.csat_score_green_value)) !== null && _56 !== void 0 ? _56 : ""}`, style: {
                                            fontSize: "14pt",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                            height: "30px",
                                            border: "none",
                                        } }, formatDecimalMemoized(csatScore)))))))))),
        currentTab === "SLA" && (React.createElement(React.Fragment, null,
            React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black", marginRight: "0px" } },
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { style: {
                                    fontWeight: "bold",
                                    fontSize: "10pt",
                                    borderBottom: "none",
                                    verticalAlign: "bottom",
                                    paddingBottom: "0px",
                                }, className: "width-d" }, "SLA"))))),
            React.createElement(Box, { display: "flex", width: "100%", pb: 2 },
                React.createElement(StyledTable, { boldBorders: true, style: { border: "3px solid black" } },
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, { className: "value-a" },
                            React.createElement(TableCell, { className: "category-c width-c", style: {
                                    fontSize: "8pt",
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    fontWeight: "normal",
                                    textAlign: "left",
                                } }, "# of Violations | 30 Day"),
                            React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                                    React.createElement(Typography, { align: "center", style: { fontWeight: "bold", fontSize: "14px" } }, "Number of SLA Violations"),
                                    React.createElement(Typography, null, `Green below: ${(_57 = settings.num_sla_violations_green_value) !== null && _57 !== void 0 ? _57 : "-"}`),
                                    React.createElement(Typography, null, `Red above: ${(_58 = settings.num_sla_violations_red_value) !== null && _58 !== void 0 ? _58 : "-"}`)) },
                                React.createElement(TableCell, { className: `width-b ${(_59 = getSLAViolationsDescriptor(numSLAViolations, settings.num_sla_violations_red_value, settings.num_sla_violations_green_value)) !== null && _59 !== void 0 ? _59 : ""}`, style: {
                                        fontSize: "14pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatDecimalMemoized(numSLAViolations)))),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: "category-c width-c", style: {
                                    fontSize: "9pt",
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    fontWeight: "normal",
                                    textAlign: "center",
                                } }, "# of Days with Violations | 30 Day"),
                            React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                                    React.createElement(Typography, { align: "center", style: { fontWeight: "bold", fontSize: "14px" } }, "Number of Days with SLA Violations"),
                                    React.createElement(Typography, null, `Green below: ${(_60 = settings.days_with_sla_violations_green_value) !== null && _60 !== void 0 ? _60 : "-"}`),
                                    React.createElement(Typography, null, `Red above: ${(_61 = settings.days_with_sla_violations_red_value) !== null && _61 !== void 0 ? _61 : "-"}`)) },
                                React.createElement(TableCell, { className: `width-b ${(_62 = getSLAViolationsDescriptor(numDaysWithSLAViolations, settings.days_with_sla_violations_red_value, settings.days_with_sla_violations_green_value)) !== null && _62 !== void 0 ? _62 : ""}`, style: {
                                        fontSize: "14pt",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    } }, formatDecimalMemoized(numDaysWithSLAViolations))))))),
            getSLAViolationsContext(slaViolations !== null && slaViolations !== void 0 ? slaViolations : [])))));
};
export default AgentScorecard;
