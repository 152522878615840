var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Image, UploadFile, Close, Slideshow } from "@mui/icons-material";
import { Tooltip, IconButton, Grid2, DialogContent, Typography, Button, DialogTitle, FormControlLabel, Switch, } from "@mui/material";
import { usePublisher, insertDirective$ } from "@mdxeditor/editor";
import * as MDXEditor_ from "@mdxeditor/editor";
import CoreDialog from "@app/common/CoreDialog";
import CoreTextField from "@app/common/CoreTextField";
import { fileToBase64, onUploadButtonClick } from "@app/util/Utils";
const { NestedLexicalEditor } = MDXEditor_;
// remarkdirective for each custom directive ?
export const ImageEditor = (_) => {
    // console.log("props:", props);
    return (React.createElement("div", null,
        React.createElement(NestedLexicalEditor, { block: true, 
            // eslint-disable-next-line
            getContent: (node) => {
                var _a;
                return [
                    Object.assign(Object.assign({}, node), { type: "image", url: ((_a = node.attributes) === null || _a === void 0 ? void 0 : _a["src"]) ? node.attributes["src"] : "", width: "500", height: "500" }),
                ];
            }, 
            // eslint-disable-next-line
            getUpdatedMdastNode: (mdastNode, children) => {
                return Object.assign(Object.assign({}, mdastNode), { children });
            } })));
};
export const VideoEditor = (props) => {
    var _a, _b, _c, _d;
    // const data = {
    //   hName: "iframe",
    //   // src: props.mdastNode.attributes?.["src"],
    //   hProperties: {
    //     ...props.mdastNode.attributes,
    //     class: props.mdastNode.name,
    //     className: props.mdastNode.name,
    //     frameBorder: 0,
    //     allow: "picture-in-picture",
    //     allowFullScreen: true,
    //   },
    // };
    const attrs = props.mdastNode.attributes;
    if (!attrs) {
        return React.createElement("div", null);
    }
    // props.mdastNode.data = data;
    return (React.createElement("div", null,
        React.createElement("iframe", { src: (_a = attrs["src"]) !== null && _a !== void 0 ? _a : undefined, title: (_b = attrs["title"]) !== null && _b !== void 0 ? _b : undefined, frameBorder: "0", allowFullScreen: true, width: (_c = attrs["width"]) !== null && _c !== void 0 ? _c : 600, height: (_d = attrs["height"]) !== null && _d !== void 0 ? _d : 400 })));
};
export const UploadImageDirectiveDescriptor = {
    name: "uploadimage",
    testNode(node) {
        return node.name === "uploadimage";
    },
    attributes: ["src"],
    hasChildren: false,
    Editor: ImageEditor,
};
export const EmbedVideoDirectiveDescriptor = {
    name: "embedvideo",
    testNode(node) {
        return node.name === "embedvideo";
    },
    attributes: ["src", "width", "height"],
    hasChildren: false,
    Editor: VideoEditor,
};
export const DialogSubmitButton = (props) => {
    const { isDisabled, onSubmit, style, text } = props;
    const insertDirective = usePublisher(insertDirective$);
    // const imageUploadHandler = imagePluginHooks.usePublisher("imageUploadHandler");
    return (React.createElement(Button, { color: "primary", disabled: isDisabled, onClick: onSubmit(insertDirective), style: Object.assign({}, style) }, text !== null && text !== void 0 ? text : "Submit"));
};
export class UploadImageButton extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                openDialog: false,
                uploadFromLocal: true,
                selectedFile: null,
                imageLink: null,
            }
        });
        Object.defineProperty(this, "openDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    openDialog: true,
                });
            }
        });
        Object.defineProperty(this, "closeDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    openDialog: false,
                });
            }
        });
        Object.defineProperty(this, "setImage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const file = e.target.files ? e.target.files[0] : null;
                if (file) {
                    this.setState({
                        selectedFile: file,
                        imageLink: null,
                    });
                }
                else {
                    console.warn("No file selected");
                }
            }
        });
        Object.defineProperty(this, "setLink", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (link) => {
                this.setState({
                    selectedFile: null,
                    imageLink: link === "" ? null : link,
                });
            }
        });
        Object.defineProperty(this, "onSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (insertDirective) => () => {
                const { imageLink, selectedFile } = this.state;
                if (selectedFile) {
                    fileToBase64(selectedFile).then((fileData) => {
                        insertDirective({
                            name: "uploadimage",
                            type: "leafDirective",
                            attributes: { src: `data:${selectedFile.type};base64,${fileData}` },
                            // children: [],
                        });
                        this.closeDialog();
                    });
                }
                else if (imageLink) {
                    insertDirective({
                        name: "uploadimage",
                        type: "leafDirective",
                        attributes: { src: imageLink },
                        // children: [],
                    });
                    this.closeDialog();
                }
            }
        });
        Object.defineProperty(this, "toggleUploadType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => {
                    return {
                        uploadFromLocal: !prevState.uploadFromLocal,
                    };
                });
            }
        });
    }
    render() {
        const { imageLink, openDialog, selectedFile, uploadFromLocal } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Upload Image") },
                React.createElement(IconButton, { onClick: this.openDialog },
                    React.createElement(Image, null))),
            React.createElement(CustomButtonDialog, { title: "Upload Image", open: !!openDialog, onCancel: this.closeDialog },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { container: true, size: 12, style: { flex: 1, padding: "8px", height: "80px" } },
                        React.createElement(Grid2, { style: { display: "flex" } },
                            React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: uploadFromLocal, onChange: this.toggleUploadType, color: "primary" }), label: "Upload from Local Device", style: { marginTop: "auto", marginBottom: "auto" } })),
                        uploadFromLocal && (React.createElement(React.Fragment, null,
                            React.createElement(Grid2, { style: { display: "flex" } },
                                React.createElement(Button, { variant: "contained", size: "small", color: "primary", onClick: onUploadButtonClick("image-upload-input"), endIcon: React.createElement(UploadFile, null), style: { marginBottom: "auto", marginTop: "auto" } }, "Select a File"),
                                React.createElement("input", { type: "file", id: "image-upload-input", style: { display: "none" }, accept: "image/*", onChange: this.setImage })),
                            selectedFile && (React.createElement(Grid2, { style: { display: "flex", padding: "8px" } },
                                React.createElement(Typography, { style: { marginBottom: "auto", marginTop: "auto" } }, selectedFile.name))))),
                        !uploadFromLocal && (React.createElement(Grid2, { style: { flex: 1, display: "flex" } },
                            React.createElement(CoreTextField, { variant: "standard", label: "Image Link", fullWidth: true, onTextChange: this.setLink, value: imageLink, style: { marginBottom: "auto" } })))),
                    React.createElement(Grid2, { style: { display: "flex" } },
                        React.createElement(DialogSubmitButton, { text: "Upload", isDisabled: !selectedFile && !imageLink, onSubmit: this.onSubmit, style: { marginBottom: "auto", marginTop: "auto", height: "26px" } }))))));
    }
}
export class EmbedVideoButton extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                openDialog: false,
                embedCode: null,
            }
        });
        Object.defineProperty(this, "openDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    openDialog: true,
                });
            }
        });
        Object.defineProperty(this, "closeDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    openDialog: false,
                });
            }
        });
        Object.defineProperty(this, "setEmbedCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({
                    embedCode: text === "" ? null : text,
                });
            }
        });
        Object.defineProperty(this, "onSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (insertDirective) => () => {
                const { embedCode } = this.state;
                if (embedCode) {
                    let src = embedCode;
                    if (src.toLowerCase().trim().includes("<iframe")) {
                        // get src from iframe
                        // eslint-disable-next-line
                        const match = src.match("src=\"(.*?)\"");
                        src = match ? match[1] : src;
                    }
                    else if (src.toLowerCase().trim().includes("loom.com")) {
                        src = src.replace("share", "embed");
                    }
                    else if (src.toLowerCase().trim().includes("youtu")) {
                        const srcSplit = src.split("/");
                        const vidId = srcSplit[srcSplit.length - 1].replaceAll("watch?v=", "");
                        src = `https://www.youtube.com/embed/${vidId}`;
                    }
                    insertDirective({
                        name: "embedvideo",
                        type: "leafDirective",
                        attributes: { src, width: "600", height: "400" },
                    });
                    this.closeDialog();
                }
            }
        });
    }
    render() {
        const { embedCode, openDialog } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Embed Video") },
                React.createElement(IconButton, { onClick: this.openDialog },
                    React.createElement(Slideshow, null))),
            React.createElement(CustomButtonDialog, { title: "Embed Video", open: !!openDialog, onCancel: this.closeDialog },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { style: { flex: 1, display: "flex" } },
                        React.createElement(CoreTextField, { variant: "standard", label: "Video", fullWidth: true, onTextChange: this.setEmbedCode, value: embedCode })),
                    React.createElement(Grid2, { style: { display: "flex" } },
                        React.createElement(DialogSubmitButton, { text: "Embed", isDisabled: !embedCode, onSubmit: this.onSubmit, style: { marginTop: "auto" } }))))));
    }
}
export class CustomButtonDialog extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                open: this.props.open,
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        return {
            open: nextProps.open,
        };
    }
    render() {
        const _a = this.props, { children, onCancel, open, title } = _a, other = __rest(_a, ["children", "onCancel", "open", "title"]);
        return (React.createElement(CoreDialog, Object.assign({ open: this.state.open }, other, { maxWidth: "sm", fullWidth: true }),
            React.createElement(DialogTitle, null,
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { flex: 1 },
                        React.createElement(Typography, { variant: "h4", align: "center" }, title)),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { onClick: onCancel },
                            React.createElement(Close, null))))),
            React.createElement(DialogContent, null, children)));
    }
}
