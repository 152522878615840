import * as React from "react";
import { Edit, House, RemoveCircleOutline } from "@mui/icons-material";
import { Grid2, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useConfirm } from "material-ui-confirm";
import dayjs_ from "@app/common/dayjs";
import RouterLink from "@app/common/RouterLink";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import StreetViewImage from "../StreetViewImage";
const PropertyListItem = (props) => {
    const { canEdit = true, onEditProperty, onRemoveProperty, property } = props;
    const confirm = useConfirm();
    const removeProperty = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This will remove the property from the lead.", confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveProperty(property.uid);
            }
        });
    }, [confirm, onRemoveProperty, property.uid]);
    const editProperty = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        onEditProperty(property.uid);
    }, [onEditProperty, property.uid]);
    return (React.createElement(ListItem, { secondaryAction: canEdit ? (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit property") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: editProperty },
                        React.createElement(Edit, { fontSize: "small" })))),
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove property") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removeProperty },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" })))))) : null },
        React.createElement(ListItemIcon, null,
            React.createElement(StreetViewImage, { address: property.property_address, ImageProps: { style: { width: "32px", height: "32px", borderRadius: "2px" } }, defaultIcon: React.createElement(House, null) })),
        React.createElement(ListItemText, { primary: property.property_address, secondary: React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "caption" }, property.created_at && dayjs_(property.created_at).isValid()
                        ? `Added ${dayjs_(property.created_at).format("dddd, MMMM D, YYYY h:mm A")}`
                        : "")),
                property.psps.map((psp) => (React.createElement(Grid2, { key: psp.uid },
                    React.createElement(Typography, { variant: "caption", color: "textSecondary" },
                        React.createElement(RouterLink, { to: `/matrix/${psp.uid}` },
                            "PSP: ",
                            psp.property_address))))),
                property.showings.map((showing) => (React.createElement(Grid2, { key: showing.uid },
                    React.createElement(Typography, { variant: "caption", color: "textSecondary" },
                        React.createElement(RouterLink, { to: `/showings/${showing.uid}` },
                            showing.showing_type,
                            ": ",
                            showing.datetime ? dayjs(showing.datetime).format("M/D/YYYY h:mm A") : null))))),
                property.qfos.map((qfo) => (React.createElement(Grid2, { key: qfo.uid },
                    React.createElement(Typography, { variant: "caption", color: "textSecondary" },
                        React.createElement(RouterLink, { to: `/forms/qfo/${qfo.uid}` },
                            "QFO: ",
                            qfo.property_address)))))) })));
};
export default PropertyListItem;
