import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import EditSOP from "@brokerage/features/apps/sops/EditSOP";
const EditSOPPage = () => {
    useDocumentTitle("SOPs");
    const params = useParams();
    const { uid } = params;
    return React.createElement(EditSOP, { uid: uid });
};
export default EditSOPPage;
