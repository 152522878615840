import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListMailschedule4TemplateTypes } from "@app/orval/api/mail-schedule-4-template-types";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { MAIL_SCHEDULE_ENTITY_TYPES } from "../utils/utils";
const MailScheduleTemplatesSidebar = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { onSidebarToggle } = props;
    const [searchParams] = useSearchParams();
    const selectedPipeline = (_a = searchParams.get("pipeline")) !== null && _a !== void 0 ? _a : null;
    const selectedTemplateType = (_b = searchParams.get("template_type")) !== null && _b !== void 0 ? _b : null;
    const selectedTemplateStatus = (_c = searchParams.get("status")) !== null && _c !== void 0 ? _c : null;
    const selectedCategory = (_d = searchParams.get("category")) !== null && _d !== void 0 ? _d : null;
    const updateSearch = useUpdateSearch();
    const entityTypes = MAIL_SCHEDULE_ENTITY_TYPES;
    const listTemplateTypesApi = useListMailschedule4TemplateTypes(INFINITE_CACHE_PARAMS);
    const templateTypes = (_f = (_e = listTemplateTypesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const setPipeline = React.useCallback((e) => {
        updateSearch("pipeline", e.target.value || null);
    }, [updateSearch]);
    const setTemplateType = React.useCallback((e) => {
        updateSearch("template_type", e.target.value || null);
    }, [updateSearch]);
    const setTemplateStatus = React.useCallback((e) => {
        updateSearch("status", e.target.value || null);
    }, [updateSearch]);
    const setCategory = React.useCallback((e) => {
        updateSearch("category", e.target.value || null);
    }, [updateSearch]);
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid2, { container: true, spacing: 1 },
                    React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, { variant: "h6" }, "Filters")),
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(IconButton, { onClick: onSidebarToggle },
                            React.createElement(Close, null)))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { component: Grid2, container: true, spacing: 2, p: 2, direction: "column", wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(TextField, { variant: "standard", label: "Pipeline", fullWidth: true, select: true, value: selectedPipeline !== null && selectedPipeline !== void 0 ? selectedPipeline : "any", onChange: setPipeline, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "any" }, "Any"),
                        entityTypes.map((pipeline) => (React.createElement(MenuItem, { key: pipeline, value: pipeline }, pipeline))))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { variant: "standard", label: "Template Type", fullWidth: true, select: true, value: selectedTemplateType !== null && selectedTemplateType !== void 0 ? selectedTemplateType : "", onChange: setTemplateType, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        (templateTypes !== null && templateTypes !== void 0 ? templateTypes : []).map((templateType) => (React.createElement(MenuItem, { key: templateType.name, value: templateType.name }, templateType.name))))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { variant: "standard", label: "Category", fullWidth: true, select: true, value: selectedCategory !== null && selectedCategory !== void 0 ? selectedCategory : "", onChange: setCategory, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        React.createElement(MenuItem, { value: "snippet" }, "Snippet"),
                        React.createElement(MenuItem, { value: "mailschedule" }, "Mail Schedule"))),
                React.createElement(Grid2, null,
                    React.createElement(TextField, { variant: "standard", label: "Status", fullWidth: true, select: true, value: selectedTemplateStatus !== null && selectedTemplateStatus !== void 0 ? selectedTemplateStatus : "", onChange: setTemplateStatus, SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "Any"),
                        React.createElement(MenuItem, { value: "ok" }, "OK"),
                        React.createElement(MenuItem, { value: "broken" }, "Broken")))))));
};
export default MailScheduleTemplatesSidebar;
