import * as React from "react";
import { LabelImportant } from "@mui/icons-material";
import { TextField, Grid2, InputAdornment, MenuItem, Typography } from "@mui/material";
const StageSelect = (props) => {
    const { TextFieldProps, disableInputAdornment, onChange, stages, value } = props;
    const setValue = React.useCallback((e) => {
        onChange(e.target.value);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ fullWidth: true, size: "small", slotProps: {
            input: {
                startAdornment: disableInputAdornment ? undefined : React.createElement(InputAdornment, { position: "start" }, "Stage"),
            },
        } }, TextFieldProps, { select: true, value: value, onChange: setValue }), stages.map((stage) => (React.createElement(MenuItem, { key: stage.uid, value: stage.uid },
        React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(LabelImportant, { style: { color: stage.color } })),
            React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { variant: "body2" }, stage.name))))))));
};
export default StageSelect;
