/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Lists all Slack channels. DASH's Slack integration, "Dashbot", must have access to the channel in order to be included.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Channels
 */
export const listSlackChannels = (params, options) => {
    return axios.get(`/api/slack/channels`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListSlackChannelsQueryKey = (params) => {
    return [`/api/slack/channels`, ...(params ? [params] : [])];
};
export const getListSlackChannelsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSlackChannelsQueryKey(params);
    const queryFn = ({ signal }) => listSlackChannels(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Channels
 */
export function useListSlackChannels(params, options) {
    const queryOptions = getListSlackChannelsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Lists members in a slack channel.

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary List Channels
 */
export const listSlackChannelMembers = (channelId, options) => {
    return axios.get(`/api/slack/channels/${channelId}/members`, options);
};
export const getListSlackChannelMembersQueryKey = (channelId) => {
    return [`/api/slack/channels/${channelId}/members`];
};
export const getListSlackChannelMembersQueryOptions = (channelId, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSlackChannelMembersQueryKey(channelId);
    const queryFn = ({ signal }) => listSlackChannelMembers(channelId, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(channelId) }, queryOptions);
};
/**
 * @summary List Channels
 */
export function useListSlackChannelMembers(channelId, options) {
    const queryOptions = getListSlackChannelMembersQueryOptions(channelId, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Lists all Slack users.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Users
 */
export const listSlackUsers = (options) => {
    return axios.get(`/api/slack/users`, options);
};
export const getListSlackUsersQueryKey = () => {
    return [`/api/slack/users`];
};
export const getListSlackUsersQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSlackUsersQueryKey();
    const queryFn = ({ signal }) => listSlackUsers(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Users
 */
export function useListSlackUsers(options) {
    const queryOptions = getListSlackUsersQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
