import * as React from "react";
import { Box, Typography } from "@mui/material";
import dayjs_ from "@app/common/dayjs";
const DateCriteriaHelper = ({ criteria, fields }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const interval = (_a = criteria.interval) !== null && _a !== void 0 ? _a : 0;
    const x0 = interval > 0 ? 100 : 200;
    const x1 = interval > 0 ? 200 : interval < 0 ? 100 : 150;
    const lineEnd = (_b = {
        "is after": 300,
        "is equal to or after": 300,
        "is before": 0,
        "is equal to or before": 0,
    }[String(criteria.comparison)]) !== null && _b !== void 0 ? _b : x1;
    const fieldNameLhs = (_c = fields.find((f) => f.key === criteria.field_key_lhs)) === null || _c === void 0 ? void 0 : _c.name;
    const fieldNameRhs = (_f = (_e = (_d = fields.find((f) => f.key === criteria.field_key_rhs)) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : criteria.value_rhs) !== null && _f !== void 0 ? _f : "today";
    let description = "";
    if (fieldNameRhs === "today") {
        const now = dayjs_(new Date());
        const diff = (_g = criteria.interval) !== null && _g !== void 0 ? _g : 0;
        // const absDiff = Math.abs(diff);
        const { units } = criteria;
        let then;
        const newDate = now.add(diff, units);
        if (units === "day") {
            then = newDate.format("dddd, MMMM D, YYYY");
        }
        else if (units === "hour") {
            then = newDate.format("dddd, MMMM D, YYYY h:mm A");
        }
        else if (units === "month") {
            then = newDate.format("dddd, MMMM D, YYYY");
        }
        else if (units === "week") {
            then = newDate.format("dddd, MMMM D, YYYY");
        }
        else if (units === "year") {
            then = newDate.format("dddd, MMMM D, YYYY");
        }
        else {
            throw new Error(`Invalid units ${units}`);
        }
        description = `Satisfied if <b>${fieldNameLhs}</b> ${((_h = criteria.comparison) !== null && _h !== void 0 ? _h : "").replace("equal to", "on")} <b>${then}</b>`;
    }
    else if (criteria.value_rhs) {
        description = `Satisfied if <b>${fieldNameLhs}</b> ${((_j = criteria.comparison) !== null && _j !== void 0 ? _j : "").replace("equal to", "on")} <b>${dayjs_(criteria.value_rhs).format("dddd, MMMM D, YYYY")}</b>`;
    }
    return (React.createElement(Box, { p: 4, style: { height: "175px", width: "300px" } },
        React.createElement(Typography, { variant: "body2", dangerouslySetInnerHTML: { __html: description } }),
        React.createElement("svg", null,
            React.createElement("line", { x1: 0, y1: 75, x2: 300, y2: 75, stroke: "black" }),
            interval !== 0 && (React.createElement(React.Fragment, null,
                React.createElement("circle", { cx: x0, cy: 75, r: 10, fill: "blue" }),
                React.createElement("text", { x: x0, y: 50, textAnchor: "middle" }, fieldNameRhs))),
            React.createElement("circle", { cx: x1, cy: 75, r: 10, fill: "red" }),
            React.createElement("text", { x: x1, y: 50, textAnchor: "middle" }, fieldNameLhs),
            criteria.interval && (React.createElement("text", { x: 150, y: 100, textAnchor: "middle" },
                Math.abs(criteria.interval),
                " ",
                criteria.units,
                "s")),
            ((_k = criteria.comparison) === null || _k === void 0 ? void 0 : _k.includes("equal")) && (React.createElement("circle", { cx: x1, cy: 75, r: 10, stroke: "green", strokeWidth: 2, fill: "none" })),
            React.createElement("line", { x1: x1, x2: lineEnd, y1: 75, y2: 75, stroke: "green", strokeWidth: 3 }))));
};
export default DateCriteriaHelper;
