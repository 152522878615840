/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Gets all Twilio messages sent to or received from the buyer

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List messages for client
 */
export const listMessagesForBuyerLead = (buyerLeadUid, options) => {
    return axios.get(`/api/buyer_leads/${buyerLeadUid}/messages`, options);
};
export const getListMessagesForBuyerLeadQueryKey = (buyerLeadUid) => {
    return [`/api/buyer_leads/${buyerLeadUid}/messages`];
};
export const getListMessagesForBuyerLeadQueryOptions = (buyerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMessagesForBuyerLeadQueryKey(buyerLeadUid);
    const queryFn = ({ signal }) => listMessagesForBuyerLead(buyerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(buyerLeadUid) }, queryOptions);
};
/**
 * @summary List messages for client
 */
export function useListMessagesForBuyerLead(buyerLeadUid, options) {
    const queryOptions = getListMessagesForBuyerLeadQueryOptions(buyerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a client alias
 */
export const updateClientAlias = (clientUid, updateAliasRequest, options) => {
    return axios.post(`/api/clients/${clientUid}`, updateAliasRequest, options);
};
export const getUpdateClientAliasMutationOptions = (options) => {
    const mutationKey = ['updateClientAlias'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { clientUid, data } = props !== null && props !== void 0 ? props : {};
        return updateClientAlias(clientUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a client alias
*/
export const useUpdateClientAlias = (options) => {
    const mutationOptions = getUpdateClientAliasMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Twilio messages sent to or received from the recruit

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary List messages for a recruit
*/
export const listMessagesForRecruit = (recruitUid, options) => {
    return axios.get(`/api/recruits/${recruitUid}/messages`, options);
};
export const getListMessagesForRecruitQueryKey = (recruitUid) => {
    return [`/api/recruits/${recruitUid}/messages`];
};
export const getListMessagesForRecruitQueryOptions = (recruitUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMessagesForRecruitQueryKey(recruitUid);
    const queryFn = ({ signal }) => listMessagesForRecruit(recruitUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(recruitUid) }, queryOptions);
};
/**
 * @summary List messages for a recruit
 */
export function useListMessagesForRecruit(recruitUid, options) {
    const queryOptions = getListMessagesForRecruitQueryOptions(recruitUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Twilio messages sent to or received from the seller

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List messages for client
 */
export const listMessagesForSellerLead = (sellerLeadUid, options) => {
    return axios.get(`/api/seller_leads/${sellerLeadUid}/messages`, options);
};
export const getListMessagesForSellerLeadQueryKey = (sellerLeadUid) => {
    return [`/api/seller_leads/${sellerLeadUid}/messages`];
};
export const getListMessagesForSellerLeadQueryOptions = (sellerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMessagesForSellerLeadQueryKey(sellerLeadUid);
    const queryFn = ({ signal }) => listMessagesForSellerLead(sellerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(sellerLeadUid) }, queryOptions);
};
/**
 * @summary List messages for client
 */
export function useListMessagesForSellerLead(sellerLeadUid, options) {
    const queryOptions = getListMessagesForSellerLeadQueryOptions(sellerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
