import * as React from "react";
import { Link } from "@mui/material";
import { isChromeExtension, liveBaseURL } from "@app/util/Utils";
/**
 * A Link component that works in an isolated web app
 * as well as Chrome extension, where we can't open things in a new page.
 *
 * In the Chrome extension version:
 * - always opens links in a new page
 * - sets the `href` to the full URL if it's an internal path
 *
 * @param props
 * @returns
 */
const CoreLink = (props) => {
    var _a;
    if (isChromeExtension) {
        const href = ((_a = props.href) === null || _a === void 0 ? void 0 : _a.startsWith("/")) ? liveBaseURL + props.href : props.href;
        return (React.createElement(Link, Object.assign({}, props, { href: href, target: "_blank" }), props.children));
    }
    return React.createElement(Link, Object.assign({}, props), props.children);
};
export default CoreLink;
