import * as React from "react";
import { Add, Delete, DragHandle, Edit, Refresh } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Typography, } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { v4 as uuidv4 } from "uuid";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useLoading from "@app/hooks/useLoading";
import { useDeleteGraduationRequirementType, useListGraduationRequirementTypes, useReorderGraduationRequirementTypes, } from "@app/orval/api/graduation-requirement-types";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const GraduationRequirementTypes = () => {
    var _a, _b;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const droppableId = React.useRef(uuidv4());
    const listGraduationRequirementTypesApi = useListGraduationRequirementTypes(REFETCH_CACHE_PARAMS);
    const graduationRequirementTypes = (_b = (_a = listGraduationRequirementTypesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const deleteGraduationRequirementTypeApi = useDeleteGraduationRequirementType();
    const reorderGraduationRequirementTypesApi = useReorderGraduationRequirementTypes();
    const refresh = React.useCallback(() => {
        listGraduationRequirementTypesApi.refetch();
    }, [listGraduationRequirementTypesApi]);
    const deleteGraduationRequirementType = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listGraduationRequirementTypesApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((t) => t.uid !== uid) });
                });
                deleteGraduationRequirementTypeApi
                    .mutateAsync({ uid })
                    .then((result) => {
                    console.log(result);
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteGraduationRequirementTypeApi, errorDialog, listGraduationRequirementTypesApi.queryKey, queryClient]);
    const reorderGraduationRequirementTypes = React.useCallback((result) => {
        if (result.destination) {
            const reorderedGraduationRequirementTypes = [...graduationRequirementTypes];
            const graduationRequirementType = reorderedGraduationRequirementTypes[result.source.index];
            reorderedGraduationRequirementTypes.splice(result.source.index, 1);
            reorderedGraduationRequirementTypes.splice(result.destination.index, 0, graduationRequirementType);
            queryClient.setQueryData(listGraduationRequirementTypesApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: reorderedGraduationRequirementTypes });
            });
            const uids = reorderedGraduationRequirementTypes.map((x) => x.uid);
            reorderGraduationRequirementTypesApi
                .mutateAsync({ data: { uids } })
                .then((reorderResult) => {
                console.log(reorderResult);
            })
                .catch(errorDialog);
        }
    }, [
        errorDialog,
        listGraduationRequirementTypesApi.queryKey,
        queryClient,
        reorderGraduationRequirementTypesApi,
        graduationRequirementTypes,
    ]);
    const loading = useLoading({
        items: [
            {
                label: "Loading graduation requirements...",
                queryResult: listGraduationRequirementTypesApi,
            },
        ],
    });
    if (loading.isLoading && loading.loadingComponent) {
        return loading.loadingComponent;
    }
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "h6" }, "Graduation Requirements")),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { component: RouterLink, to: "/onboarding/graduation-requirement-types/new", tabIndex: -1 },
                            React.createElement(Add, null))),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { onClick: refresh, disabled: listGraduationRequirementTypesApi.isFetching, tabIndex: -1 },
                            React.createElement(Refresh, null)))))),
        React.createElement(Grid2, null, listGraduationRequirementTypesApi.isRefetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        graduationRequirementTypes && graduationRequirementTypes.length > 0 && (React.createElement(Grid2, null,
            React.createElement(DragDropContext, { onDragEnd: reorderGraduationRequirementTypes },
                React.createElement(Droppable, { droppableId: droppableId.current }, (droppableProvided) => (React.createElement(List, Object.assign({ dense: true }, droppableProvided.droppableProps, { ref: droppableProvided.innerRef }),
                    graduationRequirementTypes.map((t, indx) => (React.createElement(Draggable, { key: t.uid, draggableId: t.uid, index: indx }, (draggableProvided) => (React.createElement(ListItem, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps, { secondaryAction: React.createElement(Grid2, { container: true, spacing: 0, wrap: "nowrap" },
                            React.createElement(Grid2, null,
                                React.createElement(IconButton, { tabIndex: -1, component: RouterLink, to: `/onboarding/graduation-requirement-types/${t.uid}` },
                                    React.createElement(Edit, null))),
                            React.createElement(Grid2, null,
                                React.createElement(IconButton, { onClick: deleteGraduationRequirementType(t.uid), tabIndex: -1 },
                                    React.createElement(Delete, null)))) }),
                        React.createElement(ListItemAvatar, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps),
                            React.createElement(DragHandle, null)),
                        React.createElement(ListItemText, { primary: t.name })))))),
                    droppableProvided.placeholder)))))),
        graduationRequirementTypes && graduationRequirementTypes.length === 0 && (React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, style: { textAlign: "center" } },
                React.createElement(Typography, { variant: "body1" }, "No training types found."))))));
};
export default GraduationRequirementTypes;
