import * as React from "react";
import DataValidationRuleEdit from "./DataValidationRuleEdit";
import DataValidationRuleView from "./DataValidationRuleView";
const DataValidationRule = (props) => {
    const { rule } = props;
    const [mode, setMode] = React.useState("view");
    const toggleMode = React.useCallback(() => {
        setMode((prevMode) => (prevMode === "view" ? "edit" : "view"));
    }, [setMode]);
    const onSave = React.useCallback((rule_) => {
        setMode("view");
        props.onSave(rule_);
    }, [props]);
    const onDelete = React.useCallback(() => {
        props.onDelete();
    }, [props]);
    return mode === "view" ? (React.createElement(DataValidationRuleView, { rule: rule, onEditClicked: toggleMode })) : (React.createElement(DataValidationRuleEdit, { rule: rule, onClose: toggleMode, onSave: onSave, onDelete: onDelete }));
};
export default DataValidationRule;
