import * as React from "react";
import { Link, MenuItem } from "@mui/material";
import useSession from "@app/hooks/useSession";
import PurchaseTwilioPhoneDialog from "./components/twilio/PurchaseTwilioPhoneDialog";
import SendVCardDialog from "./components/vcard/SendVCardDialog";
import ActionsMenu from "../components/box-view/ActionsMenu";
import ReleaseTwilioPhoneDialog from "./components/twilio/ReleaseTwilioPhoneDialog";
const UsersActionsCell = (props) => {
    var _a, _b;
    const { onTwilioPhoneNumberPurchased, onTwilioPhoneNumberReleased, user } = props;
    const session = useSession();
    const [isVCardDialogOpen, setIsVCardDialogOpen] = React.useState(false);
    const [isPurchaseTwilioPhoneDialogOpen, setIsPurchaseTwilioPhoneDialogOpen] = React.useState(false);
    const [isReleaseTwilioPhoneDialogOpen, setIsReleaseTwilioPhoneDialogOpen] = React.useState(false);
    const openVCardDialog = React.useCallback(() => {
        setIsVCardDialogOpen(true);
    }, [setIsVCardDialogOpen]);
    const closeVCardDialog = React.useCallback(() => {
        setIsVCardDialogOpen(false);
    }, [setIsVCardDialogOpen]);
    const openPurchaseTwilioPhoneDialog = React.useCallback(() => {
        setIsPurchaseTwilioPhoneDialogOpen(true);
    }, [setIsPurchaseTwilioPhoneDialogOpen]);
    const closePurchaseTwilioPhoneDialog = React.useCallback(() => {
        setIsPurchaseTwilioPhoneDialogOpen(false);
    }, [setIsPurchaseTwilioPhoneDialogOpen]);
    const handlePhoneNumberPurchaseComplete = React.useCallback((result) => {
        if (onTwilioPhoneNumberPurchased) {
            onTwilioPhoneNumberPurchased(result);
        }
        closePurchaseTwilioPhoneDialog();
    }, [closePurchaseTwilioPhoneDialog, onTwilioPhoneNumberPurchased]);
    const openReleaseTwilioPhoneDialog = React.useCallback(() => {
        setIsReleaseTwilioPhoneDialogOpen(true);
    }, [setIsReleaseTwilioPhoneDialogOpen]);
    const closeReleaseTwilioPhoneDialog = React.useCallback(() => {
        setIsReleaseTwilioPhoneDialogOpen(false);
    }, [setIsReleaseTwilioPhoneDialogOpen]);
    const handlePhoneNumberReleaseComplete = React.useCallback((result) => {
        if (onTwilioPhoneNumberReleased) {
            onTwilioPhoneNumberReleased(result);
        }
        closeReleaseTwilioPhoneDialog();
    }, [closeReleaseTwilioPhoneDialog, onTwilioPhoneNumberReleased]);
    const googleReviewLink = React.useMemo(() => {
        var _a;
        const office = (_a = user.office) === null || _a === void 0 ? void 0 : _a.name;
        if (office === "AVL") {
            return "https://g.page/r/CecIdRMnx50aEB0/review";
        }
        if (office === "RDU") {
            return "https://g.page/r/CSZxioyWc5etEB0/review";
        }
        if (office === "CLT") {
            return "https://g.page/r/Cb-RZ0GqC2clEB0/review";
        }
        if (office === "FAY") {
            return "https://g.page/r/CQH4qTa7QB6sEB0/review";
        }
        return null;
    }, [user.office]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsMenu, null,
            React.createElement(MenuItem, { onClick: openVCardDialog }, "Scan or send vCard..."),
            ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) && (React.createElement(MenuItem, { onClick: openPurchaseTwilioPhoneDialog, disabled: 
                // In order to get a Twilio phone:
                // User must not already have a twilio number, must not be terminated, must have first and last name
                !!user.fields["Twilio #"] || user.stage.name === "Terminated" || !user.first_name || !user.last_name }, "Purchase Twilio phone...")),
            ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole(["Superadmin", "Admin"])) && (React.createElement(MenuItem, { onClick: openReleaseTwilioPhoneDialog, disabled: !user.fields["Twilio #"] || user.stage.name !== "Terminated" }, "Release Twilio phone...")),
            React.createElement(MenuItem, { disabled: !googleReviewLink, component: googleReviewLink ? Link : undefined, href: googleReviewLink !== null && googleReviewLink !== void 0 ? googleReviewLink : undefined, target: googleReviewLink ? "_blank" : undefined },
                "Google Reviews",
                user.office ? ` (${user.office.name})` : "")),
        isVCardDialogOpen && (React.createElement(SendVCardDialog, { DialogProps: { open: true }, onClose: closeVCardDialog, userUid: user.uid, showVCard: true })),
        isPurchaseTwilioPhoneDialogOpen && (React.createElement(PurchaseTwilioPhoneDialog, { DialogProps: { open: true, onClose: closePurchaseTwilioPhoneDialog, maxWidth: "sm", fullWidth: true }, user: user, onCancel: closePurchaseTwilioPhoneDialog, onPurchaseComplete: handlePhoneNumberPurchaseComplete })),
        isReleaseTwilioPhoneDialogOpen && (React.createElement(ReleaseTwilioPhoneDialog, { DialogProps: { open: true, onClose: closeReleaseTwilioPhoneDialog, maxWidth: "sm", fullWidth: true }, user: user, onCancel: closeReleaseTwilioPhoneDialog, onReleaseComplete: handlePhoneNumberReleaseComplete }))));
};
export default UsersActionsCell;
