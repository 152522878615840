import * as React from "react";
import { Box, Grid2 } from "@mui/material";
import PeriodFilter from "./PeriodFilter";
import UsersFilter from "./UsersFilter";
const Filters = () => {
    return (React.createElement(Box, { p: 2, pb: 0, pt: 0 },
        React.createElement(Grid2, { container: true, spacing: 4, alignItems: "center" },
            React.createElement(Grid2, null,
                React.createElement(PeriodFilter, null)),
            React.createElement(Grid2, null,
                React.createElement(UsersFilter, null)))));
};
export default Filters;
