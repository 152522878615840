import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid, InputAdornment, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import CoreNumberField from "@app/common/CoreNumberField";
import { searchUsersBasic } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { sortUsersByName } from "@app/util/Utils";
import usePostQuery from "../../../dashboards/agent-dashboard/usePostQuery";
import AgentSelect from "../../components/box-view/actions/AgentSelect";
const AgentDialog = (props) => {
    var _a;
    const { DialogProps, agent, agentTypes, onClose } = props;
    const defaultAgent = React.useMemo(() => {
        var _a, _b, _c;
        return {
            agent_uid: (_a = agent === null || agent === void 0 ? void 0 : agent.agent.uid) !== null && _a !== void 0 ? _a : null,
            type: (_b = agent === null || agent === void 0 ? void 0 : agent.type) !== null && _b !== void 0 ? _b : "Producer",
            split: (_c = agent === null || agent === void 0 ? void 0 : agent.split) !== null && _c !== void 0 ? _c : null,
        };
    }, [agent === null || agent === void 0 ? void 0 : agent.agent.uid, agent === null || agent === void 0 ? void 0 : agent.split, agent === null || agent === void 0 ? void 0 : agent.type]);
    const [params, setParams] = React.useState(defaultAgent);
    const searchUsersApi = usePostQuery(searchUsersBasic, { disable_sort: true, exclude_terminated: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = searchUsersApi.data) === null || _a === void 0 ? void 0 : _a.data.sort(sortUsersByName)) !== null && _b !== void 0 ? _b : null;
    }, [searchUsersApi.data]);
    const isValid = React.useMemo(() => {
        return params.agent_uid && params.type && JSON.stringify(params) !== JSON.stringify(defaultAgent);
    }, [defaultAgent, params]);
    const handleAgentSelected = React.useCallback((val) => {
        setParams((prevState) => (Object.assign(Object.assign({}, prevState), { agent_uid: val })));
    }, [setParams]);
    const handleTypeChange = React.useCallback((e) => {
        const val = e.target.value;
        setParams((prevState) => (Object.assign(Object.assign({}, prevState), { type: val || null })));
    }, [setParams]);
    const handleSplitChange = React.useCallback((val) => {
        setParams((prevState) => (Object.assign(Object.assign({}, prevState), { split: val })));
    }, [setParams]);
    const handleUpdate = React.useCallback(() => {
        props.onUpdate({
            agent_uid: params.agent_uid,
            // @ts-expect-error: not assignable to type
            type: params.type,
            split: params.split,
        });
    }, [params.agent_uid, params.split, params.type, props]);
    React.useEffect(() => {
        setParams(defaultAgent);
    }, [defaultAgent]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, agent ? "Edit agent" : "Add an agent"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 2, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(AgentSelect, { value: params.agent_uid, onChange: handleAgentSelected, agents: users !== null && users !== void 0 ? users : [], TextFieldProps: {
                            InputProps: { startAdornment: React.createElement(InputAdornment, { position: "start" }, "Agent") },
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", InputProps: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Type"),
                        }, value: (_a = params.type) !== null && _a !== void 0 ? _a : "", onChange: handleTypeChange }, agentTypes.map((agentType) => (React.createElement(MenuItem, { key: agentType, value: agentType }, agentType))))),
                params.type !== "Producer" && (React.createElement(Grid, { item: true },
                    React.createElement(CoreNumberField, { fullWidth: true, variant: "standard", InputProps: {
                            startAdornment: React.createElement(InputAdornment, { position: "start" }, "Split"),
                        }, format: "0,0.00%", number: params.split, onNumberChange: handleSplitChange }))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleUpdate, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default AgentDialog;
