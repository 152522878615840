var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from "react";
import { Call, Device } from "@twilio/voice-sdk";
import { v4 as uuidv4 } from "uuid";
import useSession from "@app/hooks/useSession";
import { getMyTwilioVoiceAppToken, useGetMyTwilioVoiceAppToken } from "@app/orval/api/twilio";
export const DEFAULT_BIT_RATE = 32000;
/** Need to refetch token every hour */
const tokenRefetchInterval = 60 * 60 * 1000;
const useTwilioDevices = () => {
    var _a, _b, _c;
    const getMyTokensApi = useGetMyTwilioVoiceAppToken({ query: { refetchInterval: tokenRefetchInterval } });
    const myToken = (_b = (_a = getMyTokensApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [bitRate, setBitRate] = useState(DEFAULT_BIT_RATE);
    const session = useSession();
    const [twilioDevices, setTwilioDevices] = useState(null);
    const [calls, setCalls] = useState([]);
    /**
     * Updates the stateId simply for the purpose of re-rendering the component
     */
    const updateCallState = useCallback((sid) => {
        setCalls((prev) => prev.map((x) => (x.call.parameters["CallSid"] === sid ? { call: x.call, stateId: uuidv4() } : x)));
    }, []);
    const configureCall = useCallback((call) => {
        const sid = call.parameters["CallSid"];
        call.on("ringing", (e) => {
            console.log("ringing", { e });
            updateCallState(sid);
        });
        call.on("accept", (e) => {
            console.log("accepted", { e });
            updateCallState(sid);
        });
        call.on("error", (e) => {
            console.error("Twilio.Call error", { e });
            updateCallState(sid);
        });
        call.on("mute", (e) => {
            console.log("muted", { e });
            updateCallState(sid);
        });
        call.on("reject", (e) => {
            console.log("rejected", { e });
            updateCallState(sid);
        });
        call.on("connect", (e) => {
            console.log("connected", { e });
            updateCallState(sid);
        });
        call.on("disconnect", (e) => {
            console.log("disconnected", { e });
            updateCallState(sid);
        });
        call.on("cancel", (e) => {
            console.log("canceled", { e });
            updateCallState(sid);
        });
    }, [updateCallState]);
    const placeCall = useCallback((from, to) => __awaiter(void 0, void 0, void 0, function* () {
        const device = Object.values(twilioDevices)[0];
        const call = yield device.connect({ params: { To: to, _from_twilio_phone: from } });
        setCalls((prev) => [...prev, { call, stateId: uuidv4() }]);
        configureCall(call);
    }), [configureCall, twilioDevices]);
    useEffect(() => {
        localStorage.setItem("twilio_bit_rate", bitRate.toString());
        if (twilioDevices) {
            Object.values(twilioDevices).forEach((device) => {
                device.updateOptions({
                    maxAverageBitrate: bitRate,
                });
            });
        }
    }, [bitRate, twilioDevices]);
    useEffect(() => {
        var _a;
        if (myToken) {
            const devices = {};
            for (const token of [myToken]) {
                console.log({ token: token.token });
                const device = new Device(token.token, {
                    // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
                    // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
                    codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
                    logLevel: ((_a = session === null || session === void 0 ? void 0 : session.activeUser) === null || _a === void 0 ? void 0 : _a.isDeveloper) ? "DEBUG" : undefined,
                    maxAverageBitrate: DEFAULT_BIT_RATE,
                });
                device.on("registered", (device_) => {
                    console.log("Twilio.Device Registered!", device_);
                });
                device.on("incoming", (call) => {
                    console.log("Incoming call from", call);
                    setCalls((prev) => [...prev, { call, stateId: uuidv4() }]);
                    configureCall(call);
                });
                device.on("ready", (device_) => {
                    console.log("Twilio.Device Ready!", device_);
                });
                device.on("error", (error) => {
                    if (error.code === "AccessTokenExpired") {
                        getMyTwilioVoiceAppToken().then((tokenResponse) => {
                            device.updateToken(tokenResponse.data.token);
                        });
                    }
                    else {
                        console.error(`Twilio.Device Error: ${error.message}`);
                    }
                });
                device.on("connect", (call) => {
                    console.log("Successfully established call!", call);
                    // $("#modal-call-in-progress").modal("show");
                    setCalls((prev) => [...prev, { call, stateId: uuidv4() }]);
                    configureCall(call);
                });
                device.on("disconnect", (conn) => {
                    console.log("Call ended.", conn);
                    // $(".modal").modal("hide");
                });
                device.on("tokenWillExpire", () => {
                    console.log("Token expired. Refreshing...");
                    getMyTwilioVoiceAppToken().then((tokenResponse) => {
                        device.updateToken(tokenResponse.data.token);
                    });
                });
                device.register();
                devices[token.identity] = device;
            }
            setTwilioDevices(devices);
        }
        else {
            setTwilioDevices(null);
        }
    }, [configureCall, myToken, (_c = session === null || session === void 0 ? void 0 : session.activeUser) === null || _c === void 0 ? void 0 : _c.isDeveloper]);
    return {
        twilioDevices,
        calls,
        placeCall,
        bitRate,
        setBitRate,
    };
};
export default useTwilioDevices;
