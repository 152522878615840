import * as React from "react";
import { Add, Delete, Edit, Refresh, Search } from "@mui/icons-material";
import { Box, Grid, Hidden, IconButton, InputAdornment, TextField, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { CLASS_NAME_NO_FOCUS_RING, disableQuickFilterFn, renderDateTimeCell_Local } from "@app/common/grid/utils";
import Loading from "@app/common/Loading";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { deleteViewPurpose, useListViewPurposes } from "@app/orval/api/view-purposes";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const getCellClassName = () => {
    return CLASS_NAME_NO_FOCUS_RING;
};
const ViewPurposesTable = () => {
    var _a, _b;
    const [search, setSearch] = React.useState("");
    const gridApi = useGridApiRef();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const queryClient = useQueryClient();
    const listViewPurposesApi = useListViewPurposes(REFETCH_CACHE_PARAMS);
    const viewPurposes = (_b = (_a = listViewPurposesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const handleSearchChanged = React.useCallback((e) => {
        const searchStr = e.target.value;
        setSearch(searchStr);
        gridApi.current.setQuickFilterValues([searchStr]);
    }, [setSearch, gridApi]);
    const refresh = React.useCallback(() => {
        listViewPurposesApi.refetch();
    }, [listViewPurposesApi]);
    const handleDeleteViewPurpose = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listViewPurposesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((item) => item.uid !== uid) });
                });
                deleteViewPurpose(uid)
                    .then(() => {
                    console.log("deleted view purpose");
                })
                    .catch(errorDialog);
            }
        });
    }, [queryClient, listViewPurposesApi, errorDialog, confirm]);
    const loading = [
        {
            label: "Loading view purposes",
            queryResult: listViewPurposesApi,
        },
    ];
    const showInfiniteSpinner = viewPurposes == null;
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    const columns = [
        {
            field: "id",
            headerName: "",
            editable: false,
            sortable: false,
            width: 55,
            resizable: false,
            getApplyQuickFilterFn: disableQuickFilterFn,
            cellClassName: "cell-button",
            align: "center",
            renderCell: (params) => {
                const { value } = params;
                return (React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit view purpose...") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { component: RouterLink, to: `/view-purposes/${value}`, size: "small", style: { paddingTop: 0, paddingBottom: 0 }, disableRipple: true },
                                    React.createElement(Edit, { fontSize: "small" }))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Delete view purpose...") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: handleDeleteViewPurpose(value), size: "small", style: { paddingTop: 0, paddingBottom: 0 }, disableRipple: true },
                                    React.createElement(Delete, { fontSize: "small" })))))));
            },
        },
        {
            field: "name",
            headerName: "Name",
        },
        {
            field: "created_at",
            headerName: "Created",
            width: 200,
            type: "datetime",
            getApplyQuickFilterFn: disableQuickFilterFn,
            renderCell: renderDateTimeCell_Local,
        },
        {
            field: "updated_at",
            headerName: "Updated",
            width: 200,
            type: "datetime",
            getApplyQuickFilterFn: disableQuickFilterFn,
            renderCell: renderDateTimeCell_Local,
        },
    ].map((column) => (Object.assign(Object.assign({}, column), { filterable: false, disableColumnMenu: true, width: column.width || 150, resizable: column.resizable !== false, editable: false, type: (column.type || "string") })));
    const rows = viewPurposes.map((item) => (Object.assign(Object.assign({}, item), { id: item.uid })));
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 1 },
                React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { size: "small", style: { width: "300px" }, value: search, onChange: handleSearchChanged, slotProps: {
                                    input: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(Search, null))),
                                    },
                                } }))),
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Grid, { item: true, style: { flex: 1 } })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Refresh view purposes") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", onClick: refresh },
                                    React.createElement(Refresh, { fontSize: "small" }))))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add view purpose") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", component: RouterLink, to: "/view-purposes/new" },
                                    React.createElement(Add, { fontSize: "small" })))))))),
        React.createElement(Grid, { item: true },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, density: "compact", rowHeight: 30, columnHeaderHeight: 30, disableVirtualization: true, disableRowSelectionOnClick: true, hideFooter: true, getCellClassName: getCellClassName, pagination: false, rowCount: rows.length, apiRef: gridApi, initialState: {
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: [search],
                            },
                        },
                    } })))));
};
export default ViewPurposesTable;
