import * as React from "react";
import { CalendarMonth } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import AppConfig from "@app/util/AppConfig";
import { formatDateInTimezoneMemoized } from "@app/util/Utils";
import ConversationCancelMessageDialog from "./ConversationCancelMessageDialog";
const CancelMessageIconButton = (props) => {
    const { message, onMessageCancelled } = props;
    const openCancelMessageDialog = React.useCallback(() => {
        setIsCancelMessageDialogOpen(true);
    }, []);
    const closeCancelMessageDialog = React.useCallback(() => {
        setIsCancelMessageDialogOpen(false);
    }, []);
    const handleMessageCancelled = React.useCallback(() => {
        closeCancelMessageDialog();
        onMessageCancelled();
    }, [closeCancelMessageDialog, onMessageCancelled]);
    const [isCancelMessageDialogOpen, setIsCancelMessageDialogOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: message.send_at ? (React.createElement(Typography, null,
                "Scheduled at",
                " ",
                formatDateInTimezoneMemoized(new Date(message.send_at), {
                    format: "dddd, MMMM D, YYYY h:mm A",
                    timezone: AppConfig.timezone,
                }),
                " ",
                "Pacific time")) : (React.createElement(Typography, null, "Scheduled")) },
            React.createElement("span", null,
                React.createElement(IconButton, { onClick: openCancelMessageDialog, size: "small" },
                    React.createElement(CalendarMonth, { fontSize: "small" })))),
        React.createElement(ConversationCancelMessageDialog, { DialogProps: { open: isCancelMessageDialogOpen }, onCancel: closeCancelMessageDialog, onConfirm: handleMessageCancelled })));
};
export default CancelMessageIconButton;
