export const getTemplateType = (template) => {
    if (template.email_template) {
        return "Email";
    }
    if (template.email_draft_template) {
        return "Email Draft";
    }
    if (template.sms_template) {
        return "SMS";
    }
    if (template.sms_draft_template) {
        return "SMS Draft";
    }
    if (template.calendar_template) {
        return "Calendar";
    }
    if (template.slack_template) {
        return "Slack";
    }
    if (template.crm_notification_template) {
        return "CRM Notification";
    }
    console.error("Invalid template type for template:", template);
    throw Error("Invalid template type");
};
export const templateTypeHelperText = (templateType) => {
    switch (templateType) {
        case "Email":
            return "Emails are sent automatically from Gmail";
        case "Email Draft":
            return "Drafts are created in a user's Drafts folder rather than sent automatically.";
        case "SMS":
            return "SMS templates are sent as text messages via Twilio";
        case "SMS Draft":
            return "SMS Draft templates are created as drafts in a user's inbox; when sent, they'll be sent to the recipient phone numbers via Twilio.";
        case "Calendar":
            return "Calendar templates are created as events in Google Calendar.";
        case "Slack":
            return "These templates are posted in the desired Slack channel.";
        default:
            return null;
    }
};
export const getMailScheduleFieldType = (field) => {
    var _a;
    if (field.field_type === "formula") {
        if (!((_a = field.format) === null || _a === void 0 ? void 0 : _a.trim())) {
            return "text";
        }
        if (field.format.includes("0")) {
            return "number";
        }
        return "date";
    }
    return field.field_type;
};
export const getTimeFieldsInPipeline = (field) => {
    return field
        .filter((f) => f.field_type === "text" && f.name.toLowerCase().includes("time"))
        .sort((a, b) => {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    }); // TODO: use actual time fields
};
export const getDateFieldsInPipeline = (field) => {
    return field
        .filter((f) => getMailScheduleFieldType(f) === "date")
        .sort((a, b) => {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    });
};
/**
 * Includes choices fields as well as text fields and text-like formula fields
 * @param field
 * @returns
 */
export const getTextFieldsInPipeline = (field) => {
    return field
        .filter((f) => ["text", "choice", "dropdown"].includes(getMailScheduleFieldType(f)))
        .sort((a, b) => {
        if (a.name < b.name)
            return -1;
        if (a.name > b.name)
            return 1;
        return 0;
    });
};
export const getBlobComponentChildren = (blobNames, blobComponent) => {
    const children = [];
    blobNames.forEach((name) => {
        // split name into folder/file names
        const nameSplit = name.split("/");
        // get index of target component
        const componentIndex = blobComponent ? nameSplit.indexOf(blobComponent) : 0;
        if (componentIndex !== -1 && componentIndex < nameSplit.length) {
            // if component is present and not the last component
            const child = blobComponent ? nameSplit[componentIndex + 1] : nameSplit[0];
            if (!children.includes(child)) {
                children.push(child);
            }
        }
    });
    return children;
};
/**
 *
 * @param agent
 * @returns Agent's full name (FirstName LastName)
 */
export const getAgentName = (agent) => {
    if (agent == null) {
        return "";
    }
    const fullName = `${agent.first_name} ${agent.last_name}`;
    return fullName;
};
/**
 * Mention fields for use in ReactQuill and react-mentions inputs
 * for DASH agents
 *
 * @param entityType The entity type in question
 * @returns
 */
export const getAgentMentionsFields = (entityType, params) => {
    var _a;
    const fieldNames = ["Name", "First Name", "Last Name", "Email", "Phone", "Company", "Address", "Office"];
    for (const field of (_a = params === null || params === void 0 ? void 0 : params.fields) !== null && _a !== void 0 ? _a : []) {
        if (!fieldNames.includes(field.name)) {
            fieldNames.push(field.name);
        }
    }
    if (entityType === "Buyer Lead" || entityType === "Buyer Contract") {
        return ["Producer", "Co-Agent"]
            .map((x) => fieldNames.map((y) => ({
            id: `${x} ${y}`,
            display: `${x} ${y}`,
        })))
            .flat();
    }
    if (entityType === "Seller Lead" ||
        entityType === "Listing" ||
        // @ts-expect-error
        entityType === "Seller Contract") {
        return ["Producer", "Co-Agent", "Listing Coordinator"]
            .map((x) => fieldNames.map((y) => ({
            id: `${x} ${y}`,
            display: `${x} ${y}`,
        })))
            .flat();
    }
    return [];
};
export const getVendorMentionsFields = (entityType) => {
    const attrs = ["First Name", "Last Name", "Email", "Phone", "Company", "Address"];
    if (entityType === "Buyer Lead") {
        return ["Lender"]
            .map((x) => attrs.map((y) => ({
            id: `${x} ${y}`,
            display: `${x} ${y}`,
        })))
            .flat();
    }
    if (entityType === "Buyer Contract") {
        return ["Buyer Closing Attorney", "Seller Closing Attorney", "Lender", "Paralegal", "Inspector", "Listing Agent"]
            .map((x) => attrs.map((y) => ({
            id: `${x} ${y}`,
            display: `${x} ${y}`,
        })))
            .flat();
    }
    if (entityType === "Seller Lead" ||
        entityType === "Listing" ||
        // @ts-expect-error
        entityType === "Seller Contract") {
        return [
            "Buyer Closing Attorney",
            "Seller Closing Attorney",
            "Lender",
            "Paralegal",
            "Inspector",
            "Listing Agent",
            "Measurement Company",
            "Photo Company",
        ]
            .map((x) => attrs.map((y) => ({
            id: `${x} ${y}`,
            display: `${x} ${y}`,
        })))
            .flat();
    }
    return [];
};
export const getClientMentionsFields = (entityType) => {
    if (entityType === "Buyer Lead" ||
        entityType === "Buyer Contract" ||
        entityType === "Seller Lead" ||
        entityType === "Listing" ||
        // @ts-expect-error
        entityType === "Seller Contract") {
        const attrs = ["First", "Last", "Email", "Phone", "Company", "Address", "Marital Status"];
        const res = [];
        for (let i = 1; i <= 2; ++i) {
            for (const attr of attrs) {
                res.push({
                    id: `Client #${i} ${attr}`,
                    display: `Client #${i} ${attr}`,
                });
            }
        }
        return res;
    }
    return [];
};
