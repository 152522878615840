import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as LinkIcon, Money } from "@mui/icons-material";
import { Box, Button, Grid2, IconButton, Tabs, Tab, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import numeral from "numeral";
import bridgeIcon from "@app/assets/img/bridge.png";
import dotloopIcon from "@app/assets/img/dotloop.png";
import paragonIcon from "@app/assets/img/paragon.jpeg";
import streakIcon from "@app/assets/img/streak.png";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreError from "@app/common/CoreError";
import CoreLink from "@app/common/CoreLink";
import CoreLoading from "@app/common/CoreLoading";
import { withDialog } from "@app/hoc/withDialog";
import { withRouter } from "@app/hoc/withRouter";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import AppManager from "@app/util/AppManager";
import { copyToClipboard } from "@app/util/Utils";
import QuestionsForTheOfferCashToClose from "./QuestionsForTheOfferCashToClose";
const UNUSED_FIELD_NAMES = [
    "Do you have a preferred closing attorney in mind? If not, we frequently work with Midtown Law and highly recommend their services!",
];
const tabs = [
    {
        name: "cash-to-close",
        label: "Cash to Close",
        icon: React.createElement(Money, null),
        isVisible: true,
    },
];
const TabLabel = (props) => (React.createElement(Grid2, { container: true, spacing: 1, justifyContent: "center", style: { marginTop: "auto", marginBottom: "auto" } },
    props.icon && React.createElement(Grid2, null, props.icon),
    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, props.label)));
const getTheme = (parentTheme) => createTheme(Object.assign(Object.assign({}, parentTheme), { components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    [parentTheme.breakpoints.up("xs")]: {
                        minWidth: "72px",
                    },
                    [parentTheme.breakpoints.up("sm")]: {
                        minWidth: "160px",
                    },
                },
            },
        },
    } }));
class QuestionsForTheOfferSubmission extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                tab: "cash-to-close",
                submission: null,
                fields: null,
                error: null,
                isUpdatingDotloop: false,
                isImportingData: false,
            }
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "eventTarget", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: document.querySelector("body")
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { uid } = this.props.params;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                this.setState({
                    submission: null,
                    fields: null,
                    error: null,
                });
                API.fields.list({
                    query: {
                        entity_type: "Pre-Offer",
                    },
                    onSuccess: (result) => {
                        this.setState({
                            fields: result,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                if (uid) {
                    API.questionsForTheOfferSubmissions.get(uid, {
                        onSuccess: (result) => {
                            this.setState({
                                submission: result,
                            });
                        },
                        onError: (error) => {
                            this.setState({
                                error,
                            });
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "addPasteEventListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                /* @ts-expect-error No overload matches this call */
                (_a = this.eventTarget) === null || _a === void 0 ? void 0 : _a.removeEventListener("paste", this.importOTPData);
                /* @ts-expect-error No overload matches this call */
                (_b = this.eventTarget) === null || _b === void 0 ? void 0 : _b.addEventListener("paste", this.importOTPData);
                enqueueSnackbar("Press Ctrl+V on Windows or Cmd+V on Mac to paste data.", { variant: "info" });
            }
        });
        Object.defineProperty(this, "importOTPData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (event) => {
                var _a;
                const { uid } = this.props.params;
                event.preventDefault();
                const text = event.clipboardData.getData("Text");
                let json = null;
                try {
                    json = JSON.parse(text);
                }
                catch (err) {
                    console.error(err);
                    enqueueSnackbar("Text is not valid JSON. Use the Dash Chrome Extension to copy data from the Triangle MLS site.", { variant: "error" });
                    return;
                }
                for (const key of Object.keys(json)) {
                    if (!this.schema[key]) {
                        delete json[key];
                    }
                }
                if (uid) {
                    this.setState((prevState) => ({
                        submission: Object.assign(Object.assign({}, prevState.submission), { fields: Object.assign(Object.assign({}, prevState.submission.fields), json) }),
                    }), () => {
                        API.questionsForTheOfferSubmissions.update(uid, {
                            data: {
                                fields: Object.keys(this.state.submission.fields)
                                    .filter((fieldName) => !fieldName.includes("Disclaimer") && json[fieldName] !== undefined)
                                    .reduce((dict, key) => (Object.assign(Object.assign({}, dict), { [key]: this.state.submission.fields[key] !== ""
                                        ? this.state.submission.fields[key]
                                        : this.schema[key] &&
                                            ["number", "date", "datetime", "formula"].includes(this.schema[key].field_type)
                                            ? null
                                            : this.state.submission.fields[key] })), {}),
                            },
                            onSuccess: () => {
                                enqueueSnackbar(`Updated ${Object.keys(json).length} fields.`, { variant: "success" });
                            },
                            onError: (error) => {
                                console.log(error);
                                this.props.showErrorDialog(error);
                            },
                        });
                    });
                }
                /* @ts-expect-error No overload matches this call */
                (_a = this.eventTarget) === null || _a === void 0 ? void 0 : _a.removeEventListener("paste", this.importOTPData);
            }
        });
        Object.defineProperty(this, "setFieldValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (fieldName) => (value) => {
                this.setState((prevState) => ({
                    submission: Object.assign(Object.assign({}, prevState.submission), { fields: Object.assign(Object.assign({}, prevState.submission.fields), { [fieldName]: value }) }),
                }));
            }
        });
        Object.defineProperty(this, "importDataFromBridge", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { uid } = this.props.params;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                if (uid) {
                    this.setState({
                        isImportingData: true,
                    });
                    API.questionsForTheOfferSubmissions.populateBridgeData(uid, {
                        onSuccess: (result) => {
                            this.setState({
                                isImportingData: false,
                                submission: result,
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    isImportingData: false,
                                });
                                this.props.showErrorDialog(error);
                            }
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "updateDotloop", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const submission = this.state.submission;
                //
                // format data for /detail endpoint
                //
                const detail = {};
                detail["Property Address"] = {};
                detail["Financials"] = {};
                detail["Contract Dates"] = {};
                detail["Offer Dates"] = {};
                detail["Contract Info"] = {};
                detail["Referral"] = {};
                detail["Listing Information"] = {};
                detail["Geographic Information"] = {};
                detail["Property"] = {};
                if (submission.fields["City"]) {
                    detail["Property Address"]["City"] = submission.fields["City"];
                }
                if (submission.fields["State"]) {
                    detail["Property Address"]["State/Prov"] = submission.fields["State"];
                }
                if (submission.fields["County"]) {
                    detail["Property Address"]["County"] = submission.fields["County"];
                }
                if (submission.fields["Zip"]) {
                    detail["Property Address"]["Zip/Postal Code"] = submission.fields["Zip"];
                }
                if (submission.fields["HOA 1 Fees $"]) {
                    detail["Listing Information"]["Homeowner's Association Dues"] = numeral(submission.fields["HOA 1 Fees $"]).format("$0,0");
                }
                if (submission.fields["HOA 1 Mgmt"]) {
                    detail["Listing Information"]["Homeowner's Association"] = submission.fields["HOA 1 Mgmt"];
                }
                if (submission.fields["Offer Amount"]) {
                    detail["Financials"]["Purchase/Sale Price"] = numeral(submission.fields["Offer Amount"]).format("$0,0");
                }
                if (submission.fields["Earnest Money Deposit"]) {
                    detail["Financials"]["Earnest Money Amount"] = numeral(submission.fields["Earnest Money Deposit"]).format("$0,0");
                }
                if (submission.fields["Settlement Date"]) {
                    detail["Contract Dates"]["Contract Agreement Date"] = submission.fields["Settlement Date"];
                }
                for (const key of Object.keys(detail)) {
                    // eslint-disable-next-line
                    if (Object.keys(detail[key]) && Object.keys(detail[key]).length === 0) {
                        delete detail[key];
                    }
                }
                //
                // format data for /participant endpoint
                //
                const participants = [];
                if (submission.fields["Full Legal Names of ALL Borrowers"] &&
                    submission.fields["Full Legal Names of ALL Borrowers"].trim().split(" ").length === 2 &&
                    ((_a = submission.fields["Buyer #1 Email Address"]) === null || _a === void 0 ? void 0 : _a.match(AppConfig.validators.email))) {
                    participants.push({
                        role: "BUYER",
                        fullName: submission.fields["Full Legal Names of ALL Borrowers"].trim(),
                        email: submission.fields["Buyer #1 Email Address"],
                    });
                }
                this.setState({
                    isUpdatingDotloop: true,
                });
                API.dotloop.updateLoop(submission.fields["Dotloop Profile Email"], submission.fields["Dotloop Loop ID"], {
                    data: {
                        detail,
                        participants,
                    },
                    onSuccess: () => {
                        enqueueSnackbar("Loop updated.", { variant: "success" });
                        this.setState({
                            isUpdatingDotloop: false,
                        });
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                        this.setState({
                            isUpdatingDotloop: false,
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "copyQFILink", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                copyToClipboard(`${window.location.host}/forms/qfi/form/${(_a = this.props.params.uid) !== null && _a !== void 0 ? _a : ""}`);
                const closeSnackbar = (key) => () => {
                    closeSnackbar(key);
                };
                const action = (key) => React.createElement(Button, { onClick: closeSnackbar(key) }, "Dismiss");
                enqueueSnackbar("Copied QFI link to clipboard.", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    // @ts-expect-error ype '(key: string) => React.JSX.Element' is not assignable to type 'SnackbarAction'.
                    action,
                });
            }
        });
        Object.defineProperty(this, "getLinkForTab", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (tabName) => {
                let { pathname } = this.props.location;
                for (const tab of tabs) {
                    pathname = pathname.replace(`/${tab.name}`, "");
                }
                pathname += `/${tabName}`;
                return pathname;
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a;
        return {
            tab: (_a = nextProps.params.tab) !== null && _a !== void 0 ? _a : "cash-to-close",
        };
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "QFO Submission" };
        this.refresh();
    }
    componentWillUnmount() {
        var _a;
        AppManager.shared.pageTitle = {};
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    get schema() {
        var _a;
        const { fields } = this.state;
        return ((_a = fields === null || fields === void 0 ? void 0 : fields.reduce((dict, field) => (Object.assign(Object.assign({}, dict), { [field.name]: field })), {})) !== null && _a !== void 0 ? _a : null);
    }
    render() {
        const { uid } = this.props.params;
        const { error, fields, isImportingData, isUpdatingDotloop, submission, tab } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!submission || !fields || isImportingData) {
            return React.createElement(CoreLoading, null);
        }
        return (React.createElement(ThemeProvider, { theme: getTheme },
            React.createElement(Grid2, { container: true, size: 12, style: { height: "100%", overflow: "hidden" } },
                React.createElement(Grid2, { size: 6, style: { height: "100%" } },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Tabs, { value: tabs
                                .filter((tab_) => tab_.isVisible)
                                .map((tab_) => tab_.name)
                                .indexOf(tab), indicatorColor: "primary", textColor: "primary" }, tabs
                            .filter((tab_) => tab_.isVisible)
                            .map((tab_) => (React.createElement(Tab, { key: tab_.name, component: RouterLink, to: this.getLinkForTab(tab_.name), label: React.createElement(TabLabel, { icon: tab_.icon, label: tab_.label }) }))))),
                    React.createElement(Grid2, { size: 12, style: {
                            height: "calc(100% - 80px)",
                            width: "calc(100% - 1rem)",
                            overflowY: "auto",
                            overflowX: "hidden",
                        } }, tab === "cash-to-close" && uid && (React.createElement(Box, { p: 2 },
                        React.createElement(QuestionsForTheOfferCashToClose, { uid: uid }))))),
                React.createElement(Grid2, { size: 6, style: { height: "100%" } },
                    React.createElement(Grid2, { container: true, spacing: 2, style: { margin: 0, height: "100%" } },
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(Grid2, { container: true, spacing: 1, justifyContent: "flex-end", style: { width: "calc(100% - 1rem)", margin: 0 } },
                                React.createElement(Grid2, null,
                                    React.createElement(Tooltip, { title: "Open Streak Box" },
                                        React.createElement("span", null,
                                            React.createElement(IconButton, { component: CoreLink, href: `https://streak.com/a/boxes/${submission.box_key}`, target: "_blank" },
                                                React.createElement("img", { src: streakIcon, alt: "Streak", height: 20 }))))),
                                React.createElement(Grid2, null,
                                    React.createElement(Tooltip, { title: "Copy QFI Link" },
                                        React.createElement("span", null,
                                            React.createElement(IconButton, { onClick: this.copyQFILink },
                                                React.createElement(LinkIcon, null))))),
                                React.createElement(Grid2, null,
                                    React.createElement(Tooltip, { title: "Update Dotloop" },
                                        React.createElement("span", null,
                                            React.createElement(IconButton, { onClick: this.updateDotloop, disabled: isUpdatingDotloop },
                                                React.createElement("img", { src: dotloopIcon, alt: "Dotloop", height: 20 }))))),
                                React.createElement(Grid2, null,
                                    React.createElement(Tooltip, { title: "Import Data from MLS" },
                                        React.createElement(IconButton, { onClick: this.addPasteEventListener },
                                            React.createElement("img", { src: paragonIcon, alt: "Paragon", height: 20 })))),
                                React.createElement(Grid2, null,
                                    React.createElement(Tooltip, { title: "Import Data via Bridge API" },
                                        React.createElement(IconButton, { onClick: this.importDataFromBridge },
                                            React.createElement("img", { src: bridgeIcon, alt: "Bridge", height: 20 })))))),
                        React.createElement(Grid2, { size: 12, style: { height: "calc(100% - 80px)", overflowY: "auto" } },
                            React.createElement(Box, { p: 4, style: {
                                    border: "1px solid #eee",
                                    borderRadius: "0.25rem",
                                } },
                                React.createElement(Grid2, { container: true, spacing: 2 }, fields
                                    .filter((field) => {
                                    // no longer using this field
                                    return !UNUSED_FIELD_NAMES.includes(field.name);
                                })
                                    .map((field) => {
                                    var _a;
                                    let label = null;
                                    if (field.name === "Closing Attorney Preference") {
                                        label =
                                            "Have you chosen a closing attorney? If not, we recommend Midtown Law, a firm we frequently work with and trust. Please indicate 'Yes' if you have a preference, or 'No' if you'd like us to proceed with Midtown Law.";
                                    }
                                    return (React.createElement(Grid2, { key: field.name, size: { xs: 12, lg: 6 } },
                                        React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, label: label !== null && label !== void 0 ? label : field.name, field: field, value: (_a = submission.fields[field.name]) !== null && _a !== void 0 ? _a : null, apiEndpoint: `/api/forms/questions_for_the_offer/${uid}`, onSaveBegin: this.setFieldValue(field.name) })));
                                })))))))));
    }
}
export default withRouter(withDialog(QuestionsForTheOfferSubmission));
