var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Launch } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, Typography } from "@mui/material";
import axios from "axios";
import pickBy from "lodash/pickBy";
import useInfiniteScroll from "react-infinite-scroll-hook";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { searchPhoneContacts } from "@app/orval/api/phone-contacts";
import { listCachedTwilioMessagingLogs } from "@app/orval/api/twilio";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { useGetMyTwilioNumber } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { formatPhoneNumber } from "@app/util/Utils";
import ConversationSelect from "./components/ConversationSelect";
import SelectPhoneNumbersAlert from "./components/SelectPhoneNumbersAlert";
import ThreadList from "./components/ThreadList";
import ThreadSearch from "./components/ThreadSearch";
import ThreadSkeleton from "./components/ThreadSkeleton";
import TwilioSMSConversation from "./conversation/TwilioSMSConversation";
/**
 * This component wraps the `SMSListComponent` sidebar and the main `SMSConversationComponent` content view.
 */
const SMSModule = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { selectedExternalPhoneNumber, selectedTwilioPhoneNumber, showConversation = false } = props;
    const size = useSize();
    const updateSearch = useUpdateSearch();
    const session = useSession();
    const [searchTwilioPhoneNumber, setSearchTwilioPhoneNumber] = React.useState(null);
    const [searchExternalPhoneNumber, setSearchExternalPhoneNumber] = React.useState(null);
    const selectedTwilioPhoneNumber__E164 = React.useMemo(() => {
        if (selectedTwilioPhoneNumber) {
            return formatPhoneNumber(selectedTwilioPhoneNumber, { format: "international" });
        }
        return null;
    }, [selectedTwilioPhoneNumber]);
    const selectedExternalPhoneNumber__E164 = React.useMemo(() => {
        if (selectedExternalPhoneNumber) {
            return formatPhoneNumber(selectedExternalPhoneNumber, { format: "international" });
        }
        return null;
    }, [selectedExternalPhoneNumber]);
    const getMyTwilioNumberApi = useGetMyTwilioNumber(INFINITE_CACHE_PARAMS);
    const userPhoneNumber = (_b = (_a = getMyTwilioNumberApi.data) === null || _a === void 0 ? void 0 : _a.data.twilio_phone_number) !== null && _b !== void 0 ? _b : null;
    const isAdmin = (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin", "Admin"]);
    const [threadTopMessages, setThreadTopMessages] = React.useState(null);
    const [hasNextPage, setHasNextPage] = React.useState(true);
    const [isLoadingThreads, setIsLoadingThreads] = React.useState(false);
    const [contactPhoneNumbers, setContactPhoneNumbers] = React.useState(null);
    const cancelToken = React.useRef(null);
    const fetchMessages = React.useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        cancelToken.current = axios.CancelToken.source();
        const { before, externalPhoneNumber, twilioPhoneNumber } = params;
        const limit = 100;
        const query = pickBy({
            most_recent_only: true,
            exclude_statuses: "canceled",
            participants_exact_match: twilioPhoneNumber || undefined,
            participants_partial_match: externalPhoneNumber || undefined,
            limit,
            date_sent_before: before || undefined,
        }, (val) => val != null);
        setIsLoadingThreads(true);
        listCachedTwilioMessagingLogs(query, { cancelToken: cancelToken.current.token })
            .then((result) => {
            var _a;
            const logs = result.data;
            setThreadTopMessages((prev) => {
                if (prev == null) {
                    return logs;
                }
                return [...prev, ...logs];
            });
            setHasNextPage(result.data.length >= limit);
            setIsLoadingThreads(false);
            if (logs.length > 0) {
                searchPhoneContacts({
                    phone_numbers: logs.map((log) => [log.from_, log.to]).flat(),
                }, { cancelToken: (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.token })
                    .then((phoneNumbersResult) => {
                    setContactPhoneNumbers((prev) => [...(prev !== null && prev !== void 0 ? prev : []), ...phoneNumbersResult.data]);
                })
                    .catch((phoneContactsError) => {
                    if (!phoneContactsError.isCancel) {
                        console.error(phoneContactsError);
                    }
                });
            }
        })
            .catch((error) => {
            // if this is a cancellation error, ignore
            if (!error.isCancel) {
                console.error(error);
            }
            setIsLoadingThreads(false);
        });
    }), []);
    React.useEffect(() => {
        setThreadTopMessages(null);
        setHasNextPage(true);
        setContactPhoneNumbers(null);
        fetchMessages({ twilioPhoneNumber: searchTwilioPhoneNumber, externalPhoneNumber: searchExternalPhoneNumber });
    }, [searchTwilioPhoneNumber, searchExternalPhoneNumber, fetchMessages]);
    const fetchNextPage = React.useCallback(() => {
        if (threadTopMessages == null) {
            return;
        }
        const lastMessage = threadTopMessages[threadTopMessages.length - 1];
        fetchMessages({
            twilioPhoneNumber: searchTwilioPhoneNumber,
            externalPhoneNumber: searchExternalPhoneNumber,
            before: lastMessage.date_created,
        });
    }, [searchTwilioPhoneNumber, searchExternalPhoneNumber, threadTopMessages, fetchMessages]);
    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: isLoadingThreads,
        hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: !threadTopMessages,
        // rootMargin: "0px 0px 400px 0px",
    });
    const listPhoneNumbersApi = useListCachedTwilioPhones({ access_only: true }, INFINITE_CACHE_PARAMS);
    const phoneNumbers = (_e = (_d = listPhoneNumbersApi.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : null;
    const contactsByPhoneNumber = React.useMemo(() => {
        if (contactPhoneNumbers == null) {
            return null;
        }
        const res = {};
        for (const contact of contactPhoneNumbers) {
            if (contact.phone_number) {
                res[contact.phone_number] = contact;
            }
        }
        return res;
    }, [contactPhoneNumbers]);
    const threads = React.useMemo(() => {
        if (threadTopMessages == null) {
            return null;
        }
        console.log("contactsByPhoneNumber", contactsByPhoneNumber);
        const res = threadTopMessages.map((thread) => (Object.assign(Object.assign({}, thread), { fromContact: contactsByPhoneNumber === null || contactsByPhoneNumber === void 0 ? void 0 : contactsByPhoneNumber[thread.from_.replace("+1", "")], toContact: contactsByPhoneNumber === null || contactsByPhoneNumber === void 0 ? void 0 : contactsByPhoneNumber[thread.to.replace("+1", "")] })));
        console.log("SATURATED", res);
        return res;
    }, [contactsByPhoneNumber, threadTopMessages]);
    const onSearch = React.useCallback((twilioPhoneNumber, externalPhoneNumber) => {
        console.log({ twilioPhoneNumber, externalPhoneNumber });
        setSearchTwilioPhoneNumber(twilioPhoneNumber);
        setSearchExternalPhoneNumber(externalPhoneNumber);
    }, []);
    const onMessageSent = React.useCallback((message) => {
        const participants = [selectedTwilioPhoneNumber, selectedExternalPhoneNumber].filter((o) => o);
        setThreadTopMessages((prev) => {
            if (prev == null) {
                return [message];
            }
            return [message, ...prev.filter((m) => !(participants.includes(m.to) && participants.includes(m.from_)))];
        });
    }, [selectedExternalPhoneNumber, selectedTwilioPhoneNumber]);
    const onConversationSelected = React.useCallback((twilioPhoneNumber, externalPhoneNumber) => {
        updateSearch("twilio_phone", twilioPhoneNumber, "external_phone", externalPhoneNumber);
    }, [updateSearch]);
    const onConversationClosed = React.useCallback(() => {
        updateSearch("show_conversation", null, "twilio_phone", null, "external_phone", null);
    }, [updateSearch]);
    if (!session) {
        return null;
    }
    if (!isAdmin && !((_f = getMyTwilioNumberApi.data) === null || _f === void 0 ? void 0 : _f.data)) {
        return null;
    }
    if (!isAdmin && !userPhoneNumber) {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Typography, null, "You must have a Twilio phone number in order to use this app")));
    }
    if (listPhoneNumbersApi.error && !phoneNumbers) {
        return React.createElement(CoreError, { error: listPhoneNumbersApi.error });
    }
    return (React.createElement(Grid2, { container: true, style: { height: "100%", overflow: "hidden" } },
        (size.isUp("md") || (!showConversation && !(selectedTwilioPhoneNumber && selectedExternalPhoneNumber))) && (React.createElement(Grid2, { style: { width: "400px", maxWidth: "100%", height: "100%", borderRight: "1px solid #ccc" } },
            React.createElement(Grid2, { container: true, spacing: 1, direction: "column", style: { height: "100%" }, wrap: "nowrap" },
                React.createElement(Grid2, { style: { width: "100%" } },
                    React.createElement(Grid2, { container: true, style: { width: "100%" }, wrap: "nowrap" },
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(Box, { p: 2 },
                                React.createElement(ThreadSearch, { twilioPhoneNumbers: phoneNumbers, selectedTwilioPhoneNumber: searchTwilioPhoneNumber, selectedExternalPhoneNumber: searchExternalPhoneNumber, onSearch: onSearch }))),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(IconButton, { component: RouterLink, to: "/sms?show_conversation=true", tabIndex: -1 },
                                React.createElement(Launch, null))))),
                React.createElement(Grid2, null,
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { ref: rootRef, style: { flex: 1, overflowY: "scroll", width: "100%" } }, threads ? (React.createElement(React.Fragment, null,
                    React.createElement(ThreadList, { threads: threads, selectedThread: selectedTwilioPhoneNumber && selectedExternalPhoneNumber
                            ? [selectedTwilioPhoneNumber, selectedExternalPhoneNumber]
                            : null, showTwilioPhone: !searchTwilioPhoneNumber, twilioPhoneNumbers: phoneNumbers }),
                    hasNextPage && (React.createElement("div", { ref: sentryRef },
                        React.createElement(CoreLoading, null))))) : (React.createElement(ThreadSkeleton, null)))))),
        (size.isUp("md") || showConversation || (selectedTwilioPhoneNumber && selectedExternalPhoneNumber)) && (React.createElement(Grid2, { style: { flex: 1, height: "100%" } },
            React.createElement(Grid2, { container: true, spacing: 1, direction: "column", style: { height: "100%" }, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Box, { p: 2 },
                        React.createElement(ConversationSelect, { twilioPhoneNumbers: phoneNumbers, selectedTwilioPhoneNumber: selectedTwilioPhoneNumber !== null && selectedTwilioPhoneNumber !== void 0 ? selectedTwilioPhoneNumber : null, selectedExternalPhoneNumber: selectedExternalPhoneNumber !== null && selectedExternalPhoneNumber !== void 0 ? selectedExternalPhoneNumber : null, onSelect: onConversationSelected, onClose: onConversationClosed }))),
                React.createElement(Grid2, null,
                    React.createElement(Divider, null)),
                React.createElement(Grid2, { style: { flex: 1, overflowY: "hidden" } },
                    (!selectedTwilioPhoneNumber__E164 || !selectedExternalPhoneNumber__E164) && React.createElement(SelectPhoneNumbersAlert, null),
                    selectedTwilioPhoneNumber__E164 && selectedExternalPhoneNumber__E164 && (React.createElement(TwilioSMSConversation, { fromPhoneNumber: selectedTwilioPhoneNumber__E164, toPhoneNumber: selectedExternalPhoneNumber__E164, onMessageSent: onMessageSent }))))))));
};
export default SMSModule;
