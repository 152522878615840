import * as React from "react";
import { Alert, Typography } from "@mui/material";
import googleGroupsIcon from "@app/assets/img/google-groups.png";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const GoogleGroupsMissingEmail = () => {
    return (React.createElement(CollapsibleSection, { icon: React.createElement("img", { src: googleGroupsIcon, height: 20, alt: "Google Groups" }), title: "Google Groups", isCollapsible: false },
        React.createElement(Alert, { severity: "warning" },
            React.createElement(Typography, null, "This user is missing an email address"))));
};
export default GoogleGroupsMissingEmail;
