export const sortResultsFn = (a, b) => {
    const attrHeirarchy = [
        "page",
        "user",
        "contact",
        "buyerLead",
        "sellerLead",
        "buyerContract",
        "sellerContract",
        "psp",
        "qfo",
    ];
    const aIndx = attrHeirarchy.findIndex((x) => a[x]);
    const bIndx = attrHeirarchy.findIndex((x) => b[x]);
    if (aIndx < bIndx)
        return -1;
    if (aIndx > bIndx)
        return 1;
    return 0;
};
