import * as React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
const TestModeFilter = (props) => {
    var _a, _b, _c;
    const { TextFieldProps, onChange, value: testMode } = props;
    const handleTestModeChanged = React.useCallback((event) => {
        var _a;
        onChange((_a = {
            "1": true,
            "0": false,
        }[event.target.value]) !== null && _a !== void 0 ? _a : null);
    }, [onChange]);
    const value = testMode === true ? "1" : testMode === false ? "0" : "";
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true }, TextFieldProps, { select: true, value: value, onChange: handleTestModeChanged, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Debug") }, (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _a === void 0 ? void 0 : _a.input), inputLabel: Object.assign({ shrink: true }, (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.inputLabel), select: Object.assign({ displayEmpty: true }, (_c = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.select) }) }),
        React.createElement(MenuItem, { value: "" }, "Any"),
        React.createElement(MenuItem, { value: "1" }, "Test Executions Only"),
        React.createElement(MenuItem, { value: "0" }, "Live Executions Only")));
};
export default TestModeFilter;
