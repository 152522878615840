import * as React from "react";
import { Chip, createTheme, IconButton, ThemeProvider, useTheme } from "@mui/material";
import useSize from "@app/hooks/useSize";
const ResponsiveFilterChip = (props) => {
    const { isActive } = props;
    const size = useSize();
    const parentTheme = useTheme();
    const theme = React.useMemo(() => createTheme(Object.assign(Object.assign({}, parentTheme), { palette: {
            primary: {
                main: "#daedf5",
            },
        } })), [parentTheme]);
    return (React.createElement(ThemeProvider, { theme: theme }, size.isUp("xl") ? (React.createElement(Chip, Object.assign({ color: isActive ? "primary" : undefined }, props, { tabIndex: -1 }))) : (
    /* @ts-expect-error */
    React.createElement(IconButton, { onClick: props.onClick, size: props.size, tabIndex: -1, color: isActive ? "primary" : undefined }, props.icon))));
};
export default ResponsiveFilterChip;
