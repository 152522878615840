import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import FollowUpBossRedirect from "@app/entrypoints/brokerage/pages/crm/redirects/FollowUpBossRedirect";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { variant } from "@app/util/Utils";
import SellerLeadsForm from "./SellerLeadsForm";
const SellerLeadsFormPage = () => {
    var _a;
    useDocumentTitle("Sellers");
    const params = useParams();
    const searchParams = useSearchParams();
    const tab = (_a = searchParams[0].get("tab")) !== null && _a !== void 0 ? _a : undefined;
    if (variant === "followupboss") {
        return React.createElement(FollowUpBossRedirect, { boxUid: params.uid });
    }
    return React.createElement(SellerLeadsForm, { tab: tab, uid: params.uid });
};
export default SellerLeadsFormPage;
