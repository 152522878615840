import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import ShadowingTypeForm from "../../features/admin/onboarding/ShadowingTypeForm";
const ShadowingTypeFormPage = () => {
    useDocumentTitle("Onboarding | Shadowing Types");
    const params = useParams();
    return React.createElement(ShadowingTypeForm, { uid: params.uid });
};
export default ShadowingTypeFormPage;
