import * as React from "react";
import { Grid2, Table, TableRow, TableBody, TableCell } from "@mui/material";
import isHtml from "is-html";
import styled from "styled-components";
import { useGetMailschedule4CustomTemplate } from "@app/orval/api/mail-schedule-4-logs";
const Wrapper = styled("div") `
  & .ql-mention-denotation-char {
    display: none;
  }
  & span.mention {
    background: rgb(200, 220, 244);
    border-radius: 6px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;
const getAttrString = (raw) => {
    const rawWithMergeFields = raw.replace(/\$\[(.*?)\]\((.*?)\)/g, "<b><font color='#0088ee'>$1</font></b>");
    if (isHtml(raw)) {
        return (React.createElement(Wrapper, null,
            React.createElement("pre", { dangerouslySetInnerHTML: { __html: rawWithMergeFields }, style: { all: "initial" } })));
    }
    return (React.createElement("div", { dangerouslySetInnerHTML: {
            __html: rawWithMergeFields == null
                ? ""
                : rawWithMergeFields.replaceAll("\n", "<br>").replaceAll("\t", "\u00A0\u00A0\u00A0\u00A0"),
        } }));
};
const CustomTemplateModal = (props) => {
    var _a, _b;
    const { customTemplate, logUid } = props;
    const getCustomTemplateApi = useGetMailschedule4CustomTemplate(logUid !== null && logUid !== void 0 ? logUid : "", {
        query: {
            enabled: !!logUid,
        },
    });
    const customTemplateFromApi = (_b = (_a = getCustomTemplateApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const customTemplate_ = customTemplate !== null && customTemplate !== void 0 ? customTemplate : customTemplateFromApi;
    const attrs = React.useMemo(() => (Object.assign(Object.assign({}, customTemplate_ === null || customTemplate_ === void 0 ? void 0 : customTemplate_.email_template), customTemplate_ === null || customTemplate_ === void 0 ? void 0 : customTemplate_.email_draft_template)), [customTemplate_]);
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
        React.createElement(Grid2, null,
            React.createElement(Table, { size: "small" },
                React.createElement(TableBody, null, Object.entries(attrs).map(([key, value]) => (React.createElement(TableRow, { key: key },
                    React.createElement(TableCell, { style: { fontWeight: "bold" }, align: "right" }, key),
                    React.createElement(TableCell, null, getAttrString(String(value !== null && value !== void 0 ? value : "")))))))))));
};
export default CustomTemplateModal;
