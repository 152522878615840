import * as React from "react";
import { RemoveCircleOutline } from "@mui/icons-material";
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
const SlackChannelListItem = (props) => {
    const { channel, onRemoveChannel } = props;
    const confirm = useConfirm();
    const handleRemoveUserFromChannel = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemoveChannel(channel.id);
            }
        });
    }, [confirm, onRemoveChannel, channel.id]);
    return (React.createElement(ListItem, { divider: true },
        React.createElement(ListItemText, { primary: channel.name }),
        channel.is_private && (React.createElement(ListItemSecondaryAction, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove user from channel") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: handleRemoveUserFromChannel },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" }))))))));
};
export default SlackChannelListItem;
