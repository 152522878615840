import * as React from "react";
import { Close } from "@mui/icons-material";
import { Alert, Grid, IconButton, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { useListMailschedule4Errors } from "@app/orval/api/mail-schedule-4-errors";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const MailScheduleErrors = (props) => {
    var _a, _b, _c, _d;
    const { entityUids } = props;
    const listMailScheduleErrors = useListMailschedule4Errors({ entity_uids: entityUids.join(",") });
    const errors = (_b = (_a = listMailScheduleErrors.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listMailScheduleTemplatesApi = useListMailschedule4Templates({}, INFINITE_CACHE_PARAMS);
    const templates = (_d = (_c = listMailScheduleTemplatesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const [dismissedErrors, setDismissedErrors] = React.useState(new Set());
    const dismiss = React.useCallback((errorId) => () => {
        setDismissedErrors((prev) => {
            const next = new Set(prev);
            next.add(errorId);
            return next;
        });
    }, []);
    const dataErrors = React.useMemo(() => (errors !== null && errors !== void 0 ? errors : [])
        .filter((e) => e.is_data_error)
        .map((x) => (Object.assign(Object.assign({}, x), { id: `${x.box.uid}__${x.template_uid}__${x.timestamp}` })))
        .filter((x) => !dismissedErrors.has(x.id))
        .slice(0, 3), [dismissedErrors, errors]);
    if (dataErrors.length === 0) {
        return null;
    }
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" }, dataErrors.map((error) => {
        var _a;
        const template = templates === null || templates === void 0 ? void 0 : templates.find((x) => x.uid === error.template_uid);
        return (React.createElement(Grid, { key: error.id, item: true },
            React.createElement(Alert, { severity: "error", sx: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    "& .MuiAlert-message": { width: "100%", paddingBottom: 0, paddingTop: "0.25rem" },
                } },
                React.createElement(Grid, { container: true, direction: "row", wrap: "nowrap" },
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Grid, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Mail Schedule Error")),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(Typography, { variant: "body2" }, "\u2022")),
                                    React.createElement(Grid, { item: true },
                                        React.createElement(RouterLink, { to: `/ms4/templates/${error.template_uid}` },
                                            React.createElement(Typography, { variant: "body2" }, (_a = template === null || template === void 0 ? void 0 : template.name) !== null && _a !== void 0 ? _a : error.template_uid))))),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, error.error_message)))),
                    React.createElement(Grid, { item: true },
                        React.createElement(IconButton, { onClick: dismiss(error.id) },
                            React.createElement(Close, null)))))));
    })));
};
export default MailScheduleErrors;
