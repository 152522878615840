import * as React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DASHSCORE_LEVELS } from "./utils";
const DashScoreLevels = (props) => {
    const { currentLevel, onLevelChange, style } = props;
    const changeLevel = React.useCallback((_, level) => {
        onLevelChange(level);
    }, [onLevelChange]);
    return (React.createElement(RadioGroup, { row: true, value: currentLevel, onChange: changeLevel, style: style }, DASHSCORE_LEVELS.map((level, index) => {
        return (React.createElement(FormControlLabel, { key: level, value: level, control: React.createElement(Radio, null), label: `${level} Level`, labelPlacement: "top", style: index === 0 || index === DASHSCORE_LEVELS.length - 1
                ? {}
                : {
                    marginLeft: "auto",
                    marginRight: "auto",
                } }));
    })));
};
export default DashScoreLevels;
