import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import CompsPullerRuleSetForm from "./CompsPullerRuleSet";
const CompsPullerRuleSetFormPage = () => {
    useDocumentTitle("Comps Finder | Rule Sets");
    const params = useParams();
    const { uid } = params;
    return React.createElement(CompsPullerRuleSetForm, { uid: uid });
};
export default CompsPullerRuleSetFormPage;
