import * as React from "react";
import { Delete } from "@mui/icons-material";
import { Button, Divider, Grid, TextField, MenuItem, Box, IconButton } from "@mui/material";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewSorting = () => {
    const context = useSavedViewEditorContext();
    const addSorting = React.useCallback(() => {
        context.setView((prev) => {
            return Object.assign(Object.assign({}, prev), { sorting: [
                    ...prev.sorting,
                    {
                        stage_sort: {
                            direction: "asc",
                        },
                    },
                ] });
        });
    }, [context]);
    const setSortType = React.useCallback((index) => (e) => {
        const { value } = e.target;
        console.log({ value });
        context.setView((prev) => {
            var _a, _b, _c, _d, _e, _f;
            const sorting = [...prev.sorting];
            const direction = (_f = (_d = (_b = (_a = sorting[index].field_sort) === null || _a === void 0 ? void 0 : _a.direction) !== null && _b !== void 0 ? _b : (_c = sorting[index].stage_sort) === null || _c === void 0 ? void 0 : _c.direction) !== null && _d !== void 0 ? _d : (_e = sorting[index].timestamp_sort) === null || _e === void 0 ? void 0 : _e.direction) !== null && _f !== void 0 ? _f : "asc";
            if (value === "stage") {
                sorting[index] = {
                    stage_sort: {
                        direction,
                    },
                };
            }
            else if (context.fields.some((field) => field.uid === value)) {
                sorting[index] = {
                    field_sort: {
                        field: value,
                        direction,
                    },
                };
            }
            else {
                sorting[index] = {
                    timestamp_sort: {
                        timestamp_type: value,
                        direction,
                    },
                };
            }
            return Object.assign(Object.assign({}, prev), { sorting });
        });
    }, [context]);
    const setSortDirection = React.useCallback((index) => (e) => {
        const value = e.target.value;
        context.setView((prev) => {
            const sorting = [...prev.sorting];
            const item = sorting[index];
            if (item.field_sort) {
                item.field_sort.direction = value;
            }
            else if (item.stage_sort) {
                item.stage_sort.direction = value;
            }
            else if (item.timestamp_sort) {
                item.timestamp_sort.direction = value;
            }
            return Object.assign(Object.assign({}, prev), { sorting });
        });
    }, [context]);
    const removeSorting = React.useCallback((index) => () => {
        context.setView((prev) => {
            return Object.assign(Object.assign({}, prev), { sorting: prev.sorting.filter((_, i) => i !== index) });
        });
    }, [context]);
    return (React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        context.view.sorting.map((item, i) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            return (React.createElement(Grid, { key: i, item: true },
                React.createElement(Box, { p: 2, borderRadius: 0.25, bgcolor: "#ccc" },
                    React.createElement(Grid, { container: true, spacing: 2, direction: "row", wrap: "nowrap" },
                        React.createElement(Grid, { item: true },
                            React.createElement(IconButton, { size: "small", onClick: removeSorting(i) },
                                React.createElement(Delete, { fontSize: "small" }))),
                        React.createElement(Grid, { item: true, style: { width: "200px" } },
                            React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", size: "small", SelectProps: {
                                    displayEmpty: true,
                                }, value: (_d = (_b = (_a = item.field_sort) === null || _a === void 0 ? void 0 : _a.field) !== null && _b !== void 0 ? _b : (_c = item.timestamp_sort) === null || _c === void 0 ? void 0 : _c.timestamp_type) !== null && _d !== void 0 ? _d : "stage", onChange: setSortType(i) },
                                ((_e = context.fields) !== null && _e !== void 0 ? _e : [])
                                    .sort((a, b) => {
                                    if (a.name < b.name)
                                        return -1;
                                    if (a.name > b.name)
                                        return 1;
                                    return 0;
                                })
                                    .map((field) => (React.createElement(MenuItem, { key: field.uid, value: field.uid }, field.name))),
                                React.createElement(Divider, null),
                                React.createElement(MenuItem, { value: "stage" }, "Stage"),
                                React.createElement(Divider, null),
                                ((_f = context.timestampTypes) !== null && _f !== void 0 ? _f : []).map((timestampType) => (React.createElement(MenuItem, { key: timestampType, value: timestampType }, timestampType))))),
                        React.createElement(Grid, { item: true, style: { width: "100px" } },
                            React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", size: "small", SelectProps: {
                                    displayEmpty: true,
                                }, value: (_m = (_k = (_h = (_g = item.field_sort) === null || _g === void 0 ? void 0 : _g.direction) !== null && _h !== void 0 ? _h : (_j = item.stage_sort) === null || _j === void 0 ? void 0 : _j.direction) !== null && _k !== void 0 ? _k : (_l = item.timestamp_sort) === null || _l === void 0 ? void 0 : _l.direction) !== null && _m !== void 0 ? _m : "asc", onChange: setSortDirection(i) },
                                React.createElement(MenuItem, { value: "asc" }, "Ascending"),
                                React.createElement(MenuItem, { value: "desc" }, "Descending")))))));
        }),
        React.createElement(Grid, { item: true },
            React.createElement(Button, { size: "small", variant: "outlined", onClick: addSorting }, "Add sort"))));
};
export default SavedViewSorting;
