import * as React from "react";
import { Route, Navigate, Routes as Switch, useNavigate } from "react-router-dom";
import ErrorPage403 from "@app/entrypoints/brokerage/features/misc/Error403";
import ErrorPage404 from "@app/entrypoints/brokerage/features/misc/Error404";
import useSession from "@app/hooks/useSession";
import routesConfig from "../routes";
const intersects = (arr1, arr2) => arr1.filter((obj) => arr2.includes(obj)).length > 0;
const DashRoute = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { children, developer, internal, roles, visibility } = props;
    const session = useSession();
    // if there is no active user, the route should be a redirect to the login page
    if ((internal || developer || (roles !== null && roles !== void 0 ? roles : []).length > 0) &&
        (!((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal) || ((_b = session.viewingAsUser.profile) === null || _b === void 0 ? void 0 : _b.stage.name) === "Terminated")) {
        console.log("User is not internal -- logging out. Current user: ", session === null || session === void 0 ? void 0 : session.viewingAsUser);
        window.location.href = `/login${window.location.href ? `?next=${window.location.href}` : ""}`;
        return null;
    }
    let canOpenRoute = true;
    if (visibility && (!session || !visibility(session))) {
        return React.createElement(ErrorPage403, null);
    }
    // ...if route is restricted to developers
    if (developer && (!((_c = session === null || session === void 0 ? void 0 : session.activeUser) === null || _c === void 0 ? void 0 : _c.isDeveloper) || !((_d = session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.isDeveloper))) {
        canOpenRoute = false;
    }
    // ...if route is restricted to certain roles
    else if (roles && roles.length > 0 && !intersects([...((_f = (_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.roles) !== null && _f !== void 0 ? _f : [])], roles)) {
        console.log("Viewer roles: ", (_g = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.roles, " did not match page roles: ", roles);
        canOpenRoute = false;
    }
    // ...if route is restricted to internal users
    else if (internal && !((_h = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _h === void 0 ? void 0 : _h.isInternal)) {
        canOpenRoute = false;
    }
    if (canOpenRoute) {
        return children;
    }
    // otherwise, the route points to a 403 error component saying the user isn't authorized to access
    return React.createElement(ErrorPage403, null);
};
DashRoute.defaultProps = {
    internal: false,
    developer: false,
    roles: undefined,
    tags: undefined,
    pageName: undefined,
};
const Routes = (props) => {
    const { initialRoute } = props;
    const [navigateTo, setNavigateTo] = React.useState(initialRoute);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (navigateTo) {
            console.log("navigating to ", navigateTo);
            navigate(navigateTo);
            setNavigateTo(undefined);
        }
    }, [navigateTo, navigate]);
    console.log({ initialRoute, navigateTo, navigate });
    if (navigateTo) {
        return null;
    }
    return (React.createElement(Switch, null,
        routesConfig.map((route) => {
            const inner = route.redirect ? React.createElement(Navigate, { to: route.redirect, replace: true }) : route.element;
            return (React.createElement(Route, { key: route.path, path: route.path, element: route.roles || route.internalOnly || route.visibility ? (React.createElement(DashRoute, { roles: route.roles, pageName: route.pageName, internal: route.internalOnly, visibility: route.visibility, tags: route.tags }, inner)) : (inner) }));
        }),
        React.createElement(Route, { path: "/" },
            React.createElement(Route, { path: ":any", element: React.createElement(ErrorPage404, null) }))));
};
export default Routes;
