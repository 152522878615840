import * as React from "react";
import { Grid2 } from "@mui/material";
import FormRow from "@app/entrypoints/brokerage/features/apps/matrix/components/FormRow";
const SUBDIVISION_FIELDS = [
    { name: "Average Price for Subdivision", fields: [{ name: "Closed Avg Sale Price" }] },
    { name: "High Price for Subdivision", fields: [{ name: "Closed Max Sale Price" }] },
    { name: "Median Price for Subdivision", fields: [{ name: "Closed Median Sale Price" }] },
    { name: "Low Price for Subdivision", fields: [{ name: "Closed Min Sale Price" }] },
];
const PER_SQFT_SUBDIVISION_FIELDS = [
    { name: "Average Price per SqFt for Subdivision", fields: [{ name: "Closed Avg per SqFt" }] },
    { name: "High Price per SqFt for Subdivision", fields: [{ name: "Closed Max per SqFt" }] },
    { name: "Median Price per SqFt for Subdivision", fields: [{ name: "Closed Median per SqFt" }] },
    { name: "Low Price per SqFt for Subdivision", fields: [{ name: "Closed Min per SqFt" }] },
];
const DOM_FIELDS = [
    { name: "Avg DOM", fields: [{ name: "Avg DOM" }] },
    { name: "High DOM", fields: [{ name: "Max DOM" }] },
];
const MISC_FIELDS = [
    { name: "Original List Price", fields: [{ name: "Original List Price" }] },
    { name: "Current List Price", fields: [{ name: "Current List Price" }] },
    { name: "Renovated Kitchen", fields: [{ name: "Renovated Kitchen" }] },
    { name: "Home Addition", fields: [{ name: "Home Addition" }] },
    { name: "Permits Acquired", fields: [{ name: "Permits Acquired" }] },
    { name: "Replaced Main Flooring", fields: [{ name: "Replaced Main Flooring" }] },
    { name: "Renovated Master Bath", fields: [{ name: "Renovated Master Bath" }] },
];
const FIELD_COLUMNS = [SUBDIVISION_FIELDS, PER_SQFT_SUBDIVISION_FIELDS, DOM_FIELDS, MISC_FIELDS];
const AgedPropertyReportTab = (props) => {
    const { matrixFields } = props;
    return (React.createElement(Grid2, { container: true, size: 12, style: { padding: "12px" } }, FIELD_COLUMNS.map((col) => (React.createElement(Grid2, { key: col[0].name, container: true, size: { xs: 12, sm: 6, md: 3 }, style: { marginBottom: "auto" } }, col.map((row) => (React.createElement(FormRow, Object.assign({ key: row.name }, props, { rowData: Object.assign(Object.assign({}, row), { fields: row.fields.map((field) => (Object.assign(Object.assign({}, field), { value: matrixFields[field.name] }))) }), labelSize: 8 })))))))));
};
export default AgedPropertyReportTab;
