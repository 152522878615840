import * as React from "react";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import { countPendingContracts } from "@app/orval/api/agent-dashboard";
import SmallMetricBox from "./widgets/SmallMetricBox";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const PendingContracts = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { selectedUserUids } = agentDashboardFilters;
    const queryParams = pickBy({ agent_uids: [...selectedUserUids] }, (val) => val != null);
    const countPendingContractsApi = usePostQuery(countPendingContracts, queryParams);
    const units = (_a = countPendingContractsApi.data) === null || _a === void 0 ? void 0 : _a.data.value;
    const value = units != null ? numeral(units).format("0,0") : undefined;
    return (React.createElement(SmallMetricBox, { title: "Pending Contracts", value: value, isLoading: countPendingContractsApi.isFetching, error: countPendingContractsApi.error, description: `
      The total number of transactions in Buyer and Seller Contracts where the Closing Date | COE has not been reached.
      The "Period" filter has no effect on this calculation.` }));
};
export default PendingContracts;
