import * as React from "react";
import { Alert, Box, Divider, Grid2, InputAdornment, MenuItem, NoSsr, Paper, Popper, Table, TableBody, TableCell, TableRow, TextField, } from "@mui/material";
import { BarChart, useItemTooltip, useMouseTracker } from "@mui/x-charts";
import { generateVirtualElement } from "@mui/x-charts/ChartsTooltip/utils";
import dayjs from "dayjs";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import useLoading from "@app/hooks/useLoading";
import { getMailschedule4TemplateOpenRates } from "@app/orval/api/mail-schedule-4-reporting";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { MAIL_SCHEDULE_ENTITY_TYPES } from "../utils/utils";
const roundValue = (val, usePercent, decimalPlaces) => {
    if (val == null) {
        return null;
    }
    const format = `0,0.${"0".repeat(decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 1)}`;
    const replace = `.${"0".repeat(decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 1)}`;
    return `${numeral(Math.round(val * 100) / 100)
        .format(format)
        .replace(replace, "")}${usePercent ? "%" : ""}`;
};
const GraphTooltip = (props) => {
    var _a, _b, _c, _d;
    const tooltip = useItemTooltip();
    const value = tooltip === null || tooltip === void 0 ? void 0 : tooltip.value;
    const { data } = props;
    const mousePosition = useMouseTracker();
    // The pointer type can be used to have different behavior based on pointer type.
    const isMousePointer = (mousePosition === null || mousePosition === void 0 ? void 0 : mousePosition.pointerType) === "mouse";
    // Adapt the tooltip offset to the size of the pointer.
    const yOffset = isMousePointer ? 0 : 40 - ((_a = mousePosition === null || mousePosition === void 0 ? void 0 : mousePosition.height) !== null && _a !== void 0 ? _a : 0);
    if (!value) {
        return null;
    }
    const { dataIndex } = tooltip.identifier;
    const tooltipData = dataIndex != null && dataIndex >= 0 ? data[dataIndex] : null;
    return (React.createElement(NoSsr, null,
        React.createElement(Popper, { sx: { pointerEvents: "none", zIndex: (theme) => theme.zIndex.modal }, open: true, placement: isMousePointer ? "top-end" : "top", anchorEl: generateVirtualElement(mousePosition), modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, yOffset],
                    },
                },
            ] },
            React.createElement(Paper, { elevation: 0, sx: {
                    m: 1,
                    p: 1.5,
                    border: "solid",
                    borderWidth: 2,
                    borderColor: "divider",
                } },
                React.createElement(Box, { p: 2 },
                    React.createElement(Table, { size: "small" },
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 2, style: { color: "gray" } }, (_b = tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.template_name) !== null && _b !== void 0 ? _b : "")),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "Open Rate"),
                                React.createElement(TableCell, null, roundValue(value, true))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "Sent"),
                                React.createElement(TableCell, null, (_c = tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.num_sent) !== null && _c !== void 0 ? _c : "-")),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "Opened"),
                                React.createElement(TableCell, null, (_d = tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.num_opened) !== null && _d !== void 0 ? _d : "-")))))))));
};
const MailScheduleTemplateMetrics = () => {
    var _a, _b, _c, _d, _e;
    const [selectedPipeline, setSelectedPipeline] = React.useState(null);
    const [selectedTemplateUids, setSelectedTemplateUids] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const listTemplatesApi = useListMailschedule4Templates({}, INFINITE_CACHE_PARAMS);
    const templates = (_b = (_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const nonSnippetEmailTemplates = templates === null || templates === void 0 ? void 0 : templates.filter((x) => (x.template_type === "Email" || x.template_type === "Email Draft") &&
        (x.criteria.length > 0 || x.triggers.length > 0));
    const templatesByUid = React.useMemo(() => {
        if (templates == null)
            return {};
        return templates.reduce((acc, template) => {
            acc[template.uid] = template;
            return acc;
        }, {});
    }, [templates]);
    const metricsQuery = pickBy({
        template_uids: selectedTemplateUids.length > 0 ? selectedTemplateUids : undefined,
        entity_types: selectedPipeline ? [selectedPipeline] : undefined,
        sent_timestamp_from: fromDate ? dayjs(fromDate).toISOString() : undefined,
        sent_timestamp_to: toDate ? dayjs(toDate).toISOString() : undefined,
    }, (val) => val != null);
    const getMetricsApi = usePostQuery(getMailschedule4TemplateOpenRates, metricsQuery, {});
    const metrics = (_d = (_c = getMetricsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const loading = useLoading({
        items: [
            {
                label: "Loading templates...",
                queryResult: listTemplatesApi,
            },
            {
                label: "Loading metrics...",
                queryResult: getMetricsApi,
            },
        ],
    });
    const handlePipelineChanged = React.useCallback((e) => {
        setSelectedPipeline(e.target.value || null);
        setSelectedTemplateUids([]);
    }, []);
    const handleTemplateUidsChanged = React.useCallback((templateUids) => {
        setSelectedTemplateUids(templateUids);
    }, []);
    const handleFromDateChanged = React.useCallback((date) => {
        setFromDate(date);
    }, []);
    const handleToDateChanged = React.useCallback((date) => {
        setToDate(date);
    }, []);
    const dataset = React.useMemo(() => {
        if (templates == null || metrics == null)
            return [];
        return metrics
            .filter((metric) => metric.num_sent > 0)
            .map((metric) => {
            var _a;
            const template = templatesByUid[metric.template_uid];
            return {
                template_name: (_a = template === null || template === void 0 ? void 0 : template.name) !== null && _a !== void 0 ? _a : "",
                template_uid: metric.template_uid,
                open_rate: (metric.num_opened * 100) / metric.num_sent,
                num_sent: metric.num_sent,
                num_opened: metric.num_opened,
            };
        })
            .sort((a, b) => {
            if (a.open_rate > b.open_rate)
                return -1;
            if (a.open_rate < b.open_rate)
                return 1;
            return 0;
        });
    }, [templates, metrics, templatesByUid]);
    console.log({ dataset });
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 2 },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, direction: "row", spacing: 1, alignItems: "flex-end" },
                    React.createElement(Grid2, { size: { xs: 12, lg: 3 } },
                        React.createElement(TextField, { fullWidth: true, slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Pipeline"),
                                },
                                select: {
                                    displayEmpty: true,
                                },
                            }, select: true, variant: "standard", size: "small", value: selectedPipeline !== null && selectedPipeline !== void 0 ? selectedPipeline : "", onChange: handlePipelineChanged },
                            React.createElement(MenuItem, { value: "" }, "All"),
                            MAIL_SCHEDULE_ENTITY_TYPES.map((entityType) => (React.createElement(MenuItem, { key: entityType, value: entityType }, entityType))))),
                    React.createElement(Grid2, { size: { xs: 12, lg: 3 } },
                        React.createElement(CoreChoiceField, { multiple: true, fullWidth: true, slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Templates"),
                                },
                            }, value: selectedTemplateUids, onChoicesChange: handleTemplateUidsChanged, variant: "standard", size: "small", options: (_e = (nonSnippetEmailTemplates !== null && nonSnippetEmailTemplates !== void 0 ? nonSnippetEmailTemplates : []).reduce((acc, x) => {
                                return Object.assign(Object.assign({}, acc), { [x.uid]: x.name });
                            }, {})) !== null && _e !== void 0 ? _e : [] }, (nonSnippetEmailTemplates !== null && nonSnippetEmailTemplates !== void 0 ? nonSnippetEmailTemplates : [])
                            .filter((x) => {
                            if (selectedPipeline == null)
                                return true;
                            return x.entity_type === selectedPipeline;
                        })
                            .map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))),
                    React.createElement(Grid2, { size: { xs: 12, lg: 3 } },
                        React.createElement(CoreDateField, { fullWidth: true, slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Sent From"),
                                },
                            }, date: fromDate, onDateChange: handleFromDateChanged, format: "M/D/YYYY", formatType: "dayjs", hasPicker: true, variant: "standard", size: "small" })),
                    React.createElement(Grid2, { size: { xs: 12, lg: 3 } },
                        React.createElement(CoreDateField, { fullWidth: true, slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Sent To"),
                                },
                            }, date: toDate, onDateChange: handleToDateChanged, format: "M/D/YYYY", formatType: "dayjs", hasPicker: true, variant: "standard", size: "small" }))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        loading.isLoading && React.createElement(Grid2, null, loading.loadingComponent),
        templates != null && metrics != null && (React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, direction: "column", spacing: 0, wrap: "nowrap" },
                    React.createElement(Grid2, null,
                        React.createElement(Alert, { severity: "info" }, "Open rate data is most likely not 100% accurate. It includes emails that were opened by the sender in addition to the recipient, and it may undercount opens if the user has disabled images in their email.")),
                    React.createElement(Grid2, null,
                        React.createElement(BarChart
                        // @ts-expect-error Type 'MailscheduleMetric[]' is not assignable to type 'DatasetElementType<string | number | Date | null | undefined>[]'
                        , { 
                            // @ts-expect-error Type 'MailscheduleMetric[]' is not assignable to type 'DatasetElementType<string | number | Date | null | undefined>[]'
                            dataset: dataset, xAxis: [
                                {
                                    scaleType: "band",
                                    dataKey: "template_name",
                                    tickLabelStyle: {
                                        angle: -45,
                                        textAnchor: "end",
                                    },
                                },
                            ], yAxis: [
                                {
                                    label: "Open rate",
                                    dataKey: "open_rate",
                                },
                            ], series: [{ dataKey: "open_rate" }], height: 500, tooltip: { trigger: "none" }, margin: {
                                bottom: 200,
                                left: 150,
                            } },
                            React.createElement(GraphTooltip, { data: dataset })))))))));
};
export default MailScheduleTemplateMetrics;
