import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import QuestionsForTheListingFormNEWCRM from "./QuestionsForTheListingFormNEWCRM";
const QuestionsForTheListingFormPageNEWCRM = () => {
    useDocumentTitle("Questions for the Listing");
    const params = useParams();
    const { uid } = params;
    return React.createElement(QuestionsForTheListingFormNEWCRM, { sellerLeadUid: uid });
};
export default QuestionsForTheListingFormPageNEWCRM;
