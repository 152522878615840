import * as React from "react";
import { Person } from "@mui/icons-material";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid2, Typography } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import useSession from "@app/hooks/useSession";
import ResponsiveFilterChip from "./ResponsiveFilterChip";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const UsersFilter = () => {
    var _a;
    const { selectedUserUids, setSelectedUserUids, users } = useAgentDashboardFilters();
    const session = useSession();
    const [panelAnchorEl, setPanelAnchorEl] = React.useState(null);
    const handleUsersFilterClicked = React.useCallback((e) => {
        setPanelAnchorEl(e.currentTarget);
    }, [setPanelAnchorEl]);
    const closePanel = React.useCallback(() => {
        setPanelAnchorEl(null);
    }, [setPanelAnchorEl]);
    const selectAll = React.useCallback(() => {
        var _a;
        setSelectedUserUids(new Set((_a = users === null || users === void 0 ? void 0 : users.map((user) => user.uid)) !== null && _a !== void 0 ? _a : []));
    }, [setSelectedUserUids, users]);
    const toggleSelectedUser = React.useCallback((userUid) => () => {
        setSelectedUserUids((prev) => {
            const next = new Set(prev);
            if (next.has(userUid)) {
                next.delete(userUid);
            }
            else {
                next.add(userUid);
            }
            return next;
        });
    }, [setSelectedUserUids]);
    const selectUser = React.useCallback((userUid) => () => {
        setSelectedUserUids(new Set([userUid]));
    }, [setSelectedUserUids]);
    if (!users || users.length <= 1) {
        return null;
    }
    const numSelected = selectedUserUids.size > 0 ? selectedUserUids.size : users.length;
    const label = `${numSelected} of ${users.length} users`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveFilterChip, { size: "small", label: label, icon: React.createElement(Person, { fontSize: "small" }), onClick: handleUsersFilterClicked, isActive: numSelected < users.length }),
        panelAnchorEl && (React.createElement(CorePopover, { anchorEl: panelAnchorEl, onClose: closePanel, open: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, direction: "column", spacing: 1, style: { overflow: "hidden" } },
                    React.createElement(Grid2, { style: { height: "32px" } },
                        React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center", style: { width: "100%" } },
                            React.createElement(Grid2, { style: { flex: 1 } },
                                React.createElement(Typography, { style: { fontWeight: "bold" } },
                                    "Users (",
                                    numSelected,
                                    " selected)")),
                            React.createElement(Grid2, null,
                                React.createElement(Button, { size: "small", onClick: selectAll }, "Select All")))),
                    React.createElement(Grid2, null,
                        React.createElement(Divider, null)),
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0, style: { maxHeight: "300px", overflow: "scroll", flex: 1 } },
                        ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) && (React.createElement(Grid2, null,
                            React.createElement(Box, { pb: 3 },
                                React.createElement(Grid2, { container: true, spacing: 1 },
                                    React.createElement(Grid2, { style: { flex: 1 } },
                                        React.createElement(FormControlLabel, { label: "Me", control: React.createElement(Checkbox, { size: "small", checked: selectedUserUids.has(session.viewingAsUser.uid) || selectedUserUids.size === 0, color: "primary", onChange: toggleSelectedUser(session.viewingAsUser.uid), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                    React.createElement(Grid2, null,
                                        React.createElement(Button, { style: { padding: 0 }, onClick: selectUser(session.viewingAsUser.uid) }, "only")))))),
                        users
                            .sort((a, b) => {
                            const aName = a.name.toLowerCase().trim();
                            const bName = b.name.toLowerCase().trim();
                            if (aName < bName) {
                                return -1;
                            }
                            if (aName > bName) {
                                return 1;
                            }
                            return 0;
                        })
                            .map((user) => (React.createElement(Grid2, { key: user.uid },
                            React.createElement(Grid2, { container: true, spacing: 1 },
                                React.createElement(Grid2, { style: { flex: 1 } },
                                    React.createElement(FormControlLabel, { label: user.name, control: React.createElement(Checkbox, { size: "small", checked: selectedUserUids.has(user.uid) || selectedUserUids.size === 0, color: "primary", onChange: toggleSelectedUser(user.uid), style: { paddingTop: 0, paddingBottom: 0 } }) })),
                                React.createElement(Grid2, null,
                                    React.createElement(Button, { style: { padding: 0 }, onClick: selectUser(user.uid) }, "only")))))))))))));
};
export default UsersFilter;
