import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Grid2, Typography } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
const SaveChangesDialog = (props) => {
    const { DialogProps, onBackToEditing, onDiscardChanges, onSave, verbiage } = props;
    return (React.createElement(CoreDialog, Object.assign({}, DialogProps),
        React.createElement(DialogTitle, null, "Save changes?"),
        React.createElement(DialogContent, null,
            React.createElement(Typography, null, verbiage !== null && verbiage !== void 0 ? verbiage : "You have unsaved changes.")),
        React.createElement(DialogActions, null,
            React.createElement(Grid2, { container: true, spacing: 2 },
                React.createElement(Grid2, null,
                    React.createElement(Button, { color: "primary", variant: "text", onClick: onBackToEditing }, "Back to editing")),
                React.createElement(Grid2, { style: { flex: 1 } }),
                React.createElement(Grid2, null,
                    React.createElement(Button, { color: "primary", variant: "outlined", onClick: onDiscardChanges }, "Discard changes")),
                React.createElement(Grid2, null,
                    React.createElement(Button, { color: "primary", variant: "contained", onClick: onSave }, "Save"))))));
};
export default SaveChangesDialog;
