import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import OfficeForm from "../../features/admin/offices/OfficeForm";
const OfficeFormPage = () => {
    useDocumentTitle("Offices");
    const params = useParams();
    return React.createElement(OfficeForm, { uid: params.uid });
};
export default OfficeFormPage;
