import * as React from "react";
import { Add, MeetingRoom } from "@mui/icons-material";
import { IconButton, List, Tooltip, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import InterviewDialog from "./InterviewDialog";
import InterviewListItem from "./InterviewListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
/**
 * A component for managing Recruiting interviews
 * @param props
 * @returns
 */
const Interviews = (props) => {
    var _a;
    const { interviews, onAddInterview, onRemoveInterview, onUpdateInterview } = props;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [editingInterviewUid, setEditingInterviewUid] = React.useState(null);
    const openDialog = React.useCallback(() => {
        setIsDialogOpen(true);
    }, [setIsDialogOpen]);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);
    const addInterview = React.useCallback(() => {
        setEditingInterviewUid(null);
        openDialog();
    }, [setEditingInterviewUid, openDialog]);
    const editInterview = React.useCallback((agentUid) => {
        setEditingInterviewUid(agentUid);
        openDialog();
    }, [setEditingInterviewUid, openDialog]);
    const addOrUpdateInterview = React.useCallback((params) => {
        closeDialog();
        if (editingInterviewUid) {
            onUpdateInterview(editingInterviewUid, params);
        }
        else {
            onAddInterview(params);
        }
    }, [closeDialog, editingInterviewUid, onUpdateInterview, onAddInterview]);
    const interviewsSorted = React.useMemo(() => {
        var _a;
        return ((_a = interviews === null || interviews === void 0 ? void 0 : interviews.sort((a, b) => {
            var _a, _b;
            const aDate = (_a = a.date_and_time) !== null && _a !== void 0 ? _a : "";
            const bDate = (_b = b.date_and_time) !== null && _b !== void 0 ? _b : "";
            if (aDate < bDate)
                return -1;
            if (aDate > bDate)
                return 1;
            return 0;
        })) !== null && _a !== void 0 ? _a : null);
    }, [interviews]);
    const isLoading = interviews == null;
    const noInterviews = interviews && interviews.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(MeetingRoom, null), title: "Interviews", count: (_a = interviews === null || interviews === void 0 ? void 0 : interviews.length) !== null && _a !== void 0 ? _a : undefined, toolbar: onAddInterview && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add interview") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: addInterview },
                        React.createElement(Add, { fontSize: "small" }))))) },
            isLoading && React.createElement(CoreLoading, null),
            noInterviews && React.createElement(Typography, { variant: "body2" }, "No interviews"),
            interviews && interviews.length > 0 && (React.createElement(List, { dense: true }, (interviewsSorted !== null && interviewsSorted !== void 0 ? interviewsSorted : []).map((interview) => (React.createElement(InterviewListItem, { key: interview.uid, interview: interview, onEditInterview: onUpdateInterview ? editInterview : undefined, onRemoveInterview: onRemoveInterview })))))),
        isDialogOpen && (React.createElement(InterviewDialog, { DialogProps: { open: isDialogOpen }, onClose: closeDialog, onUpdate: addOrUpdateInterview, interview: (interviews !== null && interviews !== void 0 ? interviews : []).find((a) => a.uid === editingInterviewUid) }))));
};
export default Interviews;
