import * as React from "react";
import { Mention, MentionsInput } from "react-mentions";
import styled from "styled-components";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import { searchUsersBasic } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
const StyledMentionsInput = styled(MentionsInput) `
  padding-bottom: 0.5rem;
  font-family: '${AppConfig.fonts.primary}', Helvetica, Arial, sans-serif;
  font-size: 11pt;
  textarea,
  input {
    border: none;
    outline: none;
  }
  ul {
    box-shadow:
      0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
    max-height: 200px;
    overflow-y: auto;
  }
  li {
    padding: 0.25rem;
  }
  .sc-jSgupP__highlighter__substring {
    margin-right: -0.6%;
  }
`;
const CommentTextArea = (props) => {
    const { autoFocus, onChange, value } = props;
    const searchUsersApi = usePostQuery(searchUsersBasic, { disable_sort: true, exclude_terminated: true }, INFINITE_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a;
        return (_a = searchUsersApi.data) === null || _a === void 0 ? void 0 : _a.data;
    }, [searchUsersApi.data]);
    return (React.createElement(StyledMentionsInput, { value: value == null ? "" : value, onChange: onChange, allowSpaceInQuery: true, className: "MuiInputBase-root MuiInput-root MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl", style: {
            border: "none",
            paddingBottom: "0.5rem",
        }, autoFocus: autoFocus }, ["@"].map((trigger) => (React.createElement(Mention, { key: trigger, trigger: trigger, data: (users !== null && users !== void 0 ? users : []).map((user) => ({
            id: user.uid,
            display: [user.first_name, user.last_name].filter((obj) => obj).join(" "),
        })), markup: "@[__display__](__id__)", appendSpaceOnAdd: true, style: {
            border: `1px solid ${AppConfig.themeColors.primary}`,
            borderRadius: "0.25rem",
            background: "#edccca",
            lineHeight: 1.5,
            display: "inline-block",
        } })))));
};
export default CommentTextArea;
