/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary List requests
 */
export const listRequests = (params, options) => {
    return axios.get(`/api/requests`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListRequestsQueryKey = (params) => {
    return [`/api/requests`, ...(params ? [params] : [])];
};
export const getListRequestsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListRequestsQueryKey(params);
    const queryFn = ({ signal }) => listRequests(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List requests
 */
export function useListRequests(params, options) {
    const queryOptions = getListRequestsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Get request timing
 */
export const getRequestTiming = (requestId, options) => {
    return axios.get(`/api/requests/${requestId}/timing`, options);
};
export const getGetRequestTimingQueryKey = (requestId) => {
    return [`/api/requests/${requestId}/timing`];
};
export const getGetRequestTimingQueryOptions = (requestId, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRequestTimingQueryKey(requestId);
    const queryFn = ({ signal }) => getRequestTiming(requestId, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(requestId) }, queryOptions);
};
/**
 * @summary Get request timing
 */
export function useGetRequestTiming(requestId, options) {
    const queryOptions = getGetRequestTimingQueryOptions(requestId, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
