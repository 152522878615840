import * as React from "react";
import { Edit, RemoveCircleOutline } from "@mui/icons-material";
import { Chip, Grid2, IconButton, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import dayjs_ from "@app/common/dayjs";
import { getFullName } from "@app/util/Utils";
const ShowingListItem = (props) => {
    const { onEditShowing, onRemoveShowing, showing } = props;
    const confirm = useConfirm();
    const removeShowing = React.useCallback(() => {
        confirm({
            title: "Are you sure?",
            description: "This will remove the showing from the lead.",
            confirmationText: "Remove",
        }).then((result) => {
            if (result.confirmed) {
                onRemoveShowing === null || onRemoveShowing === void 0 ? void 0 : onRemoveShowing(showing.uid);
            }
        });
    }, [confirm, onRemoveShowing, showing.uid]);
    const editShowing = React.useCallback(() => {
        onEditShowing === null || onEditShowing === void 0 ? void 0 : onEditShowing(showing.uid);
    }, [onEditShowing, showing.uid]);
    const dateStr = showing.datetime && dayjs_(showing.datetime).isValid()
        ? dayjs_(showing.datetime).format("dddd, MMMM D, YYYY h:mm A")
        : showing.datetime;
    return (React.createElement(ListItem, { secondaryAction: React.createElement(React.Fragment, null,
            onEditShowing ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Edit showing") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: editShowing },
                        React.createElement(Edit, { fontSize: "small" }))))) : null,
            onRemoveShowing ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove showing") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removeShowing },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" }))))) : null) },
        React.createElement(ListItemText, { primary: React.createElement(Grid2, { container: true, spacing: 0, direction: "column", wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2" }, dateStr)),
                        React.createElement(Grid2, null,
                            React.createElement(Chip, { size: "small", label: showing.showing_type, style: { height: "15px" } })))),
                showing.agents.length > 0 && (React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" },
                        "DASH Agents: ",
                        showing.agents.map((x) => getFullName(x.first_name, x.last_name)).join(", "),
                        " "))),
                showing.contact && (React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Third Party Agent: ",
                        getFullName(showing.contact.first_name, showing.contact.last_name))))), secondary: showing.notes, style: { marginTop: 0, marginBottom: 0 } })));
};
export default ShowingListItem;
