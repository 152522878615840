import * as React from "react";
import Editor from "@monaco-editor/react";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";
import AppConfig from "@app/util/AppConfig";
const lineHeight = 20;
const StyledEditorWrapper = styled.div `
  .dash-autocomplete-suggestion {
    color: ${AppConfig.themeColors.primary};
    font-weight: bold;
  }
  .monaco-suggest-widget {
    z-index: 100001 !important;
  }
`;
const FormulaField = (props) => {
    var _a;
    const { autocompleteSuggestions, formula, helpText, onValueChange } = props;
    const [editorHeight, setEditorHeight] = React.useState(null);
    const disposeFn = React.useRef(null);
    const ideRef = React.useRef(null);
    const ideModel = (_a = ideRef.current) === null || _a === void 0 ? void 0 : _a.getModel();
    const decorationIds = React.useRef([]);
    const allAutocompleteSuggestionStrings = React.useMemo(() => {
        var _a;
        return (_a = autocompleteSuggestions === null || autocompleteSuggestions === void 0 ? void 0 : autocompleteSuggestions.map((x) => (typeof x === "string" ? x : x.label))) !== null && _a !== void 0 ? _a : [];
    }, [autocompleteSuggestions]);
    // reset text decorations
    if (ideModel) {
        const autocompleteMatches = allAutocompleteSuggestionStrings.reduce((tot, item) => {
            return [...tot, ...ideModel.findMatches(item, true, false, false, null, true)];
        }, []);
        decorationIds.current = ideModel.deltaDecorations(decorationIds.current, []);
        decorationIds.current = ideModel.deltaDecorations([], autocompleteMatches.map((match) => ({
            range: match.range,
            options: {
                inlineClassName: "dash-autocomplete-suggestion",
            },
        })));
    }
    const handleChange = React.useCallback((value) => {
        onValueChange(value !== null && value !== void 0 ? value : "");
        setEditorHeight(ideRef.current.getModel().getLineCount() * lineHeight);
    }, [onValueChange]);
    const handleMount = React.useCallback((editor_, monaco) => {
        ideRef.current = editor_;
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            noLib: true, // Disable default library
            allowNonTsExtensions: true, // Allow non-TypeScript extensions (like .js)
            target: monaco.languages.typescript.ScriptTarget.ES2020, // Set the target to ES6 or your preferred version
            // Other compiler options as needed
        });
        if (autocompleteSuggestions) {
            disposeFn.current = monaco.languages.registerCompletionItemProvider(editor_.getModel().getLanguageId(), {
                // @ts-expect-error range missing
                provideCompletionItems: (model, position) => {
                    var _a;
                    const textUntilPosition = model.getValueInRange({
                        startLineNumber: position.lineNumber,
                        startColumn: 1,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                    });
                    let suggestions = [];
                    const match = (_a = textUntilPosition.match(/[$A-Za-z0-9]+\.$/)) === null || _a === void 0 ? void 0 : _a[0];
                    const lastCharacterIsPeriod = !!textUntilPosition.match(/\.$/);
                    if (match) {
                        if (match === "$USER.") {
                            const userSuggestions = [
                                {
                                    name: "uid",
                                    type: "string",
                                },
                                { name: "first_name", type: "string | null" },
                                { name: "last_name", type: "string | null" },
                                { name: "email", type: "string | null" },
                                {
                                    name: "roles",
                                    type: "string[]",
                                    documentation: "Ex: use `$USER.roles.includes(\"Superadmin\")` to allow only superadmins to edit the field.",
                                },
                            ];
                            // @ts-expect-error
                            suggestions = userSuggestions.map((userSuggestion) => ({
                                label: userSuggestion.name,
                                kind: monaco.languages.CompletionItemKind.Constant,
                                insertText: userSuggestion.name,
                                detail: userSuggestion.type,
                                documentation: userSuggestion.documentation,
                            }));
                        }
                    }
                    else if (!lastCharacterIsPeriod) {
                        // @ts-expect-error
                        suggestions = autocompleteSuggestions.map((item) => {
                            if (typeof item === "string") {
                                return {
                                    label: item,
                                    kind: monaco.languages.CompletionItemKind.Constant,
                                    insertText: item,
                                };
                            }
                            return {
                                label: item.label,
                                kind: monaco.languages.CompletionItemKind.Constant,
                                insertText: item.label,
                                detail: item.type,
                                documentation: item.documentation,
                            };
                        });
                    }
                    if (model.uri.toString() === editor_.getModel().uri.toString()) {
                        return { suggestions };
                    }
                },
                triggerCharacters: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$.".split(""),
            });
        }
        // const contentHeight = editor_.getContentHeight() > 100 ? editor_.getContentHeight() : 420;
        setEditorHeight(editor_.getModel().getLineCount() * lineHeight);
    }, [setEditorHeight, autocompleteSuggestions]);
    React.useEffect(() => {
        return () => {
            if (disposeFn.current) {
                disposeFn.current.dispose();
            }
        };
    }, []);
    return (React.createElement(StyledEditorWrapper, null,
        React.createElement(Editor, { height: editorHeight !== null && editorHeight !== void 0 ? editorHeight : lineHeight, value: formula, onChange: handleChange, onMount: handleMount, defaultLanguage: "javascript", options: {
                automaticLayout: true,
                roundedSelection: true,
                lineHeight,
                scrollBeyondLastLine: false,
                minimap: {
                    enabled: false,
                },
                fixedOverflowWidgets: true,
            } }),
        helpText && (React.createElement(Markdown, { options: {
                disableParsingRawHTML: true,
            }, style: {
                fontFamily: `'${AppConfig.fonts.primary}', Helvetica, Arial, sans-serif`,
                fontWeight: 400,
                fontSize: 11,
            } }, helpText))));
};
export default FormulaField;
