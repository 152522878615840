var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import mlsGridIcon from "@app/assets/img/mls-grid.png";
import { getMlsGridMedia } from "@app/orval/api/mls-grid";
/**
 * Wraps an `<img>` component
 *
 * For photos pulled from MLS Grid, we can't just embed the photo in our site.
 * We have to download the photo, and use our local copy in the `<img>` tag.
 *
 * For non-MLS Grid, just returns the `<img>` element with the appropriate `src`.
 */
const MLSPhoto = (props) => {
    const [photoUrl, setPhotoUrl] = React.useState(null);
    const { alt, mediaKey, src } = props, imgProps = __rest(props, ["alt", "mediaKey", "src"]);
    const needLocalCopy = React.useMemo(() => {
        return !!(mediaKey && (src === null || src === void 0 ? void 0 : src.includes("grid")));
    }, [mediaKey, src]);
    React.useEffect(() => {
        if (needLocalCopy) {
            const query = {
                media_url: src,
                media_key: mediaKey,
            };
            console.log("Getting media", query);
            getMlsGridMedia(query)
                .then((result) => {
                setPhotoUrl(result.data.url);
            })
                .catch((error) => {
                console.error("Error getting media from MLS Grid:", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needLocalCopy]);
    let src_ = src;
    if (mediaKey && needLocalCopy) {
        src_ = photoUrl !== null && photoUrl !== void 0 ? photoUrl : mlsGridIcon;
    }
    return React.createElement("img", Object.assign({}, imgProps, { alt: alt !== null && alt !== void 0 ? alt : "MLS Photo", src: src_ }));
};
export default MLSPhoto;
