import * as React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
const TwilioSMSLoading = () => {
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 2 }, Array.from(Array(6)).map((_, i) => (React.createElement(Grid, { item: true, container: true, key: i },
            i % 2 === 1 && React.createElement(Grid, { item: true, style: { flex: 1 } }),
            React.createElement(Grid, { item: true, style: { width: "80%" } },
                React.createElement(Skeleton, { variant: "rectangular", height: 25 }))))))));
};
export default TwilioSMSLoading;
