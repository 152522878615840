import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import NMSRequests from "../../features/analyst/NMSRequests";
const NMSRequestPage = () => {
    useDocumentTitle("NMS Requests");
    const [searchParams, _] = useSearchParams();
    const limitStr = searchParams.get("limit");
    const limit = limitStr ? parseFloat(limitStr) : null;
    const offsetStr = searchParams.get("offset");
    const offset = offsetStr ? parseFloat(offsetStr) : null;
    const searchStr = searchParams.get("search");
    return React.createElement(NMSRequests, { limit: limit, offset: offset, search: searchStr });
};
export default NMSRequestPage;
