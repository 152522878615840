import * as React from "react";
import { Share } from "@mui/icons-material";
import { Button } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import SavedViewSharePanel from "./SavedViewSharePanel";
const SavedViewShareButton = () => {
    const [sharePanelAnchorEl, setSharePanelAnchorEl] = React.useState(null);
    const openSharePanel = React.useCallback((e) => {
        setSharePanelAnchorEl(e.currentTarget);
    }, [setSharePanelAnchorEl]);
    const closeSharePanel = React.useCallback(() => {
        setSharePanelAnchorEl(null);
    }, [setSharePanelAnchorEl]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { size: "small", color: "primary", variant: "outlined", startIcon: React.createElement(Share, { fontSize: "small" }), onClick: openSharePanel, tabIndex: -1 }, "Share"),
        !!sharePanelAnchorEl && (React.createElement(CorePopover, { open: true, anchorEl: sharePanelAnchorEl, onClose: closeSharePanel, slotProps: { paper: { style: { maxWidth: "400px" } } } },
            React.createElement(SavedViewSharePanel, null)))));
};
export default SavedViewShareButton;
