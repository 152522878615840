import * as React from "react";
import { Add, FileCopy, Delete, Save, CloudDownload } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, ListItemSecondaryAction, MenuItem, Paper, Slider, Switch, TextField, Tooltip, Typography, CircularProgress, Autocomplete, Alert, } from "@mui/material";
import dayjs from "dayjs";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import numeral from "numeral";
import { v4 as uuidv4 } from "uuid";
import BoxAutocomplete from "@app/common/autocompletes/BoxAutocomplete";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDateField from "@app/common/CoreDateField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import dayjs_ from "@app/common/dayjs";
import MailScheduleFormattersHelper from "@app/entrypoints/brokerage/features/mailschedule/utils/MailScheduleFormattersHelper";
import MailScheduleQuill from "@app/entrypoints/brokerage/features/mailschedule/utils/MailScheduleQuill";
import MentionsInput from "@app/entrypoints/brokerage/features/mailschedule/utils/MentionsInput";
import { bodyHelperText } from "@app/entrypoints/brokerage/features/mailschedule/utils/utils";
import { withDialog } from "@app/hoc/withDialog";
import { withRouter } from "@app/hoc/withRouter";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import Session from "@app/util/Session";
import { defaultUserIcon, downloadBlob, getReadableErrorMessage } from "@app/util/Utils";
import DateCriteriaInfoButton from "./DateCriteriaInfoButton";
import IfStatementBuilder from "./IfStatementBuilder";
import MailScheduleTemplatePreview from "../preview/MailScheduleTemplatePreview";
const MailScheduleTemplateHelperText = (props) => {
    const { extraVerbiage, includeStreakFieldVerbiage } = props;
    return (React.createElement(Typography, { variant: "caption", dangerouslySetInnerHTML: {
            __html: (includeStreakFieldVerbiage &&
                `Use <b><font color='${AppConfig.themeColors.blue}'>$'Field Name'</font></b> syntax to auto-populate with Streak data. `) +
                (extraVerbiage !== null && extraVerbiage !== void 0 ? extraVerbiage : ""),
        } }));
};
MailScheduleTemplateHelperText.defaultProps = {
    includeStreakFieldVerbiage: true,
    extraVerbiage: "",
};
const renderAgentDropdownOption = (props, option) => {
    var _a;
    const rowStyle = {
        overflow: "hidden !important",
        height: "10px",
        fontSize: "10px",
    };
    return (React.createElement(MenuItem, Object.assign({}, props, { value: option ? `agent_dropdown_option_${option.uid}` : "agent_dropdown_option_blank" }),
        React.createElement(Grid, { container: true, spacing: 2 }, option != null && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, null,
                    React.createElement("img", { alt: "", src: (_a = option.icon_url) !== null && _a !== void 0 ? _a : defaultUserIcon, width: "30px", height: "30px", style: {
                            margin: 0,
                            borderRadius: "50%",
                        } }))),
            React.createElement(Grid, { item: true, xs: 9 },
                React.createElement(Grid, { item: true, xs: 12, style: rowStyle }, `${option.first_name} ${option.last_name}`.trim()),
                React.createElement(Grid, { item: true, xs: 12, style: rowStyle }, option.email),
                React.createElement(Grid, { item: true, xs: 12, style: rowStyle }, option.phone)))))));
};
/**
 *
 * @param agent
 * @returns Agent's full name (FirstName LastName)
 */
const getAgentName = (agent) => {
    if (agent == null) {
        return "";
    }
    const fullName = `${agent.first_name} ${agent.last_name}`;
    return fullName;
};
const renderAgentSearchInput = (params) => (React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: "standard", label: "Assigned Agent" })));
const getBlobComponentChildren = (blobNames, blobComponent) => {
    const children = [];
    blobNames.forEach((name) => {
        // split name into folder/file names
        const nameSplit = name.split("/");
        // get index of target component
        const componentIndex = blobComponent ? nameSplit.indexOf(blobComponent) : 0;
        if (componentIndex !== -1 && componentIndex < nameSplit.length) {
            // if component is present and not the last component
            const child = blobComponent ? nameSplit[componentIndex + 1] : nameSplit[0];
            if (!children.includes(child)) {
                children.push(child);
            }
        }
    });
    return children;
};
const getTemplateType = (template) => {
    if (template.email_template) {
        return "Email";
    }
    if (template.email_draft_template) {
        return "Email Draft";
    }
    if (template.sms_template) {
        return "SMS";
    }
    if (template.sms_draft_template) {
        return "SMS Draft";
    }
    if (template.calendar_template) {
        return "Calendar";
    }
    if (template.slack_template) {
        return "Slack";
    }
    if (template.streak_comment_template) {
        return "Streak Comment";
    }
    console.error("Invalid template type for template:", template);
    throw Error("Invalid template type");
};
const getFieldType = (field) => {
    if (field.type === "TEXT_INPUT") {
        return "TEXT_INPUT";
    }
    if (field.type === "DATE") {
        return "DATE";
    }
    if (field.type === "DROPDOWN") {
        return "DROPDOWN";
    }
    if (field.type === "TAG") {
        return "TAG";
    }
    if (field.type === "CHECKBOX") {
        return "CHECKBOX";
    }
    if (field.type === "FORMULA") {
        if (field.name.toLowerCase().includes("date") || field.name.toLowerCase().includes("last stage change")) {
            return "DATE";
        }
        return "TEXT_INPUT";
    }
    throw new Error(`Invalid field type ${field.type}`);
};
class MailScheduleTemplateForm extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                template: null,
                templateTypes: null,
                pipelines: null,
                views: null,
                boilerplate: null,
                streakUsers: null,
                agents: null,
                boxes: null,
                selectedBoxKeyForPreview: null,
                boxAutocompleteKey: uuidv4().replace(/-/g, ""),
                messagePlainText: "",
                templates: null,
                dateOffsetUnits: null,
                slackChannels: null,
                slackUsers: null,
                twilioPhones: null,
                dateCriteriaComparisonTypes: null,
                checkboxCriteriaComparisonTypes: null,
                textCriteriaComparisonTypes: null,
                dropdownCriteriaComparisonTypes: null,
                tagCriteriaComparisonTypes: null,
                templateCriteriaDelayModes: null,
                templateCriteriaDelayUnits: null,
                templateCriteriaComparisonTypes: null,
                stageCriteriaComparisonTypes: null,
                viewCriteriaComparisonTypes: null,
                gcpBlobs: null,
                currentlyDownloadingGCPAttachments: [],
                error: null,
                templateType: "Email Draft",
                isSaving: false,
                isDeleting: false,
                isDuplicating: false,
                isPreviewOpen: false,
            }
        });
        Object.defineProperty(this, "save", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (onComplete) => () => {
                const { uid } = this.props;
                this.setState({
                    isSaving: true,
                });
                const loadingSnackbar = enqueueSnackbar("Saving template...", { variant: "info", persist: true });
                console.log("saving template", this.data);
                const params = {
                    data: this.data,
                    onSuccess: (result) => {
                        enqueueSnackbar("Template has been saved.", { variant: "success" });
                        closeSnackbar(loadingSnackbar);
                        this.setState({
                            isSaving: false,
                        });
                        console.log("onComplete", onComplete);
                        if (onComplete) {
                            onComplete(result);
                        }
                        else if (this.selectedPipeline) {
                            this.props.navigate(`/mailschedule/templates?pipeline=${this.selectedPipeline.key}`);
                        }
                        else {
                            this.props.navigate("/mailschedule/templates");
                        }
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                        this.setState({
                            isSaving: false,
                        });
                        closeSnackbar(loadingSnackbar);
                    },
                };
                if (uid) {
                    API.mailScheduleTemplates.update(uid, params);
                }
                else {
                    API.mailScheduleTemplates.create(params);
                }
            }
        });
        Object.defineProperty(this, "duplicate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.save((result) => {
                    this.setState({
                        isDuplicating: true,
                    });
                    API.mailScheduleTemplates.duplicate(result.uid, {
                        onSuccess: (duplicationResult) => {
                            this.setState({
                                isDuplicating: false,
                            });
                            this.props.navigate(`/mailschedule/templates/${duplicationResult.uid}`);
                        },
                        onError: (duplicationError) => {
                            this.props.showErrorDialog(duplicationError);
                            this.setState({
                                isDuplicating: false,
                            });
                        },
                    });
                })();
            }
        });
        Object.defineProperty(this, "delete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        const { uid } = this.props;
                        this.props.hideDialog();
                        this.setState({
                            isDeleting: true,
                        });
                        const loadingSnackbar = enqueueSnackbar("Deleting template...", { variant: "info", persist: true });
                        API.mailScheduleTemplates.delete(uid, {
                            onSuccess: () => {
                                if (this.selectedPipeline) {
                                    this.props.navigate(`/mailschedule/templates?pipeline=${this.selectedPipeline.key}`);
                                }
                                else {
                                    this.props.navigate("/mailschedule/templates");
                                }
                                enqueueSnackbar("Template has been deleted.", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                this.setState({
                                    isDeleting: false,
                                });
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        Object.defineProperty(this, "setIsActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { is_active: checked }),
                }));
            }
        });
        Object.defineProperty(this, "setPipeline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const pipeline = e.target.value;
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "Changing the pipeline may lead to unexpected behavior for certain merge fields, and all triggers and criteria will be removed, because the Streak keys may be different for fields in the new pipeline. For best results, be sure to re-add all necessary triggers and criteria, and delete and re-add all merge fields to ensure they're in sync with the new pipeline.",
                    confirmButtonText: "Change Pipeline",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            template: Object.assign(Object.assign({}, prevState.template), { timestamp_streak_field_key: null, pipeline, criteria: [], triggers: [] }),
                        }));
                    },
                });
            }
        });
        Object.defineProperty(this, "setTemplateType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const templateType = e.target.value;
                this.setState((prevState) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { email_template: templateType === "Email"
                                ? {
                                    sender_type: "assigned agent",
                                    sender: undefined,
                                    to: (_b = (_a = prevState.template.email_draft_template) === null || _a === void 0 ? void 0 : _a.to) !== null && _b !== void 0 ? _b : "",
                                    cc: (_d = (_c = prevState.template.email_draft_template) === null || _c === void 0 ? void 0 : _c.cc) !== null && _d !== void 0 ? _d : "",
                                    bcc: (_f = (_e = prevState.template.email_draft_template) === null || _e === void 0 ? void 0 : _e.bcc) !== null && _f !== void 0 ? _f : "",
                                    subject: (_k = (_h = (_g = prevState.template.email_draft_template) === null || _g === void 0 ? void 0 : _g.subject) !== null && _h !== void 0 ? _h : (_j = prevState.template.calendar_template) === null || _j === void 0 ? void 0 : _j.title) !== null && _k !== void 0 ? _k : "",
                                    body: (_p = (_m = (_l = prevState.template.email_draft_template) === null || _l === void 0 ? void 0 : _l.body) !== null && _m !== void 0 ? _m : (_o = prevState.template.calendar_template) === null || _o === void 0 ? void 0 : _o.description) !== null && _p !== void 0 ? _p : "",
                                    allow_unsubscribe: (_t = (_r = (_q = prevState.template.email_draft_template) === null || _q === void 0 ? void 0 : _q.allow_unsubscribe) !== null && _r !== void 0 ? _r : (_s = prevState.template.calendar_template) === null || _s === void 0 ? void 0 : _s.allow_unsubscribe) !== null && _t !== void 0 ? _t : false,
                                }
                                : undefined, email_draft_template: templateType === "Email Draft"
                                ? {
                                    sender_type: "assigned agent",
                                    sender: undefined,
                                    to: (_v = (_u = prevState.template.email_template) === null || _u === void 0 ? void 0 : _u.to) !== null && _v !== void 0 ? _v : "",
                                    cc: (_x = (_w = prevState.template.email_template) === null || _w === void 0 ? void 0 : _w.cc) !== null && _x !== void 0 ? _x : "",
                                    bcc: (_z = (_y = prevState.template.email_template) === null || _y === void 0 ? void 0 : _y.bcc) !== null && _z !== void 0 ? _z : "",
                                    subject: (_3 = (_1 = (_0 = prevState.template.email_template) === null || _0 === void 0 ? void 0 : _0.subject) !== null && _1 !== void 0 ? _1 : (_2 = prevState.template.calendar_template) === null || _2 === void 0 ? void 0 : _2.title) !== null && _3 !== void 0 ? _3 : "",
                                    body: (_7 = (_5 = (_4 = prevState.template.email_template) === null || _4 === void 0 ? void 0 : _4.body) !== null && _5 !== void 0 ? _5 : (_6 = prevState.template.calendar_template) === null || _6 === void 0 ? void 0 : _6.description) !== null && _7 !== void 0 ? _7 : "",
                                    allow_unsubscribe: (_11 = (_9 = (_8 = prevState.template.email_template) === null || _8 === void 0 ? void 0 : _8.allow_unsubscribe) !== null && _9 !== void 0 ? _9 : (_10 = prevState.template.calendar_template) === null || _10 === void 0 ? void 0 : _10.allow_unsubscribe) !== null && _11 !== void 0 ? _11 : false,
                                }
                                : undefined, sms_template: templateType === "SMS"
                                ? {
                                    use_agent_phone_number: (_15 = (_13 = (_12 = prevState.template.sms_template) === null || _12 === void 0 ? void 0 : _12.use_agent_phone_number) !== null && _13 !== void 0 ? _13 : (_14 = prevState.template.sms_draft_template) === null || _14 === void 0 ? void 0 : _14.use_agent_phone_number) !== null && _15 !== void 0 ? _15 : true,
                                    from_phone: (_19 = (_17 = (_16 = prevState.template.sms_template) === null || _16 === void 0 ? void 0 : _16.from_phone) !== null && _17 !== void 0 ? _17 : (_18 = prevState.template.sms_draft_template) === null || _18 === void 0 ? void 0 : _18.from_phone) !== null && _19 !== void 0 ? _19 : null,
                                    to_phone: (_23 = (_21 = (_20 = prevState.template.sms_template) === null || _20 === void 0 ? void 0 : _20.to_phone) !== null && _21 !== void 0 ? _21 : (_22 = prevState.template.sms_draft_template) === null || _22 === void 0 ? void 0 : _22.to_phone) !== null && _23 !== void 0 ? _23 : "",
                                    message: (_31 = (_29 = (_27 = (_25 = (_24 = prevState.template.sms_template) === null || _24 === void 0 ? void 0 : _24.message) !== null && _25 !== void 0 ? _25 : (_26 = prevState.template.sms_draft_template) === null || _26 === void 0 ? void 0 : _26.message) !== null && _27 !== void 0 ? _27 : (_28 = prevState.template.slack_template) === null || _28 === void 0 ? void 0 : _28.message) !== null && _29 !== void 0 ? _29 : (_30 = prevState.template.streak_comment_template) === null || _30 === void 0 ? void 0 : _30.message) !== null && _31 !== void 0 ? _31 : "",
                                    send_as_group_text: (_33 = (_32 = prevState.template.sms_template) === null || _32 === void 0 ? void 0 : _32.send_as_group_text) !== null && _33 !== void 0 ? _33 : false,
                                }
                                : undefined, sms_draft_template: templateType === "SMS Draft"
                                ? {
                                    use_agent_phone_number: (_37 = (_35 = (_34 = prevState.template.sms_template) === null || _34 === void 0 ? void 0 : _34.use_agent_phone_number) !== null && _35 !== void 0 ? _35 : (_36 = prevState.template.sms_draft_template) === null || _36 === void 0 ? void 0 : _36.use_agent_phone_number) !== null && _37 !== void 0 ? _37 : true,
                                    from_phone: (_41 = (_39 = (_38 = prevState.template.sms_template) === null || _38 === void 0 ? void 0 : _38.from_phone) !== null && _39 !== void 0 ? _39 : (_40 = prevState.template.sms_draft_template) === null || _40 === void 0 ? void 0 : _40.from_phone) !== null && _41 !== void 0 ? _41 : null,
                                    to_phone: (_45 = (_43 = (_42 = prevState.template.sms_template) === null || _42 === void 0 ? void 0 : _42.to_phone) !== null && _43 !== void 0 ? _43 : (_44 = prevState.template.sms_draft_template) === null || _44 === void 0 ? void 0 : _44.to_phone) !== null && _45 !== void 0 ? _45 : "",
                                    message: (_53 = (_51 = (_49 = (_47 = (_46 = prevState.template.sms_template) === null || _46 === void 0 ? void 0 : _46.message) !== null && _47 !== void 0 ? _47 : (_48 = prevState.template.sms_draft_template) === null || _48 === void 0 ? void 0 : _48.message) !== null && _49 !== void 0 ? _49 : (_50 = prevState.template.slack_template) === null || _50 === void 0 ? void 0 : _50.message) !== null && _51 !== void 0 ? _51 : (_52 = prevState.template.streak_comment_template) === null || _52 === void 0 ? void 0 : _52.message) !== null && _53 !== void 0 ? _53 : "",
                                    drafted_by_type: "assigned agent",
                                    drafted_by: undefined,
                                }
                                : undefined, calendar_template: templateType === "Calendar"
                                ? {
                                    assigned_agent_is_host: true,
                                    host: null,
                                    guests: (_57 = (_55 = (_54 = prevState.template.email_template) === null || _54 === void 0 ? void 0 : _54.to) !== null && _55 !== void 0 ? _55 : (_56 = prevState.template.email_draft_template) === null || _56 === void 0 ? void 0 : _56.to) !== null && _57 !== void 0 ? _57 : "",
                                    title: (_61 = (_59 = (_58 = prevState.template.email_template) === null || _58 === void 0 ? void 0 : _58.subject) !== null && _59 !== void 0 ? _59 : (_60 = prevState.template.email_draft_template) === null || _60 === void 0 ? void 0 : _60.subject) !== null && _61 !== void 0 ? _61 : "",
                                    location: "",
                                    description: (_65 = (_63 = (_62 = prevState.template.email_template) === null || _62 === void 0 ? void 0 : _62.body) !== null && _63 !== void 0 ? _63 : (_64 = prevState.template.email_draft_template) === null || _64 === void 0 ? void 0 : _64.body) !== null && _65 !== void 0 ? _65 : "",
                                    start_date_offset: 0,
                                    start_date_field: this.dateFields.length > 0 ? this.dateFields[0].key : "",
                                    is_all_day_event: true,
                                    start_time_field: undefined,
                                    start_time_absolute: undefined,
                                    start_time_offset: undefined,
                                    duration: 60,
                                    reminder_email_minutes_before: null,
                                    reminder_popup_minutes_before: null,
                                    google_meet: true,
                                    color: "Pale Blue",
                                    allow_unsubscribe: (_69 = (_67 = (_66 = prevState.template.email_template) === null || _66 === void 0 ? void 0 : _66.allow_unsubscribe) !== null && _67 !== void 0 ? _67 : (_68 = prevState.template.email_draft_template) === null || _68 === void 0 ? void 0 : _68.allow_unsubscribe) !== null && _69 !== void 0 ? _69 : false,
                                }
                                : undefined, slack_template: templateType === "Slack"
                                ? {
                                    channel_id: prevState.slackChannels && prevState.slackChannels.length > 0 ? prevState.slackChannels[0].id : "",
                                    recipients: null,
                                    bot_name: "Dashbot",
                                    message: (_75 = (_73 = (_71 = (_70 = prevState.template.sms_template) === null || _70 === void 0 ? void 0 : _70.message) !== null && _71 !== void 0 ? _71 : (_72 = prevState.template.sms_draft_template) === null || _72 === void 0 ? void 0 : _72.message) !== null && _73 !== void 0 ? _73 : (_74 = prevState.template.streak_comment_template) === null || _74 === void 0 ? void 0 : _74.message) !== null && _75 !== void 0 ? _75 : "",
                                }
                                : undefined, streak_comment_template: templateType === "Streak Comment"
                                ? {
                                    use_agent_account: true,
                                    message: (_81 = (_79 = (_77 = (_76 = prevState.template.sms_template) === null || _76 === void 0 ? void 0 : _76.message) !== null && _77 !== void 0 ? _77 : (_78 = prevState.template.sms_draft_template) === null || _78 === void 0 ? void 0 : _78.message) !== null && _79 !== void 0 ? _79 : (_80 = prevState.template.slack_template) === null || _80 === void 0 ? void 0 : _80.message) !== null && _81 !== void 0 ? _81 : "",
                                }
                                : undefined }),
                    });
                });
            }
        });
        Object.defineProperty(this, "setTemplateName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { name: e.target.value }),
                }));
            }
        });
        Object.defineProperty(this, "onAgentFieldChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, agent, reason) => {
                console.log("SET AGENT TO ", agent, "REASON:", reason);
                const newValue = agent == null ? null : agent.uid;
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { assigned_agent: reason === "selectOption" ? newValue : null }),
                }));
            }
        });
        Object.defineProperty(this, "setFromType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { sender_type: e.target.value, sender: undefined }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { sender_type: e.target.value, sender: undefined }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setFrom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { sender: newValue }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { sender: newValue }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setFromAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { streak_comment_template: Object.assign(Object.assign({}, prevState.template.streak_comment_template), { use_agent_account: !e.target.value, from_agent: e.target.value ? e.target.value : null }) }),
                }));
            }
        });
        Object.defineProperty(this, "setHost", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { assigned_agent_is_host: newValue.trim() !== "", host: newValue.trim() === "" ? null : newValue }) }),
                }));
            }
        });
        Object.defineProperty(this, "setTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { to: newValue }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { to: newValue }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setCC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { cc: newValue }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { cc: newValue }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setBCC", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { bcc: newValue }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { bcc: newValue }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setSubject", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { subject: newValue }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { subject: newValue }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setAllowUnsubscribe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, isChecked) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { allow_unsubscribe: isChecked }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { allow_unsubscribe: isChecked }) : undefined, calendar_template: prevState.template.calendar_template
                            ? Object.assign(Object.assign({}, prevState.template.calendar_template), { allow_unsubscribe: isChecked }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setGuests", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { guests: newValue }) }),
                }));
            }
        });
        Object.defineProperty(this, "setTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { title: newValue }) }),
                }));
            }
        });
        Object.defineProperty(this, "setLocation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { location: newValue }) }),
                }));
            }
        });
        Object.defineProperty(this, "setStartDateOffset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (number) => {
                this.setState((prevState) => {
                    var _a;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { start_date_offset: number
                                    ? number * (((_a = prevState.template.calendar_template.start_date_offset) !== null && _a !== void 0 ? _a : 0 < 0) ? -1 : 1)
                                    : 0 }) }),
                    });
                });
            }
        });
        Object.defineProperty(this, "setStartDateOffsetComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => {
                    var _a;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { start_date_offset: Math.abs((_a = prevState.template.calendar_template.start_date_offset) !== null && _a !== void 0 ? _a : 0) *
                                    (e.target.value === "before" ? -1 : 1) }) }),
                    });
                });
            }
        });
        Object.defineProperty(this, "setStartDateField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { start_date_field: e.target.value }) }),
                }));
            }
        });
        Object.defineProperty(this, "setIsAllDayEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { is_all_day_event: checked, start_time_field: undefined, start_time_offset: undefined, start_time_absolute: undefined, duration: checked ? undefined : 60 }) }),
                }));
            }
        });
        Object.defineProperty(this, "setUseStreakFieldForStartTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { start_time_field: checked && this.timeFields.length > 0 ? this.timeFields[0].key : undefined, start_time_offset: 0, start_time_absolute: undefined }) }),
                }));
            }
        });
        Object.defineProperty(this, "setStartTimeField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { start_time_field: e.target.value }) }),
                }));
            }
        });
        Object.defineProperty(this, "setStartTimeAbsolute", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (date) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { start_time_absolute: date !== null && date !== void 0 ? date : undefined }) }),
                }));
            }
        });
        Object.defineProperty(this, "setDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (number) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { duration: number !== null && number !== void 0 ? number : 60 }) }),
                }));
            }
        });
        Object.defineProperty(this, "setReminderEmailMinutesBefore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { reminder_email_minutes_before: e.target.value ? numeral(e.target.value).value() : null }) }),
                }));
            }
        });
        Object.defineProperty(this, "setReminderPopupMinutesBefore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { reminder_popup_minutes_before: e.target.value ? numeral(e.target.value).value() : null }) }),
                }));
            }
        });
        Object.defineProperty(this, "setEventColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { color: e.target.value }) }),
                }));
            }
        });
        Object.defineProperty(this, "setGoogleMeet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { google_meet: checked }) }),
                }));
            }
        });
        Object.defineProperty(this, "setFromPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const updates = {
                    from_phone: e.target.value !== "" ? e.target.value : undefined,
                    use_agent_phone_number: e.target.value === "",
                };
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { sms_template: prevState.template.sms_template
                            ? Object.assign(Object.assign({}, prevState.template.sms_template), updates) : undefined, sms_draft_template: prevState.template.sms_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.sms_draft_template), updates) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setToPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                const updates = {
                    to_phone: newValue,
                };
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { sms_template: prevState.template.sms_template
                            ? Object.assign(Object.assign({}, prevState.template.sms_template), updates) : undefined, sms_draft_template: prevState.template.sms_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.sms_draft_template), updates) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setSendAsGroupMMS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, checked) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { sms_template: Object.assign(Object.assign({}, prevState.template.sms_template), { send_as_group_text: checked }) }),
                }));
            }
        });
        Object.defineProperty(this, "setDraftedByType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { sms_draft_template: Object.assign(Object.assign({}, prevState.template.sms_draft_template), { drafted_by_type: e.target.value, drafted_by: e.target.value === "assigned agent" ? undefined : "" }) }),
                }));
            }
        });
        Object.defineProperty(this, "setDraftedBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { sms_draft_template: Object.assign(Object.assign({}, prevState.template.sms_draft_template), { drafted_by: newValue }) }),
                }));
            }
        });
        Object.defineProperty(this, "setSlackChannel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { slack_template: Object.assign(Object.assign({}, prevState.template.slack_template), { channel_id: e.target.value === "" ? null : e.target.value, recipients: null }) }),
                }));
            }
        });
        Object.defineProperty(this, "setSlackRecipients", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, newValue) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { slack_template: Object.assign(Object.assign({}, prevState.template.slack_template), { channel_id: null, recipients: newValue !== null && newValue !== void 0 ? newValue : null }) }),
                }));
            }
        });
        Object.defineProperty(this, "setBotName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { slack_template: Object.assign(Object.assign({}, prevState.template.slack_template), { bot_name: e.target.value }) }),
                }));
            }
        });
        Object.defineProperty(this, "setEarliestProcessTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { earliest_process_time: e.target.value || null }),
                }));
            }
        });
        Object.defineProperty(this, "setLatestProcessTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { latest_process_time: e.target.value || null }),
                }));
            }
        });
        Object.defineProperty(this, "setResendMaximum", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (number) => {
                this.setState((prevState) => {
                    var _a, _b;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { resend_maximum: number, resend_frequency: number === 1 ? undefined : ((_a = prevState.template.resend_frequency) !== null && _a !== void 0 ? _a : 1), resend_unit: number === 1 ? undefined : ((_b = prevState.template.resend_unit) !== null && _b !== void 0 ? _b : "day") }),
                    });
                });
            }
        });
        Object.defineProperty(this, "setResendFrequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (number) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { resend_frequency: number && number > 0 ? number : 1 }),
                }));
            }
        });
        Object.defineProperty(this, "setResendUnit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => {
                    var _a;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { resend_unit: (_a = e.target.value) !== null && _a !== void 0 ? _a : "day" }),
                    });
                });
            }
        });
        Object.defineProperty(this, "setTimestampStreakField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.setState((prevState) => {
                    var _a;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { timestamp_streak_field_key: (_a = e.target.value) !== null && _a !== void 0 ? _a : null }),
                    });
                });
            }
        });
        Object.defineProperty(this, "setReviewFrequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, value) => {
                let val = null;
                if (value === 1) {
                    val = "Quarterly";
                }
                else if (value === 2) {
                    val = "Annual";
                }
                else if (value !== 0) {
                    throw new Error(`Unexpected value ${value}`);
                }
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { review_frequency: val }),
                }));
            }
        });
        Object.defineProperty(this, "setMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e, newValue, newPlainTextValue, mentions) => {
                console.log("Setting Mentions Input Message", e, newValue, "-----", newPlainTextValue, mentions);
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { sms_template: prevState.template.sms_template
                            ? Object.assign(Object.assign({}, prevState.template.sms_template), { message: newValue }) : undefined, sms_draft_template: prevState.template.sms_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.sms_draft_template), { message: newValue }) : undefined, slack_template: prevState.template.slack_template
                            ? Object.assign(Object.assign({}, prevState.template.slack_template), { message: newValue }) : undefined, streak_comment_template: prevState.template.streak_comment_template
                            ? Object.assign(Object.assign({}, prevState.template.streak_comment_template), { message: newValue }) : undefined }),
                    messagePlainText: newPlainTextValue,
                }));
            }
        });
        Object.defineProperty(this, "setBody", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (body) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { email_template: prevState.template.email_template
                            ? Object.assign(Object.assign({}, prevState.template.email_template), { body }) : undefined, email_draft_template: prevState.template.email_draft_template
                            ? Object.assign(Object.assign({}, prevState.template.email_draft_template), { body }) : undefined }),
                }));
            }
        });
        Object.defineProperty(this, "setDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (description) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { calendar_template: Object.assign(Object.assign({}, prevState.template.calendar_template), { description }) }),
                }));
            }
        });
        Object.defineProperty(this, "addTrigger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { triggers: [
                            ...prevState.template.triggers,
                            {
                                stage_trigger: {
                                    from_stage_key: null,
                                    to_stage_key: null,
                                },
                            },
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "deleteTrigger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { triggers: prevState.template.triggers.filter((_, i) => i !== indx) }),
                }));
            }
        });
        Object.defineProperty(this, "addCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll) => () => {
                this.setState((prevState) => {
                    var _a, _b, _c;
                    return ({
                        template: Object.assign(Object.assign({}, prevState.template), { criteria: [
                                ...prevState.template.criteria,
                                {
                                    is_in_match_all_group: matchAll,
                                    text_criteria: {
                                        field_key_lhs: (_c = (_b = (_a = prevState
                                            .pipelines.find((pipeline) => pipeline.key === prevState.template.pipeline)) === null || _a === void 0 ? void 0 : _a.fields.find((field) => getFieldType(field) === "TEXT_INPUT")) === null || _b === void 0 ? void 0 : _b.key) !== null && _c !== void 0 ? _c : "",
                                        comparison: "is equal to",
                                        value_rhs: "",
                                    },
                                },
                            ] }),
                    });
                });
            }
        });
        Object.defineProperty(this, "deleteCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { criteria: [
                            ...prevState
                                .template.criteria.filter((criteria) => criteria.is_in_match_all_group === matchAll)
                                .filter((_, i) => i !== indx),
                            ...prevState.template.criteria.filter((criteria) => criteria.is_in_match_all_group !== matchAll),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "getCriterion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (criteria, matchAll, indx) => {
                const matchAllCriteria = criteria.filter((c) => c.is_in_match_all_group);
                const matchAnyCriteria = criteria.filter((c) => !c.is_in_match_all_group);
                const criterion = matchAll ? matchAllCriteria[indx] : matchAnyCriteria[indx];
                return criterion;
            }
        });
        Object.defineProperty(this, "getUpdatedStateFromCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (state, matchAll, indx, updatedCriteria) => {
                const { template } = state;
                const { criteria } = template;
                const matchAllCriteria = criteria.filter((c) => c.is_in_match_all_group);
                const matchAnyCriteria = criteria.filter((c) => !c.is_in_match_all_group);
                if (matchAll) {
                    return {
                        template: Object.assign(Object.assign({}, template), { criteria: [
                                ...matchAllCriteria.slice(0, indx),
                                updatedCriteria,
                                ...matchAllCriteria.slice(indx + 1, matchAllCriteria.length),
                                ...matchAnyCriteria,
                            ] }),
                    };
                }
                return {
                    template: Object.assign(Object.assign({}, template), { criteria: [
                            ...matchAllCriteria,
                            ...matchAnyCriteria.slice(0, indx),
                            updatedCriteria,
                            ...matchAnyCriteria.slice(indx + 1, matchAnyCriteria.length),
                        ] }),
                };
            }
        });
        Object.defineProperty(this, "setCriteriaType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const { uid } = this.props;
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const field = this.selectedPipeline.fields[0];
                    const fieldType = getFieldType(field);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { stage_criteria: e.target.value === "stage"
                            ? {
                                stage_key: this.selectedPipeline.stageOrder[0],
                                comparison: "equal to",
                            }
                            : undefined, view_criteria: e.target.value === "view"
                            ? {
                                view_keys: [],
                                comparison: "is in any of",
                            }
                            : undefined, text_criteria: e.target.value === "field" && fieldType === "TEXT_INPUT"
                            ? {
                                field_key_lhs: field.key,
                                comparison: "is equal to",
                                value_rhs: "",
                            }
                            : undefined, date_criteria: e.target.value === "field" && fieldType === "DATE"
                            ? {
                                field_key_lhs: field.key,
                                comparison: "is equal to or before",
                                interval: 0,
                                units: "day",
                            }
                            : undefined, dropdown_criteria: e.target.value === "field" && fieldType === "DROPDOWN"
                            ? {
                                field_key: field.key,
                                comparison: "is in",
                                dropdown_item_keys: [],
                            }
                            : undefined, tag_criteria: e.target.value === "field" && fieldType === "TAG"
                            ? {
                                field_key: field.key,
                                comparison: "includes any of",
                                tag_keys: [],
                            }
                            : undefined, checkbox_criteria: e.target.value === "field" && fieldType === "CHECKBOX"
                            ? {
                                field_key: field.key,
                                comparison: "is checked",
                            }
                            : undefined, template_criteria: e.target.value === "template"
                            ? {
                                template: prevState.templates.find((thisTemplate) => thisTemplate.pipeline === this.selectedPipeline.key && thisTemplate.uid !== uid).uid,
                                value: "has been sent",
                                delay_mode: "at least",
                                delay_unit: "hours",
                                delay_qty: 1,
                            }
                            : undefined });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateFieldCriteriaFieldKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const field = this.selectedPipeline.fields.find((f) => f.key === e.target.value);
                    const fieldType = getFieldType(field);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { stage_criteria: undefined, text_criteria: fieldType === "TEXT_INPUT"
                            ? Object.assign(Object.assign({}, criterion.text_criteria), { field_key_lhs: field.key, comparison: criterion.text_criteria ? criterion.text_criteria.comparison : "is equal to", value_rhs: criterion.text_criteria ? criterion.text_criteria.value_rhs : "" }) : undefined, date_criteria: fieldType === "DATE"
                            ? Object.assign(Object.assign({}, criterion.date_criteria), { field_key_lhs: field.key, comparison: criterion.date_criteria ? criterion.date_criteria.comparison : "is equal to or before", interval: criterion.date_criteria ? criterion.date_criteria.interval : 0, units: criterion.date_criteria ? criterion.date_criteria.units : "day" }) : undefined, dropdown_criteria: fieldType === "DROPDOWN"
                            ? Object.assign(Object.assign({}, criterion.dropdown_criteria), { field_key: field.key, comparison: criterion.dropdown_criteria ? criterion.dropdown_criteria.comparison : "is in", dropdown_item_keys: criterion.dropdown_criteria ? criterion.dropdown_criteria.dropdown_item_keys : [] }) : undefined, tag_criteria: fieldType === "TAG"
                            ? Object.assign(Object.assign({}, criterion.tag_criteria), { field_key: field.key, comparison: criterion.tag_criteria ? criterion.tag_criteria.comparison : "includes any of", tag_keys: criterion.tag_criteria ? criterion.tag_criteria.tag_keys : [] }) : undefined, checkbox_criteria: fieldType === "CHECKBOX"
                            ? Object.assign(Object.assign({}, criterion.checkbox_criteria), { field_key: field.key, comparison: criterion.checkbox_criteria ? criterion.checkbox_criteria.comparison : "is checked" }) : undefined, template_criteria: undefined });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateStageCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { stage_criteria: Object.assign(Object.assign({}, criterion.stage_criteria), { comparison: e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateStageCriteriaStageKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { stage_criteria: Object.assign(Object.assign({}, criterion.stage_criteria), { stage_key: e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateViewCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { view_criteria: Object.assign(Object.assign({}, criterion.view_criteria), { comparison: e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateViewCriteriaViews", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (viewKeys) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { view_criteria: Object.assign(Object.assign({}, criterion.view_criteria), { view_keys: viewKeys }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTemplateCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { template_criteria: Object.assign(Object.assign({}, criterion.template_criteria), { value: e.target.value, delay_mode: e.target.value === "has been sent" ? "at least" : null, delay_qty: e.target.value === "has been sent" ? 1 : null, delay_unit: e.target.value === "has been sent" ? "hours" : null }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTemplateCriteriaDelayMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { template_criteria: Object.assign(Object.assign({}, criterion.template_criteria), { delay_mode: e.target.value === ""
                                ? null
                                : e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTemplateCriteriaDelayQty", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (qty) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { template_criteria: Object.assign(Object.assign({}, criterion.template_criteria), { delay_qty: qty }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTemplateCriteriaDelayUnit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { template_criteria: Object.assign(Object.assign({}, criterion.template_criteria), { delay_unit: e.target.value === ""
                                ? null
                                : e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTemplateCriteriaTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { template_criteria: Object.assign(Object.assign({}, criterion.template_criteria), { template: e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTextCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { text_criteria: Object.assign(Object.assign({}, criterion.text_criteria), { comparison: e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTextCriteriaFieldKeyRHS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { text_criteria: Object.assign(Object.assign({}, criterion.text_criteria), { field_key_rhs: e.target.value || "", value_rhs: e.target.value ? undefined : "" }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTextCriteriaValueRHS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { text_criteria: Object.assign(Object.assign({}, criterion.text_criteria), { field_key_rhs: undefined, value_rhs: e.target.value }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDropdownCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const comparison = e.target.value;
                this.setState((prevState) => {
                    var _a;
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { dropdown_criteria: Object.assign(Object.assign({}, criterion.dropdown_criteria), { comparison, dropdown_item_keys: ["is blank", "is not blank"].includes(comparison)
                                ? undefined
                                : ((_a = criterion.dropdown_criteria.dropdown_item_keys) !== null && _a !== void 0 ? _a : []) }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDropdownCriteriaDropdownItemKeys", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (choices) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { dropdown_criteria: Object.assign(Object.assign({}, criterion.dropdown_criteria), { dropdown_item_keys: choices }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTagCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const comparison = e.target.value;
                this.setState((prevState) => {
                    var _a;
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { tag_criteria: Object.assign(Object.assign({}, criterion.tag_criteria), { comparison, tag_keys: ["is blank", "is not blank"].includes(comparison)
                                ? undefined
                                : ((_a = criterion.tag_criteria.tag_keys) !== null && _a !== void 0 ? _a : []) }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateTagCriteriaTagKeys", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (choices) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { tag_criteria: Object.assign(Object.assign({}, criterion.tag_criteria), { tag_keys: choices }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateCheckboxCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const comparison = e.target.value;
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { checkbox_criteria: Object.assign(Object.assign({}, criterion.checkbox_criteria), { comparison }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDateCriteriaIntervalMultiplier", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const multiplier = parseFloat(e.target.value);
                this.setState((prevState) => {
                    var _a;
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { date_criteria: Object.assign(Object.assign({}, criterion.date_criteria), { interval: Math.abs((_a = criterion.date_criteria.interval) !== null && _a !== void 0 ? _a : 0) * multiplier }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDateCriteriaInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (interval) => {
                this.setState((prevState) => {
                    var _a;
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { date_criteria: Object.assign(Object.assign({}, criterion.date_criteria), { interval: interval ? Math.abs(interval) * (((_a = criterion.date_criteria.interval) !== null && _a !== void 0 ? _a : 0 < 0) ? -1 : 1) : 0 }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDateCriteriaUnits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const units = e.target.value;
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { date_criteria: Object.assign(Object.assign({}, criterion.date_criteria), { units }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDateCriteriaComparison", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                const comparison = e.target.value;
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { date_criteria: Object.assign(Object.assign({}, criterion.date_criteria), { comparison }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDateCriteriaFieldKeyRHS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (e) => {
                this.setState((prevState) => {
                    var _a, _b;
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { date_criteria: Object.assign(Object.assign({}, criterion.date_criteria), { field_key_rhs: e.target.value !== "specific date" ? e.target.value : undefined, value_rhs: e.target.value !== "specific date" ? undefined : dayjs(new Date()).format("YYYY-MM-DD"), interval: e.target.value !== "specific date" ? ((_a = criterion.date_criteria.interval) !== null && _a !== void 0 ? _a : 0) : undefined, units: e.target.value !== "specific date" ? ((_b = criterion.date_criteria.units) !== null && _b !== void 0 ? _b : "day") : undefined }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "updateDateCriteriaValueRHS", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll, indx) => (date) => {
                this.setState((prevState) => {
                    const criterion = this.getCriterion(prevState.template.criteria, matchAll, indx);
                    const updatedCriterion = Object.assign(Object.assign({}, criterion), { date_criteria: Object.assign(Object.assign({}, criterion.date_criteria), { field_key_rhs: date === null ? undefined : criterion.date_criteria.field_key_rhs, value_rhs: date !== null && date !== void 0 ? date : undefined, interval: date ? undefined : 0, units: date ? undefined : "day" }) });
                    return this.getUpdatedStateFromCriteria(prevState, matchAll, indx, updatedCriterion);
                });
            }
        });
        Object.defineProperty(this, "setTriggerType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { triggers: [
                            ...prevState.template.triggers.slice(0, indx),
                            Object.assign(Object.assign({}, prevState.template.triggers[indx]), { field_trigger: e.target.value === "field"
                                    ? {
                                        field_key: null,
                                    }
                                    : undefined, stage_trigger: e.target.value === "stage"
                                    ? {
                                        from_stage_key: null,
                                        to_stage_key: null,
                                    }
                                    : undefined }),
                            ...prevState.template.triggers.slice(indx + 1, prevState.template.triggers.length),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "setTriggerFromStage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { triggers: [
                            ...prevState.template.triggers.slice(0, indx),
                            Object.assign(Object.assign({}, prevState.template.triggers[indx]), { stage_trigger: Object.assign(Object.assign({}, prevState.template.triggers[indx].stage_trigger), { from_stage_key: e.target.value || null }) }),
                            ...prevState.template.triggers.slice(indx + 1, prevState.template.triggers.length),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "setTriggerToStage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { triggers: [
                            ...prevState.template.triggers.slice(0, indx),
                            Object.assign(Object.assign({}, prevState.template.triggers[indx]), { stage_trigger: Object.assign(Object.assign({}, prevState.template.triggers[indx].stage_trigger), { to_stage_key: e.target.value || null }) }),
                            ...prevState.template.triggers.slice(indx + 1, prevState.template.triggers.length),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "setTriggerField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { triggers: [
                            ...prevState.template.triggers.slice(0, indx),
                            Object.assign(Object.assign({}, prevState.template.triggers[indx]), { field_trigger: Object.assign(Object.assign({}, prevState.template.triggers[indx].field_trigger), { field_key: e.target.value || null }) }),
                            ...prevState.template.triggers.slice(indx + 1, prevState.template.triggers.length),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "setSelectedBoxKeyForPreview", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (boxKey) => {
                this.setState({
                    selectedBoxKeyForPreview: boxKey,
                });
            }
        });
        Object.defineProperty(this, "openPreview", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isPreviewOpen: true,
                });
            }
        });
        Object.defineProperty(this, "handlePreviewClosed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isPreviewOpen: false,
                });
            }
        });
        Object.defineProperty(this, "handleTemplateProcessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                enqueueSnackbar("Template processed successfully", { variant: "success" });
                this.handlePreviewClosed();
            }
        });
        Object.defineProperty(this, "checkWildcards", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value, checkEmailAddresses = false) => {
                const { selectedPipeline } = this;
                if (!selectedPipeline) {
                    return null;
                }
                const { fields } = selectedPipeline;
                const matches = (value !== null && value !== void 0 ? value : "").match(/\$\'(.*?)\'/g);
                if (!matches) {
                    return null;
                }
                console.log(checkEmailAddresses); // TODO: I feel like we need this for something, but not sure what
                console.log("matches", matches);
                const errorFields = [];
                for (const match of matches) {
                    const fieldNameMatch = match.match(/\$\'(.*?)\'/)[1];
                    if (!fields.find((field) => fieldNameMatch === field.name)) {
                        errorFields.push(fieldNameMatch);
                    }
                }
                if (errorFields.length === 0) {
                    return null;
                }
                return `Field${errorFields.length > 1 ? "s" : ""} ${errorFields.join(", ")} not found in pipeline ${selectedPipeline.name}. Make sure the field exists with the same capitalization and spacing as you've written here.`;
            }
        });
        Object.defineProperty(this, "renderCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (matchAll) => {
                const { uid } = this.props;
                const { checkboxCriteriaComparisonTypes, dateCriteriaComparisonTypes, dateOffsetUnits, dropdownCriteriaComparisonTypes, stageCriteriaComparisonTypes, tagCriteriaComparisonTypes, template, templateCriteriaComparisonTypes, templateCriteriaDelayModes, templateCriteriaDelayUnits, templates, textCriteriaComparisonTypes, viewCriteriaComparisonTypes, views, } = this.state;
                if (!textCriteriaComparisonTypes ||
                    !dateCriteriaComparisonTypes ||
                    !tagCriteriaComparisonTypes ||
                    !dropdownCriteriaComparisonTypes ||
                    !checkboxCriteriaComparisonTypes ||
                    !templateCriteriaComparisonTypes ||
                    !stageCriteriaComparisonTypes ||
                    !viewCriteriaComparisonTypes ||
                    !dateOffsetUnits ||
                    !templates) {
                    return null;
                }
                return (React.createElement(Grid, { item: true, container: true, xs: 12, style: { padding: "4px" } }, template === null || template === void 0 ? void 0 : template.criteria.filter((criteria) => criteria.is_in_match_all_group === matchAll).map((criteria, indx) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
                    let criteriaType;
                    if (criteria.stage_criteria) {
                        criteriaType = "stage";
                    }
                    else if (criteria.template_criteria) {
                        criteriaType = "template";
                    }
                    else if (criteria.view_criteria) {
                        criteriaType = "view";
                    }
                    else {
                        criteriaType = "field";
                    }
                    return (React.createElement(Grid, { key: `matchall_${indx}`, container: true, item: true, xs: 12, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Tooltip, { title: "Delete criteria" },
                                React.createElement(IconButton, { size: "small", onClick: this.deleteCriteria(matchAll, indx) },
                                    React.createElement(Delete, { fontSize: "small" })))),
                        React.createElement(Grid, { item: true, style: { width: "200px", marginTop: "auto" } },
                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteriaType, onChange: this.setCriteriaType(matchAll, indx) }, [
                                { name: "stage", label: "Stage" },
                                { name: "field", label: "Field" },
                                { name: "view", label: "View" },
                                { name: "template", label: "Mail Schedule Template" },
                            ].map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.label))))),
                        React.createElement(Grid, { item: true, style: { flexGrow: 1, marginTop: "auto" } },
                            criteria.stage_criteria && (React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 6, style: { marginTop: "auto" } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.stage_criteria.comparison, onChange: this.updateStageCriteriaComparison(matchAll, indx) }, (stageCriteriaComparisonTypes !== null && stageCriteriaComparisonTypes !== void 0 ? stageCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                React.createElement(Grid, { item: true, xs: 6, style: { marginTop: "auto" } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.stage_criteria.stage_key, onChange: this.updateStageCriteriaStageKey(matchAll, indx) }, (_a = this.selectedPipelineStages) === null || _a === void 0 ? void 0 : _a.map((stage) => (React.createElement(MenuItem, { key: stage.key, value: stage.key }, stage.name))))))),
                            criteria.view_criteria && (React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 6, style: { marginTop: "auto" } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.view_criteria.comparison, onChange: this.updateViewCriteriaComparison(matchAll, indx) }, (viewCriteriaComparisonTypes !== null && viewCriteriaComparisonTypes !== void 0 ? viewCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                React.createElement(Grid, { item: true, xs: 6, style: { marginTop: "auto" } },
                                    React.createElement(CoreChoiceField, { variant: "standard", multiple: true, fullWidth: true, size: "small", value: criteria.view_criteria.view_keys, onChoicesChange: this.updateViewCriteriaViews(matchAll, indx), options: (views !== null && views !== void 0 ? views : [])
                                            .filter((view) => { var _a; return view.pipeline_key === ((_a = this.selectedPipeline) === null || _a === void 0 ? void 0 : _a.key); })
                                            .reduce((dict, obj) => (Object.assign(Object.assign({}, dict), { [obj.viewKey]: obj.name })), {}) }, (views !== null && views !== void 0 ? views : [])
                                        .filter((view) => { var _a; return view.pipeline_key === ((_a = this.selectedPipeline) === null || _a === void 0 ? void 0 : _a.key); })
                                        .map((view) => (React.createElement(MenuItem, { key: view.viewKey, value: view.viewKey }, view.name))))))),
                            this.selectedPipeline &&
                                (criteria.text_criteria ||
                                    criteria.dropdown_criteria ||
                                    criteria.tag_criteria ||
                                    criteria.date_criteria ||
                                    criteria.checkbox_criteria) && (React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 3 },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: (_l = (_j = (_g = (_e = (_c = (_b = criteria.text_criteria) === null || _b === void 0 ? void 0 : _b.field_key_lhs) !== null && _c !== void 0 ? _c : (_d = criteria.date_criteria) === null || _d === void 0 ? void 0 : _d.field_key_lhs) !== null && _e !== void 0 ? _e : (_f = criteria.dropdown_criteria) === null || _f === void 0 ? void 0 : _f.field_key) !== null && _g !== void 0 ? _g : (_h = criteria.tag_criteria) === null || _h === void 0 ? void 0 : _h.field_key) !== null && _j !== void 0 ? _j : (_k = criteria.checkbox_criteria) === null || _k === void 0 ? void 0 : _k.field_key) !== null && _l !== void 0 ? _l : "", onChange: this.updateFieldCriteriaFieldKey(matchAll, indx), SelectProps: {
                                            displayEmpty: true,
                                        } }, this.selectedPipeline.fields
                                        .sort((a, b) => {
                                        if (a.name < b.name)
                                            return -1;
                                        if (a.name > b.name)
                                            return 1;
                                        return 0;
                                    })
                                        .map((field) => (React.createElement(MenuItem, { key: field.key, value: field.key }, field.name))))),
                                criteria.text_criteria && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.text_criteria.comparison, onChange: this.updateTextCriteriaComparison(matchAll, indx) }, (textCriteriaComparisonTypes !== null && textCriteriaComparisonTypes !== void 0 ? textCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                    React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: (_m = criteria.text_criteria.field_key_rhs) !== null && _m !== void 0 ? _m : "", onChange: this.updateTextCriteriaFieldKeyRHS(matchAll, indx), SelectProps: {
                                                displayEmpty: true,
                                            } },
                                            this.selectedPipeline.fields
                                                .sort((a, b) => {
                                                if (a.name < b.name)
                                                    return -1;
                                                if (a.name > b.name)
                                                    return 1;
                                                return 0;
                                            })
                                                .filter((field) => getFieldType(field) === "TEXT_INPUT")
                                                .map((obj) => (React.createElement(MenuItem, { key: obj.key, value: obj.key }, obj.name))),
                                            React.createElement(MenuItem, { value: "" }, "constant value"))),
                                    !criteria.text_criteria.field_key_rhs && (React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(TextField, { variant: "standard", fullWidth: true, size: "small", value: (_o = criteria.text_criteria.value_rhs) !== null && _o !== void 0 ? _o : "", onChange: this.updateTextCriteriaValueRHS(matchAll, indx), placeholder: "Enter a value" }))))),
                                criteria.dropdown_criteria && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.dropdown_criteria.comparison, onChange: this.updateDropdownCriteriaComparison(matchAll, indx) }, (dropdownCriteriaComparisonTypes !== null && dropdownCriteriaComparisonTypes !== void 0 ? dropdownCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                    !["is blank", "is not blank"].includes(criteria.dropdown_criteria.comparison) && (React.createElement(Grid, { item: true, xs: 6 },
                                        React.createElement(CoreChoiceField, { variant: "standard", multiple: true, fullWidth: true, size: "small", value: (_p = criteria.dropdown_criteria.dropdown_item_keys) !== null && _p !== void 0 ? _p : [], onChoicesChange: this.updateDropdownCriteriaDropdownItemKeys(matchAll, indx), SelectProps: {
                                                style: {
                                                    marginTop: 0,
                                                },
                                            }, options: this.selectedPipeline.fields
                                                .find((field) => field.key === criteria.dropdown_criteria.field_key)
                                                .dropdownSettings.items.map((obj) => ({
                                                value: obj.key,
                                                label: obj.name,
                                            }))
                                                .reduce((dict, obj) => (Object.assign(Object.assign({}, dict), { [obj.value]: obj.label })), {}) }, this.selectedPipeline.fields
                                            .find((field) => field.key === criteria.dropdown_criteria.field_key)
                                            .dropdownSettings.items.map((obj) => (React.createElement(MenuItem, { key: obj.key, value: obj.key }, obj.name)))))))),
                                criteria.tag_criteria && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.tag_criteria.comparison, onChange: this.updateTagCriteriaComparison(matchAll, indx) }, (tagCriteriaComparisonTypes !== null && tagCriteriaComparisonTypes !== void 0 ? tagCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                    !["is blank", "is not blank"].includes(criteria.tag_criteria.comparison) && (React.createElement(Grid, { item: true, xs: 6 },
                                        React.createElement(CoreChoiceField, { variant: "standard", multiple: true, fullWidth: true, size: "small", value: (_q = criteria.tag_criteria.tag_keys) !== null && _q !== void 0 ? _q : [], onChoicesChange: this.updateTagCriteriaTagKeys(matchAll, indx), SelectProps: {
                                                style: {
                                                    marginTop: 0,
                                                },
                                            }, options: (_t = (_s = (_r = this.selectedPipeline.fields
                                                .find((field) => field.key === criteria.tag_criteria.field_key)) === null || _r === void 0 ? void 0 : _r.tagSettings) === null || _s === void 0 ? void 0 : _s.tags.map((obj) => ({
                                                value: obj.key,
                                                label: obj.tag,
                                            })).reduce((dict, obj) => (Object.assign(Object.assign({}, dict), { [obj.value]: obj.label })), {})) !== null && _t !== void 0 ? _t : [] }, (_v = (_u = this.selectedPipeline.fields
                                            .find((field) => field.key === criteria.tag_criteria.field_key)) === null || _u === void 0 ? void 0 : _u.tagSettings) === null || _v === void 0 ? void 0 : _v.tags.map((obj) => (React.createElement(MenuItem, { key: obj.key, value: obj.key }, obj.tag)))))))),
                                criteria.checkbox_criteria && (React.createElement(Grid, { item: true, xs: 9 },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.checkbox_criteria.comparison, onChange: this.updateCheckboxCriteriaComparison(matchAll, indx) }, (checkboxCriteriaComparisonTypes !== null && checkboxCriteriaComparisonTypes !== void 0 ? checkboxCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name)))))),
                                criteria.date_criteria && (React.createElement(Grid, { item: true, xs: 9 },
                                    React.createElement(Grid, { container: true, spacing: 1 },
                                        React.createElement(Grid, { item: true, style: { flex: 1 } },
                                            React.createElement(Grid, { container: true, spacing: 1 },
                                                React.createElement(Grid, { item: true, xs: ["is blank", "is not blank"].includes((_w = criteria.date_criteria.comparison) !== null && _w !== void 0 ? _w : "")
                                                        ? 12
                                                        : 3 },
                                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.date_criteria.comparison, onChange: this.updateDateCriteriaComparison(matchAll, indx) }, (dateCriteriaComparisonTypes !== null && dateCriteriaComparisonTypes !== void 0 ? dateCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                                !["is blank", "is not blank"].includes((_x = criteria.date_criteria.comparison) !== null && _x !== void 0 ? _x : "") &&
                                                    !criteria.date_criteria.value_rhs && (React.createElement(React.Fragment, null,
                                                    React.createElement(Grid, { item: true, xs: 1 },
                                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: Math.abs((_y = criteria.date_criteria.interval) !== null && _y !== void 0 ? _y : 0), onNumberChange: this.updateDateCriteriaInterval(matchAll, indx), format: "0,0", min: 0, max: 999 })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.date_criteria.units, onChange: this.updateDateCriteriaUnits(matchAll, indx) }, dateOffsetUnits
                                                            .filter((obj) => obj.name !== "hour") // exclude "hour" offset unit... this is only valid for resending, not comparing dates
                                                            .map((obj) => {
                                                            var _a;
                                                            return (React.createElement(MenuItem, { key: obj.name, value: obj.name }, Math.abs((_a = criteria.date_criteria.interval) !== null && _a !== void 0 ? _a : 0) === 1
                                                                ? obj.name
                                                                : `${obj.name}s`));
                                                        }))),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: ((_z = criteria.date_criteria.interval) !== null && _z !== void 0 ? _z : 0) < 0 ? -1 : 1, onChange: this.updateDateCriteriaIntervalMultiplier(matchAll, indx) },
                                                            React.createElement(MenuItem, { value: -1 }, "before"),
                                                            React.createElement(MenuItem, { value: 1 }, "after"))))),
                                                !["is blank", "is not blank"].includes((_0 = criteria.date_criteria.comparison) !== null && _0 !== void 0 ? _0 : "") && (React.createElement(React.Fragment, null,
                                                    !criteria.date_criteria.value_rhs && (React.createElement(Grid, { item: true, xs: criteria.date_criteria.value_rhs ? 3 : 4 },
                                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.date_criteria.value_rhs
                                                                ? "specific date"
                                                                : ((_1 = criteria.date_criteria.field_key_rhs) !== null && _1 !== void 0 ? _1 : ""), onChange: this.updateDateCriteriaFieldKeyRHS(matchAll, indx), SelectProps: {
                                                                displayEmpty: true,
                                                            } },
                                                            this.selectedPipeline.fields
                                                                .sort((a, b) => {
                                                                if (a.name < b.name)
                                                                    return -1;
                                                                if (a.name > b.name)
                                                                    return 1;
                                                                return 0;
                                                            })
                                                                .filter((field) => ["DATE"].includes(field.type))
                                                                .map((obj) => (React.createElement(MenuItem, { key: obj.key, value: obj.key }, obj.name))),
                                                            React.createElement(MenuItem, { value: "" }, "current date"),
                                                            React.createElement(MenuItem, { value: "specific date" }, "specific date")))),
                                                    criteria.date_criteria.value_rhs && (React.createElement(Grid, { item: true, xs: 9 },
                                                        React.createElement(CoreDateField, { variant: "standard", fullWidth: true, size: "small", date: criteria.date_criteria.value_rhs, onDateChange: this.updateDateCriteriaValueRHS(matchAll, indx), format: "M/D/YYYY", placeholder: "the current date" }))))))),
                                        !["is blank", "is not blank", "is the anniversary of"].includes((_2 = criteria.date_criteria.comparison) !== null && _2 !== void 0 ? _2 : "") && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                            React.createElement(DateCriteriaInfoButton, { criteria: criteria.date_criteria, fields: this.dateFields })))))))),
                            criteria.template_criteria && (React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, xs: criteria.template_criteria.value === "has not been sent" ? 6 : 4, style: { marginTop: "auto" } },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: criteria.template_criteria.template, onChange: this.updateTemplateCriteriaTemplate(matchAll, indx) }, (templates !== null && templates !== void 0 ? templates : [])
                                        .filter((thisTemplate) => this.selectedPipeline &&
                                        thisTemplate.pipeline === this.selectedPipeline.key &&
                                        thisTemplate.uid !== uid)
                                        .map((thisTemplate, thisTemplateIndx) => (React.createElement(MenuItem, { key: thisTemplate.uid, value: thisTemplate.uid },
                                        thisTemplateIndx + 1,
                                        " - ",
                                        thisTemplate.name))))),
                                React.createElement(Grid, { item: true, xs: criteria.template_criteria.value === "has not been sent" ? 6 : 2, style: { marginTop: "auto" } },
                                    React.createElement(TextField, { variant: "standard", select: true, size: "small", fullWidth: true, value: criteria.template_criteria.value, onChange: this.updateTemplateCriteriaComparison(matchAll, indx) }, (templateCriteriaComparisonTypes !== null && templateCriteriaComparisonTypes !== void 0 ? templateCriteriaComparisonTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                criteria.template_criteria.value !== "has not been sent" && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 2, style: {
                                            marginTop: "auto",
                                        } },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: (_3 = criteria.template_criteria.delay_mode) !== null && _3 !== void 0 ? _3 : "", onChange: this.updateTemplateCriteriaDelayMode(matchAll, indx) }, (templateCriteriaDelayModes !== null && templateCriteriaDelayModes !== void 0 ? templateCriteriaDelayModes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                    React.createElement(Grid, { item: true, xs: 1, style: {
                                            marginTop: "auto",
                                        } },
                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, placeholder: "duration", number: (_4 = criteria.template_criteria.delay_qty) !== null && _4 !== void 0 ? _4 : null, min: 0, format: "0,0", onNumberChange: this.updateTemplateCriteriaDelayQty(matchAll, indx) })),
                                    React.createElement(Grid, { item: true, 
                                        // xs={2}
                                        style: {
                                            marginTop: "auto",
                                            flexGrow: 1,
                                        } },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, value: (_5 = criteria.template_criteria.delay_unit) !== null && _5 !== void 0 ? _5 : "", size: "small", onChange: this.updateTemplateCriteriaDelayUnit(matchAll, indx) }, (templateCriteriaDelayUnits !== null && templateCriteriaDelayUnits !== void 0 ? templateCriteriaDelayUnits : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))),
                                    React.createElement(Grid, { item: true, 
                                        // xs={1}
                                        style: {
                                            marginTop: "auto",
                                        } },
                                        React.createElement(Typography, null, "ago")))))))));
                })));
            }
        });
        /** Get template data via API, or initialize a new template */
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { uid } = this.props;
                if (uid) {
                    this.setState({
                        template: null,
                    }, () => {
                        API.mailScheduleTemplates.get(uid, {
                            onSuccess: (result) => {
                                console.log("got template:", result);
                                let messagePlainText = "";
                                if (result.sms_template) {
                                    messagePlainText = result.sms_template.message;
                                }
                                else if (result.sms_draft_template) {
                                    messagePlainText = result.sms_draft_template.message;
                                }
                                else if (result.slack_template) {
                                    messagePlainText = result.slack_template.message;
                                }
                                else if (result.streak_comment_template) {
                                    messagePlainText = result.streak_comment_template.message;
                                }
                                this.setState({
                                    template: result,
                                    messagePlainText,
                                });
                            },
                            onError: (error) => {
                                if (error.name !== "AbortError") {
                                    this.setState({
                                        error,
                                    });
                                }
                            },
                        });
                    });
                }
                else if (this.state.pipelines) {
                    this.setState((prevState) => ({
                        template: uid
                            ? null
                            : {
                                name: `New Template (created ${dayjs_(new Date()).format("M/D/YYYY h:mm A")})`,
                                assigned_agent: null,
                                pipeline: prevState.pipelines[0].pipelineKey,
                                email_draft_template: {
                                    sender_type: "assigned agent",
                                    subject: "",
                                    to: "",
                                    cc: "",
                                    bcc: "",
                                    body: "",
                                    allow_unsubscribe: false,
                                },
                                triggers: [],
                                criteria: [],
                                attachments: [],
                                streak_attachments: [],
                                gcp_attachments: [],
                                is_active: true,
                                earliest_process_time: null,
                                latest_process_time: null,
                                resend_maximum: 1,
                                timestamp_streak_field_key: null,
                                review_frequency: "Annual",
                            },
                        templateType: "Email Draft",
                        error: null,
                    }));
                }
            }
        });
        /** Refreshes the list of boxes that can be used to "preview" the template */
        Object.defineProperty(this, "refreshBoxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { pipelines, template } = this.state;
                console.log("Getting boxes...");
                this.setState({
                    boxes: null,
                    selectedBoxKeyForPreview: null,
                });
                if ((template === null || template === void 0 ? void 0 : template.pipeline) && pipelines) {
                    API.streakBoxes.list(template.pipeline, {
                        onSuccess: (result) => {
                            const pipeline = pipelines.find((p) => p.key === template.pipeline);
                            console.log("Got ", result.length, "boxes from pipeline");
                            this.setState({
                                boxes: result.sort((a, b) => {
                                    if (pipeline.stageOrder.indexOf(a.stageKey) < pipeline.stageOrder.indexOf(b.stageKey))
                                        return -1;
                                    if (pipeline.stageOrder.indexOf(a.stageKey) > pipeline.stageOrder.indexOf(b.stageKey))
                                        return 1;
                                    if (a.name < b.name)
                                        return -1;
                                    if (a.name > b.name)
                                        return 1;
                                    return 0;
                                }),
                            });
                        },
                        onError: (error) => {
                            console.error("Error getting boxes: ", error);
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                }
            }
        });
        Object.defineProperty(this, "doFieldReplacements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                let _text = text;
                if (this.selectedPipeline) {
                    for (const field of this.selectedPipeline.fields) {
                        try {
                            const regexp = new RegExp(`\\$'(${field.key})`, "g");
                            _text = _text.replace(regexp, `$$'${field.name}`);
                        }
                        catch (err) {
                            console.warn(`Unable to do sanitization for field ${field.name} of pipeline ${this.selectedPipeline.name}`, err);
                            return _text;
                        }
                    }
                }
                return _text;
            }
        });
        Object.defineProperty(this, "addAttachment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { attachments: [...prevState.template.attachments, ""] }),
                }));
            }
        });
        Object.defineProperty(this, "removeAttachment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (attachment) => () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { attachments: prevState.template.attachments.filter((a) => a !== attachment) }),
                }));
            }
        });
        Object.defineProperty(this, "updateAttachment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (indx) => (e) => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { attachments: [
                            ...prevState.template.attachments.slice(0, indx),
                            e.target.value,
                            ...prevState.template.attachments.slice(indx + 1, prevState.template.attachments.length),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "addGCPAttachment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { gcp_attachments: [...prevState.template.gcp_attachments, { blob_name: "" }] }),
                }));
            }
        });
        Object.defineProperty(this, "removeGCPAttachment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (index) => () => {
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { gcp_attachments: prevState.template.gcp_attachments.filter((_, indx) => indx !== index) }),
                }));
            }
        });
        Object.defineProperty(this, "updateGCPAttachmentBlobName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (attachmentIndex, prefix) => (choice) => {
                const isFile = choice.includes(".pdf");
                this.setState((prevState) => ({
                    template: Object.assign(Object.assign({}, prevState.template), { gcp_attachments: [
                            ...prevState.template.gcp_attachments.slice(0, attachmentIndex),
                            Object.assign(Object.assign({}, prevState.template.gcp_attachments[attachmentIndex]), { blob_name: prefix + choice + (isFile ? "" : "/") }),
                            ...prevState.template.gcp_attachments.slice(attachmentIndex + 1, prevState.template.gcp_attachments.length),
                        ] }),
                }));
            }
        });
        Object.defineProperty(this, "downloadGCPAttachment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (index) => () => {
                const { template } = this.state;
                const gcpAttachment = template.gcp_attachments[index];
                console.log("Download file with blob: ", gcpAttachment.blob_name);
                this.setState((prevState) => {
                    return {
                        currentlyDownloadingGCPAttachments: [...prevState.currentlyDownloadingGCPAttachments, index],
                    };
                }, () => {
                    API.googleCloud.download({
                        query: {
                            blob_name: gcpAttachment.blob_name,
                        },
                        onSuccess: (result) => {
                            // open file in new tab using ascii content
                            // console.log("download file:", result);
                            downloadBlob(result.file, result.filename, result.mime_type, "_blank");
                            this.setState((prevState) => {
                                return {
                                    currentlyDownloadingGCPAttachments: prevState.currentlyDownloadingGCPAttachments.filter((item) => {
                                        return item !== index;
                                    }),
                                };
                            });
                        },
                        onError: (error) => {
                            console.error("Got error downloading GCP attachment: ", getReadableErrorMessage(error));
                            this.props.showErrorDialog(error);
                            this.setState((prevState) => {
                                return {
                                    currentlyDownloadingGCPAttachments: prevState.currentlyDownloadingGCPAttachments.filter((item) => {
                                        return item !== index;
                                    }),
                                };
                            });
                        },
                    });
                });
            }
        });
    }
    componentDidMount() {
        API.mailScheduleTemplateTypes.list({
            onSuccess: (result) => {
                this.setState({
                    templateTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.streakPipelines.list({
            onSuccess: (result) => {
                this.setState({
                    pipelines: result,
                }, this.refresh);
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.streakViews.list({
            onSuccess: (result) => {
                this.setState({
                    views: result.sort((a, b) => {
                        if (a.pipeline_key < b.pipeline_key)
                            return -1;
                        if (a.pipeline_key > b.pipeline_key)
                            return 1;
                        if (a.name < b.name)
                            return -1;
                        if (a.name > b.name)
                            return 1;
                        return 0;
                    }),
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.streakUsers.list({
            onSuccess: (result) => {
                this.setState({
                    streakUsers: result,
                }, this.refresh);
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        // get users for assigned agents dropdown
        API.users.list({
            onSuccess: (result) => {
                this.setState({
                    agents: result,
                });
            },
            onError: (error) => {
                if (error.name !== "AbortError") {
                    console.log(`Error getting users ${error}`);
                    this.props.showErrorDialog(error);
                }
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleTemplates.list({
            onSuccess: (result) => {
                this.setState({
                    templates: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleBoilerplate.list({
            onSuccess: (result) => {
                this.setState({
                    boilerplate: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.slack.listChannels({
            onSuccess: (result) => {
                this.setState({
                    slackChannels: result.items,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.slack.listUsers({
            onSuccess: (result) => {
                this.setState({
                    slackUsers: result,
                }, this.refresh);
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.twilioPhoneNumbers.list({
            onSuccess: (result) => {
                this.setState({
                    twilioPhones: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleDateOffsetUnits.list({
            onSuccess: (result) => {
                this.setState({
                    dateOffsetUnits: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleDateCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    dateCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleTemplateCriteriaDelayModes.list({
            onSuccess: (result) => {
                this.setState({
                    templateCriteriaDelayModes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUpdateCache: true,
            shouldUseCache: true,
        });
        API.mailScheduleTemplateCriteriaDelayUnits.list({
            onSuccess: (result) => {
                this.setState({
                    templateCriteriaDelayUnits: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleTextCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    textCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleDropdownCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    dropdownCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleCheckboxCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    checkboxCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleTagCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    tagCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleTemplateCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    templateCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleStageCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    stageCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        API.mailScheduleViewCriteriaComparisonTypes.list({
            onSuccess: (result) => {
                this.setState({
                    viewCriteriaComparisonTypes: result,
                });
            },
            onError: (error) => {
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        // get dropdown options for GCP files
        API.googleCloud.listBlobs({
            query: {
                prefix: "dash-daily/",
                files_only: true,
            },
            onSuccess: (result) => {
                this.setState({
                    gcpBlobs: result.filter((blob) => {
                        return blob.name.includes("current");
                    }),
                });
            },
            onError: (error) => {
                console.error("Got error getting GCP blobs: ", getReadableErrorMessage(error));
                this.setState({
                    error,
                });
            },
            shouldUseCache: true,
            shouldUpdateCache: true,
        });
        this.refreshBoxes();
    }
    componentDidUpdate(prevProps, prevState) {
        var _a, _b;
        if (prevProps.uid !== this.props.uid) {
            this.refresh();
        }
        if (((_a = prevState.template) === null || _a === void 0 ? void 0 : _a.pipeline) !== ((_b = this.state.template) === null || _b === void 0 ? void 0 : _b.pipeline)) {
            this.refreshBoxes();
        }
        if (!!prevState.selectedBoxKeyForPreview !== !!this.state.selectedBoxKeyForPreview) {
            this.setState({
                boxAutocompleteKey: uuidv4().replace(/-/g, ""),
            });
        }
    }
    get selectedPipeline() {
        var _a;
        const { pipelines, template } = this.state;
        if (!template || !pipelines) {
            return null;
        }
        return template.pipeline ? ((_a = pipelines.find((obj) => obj.key === template.pipeline)) !== null && _a !== void 0 ? _a : null) : null;
    }
    get selectedPipelineFields() {
        if (this.selectedPipeline) {
            return this.selectedPipeline.fields.sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
        }
        return null;
    }
    get selectedPipelineStages() {
        const { selectedPipeline } = this;
        if (selectedPipeline) {
            return Object.keys(selectedPipeline.stages)
                .sort((a, b) => {
                if (selectedPipeline.stageOrder.indexOf(a) < selectedPipeline.stageOrder.indexOf(b))
                    return -1;
                if (selectedPipeline.stageOrder.indexOf(b) < selectedPipeline.stageOrder.indexOf(a))
                    return 1;
                return 0;
            })
                .map((stageKey) => selectedPipeline.stages[stageKey]);
        }
        return null;
    }
    get textFields() {
        if (!this.selectedPipeline) {
            return [];
        }
        const { fields } = this.selectedPipeline;
        const textFields = fields
            .filter((field) => ["TEXT_INPUT", "DROPDOWN"].includes(getFieldType(field)))
            .sort((a, b) => {
            if (a.name.trim() < b.name.trim())
                return -1;
            if (a.name.trim() > b.name.trim())
                return 1;
            return 0;
        });
        return textFields;
    }
    get dateFields() {
        if (!this.selectedPipeline) {
            return [];
        }
        const { fields } = this.selectedPipeline;
        const dateFields = fields
            .filter((field) => getFieldType(field) === "DATE")
            .sort((a, b) => {
            if (a.name.trim() < b.name.trim())
                return -1;
            if (a.name.trim() > b.name.trim())
                return 1;
            return 0;
        });
        return dateFields;
    }
    get timeFields() {
        const { selectedPipeline } = this;
        if (!selectedPipeline) {
            return [];
        }
        const { fields } = selectedPipeline;
        const timeFields = fields
            .filter((field) => field.type.toLowerCase().trim() === "text_input" && field.name.toLowerCase().trim().includes("time"))
            .sort((a, b) => {
            if (a.name.trim() < b.name.trim())
                return -1;
            if (a.name.trim() > b.name.trim())
                return 1;
            return 0;
        });
        return timeFields;
    }
    get templateTypeHelperText() {
        const { templateType } = this.state;
        switch (templateType) {
            case "Email":
                return "Emails are sent automatically from Gmail";
            case "Email Draft":
                return "Drafts are created in a user's Drafts folder rather than sent automatically.";
            case "SMS":
                return "SMS templates are sent as text messages via Twilio";
            case "SMS Draft":
                return "SMS Draft templates are created as drafts in a user's inbox; when sent, they'll be sent to the recipient phone numbers via Twilio.";
            case "Calendar":
                return "Calendar templates are created as events in Google Calendar.";
            case "Slack":
                return "These templates are posted in the desired Slack channel.";
            default:
                return null;
        }
    }
    get selectedChannel() {
        var _a;
        const { slackChannels, template } = this.state;
        if (!slackChannels) {
            return null;
        }
        return (_a = slackChannels.find((channel) => { var _a; return channel.id === ((_a = template.slack_template) === null || _a === void 0 ? void 0 : _a.channel_id); })) !== null && _a !== void 0 ? _a : null;
    }
    /** TODO: actually implement this */
    get isValid() {
        return true;
    }
    get data() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const template = this.state.template;
        return Object.assign(Object.assign({}, template), { email_template: template.email_template
                ? Object.assign(Object.assign({}, template.email_template), { sender: template.email_template.sender_type === "another email address"
                        ? template.email_template.sender
                        : undefined }) : undefined, email_draft_template: template.email_draft_template
                ? Object.assign(Object.assign({}, template.email_draft_template), { sender: template.email_draft_template.sender_type === "another email address"
                        ? template.email_draft_template.sender
                        : undefined }) : undefined, sms_draft_template: template.sms_draft_template
                ? Object.assign(Object.assign({}, template.sms_draft_template), { drafted_by: (_a = template.sms_draft_template.drafted_by) !== null && _a !== void 0 ? _a : undefined }) : undefined, calendar_template: template.calendar_template
                ? Object.assign(Object.assign({}, template.calendar_template), { host: (_b = template.calendar_template.host) !== null && _b !== void 0 ? _b : undefined, start_time_field: (_c = template.calendar_template.start_time_field) !== null && _c !== void 0 ? _c : undefined, start_time_offset: (_d = template.calendar_template.start_time_offset) !== null && _d !== void 0 ? _d : undefined, start_time_absolute: (_e = template.calendar_template.start_time_absolute) !== null && _e !== void 0 ? _e : undefined, duration: (_f = template.calendar_template.duration) !== null && _f !== void 0 ? _f : undefined }) : undefined, resend_unit: (_g = template.resend_unit) !== null && _g !== void 0 ? _g : undefined, resend_frequency: (_h = template.resend_frequency) !== null && _h !== void 0 ? _h : undefined });
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        const { uid } = this.props;
        const { agents, boilerplate, 
        // boxAutocompleteKey,
        boxes, currentlyDownloadingGCPAttachments, dateOffsetUnits, error, gcpBlobs, isDeleting, isDuplicating, isPreviewOpen, isSaving, messagePlainText, pipelines, selectedBoxKeyForPreview, slackChannels, slackUsers, streakUsers, template, templateTypes, twilioPhones, } = this.state;
        if (isPreviewOpen) {
            return (React.createElement(Box, { p: 4 },
                React.createElement(Paper, { elevation: 4, style: { borderTop: `5px solid ${AppConfig.themeColors.lightblue}` } },
                    React.createElement(Box, { p: 2 },
                        React.createElement(MailScheduleTemplatePreview, { boxKey: selectedBoxKeyForPreview, template: this.data, onClose: this.handlePreviewClosed, onTemplateProcessed: this.handleTemplateProcessed })))));
        }
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!template) {
            return React.createElement(CoreLoading, null);
        }
        for (const key of Object.keys(template)) {
            if (typeof template[key] === "string") {
                template[key] = this.doFieldReplacements(template[key]);
            }
        }
        console.log({ template });
        return (React.createElement(Box, { p: 4 },
            React.createElement(Paper, { elevation: 4, style: { borderTop: `5px solid ${AppConfig.themeColors.lightblue}` } },
                React.createElement(Box, { component: Grid, container: true, p: 2, spacing: 2 },
                    React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: template.is_active, onChange: this.setIsActive, color: "primary" }), label: "Enabled" })),
                        React.createElement(Grid, { item: true, xs: 12, md: 3 },
                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "Pipeline", value: template.pipeline, onChange: this.setPipeline, helperText: "The Pipeline criteria is required for every template. Template will only be processed for boxes that reside in the indicated pipeline." },
                                React.createElement(MenuItem, { key: -1, value: "" }),
                                (pipelines !== null && pipelines !== void 0 ? pipelines : []).map((pipeline) => (React.createElement(MenuItem, { key: pipeline.key, value: pipeline.key }, pipeline.name))))),
                        React.createElement(Grid, { item: true, xs: 12, md: 3 },
                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "Template Type", value: getTemplateType(template), onChange: this.setTemplateType, helperText: this.templateTypeHelperText },
                                React.createElement(MenuItem, { key: -1, value: "" }),
                                (templateTypes !== null && templateTypes !== void 0 ? templateTypes : []).map((templateType) => (React.createElement(MenuItem, { key: templateType.name, value: templateType.name }, templateType.name))))),
                        React.createElement(Grid, { item: true, xs: 12, md: 3 },
                            React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Template Name", value: template.name, onChange: this.setTemplateName, helperText: "The Template Name is used for internal purposes only; it doesn't appear in the email subject, event title, or as any other artifact." })),
                        React.createElement(Grid, { item: true, xs: 12, md: 3 },
                            React.createElement(Autocomplete, { disableClearable: true, value: (agents !== null && agents !== void 0 ? agents : []).find((agent) => agent.uid === template.assigned_agent), forcePopupIcon: false, options: [null, ...(agents !== null && agents !== void 0 ? agents : [])], noOptionsText: "No agents found", getOptionLabel: getAgentName, renderInput: renderAgentSearchInput, renderOption: renderAgentDropdownOption, onChange: this.onAgentFieldChange }))),
                    template.streak_comment_template && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { variant: "standard", label: "Which account should post this message?", fullWidth: true, select: true, helperText: "This use must have a valid Streak API key listed on dash.premierbrokeragesystem.com/users; if they don't, the Mail Schedule won't be able to process this template.", value: (_a = template.streak_comment_template.from_agent) !== null && _a !== void 0 ? _a : "", onChange: this.setFromAgent, SelectProps: {
                                displayEmpty: true,
                            }, InputLabelProps: {
                                shrink: true,
                            } },
                            React.createElement(MenuItem, { value: "" }, "The assigned agent"),
                            ((_b = this.state.streakUsers) !== null && _b !== void 0 ? _b : []).map((user) => (React.createElement(MenuItem, { key: user.email, value: user.email }, user.fullName)))))),
                    (template.email_template || template.email_draft_template) &&
                        (() => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            const t = ((_a = template.email_template) !== null && _a !== void 0 ? _a : template.email_draft_template);
                            return (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                                    React.createElement(Grid, { item: true, xs: 12, md: t.sender_type === "another email address" ? 6 : 12 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "From", required: true, value: (_b = t.sender_type) !== null && _b !== void 0 ? _b : "assigned agent", onChange: this.setFromType, helperText: (() => {
                                                if (t.sender_type === "assigned agent") {
                                                    return "The message will be sent or drafted from the Streak assignee's inbox.";
                                                }
                                                if (t.sender_type === "another email address") {
                                                    return "The message will be sent or drafted from the specified email address.";
                                                }
                                                if (t.sender_type === "noreply") {
                                                    return "The message will be sent from noreply@dashnc.com.";
                                                }
                                                return "";
                                            })() }, ["assigned agent", template.email_template && "noreply", "another email address"]
                                            .filter((obj) => obj)
                                            .map((obj) => (React.createElement(MenuItem, { key: obj, value: obj }, obj))))),
                                    t.sender_type === "another email address" && (React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                        React.createElement(Typography, { variant: "caption" }, "From email"),
                                        React.createElement(MentionsInput, { value: (_c = t.sender) !== null && _c !== void 0 ? _c : "", onChange: this.setFrom, error: !!this.checkWildcards(t.sender), helperText: (_d = this.checkWildcards(t.sender)) !== null && _d !== void 0 ? _d : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "Must be a valid DASH email address." })), singleLine: true, streakFields: this.textFields })))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "To"),
                                    React.createElement(MentionsInput, { value: t.to, onChange: this.setTo, error: !!this.checkWildcards(t.to, true), helperText: (_e = this.checkWildcards(t.to, true)) !== null && _e !== void 0 ? _e : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Cc"),
                                    React.createElement(MentionsInput, { value: t.cc, onChange: this.setCC, error: !!this.checkWildcards(t.cc, true), helperText: (_f = this.checkWildcards(t.cc, true)) !== null && _f !== void 0 ? _f : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Bcc"),
                                    React.createElement(MentionsInput, { value: t.bcc, onChange: this.setBCC, error: !!this.checkWildcards(t.bcc, true), helperText: (_g = this.checkWildcards(t.bcc, true)) !== null && _g !== void 0 ? _g : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Subject"),
                                    React.createElement(MentionsInput, { value: t.subject, onChange: this.setSubject, error: !!this.checkWildcards(t.subject), helperText: (_h = this.checkWildcards(t.subject)) !== null && _h !== void 0 ? _h : React.createElement(MailScheduleTemplateHelperText, null), singleLine: true, streakFields: this.textFields }))));
                        })(),
                    template.calendar_template &&
                        (() => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                            const t = template.calendar_template;
                            return (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Host"),
                                    React.createElement(MentionsInput, { value: (_a = t.host) !== null && _a !== void 0 ? _a : "", onChange: this.setHost, placeholder: "Assigned Agent", error: !!this.checkWildcards((_b = t.host) !== null && _b !== void 0 ? _b : "", true), helperText: (_d = this.checkWildcards((_c = t.host) !== null && _c !== void 0 ? _c : "", true)) !== null && _d !== void 0 ? _d : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a valid email address." })), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Guests"),
                                    React.createElement(MentionsInput, { value: t.guests, onChange: this.setGuests, error: !!this.checkWildcards(t.guests, true), helperText: (_e = this.checkWildcards(t.guests, true)) !== null && _e !== void 0 ? _e : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Title"),
                                    React.createElement(MentionsInput, { value: t.title, onChange: this.setTitle, error: !!this.checkWildcards(t.title), helperText: (_f = this.checkWildcards(t.title)) !== null && _f !== void 0 ? _f : React.createElement(MailScheduleTemplateHelperText, null), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, { variant: "caption" }, "Location"),
                                    React.createElement(MentionsInput, { value: t.location, onChange: this.setLocation, error: !!this.checkWildcards(t.location), helperText: (_g = this.checkWildcards(t.location)) !== null && _g !== void 0 ? _g : React.createElement(MailScheduleTemplateHelperText, null), singleLine: true, streakFields: this.textFields })),
                                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1 },
                                    React.createElement(Grid, { item: true, style: { marginTop: "auto" } },
                                        React.createElement(Typography, { variant: "body2" }, "Date")),
                                    React.createElement(Grid, { item: true, style: { flexGrow: 1 } },
                                        React.createElement(Grid, { container: true, spacing: 1 },
                                            React.createElement(Grid, { item: true, xs: 1 },
                                                React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, size: "small", number: t.start_date_offset === null || t.start_date_offset === undefined
                                                        ? 0
                                                        : Math.abs(t.start_date_offset), format: "0,0", min: 0, max: 60, onNumberChange: this.setStartDateOffset })),
                                            React.createElement(Grid, { item: true, xs: 2 },
                                                React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, size: "small", value: !t.start_date_offset || t.start_date_offset < 0 ? "before" : "after", onChange: this.setStartDateOffsetComparison },
                                                    React.createElement(MenuItem, { value: "before" }, "days before"),
                                                    React.createElement(MenuItem, { value: "after" }, "days after"))),
                                            React.createElement(Grid, { item: true, xs: 9, style: { marginTop: "auto" } },
                                                React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, placeholder: "Streak field", value: t.start_date_field, onChange: this.setStartDateField },
                                                    React.createElement(MenuItem, { key: -1, value: "" }),
                                                    this.dateFields.map((field) => (React.createElement(MenuItem, { key: field.key, value: field.key }, field.name)))))))),
                                React.createElement(Grid, { item: true, xs: 12, style: { marginTop: "auto" } },
                                    React.createElement(FormControlLabel, { label: "All Day Event?", control: React.createElement(Checkbox, { color: "primary", checked: !!t.is_all_day_event, onChange: this.setIsAllDayEvent, size: "small" }) })),
                                !t.is_all_day_event && (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                                    React.createElement(Grid, { item: true, xs: 12, sm: 4, style: { marginTop: "auto" } },
                                        React.createElement(FormControlLabel, { label: "Use Streak field for Start Time?", control: React.createElement(Checkbox, { color: "primary", checked: !!t.start_time_field, onChange: this.setUseStreakFieldForStartTime, size: "small" }) })),
                                    t.start_time_field ? (React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Event Time Field", value: (_h = t.start_time_field) !== null && _h !== void 0 ? _h : null, onChange: this.setStartTimeField }, this.timeFields.map((field) => (React.createElement(MenuItem, { key: field.key, value: field.key }, field.name)))))) : (React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(CoreDateField, { variant: "standard", fullWidth: true, label: "Event Time", format: "h:mm A", mode: "time", date: (_j = t.start_time_absolute) !== null && _j !== void 0 ? _j : null, onDateChange: this.setStartTimeAbsolute }))),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, required: true, label: "Duration (minutes)", min: 1, max: 10080, format: "0,0", number: (_k = t.duration) !== null && _k !== void 0 ? _k : null, onNumberChange: this.setDuration })))),
                                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                                    React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Reminder Email", value: (_l = t.reminder_email_minutes_before) !== null && _l !== void 0 ? _l : "", onChange: this.setReminderEmailMinutesBefore }, [
                                            { name: "None", value: "" },
                                            { name: "5 minutes before", value: 5 },
                                            { name: "10 minutes before", value: 10 },
                                            { name: "15 minutes before", value: 15 },
                                            { name: "30 minutes before", value: 30 },
                                            { name: "1 hour before", value: 60 },
                                            { name: "2 hours before", value: 120 },
                                            { name: "3 hours before", value: 180 },
                                            { name: "24 hours before", value: 1440 },
                                        ].map((option) => (React.createElement(MenuItem, { key: option.name, value: option.value }, option.name))))),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Reminder Popup", value: (_m = t.reminder_popup_minutes_before) !== null && _m !== void 0 ? _m : "", onChange: this.setReminderPopupMinutesBefore }, [
                                            { name: "None", value: "" },
                                            { name: "5 minutes before", value: 5 },
                                            { name: "10 minutes before", value: 10 },
                                            { name: "15 minutes before", value: 15 },
                                            { name: "30 minutes before", value: 30 },
                                            { name: "1 hour before", value: 60 },
                                            { name: "2 hours before", value: 120 },
                                            { name: "3 hours before", value: 180 },
                                            { name: "24 hours before", value: 1440 },
                                        ].map((option) => (React.createElement(MenuItem, { key: option.name, value: option.value }, option.name))))),
                                    React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Event Color", value: t.color, onChange: this.setEventColor }, [
                                            "Pale Blue",
                                            "Pale Green",
                                            "Mauve",
                                            "Pale Red",
                                            "Yellow",
                                            "Orange",
                                            "Cyan",
                                            "Gray",
                                            "Blue",
                                            "Green",
                                            "Red",
                                        ].map((color) => (React.createElement(MenuItem, { key: color, value: color }, color))))),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: t.google_meet, onChange: this.setGoogleMeet, color: "primary" }), label: "Include Link to Google Meet" })))));
                        })(),
                    ((_c = template.sms_template) !== null && _c !== void 0 ? _c : template.sms_draft_template) &&
                        (() => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            const t = ((_a = template.sms_template) !== null && _a !== void 0 ? _a : template.sms_draft_template);
                            return (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, md: template.sms_draft_template ? 3 : 6 },
                                    React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "From", value: (_b = t.from_phone) !== null && _b !== void 0 ? _b : "", onChange: this.setFromPhone, SelectProps: {
                                            displayEmpty: true,
                                        }, InputLabelProps: {
                                            shrink: true,
                                        } },
                                        React.createElement(MenuItem, { key: -1, value: "" }, "agent's phone"),
                                        (twilioPhones !== null && twilioPhones !== void 0 ? twilioPhones : [])
                                            .filter((phone) => { var _a; return (_a = phone.capabilities) === null || _a === void 0 ? void 0 : _a.sms; })
                                            .map((phone) => (React.createElement(MenuItem, { key: phone.phone_number, value: phone.phone_number }, phone.friendly_name))))),
                                React.createElement(Grid, { item: true, xs: 12, md: template.sms_draft_template ? 3 : 6 },
                                    React.createElement(Typography, { variant: "caption" }, "To"),
                                    React.createElement(MentionsInput, { value: (_c = t.to_phone) !== null && _c !== void 0 ? _c : "", required: true, onChange: this.setToPhone, error: !!this.checkWildcards(t.to_phone), helperText: (_d = this.checkWildcards(t.to_phone)) !== null && _d !== void 0 ? _d : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid phone numbers." })), singleLine: true, streakFields: this.textFields })),
                                template.sms_template && (React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: (_e = t.send_as_group_text) !== null && _e !== void 0 ? _e : false, onChange: this.setSendAsGroupMMS, color: "primary" }), label: "Send as Group MMS" }))),
                                template.sms_draft_template && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 12, md: template.sms_draft_template.drafted_by_type === "another email address" ? 3 : 6 },
                                        React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Drafted by", required: true, value: (_f = template.sms_draft_template.drafted_by_type) !== null && _f !== void 0 ? _f : "", onChange: this.setDraftedByType },
                                            React.createElement(MenuItem, { key: -1, value: "" }),
                                            ["assigned agent", "another email address"].map((obj) => (React.createElement(MenuItem, { key: obj, value: obj }, obj))))),
                                    template.sms_draft_template.drafted_by_type === "another email address" && (React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                        React.createElement(Typography, { variant: "caption" }, "Email Address"),
                                        React.createElement(MentionsInput, { value: (_g = template.sms_draft_template.drafted_by) !== null && _g !== void 0 ? _g : "", onChange: this.setDraftedBy, error: !!this.checkWildcards(template.sms_draft_template.drafted_by), helperText: (_h = this.checkWildcards(template.sms_draft_template.drafted_by)) !== null && _h !== void 0 ? _h : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "Must be a valid DASH email address." })), singleLine: true, streakFields: this.textFields })))))));
                        })(),
                    template.slack_template && (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, label: "Channel", value: (_d = template.slack_template.channel_id) !== null && _d !== void 0 ? _d : "", onChange: this.setSlackChannel, error: this.selectedChannel !== null && !((_e = this.selectedChannel) === null || _e === void 0 ? void 0 : _e.is_member), helperText: this.selectedChannel && !this.selectedChannel.is_member
                                    ? "Make sure Dashbot has access to the channel"
                                    : null },
                                React.createElement(MenuItem, { key: -1, value: "" }),
                                (slackChannels !== null && slackChannels !== void 0 ? slackChannels : [])
                                    .filter((channel) => channel.name)
                                    .map((channel) => (React.createElement(MenuItem, { key: channel.id, value: channel.id }, channel.name))))),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4, style: { marginTop: "13px" } },
                            React.createElement(MentionsInput, { value: (_f = template.slack_template.recipients) !== null && _f !== void 0 ? _f : undefined, onChange: this.setSlackRecipients, error: !!this.checkWildcards((_g = template.slack_template.recipients) !== null && _g !== void 0 ? _g : undefined, true), helperText: (_j = this.checkWildcards((_h = template.slack_template.recipients) !== null && _h !== void 0 ? _h : undefined, true)) !== null && _j !== void 0 ? _j : (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })), singleLine: true, streakFields: this.textFields })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                            React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Bot Name", value: (_k = template.slack_template.bot_name) !== null && _k !== void 0 ? _k : "Dashbot", onChange: this.setBotName })))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Box, { component: Grid, container: true, item: true, xs: 12, padding: 1, border: 1, borderRadius: "8px", borderColor: "grey.500", style: { margin: "1rem" } },
                        React.createElement(Grid, { item: true, container: true, xs: 12 },
                            React.createElement(Grid, { item: true, style: { flexGrow: 1 } },
                                React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Triggers"),
                                React.createElement(Typography, { variant: "caption", style: { color: "#aaa" }, dangerouslySetInnerHTML: {
                                        __html: "If criteria are specified below, the template will be sent <em>only when the criteria are satisfied.</em> Otherwise, it will be sent whenever any of the triggers are triggered.",
                                    } })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Tooltip, { title: "Add trigger" },
                                    React.createElement(IconButton, { size: "small", onClick: this.addTrigger },
                                        React.createElement(Add, { fontSize: "small" }))))),
                        React.createElement(Grid, { item: true, container: true, xs: 12 }, template.triggers.length === 0 ? (React.createElement(Typography, { variant: "body2" },
                            "Press the ",
                            React.createElement(Add, { fontSize: "small" }),
                            " button to create a new trigger.")) : (template.triggers.map((trigger, indx) => {
                            var _a, _b, _c, _d, _e, _f;
                            return (React.createElement(Grid, { key: indx, item: true, container: true, xs: 12, spacing: 1 },
                                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Tooltip, { title: "Delete trigger" },
                                        React.createElement(IconButton, { size: "small", onClick: this.deleteTrigger(indx) },
                                            React.createElement(Delete, { fontSize: "small" })))),
                                React.createElement(Grid, { item: true, style: { flexGrow: 1 } },
                                    React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 1 },
                                        React.createElement(Grid, { item: true, xs: 4 },
                                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "Type", value: trigger.field_trigger ? "field" : "stage", onChange: this.setTriggerType(indx) },
                                                React.createElement(MenuItem, { key: "stage", value: "stage" }, "Stage change"),
                                                React.createElement(MenuItem, { key: "field", value: "field" }, "Field change"))),
                                        trigger.stage_trigger && (React.createElement(React.Fragment, null,
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "From Stage", value: (_a = trigger.stage_trigger.from_stage_key) !== null && _a !== void 0 ? _a : "", onChange: this.setTriggerFromStage(indx) }, (_b = this.selectedPipelineStages) === null || _b === void 0 ? void 0 : _b.map((stage) => (React.createElement(MenuItem, { key: stage.key, value: stage.key }, stage.name))))),
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, required: true, label: "To Stage", value: (_c = trigger.stage_trigger.to_stage_key) !== null && _c !== void 0 ? _c : "", onChange: this.setTriggerToStage(indx) }, (_d = this.selectedPipelineStages) === null || _d === void 0 ? void 0 : _d.map((stage) => (React.createElement(MenuItem, { key: stage.key, value: stage.key }, stage.name))))))),
                                        trigger.field_trigger && (React.createElement(Grid, { item: true, xs: 8 },
                                            React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, select: true, label: "Field name", value: (_e = trigger.field_trigger.field_key) !== null && _e !== void 0 ? _e : "", onChange: this.setTriggerField(indx) }, (_f = this.selectedPipelineFields) === null || _f === void 0 ? void 0 : _f.map((field) => (React.createElement(MenuItem, { key: field.key, value: field.key }, field.name))))))))));
                        })))),
                    React.createElement(Box, { component: Grid, container: true, item: true, xs: 12, padding: 1, border: 1, borderRadius: "8px", borderColor: "grey.500", style: { margin: "1rem" } },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Criteria"),
                            React.createElement(Typography, { variant: "caption", style: { color: "#aaa" }, dangerouslySetInnerHTML: {
                                    __html: "The template will <em>only</em> be sent if/when these criteria are satisfied.",
                                } })),
                        React.createElement(Grid, { item: true, container: true, xs: 12 },
                            React.createElement(Grid, { item: true, style: { width: "calc(100% - 30px)" } },
                                React.createElement(Typography, { variant: "body1" }, "Match ALL of the following")),
                            React.createElement(Grid, { item: true, style: { width: "30px" } },
                                React.createElement(Tooltip, { title: "Add criteria" },
                                    React.createElement(IconButton, { size: "small", onClick: this.addCriteria(true) },
                                        React.createElement(Add, { fontSize: "small" }))))),
                        this.renderCriteria(true),
                        React.createElement(Grid, { item: true, xs: 12, style: { padding: "4px" } },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, container: true, xs: 12, style: { padding: "4px" } },
                            React.createElement(Grid, { item: true, style: { width: "calc(100% - 30px)" } },
                                React.createElement(Typography, { variant: "body1" }, "Match ANY of the following")),
                            React.createElement(Grid, { item: true, style: { width: "30px" } },
                                React.createElement(Tooltip, { title: "Add criteria" },
                                    React.createElement(IconButton, { size: "small", onClick: this.addCriteria(false) },
                                        React.createElement(Add, { fontSize: "small" }))))),
                        this.renderCriteria(false)),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                        React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, null, "Send no earlier than")),
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(TextField, { variant: "standard", id: "time", type: "time", value: (_l = template.earliest_process_time) !== null && _l !== void 0 ? _l : "", onChange: this.setEarliestProcessTime, slotProps: {
                                        htmlInput: {
                                            step: 300, // 5 min
                                        },
                                        inputLabel: {
                                            shrink: true,
                                        },
                                    } }))),
                        React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, null, "Send no later than")),
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(TextField, { variant: "standard", id: "time", type: "time", value: (_m = template.latest_process_time) !== null && _m !== void 0 ? _m : "", onChange: this.setLatestProcessTime, slotProps: {
                                        htmlInput: {
                                            step: 300, // 5 min
                                        },
                                        inputLabel: {
                                            shrink: true,
                                        },
                                    } })))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", textAlign: "right" } },
                            React.createElement(Typography, { variant: "body2" }, "This template should be processed at most")),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", width: "100px" } },
                            React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, placeholder: "infinite", number: (_o = template.resend_maximum) !== null && _o !== void 0 ? _o : null, min: 1, max: 999, format: "0,0", onNumberChange: this.setResendMaximum })),
                        template.triggers.length > 0 ? (React.createElement(Grid, { item: true, style: { marginTop: "auto", textAlign: "right" } },
                            React.createElement(Typography, { variant: "body2" }, template.resend_maximum !== 1 ? "times" : " time"))) : (React.createElement(Grid, { item: true, style: { marginTop: "auto", textAlign: "right" } },
                            React.createElement(Typography, { variant: "body2" }, template.resend_maximum !== 1 ? "times; at frequently as once every" : " time"))),
                        template.resend_maximum !== 1 &&
                            // @ts-expect-error: Property 'resend_option' does not exist on type
                            (template.resend_option === "On Interval" ||
                                // @ts-expect-error: Property 'resend_option' does not exist on type
                                template.resend_option === "On Trigger" ||
                                template.triggers.length === 0) && (React.createElement(React.Fragment, null,
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", width: "50px" } },
                                React.createElement(CoreNumberField, { variant: "standard", fullWidth: true, min: 1, max: 999, format: "0", number: (_p = template.resend_frequency) !== null && _p !== void 0 ? _p : 1, onNumberChange: this.setResendFrequency })),
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", width: "75px" } },
                                React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, value: (_q = template.resend_unit) !== null && _q !== void 0 ? _q : "day", onChange: this.setResendUnit }, (dateOffsetUnits !== null && dateOffsetUnits !== void 0 ? dateOffsetUnits : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, `${obj.name + (template.resend_frequency !== 1 ? "s" : "")}`)))))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { container: true, item: true, xs: 12 },
                        React.createElement(Grid, { item: true, style: {
                                marginTop: "auto",
                                paddingRight: "8px",
                                textAlign: "right",
                            } },
                            React.createElement(Typography, { variant: "body2" }, "Timestamp Streak Field: ")),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", width: "200px" } },
                            React.createElement(TextField, { variant: "standard", select: true, fullWidth: true, value: template.timestamp_streak_field_key, onChange: this.setTimestampStreakField },
                                React.createElement(MenuItem, { key: "pipeline_fields_dropdown_null", value: "" }),
                                this.dateFields.map((field) => (React.createElement(MenuItem, { key: `streak_field_dropdown_${field.key}`, value: field.key }, field.name))),
                                template.timestamp_streak_field_key &&
                                    !this.dateFields.map((f) => f.key).includes(template.timestamp_streak_field_key) && (React.createElement(MenuItem, { value: template.timestamp_streak_field_key }, template.timestamp_streak_field_key))))),
                    React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { item: true, style: {
                                marginTop: "auto",
                                marginBottom: "auto",
                                textAlign: "right",
                            } },
                            React.createElement(Typography, { variant: "body2" }, "Review Frequency: ")),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", width: "200px" } },
                            React.createElement(Slider, { value: template.review_frequency
                                    ? {
                                        Annual: 2,
                                        Quarterly: 1,
                                    }[template.review_frequency]
                                    : 0, step: 1, marks: true, min: 0, max: 2, onChange: this.setReviewFrequency })),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body2" }, (_r = template.review_frequency) !== null && _r !== void 0 ? _r : "n/a"))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    this.selectedPipelineFields && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { p: 4, style: { border: "1px solid #ccc", borderRadius: "0.5rem" } },
                            React.createElement(IfStatementBuilder, { fields: this.selectedPipelineFields, variant: ((_t = (_s = template.email_template) !== null && _s !== void 0 ? _s : template.email_draft_template) !== null && _t !== void 0 ? _t : template.calendar_template)
                                    ? "html"
                                    : "plaintext" })))),
                    ((_v = (_u = template.email_template) !== null && _u !== void 0 ? _u : template.email_draft_template) !== null && _v !== void 0 ? _v : template.calendar_template) ? (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "caption" }, ((_w = template.email_template) !== null && _w !== void 0 ? _w : template.email_draft_template) ? "Body" : "Description"),
                        React.createElement(MailScheduleQuill, { value: (_2 = (_0 = (_y = (_x = template.email_template) === null || _x === void 0 ? void 0 : _x.body) !== null && _y !== void 0 ? _y : (_z = template.email_draft_template) === null || _z === void 0 ? void 0 : _z.body) !== null && _0 !== void 0 ? _0 : (_1 = template.calendar_template) === null || _1 === void 0 ? void 0 : _1.description) !== null && _2 !== void 0 ? _2 : "", onChange: ((_3 = template.email_template) !== null && _3 !== void 0 ? _3 : template.email_draft_template) ? this.setBody : this.setDescription, fields: (_4 = this.selectedPipeline) === null || _4 === void 0 ? void 0 : _4.fields, boilerplate: (_5 = (this.selectedPipeline &&
                                (boilerplate !== null && boilerplate !== void 0 ? boilerplate : []).filter((item) => item.pipeline === this.selectedPipeline.key))) !== null && _5 !== void 0 ? _5 : undefined }))) : ((() => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        const t = ((_c = (_b = (_a = template.streak_comment_template) !== null && _a !== void 0 ? _a : template.slack_template) !== null && _b !== void 0 ? _b : template.sms_template) !== null && _c !== void 0 ? _c : template.sms_draft_template);
                        return (React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(MentionsInput, { value: (_d = t.message) !== null && _d !== void 0 ? _d : "", onChange: this.setMessage, streakFields: (_e = this.selectedPipelineFields) !== null && _e !== void 0 ? _e : undefined, streakUsers: template.streak_comment_template ? (streakUsers !== null && streakUsers !== void 0 ? streakUsers : undefined) : undefined, slackUsers: template.slack_template ? ((_f = slackUsers === null || slackUsers === void 0 ? void 0 : slackUsers.filter((user) => !user.deleted)) !== null && _f !== void 0 ? _f : undefined) : undefined }),
                            React.createElement(Typography, { variant: "caption" }, `${messagePlainText.length} characters`),
                            ((_g = Session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.isDeveloper) && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement(Typography, { variant: "caption" }, (_h = t.message) !== null && _h !== void 0 ? _h : "")))));
                    })()),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: bodyHelperText } })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(MailScheduleFormattersHelper, null)),
                    (template.email_template || template.email_draft_template || template.calendar_template) &&
                        (() => {
                            var _a, _b, _c;
                            const t = ((_b = (_a = template.email_template) !== null && _a !== void 0 ? _a : template.email_draft_template) !== null && _b !== void 0 ? _b : template.calendar_template);
                            return (React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "When enabled, user will be able to unsubscribe") },
                                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: (_c = t.allow_unsubscribe) !== null && _c !== void 0 ? _c : false, onChange: this.setAllowUnsubscribe, color: "primary" }), label: "Allow Unsubscribe" }))));
                        })(),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Typography, { variant: "h6", style: { color: "#666", fontWeight: "bold" } }, "Attachments")),
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Tooltip, { title: "Add attachment" },
                                    React.createElement("span", null,
                                        React.createElement(IconButton, { onClick: this.addAttachment, disabled: template.attachments.filter((a) => !a.trim()).length > 0 },
                                            React.createElement(Add, null))))))),
                    template.attachments.length > 0 && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(List, { dense: true }, template.attachments.map((attachment, indx) => (
                        // (OK here because it's not reorderable)
                        // eslint-disable-next-line react/no-array-index-key
                        React.createElement(ListItem, { key: indx },
                            React.createElement(ListItemText, { primary: React.createElement(TextField, { value: attachment, onChange: this.updateAttachment(indx), fullWidth: true, variant: "outlined", size: "small", placeholder: "https://drive.google.com/file/d/<file_id>/view" }) }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(Tooltip, { title: "Remove attachment" },
                                    React.createElement(IconButton, { onClick: this.removeAttachment(attachment) },
                                        React.createElement(Delete, null)))))))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "caption" }, "Press the + button above to add an attachment from Google Drive. At this time, Attachments only work with Email and Email Draft templates.")),
                    template.streak_attachments.length > 0 && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Grid, { container: true, direction: "column" },
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "body1" }, "Streak Files")),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Alert, { severity: "info" }, "The Streak Files feature is no longer supported now that we've migrated Seller Leads to the New CRM.")))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(List, { dense: true }, template.streak_attachments.map((attachment, indx) => (
                            // (OK here because it's not reorderable)
                            // eslint-disable-next-line react/no-array-index-key
                            React.createElement(ListItem, { key: indx },
                                React.createElement(ListItemText, { primary: attachment.file_type })))))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Typography, { variant: "body1" }, "Google Cloud Platform Bucket")),
                            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Tooltip, { title: "Add Google Cloud Platform file" },
                                    React.createElement("span", null,
                                        React.createElement(IconButton, { onClick: this.addGCPAttachment },
                                            React.createElement(Add, null))))))),
                    template.gcp_attachments.length > 0 && (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(List, { dense: true }, template.gcp_attachments.map((attachment, indx) => {
                            const attachmentBlobSplit = attachment.blob_name.split("/");
                            const attachmentDropdowns = attachmentBlobSplit.map((name, index) => {
                                const previousComponent = index === 0 ? "" : attachmentBlobSplit[index - 1];
                                const nameOptions = getBlobComponentChildren((gcpBlobs !== null && gcpBlobs !== void 0 ? gcpBlobs : [])
                                    .filter((blob) => {
                                    return blob.name.includes(attachmentBlobSplit.slice(0, index).join("/"));
                                })
                                    .map((blob) => {
                                    return blob.name;
                                }), previousComponent === "" ? undefined : previousComponent);
                                const prefix = name === "" ? attachment.blob_name : attachment.blob_name.split(name)[0];
                                return (React.createElement(Grid
                                // (OK here because it's not reorderable)
                                // eslint-disable-next-line react/no-array-index-key
                                , { 
                                    // (OK here because it's not reorderable)
                                    // eslint-disable-next-line react/no-array-index-key
                                    key: `gcp_attachment_blob_component_${name}_${attachment.blob_name}_${index}`, item: true, xs: 3, style: { padding: "4px" } },
                                    React.createElement(CoreChoiceField, { variant: "standard", 
                                        // label="Folder"
                                        required: true, fullWidth: true, value: name, onChoiceChange: this.updateGCPAttachmentBlobName(indx, prefix), style: { paddingRight: "8px" } }, nameOptions.map((item) => (React.createElement(MenuItem, { key: item, value: item }, item))))));
                            });
                            return (
                            // (OK here because it's not reorderable)
                            // eslint-disable-next-line react/no-array-index-key
                            React.createElement(ListItem, { key: indx },
                                React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true }, attachmentDropdowns), style: { paddingRight: "35px" } }),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(Tooltip, { title: "Preview attachment" },
                                        React.createElement(IconButton, { onClick: this.downloadGCPAttachment(indx), disabled: currentlyDownloadingGCPAttachments.includes(indx) },
                                            React.createElement(CloudDownload, null))),
                                    React.createElement(Tooltip, { title: "Remove attachment" },
                                        React.createElement(IconButton, { onClick: this.removeGCPAttachment(indx) },
                                            React.createElement(Delete, null))))));
                        })))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: "caption" }, "Press the + button above to add an attachment from a Google Cloud bucket. At this time, Attachments only work with Email and Email Draft templates.")),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Divider, null)),
                    this.selectedPipeline && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(BoxAutocomplete, { boxes: boxes !== null && boxes !== void 0 ? boxes : [], selectedBoxKey: selectedBoxKeyForPreview !== null && selectedBoxKeyForPreview !== void 0 ? selectedBoxKeyForPreview : undefined, onBoxSelected: this.setSelectedBoxKeyForPreview, AutocompleteProps: {
                                    autoComplete: true,
                                    forcePopupIcon: false,
                                    limitTags: 10,
                                    freeSolo: false,
                                    size: "small",
                                }, InputProps: {
                                    endAdornment: boxes == null && !selectedBoxKeyForPreview ? (React.createElement(InputAdornment, { position: "end", style: { paddingRight: "0.25rem" } },
                                        React.createElement(CircularProgress, { size: 20 }))) : (React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(Button, { color: "inherit", disabled: !selectedBoxKeyForPreview, onClick: this.openPreview }, "Preview"))),
                                }, TextFieldProps: {
                                    size: "small",
                                    placeholder: (boxes !== null && boxes !== void 0 ? boxes : []).length > 0 ? "Select a box or copy/paste a box key" : "Copy/paste a box key",
                                } })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Divider, null)))),
                    React.createElement(Grid, { item: true, container: true, xs: 12 },
                        React.createElement(Grid, { item: true, xs: uid ? 4 : 6 },
                            React.createElement(Button, { size: "small", color: "primary", variant: "contained", startIcon: React.createElement(Save, { fontSize: "small" }), onClick: this.save(), disabled: isDuplicating || isSaving || isDeleting || !this.isValid || !pipelines }, "Save")),
                        React.createElement(Grid, { item: true, xs: uid ? 4 : 6, style: { textAlign: uid ? "center" : "right" } },
                            React.createElement(Button, { size: "small", color: "inherit", variant: "contained", startIcon: React.createElement(FileCopy, { fontSize: "small" }), onClick: this.duplicate, disabled: isDuplicating || isSaving || isDeleting || !this.isValid || !pipelines }, "Save and Duplicate")),
                        uid && (React.createElement(Grid, { item: true, xs: 4, style: { textAlign: "right" } },
                            React.createElement(Button, { size: "small", color: "secondary", variant: "contained", startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: this.delete, disabled: isDuplicating || isSaving || isDeleting || !this.isValid }, "Delete"))))))));
    }
}
export default withDialog(withRouter(MailScheduleTemplateForm));
