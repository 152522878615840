import * as React from "react";
import { Search, Timeline } from "@mui/icons-material";
import { Alert, Typography, List, ListItemText, Stack, TextField, InputAdornment, ListItemButton, LinearProgress, } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import { keepPreviousData } from "@tanstack/react-query";
import CoreLoading from "@app/common/CoreLoading";
import RouterLink from "@app/common/RouterLink";
import { useListUserActivity } from "@app/orval/api/activity";
import { formatDate_Dayjs } from "@app/util/Utils";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const RecentActivity = (props) => {
    var _a, _b;
    const { userUid } = props;
    const [search, setSearch] = React.useState("");
    const [searchText, setSearchText] = React.useState("");
    const listActivityApi = useListUserActivity({ user_uid: userUid, search }, { query: { placeholderData: keepPreviousData } });
    const activity = (_b = (_a = listActivityApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const debouncedSearch = React.useRef(null);
    const onSearchTextChanged = React.useCallback((event) => {
        setSearchText(event.target.value);
        if (debouncedSearch.current) {
            window.clearTimeout(debouncedSearch.current);
        }
        debouncedSearch.current = window.setTimeout(() => {
            setSearch(event.target.value);
        }, 500);
    }, [setSearchText]);
    return (React.createElement(CollapsibleSection, { icon: React.createElement(Timeline, null), title: "Recent Activity", isCollapsedInitially: true },
        React.createElement(Stack, { spacing: 2 },
            listActivityApi.error && React.createElement(Alert, { severity: "error" }, JSON.stringify(format(listActivityApi.error))),
            React.createElement(TextField, { size: "small", fullWidth: true, slotProps: {
                    input: {
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement(Search, null))),
                    },
                }, value: searchText, onChange: onSearchTextChanged }),
            listActivityApi.isFetching && !activity && React.createElement(CoreLoading, null),
            listActivityApi.isFetching && activity && React.createElement(LinearProgress, { variant: "indeterminate" }),
            activity && activity.length === 0 && React.createElement(Typography, { variant: "body2" }, "No activity found"),
            activity && activity.length > 0 && (React.createElement(List, { dense: true, style: { overflowX: "hidden" } }, activity.slice(0, 10).map((item) => (React.createElement(ListItemButton, { key: `${item.uid}__${item.opened}__${item.url}`, to: item.url, component: RouterLink },
                React.createElement(ListItemText, { primary: item.url, secondary: formatDate_Dayjs(item.opened, "dddd, MMMM D, YYYY h:mm A") })))))))));
};
export default RecentActivity;
