import * as React from "react";
import { FilterList } from "@mui/icons-material";
import { Box, Divider, Fab, Grid2 } from "@mui/material";
import Details from "./components/Details";
import Filters from "./components/Filters";
import Graph from "./components/Graph";
import { RequestDebuggingContext } from "./RequestDebuggingContext";
const RequestDebuggingInner = () => {
    const context = React.useContext(RequestDebuggingContext);
    const showFilters = React.useCallback(() => {
        context.setIsFiltersOpen(!context.isFiltersOpen);
    }, [context]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", style: { height: "100%" } },
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%", overflowY: "auto" } },
                    React.createElement(Grid2, { style: { height: "300px" } },
                        React.createElement(Graph, null)),
                    context.selectedRequest && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, null,
                            React.createElement(Divider, null)),
                        React.createElement(Grid2, null,
                            React.createElement(Box, { p: 2 },
                                React.createElement(Details, null))))))),
            context.isFiltersOpen && (React.createElement(Grid2, null,
                React.createElement(Divider, { orientation: "vertical" }))),
            context.isFiltersOpen && (React.createElement(Grid2, { style: { width: "300px" } },
                React.createElement(Filters, null)))),
        !context.isFiltersOpen && (React.createElement(Fab, { onClick: showFilters, style: { position: "fixed", bottom: 20, right: 20 } },
            React.createElement(FilterList, null)))));
};
export default RequestDebuggingInner;
