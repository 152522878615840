import * as React from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MaterialIcon from "@app/common/MaterialIcon";
import useSession from "@app/hooks/useSession";
import { searchBuyerContracts } from "@app/orval/api/buyer-contracts";
import { searchBuyerLeads } from "@app/orval/api/buyer-leads";
import { searchContacts } from "@app/orval/api/contacts";
import { listQfos } from "@app/orval/api/forms-questions-for-the-offer";
import { listPsps } from "@app/orval/api/psps";
import { searchRecruits } from "@app/orval/api/recruits";
import { searchSellerContracts } from "@app/orval/api/seller-contracts";
import { searchSellerLeads } from "@app/orval/api/seller-leads";
import { useGetSidebar } from "@app/orval/api/sidebar";
import { listUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import NoResults from "./NoResults";
import SearchResultsList from "./SearchResultsList";
import getMuiTheme from "./theme";
import { sortResultsFn } from "./utils";
const SEARCH_TYPES = [
    "pages",
    "users",
    "psps",
    "qfos",
    "contacts",
    "buyerLeads",
    "sellerLeads",
    "buyerContracts",
    "sellerContracts",
    "recruits",
];
const Search = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { TextFieldProps, clearWhenEscapePressed = true, defaultSearchString = "", onItemSelected, searchTypes, showNoResults = true, style, variant = "standard", } = props;
    const searchAbortController = React.useRef(null);
    const session = useSession();
    const returnsPages = searchTypes == null || searchTypes.includes("pages");
    const returnsUsers = ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) && (searchTypes == null || searchTypes.includes("users"));
    const returnsPSPs = ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.isInternal) && (searchTypes == null || searchTypes.includes("psps"));
    const returnsQFOs = ((_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.isInternal) && (searchTypes == null || searchTypes.includes("qfos"));
    const returnsContacts = ((_d = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.hasRole(["Superadmin", "Admin"])) &&
        (searchTypes == null || searchTypes.includes("contacts"));
    const returnsBuyerLeads = ((_e = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _e === void 0 ? void 0 : _e.isInternal) && (searchTypes == null || searchTypes.includes("buyerLeads"));
    const returnsSellerLeads = ((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.isInternal) && (searchTypes == null || searchTypes.includes("sellerLeads"));
    const returnsBuyerContracts = ((_g = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.isInternal) && (searchTypes == null || searchTypes.includes("buyerContracts"));
    const returnsSellerContracts = ((_h = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _h === void 0 ? void 0 : _h.isInternal) && (searchTypes == null || searchTypes.includes("sellerContracts"));
    const returnsRecruits = ((_j = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _j === void 0 ? void 0 : _j.isInternal) && (searchTypes == null || searchTypes.includes("recruits"));
    const [results, setResults] = React.useState([]);
    const [searchString, setSearchString] = React.useState(defaultSearchString);
    const [isSearchingUsers, setIsSearchingUsers] = React.useState(false);
    const [isSearchingPSPs, setIsSearchingPSPs] = React.useState(false);
    const [isSearchingQFOs, setIsSearchingQFOs] = React.useState(false);
    const [isSearchingContacts, setIsSearchingContacts] = React.useState(false);
    const [isSearchingBuyerLeads, setIsSearchingBuyerLeads] = React.useState(false);
    const [isSearchingSellerLeads, setIsSearchingSellerLeads] = React.useState(false);
    const [isSearchingBuyerContracts, setIsSearchingBuyerContracts] = React.useState(false);
    const [isSearchingSellerContracts, setIsSearchingSellerContracts] = React.useState(false);
    const [isSearchingRecruits, setIsSearchingRecruits] = React.useState(false);
    React.useEffect(() => {
        setSearchString(defaultSearchString);
    }, [defaultSearchString]);
    const getSidebarApi = useGetSidebar(INFINITE_CACHE_PARAMS);
    const sidebar = (_l = (_k = getSidebarApi.data) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : null;
    const isSearching = isSearchingUsers ||
        isSearchingPSPs ||
        isSearchingQFOs ||
        isSearchingContacts ||
        isSearchingBuyerLeads ||
        isSearchingSellerLeads ||
        isSearchingBuyerContracts ||
        isSearchingSellerLeads ||
        isSearchingSellerContracts ||
        isSearchingRecruits;
    React.useEffect(() => {
        return () => {
            var _a;
            (_a = searchAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        };
    }, []);
    const closeResults = React.useCallback((clearSearchString = false) => {
        console.log("closing results");
        setResults([]);
        if (!onItemSelected || clearSearchString) {
            setSearchString("");
        }
        setIsSearchingBuyerContracts(false);
        setIsSearchingBuyerLeads(false);
        setIsSearchingContacts(false);
        setIsSearchingSellerContracts(false);
        setIsSearchingSellerLeads(false);
        setIsSearchingUsers(false);
        setIsSearchingPSPs(false);
        setIsSearchingQFOs(false);
        setIsSearchingRecruits(false);
        if (searchAbortController.current) {
            searchAbortController.current.abort();
        }
    }, [onItemSelected]);
    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                console.log("Escape key pressed");
                closeResults(clearWhenEscapePressed);
            }
        };
        // Add event listener
        document.addEventListener("keydown", handleKeyDown);
        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const debouncedSearch = React.useRef(null);
    const doSearch = React.useCallback((str) => {
        var _a;
        (_a = searchAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        const searchStr = str.trim();
        console.log({ searchStr });
        if (searchStr) {
            setResults([]);
            searchAbortController.current = new AbortController();
            if (returnsPages) {
                setResults((prev) => {
                    const pageResults = (session === null || session === void 0 ? void 0 : session.viewingAsUser)
                        ? sidebar === null || sidebar === void 0 ? void 0 : sidebar.sections.map((section) => section.items).flat().filter((item) => item.name.toLowerCase().trim().includes(searchStr))
                        : [];
                    return [
                        ...prev,
                        ...(pageResults !== null && pageResults !== void 0 ? pageResults : []).map((x) => ({
                            page: Object.assign(Object.assign({}, x), { icon: x.iconName ? React.createElement(MaterialIcon, { name: x.iconName }) : null }),
                        })),
                    ];
                });
            }
            if (returnsUsers) {
                setIsSearchingUsers(true);
                listUsers(undefined, { signal: searchAbortController.current.signal })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data
                            .filter((item) => {
                            return [item.first_name, item.last_name]
                                .map((o) => (o !== null && o !== void 0 ? o : "").trim())
                                .join(" ")
                                .toLowerCase()
                                .includes(searchStr);
                        })
                            .map((item) => ({
                            user: item,
                        })),
                    ]);
                    setIsSearchingUsers(false);
                })
                    .catch((error) => {
                    setIsSearchingUsers(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsPSPs) {
                setIsSearchingPSPs(true);
                listPsps({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            psp: item,
                        })),
                    ]);
                    setIsSearchingPSPs(false);
                })
                    .catch((error) => {
                    setIsSearchingPSPs(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsQFOs) {
                setIsSearchingQFOs(true);
                listQfos({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            qfo: item,
                        })),
                    ]);
                    setIsSearchingQFOs(false);
                })
                    .catch((error) => {
                    setIsSearchingQFOs(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsContacts) {
                setIsSearchingContacts(true);
                searchContacts({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            contact: item,
                        })),
                    ]);
                    setIsSearchingContacts(false);
                })
                    .catch((error) => {
                    setIsSearchingContacts(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsBuyerLeads) {
                setIsSearchingBuyerLeads(true);
                searchBuyerLeads({
                    search: searchStr,
                    limit: 10,
                }, { signal: searchAbortController.current.signal })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            buyerLead: item,
                        })),
                    ]);
                    setIsSearchingBuyerLeads(false);
                })
                    .catch((error) => {
                    setIsSearchingBuyerLeads(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsSellerLeads) {
                setIsSearchingSellerLeads(true);
                searchSellerLeads({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            sellerLead: item,
                        })),
                    ]);
                    setIsSearchingSellerLeads(false);
                })
                    .catch((error) => {
                    setIsSearchingSellerLeads(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsBuyerContracts) {
                setIsSearchingBuyerContracts(true);
                searchBuyerContracts({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            buyerContract: item,
                        })),
                    ]);
                    setIsSearchingBuyerContracts(false);
                })
                    .catch((error) => {
                    setIsSearchingBuyerContracts(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsSellerContracts) {
                setIsSearchingSellerContracts(true);
                searchSellerContracts({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            sellerContract: item,
                        })),
                    ]);
                    setIsSearchingSellerContracts(false);
                })
                    .catch((error) => {
                    setIsSearchingSellerContracts(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
            if (returnsRecruits) {
                setIsSearchingRecruits(true);
                searchRecruits({
                    search: searchStr,
                    limit: 10,
                }, {
                    signal: searchAbortController.current.signal,
                })
                    .then((result) => {
                    setResults((prev) => [
                        ...prev,
                        ...result.data.map((item) => ({
                            recruit: item,
                        })),
                    ]);
                    setIsSearchingRecruits(false);
                })
                    .catch((error) => {
                    setIsSearchingRecruits(false);
                    if (error.name !== "CanceledError") {
                        console.error(error);
                    }
                });
            }
        }
        else {
            setResults([]);
            setIsSearchingUsers(false);
            setIsSearchingPSPs(false);
            setIsSearchingQFOs(false);
            setIsSearchingContacts(false);
            setIsSearchingBuyerLeads(false);
            setIsSearchingBuyerContracts(false);
            setIsSearchingSellerLeads(false);
            setIsSearchingSellerContracts(false);
            setIsSearchingRecruits(false);
        }
    }, [
        sidebar,
        setResults,
        returnsPages,
        returnsUsers,
        returnsPSPs,
        returnsQFOs,
        returnsContacts,
        returnsBuyerLeads,
        returnsSellerLeads,
        returnsBuyerContracts,
        returnsSellerContracts,
        returnsRecruits,
        session === null || session === void 0 ? void 0 : session.viewingAsUser,
    ]);
    const setSearchString_ = React.useCallback((e) => {
        if (TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.onChange) {
            TextFieldProps.onChange(e);
        }
        setSearchString(e.target.value);
        if (debouncedSearch.current) {
            window.clearTimeout(debouncedSearch.current);
        }
        debouncedSearch.current = window.setTimeout(() => {
            doSearch(e.target.value);
        }, 500);
    }, [TextFieldProps, doSearch]);
    const inner = (React.createElement("div", { style: { position: "relative", width: variant === "navbar" ? "450px" : "100%" } },
        React.createElement(TextField, Object.assign({ fullWidth: true, size: "small" }, TextFieldProps, { onChange: setSearchString_, value: searchString !== null && searchString !== void 0 ? searchString : "", slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign(Object.assign({ startAdornment: (React.createElement(InputAdornment, { position: "start" },
                        React.createElement(SearchIcon, null))) }, (_m = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _m === void 0 ? void 0 : _m.input), { endAdornment: isSearching ? (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(CircularProgress, { size: 20, style: { color: variant === "navbar" ? "white" : undefined } }))) : (
                    // @ts-expect-error: seems like mui's typings aren't great here
                    (_p = (_o = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _o === void 0 ? void 0 : _o.input) === null || _p === void 0 ? void 0 : _p.endAdornment) }) }) })),
        !isSearching && searchString.trim() && results.length === 0 && showNoResults && React.createElement(NoResults, null),
        results.length > 0 && searchString.trim() && (React.createElement(SearchResultsList, { results: results.sort(sortResultsFn), onClose: closeResults, onClick: closeResults, onItemSelected: onItemSelected }))));
    if (variant === "navbar") {
        return (React.createElement(ThemeProvider, { theme: getMuiTheme({ isSearching, searchString, height: style === null || style === void 0 ? void 0 : style.height }) }, inner));
    }
    return inner;
};
export default Search;
