import * as React from "react";
import { Help } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Divider, Grid2, InputAdornment, Paper, Stepper, Step, StepLabel, Tooltip, Typography, } from "@mui/material";
import DOMPurify from "dompurify";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { withDialog } from "@app/hoc/withDialog";
import { withRouter } from "@app/hoc/withRouter";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import AppManager from "@app/util/AppManager";
const FormField = (props) => {
    var _a, _b;
    const { formField, onSaveBegin, schema, 
    // override,
    submission, } = props;
    const field = schema[formField.name];
    if (!field) {
        console.error(`No field named ${formField.name}`);
        return null;
    }
    const { helperText } = formField;
    return (React.createElement(Grid2, { key: field.name, size: { xs: (_a = formField.xs) !== null && _a !== void 0 ? _a : 12, sm: formField.sm, md: formField.md, lg: formField.lg, xl: formField.xl } },
        React.createElement(DashAutoSaveField, { variant: "standard", size: "small", fullWidth: true, label: ((_b = props.label) !== null && _b !== void 0 ? _b : field.name) + (formField.required ? "*" : ""), field: schema[field.name], value: submission.fields[field.name], onSaveBegin: onSaveBegin(field.name), helperText: helperText, useCheckboxes: !(schema[field.name].field_type === "choice" && schema[field.name].allows_multiple) ? undefined : true, apiEndpoint: `/api/forms/questions_for_the_offer/${submission.uid}`, InputProps: field.help_text
                ? {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(Tooltip, { title: React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(field.help_text) } }) },
                            React.createElement(Help, { fontSize: "small" })))),
                }
                : undefined, hasPicker: field.field_type === "date" ? true : undefined, style: {
                background: field.field_type !== "checkbox" &&
                    (submission.fields[field.name] == null || submission.fields[field.name] === "")
                    ? "#ffe8e8"
                    : undefined,
            } })));
};
FormField.defaultProps = {
    label: null,
    override: false,
};
class QuestionsForTheInsurance extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                schema: null,
                submission: null,
                isSubmitting: false,
                error: null,
                activeStep: 0,
            }
        });
        Object.defineProperty(this, "formFields", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: [
                [
                    {
                        name: "Buyer #1 Name",
                        required: true,
                        xs: 12,
                        sm: 4,
                    },
                    {
                        name: "Buyer #1 Date of Birth",
                        required: true,
                        xs: 12,
                        sm: 4,
                    },
                    {
                        name: "Marital Status",
                        required: true,
                        xs: 12,
                        sm: 4,
                    },
                    {
                        name: "Property Address",
                        required: true,
                        xs: 12,
                        sm: 4,
                    },
                    {
                        name: "Phone",
                        required: true,
                        xs: 12,
                        sm: 4,
                    },
                    {
                        name: "Buyer #2 Name",
                        xs: 12,
                        sm: 4,
                    },
                    {
                        name: "Buyer #2 Date of Birth",
                        xs: 12,
                        sm: 4,
                    },
                ],
                [
                    {
                        name: "County",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Property Type",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Property Description",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "Year Built",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Total Living Area SqFt",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Construction Type",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Exterior Finish",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Fireplace",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Heating",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Fuel Heat",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "A/C",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Roof",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Total Baths",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Foundation",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Security System",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Pool",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                ],
                [
                    {
                        name: "Occupancy Type",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Prior Carrier",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "Length of time w/ prior insurance carrier",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "What industry are you currently employed in?",
                        required: true,
                        xs: 12,
                        md: 6,
                    },
                    {
                        name: "What is your Occupation?",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "What is your highest level of education?",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "Smokers in household?",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "Do you own any Jewelry you need insured?",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "Jewelry Description",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "Will you own a trampoline at your NEW home ?",
                        required: true,
                        xs: 12,
                    },
                    {
                        name: "Is there a Deductible amount you'd prefer?",
                        required: true,
                        xs: 12,
                    },
                ],
            ]
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { uid } = this.props.params;
                this.setState({
                    schema: null,
                    submission: null,
                });
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.fields.list({
                    query: {
                        entity_type: "Pre-Offer",
                    },
                    onSuccess: (result) => {
                        const schema = {};
                        for (const field of result) {
                            schema[field.name] = field;
                        }
                        this.setState({
                            schema,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                schema: null,
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                });
                if (uid) {
                    API.questionsForTheOfferSubmissions.get(uid, {
                        onSuccess: (result) => {
                            console.log("Got form data", result);
                            this.setState({
                                submission: result,
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    submission: null,
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.abortController.signal,
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "setFieldValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (fieldName) => (value) => {
                const shouldClearJewelryDetail = fieldName === "Do you own any Jewelry you need insured?" && !value;
                this.setState((prevState) => {
                    if (shouldClearJewelryDetail &&
                        prevState.submission.fields["Do you own any Jewelry you need insured?"] != null) {
                        API.questionsForTheOfferSubmissions.update(prevState.submission.uid, {
                            data: {
                                fields: {
                                    "Jewelry Description": undefined,
                                },
                            },
                        });
                    }
                    return {
                        submission: Object.assign(Object.assign({}, prevState.submission), { fields: Object.assign(Object.assign({}, prevState.submission.fields), { "Jewelry Description": shouldClearJewelryDetail
                                    ? undefined
                                    : prevState.submission.fields["Jewelry Description"], [fieldName]: value }) }),
                    };
                });
            }
        });
        Object.defineProperty(this, "previous", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    activeStep: prevState.activeStep - 1,
                }));
            }
        });
        Object.defineProperty(this, "next", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState((prevState) => ({
                    activeStep: prevState.activeStep + 1,
                }));
            }
        });
        Object.defineProperty(this, "submit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { submission } = this.state;
                this.setState({
                    isSubmitting: true,
                });
                API.questionsForTheOfferSubmissions.submitQFI(submission.uid, {
                    onSuccess: () => {
                        this.setState((prevState) => ({
                            isSubmitting: false,
                            submission: Object.assign(Object.assign({}, prevState.submission), { qfi_submitted_at: new Date().toISOString() }),
                        }));
                    },
                    onError: (error) => {
                        this.setState({
                            isSubmitting: false,
                        });
                        this.props.showErrorDialog(error);
                    },
                });
            }
        });
        Object.defineProperty(this, "isStepValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (step) => {
                const schema = this.state.schema;
                const submission = this.state.submission;
                const invalidFields = this.formFields[step].filter((field) => field.required &&
                    schema[field.name] &&
                    schema[field.name].field_type !== "checkbox" &&
                    (submission.fields[field.name] === "" || submission.fields[field.name] == null));
                console.warn("Invalid fields: ", invalidFields.map((field) => field.name));
                return invalidFields.length === 0;
            }
        });
    }
    componentDidMount() {
        AppManager.shared.pageTitle = { title: "QFI" };
        this.refresh();
    }
    componentDidUpdate(prevProps) {
        if (this.props.params.uid !== prevProps.params.uid) {
            this.refresh();
        }
    }
    componentWillUnmount() {
        var _a;
        AppManager.shared.pageTitle = {};
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
    }
    render() {
        const { activeStep, error, isSubmitting, schema, submission } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (submission && submission.qfi_submitted_at) {
            return (React.createElement(Box, { style: { background: AppConfig.themeColors.lightestblue, height: "100%", overflow: "auto" } },
                React.createElement(Container, { maxWidth: "xs" },
                    React.createElement(Box, { p: 2 },
                        React.createElement(Paper, { elevation: 4 },
                            React.createElement(Box, { p: 2 },
                                React.createElement(Grid2, { container: true, spacing: 2 },
                                    React.createElement(Grid2, { size: 12 },
                                        React.createElement(Typography, null, "Thanks for your submission!")))))))));
        }
        if (!schema || !submission) {
            return React.createElement(CoreLoading, null);
        }
        console.log(submission);
        const lastStep = 2;
        return (React.createElement(Box, { style: { background: AppConfig.themeColors.lightestblue, height: "100%", overflow: "auto" } },
            React.createElement(Container, { maxWidth: "lg" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Paper, { elevation: 4 },
                        React.createElement(Box, { p: 2 },
                            React.createElement(Grid2, { container: true, spacing: 2 },
                                React.createElement(Grid2, { size: 12 },
                                    React.createElement(Typography, { variant: "h6" }, "Questions for the Insurance")),
                                React.createElement(Grid2, { size: 12 },
                                    React.createElement(Divider, null)),
                                !submission.qfi_submitted_at && (React.createElement(React.Fragment, null,
                                    React.createElement(Grid2, { size: 12 },
                                        React.createElement(Stepper, { activeStep: activeStep },
                                            React.createElement(Step, null,
                                                React.createElement(StepLabel, null, "Basic Info")),
                                            React.createElement(Step, null,
                                                React.createElement(StepLabel, null, "Property Info")),
                                            React.createElement(Step, null,
                                                React.createElement(StepLabel, null, "Additional Info")))),
                                    React.createElement(Grid2, { size: 12 },
                                        React.createElement(Container, { maxWidth: "md" },
                                            React.createElement(Grid2, { container: true, spacing: 2 }, this.formFields[activeStep].map((formField) => (React.createElement(FormField, { key: formField.name, formField: formField, submission: submission, schema: schema, onSaveBegin: this.setFieldValue })))))),
                                    React.createElement(Grid2, { size: 12, style: { textAlign: "right" } },
                                        activeStep > 0 && activeStep <= lastStep && (React.createElement(Button, { size: "small", color: "inherit", variant: "contained", onClick: this.previous, style: { marginRight: "1rem" }, disabled: isSubmitting }, "Previous")),
                                        activeStep < lastStep && (React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: this.next, disabled: !this.isStepValid(activeStep) || isSubmitting }, "Next")),
                                        activeStep === lastStep && (React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: this.submit, disabled: !this.isStepValid(activeStep) || isSubmitting, endIcon: isSubmitting && React.createElement(CircularProgress, { size: 20 }) }, "Submit"))))),
                                submission.qfi_submitted_at && (React.createElement(Grid2, { size: 12 },
                                    React.createElement(Typography, null, "Thank you for your submission!"))))))))));
    }
}
export default withDialog(withRouter(QuestionsForTheInsurance));
