import * as React from "react";
import { Grid2, Typography } from "@mui/material";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import AppConfig from "@app/util/AppConfig";
import { googleMapsAPIKey } from "@app/util/Utils";
import MLSPhoto from "../MLSPhoto";
import { mapStyles, allMlsStatusesLowercase } from "../utils";
const CompsPullerGoogleMap = (props) => {
    var _a, _b, _c;
    const { properties, subjectProperty, toggleSelected, zoom } = props;
    const [hoveredProperty, setHoveredProperty] = React.useState(null);
    const center = React.useMemo(() => {
        return {
            lat: subjectProperty.data["Latitude"],
            lng: subjectProperty.data["Longitude"],
        };
    }, [subjectProperty]);
    const onMarkerSelect = React.useCallback((property) => () => {
        const status = property.data["StandardStatus"];
        if (status && allMlsStatusesLowercase.includes(status.toLowerCase().trim())) {
            toggleSelected(property.mlsnumber);
        }
    }, [toggleSelected]);
    const onMarkerHover = React.useCallback((property) => () => {
        setHoveredProperty(property);
    }, []);
    const onMarkerLoad = React.useCallback((color) => (marker) => {
        const customIcon = (opts) => (Object.assign({ path: "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z", fillColor: "#f00", fillOpacity: 1, strokeColor: "#000", strokeWeight: 1, scale: 3.5 }, opts));
        marker.setIcon(customIcon({
            fillColor: color,
            strokeColor: "white",
        }));
        return marker;
    }, []);
    return (React.createElement(LoadScript, { googleMapsApiKey: googleMapsAPIKey },
        React.createElement(GoogleMap, { mapContainerStyle: mapStyles, zoom: zoom, center: center },
            properties.map((property) => {
                const status = property.data["StandardStatus"];
                let color;
                if (property.mlsnumber.toLowerCase().trim() === subjectProperty.mlsnumber.toLowerCase().trim()) {
                    color = AppConfig.themeColors.red;
                }
                else if (!status || !allMlsStatusesLowercase.includes(status.toLowerCase().trim())) {
                    color = AppConfig.themeColors.greyLighten;
                }
                else if (property.selected) {
                    color = AppConfig.themeColors.blue;
                }
                else {
                    color = AppConfig.themeColors.lightblue;
                }
                const lat = property.data["Latitude"];
                const lng = property.data["Longitude"];
                let zIndex = 0;
                if (property.mlsnumber === subjectProperty.mlsnumber) {
                    // subject property icon should be on top
                    zIndex = 2;
                }
                else if (property.selected) {
                    // selected properties should be above non-selected
                    zIndex = 1;
                }
                return (React.createElement(Marker, { key: `${lat} / ${lng} / ${property.address} / ${property.mlsnumber} / ${color}`, position: {
                        lat,
                        lng,
                    }, zIndex: zIndex, onLoad: onMarkerLoad(color), onClick: onMarkerSelect(property), onMouseOver: onMarkerHover(property), onMouseOut: onMarkerHover(null) }));
            }),
            hoveredProperty && (React.createElement(InfoWindow, { position: {
                    lat: hoveredProperty.data["Latitude"],
                    lng: hoveredProperty.data["Longitude"],
                }, options: {
                    disableAutoPan: true,
                }, onCloseClick: onMarkerHover(null) },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, null, hoveredProperty.address)),
                    ((_a = hoveredProperty.data["Media"]) !== null && _a !== void 0 ? _a : []).length > 0 ? (React.createElement(Grid2, { size: 12 },
                        React.createElement(MLSPhoto, { alt: (_b = hoveredProperty.data["Media"][0]["ShortDescription"]) !== null && _b !== void 0 ? _b : "", src: hoveredProperty.data["Media"][0]["MediaURL"], mediaKey: (_c = hoveredProperty.data["Media"][0]["MediaKey"]) !== null && _c !== void 0 ? _c : undefined, height: "75px", style: { display: "flex", margin: "auto" } }))) : null))))));
};
export default CompsPullerGoogleMap;
