import * as React from "react";
import { AddOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import dayjs_ from "@app/common/dayjs";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListFields } from "@app/orval/api/field-definitions";
import { countRecruits, countRecruitsByStage, downloadRecruits, searchRecruits, useAddRecruit, useBulkDeleteRecruits, } from "@app/orval/api/recruits";
import { FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import RecruitingActionsMenu from "./RecruitingActionsMenu";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { StandardSmallCell } from "../components/spreadsheet-view/components";
import Spreadsheet from "../components/spreadsheet-view/Spreadsheet";
import { SpreadsheetContext } from "../components/spreadsheet-view/SpreadsheetContext";
import { collaboratorCellValueFormatter, renderCollaboratorsCell, renderInterviewsCell, } from "../components/spreadsheet-view/table-cells";
import { DAYS_IN_STAGE_COLUMN } from "../components/spreadsheet-view/utils";
import useCollaborators from "../hooks/useCollaborators";
import useStages from "../hooks/useStages";
import { groupBy, indexBy } from "../utils/utils";
const Recruits = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const stage = (_a = props.stage) !== null && _a !== void 0 ? _a : undefined;
    const limit = (_b = props.limit) !== null && _b !== void 0 ? _b : 50;
    const offset = (_c = props.offset) !== null && _c !== void 0 ? _c : 0;
    const showNewsfeed = (_d = props.showNewsfeed) !== null && _d !== void 0 ? _d : false;
    const { search, view } = props;
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const errorDialog = useErrorDialog();
    const context = React.useContext(SpreadsheetContext);
    const session = useSession();
    const bulkDeleteApi = useBulkDeleteRecruits();
    const { listRecruitStagesApi: listStagesApi, recruitStages: stages, setRecruitingStage } = useStages();
    const { addCollaboratorForRecruit, canEditCollaboratorsCells, closeCollaboratorsDialog, collaboratorsDialogParams, isCollaboratorsDialogOpen, openCollaboratorsDialog, removeCollaboratorForRecruit, setCollaboratorsDialogParams, updateCollaboratorForRecruit, } = useCollaborators();
    const requestData = React.useMemo(() => {
        var _a;
        return pickBy({ search, view, stage_uids: stage ? [stage] : undefined, filter: (_a = context === null || context === void 0 ? void 0 : context.filter) !== null && _a !== void 0 ? _a : undefined }, (val) => val !== undefined);
    }, [context === null || context === void 0 ? void 0 : context.filter, search, stage, view]);
    const paginatedRequestData = React.useMemo(() => {
        return Object.assign(Object.assign({}, requestData), { limit, offset });
    }, [requestData, limit, offset]);
    const searchRecruitsApi = usePostQuery(searchRecruits, paginatedRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const recruits = (_f = (_e = searchRecruitsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const countRecruitsApi = usePostQuery(countRecruits, requestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const count = (_h = (_g = countRecruitsApi.data) === null || _g === void 0 ? void 0 : _g.data.count) !== null && _h !== void 0 ? _h : null;
    const totalRecruitsApi = usePostQuery(countRecruits, {}, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const total = (_k = (_j = totalRecruitsApi.data) === null || _j === void 0 ? void 0 : _j.data.count) !== null && _k !== void 0 ? _k : null;
    const countRecruitsByStageApi = usePostQuery(countRecruitsByStage, omit(requestData, "stage_uids"), FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const countByStage = (_m = (_l = countRecruitsByStageApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const items = React.useMemo(() => {
        return recruits
            ? recruits.map((item) => (Object.assign({}, item)))
            : null;
    }, [recruits]);
    const countByStage_allStages = React.useMemo(() => {
        return stages && countByStage
            ? stages.map((s) => { var _a, _b; return ({ stage: s, count: (_b = (_a = countByStage.find((c) => { var _a; return ((_a = c.stage) === null || _a === void 0 ? void 0 : _a.uid) === s.uid; })) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0 }); })
            : null;
    }, [stages, countByStage]);
    const updateSearch = useUpdateSearch();
    const countByGroup = React.useMemo(() => {
        return countByStage_allStages
            ? countByStage_allStages.map((item) => {
                var _a, _b, _c;
                return ({
                    group: {
                        uid: (_a = item.stage.uid) !== null && _a !== void 0 ? _a : "",
                        name: (_b = item.stage.name) !== null && _b !== void 0 ? _b : "Not Set",
                        color: (_c = stages === null || stages === void 0 ? void 0 : stages.find((s) => s.uid === item.stage.uid)) === null || _c === void 0 ? void 0 : _c.color,
                        onClick: () => {
                            var _a;
                            updateSearch("stage", stage === item.stage.uid ? null : ((_a = item.stage.uid) !== null && _a !== void 0 ? _a : null), "offset", null);
                        },
                        isSelected: stage === item.stage.uid,
                    },
                    count: item.count,
                });
            })
            : null;
    }, [countByStage_allStages, stage, stages, updateSearch]);
    const listFieldsApi = useListFields({ entity_type: "Recruit" }, REFETCH_CACHE_PARAMS);
    const fields = (_p = (_o = listFieldsApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const recruitsGroupedByUid = React.useMemo(() => {
        return recruits ? groupBy(recruits, "uid") : null;
    }, [recruits]);
    const itemsByUid = React.useMemo(() => {
        return items ? groupBy(items, "uid") : null;
    }, [items]);
    const itemsIndexedByUid = React.useMemo(() => {
        return items ? indexBy(items, "uid") : null;
    }, [items]);
    const stagesByUid = React.useMemo(() => {
        return stages ? groupBy(stages, "uid") : null;
    }, [stages]);
    const createItemApi = useAddRecruit();
    const createItem = React.useCallback(() => {
        createItemApi
            .mutateAsync({ data: { fields: {} } })
            .then((result) => {
            navigate(`/recruits/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [createItemApi, errorDialog, navigate]);
    const onBulkUpdate = React.useCallback((items_) => {
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    const updatedItem = items_.find((x) => x.uid === item.uid);
                    return updatedItem !== null && updatedItem !== void 0 ? updatedItem : item;
                }) });
        });
    }, [queryClient, searchRecruitsApi.queryKey]);
    const bulkDelete = React.useCallback((selectedUids) => {
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.filter((item) => !selectedUids.includes(item.uid)) });
        });
        queryClient.setQueryData(countRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        queryClient.setQueryData(countRecruitsByStageApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => (Object.assign(Object.assign({}, item), { count: item.count - selectedUids.filter((uid) => { var _a, _b, _c; return ((_b = (_a = itemsByUid === null || itemsByUid === void 0 ? void 0 : itemsByUid[uid]) === null || _a === void 0 ? void 0 : _a.stage) === null || _b === void 0 ? void 0 : _b.uid) === ((_c = item.stage) === null || _c === void 0 ? void 0 : _c.uid); }).length }))) });
        });
        queryClient.setQueryData(totalRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: { count: prev.data.count - selectedUids.length } });
        });
        bulkDeleteApi
            .mutateAsync({ data: { uids: selectedUids } })
            .then(() => {
            enqueueSnackbar(`${selectedUids.length} recruits deleted`, { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [
        bulkDeleteApi,
        countRecruitsApi.queryKey,
        countRecruitsByStageApi.queryKey,
        itemsByUid,
        queryClient,
        searchRecruitsApi.queryKey,
        totalRecruitsApi.queryKey,
    ]);
    const refresh = React.useCallback(() => {
        searchRecruitsApi.refetch();
        countRecruitsApi.refetch();
        countRecruitsByStageApi.refetch();
    }, [countRecruitsApi, countRecruitsByStageApi, searchRecruitsApi]);
    const getPOSTApiEndpoint = React.useCallback((row) => {
        return `/api/recruits/${row.uid}`;
    }, []);
    const getMailMergeEmailAddressesForItem = React.useCallback((row) => {
        return [row.fields["Personal Email"]].filter((x) => x).map((x) => x);
    }, []);
    const getDetailURL = React.useCallback((row) => {
        const item = itemsByUid ? itemsByUid[row] : null;
        if (item) {
            return `/recruits/${item.uid}`;
        }
        return "";
    }, [itemsByUid]);
    const onStagesSaved = React.useCallback((allStages) => {
        queryClient.setQueryData(listStagesApi.queryKey, (prev) => {
            if (prev == null)
                return prev;
            return Object.assign(Object.assign({}, prev), { data: allStages });
        });
    }, [listStagesApi.queryKey, queryClient]);
    const nonStandardColumns = [
        {
            field: "collaborators",
            headerName: "Collaborators",
            valueFormatter: collaboratorCellValueFormatter,
            renderCell: renderCollaboratorsCell,
            renderEditCell: renderCollaboratorsCell,
            editable: canEditCollaboratorsCells,
        },
        {
            field: "interviews",
            headerName: "Interviews",
            renderCell: renderInterviewsCell,
            editable: false,
        },
        DAYS_IN_STAGE_COLUMN,
    ];
    const onCellEditStart = React.useCallback((params) => {
        var _a;
        const recruit = (_a = recruitsGroupedByUid[params.id]) !== null && _a !== void 0 ? _a : null;
        console.log("EDITING COL:", params.field);
        if (!recruit) {
            return;
        }
        if (params.field === "collaborators") {
            console.log("OPEN COLLABORATORS DIALOG", recruit);
            setCollaboratorsDialogParams({
                boxUid: recruit.uid,
                existingCollaborators: recruit.collaborators,
            });
            openCollaboratorsDialog();
        }
    }, [openCollaboratorsDialog, recruitsGroupedByUid, setCollaboratorsDialogParams]);
    const onFieldChanged = React.useCallback((params) => {
        const { field, row, value } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [
                    ...prev.data.slice(0, fullIndx),
                    Object.assign(Object.assign({}, prev.data[fullIndx]), { fields: Object.assign(Object.assign({}, prev.data[fullIndx].fields), { [field]: value }) }),
                    ...prev.data.slice(fullIndx + 1, prev.data.length),
                ] });
        });
    }, [itemsIndexedByUid, queryClient, searchRecruitsApi.queryKey]);
    const onFieldWillSave = onFieldChanged;
    const onFieldSaved = React.useCallback((params) => {
        const { response, row } = params;
        const fullIndx = itemsIndexedByUid[row];
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: [...prev.data.slice(0, fullIndx), response.data, ...prev.data.slice(fullIndx + 1, prev.data.length)] });
        });
    }, [itemsIndexedByUid, queryClient, searchRecruitsApi.queryKey]);
    const page = React.useMemo(() => {
        return Math.floor(offset / limit) + 1;
    }, [offset, limit]);
    const renderActionsColumn = React.useCallback((params) => {
        return React.createElement(RecruitingActionsMenu, { recruit: params.row });
    }, []);
    const renderListItem = React.useCallback((item) => {
        return (React.createElement(StandardSmallCell, { name: item.name, stage: stagesByUid && item.stage ? stagesByUid[item.stage.uid] : undefined, 
            // @ts-expect-error: readonly
            collaborators: item.collaborators, link: `/recruits/${item.uid}`, allLinks: items === null || items === void 0 ? void 0 : items.map((i) => `/recruits/${i.uid}`) }));
    }, [items, stagesByUid]);
    const onRowUpdate = React.useCallback((result) => {
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => (item.uid === result.data.uid ? result.data : item)) });
        });
    }, [queryClient, searchRecruitsApi.queryKey]);
    const onRowStageUpdateBegin = React.useCallback((recruitUid, stageUid) => {
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === recruitUid) {
                        return Object.assign(Object.assign({}, item), { stage: Object.assign(Object.assign({}, (stages !== null && stages !== void 0 ? stages : []).find((stage_) => {
                                return stage_.uid === stageUid;
                            })), { updated_at: dayjs_().format("YYYY-MM-DDThh:mm:ssZ") }) });
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchRecruitsApi.queryKey, stages]);
    const onRowUpdateError = React.useCallback((recruit) => () => {
        queryClient.setQueryData(searchRecruitsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === recruit.uid) {
                        return recruit;
                    }
                    return item;
                }) });
        });
    }, [queryClient, searchRecruitsApi.queryKey]);
    const onRowStageChange = React.useCallback((params) => {
        var _a;
        const { row, stageUid } = params;
        const recruit = recruits === null || recruits === void 0 ? void 0 : recruits.find((r) => r.uid === row);
        if (recruit) {
            if (((_a = recruit.stage) === null || _a === void 0 ? void 0 : _a.uid) === stageUid) {
                console.log("No API call needed. Stage is the same.");
                return;
            }
            setRecruitingStage(row, stageUid, onRowStageUpdateBegin, onRowUpdateError(recruit), onRowUpdate);
        }
        else {
            enqueueErrorSnackbar(`Unable to find Recruit with uid: ${row}`);
        }
    }, [onRowStageUpdateBegin, onRowUpdate, onRowUpdateError, recruits, setRecruitingStage]);
    const onRowCollaboratorUpdate = React.useCallback((params) => {
        const { collaboratorUid, rowUid, updateParams } = params;
        const recruit = recruits === null || recruits === void 0 ? void 0 : recruits.find((x) => x.uid === rowUid);
        console.log("UPDATE COLLABORATOR FOR ROW:", rowUid, updateParams);
        if (recruit) {
            if (collaboratorUid) {
                updateCollaboratorForRecruit(rowUid, collaboratorUid, updateParams, { onUpdate: onRowUpdate });
            }
            else {
                addCollaboratorForRecruit(rowUid, updateParams, { onSaveComplete: onRowUpdate });
            }
        }
        else {
            enqueueSnackbar(`Unable to find Recruit with uid: ${rowUid}`, { variant: "error" });
        }
        closeCollaboratorsDialog();
    }, [addCollaboratorForRecruit, closeCollaboratorsDialog, onRowUpdate, recruits, updateCollaboratorForRecruit]);
    const onRowRemoveCollaborator = React.useCallback((params) => {
        const { collaboratorUid, rowUid } = params;
        const recruit = recruits === null || recruits === void 0 ? void 0 : recruits.find((x) => x.uid === rowUid);
        console.log("REMOVE COLLABORATOR FOR ROW:", rowUid, collaboratorUid);
        if (recruit) {
            removeCollaboratorForRecruit(rowUid, collaboratorUid, { onRemove: onRowUpdate });
        }
        else {
            enqueueSnackbar(`Unable to find Recruit with uid: ${rowUid}`, { variant: "error" });
        }
    }, [onRowUpdate, recruits, removeCollaboratorForRecruit]);
    return (React.createElement(Spreadsheet, { title: "Recruits", fields: fields, stages: stages, view: view, countByGroup: countByGroup !== null && countByGroup !== void 0 ? countByGroup : [], items: items, search: search, nonStandardColumns: nonStandardColumns, getPOSTApiEndpoint: getPOSTApiEndpoint, renderActionsColumn: renderActionsColumn, getMailMergeEmailAddressesForItem: getMailMergeEmailAddressesForItem, getDetailURL: getDetailURL, entityType: "Recruit", rowsPerPage: limit, count: count !== null && count !== void 0 ? count : null, total: total !== null && total !== void 0 ? total : null, page: page, onRefresh: refresh, onStagesSaved: onStagesSaved, onCellEditStart: onCellEditStart, onFieldChanged: onFieldChanged, onFieldWillSave: onFieldWillSave, onFieldSaved: onFieldSaved, onRowCollaboratorChange: onRowCollaboratorUpdate, onRowRemoveCollaborator: onRowRemoveCollaborator, isCollaboratorsDialogOpen: isCollaboratorsDialogOpen, onCollaboratorsDialogClose: closeCollaboratorsDialog, collaboratorsDialogParams: collaboratorsDialogParams !== null && collaboratorsDialogParams !== void 0 ? collaboratorsDialogParams : undefined, renderListItem: renderListItem, onRowStageChange: onRowStageChange, slots: {
            addButton: (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add recruit") },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: createItem, tabIndex: -1, disabled: createItemApi.isPending, color: "primary" },
                        React.createElement(AddOutlined, null))))),
        }, canEditStages: true, downloadFunc: downloadRecruits, downloadFilename: "recruits.csv", loadingPercentComplete: undefined, isLoading: searchRecruitsApi.isFetching, showNewsfeed: showNewsfeed, error: searchRecruitsApi.error || countRecruitsApi.error || countRecruitsByStageApi.error, onBulkUpdateStages: onBulkUpdate, onBulkDelete: ((_q = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _q === void 0 ? void 0 : _q.hasRole(["Superadmin", "Admin"])) ? bulkDelete : undefined }));
};
export default Recruits;
