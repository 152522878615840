import * as React from "react";
import { FileCopy, Delete, Save } from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import MailScheduleFormattersHelper from "@app/entrypoints/brokerage/features/mailschedule4/utils/MailScheduleFormattersHelper";
import MailScheduleQuill from "@app/entrypoints/brokerage/features/mailschedule4/utils/MailScheduleQuill";
import { bodyHelperText } from "@app/entrypoints/brokerage/features/mailschedule4/utils/utils";
import useLoading from "@app/hooks/useLoading";
import { useListEntityTypes } from "@app/orval/api/entity-types";
import { useListFields } from "@app/orval/api/field-definitions";
import { useCreateMailschedule4Boilerplate, useDeleteMailschedule4Boilerplate, useDuplicateMailschedule4Boilerplate, useGetMailschedule4Boilerplate, useUpdateMailschedule4Boilerplate, } from "@app/orval/api/mail-schedule-4-boilerplate";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AppConfig, { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { getAgentMentionsFields, getClientMentionsFields, getVendorMentionsFields } from "../utils/form";
const DEFAULT_BOILERPLATE = {
    name: "",
    entity_type: "Buyer Lead",
    content: "",
};
const MailScheduleBoilerplateForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { uid } = props;
    const navigate = useNavigate();
    const confirm = useConfirm();
    const getBoilerplateApi = useGetMailschedule4Boilerplate(uid !== null && uid !== void 0 ? uid : "", Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: !!uid }) }));
    const boilerplate = (_b = (_a = getBoilerplateApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const [formData, setFormData] = React.useState(null);
    const [initialData, setInitialData] = React.useState(null);
    React.useEffect(() => {
        console.log("setting form data");
        if (uid == null) {
            setFormData(DEFAULT_BOILERPLATE);
            setInitialData(DEFAULT_BOILERPLATE);
        }
        else {
            setFormData(boilerplate);
            setInitialData(boilerplate);
        }
    }, [boilerplate, uid]);
    const listEntityTypesApi = useListEntityTypes(INFINITE_CACHE_PARAMS);
    const entityTypes = (_d = (_c = listEntityTypesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const createBoilerplateApi = useCreateMailschedule4Boilerplate();
    const updateBoilerplateApi = useUpdateMailschedule4Boilerplate();
    const deleteBoilerplateApi = useDeleteMailschedule4Boilerplate();
    const duplicateBoilerplateApi = useDuplicateMailschedule4Boilerplate();
    const entityType = React.useMemo(() => formData === null || formData === void 0 ? void 0 : formData.entity_type, [formData === null || formData === void 0 ? void 0 : formData.entity_type]);
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fieldsForEntityType = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data.filter((x) => {
            if (!(formData === null || formData === void 0 ? void 0 : formData.entity_type)) {
                return false;
            }
            if (x.entity_type === formData.entity_type) {
                return true;
            }
            if (x.entity_type === "Buyer Lead" && formData.entity_type === "Buyer Contract") {
                return true;
            }
            if (x.entity_type === "Seller Lead" && formData.entity_type === "Listing") {
                return true;
            }
            return false;
        }).sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type, (_e = listFieldsApi.data) === null || _e === void 0 ? void 0 : _e.data]);
    const agentMentionsFields = React.useMemo(() => {
        return entityType ? getAgentMentionsFields(entityType) : [];
    }, [entityType]);
    const vendorMentionsFields = React.useMemo(() => {
        return entityType ? getVendorMentionsFields(entityType) : [];
    }, [entityType]);
    const clientMentionsFields = React.useMemo(() => {
        return entityType ? getClientMentionsFields(entityType) : [];
    }, [entityType]);
    const mailScheduleQuillFields = React.useMemo(() => {
        return [
            ...(fieldsForEntityType !== null && fieldsForEntityType !== void 0 ? fieldsForEntityType : []).map((field) => ({
                id: field.uid,
                value: field.name,
            })),
            ...clientMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
            ...agentMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
            ...vendorMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
        ];
    }, [agentMentionsFields, clientMentionsFields, fieldsForEntityType, vendorMentionsFields]);
    const save = React.useCallback((onComplete) => () => {
        const loadingSnackbar = enqueueSnackbar("Saving boilerplate...", { variant: "info", persist: true });
        if (uid) {
            updateBoilerplateApi
                .mutateAsync({ uid, data: formData })
                .then((result) => {
                enqueueSnackbar("Boilerplate has been saved.", { variant: "success" });
                if (onComplete) {
                    onComplete(result.data);
                }
                setInitialData(formData);
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            })
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
        else {
            createBoilerplateApi
                .mutateAsync({ data: formData })
                .then((result) => {
                enqueueSnackbar("Boilerplate has been saved.", { variant: "success" });
                if (onComplete) {
                    onComplete(result.data);
                }
                navigate(`/ms4/boilerplate/${result.data.uid}`);
                setInitialData(formData);
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            })
                .finally(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
    }, [createBoilerplateApi, formData, navigate, uid, updateBoilerplateApi]);
    const duplicate = React.useCallback(() => {
        save((result) => {
            duplicateBoilerplateApi
                .mutateAsync({ uid: result.uid })
                .then((result2) => {
                enqueueSnackbar("Boilerplate has been duplicated.", { variant: "success" });
                navigate(`/ms4/boilerplate/${result2.data.uid}`);
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            });
        })();
    }, [duplicateBoilerplateApi, navigate, save]);
    const deleteBoilerplate = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                const loadingSnackbar = enqueueSnackbar("Deleting boilerplate...", {
                    variant: "info",
                    persist: true,
                });
                deleteBoilerplateApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    enqueueSnackbar("Boilerplate has been deleted", { variant: "success" });
                })
                    .catch((error) => {
                    enqueueErrorSnackbar(error);
                })
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [confirm, deleteBoilerplateApi, uid]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, []);
    const setEntityType = React.useCallback((e) => {
        const entityType_ = e.target.value;
        confirm({
            title: "Are you sure?",
            description: "Changing the pipeline may lead to unexpected behavior for certain merge fields, because the Streak keys may be different for fields in the new pipeline. For best results, delete and re-add all merge fields to ensure they're in sync with the new pipeline.",
            confirmationText: "Change Pipeline",
        }).then((result) => {
            if (result.confirmed) {
                setFormData((prev) => (Object.assign(Object.assign({}, prev), { entity_type: entityType_ })));
            }
        });
    }, [confirm]);
    const setContent = (content) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { content })));
    };
    const isValid = React.useMemo(() => {
        return ((formData === null || formData === void 0 ? void 0 : formData.name.trim()) &&
            formData.content.trim() &&
            formData.entity_type &&
            (initialData == null || JSON.stringify(initialData) !== JSON.stringify(formData)));
    }, [formData, initialData]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            uid
                ? {
                    label: "Loading boilerplate",
                    queryResult: getBoilerplateApi,
                }
                : null,
            {
                label: "Loading pipelines",
                queryResult: listEntityTypesApi,
            },
        ]
            .filter((x) => x)
            .map((x) => x),
    });
    if (isLoading) {
        return loadingComponent;
    }
    if (!formData) {
        return null; // should never hit this
    }
    const disableSave = duplicateBoilerplateApi.isPending ||
        createBoilerplateApi.isPending ||
        updateBoilerplateApi.isPending ||
        deleteBoilerplateApi.isPending ||
        !isValid;
    return (React.createElement(Box, { p: 4 },
        React.createElement(Paper, { elevation: 4, style: { borderTop: `5px solid ${AppConfig.themeColors.lightblue}` } },
            React.createElement(Box, { component: Grid, container: true, p: 2, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(TextField, { variant: "standard", fullWidth: true, required: true, label: "Name", value: formData.name, onChange: setName })),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(TextField, { variant: "standard", fullWidth: true, select: true, required: true, label: "Pipeline", value: (_f = formData.entity_type) !== null && _f !== void 0 ? _f : "", onChange: setEntityType }, (entityTypes !== null && entityTypes !== void 0 ? entityTypes : []).map((pipeline) => (React.createElement(MenuItem, { key: pipeline.name, value: pipeline.name }, pipeline.name))))),
                React.createElement(Grid, { item: true, xs: 12 }, fieldsForEntityType && (React.createElement(MailScheduleQuill, { ReactQuillProps: { value: (_g = formData.content) !== null && _g !== void 0 ? _g : "", onChange: setContent }, fields: mailScheduleQuillFields, entityType: formData.entity_type }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: bodyHelperText } })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(MailScheduleFormattersHelper, null)),
                React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 2 },
                    uid && (React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", color: "secondary", variant: "contained", startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: deleteBoilerplate, disabled: disableSave }, "Delete"))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", color: "inherit", variant: "contained", startIcon: React.createElement(FileCopy, { fontSize: "small" }), onClick: duplicate, disabled: disableSave }, "Save and Duplicate")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", color: "primary", variant: "contained", startIcon: React.createElement(Save, { fontSize: "small" }), onClick: save(), disabled: duplicateBoilerplateApi.isPending ||
                                createBoilerplateApi.isPending ||
                                updateBoilerplateApi.isPending ||
                                deleteBoilerplateApi.isPending ||
                                !isValid }, "Save")))))));
};
export default MailScheduleBoilerplateForm;
