import * as React from "react";
import { Box, Paper, Grid2, Divider, Avatar, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { convertUtcDateString } from "@app/common/date-utils";
import RouterLink from "@app/common/RouterLink";
import AppConfig from "@app/util/AppConfig";
import { getInitialsMemoized } from "@app/util/Utils";
const NewsfeedItem = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { item } = props;
    return (React.createElement(Paper, { elevation: 1 },
        React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid2, null,
                            React.createElement(Avatar, { src: (_b = (_a = item.userstamp) === null || _a === void 0 ? void 0 : _a.icon_url) !== null && _b !== void 0 ? _b : undefined }, getInitialsMemoized((_d = (_c = item.userstamp) === null || _c === void 0 ? void 0 : _c.first_name) !== null && _d !== void 0 ? _d : "", (_f = (_e = item.userstamp) === null || _e === void 0 ? void 0 : _e.last_name) !== null && _f !== void 0 ? _f : "") || "?")),
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(Markdown, { options: {
                                    disableParsingRawHTML: true,
                                }, style: {
                                    fontFamily: `'${AppConfig.fonts.primary}', Helvetica, Arial, sans-serif`,
                                    fontWeight: 400,
                                    fontSize: 11,
                                } }, item.story)))),
                React.createElement(Grid2, null,
                    React.createElement(Divider, null)),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(RouterLink, { to: item.url },
                                React.createElement(Typography, { variant: "body2" }, item.name))),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "caption" }, convertUtcDateString(item.timestamp, { format: "M/D/YYYY h:mm A" })))))))));
};
export default NewsfeedItem;
