import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { useListOffices } from "@app/orval/api/offices";
import { useListStages } from "@app/orval/api/stages";
import { useListTeams } from "@app/orval/api/teams";
import { useListRoles } from "@app/orval/api/user-roles";
import { searchUsersBasic } from "@app/orval/api/users";
import { useListVendorTypes } from "@app/orval/api/vendor-types";
import { useListBooleanFieldComparators, useListChoiceFieldComparators, useListDateFieldComparators, useListDateFilterUnits, useListDateRanges, useListNumberFieldComparators, useListStageComparators, useListTextFieldComparators, useListTimestampComparators, useListTimestampFilterUnits, useListUserComparators, useListTimestampTypes, useListUserFilterTypes, useListMultipleChoiceFieldComparators, useListViewComparators, useListMailScheduleTemplateComparators, useListViewsSimple, useListThirdPartyComparators, useListNotesComparators, useListRoleComparators, useListOfficeComparators, useListMailScheduleTemplateFilterTimeComparators, useListMailScheduleTemplateFilterTimeUnits, } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
export const FilterContext = React.createContext({
    filterGroups: [],
    entityType: null,
    addFilter: () => { },
    updateFilter: () => { },
    removeFilter: () => { },
    // @ts-expect-error
    getFilter: () => { },
    clearFilters: () => { },
    addGroup: () => { },
    updateGroup: () => { },
    removeGroup: () => { },
    fields: null,
    stages: null,
    views: null,
    mailScheduleTemplates: null,
    textFieldComparators: null,
    numberFieldComparators: null,
    dateFieldComparators: null,
    choiceFieldComparators: null,
    multipleChoiceFieldComparators: null,
    booleanFieldComparators: null,
    stageComparators: null,
    notesComparators: null,
    timestampComparators: null,
    userComparators: null,
    viewComparators: null,
    mailScheduleTemplateComparators: null,
    mailScheduleTemplateFilterTimeComparators: null,
    mailScheduleTemplateFilterTimeUnits: null,
    thirdPartyComparators: null,
    thirdPartyTypes: null,
    roleComparators: null,
    officeComparators: null,
    dateFilterUnits: null,
    timestampTypes: null,
    userFilterTypes: null,
    timestampFilterUnits: null,
    dateRanges: null,
    offices: null,
    teams: null,
    users: null,
    userRoles: null,
});
const DEFAULT_FILTER_GROUP = {
    uid: uuidv4().replace(/-/g, ""),
    is_match_all: true,
    filters: [],
    parent_uid: null,
};
const getDefaultFilter = () => {
    return {
        uid: uuidv4().replace(/-/g, ""),
    };
};
const FilterContextProvider = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    const { entityType, filterGroups, onUpdate } = props;
    const listViewsApi = useListViewsSimple({ entity_type: entityType }, REFETCH_CACHE_PARAMS);
    const views = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listViewsApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.entity_type < b.entity_type)
                return -1;
            if (a.entity_type > b.entity_type)
                return 1;
            if (a.name.trim() < b.name.trim())
                return -1;
            if (a.name.trim() > b.name.trim())
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_a = listViewsApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const listMailScheduleTemplatesApi = useListMailschedule4Templates({ entity_type: entityType }, REFETCH_CACHE_PARAMS);
    const mailScheduleTemplates = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listMailScheduleTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.entity_type < b.entity_type)
                return -1;
            if (a.entity_type > b.entity_type)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_b = listMailScheduleTemplatesApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = React.useMemo(() => {
        var _a;
        if (!((_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data)) {
            return null;
        }
        const allowedEntityTypes = [entityType];
        if (entityType === "Buyer Contract") {
            allowedEntityTypes.push("Buyer Lead");
        }
        else if (entityType === "Listing") {
            allowedEntityTypes.push("Seller Lead");
        }
        return listFieldsApi.data.data.filter((field) => allowedEntityTypes.includes(field.entity_type));
    }, [entityType, (_c = listFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data]);
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = React.useMemo(() => {
        var _a;
        if (!((_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data)) {
            return null;
        }
        return listStagesApi.data.data.filter((x) => x.type === entityType);
    }, [entityType, (_d = listStagesApi.data) === null || _d === void 0 ? void 0 : _d.data]);
    const listTextFieldComparatorsApi = useListTextFieldComparators(INFINITE_CACHE_PARAMS);
    const textFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTextFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_e = listTextFieldComparatorsApi.data) === null || _e === void 0 ? void 0 : _e.data]);
    const listNumberFieldComparatorsApi = useListNumberFieldComparators(INFINITE_CACHE_PARAMS);
    const numberFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listNumberFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_f = listNumberFieldComparatorsApi.data) === null || _f === void 0 ? void 0 : _f.data]);
    const listDateFieldComparatorsApi = useListDateFieldComparators(INFINITE_CACHE_PARAMS);
    const dateFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listDateFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_g = listDateFieldComparatorsApi.data) === null || _g === void 0 ? void 0 : _g.data]);
    const listChoiceFieldComparatorsApi = useListChoiceFieldComparators(INFINITE_CACHE_PARAMS);
    const choiceFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listChoiceFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_h = listChoiceFieldComparatorsApi.data) === null || _h === void 0 ? void 0 : _h.data]);
    const listMultipleChoiceFieldComparatorsApi = useListMultipleChoiceFieldComparators(INFINITE_CACHE_PARAMS);
    const multipleChoiceFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listMultipleChoiceFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_j = listMultipleChoiceFieldComparatorsApi.data) === null || _j === void 0 ? void 0 : _j.data]);
    const listBooleanFieldComparatorsApi = useListBooleanFieldComparators(INFINITE_CACHE_PARAMS);
    const booleanFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listBooleanFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_k = listBooleanFieldComparatorsApi.data) === null || _k === void 0 ? void 0 : _k.data]);
    const listStageComparatorsApi = useListStageComparators(INFINITE_CACHE_PARAMS);
    const stageComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStageComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_l = listStageComparatorsApi.data) === null || _l === void 0 ? void 0 : _l.data]);
    const listNotesFilterComparatorsApi = useListNotesComparators(INFINITE_CACHE_PARAMS);
    const notesComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listNotesFilterComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_m = listNotesFilterComparatorsApi.data) === null || _m === void 0 ? void 0 : _m.data]);
    const listTimestampComparatorsApi = useListTimestampComparators(INFINITE_CACHE_PARAMS);
    const timestampComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTimestampComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_o = listTimestampComparatorsApi.data) === null || _o === void 0 ? void 0 : _o.data]);
    const listUserComparatorsApi = useListUserComparators(INFINITE_CACHE_PARAMS);
    const userComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUserComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_p = listUserComparatorsApi.data) === null || _p === void 0 ? void 0 : _p.data]);
    const listViewComparatorsApi = useListViewComparators(INFINITE_CACHE_PARAMS);
    const viewComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listViewComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_q = listViewComparatorsApi.data) === null || _q === void 0 ? void 0 : _q.data]);
    const listMailScheduleTemplateComparators = useListMailScheduleTemplateComparators(INFINITE_CACHE_PARAMS);
    const mailScheduleTemplateComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listMailScheduleTemplateComparators.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_r = listMailScheduleTemplateComparators.data) === null || _r === void 0 ? void 0 : _r.data]);
    const listMailScheduleTemplateFilterTimeComparators = useListMailScheduleTemplateFilterTimeComparators(INFINITE_CACHE_PARAMS);
    const mailScheduleTemplateFilterTimeComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listMailScheduleTemplateFilterTimeComparators.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_s = listMailScheduleTemplateFilterTimeComparators.data) === null || _s === void 0 ? void 0 : _s.data]);
    const listMailScheduleTemplateFilterTimeUnits = useListMailScheduleTemplateFilterTimeUnits(INFINITE_CACHE_PARAMS);
    const mailScheduleTemplateFilterTimeUnits = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listMailScheduleTemplateFilterTimeUnits.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_t = listMailScheduleTemplateFilterTimeUnits.data) === null || _t === void 0 ? void 0 : _t.data]);
    const listThirdPartyComparators = useListThirdPartyComparators(INFINITE_CACHE_PARAMS);
    const thirdPartyComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listThirdPartyComparators.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_u = listThirdPartyComparators.data) === null || _u === void 0 ? void 0 : _u.data]);
    const listRoleComparatorsApi = useListRoleComparators(INFINITE_CACHE_PARAMS);
    const roleComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listRoleComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_v = listRoleComparatorsApi.data) === null || _v === void 0 ? void 0 : _v.data]);
    const listOfficeComparatorsApi = useListOfficeComparators(INFINITE_CACHE_PARAMS);
    const officeComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listOfficeComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_w = listOfficeComparatorsApi.data) === null || _w === void 0 ? void 0 : _w.data]);
    const listDateFilterUnitsApi = useListDateFilterUnits(INFINITE_CACHE_PARAMS);
    const dateFilterUnits = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listDateFilterUnitsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_x = listDateFilterUnitsApi.data) === null || _x === void 0 ? void 0 : _x.data]);
    const listTimestampTypesApi = useListTimestampTypes(INFINITE_CACHE_PARAMS);
    const timestampTypes = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTimestampTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_y = listTimestampTypesApi.data) === null || _y === void 0 ? void 0 : _y.data]);
    const listUserFilterTypesApi = useListUserFilterTypes(INFINITE_CACHE_PARAMS);
    const userFilterTypes = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUserFilterTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_z = listUserFilterTypesApi.data) === null || _z === void 0 ? void 0 : _z.data]);
    const listTimestampFilterUnitsApi = useListTimestampFilterUnits(INFINITE_CACHE_PARAMS);
    const timestampFilterUnits = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTimestampFilterUnitsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_0 = listTimestampFilterUnitsApi.data) === null || _0 === void 0 ? void 0 : _0.data]);
    const listDateRangesApi = useListDateRanges(INFINITE_CACHE_PARAMS);
    const dateRanges = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listDateRangesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_1 = listDateRangesApi.data) === null || _1 === void 0 ? void 0 : _1.data]);
    const listOfficesApi = useListOffices(INFINITE_CACHE_PARAMS);
    const offices = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listOfficesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_2 = listOfficesApi.data) === null || _2 === void 0 ? void 0 : _2.data]);
    const listTeamsApi = useListTeams(INFINITE_CACHE_PARAMS);
    const teams = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTeamsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_3 = listTeamsApi.data) === null || _3 === void 0 ? void 0 : _3.data]);
    const listUserRolesApi = useListRoles(INFINITE_CACHE_PARAMS);
    const roles = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUserRolesApi.data) === null || _a === void 0 ? void 0 : _a.data.map((x) => x.name)) !== null && _b !== void 0 ? _b : null;
    }, [(_4 = listUserRolesApi.data) === null || _4 === void 0 ? void 0 : _4.data]);
    const listThirdPartyTypesApi = useListVendorTypes(INFINITE_CACHE_PARAMS);
    const thirdPartyTypes = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listThirdPartyTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_5 = listThirdPartyTypesApi.data) === null || _5 === void 0 ? void 0 : _5.data.items]);
    const listUsersApi = usePostQuery(searchUsersBasic, { disable_sort: true }, INFINITE_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_6 = listUsersApi.data) === null || _6 === void 0 ? void 0 : _6.data]);
    const clearFilters = React.useCallback(() => {
        onUpdate([DEFAULT_FILTER_GROUP]);
    }, [onUpdate]);
    const addGroup = React.useCallback((uid) => {
        onUpdate([
            ...filterGroups,
            {
                is_match_all: true,
                filters: [],
                uid: uuidv4().replace(/-/g, ""),
                parent_uid: uid,
            },
        ]);
    }, [onUpdate, filterGroups]);
    const removeGroup = React.useCallback((uid) => {
        onUpdate(filterGroups.filter((fg) => fg.uid !== uid));
        // remove all groups that are children of this group
        for (const fg of filterGroups) {
            if (fg.parent_uid === uid) {
                removeGroup(fg.uid);
            }
        }
    }, [onUpdate, filterGroups]);
    const updateGroup = React.useCallback((uid, data) => {
        onUpdate(filterGroups.map((fg) => {
            if (fg.uid === uid) {
                return data;
            }
            return fg;
        }));
    }, [onUpdate, filterGroups]);
    const addFilter = React.useCallback((parentUid) => {
        onUpdate(filterGroups.map((fg) => {
            if (fg.uid === parentUid) {
                return Object.assign(Object.assign({}, fg), { filters: [...fg.filters, getDefaultFilter()] });
            }
            return fg;
        }));
    }, [onUpdate, filterGroups]);
    const removeFilter = React.useCallback((uid) => {
        onUpdate(filterGroups.map((fg) => (Object.assign(Object.assign({}, fg), { filters: fg.filters.filter((f) => f.uid !== uid) }))));
    }, [onUpdate, filterGroups]);
    const updateFilter = React.useCallback((uid, data) => {
        onUpdate(filterGroups.map((fg) => (Object.assign(Object.assign({}, fg), { filters: fg.filters.map((f) => {
                if (f.uid === uid) {
                    return data;
                }
                return f;
            }) }))));
    }, [onUpdate, filterGroups]);
    const getFilter = React.useCallback((uid) => {
        return filterGroups
            .reduce((acc, fg) => {
            return [...acc, ...fg.filters];
        }, [])
            .find((f) => f.uid === uid);
    }, [filterGroups]);
    const value = React.useMemo(() => {
        return {
            filterGroups,
            entityType,
            fields,
            stages,
            views,
            mailScheduleTemplates,
            addFilter,
            updateFilter,
            removeFilter,
            addGroup,
            removeGroup,
            updateGroup,
            clearFilters,
            getFilter,
            textFieldComparators,
            numberFieldComparators,
            dateFieldComparators,
            choiceFieldComparators,
            multipleChoiceFieldComparators,
            booleanFieldComparators,
            stageComparators,
            notesComparators,
            timestampComparators,
            userComparators,
            viewComparators,
            mailScheduleTemplateComparators,
            mailScheduleTemplateFilterTimeComparators,
            mailScheduleTemplateFilterTimeUnits,
            roleComparators,
            officeComparators,
            dateFilterUnits,
            timestampTypes,
            userFilterTypes,
            thirdPartyComparators,
            thirdPartyTypes,
            timestampFilterUnits,
            dateRanges,
            offices,
            teams,
            users,
            roles,
        };
    }, [
        filterGroups,
        entityType,
        fields,
        stages,
        views,
        mailScheduleTemplates,
        clearFilters,
        addFilter,
        updateFilter,
        removeFilter,
        getFilter,
        addGroup,
        removeGroup,
        updateGroup,
        textFieldComparators,
        numberFieldComparators,
        dateFieldComparators,
        choiceFieldComparators,
        multipleChoiceFieldComparators,
        booleanFieldComparators,
        userComparators,
        viewComparators,
        mailScheduleTemplateComparators,
        mailScheduleTemplateFilterTimeComparators,
        mailScheduleTemplateFilterTimeUnits,
        stageComparators,
        notesComparators,
        timestampComparators,
        roleComparators,
        officeComparators,
        dateFilterUnits,
        timestampTypes,
        userFilterTypes,
        timestampFilterUnits,
        thirdPartyComparators,
        thirdPartyTypes,
        dateRanges,
        offices,
        teams,
        users,
        roles,
    ]);
    return React.createElement(FilterContext.Provider, { value: value }, props.children);
};
export default FilterContextProvider;
