import * as React from "react";
import { List, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import QFOListItem from "./QFOListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const QFOs = (props) => {
    var _a;
    const { isCollapsible, onRemoveQFO, qfos } = props;
    const removeQFO = React.useCallback((propertyUid) => {
        onRemoveQFO(propertyUid);
    }, [onRemoveQFO]);
    const isLoading = qfos == null;
    const noQFOs = qfos && qfos.length === 0;
    return (React.createElement(CollapsibleSection, { title: "QFOs", count: (_a = qfos === null || qfos === void 0 ? void 0 : qfos.length) !== null && _a !== void 0 ? _a : undefined, isCollapsible: isCollapsible },
        isLoading && React.createElement(CoreLoading, null),
        noQFOs && React.createElement(Typography, { variant: "body2" }, "No QFOs"),
        qfos && qfos.length > 0 && (React.createElement(List, { dense: true }, qfos
            .sort((a, b) => {
            if (a.created_at > b.created_at)
                return -1;
            if (a.created_at < b.created_at)
                return 1;
            return 0;
        })
            .map((qfo) => (React.createElement(QFOListItem, { key: qfo.uid, qfo: qfo, onRemoveQFO: removeQFO })))))));
};
export default QFOs;
