import { useEffect, useRef, useState, useCallback } from "react";
/**
 * CREDIT: https://medium.com/geekculture/usecallbackstate-the-hook-that-let-you-run-code-after-a-setstate-operation-finished-25f40db56661
 * @param initialValue
 * @returns
 */
const useStateWithCallback = (initialValue) => {
    const [state, _setState] = useState(initialValue);
    const callbackQueue = useRef([]);
    useEffect(() => {
        callbackQueue.current.forEach((cb) => {
            cb(state);
        });
        callbackQueue.current = [];
    }, [state]);
    const setState = useCallback((newValue, callback) => {
        _setState(newValue);
        if (callback && typeof callback === "function") {
            callbackQueue.current.push(callback);
        }
    }, []);
    return [state, setState];
};
export default useStateWithCallback;
