import * as React from "react";
import { InputAdornment } from "@mui/material";
import CoreTextField from "@app/common/CoreTextField";
const BoxFilter = (props) => {
    var _a, _b;
    const { TextFieldProps, onChange, value } = props;
    const handleChange = React.useCallback((val) => {
        onChange(val);
    }, [onChange]);
    return (React.createElement(CoreTextField, Object.assign({}, TextFieldProps, { variant: "standard", fullWidth: true, value: value !== null && value !== void 0 ? value : "", onTextChange: handleChange, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Box UID") }, (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _a === void 0 ? void 0 : _a.input), inputLabel: Object.assign({ shrink: true }, (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.inputLabel) }) })));
};
export default BoxFilter;
