import * as React from "react";
import { Downloading as DownloadingIcon } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
const StyledDownloadingIcon = styled(DownloadingIcon) `
  color: green;
  animation: animate 2s linear infinite;

  @keyframes animate {
    0% { color: #bdbfbd; }
    20% { color: #aabdaa; }
    40% { color: #9bc29b; }
    60% { color: #88bf88; }
    80% { color: #73ba73; }
    100% { color: #4ab54a; }
  }
`;
const Downloading = () => {
    return (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Downloading your CSV...") },
        React.createElement(StyledDownloadingIcon, null)));
};
export default Downloading;
