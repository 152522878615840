// import { jStat } from "jstat";
import dayjs_ from "@app/common/dayjs";
const { jStat } = require("jstat");
export const PRICE_RANGES = [
    {
        name: "Total PSPs",
    },
    {
        name: "300k and Under PSPs",
        maxPrice: 300000,
    },
    {
        name: "300k-500k PSPs",
        minPrice: 300000,
        maxPrice: 500000,
    },
    {
        name: "500k-750k PSPs",
        minPrice: 500000,
        maxPrice: 750000,
    },
    {
        name: "750k+ PSPs",
        minPrice: 750000,
    },
];
export const DEFAULT_PRICE_RANGE = PRICE_RANGES[0];
export const DEFAULT_COMPLETED_BY_EMAILS = [
    "shelby.zecca@dashnc.com",
    "kylee.busche@dashnc.com",
    "courtney.lamotte@dashnc.com",
    "sara.cooke@dashnc.com",
    "will@dashnc.com",
];
/** max percentage difference between Close Price and PSP Value Avg before a psp is considered an outlier */
export const OUTLIER_TOLERANCE = 0.3;
export const VIEWS = [
    "PSP Value Range",
    "PSP Value Range vs. Close Price",
    "Standard Deviation",
    "Percent Difference",
    "Agent Scores",
    "Agent Grades",
];
export const MEASURE_ACCURACY_FROM_OPTIONS = ["PSP Value Average", "PSP Value Range"];
export const DEFAULT_EXPECTED_MEAN_PSP_VALUE_ACCURACY = 0.05;
/** Merge data from PSPs Google Sheet and Listings Google Sheet on PSP uid */
export const mergePSPData = (psps, listings) => {
    const mergedData = [];
    psps.forEach((psp) => {
        var _a, _b;
        const listing = listings.find((item) => item.psp_uid === psp.uid);
        if (listing) {
            mergedData.push(Object.assign(Object.assign(Object.assign({}, psp), listing), { created_by_name: `${(_a = psp.created_by_first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = psp.created_by_last_name) !== null && _b !== void 0 ? _b : ""}` }));
        }
    });
    const filteredData = mergedData.filter((item) => item.close_date &&
        item.close_price &&
        item.close_price >= 1000 &&
        item.psp_value_min &&
        item.psp_value_high &&
        item.psp_value_avg);
    return filteredData;
};
/** returns psps with a close_date between the given fromDate and toDate */
export const filterDataByCloseDate = (psps, fromDate, toDate) => {
    const filteredData = [...psps].filter((psp) => {
        const { close_date } = psp;
        const withinDateRange = !!(close_date &&
            (!fromDate || dayjs_(close_date).isAfter(dayjs_(fromDate))) &&
            (!toDate || dayjs_(close_date).isBefore(dayjs_(toDate))));
        return withinDateRange;
    });
    return filteredData;
};
/** returns psps with a completed_by email that matches one of the given emails */
export const filterDataByCompletedBy = (psps, completedByEmails) => {
    const filteredData = [...psps].filter((psp) => {
        const { created_by_email } = psp;
        return created_by_email && completedByEmails.includes(created_by_email);
    });
    return filteredData;
};
/** object containing email/name pairs from psp data */
export const getCompletedBy = (pspAccuracyData) => {
    const completedByData = {};
    pspAccuracyData.forEach((item) => {
        var _a, _b, _c;
        const email = (_a = item.created_by_email) !== null && _a !== void 0 ? _a : "";
        if (!Object.keys(completedByData).includes(email.toLowerCase().trim())) {
            const fullName = `${(_b = item.created_by_first_name) !== null && _b !== void 0 ? _b : ""} ${(_c = item.created_by_last_name) !== null && _c !== void 0 ? _c : ""}`;
            if (fullName.trim() !== "") {
                completedByData[email] = fullName;
            }
        }
    });
    return completedByData;
};
/** list of all unique zip codes in psp data */
export const getZipCodes = (pspAccuracyData) => {
    const zipCodes = [
        ...new Set(pspAccuracyData.map((item) => item.zip_code).filter((item) => item != null)),
    ];
    return zipCodes;
};
/* * list of all unique markets in psp data */
export const getMarkets = (pspAccuracyData) => {
    const markets = [...new Set(pspAccuracyData.map((item) => item.market).filter((item) => item != null))];
    return markets;
};
export const getTableData = (data) => {
    const dataByEmail = {};
    data.forEach((item) => {
        var _a, _b;
        const email = item.created_by_email;
        if (email) {
            if (!Object.keys(dataByEmail).includes(email)) {
                dataByEmail[email] = {
                    psps: [],
                    name: `${(_a = item.created_by_first_name) !== null && _a !== void 0 ? _a : ""} ${(_b = item.created_by_last_name) !== null && _b !== void 0 ? _b : ""}`,
                    accuracy: null,
                    avgRange: null,
                    belowRangeRate: null,
                    aboveRangeRate: null,
                };
            }
            dataByEmail[email].psps.push(item);
        }
    });
    Object.keys(dataByEmail).forEach((email) => {
        const emailPSPs = dataByEmail[email].psps;
        let totalClosePrice = 0;
        let totalPSPValueAvg = 0;
        emailPSPs.forEach((psp) => {
            totalClosePrice += psp.close_price;
            totalPSPValueAvg += psp.psp_value_avg;
        });
        dataByEmail[email].totalClosePrice = totalClosePrice;
        dataByEmail[email].totalPSPValueAvg = totalPSPValueAvg;
        dataByEmail[email].accuracy = getPSPAccuracy(emailPSPs);
        dataByEmail[email].avgRange = getAvgerageRange(emailPSPs);
        dataByEmail[email].belowRangeRate = getPercentClosePriceBelowRange(emailPSPs);
        dataByEmail[email].aboveRangeRate = getPercentClosePriceAboveRange(emailPSPs);
    });
    return dataByEmail;
};
/**
 * returns percent of PSPs where the `Close Price` is within the PSP Value range
 */
export const getPSPAccuracy = (psps) => {
    const pspsInRange = psps.filter((psp) => {
        const minValue = psp.psp_value_min;
        const highValue = psp.psp_value_high;
        const closePrice = psp.close_price;
        return minValue <= closePrice && closePrice <= highValue;
    });
    return psps.length === 0 ? null : pspsInRange.length / psps.length;
};
/**
 *
 * @param psps list of merged PSPs/Listings data
 * @returns ratio of psps with `close_price` < `psp_value_min`
 */
export const getPercentClosePriceBelowRange = (psps) => {
    const pspsBelowRange = psps.filter((psp) => {
        const minValue = psp.psp_value_min;
        const closePrice = psp.close_price;
        return minValue > closePrice;
    });
    return psps.length === 0 ? null : pspsBelowRange.length / psps.length;
};
/**
 *
 * @param psps list of merged PSPs/Listings data
 * @returns ratio of psps with `close_price` > `psp_value_high`
 */
export const getPercentClosePriceAboveRange = (psps) => {
    const pspsAboveRange = psps.filter((psp) => {
        const highValue = psp.psp_value_high;
        const closePrice = psp.close_price;
        return highValue < closePrice;
    });
    return psps.length === 0 ? null : pspsAboveRange.length / psps.length;
};
/** gets the average difference between `PSP Value (High)` and `PSP Value (Min)` */
export const getAvgerageRange = (psps) => {
    let totalRange = 0;
    let count = 0;
    psps.forEach((psp) => {
        const min = psp.psp_value_min;
        const high = psp.psp_value_high;
        totalRange += high - min;
        count += 1;
    });
    return count === 0 ? null : totalRange / count;
};
export const getPSPValueAccuracies = (psps, absValue) => {
    const ratios = psps
        .map((psp) => {
        const percentDif = getPercentDifference(psp.close_price, psp.psp_value_avg);
        console.log("testing percent dif:", psp.close_price, psp.psp_value_avg, percentDif, psp.uid);
        if (absValue) {
            return percentDif == null ? null : Math.abs(percentDif);
        }
        return percentDif;
    })
        .filter((ratio) => ratio != null);
    return ratios;
};
export const getPSPValueRangeAccuracy = (psp) => {
    const { close_price, psp_value_high, psp_value_min } = psp;
    if (psp_value_min <= close_price && close_price <= psp_value_high) {
        return 0;
    }
    const closestVal = Math.abs(close_price - psp_value_min) < Math.abs(close_price - psp_value_high) ? psp_value_min : psp_value_high;
    return getPercentDifference(close_price, closestVal);
};
export const getPSPValueRangeAccuracies = (psps, absValue) => {
    const ratios = psps
        .map((psp) => {
        const percentDif = getPSPValueRangeAccuracy(psp);
        if (absValue) {
            return percentDif == null ? null : Math.abs(percentDif);
        }
        return percentDif;
    })
        .filter((ratio) => ratio != null);
    return ratios;
};
/**
 * Calculates percent difference between two numbers.
 * Returns the result as a decimal.
 */
export const getPercentDifference = (x, y) => {
    if (x === null || y === null || y === 0) {
        return null;
    }
    const percentDiff = (x - y) / y;
    return percentDiff;
};
/** calculates the standard deviation of a dataset */
export const getStandardDeviation = (dataset, isSample) => {
    const datasetLength = dataset.length;
    if (datasetLength === 0) {
        return null;
    }
    const average = dataset.reduce((a, b) => a + b) / datasetLength;
    const n = isSample ? datasetLength - 1 : datasetLength;
    if (n === 0) {
        return null;
    }
    const standardDeviation = Math.sqrt(dataset.map((x) => Math.pow((x - average), 2)).reduce((a, b) => a + b) / n);
    return standardDeviation;
};
/** calculates zScore for a value */
export const getZScore = (value, average, standardDeviation) => standardDeviation !== 0 ? (value - average) / standardDeviation : null;
/** uses one-sample t test to calculate t valuee */
export const getTValue = (dataset, expectedMean) => {
    const datasetLength = dataset.length;
    if (datasetLength === 0) {
        return null;
    }
    if (dataset.every((item) => {
        return item === 0;
    })) {
        // return 0 if every item in dataset = 0
        return 0;
    }
    const average = dataset.reduce((a, b) => a + b) / datasetLength;
    const standardDeviation = getStandardDeviation(dataset, true);
    if (standardDeviation == null) {
        return null;
    }
    const tValue = (average - expectedMean) / (standardDeviation / Math.sqrt(datasetLength));
    // console.log("TEST:", average, expectedMean, standardDeviation, tValue);
    // numbers that are too small are interpreted as NaN by numeral
    return Math.abs(tValue) < 0.000001 ? 0 : tValue;
};
/** uses tValue and degrees of freedom to calculate p-value */
export const getPValue = (tValue, degreesOfFreedom, sides) => {
    // if (degreesOfFreedom < 5) {
    //   return null;
    // }
    const pValue = sides === 2
        ? jStat.studentt.cdf(-1 * Math.abs(tValue), degreesOfFreedom) * 2
        : 1 - jStat.studentt.cdf(Math.abs(tValue), degreesOfFreedom);
    // numbers that are too small are interpreted as NaN by numeral
    return pValue < 0.000001 ? 0 : pValue;
};
/** Calculates a grade (0 - 100) based on a pValue */
export const calculateAgentGrade = (pValue) => {
    if (pValue < 0.5) {
        // const grade = 72.7038 * Math.E ** (0.3188 * pValue);
        // const grade = 68.1773 * Math.E ** (0.533 * pValue);
        const grade = 100.2349 * Math.pow(Math.E, (-0.451 * pValue));
        return Math.min(grade, 100); // sanity check to ensure no grades higher than 100
    }
    const grade = 104.6023 * Math.pow(Math.E, (-0.5614 * pValue));
    // const grade = 59.4335 * Math.E ** (0.9486 * pValue);
    // const grade = 58.4091 * Math.E ** (1.0066 * pValue);
    return Math.max(grade, 0); // sanity check to ensure no grades lower than 0
};
/** Assigns a letter grade to a number grade */
export const getLetterGrade = (grade) => {
    const gradeMapping = [
        { name: "A+", min: 97 },
        { name: "A", min: 93, max: 97 },
        { name: "A-", min: 90, max: 93 },
        { name: "B+", min: 87, max: 90 },
        { name: "B", min: 83, max: 87 },
        { name: "B-", min: 80, max: 83 },
        { name: "C+", min: 77, max: 80 },
        { name: "C", min: 73, max: 77 },
        { name: "C-", min: 70, max: 73 },
        { name: "D+", min: 67, max: 70 },
        { name: "D", min: 63, max: 67 },
        { name: "D-", min: 60, max: 63 },
        { name: "F", max: 60 },
    ];
    let letterGrade;
    gradeMapping.forEach((item) => {
        if ((!item.min || item.min <= grade) && (!item.max || item.max > grade)) {
            letterGrade = item.name;
        }
    });
    return letterGrade !== null && letterGrade !== void 0 ? letterGrade : "F";
};
