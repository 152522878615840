import * as React from "react";
import { Avatar, Box, Button, Divider, Grid, IconButton, MenuItem, TextField, Tooltip, Typography, } from "@mui/material";
import CorePopover from "@app/common/CorePopover";
import useSession from "@app/hooks/useSession";
import { useListUsers } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { getFullName } from "@app/util/Utils";
const User = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { height } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const session = useSession();
    const listUsersApi = useListUsers({ is_in_my_purview: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            const aName = getFullName(a.first_name, a.last_name);
            const bName = getFullName(b.first_name, b.last_name);
            if (aName < bName)
                return -1;
            if (aName > bName)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [listUsersApi.data]);
    const handleClick = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [setAnchorEl]);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const setViewAs = React.useCallback((e) => {
        window.location.href = `/view-as?user=${e.target.value}`;
    }, []);
    if (!((_a = session === null || session === void 0 ? void 0 : session.activeUser) === null || _a === void 0 ? void 0 : _a.uid)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: "User Info", placement: "bottom" },
            React.createElement(IconButton, { onClick: handleClick, tabIndex: -1 },
                React.createElement(Avatar, { src: (_c = (_b = session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.icon_url) !== null && _c !== void 0 ? _c : undefined, style: { height, width: height, fontSize: "0.75rem" } }, (_e = (_d = session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.initials) !== null && _e !== void 0 ? _e : "?"))),
        React.createElement(CorePopover, { anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, PaperProps: {
                style: {
                    minWidth: "300px",
                },
            } },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Avatar, { src: (_g = (_f = session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.icon_url) !== null && _g !== void 0 ? _g : undefined }, (_h = session.viewingAsUser) === null || _h === void 0 ? void 0 : _h.initials)),
                    React.createElement(Grid, { item: true, style: { flexGrow: 1 } },
                        React.createElement(Typography, { variant: "body1" }, (_k = (_j = session.viewingAsUser) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : ""),
                        React.createElement(Typography, { variant: "body1" }, (_m = (_l = session.viewingAsUser) === null || _l === void 0 ? void 0 : _l.email) !== null && _m !== void 0 ? _m : "")))),
            users && users.length > 1 && (React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 2 },
                        React.createElement(TextField, { label: "View As", fullWidth: true, value: session.viewingAsUser.email, onChange: setViewAs, variant: "outlined", size: "small", select: true }, users
                            .filter((user) => {
                            var _a, _b, _c;
                            if (!user.email) {
                                return false;
                            }
                            if (user.roles.includes("Superadmin") && !((_a = session.activeUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin"))) {
                                return false;
                            }
                            if (user.roles.includes("Admin") && !((_b = session.activeUser) === null || _b === void 0 ? void 0 : _b.hasRole("Superadmin"))) {
                                return false;
                            }
                            if (user.roles.includes("Managing Partner") &&
                                !((_c = session.activeUser) === null || _c === void 0 ? void 0 : _c.hasRole(["Superadmin", "Admin"]))) {
                                return false;
                            }
                            return true;
                        })
                            .map((user) => (React.createElement(MenuItem, { key: user.email, value: user.email },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Typography, null, [user.first_name, user.last_name].filter((obj) => obj).join(" "))),
                                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Typography, null, "\u2022")),
                                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Typography, { variant: "caption", style: { color: "#888 " } }, user.roles.join(", ")))))))))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Divider, null)))),
            session.activeUser.uid !== ((_o = session.viewingAsUser) === null || _o === void 0 ? void 0 : _o.uid) && (React.createElement(Box, { m: 2 },
                React.createElement(Button, { style: {
                        left: "50%",
                        transform: "translateX(-50%)",
                    }, variant: "contained", href: `/view-as?user=${session.activeUser.email}`, color: "inherit", className: "mr-10 btn-xs bg-primary text-white" }, "Back to My Account"))),
            React.createElement(Box, { m: 2 },
                React.createElement(Button, { style: {
                        left: "50%",
                        transform: "translateX(-50%)",
                    }, variant: "contained", href: "/logout/", color: "primary", className: "mr-10 btn-xs bg-primary text-white" }, "Log Out")))));
};
export default User;
