import * as React from "react";
import { Add } from "@mui/icons-material";
import { Grid2, IconButton, Tooltip, Typography, MenuItem } from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import ReactQuill from "react-quill";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { PSP_COMP_QUALITY_MAPPING } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useUpdatePspComp } from "@app/orval/api/psp-comps";
import MAtrixDraggable from "./MAtrixDraggable";
const PSPCompsTab = (props) => {
    const { addPSPComp, deletePSPComp, isAddingPSPComp, pspCompSchema, pspCompTypes, pspComps, pspCompsCurrentlyUpdatingQuality, reorderPSPComps, updatePSPComp, updatePSPCompQuality, } = props;
    const [pspComps_, setPSPComps] = useStateWithCallback(pspComps);
    const [notesCurrentlySaving, setNotesCurrentlySaving] = React.useState([]); // for tracking which notes are currently saving
    const updatePSPCompApi = useUpdatePspComp();
    React.useEffect(() => {
        setPSPComps(pspComps);
    }, [pspComps, setPSPComps]);
    const closedCompFields = React.useMemo(() => {
        if (!pspCompSchema) {
            return [];
        }
        return pspCompSchema.filter((field) => !["Scheduled Close Date", "List Price/SqFt", "Weight"].includes(field.name));
    }, [pspCompSchema]);
    const pendingCompFields = React.useMemo(() => {
        if (!pspCompSchema) {
            return [];
        }
        return pspCompSchema.filter((field) => ![
            "Sold Price",
            "Sold Price/SqFt",
            "Close Date",
            "Financial Concessions",
            "Weight",
            "Comp Quality",
            "Additional Notes",
        ].includes(field.name));
    }, [pspCompSchema]);
    const activeCompFields = React.useMemo(() => {
        if (!pspCompSchema) {
            return [];
        }
        return pspCompSchema.filter((field) => ![
            "Sold Price",
            "Sold Price/SqFt",
            "Close Date",
            "Scheduled Close Date",
            "Financial Concessions",
            "Weight",
            "Comp Quality",
            "Additional Notes",
        ].includes(field.name));
    }, [pspCompSchema]);
    const getFieldsForCompType = React.useCallback((compType) => {
        if (compType === "Closed") {
            return closedCompFields;
        }
        if (compType === "Pending") {
            return pendingCompFields;
        }
        if (compType === "Active") {
            return activeCompFields;
        }
        return [];
    }, [activeCompFields, closedCompFields, pendingCompFields]);
    const handleCompCreated = React.useCallback((comp) => {
        setPSPComps((prev) => [...prev, comp]);
    }, [setPSPComps]);
    const onPSPCompCreate = React.useCallback((compType) => () => {
        addPSPComp(compType, {
            onCompCreated: handleCompCreated,
        });
    }, [addPSPComp, handleCompCreated]);
    /** should only fire after comp is successfully deleted. Won't be called if delete is cancelled */
    const handleCompDeleted = React.useCallback((deletedUid) => {
        setPSPComps((prev) => prev.filter((comp) => comp.uid !== deletedUid));
    }, [setPSPComps]);
    const onPSPCompDelete = React.useCallback((compUid) => () => {
        deletePSPComp(compUid, {
            onDeleted: handleCompDeleted,
        });
    }, [deletePSPComp, handleCompDeleted]);
    const handleCompsReordered = React.useCallback((reorderedUids) => {
        console.log("REORDERED COMPS, SET STATE:", reorderedUids);
        setPSPComps((prev) => {
            const newComps = prev.sort((a, b) => {
                const aIndx = reorderedUids.indexOf(a.uid);
                const bIndx = reorderedUids.indexOf(b.uid);
                if (aIndx < bIndx)
                    return -1;
                if (aIndx > bIndx)
                    return 1;
                return 0;
            });
            return newComps;
        });
    }, [setPSPComps]);
    const reorderComps = React.useCallback((compType) => (e) => {
        var _a;
        const activeId = e.active.id;
        const overId = (_a = e.over) === null || _a === void 0 ? void 0 : _a.id;
        if (activeId !== overId) {
            const pspCompsCorrectType = pspComps_.filter((comp) => comp.comp_type === compType);
            const oldIndex = pspCompsCorrectType.findIndex((comp) => comp.uid === activeId);
            const newIndex = pspCompsCorrectType.findIndex((comp) => comp.uid === overId);
            if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
                const compToMove = pspCompsCorrectType[oldIndex];
                pspCompsCorrectType.splice(oldIndex, 1);
                pspCompsCorrectType.splice(newIndex, 0, compToMove);
                reorderPSPComps(pspCompsCorrectType.map((pspComp) => pspComp.uid), {
                    onReorderStart: handleCompsReordered,
                });
            }
            else {
                console.warn("Could not reorder comps: oldIndex:", oldIndex, "newIndex:", newIndex);
            }
        }
    }, [handleCompsReordered, pspComps_, reorderPSPComps]);
    const onCompQualityUpdate = React.useCallback((compUid) => (choice) => {
        updatePSPCompQuality(compUid, choice);
    }, [updatePSPCompQuality]);
    const onAdditionalNotesUpdate = React.useCallback((compUid) => (range, source, editor) => {
        console.log("onAdditionalNotesUpdate", { compUid, range, source, editor });
        setNotesCurrentlySaving((prev) => [...prev, compUid]); // track which notes are currently saving
        setPSPComps(
        // update notes field in local state immediately for better UX
        (prev) => {
            const newComps = prev.map((comp) => {
                if (comp.uid === compUid) {
                    return Object.assign(Object.assign({}, comp), { fields: Object.assign(Object.assign({}, comp.fields), { "Additional Notes": editor.getHTML() }) });
                }
                return comp;
            });
            return newComps;
        }, () => {
            updatePSPCompApi
                .mutateAsync({
                uid: compUid,
                data: {
                    fields: {
                        "Additional Notes": editor.getHTML(),
                    },
                },
            })
                .then((response) => {
                updatePSPComp(response);
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            })
                .then(() => {
                setNotesCurrentlySaving((prev) => prev.filter((uid) => uid !== compUid)); // remove from saving state
            });
        });
    }, [setPSPComps, updatePSPComp, updatePSPCompApi]);
    if (!pspCompTypes || !pspCompSchema) {
        return React.createElement(CoreLoading, null);
    }
    console.log({ pspComps, pspComps_ });
    return (React.createElement(Grid2, { container: true, spacing: 2, style: { height: "100%", width: "100%", margin: 0 } },
        React.createElement(Grid2, { size: 12, style: { padding: "4px" } },
            React.createElement(Typography, { variant: "h6" }, "Comps")),
        pspCompTypes.map((compType) => (React.createElement(Grid2, { key: compType.name, container: true, size: 12, spacing: 2 },
            React.createElement(Grid2, { container: true, spacing: 2, style: { width: "150px", alignContent: "flex-start" } },
                React.createElement(Grid2, { container: true, size: 12 },
                    React.createElement(Grid2, { container: true, size: 12, style: { textAlign: "center" } },
                        React.createElement(Grid2, { style: { flexGrow: 1 } },
                            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, compType.name)),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: "Add comp" },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { size: "small", onClick: onPSPCompCreate(compType.name), disabled: isAddingPSPComp },
                                        React.createElement(Add, { fontSize: "small" })))))),
                    getFieldsForCompType(compType.name).map((field) => (React.createElement(Grid2, { key: field.name, size: 12, style: {
                            height: "35px",
                            textAlign: "right",
                            paddingTop: "5px",
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                        } },
                        React.createElement(Typography, { variant: "caption" }, field.name)))))),
            React.createElement(Grid2, { container: true, spacing: 1, style: { width: "calc(100% - 160px)" } },
                React.createElement(Grid2, { container: true, style: {
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                        padding: "4px",
                        overflowX: "auto",
                        overflowY: "hidden",
                    } },
                    React.createElement(DndContext, { onDragEnd: reorderComps(compType.name), collisionDetection: closestCenter, modifiers: [restrictToHorizontalAxis] },
                        React.createElement(SortableContext, { items: pspComps_
                                .filter((comp) => comp.comp_type === compType.name)
                                .map((comp) => ({
                                id: comp.uid,
                            })), strategy: horizontalListSortingStrategy }, pspComps_
                            .filter((comp) => comp.comp_type === compType.name)
                            .map((comp, indx) => {
                            const fields = getFieldsForCompType(compType.name);
                            return (React.createElement(MAtrixDraggable, { key: `${comp.uid}_psp_comp_draggable`, uid: comp.uid, name: `Comp ${indx + 1}`, onDelete: onPSPCompDelete(comp.uid) }, fields.map((field) => {
                                var _a, _b;
                                const isFormulaField = field.field_type === "formula";
                                const fieldVal = isFormulaField ? comp.fields[field.name].value : comp.fields[field.name];
                                let height = "35px";
                                let inner = (React.createElement(DashAutoSaveField, { variant: "standard", className: "comp-field", fullWidth: true, field: field, disabled: isFormulaField, value: fieldVal, apiEndpoint: `/api/psp_comps/${comp.uid}`, onSuccess: updatePSPComp }));
                                if (field.name === "Comp Quality") {
                                    inner = (React.createElement(CoreChoiceField, { variant: "standard", className: "comp-field", value: (_a = comp.fields[field.name]) !== null && _a !== void 0 ? _a : null, fullWidth: true, onChoiceChange: onCompQualityUpdate(comp.uid), slotProps: {
                                            input: {
                                                endAdornment: pspCompsCurrentlyUpdatingQuality.includes(comp.uid) ? (React.createElement(CoreLoading, null)) : null,
                                            },
                                        } }, field.choice_set.choices.map((option) => {
                                        var _a;
                                        return (React.createElement(MenuItem, { key: option.id, value: option.name }, (_a = PSP_COMP_QUALITY_MAPPING[option.name]) !== null && _a !== void 0 ? _a : option.name));
                                    })));
                                }
                                else if (field.name === "Additional Notes") {
                                    height = "160px";
                                    inner = (React.createElement(React.Fragment, null,
                                        React.createElement(ReactQuill, { value: (_b = comp.fields[field.name]) !== null && _b !== void 0 ? _b : "", onBlur: onAdditionalNotesUpdate(comp.uid), modules: {
                                                toolbar: [[{ list: "bullet" }, { list: "ordered" }]],
                                            }, formats: ["list", "bullet"], placeholder: "Enter your notes here...", style: {
                                                height: "115px",
                                                maxHeight: "115px",
                                                width: "100%",
                                            }, readOnly: !!notesCurrentlySaving.includes(comp.uid) }),
                                        notesCurrentlySaving.includes(comp.uid) && React.createElement(CoreLoading, null)));
                                }
                                return (React.createElement(Grid2, { key: field.name, size: 12, style: { height, marginTop: "8px", display: "flex" } }, inner));
                            })));
                        }))))))))));
};
export default PSPCompsTab;
