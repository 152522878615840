var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Download, FilterList, LabelImportantOutlined, Launch, MoreVert, Refresh, Menu as MenuIcon, VerifiedUserOutlined, KeyboardArrowDown, KeyboardArrowRight, ErrorOutline, ViewColumn, Search, CopyAll, Publish, } from "@mui/icons-material";
import { Box, Checkbox, CircularProgress, ClickAwayListener, Divider, Grid2, Hidden, IconButton, InputAdornment, LinearProgress, List, ListItemIcon, MenuItem, Paper, Popper, ThemeProvider, Tooltip, Typography, } from "@mui/material";
import { GRID_CHECKBOX_SELECTION_COL_DEF, useGridApiRef } from "@mui/x-data-grid-premium";
import axios, { CanceledError } from "axios";
import isObject from "lodash/isObject";
import { enqueueSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import CoreAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreLoading from "@app/common/CoreLoading";
import CoreMenu from "@app/common/CoreMenu";
import CorePopover from "@app/common/CorePopover";
import gridTheme from "@app/common/grid/theme";
import { renderDateTimeCell_Local } from "@app/common/grid/utils";
import RouterLink from "@app/common/RouterLink";
import SearchParamTextField from "@app/common/SearchParamTextField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useCopyLinkToCurrentPage from "@app/hooks/useCopyLinkToCurrentPage";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useStateCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { copyToClipboard, downloadBlob, fileToBase64 } from "@app/util/Utils";
import ActionsToolbar from "./ActionsToolbar";
import AddOrEditClientsDialog from "./AddOrEditClientsDialog";
import ColumnPanel from "./ColumnPanel";
import DataValidation from "./DataValidation";
import Downloading from "./Downloading";
import getDefaultFilterForField from "./get-default-filter-for-field";
import GroupLabel from "./GroupLabel";
import GroupPanel from "./GroupPanel";
import SavedViewPanel from "./SavedViewPanel";
import ScrollTrackingDatagrid from "./ScrollTrackingDatagrid";
import SpreadsheetPagination from "./SpreadsheetPagination";
import Summary from "./Summary";
import { renderCheckboxCell, renderChoiceCell, renderFieldCell, renderFormulaCell, renderStageCell, renderTextCell, } from "./table-cells";
import { renderHeader } from "./table-headers";
import useColumnArrangementApi from "./useColumnArrangementApi";
import useMarkRowAsEditing from "./useMarkRowAsEditing";
import { getGroupColor, getRowClassName, getRowHeight, getRows, GROUP_ROW_HEIGHT, isRowSelectable, STANDARD_ROW_HEIGHT, } from "./utils";
import SavedViewEditor from "./views/SavedViewEditor";
import StageSelect from "../../buyer-leads/components/StageSelect";
import ThirdPartyDialog from "../../buyer-leads/components/ThirdPartyDialog";
import StagesDialog from "../../stages/StagesDialog";
import CollaboratorDialog from "../box-view/actions/CollaboratorDialog";
import { BulkDeleteConfirmContext } from "../box-view/bulk-delete-confirm/BulkDeleteConfirmContext";
import { BuyerContractsNewsfeed, BuyerLeadsNewsfeed, ContactsNewsfeed, OverviewNewsfeed, RecruitsNewsfeed, SellerContractsNewsfeed, SellerLeadsNewsfeed, UsersNewsfeed, } from "../newsfeed/NewsfeedSingleType";
const getColumnWidth = () => 100;
const Spreadsheet = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { additionalMenuItems, canSaveColumnArrangement = true, clientsDialogParams, collaboratorsDialogParams, count, countByGroup, disableRowStageSelect, disableViews, downloadFilename, downloadFunc, entityType, error, fields, getDetailURL, getMailMergeEmailAddressesForItem, getPOSTApiEndpoint, includeNameColumn = true, includeOpenColumn = true, includeStageColumn = true, isCellEditable, isClientsDialogOpen, isCollaboratorsDialogOpen, isLoading, isThirdPartyDialogOpen, items, loadingPercentComplete, nonStandardColumns, onBulkDelete, onBulkUpdateStages, onCellEditStart, onCellEditStop, onClientsDialogClose, onCollaboratorsDialogClose, onFieldChanged, onFieldSaved, onFieldWillSave, onImportFileSelected, onRefresh, onRowClientChange, onRowCollaboratorChange, onRowRemoveClient, onRowRemoveCollaborator, onRowReorderClients, onRowStageChange, onRowThirdPartyChange, onSort, onStagesSaved, onThirdPartyDialogClose, ordering, page, renderActionsColumn, renderLaunchColumn, renderListItem, rowsPerPage, showNewsfeed, slots, stages, tableName, thirdPartyDialogParams, title, total, useDefaultColumnArrangement = false, view, } = props;
    const isPaginated = rowsPerPage != null;
    const session = useSession();
    const updateSearch = useUpdateSearch();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const [selectedRows, setSelectedRows] = useStateCallback(new Set());
    const [isStagesDialogOpen, setIsStagesDialogOpen] = React.useState(false);
    const [actionsMenuAnchorEl, setActionsMenuAnchroEl] = React.useState(null);
    const [verticalScrollPosition, setVerticalScrollPosition] = React.useState(0);
    const [selectedGroup, setSelectedGroup] = useStateCallback(null);
    const [visibleGroups, setVisibleGroups] = useStateCallback(null);
    const [showDataValidation, setShowDataValidation] = useStateCallback(false);
    const [newViewDefaultValue, setNewViewDefaultValue] = React.useState(null);
    // const [currentlyEditingRowUid, setCurrentlyEditingRowUid] = React.useState<string | null>(null);
    const { confirmBulkDelete } = React.useContext(BulkDeleteConfirmContext);
    const [savedViewPanelAnchorEl, setSavedViewPanelAnchorEl] = React.useState(null);
    const handleSavedViewPanelOpened = React.useCallback((e) => {
        setSavedViewPanelAnchorEl(e.currentTarget);
    }, [setSavedViewPanelAnchorEl]);
    const handleSavedViewPanelClosed = React.useCallback(() => {
        setSavedViewPanelAnchorEl(null);
    }, [setSavedViewPanelAnchorEl]);
    const [columnPanelAnchorEl, setColumnPanelAnchorEl] = React.useState(null);
    const handleColumnPanelOpened = React.useCallback((e) => {
        setColumnPanelAnchorEl(e.currentTarget);
    }, [setColumnPanelAnchorEl]);
    const handleColumnPanelClosed = React.useCallback(() => {
        setColumnPanelAnchorEl(null);
    }, [setColumnPanelAnchorEl]);
    const [columnSearchStr, setColumnSearchStr] = React.useState("");
    const handleColumnSearchChanged = React.useCallback((str) => {
        setColumnSearchStr(str);
    }, [setColumnSearchStr]);
    const isSavedViewEditorOpen = searchParams.get("show_saved_view_editor") === "true";
    const errorDialog = useErrorDialog();
    const copyLinkToCurrentPage = useCopyLinkToCurrentPage();
    const gridApi = useGridApiRef();
    useMarkRowAsEditing();
    const columnArrangementApi = useColumnArrangementApi({
        tableName: (_b = (_a = view !== null && view !== void 0 ? view : tableName) !== null && _a !== void 0 ? _a : entityType) !== null && _b !== void 0 ? _b : "",
        gridApi,
        canSaveColumnArrangement,
        useDefaultColumnArrangement,
    });
    const { columnArrangement, freezeColumn, hideColumn, moveColumn, reorderColumns, setColumnWidth, setVisibilityForColumns, unfreezeColumn, unhideColumns, } = columnArrangementApi;
    const onColumnFreeze = React.useCallback((columnName, isFrozen) => {
        if (isFrozen) {
            freezeColumn(columnName);
        }
        else {
            unfreezeColumn(columnName);
        }
    }, [freezeColumn, unfreezeColumn]);
    const onColumnWidthChange = React.useCallback((params) => {
        setColumnWidth(params.colDef.field, params.colDef.width);
    }, [setColumnWidth]);
    const fieldsSaturated = React.useMemo(() => {
        var _a;
        return ((_a = fields === null || fields === void 0 ? void 0 : fields.map((f) => (Object.assign(Object.assign({}, f), { isEditable: f.permissions.length === 0 ||
                f.permissions.some((p) => { var _a, _b; return p.can_edit && ((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes(p.role)); }), isVisible: f.permissions.length === 0 || f.permissions.some((p) => { var _a, _b; return (_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes(p.role); }) })))) !== null && _a !== void 0 ? _a : null);
    }, [fields, (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.roles]);
    const filterColumn = React.useCallback((field) => {
        setNewViewDefaultValue({
            filter_groups: [
                {
                    filters: [getDefaultFilterForField(field, fieldsSaturated !== null && fieldsSaturated !== void 0 ? fieldsSaturated : [])],
                    is_match_all: true,
                    uid: uuidv4(),
                },
            ],
        });
        updateSearch("show_saved_view_editor", "true");
    }, [updateSearch, fieldsSaturated]);
    const renderHeaderParams = React.useMemo(() => {
        return {
            onFreeze: onColumnFreeze,
            onHide: hideColumn,
            onUnhide: unhideColumns,
            onMove: moveColumn,
            onSort,
            onFilter: isSavedViewEditorOpen ? undefined : filterColumn,
            gridApi: gridApi.current,
            disableViews,
        };
    }, [
        onColumnFreeze,
        onSort,
        hideColumn,
        unhideColumns,
        moveColumn,
        isSavedViewEditorOpen,
        filterColumn,
        gridApi,
        disableViews,
    ]);
    const canEdit = (_e = (_d = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _d === void 0 ? void 0 : _d.isInternal) !== null && _e !== void 0 ? _e : false;
    const canEditStages = (_g = (props.canEditStages && ((_f = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.hasRole(["Superadmin", "Admin"])))) !== null && _g !== void 0 ? _g : false;
    const canEditColumnOrder = (_h = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _h === void 0 ? void 0 : _h.hasRole(["Superadmin", "Admin"]);
    const canAccessDataValidation = (_j = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _j === void 0 ? void 0 : _j.hasRole(["Superadmin", "Admin"]);
    const autoSaveFieldWasClicked = React.useRef(false);
    const clearValueWhenCellOpened = React.useRef(false);
    const importFileInput = React.useRef(null);
    const didPaste = React.useRef(false);
    const lastCharacterEntered = React.useRef(null);
    const cellIsSelected = React.useRef(false);
    const fieldsByUid = React.useMemo(() => {
        const res = {};
        for (const field of fieldsSaturated !== null && fieldsSaturated !== void 0 ? fieldsSaturated : []) {
            res[field.uid] = field;
        }
        return res;
    }, [fieldsSaturated]);
    const groups = React.useMemo(() => {
        // console.log("getting groups");
        if (countByGroup) {
            return countByGroup.map((item, i, allGroups) => {
                var _a, _b, _c, _d;
                return ({
                    value: item.group.uid,
                    name: item.group.name,
                    onClick: (_a = item.group.onClick) !== null && _a !== void 0 ? _a : (() => {
                        updateSearch("stage", item.group.uid, "offset", null);
                    }),
                    color: (_c = (_b = stages === null || stages === void 0 ? void 0 : stages.find((s) => s.uid === item.group.uid)) === null || _b === void 0 ? void 0 : _b.color) !== null && _c !== void 0 ? _c : getGroupColor({ index: i, totalGroups: allGroups.length }),
                    count: item.count,
                    isSelected: (_d = item.group.isSelected) !== null && _d !== void 0 ? _d : false,
                    isVisible: false,
                    verticalOffset: null,
                });
            });
        }
        if (!isPaginated && stages && items) {
            return stages
                .map((stage) => {
                var _a;
                return ({
                    value: stage.uid,
                    name: stage.name,
                    color: (_a = stage.color) !== null && _a !== void 0 ? _a : "#aaaaaa",
                    count: items.filter((x) => {
                        const s = x.stage;
                        return s && s.uid === stage.uid;
                    }).length,
                });
            })
                .map((group) => {
                return Object.assign(Object.assign({}, group), { isSelected: selectedGroup === group.value, isVisible: visibleGroups == null || visibleGroups.has(group.value) });
            })
                .map((group, groupIndex, allGroups) => {
                return Object.assign(Object.assign({}, group), { verticalOffset: groupIndex * GROUP_ROW_HEIGHT +
                        allGroups
                            .filter((g, i) => g.isVisible && i < groupIndex)
                            .reduce((tot, g) => tot + g.count * STANDARD_ROW_HEIGHT, 0) });
            });
        }
        return [];
    }, [countByGroup, isPaginated, stages, items, updateSearch, selectedGroup, visibleGroups]);
    const toggleNewsfeed = React.useCallback(() => {
        updateSearch("show_newsfeed", showNewsfeed ? null : "true");
    }, [showNewsfeed, updateSearch]);
    const toggleDataValidation = React.useCallback(() => {
        setShowDataValidation((prev) => !prev);
    }, [setShowDataValidation]);
    const toggleGroupVisibility = React.useCallback((groupValue) => () => {
        setVisibleGroups((prev) => {
            if (prev === null) {
                return new Set([...groups.filter((g) => g.value !== groupValue).map((g) => g.value)]);
            }
            const updatedVisibileGroups = new Set(prev);
            if (updatedVisibileGroups.has(groupValue)) {
                updatedVisibileGroups.delete(groupValue);
            }
            else {
                updatedVisibileGroups.add(groupValue);
            }
            console.log({ updatedVisibileGroups });
            return updatedVisibileGroups;
        });
    }, [groups, setVisibleGroups]);
    const showOrHideAllGroups = React.useCallback(() => {
        setVisibleGroups((prev) => prev == null || prev.size === groups.length ? new Set() : new Set(groups.map((g) => g.value)));
    }, [setVisibleGroups, groups]);
    const openActionsMenu = React.useCallback((e) => {
        setActionsMenuAnchroEl(e.currentTarget);
    }, [setActionsMenuAnchroEl]);
    const closeActionsMenu = React.useCallback(() => {
        setActionsMenuAnchroEl(null);
    }, [setActionsMenuAnchroEl]);
    const openStagesDialog = React.useCallback(() => {
        setIsStagesDialogOpen(true);
    }, [setIsStagesDialogOpen]);
    const openSavedViewEditor = React.useCallback((viewUid) => {
        updateSearch("show_saved_view_editor", "true", "view", viewUid);
        handleSavedViewPanelClosed();
    }, [handleSavedViewPanelClosed, updateSearch]);
    const closeSavedViewEditor = React.useCallback(() => {
        updateSearch("show_saved_view_editor", null);
    }, [updateSearch]);
    /**
     * Called when editing ends, but before a field is saved.
     *
     * @param eventUid The event to update
     * @param fieldName The field being saved
     */
    const handleFieldWillSave = React.useCallback((
    /** The event to update */
    itemUid, 
    /** The field name being saved */
    fieldName) => (
    /** The field value being saved */
    value) => {
        if (!onFieldWillSave) {
            throw new Error("`onFieldWillSave` is required");
        }
        console.log("WILL SAVE", { itemUid, fieldName, value });
        onFieldWillSave({
            row: itemUid,
            field: fieldName,
            value,
        });
    }, [onFieldWillSave]);
    /**
     * If the response was successful, update table state
     * @param itemUid the event to update
     * @returns a callback
     */
    const handleFieldSaved = React.useCallback((itemUid, fieldName) => (response) => {
        if (!onFieldSaved) {
            throw new Error("`onFieldSaved` is required");
        }
        onFieldSaved({
            row: itemUid,
            field: fieldName,
            response,
        });
    }, [onFieldSaved]);
    /**
     * If the response was unsuccessful, show an error
     * @param itemUid the event that was supposed to be updated
     * @param fieldName the field that was supposed to be updated
     * @returns a callback
     */
    const handleFieldError = React.useCallback((itemUid, fieldName) => (fieldError) => {
        console.error(`Error updating item ${itemUid}, field ${fieldName}: ${fieldError}`);
        errorDialog(fieldError);
    }, [errorDialog]);
    const handleStagesDialogClosed = React.useCallback(() => {
        setIsStagesDialogOpen(false);
    }, [setIsStagesDialogOpen]);
    const handleStagesSaved = React.useCallback((allStages) => {
        setIsStagesDialogOpen(false);
        if (onStagesSaved) {
            onStagesSaved(allStages);
        }
    }, [onStagesSaved]);
    const handlePageChange = React.useCallback((_, newPage) => {
        // console.log(onPageChanged);
        updateSearch("offset", newPage * rowsPerPage);
    }, [updateSearch, rowsPerPage]);
    const handleRowsPerPageChange = React.useCallback((e) => {
        updateSearch("limit", parseFloat(e.target.value), "offset", null);
    }, [updateSearch]);
    const handleRefresh = React.useCallback(() => {
        onRefresh();
    }, [onRefresh]);
    const unselectAllRows = React.useCallback(() => {
        setSelectedRows(new Set());
    }, [setSelectedRows]);
    const handleSelectionModelChange = React.useCallback((rowSelectionModel, _) => {
        // @ts-expect-error: number not assignable to string
        setSelectedRows(new Set(rowSelectionModel));
    }, [setSelectedRows]);
    const handleSortModelChange = React.useCallback((model, details) => {
        console.log("sort model changed", { model, details });
        updateSearch("sort", model.length === 0 ? null : model.map((x) => (x.sort === "desc" ? "-" : "") + x.field).join(","), "offset", 0);
    }, [updateSearch]);
    const handleCellFocusOut = React.useCallback((params) => {
        const elems = document.querySelectorAll(`[data-id="${params.id}"] > .MuiDataGrid-cell[tabindex='0'][data-field="${params.field}"]`);
        elems.forEach((elem) => {
            elem.setAttribute("tabindex", "-1");
        });
    }, []);
    const setSelectedCell = React.useCallback((value) => {
        cellIsSelected.current = false;
        const elemsToUnselect = document.querySelectorAll(".MuiDataGrid-cell[tabindex='0']");
        elemsToUnselect.forEach((elem) => {
            elem.setAttribute("tabindex", "-1");
        });
        if (value) {
            const elems = document.querySelectorAll(`[data-id="${value.rowId}"] > .MuiDataGrid-cell[data-field="${value.field}"]`);
            elems.forEach((elem) => {
                elem.setAttribute("tabindex", "0");
            });
            cellIsSelected.current = true;
        }
    }, []);
    const handleClickAway = React.useCallback(() => {
        if (cellIsSelected.current) {
            setSelectedCell(null);
        }
    }, [setSelectedCell]);
    /**
     * Gets the field name for the adjacend editable column on the left or right of the current cell
     *
     * @param params GridRenderEditCellParams of the current cell
     * @param fieldName field name of the current column
     * @param mode either "next" or "previous"
     * @returns the field name of the adjacent column
     */
    const getAdjacentColumnFieldName = React.useCallback((fieldName, mode) => {
        var _a, _b, _c;
        const colIndex = gridApi.current.getColumnIndex(fieldName); // gets the column index (considers visible columns only)
        const visibleColumns = gridApi.current.getVisibleColumns(); // TODO: type this properly
        if (mode === "next") {
            return (_b = (_a = visibleColumns.find((_, indx) => indx > colIndex)) === null || _a === void 0 ? void 0 : _a.field) !== null && _b !== void 0 ? _b : null;
        }
        const prevColumns = visibleColumns.filter((_, indx) => indx < colIndex);
        if (prevColumns.length > 0) {
            const lastPrevColumn = prevColumns[prevColumns.length - 1];
            return (_c = lastPrevColumn === null || lastPrevColumn === void 0 ? void 0 : lastPrevColumn.field) !== null && _c !== void 0 ? _c : null;
        }
        return null;
    }, [gridApi]);
    const rows = React.useMemo(() => getRows(items !== null && items !== void 0 ? items : [], groups.map((g) => (Object.assign(Object.assign({}, g), { isVisible: visibleGroups == null || visibleGroups.has(g.value) }))), 
    // @ts-expect-error Type 'string | undefined' is not assignable to type 'string'.
    (item) => { var _a; return (_a = item.stage) === null || _a === void 0 ? void 0 : _a.uid; }, selectedGroup, isPaginated, entityType), [entityType, groups, isPaginated, items, selectedGroup, visibleGroups]);
    console.log({ rows });
    const rowsByUid = React.useMemo(() => {
        const res = {};
        for (const row of rows) {
            res[row.uid] = row;
        }
        return res;
    }, [rows]);
    const selectedEntityTypes = React.useMemo(() => {
        var _a;
        const res = new Set();
        for (const rowUid of selectedRows) {
            const entityType_ = (_a = rowsByUid[rowUid]) === null || _a === void 0 ? void 0 : _a.type;
            if (entityType_) {
                res.add(entityType_);
            }
        }
        console.log({ res });
        return res;
    }, [rowsByUid, selectedRows]);
    const selectAllRows = React.useCallback(() => {
        if (selectedRows.size < rows.length) {
            setSelectedRows(new Set(rows.map((r) => r.id)));
        }
        else {
            setSelectedRows(new Set());
        }
    }, [rows, selectedRows.size, setSelectedRows]);
    const handleCellEditStart = React.useCallback((params) => {
        // setCurrentlyEditingRowUid(params.id as string);
        if (onCellEditStart) {
            onCellEditStart(params);
        }
    }, [onCellEditStart]);
    const handleCellEditStop = React.useCallback(() => {
        // setCurrentlyEditingRowUid(null);
        if (onCellEditStop) {
            onCellEditStop();
        }
    }, [onCellEditStop]);
    /**
     * Creates a keyboard event handler for a cell in "edit" mode
     *
     * @param params GridRenderEditCellparams
     * @returns The event handler
     */
    const handleEditFieldKeyDown = React.useCallback((params, multiline) => (e) => {
        if (multiline && e.key === "Enter" && e.shiftKey) {
            return;
        }
        const fieldName = params["field"];
        const item = params["row"];
        const rowIndex = rows.findIndex((row) => row.id === params.id);
        let newCellRowId = null;
        let newCellFieldName = null;
        if (e.key === "ArrowDown" && !autoSaveFieldWasClicked.current) {
            const newItem = items === null || items === void 0 ? void 0 : items[rowIndex + 1];
            if (newItem) {
                newCellRowId = newItem.uid;
            }
        }
        else if (e.key === "ArrowUp" && !autoSaveFieldWasClicked.current) {
            const newEvent = items === null || items === void 0 ? void 0 : items[rowIndex - 1];
            if (newEvent) {
                newCellRowId = newEvent.uid;
            }
        }
        else if (e.key === "ArrowLeft" && !autoSaveFieldWasClicked.current) {
            const previousColumnFieldName = getAdjacentColumnFieldName(fieldName, "previous");
            if (previousColumnFieldName) {
                newCellFieldName = previousColumnFieldName;
            }
        }
        else if (e.key === "ArrowRight" && !autoSaveFieldWasClicked.current) {
            const nextColumnFieldName = getAdjacentColumnFieldName(fieldName, "next");
            if (nextColumnFieldName) {
                newCellFieldName = nextColumnFieldName;
            }
        }
        else if (e.key === "Tab") {
            e.preventDefault();
            e.stopPropagation();
            const nextColumnFieldName = getAdjacentColumnFieldName(fieldName, "next");
            if (nextColumnFieldName) {
                newCellFieldName = nextColumnFieldName;
            }
        }
        else if (e.key === "Enter" || e.key === "Return") {
            e.preventDefault();
            e.stopPropagation();
            const newEvent = items === null || items === void 0 ? void 0 : items[rowIndex + 1];
            if (newEvent) {
                newCellRowId = newEvent.uid;
            }
        }
        if (newCellRowId || newCellFieldName) {
            gridApi.current.setCellFocus(newCellRowId !== null && newCellRowId !== void 0 ? newCellRowId : item.uid, newCellFieldName !== null && newCellFieldName !== void 0 ? newCellFieldName : fieldName);
            const cell = {
                id: item.uid,
                field: fieldName,
            };
            if (gridApi.current.getCellMode(cell.id, cell.field) === "edit") {
                gridApi.current.stopCellEditMode(cell);
            }
            setSelectedCell({
                rowId: newCellRowId !== null && newCellRowId !== void 0 ? newCellRowId : item.uid,
                field: newCellFieldName !== null && newCellFieldName !== void 0 ? newCellFieldName : fieldName,
            });
        }
    }, [getAdjacentColumnFieldName, gridApi, items, rows, setSelectedCell]);
    const handleEditFieldClick = React.useCallback(() => {
        autoSaveFieldWasClicked.current = true;
    }, []);
    const renderFieldEditCell = React.useCallback((params, autoFocus = true) => {
        var _a, _b;
        if (isCellEditable && !isCellEditable(params)) {
            return undefined;
        }
        const fieldUid = params["field"];
        const field = fieldsByUid[fieldUid];
        console.log({ params, autoFocus });
        if (!field) {
            console.error(`Unable to find field ${fieldUid} in pipeline ${entityType}`);
            return null;
        }
        if (!getPOSTApiEndpoint) {
            throw new Error("`getPostAPIEndpoint` is required when working with `fields`");
        }
        const item = params["row"];
        const value = (_a = item.fields) === null || _a === void 0 ? void 0 : _a[field.name];
        autoSaveFieldWasClicked.current = false;
        const multiline = undefined; // we don't have any multiline text fields at this point
        let initialValue = null;
        if (clearValueWhenCellOpened.current && field.field_type !== "checkbox" && autoFocus) {
            if (lastCharacterEntered.current) {
                initialValue = lastCharacterEntered.current;
            }
        }
        const coreAutoSaveField = (React.createElement(CoreAutoSaveField, { fullWidth: true, style: {
                height: multiline ? undefined : "100%",
                transform: field.field_type === "checkbox" ? "translateY(2px)" : undefined,
                marginTop: field.field_type === "checkbox" ? "unset" : undefined,
            }, field: field, SelectProps: ["choice", "dropdown"].includes(field.field_type)
                ? {
                    MenuProps: {
                        MenuListProps: {
                            disablePadding: true,
                            dense: true,
                        },
                    },
                }
                : undefined, slotProps: {
                input: {
                    disableUnderline: true,
                    style: {
                        height: ["choice", "dropdown"].includes(field.field_type) ? "100%" : undefined,
                    },
                },
            }, 
            /* @ts-expect-error Property 'initialValue' does not exist on type */
            initialValue: initialValue !== null && initialValue !== void 0 ? initialValue : undefined, value: 
            // eslint-disable-next-line no-nested-ternary
            clearValueWhenCellOpened.current && field.field_type !== "checkbox" && autoFocus
                ? null
                : field.field_type === "checkbox"
                    ? (value !== null && value !== void 0 ? value : false)
                    : ((_b = (isObject(value) && !Array.isArray(value) ? value["value"] : value)) !== null && _b !== void 0 ? _b : null), apiEndpoint: getPOSTApiEndpoint(item), onSaveBegin: handleFieldWillSave(item.uid, field.name), onSuccess: handleFieldSaved(item.uid, field.name), onError: handleFieldError(item.uid, field.name), hasPicker: field.field_type === "date" ? true : undefined, onKeyDown: handleEditFieldKeyDown(params, !!multiline), onClick: handleEditFieldClick, autoFocus: autoFocus, multiline: multiline }));
        if (multiline) {
            const anchorEl = document.querySelector(`[data-id="${item.uid}"] > [data-field="${field.uid}"]`);
            return (React.createElement(Popper, { open: true, anchorEl: anchorEl, placement: "bottom-start" },
                React.createElement(Paper, { elevation: 1, sx: {
                        p: 1,
                        minWidth: params.colDef.computedWidth,
                        transform: "translateY(-22px)",
                    } }, coreAutoSaveField)));
        }
        return coreAutoSaveField;
    }, [
        isCellEditable,
        fieldsByUid,
        getPOSTApiEndpoint,
        handleFieldWillSave,
        handleFieldSaved,
        handleFieldError,
        handleEditFieldKeyDown,
        handleEditFieldClick,
        entityType,
    ]);
    const stagesByType = React.useMemo(() => {
        if (!stages || stages.length === 0) {
            return {};
        }
        return stages.reduce((acc, stage) => {
            const stageType = stage.type === "Listing" ? "Seller Contract" : stage.type;
            if (!acc[stageType]) {
                acc[stageType] = [];
            }
            acc[stageType].push(stage);
            return acc;
        });
    }, [stages]);
    const handleRowStageChange = React.useCallback((itemUid) => (stageUid) => {
        gridApi.current.stopCellEditMode({ id: itemUid, field: "stage" });
        if (!onRowStageChange) {
            throw new Error("`onRowStageChange` is required to update a row's stage");
        }
        console.log("Change row with uid: ", itemUid, " to stage with uid: ", stageUid);
        if (!stageUid) {
            // sanity check: dropdown should not allow user to select an empty value
            console.warn("Must select a stage.");
        }
        else {
            onRowStageChange({
                row: itemUid,
                stageUid,
            });
        }
    }, [gridApi, onRowStageChange]);
    const renderStageEditCell = React.useCallback((params) => {
        var _a, _b, _c;
        const item = params["row"];
        const row = rowsByUid[item.uid];
        const value = row ? (_a = row.stage) === null || _a === void 0 ? void 0 : _a.uid : (_b = item.stage) === null || _b === void 0 ? void 0 : _b.uid;
        // @ts-expect-error Property 'type' does not exist on type 'T'
        const rowType = row ? row.entity_type : item.type;
        const stages_ = (_c = stagesByType[rowType]) !== null && _c !== void 0 ? _c : [];
        return (React.createElement(Grid2, { size: 12, style: { height: STANDARD_ROW_HEIGHT } },
            React.createElement(StageSelect, { stages: stages_, value: value !== null && value !== void 0 ? value : "", onChange: handleRowStageChange(item.uid), TextFieldProps: {
                    disabled: disableRowStageSelect || !handleRowStageChange,
                    // style: { height: STANDARD_ROW_HEIGHT },
                }, disableInputAdornment: true })));
    }, [rowsByUid, stagesByType, handleRowStageChange, disableRowStageSelect]);
    const onThirdPartyDialogClosed = React.useCallback(() => {
        // if (currentlyEditingRowUid) {
        //   gridApi.current.stopCellEditMode({ id: currentlyEditingRowUid, field: "listing_agent" });
        // }
        if (onThirdPartyDialogClose) {
            onThirdPartyDialogClose();
        }
    }, [onThirdPartyDialogClose]);
    const handleRowThirdPartyChange = React.useCallback((params, vendorUid) => {
        var _a, _b, _c;
        if (!onRowThirdPartyChange) {
            throw new Error("`onRowThirdPartyChange` is required to update a row's stage");
        }
        const rowUid = (_b = (_a = thirdPartyDialogParams === null || thirdPartyDialogParams === void 0 ? void 0 : thirdPartyDialogParams.buyerLead) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : (_c = thirdPartyDialogParams === null || thirdPartyDialogParams === void 0 ? void 0 : thirdPartyDialogParams.sellerLead) === null || _c === void 0 ? void 0 : _c.uid;
        if (rowUid) {
            // gridApi.current.stopCellEditMode({ id: rowUid, field: "listing_agent" });
            onRowThirdPartyChange({ rowUid, updateParams: params, vendorUid });
        }
        else {
            console.warn("Could not find rowUid to update third party.", thirdPartyDialogParams);
        }
    }, [onRowThirdPartyChange, thirdPartyDialogParams]);
    const onClientsDialogClosed = React.useCallback(() => {
        if (onClientsDialogClose) {
            onClientsDialogClose();
        }
    }, [onClientsDialogClose]);
    const handleRowClientsChange = React.useCallback((params) => {
        const { clientData, clientUid } = params;
        if (!onRowClientChange) {
            throw new Error("`onRowClientChange` is required to update a row's client");
        }
        const rowUid = clientsDialogParams === null || clientsDialogParams === void 0 ? void 0 : clientsDialogParams.boxUid;
        if (rowUid) {
            const contactData = clientData ? { fields: clientData } : undefined;
            onRowClientChange({
                rowUid,
                updateParams: {
                    contact_uid: clientUid,
                    contact_data: contactData,
                },
            });
        }
        else {
            console.warn("Could not find rowUid to update client.", clientsDialogParams);
        }
    }, [clientsDialogParams, onRowClientChange]);
    const handleRowReorderClients = React.useCallback((clientUids) => {
        if (!onRowReorderClients) {
            throw new Error("`onRowReorderClients` is required to update a row's client");
        }
        const rowUid = clientsDialogParams === null || clientsDialogParams === void 0 ? void 0 : clientsDialogParams.boxUid;
        if (rowUid) {
            onRowReorderClients({ rowUid, clientUids });
        }
        else {
            console.warn("Could not find rowUid to reorder clients.", clientsDialogParams);
        }
    }, [clientsDialogParams, onRowReorderClients]);
    const handleRowRemoveClient = React.useCallback((clientUid) => {
        if (!onRowRemoveClient) {
            throw new Error("`onRowRemoveClient` is required to remove a row's client");
        }
        const rowUid = clientsDialogParams === null || clientsDialogParams === void 0 ? void 0 : clientsDialogParams.boxUid;
        if (rowUid) {
            onRowRemoveClient({ rowUid, clientUid });
        }
        else {
            console.warn("Could not find rowUid to remove client.", clientsDialogParams);
        }
    }, [clientsDialogParams, onRowRemoveClient]);
    const onCollaboratorsDialogClosed = React.useCallback(() => {
        console.log("TEST: CLOSE DIALOG");
        if (onCollaboratorsDialogClose) {
            onCollaboratorsDialogClose();
        }
    }, [onCollaboratorsDialogClose]);
    const handleRowCollaboratorsChange = React.useCallback((params, collaboratorUid) => {
        if (!onRowCollaboratorChange) {
            throw new Error("`onRowCollaboratorChange` is required to update a row's collaborator");
        }
        const rowUid = collaboratorsDialogParams === null || collaboratorsDialogParams === void 0 ? void 0 : collaboratorsDialogParams.boxUid;
        console.log("UPDATE ROW COLLABORATOR", collaboratorsDialogParams);
        if (rowUid) {
            onRowCollaboratorChange({
                rowUid,
                updateParams: params,
                collaboratorUid,
            });
        }
        else {
            console.warn("Could not find rowUid to update collaborator.", collaboratorsDialogParams);
        }
    }, [collaboratorsDialogParams, onRowCollaboratorChange]);
    const handleRowRemoveCollaborator = React.useCallback((collaboratorUid) => {
        if (!onRowRemoveCollaborator) {
            throw new Error("`onRowCollaboratorRemove` is required to remove a row's collaborator");
        }
        const rowUid = collaboratorsDialogParams === null || collaboratorsDialogParams === void 0 ? void 0 : collaboratorsDialogParams.boxUid;
        if (rowUid) {
            onRowRemoveCollaborator({ rowUid, collaboratorUid });
        }
        else {
            console.warn("Could not find rowUid to remove collaborator.", collaboratorsDialogParams);
        }
    }, [collaboratorsDialogParams, onRowRemoveCollaborator]);
    const columns = React.useMemo(() => {
        const actionsColumnWidth = 65;
        const columns_ = [
            Object.assign(Object.assign({}, GRID_CHECKBOX_SELECTION_COL_DEF), { hideable: false, renderHeader: () => {
                    return (React.createElement(Checkbox, { size: "small", color: "primary", style: { padding: 0, transform: "translateX(3px)" }, checked: rows.length > 0 && selectedRows.size === rows.length, indeterminate: rows.length > 0 && selectedRows.size > 0 && selectedRows.size < rows.length, onChange: selectAllRows, disabled: !rows || rows.length === 0 }));
                } }),
            renderActionsColumn && {
                field: "_actions",
                headerName: "",
                editable: false,
                sortable: false,
                width: actionsColumnWidth,
                minWidth: actionsColumnWidth,
                resizable: false,
                hideable: false,
                cellClassName: "cell-button",
                renderCell: renderActionsColumn,
            },
            includeOpenColumn && {
                field: "uid",
                headerName: "",
                editable: false,
                sortable: false,
                width: 30,
                minWidth: 30,
                resizable: false,
                hideable: false,
                cellClassName: "cell-button",
                renderCell: renderLaunchColumn !== null && renderLaunchColumn !== void 0 ? renderLaunchColumn : ((params) => {
                    const url = getDetailURL === null || getDetailURL === void 0 ? void 0 : getDetailURL(params.value);
                    return url ? (React.createElement(IconButton, { size: "small", component: RouterLink, to: url, state: {
                            from: [window.location.pathname, window.location.search].filter((o) => o).join(""),
                            allBoxPaths: getDetailURL ? rows.map((r) => getDetailURL(r.uid)) : null,
                        }, style: { marginLeft: "auto", marginRight: "auto" }, tabIndex: -1 },
                        React.createElement(Launch, { fontSize: "small" }))) : (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Item not available due to system error") },
                        React.createElement(ErrorOutline, { color: "error", fontSize: "small", style: { paddingLeft: "5px" } })));
                }),
                renderHeader: !isPaginated
                    ? () => {
                        return (React.createElement(IconButton, { size: "small", onClick: showOrHideAllGroups, style: { padding: 0, transform: "translateX(2px)" } }, visibleGroups == null || visibleGroups.size === groups.length ? (React.createElement(KeyboardArrowDown, { fontSize: "small" })) : (React.createElement(KeyboardArrowRight, { fontSize: "small" }))));
                    }
                    : undefined,
            },
            includeNameColumn && {
                field: "name",
                headerName: "Name",
                editable: false,
                sortable: false,
                renderHeader: renderHeader(renderHeaderParams),
            },
            includeStageColumn && {
                field: "stage",
                headerName: "Stage",
                editable: !!onRowStageChange,
                renderCell: renderStageCell(stages !== null && stages !== void 0 ? stages : []),
                renderHeader: renderHeader(renderHeaderParams),
                renderEditCell: renderStageEditCell,
            },
            ...(nonStandardColumns !== null && nonStandardColumns !== void 0 ? nonStandardColumns : []).map((x) => (Object.assign(Object.assign({}, x), { renderHeader: renderHeader(renderHeaderParams) }))),
            ...(fieldsSaturated !== null && fieldsSaturated !== void 0 ? fieldsSaturated : [])
                .filter((field) => field.isVisible)
                .map((field) => {
                var _a;
                return ({
                    field: field.uid,
                    headerName: field.name,
                    cellClassName: (params) => {
                        var _a, _b, _c, _d;
                        if (field.field_type === "formula" &&
                            params.colDef.headerName &&
                            ((_b = (_a = params.row.fields) === null || _a === void 0 ? void 0 : _a[params.colDef.headerName]) === null || _b === void 0 ? void 0 : _b.error)) {
                            return "cell-field-error";
                        }
                        if (field.field_type === "formula" &&
                            params.colDef.headerName &&
                            ((_d = (_c = params.row.fields) === null || _c === void 0 ? void 0 : _c[params.colDef.headerName]) === null || _d === void 0 ? void 0 : _d.override_timestamp)) {
                            return "cell-field-frozen";
                        }
                        return `cell-field-${field.field_type}`;
                    },
                    width: getColumnWidth(),
                    align: (() => {
                        if (["date", "datetime", "time", "number"].includes(field.field_type) ||
                            (field.field_type === "formula" && field.format != null)) {
                            return "right";
                        }
                        if (field.field_type === "checkbox") {
                            return "center";
                        }
                        return "left";
                    })(),
                    editable: !["checkbox"].includes(field.field_type) && field.isEditable,
                    valueGetter: (_, row) => {
                        var _a, _b, _c, _d, _e;
                        const item = row;
                        let value;
                        if (field.field_type === "formula") {
                            value = (_c = (_b = (_a = item.fields) === null || _a === void 0 ? void 0 : _a[field.name]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : "";
                        }
                        else {
                            value = (_e = (_d = item.fields) === null || _d === void 0 ? void 0 : _d[field.name]) !== null && _e !== void 0 ? _e : "";
                        }
                        return value !== null && value !== void 0 ? value : "";
                    },
                    // valueParser: (value, row, col) => {
                    //   console.log("valueParser", { value, row, col });
                    //   if (field.field_type === "choice" && field.allows_multiple) {
                    //     return value.split(",");
                    //   }
                    //   return value;
                    // },
                    renderCell: (_a = {
                        formula: renderFormulaCell,
                        checkbox: canEdit && field.isEditable ? renderFieldEditCell : renderCheckboxCell,
                        text: renderTextCell,
                        choice: renderChoiceCell,
                    }[field.field_type]) !== null && _a !== void 0 ? _a : renderFieldCell(field),
                    renderEditCell: renderFieldEditCell,
                    renderHeader: renderHeader(Object.assign(Object.assign({}, renderHeaderParams), { fieldUid: field.uid })),
                });
            }),
            {
                field: "created_at",
                headerName: "Created",
                editable: false,
                sortable: true,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
            {
                field: "updated_at",
                headerName: "Updated",
                editable: false,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
            includeStageColumn && {
                field: "last_stage_change_timestamp",
                headerName: "Last Stage Change Timestamp",
                editable: false,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
            {
                field: "last_comment_timestamp",
                headerName: "Last Comment Timestamp",
                editable: false,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
            {
                field: "last_email_timestamp",
                headerName: "Last Email Timestamp",
                editable: false,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
            {
                field: "last_sms_timestamp",
                headerName: "Last SMS Timestamp",
                editable: false,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
            {
                field: "last_call_timestamp",
                headerName: "Last Call Timestamp",
                editable: false,
                renderCell: renderDateTimeCell_Local,
                renderHeader: renderHeader(renderHeaderParams),
            },
        ]
            .filter((column) => !!column)
            .map((column) => column)
            .filter((column) => {
            var _a, _b;
            return !columnSearchStr.trim() ||
                (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (_b = (_a = gridApi.current) === null || _a === void 0 ? void 0 : _a.getPinnedColumns().left) === null || _b === void 0 ? void 0 : _b.includes(column.field)) ||
                columnSearchStr
                    .trim()
                    .split(",")
                    .map((x) => x.toLowerCase().trim())
                    .some((x) => { var _a; return (_a = column.headerName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(x); });
        })
            .map((column, _, allColumns) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({}, column), { editable: (column.editable || column.editable == null) && canEdit, filterable: false, disableColumnMenu: true, width: ((_a = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.find((x) => x.field_uid_or_column_name === column.field)) === null || _a === void 0 ? void 0 : _a.width) || column.width || 150, resizable: column.resizable !== false, description: fieldsByUid[column.field] && fieldsByUid[column.field].help_text
                    ? ((_b = fieldsByUid[column.field].help_text) !== null && _b !== void 0 ? _b : undefined)
                    : column.headerName, type: (_c = column.type) !== null && _c !== void 0 ? _c : "string", renderCell: (params) => {
                    if (params.row.__rowtype === "group") {
                        let columnIndex = 0;
                        for (const col of allColumns) {
                            if (col.field === params.field) {
                                break;
                            }
                            columnIndex += 1;
                            if (columnIndex > 1) {
                                return null;
                            }
                        }
                        // show/hide the entire group
                        if (columnIndex === 0) {
                            return (React.createElement(IconButton, { onClick: toggleGroupVisibility(params.row.__group.value), size: "small" }, visibleGroups == null || visibleGroups.has(params.row.__group.value) ? (React.createElement(KeyboardArrowDown, { fontSize: "small" })) : (React.createElement(KeyboardArrowRight, { fontSize: "small" }))));
                        }
                        // render the group label
                        if (columnIndex === 1) {
                            return React.createElement(GroupLabel, { label: params.row.__group.name, color: params.row.__group.color });
                        }
                        // nothing goes in the columns to the right
                        return null;
                    }
                    if (column.renderCell) {
                        return column.renderCell(params);
                    }
                    return params.value;
                }, colSpan: (params) => {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if ((params === null || params === void 0 ? void 0 : params.row) && params.row.__rowtype === "group") {
                        let columnIndex = 0;
                        for (const col of allColumns) {
                            if (col.field === params.field) {
                                break;
                            }
                            columnIndex += 1;
                            if (columnIndex > 1) {
                                return 1;
                            }
                        }
                        if (columnIndex === 1) {
                            return 100;
                        }
                        return 1;
                    }
                    return 1;
                }, valueGetter: (value, row, column_, apiRef) => {
                    if (row.__rowtype === "group") {
                        return undefined;
                    }
                    if (column.valueGetter) {
                        // @ts-expect-error
                        return column.valueGetter(value, row, column_, apiRef);
                    }
                    return value;
                } }));
        })
            .sort((a, b) => {
            var _a, _b;
            if (!canSaveColumnArrangement) {
                return 0;
            }
            const aIndex = (_a = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.findIndex((c) => c.field_uid_or_column_name === a.field)) !== null && _a !== void 0 ? _a : Number.MAX_SAFE_INTEGER;
            const bIndex = (_b = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.findIndex((c) => c.field_uid_or_column_name === b.field)) !== null && _b !== void 0 ? _b : Number.MAX_SAFE_INTEGER;
            if (aIndex === -1 && bIndex !== -1) {
                return 1;
            }
            if (aIndex !== -1 && bIndex === -1) {
                return -1;
            }
            if (aIndex < bIndex) {
                return -1;
            }
            if (aIndex > bIndex) {
                return 1;
            }
            return 0;
        });
        return columns_;
    }, [
        renderActionsColumn,
        includeOpenColumn,
        renderLaunchColumn,
        isPaginated,
        includeNameColumn,
        renderHeaderParams,
        includeStageColumn,
        onRowStageChange,
        stages,
        renderStageEditCell,
        nonStandardColumns,
        fieldsSaturated,
        rows,
        selectedRows.size,
        selectAllRows,
        getDetailURL,
        showOrHideAllGroups,
        visibleGroups,
        groups.length,
        canEdit,
        renderFieldEditCell,
        columnSearchStr,
        gridApi,
        columnArrangement,
        fieldsByUid,
        toggleGroupVisibility,
        canSaveColumnArrangement,
    ]);
    /**
     * When in `view` mode: deletes the value when Backspace or Delete are pressed.
     * Also indicates that the cell should be cleared if the key toggles edit mode.
     * I.e. if the cell is filled with "42" and the user types "3", the value will be "3", not "423".
     *
     * When in `edit` mode: does nothing, `handleEditFieldKeyDown` is used.
     *
     * @param params GridCellParams
     * @param event MuiEvent
     */
    const handleCellKeyDown = React.useCallback((params, keyboardEvent) => {
        if (params.cellMode === "view") {
            // because we're in `view` mode,
            clearValueWhenCellOpened.current = true;
            // cell is being toggled by key press, not mouse
            autoSaveFieldWasClicked.current = false;
            const e = keyboardEvent;
            // cell is being toggled by paste
            didPaste.current = e.key === "v" && (e.ctrlKey || e.metaKey);
            // Delete the value in the cell
            if (e.key === "Backspace" || e.key === "Delete") {
                // eslint-disable-next-line no-param-reassign
                keyboardEvent.defaultMuiPrevented = true;
                console.log(e.key);
                const fieldUid = params["field"];
                const field = fieldsByUid[fieldUid];
                const item = params["row"];
                const apiEndpoint = getPOSTApiEndpoint === null || getPOSTApiEndpoint === void 0 ? void 0 : getPOSTApiEndpoint(item);
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (field && apiEndpoint && item.fields[field.name] !== undefined) {
                    if (!onFieldChanged) {
                        throw new Error("`onFieldChanged` is required");
                    }
                    if (!onFieldSaved) {
                        throw new Error("`onFieldSaved` is required");
                    }
                    const fieldType = field.field_type;
                    if (fieldType === "formula" && !field.isEditable) {
                        enqueueErrorSnackbar("You can't delete the value of a formula field.");
                    }
                    else {
                        let value = null;
                        if (fieldType === "checkbox") {
                            value = false;
                        }
                        if (fieldType === "choice" && field.allows_multiple) {
                            value = [];
                        }
                        console.log(`Deleting field ${field.name} of type ${fieldType} for event ${item.uid}`);
                        onFieldChanged({
                            row: item.uid,
                            field: field.name,
                            value,
                        });
                        axios
                            .post(apiEndpoint, { fields: { [field.name]: value } })
                            .then((result) => {
                            onFieldSaved({
                                row: item.uid,
                                field: field.name,
                                response: result,
                            });
                        })
                            .catch(enqueueErrorSnackbar);
                    }
                }
            }
        }
    }, [fieldsByUid, getPOSTApiEndpoint, onFieldChanged, onFieldSaved]);
    const getHighlightedCell = React.useCallback(() => document.querySelector(".MuiDataGrid-cell[tabindex='0']"), []);
    /**
     * Callback fired when a cell is clicked.
     * Indicates that, if focus is given to the cell for editing, we should NOT clear out the value.
     * The cursor will be placed at the end of the cell.
     * Ex: if the value in the cell is "42" and the user types "3", the value will be "423".
     */
    const handleCellClick = React.useCallback((params) => {
        clearValueWhenCellOpened.current = false;
        autoSaveFieldWasClicked.current = true;
        setSelectedCell({
            rowId: String(params.id),
            field: params.field,
        });
    }, [setSelectedCell]);
    const onKeyDown = React.useCallback((e) => {
        const keycode = e.keyCode;
        const isPrintable = (keycode > 47 && keycode < 58) || // number keys
            keycode === 32 ||
            keycode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
            (keycode > 64 && keycode < 91) || // letter keys
            (keycode > 95 && keycode < 112) || // numpad keys
            (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
            (keycode > 218 && keycode < 223); // [\]' (in order)
        if (isPrintable && !e.ctrlKey && !e.altKey && !e.metaKey) {
            lastCharacterEntered.current = e.key;
            if (e.shiftKey) {
                lastCharacterEntered.current = e.key.toUpperCase();
            }
        }
        else {
            lastCharacterEntered.current = null;
        }
        if (e.key === "Tab") {
            const highlightedCell = getHighlightedCell();
            if (highlightedCell) {
                const row = highlightedCell.parentElement;
                const rowId = row === null || row === void 0 ? void 0 : row.dataset["id"];
                const fieldName = highlightedCell.dataset["field"];
                e.preventDefault();
                e.stopPropagation();
                if (fieldName) {
                    const nextColumnFieldName = getAdjacentColumnFieldName(fieldName, "next");
                    let newCellFieldName = null;
                    if (nextColumnFieldName) {
                        newCellFieldName = nextColumnFieldName;
                    }
                    gridApi.current.setCellFocus(rowId, newCellFieldName !== null && newCellFieldName !== void 0 ? newCellFieldName : fieldName);
                    const cell = {
                        id: rowId,
                        field: fieldName,
                    };
                    if (gridApi.current.getCellMode(cell.id, cell.field) === "edit") {
                        gridApi.current.stopCellEditMode(cell);
                    }
                    setSelectedCell({
                        rowId: rowId,
                        field: newCellFieldName !== null && newCellFieldName !== void 0 ? newCellFieldName : fieldName,
                    });
                }
            }
        }
        else if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(e.key)) {
            // setTimeout(() => {
            // forceUpdate();
            // }, 100);
        }
    }, [getAdjacentColumnFieldName, getHighlightedCell, gridApi, setSelectedCell]);
    const onClipboardCopy = React.useCallback((...args) => {
        console.log("clipboardCopy", { args });
    }, []);
    /**
     * Used to cancel the paste operation if the user is trying to paste multiple cells
     */
    const onBeforeClipboardPasteStart = React.useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        console.log("before paste", { data: params.data });
        if (params.data.length > 1 || params.data[0].length > 1) {
            throw new Error("Only pasting a single value is supported");
        }
    }), []);
    const onClipboardPasteStart = React.useCallback((...args) => {
        console.log("start paste", { args });
        const { data } = args[0];
        // only allow pasting a single cell
        if (data.length === 1 && data[0].length === 1) {
            const highlightedCell = getHighlightedCell();
            if (highlightedCell) {
                const row = highlightedCell.parentElement;
                const rowId = row === null || row === void 0 ? void 0 : row.dataset["id"];
                const item = rowsByUid[rowId !== null && rowId !== void 0 ? rowId : ""];
                const fieldUid = highlightedCell.dataset["field"];
                const field = fieldsByUid[fieldUid !== null && fieldUid !== void 0 ? fieldUid : ""];
                let value = data[0][0];
                if ((value === null || value === void 0 ? void 0 : value.trim()) === "") {
                    value = null;
                }
                if (field) {
                    const apiEndpoint = getPOSTApiEndpoint === null || getPOSTApiEndpoint === void 0 ? void 0 : getPOSTApiEndpoint(item);
                    if (!apiEndpoint) {
                        throw new Error("`getPostAPIEndpoint` is required for copy/paste to work");
                    }
                    if (!onFieldChanged) {
                        throw new Error("`onFieldChanged` is required");
                    }
                    if (!onFieldSaved) {
                        throw new Error("`onFieldSaved` is required");
                    }
                    const fieldType = field.field_type;
                    if (fieldType === "choice" && field.allows_multiple) {
                        value = value.split(",");
                    }
                    console.log({ rowId, fieldName: field.name, value });
                    onFieldChanged({
                        row: rowId,
                        field: field.name,
                        value,
                    });
                    axios
                        .post(apiEndpoint, { fields: { [field.name]: value } })
                        .then((result) => {
                        onFieldSaved({
                            row: item.uid,
                            field: field.name,
                            response: result,
                        });
                    })
                        .catch(enqueueErrorSnackbar);
                    args[2].api.stopCellEditMode({ id: rowId, field: field.name });
                }
            }
        }
    }, [fieldsByUid, getHighlightedCell, getPOSTApiEndpoint, onFieldChanged, onFieldSaved, rowsByUid]);
    const onClick = React.useCallback(() => {
        lastCharacterEntered.current = null;
    }, []);
    const onScroll = React.useCallback(() => {
        var _a, _b;
        // getScrollPosition will be undefined if grid isn't rendered
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (gridApi.current.getScrollPosition) {
            // console.log("SCROLL");
            const pos = gridApi.current.getScrollPosition().top;
            setVerticalScrollPosition(pos);
            if (groups && groups.length) {
                // 1px fudge factor
                const selectedGroup_ = (_b = (_a = groups.filter((x) => x.verticalOffset != null && x.verticalOffset <= pos + 1).reverse()[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
                setSelectedGroup(selectedGroup_);
            }
        }
    }, [gridApi, setVerticalScrollPosition, groups, setSelectedGroup]);
    const handleGroupClick = React.useCallback((groupValue) => {
        var _a;
        const offset = (_a = groups.find((g) => g.value === groupValue)) === null || _a === void 0 ? void 0 : _a.verticalOffset;
        setSelectedGroup(groupValue, () => {
            if (offset != null) {
                gridApi.current.scroll({ left: 0, top: offset });
            }
            onScroll();
        });
    }, [setSelectedGroup, groups, gridApi, onScroll]);
    const handleBulkDelete = React.useCallback(() => {
        confirmBulkDelete(selectedRows.size).then((result) => {
            if (result) {
                const rowsToDelete = [...selectedRows];
                setSelectedRows(new Set(), () => {
                    onBulkDelete(rowsToDelete);
                });
            }
        });
    }, [confirmBulkDelete, selectedRows, setSelectedRows, onBulkDelete]);
    const copyEmailAddresses = React.useCallback(() => {
        var _a;
        if (getMailMergeEmailAddressesForItem) {
            const emailAddresses = (_a = items === null || items === void 0 ? void 0 : items.filter((x) => selectedRows.has(x.uid)).map((x) => getMailMergeEmailAddressesForItem(x)).flat()) !== null && _a !== void 0 ? _a : [];
            if (emailAddresses.length > 0) {
                copyToClipboard(emailAddresses.join(", "));
                enqueueSnackbar(`Copied ${emailAddresses.length} email addresses to clipboard`, { variant: "success" });
            }
            else {
                enqueueSnackbar("No email addresses found for selected items", { variant: "warning" });
            }
        }
        else {
            enqueueSnackbar("This feature isn't working yet", { variant: "error" });
            console.error(`mail merge not implemented for ${entityType}`);
        }
    }, [entityType, getMailMergeEmailAddressesForItem, items, selectedRows]);
    React.useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("click", onClick);
        window.addEventListener("scroll", onScroll);
        window.addEventListener("wheel", onScroll);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("click", onClick);
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("wheel", onScroll);
        };
    }, [onClick, onKeyDown, onScroll]);
    const onClickImport = React.useCallback(() => {
        var _a;
        (_a = importFileInput.current) === null || _a === void 0 ? void 0 : _a.click();
    }, []);
    const handleImportFileSelected = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if ((_b = (_a = importFileInput.current) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.length) {
            const file = importFileInput.current.files[0];
            importFileInput.current.value = "";
            // if file size is greater than 25 MB, raise an error
            if (file.size > 25 * 1024 * 1024) {
                enqueueSnackbar("File must be less than 25 MB", { variant: "error" });
                return;
            }
            // convert file to ascii-encoded base 64 string
            const base64 = yield fileToBase64(file);
            // do the import
            onImportFileSelected(base64);
        }
    }), [onImportFileSelected]);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const download = React.useCallback(() => {
        setIsDownloading(true);
        downloadFunc({})
            .then((result) => {
            setIsDownloading(false);
            downloadBlob(result.data.content, downloadFilename !== null && downloadFilename !== void 0 ? downloadFilename : `${(entityType !== null && entityType !== void 0 ? entityType : "download").toLowerCase().replace(/[^A-Za-z0-9]/g, "_")}.csv`, "application/csv", "_blank");
        })
            .catch((err) => {
            setIsDownloading(false);
            errorDialog(err, { title: "Your download failed" });
        });
    }, [setIsDownloading, downloadFunc, downloadFilename, entityType, errorDialog]);
    const exportNewsfeed = React.useCallback(() => {
        console.log("export newsfeed");
        errorDialog("This feature isn't working yet", { title: "Sorry!" });
    }, [errorDialog]);
    const columnVisibilityModel = React.useMemo(() => {
        return columns.reduce((dict, col) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, dict), { [col.field]: (_b = (_a = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.find((c) => c.field_uid_or_column_name === col.field)) === null || _a === void 0 ? void 0 : _a.is_visible) !== null && _b !== void 0 ? _b : true }));
        }, {});
    }, [columns, columnArrangement]);
    const pinnedRows = selectedGroup && !isPaginated ? [rows.find((row) => row.id === `group_${selectedGroup}`)] : [];
    const pinnedColumns = React.useMemo(() => {
        const frozenColumns = columns
            .filter((col) => { var _a, _b; return (_b = (_a = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.find((c) => c.field_uid_or_column_name === col.field)) === null || _a === void 0 ? void 0 : _a.is_frozen) !== null && _b !== void 0 ? _b : false; })
            .map((col) => col.field);
        return frozenColumns;
    }, [columnArrangement, columns]);
    const isLoadingAttrs = fields === null || stages === null;
    const paginationComponent = rowsPerPage != null && count != null && page != null && (React.createElement(SpreadsheetPagination, { count: count, rowsPerPage: rowsPerPage, page: page, handlePageChange: handlePageChange, handleRowsPerPageChange: handleRowsPerPageChange, total: total !== null && total !== void 0 ? total : undefined }));
    const summaryComponent = !isPaginated && items && React.createElement(Summary, { items: items });
    const ret = (React.createElement(Grid2, { container: true, direction: "column", style: { height: "100%", width: "100%" }, wrap: "nowrap" },
        selectedRows.size === 0 && (React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
            React.createElement(Grid2, { style: {
                    marginTop: "auto",
                    marginBottom: "auto",
                } },
                React.createElement(Box, { p: 1 },
                    React.createElement(RouterLink, { to: location.pathname.split("?")[0], style: { textDecoration: "none" } },
                        React.createElement(Typography, { variant: "h6" }, title)))),
            React.createElement(Grid2, { style: {
                    maxWidth: "200px",
                    marginTop: "auto",
                    marginBottom: "auto",
                } },
                React.createElement(Box, { p: 1 },
                    React.createElement(SearchParamTextField, { searchParam: "search", TextFieldProps: {
                            size: "small",
                            InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(Search, null))),
                            },
                        } }))),
            (slots === null || slots === void 0 ? void 0 : slots.filters) && React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, slots.filters),
            React.createElement(Hidden, { smDown: true }, summaryComponent && React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, summaryComponent)),
            React.createElement(Grid2, { style: { flex: 1 } }),
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                    isLoadingAttrs && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(CircularProgress, { size: 20 }))),
                    error && !(error instanceof CanceledError) && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, String(error)) },
                            React.createElement(ErrorOutline, { color: "error" })))),
                    isDownloading && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Downloading, null))),
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Refresh (alt / option + R)") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", onClick: handleRefresh, tabIndex: -1, disabled: isLoading },
                                    React.createElement(Refresh, { fontSize: "small" }))))),
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Column Search") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", tabIndex: -1, onClick: handleColumnPanelOpened },
                                    React.createElement(ViewColumn, { fontSize: "small" })))),
                        columnPanelAnchorEl && (React.createElement(CorePopover, { open: true, anchorEl: columnPanelAnchorEl, onClose: handleColumnPanelClosed, slotProps: { paper: { sx: { overflow: "hidden" } } } },
                            React.createElement(ColumnPanel, { onSearchUpdated: handleColumnSearchChanged, searchStr: columnSearchStr, onMoveColumn: moveColumn, columns: columns, columnArrangement: columnArrangement !== null && columnArrangement !== void 0 ? columnArrangement : undefined, onSetColumnVisibility: setVisibilityForColumns })))),
                    entityType && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Saved View Editor") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", tabIndex: -1, onClick: handleSavedViewPanelOpened },
                                    React.createElement(FilterList, { fontSize: "small" })))),
                        savedViewPanelAnchorEl && (React.createElement(CorePopover, { open: true, anchorEl: savedViewPanelAnchorEl, onClose: handleSavedViewPanelClosed },
                            React.createElement(SavedViewPanel, { onViewSelected: openSavedViewEditor, entityType: entityType }))))),
                    React.createElement(Grid2, null,
                        React.createElement(Divider, { orientation: "vertical" })),
                    React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Actions") },
                            React.createElement("span", null,
                                React.createElement(IconButton, { size: "small", onClick: openActionsMenu, 
                                    // color="default"
                                    tabIndex: -1 },
                                    React.createElement(MoreVert, { fontSize: "small" })))),
                        React.createElement(CoreMenu, { anchorEl: actionsMenuAnchorEl, open: !!actionsMenuAnchorEl, onClose: closeActionsMenu, onClick: closeActionsMenu },
                            canEditStages && !!entityType && (React.createElement(MenuItem, { divider: true, onClick: openStagesDialog },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(LabelImportantOutlined, { fontSize: "small" })),
                                "Stages")),
                            onImportFileSelected && (React.createElement(MenuItem, { onClick: onClickImport },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Publish, { fontSize: "small" })),
                                "Import")),
                            downloadFunc && ((_k = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _k === void 0 ? void 0 : _k.hasRole(["Superadmin", "Admin"])) && (React.createElement(MenuItem, { onClick: download, disabled: isDownloading },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Download, { fontSize: "small" })),
                                "Export items")),
                            ((_l = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _l === void 0 ? void 0 : _l.hasRole(["Superadmin", "Admin"])) && (React.createElement(MenuItem, { onClick: exportNewsfeed },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Download, { fontSize: "small" })),
                                "Export newsfeed")),
                            React.createElement(Divider, null),
                            React.createElement(MenuItem, { onClick: toggleNewsfeed },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(MenuIcon, { fontSize: "small" })),
                                showNewsfeed ? "Hide newsfeed" : "Show newsfeed"),
                            canAccessDataValidation && !!entityType && (React.createElement(MenuItem, { onClick: toggleDataValidation },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(VerifiedUserOutlined, { fontSize: "small" })),
                                "Data Validation")),
                            additionalMenuItems && React.createElement(Divider, null),
                            ...additionalMenuItems !== null && additionalMenuItems !== void 0 ? additionalMenuItems : [],
                            React.createElement(Divider, null),
                            React.createElement(MenuItem, { onClick: copyLinkToCurrentPage },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(CopyAll, { fontSize: "small" })),
                                "Copy link to this page")),
                        React.createElement("input", { ref: importFileInput, type: "file", accept: ".csv", style: { display: "none" }, onChange: handleImportFileSelected })),
                    (slots === null || slots === void 0 ? void 0 : slots.addButton) && React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, slots.addButton),
                    React.createElement(Hidden, { smDown: true }, paginationComponent && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, null,
                            React.createElement(Divider, { orientation: "vertical" })),
                        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, paginationComponent)))))))),
        selectedRows.size > 0 && (React.createElement(Grid2, null,
            React.createElement(ActionsToolbar, { onClose: unselectAllRows, onBulkEditComplete: onBulkUpdateStages, onDeleteButtonClicked: onBulkDelete ? handleBulkDelete : undefined, onCopyEmailAddressesButtonClicked: copyEmailAddresses, selectedUids: selectedRows, selectedEntityTypes: selectedEntityTypes, entityType: entityType === "Seller Contract" ? "Listing" : entityType }))),
        React.createElement(Hidden, { smDown: true },
            React.createElement(Grid2, { style: { flex: 1, width: "100%", height: 0 } },
                React.createElement(Grid2, { container: true, style: { width: "100%", height: "100%" }, wrap: "nowrap" },
                    React.createElement(Grid2, { style: { flex: 1, height: "100%", width: 0 } },
                        React.createElement(Grid2, { container: true, direction: "column", style: { width: "100%", height: "100%" }, wrap: "nowrap" },
                            React.createElement(Grid2, null, groups.length > 0 && (React.createElement(GroupPanel, { groups: groups, height: Math.max(56 - verticalScrollPosition, 32), onClick: handleGroupClick }))),
                            (isSavedViewEditorOpen || !!view) && (React.createElement(SavedViewEditor, { entityType: entityType, onCancel: closeSavedViewEditor, uid: view, defaultValue: newViewDefaultValue !== null && newViewDefaultValue !== void 0 ? newViewDefaultValue : undefined })),
                            isLoading && items != null && (React.createElement(Grid2, { style: { position: "relative" } },
                                React.createElement(LinearProgress, { variant: loadingPercentComplete && loadingPercentComplete > 0 ? "determinate" : "indeterminate", value: (loadingPercentComplete !== null && loadingPercentComplete !== void 0 ? loadingPercentComplete : 0) * 100, style: { position: "absolute", height: "3px", width: "100%", zIndex: 100 } }))),
                            React.createElement(Grid2, { style: { flex: 1, height: 0 } },
                                React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
                                    React.createElement(Grid2, { container: true, direction: "column", style: { height: "100%", width: "100%" } },
                                        React.createElement(Grid2, { style: { flex: 1, height: "100%", width: "100%" } },
                                            React.createElement(ThemeProvider, { theme: gridTheme },
                                                React.createElement(ScrollTrackingDatagrid, { rows: rows, columns: columns, density: "compact", columnHeaderHeight: 30, getRowHeight: getRowHeight, hideFooter: true, checkboxSelection: true, disableRowSelectionOnClick: true, loading: false, pagination: false, paginationMode: count ? "server" : "client", rowCount: count !== null && count !== void 0 ? count : undefined, rowSelectionModel: [...selectedRows], showCellVerticalBorder: true, showColumnVerticalBorder: true, sortingMode: "server", 
                                                    // filterMode="server"
                                                    disableColumnSorting: true, sortModel: ordering
                                                        ? ordering.split(",").map((o) => ({
                                                            field: o.replace(/^-/g, ""),
                                                            sort: (o.startsWith("-") ? "desc" : "asc"),
                                                        }))
                                                        : undefined, slotProps: {
                                                        cell: {
                                                            // @ts-expect-error: is missing the following properties from type
                                                            onBlur: handleCellFocusOut,
                                                        },
                                                    }, onSortModelChange: handleSortModelChange, onRowSelectionModelChange: handleSelectionModelChange, disableColumnReorder: !canEditColumnOrder, onColumnOrderChange: reorderColumns, onCellKeyDown: handleCellKeyDown, onCellClick: handleCellClick, onCellEditStart: handleCellEditStart, onCellEditStop: handleCellEditStop, columnVisibilityModel: columnVisibilityModel, pinnedColumns: {
                                                        left: [
                                                            GRID_CHECKBOX_SELECTION_COL_DEF.field,
                                                            renderActionsColumn ? "_actions" : null,
                                                            "uid",
                                                            ...pinnedColumns,
                                                        ]
                                                            .filter((o) => o != null)
                                                            .map((o) => o),
                                                    }, pinnedRows: {
                                                        top: pinnedRows,
                                                    }, localeText: {
                                                        noRowsLabel: items == null ? "Loading..." : "No items found",
                                                        noResultsOverlayLabel: 
                                                        // eslint-disable-next-line no-nested-ternary
                                                        (items === null || items === void 0 ? void 0 : items.length) === 0
                                                            ? "No results found"
                                                            : // eslint-disable-next-line no-nested-ternary
                                                                error
                                                                    ? `An error occurred: ${String(error)}`
                                                                    : items == null
                                                                        ? "Loading..."
                                                                        : "Rendering...",
                                                    }, apiRef: gridApi, getRowClassName: getRowClassName, isRowSelectable: isRowSelectable, isCellEditable: isCellEditable, onColumnWidthChange: onColumnWidthChange, ignoreValueFormatterDuringExport: true, cellSelection // This is enabled to allow copying content in multiple cells. Pasting into multiple cells isn't supported yet.
                                                    : true, onBeforeClipboardPasteStart: onBeforeClipboardPasteStart, onClipboardPasteStart: onClipboardPasteStart, onClipboardCopy: onClipboardCopy, sx: {
                                                        border: "none",
                                                        "& .MuiDataGrid-main": {
                                                            border: "none",
                                                        },
                                                    } })))))))),
                    showNewsfeed && (React.createElement(Grid2, { style: { width: "250px", borderTop: "1px solid #ccc" } },
                        React.createElement(Box, { p: 1, style: { background: "#eee", height: "calc(100% - 0.5rem)" } },
                            entityType === "Buyer Lead" && React.createElement(BuyerLeadsNewsfeed, { onClose: toggleNewsfeed }),
                            entityType === "Seller Lead" && React.createElement(SellerLeadsNewsfeed, { onClose: toggleNewsfeed }),
                            entityType === "Seller Contract" && React.createElement(SellerContractsNewsfeed, { onClose: toggleNewsfeed }),
                            entityType === "Buyer Contract" && React.createElement(BuyerContractsNewsfeed, { onClose: toggleNewsfeed }),
                            entityType === "Recruit" && React.createElement(RecruitsNewsfeed, { onClose: toggleNewsfeed }),
                            entityType === "User" && React.createElement(UsersNewsfeed, { onClose: toggleNewsfeed }),
                            entityType === "Contact" && React.createElement(ContactsNewsfeed, { onClose: toggleNewsfeed }),
                            entityType == null && React.createElement(OverviewNewsfeed, { onClose: toggleNewsfeed })))),
                    showDataValidation && entityType && (React.createElement(Grid2, { style: { width: "600px", borderTop: "1px solid #ccc" } },
                        React.createElement(Box, { p: 1, pr: 0, style: { height: "calc(100% - 0.5rem)", paddingTop: "3px" } },
                            React.createElement(DataValidation, { entityType: entityType, onClose: toggleDataValidation }))))))),
        React.createElement(Hidden, { smUp: true }, items && items.length > 0 ? (React.createElement(React.Fragment, null,
            isLoading && (React.createElement(Grid2, { style: { height: 0 } },
                React.createElement(LinearProgress, { variant: "indeterminate" }))),
            React.createElement(Grid2, { style: { flex: 1, overflowY: "auto", width: "100%", overflowX: "hidden" } },
                React.createElement(List, { dense: true, style: { height: 0 } }, rows.map((row, i) => (React.createElement(React.Fragment, { key: row.id || i }, renderListItem(row)))))),
            React.createElement(Grid2, null,
                React.createElement(Box, { p: 1 }, paginationComponent)))) : (React.createElement(Grid2, { style: { flex: 1 } }, items ? React.createElement(Typography, null, "No items found") : React.createElement(CoreLoading, null))))));
    console.log({ columns });
    return (React.createElement(React.Fragment, null,
        ret,
        isStagesDialogOpen && (React.createElement(StagesDialog, { type: entityType, onSaveComplete: handleStagesSaved, onClose: handleStagesDialogClosed, DialogProps: { open: true } })),
        isThirdPartyDialogOpen && thirdPartyDialogParams && (React.createElement(ThirdPartyDialog, Object.assign({ DialogProps: { open: isThirdPartyDialogOpen }, onClose: onThirdPartyDialogClosed, onUpdate: handleRowThirdPartyChange }, thirdPartyDialogParams))),
        isClientsDialogOpen && clientsDialogParams && (React.createElement(AddOrEditClientsDialog, { DialogProps: { open: isClientsDialogOpen }, existingClients: clientsDialogParams.existingClients, onUpdateClient: handleRowClientsChange, onRemoveClient: handleRowRemoveClient, onReorderClients: handleRowReorderClients, onClose: onClientsDialogClosed })),
        isCollaboratorsDialogOpen && collaboratorsDialogParams && (React.createElement(CollaboratorDialog, { DialogProps: { open: isCollaboratorsDialogOpen }, existingCollaborators: collaboratorsDialogParams.existingCollaborators, onUpdate: handleRowCollaboratorsChange, onRemove: handleRowRemoveCollaborator, onClose: onCollaboratorsDialogClosed }))));
};
export default Spreadsheet;
