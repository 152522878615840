import * as React from "react";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DashCard from "@app/common/CoreCard";
import { arcGISURL } from "@app/util/Utils";
const ArcGISSidebar = (props) => {
    const onClose = React.useCallback(() => {
        props.closeSidebar(null);
    }, [props]);
    const { address } = props;
    return (React.createElement(DashCard, { heading: "ArcGIS", toolbar: React.createElement(IconButton, { onClick: onClose },
            React.createElement(Close, null)), padding: 0, elevation: 0, PaperProps: {
            style: {
                overflowX: "hidden",
                overflowY: "hidden",
            },
        } },
        React.createElement("iframe", { title: "map", src: address ? arcGISURL + address : "", style: { width: "100%", height: "calc(100vh - 125px)" } })));
};
export default ArcGISSidebar;
