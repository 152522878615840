import * as React from "react";
import { Avatar, Button, Checkbox, Chip, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid2, MenuItem, Typography, } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { useNavigate } from "react-router";
import styled from "styled-components";
import User from "@app/classes/user";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDialog from "@app/common/CoreDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useDuplicatePsp, useSharePsp } from "@app/orval/api/psps";
import { useListUsers } from "@app/orval/api/users";
const StyledChip = styled(Chip) `
    & .MuiChip-label {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
`;
const getCurrentSharing = (matrix) => {
    const { sharing } = matrix;
    const { anyone_with_the_link } = sharing;
    const users = sharing.users.map((user) => user.uid);
    return {
        anyone_with_the_link,
        users,
    };
};
/**
 * Widget that allows users to share -- or duplicate -- a MAtrix.
 */
const MAtrixShareDialog = (props) => {
    var _a, _b;
    const { dialogProps, matrix, onCancel, onSaveComplete, shouldDuplicate } = props;
    const [sharing, setSharing] = React.useState(getCurrentSharing(matrix));
    const [isSaving, setIsSaving] = useStateWithCallback(false);
    const navigate = useNavigate();
    const errorDialog = useErrorDialog();
    const listUsersApi = useListUsers();
    const usersUnsorted = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const duplicatePSPApi = useDuplicatePsp();
    const sharePSPApi = useSharePsp();
    const users = React.useMemo(() => {
        return usersUnsorted === null || usersUnsorted === void 0 ? void 0 : usersUnsorted.sort((a, b) => {
            var _a, _b, _c, _d;
            const aFirst = (_a = a.first_name) !== null && _a !== void 0 ? _a : "";
            const aLast = (_b = a.last_name) !== null && _b !== void 0 ? _b : "";
            const bFirst = (_c = b.first_name) !== null && _c !== void 0 ? _c : "";
            const bLast = (_d = b.last_name) !== null && _d !== void 0 ? _d : "";
            if (aFirst < bFirst)
                return -1;
            if (aFirst > bFirst)
                return 1;
            if (aLast < bLast)
                return -1;
            if (aLast > bLast)
                return 1;
            return 0;
        });
    }, [usersUnsorted]);
    const isValid = React.useMemo(() => {
        return !!users;
    }, [users]);
    const save = React.useCallback(() => {
        setIsSaving(true, () => {
            if (shouldDuplicate) {
                const loadingSnackbar = enqueueSnackbar("Duplicating MAtrix...", { variant: "info", persist: true });
                duplicatePSPApi
                    .mutateAsync({
                    uid: matrix.uid,
                    data: {
                        sharing: {
                            anyone_with_the_link: sharing.anyone_with_the_link,
                            user_uids: sharing.users,
                        },
                    },
                })
                    .then((result) => {
                    navigate(`/matrix/${result.data.uid}`);
                    onSaveComplete(result.data);
                })
                    .catch((error) => {
                    errorDialog(error);
                })
                    .then(() => {
                    setIsSaving(false);
                    closeSnackbar(loadingSnackbar);
                });
            }
            else {
                const loadingSnackbar = enqueueSnackbar("Updating sharing settings...", {
                    variant: "info",
                    persist: true,
                });
                sharePSPApi
                    .mutateAsync({
                    uid: matrix.uid,
                    data: {
                        anyone_with_the_link: sharing.anyone_with_the_link,
                        user_uids: sharing.users,
                    },
                })
                    .then((result) => {
                    onSaveComplete(result.data);
                })
                    .catch((error) => {
                    errorDialog(error);
                })
                    .then(() => {
                    setIsSaving(false);
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [
        duplicatePSPApi,
        errorDialog,
        matrix.uid,
        navigate,
        onSaveComplete,
        setIsSaving,
        sharePSPApi,
        sharing.anyone_with_the_link,
        sharing.users,
        shouldDuplicate,
    ]);
    const setAnyoneWithTheLink = React.useCallback((_, checked) => {
        setSharing((prev) => {
            return Object.assign(Object.assign({}, prev), { anyone_with_the_link: checked });
        });
    }, []);
    const setUsers = React.useCallback((choices) => {
        setSharing((prev) => {
            return Object.assign(Object.assign({}, prev), { users: choices });
        });
    }, []);
    const renderValue = React.useCallback((selected) => {
        return (React.createElement("div", { style: {
                display: "flex",
                flexWrap: "wrap",
            } }, selected.map((value) => {
            var _a;
            const user = (users !== null && users !== void 0 ? users : []).find((obj) => obj.uid === value);
            if (user) {
                const userObj = new User(user);
                return (React.createElement(StyledChip, { key: value, label: React.createElement(Grid2, { container: true, spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(Avatar, { src: (_a = userObj.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "18px", width: "18px", fontSize: "0.6rem" } }, userObj.initials)),
                        React.createElement(Grid2, { style: {
                                flexGrow: 1,
                                marginTop: "auto",
                                marginBottom: "auto",
                                fontSize: "0.75rem",
                            } }, userObj.name)), style: { margin: 2, height: "unset" } }));
            }
            return null;
        })));
    }, [users]);
    console.log("sharing:", sharing);
    console.log("users:", users);
    return (React.createElement(CoreDialog, Object.assign({}, dialogProps, { maxWidth: "sm", fullWidth: true }),
        React.createElement(DialogTitle, null, shouldDuplicate ? "Duplicate MAtrix - Sharing Settings" : "Sharing"),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { spacing: 4, style: { padding: "8px" } },
                React.createElement(Grid2, { size: 12 },
                    React.createElement(FormControlLabel, { label: "Any logged-in user with the link has access", control: React.createElement(Checkbox, { color: "primary", size: "small", checked: sharing.anyone_with_the_link, onChange: setAnyoneWithTheLink }) })),
                users && (React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { variant: "body1" }, "Share with specific agents")),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(CoreChoiceField, { variant: "standard", label: "Agents", fullWidth: true, multiple: true, value: sharing.users, onChoicesChange: setUsers, renderValue: renderValue }, users.map((obj) => {
                            var _a;
                            const user = new User(obj);
                            return (React.createElement(MenuItem, { key: user.uid, value: user.uid },
                                React.createElement(Grid2, { container: true, spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { width: "24px", height: "24px", fontSize: "0.7rem" } }, user.initials)),
                                    React.createElement(Grid2, { style: { flexGrow: 1, marginTop: "auto", marginBottom: "auto" } }, user.name))));
                        }))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "inherit", onClick: onCancel }, "Cancel"),
            React.createElement(Button, { onClick: save, color: "primary", disabled: !isValid || !!isSaving }, "Save"))));
};
export default MAtrixShareDialog;
