import * as React from "react";
import { Person } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
const ContactSearchResult = (props) => {
    const { contact } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemIcon, null,
            React.createElement(Person, { fontSize: "large" })),
        React.createElement(ListItemText, { primary: contact.name, secondary: contact.fields["Vendor Type"] })));
};
export default ContactSearchResult;
