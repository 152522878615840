import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AlarmOn, Backspace, Block, Close, DateRange, Done, ErrorOutline, KeyboardArrowLeft, Launch, Remove, Schedule, } from "@mui/icons-material";
import { IconButton, ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import DashPopover from "@app/common/CoreMoreMenu";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { sanitize } from "@app/entrypoints/brokerage/features/mailschedule4/utils/utils";
import useLoading from "@app/hooks/useLoading";
import { useListFields } from "@app/orval/api/field-definitions";
import { useGetMailschedule4TemplateStatus, useSetMailschedule4TemplateStatus, } from "@app/orval/api/mail-schedule-status";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
import StatusTooltip from "./StatusTooltip";
import useListEntitiesApi from "../run/useListEntitiesApi";
import { renderNameCell, renderTypeCell } from "../utils/table-cells";
const MailScheduleBoxesTable = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const { backLink, entity } = props;
    const queryClient = useQueryClient();
    const getStatusesApi = useGetMailschedule4TemplateStatus({
        entity_type: entity.entityType,
        entity_uid: entity.uid,
    }, {
        query: {
            staleTime: 0,
            gcTime: 5 * 60 * 1000,
        },
    });
    const statuses = (_b = (_a = getStatusesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const setStatusesApi = useSetMailschedule4TemplateStatus();
    const listTemplatesApi = useListMailschedule4Templates({ entity_type: entity.entityType }, REFETCH_CACHE_PARAMS);
    const templates = (_d = (_c = listTemplatesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listFieldsApi = useListFields({}, INFINITE_CACHE_PARAMS);
    const fields = (_f = (_e = listFieldsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listEntityApi = useListEntitiesApi([{ entity_type: entity.entityType, uid: entity.uid }]);
    const box = (_t = (_r = (_p = (_m = (_k = (_h = (_g = listEntityApi.buyerContracts) === null || _g === void 0 ? void 0 : _g[0]) !== null && _h !== void 0 ? _h : (_j = listEntityApi.buyerLeads) === null || _j === void 0 ? void 0 : _j[0]) !== null && _k !== void 0 ? _k : (_l = listEntityApi.sellerContracts) === null || _l === void 0 ? void 0 : _l[0]) !== null && _m !== void 0 ? _m : (_o = listEntityApi.sellerLeads) === null || _o === void 0 ? void 0 : _o[0]) !== null && _p !== void 0 ? _p : (_q = listEntityApi.recruits) === null || _q === void 0 ? void 0 : _q[0]) !== null && _r !== void 0 ? _r : (_s = listEntityApi.users) === null || _s === void 0 ? void 0 : _s[0]) !== null && _t !== void 0 ? _t : null;
    const boxEntityType = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        if ((_a = listEntityApi.buyerContracts) === null || _a === void 0 ? void 0 : _a[0]) {
            return "Buyer Contract";
        }
        if ((_b = listEntityApi.buyerLeads) === null || _b === void 0 ? void 0 : _b[0]) {
            return "Buyer Lead";
        }
        if ((_c = listEntityApi.sellerContracts) === null || _c === void 0 ? void 0 : _c[0]) {
            return "Listing";
        }
        if ((_d = listEntityApi.sellerLeads) === null || _d === void 0 ? void 0 : _d[0]) {
            return "Seller Lead";
        }
        if ((_e = listEntityApi.recruits) === null || _e === void 0 ? void 0 : _e[0]) {
            return "Recruit";
        }
        if ((_f = listEntityApi.users) === null || _f === void 0 ? void 0 : _f[0]) {
            return "User";
        }
        return null;
    }, [listEntityApi]);
    const templatesForBox = React.useMemo(() => (templates !== null && templates !== void 0 ? templates : [])
        .filter((template) => template.entity_type === boxEntityType)
        .map((template, index) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return (Object.assign(Object.assign({}, template), { recipients: {
                Calendar: (_a = template.calendar_template) === null || _a === void 0 ? void 0 : _a.guests,
                Email: [(_b = template.email_template) === null || _b === void 0 ? void 0 : _b.to, (_c = template.email_template) === null || _c === void 0 ? void 0 : _c.cc, (_d = template.email_template) === null || _d === void 0 ? void 0 : _d.bcc]
                    .filter((x) => x)
                    .join(", "),
                "Email Draft": [
                    (_e = template.email_draft_template) === null || _e === void 0 ? void 0 : _e.to,
                    (_f = template.email_draft_template) === null || _f === void 0 ? void 0 : _f.cc,
                    (_g = template.email_draft_template) === null || _g === void 0 ? void 0 : _g.bcc,
                ]
                    .filter((x) => x)
                    .join(", "),
                SMS: (_h = template.sms_template) === null || _h === void 0 ? void 0 : _h.to_phone,
                "SMS Draft": (_j = template.sms_draft_template) === null || _j === void 0 ? void 0 : _j.to_phone,
                Slack: (_k = template.slack_template) === null || _k === void 0 ? void 0 : _k.channel_id,
                "Streak Comment": "",
            }[template.template_type], subject: {
                Calendar: (_l = template.calendar_template) === null || _l === void 0 ? void 0 : _l.title,
                Email: (_m = template.email_template) === null || _m === void 0 ? void 0 : _m.subject,
                "Email Draft": (_o = template.email_draft_template) === null || _o === void 0 ? void 0 : _o.subject,
                SMS: "",
                "SMS Draft": "",
                Slack: "",
                "Streak Comment": "",
            }[template.template_type], number: (templates !== null && templates !== void 0 ? templates : []).filter((_, index_) => index_ <= index).length }));
    })
        .filter((template) => template.is_active)
        .map((template) => {
        var _a, _b, _c;
        const status = statuses === null || statuses === void 0 ? void 0 : statuses.find((s) => s.entity.uid === entity.uid && s.template === template.uid);
        let t = Object.assign(Object.assign({}, template), { status: (((_a = status === null || status === void 0 ? void 0 : status.criteria) !== null && _a !== void 0 ? _a : []).find((criterion) => criterion.filters.some((f) => f.error))
                ? "error"
                : status === null || status === void 0 ? void 0 : status.status), criteria: status === null || status === void 0 ? void 0 : status.criteria, num_times_processed: status === null || status === void 0 ? void 0 : status.history.filter((item) => item.status === "processed").length, last_sent: ((_b = status === null || status === void 0 ? void 0 : status.history) !== null && _b !== void 0 ? _b : []).filter((item) => item.status === "processed").length > 0
                ? [...((_c = status === null || status === void 0 ? void 0 : status.history) !== null && _c !== void 0 ? _c : [])].sort((a, b) => {
                    if (a.timestamp > b.timestamp)
                        return -1;
                    if (a.timestamp < b.timestamp)
                        return 1;
                    return 0;
                })[0].timestamp
                : null });
        t = sanitize(t, fields !== null && fields !== void 0 ? fields : [], box);
        return t;
    }), [box, boxEntityType, entity.uid, fields, statuses, templates]);
    const updateTemplateStatus = React.useCallback((templateUid, status) => () => {
        const loadingSnackbar = enqueueSnackbar("Saving template...", { variant: "info", persist: true });
        setStatusesApi
            .mutateAsync({
            data: {
                entity: {
                    entity_type: entity.entityType,
                    uid: entity.uid,
                },
                template_uid: templateUid,
                status: status,
            },
        })
            .then(() => {
            var _a, _b;
            const template = templates === null || templates === void 0 ? void 0 : templates.find((t) => t.uid === templateUid);
            enqueueSnackbar(`Updated status for template ${(_a = template === null || template === void 0 ? void 0 : template.name) !== null && _a !== void 0 ? _a : templateUid} for box ${(_b = box === null || box === void 0 ? void 0 : box.name) !== null && _b !== void 0 ? _b : box === null || box === void 0 ? void 0 : box.uid}`, {
                variant: "success",
            });
            queryClient.setQueryData(getStatusesApi.queryKey, (old) => {
                if (!old)
                    return old;
                return Object.assign(Object.assign({}, old), { data: old.data.map((status_) => {
                        if (status_.template === templateUid) {
                            return Object.assign(Object.assign({}, status_), { status: status });
                        }
                        return status_;
                    }) });
            });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(loadingSnackbar);
        });
    }, [
        box === null || box === void 0 ? void 0 : box.name,
        box === null || box === void 0 ? void 0 : box.uid,
        entity.entityType,
        entity.uid,
        getStatusesApi.queryKey,
        queryClient,
        setStatusesApi,
        templates,
    ]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading statuses...",
                status: getStatusesApi.status,
            },
            {
                label: "Loading templates...",
                status: listTemplatesApi.status,
            },
        ],
    });
    if (isLoading) {
        return loadingComponent;
    }
    console.log({ templates, templatesForBox, boxEntityType, box, entity });
    const columns = [
        {
            name: "uid",
            label: " ",
            options: {
                searchable: false,
                sort: false,
                download: false,
                filter: false,
                print: false,
                viewColumns: false,
                customBodyRender: (value, tableMeta) => {
                    const template = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    return (React.createElement(DashPopover, null,
                        React.createElement(MenuItem, { component: RouterLink, to: `/ms4/preview?entity_type=${entity.entityType}&uid=${entity.uid}&template=${template.uid}` },
                            React.createElement(ListItemIcon, null,
                                React.createElement(Launch, { fontSize: "small" })),
                            "Open preview"),
                        template.status !== "pending" && (React.createElement(MenuItem, { onClick: updateTemplateStatus(value, "pending") },
                            React.createElement(ListItemIcon, null,
                                React.createElement(Schedule, { fontSize: "small" })),
                            template.status === "cancelled" ? "Need to Process" : "Need to Re-process")),
                        template.status !== "processed" && (React.createElement(MenuItem, { onClick: updateTemplateStatus(value, "processed") },
                            React.createElement(ListItemIcon, null,
                                React.createElement(Done, { fontSize: "small" })),
                            "Mark as Processed")),
                        template.status !== "cancelled" && (React.createElement(MenuItem, { onClick: updateTemplateStatus(value, "cancelled") },
                            React.createElement(ListItemIcon, null,
                                React.createElement(Backspace, { fontSize: "small" })),
                            "Mark as Removed"))));
                },
            },
        },
        {
            name: "number",
            label: "#",
            options: {
                viewColumns: false,
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                viewColumns: false,
                customBodyRender: renderNameCell(templatesForBox),
            },
        },
        {
            name: "type",
            label: "Type",
            options: {
                customBodyRender: renderTypeCell(templatesForBox),
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta) => {
                    var _a;
                    const template = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    const { criteria } = template;
                    const { triggers } = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index];
                    if (value === "processed") {
                        return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Processed", triggers: triggers, criteria: criteria }) },
                            React.createElement(Done, { color: "primary" })));
                    }
                    if (value === "cancelled") {
                        return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Cancelled", triggers: triggers, criteria: criteria }) },
                            React.createElement(Block, { color: "secondary" })));
                    }
                    if (value === "error") {
                        return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Error", triggers: triggers, criteria: criteria }) },
                            React.createElement(ErrorOutline, { color: "secondary" })));
                    }
                    if (value === "inactive") {
                        return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "This template is inactive", triggers: triggers, criteria: criteria }) },
                            React.createElement(Remove, null)));
                    }
                    if ((_a = (criteria !== null && criteria !== void 0 ? criteria : []).find((c) => c.filter_group.parent_uid == null)) === null || _a === void 0 ? void 0 : _a.passes) {
                        return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Ready to process", triggers: triggers, criteria: criteria }) },
                            React.createElement(AlarmOn, null)));
                    }
                    if (value === "pending") {
                        return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Pending", triggers: triggers, criteria: criteria }) },
                            React.createElement(DateRange, null)));
                    }
                    return value;
                },
            },
        },
        {
            name: "subject",
            label: "Subject or Title",
            options: {
                customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: {
                        __html: (value !== null && value !== void 0 ? value : "").replace(/(\$\'.*?\')/g, `<font color='${AppConfig.themeColors.primary}'>$1</font>`),
                    } })),
            },
        },
        {
            name: "recipients",
            label: "Recipients or Guests",
            options: {
                customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: {
                        __html: (value !== null && value !== void 0 ? value : "").replace(/(\$\'.*?\')/g, `<font color='${AppConfig.themeColors.primary}'>$1</font>`),
                    } })),
            },
        },
        {
            name: "num_times_processed",
            label: "# Times Processed",
            options: {
                display: false,
                searchable: false,
                filter: false,
                customBodyRender: (value) => {
                    if (!value) {
                        return null;
                    }
                    return value;
                },
            },
        },
        {
            name: "last_sent",
            label: "Last Sent",
            options: {
                customBodyRender: (value) => {
                    if (value && dayjs_(value).isValid()) {
                        return dayjs_(value).format("ddd, MMM D, YYYY h:mm A");
                    }
                    return value;
                },
            },
        },
    ].filter((obj) => obj);
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        search: false,
        searchOpen: true,
        viewColumns: true,
        pagination: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        expandableRows: false,
        customToolbar: () => backLink && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Close") },
            React.createElement(IconButton, { component: RouterLink, to: backLink },
                React.createElement(Close, null)))),
    };
    return (React.createElement(CoreDataTable, { title: backLink ? (React.createElement(IconButton, { component: RouterLink, to: backLink },
            React.createElement(KeyboardArrowLeft, null))) : (""), data: templatesForBox, columns: columns, options: options, cellPadding: 0 }));
};
export default MailScheduleBoxesTable;
