import * as React from "react";
import { Cancel, Money } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useGetAvailablePhoneNumber } from "@app/orval/api/twilio-phone-numbers";
import { usePurchaseTwilioPhoneForUser } from "@app/orval/api/users";
const PurchaseTwilioPhoneDialog = (props) => {
    var _a;
    const { DialogProps, onCancel, onPurchaseComplete, user } = props;
    const getAvailablePhoneNumberApi = useGetAvailablePhoneNumber({ query: { enabled: false } });
    const purchasePhoneNumberApi = usePurchaseTwilioPhoneForUser();
    const [phoneNumber, setPhoneNumber] = React.useState(null);
    const cancel = React.useCallback(() => {
        onCancel();
    }, [onCancel]);
    const getAvailablePhoneNumber_ = React.useCallback(() => {
        setPhoneNumber(null);
        getAvailablePhoneNumberApi
            .refetch()
            .then((result) => {
            setPhoneNumber(result.data.data);
        })
            .catch(enqueueErrorSnackbar);
    }, [getAvailablePhoneNumberApi]);
    const onRefreshPhoneNumberClicked = React.useCallback(() => {
        getAvailablePhoneNumber_();
    }, [getAvailablePhoneNumber_]);
    React.useEffect(() => {
        getAvailablePhoneNumber_();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const purchasePhoneNumber = React.useCallback(() => {
        purchasePhoneNumberApi
            .mutateAsync({
            profileUid: user.uid,
            data: {
                phone_number: phoneNumber.phone_number,
            },
        })
            .then((result) => {
            onPurchaseComplete(result.data);
        })
            .catch(enqueueErrorSnackbar);
    }, [onPurchaseComplete, phoneNumber, purchasePhoneNumberApi, user.uid]);
    return (React.createElement(Dialog, Object.assign({}, DialogProps, { open: (_a = DialogProps.open) !== null && _a !== void 0 ? _a : true }),
        React.createElement(DialogTitle, null,
            "Purchase Twilio Phone for ",
            user.name),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, null,
                        "Click \"Purchase\" to purchase the following Twilio phone number for ",
                        user.name,
                        ".")),
                phoneNumber && (React.createElement(Grid, { item: true },
                    React.createElement(Typography, null, phoneNumber.phone_number))),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { variant: "outlined", color: "primary", onClick: onRefreshPhoneNumberClicked, disabled: getAvailablePhoneNumberApi.isFetching }, "I don't like this number; give me another one.")))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "inherit", startIcon: React.createElement(Cancel, null), onClick: cancel }, "Cancel"),
            React.createElement(Button, { color: "primary", onClick: purchasePhoneNumber, startIcon: React.createElement(Money, null), disabled: !phoneNumber || purchasePhoneNumberApi.isPending }, "Purchase"))));
};
export default PurchaseTwilioPhoneDialog;
