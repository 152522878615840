import * as React from "react";
import { Help } from "@mui/icons-material";
import { Grid, InputAdornment, Tooltip, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import { NC_ONLY_FIELDS, SC_ONLY_FIELDS } from "./utils";
const FormField = (props) => {
    var _a, _b, _c;
    const { formField, onFieldSaveComplete, onSaveBegin, override, schema, submission } = props;
    const onSuccess = React.useCallback(() => {
        onFieldSaveComplete(formField.name);
    }, [formField.name, onFieldSaveComplete]);
    const field = schema[formField.name];
    if (!field) {
        console.error(`No field named ${formField.name}`);
        return null;
    }
    if (submission.fields["State"] !== "North Carolina" && NC_ONLY_FIELDS.includes(field.name)) {
        return null;
    }
    if (submission.fields["State"] !== "South Carolina" && SC_ONLY_FIELDS.includes(field.name)) {
        return null;
    }
    if (field.name === "Personal Property (Other)" && !override) {
        if (((_a = submission.fields["Personal Property"]) !== null && _a !== void 0 ? _a : []).includes("Other")) {
            return (React.createElement(FormField, { key: formField.name, label: "Please specify", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "How do you intend to pay the Due Diligence Fee? (Other)" && !override) {
        if (submission.fields["How do you intend to pay the Due Diligence Fee?"] === "Other") {
            return (React.createElement(FormField, { key: formField.name, label: "Please specify", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "How do you intend to pay the Termination Fee? (Other)" && !override) {
        if (submission.fields["How do you intend to pay the Termination Fee?"] === "Other") {
            return (React.createElement(FormField, { key: formField.name, label: "Please specify", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "How do you intend to pay the Earnest Money Deposit? (Other)" && !override) {
        if (submission.fields["How do you intend to pay the Earnest Money Deposit?"] === "Other") {
            return (React.createElement(FormField, { key: formField.name, label: "Please specify", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "Have to Sell - Property Address" && !override) {
        if (submission.fields["Do you have to sell or lease a property in order to qualify?"] === "Yes") {
            return (React.createElement(FormField, { key: formField.name, label: "Property address", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "Have to Sell - Under Contract" && !override) {
        if (submission.fields["Do you have to sell or lease a property in order to qualify?"] === "Yes") {
            return (React.createElement(FormField, { key: formField.name, label: "Is it already under contract?", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "Have to Sell - Currently Listed" && !override) {
        if (submission.fields["Do you have to sell or lease a property in order to qualify?"] === "Yes") {
            return (React.createElement(FormField, { key: formField.name, label: "If not, is it currently listed with an agent, will be listed with an agent, or trying to sell FSBO?", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    if (field.name === "What is the name of the Closing Attorney that you would prefer to use?" && !override) {
        // eslint-disable-next-line prettier/prettier
        if (submission.fields["Closing Attorney Preference"] === "Yes") {
            return (React.createElement(FormField, { key: formField.name, label: "Please Specify", formField: formField, submission: submission, schema: schema, onSaveBegin: onSaveBegin, onFieldSaveComplete: onFieldSaveComplete, override: true }));
        }
        return null;
    }
    const { helperText } = formField;
    return (React.createElement(Grid, { key: field.name, item: true, xs: (_b = formField.xs) !== null && _b !== void 0 ? _b : 12, sm: formField.sm, md: formField.md, lg: formField.lg, xl: formField.xl },
        React.createElement(DashAutoSaveField, { variant: "standard", size: "small", fullWidth: true, label: ((_c = props.label) !== null && _c !== void 0 ? _c : field.name) + (formField.required ? "*" : ""), field: schema[field.name], value: submission.fields[field.name], onSaveBegin: onSaveBegin(field.name), onSuccess: onSuccess, helperText: helperText, useCheckboxes: !(schema[field.name].field_type === "choice" && schema[field.name].allows_multiple) ? undefined : true, apiEndpoint: `/api/forms/questions_for_the_offer/${submission.uid}`, InputProps: field.help_text
                ? {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(Tooltip, { title: React.createElement(Typography, null,
                                React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(field.help_text) } })) },
                            React.createElement(Help, { fontSize: "small" })))),
                }
                : undefined })));
};
FormField.defaultProps = {
    label: null,
    override: false,
};
export default FormField;
