import * as React from "react";
import { AutoAwesome } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useSession from "@app/hooks/useSession";
import { useAddView } from "@app/orval/api/views";
import { getViewUrl } from "../../crm/views/utils";
const CreateViewForFiltersButton = (props) => {
    const { entityType, filters, name } = props;
    const createViewApi = useAddView();
    const navigate = useNavigate();
    const session = useSession();
    const isCreatingView = createViewApi.isPending;
    const createView = React.useCallback(() => {
        createViewApi
            .mutateAsync({
            data: {
                name: `${name !== null && name !== void 0 ? name : "New View"} (created from Mail Schedule)`,
                purpose: null,
                entity_type: entityType,
                filter_groups: filters,
                grouping: null,
                sorting: [],
                sharing: {
                    all_users: {
                        is_editable: false,
                        is_pinned: false,
                        is_shared: false,
                    },
                    offices: [],
                    roles: [],
                    users: [
                        {
                            user: session.viewingAsUser.uid,
                            is_editable: true,
                            is_pinned: false,
                            is_shared: true,
                        },
                    ],
                },
            },
        })
            .then((result) => {
            navigate(getViewUrl({ uid: result.data.uid, entity_type: entityType }));
        })
            .catch(enqueueErrorSnackbar);
    }, [createViewApi, entityType, filters, name, navigate, session]);
    return (React.createElement(Button, { size: "small", startIcon: React.createElement(AutoAwesome, null), onClick: createView, disabled: isCreatingView, variant: "outlined" }, "Create a view with these filters"));
};
export default CreateViewForFiltersButton;
