import * as React from "react";
import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import useSession from "@app/hooks/useSession";
import { useListOffices } from "@app/orval/api/offices";
import { useListRoles } from "@app/orval/api/user-roles";
import { searchUsersBasic } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import SavedViewShareSelector from "./SavedViewShareSelector";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewSharePanel = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const session = useSession();
    const searchUsersApi = usePostQuery(searchUsersBasic, { limit: 10000, disable_sort: true, exclude_terminated: true }, INFINITE_CACHE_PARAMS);
    const users = (_b = (_a = searchUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listOfficesApi = useListOffices(INFINITE_CACHE_PARAMS);
    const offices = (_d = (_c = listOfficesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listRolesApi = useListRoles(INFINITE_CACHE_PARAMS);
    const roles = (_f = (_e = listRolesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const context = useSavedViewEditorContext();
    const { setView } = context;
    const { sharing } = context.view;
    const setSharingForAllUsers = React.useCallback((value) => {
        setView((prev) => {
            return Object.assign(Object.assign({}, prev), { sharing: Object.assign(Object.assign({}, prev.sharing), { all_users: value }) });
        });
    }, [setView]);
    const setSharingForRole = React.useCallback((roleName) => (value) => {
        setView((prev) => {
            var _a;
            return Object.assign(Object.assign({}, prev), { sharing: Object.assign(Object.assign({}, prev.sharing), { roles: [
                        ...((_a = prev.sharing.roles) !== null && _a !== void 0 ? _a : []).filter((x) => x.role !== roleName),
                        Object.assign({ role: roleName }, value),
                    ] }) });
        });
    }, [setView]);
    const setSharingForOffice = React.useCallback((officeUid) => (value) => {
        setView((prev) => {
            var _a;
            return Object.assign(Object.assign({}, prev), { sharing: Object.assign(Object.assign({}, prev.sharing), { offices: [
                        ...((_a = prev.sharing.offices) !== null && _a !== void 0 ? _a : []).filter((x) => x.office !== officeUid),
                        Object.assign({ office: officeUid }, value),
                    ] }) });
        });
    }, [setView]);
    const setSharingForUser = React.useCallback((userUid) => (value) => {
        setView((prev) => {
            var _a;
            return Object.assign(Object.assign({}, prev), { sharing: Object.assign(Object.assign({}, prev.sharing), { users: [
                        ...((_a = prev.sharing.users) !== null && _a !== void 0 ? _a : []).filter((x) => x.user !== userUid),
                        Object.assign({ user: userUid }, value),
                    ] }) });
        });
    }, [setView]);
    return (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Typography, { style: { fontWeight: "bold" } }, "Share this view")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, "General permissions or all users:")),
                    React.createElement(Grid, { item: true },
                        React.createElement(SavedViewShareSelector, { value: sharing.all_users, onChange: setSharingForAllUsers }))))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Alert, { severity: "info" },
                React.createElement(Typography, { variant: "body2", dangerouslySetInnerHTML: {
                        __html: "If <i>any</i> of the following are true, the user will have access to the view. Superadmins and Admins will always be able to see and edit all views.",
                    } }))),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                    ((_g = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.uid) && (React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(SavedViewShareSelector, { value: (_j = (_h = sharing.users) === null || _h === void 0 ? void 0 : _h.find((x) => x.user === session.viewingAsUser.uid)) !== null && _j !== void 0 ? _j : {
                                            is_pinned: false,
                                            is_shared: false,
                                            is_editable: false,
                                        }, disablePinned: !!sharing.all_users.is_pinned, disableEditable: !!sharing.all_users.is_editable, disableShared: !!sharing.all_users.is_pinned || !!sharing.all_users.is_shared, onChange: setSharingForUser(session.viewingAsUser.uid), TooltipProps: { enterDelay: 1000, enterNextDelay: 5000 } })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "body2" }, "Me")))))),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid, { item: true, container: true, wrap: "nowrap", spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, null, "Roles")),
                            !roles && (React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, "Loading..."))),
                            roles && (React.createElement(Grid, { item: true },
                                React.createElement(Button, { size: "small", variant: "outlined" }, "Clear all")))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, container: true, spacing: 1 }, (roles !== null && roles !== void 0 ? roles : []).map((role) => {
                            var _a, _b;
                            return (React.createElement(Grid, { key: role.name, item: true, container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(SavedViewShareSelector, { value: (_b = (_a = sharing.roles) === null || _a === void 0 ? void 0 : _a.find((x) => x.role === role.name)) !== null && _b !== void 0 ? _b : {
                                            is_pinned: false,
                                            is_shared: false,
                                            is_editable: false,
                                        }, disablePinned: !!sharing.all_users.is_pinned, disableEditable: !!sharing.all_users.is_editable, disableShared: !!sharing.all_users.is_pinned || !!sharing.all_users.is_shared, onChange: setSharingForRole(role.name), TooltipProps: { enterDelay: 1000, enterNextDelay: 5000 } })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "body2" }, role.name))));
                        }))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid, { item: true, container: true, wrap: "nowrap", spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, null, "Offices")),
                            !offices && (React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, "Loading..."))),
                            offices && (React.createElement(Grid, { item: true },
                                React.createElement(Button, { size: "small", variant: "outlined" }, "Clear all")))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, container: true, spacing: 0 }, (offices !== null && offices !== void 0 ? offices : []).map((office) => {
                            var _a, _b;
                            return (React.createElement(Grid, { key: office.uid, item: true, container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(SavedViewShareSelector, { value: (_b = (_a = sharing.offices) === null || _a === void 0 ? void 0 : _a.find((x) => x.office === office.uid)) !== null && _b !== void 0 ? _b : {
                                            is_pinned: false,
                                            is_shared: false,
                                            is_editable: false,
                                        }, disablePinned: !!sharing.all_users.is_pinned, disableEditable: !!sharing.all_users.is_editable, disableShared: !!sharing.all_users.is_pinned || !!sharing.all_users.is_shared, onChange: setSharingForOffice(office.uid), TooltipProps: { enterDelay: 1000, enterNextDelay: 5000 } })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "body2" }, office.name))));
                        }))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Divider, null)),
                    React.createElement(Grid, { item: true, container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid, { item: true, container: true, wrap: "nowrap", spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true, style: { flex: 1 } },
                                React.createElement(Typography, null, "Users")),
                            !users && (React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, "Loading..."))),
                            users && (React.createElement(Grid, { item: true },
                                React.createElement(Button, { size: "small", variant: "outlined" }, "Clear all")))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, container: true, spacing: 0 }, (users !== null && users !== void 0 ? users : [])
                            .sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            if (a.name > b.name)
                                return 1;
                            return 0;
                        })
                            .map((user) => {
                            var _a, _b, _c, _d, _e;
                            return (React.createElement(Grid, { key: user.uid, item: true, container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(SavedViewShareSelector, { value: (_b = (_a = sharing.users) === null || _a === void 0 ? void 0 : _a.find((x) => x.user === user.uid)) !== null && _b !== void 0 ? _b : {
                                            is_pinned: false,
                                            is_shared: false,
                                            is_editable: false,
                                        }, disablePinned: !!sharing.all_users.is_pinned ||
                                            ((_c = sharing.roles) !== null && _c !== void 0 ? _c : []).some((x) => x.is_pinned && user.roles.includes(x.role)), disableEditable: !!sharing.all_users.is_editable ||
                                            ((_d = sharing.roles) !== null && _d !== void 0 ? _d : []).some((x) => x.is_editable && user.roles.includes(x.role)), disableShared: !!sharing.all_users.is_pinned ||
                                            !!sharing.all_users.is_shared ||
                                            ((_e = sharing.roles) !== null && _e !== void 0 ? _e : []).some((x) => (x.is_pinned || x.is_shared) && user.roles.includes(x.role)), onChange: setSharingForUser(user.uid), TooltipProps: { enterDelay: 1000, enterNextDelay: 5000 } })),
                                React.createElement(Grid, { item: true },
                                    React.createElement(Typography, { variant: "body2" }, user.name))));
                        }))))))));
};
export default SavedViewSharePanel;
