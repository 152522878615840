import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CoreDialog from "./CoreDialog";
/**
 * A wrapper around `CoreDialog` that includes our default styling,
 * as well as a confirm and cancel button.
 *
 * @param props
 * @returns
 */
const ActionDialog = (props) => {
    const { DialogProps, cancelButtonProps, cancelButtonText = "Cancel", children, confirmButtonProps, confirmButtonText = "OK", isCancelButtonDisabled, isConfirmButtonDisabled = false, onCancel, onConfirm, title, } = props;
    return (React.createElement(CoreDialog, Object.assign({ fullWidth: true }, DialogProps),
        title && React.createElement(DialogTitle, null, title),
        React.createElement(DialogContent, null, children),
        React.createElement(DialogActions, null,
            React.createElement(Button, Object.assign({ onClick: onCancel, tabIndex: -1, variant: "text", disabled: isCancelButtonDisabled }, cancelButtonProps), cancelButtonText),
            React.createElement(Button, Object.assign({ onClick: onConfirm, tabIndex: -1, variant: "contained", color: "primary", disabled: isConfirmButtonDisabled }, confirmButtonProps), confirmButtonText))));
};
export default ActionDialog;
