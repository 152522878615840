import * as React from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import CommentTextArea from "@app/entrypoints/brokerage/features/crm/components/box-view/Chronology/ChronologyItems/CommentTextArea";
import { useAddComment } from "@app/orval/api/comments";
const CommentPopover = (props) => {
    const { entityUid, onCancel, onSubmit } = props;
    if (!entityUid) {
        throw new Error("entityUid is required");
    }
    const [value, setValue] = React.useState("");
    const submitCommentApi = useAddComment();
    console.log({ entityUid });
    const cancel = React.useCallback(() => {
        onCancel();
    }, [onCancel]);
    const submit = React.useCallback(() => {
        onSubmit();
        const loadingSnackbar = enqueueSnackbar("Submitting comment...", { variant: "info", persist: true });
        submitCommentApi
            .mutateAsync({
            data: {
                entity_uid: entityUid,
                content: value,
            },
        })
            .then(() => {
            enqueueSnackbar("Comment submitted successfully", { variant: "success" });
        })
            .catch(enqueueErrorSnackbar)
            .finally(() => {
            closeSnackbar(loadingSnackbar);
        });
    }, [entityUid, onSubmit, submitCommentApi, value]);
    const onChange = React.useCallback((_, newValue) => {
        setValue(newValue);
    }, []);
    return (React.createElement(Box, { p: 2, style: { minWidth: "450px", position: "relative" } },
        React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, null, "Leave a comment")),
            React.createElement(Grid, { item: true },
                React.createElement(Divider, null)),
            React.createElement(Grid, { item: true },
                React.createElement(CommentTextArea, { value: value, onChange: onChange, autoFocus: true })),
            React.createElement(Grid, { item: true },
                React.createElement(Divider, null)),
            React.createElement(Grid, { item: true },
                React.createElement(Grid, { container: true, justifyContent: "flex-start" },
                    React.createElement(Button, { variant: "text", onClick: cancel, style: { marginRight: "1rem" } }, "Cancel"),
                    React.createElement(Button, { variant: "contained", onClick: submit, disabled: !value }, "Submit"))))));
};
export default CommentPopover;
