import * as React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Divider, Grid2, IconButton, Typography } from "@mui/material";
import CRMChangesReportBox from "./CRMChangesReportBox";
const CRMChangesReportSection = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { buyerContractStageChanges, buyerContracts, buyerLeadAgentChanges, buyerLeadStageChanges, buyerLeads, comments, emailsByTransactionUid, endDate, fieldValueChanges, office, sellerContractStageChanges, sellerContracts, sellerLeadAgentChanges, sellerLeadStageChanges, sellerLeads, startDate, title, } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const collapse = React.useCallback(() => {
        setIsCollapsed((prev) => !prev);
    }, []);
    const stageChangesByBuyerLeadUid = React.useMemo(() => {
        const changes = {};
        buyerLeadStageChanges === null || buyerLeadStageChanges === void 0 ? void 0 : buyerLeadStageChanges.forEach((change) => {
            if (!changes[change.buyer_lead.uid]) {
                changes[change.buyer_lead.uid] = [];
            }
            changes[change.buyer_lead.uid].push(change);
        });
        return changes;
    }, [buyerLeadStageChanges]);
    const stageChangesBySellerLeadUid = React.useMemo(() => {
        const changes = {};
        sellerLeadStageChanges === null || sellerLeadStageChanges === void 0 ? void 0 : sellerLeadStageChanges.forEach((change) => {
            if (!changes[change.seller_lead.uid]) {
                changes[change.seller_lead.uid] = [];
            }
            changes[change.seller_lead.uid].push(change);
        });
        return changes;
    }, [sellerLeadStageChanges]);
    const stageChangesByBuyerContractUid = React.useMemo(() => {
        const changes = {};
        buyerContractStageChanges === null || buyerContractStageChanges === void 0 ? void 0 : buyerContractStageChanges.forEach((change) => {
            if (!changes[change.buyer_contract.uid]) {
                changes[change.buyer_contract.uid] = [];
            }
            changes[change.buyer_contract.uid].push(change);
        });
        return changes;
    }, [buyerContractStageChanges]);
    const stageChangesBySellerContractUid = React.useMemo(() => {
        const changes = {};
        sellerContractStageChanges === null || sellerContractStageChanges === void 0 ? void 0 : sellerContractStageChanges.forEach((change) => {
            if (!changes[change.seller_contract.uid]) {
                changes[change.seller_contract.uid] = [];
            }
            changes[change.seller_contract.uid].push(change);
        });
        return changes;
    }, [sellerContractStageChanges]);
    const agentChangesByBuyerLeadUid = React.useMemo(() => {
        const changes = {};
        buyerLeadAgentChanges === null || buyerLeadAgentChanges === void 0 ? void 0 : buyerLeadAgentChanges.forEach((change) => {
            if (!changes[change.buyer_lead.uid]) {
                changes[change.buyer_lead.uid] = [];
            }
            changes[change.buyer_lead.uid].push(change);
        });
        return changes;
    }, [buyerLeadAgentChanges]);
    const agentChangesBySellerLeadUid = React.useMemo(() => {
        const changes = {};
        sellerLeadAgentChanges === null || sellerLeadAgentChanges === void 0 ? void 0 : sellerLeadAgentChanges.forEach((change) => {
            if (!changes[change.seller_lead.uid]) {
                changes[change.seller_lead.uid] = [];
            }
            changes[change.seller_lead.uid].push(change);
        });
        return changes;
    }, [sellerLeadAgentChanges]);
    const commentsByTransactionUid = React.useMemo(() => {
        const changes = {};
        comments === null || comments === void 0 ? void 0 : comments.forEach((comment) => {
            var _a, _b;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const transactionUid = ((_a = comment.buyer) === null || _a === void 0 ? void 0 : _a.uid) || ((_b = comment.seller) === null || _b === void 0 ? void 0 : _b.uid);
            if (!changes[transactionUid]) {
                changes[transactionUid] = [];
            }
            changes[transactionUid].push(comment);
        });
        return changes;
    }, [comments]);
    const fieldChangesByTransactionUid = React.useMemo(() => {
        const changes = {};
        fieldValueChanges.forEach((change) => {
            var _a, _b, _c, _d, _e;
            const transactionUid = (_e = (((_a = change.buyer_lead) === null || _a === void 0 ? void 0 : _a.uid) ||
                ((_b = change.seller_lead) === null || _b === void 0 ? void 0 : _b.uid) ||
                ((_c = change.buyer_contract) === null || _c === void 0 ? void 0 : _c.uid) ||
                ((_d = change.seller_contract) === null || _d === void 0 ? void 0 : _d.uid))) !== null && _e !== void 0 ? _e : "";
            if (!changes[transactionUid]) {
                changes[transactionUid] = [];
            }
            changes[transactionUid].push(change);
        });
        return changes;
    }, [fieldValueChanges]);
    const requiresUpdate = React.useCallback((transaction) => {
        var _a, _b, _c;
        if (office === "AVL") {
            return false;
        }
        const isNew = Boolean(transaction.created_at >= startDate && transaction.created_at <= endDate);
        if (isNew) {
            return true;
        }
        const stageChanges = (_c = (_b = (_a = stageChangesByBuyerLeadUid[transaction.uid]) !== null && _a !== void 0 ? _a : stageChangesBySellerLeadUid[transaction.uid]) !== null && _b !== void 0 ? _b : stageChangesByBuyerContractUid[transaction.uid]) !== null && _c !== void 0 ? _c : stageChangesBySellerContractUid[transaction.uid];
        if (stageChanges && stageChanges.length > 0) {
            return true;
        }
        return false;
    }, [
        endDate,
        office,
        stageChangesByBuyerContractUid,
        stageChangesByBuyerLeadUid,
        stageChangesBySellerContractUid,
        stageChangesBySellerLeadUid,
        startDate,
    ]);
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 2 },
        React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, spacing: 1 },
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "h6" }, title)),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "h6", style: { fontWeight: "normal", fontStyle: "italic", color: "#888" } }, ((_a = buyerLeads === null || buyerLeads === void 0 ? void 0 : buyerLeads.length) !== null && _a !== void 0 ? _a : 0) +
                        ((_b = buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.length) !== null && _b !== void 0 ? _b : 0) +
                        ((_c = sellerLeads === null || sellerLeads === void 0 ? void 0 : sellerLeads.length) !== null && _c !== void 0 ? _c : 0) +
                        ((_d = sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.length) !== null && _d !== void 0 ? _d : 0))),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, "/"),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "h6", style: { fontWeight: "normal", fontStyle: "italic", color: "#888" } }, ((_e = buyerLeads === null || buyerLeads === void 0 ? void 0 : buyerLeads.filter((x) => requiresUpdate(x)).length) !== null && _e !== void 0 ? _e : 0) +
                        ((_f = buyerContracts === null || buyerContracts === void 0 ? void 0 : buyerContracts.filter((x) => requiresUpdate(x)).length) !== null && _f !== void 0 ? _f : 0) +
                        ((_g = sellerLeads === null || sellerLeads === void 0 ? void 0 : sellerLeads.filter((x) => requiresUpdate(x)).length) !== null && _g !== void 0 ? _g : 0) +
                        ((_h = sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.filter((x) => requiresUpdate(x)).length) !== null && _h !== void 0 ? _h : 0))),
                React.createElement(Grid2, { style: { flex: 1 } }),
                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(IconButton, { onClick: collapse }, isCollapsed ? React.createElement(ExpandMore, null) : React.createElement(ExpandLess, null))))),
        !isCollapsed && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, null,
                React.createElement(Divider, null)), buyerLeads === null || buyerLeads === void 0 ? void 0 :
            buyerLeads.sort((a, b) => {
                const aRequiresUpdate = requiresUpdate(a);
                const bRequiresUpdate = requiresUpdate(b);
                if (aRequiresUpdate && !bRequiresUpdate) {
                    return -1;
                }
                if (!aRequiresUpdate && bRequiresUpdate) {
                    return 1;
                }
                if (a.created_at < b.created_at)
                    return -1;
                if (a.created_at > b.created_at)
                    return 1;
                return 0;
            }).map((x) => {
                var _a, _b, _c, _d;
                return (React.createElement(Grid2, { key: x.uid },
                    React.createElement(CRMChangesReportBox, { uid: x.uid, name: x.name, stage: x.stage, created_at: x.created_at, producer: (_a = x.agents.find((a) => a.type === "Producer")) === null || _a === void 0 ? void 0 : _a.agent, producerChanges: ((_b = agentChangesByBuyerLeadUid[x.uid]) !== null && _b !== void 0 ? _b : []).filter((x) => { var _a, _b; return ((_a = x.new_value) === null || _a === void 0 ? void 0 : _a.type) === "Producer" || ((_b = x.previous_value) === null || _b === void 0 ? void 0 : _b.type) === "Producer"; }), client: x.clients[0], current_timeframe: (_c = x.fields["Time Period"]) === null || _c === void 0 ? void 0 : _c.value, rejected_tags: x.fields["Rejected Tags"], stageChanges: stageChangesByBuyerLeadUid[x.uid], comments: commentsByTransactionUid[x.uid], fieldValueChanges: fieldChangesByTransactionUid[x.uid], isNew: Boolean(x.created_at >= startDate && x.created_at <= endDate), currentTimeframeChanged: Boolean(((_d = x.fields["Time Period"]) === null || _d === void 0 ? void 0 : _d.last_calculated_timestamp) &&
                            x.fields["Time Period"].last_calculated_timestamp >= startDate &&
                            x.fields["Time Period"].last_calculated_timestamp <= endDate), emails: emailsByTransactionUid === null || emailsByTransactionUid === void 0 ? void 0 : emailsByTransactionUid[x.uid], premierAgentContactId: x.fields["Premier Agent Contact ID"] })));
            }), sellerLeads === null || sellerLeads === void 0 ? void 0 :
            sellerLeads.sort((a, b) => {
                const aRequiresUpdate = requiresUpdate(a);
                const bRequiresUpdate = requiresUpdate(b);
                if (aRequiresUpdate && !bRequiresUpdate) {
                    return -1;
                }
                if (!aRequiresUpdate && bRequiresUpdate) {
                    return 1;
                }
                if (a.created_at < b.created_at)
                    return -1;
                if (a.created_at > b.created_at)
                    return 1;
                return 0;
            }).map((x) => {
                var _a, _b, _c, _d;
                return (React.createElement(Grid2, { key: x.uid },
                    React.createElement(CRMChangesReportBox, { uid: x.uid, name: x.name, stage: x.stage, created_at: x.created_at, producer: (_a = x.agents.find((a) => a.type === "Producer")) === null || _a === void 0 ? void 0 : _a.agent, producerChanges: ((_b = agentChangesBySellerLeadUid[x.uid]) !== null && _b !== void 0 ? _b : []).filter((x) => { var _a, _b; return ((_a = x.new_value) === null || _a === void 0 ? void 0 : _a.type) === "Producer" || ((_b = x.previous_value) === null || _b === void 0 ? void 0 : _b.type) === "Producer"; }), client: x.clients[0], current_timeframe: (_c = x.fields["Time Period"]) === null || _c === void 0 ? void 0 : _c.value, rejected_tags: x.fields["Rejected Tags"], stageChanges: stageChangesBySellerLeadUid[x.uid], comments: commentsByTransactionUid[x.uid], fieldValueChanges: fieldChangesByTransactionUid[x.uid], isNew: Boolean(x.created_at >= startDate && x.created_at <= endDate), currentTimeframeChanged: Boolean(((_d = x.fields["Time Period"]) === null || _d === void 0 ? void 0 : _d.last_calculated_timestamp) &&
                            x.fields["Time Period"].last_calculated_timestamp >= startDate &&
                            x.fields["Time Period"].last_calculated_timestamp <= endDate), emails: emailsByTransactionUid === null || emailsByTransactionUid === void 0 ? void 0 : emailsByTransactionUid[x.uid], premierAgentContactId: x.fields["Premier Agent Contact ID"] })));
            }), buyerContracts === null || buyerContracts === void 0 ? void 0 :
            buyerContracts.sort((a, b) => {
                const aRequiresUpdate = requiresUpdate(a);
                const bRequiresUpdate = requiresUpdate(b);
                if (aRequiresUpdate && !bRequiresUpdate) {
                    return -1;
                }
                if (!aRequiresUpdate && bRequiresUpdate) {
                    return 1;
                }
                if (a.created_at < b.created_at)
                    return -1;
                if (a.created_at > b.created_at)
                    return 1;
                return 0;
            }).map((x) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                return (React.createElement(Grid2, { key: x.uid },
                    React.createElement(CRMChangesReportBox, { uid: x.uid, name: x.name, stage: x.stage, created_at: x.created_at, producer: (_c = (_b = (_a = x.buyer_lead) === null || _a === void 0 ? void 0 : _a.agents) === null || _b === void 0 ? void 0 : _b.find((a) => a.type === "Producer")) === null || _c === void 0 ? void 0 : _c.agent, client: (_e = (_d = x.buyer_lead) === null || _d === void 0 ? void 0 : _d.clients) === null || _e === void 0 ? void 0 : _e[0], 
                        // @ts-expect-error: does not exist on type
                        current_timeframe: (_g = (_f = x.buyer_lead) === null || _f === void 0 ? void 0 : _f.fields["Time Period"]) === null || _g === void 0 ? void 0 : _g.value, 
                        // @ts-expect-error: does not exist on type
                        rejected_tags: (_h = x.buyer_lead) === null || _h === void 0 ? void 0 : _h.fields["Rejected Tags"], stageChanges: stageChangesByBuyerContractUid[x.uid], comments: commentsByTransactionUid[x.uid], fieldValueChanges: fieldChangesByTransactionUid[x.uid], isNew: Boolean(x.created_at >= startDate && x.created_at <= endDate), currentTimeframeChanged: Boolean(((_j = x.fields["Time Period"]) === null || _j === void 0 ? void 0 : _j.last_calculated_timestamp) &&
                            x.fields["Time Period"].last_calculated_timestamp >= startDate &&
                            x.fields["Time Period"].last_calculated_timestamp <= endDate), emails: emailsByTransactionUid === null || emailsByTransactionUid === void 0 ? void 0 : emailsByTransactionUid[x.uid], 
                        // @ts-expect-error: unknown is not assignable tot ype
                        premierAgentContactId: (_k = x.buyer_lead) === null || _k === void 0 ? void 0 : _k.fields["Premier Agent Contact ID"] })));
            }), sellerContracts === null || sellerContracts === void 0 ? void 0 :
            sellerContracts.sort((a, b) => {
                const aRequiresUpdate = requiresUpdate(a);
                const bRequiresUpdate = requiresUpdate(b);
                if (aRequiresUpdate && !bRequiresUpdate) {
                    return -1;
                }
                if (!aRequiresUpdate && bRequiresUpdate) {
                    return 1;
                }
                if (a.created_at < b.created_at)
                    return -1;
                if (a.created_at > b.created_at)
                    return 1;
                return 0;
            }).map((x) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return (React.createElement(Grid2, { key: x.uid },
                    React.createElement(CRMChangesReportBox, { uid: x.uid, name: x.name, stage: x.stage, created_at: x.created_at, producer: (_b = (_a = x.seller_lead) === null || _a === void 0 ? void 0 : _a.agents.find((a) => a.type === "Producer")) === null || _b === void 0 ? void 0 : _b.agent, client: (_c = x.seller_lead) === null || _c === void 0 ? void 0 : _c.clients[0], current_timeframe: (_e = (_d = x.seller_lead) === null || _d === void 0 ? void 0 : _d.fields["Time Period"]) === null || _e === void 0 ? void 0 : _e.value, rejected_tags: (_f = x.seller_lead) === null || _f === void 0 ? void 0 : _f.fields["Rejected Tags"], stageChanges: stageChangesBySellerContractUid[x.uid], comments: commentsByTransactionUid[x.uid], fieldValueChanges: fieldChangesByTransactionUid[x.uid], isNew: Boolean(x.created_at >= startDate && x.created_at <= endDate), currentTimeframeChanged: Boolean(((_g = x.fields["Time Period"]) === null || _g === void 0 ? void 0 : _g.last_calculated_timestamp) &&
                            x.fields["Time Period"].last_calculated_timestamp >= startDate &&
                            x.fields["Time Period"].last_calculated_timestamp <= endDate), emails: emailsByTransactionUid === null || emailsByTransactionUid === void 0 ? void 0 : emailsByTransactionUid[x.uid], premierAgentContactId: (_h = x.seller_lead) === null || _h === void 0 ? void 0 : _h.fields["Premier Agent Contact ID"] })));
            })))));
};
export default CRMChangesReportSection;
