var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Avatar, Grid, InputAdornment, ListItem, ListItemAvatar, ListItemText, TextField, Typography, Autocomplete, } from "@mui/material";
import { getInitialsMemoized } from "@app/util/Utils";
const getOptionLabel = (option) => {
    return [option.first_name, option.last_name].filter((obj) => obj).join(" ");
};
const isOptionEqualToValue = (option, value) => {
    return (option === null || option === void 0 ? void 0 : option.uid) == (value === null || value === void 0 ? void 0 : value.uid);
};
const renderOption = (props, option) => {
    const initials = [option.first_name, option.last_name]
        .filter((obj) => obj)
        .map((obj) => obj === null || obj === void 0 ? void 0 : obj.substring(0, 1))
        .join("");
    const name = [option.first_name, option.last_name].filter((obj) => obj).join(" ");
    const { email, phone } = option;
    return (React.createElement(ListItem, Object.assign({}, props, { key: option.uid, style: { paddingTop: 0, paddingBottom: 0 } }),
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar
            // src={option.icon_url ?? undefined}
            , { 
                // src={option.icon_url ?? undefined}
                src: undefined, style: {
                    height: "30px",
                    width: "30px",
                    fontSize: "0.9rem",
                } }, initials && initials !== "" ? initials : "?")),
        React.createElement(ListItemText, { primary: name, secondary: React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, email)),
                phone && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, phone)))) })));
};
const UserAutocomplete = (props) => {
    var _a, _b, _c, _d, _e;
    const { TextFieldProps, onChange, users, value } = props, other = __rest(props, ["TextFieldProps", "onChange", "users", "value"]);
    const [isTyping, setIsTyping] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(null);
    const handleInputValueChanged = React.useCallback((e) => {
        const val = e.target.value;
        setInputValue(val);
    }, [setInputValue]);
    const handleAutocompleteValueChanged = React.useCallback((_, value_, reason) => {
        setIsTyping(false);
        setInputValue(null);
        if (reason === "selectOption") {
            onChange(_, value_, reason);
        }
        else if (reason === "clear") {
            onChange(_, null, reason);
        }
    }, [setIsTyping, setInputValue, onChange]);
    const onInputFocus = React.useCallback(() => {
        setIsTyping((prev) => (!prev ? true : prev));
    }, [setIsTyping]);
    const onInputBlur = React.useCallback(() => {
        setIsTyping((prev) => (prev ? false : prev));
    }, [setIsTyping]);
    const renderInput = React.useCallback((params) => {
        var _a, _b, _c;
        const initials = value ? getInitialsMemoized(value.first_name, value.last_name) : null;
        return (React.createElement(TextField, Object.assign({ variant: "standard" }, params, TextFieldProps, { inputProps: Object.assign(Object.assign({}, params.inputProps), { onFocus: onInputFocus, onBlur: onInputBlur }), autoFocus: (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.autoFocus) !== null && _a !== void 0 ? _a : true, onChange: handleInputValueChanged, InputProps: Object.assign(Object.assign(Object.assign({}, params.InputProps), TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.InputProps), { startAdornment: (_c = (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.InputProps) === null || _b === void 0 ? void 0 : _b.startAdornment) !== null && _c !== void 0 ? _c : (value && !isTyping && (React.createElement(InputAdornment, { position: "start" },
                    React.createElement(Avatar
                    // src={selectedUser.icon_url ?? undefined}
                    , { 
                        // src={selectedUser.icon_url ?? undefined}
                        src: undefined, style: {
                            height: "22px",
                            width: "22px",
                            fontSize: "0.65rem",
                        } }, initials && initials !== "" ? initials : "?")))) }) })));
    }, [value, TextFieldProps, onInputFocus, onInputBlur, handleInputValueChanged, isTyping]);
    const selectedUserName = value ? [value.first_name, value.last_name].filter((o) => o).join(" ") : null;
    return (React.createElement(Autocomplete, Object.assign({ clearOnBlur: true, clearOnEscape: true, componentsProps: {
            paper: {
                style: {
                    minWidth: "300px",
                },
            },
        } }, other, { getOptionLabel: getOptionLabel, isOptionEqualToValue: isOptionEqualToValue, value: value !== null && value !== void 0 ? value : null, inputValue: (_a = inputValue !== null && inputValue !== void 0 ? inputValue : selectedUserName) !== null && _a !== void 0 ? _a : "", groupBy: (_c = (_b = props.AutocompleteProps) === null || _b === void 0 ? void 0 : _b.groupBy) !== null && _c !== void 0 ? _c : undefined, noOptionsText: (_e = (_d = props.AutocompleteProps) === null || _d === void 0 ? void 0 : _d.noOptionsText) !== null && _e !== void 0 ? _e : "No search results found", onChange: handleAutocompleteValueChanged, options: users, renderInput: renderInput, renderOption: renderOption })));
};
export default UserAutocomplete;
