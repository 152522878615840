import isObject from "lodash/isObject";
import { sanitizeStageName } from "../../developer/sections/utils";
const isDictionary = (value) => {
    return value && typeof value === "object" && !Array.isArray(value);
};
const asString = (val) => {
    if (isDictionary(val)) {
        return JSON.stringify(val);
    }
    if (typeof val === "string") {
        return `\`${val}\``;
    }
    return String(val);
};
export const doReplacements = (validationFunctionStr, context, stages) => {
    var _a, _b;
    let res = validationFunctionStr;
    // fields
    if (context["fields"]) {
        for (const key of Object.keys(context["fields"])) {
            const value = context["fields"][key];
            if (isObject(value)) {
                res = res.replaceAll(`$'${key}'`, asString(value["value"]));
            }
            else {
                res = res.replaceAll(`$'${key}'`, asString(value));
            }
        }
    }
    // agents
    for (const agentType of ["Producer", "Co-Agent"]) {
        const wildcard = `$${agentType.toLowerCase().replace(/[^A-Za-z0-9]/g, "_")}`;
        const agent = (_a = context["agents"]) === null || _a === void 0 ? void 0 : _a.find((x) => x.type === agentType);
        if (!agent) {
            res = res.replaceAll(wildcard, "undefined");
        }
        else {
            res = res.replaceAll(wildcard, asString(Object.assign(Object.assign({}, agent.agent), agent)));
        }
    }
    // stage?.position
    res = res.replaceAll("$stage?.position", asString((_b = stages === null || stages === void 0 ? void 0 : stages.find((x) => { var _a; return x.uid === ((_a = context["stage"]) === null || _a === void 0 ? void 0 : _a.uid); })) === null || _b === void 0 ? void 0 : _b.sort_order));
    // any other key
    for (const key of Object.keys(context)) {
        res = res.replaceAll(`$${key}`, asString(context[key]));
    }
    // stage position hard-coded values
    for (const stage of stages !== null && stages !== void 0 ? stages : []) {
        console.log(`$STAGE_${sanitizeStageName(stage.name)}.position`, stage.sort_order);
        res = res.replaceAll(`$STAGE_${sanitizeStageName(stage.name)}.position`, asString(stage.sort_order));
        res = res.replaceAll(`$STAGE_${sanitizeStageName(stage.name)}`, asString(stage));
    }
    res = res.replace(/\$'(.*?)'/g, "undefined");
    return res;
};
