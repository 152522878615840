const getDefaultFilterForField = (field, fieldDefinitions) => {
    console.log("filtering by field", field);
    const fieldDefinition = fieldDefinitions.find((f) => f.uid === field);
    if (fieldDefinition) {
        if (fieldDefinition.field_type === "text") {
            return {
                text_filter: {
                    lhs_field: field,
                    comparator: "is set",
                },
            };
        }
        if (fieldDefinition.field_type === "number") {
            return {
                number_filter: {
                    lhs_field: field,
                    comparator: "is set",
                },
            };
        }
        if (fieldDefinition.field_type === "date") {
            return {
                date_filter: {
                    lhs_field: field,
                    comparator: "is set",
                },
            };
        }
        if (fieldDefinition.field_type === "choice" && !fieldDefinition.allows_multiple) {
            return {
                choice_filter: {
                    lhs_field: field,
                    comparator: "is set",
                },
            };
        }
        if (fieldDefinition.field_type === "choice" && fieldDefinition.allows_multiple) {
            return {
                multiple_choice_filter: {
                    lhs_field: field,
                    comparator: "is not blank",
                },
            };
        }
        if (fieldDefinition.field_type === "checkbox") {
            return {
                boolean_filter: {
                    lhs_field: field,
                    comparator: "is checked",
                },
            };
        }
        if (fieldDefinition.field_type === "formula") {
            if (!fieldDefinition.format) {
                return {
                    text_filter: {
                        lhs_field: field,
                        comparator: "is set",
                    },
                };
            }
            if (fieldDefinition.format.includes("0")) {
                return {
                    number_filter: {
                        lhs_field: field,
                        comparator: "is set",
                    },
                };
            }
            return {
                date_filter: {
                    lhs_field: field,
                    comparator: "is set",
                },
            };
        }
    }
    if (field === "stage") {
        return {
            stage_filter: {},
        };
    }
    if (field === "created_at") {
        return {
            timestamp_filter: {
                timestamp_type: "Creation Timestamp",
            },
        };
    }
    if (field === "updated_at") {
        return {
            timestamp_filter: {
                timestamp_type: "Last Update Timestamp",
            },
        };
    }
    if (field === "last_stage_change_timestamp") {
        return {
            timestamp_filter: {
                timestamp_type: "Last Stage Change Timestamp",
            },
        };
    }
    if (field === "last_comment_timestamp") {
        return {
            timestamp_filter: {
                timestamp_type: "Last Comment Timestamp",
            },
        };
    }
    if (field === "last_email_timestamp") {
        return {
            timestamp_filter: {
                timestamp_type: "Last Email Timestamp",
            },
        };
    }
    if (field === "producer") {
        return {
            user_filter: {
                user_type: "Producer",
                comparator: "is set",
            },
        };
    }
    if (field === "co_agent") {
        return {
            user_filter: {
                user_type: "Co-Agent",
                comparator: "is set",
            },
        };
    }
    if (field === "listing_coordinator") {
        return {
            user_filter: {
                user_type: "Listing Coordinator",
                comparator: "is set",
            },
        };
    }
    if (field === "created_by") {
        return {
            user_filter: {
                user_type: "Created By",
                comparator: "is set",
            },
        };
    }
    return {};
};
export default getDefaultFilterForField;
