import * as React from "react";
import { Divider, Grid, Typography } from "@mui/material";
const FormSection = (props) => {
    const { children, title } = props;
    return (React.createElement(Grid, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: "h6" }, title)),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true }, children)));
};
export default FormSection;
