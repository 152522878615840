import * as React from "react";
import { Box } from "@mui/material";
import { ChartsGrid, LinePlot, ChartsXAxis, ChartsYAxis, ResponsiveChartContainer, ScatterPlot } from "@mui/x-charts";
import numeral from "numeral";
import CoreLoading from "@app/common/CoreLoading";
import AppConfig from "@app/util/AppConfig";
import { getDaysOnMarket, getSPLP } from "./utils";
const formatTick = (tickItem) => numeral(tickItem).format("0,0.00");
const CompsPullerAgedPropertyRegression = (props) => {
    const { properties } = props;
    if (properties.length === 0) {
        return React.createElement(CoreLoading, null);
    }
    let graphData = properties
        .map((property) => {
        const point = {
            daysOnMarket: getDaysOnMarket(property.data, property.source),
            splp: getSPLP(property.data),
        };
        return point;
    })
        .filter((property) => property.splp && property.daysOnMarket != null && property.daysOnMarket < 180);
    const scatterSeries = {
        type: "scatter",
        disableHover: true,
        data: graphData.map((point, index) => {
            return { x: point.daysOnMarket, y: point.splp, id: index };
        }),
    };
    // line of best fit
    if (graphData.length > 0) {
        let sumx = 0;
        let sumy = 0;
        let sumxy = 0;
        let sumxx = 0;
        let sumyy = 0;
        graphData.forEach((point) => {
            const x = point.daysOnMarket;
            const y = point.splp;
            sumx += x;
            sumy += y;
            sumxy += x * y;
            sumxx += x * x;
            sumyy += y * y;
        });
        const slope = (graphData.length * sumxy - sumx * sumy) / (graphData.length * sumxx - sumx * sumx);
        const intercept = (sumyy - slope * sumx) / graphData.length;
        graphData = graphData.map((point) => (Object.assign(Object.assign({}, point), { line: slope * point.daysOnMarket + intercept })));
    }
    const colors = [AppConfig.themeColors.secondary, AppConfig.themeColors.primary];
    return (React.createElement(Box, { width: "100%", height: "100%" },
        React.createElement(ResponsiveChartContainer, { dataset: graphData, xAxis: [
                {
                    dataKey: "daysOnMarket",
                    label: "Days on Market",
                    labelStyle: { fontFamily: AppConfig.fonts.primary },
                    tickLabelStyle: { fontFamily: AppConfig.fonts.primary, fontSize: 10 },
                },
            ], yAxis: [
                {
                    // min: graphRange[0],
                    // max: graphRange[1],
                    tickMaxStep: 0.05,
                    tickLabelStyle: { fontFamily: AppConfig.fonts.primary, fontSize: 10 },
                    valueFormatter: formatTick,
                },
            ], series: [
                {
                    dataKey: "line",
                    type: "line",
                    disableHighlight: true,
                },
                scatterSeries,
            ], disableAxisListener: true, colors: colors },
            React.createElement(LinePlot, null),
            React.createElement(ScatterPlot, null),
            React.createElement(ChartsXAxis, null),
            React.createElement(ChartsYAxis, null),
            React.createElement(ChartsGrid, { horizontal: true, vertical: true }))));
};
export default CompsPullerAgedPropertyRegression;
