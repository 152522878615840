import * as React from "react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { NOT_SPECIFIED } from "./utils";
const PIPELINES = [
    "Buyer Lead",
    "Buyer Contract",
    "Seller Lead",
    "Listing",
    "User",
    "Recruit",
];
const PipelineFilter = (props) => {
    var _a, _b, _c;
    const { TextFieldProps, includeNotSpecifiedOption = true, isNull, onChange, value } = props;
    const handleChange = React.useCallback((event) => {
        onChange(event.target.value || null);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true }, TextFieldProps, { select: true, value: isNull ? NOT_SPECIFIED : (value !== null && value !== void 0 ? value : ""), onChange: handleChange, slotProps: Object.assign(Object.assign({}, TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps), { input: Object.assign({ startAdornment: React.createElement(InputAdornment, { position: "start" }, "Pipeline") }, (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _a === void 0 ? void 0 : _a.input), inputLabel: Object.assign({ shrink: true }, (_b = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _b === void 0 ? void 0 : _b.inputLabel), select: Object.assign({ displayEmpty: true }, (_c = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.slotProps) === null || _c === void 0 ? void 0 : _c.select) }) }),
        React.createElement(MenuItem, { value: "" }, "Any"),
        includeNotSpecifiedOption && React.createElement(MenuItem, { value: NOT_SPECIFIED }, "Not Specified"),
        PIPELINES.map((x) => (React.createElement(MenuItem, { key: x, value: x }, x)))));
};
export default PipelineFilter;
