import * as React from "react";
/**
 * See https://developers.arcgis.com/javascript/latest/ for details
 */
const CompsPullerArcGISMap = (props) => {
    const { highlightProperties, properties, subjectProperty, toggleSelected, zoom } = props;
    const [isLoaded, setIsLoaded] = React.useState(false);
    const iframeRef = React.useRef(null);
    const setSubjectProperty = React.useCallback((subjectProperty_) => {
        console.log("setSubjectProperty", subjectProperty_);
        iframeRef.current.contentWindow.postMessage({ message: "ARCGIS_SET_SUBJECT_PROPERTY", data: { subjectProperty: subjectProperty_ } }, "*");
    }, []);
    const setProperties = React.useCallback((properties_) => {
        console.log("setProperties", properties_);
        iframeRef.current.contentWindow.postMessage({ message: "ARCGIS_SET_PROPERTIES", data: { properties: properties_ } }, "*");
    }, []);
    const setZoom = React.useCallback((zoom_) => {
        console.log("setZoom", zoom_);
        iframeRef.current.contentWindow.postMessage({ message: "ARCGIS_SET_ZOOM", data: { zoom: zoom_ } }, "*");
    }, []);
    const messageListener = React.useCallback((event) => {
        if (event.data.message === "ARCGIS_MAP_LOADED") {
            console.log("ARCGIS_MAP_LOADED");
            setIsLoaded(true);
        }
        if (event.data.message === "ARCGIS_TOGGLE_SELECTED") {
            console.log("ARCGIS_TOGGLE_SELECTED", event.data.data.mlmsNumber);
            toggleSelected(event.data.data.mlmsNumber);
        }
        if (event.data.message === "ARCGIS_HIGHLIGHT_PROPERTIES") {
            console.log("ARCGIS_HIGHLIGHT_PROPERTIES", event.data.data.mlsNumbers);
            highlightProperties(event.data.data.mlsNumbers);
        }
    }, [toggleSelected, highlightProperties]);
    React.useEffect(() => {
        window.addEventListener("message", messageListener);
        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, [messageListener]);
    React.useEffect(() => {
        if (isLoaded) {
            setProperties(properties);
        }
    }, [isLoaded, properties, setProperties]);
    React.useEffect(() => {
        if (isLoaded) {
            setSubjectProperty(subjectProperty);
        }
    }, [isLoaded, setSubjectProperty, subjectProperty]);
    React.useEffect(() => {
        if (isLoaded) {
            setZoom(zoom);
        }
    }, [isLoaded, setZoom, zoom]);
    return (React.createElement("iframe", { ref: iframeRef, src: "https://dash-arcgis-464994061323.us-east1.run.app", title: "ArcGIS", style: { width: "100%", height: "100%" } }));
};
export default CompsPullerArcGISMap;
