import * as React from "react";
import { Link, Typography } from "@mui/material";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import PhoneMenu from "./PhoneMenu";
const CallLink = (props) => {
    var _a, _b, _c;
    const { TypographyProps, name, phoneNumber, text } = props;
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const openMenu = React.useCallback((e) => {
        setMenuAnchorEl(e.currentTarget);
    }, []);
    const closeMenu = React.useCallback(() => {
        setMenuAnchorEl(null);
    }, []);
    let phoneNumberInternational = null;
    let phoneNumberNational = null;
    try {
        const phoneNumberParsed = phoneNumber ? parsePhoneNumber(phoneNumber, "US") : null;
        phoneNumberInternational = (_a = phoneNumberParsed === null || phoneNumberParsed === void 0 ? void 0 : phoneNumberParsed.formatInternational().replace(/\s/g, "")) !== null && _a !== void 0 ? _a : null;
        phoneNumberNational = (_b = phoneNumberParsed === null || phoneNumberParsed === void 0 ? void 0 : phoneNumberParsed.formatNational().replace(/\s/g, "")) !== null && _b !== void 0 ? _b : null;
    }
    catch (err) { }
    if (phoneNumberInternational && isValidPhoneNumber(phoneNumberInternational)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Link, { style: { textDecoration: "none", cursor: "pointer" }, onClick: openMenu },
                React.createElement(Typography, Object.assign({ variant: "body2" }, TypographyProps), (_c = text !== null && text !== void 0 ? text : phoneNumberNational) !== null && _c !== void 0 ? _c : phoneNumberInternational)),
            menuAnchorEl && (React.createElement(PhoneMenu, { MenuProps: {
                    anchorEl: menuAnchorEl,
                    open: true,
                    onClose: closeMenu,
                    onClick: closeMenu,
                }, contacts: [{ name, phone: phoneNumberInternational }] }))));
    }
    return (React.createElement(Typography, Object.assign({ variant: "body2" }, TypographyProps), text !== null && text !== void 0 ? text : phoneNumber));
};
export default CallLink;
