import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid, InputAdornment, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import { useListTeamRoles, useListTeams } from "@app/orval/api/teams";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const TeamSelectDialog = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { DialogProps, initialValue, onClose, onConfirm } = props;
    const [selectedTeamUid, setSelectedTeamUid] = React.useState((_a = initialValue === null || initialValue === void 0 ? void 0 : initialValue.uid) !== null && _a !== void 0 ? _a : null);
    const [selectedRole, setSelectedRole] = React.useState((_b = initialValue === null || initialValue === void 0 ? void 0 : initialValue.role) !== null && _b !== void 0 ? _b : null);
    const listTeamsApi = useListTeams(REFETCH_CACHE_PARAMS);
    const teams = (_d = (_c = listTeamsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const teamsSorted = React.useMemo(() => {
        return teams
            ? teams.sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            })
            : null;
    }, [teams]);
    const listTeamRolesApi = useListTeamRoles(REFETCH_CACHE_PARAMS);
    const teamRoles = (_f = (_e = listTeamRolesApi.data) === null || _e === void 0 ? void 0 : _e.data.items) !== null && _f !== void 0 ? _f : null;
    const isValid = React.useMemo(() => {
        return (selectedTeamUid && selectedRole) || !selectedTeamUid;
    }, [selectedTeamUid, selectedRole]);
    const handleTeamChanged = React.useCallback((e) => {
        const teamUid = e.target.value;
        setSelectedTeamUid(teamUid || null);
        if (!teamUid) {
            setSelectedRole(null);
        }
    }, [setSelectedTeamUid]);
    const handleRoleChanged = React.useCallback((e) => {
        setSelectedRole(e.target.value || null);
    }, [setSelectedRole]);
    const handleConfirm = React.useCallback(() => {
        onConfirm(selectedTeamUid ? { uid: selectedTeamUid, role: selectedRole } : null);
    }, [onConfirm, selectedTeamUid, selectedRole]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, "Add user to team"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Team"),
                            },
                        }, value: selectedTeamUid !== null && selectedTeamUid !== void 0 ? selectedTeamUid : "", onChange: handleTeamChanged, SelectProps: {
                            displayEmpty: true,
                        } },
                        React.createElement(MenuItem, { value: "" }, "No team"),
                        (teamsSorted !== null && teamsSorted !== void 0 ? teamsSorted : []).map((team) => (React.createElement(MenuItem, { key: team.uid, value: team.uid }, team.name))))),
                selectedTeamUid && (React.createElement(Grid, { item: true },
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Role"),
                            },
                        }, value: selectedRole !== null && selectedRole !== void 0 ? selectedRole : "", onChange: handleRoleChanged },
                        !selectedRole && React.createElement(MenuItem, { value: "" }),
                        (teamRoles !== null && teamRoles !== void 0 ? teamRoles : []).map((role) => (React.createElement(MenuItem, { key: role, value: role }, role)))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleConfirm, disabled: !isValid || !teams || !teamRoles, tabIndex: -1 }, "OK"))));
};
export default TeamSelectDialog;
