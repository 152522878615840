var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { TextField } from "@mui/material";
import numeral from "numeral";
const CoreNumberField = (props) => {
    const { blank = null, children, format, max: max_ = null, min: min_ = null, multiplyBy100 = false, number: number_, onBlur, onChange, onNumberChange } = props, other = __rest(props, ["blank", "children", "format", "max", "min", "multiplyBy100", "number", "onBlur", "onChange", "onNumberChange"]);
    const [value, setValue] = React.useState(null);
    const [number, setNumber] = React.useState(number_);
    const [prevNumber, setPrevNumber] = React.useState(number_);
    React.useEffect(() => {
        setPrevNumber(number_);
        setNumber(number_);
    }, [number_]);
    const min = React.useMemo(() => {
        return min_ != null ? min_ * (format.includes("%") || multiplyBy100 ? 100 : 1) : null;
    }, [min_, format, multiplyBy100]);
    const max = React.useMemo(() => {
        return max_ != null ? max_ * (format.includes("%") || multiplyBy100 ? 100 : 1) : null;
    }, [max_, format, multiplyBy100]);
    const value_ = React.useMemo(() => {
        if (value == null) {
            if (number != null) {
                let multiplier = 1;
                if ((multiplyBy100 || format.includes("%")) && !(multiplyBy100 && format.includes("%"))) {
                    multiplier = 100;
                }
                const res = numeral(Math.round(number * 100000000 * multiplier) / 100000000).format(format);
                // console.log({ number, format, multiplyBy100, multiplier, res });
                return res;
            }
            return null;
        }
        return value;
    }, [value, number, format, multiplyBy100]);
    const handleBlur = React.useCallback((e) => {
        if (onBlur) {
            onBlur(e);
        }
        const newValue = e.target.value;
        let num = newValue != null && numeral(newValue) != null ? numeral(newValue).value() : null;
        if (num !== null) {
            if ((max != null || max === 0) && num > max) {
                num = max;
            }
            if ((min != null || min === 0) && num < min) {
                num = min;
            }
            if ((format.includes("%") || multiplyBy100) && !(format.includes("%") && multiplyBy100)) {
                num /= 100;
            }
        }
        if (num == null && blank != null) {
            num = blank;
        }
        // const formattedValue = num != null ? numeral(num).format(format) : "";
        // num = numeral(num).value()!;
        // console.log([newValue, num, formattedValue, this.state.number]);
        if (onNumberChange && num !== prevNumber) {
            onNumberChange(num);
        }
        // console.log("value", newValue, formattedValue);
        setValue(null);
        setNumber(num);
        setPrevNumber(num);
    }, [blank, format, max, min, multiplyBy100, onBlur, onNumberChange, prevNumber]);
    const handleChange = React.useCallback((e) => {
        if (onChange) {
            onChange(e);
        }
        setNumber(null);
        setValue(e.target.value);
    }, [onChange]);
    return (React.createElement(TextField, Object.assign({}, other, { value: value_ !== null && value_ !== void 0 ? value_ : "", onBlur: handleBlur, onChange: handleChange }), children));
};
export default CoreNumberField;
