import * as React from "react";
import { Add, Delete } from "@mui/icons-material";
import { TextField, Button, Grid, IconButton } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { enqueueSnackbar } from "notistack";
import ActionDialog from "@app/common/ActionDialog";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import { sendVcardForUser, useListUsers } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import SendVCardUserSelect from "./SendVCardUserSelect";
import VCardQRCode from "./VCardQRCode";
const SendVCardDialog = (props) => {
    var _a, _b, _c, _d;
    const { DialogProps, onClose, showVCard = false, userUid } = props;
    const session = useSession();
    console.log({ session });
    const [recipients, setRecipients] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [isSending, setIsSending] = React.useState(false);
    const listUsersApi = useListUsers({}, INFINITE_CACHE_PARAMS);
    const users = (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const usersByUid = React.useMemo(() => {
        if (!users) {
            return null;
        }
        const result = {};
        for (const user of users) {
            result[user.uid] = user;
        }
        return result;
    }, [users]);
    const errorDialog = useErrorDialog();
    const addRecipient = React.useCallback(() => {
        setRecipients((prev) => [...prev, { phoneNumber: "", randomId: Math.random().toString() }]);
    }, [setRecipients]);
    const removeRecipient = React.useCallback((randomId) => () => {
        setRecipients((prev) => prev.filter((x) => x.randomId !== randomId));
    }, [setRecipients]);
    const updateRecipientPhoneNumber = React.useCallback((randomId) => (value) => {
        setRecipients((prev) => {
            return prev.map((x) => {
                if (x.randomId === randomId) {
                    return Object.assign(Object.assign({}, x), { phoneNumber: value !== null && value !== void 0 ? value : "" });
                }
                return x;
            });
        });
    }, [setRecipients]);
    const handleMessageChanged = React.useCallback((e) => {
        setMessage(e.target.value);
    }, [setMessage]);
    const handleConfirm = React.useCallback(() => {
        setIsSending(true);
        sendVcardForUser(userUid, { recipients: recipients.map((r) => r.phoneNumber), message: message.trim() || null })
            .then(() => {
            enqueueSnackbar("vCard has been sent", { variant: "success" });
            onClose();
        })
            .catch(errorDialog)
            .then(() => {
            setIsSending(false);
        });
    }, [onClose, errorDialog, userUid, message, recipients]);
    const isValid = React.useMemo(() => {
        return recipients.length > 0 && !recipients.find((x) => !isValidPhoneNumber(x.phoneNumber, "US"));
    }, [recipients]);
    return (React.createElement(ActionDialog, { DialogProps: Object.assign({ maxWidth: "xs" }, DialogProps), title: `Send vCard for ${(_d = (_c = usersByUid === null || usersByUid === void 0 ? void 0 : usersByUid[userUid]) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "user"}`, isConfirmButtonDisabled: !isValid || isSending, onCancel: onClose, onConfirm: handleConfirm },
        React.createElement(Grid, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
            showVCard && (React.createElement(Grid, { item: true },
                React.createElement(VCardQRCode, { userUid: userUid, includeSendButton: false }))),
            React.createElement(Grid, { item: true, container: true, spacing: 1, direction: "column", wrap: "nowrap", style: { width: "100%" } }, recipients.map((recipient) => (React.createElement(Grid, { key: recipient.randomId, item: true, container: true, spacing: 1, wrap: "nowrap", style: { width: "100%" } },
                React.createElement(Grid, { item: true, style: { flex: 1 } },
                    React.createElement(SendVCardUserSelect, { value: recipient.phoneNumber, onChange: updateRecipientPhoneNumber(recipient.randomId), users: users })),
                React.createElement(Grid, { item: true },
                    React.createElement(IconButton, { onClick: removeRecipient(recipient.randomId) },
                        React.createElement(Delete, null))))))),
            React.createElement(Grid, { item: true },
                React.createElement(Button, { onClick: addRecipient, variant: "outlined", color: "primary", size: "small", startIcon: React.createElement(Add, null) }, "Add recipient")),
            React.createElement(Grid, { item: true },
                React.createElement(TextField, { label: "Message", size: "small", variant: "outlined", minRows: 1, maxRows: 8, fullWidth: true, value: message, onChange: handleMessageChanged })))));
};
export default SendVCardDialog;
