import * as React from "react";
import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import BulkStageUpdateDialog from "./BulkStageUpdateDialog";
const BulkEditIconButton = (props) => {
    const { onUpdate, pipeline, uids } = props;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const uidsArr = React.useMemo(() => {
        return Array.from(uids);
    }, [uids]);
    const onButtonClicked = React.useCallback(() => {
        setIsDialogOpen(true);
    }, []);
    const closeDialog = React.useCallback(() => {
        setIsDialogOpen(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: React.createElement(Typography, null,
                "Edit ",
                uids.size,
                " items") },
            React.createElement("span", null,
                React.createElement(IconButton, { onClick: onButtonClicked },
                    React.createElement(Edit, null)))),
        isDialogOpen && (React.createElement(BulkStageUpdateDialog, { onUpdate: onUpdate, onClose: closeDialog, pipeline: pipeline, uids: uidsArr }))));
};
export default BulkEditIconButton;
