/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary List data validation rules
 */
export const listDataValidationRules = (params, options) => {
    return axios.get(`/api/data_validation_rules`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListDataValidationRulesQueryKey = (params) => {
    return [`/api/data_validation_rules`, ...(params ? [params] : [])];
};
export const getListDataValidationRulesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDataValidationRulesQueryKey(params);
    const queryFn = ({ signal }) => listDataValidationRules(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List data validation rules
 */
export function useListDataValidationRules(params, options) {
    const queryOptions = getListDataValidationRulesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create a data validation rule
 */
export const createDataValidationRule = (dataValidationCreateRequest, options) => {
    return axios.post(`/api/data_validation_rules`, dataValidationCreateRequest, options);
};
export const getCreateDataValidationRuleMutationOptions = (options) => {
    const mutationKey = ['createDataValidationRule'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createDataValidationRule(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a data validation rule
*/
export const useCreateDataValidationRule = (options) => {
    const mutationOptions = getCreateDataValidationRuleMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Get a data validation rule
*/
export const getDataValidationRule = (uid, options) => {
    return axios.get(`/api/data_validation_rules/${uid}`, options);
};
export const getGetDataValidationRuleQueryKey = (uid) => {
    return [`/api/data_validation_rules/${uid}`];
};
export const getGetDataValidationRuleQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDataValidationRuleQueryKey(uid);
    const queryFn = ({ signal }) => getDataValidationRule(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a data validation rule
 */
export function useGetDataValidationRule(uid, options) {
    const queryOptions = getGetDataValidationRuleQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update a data validation rule
 */
export const updateDataValidationRule = (uid, dataValidationRuleRequest, options) => {
    return axios.post(`/api/data_validation_rules/${uid}`, dataValidationRuleRequest, options);
};
export const getUpdateDataValidationRuleMutationOptions = (options) => {
    const mutationKey = ['updateDataValidationRule'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateDataValidationRule(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a data validation rule
*/
export const useUpdateDataValidationRule = (options) => {
    const mutationOptions = getUpdateDataValidationRuleMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a data validation rule
*/
export const deleteDataValidationRule = (uid, options) => {
    return axios.delete(`/api/data_validation_rules/${uid}`, options);
};
export const getDeleteDataValidationRuleMutationOptions = (options) => {
    const mutationKey = ['deleteDataValidationRule'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteDataValidationRule(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a data validation rule
*/
export const useDeleteDataValidationRule = (options) => {
    const mutationOptions = getDeleteDataValidationRuleMutationOptions(options);
    return useMutation(mutationOptions);
};
