import * as React from "react";
import { Article } from "@mui/icons-material";
import { List, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import { useListQflSubmissionsNewcrm } from "@app/orval/api/questions-for-the-listing";
import QFSListItem from "./QFLListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Forms = (props) => {
    var _a, _b, _c;
    const { sellerLeadUid } = props;
    const listQFLsApi = useListQflSubmissionsNewcrm({ seller_lead_uid: sellerLeadUid });
    const qfls = (_b = (_a = listQFLsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    return (React.createElement(CollapsibleSection, { icon: React.createElement(Article, null), title: "Forms", count: (_c = qfls === null || qfls === void 0 ? void 0 : qfls.length) !== null && _c !== void 0 ? _c : undefined },
        listQFLsApi.isFetching && (React.createElement(Typography, { variant: "body2", style: { color: "#888" } }, "Loading...")),
        listQFLsApi.error && React.createElement(CoreError, { error: listQFLsApi.error }),
        (qfls === null || qfls === void 0 ? void 0 : qfls.length) === 0 && React.createElement(Typography, { variant: "body2" }, "No forms submitted"),
        qfls && qfls.length > 0 && (React.createElement(List, { dense: true }, qfls.map((qfl) => (React.createElement(QFSListItem, { key: qfl.uid, qfl: qfl })))))));
};
export default Forms;
