import * as React from "react";
import { Divider, MenuItem } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { useGetBuyerLead } from "@app/orval/api/buyer-leads";
import { useListQfos } from "@app/orval/api/forms-questions-for-the-offer";
const BuyerLeadActionsMenuFormsSection = (props) => {
    var _a, _b, _c, _d;
    const { buyerLead } = props;
    const getBuyerLeadApi = useGetBuyerLead(buyerLead.uid, {
        query: { enabled: !buyerLead.properties, staleTime: 1000 * 30, gcTime: 1000 * 30 },
    });
    const buyerLeadFull = (_b = (_a = getBuyerLeadApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const psps = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = buyerLead.properties) !== null && _a !== void 0 ? _a : buyerLeadFull === null || buyerLeadFull === void 0 ? void 0 : buyerLeadFull.properties) !== null && _b !== void 0 ? _b : []).map((p) => p.psps).flat();
    }, [buyerLead, buyerLeadFull]);
    const listQFOsAPI = useListQfos({ buyer_lead_uid: buyerLead.uid }, { query: { staleTime: 1000 * 30, gcTime: 1000 * 30 } });
    const qfos = (_d = (_c = listQFOsAPI.data) === null || _c === void 0 ? void 0 : _c.data.map((x) => {
        var _a;
        return (Object.assign(Object.assign({}, x), { created_at: x.created, property_address: (_a = x.fields["Property Address"]) !== null && _a !== void 0 ? _a : null }));
    })) !== null && _d !== void 0 ? _d : null;
    return psps.length > 0 || (qfos && qfos.length > 0) ? (React.createElement(React.Fragment, null,
        React.createElement(Divider, null),
        psps.map((psp) => (React.createElement(MenuItem, { key: psp.uid, component: RouterLink, to: `/matrix/${psp.uid}` },
            "Open PSP: ",
            psp.property_address))),
        (qfos !== null && qfos !== void 0 ? qfos : []).map((qfo) => (React.createElement(MenuItem, { key: qfo.uid, component: RouterLink, to: `/forms/qfo/${qfo.uid}` },
            "Open QFO: ",
            qfo.property_address))))) : null;
};
export default BuyerLeadActionsMenuFormsSection;
