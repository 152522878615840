import * as React from "react";
import { Close, Refresh } from "@mui/icons-material";
import { Divider, Grid2, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import NewsfeedItem from "./NewsfeedItem";
import NewsfeedToggle from "./NewsfeedToggle";
import { visibilityOptionStrings } from "./visibility_options";
const Newsfeed = (props) => {
    var _a;
    const allowedOptions = (_a = props.allowedOptions) !== null && _a !== void 0 ? _a : visibilityOptionStrings;
    const { currentOptions, isLoading, newsfeed, onClose, onRefresh } = props;
    const visibilityOptionsSet = React.useMemo(() => {
        return new Set(currentOptions !== null && currentOptions !== void 0 ? currentOptions : []);
    }, [currentOptions]);
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, { style: { flex: 1 } },
                React.createElement(NewsfeedToggle, { allowedOptions: allowedOptions, currentOptions: currentOptions !== null && currentOptions !== void 0 ? currentOptions : allowedOptions })),
            onRefresh && (React.createElement(Grid2, null,
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Refresh newsfeed") },
                    React.createElement("span", null,
                        React.createElement(IconButton, { size: "small", onClick: onRefresh, tabIndex: -1, disabled: isLoading },
                            React.createElement(Refresh, { fontSize: "small" })))))),
            onClose && (React.createElement(Grid2, null,
                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Close newsfeed") },
                    React.createElement("span", null,
                        React.createElement(IconButton, { size: "small", onClick: onClose, tabIndex: -1 },
                            React.createElement(Close, { fontSize: "small" }))))))),
        React.createElement(Grid2, null, isLoading ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, { container: true, style: { flex: 1, overflow: "auto" }, spacing: 1, mt: 2 }, newsfeed
            .filter((item) => {
            if (currentOptions == null) {
                return true;
            }
            if (item.type === "field" && visibilityOptionsSet.has("Field value updates")) {
                return true;
            }
            if (item.type === "stage" && visibilityOptionsSet.has("Stage changes")) {
                return true;
            }
            if (item.type === "note" && visibilityOptionsSet.has("Note updates")) {
                return true;
            }
            if (item.type === "comment" && visibilityOptionsSet.has("Comments")) {
                return true;
            }
            if (item.type === "file" && visibilityOptionsSet.has("Files")) {
                return true;
            }
            return false;
        })
            .map((item) => (React.createElement(Grid2, { key: item.timestamp, style: { width: "100%" } },
            React.createElement(NewsfeedItem, { item: item })))))));
};
export default Newsfeed;
