/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Create a comment
 */
export const addComment = (commentCreateRequest, options) => {
    return axios.post(`/api/comments`, commentCreateRequest, options);
};
export const getAddCommentMutationOptions = (options) => {
    const mutationKey = ['addComment'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addComment(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a comment
*/
export const useAddComment = (options) => {
    const mutationOptions = getAddCommentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a comment
*/
export const getComment = (uid, options) => {
    return axios.get(`/api/comments/${uid}`, options);
};
export const getGetCommentQueryKey = (uid) => {
    return [`/api/comments/${uid}`];
};
export const getGetCommentQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetCommentQueryKey(uid);
    const queryFn = ({ signal }) => getComment(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a comment
 */
export function useGetComment(uid, options) {
    const queryOptions = getGetCommentQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a comment
 */
export const updateComment = (uid, commentUpdateRequest, options) => {
    return axios.post(`/api/comments/${uid}`, commentUpdateRequest, options);
};
export const getUpdateCommentMutationOptions = (options) => {
    const mutationKey = ['updateComment'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateComment(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a comment
*/
export const useUpdateComment = (options) => {
    const mutationOptions = getUpdateCommentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a comment
*/
export const deleteComment = (uid, options) => {
    return axios.delete(`/api/comments/${uid}`, options);
};
export const getDeleteCommentMutationOptions = (options) => {
    const mutationKey = ['deleteComment'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteComment(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a comment
*/
export const useDeleteComment = (options) => {
    const mutationOptions = getDeleteCommentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count comments
*/
export const countComments = (commentQueryRequest, options) => {
    return axios.post(`/api/comments/count`, commentQueryRequest, options);
};
export const getCountCommentsMutationOptions = (options) => {
    const mutationKey = ['countComments'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countComments(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count comments
*/
export const useCountComments = (options) => {
    const mutationOptions = getCountCommentsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List all comments
*/
export const listComments = (paginatedCommentQueryRequest, options) => {
    return axios.post(`/api/comments/search`, paginatedCommentQueryRequest, options);
};
export const getListCommentsMutationOptions = (options) => {
    const mutationKey = ['listComments'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listComments(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary List all comments
*/
export const useListComments = (options) => {
    const mutationOptions = getListCommentsMutationOptions(options);
    return useMutation(mutationOptions);
};
