import * as React from "react";
import { Close, Maximize, Minimize } from "@mui/icons-material";
import { Box, Fab, InputAdornment, Paper, TextField, MenuItem, Grid2, Typography } from "@mui/material";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
import { formatPhoneNumber, isValidPhoneNumber } from "@app/util/Utils";
import TwilioSMSRecipientSelect from "./TwilioSMSRecipientSelect";
import TwilioSMSConversation from "../../features/apps/sms/conversation/TwilioSMSConversation";
import useTwilioPhonePreference from "../twilio-calls/useTwilioPhonePreference";
const TwilioSMSConversationWrapper = (props) => {
    const { defaultFromPhoneNumber, defaultToPhoneNumber, onClose } = props;
    const [fromPhoneNumber, setFromPhoneNumber] = React.useState(defaultFromPhoneNumber !== null && defaultFromPhoneNumber !== void 0 ? defaultFromPhoneNumber : null);
    const [toPhoneNumber, setToPhoneNumber] = React.useState(defaultToPhoneNumber !== null && defaultToPhoneNumber !== void 0 ? defaultToPhoneNumber : null);
    const [isMinimized, setIsMinimized] = React.useState(false);
    console.log({ defaultFromPhoneNumber, defaultToPhoneNumber, fromPhoneNumber, toPhoneNumber });
    const selectedTwilioPhoneNumber__E164 = React.useMemo(() => {
        if (fromPhoneNumber) {
            return formatPhoneNumber(fromPhoneNumber, { format: "international" });
        }
        return null;
    }, [fromPhoneNumber]);
    const selectedExternalPhoneNumber__E164 = React.useMemo(() => {
        if (toPhoneNumber && isValidPhoneNumber(toPhoneNumber)) {
            return formatPhoneNumber(toPhoneNumber, { format: "international" });
        }
        return null;
    }, [toPhoneNumber]);
    const twilioPreferences = useTwilioPhonePreference();
    React.useEffect(() => {
        const lastSelectedPhone = twilioPreferences.getLastSelectedTwilioPhone();
        if (lastSelectedPhone && !defaultFromPhoneNumber) {
            setFromPhoneNumber(lastSelectedPhone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // allowed phones
    const listTwilioPhonesApi = useListCachedTwilioPhones({ include_agent_phones: true, include_proxy_phones: false, access_only: true }, INFINITE_CACHE_PARAMS);
    const twilioPhones = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listTwilioPhonesApi.data]);
    const handleFromPhoneNumberChanged = React.useCallback((e) => {
        setFromPhoneNumber(e.target.value);
        twilioPreferences.setLastSelectedTwilioPhone(e.target.value);
    }, [twilioPreferences]);
    const handleToPhoneNumberChanged = React.useCallback((phoneNumber) => {
        setToPhoneNumber(phoneNumber);
    }, []);
    const toggleMinimize = React.useCallback(() => {
        setIsMinimized((prev) => !prev);
    }, []);
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 2, style: { height: isMinimized ? undefined : "80vh", pointerEvents: "auto" } },
        React.createElement(Grid2, { style: { flex: 1, overflowY: "scroll", display: isMinimized ? "none" : undefined } },
            React.createElement(Paper, { elevation: 4, style: { height: "calc(100% - 8px)", margin: "4px" } },
                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%" } },
                    React.createElement(Grid2, { style: { background: AppConfig.themeColors.lightestblue } },
                        React.createElement(Box, { p: 2 },
                            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                                React.createElement(Grid2, null,
                                    React.createElement(TextField, { select: true, fullWidth: true, size: "small", slotProps: {
                                            input: {
                                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "From:"),
                                            },
                                            select: {
                                                displayEmpty: true,
                                            },
                                        }, value: fromPhoneNumber !== null && fromPhoneNumber !== void 0 ? fromPhoneNumber : "", onChange: handleFromPhoneNumberChanged },
                                        React.createElement(MenuItem, { value: "" }, "Select a phone"), twilioPhones === null || twilioPhones === void 0 ? void 0 :
                                        twilioPhones.map((phone) => {
                                            var _a;
                                            return (React.createElement(MenuItem, { key: phone.phone_number, value: phone.phone_number }, (_a = phone.friendly_name) !== null && _a !== void 0 ? _a : phone.phone_number));
                                        }))),
                                React.createElement(Grid2, null,
                                    React.createElement(TwilioSMSRecipientSelect, { phoneNumber: toPhoneNumber, onPhoneNumberChanged: handleToPhoneNumberChanged }))))),
                    React.createElement(Grid2, { style: { flex: 1, background: "white", overflowY: "hidden" } },
                        selectedTwilioPhoneNumber__E164 && selectedExternalPhoneNumber__E164 && (React.createElement(TwilioSMSConversation, { fromPhoneNumber: selectedTwilioPhoneNumber__E164, toPhoneNumber: selectedExternalPhoneNumber__E164 })),
                        (!selectedTwilioPhoneNumber__E164 || !selectedExternalPhoneNumber__E164) && (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Grid2, { style: { flex: 1 } }),
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { variant: "h6", style: { color: "#666", textAlign: "center" } }, "Select a phone number")),
                            React.createElement(Grid2, { style: { flex: 1 } }))))))),
        React.createElement(Grid2, { style: { textAlign: "right" } },
            React.createElement(Grid2, { container: true, spacing: 1, direction: "row" },
                React.createElement(Grid2, { style: { flex: 1 } }),
                React.createElement(Grid2, null,
                    React.createElement(Fab, { variant: "circular", onClick: toggleMinimize, size: "small" }, isMinimized ? React.createElement(Maximize, null) : React.createElement(Minimize, null))),
                React.createElement(Grid2, null,
                    React.createElement(Fab, { variant: "circular", onClick: onClose, color: "primary", size: "small" },
                        React.createElement(Close, null)))))));
};
export default TwilioSMSConversationWrapper;
