/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
 * @summary Create a recruits
 */
export const addRecruit = (recruitCreateRequest, options) => {
    return axios.post(`/api/recruits`, recruitCreateRequest, options);
};
export const getAddRecruitMutationOptions = (options) => {
    const mutationKey = ['addRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addRecruit(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a recruits
*/
export const useAddRecruit = (options) => {
    const mutationOptions = getAddRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Add a collaborator
*/
export const addCollaboratorForRecruit = (recruitUid, recruitCollaboratorWriteRequest, options) => {
    return axios.post(`/api/recruits/${recruitUid}/add_collaborator`, recruitCollaboratorWriteRequest, options);
};
export const getAddCollaboratorForRecruitMutationOptions = (options) => {
    const mutationKey = ['addCollaboratorForRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, data } = props !== null && props !== void 0 ? props : {};
        return addCollaboratorForRecruit(recruitUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a collaborator
*/
export const useAddCollaboratorForRecruit = (options) => {
    const mutationOptions = getAddCollaboratorForRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Add an interview
*/
export const addInterviewForRecruit = (recruitUid, interviewWriteRequest, options) => {
    return axios.post(`/api/recruits/${recruitUid}/add_interview`, interviewWriteRequest, options);
};
export const getAddInterviewForRecruitMutationOptions = (options) => {
    const mutationKey = ['addInterviewForRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, data } = props !== null && props !== void 0 ? props : {};
        return addInterviewForRecruit(recruitUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add an interview
*/
export const useAddInterviewForRecruit = (options) => {
    const mutationOptions = getAddInterviewForRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Gmail messages related to the lead

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary List emails for a recruit
*/
export const listEmailsForRecruit = (recruitUid, options) => {
    return axios.get(`/api/recruits/${recruitUid}/emails`, options);
};
export const getListEmailsForRecruitQueryKey = (recruitUid) => {
    return [`/api/recruits/${recruitUid}/emails`];
};
export const getListEmailsForRecruitQueryOptions = (recruitUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListEmailsForRecruitQueryKey(recruitUid);
    const queryFn = ({ signal }) => listEmailsForRecruit(recruitUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(recruitUid) }, queryOptions);
};
/**
 * @summary List emails for a recruit
 */
export function useListEmailsForRecruit(recruitUid, options) {
    const queryOptions = getListEmailsForRecruitQueryOptions(recruitUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Get a presigned URL for uploading a file

_Authorization: none required_
 * @summary Begin a file upload
 */
export const getRecruitFileUploadUrl = (recruitUid, presignedURLBodyRequest, options) => {
    return axios.post(`/api/recruits/${recruitUid}/get_file_upload_url`, presignedURLBodyRequest, options);
};
export const getGetRecruitFileUploadUrlMutationOptions = (options) => {
    const mutationKey = ['getRecruitFileUploadUrl'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, data } = props !== null && props !== void 0 ? props : {};
        return getRecruitFileUploadUrl(recruitUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Begin a file upload
*/
export const useGetRecruitFileUploadUrl = (options) => {
    const mutationOptions = getGetRecruitFileUploadUrlMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Remove a collaborator
*/
export const removeCollaboratorForRecruit = (recruitUid, uid, options) => {
    return axios.delete(`/api/recruits/${recruitUid}/remove_collaborator/${uid}`, options);
};
export const getRemoveCollaboratorForRecruitMutationOptions = (options) => {
    const mutationKey = ['removeCollaboratorForRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, uid } = props !== null && props !== void 0 ? props : {};
        return removeCollaboratorForRecruit(recruitUid, uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a collaborator
*/
export const useRemoveCollaboratorForRecruit = (options) => {
    const mutationOptions = getRemoveCollaboratorForRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Remove an interview
*/
export const removeInterviewForRecruit = (recruitUid, uid, options) => {
    return axios.delete(`/api/recruits/${recruitUid}/remove_interview/${uid}`, options);
};
export const getRemoveInterviewForRecruitMutationOptions = (options) => {
    const mutationKey = ['removeInterviewForRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, uid } = props !== null && props !== void 0 ? props : {};
        return removeInterviewForRecruit(recruitUid, uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove an interview
*/
export const useRemoveInterviewForRecruit = (options) => {
    const mutationOptions = getRemoveInterviewForRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Streak comments related to the recruit

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary List Streak comments for recruit
*/
export const listStreakCommentsForRecruit = (recruitUid, options) => {
    return axios.get(`/api/recruits/${recruitUid}/streak_comments`, options);
};
export const getListStreakCommentsForRecruitQueryKey = (recruitUid) => {
    return [`/api/recruits/${recruitUid}/streak_comments`];
};
export const getListStreakCommentsForRecruitQueryOptions = (recruitUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakCommentsForRecruitQueryKey(recruitUid);
    const queryFn = ({ signal }) => listStreakCommentsForRecruit(recruitUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(recruitUid) }, queryOptions);
};
/**
 * @summary List Streak comments for recruit
 */
export function useListStreakCommentsForRecruit(recruitUid, options) {
    const queryOptions = getListStreakCommentsForRecruitQueryOptions(recruitUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Streak files related to the lead

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Streak files for lead
 */
export const listStreakFilesForRecruit = (recruitUid, options) => {
    return axios.get(`/api/recruits/${recruitUid}/streak_files`, options);
};
export const getListStreakFilesForRecruitQueryKey = (recruitUid) => {
    return [`/api/recruits/${recruitUid}/streak_files`];
};
export const getListStreakFilesForRecruitQueryOptions = (recruitUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakFilesForRecruitQueryKey(recruitUid);
    const queryFn = ({ signal }) => listStreakFilesForRecruit(recruitUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(recruitUid) }, queryOptions);
};
/**
 * @summary List Streak files for lead
 */
export function useListStreakFilesForRecruit(recruitUid, options) {
    const queryOptions = getListStreakFilesForRecruitQueryOptions(recruitUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
 * @summary Update a collaborator
 */
export const updateCollaboratorForRecruit = (recruitUid, uid, recruitCollaboratorWriteRequest, options) => {
    return axios.post(`/api/recruits/${recruitUid}/update_collaborator/${uid}`, recruitCollaboratorWriteRequest, options);
};
export const getUpdateCollaboratorForRecruitMutationOptions = (options) => {
    const mutationKey = ['updateCollaboratorForRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, uid, data } = props !== null && props !== void 0 ? props : {};
        return updateCollaboratorForRecruit(recruitUid, uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a collaborator
*/
export const useUpdateCollaboratorForRecruit = (options) => {
    const mutationOptions = getUpdateCollaboratorForRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Update an interview
*/
export const updateInterviewForRecruit = (recruitUid, uid, interviewWriteRequest, options) => {
    return axios.post(`/api/recruits/${recruitUid}/update_interview/${uid}`, interviewWriteRequest, options);
};
export const getUpdateInterviewForRecruitMutationOptions = (options) => {
    const mutationKey = ['updateInterviewForRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { recruitUid, uid, data } = props !== null && props !== void 0 ? props : {};
        return updateInterviewForRecruit(recruitUid, uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an interview
*/
export const useUpdateInterviewForRecruit = (options) => {
    const mutationOptions = getUpdateInterviewForRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Get a recruit
*/
export const getRecruit = (uid, options) => {
    return axios.get(`/api/recruits/${uid}`, options);
};
export const getGetRecruitQueryKey = (uid) => {
    return [`/api/recruits/${uid}`];
};
export const getGetRecruitQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRecruitQueryKey(uid);
    const queryFn = ({ signal }) => getRecruit(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a recruit
 */
export function useGetRecruit(uid, options) {
    const queryOptions = getGetRecruitQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
 * @summary Update a recruit
 */
export const updateRecruit = (uid, recruitUpdateRequest, options) => {
    return axios.post(`/api/recruits/${uid}`, recruitUpdateRequest, options);
};
export const getUpdateRecruitMutationOptions = (options) => {
    const mutationKey = ['updateRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateRecruit(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a recruit
*/
export const useUpdateRecruit = (options) => {
    const mutationOptions = getUpdateRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Delete a recruit
*/
export const deleteRecruit = (uid, options) => {
    return axios.delete(`/api/recruits/${uid}`, options);
};
export const getDeleteRecruitMutationOptions = (options) => {
    const mutationKey = ['deleteRecruit'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteRecruit(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a recruit
*/
export const useDeleteRecruit = (options) => {
    const mutationOptions = getDeleteRecruitMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Create multiple recruits
*/
export const bulkCreateRecruits = (recruitBulkCreateRequest, options) => {
    return axios.post(`/api/recruits/bulk_create`, recruitBulkCreateRequest, options);
};
export const getBulkCreateRecruitsMutationOptions = (options) => {
    const mutationKey = ['bulkCreateRecruits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkCreateRecruits(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create multiple recruits
*/
export const useBulkCreateRecruits = (options) => {
    const mutationOptions = getBulkCreateRecruitsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Delete multiple recruits
*/
export const bulkDeleteRecruits = (recruitBulkDeleteRequest, options) => {
    return axios.post(`/api/recruits/bulk_delete`, recruitBulkDeleteRequest, options);
};
export const getBulkDeleteRecruitsMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteRecruits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteRecruits(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple recruits
*/
export const useBulkDeleteRecruits = (options) => {
    const mutationOptions = getBulkDeleteRecruitsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Update multiple recruits
*/
export const bulkUpdateRecruits = (recruitBulkUpdateRequest, options) => {
    return axios.post(`/api/recruits/bulk_update`, recruitBulkUpdateRequest, options);
};
export const getBulkUpdateRecruitsMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateRecruits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateRecruits(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple recruits
*/
export const useBulkUpdateRecruits = (options) => {
    const mutationOptions = getBulkUpdateRecruitsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Count recruits
*/
export const countRecruits = (recruitQuerySerializerWithCertainFieldsRemovedDe8fef75d4cf41e0b2a0e58e221c4158Request, options) => {
    return axios.post(`/api/recruits/count`, recruitQuerySerializerWithCertainFieldsRemovedDe8fef75d4cf41e0b2a0e58e221c4158Request, options);
};
export const getCountRecruitsMutationOptions = (options) => {
    const mutationKey = ['countRecruits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countRecruits(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count recruits
*/
export const useCountRecruits = (options) => {
    const mutationOptions = getCountRecruitsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Count by stage
*/
export const countRecruitsByStage = (recruitQuerySerializerWithCertainFieldsRemovedDe8fef75d4cf41e0b2a0e58e221c4158Request, options) => {
    return axios.post(`/api/recruits/count_by_stage`, recruitQuerySerializerWithCertainFieldsRemovedDe8fef75d4cf41e0b2a0e58e221c4158Request, options);
};
export const getCountRecruitsByStageMutationOptions = (options) => {
    const mutationKey = ['countRecruitsByStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countRecruitsByStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count by stage
*/
export const useCountRecruitsByStage = (options) => {
    const mutationOptions = getCountRecruitsByStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Download recruits
*/
export const downloadRecruits = (options) => {
    return axios.post(`/api/recruits/download`, undefined, options);
};
export const getDownloadRecruitsMutationOptions = (options) => {
    const mutationKey = ['downloadRecruits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return downloadRecruits(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Download recruits
*/
export const useDownloadRecruits = (options) => {
    const mutationOptions = getDownloadRecruitsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Mark a file upload as "finished"

_Authorization: none required_
* @summary Finish a file upload
*/
export const finishRecruitFileUpload = (fileUid, options) => {
    return axios.post(`/api/recruits/finish_file_upload/${fileUid}`, undefined, options);
};
export const getFinishRecruitFileUploadMutationOptions = (options) => {
    const mutationKey = ['finishRecruitFileUpload'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { fileUid } = props !== null && props !== void 0 ? props : {};
        return finishRecruitFileUpload(fileUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Finish a file upload
*/
export const useFinishRecruitFileUpload = (options) => {
    const mutationOptions = getFinishRecruitFileUploadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary Search recruits
*/
export const searchRecruits = (paginatedRecruitQueryRequest, options) => {
    return axios.post(`/api/recruits/search`, paginatedRecruitQueryRequest, options);
};
export const getSearchRecruitsMutationOptions = (options) => {
    const mutationKey = ['searchRecruits'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchRecruits(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search recruits
*/
export const useSearchRecruits = (options) => {
    const mutationOptions = getSearchRecruitsMutationOptions(options);
    return useMutation(mutationOptions);
};
