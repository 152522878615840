import * as React from "react";
import { Cancel, Delete, Save } from "@mui/icons-material";
import { Box, Divider, Grid, Button, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import Loading from "@app/common/Loading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useDeleteViewPurpose, useAddViewPurpose, useGetViewPurpose, useUpdateViewPurpose, useListViewPurposes, } from "@app/orval/api/view-purposes";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { replaceItemInArray } from "@app/util/Utils";
const DEFAULT_FORM_DATA = {
    name: "",
};
const ViewPurposeForm = (props) => {
    const { uid } = props;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [formData, setFormData] = React.useState(DEFAULT_FORM_DATA);
    const getViewPurposeApi = useGetViewPurpose(uid !== null && uid !== void 0 ? uid : "", {
        query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: false }),
    });
    const addViewPurposeApi = useAddViewPurpose();
    const updateViewPurposeApi = useUpdateViewPurpose();
    const deleteViewPurposeApi = useDeleteViewPurpose();
    const listViewPurposesApi = useListViewPurposes({ query: { enabled: false } });
    React.useEffect(() => {
        if (props.uid) {
            if (getViewPurposeApi.data) {
                setFormData(getViewPurposeApi.data.data);
            }
            else {
                getViewPurposeApi
                    .refetch()
                    .then((result) => {
                    var _a, _b;
                    setFormData({
                        name: (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data.name) !== null && _b !== void 0 ? _b : "",
                    });
                })
                    .catch(errorDialog);
            }
        }
        else {
            setFormData(DEFAULT_FORM_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const cancel = React.useCallback(() => {
        navigate("/view-purposes");
    }, [navigate]);
    const deleteViewPurpose = React.useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listViewPurposesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((item) => item.uid !== uid) });
                });
                deleteViewPurposeApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    console.log("deleted view purpose");
                    navigate("/view-purposes");
                })
                    .catch(errorDialog);
            }
        });
    }, [uid, queryClient, listViewPurposesApi.queryKey, deleteViewPurposeApi, confirm, errorDialog, navigate]);
    const saveViewPurpose = React.useCallback(() => {
        if (uid) {
            updateViewPurposeApi
                .mutateAsync({ uid, data: formData })
                .then((result) => {
                queryClient.setQueryData(listViewPurposesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                });
                queryClient.setQueryData(getViewPurposeApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: result.data });
                });
                navigate("/view-purposes");
            })
                .catch(errorDialog);
        }
        else {
            addViewPurposeApi
                .mutateAsync({ data: formData })
                .then((result) => {
                queryClient.setQueryData(listViewPurposesApi.queryKey, (old) => {
                    if (old) {
                        return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                    }
                    return undefined;
                });
                navigate("/view-purposes");
            })
                .catch(errorDialog);
        }
    }, [
        uid,
        queryClient,
        listViewPurposesApi.queryKey,
        addViewPurposeApi,
        updateViewPurposeApi,
        formData,
        navigate,
        errorDialog,
        getViewPurposeApi.queryKey,
    ]);
    const isValid = !!formData.name.trim();
    const isMutating = addViewPurposeApi.status === "pending" ||
        updateViewPurposeApi.status === "pending" ||
        deleteViewPurposeApi.status === "pending";
    const loading = [
        {
            label: "Loading view purposes",
            queryResult: getViewPurposeApi,
        },
    ].filter((_) => !!uid);
    const showInfiniteSpinner = uid ? getViewPurposeApi.status === "pending" : false;
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    return (React.createElement(Grid, { container: true, direction: "column", spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Name", value: formData.name, onChange: setName, slotProps: {
                                htmlInput: {
                                    maxLength: 255,
                                },
                            } }))))),
        React.createElement(Grid, { item: true },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                    uid && (React.createElement(Grid, { item: true },
                        React.createElement(Button, { size: "small", color: "error", tabIndex: -1, disabled: isMutating, startIcon: React.createElement(Delete, { fontSize: "small" }), onClick: deleteViewPurpose }, "Delete"))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { startIcon: React.createElement(Cancel, null), onClick: cancel, disabled: isMutating, tabIndex: -1 }, "Cancel")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { startIcon: React.createElement(Save, null), onClick: saveViewPurpose, disabled: isMutating || !isValid, color: "primary", variant: "outlined", tabIndex: -1 }, "Save")))))));
};
export default ViewPurposeForm;
