import * as React from "react";
import { Link } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
const PageSearchResult = (props) => {
    const { page } = props;
    return (React.createElement(Grid, { style: { alignItems: "center" }, container: true, spacing: 1 },
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, page.icon ? page.icon : React.createElement(Link, null)),
        React.createElement(Grid, { item: true, container: true, style: {
                flex: 1,
            } },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { style: {
                        display: "flex",
                        alignItems: "center",
                    } }, page.name)))));
};
export default PageSearchResult;
