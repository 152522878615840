import * as React from "react";
import { CloudDownload, Delete } from "@mui/icons-material";
import { Grid2, IconButton, List, ListItem, ListItemText, MenuItem, Tooltip } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useListCloudStorageBlobs, downloadFileFromCloudStorage } from "@app/orval/api/google-cloud";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
import { downloadBlob } from "@app/util/Utils";
import { getBlobComponentChildren } from "../utils/form";
const GCPAttachments = (props) => {
    var _a, _b;
    const { gcpAttachments, onAttachmentRemoved, onAttachmentUpdated } = props;
    const [currentlyDownloadingGCPAttachments, setCurrentlyDownloadingGCPAttachments] = React.useState(new Set());
    const listBlobsApi = useListCloudStorageBlobs({ prefix: "dash-daily/", files_only: true }, INFINITE_CACHE_PARAMS);
    const gcpBlobs = (_b = (_a = listBlobsApi.data) === null || _a === void 0 ? void 0 : _a.data.filter((x) => x.name.includes("current"))) !== null && _b !== void 0 ? _b : null;
    const handleBlobSelected = React.useCallback((index, prefix) => (name) => {
        onAttachmentUpdated(index, prefix, name);
    }, [onAttachmentUpdated]);
    const downloadGCPAttachment = React.useCallback((index) => () => {
        const gcpAttachment = gcpAttachments[index];
        setCurrentlyDownloadingGCPAttachments((prev) => new Set(prev).add(gcpAttachment.blob_name));
        downloadFileFromCloudStorage({
            blob_name: gcpAttachment.blob_name,
        })
            .then((result) => {
            downloadBlob(result.data.file, result.data.filename, result.data.mime_type, "_blank");
        })
            .catch(enqueueErrorSnackbar)
            .then(() => {
            setCurrentlyDownloadingGCPAttachments((prev) => {
                const next = new Set(prev);
                next.delete(gcpAttachment.blob_name);
                return next;
            });
        });
    }, [gcpAttachments]);
    const removeGCPAttachment = React.useCallback((indx) => () => {
        onAttachmentRemoved(indx);
    }, [onAttachmentRemoved]);
    return (React.createElement(List, { dense: true }, gcpAttachments.map((attachment, indx) => {
        const attachmentBlobSplit = attachment.blob_name.split("/");
        const attachmentDropdowns = attachmentBlobSplit.map((name, index) => {
            const previousComponent = index === 0 ? "" : attachmentBlobSplit[index - 1];
            const nameOptions = getBlobComponentChildren((gcpBlobs !== null && gcpBlobs !== void 0 ? gcpBlobs : [])
                .filter((blob) => {
                return blob.name.includes(attachmentBlobSplit.slice(0, index).join("/"));
            })
                .map((blob) => {
                return blob.name;
            }), previousComponent === "" ? undefined : previousComponent);
            const prefix = name === "" ? attachment.blob_name : attachment.blob_name.split(name)[0];
            return (React.createElement(Grid2
            // (OK here because it's not reorderable)
            // eslint-disable-next-line react/no-array-index-key
            , { 
                // (OK here because it's not reorderable)
                // eslint-disable-next-line react/no-array-index-key
                key: `gcp_attachment_blob_component_${name}_${attachment.blob_name}_${index}`, size: 3, style: { padding: "4px" } },
                React.createElement(CoreChoiceField, { variant: "standard", 
                    // label="Folder"
                    required: true, fullWidth: true, value: name, onChoiceChange: handleBlobSelected(indx, prefix), style: { paddingRight: "8px" } }, nameOptions.map((item) => (React.createElement(MenuItem, { key: item, value: item }, item))))));
        });
        return (
        // (OK here because it's not reorderable)
        React.createElement(ListItem, { key: indx, secondaryAction: React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: "Preview attachment" },
                    React.createElement(IconButton, { onClick: downloadGCPAttachment(indx), disabled: currentlyDownloadingGCPAttachments.has(attachment.blob_name) },
                        React.createElement(CloudDownload, null))),
                React.createElement(Tooltip, { title: "Remove attachment" },
                    React.createElement(IconButton, { onClick: removeGCPAttachment(indx) },
                        React.createElement(Delete, null)))) },
            React.createElement(ListItemText, { primary: React.createElement(Grid2, { container: true }, attachmentDropdowns), style: { paddingRight: "35px" } })));
    })));
};
export default GCPAttachments;
