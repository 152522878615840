/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all teams
 */
export const listTeams = (options) => {
    return axios.get(`/api/teams`, options);
};
export const getListTeamsQueryKey = () => {
    return [`/api/teams`];
};
export const getListTeamsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTeamsQueryKey();
    const queryFn = ({ signal }) => listTeams(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all teams
 */
export function useListTeams(options) {
    const queryOptions = getListTeamsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create a team
 */
export const addTeam = (teamWriteRequest, options) => {
    return axios.post(`/api/teams`, teamWriteRequest, options);
};
export const getAddTeamMutationOptions = (options) => {
    const mutationKey = ['addTeam'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addTeam(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a team
*/
export const useAddTeam = (options) => {
    const mutationOptions = getAddTeamMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Get a team
*/
export const getTeam = (uid, options) => {
    return axios.get(`/api/teams/${uid}`, options);
};
export const getGetTeamQueryKey = (uid) => {
    return [`/api/teams/${uid}`];
};
export const getGetTeamQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTeamQueryKey(uid);
    const queryFn = ({ signal }) => getTeam(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a team
 */
export function useGetTeam(uid, options) {
    const queryOptions = getGetTeamQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Update a team
 */
export const updateTeam = (uid, teamWriteRequest, options) => {
    return axios.post(`/api/teams/${uid}`, teamWriteRequest, options);
};
export const getUpdateTeamMutationOptions = (options) => {
    const mutationKey = ['updateTeam'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateTeam(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a team
*/
export const useUpdateTeam = (options) => {
    const mutationOptions = getUpdateTeamMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a team
*/
export const deleteTeam = (uid, options) => {
    return axios.delete(`/api/teams/${uid}`, options);
};
export const getDeleteTeamMutationOptions = (options) => {
    const mutationKey = ['deleteTeam'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteTeam(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a team
*/
export const useDeleteTeam = (options) => {
    const mutationOptions = getDeleteTeamMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List roles
*/
export const listTeamRoles = (options) => {
    return axios.get(`/api/teams/roles`, options);
};
export const getListTeamRolesQueryKey = () => {
    return [`/api/teams/roles`];
};
export const getListTeamRolesQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTeamRolesQueryKey();
    const queryFn = ({ signal }) => listTeamRoles(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List roles
 */
export function useListTeamRoles(options) {
    const queryOptions = getListTeamRolesQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
