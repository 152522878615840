import * as React from "react";
import { Alert, Avatar, Box, Button, Checkbox, FormControlLabel, Grid2, MenuItem, TextField, Typography, } from "@mui/material";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import Search from "@app/entrypoints/brokerage/container/Header/Search/Search";
import { MailMergeContext } from "@app/entrypoints/brokerage/context/mail-merge/MailMergeContext";
import useSession from "@app/hooks/useSession";
import { useListMailschedule4Settings } from "@app/orval/api/mail-schedule-4-settings";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { searchUsersBasic } from "@app/orval/api/users";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { getInitials } from "@app/util/Utils";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import { MAIL_SCHEDULE_ENTITY_TYPES } from "../utils/utils";
const MailScheduleRun = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const [selectedPipeline, setSelectedPipeline] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [selectedBox, setSelectedBox] = React.useState(null);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [isTestModeEnabled, setIsTestModeEnabled] = React.useState(true);
    const [shouldIgnoreDisabledSettings, setShouldIgnoreDisabledSettings] = React.useState(false);
    const [isRunning, setIsRunning] = React.useState(false);
    const navigate = useNavigate();
    const context = React.useContext(MailMergeContext);
    const session = useSession();
    const userIsDeveloper = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isDeveloper;
    const handleSelectedPipelineChanged = React.useCallback((e) => {
        setSelectedPipeline(e.target.value);
        setSelectedBox(null);
        setSelectedTemplate(null);
    }, []);
    const handleSelectedUserChanged = React.useCallback((e) => {
        setSelectedUser(e.target.value);
    }, []);
    const handleSelectedBoxChanged = React.useCallback((result) => {
        setSelectedBox(result);
    }, []);
    const handleSelectedTemplateChanged = React.useCallback((e) => {
        setSelectedTemplate(e.target.value);
    }, []);
    const handleTestModeChanged = React.useCallback((_, checked) => {
        setIsTestModeEnabled(checked);
        if (!checked) {
            setShouldIgnoreDisabledSettings(false);
        }
    }, []);
    const handleShouldIgnoreDisabledSettingsChanged = React.useCallback((_, checked) => {
        setShouldIgnoreDisabledSettings(checked);
    }, []);
    const listSettingsApi = useListMailschedule4Settings();
    const settings = (_c = (_b = listSettingsApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null;
    const listUsersApi = usePostQuery(searchUsersBasic, { limit: 1000, disable_sort: true }, REFETCH_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return ((_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [(_d = listUsersApi.data) === null || _d === void 0 ? void 0 : _d.data]);
    const listTemplatesApi = useListMailschedule4Templates({ entity_type: selectedPipeline !== null && selectedPipeline !== void 0 ? selectedPipeline : undefined }, Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: !!selectedPipeline }) }));
    const templates = (_f = (_e = listTemplatesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const runMailSchedule = React.useCallback(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        setIsRunning(true);
        context
            .runMailMerge({
            data: {
                entity_type: selectedPipeline !== null && selectedPipeline !== void 0 ? selectedPipeline : undefined,
                entity_uids: selectedBox
                    ? [
                        (_m = (_k = (_h = (_f = (_d = (_b = (_a = selectedBox.buyerContract) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : (_c = selectedBox.buyerLead) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : (_e = selectedBox.recruit) === null || _e === void 0 ? void 0 : _e.uid) !== null && _f !== void 0 ? _f : (_g = selectedBox.sellerContract) === null || _g === void 0 ? void 0 : _g.uid) !== null && _h !== void 0 ? _h : (_j = selectedBox.sellerLead) === null || _j === void 0 ? void 0 : _j.uid) !== null && _k !== void 0 ? _k : (_l = selectedBox.user) === null || _l === void 0 ? void 0 : _l.uid) !== null && _m !== void 0 ? _m : "",
                    ]
                    : undefined,
                is_test: isTestModeEnabled,
                template_uids: selectedTemplate ? [selectedTemplate] : undefined,
                run_for_uids: selectedUser ? [selectedUser] : undefined,
                should_ignore_disabled_settings: shouldIgnoreDisabledSettings && isTestModeEnabled,
            },
            onExecutionStarted: (res) => {
                navigate(`/ms4/logs/detailed?uid=${res.uid}`);
            },
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
            setIsRunning(false);
        });
    }, [
        context,
        isTestModeEnabled,
        navigate,
        selectedBox,
        selectedPipeline,
        selectedTemplate,
        selectedUser,
        shouldIgnoreDisabledSettings,
    ]);
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid2, { container: true, spacing: 4, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(Alert, { severity: "info" },
                    React.createElement(Typography, null, "The Mail Schedule runs automatically in the background, but you can use this widget to manually run the Mail Schedule if necessary."))),
            React.createElement(Grid2, null,
                React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
                    ((_g = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.hasRole(["Superadmin", "Admin"])) && (React.createElement(Grid2, null,
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Agent", select: true, value: selectedUser !== null && selectedUser !== void 0 ? selectedUser : "", onChange: handleSelectedUserChanged, slotProps: {
                                select: {
                                    displayEmpty: true,
                                },
                                inputLabel: {
                                    shrink: true,
                                },
                            } },
                            React.createElement(MenuItem, { key: -1, value: "" }, "All Agents"),
                            (users !== null && users !== void 0 ? users : []).map((user) => {
                                var _a;
                                return (React.createElement(MenuItem, { key: user.uid, value: user.uid },
                                    React.createElement(Grid2, { container: true, spacing: 1 },
                                        React.createElement(Grid2, null,
                                            React.createElement(Avatar, { src: (_a = user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "24px", width: "24px", fontSize: "0.75rem" } }, getInitials(user.first_name, user.last_name))),
                                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, [user.first_name, user.last_name].filter((obj) => obj).join(" ")))));
                            })))),
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Pipeline", select: true, value: selectedPipeline !== null && selectedPipeline !== void 0 ? selectedPipeline : "", onChange: handleSelectedPipelineChanged, slotProps: {
                                select: {
                                    displayEmpty: true,
                                },
                                inputLabel: {
                                    shrink: true,
                                },
                            } },
                            React.createElement(MenuItem, { key: -1, value: "" }, "All Pipelines"),
                            MAIL_SCHEDULE_ENTITY_TYPES.filter((pipeline) => {
                                var _a;
                                return userIsDeveloper ||
                                    ((_a = settings === null || settings === void 0 ? void 0 : settings.entity_type_settings.find((x) => x.entity_type === pipeline)) === null || _a === void 0 ? void 0 : _a.is_enabled);
                            }).map((pipeline) => (React.createElement(MenuItem, { key: pipeline, value: pipeline }, pipeline))))),
                    React.createElement(Grid2, null,
                        React.createElement(Search
                        // @ts-expect-error: string not assignable to type
                        , { 
                            // @ts-expect-error: string not assignable to type
                            searchTypes: [
                                selectedPipeline === "Buyer Contract" ? "buyerContracts" : null,
                                selectedPipeline === "Buyer Lead" ? "buyerLeads" : null,
                                selectedPipeline === "Listing" ? "sellerContracts" : null,
                                selectedPipeline === "Seller Lead" ? "sellerLeads" : null,
                                selectedPipeline === "User" ? "users" : null,
                                selectedPipeline === "Recruit" ? "recruits" : null,
                            ].filter((o) => o), onItemSelected: handleSelectedBoxChanged, TextFieldProps: {
                                size: "small",
                                placeholder: "Search for a box",
                            }, variant: "standard", defaultSearchString: (_u = (_s = (_q = (_o = (_l = (_j = (_h = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.buyerContract) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : (_k = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.buyerLead) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : (_m = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.contact) === null || _m === void 0 ? void 0 : _m.name) !== null && _o !== void 0 ? _o : (_p = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.recruit) === null || _p === void 0 ? void 0 : _p.name) !== null && _q !== void 0 ? _q : (_r = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.sellerContract) === null || _r === void 0 ? void 0 : _r.name) !== null && _s !== void 0 ? _s : (_t = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.sellerLead) === null || _t === void 0 ? void 0 : _t.name) !== null && _u !== void 0 ? _u : (_v = selectedBox === null || selectedBox === void 0 ? void 0 : selectedBox.user) === null || _v === void 0 ? void 0 : _v.name, showNoResults: !selectedBox })),
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { variant: "standard", fullWidth: true, label: "Template", select: true, value: selectedTemplate !== null && selectedTemplate !== void 0 ? selectedTemplate : "", onChange: handleSelectedTemplateChanged, slotProps: {
                                select: {
                                    displayEmpty: true,
                                },
                                inputLabel: {
                                    shrink: true,
                                },
                            } },
                            React.createElement(MenuItem, { key: -1, value: "" }, "All Templates"),
                            (templates !== null && templates !== void 0 ? templates : [])
                                .filter((template_) => template_.is_active && template_.entity_type === selectedPipeline)
                                .map((template_) => (React.createElement(MenuItem, { key: template_.uid, value: template_.uid }, template_.name))))),
                    React.createElement(Grid2, null,
                        React.createElement(FormControlLabel, { label: isTestModeEnabled
                                ? "Test mode is ON; running the Mail Schedule only shows you what would send, but doesn't process any templates"
                                : "Test mode is OFF; running the Mail Schedule will actually process templates", control: React.createElement(Checkbox, { size: "small", onChange: handleTestModeChanged, color: "primary", checked: isTestModeEnabled }) })),
                    React.createElement(Grid2, null,
                        React.createElement(FormControlLabel, { label: shouldIgnoreDisabledSettings
                                ? 'Mail Schedule will be run for "disabled" pipelines and templates. This can only be activated when Test mode is ON. Useful for testing Mail Schedule templates before they\'re actually "live".'
                                : 'Mail Schedule will not be run for "disabled" pipelines or templates.', control: React.createElement(Checkbox, { size: "small", onChange: handleShouldIgnoreDisabledSettingsChanged, color: "primary", checked: isTestModeEnabled && shouldIgnoreDisabledSettings, disabled: !isTestModeEnabled }) })))),
            React.createElement(Grid2, null,
                React.createElement(Button, { onClick: runMailSchedule, disabled: isRunning, color: "primary", variant: "contained" }, "Run Mail Schedule")))));
};
export default MailScheduleRun;
