import * as React from "react";
import { Box, Grid2 } from "@mui/material";
import DateFilters from "./DateFilters";
import OfficeFilter from "./OfficeFilter";
import SourceFilter from "./SourceFilter";
import StageFilter from "./StageFilter";
import TeamFilter from "./TeamFilter";
import UsersFilter from "./UsersFilter";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const TransactionFilters = () => {
    const agentDashboardFilters = useAgentDashboardFilters();
    return (React.createElement(Box, { p: 2, pb: 0, pt: 0 },
        React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
            agentDashboardFilters.canAccessUsersFilter && (React.createElement(Grid2, null,
                React.createElement(UsersFilter, null))),
            agentDashboardFilters.canAccessOfficeFilter && (React.createElement(Grid2, null,
                React.createElement(OfficeFilter, null))),
            agentDashboardFilters.canAccessTeamsFilter && (React.createElement(Grid2, null,
                React.createElement(TeamFilter, null))),
            React.createElement(Grid2, null,
                React.createElement(SourceFilter, null)),
            React.createElement(Grid2, null,
                React.createElement(StageFilter, null)),
            React.createElement(Grid2, null,
                React.createElement(DateFilters, null)))));
};
export default TransactionFilters;
