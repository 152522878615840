var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Portal } from "@mui/base/Portal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import * as Sentry from "@sentry/react";
import axios from "axios";
import * as ReactDOM from "react-dom/client";
import parentTheme from "@app/common/theme";
import AppConfig, { IGNORABLE_ERRORS } from "@app/util/AppConfig";
import { getReadableErrorMessage } from "@app/util/Utils";
import MainApp from "./App";
const SENTRY_DSN = AppConfig.env.sentryDSN;
if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        ignoreErrors: IGNORABLE_ERRORS,
    });
    // override the behavior of console.error to play nice with Sentry
    const standardErrorFunc = (() => console.error)();
    console.error = (error, ...args) => {
        const errorMessage = getReadableErrorMessage(error);
        standardErrorFunc(errorMessage, ...args);
    };
}
const MUI_PREMIUM_LICENSE_KEY = AppConfig.env.muiPremiumLicenseKey;
if (MUI_PREMIUM_LICENSE_KEY) {
    LicenseInfo.setLicenseKey(MUI_PREMIUM_LICENSE_KEY);
}
/**
 * Saturates the Fetch API (i.e. `window.fetch`) with the specified default headers,
 * as well as the specified default base URL (it'll just be "/" if not specified).
 *
 * Note that since the `window.fetch` object is global to the window, it's not really best
 * practice to set the default headers for _everything_. If we're using the portal as a web app,
 * it's OK because the React portal is basically the only thing in the window, but if it's being used
 * as a Chrome Extension, we could have issues. So the specified default base URL and headers
 * will only apply if the `x-dashcrm-request` header is present.
 *
 * @param options
 */
const setupFetch = (options) => {
    const { apiBaseUrl, apiHeaders } = options;
    // CREDIT: https://stackoverflow.com/questions/44820568/set-default-header-for-every-fetch-request
    const originalFetch = window.fetch;
    window.fetch = (input, init) => __awaiter(void 0, void 0, void 0, function* () {
        let isInternalRequest = false;
        if (init === null || init === void 0 ? void 0 : init.headers) {
            const internalRequestHeaderName = "x-dashcrm-request";
            if (init.headers instanceof Headers) {
                isInternalRequest = !!init.headers.get(internalRequestHeaderName);
            }
            else if (init.headers instanceof Array) {
                isInternalRequest = !!init.headers.find((header) => header[0].toLowerCase().trim() === internalRequestHeaderName);
            }
            else {
                isInternalRequest = !!init.headers[internalRequestHeaderName];
            }
        }
        if (isInternalRequest) {
            if (!init) {
                // eslint-disable-next-line no-param-reassign
                init = {};
            }
            if (!init.headers) {
                // eslint-disable-next-line no-param-reassign
                init.headers = new Headers();
            }
            // init.headers could be:
            //   `A Headers object, an object literal,
            //    or an array of two-item arrays to set request’s headers.`
            for (const [key, value] of Object.entries(apiHeaders !== null && apiHeaders !== void 0 ? apiHeaders : {})) {
                if (init.headers instanceof Headers) {
                    init.headers.append(key, value);
                }
                else if (init.headers instanceof Array) {
                    init.headers.push([key, value]);
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    init.headers[key] = value;
                }
            }
            if (typeof input === "string" && input.startsWith("/") && apiBaseUrl != null) {
                // eslint-disable-next-line no-param-reassign
                input = apiBaseUrl + input;
            }
        }
        return originalFetch(input, init);
    });
};
/**
 * Saturates axios with the specified default headers as well as the specified default base URL
 * (it'll be "/" if not specified).
 *
 * @param options
 */
const setupAxios = (options) => {
    const { apiBaseUrl, apiHeaders } = options;
    if (apiBaseUrl) {
        axios.defaults.baseURL = apiBaseUrl;
    }
    if (apiHeaders) {
        for (const [key, value] of Object.entries(apiHeaders)) {
            axios.defaults.headers.common[key] = value;
            axios.defaults.headers.get[key] = value;
        }
    }
    axios.defaults.headers.common["x-dash-frontend-version"] = AppConfig.env.version;
    axios.defaults.headers.get["x-dash-frontend-version"] = AppConfig.env.version;
};
const createRoot = (querySelector, options) => {
    var _a, _b, _c, _d, _e;
    const apiBaseUrl = options === null || options === void 0 ? void 0 : options.apiBaseUrl;
    const apiHeaders = options === null || options === void 0 ? void 0 : options.apiHeaders;
    const showSidebar = (_a = options === null || options === void 0 ? void 0 : options.showSidebar) !== null && _a !== void 0 ? _a : false;
    const showHeader = (_b = options === null || options === void 0 ? void 0 : options.showHeader) !== null && _b !== void 0 ? _b : false;
    const routerType = (_c = options === null || options === void 0 ? void 0 : options.routerType) !== null && _c !== void 0 ? _c : "memory";
    const routerBasename = (_d = options === null || options === void 0 ? void 0 : options.routerBasename) !== null && _d !== void 0 ? _d : "dashcrm";
    const initialRoute = options === null || options === void 0 ? void 0 : options.initialRoute;
    const forceSmallDevice = (_e = options === null || options === void 0 ? void 0 : options.forceSmallDevice) !== null && _e !== void 0 ? _e : false;
    const wrapperProps = options === null || options === void 0 ? void 0 : options.wrapperProps;
    const rootEl = document.querySelector(querySelector);
    console.log("creating root", options);
    // @ts-expect-error: Element not assignable to type
    AppConfig.rootElement = rootEl;
    console.log("creating root", { querySelector, rootEl, initialRoute });
    const root = ReactDOM.createRoot(rootEl);
    setupAxios({ apiBaseUrl, apiHeaders });
    if (apiBaseUrl !== null && apiBaseUrl !== void 0 ? apiBaseUrl : apiHeaders) {
        setupFetch({ apiBaseUrl, apiHeaders });
    }
    const theme = forceSmallDevice
        ? createTheme(Object.assign(Object.assign({}, parentTheme), { breakpoints: {
                values: {
                    xs: 0,
                    sm: Infinity,
                    md: Infinity,
                    lg: Infinity,
                    xl: Infinity,
                },
            } }))
        : parentTheme;
    console.log({ theme });
    root.render(React.createElement(Portal, { container: rootEl },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(MainApp, { routerBasename: routerBasename, routerType: routerType, showSidebar: showSidebar, showHeader: showHeader, initialRoute: initialRoute, wrapperProps: wrapperProps }))));
};
export default createRoot;
