import * as React from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid2, Radio, RadioGroup, TextField, } from "@mui/material";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreDateField from "@app/common/CoreDateField";
import CoreDialog from "@app/common/CoreDialog";
import { searchContacts } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA, INFINITE_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../../dashboards/agent-dashboard/usePostQuery";
import { mapping, overridableFieldNames } from "../../buyer-leads/components/ClientAliasDialog";
import ClientsReorder from "../../buyer-leads/components/ClientsReorder";
import { NOT_DUPLICATE_CONTACT_FILTER } from "../../buyer-leads/components/utils";
import { groupBy } from "../../utils/utils";
import ContactAutocomplete from "../box-view/actions/ContactAutocomplete";
const CONTACT_DETAILS_FIELDS = ["First Name", "Last Name", "Email", "Phone", "Date of Birth"];
const AddOrEditClientsDialog = (props) => {
    var _a, _b, _c, _d;
    const { DialogProps, existingClients, onClose, onRemoveClient, onReorderClients, onUpdateClient } = props;
    const [mode, setMode] = React.useState("add");
    const [addClientMode, setAddClientMode] = React.useState("select-existing");
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [contactDetails, setContactDetails] = React.useState(null);
    const [currentlyEditingClientUid, setCurrentlyEditingClientUid] = React.useState(null);
    const paginatedRequestData = React.useMemo(() => {
        return { uids: existingClients.map((c) => c.client.uid) };
    }, [existingClients]);
    const searchContactsApi = usePostQuery(searchContacts, paginatedRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const contacts = (_b = (_a = searchContactsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listContactFieldsApi = useListFields({ entity_type: "Contact" }, INFINITE_CACHE_PARAMS);
    const contactFields = (_d = (_c = listContactFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const contactsByUid = React.useMemo(() => {
        return contacts ? groupBy(contacts, "uid") : null;
    }, [contacts]);
    const contactFieldsByName = React.useMemo(() => {
        if (!contactFields) {
            return {};
        }
        return contactFields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [contactFields]);
    const dialogTitle = React.useMemo(() => {
        switch (mode) {
            case "add":
                return "Add Clients";
            case "edit":
                return "Edit Clients";
            default:
                return "Add or Edit Clients";
        }
    }, [mode]);
    const isValid = React.useMemo(() => {
        if (mode === "add") {
            return !!(selectedContact ||
                ((contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails["First Name"]) &&
                    contactDetails["Last Name"] &&
                    (contactDetails["Email"] || contactDetails["Phone"])));
        }
        if (mode === "edit") {
            return true;
        }
        console.warn("invalid mode:", mode);
        return false;
    }, [contactDetails, mode, selectedContact]);
    const currentlyEditingClient = React.useMemo(() => {
        return existingClients.find((c) => c.client.uid === currentlyEditingClientUid);
    }, [currentlyEditingClientUid, existingClients]);
    const currentlyEditingContact = React.useMemo(() => {
        return currentlyEditingClient ? contactsByUid === null || contactsByUid === void 0 ? void 0 : contactsByUid[currentlyEditingClient.client.uid] : null;
    }, [contactsByUid, currentlyEditingClient]);
    const apiEndpoint = React.useMemo(() => {
        return currentlyEditingClient ? `/api/clients/${currentlyEditingClient.uid}` : "";
    }, [currentlyEditingClient]);
    const onModeChange = React.useCallback((e) => {
        const val = e.target.value;
        setMode(val);
        setContactDetails(null);
    }, []);
    const onAddClientModeChange = React.useCallback((e) => {
        const val = e.target.value;
        setAddClientMode(val);
        setContactDetails(null);
    }, []);
    const onSubmit = React.useCallback(() => {
        if (mode === "add") {
            if (addClientMode === "select-existing") {
                onUpdateClient({ clientUid: selectedContact.uid });
            }
            else if (addClientMode === "create-new") {
                onUpdateClient({ clientData: contactDetails });
            }
            else {
                throw new Error(`Invalid mode ${mode}`);
            }
        }
        else if (mode === "edit") {
            onUpdateClient({});
        }
        else {
            throw new Error(`Invalid mode ${mode}`);
        }
    }, [addClientMode, contactDetails, mode, onUpdateClient, selectedContact]);
    const onSelectedContactChange = React.useCallback((_, val) => {
        setSelectedContact(val);
    }, []);
    const onContactDetailsChange = React.useCallback((fieldName) => (e) => {
        setContactDetails((prev) => {
            if (!prev) {
                return {
                    [fieldName]: e.target.value,
                };
            }
            return Object.assign(Object.assign({}, prev), { [fieldName]: e.target.value });
        });
    }, []);
    const setDateOfBirth = React.useCallback((date) => {
        setContactDetails((prev) => {
            return Object.assign(Object.assign({}, prev), { "Date of Birth": date !== null && date !== void 0 ? date : undefined });
        });
    }, []);
    const handleEditClient = React.useCallback((clientUid) => {
        setCurrentlyEditingClientUid(clientUid);
    }, []);
    const handleRemoveClient = React.useCallback((clientUid) => {
        onRemoveClient(clientUid);
    }, [onRemoveClient]);
    return (React.createElement(CoreDialog, Object.assign({ onClose: onClose }, DialogProps),
        React.createElement(DialogTitle, null, dialogTitle),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column", style: { width: "550px" } },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { size: mode === "add" ? 5 : 12 },
                        React.createElement(RadioGroup, { defaultValue: "add", name: "mode", value: mode, onChange: onModeChange },
                            React.createElement(FormControlLabel, { value: "add", control: React.createElement(Radio, null), label: "Add a contact" }),
                            React.createElement(FormControlLabel, { value: "edit", control: React.createElement(Radio, null), label: "Edit contacts" }))),
                    mode === "add" && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, { size: 2 },
                            React.createElement(Divider, { orientation: "vertical" })),
                        React.createElement(Grid2, { size: 5 },
                            React.createElement(RadioGroup, { defaultValue: "select-existing", name: "mode", value: addClientMode, onChange: onAddClientModeChange },
                                React.createElement(FormControlLabel, { value: "select-existing", control: React.createElement(Radio, null), label: "Select an existing contact" }),
                                React.createElement(FormControlLabel, { value: "create-new", control: React.createElement(Radio, null), label: "Create a new contact" })))))),
                mode === "add" && (React.createElement(React.Fragment, null,
                    addClientMode === "select-existing" && (React.createElement(Grid2, null,
                        React.createElement(ContactAutocomplete, { onChange: onSelectedContactChange, value: selectedContact, query: {
                                filter: NOT_DUPLICATE_CONTACT_FILTER,
                            } }))),
                    addClientMode === "create-new" && (React.createElement(Grid2, null,
                        React.createElement(Box, { pt: 1, pb: 8 },
                            React.createElement(Grid2, { container: true, spacing: 2 }, CONTACT_DETAILS_FIELDS.map((fieldName) => {
                                var _a, _b;
                                if (fieldName.toLowerCase().includes("date")) {
                                    return (React.createElement(Grid2, { key: fieldName, size: { xs: 12, sm: 6 } },
                                        React.createElement(CoreDateField, { label: fieldName, variant: "standard", fullWidth: true, size: "small", date: (_a = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails[fieldName]) !== null && _a !== void 0 ? _a : null, onDateChange: setDateOfBirth, format: "MM/DD/YYYY", formatType: "dayjs", hasPicker: true })));
                                }
                                return (React.createElement(Grid2, { key: fieldName, size: { xs: 12, sm: 6 } },
                                    React.createElement(TextField, { label: fieldName, variant: "standard", fullWidth: true, size: "small", value: (_b = contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails[fieldName]) !== null && _b !== void 0 ? _b : "", onChange: onContactDetailsChange(fieldName), slotProps: {
                                            htmlInput: {
                                                maxLength: 255,
                                            },
                                        } })));
                            }))))))),
                mode === "edit" && (React.createElement(React.Fragment, null,
                    React.createElement(ClientsReorder, { clients: existingClients, onRemoveClient: handleRemoveClient, onReorderClients: onReorderClients, onEditClient: handleEditClient, disableDialog: true }),
                    currentlyEditingClientUid && (React.createElement(Grid2, { container: true, spacing: 2 }, overridableFieldNames.map((f) => {
                        var _a, _b;
                        return (React.createElement(Grid2, { key: f, size: { xs: 12, md: 6 } },
                            React.createElement(DashAutoSaveField, { size: "small", variant: "standard", label: f, fullWidth: true, field: contactFieldsByName[f], apiEndpoint: apiEndpoint, value: (_b = (_a = currentlyEditingClient === null || currentlyEditingClient === void 0 ? void 0 : currentlyEditingClient.client[mapping[f]]) !== null && _a !== void 0 ? _a : currentlyEditingContact === null || currentlyEditingContact === void 0 ? void 0 : currentlyEditingContact.fields[f]) !== null && _b !== void 0 ? _b : "", placeholder: currentlyEditingContact === null || currentlyEditingContact === void 0 ? void 0 : currentlyEditingContact.fields[f], InputLabelProps: {
                                    shrink: (currentlyEditingContact === null || currentlyEditingContact === void 0 ? void 0 : currentlyEditingContact.fields[f]) ? true : undefined,
                                }, 
                                // onSaveBegin={setClientInfo(f)}
                                hasPicker: contactFieldsByName[f].field_type === "date" ? true : undefined })));
                    }))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onSubmit, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default AddOrEditClientsDialog;
