import * as React from "react";
import { Button, Link } from "@mui/material";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const MediaItem = (props) => {
    const { mediaItem } = props;
    const url = `/twilio/media/${mediaItem.sid}`;
    if (mediaItem.content_type.startsWith("image/")) {
        return (React.createElement(Link, { href: url, target: "_blank" },
            React.createElement("img", { src: url, alt: "Media", style: { maxWidth: "100%", maxHeight: "144px", borderRadius: "0.5rem", marginTop: "0.5rem" } })));
    }
    if (["audio/mpeg", "audio/vnd.wav", "audio/ogg"].includes(mediaItem.content_type)) {
        return React.createElement(AudioPlayer, { src: url });
    }
    if (["video/mp4", "video/quicktime"].includes(mediaItem.content_type)) {
        return (React.createElement("video", { height: 160, controls: true, controlsList: "nodownload" },
            React.createElement("track", { default: true, kind: "captions", srcLang: "en" }),
            React.createElement("source", { src: url, type: mediaItem.content_type })));
    }
    return (React.createElement(Button, { component: Link, href: url, target: "_blank" }, "Download Attachment"));
};
export default MediaItem;
