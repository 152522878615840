import * as React from "react";
import { Add } from "@mui/icons-material";
import { Container, Button, Box, Divider, Grid, TextField, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import Search from "@app/entrypoints/brokerage/container/Header/Search/Search";
import { useAddDraftToBox, useAddThreadsToBox } from "@app/orval/api/emails";
const AddThreadsToBox = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { defaultThreadIds = "", mode = "threads", showThreadIdInput = true } = props;
    const [threadIds, setThreadIds] = React.useState(defaultThreadIds);
    const threadIdsArray = React.useMemo(() => {
        return threadIds.split(",").map((threadId) => threadId.trim());
    }, [threadIds]);
    const [selectedEntity, setSelectedEntity] = React.useState(null);
    const navigate = useNavigate();
    const addThreadsToBoxApi = useAddThreadsToBox();
    const addDraftToBoxApi = useAddDraftToBox();
    const handleThreadIdsChanged = React.useCallback((event) => {
        setThreadIds(event.target.value);
    }, []);
    const handleItemSelected = React.useCallback((searchResult) => {
        setSelectedEntity(searchResult);
    }, []);
    React.useEffect(() => {
        setThreadIds(defaultThreadIds);
    }, [defaultThreadIds]);
    const selectedEntityUid = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return ((_m = (_k = (_h = (_f = (_d = (_b = (_a = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.buyerLead) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : (_c = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.sellerLead) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : (_e = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.buyerContract) === null || _e === void 0 ? void 0 : _e.uid) !== null && _f !== void 0 ? _f : (_g = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.sellerContract) === null || _g === void 0 ? void 0 : _g.uid) !== null && _h !== void 0 ? _h : (_j = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.recruit) === null || _j === void 0 ? void 0 : _j.uid) !== null && _k !== void 0 ? _k : (_l = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.user) === null || _l === void 0 ? void 0 : _l.uid) !== null && _m !== void 0 ? _m : "");
    }, [selectedEntity]);
    const addThreadsToBox = React.useCallback(() => {
        console.log({ threadIdsArray });
        if (mode === "drafts") {
            if (threadIdsArray.length !== 1) {
                enqueueErrorSnackbar({
                    message: "You can only attach 1 draft at a time",
                });
            }
            else {
                addDraftToBoxApi
                    .mutateAsync({
                    data: {
                        entity_uid: selectedEntityUid,
                        draft_id: threadIdsArray[0],
                    },
                })
                    .then(() => {
                    navigate(`/boxes/${selectedEntityUid}`);
                })
                    .catch(enqueueErrorSnackbar);
            }
        }
        else {
            addThreadsToBoxApi
                .mutateAsync({
                data: {
                    entity_uid: selectedEntityUid,
                    thread_ids: threadIdsArray,
                },
            })
                .then(() => {
                navigate(`/boxes/${selectedEntityUid}`);
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [addDraftToBoxApi, addThreadsToBoxApi, mode, navigate, selectedEntityUid, threadIdsArray]);
    // must have selected a box, and must have entered at least one thread
    const canAddThreadsToBox = !!selectedEntity && threadIds.trim().length >= 8 && !addThreadsToBoxApi.isPending;
    return (React.createElement(Container, { maxWidth: "sm" },
        React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
            showThreadIdInput && (React.createElement(Grid, { item: true },
                React.createElement(Box, { p: 2 },
                    React.createElement(TextField, { size: "small", label: "Thread IDs", fullWidth: true, helperText: "Enter thread IDs separated by commas", value: threadIds, onChange: handleThreadIdsChanged })))),
            mode === "drafts" && !showThreadIdInput && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2 },
                        React.createElement(Alert, { severity: "info", variant: "outlined" }, "You should only attach drafts to the box if they originated outside the CRM (ex: created from within Gmail or the mail app on your mobile device). If the draft originated within the new CRM (ex: from any of the \"actions\" menus or Mail Merge), the draft will be attached automatically."))),
                React.createElement(Grid, { item: true },
                    React.createElement(Box, { p: 2 },
                        React.createElement(Alert, { severity: "info", variant: "outlined" }, "Drafts are not displayed in the box. The email will only be displayed in the box after the draft is sent."))))),
            showThreadIdInput && (React.createElement(Grid, { item: true },
                React.createElement(Divider, null))),
            !showThreadIdInput && (React.createElement(Grid, { item: true },
                React.createElement(Box, { p: 2, pb: 0 },
                    React.createElement(Typography, null,
                        "Add ",
                        threadIdsArray.length,
                        " ",
                        mode === "drafts" ? "draft" : "thread",
                        threadIdsArray.length === 1 ? "" : "s",
                        " to box")))),
            React.createElement(Grid, { item: true },
                React.createElement(Box, { p: 2 },
                    React.createElement(Search, { searchTypes: ["buyerContracts", "sellerContracts", "buyerLeads", "sellerLeads", "users", "recruits"], variant: "standard", onItemSelected: handleItemSelected, defaultSearchString: (_m = (_k = (_h = (_f = (_d = (_b = (_a = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.buyerLead) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : (_c = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.sellerLead) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (_e = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.buyerContract) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : (_g = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.sellerContract) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : (_j = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.recruit) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : (_l = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.user) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : "", showNoResults: false }))),
            React.createElement(Grid, { item: true },
                React.createElement(Box, { p: 2 },
                    React.createElement(Button, { variant: "contained", color: "primary", disabled: !canAddThreadsToBox || addThreadsToBoxApi.isPending || addDraftToBoxApi.isPending, startIcon: React.createElement(Add, null), onClick: addThreadsToBox }, "Add to box"))))));
};
export default AddThreadsToBox;
