import * as React from "react";
import { ColorLens, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Grid2, IconButton, InputAdornment, TextField } from "@mui/material";
import fontColorContrast from "font-color-contrast";
import { CirclePicker, SketchPicker } from "react-color";
import styled from "styled-components";
import CorePopover from "@app/common/CorePopover";
const StyledGrid = styled(Grid2) `
  border-radius: 8px;

  & .MuiInput-underline:before, .MuiInput-underline:after {
    visibility: hidden;
  }

  & .MuiIconButton-root {
    visibility: hidden;
  }

  &:hover .MuiIconButton-root {
    visibility: visible;
  }

  &:hover .MuiInput-underline:before, .MuiInput-underline:after {
    visibility: visible;
  }
`;
const Stage = (props) => {
    const { dragHandle, hasDetails, onDelete, onUpdate, value } = props;
    const [editPopoverAnchorEl, setEditPopoverAnchorEl] = React.useState(null);
    const [colorPickerAnchorEl, setColorPickerAnchorEl] = React.useState(null);
    const [showDetailedColors, shouldShowDetailedColors] = React.useState(false);
    const openEditPopover = React.useCallback((e) => {
        setEditPopoverAnchorEl(e.currentTarget);
    }, [setEditPopoverAnchorEl]);
    const closeEditPopover = React.useCallback(() => {
        setEditPopoverAnchorEl(null);
    }, [setEditPopoverAnchorEl]);
    const openColorPicker = React.useCallback((e) => {
        setColorPickerAnchorEl(e.currentTarget);
    }, [setColorPickerAnchorEl]);
    const closeColorPicker = React.useCallback(() => {
        setColorPickerAnchorEl(null);
    }, [setColorPickerAnchorEl]);
    const setName = React.useCallback((e) => {
        onUpdate({ name: e.target.value, color: value.color, followupboss_stage_name: value.followupboss_stage_name });
    }, [value, onUpdate]);
    const setColor = React.useCallback((result) => {
        onUpdate({ name: value.name, color: result.hex, followupboss_stage_name: value.followupboss_stage_name });
    }, [value, onUpdate]);
    const setFollowUpBossStageName = React.useCallback((e) => {
        onUpdate({ name: value.name, color: value.color, followupboss_stage_name: e.target.value });
    }, [value, onUpdate]);
    const toggleColors = React.useCallback(() => {
        shouldShowDetailedColors((prev) => !prev);
    }, [shouldShowDetailedColors]);
    const textColor = fontColorContrast(value.color, 0.7);
    const iconColor = textColor === "#000000" ? "#666" : textColor;
    return (React.createElement(StyledGrid, { container: true, spacing: 2, style: { background: value.color, color: textColor, height: "56px" }, wrap: "nowrap" },
        dragHandle && React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } }, dragHandle),
        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
            React.createElement(TextField, { variant: "standard", fullWidth: true, value: value.name, onChange: setName, slotProps: {
                    htmlInput: {
                        maxLength: 255,
                    },
                }, sx: {
                    input: {
                        color: textColor,
                        fontSize: "1rem",
                    },
                    "& .MuiInput-underline:before": { borderBottomColor: textColor },
                    "& .MuiInput-underline:after": { borderBottomColor: textColor },
                } })),
        hasDetails && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { onClick: openEditPopover, tabIndex: -1 },
                React.createElement(Edit, { style: { color: iconColor } })),
            React.createElement(CorePopover, { open: !!editPopoverAnchorEl, anchorEl: editPopoverAnchorEl, onClose: closeEditPopover, slotProps: {
                    paper: {
                        style: {
                            maxWidth: "420px",
                        },
                    },
                } },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, direction: "column", spacing: 4 },
                        React.createElement(Grid2, null,
                            React.createElement(TextField, { variant: "standard", fullWidth: true, value: value.followupboss_stage_name, onChange: setFollowUpBossStageName, slotProps: {
                                    htmlInput: {
                                        maxLength: 255,
                                    },
                                    input: {
                                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Follow Up Boss Stage Name"),
                                    },
                                }, helperText: "If a box is located in this stage in the CRM, it will be moved to this corresponding stage in Follow Up Boss (if the box has a FUB ID set). If blank, the stage will not be changed in Follow Up Boss." }))))))),
        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { onClick: openColorPicker, tabIndex: -1 },
                React.createElement(ColorLens, { style: { color: iconColor } })),
            React.createElement(CorePopover, { open: !!colorPickerAnchorEl, anchorEl: colorPickerAnchorEl, onClose: closeColorPicker },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
                        React.createElement(Grid2, null, showDetailedColors ? (React.createElement(SketchPicker, { color: value.color, onChange: setColor })) : (React.createElement(CirclePicker, { color: value.color, onChange: setColor }))),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { onClick: toggleColors, variant: "outlined" }, showDetailedColors ? "Show less colors" : "Show more colors")),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { onClick: closeColorPicker, variant: "outlined" }, "Done")))))),
        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(IconButton, { onClick: onDelete, tabIndex: -1 },
                React.createElement(Delete, { style: { color: iconColor } })))));
};
export default Stage;
