import * as React from "react";
import { Article } from "@mui/icons-material";
import { List, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { useListRecruitingQfaSubmissonsNewcrm } from "@app/orval/api/recruiting-qfa";
import QFAListItem from "./QFAListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Forms = (props) => {
    var _a, _b, _c;
    const { recruitUid } = props;
    const listQFAsApi = useListRecruitingQfaSubmissonsNewcrm({ recruit_uid: recruitUid });
    const qfas = (_b = (_a = listQFAsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    return (React.createElement(CollapsibleSection, { icon: React.createElement(Article, null), title: "Forms", count: (_c = qfas === null || qfas === void 0 ? void 0 : qfas.length) !== null && _c !== void 0 ? _c : undefined },
        listQFAsApi.isFetching && React.createElement(CoreLoading, null),
        listQFAsApi.error && React.createElement(CoreError, { error: listQFAsApi.error }),
        (qfas === null || qfas === void 0 ? void 0 : qfas.length) === 0 && React.createElement(Typography, { variant: "body2" }, "No forms submitted"),
        qfas && qfas.length > 0 && (React.createElement(List, { dense: true }, qfas.map((qfa) => (React.createElement(QFAListItem, { key: qfa.uid, qfa: qfa })))))));
};
export default Forms;
