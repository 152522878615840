import * as React from "react";
import { Check } from "@mui/icons-material";
import { Alert, Grid, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import { useQueryClient } from "@tanstack/react-query";
import twilioIcon from "@app/assets/img/twilio.png";
import CoreLoading from "@app/common/CoreLoading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useGetTwilioAccess, useUpdateTwilioAccess } from "@app/orval/api/twilio-access";
import { useListCachedTwilioPhones } from "@app/orval/api/twilio-cached-phone-numbers";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { formatPhoneNumber } from "@app/util/Utils";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const TwilioAccess = (props) => {
    var _a, _b, _c, _d, _e;
    const { userTwilioPhoneNumber, userUid } = props;
    const [currentlyUpdatingAccess, setCurrentlyUpdatingAccess] = React.useState(new Set());
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const listTwilioPhonesApi = useListCachedTwilioPhones({ include_agent_phones: false }, INFINITE_CACHE_PARAMS);
    const twilioPhones = (_b = (_a = listTwilioPhonesApi.data) === null || _a === void 0 ? void 0 : _a.data.filter((x) => (x.friendly_name && !x.friendly_name.includes("Agent Phone") && !x.friendly_name.includes("Proxy")) ||
        (userTwilioPhoneNumber &&
            x.phone_number === formatPhoneNumber(userTwilioPhoneNumber, { format: "international" })))) !== null && _b !== void 0 ? _b : null;
    const twilioAccessForUserApi = useGetTwilioAccess({ user_uids: userUid }, REFETCH_CACHE_PARAMS);
    const twilioAccessForUser = (_d = (_c = twilioAccessForUserApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const phonesWithAccess = React.useMemo(() => {
        return twilioAccessForUser ? new Set(twilioAccessForUser.map((x) => x.phone_sid)) : null;
    }, [twilioAccessForUser]);
    const updateTwilioAccessApi = useUpdateTwilioAccess();
    const isLoading = listTwilioPhonesApi.isFetching || twilioAccessForUserApi.isFetching;
    const toggleAccess = React.useCallback((phoneSid) => (_, checked) => {
        setCurrentlyUpdatingAccess((prev) => new Set([...prev, phoneSid]));
        updateTwilioAccessApi
            .mutateAsync({
            data: {
                items: [
                    {
                        user_uid: userUid,
                        phone_sid: phoneSid,
                        has_access: checked,
                    },
                ],
            },
        })
            .then(() => {
            queryClient.setQueryData(twilioAccessForUserApi.queryKey, (old) => {
                if (old) {
                    return Object.assign(Object.assign({}, old), { data: checked
                            ? [...old.data, { phone_sid: phoneSid, user: { uid: userUid } }]
                            : old.data.filter((x) => x.phone_sid !== phoneSid) });
                }
                return undefined;
            });
        })
            .catch(errorDialog)
            .then(() => {
            setCurrentlyUpdatingAccess((prev) => new Set([...prev].filter((x) => x !== phoneSid)));
        });
    }, [queryClient, errorDialog, twilioAccessForUserApi.queryKey, updateTwilioAccessApi, userUid]);
    return (React.createElement(CollapsibleSection, { icon: React.createElement("img", { src: twilioIcon, height: 20, alt: "Twilio" }), title: "Twilio Access", count: (_e = twilioAccessForUser === null || twilioAccessForUser === void 0 ? void 0 : twilioAccessForUser.length) !== null && _e !== void 0 ? _e : undefined, isCollapsedInitially: true },
        listTwilioPhonesApi.error && React.createElement(Alert, { severity: "error" }, JSON.stringify(format(listTwilioPhonesApi.error))),
        twilioAccessForUserApi.error && (React.createElement(Alert, { severity: "error" }, JSON.stringify(format(twilioAccessForUserApi.error)))),
        isLoading && React.createElement(CoreLoading, null),
        twilioPhones && twilioPhones.length === 0 && React.createElement(Typography, { variant: "body2" }, "No Twilio phones found"),
        phonesWithAccess && twilioPhones && twilioPhones.length > 0 && (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" }, twilioPhones.map((phone) => {
            var _a;
            return (React.createElement(Grid, { key: phone.sid, item: true },
                React.createElement(FormControlLabel, { label: (_a = phone.friendly_name) !== null && _a !== void 0 ? _a : phone.phone_number, control: userTwilioPhoneNumber &&
                        phone.phone_number === formatPhoneNumber(userTwilioPhoneNumber, { format: "international" }) ? (React.createElement(Check, { color: "primary", fontSize: "small", style: { marginLeft: "10px", marginRight: "8px" } })) : (React.createElement(Checkbox, { color: "primary", size: "small", onChange: toggleAccess(phone.sid), checked: phonesWithAccess.has(phone.sid), disabled: currentlyUpdatingAccess.has(phone.sid), style: { paddingTop: 0, paddingBottom: 0 } })) })));
        })))));
};
export default TwilioAccess;
