import * as React from "react";
import { Grid, Typography } from "@mui/material";
const StackedFields = (props) => {
    const { firstField, firstLabel, secondField, secondLabel } = props;
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { container: true, item: true, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto", fontSize: "10px" } }, firstLabel)),
            React.createElement(Grid, { item: true, style: { flex: 1 } }, firstField)),
        React.createElement(Grid, { container: true, item: true, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "caption", style: { marginTop: "auto", marginBottom: "auto", fontSize: "10px" } }, secondLabel)),
            React.createElement(Grid, { item: true, style: { flex: 1 } }, secondField))));
};
export default StackedFields;
