/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get Buyer Contract stage changes
 */
export const listNewsfeedBuyerContractStageChanges = (paginatedBuyerContractStageChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/buyer_contract_stage_changes`, paginatedBuyerContractStageChangeQueryRequest, options);
};
export const getListNewsfeedBuyerContractStageChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedBuyerContractStageChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedBuyerContractStageChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Buyer Contract stage changes
*/
export const useListNewsfeedBuyerContractStageChanges = (options) => {
    const mutationOptions = getListNewsfeedBuyerContractStageChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Buyer Lead note updates
*/
export const listNewsfeedBuyerLeadAgentChanges = (paginatedBuyerLeadAgentChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/buyer_lead_agent_changes`, paginatedBuyerLeadAgentChangeQueryRequest, options);
};
export const getListNewsfeedBuyerLeadAgentChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedBuyerLeadAgentChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedBuyerLeadAgentChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Buyer Lead note updates
*/
export const useListNewsfeedBuyerLeadAgentChanges = (options) => {
    const mutationOptions = getListNewsfeedBuyerLeadAgentChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Buyer Lead note updates
*/
export const listNewsfeedBuyerLeadNoteChanges = (paginatedBuyerLeadNoteChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/buyer_lead_note_changes`, paginatedBuyerLeadNoteChangeQueryRequest, options);
};
export const getListNewsfeedBuyerLeadNoteChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedBuyerLeadNoteChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedBuyerLeadNoteChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Buyer Lead note updates
*/
export const useListNewsfeedBuyerLeadNoteChanges = (options) => {
    const mutationOptions = getListNewsfeedBuyerLeadNoteChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Buyer Lead stage changes
*/
export const listNewsfeedBuyerLeadStageChanges = (paginatedBuyerLeadStageChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/buyer_lead_stage_changes`, paginatedBuyerLeadStageChangeQueryRequest, options);
};
export const getListNewsfeedBuyerLeadStageChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedBuyerLeadStageChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedBuyerLeadStageChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Buyer Lead stage changes
*/
export const useListNewsfeedBuyerLeadStageChanges = (options) => {
    const mutationOptions = getListNewsfeedBuyerLeadStageChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Contact note updates
*/
export const listNewsfeedContactNoteChanges = (paginatedContactNoteChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/contact_note_changes`, paginatedContactNoteChangeQueryRequest, options);
};
export const getListNewsfeedContactNoteChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedContactNoteChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedContactNoteChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Contact note updates
*/
export const useListNewsfeedContactNoteChanges = (options) => {
    const mutationOptions = getListNewsfeedContactNoteChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get field value changes
*/
export const listNewsfeedFieldValueUpdates = (paginatedHistorySearchRequest, options) => {
    return axios.post(`/api/newsfeed/field_value_changes`, paginatedHistorySearchRequest, options);
};
export const getListNewsfeedFieldValueUpdatesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedFieldValueUpdates'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedFieldValueUpdates(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get field value changes
*/
export const useListNewsfeedFieldValueUpdates = (options) => {
    const mutationOptions = getListNewsfeedFieldValueUpdatesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Recruit note updates
*/
export const listNewsfeedRecruitNoteChanges = (paginatedRecruitNoteChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/recruit_note_changes`, paginatedRecruitNoteChangeQueryRequest, options);
};
export const getListNewsfeedRecruitNoteChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedRecruitNoteChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedRecruitNoteChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Recruit note updates
*/
export const useListNewsfeedRecruitNoteChanges = (options) => {
    const mutationOptions = getListNewsfeedRecruitNoteChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Recruit stage changes
*/
export const listNewsfeedRecruitStageChanges = (paginatedRecruitStageChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/recruit_stage_changes`, paginatedRecruitStageChangeQueryRequest, options);
};
export const getListNewsfeedRecruitStageChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedRecruitStageChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedRecruitStageChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Recruit stage changes
*/
export const useListNewsfeedRecruitStageChanges = (options) => {
    const mutationOptions = getListNewsfeedRecruitStageChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Seller Contract stage changes
*/
export const listNewsfeedSellerContractStageChanges = (paginatedSellerContractStageChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/seller_contract_stage_changes`, paginatedSellerContractStageChangeQueryRequest, options);
};
export const getListNewsfeedSellerContractStageChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedSellerContractStageChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedSellerContractStageChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Seller Contract stage changes
*/
export const useListNewsfeedSellerContractStageChanges = (options) => {
    const mutationOptions = getListNewsfeedSellerContractStageChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Seller Lead note updates
*/
export const listNewsfeedSellerLeadAgentChanges = (paginatedSellerLeadAgentChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/seller_lead_agent_changes`, paginatedSellerLeadAgentChangeQueryRequest, options);
};
export const getListNewsfeedSellerLeadAgentChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedSellerLeadAgentChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedSellerLeadAgentChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Seller Lead note updates
*/
export const useListNewsfeedSellerLeadAgentChanges = (options) => {
    const mutationOptions = getListNewsfeedSellerLeadAgentChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Seller Lead note updates
*/
export const listNewsfeedSellerLeadNoteChanges = (paginatedSellerLeadNoteChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/seller_lead_note_changes`, paginatedSellerLeadNoteChangeQueryRequest, options);
};
export const getListNewsfeedSellerLeadNoteChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedSellerLeadNoteChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedSellerLeadNoteChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Seller Lead note updates
*/
export const useListNewsfeedSellerLeadNoteChanges = (options) => {
    const mutationOptions = getListNewsfeedSellerLeadNoteChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get Seller Lead stage changes
*/
export const listNewsfeedSellerLeadStageChanges = (paginatedSellerLeadStageChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/seller_lead_stage_changes`, paginatedSellerLeadStageChangeQueryRequest, options);
};
export const getListNewsfeedSellerLeadStageChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedSellerLeadStageChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedSellerLeadStageChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get Seller Lead stage changes
*/
export const useListNewsfeedSellerLeadStageChanges = (options) => {
    const mutationOptions = getListNewsfeedSellerLeadStageChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get User note updates
*/
export const listNewsfeedUserNoteChanges = (paginatedUserNoteChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/user_note_changes`, paginatedUserNoteChangeQueryRequest, options);
};
export const getListNewsfeedUserNoteChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedUserNoteChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedUserNoteChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get User note updates
*/
export const useListNewsfeedUserNoteChanges = (options) => {
    const mutationOptions = getListNewsfeedUserNoteChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get User stage changes
*/
export const listNewsfeedUserStageChanges = (paginatedUserStageChangeQueryRequest, options) => {
    return axios.post(`/api/newsfeed/user_stage_changes`, paginatedUserStageChangeQueryRequest, options);
};
export const getListNewsfeedUserStageChangesMutationOptions = (options) => {
    const mutationKey = ['listNewsfeedUserStageChanges'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return listNewsfeedUserStageChanges(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Get User stage changes
*/
export const useListNewsfeedUserStageChanges = (options) => {
    const mutationOptions = getListNewsfeedUserStageChangesMutationOptions(options);
    return useMutation(mutationOptions);
};
