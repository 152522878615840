import * as React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import Autoreply from "./Autoreply";
import BlockedNumbers from "./BlockedNumbers";
import Calls from "./Calls";
import DoNotCallPage from "./DoNotCallPage";
import OnHoldMusic from "./OnHoldMusic";
import VoicemailPage from "./VoicemailPage";
const getTheme = (parentTheme) => {
    var _a, _b, _c;
    return createTheme(Object.assign(Object.assign({}, parentTheme), { components: Object.assign(Object.assign({}, parentTheme.components), { MuiTab: Object.assign(Object.assign({}, (_a = parentTheme.components) === null || _a === void 0 ? void 0 : _a.MuiTab), { styleOverrides: Object.assign(Object.assign({}, (_c = (_b = parentTheme.components) === null || _b === void 0 ? void 0 : _b.MuiTab) === null || _c === void 0 ? void 0 : _c.styleOverrides), { root: {
                        [parentTheme.breakpoints.up("xs")]: {
                            minWidth: "72px",
                        },
                        [parentTheme.breakpoints.up("sm")]: {
                            minWidth: "160px",
                        },
                    } }) }) }) }));
};
const TwilioPage = () => {
    var _a;
    const params = useParams();
    const page = (_a = params.page) !== null && _a !== void 0 ? _a : "calls";
    useDocumentTitle("Integrations | Twilio");
    return (React.createElement(ThemeProvider, { theme: getTheme },
        React.createElement(Tabs, { value: ["calls", "blocked-numbers", "do-not-call", "autoreply", "voicemail", "on-hold"].indexOf(page), variant: "scrollable", scrollButtons: "auto", indicatorColor: "primary", textColor: "primary", style: {
                width: "100%",
            } },
            React.createElement(Tab, { component: RouterLink, to: "/integrations/twilio/calls", label: "Calls" }),
            React.createElement(Tab, { component: RouterLink, to: "/integrations/twilio/blocked-numbers", label: "Blocked Numbers" }),
            React.createElement(Tab, { component: RouterLink, to: "/integrations/twilio/do-not-call", label: "Do Not Call" }),
            React.createElement(Tab, { component: RouterLink, to: "/integrations/twilio/autoreply", label: "Autoreply" }),
            React.createElement(Tab, { component: RouterLink, to: "/integrations/twilio/voicemail", label: "Voicemail" }),
            React.createElement(Tab, { component: RouterLink, to: "/integrations/twilio/on-hold", label: "On-Hold Music" })),
        React.createElement("div", { style: { height: "calc(100% - 48px)", overflowY: "auto" } },
            page === "calls" && React.createElement(Calls, null),
            page === "blocked-numbers" && React.createElement(BlockedNumbers, null),
            page === "do-not-call" && React.createElement(DoNotCallPage, null),
            page === "autoreply" && React.createElement(Autoreply, null),
            page === "voicemail" && React.createElement(VoicemailPage, null),
            page === "on-hold" && React.createElement(OnHoldMusic, null))));
};
export default TwilioPage;
