import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import RecruitingForm from "../../features/crm/recruiting/RecruitingForm";
const RecruitingFormPage = () => {
    var _a;
    useDocumentTitle("Recruits");
    const params = useParams();
    const searchParams = useSearchParams();
    const tab = (_a = searchParams[0].get("tab")) !== null && _a !== void 0 ? _a : undefined;
    return React.createElement(RecruitingForm, { tab: tab, uid: params.uid });
};
export default RecruitingFormPage;
