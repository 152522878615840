import * as React from "react";
import { Badge } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import omit from "lodash/omit";
import AppConfig from "@app/util/AppConfig";
const StyledBadge = withStyles((theme) => {
    var _a, _b;
    return ({
        badge: {
            boxShadow: `0 0 0 2px ${(_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.background.paper) !== null && _b !== void 0 ? _b : "white"}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                content: "\"\"",
            },
        },
    });
})(Badge);
const DashStatusBadge = (props) => {
    const color = React.useMemo(() => {
        switch (props.status) {
            case "active":
                return AppConfig.themeColors.success;
            case "away":
                return AppConfig.themeColors.warning;
            default:
                ((status) => {
                    throw new Error(`Invalid status: ${status}`);
                })(props.status);
        }
        return "#000000";
    }, [props.status]);
    const getMuiTheme = React.useCallback((parentTheme) => createTheme(Object.assign(Object.assign({}, parentTheme), { components: {
            MuiBadge: {
                styleOverrides: {
                    badge: {
                        backgroundColor: color,
                        color,
                    },
                },
            },
        } })), [color]);
    const badgeProps = omit(props, "status", "children");
    return (React.createElement(ThemeProvider, { theme: getMuiTheme },
        React.createElement(StyledBadge, Object.assign({}, badgeProps), props.children)));
};
export default DashStatusBadge;
