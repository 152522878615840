var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import CoreTextField from "@app/common/CoreTextField";
import { withDialog } from "@app/hoc/withDialog";
import API from "@app/util/api";
class AutoreplyDialog extends React.Component {
    constructor() {
        var _a, _b;
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                open: (_a = this.props.open) !== null && _a !== void 0 ? _a : false,
                item: (_b = this.props.item) !== null && _b !== void 0 ? _b : { input: "", output: "" },
                isSaving: false,
            }
        });
        Object.defineProperty(this, "save", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { item } = this.state;
                const { onSaveComplete } = this.props;
                this.setState({
                    isSaving: true,
                });
                const data = {
                    input: item.input,
                    output: item.output,
                };
                // console.log("DATA", data);
                const params = {
                    data,
                    onSuccess: (result) => {
                        this.setState({
                            isSaving: false,
                        });
                        if (onSaveComplete) {
                            onSaveComplete(result);
                        }
                    },
                    onError: (error) => {
                        this.setState({
                            isSaving: false,
                        });
                        this.props.showErrorDialog(error);
                    },
                };
                if (item.uid) {
                    API.twilioAutoReplySettings.update(item.uid, params);
                }
                else {
                    API.twilioAutoReplySettings.create(params);
                }
            }
        });
        Object.defineProperty(this, "setInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState((prevState) => ({
                    item: Object.assign(Object.assign({}, prevState.item), { input: text }),
                }));
            }
        });
        Object.defineProperty(this, "setOutput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState((prevState) => ({
                    item: Object.assign(Object.assign({}, prevState.item), { output: text }),
                }));
            }
        });
    }
    componentDidUpdate(prevProps) {
        var _a;
        if (!prevProps.open && this.props.open) {
            this.setState({
                item: (_a = this.props.item) !== null && _a !== void 0 ? _a : { input: "", output: "" },
            });
        }
    }
    get isValid() {
        var _a, _b;
        const { item } = this.state;
        return !!((_a = item.input) !== null && _a !== void 0 ? _a : "").trim() && !!((_b = item.output) !== null && _b !== void 0 ? _b : "").trim();
    }
    render() {
        var _a, _b;
        const { isSaving, item } = this.state;
        const _c = this.props, { hideDialog, onCancel, onSaveComplete, open, showDialog } = _c, other = __rest(_c, ["hideDialog", "onCancel", "onSaveComplete", "open", "showDialog"]);
        return (React.createElement(CoreDialog, Object.assign({ open: this.state.open }, other, { maxWidth: "sm", fullWidth: true }),
            React.createElement(DialogTitle, null,
                item.uid ? "Edit" : "Add",
                " Setting"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { p: 2, component: Grid, container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreTextField, { fullWidth: true, label: "Input", value: (_a = item.input) !== null && _a !== void 0 ? _a : "", onTextChange: this.setInput })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreTextField, { fullWidth: true, label: "Output", value: (_b = item.output) !== null && _b !== void 0 ? _b : "", onTextChange: this.setOutput })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: this.props.onCancel }, "Cancel"),
                React.createElement(Button, { onClick: this.save, color: "primary", disabled: !this.isValid || !!isSaving }, "Save"))));
    }
}
Object.defineProperty(AutoreplyDialog, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        item: null,
        group: null,
    }
});
/* eslint-disable react/no-arrow-function-lifecycle */
Object.defineProperty(AutoreplyDialog, "getDerivedStateFromProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (nextProps) => ({
        open: nextProps.open,
    })
});
export default withDialog(AutoreplyDialog);
