import * as React from "react";
import { Close, Code, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Collapse, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import CoreLink from "@app/common/CoreLink";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import DashFixedWidthTableCell from "@app/common/grid/CoreFixedWidthTableCell";
import { getAddress, getUrl } from "./utils";
const CompsPullerSelectPropertyTable = (props) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const { hasSelectedSubjectProperty, onClose, onSelectProperty, properties } = props;
    const title = React.useMemo(() => {
        return `${properties.length} listings returned. Please select one.`;
    }, [properties.length]);
    const rows = React.useMemo(() => {
        return properties.map((property) => (Object.assign(Object.assign(Object.assign({}, property), property.data), { address: getAddress(property.data) })));
    }, [properties]);
    const selectProperty = React.useCallback((property) => () => {
        onSelectProperty(property);
        setIsOpen(false);
    }, [onSelectProperty]);
    const columns = React.useMemo(() => {
        const columnDefinitions = [
            {
                name: "ListingId",
                label: " ",
                options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        if (value) {
                            const property = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                            const { source } = property;
                            const url = getUrl(source, value);
                            if (url) {
                                return (React.createElement(Tooltip, { title: "View JSON" },
                                    React.createElement(IconButton, { size: "small", component: CoreLink, href: url, target: "_blank" },
                                        React.createElement(Code, { fontSize: "small" }))));
                            }
                        }
                        return null;
                    },
                    setCellHeaderProps: () => ({
                        style: {
                            width: "40px",
                            minWidth: "40px",
                            maxWidth: "40px",
                        },
                    }),
                },
            },
            {
                name: "address",
                label: "Address",
                options: {
                    customBodyRender: (value) => value !== null && value !== void 0 ? value : "-",
                },
            },
            {
                name: "ListingId",
                label: "MLS Number",
                options: {
                    customBodyRender: (value) => value !== null && value !== void 0 ? value : "-",
                },
            },
            {
                name: "ListPrice",
                label: "List Price",
                options: {
                    customBodyRender: (value) => (value ? numeral(value).format("$0,0") : "-"),
                },
            },
            {
                name: "ListingContractDate",
                label: "Listing Contract Date",
                options: {
                    sortDirection: "desc",
                    customBodyRender: (value) => value && dayjs_(value).isValid() ? dayjs_(value).format("dddd, MMMM D, YYYY") : "-",
                },
            },
            {
                name: "SelectButton",
                label: " ",
                options: {
                    sort: false,
                    customHeadRender: ({ index }) => React.createElement(DashFixedWidthTableCell, { key: index, width: "35px" }),
                    customBodyRender: (_, tableMeta) => {
                        const property = properties[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        return (React.createElement(Button, { color: "primary", onClick: selectProperty(property) }, "Select"));
                    },
                },
            },
        ];
        return columnDefinitions.filter((obj) => obj);
    }, [properties, rows, selectProperty]);
    const onDisplayToggle = React.useCallback(() => {
        setIsOpen((prev) => !prev);
    }, [setIsOpen]);
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        pagination: false,
        viewColumns: false,
        search: false,
        searchOpen: false,
        sort: true,
        elevation: 1,
    };
    return (React.createElement(Grid, { container: true, spacing: 2, style: { marginBottom: "16px" } },
        React.createElement(Grid, { item: true, xs: 12, style: { display: "flex" } },
            React.createElement(Typography, { align: "center", style: { marginTop: "auto", marginBottom: "auto", marginLeft: "auto" } }, title),
            React.createElement(Tooltip, { title: React.createElement(Typography, null, isOpen ? "Hide Potential Subject Properties" : "Show Potential Subject Properties") },
                React.createElement(IconButton, { size: "small", onClick: onDisplayToggle, style: hasSelectedSubjectProperty ? undefined : { marginRight: "auto" } }, isOpen ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null))),
            hasSelectedSubjectProperty && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Close") },
                React.createElement(IconButton, { size: "small", onClick: onClose, style: { marginRight: "auto" } },
                    React.createElement(Close, null))))),
        React.createElement(Collapse, { in: isOpen, style: { width: "100%" } },
            React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 0 }))));
};
export default CompsPullerSelectPropertyTable;
