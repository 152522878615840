import * as React from "react";
import { Add, House } from "@mui/icons-material";
import { IconButton, List, Tooltip, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import ShowingListItem from "./ShowingListItem";
import ShowingsDialog from "./ShowingsDialog";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Showings = (props) => {
    var _a;
    const { CollapsibleSectionProps, onAddShowing, onRemoveShowing, onUpdateShowing, showings } = props;
    const [isShowingsDialogOpen, setIsShowingDialogOpen] = React.useState(false);
    const [editingShowingUid, setEditingShowingUid] = React.useState(null);
    const openShowingDialog = React.useCallback(() => {
        setIsShowingDialogOpen(true);
    }, []);
    const closeShowingDialog = React.useCallback(() => {
        setIsShowingDialogOpen(false);
    }, []);
    const addShowing = React.useCallback(() => {
        setEditingShowingUid(null);
        openShowingDialog();
    }, [openShowingDialog]);
    const editShowing = React.useCallback((showingUid) => {
        setEditingShowingUid(showingUid);
        openShowingDialog();
    }, [openShowingDialog]);
    const addOrUpdateShowing = React.useCallback((params) => {
        closeShowingDialog();
        console.log({ editingShowingUid });
        if (editingShowingUid) {
            onUpdateShowing === null || onUpdateShowing === void 0 ? void 0 : onUpdateShowing(editingShowingUid, params);
        }
        else {
            onAddShowing === null || onAddShowing === void 0 ? void 0 : onAddShowing(params);
        }
    }, [closeShowingDialog, editingShowingUid, onAddShowing, onUpdateShowing]);
    const isLoading = showings == null;
    const noAgents = showings && showings.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, Object.assign({ icon: React.createElement(House, null), title: "Showings", count: (_a = showings === null || showings === void 0 ? void 0 : showings.length) !== null && _a !== void 0 ? _a : undefined, toolbar: onAddShowing ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add showing") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: addShowing, tabIndex: -1 },
                        React.createElement(Add, { fontSize: "small" }))))) : undefined }, CollapsibleSectionProps),
            isLoading && React.createElement(CoreLoading, null),
            noAgents && React.createElement(Typography, { variant: "body2" }, "No showings"),
            showings && showings.length > 0 && (React.createElement(List, { dense: true }, showings
                .sort((a, b) => {
                if (a.datetime > b.datetime)
                    return -1;
                if (a.datetime < b.datetime)
                    return 1;
                return 0;
            })
                .map((showing) => (React.createElement(ShowingListItem, { key: showing.uid, showing: showing, onEditShowing: onUpdateShowing ? editShowing : undefined, onRemoveShowing: onRemoveShowing })))))),
        isShowingsDialogOpen && (React.createElement(ShowingsDialog, { DialogProps: { open: isShowingsDialogOpen }, onClose: closeShowingDialog, onUpdate: addOrUpdateShowing, showing: (showings !== null && showings !== void 0 ? showings : []).find((a) => a.uid === editingShowingUid) }))));
};
export default Showings;
