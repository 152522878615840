/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * This endpoint triggers a script which runs the Mail Schedule. This script may take a while to run; if you set `run_in_background` to `true`, this endpoint will immediately return a `uid` for the script execution almost immediately, the script itself will run in the bacground, and you can use this `uid` with the *Check execution status* endpoint to check the status of the script and get a summary of processed templates.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Run the Mail Schedule
 */
export const runMailschedule3 = (runMailScheduleBodyRequest, options) => {
    return axios.post(`/api/mailschedule/run`, runMailScheduleBodyRequest, options);
};
export const getRunMailschedule3MutationOptions = (options) => {
    const mutationKey = ['runMailschedule3'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return runMailschedule3(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Run the Mail Schedule
*/
export const useRunMailschedule3 = (options) => {
    const mutationOptions = getRunMailschedule3MutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* The *Run the Mail Schedule* endpoint is designed to return the execution `uid` immediately, but the script itself takes a while to run.  Use this script to check the script's status.  If the script has finished, it will return a summary of the processed templates.

_Authorization: only logged in, registered users can use this endpoint._
* @summary Check execution status
*/
export const checkMailschedule3ExecutionStatus = (uid, options) => {
    return axios.get(`/api/mailschedule/run/${uid}`, options);
};
export const getCheckMailschedule3ExecutionStatusQueryKey = (uid) => {
    return [`/api/mailschedule/run/${uid}`];
};
export const getCheckMailschedule3ExecutionStatusQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCheckMailschedule3ExecutionStatusQueryKey(uid);
    const queryFn = ({ signal }) => checkMailschedule3ExecutionStatus(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Check execution status
 */
export function useCheckMailschedule3ExecutionStatus(uid, options) {
    const queryOptions = getCheckMailschedule3ExecutionStatusQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * This endpoint triggers a script which runs the Mail Schedule. This script may take a while to run; if you set `run_in_background` to `true`, this endpoint will immediately return a `uid` for the script execution almost immediately, the script itself will run in the bacground, and you can use this `uid` with the *Check execution status* endpoint to check the status of the script and get a summary of processed templates.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Run the Mail Schedule
 */
export const runMailschedule4 = (mailSchedule4RunMailScheduleBodyRequest, options) => {
    return axios.post(`/api/mailschedule4/run`, mailSchedule4RunMailScheduleBodyRequest, options);
};
export const getRunMailschedule4MutationOptions = (options) => {
    const mutationKey = ['runMailschedule4'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return runMailschedule4(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Run the Mail Schedule
*/
export const useRunMailschedule4 = (options) => {
    const mutationOptions = getRunMailschedule4MutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* The *Run the Mail Schedule* endpoint is designed to return the execution `uid` immediately, but the script itself takes a while to run.  Use this script to check the script's status.  If the script has finished, it will return a summary of the processed templates.

_Authorization: only logged in, registered users can use this endpoint._
* @summary Check execution status
*/
export const checkMailschedule4ExecutionStatus = (uid, options) => {
    return axios.get(`/api/mailschedule4/run/${uid}`, options);
};
export const getCheckMailschedule4ExecutionStatusQueryKey = (uid) => {
    return [`/api/mailschedule4/run/${uid}`];
};
export const getCheckMailschedule4ExecutionStatusQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCheckMailschedule4ExecutionStatusQueryKey(uid);
    const queryFn = ({ signal }) => checkMailschedule4ExecutionStatus(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Check execution status
 */
export function useCheckMailschedule4ExecutionStatus(uid, options) {
    const queryOptions = getCheckMailschedule4ExecutionStatusQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Use this endpoint to upload your attachments to Cloud Storage *before* running Mail merge

_Authorization: none required_
 * @summary Upload custom template attachment
 */
export const uploadCustomTemplateAttachment = (uploadMailMergeAttachmentBodyRequest, options) => {
    return axios.post(`/api/mailschedule4/upload_attachment`, uploadMailMergeAttachmentBodyRequest, options);
};
export const getUploadCustomTemplateAttachmentMutationOptions = (options) => {
    const mutationKey = ['uploadCustomTemplateAttachment'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return uploadCustomTemplateAttachment(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Upload custom template attachment
*/
export const useUploadCustomTemplateAttachment = (options) => {
    const mutationOptions = getUploadCustomTemplateAttachmentMutationOptions(options);
    return useMutation(mutationOptions);
};
