import * as React from "react";
import { useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import PriceReductionScheduleForm from "./PriceReductionScheduleForm";
const PriceReductionScheduleFormPage = () => {
    useDocumentTitle("Price Reduction Schedule");
    const { uid } = useParams();
    return React.createElement(PriceReductionScheduleForm, { uid: uid });
};
export default PriceReductionScheduleFormPage;
