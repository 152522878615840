import * as React from "react";
import { Divider, Grid, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import CoreDateField from "@app/common/CoreDateField";
import CoreNumberField from "@app/common/CoreNumberField";
import { FilterContext } from "../FilterContext";
const SavedViewDateFilter = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const dateFields = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = context.fields) === null || _a === void 0 ? void 0 : _a.filter((f) => f.field_type === "date")) !== null && _b !== void 0 ? _b : [];
    }, [context.fields]);
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.date_filter.comparator = comparator;
        if (comparator === "is not set" || comparator === "is set") {
            newFilter.date_filter.rhs_field = undefined;
            newFilter.date_filter.rhs_value = undefined;
            newFilter.date_filter.rhs_range = undefined;
        }
        else if (comparator === "is in a range") {
            newFilter.date_filter.rhs_field = undefined;
            newFilter.date_filter.rhs_value = undefined;
            newFilter.date_filter.rhs_range = "today";
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setInterval = React.useCallback((val) => {
        var _a;
        const newFilter = Object.assign({}, filter);
        newFilter.date_filter.interval = val != null ? val * (((_a = filter.date_filter.interval) !== null && _a !== void 0 ? _a : 0) < 1 ? -1 : 1) : null;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setUnits = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.date_filter.units = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setBeforeAfter = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.date_filter.interval =
            newFilter.date_filter.interval != null
                ? Math.abs(newFilter.date_filter.interval) * (val === "before" ? -1 : 1)
                : null;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHS = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        if (val === "current date" || val === "current date and time") {
            newFilter.date_filter.rhs_field = undefined;
            newFilter.date_filter.rhs_value = undefined;
        }
        else if (val === "constant value") {
            newFilter.date_filter.rhs_field = undefined;
            newFilter.date_filter.rhs_value = dayjs().toISOString();
        }
        else {
            newFilter.date_filter.rhs_field = val;
            newFilter.date_filter.rhs_value = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setConstantDate = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.date_filter.rhs_value = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSRange = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.date_filter.rhs_range = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.dateFieldComparators;
    const comparatorSelect = (React.createElement(Grid, { item: true, style: { width: "150px" } },
        React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.date_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))));
    return (React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap" },
        filter.date_filter.comparator !== "is the anniversary of" && comparatorSelect,
        filter.date_filter.comparator !== "is set" && filter.date_filter.comparator !== "is not set" && (React.createElement(React.Fragment, null,
            !filter.date_filter.rhs_value && !filter.date_filter.rhs_range && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, style: { width: "75px" } },
                    React.createElement(CoreNumberField, { size: "small", variant: "standard", fullWidth: true, format: "0,0", min: 0, max: 999, number: filter.date_filter.interval != null ? Math.abs(filter.date_filter.interval) : null, onNumberChange: setInterval })),
                React.createElement(Grid, { item: true, style: { width: "75px" } },
                    React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_b = filter.date_filter.units) !== null && _b !== void 0 ? _b : "", onChange: setUnits }, ((_c = context.dateFilterUnits) !== null && _c !== void 0 ? _c : []).map((x) => (React.createElement(MenuItem, { key: x, value: x },
                        x,
                        filter.date_filter.interval === 1 ? "" : "s"))))),
                React.createElement(Grid, { item: true, style: { width: "75px" } },
                    React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: ((_d = filter.date_filter.interval) !== null && _d !== void 0 ? _d : 0) <= 0 ? "before" : "after", onChange: setBeforeAfter },
                        React.createElement(MenuItem, { value: "before" }, "before"),
                        React.createElement(MenuItem, { value: "after" }, "after"))))),
            !filter.date_filter.rhs_range && (React.createElement(Grid, { item: true, style: { width: "200px" } },
                React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_f = (_e = filter.date_filter.rhs_field) !== null && _e !== void 0 ? _e : (filter.date_filter.rhs_value ? "constant value" : null)) !== null && _f !== void 0 ? _f : "current date and time", onChange: setRHS },
                    React.createElement(MenuItem, { value: "current date and time" }, "the current date and time"),
                    React.createElement(Divider, null),
                    dateFields.map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))),
                    React.createElement(Divider, null),
                    React.createElement(MenuItem, { value: "constant value" }, "a constant value")))),
            filter.date_filter.rhs_value && (React.createElement(Grid, { item: true, style: { width: "200px" } },
                React.createElement(CoreDateField, { mode: "date", format: "dddd, MMMM D, YYYY", formatType: "dayjs", fullWidth: true, date: filter.date_filter.rhs_value, onDateChange: setConstantDate, hasPicker: true }))),
            filter.date_filter.rhs_range && (React.createElement(Grid, { item: true, style: { width: "150px" } },
                React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_g = filter.date_filter.rhs_range) !== null && _g !== void 0 ? _g : "", onChange: setRHSRange }, ((_h = context.dateRanges) !== null && _h !== void 0 ? _h : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x)))))))),
        filter.date_filter.comparator === "is the anniversary of" && comparatorSelect));
};
export default SavedViewDateFilter;
