/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get DashScore data
 */
export const listDashscoreTabDescriptions = (options) => {
    return axios.get(`/api/reports/dashscore/tab_descriptions`, options);
};
export const getListDashscoreTabDescriptionsQueryKey = () => {
    return [`/api/reports/dashscore/tab_descriptions`];
};
export const getListDashscoreTabDescriptionsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDashscoreTabDescriptionsQueryKey();
    const queryFn = ({ signal }) => listDashscoreTabDescriptions(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get DashScore data
 */
export function useListDashscoreTabDescriptions(options) {
    const queryOptions = getListDashscoreTabDescriptionsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Updates the description for a DASHscore tab.
 */
export const updateDashscoreTabDescription = (dashScoreTabDescriptionRequest, options) => {
    return axios.post(`/api/reports/dashscore/tab_descriptions`, dashScoreTabDescriptionRequest, options);
};
export const getUpdateDashscoreTabDescriptionMutationOptions = (options) => {
    const mutationKey = ['updateDashscoreTabDescription'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return updateDashscoreTabDescription(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Updates the description for a DASHscore tab.
*/
export const useUpdateDashscoreTabDescription = (options) => {
    const mutationOptions = getUpdateDashscoreTabDescriptionMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary List all DASHscore Tab names
*/
export const listDashscoreTabs = (options) => {
    return axios.get(`/api/reports/dashscore/tabs`, options);
};
export const getListDashscoreTabsQueryKey = () => {
    return [`/api/reports/dashscore/tabs`];
};
export const getListDashscoreTabsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListDashscoreTabsQueryKey();
    const queryFn = ({ signal }) => listDashscoreTabs(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all DASHscore Tab names
 */
export function useListDashscoreTabs(options) {
    const queryOptions = getListDashscoreTabsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
