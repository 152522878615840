import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import Views from "./Views";
import usePaginatedTable from "../components/spreadsheet-view/usePaginatedTable";
const ViewsPage = () => {
    useDocumentTitle("Views");
    const { limit, offset, search } = usePaginatedTable();
    const [searchParams, _] = useSearchParams();
    const type = searchParams.get("type");
    return (React.createElement(Views, { limit: limit !== null && limit !== void 0 ? limit : undefined, offset: offset !== null && offset !== void 0 ? offset : undefined, search: search !== null && search !== void 0 ? search : undefined, type: type || undefined }));
};
export default ViewsPage;
