import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router";
import { useListUserActivity, useLogUserActivity, useMarkPageVisitEnded, useUpdateUserActivity, } from "@app/orval/api/activity";
import useSession from "./useSession";
const ActivityContext = createContext({ currentActivity: [] });
export const ActivityProvider = (props) => {
    var _a, _b, _c;
    const { fetchIntervalMs, updateIntervalMs } = props;
    const location = useLocation();
    const session = useSession();
    const listActivityApi = useListUserActivity({ offline_threshold_minutes: 10, most_recent_page_only: true }, { query: { enabled: false } });
    const currentActivity = (_b = (_a = listActivityApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const pageVisitUid = useRef(null);
    const lastActiveTimestamp = useRef(new Date().toISOString());
    const previousLastActiveTimestamp = useRef(null);
    const fetchActivityTimeout = useRef(null);
    const updateActivityTimeout = useRef(null);
    const logActivityApi = useLogUserActivity();
    const updateActivityApi = useUpdateUserActivity();
    const markPageVisitEndedApi = useMarkPageVisitEnded();
    const listActivity = useCallback(() => {
        var _a;
        if (!((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal)) {
            return;
        }
        const lastActive = lastActiveTimestamp.current;
        const previousLastActive = previousLastActiveTimestamp.current;
        if (lastActive === previousLastActive) {
            console.log("User is not active. Skipping activity fetch.");
            if (fetchActivityTimeout.current) {
                clearTimeout(fetchActivityTimeout.current);
            }
            fetchActivityTimeout.current = window.setTimeout(listActivity, fetchIntervalMs);
            return;
        }
        listActivityApi.refetch().then(() => {
            fetchActivityTimeout.current = window.setTimeout(listActivity, fetchIntervalMs);
        });
    }, [fetchIntervalMs, listActivityApi, (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.isInternal]);
    /**
     * Update the user's activity.
     * If the user is inactive (i.e. their `lastActiveTimestamp` hasn't changed),
     * this function does nothing.
     *
     * This function also creates a timeout to call itself again after `updateIntervalMs`.
     */
    const updateActivity = useCallback(() => {
        var _a;
        if (!pageVisitUid.current) {
            throw new Error("pageVisitUid is null");
        }
        const lastActive = lastActiveTimestamp.current;
        const previousLastActive = previousLastActiveTimestamp.current;
        if (lastActive === previousLastActive) {
            console.log("No activity changes.  Not updating activity.");
            if (updateActivityTimeout.current) {
                clearTimeout(updateActivityTimeout.current);
            }
            updateActivityTimeout.current = window.setTimeout(updateActivity, updateIntervalMs);
            return;
        }
        updateActivityApi
            .mutateAsync({
            uid: pageVisitUid.current,
            data: { last_active: lastActive, path: location.pathname + ((_a = location.search) !== null && _a !== void 0 ? _a : "") },
        })
            .then(() => {
            previousLastActiveTimestamp.current = lastActive;
        })
            .catch(console.error)
            .then(() => {
            updateActivityTimeout.current = window.setTimeout(updateActivity, updateIntervalMs);
        });
    }, [location.pathname, location.search, updateActivityApi, updateIntervalMs]);
    /**
     * Log the user's activity when the open they app.
     * This function should only be called once.
     */
    const logActivity = useCallback(() => {
        var _a;
        logActivityApi
            .mutateAsync({ data: { path: location.pathname + ((_a = location.search) !== null && _a !== void 0 ? _a : "") } })
            .then((result) => {
            console.log("Logged Activity", result.data.uid);
            pageVisitUid.current = result.data.uid;
            updateActivityTimeout.current = window.setTimeout(updateActivity, updateIntervalMs);
        })
            .catch(console.error);
    }, [location.pathname, location.search, logActivityApi, updateActivity, updateIntervalMs]);
    /**
     * Logs the page visit as having ended when the user
     * closes the app. Should only be called once.
     */
    const removeActivity = useCallback(() => {
        markPageVisitEndedApi
            .mutateAsync({ uid: pageVisitUid.current })
            .then((result) => {
            console.log("Deleted Activity", result);
        })
            .catch(console.error);
    }, [markPageVisitEndedApi]);
    /**
     * Stop periodically updating the user's activity.
     */
    const stop = useCallback(() => {
        if (updateActivityTimeout.current) {
            clearTimeout(updateActivityTimeout.current);
        }
    }, []);
    /**
     * Runs when the component is mounted.
     * This is where we start fetching the recent activity of all users,
     * and start the process of periodically checking/updating the current
     * user's activity.
     */
    useEffect(() => {
        listActivity();
        logActivity();
        window.onbeforeunload = () => {
            removeActivity();
        };
        window.onmousemove = () => {
            lastActiveTimestamp.current = new Date().toISOString();
        };
        window.onkeypress = () => {
            lastActiveTimestamp.current = new Date().toISOString();
        };
        return () => {
            if (pageVisitUid.current) {
                removeActivity();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (pageVisitUid.current) {
            stop();
            updateActivity();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    const value = useMemo(() => {
        return {
            currentActivity,
        };
    }, [currentActivity]);
    return React.createElement(ActivityContext.Provider, { value: value }, props.children);
};
const useActivity = () => {
    const actityContext = useContext(ActivityContext);
    return {
        currentActivity: actityContext.currentActivity,
    };
};
export default useActivity;
