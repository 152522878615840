/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Downloads a file from a GCP folder and returns the ASCII content.
 */
export const downloadFileFromCloudStorage = (params, options) => {
    return axios.get(`/api/utilities/google_cloud/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadFileFromCloudStorageQueryKey = (params) => {
    return [`/api/utilities/google_cloud/download`, ...(params ? [params] : [])];
};
export const getDownloadFileFromCloudStorageQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadFileFromCloudStorageQueryKey(params);
    const queryFn = ({ signal }) => downloadFileFromCloudStorage(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Downloads a file from a GCP folder and returns the ASCII content.
 */
export function useDownloadFileFromCloudStorage(params, options) {
    const queryOptions = getDownloadFileFromCloudStorageQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List filenames from a GCP folder
 */
export const listCloudStorageBlobs = (params, options) => {
    return axios.get(`/api/utilities/google_cloud/list_blobs`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListCloudStorageBlobsQueryKey = (params) => {
    return [`/api/utilities/google_cloud/list_blobs`, ...(params ? [params] : [])];
};
export const getListCloudStorageBlobsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCloudStorageBlobsQueryKey(params);
    const queryFn = ({ signal }) => listCloudStorageBlobs(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List filenames from a GCP folder
 */
export function useListCloudStorageBlobs(params, options) {
    const queryOptions = getListCloudStorageBlobsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
