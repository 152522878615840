import * as React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
const AgentMenuItem = (props) => {
    var _a;
    const { agent } = props;
    const initials = [agent.first_name, agent.last_name]
        .filter((o) => o === null || o === void 0 ? void 0 : o.trim())
        .map((o) => o.substring(0, 1))
        .join("");
    const name = [agent.first_name, agent.last_name].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).join(" ");
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Avatar, { src: (_a = agent.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { width: "22px", height: "22px", fontSize: "0.7rem" } }, initials)),
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, { variant: "body2" }, name))));
};
export default AgentMenuItem;
