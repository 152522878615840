import Loading from "@app/common/Loading";
const useLoading = (params) => {
    const { items, showInfiniteSpinner } = params;
    const loadingComponent = Loading({ items, showInfiniteSpinner });
    const loadingComponent_ = loadingComponent || showInfiniteSpinner ? loadingComponent : null;
    return {
        isLoading: !!loadingComponent,
        loadingComponent: loadingComponent_,
    };
};
export default useLoading;
