import * as React from "react";
import { Grid2, MenuItem, TextField, Typography } from "@mui/material";
import CoreNumberField from "@app/common/CoreNumberField";
import { FilterContext } from "../FilterContext";
const SavedViewMailScheduleTemplateFilter = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.getFilter(uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.mail_schedule_template_filter.comparator = comparator;
        if (comparator === "has been sent") {
            newFilter.mail_schedule_template_filter.time_comparator = "at least";
            newFilter.mail_schedule_template_filter.time_interval = 0;
            newFilter.mail_schedule_template_filter.time_unit = "days";
        }
        else {
            newFilter.mail_schedule_template_filter.time_comparator = null;
            newFilter.mail_schedule_template_filter.time_interval = null;
            newFilter.mail_schedule_template_filter.time_unit = null;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setTimeComparator = React.useCallback((e) => {
        const val = e.target.value || null;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.mail_schedule_template_filter.time_comparator = comparator;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setTimeInterval = React.useCallback((val) => {
        const newFilter = Object.assign({}, filter);
        newFilter.mail_schedule_template_filter.time_interval = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setTimeUnits = React.useCallback((e) => {
        const val = e.target.value || null;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.mail_schedule_template_filter.time_unit = comparator;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setTemplate = React.useCallback((e) => {
        const newFilter = Object.assign({}, filter);
        newFilter.mail_schedule_template_filter.template_uid = e.target.value;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.mailScheduleTemplateComparators;
    return (React.createElement(Grid2, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid2, { style: { width: "300px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.mail_schedule_template_filter.template_uid) !== null && _a !== void 0 ? _a : "", onChange: setTemplate }, ((_b = context.mailScheduleTemplates) !== null && _b !== void 0 ? _b : []).map((x) => (React.createElement(MenuItem, { key: x.uid, value: x.uid }, x.name))))),
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_c = filter.mail_schedule_template_filter.comparator) !== null && _c !== void 0 ? _c : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_d = filter.mail_schedule_template_filter.time_comparator) !== null && _d !== void 0 ? _d : "", onChange: setTimeComparator }, ((_e = context.mailScheduleTemplateFilterTimeComparators) !== null && _e !== void 0 ? _e : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        React.createElement(Grid2, { style: { width: "100px" } },
            React.createElement(CoreNumberField, { size: "small", variant: "standard", fullWidth: true, number: (_f = filter.mail_schedule_template_filter.time_interval) !== null && _f !== void 0 ? _f : null, format: "0,0", min: 0, max: 999, onNumberChange: setTimeInterval })),
        React.createElement(Grid2, { style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_g = filter.mail_schedule_template_filter.time_unit) !== null && _g !== void 0 ? _g : "", onChange: setTimeUnits }, ((_h = context.mailScheduleTemplateFilterTimeUnits) !== null && _h !== void 0 ? _h : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(Typography, null, "ago"))));
};
export default SavedViewMailScheduleTemplateFilter;
