import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import AddThreadsToBox from "./AddThreadsToBox";
const AddThreadsToBoxPage = () => {
    var _a;
    useDocumentTitle("Add Threads");
    const [searchParams, _] = useSearchParams();
    const showThreadIdInput = searchParams.get("show_thread_id_input") !== "false";
    const defaultThreadIds = searchParams.get("default_thread_ids");
    const mode = (_a = searchParams.get("mode")) !== null && _a !== void 0 ? _a : "threads";
    return (React.createElement(AddThreadsToBox, { showThreadIdInput: showThreadIdInput, defaultThreadIds: defaultThreadIds !== null && defaultThreadIds !== void 0 ? defaultThreadIds : "", mode: mode }));
};
export default AddThreadsToBoxPage;
