import { createTheme } from "@mui/material";
const getMuiTheme = (params) => (parentTheme) => {
    var _a, _b, _c, _d, _e;
    const { height, isSearching, searchString } = params;
    return createTheme(Object.assign(Object.assign({}, parentTheme), { components: Object.assign(Object.assign({}, parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.components), { MuiAutocomplete: Object.assign(Object.assign({}, (_a = parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.components) === null || _a === void 0 ? void 0 : _a.MuiAutocomplete), { styleOverrides: {
                    root: {
                        height: height !== null && height !== void 0 ? height : undefined,
                        "& fieldset": {
                            top: "1px",
                        },
                        "& fieldset > legend": {
                            visibility: "hidden",
                            "& span": {
                                display: "none",
                            },
                        },
                    },
                    popupIndicator: {
                        top: "3px",
                        color: "white",
                    },
                    popper: {
                        display: isSearching || (searchString !== null && searchString !== void 0 ? searchString : "").trim() !== "" ? "block" : "none",
                    },
                } }), MuiInputLabel: Object.assign(Object.assign({}, (_b = parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.components) === null || _b === void 0 ? void 0 : _b.MuiInputLabel), { styleOverrides: {
                    formControl: {
                        color: "white !important",
                    },
                    shrink: {
                        display: "none",
                    },
                } }), MuiInputBase: Object.assign(Object.assign({}, (_c = parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.components) === null || _c === void 0 ? void 0 : _c.MuiInputBase), { styleOverrides: {
                    root: {
                        height: height !== null && height !== void 0 ? height : undefined,
                        color: "white !important",
                    },
                    input: {
                        height: height !== null && height !== void 0 ? height : undefined,
                    },
                } }), MuiInputAdornment: Object.assign(Object.assign({}, (_d = parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.components) === null || _d === void 0 ? void 0 : _d.MuiInputAdornment), { styleOverrides: {
                    root: {
                        color: "white !important",
                    },
                    positionEnd: {
                        color: "white !important",
                    },
                } }), MuiOutlinedInput: Object.assign(Object.assign({}, (_e = parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.components) === null || _e === void 0 ? void 0 : _e.MuiOutlinedInput), { styleOverrides: {
                    notchedOutline: {
                        borderColor: "white !important",
                    },
                } }), MuiListSubheader: {
                styleOverrides: {
                    root: {
                        lineHeight: 2,
                    },
                },
            } }) }));
};
export default getMuiTheme;
