import * as React from "react";
import { Refresh } from "@mui/icons-material";
import { Box, Divider, Grid2, IconButton, LinearProgress, ThemeProvider, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import dayjs_ from "@app/common/dayjs";
import EllipsisText from "@app/common/EllipsisText";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { GET_CELL_CLASS_NAME_NO_FOCUS_RING, renderUserCell } from "@app/common/grid/utils";
import RouterLink from "@app/common/RouterLink";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { searchBuyerLeads } from "@app/orval/api/buyer-leads";
import { countZhlEngagedTransferSubmissions, searchZhlEngagedTransferSubmissions } from "@app/orval/api/zhl-etr";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const ZHLEngagedTransferSubmissions = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { limit = 1000, offset = 0 } = props;
    const updateSearch = useUpdateSearch();
    const searchSubmissionsApi = usePostQuery(searchZhlEngagedTransferSubmissions, {
        limit,
        offset,
    }, REFETCH_CACHE_PARAMS);
    const submissions = (_b = (_a = searchSubmissionsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const rows = React.useMemo(() => {
        if (submissions == null) {
            return [];
        }
        return submissions.map((submission) => (Object.assign({ id: submission.uid }, submission)));
    }, [submissions]);
    const buyerLeadUids = React.useMemo(() => {
        if (submissions == null) {
            return new Set();
        }
        return new Set(submissions.map((submission) => submission.buyer_lead_uid));
    }, [submissions]);
    const buyerLeads = usePostQuery(searchBuyerLeads, {
        uids: [...buyerLeadUids],
    }, undefined, buyerLeadUids.size > 0);
    const buyerLeadsByUid = React.useMemo(() => {
        var _a;
        if (((_a = buyerLeads.data) === null || _a === void 0 ? void 0 : _a.data) == null) {
            return {};
        }
        return buyerLeads.data.data.reduce((acc, buyerLead) => {
            acc[buyerLead.uid] = buyerLead;
            return acc;
        }, {});
    }, [(_c = buyerLeads.data) === null || _c === void 0 ? void 0 : _c.data]);
    const countSubmissionsApi = usePostQuery(countZhlEngagedTransferSubmissions, {}, REFETCH_CACHE_PARAMS);
    const count = (_e = (_d = countSubmissionsApi.data) === null || _d === void 0 ? void 0 : _d.data.count) !== null && _e !== void 0 ? _e : null;
    const page = React.useMemo(() => {
        if (limit === 0) {
            return 0;
        }
        return Math.floor(offset / limit);
    }, [limit, offset]);
    const onPageChange = React.useCallback((model) => {
        const rowsPerPage = model.pageSize;
        const currentPage = model.page;
        updateSearch("offset", rowsPerPage * currentPage, "limit", rowsPerPage);
    }, [updateSearch]);
    const refresh = React.useCallback(() => {
        searchSubmissionsApi.refetch();
        countSubmissionsApi.refetch();
    }, [searchSubmissionsApi, countSubmissionsApi]);
    const error = (_f = searchSubmissionsApi.error) !== null && _f !== void 0 ? _f : countSubmissionsApi.error;
    React.useEffect(() => {
        if (error && submissions != null) {
            enqueueErrorSnackbar(error);
        }
    }, [error, submissions]);
    const columns = React.useMemo(() => [
        {
            field: "buyer_lead_uid",
            headerName: "Buyer Lead",
            width: 250,
            renderCell: (params) => {
                const buyerLead = buyerLeadsByUid[params.value];
                return (React.createElement(RouterLink, { to: `/boxes/${params.value}` }, buyerLead ? buyerLead.name.trim() || params.value : params.value));
            },
        },
        {
            field: "state",
            headerName: "State",
            width: 75,
        },
        {
            field: "notes",
            headerName: "Notes",
            width: 300,
            renderCell: (params) => {
                return React.createElement(EllipsisText, { text: params.value, TypographyProps: { sx: { width: "100%" }, variant: "body2" } });
            },
        },
        {
            field: "submitted_at",
            headerName: "Submitted At",
            width: 200,
            renderCell: (params) => {
                const { value } = params;
                if (value && dayjs_(value).isValid()) {
                    return dayjs_(value).format("ddd, MMM D, YYYY h:mm:ss A");
                }
                return value;
            },
        },
        {
            field: "submitted_by",
            headerName: "Submitted By",
            width: 200,
            renderCell: renderUserCell,
        },
    ], [buyerLeadsByUid]);
    if (error && submissions == null) {
        return React.createElement(CoreError, { error: error });
    }
    if (submissions == null) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0, style: { width: "100%", height: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 1 },
                React.createElement(Grid2, { container: true, direction: "row", spacing: 1 },
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: refresh, disabled: searchSubmissionsApi.isFetching },
                            React.createElement(Refresh, null)))))),
        React.createElement(Grid2, null, searchSubmissionsApi.isFetching ? React.createElement(LinearProgress, { variant: "indeterminate" }) : React.createElement(Divider, null)),
        React.createElement(Grid2, { style: { flex: 1, overflow: "hidden" } },
            submissions.length === 0 && offset === 0 && React.createElement(Typography, { align: "center" }, "No submissions found."),
            submissions.length > 0 && (React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows, columns: columns, rowHeight: 30, disableRowSelectionOnClick: true, disableColumnReorder: true, disableColumnSorting: true, disableColumnResize: true, disableColumnMenu: true, hideFooterSelectedRowCount: true, density: "compact", pagination: count != null && rows.length < count, rowCount: count !== null && count !== void 0 ? count : undefined, paginationMode: "server", pageSizeOptions: [10, 25, 50, 100, 200, 500, 1000], paginationModel: { page, pageSize: limit }, onPaginationModelChange: onPageChange, getCellClassName: GET_CELL_CLASS_NAME_NO_FOCUS_RING, localeText: {
                        toolbarColumns: "",
                    }, sx: {
                        border: "none",
                        ".MuiDataGrid-main": {
                            border: "none",
                        },
                    } }))))));
    return null;
};
export default ZHLEngagedTransferSubmissions;
