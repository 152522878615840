import * as React from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, MenuItem, Grid2, InputAdornment, TextField, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import { getParents } from "./utils";
const EditSOPFolderDialog = (props) => {
    var _a;
    const { allFolders, dialogProps, folder, onCancel, onFolderUpdated } = props;
    const [data, setData] = React.useState({
        name: folder.name,
        parent_uid: folder.parent_uid,
    });
    const isValid = React.useMemo(() => {
        return !!data.name.trim();
    }, [data]);
    const onSave = React.useCallback(() => {
        onFolderUpdated(data);
    }, [data, onFolderUpdated]);
    const setFolderName = React.useCallback((e) => {
        const val = e.target.value;
        setData((prev) => (Object.assign(Object.assign({}, prev), { name: val })));
    }, []);
    const setParentFolder = React.useCallback((e) => {
        const val = e.target.value || null;
        setData((prev) => (Object.assign(Object.assign({}, prev), { parent_uid: val })));
    }, []);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, dialogProps),
        React.createElement(DialogTitle, null, "Edit folder"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 4 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { fullWidth: true, variant: "standard", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Name"),
                                },
                            }, value: data.name, onChange: setFolderName })),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Parent Folder"),
                                },
                            }, value: (_a = data.parent_uid) !== null && _a !== void 0 ? _a : "", onChange: setParentFolder },
                            React.createElement(MenuItem, { value: "" }, "None"),
                            allFolders
                                .filter((f) => 
                            // this folder can't be a parent of itself,
                            // and we can't put it in one of it's child folders
                            f.uid !== folder.uid &&
                                !getParents(f, allFolders)
                                    .map((p) => p.uid)
                                    .includes(folder.uid))
                                .map((f) => {
                                const parents = getParents(f, allFolders);
                                const name = [...parents.map((p) => p.name).reverse(), f.name].join(" > ");
                                return (React.createElement(MenuItem, { key: f.uid, value: f.uid }, name));
                            })))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onCancel }, "Cancel"),
            React.createElement(Button, { onClick: onSave, color: "primary", disabled: !isValid }, "Save"))));
};
export default EditSOPFolderDialog;
