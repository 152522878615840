import * as React from "react";
import { Grid, Button, InputAdornment, MenuItem, TextField, Box } from "@mui/material";
import Filter from "./Filter";
import { FilterContext } from "./FilterContext";
const FilterGroup = (props) => {
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filterGroup = context.filterGroups.find((fg) => fg.uid === uid);
    const children = context.filterGroups.filter((fg) => fg.parent_uid === uid);
    const setMatchAll = React.useCallback(() => {
        context.updateGroup(uid, Object.assign(Object.assign({}, filterGroup), { is_match_all: !filterGroup.is_match_all }));
    }, [context, filterGroup, uid]);
    const addFilter = React.useCallback(() => {
        context.addFilter(uid);
    }, [context, uid]);
    const addGroup = React.useCallback(() => {
        context.addGroup(uid);
    }, [context, uid]);
    const removeGroup = React.useCallback(() => {
        context.removeGroup(uid);
    }, [context, uid]);
    console.log({ children });
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
            React.createElement(TextField, { select: true, variant: "outlined", size: "small", slotProps: {
                    input: {
                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Match"),
                    },
                }, value: filterGroup.is_match_all ? "1" : "0", onChange: setMatchAll },
                React.createElement(MenuItem, { value: "1" }, "All"),
                React.createElement(MenuItem, { value: "0" }, "Any"))),
        React.createElement(Grid, { item: true, container: true, style: { flex: 1 }, direction: "column", wrap: "nowrap", spacing: 2 },
            filterGroup.filters.length > 0 &&
                filterGroup.filters.map((filter) => (React.createElement(Grid, { key: filter.uid, item: true },
                    React.createElement(Filter, { uid: filter.uid })))),
            children.length > 0 &&
                children.map((child) => (React.createElement(Grid, { key: child.uid, item: true },
                    React.createElement(Box, { p: 1, style: { borderRadius: "0.25rem", border: "1px solid #aaa" } },
                        React.createElement(FilterGroup, { uid: child.uid }))))),
            React.createElement(Grid, { container: true, item: true, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { size: "small", onClick: addFilter, variant: "outlined", tabIndex: -1 }, "Add filter")),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { size: "small", onClick: addGroup, variant: "outlined", tabIndex: -1 }, "Add group")),
                filterGroup.parent_uid && (React.createElement(Grid, { item: true },
                    React.createElement(Button, { size: "small", onClick: removeGroup, variant: "outlined", tabIndex: -1 }, "Remove group")))))));
};
export default FilterGroup;
