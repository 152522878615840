var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Check, Warning, Error } from "@mui/icons-material";
import { Box, Paper, Grid2, Typography, CircularProgress, Button } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { getMailschedule4Execution, useStopMailschedule4Execution } from "@app/orval/api/mail-schedule-4-logs";
const MailMergeJob = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { onClose, uid } = props;
    const [progress, setProgress] = React.useState(null);
    const [isCancelPressed, setIsCancelPressed] = React.useState(false);
    const stopMailMergeApi = useStopMailschedule4Execution();
    const close = React.useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);
    const getProgress = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const progressResponse = yield getMailschedule4Execution(uid);
        setProgress(progressResponse.data);
        if (progressResponse.data.stopped_timestamp != null ||
            progressResponse.data.end_timestamp != null ||
            progressResponse.data.error_message != null) {
            window.setTimeout(close, 30000);
            return;
        }
        yield new Promise((resolve) => setTimeout(resolve, 1000));
        getProgress();
    }), [uid, close]);
    const cancelJob = React.useCallback(() => {
        setIsCancelPressed(true);
        stopMailMergeApi.mutateAsync({ uid }).then(() => {
            getProgress();
        });
    }, [getProgress, stopMailMergeApi, uid]);
    React.useEffect(() => {
        getProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const numProcessed = ((_a = progress === null || progress === void 0 ? void 0 : progress.num_templates_sent) !== null && _a !== void 0 ? _a : 0) + ((_b = progress === null || progress === void 0 ? void 0 : progress.num_templates_errored_out) !== null && _b !== void 0 ? _b : 0);
    const totalToSend = ((_d = (_c = progress === null || progress === void 0 ? void 0 : progress.run_for_custom_templates) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) * ((_f = (_e = progress === null || progress === void 0 ? void 0 : progress.run_for_entity_uids) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0);
    const isDone = (progress === null || progress === void 0 ? void 0 : progress.end_timestamp) != null || (progress === null || progress === void 0 ? void 0 : progress.stopped_timestamp) != null || (progress === null || progress === void 0 ? void 0 : progress.error_message) != null;
    const percentComplete = progress != null && totalToSend > 0 ? (numProcessed / totalToSend) * 100 : 0;
    const statusMessage = React.useMemo(() => {
        if (!progress) {
            return "Starting...";
        }
        if (progress.error_message) {
            return "An error occurred.";
        }
        if (progress.stopped_timestamp) {
            return "Stopped.";
        }
        if (progress.end_timestamp) {
            return "Done.";
        }
        if (!numProcessed) {
            return "Working...";
        }
        return null;
    }, [progress, numProcessed]);
    const progressMessage = React.useMemo(() => {
        if (!progress) {
            return null;
        }
        if (!numProcessed) {
            return null;
        }
        if (totalToSend === 0) {
            return `Processed ${numProcessed} templates`;
        }
        return `Processed ${numProcessed} of ${totalToSend}`;
    }, [numProcessed, progress, totalToSend]);
    return (React.createElement(Paper, { elevation: 4, style: { background: "#333", color: "#fff" } },
        React.createElement(Box, { p: 2 },
            React.createElement(Grid2, { container: true, direction: "column", spacing: 1, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, direction: "row", spacing: 1, wrap: "nowrap", alignItems: "center" },
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(Grid2, { container: true, direction: "row", spacing: 1, alignItems: "baseline" },
                                statusMessage && (React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, statusMessage))),
                                progressMessage && (React.createElement(Grid2, null,
                                    " ",
                                    React.createElement(Typography, { variant: "body2" }, progressMessage))))),
                        React.createElement(Grid2, null, progress == null || !isDone ? (React.createElement(CircularProgress, { size: 20, color: "primary", variant: progress == null || progress.num_templates_sent === 0 ? "indeterminate" : "determinate", value: percentComplete, style: { color: "#ccc" } })) : progress.error_message ? (React.createElement(Error, { style: { color: "#ffaa99" } })) : progress.num_templates_errored_out ? (React.createElement(Warning, { style: { color: "#ffcc00" } })) : (React.createElement(Check, { style: { color: "#00ffaa" } }))))),
                (progress === null || progress === void 0 ? void 0 : progress.num_templates_errored_out) != null && progress.num_templates_errored_out > 0 && (React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2" }, "An error occurred sending")),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, progress.num_templates_errored_out)),
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2" }, progress.num_templates_errored_out > 1 ? "emails" : "email"))))),
                stopMailMergeApi.isPending && (React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" }, "Stopping..."))),
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                        progress != null && !isDone && (React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", color: "error", variant: "outlined", onClick: cancelJob, style: { color: "#ccc", borderColor: "#ccc" }, disabled: isCancelPressed }, "Cancel Job"))),
                        isDone && (React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: close, style: { color: "#ccc", borderColor: "#ccc" } }, "Close"))),
                        React.createElement(Grid2, { style: { flex: 1 } }),
                        isDone && (React.createElement(Grid2, null,
                            React.createElement(Button, { size: "small", color: "primary", variant: "outlined", component: RouterLink, onClick: close, to: `/ms4/logs/detailed?uid=${progress.uid}`, style: { color: "#ccc", borderColor: "#ccc" } }, "Open")))))))));
};
export default MailMergeJob;
