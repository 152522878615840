import * as React from "react";
import { Add, Person } from "@mui/icons-material";
import { IconButton, List, Tooltip, Typography } from "@mui/material";
import AgentDialog from "./AgentDialog";
import AgentListItem from "./AgentListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
/**
 * Component for managing the agents associated with a lead
 * @param props
 * @returns
 */
const Agents = (props) => {
    const { agentTypes, agents, onCreateAgent, onRemoveAgent, onUpdateAgent, variant } = props;
    const [isAgentDialogOpen, setIsAgentDialogOpen] = React.useState(false);
    const [editingAgentUid, setEditingAgentUid] = React.useState(null);
    const openAgentDialog = React.useCallback(() => {
        setIsAgentDialogOpen(true);
    }, [setIsAgentDialogOpen]);
    const closeAgentDialog = React.useCallback(() => {
        setIsAgentDialogOpen(false);
    }, [setIsAgentDialogOpen]);
    const addAgent = React.useCallback(() => {
        setEditingAgentUid(null);
        openAgentDialog();
    }, [openAgentDialog]);
    const editAgent = React.useCallback((agentUid) => {
        setEditingAgentUid(agentUid);
        openAgentDialog();
    }, [openAgentDialog]);
    const createOrUpdateAgent = React.useCallback((params) => {
        // TODO: bad typing
        closeAgentDialog();
        console.log({ editingAgentUid });
        if (editingAgentUid) {
            onUpdateAgent(editingAgentUid, params);
        }
        else {
            onCreateAgent(params);
        }
    }, [closeAgentDialog, editingAgentUid, onUpdateAgent, onCreateAgent]);
    const agentsEnriched = React.useMemo(() => {
        return agents
            .map((a) => {
            if (a.type === "Producer") {
                return Object.assign(Object.assign({}, a), { split: 1 - agents.reduce((tot, x) => { var _a; return tot + ((_a = x.split) !== null && _a !== void 0 ? _a : 0); }, 0) });
            }
            return a;
        })
            .sort((a, b) => {
            const aTypeIndx = agentTypes.indexOf(a.type);
            const bTypeIndx = agentTypes.indexOf(b.type);
            if (aTypeIndx < bTypeIndx)
                return -1;
            if (aTypeIndx > bTypeIndx)
                return 1;
            const aSplit = a.split || 0;
            const bSplit = b.split || 0;
            if (aSplit > bSplit)
                return -1;
            if (aSplit < bSplit)
                return 1;
            const aName = [a.agent.first_name, a.agent.last_name].filter((o) => o).join(" ");
            const bName = [b.agent.first_name, b.agent.last_name].filter((o) => o).join(" ");
            if (aName < bName)
                return -1;
            if (aName > bName)
                return 1;
            return 0;
        });
    }, [agents, agentTypes]);
    const noAgents = agentsEnriched.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(Person, null), title: "Agents", count: agentsEnriched.length, toolbar: onCreateAgent ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add agent") },
                React.createElement(IconButton, { size: "small", onClick: addAgent },
                    React.createElement(Add, { fontSize: "small" })))) : null },
            noAgents && React.createElement(Typography, { variant: "body2" }, "No agents"),
            agentsEnriched.length > 0 && (React.createElement(List, { dense: true }, agentsEnriched.map((agent) => (React.createElement(AgentListItem, { key: agent.uid, agent: agent, onEditAgent: onUpdateAgent ? editAgent : undefined, onRemoveAgent: onRemoveAgent })))))),
        isAgentDialogOpen && (React.createElement(AgentDialog, { DialogProps: { open: isAgentDialogOpen }, onClose: closeAgentDialog, onUpdate: createOrUpdateAgent, agent: agents.find((a) => a.uid === editingAgentUid), variant: variant, agentTypes: agentTypes }))));
};
export default Agents;
