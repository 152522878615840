import * as React from "react";
import { Alert, Grid2 } from "@mui/material";
import dayjs from "dayjs";
import usePaginatedQuery from "@app/hooks/api/usePaginatedQuery";
import useLoading from "@app/hooks/useLoading";
import { searchBuyerContracts } from "@app/orval/api/buyer-contracts";
import { searchBuyerLeads } from "@app/orval/api/buyer-leads";
import { listComments } from "@app/orval/api/comments";
import { getTransactionsForThreads, listEmails } from "@app/orval/api/emails";
import { listNewsfeedBuyerContractStageChanges, listNewsfeedBuyerLeadStageChanges, listNewsfeedFieldValueUpdates, listNewsfeedSellerContractStageChanges, listNewsfeedSellerLeadStageChanges, listNewsfeedBuyerLeadAgentChanges, listNewsfeedSellerLeadAgentChanges, } from "@app/orval/api/newsfeed";
import { searchSellerContracts } from "@app/orval/api/seller-contracts";
import { searchSellerLeads } from "@app/orval/api/seller-leads";
import CRMChangesReportSection from "./CRMChangesReportSection";
import { isRelevant } from "./utils";
const getFilter = (startDate, endDate) => {
    return [
        {
            uid: "parent",
            is_match_all: false,
            filters: [],
            parent_uid: null,
        },
        {
            is_match_all: true,
            filters: [
                {
                    uid: "",
                    timestamp_filter: {
                        timestamp_type: "Creation Timestamp",
                        comparator: "is after",
                        interval: 0,
                        units: "day",
                        rhs_value: startDate,
                        consider_time: true,
                    },
                },
                {
                    uid: "",
                    timestamp_filter: {
                        timestamp_type: "Creation Timestamp",
                        comparator: "is before",
                        interval: 0,
                        units: "day",
                        rhs_value: endDate,
                        consider_time: true,
                    },
                },
            ],
            uid: "",
            parent_uid: "parent",
        },
        {
            is_match_all: true,
            filters: [
                {
                    uid: "",
                    timestamp_filter: {
                        timestamp_type: "Last Update Timestamp",
                        comparator: "is after",
                        interval: 0,
                        units: "day",
                        rhs_value: startDate,
                        consider_time: true,
                    },
                },
                // {
                //   uid: "",
                //   timestamp_filter: {
                //     timestamp_type: "Last Update Timestamp",
                //     comparator: "is before",
                //     interval: 0,
                //     units: "day",
                //     rhs_value: endDate,
                //     consider_time: true,
                //   },
                // },
            ],
            uid: "",
            parent_uid: "parent",
        },
        {
            is_match_all: true,
            filters: [
                {
                    uid: "",
                    timestamp_filter: {
                        timestamp_type: "Last Stage Change Timestamp",
                        comparator: "is after",
                        interval: 0,
                        units: "day",
                        rhs_value: startDate,
                        consider_time: true,
                    },
                },
                // {
                //   uid: "",
                //   timestamp_filter: {
                //     timestamp_type: "Last Stage Change Timestamp",
                //     comparator: "is before",
                //     interval: 0,
                //     units: "day",
                //     rhs_value: endDate,
                //     consider_time: true,
                //   },
                // },
            ],
            uid: "",
            parent_uid: "parent",
        },
        {
            is_match_all: true,
            filters: [
                {
                    uid: "",
                    timestamp_filter: {
                        timestamp_type: "Last Comment Timestamp",
                        comparator: "is after",
                        interval: 0,
                        units: "day",
                        rhs_value: startDate,
                        consider_time: true,
                    },
                },
                // {
                //   uid: "",
                //   timestamp_filter: {
                //     timestamp_type: "Last Comment Timestamp",
                //     comparator: "is before",
                //     interval: 0,
                //     units: "day",
                //     rhs_value: endDate,
                //   },
                // },
            ],
            uid: "",
            parent_uid: "parent",
        },
        {
            is_match_all: true,
            filters: [
                {
                    uid: "",
                    timestamp_filter: {
                        timestamp_type: "Last Email Timestamp",
                        comparator: "is after",
                        interval: 0,
                        units: "day",
                        rhs_value: startDate,
                        consider_time: true,
                    },
                },
                // {
                //   uid: "",
                //   timestamp_filter: {
                //     timestamp_type: "Last Email Timestamp",
                //     comparator: "is before",
                //     interval: 0,
                //     units: "day",
                //     rhs_value: endDate,
                //   },
                // },
            ],
            uid: "",
            parent_uid: "parent",
        },
    ];
};
const getRelevantEmails = (params) => {
    const { clientEmails, emailsByParticipant, producerEmails } = params;
    return [...clientEmails, ...producerEmails]
        .map((x) => { var _a; return (_a = emailsByParticipant[x]) !== null && _a !== void 0 ? _a : []; })
        .flat()
        .filter((x, i, all) => {
        return !all.some((y, j) => x.id === y.id && i < j);
    })
        .filter((email) => {
        var _a, _b, _c, _d;
        return (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (clientEmails.has((_b = (_a = email.sender) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) !== null && _b !== void 0 ? _b : "") &&
            [...producerEmails].some((x) => [...email.to, ...email.cc, ...email.bcc].map((y) => y.toLowerCase().trim()).includes(x))) ||
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (producerEmails.has((_d = (_c = email.sender) === null || _c === void 0 ? void 0 : _c.toLowerCase().trim()) !== null && _d !== void 0 ? _d : "") &&
                [...clientEmails].some((x) => [...email.to, ...email.cc, ...email.bcc].map((y) => y.toLowerCase().trim()).includes(x))));
    });
};
const CRMChangesReportContent = (props) => {
    var _a;
    const { endDate, office, startDate } = props;
    const { data: fieldChanges, error: fieldChangesError } = usePaginatedQuery(listNewsfeedFieldValueUpdates, {
        earliest: startDate,
        latest: endDate,
        entity_types: ["Buyer Lead", "Seller Lead", "Buyer Contract", "Listing"],
    });
    const { data: buyerLeadAgentChanges, error: buyerLeadAgentChangesError } = usePaginatedQuery(listNewsfeedBuyerLeadAgentChanges, {
        earliest: startDate,
        latest: endDate,
    });
    const { data: sellerLeadAgentChanges, error: sellerLeadAgentChangesError } = usePaginatedQuery(listNewsfeedSellerLeadAgentChanges, {
        earliest: startDate,
        latest: endDate,
    });
    const { data: buyerLeadStageChanges, error: buyerLeadStageChangesError } = usePaginatedQuery(listNewsfeedBuyerLeadStageChanges, {
        earliest: startDate,
        latest: endDate,
    });
    const { data: sellerLeadStageChanges, error: sellerLeadStageChangesError } = usePaginatedQuery(listNewsfeedSellerLeadStageChanges, {
        earliest: startDate,
        latest: endDate,
    });
    const { data: buyerContractStageChanges, error: buyerContractStageChangesError } = usePaginatedQuery(listNewsfeedBuyerContractStageChanges, {
        earliest: startDate,
        latest: endDate,
    });
    const { data: sellerContractStageChanges, error: sellerContractStageChangesError } = usePaginatedQuery(listNewsfeedSellerContractStageChanges, {
        earliest: startDate,
        latest: endDate,
    });
    const { data: comments, error: commentsError } = usePaginatedQuery(listComments, {
        added_from: startDate,
        added_to: endDate,
    });
    const { data: buyerLeads, error: buyerLeadsError } = usePaginatedQuery(searchBuyerLeads, {
        filter: getFilter(startDate, endDate),
    });
    const { data: sellerLeads, error: sellerLeadsError } = usePaginatedQuery(searchSellerLeads, {
        filter: getFilter(startDate, endDate),
    });
    const { data: buyerContracts, error: buyerContractsError } = usePaginatedQuery(searchBuyerContracts, {
        filter: getFilter(startDate, endDate),
    });
    const { data: sellerContracts, error: sellerContractsError } = usePaginatedQuery(searchSellerContracts, {
        filter: getFilter(startDate, endDate),
    });
    const { data: emails, error: emailsError } = usePaginatedQuery(listEmails, {
        from_: dayjs(startDate).valueOf(),
        to: dayjs(endDate).valueOf(),
    });
    const { data: transactionsForThreads, error: transactionsForThreadsError } = usePaginatedQuery(getTransactionsForThreads, {
        thread_ids: [...new Set((_a = emails === null || emails === void 0 ? void 0 : emails.map((x) => x.thread_id)) !== null && _a !== void 0 ? _a : [])],
    }, { enabled: !!emails });
    const loading = useLoading({
        items: [
            {
                label: "Loading field changes",
                status: fieldChanges ? "success" : "pending",
                error: fieldChangesError,
            },
            {
                label: "Loading buyer lead stage changes...",
                status: buyerLeadStageChanges ? "success" : "pending",
                error: buyerLeadStageChangesError,
            },
            {
                label: "Loading seller lead stage changes...",
                status: sellerLeadStageChanges ? "success" : "pending",
                error: sellerLeadStageChangesError,
            },
            {
                label: "Loading buyer contract stage changes...",
                status: buyerContractStageChanges ? "success" : "pending",
                error: buyerContractStageChangesError,
            },
            {
                label: "Loading seller contract stage changes...",
                status: sellerContractStageChanges ? "success" : "pending",
                error: sellerContractStageChangesError,
            },
            {
                label: "Loading buyer agent changes...",
                status: buyerLeadAgentChanges ? "success" : "pending",
                error: buyerLeadAgentChangesError,
            },
            {
                label: "Loading seller agent changes...",
                status: sellerLeadAgentChanges ? "success" : "pending",
                error: sellerLeadAgentChangesError,
            },
            {
                label: "Loading comments...",
                status: comments ? "success" : "pending",
                error: commentsError,
            },
            {
                label: "Loading buyer leads...",
                status: buyerLeads ? "success" : "pending",
                error: buyerLeadsError,
            },
            {
                label: "Loading seller leads...",
                status: sellerLeads ? "success" : "pending",
                error: sellerLeadsError,
            },
            {
                label: "Loading buyer contracts...",
                status: buyerContracts ? "success" : "pending",
                error: buyerContractsError,
            },
            {
                label: "Loading seller contracts...",
                status: sellerContracts ? "success" : "pending",
                error: sellerContractsError,
            },
            {
                label: "Loading emails...",
                status: emails ? "success" : "pending",
                error: emailsError,
            },
            {
                label: `Getting leads & contracts for ${(emails === null || emails === void 0 ? void 0 : emails.length) ? `${emails.length} ` : ""}emails...`,
                status: transactionsForThreads ? "success" : "pending",
                error: transactionsForThreadsError,
            },
        ],
    });
    const emailsByParticipant = React.useMemo(() => {
        const res = {};
        for (const email of emails !== null && emails !== void 0 ? emails : []) {
            const addresses = [email.sender, ...email.to, ...email.cc, ...email.bcc]
                .filter((x) => x)
                .map((x) => x.toLowerCase().trim());
            for (const address of addresses) {
                if (!res[address]) {
                    res[address] = [];
                }
                res[address].push(email);
            }
        }
        return res;
    }, [emails]);
    const relevantBuyerLeads = React.useMemo(() => (buyerLeads !== null && buyerLeads !== void 0 ? buyerLeads : []).filter(isRelevant({ office })), [buyerLeads, office]);
    const relevantSellerLeads = React.useMemo(() => (sellerLeads !== null && sellerLeads !== void 0 ? sellerLeads : []).filter(isRelevant({ office })), [office, sellerLeads]);
    const relevantBuyerContracts = React.useMemo(() => (buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : []).filter(isRelevant({ office })), [buyerContracts, office]);
    const relevantSellerContracts = React.useMemo(() => (sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : []).filter(isRelevant({ office })), [office, sellerContracts]);
    const threadIdsByTransactionUid = React.useMemo(() => {
        const res = {};
        for (const transaction of transactionsForThreads !== null && transactionsForThreads !== void 0 ? transactionsForThreads : []) {
            if (res[transaction.transaction.uid] == null) {
                res[transaction.transaction.uid] = [];
            }
            res[transaction.transaction.uid].push(transaction.thread_id);
        }
        return res;
    }, [transactionsForThreads]);
    const emailsByThreadId = React.useMemo(() => {
        const res = {};
        for (const email of emails !== null && emails !== void 0 ? emails : []) {
            if (!res[email.thread_id]) {
                res[email.thread_id] = [];
            }
            res[email.thread_id].push(email);
        }
        return res;
    }, [emails]);
    const emailsByTransactionUid = React.useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const res = {};
        for (const buyerLead of relevantBuyerLeads) {
            const transactionUid = buyerLead.uid;
            const clientEmails = new Set(buyerLead.clients.filter((x) => x.client.email).map((x) => x.client.email.toLowerCase().trim()));
            const producerEmails = new Set(buyerLead.agents
                .filter((x) => x.type === "Producer" && x.agent.email)
                .map((x) => x.agent.email.toLowerCase().trim()));
            const threadIds = (_a = threadIdsByTransactionUid[transactionUid]) !== null && _a !== void 0 ? _a : [];
            const manuallyAttachedEmails = threadIds.map((x) => emailsByThreadId[x]).flat();
            const autoAttachedEmails = getRelevantEmails({ clientEmails, producerEmails, emailsByParticipant });
            const relevantEmails = [...manuallyAttachedEmails, ...autoAttachedEmails];
            for (const relevantEmail of relevantEmails) {
                if (!res[transactionUid]) {
                    res[transactionUid] = [];
                }
                res[transactionUid].push(relevantEmail);
            }
        }
        for (const sellerLead of relevantSellerLeads) {
            const transactionUid = sellerLead.uid;
            const clientEmails = new Set(sellerLead.clients.filter((x) => x.client.email).map((x) => x.client.email.toLowerCase().trim()));
            const producerEmails = new Set(sellerLead.agents
                .filter((x) => x.type === "Producer" && x.agent.email)
                .map((x) => x.agent.email.toLowerCase().trim()));
            const threadIds = (_b = threadIdsByTransactionUid[transactionUid]) !== null && _b !== void 0 ? _b : [];
            const manuallyAttachedEmails = threadIds.map((x) => emailsByThreadId[x]).flat();
            const autoAttachedEmails = getRelevantEmails({ clientEmails, producerEmails, emailsByParticipant });
            const relevantEmails = [...manuallyAttachedEmails, ...autoAttachedEmails];
            for (const relevantEmail of relevantEmails) {
                if (!res[transactionUid]) {
                    res[transactionUid] = [];
                }
                res[transactionUid].push(relevantEmail);
            }
        }
        for (const buyerContract of relevantBuyerContracts) {
            const transactionUid = buyerContract.uid;
            const clientEmails = new Set((_d = (_c = buyerContract.buyer_lead) === null || _c === void 0 ? void 0 : _c.clients) === null || _d === void 0 ? void 0 : _d.filter((x) => x.client.email).map((x) => x.client.email.toLowerCase().trim()));
            const producerEmails = new Set((_f = (_e = buyerContract.buyer_lead) === null || _e === void 0 ? void 0 : _e.agents) === null || _f === void 0 ? void 0 : _f.filter((x) => x.type === "Producer" && x.agent.email).map((x) => x.agent.email.toLowerCase().trim()));
            const threadIds = (_g = threadIdsByTransactionUid[transactionUid]) !== null && _g !== void 0 ? _g : [];
            const manuallyAttachedEmails = threadIds.map((x) => emailsByThreadId[x]).flat();
            const autoAttachedEmails = getRelevantEmails({ clientEmails, producerEmails, emailsByParticipant });
            const relevantEmails = [...manuallyAttachedEmails, ...autoAttachedEmails];
            for (const relevantEmail of relevantEmails) {
                if (!res[transactionUid]) {
                    res[transactionUid] = [];
                }
                res[transactionUid].push(relevantEmail);
            }
        }
        for (const sellerContract of relevantSellerContracts) {
            const transactionUid = sellerContract.uid;
            const clientEmails = new Set((_h = sellerContract.seller_lead) === null || _h === void 0 ? void 0 : _h.clients.filter((x) => x.client.email).map((x) => x.client.email.toLowerCase().trim()));
            const producerEmails = new Set((_j = sellerContract.seller_lead) === null || _j === void 0 ? void 0 : _j.agents.filter((x) => x.type === "Producer" && x.agent.email).map((x) => x.agent.email.toLowerCase().trim()));
            const threadIds = (_k = threadIdsByTransactionUid[transactionUid]) !== null && _k !== void 0 ? _k : [];
            const manuallyAttachedEmails = threadIds.map((x) => emailsByThreadId[x]).flat();
            const autoAttachedEmails = getRelevantEmails({ clientEmails, producerEmails, emailsByParticipant });
            const relevantEmails = [...manuallyAttachedEmails, ...autoAttachedEmails];
            for (const relevantEmail of relevantEmails) {
                if (!res[transactionUid]) {
                    res[transactionUid] = [];
                }
                res[transactionUid].push(relevantEmail);
            }
        }
        return res;
    }, [
        emailsByParticipant,
        emailsByThreadId,
        relevantBuyerContracts,
        relevantBuyerLeads,
        relevantSellerContracts,
        relevantSellerLeads,
        threadIdsByTransactionUid,
    ]);
    const stageChangesByBuyerLeadUid = React.useMemo(() => {
        const changes = {};
        buyerLeadStageChanges === null || buyerLeadStageChanges === void 0 ? void 0 : buyerLeadStageChanges.forEach((change) => {
            if (!changes[change.buyer_lead.uid]) {
                changes[change.buyer_lead.uid] = [];
            }
            changes[change.buyer_lead.uid].push(change);
        });
        return changes;
    }, [buyerLeadStageChanges]);
    const stageChangesBySellerLeadUid = React.useMemo(() => {
        const changes = {};
        sellerLeadStageChanges === null || sellerLeadStageChanges === void 0 ? void 0 : sellerLeadStageChanges.forEach((change) => {
            if (!changes[change.seller_lead.uid]) {
                changes[change.seller_lead.uid] = [];
            }
            changes[change.seller_lead.uid].push(change);
        });
        return changes;
    }, [sellerLeadStageChanges]);
    const stageChangesByBuyerContractUid = React.useMemo(() => {
        const changes = {};
        buyerContractStageChanges === null || buyerContractStageChanges === void 0 ? void 0 : buyerContractStageChanges.forEach((change) => {
            if (!changes[change.buyer_contract.uid]) {
                changes[change.buyer_contract.uid] = [];
            }
            changes[change.buyer_contract.uid].push(change);
        });
        return changes;
    }, [buyerContractStageChanges]);
    const stageChangesBySellerContractUid = React.useMemo(() => {
        const changes = {};
        sellerContractStageChanges === null || sellerContractStageChanges === void 0 ? void 0 : sellerContractStageChanges.forEach((change) => {
            if (!changes[change.seller_contract.uid]) {
                changes[change.seller_contract.uid] = [];
            }
            changes[change.seller_contract.uid].push(change);
        });
        return changes;
    }, [sellerContractStageChanges]);
    const agentChangesByBuyerLeadUid = React.useMemo(() => {
        const changes = {};
        buyerLeadAgentChanges === null || buyerLeadAgentChanges === void 0 ? void 0 : buyerLeadAgentChanges.forEach((change) => {
            if (!changes[change.buyer_lead.uid]) {
                changes[change.buyer_lead.uid] = [];
            }
            changes[change.buyer_lead.uid].push(change);
        });
        return changes;
    }, [buyerLeadAgentChanges]);
    const agentChangesBySellerLeadUid = React.useMemo(() => {
        const changes = {};
        sellerLeadAgentChanges === null || sellerLeadAgentChanges === void 0 ? void 0 : sellerLeadAgentChanges.forEach((change) => {
            if (!changes[change.seller_lead.uid]) {
                changes[change.seller_lead.uid] = [];
            }
            changes[change.seller_lead.uid].push(change);
        });
        return changes;
    }, [sellerLeadAgentChanges]);
    const commentsByTransactionUid = React.useMemo(() => {
        const changes = {};
        comments === null || comments === void 0 ? void 0 : comments.forEach((comment) => {
            var _a, _b;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const transactionUid = ((_a = comment.buyer) === null || _a === void 0 ? void 0 : _a.uid) || ((_b = comment.seller) === null || _b === void 0 ? void 0 : _b.uid);
            if (!changes[transactionUid]) {
                changes[transactionUid] = [];
            }
            changes[transactionUid].push(comment);
        });
        return changes;
    }, [comments]);
    const fieldChangesByTransactionUid = React.useMemo(() => {
        const changes = {};
        fieldChanges === null || fieldChanges === void 0 ? void 0 : fieldChanges.forEach((change) => {
            var _a, _b, _c, _d, _e;
            const transactionUid = (_e = (((_a = change.buyer_lead) === null || _a === void 0 ? void 0 : _a.uid) ||
                ((_b = change.seller_lead) === null || _b === void 0 ? void 0 : _b.uid) ||
                ((_c = change.buyer_contract) === null || _c === void 0 ? void 0 : _c.uid) ||
                ((_d = change.seller_contract) === null || _d === void 0 ? void 0 : _d.uid))) !== null && _e !== void 0 ? _e : "";
            if (!changes[transactionUid]) {
                changes[transactionUid] = [];
            }
            changes[transactionUid].push(change);
        });
        return changes;
    }, [fieldChanges]);
    const relevantBuyerLeadsFiltered = relevantBuyerLeads.filter((x) => {
        var _a;
        return (x.uid in stageChangesByBuyerLeadUid ||
            x.uid in commentsByTransactionUid ||
            x.uid in agentChangesByBuyerLeadUid ||
            (x.uid in fieldChangesByTransactionUid &&
                fieldChangesByTransactionUid[x.uid].some((f) => f.field_name === "Rejected Tags")) ||
            x.uid in emailsByTransactionUid ||
            (((_a = x.fields["Time Period"]) === null || _a === void 0 ? void 0 : _a.last_calculated_timestamp) &&
                x.fields["Time Period"].last_calculated_timestamp >= startDate &&
                x.fields["Time Period"].last_calculated_timestamp <= endDate) ||
            (startDate && x.created_at >= startDate && x.created_at <= startDate));
    });
    const relevantSellerLeadsFiltered = relevantSellerLeads.filter((x) => {
        var _a;
        return (x.uid in stageChangesBySellerLeadUid ||
            x.uid in agentChangesBySellerLeadUid ||
            x.uid in commentsByTransactionUid ||
            (x.uid in fieldChangesByTransactionUid &&
                fieldChangesByTransactionUid[x.uid].some((f) => f.field_name === "Rejected Tags")) ||
            x.uid in emailsByTransactionUid ||
            (((_a = x.fields["Time Period"]) === null || _a === void 0 ? void 0 : _a.last_calculated_timestamp) &&
                x.fields["Time Period"].last_calculated_timestamp >= startDate &&
                x.fields["Time Period"].last_calculated_timestamp <= endDate) ||
            (startDate && x.created_at >= startDate && x.created_at <= startDate));
    });
    const relevantBuyerContractsFiltered = relevantBuyerContracts.filter((x) => {
        var _a, _b;
        return (x.uid in stageChangesByBuyerContractUid ||
            x.uid in commentsByTransactionUid ||
            x.uid in emailsByTransactionUid ||
            // @ts-expect-error
            (((_b = (_a = x.buyer_lead) === null || _a === void 0 ? void 0 : _a.fields["Time Period"]) === null || _b === void 0 ? void 0 : _b.last_calculated_timestamp) &&
                // @ts-expect-error
                x.buyer_lead.fields["Time Period"].last_calculated_timestamp >= startDate &&
                // @ts-expect-error
                x.buyer_lead.fields["Time Period"].last_calculated_timestamp <= endDate) ||
            (startDate && x.created_at >= startDate && x.created_at <= startDate));
    });
    const relevantSellerContractsFiltered = relevantSellerContracts.filter((x) => {
        var _a, _b;
        return (x.uid in stageChangesBySellerContractUid ||
            x.uid in commentsByTransactionUid ||
            x.uid in emailsByTransactionUid ||
            (((_b = (_a = x.seller_lead) === null || _a === void 0 ? void 0 : _a.fields["Time Period"]) === null || _b === void 0 ? void 0 : _b.last_calculated_timestamp) &&
                x.seller_lead.fields["Time Period"].last_calculated_timestamp >= startDate &&
                x.seller_lead.fields["Time Period"].last_calculated_timestamp <= endDate) ||
            (startDate && x.created_at >= startDate && x.created_at <= startDate));
    });
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
        loading.loadingComponent && React.createElement(Grid2, null, loading.loadingComponent),
        React.createElement(Grid2, null),
        !loading.loadingComponent && (React.createElement(React.Fragment, null,
            !office && (React.createElement(Grid2, null,
                React.createElement(Alert, { severity: "info", variant: "outlined" }, "It looks like you haven't selected an office, so this report is probably showing more data than you actually need to update. Each office has different criteria for what should be updated, so select an office for more accurate results."))),
            React.createElement(Grid2, null,
                React.createElement(CRMChangesReportSection, { title: "Buyer Leads", buyerLeads: relevantBuyerLeadsFiltered, buyerLeadStageChanges: buyerLeadStageChanges !== null && buyerLeadStageChanges !== void 0 ? buyerLeadStageChanges : undefined, buyerLeadAgentChanges: buyerLeadAgentChanges !== null && buyerLeadAgentChanges !== void 0 ? buyerLeadAgentChanges : undefined, comments: comments !== null && comments !== void 0 ? comments : [], fieldValueChanges: fieldChanges !== null && fieldChanges !== void 0 ? fieldChanges : [], startDate: startDate, endDate: endDate, office: office, emailsByTransactionUid: emailsByTransactionUid })),
            React.createElement(Grid2, null,
                React.createElement(CRMChangesReportSection, { title: "Seller Leads", sellerLeads: relevantSellerLeadsFiltered, sellerLeadStageChanges: sellerLeadStageChanges !== null && sellerLeadStageChanges !== void 0 ? sellerLeadStageChanges : undefined, sellerLeadAgentChanges: sellerLeadAgentChanges !== null && sellerLeadAgentChanges !== void 0 ? sellerLeadAgentChanges : undefined, comments: comments !== null && comments !== void 0 ? comments : [], fieldValueChanges: fieldChanges !== null && fieldChanges !== void 0 ? fieldChanges : [], startDate: startDate, endDate: endDate, office: office, emailsByTransactionUid: emailsByTransactionUid })),
            React.createElement(Grid2, null,
                React.createElement(CRMChangesReportSection, { title: "Buyer Contracts", buyerContracts: relevantBuyerContractsFiltered, buyerContractStageChanges: buyerContractStageChanges !== null && buyerContractStageChanges !== void 0 ? buyerContractStageChanges : undefined, fieldValueChanges: fieldChanges !== null && fieldChanges !== void 0 ? fieldChanges : [], startDate: startDate, endDate: endDate, office: office, emailsByTransactionUid: emailsByTransactionUid })),
            React.createElement(Grid2, null,
                React.createElement(CRMChangesReportSection, { title: "Seller Contracts", sellerContracts: relevantSellerContractsFiltered, sellerContractStageChanges: sellerContractStageChanges !== null && sellerContractStageChanges !== void 0 ? sellerContractStageChanges : undefined, fieldValueChanges: fieldChanges !== null && fieldChanges !== void 0 ? fieldChanges : [], startDate: startDate, endDate: endDate, office: office, emailsByTransactionUid: emailsByTransactionUid }))))));
};
export default CRMChangesReportContent;
