import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Refresh } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import CoreLink from "@app/common/CoreLink";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import Loading from "@app/common/Loading";
import { useListMailschedule3Errors } from "@app/orval/api/mail-schedule-errors";
import { useListMailschedule3Templates } from "@app/orval/api/mail-schedule-templates";
import { useListStreakPipelines } from "@app/orval/api/streak-pipelines";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
const MailScheduleErrorsTable = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { boxKey, templateUid } = props;
    const listMailScheduleTemplatesApi = useListMailschedule3Templates(undefined, DEFAULT_CACHE_PARAMS);
    const templates = (_b = (_a = listMailScheduleTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listStreakPipelinesApi = useListStreakPipelines(DEFAULT_CACHE_PARAMS);
    const pipelines = (_d = (_c = listStreakPipelinesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listMailScheduleErrorsApi = useListMailschedule3Errors({ box_key: boxKey, template_uid: templateUid }, { query: { gcTime: 0 } });
    const errors = (_f = (_e = listMailScheduleErrorsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const refresh = React.useCallback(() => {
        listMailScheduleErrorsApi.refetch();
    }, [listMailScheduleErrorsApi]);
    const loading = [
        {
            label: "Loading templates...",
            queryResult: listMailScheduleTemplatesApi,
        },
        {
            label: "Loading pipelines...",
            queryResult: listStreakPipelinesApi,
        },
        {
            label: "Loading errors...",
            queryResult: listMailScheduleErrorsApi,
        },
    ];
    const showInfiniteSpinner = !templates || !pipelines || !errors;
    const loadingComponent = Loading({ items: loading, showInfiniteSpinner });
    if (loadingComponent || showInfiniteSpinner) {
        return loadingComponent;
    }
    const pipelinesByKey = mapValues(groupBy(pipelines, "key"), (val) => val[0]);
    const rows = errors.map((e) => {
        var _a, _b;
        return (Object.assign(Object.assign({}, e), { template: (_b = (_a = templates.find((t) => t.uid === e.template_uid)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : e.template_uid, error_type: (() => {
                if (e.is_data_error) {
                    return "Streak";
                }
                if (e.is_data_error === false) {
                    return "Template";
                }
                return null;
            })() }));
    });
    const columns = [
        {
            name: "timestamp",
            label: "Timestamp",
            options: {
                customBodyRender: (value) => {
                    if (value && dayjs_(value).isValid()) {
                        return dayjs_(value).format("dddd, MMMM D, YYYY h:mm A");
                    }
                    return value;
                },
            },
        },
        {
            name: "pipeline_key",
            label: "Pipeline",
            options: {
                customBodyRender: (value) => {
                    if (value) {
                        return (React.createElement(CoreLink, { href: `https://streak.com/a/pipelines/${value}`, target: "_blank" }, pipelinesByKey[value] ? pipelinesByKey[value].name : value));
                    }
                    return value;
                },
                sortCompare: (order) => (a, b) => {
                    const aPipeline = pipelinesByKey[a.data];
                    const bPipeline = pipelinesByKey[b.data];
                    if (aPipeline && !bPipeline)
                        return -1 * (order === "desc" ? -1 : 1);
                    if (!aPipeline && bPipeline)
                        return 1 * (order === "desc" ? -1 : 1);
                    if (!aPipeline && !bPipeline)
                        return 0;
                    if (aPipeline.name < bPipeline.name)
                        return -1 * (order === "desc" ? -1 : 1);
                    if (aPipeline.name > bPipeline.name)
                        return 1 * (order === "desc" ? -1 : 1);
                    return 0;
                },
            },
        },
        {
            name: "box_name",
            label: "Box",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const boxKey = rows[tableMeta.currentTableData[tableMeta.rowIndex].index].box_key;
                    if (boxKey) {
                        return (React.createElement(CoreLink, { href: `https://streak.com/a/boxes/${boxKey}`, target: "_blank" }, value || boxKey));
                    }
                    return value || boxKey;
                },
            },
        },
        {
            name: "template",
            label: "Template",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const templateUid = rows[tableMeta.currentTableData[tableMeta.rowIndex].index].template_uid;
                    return React.createElement(RouterLink, { to: `/mailschedule/templates/${templateUid}` }, value);
                },
            },
        },
        {
            name: "error_type",
            label: "Error Type",
        },
        {
            name: "error_message",
            label: "Error",
        },
    ];
    const options = {
        responsive: "standard",
        selectableRows: "none",
        download: false,
        print: false,
        filter: false,
        search: false,
        searchOpen: true,
        pagination: true,
        rowsPerPage: 200,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        viewColumns: false,
        expandableRows: false,
        customToolbar: () => (React.createElement(Tooltip, { title: "Refresh" },
            React.createElement(IconButton, { onClick: refresh },
                React.createElement(Refresh, null)))),
    };
    return React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 0 });
};
export default MailScheduleErrorsTable;
