/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * If the property address has already been geocoded via the Google Maps API, this endpoint simply looks up the previous result.

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Geocode multiple properties by address
 */
export const bulkGeocodeWithGoogleMaps = (bulkGeocodeBodyRequest, options) => {
    return axios.post(`/api/utilities/bulk_geocode`, bulkGeocodeBodyRequest, options);
};
export const getBulkGeocodeWithGoogleMapsMutationOptions = (options) => {
    const mutationKey = ['bulkGeocodeWithGoogleMaps'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkGeocodeWithGoogleMaps(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Geocode multiple properties by address
*/
export const useBulkGeocodeWithGoogleMaps = (options) => {
    const mutationOptions = getBulkGeocodeWithGoogleMapsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* If the property address has already been geocoded via the Google Maps API, this endpoint simply looks up the previous result.

_Authorization: only logged in, registered users can use this endpoint._
* @summary Geocode a property by address
*/
export const geocodeWithGoogleMaps = (params, options) => {
    return axios.get(`/api/utilities/geocode`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGeocodeWithGoogleMapsQueryKey = (params) => {
    return [`/api/utilities/geocode`, ...(params ? [params] : [])];
};
export const getGeocodeWithGoogleMapsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGeocodeWithGoogleMapsQueryKey(params);
    const queryFn = ({ signal }) => geocodeWithGoogleMaps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Geocode a property by address
 */
export function useGeocodeWithGoogleMaps(params, options) {
    const queryOptions = getGeocodeWithGoogleMapsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * If we've already gotten a photo for the provided address, this endpoint simply looks up the previous result.

_Authorization: none required_
 * @summary Get the Google street view photo for a property address
 */
export const getGoogleMapsStreetViewPhoto = (params, options) => {
    return axios.get(`/api/utilities/streetview`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetGoogleMapsStreetViewPhotoQueryKey = (params) => {
    return [`/api/utilities/streetview`, ...(params ? [params] : [])];
};
export const getGetGoogleMapsStreetViewPhotoQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetGoogleMapsStreetViewPhotoQueryKey(params);
    const queryFn = ({ signal }) => getGoogleMapsStreetViewPhoto(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the Google street view photo for a property address
 */
export function useGetGoogleMapsStreetViewPhoto(params, options) {
    const queryOptions = getGetGoogleMapsStreetViewPhotoQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
