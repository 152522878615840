import * as React from "react";
import { Add } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { renderDateTimeCell_Local } from "@app/common/grid/utils";
import BuyerContractActionsMenu from "@app/entrypoints/brokerage/features/crm/buyer-contracts/components/BuyerContractActionsMenu";
import BuyerLeadActionsMenu from "@app/entrypoints/brokerage/features/crm/buyer-leads/components/BuyerLeadActionsMenu";
import { StandardSmallCell } from "@app/entrypoints/brokerage/features/crm/components/spreadsheet-view/components";
import { DAYS_IN_STAGE_COLUMN, PRODUCER_LAST_UPDATED_TIMESTAMP_COLUMN, } from "@app/entrypoints/brokerage/features/crm/components/spreadsheet-view/utils";
import useClients from "@app/entrypoints/brokerage/features/crm/hooks/useClients";
import useCollaborators from "@app/entrypoints/brokerage/features/crm/hooks/useCollaborators";
import useStages from "@app/entrypoints/brokerage/features/crm/hooks/useStages";
import SellerContractActionsMenu from "@app/entrypoints/brokerage/features/crm/seller-contracts/components/SellerContractActionsMenu";
import SellerLeadActionsMenu from "@app/entrypoints/brokerage/features/crm/seller-leads/components/SellerLeadActionsMenu";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { countTransactions, countTransactionsByStatus, listTransactions } from "@app/orval/api/agent-dashboard";
import { useAddBuyerLead } from "@app/orval/api/buyer-leads";
import { useListFields } from "@app/orval/api/field-definitions";
import { useAddSellerLead } from "@app/orval/api/seller-leads";
import { useListStages } from "@app/orval/api/stages";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import Spreadsheet from "../../../../crm/components/spreadsheet-view/Spreadsheet";
import { agentCellValueGetter, clientCellValueFormatter, collaboratorCellValueFormatter, renderAgentsCell, renderClientsCell, renderCollaboratorsCell, } from "../../../../crm/components/spreadsheet-view/table-cells";
import { groupBy } from "../../../../crm/utils/utils";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
import usePostQuery from "../../usePostQuery";
import TransactionFilters from "../filters/TransactionFilters";
const FIELDS_EDITABLE_FROM_OVERVIEW = ["stage", "clients", "collaborators"];
const OverviewSpreadsheet = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    const { limit = 50, offset = 0, search, showNewsfeed = false, status } = props;
    const agentDashboardFilters = useAgentDashboardFilters();
    const queryClient = useQueryClient();
    const [sort, setSort] = React.useState(null);
    const handleSort = React.useCallback((field, direction) => {
        setSort({
            field,
            direction: direction === "descending" ? "desc" : "asc",
        });
    }, []);
    const query = pickBy({
        search: (search === null || search === void 0 ? void 0 : search.trim()) || undefined,
        statuses: status ? [status] : undefined,
        agent_uids: agentDashboardFilters.canAccessUsersFilter &&
            agentDashboardFilters.selectedUserUids.size > 0 &&
            agentDashboardFilters.selectedUserUids.size < ((_b = (_a = agentDashboardFilters.users) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0)
            ? [...agentDashboardFilters.selectedUserUids].sort()
            : undefined,
        offices: agentDashboardFilters.canAccessOfficeFilter &&
            agentDashboardFilters.selectedOfficeUids.size > 0 &&
            agentDashboardFilters.selectedOfficeUids.size < ((_d = (_c = agentDashboardFilters.offices) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0)
            ? [...agentDashboardFilters.selectedOfficeUids].sort()
            : undefined,
        teams: agentDashboardFilters.canAccessTeamsFilter &&
            agentDashboardFilters.selectedTeamUids.size > 0 &&
            agentDashboardFilters.selectedTeamUids.size < ((_f = (_e = agentDashboardFilters.teams) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0)
            ? [...agentDashboardFilters.selectedTeamUids].sort()
            : undefined,
        stage_uids: agentDashboardFilters.selectedStageUids.size > 0 &&
            agentDashboardFilters.selectedStageUids.size < ((_h = (_g = agentDashboardFilters.stages) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0)
            ? [...agentDashboardFilters.selectedStageUids].sort()
            : undefined,
        sources: agentDashboardFilters.selectedSources.size > 0 ? [...agentDashboardFilters.selectedSources].sort() : undefined,
        lead_creation_date__gte: (_j = agentDashboardFilters.leadCreationDateGTE) !== null && _j !== void 0 ? _j : undefined,
        lead_creation_date__lte: (_k = agentDashboardFilters.leadCreationDateLTE) !== null && _k !== void 0 ? _k : undefined,
        effective_date__gte: (_l = agentDashboardFilters.effectiveDateGTE) !== null && _l !== void 0 ? _l : undefined,
        effective_date__lte: (_m = agentDashboardFilters.effectiveDateLTE) !== null && _m !== void 0 ? _m : undefined,
        closing_date__gte: (_o = agentDashboardFilters.closingDateGTE) !== null && _o !== void 0 ? _o : undefined,
        closing_date__lte: (_p = agentDashboardFilters.closingDateLTE) !== null && _p !== void 0 ? _p : undefined,
        entity_types: ["Buyer Lead", "Seller Lead", "Buyer Contract", "Seller Contract"],
        ordering: sort
            ? [
                {
                    field: sort.field,
                    direction: sort.direction,
                },
            ]
            : undefined,
    }, 
    // omit undefined values
    // only care about `search` if it is provided (ignore other filters)
    (val, key) => val !== undefined && (key === "stage_uids" ? !(search === null || search === void 0 ? void 0 : search.trim()) : true));
    const paginatedQuery = pickBy(Object.assign({ limit, offset }, query), (val) => val != null);
    const updateSearch = useUpdateSearch();
    const addBuyerLeadApi = useAddBuyerLead();
    const addSellerLeadApi = useAddSellerLead();
    const navigate = useNavigate();
    const errorDialog = useErrorDialog();
    const session = useSession();
    const { setBuyerContractStage, setBuyerLeadStage, setSellerContractStage, setSellerLeadStage } = useStages();
    const { addClientForBuyerLead, addClientForSellerLead, canEditClientsCells, clientsDialogParams, closeClientsDialog, isClientsDialogOpen, openClientsDialog, removeClientForBuyerLead, removeClientForSellerLead, reorderClientsForBuyerLead, reorderClientsForSellerLead, setClientsDialogParams, } = useClients();
    const { addCollaboratorForBuyerLead, addCollaboratorForSellerLead, canEditCollaboratorsCells, closeCollaboratorsDialog, collaboratorsDialogParams, isCollaboratorsDialogOpen, openCollaboratorsDialog, removeCollaboratorForBuyerLead, removeCollaboratorForSellerLead, setCollaboratorsDialogParams, updateCollaboratorForBuyerLead, updateCollaboratorForSellerLead, } = useCollaborators();
    const isEnabled = !!(session === null || session === void 0 ? void 0 : session.viewingAsUser) &&
        !!agentDashboardFilters.offices &&
        !!agentDashboardFilters.teams &&
        !!agentDashboardFilters.stages &&
        !!agentDashboardFilters.users;
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = React.useMemo(() => {
        var _a, _b;
        const entityTypes = ["Buyer Lead", "Seller Lead", "Buyer Contract", "Seller Contract", "Listing"];
        return ((_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data.filter((x) => {
            return entityTypes.includes(x.entity_type);
        }).sort((a, b) => {
            const aIndx = entityTypes.indexOf(a.entity_type);
            const bIndx = entityTypes.indexOf(b.entity_type);
            if (aIndx < bIndx)
                return -1;
            if (aIndx > bIndx)
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null);
    }, [listFieldsApi.data]);
    const fieldsFiltered = React.useMemo(() => fields === null || fields === void 0 ? void 0 : fields.filter((x, i, all) => {
        return !all.some((y, j) => j > i && x.name === y.name && x.field_type === y.field_type);
    }), [fields]);
    const listTransactionsApi = usePostQuery(listTransactions, paginatedQuery, REFETCH_CACHE_PARAMS, isEnabled);
    const items = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTransactionsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listTransactionsApi.data]);
    const itemsByUid = React.useMemo(() => {
        return items ? groupBy(items, "uid") : null;
    }, [items]);
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_r = (_q = listStagesApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const stagesSorted = React.useMemo(() => {
        return stages === null || stages === void 0 ? void 0 : stages.sort((a, b) => {
            if (a.sort_order < b.sort_order)
                return -1;
            if (a.sort_order > b.sort_order)
                return 1;
            return 0;
        });
    }, [stages]);
    const stagesByUid = React.useMemo(() => {
        return stagesSorted ? groupBy(stagesSorted, "uid") : null;
    }, [stagesSorted]);
    const countTransactionsApi = usePostQuery(countTransactions, omit(query, "ordering", "entity_types"), REFETCH_CACHE_PARAMS, isEnabled);
    const count = (_t = (_s = countTransactionsApi.data) === null || _s === void 0 ? void 0 : _s.data.count) !== null && _t !== void 0 ? _t : null;
    const countByStatusApi = usePostQuery(countTransactionsByStatus, omit(query, "statuses", "ordering", "entity_types"), REFETCH_CACHE_PARAMS, isEnabled);
    const countByStatus = (_v = (_u = countByStatusApi.data) === null || _u === void 0 ? void 0 : _u.data) !== null && _v !== void 0 ? _v : null;
    const countByGroup = React.useMemo(() => {
        return countByStatus
            ? ["Lead", "Listing", "Contract", "Past Client"].map((statusName) => {
                var _a, _b;
                return ({
                    group: {
                        uid: statusName,
                        name: statusName,
                        onClick: () => {
                            updateSearch("status", statusName === status ? null : statusName, "offset", null);
                        },
                        isSelected: statusName === status,
                    },
                    count: (_b = (_a = countByStatus.find((x) => x.status === statusName)) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0,
                });
            })
            : null;
    }, [countByStatus, updateSearch, status]);
    const totalTransactionsApi = usePostQuery(countTransactions, {}, REFETCH_CACHE_PARAMS);
    const total = (_x = (_w = totalTransactionsApi.data) === null || _w === void 0 ? void 0 : _w.data.count) !== null && _x !== void 0 ? _x : null;
    const page = React.useMemo(() => {
        return Math.floor(offset / limit) + 1;
    }, [offset, limit]);
    const refresh = React.useCallback(() => {
        listTransactionsApi.refetch();
    }, [listTransactionsApi]);
    const getDetailURL = React.useCallback((row) => {
        const item = itemsByUid ? itemsByUid[row] : null;
        if ((item === null || item === void 0 ? void 0 : item.type) === "Buyer Lead" || (item === null || item === void 0 ? void 0 : item.type) === "Buyer Contract") {
            return `/buyers/${item.lead_uid}`;
        }
        if ((item === null || item === void 0 ? void 0 : item.type) === "Seller Lead" || (item === null || item === void 0 ? void 0 : item.type) === "Seller Contract") {
            return `/sellers/${item.lead_uid}`;
        }
        return "";
    }, [itemsByUid]);
    const getMailMergeEmailAddressesForItem = React.useCallback((row) => {
        var _a, _b, _c, _d, _e;
        return ((_e = (_d = (_b = (_a = row.buyer_lead) === null || _a === void 0 ? void 0 : _a.clients) !== null && _b !== void 0 ? _b : (_c = row.seller_lead) === null || _c === void 0 ? void 0 : _c.clients) !== null && _d !== void 0 ? _d : row.clients) !== null && _e !== void 0 ? _e : [])
            .map((x) => x.client.email)
            .filter((x) => x)
            .map((x) => x);
    }, []);
    const nonStandardColumns = React.useMemo(() => [
        {
            field: "type",
            headerName: "Type",
            editable: false,
            sortable: false,
        },
        {
            field: "Source",
            headerName: "Source",
            editable: false,
            sortable: false,
            // valueGetter: sourceCellValueGetter,
            // renderCell: renderSourceCell,
        },
        {
            field: "collaborators",
            headerName: "Collaborators",
            valueFormatter: collaboratorCellValueFormatter,
            renderCell: renderCollaboratorsCell,
            renderEditCell: renderCollaboratorsCell,
            editable: canEditCollaboratorsCells,
            sortable: false,
        },
        {
            field: "clients",
            headerName: "Clients",
            editable: canEditClientsCells,
            sortable: false,
            valueFormatter: clientCellValueFormatter,
            renderCell: renderClientsCell,
            renderEditCell: renderClientsCell,
        },
        {
            field: "producer",
            headerName: "Producer",
            editable: false,
            sortable: false,
            valueGetter: agentCellValueGetter,
            renderCell: renderAgentsCell,
        },
        {
            field: "co_agent",
            headerName: "Co-Agent",
            editable: false,
            sortable: false,
            valueGetter: agentCellValueGetter,
            renderCell: renderAgentsCell,
        },
        {
            field: "lead_created_at",
            headerName: "Lead Created",
            editable: false,
            renderCell: renderDateTimeCell_Local,
        },
        DAYS_IN_STAGE_COLUMN,
        PRODUCER_LAST_UPDATED_TIMESTAMP_COLUMN,
        // ...(fieldsSaturated ?? [])
        //   .filter((x) => x.isVisible)
        //   .filter((x, i, all) => {
        //     return !all.some((y, j) => j > i && x.name === y.name && x.field_type === y.field_type);
        //   })
        //   .map((x) => ({
        //     field: `fields.${x.uid}`,
        //     headerName: x.name,
        //     editable: false,
        //   })),
    ], [canEditClientsCells, canEditCollaboratorsCells]);
    const onCellEditStart = React.useCallback((params) => {
        var _a, _b, _c, _d, _e, _f;
        const box = (_a = itemsByUid[params.id]) !== null && _a !== void 0 ? _a : null;
        console.log("EDITING COL:", params.field);
        if (!box) {
            return;
        }
        if (params.field === "clients") {
            const clients = (_f = (_d = (_b = box.clients) !== null && _b !== void 0 ? _b : (_c = box.buyer_lead) === null || _c === void 0 ? void 0 : _c.clients) !== null && _d !== void 0 ? _d : (_e = box.seller_lead) === null || _e === void 0 ? void 0 : _e.clients) !== null && _f !== void 0 ? _f : [];
            setClientsDialogParams({ boxUid: box.uid, existingClients: clients });
            openClientsDialog();
        }
        if (params.field === "collaborators") {
            setCollaboratorsDialogParams({
                boxUid: box.uid,
                existingCollaborators: box.collaborators,
            });
            openCollaboratorsDialog();
        }
    }, [itemsByUid, openClientsDialog, openCollaboratorsDialog, setClientsDialogParams, setCollaboratorsDialogParams]);
    const renderActionsColumn = React.useCallback((params) => {
        const item = params.row;
        if (item.type === "Buyer Lead") {
            return React.createElement(BuyerLeadActionsMenu, { buyerLead: params.row });
        }
        if (item.type === "Seller Lead") {
            return React.createElement(SellerLeadActionsMenu, { sellerLead: params.row });
        }
        if (item.type === "Buyer Contract") {
            return React.createElement(BuyerContractActionsMenu, { buyerContract: params.row });
        }
        if (item.type === "Seller Contract") {
            return React.createElement(SellerContractActionsMenu, { sellerContract: params.row });
        }
        return null;
    }, []);
    const renderListItem = React.useCallback((item) => {
        let path = "";
        if (item.type === "Buyer Lead") {
            path = `/buyers/${item.uid}`;
        }
        else if (item.type === "Seller Lead") {
            path = `/sellers/${item.uid}`;
        }
        else if (item.type === "Buyer Contract") {
            path = `/buyers/${item.lead_uid}`;
        }
        else if (item.type === "Seller Contract") {
            path = `/sellers/${item.lead_uid}`;
        }
        else {
            console.warn("Item ", item, " has unexpected type ", item.type);
            throw new Error(`Unexpected type ${item.type}`);
        }
        return (React.createElement(StandardSmallCell, { name: item.name, stage: stagesByUid && item.stage ? stagesByUid[item.stage.uid] : undefined, 
            // @ts-expect-error: readonly
            collaborators: item.collaborators, link: path, allLinks: items === null || items === void 0 ? void 0 : items.map((x) => {
                if (x.type === "Buyer Lead") {
                    return `/buyers/${x.uid}`;
                }
                if (x.type === "Seller Lead") {
                    return `/sellers/${x.uid}`;
                }
                if (x.type === "Buyer Contract") {
                    return `/buyers/${x.lead_uid}`;
                }
                if (x.type === "Seller Contract") {
                    return `/sellers/${x.lead_uid}`;
                }
                return "";
            }) }));
    }, [items, stagesByUid]);
    const addBuyerLead = React.useCallback(() => {
        addBuyerLeadApi
            .mutateAsync({ data: {} })
            .then((result) => {
            navigate(`/buyers/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [addBuyerLeadApi, navigate, errorDialog]);
    const addSellerLead = React.useCallback(() => {
        addSellerLeadApi
            .mutateAsync({ data: {} })
            .then((result) => {
            navigate(`/sellers/${result.data.uid}`);
        })
            .catch(errorDialog);
    }, [addSellerLeadApi, navigate, errorDialog]);
    const additionalMenuItems = [
        React.createElement(MenuItem, { key: "add-buyer-lead", onClick: addBuyerLead },
            React.createElement(ListItemIcon, null,
                React.createElement(Add, { fontSize: "small" })),
            "Add buyer lead"),
        React.createElement(MenuItem, { key: "add-seller-lead", onClick: addSellerLead },
            React.createElement(ListItemIcon, null,
                React.createElement(Add, { fontSize: "small" })),
            "Add seller lead"),
    ];
    const rows = React.useMemo(() => {
        return items
            ? items.map((item) => {
                var _a, _b, _c, _d, _e, _f, _g;
                return (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, item), (_a = item.buyer_lead) === null || _a === void 0 ? void 0 : _a.fields), (_b = item.seller_lead) === null || _b === void 0 ? void 0 : _b.fields), (_d = (_c = item.buyer_contract) === null || _c === void 0 ? void 0 : _c.buyer_lead) === null || _d === void 0 ? void 0 : _d.fields), (_f = (_e = item.seller_contract) === null || _e === void 0 ? void 0 : _e.seller_lead) === null || _f === void 0 ? void 0 : _f.fields), item.fields), { entity_type: item.type, producer: item.agents.filter((a) => a.type === "Producer"), co_agent: item.agents.filter((a) => a.type === "Co-Agent"), showing_agent: item.agents.filter((a) => a.type === "Showing Agent"), fields: (_g = item.fields) !== null && _g !== void 0 ? _g : {} }));
            })
            : null;
    }, [items]);
    const isCellEditable = React.useCallback((params) => {
        // @ts-expect-error Argument of type 'string' is not assignable to parameter of type '"stage"'
        return FIELDS_EDITABLE_FROM_OVERVIEW.includes(params.field);
    }, []);
    const onRowUpdate = React.useCallback((params) => {
        const contractUids = params.data.contracts.map((x) => x.uid);
        // TODO: figure out how to return the right type here
        // @ts-expect-error
        queryClient.setQueryData(listTransactionsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    // update the lead and contracts
                    if (item.uid === params.data.uid || contractUids.includes(item.uid)) {
                        return Object.assign(Object.assign({}, item), { clients: params.data.clients });
                    }
                    return item;
                }) });
        });
    }, [queryClient, listTransactionsApi.queryKey]);
    const onRowStageUpdateBegin = React.useCallback((rowUid, stageUid) => {
        console.log("Set initially to stage: ", stageUid);
        queryClient.setQueryData(listTransactionsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            const fullIndx = prev.data.findIndex((x) => x.uid === rowUid);
            return Object.assign(Object.assign({}, prev), { data: [
                    ...prev.data.slice(0, fullIndx),
                    Object.assign(Object.assign({}, prev.data[fullIndx]), { stage: Object.assign(Object.assign({}, stagesByUid[stageUid]), { updated_at: new Date().toISOString() }) }),
                    ...prev.data.slice(fullIndx + 1, prev.data.length),
                ] });
        });
    }, [listTransactionsApi.queryKey, queryClient, stagesByUid]);
    const onRowStageUpdate = React.useCallback((params) => {
        console.log("Row update success:", params);
        queryClient.setQueryData(listTransactionsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === params.data.uid) {
                        return Object.assign(Object.assign({}, item), { stage: params.data.stage });
                    }
                    return item;
                }) });
        });
    }, [listTransactionsApi.queryKey, queryClient]);
    const onRowStageUpdateError = React.useCallback((transaction) => () => {
        console.log("Reset state to lead with stage:", transaction.stage);
        queryClient.setQueryData(listTransactionsApi.queryKey, (prev) => {
            if (prev == null) {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { data: prev.data.map((item) => {
                    if (item.uid === transaction.uid) {
                        return transaction;
                    }
                    return item;
                }) });
        });
    }, [listTransactionsApi.queryKey, queryClient]);
    const onRowStageChange = React.useCallback((params) => {
        const { row, stageUid } = params;
        const box = itemsByUid ? itemsByUid[row] : null;
        if (box) {
            if (box.type === "Buyer Lead") {
                setBuyerLeadStage(row, stageUid, {
                    onBeforeUpdate: onRowStageUpdateBegin,
                    onError: onRowStageUpdateError(box),
                    onBuyerLeadUpdated: onRowStageUpdate,
                });
            }
            if (box.type === "Buyer Contract") {
                setBuyerContractStage(row, stageUid, {
                    onBeforeUpdate: onRowStageUpdateBegin,
                    onError: onRowStageUpdateError(box),
                    onUpdate: onRowStageUpdate,
                });
            }
            if (box.type === "Seller Lead") {
                setSellerLeadStage(row, stageUid, {
                    onUpdateBegin: onRowStageUpdateBegin,
                    onError: onRowStageUpdateError(box),
                    onUpdate: onRowStageUpdate,
                });
            }
            if (box.type === "Seller Contract") {
                setSellerContractStage(row, stageUid, {
                    onUpdateBegin: onRowStageUpdateBegin,
                    onError: onRowStageUpdateError(box),
                    onUpdate: onRowStageUpdate,
                });
            }
        }
    }, [
        itemsByUid,
        setBuyerLeadStage,
        onRowStageUpdateBegin,
        onRowStageUpdateError,
        onRowStageUpdate,
        setBuyerContractStage,
        setSellerLeadStage,
        setSellerContractStage,
    ]);
    const onRowClientsUpdate = React.useCallback((params) => {
        const { rowUid, updateParams } = params;
        const box = itemsByUid ? itemsByUid[rowUid] : null;
        console.log("UPDATE CLIENTS FOR ROW:", rowUid, updateParams);
        if (box) {
            if (updateParams.contact_uid || updateParams.contact_data) {
                if (box.type === "Buyer Lead" || box.type === "Buyer Contract") {
                    addClientForBuyerLead(box.lead_uid, updateParams, { onUpdate: onRowUpdate });
                }
                else if (box.type === "Seller Lead" || box.type === "Seller Contract") {
                    addClientForSellerLead(box.lead_uid, updateParams, { onUpdate: onRowUpdate });
                }
            }
        }
        else {
            enqueueSnackbar(`Unable to find Box with uid: ${rowUid}`, { variant: "error" });
        }
        closeClientsDialog();
    }, [addClientForBuyerLead, addClientForSellerLead, closeClientsDialog, itemsByUid, onRowUpdate]);
    const onRowRemoveClient = React.useCallback((params) => {
        const { clientUid, rowUid } = params;
        const box = itemsByUid ? itemsByUid[rowUid] : null;
        console.log("REMOVE CLIENT FOR ROW:", rowUid, clientUid);
        if (box) {
            if (box.type === "Buyer Lead" || box.type === "Buyer Contract") {
                removeClientForBuyerLead(box.lead_uid, clientUid, { onRemove: onRowUpdate });
            }
            else if (box.type === "Seller Lead" || box.type === "Seller Contract") {
                removeClientForSellerLead(box.lead_uid, clientUid, { onRemove: onRowUpdate });
            }
        }
        else {
            enqueueSnackbar(`Unable to find Box with uid: ${rowUid}`, { variant: "error" });
        }
    }, [itemsByUid, onRowUpdate, removeClientForBuyerLead, removeClientForSellerLead]);
    const onRowReorderClients = React.useCallback((params) => {
        const { clientUids, rowUid } = params;
        console.log("REORDER CLIENTS FOR ROW:", rowUid, clientUids);
        const box = itemsByUid ? itemsByUid[rowUid] : null;
        if (box) {
            if (box.type === "Buyer Lead" || box.type === "Buyer Contract") {
                reorderClientsForBuyerLead({
                    buyerLeadUid: box.lead_uid,
                    clientUids,
                });
            }
            else if (box.type === "Seller Lead" || box.type === "Seller Contract") {
                reorderClientsForSellerLead({
                    sellerLeadUid: box.lead_uid,
                    clientUids,
                });
            }
        }
        else {
            enqueueSnackbar(`Unable to find Box with uid: ${rowUid}`, { variant: "error" });
        }
    }, [itemsByUid, reorderClientsForBuyerLead, reorderClientsForSellerLead]);
    const onRowCollaboratorUpdate = React.useCallback((params) => {
        const { collaboratorUid, rowUid, updateParams } = params;
        const box = itemsByUid ? itemsByUid[rowUid] : null;
        console.log("UPDATE COLLABORATOR FOR ROW:", rowUid, collaboratorUid, updateParams);
        if (box) {
            if (box.type === "Seller Lead" || box.type === "Seller Contract") {
                if (collaboratorUid) {
                    updateCollaboratorForSellerLead(box.lead_uid, collaboratorUid, updateParams, { onUpdate: onRowUpdate });
                }
                else {
                    addCollaboratorForSellerLead(rowUid, updateParams, { onSaveComplete: onRowUpdate });
                }
            }
            else if (box.type === "Buyer Lead" || box.type === "Buyer Contract") {
                if (collaboratorUid) {
                    updateCollaboratorForBuyerLead(box.lead_uid, collaboratorUid, updateParams, { onUpdate: onRowUpdate });
                }
                else {
                    addCollaboratorForBuyerLead(rowUid, updateParams, { onSaveComplete: onRowUpdate });
                }
            }
        }
        else {
            enqueueSnackbar(`Unable to find Box with uid: ${rowUid}`, { variant: "error" });
        }
    }, [
        addCollaboratorForBuyerLead,
        addCollaboratorForSellerLead,
        itemsByUid,
        onRowUpdate,
        updateCollaboratorForBuyerLead,
        updateCollaboratorForSellerLead,
    ]);
    const onRowRemoveCollaborator = React.useCallback((params) => {
        const { collaboratorUid, rowUid } = params;
        const box = itemsByUid ? itemsByUid[rowUid] : null;
        console.log("REMOVE COLLABORATOR FOR ROW:", rowUid, collaboratorUid);
        if (box) {
            if (box.type === "Seller Lead" || box.type === "Seller Contract") {
                removeCollaboratorForSellerLead(box.lead_uid, collaboratorUid, { onRemove: onRowUpdate });
            }
            else if (box.type === "Buyer Lead" || box.type === "Buyer Contract") {
                removeCollaboratorForBuyerLead(box.lead_uid, collaboratorUid, { onRemove: onRowUpdate });
            }
        }
        else {
            enqueueSnackbar(`Unable to find Box with uid: ${rowUid}`, { variant: "error" });
        }
    }, [itemsByUid, onRowUpdate, removeCollaboratorForBuyerLead, removeCollaboratorForSellerLead]);
    return (React.createElement(Spreadsheet, { title: "", fields: true ? [] : fieldsFiltered, stages: stagesSorted, countByGroup: countByGroup !== null && countByGroup !== void 0 ? countByGroup : [], items: rows, search: search, nonStandardColumns: nonStandardColumns, getMailMergeEmailAddressesForItem: getMailMergeEmailAddressesForItem, getDetailURL: getDetailURL, renderActionsColumn: renderActionsColumn, rowsPerPage: limit, count: count !== null && count !== void 0 ? count : null, total: total !== null && total !== void 0 ? total : null, page: page, onRefresh: refresh, onCellEditStart: onCellEditStart, renderListItem: renderListItem, canEditStages: true, loadingPercentComplete: undefined, isLoading: listTransactionsApi.isFetching, showNewsfeed: showNewsfeed, additionalMenuItems: additionalMenuItems, slots: {
            filters: React.createElement(TransactionFilters, null),
        }, error: listTransactionsApi.error || countTransactionsApi.error || countByStatusApi.error || listStagesApi.error, disableViews: true, tableName: "Overview", isCellEditable: isCellEditable, canSaveColumnArrangement: false, useDefaultColumnArrangement: true, onSort: handleSort, onRowStageChange: onRowStageChange, onRowClientChange: onRowClientsUpdate, onRowReorderClients: onRowReorderClients, onRowRemoveClient: onRowRemoveClient, isClientsDialogOpen: isClientsDialogOpen, onClientsDialogClose: closeClientsDialog, onRowCollaboratorChange: onRowCollaboratorUpdate, onRowRemoveCollaborator: onRowRemoveCollaborator, isCollaboratorsDialogOpen: isCollaboratorsDialogOpen, onCollaboratorsDialogClose: closeCollaboratorsDialog, collaboratorsDialogParams: collaboratorsDialogParams !== null && collaboratorsDialogParams !== void 0 ? collaboratorsDialogParams : undefined, clientsDialogParams: clientsDialogParams !== null && clientsDialogParams !== void 0 ? clientsDialogParams : undefined }));
};
export default OverviewSpreadsheet;
