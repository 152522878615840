var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Add, Delete, Edit, SupervisorAccount } from "@mui/icons-material";
import { Typography, List, ListItem, ListItemText, IconButton, Tooltip, Avatar, Grid2 } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useConfirm } from "material-ui-confirm";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useGetUser, useRemoveUserShadowing } from "@app/orval/api/users";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { getFullName, getInitials } from "@app/util/Utils";
import ShadowingDialog from "./ShadowingDialog";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const Shadowings = (props) => {
    const { user } = props;
    const [isShadowingDialogOpen, setIsShadowingDialogOpen] = React.useState(false);
    const [selectedShadowing, setSelectedShadowing] = React.useState(undefined);
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const getUserApi = useGetUser(user.uid, { query: { enabled: false } });
    const removeShadowingApi = useRemoveUserShadowing();
    const { shadowings } = user;
    const removeShadowing = React.useCallback((shadowingUid) => () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS);
        if (result.confirmed) {
            queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                if (!oldData) {
                    return oldData;
                }
                return Object.assign(Object.assign({}, oldData), { data: Object.assign(Object.assign({}, oldData.data), { shadowings: oldData.data.shadowings.filter((shadowing) => shadowing.uid !== shadowingUid) }) });
            });
            removeShadowingApi
                .mutateAsync({ userUid: user.uid, shadowingUid })
                .then((response) => {
                queryClient.setQueryData(getUserApi.queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }
                    return Object.assign(Object.assign({}, oldData), { data: response.data });
                });
            })
                .catch(errorDialog);
        }
    }), [confirm, errorDialog, getUserApi.queryKey, queryClient, removeShadowingApi, user.uid]);
    const openNewShadowingDialog = React.useCallback(() => {
        setSelectedShadowing(undefined);
        setIsShadowingDialogOpen(true);
    }, [setSelectedShadowing, setIsShadowingDialogOpen]);
    const closeShadowingDialog = React.useCallback(() => {
        setIsShadowingDialogOpen(false);
        setSelectedShadowing(undefined);
    }, [setIsShadowingDialogOpen, setSelectedShadowing]);
    const setSelectedShadowingAndOpenDialog = React.useCallback((shadowing) => () => {
        setSelectedShadowing(shadowing);
        setIsShadowingDialogOpen(true);
    }, [setSelectedShadowing, setIsShadowingDialogOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(SupervisorAccount, null), title: "Shadowings", count: shadowings.length, isCollapsedInitially: true, toolbar: React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add Shadowing"), placement: "top", arrow: true, disableInteractive: true },
                React.createElement("span", null,
                    React.createElement(IconButton, { onClick: openNewShadowingDialog, size: "small" },
                        React.createElement(Add, { fontSize: "small" })))) },
            shadowings.length === 0 && React.createElement(Typography, { variant: "body2" }, "No shadowings completed"),
            shadowings.length > 0 && (React.createElement(List, { dense: true }, shadowings.map((shadowing) => {
                var _a;
                return (React.createElement(ListItem, { key: shadowing.uid, secondaryAction: React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: "Edit Shadowing", placement: "top", arrow: true },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { edge: "end", "aria-label": "edit", onClick: setSelectedShadowingAndOpenDialog(shadowing) },
                                        React.createElement(Edit, null))))),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: "Delete Shadowing", placement: "top", arrow: true },
                                React.createElement("span", null,
                                    React.createElement(IconButton, { edge: "end", "aria-label": "delete", onClick: removeShadowing(shadowing.uid) },
                                        React.createElement(Delete, null)))))) },
                    React.createElement(ListItemText, { primary: React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1, alignItems: "center" },
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { variant: "body2" }, shadowing.shadowing_type.name)),
                            shadowing.shadowing_date_and_time && (React.createElement(React.Fragment, null,
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "caption", style: { color: "#888" } }, "\u2022")),
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "caption", style: { color: "#888" } },
                                        "At ",
                                        dayjs(shadowing.shadowing_date_and_time).format("dddd, MMMM D, YYYY h:mm A")))))), secondary: React.createElement(Grid2, { container: true, spacing: 0, direction: "column", wrap: "nowrap" },
                            React.createElement(Grid2, null,
                                React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap", alignItems: "center" },
                                    React.createElement(Grid2, null,
                                        React.createElement(Avatar, { src: (_a = shadowing.shadowed_user.icon_url) !== null && _a !== void 0 ? _a : undefined, style: { height: "22px", width: "22px", fontSize: "0.675rem" } }, getInitials(shadowing.shadowed_user.first_name, shadowing.shadowed_user.last_name))),
                                    React.createElement(Grid2, { style: { flex: 1 } },
                                        React.createElement(Typography, { variant: "caption", noWrap: true },
                                            "with ",
                                            getFullName(shadowing.shadowed_user.first_name, shadowing.shadowed_user.last_name)))))) })));
            })))),
        isShadowingDialogOpen && (React.createElement(ShadowingDialog, { DialogProps: { open: true, maxWidth: "sm", PaperProps: { sx: { minWidth: "500px" } } }, userUid: user.uid, shadowing: selectedShadowing, onClose: closeShadowingDialog }))));
};
export default Shadowings;
