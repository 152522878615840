import * as React from "react";
import { Delete, Edit, Folder, FolderOpen, Launch, Search, TextSnippet } from "@mui/icons-material";
import { Divider, Grid2, Button, Box, Typography, IconButton, Tooltip, TextField, InputAdornment } from "@mui/material";
import { TreeItem, SimpleTreeView } from "@mui/x-tree-view";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router";
import styled from "styled-components";
import CoreError from "@app/common/CoreError";
import Loading from "@app/common/Loading";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useCreateSopFolder, useDeleteSopFolder, useListSopFolders, useUpdateSopFolder, } from "@app/orval/api/sop-folders";
import { useDeleteSop, useListSops, useUpdateSop } from "@app/orval/api/sops";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { isChromeExtension } from "@app/util/Utils";
import EditSOPFolderDialog from "./EditSOPFolderDialog";
import { canEditSops } from "./utils";
const StyledSimpleTreeView = styled(SimpleTreeView) `
  width: 100%;
`;
const SOPs = () => {
    var _a, _b, _c, _d;
    const [isAddingFolder, setIsAddingFolder] = useStateWithCallback(false);
    const [currentlyEditingFolder, setCurrentlyEditingFolder] = React.useState(null);
    const [search, setSearch] = React.useState("");
    const queryClient = useQueryClient();
    const listSOPsApi = useListSops();
    const updateSOPApi = useUpdateSop();
    const deleteSOPApi = useDeleteSop();
    const listFoldersApi = useListSopFolders();
    const addFolderApi = useCreateSopFolder();
    const updateFolderApi = useUpdateSopFolder();
    const deleteFolderApi = useDeleteSopFolder();
    const sops = (_b = (_a = listSOPsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const folders = (_d = (_c = listFoldersApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const loading = [
        {
            label: "Loading SOPs...",
            status: listSOPsApi.status,
        },
        {
            label: "Loading Folders...",
            status: listFoldersApi.status,
        },
    ];
    const addFolder = React.useCallback(() => {
        setIsAddingFolder(true, () => {
            addFolderApi
                .mutateAsync({
                data: {
                    parent_uid: null,
                    name: "New Folder",
                },
            })
                .then((result) => {
                queryClient.setQueryData(listFoldersApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
                });
            })
                .catch((error) => {
                errorDialog(error);
                console.error("Error creating folder", error);
            })
                .then(() => {
                setIsAddingFolder(false);
            });
        });
    }, [addFolderApi, errorDialog, listFoldersApi.queryKey, queryClient, setIsAddingFolder]);
    const deleteFolder = React.useCallback((uid) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteFolderApi
                    .mutateAsync({ uid })
                    .then((result) => {
                    queryClient.setQueryData(listFoldersApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: old.data.filter((f) => f.uid !== result.data.uid) });
                    });
                })
                    .catch((error) => {
                    errorDialog(error);
                    console.error("Error deleting folder", error);
                });
            }
        });
    }, [confirm, deleteFolderApi, errorDialog, listFoldersApi.queryKey, queryClient]);
    const deleteSOP = React.useCallback((uid) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteSOPApi
                    .mutateAsync({ uid })
                    .then((result) => {
                    queryClient.setQueryData(listSOPsApi.queryKey, (old) => {
                        if (!old) {
                            return old;
                        }
                        return Object.assign(Object.assign({}, old), { data: old.data.filter((f) => f.uid !== result.data.uid) });
                    });
                })
                    .catch((error) => {
                    errorDialog(error);
                    console.error("Error deleting SOP", error);
                });
            }
        });
    }, [confirm, deleteSOPApi, errorDialog, listSOPsApi.queryKey, queryClient]);
    const openSop = React.useCallback((uid) => () => {
        if (isChromeExtension) {
            navigate(`/sops/${uid}/view`);
        }
        else {
            window.open(`${window.location.origin}/sops/${uid}/view`, "_blank");
        }
    }, [navigate]);
    const openEditFolderDialog = React.useCallback((folder) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentlyEditingFolder(folder);
    }, []);
    const closeEditFolderDialog = React.useCallback(() => {
        setCurrentlyEditingFolder(null);
    }, []);
    const updateFolder = React.useCallback((folder) => {
        if (!currentlyEditingFolder) {
            return;
        }
        updateFolderApi
            .mutateAsync({
            uid: currentlyEditingFolder.uid,
            data: {
                name: folder.name,
                parent_uid: folder.parent_uid,
            },
        })
            .then((result) => {
            queryClient.setQueryData(listFoldersApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((f) => (f.uid === result.data.uid ? result.data : f)) });
            });
        })
            .catch((error) => {
            errorDialog(error);
            console.error("Error updating folder", error);
        })
            .then(() => {
            closeEditFolderDialog();
        });
    }, [closeEditFolderDialog, currentlyEditingFolder, errorDialog, listFoldersApi.queryKey, queryClient, updateFolderApi]);
    const updateParentFolder = React.useCallback((result) => {
        const { destination } = result;
        console.log("DROP RESULT", result, destination);
        if (!destination) {
            return;
        }
        const sop = sops === null || sops === void 0 ? void 0 : sops.find((s) => s.uid === result.draggableId);
        if (!sop) {
            console.log("SOP not found", result.draggableId);
            return;
        }
        let destinationFolder = folders === null || folders === void 0 ? void 0 : folders.find((f) => f.uid === destination.droppableId);
        console.log({ folders });
        console.log("DESTINATION FOLDER", destinationFolder, destination.droppableId);
        if (!destinationFolder) {
            const parentFolderUid = destination.droppableId.replace("sops-droppable-", "");
            destinationFolder = folders === null || folders === void 0 ? void 0 : folders.find((f) => f.uid === parentFolderUid);
        }
        console.log("DROP SOP TO FOLDER", destinationFolder, result.draggableId);
        console.log("move sop", sop.name, "to folder", destinationFolder === null || destinationFolder === void 0 ? void 0 : destinationFolder.name);
        updateSOPApi
            .mutateAsync({
            uid: sop.uid,
            data: {
                parent_uid: destinationFolder ? destinationFolder.uid : null,
                name: sop.name,
                content: sop.content_text,
            },
        })
            .then((updateResult) => {
            queryClient.setQueryData(listSOPsApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((s) => (s.uid === updateResult.data.uid ? updateResult.data : s)) });
            });
        })
            .catch((error) => {
            errorDialog(error);
            console.error("Error updating SOP", error);
        });
    }, [errorDialog, folders, listSOPsApi.queryKey, queryClient, sops, updateSOPApi]);
    const renderTree = React.useCallback((parentUid) => {
        if (!folders || !sops) {
            return null;
        }
        // if there's an active search, we don't show folders
        const foldersFiltered = search.trim()
            ? []
            : folders
                .filter((f) => f.parent_uid === parentUid)
                .sort((a, b) => {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
        // show all SOPs in the `parentUid` folder
        // or just show everything in a list if there's an active search
        const sopsFiltered = sops
            .filter((s) => (!search.trim() && s.parent_uid === parentUid) ||
            (search.trim() &&
                (s.name.toLowerCase().includes(search.toLowerCase().trim()) ||
                    s.content_text.toLowerCase().includes(search.toLowerCase().trim()))))
            .sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
        const itemWidth = "calc(100% - 10px)";
        const canEditAll = canEditSops();
        return (React.createElement(React.Fragment, null,
            foldersFiltered.map((f) => {
                const label = (React.createElement(Grid2, { container: true, spacing: 1, style: { width: itemWidth } },
                    React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, { variant: "body2" }, f.name)),
                    React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true },
                            canEditAll && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Tooltip, { title: "Edit folder" },
                                    React.createElement(IconButton, { size: "small", onClick: openEditFolderDialog(f) },
                                        React.createElement(Edit, { fontSize: "small" }))))),
                            canEditAll && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                React.createElement(Tooltip, { title: "Delete folder", onClick: deleteFolder(f.uid) },
                                    React.createElement(IconButton, { size: "small" },
                                        React.createElement(Delete, { fontSize: "small" })))))))));
                return (React.createElement(Droppable, { key: f.uid, droppableId: f.uid, isCombineEnabled: true }, (droppableProvided) => (React.createElement(Grid2, Object.assign({ container: true }, droppableProvided.droppableProps, { ref: droppableProvided.innerRef }),
                    React.createElement(TreeItem, { itemId: f.uid, label: label, slots: { collapseIcon: Folder, expandIcon: FolderOpen }, style: { width: "100%" } }, renderTree(f.uid)),
                    droppableProvided.placeholder))));
            }),
            React.createElement(Droppable, { droppableId: `sops-droppable-${parentUid}` }, (droppableProvided) => (React.createElement(Grid2, Object.assign({ container: true }, droppableProvided.droppableProps, { ref: droppableProvided.innerRef }),
                sopsFiltered.map((s, index) => {
                    const canEditSOP = canEditSops([...s.sharing]);
                    const label = (React.createElement(Grid2, { container: true, spacing: 1, style: { width: itemWidth } },
                        React.createElement(Grid2, { style: { flex: 1, marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body2" }, s.name)),
                        React.createElement(Grid2, null,
                            React.createElement(Grid2, { container: true },
                                React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Tooltip, { title: "View SOP" },
                                        React.createElement(IconButton, { size: "small", component: RouterLink, target: "_blank", to: `/sops/${s.uid}/view` },
                                            React.createElement(Launch, { fontSize: "small" })))),
                                canEditSOP && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Tooltip, { title: "Edit SOP" },
                                        React.createElement(IconButton, { size: "small", component: RouterLink, target: "_blank", to: `/sops/${s.uid}` },
                                            React.createElement(Edit, { fontSize: "small" }))))),
                                canEditSOP && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                                    React.createElement(Tooltip, { title: "Delete SOP" },
                                        React.createElement(IconButton, { size: "small", onClick: deleteSOP(s.uid) },
                                            React.createElement(Delete, { fontSize: "small" })))))))));
                    return (React.createElement(Draggable, { key: `${s.uid}__${s.parent_uid}`, draggableId: s.uid, index: index }, (draggableProvided) => (React.createElement(Grid2, Object.assign({ key: `${s.uid}_draggable_inner`, ref: draggableProvided.innerRef }, draggableProvided.draggableProps, { container: true, size: 12, direction: "column", wrap: "nowrap" }),
                        React.createElement(TreeItem, Object.assign({ key: `${s.uid}_tree_item`, itemId: s.uid, label: label, slots: { icon: TextSnippet }, onDoubleClick: openSop(s.uid) }, draggableProvided.dragHandleProps))))));
                }),
                droppableProvided.placeholder)))));
    }, [deleteFolder, deleteSOP, folders, openEditFolderDialog, openSop, search, sops]);
    const onSearchChange = React.useCallback((e) => {
        const val = e.target.value;
        setSearch(val);
    }, [setSearch]);
    if (loading.find((x) => x.status !== "success")) {
        return React.createElement(Loading, { items: loading });
    }
    if (!sops || !folders) {
        return React.createElement(CoreError, { error: "An error occurred loading the component" });
    }
    const canEdit = canEditSops();
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, spacing: 2 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, spacing: 2 },
                        React.createElement(Grid2, null,
                            React.createElement(TextField, { style: { maxWidth: "400px" }, variant: "outlined", size: "small", slotProps: {
                                    input: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(Search, null))),
                                    },
                                }, value: search, onChange: onSearchChange })),
                        React.createElement(Grid2, { style: { flex: 1 } }),
                        canEdit && (React.createElement(Grid2, null,
                            React.createElement(Button, { variant: "outlined", onClick: addFolder, disabled: isAddingFolder }, "Add folder"))),
                        canEdit && (React.createElement(Grid2, null,
                            React.createElement(Button, { variant: "outlined", component: RouterLink, to: "/sops/new", disabled: isAddingFolder }, "Start New SOP")))))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Divider, null)),
            React.createElement(Grid2, { container: true, size: 12 },
                React.createElement(StyledSimpleTreeView, null,
                    React.createElement(DndProvider, { backend: HTML5Backend },
                        React.createElement(DragDropContext, { onDragEnd: updateParentFolder }, renderTree(null)))))),
        currentlyEditingFolder && (React.createElement(EditSOPFolderDialog, { dialogProps: {
                open: true,
            }, folder: currentlyEditingFolder, allFolders: folders, 
            // @ts-expect-error
            onFolderUpdated: updateFolder, onCancel: closeEditFolderDialog }))));
};
export default SOPs;
