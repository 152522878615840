import * as React from "react";
import { Comment } from "@mui/icons-material";
import { Divider, ListItemIcon, MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import CorePopover from "@app/common/CorePopover";
import { copyToClipboard, getFullName } from "@app/util/Utils";
import ActionsMenu from "@brokerage/features/crm/components/box-view/ActionsMenu";
import CommentPopover from "../../dashboards/agent-dashboard/components/pinned-boxes/CommentPopover";
import EmailMenuSection from "../components/box-view/ActionsMenu/EmailMenuSection";
import PhoneMenuSection from "../components/box-view/ActionsMenu/PhoneMenuSection";
const RecruitingActionsMenu = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { recruit } = props;
    const ref = React.useRef(null);
    const [isCommentPopoverOpen, setIsCommentPopoverOpen] = React.useState(false);
    const openCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(true);
    }, []);
    const closeCommentPopover = React.useCallback(() => {
        setIsCommentPopoverOpen(false);
    }, []);
    const copyQFALink = React.useCallback(() => {
        copyToClipboard(`${window.location.protocol}//${window.location.host}/forms/questions-for-affiliation/${recruit.uid}`);
        enqueueSnackbar("Copied QFA link to clipboard", { variant: "success" });
    }, [recruit.uid]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionsMenu, { ref: ref },
            React.createElement(MenuItem, { onClick: openCommentPopover },
                React.createElement(ListItemIcon, null,
                    React.createElement(Comment, null)),
                "Add a comment"),
            React.createElement(MenuItem, { onClick: copyQFALink }, "Copy Questions for the Affiliation link"),
            React.createElement(Divider, null),
            React.createElement(EmailMenuSection, { entityType: "Recruit", contacts: [
                    {
                        name: getFullName((_a = recruit.fields["First Name"]) !== null && _a !== void 0 ? _a : null, (_b = recruit.fields["Last Name"]) !== null && _b !== void 0 ? _b : null),
                        email: (_c = recruit.fields["Personal Email"]) !== null && _c !== void 0 ? _c : "",
                    },
                ], entityUids: [recruit.uid] }),
            React.createElement(Divider, null),
            React.createElement(PhoneMenuSection, { contacts: [
                    {
                        name: getFullName((_d = recruit.fields["First Name"]) !== null && _d !== void 0 ? _d : null, (_e = recruit.fields["Last Name"]) !== null && _e !== void 0 ? _e : null),
                        phone: (_f = recruit.fields["Phone"]) !== null && _f !== void 0 ? _f : "",
                    },
                ] })),
        isCommentPopoverOpen && (React.createElement(CorePopover, { open: true, anchorEl: ref.current },
            React.createElement(CommentPopover, { onCancel: closeCommentPopover, onSubmit: closeCommentPopover, entityUid: recruit.uid })))));
};
export default RecruitingActionsMenu;
