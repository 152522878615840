import * as React from "react";
import { Add, Groups } from "@mui/icons-material";
import { IconButton, List, Typography } from "@mui/material";
import CollapsibleSection from "./CollapsibleSection";
import ContactRelationshipListItem from "./ContactRelationshipListItem";
import RelatedContactDialog from "../actions/RelatedContactDialog";
const ContactRelationships = (props) => {
    const { contact, onCreateExistingContactRelationship, onCreateNewContactRelationship, onRemoveRelationship } = props;
    const [isRelatedContactDialogOpen, setIsRelatedContactDialogOpen] = React.useState(false);
    const openRelatedContactDialog = React.useCallback(() => {
        setIsRelatedContactDialogOpen(true);
    }, [setIsRelatedContactDialogOpen]);
    const closeRelatedContactDialog = React.useCallback(() => {
        setIsRelatedContactDialogOpen(false);
    }, [setIsRelatedContactDialogOpen]);
    const createExistingContactRelationship = React.useCallback((contactUid, relationship) => {
        onCreateExistingContactRelationship(contactUid, relationship);
        closeRelatedContactDialog();
    }, [closeRelatedContactDialog, onCreateExistingContactRelationship]);
    const createNewContactRelationship = React.useCallback((contactData, relationship) => {
        onCreateNewContactRelationship(contactData, relationship);
        closeRelatedContactDialog();
    }, [closeRelatedContactDialog, onCreateNewContactRelationship]);
    console.log(contact);
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { title: "Relationships", count: contact.relationships.length, icon: React.createElement(Groups, null), toolbar: React.createElement(IconButton, { size: "small", onClick: openRelatedContactDialog },
                React.createElement(Add, { fontSize: "small" })) },
            contact.relationships.length === 0 && React.createElement(Typography, { variant: "body2" }, "No relationships"),
            contact.relationships.length > 0 && (React.createElement(List, { dense: true }, contact.relationships.map((item) => (React.createElement(ContactRelationshipListItem, { key: item.related_contact_uid, relationship: item, onUnlink: onRemoveRelationship })))))),
        React.createElement(RelatedContactDialog, { DialogProps: { open: isRelatedContactDialogOpen }, onExistingContactSelected: createExistingContactRelationship, onNewContactSelected: createNewContactRelationship, onClose: closeRelatedContactDialog })));
};
export default ContactRelationships;
