/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get DASHscore history data.
 */
export const getDashscoreHistory = (params, options) => {
    return axios.get(`/api/dashscore/history`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetDashscoreHistoryQueryKey = (params) => {
    return [`/api/dashscore/history`, ...(params ? [params] : [])];
};
export const getGetDashscoreHistoryQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDashscoreHistoryQueryKey(params);
    const queryFn = ({ signal }) => getDashscoreHistory(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get DASHscore history data.
 */
export function useGetDashscoreHistory(params, options) {
    const queryOptions = getGetDashscoreHistoryQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get DashScore data
 */
export const getDashscoreData = (options) => {
    return axios.get(`/api/reports/dashscore`, options);
};
export const getGetDashscoreDataQueryKey = () => {
    return [`/api/reports/dashscore`];
};
export const getGetDashscoreDataQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDashscoreDataQueryKey();
    const queryFn = ({ signal }) => getDashscoreData(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get DashScore data
 */
export function useGetDashscoreData(options) {
    const queryOptions = getGetDashscoreDataQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get DASHscore data from Buyer Leads.
 */
export const getDashscoreBuyerLeadsData = (params, options) => {
    return axios.get(`/api/reports/dashscore/buyer_leads`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetDashscoreBuyerLeadsDataQueryKey = (params) => {
    return [`/api/reports/dashscore/buyer_leads`, ...(params ? [params] : [])];
};
export const getGetDashscoreBuyerLeadsDataQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDashscoreBuyerLeadsDataQueryKey(params);
    const queryFn = ({ signal }) => getDashscoreBuyerLeadsData(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get DASHscore data from Buyer Leads.
 */
export function useGetDashscoreBuyerLeadsData(params, options) {
    const queryOptions = getGetDashscoreBuyerLeadsDataQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets the spreadsheet ID of the Google Sheet used as the data source

_Authorization: none required_
 * @summary Get spreadsheet ID
 */
export const getDashscoreSpreadsheetId = (options) => {
    return axios.get(`/api/reports/dashscore/get_spreadsheet_id`, options);
};
export const getGetDashscoreSpreadsheetIdQueryKey = () => {
    return [`/api/reports/dashscore/get_spreadsheet_id`];
};
export const getGetDashscoreSpreadsheetIdQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDashscoreSpreadsheetIdQueryKey();
    const queryFn = ({ signal }) => getDashscoreSpreadsheetId(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get spreadsheet ID
 */
export function useGetDashscoreSpreadsheetId(options) {
    const queryOptions = getGetDashscoreSpreadsheetIdQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get my DASHscore
 */
export const getMyDashscore = (options) => {
    return axios.get(`/api/reports/dashscore/my_dashscore`, options);
};
export const getGetMyDashscoreQueryKey = () => {
    return [`/api/reports/dashscore/my_dashscore`];
};
export const getGetMyDashscoreQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMyDashscoreQueryKey();
    const queryFn = ({ signal }) => getMyDashscore(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get my DASHscore
 */
export function useGetMyDashscore(options) {
    const queryOptions = getGetMyDashscoreQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get my DASHscore
 */
export const getMyFlexCvr = (options) => {
    return axios.get(`/api/reports/dashscore/my_flex_cvr`, options);
};
export const getGetMyFlexCvrQueryKey = () => {
    return [`/api/reports/dashscore/my_flex_cvr`];
};
export const getGetMyFlexCvrQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMyFlexCvrQueryKey();
    const queryFn = ({ signal }) => getMyFlexCvr(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get my DASHscore
 */
export function useGetMyFlexCvr(options) {
    const queryOptions = getGetMyFlexCvrQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Sets the spreadsheet ID of the Google Sheet used as the data source

_Authorization: only logged in, registered users can use this endpoint._
 * @summary Set spreadsheet ID
 */
export const setDashscoreSpreadsheetId = (dashScoreGoogleSheetRequest, options) => {
    return axios.post(`/api/reports/dashscore/set_spreadsheet_id`, dashScoreGoogleSheetRequest, options);
};
export const getSetDashscoreSpreadsheetIdMutationOptions = (options) => {
    const mutationKey = ['setDashscoreSpreadsheetId'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return setDashscoreSpreadsheetId(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Set spreadsheet ID
*/
export const useSetDashscoreSpreadsheetId = (options) => {
    const mutationOptions = getSetDashscoreSpreadsheetIdMutationOptions(options);
    return useMutation(mutationOptions);
};
