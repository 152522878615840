import * as React from "react";
import { useCallback } from "react";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import { useAddClientForBuyerLead, useRemoveClientForBuyerLead, useReorderClientsForBuyerLead, } from "@app/orval/api/buyer-leads";
import { useAddSellerLeadClient, useRemoveSellerLeadClient, useReorderSellerLeadClients, } from "@app/orval/api/seller-leads";
export const useClients = () => {
    var _a, _b;
    const [isClientsDialogOpen, setIsClientsDialogOpen] = React.useState(false);
    const [clientsDialogParams, setClientsDialogParams] = React.useState(null);
    const session = useSession();
    const errorDialog = useErrorDialog();
    const canEditClientsCells = ["scripts@dashnc.com", "developer@dashnc.com"].includes((_b = (_a = session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : "");
    const addClientForBuyerLeadApi = useAddClientForBuyerLead();
    const removeClientForBuyerLeadApi = useRemoveClientForBuyerLead();
    const reorderClientsForBuyerLeadApi = useReorderClientsForBuyerLead();
    const addClientForSellerLeadApi = useAddSellerLeadClient();
    const removeClientForSellerLeadApi = useRemoveSellerLeadClient();
    const reorderClientsForSellerLeadApi = useReorderSellerLeadClients();
    const openClientsDialog = useCallback(() => {
        setIsClientsDialogOpen(true);
    }, []);
    const closeClientsDialog = useCallback(() => {
        setIsClientsDialogOpen(false);
    }, []);
    const addClientForBuyerLead = useCallback((buyerLeadUid, params, options) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        addClientForBuyerLeadApi
            .mutateAsync({
            buyerLeadUid,
            data,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [addClientForBuyerLeadApi, errorDialog]);
    const removeClientForBuyerLead = useCallback((buyerLeadUid, clientUid, options) => {
        removeClientForBuyerLeadApi
            .mutateAsync({
            buyerLeadUid,
            data: {
                contact_uid: clientUid,
            },
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeClientForBuyerLeadApi]);
    const reorderClientsForBuyerLead = useCallback((params, options) => {
        const { buyerLeadUid, clientUids } = params;
        reorderClientsForBuyerLeadApi
            .mutateAsync({
            buyerLeadUid,
            data: {
                client_uids: clientUids,
            },
        })
            .then((result) => {
            console.log({ result });
            if (options === null || options === void 0 ? void 0 : options.onReorder) {
                options.onReorder(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, reorderClientsForBuyerLeadApi]);
    const addClientForSellerLead = useCallback((sellerLeadUid, params, options) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        addClientForSellerLeadApi
            .mutateAsync({
            sellerLeadUid,
            data,
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onUpdate) {
                options.onUpdate(result);
            }
        })
            .catch(errorDialog);
    }, [addClientForSellerLeadApi, errorDialog]);
    const removeClientForSellerLead = useCallback((sellerLeadUid, clientUid, options) => {
        removeClientForSellerLeadApi
            .mutateAsync({
            sellerLeadUid,
            data: {
                contact_uid: clientUid,
            },
        })
            .then((result) => {
            if (options === null || options === void 0 ? void 0 : options.onRemove) {
                options.onRemove(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, removeClientForSellerLeadApi]);
    const reorderClientsForSellerLead = useCallback((params, options) => {
        const { clientUids, sellerLeadUid } = params;
        reorderClientsForSellerLeadApi
            .mutateAsync({
            sellerLeadUid,
            data: {
                client_uids: clientUids,
            },
        })
            .then((result) => {
            console.log({ result });
            if (options === null || options === void 0 ? void 0 : options.onReorder) {
                options.onReorder(result);
            }
        })
            .catch(errorDialog);
    }, [errorDialog, reorderClientsForSellerLeadApi]);
    return {
        isClientsDialogOpen,
        setIsClientsDialogOpen,
        openClientsDialog,
        closeClientsDialog,
        canEditClientsCells,
        clientsDialogParams,
        setClientsDialogParams,
        addClientForBuyerLead,
        removeClientForBuyerLead,
        reorderClientsForBuyerLead,
        addClientForSellerLead,
        removeClientForSellerLead,
        reorderClientsForSellerLead,
    };
};
export default useClients;
