/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List Renovation Budget
 */
export const listRenovationBudgetItems = (params, options) => {
    return axios.get(`/api/renovation_budget`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListRenovationBudgetItemsQueryKey = (params) => {
    return [`/api/renovation_budget`, ...(params ? [params] : [])];
};
export const getListRenovationBudgetItemsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListRenovationBudgetItemsQueryKey(params);
    const queryFn = ({ signal }) => listRenovationBudgetItems(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List Renovation Budget
 */
export function useListRenovationBudgetItems(params, options) {
    const queryOptions = getListRenovationBudgetItemsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a Renovation Budget Line Item
 */
export const createRenovationBudgetItem = (renovationBudgetLineItemCreateRequest, options) => {
    return axios.post(`/api/renovation_budget`, renovationBudgetLineItemCreateRequest, options);
};
export const getCreateRenovationBudgetItemMutationOptions = (options) => {
    const mutationKey = ['createRenovationBudgetItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createRenovationBudgetItem(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a Renovation Budget Line Item
*/
export const useCreateRenovationBudgetItem = (options) => {
    const mutationOptions = getCreateRenovationBudgetItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a Renovation Budget Line Item
*/
export const getRenovationBudgetItem = (uid, options) => {
    return axios.get(`/api/renovation_budget/${uid}`, options);
};
export const getGetRenovationBudgetItemQueryKey = (uid) => {
    return [`/api/renovation_budget/${uid}`];
};
export const getGetRenovationBudgetItemQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRenovationBudgetItemQueryKey(uid);
    const queryFn = ({ signal }) => getRenovationBudgetItem(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a Renovation Budget Line Item
 */
export function useGetRenovationBudgetItem(uid, options) {
    const queryOptions = getGetRenovationBudgetItemQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a Renovation Budget Line Item
 */
export const updateRenovationBudgetItem = (uid, renovationBudgetLineItemRequest, options) => {
    return axios.post(`/api/renovation_budget/${uid}`, renovationBudgetLineItemRequest, options);
};
export const getUpdateRenovationBudgetItemMutationOptions = (options) => {
    const mutationKey = ['updateRenovationBudgetItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateRenovationBudgetItem(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a Renovation Budget Line Item
*/
export const useUpdateRenovationBudgetItem = (options) => {
    const mutationOptions = getUpdateRenovationBudgetItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a Renovation Budget Line Item
*/
export const deleteRenovationBudgetItem = (uid, options) => {
    return axios.delete(`/api/renovation_budget/${uid}`, options);
};
export const getDeleteRenovationBudgetItemMutationOptions = (options) => {
    const mutationKey = ['deleteRenovationBudgetItem'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteRenovationBudgetItem(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a Renovation Budget Line Item
*/
export const useDeleteRenovationBudgetItem = (options) => {
    const mutationOptions = getDeleteRenovationBudgetItemMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count Renovation Budget
*/
export const countRenovationBudgetItems = (params, options) => {
    return axios.get(`/api/renovation_budget/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountRenovationBudgetItemsQueryKey = (params) => {
    return [`/api/renovation_budget/count`, ...(params ? [params] : [])];
};
export const getCountRenovationBudgetItemsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountRenovationBudgetItemsQueryKey(params);
    const queryFn = ({ signal }) => countRenovationBudgetItems(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count Renovation Budget
 */
export function useCountRenovationBudgetItems(params, options) {
    const queryOptions = getCountRenovationBudgetItemsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download Renovation Budget
 */
export const downloadRenovationBudgetItems = (params, options) => {
    return axios.get(`/api/renovation_budget/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadRenovationBudgetItemsQueryKey = (params) => {
    return [`/api/renovation_budget/download`, ...(params ? [params] : [])];
};
export const getDownloadRenovationBudgetItemsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadRenovationBudgetItemsQueryKey(params);
    const queryFn = ({ signal }) => downloadRenovationBudgetItems(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download Renovation Budget
 */
export function useDownloadRenovationBudgetItems(params, options) {
    const queryOptions = getDownloadRenovationBudgetItemsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
