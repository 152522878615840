import * as React from "react";
import { TextSnippet } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
const SnippetButton = (props) => {
    const { IconButtonProps } = props;
    const [snippetMenuAnchorEl, setSnippetMenuAnchorEl] = React.useState(null);
    const openSnippetMenu = React.useCallback((e) => {
        setSnippetMenuAnchorEl(e.currentTarget);
    }, []);
    const closeSnippetMenu = React.useCallback(() => {
        setSnippetMenuAnchorEl(null);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, Object.assign({ tabIndex: -1 }, IconButtonProps, { onClick: openSnippetMenu }),
            React.createElement(TextSnippet, null)),
        snippetMenuAnchorEl && (React.createElement(Menu, { anchorEl: snippetMenuAnchorEl, open: !!snippetMenuAnchorEl, onClose: closeSnippetMenu, onClick: closeSnippetMenu },
            React.createElement(MenuItem, { disabled: true }, "Add a snippet"),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { disabled: true }, "(this isn't working yet)")))));
};
export default SnippetButton;
