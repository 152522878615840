var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { generateTemporaryTwilioMediaUploadUrl } from "@app/orval/api/twilio";
// eslint-disable-next-line import/prefer-default-export
export const uploadFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const uploadUrl = yield generateTemporaryTwilioMediaUploadUrl({ mime_type: file.type });
    const response = yield fetch(uploadUrl.data.url, {
        method: "PUT",
        headers: {
            "Content-Type": file.type,
        },
        body: file,
    });
    if (!response.ok) {
        throw new Error(`Failed to upload file: ${response.statusText}`);
    }
    return uploadUrl.data.uid;
});
