/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List user activity
 */
export const listUserActivity = (params, options) => {
    return axios.get(`/api/activity`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListUserActivityQueryKey = (params) => {
    return [`/api/activity`, ...(params ? [params] : [])];
};
export const getListUserActivityQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListUserActivityQueryKey(params);
    const queryFn = ({ signal }) => listUserActivity(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List user activity
 */
export function useListUserActivity(params, options) {
    const queryOptions = getListUserActivityQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create or update a user activity log (i.e. with mouse/keyboard activity data)
 */
export const logUserActivity = (activityCreateRequest, options) => {
    return axios.post(`/api/activity`, activityCreateRequest, options);
};
export const getLogUserActivityMutationOptions = (options) => {
    const mutationKey = ['logUserActivity'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return logUserActivity(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create or update a user activity log (i.e. with mouse/keyboard activity data)
*/
export const useLogUserActivity = (options) => {
    const mutationOptions = getLogUserActivityMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Create or update a user activity log (i.e. with mouse/keyboard activity data)
*/
export const updateUserActivity = (uid, activityUpdateRequest, options) => {
    return axios.post(`/api/activity/${uid}`, activityUpdateRequest, options);
};
export const getUpdateUserActivityMutationOptions = (options) => {
    const mutationKey = ['updateUserActivity'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateUserActivity(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create or update a user activity log (i.e. with mouse/keyboard activity data)
*/
export const useUpdateUserActivity = (options) => {
    const mutationOptions = getUpdateUserActivityMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Log user activity (page visit ended)
*/
export const markPageVisitEnded = (uid, options) => {
    return axios.delete(`/api/activity/${uid}`, options);
};
export const getMarkPageVisitEndedMutationOptions = (options) => {
    const mutationKey = ['markPageVisitEnded'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return markPageVisitEnded(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Log user activity (page visit ended)
*/
export const useMarkPageVisitEnded = (options) => {
    const mutationOptions = getMarkPageVisitEndedMutationOptions(options);
    return useMutation(mutationOptions);
};
