import React, { useCallback, useContext } from "react";
import { Close, Info, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, Divider, Grid2, IconButton, InputAdornment, MenuItem, TextField, Tooltip, Typography, } from "@mui/material";
import CoreDateField from "@app/common/CoreDateField";
import CoreNumberField from "@app/common/CoreNumberField";
import { RequestDebuggingContext, requestMethods } from "../RequestDebuggingContext";
const Filters = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const context = useContext(RequestDebuggingContext);
    const closeFilters = useCallback(() => {
        context.setIsFiltersOpen(false);
    }, [context]);
    const setUserEmail = useCallback((e) => {
        context.setUserEmail(e.target.value.trim() || null);
    }, [context]);
    const setSearch = useCallback((e) => {
        context.setSearch(e.target.value.trim() || null);
    }, [context]);
    const setRequestMethod = useCallback((e) => {
        context.setRequestMethod((e.target.value.trim() || null));
    }, [context]);
    return (React.createElement(Grid2, { container: true, direction: "column", spacing: 2, wrap: "nowrap", style: { height: "100%", overflow: "hidden" } },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, direction: "row", spacing: 1, wrap: "nowrap", alignItems: "center" },
                    React.createElement(Grid2, { style: { flex: 1 } },
                        React.createElement(Typography, { variant: "h6" }, "Filters")),
                    React.createElement(Grid2, null,
                        React.createElement(IconButton, { onClick: closeFilters },
                            React.createElement(Close, null)))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, { style: { flex: 1, overflow: "auto" } },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 2, style: { height: "100%" } },
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Search"),
                                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                        React.createElement(Tooltip, { title: React.createElement(Typography, null, "Use RE2 syntax. See https://github.com/google/re2/wiki/Syntax") },
                                            React.createElement(Info, null)))),
                                },
                            }, size: "small", fullWidth: true, variant: "standard", value: (_a = context.search) !== null && _a !== void 0 ? _a : null, onChange: setSearch })),
                    React.createElement(Grid2, null,
                        React.createElement(CoreDateField, { size: "small", fullWidth: true, format: "M/D/YYYY h:mm A", formatType: "dayjs", mode: "datetime", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "From"),
                                },
                            }, date: (_b = context.fromDatetime) !== null && _b !== void 0 ? _b : null, onDateChange: context.setFromDatetime })),
                    React.createElement(Grid2, null,
                        React.createElement(CoreDateField, { size: "small", fullWidth: true, format: "M/D/YYYY h:mm A", formatType: "dayjs", mode: "datetime", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "To"),
                                },
                            }, date: (_c = context.toDatetime) !== null && _c !== void 0 ? _c : null, onDateChange: context.setToDatetime })),
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { size: "small", fullWidth: true, variant: "standard", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "User Email"),
                                },
                            }, value: (_d = context.userEmail) !== null && _d !== void 0 ? _d : "", onChange: setUserEmail })),
                    React.createElement(Grid2, null,
                        React.createElement(TextField, { size: "small", fullWidth: true, variant: "standard", select: true, slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Request Type"),
                                },
                            }, value: (_e = context.requestMethod) !== null && _e !== void 0 ? _e : "", onChange: setRequestMethod }, requestMethods.map((requestMethod) => (React.createElement(MenuItem, { key: requestMethod, value: requestMethod }, requestMethod))))),
                    React.createElement(Grid2, null,
                        React.createElement(CoreNumberField, { size: "small", fullWidth: true, variant: "standard", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Response Status"),
                                },
                            }, format: "0", number: (_f = context.responseStatus) !== null && _f !== void 0 ? _f : null, onNumberChange: context.setResponseStatus })),
                    React.createElement(Grid2, null,
                        React.createElement(CoreNumberField, { size: "small", fullWidth: true, variant: "standard", slotProps: {
                                input: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Min. Request Time (seconds)"),
                                },
                            }, format: "0,0.000", number: (_g = context.minRequestTimeSeconds) !== null && _g !== void 0 ? _g : null, onNumberChange: context.setMinRequestTimeSeconds })),
                    React.createElement(Grid2, { style: { textAlign: "center" } },
                        React.createElement(Button, { onClick: context.clearFilters, variant: "outlined" }, "Clear")),
                    React.createElement(Grid2, { style: { textAlign: "center" } },
                        React.createElement(Grid2, { container: true, spacing: 1 },
                            React.createElement(Grid2, null,
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Earlier") },
                                    React.createElement(IconButton, { onClick: context.next, disabled: !!((_h = context.searchRequestsApi) === null || _h === void 0 ? void 0 : _h.isFetching) },
                                        React.createElement(KeyboardArrowLeft, null)))),
                            React.createElement(Grid2, { style: { flex: 1 } }),
                            React.createElement(Grid2, null,
                                React.createElement(Button, { onClick: context.refresh, disabled: !!((_j = context.searchRequestsApi) === null || _j === void 0 ? void 0 : _j.isFetching), variant: "contained", color: "primary" }, context.offset === 0 ? "Refresh" : "Jump to Now")),
                            React.createElement(Grid2, { style: { flex: 1 } }),
                            React.createElement(Grid2, null,
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Later") },
                                    React.createElement(IconButton, { onClick: context.previous, disabled: !!((_k = context.searchRequestsApi) === null || _k === void 0 ? void 0 : _k.isFetching) },
                                        React.createElement(KeyboardArrowRight, null)))))))))));
};
export default Filters;
