import * as React from "react";
import { Check, Clear, Error } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";
import isObject from "lodash/isObject";
import { formatFieldValue } from "@app/util/Utils";
const getFieldValueString = (params) => {
    const { error, field, fieldValue } = params;
    if (error) {
        return "Error";
    }
    if (field.field_type === "checkbox") {
        if (fieldValue) {
            return React.createElement(Check, { fontSize: "small" });
        }
        return React.createElement(Clear, { fontSize: "small" });
    }
    if (fieldValue == null) {
        return "(blank)";
    }
    const fieldValueFormatted = formatFieldValue(field, fieldValue);
    if (fieldValueFormatted.trim() === "") {
        return "(blank)";
    }
    return fieldValueFormatted;
};
const DataEntryNonEditableField = (props) => {
    const { field, value } = props;
    const error = isObject(value) ? value["error"] : null;
    const fieldValueString = getFieldValueString({
        field,
        error,
        fieldValue: value,
    });
    return (React.createElement(Grid, { container: true, direction: "column", spacing: 0, wrap: "nowrap" },
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: "caption", style: {
                    color: "#888",
                } }, field.name)),
        React.createElement(Grid, { item: true, style: {
                overflow: "hidden",
            } },
            React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid, { item: true, style: { overflow: "hidden", flex: 1 } },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        React.createElement("pre", { style: {
                                fontFamily: "inherit",
                                whiteSpace: "pre-wrap",
                                margin: "0.25rem",
                                lineBreak: "anywhere",
                                fontStyle: !error && fieldValueString === "(blank)" ? "italic" : undefined,
                                color: !error && fieldValueString === "(blank)" ? "#888" : undefined,
                            } }, fieldValueString))),
                error && (React.createElement(Grid, { item: true },
                    React.createElement(Tooltip, { title: React.createElement(Typography, null, error) },
                        React.createElement(Error, { fontSize: "small", style: { color: "rgb(255,125,125)" } }))))))));
};
export default DataEntryNonEditableField;
