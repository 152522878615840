import * as React from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";
const ThreadSkeletonItem = () => {
    return (React.createElement(ListItem, null,
        React.createElement(ListItemAvatar, null,
            React.createElement(Skeleton, { variant: "circular", style: { height: "44px", width: "44px" } })),
        React.createElement(ListItemText, { primary: React.createElement(Skeleton, { variant: "rectangular" }), secondary: React.createElement(Skeleton, { variant: "text" }) })));
};
const ThreadSkeleton = () => {
    return (React.createElement(List, { dense: true },
        React.createElement(ThreadSkeletonItem, null),
        React.createElement(ThreadSkeletonItem, null),
        React.createElement(ThreadSkeletonItem, null),
        React.createElement(ThreadSkeletonItem, null),
        React.createElement(ThreadSkeletonItem, null)));
};
export default ThreadSkeleton;
