import * as React from "react";
import { Close, Search } from "@mui/icons-material";
import { Alert, Grid2, Box, Typography, Paper, Divider, Tooltip, FormControlLabel, Switch, IconButton, InputAdornment, } from "@mui/material";
import { regressionQuad, regressionLinear } from "d3-regression";
import numeral from "numeral";
import { Cell, ResponsiveContainer, Bar, CartesianGrid, ComposedChart, Line, Scatter, XAxis, YAxis, Tooltip as RechartsTooltip, Dot, Legend, } from "recharts";
import styled from "styled-components";
import CoreAnchor from "@app/common/CoreAnchor";
import CorePopover from "@app/common/CorePopover";
import CoreTextField from "@app/common/CoreTextField";
import RouterLink from "@app/common/RouterLink";
import AppConfig from "@app/util/AppConfig";
import { getPSPValueRangeAccuracy, getPSPValueRangeAccuracies, getPSPValueAccuracies, getStandardDeviation, getZScore, getLetterGrade, } from "./utils";
const CustomLegend = ({ renderLegend }) => renderLegend();
const CustomTooltipUnstyled = ({ active, payload, renderTooltip }) => {
    var _a;
    if (active && payload) {
        return (_a = renderTooltip(payload)) !== null && _a !== void 0 ? _a : null;
    }
    return null;
};
const CustomTooltip = styled(CustomTooltipUnstyled) `
    overflow-y: auto;
    overflow-x: hidden;
    & .recharts-tooltip-wrapper {
        overflow-y: scroll;
        overflow-x: hidden;
    }
`;
/** width of the recharts tooltip */
const TOOLTIP_WIDTH = 325;
/** set max domain so outliers don't make graph look weird */
const MAX_Z_SCORE_DOMAIN = 3;
/** set max domain so outliers don't make graph look weird */
const MAX_ACCURACY_DOMAIN = 0.3;
const BEST_FIT_OPTIONS = ["Quadratic", "Linear"];
const CLOSE_PRICE_SCATTER_COLOR = "#388E3C";
const DEFAULT_GRAPH_COLOR = AppConfig.themeColors.blue;
const LIST_PRICE_SCATTER_COLOR = AppConfig.themeColors.red;
const LEGEND_LABELS = [
    { name: "Range", color: DEFAULT_GRAPH_COLOR },
    { name: "Initial List Price", color: LIST_PRICE_SCATTER_COLOR },
    { name: "Close Price", color: CLOSE_PRICE_SCATTER_COLOR },
];
/**
 * creates a round point for use in scatter plot
 */
const customScatterPoint = (props_) => (React.createElement(Dot, { cx: props_.cx, cy: props_.cy, fill: props_.fill, r: 3 }));
/** formats tick numbers for recharts graph axis */
const formatTick = (fmt, domainLimits) => (tickItem) => {
    let tick = numeral(fmt.includes("%") ? tickItem * 100 : tickItem).format(fmt);
    if (domainLimits) {
        if (tickItem <= domainLimits.min) {
            tick += "-";
        }
        if (tickItem >= domainLimits.max) {
            tick += "+";
        }
    }
    return tick;
};
const formatLetterGradeTick = (value) => {
    return getLetterGrade(value);
};
const createLegend = () => {
    return (React.createElement(Box, { ml: 15, mb: 2 },
        React.createElement(Grid2, { container: true, spacing: 1 },
            React.createElement(Grid2, { size: 12, display: "flex" },
                React.createElement(Box, { display: "flex", ml: "auto", mr: "auto" }, LEGEND_LABELS.map((label) => (React.createElement(Box, { key: label.color, display: "flex", ml: 2, mr: 2 },
                    React.createElement(Box, { width: "15px", height: "15px", bgcolor: label.color, mr: 2, borderRadius: label.name === "Range" ? 0 : 7 }),
                    React.createElement(Typography, { style: { lineHeight: "17px" } }, label.name)))))))));
};
/**
 * PSP Accuracy Graphs.
 * `view` prop determines which graph to show.
 */
const PSPAccuracyChart = (props) => {
    const [bestFitCurveType, setBestFitCurveType] = React.useState(BEST_FIT_OPTIONS[0]);
    const [scatterPlotTooltipData, setScatterplotTooltipData] = React.useState(null);
    const [addressSearch, setAddressSearch] = React.useState(null);
    const [scatterPlotTooltipPos, setScatterPlotTooltipPos] = React.useState(null);
    const graphRef = React.createRef();
    const alertMessage = React.useMemo(() => {
        const { view } = props;
        if (["PSP Value Range"].includes(view)) {
            return null;
        }
        const standardMessage = (React.createElement(React.Fragment, null,
            React.createElement(Typography, null, "The accuracy of each PSP is measured by determined the percent difference between the property's Close Price and Average PSP Value."),
            React.createElement(Typography, null, "Standard Deviation (SD) measures the amount of variation in a dataset. A lower number means that there is less variation within the dataset.")));
        let message;
        if (view === "PSP Value Range vs. Close Price") {
            message = (React.createElement(Typography, null, "This scatter plot shows PSP Value Range (as a % of close price) vs. Close Price."));
            return message;
        }
        if (view === "Standard Deviation") {
            message = (React.createElement(Typography, null, "This graph shows each PSP's z-score (the number of SDs the PSP's accuracy is above or below the mean). The closer the z-score is to 0, the closer the PSP's accuracy is to the mean. A z-score of 1 means the PSP is 1 SD above the mean while a z-score of -1 means the PSP is 1 SD below the mean. Note that outliers may affect the Standard Deviation and could make some PSPs seem better than they actually are, so be sure to enter data as accurately as possible in the PSP module."));
        }
        if (view === "Percent Difference") {
            message = (React.createElement(Typography, null, "This graph shows the % difference of Close Price compared to PSP Value Avg for each psp."));
        }
        if (view === "Agent Scores") {
            message = (React.createElement(Typography, null, "This graph shows the magnitude of the t-value for each agent's psps. The t-value compares the accuracies of an agent's psps with the accuracies of all the psps. A lower value indicates greater overall accuracy."));
        }
        return (React.createElement(React.Fragment, null,
            standardMessage,
            message));
    }, [props]);
    const onSearchInputChange = React.useCallback((e) => {
        const val = e.target.value;
        setAddressSearch(val === "" ? null : val);
    }, [setAddressSearch]);
    const createCustomTooltip = React.useCallback((payload) => {
        var _a, _b;
        const { view } = props;
        const data = (_a = payload[0]) === null || _a === void 0 ? void 0 : _a.payload;
        if (!data) {
            return null;
        }
        let pspInfo;
        if (["Standard Deviation", "Percent Difference", "Agent Scores", "Agent Grades"].includes(view)) {
            // list info for all psps in category
            pspInfo = data.psps
                .filter((psp) => {
                var _a;
                return (addressSearch == null || ((_a = psp.address) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim().includes(addressSearch.toLowerCase().trim())));
            })
                .map((psp) => {
                var _a;
                return (React.createElement(Grid2, { key: `tooltip_psp_data_${psp.uid}`, container: true, size: 12 },
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Divider, null)),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, null,
                            React.createElement(CoreAnchor, { target: "_blank", href: `/matrix/${psp.uid}`, rel: "noreferrer" }, (_a = psp.address) !== null && _a !== void 0 ? _a : "Address Unknown"))),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { paddingLeft: "8px" } },
                            "\u2022",
                            ` Close Price: ${numeral(psp.close_price).format("$0,0")}`)),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { paddingLeft: "8px" } },
                            "\u2022",
                            ` PSP Value (Min): ${numeral(psp.psp_value_min).format("$0,0")}`)),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { paddingLeft: "8px" } },
                            "\u2022",
                            ` PSP Value (Avg): ${numeral(psp.psp_value_avg).format("$0,0")}`)),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { paddingLeft: "8px" } },
                            "\u2022",
                            ` PSP Value (High): ${numeral(psp.psp_value_high).format("$0,0")}`))));
            });
        }
        return (React.createElement(Paper, { elevation: 4 },
            React.createElement(Grid2, { container: true, spacing: 1, style: {
                    overflow: "auto",
                    maxHeight: "250px",
                    maxWidth: `${TOOLTIP_WIDTH}px`,
                    overflowX: "hidden",
                    padding: "4px",
                } },
                React.createElement(React.Fragment, null,
                    ["Agent Grades", "Agent Scores"].includes(view) && (React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { fontWeight: "bold" } }, `Created By: ${data.name}`))),
                    view === "Agent Grades" && (React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { fontWeight: "bold" } }, `Grade: ${(_b = data.letterGrade) !== null && _b !== void 0 ? _b : "-"}`))),
                    view === "Agent Scores" && (React.createElement(React.Fragment, null,
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(Typography, { style: { fontWeight: "bold" } }, `Score: ${data.score != null ? numeral(data.score).format("0,0.00") : "-"}`)),
                        React.createElement(Grid2, { size: 12 },
                            React.createElement(Typography, { style: { fontWeight: "bold" } }, `T-Value: ${data.tValue != null ? numeral(data.tValue).format("0,0.00") : "-"}`)))),
                    ["Agent Grades", "Agent Scores"].includes(view) && (React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { fontWeight: "bold" } }, `Total Close Price: ${numeral(data.totalClosePrice).format("$0,0")}`))),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { style: { fontWeight: "bold" } }, `Total PSPs: ${numeral(pspInfo.length).format("0,0")}`)),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(CoreTextField, { variant: "standard", size: "small", InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(Search, null))),
                            }, fullWidth: true, placeholder: "Address", value: addressSearch, onChange: onSearchInputChange })),
                    pspInfo))));
    }, [props, addressSearch, onSearchInputChange]);
    const openScatterPlotTooltip = React.useCallback((data, _, event) => {
        const position = {
            left: event.clientX,
            top: event.clientY,
        };
        setScatterPlotTooltipPos(position);
        setScatterplotTooltipData(data == null ? null : data.payload);
    }, [setScatterPlotTooltipPos, setScatterplotTooltipData]);
    const closeScatterPlotTooltip = React.useCallback(() => {
        setScatterPlotTooltipPos(null);
        setScatterplotTooltipData(null);
    }, [setScatterPlotTooltipPos, setScatterplotTooltipData]);
    /** listens for key events on the popover */
    const handleKeyDown = React.useCallback((e) => {
        if (e.key.toLowerCase() === "escape") {
            closeScatterPlotTooltip();
        }
    }, [closeScatterPlotTooltip]);
    const changeLineOfBestFit = React.useCallback((_, isChecked) => {
        setBestFitCurveType(isChecked ? "Quadratic" : "Linear");
    }, [setBestFitCurveType]);
    const { dataByEmail, measureAccuracyFrom, psps, view } = props;
    // get standard deviation
    const closePricePSPValueRatios = measureAccuracyFrom === "PSP Value Range" ? getPSPValueRangeAccuracies(psps) : getPSPValueAccuracies(psps);
    const average = closePricePSPValueRatios.length > 0
        ? closePricePSPValueRatios.reduce((a, b) => a + b) / closePricePSPValueRatios.length
        : null;
    const standardDeviation = getStandardDeviation(closePricePSPValueRatios);
    // format data
    const rawData = psps
        .map((psp) => {
        const closePrice = psp.close_price;
        const pspValueAvg = psp.psp_value_avg;
        const { psp_value_high, psp_value_min } = psp;
        // accuracy
        let closePriceAccuracy = null;
        if (measureAccuracyFrom === "PSP Value Range") {
            closePriceAccuracy = getPSPValueRangeAccuracy(psp);
        }
        else {
            closePriceAccuracy = pspValueAvg ? (closePrice - pspValueAvg) / pspValueAvg : null;
        }
        const closePriceAccuracyRounded = closePriceAccuracy == null ? null : Math.round(closePriceAccuracy * 100) / 100;
        let closePriceAccuracyCategory = null;
        if (closePriceAccuracyRounded != null) {
            if (closePriceAccuracyRounded >= 0) {
                closePriceAccuracyCategory = String(Math.min(closePriceAccuracyRounded, MAX_ACCURACY_DOMAIN));
            }
            else {
                closePriceAccuracyCategory = String(Math.max(closePriceAccuracyRounded, -1 * MAX_ACCURACY_DOMAIN));
            }
        }
        // avg range as percent of close price
        const pspValueRange = psp_value_high - psp_value_min;
        const pspValueRangeRatio = closePrice ? pspValueRange / closePrice : null;
        // z-score
        const zScore = closePriceAccuracy && average && standardDeviation
            ? getZScore(closePriceAccuracy, average, standardDeviation)
            : null;
        const zScoreRounded = zScore == null ? null : Math.round(zScore * 10) / 10; // round to nearest tenth
        let zScoreCategory = null;
        if (zScoreRounded != null) {
            if (zScoreRounded >= 0) {
                zScoreCategory = String(Math.min(zScoreRounded, MAX_Z_SCORE_DOMAIN));
            }
            else {
                zScoreCategory = String(Math.max(zScoreRounded, -1 * MAX_Z_SCORE_DOMAIN));
            }
        }
        return Object.assign(Object.assign({}, psp), { uid: psp.uid, Range: [psp_value_min, psp_value_high], pspValueRange,
            pspValueRangeRatio,
            zScore,
            closePriceAccuracy,
            zScoreCategory,
            closePriceAccuracyCategory });
    })
        .sort((a, b) => {
        const aClosePrice = a.close_price;
        const bClosePrice = b.close_price;
        if (aClosePrice > bClosePrice)
            return -1;
        if (aClosePrice < bClosePrice)
            return 1;
        return 0;
    });
    let graphData = rawData;
    let equation = null;
    let rSquaredValue;
    if (view === "PSP Value Range vs. Close Price") {
        graphData = rawData.filter((psp) => psp.pspValueRangeRatio != null);
        const bestFitData = graphData
            .map((psp) => ({
            x: psp.close_price,
            y: psp.pspValueRangeRatio,
        }))
            .sort((a, b) => {
            if (a.x > b.x)
                return 1;
            if (a.x < b.x)
                return -1;
            return 0;
        });
        if (bestFitCurveType === "Quadratic") {
            const regressionGenerator = regressionQuad()
                .x((d) => d.x)
                .y((d) => d.y);
            const regression = regressionGenerator(bestFitData);
            const { a, b, c, rSquared } = regression;
            if (bestFitData.length > 2 &&
                a != null &&
                !Number.isNaN(a) &&
                b != null &&
                !Number.isNaN(b) &&
                c != null &&
                !Number.isNaN(c)) {
                const minX = bestFitData[0].x;
                const maxX = bestFitData[bestFitData.length - 1].x;
                for (let currentX = minX; currentX <= maxX; currentX += 1000) {
                    // @ts-expect-error best fit data is not same type as normal graph data
                    graphData.push({
                        close_price: currentX,
                        bestFitY: (a * Math.pow(currentX, 2) + b * currentX + c),
                    });
                }
                equation = (React.createElement(Typography, null,
                    `${a.toPrecision(2)}x`,
                    React.createElement("sup", null, "2"),
                    ` + ${b.toPrecision(2)}x + ${c.toPrecision(2)}`));
            }
            rSquaredValue = rSquared != null && !Number.isNaN(rSquared) ? rSquared : null;
        }
        else {
            const regressionGenerator = regressionLinear()
                .x((d) => d.x)
                .y((d) => d.y);
            const regression = regressionGenerator(bestFitData);
            const { a, b, rSquared } = regression;
            if (bestFitData.length > 1 && a != null && !Number.isNaN(a) && b != null && !Number.isNaN(b)) {
                graphData = graphData.map((item) => (Object.assign(Object.assign({}, item), { bestFitY: regression.a * item.close_price + regression.b })));
                equation = (React.createElement(Typography, { style: { lineHeight: "25px" } }, `${a.toPrecision(2)}x + ${b.toPrecision(2)}`));
            }
            rSquaredValue = rSquared != null && !Number.isNaN(rSquared) ? rSquared : null;
        }
    }
    if (view === "Standard Deviation") {
        const pspsByZScore = {};
        const maxZScoreRounded = Math.min(Math.max(...rawData.map((psp) => { var _a; return Math.abs(Math.round(((_a = psp.zScore) !== null && _a !== void 0 ? _a : 0) * 10) / 10); })), MAX_Z_SCORE_DOMAIN);
        rawData.forEach((item) => {
            const { zScore, zScoreCategory } = item;
            if (zScore != null && zScoreCategory != null) {
                if (!Object.keys(pspsByZScore).includes(zScoreCategory)) {
                    pspsByZScore[zScoreCategory] = {
                        psps: [],
                        numPSPs: 0,
                        zScore,
                    };
                }
                pspsByZScore[zScoreCategory].psps.push(item);
                pspsByZScore[zScoreCategory].numPSPs += 1;
            }
        });
        if (!pspsByZScore[String(maxZScoreRounded)]) {
            pspsByZScore[String(maxZScoreRounded)] = {
                psps: [],
                numPSPs: 0,
                zScore: maxZScoreRounded,
            };
        }
        if (!pspsByZScore[String(-1 * maxZScoreRounded)]) {
            pspsByZScore[String(-1 * maxZScoreRounded)] = {
                psps: [],
                numPSPs: 0,
                zScore: -1 * maxZScoreRounded,
            };
        }
        graphData = Object.keys(pspsByZScore)
            .map((item) => (Object.assign(Object.assign({}, pspsByZScore[item]), { zScoreCategory: parseFloat(item) })))
            .sort((a, b) => {
            const aZScore = a.zScore;
            const bZScore = b.zScore;
            if (aZScore != null && bZScore != null) {
                if (aZScore > bZScore)
                    return 1;
                if (aZScore < bZScore)
                    return -1;
            }
            return 0;
        });
    }
    if (view === "Percent Difference") {
        const pspsByClosePriceAccuracy = {};
        /** used to determine the domain of the graph */
        const maxClosePriceAccuracyRounded = Math.min(Math.max(...rawData.map((psp) => { var _a; return Math.abs(Math.round(((_a = psp.closePriceAccuracy) !== null && _a !== void 0 ? _a : 0) * 100) / 100); })), MAX_ACCURACY_DOMAIN);
        rawData.forEach((item) => {
            const { closePriceAccuracy, closePriceAccuracyCategory } = item;
            if (closePriceAccuracy != null && closePriceAccuracyCategory != null) {
                if (!Object.keys(pspsByClosePriceAccuracy).includes(closePriceAccuracyCategory)) {
                    pspsByClosePriceAccuracy[closePriceAccuracyCategory] = {
                        psps: [],
                        numPSPs: 0,
                        closePriceAccuracy,
                    };
                }
                pspsByClosePriceAccuracy[closePriceAccuracyCategory].psps.push(item);
                pspsByClosePriceAccuracy[closePriceAccuracyCategory].numPSPs += 1;
            }
        });
        if (!pspsByClosePriceAccuracy[String(maxClosePriceAccuracyRounded)]) {
            pspsByClosePriceAccuracy[String(maxClosePriceAccuracyRounded)] = {
                psps: [],
                numPSPs: 0,
                closePriceAccuracy: maxClosePriceAccuracyRounded,
            };
        }
        if (!pspsByClosePriceAccuracy[String(-1 * maxClosePriceAccuracyRounded)]) {
            pspsByClosePriceAccuracy[String(-1 * maxClosePriceAccuracyRounded)] = {
                psps: [],
                numPSPs: 0,
                closePriceAccuracy: -1 * maxClosePriceAccuracyRounded,
            };
        }
        graphData = Object.keys(pspsByClosePriceAccuracy)
            .map((item) => (Object.assign(Object.assign({}, pspsByClosePriceAccuracy[item]), { closePriceAccuracyCategory: parseFloat(item) })))
            .sort((a, b) => {
            const aClosePriceAccuracy = a.closePriceAccuracy;
            const bClosePriceAccuracy = b.closePriceAccuracy;
            if (aClosePriceAccuracy != null && bClosePriceAccuracy != null) {
                if (aClosePriceAccuracy > bClosePriceAccuracy)
                    return 1;
                if (aClosePriceAccuracy < bClosePriceAccuracy)
                    return -1;
            }
            return 0;
        });
    }
    if (["Agent Scores", "Agent Grades"].includes(view)) {
        // @ts-expect-error doesn't match PSPGraphDataPoint
        graphData = Object.keys(dataByEmail)
            .map((email) => {
            const { tValue } = dataByEmail[email];
            const score = tValue != null && dataByEmail[email].psps.length > 1 ? Math.abs(tValue) : null;
            return Object.assign(Object.assign({}, dataByEmail[email]), { email,
                score });
        })
            .filter((item) => item.score != null);
    }
    return (React.createElement(React.Fragment, null,
        alertMessage && (React.createElement(Grid2, { size: 12 },
            React.createElement(Alert, { severity: "info" },
                alertMessage,
                view === "PSP Value Range vs. Close Price" && (React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                    React.createElement(Typography, { style: { lineHeight: "25px", paddingRight: "4px" } },
                        "The line of best fit is described by the equation:",
                        " "), equation !== null && equation !== void 0 ? equation : React.createElement(Typography, null, "-"),
                    React.createElement(Typography, { style: { lineHeight: "25px", paddingRight: "4px", paddingLeft: "4px" } },
                        " ",
                        "with an rSquared of:",
                        " "),
                    rSquaredValue == null ? (React.createElement(Typography, { style: { lineHeight: "25px", paddingRight: "4px" } }, "-")) : (React.createElement(Typography, { style: { lineHeight: "25px", paddingRight: "4px" } }, rSquaredValue.toPrecision(3)))))))),
        view === "PSP Value Range vs. Close Price" && (React.createElement(Grid2, { size: 12, style: { paddingLeft: "8px" } },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "When enabled, uses a Quadratic Line of Best Fit. Otherwise uses Linear.") },
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: bestFitCurveType === "Quadratic", onChange: changeLineOfBestFit, color: "primary" }), label: `Use ${bestFitCurveType} Line of Best Fit` })))),
        React.createElement(Grid2, { size: 12, style: { height: "400px" } },
            React.createElement(ResponsiveContainer, { ref: graphRef, width: "100%", height: "100%" },
                React.createElement(ComposedChart, { data: graphData, margin: {
                        top: 10,
                        bottom: 25,
                        left: 5,
                        right: 5,
                    } },
                    React.createElement(CartesianGrid, { stroke: "#ccc" }),
                    view === "PSP Value Range" && (React.createElement(React.Fragment, null,
                        React.createElement(XAxis, { label: {
                                value: "PSPs",
                                fontFamily: AppConfig.fonts.primary,
                            }, tick: false, style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(YAxis, { type: "number", tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("$0.0a"), style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(Bar, { dataKey: "Range", fill: DEFAULT_GRAPH_COLOR, barSize: 5, onClick: openScatterPlotTooltip }, graphData.map((psp) => (React.createElement(Cell, { key: `bar_cell_${psp.uid}`, fill: DEFAULT_GRAPH_COLOR })))),
                        React.createElement(Scatter, { name: "Initial List Price", dataKey: "original_list_price", fill: LIST_PRICE_SCATTER_COLOR, shape: customScatterPoint, onClick: openScatterPlotTooltip }),
                        React.createElement(Scatter, { name: "Close Price", dataKey: "close_price", fill: CLOSE_PRICE_SCATTER_COLOR, shape: customScatterPoint, onClick: openScatterPlotTooltip }),
                        React.createElement(Legend, { verticalAlign: "top", content: React.createElement(CustomLegend, { renderLegend: createLegend }) }))),
                    view === "PSP Value Range vs. Close Price" && (React.createElement(React.Fragment, null,
                        React.createElement(XAxis, { type: "number", dataKey: "close_price", label: {
                                value: "Close Price",
                                position: "insideBottom",
                                offset: -5,
                                fontFamily: AppConfig.fonts.primary,
                            }, tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("$0,0"), style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(YAxis, { type: "number", dataKey: "pspValueRangeRatio", label: {
                                value: "PSP Value Range (%)",
                                angle: -90,
                                position: "center",
                                fontFamily: AppConfig.fonts.primary,
                                dx: -20,
                            }, tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("0,0.0%"), style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(Scatter, { name: "Initial List Price", dataKey: "pspValueRangeRatio", fill: DEFAULT_GRAPH_COLOR, shape: customScatterPoint, onClick: openScatterPlotTooltip }),
                        React.createElement(Line, { type: bestFitCurveType === "Linear" ? "linear" : "basis", dataKey: "bestFitY", dot: false, stroke: AppConfig.themeColors.secondary }))),
                    view === "Standard Deviation" && (React.createElement(React.Fragment, null,
                        React.createElement(XAxis, { type: "number", label: {
                                value: "Distance from Mean",
                                position: "insideBottom",
                                offset: -5,
                                fontFamily: AppConfig.fonts.primary,
                            }, dataKey: "zScoreCategory", tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("0,0.0", { min: -1 * MAX_Z_SCORE_DOMAIN, max: MAX_Z_SCORE_DOMAIN }), domain: ["dataMin", "dataMax"], style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(YAxis, { type: "number", label: {
                                value: "Number of PSPs",
                                angle: -90,
                                position: "center",
                                fontFamily: AppConfig.fonts.primary,
                            }, tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("0,0"), allowDecimals: false, style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(Bar, { dataKey: "numPSPs", fill: DEFAULT_GRAPH_COLOR }, graphData.map((psp) => (React.createElement(Cell, { key: `psp_bell_curve_${psp.uid}`, fill: DEFAULT_GRAPH_COLOR })))))),
                    view === "Percent Difference" && (React.createElement(React.Fragment, null,
                        React.createElement(XAxis, { type: "number", label: {
                                value: "Close Price % Difference from PSP Value",
                                position: "insideBottom",
                                offset: -5,
                                fontFamily: AppConfig.fonts.primary,
                            }, dataKey: "closePriceAccuracyCategory", tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("0%", { min: -1 * MAX_ACCURACY_DOMAIN, max: MAX_ACCURACY_DOMAIN }), style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(YAxis, { type: "number", label: {
                                value: "Number of PSPs",
                                angle: -90,
                                position: "center",
                                fontFamily: AppConfig.fonts.primary,
                            }, tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("0,0"), allowDecimals: false, style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(Bar, { dataKey: "numPSPs", fill: DEFAULT_GRAPH_COLOR }, graphData.map((psp) => (React.createElement(Cell, { key: `psp_percent_difference_${psp.uid}`, fill: DEFAULT_GRAPH_COLOR })))))),
                    view === "Agent Scores" && (React.createElement(React.Fragment, null,
                        React.createElement(XAxis, { label: {
                                value: "Agents",
                                position: "insideBottom",
                                offset: -5,
                                fontFamily: AppConfig.fonts.primary,
                            }, dataKey: "name", tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(YAxis, { type: "number", label: {
                                value: "Score (Absolute Value of T-Value)",
                                angle: -90,
                                position: "center",
                                fontFamily: AppConfig.fonts.primary,
                            }, tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatTick("0,0.0"), style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(Bar, { dataKey: "score", fill: DEFAULT_GRAPH_COLOR }, graphData.map((psp) => (React.createElement(Cell, { key: `agent_score_${psp.uid}`, fill: DEFAULT_GRAPH_COLOR })))))),
                    view === "Agent Grades" && (React.createElement(React.Fragment, null,
                        React.createElement(XAxis, { label: {
                                value: "Agents",
                                position: "insideBottom",
                                offset: -5,
                                fontFamily: AppConfig.fonts.primary,
                            }, dataKey: "name", tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(YAxis, { type: "number", label: {
                                value: "Grade",
                                angle: -90,
                                position: "center",
                                fontFamily: AppConfig.fonts.primary,
                            }, domain: ["dataMin - 5", 100], tick: { fontSize: 10, fontFamily: AppConfig.fonts.primary }, tickFormatter: formatLetterGradeTick, style: {
                                fontFamily: AppConfig.fonts.primary,
                            } }),
                        React.createElement(Bar, { dataKey: "grade", fill: DEFAULT_GRAPH_COLOR }, graphData.map((psp) => (React.createElement(Cell, { key: `agent_grade_${psp.uid}`, fill: DEFAULT_GRAPH_COLOR })))))),
                    !["PSP Value Range", "PSP Value Range vs. Close Price"].includes(view) && (React.createElement(RechartsTooltip, { position: {
                            y: 100,
                        }, wrapperStyle: {
                            pointerEvents: "unset",
                        }, offset: -25, cursor: false, content: React.createElement(CustomTooltip, { renderTooltip: createCustomTooltip }) }))))),
        React.createElement(CorePopover, { anchorReference: "anchorPosition", anchorPosition: scatterPlotTooltipPos !== null && scatterPlotTooltipPos !== void 0 ? scatterPlotTooltipPos : undefined, 
            // anchorPosition={
            //     x: document.body.clientWidth / 2,
            //     y:
            // }
            open: !!scatterPlotTooltipPos && !!scatterPlotTooltipData, onClose: closeScatterPlotTooltip, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, PaperProps: {
                elevation: 4,
                style: {
                    minWidth: `${TOOLTIP_WIDTH}px`,
                    maxWidth: `${TOOLTIP_WIDTH}px`,
                },
            }, onKeyDown: handleKeyDown }, scatterPlotTooltipData && (React.createElement(Grid2, { container: true, 
            // spacing={1}
            style: {
                overflow: "auto",
                // height: "350px",
                maxHeight: "350px",
                maxWidth: `${TOOLTIP_WIDTH}px`,
                overflowX: "hidden",
                padding: "4px",
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Grid2, { size: 11 },
                    React.createElement(RouterLink, { target: "_blank", to: `/matrix/${scatterPlotTooltipData.uid}`, rel: "noreferrer" },
                        React.createElement(Typography, { style: { fontWeight: "bold" } }, scatterPlotTooltipData.address))),
                React.createElement(Grid2, { size: 1, style: { margin: "auto" } },
                    React.createElement(IconButton, { size: "small", onClick: closeScatterPlotTooltip, style: {
                            display: "flex",
                            marginLeft: "auto",
                            marginBottom: "auto",
                            marginTop: "auto",
                        } },
                        React.createElement(Close, null))),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `Created By: ${scatterPlotTooltipData.created_by_name}`)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `Original List Price: ${numeral(scatterPlotTooltipData.original_list_price).format("$0,0")}`)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `Close Price: ${numeral(scatterPlotTooltipData.close_price).format("$0,0")}`)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `PSP Value (Min): ${numeral(scatterPlotTooltipData.psp_value_min).format("$0,0")}`)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `PSP Value (Avg): ${numeral(scatterPlotTooltipData.psp_value_avg).format("$0,0")}`)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `PSP Value (High): ${numeral(scatterPlotTooltipData.psp_value_high).format("$0,0")}`)),
                React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, null, `PSP Value Range: ${scatterPlotTooltipData.pspValueRange != null
                        ? numeral(scatterPlotTooltipData.pspValueRange).format("$0,0")
                        : "-"} (${scatterPlotTooltipData.pspValueRangeRatio != null
                        ? numeral(scatterPlotTooltipData.pspValueRangeRatio * 100).format("0,0.0%")
                        : "-"})`))))))));
};
export default PSPAccuracyChart;
