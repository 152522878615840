import * as React from "react";
import { Business, Cake, Email, Favorite, Phone } from "@mui/icons-material";
import { Grid, ListItemButton, ListItemText, Typography } from "@mui/material";
import dayjs from "dayjs";
import styled from "styled-components";
import RouterLink from "@app/common/RouterLink";
import PipelineAndStageText from "@app/entrypoints/brokerage/container/Header/Search/components/PipelineAndStageText";
import AppConfig from "@app/util/AppConfig";
const DiffContainer = styled("div") `
  color: ${(props) => (props.isChanged ? AppConfig.themeColors.primary : "#666")};
  & .MuiTypography-root {
    font-weight: ${(props) => (props.isChanged ? "bold !important" : "normal")};
  }
`;
const ContactAliasListItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { contact, transaction } = props;
    const clientInfo = (_a = transaction.clients) === null || _a === void 0 ? void 0 : _a.find((x) => x.client.uid === contact.uid);
    const clientName = [clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.first_name, clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.last_name].filter((o) => o).join(" ");
    const email = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.email;
    const phone = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.phone;
    const dateOfBirth = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.date_of_birth;
    const company = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.company;
    const maritalStatus = clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.client.marital_status;
    return (React.createElement(ListItemButton, { component: RouterLink, to: `/boxes/${transaction.uid}` },
        React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true, direction: "column", spacing: 0 },
                clientName && (React.createElement(Grid, { item: true },
                    React.createElement(DiffContainer, { isChanged: contact.name !== clientName },
                        React.createElement(Typography, { variant: "body2" }, clientName)))),
                email && (React.createElement(Grid, { item: true },
                    React.createElement(DiffContainer, { isChanged: ((_b = contact.fields["Email"]) !== null && _b !== void 0 ? _b : null) !== email },
                        React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Email, { fontSize: "small", sx: { color: "#666" } })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, email)))))),
                phone && (React.createElement(Grid, { item: true },
                    React.createElement(DiffContainer, { isChanged: ((_c = contact.fields["Phone"]) !== null && _c !== void 0 ? _c : null) !== phone },
                        React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Phone, { fontSize: "small", sx: { color: "#666" } })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, phone)))))),
                dateOfBirth && (React.createElement(Grid, { item: true },
                    React.createElement(DiffContainer, { isChanged: ((_d = contact.fields["Date of Birth"]) !== null && _d !== void 0 ? _d : null) !== dateOfBirth },
                        React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Cake, { fontSize: "small", sx: { color: "#666" } })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, dayjs(dateOfBirth).format("dddd, MMMM D, YYYY"))))))),
                company && (React.createElement(Grid, { item: true },
                    React.createElement(DiffContainer, { isChanged: ((_e = contact.fields["Company"]) !== null && _e !== void 0 ? _e : null) !== company },
                        React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Business, { fontSize: "small", sx: { color: "#666" } })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, company)))))),
                maritalStatus && (React.createElement(Grid, { item: true },
                    React.createElement(DiffContainer, { isChanged: ((_f = contact.fields["Marital Status"]) !== null && _f !== void 0 ? _f : null) !== maritalStatus },
                        React.createElement(Grid, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Favorite, { fontSize: "small", sx: { color: "#666" } })),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "body2" }, maritalStatus))))))), secondary: React.createElement(PipelineAndStageText, { pipeline: transaction.type, stageName: (_g = transaction.stage) === null || _g === void 0 ? void 0 : _g.name, stageUid: (_h = transaction.stage) === null || _h === void 0 ? void 0 : _h.uid }) })));
};
export default ContactAliasListItem;
