var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import CoreTextField from "@app/common/CoreTextField";
import { withDialog } from "@app/hoc/withDialog";
import API from "@app/util/api";
class BlockedNumbersDialog extends React.Component {
    constructor() {
        var _a, _b;
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                open: (_a = this.props.open) !== null && _a !== void 0 ? _a : false,
                item: (_b = this.props.item) !== null && _b !== void 0 ? _b : { number: "", notes: null, forward_to: null },
                isSaving: false,
            }
        });
        Object.defineProperty(this, "save", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { item } = this.state;
                const { onSaveComplete } = this.props;
                this.setState({
                    isSaving: true,
                });
                const data = {
                    number: item.number,
                    notes: ((_a = item.notes) !== null && _a !== void 0 ? _a : "").trim() || null,
                    forward_to: ((_b = item.forward_to) !== null && _b !== void 0 ? _b : "").trim() || null,
                };
                const params = {
                    data,
                    onSuccess: (result) => {
                        this.setState({
                            isSaving: false,
                        });
                        onSaveComplete(result);
                    },
                    onError: (error) => {
                        this.setState({
                            isSaving: false,
                        });
                        this.props.showErrorDialog(error);
                    },
                };
                if (item.uid) {
                    API.twilioBlockedNumbers.update(item.uid, params);
                }
                else {
                    API.twilioBlockedNumbers.create(params);
                }
            }
        });
        Object.defineProperty(this, "setNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState((prevState) => ({
                    item: Object.assign(Object.assign({}, prevState.item), { number: text }),
                }));
            }
        });
        Object.defineProperty(this, "setNotes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState((prevState) => ({
                    item: Object.assign(Object.assign({}, prevState.item), { notes: text }),
                }));
            }
        });
        Object.defineProperty(this, "setForwardTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState((prevState) => ({
                    item: Object.assign(Object.assign({}, prevState.item), { forward_to: text }),
                }));
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        return {
            open: nextProps.open,
        };
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                item: this.props.item || { number: "", notes: null, forward_to: null },
            });
        }
    }
    get isValid() {
        var _a, _b;
        const { item } = this.state;
        return (((_a = item.number) !== null && _a !== void 0 ? _a : "").replace(/[^0-9]/g, "").length >= 10 && ((_b = item.number) !== null && _b !== void 0 ? _b : "").replace(/[^0-9]/g, "").length <= 11);
    }
    render() {
        var _a, _b;
        const { isSaving, item } = this.state;
        const _c = this.props, { hideDialog, onCancel, onSaveComplete, open, showDialog } = _c, other = __rest(_c, ["hideDialog", "onCancel", "onSaveComplete", "open", "showDialog"]);
        return (React.createElement(CoreDialog, Object.assign({ open: this.state.open }, other, { maxWidth: "sm", fullWidth: true }),
            React.createElement(DialogTitle, null,
                item.uid ? "Edit" : "Add",
                " Number"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { p: 2, component: Grid, container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreTextField, { fullWidth: true, label: "Number", value: item.number, onTextChange: this.setNumber })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreTextField, { fullWidth: true, label: "Notes", value: (_a = item.notes) !== null && _a !== void 0 ? _a : "", onTextChange: this.setNotes })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(CoreTextField, { fullWidth: true, label: "Forward To", value: (_b = item.forward_to) !== null && _b !== void 0 ? _b : "", onTextChange: this.setForwardTo })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: this.props.onCancel }, "Cancel"),
                React.createElement(Button, { onClick: this.save, color: "primary", disabled: !this.isValid || !!isSaving }, "Save"))));
    }
}
Object.defineProperty(BlockedNumbersDialog, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        item: null,
        group: null,
    }
});
export default withDialog(BlockedNumbersDialog);
