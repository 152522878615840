import * as React from "react";
import { Box, Divider, Grid2, Paper } from "@mui/material";
import AgentNet from "./components/AgentNet";
import ClosedContracts from "./components/ClosedContracts";
import ContractsBySource from "./components/ContractsBySource";
import ContractsHistory from "./components/ContractsHistory";
import DASHscore from "./components/DASHscore";
import Filters from "./components/filters/Filters";
import Header from "./components/Header";
import MyTasks from "./components/my-tasks/MyTasks";
import PendingContracts from "./components/PendingContracts";
import PinnedBoxes from "./components/PinnedBoxes";
import TotalUnits from "./components/TotalUnits";
import TotalVolume from "./components/TotalVolume";
const AgentDashboard = () => {
    return (React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap", style: { height: "100%" }, mt: 0 },
        React.createElement(Grid2, null,
            React.createElement(Header, null)),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Filters, null)),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 2 },
                    React.createElement(Grid2, { size: { xs: 6, md: 3 } },
                        React.createElement(TotalVolume, null)),
                    React.createElement(Grid2, { size: { xs: 6, md: 3 } },
                        React.createElement(AgentNet, null)),
                    React.createElement(Grid2, { size: { xs: 6, md: 3 } },
                        React.createElement(TotalUnits, null)),
                    React.createElement(Grid2, { size: { xs: 6, md: 3 } },
                        React.createElement(DASHscore, null)),
                    React.createElement(Grid2, { size: { xs: 6, md: 2 }, container: true, direction: "column" },
                        React.createElement(Grid2, null,
                            React.createElement(ClosedContracts, null)),
                        React.createElement(Grid2, null,
                            React.createElement(PendingContracts, null))),
                    React.createElement(Grid2, { size: { xs: 6, md: 3 } },
                        React.createElement(ContractsBySource, null)),
                    React.createElement(Grid2, { size: { xs: 12, md: 7 } },
                        React.createElement(ContractsHistory, null)),
                    React.createElement(Grid2, { size: { xs: 12, md: 4 } },
                        React.createElement(PinnedBoxes, null)),
                    React.createElement(Grid2, { size: { xs: 12, md: 4 } },
                        React.createElement(Paper, { elevation: 4 },
                            React.createElement(MyTasks, { ListWrapperProps: { style: { maxHeight: "300px", overflowY: "scroll", paddingTop: 0 } } }))))))));
};
export default AgentDashboard;
