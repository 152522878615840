import * as React from "react";
import isObject from "lodash/isObject";
import isSet from "lodash/isSet";
/**
 * Hook for persisting state to local storage whenever it is changed,
 * and retrieving it when the component is mounted.
 *
 * @param defaultValue
 * @param storageKey
 * @returns
 */
const usePersistedState = (defaultValue, storageKey, transformation) => {
    const [state, setState] = React.useState(() => {
        const persistedState = localStorage.getItem(storageKey);
        if (!persistedState) {
            return defaultValue;
        }
        if (transformation) {
            console.log({ persistedState });
            try {
                return transformation(persistedState);
            }
            catch (e) {
                console.warn(e);
                return defaultValue;
            }
        }
        return persistedState;
    });
    React.useEffect(() => {
        if (state) {
            let stringState = String(state);
            if (isSet(state)) {
                stringState = JSON.stringify(Array.from(state));
            }
            else if (isObject(state)) {
                stringState = JSON.stringify(state);
            }
            localStorage.setItem(storageKey, stringState);
        }
        else {
            localStorage.removeItem(storageKey);
        }
    }, [state, storageKey]);
    return [state, setState];
};
export default usePersistedState;
