import * as React from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { visibilityOptions } from "./visibility_options";
const visibilityOptionsByKey = visibilityOptions.reduce((dict, v) => (Object.assign(Object.assign({}, dict), { [v.label]: v })), {});
const NewsfeedToggle = (props) => {
    const { allowedOptions, currentOptions } = props;
    const showAll = currentOptions.length === allowedOptions.length;
    const allToggleButtonRef = React.useRef(null);
    const updateSearch = useUpdateSearch();
    const toggleVisibility = React.useCallback((e, value) => {
        const selectAll = e.currentTarget === allToggleButtonRef.current && !showAll;
        const unselectAll = e.currentTarget === allToggleButtonRef.current && showAll;
        const newValue = (() => {
            if (selectAll) {
                return allowedOptions;
            }
            if (unselectAll) {
                return [];
            }
            return value.filter((x) => x !== "all");
        })();
        updateSearch("newsfeed_visibility", newValue.join(",") || null);
    }, [updateSearch, allowedOptions, showAll]);
    return (React.createElement(ToggleButtonGroup, { value: currentOptions, onChange: toggleVisibility, size: "small", style: { height: "25px" } },
        allowedOptions.map((option) => (React.createElement(Tooltip, { key: option, title: React.createElement(Typography, null, visibilityOptionsByKey[option].tooltip) },
            React.createElement("span", null,
                React.createElement(ToggleButton, { value: option, style: { height: "100%" } }, visibilityOptionsByKey[option].icon))))),
        React.createElement(Tooltip, { ref: allToggleButtonRef, title: React.createElement(Typography, null, "Show/hide all") },
            React.createElement("span", null,
                React.createElement(ToggleButton, { value: "all", selected: showAll, style: { height: "100%" } },
                    React.createElement(Typography, null, "ALL"))))));
};
export default NewsfeedToggle;
