import * as React from "react";
import { Code } from "@mui/icons-material";
import { Link, ListItemIcon, MenuItem } from "@mui/material";
import zillowIcon from "@app/assets/img/zillow.png";
export const getPremierAgentUrl = (premierAgentContactId) => {
    return `https://premieragent.zillow.com/crm/contacts/contactdetails/${premierAgentContactId}`;
};
export const getPremierAgentApiUrl = (premierAgentContactId) => {
    return `https://premieragent.zillow.com/crm/proxy/contacts/contacts/${premierAgentContactId}`;
};
const PremierAgentMenuSection = (props) => {
    const { premierAgentId } = props;
    const premierAgentConsoleUrl = premierAgentId ? getPremierAgentUrl(premierAgentId) : null;
    const premierAgentApiUrl = premierAgentId ? getPremierAgentApiUrl(premierAgentId) : null;
    return [
        /* @ts-expect-error */
        React.createElement(MenuItem, { key: "pa", disabled: !premierAgentConsoleUrl, component: premierAgentConsoleUrl ? Link : undefined, href: premierAgentConsoleUrl !== null && premierAgentConsoleUrl !== void 0 ? premierAgentConsoleUrl : undefined, target: premierAgentConsoleUrl ? "_blank" : undefined },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: zillowIcon, alt: "Zillow", height: 20 })),
            "Open in Premier Agent"),
        /* @ts-expect-error */
        React.createElement(MenuItem, { key: "api", disabled: !premierAgentApiUrl, component: premierAgentApiUrl ? Link : undefined, href: premierAgentApiUrl !== null && premierAgentApiUrl !== void 0 ? premierAgentApiUrl : undefined, target: premierAgentApiUrl ? "_blank" : undefined },
            React.createElement(ListItemIcon, null,
                React.createElement(Code, null)),
            "View Premier Agent Response"),
    ];
};
export default PremierAgentMenuSection;
