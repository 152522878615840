import * as React from "react";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, InputAdornment, FormControlLabel, Switch, Grid2, List, Tooltip, Typography, IconButton, Collapse, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import useSession from "@app/hooks/useSession";
import { useListUsers } from "@app/orval/api/users";
import { DEFAULT_CACHE_PARAMS } from "@app/orval/config";
import AgentSelect from "./AgentSelect";
import CollaboratorListItem from "./CollaboratorListItem";
const CollaboratorDialog = (props) => {
    var _a, _b, _c;
    const { DialogProps, canAssignSelfOnly, collaborator, existingCollaborators, onClose, onRemove, onUpdate } = props;
    const [mode, setMode] = React.useState(null);
    const [selectedCollaborator, setSelectedCollaborator] = React.useState(collaborator !== null && collaborator !== void 0 ? collaborator : null);
    const [existingCollaborators_, setExistingCollaborators_] = React.useState(existingCollaborators !== null && existingCollaborators !== void 0 ? existingCollaborators : null);
    const [showExistingCollaborators, setShowExistingCollaborators] = React.useState((existingCollaborators !== null && existingCollaborators !== void 0 ? existingCollaborators : []).length > 0);
    const session = useSession();
    const [userUid, setUserUid] = React.useState(null);
    const [canEdit, setCanEdit] = React.useState(false); // if the user can only assign themselves, they must give themself edit access
    const [canRunMailSchedule, setCanRunMailSchedule] = React.useState(false);
    const [isMailScheduleOwner, setIsMailScheduleOwner] = React.useState(false);
    const [shouldReceiveNotifications, setShouldReceiveNotifications] = React.useState((_a = collaborator === null || collaborator === void 0 ? void 0 : collaborator.should_receive_notifications) !== null && _a !== void 0 ? _a : false);
    const listUsersApi = useListUsers(undefined, DEFAULT_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a;
        return (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data.filter((user) => {
            var _a;
            return canAssignSelfOnly ? user.uid === ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) : true;
        }).sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
    }, [canAssignSelfOnly, (_b = listUsersApi.data) === null || _b === void 0 ? void 0 : _b.data, (_c = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.uid]);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e;
        setUserUid((_a = collaborator === null || collaborator === void 0 ? void 0 : collaborator.user.uid) !== null && _a !== void 0 ? _a : null);
        setCanEdit((_b = collaborator === null || collaborator === void 0 ? void 0 : collaborator.can_edit) !== null && _b !== void 0 ? _b : false);
        setCanRunMailSchedule((_c = collaborator === null || collaborator === void 0 ? void 0 : collaborator.can_run_mail_schedule) !== null && _c !== void 0 ? _c : false);
        setIsMailScheduleOwner((_d = collaborator === null || collaborator === void 0 ? void 0 : collaborator.is_mail_schedule_owner) !== null && _d !== void 0 ? _d : false);
        setShouldReceiveNotifications((_e = collaborator === null || collaborator === void 0 ? void 0 : collaborator.should_receive_notifications) !== null && _e !== void 0 ? _e : false);
    }, [collaborator]);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e;
        setUserUid((_a = selectedCollaborator === null || selectedCollaborator === void 0 ? void 0 : selectedCollaborator.user.uid) !== null && _a !== void 0 ? _a : null);
        setCanEdit((_b = selectedCollaborator === null || selectedCollaborator === void 0 ? void 0 : selectedCollaborator.can_edit) !== null && _b !== void 0 ? _b : false);
        setCanRunMailSchedule((_c = selectedCollaborator === null || selectedCollaborator === void 0 ? void 0 : selectedCollaborator.can_run_mail_schedule) !== null && _c !== void 0 ? _c : false);
        setIsMailScheduleOwner((_d = selectedCollaborator === null || selectedCollaborator === void 0 ? void 0 : selectedCollaborator.is_mail_schedule_owner) !== null && _d !== void 0 ? _d : false);
        setShouldReceiveNotifications((_e = selectedCollaborator === null || selectedCollaborator === void 0 ? void 0 : selectedCollaborator.should_receive_notifications) !== null && _e !== void 0 ? _e : false);
    }, [selectedCollaborator]);
    // if the user can only assign themself,
    // 1) automatically set the current user as the selected user
    // 2) force the user to give themself edit access
    React.useEffect(() => {
        if (canAssignSelfOnly) {
            if (userUid === null && users && users.length > 0) {
                setUserUid(users[0].uid);
            }
            setCanEdit(true);
        }
    }, [canAssignSelfOnly, userUid, users]);
    React.useEffect(() => {
        setShowExistingCollaborators((existingCollaborators_ !== null && existingCollaborators_ !== void 0 ? existingCollaborators_ : []).length > 0);
    }, [existingCollaborators_]);
    const dialogTitle = React.useMemo(() => {
        if (existingCollaborators_ && existingCollaborators_.length > 0) {
            if (selectedCollaborator) {
                return "Edit collaborator";
            }
            if (mode === "add") {
                return "Add a collaborator";
            }
            return "Add or Edit a collaborator";
        }
        return collaborator ? "Edit collaborator" : "Add a collaborator";
    }, [collaborator, existingCollaborators_, mode, selectedCollaborator]);
    const onCanEditChanged = React.useCallback((_, checked) => {
        setCanEdit(checked);
    }, [setCanEdit]);
    const onCanRunMailScheduleChanged = React.useCallback((_, checked) => {
        setCanRunMailSchedule(checked);
    }, [setCanRunMailSchedule]);
    const onIsMailScheduleOwnerChanged = React.useCallback((_, checked) => {
        setIsMailScheduleOwner(checked);
    }, [setIsMailScheduleOwner]);
    const onShouldReceiveNotificationsChanged = React.useCallback((_, checked) => {
        setShouldReceiveNotifications(checked);
    }, [setShouldReceiveNotifications]);
    const toggleShowExistingCollaborators = React.useCallback(() => {
        setShowExistingCollaborators((prev) => !prev);
    }, []);
    const handleAddCollaboratorSelect = React.useCallback(() => {
        setMode("add");
        setSelectedCollaborator(null);
        setShowExistingCollaborators(false);
    }, []);
    const handleCollaboratorSelect = React.useCallback((collaboratorUid) => {
        var _a;
        setMode(null);
        setSelectedCollaborator((_a = existingCollaborators_ === null || existingCollaborators_ === void 0 ? void 0 : existingCollaborators_.find((existingCollaborator) => existingCollaborator.uid === collaboratorUid)) !== null && _a !== void 0 ? _a : null);
        setShowExistingCollaborators(false);
    }, [existingCollaborators_]);
    const handleRemoveCollaborator = React.useCallback((collaboratorUid) => {
        if (onRemove) {
            onRemove(collaboratorUid);
        }
        setSelectedCollaborator(null);
        setExistingCollaborators_((prev) => {
            if (!prev)
                return prev;
            return prev.filter((item) => item.uid !== collaboratorUid);
        });
    }, [onRemove]);
    const onSave = React.useCallback(() => {
        console.log("SAVE:", userUid, canEdit, canRunMailSchedule, isMailScheduleOwner, shouldReceiveNotifications);
        onUpdate({
            user_uid: userUid,
            can_edit: canEdit,
            can_run_mail_schedule: canRunMailSchedule,
            is_mail_schedule_owner: isMailScheduleOwner,
            should_receive_notifications: shouldReceiveNotifications,
        }, selectedCollaborator ? selectedCollaborator.uid : undefined);
    }, [
        userUid,
        canEdit,
        canRunMailSchedule,
        isMailScheduleOwner,
        shouldReceiveNotifications,
        onUpdate,
        selectedCollaborator,
    ]);
    const isValid = !!userUid;
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, dialogTitle),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column" },
                existingCollaborators_ && existingCollaborators_.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { style: { display: "flex" } },
                        showExistingCollaborators && (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add collaborator") },
                            React.createElement(IconButton, { size: "small", onClick: handleAddCollaboratorSelect, style: { display: "flex", marginLeft: "auto", marginRight: "12px" } },
                                React.createElement(Add, { fontSize: "small" })))),
                        React.createElement(Tooltip, { title: React.createElement(Typography, null, showExistingCollaborators ? "Hide existing collaborators" : "Show existing collaborators") },
                            React.createElement(IconButton, { size: "small", onClick: toggleShowExistingCollaborators, style: {
                                    display: "flex",
                                    marginLeft: showExistingCollaborators ? undefined : "auto",
                                    marginRight: "12px",
                                } }, showExistingCollaborators ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null)))),
                    React.createElement(Collapse, { in: showExistingCollaborators },
                        React.createElement(List, { dense: true }, existingCollaborators_.map((existingCollaborator) => (React.createElement(CollaboratorListItem, { key: existingCollaborator.uid, collaborator: existingCollaborator, onEditCollaborator: handleCollaboratorSelect, onRemoveCollaborator: handleRemoveCollaborator }))))))),
                React.createElement(Grid2, null,
                    React.createElement(AgentSelect, { value: userUid, onChange: setUserUid, agents: users !== null && users !== void 0 ? users : [], TextFieldProps: {
                            InputProps: { startAdornment: React.createElement(InputAdornment, { position: "start" }, "User") },
                        } })),
                React.createElement(Grid2, null,
                    React.createElement(FormControlLabel, { label: "Can edit", control: React.createElement(Switch, { checked: canEdit, onChange: onCanEditChanged, 
                            // if the user can only assign themself,
                            // they are forced to give themself edit access
                            disabled: canAssignSelfOnly }) })),
                React.createElement(Grid2, null,
                    React.createElement(FormControlLabel, { label: "Should receive notifications", control: React.createElement(Switch, { checked: shouldReceiveNotifications, onChange: onShouldReceiveNotificationsChanged }) })),
                React.createElement(Grid2, null,
                    React.createElement(FormControlLabel, { label: "Can run Mail Schedule", control: React.createElement(Switch, { checked: canRunMailSchedule, onChange: onCanRunMailScheduleChanged }) })),
                React.createElement(Grid2, null,
                    React.createElement(FormControlLabel, { label: "Is Mail Schedule Owner", control: React.createElement(Switch, { checked: isMailScheduleOwner, onChange: onIsMailScheduleOwnerChanged }) })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: onSave, disabled: !isValid, tabIndex: -1 }, "OK"))));
};
export default CollaboratorDialog;
