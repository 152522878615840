import * as React from "react";
import { Alert, Grid2 } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import ActionDialog from "@app/common/ActionDialog";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import CoreLoading from "@app/common/CoreLoading";
import { useGetContact } from "@app/orval/api/contacts";
import { useListFields } from "@app/orval/api/field-definitions";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
export const overridableFieldNames = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Date of Birth",
    "Marital Status",
];
export const mapping = {
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    Phone: "phone",
    "Date of Birth": "date_of_birth",
    "Marital Status": "marital_status",
};
const ClientAliasDialog = (props) => {
    var _a, _b, _c, _d;
    const { DialogProps, client, getBuyerLeadApi, getSellerLeadApi, onCancel, onConfirm } = props;
    const queryClient = useQueryClient();
    const contact = useGetContact(client.client.uid, REFETCH_CACHE_PARAMS);
    const contactData = (_b = (_a = contact.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listContactFieldsApi = useListFields({ entity_type: "Contact" }, INFINITE_CACHE_PARAMS);
    const contactFields = (_d = (_c = listContactFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const contactFieldsByName = React.useMemo(() => {
        if (!contactFields) {
            return {};
        }
        return contactFields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [contactFields]);
    const apiEndpoint = React.useMemo(() => {
        return `/api/clients/${client.uid}`;
    }, [client.uid]);
    const setClientInfo = React.useCallback((fieldName) => (value) => {
        if (getBuyerLeadApi) {
            queryClient.setQueryData(getBuyerLeadApi.queryKey, (old) => {
                return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { clients: old.data.clients.map((c) => {
                            if (c.uid === client.uid) {
                                return Object.assign(Object.assign({}, c), { client: Object.assign(Object.assign({}, c.client), { [mapping[fieldName]]: (value === null || value === void 0 ? void 0 : value.trim()) || (contactData === null || contactData === void 0 ? void 0 : contactData.fields[fieldName]) }) });
                            }
                            return c;
                        }) }) });
            });
        }
        if (getSellerLeadApi) {
            queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
                return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { clients: old.data.clients.map((c) => {
                            if (c.uid === client.uid) {
                                return Object.assign(Object.assign({}, c), { client: Object.assign(Object.assign({}, c.client), { [mapping[fieldName]]: (value === null || value === void 0 ? void 0 : value.trim()) || (contactData === null || contactData === void 0 ? void 0 : contactData.fields[fieldName]) }) });
                            }
                            return c;
                        }) }) });
            });
        }
    }, [client.uid, contactData === null || contactData === void 0 ? void 0 : contactData.fields, getBuyerLeadApi, getSellerLeadApi, queryClient]);
    return (React.createElement(ActionDialog, { title: "Client Alias", DialogProps: DialogProps, confirmButtonText: "OK", onCancel: onCancel, onConfirm: onConfirm },
        React.createElement(Grid2, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
            React.createElement(Grid2, null,
                React.createElement(Alert, { severity: "info", variant: "outlined" }, "Info that you add here will only apply to this lead and any attached contracts. It won't be visible on any other leads or contracts we may have in the system for this client. If you leave these fields blank, we will use the contact information from the original lead source.")),
            contactFields ? (React.createElement(Grid2, { container: true, spacing: 2 }, overridableFieldNames.map((f) => {
                var _a, _b;
                return (React.createElement(Grid2, { key: f, size: { xs: 12, md: 6 } },
                    React.createElement(DashAutoSaveField, { size: "small", variant: "standard", label: f, fullWidth: true, field: contactFieldsByName[f], apiEndpoint: apiEndpoint, value: (_b = (_a = client.client[mapping[f]]) !== null && _a !== void 0 ? _a : contactData === null || contactData === void 0 ? void 0 : contactData.fields[f]) !== null && _b !== void 0 ? _b : "", placeholder: contactData === null || contactData === void 0 ? void 0 : contactData.fields[f], InputLabelProps: {
                            shrink: (contactData === null || contactData === void 0 ? void 0 : contactData.fields[f]) ? true : undefined,
                        }, onSaveBegin: setClientInfo(f), hasPicker: contactFieldsByName[f].field_type === "date" ? true : undefined })));
            }))) : (React.createElement(Grid2, null,
                React.createElement(CoreLoading, null))))));
};
export default ClientAliasDialog;
