import * as React from "react";
import { Box, Divider, Grid2, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import { useSearchMlsGrid } from "@app/orval/api/mls-grid";
import SearchParamTextField from "@app/common/SearchParamTextField";
const MLSGrid = () => {
    var _a, _b;
    const [searchParams,] = useSearchParams();
    const mlsnumber = searchParams.get("mlsnumber");
    useDocumentTitle("Integrations | MLS Grid");
    const searchMlsGridApi = useSearchMlsGrid({ listing_id: mlsnumber !== null && mlsnumber !== void 0 ? mlsnumber : "" }, { query: { enabled: !!(mlsnumber === null || mlsnumber === void 0 ? void 0 : mlsnumber.trim()) } });
    const mlsGridResponse = (_b = (_a = searchMlsGridApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const error = searchMlsGridApi.error;
    let responseElement;
    if (error) {
        responseElement = React.createElement(CoreError, { error: error });
    }
    else if (mlsGridResponse) {
        responseElement = React.createElement("pre", null, JSON.stringify(mlsGridResponse, null, 2));
    }
    else {
        responseElement = React.createElement(CoreLoading, null);
    }
    return (React.createElement(Box, { component: Grid2, container: true, p: 2, spacing: 2, direction: "column" },
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body2" }, "Use the MLS Grid API explorer to view the API response for a given property, identified by its MLS number.")),
        React.createElement(Grid2, null,
            React.createElement(SearchParamTextField, { TextFieldProps: {
                    label: "MLS #",
                    variant: "outlined",
                    size: "small",
                    fullWidth: true,
                    style: {
                        maxWidth: "300px",
                    },
                }, searchParam: "mlsnumber" })),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        mlsnumber && (React.createElement(Grid2, null, responseElement))));
};
export default MLSGrid;
