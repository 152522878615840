/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Get the status of each template for a given box, pipeline, or agent
 */
export const getMailschedule3TemplateStatus = (params, options) => {
    return axios.get(`/api/mailschedule/status`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetMailschedule3TemplateStatusQueryKey = (params) => {
    return [`/api/mailschedule/status`, ...(params ? [params] : [])];
};
export const getGetMailschedule3TemplateStatusQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule3TemplateStatusQueryKey(params);
    const queryFn = ({ signal }) => getMailschedule3TemplateStatus(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the status of each template for a given box, pipeline, or agent
 */
export function useGetMailschedule3TemplateStatus(params, options) {
    const queryOptions = getGetMailschedule3TemplateStatusQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Set the status of a template for a given box
 */
export const setMailschedule3TemplateStatus = (templateStatusWriteRequest, options) => {
    return axios.post(`/api/mailschedule/status`, templateStatusWriteRequest, options);
};
export const getSetMailschedule3TemplateStatusMutationOptions = (options) => {
    const mutationKey = ['setMailschedule3TemplateStatus'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return setMailschedule3TemplateStatus(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Set the status of a template for a given box
*/
export const useSetMailschedule3TemplateStatus = (options) => {
    const mutationOptions = getSetMailschedule3TemplateStatusMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get the status of each template for a given box, pipeline, or agent
*/
export const getMailschedule4TemplateStatus = (params, options) => {
    return axios.get(`/api/mailschedule4/status`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetMailschedule4TemplateStatusQueryKey = (params) => {
    return [`/api/mailschedule4/status`, ...(params ? [params] : [])];
};
export const getGetMailschedule4TemplateStatusQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMailschedule4TemplateStatusQueryKey(params);
    const queryFn = ({ signal }) => getMailschedule4TemplateStatus(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the status of each template for a given box, pipeline, or agent
 */
export function useGetMailschedule4TemplateStatus(params, options) {
    const queryOptions = getGetMailschedule4TemplateStatusQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Set the status of a template for a given box
 */
export const setMailschedule4TemplateStatus = (mailSchedule4TemplateStatusWriteRequest, options) => {
    return axios.post(`/api/mailschedule4/status`, mailSchedule4TemplateStatusWriteRequest, options);
};
export const getSetMailschedule4TemplateStatusMutationOptions = (options) => {
    const mutationKey = ['setMailschedule4TemplateStatus'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return setMailschedule4TemplateStatus(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Set the status of a template for a given box
*/
export const useSetMailschedule4TemplateStatus = (options) => {
    const mutationOptions = getSetMailschedule4TemplateStatusMutationOptions(options);
    return useMutation(mutationOptions);
};
