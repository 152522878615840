import * as React from "react";
import { Grid2, Link, Typography } from "@mui/material";
import { isChromeExtension } from "@app/util/Utils";
const ChromeExtensionCompatibleIframe = (props) => {
    const res = isChromeExtension ? (React.createElement(Grid2, { container: true, direction: "column", spacing: 1 },
        React.createElement(Grid2, null,
            React.createElement(Typography, { variant: "body2", style: { textWrap: "wrap", color: "#666" } }, "Unable to display PDFs within Gmail.")),
        React.createElement(Grid2, null,
            React.createElement(Link, { href: props.src, target: "_blank" }, "Click here to view the PDF")))) : (React.createElement("iframe", Object.assign({}, props, { title: props.title })));
    return res;
};
export default ChromeExtensionCompatibleIframe;
