import * as React from "react";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Alert, Box, Collapse, Divider, Fab, Grid2, Hidden, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useLocation } from "react-router";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import useSession from "@app/hooks/useSession";
import useSize from "@app/hooks/useSize";
import useStateCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { countTransactionsByThirdParty, listTransactions } from "@app/orval/api/agent-dashboard";
import { REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS } from "@app/orval/config";
import Forms from "./components/Forms";
import MLSProperty from "./components/MLSProperty";
import Notes from "./components/Notes";
import SellerLeadActionsMenu from "./components/SellerLeadActionsMenu";
import Showings from "./components/Showings";
import useSellerLeadsApi from "./useSellerLeadsApi";
import useValidation from "./useValidation";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import Agents from "../buyer-leads/components/Agents";
import Clients from "../buyer-leads/components/Clients";
import LoadingContracts from "../buyer-leads/components/LoadingContracts";
import LockedContractNotification from "../buyer-leads/components/LockedContractNotification";
import OtherLeadsAndContracts from "../buyer-leads/components/OtherLeadsAndContracts";
import SectionHeader from "../buyer-leads/components/SectionHeader";
import SellerPSPs from "../buyer-leads/components/SellerPSPs";
import ThirdParties from "../buyer-leads/components/ThirdParties";
import ViewAllToggle from "../buyer-leads/components/ViewAllToggle";
import Collaborators from "../components/box-view/actions/Collaborators";
import Chronology from "../components/box-view/Chronology/Chronology";
import DataEntry from "../components/box-view/DataEntry";
import DataEntrySkeleton from "../components/box-view/DataEntrySkeleton";
import Header from "../components/box-view/Header";
import ValidationErrors from "../components/box-view/ValidationErrors";
import ViewMembership from "../components/box-view/ViewMembership";
import SellerContractActionsMenu from "../seller-contracts/components/SellerContractActionsMenu";
import { getAddress } from "../utils/utils";
const ALLOWED_VISIBILITY_OPTIONS = ["Calls", "Comments", "Files", "Emails", "History", "SMS", "PSPs", "Forms"];
const ALLOWED_VISIBILITY_OPTIONS__WRITEABLE = [...ALLOWED_VISIBILITY_OPTIONS];
const SellerLeadsForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { uid } = props;
    const tab = (_a = props.tab) !== null && _a !== void 0 ? _a : "data";
    // STATE
    const [search, setSearch] = useStateCallback("");
    const [searchStringDebounced, setSearchStringDebounced] = useStateCallback(search);
    const [isChronologyOpen, setIsChronologyOpen] = React.useState(!!window.localStorage.getItem("chronologyOpen"));
    const [isSellerLeadCollapsed, setIsSellerLeadCollapsed] = React.useState(false);
    const [collapsedSellerContracts, setCollapsedSellerContracts] = React.useState(new Set());
    const [unlockedSellerContracts, setUnlockedSellerContracts] = React.useState(new Set());
    const [isViewAllEnabled, setIsViewAllEnabled] = React.useState(false);
    const size = useSize();
    // API
    const { addAgent, addCollaborator, addExistingClient, addNewClient, addShowing, addThirdParty, agentChanges, calls, canEdit, comments, createTask, currentlySavingComment, currentlyUploadingFiles, deleteComment, deleteSellerContract, deleteSellerLead, deleteTask, emails, fieldValueChanges, fields, files, getSellerLeadApi, isAddingPSP, isLoading, linkPSP, mailScheduleErrors, mailScheduleHistory, messages, noteChanges, notifications, onFieldSaveBegin, onFieldSaveComplete, onFieldSaveError, onFileDeleted, onFilesSelectedForUpload, onMarketAnalysisClicked, onSellerContractFieldSaveBegin, onSellerContractFieldSaveComplete, onSellerContractFieldSaveError, onSmsSent, removeAgent, removeClient, removeCollaborator, removePSP, removeShowing, removeThirdParty, reorderClients, saveComment, sections, sellerContractFields, sellerContractSections, sellerContractStageChanges, sellerContractStages, sellerContracts, sellerLead, setNotes, setSellerContractStage, setStage, stageChanges, stages, startNewPSP, startNewSellerContract, streakComments, streakFiles, tasks, updateAgent, updateCollaborator, updateShowing, updateTask, updateThirdParty, } = useSellerLeadsApi(uid, { search: searchStringDebounced });
    const allStageChanges = React.useMemo(() => {
        const changes = [...(sellerContractStageChanges !== null && sellerContractStageChanges !== void 0 ? sellerContractStageChanges : []), ...(stageChanges !== null && stageChanges !== void 0 ? stageChanges : [])];
        return changes;
    }, [sellerContractStageChanges, stageChanges]);
    const sellerContractUids = React.useMemo(() => {
        return sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.map((x) => x.uid);
    }, [sellerContracts]);
    // HOOKS
    const location = useLocation();
    const updateSearch = useUpdateSearch({ navigateOptions: { state: location.state } });
    const session = useSession();
    // CALLBACKS
    const onTabChanged = React.useCallback((_, value) => {
        updateSearch("tab", value);
        if (value === "chronology") {
            setIsChronologyOpen(true);
        }
    }, [updateSearch, setIsChronologyOpen]);
    const searchTimeout = React.useRef(null);
    const doSearch = React.useCallback((searchString) => {
        if (searchTimeout.current) {
            window.clearTimeout(searchTimeout.current);
        }
        setSearch(searchString);
        searchTimeout.current = window.setTimeout(() => {
            setSearchStringDebounced(searchString);
        }, 500);
    }, [setSearch, setSearchStringDebounced]);
    const openChronology = React.useCallback(() => {
        window.localStorage.setItem("chronologyOpen", "1");
        setIsChronologyOpen(true);
    }, []);
    const closeChronology = React.useCallback(() => {
        window.localStorage.removeItem("chronologyOpen");
        setIsChronologyOpen(false);
    }, []);
    const collapseSellerLead = React.useCallback(() => {
        setIsSellerLeadCollapsed((prev) => !prev);
    }, []);
    const collapseSellerContract = React.useCallback((sellerContractUid) => () => {
        console.log({ sellerContractUid });
        setCollapsedSellerContracts((val) => {
            if (val.has(sellerContractUid)) {
                val.delete(sellerContractUid);
            }
            else {
                val.add(sellerContractUid);
            }
            console.log(val);
            return new Set([...val]);
        });
    }, [setCollapsedSellerContracts]);
    const unlockSellerContract = React.useCallback((sellerContractUid) => () => {
        setUnlockedSellerContracts((val) => {
            if (val.has(sellerContractUid)) {
                val.delete(sellerContractUid);
            }
            else {
                val.add(sellerContractUid);
            }
            return new Set([...val]);
        });
    }, [setUnlockedSellerContracts]);
    React.useEffect(() => {
        for (const sellerContractUid of sellerContractUids !== null && sellerContractUids !== void 0 ? sellerContractUids : []) {
            setCollapsedSellerContracts((val) => {
                if (sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.find((x) => { var _a; return x.uid === sellerContractUid && ((_a = x.stage) === null || _a === void 0 ? void 0 : _a.name) === "Terminated"; })) {
                    val.add(sellerContractUid);
                }
                return new Set([...val]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sellerContractUids === null || sellerContractUids === void 0 ? void 0 : sellerContractUids.join(",")]);
    const clientUids = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.clients.map((x) => x.client.uid);
    const transactionsForClientsApi = usePostQuery(listTransactions, {
        client_uids: clientUids !== null && clientUids !== void 0 ? clientUids : [],
    }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS, clientUids != null && clientUids.length > 0);
    const transactionsForClients = (_c = (_b = transactionsForClientsApi.data) === null || _b === void 0 ? void 0 : _b.data.filter((x) => x.uid !== uid && x.lead_uid !== uid)) !== null && _c !== void 0 ? _c : [];
    const countTransactionsByThirdPartyApi = usePostQuery(countTransactionsByThirdParty, {
        third_party_uids: (_d = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.vendors.map((x) => x.contact.uid).sort()) !== null && _d !== void 0 ? _d : [],
    }, REFETCH_CACHE_PARAMS_GARBAGE_COLLECT_5_MINS, (sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.vendors) != null && sellerLead.vendors.length > 0);
    const transactionsByThirdParty = (_f = (_e = countTransactionsByThirdPartyApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const numTransactionsByThirdParty = React.useMemo(() => {
        return transactionsByThirdParty === null || transactionsByThirdParty === void 0 ? void 0 : transactionsByThirdParty.reduce((acc, x) => (Object.assign(Object.assign({}, acc), { [x.third_party_contact_uid]: x.count })), {});
    }, [transactionsByThirdParty]);
    const isSmallDevice = useSize().isDown("sm");
    const toggleViewAllEnabled = React.useCallback(() => {
        setIsViewAllEnabled((prev) => !prev);
    }, []);
    const { sellerContractFieldSectionsSaturated, sellerContractFieldsSaturated, sellerLeadFieldSectionsSaturated, sellerLeadFieldsSaturated, validationErrors, } = useValidation({
        sellerLead,
        sellerContracts,
        sellerLeadFields: fields !== null && fields !== void 0 ? fields : null,
        sellerLeadFieldSections: sections,
        sellerContractFields,
        sellerContractFieldSections: sellerContractSections !== null && sellerContractSections !== void 0 ? sellerContractSections : null,
    });
    if (!sellerLead && getSellerLeadApi.error) {
        return React.createElement(CoreError, { error: getSellerLeadApi.error });
    }
    if (!sellerLead || isLoading) {
        return React.createElement(CoreLoading, null);
    }
    const basicAddress = ((_g = sellerLead.fields["Street #"]) === null || _g === void 0 ? void 0 : _g.trim()) &&
        ((_h = sellerLead.fields["Street Name"]) === null || _h === void 0 ? void 0 : _h.trim()) &&
        ((_j = sellerLead.fields["City"]) === null || _j === void 0 ? void 0 : _j.trim())
        ? [sellerLead.fields["Street #"], sellerLead.fields["Street Name"], sellerLead.fields["City"]].join(" ")
        : null;
    const sellerLeadAddress = getAddress(sellerLead);
    const alerts = [
        {
            test: !sellerLead.agents.find((x) => x.type === "Producer"),
            message: "This box is missing a producer.",
            severity: "warning",
        },
        {
            test: sellerContracts != null &&
                !!sellerContracts.find((x) => { var _a, _b; return ((_a = x.stage) === null || _a === void 0 ? void 0 : _a.name) !== "Terminated" && ((_b = x.stage) === null || _b === void 0 ? void 0 : _b.name) !== "Closed"; }) &&
                ((_k = sellerLead.stage) === null || _k === void 0 ? void 0 : _k.name) !== "Contract",
            message: 'The Seller Lead stage should probably be "Contract" if there is an active contract.',
            severity: "warning",
        },
        {
            test: sellerContracts != null &&
                !sellerContracts.find((x) => { var _a; return ((_a = x.stage) === null || _a === void 0 ? void 0 : _a.name) !== "Terminated"; }) &&
                ((_l = sellerLead.stage) === null || _l === void 0 ? void 0 : _l.name) === "Contract",
            message: 'The Seller Lead stage probably shouldn\'t be "Contract" unless there is an active or closed contract.',
            severity: "warning",
        },
        {
            test: isViewAllEnabled,
            message: "It looks like you've enabled the feature to view all sections and fields. This option won't be here forever, so let an admin know if the necessary aren't appearing when the 'view-all' feature is disabled.",
            severity: "info",
        },
    ];
    return (React.createElement(Grid2, { container: true, direction: "column", style: { background: "#eee", overflowY: "hidden", height: "100%" }, wrap: "nowrap" },
        validationErrors.length > 0 && (React.createElement(Grid2, null,
            React.createElement(ValidationErrors, { errors: validationErrors }))),
        alerts
            .filter((x) => x.test)
            .map((x) => (React.createElement(Grid2, { key: x.message },
            React.createElement(Alert, { severity: x.severity }, x.message)))),
        React.createElement(Grid2, { style: { width: "100%" } },
            React.createElement(Box, { p: 2, pb: 0, pt: 0 },
                React.createElement(Header, { moduleName: "Sellers", pipelineUrl: "/seller-leads", name: sellerLead.name, showCopyLinkButton: true, onDelete: deleteSellerLead, deleteVerbiage: "Delete lead", referredFrom: "/overview", leadSource: sellerLead.fields["Source"], leadType: sellerLead.fields["Lead Type"], createdAt: sellerLead.created_at },
                    React.createElement(ViewAllToggle, { viewAll: isViewAllEnabled, onClick: toggleViewAllEnabled }),
                    React.createElement(ViewMembership, { entityType: "Seller Lead", entityUid: sellerLead.uid }),
                    React.createElement(SellerLeadActionsMenu, { sellerLead: sellerLead, onCreateSellerContractSelected: startNewSellerContract })))),
        React.createElement(Grid2, { style: { overflow: "hidden", flex: 1 } },
            !isChronologyOpen && (React.createElement(Hidden, { lgDown: true },
                React.createElement(Tooltip, { title: "Open history", placement: "left" },
                    React.createElement(Fab, { size: "large", onClick: openChronology, style: {
                            position: "absolute",
                            top: "50%",
                            right: "-20px",
                            opacity: 0.8,
                            backgroundColor: "#00a9df",
                            color: "white",
                        } },
                        React.createElement(KeyboardArrowLeft, { style: { transform: "translateX(-8px)" } }))))),
            React.createElement(Grid2, { container: true, spacing: 1, style: { height: "100%", flex: 1 } },
                React.createElement(Hidden, { lgDown: tab !== "overview" },
                    React.createElement(Grid2, { size: size.isUp("lg") ? undefined : 12, style: {
                            height: "100%",
                            overflowY: "hidden",
                            minWidth: size.isUp("lg") ? "325px" : undefined,
                            maxWidth: size.isUp("lg") ? "350px" : undefined,
                        } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "hidden" } },
                            React.createElement(Grid2, { container: true, spacing: 2, direction: "column", style: { height: "100%", overflowY: "hidden" }, wrap: "nowrap" },
                                basicAddress && (React.createElement(Grid2, null,
                                    React.createElement(Paper, { elevation: 4 },
                                        React.createElement(Box, { p: 2 },
                                            React.createElement(MLSProperty, { address: basicAddress, layout: "horizontal", StreetViewImageProps: { ImageProps: { style: { maxHeight: "200px" } } }, activeDate: sellerLead.fields["Active Date"] }))))),
                                React.createElement(Grid2, { style: { overflow: "hidden" } },
                                    React.createElement(Paper, { style: { maxHeight: "100%", overflowY: "scroll" } },
                                        React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll" }, direction: "column" },
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2, pb: 0 },
                                                    React.createElement(Collaborators, { collaborators: sellerLead.collaborators, onCreateCollaborator: canEdit ? addCollaborator : undefined, onUpdateCollaborator: canEdit ? updateCollaborator : undefined, onRemoveCollaborator: canEdit ? removeCollaborator : undefined }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2, pb: 0 },
                                                    React.createElement(Clients, { clients: sellerLead.clients, onRemoveClient: canEdit ? removeClient : undefined, onAddNewClient: canEdit ? addNewClient : undefined, onAddExistingClient: canEdit ? addExistingClient : undefined, onReorderClients: canEdit ? reorderClients : undefined, getSellerLeadApi: getSellerLeadApi }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2, pb: 0 },
                                                    React.createElement(Agents, { agents: sellerLead.agents, agentTypes: ["Producer", "Co-Agent", "Listing Coordinator"], variant: "seller", onCreateAgent: canEdit ? addAgent : undefined, onUpdateAgent: canEdit ? updateAgent : undefined, onRemoveAgent: canEdit ? removeAgent : undefined }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2, pb: 0 },
                                                    React.createElement(ThirdParties, { parties: sellerLead.vendors, onAddParty: addThirdParty, onUpdateParty: updateThirdParty, onRemoveParty: removeThirdParty, sellerLead: sellerLead, sellerContracts: sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : undefined, transactionsPerThirdParty: numTransactionsByThirdParty, getSellerLeadApi: getSellerLeadApi, canEdit: canEdit }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2, pb: 0 },
                                                    React.createElement(Showings, { showings: sellerLead.showings, onAddShowing: canEdit ? addShowing : undefined, onUpdateShowing: canEdit ? updateShowing : undefined, onRemoveShowing: canEdit ? removeShowing : undefined }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2 },
                                                    React.createElement(SellerPSPs, { sellerLead: sellerLead, psps: sellerLead.psps, onMarketAnalysisClicked: canEdit ? onMarketAnalysisClicked : undefined, onStartNewPSP: canEdit ? startNewPSP : undefined, onLinkPSP: canEdit ? linkPSP : undefined, onRemovePSP: canEdit ? removePSP : undefined, isCollapsible: true, isAddingPSP: isAddingPSP }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2, pb: 0 },
                                                    React.createElement(Forms, { sellerLeadUid: sellerLead.uid }))),
                                            React.createElement(Grid2, null,
                                                React.createElement(Divider, null)),
                                            React.createElement(Grid2, null,
                                                React.createElement(Box, { p: 2 },
                                                    React.createElement(Notes, { notes: (_m = sellerLead.notes) !== null && _m !== void 0 ? _m : "", onSetNotes: setNotes, canEdit: canEdit }))),
                                            transactionsForClients.length > 0 && (React.createElement(React.Fragment, null,
                                                React.createElement(Grid2, null,
                                                    React.createElement(Divider, null)),
                                                React.createElement(Grid2, null,
                                                    React.createElement(Box, { p: 2 },
                                                        React.createElement(OtherLeadsAndContracts, { clients: sellerLead.clients, buyerLeads: transactionsForClients
                                                                .filter((t) => t.buyer_lead)
                                                                .map((t) => t.buyer_lead), sellerLeads: transactionsForClients
                                                                .filter((t) => t.seller_lead)
                                                                .map((t) => t.seller_lead), buyerContracts: transactionsForClients
                                                                .filter((t) => t.buyer_contract)
                                                                .map((t) => t.buyer_contract), sellerContracts: transactionsForClients
                                                                .filter((t) => t.seller_contract)
                                                                .map((t) => t.seller_contract) })))))))))))),
                React.createElement(Hidden, { lgDown: tab !== "data" },
                    React.createElement(Grid2, { style: { height: "100%", overflowY: "hidden", width: "100%", flex: 1 } },
                        React.createElement(Box, { p: 2, pt: 0, pb: 0, style: { height: "100%", overflowY: "scroll", width: "100%" } },
                            React.createElement(Grid2, { container: true, spacing: 2, direction: "column", style: { width: "100%" } },
                                React.createElement(Grid2, { style: { width: "100%" } },
                                    React.createElement(Paper, { style: { maxHeight: "100%", overflowY: "scroll", width: "100%" } },
                                        React.createElement(Grid2, { container: true, style: { height: "100%", overflowY: "scroll", width: "100%" }, direction: "column" },
                                            React.createElement(Grid2, { style: { width: "100%" } },
                                                React.createElement(Box, { p: 2 },
                                                    React.createElement(SectionHeader, { uid: sellerLead.uid, name: "Lead", address: sellerLeadAddress !== null && sellerLeadAddress !== void 0 ? sellerLeadAddress : undefined, isCollapsed: isSellerLeadCollapsed, onCollapse: collapseSellerLead, stages: (_o = (stages !== null && stages !== void 0 ? stages : undefined)) === null || _o === void 0 ? void 0 : _o.filter((x) => {
                                                            var _a;
                                                            return ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) ||
                                                                x.name !== "Listing Coordinator";
                                                        }), stage: (_p = sellerLead.stage) === null || _p === void 0 ? void 0 : _p.uid, onStageChange: setStage, mailScheduleLinkUrl: `/seller-leads/${sellerLead.uid}/mail-schedule`, numMailScheduleErrors: mailScheduleErrors === null || mailScheduleErrors === void 0 ? void 0 : mailScheduleErrors.filter((x) => x.box_key === sellerLead.streak_seller_leads_box_key).length, canEdit: canEdit }))),
                                            React.createElement(Collapse, { in: !isSellerLeadCollapsed },
                                                React.createElement(Grid2, null, sellerLeadFieldsSaturated ? (React.createElement(DataEntry, { apiUri: `/api/seller_leads/${sellerLead.uid}`, onFieldSaveBegin: onFieldSaveBegin, onFieldSaveComplete: onFieldSaveComplete, onFieldSaveError: onFieldSaveError, fields: sellerLeadFieldsSaturated, sections: sellerLeadFieldSectionsSaturated !== null && sellerLeadFieldSectionsSaturated !== void 0 ? sellerLeadFieldSectionsSaturated : [], values: sellerLead.fields, viewAllEnabled: isViewAllEnabled, collapseCompletedSectionsByDefault: true, subordinateUserUidsToCheck: sellerLead.agents.map((x) => x.agent.uid), isEditable: canEdit })) : (React.createElement(Box, { p: 2 },
                                                    React.createElement(DataEntrySkeleton, null)))))))),
                                sellerLead.contracts.length > 0 && !sellerContracts && (React.createElement(Grid2, null,
                                    React.createElement(LoadingContracts, { numContracts: sellerLead.contracts.length }))),
                                (sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : []).map((sellerContract) => {
                                    var _a, _b, _c, _d, _e;
                                    const shouldBeLocked = ((_a = sellerContract.stage) === null || _a === void 0 ? void 0 : _a.name) === "Closed" || ((_b = sellerContract.stage) === null || _b === void 0 ? void 0 : _b.name) === "Terminated";
                                    const isUnlocked = unlockedSellerContracts.has(sellerContract.uid);
                                    const isLocked = !isUnlocked && shouldBeLocked;
                                    return (React.createElement(Grid2, { key: sellerContract.uid, style: { width: "100%" } },
                                        React.createElement(Paper, { style: { maxHeight: "calc(100% - 1rem)", width: "100%", overflowY: "scroll" } },
                                            React.createElement(Grid2, { container: true, style: { height: "100%", width: "100%", overflowY: "scroll" }, direction: "column" },
                                                React.createElement(Grid2, { style: { width: "100%" } },
                                                    React.createElement(Box, { p: 2 },
                                                        React.createElement(SectionHeader, { uid: sellerContract.uid, name: "Contract", address: sellerLeadAddress !== null && sellerLeadAddress !== void 0 ? sellerLeadAddress : undefined, stages: sellerContractStages !== null && sellerContractStages !== void 0 ? sellerContractStages : undefined, stage: (_c = sellerContract.stage) === null || _c === void 0 ? void 0 : _c.uid, onStageChange: setSellerContractStage(sellerContract.uid), onCollapse: collapseSellerContract(sellerContract.uid), isCollapsed: collapsedSellerContracts.has(sellerContract.uid), isLocked: !isUnlocked, onToggleLock: shouldBeLocked ? unlockSellerContract(sellerContract.uid) : undefined, onDelete: deleteSellerContract(sellerContract.uid), deleteVerbiage: "Delete seller contract", toolbar: React.createElement(Grid2, { container: true, spacing: 1, wrap: "nowrap" },
                                                                React.createElement(Grid2, null,
                                                                    React.createElement(ViewMembership, { entityType: "Listing", entityUid: sellerContract.uid })),
                                                                React.createElement(Grid2, null,
                                                                    React.createElement(SellerContractActionsMenu, { sellerContract: sellerContract }))), mailScheduleLinkUrl: `/seller-contracts/${sellerContract.uid}/mail-schedule`, numMailScheduleErrors: mailScheduleErrors === null || mailScheduleErrors === void 0 ? void 0 : mailScheduleErrors.filter((x) => x.box_key === sellerContract.streak_luc_box_key).length, canEdit: canEdit }))),
                                                React.createElement(Collapse, { in: !collapsedSellerContracts.has(sellerContract.uid) },
                                                    isLocked && (React.createElement(Grid2, null,
                                                        React.createElement(Box, { p: 2 },
                                                            React.createElement(LockedContractNotification, null)))),
                                                    React.createElement(Grid2, null, sellerContractFieldsSaturated ? (React.createElement(DataEntry, { apiUri: `/api/seller_contracts/${sellerContract.uid}`, onFieldSaveBegin: onSellerContractFieldSaveBegin(sellerContract.uid), onFieldSaveComplete: onSellerContractFieldSaveComplete(sellerContract.uid), onFieldSaveError: onSellerContractFieldSaveError(sellerContract.uid), fields: (_d = sellerContractFieldsSaturated[sellerContract.uid]) !== null && _d !== void 0 ? _d : [], sections: (_e = sellerContractFieldSectionsSaturated === null || sellerContractFieldSectionsSaturated === void 0 ? void 0 : sellerContractFieldSectionsSaturated[sellerContract.uid]) !== null && _e !== void 0 ? _e : [], values: sellerContract.fields, viewAllEnabled: isViewAllEnabled, isEditable: !isLocked && canEdit, collapseCompletedSectionsByDefault: true })) : (React.createElement(Box, { p: 2 },
                                                        React.createElement(DataEntrySkeleton, null)))))))));
                                }))))),
                (size.isUp("lg") ? isChronologyOpen : tab === "chronology") && (React.createElement(Grid2, { size: size.isUp("lg") ? undefined : 12, style: {
                        height: "100%",
                        overflowY: "hidden",
                        minWidth: size.isUp("lg") ? "450px" : undefined,
                        maxWidth: size.isUp("lg") ? "600px" : undefined,
                    } },
                    React.createElement(Box, { p: 2, style: { height: "100%", overflowY: "hidden" } },
                        React.createElement(Paper, { style: { height: "100%", maxHeight: "calc(100% - 1rem)", overflowY: "hidden" } },
                            React.createElement(Box, { p: 2, style: { height: "100%", overflowY: "hidden" } },
                                React.createElement(Chronology, { allowedVisibilityOptions: ALLOWED_VISIBILITY_OPTIONS__WRITEABLE, visibilityType: "Seller", updates: fieldValueChanges, stageChanges: allStageChanges, comments: comments, tasks: tasks, notifications: notifications, messages: messages, calls: calls, emails: emails, noteChanges: noteChanges, agentChanges: agentChanges, streakComments: streakComments, streakFiles: streakFiles, files: files, contacts: sellerLead.clients
                                        .filter((c) => c.client.phone && isValidPhoneNumber(c.client.phone.trim(), "US"))
                                        .map((c) => ({
                                        name: [c.client.first_name, c.client.last_name].filter((o) => o).join(" ") || "Client",
                                        phone_number: c.client.phone.trim(),
                                    })), mailScheduleTemplateHistory: mailScheduleHistory, isSubmittingComment: !!currentlySavingComment, onSmsSent: onSmsSent, onSearch: doSearch, onCommentSubmitted: saveComment, onCommentDeleted: deleteComment, onTaskCreated: createTask, onTaskUpdated: updateTask, onTaskDeleted: deleteTask, onFileDeleted: onFileDeleted, onFilesSelectedForUpload: onFilesSelectedForUpload, currentlyUploadingFiles: currentlyUploadingFiles, showToolbar: true, onClose: !isSmallDevice ? closeChronology : undefined, entityUid: sellerLead.uid })))))))),
        React.createElement(Hidden, { lgUp: true },
            React.createElement(Grid2, { style: { background: "white" } },
                React.createElement(Tabs, { value: tab, onChange: onTabChanged, variant: "fullWidth" },
                    React.createElement(Tab, { value: "overview", label: "Overview" }),
                    React.createElement(Tab, { value: "data", label: "Data" }),
                    React.createElement(Tab, { value: "chronology", label: "Chronology" }))))));
};
export default SellerLeadsForm;
