import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import User from "@app/classes/user";
import CoreLoading from "@app/common/CoreLoading";
import { useGetSessionInfo } from "@app/orval/api/auth";
import Session from "@app/util/Session";
const LoggingIn = () => {
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid, { container: true, direction: "column", spacing: 2 },
            React.createElement(Grid, { item: true },
                React.createElement(CoreLoading, null)),
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1", style: { textAlign: "center" } }, "Logging in...")))));
};
export const SessionContext = createContext(null);
export const SessionProvider = (props) => {
    var _a, _b;
    const sessionApi = useGetSessionInfo();
    const session = (_b = (_a = sessionApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    console.log("ACTIVE USER", window._DASH_ACTIVE_USER);
    const activeUser = useMemo(() => {
        var _a;
        const userInfo = (_a = session === null || session === void 0 ? void 0 : session.user_info) !== null && _a !== void 0 ? _a : window._DASH_ACTIVE_USER;
        return userInfo ? new User(userInfo) : null;
    }, [session === null || session === void 0 ? void 0 : session.user_info]);
    const viewingAsUser = useMemo(() => {
        var _a, _b, _c;
        const viewingAsUserInfo = (_c = (_b = (_a = session === null || session === void 0 ? void 0 : session.viewing_as_user_info) !== null && _a !== void 0 ? _a : session === null || session === void 0 ? void 0 : session.user_info) !== null && _b !== void 0 ? _b : window._DASH_VIEWING_AS_USER) !== null && _c !== void 0 ? _c : window._DASH_ACTIVE_USER;
        return viewingAsUserInfo ? new User(viewingAsUserInfo) : null;
    }, [session === null || session === void 0 ? void 0 : session.user_info, session === null || session === void 0 ? void 0 : session.viewing_as_user_info]);
    const [isReady, setIsReady] = useState(!!(activeUser && viewingAsUser));
    useEffect(() => {
        if (session || (activeUser && viewingAsUser)) {
            Session.activeUser = activeUser;
            Session.viewingAsUser = viewingAsUser;
            setIsReady(true);
        }
    }, [activeUser, session, setIsReady, viewingAsUser]);
    console.log({ isReady, session, activeUser, viewingAsUser });
    return (React.createElement(SessionContext.Provider, { value: {
            activeUser,
            viewingAsUser,
        } }, isReady ? props.children : React.createElement(LoggingIn, null)));
};
const useSession = () => {
    const session = useContext(SessionContext);
    return session;
};
export default useSession;
