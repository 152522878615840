import * as React from "react";
import { MoreVert } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import CoreMenu from "@app/common/CoreMenu";
import useSize from "@app/hooks/useSize";
const ActionsMenu = React.forwardRef((props, ref) => {
    const { IconButtonProps, children, variant } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const size = useSize();
    const openMenu = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [setAnchorEl]);
    const closeMenu = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    return (React.createElement(React.Fragment, null,
        size.isDown("sm") || variant === "iconbutton" ? (React.createElement(IconButton, Object.assign({}, IconButtonProps, { ref: ref, size: "small", onClick: openMenu, style: { marginLeft: "auto", marginRight: "auto" }, tabIndex: -1 }),
            React.createElement(MoreVert, { fontSize: "small" }))) : (React.createElement(Button, { ref: ref, variant: "contained", onClick: openMenu, className: "dash-actions-button", color: "primary", tabIndex: -1 }, "Actions")),
        anchorEl && (React.createElement(CoreMenu, { open: true, anchorEl: anchorEl, onClose: closeMenu, onClick: closeMenu }, children))));
});
ActionsMenu.displayName = "ActionsMenu";
export default ActionsMenu;
