import * as React from "react";
/**
 * TODO: do we need this component
 * @returns
 */
const Login = () => {
    React.useEffect(() => {
        window.location.href = window.location.href;
    }, []);
    return null;
};
export default Login;
