import * as React from "react";
import { Abc, AccessTime, ArrowDropDown, CalendarMonth, Check, Code, Event, Numbers } from "@mui/icons-material";
const FieldTypeIcon = (props) => {
    const { fontSize, style, type } = props;
    if (type === "checkbox") {
        return React.createElement(Check, { style: style, fontSize: fontSize });
    }
    if (type === "number") {
        return React.createElement(Numbers, { style: style, fontSize: fontSize });
    }
    if (type === "text") {
        return React.createElement(Abc, { style: style, fontSize: fontSize });
    }
    if (type === "choice") {
        return React.createElement(ArrowDropDown, { style: style, fontSize: fontSize });
    }
    if (type === "date") {
        return React.createElement(CalendarMonth, { style: style, fontSize: fontSize });
    }
    if (type === "datetime") {
        return React.createElement(Event, { style: style, fontSize: fontSize });
    }
    if (type === "time") {
        return React.createElement(AccessTime, { style: style, fontSize: fontSize });
    }
    if (type === "formula") {
        return React.createElement(Code, { style: style, fontSize: fontSize });
    }
    console.warn(`Unrecognized type ${type}`);
    return null;
};
export default FieldTypeIcon;
