import * as React from "react";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import BuyerLeads from "./BuyerLeads";
import { SpreadsheetProvider } from "../components/spreadsheet-view/SpreadsheetContext";
import usePaginatedTable from "../components/spreadsheet-view/usePaginatedTable";
const BuyerLeadsPage = () => {
    useDocumentTitle("Buyer Leads");
    const { limit, offset, search, showNewsfeed, stage, view } = usePaginatedTable();
    return (React.createElement(SpreadsheetProvider, null,
        React.createElement(BuyerLeads, { limit: limit !== null && limit !== void 0 ? limit : undefined, offset: offset !== null && offset !== void 0 ? offset : undefined, search: search !== null && search !== void 0 ? search : undefined, stage: stage !== null && stage !== void 0 ? stage : undefined, view: view !== null && view !== void 0 ? view : undefined, showNewsfeed: showNewsfeed })));
};
export default BuyerLeadsPage;
