import * as React from "react";
import { Grid2, Typography } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import RouterLink from "@app/common/RouterLink";
import FieldTypeIcon from "./FieldTypeIcon";
import { DragAndDropItemTypes } from "./utils";
const SectionField = (props) => {
    var _a;
    const { entityType, field, onReorder, sectionUid } = props;
    const outerRef = React.useRef(null);
    const [posOfDraggedObject, setPosOfDraggedObject] = React.useState(null);
    const { uid } = field;
    const [_, drag] = useDrag(() => ({
        type: DragAndDropItemTypes.FIELD,
        item: { uid, entityType, sectionUid },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: DragAndDropItemTypes.FIELD,
        hover: (_, monitor) => {
            const { x, y } = monitor.getClientOffset();
            setPosOfDraggedObject({ x, y });
        },
        drop: (item, monitor) => {
            const { x, y } = monitor.getClientOffset();
            const { height, top } = outerRef.current.getBoundingClientRect();
            let pos = "above";
            if (y > top + height / 2) {
                pos = "below";
            }
            console.log(
            // @ts-expect-error: unknown
            `dropped item ${item.uid} ${pos} field ${uid} in section ${sectionUid} at pos ${x} ${y}`);
            // @ts-expect-error: unknown
            onReorder({ fieldUid: item.uid, targetSectionUid: sectionUid, targetFieldUid: uid, position: pos });
        },
        canDrop: (item) => {
            // @ts-expect-error: item is of type unknown
            return item.entityType === entityType && item.uid !== uid;
        },
        collect: (monitor) => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            };
        },
        // collect: (monitor) => ({
        //   isOver: monitor.isOver(),
        //   canDrop: monitor.canDrop(),
        // }),
    }), [onReorder, sectionUid, uid]);
    const ref = React.useCallback((node) => {
        return drag(drop(node));
    }, [drag, drop]);
    const rect = (_a = outerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    const active = (() => {
        if (!canDrop) {
            return null;
        }
        if (!isOver) {
            return null;
        }
        if (!posOfDraggedObject) {
            return null;
        }
        if (rect) {
            const midpoint = rect.top + rect.height / 2;
            const draggedObjectY = posOfDraggedObject.y;
            return draggedObjectY > midpoint ? "below" : "above";
        }
        return null;
    })();
    return (React.createElement("div", { ref: outerRef },
        React.createElement(Grid2, { ref: ref, container: true, spacing: 1, pl: 12, alignItems: "center", wrap: "nowrap", style: {
                borderTop: active === "above" ? "2px solid #88aaee" : undefined,
                borderBottom: active === "below" ? "2px solid #88aaee" : undefined,
            } },
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(FieldTypeIcon, { type: field.field_type, style: { color: "#aaa" }, fontSize: "medium" })),
            React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(RouterLink, { to: `/field-management?field=${field.uid}` },
                    React.createElement(Typography
                    // {...dragHandleProps}
                    , null, field.name))))));
};
export default SectionField;
