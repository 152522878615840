import * as React from "react";
import { useParams } from "react-router-dom";
import MAtrixForm from "@app/entrypoints/brokerage/features/apps/matrix/MAtrixForm";
import { TAB_NAMES } from "@app/entrypoints/brokerage/features/apps/matrix/utils";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
const MAtrixFormPage = () => {
    var _a, _b;
    const uidParams = useParams();
    const tabParams = useParams();
    const tab = (_a = tabParams.tab) !== null && _a !== void 0 ? _a : TAB_NAMES[0];
    const tab_ = (_b = TAB_NAMES.find((t) => t.toLowerCase().trim() === tab.toLowerCase().trim())) !== null && _b !== void 0 ? _b : tab;
    useDocumentTitle(`MAtrix | ${tab_.replaceAll("-", " ")}`);
    return React.createElement(MAtrixForm, { uid: uidParams.uid, currentTab: tab_ });
};
export default MAtrixFormPage;
