import * as React from "react";
import { Add, Person } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import CoreLoading from "@app/common/CoreLoading";
import AddClientDialog from "./AddClientDialog";
import ClientsReorder from "./ClientsReorder";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Clients = (props) => {
    var _a;
    const { allowEditing, clients, getBuyerLeadApi, getSellerLeadApi, onAddExistingClient, onAddNewClient, onRemoveClient, onReorderClients, } = props;
    const [isAddClientDialogOpen, setIsAddClientDialogOpen] = React.useState(false);
    const openAddClientDialog = React.useCallback(() => {
        setIsAddClientDialogOpen(true);
    }, []);
    const closeAddClientDialog = React.useCallback(() => {
        setIsAddClientDialogOpen(false);
    }, []);
    const addExistingClient = React.useCallback((clientUid) => {
        onAddExistingClient(clientUid);
        closeAddClientDialog();
    }, [closeAddClientDialog, onAddExistingClient]);
    const addNewClient = React.useCallback((clientData) => {
        onAddNewClient(clientData);
        closeAddClientDialog();
    }, [closeAddClientDialog, onAddNewClient]);
    const isLoading = clients == null;
    const noClients = clients && clients.length === 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(CollapsibleSection, { icon: React.createElement(Person, null), title: "Clients", count: (_a = clients === null || clients === void 0 ? void 0 : clients.length) !== null && _a !== void 0 ? _a : undefined, toolbar: onAddExistingClient || onAddNewClient ? (React.createElement(Tooltip, { title: React.createElement(Typography, null, "Add client") },
                React.createElement(IconButton, { size: "small", onClick: openAddClientDialog },
                    React.createElement(Add, { fontSize: "small" })))) : null },
            isLoading && React.createElement(CoreLoading, null),
            noClients && React.createElement(Typography, { variant: "body2" }, "No clients"),
            clients && clients.length > 0 && (React.createElement(ClientsReorder, { clients: clients, onReorderClients: onReorderClients, onRemoveClient: onRemoveClient, allowEditing: allowEditing, getBuyerLeadApi: getBuyerLeadApi, getSellerLeadApi: getSellerLeadApi }))),
        React.createElement(AddClientDialog, { DialogProps: { open: isAddClientDialogOpen }, onAddExistingClientSelected: addExistingClient, onAddNewClientSelected: addNewClient, onClose: closeAddClientDialog })));
};
export default Clients;
