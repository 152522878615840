var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import QuillMention from "quill-mention";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillFontWhitelist, Formatters } from "./utils";
Quill.register("modules/mention", QuillMention, true);
const Font = Quill.import("formats/font");
Font.whitelist = quillFontWhitelist;
Quill.register(Font, true);
/** Defines the items in the `ReactQuill` toolbar */
const modules = {
    toolbar: [
        [{ font: Font.whitelist }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        [{ script: "sub" }, { script: "super" }],
        ["link", "image"],
        ["clean"],
    ],
};
/** Needed to whitelist the actions associated with `ReactQuill` */
const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "align",
    "list",
    "bullet",
    "indent",
    "sub",
    "super",
    "link",
    "image",
    "mention",
];
export default class MailScheduleQuill extends React.Component {
    constructor() {
        super(...arguments);
        /** `source` function for `QuillMention` */
        Object.defineProperty(this, "source", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (searchTerm, renderList, mentionChar) => {
                const { boilerplate, fields } = this.props;
                if ((mentionChar === "$" || mentionChar === "$'") && fields) {
                    const values = [
                        {
                            id: "name",
                            value: "Name",
                        },
                        ...fields
                            .sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            if (a.name > b.name)
                                return 1;
                            return 0;
                        })
                            .map((field) => ({
                            id: field.key,
                            value: field.name,
                        }))
                            .concat(boilerplate
                            ? boilerplate
                                .sort((a, b) => {
                                if (a.name < b.name)
                                    return -1;
                                if (a.name > b.name)
                                    return 1;
                                return 0;
                            })
                                .map((item) => ({
                                id: item.uid,
                                value: item.name,
                            }))
                            : []),
                    ];
                    if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                    }
                    else {
                        const matches = values.filter((v) => v.value.toLowerCase().includes(searchTerm.toLowerCase()));
                        renderList(matches, searchTerm);
                    }
                }
                else if (mentionChar === ":" || mentionChar === "::") {
                    const formatters = [
                        Formatters.Birthday.name,
                        Formatters.Currency.name,
                        Formatters.Date.name,
                        Formatters.Datetime.name,
                        Formatters.DaysUntil.name,
                        Formatters.DaysSince.name,
                        Formatters.Fulldate.name,
                        Formatters.MMDDYY.name,
                        Formatters.TinyURL.name,
                    ];
                    const values = formatters.map((formatter) => ({
                        id: formatter,
                        value: formatter,
                    }));
                    const matches = values.filter((v) => v.value.toLowerCase().includes(searchTerm.toLowerCase()));
                    renderList(matches, searchTerm);
                }
            }
        });
    }
    /** Uses `QuillMention` to add a `mention` module to `ReactQuill`, allowing the user to type the `$` character and have it auto-populate from a list of Streak fields */
    get mentionModule() {
        return {
            allowedChars: /^.*$/,
            mentionDenotationChars: ["$", "$'", "::"],
            source: this.source,
            showDenotationChar: false,
            // onSelect: this.onSelect,
        };
    }
    render() {
        const _a = this.props, { children, fields } = _a, other = __rest(_a, ["children", "fields"]);
        return (React.createElement(ReactQuill, Object.assign({ modules: Object.assign(Object.assign({}, modules), { mention: this.mentionModule, clipboard: {
                    matchVisual: false,
                } }), formats: formats }, other)));
    }
}
