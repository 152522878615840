import * as React from "react";
import { Article } from "@mui/icons-material";
import { List, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import CoreError from "@app/common/CoreError";
import { useListQfos } from "@app/orval/api/forms-questions-for-the-offer";
import { useListQfsSubmissionNewcrm } from "@app/orval/api/questions-for-the-search";
import QFOListItem from "./QFOListItem";
import QFSListItem from "./QFSListItem";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
const Forms = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { buyerLeadUid, onRemoveQFO } = props;
    const queryClient = useQueryClient();
    const listQFSesAPI = useListQfsSubmissionNewcrm({ buyer_lead_uid: buyerLeadUid });
    const qfses = (_b = (_a = listQFSesAPI.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listQFOsAPI = useListQfos({ buyer_lead_uid: buyerLeadUid });
    const qfos = (_d = (_c = listQFOsAPI.data) === null || _c === void 0 ? void 0 : _c.data.map((x) => {
        var _a;
        return (Object.assign(Object.assign({}, x), { created_at: x.created, property_address: (_a = x.fields["Property Address"]) !== null && _a !== void 0 ? _a : null }));
    })) !== null && _d !== void 0 ? _d : null;
    const removeQFO = React.useCallback((uid) => {
        onRemoveQFO(qfos.find((x) => x.uid === uid).buyer_lead_property_uid, uid);
        queryClient.setQueryData(listQFOsAPI.queryKey, (oldData) => {
            if (oldData == null) {
                return oldData;
            }
            return Object.assign(Object.assign({}, oldData), { data: oldData.data.filter((x) => x.uid !== uid) });
        });
    }, [listQFOsAPI.queryKey, onRemoveQFO, qfos, queryClient]);
    const count = qfos != null || qfses != null ? ((_e = qfos === null || qfos === void 0 ? void 0 : qfos.length) !== null && _e !== void 0 ? _e : 0) + ((_f = qfses === null || qfses === void 0 ? void 0 : qfses.length) !== null && _f !== void 0 ? _f : 0) : undefined;
    return (React.createElement(CollapsibleSection, { icon: React.createElement(Article, null), title: "Forms", count: count },
        (listQFSesAPI.isFetching || listQFOsAPI.isFetching) && (React.createElement(Typography, { variant: "body2", style: { color: "#888" } }, "Loading...")),
        listQFSesAPI.error && React.createElement(CoreError, { error: listQFSesAPI.error }),
        listQFOsAPI.error && React.createElement(CoreError, { error: listQFOsAPI.error }),
        (qfses === null || qfses === void 0 ? void 0 : qfses.length) === 0 && (qfos === null || qfos === void 0 ? void 0 : qfos.length) === 0 && React.createElement(Typography, { variant: "body2" }, "No forms submitted"),
        qfses && qfses.length > 0 && (React.createElement(List, { dense: true }, qfses.map((qfs) => (React.createElement(QFSListItem, { key: qfs.uid, qfs: qfs }))))),
        qfos && qfos.length > 0 && (React.createElement(List, { dense: true }, qfos.map((qfo) => (React.createElement(QFOListItem, { key: qfo.uid, qfo: qfo, onRemoveQFO: removeQFO })))))));
};
export default Forms;
