// this component uses @mdxeditor/editor as a WYSIWYG markdown editor
// see https://mdxeditor.dev/editor/docs/overview for documentation
var _a;
import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Delete, KeyboardArrowLeft, Preview, Save, Share } from "@mui/icons-material";
import { Box, Button, Divider, Grid2, InputAdornment, MenuItem, TextField } from "@mui/material";
// eslint-disable-next-line import/order
import { AdmonitionDirectiveDescriptor, BlockTypeSelect, BoldItalicUnderlineToggles, CodeToggle, CreateLink, DiffSourceToggleWrapper, InsertAdmonition, InsertTable, InsertThematicBreak, ListsToggle, MDXEditor, Separator, UndoRedo, diffSourcePlugin, directivesPlugin, headingsPlugin, imagePlugin, linkDialogPlugin, linkPlugin, listsPlugin, quotePlugin, tablePlugin, thematicBreakPlugin, toolbarPlugin, } from "@mdxeditor/editor";
/**
 * NOTE: this is completely a hack that we're including the style for @mdxeditor in this file.
 *
 * This is just copy/pasted from node_modules/@mdxeditor/editor/dist/style.css
 *
 * `import @mdxeditor/editor/dist/style.css` is how the documentation says styles should be imported,
 * but for some reason that's not working.
 */
// import "./style";
import "@mdxeditor/editor/style.css";
import { useConfirm } from "material-ui-confirm";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useListSopFolders } from "@app/orval/api/sop-folders";
import { createSop, deleteSop, getSop, updateSop } from "@app/orval/api/sops";
import Session from "@app/util/Session";
import { UploadImageDirectiveDescriptor, UploadImageButton, EmbedVideoButton, EmbedVideoDirectiveDescriptor, } from "./custom_directives";
import SOP from "./SOP";
import SOPShareDialog from "./SOPShareDialog";
import { formatImages, formatInvalidEmailLinks, getParents, imageUploader } from "./utils";
const canEditSource = (_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]);
const toolbarContents = (React.createElement(React.Fragment, null,
    React.createElement(UndoRedo, null),
    React.createElement(Separator, null),
    React.createElement(BoldItalicUnderlineToggles, null),
    React.createElement(CodeToggle, null),
    React.createElement(Separator, null),
    React.createElement(ListsToggle, null),
    React.createElement(Separator, null),
    React.createElement(BlockTypeSelect, null),
    React.createElement(Separator, null),
    React.createElement(CreateLink, null),
    React.createElement(UploadImageButton, null),
    React.createElement(EmbedVideoButton, null),
    React.createElement(Separator, null),
    React.createElement(InsertTable, null),
    React.createElement(InsertThematicBreak, null),
    React.createElement(Separator, null),
    React.createElement(InsertAdmonition, null)));
const plugins = [
    headingsPlugin(),
    listsPlugin(),
    quotePlugin(),
    thematicBreakPlugin(),
    directivesPlugin({
        directiveDescriptors: [
            AdmonitionDirectiveDescriptor,
            UploadImageDirectiveDescriptor,
            EmbedVideoDirectiveDescriptor,
        ],
    }),
    imagePlugin({ imageUploadHandler: imageUploader }),
    tablePlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    diffSourcePlugin(),
    toolbarPlugin({
        toolbarContents: () => canEditSource ? React.createElement(DiffSourceToggleWrapper, null, toolbarContents) : toolbarContents,
    }),
];
const EditSOP = (props) => {
    var _a, _b, _c;
    const { uid } = props;
    const [sop, setSop] = useStateWithCallback(null);
    const [initialSop, setInitialSop] = useStateWithCallback(null);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [isSaving, setIsSaving] = useStateWithCallback(false);
    const [isDeleting, setIsDeleting] = useStateWithCallback(false);
    const [isPreviewing, setIsPreviewing] = useStateWithCallback(false);
    const [error, setError] = useStateWithCallback(null);
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const listFoldersApi = useListSopFolders();
    const folders = (_b = (_a = listFoldersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const child = React.createRef();
    const hasChanges = React.useMemo(() => {
        return initialSop == null || JSON.stringify(sop) !== JSON.stringify(initialSop);
    }, [initialSop, sop]);
    const canSave = React.useMemo(() => {
        return (sop === null || sop === void 0 ? void 0 : sop.name.trim()) && sop.content.trim() && hasChanges;
    }, [sop, hasChanges]);
    /**
     * Alerts the user that there are unsaved changes before
     * navigating away from the form
     *
     * @returns true if there are unsaved changes
     */
    const onBeforeUnload = React.useCallback((e) => {
        console.log("onbeforeunload");
        if (hasChanges) {
            console.log("has changes");
            const message = "You have unsaved changes.";
            e.preventDefault();
            e.returnValue = message;
            return true;
        }
        return undefined;
    }, [hasChanges]);
    const retrieveSop = React.useCallback(() => {
        if (uid) {
            getSop(uid, { permission_type: "Edit" })
                .then((result) => {
                setSop({
                    parent_uid: result.data.parent_uid,
                    name: result.data.name,
                    content: result.data.content,
                    sharing: [...result.data.sharing],
                });
            })
                .catch((err) => {
                setError(err);
            });
        }
    }, [setError, setSop, uid]);
    const createBlankSop = React.useCallback(() => {
        setSop({
            parent_uid: null,
            name: "",
            content: "",
            sharing: [],
        });
    }, [setSop]);
    React.useEffect(() => {
        if (uid) {
            retrieveSop();
        }
        else {
            createBlankSop();
        }
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [uid, retrieveSop, createBlankSop, onBeforeUnload]);
    const onNameChange = React.useCallback((e) => {
        const val = e.target.value;
        setSop((prev) => {
            if (!prev) {
                return {
                    name: val,
                    content: "",
                    sharing: [],
                    parent_uid: null,
                };
            }
            return Object.assign(Object.assign({}, prev), { name: val });
        });
    }, [setSop]);
    const onParentFolderChange = React.useCallback((e) => {
        const val = e.target.value;
        setSop((prev) => {
            if (!prev) {
                return {
                    name: "",
                    content: "",
                    sharing: [],
                    parent_uid: val,
                };
            }
            return Object.assign(Object.assign({}, prev), { parent_uid: val });
        });
    }, [setSop]);
    const onSave = React.useCallback(() => {
        setIsSaving(true, () => {
            var _a, _b, _c;
            const loadingSnackbar = enqueueSnackbar("Updating SOP...", {
                variant: "info",
                persist: true,
            });
            const payload = {
                name: (_a = sop === null || sop === void 0 ? void 0 : sop.name) !== null && _a !== void 0 ? _a : "",
                content: (_b = sop === null || sop === void 0 ? void 0 : sop.content) !== null && _b !== void 0 ? _b : "",
                parent_uid: (_c = sop === null || sop === void 0 ? void 0 : sop.parent_uid) !== null && _c !== void 0 ? _c : null,
            };
            if (uid) {
                updateSop(uid, payload)
                    .then((result) => {
                    setInitialSop(Object.assign(Object.assign({}, result.data), { sharing: [...result.data.sharing] }));
                    enqueueSnackbar("Saved SOP", { variant: "success" });
                    navigate(`/sops/${result.data.uid}`);
                })
                    .catch((err) => {
                    errorDialog(err);
                })
                    .finally(() => {
                    setIsSaving(false);
                    closeSnackbar(loadingSnackbar);
                });
            }
            else {
                createSop(payload)
                    .then((result) => {
                    setInitialSop(Object.assign(Object.assign({}, result.data), { sharing: [...result.data.sharing] }));
                    enqueueSnackbar("Saved SOP", { variant: "success" });
                })
                    .catch((err) => {
                    errorDialog(err);
                })
                    .finally(() => {
                    setIsSaving(false);
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [errorDialog, navigate, setInitialSop, setIsSaving, sop, uid]);
    const onDelete = React.useCallback(() => {
        confirm({
            title: "Are you sure?",
            description: "You won't be able to undo this action.",
            confirmationText: "Delete",
            confirmationButtonProps: {
                color: "secondary",
                variant: "contained",
                tabIndex: -1,
            },
        }).then((result) => {
            if (result.confirmed) {
                setIsDeleting(true, () => {
                    if (uid) {
                        deleteSop(uid)
                            .then(() => {
                            navigate("/sops");
                        })
                            .catch((err) => {
                            errorDialog(err);
                        })
                            .then(() => {
                            setIsDeleting(false);
                        });
                    }
                });
            }
        });
    }, [confirm, errorDialog, navigate, setIsDeleting, uid]);
    const setContent = React.useCallback((markdown) => {
        console.log("SET NEW CONTENT", markdown);
        setSop((prev) => {
            if (!prev) {
                return {
                    name: "",
                    content: markdown,
                    sharing: [],
                    parent_uid: null,
                };
            }
            return Object.assign(Object.assign({}, prev), { content: markdown });
        });
    }, [setSop]);
    const preview = React.useCallback(() => {
        setIsPreviewing(true);
    }, [setIsPreviewing]);
    const closePreview = React.useCallback(() => {
        setIsPreviewing(false);
    }, [setIsPreviewing]);
    const onShareClick = React.useCallback(() => {
        var _a;
        // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
        (_a = child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
        setShareDialogOpen(true);
    }, [child]);
    const onShareComplete = React.useCallback((result) => {
        setSop(Object.assign(Object.assign({}, result), { sharing: [...result.sharing] }));
        setShareDialogOpen(false);
    }, [setSop]);
    const onShareCancel = React.useCallback(() => {
        setShareDialogOpen(false);
    }, []);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!sop) {
        return React.createElement(CoreLoading, null);
    }
    if (isPreviewing) {
        return React.createElement(SOP, { sop: sop, onClose: closePreview });
    }
    // TODO: test if we still need the replaceAll
    const content = formatInvalidEmailLinks(formatImages(sop.content.replaceAll("<!---->", `



    `)));
    console.log("CONTENT:", content);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid2, { container: true, spacing: 2 },
            React.createElement(Grid2, { size: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, spacing: 2 },
                        React.createElement(Grid2, { size: { xs: 12, sm: 6 }, style: { marginTop: "auto" } },
                            React.createElement(TextField, { size: "small", variant: "standard", fullWidth: true, slotProps: {
                                    input: {
                                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Name"),
                                    },
                                }, value: sop.name, onChange: onNameChange })),
                        folders && (React.createElement(Grid2, { size: { xs: 12, sm: 6 }, style: { marginTop: "auto" } },
                            React.createElement(TextField, { fullWidth: true, variant: "standard", select: true, slotProps: {
                                    input: {
                                        startAdornment: React.createElement(InputAdornment, { position: "start" }, "Parent Folder"),
                                    },
                                }, value: (_c = sop.parent_uid) !== null && _c !== void 0 ? _c : "", onChange: onParentFolderChange },
                                React.createElement(MenuItem, { value: "" }, "None"),
                                folders
                                    .filter((f) => {
                                    var _a;
                                    // this folder can't be a parent of itself,
                                    // and we can't put it in one of it's child folders
                                    return f.uid === sop.parent_uid ||
                                        (f.uid !== sop.parent_uid &&
                                            !getParents(f, folders)
                                                .map((p) => p.uid)
                                                .includes((_a = sop.parent_uid) !== null && _a !== void 0 ? _a : ""));
                                })
                                    .map((f) => {
                                    const parents = getParents(f, folders);
                                    const name = [...parents.map((p) => p.name).reverse(), f.name].join(" > ");
                                    return (React.createElement(MenuItem, { key: f.uid, value: f.uid }, name));
                                }))))))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Divider, null)),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(MDXEditor, { markdown: content, plugins: plugins, onChange: setContent }))),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Divider, null)),
            React.createElement(Grid2, { size: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, spacing: 2 },
                        uid && (React.createElement(Grid2, null,
                            React.createElement(Button, { onClick: onDelete, startIcon: React.createElement(Delete, null), variant: "contained", color: "secondary" }, "Delete"))),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { onClick: preview, startIcon: React.createElement(Preview, null), variant: "outlined" }, "Preview")),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { startIcon: React.createElement(Share, null), variant: "outlined", onClick: onShareClick }, "Share")),
                        React.createElement(Grid2, { style: { flex: 1 } }),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { component: RouterLink, to: "/sops", startIcon: React.createElement(KeyboardArrowLeft, null), variant: "outlined" }, "Back to SOPs")),
                        React.createElement(Grid2, null,
                            React.createElement(Button, { onClick: onSave, startIcon: React.createElement(Save, null), variant: "contained", color: "primary", disabled: isSaving || isDeleting || !canSave }, "Save")))))),
        React.createElement(SOPShareDialog, { dialogProps: {
                open: !!shareDialogOpen,
            }, sop: sop, 
            // @ts-expect-error Type '(result: SOPWithContent) => void' is not assignable to type '(data: Sop) => void'
            onSaveComplete: onShareComplete, onCancel: onShareCancel })));
};
export default EditSOP;
