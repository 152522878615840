import React from "react";
import useSession from "@app/hooks/useSession";
// Define the HOC
const withSession = (WrappedComponent) => {
    // This component uses the useSession hook and wraps another component
    // eslint-disable-next-line react/display-name
    return (props) => {
        const session = useSession(); // Use the hook to get session data
        // Render the wrapped component with session data as props
        return React.createElement(WrappedComponent, Object.assign({}, props, { session: session }));
    };
};
export default withSession;
