var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext } from "react";
import omit from "lodash/omit";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { MailMergeContext } from "@app/entrypoints/brokerage/context/mail-merge/MailMergeContext";
import { createDraft } from "@app/entrypoints/brokerage/window-messages";
import { downloadFileFromCloudStorage } from "@app/orval/api/google-cloud";
import { useParseMultipleMailschedule4Templates } from "@app/orval/api/mail-schedule-parsing";
import { uploadCustomTemplateAttachment } from "@app/orval/api/mail-schedule-run";
import { getMailschedule4Template } from "@app/orval/api/mail-schedule-templates";
import { fileToBase64, isChromeExtension } from "@app/util/Utils";
import { enqueueErrorSnackbar } from "../snackbars";
/**
 * Draft templated emails via the Gmail API
 *
 * @param options
 * @returns
 */
const useEmailDraftCreator = () => {
    const parseMultipleTemplatesApi = useParseMultipleMailschedule4Templates();
    const context = useContext(MailMergeContext);
    const createEmailDrafts = (params) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // if we only have a few templates to process, and we're running
        // within Gmail, just create the drafts in the Gmail compose window
        if (isChromeExtension && params.items.length <= 6) {
            const { attachments, items } = params;
            const loadingSnackbar = enqueueSnackbar(`Parsing ${items.length} email${items.length !== 1 ? "s" : ""}...`, {
                variant: "info",
                persist: true,
            });
            parseMultipleTemplatesApi
                .mutateAsync({
                data: {
                    items: items.map((item) => {
                        const { template } = item;
                        if (template === null || template === void 0 ? void 0 : template.email_draft_template) {
                            template.email_draft_template = omit(template.email_draft_template, "sender", "sender_type");
                        }
                        if (template === null || template === void 0 ? void 0 : template.email_template) {
                            template.email_template = omit(template.email_template, "sender", "sender_type");
                        }
                        return {
                            template_uid: item.templateUid,
                            template: item.template,
                            entity_uid: item.entityUid,
                            entity_type: item.entityType,
                        };
                    }),
                },
            })
                .then((result) => __awaiter(void 0, void 0, void 0, function* () {
                // sanity check
                if (result.data.length !== items.length) {
                    throw new Error("Mismatched number of items returned from the server");
                }
                const commonAttachments = yield Promise.all((attachments !== null && attachments !== void 0 ? attachments : []).map((x) => __awaiter(void 0, void 0, void 0, function* () {
                    return ({
                        filename: x.name,
                        content: yield fileToBase64(x),
                    });
                })));
                const drafts = yield Promise.all(result.data.map((item, i) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                    const attachments_ = yield Promise.all([
                        ...((_a = items[i].attachments) !== null && _a !== void 0 ? _a : []).map((x) => __awaiter(void 0, void 0, void 0, function* () {
                            return ({
                                filename: x.name,
                                content: yield fileToBase64(x),
                            });
                        })),
                        ...((_b = item.attachments) !== null && _b !== void 0 ? _b : []),
                    ]);
                    return {
                        to: (_d = (_c = item.email_draft_template) === null || _c === void 0 ? void 0 : _c.to) !== null && _d !== void 0 ? _d : (_e = item.email_template) === null || _e === void 0 ? void 0 : _e.to,
                        cc: (_g = (_f = item.email_draft_template) === null || _f === void 0 ? void 0 : _f.cc) !== null && _g !== void 0 ? _g : (_h = item.email_template) === null || _h === void 0 ? void 0 : _h.cc,
                        bcc: (_k = (_j = item.email_draft_template) === null || _j === void 0 ? void 0 : _j.bcc) !== null && _k !== void 0 ? _k : (_l = item.email_template) === null || _l === void 0 ? void 0 : _l.bcc,
                        subject: (_o = (_m = item.email_draft_template) === null || _m === void 0 ? void 0 : _m.subject) !== null && _o !== void 0 ? _o : (_p = item.email_template) === null || _p === void 0 ? void 0 : _p.subject,
                        body: (_r = (_q = item.email_draft_template) === null || _q === void 0 ? void 0 : _q.body) !== null && _r !== void 0 ? _r : (_s = item.email_template) === null || _s === void 0 ? void 0 : _s.body,
                        attachments: attachments_,
                        entity_uids: [items[i].entityUid],
                        template_uid: items[i].templateUid,
                    };
                })));
                console.log({ drafts });
                for (const draft of drafts) {
                    createDraft({ draft: Object.assign(Object.assign({}, draft), { attachments: [...draft.attachments, ...commonAttachments] }) });
                }
            }))
                .catch(enqueueErrorSnackbar)
                .then(() => {
                closeSnackbar(loadingSnackbar);
            });
        }
        // if we're not running within gmail, or have a bunch of
        // drats to process, run them as a Mail Merge
        else {
            const commonAttachmentPromises = ((_a = params.attachments) !== null && _a !== void 0 ? _a : []).map((x) => __awaiter(void 0, void 0, void 0, function* () {
                return uploadCustomTemplateAttachment({
                    filename: x.name,
                    content: yield fileToBase64(x),
                });
            }));
            const commonAttachmentResponse = yield Promise.all(commonAttachmentPromises);
            const commonAttachments = commonAttachmentResponse.map((x) => ({
                filename: x.data.filename,
                gcs_filename: x.data.gcs_filename,
            }));
            params.items.forEach((x) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
                const attachmentPromises = ((_a = x.attachments) !== null && _a !== void 0 ? _a : []).map((a) => __awaiter(void 0, void 0, void 0, function* () {
                    return uploadCustomTemplateAttachment({
                        filename: a.name,
                        content: yield fileToBase64(a),
                    });
                }));
                const attachmentResponse = yield Promise.all(attachmentPromises);
                const attachments = attachmentResponse.map((a) => ({
                    filename: a.data.filename,
                    gcs_filename: a.data.gcs_filename,
                }));
                const template = (_b = x.template) !== null && _b !== void 0 ? _b : (yield getMailschedule4Template(x.templateUid)).data;
                const gcpAttachmentPromises = x.templateUid && !x.template && template.gcp_attachments
                    ? template.gcp_attachments.map((a) => __awaiter(void 0, void 0, void 0, function* () {
                        return downloadFileFromCloudStorage({
                            blob_name: a.blob_name,
                        });
                    }))
                    : [];
                const gcpAttachmentResponse = yield Promise.all(gcpAttachmentPromises);
                const uploadedAttachmentPromises = gcpAttachmentResponse.map((a) => __awaiter(void 0, void 0, void 0, function* () {
                    return uploadCustomTemplateAttachment({
                        content: a.data.file,
                        filename: a.data.filename,
                    });
                }));
                const uploadedGcpAttachments = yield Promise.all(uploadedAttachmentPromises);
                context
                    .runMailMerge({
                    data: {
                        entity_uids: [x.entityUid],
                        custom_templates: [
                            {
                                base_template_uid: (_c = x.templateUid) !== null && _c !== void 0 ? _c : null,
                                email_draft_template: {
                                    to: (_g = (_e = (_d = template.email_draft_template) === null || _d === void 0 ? void 0 : _d.to) !== null && _e !== void 0 ? _e : (_f = template.email_template) === null || _f === void 0 ? void 0 : _f.to) !== null && _g !== void 0 ? _g : "",
                                    cc: (_l = (_j = (_h = template.email_draft_template) === null || _h === void 0 ? void 0 : _h.cc) !== null && _j !== void 0 ? _j : (_k = template.email_template) === null || _k === void 0 ? void 0 : _k.cc) !== null && _l !== void 0 ? _l : "",
                                    bcc: (_q = (_o = (_m = template.email_draft_template) === null || _m === void 0 ? void 0 : _m.bcc) !== null && _o !== void 0 ? _o : (_p = template.email_template) === null || _p === void 0 ? void 0 : _p.bcc) !== null && _q !== void 0 ? _q : "",
                                    subject: (_u = (_s = (_r = template.email_draft_template) === null || _r === void 0 ? void 0 : _r.subject) !== null && _s !== void 0 ? _s : (_t = template.email_template) === null || _t === void 0 ? void 0 : _t.subject) !== null && _u !== void 0 ? _u : "",
                                    body: (_y = (_w = (_v = template.email_draft_template) === null || _v === void 0 ? void 0 : _v.body) !== null && _w !== void 0 ? _w : (_x = template.email_template) === null || _x === void 0 ? void 0 : _x.body) !== null && _y !== void 0 ? _y : "",
                                    attachments: [...commonAttachments, ...attachments, ...uploadedGcpAttachments.map((a) => a.data)],
                                },
                            },
                        ],
                        is_test: false,
                        should_ignore_criteria: true,
                    },
                })
                    .catch(enqueueErrorSnackbar);
            }));
        }
    });
    return {
        createEmailDrafts,
        parseMultipleTemplatesApi,
    };
};
export default useEmailDraftCreator;
