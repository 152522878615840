import * as React from "react";
import { useContext } from "react";
import { Grid2 } from "@mui/material";
import { TwilioSMSContext } from "../../context/twilio-sms/TwilioSMSContext";
import TwilioSMSConversationWrapper from "../../context/twilio-sms/TwilioSMSConversationWrapper";
const SMS = () => {
    const context = useContext(TwilioSMSContext);
    const closeConversation = React.useCallback((uid) => () => {
        context.closeConversation(uid);
    }, [context]);
    const conversations = React.useMemo(() => {
        return [...context.conversations].reverse();
    }, [context.conversations]);
    return (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 }, conversations.map((conversation) => (React.createElement(Grid2, { key: conversation.uid, style: { marginTop: "auto" } },
        React.createElement("div", { style: { width: "300px" } },
            React.createElement(TwilioSMSConversationWrapper, { defaultFromPhoneNumber: conversation.fromPhoneNumber, defaultToPhoneNumber: conversation.toPhoneNumber, onClose: closeConversation(conversation.uid) })))))));
};
export default SMS;
