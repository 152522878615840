export const DragAndDropItemTypes = {
    FIELD: "field",
};
export const sanitizeStageName = (name) => {
    return name
        .toUpperCase()
        .trim()
        .replace(/[^0-9A-Z]/g, "_");
};
export const getAutocompleteSuggestionsForField = (entityType, allFields, allStages) => {
    var _a;
    const autocompleteEntityTypes = new Set([entityType]);
    // if this is a Buyer Contract or Seller Contract,
    // we need to be able to reference fields from Buyer Leads or Seller Leads
    if (entityType === "Buyer Contract") {
        autocompleteEntityTypes.add("Buyer Lead");
    }
    if (entityType === "Listing") {
        autocompleteEntityTypes.add("Seller Lead");
    }
    const autocompletes = allFields
        .filter((x) => autocompleteEntityTypes.has(x.entity_type))
        .map((x) => {
        let fieldType = "unknown";
        if (x.field_type === "choice") {
            if (x.allows_multiple) {
                fieldType = "string[]";
            }
            else {
                fieldType = "string";
            }
        }
        else if (x.field_type === "number") {
            fieldType = "number";
        }
        else if (x.field_type === "checkbox") {
            fieldType = "boolean";
        }
        else if (["text", "date", "datetime", "time"].includes(x.field_type)) {
            fieldType = "string";
        }
        else if (x.field_type === "formula") {
            fieldType = "any";
        }
        return {
            label: `$'${x.name}'`,
            type: `${fieldType} | undefined`,
            documentation: x.description,
        };
    });
    const relevantStages = (_a = allStages === null || allStages === void 0 ? void 0 : allStages.filter((x) => x.type === entityType)) !== null && _a !== void 0 ? _a : [];
    relevantStages.forEach((stage) => {
        const base = `$STAGE_${sanitizeStageName(stage.name)}`;
        autocompletes.push({
            label: `${base}.uid`,
            type: "string | null",
        });
        autocompletes.push({
            label: `${base}.name`,
            type: "string | null",
        });
        autocompletes.push({
            label: `${base}.position`,
            type: "number | null",
        });
    });
    if (entityType === "Buyer Lead" ||
        entityType === "Seller Lead" ||
        entityType === "Listing" ||
        entityType === "Buyer Contract" ||
        entityType === "Recruit" ||
        entityType === "User") {
        autocompletes.push({
            label: "$stage?.uid",
            type: "string | null",
        });
        autocompletes.push({
            label: "$stage?.name",
            type: "string | null",
        });
        autocompletes.push({
            label: "$stage?.updated_at",
            type: "string | null",
        });
        autocompletes.push({
            label: "$stage?.position",
            type: "string | null",
        });
    }
    if (entityType === "Buyer Lead" ||
        entityType === "Buyer Contract" ||
        entityType === "Listing" ||
        entityType === "Seller Lead") {
        const agentAutocompleteTypes = ["producer", "co_agent"];
        for (const agentAutocompleteType of agentAutocompleteTypes) {
            autocompletes.push({
                label: `$${agentAutocompleteType}?.first_name`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${agentAutocompleteType}?.last_name`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${agentAutocompleteType}?.email`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${agentAutocompleteType}?.phone`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${agentAutocompleteType}?.split`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${agentAutocompleteType}?.agent_statuses`,
                type: "string | null",
            });
        }
        const vendorTypes = [
            "buyer_closing_attorney",
            "inspector",
            "lender",
            "listing_agent",
            "paralegal",
            "seller_closing_attorney",
            "site_manager",
            "transaction_coordinator",
            "zillow_loan_officer",
        ];
        for (const vendorType of vendorTypes) {
            autocompletes.push({
                label: `$${vendorType}?.first_name`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${vendorType}?.last_name`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${vendorType}?.email`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${vendorType}?.phone`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$${vendorType}?.company`,
                type: "string | null",
            });
        }
        for (let i = 0; i < 2; ++i) {
            autocompletes.push({
                label: `$clients[${i}]?.first_name`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$clients[${i}]?.last_name`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$clients[${i}]?.email`,
                type: "string | null",
            });
            autocompletes.push({
                label: `$clients[${i}]?.phone`,
                type: "string | null",
            });
        }
        autocompletes.push({
            label: "$name",
            type: "string | null",
        });
        autocompletes.push({
            label: "$notes",
            type: "string | null",
        });
    }
    for (const commonField of ["created_at", "updated_at", "archived_at"]) {
        autocompletes.push({
            label: `$${commonField}`,
            type: "string | null",
        });
    }
    if (entityType === "Buyer Contract" || entityType === "Listing") {
        autocompletes.push({
            label: "$flex_payout",
            type: "number | null",
        });
    }
    return autocompletes;
};
export const isChildSection = (childSectionUid, parentSectionUid, allSections) => {
    const childSection = allSections.find((x) => x.uid === childSectionUid);
    if (!(childSection === null || childSection === void 0 ? void 0 : childSection.parent_section)) {
        return false;
    }
    if (childSection.parent_section === parentSectionUid) {
        return true;
    }
    if (childSection.parent_section) {
        return isChildSection(childSection.parent_section, parentSectionUid, allSections);
    }
    return false;
};
