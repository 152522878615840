var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { ListItem, ListItemAvatar, ListItemText, TextField, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router";
import FieldTypeIcon from "./FieldTypeIcon";
const getOptionLabel = (option) => {
    return option.name;
};
const isOptionEqualToValue = (option, value) => {
    return (option === null || option === void 0 ? void 0 : option.uid) == (value === null || value === void 0 ? void 0 : value.uid);
};
const renderOption = (props, option) => {
    return (React.createElement(ListItem, Object.assign({}, props, { key: option.uid, style: { paddingTop: 0, paddingBottom: 0 } }),
        React.createElement(ListItemAvatar, { style: { width: "35px", minWidth: "35px" } },
            React.createElement(FieldTypeIcon, { type: option.field_type, fontSize: "large" })),
        React.createElement(ListItemText, { primary: option.name, secondary: option.entity_type })));
};
const FieldManagementAutocomplete = (props) => {
    var _a, _b, _c, _d, _e;
    const { AutocompleteProps, TextFieldProps, fields, value } = props, other = __rest(props, ["AutocompleteProps", "TextFieldProps", "fields", "value"]);
    const [inputValue, setInputValue] = React.useState(null);
    const navigate = useNavigate();
    const handleInputValueChanged = React.useCallback((e) => {
        const val = e.target.value;
        setInputValue(val);
    }, [setInputValue]);
    const handleAutocompleteValueChanged = React.useCallback((_, value_, reason) => {
        setInputValue(null);
        if (reason === "selectOption") {
            navigate(value_ ? `/field-management?field=${value_.uid}` : "/field-management");
        }
    }, [navigate]);
    const renderInput = React.useCallback((params) => {
        var _a;
        return (React.createElement(TextField, Object.assign({ variant: "outlined" }, params, TextFieldProps, { inputProps: Object.assign({}, params.inputProps), autoFocus: (_a = TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.autoFocus) !== null && _a !== void 0 ? _a : true, onChange: handleInputValueChanged, InputProps: Object.assign(Object.assign({}, params.InputProps), TextFieldProps === null || TextFieldProps === void 0 ? void 0 : TextFieldProps.InputProps) })));
    }, [TextFieldProps, handleInputValueChanged]);
    return (React.createElement(Autocomplete, Object.assign({ clearOnBlur: true, clearOnEscape: true, componentsProps: {
            paper: {
                style: {
                    minWidth: "300px",
                },
            },
        } }, other, AutocompleteProps, { getOptionLabel: getOptionLabel, isOptionEqualToValue: isOptionEqualToValue, groupBy: (_b = (_a = props.AutocompleteProps) === null || _a === void 0 ? void 0 : _a.groupBy) !== null && _b !== void 0 ? _b : undefined, noOptionsText: (_d = (_c = props.AutocompleteProps) === null || _c === void 0 ? void 0 : _c.noOptionsText) !== null && _d !== void 0 ? _d : "No search results found", inputValue: (_e = inputValue !== null && inputValue !== void 0 ? inputValue : value === null || value === void 0 ? void 0 : value.name) !== null && _e !== void 0 ? _e : "", onChange: handleAutocompleteValueChanged, options: fields, renderInput: renderInput, renderOption: renderOption })));
};
export default FieldManagementAutocomplete;
