var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Check, ErrorOutline, Info, Report, Warning } from "@mui/icons-material";
import { Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, InputAdornment, MenuItem, Select, Typography, Tooltip, } from "@mui/material";
import axios from "axios";
import omit from "lodash/omit";
import numeral from "numeral";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import CoreDateField from "./CoreDateField";
import CoreNumberField from "./CoreNumberField";
import CoreTextField from "./CoreTextField";
import { enqueueErrorSnackbar } from "./snackbars";
const DashAutoSaveField = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { apiEndpoint, choiceFilter, field, hasPicker, multiplyBy100, onError, onSaveBegin, onSuccess, shouldSave = true, suppressError, useCheckboxes, value: value_, warning, } = props;
    const [isSaving, setIsSaving] = React.useState(false);
    const [saveStatus, setSaveStatus] = React.useState(null);
    const [value, setValue] = useStateWithCallback(value_);
    const errorDialog = useErrorDialog();
    const previousValue = React.useRef((() => {
        let val = value_;
        if (val === undefined) {
            val = null;
        }
        else if (field.field_type !== "text" && val === "") {
            val = null;
        }
        return val;
    })());
    React.useEffect(() => {
        if (value_ !== value) {
            previousValue.current = props.value;
            setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value_]);
    const save = React.useCallback((valueToSave) => {
        let fields = {};
        fields[field.name] = valueToSave;
        if (onSaveBegin) {
            const result = onSaveBegin(valueToSave);
            if (result) {
                fields = Object.assign(Object.assign({}, fields), result);
            }
        }
        if (shouldSave) {
            setIsSaving(true);
            console.log("saving fields", fields);
            axios
                .post(apiEndpoint, {
                fields,
            })
                .then((result) => {
                console.log("API call done");
                setIsSaving(false);
                setSaveStatus("success");
                setTimeout(() => {
                    setSaveStatus(null);
                }, 3000);
                if (onSuccess) {
                    console.log("API call success", result);
                    onSuccess(result);
                }
            })
                .catch((error) => {
                var _a;
                if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status >= 500) {
                    console.error(error);
                }
                setIsSaving(false);
                setSaveStatus("error");
                setTimeout(() => {
                    setSaveStatus(null);
                }, 3000);
                if (onError) {
                    onError(error);
                }
                else if (!suppressError) {
                    enqueueErrorSnackbar(error);
                }
            });
        }
    }, [apiEndpoint, field.name, onError, onSaveBegin, onSuccess, shouldSave, suppressError]);
    const setChecked = React.useCallback((_, checked) => {
        setValue(checked);
        save(checked);
    }, [setValue, save]);
    const setNumber = React.useCallback((number) => {
        if (String(number) !== String(Number.parseFloat(previousValue.current)) ||
            (number === null && previousValue.current !== null)) {
            previousValue.current = number;
            save(number);
        }
    }, [save]);
    const setDate = React.useCallback((date) => {
        let prev = previousValue.current;
        let _date = date;
        if (prev != null && String(prev).trim() === "") {
            prev = null;
        }
        if (_date !== null && String(_date).trim() === "") {
            _date = null;
        }
        if (_date !== prev) {
            previousValue.current = String(_date).trim() === "" ? null : _date;
            save(String(_date).trim() === "" ? null : _date);
        }
    }, [save]);
    const setDropdownChecked = React.useCallback((option) => (_, checked) => {
        const prevValue = (value !== null && value !== void 0 ? value : []).flat().filter((obj) => obj);
        const newValue = checked ? [...prevValue, option.name] : prevValue.filter((opt) => opt !== option.name);
        setValue(newValue);
        save(newValue);
    }, [save, setValue, value]);
    const setChoiceMultiple = React.useCallback((e) => {
        const val = e.target.value;
        if (JSON.stringify(val) !== JSON.stringify(previousValue.current)) {
            previousValue.current = val;
            save(val);
            setValue(e.target.value);
        }
    }, [save, setValue]);
    const renderChoiceMultiple = React.useCallback((selected) => (React.createElement("div", { style: {
            display: "flex",
            flexWrap: "wrap",
        } }, selected.map((valueToRender) => (React.createElement(Chip, { key: valueToRender, label: valueToRender, style: { margin: 2, height: "unset" } }))))), []);
    const setChoice = React.useCallback((e) => {
        let val = e.target.value;
        if (val === "") {
            val = null;
        }
        if (val !== previousValue.current) {
            previousValue.current = val;
            save(val);
            setValue(e.target.value);
        }
    }, [save, setValue]);
    const setText = React.useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);
    const onTextBlur = React.useCallback((e) => {
        var _a;
        const val = value;
        if ((val !== null && val !== void 0 ? val : "") !== ((_a = previousValue.current) !== null && _a !== void 0 ? _a : "")) {
            previousValue.current = e.target.value;
            save(e.target.value);
        }
    }, [save, value]);
    const onFormulaErrorClick = React.useCallback(() => {
        var _a;
        console.log("ERROR");
        errorDialog({
            title: `Error: ${field.name}`,
            text: (_a = value.error) !== null && _a !== void 0 ? _a : "",
        });
    }, [errorDialog, field.name, value === null || value === void 0 ? void 0 : value.error]);
    const onFormulaDescriptionClick = React.useCallback(() => {
        errorDialog({
            title: `${field.name}`,
            html: `Formula:<br><br><pre>${field.formula.replace(/\$'.*?'/g, "<span style='color: #fc038c; font-weight: bold'>$&</span>")}</pre><hr>Parsed:<br><br><pre>${value.parsed_formula}</pre>`,
        });
    }, [errorDialog, field.formula, field.name, value === null || value === void 0 ? void 0 : value.parsed_formula]);
    let endAdornment = (_b = (_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.endAdornment) !== null && _b !== void 0 ? _b : null;
    if (isSaving) {
        endAdornment = (React.createElement(InputAdornment, { position: "end" },
            React.createElement(CircularProgress, { size: 25 })));
    }
    else if (saveStatus === "success") {
        endAdornment = (React.createElement(InputAdornment, { position: "end" },
            React.createElement(Check, null)));
    }
    else if (saveStatus === "error") {
        endAdornment = (React.createElement(InputAdornment, { position: "end" },
            React.createElement(ErrorOutline, { color: "secondary" })));
    }
    else if (warning) {
        endAdornment = (React.createElement(InputAdornment, { position: "end" },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, warning) },
                React.createElement(Warning, { color: "warning" }))));
    }
    const fieldType = React.useMemo(() => {
        var _a;
        if (!field) {
            return null;
        }
        if (field["field_type"] !== "formula") {
            return field["field_type"];
        }
        // infer field type for formula fields based on format
        if ((_a = field["format"]) === null || _a === void 0 ? void 0 : _a.includes("0")) {
            return "number";
        }
        if (field["format"]) {
            return "date";
        }
        return "text";
    }, [field]);
    if (!field) {
        return null;
    }
    if (fieldType === "checkbox") {
        const { fullWidth } = props, formControlProps = __rest(props, ["fullWidth"]);
        return (
        // @ts-expect-error type is not assignable to type 'IntrinsicAttributes & FormControlLabelProps'
        React.createElement(FormControlLabel, Object.assign({}, formControlProps, { label: formControlProps.label, control: React.createElement(Checkbox, { color: "primary", checked: value || false, onChange: setChecked, disabled: !!props.disabled }) })));
    }
    if (fieldType === "number") {
        return (React.createElement(CoreNumberField, Object.assign({}, props, { number: Number.isNaN(value) || value === undefined ? null : numeral(value).value(), 
            // value={value}
            max: field.max !== undefined ? field.max : null, min: field.min !== undefined ? field.min : null, format: (_c = field.format) !== null && _c !== void 0 ? _c : "0,0", multiplyBy100: multiplyBy100, onChange: props.onChange, onNumberChange: setNumber, disabled: !!props.disabled, InputProps: Object.assign(Object.assign({}, props.InputProps), { endAdornment }) })));
    }
    if (fieldType === "date") {
        return (React.createElement(CoreDateField, Object.assign({}, props, { hasPicker: hasPicker, date: value === undefined ? null : value, format: (_d = field.format) !== null && _d !== void 0 ? _d : "M/D/YYYY", formatType: ((_e = field.format) === null || _e === void 0 ? void 0 : _e.includes("D")) ? "dayjs" : "datejs", onChange: props.onChange, onDateChange: setDate, disabled: !!props.disabled, InputProps: Object.assign(Object.assign({}, props.InputProps), { endAdornment }) })));
    }
    if (fieldType === "choice") {
        if (field.allows_multiple) {
            const { fullWidth } = props, formControlProps = __rest(props, ["fullWidth"]);
            if (useCheckboxes) {
                return (React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, { variant: "body2" }, (_f = props.label) !== null && _f !== void 0 ? _f : field.name)),
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } }, endAdornment)),
                    React.createElement(Grid, { item: true, xs: 12 }, field.choice_set.choices.map((option) => (React.createElement(FormControlLabel, { key: option.id, label: option.name, control: React.createElement(Checkbox, { size: "small", color: "primary", onChange: setDropdownChecked(option), checked: [value].flat().includes(option.name) }) }))))));
            }
            return (React.createElement(FormControl, Object.assign({}, formControlProps, { fullWidth: fullWidth }),
                React.createElement(InputLabel, null, props.label),
                React.createElement(Select, Object.assign({ multiple: true, fullWidth: fullWidth, value: [value].flat().filter((obj) => obj != null && obj !== ""), onChange: setChoiceMultiple, input: React.createElement(Input, Object.assign({}, props.InputProps, { fullWidth: fullWidth, endAdornment: endAdornment })), disabled: !!props.disabled, renderValue: renderChoiceMultiple }, omit(props, "value")), field.choice_set.choices.map((option) => (React.createElement(MenuItem, { key: option.id, value: option.name }, option.name))))));
        }
        return (React.createElement(CoreTextField, Object.assign({}, props, { value: value !== null && value !== void 0 ? value : "", onChange: setChoice, slotProps: {
                input: Object.assign(Object.assign(Object.assign({}, props.inputProps), (_g = props.slotProps) === null || _g === void 0 ? void 0 : _g.input), { endAdornment }),
            }, disabled: !!props.disabled, select: true }),
            React.createElement(MenuItem, { key: -1, value: "" }),
            field.choice_set.choices.filter(choiceFilter !== null && choiceFilter !== void 0 ? choiceFilter : (() => true)).map((option) => (React.createElement(MenuItem, { key: option.id, value: option.name }, option.name)))));
    }
    if (fieldType === "text") {
        return (React.createElement(CoreTextField, Object.assign({}, props, { value: value !== null && value !== void 0 ? value : "", 
            // regexp={field.validation || null}
            onChange: setText, onBlur: onTextBlur, disabled: !!props.disabled, slotProps: {
                input: Object.assign(Object.assign(Object.assign({}, props.InputProps), (_h = props.slotProps) === null || _h === void 0 ? void 0 : _h.input), { endAdornment }),
            } })));
    }
    if (field["field_type"] === "formula") {
        let val = value.value;
        if (value.error) {
            val = "ERROR";
        }
        const endAdorn = (React.createElement(InputAdornment, { position: "end", style: { position: "absolute", right: "0px" } },
            value.error && (React.createElement(IconButton, { tabIndex: -1, style: { padding: 0 }, onClick: onFormulaErrorClick },
                React.createElement(Report, { size: 25, color: "secondary" }))),
            value.formula && value.parsed_formula && (React.createElement(IconButton, { tabIndex: -1, style: { padding: 0 }, onClick: onFormulaDescriptionClick },
                React.createElement(Info, { size: 25 })))));
        if (field.format && !value.error) {
            if (field.format.includes("/") || field.format.includes("-")) {
                return (React.createElement(CoreDateField, Object.assign({}, props, { date: val !== null && val !== void 0 ? val : null, format: field.format, disabled: true, slotProps: {
                        input: Object.assign(Object.assign(Object.assign({}, props.inputProps), (_j = props.slotProps) === null || _j === void 0 ? void 0 : _j.input), { endAdornment }),
                    } })));
            }
            return (React.createElement(CoreNumberField, Object.assign({}, props, { number: val == null ? null : numeral(val).value(), format: field.format, multiplyBy100: multiplyBy100, disabled: true, InputProps: Object.assign(Object.assign({}, props.InputProps), { endAdornment: endAdorn }) })));
        }
        return (React.createElement(CoreTextField, Object.assign({}, props, { value: val !== null && val !== void 0 ? val : "", disabled: true, InputProps: Object.assign(Object.assign({}, props.InputProps), { endAdornment: endAdorn }) })));
    }
    return null;
};
export default DashAutoSaveField;
