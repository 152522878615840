var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Box, Divider, Grid2, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import housecanaryLogo from "@app/assets/img/housecanary.png";
import DashAutoSaveField from "@app/common/CoreAutoSaveField";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useGetHousecanaryTaxHistory } from "@app/orval/api/house-canary";
const HouseCanary = (props) => {
    var _a;
    const { matrix, schema } = props;
    const getHouseCanaryTaxRecordsApi = useGetHousecanaryTaxHistory({ address: (_a = matrix.fields["Property Address"]) !== null && _a !== void 0 ? _a : "", cached_since: "2022-01-01T00:00:00Z" }, { query: { enabled: false } });
    const confirm = useConfirm();
    const getTaxHistory = React.useCallback(() => {
        getHouseCanaryTaxRecordsApi
            .refetch()
            .then((result) => {
            const mostRecentTaxHistory = result
                .data.data.items.map((x) => x.result.tax_history)
                .flat()
                .sort((a, b) => {
                const aYear = a.assessment_year;
                const bYear = b.assessment_year;
                if (aYear > bYear)
                    return -1;
                if (aYear < bYear)
                    return 1;
                return 0;
            })[0];
            // populate fields using most recent tax history
            console.log({ mostRecentTaxHistory });
        })
            .catch(enqueueErrorSnackbar);
    }, [getHouseCanaryTaxRecordsApi]);
    const onHousecanaryClicked = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield confirm({
            title: "Pull tax info via HouseCanary",
            description: "This costs us 40 cents if we don't have up-to-date records for this property!",
            confirmationText: "Pull info",
            confirmationButtonProps: { variant: "contained" },
        });
        if (response.confirmed) {
            getTaxHistory();
        }
    }), [confirm, getTaxHistory]);
    const onFieldSaveBegin = React.useCallback((fieldName) => () => {
        console.log({ fieldName });
    }, []);
    const onFieldSaveComplete = React.useCallback(() => { }, []);
    console.log({ matrix, schema });
    return (React.createElement(Paper, { elevation: 1, style: { width: "100%" } },
        React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
            React.createElement(Grid2, { style: { background: "rgb(250, 250, 255)" } },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid2, { container: true, direction: "row", alignItems: "center", spacing: 1 },
                        React.createElement(Grid2, { style: { flex: 1 } },
                            React.createElement(Typography, { variant: "h6" }, "HouseCanary")),
                        React.createElement(Grid2, null,
                            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Pull tax info via HouseCanary"), disableInteractive: true },
                                React.createElement(IconButton, { onClick: onHousecanaryClicked, disabled: matrix.fields["Property Address"] == null || getHouseCanaryTaxRecordsApi.isFetching },
                                    React.createElement("img", { src: housecanaryLogo, alt: "HouseCanary", height: 24 }))))))),
            React.createElement(Grid2, null,
                React.createElement(Divider, null)),
            React.createElement(Grid2, null,
                React.createElement(Box, { p: 1 },
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 },
                        React.createElement(Grid2, null,
                            React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, label: "HouseCanary Assessment Year", field: schema["HouseCanary Assessment Year"], value: matrix.fields["HouseCanary Assessment Year"], apiEndpoint: `/api/psps/${matrix.uid}`, onSaveBegin: onFieldSaveBegin("HouseCanary Assessment Year"), onSuccess: onFieldSaveComplete, size: "small" })),
                        React.createElement(Grid2, null,
                            React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, label: "HouseCanary Tax Amount", field: schema["HouseCanary Tax Amount"], value: matrix.fields["HouseCanary Tax Amount"], apiEndpoint: `/api/psps/${matrix.uid}`, onSaveBegin: onFieldSaveBegin("HouseCanary Tax Amount"), onSuccess: onFieldSaveComplete, size: "small" })),
                        React.createElement(Grid2, null,
                            React.createElement(DashAutoSaveField, { variant: "standard", fullWidth: true, label: "HouseCanary Total Assessed Value", field: schema["HouseCanary Total Assessed Value"], value: matrix.fields["HouseCanary Total Assessed Value"], apiEndpoint: `/api/psps/${matrix.uid}`, onSaveBegin: onFieldSaveBegin("HouseCanary Total Assessed Value"), onSuccess: onFieldSaveComplete, size: "small" }))))))));
};
export default HouseCanary;
