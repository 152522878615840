import * as React from "react";
import { useLocation, useParams } from "react-router";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import ReportConfig from "./ReportConfig";
import ReportsTable from "./ReportsTable";
const ReportConfigWrapper = () => {
    useDocumentTitle("Google Data Studios");
    const location = useLocation();
    const params = useParams();
    const { uid } = params;
    if (uid || location.pathname.includes("/new")) {
        return React.createElement(ReportConfig, { uid: uid });
    }
    return React.createElement(ReportsTable, null);
};
export default ReportConfigWrapper;
