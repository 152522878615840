import * as React from "react";
import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import useStateCallback from "@app/hooks/useStateCallback";
import { useGetGoogleMapsStreetViewPhoto } from "@app/orval/api/geocode";
const StyledLightbox = styled(Lightbox) `
  & .yarl_root {
    background: transparent;
  }
  & .yarl__container {
    background: rgba(0, 0, 0, 0.85);
  }
`;
const StreetViewImage = (props) => {
    var _a, _b, _c, _d;
    const { address } = props;
    const defaultIcon = (_a = props.defaultIcon) !== null && _a !== void 0 ? _a : null;
    const streetViewPhotoApi = useGetGoogleMapsStreetViewPhoto({ address }, { query: { enabled: !!address && address.length > 4, staleTime: Infinity, gcTime: Infinity } });
    const streetViewPhotoUrl = (_c = (_b = streetViewPhotoApi.data) === null || _b === void 0 ? void 0 : _b.data.photo_url) !== null && _c !== void 0 ? _c : null;
    const [isLightboxOpen, setIsLightboxOpen] = useStateCallback(false);
    const preventDefault = React.useCallback((e) => {
        e.preventDefault();
    }, []);
    const onClickToClose = (e) => {
        if (e.target.classList.contains("yarl__slide")) {
            closeLightbox();
        }
    };
    const openLightbox = React.useCallback(() => {
        setIsLightboxOpen(true, () => {
            window.setTimeout(() => {
                document.addEventListener("click", onClickToClose);
            }, 1);
        });
    }, [onClickToClose, setIsLightboxOpen]);
    const closeLightbox = React.useCallback(() => {
        setIsLightboxOpen(false, () => {
            document.removeEventListener("click", onClickToClose);
        });
    }, [onClickToClose, setIsLightboxOpen]);
    if (streetViewPhotoUrl) {
        const res = (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        React.createElement("img", Object.assign({ src: streetViewPhotoUrl, alt: address }, props.ImageProps, { style: Object.assign({ cursor: "pointer" }, (_d = props.ImageProps) === null || _d === void 0 ? void 0 : _d.style), onClick: openLightbox })));
        return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        React.createElement("div", { onClick: preventDefault },
            res,
            React.createElement(StyledLightbox, { open: isLightboxOpen, close: closeLightbox, slides: [{ src: streetViewPhotoUrl }], render: {
                    buttonPrev: () => null,
                    buttonNext: () => null,
                } })));
    }
    return defaultIcon;
};
export default StreetViewImage;
