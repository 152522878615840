import * as React from "react";
import { IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
const AttachFilesButton = (props) => {
    const { IconButtonProps, children, inputProps, onFilesSelected } = props;
    const inputId = React.useRef(uuidv4());
    const onClick = React.useCallback(() => {
        document.getElementById(inputId.current).click();
    }, []);
    const onChange = React.useCallback((e) => {
        onFilesSelected(Array.from(e.target.files));
        e.target.value = "";
    }, [onFilesSelected]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, Object.assign({ tabIndex: -1 }, IconButtonProps, { onClick: onClick }), children),
        React.createElement("input", Object.assign({ id: inputId.current, type: "file", multiple: true, onChange: onChange }, inputProps, { style: { display: "none" } }))));
};
export default AttachFilesButton;
