import * as React from "react";
import { IconButton } from "@mui/material";
import CoreDateFieldPicker from "./CoreDateFieldPicker";
import CorePopover from "./CorePopover";
const DatePickerIconButton = (props) => {
    const { children } = props;
    const [pickerAnchorEl, setPickerAnchorEl] = React.useState(null);
    const openPicker = React.useCallback((e) => {
        setPickerAnchorEl(e.currentTarget);
    }, [setPickerAnchorEl]);
    const closeDatePicker = React.useCallback(() => {
        setPickerAnchorEl(null);
    }, [setPickerAnchorEl]);
    const onChange = React.useCallback((date) => {
        closeDatePicker();
        props.DateFieldPickerProps.onChange(date);
    }, [closeDatePicker, props.DateFieldPickerProps]);
    const onClose = React.useCallback(() => {
        closeDatePicker();
    }, [closeDatePicker]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, Object.assign({}, props.IconButtonProps, { onClick: openPicker }), children),
        pickerAnchorEl && (React.createElement(CorePopover, { open: true, anchorEl: pickerAnchorEl, onClose: closeDatePicker },
            React.createElement(CoreDateFieldPicker, Object.assign({}, props.DateFieldPickerProps, { onChange: onChange, onClose: onClose }))))));
};
export default DatePickerIconButton;
