import * as React from "react";
import { CalendarMonth } from "@mui/icons-material";
import { Box, Grid2, Typography } from "@mui/material";
import CoreDateField from "@app/common/CoreDateField";
import CorePopover from "@app/common/CorePopover";
import ResponsiveFilterChip from "./ResponsiveFilterChip";
import useAgentDashboardFilters from "../../useAgentDashboardFilters";
const DateFilters = () => {
    const { closingDateGTE, closingDateLTE, effectiveDateGTE, effectiveDateLTE, leadCreationDateGTE, leadCreationDateLTE, setClosingDateGTE, setClosingDateLTE, setEffectiveDateGTE, setEffectiveDateLTE, setLeadCreationDateGTE, setLeadCreationDateLTE, } = useAgentDashboardFilters();
    const [panelAnchorEl, setPanelAnchorEl] = React.useState(null);
    const handleDateFilterClicked = React.useCallback((e) => {
        setPanelAnchorEl(e.currentTarget);
    }, [setPanelAnchorEl]);
    const closePanel = React.useCallback(() => {
        setPanelAnchorEl(null);
    }, [setPanelAnchorEl]);
    const numDateFiltersSelected = [
        leadCreationDateGTE,
        leadCreationDateLTE,
        effectiveDateGTE,
        effectiveDateLTE,
        closingDateGTE,
        closingDateLTE,
    ].filter((x) => x != null).length;
    const label = `${numDateFiltersSelected} date filter${numDateFiltersSelected === 1 ? "" : "s"}`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveFilterChip, { size: "small", label: label, icon: React.createElement(CalendarMonth, { fontSize: "small" }), onClick: handleDateFilterClicked, isActive: numDateFiltersSelected > 0 }),
        panelAnchorEl && (React.createElement(CorePopover, { anchorEl: panelAnchorEl, onClose: closePanel, open: true, sx: { "& .MuiPaper-root ": { minWidth: "300px" } } },
            React.createElement(Box, { p: 4 },
                React.createElement(Grid2, { container: true, direction: "column", spacing: 2, style: { overflow: "hidden" } },
                    React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { style: { fontWeight: "bold" } }, "Lead Creation Date")),
                            React.createElement(Grid2, null,
                                React.createElement(CoreDateField, { date: leadCreationDateGTE, onDateChange: setLeadCreationDateGTE, variant: "standard", size: "small", format: "M/d/yyyy", hasPicker: true })),
                            React.createElement(Grid2, null,
                                React.createElement(Typography, null, "to")),
                            React.createElement(Grid2, null,
                                React.createElement(CoreDateField, { date: leadCreationDateLTE, onDateChange: setLeadCreationDateLTE, variant: "standard", size: "small", format: "M/d/yyyy", hasPicker: true })))),
                    React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { style: { fontWeight: "bold" } }, "Effective Date")),
                            React.createElement(Grid2, null,
                                React.createElement(CoreDateField, { date: effectiveDateGTE, onDateChange: setEffectiveDateGTE, variant: "standard", size: "small", format: "M/d/yyyy", hasPicker: true })),
                            React.createElement(Grid2, null,
                                React.createElement(Typography, null, "to")),
                            React.createElement(Grid2, null,
                                React.createElement(CoreDateField, { date: effectiveDateLTE, onDateChange: setEffectiveDateLTE, variant: "standard", size: "small", format: "M/d/yyyy", hasPicker: true })))),
                    React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { style: { fontWeight: "bold" } }, "Closing Date")),
                            React.createElement(Grid2, null,
                                React.createElement(CoreDateField, { date: closingDateGTE, onDateChange: setClosingDateGTE, variant: "standard", size: "small", format: "M/d/yyyy", hasPicker: true })),
                            React.createElement(Grid2, null,
                                React.createElement(Typography, null, "to")),
                            React.createElement(Grid2, null,
                                React.createElement(CoreDateField, { date: closingDateLTE, onDateChange: setClosingDateLTE, variant: "standard", size: "small", format: "M/d/yyyy", hasPicker: true }))))))))));
};
export default DateFilters;
