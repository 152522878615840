/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Submit a new question for the search.
 */
export const submitQuestionsForTheSearch = (questionsForTheSearchNEWCRMRequest, options) => {
    return axios.post(`/api/forms/questions_for_the_search/submit`, questionsForTheSearchNEWCRMRequest, options);
};
export const getSubmitQuestionsForTheSearchMutationOptions = (options) => {
    const mutationKey = ['submitQuestionsForTheSearch'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return submitQuestionsForTheSearch(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Submit a new question for the search.
*/
export const useSubmitQuestionsForTheSearch = (options) => {
    const mutationOptions = getSubmitQuestionsForTheSearchMutationOptions(options);
    return useMutation(mutationOptions);
};
