import * as React from "react";
import { Edit, KeyboardArrowDown, KeyboardArrowRight, Warning } from "@mui/icons-material";
import { Box, Collapse, MenuItem, Grid, IconButton, Tooltip, Typography, ListItemIcon, Grid2 } from "@mui/material";
import isObject from "lodash/isObject";
import { useMeasure } from "react-use";
import styled from "styled-components";
import CoreAutoSaveField from "@app/common/CoreAutoSaveField";
import RouterLink from "@app/common/RouterLink";
import ActionsMenu from "./ActionsMenu";
import DataEntryNonEditableField from "./DataEntryNonEditableField";
import DataEntrySection from "./DataEntrySection";
import useTeamAdminPermissions from "../../users/useTeamAdminPermissions";
const DataEntryContainer = styled(Grid2) `
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  align-content: flex-start;
  & .MuiInput-input {
    font-size: 0.75rem;
  }
  & .MuiFormControlLabel-root > .MuiTypography-root {
    font-size: 0.75rem;
  }
  & .MuiInputLabel-animated:not(.MuiInputLabel-shrink) {
    font-size: 0.75rem;
  }
`;
const StyledFieldWrapper = styled(Grid2) `
  .field-actions {
    display: none;
  }
  &:hover .field-actions {
    display: unset;
  }
`;
const minInputElementWidth = 200;
const DataEntry = (props) => {
    const { apiUri, collapseCompletedSectionsByDefault = false, fields, isCollapsible, isEditable = true, name, sections, subordinateUserUidsToCheck, values, viewAllEnabled = false, } = props;
    const [isOpen, setIsOpen] = React.useState(true);
    const [ref, measure] = useMeasure();
    const inputElementWidth = React.useMemo(() => {
        if (measure.width <= minInputElementWidth) {
            return 12;
        }
        if (measure.width <= minInputElementWidth * 2) {
            return 6;
        }
        if (measure.width <= minInputElementWidth * 3) {
            return 4;
        }
        return 3;
    }, [measure.width]);
    const onFieldSaveBegin = React.useCallback((fieldName) => (fieldValue) => {
        if (props.onFieldSaveBegin) {
            props.onFieldSaveBegin(fieldName, fieldValue);
        }
    }, [props]);
    const collapse = React.useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    const { effectiveUser } = useTeamAdminPermissions(subordinateUserUidsToCheck !== null && subordinateUserUidsToCheck !== void 0 ? subordinateUserUidsToCheck : []);
    const effectiveRoles = effectiveUser === null || effectiveUser === void 0 ? void 0 : effectiveUser.roles;
    const fieldsSaturated = React.useMemo(() => {
        return fields.map((f) => (Object.assign(Object.assign({}, f), { isEditable: f.permissions.length === 0 || f.permissions.some((p) => p.can_edit && (effectiveRoles === null || effectiveRoles === void 0 ? void 0 : effectiveRoles.includes(p.role))), isVisible: f.permissions.length === 0 || f.permissions.some((p) => effectiveRoles === null || effectiveRoles === void 0 ? void 0 : effectiveRoles.includes(p.role)) })));
    }, [effectiveRoles, fields]);
    const fieldsBySectionUid = React.useMemo(() => {
        var _a, _b;
        const res = {};
        for (const field of fieldsSaturated) {
            const sectionUid = (_b = (_a = field.section) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : "";
            if (!res[sectionUid]) {
                res[sectionUid] = [];
            }
            res[sectionUid].push(field);
        }
        return res;
    }, [fieldsSaturated]);
    const visibleSections = React.useMemo(() => {
        return sections.filter((section) => viewAllEnabled ||
            !section.visibleValidation ||
            section.visibleValidation.result === undefined ||
            section.visibleValidation.result);
    }, [sections, viewAllEnabled]);
    const renderSubSections = React.useCallback((parentSectionUid, level) => {
        const level_ = level !== null && level !== void 0 ? level : 0;
        return [...visibleSections, { uid: "", name: "", parent_section: null }]
            .filter((section) => {
            var _a;
            return ((_a = fieldsBySectionUid[section.uid]) !== null && _a !== void 0 ? _a : []).length > 0 ||
                sections.some((s) => s.parent_section === section.uid);
        })
            .filter((section) => section.parent_section === parentSectionUid)
            .map((section) => {
            var _a;
            const visibleFieldsInSection = ((_a = fieldsBySectionUid[section.uid]) !== null && _a !== void 0 ? _a : []).filter((field) => field.isVisible &&
                (viewAllEnabled ||
                    !field.visibleValidation ||
                    field.visibleValidation.result ||
                    field.visibleValidation.result === undefined));
            const isIncomplete = visibleFieldsInSection.some((x) => !isObject(values[x.name]) &&
                (values[x.name] == null ||
                    String(values[x.name]).trim() === "" ||
                    values[x.name] === false ||
                    (Array.isArray(values[x.name]) && values[x.name].length === 0))) || visibleSections.some((s) => s.parent_section === section.uid);
            return (React.createElement(Grid2, { key: section.uid },
                React.createElement(DataEntrySection, { name: React.createElement(Typography, { variant: level_ === 0 ? "h6" : level_ === 1 ? "body1" : "body2", style: { fontWeight: "bold" } }, section.name ? section.name : sections.length > 0 ? "All other fields" : section.name), hideHeader: visibleSections.length === 0, isVisible: collapseCompletedSectionsByDefault ? isIncomplete : true, isCompleted: collapseCompletedSectionsByDefault ? !isIncomplete : undefined },
                    React.createElement(Grid2, { container: true, spacing: 1, direction: "column", wrap: "nowrap" },
                        React.createElement(Grid2, { container: true, spacing: 1 }, visibleFieldsInSection.map((field) => {
                            var _a;
                            const fieldIsEditable = field.isEditable && (!field.editableValidation || field.editableValidation.result);
                            const fieldValue = values[field.name];
                            return (React.createElement(StyledFieldWrapper, { key: field.uid, size: { xs: inputElementWidth }, style: {
                                    marginTop: fieldIsEditable ? "auto" : undefined,
                                    overflowX: "hidden",
                                } },
                                React.createElement(Grid2, { container: true, spacing: 0, wrap: "nowrap" },
                                    React.createElement(Grid2, { style: { flex: 1 } }, isEditable && fieldIsEditable && field.field_type !== "formula" ? (React.createElement(Grid2, { container: true, direction: "row", wrap: "nowrap", spacing: 1 },
                                        React.createElement(Grid2, { style: { flex: 1 } },
                                            React.createElement(CoreAutoSaveField, { apiEndpoint: apiUri, field: Object.assign({}, field), size: "small", variant: "standard", fullWidth: true, label: field.name, 
                                                // label={field.field_type === "checkbox" ? field.name : undefined}
                                                onSaveBegin: onFieldSaveBegin(field.name), onSuccess: props.onFieldSaveComplete, 
                                                // @ts-expect-error
                                                onError: props.onFieldSaveError, value: fieldValue !== undefined ? fieldValue : null, hasPicker: field.field_type === "date" ? true : undefined })),
                                        ((_a = field.warnValidation) === null || _a === void 0 ? void 0 : _a.result) && (React.createElement(Grid2, { style: { marginTop: "auto" } },
                                            React.createElement(Tooltip, { title: React.createElement(Typography, null, field.warnValidation.result) },
                                                React.createElement(Warning, { color: "warning" })))))) : (React.createElement(DataEntryNonEditableField, { field: field, value: fieldValue }))),
                                    (effectiveUser === null || effectiveUser === void 0 ? void 0 : effectiveUser.hasRole(["Superadmin"])) && (React.createElement(Grid2, { className: "field-actions", style: { marginTop: "auto" } },
                                        React.createElement(ActionsMenu, { IconButtonProps: { tabIndex: -1 }, variant: "iconbutton" },
                                            React.createElement(MenuItem, { component: RouterLink, to: `/field-management?field=${field.uid}` },
                                                React.createElement(ListItemIcon, null,
                                                    React.createElement(Edit, null)),
                                                "Edit field definition")))))));
                        })),
                        React.createElement(Grid2, null, renderSubSections(section.uid, level_ + 1))))));
        });
    }, [
        apiUri,
        collapseCompletedSectionsByDefault,
        fieldsBySectionUid,
        inputElementWidth,
        isEditable,
        onFieldSaveBegin,
        props.onFieldSaveComplete,
        props.onFieldSaveError,
        sections,
        effectiveUser,
        values,
        viewAllEnabled,
        visibleSections,
    ]);
    return (React.createElement(DataEntryContainer, { ref: ref, container: true, spacing: 2, style: { alignContent: "baseline", padding: "0.5rem", width: "100%" }, direction: "column", wrap: "nowrap" },
        (name || isCollapsible) && (React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true, spacing: 1 },
                    name && (React.createElement(Grid2, { style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(Typography, null, name))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    isCollapsible && (React.createElement(Grid2, null,
                        React.createElement(IconButton, { size: "small", onClick: collapse }, isOpen ? React.createElement(KeyboardArrowDown, { fontSize: "small" }) : React.createElement(KeyboardArrowRight, { fontSize: "small" })))))))),
        React.createElement(Collapse, { in: isOpen, style: { width: "100%" } }, renderSubSections(null))));
};
export default DataEntry;
