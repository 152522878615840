import * as React from "react";
import { RemoveCircleOutline } from "@mui/icons-material";
import { Chip, Grid, IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip, Typography, } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { convertUtcDateString } from "@app/common/date-utils";
import RouterLink from "@app/common/RouterLink";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import { getFullName } from "@app/util/Utils";
import StreetViewImage from "./StreetViewImage";
const PSPListItem = (props) => {
    var _a, _b, _c;
    const { onRemovePSP, psp } = props;
    const confirm = useConfirm();
    const removePSP = React.useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { description: "This will remove the PSP from the lead.", confirmationText: "Remove" })).then((result) => {
            if (result.confirmed) {
                onRemovePSP(psp.uid);
            }
        });
    }, [confirm, onRemovePSP, psp.uid]);
    return (React.createElement(ListItemButton, { component: RouterLink, to: `/matrix/${psp.uid}` },
        React.createElement(ListItemIcon, { style: { maxWidth: "50px", maxHeight: "50px", overflow: "hidden" } },
            React.createElement(StreetViewImage, { address: (_a = psp.property_address) !== null && _a !== void 0 ? _a : "", ImageProps: { style: { width: "32px", height: "32px", borderRadius: "2px" } } })),
        React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true, spacing: 4 },
                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "body2" }, ((_b = psp.property_address) === null || _b === void 0 ? void 0 : _b.trim()) || "(no property address)")),
                ((_c = psp.psp_request) === null || _c === void 0 ? void 0 : _c.claimed_by) && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Chip, { size: "small", label: `MA: ${getFullName(psp.psp_request.claimed_by.first_name, psp.psp_request.claimed_by.last_name)}` })))), secondary: React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                    React.createElement(Typography, { variant: "caption" }, psp.created_at
                        ? `Added ${convertUtcDateString(psp.created_at, { format: "dddd, MMMM D, YYYY h:mm A" })}`
                        : ""))) }),
        React.createElement(ListItemSecondaryAction, null,
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Remove PSP") },
                React.createElement("span", null,
                    React.createElement(IconButton, { size: "small", onClick: removePSP },
                        React.createElement(RemoveCircleOutline, { fontSize: "small" })))))));
};
export default PSPListItem;
