import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Close, Reorder, Save } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, TextField, Tooltip, Typography, } from "@mui/material";
import { Draggable, DragDropContext, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useLoading from "@app/hooks/useLoading";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useReorderMailschedule4Templates } from "@app/orval/api/mail-schedule-4-templates";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const pipelines = ["Buyer Contract", "Buyer Lead", "Seller Contract", "Seller Lead", "User", "Recruit"].sort();
const MailScheduleTemplateReorder = (props) => {
    var _a, _b;
    const { pipeline } = props;
    const updateSearch = useUpdateSearch();
    const queryClient = useQueryClient();
    const listTemplatesApi = useListMailschedule4Templates({ entity_type: pipeline }, Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: !!pipeline }) }));
    const templates = (_b = (_a = listTemplatesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const reorderTemplatesApi = useReorderMailschedule4Templates();
    const isSaving = reorderTemplatesApi.isPending;
    const save = React.useCallback(() => {
        const templateOrder = templates.map((obj) => obj.uid);
        reorderTemplatesApi
            .mutateAsync({ data: { templates: templateOrder } })
            .then(() => {
            enqueueSnackbar("Templates have been reordered", { variant: "success" });
        })
            .catch(enqueueErrorSnackbar);
    }, [reorderTemplatesApi, templates]);
    const onPipelineChange = React.useCallback((e) => {
        updateSearch("pipeline", e.target.value || null);
    }, [updateSearch]);
    const onDrop = React.useCallback((result) => {
        if (result.destination && result.source.index !== result.destination.index) {
            queryClient.setQueryData(listTemplatesApi.queryKey, (old) => {
                if (!old)
                    return old;
                const newTemplates = [...old.data];
                const template = newTemplates[result.source.index];
                newTemplates.splice(result.source.index, 1);
                newTemplates.splice(result.destination.index, 0, template);
                return Object.assign(Object.assign({}, old), { data: newTemplates });
            });
        }
    }, [listTemplatesApi.queryKey, queryClient]);
    const { isLoading, loadingComponent } = useLoading({
        items: [
            {
                label: "Loading templates",
                queryResult: listTemplatesApi,
            },
        ],
    });
    if (isLoading) {
        return loadingComponent;
    }
    if (!templates) {
        return null; // should never happen
    }
    return (React.createElement(Grid, { container: true, spacing: 1, style: { height: "100%", width: "100%" } },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { p: 2 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                        React.createElement(TextField, { variant: "standard", label: "Pipeline", value: pipeline !== null && pipeline !== void 0 ? pipeline : "", onChange: onPipelineChange, style: { width: "200px" } },
                            React.createElement(MenuItem, { key: -1, value: "" }),
                            pipelines.map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } }),
                    React.createElement(Grid, { item: true, style: {
                            marginTop: "auto",
                            marginBottom: "auto",
                        } },
                        React.createElement(Tooltip, { title: "Save" },
                            React.createElement("span", null,
                                React.createElement(IconButton, { onClick: save, disabled: isSaving },
                                    React.createElement(Save, null)))),
                        React.createElement(Tooltip, { title: "Close" },
                            React.createElement(IconButton, { component: RouterLink, to: `/ms4/templates${pipeline ? `?pipeline=${pipeline}` : ""}` },
                                React.createElement(Close, null))))))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { item: true, xs: 12, style: { height: "calc(100% - 70px)", overflowY: "hidden", position: "relative" } },
            React.createElement(Box, { p: 2, style: { height: "100%" } },
                React.createElement(Typography, { variant: "body2" },
                    "Drag & drop the templates below to update the order in which they should appear. Press the",
                    " ",
                    React.createElement(Save, { fontSize: "small" }),
                    " button above when done. Re-ordering the templates has no effect on when they are sent; it is only a visual aid. Templates are only processed based on their triggers and/or criteria."),
                React.createElement(DragDropContext, { onDragEnd: onDrop },
                    React.createElement(Droppable, { droppableId: "reorder-list" }, (droppableProvided) => (React.createElement(React.Fragment, null,
                        React.createElement(List, Object.assign({}, droppableProvided.droppableProps, { ref: droppableProvided.innerRef, style: {
                                maxHeight: "calc(100% - 30px)",
                                overflowY: "auto",
                            }, dense: true }), templates.map((obj, indx) => (React.createElement(Draggable, { key: obj.uid, draggableId: String(obj.uid), index: indx }, (draggableProvided) => (React.createElement(ListItem, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps, { key: obj.uid }),
                            React.createElement(ListItemIcon, Object.assign({ className: "drag-handle" }, draggableProvided.dragHandleProps, { style: {
                                    cursor: "grab",
                                } }),
                                React.createElement(Reorder, { fontSize: "small" })),
                            React.createElement(ListItemText, { primary: indx + 1, secondary: obj.name }))))))),
                        droppableProvided.placeholder))))))));
};
export default MailScheduleTemplateReorder;
