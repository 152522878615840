import * as React from "react";
import { Delete, LabelImportant } from "@mui/icons-material";
import { Button, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography, } from "@mui/material";
import CoreChoiceField from "@app/common/CoreChoiceField";
import FormulaField from "@app/entrypoints/brokerage/features/developer/fields/components/FormulaField";
import { getAutocompleteSuggestionsForField } from "@app/entrypoints/brokerage/features/developer/sections/utils";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListStages } from "@app/orval/api/stages";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const DataValidationRuleEdit = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { onClose, onDelete, onSave, rule } = props;
    const [formData, setFormData] = React.useState(rule);
    const setStage = React.useCallback((choices) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { stage_validation_rule: Object.assign(Object.assign({}, prev.stage_validation_rule), { stage_uids: choices }) })));
    }, []);
    const setAction = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { stage_validation_rule: Object.assign(Object.assign({}, prev.stage_validation_rule), { action: e.target.value }) })));
    }, []);
    const setFormula = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { stage_validation_rule: Object.assign(Object.assign({}, prev.stage_validation_rule), { formula: value }) })));
    }, []);
    const setIsEnabled = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { is_enabled: checked })));
    }, []);
    const handleSave = React.useCallback(() => {
        onSave(formData);
    }, [formData, onSave]);
    const listFieldsApi = useListFields({ entity_type: rule.entity_type }, INFINITE_CACHE_PARAMS);
    const fields = (_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    // @ts-expect-error
    const listStagesApi = useListStages({ type: rule.entity_type }, INFINITE_CACHE_PARAMS);
    const stages = (_d = (_c = listStagesApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const autocompleteSuggestions = React.useMemo(() => {
        return fields && rule.entity_type
            ? getAutocompleteSuggestionsForField(rule.entity_type, fields, stages !== null && stages !== void 0 ? stages : undefined)
            : null;
    }, [fields, rule.entity_type, stages]);
    const autocompleteSuggestionsForEntityType = React.useMemo(() => {
        return [];
    }, []);
    const autocompleteSuggestionsWithValueAndUser = React.useMemo(() => {
        return autocompleteSuggestions
            ? [
                { label: "$VALUE", type: "any", documentation: "The value of this field" },
                {
                    label: "$USER",
                    type: "Map",
                    documentation: "The user doing the update. Use `$USER.roles` to validate against the array of user roles.",
                },
                ...autocompleteSuggestionsForEntityType,
                ...autocompleteSuggestions,
            ]
            : null;
    }, [autocompleteSuggestions, autocompleteSuggestionsForEntityType]);
    return (React.createElement(Grid, { container: true, spacing: 1, direction: "column" },
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, spacing: 1, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(CoreChoiceField, { size: "small", multiple: true, InputProps: { startAdornment: React.createElement(InputAdornment, { position: "start" }) }, value: (_f = (_e = formData.stage_validation_rule) === null || _e === void 0 ? void 0 : _e.stage_uids) !== null && _f !== void 0 ? _f : [], onChoicesChange: setStage, fullWidth: true, select: true, SelectProps: { displayEmpty: true }, options: (stages !== null && stages !== void 0 ? stages : []).reduce((acc, stage) => {
                            return Object.assign(Object.assign({}, acc), { [stage.uid]: stage.name });
                        }) },
                        React.createElement(MenuItem, { value: "" }, "Select a stage"), stages === null || stages === void 0 ? void 0 :
                        stages.map((stage) => (React.createElement(MenuItem, { key: stage.uid, value: stage.uid },
                            React.createElement(Grid, { container: true, spacing: 1 },
                                React.createElement(Grid, { item: true },
                                    React.createElement(LabelImportant, { fontSize: "small", style: { color: stage.color } })),
                                React.createElement(Grid, { item: true, style: { flex: 1 } },
                                    React.createElement(Typography, { variant: "body2" }, stage.name)))))))),
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { size: "small", InputProps: { startAdornment: React.createElement(InputAdornment, { position: "start" }) }, value: (_h = (_g = formData.stage_validation_rule) === null || _g === void 0 ? void 0 : _g.action) !== null && _h !== void 0 ? _h : "", onChange: setAction, fullWidth: true, select: true },
                        React.createElement(MenuItem, { value: "Disable" }, "Disable"),
                        React.createElement(MenuItem, { value: "Warn" }, "Warn"),
                        React.createElement(MenuItem, { value: "Reject" }, "Reject"))),
                React.createElement(Grid, { item: true },
                    React.createElement(FormulaField, { formula: (_k = (_j = formData.stage_validation_rule) === null || _j === void 0 ? void 0 : _j.formula) !== null && _k !== void 0 ? _k : "", onValueChange: setFormula, autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser !== null && autocompleteSuggestionsWithValueAndUser !== void 0 ? autocompleteSuggestionsWithValueAndUser : [] })))),
        React.createElement(Grid, { item: true },
            React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { onClick: handleSave, size: "small", variant: "text" }, "Update")),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { onClick: onClose, size: "small", variant: "text" }, "Close")),
                React.createElement(Grid, { item: true, style: { flex: 1 } }),
                React.createElement(Grid, { item: true },
                    React.createElement(FormControlLabel, { label: "Enabled", control: React.createElement(Switch, { checked: formData.is_enabled, onChange: setIsEnabled, size: "small", color: "primary" }) })),
                React.createElement(Grid, { item: true, style: { flex: 1 } }),
                React.createElement(Grid, { item: true },
                    React.createElement(IconButton, { size: "small", onClick: onDelete },
                        React.createElement(Delete, { fontSize: "small" })))))));
};
export default DataValidationRuleEdit;
