import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack, Check, Error, Refresh } from "@mui/icons-material";
import { Chip, Grid, IconButton, InputAdornment, Link, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import styled from "styled-components";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreTextField from "@app/common/CoreTextField";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { withDialog } from "@app/hoc/withDialog";
import { withFilter } from "@app/hoc/withFilter";
import API from "@app/util/api";
import { getStreakLinkForBox, getStreakLinkForPipeline } from "@app/util/Utils";
const StyledWrapper = styled("div") `
    & .MuiFab-root {
        display: none;
    }
    &:hover .MuiFab-root {
        display: inline-flex;
    }
`;
class MailScheduleLogsTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                logs: null,
                execution: null,
                templates: null,
                pipelines: null,
                query: {},
                error: null,
                count: null,
                page: null,
                rowsPerPage: null,
            }
        });
        Object.defineProperty(this, "pipelinesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "templatesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "logsAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "executionAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "getTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    templates: null,
                }, () => {
                    var _a;
                    (_a = this.templatesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.templatesAbortController = new AbortController();
                    API.mailScheduleTemplates.list({
                        onSuccess: (result) => {
                            this.setState({
                                templates: result.reduce((dict, template) => (Object.assign(Object.assign({}, dict), { [template.uid]: template })), {}),
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.templatesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "getPipelines", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    pipelines: null,
                }, () => {
                    var _a;
                    (_a = this.pipelinesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.pipelinesAbortController = new AbortController();
                    API.streakPipelines.list({
                        onSuccess: (result) => {
                            this.setState({
                                pipelines: result.reduce((dict, pipeline) => (Object.assign(Object.assign({}, dict), { [pipeline.key]: pipeline })), {}),
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.pipelinesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "getLogs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    logs: null,
                    error: null,
                }, () => {
                    var _a;
                    const { query } = this.state;
                    (_a = this.logsAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.logsAbortController = new AbortController();
                    API.mailScheduleScriptExecutions.getLogs(this.props.executionUid, {
                        query: pickBy(query, (val) => val != null),
                        onSuccess: (result) => {
                            this.setState({
                                logs: result.results,
                                rowsPerPage: result.limit,
                                page: Math.floor(result.offset / result.limit),
                                count: result.total,
                                error: null,
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.logsAbortController.signal,
                        },
                    });
                });
            }
        });
        Object.defineProperty(this, "getExecution", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    execution: null,
                }, () => {
                    var _a;
                    (_a = this.executionAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.executionAbortController = new AbortController();
                    API.mailScheduleScriptExecutions.getExecution(this.props.executionUid, {
                        onSuccess: (result) => {
                            this.setState({
                                execution: result,
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                console.error(error);
                            }
                        },
                        options: {
                            signal: this.executionAbortController.signal,
                        },
                    });
                });
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.getLogs();
                this.getExecution();
            }
        });
        Object.defineProperty(this, "setStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                this.props.updateFilter("status", e.target.value || null, "offset", 0);
            }
        });
        Object.defineProperty(this, "setSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.props.updateFilter("search", value.trim() || null, "offset", 0);
            }
        });
        Object.defineProperty(this, "goBack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.navigate("/mailschedule/logs");
            }
        });
        Object.defineProperty(this, "pipelineSortCompare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (order) => (obj1, obj2) => {
                var _a, _b;
                const { pipelines } = this.state;
                const pipelineName1 = (_a = pipelines === null || pipelines === void 0 ? void 0 : pipelines[obj1.data].name) !== null && _a !== void 0 ? _a : "";
                const pipelineName2 = (_b = pipelines === null || pipelines === void 0 ? void 0 : pipelines[obj2.data].name) !== null && _b !== void 0 ? _b : "";
                let val = 0;
                if (pipelineName1 < pipelineName2)
                    val = -1;
                if (pipelineName1 > pipelineName2)
                    val = 1;
                return val * (order === "asc" ? 1 : -1);
            }
        });
        Object.defineProperty(this, "templateSortCompare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (order) => (obj1, obj2) => {
                var _a, _b;
                const { templates } = this.state;
                const template1 = (_a = templates === null || templates === void 0 ? void 0 : templates[obj1.data].name) !== null && _a !== void 0 ? _a : "";
                const template2 = (_b = templates === null || templates === void 0 ? void 0 : templates[obj2.data].name) !== null && _b !== void 0 ? _b : "";
                let val = 0;
                if (template1 < template2)
                    val = -1;
                if (template1 > template2)
                    val = 1;
                return val * (order === "asc" ? 1 : -1);
            }
        });
        Object.defineProperty(this, "boxSortCompare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (order) => (obj1, obj2) => {
                var _a, _b;
                const { logs } = this.state;
                const log1 = logs === null || logs === void 0 ? void 0 : logs.find((log) => log.box_key === obj1.data);
                const log2 = logs === null || logs === void 0 ? void 0 : logs.find((log) => log.box_key === obj2.data);
                const boxName1 = (_a = log1 === null || log1 === void 0 ? void 0 : log1.box_name) !== null && _a !== void 0 ? _a : "";
                const boxName2 = (_b = log2 === null || log2 === void 0 ? void 0 : log2.box_name) !== null && _b !== void 0 ? _b : "";
                let val = 0;
                if (boxName1 < boxName2)
                    val = -1;
                if (boxName1 > boxName2)
                    val = 1;
                return val * (order === "asc" ? 1 : -1);
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a, _b;
        const queryParams = new URLSearchParams(nextProps.location.search);
        const query = {
            status: ((_a = queryParams.get("status")) !== null && _a !== void 0 ? _a : undefined),
            search: (_b = queryParams.get("search")) !== null && _b !== void 0 ? _b : undefined,
            limit: queryParams.get("limit") ? numeral(queryParams.get("limit")).value() : 100,
            offset: queryParams.get("offset") ? numeral(queryParams.get("offset")).value() : 0,
        };
        return {
            query,
        };
    }
    componentDidMount() {
        this.getPipelines();
        this.getTemplates();
        this.getExecution();
        this.getLogs();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.executionUid !== this.props.executionUid ||
            JSON.stringify(prevState.query) !== JSON.stringify(this.state.query)) {
            this.refresh();
        }
    }
    componentWillUnmount() {
        var _a, _b, _c, _d;
        (_a = this.pipelinesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
        (_b = this.templatesAbortController) === null || _b === void 0 ? void 0 : _b.abort();
        (_c = this.executionAbortController) === null || _c === void 0 ? void 0 : _c.abort();
        (_d = this.logsAbortController) === null || _d === void 0 ? void 0 : _d.abort();
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const { count, error, execution, logs, page, pipelines, query, rowsPerPage, templates } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        const rows = (logs !== null && logs !== void 0 ? logs : []).map((e) => (Object.assign(Object.assign({}, e), { template: e.template_uid && templates && templates[e.template_uid] ? templates[e.template_uid].name : e.template_uid, pipeline: e.pipeline_key && pipelines && pipelines[e.pipeline_key] ? pipelines[e.pipeline_key].name : e.pipeline_key })));
        const columns = [
            {
                name: "template_uid",
                label: "Template",
                options: {
                    customBodyRender: (value) => {
                        var _a;
                        const template = templates === null || templates === void 0 ? void 0 : templates[value];
                        if (value) {
                            return React.createElement(RouterLink, { to: `/mailschedule/templates/${value}` }, (_a = template === null || template === void 0 ? void 0 : template.name) !== null && _a !== void 0 ? _a : value);
                        }
                        return value;
                    },
                    sortCompare: this.templateSortCompare,
                },
            },
            {
                name: "pipeline_key",
                label: "Pipeline",
                options: {
                    customBodyRender: (value) => {
                        var _a;
                        const pipeline = pipelines === null || pipelines === void 0 ? void 0 : pipelines[value];
                        if (value) {
                            return (React.createElement(Link, { href: getStreakLinkForPipeline(value), target: "_blank" }, (_a = pipeline === null || pipeline === void 0 ? void 0 : pipeline.name) !== null && _a !== void 0 ? _a : value));
                        }
                        return value;
                    },
                    sortCompare: this.pipelineSortCompare,
                },
            },
            {
                name: "box_key",
                label: "Box",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const log = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        const boxName = log.box_name;
                        if (value) {
                            return (React.createElement(Link, { href: getStreakLinkForBox(value), target: "_blank" }, boxName !== null && boxName !== void 0 ? boxName : value));
                        }
                        return value;
                    },
                    sortCompare: this.boxSortCompare,
                },
            },
            {
                name: "start_timestamp",
                label: "Start",
                options: {
                    customBodyRender: (value) => {
                        if (value && dayjs_(value).isValid()) {
                            return dayjs_(value).format("dddd, MMMM D, YYYY h:mm:ss A");
                        }
                        return value;
                    },
                },
            },
            {
                name: "end_timestamp",
                label: "End",
                options: {
                    customBodyRender: (value) => {
                        if (value && dayjs_(value).isValid()) {
                            return dayjs_(value).format("dddd, MMMM D, YYYY h:mm:ss A");
                        }
                        return value;
                    },
                },
            },
            {
                name: "error_message",
                label: "Status",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        let status;
                        let icon;
                        if (value) {
                            status = "Errored Out";
                            icon = React.createElement(Error, { style: { color: "red" } });
                        }
                        else {
                            const log = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                            if (!log.end_timestamp &&
                                dayjs_(new Date(`${log.start_timestamp}+00:00`)).isAfter(dayjs_().add(-1, "hour"))) {
                                status = "Running";
                                icon = React.createElement(CoreLoading, { size: 16 });
                            }
                            else {
                                status = "Finished";
                                icon = React.createElement(Check, { style: { color: "green" } });
                            }
                        }
                        return (React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true }, value ? React.createElement(Tooltip, { title: React.createElement(Typography, { variant: "body2" }, value) }, icon) : icon),
                            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, status)));
                    },
                    setCellProps: () => ({
                        style: {
                            width: "100px",
                            minWidth: "100px",
                        },
                    }),
                },
            },
        ];
        const options = {
            responsive: "standard",
            selectableRows: "none",
            download: false,
            print: false,
            filter: false,
            pagination: true,
            sort: (count !== null && count !== void 0 ? count : 0) <= (rowsPerPage !== null && rowsPerPage !== void 0 ? rowsPerPage : 0),
            viewColumns: false,
            search: false,
            serverSide: (count !== null && count !== void 0 ? count : 0) > (rowsPerPage !== null && rowsPerPage !== void 0 ? rowsPerPage : 0),
            count: logs ? count : undefined,
            page: logs ? page : undefined,
            rowsPerPage: logs ? rowsPerPage : undefined,
            rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
            onChangePage: (currentPage) => {
                this.props.updateFilter("offset", rowsPerPage * currentPage);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                this.props.updateFilter("limit", numberOfRows);
            },
            customToolbar: execution
                ? () => {
                    var _a, _b;
                    return (React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { flex: 1 } }),
                        React.createElement(Grid, { item: true },
                            React.createElement(Tooltip, { title: "Refresh" },
                                React.createElement(IconButton, { onClick: this.refresh },
                                    React.createElement(Refresh, null)))),
                        React.createElement(Grid, { item: true },
                            React.createElement(CoreTextField
                            /* @ts-expect-error */
                            , { 
                                /* @ts-expect-error */
                                variant: "outlined", size: "small", style: { width: "200px" }, InputProps: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Search"),
                                }, value: (_a = query.search) !== null && _a !== void 0 ? _a : "", onTextChange: this.setSearch })),
                        React.createElement(Grid, { item: true },
                            React.createElement(TextField, { variant: "outlined", size: "small", style: { width: "200px" }, select: true, InputProps: {
                                    startAdornment: React.createElement(InputAdornment, { position: "start" }, "Status"),
                                }, SelectProps: {
                                    displayEmpty: true,
                                }, value: (_b = query.status) !== null && _b !== void 0 ? _b : "", onChange: this.setStatus },
                                React.createElement(MenuItem, { value: "" }, "Any"),
                                React.createElement(MenuItem, { value: "Success" }, "Success"),
                                React.createElement(MenuItem, { value: "Error" }, "Error")))));
                }
                : undefined,
        };
        return (React.createElement(StyledWrapper, { style: { height: "100%" } }, logs ? (React.createElement(CoreDataTable, { title: React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(IconButton, { size: "small", onClick: this.goBack },
                        React.createElement(ArrowBack, { fontSize: "small" }))),
                execution && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true },
                        React.createElement(Chip, { label: `Agent: ${(_a = execution.agent_email) !== null && _a !== void 0 ? _a : "All"}`, size: "small" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Chip, { label: `Box: ${(_b = execution.box_key) !== null && _b !== void 0 ? _b : "All"}`, size: "small" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Chip, { label: `Pipeline: ${(_f = (_e = (_d = pipelines === null || pipelines === void 0 ? void 0 : pipelines[(_c = execution.pipeline_key) !== null && _c !== void 0 ? _c : ""]) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : execution.pipeline_key) !== null && _f !== void 0 ? _f : "All"}`, size: "small" })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Chip, { label: `Template: ${(_k = (_j = (_h = templates === null || templates === void 0 ? void 0 : templates[(_g = execution.template_uid) !== null && _g !== void 0 ? _g : ""]) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : execution.template_uid) !== null && _k !== void 0 ? _k : "All"}`, size: "small" }))))), data: rows, columns: columns, options: options, cellPadding: 2 })) : (React.createElement(CoreLoading, null))));
    }
}
export default withDialog(withFilter(MailScheduleLogsTable));
