import * as React from "react";
import { MoreVert } from "@mui/icons-material";
import { Menu, IconButton } from "@mui/material";
import { getRootElement, isChromeExtension } from "@app/util/Utils";
const CoreMoreMenu = (props) => {
    const { anchorIcon, children, iconButtonProps, menuProps, size } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = React.useCallback((event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, Object.assign({ onClick: handleClick, size: size !== null && size !== void 0 ? size : "small" }, iconButtonProps), anchorIcon !== null && anchorIcon !== void 0 ? anchorIcon : React.createElement(MoreVert, { fontSize: size !== null && size !== void 0 ? size : "small" })),
        React.createElement(Menu, Object.assign({ open: !!anchorEl, anchorEl: anchorEl, onClick: handleClose, onClose: handleClose, container: isChromeExtension ? getRootElement() : undefined }, menuProps), children)));
};
export default CoreMoreMenu;
