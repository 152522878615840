import * as React from "react";
import { Delete, Refresh } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, MenuItem, Typography, } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import { convertUtcDateString } from "@app/common/date-utils";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { useDeleteSlackAuthorization, useListSlackAuthorizations } from "@app/orval/api/slack-authorizations";
import AppConfig from "@app/util/AppConfig";
import Session from "@app/util/Session";
const SlackAuthorizationTable = (props) => {
    const { authorizations, error, onRefresh, onRemoveAuthorization } = props;
    const removeAuthorization = (uid) => () => {
        onRemoveAuthorization(uid);
    };
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (!authorizations) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Button, { variant: "contained", color: "inherit", onClick: onRefresh, startIcon: React.createElement(Refresh, { fontSize: "small" }) }, "Refresh")),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(List, { dense: true }, authorizations.map((authorization) => {
                var _a;
                return (React.createElement(ListItem, { key: authorization.uid },
                    React.createElement(ListItemAvatar, null,
                        React.createElement(Avatar, { src: (_a = authorization.created_by.icon_url) !== null && _a !== void 0 ? _a : undefined }, [authorization.created_by.first_name, authorization.created_by.last_name]
                            .filter((obj) => obj)
                            .map((obj) => obj.substring(0, 1))
                            .join(""))),
                    React.createElement(ListItemText, { primary: [authorization.created_by.first_name, authorization.created_by.last_name]
                            .filter((obj) => obj)
                            .join(" "), secondary: `Created ${convertUtcDateString(new Date(authorization.created), { format: "M/D/YYYY h:mm A" })}` }),
                    React.createElement(ListItemSecondaryAction, null,
                        React.createElement(IconButton, { onClick: removeAuthorization(authorization.uid) },
                            React.createElement(Delete, null)))));
            })))));
};
const SlackAuthorization = () => {
    var _a, _b, _c;
    const authorizationsListApi = useListSlackAuthorizations();
    const authorizationsDeleteApi = useDeleteSlackAuthorization();
    const [scopes, setScopes] = React.useState(AppConfig.slackScopes);
    const removeAuthorization = React.useCallback((uid) => {
        authorizationsListApi.data.data = authorizationsListApi.data.data.filter((x) => x.uid !== uid);
        authorizationsDeleteApi
            .mutateAsync({ uid })
            .then(() => enqueueSnackbar("Deleted authorization", { variant: "success" }))
            .catch(enqueueErrorSnackbar);
    }, [authorizationsDeleteApi, authorizationsListApi.data]);
    const onScopesChanged = React.useCallback((choices) => {
        setScopes(choices);
    }, [setScopes]);
    const refresh = React.useCallback(() => {
        authorizationsListApi.data = undefined;
        authorizationsListApi.refetch();
    }, []);
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, "Select the desired scopes and press the button below to authorize our Slack integration, Dashbot, to read and write messages in your workspace. All scopes are required to use Dashbot's full capabilities.")),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(CoreChoiceField, { variant: "standard", fullWidth: true, multiple: true, value: scopes, onChoicesChange: onScopesChanged }, AppConfig.slackScopes.map((scope) => (React.createElement(MenuItem, { key: scope, value: scope }, scope))))),
                React.createElement(Grid, { item: true, xs: 12, style: { textAlign: "center" } },
                    React.createElement(Link, { href: `/slack/authentication?scopes=${scopes.join(",")}`, target: "_blank" },
                        React.createElement("img", { alt: "Add to Slack", height: "40", width: "139", src: "https://platform.slack-edge.com/img/add_to_slack.png", srcSet: "https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" })))),
            ((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.isInternal) && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Divider, null)),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(SlackAuthorizationTable, { error: authorizationsListApi.error, authorizations: (_c = (_b = authorizationsListApi.data) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : null, onRefresh: refresh, onRemoveAuthorization: removeAuthorization })))))));
};
export default SlackAuthorization;
