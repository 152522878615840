var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid2, InputAdornment, MenuItem, TextField, Typography, } from "@mui/material";
import { htmlToText } from "html-to-text";
import pickBy from "lodash/pickBy";
import { v4 as uuidv4 } from "uuid";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import { MailMergeContext } from "@app/entrypoints/brokerage/context/mail-merge/MailMergeContext";
import useDownloadMultipleCloudStorageFiles from "@app/hooks/api/useDownloadMultipleCloudStorageFiles";
import useSession from "@app/hooks/useSession";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListMailschedule4Boilerplate } from "@app/orval/api/mail-schedule-4-boilerplate";
import { parseMailschedule4Template } from "@app/orval/api/mail-schedule-parsing";
import { uploadCustomTemplateAttachment } from "@app/orval/api/mail-schedule-run";
import { useListMailschedule4Templates } from "@app/orval/api/mail-schedule-templates";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { base64ToFile, fileToBase64 } from "@app/util/Utils";
import EmailAttachments from "./EmailAttachments";
import usePostQuery from "../../../dashboards/agent-dashboard/usePostQuery";
import { MailScheduleTemplateHelperText } from "../../../mailschedule4/templates/MailScheduleTemplateHelperText";
import { getAgentMentionsFields, getClientMentionsFields, getTextFieldsInPipeline, getVendorMentionsFields, } from "../../../mailschedule4/utils/form";
import MailScheduleQuill from "../../../mailschedule4/utils/MailScheduleQuill";
import MentionsInput from "../../../mailschedule4/utils/MentionsInput";
const MailMergeDialog = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const { defaultTemplate, entityType, entityUids, onClose } = props;
    const mailMergeContext = React.useContext(MailMergeContext);
    const session = useSession();
    const [selectedTemplateUid, setSelectedTemplateUid] = React.useState(defaultTemplate !== null && defaultTemplate !== void 0 ? defaultTemplate : null);
    const [formData, setFormData] = React.useState({
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        body: "",
        attachments: [],
    });
    const [isRunningMailMerge, setIsRunningMailMerge] = React.useState(false);
    const listFieldsApi = useListFields({}, INFINITE_CACHE_PARAMS);
    const fields = (_b = (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const fieldsForEntityType = React.useMemo(() => {
        if (!fields || !entityType) {
            return [];
        }
        return fields.filter((x) => {
            if (!entityType) {
                return false;
            }
            if (x.entity_type === entityType) {
                return true;
            }
            if (x.entity_type === "Buyer Lead" && entityType === "Buyer Contract") {
                return true;
            }
            if (x.entity_type === "Seller Lead" &&
                (entityType === "Listing" ||
                    // @ts-expect-error
                    entityType === "Seller Contract")) {
                return true;
            }
            return false;
        });
    }, [entityType, fields]);
    const textFields = React.useMemo(() => {
        return getTextFieldsInPipeline(fieldsForEntityType);
    }, [fieldsForEntityType]);
    const agentMentionsFields = React.useMemo(() => {
        return entityType ? getAgentMentionsFields(entityType) : [];
    }, [entityType]);
    const vendorMentionsFields = React.useMemo(() => {
        return entityType ? getVendorMentionsFields(entityType) : [];
    }, [entityType]);
    const clientMentionsFields = React.useMemo(() => {
        return entityType ? getClientMentionsFields(entityType) : [];
    }, [entityType]);
    const mailScheduleQuillFields = React.useMemo(() => {
        return [
            ...fieldsForEntityType.map((field) => ({
                id: field.uid,
                value: field.name,
            })),
            ...clientMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
            ...agentMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
            ...vendorMentionsFields.map((field) => ({
                id: field.id,
                value: field.display,
            })),
        ];
    }, [agentMentionsFields, clientMentionsFields, fieldsForEntityType, vendorMentionsFields]);
    const listBoilerplateApi = useListMailschedule4Boilerplate(REFETCH_CACHE_PARAMS);
    const boilerplate = (_d = (_c = listBoilerplateApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const boilerplateForEntityType = React.useMemo(() => {
        if (!boilerplate || !entityType) {
            return [];
        }
        return boilerplate.filter((b) => b.entity_type === entityType);
    }, [boilerplate, entityType]);
    const listTemplatesApi = useListMailschedule4Templates(pickBy({ entity_type: entityType }, (val) => val != null), Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS), { query: Object.assign(Object.assign({}, INFINITE_CACHE_PARAMS.query), { enabled: !!entityType }) }));
    const templates = entityType ? ((_f = (_e = listTemplatesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null) : null;
    const emailTemplates = templates === null || templates === void 0 ? void 0 : templates.filter((x) => x.is_active).filter((x) => x.template_type === "Email" || x.template_type === "Email Draft");
    // .map((x) => sanitize(x, fields ?? []));
    const selectedTemplate = React.useMemo(() => {
        return emailTemplates === null || emailTemplates === void 0 ? void 0 : emailTemplates.find((x) => x.uid === selectedTemplateUid);
    }, [emailTemplates, selectedTemplateUid]);
    const templateAttachments = React.useMemo(() => {
        var _a;
        return ((_a = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.gcp_attachments.map((x) => {
            var _a;
            return (Object.assign(Object.assign({}, x), { bucket_name: (_a = x.bucket_name) !== null && _a !== void 0 ? _a : "dash-realty-files" }));
        })) !== null && _a !== void 0 ? _a : []);
    }, [selectedTemplate]);
    const { downloadedFiles, downloadingFiles } = useDownloadMultipleCloudStorageFiles(templateAttachments);
    const downloadedFilesWithUids = React.useMemo(() => {
        return downloadedFiles.map((x) => ({
            uid: uuidv4(),
            file: base64ToFile(x.file, x.filename, x.mime_type),
        }));
    }, [downloadedFiles]);
    React.useEffect(() => {
        if (downloadedFilesWithUids.length > 0 && downloadingFiles.length === 0) {
            setFormData((prev) => (Object.assign(Object.assign({}, prev), { attachments: [...prev.attachments, ...downloadedFilesWithUids] })));
        }
    }, [downloadedFiles, downloadedFilesWithUids, downloadingFiles.length]);
    // We only parse the selected template right now if only a single
    // box was selected. When a single box is selected for the mail merge,
    // it's useful to display the parsed fields (client name, email, whatever)
    // rather than the merge fields. If multiple boxes are selected, we
    // can't really do this in the UI, so the templates will be parsed when
    // the emails are drafted or sent.
    const parseTemplateApi = usePostQuery(parseMailschedule4Template, {
        entity: {
            entity_type: entityType,
            uid: entityUids[0],
        },
        template: Object.assign(Object.assign({}, selectedTemplate), { email_draft_template: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.email_draft_template)
                ? Object.assign(Object.assign({}, selectedTemplate.email_draft_template), { sender: (_h = (_g = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.email) !== null && _h !== void 0 ? _h : undefined, allow_unsubscribe: (_j = selectedTemplate.email_draft_template.allow_unsubscribe) !== null && _j !== void 0 ? _j : false }) : undefined, email_template: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.email_template)
                ? Object.assign(Object.assign({}, selectedTemplate.email_template), { sender: (_l = (_k = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _k === void 0 ? void 0 : _k.email) !== null && _l !== void 0 ? _l : undefined, allow_unsubscribe: (_m = selectedTemplate.email_template.allow_unsubscribe) !== null && _m !== void 0 ? _m : false }) : undefined, criteria: [], resend_frequency: 1, resend_unit: "day", resend_maximum: 1 }),
    }, undefined, !!selectedTemplate && !!entityType && entityUids.length === 1);
    const parsedTemplate = entityUids.length === 1 ? ((_p = (_o = parseTemplateApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null) : null;
    React.useEffect(() => {
        const t = parsedTemplate !== null && parsedTemplate !== void 0 ? parsedTemplate : selectedTemplate;
        setFormData((prev) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
            return (Object.assign(Object.assign({}, prev), { to: (_d = (_b = (_a = t === null || t === void 0 ? void 0 : t.email_template) === null || _a === void 0 ? void 0 : _a.to) !== null && _b !== void 0 ? _b : (_c = t === null || t === void 0 ? void 0 : t.email_draft_template) === null || _c === void 0 ? void 0 : _c.to) !== null && _d !== void 0 ? _d : "", cc: (_h = (_f = (_e = t === null || t === void 0 ? void 0 : t.email_template) === null || _e === void 0 ? void 0 : _e.cc) !== null && _f !== void 0 ? _f : (_g = t === null || t === void 0 ? void 0 : t.email_draft_template) === null || _g === void 0 ? void 0 : _g.cc) !== null && _h !== void 0 ? _h : "", bcc: (_m = (_k = (_j = t === null || t === void 0 ? void 0 : t.email_template) === null || _j === void 0 ? void 0 : _j.bcc) !== null && _k !== void 0 ? _k : (_l = t === null || t === void 0 ? void 0 : t.email_draft_template) === null || _l === void 0 ? void 0 : _l.bcc) !== null && _m !== void 0 ? _m : "", subject: (_r = (_p = (_o = t === null || t === void 0 ? void 0 : t.email_template) === null || _o === void 0 ? void 0 : _o.subject) !== null && _p !== void 0 ? _p : (_q = t === null || t === void 0 ? void 0 : t.email_draft_template) === null || _q === void 0 ? void 0 : _q.subject) !== null && _r !== void 0 ? _r : "", body: (_v = (_t = (_s = t === null || t === void 0 ? void 0 : t.email_template) === null || _s === void 0 ? void 0 : _s.body) !== null && _t !== void 0 ? _t : (_u = t === null || t === void 0 ? void 0 : t.email_draft_template) === null || _u === void 0 ? void 0 : _u.body) !== null && _v !== void 0 ? _v : "" }));
        });
    }, [selectedTemplate, entityUids.length, parsedTemplate]);
    const handleSelectedTemplateChanged = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { attachments: prev.attachments.filter((x) => !downloadedFilesWithUids.some((y) => y.uid === x.uid)) })));
        setSelectedTemplateUid(e.target.value);
    }, [downloadedFilesWithUids]);
    const setTo = React.useCallback((_, val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { to: val })));
    }, []);
    const setCc = React.useCallback((_, val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { cc: val })));
    }, []);
    const setBcc = React.useCallback((_, val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { bcc: val })));
    }, []);
    const setSubject = React.useCallback((_, val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { subject: val })));
    }, []);
    const setBody = React.useCallback((val) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { body: val })));
    }, []);
    const setAttachments = React.useCallback((val) => {
        console.log(val);
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { attachments: val })));
    }, []);
    const checkWildcards = React.useCallback((value, checkEmailAddresses = false) => {
        const matches = (value !== null && value !== void 0 ? value : "").match(/\$\'(.*?)\'/g);
        if (!matches) {
            return null;
        }
        console.log({ checkEmailAddresses, matches });
        const errorFields = [];
        for (const match of matches) {
            const fieldNameMatch = match.match(/\$\'(.*?)\'/)[1];
            if (!fieldsForEntityType.find((field) => fieldNameMatch === field.name)) {
                errorFields.push(fieldNameMatch);
            }
        }
        if (errorFields.length === 0) {
            return null;
        }
        return `Field${errorFields.length > 1 ? "s" : ""} ${errorFields.join(", ")} not found in pipeline ${entityType}. Make sure the field exists with the same capitalization and spacing as you've written here.`;
    }, [fieldsForEntityType, entityType]);
    const createDrafts = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsRunningMailMerge(true);
        const uploadedAttachmentPromises = formData.attachments.map((attachment) => __awaiter(void 0, void 0, void 0, function* () {
            const base64 = yield fileToBase64(attachment.file);
            return uploadCustomTemplateAttachment({
                content: base64,
                filename: attachment.file.name,
            });
        }));
        const uploadedAttachmentsResponse = yield Promise.all(uploadedAttachmentPromises);
        const uploadedAttachments = uploadedAttachmentsResponse.map((x) => ({
            filename: x.data.filename,
            gcs_filename: x.data.gcs_filename,
        }));
        try {
            const mailMergeResponse = yield mailMergeContext.runMailMerge({
                data: {
                    entity_uids: entityUids,
                    custom_templates: [
                        {
                            base_template_uid: (_a = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.uid) !== null && _a !== void 0 ? _a : null,
                            email_draft_template: {
                                to: formData.to,
                                cc: formData.cc,
                                bcc: formData.bcc,
                                subject: formData.subject,
                                body: formData.body,
                                attachments: uploadedAttachments,
                            },
                        },
                    ],
                    is_test: false,
                    should_ignore_criteria: true,
                    should_ignore_disabled_settings: true,
                },
                onExecutionStarted: (data) => {
                    console.log("Mail merge execution started", data);
                    onClose();
                },
            });
            console.log(`Running mail merge ${mailMergeResponse.uid}`);
        }
        catch (err) {
            enqueueErrorSnackbar(err);
        }
        setIsRunningMailMerge(false);
    }), [
        formData.attachments,
        formData.to,
        formData.cc,
        formData.bcc,
        formData.subject,
        formData.body,
        mailMergeContext,
        selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.uid,
        entityUids,
        onClose,
    ]);
    const sendEmails = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsRunningMailMerge(true);
        const uploadedAttachmentPromises = formData.attachments.map((attachment) => __awaiter(void 0, void 0, void 0, function* () {
            const base64 = yield fileToBase64(attachment.file);
            return uploadCustomTemplateAttachment({
                content: base64,
                filename: attachment.file.name,
            });
        }));
        const uploadedAttachmentsResponse = yield Promise.all(uploadedAttachmentPromises);
        const uploadedAttachments = uploadedAttachmentsResponse.map((x) => ({
            filename: x.data.filename,
            gcs_filename: x.data.gcs_filename,
        }));
        const mailMergeResponse = yield mailMergeContext.runMailMerge({
            data: {
                entity_uids: entityUids,
                custom_templates: [
                    {
                        base_template_uid: (_a = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.uid) !== null && _a !== void 0 ? _a : null,
                        email_template: {
                            to: formData.to,
                            cc: formData.cc,
                            bcc: formData.bcc,
                            subject: formData.subject,
                            body: formData.body,
                            attachments: uploadedAttachments,
                        },
                    },
                ],
                is_test: false,
                should_ignore_criteria: true,
                should_ignore_disabled_settings: true,
            },
            onExecutionStarted: (data) => {
                console.log("Mail merge execution started", data);
                onClose();
            },
        });
        console.log(`Running mail merge ${mailMergeResponse.uid}`);
        setIsRunningMailMerge(false);
    }), [
        formData.attachments,
        formData.to,
        formData.cc,
        formData.bcc,
        formData.subject,
        formData.body,
        mailMergeContext,
        entityUids,
        selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.uid,
        onClose,
    ]);
    return (React.createElement(Dialog, { open: true, fullWidth: true, maxWidth: "lg" },
        React.createElement(DialogTitle, null,
            "Mail Merge (",
            entityUids.length,
            " box",
            entityUids.length !== 1 ? "es" : "",
            ")"),
        React.createElement(DialogContent, null,
            React.createElement(Grid2, { container: true, direction: "column", spacing: 4, wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Alert, { severity: "info", variant: "outlined" }, "Emails sent via Mail Merge will automatically be attached to the box. Drafts will also be attached to the box if they are sent via Gmail.")),
                !entityType && entityUids.length !== 1 && (React.createElement(Grid2, null,
                    React.createElement(Alert, { severity: "info", variant: "outlined" }, "You will only be able to use merge fields if you select boxes in a single pipeline."))),
                !!entityType && (React.createElement(Grid2, null,
                    React.createElement(TextField, { slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Select a template"),
                            },
                        }, select: true, fullWidth: true, size: "small", variant: "outlined", value: selectedTemplateUid !== null && selectedTemplateUid !== void 0 ? selectedTemplateUid : "", onChange: handleSelectedTemplateChanged },
                        React.createElement(MenuItem, { value: "" }), emailTemplates === null || emailTemplates === void 0 ? void 0 :
                        emailTemplates.map((template) => (React.createElement(MenuItem, { key: template.uid, value: template.uid }, template.name)))))),
                parseTemplateApi.isFetching && entityUids.length === 1 ? (React.createElement(Grid2, null,
                    React.createElement(Typography, null, "Parsing template..."))) : (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption" }, "To"),
                        React.createElement(MentionsInput, { value: formData.to, onChange: setTo, error: !!checkWildcards(formData.to, true), helperText: (_q = checkWildcards(formData.to, true)) !== null && _q !== void 0 ? _q : (entityType ? (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })) : undefined), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                ...agentMentionsFields,
                                ...clientMentionsFields,
                                ...vendorMentionsFields,
                            ], disabled: parseTemplateApi.isFetching })),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption" }, "Cc"),
                        React.createElement(MentionsInput, { value: formData.cc, onChange: setCc, error: !!checkWildcards(formData.cc, true), helperText: (_r = checkWildcards(formData.cc, true)) !== null && _r !== void 0 ? _r : (entityType ? (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })) : undefined), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                ...agentMentionsFields,
                                ...clientMentionsFields,
                                ...vendorMentionsFields,
                            ] })),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption" }, "Bcc"),
                        React.createElement(MentionsInput, { value: formData.bcc, onChange: setBcc, error: !!checkWildcards(formData.bcc, true), helperText: (_s = checkWildcards(formData.bcc, true)) !== null && _s !== void 0 ? _s : (entityType ? (React.createElement(MailScheduleTemplateHelperText, { extraVerbiage: "The template will not be processed if this field does not evaluate to a comma-separated list of valid email addresses." })) : undefined), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                ...agentMentionsFields,
                                ...clientMentionsFields,
                                ...vendorMentionsFields,
                            ] })),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption" }, "Subject"),
                        React.createElement(MentionsInput, { value: formData.subject, onChange: setSubject, error: !!checkWildcards(formData.subject, false), helperText: (_t = checkWildcards(formData.subject, false)) !== null && _t !== void 0 ? _t : (entityType ? React.createElement(MailScheduleTemplateHelperText, null) : undefined), singleLine: true, fields: textFields, additionalAutocompleteOptions: [
                                ...agentMentionsFields,
                                ...clientMentionsFields,
                                ...vendorMentionsFields,
                            ] })),
                    React.createElement(Grid2, null,
                        React.createElement(Divider, null)),
                    React.createElement(Grid2, null,
                        React.createElement(Typography, { variant: "caption" }, "Body"),
                        React.createElement(MailScheduleQuill, { ReactQuillProps: {
                                value: formData.body,
                                onChange: setBody,
                            }, fields: mailScheduleQuillFields, boilerplate: boilerplateForEntityType, entityType: entityType })),
                    React.createElement(Grid2, null,
                        React.createElement(Grid2, { container: true, direction: "column", spacing: 0 },
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { variant: "caption" }, "Attachments")),
                            downloadingFiles.length > 0 && (React.createElement(Grid2, null,
                                React.createElement(Grid2, { container: true, direction: "row", alignItems: "center", spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(CircularProgress, { size: 16 })),
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "caption" },
                                            "Downloading ", (_u = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.gcp_attachments.length) !== null && _u !== void 0 ? _u : downloadingFiles.length,
                                            " ",
                                            "attachments for template"))))),
                            React.createElement(Grid2, null,
                                React.createElement(EmailAttachments, { attachments: formData.attachments, onChange: setAttachments })))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, variant: "text", size: "small", disabled: isRunningMailMerge }, "Cancel"),
            React.createElement(Button, { onClick: createDrafts, disabled: !htmlToText(formData.body).trim() || isRunningMailMerge, variant: "outlined", size: "small" }, "Create Drafts"),
            React.createElement(Button, { onClick: sendEmails, disabled: !(formData.to.trim() || formData.cc.trim() || formData.bcc.trim()) ||
                    !formData.body.trim() ||
                    isRunningMailMerge, variant: "contained", size: "small" }, "Send Now"))));
};
export default MailMergeDialog;
