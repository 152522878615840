import * as React from "react";
import { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import useSavedViewEditorContext from "../SavedViewEditorContext";
const SavedViewSaveAsDialog = () => {
    var _a;
    const context = useSavedViewEditorContext();
    const [title, setTitle] = useState(((_a = context.view) === null || _a === void 0 ? void 0 : _a.name) ? `${context.view.name} (copy)` : "");
    const handleTitleChanged = React.useCallback((e) => {
        setTitle(e.target.value);
    }, []);
    const handleSave = React.useCallback(() => {
        context.saveAs(title);
    }, [context, title]);
    const canSave = !!title;
    return (React.createElement(Dialog, { open: true, onClose: context.closeSaveAsDialog, maxWidth: "xs", fullWidth: true },
        React.createElement(DialogTitle, null, "Save As"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { p: 4 },
                React.createElement(TextField, { fullWidth: true, label: "Name", value: title, onChange: handleTitleChanged, slotProps: {
                        htmlInput: {
                            maxLength: 255,
                        },
                    }, size: "small" }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: context.closeSaveAsDialog, variant: "text" }, "Cancel"),
            React.createElement(Button, { onClick: handleSave, disabled: !canSave, color: "primary", variant: "contained" }, "Save"))));
};
export default SavedViewSaveAsDialog;
