var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Grid2 } from "@mui/material";
import { useRunMailschedule4 } from "@app/orval/api/mail-schedule-run";
import MailMergeJob from "./MailMergeJob";
export const MailMergeContext = React.createContext({
    runMailMerge: () => __awaiter(void 0, void 0, void 0, function* () {
        return {};
    }),
    jobs: [],
});
const MailMergeProvider = (props) => {
    const [jobs, setJobs] = React.useState([]);
    const runMailMergeApi = useRunMailschedule4();
    const runMailMerge = React.useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        const runMailMergeResponse = yield runMailMergeApi.mutateAsync({ data: params.data });
        setJobs((prev) => [
            ...prev,
            {
                uid: runMailMergeResponse.data.uid,
                type: "Mail Merge",
            },
        ]);
        if (params.onExecutionStarted) {
            params.onExecutionStarted(runMailMergeResponse.data);
        }
        return runMailMergeResponse.data;
    }), [runMailMergeApi]);
    const removeJob = React.useCallback((uid) => () => {
        setJobs((prev) => prev.filter((job) => job.uid !== uid));
    }, []);
    const value = React.useMemo(() => {
        return {
            jobs,
            runMailMerge,
        };
    }, [jobs, runMailMerge]);
    return (React.createElement(MailMergeContext.Provider, { value: value },
        props.children,
        jobs.length > 0 && (React.createElement("div", { style: { width: "200px", maxHeight: "100vh", position: "fixed", right: "5px", bottom: "5px", zIndex: 1050 } },
            React.createElement(Grid2, { container: true, spacing: 2, direction: "column", wrap: "nowrap" }, jobs.map((x) => (React.createElement(Grid2, { key: x.uid },
                React.createElement(MailMergeJob, { uid: x.uid, onClose: removeJob(x.uid) })))))))));
};
export default MailMergeProvider;
