import { visit } from "unist-util-visit";
export const reactMarkdownRemarkDirective = () => {
    return (tree) => {
        visit(tree, ["textDirective", "leafDirective", "containerDirective"], (node) => {
            let hName = "div";
            if (node.name === "uploadimage") {
                hName = "img";
            }
            else if (node.name === "embedvideo") {
                hName = "iframe";
            }
            node.data = Object.assign(Object.assign({}, node.data), { hName, hProperties: Object.assign(Object.assign({}, node.attributes), { class: node.name, className: node.name }) });
            return node;
        });
    };
};
export const embedVideoRemarkDirective = () => {
    return (tree, file) => {
        visit(tree, ["leafDirective", "containerDirective", "textDirective"], (node) => {
            if (node.name !== "embedvideo") {
                return;
            }
            const data = node.data || (node.data = {});
            const attributes = node.attributes || {};
            const { src } = attributes;
            if (node.type === "textDirective") {
                file.fail("Unexpected `:embedvideo` text directive, use two colons for a leaf directive", node);
            }
            if (!src) {
                file.fail("Unexpected missing `id` on `embedvideo` directive", node);
            }
            data.hName = "iframe";
            data.hProperties = {
                src,
                width: 200,
                height: 200,
                frameBorder: 0,
                allow: "picture-in-picture",
                allowFullScreen: true,
            };
        });
    };
};
