import React, { useRef, useState, useEffect } from "react";
import { Typography, Tooltip } from "@mui/material";
/**
 * Renders a <Typography /> component. If the text overflows, it will show a tooltip with the full text.
 *
 * @param props
 * @returns
 */
const EllipsisText = (props) => {
    const { TooltipProps, TypographyProps, text } = props;
    const textRef = useRef(null);
    const [isClipped, setIsClipped] = useState(false);
    useEffect(() => {
        if (textRef.current) {
            setIsClipped(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [text]);
    return (React.createElement(Tooltip, Object.assign({ title: isClipped ? (React.createElement("div", null, text.split("\n").map((line, index) => (React.createElement(Typography, { key: index }, line))))) : (""), placement: "top" }, TooltipProps),
        React.createElement(Typography, Object.assign({ ref: textRef }, TypographyProps, { noWrap: true, sx: Object.assign(Object.assign({}, TypographyProps === null || TypographyProps === void 0 ? void 0 : TypographyProps.sx), { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", cursor: isClipped ? "pointer" : "default" }) }), text)));
};
export default EllipsisText;
