import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Alarm, AlarmOn, Backspace, Block, Clear, DateRange, Done, ErrorOutline, Launch, Remove, Schedule, } from "@mui/icons-material";
import { Box, Divider, Grid, MenuItem, TextField, Tooltip, Typography, Autocomplete, ListItemIcon, } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import DashPopover from "@app/common/CoreMoreMenu";
import CoreTextField from "@app/common/CoreTextField";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import MailScheduleTemplatePreview from "@app/entrypoints/brokerage/features/mailschedule/preview/MailScheduleTemplatePreview";
import { sanitize } from "@app/entrypoints/brokerage/features/mailschedule/utils/utils";
import { withDialog } from "@app/hoc/withDialog";
import { withFilter } from "@app/hoc/withFilter";
import API from "@app/util/api";
import AppConfig from "@app/util/AppConfig";
import Session from "@app/util/Session";
import { getFullName } from "@app/util/Utils";
const StatusTooltip = (props) => (React.createElement(Box, { p: 1 },
    React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Typography, null, props.status)),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Divider, { style: { background: "white" } })),
        props.triggers.map((trigger, indx) => (React.createElement(Grid, { key: `trigger${indx}__${trigger.description}`, container: true, item: true, xs: 12, spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Alarm, { style: { color: AppConfig.themeColors.lightestblue }, fontSize: "small" })),
            React.createElement(Grid, { item: true, style: { flex: 1 } },
                React.createElement(Typography, { variant: "body2" }, trigger.description))))),
        props.criteria.map((criterion, indx) => (React.createElement(React.Fragment, null,
            !criterion.criterion.is_in_match_all_group &&
                (indx === 0 || props.criteria[indx - 1].criterion.is_in_match_all_group) && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, { style: { background: AppConfig.themeColors.lightestblue } }))),
            React.createElement(Grid, { key: `criteria${indx}__${criterion.criterion.description}`, container: true, item: true, xs: 12, spacing: 1 },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1 },
                    React.createElement(Grid, { item: true }, criterion.satisfied ? (React.createElement(Done, { style: { color: "#61ff71" }, fontSize: "small" })) : (React.createElement(Clear, { style: { color: "#ff6363" }, fontSize: "small" }))),
                    React.createElement(Grid, { item: true, style: { flex: 1 } },
                        React.createElement(Typography, { variant: "body2" }, criterion.criterion.description))),
                criterion.error && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: "body2" }, criterion.error))))))))));
class MailScheduleBoxesTableWrapper extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "child", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "boxesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "statusAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                agents: null,
                templates: null,
                pipelines: null,
                boxes: null,
                boxQueryStartPage: 0,
                isGettingBoxes: false,
                statuses: null,
                boxSearch: null,
                boxFilter: null,
                pipelineFilter: null,
                stageFilter: null,
                agentFilter: null,
                error: null,
                currentlyPreviewingTemplate: null,
            }
        });
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.users.list({
                    onSuccess: (result) => {
                        this.setState({
                            agents: result
                                .sort((a, b) => {
                                const aName = getFullName(a.first_name, a.last_name);
                                const bName = getFullName(b.first_name, b.last_name);
                                if (aName < bName)
                                    return -1;
                                if (aName > bName)
                                    return 1;
                                return 0;
                            })
                                .filter((user) => user.roles.filter((role) => role === "Producer-Full Time" || role === "Producer-Part Time").length > 0),
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                        });
                    },
                    onError: (error) => {
                        this.setState({
                            error,
                        });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
                API.mailScheduleTemplates.list({
                    onSuccess: (result) => {
                        this.setState({
                            templates: result
                                .map((template, index) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                                return (Object.assign(Object.assign({}, template), { recipients: {
                                        Calendar: (_a = template.calendar_template) === null || _a === void 0 ? void 0 : _a.guests,
                                        Email: [(_b = template.email_template) === null || _b === void 0 ? void 0 : _b.to, (_c = template.email_template) === null || _c === void 0 ? void 0 : _c.cc, (_d = template.email_template) === null || _d === void 0 ? void 0 : _d.bcc]
                                            .filter((x) => x)
                                            .join(", "),
                                        "Email Draft": [
                                            (_e = template.email_draft_template) === null || _e === void 0 ? void 0 : _e.to,
                                            (_f = template.email_draft_template) === null || _f === void 0 ? void 0 : _f.cc,
                                            (_g = template.email_draft_template) === null || _g === void 0 ? void 0 : _g.bcc,
                                        ]
                                            .filter((x) => x)
                                            .join(", "),
                                        SMS: (_h = template.sms_template) === null || _h === void 0 ? void 0 : _h.to_phone,
                                        "SMS Draft": (_j = template.sms_draft_template) === null || _j === void 0 ? void 0 : _j.to_phone,
                                        Slack: (_k = template.slack_template) === null || _k === void 0 ? void 0 : _k.channel_id,
                                        "Streak Comment": "",
                                    }[template.template_type], subject: {
                                        Calendar: (_l = template.calendar_template) === null || _l === void 0 ? void 0 : _l.title,
                                        Email: (_m = template.email_template) === null || _m === void 0 ? void 0 : _m.subject,
                                        "Email Draft": (_o = template.email_draft_template) === null || _o === void 0 ? void 0 : _o.subject,
                                        SMS: "",
                                        "SMS Draft": "",
                                        Slack: "",
                                        "Streak Comment": "",
                                    }[template.template_type], number: result.filter((template_, index_) => template_.pipeline === template.pipeline && index_ <= index)
                                        .length }));
                            })
                                .filter((template) => template.is_active),
                        });
                    },
                    onError: (error) => {
                        this.setState({ error });
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
        Object.defineProperty(this, "getBoxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pipelineKey, startPage) => {
                const { isGettingBoxes } = this.state;
                if (isGettingBoxes) {
                    console.log("Getting paginated boxes: page", startPage);
                    this.boxesAbortController = new AbortController();
                    API.streakBoxes.listPaginated(pipelineKey, {
                        query: {
                            start_page: startPage,
                            end_page: startPage + 1,
                        },
                        onSuccess: (result) => {
                            this.setState((prevState) => {
                                return {
                                    boxes: prevState.boxes == null ? result.boxes : [...prevState.boxes, ...result.boxes],
                                    isGettingBoxes: result.boxes.length > 0,
                                };
                            }, () => {
                                this.getBoxes(pipelineKey, startPage + 2);
                            });
                        },
                        onError: (error) => {
                            this.props.showErrorDialog(error);
                        },
                        options: {
                            signal: this.boxesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                }
            }
        });
        Object.defineProperty(this, "refreshBoxes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const pipelineKey = this.state.pipelineFilter;
                (_a = this.boxesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.setState({
                    boxes: null,
                    isGettingBoxes: true,
                }, () => {
                    if (pipelineKey) {
                        this.getBoxes(pipelineKey, 0);
                    }
                });
            }
        });
        Object.defineProperty(this, "refreshStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const boxKey = this.state.boxFilter;
                (_a = this.statusAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.setState({
                    statuses: null,
                });
                if (boxKey) {
                    this.statusAbortController = new AbortController();
                    API.mailSchedule.getStatusOfTemplate({
                        query: {
                            box_key: boxKey,
                        },
                        onSuccess: (result) => {
                            this.setState({
                                statuses: result,
                            });
                        },
                        onError: (error) => {
                            this.props.showErrorDialog(error);
                        },
                        options: {
                            signal: this.statusAbortController.signal,
                        },
                    });
                }
            }
        });
        Object.defineProperty(this, "setAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const value = e.target.value === " " ? null : e.target.value;
                this.props.updateFilter("agent", value, "box", null);
                this.setState({
                    boxSearch: null,
                });
            }
        });
        Object.defineProperty(this, "setPipeline", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const value = e.target.value === " " ? null : e.target.value;
                this.props.updateFilter("pipeline", value, "stage", null, "box", null);
                this.setState({
                    boxSearch: null,
                });
            }
        });
        Object.defineProperty(this, "setStage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const value = e.target.value === " " ? null : e.target.value;
                this.props.updateFilter("stage", value, "box", null);
                this.setState({
                    boxSearch: null,
                });
            }
        });
        Object.defineProperty(this, "filterOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (options) => options
                .filter((box) => !this.state.boxSearch ||
                box.name.toLowerCase().trim().indexOf(this.state.boxSearch.toLowerCase().trim()) !== -1)
                .slice(0, 100)
        });
        Object.defineProperty(this, "groupBy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (box) => {
                var _a, _b;
                const { agentFilter, pipelineFilter, pipelines, stageFilter } = this.state;
                const pipelinesDict = {};
                for (const pipeline of pipelines) {
                    pipelinesDict[pipeline.key] = pipeline;
                }
                if (stageFilter && agentFilter) {
                    return "";
                }
                if (stageFilter) {
                    return (_b = (_a = box.assignedToSharingEntries[0]) === null || _a === void 0 ? void 0 : _a.fullName) !== null && _b !== void 0 ? _b : "";
                }
                if (pipelineFilter) {
                    return pipelinesDict[box.pipelineKey].stages[box.stageKey].name;
                }
                return `${pipelinesDict[box.pipelineKey].name} - ${pipelinesDict[box.pipelineKey].stages[box.stageKey].name}`;
            }
        });
        Object.defineProperty(this, "onSearchInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, value, reason) => {
                // console.log("onSearchInputChange", value, reason);
                if (reason !== "reset") {
                    this.setState({
                        boxSearch: value,
                        boxFilter: null,
                    });
                }
            }
        });
        Object.defineProperty(this, "getOptionLabel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (box) => [
                [box.readable_fields["Street #"], box.readable_fields["Street Name"]].filter((obj) => obj).join(" "),
                [
                    [box.readable_fields["Client #1 First"], box.readable_fields["Client #1 Last"]]
                        .filter((obj) => obj)
                        .join(" "),
                    [box.readable_fields["Client #2 First"], box.readable_fields["Client #2 Last"]]
                        .filter((obj) => obj)
                        .join(" "),
                ]
                    .filter((obj) => obj)
                    .join(" and "),
            ]
                .filter((obj) => obj)
                .join(" - ") || box.name
        });
        Object.defineProperty(this, "renderBoxSearchInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (params) => (React.createElement(TextField, Object.assign({}, params, { variant: "standard", label: this.state.boxes || !this.state.pipelineFilter ? "Box" : "Loading boxes...", 
                // placeholder="Search..."
                size: "small" })))
        });
        Object.defineProperty(this, "onBoxSearchChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, value, reason) => {
                this.setState({
                    statuses: null,
                }, () => {
                    if (reason === "selectOption") {
                        this.setState({
                            boxSearch: null,
                        }, () => {
                            this.props.updateFilter("box", value.key);
                        });
                    }
                    else if (reason === "removeOption") {
                        this.setState({
                            boxSearch: null,
                        }, () => {
                            this.props.updateFilter("box", null);
                        });
                    }
                });
            }
        });
        Object.defineProperty(this, "updateTemplateStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (boxKey, templateUid, status) => () => {
                var _a;
                // @ts-expect-error
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                const template = this.state.templates.find((template_) => template_.uid === templateUid);
                const box = this.state.boxes.find((box_) => box_.key === boxKey);
                const loadingSnackbar = enqueueSnackbar("Saving template...", { variant: "info", persist: true });
                API.mailSchedule.setStatusOfTemplate({
                    data: {
                        box_key: boxKey,
                        template_uid: templateUid,
                        status: status,
                    },
                    onSuccess: () => {
                        var _a, _b;
                        closeSnackbar(loadingSnackbar);
                        enqueueSnackbar(`Updated status for template ${(_a = template === null || template === void 0 ? void 0 : template.name) !== null && _a !== void 0 ? _a : templateUid} for box ${(_b = box === null || box === void 0 ? void 0 : box.name) !== null && _b !== void 0 ? _b : boxKey}`, {
                            variant: "success",
                        });
                        this.setState((prevState) => ({
                            statuses: [
                                ...prevState.statuses.filter((status_) => status_.template !== templateUid || status_.box_key !== boxKey),
                                Object.assign(Object.assign({}, prevState.statuses.find((status_) => status_.template === templateUid && status_.box_key === boxKey)), { status: status }),
                            ],
                        }));
                    },
                    onError: (error) => {
                        this.props.showErrorDialog(error);
                    },
                });
            }
        });
        Object.defineProperty(this, "handlePreviewOpened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (templateUid) => () => {
                this.setState((prevState) => ({
                    currentlyPreviewingTemplate: prevState.templates.find((template) => template.uid === templateUid),
                }));
            }
        });
        Object.defineProperty(this, "handlePreviewClosed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    currentlyPreviewingTemplate: null,
                });
            }
        });
        Object.defineProperty(this, "handleTemplateProcessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                enqueueSnackbar("Template processed successfully", { variant: "success" });
                this.handlePreviewClosed();
                this.refreshStatus();
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a, _b, _c, _d;
        const params = new URLSearchParams(nextProps.location.search);
        return {
            agentFilter: (_a = params.get("agent")) !== null && _a !== void 0 ? _a : null,
            pipelineFilter: (_b = params.get("pipeline")) !== null && _b !== void 0 ? _b : null,
            stageFilter: (_c = params.get("stage")) !== null && _c !== void 0 ? _c : null,
            boxFilter: (_d = params.get("box")) !== null && _d !== void 0 ? _d : null,
        };
    }
    componentDidMount() {
        this.refresh();
        this.refreshBoxes();
        this.refreshStatus();
    }
    componentDidUpdate(_, prevState) {
        const { boxFilter, pipelineFilter } = this.state;
        const prevPipelineFilter = prevState.pipelineFilter;
        const prevBoxFilter = prevState.boxFilter;
        if (pipelineFilter !== prevPipelineFilter) {
            this.refreshBoxes();
        }
        if (boxFilter !== prevBoxFilter) {
            this.refreshStatus();
        }
    }
    componentWillUnmount() {
        var _a, _b, _c;
        (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
        (_b = this.boxesAbortController) === null || _b === void 0 ? void 0 : _b.abort();
        (_c = this.statusAbortController) === null || _c === void 0 ? void 0 : _c.abort();
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const { agentFilter, agents, boxFilter, boxes, currentlyPreviewingTemplate, error, isGettingBoxes, pipelineFilter, pipelines, stageFilter, statuses, templates, } = this.state;
        if (currentlyPreviewingTemplate) {
            return (React.createElement(MailScheduleTemplatePreview, { templateUid: currentlyPreviewingTemplate.uid, boxKey: boxes.find((box) => box.key === boxFilter).key, onClose: this.handlePreviewClosed, onTemplateProcessed: this.handleTemplateProcessed }));
        }
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!agents || !pipelines || !templates) {
            return React.createElement(CoreLoading, null);
        }
        if (!(((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole("Superadmin")) || ((_b = Session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.hasRole("Admin")))) {
            const params = new URLSearchParams(this.props.location.search);
            if (!params.get("agent")) {
                params.set("agent", (_d = (_c = Session.viewingAsUser) === null || _c === void 0 ? void 0 : _c.email) !== null && _d !== void 0 ? _d : "");
                this.props.navigate(`/mailschedule/boxes?${params.toString()}`);
                return null;
            }
        }
        let selectedPipeline = null;
        let stages = null;
        if (pipelineFilter) {
            selectedPipeline = (_e = pipelines.find((pipeline) => pipeline.key === pipelineFilter)) !== null && _e !== void 0 ? _e : null;
            if (selectedPipeline) {
                stages = Object.keys(selectedPipeline.stages)
                    .sort((a, b) => {
                    if (selectedPipeline.stageOrder.indexOf(a) < selectedPipeline.stageOrder.indexOf(b))
                        return -1;
                    if (selectedPipeline.stageOrder.indexOf(a) > selectedPipeline.stageOrder.indexOf(b))
                        return -1;
                    return 0;
                })
                    .map((key) => selectedPipeline.stages[key]);
            }
            else {
                console.error(`No pipeline with key ${pipelineFilter}`);
            }
        }
        const pipelinesDict = {};
        for (const pipeline of pipelines) {
            pipelinesDict[pipeline.key] = pipeline;
        }
        const filteredBoxes = (boxes !== null && boxes !== void 0 ? boxes : [])
            .filter((box) => {
            var _a, _b;
            return agentFilter
                ? (((_a = box.readable_fields) === null || _a === void 0 ? void 0 : _a["Mail Schedule User"])
                    ? box.readable_fields["Mail Schedule User"].toLowerCase().trim()
                    : (_b = box.assignedToSharingEntries[0]) === null || _b === void 0 ? void 0 : _b.email.toLowerCase().trim()) === agentFilter.toLowerCase().trim()
                : true;
        })
            .filter((box) => (pipelineFilter ? box.pipelineKey === pipelineFilter : true))
            .filter((box) => (stageFilter ? box.stageKey === stageFilter : true))
            .sort((a, b) => {
            var _a, _b, _c, _d;
            if (stageFilter) {
                if (((_a = a.assignedToSharingEntries[0]) === null || _a === void 0 ? void 0 : _a.fullName) < ((_b = b.assignedToSharingEntries[0]) === null || _b === void 0 ? void 0 : _b.fullName))
                    return -1;
                if (((_c = a.assignedToSharingEntries[0]) === null || _c === void 0 ? void 0 : _c.fullName) > ((_d = b.assignedToSharingEntries[0]) === null || _d === void 0 ? void 0 : _d.fullName))
                    return 1;
            }
            if (pipelinesDict[a.pipelineKey].name < pipelinesDict[b.pipelineKey].name)
                return -1;
            if (pipelinesDict[a.pipelineKey].name > pipelinesDict[b.pipelineKey].name)
                return 1;
            if (pipelinesDict[a.pipelineKey].stageOrder.indexOf(a.stageKey) <
                pipelinesDict[b.pipelineKey].stageOrder.indexOf(b.stageKey))
                return -1;
            if (pipelinesDict[a.pipelineKey].stageOrder.indexOf(a.stageKey) >
                pipelinesDict[b.pipelineKey].stageOrder.indexOf(b.stageKey))
                return 1;
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
        let templatesForBox = null;
        if (boxFilter && boxes) {
            const box = boxes.find((obj) => obj.key === boxFilter);
            if (box && statuses) {
                templatesForBox = templates
                    .filter((template) => template.pipeline === box.pipelineKey)
                    .map((template) => {
                    var _a;
                    const status = statuses.find((s) => s.box_key === box.key && s.template === template.uid);
                    let t = Object.assign(Object.assign({}, template), { status: (((_a = status.criteria) !== null && _a !== void 0 ? _a : []).find((criterion) => criterion.error)
                            ? "error"
                            : status.status), criteria: status.criteria, num_times_processed: status.history.filter((item) => item.status === "processed").length, last_sent: status.history.filter((item) => item.status === "processed").length > 0
                            ? status.history.sort((a, b) => {
                                if (a.timestamp > b.timestamp)
                                    return -1;
                                if (a.timestamp < b.timestamp)
                                    return 1;
                                return 0;
                            })[0].timestamp
                            : null });
                    const pipeline = pipelines.find((p) => p.key === t.pipeline);
                    if (pipeline) {
                        t = sanitize(t, pipeline, box);
                    }
                    return t;
                });
            }
        }
        const columns = [
            boxFilter && {
                name: "uid",
                label: " ",
                options: {
                    searchable: false,
                    sort: false,
                    download: false,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta) => {
                        const template = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        return (React.createElement(DashPopover, null,
                            React.createElement(MenuItem, { onClick: this.handlePreviewOpened(value) },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Launch, { fontSize: "small" })),
                                "Open preview"),
                            template.status !== "pending" && (React.createElement(MenuItem, { onClick: this.updateTemplateStatus(boxFilter, value, "pending") },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Schedule, { fontSize: "small" })),
                                template.status === "cancelled" ? "Need to Process" : "Need to Re-process")),
                            template.status !== "processed" && (React.createElement(MenuItem, { onClick: this.updateTemplateStatus(boxFilter, value, "processed") },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Done, { fontSize: "small" })),
                                "Mark as Processed")),
                            template.status !== "cancelled" && (React.createElement(MenuItem, { onClick: this.updateTemplateStatus(boxFilter, value, "cancelled") },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Backspace, { fontSize: "small" })),
                                "Mark as Removed"))));
                    },
                },
            },
            {
                name: "number",
                label: "#",
                options: {
                    viewColumns: false,
                },
            },
            {
                name: "name",
                label: "Name",
                options: {
                    viewColumns: false,
                    customBodyRender: (value, tableMeta) => {
                        var _a;
                        if ((_a = Session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) {
                            const template = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index];
                            return (React.createElement(RouterLink, { to: `/mailschedule/templates/${template.uid}` },
                                React.createElement(Typography, { variant: "body2" }, template.name)));
                        }
                        return value;
                    },
                },
            },
            {
                name: "status",
                label: "Status",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const criteria = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index].criteria;
                        const { triggers } = templatesForBox[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        if (value === "processed") {
                            return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Processed", triggers: triggers, criteria: criteria }) },
                                React.createElement(Done, { color: "primary" })));
                        }
                        if (value === "cancelled") {
                            return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Cancelled", triggers: triggers, criteria: criteria }) },
                                React.createElement(Block, { color: "secondary" })));
                        }
                        if (value === "error") {
                            return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Error", triggers: triggers, criteria: criteria }) },
                                React.createElement(ErrorOutline, { color: "secondary" })));
                        }
                        if (value === "inactive") {
                            return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "This template is inactive", triggers: triggers, criteria: criteria }) },
                                React.createElement(Remove, null)));
                        }
                        if (criteria.length > 0 && criteria.filter((criterion) => !criterion.satisfied).length === 0) {
                            return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Ready to process", triggers: triggers, criteria: criteria }) },
                                React.createElement(AlarmOn, null)));
                        }
                        if (value === "pending") {
                            return (React.createElement(Tooltip, { title: React.createElement(StatusTooltip, { status: "Pending", triggers: triggers, criteria: criteria }) },
                                React.createElement(DateRange, null)));
                        }
                        return value;
                    },
                },
            },
            {
                name: "subject",
                label: "Subject or Title",
                options: {
                    customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: (value !== null && value !== void 0 ? value : "").replace(/(\$\'.*?\')/g, `<font color='${AppConfig.themeColors.primary}'>$1</font>`),
                        } })),
                },
            },
            {
                name: "recipients",
                label: "Recipients or Guests",
                options: {
                    customBodyRender: (value) => (React.createElement("div", { dangerouslySetInnerHTML: {
                            __html: (value !== null && value !== void 0 ? value : "").replace(/(\$\'.*?\')/g, `<font color='${AppConfig.themeColors.primary}'>$1</font>`),
                        } })),
                },
            },
            {
                name: "num_times_processed",
                label: "# Times Processed",
                options: {
                    display: false,
                    searchable: false,
                    filter: false,
                    customBodyRender: (value) => {
                        if (!value) {
                            return null;
                        }
                        return value;
                    },
                },
            },
            {
                name: "last_sent",
                label: "Last Sent",
                options: {
                    customBodyRender: (value) => {
                        if (value && dayjs_(value).isValid()) {
                            return dayjs_(value).format("ddd, MMM D, YYYY h:mm A");
                        }
                        return value;
                    },
                },
            },
        ].filter((obj) => obj);
        const options = {
            responsive: "standard",
            selectableRows: "none",
            download: false,
            print: false,
            filter: false,
            search: false,
            searchOpen: true,
            viewColumns: true,
            pagination: true,
            rowsPerPage: 100,
            rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
            expandableRows: false,
        };
        return (React.createElement(Grid, { container: true, spacing: 2, style: {
                width: "100%",
                height: "100%",
                overflowX: "hidden",
                margin: 0,
            }, alignContent: "flex-start" },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        (((_f = Session.viewingAsUser) === null || _f === void 0 ? void 0 : _f.hasRole("Superadmin")) || ((_g = Session.viewingAsUser) === null || _g === void 0 ? void 0 : _g.hasRole("Admin"))) && (React.createElement(Grid, { item: true, xs: 3, style: { marginTop: "auto" } },
                            React.createElement(CoreTextField, { variant: "standard", fullWidth: true, label: "Agent", select: true, value: agentFilter !== null && agentFilter !== void 0 ? agentFilter : " ", onChange: this.setAgent },
                                React.createElement(MenuItem, { key: -1, value: " " }, "Any"),
                                agents
                                    .filter((agent) => agent.email)
                                    .map((agent) => {
                                    var _a, _b;
                                    return (React.createElement(MenuItem, { key: (_a = agent.email) !== null && _a !== void 0 ? _a : "", value: (_b = agent.email) !== null && _b !== void 0 ? _b : "" },
                                        agent.first_name,
                                        " ",
                                        agent.last_name));
                                })))),
                        React.createElement(Grid, { item: true, xs: 3, style: { marginTop: "auto" } },
                            React.createElement(CoreTextField, { variant: "standard", fullWidth: true, label: "Pipeline", select: true, value: pipelineFilter !== null && pipelineFilter !== void 0 ? pipelineFilter : " ", onChange: this.setPipeline },
                                React.createElement(MenuItem, { key: -1, value: " " }, "Any"),
                                pipelines.map((pipeline) => (React.createElement(MenuItem, { key: pipeline.key, value: pipeline.key }, pipeline.name))))),
                        React.createElement(Grid, { item: true, xs: 3, style: { marginTop: "auto" } },
                            React.createElement(CoreTextField, { variant: "standard", fullWidth: true, label: "Stage", select: true, value: stageFilter !== null && stageFilter !== void 0 ? stageFilter : " ", onChange: this.setStage, disabled: !pipelineFilter },
                                React.createElement(MenuItem, { key: -1, value: " " }, "Any"), stages === null || stages === void 0 ? void 0 :
                                stages.sort((a, b) => {
                                    const pipeline = pipelines.find((p) => p.key === pipelineFilter);
                                    if (pipeline) {
                                        const orderA = pipeline.stageOrder.findIndex((stageKey) => stageKey === a.key);
                                        const orderB = pipeline.stageOrder.findIndex((stageKey) => stageKey === b.key);
                                        if (orderA < orderB)
                                            return -1;
                                        if (orderA > orderB)
                                            return 1;
                                    }
                                    return 0;
                                }).map((stage) => (React.createElement(MenuItem, { key: stage.key, value: stage.key }, stage.name))))),
                        React.createElement(Grid, { item: true, xs: 3, style: { marginTop: "auto" } },
                            React.createElement(Autocomplete, { disableClearable: true, forcePopupIcon: false, options: filteredBoxes, noOptionsText: "No boxes found", filterOptions: this.filterOptions, groupBy: this.groupBy, inputValue: this.state.boxSearch === null
                                    ? (_l = (_k = (_j = ((_h = this.state.boxes) !== null && _h !== void 0 ? _h : []).find((box) => box.key === boxFilter)) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : boxFilter) !== null && _l !== void 0 ? _l : ""
                                    : this.state.boxSearch, onInputChange: this.onSearchInputChange, getOptionLabel: this.getOptionLabel, style: { width: 300 }, 
                                // debug
                                renderInput: this.renderBoxSearchInput, 
                                // renderOption={}
                                onChange: this.onBoxSearchChange, disabled: !this.state.boxes }))))),
            pipelineFilter && (!boxes || isGettingBoxes) && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Typography, null, "Loading boxes...")))),
            boxFilter && !statuses && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { p: 2 },
                    React.createElement(Typography, null, "Getting status for each template...")))),
            templatesForBox && (React.createElement(Grid, { item: true, xs: 12, style: { height: "calc(100% - 68px)", padding: "0.5rem" } },
                React.createElement(CoreDataTable, { title: "", data: templatesForBox, columns: columns, options: options, cellPadding: 0 })))));
    }
}
export default withDialog(withFilter(MailScheduleBoxesTableWrapper));
