import * as React from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@mui/material";
import pickBy from "lodash/pickBy";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreDialog from "@app/common/CoreDialog";
import useSession from "@app/hooks/useSession";
import { useListPsps } from "@app/orval/api/psps";
const PSPCertificationDialog = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const [selectedPSP, setSelectedPSP] = React.useState(null);
    const { onCancel, onConfirm } = props;
    const session = useSession();
    const query = pickBy({
        limit: 10,
        created_by_market_analyst: true,
        should_exclude_completed_training_psps: true,
        is_training_psp: false,
        is_training_enabled: true,
        is_archived: false,
        market: ((_a = session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin", "Market Analyst"]))
            ? undefined
            : ((_d = (_c = (_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.office) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "RDU"),
    }, (val) => val !== undefined);
    const listPSPsApi = useListPsps(query);
    const psps = (_f = (_e = listPSPsApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const isValid = React.useMemo(() => {
        return !!selectedPSP;
    }, [selectedPSP]);
    const selectPSP = React.useCallback((uid) => {
        setSelectedPSP(uid);
    }, []);
    const onStartTrainingSelect = React.useCallback(() => {
        onConfirm(selectedPSP);
    }, [onConfirm, selectedPSP]);
    return (React.createElement(CoreDialog, { open: true, maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, "Select Property"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { p: 2, component: Grid, container: true, spacing: 4 },
                React.createElement(Grid, { item: true, xs: 12 }, psps && (React.createElement(CoreChoiceField, { variant: "standard", fullWidth: true, value: selectedPSP !== null && selectedPSP !== void 0 ? selectedPSP : "", onChoiceChange: selectPSP },
                    React.createElement(MenuItem, { value: "" }),
                    psps.map((psp) => {
                        var _a;
                        return (React.createElement(MenuItem, { key: psp.uid, value: psp.uid }, (_a = psp.fields["Property Address"]) !== null && _a !== void 0 ? _a : "Unknown Address"));
                    })))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "inherit", onClick: onCancel }, "Cancel"),
            React.createElement(Button, { onClick: onStartTrainingSelect, color: "primary", disabled: !isValid }, "Start Training PSP"))));
};
export default PSPCertificationDialog;
