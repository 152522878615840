import { createTheme } from "@mui/material/styles";
import memoize from "fast-memoize";
import numeral from "numeral";
import { getBaseThemeOptions } from "@app/common/grid/theme";
import config from "../../../../../../../../config.json";
export const DASHSCORE_LEVELS = config.dashscore_levels;
export const DASHSCORE_TABS = ["Scorecard", "Leaderboard"];
export const EXPECTED_COLOR = "rgb(182, 215, 168)";
export const ACCEPTABLE_COLOR = "rgb(255, 229, 153)";
export const UNACCEPTABLE_COLOR = "rgb(234, 153, 153)";
export const CURRENT_PCAS = [
    "Midtown Property Law PLLC",
    "Milestones Law",
    "Norwood Armstrong & Stokes PLLC",
    "Brady Law Firm PLLC",
];
export const getMetricScore = (val, redVal, greenVal) => {
    if (val == null || greenVal == null || redVal == null) {
        return null;
    }
    if (val >= greenVal) {
        return "Expected";
    }
    if (val > redVal) {
        return "Acceptable";
    }
    return "Unacceptable";
};
export const getSLAViolationsDescriptor = (val, redVal, greenVal) => {
    if (greenVal == null || redVal == null) {
        return null;
    }
    if (val == null) {
        return "Expected";
    }
    if (val <= greenVal) {
        return "Expected";
    }
    if (val < redVal) {
        return "Acceptable";
    }
    return "Unacceptable";
};
export const formatPercentage = (val) => {
    if (val == null) {
        return null;
    }
    return numeral(Math.round(val * 10000) / 100)
        .format("0,0.0%")
        .replace(".0", "");
};
export const formatDecimal = (val, decimalPlaces) => {
    if (val == null) {
        return null;
    }
    const format = `0,0.${"0".repeat(decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 1)}`;
    const replace = `.${"0".repeat(decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 1)}`;
    return numeral(Math.round(val * 100) / 100)
        .format(format)
        .replace(replace, "");
};
export const formatPercentageMemoized = memoize(formatPercentage);
export const formatDecimalMemoized = memoize(formatDecimal);
export const percentColumnRenderer = (value) => {
    return formatPercentageMemoized(value);
};
export const decimalColumnRenderer = (value) => {
    return formatDecimalMemoized(value);
};
export const getLeaderboardCellClassName = (params) => {
    var _a;
    const { field, row } = params;
    const val = params.value;
    return (_a = getMetricScore(val, row[`${field}__RedValue`], row[`${field}__GreenValue`])) !== null && _a !== void 0 ? _a : "";
};
export const getDashscoreLeaderboardGridThemeOptions = (parentTheme) => {
    var _a, _b, _c, _d, _e, _f;
    const base = getBaseThemeOptions(parentTheme);
    return Object.assign(Object.assign({}, base), { components: Object.assign(Object.assign({}, base.components), { MuiDataGrid: Object.assign(Object.assign({}, (_a = base.components) === null || _a === void 0 ? void 0 : _a.MuiDataGrid), { styleOverrides: Object.assign(Object.assign({}, (_c = (_b = base.components) === null || _b === void 0 ? void 0 : _b.MuiDataGrid) === null || _c === void 0 ? void 0 : _c.styleOverrides), { cell: Object.assign(Object.assign({}, (_f = (_e = (_d = base.components) === null || _d === void 0 ? void 0 : _d.MuiDataGrid) === null || _e === void 0 ? void 0 : _e.styleOverrides) === null || _f === void 0 ? void 0 : _f.cell), { "&.Expected": {
                            backgroundColor: EXPECTED_COLOR,
                        }, "&.Acceptable": {
                            backgroundColor: ACCEPTABLE_COLOR,
                        }, "&.Unacceptable": {
                            backgroundColor: UNACCEPTABLE_COLOR,
                        } }) }) }) }) });
};
export const dashscoreLeaderboardThemeProvider = (parentTheme) => {
    return createTheme(getDashscoreLeaderboardGridThemeOptions(parentTheme));
};
export const getDashscoreBreakdownText = (score, maxPoints) => {
    if (score == null) {
        return "-";
    }
    const maxPointsText = maxPoints == null ? "" : ` / ${maxPoints}`;
    return `${score}${maxPointsText} points`;
};
