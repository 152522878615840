class AppManager {
    constructor() {
        Object.defineProperty(this, "pageTitleComponent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    static get shared() {
        if (AppManager.instance == null) {
            AppManager.instance = new AppManager();
        }
        return this.instance;
    }
    get pageTitle() {
        if (this.pageTitleComponent) {
            return this.pageTitleComponent.state;
        }
        return null;
    }
    set pageTitle(attrs) {
        var _a;
        if (this.pageTitleComponent) {
            this.pageTitleComponent.setState({ title: attrs === null || attrs === void 0 ? void 0 : attrs.title, subtitle: attrs === null || attrs === void 0 ? void 0 : attrs.subtitle });
        }
        document.title = [[attrs === null || attrs === void 0 ? void 0 : attrs.title, attrs === null || attrs === void 0 ? void 0 : attrs.subtitle].filter((obj) => obj).join(" | "), "DASH"]
            .filter((obj) => obj)
            .join(" | ");
        document.description = [
            (_a = attrs === null || attrs === void 0 ? void 0 : attrs.description) !== null && _a !== void 0 ? _a : [attrs === null || attrs === void 0 ? void 0 : attrs.title, attrs === null || attrs === void 0 ? void 0 : attrs.subtitle].filter((obj) => obj).join(" | "),
            "DASH",
        ]
            .filter((obj) => obj)
            .join(" | ");
    }
}
Object.defineProperty(AppManager, "instance", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: null
});
export default AppManager;
