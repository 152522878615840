import * as React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Grid, InputAdornment, } from "@mui/material";
import CoreDialog from "@app/common/CoreDialog";
import { useListSlackChannels } from "@app/orval/api/slack";
import { INFINITE_CACHE_PARAMS } from "@app/orval/config";
const SlackChannelDialog = (props) => {
    var _a, _b;
    const { DialogProps, onClose, onConfirm } = props;
    const [selectedChannelId, setSelectedChannelId] = React.useState(null);
    const listSlackChannelsApi = useListSlackChannels({ exclude_archived: true, types: "private_channel" }, INFINITE_CACHE_PARAMS);
    const slackChannels = (_b = (_a = listSlackChannelsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    const isValid = React.useMemo(() => {
        return !!selectedChannelId;
    }, [selectedChannelId]);
    const handleChannelChanged = React.useCallback((e) => {
        setSelectedChannelId(e.target.value);
    }, [setSelectedChannelId]);
    const handleConfirm = React.useCallback(() => {
        const selectedChannel = slackChannels === null || slackChannels === void 0 ? void 0 : slackChannels.find((channel) => channel.id === selectedChannelId);
        onConfirm(selectedChannel);
    }, [onConfirm, selectedChannelId, slackChannels]);
    return (React.createElement(CoreDialog, Object.assign({ maxWidth: "sm", fullWidth: true }, DialogProps),
        React.createElement(DialogTitle, null, "Add user to channel"),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 2, direction: "column", wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(TextField, { select: true, fullWidth: true, variant: "standard", slotProps: {
                            input: {
                                startAdornment: React.createElement(InputAdornment, { position: "start" }, "Type"),
                            },
                        }, value: selectedChannelId !== null && selectedChannelId !== void 0 ? selectedChannelId : "", onChange: handleChannelChanged }, (slackChannels !== null && slackChannels !== void 0 ? slackChannels : []).map((channel) => (React.createElement(MenuItem, { key: channel.id, value: channel.id }, channel.name))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose, tabIndex: -1 }, "Cancel"),
            React.createElement(Button, { onClick: handleConfirm, disabled: !isValid || !slackChannels, tabIndex: -1 }, "OK"))));
};
export default SlackChannelDialog;
