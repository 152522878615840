import * as React from "react";
import { Box, Paper } from "@mui/material";
import { TwilioCallContext } from "@app/entrypoints/brokerage/context/twilio-calls/TwilioCallContext";
import TwilioDialWidget from "@app/entrypoints/brokerage/context/twilio-calls/TwilioDialWidget";
const Dialer = () => {
    const { closeDialWidget, contactSuggestions } = React.useContext(TwilioCallContext);
    return (React.createElement(Paper, { elevation: 4, style: { pointerEvents: "auto" } },
        React.createElement(Box, { p: 2 },
            React.createElement(TwilioDialWidget, { contacts: contactSuggestions, onCallPressed: closeDialWidget, onClose: closeDialWidget, containerProps: { style: { maxWidth: "360px" } } }))));
};
export default Dialer;
