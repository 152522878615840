import * as React from "react";
import { CopyAll } from "@mui/icons-material";
import { Button, Grid2, Typography } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { enqueueSnackbar } from "notistack";
import zillowIcon from "@app/assets/img/zillow.png";
import CoreLink from "@app/common/CoreLink";
import RouterLink from "@app/common/RouterLink";
import { copyToClipboard, getFullName } from "@app/util/Utils";
import { getPremierAgentUrl } from "../../crm/components/box-view/ActionsMenu/PremierAgentMenuSection";
dayjs.extend(utc);
dayjs.extend(timezone);
const CRMChangesReportBox = (props) => {
    var _a, _b, _c, _d;
    const { client, comments, created_at, current_timeframe, currentTimeframeChanged, emails, fieldValueChanges, isNew, name, premierAgentContactId, producer, producerChanges, rejected_tags, stage, stageChanges, uid, } = props;
    const relevantStageChanges = stageChanges === null || stageChanges === void 0 ? void 0 : stageChanges.filter((x) => x.previous_value && x.new_value);
    const uniqueEmails = emails === null || emails === void 0 ? void 0 : emails.filter((x, i, all) => {
        return !all.some((y, j) => y.subject === x.subject && i < j);
    });
    const copyText = React.useCallback(() => {
        var _a;
        const stageChangesText = (_a = relevantStageChanges === null || relevantStageChanges === void 0 ? void 0 : relevantStageChanges.map((x) => { var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k; return `${getFullName((_b = (_a = x.userstamp) === null || _a === void 0 ? void 0 : _a.first_name) !== null && _b !== void 0 ? _b : null, (_d = (_c = x.userstamp) === null || _c === void 0 ? void 0 : _c.last_name) !== null && _d !== void 0 ? _d : null)} changed the stagefrom ${(_g = (_f = (_e = x.previous_value) === null || _e === void 0 ? void 0 : _e.stage) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : "n/a"} to ${(_k = (_j = (_h = x.new_value) === null || _h === void 0 ? void 0 : _h.stage) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : "n/a"} on ${dayjs(x.timestamp).tz("America/New_York").format("dddd, MMMM D, YYYY h:mm A")} Eastern Time`; }).join("\n\t")) !== null && _a !== void 0 ? _a : "";
        const commentsText = comments === null || comments === void 0 ? void 0 : comments.map((x) => { var _a, _b, _c, _d; return `${getFullName((_b = (_a = x.edits[0].author) === null || _a === void 0 ? void 0 : _a.first_name) !== null && _b !== void 0 ? _b : null, (_d = (_c = x.edits[0].author) === null || _c === void 0 ? void 0 : _c.last_name) !== null && _d !== void 0 ? _d : null)} added a comment: ${x.edits[0].content.replace(/\@\[(.*?)]\(.*?\)/g, "@$1")}`; });
        const emailText = uniqueEmails === null || uniqueEmails === void 0 ? void 0 : uniqueEmails.map((x) => x.subject);
        const text = [];
        if (stageChangesText) {
            text.push(`CHANGES:\n\t${stageChangesText}`);
        }
        if (commentsText) {
            text.push(`NEW COMMENTS:\n\t${commentsText.join("\n\t")}`);
        }
        if (emailText) {
            text.push(`NEW EMAILS:\n\t${emailText.join("\n\t")}`);
        }
        copyToClipboard(text.join("\n"));
        enqueueSnackbar(`Info for ${name} copied to clipboard`, { variant: "success" });
    }, [comments, name, relevantStageChanges, uniqueEmails]);
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", style: { background: isNew ? "pink" : undefined }, spacing: 1 },
        isNew && (React.createElement(Grid2, { pb: 2 },
            React.createElement(Typography, { variant: "h6" }, "NEW BOX"))),
        React.createElement(Grid2, null,
            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                React.createElement(Grid2, null,
                    React.createElement(Grid2, { container: true, spacing: 1, alignItems: "center" },
                        React.createElement(Grid2, null,
                            React.createElement(RouterLink, { to: `/boxes/${uid}` },
                                React.createElement(Typography, { variant: "body2" }, name || "(no name)"))),
                        premierAgentContactId && (React.createElement(Grid2, null,
                            React.createElement(CoreLink, { href: getPremierAgentUrl(premierAgentContactId), target: "_blank" },
                                React.createElement("img", { src: zillowIcon, alt: "Zillow", height: 20 })))))),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Created: ",
                        dayjs.utc(created_at).tz("America/New_York").format("dddd, MMMM D, YYYY h:mm A"),
                        " Eastern Time")),
                React.createElement(Grid2, { style: { background: !isNew && producerChanges && producerChanges.length > 0 ? "lightblue" : undefined } },
                    React.createElement(Typography, { variant: "body2" },
                        "Producer: ",
                        getFullName((_a = producer === null || producer === void 0 ? void 0 : producer.first_name) !== null && _a !== void 0 ? _a : null, (_b = producer === null || producer === void 0 ? void 0 : producer.last_name) !== null && _b !== void 0 ? _b : null))),
                React.createElement(Grid2, { style: { background: !isNew && stageChanges && stageChanges.length > 0 ? "lightblue" : undefined } },
                    React.createElement(Typography, { variant: "body2" },
                        "Stage: ",
                        stage.name)),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Client #1 Email: ", (_c = client === null || client === void 0 ? void 0 : client.client.email) !== null && _c !== void 0 ? _c : "")),
                React.createElement(Grid2, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Client #1 Phone: ", (_d = client === null || client === void 0 ? void 0 : client.client.phone) !== null && _d !== void 0 ? _d : "")),
                React.createElement(Grid2, { style: {
                        background: !isNew && currentTimeframeChanged ? "yellow" : undefined,
                    } },
                    React.createElement(Typography, { variant: "body2" },
                        "Timeframe: ", current_timeframe !== null && current_timeframe !== void 0 ? current_timeframe : "")),
                ((Array.isArray(rejected_tags) && rejected_tags.length > 0) ||
                    (!Array.isArray(rejected_tags) && rejected_tags)) && (React.createElement(Grid2, { style: {
                        background: !isNew && (fieldValueChanges === null || fieldValueChanges === void 0 ? void 0 : fieldValueChanges.some((x) => x.field_name === "Rejected Tags")) ? "yellow" : undefined,
                    } },
                    React.createElement(Typography, { variant: "body2" },
                        "Rejected Tags: ",
                        Array.isArray(rejected_tags) ? rejected_tags.join(", ") : rejected_tags))),
                relevantStageChanges && relevantStageChanges.length > 0 && (React.createElement(Grid2, { style: { background: !isNew ? "yellow" : undefined } },
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "CHANGES:")),
                        React.createElement(Grid2, { pl: 4 },
                            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 }, relevantStageChanges.map((x) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                                return (React.createElement(Grid2, { key: (_b = (_a = x.new_value) === null || _a === void 0 ? void 0 : _a.version_uid) !== null && _b !== void 0 ? _b : "" },
                                    React.createElement(Typography, { variant: "body2" },
                                        getFullName((_d = (_c = x.userstamp) === null || _c === void 0 ? void 0 : _c.first_name) !== null && _d !== void 0 ? _d : null, (_f = (_e = x.userstamp) === null || _e === void 0 ? void 0 : _e.last_name) !== null && _f !== void 0 ? _f : null),
                                        " changed the stage from ", (_j = (_h = (_g = x.previous_value) === null || _g === void 0 ? void 0 : _g.stage) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : "n/a",
                                        " to ", (_m = (_l = (_k = x.new_value) === null || _k === void 0 ? void 0 : _k.stage) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : "n/a",
                                        " on",
                                        " ",
                                        dayjs(x.timestamp).tz("America/New_York").format("dddd, MMMM D, YYYY h:mm A"),
                                        " Eastern Time")));
                            })))))),
                comments && comments.length > 0 && (React.createElement(Grid2, { style: { background: !isNew ? "yellow" : undefined } },
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "NEW COMMENTS:")),
                        React.createElement(Grid2, { pl: 4 },
                            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 }, comments.map((x) => {
                                var _a, _b, _c, _d;
                                return (React.createElement(Grid2, { key: x.uid, container: true, direction: "row", spacing: 1 },
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body2" },
                                            getFullName((_b = (_a = x.edits[0].author) === null || _a === void 0 ? void 0 : _a.first_name) !== null && _b !== void 0 ? _b : null, (_d = (_c = x.edits[0].author) === null || _c === void 0 ? void 0 : _c.last_name) !== null && _d !== void 0 ? _d : null),
                                            " ",
                                            "added a comment:")),
                                    React.createElement(Grid2, null,
                                        React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic" } }, x.edits[0].content.replace(/\@\[(.*?)]\(.*?\)/g, "@$1")))));
                            })))))),
                uniqueEmails && uniqueEmails.length > 0 && (React.createElement(Grid2, { style: { background: !isNew ? "yellow" : undefined } },
                    React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "NEW EMAILS:")),
                        React.createElement(Grid2, { pl: 4 },
                            React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 0 }, uniqueEmails.map((x) => (React.createElement(Grid2, { key: x.id, container: true, direction: "row", spacing: 1 },
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "body2" }, x.subject)),
                                emails.filter((y) => y.subject === x.subject).length > 1 && (React.createElement(Grid2, null,
                                    React.createElement(Typography, { variant: "body2", style: { fontStyle: "italic", color: "#888" } },
                                        emails.filter((y) => y.subject === x.subject).length,
                                        " messages"))))))))))))),
        React.createElement(Grid2, null,
            React.createElement(Button, { size: "small", variant: "text", startIcon: React.createElement(CopyAll, { fontSize: "small" }), color: "inherit", tabIndex: -1, onClick: copyText }, "Copy to clipboard"))));
};
export default CRMChangesReportBox;
