import * as React from "react";
import { Typography } from "@mui/material";
/**
 * Places a text adornment on MAtrix fields.
 * @param props
 * @returns Typography with passed in text
 */
const FormFieldTextAdornment = (props) => {
    const { placement, text } = props;
    const padding = placement === "end" ? "3px 0px 7px 4px" : "3px 4px 7px 0px";
    return React.createElement(Typography, { style: { fontSize: "11px", padding } }, text);
};
export default FormFieldTextAdornment;
