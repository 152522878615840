var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { TableCell } from "@mui/material";
const DashFixedWidthTableCell = (props) => {
    const { style, width } = props, allOtherProps = __rest(props, ["style", "width"]);
    return (React.createElement(TableCell, Object.assign({}, allOtherProps, { style: Object.assign({ top: "0px", left: "0px", zIndex: 100, background: "white", position: "sticky", width: width || "50px", minWidth: width || "50px" }, style) })));
};
DashFixedWidthTableCell.defaultProps = {
    width: null,
};
export default DashFixedWidthTableCell;
