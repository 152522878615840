import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Check, Error, FilterList, HighlightOff, Launch, Refresh, Stop } from "@mui/icons-material";
import { Fab, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import styled from "styled-components";
import streakIcon from "@app/assets/img/streak.png";
import CoreError from "@app/common/CoreError";
import CoreLink from "@app/common/CoreLink";
import CoreLoading from "@app/common/CoreLoading";
import dayjs_ from "@app/common/dayjs";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { withDialog } from "@app/hoc/withDialog";
import { withFilter } from "@app/hoc/withFilter";
import API from "@app/util/api";
import { getStreakLinkForBox } from "@app/util/Utils";
const StyledWrapper = styled("div") `
    & .actions {
        display: none;
    }
    &:hover .actions {
        display: inline-flex;
    }
`;
class MailScheduleExecutionsTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                executions: null,
                templates: null,
                pipelines: null,
                error: null,
                query: {},
                stoppingExecutions: new Set(),
                count: null,
                page: null,
                rowsPerPage: null,
            }
        });
        Object.defineProperty(this, "pipelinesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "templatesAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "logsAbortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "getTemplates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    templates: null,
                }, () => {
                    var _a;
                    (_a = this.templatesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.templatesAbortController = new AbortController();
                    API.mailScheduleTemplates.list({
                        onSuccess: (result) => {
                            this.setState({
                                templates: result.reduce((dict, template) => (Object.assign(Object.assign({}, dict), { [template.uid]: template })), {}),
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.templatesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "getPipelines", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    pipelines: null,
                }, () => {
                    var _a;
                    (_a = this.pipelinesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.pipelinesAbortController = new AbortController();
                    API.streakPipelines.list({
                        onSuccess: (result) => {
                            this.setState({
                                pipelines: result.reduce((dict, pipeline) => (Object.assign(Object.assign({}, dict), { [pipeline.key]: pipeline })), {}),
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.pipelinesAbortController.signal,
                        },
                        shouldUseCache: true,
                        shouldUpdateCache: true,
                    });
                });
            }
        });
        Object.defineProperty(this, "getExecutionLogs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    executions: null,
                    error: null,
                }, () => {
                    var _a;
                    const { query } = this.state;
                    (_a = this.logsAbortController) === null || _a === void 0 ? void 0 : _a.abort();
                    this.logsAbortController = new AbortController();
                    API.mailScheduleScriptExecutions.listExecutions({
                        query: pickBy(query, (val) => val != null),
                        onSuccess: (result) => {
                            this.setState({
                                executions: result.results,
                                rowsPerPage: result.limit,
                                page: Math.floor(result.offset / result.limit),
                                count: result.count,
                                error: null,
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState({
                                    error,
                                });
                            }
                        },
                        options: {
                            signal: this.logsAbortController.signal,
                        },
                    });
                });
            }
        });
        /**
         * Stop the Mail Schedule execution with the given `uid`
         * @param executionUid the execution `uid`
         * @returns
         */
        Object.defineProperty(this, "stopExecution", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (executionUid) => () => {
                this.setState((prevState) => {
                    const { stoppingExecutions } = prevState;
                    stoppingExecutions.add(executionUid);
                    return {
                        stoppingExecutions,
                    };
                }, () => {
                    API.mailScheduleScriptExecutions.stopExecution(executionUid, {
                        onSuccess: (result) => {
                            this.setState((prevState) => {
                                var _a;
                                const { stoppingExecutions } = prevState;
                                stoppingExecutions.delete(executionUid);
                                const executionIndx = (_a = prevState.executions) === null || _a === void 0 ? void 0 : _a.findIndex((e) => e.uid === executionUid);
                                const executions = executionIndx != null
                                    ? [
                                        ...prevState.executions.slice(0, executionIndx),
                                        result,
                                        ...prevState.executions.slice(executionIndx + 1, prevState.executions.length),
                                    ]
                                    : prevState.executions;
                                return {
                                    stoppingExecutions,
                                    executions,
                                };
                            });
                        },
                        onError: (error) => {
                            if (error.name !== "AbortError") {
                                this.setState((prevState) => {
                                    const { stoppingExecutions } = prevState;
                                    stoppingExecutions.delete(executionUid);
                                    return {
                                        stoppingExecutions,
                                    };
                                });
                                this.props.showErrorDialog(error);
                            }
                        },
                    });
                });
            }
        });
    }
    static getDerivedStateFromProps(nextProps) {
        var _a, _b, _c, _d, _e;
        const queryParams = new URLSearchParams(nextProps.location.search);
        const query = {
            limit: queryParams.get("limit") ? numeral(queryParams.get("limit")).value() : 100,
            offset: queryParams.get("offset") ? numeral(queryParams.get("offset")).value() : 0,
            agent_email: (_a = queryParams.get("agent_email")) !== null && _a !== void 0 ? _a : undefined,
            agent_email_isnull: queryParams.get("agent_email_isnull") === "true" || undefined,
            template_uid: (_b = queryParams.get("template_uid")) !== null && _b !== void 0 ? _b : undefined,
            template_uid_isnull: queryParams.get("template_uid_isnull") === "true" || undefined,
            pipeline_key: (_c = queryParams.get("pipeline_key")) !== null && _c !== void 0 ? _c : undefined,
            pipeline_key_isnull: queryParams.get("pipeline_key_isnull") === "true" || undefined,
            status: ((_d = queryParams.get("status")) !== null && _d !== void 0 ? _d : undefined),
            box_key: (_e = queryParams.get("box_key")) !== null && _e !== void 0 ? _e : undefined,
        };
        return {
            query,
        };
    }
    componentDidMount() {
        this.getPipelines();
        this.getTemplates();
        this.getExecutionLogs();
    }
    componentDidUpdate(_, prevState) {
        if (JSON.stringify(prevState.query) !== JSON.stringify(this.state.query)) {
            this.getExecutionLogs();
        }
    }
    componentWillUnmount() {
        var _a, _b, _c;
        (_a = this.pipelinesAbortController) === null || _a === void 0 ? void 0 : _a.abort();
        (_b = this.templatesAbortController) === null || _b === void 0 ? void 0 : _b.abort();
        (_c = this.logsAbortController) === null || _c === void 0 ? void 0 : _c.abort();
    }
    render() {
        const { count, error, executions, page, pipelines, rowsPerPage, stoppingExecutions, templates } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        const rows = (executions !== null && executions !== void 0 ? executions : []).map((e) => (Object.assign(Object.assign({}, e), { template: e.template_uid && templates && templates[e.template_uid] ? templates[e.template_uid].name : e.template_uid, pipeline: e.pipeline_key && pipelines && pipelines[e.pipeline_key] ? pipelines[e.pipeline_key].name : e.pipeline_key })));
        const columns = [
            {
                name: "uid",
                label: " ",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const log = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                        return (React.createElement(Grid, { container: true, spacing: 1, style: { width: "calc(100% + 10px)" } },
                            React.createElement(Grid, { item: true },
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Open logs") },
                                    React.createElement(IconButton, { size: "small", component: RouterLink, to: `/mailschedule/logs/${value}` },
                                        React.createElement(Launch, { fontSize: "small" })))),
                            !log.end_timestamp &&
                                !log.error_message &&
                                dayjs_(log.start_timestamp).isAfter(dayjs_(new Date()).add(-1, "hour")) && (React.createElement(Grid, { item: true },
                                React.createElement(Tooltip, { title: React.createElement(Typography, null, "Stop script execution") },
                                    React.createElement("span", null,
                                        React.createElement(IconButton, { size: "small", onClick: this.stopExecution(value), disabled: stoppingExecutions.has(value) },
                                            React.createElement(HighlightOff, { fontSize: "small" }))))))));
                    },
                    setCellProps: () => ({
                        style: {
                            width: "50px",
                            minWidth: "50px",
                        },
                    }),
                },
            },
            {
                name: "start_timestamp",
                label: "Start",
                options: {
                    customBodyRender: (value) => {
                        if (value && dayjs_(value).isValid()) {
                            return dayjs_(value).format("dddd, MMMM D, YYYY h:mm:ss A");
                        }
                        return value;
                    },
                },
            },
            {
                name: "end_timestamp",
                label: "End",
                options: {
                    customBodyRender: (value) => {
                        if (value && dayjs_(value).isValid()) {
                            return dayjs_(value).format("dddd, MMMM D, YYYY h:mm:ss A");
                        }
                        return value;
                    },
                },
            },
            {
                name: "error_message",
                label: "Status",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        let status;
                        let icon;
                        if (value) {
                            status = "Errored Out";
                            icon = React.createElement(Error, { style: { color: "red" } });
                        }
                        else {
                            const log = rows[tableMeta.currentTableData[tableMeta.rowIndex].index];
                            if (log.stopped_timestamp) {
                                status = "Stopped";
                                icon = React.createElement(Stop, { style: { color: "#888" } });
                            }
                            else if (!log.end_timestamp && dayjs_(log.start_timestamp).isAfter(dayjs_().add(-1, "hour"))) {
                                status = "Running";
                                icon = React.createElement(CoreLoading, { size: 16 });
                            }
                            else {
                                status = "Finished";
                                icon = React.createElement(Check, { style: { color: "green" } });
                            }
                        }
                        return (React.createElement(Grid, { container: true, spacing: 1 },
                            React.createElement(Grid, { item: true }, value ? React.createElement(Tooltip, { title: React.createElement(Typography, { variant: "body2" }, value) }, icon) : icon),
                            React.createElement(Grid, { item: true, style: { flex: 1, marginTop: "auto", marginBottom: "auto" } }, status)));
                    },
                    setCellProps: () => ({
                        style: {
                            width: "100px",
                            minWidth: "100px",
                        },
                    }),
                },
            },
            {
                name: "num_templates_sent",
                label: "# Processed",
            },
            {
                name: "num_templates_errored_out",
                label: "# Errored Out",
            },
            {
                name: "pipeline_key",
                label: "Pipeline",
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            return (React.createElement(Link, { href: `https://streak.com/a/pipelines/${value}`, target: "_blank" }, (pipelines === null || pipelines === void 0 ? void 0 : pipelines[value]) ? pipelines[value].name : value));
                        }
                        return value;
                    },
                },
            },
            {
                name: "agent_email",
                label: "Agent",
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            return (React.createElement(Link, { href: `mailto:${value}`, target: "_blank" }, value));
                        }
                        return value;
                    },
                },
            },
            {
                name: "box_key",
                label: "Box",
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            return (React.createElement(Tooltip, { title: "Open Streak box" },
                                React.createElement(IconButton, { size: "small", component: CoreLink, target: "_blank", href: getStreakLinkForBox(value) },
                                    React.createElement("img", { src: streakIcon, alt: "Streak", width: 16, height: 16 }))));
                        }
                        return value;
                    },
                },
            },
            {
                name: "template_uid",
                label: "Template",
                options: {
                    customBodyRender: (value) => {
                        const template = templates === null || templates === void 0 ? void 0 : templates[value];
                        return React.createElement(RouterLink, { to: `/mailschedule/templates/${value}` }, template ? template.name : value);
                    },
                },
            },
        ];
        const options = {
            responsive: "standard",
            selectableRows: "none",
            download: false,
            print: false,
            filter: false,
            pagination: true,
            sort: false,
            viewColumns: false,
            search: false,
            serverSide: true,
            count: executions ? count : undefined,
            page: executions ? page : undefined,
            rowsPerPage: executions ? rowsPerPage : undefined,
            rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
            onChangePage: (currentPage) => {
                this.props.updateFilter("offset", rowsPerPage * currentPage);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                this.props.updateFilter("limit", numberOfRows);
            },
        };
        return (React.createElement(StyledWrapper, { style: { height: "100%", position: "relative" } },
            executions ? (React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 2 })) : (React.createElement(CoreLoading, null)),
            React.createElement("div", { className: "actions", style: {
                    position: "absolute",
                    top: "0px",
                    right: "16px",
                    zIndex: 100,
                } },
                React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: "Refresh" },
                            React.createElement("span", null,
                                React.createElement(Fab, { onClick: this.getExecutionLogs, size: "small", disabled: !executions },
                                    React.createElement(Refresh, { fontSize: "small" }))))),
                    this.props.onSidebarToggle && (React.createElement(Grid, { item: true },
                        React.createElement(Tooltip, { title: "Show/hide filters" },
                            React.createElement(Fab, { onClick: this.props.onSidebarToggle, size: "small" },
                                React.createElement(FilterList, { fontSize: "small" })))))))));
    }
}
export default withDialog(withFilter(MailScheduleExecutionsTable));
