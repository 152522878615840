import * as React from "react";
import pickBy from "lodash/pickBy";
import numeral from "numeral";
import { countClosedContracts } from "@app/orval/api/agent-dashboard";
import SmallMetricBox from "./widgets/SmallMetricBox";
import useAgentDashboardFilters from "../useAgentDashboardFilters";
import usePostQuery from "../usePostQuery";
const ClosedContracts = () => {
    var _a;
    const agentDashboardFilters = useAgentDashboardFilters();
    const { fromDate, selectedUserUids, toDate } = agentDashboardFilters;
    const queryParams = pickBy({ from_date: fromDate, to_date: toDate, agent_uids: [...selectedUserUids] }, (val) => val != null);
    const countClosedContractsApi = usePostQuery(countClosedContracts, queryParams);
    const units = (_a = countClosedContractsApi.data) === null || _a === void 0 ? void 0 : _a.data.value;
    const value = units != null ? numeral(units).format("0,0") : undefined;
    return (React.createElement(SmallMetricBox, { title: "Closed Contracts", value: value, isLoading: countClosedContractsApi.isFetching, error: countClosedContractsApi.error, description: `
      The total number of transactions in Buyer and Seller Contracts where the Closing Date | COE falls within the specified date range.
      Does not include Terminated transactions.` }));
};
export default ClosedContracts;
