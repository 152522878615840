import * as React from "react";
import { IconButton } from "@mui/material";
import CorePopover from "./CorePopover";
import UserSelect from "./UserSelect";
const UserSelectIconButton = (props) => {
    const { children, onSelect, selectedUserUids } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPicker = React.useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [setAnchorEl]);
    const closePopover = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);
    const onClose = React.useCallback(() => {
        closePopover();
    }, [closePopover]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, Object.assign({}, props.IconButtonProps, { onClick: openPicker }), children),
        anchorEl && (React.createElement(CorePopover, { open: true, anchorEl: anchorEl, onClose: onClose },
            React.createElement(UserSelect, { selectedUserUids: selectedUserUids, onSelect: onSelect })))));
};
export default UserSelectIconButton;
