import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import SMSWrapper from "./SMSModule";
const SMSPage = () => {
    useDocumentTitle("Messaging");
    const [searchParams] = useSearchParams();
    const twilioPhoneNumber = searchParams.get("twilio_phone");
    const externalPhoneNumber = searchParams.get("external_phone");
    const showConversation = searchParams.get("show_conversation") === "true";
    return (React.createElement(SMSWrapper, { selectedTwilioPhoneNumber: twilioPhoneNumber, selectedExternalPhoneNumber: externalPhoneNumber, showConversation: showConversation }));
};
export default SMSPage;
