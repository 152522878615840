import * as React from "react";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import usePostQuery from "@app/entrypoints/brokerage/features/dashboards/agent-dashboard/usePostQuery";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useSession from "@app/hooks/useSession";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListOffices } from "@app/orval/api/offices";
import { useListStages } from "@app/orval/api/stages";
import { useListTeams } from "@app/orval/api/teams";
import { searchUsersBasic } from "@app/orval/api/users";
import { useAddView, useDeleteView, useGetView, useListBooleanFieldComparators, useListChoiceFieldComparators, useListDateFieldComparators, useListDateFilterUnits, useListDateRanges, useListNumberFieldComparators, useListStageComparators, useListTextFieldComparators, useListTimestampComparators, useListTimestampFilterUnits, useListUserComparators, useListTimestampTypes, useListUserFilterTypes, useUpdateView, useListMultipleChoiceFieldComparators, } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { removeUndefinedRecursive } from "@app/util/Utils";
import { getViewUrl } from "../../../views/utils";
import { SpreadsheetContext } from "../SpreadsheetContext";
import SavedViewSaveAsDialog from "./components/SavedViewSaveAsDialog";
const SavedViewEditorContext = React.createContext({
    uid: null,
    entityType: null,
    view: null,
    initialData: null,
    setView: () => { },
    deleteView: () => { },
    addFilter: () => { },
    isDeleting: false,
    saveView: () => { },
    saveAs: () => { },
    testView: () => { },
    clearFilters: () => { },
    createNewView: () => { },
    cancel: () => { },
    close: () => { },
    isSaving: false,
    fields: null,
    stages: null,
    textFieldComparators: null,
    numberFieldComparators: null,
    dateFieldComparators: null,
    choiceFieldComparators: null,
    multipleChoiceFieldComparators: null,
    booleanFieldComparators: null,
    stageComparators: null,
    timestampComparators: null,
    userComparators: null,
    dateFilterUnits: null,
    timestampTypes: null,
    userFilterTypes: null,
    timestampFilterUnits: null,
    dateRanges: null,
    offices: null,
    teams: null,
    users: null,
    canSave: false,
    canTest: false,
    isLoading: false,
    isEditing: true,
    setIsEditing: () => { },
    canEdit: true,
    openSaveAsDialog: () => { },
    closeSaveAsDialog: () => { },
});
export const SavedViewEditorContextProvider = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const { defaultValue, entityType, onCancel, onSaveComplete, uid } = props;
    const errorDialog = useErrorDialog();
    const session = useSession();
    const updateSearch = useUpdateSearch();
    const navigate = useNavigate();
    const spreadsheetContext = React.useContext(SpreadsheetContext);
    const getDefaultView = React.useCallback(() => {
        var _a, _b;
        return Object.assign({ name: "", purpose: null, filter_groups: [
                {
                    uid: uuidv4().replace(/-/g, ""),
                    is_match_all: true,
                    filters: [],
                    parent_uid: null,
                },
            ], sorting: [], grouping: null, sharing: {
                all_users: {
                    is_shared: !!((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])),
                    is_editable: false,
                    is_pinned: false,
                },
                roles: [],
                offices: [],
                users: ((_b = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _b === void 0 ? void 0 : _b.uid) && !session.viewingAsUser.hasRole(["Superadmin", "Admin"])
                    ? [{ user: session.viewingAsUser.uid, is_shared: true, is_editable: true, is_pinned: false }]
                    : [],
            } }, defaultValue);
    }, [session === null || session === void 0 ? void 0 : session.viewingAsUser, defaultValue]);
    const [initialData, setInitialData] = React.useState(getDefaultView());
    const [view, setView] = React.useState(getDefaultView());
    const [isEditing, setIsEditing] = React.useState(true);
    const [isSaveAsDialogOpen, setIsSaveAsDialogOpen] = React.useState(false);
    const getViewApi = useGetView(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: false } });
    const createViewApi = useAddView();
    const updateViewApi = useUpdateView();
    const deleteViewApi = useDeleteView();
    const updateViewFromApi = React.useCallback((data) => {
        var _a, _b;
        const formData = Object.assign(Object.assign({}, data), { 
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            purpose: (_b = (_a = data.purpose) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : null, filter_groups: data.filter_groups.map((fg) => (Object.assign(Object.assign({}, fg), { filters: fg.filters.map((f) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h;
                    return (Object.assign(Object.assign({}, f), { text_filter: f.text_filter
                            ? Object.assign(Object.assign({}, f.text_filter), { lhs_field: f.text_filter.lhs_field.uid, rhs_field: (_a = f.text_filter.rhs_field) === null || _a === void 0 ? void 0 : _a.uid }) : undefined, number_filter: f.number_filter
                            ? Object.assign(Object.assign({}, f.number_filter), { lhs_field: f.number_filter.lhs_field.uid, rhs_field: (_b = f.number_filter.rhs_field) === null || _b === void 0 ? void 0 : _b.uid }) : undefined, boolean_filter: f.boolean_filter
                            ? Object.assign(Object.assign({}, f.boolean_filter), { lhs_field: f.boolean_filter.lhs_field.uid, rhs_field: (_c = f.boolean_filter.rhs_field) === null || _c === void 0 ? void 0 : _c.uid }) : undefined, date_filter: f.date_filter
                            ? Object.assign(Object.assign({}, f.date_filter), { lhs_field: f.date_filter.lhs_field.uid, rhs_field: (_d = f.date_filter.rhs_field) === null || _d === void 0 ? void 0 : _d.uid }) : undefined, choice_filter: f.choice_filter
                            ? Object.assign(Object.assign({}, f.choice_filter), { lhs_field: f.choice_filter.lhs_field.uid }) : undefined, multiple_choice_filter: f.multiple_choice_filter
                            ? Object.assign(Object.assign({}, f.multiple_choice_filter), { lhs_field: f.multiple_choice_filter.lhs_field.uid }) : undefined, stage_filter: f.stage_filter
                            ? Object.assign(Object.assign({}, f.stage_filter), { rhs_stage: (_e = f.stage_filter.rhs_stage) === null || _e === void 0 ? void 0 : _e.uid, rhs_stages: (_f = f.stage_filter.rhs_stages) === null || _f === void 0 ? void 0 : _f.map((x) => x.uid) }) : undefined, timestamp_filter: f.timestamp_filter
                            ? Object.assign(Object.assign({}, f.timestamp_filter), { rhs_field: (_g = f.timestamp_filter.rhs_field) === null || _g === void 0 ? void 0 : _g.uid }) : undefined, user_filter: f.user_filter
                            ? Object.assign(Object.assign({}, f.user_filter), { rhs_values: f.user_filter.rhs_values }) : undefined, office_filter: f.office_filter
                            ? Object.assign(Object.assign({}, f.office_filter), { rhs_values: (_h = f.office_filter.rhs_values) === null || _h === void 0 ? void 0 : _h.map((x) => x.uid) }) : undefined, uid: uuidv4().replace(/-/g, "") }));
                }) }))) });
        setView(formData);
        setInitialData(formData);
    }, [setView, setInitialData]);
    React.useEffect(() => {
        if (uid) {
            setView(null);
            setInitialData(null);
            getViewApi
                .refetch()
                .then((result) => {
                updateViewFromApi(result.data.data);
            })
                .catch(errorDialog);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    // by default, if we open an existing view, we are not in "edit" mode...
    // generally the user will just want to see the boxes within that view
    React.useEffect(() => {
        if (uid) {
            setIsEditing(false);
        }
    }, [uid]);
    // clear the existing query filter when we open or close the view editor
    React.useEffect(() => {
        if (spreadsheetContext) {
            spreadsheetContext.setFilter(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const canEdit = React.useMemo(() => {
        var _a, _b, _c, _d;
        // new views are always editable
        if (!uid) {
            return true;
        }
        // superadmins and admins can edit any view
        if ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"])) {
            return true;
        }
        // user must have permission to edit existing views
        const permissions = view === null || view === void 0 ? void 0 : view.sharing;
        if (permissions === null || permissions === void 0 ? void 0 : permissions.all_users.is_editable) {
            return true;
        }
        if (((_b = permissions === null || permissions === void 0 ? void 0 : permissions.roles) !== null && _b !== void 0 ? _b : [])
            .filter((x) => x.is_editable)
            .some((x) => { var _a, _b; return ((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.roles) !== null && _b !== void 0 ? _b : []).includes(x.role); })) {
            return true;
        }
        if (((_c = permissions === null || permissions === void 0 ? void 0 : permissions.users) !== null && _c !== void 0 ? _c : []).filter((x) => x.is_editable).some((x) => { var _a; return ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.uid) === x.user; })) {
            return true;
        }
        if (((_d = permissions === null || permissions === void 0 ? void 0 : permissions.offices) !== null && _d !== void 0 ? _d : [])
            .filter((x) => x.is_editable)
            .some((x) => { var _a, _b; return ((_b = (_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.office) === null || _b === void 0 ? void 0 : _b.uid) === x.office; })) {
            return true;
        }
        return false;
    }, [uid, view === null || view === void 0 ? void 0 : view.sharing, session === null || session === void 0 ? void 0 : session.viewingAsUser]);
    const canTest = React.useMemo(() => {
        return (
        // view has to be loaded
        view !== null &&
            // must have some filter groups present
            view.filter_groups.length > 0 &&
            // each filter group has to have at least one filter or a child filter group
            !view.filter_groups.find((x) => x.filters.length === 0 && !view.filter_groups.find((fg) => fg.parent_uid === x.uid)) &&
            // each filter must have a valid type
            !view.filter_groups
                .map((fg) => fg.filters)
                .flat()
                .find((f) => !f.boolean_filter &&
                !f.choice_filter &&
                !f.date_filter &&
                !f.multiple_choice_filter &&
                !f.number_filter &&
                !f.stage_filter &&
                !f.notes_filter &&
                !f.text_filter &&
                !f.timestamp_filter &&
                !f.user_filter &&
                !f.view_filter &&
                !f.mail_schedule_template_filter &&
                !f.third_party_filter &&
                !f.role_filter &&
                !f.office_filter));
    }, [view]);
    // const hasChanged = !view || !initialData || JSON.stringify(view) !== JSON.stringify(initialData);
    const canSave = canTest && (view === null || view === void 0 ? void 0 : view.name.trim()) !== ""; /* && hasChanged */
    const isLoading = React.useMemo(() => {
        return getViewApi.isFetching;
    }, [getViewApi]);
    const listFieldsApi = useListFields({}, REFETCH_CACHE_PARAMS);
    const fields = React.useMemo(() => {
        var _a;
        if (!((_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data)) {
            return null;
        }
        const allowedEntityTypes = [entityType];
        if (entityType === "Buyer Contract") {
            allowedEntityTypes.push("Buyer Lead");
        }
        else if (entityType === "Listing") {
            allowedEntityTypes.push("Seller Lead");
        }
        return listFieldsApi.data.data.filter((field) => allowedEntityTypes.includes(field.entity_type));
    }, [entityType, (_a = listFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data]);
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = React.useMemo(() => {
        var _a;
        if (!((_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data)) {
            return null;
        }
        return listStagesApi.data.data.filter((x) => x.type === entityType);
    }, [entityType, (_b = listStagesApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const listTextFieldComparatorsApi = useListTextFieldComparators(INFINITE_CACHE_PARAMS);
    const textFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTextFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_c = listTextFieldComparatorsApi.data) === null || _c === void 0 ? void 0 : _c.data]);
    const listNumberFieldComparatorsApi = useListNumberFieldComparators(INFINITE_CACHE_PARAMS);
    const numberFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listNumberFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_d = listNumberFieldComparatorsApi.data) === null || _d === void 0 ? void 0 : _d.data]);
    const listDateFieldComparatorsApi = useListDateFieldComparators(INFINITE_CACHE_PARAMS);
    const dateFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listDateFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_e = listDateFieldComparatorsApi.data) === null || _e === void 0 ? void 0 : _e.data]);
    const listChoiceFieldComparatorsApi = useListChoiceFieldComparators(INFINITE_CACHE_PARAMS);
    const choiceFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listChoiceFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_f = listChoiceFieldComparatorsApi.data) === null || _f === void 0 ? void 0 : _f.data]);
    const listMultipleChoiceFieldComparatorsApi = useListMultipleChoiceFieldComparators(INFINITE_CACHE_PARAMS);
    const multipleChoiceFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listMultipleChoiceFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_g = listMultipleChoiceFieldComparatorsApi.data) === null || _g === void 0 ? void 0 : _g.data]);
    const listBooleanFieldComparatorsApi = useListBooleanFieldComparators(INFINITE_CACHE_PARAMS);
    const booleanFieldComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listBooleanFieldComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_h = listBooleanFieldComparatorsApi.data) === null || _h === void 0 ? void 0 : _h.data]);
    const listStageComparatorsApi = useListStageComparators(INFINITE_CACHE_PARAMS);
    const stageComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listStageComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_j = listStageComparatorsApi.data) === null || _j === void 0 ? void 0 : _j.data]);
    const listTimestampComparatorsApi = useListTimestampComparators(INFINITE_CACHE_PARAMS);
    const timestampComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTimestampComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_k = listTimestampComparatorsApi.data) === null || _k === void 0 ? void 0 : _k.data]);
    const listUserComparatorsApi = useListUserComparators(INFINITE_CACHE_PARAMS);
    const userComparators = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUserComparatorsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_l = listUserComparatorsApi.data) === null || _l === void 0 ? void 0 : _l.data]);
    const listDateFilterUnitsApi = useListDateFilterUnits(INFINITE_CACHE_PARAMS);
    const dateFilterUnits = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listDateFilterUnitsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_m = listDateFilterUnitsApi.data) === null || _m === void 0 ? void 0 : _m.data]);
    const listTimestampTypesApi = useListTimestampTypes(INFINITE_CACHE_PARAMS);
    const timestampTypes = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTimestampTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_o = listTimestampTypesApi.data) === null || _o === void 0 ? void 0 : _o.data]);
    const listUserFilterTypesApi = useListUserFilterTypes(INFINITE_CACHE_PARAMS);
    const userFilterTypes = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUserFilterTypesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_p = listUserFilterTypesApi.data) === null || _p === void 0 ? void 0 : _p.data]);
    const listTimestampFilterUnitsApi = useListTimestampFilterUnits(INFINITE_CACHE_PARAMS);
    const timestampFilterUnits = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTimestampFilterUnitsApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_q = listTimestampFilterUnitsApi.data) === null || _q === void 0 ? void 0 : _q.data]);
    const listDateRangesApi = useListDateRanges(INFINITE_CACHE_PARAMS);
    const dateRanges = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listDateRangesApi.data) === null || _a === void 0 ? void 0 : _a.data.items) !== null && _b !== void 0 ? _b : null;
    }, [(_r = listDateRangesApi.data) === null || _r === void 0 ? void 0 : _r.data]);
    const listOfficesApi = useListOffices(INFINITE_CACHE_PARAMS);
    const offices = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listOfficesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_s = listOfficesApi.data) === null || _s === void 0 ? void 0 : _s.data]);
    const listTeamsApi = useListTeams(INFINITE_CACHE_PARAMS);
    const teams = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listTeamsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_t = listTeamsApi.data) === null || _t === void 0 ? void 0 : _t.data]);
    const listUsersApi = usePostQuery(searchUsersBasic, { disable_sort: true }, INFINITE_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_u = listUsersApi.data) === null || _u === void 0 ? void 0 : _u.data]);
    const deleteView = React.useCallback(() => {
        deleteViewApi
            .mutateAsync({ uid: uid })
            .then(() => {
            enqueueSnackbar("View deleted", { variant: "success" });
            updateSearch("view", null);
        })
            .catch((error) => {
            enqueueErrorSnackbar(error);
        });
    }, [deleteViewApi, uid, updateSearch]);
    const saveView = React.useCallback(() => {
        if (uid) {
            updateViewApi
                .mutateAsync({ uid, data: removeUndefinedRecursive(view) })
                .then((result) => {
                enqueueSnackbar("View updated", { variant: "success" });
                setIsEditing(false);
                updateViewFromApi(result.data);
                spreadsheetContext === null || spreadsheetContext === void 0 ? void 0 : spreadsheetContext.setFilter(null);
                if (onSaveComplete) {
                    onSaveComplete(result.data);
                }
            })
                .catch((error) => {
                enqueueErrorSnackbar(error);
            });
        }
        else {
            createViewApi
                .mutateAsync({ data: Object.assign(Object.assign({}, removeUndefinedRecursive(view)), { entity_type: entityType }) })
                .then((result) => {
                enqueueSnackbar("View created", { variant: "success" });
                navigate(getViewUrl({ uid: result.data.uid, entity_type: entityType }));
                spreadsheetContext === null || spreadsheetContext === void 0 ? void 0 : spreadsheetContext.setFilter(null);
                if (onSaveComplete) {
                    onSaveComplete(result.data);
                }
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [
        uid,
        updateViewApi,
        view,
        updateViewFromApi,
        spreadsheetContext,
        onSaveComplete,
        createViewApi,
        entityType,
        navigate,
    ]);
    const testView = React.useCallback(() => {
        if (spreadsheetContext) {
            // note that we use ... notation to create a new object
            // so that the query refreshes event if the filter
            // hasn't changed
            spreadsheetContext.setFilter([...view.filter_groups]);
        }
        else {
            console.error("Can't test view when SpreadsheetContext doesn't exist");
        }
    }, [spreadsheetContext, view]);
    const cancel = React.useCallback(() => {
        setIsEditing(false);
        updateSearch("show_saved_view_editor", null);
        if (onCancel) {
            onCancel();
        }
    }, [onCancel, updateSearch]);
    const close = React.useCallback(() => {
        setIsEditing(false);
        updateSearch("show_saved_view_editor", null, "view", null);
    }, [updateSearch]);
    const clearFilters = React.useCallback(() => {
        setView((prev) => {
            var _a, _b;
            return Object.assign(Object.assign({}, getDefaultView()), { name: (_a = prev === null || prev === void 0 ? void 0 : prev.name) !== null && _a !== void 0 ? _a : "", sharing: (_b = prev === null || prev === void 0 ? void 0 : prev.sharing) !== null && _b !== void 0 ? _b : getDefaultView().sharing });
        });
    }, [getDefaultView]);
    const createNewView = React.useCallback(() => {
        setIsEditing(true);
        updateSearch("view", null, "show_saved_view_editor", "true");
        setView(getDefaultView());
    }, [updateSearch, getDefaultView]);
    const addFilter = React.useCallback(() => {
        setView((prev) => {
            var _a;
            return Object.assign(Object.assign({}, prev), { filter_groups: [
                    Object.assign(Object.assign({}, prev.filter_groups[0]), { filters: [...((_a = prev.filter_groups[0].filters) !== null && _a !== void 0 ? _a : []), {}] }),
                    ...prev.filter_groups.slice(1),
                ] });
        });
    }, [setView]);
    const openSaveAsDialog = React.useCallback(() => {
        setIsSaveAsDialogOpen(true);
    }, []);
    const closeSaveAsDialog = React.useCallback(() => {
        setIsSaveAsDialogOpen(false);
    }, []);
    const saveAs = React.useCallback((title) => {
        closeSaveAsDialog();
        createViewApi
            .mutateAsync({
            data: Object.assign(Object.assign({}, removeUndefinedRecursive(view)), { sharing: {
                    all_users: {
                        is_editable: false,
                        is_pinned: false,
                        is_shared: false,
                    },
                    offices: [],
                    roles: [],
                    users: [
                        {
                            is_editable: true,
                            is_pinned: false,
                            is_shared: true,
                            user: session.viewingAsUser.uid,
                        },
                    ],
                }, name: title, entity_type: entityType }),
        })
            .then((result) => {
            enqueueSnackbar("View created", { variant: "success" });
            navigate(getViewUrl({ uid: result.data.uid, entity_type: entityType }));
            spreadsheetContext === null || spreadsheetContext === void 0 ? void 0 : spreadsheetContext.setFilter(null);
        })
            .catch(enqueueErrorSnackbar);
    }, [closeSaveAsDialog, createViewApi, view, session, entityType, navigate, spreadsheetContext]);
    const value = React.useMemo(() => {
        return {
            uid: uid !== null && uid !== void 0 ? uid : null,
            entityType,
            view,
            initialData,
            setView,
            fields,
            cancel,
            close,
            stages,
            deleteView,
            saveView,
            saveAs,
            testView,
            addFilter,
            clearFilters,
            createNewView,
            isDeleting: deleteViewApi.isPending,
            isSaving: createViewApi.isPending || updateViewApi.isPending,
            textFieldComparators,
            numberFieldComparators,
            dateFieldComparators,
            choiceFieldComparators,
            multipleChoiceFieldComparators,
            booleanFieldComparators,
            stageComparators,
            timestampComparators,
            userComparators,
            dateFilterUnits,
            timestampTypes,
            userFilterTypes,
            timestampFilterUnits,
            dateRanges,
            offices,
            teams,
            users,
            canSave,
            canTest,
            isLoading,
            isEditing,
            setIsEditing,
            canEdit,
            openSaveAsDialog,
            closeSaveAsDialog,
        };
    }, [
        uid,
        entityType,
        view,
        initialData,
        setView,
        fields,
        cancel,
        close,
        stages,
        deleteView,
        saveView,
        saveAs,
        testView,
        clearFilters,
        createNewView,
        addFilter,
        deleteViewApi.isPending,
        createViewApi.isPending,
        updateViewApi.isPending,
        textFieldComparators,
        numberFieldComparators,
        dateFieldComparators,
        choiceFieldComparators,
        multipleChoiceFieldComparators,
        booleanFieldComparators,
        userComparators,
        stageComparators,
        timestampComparators,
        dateFilterUnits,
        timestampTypes,
        userFilterTypes,
        timestampFilterUnits,
        dateRanges,
        offices,
        teams,
        users,
        canSave,
        canTest,
        isLoading,
        isEditing,
        setIsEditing,
        canEdit,
        openSaveAsDialog,
        closeSaveAsDialog,
    ]);
    return (React.createElement(SavedViewEditorContext.Provider, { value: value },
        React.createElement(React.Fragment, null,
            props.children,
            isSaveAsDialogOpen && React.createElement(SavedViewSaveAsDialog, null))));
};
const useSavedViewEditorContext = () => {
    const { addFilter, booleanFieldComparators, canEdit, canSave, canTest, cancel, choiceFieldComparators, clearFilters, close, closeSaveAsDialog, createNewView, dateFieldComparators, dateFilterUnits, dateRanges, deleteView, entityType, fields, initialData, isDeleting, isEditing, isLoading, isSaving, multipleChoiceFieldComparators, numberFieldComparators, offices, openSaveAsDialog, saveAs, saveView, setIsEditing, setView, stageComparators, stages, teams, testView, textFieldComparators, timestampComparators, timestampFilterUnits, timestampTypes, uid, userComparators, userFilterTypes, users, view, } = React.useContext(SavedViewEditorContext);
    return {
        uid,
        entityType,
        view,
        initialData,
        setView,
        saveView,
        saveAs,
        testView,
        clearFilters,
        createNewView,
        addFilter,
        deleteView,
        fields,
        cancel,
        close,
        stages,
        textFieldComparators,
        numberFieldComparators,
        dateFieldComparators,
        choiceFieldComparators,
        multipleChoiceFieldComparators,
        booleanFieldComparators,
        stageComparators,
        timestampComparators,
        userComparators,
        dateFilterUnits,
        timestampTypes,
        userFilterTypes,
        timestampFilterUnits,
        dateRanges,
        offices,
        teams,
        users,
        isDeleting,
        isSaving,
        canSave,
        canTest,
        isLoading,
        isEditing,
        setIsEditing,
        canEdit,
        openSaveAsDialog,
        closeSaveAsDialog,
    };
};
export default useSavedViewEditorContext;
