/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary List all current voicemail recordings
 */
export const listTwilioVoicemailGreetings = (options) => {
    return axios.get(`/api/twilio/voicemail_greetings`, options);
};
export const getListTwilioVoicemailGreetingsQueryKey = () => {
    return [`/api/twilio/voicemail_greetings`];
};
export const getListTwilioVoicemailGreetingsQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListTwilioVoicemailGreetingsQueryKey();
    const queryFn = ({ signal }) => listTwilioVoicemailGreetings(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List all current voicemail recordings
 */
export function useListTwilioVoicemailGreetings(options) {
    const queryOptions = getListTwilioVoicemailGreetingsQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Set the current voicemail recording for a Twilio phone
 */
export const createTwilioVoicemailGreeting = (voicemailGreetingWriteRequest, options) => {
    return axios.post(`/api/twilio/voicemail_greetings`, voicemailGreetingWriteRequest, options);
};
export const getCreateTwilioVoicemailGreetingMutationOptions = (options) => {
    const mutationKey = ['createTwilioVoicemailGreeting'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createTwilioVoicemailGreeting(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Set the current voicemail recording for a Twilio phone
*/
export const useCreateTwilioVoicemailGreeting = (options) => {
    const mutationOptions = getCreateTwilioVoicemailGreetingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get the current voicemail recording for a Twilio phone
*/
export const getTwilioVoicemailGreeting = (phoneNumber, options) => {
    return axios.get(`/api/twilio/voicemail_greetings/${phoneNumber}`, options);
};
export const getGetTwilioVoicemailGreetingQueryKey = (phoneNumber) => {
    return [`/api/twilio/voicemail_greetings/${phoneNumber}`];
};
export const getGetTwilioVoicemailGreetingQueryOptions = (phoneNumber, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTwilioVoicemailGreetingQueryKey(phoneNumber);
    const queryFn = ({ signal }) => getTwilioVoicemailGreeting(phoneNumber, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(phoneNumber) }, queryOptions);
};
/**
 * @summary Get the current voicemail recording for a Twilio phone
 */
export function useGetTwilioVoicemailGreeting(phoneNumber, options) {
    const queryOptions = getGetTwilioVoicemailGreetingQueryOptions(phoneNumber, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
