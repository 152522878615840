import useMailScheduleTemplateErrors from "./useMailScheduleTemplateErrors";
import useUsersWithNonexistentTwilioPhones from "./useUsersWithNonexistentTwilioPhone";
import useViewsWithErrors from "./useViewsWithErrors";
const useErrors = () => {
    const viewsWithErrors = useViewsWithErrors();
    const usersWithNonexistantTwilioPhone = useUsersWithNonexistentTwilioPhones();
    const mailScheduleTemplateErrors = useMailScheduleTemplateErrors();
    return {
        viewsWithErrors,
        usersWithNonexistantTwilioPhone,
        mailScheduleTemplateErrors,
    };
};
export default useErrors;
