import * as React from "react";
import { Alert, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { useListUsers } from "@app/orval/api/users";
import { groupBy } from "./utils/utils";
const DuplicateFieldAlert = (props) => {
    var _a;
    const { duplicates } = props;
    const numUsersWithDuplicates = Object.keys(duplicates).length;
    const listUsersApi = useListUsers({}, { query: { enabled: false } });
    const users = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data;
    const usersByUid = React.useMemo(() => {
        return users ? groupBy(users, "uid") : {};
    }, [users]);
    return (React.createElement(Alert, { severity: "warning", sx: { "& .MuiAlert-message": { width: "100%" }, paddingBottom: 0 } },
        React.createElement(Grid, { container: true, direction: "column", spacing: 1, wrap: "nowrap", style: { width: "100%", paddingBottom: 0 } },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body1" },
                    numUsersWithDuplicates,
                    " user",
                    numUsersWithDuplicates > 1 ? "s" : "",
                    " were found with conflicting data. This could cause problems with various automations. Ensure that these fields are unique for each user.")),
            React.createElement(Grid, { item: true },
                React.createElement(List, { dense: true, style: { padding: 0, maxHeight: "150px", overflowY: "scroll" } }, Object.keys(duplicates).map((userUid) => {
                    const user = usersByUid[userUid];
                    const fields = duplicates[userUid];
                    return (React.createElement(ListItemButton, { key: userUid, component: RouterLink, to: `/users/${userUid}` },
                        React.createElement(ListItemText, { primary: user ? user.name : "", secondary: fields.join(", ") })));
                }))))));
};
export default DuplicateFieldAlert;
