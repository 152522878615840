import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useSession from "@app/hooks/useSession";
import { useGetColumnArrangement, useUpdateColumnArrangement } from "@app/orval/api/column-arrangements";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
const useColumnArrangementApi = (options) => {
    var _a, _b;
    const { canSaveColumnArrangement = true, gridApi, tableName, useDefaultColumnArrangement = false } = options;
    const session = useSession();
    const canSaveColumnArrangement_ = canSaveColumnArrangement && ((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.hasRole(["Superadmin", "Admin"]));
    const queryClient = useQueryClient();
    const getColumnArrangementApi = useGetColumnArrangement({ table_name: tableName }, Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS), { query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { refetchInterval: canSaveColumnArrangement_ ? 10000 : undefined, enabled: !!tableName && !useDefaultColumnArrangement, placeholderData: useDefaultColumnArrangement && gridApi.current.getAllColumns
                ? {
                    data: gridApi.current.getAllColumns().map((column, i) => {
                        var _a;
                        return ({
                            table_name: tableName,
                            field_uid_or_column_name: column.field,
                            is_frozen: false,
                            is_visible: true,
                            width: (_a = column.width) !== null && _a !== void 0 ? _a : 100,
                            sort_order: i + 1,
                        });
                    }),
                    status: 200,
                    statusText: "OK",
                    headers: {},
                    config: {},
                    request: undefined,
                }
                : undefined }) }));
    const columnArrangement = useMemo(() => {
        var _a, _b;
        return (_b = (_a = getColumnArrangementApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [(_b = getColumnArrangementApi.data) === null || _b === void 0 ? void 0 : _b.data]);
    const updateColumnArrangementApi = useUpdateColumnArrangement();
    const setNewColumnArrangement = useCallback((columns) => {
        console.log({ columns });
        let newColumnArrangement = gridApi.current.getAllColumns().map((column, i) => {
            var _a;
            const arrangementItem = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.find((item) => item.field_uid_or_column_name === column.field);
            return Object.assign(Object.assign(Object.assign(Object.assign({}, column), { table_name: tableName, field_uid_or_column_name: column.field, is_frozen: false, is_visible: true, width: (_a = column.width) !== null && _a !== void 0 ? _a : 100 }), arrangementItem), { sort_order: i + 1 });
        });
        for (const column of columns) {
            const { columnName } = column;
            const { isFrozen, isVisible, position, shouldUpdateState = true, width } = column.params;
            newColumnArrangement = newColumnArrangement.map((col) => {
                var _a, _b, _c, _d;
                const arrangementItem = columnArrangement === null || columnArrangement === void 0 ? void 0 : columnArrangement.find((item) => item.field_uid_or_column_name === col.field);
                if (col.field === columnName) {
                    return Object.assign(Object.assign(Object.assign(Object.assign({}, col), { table_name: tableName, field_uid_or_column_name: columnName }), arrangementItem), { is_frozen: (_a = isFrozen !== null && isFrozen !== void 0 ? isFrozen : arrangementItem === null || arrangementItem === void 0 ? void 0 : arrangementItem.is_frozen) !== null && _a !== void 0 ? _a : false, is_visible: (_b = isVisible !== null && isVisible !== void 0 ? isVisible : arrangementItem === null || arrangementItem === void 0 ? void 0 : arrangementItem.is_visible) !== null && _b !== void 0 ? _b : true, width: (_d = (_c = width !== null && width !== void 0 ? width : arrangementItem === null || arrangementItem === void 0 ? void 0 : arrangementItem.width) !== null && _c !== void 0 ? _c : col.width) !== null && _d !== void 0 ? _d : 100 });
                }
                return col;
            });
            if (position != null && shouldUpdateState) {
                const oldPosition = newColumnArrangement.findIndex((col) => col.field_uid_or_column_name === columnName);
                const col = newColumnArrangement.find((c) => c.field_uid_or_column_name === columnName);
                if (col != null && oldPosition !== -1) {
                    newColumnArrangement.splice(oldPosition, 1);
                    newColumnArrangement.splice(position, 0, col);
                }
                else {
                    throw new Error(`Unable to find column ${columnName} in column arrangement`);
                }
            }
        }
        newColumnArrangement = newColumnArrangement.map((col, i) => (Object.assign(Object.assign({}, col), { sort_order: i + 1 })));
        queryClient.setQueryData(getColumnArrangementApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: newColumnArrangement });
        });
        console.log({ newColumnArrangement, canSaveColumnArrangement_ });
        if (canSaveColumnArrangement_ && !useDefaultColumnArrangement) {
            updateColumnArrangementApi
                .mutateAsync({ data: { items: newColumnArrangement } })
                .then(() => {
                console.log("updated column arrangement");
                getColumnArrangementApi.refetch().then(() => {
                    console.log("Refetched columns");
                });
            })
                .catch(console.error);
        }
        return newColumnArrangement;
    }, [
        canSaveColumnArrangement_,
        columnArrangement,
        getColumnArrangementApi,
        gridApi,
        queryClient,
        tableName,
        updateColumnArrangementApi,
        useDefaultColumnArrangement,
    ]);
    const setColumnWidth = useCallback((columnName, width) => {
        if (!columnArrangement)
            return;
        setNewColumnArrangement([{ columnName, params: { width } }]);
    }, [columnArrangement, setNewColumnArrangement]);
    const freezeColumn = useCallback((columnName) => {
        if (!columnArrangement)
            return;
        setNewColumnArrangement([{ columnName, params: { isFrozen: true } }]);
    }, [columnArrangement, setNewColumnArrangement]);
    const unfreezeColumn = useCallback((columnName) => {
        if (!columnArrangement)
            return;
        setNewColumnArrangement([{ columnName, params: { isFrozen: false } }]);
    }, [columnArrangement, setNewColumnArrangement]);
    const hideColumn = useCallback((columnName) => {
        if (!columnArrangement)
            return;
        console.log("hide");
        setNewColumnArrangement([{ columnName, params: { isVisible: false } }]);
    }, [columnArrangement, setNewColumnArrangement]);
    const unhideColumns = useCallback((columnNames) => {
        if (!columnArrangement)
            return;
        console.log("hide");
        columnNames.forEach((columnName) => {
            setNewColumnArrangement([{ columnName, params: { isVisible: true } }]);
        });
    }, [columnArrangement, setNewColumnArrangement]);
    const moveColumn = useCallback((columnName, toPosition) => {
        if (!columnArrangement)
            return;
        console.log("move");
        setNewColumnArrangement([{ columnName, params: { position: toPosition } }]);
    }, [columnArrangement, setNewColumnArrangement]);
    const showColumn = useCallback((columnName) => {
        if (!columnArrangement)
            return;
        setNewColumnArrangement([{ columnName, params: { isVisible: true } }]);
    }, [columnArrangement, setNewColumnArrangement]);
    const setVisibilityForColumns = useCallback((columns) => {
        if (!columnArrangement)
            return;
        setNewColumnArrangement(columns.map((col) => ({ columnName: col.columnName, params: { isVisible: col.isVisible } })));
    }, [columnArrangement, setNewColumnArrangement]);
    const reorderColumns = useCallback((columns) => {
        if (!columnArrangement)
            return;
        console.log("reorder");
        setNewColumnArrangement([
            { columnName: columns.column.field, params: { position: columns.targetIndex, shouldUpdateState: false } },
        ]);
    }, [columnArrangement, setNewColumnArrangement]);
    return {
        columnArrangement,
        freezeColumn,
        unfreezeColumn,
        hideColumn,
        showColumn,
        moveColumn,
        unhideColumns,
        setColumnWidth,
        reorderColumns,
        setVisibilityForColumns,
    };
};
export default useColumnArrangementApi;
