import * as React from "react";
import { Grid2, Tooltip, Typography } from "@mui/material";
import dayjs_ from "@app/common/dayjs";
import { ACCEPTABLE_COLOR, EXPECTED_COLOR, formatDecimalMemoized, getMetricScore, UNACCEPTABLE_COLOR } from "./utils";
const scoreHistoryTooltip = (scoreHistory) => {
    return (React.createElement(Grid2, { style: { width: "200px", maxHeight: "200px", overflowY: "auto", paddingRight: "16px" } },
        React.createElement(Grid2, null,
            React.createElement(Typography, { align: "center", fontSize: "13pt" }, "30 Day DASHscore History")),
        scoreHistory.map((score) => (React.createElement(Grid2, { key: score.timestamp, container: true },
            React.createElement(Typography, { fontSize: "10pt" }, `${dayjs_(score.timestamp).format("ddd, MMM DD, YYYY")}:`),
            React.createElement(Typography, { fontSize: "10pt", style: { paddingLeft: "8px" } }, score.score ? Math.round(score.score) : "-"))))));
};
const DASHscorePayout = (props) => {
    var _a;
    const { payoutRate, rolling30Score, scoreGreenVal, scoreHistory, scoreRedVal, units } = props;
    const rolling30Rounded = rolling30Score ? Math.round(rolling30Score) : null;
    const scoreRating = (_a = getMetricScore(rolling30Rounded, scoreRedVal, scoreGreenVal)) !== null && _a !== void 0 ? _a : null;
    let bgColor;
    if (scoreRating === "Expected") {
        bgColor = EXPECTED_COLOR;
    }
    else if (scoreRating === "Acceptable") {
        bgColor = ACCEPTABLE_COLOR;
    }
    else if (scoreRating === "Unacceptable") {
        bgColor = UNACCEPTABLE_COLOR;
    }
    return (React.createElement(Tooltip, { title: scoreHistory ? scoreHistoryTooltip(scoreHistory) : "", placement: "left" },
        React.createElement(Grid2, { container: true, style: { border: "3px solid black", padding: "8px", background: bgColor } },
            React.createElement(Grid2, { size: 12, style: { marginLeft: "auto", marginRight: "auto" } },
                React.createElement(Typography, { fontWeight: "bold", fontSize: "28pt", align: "center" }, "Payout")),
            React.createElement(Grid2, { container: true, size: 12, style: { marginLeft: "auto", marginRight: "auto", marginTop: "auto" } },
                React.createElement(Typography, { fontWeight: "bold", fontSize: "13pt", style: { paddingRight: "8px" } },
                    "Units:",
                    " "),
                React.createElement(Typography, { fontSize: "13pt" }, units != null ? formatDecimalMemoized(units) : "-")),
            React.createElement(Grid2, { container: true, size: 12, style: { marginLeft: "auto", marginRight: "auto", height: "10px" } },
                React.createElement(Typography, { fontWeight: "bold", fontSize: "13pt", style: { paddingRight: "8px" } },
                    "Rolling 30 DashScore:",
                    " "),
                React.createElement(Typography, { fontSize: "13pt" }, rolling30Rounded !== null && rolling30Rounded !== void 0 ? rolling30Rounded : "-")),
            React.createElement(Grid2, { container: true, size: 12, style: { marginLeft: "auto", marginRight: "auto", marginBottom: "auto" } },
                React.createElement(Typography, { fontWeight: "bold", fontSize: "13pt", style: { paddingRight: "8px" } },
                    "Payout Rate:",
                    " "),
                React.createElement(Typography, { fontSize: "13pt" }, payoutRate ? formatDecimalMemoized(payoutRate, 2) : "-")))));
};
export default DASHscorePayout;
