import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Warning } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Divider, Grid, Paper, Stepper, Step, StepLabel, Typography, Tooltip, } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useListPreOfferFields } from "@app/orval/api/field-definitions";
import { submitQfo, useAddQfo, useGetQfo, useListQfos, useUpdateQfo, } from "@app/orval/api/forms-questions-for-the-offer";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import AppConfig from "@app/util/AppConfig";
import { fileContentToURL } from "@app/util/Utils";
import FormField from "./QuestionsForTheOfferFormField";
import { FORM_FIELDS, NC_ONLY_FIELDS, SC_ONLY_FIELDS } from "./utils";
// needed for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const QuestionsForTheOffer = (props) => {
    var _a, _b, _c, _d;
    const [isSubmitting, setIsSubmitting] = useStateWithCallback(false);
    const [isStartingNewSubmission, setIsStartingNewSubmission] = useStateWithCallback(false);
    // const [isUpdatingField, setIsUpdatingField] = useStateWithCallback(false);
    const [fieldsCurrentlyUpdating, setFieldsCurrentlyUpdating] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [submission, setSubmission] = React.useState(null);
    // const [hasPreviousSubmission, setHasPreviousSubmission] = React.useState<boolean | null>(null);
    const [schema, setSchema] = React.useState(null);
    const [offerAsciiContent, setOfferAsciiContent] = React.useState(undefined);
    const [error, setError] = React.useState(null);
    const { uid } = props;
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const errorDialog = useErrorDialog();
    const queryParams = useSearchParams()[0];
    const [qfoParams, setQfoParams] = useStateWithCallback({
        box_key: (_a = queryParams.get("box_key")) !== null && _a !== void 0 ? _a : undefined,
        buyer_lead_property_uid: (_b = queryParams.get("property")) !== null && _b !== void 0 ? _b : undefined,
    });
    const getQFOApi = useGetQfo(uid !== null && uid !== void 0 ? uid : "", {
        query: Object.assign(Object.assign({}, REFETCH_CACHE_PARAMS.query), { enabled: false }),
    });
    const listQFOsApi = useListQfos(qfoParams);
    const updateQFOApi = useUpdateQfo();
    const addQFOApi = useAddQfo();
    const listFieldsApi = useListPreOfferFields();
    const previousSubmissions = (_d = (_c = listQFOsApi.data) === null || _c === void 0 ? void 0 : _c.data.filter((obj) => obj.submitted_at)) !== null && _d !== void 0 ? _d : [];
    const createQfo = React.useCallback(() => {
        console.log("Create QFO with params", qfoParams);
        addQFOApi
            .mutateAsync({
            data: {
                buyer_lead_property_uid: qfoParams.buyer_lead_property_uid,
            },
        })
            .then((result) => {
            navigate(`/forms/qfo/form/${result.data.uid}`);
        })
            .catch((createError) => {
            console.log("Error adding qfo", createError);
            errorDialog(createError);
        });
    }, [addQFOApi, qfoParams, navigate, errorDialog]);
    React.useEffect(() => {
        if (uid) {
            // get Pre-Offer fields
            console.log("GET QFO WITH UID", uid);
            listFieldsApi
                .refetch()
                .then((result) => {
                const preOfferSchema = {};
                for (const field of result.data.data) {
                    preOfferSchema[field.name] = field;
                }
                setSchema(preOfferSchema);
                queryClient.setQueryData(listFieldsApi.queryKey, result.data);
            })
                .catch((fieldError) => {
                console.error("Error listing pre-offer fields", fieldError);
                setError(fieldError);
            });
            // retrieve the submission
            getQFOApi
                .refetch()
                .then((result) => {
                console.log({ result });
                setSubmission(result.data.data);
                // TODO: remove box key once we stop using Streak
                const resultBoxKey = result.data.data.box_key;
                const resultBuyerLead = result.data.data.buyer_lead_property_uid;
                setQfoParams({ box_key: resultBoxKey !== null && resultBoxKey !== void 0 ? resultBoxKey : undefined, buyer_lead_property_uid: resultBuyerLead !== null && resultBuyerLead !== void 0 ? resultBuyerLead : undefined });
            })
                .catch((getError) => {
                console.error("Error getting QFO submission", getError);
                // set error
                setError(getError);
            });
        }
        else {
            setSchema(null);
            setSubmission(null);
            // list all, by box key, or by property uid
            console.log("GET QFO WITH PARAMS", queryParams);
            const boxKey = queryParams.get("box_key");
            const propertyUid = queryParams.get("property");
            setQfoParams({ box_key: boxKey !== null && boxKey !== void 0 ? boxKey : undefined, buyer_lead_property_uid: propertyUid !== null && propertyUid !== void 0 ? propertyUid : undefined }, () => {
                if (!boxKey && !propertyUid) {
                    setError("Invalid URL");
                }
                else {
                    listQFOsApi
                        .refetch()
                        .then((listResult) => {
                        if (listResult.data.data.length === 0 && propertyUid) {
                            // create a new submission
                            createQfo();
                            queryClient.setQueryData(listQFOsApi.queryKey, listResult.data);
                        }
                        else {
                            navigate(`/forms/qfo/form/${listResult.data.data[0].uid}`);
                        }
                    })
                        .catch((listError) => {
                        console.error("Error listing qfos for params:", qfoParams, listError);
                        setError(listError);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    const onFieldChangeComplete = React.useCallback((fieldName) => {
        setFieldsCurrentlyUpdating((prevState) => {
            return prevState.filter((field) => field !== fieldName);
        });
    }, []);
    const setFieldValue = React.useCallback((fieldName) => (value) => {
        setFieldsCurrentlyUpdating((prevState) => {
            if (prevState.includes(fieldName)) {
                return prevState;
            }
            return [...prevState, fieldName];
        });
        const shouldClearPersonalPropertyOther = fieldName === "Personal Property" && !(value || []).includes("Other");
        const shouldClearDueDiligenceTypeOther = fieldName === "How do you intend to pay the Due Diligence Fee?" && !(value || "").includes("Other");
        const shouldClearTerminationFeeTypeOther = fieldName === "How do you intend to pay the Termination Fee?" && !(value || "").includes("Other");
        const shouldClearEarnestMoneyOther = fieldName === "How do you intend to pay the Earnest Money Deposit?" && !(value || "").includes("Other");
        const shouldClearHaveToSell = fieldName === "Do you have to sell or lease a property in order to qualify?" && !value;
        const shouldResetPreferredAttorney = fieldName === "Closing Attorney Preference" && value === "No";
        setSubmission((prevState) => {
            if (!prevState) {
                return prevState;
            }
            if (shouldClearPersonalPropertyOther && prevState.fields["Personal Property (Other)"]) {
                updateQFOApi.mutateAsync({
                    uid: prevState.uid,
                    data: {
                        fields: {
                            "Personal Property (Other)": null,
                        },
                    },
                });
            }
            if (shouldClearDueDiligenceTypeOther &&
                prevState.fields["How do you intend to pay the Due Diligence Fee? (Other)"]) {
                updateQFOApi.mutateAsync({
                    uid: prevState.uid,
                    data: {
                        fields: {
                            "How do you intend to pay the Due Diligence Fee? (Other)": null,
                        },
                    },
                });
            }
            if (shouldClearTerminationFeeTypeOther &&
                prevState.fields["How do you intend to pay the Termination Fee? (Other)"]) {
                updateQFOApi.mutateAsync({
                    uid: prevState.uid,
                    data: {
                        fields: {
                            "How do you intend to pay the Termination Fee? (Other)": null,
                        },
                    },
                });
            }
            if (shouldClearEarnestMoneyOther &&
                prevState.fields["How do you intend to pay the Earnest Money Deposit? (Other)"]) {
                updateQFOApi.mutateAsync({
                    uid: prevState.uid,
                    data: {
                        fields: {
                            "How do you intend to pay the Earnest Money Deposit? (Other)": null,
                        },
                    },
                });
            }
            if (shouldClearHaveToSell) {
                updateQFOApi.mutateAsync({
                    uid: prevState.uid,
                    data: {
                        fields: {
                            "Have to Sell - Property Address": null,
                            "Have to Sell - Under Contract": null,
                            "Have to Sell - Currently Listed": null,
                        },
                    },
                });
            }
            if (shouldResetPreferredAttorney) {
                updateQFOApi.mutateAsync({
                    uid: prevState.uid,
                    data: {
                        fields: {
                            "What is the name of the Closing Attorney that you would prefer to use?": null,
                        },
                    },
                });
            }
            return Object.assign(Object.assign({}, prevState), { fields: Object.assign(Object.assign({}, prevState.fields), { "Personal Property (Other)": shouldClearPersonalPropertyOther
                        ? undefined
                        : prevState.fields["Personal Property (Other)"], "How do you intend to pay the Due Diligence Fee? (Other)": shouldClearDueDiligenceTypeOther
                        ? undefined
                        : prevState.fields["How do you intend to pay the Due Diligence Fee? (Other)"], "Have to Sell - Property Address": shouldClearHaveToSell
                        ? undefined
                        : prevState.fields["Have to Sell - Property Address"], "Have to Sell - Under Contract": shouldClearHaveToSell
                        ? undefined
                        : prevState.fields["Have to Sell - Under Contract"], "Have to Sell - Currently Listed": shouldClearHaveToSell
                        ? undefined
                        : prevState.fields["Have to Sell - Currently Listed"], "What is the name of the Closing Attorney that you would prefer to use?": shouldResetPreferredAttorney
                        ? undefined
                        : prevState.fields["What is the name of the Closing Attorney that you would prefer to use?"], [fieldName]: value }) });
        });
    }, [setSubmission, updateQFOApi]);
    const previous = React.useCallback(() => {
        setActiveStep((prevState) => prevState - 1);
    }, []);
    const next = React.useCallback(() => {
        setActiveStep((prevState) => prevState + 1);
    }, []);
    const submit = React.useCallback(() => {
        setIsSubmitting(true, () => {
            submitQfo(submission.uid)
                .then((result) => {
                setOfferAsciiContent(result.data.content);
            })
                .catch((submitError) => {
                errorDialog(submitError);
            })
                .then(() => {
                setIsSubmitting(false);
            });
        });
    }, [errorDialog, setIsSubmitting, submission]);
    const isStepValid = React.useCallback((step) => {
        if (!submission || !schema) {
            return false;
        }
        const invalidFields = FORM_FIELDS[step].filter((field) => field.required &&
            (submission.fields[field.name] === "" ||
                submission.fields[field.name] == null ||
                (Array.isArray(submission.fields[field.name]) && submission.fields[field.name].length === 0)) &&
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            !!schema[field.name]);
        const visibleInvalidFields = invalidFields.filter((field) => {
            if (submission.fields["State"] === "North Carolina" && SC_ONLY_FIELDS.includes(field.name)) {
                return false;
            }
            if (submission.fields["State"] === "South Carolina" && NC_ONLY_FIELDS.includes(field.name)) {
                return false;
            }
            return true;
        });
        if (visibleInvalidFields.length > 0) {
            console.warn("Invalid fields: ", visibleInvalidFields.map((field) => field.name));
        }
        return visibleInvalidFields.length === 0;
    }, [schema, submission]);
    const startNewSubmission = React.useCallback(() => {
        if (submission) {
            setIsStartingNewSubmission(true, () => {
                setSubmission(null);
                addQFOApi
                    .mutateAsync({
                    data: {
                        buyer_lead_property_uid: submission.buyer_lead_property_uid,
                    },
                })
                    .then((result) => {
                    navigate(`/forms/qfo/form/${result.data.uid}`);
                    setOfferAsciiContent(undefined);
                    setActiveStep(0);
                })
                    .catch((createError) => {
                    errorDialog(createError);
                })
                    .then(() => {
                    setIsStartingNewSubmission(false);
                    setError(null);
                    setIsStartingNewSubmission(false);
                });
            });
        }
        else {
            console.warn("No submission found");
        }
    }, [addQFOApi, errorDialog, navigate, setIsStartingNewSubmission, setSubmission, submission]);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    let pdfUrl;
    if (offerAsciiContent) {
        pdfUrl = fileContentToURL(offerAsciiContent, "application/pdf");
    }
    console.log({ submission });
    if (submission && (submission.submitted_at || offerAsciiContent)) {
        return (React.createElement(Box, { style: { background: AppConfig.themeColors.lightestblue, height: "100%", overflow: "auto" } },
            React.createElement(Container, { maxWidth: offerAsciiContent ? "md" : "xs" },
                React.createElement(Box, { p: 2 },
                    React.createElement(Paper, { elevation: 4 },
                        React.createElement(Box, { p: 2 },
                            React.createElement(Grid, { container: true, spacing: 2 },
                                offerAsciiContent ? (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(Typography, null, "I'll get the offer written up and over to you for signature ASAP.")),
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement("a", { href: pdfUrl, target: "__blank" },
                                            React.createElement(Document, { file: pdfUrl },
                                                React.createElement(Page, { pageNumber: 1, renderTextLayer: false, renderAnnotationLayer: false })))))) : (React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, null, "Thanks for your submission!"))),
                                submission.buyer_lead_property_uid && (React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Button, { onClick: startNewSubmission, color: "primary", disabled: isStartingNewSubmission, endIcon: isStartingNewSubmission && React.createElement(CircularProgress, { size: 20 }) }, "Click here to start a new submission."))))))))));
    }
    if (!schema || !submission || listFieldsApi.isLoading || getQFOApi.isLoading || listQFOsApi.isLoading) {
        return React.createElement(CoreLoading, null);
    }
    // form only contains 3 steps if there was a previous submission, otherwise 4
    // const lastStep = hasPreviousSubmission ? 2 : 3;
    const lastStep = previousSubmissions.length > 0 ? 2 : 3;
    // used for indexing into the formFields array
    const previousSubmissionIncrement = activeStep > 0 && previousSubmissions.length > 0 ? 1 : 0;
    const disableSubmit = !isStepValid(activeStep + previousSubmissionIncrement) || isSubmitting || fieldsCurrentlyUpdating.length > 0;
    return (React.createElement(Box, { style: { background: AppConfig.themeColors.lightestblue, height: "100%", overflow: "auto" } },
        React.createElement(Container, { maxWidth: "lg" },
            React.createElement(Box, { p: 2 },
                React.createElement(Paper, { elevation: 4 },
                    React.createElement(Box, { p: 2 },
                        React.createElement(Grid, { container: true, spacing: 2 },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Typography, { variant: "h6" }, "Questions for the Offer")),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Divider, null)),
                            !submission.submitted_at && (React.createElement(React.Fragment, null,
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Stepper, { activeStep: activeStep },
                                        React.createElement(Step, null,
                                            React.createElement(StepLabel, null, "Basic Information")),
                                        previousSubmissions.length === 0 && (React.createElement(Step, null,
                                            React.createElement(StepLabel, null, "Buyer Information"))),
                                        React.createElement(Step, null,
                                            React.createElement(StepLabel, null, "Offer Information")),
                                        React.createElement(Step, null,
                                            React.createElement(StepLabel, null, "Loan Information")))),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Container, { maxWidth: "md" },
                                        React.createElement(Grid, { container: true, spacing: 2 }, FORM_FIELDS[activeStep + previousSubmissionIncrement].map((formField) => (React.createElement(FormField, { key: `${formField.name}_${activeStep}`, label: formField.label, formField: formField, submission: submission, schema: schema, onSaveBegin: setFieldValue, onFieldSaveComplete: onFieldChangeComplete })))))),
                                React.createElement(Grid, { item: true, xs: 12, style: { textAlign: "right" } },
                                    activeStep > 0 && activeStep <= lastStep && (React.createElement(Button, { size: "small", color: "inherit", variant: "contained", onClick: previous, style: { marginRight: "1rem", marginBottom: "6px" }, disabled: isSubmitting }, "Previous")),
                                    activeStep < lastStep && (React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: next, disabled: !isStepValid(activeStep + previousSubmissionIncrement) || isSubmitting, style: { marginBottom: "6px" } }, "Next")),
                                    activeStep === lastStep && (React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: submit, disabled: disableSubmit, endIcon: isSubmitting && React.createElement(CircularProgress, { size: 20 }), style: { marginBottom: "6px" } }, "Submit")),
                                    activeStep === lastStep &&
                                        (!isStepValid(activeStep + previousSubmissionIncrement) ||
                                            fieldsCurrentlyUpdating.length > 0) && (React.createElement(Box, { display: "inline-flex", height: "80%", alignItems: "flex-end", p: 1 },
                                        React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                                                React.createElement(Typography, null, "Cannot Submit Form:"),
                                                !isStepValid(activeStep + previousSubmissionIncrement) && (React.createElement(Typography, null, "All required fields must be filled.")),
                                                fieldsCurrentlyUpdating.length > 0 && (React.createElement(Typography, null, "Cannot submit while a field is updating."))), enterTouchDelay: 0 },
                                            React.createElement(Typography, { variant: "caption", color: "error", align: "center" },
                                                React.createElement(Warning, { fontSize: "small" })))))))),
                            submission.submitted_at && (React.createElement(Grid, { item: true, xs: 12 })))))))));
};
export default QuestionsForTheOffer;
