/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Automatically search Bridge, Spark, and Canopy MLS until 20 comps are found.
 */
export const pullComps = (params, options) => {
    return axios.get(`/api/compsfinder/search`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getPullCompsQueryKey = (params) => {
    return [`/api/compsfinder/search`, ...(params ? [params] : [])];
};
export const getPullCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getPullCompsQueryKey(params);
    const queryFn = ({ signal }) => pullComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Automatically search Bridge, Spark, and Canopy MLS until 20 comps are found.
 */
export function usePullComps(params, options) {
    const queryOptions = getPullCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
