import * as React from "react";
import { Refresh } from "@mui/icons-material";
import { Box, Grid, Typography, IconButton, Button, Divider } from "@mui/material";
import pickBy from "lodash/pickBy";
import CoreDateField from "@app/common/CoreDateField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { searchBuyerContracts } from "@app/orval/api/buyer-contracts";
import { useListUserTeams } from "@app/orval/api/lists-user-teams";
import { usePortalToolsListPsps } from "@app/orval/api/portal-tools-psps";
import { usePortalToolsListQfos } from "@app/orval/api/portal-tools-qfos";
import { searchSellerContracts } from "@app/orval/api/seller-contracts";
import { useListUsers } from "@app/orval/api/users";
import { FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA } from "@app/orval/config";
import { formatDate_Dayjs } from "@app/util/Utils";
import ReportingPortalToolsContent from "./ReportingPortalToolsContent";
import ReportingPortalToolsLoadingMessage from "./ReportingPortalToolsLoadingMessage";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
const BUYER_CONTRACTS_CLOSING_DATE_KEY = "cdd90a08fd1c4134947501c7dcd9e006";
const BUYER_CONTRACTS_EXCLUDE_STAGES = ["345acd0eb98d48a8a61b85a48bf6efb9", "6b1c9013bb7e418ebb8e380212526f2c"];
const SELLER_CONTRACTS_CLOSING_DATE_KEY = "c847e766bcc64c348a94642186816943";
const SELLER_CONTRACTS_EXCLUDE_STAGES = ["10946bd343784f578e535615ab92a35a", "507c4f6128224c4d89c6889f95f30c4b"];
const AFTER_COMPARATOR = "is equal to or after";
const BEFORE_COMPARATOR = "is equal to or before";
const ReportingPortalToolsWrapper = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const [fromDate, setFromDate] = React.useState(formatDate_Dayjs(new Date(new Date().setDate(new Date().getDate() - 30)).toString(), "YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(null);
    const [psps, setPSPs] = React.useState(null);
    const [qfos, setQFOs] = React.useState(null);
    const isMountedRef = React.useRef(true);
    const errorDialog = useErrorDialog();
    React.useEffect(() => {
        // cleanup
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    const listUsersApi = useListUsers();
    const listTeamsApi = useListUserTeams();
    const listPSPsApi = usePortalToolsListPsps(pickBy({
        from_date: fromDate !== null && fromDate !== void 0 ? fromDate : undefined,
        to_date: toDate !== null && toDate !== void 0 ? toDate : undefined,
    }));
    const listQFOsApi = usePortalToolsListQfos(pickBy({
        from_date: fromDate !== null && fromDate !== void 0 ? fromDate : undefined,
        to_date: toDate !== null && toDate !== void 0 ? toDate : undefined,
    }, (val) => val !== undefined));
    const buyerContractsRequestData = React.useMemo(() => {
        const filters = [
            {
                stage_filter: {
                    comparator: "is not any of",
                    rhs_stages: BUYER_CONTRACTS_EXCLUDE_STAGES,
                    uid: "",
                },
            },
        ];
        if (fromDate) {
            filters.push({
                date_filter: {
                    lhs_field: BUYER_CONTRACTS_CLOSING_DATE_KEY,
                    comparator: AFTER_COMPARATOR,
                    interval: 0,
                    rhs_value: fromDate,
                    units: "day",
                },
                uid: "",
            });
        }
        if (toDate) {
            filters.push({
                date_filter: {
                    lhs_field: BUYER_CONTRACTS_CLOSING_DATE_KEY,
                    comparator: BEFORE_COMPARATOR,
                    interval: 0,
                    rhs_value: toDate,
                    units: "day",
                },
                uid: "",
            });
        }
        return {
            filter: [
                {
                    uid: "",
                    parent_uid: null,
                    is_match_all: true,
                    filters,
                },
            ],
        };
    }, [fromDate, toDate]);
    const sellerContractsRequestData = React.useMemo(() => {
        const filters = [
            {
                stage_filter: {
                    comparator: "is not any of",
                    rhs_stages: SELLER_CONTRACTS_EXCLUDE_STAGES,
                    uid: "",
                },
            },
        ];
        if (fromDate) {
            filters.push({
                date_filter: {
                    lhs_field: SELLER_CONTRACTS_CLOSING_DATE_KEY,
                    comparator: AFTER_COMPARATOR,
                    interval: 0,
                    rhs_value: fromDate,
                    units: "day",
                },
                uid: "",
            });
        }
        if (toDate) {
            filters.push({
                date_filter: {
                    lhs_field: SELLER_CONTRACTS_CLOSING_DATE_KEY,
                    comparator: BEFORE_COMPARATOR,
                    interval: 0,
                    rhs_value: toDate,
                    units: "day",
                },
                uid: "",
            });
        }
        return {
            filter: [
                {
                    uid: "",
                    parent_uid: null,
                    is_match_all: true,
                    filters,
                },
            ],
        };
    }, [fromDate, toDate]);
    const searchBuyerContractsApi = usePostQuery(searchBuyerContracts, buyerContractsRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const buyerContracts = (_b = (_a = searchBuyerContractsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const searchSellerContractsApi = usePostQuery(searchSellerContracts, sellerContractsRequestData, FIVE_MINUTE_CACHE_PARAMS_WITH_PLACEHOLDER_DATA);
    const sellerContracts = (_d = (_c = searchSellerContractsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const getData = React.useCallback(() => {
        listPSPsApi
            .refetch()
            .then((result) => {
            var _a, _b;
            if (isMountedRef.current) {
                setPSPs((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
            }
        })
            .catch((error) => {
            errorDialog(error);
        });
        listQFOsApi
            .refetch()
            .then((result) => {
            var _a, _b;
            if (isMountedRef.current) {
                setQFOs((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
            }
        })
            .catch((error) => {
            errorDialog(error);
        });
    }, [errorDialog, listPSPsApi, listQFOsApi]);
    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const users = (_f = (_e = listUsersApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const teams = (_h = (_g = listTeamsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const apiError = (_o = (_m = (_l = (_k = (_j = listUsersApi.error) !== null && _j !== void 0 ? _j : listTeamsApi.error) !== null && _k !== void 0 ? _k : listPSPsApi.error) !== null && _l !== void 0 ? _l : listQFOsApi.error) !== null && _m !== void 0 ? _m : searchBuyerContractsApi.error) !== null && _o !== void 0 ? _o : searchSellerContractsApi.error;
    if (apiError) {
        return React.createElement(CoreError, { error: apiError });
    }
    const loading = [
        {
            label: "Loading Users...",
            isLoading: listUsersApi.status !== "success",
        },
        {
            label: "Loading Teams...",
            isLoading: listTeamsApi.status !== "success",
        },
        {
            label: "Loading PSPs...",
            isLoading: listPSPsApi.status !== "success",
        },
        {
            label: "Loading QFOs...",
            isLoading: listQFOsApi.status !== "success",
        },
        {
            label: "Loading Buyer Contracts...",
            isLoading: searchBuyerContractsApi.status !== "success",
        },
        {
            label: "Loading Seller Contracts...",
            isLoading: searchSellerContractsApi.status !== "success",
        },
    ].filter((x) => x.isLoading);
    const teamNames = (teams !== null && teams !== void 0 ? teams : []).map((team) => {
        return team.name;
    });
    console.log({ buyerContracts });
    console.log({ sellerContracts });
    console.log({ psps });
    console.log({ qfos });
    // get per agent stats
    const agentStats = (users !== null && users !== void 0 ? users : [])
        .filter((user) => user.email)
        .map((user) => {
        var _a, _b, _c, _d, _e;
        const agentName = `${user.first_name} ${user.last_name}`;
        const agentEmail = (_a = user.email) !== null && _a !== void 0 ? _a : "";
        const agentUID = user.uid;
        const userBuyerContracts = (buyerContracts !== null && buyerContracts !== void 0 ? buyerContracts : []).filter((contract) => {
            var _a, _b, _c, _d;
            const collaborators = (_b = (_a = contract.buyer_lead) === null || _a === void 0 ? void 0 : _a.collaborators) !== null && _b !== void 0 ? _b : [];
            const agents = (_d = (_c = contract.buyer_lead) === null || _c === void 0 ? void 0 : _c.agents) !== null && _d !== void 0 ? _d : [];
            return (collaborators.filter((item) => {
                var _a;
                return ((_a = item.user) === null || _a === void 0 ? void 0 : _a.uid) === agentUID;
            }).length > 0 ||
                agents.filter((item) => {
                    return item.agent.uid === agentUID;
                }).length > 0);
        });
        const userSellerContracts = (sellerContracts !== null && sellerContracts !== void 0 ? sellerContracts : []).filter((contract) => {
            var _a, _b, _c, _d;
            const collaborators = (_b = (_a = contract.seller_lead) === null || _a === void 0 ? void 0 : _a.collaborators) !== null && _b !== void 0 ? _b : [];
            const agents = (_d = (_c = contract.seller_lead) === null || _c === void 0 ? void 0 : _c.agents) !== null && _d !== void 0 ? _d : [];
            return (collaborators.filter((item) => {
                return item.user.uid === agentUID;
            }).length > 0 ||
                agents.filter((item) => {
                    return item.agent.uid === agentUID;
                }).length > 0);
        });
        const userPSPs = (psps !== null && psps !== void 0 ? psps : []).filter((psp) => {
            const assignedAgent = psp.agent_id;
            const sellerLeadAgents = psp.agents;
            if (assignedAgent) {
                return assignedAgent === agentUID;
            }
            if (sellerLeadAgents) {
                return sellerLeadAgents.find((agent) => agent.type === "Producer" && agent.agent.uid === agentUID);
            }
            return false;
        });
        const userQFOs = (qfos !== null && qfos !== void 0 ? qfos : []).filter((qfo) => {
            const assignedAgent = qfo.assigned_agent;
            const buyerLeadAgents = qfo.agents;
            if (assignedAgent) {
                return assignedAgent.toLowerCase().trim() === agentEmail.toLowerCase().trim();
            }
            if (buyerLeadAgents) {
                return buyerLeadAgents.find((agent) => agent.type === "Producer" &&
                    agent.agent.email &&
                    agent.agent.email.toLowerCase().trim() === agentEmail.toLowerCase().trim());
            }
            return false;
        });
        return {
            uid: agentUID,
            name: agentName,
            email: agentEmail,
            team: (_c = (_b = user.team) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : null,
            office: (_e = (_d = user.office) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : null,
            contracts: [...userBuyerContracts, ...userSellerContracts],
            psps: userPSPs,
            qfos: userQFOs,
        };
    });
    console.log({ agentStats });
    return (React.createElement(Box, { p: 2 },
        React.createElement(Grid, { container: true, spacing: 2, style: { padding: "4px", height: "100%" } },
            React.createElement(Grid, { container: true, item: true, xs: 12 },
                React.createElement(Typography, { variant: "h6" }, "Portal Tools"),
                React.createElement(IconButton, { onClick: getData, style: { marginLeft: "auto" } },
                    React.createElement(Refresh, null))),
            React.createElement(Grid, { container: true, item: true, xs: 12 },
                React.createElement(Grid, { item: true, xs: 5, style: { paddingLeft: "4px", paddingRight: "4px", margin: "auto" } },
                    React.createElement(CoreDateField, { variant: "standard", label: "From", fullWidth: true, date: fromDate !== null && fromDate !== void 0 ? fromDate : null, format: "MM/dd/yyyy", onDateChange: setFromDate })),
                React.createElement(Grid, { item: true, xs: 5, style: { paddingLeft: "4px", paddingRight: "4px", margin: "auto" } },
                    React.createElement(CoreDateField, { variant: "standard", label: "To", fullWidth: true, date: toDate !== null && toDate !== void 0 ? toDate : null, format: "MM/dd/yyyy", onDateChange: setToDate })),
                React.createElement(Grid, { item: true, xs: 2, style: { paddingLeft: "4px", paddingRight: "4px", margin: "auto" } },
                    React.createElement(Button, { color: "primary", variant: "contained", onClick: getData }, "Update")),
                React.createElement(Grid, { item: true, xs: 12, style: { padding: "4px" } },
                    React.createElement(Divider, null))),
            React.createElement(Grid, { container: true, spacing: 2 },
                loading.length > 0 && (React.createElement(Grid, { item: true, style: { padding: "8px", margin: "auto" } },
                    loading.map((x) => {
                        return React.createElement(ReportingPortalToolsLoadingMessage, { key: x.label, text: x.label });
                    }),
                    React.createElement(CoreLoading, null))),
                loading.length === 0 && psps && qfos && buyerContracts && sellerContracts && (React.createElement(ReportingPortalToolsContent, { teams: teamNames, agentStats: agentStats, contracts: [...buyerContracts, ...sellerContracts], psps: psps, qfos: qfos }))))));
};
export default ReportingPortalToolsWrapper;
