import * as React from "react";
import { Box, Grid2, Button, Typography, AppBar, Toolbar, TextField } from "@mui/material";
import logoWhite from "@app/assets/img/logo-white.png";
import CoreLoading from "@app/common/CoreLoading";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useSession from "@app/hooks/useSession";
import { useMailschedule3Unsubscribe } from "@app/orval/api/mail-schedule-unsubscribe";
import { validateEmail } from "@app/util/Utils";
/**
 * Allows users to unsubscribe from Mail Schedule emails. Should be mobile friendly and work on multiple browsers.
 *
 * FUTURE WORK: This is a hacked-together approach to unsubscribe functionality.
 * We're basically allowing ANYONE to visit the /unsubscribe page and unsubscribe
 * ANY email address from Mail Schedule. We can't really embed an email address
 * in the unsubscribe link we send to recipients, because we sometimes send a single
 * Mail Schedule email to multiple recipients. But in the future, we could embed
 * a unique identifier in the link, use that to verify that the user enters one
 * of the email addresses in the list of recipients, then maybe email a confirmation
 * unsubscribe link to that email address. Again, this is hacky, but quick. And
 * we only have a couple dozen unsubscribed emails at this point.
 *
 * FUTURE WORK: We should add some indication in the CRM that a lead/contract/whatever
 * contains unsubscribed email addresses.
 * */
const Unsubscribe = () => {
    var _a;
    const [email, setEmail] = React.useState(null);
    const [unsubscribed, setUnsubscribed] = React.useState(false);
    const session = useSession();
    const unsubscribeApi = useMailschedule3Unsubscribe();
    const isUnsubscribing = unsubscribeApi.isPending;
    const handleEmailChanged = React.useCallback((e) => {
        setEmail(e.target.value);
    }, []);
    const isValid = React.useMemo(() => {
        return (email === null || email === void 0 ? void 0 : email.trim()) != null && validateEmail(email.trim());
    }, [email]);
    const unsubscribe = React.useCallback(() => {
        unsubscribeApi
            .mutateAsync({
            data: { email: email.trim() },
        })
            .then(() => {
            setUnsubscribed(true);
        })
            .catch(enqueueErrorSnackbar);
    }, [email, unsubscribeApi]);
    const isDashUser = !!((_a = session === null || session === void 0 ? void 0 : session.viewingAsUser) === null || _a === void 0 ? void 0 : _a.email);
    const offsetY = 66;
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, null,
            React.createElement(Toolbar, null,
                React.createElement(Grid2, { container: true },
                    React.createElement(Box, { display: "flex", height: "100%", width: "100%" },
                        React.createElement(Box, { display: "flex", height: "100%", ml: "auto", mt: "auto", mb: "auto", p: 2 },
                            React.createElement("img", { src: logoWhite, alt: "", height: 40 })),
                        React.createElement(Box, { display: "flex", mr: "auto", p: 2 },
                            React.createElement(Typography, { variant: "h6", align: "center", style: { lineHeight: "60px" } }, "DASH Realty")))))),
        React.createElement(Box, { position: isDashUser ? undefined : "fixed", top: isDashUser ? undefined : `${offsetY + 10}px`, width: isDashUser ? "100%" : "100vw", height: isDashUser ? "100%" : `calc(100vh - ${offsetY}px)`, p: 2 },
            React.createElement(Grid2, { container: true, spacing: 2 },
                isUnsubscribing && (React.createElement(Grid2, { size: 12 },
                    React.createElement(CoreLoading, null))),
                unsubscribed && !isUnsubscribing && (React.createElement(Grid2, { size: 12 },
                    React.createElement(Typography, { align: "center", variant: "h6" }, "You have been unsubscribed successfully."))),
                !unsubscribed && !isUnsubscribing && (React.createElement(React.Fragment, null,
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(Typography, { align: "center", variant: "h5" }, "Please enter your email below to confirm unsubscription.")),
                    React.createElement(Grid2, { size: 12, style: { display: "flex" } },
                        React.createElement(Box, { width: "100%", height: "100%", maxWidth: "400px", display: "flex", ml: "auto", mr: "auto", mt: 4 },
                            React.createElement(TextField, { fullWidth: true, variant: "outlined", size: "small", value: email !== null && email !== void 0 ? email : "", placeholder: "Email Address", onChange: handleEmailChanged, style: {
                                    padding: "8px",
                                    marginBottom: "auto",
                                    marginTop: "auto",
                                    display: "flex",
                                } }),
                            React.createElement(Box, { display: "flex", width: "75px", mt: "auto", mb: "auto" },
                                React.createElement(Button, { variant: "contained", size: "small", disabled: !isValid, onClick: unsubscribe },
                                    React.createElement(Typography, null, "Submit")))))))))));
};
export default Unsubscribe;
