import * as React from "react";
import { Grid2 } from "@mui/material";
import ActiveCall from "./calls/ActiveCall";
import Dialer from "./calls/Dialer";
import IncomingCall from "./calls/IncomingCall";
import SMS from "./SMS";
import { TwilioCallContext } from "../../context/twilio-calls/TwilioCallContext";
import { TwilioSMSContext } from "../../context/twilio-sms/TwilioSMSContext";
const Overlay = () => {
    const smsContext = React.useContext(TwilioSMSContext);
    const callContext = React.useContext(TwilioCallContext);
    const calls = callContext.calls.map((x) => x.call).filter((x) => x.status() !== "closed");
    const incomingCalls = calls.filter((call) => ["ringing", "pending"].includes(call.status()) && call.direction === "INCOMING");
    const activeCalls = calls.filter((call) => call.direction === "OUTGOING" || call.status() === "open");
    return (React.createElement(Grid2, { container: true, direction: "row", spacing: 2, wrap: "nowrap", style: { position: "fixed", right: "5px", bottom: "5px", zIndex: 1050, pointerEvents: "none" }, alignItems: "flex-end" },
        smsContext.conversations.length > 0 && (React.createElement(Grid2, null,
            React.createElement(SMS, null))),
        (callContext.isDialWidgetOpen || activeCalls.length > 0 || incomingCalls.length > 0) && (React.createElement(Grid2, null,
            incomingCalls.length > 0 && (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 }, incomingCalls.map((call, i) => (React.createElement(Grid2, { key: i },
                React.createElement(IncomingCall, { call: call })))))),
            activeCalls.length > 0 && (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 1 }, activeCalls.map((call, i) => (React.createElement(Grid2, { key: i },
                React.createElement(ActiveCall, { call: call })))))),
            callContext.isDialWidgetOpen && (React.createElement(Grid2, null,
                React.createElement(Dialer, null)))))));
};
export default Overlay;
