/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get Mail Schedule Errors
 */
export const listMailScheduleErrorsForBoxes = (params, options) => {
    return axios.get(`/api/mailschedule/list_errors`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListMailScheduleErrorsForBoxesQueryKey = (params) => {
    return [`/api/mailschedule/list_errors`, ...(params ? [params] : [])];
};
export const getListMailScheduleErrorsForBoxesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailScheduleErrorsForBoxesQueryKey(params);
    const queryFn = ({ signal }) => listMailScheduleErrorsForBoxes(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get Mail Schedule Errors
 */
export function useListMailScheduleErrorsForBoxes(params, options) {
    const queryOptions = getListMailScheduleErrorsForBoxesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get Mail Schedule History
 */
export const listMailScheduleHistoryForBoxes = (params, options) => {
    return axios.get(`/api/mailschedule/list_history`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListMailScheduleHistoryForBoxesQueryKey = (params) => {
    return [`/api/mailschedule/list_history`, ...(params ? [params] : [])];
};
export const getListMailScheduleHistoryForBoxesQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMailScheduleHistoryForBoxesQueryKey(params);
    const queryFn = ({ signal }) => listMailScheduleHistoryForBoxes(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get Mail Schedule History
 */
export function useListMailScheduleHistoryForBoxes(params, options) {
    const queryOptions = getListMailScheduleHistoryForBoxesQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
