import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import pickBy from "lodash/pickBy";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { enqueueErrorSnackbar } from "@app/common/snackbars";
import useErrorDialog from "@app/hooks/useErrorDialog";
import useFileUploads from "@app/hooks/useFileUploads";
import useStateCallback from "@app/hooks/useStateCallback";
import useStateWithCallback from "@app/hooks/useStateCallback";
import { useCreatePspWithMarketAnalysisForSellerLead } from "@app/orval/api/buyer-leads";
import { useListMessagesForSellerLead } from "@app/orval/api/clients";
import { useAddComment, useDeleteComment, useUpdateComment, listComments } from "@app/orval/api/comments";
import { useListFields } from "@app/orval/api/field-definitions";
import { useListFieldSections } from "@app/orval/api/field-sections";
import { searchFiles, useDeleteFile } from "@app/orval/api/files";
import { useListMailScheduleErrorsForBoxes, useListMailScheduleHistoryForBoxes } from "@app/orval/api/mail-schedule";
import { listNewsfeedFieldValueUpdates, listNewsfeedSellerContractStageChanges, listNewsfeedSellerLeadNoteChanges, listNewsfeedSellerLeadStageChanges, listNewsfeedSellerLeadAgentChanges, } from "@app/orval/api/newsfeed";
import { searchNotifications } from "@app/orval/api/notifications";
import { useCreatePsp } from "@app/orval/api/psps";
import { useAddSellerContract, useDeleteSellerContract, getSellerContract, searchSellerContracts, } from "@app/orval/api/seller-contracts";
import { useUpdateSellerLeadAgent, useAddSellerLead, useAddSellerLeadAgent, useAddSellerLeadClient, useAddSellerLeadPsp, useAddSellerLeadShowing, useAddSellerLeadVendor, useDeleteSellerLead, useGetSellerLead, useListCallsForSellerLead, useListEmailsForSellerLead, useRemoveSellerLeadAgent, useRemoveSellerLeadClient, useRemoveSellerLeadPsp, useRemoveSellerLeadShowing, useRemoveSellerLeadVendor, useReorderSellerLeadClients, useUpdateSellerLead, useUpdateSellerLeadShowing, useUpdateSellerLeadVendor, useListStreakCommentsForSellerLead, useListStreakFilesForSellerLead, } from "@app/orval/api/seller-leads";
import { useListStages } from "@app/orval/api/stages";
import { listTasks, useAddTask, useDeleteTask, useUpdateTask } from "@app/orval/api/tasks";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import useCanEditSellerLead from "./useCanEditSellerLead";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import useCollaborators from "../hooks/useCollaborators";
import useStages from "../hooks/useStages";
const ALLOWED_VISIBILITY_OPTIONS = ["Calls", "Comments", "Emails", "History", "SMS", "PSPs", "Forms"];
const useSellerLeadsApi = (uid, options) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24;
    const { search } = options;
    // STATE
    const [currentlySavingComment, setCurrentlySavingComment] = useStateCallback(null);
    const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = useStateWithCallback(new Set());
    // HOOKS
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { uploadFile } = useFileUploads();
    const { setSellerContractStage: setSellerContractStage_, setSellerLeadStage } = useStages();
    const { addCollaboratorForSellerLead, removeCollaboratorForSellerLead, updateCollaboratorForSellerLead } = useCollaborators();
    const listSellerLeadFieldsApi = useListFields({ entity_type: "Seller Lead" }, REFETCH_CACHE_PARAMS);
    const fields = (_b = (_a = listSellerLeadFieldsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const fieldsByName = useMemo(() => {
        return fields === null || fields === void 0 ? void 0 : fields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [fields]);
    const listSellerContractFieldsApi = useListFields({ entity_type: "Listing" }, REFETCH_CACHE_PARAMS);
    const sellerContractFields = (_d = (_c = listSellerContractFieldsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const sellerContractFieldsByName = useMemo(() => {
        return sellerContractFields === null || sellerContractFields === void 0 ? void 0 : sellerContractFields.reduce((acc, field) => {
            acc[field.name] = field;
            return acc;
        }, {});
    }, [sellerContractFields]);
    const listSellerLeadStagesApi = useListStages({ type: "Seller Lead" }, REFETCH_CACHE_PARAMS);
    const stages = (_f = (_e = listSellerLeadStagesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listSellerContractStagesApi = useListStages({ type: "Listing" }, REFETCH_CACHE_PARAMS);
    const sellerContractStages = (_h = (_g = listSellerContractStagesApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const listSectionsApi = useListFieldSections({ entity_type: "Seller Lead" }, REFETCH_CACHE_PARAMS);
    const sections = (_k = (_j = listSectionsApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const listSellerContractSectionsApi = useListFieldSections({ entity_type: "Listing" }, REFETCH_CACHE_PARAMS);
    const sellerContractSections = (_m = (_l = listSellerContractSectionsApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const getSellerLeadApi = useGetSellerLead(uid, REFETCH_CACHE_PARAMS);
    const sellerLead = ((_p = (_o = getSellerLeadApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null);
    const searchSellerContractsApi = usePostQuery(searchSellerContracts, { seller_lead_uid: uid, should_recalculate_formulas: true }, {});
    const sellerContracts = useMemo(() => {
        var _a, _b, _c;
        return ((_c = (_b = (_a = searchSellerContractsApi.data) === null || _a === void 0 ? void 0 : _a.data.sort((a, b) => {
            var _a, _b, _c, _d;
            if (((_a = a.stage) === null || _a === void 0 ? void 0 : _a.name) !== "Terminated" && ((_b = b.stage) === null || _b === void 0 ? void 0 : _b.name) === "Terminated")
                return -1;
            if (((_c = a.stage) === null || _c === void 0 ? void 0 : _c.name) === "Terminated" && ((_d = b.stage) === null || _d === void 0 ? void 0 : _d.name) !== "Terminated")
                return 1;
            return 0;
        })) !== null && _b !== void 0 ? _b : null) !== null && _c !== void 0 ? _c : null);
    }, [searchSellerContractsApi.data]);
    const canEdit = useCanEditSellerLead(sellerLead);
    const createSellerLeadApi = useAddSellerLead();
    const updateSellerLeadApi = useUpdateSellerLead();
    const deleteSellerLeadApi = useDeleteSellerLead();
    const createSellerContractApi = useAddSellerContract();
    const deleteSellerContractApi = useDeleteSellerContract();
    const listCommentsApi = usePostQuery(listComments, { seller_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const comments = (_r = (_q = listCommentsApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const searchNotificationsApi = usePostQuery(searchNotifications, { entity_uids: [(_s = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.uid) !== null && _s !== void 0 ? _s : "", ...((_t = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts) !== null && _t !== void 0 ? _t : []).map((x) => x.uid)] }, {}, !!sellerLead);
    const notifications = (_v = (_u = searchNotificationsApi.data) === null || _u === void 0 ? void 0 : _u.data) !== null && _v !== void 0 ? _v : null;
    const addCommentApi = useAddComment();
    const updateCommentApi = useUpdateComment();
    const deleteCommentApi = useDeleteComment();
    const listTasksApi = usePostQuery(listTasks, { seller_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const tasks = (_x = (_w = listTasksApi.data) === null || _w === void 0 ? void 0 : _w.data) !== null && _x !== void 0 ? _x : null;
    const addTaskApi = useAddTask();
    const updateTaskApi = useUpdateTask();
    const deleteTaskApi = useDeleteTask();
    const listEmailsApi = useListEmailsForSellerLead(uid, REFETCH_CACHE_PARAMS);
    const emails = (_z = (_y = listEmailsApi.data) === null || _y === void 0 ? void 0 : _y.data) !== null && _z !== void 0 ? _z : null;
    const listMessagesApi = useListMessagesForSellerLead(uid, REFETCH_CACHE_PARAMS);
    const messages = (_1 = (_0 = listMessagesApi.data) === null || _0 === void 0 ? void 0 : _0.data) !== null && _1 !== void 0 ? _1 : null;
    const listCallsApi = useListCallsForSellerLead(uid, REFETCH_CACHE_PARAMS);
    const calls = (_3 = (_2 = listCallsApi.data) === null || _2 === void 0 ? void 0 : _2.data) !== null && _3 !== void 0 ? _3 : null;
    const fieldValueChangesApi = usePostQuery(listNewsfeedFieldValueUpdates, { seller_lead_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const fieldValueChanges = (_5 = (_4 = fieldValueChangesApi.data) === null || _4 === void 0 ? void 0 : _4.data) !== null && _5 !== void 0 ? _5 : null;
    const noteChangesApi = usePostQuery(listNewsfeedSellerLeadNoteChanges, { seller_lead_uids: [uid], search }, {
        gcTime: Infinity,
    });
    const noteChanges = (_7 = (_6 = noteChangesApi.data) === null || _6 === void 0 ? void 0 : _6.data) !== null && _7 !== void 0 ? _7 : null;
    const agentChangesApi = usePostQuery(listNewsfeedSellerLeadAgentChanges, { seller_lead_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const agentChanges = (_9 = (_8 = agentChangesApi.data) === null || _8 === void 0 ? void 0 : _8.data) !== null && _9 !== void 0 ? _9 : null;
    const stageChangesApi = usePostQuery(listNewsfeedSellerLeadStageChanges, { seller_lead_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const stageChanges = (_11 = (_10 = stageChangesApi.data) === null || _10 === void 0 ? void 0 : _10.data) !== null && _11 !== void 0 ? _11 : null;
    const sellerContractStageChangesApi = usePostQuery(listNewsfeedSellerContractStageChanges, { seller_contract_uids: (_12 = sellerLead === null || sellerLead === void 0 ? void 0 : sellerLead.contracts.map((x) => x.uid)) !== null && _12 !== void 0 ? _12 : [] }, REFETCH_CACHE_PARAMS, !!(sellerLead && sellerLead.contracts.length > 0));
    const sellerContractStageChanges = (_14 = (_13 = sellerContractStageChangesApi.data) === null || _13 === void 0 ? void 0 : _13.data) !== null && _14 !== void 0 ? _14 : null;
    const streakCommentsApi = useListStreakCommentsForSellerLead(uid, REFETCH_CACHE_PARAMS);
    const streakComments = (_16 = (_15 = streakCommentsApi.data) === null || _15 === void 0 ? void 0 : _15.data) !== null && _16 !== void 0 ? _16 : null;
    const streakFilesApi = useListStreakFilesForSellerLead(uid, REFETCH_CACHE_PARAMS);
    const streakFiles = (_18 = (_17 = streakFilesApi.data) === null || _17 === void 0 ? void 0 : _17.data) !== null && _18 !== void 0 ? _18 : null;
    const listFilesApi = usePostQuery(searchFiles, { seller_uids: [uid], search }, REFETCH_CACHE_PARAMS);
    const files = (_20 = (_19 = listFilesApi.data) === null || _19 === void 0 ? void 0 : _19.data) !== null && _20 !== void 0 ? _20 : null;
    const deleteFileApi = useDeleteFile();
    const addAgentApi = useAddSellerLeadAgent();
    const updateAgentApi = useUpdateSellerLeadAgent();
    const removeAgentApi = useRemoveSellerLeadAgent();
    const addClientApi = useAddSellerLeadClient();
    const removeClientApi = useRemoveSellerLeadClient();
    const reorderClientsApi = useReorderSellerLeadClients();
    const addShowingApi = useAddSellerLeadShowing();
    const updateShowingApi = useUpdateSellerLeadShowing();
    const removeShowingApi = useRemoveSellerLeadShowing();
    const addThirdPartyApi = useAddSellerLeadVendor();
    const updateThirdPartyApi = useUpdateSellerLeadVendor();
    const removeThirdPartyApi = useRemoveSellerLeadVendor();
    const addPSPApi = useCreatePsp();
    const linkPSPApi = useAddSellerLeadPsp();
    const removePSPApi = useRemoveSellerLeadPsp();
    const createPspWithMarketAnalysisApi = useCreatePspWithMarketAnalysisForSellerLead();
    const isLoading = createSellerLeadApi.isPending || deleteSellerLeadApi.isPending;
    const onLeadUpdate = useCallback((result) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, result);
    }, [getSellerLeadApi.queryKey, queryClient]);
    const addCollaborator = useCallback((params) => {
        addCollaboratorForSellerLead(uid, params, {
            onSaveComplete: onLeadUpdate,
        });
    }, [addCollaboratorForSellerLead, onLeadUpdate, uid]);
    const updateCollaborator = useCallback((collaboratorUid, params) => {
        updateCollaboratorForSellerLead(uid, collaboratorUid, params, {
            onUpdate: onLeadUpdate,
        });
    }, [onLeadUpdate, uid, updateCollaboratorForSellerLead]);
    const removeCollaborator = useCallback((collaboratorUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { collaborators: old.data.collaborators.filter((a) => a.uid !== collaboratorUid) }) });
        });
        removeCollaboratorForSellerLead(uid, collaboratorUid, {
            onRemove: onLeadUpdate,
        });
    }, [getSellerLeadApi.queryKey, onLeadUpdate, queryClient, removeCollaboratorForSellerLead, uid]);
    const addAgent = useCallback((params) => {
        addAgentApi
            .mutateAsync({ sellerLeadUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addAgentApi, errorDialog, getSellerLeadApi.queryKey, queryClient, uid]);
    const updateAgent = useCallback((agentUid, params) => {
        updateAgentApi
            .mutateAsync({ uid: agentUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, updateAgentApi]);
    const removeAgent = useCallback((agentUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { agents: old.data.agents.filter((a) => a.uid !== agentUid) }) });
        });
        removeAgentApi
            .mutateAsync({ uid: agentUid })
            .then((result) => queryClient.setQueryData(getSellerLeadApi.queryKey, result))
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, removeAgentApi]);
    const addNewClient = useCallback((clientData) => {
        addClientApi
            .mutateAsync({
            sellerLeadUid: uid,
            data: {
                contact_data: {
                    fields: clientData,
                },
            },
        })
            .then((result) => {
            listEmailsApi.refetch();
            listMessagesApi.refetch();
            listCallsApi.refetch();
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [
        addClientApi,
        errorDialog,
        getSellerLeadApi.queryKey,
        listCallsApi,
        listEmailsApi,
        listMessagesApi,
        queryClient,
        uid,
    ]);
    const addExistingClient = useCallback((clientUid) => {
        addClientApi
            .mutateAsync({
            sellerLeadUid: uid,
            data: {
                contact_uid: clientUid,
            },
        })
            .then((result) => {
            listEmailsApi.refetch();
            listMessagesApi.refetch();
            listCallsApi.refetch();
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [
        addClientApi,
        errorDialog,
        getSellerLeadApi.queryKey,
        listCallsApi,
        listEmailsApi,
        listMessagesApi,
        queryClient,
        uid,
    ]);
    const removeClient = useCallback((clientUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { clients: old.data.clients.filter((c) => c.uid !== clientUid) }) });
        });
        removeClientApi
            .mutateAsync({
            sellerLeadUid: uid,
            data: {
                contact_uid: clientUid,
            },
        })
            .then((result) => {
            listEmailsApi.refetch();
            listMessagesApi.refetch();
            listCallsApi.refetch();
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [
        errorDialog,
        getSellerLeadApi.queryKey,
        listCallsApi,
        listEmailsApi,
        listMessagesApi,
        queryClient,
        removeClientApi,
        uid,
    ]);
    const reorderClients = useCallback((clientUids) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { clients: [...old.data.clients].sort((a, b) => {
                        const aIndx = clientUids.indexOf(a.uid);
                        const bIndx = clientUids.indexOf(b.uid);
                        if (aIndx < bIndx)
                            return -1;
                        if (aIndx > bIndx)
                            return 1;
                        return 0;
                    }) }) });
        });
        reorderClientsApi
            .mutateAsync({
            sellerLeadUid: uid,
            data: {
                client_uids: clientUids,
            },
        })
            .then(console.log)
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, reorderClientsApi, uid]);
    const addShowing = useCallback((params) => {
        addShowingApi
            .mutateAsync({ sellerLeadUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addShowingApi, errorDialog, getSellerLeadApi.queryKey, queryClient, uid]);
    const updateShowing = useCallback((showingUid, params) => {
        updateShowingApi
            .mutateAsync({ uid: showingUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, updateShowingApi]);
    const removeShowing = useCallback((showingUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { showings: old.data.showings.filter((s) => s.uid !== uid) }) });
        });
        removeShowingApi
            .mutateAsync({ uid: showingUid })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, removeShowingApi, uid]);
    const addThirdParty = useCallback((params) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        addThirdPartyApi
            .mutateAsync({ sellerLeadUid: uid, data: params })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [addThirdPartyApi, errorDialog, getSellerLeadApi.queryKey, queryClient, uid]);
    const updateThirdParty = useCallback((vendorUid, params) => {
        const data = Object.assign({}, params);
        if (!data.contact_data) {
            delete data.contact_data;
        }
        if (!data.contact_uid) {
            delete data.contact_uid;
        }
        updateThirdPartyApi
            .mutateAsync({ uid: vendorUid, data: params })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, updateThirdPartyApi]);
    const removeThirdParty = useCallback((vendorUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { vendors: old.data.vendors.filter((v) => v.uid !== vendorUid) }) });
        });
        removeThirdPartyApi
            .mutateAsync({ uid: vendorUid })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, removeThirdPartyApi]);
    const startNewPSP = useCallback(() => {
        var _a;
        addPSPApi
            .mutateAsync({
            data: {
                fields: {
                    "Property Address": (_a = sellerLead.name) !== null && _a !== void 0 ? _a : undefined, // TODO: maybe break down the address here
                },
                seller_lead_uid: uid,
            },
        })
            .then((result) => {
            navigate(result.data.uid);
        })
            .catch(errorDialog);
    }, [addPSPApi, errorDialog, navigate, sellerLead, uid]);
    const onMarketAnalysisClicked = useCallback(() => {
        createPspWithMarketAnalysisApi
            .mutateAsync({
            sellerLeadUid: uid,
        })
            .then((result) => {
            navigate(`/matrix/${result.data.uid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [createPspWithMarketAnalysisApi, navigate, uid]);
    const linkPSP = useCallback((pspUid) => {
        linkPSPApi
            .mutateAsync({ sellerLeadUid: uid, pspUid })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, linkPSPApi, queryClient, uid]);
    const removePSP = useCallback((pspUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { psps: old.data.psps.filter((psp) => psp.uid !== pspUid) }) });
        });
        removePSPApi
            .mutateAsync({ sellerLeadUid: uid, pspUid })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, removePSPApi, uid]);
    const startNewSellerContract = useCallback(() => {
        createSellerContractApi
            .mutateAsync({ data: { seller_lead_uid: uid } })
            .then((result) => {
            queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
            getSellerLeadApi.refetch();
        })
            .catch(errorDialog);
    }, [createSellerContractApi, errorDialog, getSellerLeadApi, queryClient, searchSellerContractsApi.queryKey, uid]);
    const deleteSellerContract = useCallback((sellerContractUid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== sellerContractUid) });
                });
                deleteSellerContractApi
                    .mutateAsync({ uid: sellerContractUid })
                    .then(() => {
                    getSellerLeadApi.refetch();
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteSellerContractApi, errorDialog, getSellerLeadApi, queryClient, searchSellerContractsApi.queryKey]);
    const deleteSellerLead = useCallback(() => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                deleteSellerLeadApi
                    .mutateAsync({ uid })
                    .then(() => {
                    enqueueSnackbar("Deleted seller lead", { variant: "success" });
                    navigate("/seller-leads");
                })
                    .catch(errorDialog);
            }
        });
    }, [confirm, deleteSellerLeadApi, errorDialog, navigate, uid]);
    const onSmsSent = useCallback(() => {
        listMessagesApi.refetch();
    }, [listMessagesApi]);
    const onStageUpdateBegin = useCallback((_, stageUid) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { stage: Object.assign(Object.assign({}, (stages !== null && stages !== void 0 ? stages : []).find((x) => x.uid === stageUid)), { updated_at: new Date().toISOString() }) }) });
        });
    }, [stages, getSellerLeadApi.queryKey, queryClient]);
    const onStageUpdateError = useCallback(() => {
        getSellerLeadApi.refetch();
    }, [getSellerLeadApi]);
    const onStageUpdate = useCallback((result) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, result);
    }, [getSellerLeadApi.queryKey, queryClient]);
    const onSellerContractCreated = useCallback((result) => {
        queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
        });
        getSellerLeadApi.refetch();
    }, [getSellerLeadApi, queryClient, searchSellerContractsApi.queryKey]);
    const setStage = useCallback((stageUid, params) => {
        var _a;
        setSellerLeadStage(uid, stageUid, {
            onUpdateBegin: onStageUpdateBegin,
            onError: onStageUpdateError,
            onUpdate: onStageUpdate,
            onSellerContractCreated,
            overrideValidator: (_a = params === null || params === void 0 ? void 0 : params.overrideValidator) !== null && _a !== void 0 ? _a : false,
        });
    }, [onSellerContractCreated, onStageUpdate, onStageUpdateBegin, onStageUpdateError, setSellerLeadStage, uid]);
    const onSellerLeadUpdateError = useCallback(() => {
        getSellerLeadApi.refetch();
    }, [getSellerLeadApi]);
    const onSellerContractStageUpdateBegin = useCallback((sellerContractUid, stageUid) => {
        queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.findIndex((sellerContract) => sellerContract.uid === sellerContractUid);
            const data = [
                ...old.data.slice(0, indx),
                Object.assign(Object.assign({}, old.data[indx]), { stage: Object.assign(Object.assign({}, (sellerContractStages !== null && sellerContractStages !== void 0 ? sellerContractStages : []).find((s) => s.uid === stageUid)), { updated_at: new Date().toISOString() }) }),
                ...old.data.slice(indx + 1, old.data.length),
            ];
            return Object.assign(Object.assign({}, old), { data });
        });
    }, [sellerContractStages, queryClient, searchSellerContractsApi.queryKey]);
    const onSellerContractUpdate = useCallback((result) => {
        queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.findIndex((x) => x.uid === result.data.uid);
            return Object.assign(Object.assign({}, old), { data: [...old.data.slice(0, indx), result.data, ...old.data.slice(indx + 1, old.data.length)] });
        });
    }, [queryClient, searchSellerContractsApi.queryKey]);
    const onSellerContractStageUpdateError = useCallback((contract) => () => {
        queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.findIndex((x) => x.uid === contract.uid);
            return Object.assign(Object.assign({}, old), { data: [...old.data.slice(0, indx), contract, ...old.data.slice(indx + 1, old.data.length)] });
        });
    }, [queryClient, searchSellerContractsApi.queryKey]);
    const setSellerContractStage = useCallback((buyerContractUid) => (stageUid, params) => {
        var _a;
        const overrideValidator = (_a = params === null || params === void 0 ? void 0 : params.overrideValidator) !== null && _a !== void 0 ? _a : false;
        const dataBeforeUpdate = sellerContracts.find((x) => x.uid === buyerContractUid);
        setSellerContractStage_(buyerContractUid, stageUid, {
            onUpdateBegin: onSellerContractStageUpdateBegin,
            onError: onSellerContractStageUpdateError(dataBeforeUpdate),
            onUpdate: onSellerContractUpdate,
            overrideValidator,
        });
    }, [
        sellerContracts,
        setSellerContractStage_,
        onSellerContractStageUpdateBegin,
        onSellerContractStageUpdateError,
        onSellerContractUpdate,
    ]);
    const setNotes = useCallback((notes) => {
        updateSellerLeadApi
            .mutateAsync({ uid, data: { notes } })
            .then((result) => {
            queryClient.setQueryData(getSellerLeadApi.queryKey, result);
        })
            .catch(errorDialog);
    }, [errorDialog, getSellerLeadApi.queryKey, queryClient, uid, updateSellerLeadApi]);
    const onFieldSaveBegin = useCallback((fieldName, fieldValue) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), { [fieldName]: fieldValue }) }) });
        });
    }, [getSellerLeadApi.queryKey, queryClient]);
    const onFieldSaveComplete = useCallback((response) => {
        queryClient.setQueryData(getSellerLeadApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { fields: Object.assign(Object.assign({}, old.data.fields), Object.fromEntries(Object.entries(response.data.fields).filter(([name, _]) => {
                        var _a;
                        return (_a = fieldsByName === null || fieldsByName === void 0 ? void 0 : fieldsByName[name]) === null || _a === void 0 ? void 0 : _a.formula;
                    }))) }) });
        });
    }, [fieldsByName, getSellerLeadApi.queryKey, queryClient]);
    const onFieldSaveError = useCallback((error) => {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            confirm({
                title: "Unable to save field",
                description: String(error.response.data),
                hideCancelButton: true,
                confirmationText: "Ok",
            });
        }
        getSellerLeadApi.refetch();
    }, [confirm, getSellerLeadApi]);
    const onSellerContractFieldSaveBegin = useCallback((sellerContractUid) => (fieldName, fieldValue) => {
        queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.findIndex((x) => x.uid === sellerContractUid);
            const data = [
                ...old.data.slice(0, indx),
                Object.assign(Object.assign({}, old.data[indx]), { fields: Object.assign(Object.assign({}, old.data[indx].fields), { [fieldName]: fieldValue }) }),
                ...old.data.slice(indx + 1, old.data.length),
            ];
            return Object.assign(Object.assign({}, old), { data });
        });
    }, [queryClient, searchSellerContractsApi.queryKey]);
    const onSellerContractFieldSaveComplete = useCallback((sellerContractUid) => (response) => {
        queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            const indx = old.data.findIndex((x) => x.uid === sellerContractUid);
            const data = [
                ...old.data.slice(0, indx),
                Object.assign(Object.assign({}, old.data[indx]), { fields: Object.assign(Object.assign({}, old.data[indx].fields), Object.fromEntries(Object.entries(response.data.fields).filter(([name, _]) => {
                        var _a;
                        return (_a = sellerContractFieldsByName === null || sellerContractFieldsByName === void 0 ? void 0 : sellerContractFieldsByName[name]) === null || _a === void 0 ? void 0 : _a.formula;
                    }))) }),
                ...old.data.slice(indx + 1, old.data.length),
            ];
            return Object.assign(Object.assign({}, old), { data });
        });
    }, [sellerContractFieldsByName, queryClient, searchSellerContractsApi.queryKey]);
    const onSellerContractFieldSaveError = useCallback((sellerContractUid) => (error) => {
        var _a;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            confirm({
                title: "Unable to save field",
                description: String(error.response.data),
                hideCancelButton: true,
                confirmationText: "Ok",
            });
        }
        getSellerContract(sellerContractUid)
            .then((result) => {
            queryClient.setQueryData(searchSellerContractsApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((x) => (x.uid === sellerContractUid ? result.data : x)) });
            });
        })
            .catch(console.error);
    }, [confirm, queryClient, searchSellerContractsApi.queryKey]);
    /**
     * Saves a comment via API
     * @param comment
     */
    const saveComment = useCallback((comment) => {
        setCurrentlySavingComment(comment);
        const { content, reply_to } = comment;
        const commentUid = comment.uid;
        if (commentUid) {
            updateCommentApi
                .mutateAsync({
                uid: commentUid,
                data: {
                    content,
                },
            })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.map((c) => (c.uid === commentUid ? result.data : c)) });
                });
                setCurrentlySavingComment(null);
            })
                .catch((error) => {
                setCurrentlySavingComment(null);
                errorDialog(error);
            });
        }
        else {
            const data = Object.assign({ content }, pickBy({
                reply_to,
                entity_uid: uid,
            }, (val) => val !== undefined));
            addCommentApi
                .mutateAsync({
                data,
            })
                .then((result) => {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: [result.data, ...old.data] });
                });
                setCurrentlySavingComment(null);
            })
                .catch((error) => {
                setCurrentlySavingComment(null);
                errorDialog(error);
            });
        }
    }, [
        addCommentApi,
        errorDialog,
        listCommentsApi.queryKey,
        queryClient,
        setCurrentlySavingComment,
        uid,
        updateCommentApi,
    ]);
    /**
     * Deletes the specified comment via API
     * @param commentUid
     */
    const deleteComment = useCallback((commentUid) => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(listCommentsApi.queryKey, (old) => {
                    if (!old) {
                        return old;
                    }
                    return Object.assign(Object.assign({}, old), { data: old.data.filter((comment) => comment.uid !== commentUid) });
                });
                deleteCommentApi.mutateAsync({ uid: commentUid }).then(console.log).catch(console.error);
            }
        });
    }, [confirm, deleteCommentApi, listCommentsApi.queryKey, queryClient]);
    const createTask = useCallback((task) => {
        addTaskApi
            .mutateAsync({
            data: Object.assign(Object.assign({}, task), { entity_uid: uid }),
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [addTaskApi, listTasksApi, queryClient, uid]);
    const updateTask = useCallback((taskUid, task) => {
        updateTaskApi
            .mutateAsync({
            uid: taskUid,
            data: task,
        })
            .then((result) => {
            queryClient.setQueryData(listTasksApi.queryKey, (old) => {
                if (!old) {
                    return old;
                }
                return Object.assign(Object.assign({}, old), { data: old.data.map((x) => (x.uid === taskUid ? result.data : x)) });
            });
        })
            .catch(enqueueErrorSnackbar);
    }, [listTasksApi, queryClient, updateTaskApi]);
    const deleteTask = useCallback((taskUid) => {
        queryClient.setQueryData(listTasksApi.queryKey, (old) => {
            if (!old) {
                return old;
            }
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== taskUid) });
        });
        deleteTaskApi
            .mutateAsync({ uid: taskUid })
            .then(() => {
            console.log(`deleted task ${taskUid}`);
        })
            .catch(enqueueErrorSnackbar);
    }, [deleteTaskApi, listTasksApi, queryClient]);
    const onFilesSelectedForUpload = useCallback((fileList) => {
        const filesToUpload = Array.from(fileList);
        setCurrentlyUploadingFiles((prev) => new Set([...prev, ...filesToUpload.map((x) => x.name)]));
        for (const file of filesToUpload) {
            console.log({ file });
            uploadFile({
                sellerLeadUid: uid,
                file,
            })
                .then((file_) => {
                setCurrentlyUploadingFiles((prev) => new Set([...prev].filter((fname) => fname !== file_.filename)));
                queryClient.setQueryData(listFilesApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), { data: [...old.data, file_] });
                });
            })
                .catch(enqueueErrorSnackbar);
        }
    }, [uploadFile, uid, queryClient, listFilesApi.queryKey, setCurrentlyUploadingFiles]);
    const onFileDeleted = useCallback((fileUid) => {
        queryClient.setQueryData(listFilesApi.queryKey, (old) => {
            if (!old)
                return old;
            return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== fileUid) });
        });
        deleteFileApi.mutateAsync({ uid: fileUid }).then(console.log).catch(enqueueErrorSnackbar);
    }, [queryClient, listFilesApi.queryKey, deleteFileApi]);
    const isAddingPSP = addPSPApi.isPending || linkPSPApi.isPending || createPspWithMarketAnalysisApi.isPending;
    const sellerContractUids = sellerContracts === null || sellerContracts === void 0 ? void 0 : sellerContracts.map((x) => x.uid);
    const getMailScheduleHistoryApi = useListMailScheduleHistoryForBoxes(pickBy({
        seller_lead_uids: uid,
        seller_contract_uids: sellerContractUids === null || sellerContractUids === void 0 ? void 0 : sellerContractUids.join(","),
    }, (val) => !!val));
    const mailScheduleHistory = (_22 = (_21 = getMailScheduleHistoryApi.data) === null || _21 === void 0 ? void 0 : _21.data) !== null && _22 !== void 0 ? _22 : null;
    const getMailScheduleErrorsApi = useListMailScheduleErrorsForBoxes(pickBy({
        seller_lead_uids: uid,
        seller_contract_uids: sellerContractUids === null || sellerContractUids === void 0 ? void 0 : sellerContractUids.join(","),
    }, (val) => !!val));
    const mailScheduleErrors = (_24 = (_23 = getMailScheduleErrorsApi.data) === null || _23 === void 0 ? void 0 : _23.data) !== null && _24 !== void 0 ? _24 : null;
    return {
        currentlySavingComment,
        fields,
        sellerContractFields,
        comments,
        sellerContractStages,
        emails,
        messages,
        calls,
        fieldValueChanges,
        noteChanges,
        streakComments,
        streakFiles,
        stages,
        sections,
        sellerLead,
        sellerContracts,
        mailScheduleHistory,
        mailScheduleErrors,
        notifications,
        isLoading,
        addCollaborator,
        updateCollaborator,
        removeCollaborator,
        addAgent,
        updateAgent,
        removeAgent,
        addNewClient,
        addExistingClient,
        removeClient,
        reorderClients,
        addShowing,
        updateShowing,
        removeShowing,
        addThirdParty,
        updateThirdParty,
        removeThirdParty,
        startNewPSP,
        linkPSP,
        removePSP,
        sellerContractSections,
        stageChanges,
        sellerContractStageChanges,
        startNewSellerContract,
        deleteSellerContract,
        deleteSellerLead,
        onSmsSent,
        setStage,
        setSellerContractStage,
        setNotes,
        onFieldSaveBegin,
        onFieldSaveComplete,
        onFieldSaveError,
        onSellerContractFieldSaveBegin,
        onSellerContractFieldSaveComplete,
        onSellerContractFieldSaveError,
        saveComment,
        agentChanges,
        deleteComment,
        isAddingPSP,
        onMarketAnalysisClicked,
        getSellerLeadApi,
        tasks,
        createTask,
        updateTask,
        deleteTask,
        onLeadUpdate,
        onSellerContractStageUpdateBegin,
        onSellerContractUpdate,
        onSellerContractStageUpdateError,
        onSellerLeadUpdateError,
        onFilesSelectedForUpload,
        onFileDeleted,
        files,
        currentlyUploadingFiles,
        canEdit,
    };
};
export default useSellerLeadsApi;
