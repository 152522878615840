import * as React from "react";
import { Edit, Launch, PushPin, Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import RouterLink from "@app/common/RouterLink";
import { getViewUrl } from "./utils";
const ViewsActionCell = (props) => {
    const { view } = props;
    const viewUrl = React.useMemo(() => {
        const url = getViewUrl({ uid: view.uid, entity_type: view.entity_type });
        return url;
    }, [view.entity_type, view.uid]);
    return (React.createElement(IconButton, { size: "small", component: RouterLink, to: viewUrl, style: { padding: 0 }, disableRipple: true },
        React.createElement(Launch, { fontSize: "small" })));
};
export const renderActionCell = (params) => {
    const view = params.row;
    return React.createElement(ViewsActionCell, { view: view });
};
const SharingVisibility = (props) => {
    const { is_editable, is_pinned, is_shared } = props;
    return (React.createElement(Grid, { container: true, spacing: 0, wrap: "nowrap" },
        is_pinned && (React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Pinned") },
                React.createElement(PushPin, { fontSize: "small", style: { color: "#006633" } })))),
        is_editable && (React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Editable") },
                React.createElement(Edit, { fontSize: "small", style: { color: "#ff6666" } })))),
        is_shared && !is_pinned && (React.createElement(Grid, { item: true },
            React.createElement(Tooltip, { title: React.createElement(Typography, null, "Visible") },
                React.createElement(Visibility, { fontSize: "small", style: { color: "#6666ff" } }))))));
};
export const renderFiltersCell = (params) => {
    const view = params.row;
    const filters = view.filter_groups.map((group) => group.filters).flat();
    const descriptions = filters.map((filter) => filter.description);
    return descriptions.filter((x) => x.trim()).join("; ");
};
export const renderSharingCell = (params) => {
    var _a, _b, _c;
    const view = params.row;
    return (React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap" },
        (view.sharing.all_users.is_editable || view.sharing.all_users.is_pinned || view.sharing.all_users.is_shared) && (React.createElement(Grid, { item: true, container: true, spacing: 1, wrap: "nowrap" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, "All users")),
            React.createElement(Grid, { item: true },
                React.createElement(SharingVisibility, Object.assign({}, view.sharing.all_users))))),
        ((_a = view.sharing.roles) !== null && _a !== void 0 ? _a : [])
            .filter((role) => role.is_editable || role.is_pinned || role.is_shared)
            .map((role) => (React.createElement(Grid, { key: role.role, item: true, container: true, spacing: 1, wrap: "nowrap" },
            React.createElement(Grid, { item: true },
                React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, role.role)),
            React.createElement(Grid, { item: true },
                React.createElement(SharingVisibility, Object.assign({}, role)))))),
        ((_b = view.sharing.offices) !== null && _b !== void 0 ? _b : [])
            .filter((office) => office.office)
            .filter((office) => office.is_editable || office.is_pinned || office.is_shared)
            .map((office) => {
            var _a, _b;
            return (React.createElement(Grid, { key: (_a = office.office) === null || _a === void 0 ? void 0 : _a.uid, item: true, container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, (_b = office.office) === null || _b === void 0 ? void 0 : _b.name)),
                React.createElement(Grid, { item: true },
                    React.createElement(SharingVisibility, Object.assign({}, office)))));
        }),
        ((_c = view.sharing.users) !== null && _c !== void 0 ? _c : [])
            .filter((user) => user.user)
            .filter((user) => user.is_editable || user.is_pinned || user.is_shared)
            .map((user) => {
            var _a, _b;
            return (React.createElement(Grid, { key: (_a = user.user) === null || _a === void 0 ? void 0 : _a.uid, item: true, container: true, spacing: 1, wrap: "nowrap" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2", style: { color: "#666" } }, (_b = user.user) === null || _b === void 0 ? void 0 : _b.name)),
                React.createElement(Grid, { item: true },
                    React.createElement(SharingVisibility, Object.assign({}, user)))));
        })));
};
