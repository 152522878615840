import * as React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { FilterContext } from "../FilterContext";
const SavedViewNotesFilter = (props) => {
    var _a, _b;
    const { uid } = props;
    const context = React.useContext(FilterContext);
    const filter = context.filterGroups
        .reduce((acc, fg) => {
        return [...acc, ...fg.filters];
    }, [])
        .find((f) => f.uid === uid);
    const setComparator = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        const comparator = val;
        newFilter.notes_filter.comparator = comparator;
        if (comparator === "is not set" || comparator === "is set") {
            newFilter.notes_filter.rhs_value = undefined;
        }
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const setRHSValue = React.useCallback((e) => {
        const val = e.target.value;
        const newFilter = Object.assign({}, filter);
        newFilter.notes_filter.rhs_value = val;
        context.updateFilter(uid, newFilter);
    }, [context, filter, uid]);
    const comparators = context.notesComparators;
    return (React.createElement(Grid, { container: true, spacing: 2, wrap: "nowrap" },
        React.createElement(Grid, { item: true, style: { width: "150px" } },
            React.createElement(TextField, { select: true, size: "small", variant: "standard", fullWidth: true, value: (_a = filter.notes_filter.comparator) !== null && _a !== void 0 ? _a : "", onChange: setComparator }, (comparators !== null && comparators !== void 0 ? comparators : []).map((x) => (React.createElement(MenuItem, { key: x, value: x }, x))))),
        ["contains", "does not contain", "equals", "does not equal"].includes(filter.notes_filter.comparator) && (React.createElement(Grid, { item: true, style: { width: "200px" } },
            React.createElement(TextField, { size: "small", variant: "standard", fullWidth: true, value: (_b = filter.notes_filter.rhs_value) !== null && _b !== void 0 ? _b : "", onChange: setRHSValue })))));
};
export default SavedViewNotesFilter;
