import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Add, Delete, FileCopy, Launch, Refresh } from "@mui/icons-material";
import { IconButton, TableRow, TableCell, Tooltip, MenuItem, ListItemIcon } from "@mui/material";
import { htmlToText } from "html-to-text";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import DashPopover from "@app/common/CoreMoreMenu";
import CoreDataTable from "@app/common/grid/CoreDataTable";
import { withDialog } from "@app/hoc/withDialog";
import { withRouter } from "@app/hoc/withRouter";
import API from "@app/util/api";
class MailScheduleBoilerplateTable extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                boilerplate: null,
                pipelines: null,
                error: null,
            }
        });
        Object.defineProperty(this, "child", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: React.createRef()
        });
        Object.defineProperty(this, "abortController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Duplicates the boilerplate and redirects to the new boilerplate form on success */
        Object.defineProperty(this, "duplicateBoilerplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                const loadingSnackbar = enqueueSnackbar("Duplicating boilerplate...", {
                    variant: "info",
                    persist: true,
                });
                API.mailScheduleBoilerplate.duplicate(uid, {
                    onSuccess: (result) => {
                        enqueueSnackbar("Boilerplate has been duplicated.", { variant: "success" });
                        closeSnackbar(loadingSnackbar);
                        this.props.navigate(`/mailschedule/boilerplate/${result.uid}`);
                    },
                    onError: (error) => {
                        closeSnackbar(loadingSnackbar);
                        this.props.showErrorDialog(error);
                    },
                });
            }
        });
        /** Deletes the selected boilerplate */
        Object.defineProperty(this, "deleteBoilerplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (uid) => () => {
                var _a;
                // @ts-expect-error: Property 'handleClose' does not exist on type 'HTMLElement'
                (_a = this.child.current) === null || _a === void 0 ? void 0 : _a.handleClose();
                this.props.showDialog({
                    title: "Are you sure?",
                    text: "You won't be able to undo this action.",
                    confirmButtonText: "Delete",
                    confirmButtonColor: "secondary",
                    onConfirm: () => {
                        this.props.hideDialog();
                        this.setState((prevState) => ({
                            boilerplate: prevState.boilerplate.filter((item) => item.uid !== uid),
                        }));
                        const loadingSnackbar = enqueueSnackbar("Deleting boilerplate...", {
                            variant: "info",
                            persist: true,
                        });
                        API.mailScheduleBoilerplate.delete(uid, {
                            onSuccess: () => {
                                enqueueSnackbar("Boilerplate has been deleted", { variant: "success" });
                                closeSnackbar(loadingSnackbar);
                            },
                            onError: (error) => {
                                this.props.showErrorDialog(error);
                                closeSnackbar(loadingSnackbar);
                            },
                        });
                    },
                });
            }
        });
        /** Refreshes data in the table */
        Object.defineProperty(this, "refresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                this.setState({
                    boilerplate: null,
                    error: null,
                });
                (_a = this.abortController) === null || _a === void 0 ? void 0 : _a.abort();
                this.abortController = new AbortController();
                API.mailScheduleBoilerplate.list({
                    onSuccess: (result) => {
                        this.setState({
                            boilerplate: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUpdateCache: true,
                });
                API.streakPipelines.list({
                    onSuccess: (result) => {
                        this.setState({
                            pipelines: result,
                        });
                    },
                    onError: (error) => {
                        if (error.name !== "AbortError") {
                            this.setState({
                                error,
                            });
                        }
                    },
                    options: {
                        signal: this.abortController.signal,
                    },
                    shouldUseCache: true,
                    shouldUpdateCache: true,
                });
            }
        });
    }
    componentDidMount() {
        this.refresh();
    }
    render() {
        const { boilerplate, error, pipelines } = this.state;
        if (error) {
            return React.createElement(CoreError, { error: error });
        }
        if (!boilerplate || !pipelines) {
            return React.createElement(CoreLoading, null);
        }
        const rows = boilerplate.map((item) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({}, item), { pipeline: (_c = (_b = (_a = pipelines.find((p) => p.key === item.pipeline)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : item.pipeline) !== null && _c !== void 0 ? _c : "" }));
        });
        const columns = [
            {
                name: "actions",
                label: " ",
                options: {
                    searchable: false,
                    sort: false,
                    download: false,
                    filter: false,
                    print: false,
                    customBodyRender: (_, tableMeta) => {
                        const item = rows[tableMeta.rowIndex];
                        return (React.createElement(DashPopover, null,
                            React.createElement(MenuItem, { component: RouterLink, to: `/mailschedule/boilerplate/${item.uid}` },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Launch, { fontSize: "small" })),
                                "Open"),
                            React.createElement(MenuItem, { onClick: this.duplicateBoilerplate(item.uid) },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(FileCopy, { fontSize: "small" })),
                                "Duplicate"),
                            React.createElement(MenuItem, { onClick: this.deleteBoilerplate(item.uid) },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(Delete, { fontSize: "small" })),
                                "Delete")));
                    },
                },
            },
            {
                name: "name",
                label: "Name",
            },
            {
                name: "pipeline",
                label: "Pipeline",
            },
            {
                name: "content",
                label: "Content",
                options: {
                    customBodyRender: (value) => {
                        if (value) {
                            const text = htmlToText(value);
                            if (text.length > 50) {
                                return `${text.substring(0, 50)}...`;
                            }
                            return text;
                        }
                        return value;
                    },
                },
            },
        ];
        const options = {
            responsive: "standard",
            selectableRows: "none",
            download: false,
            print: false,
            filter: false,
            search: false,
            searchOpen: true,
            pagination: true,
            rowsPerPage: 100,
            rowsPerPageOptions: [10, 25, 50, 100, 200, 500, 1000],
            viewColumns: false,
            expandableRows: true,
            customToolbar: () => (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: "Refresh" },
                    React.createElement(IconButton, { onClick: this.refresh },
                        React.createElement(Refresh, null))),
                React.createElement(Tooltip, { title: "Create new boilerplate" },
                    React.createElement(IconButton, { component: RouterLink, to: "/mailschedule/boilerplate/new" },
                        React.createElement(Add, null))))),
            renderExpandableRow: (_, rowMeta) => (React.createElement(TableRow, null,
                React.createElement(TableCell, { colSpan: columns.length + 1, dangerouslySetInnerHTML: { __html: boilerplate[rowMeta.dataIndex].content } }))),
        };
        return React.createElement(CoreDataTable, { title: "", data: rows, columns: columns, options: options, cellPadding: 0 });
    }
}
export default withDialog(withRouter(MailScheduleBoilerplateTable));
