import * as React from "react";
import { Launch } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TwilioSMSContext } from "@app/entrypoints/brokerage/context/twilio-sms/TwilioSMSContext";
const SMSPopOutButton = (props) => {
    const { fromPhoneNumber, toPhoneNumber } = props;
    const context = React.useContext(TwilioSMSContext);
    const popOut = React.useCallback(() => {
        console.log("popping out", {
            fromPhoneNumber,
            toPhoneNumber,
        });
        context.openConversation({
            fromPhoneNumber,
            toPhoneNumber,
        });
    }, [context, fromPhoneNumber, toPhoneNumber]);
    return (React.createElement(IconButton, { size: "small", onClick: popOut, tabIndex: -1 },
        React.createElement(Launch, { fontSize: "small" })));
};
export default SMSPopOutButton;
