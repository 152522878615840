/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary List CMA Comps
 */
export const listCmaComps = (params, options) => {
    return axios.get(`/api/cma_comps`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListCmaCompsQueryKey = (params) => {
    return [`/api/cma_comps`, ...(params ? [params] : [])];
};
export const getListCmaCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCmaCompsQueryKey(params);
    const queryFn = ({ signal }) => listCmaComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List CMA Comps
 */
export function useListCmaComps(params, options) {
    const queryOptions = getListCmaCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a CMA Comp
 */
export const createCmaComp = (cMACompCreateRequest, options) => {
    return axios.post(`/api/cma_comps`, cMACompCreateRequest, options);
};
export const getCreateCmaCompMutationOptions = (options) => {
    const mutationKey = ['createCmaComp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createCmaComp(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a CMA Comp
*/
export const useCreateCmaComp = (options) => {
    const mutationOptions = getCreateCmaCompMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a CMA Comp
*/
export const getCmaComp = (uid, options) => {
    return axios.get(`/api/cma_comps/${uid}`, options);
};
export const getGetCmaCompQueryKey = (uid) => {
    return [`/api/cma_comps/${uid}`];
};
export const getGetCmaCompQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetCmaCompQueryKey(uid);
    const queryFn = ({ signal }) => getCmaComp(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a CMA Comp
 */
export function useGetCmaComp(uid, options) {
    const queryOptions = getGetCmaCompQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a CMA Comp
 */
export const updateCmaComp = (uid, cMACompUpdateRequest, options) => {
    return axios.post(`/api/cma_comps/${uid}`, cMACompUpdateRequest, options);
};
export const getUpdateCmaCompMutationOptions = (options) => {
    const mutationKey = ['updateCmaComp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateCmaComp(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a CMA Comp
*/
export const useUpdateCmaComp = (options) => {
    const mutationOptions = getUpdateCmaCompMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a CMA Comp
*/
export const deleteCmaComp = (uid, options) => {
    return axios.delete(`/api/cma_comps/${uid}`, options);
};
export const getDeleteCmaCompMutationOptions = (options) => {
    const mutationKey = ['deleteCmaComp'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteCmaComp(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a CMA Comp
*/
export const useDeleteCmaComp = (options) => {
    const mutationOptions = getDeleteCmaCompMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count CMA Comps
*/
export const countCmaComps = (params, options) => {
    return axios.get(`/api/cma_comps/count`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getCountCmaCompsQueryKey = (params) => {
    return [`/api/cma_comps/count`, ...(params ? [params] : [])];
};
export const getCountCmaCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getCountCmaCompsQueryKey(params);
    const queryFn = ({ signal }) => countCmaComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Count CMA Comps
 */
export function useCountCmaComps(params, options) {
    const queryOptions = getCountCmaCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Download CMA Comps
 */
export const downloadCmaComps = (params, options) => {
    return axios.get(`/api/cma_comps/download`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getDownloadCmaCompsQueryKey = (params) => {
    return [`/api/cma_comps/download`, ...(params ? [params] : [])];
};
export const getDownloadCmaCompsQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadCmaCompsQueryKey(params);
    const queryFn = ({ signal }) => downloadCmaComps(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download CMA Comps
 */
export function useDownloadCmaComps(params, options) {
    const queryOptions = getDownloadCmaCompsQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Reorder CMA Comps
 */
export const reorderCmaComps = (multipleUIDRequest, options) => {
    return axios.post(`/api/cma_comps/reorder`, multipleUIDRequest, options);
};
export const getReorderCmaCompsMutationOptions = (options) => {
    const mutationKey = ['reorderCmaComps'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return reorderCmaComps(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder CMA Comps
*/
export const useReorderCmaComps = (options) => {
    const mutationOptions = getReorderCmaCompsMutationOptions(options);
    return useMutation(mutationOptions);
};
