import * as React from "react";
import { Code, SpeakerNotes } from "@mui/icons-material";
import { Button, Divider, Grid2, IconButton, Tooltip } from "@mui/material";
import numeral from "numeral";
import CoreLink from "@app/common/CoreLink";
import PropertyInfoStat from "./PropertyInfoStat";
import { getBathroomsFull, getBedroomsTotal, getUrl, getAddress, getLivingArea } from "./utils";
/**
 * Renders info about the property in a horizontal bar
 */
const PropertyInfoBar = (props) => {
    var _a, _b, _c, _d;
    const { addSubjectPropertyData, isAddingSubjectProperty, openPhotos, pspUid, showDetails, subjectProperty } = props;
    const onPhotosOpen = React.useCallback((address, photos) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        openPhotos(address, photos.map((photo) => {
            return {
                src: photo.url,
            };
        }));
    }, [openPhotos]);
    const onDetailsOpen = React.useCallback((_) => {
        showDetails(subjectProperty.data);
    }, [showDetails, subjectProperty.data]);
    const onSubjectPropertyButtonClicked = React.useCallback(() => {
        if (addSubjectPropertyData) {
            // add info to matrix
            addSubjectPropertyData(subjectProperty);
        }
    }, [addSubjectPropertyData, subjectProperty]);
    const photos = ((_a = subjectProperty.data["Media"]) !== null && _a !== void 0 ? _a : []).map((photo) => ({
        id: photo["MediaObjectID"],
        url: photo["MediaURL"],
        key: photo["MediaKey"],
        description: photo["ShortDescription"],
    }));
    const { source } = subjectProperty;
    const listingId = subjectProperty.data["ListingId"];
    const url = getUrl(source, listingId);
    const address = getAddress(subjectProperty.data);
    const livingArea = getLivingArea(subjectProperty.data, subjectProperty.source);
    const bedsTotal = getBedroomsTotal(subjectProperty.data);
    const bathsFull = getBathroomsFull(subjectProperty.data);
    return (React.createElement(Grid2, { container: true, spacing: 1 },
        photos.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, null,
                React.createElement(Button, { variant: "contained", size: "small", color: "primary", onClick: onPhotosOpen(subjectProperty.address, photos), style: {
                        fontSize: "0.5rem",
                        height: "1.125rem",
                        transform: "translateY(-1px)",
                    } }, "View Photos")),
            React.createElement(Grid2, null,
                React.createElement(Divider, { orientation: "vertical" })))),
        React.createElement(Grid2, null,
            React.createElement(Tooltip, { title: "Show details" },
                React.createElement(IconButton, { size: "small", style: {
                        fontSize: "0.5rem",
                        height: "1.125rem",
                        transform: "translateY(-1px)",
                    }, onClick: onDetailsOpen },
                    React.createElement(SpeakerNotes, { fontSize: "small" })))),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Address", value: address })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Subdivision", value: (_b = subjectProperty.data["SubdivisionName"]) !== null && _b !== void 0 ? _b : "unknown" })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Property Type", value: (_c = subjectProperty.data["PropertySubType"]) !== null && _c !== void 0 ? _c : "unknown" })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "List Price", value: subjectProperty.data["ListPrice"] !== null && subjectProperty.data["ListPrice"] !== undefined
                    ? numeral(subjectProperty.data["ListPrice"]).format("$0,0")
                    : "unknown" })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Year of Construction", value: subjectProperty.data["YearBuilt"] })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "SqFt", value: livingArea ? numeral(livingArea).format("0,0") : "unknown" })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Bedrooms", value: bedsTotal === null ? "unknown" : numeral(bedsTotal).format("0,0") })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Baths", value: bathsFull === null ? "unknown" : numeral(bathsFull).format("0,0") })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Garage Spaces", value: (_d = subjectProperty.data["GarageSpaces"]) !== null && _d !== void 0 ? _d : "unknown" })),
        React.createElement(Grid2, null,
            React.createElement(Divider, { orientation: "vertical" })),
        React.createElement(Grid2, null,
            React.createElement(PropertyInfoStat, { label: "Pool", value: subjectProperty.data["PoolPrivateYN"] ? "Yes" : "No" })),
        pspUid && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, null,
                React.createElement(Divider, { orientation: "vertical" })),
            React.createElement(Grid2, null,
                React.createElement(Button, { variant: "contained", size: "small", color: "primary", onClick: onSubjectPropertyButtonClicked, disabled: isAddingSubjectProperty, style: {
                        fontSize: "0.5rem",
                        height: "1.125rem",
                        transform: "translateY(-1px)",
                    } }, "Add to PSP")))),
        url && (React.createElement(React.Fragment, null,
            React.createElement(Grid2, { style: { flex: 1 } }),
            React.createElement(Grid2, null,
                React.createElement(Tooltip, { title: "View JSON" },
                    React.createElement(IconButton, { size: "small", component: CoreLink, href: url, target: "_blank" },
                        React.createElement(Code, { fontSize: "small" }))))))));
};
export default PropertyInfoBar;
