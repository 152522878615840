import * as React from "react";
import { Refresh, Search, Star } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, InputAdornment, LinearProgress, ThemeProvider, ToggleButton, ToggleButtonGroup, } from "@mui/material";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import CoreDataGrid from "@app/common/grid/DataGrid";
import { gridThemeNoCheckboxes } from "@app/common/grid/theme";
import { renderDateTimeCell_Local, renderUserCell } from "@app/common/grid/utils";
import SearchParamTextField from "@app/common/SearchParamTextField";
import useStarredViewsForCurrentUser from "@app/hooks/api/useStarredViewsForCurrentUser";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { useListOffices } from "@app/orval/api/offices";
import { searchUsersBasic } from "@app/orval/api/users";
import { useListViews, useCountViews } from "@app/orval/api/views";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS_WITH_PACEHOLDER_DATA } from "@app/orval/config";
import { renderActionCell, renderFiltersCell, renderSharingCell } from "./table-cells";
import usePostQuery from "../../dashboards/agent-dashboard/usePostQuery";
import SpreadsheetPagination from "../components/spreadsheet-view/SpreadsheetPagination";
const entityTypeOptions = [
    {
        name: "Buyer Leads",
        value: "Buyer Lead",
    },
    {
        name: "Seller Leads",
        value: "Seller Lead",
    },
    {
        name: "Buyer Contracts",
        value: "Buyer Contract",
    },
    {
        name: "Seller Contracts",
        value: "Listing",
    },
    {
        name: "Contacts",
        value: "Contact",
    },
    {
        name: "Recruits",
        value: "Recruit",
    },
    {
        name: "Users",
        value: "User",
    },
];
const Views = (props) => {
    var _a, _b, _c, _d;
    const { limit = 100, offset = 0, search, type } = props;
    const paginatedQueryParams = pickBy({ limit, offset, search, entity_type: type });
    const queryParams = omit(paginatedQueryParams, "limit", "offset");
    const updateSearch = useUpdateSearch();
    const listViewsApi = useListViews(paginatedQueryParams, REFETCH_CACHE_PARAMS_WITH_PACEHOLDER_DATA);
    const views = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listViewsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listViewsApi]);
    const listOfficesApi = useListOffices(INFINITE_CACHE_PARAMS);
    const offices = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listOfficesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listOfficesApi]);
    const listUsersApi = usePostQuery(searchUsersBasic, { limit: 1000, disable_sort: true }, INFINITE_CACHE_PARAMS);
    const users = React.useMemo(() => {
        var _a, _b;
        return (_b = (_a = listUsersApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }, [listUsersApi]);
    const countViewsApi = useCountViews(queryParams, REFETCH_CACHE_PARAMS_WITH_PACEHOLDER_DATA);
    const count = (_b = (_a = countViewsApi.data) === null || _a === void 0 ? void 0 : _a.data.count) !== null && _b !== void 0 ? _b : null;
    const totalViewsApi = useCountViews({}, REFETCH_CACHE_PARAMS_WITH_PACEHOLDER_DATA);
    const total = (_d = (_c = totalViewsApi.data) === null || _c === void 0 ? void 0 : _c.data.count) !== null && _d !== void 0 ? _d : null;
    const refresh = React.useCallback(() => {
        listViewsApi.refetch();
    }, [listViewsApi]);
    const { starView, starredViewUids } = useStarredViewsForCurrentUser();
    const starView_ = React.useCallback((uid, name) => () => {
        starView(uid, name);
    }, [starView]);
    const renderStarCell = React.useCallback((params) => {
        var _a;
        const row = params.row;
        const isStarred = (_a = starredViewUids === null || starredViewUids === void 0 ? void 0 : starredViewUids.has(row.uid)) !== null && _a !== void 0 ? _a : null;
        if (isStarred == null) {
            return null;
        }
        return (React.createElement(Star, { style: { color: isStarred ? "#FFD700" : "#ffffff", cursor: "pointer", height: "100%" }, sx: { "& path": { stroke: "#ccc" } }, fontSize: "small", onClick: starView_(row.uid, row.name) }));
    }, [starView_, starredViewUids]);
    const columns = React.useMemo(() => {
        return [
            {
                headerName: "",
                field: "uid",
                width: 38,
                minWidth: 38,
                resizable: false,
                sortable: false,
                renderCell: renderActionCell,
            },
            {
                headerName: "",
                field: "is_starred",
                width: 38,
                minWidth: 38,
                resizable: false,
                sortable: false,
                renderCell: renderStarCell,
            },
            {
                headerName: "Name",
                field: "name",
                width: 200,
            },
            {
                headerName: "Purpose",
                field: "purpose",
                width: 100,
            },
            {
                headerName: "Sharing",
                field: "sharing",
                width: 300,
                renderCell: renderSharingCell,
            },
            {
                headerName: "Filters",
                field: "filters",
                width: 400,
                renderCell: renderFiltersCell,
            },
            {
                headerName: "Type",
                field: "entity_type",
                width: 150,
            },
            {
                headerName: "Created At",
                field: "created_at",
                width: 200,
                renderCell: renderDateTimeCell_Local,
            },
            {
                headerName: "Created By",
                field: "created_by",
                width: 200,
                renderCell: renderUserCell,
            },
            {
                headerName: "Updated At",
                field: "updated_at",
                width: 200,
                renderCell: renderDateTimeCell_Local,
            },
            {
                headerName: "Updated By",
                field: "updated_by",
                width: 200,
                renderCell: renderUserCell,
            },
        ];
    }, [renderStarCell]);
    const rows = React.useMemo(() => {
        var _a;
        return ((_a = views === null || views === void 0 ? void 0 : views.map((view) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({ id: view.uid }, view), { purpose: view.purpose ? view.purpose.name : null, created_at: new Date(view.created_at).toLocaleString(), updated_at: new Date(view.updated_at).toLocaleString(), sharing: Object.assign(Object.assign({}, view.sharing), { users: (_a = view.sharing.users) === null || _a === void 0 ? void 0 : _a.map((user) => {
                        const foundUser = users === null || users === void 0 ? void 0 : users.find((u) => u.uid === user.user);
                        return Object.assign(Object.assign({}, user), { user: foundUser });
                    }), offices: (_b = view.sharing.offices) === null || _b === void 0 ? void 0 : _b.map((office) => {
                        const foundOffice = offices === null || offices === void 0 ? void 0 : offices.find((u) => u.uid === office.office);
                        return Object.assign(Object.assign({}, office), { office: foundOffice });
                    }) }), is_starred: (_c = starredViewUids === null || starredViewUids === void 0 ? void 0 : starredViewUids.has(view.uid)) !== null && _c !== void 0 ? _c : null }));
        })) !== null && _a !== void 0 ? _a : null);
    }, [offices, starredViewUids, users, views]);
    const isLoading = React.useMemo(() => {
        return listViewsApi.isFetching || countViewsApi.isFetching || totalViewsApi.isFetching;
    }, [listViewsApi.isFetching, countViewsApi.isFetching, totalViewsApi.isFetching]);
    const page = React.useMemo(() => {
        return offset / limit + 1;
    }, [offset, limit]);
    const handlePageChange = React.useCallback((_, newPage) => {
        updateSearch("offset", newPage * limit);
    }, [updateSearch, limit]);
    const handleRowsPerPageChange = React.useCallback((e) => {
        updateSearch("limit", parseInt(e.target.value, 10), "offset", 0);
    }, [updateSearch]);
    const setType = React.useCallback((_, newType) => {
        updateSearch("type", newType, "offset", 0);
    }, [updateSearch]);
    return (React.createElement(Grid, { container: true, spacing: 0, direction: "column", wrap: "nowrap", style: { height: "100%" } },
        React.createElement(Grid, { item: true, container: true, spacing: 2 },
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(Box, { p: 2, pt: 0, pb: 0 },
                    React.createElement(SearchParamTextField, { searchParam: "search", TextFieldProps: {
                            size: "small",
                            InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(Search, null))),
                            },
                        } }))),
            React.createElement(Grid, { item: true, style: { flex: 1 } }),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(ToggleButtonGroup, { exclusive: true, size: "small", value: type, onChange: setType, tabIndex: -1 }, entityTypeOptions.map((option) => (React.createElement(ToggleButton, { key: option.value, value: option.value, tabIndex: -1 }, option.name))))),
            React.createElement(Grid, { item: true },
                React.createElement(Divider, { orientation: "vertical" })),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(IconButton, { size: "small", onClick: refresh, tabIndex: -1 },
                    React.createElement(Refresh, { fontSize: "small" }))),
            React.createElement(Grid, { item: true },
                React.createElement(Divider, { orientation: "vertical" })),
            React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                React.createElement(SpreadsheetPagination, { count: count !== null && count !== void 0 ? count : 0, total: total !== null && total !== void 0 ? total : undefined, rowsPerPage: limit, page: page, handlePageChange: handlePageChange, handleRowsPerPageChange: handleRowsPerPageChange }))),
        isLoading && rows != null && (React.createElement(Grid, { item: true, style: { position: "relative" } },
            React.createElement(LinearProgress, { variant: "indeterminate", style: { position: "absolute", height: "3px", width: "100%", zIndex: 100 } }))),
        React.createElement(Grid, { item: true, style: { flex: 1, height: "100%", width: "100%" } },
            React.createElement(ThemeProvider, { theme: gridThemeNoCheckboxes },
                React.createElement(CoreDataGrid, { rows: rows !== null && rows !== void 0 ? rows : [], columns: columns, density: "compact", rowHeight: 30, columnHeaderHeight: 30, hideFooter: true, loading: false, pagination: false, paginationMode: "server", rowCount: count !== null && count !== void 0 ? count : undefined, showCellVerticalBorder: true, showColumnVerticalBorder: true, sortingMode: "server", filterMode: "server", disableColumnReorder: true, disableColumnSorting: true, disableColumnFilter: true, disableColumnMenu: true, localeText: {
                        noRowsLabel: rows == null ? "Loading..." : "No items found",
                        noResultsOverlayLabel: 
                        // eslint-disable-next-line no-nested-ternary
                        (rows === null || rows === void 0 ? void 0 : rows.length) === 0
                            ? "No views found"
                            : // eslint-disable-next-line no-nested-ternary
                                listViewsApi.error
                                    ? `An error occurred: ${String(listViewsApi.error)}`
                                    : rows == null
                                        ? "Loading..."
                                        : "Rendering...",
                    } })))));
};
export default Views;
