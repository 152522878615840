import * as React from "react";
import { Add, Edit, Delete, DragHandle } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Typography, } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CoreError from "@app/common/CoreError";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { deleteTwilioAutoReplySetting, reorderTwilioAutoReplySettings, useListTwilioAutoReplySettings, } from "@app/orval/api/twilio-auto-reply";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import AutoreplyDialog from "./AutoreplyDialog";
const Autoreply = () => {
    useDocumentTitle("Twilio | Autoreply");
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [items, setItems] = React.useState(null);
    const queryClient = useQueryClient();
    const twilioAutoReplySettingsListApi = useListTwilioAutoReplySettings();
    // const items = twilioAutoReplySettingsListApi.data?.data ?? null;
    const confirm = useConfirm();
    const showErrorDialog = useErrorDialog();
    const getAutoReplySettings = React.useCallback(() => {
        twilioAutoReplySettingsListApi
            .refetch()
            .then((result) => {
            var _a, _b;
            setItems((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null);
        })
            .catch((error) => {
            twilioAutoReplySettingsListApi.error = error;
        });
    }, [setItems, twilioAutoReplySettingsListApi]);
    React.useEffect(() => {
        getAutoReplySettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const openDialog = React.useCallback((uid) => () => {
        setSelectedItem(uid ? items.find((item) => item.uid === uid) : null);
        setIsDialogOpen(true);
    }, [setSelectedItem, setIsDialogOpen, items]);
    const deleteItem = React.useCallback((uid) => () => {
        confirm(CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS).then((result) => {
            if (result.confirmed) {
                queryClient.setQueryData(twilioAutoReplySettingsListApi.queryKey, (old) => {
                    if (!old)
                        return old;
                    return Object.assign(Object.assign({}, old), old.data.filter((item) => item.uid !== uid));
                });
                const loadingSnackbar = enqueueSnackbar("Deleting...", { variant: "info", persist: true });
                deleteTwilioAutoReplySetting(uid)
                    .then(() => {
                    enqueueSnackbar("Deleted phone number", { variant: "success" });
                })
                    .catch((error) => {
                    showErrorDialog(error);
                })
                    .finally(() => {
                    closeSnackbar(loadingSnackbar);
                });
            }
        });
    }, [queryClient, confirm, showErrorDialog, twilioAutoReplySettingsListApi.queryKey]);
    const onSaveComplete = React.useCallback(() => {
        // this.refresh();
        setIsDialogOpen(false);
        getAutoReplySettings();
    }, [setIsDialogOpen, getAutoReplySettings]);
    const onCancel = React.useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);
    const reorder = React.useCallback((e) => {
        const { destination, source } = e;
        if ((destination === null || destination === void 0 ? void 0 : destination.index) != null) {
            queryClient.setQueryData(twilioAutoReplySettingsListApi.queryKey, (old) => {
                if (!old)
                    return old;
                const newItems = old.data;
                const item = newItems[source.index];
                newItems.splice(source.index, 1);
                newItems.splice(destination.index, 0, item);
                reorderTwilioAutoReplySettings({
                    items: newItems.map((item_) => item_.uid),
                })
                    .then(() => {
                    console.log("reordered");
                })
                    .catch((error) => {
                    showErrorDialog(error);
                });
                return Object.assign(Object.assign({}, old), newItems);
            });
        }
    }, [queryClient, twilioAutoReplySettingsListApi.queryKey, showErrorDialog]);
    let content;
    const { error } = twilioAutoReplySettingsListApi;
    if (error) {
        content = React.createElement(CoreError, { error: error });
    }
    else if (!items) {
        content = null;
    }
    else {
        content = (React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Table, { size: "small", stickyHeader: true },
                React.createElement("colgroup", null,
                    React.createElement("col", { style: { width: "150px" } }),
                    React.createElement("col", null),
                    React.createElement("col", null)),
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { style: { textAlign: "center" } },
                            React.createElement(Tooltip, { title: "Add setting" },
                                React.createElement(IconButton, { size: "small", onClick: openDialog() },
                                    React.createElement(Add, { fontSize: "small" })))),
                        React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Input"),
                        React.createElement(TableCell, { style: { fontWeight: "bold" } }, "Output"))),
                React.createElement(DragDropContext, { onDragEnd: reorder },
                    React.createElement(Droppable, { droppableId: "autoreply-droppable" }, (droppableProvided) => (React.createElement(TableBody, Object.assign({}, droppableProvided.droppableProps, { ref: droppableProvided.innerRef, style: { display: "table-row-group" } }), items.map((item, indx) => (React.createElement(Draggable, { key: item.uid, draggableId: String(item.uid), index: indx }, (draggableProvided) => (React.createElement(TableRow, Object.assign({ ref: draggableProvided.innerRef }, draggableProvided.draggableProps),
                        React.createElement(TableCell, Object.assign({}, draggableProvided.dragHandleProps, { style: { textAlign: "center", marginTop: "auto", marginBottom: "auto" } }),
                            React.createElement(DragHandle, { fontSize: "small", className: "drag-handle", style: {
                                    cursor: "grab",
                                    transform: "translateY(7px)",
                                } }),
                            React.createElement(Tooltip, { title: "Remove setting" },
                                React.createElement(IconButton, { size: "small", onClick: deleteItem(item.uid) },
                                    React.createElement(Delete, { fontSize: "small" }))),
                            React.createElement(Tooltip, { title: "Edit setting" },
                                React.createElement(IconButton, { size: "small", onClick: openDialog(item.uid) },
                                    React.createElement(Edit, { fontSize: "small" })))),
                        React.createElement(TableCell, null, item.input),
                        React.createElement(TableCell, null, item.output)))))))))))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { component: Grid, container: true, p: 2, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: "caption", display: "block" }, "Use this feature to set up automatic replies when text messages are received."),
                React.createElement(Typography, { variant: "caption", display: "block", style: { color: "red" } }, "NOTE: Since DASH is no longer soliciting SMS feedback, this feature is not being used."),
                React.createElement(Typography, { variant: "caption", display: "block", dangerouslySetInnerHTML: {
                        __html: "If a <b>partial</b> match is detected with the input, the output text will be sent.",
                    } }),
                React.createElement(Typography, { variant: "caption", display: "block" }, "The automation will search this list from top to bottom, so if multiple matches are detected, the one closest to the top will be processed.")),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Divider, null)),
            content),
        React.createElement(AutoreplyDialog, { open: !!isDialogOpen, item: selectedItem, onSaveComplete: onSaveComplete, onCancel: onCancel })));
};
export default Autoreply;
