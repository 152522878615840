var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useMemo, useRef } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
const usePostQuery = (mutation, data, options, isEnabled = true) => {
    const queryKey = useMemo(() => {
        return [`${mutation.name}__${JSON.stringify(data)}`];
    }, [data, mutation.name]);
    const cancelToken = useRef(null);
    const api = useQuery(Object.assign({ 
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey, queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            console.log("QUERYFN DOING QUERY");
            if (!isEnabled) {
                console.log("QUERYFN UNDEFINED");
                return undefined;
            }
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
            cancelToken.current = axios.CancelToken.source();
            return mutation(data, { cancelToken: cancelToken.current.token });
        }), staleTime: 0, gcTime: 5 * 60 * 1000, placeholderData: keepPreviousData, enabled: isEnabled }, options));
    useEffect(() => {
        return () => {
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
        };
    }, []);
    return Object.assign(Object.assign({}, api), { queryKey: queryKey });
};
export default usePostQuery;
