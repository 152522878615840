import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Divider, Grid2, Typography } from "@mui/material";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreTextField from "@app/common/CoreTextField";
import useStateWithCallback from "@app/hooks/useStateCallback";
import useUpdateSearch from "@app/hooks/useUpdateSearch";
import { searchSpark } from "@app/orval/api/spark";
const Spark = () => {
    const [sparkResponse, setSparkResponse] = React.useState(null);
    const [isSearching, setIsSearching] = useStateWithCallback(false);
    const [error, setError] = React.useState(null);
    const queryParams = useSearchParams()[0];
    const updateSearch = useUpdateSearch();
    const mlsId = React.useMemo(() => {
        var _a;
        return (_a = queryParams.get("mlsid")) !== null && _a !== void 0 ? _a : null;
    }, [queryParams]);
    React.useEffect(() => {
        setSparkResponse(null);
        if (mlsId) {
            setIsSearching(true, () => {
                searchSpark({ listing_id: mlsId })
                    .then((result) => {
                    setSparkResponse(result.data);
                })
                    .catch((sparkError) => {
                    setError(sparkError);
                })
                    .then(() => {
                    setIsSearching(false);
                });
            });
        }
    }, [mlsId, setIsSearching, setSparkResponse]);
    const onMlsIdChange = React.useCallback((text) => {
        updateSearch("mlsid", text);
    }, [updateSearch]);
    let responseElement;
    if (error) {
        responseElement = React.createElement(CoreError, { error: error });
    }
    else if (sparkResponse) {
        responseElement = React.createElement("pre", null, JSON.stringify(sparkResponse, null, 2));
    }
    else {
        responseElement = React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, spacing: 2, style: { padding: "16px" } },
        React.createElement(Grid2, { size: 12, style: { marginBottom: "8px" } },
            React.createElement(Typography, { variant: "body2" }, "Use the Spark Interactive API explorer to view the API response for a given property, identified by its MLS number.")),
        React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
            React.createElement(CoreTextField, { label: "MLS #", size: "small", fullWidth: true, style: {
                    maxWidth: "300px",
                    marginRight: "8px",
                }, value: mlsId !== null && mlsId !== void 0 ? mlsId : "", onTextChange: onMlsIdChange, disabled: isSearching })),
        React.createElement(Grid2, { size: 12 },
            React.createElement(Divider, null)),
        mlsId && React.createElement(Grid2, { size: 12 }, responseElement)));
};
export default Spark;
