import { useEffect } from "react";
import { useGetAccessToMessage } from "@app/orval/api/emails";
import { isChromeExtension } from "@app/util/Utils";
import { navigateTo } from "../../window-messages";
/**
 * Redirects a user to the thread in Gmail
 * if they have access to the thread
 * @param threadId
 */
const useGmailMessageRedirect = (messageId) => {
    var _a, _b;
    const getThreadAccess = useGetAccessToMessage(messageId !== null && messageId !== void 0 ? messageId : "", {
        query: { enabled: !!messageId && isChromeExtension },
    });
    const messageIdConfirmedAccess = (_b = (_a = getThreadAccess.data) === null || _a === void 0 ? void 0 : _a.data.message_id) !== null && _b !== void 0 ? _b : null;
    useEffect(() => {
        if (messageIdConfirmedAccess) {
            navigateTo(`inbox/${messageIdConfirmedAccess}`, { isRootLevel: true });
        }
    }, [messageIdConfirmedAccess]);
};
export default useGmailMessageRedirect;
