import * as React from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withRouter = (Component) => {
    const WithRouter = (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const searchParams = useSearchParams();
        const params = useParams();
        return React.createElement(Component, Object.assign({}, props, { location: location, navigate: navigate, params: params, searchParams: searchParams }));
    };
    return WithRouter;
};
