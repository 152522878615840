import * as React from "react";
import { Alert } from "@mui/material";
import { useMountedState } from "react-use";
import { useGetCurrentVersion } from "@app/orval/api/version";
const reloadPage = () => {
    window.location.reload();
};
const NewVersionNotification = () => {
    const getVersionApi = useGetCurrentVersion();
    const [initialVersion, setInitialVersion] = React.useState(null);
    const [currentVersion, setCurrentVersion] = React.useState(null);
    const isMounted = useMountedState();
    const getVersion = React.useCallback(() => {
        if (!isMounted()) {
            return;
        }
        getVersionApi
            .refetch()
            .then((result) => {
            var _a, _b, _c, _d;
            if (initialVersion == null) {
                setInitialVersion((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.data.version) !== null && _b !== void 0 ? _b : null);
            }
            setCurrentVersion((_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.data.version) !== null && _d !== void 0 ? _d : null);
        })
            .catch(console.error)
            .then(() => {
            setTimeout(getVersion, 30000);
        });
    }, [getVersionApi, initialVersion, isMounted]);
    React.useEffect(() => {
        getVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!initialVersion || initialVersion === currentVersion) {
        return null;
    }
    return (React.createElement(Alert, { severity: "info", onClick: reloadPage, style: {
            paddingTop: 0,
            paddingBottom: 0,
            cursor: "pointer",
            borderRadius: 0,
            background: "rgb(255, 205, 205)",
        } }, "A new version of the application is available. Click here to refresh the page to be sure you're working with the latest updates."));
};
export default NewVersionNotification;
