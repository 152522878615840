import * as React from "react";
import { House, LabelImportant } from "@mui/icons-material";
import { Chip, List, Grid, ListItemButton, ListItemText, Typography, ListItemIcon } from "@mui/material";
import fontColorContrast from "font-color-contrast";
import RouterLink from "@app/common/RouterLink";
import { useListStages } from "@app/orval/api/stages";
import { REFETCH_CACHE_PARAMS } from "@app/orval/config";
import StreetViewImage from "./StreetViewImage";
import CollapsibleSection from "../../components/box-view/Contact/CollapsibleSection";
import { groupBy } from "../../utils/utils";
const OtherLeadsAndContracts = (props) => {
    var _a, _b;
    const { buyerContracts, buyerLeads, clients, sellerContracts, sellerLeads } = props;
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_b = (_a = listStagesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const stagesByUid = React.useMemo(() => {
        return stages ? groupBy(stages, "uid") : null;
    }, [stages]);
    const res = React.useMemo(() => {
        return [
            ...buyerLeads.map((x) => {
                var _a, _b;
                return ({
                    type: "Buyer Lead",
                    uid: x.uid,
                    name: x.name,
                    address: [x.fields["Street #"], x.fields["Street Name"], x.fields["City"]].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).length > 0
                        ? [x.fields["Street #"], x.fields["Street Name"], x.fields["City"], x.fields["State"]]
                            .filter((o) => o)
                            .join(" ")
                        : null,
                    stageName: (_a = x.stage) === null || _a === void 0 ? void 0 : _a.name,
                    stageColor: x.stage && (stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[x.stage.uid]) ? stagesByUid[x.stage.uid].color : null,
                    clients: (_b = clients === null || clients === void 0 ? void 0 : clients.filter((client) => x.clients.map((c) => c.uid).includes(client.uid))) !== null && _b !== void 0 ? _b : null,
                    url: `/buyers/${x.uid}`,
                });
            }),
            ...sellerLeads.map((x) => {
                var _a, _b;
                return ({
                    type: "Seller Lead",
                    uid: x.uid,
                    name: x.name,
                    address: [x.fields["Street #"], x.fields["Street Name"], x.fields["City"]].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).length > 0
                        ? [x.fields["Street #"], x.fields["Street Name"], x.fields["City"], x.fields["State"]]
                            .filter((o) => o)
                            .join(" ")
                        : null,
                    stageName: (_a = x.stage) === null || _a === void 0 ? void 0 : _a.name,
                    stageColor: x.stage && (stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[x.stage.uid]) ? stagesByUid[x.stage.uid].color : null,
                    clients: (_b = clients === null || clients === void 0 ? void 0 : clients.filter((client) => x.clients.map((c) => c.uid).includes(client.uid))) !== null && _b !== void 0 ? _b : null,
                    url: `/sellers/${x.uid}`,
                });
            }),
            ...buyerContracts.map((x) => {
                var _a;
                return ({
                    type: "Buyer Contract",
                    uid: x.uid,
                    name: x.name,
                    address: [x.fields["Street #"], x.fields["Street Name"], x.fields["City"]].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).length > 0
                        ? [x.fields["Street #"], x.fields["Street Name"], x.fields["City"], x.fields["State"]]
                            .filter((o) => o)
                            .join(" ")
                        : null,
                    stageName: (_a = x.stage) === null || _a === void 0 ? void 0 : _a.name,
                    stageColor: x.stage && (stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[x.stage.uid]) ? stagesByUid[x.stage.uid].color : null,
                    clients: null,
                    url: `/buyers/${x.buyer_lead_uid}`,
                });
            }),
            ...sellerContracts.map((x) => {
                var _a;
                return ({
                    type: "Seller Contract",
                    uid: x.uid,
                    name: x.name,
                    address: [x.fields["Street #"], x.fields["Street Name"], x.fields["City"]].filter((o) => o === null || o === void 0 ? void 0 : o.trim()).length > 0
                        ? [x.fields["Street #"], x.fields["Street Name"], x.fields["City"], x.fields["State"]]
                            .filter((o) => o)
                            .join(" ")
                        : null,
                    stageName: (_a = x.stage) === null || _a === void 0 ? void 0 : _a.name,
                    stageColor: x.stage && (stagesByUid === null || stagesByUid === void 0 ? void 0 : stagesByUid[x.stage.uid]) ? stagesByUid[x.stage.uid].color : null,
                    clients: null,
                    url: `/sellers/${x.seller_lead_uid}`,
                });
            }),
        ];
    }, [buyerLeads, sellerLeads, buyerContracts, sellerContracts, stagesByUid, clients]);
    return (React.createElement(CollapsibleSection, Object.assign({ icon: React.createElement(LabelImportant, null), title: "Other Leads and Contracts", count: buyerLeads.length + sellerLeads.length + buyerContracts.length + sellerContracts.length }, props.CollapsibleSectionProps),
        React.createElement(List, { dense: true }, res.map((item) => {
            var _a;
            return (React.createElement(ListItemButton, { key: item.uid, component: RouterLink, to: item.url },
                React.createElement(ListItemIcon, null, item.address && item.address.length > 4 ? (React.createElement(StreetViewImage, { address: item.address, ImageProps: { style: { width: "32px", height: "32px", borderRadius: "2px" } } })) : (React.createElement(House, { fontSize: "large" }))),
                React.createElement(ListItemText, { primary: React.createElement(Grid, { container: true, spacing: 1 },
                        React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Typography, null, item.type)),
                        item.stageName && (React.createElement(Grid, { item: true, style: { marginTop: "auto", marginBottom: "auto" } },
                            React.createElement(Chip, { size: "small", sx: {
                                    background: (_a = item.stageColor) !== null && _a !== void 0 ? _a : undefined,
                                    color: item.stageColor ? fontColorContrast(item.stageColor, 0.7) : undefined,
                                    height: "12px",
                                }, label: item.stageName })))), secondary: item.name })));
        }))));
};
export default OtherLeadsAndContracts;
