import * as React from "react";
import { Alert, Grid, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { format } from "@redtea/format-axios-error";
import { useQueryClient } from "@tanstack/react-query";
import googleGroupsIcon from "@app/assets/img/google-groups.png";
import CoreLoading from "@app/common/CoreLoading";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useListGoogleGroups, useUpdateGoogleGroupAccess } from "@app/orval/api/google-groups";
import { useListGoogleGroupsForUser } from "@app/orval/api/users";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import CollapsibleSection from "../../../components/box-view/Contact/CollapsibleSection";
const GoogleGroupAccess = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { userUid } = props;
    const [currentlyUpdatingAccess, setCurrentlyUpdatingAccess] = React.useState(new Set());
    const queryClient = useQueryClient();
    const errorDialog = useErrorDialog();
    const listGoogleGroupsApi = useListGoogleGroups(INFINITE_CACHE_PARAMS);
    const googleGroups = (_b = (_a = listGoogleGroupsApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const groupAccessForUserApi = useListGoogleGroupsForUser(userUid, REFETCH_CACHE_PARAMS);
    const groupAccessForUser = (_d = (_c = groupAccessForUserApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const groupsWithAccess = React.useMemo(() => {
        var _a;
        return groupAccessForUser ? new Set(((_a = groupAccessForUser.groups) !== null && _a !== void 0 ? _a : []).map((x) => x.id)) : null;
    }, [groupAccessForUser]);
    const updateGroupAccessApi = useUpdateGoogleGroupAccess();
    const isLoading = listGoogleGroupsApi.isFetching || groupAccessForUserApi.isFetching;
    const toggleAccess = React.useCallback((groupId) => (_, checked) => {
        setCurrentlyUpdatingAccess((prev) => new Set([...prev, groupId]));
        updateGroupAccessApi
            .mutateAsync({
            data: {
                user_uid: userUid,
                group_id: groupId,
                has_access: checked,
            },
        })
            .then(() => {
            queryClient.setQueryData(groupAccessForUserApi.queryKey, (old) => {
                var _a, _b, _c;
                if (old) {
                    return Object.assign(Object.assign({}, old), { data: Object.assign(Object.assign({}, old.data), { groups: checked
                                ? [...((_a = old.data.groups) !== null && _a !== void 0 ? _a : []), ((_b = googleGroups.groups) !== null && _b !== void 0 ? _b : []).find((x) => x.id === groupId)]
                                : ((_c = old.data.groups) !== null && _c !== void 0 ? _c : []).filter((x) => x.id !== groupId) }) });
                }
                return undefined;
            });
        })
            .catch(errorDialog)
            .then(() => {
            setCurrentlyUpdatingAccess((prev) => new Set([...prev].filter((x) => x !== groupId)));
        });
    }, [updateGroupAccessApi, userUid, errorDialog, queryClient, groupAccessForUserApi.queryKey, googleGroups]);
    return (React.createElement(CollapsibleSection, { icon: React.createElement("img", { src: googleGroupsIcon, height: 20, alt: "Google Groups" }), title: "Google Group Access", count: groupAccessForUser ? ((_e = groupAccessForUser.groups) !== null && _e !== void 0 ? _e : []).length : undefined, isCollapsedInitially: true },
        listGoogleGroupsApi.error && React.createElement(Alert, { severity: "error" }, JSON.stringify(format(listGoogleGroupsApi.error))),
        groupAccessForUserApi.error && (React.createElement(Alert, { severity: "error" }, JSON.stringify(format(groupAccessForUserApi.error)))),
        isLoading && React.createElement(CoreLoading, null),
        googleGroups && ((_f = googleGroups.groups) !== null && _f !== void 0 ? _f : []).length === 0 && (React.createElement(Typography, { variant: "body2" }, "No Google Groups found")),
        groupsWithAccess && (googleGroups === null || googleGroups === void 0 ? void 0 : googleGroups.groups) && googleGroups.groups.length > 0 && (React.createElement(Grid, { container: true, direction: "column", wrap: "nowrap" }, googleGroups.groups
            .sort((a, b) => {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        })
            .map((group) => (React.createElement(Grid, { key: group.id, item: true },
            React.createElement(FormControlLabel, { label: group.name, control: React.createElement(Checkbox, { color: "primary", size: "small", onChange: toggleAccess(group.id), checked: groupsWithAccess.has(group.id), disabled: currentlyUpdatingAccess.has(group.id), style: { paddingTop: 0, paddingBottom: 0 } }) }))))))));
};
export default GoogleGroupAccess;
