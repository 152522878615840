import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "@app/hooks/useDocumentTitle";
import RequestDebugging from "./RequestDebugging";
const RequestDebuggingPage = () => {
    useDocumentTitle("Debugging");
    const [searchParams] = useSearchParams();
    const fromDatetime = searchParams.get("from_datetime");
    const toDatetime = searchParams.get("to_datetime");
    const userEmail = searchParams.get("user_email");
    const search = searchParams.get("search");
    const limit = searchParams.get("limit");
    const offset = searchParams.get("offset");
    return (React.createElement(RequestDebugging, { fromDatetime: fromDatetime !== null && fromDatetime !== void 0 ? fromDatetime : undefined, toDatetime: toDatetime !== null && toDatetime !== void 0 ? toDatetime : undefined, userEmail: userEmail !== null && userEmail !== void 0 ? userEmail : undefined, search: search !== null && search !== void 0 ? search : undefined, limit: limit ? parseInt(limit, 10) : undefined, offset: offset ? parseInt(offset, 10) : undefined }));
};
export default RequestDebuggingPage;
